import React from "react";
import { useTranslation } from "react-i18next";

import {
  CreateSimpleCanopyButton,
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import {
  MAIN_PROJECTS_ROUTE,
  PROJECT_DRAFT_BASE_ROUTE
} from "@arbolus-technologies/routes";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { LinkBoxButton } from "@arbolus-technologies/ui/components";
import { GrayBox } from "@arbolus-technologies/ui/layout";

import styles from "./DashboardMainButtons.module.scss";

export const DashboardMainButtons: React.FC = () => {
  const { t } = useTranslation("dashboardMainButtons");
  const { trackClick } = useArbolusTracking();

  return (
    <GrayBox fullWidth>
      <div className={styles.container}>
        <CreateSimpleCanopyButton
          buttonType="dashboard"
          onTrack={() =>
            trackClick(MixPanelEventNames.UserDashboardTopVideoInsights)
          }
        />
        <LinkBoxButton
          text={t("createProject")}
          icon={{
            name: "folder",
            color: ARBOLUS_COLORS.bColorBaseWhite,
            background: ARBOLUS_COLORS.bColorBasePurple
          }}
          subTitle={t("arbolusCalls")}
          to={PROJECT_DRAFT_BASE_ROUTE()}
          onClick={() => trackClick(MixPanelEventNames.CreateSimplifiedProject)}
        />
        <LinkBoxButton
          text={t("myProjects")}
          icon={{
            name: "webhook",
            color: ARBOLUS_COLORS.bColorBaseWhite,
            background: ARBOLUS_COLORS.bColorBasePurple
          }}
          to={MAIN_PROJECTS_ROUTE()}
        />
      </div>
    </GrayBox>
  );
};
