import React from "react";
import { Route } from "react-router";

import {
  BOOKMARKS,
  BOOKMARK_COLLECTIONS,
  BookmarkCollectionsPage,
  CollectionPage
} from "@arbolus-technologies/features/common";

export const BookmarksRouter = [
  <Route
    path={BOOKMARK_COLLECTIONS}
    key={BOOKMARK_COLLECTIONS}
    component={BookmarkCollectionsPage}
    exact
  />,
  <Route path={BOOKMARKS} key={BOOKMARKS} component={CollectionPage} exact />
];
