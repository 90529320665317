import clsx from "clsx";
import React from "react";

import styles from "./NumberInACircle.module.scss";

interface NumberInACircleProps {
  number?: number;
  active: boolean;
  isReviewNow?: boolean;
}
export const NumberInACircle: React.FC<NumberInACircleProps> = ({
  number,
  active,
  isReviewNow
}) => (
  <div
    className={clsx(
      styles.numberContainer,
      isReviewNow ? styles.reviewNow : undefined,
      active ? styles.active : undefined
    )}
  >
    {<div>{number}</div> ?? <span>...</span>}
  </div>
);
