import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { AntDButtonLink, AntDIcon } from "@arbolus-technologies/antDComponents";
import {
  ArbolusGPTButton,
  ExpertInvitePanel,
  MixPanelEventNames,
  SlidePanel,
  useArbolusTracking,
  useIsChatAvailable
} from "@arbolus-technologies/features/common";
import {
  NEW_EXPERT,
  PROJECT_CHAT_BASE_ROUTE,
  PROJECT_ROUTE
} from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { NotificationDot } from "@arbolus-technologies/ui/components";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import styles from "./HeaderButtons.module.scss";

interface HeaderButtonProps {
  projectId: string;
  unreadMessagesCount?: number;
}

export const HeaderButtons: React.FC<HeaderButtonProps> = ({
  projectId,
  unreadMessagesCount
}) => {
  const { t } = useTranslation("projectDashboard");
  const dispatch = useDispatch();
  const { trackRedirect, trackClick } = useArbolusTracking();

  const projectName = useSelector(ProjectNxSelector.projectName());
  const loggedUserMail = useSelector(CacheSelector.userProfile()).email;

  const chatUrl = PROJECT_CHAT_BASE_ROUTE(projectId);
  const displayChatButton = useIsChatAvailable();
  const hasUnreadMessages =
    typeof unreadMessagesCount !== "undefined" && unreadMessagesCount > 0;
  const showNotificationDot = displayChatButton && hasUnreadMessages;

  const openExpertsPanel = () => {
    dispatch(PanelStoreActions.openPanel(PanelId.AssignExperts));
  };

  return (
    <>
      <div className={styles.btnContainer}>
        <ArbolusGPTButton
          projectPath={`${PROJECT_ROUTE(projectId).pathname} | ${projectName}>`}
          onTrack={() =>
            trackClick(MixPanelEventNames.ProjectDashboardArbolusGPTTest, {
              loggedUserMail
            })
          }
          projectId={projectId}
        />
        {displayChatButton && (
          <div className={styles.chatContainer}>
            {showNotificationDot && (
              <NotificationDot
                offsetTop="-4px"
                offsetRight="-4px"
                size="10px"
              />
            )}
            <AntDButtonLink
              type="default"
              to={chatUrl}
              text={t("chat")}
              onClick={() =>
                trackRedirect(
                  MixPanelEventNames.ProjectDashboardTopChat,
                  chatUrl,
                  {
                    component: "Chat icon"
                  }
                )
              }
              icon={<AntDIcon name="forum" />}
            />
          </div>
        )}
        <Button
          type="primary"
          onClick={openExpertsPanel}
          icon={<AntDIcon name="add" />}
        >
          {t("addExperts")}
        </Button>
      </div>
      <SlidePanel
        panelId={PanelId.AssignExperts}
        width={SIDE_PANEL_SIZE._400}
        closeButtonDirection="right"
        title={t("addExperts")}
        hideHeader
        noPadding
      >
        <ExpertInvitePanel
          projectId={projectId}
          createExpertRoute={NEW_EXPERT}
        />
      </SlidePanel>
    </>
  );
};
