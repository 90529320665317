import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import AdminCard from "../../card/AdminCard";

interface TimesheetCardProps {
  title: string;
  hours: number;
}

type TimesheetCardPropsIntersectProps = TimesheetCardProps & WithTranslation;

const TimesheetCard = ({
  title,
  hours,
  t
}: TimesheetCardPropsIntersectProps): JSX.Element => {
  const isComingSoon = true;
  return (
    <>
      <AdminCard title={title}>
        {isComingSoon ? (
          <span>{t("comingSoon")}</span>
        ) : (
          `${t("billHours")}:${hours}`
        )}
      </AdminCard>
    </>
  );
};

export default withTranslation("projectBasePage")(TimesheetCard);
