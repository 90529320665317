/* eslint-disable @typescript-eslint/no-empty-function */
import { push } from "connected-react-router";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Row } from "reactstrap";
import { Dispatch } from "redux";
import { createStructuredSelector } from "reselect";

import { ExpertsListPageTab, ReferralSummary } from "@arbolus-technologies/api";

import { PROJECT_EXPERTS_ROUTE } from "../../../../../constants/navigation/projectRoutes";
import AccessManager from "../../../../../contexts/roleBasedAccess/AccessManager";
import { AppAction } from "../../../../../store/actions";
import { AppState } from "../../../../../store/reducers";
import { ProjectSelector } from "../../../store";
import ReferralStats from "./referralList/ReferralStats";

interface ProjectHeaderPaneStoreProps {
  isReferralSummaryLoading: boolean;
  referralSummary: ReferralSummary;
  projectId?: string;
}

interface ProjectHeaderPaneProps extends ProjectHeaderPaneStoreProps {
  navigateToExpertsReferral: (projectId: string) => void;
}

const ProjectHeaderPane = ({
  isReferralSummaryLoading,
  navigateToExpertsReferral,
  projectId,
  referralSummary
}: ProjectHeaderPaneProps): JSX.Element => {
  const { t } = useTranslation("projectBasePage");

  return (
    <Row>
      <div className="section">
        <h1 className="engage-title">{t("engage")}</h1>
      </div>
      <AccessManager permission="projectBase:referralStatIndication">
        <ReferralStats
          referralSummary={referralSummary}
          isReferralSummaryLoading={isReferralSummaryLoading}
          projectId={projectId!}
          onReferralStateClicked={navigateToExpertsReferral}
        />
      </AccessManager>
    </Row>
  );
};

const mapStateToProps = createStructuredSelector<
  AppState,
  ProjectHeaderPaneStoreProps
>({
  isReferralSummaryLoading:
    ProjectSelector.projectReferralSummaryLoadingSelector(),
  projectId: ProjectSelector.projectIdSelector(),
  referralSummary: ProjectSelector.projectReferralSummarySelector()
});

const mapDispatchToProps = (dispatch: Dispatch): Record<string, AppAction> => ({
  navigateToExpertsReferral: (
    projectId: string,
    referralState?: ExpertsListPageTab
  ): AppAction =>
    dispatch(push(PROJECT_EXPERTS_ROUTE(projectId, referralState)))
});

ProjectHeaderPane.defaultProps = {
  isReferralSummaryLoading: false,
  projectId: "",
  navigateToExpertsReferral: (): void => {},
  referralSummary: {} as ReferralSummary
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectHeaderPane);
