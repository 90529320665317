import { LOAD_REFERRAL_TAB_COUNT_SUCCESS } from "../actions/Referral/ReferralActionTypes";
import {
  ProjectNxReducerState,
  ReferralsTabCount
} from "../models/definitions";

interface ReferralHandlersI {
  type: typeof LOAD_REFERRAL_TAB_COUNT_SUCCESS;
  payload: ReferralsTabCount;
}

export const ReferralHandlers = {
  [LOAD_REFERRAL_TAB_COUNT_SUCCESS]: (
    state: ProjectNxReducerState,
    action: ReferralHandlersI
  ) => ({
    ...state,
    referrals: { ...state.referrals, tabCount: action.payload }
  })
};
