import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { SlidePanel } from "@arbolus-technologies/features/common";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ProjectNotificationsButton } from "@arbolus-technologies/ui/components";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { ProjectNotificationsForm } from "../ProjectNotificationsForm/ProjectNotificationsForm";

interface ProjectNotificationsSlidePanelProps {
  projectId: string;
}

export const ProjectNotificationsSlidePanel: React.FC<
  ProjectNotificationsSlidePanelProps
> = ({ projectId }) => {
  const { t } = useTranslation("projectNotificationsSlidePanel");
  const dispatch = useDispatch();

  const closePanel = useCallback(() => {
    dispatch(PanelStoreActions.closePanel(PanelId.ProjectNotifications));
  }, [dispatch]);

  return (
    <>
      <ProjectNotificationsButton
        onClick={() => {
          dispatch(PanelStoreActions.openPanel(PanelId.ProjectNotifications));
        }}
      />

      <SlidePanel
        title={t("title")}
        panelId={PanelId.ProjectNotifications}
        closeButtonDirection="right"
        width={SIDE_PANEL_SIZE._400}
      >
        <ProjectNotificationsForm onCancel={closePanel} projectId={projectId} />
      </SlidePanel>
    </>
  );
};
