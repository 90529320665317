import { CSSProperties, ReactNode, createElement } from "react";
import { ExternalToast, toast } from "sonner";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { ApiError } from "../ApiError";
import {
  CIQError,
  CompleteValidatorErrors,
  ErrorResponse
} from "../models/api";

type Position =
  | "top-left"
  | "top-right"
  | "bottom-left"
  | "bottom-right"
  | "top-center"
  | "bottom-center";

const DEFAULT_DURATION = 4000;

const TOAST_CONFIG: ExternalToast = {
  duration: DEFAULT_DURATION,
  position: "bottom-right"
};

export class ToasterService {
  public showSuccess = (message: string, position?: Position): void => {
    this.internalShowSuccess(message, false, position);
  };

  public showSuccessFixed = (message: string, position?: Position): void => {
    this.internalShowSuccess(message, true, position);
  };

  public showError = (message: string, position?: Position): void => {
    this.internalShowError(message, false, position);
  };

  public showErrorFixed = (message: string, position?: Position): void => {
    this.internalShowError(message, true, position);
  };

  public showWarning = (message: string, position?: Position): void => {
    this.internalShowWarning(message, false, position);
  };

  public showWarningFixed = (message: string, position?: Position): void => {
    this.internalShowWarning(message, true, position);
  };

  public showInfo = (message: string, position?: Position): void => {
    this.internalShowInfo(message, false, position);
  };

  public showInfoFixed = (message: string, position?: Position): void => {
    this.internalShowInfo(message, true, position);
  };

  public showStandard = (message: string, position?: Position): void => {
    this.internalShowStandard(message, false, position);
  };

  public showStandardFixed = (message: string, position?: Position): void => {
    this.internalShowStandard(message, true, position);
  };

  public showCustom = (body: JSX.Element, position?: Position): void => {
    this.internalShowCustom(body, false, position);
  };

  public showCustomFixed = (body: JSX.Element, position?: Position): void => {
    this.internalShowCustom(body, true, position);
  };

  public showApiErrors = (
    error: ErrorResponse<CIQError> | CompleteValidatorErrors | ApiError
  ): void => {
    const apiError = error instanceof ApiError ? error : new ApiError(error);
    apiError.errorMessages.forEach((message) => this.showError(message));
  };

  public showAction = (
    message: string,
    action: {
      label: ReactNode;
      onClick: () => void;
    },
    position?: Position
  ): void => {
    this.internalShowAction(message, false, action, position);
  };

  public showActionFixed = (
    message: string,
    action: {
      label: ReactNode;
      onClick: () => void;
    },
    position?: Position
  ): void => {
    this.internalShowAction(message, true, action, position);
  };

  private internalShowSuccess = (
    message: string,
    isFixed: boolean,
    position?: Position
  ): void => {
    toast.dismiss();
    toast(message, {
      ...TOAST_CONFIG,
      icon: this.icon("check_circle"),
      style: this.styles("#FFFFFF", "#3DB05D", "12px", "1px solid #3DB05D"),
      closeButton: true,
      duration: this.isFixedDuration(isFixed),
      position: this.isCustomPosition(position)
    });
  };

  private internalShowError = (
    message: string,
    isFixed: boolean,
    position?: Position
  ): void => {
    toast.dismiss();
    toast(message, {
      ...TOAST_CONFIG,
      icon: this.icon("cancel"),
      style: this.styles("#FFFFFF", "#FF5A3A", "12px", "1px solid #FF5A3A"),
      closeButton: this.isFixedCloseButton(isFixed),
      duration: this.isFixedDuration(isFixed),
      position: this.isCustomPosition(position)
    });
  };

  private internalShowWarning = (
    message: string,
    isFixed: boolean,
    position?: Position
  ): void => {
    toast.dismiss();
    toast(message, {
      ...TOAST_CONFIG,
      icon: this.icon("error"),
      style: this.styles("#FFFFFF", "#F78104", "12px", "1px solid #F78104"),
      closeButton: this.isFixedCloseButton(isFixed),
      duration: this.isFixedDuration(isFixed),
      position: this.isCustomPosition(position)
    });
  };

  private internalShowInfo = (
    message: string,
    isFixed: boolean,
    position?: Position
  ): void => {
    toast.dismiss();
    toast(message, {
      ...TOAST_CONFIG,
      icon: this.icon("info"),
      style: this.styles("#FFFFFF", "#4E46CA", "12px", "1px solid #4E46CA"),
      closeButton: this.isFixedCloseButton(isFixed),
      duration: this.isFixedDuration(isFixed),
      position: this.isCustomPosition(position)
    });
  };

  private internalShowStandard = (
    message: string,
    isFixed: boolean,
    position?: Position
  ): void => {
    toast.dismiss();
    toast(message, {
      ...TOAST_CONFIG,
      style: this.styles("#FFFFFF", "#000A3F", "12px"),
      closeButton: this.isFixedCloseButton(isFixed),
      duration: this.isFixedDuration(isFixed),
      position: this.isCustomPosition(position)
    });
  };

  private internalShowCustom = (
    body: JSX.Element,
    isFixed: boolean,
    position?: Position
  ): void => {
    toast.dismiss();
    toast(body, {
      ...TOAST_CONFIG,
      style: this.styles("#FFFFFF", "#000A3F", "12px"),
      closeButton: this.isFixedCloseButton(isFixed),
      duration: this.isFixedDuration(isFixed),
      position: this.isCustomPosition(position)
    });
  };

  private internalShowAction = (
    message: string,
    isFixed: boolean,
    action: {
      label: ReactNode;
      onClick: () => void;
    },
    position?: Position
  ): void => {
    toast.dismiss();
    toast(message, {
      ...TOAST_CONFIG,
      style: this.styles("#FFFFFF", "#000A3F", "12px"),
      action: {
        ...action,
        label: `${action.label} >`
      },
      closeButton: this.isFixedCloseButton(isFixed),
      duration: this.isFixedDuration(isFixed),
      position: this.isCustomPosition(position),
      actionButtonStyle: {
        background: ARBOLUS_COLORS.bColorBaseWhite,
        color: ARBOLUS_COLORS.bColorBasePurple,
        fontSize: "12px",
        textTransform: "capitalize"
      }
    });
  };

  private icon = (icon: string): ReactNode =>
    createElement(
      "span",
      {
        className: "material-symbols-sharp icon",
        style: {
          fontSize: "24px"
        }
      },
      icon
    );

  private isCustomPosition = (position?: Position) =>
    position ? position : "bottom-right";
  private isFixedCloseButton = (isFixed: boolean) => (isFixed ? true : false);
  private isFixedDuration = (isFixed: boolean) =>
    isFixed ? Infinity : DEFAULT_DURATION;

  private styles = (
    backgroundColor: string,
    color: string,
    fontSize: string,
    border?: string
  ): CSSProperties => ({
    backgroundColor,
    color,
    fontSize,
    border,
    textAlign: "left",
    gap: "16px"
  });
}

export const DefaultToasterService = new ToasterService();
