import React from "react";
import { useTranslation } from "react-i18next";

import { MAIN_PROJECTS_ROUTE } from "@arbolus-technologies/routes";
import { useDocumentTitle } from "@arbolus-technologies/utils";

import { ProjectDraftsTable } from "../Modules/ProjectsTable/ProjectDraftsTable";
import { ProjectListLayout } from "../Modules/ProjectsTable/ProjectListLayout";

export const ProjectDraftListPage: React.FC = () => {
  const { t } = useTranslation("projectsTable");
  useDocumentTitle("projectsTable", "title");

  return (
    <ProjectListLayout title={t("draftTitle")} to={MAIN_PROJECTS_ROUTE()}>
      <ProjectDraftsTable />
    </ProjectListLayout>
  );
};
