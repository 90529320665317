import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./OutlinedChip.module.scss";

interface OutlinedChipProps {
  color: ARBOLUS_COLORS;
  text: string;
  fontSize?: "12px" | "13px" | "14px" | "16px";
}

export const OutlinedChip: React.FC<OutlinedChipProps> = ({
  color: borderColor,
  text,
  fontSize = "inherit"
}) => (
  <span className={styles.outline} style={{ borderColor, fontSize }}>
    {text}
  </span>
);
