export enum INCIDENT_CATEGORY {
  CALL = "Calls",
  CANOPY = "Canopy",
  CLIENTS = "Clients",
  EXPERTS = "Experts",
  NOTIFICATIONS = "Notifications",
  TRANSACTIONS = "Transactions",
  USERS = "Users",
  OTHER = "Other",
  PROJECTS = "Projects",
  DASHBOARDS = "Dashboards"
}

export enum INCIDENT_PRIORITY {
  Lowest = "Lowest",
  Low = "Low",
  Medium = "Medium",
  High = "High",
  Highest = "Highest"
}

export enum INCIDENT_FORM {
  CATEGORY = "category",
  PRIORITY = "priority",
  TITLE = "title",
  OVERVIEW = "overview",
  ENTITY = "entity",
  ENTITY_ID = "entityId",
  ATTACHMENTS = "attachments"
}

export enum DNCFormEnum {
  DNC_STATUS = "dncStatus",
  DNC_CATEGORY = "dncCategory",
  DNC_NOTE = "dncNote"
}
