import i18next from "i18next";

import { ClassificationType } from "@arbolus-technologies/models/common";

interface ClassificationTypesValuesProps {
  value: string;
  label: string;
}

export const classificationTypesValues: ClassificationTypesValuesProps[] =
  Object.values(ClassificationType).map((type) => ({
    value: type,
    label: i18next.t(`classificationTypes:${type}`)
  }));
