import { useMemo } from "react";
import { useSelector } from "react-redux";

import { CacheSelector } from "@arbolus-technologies/stores/cache";

export function useDisplayTimezone(timezone: string): string {
  const timezones = useSelector(
    CacheSelector.appTimezoneSelectOptionMapSelector()
  );

  const displayTimezone = useMemo(
    () => timezones.get(timezone)?.customLabel || "",
    [timezones, timezone]
  );

  return displayTimezone;
}
