import { Button } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import {
  fiveSlotsIcon,
  fourSlotsIcon,
  oneSlotIcon,
  threeSlotsIcon,
  twoSlotsIcon,
  zeroSlotsIcon
} from "@arbolus-technologies/theme";
import { IconCircleFeedback } from "@arbolus-technologies/ui/components";

import styles from "./ExpertAvailabilitySchedulerFooter.module.scss";

const icons = {
  icon_0: zeroSlotsIcon,
  icon_1: oneSlotIcon,
  icon_2: twoSlotsIcon,
  icon_3: threeSlotsIcon,
  icon_4: fourSlotsIcon,
  icon_5: fiveSlotsIcon
};

interface ExpertAvailabilitySchedulerFooterProps {
  slotsCount: number;
  onSave: () => void;
  isUpdate: boolean;
  isAdmin: boolean;
  isDisabled: boolean;
  hasAdditionalTimeSlotsRequested: boolean;
  isApplicationProcess?: boolean;
}

const ExpertAvailabilitySchedulerFooter: React.FC<
  ExpertAvailabilitySchedulerFooterProps
> = ({
  slotsCount,
  onSave,
  isUpdate,
  isAdmin,
  isDisabled,
  hasAdditionalTimeSlotsRequested,
  isApplicationProcess
}) => {
  const { t } = useTranslation("expertAvailabilityScheduler");
  const initialIcon = slotsCount < 5 ? "icon_" + slotsCount : "icon_5";
  const icon = icons[initialIcon as keyof typeof icons];

  const getSaveButtonLabel = () => {
    if (hasAdditionalTimeSlotsRequested) {
      return t("confirm");
    }
    if (isUpdate) {
      return t("update");
    }
    return "";
  };

  return (
    <div
      className={clsx(
        styles.expertAvailabilitySchedulerFooter,
        isMobile ? styles.mobileOnly : "",
        isApplicationProcess ? styles.isApplicationProcess : ""
      )}
    >
      <div className={styles.footerLeft}>
        {!isAdmin && (
          <IconCircleFeedback icon={icon} message={t("dynamicMessage")} />
        )}
      </div>
      {!isApplicationProcess && (
        <Button
          onClick={onSave}
          disabled={isDisabled}
          text={getSaveButtonLabel()}
        />
      )}
    </div>
  );
};

export default ExpertAvailabilitySchedulerFooter;
