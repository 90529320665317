/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from "clsx";
import React, { useEffect } from "react";
import { Control, UseFieldArrayReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { InputController } from "../InputController/InputController";

import styles from "./MultiTextInputController.module.scss";

interface MultiTextInputControllerProps {
  arrMethods: UseFieldArrayReturn<any, "questions", "id">;
  errors: any;
  watch: any;
  control: Control<any>;
  disabled?: boolean;
}

export const MultiTextInputController: React.FC<
  MultiTextInputControllerProps
> = ({ arrMethods, errors, control, watch, disabled = false }) => {
  const { fields, remove, append } = arrMethods;
  const { t } = useTranslation("projectNx");

  useEffect(() => {
    if (fields.length === 0) {
      append({ question: "" });
    }
  }, [fields, append]);

  const isDisabled =
    watch("questions").some((e: { question: string }) => e.question === "") ||
    fields.length >= 15;

  return (
    <div className={styles.fieldsContainer}>
      <span className={styles.title}>{t("questionsTitle")}</span>
      {fields.map((field, index) => (
        <div key={field.id}>
          <div className={styles.questionInfo}>
            <span>
              {t("question")} #{index + 1}
            </span>
            <span
              onClick={() => fields.length > 1 && remove(index)}
              className={clsx(
                "ciq-icon ciq-trash",
                styles.trash,
                disabled && styles.disabledElement
              )}
            />
          </div>
          <InputController
            name={`questions.${index}.question`}
            error={errors.questions?.[index]?.question}
            control={control}
            placeholder={""}
            type="textarea"
            disabled={disabled}
          />
        </div>
      ))}
      <button
        disabled={isDisabled || disabled}
        className={clsx(
          styles.addQuestion,
          isDisabled && styles.disabled,
          disabled && styles.disabledElement
        )}
        onClick={() => append({ question: "" })}
      >
        {t("addQuestion")}
      </button>
    </div>
  );
};
