import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  PROJECT_REFERRAL_STATE,
  ProjectService,
  RecommendationResponse
} from "@arbolus-technologies/api";
import { AddEditAngleSlidePanel } from "@arbolus-technologies/features/common";
import { ProjectExpertsStoreActions } from "@arbolus-technologies/stores/project-experts-store";
import { statusExpertShortList } from "@arbolus-technologies/theme";
import { ThreeDots } from "@arbolus-technologies/ui/components";

export const RecommendationsDropdown: React.FC<{
  row: RecommendationResponse;
  projectId: string;
}> = ({ row, projectId }) => {
  const { t } = useTranslation("projectExpertListMenu");
  const dispatch = useDispatch();
  const [isAnglePanelOpen, setIsAnglePanelOpen] = useState(false);
  const [referralId, setReferralId] = useState<string>("");
  const expert = {
    id: row.id,
    firstName: row.firstName,
    lastName: row.lastName,
    title: row.title,
    email: "", // Not needed
    profileImageUrl: row.profileImageUrl || ""
  };

  const handleMoveToShortlist = () => {
    ProjectService.addExpertReferral(
      projectId,
      row.id,
      PROJECT_REFERRAL_STATE.SHORTLIST
    ).subscribe(
      ({ id }) => {
        setReferralId(id);
        setIsAnglePanelOpen(true);
      },
      (err: ErrorResponse<CIQError>) => {
        DefaultToasterService.showError(err.message);
      }
    );
  };

  const popoverId = `popover_recommendations_${row.id}`;

  const items = [
    {
      title: t("moveToShortList"),
      icon: "status-expert-shortlist",
      iconComponent: () => (
        <img
          src={statusExpertShortList}
          alt={t("moveExpertToCandidateSuccess")}
        />
      ),
      onClick: handleMoveToShortlist
    }
  ];

  const handleAfterApply = () => {
    dispatch(ProjectExpertsStoreActions.getProjectSummary(projectId));
    dispatch(ProjectExpertsStoreActions.getRecommendations(projectId));
    setIsAnglePanelOpen(false);
  };

  return (
    <>
      <ThreeDots popoverId={popoverId} items={items} />
      <AddEditAngleSlidePanel
        panelOpen={isAnglePanelOpen}
        setPanelOpen={setIsAnglePanelOpen}
        isEdit={false}
        projectId={projectId}
        referralId={referralId}
        expert={expert}
        keepReferralStatus
        removeFromProjectOnCancel
        onAfterApply={handleAfterApply}
      />
    </>
  );
};
