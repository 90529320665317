import React from "react";

import styles from "./DemoVideoPopUp.module.scss";

interface TextIconItemProps {
  text: string;
}
export const TextIconItem: React.FC<TextIconItemProps> = ({ text }) => (
  <div className={styles.wrapperDescription2}>
    <span className={styles.iconSize} role="img" aria-label="check">
      ✅
    </span>
    <p className={styles.textColor}>{text}</p>
  </div>
);
