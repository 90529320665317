import { User } from "./user";

export interface Document {
  id: string;
  fileName: string;
  fileSize: number;
  uploadedUserId: string;
  created: Date;
  modified: Date;
  uploadedUser: User;
  type: FILE_TYPE;
  durationSeconds?: number;
  transcriptId?: string;
}

export enum FILE_TYPE {
  TRANSCRIPT = "Transcription",
  DOCUMENT = "Document"
}

export interface DocumentDownloadResponse {
  downloadUrl: string;
  id: string;
  fileName: string;
  uploadedUserId: string;
  uploadedUser: User;
  created: Date;
  modified: Date;
}

export interface ExpertDocument {
  workspaceId: string;
  firstName: string;
  lastName: string;
  profileImageUrl: string;
  shared: boolean;
  workspaceType: string;
}

export interface DocumentStatusChangeResponse {
  id: string;
}

export interface DocumentStatusChangeRequest {
  message: string;
  documentIds: string[];
}
