/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";

import {
  CIQError,
  ErrorResponse,
  ExpertListProject,
  ExpertService,
  FilterCriteria,
  ListResponse,
  ToasterService
} from "@arbolus-technologies/api";
import {
  Loader,
  TableTextFilter,
  TanstackTable
} from "@arbolus-technologies/ui/components";
import { normalize } from "@arbolus-technologies/utils";

import { ProjectIcons } from "../../Components/ProjectDetails/ProjectIcons";
import { ProjectName } from "../../Components/ProjectDetails/ProjectName";
import { EXPERT_PROJECT_FILTER_CRITERIA } from "../../helpers/constants";

import styles from "./Projects.module.scss";

export interface ProjectsProps {
  expertId: string;
  expertService?: typeof ExpertService;
}

const notificationService = new ToasterService();

export const Projects: React.FC<ProjectsProps> = ({
  expertId,
  expertService = ExpertService
}) => {
  const { t } = useTranslation("expertProjects");

  const [projects, setProjects] = useState<ExpertListProject[]>([]);
  const [filteredProjects, setFilteredProjects] = useState<ExpertListProject[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSortFilterOpen, setIsSortFilterOpen] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<string>(
    EXPERT_PROJECT_FILTER_CRITERIA.ALL.name
  );

  useEffect(() => {
    const fetchProjects = (): void => {
      setIsLoading(true);

      expertService.getExpertProjects(expertId).subscribe(
        (res: ListResponse<ExpertListProject>) => {
          setProjects(res.items || []);
          setFilteredProjects(res.items || []);
          setIsLoading(false);
        },
        (error: ErrorResponse<CIQError>) => {
          setIsLoading(false);
          notificationService.showError(error.message);
        }
      );
    };

    fetchProjects();
  }, [expertId, expertService]);

  const handleDropdownToggleEvent = (): void => {
    setIsSortFilterOpen((prevState) => !prevState);
  };

  const renderProjectsContent = (): JSX.Element => {
    if (isLoading) {
      return <Loader isSidePanel />;
    }

    if (filteredProjects.length === 0) {
      return <div className={styles.noResultContainer}>{t("noProjects")}</div>;
    }

    const columnDefinitions = [
      {
        name: "project",
        component: (props: any) => (
          <TableTextFilter
            {...props}
            displayName="Project"
            inputClassName={styles.inputClassName}
          />
        ),
        cellComponent: ProjectName,
        filterFn: "includesString",
        accessorFn: (row: ExpertListProject) => normalize(row.project.name),
        size: "45%"
      },
      {
        name: "client",
        component: (props: any) => (
          <TableTextFilter
            {...props}
            displayName="Client"
            inputClassName={styles.inputClassName}
          />
        ),
        cellComponent: ({ row }: { row: ExpertListProject }) => (
          <div className={styles.clientName}>{row.project.client.name}</div>
        ),
        filterFn: "includesString",
        accessorFn: (row: ExpertListProject) =>
          normalize(row.project.client.name),
        size: "30%"
      },
      {
        name: "moreOptions",
        component: () => <div />,
        cellComponent: ProjectIcons,
        size: "20%"
      }
    ];

    return (
      <TanstackTable
        defaultData={filteredProjects}
        columnDefinitions={columnDefinitions}
      />
    );
  };

  const handleFilterChange = (filterCriteria: FilterCriteria) => {
    setFilterValue(filterCriteria.name);
    const filterProjects = filterCriteria.value
      ? projects.filter((project) => project.status === filterCriteria.value)
      : projects;
    setFilteredProjects(filterProjects);
  };

  return (
    <div className={styles.projectsContainer}>
      <Dropdown
        isOpen={isSortFilterOpen}
        toggle={handleDropdownToggleEvent}
        className="d-flex"
        data-testid="status-dropdown"
      >
        <DropdownToggle caret>{filterValue}</DropdownToggle>
        <DropdownMenu right>
          <DropdownItem
            onClick={(): void =>
              handleFilterChange(EXPERT_PROJECT_FILTER_CRITERIA.ALL)
            }
          >
            {EXPERT_PROJECT_FILTER_CRITERIA.ALL.name}
          </DropdownItem>
          <DropdownItem
            onClick={(): void =>
              handleFilterChange(EXPERT_PROJECT_FILTER_CRITERIA.ACCEPT)
            }
          >
            {EXPERT_PROJECT_FILTER_CRITERIA.ACCEPT.name}
          </DropdownItem>
          <DropdownItem
            onClick={(): void =>
              handleFilterChange(EXPERT_PROJECT_FILTER_CRITERIA.PENDING)
            }
          >
            {EXPERT_PROJECT_FILTER_CRITERIA.PENDING.name}
          </DropdownItem>
          <DropdownItem
            onClick={(): void =>
              handleFilterChange(EXPERT_PROJECT_FILTER_CRITERIA.REJECT)
            }
          >
            {EXPERT_PROJECT_FILTER_CRITERIA.REJECT.name}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <div className={styles.projectList}>{renderProjectsContent()}</div>
    </div>
  );
};
