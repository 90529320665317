import React from "react";
import { useSelector } from "react-redux";

import {
  ArbolusGPTButton,
  CreateSimpleCanopyButton,
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { PROJECT_ROUTE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";

import styles from "./HeaderButtons.module.scss";

interface HeaderButtonsProps {
  projectId?: string;
}

export const HeaderButtons: React.FC<HeaderButtonsProps> = ({ projectId }) => {
  const { trackClick } = useArbolusTracking();

  const isAdmin = useSelector(CacheSelector.isAdmin());
  const projectName = useSelector(ProjectNxSelector.projectName());
  const loggedUserMail = useSelector(CacheSelector.userProfile()).email;

  return (
    <div className={styles.btnContainer}>
      {projectId && (
        <ArbolusGPTButton
          projectPath={`${PROJECT_ROUTE(projectId).pathname} | ${projectName}>`}
          projectId={projectId}
          onTrack={() =>
            trackClick(MixPanelEventNames.CanopiesListProjectArbolusGPT, {
              loggedUserMail
            })
          }
        />
      )}
      {(projectId || !isAdmin) && (
        <CreateSimpleCanopyButton
          buttonType="default"
          onTrack={() => trackClick(MixPanelEventNames.CanopyListTopNewCanopy)}
        />
      )}
    </div>
  );
};
