import dompurify from "dompurify";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { ProjectCompliance } from "@arbolus-technologies/api";
import { SlidePanel } from "@arbolus-technologies/features/common";
import { COMPLIANCE_TYPE } from "@arbolus-technologies/models/project";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  CircleWithIcon,
  Drawer,
  PublicCompanyBadge
} from "@arbolus-technologies/ui/components";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { PanelComplianceQuestion } from "./Components/PanelComplianceQuestion";

import styles from "./ComplianceSlidePanel.module.scss";

interface ComplianceSlidePanelProps {
  projectCompliance: ProjectCompliance;
}

export const ComplianceSlidePanel: React.FC<ComplianceSlidePanelProps> = ({
  projectCompliance
}) => {
  const { t } = useTranslation("complianceSlidePanel");
  const {
    complianceQuestionSettings,
    complianceWarningNote,
    publicCompaniesPeriod
  } = projectCompliance;
  const hasCompliance =
    complianceQuestionSettings.complianceType !== COMPLIANCE_TYPE.NONE ||
    complianceWarningNote ||
    publicCompaniesPeriod;

  const dispatch = useDispatch();

  const closePanel = useCallback(() => {
    dispatch(PanelStoreActions.closePanel(PanelId.Compliance));
  }, [dispatch]);

  return (
    <SlidePanel
      panelId={PanelId.Compliance}
      width={SIDE_PANEL_SIZE._400}
      title={t("title")}
      closeButtonDirection="right"
      customCloseRequest={closePanel}
    >
      <div className={styles.container}>
        <div className={styles.complianceHeader}>
          <CircleWithIcon
            icon="error"
            fontSize="16px"
            customHeight="24px"
            customWidth="24px"
            color={ARBOLUS_COLORS.bColorBaseWhite}
            background={
              hasCompliance
                ? ARBOLUS_COLORS.bColorBaseOrange
                : ARBOLUS_COLORS.bColorBasePurple
            }
          />
          <h3>{t(hasCompliance ? "complianceActive" : "noCompliance")}</h3>
        </div>
        {complianceWarningNote && (
          <Drawer title={t("complianceMessage")}>
            <div
              className={styles.complianceMessage}
              dangerouslySetInnerHTML={{
                __html: dompurify.sanitize(complianceWarningNote)
              }}
            />
          </Drawer>
        )}
        {publicCompaniesPeriod !== null && (
          <Drawer title={t("publicCompanies")}>
            <PublicCompanyBadge />
            <p className={styles.publicCompaniesMessage}>
              {publicCompaniesPeriod > 0
                ? t("publicCompaniesMessage", {
                    months: publicCompaniesPeriod
                  })
                : t("currentPublicCompaniesMessage")}
            </p>
          </Drawer>
        )}
        {complianceQuestionSettings.complianceType !== COMPLIANCE_TYPE.NONE && (
          <Drawer
            title={t("complianceQuestions", {
              type: complianceQuestionSettings.complianceType
            })}
          >
            <div className={styles.questionsContainer}>
              {complianceQuestionSettings.complianceQuestions?.map(
                (question) => (
                  <PanelComplianceQuestion
                    key={question.id}
                    question={question}
                  />
                )
              )}
            </div>
          </Drawer>
        )}
      </div>
    </SlidePanel>
  );
};
