import { DocumentType } from "./enums";

export const extensionToIconMap = new Map([
  ["pdf", DocumentType.PDF],
  ["xls", DocumentType.XLS],
  ["csv", DocumentType.CSV],
  ["ppt", DocumentType.PRESENTATION],
  ["pptx", DocumentType.PRESENTATION],
  ["doc", DocumentType.DOC],
  ["docx", DocumentType.DOC],
  ["zip", DocumentType.ZIP],
  ["7zip", DocumentType.ZIP],
  ["rar", DocumentType.ZIP],
  ["xlsx", DocumentType.EXCEL],
  ["xlt", DocumentType.EXCEL],
  ["mng", DocumentType.IMAGE],
  ["tiff", DocumentType.IMAGE],
  ["ico", DocumentType.IMAGE],
  ["bmp", DocumentType.IMAGE],
  ["png", DocumentType.IMAGE],
  ["jpeg", DocumentType.IMAGE],
  ["jpg", DocumentType.IMAGE],
  ["gif", DocumentType.IMAGE],
  ["event", DocumentType.EVENT],
  ["Transcription", DocumentType.TRANSCRIPT],
  ["txt", DocumentType.GENERIC]
]);
