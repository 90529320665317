import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Col, Row } from "reactstrap";

import { ExpertsListPageTab, ReferralSummary } from "@arbolus-technologies/api";
import { ProjectExpertsStoreActions } from "@arbolus-technologies/stores/project-experts-store";

import { CANDIDATE_PICKER_PANEL_ROUTE } from "../../../../../../constants/navigation/panelRoutes";

interface ReferralStatsProps {
  referralSummary: ReferralSummary;
  isReferralSummaryLoading: boolean;
  projectId: string;
  onReferralStateClicked: (projectId: string, referralState?: string) => void;
}

const ReferralStats = ({
  referralSummary: { review, accept, allExperts },
  projectId,
  isReferralSummaryLoading,
  onReferralStateClicked
}: ReferralStatsProps): JSX.Element => {
  const { t } = useTranslation("referralStats");
  const history = useHistory();
  const dispatch = useDispatch();

  const handleReferralStateClicked = (
    e: React.MouseEvent,
    state: ExpertsListPageTab
  ): void => {
    dispatch(ProjectExpertsStoreActions.setExpertListTab(state));
    onReferralStateClicked(projectId, state);
    e.stopPropagation();
  };

  return (
    <Col xl="12" lg="12" md="12" sm="12" xs="12">
      <div
        className="project-referral-stats"
        onClick={(e): void =>
          handleReferralStateClicked(e, ExpertsListPageTab.Book)
        }
      >
        <Row>
          <Col>
            <h3>Experts</h3>
            <div className="stat-container">
              {!!review && (
                <div
                  className="stat-tag fill orange"
                  onClick={(e: React.MouseEvent): void => {
                    e.stopPropagation();
                    history.push(CANDIDATE_PICKER_PANEL_ROUTE());
                  }}
                >
                  <div className="stat-info">
                    <div className="referral-stat-indicator review" />
                    <span>{t("reviewNow")}</span>
                  </div>
                  <span className="stat-value">
                    {isReferralSummaryLoading ? "..." : review}
                  </span>
                </div>
              )}

              <div
                className="stat-tag"
                onClick={(e): void =>
                  handleReferralStateClicked(e, ExpertsListPageTab.AllExperts)
                }
              >
                <div className="stat-info">
                  <div className="referral-stat-indicator more" />
                  <span>{t("allExperts")}</span>
                </div>
                <span className="stat-value">
                  {isReferralSummaryLoading ? "..." : allExperts}
                </span>
              </div>

              <div
                className="stat-tag"
                onClick={(e): void =>
                  handleReferralStateClicked(e, ExpertsListPageTab.Accept)
                }
              >
                <div className="stat-info">
                  <div className="referral-stat-indicator approve" />
                  <span>{t("approved")}</span>
                </div>
                <span className="stat-value">
                  {isReferralSummaryLoading ? "..." : accept}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default ReferralStats;
