import { Icon } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { SlidePanel } from "@arbolus-technologies/features/common";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { FilesList } from "./FilesList/FilesList";

import styles from "./AttachFilesButton.module.scss";

interface AttachFilesButtonProps {
  projectId: string;
  disabled: boolean;
}
export const AttachFilesButton: React.FC<AttachFilesButtonProps> = ({
  projectId,
  disabled
}) => {
  const { t } = useTranslation("chat");
  const dispatch = useDispatch();

  const handleOnClickAttachFiles = (): void => {
    dispatch(PanelStoreActions.openPanel(PanelId.AttachFiles));
  };

  return (
    <div className={styles.attachButton}>
      <Icon
        name="attach_file"
        fontSize="24px"
        color={
          disabled
            ? ARBOLUS_COLORS.bColorSecondaryGreyMid
            : ARBOLUS_COLORS.bColorBasePurple
        }
        onClick={handleOnClickAttachFiles}
        disabled={disabled}
        id="attach_file"
      />
      <SlidePanel
        title={t("shareSidePanelTitle")}
        panelId={PanelId.AttachFiles}
        closeButtonDirection="right"
        width={SIDE_PANEL_SIZE._400}
      >
        <FilesList projectId={projectId} />
      </SlidePanel>
    </div>
  );
};
