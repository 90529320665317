import { Icon } from "arbolus-ui-components";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, InputGroup, InputGroupAddon } from "reactstrap";

import {
  ClientService,
  DefaultToasterService,
  IErrorResponse,
  ListProjectBase,
  ProjectService,
  ToasterService
} from "@arbolus-technologies/api";
import { Loader } from "@arbolus-technologies/ui/components";

import styles from "./ClientAddToProject.module.scss";

interface ClientAddToProjectProps {
  userId: string;
  clientId: string;
  onAddToProject?: () => void;
  notificationService?: ToasterService;
}

export const ClientAddToProject: React.FC<ClientAddToProjectProps> = ({
  userId,
  clientId,
  onAddToProject,
  notificationService = DefaultToasterService
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [projectsItems, setProjectsItems] = useState<ListProjectBase[]>([]);
  const [filteredProjectsItems, setFilteredProjectsItems] = useState<
    ListProjectBase[]
  >([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { t } = useTranslation("clientUserSlidePanel");

  const getProjects = useCallback(
    () =>
      ClientService.getTeamMembersCandidateProjects(clientId, userId).subscribe(
        ({ items }) => {
          setProjectsItems(items);
          setIsLoading(false);
        },
        (error: IErrorResponse) => {
          notificationService.showError(error.message);
          setIsLoading(false);
        }
      ),
    [clientId, userId, notificationService]
  );

  useEffect(() => {
    getProjects();
  }, [clientId, userId, getProjects]);

  useEffect(() => {
    if (searchTerm !== "") {
      const projects = projectsItems.filter((project) =>
        project.name
          .toLocaleLowerCase()
          .includes(searchTerm.toLocaleLowerCase())
      );
      setFilteredProjectsItems(projects);
    }
  }, [searchTerm, projectsItems]);

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (value === "") {
      setFilteredProjectsItems(projectsItems);
    }
    setSearchTerm(value);
  };

  const handleAddToProject = (id: string): void => {
    setIsLoading(true);

    ProjectService.addProjectMember(id, userId).subscribe(
      () => {
        notificationService.showSuccess(t("userAddedSuccessfully"));
        getProjects();
        onAddToProject?.();
      },
      (error: IErrorResponse) => {
        notificationService.showError(error.message);
        setIsLoading(false);
      }
    );
  };

  if (isLoading) {
    return <Loader isFull />;
  }

  // eslint-disable-next-line no-negated-condition
  const projects = searchTerm !== "" ? filteredProjectsItems : projectsItems;

  return (
    <>
      <InputGroup className={styles.searchInputWrapper}>
        <InputGroupAddon
          addonType="prepend"
          className={styles.iconSearchWrapper}
        >
          <Icon name="search" fontSize="20px" color="#bbbecb" />
        </InputGroupAddon>
        <Input
          autoComplete="off"
          placeholder={t("searchPlaceholder")}
          name="searchQuery"
          className={styles.searchInput}
          type={"text"}
          value={searchTerm}
          onChange={handleSearchInput}
        />
      </InputGroup>
      {projects.length > 0 ? (
        <div className={styles.itemWrapper}>
          {projects.map((project) => (
            <div
              key={project.id}
              className={styles.item}
              onClick={() => handleAddToProject(project.id)}
            >
              <span className={styles.itemName}>{project.name}</span>
              <Icon name="add" fontSize="24px" color="" />
            </div>
          ))}
        </div>
      ) : (
        <h2>{t("noResults")}</h2>
      )}
    </>
  );
};
