import clsx from "clsx";
import React, { ReactNode, useState } from "react";
import { Collapse } from "reactstrap";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./Drawer.module.scss";

interface DrawerProps {
  title?: string;
  subtitle?: string;
  children?: ReactNode;
  openByDefault?: boolean;
  headerNotCentered?: boolean; // Prop used to align arrow with title and subtitle at flex-start. If only title, centered with arrow by default.
  titleChildren?: (isDrawerOpen: boolean) => JSX.Element;
  disableCollapse?: boolean; // Ignored when openByDefault is false,
  backgroundColor?: ARBOLUS_COLORS;
  padding?: string;
}

export const Drawer: React.FC<DrawerProps> = ({
  title,
  subtitle,
  children,
  openByDefault = false,
  headerNotCentered = false,
  titleChildren,
  disableCollapse = false,
  backgroundColor = ARBOLUS_COLORS.bColorBaseWhite,
  padding = "24px"
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(openByDefault);

  const hideExpand = openByDefault && disableCollapse;
  const shouldHideHeader = hideExpand && !title && !titleChildren;

  const handleDrawerToggle = () => {
    !hideExpand && setIsDrawerOpen((prev) => !prev);
  };

  return (
    <div className={styles.container} style={{ backgroundColor, padding }}>
      {!shouldHideHeader && (
        <div
          className={clsx(
            styles.header,
            headerNotCentered && styles.headerNotCentered,
            hideExpand && styles.hideExpand
          )}
          onClick={handleDrawerToggle}
          onKeyDown={handleDrawerToggle}
        >
          <div
            className={clsx(
              styles.titleAndSubtitleContainer,
              hideExpand && styles.fullWidth
            )}
          >
            {titleChildren?.(isDrawerOpen) ?? (
              <h1 className={styles.title} title={title}>
                {title}
              </h1>
            )}
            {subtitle && (
              <h2 className={styles.subtitle} title={subtitle}>
                {subtitle}
              </h2>
            )}
          </div>

          {!hideExpand && (
            <span className="material-symbols-sharp align-self-start">
              {isDrawerOpen ? "expand_less" : "expand_more"}
            </span>
          )}
        </div>
      )}
      {children && (
        <div
          className={styles.collapseContainer}
          style={{ marginTop: isDrawerOpen && !shouldHideHeader ? 24 : 0 }}
        >
          <Collapse isOpen={isDrawerOpen}>{children}</Collapse>
        </div>
      )}
    </div>
  );
};
