import React from "react";

import { PaginationData } from "@arbolus-technologies/api";
import { ChevronButton } from "@arbolus-technologies/ui/components";

import styles from "./Pagination.module.scss";

interface PaginationProps extends Omit<PaginationData, "offset"> {
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  limit,
  count,
  pageNumber = 0,
  setPageNumber
}) => {
  const hasNextPage = (pageNumber + 1) * limit < count;

  const handlePrev = () => {
    setPageNumber(pageNumber - 1);
  };

  const handleNext = () => {
    setPageNumber(pageNumber + 1);
  };

  return (
    <div className={styles.paginationContainer}>
      <ChevronButton
        icon={{ position: "start", direction: "left", size: "24px" }}
        onClick={handlePrev}
        disabled={pageNumber === 0}
      />
      <ChevronButton
        icon={{ position: "start", direction: "right", size: "24px" }}
        onClick={handleNext}
        disabled={!hasNextPage}
      />
    </div>
  );
};
