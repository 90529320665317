import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Media from "react-media";
import { Table } from "reactstrap";
import { Subscription } from "rxjs";

import { SORT_DIRECTION, ToasterService } from "@arbolus-technologies/api";
import { Loader } from "@arbolus-technologies/ui/components";

import { MAX_PAGE_SIZE } from "../../../../../constants/api";
import {
  INBOX_NOTIFICATION_CATEGORY,
  MultipleInboxCategoryMap,
  NOTIFICATION_VERSION_TYPE
} from "../../../../../constants/notifications";
import {
  APP_DEVICE_MEDIA_QUERIES,
  UI_INBOX_PAGE
} from "../../../../../constants/ui";
import {
  ContentPanelContextConsumer,
  ContentPanelContextProps
} from "../../../../../contexts/contentPanel/ContentPanelContext";
import { CIQError, ErrorResponse } from "../../../../../models/api";
import { InboxNotification } from "../../../../../models/notifications";
import { NotificationsGetRequest } from "../../../../../models/user";
import { UserService } from "../../../../../services";
import { CIQInfiniteScroll } from "../../../../app/components";
import { CIQInfiniteScrollDirection } from "../../../../app/components/CustomInfiniteScroll";
import InboxCategoryList from "../CategoryListing";
import InboxEmptyState from "../emptyState/InboxEmptyState";
import NotificationItem from "../notificationItem/NotificationItem";

const notification = new ToasterService();

interface NotificationTabProps extends WithTranslation {
  category: INBOX_NOTIFICATION_CATEGORY;
  categories: INBOX_NOTIFICATION_CATEGORY[];
  activeCategory: INBOX_NOTIFICATION_CATEGORY;
  onMobileCategorySelect: (category: INBOX_NOTIFICATION_CATEGORY) => void;
  isActive: boolean;
  updateNotificationCounts: (categories: INBOX_NOTIFICATION_CATEGORY[]) => void;
  inboxCategoryCount: number;
}

interface NotificationTabState {
  isLoading: boolean;
  notifications: InboxNotification[];
  isMore: boolean;
  loadingIds: string[];
}

class NotificationTab extends React.Component<
  NotificationTabProps,
  NotificationTabState
> {
  constructor(props: NotificationTabProps) {
    super(props);

    this.state = {
      notifications: [],
      isLoading: false,
      isMore: true,
      loadingIds: []
    };
  }

  componentWillUnmount(): void {
    this.getNotificationsSubscription?.unsubscribe();
    this.deleteNotificationSubscription?.unsubscribe();
  }

  private getNotificationsSubscription?: Subscription;

  private deleteNotificationSubscription?: Subscription;

  private initialNotificationCount = MAX_PAGE_SIZE;

  fetchNotifications = (isPreFetch?: boolean): void => {
    const { category } = this.props;
    const { notifications } = this.state;
    const isInitialPage = notifications.length === 0;

    const requestParams: NotificationsGetRequest = {
      notificationType: [category],
      limit: isInitialPage ? this.initialNotificationCount : MAX_PAGE_SIZE,
      offset: notifications.length - (isPreFetch ? 1 : 0),
      orderBy: "created",
      orderDirection: SORT_DIRECTION.DESCENDING
    };

    this.setState({
      isLoading: true
    });
    this.getNotificationsSubscription = UserService.getNotifications(
      requestParams
    ).subscribe(
      ({ items, pagination }) => {
        this.setState((prevState) => {
          const nextNotifications = prevState.notifications.concat(items);
          const nextNotificationsCount = nextNotifications.length;

          return {
            isLoading: false,
            notifications: nextNotifications,
            isMore: nextNotificationsCount < pagination.count
          };
        });
      },
      (error: ErrorResponse<CIQError>) => {
        notification.showError(error.message);
        this.setState({ isLoading: true });
      }
    );
  };

  handleInitialFetch = (elementCount?: number): void => {
    this.initialNotificationCount = elementCount || MAX_PAGE_SIZE;
    this.setState(
      {
        notifications: []
      },
      this.fetchNotifications
    );
  };

  handleListBottomReached = (isPreFetch?: boolean): void => {
    const { isMore, isLoading } = this.state;

    if (isMore && !isLoading) {
      this.fetchNotifications(isPreFetch);
    }
  };

  handleDeleteNotification = ({
    id,
    seen,
    notificationType,
    notificationData: { type },
    version
  }: InboxNotification): void => {
    const { updateNotificationCounts, inboxCategoryCount } = this.props;

    this.setState(({ loadingIds }) => ({
      loadingIds: loadingIds.concat(id)
    }));

    this.deleteNotificationSubscription = UserService.markNotificationsAsDone([
      id
    ]).subscribe(
      () => {
        // Refetch automatically if user when user deleting last notification for pulled set
        if (inboxCategoryCount > 0 && this.state.notifications.length === 1) {
          this.handleListBottomReached(true);
        }

        this.setState(({ loadingIds, notifications }) => ({
          loadingIds: loadingIds.filter((p) => p !== id),
          notifications: notifications.filter((n) => n.id !== id)
        }));

        if (!seen) {
          let stagedCategories = [notificationType];

          // Check for secondary categories
          const multipleCategories = MultipleInboxCategoryMap.get(type);
          if (version === NOTIFICATION_VERSION_TYPE.V2 && multipleCategories) {
            stagedCategories = multipleCategories;
          }

          updateNotificationCounts(stagedCategories);
        }
      },
      (error: ErrorResponse<CIQError>) => {
        notification.showError(error.message);
        this.setState(({ loadingIds }) => ({
          loadingIds: loadingIds.filter((p) => p !== id)
        }));
      }
    );
  };

  handleNotificationClick = (notification: InboxNotification): void => {
    const { notifications } = this.state;

    if (!notification.seen) {
      this.setState({
        notifications: notifications.map((n) => {
          const updatedNotification = n;
          if (n.id === notification.id) {
            updatedNotification.seen = true;
          }
          return updatedNotification;
        })
      });
    }
  };

  renderNotifications = (): JSX.Element => {
    const { notifications, isLoading, loadingIds } = this.state;

    return (
      <>
        <Table className="ciqs-table">
          <tbody>
            {notifications.map((notification) => (
              <NotificationItem
                onDelete={(): void =>
                  this.handleDeleteNotification(notification)
                }
                isLoading={loadingIds.includes(notification.id)}
                key={notification.id}
                notification={notification}
                onNotificationClick={(): void =>
                  this.handleNotificationClick(notification)
                }
              />
            ))}
          </tbody>
        </Table>
        {isLoading && <Loader />}
      </>
    );
  };

  render(): JSX.Element {
    const { isActive, t, activeCategory, onMobileCategorySelect, categories } =
      this.props;
    const { isLoading, notifications } = this.state;

    return (
      <div className="category-container">
        <div className="header-container">
          <ContentPanelContextConsumer>
            {({
              setContent,
              closePanel
            }: ContentPanelContextProps): JSX.Element => (
              <button
                className="btn with-icon"
                onClick={(): void => {
                  setContent(
                    <div className="notification-category-panel content-panel-body">
                      <div className="panel-header">
                        <h2>{t("title")}</h2>
                        <div className="btn-close" onClick={closePanel}>
                          <i className="ciq-icon ciq-close" />
                        </div>
                      </div>
                      <div className="panel-body">
                        <InboxCategoryList
                          onCategorySelect={(selectedCategory): void => {
                            onMobileCategorySelect(selectedCategory);
                            closePanel();
                          }}
                          categories={categories}
                          activeCategory={activeCategory}
                        />
                      </div>
                    </div>,
                    true
                  );
                }}
              >
                <span className="ciq-icon ciq-component-icon-ic-filter-2" />
              </button>
            )}
          </ContentPanelContextConsumer>
        </div>

        {isActive && (
          <Media queries={APP_DEVICE_MEDIA_QUERIES}>
            {(matches): JSX.Element => (
              <CIQInfiniteScroll
                style={{
                  height: UI_INBOX_PAGE.NOTIFICATIONS_LIST_HEIGHT(matches),
                  maxHeight: UI_INBOX_PAGE.NOTIFICATIONS_LIST_HEIGHT(matches),
                  overflowX: "hidden"
                }}
                className="simplebar-light notifications-container"
                onInitialFetch={this.handleInitialFetch}
                direction={CIQInfiniteScrollDirection.BOTTOM}
                elementHeight={UI_INBOX_PAGE.NOTIFICATION_ITEM_HEIGHT}
                onBottomReached={this.handleListBottomReached}
              >
                {this.renderNotifications()}
                {!isLoading && notifications.length === 0 && (
                  <InboxEmptyState category={activeCategory} />
                )}
              </CIQInfiniteScroll>
            )}
          </Media>
        )}
      </div>
    );
  }
}

export default withTranslation("inbox")(NotificationTab);
