import { Button, Flex } from "antd";
import clsx from "clsx";
import { Field, Form, Formik, FormikProps } from "formik";
import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import {
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row
} from "reactstrap";

import { AppConstants } from "../../../../constants";
import {
  ContentPanelContextConsumer,
  ContentPanelContextProps
} from "../../../../contexts/contentPanel/ContentPanelContext";
import { User } from "../../../../models/user";
import { CIQFormInput } from "../../../app/components";
import { CIQMemberPicker } from "../panels";
import { DetailsTabValidationSchema } from "./DetailsTabSchema";

interface DetailsTabProps {
  isSaving: boolean;
  companyName: string;
  externalName: string;
  mainContact: User;
  clientId: string;
  updateClient: (values: DetailsTabFormValues) => void;
}

type DetailsTabIntersectProps = DetailsTabProps & WithTranslation;

export interface DetailsTabFormValues {
  companyName: string;
  externalName: string;
  mainContact: User;
}

class DetailsTab extends Component<DetailsTabIntersectProps> {
  handleOpenMemberSelection = (
    contentPanelContext: ContentPanelContextProps,
    setFieldValue: Function,
    setFieldTouched: Function,
    mainContact: User
  ): void => {
    const { t, isSaving } = this.props;

    if (!isSaving) {
      contentPanelContext.setContent(
        // @ts-ignore
        <CIQMemberPicker
          key={AppConstants.CONTENT_PANEL.BILLING_USER_PICKER}
          currentBillingUser={mainContact}
          onSelectBillingUser={(mainClient): void => {
            setFieldValue("mainContact", mainClient);
            contentPanelContext.closePanel();
          }}
          onPanelClose={(): void => setFieldTouched("mainContact", true)}
          clientMembers
          panelHeader={t("mainContactTitle")}
          isAdminUsersOnly
          searchPlaceholder={t("searchMainContact")}
        />,
        true,
        "",
        () => setFieldTouched("mainContact", true)
      );
    }
  };

  handleFormSubmit = (values: DetailsTabFormValues): void => {
    const { updateClient } = this.props;
    updateClient(values);
  };

  renderForm = ({
    values,
    errors,
    touched,
    dirty,
    isValid,
    setFieldValue,
    setFieldTouched
  }: FormikProps<DetailsTabFormValues>): JSX.Element => {
    const { t, isSaving } = this.props;
    const { mainContact } = values;

    const saveButtonStatus = !isValid || !dirty;
    const mainContactName = `${mainContact.firstName} ${mainContact.lastName}`;

    return (
      <Form style={{ width: "100%" }}>
        <Row>
          <Col xs={12} md={6}>
            <FormGroup
              className={clsx({
                "is-invalid": touched.companyName && errors.companyName
              })}
            >
              <Label className="space-between">
                {t("companyName")}
                <span className="field-tip">{t("forInternalUse")}</span>
              </Label>
              <Field
                component={CIQFormInput}
                data-testid="companyName"
                name="companyName"
                type="text"
                autoComplete="off"
                placeholder={t("companyName")}
                readOnly={isSaving}
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup
              className={clsx({
                "is-invalid": touched.externalName && errors.externalName
              })}
            >
              <Label className="space-between">
                {t("externalName")}
                <span className="field-tip">{t("shownToExperts")}</span>
              </Label>
              <Field
                component={CIQFormInput}
                data-testid="externalName"
                name="externalName"
                type="text"
                autoComplete="off"
                placeholder={t("externalName")}
                readOnly={isSaving}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <FormGroup
              className={clsx({
                "is-invalid": touched.mainContact && errors.mainContact
              })}
            >
              <Label>{t("mainContact")}</Label>
              <ContentPanelContextConsumer>
                {(
                  contentPanelContext: ContentPanelContextProps
                ): JSX.Element => (
                  <InputGroup
                    className="append select"
                    onClick={(): void =>
                      this.handleOpenMemberSelection(
                        contentPanelContext,
                        setFieldValue,
                        setFieldTouched,
                        mainContact
                      )
                    }
                  >
                    <Input
                      autoComplete="off"
                      name="mainContact"
                      type="text"
                      placeholder={t("mainContact")}
                      readOnly
                      value={mainContactName}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <span className="ciq-icon ciq-chevron-up-down" />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                )}
              </ContentPanelContextConsumer>
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup>
              <Label>&nbsp;</Label>
              <Field
                name="mainContactEmail"
                id="mainContactEmail"
                autoComplete="off"
                value={mainContact.email}
                component={CIQFormInput}
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
        <Flex justify="flex-end">
          <Button
            htmlType="submit"
            type="primary"
            data-testid="submitChanges"
            size="large"
            disabled={saveButtonStatus || isSaving}
          >
            {t("saveDetails")}
          </Button>
        </Flex>
      </Form>
    );
  };

  render(): JSX.Element {
    const { companyName, externalName, mainContact } = this.props;

    return (
      <div>
        <Row
          style={{
            margin: "0 auto",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Formik<DetailsTabFormValues>
            initialValues={{
              companyName: companyName || "",
              externalName: externalName || "",
              mainContact
            }}
            validateOnBlur
            enableReinitialize
            validateOnChange
            validationSchema={DetailsTabValidationSchema}
            onSubmit={this.handleFormSubmit}
          >
            {this.renderForm}
          </Formik>
        </Row>
      </div>
    );
  }
}

export default withTranslation("detailsTab")(DetailsTab);
