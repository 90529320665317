import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { DiscoverExpertStats } from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  ArbolusTooltip,
  Button,
  ExpertLinkedinIcon,
  IconWithNumber
} from "@arbolus-technologies/ui/components";

import { DiscoverMatchingFiltersPanelData } from "../../../DiscoverMatchingFiltersPanel/interfaces";

import styles from "./ExpertStats.module.scss";

interface ExpertStatsProps {
  stats: DiscoverExpertStats;
  previousProjects: string[];
  discoverMatchingFiltersPanelData: DiscoverMatchingFiltersPanelData;
  searchMatchColumns: string[];
  recommendationMatchPercentage: number | null;
  isRecommendedExpert: boolean;
  linkedinUrl?: string;
}

export const ExpertStats: React.FC<ExpertStatsProps> = ({
  stats,
  previousProjects,
  discoverMatchingFiltersPanelData,
  searchMatchColumns = [],
  recommendationMatchPercentage,
  isRecommendedExpert,
  linkedinUrl
}) => {
  const { t } = useTranslation("discoverPage");
  const dispatch = useDispatch();
  const isAdmin = useSelector(CacheSelector.isAdmin());
  const discoverFilters = useSelector(ProjectNxSelector.discoverFilters());
  const searchTerm = useSelector(
    ProjectNxSelector.getDiscoverExpertSearchTerm()
  );

  const projectsFromFilters = discoverFilters.projects.map(
    ({ label }) => label
  );
  const matchedProjects = previousProjects.filter((project) =>
    projectsFromFilters.includes(project)
  );

  const areFiltersApplied = (): boolean =>
    Object.values(discoverFilters).some(
      (filter) => Array.isArray(filter) && filter.length > 0
    );

  const displayRecommendedMatchPercentage =
    !areFiltersApplied() && !searchTerm && isAdmin && isRecommendedExpert;

  return (
    <div className={styles.expertStats}>
      {isAdmin && <ExpertLinkedinIcon linkedInUrl={linkedinUrl} />}
      <ArbolusTooltip
        content={matchedProjects.map((projectName, index) => (
          <span key={index}>{projectName}</span>
        ))}
        lightTheme
        disabled={matchedProjects.length === 0}
      >
        <IconWithNumber
          amount={stats.projects}
          color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
          name="folder"
          iconSize="16px"
        />
      </ArbolusTooltip>
      <IconWithNumber
        amount={stats.calls}
        color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
        name="call"
        tooltip={t("calls")}
        iconSize="16px"
      />
      <IconWithNumber
        amount={stats.canopies}
        color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
        name="workspaces"
        tooltip={t("canopies")}
        iconSize="16px"
      />
      {displayRecommendedMatchPercentage ? (
        <span className={styles.matchPercentage}>
          {t("matchPercentage", {
            percentage: recommendationMatchPercentage
          })}
        </span>
      ) : (
        <>
          {isAdmin && (
            <Button
              text={t("matches", { count: searchMatchColumns.length })}
              endIcon="chevron_right"
              type="tertiary"
              disabled={searchMatchColumns.length === 0}
              onClick={() =>
                dispatch(
                  PanelStoreActions.openPanel(
                    PanelId.DiscoverMatchingFilters,
                    discoverMatchingFiltersPanelData
                  )
                )
              }
            />
          )}
        </>
      )}
    </div>
  );
};
