import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  BookmarkService,
  DefaultToasterService,
  ToasterService
} from "@arbolus-technologies/api";
import { BookmarkCollectionsStoreActions } from "@arbolus-technologies/stores/bookmark-collections";
import { DeleteModal } from "@arbolus-technologies/ui/components";

interface DeleteCollectionModalProps {
  id: string;
  isOpen: boolean;
  close: () => void;
  onSuccess?: () => void;
  bookmarkService?: typeof BookmarkService;
  notificationService?: ToasterService;
}

export const DeleteCollectionModal: React.FC<DeleteCollectionModalProps> = ({
  id,
  isOpen,
  close,
  onSuccess,
  bookmarkService = BookmarkService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("bookmarkCollectionActions");
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);

  const deleteCollection = useCallback(() => {
    if (submitting) {
      return;
    }

    setSubmitting(true);
    bookmarkService.deleteBookmarkCollection(id).subscribe({
      next: () => {
        dispatch(BookmarkCollectionsStoreActions.deleteCollection(id));
        setSubmitting(false);
        close();
        onSuccess && onSuccess();
      },
      error: (error) => {
        setSubmitting(false);
        notificationService.showApiErrors(error);
      }
    });
  }, [
    bookmarkService,
    close,
    dispatch,
    id,
    notificationService,
    onSuccess,
    submitting
  ]);

  return (
    <DeleteModal
      toggleModal={isOpen}
      messageTitle={t("delete")}
      warningMessage={t("deleteText")}
      onConfirm={deleteCollection}
      onCancel={close}
    />
  );
};
