import { ChannelAuthorizationCallback } from "pusher-js";
import { Channel } from "pusher-js/with-encryption";
import { Subject } from "rxjs";
import { bufferTime, filter, map, switchMap } from "rxjs/operators";

import { UserService } from "@arbolus-technologies/api";

const PUSHER_BATCH_BUFFER_TIME = 1000;

interface BufferedAuthorizer {
  channelName: string;
  socketId: string;
}

class PusherBufferedAuthManager {
  callbacks: Map<string, ChannelAuthorizationCallback>;

  requestBuffer: Subject<BufferedAuthorizer>;

  constructor() {
    this.callbacks = new Map();
    this.requestBuffer = new Subject();

    this.requestBuffer
      .pipe(
        bufferTime(PUSHER_BATCH_BUFFER_TIME),
        filter((buffer) => buffer.length > 0),
        map((batch) => ({
          socketId: batch[0].socketId,
          channelNames: batch.map(({ channelName }) => channelName)
        })),
        switchMap((batchRequest) =>
          UserService.pusherAuthenticateAll(batchRequest)
        )
      )
      .subscribe(
        ({ authData }) => {
          const channelNames = Object.keys(authData);

          channelNames.forEach((channelName) => {
            if (this.callbacks.has(channelName)) {
              const callback = this.callbacks.get(channelName)!;
              const { auth, channelData } = authData[channelName];

              callback(null, { auth, channel_data: channelData });

              this.callbacks.delete(channelName);
            }
          });
        },
        () => {
          // Release all callbacks
          Array.from(this.callbacks.entries()).forEach(([key, _]) => {
            this.callbacks.delete(key);
          });
        }
      );
  }

  addAuthorizer = (
    newAuthorizer: BufferedAuthorizer,
    callback: ChannelAuthorizationCallback
  ): void => {
    this.callbacks.set(newAuthorizer.channelName, callback);
    this.requestBuffer.next(newAuthorizer);
  };

  authorizer = (channel: Channel) => ({
    authorize: (
      socketId: string,
      callback: ChannelAuthorizationCallback
    ): void => {
      const { name } = channel;

      if (!this.callbacks.has(name)) {
        const authObj: BufferedAuthorizer = {
          socketId,
          channelName: name
        };

        this.addAuthorizer(authObj, callback);
      }
    }
  });
}

export const pusherBufferedAuthManager = new PusherBufferedAuthManager();
