import i18next from "i18next";
import _ from "lodash";

import {
  PROJECT_TIMELINE_FORMAT,
  formatDate
} from "@arbolus-technologies/utils";

import { Event } from "../models/event";
import { Transcript } from "../models/transcript";
import { User } from "../models/user";
import { Speaker } from "../models/view/transcript";

import { UtilsService } from ".";

export const TranscriptService = {
  generateTranscriptSpeakers: (
    event: Event,
    transcript: Transcript
  ): {
    speakersMap: Map<string, Speaker>;
    speakersList: Speaker[];
  } => {
    const { transcriptionData } = transcript;
    const { speakers } = transcriptionData;
    const { eventGuests } = event;

    // Filter edited unidentified speakers
    const extraSpeakers: Speaker[] = speakers
      .filter((s) => s.edited && !s.userId)
      .map((s) => ({
        name: s.name
      }));

    // Convert event guest to Transcript speakers
    const guestSpeakers: Speaker[] = eventGuests.map(
      ({ email, user, userId }) => ({
        userId,
        name: UtilsService.displayUserName({
          email,
          firstName: user?.firstName,
          lastName: user?.lastName
        } as User),
        profileImageUrl: user?.profileImageUrl
      })
    );

    // Join result + Sort by name
    const speakersList = [...guestSpeakers, ...extraSpeakers].sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    // Remove duplicates
    const sanitizedSpeakers = _.uniqBy(
      speakersList,
      ({ name, userId }) => name + (userId || "")
    );

    // Create speaker map
    const nextSpeakerMap = new Map(
      speakers.map(({ id, userId, name, edited }) => {
        let guestUser;

        if (userId) guestUser = guestSpeakers.find((s) => s.userId === userId);

        return [
          id,
          {
            userId,
            name: guestUser?.name || name,
            profileImageUrl: guestUser?.profileImageUrl,
            edited
          } as Speaker
        ];
      })
    );

    return {
      speakersMap: nextSpeakerMap,
      speakersList: sanitizedSpeakers
    };
  },
  generateEventDetails: (
    created: Date,
    durationSeconds: number,
    speakersCount: number
  ): string => {
    const eventDate = formatDate(
      UtilsService.convertUTCToActiveZone(created),
      PROJECT_TIMELINE_FORMAT
    );

    const duration =
      UtilsService.convertSecondsToHoursAndMinutes(durationSeconds);

    return `${eventDate} • ${duration} • ${i18next.t(
      "transcript:participantCount",
      { count: speakersCount }
    )}`;
  }
};
