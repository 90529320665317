import { Button, IconButton } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-media";
import { useSelector } from "react-redux";

import {
  ArbolusGPTButton,
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { PROJECT_ROUTE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import {
  APP_DEVICE_MEDIA_QUERIES,
  BackButton
} from "@arbolus-technologies/ui/components";

import styles from "./TranscriptPageHeaderTemplate.module.scss";

export const TranscriptPageHeaderTemplate: React.FC<{
  onClickBack: () => void;
  onDownload: () => void;
  projectId: string;
  children: React.ReactNode;
}> = ({ onClickBack, onDownload, projectId, children }) => {
  const { t } = useTranslation("transcript");
  const matches = useMedia({ queries: APP_DEVICE_MEDIA_QUERIES });
  const { trackClick } = useArbolusTracking();

  const projectName = useSelector(ProjectNxSelector.projectName());
  const loggedUserMail = useSelector(CacheSelector.userProfile()).email;

  return (
    <div className={styles.headerTemplate}>
      <BackButton title={t("back")} onClick={onClickBack} />
      <div className={styles.children}>
        <ArbolusGPTButton
          projectPath={`${PROJECT_ROUTE(projectId).pathname} | ${projectName}>`}
          projectId={projectId}
          onTrack={() =>
            trackClick(MixPanelEventNames.TranscriptCallArbolusGPT, {
              loggedUserMail
            })
          }
        />
        {matches.large ? (
          <Button
            text={t("download")}
            onClick={onDownload}
            endIcon={"download"}
          />
        ) : (
          <IconButton icon="download" onClick={onDownload} />
        )}
        {children}
      </div>
    </div>
  );
};
