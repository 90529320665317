import { Button } from "antd";
import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import {
  CIQError,
  CIQUser,
  ErrorResponse,
  ListClientMember,
  SpecialPermissionsPaginatedRequest,
  ToasterService,
  USER_PAGE_SIZE
} from "@arbolus-technologies/api";
import {
  InternalSlidePanel,
  MixpanelPages,
  trackPageView
} from "@arbolus-technologies/features/common";
import {
  EDIT_USER_ROUTE,
  ProjectSlidePanel,
  TeamMemberContentClient,
  UserTable
} from "@arbolus-technologies/features/users";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import {
  HR,
  SEARCH_DEBOUNCE_TIMEOUT
} from "@arbolus-technologies/ui/components";
import { AntDHeader } from "@arbolus-technologies/ui/layout";
import { SIDE_PANEL_SIZE, useDebounce } from "@arbolus-technologies/utils";

import { USER_SORT_CRITERIA } from "../../../../constants/user";
import { ClientService } from "../../../../services";
import { AuthSelector } from "../../../auth/store";
import { ClientTeamInvitePanel } from "../../components/panels/clientTeamInvite/ClientTeamInvitePanel";

import styles from "./UsersPage.module.scss";

const notificationService = new ToasterService();

export const UsersPage: React.FC = () => {
  const { t } = useTranslation("usersPage");
  const dispatch = useDispatch();
  const history = useHistory();

  const clientId = useSelector(AuthSelector.authClientIdSelector());
  const isCurrentUserClient = useSelector(
    CacheSelector.isCurrentUserClientSelector()
  );
  const [users, setUsers] = useState<ListClientMember[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<CIQUser | null>(null);
  const [isTeamMemberSlidePanelOpen, setIsTeamMemberSlidePanelOpen] =
    useState<boolean>(false);
  const [isProjectsSlidePanelOpen, setIsProjectsSlidePanelOpen] =
    useState<boolean>(false);
  const [selectedPermissionIds, setSelectedPermissionIds] = useState<string[]>(
    []
  );

  useEffect(() => {
    if (isCurrentUserClient) {
      trackPageView({ page: MixpanelPages.TeamMembers });
    }
  }, [isCurrentUserClient]);

  const debouncedSearchTerm = useDebounce<string>(
    searchTerm,
    SEARCH_DEBOUNCE_TIMEOUT
  );

  const fetchUsers = useCallback(
    (page = 0) => {
      setIsLoading(true);

      const apiParams: SpecialPermissionsPaginatedRequest = {
        searchTerm: debouncedSearchTerm,
        claimValues: selectedPermissionIds,
        offset: USER_PAGE_SIZE * page,
        limit: USER_PAGE_SIZE,
        orderDirection: USER_SORT_CRITERIA.NAME.direction,
        orderBy: USER_SORT_CRITERIA.NAME.value
      };

      ClientService.getTeamMembers(clientId!, apiParams).subscribe(
        ({ items, pagination }) => {
          setUsers(items);
          setTotalCount(pagination.count);
          setIsLoading(false);
        },
        (error: ErrorResponse<CIQError>) => {
          setIsLoading(false);
          notificationService.showError(error.message);
        }
      );
    },
    // debouncedSearchTerm is a dependency because then we can call
    // fetchUsers from onPageChange and keep the search term
    [clientId, debouncedSearchTerm, selectedPermissionIds]
  );

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers, debouncedSearchTerm]);

  const handleOpenTeamMemberClientSlidePanel = () => {
    setIsTeamMemberSlidePanelOpen(true);
  };

  const handleCloseTeamMemberClientSlidePanel = () => {
    setIsTeamMemberSlidePanelOpen(false);
  };

  const handleOpenProjectsSlidePanel = () => setIsProjectsSlidePanelOpen(true);
  const handleCloseProjectsSlidePanel = () =>
    setIsProjectsSlidePanelOpen(false);

  const handleOnAssignSlidePanel = (id: string) => {
    const user = users?.find((user) => user.id === id) ?? null;
    setSelectedUser(user);
    handleOpenProjectsSlidePanel();
  };

  const handleGoToClientEditPage = () =>
    history.push(EDIT_USER_ROUTE(selectedUser?.id!));

  const openClientUserSlidePanel = () => {
    dispatch(PanelStoreActions.openPanel(PanelId.ClientTeamInvite));
  };

  return (
    <>
      <div className={clsx(styles.usersPage)}>
        <AntDHeader
          title={t("users")}
          rightContainer={
            <Button
              onClick={openClientUserSlidePanel}
              iconPosition="end"
              icon={<AntDIcon name="add" />}
            >
              {t("newUser")}
            </Button>
          }
        />
        <div className={styles.scrollableContent}>
          {users && (
            <UserTable
              onSearch={setSearchTerm}
              onPageChange={fetchUsers}
              isLoading={isLoading}
              totalCount={totalCount}
              users={users}
              onUserNameClick={(user: CIQUser): void => {
                setSelectedUser(user);
                handleOpenTeamMemberClientSlidePanel();
              }}
              onAssignSlidePanel={handleOnAssignSlidePanel}
              onlyAssignAction
              onFilter={setSelectedPermissionIds}
              selectedPermissionIds={selectedPermissionIds}
            />
          )}
        </div>
      </div>
      <InternalSlidePanel
        customCloseRequest={handleCloseTeamMemberClientSlidePanel}
        isOpen={isTeamMemberSlidePanelOpen}
        width={SIDE_PANEL_SIZE._720}
        hideHeader
      >
        <div className={styles.slidePanelContainerTitle}>
          <h2>{t("details")}</h2>
          <Icon
            name="close"
            fontSize="18px"
            onClick={handleCloseTeamMemberClientSlidePanel}
          />
        </div>
        <HR margin={{ top: 3, bottom: 3 }} />
        {selectedUser && (
          <TeamMemberContentClient
            clientId={clientId!}
            user={selectedUser}
            handleCloseTeamMemberClientSlidePanel={
              handleCloseTeamMemberClientSlidePanel
            }
            handleAssignProject={handleOpenProjectsSlidePanel}
            goToClientEditPage={handleGoToClientEditPage}
          />
        )}
      </InternalSlidePanel>
      {selectedUser && isProjectsSlidePanelOpen && (
        <ProjectSlidePanel
          clientId={clientId!}
          userId={selectedUser.id}
          handleCloseProjectsSlidePanel={handleCloseProjectsSlidePanel}
          openPanel={isProjectsSlidePanelOpen}
        />
      )}
      <ClientTeamInvitePanel />
    </>
  );
};
