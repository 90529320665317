import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./ButtonToggle.module.scss";

interface ButtonToggleProps {
  handleToggle: () => void;
  toggle: boolean;
}
export const ButtonToggle: React.FC<ButtonToggleProps> = ({
  handleToggle,
  toggle
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.buttonToggleContainer}>
      <button onClick={() => handleToggle()}>
        {toggle ? (
          <div className={styles.buttonBody}>
            <span>{t("common:buttonToggle:closeAll")}</span>
            <span className={clsx("material-symbols-sharp", styles.icon)}>
              unfold_less
            </span>
          </div>
        ) : (
          <div className={styles.buttonBody}>
            <span>{t("common:buttonToggle:openAll")}</span>
            <span className={clsx("material-symbols-sharp", styles.icon)}>
              unfold_more
            </span>
          </div>
        )}
      </button>
    </div>
  );
};
