import { pdf } from "@react-pdf/renderer";
import clsx from "clsx";
import { saveAs } from "file-saver";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Modal } from "reactstrap";

import {
  CanopyQuestionsPDF,
  CanopyService,
  DefaultToasterService,
  FileResponse,
  ToasterService
} from "@arbolus-technologies/api";
import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { Elevation } from "@arbolus-technologies/theme";
import { ButtonDropdown } from "@arbolus-technologies/ui/components";
import { string64toBlob, useCookie } from "@arbolus-technologies/utils";

import { CanopyFeedbackCollector } from "../../CanopyFeedbackCollector/CanopyFeedbackCollector";
import { PDFExportCanopyAnswers } from "../../PDFDocumentTypes/PDFExportCanopyAnswers";

import styles from "./ExportAnswersButton.module.scss";

const blobTypeExtension =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

interface ExportAnswersButtonProps {
  canopyId: string;
  canopyTitle: string;
  isQuestionsPage?: boolean;
  notificationService?: ToasterService;
}

export const ExportAnswersButton: React.FC<ExportAnswersButtonProps> = ({
  canopyId,
  canopyTitle,
  isQuestionsPage = false,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("canopyV2");
  const { isCookieStored } = useCookie(`feedback-collector-${canopyId}`);
  const { trackClick } = useArbolusTracking();

  const [showCanopyFeedbackCollector, setShowCanopyFeedbackCollector] =
    useState(false);
  const isAdmin = useSelector(CacheSelector.isAdmin());

  const handleExportAnswers = () => {
    trackClick(
      isQuestionsPage
        ? MixPanelEventNames.CanopyQuestionsExportAnswersExcel
        : MixPanelEventNames.CanopyExpertsExportAnswersExcel,
      { canopyId }
    );

    CanopyService.exportAnswers(canopyId).subscribe({
      next: (response: FileResponse) => {
        const blob = string64toBlob(response.file, blobTypeExtension);
        saveAs(blob, response.name);
        notificationService.showSuccess(t("downloadSuccess"));
      },
      error: (error) => {
        notificationService.showError(error.message);
      }
    });
  };

  const handlePreparePdf = () => {
    trackClick(
      isQuestionsPage
        ? MixPanelEventNames.CanopyQuestionsExportAnswersPDF
        : MixPanelEventNames.CanopyExpertsExportAnswersPDF,
      { canopyId }
    );

    CanopyService.getAllPdfAnswers(canopyId).subscribe({
      next: async (response: { items: CanopyQuestionsPDF }) => {
        const doc = (
          <PDFExportCanopyAnswers
            data={response.items}
            canopyTitle={canopyTitle}
          />
        );
        const asPdf = pdf();
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        saveAs(blob, `${canopyTitle.replace(/ /g, "_")}.pdf`);
      },
      error: (error) => {
        notificationService.showError(error.message);
      }
    });
    setShowCanopyFeedbackCollector(true);
  };

  return (
    <>
      <ButtonDropdown
        text={t("exportAnswers")}
        options={[
          { name: t("spreadsheet"), onClick: handleExportAnswers },
          { name: t("pdf"), onClick: handlePreparePdf }
        ]}
        onTrack={() =>
          trackClick(
            isQuestionsPage
              ? MixPanelEventNames.CanopyQuestionsExportAnswers
              : MixPanelEventNames.CanopyExpertsExportAnswers,
            { canopyId }
          )
        }
      />
      {!isCookieStored() && !isAdmin && (
        <Modal
          isOpen={showCanopyFeedbackCollector}
          className={clsx("modal-alert", styles.modal)}
          zIndex={Elevation.Z_INDEX_99}
        >
          <CanopyFeedbackCollector
            purple
            noBorder
            onClickClose={() => setShowCanopyFeedbackCollector(false)}
            cookie={`feedback-collector-${canopyId}`}
          />
        </Modal>
      )}
    </>
  );
};
