import React from "react";
import { useTranslation } from "react-i18next";

import { REDIRECT_STATES } from "../../../../../constants/app";

import { ReactComponent as RedirectImage } from "../../../../../assets/images/empty-states/redirect.svg";

interface RedirectPageProps {
  redirectionType: REDIRECT_STATES;
}

const RedirectPage: React.FC<RedirectPageProps> = ({ redirectionType }) => {
  const { t } = useTranslation("redirectPage");

  const redirectTitle = (): string => {
    switch (redirectionType) {
      case REDIRECT_STATES.AUTH_SUBDOMAIN_SWITCH:
        return "loadingAccount";
      case REDIRECT_STATES.GUEST_SUBDOMAIN_SWITCH:
        return "logoutFromAccount";
      default:
        return "redirecting";
    }
  };

  return (
    <div className="redirect-container">
      <RedirectImage className="redirect-image" />
      <div className="redirect-message">
        <h1>{t(`${redirectTitle()}`)}</h1>
        <p>{t("wait")}</p>
      </div>
    </div>
  );
};

export default RedirectPage;
