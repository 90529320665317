import clsx from "clsx";
import React, { ChangeEvent, useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  DefaultToasterService,
  ToasterService
} from "@arbolus-technologies/api";

import { LoaderOrComponent } from "../Loader/LoaderOrComponent";

import styles from "./UploadInput.module.scss";

interface UploadInputProps {
  isUploading: boolean;
  acceptFileTypes?: string;
  onFileSelected: (file: File) => void;
  children: React.ReactNode;
  notificationService?: ToasterService;
}

export const UploadInput: React.FC<UploadInputProps> = ({
  isUploading,
  acceptFileTypes,
  onFileSelected,
  children,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("uploadInput");

  const clickUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (files?.length !== 1) {
        notificationService.showError(t("fileCountError"));
        return;
      }

      onFileSelected(files![0]);
    },
    [notificationService, onFileSelected, t]
  );

  return (
    <div className={clsx(isUploading && styles.disabled)}>
      <label
        htmlFor="upload-document"
        role="button"
        className="d-flex w-100 h-100"
      >
        <LoaderOrComponent isLoading={isUploading}>
          {children}
        </LoaderOrComponent>
      </label>
      <input
        id="upload-document"
        type="file"
        className="d-none"
        accept={acceptFileTypes}
        onChange={clickUpload}
      />
    </div>
  );
};
