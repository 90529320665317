import { GridApi } from "ag-grid-community";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { ModalService } from "@arbolus-technologies/features/common";
import { Referral } from "@arbolus-technologies/models/common";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import {
  ProjectNxSelector,
  ProjectNxStoreActions
} from "@arbolus-technologies/stores/project";

import { ReferralGridContext } from "../../../Models/ReferralsTable";

export const useReferralGridContext = (
  api?: GridApi,
  reloadData?: Referral[] // If you have to update the groups
): ReferralGridContext => {
  const project = useSelector(ProjectNxSelector.projectData());
  const timezoneId = useSelector(ProjectNxSelector.displayTimezoneId());
  const dispatch = useDispatch();

  const context = React.useMemo<ReferralGridContext>(
    () => ({
      project: project!,
      modalService: new ModalService(dispatch),
      timezone: timezoneId,
      openExpertProfile: (params) =>
        dispatch(
          PanelStoreActions.openPanel(PanelId.ReferralExpertPanel, params)
        ),
      updateAngle: (updateParams) =>
        dispatch(
          PanelStoreActions.openPanel(PanelId.UpdateExpertAngle, updateParams)
        ),
      openAssignExpertToProject: (referrals) =>
        dispatch(
          PanelStoreActions.openPanel(PanelId.AssignExpertToProject, referrals)
        ),
      reloadReferralsCount: (projectId: string) =>
        dispatch(ProjectNxStoreActions.loadReferralTabCount(projectId))
    }),

    [dispatch, project, timezoneId]
  );

  React.useEffect(() => {
    if (!api) {
      return;
    }

    api.setGridOption("context", context);
    api.refreshHeader();
    if (reloadData) {
      api.setGridOption("rowData", []);
      api.setGridOption("rowData", reloadData);
      api.expandAll();
    } else {
      api.redrawRows();
    }
  }, [api, reloadData, context]);

  return context;
};
