import queryString from "query-string";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { zip } from "rxjs";

import {
  ApiPaginatedResponse,
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  Project,
  ProjectCollectionRequest,
  ProjectService,
  SORT_DIRECTION,
  ToasterService
} from "@arbolus-technologies/api";
import {
  DiscoverFilterOption,
  DiscoverFilterType,
  ProjectResponse
} from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  ProjectNxSelector,
  ProjectNxStoreActions
} from "@arbolus-technologies/stores/project";
import { useDebounce, utilService } from "@arbolus-technologies/utils";

import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { SEARCH_LIMIT } from "../../Modules/DiscoverFilters/DiscoverFilters";
import { useDiscoverFilters } from "./useDiscoverFilters";

interface UseDiscoverProjectFilterProps {
  projectService?: typeof ProjectService;
  notificationService?: ToasterService;
}

export interface UseDiscoverProjectFilter {
  handleBottomReached: (query: string) => void | null;
  handleInputChangeEvent: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  handleClearQueryClicked: () => void;
  handleSelectOption: (option: DiscoverFilterOption) => void;
  filterOptions: DiscoverFilterOption[];
  applyProjectFilter: () => void;
  urlChangeOnProjectFilterUpdate: (options: DiscoverFilterOption[]) => void;
  handleClearFilter: () => void;
  updateProjectInfoFromUrl: () => Promise<DiscoverFilterOption[]>;
}

const MINIMUM_SEARCH_LENGTH = 2;

export const useDiscoverProjectFilter = ({
  projectService = ProjectService,
  notificationService = DefaultToasterService
}: UseDiscoverProjectFilterProps = {}): UseDiscoverProjectFilter => {
  const { t } = useTranslation("discoverPage");
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { trackFiltered } = useArbolusTracking();

  const parameters = queryString.parse(location.search);
  const { projects }: { projects?: string } = parameters;

  const {
    setSelectedOptions,
    preSelectedOptions,
    setPreSelectedOptions,
    clearFilter
  } = useDiscoverFilters({
    filterType: DiscoverFilterType.Projects
  });

  const [isProjectsLoading, setIsProjectsLoading] = useState<boolean>(false);
  const [projectsPaginatedResponse, setProjectsPaginatedResponse] =
    useState<ApiPaginatedResponse<ProjectResponse> | null>(null);
  const [filterOptions, setFilterOptions] = useState<DiscoverFilterOption[]>(
    []
  );

  const clientId = useSelector(ProjectNxSelector.projectClientId());
  const isAdmin = useSelector(CacheSelector.isAdmin());
  const projectName = useSelector(ProjectNxSelector.projectName());
  const projectClientName = useSelector(ProjectNxSelector.projectClientName());
  const projectFilterQuery = useSelector(ProjectNxSelector.filtersQuery())[
    DiscoverFilterType.Projects
  ];
  const debouncedSearchQuery = useDebounce<string>(projectFilterQuery, 300);

  useEffect(() => {
    if (preSelectedOptions.length === 10) {
      notificationService.showError(t("maximumProjects"));
    }
  }, [notificationService, preSelectedOptions, t]);

  const handleBottomReached = (query: string) => {
    if (
      projectsPaginatedResponse &&
      projectsPaginatedResponse?.pagination.count >
        projectsPaginatedResponse?.pagination.offset + SEARCH_LIMIT &&
      !isProjectsLoading
    ) {
      return getProjectSearchResults(query);
    }
    return null;
  };

  const getProjectSearchResults = (query?: string) => {
    setIsProjectsLoading(true);
    const queryParams: ProjectCollectionRequest = {
      searchTerm: query,
      offset: projectsPaginatedResponse?.items.length ?? 0,
      limit: 10,
      orderBy: "Name",
      orderDirection: SORT_DIRECTION.ASCENDING,
      clientId: isAdmin ? undefined : clientId
    };

    projectService.projectCollection(queryParams).subscribe(
      (projectsResponse) => {
        const filterOptionsArray = projectsResponse.items.map((project) => ({
          value: project.id,
          label: project.name
        }));

        setProjectsPaginatedResponse({
          items: [
            ...(projectsPaginatedResponse?.items ?? []),
            ...projectsResponse.items
          ],
          pagination: projectsResponse.pagination
        });
        setIsProjectsLoading(false);
        setFilterOptions([...filterOptions, ...filterOptionsArray]);
      },
      (error: ErrorResponse<CIQError>) => {
        setIsProjectsLoading(false);
        notificationService.showError(error.message);
        return [];
      }
    );
  };

  const handleInputChangeEvent = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const newQuery = event.target.value;
    dispatch(
      ProjectNxStoreActions.setFilterQuery(
        DiscoverFilterType.Projects,
        newQuery
      )
    );
    setProjectsPaginatedResponse(null);
    setFilterOptions([]);
  };

  useEffect(() => {
    if (debouncedSearchQuery.length >= MINIMUM_SEARCH_LENGTH) {
      getProjectSearchResults(debouncedSearchQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  const handleClearQueryClicked = () => {
    dispatch(
      ProjectNxStoreActions.setFilterQuery(DiscoverFilterType.Projects, "")
    );
  };

  const handleSelectOption = (option: DiscoverFilterOption) => {
    const isOptionSelected = preSelectedOptions
      .map((selectedOption) => selectedOption.value)
      .includes(option.value);

    if (isOptionSelected) {
      const filteredOptions = preSelectedOptions.filter(
        (selectedOption) => selectedOption.value !== option.value
      );
      setPreSelectedOptions(filteredOptions);
    } else {
      setPreSelectedOptions([...preSelectedOptions, option]);
    }
  };

  useEffect(() => {
    if (projectFilterQuery.length === 0) {
      setProjectsPaginatedResponse(null);
      setFilterOptions([]);
    }
  }, [projectFilterQuery.length]);

  const applyProjectFilter = () => {
    setSelectedOptions(preSelectedOptions);
    urlChangeOnProjectFilterUpdate(preSelectedOptions);
    trackFiltered(MixPanelEventNames.InternalSearchProjectsSearch, {
      Project: projectName,
      Client: projectClientName
    });
  };

  const updateProjectInfoFromUrl = () => {
    const projectIds = projects ? projects.split(",") : [];
    if (!projectIds.length) {
      return Promise.resolve([]);
    }

    return new Promise<DiscoverFilterOption[]>((resolve, reject) => {
      const promises = projectIds.map((value) =>
        projectService.getProject(value)
      );
      const subscription = zip(...promises).subscribe({
        next: (projects) => {
          const filterOptionsArray = projects.map((project: Project) => ({
            value: project.id,
            label: project.name
          }));

          resolve(filterOptionsArray);
          subscription.unsubscribe();
        },
        error: (error) => {
          reject(error);
          subscription.unsubscribe();
        }
      });
    });
  };

  const urlChangeOnProjectFilterUpdate = (options: DiscoverFilterOption[]) => {
    if (isAdmin) {
      const nonEmptyParams = utilService.getNonEmptyQueryString(parameters);
      const projects = options.map((option) => option.value).join(",");
      if (projects) {
        nonEmptyParams["projects"] = projects;
      } else {
        delete nonEmptyParams["projects"];
      }

      const params = new URLSearchParams(nonEmptyParams);
      history.replace({
        pathname: location.pathname,
        search: params.toString()
      });
    }
  };

  const handleClearFilter = () => {
    clearFilter();
    if (isAdmin) {
      const nonEmptyParams = utilService.getNonEmptyQueryString(parameters);
      delete nonEmptyParams["projects"];

      const params = new URLSearchParams(nonEmptyParams);
      history.replace({
        pathname: location.pathname,
        search: params.toString()
      });
    }
  };

  return {
    handleBottomReached,
    handleInputChangeEvent,
    handleClearQueryClicked,
    handleSelectOption,
    filterOptions,
    applyProjectFilter,
    urlChangeOnProjectFilterUpdate,
    handleClearFilter,
    updateProjectInfoFromUrl
  };
};
