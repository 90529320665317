import { Editor } from "@tiptap/react";
import clsx from "clsx";
import React from "react";

import { TiptapEditorToolbarButton } from "./TiptapEditorToolbarButton";

import styles from "./TiptapEditorToolbar.module.scss";

export const TiptapEditorToolbar: React.FC<{
  editor: Editor;
  disabled: boolean;
}> = ({ editor, disabled }) => (
  <div className={clsx(styles.toolbar, { [styles.disabled]: disabled })}>
    <TiptapEditorToolbarButton
      icon="format_bold"
      onClick={() => editor.chain().focus().toggleBold().run()}
      isActive={editor.isActive("bold")}
    />
    <TiptapEditorToolbarButton
      icon="format_italic"
      onClick={() => editor.chain().focus().toggleItalic().run()}
      isActive={editor.isActive("italic")}
    />
    <TiptapEditorToolbarButton
      icon="format_underlined"
      onClick={() => editor.chain().focus().toggleUnderline().run()}
      isActive={editor.isActive("underline")}
    />
    <TiptapEditorToolbarButton
      icon="format_list_numbered"
      onClick={() => editor.chain().focus().toggleOrderedList().run()}
      isActive={editor.isActive("orderedList")}
    />
    <TiptapEditorToolbarButton
      icon="format_list_bulleted"
      onClick={() => editor.chain().focus().toggleBulletList().run()}
      isActive={editor.isActive("bulletList")}
    />
  </div>
);
