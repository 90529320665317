import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { Badge } from "../Badge";

export interface NotificationBadgeProps {
  count: number;
}

export const NotificationBadge: React.FC<NotificationBadgeProps> = ({
  count
}) => (
  <Badge
    background={ARBOLUS_COLORS.bColorBaseOrange}
    text={count > 9 ? "9+" : `${count}`}
    textStyle="lowercase"
    fontWeight={600}
    customStyle={{
      fontSize: "12px",
      padding: "3px 8px",
      height: "24px",
      minWidth: "24px",
      minHeight: "24px"
    }}
  />
);
