import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { convertToPercentage } from "@arbolus-technologies/features/common";
import { ANSWER_PREFERENCES_TYPE_VALUE } from "@arbolus-technologies/models/canopy";
import { CanopyAnswerSummaryItemValue } from "@arbolus-technologies/models/canopy-panels";
import { HorizontalProgressComponentGroup } from "@arbolus-technologies/ui/components";

import styles from "./CanopySummaryComponentValue.module.scss";

interface CanopySummaryComponentValueProps {
  summary: CanopyAnswerSummaryItemValue;
}

export const CanopySummaryComponentValue: React.FC<
  CanopySummaryComponentValueProps
> = ({ summary }) => {
  const { t } = useTranslation("canopySummary");
  const {
    questionDetails,
    rangeMin,
    rangeMax,
    valuePreferencesType,
    valueAnswers,
    canopyQuestionId
  } = summary;

  const convertedArray = useMemo(
    () =>
      valueAnswers.map((item) => {
        const value = item.answersAverage?.toFixed(2) ?? null;
        const avg = value
          ? t("avg", {
              average: value,
              percent:
                valuePreferencesType ===
                ANSWER_PREFERENCES_TYPE_VALUE.PERCENTAGE
                  ? "%"
                  : ""
            })
          : t("n/a");
        const valuePercentage = item.answersAverage
          ? convertToPercentage(rangeMin, rangeMax, item.answersAverage)
          : 0;

        return {
          id: item.multiRatingChoiceId ?? canopyQuestionId,
          title: item.multiRatingChoiceText ?? "",
          progressText: avg,
          value: valuePercentage,
          hasValue: item.answersAverage !== null
        };
      }),
    [
      canopyQuestionId,
      rangeMax,
      rangeMin,
      t,
      valueAnswers,
      valuePreferencesType
    ]
  );

  return (
    <div className={styles.container}>
      <span className={styles.subtitle}>{questionDetails}</span>
      <HorizontalProgressComponentGroup
        headerText={t(`header${valuePreferencesType}`, {
          rangeMin,
          rangeMax
        })}
        data={convertedArray}
        hideBorder
      />
    </div>
  );
};
