import React, { createContext, useContext, useMemo, useState } from "react";

import { Project } from "@arbolus-technologies/api";
import {
  AdminChatMode,
  AngleChat,
  AngleChatData,
  ChatClientDetails,
  ChatData,
  ChatType,
  ChatUserRole,
  UnreadMessagesCount,
  UnreadMessagesCountAdminSwitch
} from "@arbolus-technologies/models/project";

import {
  ChangeChatFunction,
  useChangeChat
} from "../../Hooks/Chat/useChangeChat";
import { useChatRole } from "../../Hooks/Chat/useChatRole";
import { useInitializeChat } from "../../Hooks/Chat/useInitializeChat";
import { useLiveChatUpdates } from "../../Hooks/Chat/useLiveChatUpdates";
import {
  UnreadCountByAngle,
  useUnreadCount
} from "../../Hooks/Chat/useUnreadCount";
import { AngleWithChatById } from "../../Models/chat";
import { getInitialChatType } from "./ChatContextUtils";
export interface ContextValue {
  changeChat: ChangeChatFunction;
  currentChat?: ChatData | AngleChatData;
  currentChatId?: string;
  chatSenderRole: ChatUserRole;
  chatType?: ChatType;
  projectChat?: ChatData;
  chatsByAngle: AngleWithChatById;
  isLoading: boolean;
  activeAdminMode: AdminChatMode;
  setActiveAdminMode: (mode: AdminChatMode) => void;
  expertSupportChatActive: boolean;
  expertToArbolusChat?: ChatData;
  expertProjectChat?: ChatData;
  unreadMessagesCount: UnreadMessagesCount;
  unreadMessagesCountByAdminMode: UnreadMessagesCountAdminSwitch;
  unreadMessagesCountByAngle: UnreadCountByAngle;
  client: { name: string | undefined; imageUrl: string | undefined };
  projectData?: Project | null;
  projectId?: string;
  expertToArbolusChats?: Record<string, AngleChat>;
  isChatEnabled: boolean;
}

// Exported ONLY for testing purposes
export const ChatContext = createContext({} as ContextValue);

export const ChatProvider: React.FC<{
  projectId: string;
  client?: ChatClientDetails;
  children: React.ReactNode;
}> = ({ projectId, client, children }) => {
  const { isAdmin, isExpert, chatSenderRole } = useChatRole();

  const [currentChat, setCurrentChat] = useState<
    ChatData | AngleChatData | undefined
  >();
  const [chatType, setChatType] = useState<ChatType | undefined>(
    getInitialChatType(isAdmin, isExpert)
  );

  const [activeAdminMode, setActiveAdminMode] = useState(
    AdminChatMode.ClientChat
  );

  const {
    initializeUnreadCount,
    incrementUnreadCount,
    markAsRead,
    channelNameByChatId,
    unreadMessagesCount,
    unreadMessagesCountByAdminMode,
    unreadMessagesCountByAngle
  } = useUnreadCount();

  const changeChat = useChangeChat(
    projectId,
    chatSenderRole,
    currentChat,
    setCurrentChat,
    setChatType,
    markAsRead
  );

  const {
    projectData,
    isLoading,
    isChatEnabled,
    chatsByAngle,
    projectChat,
    expertProjectChat,
    expertToArbolusChat,
    expertToArbolusChats
  } = useInitializeChat(
    projectId,
    changeChat,
    setActiveAdminMode,
    initializeUnreadCount
  );

  useLiveChatUpdates(
    channelNameByChatId,
    currentChat?.id!,
    incrementUnreadCount
  );

  const expertSupportChatActive =
    chatSenderRole === ChatUserRole.Admin &&
    activeAdminMode === AdminChatMode.ExpertSupport;

  const contextValue = useMemo(
    () => ({
      changeChat,
      currentChat,
      currentChatId: currentChat?.id,
      unreadMessagesCount,
      unreadMessagesCountByAdminMode,
      unreadMessagesCountByAngle,
      chatSenderRole,
      chatType,
      projectChat,
      expertProjectChat,
      expertToArbolusChat,
      chatsByAngle,
      isLoading,
      activeAdminMode,
      setActiveAdminMode,
      expertSupportChatActive,
      projectData,
      projectId: projectData?.id,
      client: { name: client?.name, imageUrl: client?.logoUrl },
      expertToArbolusChats,
      isChatEnabled
    }),
    [
      changeChat,
      currentChat,
      unreadMessagesCount,
      unreadMessagesCountByAdminMode,
      unreadMessagesCountByAngle,
      chatSenderRole,
      chatType,
      projectChat,
      expertProjectChat,
      expertToArbolusChat,
      chatsByAngle,
      isLoading,
      activeAdminMode,
      expertSupportChatActive,
      projectData,
      client?.name,
      client?.logoUrl,
      expertToArbolusChats,
      isChatEnabled
    ]
  );

  return (
    <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>
  );
};

export const useChat = (): ContextValue => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error("useChat must be used within a ChatProvider");
  }
  return context;
};
