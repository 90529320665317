import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { SearchInput } from "@arbolus-technologies/ui/components";
import { SEARCH_DEBOUNCE_TIMEOUT_COMMON } from "@arbolus-technologies/utils";

import { Country } from "../../../interfaces";
import { SelectableCountry } from "./SelectableCountry/SelectableCountry";

import styles from "./CountryPanelBody.module.scss";

interface CountryPanelBodyProps {
  country: Country | null;
  onSelectCountry: (value: Country) => void;
}

export const CountryPanelBody: React.FC<CountryPanelBodyProps> = ({
  country,
  onSelectCountry
}) => {
  const { t } = useTranslation("countrySelectionPanel");

  const [searchTerm, setSearchTerm] = useState<string>(country?.name ?? "");
  const [countries, setCountries] = useState<Country[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(
    country
  );

  const countriesList = useSelector(CacheSelector.metadataCountries());

  useEffect(() => {
    if (searchTerm) {
      const filteredCountries = countriesList.filter((country) =>
        country.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setCountries(filteredCountries);
    }
  }, [countriesList, searchTerm]);

  const onQueryChange = (searchValue: string) => {
    setSearchTerm(searchValue);
    if (searchValue === "") {
      setCountries([]);
    }
  };

  const handleSelectedCountry = (country: Country) => {
    setSelectedCountry(country);
    onSelectCountry(country);
  };

  const countriesToShow = searchTerm ? countries : countriesList;

  return (
    <div>
      <SearchInput
        onQueryChange={onQueryChange}
        isDebounced
        initialValue={searchTerm}
        debouncingTime={SEARCH_DEBOUNCE_TIMEOUT_COMMON}
        minimumSearchLength={1}
        placeholder={t("searchCountryPlaceholder")}
        hasSearchIcon
        containerClassnames={styles.searchInputContainer}
        autoFocus={!isMobile}
      />
      {countriesToShow?.length > 0 && (
        <div className={styles.countryListContainer}>
          {countriesToShow.map((country) => {
            const isSelected = country.name === selectedCountry?.name;

            return (
              <SelectableCountry
                key={country.name}
                country={country}
                isSelected={isSelected}
                handleSelectedCountry={handleSelectedCountry}
              />
            );
          })}
        </div>
      )}
      {searchTerm && countries?.length === 0 && (
        <span className={styles.noResults}>{t("noResults")}</span>
      )}
    </div>
  );
};
