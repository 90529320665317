import { Icon } from "arbolus-ui-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ToasterService } from "@arbolus-technologies/api";
import { InternalSlidePanel } from "@arbolus-technologies/features/common";
import { HR } from "@arbolus-technologies/ui/components";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { ClientAddToProject } from "../ClientUserSlidePanel/ClientAddToProject";

import styles from "./ProjectSlidePanel.module.scss";

export interface ProjectSlidePanelProps {
  clientId: string;
  userId: string;
  openPanel: boolean;
  handleCloseProjectsSlidePanel: (refreshUsers: boolean) => void;
}

const notification = new ToasterService();
export const ProjectSlidePanel: React.FC<ProjectSlidePanelProps> = ({
  clientId,
  userId,
  openPanel,
  handleCloseProjectsSlidePanel
}) => {
  const { t } = useTranslation("clientUserListItem");

  const [isProjectAdded, setIsProjectAdded] = useState<boolean>(false);

  const handleClose = () => {
    handleCloseProjectsSlidePanel(isProjectAdded);
    setIsProjectAdded(false);
  };

  const handleUpdateListOnAssignProject = () => {
    setIsProjectAdded(true);
  };

  return (
    <InternalSlidePanel
      customCloseRequest={handleClose}
      isOpen={openPanel}
      width={SIDE_PANEL_SIZE._720}
      hideHeader
    >
      <div className={styles.projectSlidePanelContainer}>
        <div className={styles.projectSlidePanelHeader}>
          <h2>{t("addToProject")}</h2>
          <Icon name="close" fontSize="18px" onClick={handleClose} />
        </div>
        <HR margin={{ top: 3, bottom: 3 }} />
        <ClientAddToProject
          clientId={clientId}
          userId={userId}
          onAddToProject={handleUpdateListOnAssignProject}
          notificationService={notification}
        />
      </div>
    </InternalSlidePanel>
  );
};
