import React from "react";
import { useTranslation } from "react-i18next";

import { eyes } from "@arbolus-technologies/theme";

import styles from "./DNCHistoryListEmpty.module.scss";

export const DNCHistoryListEmpty: React.FC = () => {
  const { t } = useTranslation("dnc");

  return (
    <div className={styles.container}>
      <img
        src={eyes}
        alt={t("noDncHistoryIconAltText")}
        className={styles.image}
      />
      <div className={styles.textContainer}>
        <h1 className={styles.title}>{t("noDncHistoryTitle")}</h1>
        <p className={styles.text}>{t("noDncHistoryText")}</p>
      </div>
    </div>
  );
};
