export enum DocumentType {
  PDF = "PDF",
  XLS = "XLS",
  CSV = "CSV",
  PRESENTATION = "PRESENTATION",
  DOC = "DOC",
  ZIP = "ZIP",
  EXCEL = "EXCEL",
  IMAGE = "IMAGE",
  EVENT = "EVENT",
  TRANSCRIPT = "Transcription",
  GENERIC = "GENERIC"
}
