import { OutputSelector, createSelector } from "reselect";

import { Permission } from "@arbolus-technologies/models/common";

import { CacheStateSelector } from "../CacheSelector";
import { CacheAppState, CacheReducerState } from "../models/definitions";

const MANAGE_COMPANY_DETAILS_PERMISSION = "MANAGE_COMPANY_DETAILS";
const MANAGE_COMPLIANCE_PERMISSION = "MANAGE_COMPLIANCE";

export const currentAdminSpecialPermissionsSelector = (): OutputSelector<
  CacheAppState,
  Permission[],
  (res: CacheReducerState) => Permission[]
> =>
  createSelector<CacheAppState, CacheReducerState, Permission[]>(
    CacheStateSelector,
    (cacheReducerState) => cacheReducerState.specialPermissions ?? []
  );

export const hasManageCompaniesPermissionSelector = (): OutputSelector<
  CacheAppState,
  boolean,
  (res: CacheReducerState) => boolean
> =>
  createSelector<CacheAppState, CacheReducerState, boolean>(
    CacheStateSelector,
    (cacheReducerState) =>
      !!cacheReducerState.specialPermissions?.find(
        (permission) =>
          permission.claimValue === MANAGE_COMPANY_DETAILS_PERMISSION
      )?.active
  );

export const hasManageCompliancePermissionSelector = (): OutputSelector<
  CacheAppState,
  boolean,
  (res: CacheReducerState) => boolean
> =>
  createSelector<CacheAppState, CacheReducerState, boolean>(
    CacheStateSelector,
    (cacheReducerState) =>
      !!cacheReducerState.specialPermissions?.find(
        (permission) => permission.claimValue === MANAGE_COMPLIANCE_PERMISSION
      )?.active
  );
