import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { BoxButton, BoxButtonProps } from "./BoxButton";

export interface AngleBoxButtonProps
  extends Omit<BoxButtonProps, "text" | "icon"> {
  angle: { color: string; title: string };
  onClick?: () => void;
  disabled?: boolean;
}

export const AngleBoxButton: React.FC<AngleBoxButtonProps> = ({
  angle,
  ...props
}) => (
  <BoxButton
    {...props}
    text={angle.title}
    icon={{
      name: "zoom_in_map",
      color: ARBOLUS_COLORS.bColorBaseDark,
      background: angle.color
    }}
  />
);
