import i18next from "i18next";
import { object, string } from "yup";

export interface AdminReportExpertForm {
  feedbackText: string;
}

export const GetAdminReportExpertSchema = object().shape({
  feedbackText: string()
    .required(i18next.t("reportExpert:required"))
    .min(150, i18next.t("reportExpert:minLength", { length: 150 }))
});
