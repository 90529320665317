import { Observable } from "rxjs";

import { PROJECT_CLIENTS_API } from "../constants/api";
import { ApiPaginatedRequest, ApiPaginatedResponse } from "../models/api";
import {
  ProjectClientBillingQueryParams,
  ProjectClientBillingResponse,
  ProjectClientExportTransactionsResponse,
  ProjectClientTotalSpendResponse
} from "../models/projectClient";
import { ListProject } from "../models/projects";
import { restService } from "../restService";

export const ProjectClientService = {
  getBilling: (
    params: ProjectClientBillingQueryParams
  ): Observable<ProjectClientBillingResponse> =>
    restService.get(PROJECT_CLIENTS_API.BILLING(), { ...params }),
  getTotalSpent: (
    projectId: string
  ): Observable<ProjectClientTotalSpendResponse> =>
    restService.get<ProjectClientTotalSpendResponse>(
      PROJECT_CLIENTS_API.TOTAL_SPENT(),
      {
        projectId
      }
    ),
  exportTransactions: (
    projectId: string,
    fromTransactionDate: string | null,
    toTransactionDate: string | null
  ): Observable<ProjectClientExportTransactionsResponse> =>
    restService.get<ProjectClientExportTransactionsResponse>(
      PROJECT_CLIENTS_API.EXPORT_TRANSACTIONS(),
      {
        projectId,
        fromTransactionDate,
        toTransactionDate
      }
    ),
  getClientProjects: (
    apiParams: ApiPaginatedRequest,
    projectState: string
  ): Observable<ApiPaginatedResponse<ListProject>> =>
    restService.get<ApiPaginatedResponse<ListProject>>(
      PROJECT_CLIENTS_API.GET_PROJECTS(),
      { ...apiParams, projectState }
    )
};
