import clsx from "clsx";
import React from "react";

import { IAvatar } from "@arbolus-technologies/models/common";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  AvatarGroup,
  NotificationBadge,
  NotificationDot
} from "@arbolus-technologies/ui/components";

import styles from "./ChatListItem.module.scss";

interface ChatListItemProps {
  name: string;
  avatars: IAvatar[];
  isActive?: boolean;
  unreadCount: number;
  onClick?: () => void;
  isCurrentChatMemberOnline?: boolean;
}

export const ChatListItem: React.FC<ChatListItemProps> = ({
  name,
  avatars,
  isActive,
  unreadCount,
  onClick,
  isCurrentChatMemberOnline = false
}) => (
  <div
    className={clsx(styles.chatListItem, {
      [styles.activeItem]: isActive,
      [styles.disabled]: isActive
    })}
    onClick={onClick}
  >
    <div className={styles.avatarContainer}>
      <AvatarGroup
        avatars={avatars}
        totalCount={avatars.length}
        type="avatarGroupCircleSmall"
      />
      {isCurrentChatMemberOnline && (
        <NotificationDot
          offsetTop="0"
          offsetRight="-8px"
          size="8px"
          color={ARBOLUS_COLORS.bColorSecondaryGreen}
        />
      )}
    </div>
    <span className={styles.name} title={name}>
      {name}
    </span>
    {unreadCount > 0 && <NotificationBadge count={unreadCount} />}
  </div>
);
