import { Icon } from "arbolus-ui-components";
import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./ApproveRejectButtons.module.scss";

interface ApproveRejectButtonsProps {
  handleOnApprove: () => void;
  handleOnReject: () => void;
  disabled?: boolean;
}

export const ApproveRejectButtons: React.FC<ApproveRejectButtonsProps> = ({
  handleOnApprove,
  handleOnReject,
  disabled
}) => (
  <>
    <div className={styles.iconReject}>
      <Icon
        name="close"
        fontSize="16px"
        color={ARBOLUS_COLORS.bColorBaseWhite}
        onClick={handleOnReject}
        disabled={disabled}
      />
    </div>
    <div className={styles.iconApprove}>
      <Icon
        name="done"
        fontSize="16px"
        color={ARBOLUS_COLORS.bColorBaseWhite}
        onClick={handleOnApprove}
        disabled={disabled}
      />
    </div>
  </>
);
