import { Observable } from "rxjs";

import { TRANSCRIPTS_API } from "../constants/api";
import { getMultiSortParams } from "../helpers";
import {
  ApiPaginatedResponseTranscriptions,
  CreatedResponse,
  DeletedResponse,
  SuccessResponse
} from "../models/api";
import {
  CreateTranscriptHighlightRequest,
  ListTranscriptModel,
  ListTranscriptsParams,
  Transcript,
  UpdateSpeakerResponse
} from "../models/transcript";
import { restService } from "../restService";

type TranscriptStatusResponse = {
  id: string;
  jobStatus: "Completed" | "Pending";
  language: string;
};

export const TranscriptService = {
  getTranscriptData: (
    projectId: string,
    transcriptId: string
  ): Observable<Transcript> =>
    restService.get<Transcript>(
      TRANSCRIPTS_API.GET_TRANSCRIPT_DATA(projectId, transcriptId)
    ),
  listTranscripts: (
    projectId: string,
    params: ListTranscriptsParams
  ): Observable<ApiPaginatedResponseTranscriptions<ListTranscriptModel>> =>
    restService.get<ApiPaginatedResponseTranscriptions<ListTranscriptModel>>(
      TRANSCRIPTS_API.LIST_TRANSCRIPTS(projectId),
      getMultiSortParams(params)
    ),
  updateTranscriptSpeaker: (
    projectId: string,
    transcriptId: string,
    speakerId: string,
    name: string,
    userId?: string
  ): Observable<UpdateSpeakerResponse> =>
    restService.post<UpdateSpeakerResponse>(
      TRANSCRIPTS_API.UPDATE_TRANSCRIPT_SPEAKER(projectId, transcriptId),
      {
        speakerId,
        name,
        userId
      }
    ),
  deleteTranscript: (
    projectId: string,
    transcriptId: string
  ): Observable<DeletedResponse> =>
    restService.delete<DeletedResponse>(
      TRANSCRIPTS_API.DELETE_TRANSCRIPT(projectId, transcriptId)
    ),
  createTranscriptHighlight: (
    projectId: string,
    transcriptId: string,
    requestData: CreateTranscriptHighlightRequest
  ): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(
      TRANSCRIPTS_API.TRANSCRIPT_HIGHLIGHT(projectId, transcriptId),
      requestData
    ),
  deleteTranscriptHighlights: (
    projectId: string,
    transcriptId: string,
    monologueId: string,
    highlightIds: string[]
  ): Observable<SuccessResponse> =>
    restService.delete<SuccessResponse>(
      TRANSCRIPTS_API.TRANSCRIPT_HIGHLIGHT(projectId, transcriptId),
      undefined,
      { highlightIds, monologueId }
    ),
  requestHumanTranscript: (
    projectId: string,
    transcriptId: string
  ): Observable<SuccessResponse> =>
    restService.post<SuccessResponse>(
      TRANSCRIPTS_API.REQUEST_HUMAN_TRANSCRIPT(projectId, transcriptId),
      null
    ),
  regenerateTranscript: (
    projectId: string,
    transcriptId: string,
    language: string
  ): Observable<SuccessResponse> =>
    restService.post<SuccessResponse>(
      TRANSCRIPTS_API.REGENERATE_TRANSCRIPT(projectId, transcriptId, language),
      null
    ),
  getTranscriptStatus: (
    projectId: string,
    transcriptId: string
  ): Observable<TranscriptStatusResponse> =>
    restService.get<TranscriptStatusResponse>(
      TRANSCRIPTS_API.GET_TRANSCRIPT_STATUS(projectId, transcriptId)
    )
};
