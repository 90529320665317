import { Icon } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";

import { CanopyQuestion } from "@arbolus-technologies/api";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./QuestionsTimer.module.scss";

interface QuestionsTimerProps {
  questions: CanopyQuestion[];
}

export const QuestionsTimer: React.FC<QuestionsTimerProps> = ({
  questions
}) => {
  const { t } = useTranslation();

  const handleQuestionsTimer = () => {
    const questionsLength = questions.length;
    if (questionsLength >= 1 && questionsLength <= 3) {
      return 5;
    }
    if (questionsLength >= 4 && questionsLength <= 9) {
      return 10;
    }
    if (questionsLength >= 10) {
      return 15;
    }
  };

  return (
    <div className={styles.timeContainer}>
      <Icon
        name="schedule"
        fontSize="24px"
        color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
      />
      <p className={styles.time}>
        {handleQuestionsTimer()}
        {t("mins")}
      </p>
    </div>
  );
};
