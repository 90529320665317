import { ActionCreator } from "redux";

import {
  AngleResponse,
  AngleToSend,
  CIQError,
  ErrorResponse,
  QuestionsResponse
} from "@arbolus-technologies/api";
import { AngleModel } from "@arbolus-technologies/models/common";
import { AngleLink } from "@arbolus-technologies/models/project";

import {
  ADD_NEW_ANGLE,
  ADD_NEW_ANGLE_FAILURE,
  ADD_NEW_ANGLE_SUCCESS,
  ADD_SCREENING_QUESTIONS_VIEW,
  AddNewAngleAction,
  AddNewAngleFailureAction,
  AddNewAngleSuccessAction,
  AddScreeningQuestionsView,
  EDIT_ANGLE,
  EDIT_ANGLE_FAILURE,
  EDIT_ANGLE_QUESTIONS_ADMIN,
  EDIT_ANGLE_QUESTIONS_ADMIN_FAILURE,
  EDIT_ANGLE_QUESTIONS_ADMIN_SUCCESS,
  EDIT_ANGLE_SUCCESS,
  EditAngleAction,
  EditAngleFailureAction,
  EditAngleQuestionsAdminAction,
  EditAngleQuestionsAdminFailureAction,
  EditAngleQuestionsAdminSuccessAction,
  EditAngleSuccessAction,
  GET_ANGLES_CARDS,
  GET_ANGLES_CARDS_FAILURE,
  GET_ANGLES_CARDS_SUCCESS,
  GET_ANGLES_LINK,
  GET_ANGLES_LINK_FAILURE,
  GET_ANGLES_LINK_SUCCESS,
  GET_ANGLE_QUESTIONS,
  GET_ANGLE_QUESTIONS_FAILURE,
  GET_ANGLE_QUESTIONS_SUCCESS,
  GET_CLIENT_ID_BY_ADMIN,
  GET_SINGLE_ANGLE,
  GET_SINGLE_ANGLE_FAILURE,
  GET_SINGLE_ANGLE_SUCCESS,
  GetAngleQuestionsAction,
  GetAngleQuestionsFailureAction,
  GetAngleQuestionsSuccessAction,
  GetAnglesCardsAction,
  GetAnglesCardsFailureAction,
  GetAnglesCardsSuccessAction,
  GetAnglesLinkAction,
  GetAnglesLinkFailureAction,
  GetAnglesLinkSuccessAction,
  GetClientIdByAdminAction,
  GetSingleAngleAction,
  GetSingleAngleFailureAction,
  GetSingleAngleSuccessAction,
  NO_SCREENING_QUESTIONS_VIEW,
  NoScreeningQuestionsView,
  RESET_ANGLES_LINK,
  RESET_SELECTED_ANGLE,
  ResetAnglesLinkAction,
  ResetSelectedAngleAction,
  SET_AVAILABLE_COLORS,
  SET_FORM_CREATE_EDIT_ANGLE_DISABLED,
  SET_FORM_CREATE_EDIT_ANGLE_ENABLED,
  SetAvailableColorsAction,
  SetFormCreateEditAngleDisabledAction,
  SetFormCreateEditAngleEnabledAction
} from "./ProjectAnglesActionTypes";

export const getAnglesCards: ActionCreator<GetAnglesCardsAction> = (
  projectId: string
) => ({
  type: GET_ANGLES_CARDS,
  payload: {
    projectId
  }
});

export const getAnglesCardsSuccess: ActionCreator<
  GetAnglesCardsSuccessAction
> = (anglesCards: AngleModel[]) => ({
  type: GET_ANGLES_CARDS_SUCCESS,
  payload: {
    anglesCards
  }
});

export const getAnglesCardsFailure: ActionCreator<
  GetAnglesCardsFailureAction
> = (error: ErrorResponse<CIQError>) => ({
  type: GET_ANGLES_CARDS_FAILURE,
  payload: {
    error
  }
});

// GET ANGLES LINK
export const getAnglesLink = (
  projectId: string,
  angleId?: string
): GetAnglesLinkAction => ({
  type: GET_ANGLES_LINK,
  payload: {
    projectId,
    angleId
  }
});

export const getAnglesLinkSuccess = (
  linksResponse: AngleLink[],
  angleId?: string
): GetAnglesLinkSuccessAction => ({
  type: GET_ANGLES_LINK_SUCCESS,
  payload: {
    linksResponse,
    angleId
  }
});

export const getAnglesLinkFailure = (
  error: ErrorResponse<CIQError>
): GetAnglesLinkFailureAction => ({
  type: GET_ANGLES_LINK_FAILURE
});

// RESET ANGLES LINK
export const resetAnglesLink = (): ResetAnglesLinkAction => ({
  type: RESET_ANGLES_LINK
});

// GET SINGLE ANGLE
export const getSingleAngle = (angleId: string): GetSingleAngleAction => ({
  type: GET_SINGLE_ANGLE,
  payload: {
    angleId
  }
});

export const getSingleAngleSuccess = (
  selectedAngle: AngleResponse
): GetSingleAngleSuccessAction => ({
  type: GET_SINGLE_ANGLE_SUCCESS,
  payload: {
    selectedAngle
  }
});

export const getSingleAngleFailure = (
  error: ErrorResponse<CIQError>
): GetSingleAngleFailureAction => ({
  type: GET_SINGLE_ANGLE_FAILURE,
  payload: {
    error
  }
});

// GET ANGLE QUESTIONS
export const getAngleQuestions = (
  angleId: string
): GetAngleQuestionsAction => ({
  type: GET_ANGLE_QUESTIONS,
  payload: {
    angleId
  }
});

export const getAngleQuestionsSuccess = (
  response: QuestionsResponse
): GetAngleQuestionsSuccessAction => ({
  type: GET_ANGLE_QUESTIONS_SUCCESS,
  payload: {
    response
  }
});

export const getAngleQuestionsFailure = (
  error: ErrorResponse<CIQError>
): GetAngleQuestionsFailureAction => ({
  type: GET_ANGLE_QUESTIONS_FAILURE,
  payload: {
    error
  }
});

// EDIT ANGLE QUESTIONS ADMIN
export const editAngleQuestionsAdmin = (
  angleId: string,
  questions: { questions: string[] }
): EditAngleQuestionsAdminAction => ({
  type: EDIT_ANGLE_QUESTIONS_ADMIN,
  payload: {
    angleId,
    questions
  }
});

export const editAngleQuestionsAdminSuccess = (
  response: QuestionsResponse
): EditAngleQuestionsAdminSuccessAction => ({
  type: EDIT_ANGLE_QUESTIONS_ADMIN_SUCCESS,
  payload: {
    response
  }
});

export const editAngleQuestionsAdminFailure = (
  error: ErrorResponse<CIQError>
): EditAngleQuestionsAdminFailureAction => ({
  type: EDIT_ANGLE_QUESTIONS_ADMIN_FAILURE,
  payload: {
    error
  }
});

// GET CLIENT ID
export const getClientIdByAdmin = (
  clientId: string
): GetClientIdByAdminAction => ({
  type: GET_CLIENT_ID_BY_ADMIN,
  payload: {
    clientId
  }
});

//EDIT ANGLE
export const editAngle = (
  angleId: string,
  angleData: AngleToSend
): EditAngleAction => ({
  type: EDIT_ANGLE,
  payload: {
    angleId,
    angleData
  }
});

export const editAngleSuccess = (
  selectedAngle: AngleResponse
): EditAngleSuccessAction => ({
  type: EDIT_ANGLE_SUCCESS,
  payload: {
    selectedAngle
  }
});

export const editAngleFailure = (
  error: ErrorResponse<CIQError>
): EditAngleFailureAction => ({
  type: EDIT_ANGLE_FAILURE,
  payload: {
    error
  }
});

//ADD NEW ANGLE
export const addNewAngle = (
  angleData: AngleToSend,
  projectId: string
): AddNewAngleAction => ({
  type: ADD_NEW_ANGLE,
  payload: {
    angleData,
    projectId
  }
});

export const addNewAngleSuccess = (): AddNewAngleSuccessAction => ({
  type: ADD_NEW_ANGLE_SUCCESS
});

export const addNewAngleFailure = (
  error: ErrorResponse<CIQError>
): AddNewAngleFailureAction => ({
  type: ADD_NEW_ANGLE_FAILURE,
  payload: {
    error
  }
});

export const resetSelectedAngle = (): ResetSelectedAngleAction => ({
  type: RESET_SELECTED_ANGLE
});

//IsFormDisabled
export const setFormCreateEditAngleDisabled =
  (): SetFormCreateEditAngleDisabledAction => ({
    type: SET_FORM_CREATE_EDIT_ANGLE_DISABLED
  });

export const setFormCreateEditAngleEnabled =
  (): SetFormCreateEditAngleEnabledAction => ({
    type: SET_FORM_CREATE_EDIT_ANGLE_ENABLED
  });

export const addScreeningQuestionsView = (): AddScreeningQuestionsView => ({
  type: ADD_SCREENING_QUESTIONS_VIEW
});

export const noScreeningQuestionsView = (): NoScreeningQuestionsView => ({
  type: NO_SCREENING_QUESTIONS_VIEW
});

export const setAvailableColors: ActionCreator<SetAvailableColorsAction> = (
  anglesCards: AngleModel[]
) => ({
  type: SET_AVAILABLE_COLORS,
  payload: {
    anglesCards
  }
});

export const ProjectAnglesStoreActions = {
  getAnglesCards,
  getAnglesCardsSuccess,
  getAnglesCardsFailure,
  getAnglesLink,
  getAnglesLinkSuccess,
  getAnglesLinkFailure,
  getSingleAngle,
  getSingleAngleSuccess,
  getSingleAngleFailure,
  getClientIdByAdmin,
  getAngleQuestions,
  getAngleQuestionsSuccess,
  getAngleQuestionsFailure,
  editAngle,
  editAngleSuccess,
  editAngleFailure,
  addNewAngle,
  addNewAngleSuccess,
  addNewAngleFailure,
  resetSelectedAngle,
  setFormCreateEditAngleDisabled,
  setFormCreateEditAngleEnabled,
  addScreeningQuestionsView,
  noScreeningQuestionsView,
  editAngleQuestionsAdmin,
  editAngleQuestionsAdminSuccess,
  editAngleQuestionsAdminFailure,
  setAvailableColors,
  resetAnglesLink
};
