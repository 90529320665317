import clsx from "clsx";
import React from "react";

import { ProjectMember } from "../../../../../models/view/project";
import MemberItem from "./MemberItem";
import { MEMBER_TYPE } from "./TeamPanel";

interface MemberListProps {
  memberType: MEMBER_TYPE;
  members: ProjectMember[];
  loadingUsers: string[];
  currentUserId?: string;
  onAddMember?: (memberId: string) => void;
  onRemoveMember?: (memberId: string, userId: string) => void;
  onInviteMember?: (email: string) => void;
  onClientUserSelected?: (user: ProjectMember) => void;
}

const MemberList: React.FC<MemberListProps> = ({
  members,
  onRemoveMember,
  onAddMember,
  memberType,
  loadingUsers,
  currentUserId,
  onClientUserSelected
}) => {
  const renderMemberList = (): JSX.Element => {
    if (memberType === MEMBER_TYPE.PROJECT) {
      // eslint-disable-next-line no-unused-expressions
      members?.sort((x, y) =>
        // eslint-disable-next-line no-nested-ternary
        x.userId === currentUserId ? -1 : y.userId === currentUserId ? 1 : 0
      );
    }

    return (
      <>
        {members.map((member) => (
          <div
            className={clsx("user-item", {
              disabled: loadingUsers.includes(member.userId!)
            })}
            key={member.userId}
          >
            <MemberItem
              member={member}
              onRemoveMember={(): void =>
                onRemoveMember!(member.memberId!, member.userId!)
              }
              isCurrentUser={currentUserId === member.userId}
              onAddMember={onAddMember}
              removable={memberType === MEMBER_TYPE.PROJECT}
              onClientUserSelected={onClientUserSelected}
            />
            {memberType === MEMBER_TYPE.PROJECT && !member.emailConfirmed && (
              <div className="user-status">Pending</div>
            )}
          </div>
        ))}
      </>
    );
  };

  return renderMemberList();
};

export default MemberList;
