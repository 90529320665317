import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import "moment-timezone";

export const useFromDateRange = (
  timezone: string,
  numDays = 2,
  from?: moment.Moment
): {
  setFromDateRange: (pageIndex: number, isFirstPage?: boolean) => void;
  dates: moment.Moment[];
  startDate: string;
  endDate: string;
} => {
  const [dates, setDates] = useState<moment.Moment[]>([]);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  useEffect(() => {
    const dateRange: moment.Moment[] = [];
    const dayFirst = from || moment();
    for (let i = 0; i < numDays; i++) {
      const day = moment(dayFirst).tz(timezone).add(i, "days");
      dateRange.push(day);
    }

    setDates(dateRange);
    setStartDate(dateRange[0].toISOString());
    setEndDate(getEndDateTime(dateRange[dateRange.length - 1]).toISOString());
  }, [from, numDays, timezone]);

  const getEndDateTime = (date: moment.Moment) =>
    date.clone().add(1, "days").startOf("day");

  const setFromDateRange = useCallback(
    (pageIndex: number, isFirstPage = false) => {
      const dateRange: moment.Moment[] = [...dates];
      dateRange.forEach((day) => {
        day.add(pageIndex * 3, "days");
      });

      setDates(dateRange);

      setStartDate(
        (isFirstPage
          ? moment(from || moment()).tz(timezone)
          : dateRange[0].startOf("day")
        ).toISOString()
      );
      setEndDate(getEndDateTime(dateRange[dateRange.length - 1]).toISOString());
    },
    [dates, from, timezone]
  );

  return {
    setFromDateRange,
    dates,
    startDate,
    endDate
  };
};
