import { OperatorFunction } from "rxjs";
import { map } from "rxjs/operators";

import { PagedListResponse } from "../models/api";

export const mapPagedListResponse = <TSource, TDest>(
  mapper: (src: TSource) => TDest
): OperatorFunction<PagedListResponse<TSource>, PagedListResponse<TDest>> =>
  map((listResponse) => transform(listResponse, mapper));

const transform = <TSource, TDest>(
  source: PagedListResponse<TSource>,
  mapper: (src: TSource) => TDest
): PagedListResponse<TDest> => ({
  pagination: source.pagination,
  items: source.items.map(mapper)
});
