/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Control, FieldError } from "react-hook-form";

import { InputController } from "../InputController/InputController";
import {
  InternalArbolusModal,
  InternalArbolusModalProps
} from "./InternalArbolusModal";

import styles from "./ArbolusModalFillable.module.scss";

interface ArbolusModalFillableProps extends InternalArbolusModalProps {
  control: Control<any>;
  placeholder: string;
  name: string;
  error?: FieldError;
}

export const ArbolusModalFillable: React.FC<ArbolusModalFillableProps> = ({
  title,
  isOpen,
  toggle,
  iconTitle,
  leftButton,
  rightButton,
  control,
  placeholder,
  name,
  error
}) => (
  <InternalArbolusModal
    title={title}
    isOpen={isOpen}
    toggle={toggle}
    iconTitle={iconTitle}
    leftButton={leftButton}
    rightButton={rightButton}
    body={
      <InputController
        classnames={styles.input}
        control={control}
        placeholder={placeholder}
        name={name}
        error={error}
        required
      />
    }
  />
);
