import { GridApi } from "ag-grid-community";
import i18next from "i18next";
import { forkJoin } from "rxjs";

import {
  DefaultReferralsService,
  DefaultToasterService,
  IReferralsService,
  ProjectService,
  ToasterService
} from "@arbolus-technologies/api";
import { IModalService } from "@arbolus-technologies/features/common";
import {
  APPLICATION_STATUS,
  DO_NOT_CONTACT_STATUS,
  Referral
} from "@arbolus-technologies/models/common";
import { ExtractMethods } from "@arbolus-technologies/utils";

import { EXPERT_PROFILE } from "@arbolus-technologies/routes";
import { LocationDescriptorObject } from "history";
import { ReloadReferralsCount } from "../../Models/ReferralsTable";
import { BulkBaseService, ForkJoinResults } from "./BulkBaseService";

export type IBulkReferralActionsService =
  ExtractMethods<BulkReferralActionsService>;

const t = (key: string) => i18next.t(`referrals:actions:${key}`);

export class BulkReferralActionsService extends BulkBaseService {
  private _referralsService: IReferralsService;
  private _projectService: typeof ProjectService;

  constructor(
    referralsService: IReferralsService,
    projectService: typeof ProjectService,
    toasterService: ToasterService
  ) {
    super(toasterService);
    this._referralsService = referralsService;
    this._projectService = projectService;
  }

  private processReferralsWithIncompleteWorkHistory(referrals: Referral[]): {
    incompleteWorkHistoryNames: {
      text: string;
      to: LocationDescriptorObject;
    }[];
    hasReferralWithoutWorkHistory: boolean;
  } {
    const filterReferralsWithIncompleteWorkHistory = (
      referrals: Referral[]
    ): Referral[] =>
      referrals.filter(
        (referral) =>
          referral.hasMissingWorkHistoryLocation &&
          referral.expert.doNotContactStatus !== DO_NOT_CONTACT_STATUS.DNC
      );

    const referralsWithIncompleteWorkHistory =
      filterReferralsWithIncompleteWorkHistory(referrals);

    const incompleteWorkHistoryNames = referralsWithIncompleteWorkHistory.map(
      (referral) => ({
        text: `${referral.expert.firstName} ${referral.expert.lastName}`,
        to: EXPERT_PROFILE(referral.expert.expertId)
      })
    );

    const hasReferralWithoutWorkHistory =
      referralsWithIncompleteWorkHistory.length > 0;

    return {
      incompleteWorkHistoryNames,
      hasReferralWithoutWorkHistory
    };
  }

  public resetApplication(
    projectId: string,
    referrals: Referral[],
    modalService: IModalService,
    api: GridApi
  ): void {
    const onConfirm = () => {
      const resetRequest = (referral: Referral) =>
        this._referralsService.resetApplication(
          projectId,
          referral.expert.expertId
        );
      const next = (results: ForkJoinResults) => {
        this.next(
          results,
          referrals,
          (results) =>
            this.updateNodes(api, results, {
              application: APPLICATION_STATUS.SENT
            }),
          t("resetApplicationSuccess"),
          modalService
        );
      };
      const requests = this.createObservables(referrals, resetRequest);
      forkJoin(requests).subscribe({
        next,
        error: this._toasterService.showApiErrors
      });
    };
    modalService.openMainModal({
      title: t("resetApplicationTitle"),
      subtitle: t("resetApplicationSubtitle"),
      type: "rejection",
      onConfirm
    });
  }

  public sendApplication(
    projectId: string,
    referrals: Referral[],
    modalService: IModalService,
    api: GridApi
  ): void {
    const { incompleteWorkHistoryNames, hasReferralWithoutWorkHistory } =
      this.processReferralsWithIncompleteWorkHistory(referrals);

    const onConfirm = () => {
      const applicationRequests = (referral: Referral) =>
        this._referralsService.sendApplication(
          projectId,
          referral.expert.expertId
        );
      const next = (results: ForkJoinResults) => {
        this.next(
          results,
          referrals,
          (results) =>
            this.updateNodes(api, results, {
              application: APPLICATION_STATUS.SENT
            }),
          t("sendApplicationSuccess"),
          modalService
        );
      };
      const requests = this.createObservables(referrals, applicationRequests);
      forkJoin(requests).subscribe({
        next,
        error: this._toasterService.showApiErrors
      });
    };

    if (hasReferralWithoutWorkHistory) {
      modalService.openListMainModal({
        messages: incompleteWorkHistoryNames,
        title: t("incompleteWorkHistoryTitle"),
        confirmText: t("yesIWantToProceed"),
        onConfirm: () => {
          modalService.closeListMainModal();
          onConfirm();
        }
      });
    } else {
      modalService.openMainModal({
        title: t("sendApplicationTitle"),
        subtitle: t("sendApplicationSubtitle"),
        onConfirm
      });
    }
  }

  public sendApplicationReminder(
    projectId: string,
    referrals: Referral[],
    modalService: IModalService
  ): void {
    const { incompleteWorkHistoryNames, hasReferralWithoutWorkHistory } =
      this.processReferralsWithIncompleteWorkHistory(referrals);

    const onConfirm = () => {
      const applicationRequests = (referral: Referral) =>
        this._projectService.sendApplicationReminder(
          projectId,
          referral.expert.expertId
        );
      const next = (results: ForkJoinResults) => {
        this.next(
          results,
          referrals,
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          (_) => {},
          t("applicationReminderSent"),
          modalService
        );
      };
      const requests = this.createObservables(referrals, applicationRequests);
      forkJoin(requests).subscribe({
        next,
        error: this._toasterService.showApiErrors
      });
    };

    if (hasReferralWithoutWorkHistory) {
      modalService.openListMainModal({
        messages: incompleteWorkHistoryNames,
        title: t("incompleteWorkHistoryTitle"),
        confirmText: t("yesIWantToProceed"),
        onConfirm: () => {
          modalService.closeListMainModal();
          onConfirm();
        }
      });
    } else {
      modalService.openMainModal({
        title: t("sendApplicationReminderTitle"),
        subtitle: t("sendApplicationReminderSubtitle"),
        onConfirm
      });
    }
  }

  public removeFromProject(
    projectId: string,
    referrals: Referral[],
    modalService: IModalService,
    api: GridApi,
    reloadReferralsCount: ReloadReferralsCount
  ): void {
    const onConfirm = () => {
      const removeRequest = (referral: Referral) =>
        this._projectService.removeFromProject(projectId, referral.id);
      const next = (results: ForkJoinResults) => {
        this.next(
          results,
          referrals,
          (results) => {
            this.removeNodes(api, results);
            reloadReferralsCount(projectId);
          },
          t("removeExpertSuccess"),
          modalService
        );
      };
      const requests = this.createObservables(referrals, removeRequest);
      forkJoin(requests).subscribe({
        next,
        error: this._toasterService.showApiErrors
      });
    };
    modalService.openMainModal({
      title: t("removeExpertTitle"),
      subtitle: t("removeExpertSubtitle"),
      confirmText: t("removeExpertConfirmText"),
      type: "rejection",
      onConfirm
    });
  }

  public moveToCandidate(
    projectId: string,
    referrals: Referral[],
    modalService: IModalService,
    api: GridApi,
    reloadReferralsCount: ReloadReferralsCount
  ): void {
    const hasPublicCompany = referrals.some(
      (referral) => referral.expert.lastPublicCompanyExpDate
    );
    const onConfirm = () => {
      const candidateRequest = (referral: Referral) =>
        this._referralsService.moveToCandidate(
          projectId,
          referral.expert.expertId
        );
      const next = (results: ForkJoinResults) => {
        this.next(
          results,
          referrals,
          (results) => {
            this.removeNodes(api, results);
            reloadReferralsCount(projectId);
          },
          t("moveToCandidateSuccess"),
          modalService
        );
      };
      const requests = this.createObservables(referrals, candidateRequest);
      forkJoin(requests).subscribe({
        next,
        error: this._toasterService.showApiErrors
      });
    };
    modalService.openMainModal({
      title: hasPublicCompany
        ? t("moveToCandidateTitlePublicCompany")
        : t("moveToCandidateTitle"),
      subtitle: hasPublicCompany
        ? t("moveToCandidateSubtitlePublicCompany")
        : t("moveToCandidateSubtitle"),
      onConfirm
    });
  }

  public reactivate(
    projectId: string,
    referrals: Referral[],
    modalService: IModalService,
    api: GridApi,
    reloadReferralsCount: ReloadReferralsCount
  ): void {
    const onConfirm = () => {
      const reactivateRequest = (referral: Referral) =>
        this._referralsService.reactive(projectId, referral.expert.expertId);
      const next = (results: ForkJoinResults) => {
        this.next(
          results,
          referrals,
          (results) => {
            this.removeNodes(api, results);
            reloadReferralsCount(projectId);
          },
          t("reactivateSuccess"),
          modalService
        );
      };
      const requests = this.createObservables(referrals, reactivateRequest);
      forkJoin(requests).subscribe({
        next,
        error: this._toasterService.showApiErrors
      });
    };
    modalService.openMainModal({
      title: t("reactivateTitle"),
      subtitle: t("reactivateSubtitle"),
      onConfirm
    });
  }
}

export const DefaultBulkReferralActionsService = new BulkReferralActionsService(
  DefaultReferralsService,
  ProjectService,
  DefaultToasterService
);
