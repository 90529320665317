import { Button } from "arbolus-ui-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { AddCollectionModal } from "../AddCollectionModal/AddCollectionModal";

interface AddCollectionButtonProps {
  onSuccess?: (id: string) => void;
}

export const AddCollectionButton: React.FC<AddCollectionButtonProps> = ({
  onSuccess
}) => {
  const { t } = useTranslation("bookmarkCollections");
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <AddCollectionModal
        isOpen={isOpen}
        close={() => setIsOpen(false)}
        onSuccess={onSuccess}
      />
      <Button
        text={t("addCollection")}
        endIcon="add"
        onClick={() => setIsOpen((p) => !p)}
      />
    </>
  );
};
