import { Button } from "arbolus-ui-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import {
  AnglesService,
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  PROJECT_REFERRAL_STATE,
  ToasterService
} from "@arbolus-technologies/api";
import { AngleModel } from "@arbolus-technologies/models/common";
import { PROJECT_ALL_REFERRALS_REVIEW } from "@arbolus-technologies/routes";
import { ProjectNxStoreActions } from "@arbolus-technologies/stores/project";

import { WorkWithMePanel } from "./WorkWithMePanel";

interface WorkWithMeButtonProps {
  expertId: string;
  angles: AngleModel[];
  projectId: string;
  anglesService?: typeof AnglesService;
  notificationService?: ToasterService;
}

export const WorkWithMeButton: React.FC<WorkWithMeButtonProps> = ({
  expertId,
  angles,
  projectId,
  anglesService = AnglesService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("discoverPage");
  const dispatch = useDispatch();
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expertsAssigned, setExpertsAssigned] = useState<string[]>([]);
  const isExpertAssigned = expertsAssigned.includes(expertId);

  const addExpertReferral = (angleId: string, expertId: string) => {
    anglesService
      .assignAngle(angleId, {
        expertId,
        state: PROJECT_REFERRAL_STATE.CANDIDATE
      })
      .subscribe(
        () => {
          setExpertsAssigned([...expertsAssigned, expertId]);
          // Update the candidate picker panel referrals to show latest state
          dispatch(ProjectNxStoreActions.refetchReferrals(projectId));
          notificationService.showAction(t("workWithMeSuccess"), {
            label: t("goToReviewButton"),
            onClick: () => history.push(PROJECT_ALL_REFERRALS_REVIEW(projectId))
          });
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showError(error.message);
        }
      );
  };

  const handleConfirm = (selectedAngle: AngleModel) => {
    addExpertReferral(selectedAngle.id, expertId);
  };

  return (
    <>
      <Button
        text={t("workWithMe")}
        onClick={() => setIsModalOpen(true)}
        endIcon="chevron_right"
        type="primary"
        size="small"
        disabled={isExpertAssigned}
      />
      <WorkWithMePanel
        onConfirm={handleConfirm}
        isModalOpen={isModalOpen}
        togglePanel={() => setIsModalOpen((prev) => !prev)}
        angles={angles}
      />
    </>
  );
};
