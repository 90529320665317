import {
  fiveSlotsIcon,
  fourSlotsIcon,
  oneSlotIcon,
  threeSlotsIcon,
  twoSlotsIcon,
  zeroSlotsIcon
} from "@arbolus-technologies/theme";

export const SLOT_ICONS = {
  icon_0: zeroSlotsIcon,
  icon_1: oneSlotIcon,
  icon_2: twoSlotsIcon,
  icon_3: threeSlotsIcon,
  icon_4: fourSlotsIcon,
  icon_5: fiveSlotsIcon
} as const;
