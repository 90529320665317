import React from "react";
import { useTranslation } from "react-i18next";

import { ExpertsListPageTab } from "@arbolus-technologies/api";

import { ReactComponent as NoAvailabilitiesIcon } from "../../../assets/images/no-availabilities.svg";

import styles from "./NoData.module.scss";

type TabWithoutBook = Exclude<ExpertsListPageTab, ExpertsListPageTab.Book>;

interface NoDataProps {
  tabActive: TabWithoutBook;
}

export const NoData: React.FC<NoDataProps> = ({ tabActive }) => {
  const { t } = useTranslation("projectExpertListEmpty");
  const messages: {
    [key in TabWithoutBook]: {
      title: string;
      subTitle: string;
    };
  } = {
    [ExpertsListPageTab.Accept]: {
      title: t("approvedListEmptyTitle"),
      subTitle: t("approvedEmptyDescription")
    },
    [ExpertsListPageTab.Reject]: {
      title: t("rejectedListEmptyTitle"),
      subTitle: t("rejectedEmptyDescription")
    },
    [ExpertsListPageTab.Review]: {
      title: t("reviewListEmptyTitle"),
      subTitle: t("reviewEmptyDescription")
    },
    [ExpertsListPageTab.Recommendations]: {
      title: t("recommendationsListEmptyTitle"),
      subTitle: t("recommendationsEmptyDescription")
    },
    [ExpertsListPageTab.ShortList]: {
      title: t("shortListEmptyTitle"),
      subTitle: t("shortListEmptyDescription")
    },
    [ExpertsListPageTab.Candidate]: {
      title: t("candidateListEmptyTitle"),
      subTitle: t("candidateEmptyDescription")
    },
    [ExpertsListPageTab.AllExperts]: {
      title: t("allExpertsListEmptyTitle"),
      subTitle: t("allExpertsListEmptyDescription")
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <span className={styles.title}>
          <NoAvailabilitiesIcon />
          {messages[tabActive].title}
        </span>
        <span className={styles.subTitle}>{messages[tabActive].subTitle}</span>
      </div>
    </div>
  );
};
