import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  AdminService,
  DNCHistory,
  DNCHistoryModel
} from "@arbolus-technologies/api";

import { DNCHistoryItem } from "../DNCHistoryItem/DNCHistoryItem";
import { DNCHistoryListEmpty } from "./DNCHistoryListEmpty/DNCHistoryListEmpty";

import styles from "./DNCHistoryList.module.scss";

interface DNCHistoryListProps {
  expertId: string;
  shouldRefetchHistory?: boolean;
  canEditComment?: boolean;
  handleSetShouldRefetchHistory?: (shouldRefetchHistory: boolean) => void;
  updateExpertDncHistory?: (dncHistory: DNCHistory) => void;
  adminService?: typeof AdminService;
}

export const DNCHistoryList: React.FC<DNCHistoryListProps> = ({
  expertId,
  canEditComment = false,
  shouldRefetchHistory = false,
  handleSetShouldRefetchHistory,
  updateExpertDncHistory,
  adminService = AdminService
}) => {
  const { t } = useTranslation("dnc");
  const [expertDncHistory, setExpertDncHistory] = useState<DNCHistoryModel[]>(
    []
  );

  const getExpertDncHistory = () => {
    adminService.getExpertDNCHistory(expertId).subscribe((response) => {
      setExpertDncHistory(response.items);
      updateExpertDncHistory?.(response);
      handleSetShouldRefetchHistory?.(false);
    });
  };

  useEffect(() => {
    getExpertDncHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    shouldRefetchHistory && getExpertDncHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetchHistory]);

  return (
    <>
      {expertDncHistory.length === 0 && (
        <div className={styles.emptyContainer}>
          <DNCHistoryListEmpty />
        </div>
      )}
      {expertDncHistory.length > 0 && (
        <div className={styles.listContainer}>
          <h1 className={styles.title}>{t("history")}</h1>
          {expertDncHistory.map((dncHistory, index) => (
            <DNCHistoryItem
              key={dncHistory.created}
              dncHistory={dncHistory}
              canEditComment={canEditComment}
              onUpdate={() => handleSetShouldRefetchHistory?.(true)}
              isFirstItem={index === 0}
            />
          ))}
        </div>
      )}
    </>
  );
};
