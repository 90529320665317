/* spell-checker: disable */

const CUSTOMERS_ENDPOINT = `/customers`;

export const CUSTOMERS_API = {
  // Allowed methods: POST
  INSIGHTS: `${CUSTOMERS_ENDPOINT}/insights`,
  // Allowed methods: DELETE
  SINGLE_INSIGHT: (customerInsightId: string) =>
    `${CUSTOMERS_ENDPOINT}/insights/${customerInsightId}`,
  // Allowed methods: GET
  EXPERT_INSIGHTS: (expertId: string) =>
    `${CUSTOMERS_ENDPOINT}/insights/experts/${expertId}`,

  // Allowed methods: GET, POST
  SURVEYS: `${CUSTOMERS_ENDPOINT}/surveys`,
  // Allowed methods: GET
  SPECIFIC_SURVEY: (surveyId: string) =>
    `${CUSTOMERS_ENDPOINT}/surveys/${surveyId}`,
  // Allowed methods: PUT
  SURVEY_COMPANIES: (id: string) =>
    `${CUSTOMERS_ENDPOINT}/surveys/${id}/companies`,

  // Allowed methods: GET
  SURVEY_ANSWERS_FOR_EXPERT: (surveyId: string, expertId: string) =>
    `${CUSTOMERS_ENDPOINT}/surveyanswers/${surveyId}/experts/${expertId}`,
  // Allowed methods: PUT
  SURVEY_ANSWERS_INSIGHTS: (surveyId: string) =>
    `${CUSTOMERS_ENDPOINT}/surveyanswers/${surveyId}/insights`,
  // Allowed methods: PUT
  SURVEY_ANSWERS_COMPETITORS: (surveyId: string) =>
    `${CUSTOMERS_ENDPOINT}/surveyanswers/${surveyId}/competitors`
};
