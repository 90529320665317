import React from "react";
import { useSelector } from "react-redux";

import { ExpertUser } from "@arbolus-technologies/api";
import { DateString } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

import DeleteExpertSection from "../../Components/DeleteExpertSection/DeleteExpertSection";
import { EditExpertStatusSection } from "../../Components/EditExpertStatusSection/EditExpertStatusSection";

import styles from "./EditExpertSettings.module.scss";

interface EditExpertSettingsProps {
  expertUser: ExpertUser;
  expertId: string;
  lastPublicCompanyExpDate: DateString | null;
}

export const EditExpertSettings: React.FC<EditExpertSettingsProps> = ({
  expertUser,
  expertId,
  lastPublicCompanyExpDate
}) => {
  const hasManageCompliancePermission = useSelector(
    CacheSelector.hasManageCompliancePermissionSelector()
  );

  return (
    <div className={styles.container}>
      <DeleteExpertSection expertUser={expertUser} />
      {hasManageCompliancePermission && (
        <EditExpertStatusSection
          expertUser={expertUser}
          expertId={expertId}
          lastPublicCompanyExpDate={lastPublicCompanyExpDate}
        />
      )}
    </div>
  );
};
