import { Button } from "arbolus-ui-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import {
  CIQError,
  ClientService,
  DefaultToasterService,
  ErrorResponse,
  ExpertDetail,
  ToasterService
} from "@arbolus-technologies/api";
import {
  ExpertDrawerContainer,
  ExpertEndorsements
} from "@arbolus-technologies/features/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { DeleteModal } from "@arbolus-technologies/ui/components";
import { displayUserName } from "@arbolus-technologies/utils";

import { ContactCard } from "../../Components/ContactCard/ContactCard";
import { ExpertWorkDetails } from "../../Components/ExpertWorkDetails/ExpertWorkDetails";
import { Facts } from "../../Components/Facts/Facts";

import styles from "./Profile.module.scss";

interface ProfileProps {
  expertDetails?: ExpertDetail;
  onNavigateBack: () => void;
  handleLoading: (isLoading: boolean) => void;
  clientService?: typeof ClientService;
  notificationService?: ToasterService;
}

export const Profile: React.FC<ProfileProps> = ({
  expertDetails,
  onNavigateBack,
  handleLoading,
  clientService = ClientService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("profile");
  const { expertId } = useParams<{ expertId: string }>();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const isAdmin = useSelector(CacheSelector.isAdmin());
  const clientId = useSelector(CacheSelector.loggedInUserClientId());

  const handleRemoveCancelClicked = (): void => {
    setIsModalOpen(false);
  };

  if (!expertDetails) {
    return null;
  }

  const handleRemoveFromNetwork = (): void => {
    if (clientId) {
      handleLoading(true);

      clientService.removeSavedExpert(clientId!, expertId).subscribe(
        () => {
          notificationService.showSuccess(
            t("expertRemoveSuccess", {
              expertName: displayUserName(expertDetails.user, false)
            })
          );
          handleLoading(false);
          onNavigateBack();
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showError(error.message);
          handleLoading(false);
        }
      );
    }
  };

  const {
    id,
    overview = "",
    background = "",
    workHistories,
    linkedinProfile,
    experienceLevel,
    endorsements,
    rateCards,
    preferredContactMethod = "",
    user: { email, phoneNumber },
    industries,
    companies,
    funFact,
    quickFacts,
    lastPublicCompanyExpDate,
    customers = []
  } = expertDetails;

  const contactDetails = {
    userId: id,
    email,
    phoneNumber,
    linkedinProfile,
    experienceLevel,
    rateCards,
    preferredContactMethod
  };

  return (
    <div className={styles.profileContainer}>
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <ContactCard contactDetails={contactDetails} isAdmin={isAdmin} />
          <ExpertWorkDetails
            industries={industries}
            companies={companies}
            customers={customers}
            isAdmin={isAdmin}
          />
          <Facts funFact={funFact} quickFacts={quickFacts} />
        </div>
        <div className={styles.rightContainer}>
          <ExpertDrawerContainer
            overview={overview}
            background={background}
            workHistories={workHistories}
            displayPublicCompanies={lastPublicCompanyExpDate !== null}
          />
          <ExpertEndorsements endorsements={endorsements} />
        </div>
      </div>
      {clientId && (
        <>
          <div className={styles.button}>
            <Button
              type="secondary"
              text={t("removeFromNetwork")}
              onClick={(): void => {
                setIsModalOpen(true);
              }}
              endIcon="delete"
            />
          </div>
          <DeleteModal
            toggleModal={isModalOpen}
            messageTitle={t("removeExpert")}
            warningMessage={t("removeExpertWarning")}
            deleteActionText={t("remove")}
            onConfirm={handleRemoveFromNetwork}
            onCancel={handleRemoveCancelClicked}
          />
        </>
      )}
    </div>
  );
};
