import React from "react";

import { Loader } from "./Loader";

interface LoaderOrComponentProps {
  isLoading: boolean;
  isFull?: boolean;
  children: React.ReactNode;
}

export const LoaderOrComponent: React.FC<LoaderOrComponentProps> = ({
  isLoading,
  isFull = false,
  children
}) =>
  isLoading ? <Loader className="w-100" isFull={isFull} /> : <>{children}</>;
