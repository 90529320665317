import i18next from "i18next";

import { PageStep } from "@arbolus-technologies/models/common";

import { AgreementStep } from "../../components/SimplifiedCreateProject/AgreementStep/AgreementStep";
import { ProjectDetailsStep } from "../../components/SimplifiedCreateProject/ProjectDetailsStep/ProjectDetailsStep";

export const PROJECT_CREATION_STEPS_TRANSLATION: PageStep[] = [
  {
    name: i18next.t("createNewProject:projectDetails"),
    StepComponent: ProjectDetailsStep,
    nextStepButtonText: i18next.t("createNewProject:nextAgreement")
  },
  {
    name: i18next.t("createNewProject:engagementAgreement"),
    StepComponent: AgreementStep,
    nextStepButtonText: i18next.t("createNewProject:confirmAndStart")
  }
];
