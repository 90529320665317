import i18next from "i18next";
import * as Yup from "yup";
import { FIELD_NAMES } from "./expertSurveyQuestions";

const { PERSONA, IMPLEMENTATION_STAGE, SPEND, NPS, RENEWAL_INTENT } =
  FIELD_NAMES;

const messageError = i18next.t("register:fieldRequired");

export const CompanyQuestionsSchema = Yup.object().shape({
  [SPEND]: Yup.number()
    .typeError(i18next.t("register:surveySpendTypeError"))
    .max(2147483647, i18next.t("register:surveySpendMaxError"))
    .min(1, i18next.t("register:surveySpendMinError")),
  [PERSONA]: Yup.string().required(messageError).nullable(),
  [IMPLEMENTATION_STAGE]: Yup.string().required(messageError).nullable(),
  [NPS]: Yup.number().required(messageError).nullable(),
  [RENEWAL_INTENT]: Yup.string().required(messageError).nullable()
});
