import { MbscCalendarEvent } from "@mobiscroll/react";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { ProjectExpertAvailability } from "@arbolus-technologies/api";
import { Availability as AvailabilityCalendar } from "@arbolus-technologies/features/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

import { AvailabilityFooter } from "./AvailabilityFooter/AvailabilityFooter";
import { AvailabilityTimezone } from "./AvailabilityTimezone/AvailabilityTimezone";

import styles from "./AvailabilityScheduler.module.scss";

interface AvailabilitySchedulerProps {
  availabilityDetails: ProjectExpertAvailability;
  currentTimezone: string;
  goToPrevStep: () => void;
  onSave: () => void;
}

export const AvailabilityScheduler: React.FC<AvailabilitySchedulerProps> = ({
  availabilityDetails,
  currentTimezone,
  goToPrevStep,
  onSave
}) => {
  const [selectedSlots, setSelectedSlots] = useState<MbscCalendarEvent[]>([]);

  const systemTimezone = useSelector(
    CacheSelector.appGuessCurrentTimeZoneSelector()
  );
  const [expertTimezone, setExpertTimezone] = useState<string>(
    currentTimezone || systemTimezone.value
  );
  const timezones = useSelector(
    CacheSelector.appTimezoneSelectOptionMapSelector()
  );

  const handleTimezoneChange = (value: string): void => {
    setExpertTimezone(value);
  };

  const handleUpdateAvailabilityDetails = (
    expertAvailabilitySlots: MbscCalendarEvent[]
  ): void => {
    setSelectedSlots(expertAvailabilitySlots);
  };

  return (
    <>
      <AvailabilityTimezone
        projectTimezone={availabilityDetails.projectTimezone ?? ""}
        expertTimezone={expertTimezone}
        timezones={timezones}
        handleTimezoneChange={handleTimezoneChange}
      />
      <div className={styles.fullWidth}>
        <div className={styles.calendar}>
          <AvailabilityCalendar
            projectTimezone={availabilityDetails.projectTimezone ?? ""}
            expertTimezone={expertTimezone}
            expertAvailabilitySlotsSelected={
              availabilityDetails.expertAvailabilitySlots
            }
            timezones={timezones}
            onUpdateAvailabilityDetails={handleUpdateAvailabilityDetails}
          />
        </div>
        <AvailabilityFooter
          slotsCount={selectedSlots.length}
          onSave={onSave}
          isDisabled={
            selectedSlots.length === 0 ||
            selectedSlots.filter((s) => s.cssClass?.includes("disabled"))
              .length === selectedSlots.length
          }
          goToPrevStep={goToPrevStep}
        />
      </div>
    </>
  );
};
