export enum PROJECT_STATUS_COLORS {
  new = "purple",
  approved = "green",
  pendingApproval = "orange"
}

export enum PROJECT_ORDER_BY {
  NAME = "name",
  CREATED = "created"
}
