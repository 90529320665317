import i18next from "i18next";
import { Epic } from "redux-observable";
import { EMPTY, of } from "rxjs";
import {
  catchError,
  filter,
  flatMap,
  map,
  mergeMap,
  switchMap
} from "rxjs/operators";
import { isOfType } from "typesafe-actions";

import {
  ApiError,
  CIQError,
  CreateNewProjectBody,
  ErrorResponse,
  PaginatedRequest,
  ToasterService,
  somethingWentWrongLabel
} from "@arbolus-technologies/api";
import {
  DO_NOT_CONTACT_STATUS,
  ISearchTerm
} from "@arbolus-technologies/models/common";
import {
  ChatUserRole,
  FilterTimeSelectorOptions,
  SimplifiedCreateProjectPayload,
  SimplifiedEditProjectPayload
} from "@arbolus-technologies/models/project";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";

import { ProjectNxStoreActions } from "../..";
import {
  CLEAR_ALL_DISCOVER_FILTERS,
  CLEAR_DISCOVER_FILTER,
  SET_COMPANY_FILTER_PERIOD,
  SET_COMPANY_IN_PAST_MONTHS,
  SET_DISCOVER_EXPERT_ALL_FILTERS,
  SET_DISCOVER_EXPERT_CURRENT_PAGE,
  SET_DISCOVER_EXPERT_SEARCH_TERM,
  SET_DISCOVER_FILTER,
  SET_KEYWORDS_SEARCH_OPTIONS,
  SET_WORK_HISTORY_LOCATION_FILTER_PERIOD,
  TOGGLE_DNC_EXPERTS
} from "../actions/ExpertDiscover/ExpertDiscoverActionTypes";
import {
  CREATE_ANGLE as CREATE_ANGLE_ACTION_TYPE,
  CREATE_PROJECT_OLD,
  DELETE_ANGLE,
  DELETE_PROJECT_DRAFT,
  GET_ANGLE,
  GET_ANGLES_LIST,
  GET_BUSINESS_ENTITIES,
  GET_CHAT_FILES,
  GET_CHAT_MESSAGES,
  GET_PROJECT_ENGAGEMENT_AGREEMENTS,
  LOAD_PROJECT,
  SEND_CHAT_MESSAGE,
  SHARE_CHAT_FILES,
  SIMPLIFIED_CREATE_PROJECT,
  SIMPLIFIED_EDIT_PROJECT,
  SIMPLIFIED_EDIT_PROJECT_SUCCESS,
  UPDATE_ANGLE
} from "../actions/ProjectActionTypes";
import { loadReferralTabCountSuccess } from "../actions/ProjectNxStoreActions";
import { LOAD_REFERRAL_TAB_COUNT } from "../actions/Referral/ReferralActionTypes";
import {
  ProjectNxAppState,
  ProjectNxStoreDependencies,
  ProjectStoreAction,
  ReferralsTabCount
} from "../models/definitions";
import {
  createCompanyFilters,
  createLocationFilters,
  createProjectFilters
} from "../utils/DiscoverExpertUtils";

const engagementAgreements: Epic<
  ProjectStoreAction,
  ProjectStoreAction,
  ProjectNxAppState,
  ProjectNxStoreDependencies
> = (action$, state$, { projectService, notificationService }) =>
  action$.pipe(
    filter(isOfType(GET_PROJECT_ENGAGEMENT_AGREEMENTS)),
    switchMap(({ payload: { clientId } }) =>
      projectService.getEngagementAgreements("ClientAgreement", clientId).pipe(
        map((response) =>
          ProjectNxStoreActions.getEngagementAgreementsSuccess(response)
        ),
        catchError((error) => {
          notificationService.showError(i18next.t(somethingWentWrongLabel));
          return of(
            ProjectNxStoreActions.getEngagementAgreementsFailure(error)
          );
        })
      )
    )
  );

const businessEntities: Epic<
  ProjectStoreAction,
  ProjectStoreAction,
  ProjectNxAppState,
  ProjectNxStoreDependencies
> = (action$, state$, { projectService, notificationService }) =>
  action$.pipe(
    filter(isOfType(GET_BUSINESS_ENTITIES)),
    switchMap(({ payload: { clientId } }) =>
      projectService.getBusinessEntities(clientId).pipe(
        map((response) =>
          ProjectNxStoreActions.getBusinessEntitiesProjectSuccess(
            response.items
          )
        ),
        catchError((error) => {
          notificationService.showError(i18next.t(somethingWentWrongLabel));
          return of(
            ProjectNxStoreActions.getBusinessEntitiesProjectFailure(error)
          );
        })
      )
    )
  );

const createUpdateAngle: Epic<
  ProjectStoreAction,
  ProjectStoreAction,
  ProjectNxAppState,
  ProjectNxStoreDependencies
> = (
  action$,
  state$,
  { notificationService, projectService, history, routing }
) =>
  action$.pipe(
    filter(isOfType(CREATE_ANGLE_ACTION_TYPE)),
    switchMap(({ payload: { angleData, projectDraftId } }) => {
      return projectService.createAngleApi(angleData, projectDraftId).pipe(
        flatMap(() => of(ProjectNxStoreActions.createAngleSuccess())),
        catchError((error) =>
          of(ProjectNxStoreActions.createAngleFailure(error))
        )
      );
    })
  );

const getAnglesList: Epic<
  ProjectStoreAction,
  ProjectStoreAction,
  ProjectNxAppState,
  ProjectNxStoreDependencies
> = (action$, state$, { notificationService, projectService }) =>
  action$.pipe(
    filter(isOfType(GET_ANGLES_LIST)),
    switchMap(({ payload: { projectDraftId } }) =>
      projectService.getAnglesListFromApi(projectDraftId).pipe(
        flatMap((response) => {
          if (response.items.length === 0) {
            return EMPTY;
          } else {
            return of(
              ProjectNxStoreActions.getAnglesListSuccess(response.items)
            );
          }
        }),
        catchError((error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(i18next.t(somethingWentWrongLabel));
          return of(ProjectNxStoreActions.getAnglesListFailure(error));
        })
      )
    )
  );

const deleteAngle: Epic<
  ProjectStoreAction,
  ProjectStoreAction,
  ProjectNxAppState,
  ProjectNxStoreDependencies
> = (action$, state$, { notificationService, projectService }) =>
  action$.pipe(
    filter(isOfType(DELETE_ANGLE)),
    switchMap(({ payload: { angleDraftId } }) =>
      projectService.deleteAngleFromApi(angleDraftId).pipe(
        mergeMap((angleDeleteResponse) =>
          of(ProjectNxStoreActions.deleteAngleSuccess(angleDeleteResponse.id))
        ),
        catchError((error) => {
          notificationService.showError(i18next.t(somethingWentWrongLabel));
          return of(ProjectNxStoreActions.deleteAngleFailure(error));
        })
      )
    )
  );

const getAngleData: Epic<
  ProjectStoreAction,
  ProjectStoreAction,
  ProjectNxAppState,
  ProjectNxStoreDependencies
> = (action$, state$, { notificationService, projectService }) =>
  action$.pipe(
    filter(isOfType(GET_ANGLE)),
    switchMap(({ payload: { angleDraftId } }) =>
      projectService.getAngleFromApi(angleDraftId).pipe(
        flatMap((response) =>
          of(ProjectNxStoreActions.getAngleSuccess(response))
        ),
        catchError((error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(i18next.t(somethingWentWrongLabel));
          return of(ProjectNxStoreActions.getAngleFailure(error));
        })
      )
    )
  );

const updateAngle: Epic<
  ProjectStoreAction,
  ProjectStoreAction,
  ProjectNxAppState,
  ProjectNxStoreDependencies
> = (action$, state$, { notificationService, projectService }) =>
  action$.pipe(
    filter(isOfType(UPDATE_ANGLE)),
    switchMap(({ payload: { angleDraft } }) =>
      projectService
        .updateAngleFromApi(angleDraft, state$.value.projectNx.selectedAngle.id)
        .pipe(
          flatMap(() => of(ProjectNxStoreActions.updateAngleSuccess())),
          catchError((error: ErrorResponse<CIQError>) => {
            notificationService.showApiErrors(error);
            return of(ProjectNxStoreActions.updateAngleFailure(error));
          })
        )
    )
  );

const createProject: Epic<
  ProjectStoreAction,
  ProjectStoreAction,
  ProjectNxAppState,
  ProjectNxStoreDependencies
> = (
  action$,
  state$,
  { projectService, history, routing, notificationService }
) =>
  action$.pipe(
    filter(isOfType(CREATE_PROJECT_OLD)),
    switchMap(({ payload: { projectDraft } }) => {
      const { mainContactUser, timeZone, id, industryId, enableWorkspaces } =
        projectDraft;
      const { projectManagers, primaryProjectLead, secondaryProjectLead } =
        state$.value.projectNx;

      const project: CreateNewProjectBody = {
        ...projectDraft,
        mainContactUserId: mainContactUser!.id,
        workspaceEnabled: enableWorkspaces,
        timeZone,
        projectDraftId: id,
        projectManagerUserIds:
          projectManagers && projectManagers.length > 0
            ? projectManagers.map((manager) => manager.id)
            : [],
        primaryLeadUserId: primaryProjectLead?.id,
        secondaryLeadUserId: secondaryProjectLead?.id,
        industryId
      };

      return projectService.createProjectV2(project).pipe(
        flatMap((res) => {
          history.push(routing.discoverExpertsRoute(res.id));
          if (routing.projectEditRoute) {
            notificationService.showAction(
              i18next.t("remindTargetCompany:text"),
              {
                label: i18next.t("remindTargetCompany:action") as string,
                onClick: () => history.push(routing.projectEditRoute!(res.id))
              }
            );
          }

          return of(
            ProjectNxStoreActions.createProjectSuccess(),
            ProjectNxStoreActions.deleteProjectDraft(projectDraft.id),
            ProjectNxStoreActions.forceReloadProjects(),
            ProjectNxStoreActions.clearProjectManagers()
          );
        }),
        catchError((error: ErrorResponse<CIQError>) => {
          const apiError = new ApiError(error);
          const newNotification = new ToasterService();
          apiError.errorMessages.forEach((err) =>
            newNotification.showError(err)
          );
          return of(ProjectNxStoreActions.createProjectFailure());
        })
      );
    })
  );

const simplifiedCreateProject: Epic<
  ProjectStoreAction,
  ProjectStoreAction,
  {},
  ProjectNxStoreDependencies
> = (action$, _, { projectService, notificationService, history, routing }) =>
  action$.pipe(
    filter(isOfType(SIMPLIFIED_CREATE_PROJECT)),
    switchMap(({ payload }) => {
      const { apiPayload }: { apiPayload: SimplifiedCreateProjectPayload } =
        payload;
      return projectService.createProjectV3(apiPayload).pipe(
        mergeMap((result) => {
          history.push(routing.projectRoute(result.id));
          return of(ProjectNxStoreActions.simplifiedCreateProjectSuccess());
        }),
        catchError((error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
          return of(ProjectNxStoreActions.simplifiedCreateProjectFailure());
        })
      );
    })
  );

const simplifiedEditProject: Epic<
  ProjectStoreAction,
  ProjectStoreAction,
  ProjectNxAppState,
  ProjectNxStoreDependencies
> = (
  action$,
  $state,
  { projectService, notificationService, history, routing }
) =>
  action$.pipe(
    filter(isOfType(SIMPLIFIED_EDIT_PROJECT)),
    switchMap(({ payload }) => {
      const { apiPayload }: { apiPayload: SimplifiedEditProjectPayload } =
        payload;
      return projectService.editProjectV3(apiPayload).pipe(
        mergeMap(() => {
          // projectBriefRoute prop is available only in ciq-client
          const { projectBriefRoute } = routing;
          if (projectBriefRoute) {
            history.push(projectBriefRoute(apiPayload.projectId));
          }
          return of(
            ProjectNxStoreActions.simplifiedEditProjectSuccess(apiPayload)
          );
        }),
        catchError((error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
          return of(ProjectNxStoreActions.simplifiedEditProjectFailure());
        })
      );
    })
  );

const deleteProjectDraft: Epic<
  ProjectStoreAction,
  ProjectStoreAction,
  ProjectNxAppState,
  ProjectNxStoreDependencies
> = (action$, state$, { notificationService, projectService }) =>
  action$.pipe(
    filter(isOfType(DELETE_PROJECT_DRAFT)),
    switchMap(({ payload: { projectDraftId } }) =>
      projectService.deleteProjectDraftV2(projectDraftId).pipe(
        mergeMap(() => of(ProjectNxStoreActions.deleteProjectDraftSuccess())),
        catchError(() => {
          notificationService.showError(i18next.t(somethingWentWrongLabel));
          return of(ProjectNxStoreActions.deleteProjectDraftFailure());
        })
      )
    )
  );

const getProjectData: Epic<
  ProjectStoreAction,
  ProjectStoreAction,
  ProjectNxAppState,
  ProjectNxStoreDependencies
> = (action$, state$, { notificationService, projectService }) =>
  action$.pipe(
    filter(isOfType(LOAD_PROJECT)),
    switchMap(({ payload: { projectId } }) =>
      projectService.getProject(projectId).pipe(
        map((response) => ProjectNxStoreActions.loadProjectSuccess(response)),
        catchError(() => {
          notificationService.showError(i18next.t(somethingWentWrongLabel));
          return of(ProjectNxStoreActions.loadProjectFailure());
        })
      )
    )
  );

const reloadProjectAfterEdit: Epic<
  ProjectStoreAction,
  ProjectStoreAction,
  ProjectNxAppState,
  ProjectNxStoreDependencies
> = (action$, state$, { notificationService, projectService }) =>
  action$.pipe(
    filter(isOfType(SIMPLIFIED_EDIT_PROJECT_SUCCESS)),
    switchMap(
      ({
        payload: {
          updatedProject: { projectId }
        }
      }) =>
        projectService.getProject(projectId).pipe(
          map((response) => ProjectNxStoreActions.loadProjectSuccess(response)),
          catchError(() => {
            notificationService.showError(i18next.t(somethingWentWrongLabel));
            return of(ProjectNxStoreActions.loadProjectFailure());
          })
        )
    )
  );

const getChatMessages: Epic<
  ProjectStoreAction,
  ProjectStoreAction,
  ProjectNxAppState,
  ProjectNxStoreDependencies
> = (action$, state$, { notificationService, chatService }) =>
  action$.pipe(
    filter(isOfType(GET_CHAT_MESSAGES)),
    switchMap(({ payload: { projectId, chatId, queryParams } }) =>
      chatService
        .getMessages(
          projectId,
          chatId,
          queryParams.offset,
          queryParams.limit,
          queryParams.orderBy,
          queryParams.orderDirection
        )
        .pipe(
          map((response) =>
            ProjectNxStoreActions.getChatMessagesSuccess(response)
          ),
          catchError((error: ErrorResponse<CIQError>) => {
            notificationService.showError(i18next.t(somethingWentWrongLabel));
            return of(ProjectNxStoreActions.getChatMessagesFailure(error));
          })
        )
    )
  );

const sendChatMessage: Epic<
  ProjectStoreAction,
  ProjectStoreAction,
  ProjectNxAppState,
  ProjectNxStoreDependencies
> = (action$, state$, { notificationService, chatService }) =>
  action$.pipe(
    filter(isOfType(SEND_CHAT_MESSAGE)),
    switchMap(({ payload: { projectId, chatId, message } }) =>
      chatService.sendMessage(projectId, chatId, message).pipe(
        map(() => ProjectNxStoreActions.sendChatMessageSuccess()),
        catchError((error: ErrorResponse<CIQError>) => {
          notificationService.showError(i18next.t(somethingWentWrongLabel));
          return of(ProjectNxStoreActions.sendChatMessageFailure(error));
        })
      )
    )
  );

const getChatFiles: Epic<
  ProjectStoreAction,
  ProjectStoreAction,
  ProjectNxAppState,
  ProjectNxStoreDependencies
> = (action$, state$, { notificationService, documentService }) =>
  action$.pipe(
    filter(isOfType(GET_CHAT_FILES)),
    switchMap(({ payload: { projectId, queryParams, userRole } }) => {
      const getDocumentsService = (
        projectId: string,
        queryParams: PaginatedRequest<"Created"> & ISearchTerm
      ) => {
        const getFilesServiceByRole = {
          [ChatUserRole.Admin]: documentService.getAdminDocuments,
          [ChatUserRole.Client]: documentService.getClientDocuments,
          [ChatUserRole.Expert]: documentService.getExpertDocuments
        };
        const getFilesService = getFilesServiceByRole[userRole as ChatUserRole];
        return getFilesService(projectId, queryParams);
      };
      return getDocumentsService(projectId, queryParams).pipe(
        map((response) => ProjectNxStoreActions.getChatFilesSuccess(response)),
        catchError((error: ErrorResponse<CIQError>) => {
          notificationService.showError(i18next.t(somethingWentWrongLabel));
          return of(ProjectNxStoreActions.getChatFilesFailure(error));
        })
      );
    })
  );

const shareChatFiles: Epic<
  ProjectStoreAction,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  ProjectNxAppState,
  ProjectNxStoreDependencies
> = (action$, state$, { notificationService, documentService }) =>
  action$.pipe(
    filter(isOfType(SHARE_CHAT_FILES)),
    switchMap(({ payload: { projectId, chatId, documentIds } }) =>
      documentService
        .shareDocument(projectId, chatId, { documentIds, message: "" })
        .pipe(
          mergeMap((response) =>
            of(
              ProjectNxStoreActions.shareChatFilesSuccess(response),
              PanelStoreActions.closePanel(PanelId.AttachFiles)
            )
          ),
          catchError((error: ErrorResponse<CIQError>) => {
            notificationService.showError(i18next.t(somethingWentWrongLabel));
            return of(ProjectNxStoreActions.shareChatFilesFailure(error));
          })
        )
    )
  );

const fetchReferralTabCount: Epic<
  ProjectStoreAction,
  ProjectStoreAction,
  ProjectNxAppState,
  ProjectNxStoreDependencies
> = (action$, _, { referralsService, notificationService }) =>
  action$.pipe(
    filter(isOfType(LOAD_REFERRAL_TAB_COUNT)),
    switchMap(({ payload: { projectId } }) =>
      referralsService.getReferralsStats(projectId).pipe(
        map((response: ReferralsTabCount) =>
          loadReferralTabCountSuccess(response)
        ),
        catchError(() => {
          notificationService.showError(i18next.t(somethingWentWrongLabel));
          return of(ProjectNxStoreActions.loadReferralTabCountFailure());
        })
      )
    )
  );

const fetchInternalExperts: Epic<
  ProjectStoreAction,
  ProjectStoreAction,
  ProjectNxAppState,
  ProjectNxStoreDependencies
> = (action$, state$, { projectService, notificationService }) =>
  action$.pipe(
    filter(
      isOfType([
        SET_DISCOVER_EXPERT_SEARCH_TERM,
        SET_DISCOVER_EXPERT_ALL_FILTERS,
        SET_DISCOVER_FILTER,
        SET_COMPANY_FILTER_PERIOD,
        SET_COMPANY_IN_PAST_MONTHS,
        SET_WORK_HISTORY_LOCATION_FILTER_PERIOD,
        CLEAR_ALL_DISCOVER_FILTERS,
        CLEAR_DISCOVER_FILTER,
        SET_DISCOVER_EXPERT_CURRENT_PAGE,
        SET_KEYWORDS_SEARCH_OPTIONS,
        TOGGLE_DNC_EXPERTS
      ])
    ),
    switchMap(({ type }) => {
      const {
        expertDiscover: {
          keywordsFields,
          filters,
          workHistoryLocationFilterPeriod,
          companyFilterPeriod,
          displayRecommendations,
          hideDncExperts,
          searchTerm,
          currentPage,
          pageSize,
          companyInPastMonths = 0
        },
        clientId
      } = state$.value.projectNx;

      if (
        (type === SET_KEYWORDS_SEARCH_OPTIONS &&
          filters.keywords.length === 0) ||
        (type === SET_COMPANY_FILTER_PERIOD && filters.company.length === 0) ||
        (type === SET_WORK_HISTORY_LOCATION_FILTER_PERIOD &&
          filters.workHistoryLocations.length === 0) ||
        (type === SET_COMPANY_IN_PAST_MONTHS &&
          (filters.company.length === 0 ||
            companyFilterPeriod !== FilterTimeSelectorOptions.PastNotCurrent))
      ) {
        return of(ProjectNxStoreActions.setDiscoverExpertListLoading(false));
      }

      let requestedPage = 1;
      if (type === SET_DISCOVER_EXPERT_CURRENT_PAGE) {
        requestedPage = currentPage;
      } else {
        state$.value.projectNx.expertDiscover.currentPage = 1;
      }

      const projectId = window.location.pathname.split("/")[2];

      const companyFilterObj = createCompanyFilters(filters.company);
      const isAnyCompanyExcluded = companyFilterObj.some(
        (option) => option.isExcluded
      );
      if (isAnyCompanyExcluded) {
        state$.value.projectNx.expertDiscover.companyInPastMonths = 0;
      }

      const companiesFilter = {
        filters: companyFilterObj,
        isCurrent: companyFilterPeriod === FilterTimeSelectorOptions.Current,
        isPastNotCurrent:
          companyFilterPeriod === FilterTimeSelectorOptions.PastNotCurrent,
        companyInPastMonths: isAnyCompanyExcluded ? 0 : companyInPastMonths * 6
      };

      const expertCurrentLocationsFilter = {
        filters: createLocationFilters(filters.expertLocations)
      };

      const expertWorkHistoryLocationsFilter = {
        filters: createLocationFilters(filters.workHistoryLocations),
        isCurrent:
          workHistoryLocationFilterPeriod === FilterTimeSelectorOptions.Current,
        isPastNotCurrent:
          workHistoryLocationFilterPeriod ===
          FilterTimeSelectorOptions.PastNotCurrent
      };

      const projectsFilter = {
        filters: createProjectFilters(filters.projects)
      };

      const keywordFilter = filters.keywords[0]?.value;

      return projectService
        .expertSearch(projectId, {
          projectId: projectId,
          searchTerm,
          companies: companiesFilter,
          expertCurrentLocations: expertCurrentLocationsFilter,
          workHistoryLocations: expertWorkHistoryLocationsFilter,
          projects: projectsFilter,
          customers: filters.customers.map((customer) => customer.value),
          isRecommendationFilterEnabled: displayRecommendations,
          isDoNotContactHideFilterEnabled: hideDncExperts,
          clientId,
          keywords: keywordFilter,
          keywordsFields: keywordFilter ? keywordsFields : [],
          limit: pageSize,
          offset: (requestedPage - 1) * pageSize
        })
        .pipe(
          mergeMap((response) => {
            // Temporary filter until BE is done. To be removed.
            const updatedItems = response.items.filter(
              (item) =>
                !item.isRecommendedExpert ||
                item.doNotContactStatus !== DO_NOT_CONTACT_STATUS.DNC
            );
            return of(
              ProjectNxStoreActions.setDiscoverExpertList({
                ...response,
                items: updatedItems
              }),
              ProjectNxStoreActions.setDiscoverExpertListLoading(false)
            );
          }),
          catchError((error: ErrorResponse<CIQError>) => {
            notificationService.showError(i18next.t(somethingWentWrongLabel));
            return of(
              ProjectNxStoreActions.shareChatFilesFailure(error),
              ProjectNxStoreActions.setDiscoverExpertListLoading(false)
            );
          })
        );
    })
  );

export const ProjectNxEpics = [
  businessEntities,
  createProject,
  createUpdateAngle,
  deleteAngle,
  deleteProjectDraft,
  engagementAgreements,
  getAngleData,
  getAnglesList,
  getChatFiles,
  getChatMessages,
  getProjectData,
  fetchReferralTabCount,
  reloadProjectAfterEdit,
  sendChatMessage,
  shareChatFiles,
  simplifiedCreateProject,
  simplifiedEditProject,
  updateAngle,
  fetchInternalExperts
];
