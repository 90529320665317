import clsx from "clsx";
import React from "react";

import styles from "./ArbolusTooltip.module.scss";

export enum ArbolusTooltipPlace {
  BOTTOM = "bottom",
  RIGHT = "right",
  LEFT = "left"
}

interface ArbolusTooltipProps {
  content: React.ReactNode;
  place?: `${ArbolusTooltipPlace}`;
  lightTheme?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
}

export const ArbolusTooltip: React.FC<ArbolusTooltipProps> = ({
  content,
  place = ArbolusTooltipPlace.BOTTOM,
  lightTheme,
  disabled = false,
  children
}) => (
  <div className={clsx(styles.tooltip, disabled && styles.disabled)}>
    {children}
    {!disabled && (
      <div
        className={clsx(
          "ag-grid-tooltip",
          styles.content,
          lightTheme && styles.lightTheme,
          place === ArbolusTooltipPlace.RIGHT && styles.right,
          place === ArbolusTooltipPlace.LEFT && styles.left
        )}
      >
        {content}
      </div>
    )}
  </div>
);
