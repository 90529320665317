import { ActionCreator } from "redux";

import { INBOX_NOTIFICATION_CATEGORY } from "../../../../constants/notifications";
import { CIQError, ErrorResponse } from "../../../../models/api";
import {
  GET_NOTIFICATION_COUNTS,
  GET_NOTIFICATION_COUNTS_FAILURE,
  GET_NOTIFICATION_COUNTS_SUCCESS,
  GetNotificationCountsAction,
  GetNotificationCountsFailureAction,
  GetNotificationCountsSuccessAction,
  MARK_NOTIFICATION_AS_READ,
  MARK_NOTIFICATION_AS_READ_FAILURE,
  MARK_NOTIFICATION_AS_READ_SUCCESS,
  MarkNotificationAsReadAction,
  MarkNotificationAsReadFailureAction,
  MarkNotificationAsReadSuccessAction
} from "./InboxActionTypes";

export const getNotificationCounts: ActionCreator<
  GetNotificationCountsAction
> = () => ({
  type: GET_NOTIFICATION_COUNTS
});

export const getNotificationCountsSuccess: ActionCreator<
  GetNotificationCountsSuccessAction
> = (notificationCounts: Map<INBOX_NOTIFICATION_CATEGORY, number>) => ({
  type: GET_NOTIFICATION_COUNTS_SUCCESS,
  payload: {
    notificationCounts
  }
});

export const getNotificationCountsFailure: ActionCreator<
  GetNotificationCountsFailureAction
> = (error: ErrorResponse<CIQError>) => ({
  type: GET_NOTIFICATION_COUNTS_FAILURE,
  payload: {
    error
  }
});

export const markNotificationsAsRead: ActionCreator<
  MarkNotificationAsReadAction
> = (
  notificationId: string,
  notificationCategory: INBOX_NOTIFICATION_CATEGORY
) => ({
  type: MARK_NOTIFICATION_AS_READ,
  payload: {
    notificationId,
    notificationCategory
  }
});

export const markNotificationsAsReadSuccess: ActionCreator<
  MarkNotificationAsReadSuccessAction
> = (notificationCategories: INBOX_NOTIFICATION_CATEGORY[]) => ({
  type: MARK_NOTIFICATION_AS_READ_SUCCESS,
  payload: {
    notificationCategories
  }
});

export const markNotificationsAsReadFailure: ActionCreator<
  MarkNotificationAsReadFailureAction
> = (error: ErrorResponse<CIQError>) => ({
  type: MARK_NOTIFICATION_AS_READ_FAILURE,
  payload: {
    error
  }
});
