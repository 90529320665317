import React, { useLayoutEffect, useRef, useState } from "react";

import { RecommendationCompany } from "@arbolus-technologies/api";
import { ArbolusTooltip } from "@arbolus-technologies/ui/components";

import styles from "./Companies.module.scss";

export const Companies: React.FC<{
  id: string;
  companies: RecommendationCompany[];
}> = ({ id, companies }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const text = companies.map((company) => company.name).join(", ");

  // Show tooltip when text is truncated
  useLayoutEffect(() => {
    if (ref.current && ref.current.scrollWidth > ref.current.clientWidth) {
      setShowTooltip(true);
    }
  }, [ref]);

  return (
    <ArbolusTooltip content={text} disabled={!showTooltip}>
      <div ref={ref} id={id} className={styles.company}>
        {text}
      </div>
    </ArbolusTooltip>
  );
};
