import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";

import { SURVEY_FORM_TYPE } from "@arbolus-technologies/models/canopy";

import { MixPanelEventNames } from "../../../Mixpanel/enums";
import { useArbolusTracking } from "../../../Mixpanel/useArbolusTracking";

import styles from "./CreateSurveyActions.module.scss";

interface CreateSurveyActionsProps {
  isCanopySaving: boolean;
  type: string;
  closePanel: () => void;
  handleSubmit: () => void;
  disableButton: boolean;
}

export const CreateSurveyActions = ({
  isCanopySaving,
  type,
  closePanel,
  handleSubmit,
  disableButton
}: CreateSurveyActionsProps): JSX.Element => {
  const { t } = useTranslation("createSurvey");
  const { trackClick } = useArbolusTracking();

  const handleCancel = () => {
    trackClick(MixPanelEventNames.CanopyCreationPanelCancel);
    closePanel();
  };

  return (
    <div className={styles.buttonContainer}>
      <Button type="tertiary" onClick={handleCancel} text={t("cancel")} />
      <Button
        disabled={isCanopySaving || disableButton}
        onClick={handleSubmit}
        text={type === SURVEY_FORM_TYPE.CREATE ? t("addQuestions") : t("save")}
      />
    </div>
  );
};
