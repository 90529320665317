import Editor from "@draft-js-plugins/editor";
import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  EditorState
} from "draft-js";
import React, { useEffect, useRef } from "react";
import { Subscription } from "rxjs";
import SimpleBar from "simplebar-react";

import { MonologueContentBlockDataKey } from "@arbolus-technologies/models/common";

import { TRANSCRIPT_DRAFT_JS_ENTITY } from "../../../../../constants/transcript";
import useTranscriptContext from "../../../../../contexts/transcript/UseTranscriptContext";
import {
  MonologueBlockRenderer,
  Speaker
} from "../../../../../models/view/transcript";
import { UtilsService } from "../../../../../services";
import MonologueBlock, {
  MonologueBlockProps,
  MonologueProps
} from "../monologue/MonologueBlock";
import TextHolders from "../monologue/TextHolders";
import createHighLightToolBarPlugin from "../monologue/highlight/plugin";

// Setup DraftJS Plugins
const higLightToolbarPlugin = createHighLightToolBarPlugin();
const { HighLightToolBar } = higLightToolbarPlugin;
const plugins = [higLightToolbarPlugin];

const ownHighlightStrategy = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
): void => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() ===
        TRANSCRIPT_DRAFT_JS_ENTITY.OWN_HIGHLIGHT
    );
  }, callback);
};
const othersHighlightStrategy = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
): void => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() ===
        TRANSCRIPT_DRAFT_JS_ENTITY.HIGHLIGHT
    );
  }, callback);
};

const defaultStrategy = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void
): void => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey === null;
  }, callback);
};

// Setup DraftJS Decorators
const compositeDecorator = new CompositeDecorator([
  {
    strategy: ownHighlightStrategy,
    component: TextHolders.OwnHighlightHolder
  },
  {
    strategy: othersHighlightStrategy,
    component: TextHolders.HighlightHolder
  },
  { strategy: defaultStrategy, component: TextHolders.DefaultHolder }
]);

interface TranscriptEditorProps {
  onPlayFromMonologue: (startTime: number) => void;
}

const TranscriptEditor: React.FC<TranscriptEditorProps> = ({
  onPlayFromMonologue
}): JSX.Element => {
  const {
    transcriptMeta,
    monologues,
    editorState,
    updateSpeakerAssignment,
    speakersMap,
    speakersList,
    loadingSpeakers,
    mode,
    resetEditorSelection,
    setEditorState
  } = useTranscriptContext();

  const transcriptEditorRef = useRef<Editor>(null);
  const updateTranscriptSpeakerSubscription = useRef<Subscription>();

  useEffect(
    () => (): void => {
      updateTranscriptSpeakerSubscription.current?.unsubscribe();
    },
    []
  );

  const handleEditorChange = (es: EditorState): void => {
    setEditorState(es);
  };

  const handleFocus = (isFocus = false): void => {
    // Transferring element clicks to editor
    // https://draftjs.org/docs/advanced-topics-managing-focus/
    if (isFocus) transcriptEditorRef.current?.editor?.focus();
    else {
      resetEditorSelection();
      transcriptEditorRef.current?.editor?.blur();
    }
  };

  const renderBlockRenderer = (
    block: ContentBlock
  ): MonologueBlockRenderer<
    MonologueBlockProps<MonologueProps>,
    MonologueProps
  > => {
    const { recordingUrl } = transcriptMeta;

    const blockData: Immutable.Map<
      MonologueContentBlockDataKey,
      string | number
    > = block.getData();
    const blockSpeaker = blockData.get("speaker") as string;
    const blockStart = blockData.get("start") as number;

    return {
      component: MonologueBlock,
      props: {
        mode,
        hasVideoURL: !!recordingUrl,
        speaker: speakersMap?.get(blockSpeaker)!,
        speakers: speakersList,
        isSpeakerChangeLoading: loadingSpeakers.includes(blockSpeaker),
        monologueStart: UtilsService.displayTimeDuration(blockStart || 0),
        onFocusChange: (isFocus: boolean): void => handleFocus(isFocus),
        onSpeakerItemStartPlay: (): void => onPlayFromMonologue(blockStart),
        onSpeakerAdd: (newSpeaker: Speaker): void =>
          updateSpeakerAssignment(blockSpeaker, newSpeaker, true),
        onSpeakerAssign: (newSpeaker: Speaker): void =>
          updateSpeakerAssignment(blockSpeaker, newSpeaker, false)
      }
    };
  };

  return (
    <SimpleBar
      id="transcript-scroll"
      className="speaker-details simplebar-light"
      onBlur={(): void => handleFocus()}
    >
      {editorState && (
        <>
          <Editor
            decorators={[compositeDecorator]}
            editorState={editorState}
            onChange={handleEditorChange}
            blockRendererFn={renderBlockRenderer}
            plugins={plugins}
            ref={transcriptEditorRef}
          />
          <HighLightToolBar monologues={Array.from(monologues.values())} />
        </>
      )}
    </SimpleBar>
  );
};

export default TranscriptEditor;
