import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  PanelSelector,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ConfirmationModal } from "@arbolus-technologies/ui/components";

export const MainModal: React.FC = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(PanelSelector.isMainModalOpen());
  const data = useSelector(PanelSelector.mainModalData());

  const close = React.useCallback(
    () => dispatch(PanelStoreActions.closeMainModal()),
    [dispatch]
  );

  const onConfirm = React.useCallback(() => {
    data.onConfirm();
    close();
  }, [close, data]);

  return (
    <ConfirmationModal
      type={data.type}
      title={data.title}
      subtitle={data.subtitle}
      confirmText={data.confirmText}
      onConfirm={onConfirm}
      cancelText={data.cancelText}
      onCancel={close}
      isOpen={isOpen}
      toggle={close}
    />
  );
};
