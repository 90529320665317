import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { NEW_EXPERT } from "@arbolus-technologies/routes";

export const GoToAddExpertButton: React.FC = () => {
  const { t } = useTranslation("expertTablePage");
  const { push } = useHistory();

  function handleClick() {
    push(NEW_EXPERT);
  }

  return (
    <Button icon={<AntDIcon name="add" />} onClick={handleClick} type="primary">
      {t("addExpertButton")}
    </Button>
  );
};
