import React from "react";

import style from "./CanopySummerTitleComponent.module.scss";

interface CanopySummaryTitleComponentProps {
  title: string;
}
export const CanopySummaryTitleComponent: React.FC<
  CanopySummaryTitleComponentProps
> = ({ title }) => <div className={style.title}>{title}</div>;
