import { Unsubscribe } from "redux";
import { Observable, OperatorFunction } from "rxjs";

import { appStore } from "../App";
import { AppConstants } from "../constants";

class RBServiceManager {
  private role: string;

  private storeSubscription: Unsubscribe | undefined;

  constructor() {
    this.role = AppConstants.APP_USER_ROLES.guest;
  }

  subscribeToStore(): void {
    this.storeSubscription = appStore.subscribe(() => {
      const appState = appStore.getState();
      const { currentRole } = appState.auth;
      const nextRole = currentRole || AppConstants.APP_USER_ROLES.guest;

      // Sync user role
      if (this.role !== nextRole) this.setRole(nextRole);
    });
  }

  unsubscribeFromStore(): void {
    this.storeSubscription?.();
  }

  setRole(role: string): void {
    this.role = role;
  }

  serviceCaller<T, E>(
    clientService: Observable<T>,
    expertService: Observable<E>
  ): Observable<T | E> {
    const isClient = this.role !== AppConstants.APP_USER_ROLES.expert;

    return isClient ? clientService : expertService;
  }

  operatorSelector<T, R>(
    clientValue: OperatorFunction<T, R>,
    expertValue: OperatorFunction<T, R>
  ): OperatorFunction<T, R> {
    const isClient = this.role !== AppConstants.APP_USER_ROLES.expert;
    return isClient ? clientValue : expertValue;
  }

  valueSelector<C, E>(clientValue: C, expertValue: E): C | E {
    const isClient = this.role !== AppConstants.APP_USER_ROLES.expert;

    return isClient ? clientValue : expertValue;
  }
}

export default new RBServiceManager();
