import React from "react";
import { Route, Switch } from "react-router";

import {
  CLIENT_NETWORK,
  DASHBOARD
} from "../../constants/navigation/authRoutes";
import ClientNetworkPage from "./pages/network/ClientNetworkPage";

const ClientNetwork: React.FC = () => (
  <Switch>
    <Route
      exact
      path={[DASHBOARD, CLIENT_NETWORK]}
      component={ClientNetworkPage}
    />
  </Switch>
);

export default ClientNetwork;
