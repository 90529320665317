import { IconButton } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { SlidePanel } from "@arbolus-technologies/features/common";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { ExpertProjectNotificationsForm } from "../ProjectNotificationsForm/ExpertProjectNotificationsForm";

export const validProjectSettings = [
  "Messages",
  "SharedDocs",
  "NewReferrals",
  "Applications"
];

interface ExpertProjectNotificationsSlidePanelProps {
  projectId: string;
}

export const ExpertProjectNotificationsSlidePanel: React.FC<
  ExpertProjectNotificationsSlidePanelProps
> = ({ projectId }) => {
  const { t } = useTranslation("projectNotificationsSlidePanel");
  const dispatch = useDispatch();

  return (
    <>
      <IconButton
        icon="settings"
        onClick={() =>
          dispatch(PanelStoreActions.openPanel(PanelId.ProjectNotifications))
        }
      />
      <SlidePanel
        title={t("title")}
        panelId={PanelId.ProjectNotifications}
        closeButtonDirection="right"
        width={SIDE_PANEL_SIZE._400}
      >
        <ExpertProjectNotificationsForm projectId={projectId} />
      </SlidePanel>
    </>
  );
};
