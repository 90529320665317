import { BaseNotification } from "./BaseNotification";

export class PlaceholderNotification extends BaseNotification {
  title = "";
  subtitle = "";
  link = {};

  constructor(id: string) {
    super(id, "check");
  }
}
