import { Handlers, createReducer } from "reduxsauce";

import { DELETE_COLLECTION } from "./actions/DeleteCollection/DeleteCollectionActionTypes";
import {
  LIST_COLLECTIONS,
  LIST_COLLECTIONS_FAILURE,
  LIST_COLLECTIONS_RELOAD,
  LIST_COLLECTIONS_RESET,
  LIST_COLLECTIONS_SUCCESS
} from "./actions/ListCollections/ListCollectionsActionTypes";
import { RENAME_COLLECTION } from "./actions/RenameCollection/RenameCollectionActionTypes";
import { BookmarkCollectionsReducerState } from "./models/definitions";
import { handleDeleteCollection } from "./reducers/HandleDeleteCollectionReducer";
import {
  handleListCollections,
  handleListCollectionsFailure,
  handleListCollectionsReset,
  handleListCollectionsSuccess
} from "./reducers/HandleListCollectionsReducer";
import { handleRenameCollection } from "./reducers/HandleRenameCollectionReducer";
import { initialState } from "./reducers/InitialState";

const returnSameState = (
  state = initialState
): BookmarkCollectionsReducerState => ({
  ...state
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handlers: Handlers<BookmarkCollectionsReducerState, any> = {
  [LIST_COLLECTIONS]: handleListCollections,
  [LIST_COLLECTIONS_SUCCESS]: handleListCollectionsSuccess,
  [LIST_COLLECTIONS_FAILURE]: handleListCollectionsFailure,
  [LIST_COLLECTIONS_RELOAD]: returnSameState,
  [LIST_COLLECTIONS_RESET]: handleListCollectionsReset,
  [RENAME_COLLECTION]: handleRenameCollection,
  [DELETE_COLLECTION]: handleDeleteCollection
};

export const BookmarkCollectionsReducer = createReducer(initialState, handlers);
