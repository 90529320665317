import { useContext } from "react";

import {
  AccessControlContext,
  AccessControlContextProps
} from "./AccessControlContext";

export default function useAccessControlContext(): AccessControlContextProps {
  const context = useContext(AccessControlContext);
  if (!context) {
    throw new Error(
      "useAccessControlContext must be used within a AccessControlProvider"
    );
  }
  return context;
}
