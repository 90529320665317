import { Button } from "arbolus-ui-components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  CIQError,
  ErrorResponse,
  ExpertService,
  InternalComment,
  ToasterService
} from "@arbolus-technologies/api";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { DeleteModal, Loader } from "@arbolus-technologies/ui/components";

import { CommentCardItem } from "../../Components/CommentCardItem/CommentCardItem";
import { CreateUpdateCommentSlidePanel } from "../../Components/CreateUpdateCommentSlidePanel/CreateUpdateCommentSlidePanel";

import styles from "./InternalComments.module.scss";

const notificationService = new ToasterService();

interface InternalCommentsProps {
  expertId: string;
}

export const InternalComments: React.FC<InternalCommentsProps> = ({
  expertId
}) => {
  const [internalComments, setInternalComments] = useState<InternalComment[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedComment, setSelectedComment] = useState<InternalComment>();
  const [idToDelete, setIdToDelete] = useState<string>("");
  const [hasToUpdateInternalComment, setHasToUpdateInternalComment] =
    useState<boolean>(false);

  const { t } = useTranslation("internalComments");
  const dispatch = useDispatch();

  const getInternalComments = useCallback(() => {
    ExpertService.getInternalComments(expertId).subscribe(
      ({ items }) => {
        setInternalComments(items);
        setLoading(false);
      },
      ({ message }: ErrorResponse<CIQError>) => {
        notificationService.showError(message);
        setLoading(false);
      }
    );
  }, [expertId]);

  const removeInternalComment = useCallback(() => {
    ExpertService.deleteInternalComment(idToDelete).subscribe(
      () => {
        notificationService.showSuccess(t("commentDeleted"));
        getInternalComments();
        setIdToDelete("");
      },
      ({ message }: ErrorResponse<CIQError>) => {
        notificationService.showError(message);
      }
    );
  }, [getInternalComments, idToDelete, t]);

  useEffect(() => {
    getInternalComments();
  }, [hasToUpdateInternalComment, getInternalComments]);

  const handleCloseNewCommentPanel = (): void => {
    setSelectedComment(undefined);
    dispatch(PanelStoreActions.closePanel(PanelId.NewComment));
  };

  const handleOpenNewCommentPanel = () =>
    dispatch(PanelStoreActions.openPanel(PanelId.NewComment));

  const handleHasToUpdateInternalComments = () => {
    setHasToUpdateInternalComment((prevState) => !prevState);
    setSelectedComment(undefined);
  };

  const handleOnConfirmModal = () => {
    removeInternalComment();
    hideModal();
  };

  const showModal = () => setShowDeleteModal(true);

  const hideModal = () => setShowDeleteModal(false);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className={styles.newButtonWrapper}>
        <Button
          type="secondary"
          onClick={handleOpenNewCommentPanel}
          endIcon="add"
          text={t("newComment")}
        />
      </div>
      {internalComments.length > 0 ? (
        <div className={styles.container}>
          {internalComments.map((comment: InternalComment) => {
            const commentSelected = () => {
              setSelectedComment(comment);
              handleOpenNewCommentPanel();
            };

            return (
              <CommentCardItem
                {...comment}
                key={comment.id}
                handleCommentSelected={commentSelected}
                showDeleteModal={() => {
                  setIdToDelete(comment.id);
                  showModal();
                }}
              />
            );
          })}
        </div>
      ) : (
        <div className={styles.noResultContainer}>{t("noComments")}</div>
      )}

      <CreateUpdateCommentSlidePanel
        expertId={expertId}
        selectedComment={selectedComment}
        setSelectedComment={() => setSelectedComment(undefined)}
        handleHasToUpdateInternalComments={handleHasToUpdateInternalComments}
        customCloseRequest={handleCloseNewCommentPanel}
      />
      <DeleteModal
        toggleModal={showDeleteModal}
        messageTitle={t("removeCommentTitle")}
        warningMessage={t("removeCommentBody")}
        deleteActionText={t("deleteActionText")}
        onCancel={hideModal}
        onConfirm={handleOnConfirmModal}
      />
    </>
  );
};
