import { EditorState } from "draft-js";
import React, { useCallback, useEffect, useReducer, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { matchPath } from "react-router";
import { Subscription } from "rxjs";

import { ToasterService, TranscriptService } from "@arbolus-technologies/api";

import { PROJECT_EVENT_TRANSCRIPT } from "../../constants/navigation/projectRoutes";
import { AuthSelector } from "../../containers/auth/store";
import { CIQError, ErrorResponse } from "../../models/api";
import { Event } from "../../models/event";
import {
  Monologue,
  Transcript,
  TranscriptHighLight
} from "../../models/transcript";
import { Speaker } from "../../models/view/transcript";
import { DraftJSUtilService } from "../../services";
import { TranscriptService as TranscriptUtils } from "../../services/TranscriptService";
import { TranscriptContextProvider, TranscriptMode } from "./TranscriptContext";
import {
  RESET_EDITOR_SELECTION,
  SET_EDITOR_STATE,
  SET_MODE,
  TOGGLE_MONOLOGUE_TEXT,
  TranscriptContextReducerState,
  UPDATE_HIGHLIGHTS,
  UPDATE_SPEAKER_ASSIGNMENT,
  UPDATE_SPEAKER_ASSIGNMENT_FAILURE,
  UPDATE_SPEAKER_ASSIGNMENT_SUCCESS,
  transcriptContextReducer
} from "./TranscriptContextReducer";

const notification = new ToasterService();

interface TranscriptProviderProps {
  initTranscript: Transcript;
  initEvent: Event;
  children: React.ReactNode;
}

const TranscriptProvider: React.FC<TranscriptProviderProps> = ({
  children,
  initEvent,
  initTranscript
}): JSX.Element => {
  const { t } = useTranslation("transcript");
  const currentUserId = useSelector(AuthSelector.authUserIdSelector());
  const updateTranscriptSpeakerSubscription = useRef<Subscription>();

  const { id, transcriptionData, ...initTranscriptMeta } = initTranscript;
  const { monologues: initMonologues, speakers } = transcriptionData;
  const { highlightCount } = transcriptionData;

  // Reducer Initialization
  const monologueMap = new Map<string, Monologue>();
  initMonologues.forEach((monologue) => {
    monologueMap.set(monologue.id, monologue);
  });
  const initEditorState = DraftJSUtilService.applyFullTranscriptMode(
    Array.from(monologueMap.values()),
    currentUserId!
  );
  const speakerResults = TranscriptUtils.generateTranscriptSpeakers(
    initEvent,
    initTranscript
  );

  const transcriptContextReducerState: TranscriptContextReducerState = {
    transcriptMeta: {
      ...initTranscriptMeta,
      transcriptSpeakerCount: speakers.length
    },
    event: initEvent,
    monologues: monologueMap,
    editorState: initEditorState,
    speakersList: speakerResults.speakersList,
    speakersMap: speakerResults.speakersMap,
    mode: TranscriptMode.FULL_TRANSCRIPT,
    loadingSpeakers: [],
    highlightsCount: highlightCount
  };

  const [transcriptState, dispatch] = useReducer(
    transcriptContextReducer,
    transcriptContextReducerState
  );

  const routeMatch = matchPath<{ projectId: string; transcriptId: string }>(
    window.location.pathname,
    PROJECT_EVENT_TRANSCRIPT
  );
  const {
    mode,
    editorState,
    speakersList,
    speakersMap,
    loadingSpeakers,
    highlightsCount,
    event,
    monologues,
    transcriptMeta
  } = transcriptState;

  useEffect(
    () => (): void => {
      updateTranscriptSpeakerSubscription.current?.unsubscribe();
    },
    []
  );

  const resetEditorSelection = useCallback((): void => {
    dispatch({
      type: RESET_EDITOR_SELECTION
    });
  }, []);

  const setEditorState = useCallback((newEditorState: EditorState): void => {
    dispatch({
      type: SET_EDITOR_STATE,
      payload: {
        editorState: newEditorState
      }
    });
  }, []);

  const setTranscriptMode = useCallback(
    (nextMode: TranscriptMode): void => {
      dispatch({
        type: SET_MODE,
        payload: {
          mode: nextMode,
          currentUserId: currentUserId!
        }
      });
    },
    [currentUserId]
  );

  const toggleMonologueText = useCallback(
    (monologueId: string, isFullMode: boolean): void => {
      dispatch({
        type: TOGGLE_MONOLOGUE_TEXT,
        payload: {
          monologueId,
          isFullMode,
          currentUserId: currentUserId!
        }
      });
    },
    [currentUserId]
  );

  const updateHighlights = useCallback(
    (
      updatedEditorState: EditorState,
      monologueId: string,
      removedHighlights: string[],
      newHighlight?: TranscriptHighLight
    ): void => {
      dispatch({
        type: UPDATE_HIGHLIGHTS,
        payload: {
          updatedEditorState,
          monologueId,
          removedHighlights,
          currentUserId: currentUserId!,
          newHighlight
        }
      });
    },
    [currentUserId]
  );

  const updateSpeakerAssignment = useCallback(
    (speakerId: string, speaker: Speaker, isNewSpeaker: boolean) => {
      dispatch({ type: UPDATE_SPEAKER_ASSIGNMENT, payload: { speakerId } });

      const { params } = routeMatch!;
      const { projectId, transcriptId } = params;
      const { name, userId } = speaker;

      updateTranscriptSpeakerSubscription.current =
        TranscriptService.updateTranscriptSpeaker(
          projectId,
          transcriptId,
          speakerId,
          name,
          userId
        ).subscribe(
          () => {
            dispatch({
              type: UPDATE_SPEAKER_ASSIGNMENT_SUCCESS,
              payload: {
                speaker,
                speakerId,
                isNewSpeaker
              }
            });

            notification.showSuccess(t("assignSuccess"));
          },
          (error: ErrorResponse<CIQError>) => {
            notification.showError(error.message);
            dispatch({
              type: UPDATE_SPEAKER_ASSIGNMENT_FAILURE,
              payload: {
                speakerId
              }
            });
          }
        );
    },
    [t, routeMatch]
  );

  return (
    <TranscriptContextProvider
      value={{
        mode,
        setTranscriptMode,
        editorState,
        speakersMap,
        speakersList,
        updateSpeakerAssignment,
        loadingSpeakers,
        resetEditorSelection,
        toggleMonologueText,
        setEditorState,
        highlightsCount,
        updateHighlights,
        event,
        transcriptMeta,
        monologues
      }}
    >
      {children}
    </TranscriptContextProvider>
  );
};

export default TranscriptProvider;
