import clsx from "clsx";
import moment from "moment";
import React, { useState, useEffect } from "react";

import { UtilsService } from "../../../../services";

import christmasHatImage from "../../../../assets/images/icons/christmas-hat.png";

interface AvatarImageProps {
  username: string;
  profileImageUrl?: string;
  christmasHat?: boolean;
  imageClass?: string;
  fallBackImageClass?: string;
  onClick?: () => void;
  id?: string;
  type?: "card" | "smallCircle" | "bigCircle" | "adding" | "mediumCircle";
  adding?: boolean;
}

const AvatarImage: React.FC<AvatarImageProps> = ({
  username,
  profileImageUrl,
  christmasHat,
  imageClass = "user-avatar",
  fallBackImageClass = "fallback-avatar",
  onClick,
  id,
  type,
  adding
}) => {
  const [error, setError] = useState<boolean>(false);
  const avatarName = UtilsService.nameToAvatarText(username ?? "?");
  const enableChristmasHat = UtilsService.enableFunctionalityBasedOnDate(
    new Date(moment().year(), 11, 24),
    new Date(moment().year(), 11, 27)
  );

  const avatarClass = (): string => {
    switch (type) {
      case "card":
        return "avatar-container-card";
      case "bigCircle":
        return "avatar-container-bigCircle";
      case "mediumCircle":
        return "avatar-container-mediumCircle";
      case "smallCircle":
        return "avatar-container-smallCircle";
      case "adding":
        return "avatar-container-adding";
      default:
        return "avatar-container";
    }
  };

  useEffect(() => {
    setError(!profileImageUrl);
  }, [profileImageUrl]);

  return error ? (
    <div className={avatarClass()} onClick={onClick} id={id}>
      {christmasHat && enableChristmasHat && (
        <img
          className="christmas-hat"
          src={christmasHatImage}
          alt="christmas hat"
        />
      )}
      <div className={clsx(fallBackImageClass, adding ? "adding" : null)}>
        {adding && "+"}
        {avatarName}
      </div>
    </div>
  ) : (
    <div className={avatarClass()} onClick={onClick} id={id}>
      {christmasHat && enableChristmasHat && (
        <img
          className="christmas-hat"
          src={christmasHatImage}
          alt="christmas hat"
        />
      )}
      <img
        className={imageClass}
        src={profileImageUrl}
        onError={(): void => setError(true)}
        alt={avatarName}
      />
    </div>
  );
};

/**
 * @deprecated
 * use Avatar from @arbolus-technologies/libs/ui/components
 */
export default AvatarImage;
