import React from "react";
import { TypeaheadResult } from "react-bootstrap-typeahead";
import { useTranslation } from "react-i18next";
import { Observable } from "rxjs";

import {
  ApiPaginatedRequest,
  ApiPaginatedResponse,
  Client
} from "@arbolus-technologies/api";
import { Avatar } from "@arbolus-technologies/ui/components";

import {
  SelectController,
  TypeaheadSelectorControllerProps
} from "../SelectTypeahead/SelectController";

import styles from "./ClientSelector.module.scss";

interface DisplayClient {
  name: string;
  logoUrl: string;
}

export interface ClientSelectorProps<T extends Client>
  extends TypeaheadSelectorControllerProps<Client> {
  getItems: (
    pagination: ApiPaginatedRequest
  ) => Observable<ApiPaginatedResponse<T>>;
}

export const ClientSelector = <T extends Client>({
  ...props
}: ClientSelectorProps<T>): React.ReactElement => {
  const { t } = useTranslation();

  const renderer = ({
    name,
    logoUrl
  }: TypeaheadResult<DisplayClient>): JSX.Element => (
    <div className={styles.itemContainer}>
      <Avatar avatar={{ name, imageUrl: logoUrl }} />
      <span className={styles.itemText}>{name}</span>
    </div>
  );

  return (
    <SelectController
      {...props}
      placeholderText={t("common:clientSelection:findClient")}
      promptText={t("common:clientSelection:findingClients")}
      searchText={t("common:clientSelection:findingClients")}
      paginationText={t("common:clientSelection:loadMore")}
      orderBy="name"
      renderer={renderer}
      getLabelKey={(client) => client?.name ?? ""}
      displayResults={10}
    />
  );
};
