import React from "react";

import { Avatar } from "@arbolus-technologies/ui/components";
import { maxLengthString } from "@arbolus-technologies/utils";

import { ResponseDate } from "./ResponseDate";

import styles from "./BookmarkAnswer.module.scss";

interface ResponseDateAndExpertProps {
  name: string;
  imageUrl: string;
  responseDate: Date;
  handleOpenProfile: (expertId: string) => void;
  expertId: string;
}

export const ResponseDateAndExpert: React.FC<ResponseDateAndExpertProps> = ({
  name,
  imageUrl,
  responseDate,
  handleOpenProfile,
  expertId
}) => (
  <div className={styles.responseDateAndExpert}>
    <ResponseDate responseDate={responseDate} />
    <div
      className={styles.expertContainer}
      style={{ cursor: "pointer" }}
      onClick={() => handleOpenProfile(expertId)}
    >
      <Avatar
        avatar={{
          name,
          imageUrl
        }}
      />
      <span>{maxLengthString(name, 20)}</span>
    </div>
  </div>
);
