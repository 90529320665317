import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { ToasterService, User } from "@arbolus-technologies/api";
import { SlidePanel } from "@arbolus-technologies/features/common";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { UserHeader } from "@arbolus-technologies/ui/components";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { ClientAddToProject } from "./ClientAddToProject";
import { ClientUserTabContent } from "./ClientUserTabContent/ClientUserTabContent";
import { ClientUserTabHeader } from "./ClientUserTabHeader/ClientUserTabHeader";
import { CLIENT_PROFILE_TABS } from "./type";

import styles from "./ClientUserSlidePanel.module.scss";

const notificationService = new ToasterService();

interface ClientUserProps {
  user: User | null;
  clientName?: string;
  clientId?: string;
  showBackTitle?: boolean;
  goToClientEditPage?: () => void;
  onClose?: () => void;
}

const { PROFILE } = CLIENT_PROFILE_TABS;
const itemIconColor = ARBOLUS_COLORS.bColorGrayIcon;

export const ClientUserSlidePanel: React.FC<ClientUserProps> = ({
  user,
  clientName,
  clientId,
  goToClientEditPage,
  onClose,
  showBackTitle = false
}) => {
  const { t } = useTranslation("clientUserSlidePanel");
  const dispatch = useDispatch();
  const closePanel = () =>
    dispatch(PanelStoreActions.closePanel(PanelId.ClientProfile));

  const [activeTab, setActiveTab] = useState<CLIENT_PROFILE_TABS>(PROFILE);
  const [addToProjectStep, setAddToProjectStep] = useState<boolean>(false);

  const threeDotsItems = [
    {
      title: t("editProfile"),
      icon: "edit",
      iconColor: itemIconColor,
      color: "",
      onClick: () => goToClientEditPage?.()
    },
    {
      title: t("addToProject"),
      icon: "add",
      iconColor: itemIconColor,
      color: "",
      onClick: () => setAddToProjectStep(true)
    }
  ];

  const renderSlidePanelTitle = () => {
    if (addToProjectStep) {
      return (
        <div onClick={() => setAddToProjectStep(false)}>
          <span className={clsx("material-symbols-sharp", styles.arrowLeft)}>
            chevron_left
          </span>
          <h2>{t("addProjectTitle")}</h2>
        </div>
      );
    }
    if (showBackTitle) {
      return (
        <div className={styles.showBackTitleWrapper} onClick={closePanel}>
          <span
            className={clsx(
              "material-symbols-sharp",
              styles.arrowLeft,
              styles.element
            )}
          >
            chevron_left
          </span>
          <h2 className={styles.element}>{t("details")}</h2>
        </div>
      );
    }
    return <h2>{t("clientTeam")}</h2>;
  };

  const renderSidePanelContent = () => {
    if (!clientId || !user) return null;

    if (addToProjectStep) {
      return (
        <ClientAddToProject
          clientId={clientId}
          userId={user.id}
          notificationService={notificationService}
        />
      );
    }

    return (
      <>
        <UserHeader
          user={user}
          threeDotsItems={threeDotsItems}
          clientName={clientName}
        />
        <ClientUserTabHeader
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ClientUserTabContent
          activeTab={activeTab}
          clientId={clientId}
          user={user}
          notificationService={notificationService}
          onUserDecision={closePanel}
        />
      </>
    );
  };

  return (
    <SlidePanel
      panelId={PanelId.ClientProfile}
      title={
        <div className={styles.slidePanelTitle}>{renderSlidePanelTitle()}</div>
      }
      width={SIDE_PANEL_SIZE._720}
      closeButtonDirection="right"
      customCloseRequest={() => {
        closePanel();
        setAddToProjectStep(false);
        onClose && onClose();
      }}
      classnames={{ overlay: styles.panelOverlay }}
    >
      {renderSidePanelContent()}
    </SlidePanel>
  );
};
