import { useEffect, useState } from "react";

interface UseCheckMicrophone {
  getMicrophoneStatus: () => void;
  isMicrophoneAvailable: boolean;
  isMicrophoneMuted: boolean;
}

export const useCheckMicrophone = (): UseCheckMicrophone => {
  const [isMicrophoneAvailable, setIsMicrophoneAvailable] =
    useState<boolean>(true);
  const [isMicrophoneMuted, setIsMicrophoneMuted] = useState<boolean>(false);
  const [intervalId, setIntervalId] = useState<number | undefined>();

  const getMicrophoneStatus = () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: true
      })
      .then((stream) => {
        // Microphone available getUserMedia success
        // Check docs https://www.webrtc-developers.com/how-to-know-if-my-microphone-works/#permission-to-use-a-device

        const audioTracks = stream.getAudioTracks();
        // audioTracks.length === 0 => No audio from microphone has been captured.
        if (audioTracks.length === 0) {
          setIsMicrophoneAvailable(false);
        }
        if (audioTracks.length > 0) {
          const track = audioTracks[0];
          // !track.enabled => Track is disabled which means that the track provides silence instead of real data. When disabled, a track can be enabled again. When in that case, user can't be heard until track is enabled again.
          // track.readyState === 'ended' => Possibly a disconnection of the device. When ended, a track can't be active again. This track will no more provide data.
          if (!track.enabled || track.readyState === "ended") {
            setIsMicrophoneAvailable(false);
          } else {
            // Audio track is providing data
            // Create and configure the audio pipeline
            const audioContext = new AudioContext();
            const analyzer = audioContext.createAnalyser();
            analyzer.fftSize = 512;
            analyzer.smoothingTimeConstant = 0.1;
            const sourceNode = audioContext.createMediaStreamSource(stream);
            sourceNode.connect(analyzer);
            // Analyze the sound
            if (!intervalId) {
              const interval = setInterval(function testMicrophone() {
                // Compute the max volume level (-Infinity...0)
                const fftBins = new Float32Array(analyzer.frequencyBinCount); // Number of values manipulated for each sample
                analyzer.getFloatFrequencyData(fftBins);

                // Compute a wave (0...)
                const frequencyRangeData = new Uint8Array(
                  analyzer.frequencyBinCount
                );
                analyzer.getByteFrequencyData(frequencyRangeData);
                const sum = frequencyRangeData.reduce((p, c) => p + c, 0);
                // audioMeter varies from 0 to 10
                const audioMeter = Math.sqrt(sum / frequencyRangeData.length);

                // audioMeter === 0 --> no sound input
                setIsMicrophoneMuted(audioMeter === 0);
              }, 2000);
              setIntervalId(interval);
            }

            // Check microphone status every time there is a physical device change
            navigator.mediaDevices.ondevicechange = () => {
              setTimeout(() => getMicrophoneStatus(), 2000);
            };
          }
        }
      })
      .catch(() => {
        // Microphone not available getUserMedia error
        setIsMicrophoneAvailable(false);
      });
  };

  useEffect(
    () => () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    },
    [intervalId]
  );

  return {
    getMicrophoneStatus,
    isMicrophoneAvailable,
    isMicrophoneMuted
  };
};
