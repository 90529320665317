import {
  BlockMapBuilder,
  CharacterMetadata,
  ContentBlock,
  ContentState,
  EditorState,
  Modifier,
  SelectionState
} from "draft-js";
import Immutable from "immutable";
import _ from "lodash";

import { TranscriptService } from "@arbolus-technologies/api";
import { MonologueContentBlockDataKey } from "@arbolus-technologies/models/common";

import {
  TRANSCRIPT_DRAFT_JS_ENTITY,
  TRANSCRIPT_HIGHLIGHT_TRIM_WORD_LIMIT
} from "../constants/transcript";
import { Monologue, TranscriptHighLight } from "../models/transcript";
import {
  DraftJSHighlightEntity,
  HighlightOffset
} from "../models/view/transcript";
import { utilService as UtilsService } from "./UtilService";

export const draftJSUtilService = {
  getSelectionText: (editorState: EditorState): string => {
    const selection = editorState.getSelection();
    const anchorKey = selection.getAnchorKey();
    const currentContent = editorState.getCurrentContent();
    const currentBlock = currentContent.getBlockForKey(anchorKey);

    // Then based on the docs for SelectionState -
    const start = selection.getStartOffset();
    const end = selection.getEndOffset();
    return currentBlock.getText().slice(start, end);
  },
  getHighlightEntitiesWithinSelection: (
    editorState: EditorState
  ): DraftJSHighlightEntity[] => {
    const selection = editorState.getSelection();
    const selectionStart = selection.getStartOffset();
    const selectionEnd = selection.getEndOffset();

    const currentContent = editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(
      selection.getStartKey()
    );
    const characterList = currentContentBlock.getCharacterList();

    const selectionCharList = characterList.slice(selectionStart, selectionEnd);

    const entities = new Map<string, DraftJSHighlightEntity>();

    selectionCharList.forEach((characterMeta) => {
      if (
        characterMeta?.getEntity() &&
        !entities.has(characterMeta?.getEntity())
      ) {
        const entityKey = characterMeta.getEntity();
        const entity = currentContent.getEntity(entityKey);
        const entityData = entity.getData() as DraftJSHighlightEntity;

        entities.set(entityKey, entityData);
      }
    });

    return Array.from(entities.values());
  },
  createCustomSelectionWithSelection: (
    selection: SelectionState,
    startIndex: number,
    endIndex: number
  ): SelectionState => {
    const isBackWardSelection = selection.getIsBackward();
    const anchorOffset = isBackWardSelection ? endIndex : startIndex;
    const focusOffset = isBackWardSelection ? startIndex : endIndex;

    return new SelectionState({
      anchorKey: selection.getAnchorKey(),
      anchorOffset,
      focusKey: selection.getFocusKey(),
      focusOffset,
      handleFocus: selection.getHasFocus(),
      isBackward: selection.getIsBackward()
    });
  },
  clearSelectionsFromEditorState: (
    editorState: EditorState,
    monologueId: string
  ): EditorState =>
    EditorState.forceSelection(
      editorState,
      SelectionState.createEmpty(monologueId)
    ),
  reprocessOtherHighlights: (
    contentState: ContentState,
    selectionState: SelectionState,
    highlights: TranscriptHighLight[],
    currentUserId: string,
    rangeStart: number,
    rangeEnd: number
  ): ContentState => {
    let updatedContentState = contentState;

    const processHighlights = highlights.filter(
      ({ userId, startIndex, endIndex }) =>
        userId !== currentUserId &&
        (UtilsService.isBetween(rangeStart, startIndex, endIndex) ||
          UtilsService.isBetween(rangeEnd, startIndex, endIndex))
    );

    processHighlights.forEach(({ id, startIndex, endIndex, userId }) => {
      updatedContentState = updatedContentState.createEntity(
        TRANSCRIPT_DRAFT_JS_ENTITY.HIGHLIGHT,
        "MUTABLE",
        {
          highlightId: id,
          startIndex,
          endIndex,
          userId
        } as DraftJSHighlightEntity
      );

      const newHighlightEntityKey =
        updatedContentState.getLastCreatedEntityKey();

      // Apply highlight entity from selection
      updatedContentState = Modifier.applyEntity(
        updatedContentState,
        draftJSUtilService.createCustomSelectionWithSelection(
          selectionState,
          startIndex,
          endIndex
        ),
        newHighlightEntityKey
      );
    });

    return updatedContentState;
  },
  addHighlightFromEditorState: (
    contentState: ContentState,
    selection: SelectionState,
    highlightEntity: DraftJSHighlightEntity
  ): ContentState => {
    let updatedContentState = contentState;

    updatedContentState = updatedContentState.createEntity(
      TRANSCRIPT_DRAFT_JS_ENTITY.OWN_HIGHLIGHT,
      "MUTABLE",
      highlightEntity
    );

    const newHighlightEntityKey = updatedContentState.getLastCreatedEntityKey();

    // Apply highlight entity for selection
    updatedContentState = Modifier.applyEntity(
      updatedContentState,
      selection,
      newHighlightEntityKey
    );

    return updatedContentState;
  },
  removeHighlightFromEditorState: (
    selection: SelectionState,
    contentState: ContentState,
    { startIndex, endIndex }: DraftJSHighlightEntity
  ): ContentState => {
    const customSelection =
      draftJSUtilService.createCustomSelectionWithSelection(
        selection,
        startIndex,
        endIndex
      );

    // Remove highlight entity from selection
    return Modifier.applyEntity(contentState, customSelection, null);
  },
  isSelectionHighlighted: (
    editorState: EditorState,
    currentUserId: string
  ): boolean => {
    const selection = editorState.getSelection();
    const selectionStart = selection.getStartOffset();
    const selectionEnd = selection.getEndOffset();

    const allHighlightEntities =
      draftJSUtilService.getHighlightEntitiesWithinSelection(editorState);

    // Check own highlights within/exact selection range
    const highlightExist = allHighlightEntities.find(
      ({ userId, startIndex, endIndex }) =>
        userId === currentUserId &&
        ((selectionStart === startIndex && selectionEnd === endIndex) ||
          (selectionStart >= startIndex && selectionEnd <= endIndex))
    );

    return !!highlightExist;
  },
  createHighlightForSelection: async (
    editorState: EditorState,
    projectId: string,
    transcriptId: string,
    currentUserId: string
  ): Promise<{
    updatedEditorState: EditorState;
    monologueId: string;
    deletedHighlights: string[];
    newHighlight: TranscriptHighLight;
  }> => {
    const selection = editorState.getSelection();

    const selectionStart = selection.getStartOffset();
    const selectionEnd = selection.getEndOffset();

    let currentContent = editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(
      selection.getStartKey()
    );
    const selectedText = currentContentBlock
      .getText()
      .slice(selectionStart, selectionEnd);
    const monologueId = currentContentBlock.getKey();

    const allHighlightEntities =
      draftJSUtilService.getHighlightEntitiesWithinSelection(editorState);

    // Check own highlights within/exact selection range
    const ownHighlightsWithinSelection = allHighlightEntities.filter(
      ({ userId }) => userId === currentUserId
    );

    // Remove conflicting highlight within selection
    ownHighlightsWithinSelection.forEach((highlight) => {
      currentContent = draftJSUtilService.removeHighlightFromEditorState(
        selection,
        currentContent,
        highlight
      );
    });

    // Create new highlight
    currentContent = draftJSUtilService.addHighlightFromEditorState(
      currentContent,
      selection,
      {
        endIndex: selectionEnd,
        startIndex: selectionStart,
        userId: currentUserId,
        highlightId: ""
      }
    );

    const newEntityKey = currentContent.getLastCreatedEntityKey();

    const stagedToDeleteIds = ownHighlightsWithinSelection.map(
      (hl) => hl.highlightId
    );
    if (stagedToDeleteIds.length > 0) {
      // Sync deletion Changes with Server
      await TranscriptService.deleteTranscriptHighlights(
        projectId,
        transcriptId,
        monologueId,
        stagedToDeleteIds
      ).toPromise();
    }

    // Sync creation changes with Server
    const result = await TranscriptService.createTranscriptHighlight(
      projectId,
      transcriptId,
      {
        monologueId,
        endIndex: selectionEnd,
        startIndex: selectionStart,
        text: selectedText
      }
    ).toPromise();

    const newHighlightEntity = {
      id: result.id,
      endIndex: selectionEnd,
      startIndex: selectionStart,
      createdDate: new Date(),
      userId: currentUserId
    };

    // Update entity data
    currentContent = currentContent.mergeEntityData(newEntityKey, {
      highlightId: result.id
    });

    // Apply changes + clear selection
    return {
      updatedEditorState: draftJSUtilService.clearSelectionsFromEditorState(
        EditorState.push(editorState, currentContent, "change-inline-style"),
        monologueId
      ),
      monologueId,
      deletedHighlights: stagedToDeleteIds,
      newHighlight: newHighlightEntity
    };
  },
  deleteHighlightForSelection: async (
    editorState: EditorState,
    projectId: string,
    transcriptId: string,
    currentUserId: string,
    highlightMap: Map<string, TranscriptHighLight[]>
  ): Promise<{
    updatedEditorState: EditorState;
    monologueId: string;
    removedHighlightId: string;
  }> => {
    const selection = editorState.getSelection();

    const selectionStart = selection.getStartOffset();
    let currentContent = editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(
      selection.getStartKey()
    );
    const monologueId = currentContentBlock.getKey();

    const characterList = currentContentBlock.getCharacterList();

    const startCharMeta = characterList.get(selectionStart);
    const highlightEntityKey = startCharMeta.getEntity();
    const entity = currentContent.getEntity(highlightEntityKey);
    const highlightEntityData = entity.getData() as DraftJSHighlightEntity;
    const { highlightId } = highlightEntityData;

    await TranscriptService.deleteTranscriptHighlights(
      projectId,
      transcriptId,
      monologueId,
      [highlightId]
    ).toPromise();

    // Remove own highlight
    currentContent = draftJSUtilService.removeHighlightFromEditorState(
      selection,
      currentContent,
      highlightEntityData
    );

    // Re-add others highlights
    currentContent = draftJSUtilService.reprocessOtherHighlights(
      currentContent,
      selection,
      highlightMap.get(monologueId)!,
      currentUserId,
      selectionStart,
      selection.getEndOffset()
    );

    return {
      updatedEditorState: draftJSUtilService.clearSelectionsFromEditorState(
        EditorState.push(editorState, currentContent, "apply-entity"),
        monologueId
      ),
      monologueId,
      removedHighlightId: highlightId
    };
  },
  isHighlightsExist: (
    contentBlock: ContentBlock,
    contentState: ContentState
  ): boolean =>
    !!contentBlock.getCharacterList().find((character) => {
      const entityKey = character?.getEntity();

      if (entityKey) {
        const entityType = contentState.getEntity(entityKey).getType();

        return [
          TRANSCRIPT_DRAFT_JS_ENTITY.HIGHLIGHT,
          TRANSCRIPT_DRAFT_JS_ENTITY.OWN_HIGHLIGHT
        ].includes(entityType);
      }

      return false;
    }),
  findHighlightTrimRanges: (
    monologueText: string,
    highlights: TranscriptHighLight[]
  ): HighlightOffset => {
    const textLength = monologueText.length;
    const firstHighlight = _.minBy(highlights, "startIndex");
    const lastHighlight = _.maxBy(highlights, "endIndex");

    // If in case no highlights
    if (!firstHighlight || !lastHighlight) {
      return {
        trimStart: 0,
        trimEnd: textLength
      };
    }

    let trimStart = firstHighlight.startIndex;
    let trimEnd = lastHighlight.endIndex;

    if (trimEnd < textLength) {
      const lastText = monologueText.substring(trimEnd, textLength);
      const lastTextWords = lastText.split(" ");
      const lastSelectedWords = lastTextWords.slice(
        0,
        TRANSCRIPT_HIGHLIGHT_TRIM_WORD_LIMIT + 1
      );
      const lastSelectedText = lastSelectedWords.join(" ");
      const backOffset = lastSelectedText.length;
      trimEnd = lastHighlight.endIndex + backOffset;
    }

    if (trimStart !== 0) {
      const frontText = monologueText.substring(0, trimStart);
      const frontTextWords = frontText.split(" ");
      const frontTextWordsRev = frontTextWords.reverse();
      const frontSelectedWords = frontTextWordsRev.slice(
        0,
        TRANSCRIPT_HIGHLIGHT_TRIM_WORD_LIMIT + 1
      );
      const frontSelectedText = frontSelectedWords.join(" ");
      const frontOffset = frontSelectedText.length;

      trimStart = firstHighlight.startIndex - frontOffset;
    }

    return {
      trimStart,
      trimEnd
    };
  },
  createCharacterMetaData: (
    currentUserId: string,
    contentState: ContentState,
    monologueText: string,
    highlights: TranscriptHighLight[],
    highlightStartOffset = 0
  ): Immutable.List<CharacterMetadata> => {
    let newContentState = contentState;

    // Create character meta data for text
    let characterMetaData = Immutable.List(
      monologueText.split("").map(() => CharacterMetadata.create())
    );

    // Apply highlights to character meta data
    highlights.forEach(({ id: highlightId, userId, startIndex, endIndex }) => {
      let start = startIndex;

      const ownHightLight = userId === currentUserId;
      const highlightStart = startIndex - highlightStartOffset;
      const highlightEnd = endIndex - highlightStartOffset;

      newContentState = newContentState.createEntity(
        ownHightLight
          ? TRANSCRIPT_DRAFT_JS_ENTITY.OWN_HIGHLIGHT
          : TRANSCRIPT_DRAFT_JS_ENTITY.HIGHLIGHT,
        "MUTABLE",
        {
          highlightId,
          userId,
          startIndex: highlightStart,
          endIndex: highlightEnd
        } as DraftJSHighlightEntity
      );

      // Create new entity per highlight
      const entityKey = newContentState.getLastCreatedEntityKey();

      while (start < endIndex) {
        const entityAppliedMeta = CharacterMetadata.applyEntity(
          characterMetaData.get(start - highlightStartOffset),
          entityKey
        );

        characterMetaData = characterMetaData.set(
          start - highlightStartOffset,
          entityAppliedMeta
        );
        start += 1;
      }
    });

    return characterMetaData;
  },
  createMonologueContentBlock: (
    contentState: ContentState,
    monologue: Monologue,
    currentUserId: string,
    isTruncated = false
  ): ContentBlock => {
    const { id, text, highlights, speakerId, startTime } = monologue;

    let monologueText = text;
    const highlightOffset = draftJSUtilService.findHighlightTrimRanges(
      text,
      highlights
    );

    if (isTruncated) {
      monologueText = text.substring(
        highlightOffset.trimStart,
        highlightOffset.trimEnd
      );
    }

    const characterMetaData = draftJSUtilService.createCharacterMetaData(
      currentUserId,
      contentState,
      monologueText,
      highlights,
      isTruncated ? highlightOffset.trimStart : 0
    );

    return new ContentBlock({
      key: id,
      text: monologueText,
      type: "monologue",
      data: Immutable.Map<MonologueContentBlockDataKey, string | number>([
        ["speaker", speakerId],
        ["start", startTime],
        ["trimStart", highlightOffset.trimStart], // Actual start trim points if truncated
        ["trimEnd", highlightOffset.trimEnd] // Actual end trim points if truncated
      ]),
      characterList: characterMetaData
    });
  },
  applyFullTranscriptMode: (
    monologues: Monologue[],
    currentUserId: string
  ): EditorState => {
    const contentState = new ContentState();

    const blocks: ContentBlock[] = monologues.map((monologue) =>
      draftJSUtilService.createMonologueContentBlock(
        contentState,
        monologue,
        currentUserId,
        false
      )
    );

    const blockMap = BlockMapBuilder.createFromArray(blocks);
    const editorContentState = contentState.merge({
      blockMap
    });

    const editorState = EditorState.createWithContent(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      editorContentState as any
    );

    const undoDisabledEditor = EditorState.set(editorState, {
      allowUndo: false
    });

    return undoDisabledEditor;
  },
  applyHighlightsMode: (
    monologues: Map<string, Monologue>,
    currentUserId: string
  ): EditorState => {
    const contentState = new ContentState();

    // Filter monologues with highlights
    const highlightedMonologueIds = Array.from(monologues.values())
      .filter((m) => m.highlights.length > 0)
      .map((m) => m.id);

    // Create new content blocks for monologues
    const blocks: ContentBlock[] = highlightedMonologueIds.map(
      (monologueId) => {
        const monologue = monologues.get(monologueId)!;

        return draftJSUtilService.createMonologueContentBlock(
          contentState,
          monologue,
          currentUserId,
          true
        );
      }
    );

    const blockMap = BlockMapBuilder.createFromArray(blocks);
    const editorContentState = contentState.merge({
      blockMap
    });

    return EditorState.createWithContent(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      editorContentState as any
    );
  },
  toggleMonologueHighlights: (
    editorState: EditorState,
    monologues: Map<string, Monologue>,
    monologueId: string,
    currentUserId: string,
    isFullMode: boolean
  ): EditorState => {
    const contentState = editorState.getCurrentContent();
    const blocks = contentState.getBlocksAsArray();

    const updatedBlocks = blocks.map((block) => {
      const mId = block.getKey();

      // Change only toggled block
      if (mId === monologueId) {
        const monologue = monologues.get(mId)!;
        return draftJSUtilService.createMonologueContentBlock(
          contentState,
          monologue,
          currentUserId,
          !isFullMode
        );
      }

      return block;
    });

    const blockMap = BlockMapBuilder.createFromArray(updatedBlocks);
    const editorContentState = contentState.merge({
      blockMap
    });

    return EditorState.createWithContent(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      editorContentState as any
    );
  }
};
