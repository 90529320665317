import React from "react";

import { UserModel } from "@arbolus-technologies/models/common";

import { UserPortrait } from "../Portraits/components/UserPortrait/UserPortrait";

import styles from "./Testimonial.module.scss";

interface TestimonialProps {
  user: UserModel;
  testimonial: string;
}

export const Testimonial: React.FC<TestimonialProps> = ({
  user,
  testimonial
}) => (
  <div className={styles.container}>
    <div className={styles.userPortraitContainer}>
      <UserPortrait user={user} avatarSize="mediumXSCircle" />
    </div>
    <div className={styles.testimonial}>{`“${testimonial}”`}</div>
  </div>
);
