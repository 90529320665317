import { LocationDescriptorObject } from "history";
import React from "react";
import { Link } from "react-router-dom";

import {
  InternalArbolusModal,
  InternalArbolusModalProps
} from "./InternalArbolusModal";

import styles from "./ArbolusListModal.module.scss";

interface ArbolusListModalProps extends InternalArbolusModalProps {
  messages: { text: string; to?: LocationDescriptorObject }[];
  isReduxService?: boolean;
}

export const ArbolusListModal: React.FC<ArbolusListModalProps> = ({
  messages,
  isReduxService = false,
  ...props
}) => {
  const body = (
    <ul className={styles.list}>
      {messages.map((message, index) => (
        <li key={index} className={styles.link}>
          {message.to ? (
            isReduxService ? (
              <a
                key={index}
                href={message.to.pathname}
                target="_blank"
                rel="noopener noreferrer"
              >
                {message.text}
              </a>
            ) : (
              <Link
                key={index}
                to={message.to as LocationDescriptorObject}
                target="_blank"
              >
                {message.text}
              </Link>
            )
          ) : (
            message.text
          )}
        </li>
      ))}
    </ul>
  );

  return <InternalArbolusModal body={body} {...props} />;
};
