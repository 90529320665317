import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  CIQError,
  CustomersService,
  DefaultToasterService,
  ErrorResponse,
  ExpertBaseProject,
  ProjectService,
  SORT_DIRECTION,
  ToasterService
} from "@arbolus-technologies/api";
import {
  MixpanelPages,
  PageTracker
} from "@arbolus-technologies/features/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { InfiniteScrollWithHeader } from "@arbolus-technologies/ui/components";
import { AntDHeader } from "@arbolus-technologies/ui/layout";
import { useDocumentTitle } from "@arbolus-technologies/utils";

import { ProjectCard } from "../../Components/ProjectCard/ProjectCard";
import {
  ProjectListFilterParams,
  ProjectListFilters
} from "../../Components/ProjectListFilters/ProjectListFilters";
import { PROJECT_ORDER_BY } from "../../helpers/constants";
import { EncouragementCard } from "./EncouragementCard/EncouragementCard";

import styles from "./ExpertProjectList.module.scss";

interface ExpertProjectListProps {
  projectService?: typeof ProjectService;
  toasterService?: ToasterService;
}

export const ExpertProjectList: React.FC<ExpertProjectListProps> = ({
  projectService = ProjectService,
  toasterService = DefaultToasterService
}) => {
  const { t } = useTranslation("expertProjectList");
  useDocumentTitle("projectsPage", "projects");

  const [projects, setProjects] = useState<ExpertBaseProject[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isMore, setIsMore] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<ProjectListFilterParams>({
    searchTerm: "",
    orderBy: PROJECT_ORDER_BY.CREATED,
    orderDirection: SORT_DIRECTION.DESCENDING
  });
  const loggedInExpertId = useSelector(CacheSelector.loggedInUserExpertId());
  const [showEncouragement, setShowEncouragement] = useState(false);

  useEffect(function getVendors() {
    CustomersService.getInsightsFromExpert(loggedInExpertId).subscribe(
      (response) => {
        const currentJob = response.items.find((item) => item.isCurrentJob);
        if (currentJob) {
          const hasVendors = currentJob.insightVendors.length > 0;
          setShowEncouragement(!hasVendors);
        }
      },
      (error) => toasterService.showApiErrors(error)
    );
  });

  function getProjects(
    searchTerm = filterParams.searchTerm,
    orderBy = filterParams.orderBy,
    orderDirection = filterParams.orderDirection,
    restart = false
  ) {
    if (!isMore && !restart) {
      return;
    }

    if (restart) {
      setProjects([]);
    }

    setIsLoading(true);

    projectService
      .getExpertProjects(
        searchTerm,
        orderBy,
        orderDirection,
        (!restart && projects?.length) || 0
      )
      .subscribe(
        ({ items, pagination }) => {
          setProjects((prev) =>
            prev && !restart ? [...prev, ...items] : [...items]
          );
          setIsMore(
            pagination.count >
              ((!restart && projects?.length) || 0) + items.length
          );

          setIsLoading(false);
        },
        (error: ErrorResponse<CIQError>) => {
          setIsLoading(false);
          toasterService.showError(error.message);
        }
      );
  }

  const renderer = (project: ExpertBaseProject) => (
    <ProjectCard
      key={project.id}
      id={project.id}
      title={project.name}
      activeReferral={project.activeReferral}
      hasUnreadMessages={project.hasUnreadMessages}
      applicationStatus={project.referralApplicationStatus}
    />
  );

  const onFilterChange = useCallback(
    (filterParams: ProjectListFilterParams) => {
      setFilterParams(filterParams);
      getProjects(
        filterParams.searchTerm,
        filterParams.orderBy,
        filterParams.orderDirection,
        true
      );
    },
    []
  );

  return (
    <PageTracker page={MixpanelPages.Projects}>
      <div className={styles.container}>
        <InfiniteScrollWithHeader
          smallMarginBottom
          classnames={styles.infiniteScroll}
          rendererContainerClassnames={styles.cardsContainer}
          items={projects}
          introItem={showEncouragement ? <EncouragementCard /> : undefined}
          itemHeight={59}
          isLoading={isLoading}
          nextItems={() => getProjects()}
          renderer={renderer}
        >
          <div className={styles.filterContainer}>
            <AntDHeader title={t("projects")} />
            <ProjectListFilters onFilterChange={onFilterChange} />
          </div>
        </InfiniteScrollWithHeader>
      </div>
    </PageTracker>
  );
};
