import React from "react";

import { Avatar, NotificationDot } from "@arbolus-technologies/ui/components";

import styles from "./ChatCardRow.module.scss";

interface ChatCardRowProps {
  id: string;
  name: string;
  hasUnreadMessages: boolean;
  imageUrl: string;
  handleClick: (
    id: string,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
}

export const ChatCardRow: React.FC<ChatCardRowProps> = ({
  id,
  name,
  hasUnreadMessages,
  imageUrl,
  handleClick
}) => (
  <div className={styles.container} onClick={(e): void => handleClick(id, e)}>
    <div className={styles.avatarAndTitleContainer}>
      <Avatar
        avatar={{
          name,
          imageUrl
        }}
        type="smallXSCircle"
      />
      <div className={styles.title}>{name}</div>
    </div>
    {hasUnreadMessages && <NotificationDot offsetRight="0" size="10px" />}
  </div>
);
