import clsx from "clsx";
import React from "react";
import { Control, Controller } from "react-hook-form";
import { Form, FormGroup, Label } from "reactstrap";

import styles from "./RadioButtonsController.module.scss";

interface RadioButtonsControllerProps {
  control: Control<any>;
  name: string;
  onConfirmText: string;
  onDenyText: string;
  onConfirm?: () => void;
}

export const RadioButtonsController: React.FC<RadioButtonsControllerProps> = ({
  control,
  name,
  onConfirmText,
  onDenyText,
  onConfirm
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value } }) => (
      <Form inline>
        <FormGroup className={clsx("custom-checkbox", styles.radio)}>
          <Label>
            {onConfirmText}
            <input
              onChange={() => {
                onChange(true);
                onConfirm && onConfirm();
              }}
              type="radio"
              checked={value}
              className="form-check-input"
            />
            <span className="checkmark" />
          </Label>
        </FormGroup>
        <FormGroup className={clsx("custom-checkbox", styles.radio)}>
          <Label>
            {onDenyText}
            <input
              onChange={() => {
                onChange(false);
              }}
              type="radio"
              checked={!value}
              className="form-check-input"
            />
            <span className="checkmark" />
          </Label>
        </FormGroup>
      </Form>
    )}
  />
);
