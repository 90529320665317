import React from "react";

import { FormValue, Permission } from "@arbolus-technologies/models/common";

import { CheckboxSimpleForm } from "./CheckboxSimpleForm";

export interface PermissionsProps {
  allowUpdate: boolean;
  permissions: Permission[];
  onSave: (permissions: Permission[]) => void;
  onCancel?: () => void;
}

export const PermissionsForm: React.FC<PermissionsProps> = ({
  allowUpdate,
  permissions,
  onSave,
  onCancel
}) => {
  const onSubmit = (formValues: FormValue[]) => {
    const permissions = formValues.map(
      (formValue): Permission => ({
        claimValue: formValue.label,
        active: formValue.active
      })
    );
    onSave(permissions);
  };

  return (
    <CheckboxSimpleForm
      allowUpdate={allowUpdate}
      name="permissions"
      formValues={permissions.map(
        (permission): FormValue => ({
          label: permission.claimValue,
          active: permission.active
        })
      )}
      onSave={onSubmit}
      onCancel={onCancel}
    />
  );
};
