import React from "react";
import { useTranslation } from "react-i18next";

import summaryExample from "../../../../assets/summaryExample.png";

import styles from "./EmptySummary.module.scss";

export const EmptySummary: React.FC = () => {
  const { t } = useTranslation("canopySummary");
  return (
    <div className={styles.containerImage}>
      <div className={styles.titleExample}>{t("exampleOfTheSummary")}</div>
      <img
        src={summaryExample}
        className={styles.exampleImage}
        alt="summary-example"
      />
    </div>
  );
};
