import {
  BOOKMARK_COLLECTION_ORDER_BY,
  ListBookmarkCollectionsQueryParams,
  SORT_DIRECTION
} from "@arbolus-technologies/api";
import { BookmarkCollection } from "@arbolus-technologies/models/common";

export interface QueryParamsInterface {
  limit: number;
  offset: number;
  count: number;
  orderBy: BOOKMARK_COLLECTION_ORDER_BY;
  orderDirection: SORT_DIRECTION;
  referenceId: string;
}

export enum BookmarkActions {
  UPDATE_REDUCER = "updateReducer",
  RESET_REDUCER = "resetReducer"
}

export interface BookmarkAction {
  type: BookmarkActions;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

export interface BookmarkState {
  collectionList: BookmarkCollection[] | null;
  queryParams: ListBookmarkCollectionsQueryParams;
  isProcessingClick: boolean;
}

export const reducer = (
  state: BookmarkState,
  action: BookmarkAction
): BookmarkState => {
  const { type } = action;
  switch (type) {
    case BookmarkActions.UPDATE_REDUCER:
      return { ...state, ...action.payload };
    case BookmarkActions.RESET_REDUCER:
      return initialState;
    default:
      return state;
  }
};

const initialReducerQueryParams: QueryParamsInterface = {
  limit: 10,
  offset: 0,
  count: 10,
  orderBy: BOOKMARK_COLLECTION_ORDER_BY.CREATED,
  orderDirection: SORT_DIRECTION.DESCENDING,
  referenceId: ""
};

export const initialState: BookmarkState = {
  collectionList: null,
  queryParams: initialReducerQueryParams,
  isProcessingClick: false
};
