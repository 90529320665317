import {
  FilterCriteria,
  SORT_DIRECTION,
  SortCriteria
} from "@arbolus-technologies/api";

export const PROJECT_STATES = {
  ACTIVE: "Active",
  DRAFT: "Draft",
  ARCHIVE: "Archive"
};

export const PROJECT_FILTER_CRITERIA = {
  ALL: { name: "showAllProjects", value: "" } as FilterCriteria,
  ACTIVE: { name: "showActiveProjects", value: "Active" } as FilterCriteria,
  ARCHIVE: { name: "showArchivedProjects", value: "Archive" } as FilterCriteria
};

export const PROJECT_SORT_CRITERIA = {
  NAME: {
    name: "sortByName",
    value: "name",
    direction: SORT_DIRECTION.ASCENDING
  } as SortCriteria,
  DATE: {
    name: "sortByDate",
    value: "created",
    direction: SORT_DIRECTION.DESCENDING
  } as SortCriteria
};

export enum COMPLIANCE_QUESTION_IMPORTANCE {
  HIGH = "High",
  LOW = "Low"
}

export enum COMPLIANCE_STATUS {
  PASSED = "Passed",
  FAILED = "Failed"
}

export enum FIRST_CALL_STATUS {
  YES = "Yes",
  NO = "No"
}
