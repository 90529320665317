const COMPANIES_ENDPOINT = `/companies`;

export const COMPANIES_API = {
  GET_COMPANIES: (): string => `${COMPANIES_ENDPOINT}`,
  GET_COMPANY: (companyId: string): string =>
    `${COMPANIES_ENDPOINT}/${companyId}`,
  ADD_COMPANY: (): string => `${COMPANIES_ENDPOINT}`,
  UPDATE_COMPANY: (companyId: string): string =>
    `${COMPANIES_ENDPOINT}/${companyId}`,
  MERGE_COMPANIES: (): string => `${COMPANIES_ENDPOINT}/merge`,
  GROUP_COMPANIES: (): string => `${COMPANIES_ENDPOINT}/group`,
  UNGROUP_COMPANY: (companyId: string): string =>
    `${COMPANIES_ENDPOINT}/${companyId}/ungroup`,
  PATCH_COMPANY: (companyId: string): string =>
    `${COMPANIES_ENDPOINT}/${companyId}`
};
