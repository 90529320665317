import { Button } from "arbolus-ui-components";
import { saveAs } from "file-saver";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  DefaultToasterService,
  ProjectService,
  ToasterService
} from "@arbolus-technologies/api";
import { Loader } from "@arbolus-technologies/ui/components";
import { string64toBlob } from "@arbolus-technologies/utils";

const blobTypeExtension =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

interface ExportExpertsButtonProps {
  projectId: string;
  projectService?: typeof ProjectService;
  notificationService?: ToasterService;
}

export const ExportExpertsButton: React.FC<ExportExpertsButtonProps> = ({
  projectId,
  projectService = ProjectService,
  notificationService = DefaultToasterService
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation("expertsList");

  const handleExportExperts = () => {
    setIsLoading(true);

    projectService.exportExperts(projectId).subscribe({
      next: (response) => {
        const blob = string64toBlob(response.file, blobTypeExtension);
        saveAs(blob, response.name);
        setIsLoading(false);
        notificationService.showSuccess(t("downloadSuccess"));
      },
      error: (error) => {
        setIsLoading(false);
        notificationService.showError(error.message);
      }
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Button
      onClick={handleExportExperts}
      text={t("exportTitleButton")}
      type="secondary"
    />
  );
};
