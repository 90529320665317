import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CIQError,
  ClientService,
  ErrorResponse,
  ListProjectBase,
  PROJECT_STATUS,
  ToasterService,
  User
} from "@arbolus-technologies/api";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { Loader, Tabs, UserHeader } from "@arbolus-technologies/ui/components";

import { ClientMemberPermissionsTab } from "./TabsContent/ClientMemberPermissionsTab/ClientMemberPermissionsTab";
import { Projects } from "./TabsContent/Projects";

const itemIconColor = ARBOLUS_COLORS.bColorGrayIcon;

interface TeamMemberSlidePanelProps {
  user: User;
  clientId: string;
  goToClientEditPage: () => void;
  handleAssignProject: () => void;
  handleCloseTeamMemberClientSlidePanel: () => void;
}

const notificationService = new ToasterService();

export const TeamMemberContentClient: React.FC<TeamMemberSlidePanelProps> = ({
  user,
  clientId,
  handleAssignProject,
  handleCloseTeamMemberClientSlidePanel,
  goToClientEditPage
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeProjects, setActiveProjects] = useState<ListProjectBase[]>([]);
  const [archiveProjects, setArchiveProjects] = useState<ListProjectBase[]>([]);
  const { t } = useTranslation("teamMemberSlidePanel");

  const getProjectsList = useCallback(() => {
    ClientService.getTeamMembersProjects(clientId, user.id).subscribe({
      next: (response) => {
        const activeProjects = response.items.filter(
          (projects) => projects.projectState === PROJECT_STATUS.ACTIVE
        );
        const archiveProjects = response.items.filter(
          (projects) => projects.projectState === PROJECT_STATUS.ARCHIVE
        );

        setActiveProjects(activeProjects);
        setArchiveProjects(archiveProjects);
        setIsLoading(false);
      },
      error: (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
        setIsLoading(false);
      }
    });
  }, [clientId, user.id]);

  useEffect(() => {
    getProjectsList();
  }, [getProjectsList]);

  const threeDotsItems = [
    {
      title: t("editProfile"),
      icon: "edit",
      iconColor: itemIconColor,
      color: "",
      onClick: goToClientEditPage
    },
    {
      title: t("addToProject"),
      icon: "add",
      iconColor: itemIconColor,
      color: "",
      onClick: handleAssignProject
    }
  ];

  const tabs = useMemo(
    () => ({
      projects: {
        title: t("projects"),
        component: () => (
          <Projects
            activeProjects={activeProjects}
            archiveProjects={archiveProjects}
          />
        )
      },
      permissions: {
        title: t("permissions"),
        component: () => (
          <ClientMemberPermissionsTab
            user={user}
            onUserDecision={handleCloseTeamMemberClientSlidePanel}
          />
        )
      }
    }),
    [
      t,
      activeProjects,
      archiveProjects,
      user,
      handleCloseTeamMemberClientSlidePanel
    ]
  );

  if (isLoading) return <Loader />;

  return (
    <>
      <UserHeader user={user} threeDotsItems={threeDotsItems} />
      <Tabs tabMapping={tabs} />
    </>
  );
};
