import { ICellRendererParams } from "ag-grid-community";
import { Icon } from "arbolus-ui-components";
import React from "react";

import { ProjectModel } from "@arbolus-technologies/models/common";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./InProjectCellRenderer.module.scss";

type ClientRendererProps = ICellRendererParams<ProjectModel, never, never>;

export const InProjectCellRenderer: React.FC<ClientRendererProps> = ({
  data
}) => {
  const [name, color] = data?.inProject
    ? ["check", ARBOLUS_COLORS.bColorSecondaryGreen]
    : ["close", ARBOLUS_COLORS.bColorSecondaryGreyDark];

  return (
    <div className={styles.container}>
      <Icon name={name} color={color} fontSize="24px" />
    </div>
  );
};
