import React from "react";
import { useTranslation } from "react-i18next";

import { RateCard } from "@arbolus-technologies/api";
import { EXPERT_EXPERIENCE } from "@arbolus-technologies/models/expert";
import {
  ExpertLinkedin,
  IconWithText
} from "@arbolus-technologies/ui/components";
import { convertValueToCurrencyFormat } from "@arbolus-technologies/utils";

import styles from "./ContactCard.module.scss";

export interface ContactCardProps {
  contactDetails: {
    email: string;
    phoneNumber?: string;
    linkedinProfile?: string;
    experienceLevel?: string;
    rateCards?: RateCard[];
    preferredContactMethod: string;
    userId: string;
  };
  isAdmin: boolean;
}

export const ContactCard: React.FC<ContactCardProps> = ({
  contactDetails,
  isAdmin = false
}) => {
  const { t } = useTranslation("contactCard");

  const {
    email,
    phoneNumber,
    linkedinProfile,
    experienceLevel = "",
    rateCards,
    preferredContactMethod
  } = contactDetails;

  const getRateCard = () =>
    rateCards?.map((expertPackage) => {
      const { id, price, isoCurrencyCode } = expertPackage;
      const chargeInfo = t("perHour", {
        fee: convertValueToCurrencyFormat(price, isoCurrencyCode)
      });

      return <div key={id}>{chargeInfo}</div>;
    });

  return (
    <div className={styles.contactCardContainer}>
      {isAdmin && (
        <>
          <div className={styles.topContainer}>
            <IconWithText
              isLink={{
                textLink: email,
                urlLink: `mailto:${email}`
              }}
              icon={{ name: "email" }}
            />
            {phoneNumber && (
              <IconWithText
                isLink={{
                  textLink: phoneNumber,
                  urlLink: `tel:${phoneNumber}`
                }}
                icon={{ name: "call" }}
              />
            )}
            <ExpertLinkedin linkedInUrl={linkedinProfile} />
            {preferredContactMethod && (
              <div className={styles.row}>
                <div className={styles.title}>
                  {t("preferredContactMethod")}
                </div>
                <div>{preferredContactMethod}</div>
              </div>
            )}
          </div>
          <hr />
        </>
      )}
      <div className={styles.bottomContainer}>
        <div className={styles.row}>
          <div className={styles.title}>{t("seniority")}</div>
          <div>
            {EXPERT_EXPERIENCE.get(experienceLevel)?.label ??
              EXPERT_EXPERIENCE.get("CSuiteLevel")?.label}
          </div>
        </div>
        {isAdmin && rateCards && rateCards.length > 0 && (
          <div className={styles.row}>
            <div className={styles.title}>{t("rateCard")}</div>
            {getRateCard()}
          </div>
        )}
      </div>
    </div>
  );
};
