import i18next from "i18next";
import * as Yup from "yup";

import {
  ANSWER_PREFERENCES_TYPE,
  CreateEditQuestionFields,
  FormConstraints
} from "@arbolus-technologies/models/canopy";

import { CanopyBaseSchema } from "./CanopyBaseSchema";

const form = "canopyBuilder";

export const CanopyMultiChoiceSchema = CanopyBaseSchema.concat(
  Yup.object().shape(
    {
      [CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE]:
        Yup.mixed<ANSWER_PREFERENCES_TYPE>()
          .oneOf(Object.values(ANSWER_PREFERENCES_TYPE))
          .required(i18next.t(`${form}:answerPreferencesTypeRequired`)),
      [CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_EXACT]:
        Yup.number().when([CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE], {
          is: (preferenceType: ANSWER_PREFERENCES_TYPE) =>
            preferenceType === ANSWER_PREFERENCES_TYPE.EXACT,
          then: Yup.number()
            .typeError(i18next.t(`${form}:choicesNumberType`))
            .required(i18next.t(`${form}:choicesNumberRequired`))
            .min(
              FormConstraints.MIN_ANSWER_CHOICES,
              i18next.t(`${form}:choicesNumberMin`, {
                minValue: FormConstraints.MIN_ANSWER_CHOICES
              })
            )
            .max(
              FormConstraints.MAX_ANSWER_CHOICES,
              i18next.t(`${form}:choicesNumberMax`, {
                maxValue: FormConstraints.MAX_ANSWER_CHOICES
              })
            )
        }),
      [CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_INITIAL_RANGE]:
        Yup.number().when(
          [
            CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE,
            CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_FINAL_RANGE
          ],
          (
            preferenceType: ANSWER_PREFERENCES_TYPE,
            finalRangeNumber: number,
            schema: Yup.NumberSchema<number>
          ) => {
            const isCorrectType =
              preferenceType === ANSWER_PREFERENCES_TYPE.RANGE;

            if (isCorrectType) {
              return schema
                .typeError(i18next.t(`${form}:choicesNumberType`))
                .required(i18next.t(`${form}:choicesNumberRequired`))
                .min(
                  FormConstraints.MIN_ANSWER_CHOICES,
                  i18next.t(`${form}:choicesNumberMin`, {
                    minValue: FormConstraints.MIN_ANSWER_CHOICES
                  })
                )
                .max(
                  FormConstraints.MAX_ANSWER_CHOICES - 1,
                  i18next.t(`${form}:choicesNumberMax`, {
                    maxValue: FormConstraints.MAX_ANSWER_CHOICES - 1
                  })
                )
                .test(
                  "min-check",
                  i18next.t(`${form}:fromGreaterThanToError`),
                  (initialRangeNumber) => {
                    if (initialRangeNumber && finalRangeNumber) {
                      return initialRangeNumber < finalRangeNumber;
                    }
                    return true;
                  }
                );
            } else {
              return schema.nullable();
            }
          }
        ),
      [CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_FINAL_RANGE]:
        Yup.number().when(
          [
            CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE,
            CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_INITIAL_RANGE
          ],
          (
            preferenceType: ANSWER_PREFERENCES_TYPE,
            initialRangeNumber: number,
            schema: Yup.NumberSchema<number>
          ) => {
            const isCorrectType =
              preferenceType === ANSWER_PREFERENCES_TYPE.RANGE;

            if (isCorrectType) {
              return schema
                .typeError(i18next.t(`${form}:choicesNumberType`))
                .required(i18next.t(`${form}:choicesNumberRequired`))
                .min(
                  FormConstraints.MIN_ANSWER_CHOICES + 1,
                  i18next.t(`${form}:choicesNumberMin`, {
                    minValue: FormConstraints.MIN_ANSWER_CHOICES + 1
                  })
                )
                .max(
                  FormConstraints.MAX_ANSWER_CHOICES,
                  i18next.t(`${form}:choicesNumberMax`, {
                    maxValue: FormConstraints.MAX_ANSWER_CHOICES
                  })
                )
                .test(
                  "min-check",
                  i18next.t(`${form}:toSmallerThanFromError`),
                  (finalRangeNumber) => {
                    if (initialRangeNumber && finalRangeNumber) {
                      return initialRangeNumber < finalRangeNumber;
                    }
                    return true;
                  }
                );
            } else {
              return schema.nullable();
            }
          }
        ),
      [CreateEditQuestionFields.ANSWER_CHOICES]: Yup.array()
        .of(
          Yup.object({
            text: Yup.string()
              .required(i18next.t(`${form}:choiceRequired`))
              .max(
                FormConstraints.MAX_CHOICE_LENGTH,
                i18next.t(`${form}:choiceMaxLength`, {
                  length: FormConstraints.MAX_CHOICE_LENGTH
                })
              ),
            isOther: Yup.boolean(),
            sortOrder: Yup.number()
          })
        )
        .max(FormConstraints.MAX_CHOICES)
        .required()
    },
    [
      [
        CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_FINAL_RANGE,
        CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_INITIAL_RANGE
      ]
    ]
  )
);
