import { Button, Icon } from "arbolus-ui-components";
import clsx from "clsx";
import { Field, Form, Formik, FormikProps } from "formik";
import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { connect } from "react-redux";
import { RouteComponentProps, StaticContext } from "react-router";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Dispatch } from "redux";
import { createStructuredSelector } from "reselect";
import { Observable, Subscription, of, zip } from "rxjs";
import { switchMap } from "rxjs/operators";
import SimpleBar from "simplebar-react";

import { ToasterService } from "@arbolus-technologies/api";
import {
  CitySelector,
  CustomAvatarSelect
} from "@arbolus-technologies/features/common";
import { SelectOption } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { CustomSelect, Loader } from "@arbolus-technologies/ui/components";

import { USERS_API } from "../../../../constants/api";
import { USERS } from "../../../../constants/navigation/authRoutes";
import { PROJECT_MEMBER_PANEL_ROUTE } from "../../../../constants/navigation/panelRoutes";
import { PROJECT_NEW_EVENT_ROUTE } from "../../../../constants/navigation/projectRoutes";
import { UI_WINDOW_HEIGHT } from "../../../../constants/ui";
import {
  ApiCreatedResponse,
  CIQError,
  ErrorResponse
} from "../../../../models/api";
import {
  ClientMember,
  CreateUserRequest,
  LoggedInUser,
  UpdateUserRequest
} from "../../../../models/user";
import {
  ClientService,
  DocumentService,
  ProjectService
} from "../../../../services";
import { AppAction } from "../../../../store/actions";
import { AppState } from "../../../../store/reducers";
import { CIQFormInput, CIQSelect } from "../../../app/components";
import { AppSelector, AppStoreActions } from "../../../app/store";
import { AuthSelector } from "../../../auth/store";
import {
  NewUserCiqValidationSchema,
  NewUserValidationSchema
} from "./NewUserSchema";

import arbolusLogo from "../../../../assets/images/logos/logo-tree-blue.png";

const notification = new ToasterService();

interface NewUserPageStoreProps {
  clientName?: string;
  clientId?: string;
  timezones: SelectOption[];
  currentUserId?: string;
  industries: SelectOption[];
  countriesOptions: SelectOption[];
  subdomain?: string;
}

interface NewUserPageProps
  extends NewUserPageStoreProps,
    RouteComponentProps<
      { userId: string },
      StaticContext,
      {
        projectId?: string;
        email?: string;
        eventPageUuid?: string;
      }
    > {
  updateUserProfile: (user: LoggedInUser) => void;
}
interface NewUserPageState {
  isSaveLoading: boolean;
  isLoading: boolean;
}

type NewUserPageIntersectProps = NewUserPageProps & WithTranslation;

interface NewUserPageFormValues {
  profileImage?: File;
  firstName?: string;
  lastName?: string;
  title: string;
  phoneNumber?: string;
  email: string;
  timezone: string;
  isRealNameEnabled: boolean;
  displayName: string;
  industryId: string;
  country: SelectOption;
  city: {
    id: string;
    name: string;
  };
}

class NewUserPage extends Component<
  NewUserPageIntersectProps,
  NewUserPageState
> {
  constructor(props: NewUserPageIntersectProps) {
    super(props);
    this.state = {
      isSaveLoading: false,
      isLoading: false
    };

    const {
      match: {
        params: { userId }
      },
      location: { state }
    } = props;
    this.clientUserId = userId;

    if (state && state.email) {
      this.teamUser = { ...this.teamUser, email: state.email };
    }
  }

  componentDidMount(): void {
    const { t } = this.props;

    document.title = this.clientUserId ? t("updateUser") : t("newUser");
    if (this.clientUserId) {
      this.fetchUser();
    }
  }

  componentWillUnmount(): void {
    this.createUserSubscription?.unsubscribe();
    this.updateUserSubscription?.unsubscribe();
    this.getUserSubscription?.unsubscribe();
  }

  private teamUser: ClientMember = {
    firstName: "",
    lastName: "",
    email: "",
    emailConfirmed: false,
    id: "",
    isoCountryCode: "",
    profileImageUrl: "",
    title: "",
    phoneNumber: undefined,
    timezone: "",
    clientUserProfile: {
      displayName: "",
      isRealNameEnabled: true,
      industryId: ""
    },
    country: {
      id: "",
      name: "",
      city: {
        id: "",
        name: ""
      }
    }
  };

  private createUserSubscription?: Subscription;

  private updateUserSubscription?: Subscription;

  private getUserSubscription?: Subscription;

  private clientUserId: string | undefined;

  fetchUser = (): void => {
    const { clientId, t } = this.props;
    this.setState({ isLoading: true });
    this.getUserSubscription = ClientService.getUser(
      clientId!,
      this.clientUserId!
    ).subscribe(
      (res: ClientMember) => {
        const clientUserProfile = {
          ...res.clientUserProfile,
          displayName: res?.clientUserProfile?.displayName || "",
          isRealNameEnabled: res?.clientUserProfile?.isRealNameEnabled || true
        };

        this.teamUser = {
          ...res,
          phoneNumber: res.phoneNumber?.split("+")[1],
          ...clientUserProfile
        };

        this.setState({
          isLoading: false
        });
      },
      ({ message }: ErrorResponse<CIQError>) => {
        notification.showError(message || t("unableToLoad"));
        this.handleNavigateBack();
      }
    );
  };

  createNewUser = (
    newUserDetails: CreateUserRequest,
    profileImage?: File
  ): void => {
    const {
      clientId,
      location: { state }
    } = this.props;

    if (clientId) {
      this.createUserSubscription = ClientService.createUser(
        clientId,
        newUserDetails
      )
        .pipe(
          switchMap(({ id }: ApiCreatedResponse) => {
            this.clientUserId = id;

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const userServices: Observable<any>[] = [];
            if (profileImage && this.clientUserId) {
              const formData = new FormData();
              formData.append("file", profileImage);

              userServices.push(
                DocumentService.uploadFile(
                  USERS_API.ADD_USER_PROFILE_IMAGE(this.clientUserId),
                  formData
                )
              );
            }

            if (state?.projectId) {
              userServices.push(
                ProjectService.addProjectMember(
                  state.projectId,
                  this.clientUserId
                )
              );
            }

            if (userServices.length === 0) {
              userServices.push(of(null));
            }

            return zip(...userServices);
          })
        )
        .subscribe(
          () => {
            this.setState({
              isSaveLoading: false
            });
            this.handleNavigateBack();
          },
          (error: ErrorResponse<CIQError>) => {
            notification.showError(error.message);
            this.setState({
              isSaveLoading: false
            });
          }
        );
    }
  };

  updateUser = (
    updatedUserDetails: UpdateUserRequest,
    profileImage?: File
  ): void => {
    const { clientId, updateUserProfile, currentUserId } = this.props;
    const { id } = this.teamUser;

    this.updateUserSubscription = ClientService.updateUser(
      clientId!,
      this.clientUserId!,
      updatedUserDetails
    )
      .pipe(
        switchMap(() => {
          const formData = new FormData();
          if (profileImage && this.clientUserId) {
            formData.append("file", profileImage);
            return DocumentService.uploadFile(
              USERS_API.ADD_USER_PROFILE_IMAGE(this.clientUserId),
              formData
            );
          }
          return of(null);
        })
      )
      .subscribe(
        () => {
          this.setState({
            isSaveLoading: false
          });

          // Update logged in user
          if (currentUserId === id) {
            updateUserProfile(updatedUserDetails as LoggedInUser);
          }
          this.handleNavigateBack();
        },
        (error: ErrorResponse<CIQError>) => {
          notification.showError(error.message);
          this.setState({
            isSaveLoading: false
          });
        }
      );
  };

  handleNavigateToUsers = (): void => {
    const { history } = this.props;
    history.push(USERS);
  };

  handleNavigateBack = (): void => {
    const {
      history,
      location: { state }
    } = this.props;

    if (state?.projectId && state?.eventPageUuid) {
      // User was created via invite thru event page - redirect back
      history.replace(PROJECT_NEW_EVENT_ROUTE(state.projectId), {
        eventPageUuid: state.eventPageUuid
      });
    } else if (state?.projectId) {
      history.replace(PROJECT_MEMBER_PANEL_ROUTE(state.projectId));
    } else {
      history.replace(USERS);
    }
  };

  handleFormSubmit = (values: NewUserPageFormValues): void => {
    this.setState({ isSaveLoading: true });

    const userDetails = {
      firstName: values.firstName!,
      lastName: values.lastName!,
      phoneNumber: values.phoneNumber ? `+${values.phoneNumber}` : "",
      title: values.title,
      timezone: values.timezone,
      clientUserProfile: {
        isRealNameEnabled: values.isRealNameEnabled,
        displayName: values.isRealNameEnabled ? null : values.displayName,
        industryId: values.industryId
      },
      countryId: values.country?.value || "",
      cityId: values.city?.id || ""
    };

    if (this.clientUserId) {
      this.updateUser(userDetails, values.profileImage);
    } else {
      this.createNewUser(
        { ...userDetails, email: values.email.toLowerCase() },
        values.profileImage
      );
    }
  };

  renderForm = ({
    values,
    errors,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    touched,
    isValid,
    dirty,
    handleSubmit
  }: FormikProps<NewUserPageFormValues>): JSX.Element => {
    const { isSaveLoading } = this.state;
    const { t, timezones, industries, subdomain, countriesOptions } =
      this.props;
    const {
      phoneNumber,
      timezone,
      isRealNameEnabled,
      industryId,
      country,
      city
    } = values;

    const isCiqUser = subdomain?.includes("ciq");

    const handlePhoneChange = (value: string): void => {
      setFieldTouched("phoneNumber", true);
      setFieldValue("phoneNumber", value);
    };

    const handleCountryChange = (value: string) => {
      const item = countriesOptions.find((item) => item.value === value);
      setFieldValue("city", "");
      handleCountryChangeSecondStep(item);
    };

    const handleCountryChangeSecondStep = (item?: SelectOption) => {
      setFieldValue("country", { value: item?.value, label: item?.label });
    };

    const handleCityChange = (value: string): void => {
      setFieldTouched("city", true);
      setFieldValue("city", value);
    };

    const handleTimeZoneChange = (value: string): void => {
      setFieldValue("timezone", value);
    };

    const handleIndustryChange = (value: string): void => {
      setFieldValue("industryId", value);
    };

    const handlePhotoUpload = (userImage: File): void => {
      setFieldValue("profileImage", userImage);
    };

    return (
      <Form>
        <Row className="img-upload-row">
          <Col className="d-none d-md-flex" />
          <Col className="img-upload-col">
            <CustomAvatarSelect
              isLoading={isSaveLoading}
              onImageSelected={handlePhotoUpload}
              profileImageUrl={this.teamUser.profileImageUrl}
            />
          </Col>

          <Col className="d-none d-md-flex">
            <Label className="info-label">Min. 100px X 100px</Label>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <FormGroup
              className={clsx({
                "is-invalid": touched.firstName && errors.firstName
              })}
            >
              <Label>{t("firstName")}</Label>
              <Field
                autoComplete="off"
                name="firstName"
                placeholder={t("firstName")}
                type="text"
                component={CIQFormInput}
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup
              className={clsx({
                "is-invalid": touched.lastName && errors.lastName
              })}
            >
              <Label>{t("lastName")}</Label>
              <Field
                autoComplete="off"
                name="lastName"
                placeholder={t("lastName")}
                type="text"
                component={CIQFormInput}
              />
            </FormGroup>
          </Col>
        </Row>
        {!isCiqUser && (
          <Row className="displayNameCheckBoxRow">
            <Col xs={12} md={6} className="displayNameColumn">
              <FormGroup
                className={clsx(
                  {
                    "is-invalid":
                      touched.isRealNameEnabled && errors.isRealNameEnabled
                  },
                  "displayNameCheckbox"
                )}
                check
                inline
              >
                <Input
                  type="checkbox"
                  name="isRealNameEnabled"
                  checked={isRealNameEnabled}
                  onChange={() =>
                    setFieldValue("isRealNameEnabled", !isRealNameEnabled)
                  }
                />
                <Label check className="displayNameCheckLabel">
                  {t("displayNameCheckLabel")}
                </Label>
                <span
                  className="displayNameCheckboxIcon"
                  title={t("displayNameTooltip")}
                >
                  <Icon
                    name="info"
                    fontSize="16px"
                    color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
                  />
                </span>
              </FormGroup>
            </Col>
          </Row>
        )}
        {!isRealNameEnabled && (
          <Row className="displayNameCheckBoxRow">
            <Col xs={12} md={6}>
              <FormGroup
                className={clsx({
                  "is-invalid": touched.displayName && errors.displayName
                })}
              >
                <Label>{t("displayNameLabel")}</Label>
                <Field
                  autoComplete="off"
                  name="displayName"
                  type="text"
                  component={CIQFormInput}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12} md={6}>
            <FormGroup
              className={clsx({
                "is-invalid": touched.country && errors.country
              })}
            >
              <Label className="space-between">{t("country")}</Label>
              <CIQSelect
                options={countriesOptions}
                defaultValue={countriesOptions.find(
                  (countryOption) => countryOption.value === country.value
                )}
                onSelectChange={handleCountryChange}
                noOptionsMessage={""}
                placeholder={t("notSetPlaceholder")}
                customStyle
                isSearchable
              />
              {errors.country?.value && errors.country?.label && (
                <Label className="invalid-feedback">
                  {errors.country.value}
                </Label>
              )}
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup>
              <Label className="space-between">
                {t("city")}
                <span>{t("optional")}</span>
              </Label>
              <CitySelector
                value={city?.id ? [city] : []}
                countryId={country?.value}
                name="city"
                onChange={handleCityChange}
                onBlur={() => handleBlur("city")}
                orderBy="firstName"
                promptText={t("selectPromptTextCity")}
                searchText={t("selectSearchTextCity")}
                paginationText={t("selectPaginationText")}
                placeholderText={
                  country?.value ? t("selectPlaceholderTextCity") : ""
                }
                disabled={!country?.value}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <FormGroup
              className={clsx({
                "is-invalid": touched.title && errors.title
              })}
            >
              <Label>{t("jobTitle")}</Label>
              <Field
                autoComplete="off"
                name="title"
                placeholder={t("jobTitle")}
                type="text"
                component={CIQFormInput}
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup
              className={clsx({
                "is-invalid": touched.industryId && errors.industryId
              })}
            >
              <Label className="space-between">
                {t("industryFocus")}
                <span>{t("optional")}</span>
              </Label>
              <CustomSelect
                options={industries}
                defaultValue={industries.find(
                  (industry) => industry.value === industryId
                )}
                onSelectChange={handleIndustryChange}
                noOptionsMessage={t("noIndustriesOptions")}
                displayIcon
                isSearchable
                placeholder={t("notSetPlaceholder")}
                classNamePrefix="ciqs-select"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <FormGroup
              className={clsx({
                "is-invalid": touched.timezone && errors.timezone
              })}
            >
              <Label className="space-between">
                {t("timezone")}
                <span>{t("optional")}</span>
              </Label>
              <CIQSelect
                options={timezones}
                defaultValue={timezones.find((tz) => tz.value === timezone)}
                onSelectChange={handleTimeZoneChange}
                noOptionsMessage={t("noTimezoneOptions")}
                placeholder={t("notSetPlaceholder")}
                customStyle
                isSearchable
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <FormGroup
              className={clsx({
                "is-invalid": touched.email && errors.email
              })}
            >
              <Label>{t("email")}</Label>
              <Field
                className="email-input"
                disabled={this.clientUserId}
                autoComplete="off"
                name="email"
                placeholder={t("email")}
                component={CIQFormInput}
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup
              className={clsx({
                "is-invalid": errors.phoneNumber
              })}
            >
              <Label className="space-between">
                {t("contactNumber")}
                <span>{t("optional")}</span>
              </Label>

              <PhoneInput
                inputProps={{
                  name: "phoneNumber"
                }}
                placeholder={t("contactNumberPlaceholder")}
                enableSearch
                country="us"
                value={phoneNumber || ""}
                onBlur={handleBlur("phoneNumber")}
                onChange={handlePhoneChange}
                disableSearchIcon
                prefix=""
              />
              {errors.phoneNumber && touched.phoneNumber && (
                <Label className="invalid-feedback">{errors.phoneNumber}</Label>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="btn-container">
              <Button
                disabled={isSaveLoading}
                text={t("save")}
                onClick={handleSubmit}
              />
            </div>
          </Col>
        </Row>
      </Form>
    );
  };

  render(): JSX.Element {
    const { isLoading, isSaveLoading } = this.state;
    const { clientName, t, subdomain } = this.props;

    const {
      email,
      title,
      firstName,
      lastName,
      phoneNumber,
      timezone,
      clientUserProfile,
      country
    } = this.teamUser;

    const isCiqUser = subdomain?.includes("ciq");

    return isLoading ? (
      <Loader isFull />
    ) : (
      <SimpleBar
        className="simplebar-light"
        style={{ height: UI_WINDOW_HEIGHT }}
      >
        <div className="new-user-container ">
          <div className="full-page-logo">
            <img src={arbolusLogo} height="30px" width="30px" alt="logo" />
          </div>
          <Row className="nu-header">
            <Col className="pagination-content">
              <span onClick={this.handleNavigateToUsers}>{clientName}</span>
            </Col>
            <Col>
              <Button
                disabled={isSaveLoading}
                type="tertiary"
                onClick={(): void => this.handleNavigateBack()}
                text={t("cancel")}
              />
            </Col>
          </Row>

          <Row className="nu-body">
            <h1>{this.clientUserId ? t("updateUser") : t("newUser")}</h1>
            <Formik<NewUserPageFormValues>
              initialValues={{
                email,
                title: title || "",
                firstName: firstName || "",
                lastName: lastName || "",
                phoneNumber: phoneNumber || "",
                profileImage: undefined,
                timezone,
                isRealNameEnabled: clientUserProfile?.isRealNameEnabled ?? true,
                displayName: clientUserProfile?.displayName || "",
                industryId: clientUserProfile?.industryId || "",
                country: {
                  value: country?.id ?? "",
                  label: country?.name ?? ""
                },
                city: country?.city ?? ""
              }}
              validationSchema={
                isCiqUser ? NewUserCiqValidationSchema : NewUserValidationSchema
              }
              onSubmit={this.handleFormSubmit}
            >
              {this.renderForm}
            </Formik>
          </Row>
        </div>
      </SimpleBar>
    );
  }
}

const mapStateToProps = createStructuredSelector<
  AppState,
  NewUserPageProps,
  NewUserPageStoreProps
>({
  clientName: AuthSelector.authClientNameSelector(),
  clientId: AuthSelector.authClientIdSelector(),
  timezones: AppSelector.appTimeZoneSelector(),
  currentUserId: AuthSelector.authUserIdSelector(),
  industries: AppSelector.appIndustriesDropdownSelector(0),
  countriesOptions: CacheSelector.countriesOptions(),
  subdomain: AuthSelector.authClientSubDomainSelector()
});

const mapDispatchToProps = (dispatch: Dispatch): Record<string, AppAction> => ({
  updateUserProfile: (user: LoggedInUser): AppAction =>
    dispatch(AppStoreActions.updateUserProfile(user))
});

export default withTranslation("newUserPage")(
  connect(mapStateToProps, mapDispatchToProps)(NewUserPage)
);
