import React from "react";
import { useSelector } from "react-redux";

import {
  DefaultToasterService,
  ExpertService,
  ExpertTrainingModel,
  ToasterService
} from "@arbolus-technologies/api";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ExpertTrainingModal } from "@arbolus-technologies/ui/components";

interface ExpertTrainingProps {
  expertService?: typeof ExpertService;
  notificationService?: ToasterService;
}

export const ExpertTraining: React.FC<ExpertTrainingProps> = ({
  expertService = ExpertService,
  notificationService = DefaultToasterService
}) => {
  const [showTraining, setShowTraining] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [training, setTraining] = React.useState<ExpertTrainingModel>();
  const isExpert = useSelector(CacheSelector.isCurrentUserExpertSelector());

  React.useEffect(() => {
    // skip first login on account creation
    if (!isExpert || window.location.pathname.includes("request-endorsement")) {
      return;
    }

    expertService.getExpertTraining().subscribe({
      next: (response) => {
        if (response.expertTraining) {
          setTraining(response.expertTraining);
          setShowTraining(true);
        }
      }
    });
  }, [expertService, isExpert]);

  const acknowledgeTraining = React.useCallback(() => {
    if (!isExpert || !training) {
      return;
    }

    setIsLoading(true);
    expertService.acknowledgeExpertTraining(training.id).subscribe({
      next: () => {
        setIsLoading(false);
        setShowTraining(false);
      },
      error: (error) => {
        notificationService.showApiErrors(error);
        setIsLoading(false);
      }
    });
  }, [expertService, isExpert, notificationService, training]);

  const downloadTraining = React.useCallback(() => {
    if (!isExpert || !training) {
      return;
    }

    window.open(training.downloadUrl, "_blank");
  }, [isExpert, training]);

  return isExpert ? (
    <ExpertTrainingModal
      isOpen={showTraining}
      isLoading={isLoading}
      title={training?.title || ""}
      text={training?.text || ""}
      onRead={acknowledgeTraining}
      onDownload={downloadTraining}
    />
  ) : null;
};
