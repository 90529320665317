import { Icon } from "arbolus-ui-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CIQError,
  CanopyService,
  DefaultToasterService,
  ErrorResponse,
  ToasterService
} from "@arbolus-technologies/api";
import {
  TRANSCRIPT_EDIT_MODE,
  TRANSCRIPT_STATUS
} from "@arbolus-technologies/models/common";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { VideoPlayer } from "@arbolus-technologies/ui/components";
import {
  CANOPY_DATE_TIME_FORMAT,
  formatDate
} from "@arbolus-technologies/utils";

import { BookmarkButton } from "../../BookmarkButton/BookmarkButton";
import { AnswerModel, AnswerQuestion, Data, ExpertModel } from "../types";
import { WithExpert } from "./Sections/WithExpert/WithExpert";
import { WithQuestion } from "./Sections/WithQuestion/WithQuestion";
import { useVideoDuration } from "./useVideoDuration";

import styles from "./AnswerVideo.module.scss";

interface AnswerVideoProps {
  question?: AnswerQuestion;
  expert?: ExpertModel;
  answer: AnswerModel;
  openAll?: boolean;
  topicName?: string;
  handleShareLink: (answerId: string, expertId?: string) => void;
  redirectToQuestion?: (questionId: string) => void;
  redirectToExpert?: (expertId: string) => void;
  playingVideoId: string;
  setPlayingVideoId: React.Dispatch<React.SetStateAction<string>>;
  canopyService?: typeof CanopyService;
  notificationService?: ToasterService;
}

export const AnswerVideo: React.FC<AnswerVideoProps> = ({
  question,
  expert,
  answer,
  openAll = false,
  handleShareLink,
  redirectToQuestion,
  redirectToExpert,
  playingVideoId,
  setPlayingVideoId,
  canopyService = CanopyService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("answer");

  const { created, downloadUrl, transcript } = answer;
  const { videoDuration } = useVideoDuration(downloadUrl);

  const [isDrawerOpen, setIsDrawerOpen] = useState(openAll);
  const [internalTranscript, setInternalTranscript] = useState(transcript);
  const [resetModal, setResetModal] = useState(false);
  const [transcriptState, setTranscriptState] = useState<TRANSCRIPT_EDIT_MODE>(
    TRANSCRIPT_EDIT_MODE.VIEW
  );

  useEffect(() => {
    setIsDrawerOpen(openAll);
  }, [openAll]);

  useEffect(() => {
    setInternalTranscript(transcript);
  }, [transcript]);

  const handleTranscriptStatus = (): TRANSCRIPT_STATUS => {
    if (internalTranscript === null || internalTranscript === undefined) {
      return TRANSCRIPT_STATUS.NO_TRANSCRIPT;
    }

    if (answer?.isSmartTranscript) {
      return TRANSCRIPT_STATUS.SMART_TRANSCRIPT;
    }

    return TRANSCRIPT_STATUS.AUTOMATIC_TRANSCRIPT;
  };

  const handleSetResetModal = (value: boolean) => {
    setResetModal(value);
  };

  const handleSetTranscriptState = (value: TRANSCRIPT_EDIT_MODE) => {
    setTranscriptState(value);
  };

  const saveTranscript = (content: string) => {
    canopyService.editVideoTranscript(answer.id, content).subscribe(
      () => {
        setTranscriptState(TRANSCRIPT_EDIT_MODE.VIEW);
        setInternalTranscript(content);
        notificationService.showSuccess(t("transcriptSaveSuccess"));
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
      }
    );
  };

  const resetTranscript = () => {
    canopyService.resetVideoTranscript(answer.id).subscribe(
      (response) => {
        setTranscriptState(TRANSCRIPT_EDIT_MODE.VIEW);
        setInternalTranscript(response.content);
        setResetModal(false);
        notificationService.showSuccess(t("transcriptResetSuccess"));
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
      }
    );
  };

  const data: Data = {
    answer: answer,
    handleShareLink: handleShareLink,
    isDrawerOpen: isDrawerOpen,
    transcriptStatus: handleTranscriptStatus(),
    transcriptState: transcriptState,
    resetTranscript: resetTranscript,
    internalTranscript: internalTranscript,
    transcript: transcript,
    saveTranscript: saveTranscript,
    handleSetTranscriptState: handleSetTranscriptState,
    handleSetResetModal: handleSetResetModal,
    resetModal: resetModal,
    handleTranscriptClick: () => setIsDrawerOpen((prev) => !prev)
  };

  return (
    <div className={styles.container}>
      <div className={styles.videoContainer}>
        <VideoPlayer
          src={downloadUrl}
          size={224}
          answerId={answer.id}
          playingVideoId={playingVideoId}
          setPlayingVideoId={setPlayingVideoId}
        />
      </div>
      <div className={styles.textContainer}>
        {question && redirectToQuestion && (
          <WithQuestion
            question={question}
            redirectToQuestion={redirectToQuestion}
            data={data}
            videoDuration={videoDuration}
          />
        )}
        {expert && (
          <WithExpert
            expert={expert}
            redirectToExpert={redirectToExpert}
            data={data}
            videoDuration={videoDuration}
          />
        )}
      </div>

      <div className={styles.buttonsContainer}>
        <div className={styles.actionsContainer}>
          <BookmarkButton
            answer={{ isBookmarked: answer.isBookmarked, id: answer.id }}
          />
        </div>
        <Icon
          name={isDrawerOpen ? "expand_less" : "expand_more"}
          color={ARBOLUS_COLORS.bColorBaseDark}
          fontSize="24px"
          onClick={() => setIsDrawerOpen((prev) => !prev)}
        />
      </div>
      <div className={styles.bottomContainer}>
        <p className={styles.responseDate}>
          <span className={styles.videoDuration}>{videoDuration}</span>
          {` - `}
          {formatDate(created, CANOPY_DATE_TIME_FORMAT)}
        </p>
      </div>
    </div>
  );
};
