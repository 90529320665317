import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { BookmarkCollection } from "@arbolus-technologies/models/common";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { IThreeDotsItem } from "@arbolus-technologies/ui/components";

import { DeleteCollectionModal } from "../DeleteCollectionModal/DeleteCollectionModal";
import { RenameCollectionModal } from "../RenameCollectionModal/RenameCollectionModal";

interface CollectionDotActionsProps {
  collection: BookmarkCollection;
  childrenRenderer: (actionItems: IThreeDotsItem[]) => JSX.Element;
  onDeleteSuccess?: () => void;
}

export const CollectionDotActions: React.FC<CollectionDotActionsProps> = ({
  collection,
  childrenRenderer,
  onDeleteSuccess
}) => {
  const { t } = useTranslation("bookmarkCollectionActions");
  const [renameOpen, setRenameOpen] = useState(false);
  const [showRename, setShowRename] = useState(renameOpen);
  const [deleteOpen, setDeleteOpen] = useState(false);

  useEffect(() => {
    if (showRename !== renameOpen) {
      if (showRename) {
        setTimeout(() => setShowRename(false), 300);
      } else {
        setShowRename(true);
      }
    }
  }, [renameOpen, showRename, setShowRename]);

  const actionItems: IThreeDotsItem[] = [
    {
      title: t("rename"),
      icon: "edit",
      color: ARBOLUS_COLORS.bColorSecondaryGreyDark,
      iconColor: ARBOLUS_COLORS.bColorSecondaryGreyDark,
      onClick: () => setRenameOpen(true),
      disabled: collection.isDefault
    },
    {
      title: t("delete"),
      icon: "delete",
      color: ARBOLUS_COLORS.bColorAccentOrangeDark,
      iconColor: ARBOLUS_COLORS.bColorAccentOrangeDark,
      onClick: () => setDeleteOpen(true),
      disabled: collection.isDefault
    }
  ];

  return (
    <>
      {showRename && (
        <RenameCollectionModal
          id={collection.id}
          currentName={collection.name}
          isOpen={renameOpen}
          close={() => setRenameOpen(false)}
        />
      )}
      <DeleteCollectionModal
        id={collection.id}
        isOpen={deleteOpen}
        close={() => setDeleteOpen(false)}
        onSuccess={onDeleteSuccess}
      />
      {childrenRenderer(actionItems)}
    </>
  );
};
