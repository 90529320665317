import { StringMap, TOptions } from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const useDocumentTitle = (
  namespace: string,
  key: string | string[],
  options?: string | TOptions<StringMap> | undefined
): void => {
  const { t } = useTranslation(namespace);

  useEffect(() => {
    document.title = t(key, options);
  }, [key, options, t]);
};
