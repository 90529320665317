import { combineEpics } from "redux-observable";

import { BookmarkCollectionsEpics } from "@arbolus-technologies/stores/bookmark-collections";
import { BookmarksEpics } from "@arbolus-technologies/stores/bookmarks";
import { CanopyV2Epics } from "@arbolus-technologies/stores/canopy-V2";
import { CanopyBuilderEpics } from "@arbolus-technologies/stores/canopy-builder";
import { CanopyClientEpics } from "@arbolus-technologies/stores/canopy-client";
import { CanopyExpertEpics } from "@arbolus-technologies/stores/canopy-expert";
import { ProjectNxEpics } from "@arbolus-technologies/stores/project";
import { ProjectAnglesEpics } from "@arbolus-technologies/stores/project-angles-store";
import { ProjectCanopyEpics } from "@arbolus-technologies/stores/project-canopy-store";
import { ProjectExpertsEpics } from "@arbolus-technologies/stores/project-experts-store";

import AppEpics from "../containers/app/store/epics/AppEpics";
import InboxEpics from "../containers/app/store/epics/InboxEpics";
import LoginEpics from "../containers/auth/store/epics/LoginEpics";
import ProjectEpics from "../containers/project/store/epics/ProjectEpics";

export default combineEpics(
  ...ProjectEpics,
  ...CanopyExpertEpics,
  ...LoginEpics,
  ...InboxEpics,
  ...AppEpics,
  ...ProjectCanopyEpics,
  ...ProjectAnglesEpics,
  ...CanopyClientEpics,
  ...CanopyV2Epics,
  ...CanopyBuilderEpics,
  ...ProjectNxEpics,
  ...BookmarkCollectionsEpics,
  ...BookmarksEpics,
  ...ProjectExpertsEpics
);
