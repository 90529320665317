import React from "react";

import { TranscriptMode } from "../../../../../contexts/transcript/TranscriptContext";
import useTranscriptContext from "../../../../../contexts/transcript/UseTranscriptContext";
import HighlightEvents from "./highlight/HighlightEvents";

const OwnHighlightHolder: React.FC<{ children: React.ReactNode }> = ({
  children,
  ...props
}): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const holderProps = props as any;
  const highlightId = holderProps.entityKey;

  return (
    <span
      id={`highlight-${highlightId}`}
      className="own-highlight-text"
      onClick={(): void => HighlightEvents.selectOwnHighlight(highlightId)}
    >
      {children}
    </span>
  );
};

const HighlightHolder: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const { mode, resetEditorSelection, editorState } = useTranscriptContext();
  const isHighlightMode = mode === TranscriptMode.HIGHLIGHTS;

  const handleTextClick = (): void => {
    if (isHighlightMode && !editorState?.getSelection().isCollapsed())
      resetEditorSelection();
  };

  return (
    <span className="highlight-text" onClick={handleTextClick}>
      {children}
    </span>
  );
};

const DefaultHolder: React.FC<{ children: React.ReactNode }> = ({
  children
}): JSX.Element => {
  const { mode, resetEditorSelection, editorState } = useTranscriptContext();
  const isHighlightMode = mode === TranscriptMode.HIGHLIGHTS;

  const handleTextClick = (): void => {
    if (isHighlightMode && !editorState?.getSelection().isCollapsed())
      resetEditorSelection();
  };
  return (
    <span className="transcript-text" onClick={handleTextClick}>
      {children}
    </span>
  );
};

const holder = {
  OwnHighlightHolder,
  HighlightHolder,
  DefaultHolder
};

export default holder;
