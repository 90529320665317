import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React, { useRef, useEffect } from "react";
import { Subscription } from "rxjs";

import { CanopyDocument } from "@arbolus-technologies/models/canopy";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { maxLengthString } from "@arbolus-technologies/utils";

import styles from "./SurveyDocs.module.scss";

interface SurveyDocsProps {
  documents: CanopyDocument[];
  links: string[];
  isCanopyDetails?: boolean;
}

export const SurveyDocs: React.FC<SurveyDocsProps> = ({
  documents,
  links,
  isCanopyDetails
}) => {
  const documentDownloadSubscription = useRef<Subscription>();
  const maxLength = 22;

  useEffect(
    () => (): void => {
      documentDownloadSubscription.current?.unsubscribe();
    },
    []
  );

  return (
    <div
      className={clsx(
        isCanopyDetails
          ? styles.canopyDetailsDocsContainer
          : styles.docsContainer
      )}
    >
      {documents?.map((doc) => (
        <a key={doc.id} href={doc.downloadUrl} className={styles.docs}>
          <Icon name="attach_file" fontSize="20px" color="#bbbecb" />
          <span className={styles.docsText}>
            {maxLength && doc.fileName.length > maxLength
              ? maxLengthString(doc.fileName, maxLength)
              : doc.fileName}
          </span>
        </a>
      ))}
      {links?.map((link) => (
        <a
          key={link}
          href={link}
          className={styles.docs}
          target="_blank"
          rel="noreferrer"
        >
          <Icon
            name="link"
            fontSize="20px"
            color={ARBOLUS_COLORS.bColorBaseBlue}
          />
          <span className={styles.docsText}>
            {maxLength && link.length > maxLength
              ? maxLengthString(link, maxLength)
              : link}
          </span>
        </a>
      ))}
    </div>
  );
};
