import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";

import AccessManager from "../../../../../contexts/roleBasedAccess/AccessManager";
import { User } from "../../../../../models/user";
import { ProjectMember } from "../../../../../models/view/project";
import { UtilsService } from "../../../../../services";
import { CIQAvatar } from "../../../../app/components";
import { AuthSelector } from "../../../../auth/store";

import styles from "./TeamPanel.module.scss";

interface MemberItemProps {
  member: ProjectMember;
  removable?: boolean;
  isCurrentUser?: boolean;
  onRemoveMember?: (memberId: string) => void;
  onAddMember?: (memberId: string) => void;
  onClientUserSelected?: (user: ProjectMember) => void;
}

const MemberItem: React.FC<MemberItemProps> = ({
  member,
  removable,
  isCurrentUser,
  onRemoveMember,
  onAddMember,
  onClientUserSelected
}: MemberItemProps) => {
  const [removeMode, setRemoveMode] = useState<boolean>(false);
  const { t } = useTranslation("teamManagementPanel");
  const loggedInClientId = useSelector(AuthSelector.authClientIdSelector());

  const handleConfirmRemoveMember = (): void => {
    setRemoveMode(true);
  };

  const handleCancelRemoveMember = (): void => {
    setRemoveMode(false);
  };

  const handleAddMember = (): void => {
    onAddMember!(member.userId!);
  };

  const { firstName, lastName, email, profileImageUrl, memberId } = member;

  const user = {
    firstName,
    lastName,
    email
  } as User;
  const expertMemberName = user?.firstName
    ? UtilsService.displayUserName(user)
    : t("guestUser");

  const renderRightContainer = (): JSX.Element => {
    if (removable) {
      const rightContainer = removeMode ? (
        <div className="right-container">
          <Button
            size="sm"
            color="danger"
            className="remove-btn"
            type="button"
            onClick={(): void => {
              onRemoveMember!(memberId!);
            }}
          >
            Remove
          </Button>
          <span
            className="ciq-icon ciq-close"
            onClick={handleCancelRemoveMember}
          />
        </div>
      ) : (
        <div className="right-container">
          <AccessManager permission="projectTeam:removeMember">
            <span
              className="ciq-icon ciq-trash"
              onClick={handleConfirmRemoveMember}
            />
          </AccessManager>
        </div>
      );
      return rightContainer;
    }
    return (
      <div className="right-container">
        <span className="ciq-icon ciq-plus" onClick={handleAddMember} />
      </div>
    );
  };

  const isNameClickable =
    member.clientId === loggedInClientId &&
    !member.isCiqManager &&
    member.emailConfirmed;

  return (
    <>
      <div className="left-container">
        <AccessManager
          permission="projectTeam:showClientUserInfo"
          deniedElement={
            <>
              <div className="users-avatar">
                <CIQAvatar
                  username={expertMemberName}
                  profileImageUrl={profileImageUrl}
                />
              </div>
              <div className="user-name">{expertMemberName}</div>
            </>
          }
        >
          <div className="users-avatar">
            <CIQAvatar
              username={UtilsService.displayUserName(user)}
              profileImageUrl={profileImageUrl}
            />
          </div>
          <div
            className={clsx("user-name", {
              [styles.link]: isNameClickable
            })}
            onClick={() => isNameClickable && onClientUserSelected?.(member)}
          >
            {isCurrentUser ? "Me" : UtilsService.displayUserName(user)}
          </div>
        </AccessManager>
      </div>

      {!isCurrentUser && renderRightContainer()}
    </>
  );
};

export default MemberItem;
