import React, { useCallback, useState } from "react";

import {
  DefaultToasterService,
  DocumentService,
  ToasterService
} from "@arbolus-technologies/api";
import { DocumentBox } from "@arbolus-technologies/ui/components";

interface DownloadDocumentBoxProps {
  id: string;
  projectId: string;
  name: string;
  documentService?: typeof DocumentService;
  notificationService?: ToasterService;
}

export const DownloadDocumentBox: React.FC<DownloadDocumentBoxProps> = ({
  id,
  projectId,
  name,
  documentService = DocumentService,
  notificationService = DefaultToasterService
}) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadDocument = useCallback((): void => {
    if (isDownloading) {
      return;
    }

    setIsDownloading(true);
    documentService.downloadDocument(projectId, id).subscribe({
      error: notificationService.showApiErrors,
      complete: () => setIsDownloading(false)
    });
  }, [documentService, id, isDownloading, notificationService, projectId]);

  return (
    <DocumentBox
      name={name}
      fileId={id}
      isDownloading={isDownloading}
      onClickDownload={downloadDocument}
    />
  );
};
