import React from "react";

import { User } from "@arbolus-technologies/models/project";
import { utilService } from "@arbolus-technologies/utils";

import { Avatar } from "../Avatar/Avatar";

import styles from "./UserTag.module.scss";

interface UserTagProps {
  user: User;
  onRemoveClicked?: () => void;
}

export const UserTag: React.FC<UserTagProps> = ({
  user,
  onRemoveClicked
}: UserTagProps) => (
  <button type="button" className={styles.userTag}>
    <Avatar
      avatar={{
        name: utilService.displayUserName(user),
        imageUrl: user.profileImageUrl
      }}
    />

    <span>{utilService.displayUserName(user)}</span>

    {onRemoveClicked && (
      <span className="ciq-icon ciq-close" onClick={onRemoveClicked} />
    )}
  </button>
);
