import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CIQError,
  ErrorResponse,
  ExpertService,
  ProjectNote,
  ToasterService
} from "@arbolus-technologies/api";
import { Loader } from "@arbolus-technologies/ui/components";

import { ProjectNoteCard } from "./ProjectNoteCard/ProjectNoteCard";

import styles from "./ProjectNotes.module.scss";

const notificationService = new ToasterService();

export interface ProjectNotesProps {
  expertId: string;
  expertService?: typeof ExpertService;
}

export const ProjectNotes: React.FC<ProjectNotesProps> = ({
  expertId,
  expertService = ExpertService
}) => {
  const { t } = useTranslation("projectNotes");

  const [projectNotes, setProjectNotes] = useState<ProjectNote[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getProjectNotes = () => {
      setLoading(true);

      expertService.getProjectNotes(expertId).subscribe(
        (projectNotes) => {
          setProjectNotes(projectNotes.items.filter((item) => !!item.note));
          setLoading(false);
        },
        ({ message }: ErrorResponse<CIQError>) => {
          notificationService.showError(message);
          setLoading(false);
        }
      );
    };

    getProjectNotes();
  }, [expertId, expertService]);

  if (loading) {
    return <Loader />;
  }

  return projectNotes.length > 0 ? (
    <div className={styles.container}>
      {projectNotes.map((projectNote: ProjectNote) => (
        <ProjectNoteCard
          key={projectNote.project.id}
          projectNote={projectNote}
        />
      ))}
    </div>
  ) : (
    <div className={styles.noResultContainer}>{t("noProjectNotes")}</div>
  );
};
