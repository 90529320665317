import React from "react";
import { useTranslation } from "react-i18next";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { AdminProjectStatsCard } from "../AdminProjectStatsCard/AdminProjectStatsCard";
import { StatButtonProps } from "./StatButtonProps";

export const ApprovedButton: React.FC<StatButtonProps> = ({
  to,
  itemCount
}) => {
  const { t } = useTranslation("approvedButton");

  return (
    <AdminProjectStatsCard
      title={t("approved")}
      subtitle={
        itemCount > 0
          ? t("expertCount", {
              count: itemCount
            })
          : t("noExpertsAvailable")
      }
      iconName="thumb_up"
      color={ARBOLUS_COLORS.bColorSecondaryGreen}
      to={to}
    />
  );
};
