import { ICellRendererParams } from "ag-grid-community";
import React from "react";

import { ContextMenuRenderer } from "@arbolus-technologies/ui/components";

import { ApprovedRenderer } from "./StatusRenderer";

export const ApprovedCandidateActionRenderer: React.FC<ICellRendererParams> = (
  params
) => (
  <div className="flex-row-4 align-items-center">
    <div className="d-inline-block">
      <ApprovedRenderer />
    </div>
    <ContextMenuRenderer {...params} />
  </div>
);
