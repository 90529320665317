import { yupResolver } from "@hookform/resolvers/yup";
import { Icon } from "arbolus-ui-components";
import React from "react";
import { FormProvider, UseFormReturn, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import {
  MixPanelActions,
  MixPanelEventNames,
  useMixpanel
} from "@arbolus-technologies/features/common";
import { MAIN_PROJECTS_ROUTE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  ProjectNxSelector,
  ProjectNxStoreActions
} from "@arbolus-technologies/stores/project";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { PageWithSteps } from "@arbolus-technologies/ui/components";

import { ProjectCreateSchema } from "../../SimplifiedProjectSchema";
import {
  ACTIVATE_CHAT,
  BRIEF,
  CASE_CODE,
  CONTACT_NUMBER,
  END_DATE,
  EXPERT_COUNT,
  MAIN_CONTACT,
  MANAGER_EMAIL,
  MANAGER_NAME,
  PARTNER_EMAIL,
  PARTNER_NAME,
  PROJECT_NAME,
  START_DATE,
  TIMEZONE
} from "../../type";
import { PROJECT_CREATION_STEPS_TRANSLATION } from "./ProjectCreationSteps";

import styles from "./SimplifiedCreateProjectPage.module.scss";

interface SimplifiedCreateProjectPageProps {
  clientId: string;
}

export const SimplifiedCreateProjectPage: React.FC<
  SimplifiedCreateProjectPageProps
> = ({ clientId }) => {
  const { t } = useTranslation("projectSimplifiedForm");
  const dispatch = useDispatch();
  const { trackEvent } = useMixpanel();
  const { push } = useHistory();

  const loggedInUser = useSelector(CacheSelector.loggedInUser());
  const isCreatingSimplifiedProject = useSelector(
    ProjectNxSelector.isCreatingSimplifiedProject()
  );
  const isAdmin = useSelector(CacheSelector.isAdmin());

  // @ts-ignore
  const methods: UseFormReturn = useForm({
    resolver: yupResolver(ProjectCreateSchema),
    mode: "all",
    defaultValues: {
      [PROJECT_NAME]: t("formTitle"),
      [EXPERT_COUNT]: 1,
      [MAIN_CONTACT]: loggedInUser,
      [CONTACT_NUMBER]: loggedInUser.phoneNumber,
      [ACTIVATE_CHAT]: true,
      [START_DATE]: new Date(),
      [END_DATE]: null
    }
  });

  const handleAfterLastStep = () => {
    const formValues = methods.getValues();
    dispatch(
      ProjectNxStoreActions.simplifiedCreateProject({
        clientId,
        name: formValues[PROJECT_NAME],
        brief: formValues[BRIEF],
        timeZone: formValues[TIMEZONE],
        minimumNumberOfExperts: formValues[EXPERT_COUNT],
        mainContactUserId: formValues[MAIN_CONTACT].id,
        phoneNumber: formValues[CONTACT_NUMBER],
        activeProjectChatForAllUsers: formValues[ACTIVATE_CHAT],
        startDate: formValues[START_DATE],
        endDate: formValues[END_DATE],
        caseCode: formValues[CASE_CODE],
        managerName: formValues[MANAGER_NAME],
        managerEmail: formValues[MANAGER_EMAIL],
        partnerName: formValues[PARTNER_NAME],
        partnerEmail: formValues[PARTNER_EMAIL]
      })
    );

    trackEvent?.(MixPanelEventNames.Projects, {
      action: MixPanelActions.Completed
    });
  };

  // Make sure this form is visible only to clients
  if (isAdmin) {
    push(MAIN_PROJECTS_ROUTE());
    return null;
  }

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.pageContainer}>
        <ProjectCreationHeader />
        <FormProvider {...methods}>
          <PageWithSteps
            steps={PROJECT_CREATION_STEPS_TRANSLATION}
            onAfterLastStep={handleAfterLastStep}
            stepComponentProps={{
              clientId
            }}
            isProcessingLastStep={isCreatingSimplifiedProject}
          />
        </FormProvider>
      </div>
    </div>
  );
};

const ProjectCreationHeader: React.FC = () => {
  const { t } = useTranslation("createNewProject");

  return (
    <div className={styles.header}>
      <Icon
        name="folder"
        fontSize="24px"
        color={ARBOLUS_COLORS.bColorBasePurple}
      />
      <h1>{t("newProject")}</h1>
    </div>
  );
};
