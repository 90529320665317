import React from "react";
import { useTranslation } from "react-i18next";

import { logoNameBlue } from "@arbolus-technologies/theme";
import { LinkButton } from "@arbolus-technologies/ui/components";

import styles from "./CanopyPaused.module.scss";

export const CanopyPaused: React.FC = () => {
  const { t } = useTranslation("canopyPaused");

  return (
    <div className={styles.container}>
      <img src={logoNameBlue} alt="arbolus-logo" className={styles.arbolus} />
      <h1>{t("title")}</h1>
      <p className="text-left">{t("subtitle")}</p>
      <LinkButton to="/" text={t("home")} />
    </div>
  );
};
