import React from "react";
import ReactPlaceholder from "react-placeholder/lib";
import { useSelector } from "react-redux";

import { ExpertSlotsByDate } from "@arbolus-technologies/api";
import { SelectOption } from "@arbolus-technologies/models/common";
import { ScheduleProjectData } from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { ScheduleColumnBody } from "./ScheduleColumnBody/ScheduleColumnBody";
import { ScheduleColumnHeader } from "./ScheduleColumnHeader/ScheduleColumnHeader";

import styles from "./ScheduleColumn.module.scss";

interface ScheduleColumnProps {
  expertSlotsByDate?: ExpertSlotsByDate;
  project: ScheduleProjectData;
  isLoading: boolean;
  columnIndex: number;
  pageNumber: number;
  handleClickRow: (
    expertId: string,
    referralId: string,
    openAvailabilityTab?: boolean
  ) => void;
  formattedDates: string[];
  slotsNumber?: number;
}

export const ScheduleColumn: React.FC<ScheduleColumnProps> = ({
  expertSlotsByDate,
  project,
  isLoading,
  columnIndex,
  pageNumber,
  handleClickRow,
  formattedDates,
  slotsNumber = 0
}) => {
  const timezones: Map<string, SelectOption> = useSelector(
    CacheSelector.appTimezoneSelectOptionMapSelector()
  );
  const timezone = timezones.get(project.timezone)?.customLabel || "";

  return (
    <>
      <div className={styles.scheduleColumn}>
        <ScheduleColumnHeader
          title={formattedDates[columnIndex]}
          slotsNumber={slotsNumber}
          timezone={timezone}
          columnIndex={columnIndex}
          currentPage={pageNumber}
        />
        <ReactPlaceholder
          type="media"
          ready={!isLoading}
          showLoadingAnimation
          color={ARBOLUS_COLORS.bColorSecondaryGreyLight}
        >
          <ScheduleColumnBody
            expertSlotsByDate={expertSlotsByDate}
            handleClickRow={handleClickRow}
            project={project}
          />
        </ReactPlaceholder>
      </div>
    </>
  );
};
