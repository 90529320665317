import { Button } from "arbolus-ui-components";
import dompurify from "dompurify";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { DeleteModal, Drawer } from "@arbolus-technologies/ui/components";

import styles from "./Welcome.module.scss";

const sanitizer = dompurify.sanitize;

export interface WelcomeProps {
  isLoading: boolean;
  stepDetails: string[];
  onStartApplication: () => void;
  onDeclineApplication?: () => void;
}

export const Welcome: React.FC<WelcomeProps> = ({
  isLoading,
  stepDetails,
  onStartApplication,
  onDeclineApplication
}) => {
  const { t } = useTranslation("expertApplications");

  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState<boolean>(false);

  const handleDeclineCancelClicked = (): void => {
    setIsDeclineModalOpen(false);
  };

  const handleDeclineConfirmClicked = (): void => {
    setIsDeclineModalOpen(false);

    onDeclineApplication?.();
  };

  const handleDeclineClick = (): void => {
    setIsDeclineModalOpen(true);
  };

  return (
    <div className={styles.container}>
      <Drawer openByDefault disableCollapse title={t("nextSteps")}>
        <div className={styles.stepsDetails}>
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizer(t("stepInfo"))
            }}
          />
          {stepDetails.map((step, key) => (
            <div className={styles.step} key={step}>
              <div className={styles.stepCount}>{key + 1}</div>
              <span
                dangerouslySetInnerHTML={{
                  __html: sanitizer(step)
                }}
              />
            </div>
          ))}
        </div>
      </Drawer>
      <div className={styles.btnContainer}>
        {onDeclineApplication && (
          <Button
            type="secondary"
            onClick={handleDeclineClick}
            text={t("declineProject")}
            disabled={isLoading}
          />
        )}
        <Button
          onClick={onStartApplication}
          text={t("getStarted")}
          disabled={isLoading}
        />
      </div>
      <DeleteModal
        toggleModal={isDeclineModalOpen}
        messageTitle={t("declineModalTitle")}
        warningMessage={t("declineWarningMessage")}
        deleteActionText={t("decline")}
        onCancel={handleDeclineCancelClicked}
        onConfirm={handleDeclineConfirmClicked}
      />
    </div>
  );
};
