import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import SimpleBar from "simplebar-react";

import { SlidePanel } from "@arbolus-technologies/features/common";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import {
  ProjectAnglesSelector,
  ProjectAnglesStoreActions
} from "@arbolus-technologies/stores/project-angles-store";
import { NoAngles } from "@arbolus-technologies/ui/angles";
import {
  InformModal,
  Loader,
  UI_ANGLES_LIST
} from "@arbolus-technologies/ui/components";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { AnglesCard } from "../AnglesCard/AnglesCard";
import { AngleDetails } from "../ViewEditAnglePanel/AngleDetails";

import styles from "./AnglesListPage.module.scss";

const ANGLES_LIMIT_NUMBER = 10;

interface AnglesListPageProps {
  project: {
    id: string;
    name: string;
  };
  goToProject: () => void;
  navigateToExpertsListTab: string;
  isAdmin?: boolean;
}

export const AnglesListPage: React.FC<AnglesListPageProps> = ({
  project,
  goToProject,
  navigateToExpertsListTab,
  isAdmin = false
}) => {
  const [showLimitAngleModal, setShowLimitAngleModal] =
    useState<boolean>(false);
  const { t } = useTranslation("anglesList");
  const dispatch = useDispatch();
  const anglesCards = useSelector(ProjectAnglesSelector.anglesCardsSelector());
  const isAnglesCardsLoading = useSelector(
    ProjectAnglesSelector.anglesCardsLoadingSelector()
  );

  const createNewAngle = () => {
    if (anglesCards.length >= ANGLES_LIMIT_NUMBER) {
      setShowLimitAngleModal(true);
    } else {
      dispatch(PanelStoreActions.openPanel(PanelId.CreateAngle));
      dispatch(ProjectAnglesStoreActions.setFormCreateEditAngleEnabled());
    }
  };

  return (
    <div className={styles.anglesPageContainer}>
      <div className={styles.pageContentHeader}>
        <div className={styles.headerContainer}>
          <div className={styles.leftContainer}>
            <h3 onClick={goToProject}>{project.name}</h3>
            <h1>{t("angles")}</h1>
          </div>
          <div className={styles.rightContainer}>
            <div>
              <Button
                color="primary"
                className={styles.withIcon}
                onClick={createNewAngle}
              >
                <span className="ciq-icon ciq-plus" />
                {t("addAngle")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <SimpleBar
        className="simplebar-light"
        style={{
          minHeight: `calc( 100vh - ${UI_ANGLES_LIST.CONTAINER_EXCESS_HEIGHT}px)`,
          maxHeight: `calc( 100vh - ${UI_ANGLES_LIST.CONTAINER_EXCESS_HEIGHT}px)`,
          overflowX: "hidden"
        }}
      >
        <div className={styles.anglesBody}>
          <div className={styles.anglesContainer}>
            {isAnglesCardsLoading ? (
              <div className={styles.anglesLoaderContainer}>
                <Loader />
              </div>
            ) : (
              <>
                {anglesCards.length > 0 &&
                  anglesCards.map((angleCard) => (
                    <div className={styles.angleCard} key={angleCard.id}>
                      <AnglesCard
                        projectId={project.id}
                        angleCard={angleCard}
                        navigateToExpertsListTab={navigateToExpertsListTab}
                        isAdmin={isAdmin}
                      />
                    </div>
                  ))}
                {anglesCards.length === 0 && <NoAngles />}
              </>
            )}
          </div>
        </div>
      </SimpleBar>
      <SlidePanel panelId={PanelId.CreateAngle} width={SIDE_PANEL_SIZE._720}>
        <AngleDetails projectId={project.id} isAdmin={isAdmin} />
      </SlidePanel>
      <InformModal
        toggleModal={showLimitAngleModal}
        messageTitle={t("anglesLimitTitle")}
        warningMessage={t("anglesLimitBody")}
        hideModal={() => setShowLimitAngleModal(false)}
      />
    </div>
  );
};
