import React from "react";

import { displayTotalAmount } from "@arbolus-technologies/utils";

interface AmountRendererProps extends React.HTMLProps<HTMLSpanElement> {
  amount: number;
  isoCurrencyCode?: string;
  isCredit: boolean;
}

export const AmountRenderer: React.FC<AmountRendererProps> = ({
  amount,
  isoCurrencyCode = "",
  className,
  isCredit
}: AmountRendererProps) => (
  <span className={className}>
    {displayTotalAmount(isCredit, {
      amount,
      currencyCode: isoCurrencyCode,
      minimumFractionDigits: 2
    })}
  </span>
);
