import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";

import {
  CALL_STATUSES,
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ExpertSlotsByDate,
  ProjectExpertsService,
  ProjectReferralAvailability,
  STATUSES,
  SelectedFilterEnum,
  ToasterService
} from "@arbolus-technologies/api";
import { ScheduleProjectData } from "@arbolus-technologies/models/project";
import { ProjectExpertsSelector } from "@arbolus-technologies/stores/project-experts-store";
import {
  DATE_SHORT_MONTH_FORMAT,
  DATE_TIME_FORMAT,
  useFromDateRange
} from "@arbolus-technologies/utils";

import { parseExpertSlotsByDate } from "../../utils";
import { ScheduleColumn } from "./ScheduleColumn/ScheduleColumn";
import { ScheduleHeader } from "./ScheduleHeader/ScheduleHeader";

import styles from "./Schedule.module.scss";

interface ScheduleProps {
  project: ScheduleProjectData;
  handleClickRow: (
    expertId: string,
    referralId: string,
    openAvailabilityTab?: boolean
  ) => void;
  notificationService?: ToasterService;
}

export const Schedule: React.FC<ScheduleProps> = ({
  project,
  handleClickRow,
  notificationService = DefaultToasterService
}) => {
  const appliedFilters = useSelector(
    ProjectExpertsSelector.scheduleTabFilters(project.id)
  );
  const statuses = appliedFilters
    .filter((item) => item.key === SelectedFilterEnum.STATUSES)
    .map((item) => item.id) as STATUSES[];
  const angleIds = appliedFilters
    .filter((item) => item.key === SelectedFilterEnum.ANGLE_IDS)
    .map((item) => item.id);
  const callStatuses = appliedFilters
    .filter((item) => item.key === SelectedFilterEnum.CALL_STATUSES)
    .map((item) => item.id) as CALL_STATUSES[];

  const [
    isScheduleAvailabilityListLoading,
    setIsScheduleAvailabilityListLoading
  ] = useState<boolean>(false);
  const [expertSlotsByDate, setExpertSlotsByDate] = useState<
    ExpertSlotsByDate[]
  >([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [formattedDates, setFormattedDates] = useState<string[]>([]);

  const { setFromDateRange, dates, startDate, endDate } = useFromDateRange(
    project.timezone,
    3
  );

  const getScheduleAvailabilityList = () => {
    setIsScheduleAvailabilityListLoading(true);
    ProjectExpertsService.getScheduleAvailabilityList(
      project.id,
      startDate,
      endDate,
      statuses,
      angleIds,
      callStatuses
    ).subscribe(
      (res: ProjectReferralAvailability) => {
        setIsScheduleAvailabilityListLoading(false);
        const groupedSlots: ExpertSlotsByDate[] = parseExpertSlotsByDate(
          res.slots,
          res.experts,
          project.timezone
        );
        setExpertSlotsByDate(groupedSlots);
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showError(error.message);
        setIsScheduleAvailabilityListLoading(false);
      }
    );
  };

  useEffect(() => {
    if (dates.length) {
      setFormattedDates(
        dates.map((date) => date.format(DATE_SHORT_MONTH_FORMAT))
      );
    }
  }, [dates]);

  useEffect(() => {
    if (startDate && endDate) {
      getScheduleAvailabilityList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    project.id,
    appliedFilters,
    project.timezone,
    startDate,
    endDate
  ]);

  const handleNext = () => {
    setFromDateRange(1);
    setCurrentPage((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setFromDateRange(-1, currentPage === 1);
    setCurrentPage((prev) => prev - 1);
  };

  return dates.length === 0 ? null : (
    <div className={styles.scheduleContainer}>
      <ScheduleHeader
        dateHeader={`${formattedDates[0]} - ${formattedDates[2]}`}
        pageNumber={currentPage}
        onNext={handleNext}
        onPrev={handlePrevious}
        projectId={project.id}
      />
      <div className={clsx(styles.body, { [styles.mobileBody]: isMobile })}>
        {[0, 1, 2].map((index) => {
          const columnSlots = expertSlotsByDate.find(
            (columnSlot) =>
              columnSlot.date === dates[index].format(DATE_TIME_FORMAT)
          );
          return (
            <ScheduleColumn
              key={index}
              columnIndex={index}
              pageNumber={currentPage}
              project={project}
              isLoading={isScheduleAvailabilityListLoading}
              handleClickRow={handleClickRow}
              expertSlotsByDate={columnSlots}
              formattedDates={formattedDates}
              slotsNumber={columnSlots?.count}
            />
          );
        })}
      </div>
    </div>
  );
};
