import { User } from "@arbolus-technologies/models/project";

// Details
export const PROJECT_NAME = "name";
export const BRIEF = "description";
export const TIMEZONE = "timeZone";
export const EXPERT_COUNT = "expertCount";
export const MAIN_CONTACT = "mainContactUser";
export const CONTACT_NUMBER = "adminContactNo";
export const ACTIVATE_CHAT = "activateChat";
// Additional details
export const START_DATE = "startDate";
export const END_DATE = "endDate";
export const CASE_CODE = "caseCode";
// Ownership
export const MANAGER_NAME = "managerName";
export const MANAGER_EMAIL = "managerEmail";
export const PARTNER_NAME = "partnerName";
export const PARTNER_EMAIL = "partnerEmail";

export interface SimplifiedProjectEditFormValues {
  // Details
  [PROJECT_NAME]: string;
  [BRIEF]?: string;
  [TIMEZONE]: string;
  [MAIN_CONTACT]?: User;
  [CONTACT_NUMBER]?: string;
  // Additional details
  [START_DATE]: Date;
  [END_DATE]: Date | null;
  [CASE_CODE]?: string;
  // Ownership
  [MANAGER_NAME]?: string;
  [MANAGER_EMAIL]?: string;
  [PARTNER_NAME]?: string;
  [PARTNER_EMAIL]?: string;
  [ACTIVATE_CHAT]: boolean;
  [EXPERT_COUNT]: number;
}
