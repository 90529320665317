import { GetAdminUserProfileAction } from "../actions/CacheGetAdminUserProfile/CacheGetAdminUserProfileActionTypes";
import { CacheReducerState } from "../models/definitions";
import { initialState } from "./CacheInitialState";

export const handleGetUserProfile = (
  state = initialState,
  { payload }: GetAdminUserProfileAction
): CacheReducerState => ({
  ...state,
  userProfile: payload.userProfile
});
