import React from "react";
import { Alert as ReactstrapAlert } from "reactstrap";

import { CIQError, ErrorResponse } from "../../../models/api";

interface AlertProps {
  feedback: ErrorResponse<CIQError> | string;
  isSuccess?: boolean;
}

/**
 * @deprecated use ant design instead
 */
export default function Alert({ feedback, isSuccess = true }: AlertProps) {
  if (typeof feedback === "string") {
    return (
      <ReactstrapAlert color={isSuccess ? "success" : "danger"}>
        {feedback}
      </ReactstrapAlert>
    );
  }

  const { errors, message } = feedback;
  return (
    <ReactstrapAlert color={isSuccess ? "success" : "danger"}>
      {message ||
        errors.map((e) => <span key={`error_${e.status}`}>{e.message}</span>)}
    </ReactstrapAlert>
  );
}
