import i18next from "i18next";

import { CheckBoxOption } from "./types";

export const checkBoxesDefaultValues: CheckBoxOption[] = [
  {
    text: i18next.t("reportExpert:option1Text"),
    isSelected: false
  },
  {
    text: i18next.t("reportExpert:option2Text"),
    isSelected: false
  },
  {
    text: i18next.t("reportExpert:option3Text"),
    isSelected: false
  },
  {
    text: i18next.t("reportExpert:option4Text"),
    isSelected: false
  }
];

export const CLIENT_REPORT_EXPERT_SLACK_CHANNEL_URL =
  process.env.NX_PUBLIC_CLIENT_REPORT_EXPERT_SLACK_CHANNEL_URL;
