import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "arbolus-ui-components";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  CIQError,
  Compliance,
  DefaultToasterService,
  ErrorResponse,
  ExpertService,
  ToasterService
} from "@arbolus-technologies/api";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

import { ComplianceQuestion } from "./ComplianceQuestion/ComplianceQuestion";
import {
  ComplianceFormValues,
  ComplianceQuestionsSchema
} from "./ComplianceQuestionsSchema";

import styles from "./ComplianceQuestionsForm.module.scss";

interface ComplianceQuestionsFormProps {
  complianceQuestions: Compliance[];
  nextStepName: string;
  projectId: string;
  goToPrevStep: () => void;
  onAfterSubmit: () => void;
  expertService?: typeof ExpertService;
  notificationService?: ToasterService;
}

export const ComplianceQuestionsForm: React.FC<
  ComplianceQuestionsFormProps
> = ({
  complianceQuestions,
  nextStepName,
  projectId,
  goToPrevStep,
  onAfterSubmit,
  expertService = ExpertService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("expertApplications");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const expertId = useSelector(CacheSelector.loggedInUserExpertId());

  const methods = useForm<ComplianceFormValues>({
    // @ts-ignore
    resolver: yupResolver(ComplianceQuestionsSchema),
    mode: "all",
    defaultValues: {
      complianceAnswers: complianceQuestions.map((complianceQuestion) => ({
        questionId: complianceQuestion.questionId,
        answer: complianceQuestion.answer?.answer,
        textAnswer: complianceQuestion.answer?.textAnswer,
        textRequiredValue:
          complianceQuestion.question.displayModel.textRequiredValue
      }))
    }
  });

  const onSubmit = (values: ComplianceFormValues): void => {
    const answers = values.complianceAnswers.map((answer) => ({
      answer: answer.answer,
      questionId: answer.questionId,
      textAnswer: answer.textAnswer
    }));

    setIsLoading(true);

    expertService
      .addComplianceAnswers(expertId, {
        projectId,
        answers
      })
      .subscribe(
        () => {
          onAfterSubmit();
          setIsLoading(false);
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showError(error.message);
          setIsLoading(false);
        }
      );
  };

  return (
    <FormProvider {...methods}>
      {complianceQuestions.map((complianceQuestion, index) => (
        <ComplianceQuestion
          key={complianceQuestion.questionId}
          complianceQuestion={complianceQuestion}
          index={index}
        />
      ))}
      <div className={styles.btnContainer}>
        <Button
          type="tertiary"
          text={t("back")}
          onClick={goToPrevStep}
          disabled={isLoading}
        />
        <Button
          text={nextStepName}
          onClick={methods.handleSubmit(onSubmit)}
          disabled={isLoading || !methods.formState.isValid}
        />
      </div>
    </FormProvider>
  );
};
