import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { DiscoverFilterType } from "@arbolus-technologies/models/project";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";

import { useDiscoverExpertLocationFilter } from "../../../../Hooks/Discover/useDiscoverExpertLocationFilter";
import { useDiscoverFilters } from "../../../../Hooks/Discover/useDiscoverFilters";
import { FilterWrapper } from "../FilterWrapper/FilterWrapper";
import { SearchWithCheckboxPills } from "../SearchWithCheckboxPills/SearchWithCheckboxPills";

export const ExpertLocationFilter: React.FC = () => {
  const { t } = useTranslation("discoverPage");

  const {
    selectedOptions,
    setSelectedOptions,
    canClearFilter,
    setPreSelectedOptions,
    preSelectedOptions
  } = useDiscoverFilters({ filterType: DiscoverFilterType.Expert_Locations });
  const {
    handleClearQueryClicked,
    handleInputChangeEvent,
    handleSelectOption,
    filterOptions,
    applyExpertLocationFilter,
    urlChangeOnExpertLocationFilterUpdate,
    handleClearFilter
  } = useDiscoverExpertLocationFilter();

  const locationFilterQuery = useSelector(ProjectNxSelector.filtersQuery())[
    DiscoverFilterType.Expert_Locations
  ];

  return (
    <FilterWrapper
      title={t("ExpertLocationFilterTitle")}
      canClearFilter={canClearFilter}
      onClear={handleClearFilter}
      filterCounter={selectedOptions.length}
    >
      <SearchWithCheckboxPills
        onClearQueryClicked={handleClearQueryClicked}
        filterOptions={filterOptions}
        onInputChangeEvent={handleInputChangeEvent}
        searchQuery={locationFilterQuery}
        selectedOptions={selectedOptions}
        preSelectedOptions={preSelectedOptions}
        setSelectedOptions={setSelectedOptions}
        setPreSelectedOptions={setPreSelectedOptions}
        placeholder={t("locationFilterPlaceholder")}
        onBottomReached={() => false}
        onSelectOption={handleSelectOption}
        applyFilter={applyExpertLocationFilter}
        errorText={t("maxNumberLocations")}
        urlChangeOnFilterUpdate={urlChangeOnExpertLocationFilterUpdate}
        showFlagIcon
      />
    </FilterWrapper>
  );
};
