import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";

import { DeleteModal } from "../DeleteModal/DeleteModal";

import styles from "./AngleCardItem.module.scss";

interface AngleCardItemProps {
  angleData: {
    title: string;
    color: string;
    isCompleted: boolean;
    id: string;
  };

  disableDelete: boolean;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
}

export const AngleCardItem: React.FC<AngleCardItemProps> = ({
  angleData,
  onDelete,
  onEdit,
  disableDelete
}) => {
  const { t } = useTranslation("createAngleStep");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const showModal = () => setShowDeleteModal(true);
  const hideModal = () => setShowDeleteModal(false);

  const { title, color, isCompleted, id } = angleData;

  return (
    <Card className={styles.card}>
      <div className={styles.container}>
        <div className={styles.colorTitleContainer}>
          <span
            className={styles.angleColor}
            style={{ backgroundColor: color }}
          />
          <span>{title}</span>
        </div>
        {!isCompleted && (
          <div className={styles.incompleteContainer}>
            <span className={clsx(styles.icon, "ciq-info-solid")} />
            <span className={styles.text}>{t("incomplete")}</span>
          </div>
        )}
        <div className={styles.buttonsContainer}>
          <button className={styles.buttons} onClick={() => onEdit(id)}>
            <span className={clsx(styles.icon, "ciq-edit")} />
          </button>
          <button
            className={clsx(styles.buttons, disableDelete && styles.hideHover)}
            disabled={disableDelete}
            onClick={showModal}
          >
            <span className={clsx(styles.icon, "ciq-trash")} />
          </button>
        </div>
      </div>
      <DeleteModal
        toggleModal={showDeleteModal}
        messageTitle={t("titleModal")}
        warningMessage={t("messageModal")}
        deleteActionText={t("actionDeleteText")}
        onCancel={hideModal}
        onConfirm={() => {
          onDelete(id);
          hideModal();
        }}
      />
    </Card>
  );
};
