import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { CanopyParamUrlTypes } from "@arbolus-technologies/models/canopy";
import { ExpertProfile } from "@arbolus-technologies/models/canopy-panels";
import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { CanopyV2Selector } from "@arbolus-technologies/stores/canopy-V2";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import {
  Button,
  PublicCompanyBadge,
  UserPortrait
} from "@arbolus-technologies/ui/components";

import styles from "./ExpertDetails.module.scss";

interface ExpertDetailsHeaderProps {
  expert: ExpertProfile;
  showExpertCompliance: boolean;
}
export const ExpertDetailsHeader: React.FC<ExpertDetailsHeaderProps> = ({
  expert,
  showExpertCompliance
}) => {
  const dispatch = useDispatch();
  const { trackClick } = useArbolusTracking();
  const { t } = useTranslation("canopyV2");
  const canopyData = useSelector(CanopyV2Selector.canopyData());
  const isAdmin = useSelector(CacheSelector.isAdmin());
  const { expertId, canopyId } = useParams<CanopyParamUrlTypes>();

  const handleOpenProfile = () => {
    dispatch(
      PanelStoreActions.openPanel(PanelId.ExpertProfile, {
        expertId,
        projectId: canopyData?.projectId,
        canopyId
      })
    );
  };

  const handleOpenExpertFollowUpSlidePanel = (): void => {
    trackClick(MixPanelEventNames.CanopyExpertsAnswersFollowup, {
      canopyId,
      expertId
    });
    dispatch(
      PanelStoreActions.openPanel(PanelId.ExpertEngageWithMe, {
        ...expert,
        status: null
      })
    );
  };

  return (
    <div className={styles.expertTopContainer}>
      <div
        className={styles.expertPortraitWrapper}
        onClick={handleOpenProfile}
        onKeyDown={handleOpenProfile}
      >
        <UserPortrait
          onHoverTooltip
          user={{
            ...expert,
            status: showExpertCompliance ? expert.status : undefined
          }}
          icon={
            expert.lastPublicCompanyExpDate ? (
              <PublicCompanyBadge date={expert.lastPublicCompanyExpDate} />
            ) : undefined
          }
        />
      </div>
      <div className={styles.buttonsWrapper}>
        {!isAdmin &&
          expert.doNotContactStatus !== DO_NOT_CONTACT_STATUS.DNC && (
            <Button
              text={t("askMore")}
              endIcon="chevron_right"
              onClick={handleOpenExpertFollowUpSlidePanel}
            />
          )}
      </div>
    </div>
  );
};
