import { MutableRefObject, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IChatMessage, SORT_DIRECTION } from "@arbolus-technologies/api";
import {
  ProjectNxSelector,
  ProjectNxStoreActions
} from "@arbolus-technologies/stores/project";

import { useChat } from "../../Contexts/ChatContext/ChatContext";

export function useGetMessages(): {
  getNewChatMessages: () => void;
  chatMessages: IChatMessage[] | null;
  areMessagesLoading: boolean;
  initialFetchPending: boolean;
  messagesHaveNextPage: boolean;
  messageContainerRef: MutableRefObject<HTMLDivElement | undefined>;
} {
  const dispatch = useDispatch();
  const { projectId, currentChatId } = useChat();
  const messageContainerRef = useRef<HTMLDivElement>();
  const chatMessages = useSelector(ProjectNxSelector.chatMessages());
  const areMessagesLoading = useSelector(
    ProjectNxSelector.chatMessagesLoading()
  );
  const messagesHaveNextPage = useSelector(
    ProjectNxSelector.chatMessagesHasNextPage()
  );
  const initialFetchPending = useSelector(
    ProjectNxSelector.isGetMessagesInitialFetch()
  );

  const getNewChatMessages = useCallback((): void => {
    if (!projectId || !currentChatId) return;
    dispatch(
      ProjectNxStoreActions.getChatMessages(projectId, currentChatId, false, {
        limit: 10,
        offset: chatMessages?.length ?? 0,
        orderBy: "Created",
        orderDirection: SORT_DIRECTION.DESCENDING
      })
    );
  }, [chatMessages?.length, currentChatId, dispatch, projectId]);

  return {
    getNewChatMessages,
    chatMessages,
    areMessagesLoading,
    initialFetchPending,
    messagesHaveNextPage,
    messageContainerRef
  };
}
