import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Observable } from "rxjs";

import {
  CIQError,
  DefaultToasterService,
  Document,
  ErrorResponse,
  PagedListResponse,
  PaginatedRequest,
  SORT_DIRECTION,
  ToasterService
} from "@arbolus-technologies/api";
import { DownloadDocumentBox } from "@arbolus-technologies/features/common";
import { PROJECT_FILES_ROUTE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  ExpertProjectEmptyDocuments,
  LoaderOrComponent,
  ProjectEmptyDocuments
} from "@arbolus-technologies/ui/components";
import { GrayBox } from "@arbolus-technologies/ui/layout";

import { MoreDocuments } from "../../Components/MoreDocuments/MoreDocuments";
import { UploadDocument } from "../../Components/UploadDocument/UploadDocument";

import styles from "./ProjectDocuments.module.scss";

interface ProjectDocumentsProps {
  projectId: string;
  hideUpload?: boolean;
  getDocuments: (
    queryParams: PaginatedRequest<"created">
  ) => Observable<PagedListResponse<Document>>;
  notificationService?: ToasterService;
}

const queryParams: PaginatedRequest<"created"> = {
  limit: 6,
  offset: 0,
  orderBy: "created",
  orderDirection: SORT_DIRECTION.DESCENDING
};

export const ProjectDocuments: React.FC<ProjectDocumentsProps> = ({
  projectId,
  hideUpload = false,
  getDocuments,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("projectDocuments");

  const [documents, setDocuments] = useState<Document[]>([]);
  const [count, setCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const isExpert = useSelector(CacheSelector.loggedInUserExpertId());

  const onDocumentUploaded = useCallback(
    (id: string, fileName: string) => {
      setCount((prev) => prev + 1);
      if (count > 6) {
        return;
      }

      if (count === 6) {
        setDocuments((prev) => prev.slice(0, 5));
        return;
      }

      setDocuments((prev) => [...prev, { fileName, id } as Document]);
    },
    [count]
  );

  const fetchDocuments = useCallback(() => {
    setIsLoading(true);
    getDocuments(queryParams).subscribe(
      (response) => {
        const {
          pagination: { count }
        } = response;
        setCount(count);
        if (count < 7) {
          setDocuments(response.items);
        } else {
          setDocuments(response.items.slice(0, 5));
        }
        setIsLoading(false);
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
        setIsLoading(false);
      }
    );
  }, [notificationService, getDocuments]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  return (
    <GrayBox
      title={{
        text: t("title"),
        to: PROJECT_FILES_ROUTE(projectId)
      }}
      flexDirection="column"
      gap={[2, 0]}
      fullWidth={!isExpert}
      actionComponent={
        hideUpload ? null : (
          <UploadDocument
            key={count}
            projectId={projectId}
            onDocumentUploaded={onDocumentUploaded}
          />
        )
      }
    >
      <LoaderOrComponent isLoading={isLoading}>
        {!documents.length && !isExpert && <ProjectEmptyDocuments />}
        {!documents.length && isExpert && <ExpertProjectEmptyDocuments />}
        {documents.length > 0 && (
          <div
            className={clsx(styles.documentsContainer, {
              [styles.isExpert]: isExpert
            })}
          >
            {documents?.map((document) => (
              <DownloadDocumentBox
                key={document.id}
                id={document.id}
                projectId={projectId}
                name={document.fileName}
              />
            ))}
            {count > 6 && (
              <MoreDocuments count={count - 5} projectId={projectId} />
            )}
          </div>
        )}
      </LoaderOrComponent>
    </GrayBox>
  );
};
