import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { CALL_STATUSES, ProjectStats } from "@arbolus-technologies/api";
import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import {
  PROJECT_ALL_REFERRALS,
  PROJECT_ALL_REFERRALS_REVIEW,
  PROJECT_APPROVED_REFERRALS,
  PROJECT_BOOK,
  PROJECT_DISCOVER_ROUTE
} from "@arbolus-technologies/routes";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  BookButton,
  CallsButton,
  LinkBoxButton,
  LoaderOrComponent,
  ReviewAllReferralsButton
} from "@arbolus-technologies/ui/components";
import { GrayBox } from "@arbolus-technologies/ui/layout";

import styles from "./QuickActionsMenu.module.scss";

interface ClientActionsMenuProps {
  projectId: string;
  projectStats: ProjectStats;
  expertsCount: number;
  isLoading: boolean;
}

export const ClientActionsMenu: React.FC<ClientActionsMenuProps> = ({
  projectId,
  projectStats,
  expertsCount,
  isLoading
}) => {
  const { t } = useTranslation("clientActionsMenu");
  const { referralsUrl, referralsEventName } = useMemo(
    () =>
      projectStats && projectStats.reviewCount > 0
        ? {
            referralsUrl: PROJECT_ALL_REFERRALS_REVIEW(projectId),
            referralsEventName: MixPanelEventNames.ReviewExpertReferral
          }
        : {
            referralsUrl: PROJECT_ALL_REFERRALS(projectId),
            referralsEventName: MixPanelEventNames.AllReferrals
          },
    [projectStats, projectId]
  );
  const bookUrl = PROJECT_BOOK(projectId);
  const callsUrl = PROJECT_APPROVED_REFERRALS(
    projectId,
    CALL_STATUSES.CALL_DONE
  );
  const { trackRedirect } = useArbolusTracking();

  return (
    <GrayBox fullWidth>
      <LoaderOrComponent isLoading={isLoading}>
        <div className={styles.container}>
          <LinkBoxButton
            text={t("findExpertsButton")}
            to={PROJECT_DISCOVER_ROUTE(projectId)}
            icon={{
              color: ARBOLUS_COLORS.bColorBaseWhite,
              background: ARBOLUS_COLORS.bColorBasePurple,
              name: "search"
            }}
            subTitle={t("findExpertsButtonTitle", {
              amount: expertsCount
            })}
          />
          <ReviewAllReferralsButton
            itemCount={projectStats?.reviewCount || 0}
            to={referralsUrl}
            onClick={() => trackRedirect(referralsEventName, referralsUrl)}
          />
          <BookButton
            itemCount={projectStats?.availableTimeSlotsCount || 0}
            to={bookUrl}
            onClick={() => trackRedirect(MixPanelEventNames.Book, bookUrl)}
          />
          <CallsButton
            itemCount={projectStats?.callsDoneCount || 0}
            to={callsUrl}
            onClick={() =>
              trackRedirect(MixPanelEventNames.ApprovedReferrals, callsUrl)
            }
          />
        </div>
      </LoaderOrComponent>
    </GrayBox>
  );
};
