import React from "react";
import { useTranslation } from "react-i18next";

import { ResponseAllExpertsTable } from "@arbolus-technologies/api";

interface StatusCallProps {
  row: ResponseAllExpertsTable;
}

export const StatusCall: React.FC<StatusCallProps> = ({
  row: { callStatus }
}) => {
  const { t } = useTranslation("statusCall");
  return <div>{callStatus ? t(callStatus.toLowerCase()) : ""}</div>;
};
