import { Button } from "arbolus-ui-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { SlidePanel } from "@arbolus-technologies/features/common";
import { AngleModel } from "@arbolus-technologies/models/common";
import { PanelId } from "@arbolus-technologies/stores/panels";
import { SearchInput } from "@arbolus-technologies/ui/components";

import { AnglesList } from "../../../AnglesList/AnglesList";

import styles from "./WorkWithMePanel.module.scss";

interface WorkWithMePanelProps {
  onConfirm: (selectedAngle: AngleModel) => void;
  isModalOpen: boolean;
  togglePanel: () => void;
  angles: AngleModel[];
}

export const WorkWithMePanel: React.FC<WorkWithMePanelProps> = ({
  onConfirm,
  isModalOpen,
  togglePanel,
  angles
}) => {
  const { t } = useTranslation("discoverPage");
  const [filteredAngles, setFilteredAngles] = useState<AngleModel[]>(angles);
  const [selectedAngle, setSelectedAngle] = useState<AngleModel>();

  const handleCloseWorkWithMePanel = () => {
    setSelectedAngle(undefined);
    togglePanel();
  };

  const handleConfirm = () => {
    handleCloseWorkWithMePanel();
    onConfirm(selectedAngle!);
  };

  const filterAngles = (query: string) => {
    setFilteredAngles(angles.filter((angle) => angle.title.includes(query)));
  };

  return (
    <SlidePanel
      panelId={PanelId.DiscoverWorkWithMe}
      title={t("workWithMePanelTitle")}
      customCloseRequest={handleCloseWorkWithMePanel}
      isMapped
      isOpenInternally={isModalOpen}
      closeButtonDirection="right"
    >
      <div className={styles.panelBody}>
        <SearchInput onQueryChange={filterAngles} hasSearchIcon />

        <div className={styles.angles}>
          <AnglesList
            onAngleClick={setSelectedAngle}
            angles={filteredAngles}
            selectedAngles={[selectedAngle?.id ?? ""]}
          />
        </div>

        <div className={styles.footer}>
          <Button
            text={t("workWithMePanelConfirmButton")}
            onClick={handleConfirm}
            disabled={!selectedAngle}
          />
        </div>
      </div>
    </SlidePanel>
  );
};
