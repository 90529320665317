import { Drawer } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { Company } from "@arbolus-technologies/api";

import { CompanyPanelBody } from "./CompanyPanelBody/CompanyPanelBody";

interface CompanySelectionPanelProps {
  company?: Company | null;
  onCompanySelected: (company: Company, rowId?: string) => void;
  newCompanyUrl?: string;
  isOpen: boolean;
  handleClose: () => void;
}

export const CompanySelectionPanel: React.FC<CompanySelectionPanelProps> = ({
  company,
  onCompanySelected,
  newCompanyUrl,
  isOpen,
  handleClose
}) => {
  const { t } = useTranslation("companySelectionPanel");

  return (
    <Drawer
      title={t("title")}
      onClose={handleClose}
      open={isOpen}
      destroyOnClose
    >
      <CompanyPanelBody
        onSelectCompany={(company) => {
          onCompanySelected(company);
          handleClose();
        }}
        company={company ?? null}
        newCompanyUrl={newCompanyUrl}
      />
    </Drawer>
  );
};
