import React, { useEffect, useState } from "react";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ProjectService,
  ToasterService
} from "@arbolus-technologies/api";
import { PipelineExpertHistories } from "@arbolus-technologies/models/expert";
import { LoaderOrComponent } from "@arbolus-technologies/ui/components";
import { PipelineExpertHistory } from "@arbolus-technologies/ui/pipeline";

import styles from "./ExternalExpertHistories.module.scss";

interface ExternalExpertHistoriesProps {
  projectPipelineId: string;
  projectService?: typeof ProjectService;
  notificationService?: ToasterService;
}

export const ExternalExpertHistories: React.FC<
  ExternalExpertHistoriesProps
> = ({
  projectPipelineId,
  projectService = ProjectService,
  notificationService = DefaultToasterService
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [externalExpertHistory, setExternalExpertHistory] =
    useState<PipelineExpertHistories | null>(null);

  useEffect(() => {
    setIsLoading(true);
    projectService.getProjectPipelineExpertHistory(projectPipelineId).subscribe(
      (response) => {
        setExternalExpertHistory(response);
        setIsLoading(false);
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showError(error.message);
        setIsLoading(false);
      }
    );
  }, [notificationService, projectPipelineId, projectService]);

  return (
    <LoaderOrComponent isLoading={isLoading}>
      {externalExpertHistory && (
        <div className={styles.container}>
          {externalExpertHistory.shortlistedDate && (
            <PipelineExpertHistory
              pipelineExpertHistory={{
                initialStatus: "shortlisted",
                changedByUser: externalExpertHistory.shortlistedByUser,
                changedDate: externalExpertHistory.shortlistedDate
              }}
            />
          )}
          {externalExpertHistory.pipelineStatusChanges.map(
            (externalExpertHistory) => (
              <PipelineExpertHistory
                key={externalExpertHistory.changedDate}
                pipelineExpertHistory={externalExpertHistory}
              />
            )
          )}
          <PipelineExpertHistory
            pipelineExpertHistory={{
              initialStatus: "sourced",
              changedByUser: externalExpertHistory.sourcedByUser,
              changedDate: externalExpertHistory.sourcedDate
            }}
          />
        </div>
      )}
    </LoaderOrComponent>
  );
};
