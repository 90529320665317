import React from "react";

import { RecommendationResponse } from "@arbolus-technologies/api";
import { TanstackTable } from "@arbolus-technologies/ui/components";

import { recommendationsTableDefinition } from "./recommendationsTableDefinition";

import styles from "./RecommendationsTable.module.scss";

const tableClasses = {
  head: { th: styles.gridHeader },
  body: { td: styles.gridItem, firstTd: styles.firstInRow },
  noData: styles.noData
};

interface RecommendationsTableProps {
  recommendations: RecommendationResponse[];
  handleClickRow: (
    cellId: string,
    recommendation: RecommendationResponse
  ) => void;
  projectId: string;
}

export const RecommendationsTable: React.FC<RecommendationsTableProps> = ({
  recommendations,
  handleClickRow,
  projectId
}) => {
  const { columnDefinitions, gridTemplate } = recommendationsTableDefinition;

  return (
    <div className={styles.tableContainer}>
      <TanstackTable
        defaultData={recommendations}
        columnDefinitions={columnDefinitions}
        classnames={tableClasses}
        gridTemplate={gridTemplate}
        handleClickRow={handleClickRow}
        cellProps={{ projectId }}
      />
    </div>
  );
};
