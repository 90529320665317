import i18next from "i18next";

import { BaseCanopyNotification } from "./BaseCanopyNotification";

export class CanopyLaunchedNotification extends BaseCanopyNotification {
  title: string;

  constructor(
    id: string,
    canopyId: string,
    canopyName: string,
    created: string
  ) {
    super(id, canopyId, created);
    this.title = i18next.t("dashboardNotifications:canopyLaunched:title", {
      canopyName
    });
  }
}
