/* eslint-disable react/no-array-index-key */
import { PureComponent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { Slot } from "@arbolus-technologies/api";
import { ExpertRates } from "@arbolus-technologies/features/common";
import {
  DO_NOT_CONTACT_STATUS,
  ExpertRate
} from "@arbolus-technologies/models/common";
import {
  ExpertLinkedin,
  TimeSlotSection
} from "@arbolus-technologies/ui/components";

import { Endorsement, ExpertWorkHistory } from "../../../../../models/expert";
import { UtilsService } from "../../../../../services";
import CollapseTimeline from "../../../../project/components/panels/expertProfile/CollapseTimeline";
import ExpertEndorsements from "./ExpertEndorsements";

interface ExpertProfilePreviewProps {
  endorsements?: Endorsement[];
  background?: string;
  linkedInUrl?: string;
  workHistory?: ExpertWorkHistory[];
  uiExpertRate?: ExpertRate;
  availabilitySlots?: Slot[];
  renderAvailabilityTab?: () => void;
  hideChooseTimeSlots: boolean;
  displayPublicCompanies: boolean;
  doNotContactStatus: DO_NOT_CONTACT_STATUS;
}

type ExpertProfileIntersectProps = ExpertProfilePreviewProps & WithTranslation;

class ExpertProfilePreview extends PureComponent<
  ExpertProfileIntersectProps,
  {}
> {
  render(): JSX.Element {
    const { t } = this.props;
    const {
      endorsements,
      background,
      linkedInUrl,
      workHistory,
      uiExpertRate,
      availabilitySlots,
      renderAvailabilityTab,
      hideChooseTimeSlots,
      displayPublicCompanies,
      doNotContactStatus
    } = this.props;
    const isEndorsementExist = !!endorsements?.length;
    const isWorkHistoryExist = !!workHistory?.length;

    return (
      <div className="expert-referral-container">
        <div className="containerSection1">
          {!hideChooseTimeSlots && (
            <TimeSlotSection
              availabilitySlots={availabilitySlots}
              renderAvailabilityTab={renderAvailabilityTab}
              doNotContactStatus={doNotContactStatus}
            />
          )}
          {uiExpertRate && (
            <div className="expert-rates-container">
              <ExpertRates expertRate={uiExpertRate} />
            </div>
          )}
        </div>
        {isEndorsementExist && (
          <ExpertEndorsements endorsements={endorsements} />
        )}
        {(isWorkHistoryExist || background) && (
          <section>
            {isWorkHistoryExist && (
              <div className="expert-referral-timeline">
                <CollapseTimeline
                  timeline={workHistory}
                  displayPublicCompanies={displayPublicCompanies}
                />
              </div>
            )}
            {background && (
              <div className="expert-referral-background">
                <h2>{t("background")}</h2>
                <div className="rich-content">
                  {UtilsService.parseRichTextContent(background)}
                </div>
              </div>
            )}
          </section>
        )}
        <ExpertLinkedin linkedInUrl={linkedInUrl} />
      </div>
    );
  }
}

export default withTranslation("expertProfilePage")(ExpertProfilePreview);
