/* eslint-disable import/extensions */
/* eslint-disable no-bitwise */
import { EMPTY, Observable, Subject, of } from "rxjs";
import { switchMap } from "rxjs/operators";

import { restService } from "@arbolus-technologies/api";

import {
  DOCUMENTS_API,
  PROJECT_CLIENTS_API,
  PROJECT_EXPERTS_API
} from "../constants/api";
import { DOCUMENT_NAME_SANITIZE_REGEX } from "../constants/validation";
import {
  ApiDeletedResponse,
  ApiNonPaginatedResponse,
  ApiPaginatedRequest,
  ApiPaginatedResponse,
  ApiSuccessResponse
} from "../models/api";
import {
  Document,
  DocumentDownloadResponse,
  DocumentStatusChangeRequest,
  DocumentStatusChangeResponse,
  ExpertDocument
} from "../models/documents";

export const documentService = {
  uploadFile: (
    endpointURL: string,
    file: FormData,
    uploadSubject?: Subject<number>
  ): Observable<Document> =>
    restService.post(endpointURL, file, undefined, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: (progressEvent: ProgressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (uploadSubject) {
          uploadSubject.next(percentCompleted);
        } else {
          of(0);
        }
      }
    }),
  downloadDocument: (
    projectId: string,
    documentId: string
  ): Observable<DocumentDownloadResponse> =>
    restService
      .get<DocumentDownloadResponse>(
        DOCUMENTS_API.GET_DOCUMENT_DOWNLOAD_URL(projectId, documentId)
      )
      .pipe(switchMap((document: DocumentDownloadResponse) => of(document))),
  downloadCanopyDocument: (downloadUrlDocument: string): Observable<never> =>
    restService.get<DocumentDownloadResponse>(downloadUrlDocument).pipe(
      switchMap(({ downloadUrl }: DocumentDownloadResponse) => {
        window.location.href = downloadUrl;
        return EMPTY;
      })
    ),
  getClientDocumentStatus: (
    projectId: string,
    documentId: string
  ): Observable<ApiNonPaginatedResponse<ExpertDocument>> =>
    restService.get<ApiNonPaginatedResponse<ExpertDocument>>(
      PROJECT_CLIENTS_API.GET_DOCUMENT_STATUS(projectId, documentId)
    ),
  getExpertDocumentStatus: (
    projectId: string,
    documentId: string
  ): Observable<ApiNonPaginatedResponse<ExpertDocument>> =>
    restService.get<ApiNonPaginatedResponse<ExpertDocument>>(
      PROJECT_EXPERTS_API.GET_DOCUMENT_STATUS(projectId, documentId)
    ),
  getClientDocuments: (
    projectId: string,
    apiParams: ApiPaginatedRequest
  ): Observable<ApiPaginatedResponse<Document>> =>
    restService.get<ApiPaginatedResponse<Document>>(
      PROJECT_CLIENTS_API.GET_DOCUMENTS(projectId),
      apiParams
    ),
  getExpertDocuments: (
    projectId: string,
    apiParams: ApiPaginatedRequest
  ): Observable<ApiPaginatedResponse<Document>> =>
    restService.get<ApiPaginatedResponse<Document>>(
      PROJECT_EXPERTS_API.GET_DOCUMENTS(projectId),
      apiParams
    ),
  getDocument: (projectId: string, documentId: string): Observable<Document> =>
    restService.get(DOCUMENTS_API.GET_DOCUMENT(projectId, documentId)),
  shareDocument: (
    projectId: string,
    chatId: string,
    updateRequest: DocumentStatusChangeRequest
  ): Observable<DocumentStatusChangeResponse> =>
    restService.post<DocumentStatusChangeResponse>(
      DOCUMENTS_API.UPDATE_EXPERTS_DOCUMENT_STATUS(projectId, chatId),
      updateRequest
    ),
  renameDocument: (
    projectId: string,
    documentId: string,
    fileName: string
  ): Observable<ApiSuccessResponse> =>
    restService.put(DOCUMENTS_API.RENAME_DOCUMENT_URL(projectId, documentId), {
      fileName
    }),
  deleteDocument: (
    projectId: string,
    documentId: string
  ): Observable<ApiDeletedResponse> =>
    restService.delete(DOCUMENTS_API.DELETE_DOCUMENT(projectId, documentId)),
  prettifyFileName: (fileName: string): string =>
    fileName.slice(0, fileName.indexOf(".")),
  prettifyFileSize: (fileSize: number): string => {
    const i =
      fileSize === 0 ? 0 : Math.floor(Math.log(fileSize) / Math.log(1024));
    return `${(fileSize / 1024 ** i).toFixed(2)} ${
      ["B", "KB", "MB", "GB", "TB"][i]
    }`;
  },
  sanitizeFileName: (fileName: string): string => {
    const lastIndex = fileName.lastIndexOf(".");
    let extension = "";
    let sanitizedName = fileName;
    if (lastIndex > -1) {
      extension = fileName.slice(lastIndex + 1).toLowerCase();
      sanitizedName = fileName.slice(0, lastIndex);
    }

    sanitizedName = sanitizedName.replace(
      new RegExp(DOCUMENT_NAME_SANITIZE_REGEX, "g"),
      ""
    );

    // Assign random name
    if (sanitizedName.length === 0) {
      sanitizedName = extension ? extension.toUpperCase() : "File";
    }

    return `${sanitizedName}${extension ? `.${extension}` : ""}`;
  }
};
