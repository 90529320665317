import React from "react";

import hired from "../../assets/icons/expert-hired.svg";
import rejected from "../../assets/icons/expert-reject.svg";

import styles from "./IconButtonHand.module.scss";

interface IconButtonHandProps {
  handleOnApprove: () => void;
  handleOnReject: () => void;
}

export const IconButtonHand: React.FC<IconButtonHandProps> = ({
  handleOnApprove,
  handleOnReject
}) => (
  <div className={styles.container}>
    <button className={styles.iconApprove} onClick={handleOnApprove}>
      <img src={hired} alt="" />
    </button>
    <button className={styles.iconReject} onClick={handleOnReject}>
      <img src={rejected} alt="" />
    </button>
  </div>
);
