// Sort Directions
export enum SORT_DIRECTION {
  ASCENDING = "asc",
  DESCENDING = "desc"
}

// Error Codes
export const REST_ERROR = {
  REQUEST_CANCELED: "REQUEST_CANCELED",
  NETWORK_ERROR: "NETWORK_ERROR",
  INTERNAL_ERROR: "INTERNAL_ERROR",
  UNSUPPORTED_FILE_TYPE: "1044"
};

// Order By in Clients
export const ORDER_BY = {
  NAME: "Name",
  FIRST_NAME: "FirstName"
};

// Order By in Ownerships
export const OWNERSHIP_ORDER_BY = {
  USER_NAME: "userName",
  CLIENT_NAME: "clientName"
};
