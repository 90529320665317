import { createMatchSelector } from "connected-react-router";
import { matchPath } from "react-router";
import { OutputSelector, createSelector } from "reselect";

import { CANOPY_DETAILS } from "@arbolus-technologies/routes";

import { AuthRouteConstants } from "../../../constants";
import {
  DRAFT_PROJECT,
  EDIT_USER,
  ENDORSEMENT,
  NEW_PROJECT,
  NEW_USER,
  WELCOME
} from "../../../constants/navigation/authRoutes";
import {
  CANOPY_LAUNCH_SURVEY,
  CANOPY_MAIN_PAGE,
  CANOPY_PAUSED
} from "../../../constants/navigation/canopyRoutes";
import {
  EDIT_PROJECT,
  PROJECT_BASE,
  PROJECT_CALENDAR,
  PROJECT_EVENT_TRANSCRIPT,
  PROJECT_EXPERTS,
  PROJECT_FILES,
  PROJECT_NEW_EVENT,
  PROJECT_REFER_EXPERT,
  PROJECT_TRANSCRIPTS
} from "../../../constants/navigation/projectRoutes";
import {
  PROJECT_BASE_TAB,
  PROJECT_CALENDAR_TAB,
  PROJECT_EVENT_TAB,
  PROJECT_FILES_TAB
} from "../../../constants/navigation/projectTabRoutes";
import { PUBLIC_ROUTES } from "../../../constants/navigation/publicRoutes";
import { AppState } from "../../../store/reducers";

const projectBaseMatchSelector = createMatchSelector<AppState, {}>(
  PROJECT_BASE
);

const projectCalendarMatchSelector = createMatchSelector<AppState, {}>(
  PROJECT_CALENDAR
);

const projectFilesMatchSelector = createMatchSelector<AppState, {}>(
  PROJECT_FILES
);

const projectNewEventMatchSelector = createMatchSelector<AppState, {}>(
  PROJECT_NEW_EVENT
);

const navigationBarVisibleSelector = (): OutputSelector<
  AppState,
  boolean,
  (res: AppState) => boolean
> =>
  createSelector<AppState, AppState, boolean>(
    (state: AppState) => state,
    (appState: AppState) => {
      // collapse navbar on  full screen pages
      const isFullScreenRoutes = matchPath(appState.router.location.pathname, {
        path: [
          NEW_PROJECT,
          PROJECT_NEW_EVENT,
          EDIT_PROJECT,
          NEW_USER,
          EDIT_USER,
          WELCOME,
          ENDORSEMENT,
          PROJECT_REFER_EXPERT,
          PROJECT_EVENT_TRANSCRIPT,
          PROJECT_TRANSCRIPTS,
          DRAFT_PROJECT,
          CANOPY_MAIN_PAGE,
          CANOPY_DETAILS,
          CANOPY_LAUNCH_SURVEY,
          CANOPY_PAUSED,
          ...[PROJECT_EXPERTS]
        ]
      });

      if (isFullScreenRoutes) {
        return false;
      }

      // collapse navbar on invalid routes
      const isAppRoute = matchPath(appState.router.location.pathname, {
        path: [...AuthRouteConstants.AUTH_ROUTES, ...PUBLIC_ROUTES],
        exact: true
      });
      if (!isAppRoute) {
        return false;
      }

      return true;
    }
  );

const navigationHeaderVisibleSelector = (): OutputSelector<
  AppState,
  boolean,
  (res: AppState) => boolean
> =>
  createSelector<AppState, AppState, boolean>(
    (state: AppState) => state,
    (appState: AppState) => {
      // exclude full screen pages
      const isFullScreenRoutes = matchPath(appState.router.location.pathname, {
        path: [
          DRAFT_PROJECT,
          NEW_PROJECT,
          PROJECT_NEW_EVENT,
          EDIT_PROJECT,
          NEW_USER,
          EDIT_USER,
          PROJECT_REFER_EXPERT,
          PROJECT_EVENT_TRANSCRIPT,
          PROJECT_TRANSCRIPTS,
          CANOPY_MAIN_PAGE,
          CANOPY_DETAILS,
          CANOPY_LAUNCH_SURVEY
        ]
      });
      if (isFullScreenRoutes) {
        return false;
      }

      return true;
    }
  );

const navigationActiveProjectTabSelector = (): OutputSelector<
  AppState,
  string,
  (res: AppState) => string
> =>
  createSelector<AppState, AppState, string>(
    (state: AppState) => state,
    (appState) => {
      if (projectCalendarMatchSelector(appState)) return PROJECT_CALENDAR_TAB;
      if (projectFilesMatchSelector(appState)) return PROJECT_FILES_TAB;
      if (projectNewEventMatchSelector(appState)) return PROJECT_EVENT_TAB;
      if (projectBaseMatchSelector(appState)?.isExact) return PROJECT_BASE_TAB;
      return "";
    }
  );

export {
  navigationActiveProjectTabSelector,
  navigationBarVisibleSelector,
  navigationHeaderVisibleSelector
};
