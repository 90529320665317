import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  DefaultToasterService,
  ToasterService,
  WebHooksService
} from "@arbolus-technologies/api";

import {
  InternalArbolusModal,
  InternalArbolusModalProps
} from "../InternalArbolusModal";
import {
  AdminReportExpertForm,
  GetAdminReportExpertSchema
} from "./ArbolusModalAdminReportExpertSchema";
import { ModalBody } from "./ModalBody";

const ADMIN_REPORT_EXPERT_SLACK_CHANNEL_URL =
  process.env.NX_PUBLIC_ADMIN_REPORT_EXPERT_SLACK_CHANNEL_URL;

type ArbolusModalAdminReportExpertProps = Omit<
  InternalArbolusModalProps,
  "subtitle" | "title" | "rightButton"
> & {
  email: string;
  expertName?: string;
  expertId?: string;
  notificationService?: ToasterService;
  webHooksService?: typeof WebHooksService;
};

export const ArbolusModalAdminReportExpert: React.FC<
  ArbolusModalAdminReportExpertProps
> = ({
  isOpen,
  toggle,
  email,
  expertName,
  expertId,
  notificationService = DefaultToasterService,
  webHooksService = WebHooksService
}) => {
  const { t } = useTranslation("reportExpert");

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    clearErrors,
    reset
  } = useForm<AdminReportExpertForm>({
    mode: "all",
    reValidateMode: "onSubmit",
    // @ts-ignore
    resolver: yupResolver(GetAdminReportExpertSchema),
    defaultValues: {
      feedbackText: ""
    }
  });

  const handleCancel = () => {
    clearErrors();
    reset();
    toggle();
  };

  const getSlackMessage = (feedbackText: string): string =>
    t("adminReportedExpert", {
      expertName,
      linkToProfile: `${process.env.NX_PUBLIC_ADMIN_BASE_URL}/expert/${expertId}`,
      reporter: email,
      feedbackText
    });

  const onSubmit = (data: AdminReportExpertForm) => {
    const feedbackText = data.feedbackText;
    const message = getSlackMessage(feedbackText);

    setIsSubmitting(true);
    webHooksService
      .sendSlackMessage(message, ADMIN_REPORT_EXPERT_SLACK_CHANNEL_URL)
      .subscribe(
        () => {
          notificationService.showSuccess(t("reportSuccess"));
        },
        () => {
          notificationService.showError(t("reportFailure"));
        },
        () => {
          setIsSubmitting(false);
          handleCancel();
        }
      );
  };

  return (
    <InternalArbolusModal
      title={t("reportExpertTitle")}
      body={<ModalBody control={control} errors={errors} />}
      isOpen={isOpen}
      toggle={toggle}
      leftButton={{
        text: t("cancel"),
        onClick: handleCancel,
        type: "tertiary"
      }}
      rightButton={{
        text: t("reportExpert"),
        type: "rejection",
        onClick: handleSubmit(onSubmit),
        disabled: !isDirty || !isValid || isSubmitting
      }}
    />
  );
};
