import { Button } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { Elevation } from "@arbolus-technologies/theme";

interface ModalWithChildrenProps {
  onCancel: () => void;
  onConfirm: () => void;
  toggleModal: boolean;
  confirmActionText?: string;
  cancelActionText?: string;
  children: JSX.Element;
  messageTitle?: string;
  onConfirmButtonColor?: boolean;
  isConfirmButtonDisabled?: boolean;
  className?: string;
  customModalBody?: boolean;
}

/**
 * @deprecated
 * use ArbolusModal from @arbolus-technologies/libs/ui/components
 */

export const ModalWithChildren: React.FC<ModalWithChildrenProps> = ({
  onCancel,
  onConfirm,
  toggleModal,
  messageTitle,
  children,
  confirmActionText,
  cancelActionText,
  isConfirmButtonDisabled,
  className,
  customModalBody = false
}) => {
  const { t } = useTranslation("modalWithChildren");
  return (
    <Modal
      isOpen={toggleModal}
      className={clsx("modal-alert", className ?? null)}
      zIndex={Elevation.Z_INDEX_TOAST}
    >
      {messageTitle && <ModalHeader>{messageTitle}</ModalHeader>}
      {customModalBody ? children : <ModalBody>{children}</ModalBody>}
      <ModalFooter>
        <Button
          onClick={onCancel}
          text={cancelActionText || t("cancel")}
          type="secondary"
        />
        <Button
          onClick={(): void => onConfirm()}
          text={confirmActionText || t("confirm")}
          type="primary"
          disabled={isConfirmButtonDisabled}
        />
      </ModalFooter>
    </Modal>
  );
};
