import clsx from "clsx";
import React from "react";

import styles from "./ColorCircle.module.scss";

export interface ColorCircleProps {
  color: "green" | "amber" | "red" | "grey" | "orange";
  size: number;
}

export const ColorCircle: React.FC<ColorCircleProps> = ({ color, size }) => (
  <div
    className={clsx(styles.circle, styles[color])}
    style={{ maxHeight: size, maxWidth: size, minWidth: size, minHeight: size }}
  />
);
