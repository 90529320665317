import { useFeature } from "flagged";
import queryString from "query-string";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import { FEATURE_FLAGS } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  ProjectNxSelector,
  ProjectNxStoreActions
} from "@arbolus-technologies/stores/project";
import { ArbolusModal } from "@arbolus-technologies/ui/components";

import { ClearButton } from "../../Components/Discover/Filters/ClearButton/ClearButton";
import { CompaniesFilter } from "../../Components/Discover/Filters/CompaniesFilter/CompaniesFilter";
import { CustomersFilter } from "../../Components/Discover/Filters/CustomersFilter/CustomersFilter";
import { ExpertLocationFilter } from "../../Components/Discover/Filters/ExpertLocationFilter/ExpertLocationFilter";
import { KeywordsFilter } from "../../Components/Discover/Filters/KeywordsFilter/KeywordsFilter";
import { ProjectsFilter } from "../../Components/Discover/Filters/ProjectsFilter/ProjectsFilter";
import { WorkHistoryLocationFilter } from "../../Components/Discover/Filters/WorkHistoryLocationFilter/WorkHistoryLocationFilter";

import styles from "./DiscoverFilters.module.scss";

export const SEARCH_LIMIT = 40;

export const DiscoverFilters: React.FC<{ withoutHeader?: boolean }> = ({
  withoutHeader = false
}) => {
  const { t } = useTranslation("discoverPage");
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const expertSearchCustomerFilterFeatureFlag = useFeature(
    FEATURE_FLAGS.ExpertSearchCustomerFilter
  );

  const parameters = queryString.parse(location.search);
  const { query = "" }: { query?: string } = parameters;

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);

  const discoverFilters = useSelector(ProjectNxSelector.discoverFilters());
  const isAdmin = useSelector(CacheSelector.isAdmin());

  const appliedFiltersNumber = Object.values(discoverFilters).reduce(
    (sum, options) => sum + options.length,
    0
  );
  const canClearAll = Object.values(discoverFilters).some(
    (options) => options.length > 0
  );

  const handleClearAllFilters = () => {
    dispatch(ProjectNxStoreActions.clearAllDiscoverFilters(isAdmin));

    setIsConfirmationModalOpen(false);
    if (isAdmin) {
      const nonEmptyParams: Record<string, string> = {};
      if (query) {
        nonEmptyParams["query"] = query;
      }

      const params = new URLSearchParams(nonEmptyParams);
      history.replace({
        pathname: location.pathname,
        search: params.toString()
      });
    }
  };

  return (
    <>
      <ArbolusModal
        title={t("clearFiltersTitle")}
        subtitle={t("clearFiltersSubtitle")}
        toggle={() => setIsConfirmationModalOpen(false)}
        isOpen={isConfirmationModalOpen}
        leftButton={{
          text: t("cancelClearFiltersButton"),
          type: "tertiary",
          onClick: () => setIsConfirmationModalOpen(false)
        }}
        rightButton={{
          text: t("confirmClearFiltersButton"),
          type: "confirmation",
          onClick: handleClearAllFilters
        }}
      />
      <div className={styles.filtersContainer}>
        <div className={styles.header}>
          {!withoutHeader && (
            <h1 className={styles.title}>
              {t("filtersSectionTitle")}
              {appliedFiltersNumber > 0 && ` (${appliedFiltersNumber})`}
            </h1>
          )}
          <ClearButton
            text={t("clearAllFilters")}
            onClick={() => setIsConfirmationModalOpen(true)}
            disabled={!canClearAll}
          />
        </div>

        {isAdmin && <KeywordsFilter />}
        <ExpertLocationFilter />
        <WorkHistoryLocationFilter />
        <CompaniesFilter />
        <ProjectsFilter />
        {isAdmin && expertSearchCustomerFilterFeatureFlag && (
          <CustomersFilter />
        )}
      </div>
    </>
  );
};
