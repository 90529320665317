import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ExpertService,
  ProjectAnswer,
  ToasterService
} from "@arbolus-technologies/api";
import { PageStep } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

import { ExpertApplicationWrapper } from "../../Components/ExpertApplicationWrapper/ExpertApplicationWrapper";
import { ScreeningQuestionsForm } from "../../Components/ScreeningQuestionsForm/ScreeningQuestionsForm";

interface ScreeningQuestionsProps {
  name: string;
  setCurrentStep: (step: number) => void;
  steps: PageStep[];
  projectId: string;
  expertService?: typeof ExpertService;
  notificationService?: ToasterService;
}

export const ScreeningQuestions: React.FC<ScreeningQuestionsProps> = ({
  name,
  setCurrentStep,
  steps,
  projectId,
  expertService = ExpertService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("expertApplications");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [screeningQuestions, setScreeningQuestions] = useState<ProjectAnswer[]>(
    []
  );

  const expertId = useSelector(CacheSelector.loggedInUserExpertId());

  useEffect(() => {
    setIsLoading(true);

    expertService.getAnswers(expertId, projectId).subscribe(
      (res) => {
        setScreeningQuestions(res.items);
        setIsLoading(false);
      },
      (err: ErrorResponse<CIQError>) => {
        notificationService.showError(err.message);
        setIsLoading(false);
      }
    );
  }, [expertId, projectId, expertService, notificationService]);

  const currentStep = steps.findIndex((step) => step.name === name);

  return (
    <ExpertApplicationWrapper
      currentStep={currentStep}
      name={name}
      isLoading={isLoading}
    >
      {screeningQuestions.length > 0 && (
        <ScreeningQuestionsForm
          screeningQuestions={screeningQuestions}
          nextStepName={t("nextStep", { step: steps[currentStep + 1].name })}
          projectId={projectId}
          goToPrevStep={() => setCurrentStep(currentStep - 1)}
          onAfterSubmit={() => {
            setCurrentStep(currentStep + 1);
          }}
        />
      )}
    </ExpertApplicationWrapper>
  );
};
