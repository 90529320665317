import clsx from "clsx";
import React from "react";

import styles from "./ButtonNoBorderWithIcon.module.scss";

interface ButtonNoBorderWithIconProps {
  name?: string;
  onClick?: () => void;
  iconName?: string;
  leftIconName?: string;
  align?: "left" | "right" | "center";
  disabled?: boolean;
  classnames?: string;
  style?: React.CSSProperties;
}
export const ButtonNoBorderWithIcon: React.FC<ButtonNoBorderWithIconProps> = ({
  name,
  onClick,
  iconName,
  leftIconName,
  align = "center",
  classnames,
  disabled = false,
  style
}) => (
  <button
    onClick={onClick}
    className={clsx(
      styles.button,
      styles[align],
      classnames,
      disabled && styles.disabled
    )}
    disabled={disabled}
    style={style}
  >
    {leftIconName && (
      <span className={clsx("material-symbols-sharp", styles.icon)}>
        {leftIconName}
      </span>
    )}
    {name}
    {iconName && (
      <span className={clsx("material-symbols-sharp", styles.icon)}>
        {iconName}
      </span>
    )}
  </button>
);
