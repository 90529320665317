import i18next from "i18next";
import * as Yup from "yup";

import {
  DIGIT_REGEX_REQUIRED,
  LOWER_UPPERCASE_REGEX,
  SPECIAL_CHARACTERS_REGEX,
  UserConstraints
} from "@arbolus-technologies/ui/components";

export const ResetPasswordFormValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required(i18next.t("resetPassword:passwordRequiredError"))
    .matches(LOWER_UPPERCASE_REGEX, " ")
    .matches(SPECIAL_CHARACTERS_REGEX, " ")
    .matches(DIGIT_REGEX_REQUIRED, " ")
    .min(UserConstraints.MIN_PASSWORD_LENGTH, " "), // Note: Empty string will replace by yup, use a space for empty error message
  confirmPassword: Yup.string()
    .required(i18next.t("resetPassword:confirmPasswordRequiredError"))
    .test(
      "equal-check",
      i18next.t("resetPassword:confirmPasswordMatchError"),
      function (passwordCopy: string) {
        const { password } = this.parent;
        return password === passwordCopy;
      }
    )
});
