import { ObjectSchema } from "yup";

import {
  ANSWER_TYPE,
  CanopyQuestionFields
} from "@arbolus-technologies/models/canopy";

import { CanopyBaseSchema } from "./CanopyBaseSchema";
import { CanopyMultiChoiceSchema } from "./CanopyMultiChoiceSchema";
import { CanopyValueSchema } from "./CanopyValueSchema";

const canopySchemaSelector: Record<
  ANSWER_TYPE,
  ObjectSchema<CanopyQuestionFields>
> = {
  [ANSWER_TYPE.VIDEO]: CanopyBaseSchema,
  [ANSWER_TYPE.MULTIPLE_CHOICE]: CanopyMultiChoiceSchema,
  [ANSWER_TYPE.VALUE]: CanopyValueSchema,
  [ANSWER_TYPE.SHORT_TEXT]: CanopyBaseSchema,
  [ANSWER_TYPE.NPS]: CanopyBaseSchema
};

export const CanopySchema = (
  answerType: ANSWER_TYPE
): ObjectSchema<CanopyQuestionFields> => canopySchemaSelector[answerType];
