import { cloneDeep } from "lodash";

import { DiscoverFilterOption } from "@arbolus-technologies/models/project";

export const cloneAndFilter = (
  preSelectedOptions: DiscoverFilterOption[],
  selectedOptions: DiscoverFilterOption[]
): DiscoverFilterOption[] => {
  const preSelectedOptionsCopy = cloneDeep(preSelectedOptions);

  const preselectedValues = preSelectedOptionsCopy.map(
    (option: DiscoverFilterOption) => option.value
  );
  const filtersToKeep = selectedOptions.filter((option: DiscoverFilterOption) =>
    preselectedValues.includes(option.value)
  );

  const previousValues = selectedOptions.map(
    (option: DiscoverFilterOption) => option.value
  );
  const filtersToAdd = preSelectedOptionsCopy.filter(
    (option: DiscoverFilterOption) => !previousValues.includes(option.value)
  );

  return [...filtersToKeep, ...filtersToAdd];
};
