import clsx from "clsx";
import React from "react";
import { useHistory, useRouteMatch } from "react-router";
import { Button, NavItem, NavLink } from "reactstrap";

interface RoutedTabHeaderProps {
  activateRoute: string | string[];
  navigateTo: string;
  title: string;
  isNavItem?: boolean;
}

const RoutedTabHeader: React.FC<RoutedTabHeaderProps> = ({
  title,
  activateRoute,
  navigateTo,
  isNavItem
}): JSX.Element => {
  const history = useHistory();
  const match = useRouteMatch({
    path: activateRoute,
    exact: true
  });

  if (isNavItem) {
    return (
      <NavItem>
        <NavLink
          onClick={(): void => history.push(navigateTo)}
          className={clsx({ active: !!match })}
        >
          {title.toUpperCase()}
        </NavLink>
      </NavItem>
    );
  }

  return (
    <Button
      size="sm"
      color="default"
      active={!!match}
      onClick={(): void => {
        history.push(navigateTo);
      }}
    >
      {title}
    </Button>
  );
};

export default RoutedTabHeader;
