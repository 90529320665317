import { Icon } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { ExpertUser } from "@arbolus-technologies/api";
import { ExpertDncStatusSlidePanel } from "@arbolus-technologies/features/common";
import { DateString } from "@arbolus-technologies/models/common";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";

import styles from "./EditExpertStatusSection.module.scss";

interface EditExpertStatusSectionProps {
  expertUser: ExpertUser;
  expertId: string;
  lastPublicCompanyExpDate: DateString | null;
}

export const EditExpertStatusSection: React.FC<
  EditExpertStatusSectionProps
> = ({ expertUser, expertId, lastPublicCompanyExpDate }) => {
  const { t } = useTranslation("expertStatus");
  const dispatch = useDispatch();

  return (
    <>
      <div
        className={styles.container}
        onClick={() =>
          dispatch(PanelStoreActions.openPanel(PanelId.ExpertStatus))
        }
      >
        <div className={styles.textContainer}>
          <h1 className={styles.title}>{t("expertStatus")}</h1>
          <p className={styles.subtitle}>{t("expertStatusDescription")}</p>
        </div>
        <Icon name="chevron_right" fontSize="24px" />
      </div>
      <ExpertDncStatusSlidePanel
        expertUser={expertUser}
        expertId={expertId}
        lastPublicCompanyExpDate={lastPublicCompanyExpDate}
      />
    </>
  );
};
