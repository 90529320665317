import queryString from "query-string";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import {
  DefaultToasterService,
  ToasterService
} from "@arbolus-technologies/api";
import {
  DiscoverFilterOperator,
  DiscoverFilterOption,
  DiscoverFilterType,
  FilterTimeSelectorOptions
} from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  ProjectNxSelector,
  ProjectNxStoreActions
} from "@arbolus-technologies/stores/project";
import { useDebounce, utilService } from "@arbolus-technologies/utils";

import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { CountryAndRegion } from "../../Models/Discover";
import { cloneAndFilter } from "../../Utils/DiscoverExpertUtils";
import { useDiscoverFilters } from "./useDiscoverFilters";

interface UseDiscoverWorkHistoryLocationFilterProps {
  notificationService?: ToasterService;
}

export interface UseDiscoverWorkHistoryLocationFilter {
  handleSetWorkHistoryLocationFilterPeriod: (
    filterTimeSelectorOption: string
  ) => void;
  handleInputChangeEvent: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  handleClearQueryClicked: () => void;
  handleSelectOption: (option: DiscoverFilterOption) => void;
  filterOptions: DiscoverFilterOption[];
  applyWorkHistoryLocationFilter: () => void;
  urlChangeOnWorkHistoryLocationFilterUpdate: (
    options: DiscoverFilterOption[]
  ) => void;
  handleClearFilter: () => void;
  updateWorkHistoryLocationInfoFromUrl: () => DiscoverFilterOption[];
}

const MINIMUM_SEARCH_LENGTH = 2;

export const useDiscoverWorkHistoryLocationFilter = ({
  notificationService = DefaultToasterService
}: UseDiscoverWorkHistoryLocationFilterProps = {}): UseDiscoverWorkHistoryLocationFilter => {
  const { t } = useTranslation("discoverPage");
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { trackFiltered } = useArbolusTracking();

  const parameters = queryString.parse(location.search);
  const {
    includedWorkHistoryLocations,
    excludedWorkHistoryLocations
  }: {
    includedWorkHistoryLocations?: string;
    excludedWorkHistoryLocations?: string;
  } = parameters;

  const {
    selectedOptions,
    setSelectedOptions,
    preSelectedOptions,
    setPreSelectedOptions,
    clearFilter
  } = useDiscoverFilters({
    filterType: DiscoverFilterType.Work_History_Locations
  });

  const [filterOptions, setFilterOptions] = useState<DiscoverFilterOption[]>(
    []
  );

  const metadataCountries = useSelector(CacheSelector.metadataCountries());
  const regions = useSelector(CacheSelector.regions());
  const isAdmin = useSelector(CacheSelector.isAdmin());
  const projectName = useSelector(ProjectNxSelector.projectName());
  const projectClientName = useSelector(ProjectNxSelector.projectClientName());

  const workHistoryLocationFilterQuery = useSelector(
    ProjectNxSelector.filtersQuery()
  )[DiscoverFilterType.Work_History_Locations];
  const debouncedSearchQuery = useDebounce<string>(
    workHistoryLocationFilterQuery,
    300
  );

  const countriesAndRegions = (): CountryAndRegion[] => {
    const countriesWithoutRegions = metadataCountries.filter(
      (country) => !regions.map((region) => region.name).includes(country.name)
    );
    return Array.from(new Set([...countriesWithoutRegions, ...regions]));
  };

  const workHistoryLocationSelectors = countriesAndRegions().map(
    (location) => ({
      value: location.id,
      label: location.name,
      searchLabel: location.threeLetterCode ?? location.name,
      operator: DiscoverFilterOperator.Include
    })
  );

  useEffect(() => {
    if (preSelectedOptions.length === 10) {
      notificationService.showError(t("maximumLocations"));
    }
  }, [notificationService, preSelectedOptions, t]);

  const handleSetWorkHistoryLocationFilterPeriod = (
    filterTimeSelectorOption: string
  ) => {
    dispatch(
      ProjectNxStoreActions.setWorkHistoryLocationFilterPeriod(
        filterTimeSelectorOption as FilterTimeSelectorOptions
      )
    );

    if (isAdmin) {
      const nonEmptyParams = utilService.getNonEmptyQueryString(parameters);
      if (filterTimeSelectorOption === FilterTimeSelectorOptions.All) {
        delete nonEmptyParams["workHistoryLocationState"];
      } else {
        nonEmptyParams["workHistoryLocationState"] = filterTimeSelectorOption;
      }

      const params = new URLSearchParams(nonEmptyParams);
      history.replace({
        pathname: location.pathname,
        search: params.toString()
      });
    }
  };

  const getWorkHistoryLocationSearchResults = (query: string) => {
    const workHistoryLocations = workHistoryLocationSelectors.filter(
      (workHistoryLocation) =>
        workHistoryLocation.label.toLowerCase().includes(query.toLowerCase()) ||
        workHistoryLocation.searchLabel
          .toLowerCase()
          .includes(query.toLowerCase())
    );

    setFilterOptions(workHistoryLocations);
  };

  const handleInputChangeEvent = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const newQuery = event.target.value;
    dispatch(
      ProjectNxStoreActions.setFilterQuery(
        DiscoverFilterType.Work_History_Locations,
        newQuery
      )
    );
  };

  useEffect(() => {
    if (debouncedSearchQuery.length >= MINIMUM_SEARCH_LENGTH) {
      getWorkHistoryLocationSearchResults(debouncedSearchQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  const handleClearQueryClicked = () => {
    dispatch(
      ProjectNxStoreActions.setFilterQuery(
        DiscoverFilterType.Work_History_Locations,
        ""
      )
    );
  };

  const handleSelectOption = (option: DiscoverFilterOption) => {
    const isOptionSelected = preSelectedOptions
      .map((selectedOption) => selectedOption.value)
      .includes(option.value);

    if (isOptionSelected) {
      const filteredOptions = preSelectedOptions.filter(
        (selectedOption) => selectedOption.value !== option.value
      );
      setPreSelectedOptions(filteredOptions);
    } else {
      setPreSelectedOptions([...preSelectedOptions, option]);
    }
  };

  const applyWorkHistoryLocationFilter = () => {
    const selectedOptionsUpdated = cloneAndFilter(
      preSelectedOptions,
      selectedOptions
    );
    setSelectedOptions(selectedOptionsUpdated);
    urlChangeOnWorkHistoryLocationFilterUpdate(selectedOptionsUpdated);
    trackFiltered(MixPanelEventNames.InternalSearchWorkHistorySearch, {
      Project: projectName,
      Client: projectClientName
    });
  };

  useEffect(() => {
    if (workHistoryLocationFilterQuery.length === 0) {
      setFilterOptions([]);
    }
  }, [workHistoryLocationFilterQuery.length]);

  const updateWorkHistoryLocationInfoFromUrl = () => {
    const includedWorkHistoryLocationIds = includedWorkHistoryLocations
      ? includedWorkHistoryLocations.split(",")
      : [];
    const excludedWorkHistoryLocationIds = excludedWorkHistoryLocations
      ? excludedWorkHistoryLocations.split(",")
      : [];

    return [
      ...includedWorkHistoryLocationIds,
      ...excludedWorkHistoryLocationIds
    ]
      .map((value) => {
        const option = workHistoryLocationSelectors.find(
          (selectedOption) => selectedOption.value === value
        );

        if (option) {
          option.operator = includedWorkHistoryLocationIds.includes(value)
            ? DiscoverFilterOperator.Include
            : DiscoverFilterOperator.Exclude;
        }
        return option;
      })
      .filter((option) => !!option) as DiscoverFilterOption[];
  };

  const urlChangeOnWorkHistoryLocationFilterUpdate = (
    options: DiscoverFilterOption[]
  ) => {
    if (isAdmin) {
      const includedWorkHistoryLocations = options
        .filter((option) => option.operator === DiscoverFilterOperator.Include)
        .map((option) => option.value)
        .join(",");

      const excludedWorkHistoryLocations = options
        .filter((option) => option.operator === DiscoverFilterOperator.Exclude)
        .map((option) => option.value)
        .join(",");

      const nonEmptyParams = utilService.getNonEmptyQueryString(parameters);
      if (includedWorkHistoryLocations) {
        nonEmptyParams["includedWorkHistoryLocations"] =
          includedWorkHistoryLocations;
      } else {
        delete nonEmptyParams["includedWorkHistoryLocations"];
      }

      if (excludedWorkHistoryLocations) {
        nonEmptyParams["excludedWorkHistoryLocations"] =
          excludedWorkHistoryLocations;
      } else {
        delete nonEmptyParams["excludedWorkHistoryLocations"];
      }

      const params = new URLSearchParams(nonEmptyParams);
      history.replace({
        pathname: location.pathname,
        search: params.toString()
      });
    }
  };

  const handleClearFilter = () => {
    clearFilter();
    if (isAdmin) {
      const nonEmptyParams = utilService.getNonEmptyQueryString(parameters);
      delete nonEmptyParams["includedWorkHistoryLocations"];
      delete nonEmptyParams["excludedWorkHistoryLocations"];
      delete nonEmptyParams["workHistoryLocationState"];

      const params = new URLSearchParams(nonEmptyParams);
      history.replace({
        pathname: location.pathname,
        search: params.toString()
      });
    }
  };

  return {
    handleSetWorkHistoryLocationFilterPeriod,
    handleInputChangeEvent,
    handleClearQueryClicked,
    handleSelectOption,
    filterOptions,
    applyWorkHistoryLocationFilter,
    urlChangeOnWorkHistoryLocationFilterUpdate,
    handleClearFilter,
    updateWorkHistoryLocationInfoFromUrl
  };
};
