import React, { useCallback, useEffect, useState } from "react";

import {
  AllCanopy,
  CANOPY_ORDER_BY,
  CIQError,
  CanopyService,
  ErrorResponse,
  PaginatedRequest,
  SORT_DIRECTION
} from "@arbolus-technologies/api";
import { CanopyGrayBox } from "@arbolus-technologies/features/common";
import { CanopyPageType } from "@arbolus-technologies/models/canopy";
import { CANOPY_LIST } from "@arbolus-technologies/routes";

interface DashboardCanopyProps {
  canopyService?: typeof CanopyService;
}

const queryParams: PaginatedRequest<CANOPY_ORDER_BY> = {
  limit: 3,
  offset: 0,
  orderBy: CANOPY_ORDER_BY.LAST_ANSWER_DATE,
  orderDirection: SORT_DIRECTION.DESCENDING
};

export const DashboardCanopy: React.FC<DashboardCanopyProps> = ({
  canopyService = CanopyService
}) => {
  const [canopies, setCanopies] = useState<AllCanopy[]>([]);
  const [isCanopiesLoading, setIsCanopiesLoading] = useState<boolean>(false);

  const getCanopies = useCallback(() => {
    setIsCanopiesLoading(true);
    canopyService.getAllCanopies(queryParams).subscribe(
      (response) => {
        setCanopies(response.items);
        setIsCanopiesLoading(false);
      },
      (error: ErrorResponse<CIQError>) => {
        console.error(error);
        setIsCanopiesLoading(false);
      }
    );
  }, [canopyService]);

  useEffect(() => {
    getCanopies();
  }, [getCanopies]);

  return (
    <CanopyGrayBox
      to={CANOPY_LIST}
      isCanopiesLoading={isCanopiesLoading}
      canopies={canopies}
      canopyPageType={CanopyPageType.UserDashboard}
    />
  );
};
