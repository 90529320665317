/* eslint-disable react/static-property-placement */
import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  InjectedDeviceMonitorProps,
  withDeviceMonitor
} from "../../containers/app/components/DeviceMonitor";
import {
  navigationBarVisibleSelector,
  navigationHeaderVisibleSelector
} from "../../containers/dashboard/store/selector";
import { AppState } from "../../store/reducers";
import { NavBarContextProps, NavBarContextProvider } from "./NavBarContext";

interface NavBarContextProviderStoreProps {
  isNavbarVisible: boolean;
  isNavHeaderVisible: boolean;
}

export interface NavBarContextProviderProps
  extends NavBarContextProviderStoreProps,
    InjectedDeviceMonitorProps {
  isProjectActionsActive: boolean;
  children: ReactNode;
}

export interface NavBarContextProviderState {
  isExpand: boolean;
  isHeaderVisible: boolean;
  isProjectActionsActive: boolean;
}

class NavBarProvider extends React.Component<
  NavBarContextProviderProps,
  NavBarContextProviderState
> {
  static defaultProps = {
    isNavbarVisible: false,
    isNavHeaderVisible: false,
    isProjectActionsActive: false
  };

  static getDerivedStateFromProps({
    isNavbarVisible,
    isNavHeaderVisible,
    matches
  }: NavBarContextProviderProps): NavBarContextProviderState {
    const nextState = {} as NavBarContextProviderState;
    if (matches.large) nextState.isExpand = isNavbarVisible; // DEVICE DETECTION

    nextState.isHeaderVisible = isNavHeaderVisible;
    return nextState;
  }

  constructor(props: NavBarContextProviderProps) {
    super(props);

    const { isNavbarVisible, isNavHeaderVisible, matches } = props;
    const isLargeDevice = matches.large;

    this.state = {
      isExpand: isNavbarVisible && isLargeDevice,
      isHeaderVisible: isNavHeaderVisible,
      isProjectActionsActive: false
    };
  }

  handleNavBarToggle = (): void => {
    const { isExpand } = this.state;
    this.setState({
      isExpand: !isExpand
    });
  };

  handleProjectActionToggle = (): void => {
    const { isProjectActionsActive } = this.state;
    this.setState({
      isProjectActionsActive: !isProjectActionsActive
    });
  };

  render(): JSX.Element {
    const { isExpand, isHeaderVisible, isProjectActionsActive } = this.state;
    const { children } = this.props;

    const customNavBarContext: NavBarContextProps = {
      isExpand,
      isHeaderVisible,
      isProjectActionsActive,
      toggle: this.handleNavBarToggle,
      projectActionToggle: this.handleProjectActionToggle
    };

    return (
      <NavBarContextProvider value={customNavBarContext}>
        {children}
      </NavBarContextProvider>
    );
  }
}

const mapStateToProps = createStructuredSelector<
  AppState,
  NavBarContextProviderProps,
  NavBarContextProviderStoreProps
>({
  isNavbarVisible: navigationBarVisibleSelector(),
  isNavHeaderVisible: navigationHeaderVisibleSelector()
});

const storeConnectedProvider = connect(mapStateToProps, null)(NavBarProvider);
const deviceMonitoringProvider = withDeviceMonitor(storeConnectedProvider);

export default deviceMonitoringProvider;
