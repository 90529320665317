import {
  BookmarkCollectionsAppState,
  BookmarkCollectionsReducerState
} from "./models/definitions";
import {
  collectionList,
  collectionListHasNextPage,
  collectionListIsLoading,
  collectionListSearchTerm
} from "./selectors/ListCollectionsSelector";

export const BookmarkCollectionsStateSelector = (
  state: BookmarkCollectionsAppState
): BookmarkCollectionsReducerState => state.bookmarkCollections;

export const BookmarkCollectionsSelector = {
  collectionList,
  collectionListHasNextPage,
  collectionListSearchTerm,
  collectionListIsLoading
};
