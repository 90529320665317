import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { ANSWER_TYPE } from "@arbolus-technologies/models/canopy";
import { maxLengthString } from "@arbolus-technologies/utils";

import { QuestionType } from "../QuestionType/QuestionType";

import bye from "../../assets/bye.svg";
import hello from "../../assets/hello.png";

import styles from "./CardSurveyBuilder.module.scss";

export interface CardSurveyBuilderProps {
  id?: string;
  order?: number;
  orderOnCreate: number;
  title?: string;
  type?: ANSWER_TYPE;
  defaultType: "start" | "end" | "question" | "new";
  createQuestion: (order: number) => void;
  editQuestion: (questionId: string, order: number) => void;
}

export const CardSurveyBuilder: React.FC<CardSurveyBuilderProps> = ({
  id,
  defaultType,
  title,
  order,
  orderOnCreate,
  type,
  createQuestion,
  editQuestion
}) => {
  const { t } = useTranslation("canopyComponents");

  const isNew = defaultType === "new";
  const isStart = defaultType === "start";
  const isEnd = defaultType === "end";
  const isQuestion = defaultType === "question";
  const isClickable = isQuestion || isNew;

  const handleOnCardClick = () =>
    isNew ? createQuestion(order || orderOnCreate) : editQuestion(id!, order!);

  const displayOrder = () => (order || 0) + 1;

  return (
    <div className={styles.cardFullContainer}>
      {!isStart && (
        <div className={styles.addQuestion}>
          <div className={styles.line} />
          {isQuestion && (
            <div
              className={styles.buttonContainer}
              onClick={() => createQuestion(order!)}
            >
              <button className={styles.button}>
                <span className="ciq-icon ciq-plus" />
              </button>
            </div>
          )}
        </div>
      )}
      <div
        className={clsx(isClickable ? styles.container : styles.containerPlain)}
        onClick={isClickable ? handleOnCardClick : undefined}
      >
        {defaultType !== "new" && (
          <div className={styles.iconNumberContainer}>
            {isQuestion && (
              <div className={styles.circle}>
                <span>{displayOrder()}</span>
              </div>
            )}
            {isStart && <img src={hello} alt="hello" />}
            {isEnd && <img src={bye} alt="finish" />}
          </div>
        )}

        <div className={styles.bodyContainer}>
          {isStart && (
            <div className={styles.startBody}>
              {t("start")}
              <span>{t("startDescription")}</span>
            </div>
          )}
          {isEnd && (
            <div className={styles.endBody}>
              {t("finish")}
              <span>{t("finishDescription")}</span>
            </div>
          )}
          {isQuestion && title && (
            <div className={styles.questionBody}>
              <span title={title}>{maxLengthString(title, 65)}</span>
            </div>
          )}
          {isNew && (
            <div className={styles.newBody}>
              <div className={styles.buttonNewContainer}>
                <div className={styles.buttonNew}>
                  <span className="ciq-icon ciq-plus" />
                </div>
              </div>
              <span className={styles.questionTitle}>{t("addAQuestion")}</span>
            </div>
          )}
        </div>
        {isQuestion && type && (
          <div className={styles.typeContainer}>
            <QuestionType type={type} />
          </div>
        )}
      </div>
    </div>
  );
};
