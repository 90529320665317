import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Button, Col, Row } from "reactstrap";
import SimpleBar from "simplebar-react";

import { useIsChatAvailable } from "@arbolus-technologies/features/common";
import { PROJECT_CHAT_BASE_ROUTE } from "@arbolus-technologies/routes";

import { UI_REVIEWAL_PANEL } from "../../../../../constants/ui";

import brandLogo from "../../../../../assets/images/logos/logo-tree-blue.svg";

interface ReviewCompletedProps {
  onGoBackClicked: () => void;
  noCandidates: boolean;
  projectId: string;
  onPanelClose: () => void;
}

const ReviewCompleted: React.FC<ReviewCompletedProps> = ({
  onGoBackClicked,
  noCandidates,
  projectId,
  onPanelClose
}) => {
  const { t } = useTranslation("reviewCompleted");
  const { push } = useHistory();
  const isChatAvailable = useIsChatAvailable();

  const handleGoToChat = () => {
    onPanelClose();
    push(PROJECT_CHAT_BASE_ROUTE(projectId));
    // [ARB-10399] Removed redirect to a particular chat (old workspace code)
  };

  return (
    <SimpleBar
      className="expert-container simplebar-light"
      style={{
        overflowX: "hidden",
        maxHeight: UI_REVIEWAL_PANEL.PANEL_HEIGHT
      }}
    >
      <Row className="expert-header">
        <Col className="left-action">
          {!noCandidates && (
            <div className="btn transparent-btn" onClick={onGoBackClicked}>
              <span className="ciq-icon ciq-left" />
            </div>
          )}
        </Col>
        <Col className="expert-icon">
          <span className="ciq-check" />
        </Col>
        <Col className="right-action" />
      </Row>
      {isChatAvailable && (
        <div className="review-header-body">
          <h2>{t("thatsIt")}</h2>
          <p>{t("kickOffCall")}</p>
          <Button size="lg" color="primary" onClick={handleGoToChat}>
            {t("goToChat")}
          </Button>
        </div>
      )}
      {isChatAvailable && (
        <div className="review-support-container">
          <img src={brandLogo} alt="brand-logo" />
          <h3>{t("doBetter")}</h3>
          <p>{t("sendFeedback")}</p>
          <Button size="lg" color="secondary" onClick={handleGoToChat}>
            {t("talkUs")}
          </Button>
        </div>
      )}
    </SimpleBar>
  );
};

export default ReviewCompleted;
