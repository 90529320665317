import { Button, Flex } from "antd";
import clsx from "clsx";
import { Field, FieldArray, Formik, FormikProps, getIn } from "formik";
import { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

import { EXPERT_EXPERIENCE } from "@arbolus-technologies/models/expert";
import {
  LinkedInURLInput,
  RichTextEditor
} from "@arbolus-technologies/ui/components";
import { convertValueToCurrencyFormat } from "@arbolus-technologies/utils";

import { ExpertDetails } from "../../../../../models/view/expert";
import { CIQFormInput, CIQSelect } from "../../../../app/components";
import { ExpertDetailsValidationSchema } from "./ExpertDetailsSchema";

interface ExpertProfileDetailsPaneProps {
  expertDetails: ExpertDetails;
  onUpdateExpertDetails: (values: ExpertDetails) => void;
  isSaveInProgress: boolean;
}

interface ExpertProfileDetailsFormValues {
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  linkedInUrl: string;
  seniority: string;
  introduction: string;
  funFact: string;
  quickFacts: string[];
  phoneNumber?: string;
}

type ExpertProfileDetailsIntersectProps = ExpertProfileDetailsPaneProps &
  WithTranslation;

class ExpertProfileDetailsPane extends Component<
  ExpertProfileDetailsIntersectProps,
  {}
> {
  componentDidUpdate(prevProps: ExpertProfileDetailsPaneProps): void {
    const { isSaveInProgress } = this.props;

    if (!isSaveInProgress && prevProps.isSaveInProgress) {
      this.formRef?.resetForm({ values: this.formRef?.values });
    }
  }

  private formRef?: FormikProps<ExpertProfileDetailsFormValues> | null = null;

  handleSubmit = (formValues: ExpertProfileDetailsFormValues): void => {
    const { onUpdateExpertDetails } = this.props;

    const {
      firstName,
      lastName,
      email,
      linkedInUrl,
      seniority,
      title,
      introduction,
      funFact,
      quickFacts,
      phoneNumber
    } = formValues;
    const updatedDetails: ExpertDetails = {
      experienceLevel: seniority,
      linkedInUrl,
      firstName,
      lastName,
      title,
      email,
      introduction,
      funFact,
      quickFacts: quickFacts.filter((q) => q),
      phoneNumber
    };

    onUpdateExpertDetails(updatedDetails);
  };

  renderExpertDetails = ({
    values,
    errors,
    touched,
    isValid,
    dirty,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    submitForm
  }: FormikProps<ExpertProfileDetailsFormValues>): JSX.Element => {
    const {
      t,
      isSaveInProgress,
      expertDetails: { isoCurrencyCode, hourlyRate }
    } = this.props;

    const formQuickFacts = ["", ""];
    values.quickFacts.forEach((qf, i) => {
      formQuickFacts[i] = qf;
    });

    const saveButtonEnabled = isValid && dirty;

    const selectValueChange = (field: string, value: string): void => {
      setFieldValue(field, value);
    };

    const handlePhoneChange = (value: string): void => {
      setFieldTouched("phoneNumber", true);
      setFieldValue("phoneNumber", value);
    };

    return (
      <>
        <section>
          <Row>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label for="">{t("firstName")}</Label>
                <Field
                  placeholder={t("firstName")}
                  name="firstName"
                  type="text"
                  component={CIQFormInput}
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label>{t("lastName")}</Label>
                <Field
                  placeholder={t("lastName")}
                  name="lastName"
                  type="text"
                  component={CIQFormInput}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>{t("headline")}</Label>
                <Field
                  placeholder={t("headline")}
                  name="title"
                  type="text"
                  value={values.title || ""}
                  component={CIQFormInput}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label>{t("email")}</Label>
                <Field
                  disabled
                  placeholder={t("email")}
                  name="email"
                  component={CIQFormInput}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup
                className={clsx({
                  "is-invalid": errors.phoneNumber && touched.phoneNumber
                })}
              >
                <Label className="space-between">
                  {t("contactNumber")}
                  <span>{t("optional")}</span>
                </Label>
                <PhoneInput
                  inputProps={{
                    name: "phoneNumber",
                    autoComplete: "off"
                  }}
                  value={values.phoneNumber || ""}
                  placeholder={t("contactNumberPlaceholder")}
                  onChange={handlePhoneChange}
                  onBlur={handleBlur("phoneNumber")}
                  enableSearch
                  country="us"
                  disableSearchIcon
                  prefix=""
                />
                {errors.phoneNumber && touched.phoneNumber && (
                  <Label className="invalid-feedback">
                    {errors.phoneNumber}
                  </Label>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12}>
              <FormGroup>
                <Label className="space-between">
                  {t("linkedInUrl")}
                  <span>{t("optional")}</span>
                </Label>
                <Field
                  placeholder={t("linkedInUrlPlaceholder")}
                  name="linkedInUrl"
                  type="text"
                  component={LinkedInURLInput}
                />
              </FormGroup>
            </Col>
          </Row>
        </section>
        <section className="about-section">
          <Row>
            <Col>
              <h3>{t("about")}</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup
                className={clsx("richtexteditor-container", {
                  "is-invalid": touched.introduction && errors.introduction
                })}
              >
                <Label>{t("introduction")}</Label>
                <RichTextEditor
                  placeholder={t("introductionPlaceholder")}
                  editorState={values.introduction}
                  onChange={(content: string): void => {
                    setFieldValue("introduction", content);
                  }}
                  onBlur={() => setFieldTouched("introduction", true)}
                />
                {errors.introduction && touched.introduction && (
                  <div className="invalid-feedback">{errors.introduction}</div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup className="m-0">
                <FieldArray
                  name="quickFacts"
                  render={(): JSX.Element => (
                    <Row>
                      {formQuickFacts.map((quickFact, index) => {
                        const name = `quickFacts[${index}]`;
                        const error = getIn(errors, name);
                        const touch = getIn(touched, name);
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <Col key={index}>
                            <FormGroup
                              className={clsx({
                                "is-invalid": touch && error
                              })}
                            >
                              <Label className="space-between">
                                {index === 0 && t("quickFacts")}
                                &nbsp;
                                <span>Optional</span>
                              </Label>
                              <Field
                                name={name}
                                placeholder={
                                  (index === 0 &&
                                    t("quickFactsPlaceholder1")) ||
                                  (index === 1 && t("quickFactsPlaceholder2"))
                                }
                                type="text"
                                component={CIQFormInput}
                                value={quickFact}
                              />
                              {error && touch && (
                                <div className="invalid-feedback">{error}</div>
                              )}
                            </FormGroup>
                          </Col>
                        );
                      })}
                    </Row>
                  )}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label className="space-between">
                  {t("funFact")}
                  <span>{t("optional")}</span>
                </Label>
                <Field
                  placeholder={t("funFactPlaceholder")}
                  name="funFact"
                  type="text"
                  component={CIQFormInput}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label>{t("seniority")}</Label>
                <CIQSelect
                  options={Array.from(EXPERT_EXPERIENCE.values())}
                  defaultValue={EXPERT_EXPERIENCE.get(values.seniority)}
                  noOptionsMessage={t("noSelectOptions")}
                  onSelectChange={(value: string): void =>
                    selectValueChange("seniority", value)
                  }
                  customStyle
                />
              </FormGroup>
            </Col>
            {isoCurrencyCode && hourlyRate && (
              <Col sm={12} md={6} className="rate">
                <Label className="rate-label">Hourly Rate</Label>
                <Input
                  className="rate-input"
                  value={convertValueToCurrencyFormat(
                    hourlyRate,
                    isoCurrencyCode
                  )}
                  disabled
                />
              </Col>
            )}
          </Row>
        </section>
        <Row>
          <Col>
            <Flex justify="flex-end">
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                disabled={!saveButtonEnabled || isSaveInProgress}
                onClick={submitForm}
              >
                {t("saveDetails")}
              </Button>
            </Flex>
          </Col>
        </Row>
      </>
    );
  };

  render(): JSX.Element {
    const { expertDetails } = this.props;
    const {
      firstName,
      lastName,
      title,
      email,
      linkedInUrl,
      experienceLevel,
      introduction,
      funFact,
      quickFacts,
      phoneNumber
    } = expertDetails;

    return (
      <div className="expert-details-tab">
        <Formik<ExpertProfileDetailsFormValues>
          initialValues={{
            firstName,
            lastName,
            email,
            title,
            linkedInUrl,
            seniority:
              EXPERT_EXPERIENCE.get(experienceLevel)?.value ||
              EXPERT_EXPERIENCE.get("CSuiteLevel")?.value!,
            introduction,
            funFact,
            quickFacts: quickFacts || ["", ""],
            phoneNumber
          }}
          validationSchema={ExpertDetailsValidationSchema}
          validateOnChange
          validateOnBlur
          innerRef={(instance): void => {
            this.formRef = instance;
          }}
          onSubmit={this.handleSubmit}
        >
          {this.renderExpertDetails}
        </Formik>
      </div>
    );
  }
}

export default withTranslation("expertProfileEditPage")(
  ExpertProfileDetailsPane
);
