import clsx from "clsx";
import { Form, Formik, FormikProps } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, FormFeedback, FormGroup, Input, Row } from "reactstrap";

import {
  MixPanelActions,
  MixPanelEventNames,
  trackEvent
} from "@arbolus-technologies/features/common";
import {
  APP_USER_ROLES,
  SelectOption
} from "@arbolus-technologies/models/common";

import { User } from "../../../../../models/user";
import { UtilsService } from "../../../../../services";
import { CIQSelect } from "../../../../app/components";
import { ExpertRateValidationSchema } from "./ExpertRateSchema";

interface ExpertRateProps {
  hourlyRate?: number;
  currencyCode?: string;
  currencies: SelectOption[];
  isLoading: boolean;
  onSaveButtonClicked: (values: ExpertRateFormValues) => void;
  user?: User;
  invitationCode?: string;
}

export interface ExpertRateFormValues {
  currency: string;
  hourlyRate?: number;
}

const ExpertRate: React.FC<ExpertRateProps> = ({
  currencies,
  isLoading,
  onSaveButtonClicked,
  hourlyRate,
  currencyCode,
  user,
  invitationCode
}) => {
  const { t } = useTranslation("register");
  let initialCurrencyValue;

  if (currencyCode) {
    initialCurrencyValue = currencies.find(
      (c) => c.value === currencyCode
    )?.value;
  } else {
    initialCurrencyValue = currencies[0].value;
  }

  useEffect(() => {
    const { email, firstName, lastName, phoneNumber } = user || {};
    const { expert } = APP_USER_ROLES;

    trackEvent(MixPanelEventNames.UserRegistrationExpertRate, {
      action: MixPanelActions.VisitedPage,
      firstName,
      lastName,
      email,
      invitationCode,
      accountType: expert,
      phoneNumber: phoneNumber ? `+${phoneNumber}` : ""
    });
  }, [invitationCode, user]);

  const handleSubmit = (values: ExpertRateFormValues): void => {
    const { email, firstName, lastName, phoneNumber } = user || {};
    const { expert } = APP_USER_ROLES;

    trackEvent(MixPanelEventNames.UserRegistrationExpertRate, {
      action: MixPanelActions.Clicked,
      button: "Save Expert Rate",
      firstName,
      lastName,
      email,
      invitationCode,
      accountType: expert,
      phoneNumber: phoneNumber ? `+${phoneNumber}` : ""
    });
    onSaveButtonClicked(values);
  };

  const renderRatesForm = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    isValid
  }: FormikProps<ExpertRateFormValues>): JSX.Element => {
    const selectValueChange = (field: string, value: string): void => {
      setFieldValue(field, value);
    };

    const handleHourlyRateChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ): void => {
      const { value } = event.target;
      setFieldValue(
        "hourlyRate",
        value ? UtilsService.shapeUpValues(parseFloat(value)) : ""
      );
    };

    return (
      <Form>
        <Row>
          <Col md="4">
            <FormGroup>
              <CIQSelect
                noOptionsMessage=""
                defaultValue={
                  currencies.find((c) => c.value === values.currency) ||
                  currencies[0]
                }
                options={currencies}
                onSelectChange={(value: string): void =>
                  selectValueChange("currency", value)
                }
                customStyle
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup
              className={clsx({
                "is-invalid": errors.hourlyRate && touched.hourlyRate
              })}
            >
              <Input
                autoComplete="off"
                name="hourlyRate"
                type="number"
                value={values.hourlyRate || ""}
                onChange={handleHourlyRateChange}
                onBlur={handleBlur}
                placeholder={t("hourlyRate")}
              />
              {errors.hourlyRate && touched.hourlyRate && (
                <FormFeedback>{errors.hourlyRate}</FormFeedback>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Button
          type="submit"
          size="lg"
          color="primary"
          className="btn-bold"
          disabled={!isValid || isLoading}
        >
          {t("saveAndContinue")}
        </Button>
      </Form>
    );
  };

  return (
    <div className="expert-rate-container">
      <div className="message-banner green">
        <p className="message">{t("expertRateBannerMessage")}</p>
      </div>

      <div className="rate-message-container">
        <div className="title">
          <h1>{t("ratesTitle")}</h1>
        </div>

        <div className="message">
          <p>{t("expertRateMessage")}</p>
        </div>
      </div>

      <div className="rate-value-form">
        <Formik<ExpertRateFormValues>
          validateOnBlur
          initialValues={{
            currency: initialCurrencyValue!,
            hourlyRate
          }}
          validateOnChange
          validationSchema={ExpertRateValidationSchema}
          onSubmit={handleSubmit}
        >
          {renderRatesForm}
        </Formik>
      </div>
    </div>
  );
};

export default ExpertRate;
