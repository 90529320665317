import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Col, Input, InputGroup, InputGroupAddon, Row } from "reactstrap";

import {
  SEARCH_DEBOUNCE_TIMEOUT,
  useDebounce
} from "@arbolus-technologies/utils";

interface SearchBarProps {
  initialValue?: string;
  onQueryChange: (query: string) => void;
  onClear?: () => void;
  isDebounced?: boolean;
  overrideClear?: boolean;
  placeholder?: string;
  debouncingTime?: number;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  initialValue,
  onQueryChange,
  onClear,
  isDebounced,
  overrideClear,
  placeholder,
  debouncingTime,
  ...props
}): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedValue = useDebounce<string>(
    searchQuery,
    debouncingTime || SEARCH_DEBOUNCE_TIMEOUT
  );

  useEffect(() => {
    isDebounced && onQueryChange(searchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const handleInputChangeEvent = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const newQuery = event.target.value;
    setSearchQuery(newQuery);
    !isDebounced && onQueryChange(newQuery);
  };

  const handleClearQueryClicked = (): void => {
    setSearchQuery("");
    onClear && onClear();
  };

  return (
    <Row className="search-filter-bar">
      <Col className="search-column">
        <InputGroup className="search-input-white">
          <Input
            {...props}
            autoComplete="off"
            value={searchQuery}
            placeholder={placeholder || ""}
            name="searchQuery"
            onChange={handleInputChangeEvent}
          />
          <InputGroupAddon addonType="append">
            <span
              onClick={handleClearQueryClicked}
              className={clsx("ciq-icon", {
                "ciq-search": overrideClear ? !overrideClear : !searchQuery,
                "ciq-close": overrideClear || searchQuery
              })}
            />
          </InputGroupAddon>
        </InputGroup>
      </Col>
    </Row>
  );
};
