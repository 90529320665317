import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { PROJECT_DISCOVER_ROUTE } from "@arbolus-technologies/routes";

import styles from "./SearchNetwork.module.scss";

interface SearchNetworkProps {
  projectId: string;
}

export const SearchNetwork: React.FC<SearchNetworkProps> = ({ projectId }) => {
  const { t } = useTranslation("inviteExperts");
  const searchRoute = PROJECT_DISCOVER_ROUTE(projectId);

  return (
    <div className={styles.searchNetworkContainer}>
      <h2 className={styles.searchNetworkTitle}>{t("searchNetwork")}</h2>
      <p className={styles.searchNetworkParagraph}>{t("searchNetworkText")}</p>
      <Link to={searchRoute}>
        <Button text={t("searchNow")} endIcon="chevron_right" type="primary" />
      </Link>
    </div>
  );
};
