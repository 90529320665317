import React from "react";
import { useTranslation } from "react-i18next";

import { CanopyQuestion } from "@arbolus-technologies/api";

import styles from "./AnswersProgress.module.scss";

interface AnswersProgressProps {
  questions: CanopyQuestion[];
}

export const AnswersProgress: React.FC<AnswersProgressProps> = ({
  questions = []
}) => {
  const { t } = useTranslation("canopyComponents");

  const questionsCompleted = questions.filter(
    (question) => question.isAnswered === true
  ).length;
  const questionsTotal = questions.length;
  const completionPercentage = Math.round(
    (questionsCompleted / questionsTotal) * 100
  );

  return (
    <p className={styles.progress}>
      {completionPercentage}% {t("complete")}
    </p>
  );
};
