import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { EXPERT_FOLLOW_UP_STEPS } from "@arbolus-technologies/models/expert";
import { ExpertStoreActions } from "@arbolus-technologies/stores/expert";

import { MixPanelEventNames } from "../../../../Mixpanel/enums";
import { useArbolusTracking } from "../../../../Mixpanel/useArbolusTracking";

import styles from "./EngageExpert.module.scss";

interface EngageExpertProps {
  onBook: () => void;
  expertId: string;
  canopyId: string;
}

export const EngageExpert: React.FC<EngageExpertProps> = ({
  onBook,
  expertId,
  canopyId
}) => {
  const { t } = useTranslation("expertFollowUpSlidePanel");
  const dispatch = useDispatch();
  const { trackClick } = useArbolusTracking();

  const handleBookClick = () => {
    trackClick(MixPanelEventNames.CanopyFollowupBook, { canopyId, expertId });
    onBook();
  };

  const handleAskClick = () => {
    trackClick(MixPanelEventNames.CanopyFollowupAsk, { canopyId, expertId });
    dispatch(
      ExpertStoreActions.setExpertFollowUpStep(
        EXPERT_FOLLOW_UP_STEPS.SEND_ADDITIONAL_QUESTIONS
      )
    );
  };

  return (
    <div className={styles.engageExpertContainer}>
      <div className={styles.expertFollowUpTitle}>
        {t("expertFollowUpTitle")}
      </div>
      <div className={styles.details}>
        <div className={styles.leftContainer}>
          <div className={styles.title}>{t("bookCallTitle")}</div>
          <div className={styles.subTitle}>{t("bookCallSubTitle")}</div>
        </div>
        <Button
          text={t("book")}
          onClick={handleBookClick}
          endIcon="chevron_right"
        />
      </div>
      <div className={styles.details}>
        <div className={styles.leftContainer}>
          <div className={styles.title}>{t("sendQuestionsTitle")}</div>
          <div className={styles.subTitle}>{t("sendQuestionsSubTitle")}</div>
        </div>
        <Button
          text={t("ask")}
          onClick={handleAskClick}
          endIcon="chevron_right"
        />
      </div>
    </div>
  );
};
