import React from "react";
import { useTranslation } from "react-i18next";

import { ArbolusModal } from "@arbolus-technologies/ui/components";

interface ConfirmationModalsProps {
  questionModal?: {
    handleConfirmQuestion: () => void;
    isQuestionModalOpen: boolean;
    setIsQuestionModalOpen: (isOpen: boolean) => void;
  };
  detailsModal?: {
    handleConfirmDetails: () => void;
    isDetailsModalOpen: boolean;
    setIsDetailsModalOpen: (isOpen: boolean) => void;
  };
  backModal?: {
    handleConfirmGoBack: () => void;
    isGoBackModalOpen: boolean;
    setIsGoBackModalOpen: (isOpen: boolean) => void;
  };
  retakeModal?: {
    handleConfirmRetake: () => void;
    isRetakeModalOpen: boolean;
    setIsRetakeModalOpen: (isOpen: boolean) => void;
  };
  shortVideoModal?: {
    handleSaveShortVideo: () => void;
    handleConfirmShortVideo: () => void;
    isShortVideoModalOpen: boolean;
    setIsShortVideoModalOpen: (isOpen: boolean) => void;
  };
}

export const ConfirmationModals: React.FC<ConfirmationModalsProps> = ({
  questionModal,
  detailsModal,
  backModal,
  retakeModal,
  shortVideoModal
}) => {
  const { t } = useTranslation("canopyConfirmationModals");

  return (
    <>
      {backModal && (
        <ArbolusModal
          title={t("warningTitle")}
          subtitle={t("warningText")}
          toggle={() =>
            backModal.setIsGoBackModalOpen(!backModal.isGoBackModalOpen)
          }
          isOpen={backModal.isGoBackModalOpen}
          leftButton={{
            text: t("warningCancel"),
            type: "secondary",
            onClick: () =>
              backModal.setIsGoBackModalOpen(!backModal.isGoBackModalOpen)
          }}
          rightButton={{
            text: t("warningConfirm"),
            type: "rejection",
            onClick: backModal.handleConfirmGoBack
          }}
        />
      )}
      {questionModal && (
        <ArbolusModal
          title={t("warningTitle")}
          subtitle={t("warningText")}
          toggle={() =>
            questionModal.setIsQuestionModalOpen(
              !questionModal.isQuestionModalOpen
            )
          }
          isOpen={questionModal.isQuestionModalOpen}
          leftButton={{
            text: t("warningCancel"),
            type: "secondary",
            onClick: () =>
              questionModal.setIsQuestionModalOpen(
                !questionModal.isQuestionModalOpen
              )
          }}
          rightButton={{
            text: t("warningConfirm"),
            type: "rejection",
            onClick: questionModal.handleConfirmQuestion
          }}
        />
      )}
      {detailsModal && (
        <ArbolusModal
          title={t("warningTitle")}
          subtitle={t("warningText")}
          toggle={() =>
            detailsModal.setIsDetailsModalOpen(!detailsModal.isDetailsModalOpen)
          }
          isOpen={detailsModal.isDetailsModalOpen}
          leftButton={{
            text: t("warningCancel"),
            type: "secondary",
            onClick: () =>
              detailsModal.setIsDetailsModalOpen(
                !detailsModal.isDetailsModalOpen
              )
          }}
          rightButton={{
            text: t("warningConfirm"),
            type: "rejection",
            onClick: detailsModal.handleConfirmDetails
          }}
        />
      )}
      {retakeModal && (
        <ArbolusModal
          title={t("retakeTitle")}
          subtitle={t("retakeText")}
          toggle={() =>
            retakeModal.setIsRetakeModalOpen(!retakeModal.isRetakeModalOpen)
          }
          isOpen={retakeModal.isRetakeModalOpen}
          leftButton={{
            text: t("retakeCancel"),
            type: "secondary",
            onClick: () =>
              retakeModal.setIsRetakeModalOpen(!retakeModal.isRetakeModalOpen)
          }}
          rightButton={{
            text: t("retakeConfirm"),
            type: "rejection",
            onClick: retakeModal.handleConfirmRetake
          }}
        />
      )}
      {shortVideoModal && (
        <ArbolusModal
          title={t("shortVideoTitle")}
          subtitle={t("shortVideoText")}
          toggle={() =>
            shortVideoModal.setIsShortVideoModalOpen(
              !shortVideoModal.isShortVideoModalOpen
            )
          }
          isOpen={shortVideoModal.isShortVideoModalOpen}
          leftButton={{
            text: t("shortVideoSave"),
            type: "secondary",
            onClick: shortVideoModal.handleSaveShortVideo
          }}
          rightButton={{
            text: t("shortVideoRetake"),
            type: "primary",
            onClick: shortVideoModal.handleConfirmShortVideo
          }}
        />
      )}
    </>
  );
};
