/* eslint-disable @typescript-eslint/no-explicit-any */
import { Handlers, createReducer } from "reduxsauce";

import { CanopyQuestion } from "@arbolus-technologies/api";
import { CANOPY_STATUS } from "@arbolus-technologies/models/canopy";

import {
  BUILDER_RESET,
  CANOPY_CHANGE_STATUS,
  CANOPY_CHANGE_STATUS_FAILURE,
  CANOPY_CHANGE_STATUS_SUCCESS,
  CLEAR_CANOPY,
  CLEAR_CANOPY_ERRORS,
  CLEAR_ERRORS_BUILDER,
  CREATE_CANOPY,
  CREATE_CANOPY_FAILURE,
  CREATE_CANOPY_QUESTION,
  CREATE_CANOPY_QUESTION_FAILURE,
  CREATE_CANOPY_QUESTION_SUCCESS,
  CREATE_CANOPY_SUCCESS,
  CreateCanopyFailureAction,
  CreateCanopyQuestionSuccessAction,
  DELETE_CANOPY,
  DELETE_CANOPY_FAILURE,
  DELETE_CANOPY_QUESTION,
  DELETE_CANOPY_QUESTION_FAILURE,
  DELETE_CANOPY_QUESTION_SUCCESS,
  DELETE_CANOPY_SUCCESS,
  DeleteCanopyQuestionSuccessAction,
  DeleteCanopySuccessAction,
  EDIT_CANOPY,
  EDIT_CANOPY_FAILURE,
  EDIT_CANOPY_QUESTION,
  EDIT_CANOPY_QUESTION_FAILURE,
  EDIT_CANOPY_QUESTION_SUCCESS,
  EDIT_CANOPY_SUCCESS,
  EXIT_FROM_CREATE_CANOPY,
  EditCanopyFailureAction,
  EditCanopyQuestionFailureAction,
  EditCanopyQuestionSuccessAction,
  GET_CANOPY_CLIENT,
  GET_CANOPY_CLIENT_FAILURE,
  GET_CANOPY_CLIENT_QUESTIONS,
  GET_CANOPY_CLIENT_QUESTIONS_FAILURE,
  GET_CANOPY_CLIENT_QUESTIONS_SUCCESS,
  GET_CANOPY_CLIENT_SUCCESS,
  GET_PUBLIC_CANOPY,
  GET_PUBLIC_CANOPY_FAILURE,
  GET_PUBLIC_CANOPY_SUCCESS,
  GET_QUESTION,
  GET_QUESTION_FAILURE,
  GET_QUESTION_SUCCESS,
  GetCanopyClientQuestionsSuccessAction,
  GetCanopyClientSuccessAction,
  GetPublicCanopySuccessAction,
  GetQuestionSuccessAction,
  RESET_PANEL,
  UPLOAD_DOCUMENTS_TO_CANOPY_COMPLETED,
  UPLOAD_DOCUMENTS_TO_CANOPY_FAILED
} from "./actions/CanopyBuilderActionTypes";
import { CanopyBuilderReducerState } from "./models/definitions";

export const initialState: CanopyBuilderReducerState = {
  isCanopyLoading: true,
  isCanopySaving: false,
  canopy: {
    id: "",
    status: CANOPY_STATUS.DRAFT,
    title: "",
    overview: "",
    links: [],
    projectId: "",
    hasComplianceCheck: false,
    isAutomaticallyCreated: false,
    questionsCount: 0,
    targetCompanyId: null
  },
  error: null,
  loadingSurveyQuestions: false,
  canopyQuestions: [],
  selectedCanopy: null,
  selectedQuestion: null,
  isQuestionLoading: false,
  questionAddedCorrectly: false,
  canopySuccess: false,
  loadingQuestion: false,
  questionErrors: null,
  recordQuestion: false,
  isPublicCanopyLoading: false,
  publicCanopy: null,
  changeStatusLoading: false,
  isDeletingQuestion: false
};

// Get canopy Client
const handleGetCanopyClient = (
  state = initialState
): CanopyBuilderReducerState => ({
  ...state,
  isCanopyLoading: true
});

const handleGetCanopyClientSuccess = (
  state = initialState,
  { payload }: GetCanopyClientSuccessAction
): CanopyBuilderReducerState => ({
  ...state,
  canopy: payload.canopyData,
  isCanopyLoading: false
});

const handleGetCanopyClientFailure = (
  state = initialState
): CanopyBuilderReducerState => ({
  ...state,
  isCanopyLoading: false
});

// Get public canopy
const handleGetPublicCanopy = (
  state = initialState
): CanopyBuilderReducerState => ({
  ...state,
  isPublicCanopyLoading: true
});

const handleGetPublicCanopySuccess = (
  state = initialState,
  { payload }: GetPublicCanopySuccessAction
): CanopyBuilderReducerState => ({
  ...state,
  publicCanopy: payload.canopyPublicData,
  isPublicCanopyLoading: false
});

const handleGetPublicCanopyFailure = (
  state = initialState
): CanopyBuilderReducerState => ({
  ...state,
  isPublicCanopyLoading: false
});

// Delete canopy
const handleDeleteCanopy = (
  state = initialState
): CanopyBuilderReducerState => ({
  ...state,
  isCanopyLoading: true
});

const handleDeleteCanopySuccess = (
  state = initialState,
  { payload }: DeleteCanopySuccessAction
): CanopyBuilderReducerState => ({
  ...state,
  canopy: state.canopy,
  isCanopyLoading: false
});

const handleDeleteCanopyFailure = (
  state = initialState
): CanopyBuilderReducerState => ({
  ...state,
  isCanopyLoading: false
});

// Create canopy
const handleCreateCanopy = (
  state = initialState
): CanopyBuilderReducerState => ({
  ...state,
  isCanopySaving: true,
  canopySuccess: false,
  error: null
});

const handleCreateCanopySuccess = (
  state = initialState
): CanopyBuilderReducerState => ({
  ...state,
  error: null,
  canopySuccess: true
});

const handleCreateCanopyFailure = (
  state = initialState,
  { payload: { errors } }: CreateCanopyFailureAction
): CanopyBuilderReducerState => ({
  ...state,
  error: errors,
  isCanopySaving: false,
  canopySuccess: false
});

// Edit canopy
const handleEditCanopy = (state = initialState): CanopyBuilderReducerState => ({
  ...state,
  isCanopySaving: true,
  isCanopyLoading: false,
  canopySuccess: false,
  error: null
});

const handleEditCanopySuccess = (
  state = initialState
): CanopyBuilderReducerState => ({
  ...state,
  isCanopySaving: false,
  isCanopyLoading: false,
  canopySuccess: true,
  error: null
});

const handleEditCanopyFailure = (
  state = initialState,
  { payload: { error } }: EditCanopyFailureAction
): CanopyBuilderReducerState => ({
  ...state,
  error,
  isCanopySaving: false,
  canopySuccess: false
});

// Upload documents to canopy
const handleUploadDocumentsToCanopyFailed = (
  state = initialState
): CanopyBuilderReducerState => ({
  ...state,
  isCanopySaving: false
});

// Upload documents to canopy completed
const handleUploadDocumentsToCanopyCompleted = (
  state = initialState
): CanopyBuilderReducerState => ({
  ...state,
  isCanopySaving: false,
  error: null
});

// Exit from create canopy
const handleExitFromCreateCanopy = (): CanopyBuilderReducerState => ({
  ...initialState
});

const getCanopyClientQuestions = (
  state = initialState
): CanopyBuilderReducerState => ({
  ...state,
  loadingSurveyQuestions: true
});

const getCanopyClientQuestionsSuccess = (
  state = initialState,
  { payload }: GetCanopyClientQuestionsSuccessAction
): CanopyBuilderReducerState => {
  const canopyQuestionRev: CanopyQuestion[] = payload.canopyQuestions.map(
    (question) => ({
      canopyId: state.canopy.id,
      id: question.id,
      order: question.order,
      text: question.text,
      details: question.details,
      type: question.type,
      isAnswered: question.isAnswered,
      nextQuestionId: null,
      previousQuestionId: null
    })
  );

  return {
    ...state,
    loadingSurveyQuestions: false,
    canopyQuestions: canopyQuestionRev
  };
};

const getCanopyClientQuestionsFailure = (
  state = initialState
): CanopyBuilderReducerState => ({
  ...state,
  loadingSurveyQuestions: false
});

// Get Question
const getQuestion = (state = initialState): CanopyBuilderReducerState => ({
  ...state,
  isQuestionLoading: true
});

const getQuestionSuccess = (
  state = initialState,
  { payload }: GetQuestionSuccessAction
): CanopyBuilderReducerState => ({
  ...state,
  isQuestionLoading: false,
  selectedQuestion: payload.question
});

const getQuestionFailure = (
  state = initialState
): CanopyBuilderReducerState => ({
  ...state,
  isQuestionLoading: false
});

const resetPanel = (state = initialState): CanopyBuilderReducerState => ({
  ...state,
  selectedQuestion: null,
  questionAddedCorrectly: false,
  canopySuccess: false,
  questionErrors: null
});

const createCanopyQuestion = (
  state = initialState
): CanopyBuilderReducerState => ({
  ...state,
  loadingQuestion: true,
  questionAddedCorrectly: false
});

const createCanopyQuestionSuccess = (
  state = initialState,
  { payload }: CreateCanopyQuestionSuccessAction
): CanopyBuilderReducerState => {
  const order =
    payload.order === undefined ? state.canopyQuestions.length : payload.order;
  const newQuestion: any = {
    order,
    id: payload.newId,
    text: payload.text,
    details: payload.details,
    type: payload.type
  };
  const questions = [...state.canopyQuestions];
  if (payload.order !== undefined) {
    for (let i = order; i < state.canopyQuestions.length; i += 1) {
      questions[i].order += 1;
    }
  }

  questions.splice(order, 0, newQuestion);

  return {
    ...state,
    canopyQuestions: questions,
    questionAddedCorrectly: true,
    loadingQuestion: false
  };
};

const createCanopyQuestionFailure = (
  state = initialState,
  { payload: { error } }: EditCanopyQuestionFailureAction
): CanopyBuilderReducerState => ({
  ...state,
  questionAddedCorrectly: false,
  loadingQuestion: false,
  questionErrors: error
});

// CLEAR Selected Canopy
const clearSelectedCanopy = (
  state = initialState
): CanopyBuilderReducerState => ({
  ...state,
  canopy: {
    id: "",
    status: CANOPY_STATUS.DRAFT,
    title: "",
    overview: "",
    links: [],
    projectId: "",
    hasComplianceCheck: false,
    isAutomaticallyCreated: false,
    questionsCount: 0,
    targetCompanyId: null
  },
  canopyQuestions: []
});

// CLEAR Errors
const clearErrorsCanopy = (
  state = initialState
): CanopyBuilderReducerState => ({
  ...state,
  error: null
});

// EDIT Canopy Question

const editCanopyQuestion = (
  state = initialState
): CanopyBuilderReducerState => ({
  ...state,
  questionAddedCorrectly: false,
  loadingQuestion: true
});

const editCanopyQuestionSuccess = (
  state = initialState,
  { payload }: EditCanopyQuestionSuccessAction
): CanopyBuilderReducerState => {
  const newCanopyQuestions = state.canopyQuestions.map((question: any) =>
    question.id === payload.questionId
      ? ({
          id: question.id,
          order: question.order,
          text: payload.question,
          type: question.type
        } as CanopyQuestion)
      : question
  );

  return {
    ...state,
    questionAddedCorrectly: true,
    canopyQuestions: newCanopyQuestions,
    loadingQuestion: false
  };
};

const editCanopyQuestionFailure = (
  state = initialState,
  { payload: { error } }: EditCanopyQuestionFailureAction
): CanopyBuilderReducerState => ({
  ...state,
  questionAddedCorrectly: false,
  questionErrors: error,
  loadingQuestion: false
});

// DELETE CANOPY QUESTION

const deleteCanopyQuestion = (
  state = initialState
): CanopyBuilderReducerState => ({
  ...state,
  isDeletingQuestion: false
});

const deleteCanopyQuestionSuccess = (
  state = initialState,
  { payload }: DeleteCanopyQuestionSuccessAction
): CanopyBuilderReducerState => {
  const newArr = state.canopyQuestions
    .filter((e: any) => e.id !== payload.questionId)
    .map((question, index) => ({ ...question, order: index }));

  return {
    ...state,
    canopyQuestions: newArr,
    isDeletingQuestion: true
  };
};

const deleteCanopyQuestionFailure = (
  state = initialState
): CanopyBuilderReducerState => ({
  ...state,
  isDeletingQuestion: false
});

// CLEAR ERRORS BUILDER

const clearErrorsBuilder = (
  state = initialState
): CanopyBuilderReducerState => ({
  ...state,
  error: null
});

// CHANGE STATUS

const changeStatus = (state = initialState): CanopyBuilderReducerState => ({
  ...state,
  error: null,
  changeStatusLoading: true
});

const changeStatusSuccess = (
  state = initialState
): CanopyBuilderReducerState => ({
  ...state,
  error: null,
  changeStatusLoading: false
});

const changeStatusFailure = (
  state = initialState
): CanopyBuilderReducerState => ({
  ...state,
  error: null,
  changeStatusLoading: false
});

const canopyBuilderReset = (
  state = initialState
): CanopyBuilderReducerState => ({
  ...state,
  canopy: initialState.canopy
});

// Current `Handlers` definition not good enough for proper typing
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handlers: Handlers<CanopyBuilderReducerState, any> = {
  [GET_CANOPY_CLIENT]: handleGetCanopyClient,
  [GET_CANOPY_CLIENT_SUCCESS]: handleGetCanopyClientSuccess,
  [GET_CANOPY_CLIENT_FAILURE]: handleGetCanopyClientFailure,
  [GET_PUBLIC_CANOPY]: handleGetPublicCanopy,
  [GET_PUBLIC_CANOPY_SUCCESS]: handleGetPublicCanopySuccess,
  [GET_PUBLIC_CANOPY_FAILURE]: handleGetPublicCanopyFailure,
  [DELETE_CANOPY]: handleDeleteCanopy,
  [DELETE_CANOPY_SUCCESS]: handleDeleteCanopySuccess,
  [DELETE_CANOPY_FAILURE]: handleDeleteCanopyFailure,
  [CREATE_CANOPY]: handleCreateCanopy,
  [CREATE_CANOPY_SUCCESS]: handleCreateCanopySuccess,
  [CREATE_CANOPY_FAILURE]: handleCreateCanopyFailure,
  [EDIT_CANOPY]: handleEditCanopy,
  [EDIT_CANOPY_SUCCESS]: handleEditCanopySuccess,
  [EDIT_CANOPY_FAILURE]: handleEditCanopyFailure,
  [UPLOAD_DOCUMENTS_TO_CANOPY_FAILED]: handleUploadDocumentsToCanopyFailed,
  [UPLOAD_DOCUMENTS_TO_CANOPY_COMPLETED]:
    handleUploadDocumentsToCanopyCompleted,
  [GET_CANOPY_CLIENT_QUESTIONS]: getCanopyClientQuestions,
  [GET_CANOPY_CLIENT_QUESTIONS_SUCCESS]: getCanopyClientQuestionsSuccess,
  [GET_CANOPY_CLIENT_QUESTIONS_FAILURE]: getCanopyClientQuestionsFailure,
  [GET_QUESTION]: getQuestion,
  [GET_QUESTION_SUCCESS]: getQuestionSuccess,
  [GET_QUESTION_FAILURE]: getQuestionFailure,
  [EXIT_FROM_CREATE_CANOPY]: handleExitFromCreateCanopy,
  [RESET_PANEL]: resetPanel,
  [CREATE_CANOPY_QUESTION]: createCanopyQuestion,
  [CREATE_CANOPY_QUESTION_SUCCESS]: createCanopyQuestionSuccess,
  [CREATE_CANOPY_QUESTION_FAILURE]: createCanopyQuestionFailure,
  [EDIT_CANOPY_QUESTION]: editCanopyQuestion,
  [EDIT_CANOPY_QUESTION_SUCCESS]: editCanopyQuestionSuccess,
  [EDIT_CANOPY_QUESTION_FAILURE]: editCanopyQuestionFailure,
  [DELETE_CANOPY_QUESTION]: deleteCanopyQuestion,
  [DELETE_CANOPY_QUESTION_SUCCESS]: deleteCanopyQuestionSuccess,
  [DELETE_CANOPY_QUESTION_FAILURE]: deleteCanopyQuestionFailure,
  [CLEAR_CANOPY]: clearSelectedCanopy,
  [CLEAR_CANOPY_ERRORS]: clearErrorsCanopy,
  [CLEAR_ERRORS_BUILDER]: clearErrorsBuilder,
  [CANOPY_CHANGE_STATUS]: changeStatus,
  [CANOPY_CHANGE_STATUS_SUCCESS]: changeStatusSuccess,
  [CANOPY_CHANGE_STATUS_FAILURE]: changeStatusFailure,
  [BUILDER_RESET]: canopyBuilderReset
};

export const CanopyBuilderReducer = createReducer(initialState, handlers);
