import React from "react";
import Video from "react-video-renderer";

import styles from "./CustomVideoPlayer.module.scss";

interface CustomVideoPlayerProps {
  src: string;
  isCanopyV2?: boolean;
}

export const CustomVideoPlayer: React.FC<CustomVideoPlayerProps> = ({
  src,
  isCanopyV2
}) => (
  <Video controls src={`${src}#t=0.5`} preload="metadata">
    {(video, state) => (
      <div>
        <div
          className={
            isCanopyV2 ? styles.videoComponentCanopyV2 : styles.videoComponent
          }
        >
          {video}
        </div>
      </div>
    )}
  </Video>
);
