/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from "clsx";
import React from "react";
import { Control, Controller, FieldError } from "react-hook-form";
import { Input } from "reactstrap";

import { ValidatorErrors } from "../../../../models/api";

import styles from "./InputController.module.scss";

interface InputControllerProps {
  control: Control<any>;
  defaultValue?: string;
  placeholder: string;
  name: string;
  error?: FieldError;
  canopyErrors?: ValidatorErrors | null;
  required?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  size?: "medium" | "big";
  isInvalid?: boolean;
}

const InputController: React.FC<InputControllerProps> = ({
  control,
  defaultValue,
  placeholder,
  name,
  error,
  canopyErrors,
  required = false,
  disabled,
  multiline,
  size,
  isInvalid
}): JSX.Element => {
  const isCanopyError = !!(
    canopyErrors &&
    canopyErrors !== null &&
    typeof canopyErrors.Title !== "undefined"
  );
  return (
    <div className={styles.inputContainer}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ""}
        render={({ field }) => (
          <Input
            disabled={disabled}
            invalid={!!error || isInvalid || isCanopyError}
            className={clsx(styles.inputController, size ? styles[size] : null)}
            placeholder={required ? `* ${placeholder}` : placeholder}
            type={multiline ? "textarea" : "text"}
            {...field}
          />
        )}
      />
      {error && (
        <div className={styles.error} data-testid="error-yup">
          {error.message}
        </div>
      )}
      {isCanopyError && (
        <div className={styles.error} data-testid="error-yup">
          {canopyErrors && canopyErrors.Title[0]}
        </div>
      )}
    </div>
  );
};

export default InputController;
