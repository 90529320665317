import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  ANSWER_PREFERENCES_TYPE_VALUE,
  CanopyQuestionValue,
  CreateEditQuestionFields
} from "@arbolus-technologies/models/canopy";
import { CustomSelectController } from "@arbolus-technologies/ui/components";

import { DEFAULT_VALUE } from "../../../../../../helpers/constants";
import { useCanopyContext } from "../../../../../../helpers/useCanopyContext";
import { ValueRange } from "./ValueRange";
import { ValueTypeMultiRating } from "./ValueTypeMultiRating/ValueTypeMultiRating";

import styles from "./ValueTypes.module.scss";

export const ValueTypes: React.FC = () => {
  const { t } = useTranslation("canopyBuilder");
  const { isEditMode } = useCanopyContext();

  const {
    control,
    formState: { errors },
    watch,
    trigger
  } = useFormContext<CanopyQuestionValue>();

  const [
    answerPreferencesTypeValue,
    answerPreferencesTypeValueNumberInitialRange,
    answerPreferencesTypeValueNumberFinalRange
  ] = watch([
    CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE,
    CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE_NUMBER_INITIAL_RANGE,
    CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE_NUMBER_FINAL_RANGE
  ]);

  useEffect(() => {
    if (
      answerPreferencesTypeValueNumberInitialRange ||
      answerPreferencesTypeValueNumberFinalRange
    ) {
      trigger([
        CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE_NUMBER_INITIAL_RANGE,
        CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE_NUMBER_FINAL_RANGE
      ]);
    }
  }, [
    answerPreferencesTypeValueNumberFinalRange,
    answerPreferencesTypeValueNumberInitialRange,
    trigger
  ]);

  const answerPreferencesTypeValues = () => [
    {
      value: ANSWER_PREFERENCES_TYPE_VALUE.NUMBER,
      label: t("number")
    },
    {
      value: ANSWER_PREFERENCES_TYPE_VALUE.PERCENTAGE,
      label: t("percentage")
    },
    {
      value: ANSWER_PREFERENCES_TYPE_VALUE.MULTI_RATING,
      label: t("multiRating")
    }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.inputsContainer}>
        <div className={styles.selectContainer}>
          <CustomSelectController
            name={CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE}
            control={control}
            options={answerPreferencesTypeValues()}
            noOptionsMessage={t("noOptionsAvailable")}
            placeholder={t("selectPlaceholder")}
            error={
              errors[CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE]
                ?.message
            }
            disabled={isEditMode}
          />
        </div>
        <ValueRange />
      </div>
      <p className={styles.valueMessage}>
        {t(`value${answerPreferencesTypeValue}Message`, {
          min: answerPreferencesTypeValueNumberInitialRange ?? DEFAULT_VALUE,
          max: answerPreferencesTypeValueNumberFinalRange ?? DEFAULT_VALUE
        })}
      </p>
      {answerPreferencesTypeValue ===
        ANSWER_PREFERENCES_TYPE_VALUE.MULTI_RATING &&
        Number(answerPreferencesTypeValueNumberInitialRange) >= 0 &&
        Number(answerPreferencesTypeValueNumberFinalRange) > 0 && (
          <ValueTypeMultiRating />
        )}
    </div>
  );
};
