import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ClientService,
  DefaultToasterService,
  IErrorResponse,
  ProjectHistory,
  ToasterService
} from "@arbolus-technologies/api";
import { Loader } from "@arbolus-technologies/ui/components";

import { ProjectItem } from "./ProjectItem";

import styles from "./Project.module.scss";

interface ProjectProps {
  clientId: string;
  userId: string;
  notificationService?: ToasterService;
}

export const Project: React.FC<ProjectProps> = ({
  clientId,
  userId,
  notificationService = DefaultToasterService
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [projectList, setProjectList] = useState<ProjectHistory[]>([]);
  const { t } = useTranslation("clientUserSlidePanel");

  useEffect(() => {
    ClientService.getClientHistoryProjects(clientId, userId).subscribe(
      ({ items: projectList }) => {
        setIsLoading(false);
        setProjectList(projectList);
      },
      ({ message }: IErrorResponse) => {
        notificationService.showError(message);
        setIsLoading(false);
      }
    );
  }, [clientId, userId, notificationService]);

  return isLoading ? (
    <Loader isFull />
  ) : (
    <>
      <div className={styles.historyWrapper}>
        <span className={styles.projectHistoryLabel}>
          {t("projectHistory")}
        </span>
      </div>
      {projectList.length > 0 ? (
        projectList.map((project, key) => {
          const isLast = key === projectList.length - 1;
          return (
            <ProjectItem
              project={project}
              key={project.projectId}
              isLastItem={isLast}
            />
          );
        })
      ) : (
        <span>{t("noProjects")}</span>
      )}
    </>
  );
};
