import { Column, TableGenerics } from "@tanstack/react-table";
import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { Optional, useDebounce } from "@arbolus-technologies/utils";

import styles from "./TableTextFilter.module.scss";

interface TableTextFilterProps {
  displayName: string;
  debounceTime?: number;
  minLength?: number;
  column: Column<TableGenerics>;
  inputClassName?: string;
  onChange?: (value: string) => void;
}

export const TableTextFilter: React.FC<TableTextFilterProps> = ({
  displayName,
  debounceTime = 500,
  minLength = 1,
  column,
  inputClassName,
  onChange
}: TableTextFilterProps) => {
  const { setFilterValue } = column;

  const [currentTextFilter, setCurrentTextFilter] =
    useState<Optional<string>>();
  const debouncedTextFilter = useDebounce(currentTextFilter, debounceTime);
  useEffect(() => {
    if (debouncedTextFilter && debouncedTextFilter.length < minLength) {
      return;
    }

    setFilterValue(debouncedTextFilter);
  }, [setFilterValue, debouncedTextFilter, minLength]);

  const changeFilter = (text: string) => {
    if (text.length === 0) {
      setCurrentTextFilter(undefined);
    } else {
      setCurrentTextFilter(text);
    }
    onChange && onChange(text);
  };

  return (
    <div className="d-flex flex-row align-items-center">
      <div className={styles.searchIcon}>
        <Icon
          name="search"
          fontSize="18px"
          color={ARBOLUS_COLORS.bColorGrayIcon}
        />
      </div>
      <Input
        type="text"
        placeholder={displayName}
        value={currentTextFilter || ""}
        onChange={(event) => changeFilter(event.target.value)}
        className={clsx(styles.input, inputClassName)}
      />
      {!!currentTextFilter && (
        <Icon
          name="close"
          fontSize="18px"
          color={ARBOLUS_COLORS.bColorGrayIcon}
          onClick={() => changeFilter("")}
        />
      )}
    </div>
  );
};
