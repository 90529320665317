import { yupResolver } from "@hookform/resolvers/yup";
import saveAs from "file-saver";
import i18next from "i18next";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Label } from "reactstrap";

import {
  DefaultToasterService,
  ProjectClientService,
  ToasterService
} from "@arbolus-technologies/api";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import {
  DatePickerController,
  DateRange,
  DateRangeButton
} from "@arbolus-technologies/ui/components";
import {
  DATE_TIME_FORMAT,
  Nullable,
  daysToToday,
  formatDate,
  monthsToToday,
  string64toBlob
} from "@arbolus-technologies/utils";

import { ExportTransactionsSchema } from "./ExportTransactionsSchema";

import styles from "./ExportTransactions.module.scss";

const START_DATE = "startDate";
const END_DATE = "endDate";

interface ExportTransactionsProps {
  projectId: string;
  projectClientService?: typeof ProjectClientService;
  notificationService?: ToasterService;
}

interface ExportTransactionsFormProps {
  startDate: Nullable<Date>;
  endDate: Nullable<Date>;
}

export const ExportTransactions: React.FC<ExportTransactionsProps> = ({
  projectId,
  projectClientService = ProjectClientService,
  notificationService = DefaultToasterService
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("projectSpend");
  const [isAllDateSelected, setIsAllDateSelected] = useState(false);
  const newDate = new Date();
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    watch
  } = useForm<ExportTransactionsFormProps>({
    resolver: yupResolver(ExportTransactionsSchema),
    mode: "onChange",
    defaultValues: {
      startDate: daysToToday(7),
      endDate: newDate
    }
  });

  const dateRange: DateRange[] = [
    {
      name: t("lastDays", { count: 7 }),
      value: 7,
      type: "days",
      isSelected: false,
      id: 1
    },
    {
      name: t("lastDays", { count: 14 }),
      value: 14,
      type: "days",
      isSelected: false,
      id: 2
    },
    {
      name: t("lastDays", { count: 30 }),
      value: 30,
      type: "days",
      isSelected: false,
      id: 3
    },
    {
      name: t("lastMonths", { count: 3 }),
      value: 3,
      type: "months",
      isSelected: false,
      id: 4
    },
    {
      name: t("selectAll"),
      value: null,
      type: "all",
      isSelected: false,
      id: 5
    }
  ];

  const [dateRangeButtons, setDateRangeButtons] =
    useState<DateRange[]>(dateRange);

  const onSubmit = (data: ExportTransactionsFormProps) => {
    const fromDateFormatted =
      data.startDate === null
        ? null
        : formatDate(data.startDate, DATE_TIME_FORMAT);
    const toDateFormatted =
      data.endDate === null ? null : formatDate(data.endDate, DATE_TIME_FORMAT);
    projectClientService
      .exportTransactions(projectId, fromDateFormatted, toDateFormatted)
      .subscribe({
        next: (response) => {
          const blob = string64toBlob(response.file, "text/csv");
          saveAs(blob, response.name);
          notificationService.showSuccess(
            i18next.t("transcript:downloadSuccess")
          );
          dispatch(PanelStoreActions.closePanel(PanelId.ProjectSpend));
        },
        error: notificationService.showApiErrors
      });
  };

  const handleButtonSelected = (dateRangeItem: DateRange) => {
    dateRangeButtons.forEach((dateButton) =>
      dateButton.id === dateRangeItem.id
        ? (dateButton.isSelected = !dateButton.isSelected)
        : (dateButton.isSelected = false)
    );
    setDateRangeButtons(dateRangeButtons);
  };

  const customDateSelected = () => {
    dateRangeButtons.forEach((dateButton) => (dateButton.isSelected = false));
  };

  const setDateValues = (dateRangeItem: DateRange) => {
    handleButtonSelected(dateRangeItem);
    if (dateRangeItem.type === "days") {
      setIsAllDateSelected(false);
      setValue("startDate", daysToToday(dateRangeItem.value!));
      setValue("endDate", newDate);
    }
    if (dateRangeItem.type === "months") {
      setIsAllDateSelected(false);
      setValue("startDate", monthsToToday(dateRangeItem.value!));
      setValue("endDate", newDate);
    }
    if (dateRangeItem.type === "all") {
      setIsAllDateSelected(!isAllDateSelected);
      setValue("startDate", null);
      setValue("endDate", null);
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t("exportProjectSpend")}</h1>
      <p className={styles.text}>{t("exportProjectSpendText")}</p>
      <div className={styles.dateSelectors}>
        <div className={styles.dateTimeContainerLeft}>
          <Label className={styles.dateLabel}>{t("from")}</Label>
          <DatePickerController
            name={START_DATE}
            control={control}
            error={errors[START_DATE]}
            disabled={isAllDateSelected}
            maxDate={watch("endDate")!}
            customDateSelected={customDateSelected}
            placeholder=" "
          />
        </div>
        <div className={styles.dateTimeContainerRight}>
          <Label className={styles.dateLabel}>{t("to")}</Label>
          <DatePickerController
            name={END_DATE}
            control={control}
            error={errors[END_DATE]}
            disabled={isAllDateSelected}
            maxDate={new Date()}
            minDate={watch("startDate")!}
            customDateSelected={customDateSelected}
          />
        </div>
      </div>
      <div className={styles.rangeSelectors}>
        <Label className={styles.dateLabel}>{t("dataRange")}</Label>
        <div className={styles.rangeButtonsContainer}>
          {dateRangeButtons.map((dateRangeItem) => (
            <DateRangeButton
              key={dateRangeItem.id}
              dateRangeItem={dateRangeItem}
              setDateValues={setDateValues}
            />
          ))}
        </div>
      </div>

      <Button
        color="primary"
        onClick={handleSubmit(onSubmit)}
        className={styles.exportButton}
      >
        {t("export")}
      </Button>
    </div>
  );
};
