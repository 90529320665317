import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Label, Row } from "reactstrap";

import { TimeZone } from "@arbolus-technologies/api";
import { classificationTypesValues } from "@arbolus-technologies/features/common";
import { ClassificationType } from "@arbolus-technologies/models/common";
import {
  PROJECT_TIMELINE_FORMAT,
  formatDate
} from "@arbolus-technologies/utils";

import AccessManager from "../../../../../contexts/roleBasedAccess/AccessManager";
import { User } from "../../../../../models/user";
import { UtilsService } from "../../../../../services";
import ProjectMetaTag from "../tag/ProjectMetaTag";

interface ProjectDetailsPaneProps {
  timezoneMap: Map<string, TimeZone>;
  timezone?: string;
  projectName: string;
  overview: string;
  internalOverview?: string;
  startDate?: Date;
  endDate?: Date;
  mainContact?: User;
  caseCode?: string;
  contactNumber?: string;
  lastUpdated?: Date;
  updatedUsername?: string;
  onDetailsEditClick: () => void;
  classification: string;
  classificationDescription?: string;
  industry: { id: string; name: string };
}

const ProjectDetailsPane: React.FC<ProjectDetailsPaneProps> = ({
  timezoneMap,
  timezone,
  mainContact,
  caseCode,
  contactNumber,
  endDate,
  lastUpdated,
  overview,
  internalOverview,
  projectName,
  startDate,
  updatedUsername,
  onDetailsEditClick,
  classification,
  classificationDescription,
  industry
}: ProjectDetailsPaneProps) => {
  const { t } = useTranslation("projectBrief");

  const createdDate =
    startDate &&
    formatDate(
      UtilsService.convertUTCToActiveZone(startDate),
      PROJECT_TIMELINE_FORMAT
    );

  const end = endDate
    ? formatDate(
        UtilsService.convertUTCToActiveZone(endDate),
        PROJECT_TIMELINE_FORMAT
      )
    : t("ongoing");

  const timeline = `${createdDate} - ${end}`;

  const lastUpdateDate =
    lastUpdated &&
    formatDate(
      UtilsService.convertUTCToActiveZone(lastUpdated),
      PROJECT_TIMELINE_FORMAT
    );

  return (
    <div className="project-details">
      <div className="header-section">
        <div className="left-section">
          <h1>{t("projectDetails")}</h1>
        </div>
        <div className="right-section">
          <AccessManager permission="projectBrief:editProject">
            <Button size="sm" color="primary" onClick={onDetailsEditClick}>
              {t("edit")}
            </Button>
          </AccessManager>
        </div>
      </div>
      <div className="body-section">
        <Row>
          <Label>{t("name")}</Label>
        </Row>
        <Row>
          <p>{projectName}</p>
        </Row>
        <Row>
          <Col>
            <Row>
              <Label>{t("classification")}</Label>
            </Row>
            <Row>
              <p>
                {
                  classificationTypesValues.find(
                    (classificationType) =>
                      classificationType.value === classification
                  )?.label
                }
              </p>
            </Row>
          </Col>
          {classification === ClassificationType.Other && (
            <Col>
              <Row>
                <Label>{t("classificationDescription")}</Label>
              </Row>
              <Row>
                <p>{classificationDescription}</p>
              </Row>
            </Col>
          )}
        </Row>
        <Row>
          <Label>{t("industry")}</Label>
        </Row>
        <Row>
          <p>{industry.name}</p>
        </Row>
        {internalOverview && (
          <>
            <Row>
              <Label>{t("internalBrief")}</Label>
            </Row>
            <Row className="rich-content">
              {UtilsService.parseRichTextContent(internalOverview)}
            </Row>
          </>
        )}
        <Row>
          <AccessManager permission="projectBrief:externalOverview">
            <Label>{t("externalBrief")}</Label>
          </AccessManager>
          <AccessManager permission="projectBrief:overview">
            <Label>{t("overview")}</Label>
          </AccessManager>
        </Row>
        <Row className="rich-content">
          {UtilsService.parseRichTextContent(overview)}
        </Row>

        <Row>
          {startDate && (
            <Col>
              <Row>
                <Label>{t("timeline")}</Label>
              </Row>
              <Row>
                <ProjectMetaTag message={timeline} />
              </Row>
            </Col>
          )}
          {caseCode && (
            <Col>
              <Row>
                <Label>{t("caseCode")}</Label>
              </Row>
              <Row>
                <ProjectMetaTag message={caseCode} />
              </Row>
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            {mainContact && (
              <>
                <Row>
                  <Label>{t("mainContact")}</Label>
                </Row>
                <Row>
                  <ProjectMetaTag user={mainContact} />
                </Row>
              </>
            )}
          </Col>
          {contactNumber && (
            <Col>
              <Row>
                <Label>{t("contactNumber")}</Label>
              </Row>
              <Row>
                <ProjectMetaTag
                  message={UtilsService.formatPhoneNumber(contactNumber)}
                />
              </Row>
            </Col>
          )}
        </Row>
        {timezone && (
          <Row>
            <Col>
              <Row>
                <Label>{t("timezone")}</Label>
              </Row>
              <Row>
                <ProjectMetaTag
                  message={timezoneMap.get(timezone)?.displayText}
                />
              </Row>
            </Col>
          </Row>
        )}

        {lastUpdated && updatedUsername && (
          <Row className="last-updated-details">
            {t("projectLastUpdate", {
              userName: updatedUsername,
              lastUpdateDate
            })}
          </Row>
        )}
      </div>
    </div>
  );
};

export default ProjectDetailsPane;
