import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ANSWER_PREFERENCES_TYPE_VALUE } from "@arbolus-technologies/models/canopy";
import {
  ANSWER_TYPE,
  AnswerValue,
  ChoiceValueAnswer,
  ValueSelectedQuestion
} from "@arbolus-technologies/models/canopy-panels";
import { HorizontalProgressComponentGroup } from "@arbolus-technologies/ui/components";
import { APP_DATE_FORMAT } from "@arbolus-technologies/utils";

import { convertToPercentage } from "../../../utils";
import { ExpertsListForCanopyAnswersSlidePanel } from "../../ExpertsListForCanopyAnswersSlidePanel/ExpertsListForCanopyAnswersSlidePanel";

interface ValueTypeViewerProps {
  data: AnswerValue;
}

export const ValueTypeViewer: React.FC<ValueTypeViewerProps> = ({ data }) => {
  const { t } = useTranslation("valueTypeViewer");

  const {
    lastResponseDate,
    questionId,
    rangeMax,
    rangeMin,
    valueAnswers,
    valuePreferencesType
  } = data;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedQuestionDetails, setSelectedQuestionDetails] =
    useState<ValueSelectedQuestion | null>(null);

  const handleExpertsLink = useCallback(
    (value: string | null, percentage: number, choice: ChoiceValueAnswer) => {
      setSelectedQuestionDetails({
        value,
        percentage,
        choice: {
          id: choice.multiRatingChoiceId,
          text: choice.multiRatingChoiceText
        }
      });
      setIsOpen(true);
    },
    []
  );

  const convertedArray = useMemo(
    () =>
      valueAnswers.map((item) => {
        const value = item.answersAverage?.toFixed(2) ?? null;
        const avg = value
          ? t("avg", {
              average: value,
              percent:
                valuePreferencesType ===
                ANSWER_PREFERENCES_TYPE_VALUE.PERCENTAGE
                  ? "%"
                  : ""
            })
          : t("n/a");
        const percentage = item.answersAverage
          ? convertToPercentage(rangeMin, rangeMax, item.answersAverage)
          : 0;

        return {
          id: item.multiRatingChoiceId ?? questionId,
          title: item.multiRatingChoiceText ?? "",
          progressEndClick: () => handleExpertsLink(value, percentage, item),
          progressEndText: t("Experts"),
          progressText: avg,
          value: percentage,
          hasValue: item.answersAverage !== null
        };
      }),
    [
      handleExpertsLink,
      questionId,
      rangeMax,
      rangeMin,
      t,
      valueAnswers,
      valuePreferencesType
    ]
  );

  const lastResponse =
    lastResponseDate === null
      ? ""
      : `${t("lastResponse")}: ${moment(lastResponseDate).format(
          APP_DATE_FORMAT
        )}`;

  return (
    <>
      <HorizontalProgressComponentGroup
        headerText={t(`header${valuePreferencesType}`, {
          rangeMin,
          rangeMax
        })}
        footerText={lastResponse}
        data={convertedArray}
      />
      <ExpertsListForCanopyAnswersSlidePanel
        questionId={questionId}
        selectedQuestionDetails={selectedQuestionDetails}
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
        panelTitle={t("answersTitle", { title: valuePreferencesType })}
        panelHeader={{
          title:
            selectedQuestionDetails?.choice.text ??
            t(`average${valuePreferencesType}`, {
              value: selectedQuestionDetails?.value ?? t("n/a")
            }),
          message: `${
            selectedQuestionDetails?.choice.id
              ? t("avgValue", { value: selectedQuestionDetails?.value })
              : ""
          }`
        }}
        type={ANSWER_TYPE.VALUE}
      />
    </>
  );
};
