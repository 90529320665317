import React, { ReactNode, useState } from "react";
import { CarouselIndicators, Carousel as CarouselReactstrap } from "reactstrap";

import styles from "./Carousel.module.scss";

interface CarouselProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any[];
  children: ReactNode;
}

export const Carousel: React.FC<CarouselProps> = ({ items, children }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [isAnimating, setIsAnimating] = useState<boolean>(false);

  const next = () => {
    if (isAnimating) return;
    if (activeIndex === items.length - 1) {
      setActiveIndex(0);
    } else {
      setActiveIndex((prev) => prev + 1);
    }
  };

  const previous = () => {
    if (isAnimating) return;
    if (activeIndex === 0) {
      setActiveIndex(items.length - 1);
    } else {
      setActiveIndex((prev) => prev - 1);
    }
  };

  const goToIndex = (newIndex: number) => {
    if (isAnimating) return;
    setActiveIndex(newIndex);
  };

  return (
    <CarouselReactstrap
      activeIndex={activeIndex}
      next={(): void => next()}
      previous={(): void => previous()}
      className={styles.carousel}
    >
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={(newIndex: number) => goToIndex(newIndex)}
        onAnimationStart={() => setIsAnimating(true)}
        onAnimationEnd={() => setIsAnimating(false)}
        className={styles.carouselIndicators}
      />
      {children}
    </CarouselReactstrap>
  );
};
