/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";

import { Checkbox } from "../Checkbox/Checkbox";

interface CheckboxControllerProps {
  control: Control<any>;
  name: string;
  errors: FieldErrors<any>;
  disabled?: boolean;
  text: string;
  onChangeHandler?: (
    checked: boolean,
    onChange: (checked: boolean) => void
  ) => void;
}

export const CheckboxController: React.FC<CheckboxControllerProps> = ({
  control,
  name,
  errors,
  disabled = false,
  text,
  onChangeHandler
}): JSX.Element => {
  const isError = !!(Object.keys(errors).length > 0 && errors[name]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => (
        <Checkbox
          isChecked={value}
          isDisabled={disabled}
          onChange={(e: any) => {
            const checked: boolean = e.target.checked;
            if (onChangeHandler) {
              onChangeHandler(checked, onChange);
              return;
            }
            onChange(checked);
          }}
          text={text}
          onBlur={onBlur}
          isInvalid={isError}
        />
      )}
    />
  );
};
