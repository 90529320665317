import {
  DisplayUser,
  convertUTCToActiveZone,
  utilService as newUtilService
} from "@arbolus-technologies/utils";

import { MAXIMUM_FRACTION_DIGITS } from "../constants/validation";

export const utilService = {
  ...newUtilService,

  convertUTCToActiveZone,

  displayUserName(user: DisplayUser, isFullName = true): string {
    return newUtilService.displayUserName(user, isFullName);
  },

  // Different implementations in ciq-admin and ciq-client
  shapeUpValues: (
    value: number,
    maximumFractionDigits = MAXIMUM_FRACTION_DIGITS
  ): number => {
    const fractionLimit = 10 ** maximumFractionDigits;
    return Math.floor(value * fractionLimit) / fractionLimit;
  },

  // Different implementations in ciq-admin and ciq-client
  maxLengthString: (text: string, maxLength: number): string =>
    text.length >= maxLength
      ? text.substring(0, maxLength) + String.fromCharCode(8230)
      : text
};
