import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./AngleBadge.module.scss";

interface AngleBadgeProps {
  title: string;
  color: string;
}

export const AngleBadge: React.FC<AngleBadgeProps> = ({ title, color }) => {
  const { t } = useTranslation("anglesPane");

  return (
    <div className={styles.container}>
      <div className={styles.title}>{t("assignedAngle")}</div>
      <div className={styles.badge} style={{ background: `${color}` }}>
        {title}
      </div>
    </div>
  );
};
