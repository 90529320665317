import { Icon } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  TRANSCRIPT_EDIT_MODE,
  TRANSCRIPT_STATUS
} from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

import styles from "./TranscriptButtons.module.scss";

interface TranscriptButtonsProps {
  transcriptStatus: TRANSCRIPT_STATUS;
  handleSetTranscriptState: (mode: TRANSCRIPT_EDIT_MODE) => void;
  handleSetResetModal: (value: boolean) => void;
}

export const TranscriptButtons: React.FC<TranscriptButtonsProps> = ({
  transcriptStatus,
  handleSetTranscriptState,
  handleSetResetModal
}) => {
  const { t } = useTranslation("answer");
  const isAdmin = useSelector(CacheSelector.isAdmin());

  const isEditable =
    (transcriptStatus === TRANSCRIPT_STATUS.SMART_TRANSCRIPT ||
      transcriptStatus === TRANSCRIPT_STATUS.AUTOMATIC_TRANSCRIPT) &&
    isAdmin;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isEditable && (
        <div className={styles.innerButtonsContainer}>
          <div
            onClick={() => handleSetTranscriptState(TRANSCRIPT_EDIT_MODE.EDIT)}
            title={t("edit")}
            className={styles.icon}
          >
            <Icon name="Edit" color="#7f849f" fontSize="24px" />
          </div>
          <div
            onClick={() => handleSetResetModal(true)}
            title={t("reset")}
            className={styles.icon}
          >
            <Icon name="Undo" color="#7f849f" fontSize="24px" />
          </div>
        </div>
      )}
    </>
  );
};
