import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import {
  FieldError,
  SubmitHandler,
  useFieldArray,
  useForm
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "reactstrap";

import {
  ApiError,
  CompleteValidatorErrors,
  DownloadCanopyInterface,
  FileInterface,
  FormInterface,
  LinksUrlProps,
  UploadingAttachment,
  UserModel,
  ValidatorErrors
} from "@arbolus-technologies/api";
import {
  CANOPY_FAQ_URL,
  CanopyConstraints,
  SURVEY_FORM_TYPE
} from "@arbolus-technologies/models/canopy";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  CanopyBuilderSelector,
  CanopyBuilderStoreActions
} from "@arbolus-technologies/stores/canopy-builder";
import { thinkingFace } from "@arbolus-technologies/theme";
import {
  CustomSwitchController,
  InfoDrawer,
  InputController,
  OrderedList,
  WordCounter
} from "@arbolus-technologies/ui/components";
import { normalizeFileName } from "@arbolus-technologies/utils";

import { MixPanelEventNames } from "../../Mixpanel/enums";
import { useArbolusTracking } from "../../Mixpanel/useArbolusTracking";
import { AdminSelector } from "../../Selectors/UserSelectors/AdminSelector";
import { ReferenceMaterialController } from "../ReferenceMaterialController/ReferenceMaterialController";
import { CreateSurveyActions } from "./CreateSurveyActions/CreateSurveyActions";
import { CreateSurveySchema } from "./CreateSurveySchema";

import styles from "./CreateSurvey.module.scss";

interface LinkProps {
  url: string;
}

interface SubmitDataProps {
  links: LinkProps[];
  title: string;
  overview: string;
  hasComplianceCheck: boolean;
  defaultOwner: UserModel | null;
}

const setUrlLinks = (links: string[]) => {
  const linkArrayUrls: LinksUrlProps[] = [];
  links.forEach((link) => {
    const linksUrl: LinksUrlProps = {
      url: link
    };
    linkArrayUrls.push(linksUrl);
  });
  return linkArrayUrls;
};

const getCanopyCreateRequest = (
  isAdmin: boolean,
  data: SubmitDataProps,
  projectId: string
) => {
  const commonFields = {
    projectId,
    title: data.title.trim(),
    overview: data.overview.trim(),
    links: data.links.flatMap((link) => link.url),
    hasComplianceCheck: data.hasComplianceCheck
  };
  const adminFields = {
    defaultOwnerId: data.defaultOwner?.id
  };
  const createRequest = isAdmin
    ? { ...commonFields, ...adminFields }
    : commonFields;
  return createRequest;
};

const getCanopyEditRequest = (data: SubmitDataProps, canopyId: string) => ({
  canopyId,
  title: data.title.trim(),
  overview: data.overview.trim(),
  links: data.links.flatMap((link) => link.url),
  hasComplianceCheck: data.hasComplianceCheck,
  defaultOwnerId: data.defaultOwner?.id
});

const getCanopyFormDataDocuments = (uploadingFiles: UploadingAttachment[]) => {
  const canopyDocuments = [] as File[];
  const canopyFormDataDocuments = [] as FormData[];
  uploadingFiles.forEach((file) => canopyDocuments.push(file.attachment));
  canopyDocuments.forEach((document) => {
    const formData = new FormData();

    const fileNameParsed = normalizeFileName(document.name);
    formData.append("document", document, fileNameParsed);

    canopyFormDataDocuments.push(formData);
  });
  return canopyFormDataDocuments;
};

interface CreateSurveyProps {
  type: string;
  canopyId: string;
  projectId: string;
  closePanel: () => void;
  showError: (error: string) => void;
}

export const CreateSurvey: React.FC<CreateSurveyProps> = ({
  type,
  canopyId,
  projectId,
  closePanel,
  showError
}) => {
  const { t } = useTranslation("createSurvey");
  const dispatch = useDispatch();
  const { trackSlidePanel, trackClick } = useArbolusTracking();

  const [uploadingFiles, setUploadingFiles] = useState<UploadingAttachment[]>(
    []
  );
  const [documentsToRemove, setDocumentsToRemove] = useState<string[]>([]);

  const isCanopySaving = useSelector(
    CanopyBuilderSelector.canopySavingSelector()
  );
  const isAdmin = useSelector(CacheSelector.isAdmin());
  const apiErrors = useSelector(CanopyBuilderSelector.canopyErrorsSelector());
  const {
    title,
    overview,
    links = [],
    documents = [],
    hasComplianceCheck,
    defaultOwner
  } = useSelector(CanopyBuilderSelector.canopySelector());
  const canopySuccess = useSelector(CanopyBuilderSelector.isCanopySuccessful());

  useEffect(() => {
    trackSlidePanel(MixPanelEventNames.CanopyCreationPanel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(CanopyBuilderStoreActions.clearErrorsBuilder());
  }, [dispatch]);

  useEffect(() => {
    if (canopySuccess === true) {
      closePanel();
      dispatch(CanopyBuilderStoreActions.resetPanel());
    }
  }, [canopySuccess, closePanel, dispatch]);

  const linkArrayUrls = setUrlLinks(links);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isDirty }
  } = useForm<FormInterface>({
    resolver: yupResolver(CreateSurveySchema),
    mode: "onChange",
    defaultValues: {
      isAdmin: isAdmin,
      title,
      overview,
      links: linkArrayUrls,
      documents,
      hasComplianceCheck:
        type === SURVEY_FORM_TYPE.EDIT ? hasComplianceCheck : true,
      defaultOwner: defaultOwner
    }
  });
  const {
    fields: linkFields,
    append: linkAppend,
    remove: linkRemove
  } = useFieldArray<FormInterface>({
    control,
    name: "links",
    keyName: "id"
  });
  const {
    fields: downloadFields,
    append: downloadAppend,
    remove: downloadRemove
  } = useFieldArray<FormInterface>({
    control,
    name: "documents",
    keyName: "id"
  });

  const onSubmit: SubmitHandler<FormInterface> = (data) => {
    const canopyCreateRequest = getCanopyCreateRequest(
      isAdmin,
      data,
      projectId
    );
    const canopyEditRequest = getCanopyEditRequest(data, canopyId);
    const canopyFormDataDocuments = getCanopyFormDataDocuments(uploadingFiles);
    if (type === SURVEY_FORM_TYPE.CREATE) {
      trackClick(MixPanelEventNames.CanopyCreationPanelAddQuestions);

      dispatch(
        CanopyBuilderStoreActions.createCanopy(
          canopyCreateRequest,
          canopyFormDataDocuments
        )
      );
    }
    if (type === SURVEY_FORM_TYPE.EDIT) {
      dispatch(
        CanopyBuilderStoreActions.editCanopy(
          canopyEditRequest,
          canopyFormDataDocuments,
          documentsToRemove
        )
      );
    }
  };

  const handleSetUploadingFiles = (documents: UploadingAttachment[]) =>
    setUploadingFiles(documents);

  const handleSetDocumentsToRemove = (id: string) =>
    setDocumentsToRemove((prevState) => [...prevState, id]);

  const getCanopyErrors = (): ValidatorErrors | null => {
    if (!apiErrors) {
      return null;
    }

    const apiError = new ApiError(apiErrors);
    if (apiError.isDefaultError) {
      return null;
    }
    return (apiErrors as CompleteValidatorErrors).errors;
  };

  const shouldBeDisabled = () => {
    if (watch("overview") !== "" && watch("title") !== "") return false;
    if (links.length === 0 && !isDirty) return true;
    return true;
  };

  const stepList = [
    t("drawerStep1"),
    t("drawerStep2"),
    t("drawerStep3"),
    t("drawerStep4")
  ];

  const collapseContent = (
    <>
      <p className={styles.infoDrawerSubtitle}>{t("drawerSubtitle")}</p>
      <div className={styles.orderListContainer}>
        <OrderedList items={stepList} isNumberBold />
      </div>
      <div className={styles.helpLinkContainer}>
        <a
          target="_blank"
          rel="noreferrer"
          href={CANOPY_FAQ_URL}
          className={styles.helpLink}
        >
          {t("needMoreHelpLink")}
        </a>
      </div>
    </>
  );

  return (
    <div className={styles.container}>
      {!isAdmin && (
        <InfoDrawer
          icon={thinkingFace}
          title={t("drawerTitle")}
          collapseContent={collapseContent}
        />
      )}

      <div className={styles.inputContainer}>
        <Label className={styles.labelSurvey}>{t("title")}</Label>
        <InputController
          name="title"
          error={errors.title}
          validatorErrors={getCanopyErrors()}
          control={control}
          placeholder={t("title")}
          type="textarea"
          size="medium"
          onTrackInput={() =>
            trackClick(MixPanelEventNames.CanopyCreationPanelTitle)
          }
        />
        <WordCounter
          maxLength={CanopyConstraints.MAX_CANOPY_TITLE_LENGTH}
          textLength={watch("title").length}
        />
      </div>
      <div className={styles.inputContainer}>
        <Label className={styles.labelSurvey}>{t("overview")}</Label>
        <InputController
          name="overview"
          error={errors.overview}
          control={control}
          placeholder={t("overview")}
          type="textarea"
          size="big"
          onTrackInput={() =>
            trackClick(MixPanelEventNames.CanopyCreationPanelDescription)
          }
        />
        <WordCounter
          maxLength={CanopyConstraints.MAX_CANOPY_OVERVIEW_LENGTH}
          textLength={watch("overview").length}
        />
      </div>
      <Label className={styles.labelSurvey}>{t("referenceMaterial")}</Label>
      <ReferenceMaterialController
        control={control}
        errors={errors}
        uploadingFiles={uploadingFiles}
        setUploadingFiles={handleSetUploadingFiles}
        links={linkFields as FileInterface[]}
        documents={downloadFields as DownloadCanopyInterface[]}
        type={type}
        linkAppend={linkAppend}
        linkRemove={linkRemove}
        downloadAppend={downloadAppend}
        downloadRemove={downloadRemove}
        showError={showError}
        setDocumentsToRemove={handleSetDocumentsToRemove}
      />

      {isAdmin && (
        <>
          <Label className={styles.labelSettingsSurvey}>{t("settings")}</Label>
          <div className={styles.horizontalInputsContainer}>
            <div className={styles.inputField}>
              <Label className={styles.labelSettings}>Owner</Label>
              <AdminSelector
                name="defaultOwner"
                control={control}
                error={errors.defaultOwner as FieldError}
                placeholderText="Default Owner"
              />
            </div>
          </div>
          <Label className={styles.labelAdminSurvey}>{t("adminReview")}</Label>
          <div className={styles.complianceContainer}>
            <Label className={styles.labelComplianceSurvey}>
              {t("complianceCheck")}
            </Label>
            <div className={styles.customSwitch}>
              <CustomSwitchController
                name={"hasComplianceCheck"}
                errors={errors}
                control={control}
                disabled
              />
            </div>
          </div>
        </>
      )}
      <CreateSurveyActions
        disableButton={shouldBeDisabled() || Object.keys(errors).length > 0}
        isCanopySaving={isCanopySaving}
        type={type}
        closePanel={closePanel}
        handleSubmit={handleSubmit(onSubmit)}
      />
    </div>
  );
};
