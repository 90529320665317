import { useSelector } from "react-redux";

import { UserRole } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { extractBoolEnvVariable } from "@arbolus-technologies/utils";

import { useUserProfile } from "./useUserProfile";

const IS_ADMIN_APP = extractBoolEnvVariable("NX_PUBLIC_IS_ADMIN_APP") === true;

export function useIsChatAvailable(): boolean {
  const isChatEnabled = useSelector(ProjectNxSelector.isChatEnabled());
  const userProfile = useUserProfile();
  const userRole = useSelector(CacheSelector.generateActiveUserRole());
  // Caveat: chat is disabled for Arbolus team users in ciq-client app
  const isExpertOrAdmin =
    userRole === UserRole.expert ||
    (userRole === UserRole.arbolusTeam && IS_ADMIN_APP);
  return isChatEnabled
    ? !userProfile.complianceManager
    : !userProfile.complianceManager && isExpertOrAdmin;
}
