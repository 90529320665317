import { CIQError, ErrorResponse } from "@arbolus-technologies/api";
import { BookmarkCollection } from "@arbolus-technologies/models/common";

export const GET_COLLECTION = "BOOKMARKS_GET_COLLECTIONS";
export const GET_COLLECTION_SUCCESS = "BOOKMARKS_GET_COLLECTION_SUCCESS";
export const GET_COLLECTION_FAILURE = "BOOKMARKS_GET_COLLECTION_FAILURE";

export interface GetCollectionAction {
  type: typeof GET_COLLECTION;
  payload: {
    id: string;
  };
}

export interface GetCollectionSuccessAction {
  type: typeof GET_COLLECTION_SUCCESS;
  payload: {
    response: BookmarkCollection;
  };
}

export interface GetCollectionFailureAction {
  type: typeof GET_COLLECTION_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}
