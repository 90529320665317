import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

import { ShortTextAnswer } from "@arbolus-technologies/models/canopy-panels";
import { UserPortrait } from "@arbolus-technologies/ui/components";

import styles from "./ShortTextTypeViewer.module.scss";

interface ShortTextTypeViewerProps {
  data: ShortTextAnswer;
  handleOpenProfile: (expertId: string) => void;
}

export const ShortTextTypeViewer: React.FC<ShortTextTypeViewerProps> = ({
  data,
  handleOpenProfile
}) => {
  const { t } = useTranslation("shortTextTypeViewer");

  const {
    expertId,
    firstName,
    lastName,
    profileImageUrl,
    shortText,
    title,
    responseDate
  } = data;

  const lastResponse = `${t("responseDate")} ${moment(responseDate).format(
    "DD MMM YYYY"
  )}`;

  return (
    <div className={styles.container}>
      <div
        className={styles.expertPortraitWrapper}
        onClick={() => handleOpenProfile(expertId)}
      >
        <UserPortrait
          user={{
            firstName,
            lastName,
            id: expertId,
            profileImageUrl: profileImageUrl || "",
            title
          }}
          avatarSize="canopyCircle"
          onHoverTooltip
        />
      </div>
      <div className={styles.text}>{shortText}</div>
      <div className={styles.lastResponse}>{lastResponse}</div>
    </div>
  );
};
