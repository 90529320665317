import clsx from "clsx";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  Country,
  DO_NOT_CONTACT_STATUS
} from "@arbolus-technologies/models/common";
import {
  EXPERT_TYPE,
  PipelineExpertUser
} from "@arbolus-technologies/models/expert";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  ArbolusAvatar,
  AssignedAngle,
  Tag,
  WorkHistoryCollapse
} from "@arbolus-technologies/ui/components";

import { EmailPhoneComponent } from "../EmailPhoneComponent/EmailPhoneComponent";
import { PipelineRateCard } from "../PipelineRateCard/PipelineRateCard";

import styles from "./PipelineExpertCard.module.scss";

interface PipelineExpertCardProps {
  expert: PipelineExpertUser;
  metadataCountries: Country[];
  openExpertProfile: () => void;
  openExternalExpertSlidePanel: () => void;
}

export const PipelineExpertCard: React.FC<PipelineExpertCardProps> = ({
  expert,
  metadataCountries,
  openExpertProfile,
  openExternalExpertSlidePanel
}) => {
  const { t } = useTranslation("pipelineExpertCard");

  const expertCountry = metadataCountries.find(
    (metadataCountry) =>
      metadataCountry.threeLetterCode === expert.isoCountryCode
  );

  const isInternal = expert.platformStatus === EXPERT_TYPE.INTERNAL;

  const handleExpertClick = () => {
    if (expert.platformStatus === EXPERT_TYPE.INTERNAL) {
      openExpertProfile();
    } else {
      openExternalExpertSlidePanel();
    }
  };

  return (
    <div
      className={clsx(styles.container, {
        [styles.internal]: expert.isAlreadyAdded || isInternal,
        [styles.dnc]: expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC,
        [styles.caution]:
          expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.CAUTION
      })}
    >
      <div className={styles.firstRow} onClick={handleExpertClick}>
        <ArbolusAvatar
          firstName={expert.firstName}
          lastName={expert.lastName}
          profileImageUrl={expert.profileImageUrl}
          title={expert.title}
          countryTwoLetterCode={expertCountry?.twoLetterCode}
          lastPublicCompanyDate={expert.lastPublicCompanyExpDate}
          avatarType="mediumXSCircle"
          countryName={expertCountry?.name ?? ""}
          doNotContactStatus={expert.doNotContactStatus}
          isAdmin
        />
        <div className={styles.tagContainer}>
          <AssignedAngle
            angle={{
              color: expert.angle.color,
              title: expert.angle.title
            }}
          />
          <PipelineRateCard rateCard={expert.rateCard} />
        </div>
      </div>
      <div className={styles.generalInfo}>
        <EmailPhoneComponent
          arrayOfStrings={expert.projectPipelineEmails ?? []}
          iconName="email"
        />
        <EmailPhoneComponent
          arrayOfStrings={expert.projectPipelinePhones ?? []}
          iconName="phone"
        />
      </div>
      <div className={styles.workHistory}>
        <WorkHistoryCollapse workHistories={expert.workHistories} />
      </div>
      <div className={styles.bottomInfoContainer}>
        {!expert.isAlreadyAdded && isInternal && (
          <Tag
            color={ARBOLUS_COLORS.bColorAccentGreenDark}
            text={t("internal")}
          />
        )}
        <button
          className={styles.projectPipelineStatus}
          onClick={openExternalExpertSlidePanel}
        >
          {expert.isAlreadyAdded ? (
            <Tag
              color={ARBOLUS_COLORS.bColorAccentGreenDark}
              text={t("shortlisted")}
            />
          ) : (
            <Tag
              color={ARBOLUS_COLORS.bColorBasePurple}
              text={i18next.t(
                `externalExpertStatus:${expert.projectPipelineStatus}`
              )}
            />
          )}
        </button>
      </div>
    </div>
  );
};
