import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { PROJECT_AVAILABILITY_ROUTE } from "@arbolus-technologies/routes";

import styles from "./ProjectBrief.module.scss";

export interface ProjectBriefTitleProps {
  projectId: string;
  isAccepted: boolean;
}

export const ProjectBriefTitle: React.FC<ProjectBriefTitleProps> = ({
  projectId,
  isAccepted
}) => {
  const { t } = useTranslation("projectApplication");
  const history = useHistory();

  const navigateToAvailability = () => {
    history.push(PROJECT_AVAILABILITY_ROUTE(projectId));
  };

  if (!isAccepted) {
    return <h1 className={styles.title}>{t("projectBrief")}</h1>;
  }

  return (
    <div className={styles.pendingApproval}>
      <div className={styles.pendingApprovalTitle}>
        <h1>{t("pendingApproval")}</h1>
        <Button
          type="secondary"
          text={t("editAvailability")}
          endIcon="calendar_today"
          onClick={navigateToAvailability}
        />
      </div>
      <div>{t("applicationReview")}</div>
      <h3>{t("projectBrief")}</h3>
    </div>
  );
};
