import React from "react";

import styles from "./StepHeader.module.scss";

interface StepHeaderProps {
  title: string;
  stepNum: number;
}

export const StepHeader: React.FC<StepHeaderProps> = ({ title, stepNum }) => (
  <div>
    <h1 className={styles.title}>
      <span className={styles.stepNum}>{stepNum}</span>
      {title}
    </h1>
  </div>
);
