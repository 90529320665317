import {
  ANGLE_SEARCH_PARAM,
  ChatType,
  DatabaseChatType
} from "@arbolus-technologies/models/project";

export const getCurrentAngleId = (): string | null => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(ANGLE_SEARCH_PARAM);
};

export function isProjectChat(chatType?: ChatType): boolean {
  if (!chatType) return false;
  return [
    // Support chat between client and Arbolus team
    // Admin is seeing this in "Client support" tab
    ChatType.ClientToProject,
    ChatType.AdminToProject,
    // What expert sees as "Project chat" is same as AdminToExpertAndClient or ClientToExpertAndAdmin
    ChatType.ExpertToProject
  ].includes(chatType);
}

export function isAngleChat(chatType?: ChatType): boolean {
  if (!chatType) return false;
  return [ChatType.ClientToAngle, ChatType.AdminToAngle].includes(chatType);
}

export function isAdminChat(chatType: DatabaseChatType): boolean {
  return ![
    DatabaseChatType.ClientSupport,
    DatabaseChatType.Workspace,
    DatabaseChatType.Angle
  ].includes(chatType);
}
