import { GridApi } from "ag-grid-community";
import i18next from "i18next";
import moment from "moment";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { ApiError, ToasterService } from "@arbolus-technologies/api";
import { IModalService } from "@arbolus-technologies/features/common";
import { Referral } from "@arbolus-technologies/models/common";
import { isValueTruthy } from "@arbolus-technologies/utils";

export type ForkJoinRequests = {
  [key: string]: Observable<boolean | ApiError>;
};
export type ForkJoinResults = { [key: string]: boolean | ApiError };

const t = (key: string) => i18next.t(`referrals:actions:${key}`);

export abstract class BulkBaseService {
  protected _toasterService: ToasterService;

  constructor(toasterService: ToasterService) {
    this._toasterService = toasterService;
  }

  protected createObservables<T>(
    referrals: Referral[],
    singleRequests: (referral: Referral) => Observable<T>
  ): ForkJoinRequests {
    return referrals.reduce<ForkJoinRequests>((acc, referral) => {
      acc[referral.id] = singleRequests(referral).pipe(
        map(() => true),
        catchError((apiError) => of(new ApiError(apiError)))
      );
      return acc;
    }, {});
  }

  protected next(
    results: ForkJoinResults,
    referrals: Referral[],
    successFunction: (results: ForkJoinResults) => void,
    successMessage: string,
    modalService: IModalService
  ): void {
    const errorResults = Object.entries(results).filter(
      ([_, result]) => result instanceof ApiError
    ) as [string, ApiError][];
    if (errorResults.length) {
      const successResults = Object.entries(results).filter(
        ([_, result]) => !(result instanceof ApiError)
      );
      successFunction(Object.fromEntries(successResults));
      modalService.openListMainModal({
        messages: this.getErrorMessages(referrals, errorResults),
        title: t("multipleErrors"),
        confirmText: t("dismissMultipleErrors"),
        onConfirm: () => modalService.closeListMainModal(),
        onlyConfirm: true
      });
    } else {
      successFunction(results);
      this._toasterService.showSuccess(successMessage);
    }
  }

  private getErrorMessages(
    referrals: Referral[],
    errors: [string, ApiError][]
  ): { text: string }[] {
    return errors.map(([id, result]) => {
      const referral = referrals.find((r) => r.id === id);
      return {
        text: `${referral?.expertName}: ${result.errorMessages.join(", ")}`
      };
    });
  }

  protected updateNodes(
    api: GridApi,
    results: ForkJoinResults,
    updateValue: Partial<Referral>
  ): void {
    const rowNodes = Object.keys(results)
      .map((id) => api.getRowNode(id))
      .filter(isValueTruthy);
    const modified = new Date(moment.utc().format());
    const updateNodes = rowNodes.map((node) =>
      Referral.fromObject({
        ...node!.data,
        ...updateValue,
        modified
      })
    );
    api.applyTransaction({
      update: updateNodes
    });
    rowNodes.forEach((node) => node.setSelected(false));
  }

  protected removeNodes(api: GridApi, results: ForkJoinResults): void {
    api.applyTransaction({
      remove: Object.keys(results)
        .filter(isValueTruthy)
        .map((id) => ({ id }))
    });
  }
}
