import clsx from "clsx";
import React from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { BadgeData, BadgeGroup } from "../Badges/BadgeGroup/BadgeGroup";
import { Button } from "../Buttons/Button/Button";

import styles from "./FilterWithBadges.module.scss";

// TO_REPLACE

export type FilterType = {
  id: string;
  text?: string;
  iconComponent?: JSX.Element;
  key?: string;
};

export interface FilterWithBadgesProps {
  data: FilterType[];
  onFilter: (filters: FilterType[]) => void;
  resetFiltersText?: string;
  filterButtonText: string;
  onFilterButtonClick: () => void;
  showNoFiltersAppliedMessage?: boolean;
}

export const FilterWithBadges: React.FC<FilterWithBadgesProps> = ({
  data,
  onFilter,
  resetFiltersText,
  filterButtonText,
  onFilterButtonClick,
  showNoFiltersAppliedMessage = false
}) => {
  const { t } = useTranslation("filterWithBadges");

  const handleRemoveFilter = (item: FilterType) => {
    const filteredItems = data?.filter(
      (selectedItem) => selectedItem.id !== item.id
    );

    onFilter(filteredItems);
  };

  return (
    <div
      className={clsx(styles.filterPanel, {
        [styles.mobileFilterPanel]: isMobile
      })}
    >
      {data.length > 0 && (
        <BadgeGroup
          badges={groupBadges(data, handleRemoveFilter)}
          background={ARBOLUS_COLORS.bColorSecondaryGreyLight}
          fontColor={ARBOLUS_COLORS.bColorBaseDark}
          classnames={styles.badgeGroup}
        />
      )}
      <div className={styles.filterControls}>
        {data.length > 0 && (
          <Button
            type="tertiary"
            onClick={() => onFilter([])}
            text={resetFiltersText ?? t("resetFilters")}
          />
        )}
        {showNoFiltersAppliedMessage && data.length === 0 && (
          <span className={styles.noFilterMessage}>
            {t("noFiltersApplied")}
          </span>
        )}
        <Button
          type="filter"
          text={filterButtonText}
          onClick={onFilterButtonClick}
        />
      </div>
    </div>
  );
};

// Returns badges array but sorted by key
function groupBadges(
  filters: FilterType[],
  removeFilter: (filter: FilterType) => void
) {
  const groups: Record<string, BadgeData[]> = {};
  for (const filter of filters) {
    const key = filter?.key ?? "default";
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(convertFilterToBadge(filter, removeFilter));
  }
  return Object.values(groups).flat();
}

function convertFilterToBadge(
  filter: FilterType,
  removeFilter: (filter: FilterType) => void
): BadgeData {
  return {
    id: filter.id,
    text: filter.text || "",
    iconComponent: filter.iconComponent,
    onClose: () => removeFilter(filter)
  };
}
