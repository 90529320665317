import { Button } from "antd";
import { BaseButtonProps } from "antd/es/button/button";
import React from "react";
import { Link } from "react-router-dom";

interface AntDButtonLinkProps extends BaseButtonProps {
  text?: string;
  to: string;
  onClick?: () => void;
}

export const AntDButtonLink: React.FC<AntDButtonLinkProps> = ({
  to,
  text,
  onClick,
  ...props
}) => {
  return (
    <Link to={to}>
      <Button onClick={onClick} {...props}>
        {text && text}
      </Button>
    </Link>
  );
};
