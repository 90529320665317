import React from "react";
import { isMobile } from "react-device-detect";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { BackButton, Badge } from "@arbolus-technologies/ui/components";

import styles from "./Header.module.scss";

export interface HeaderProps {
  title?: string;
  actionButton?: JSX.Element;
  customPadding?: string;
  backButton?: {
    onClick: () => void;
    title: string;
  };
  hasBeta?: boolean;
  rightButton?: React.ReactNode;
}

export const Header: React.FC<HeaderProps> = ({
  title,
  actionButton,
  backButton,
  customPadding,
  hasBeta,
  rightButton
}) => {
  if (isMobile) {
    return (
      <div className={styles.mobileContainer}>
        {backButton && (
          <BackButton title={backButton.title} onClick={backButton.onClick} />
        )}
        {actionButton}
      </div>
    );
  }

  return (
    <div
      className={styles.container}
      style={{ padding: customPadding ?? undefined }}
    >
      {backButton && (
        <BackButton title={backButton.title} onClick={backButton.onClick} />
      )}
      {title && (
        <div className={styles.titleContainer}>
          <span className={styles.title}>{title}</span>
          {hasBeta && (
            <Badge
              text="BETA"
              background="white"
              fontColor={ARBOLUS_COLORS.bColorBasePurple}
              borderColor={ARBOLUS_COLORS.bColorBasePurple}
              textStyle="uppercase"
            />
          )}
        </div>
      )}
      {actionButton}
      {rightButton}
    </div>
  );
};
