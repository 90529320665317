import React from "react";
import { CarouselItem } from "reactstrap";

import {
  Endorsement,
  EndorsementWithId
} from "@arbolus-technologies/models/canopy-panels";
import { Carousel, Testimonial } from "@arbolus-technologies/ui/components";

export interface ExpertEndorsementsProps {
  endorsements: Endorsement[];
}

export const ExpertEndorsements: React.FC<ExpertEndorsementsProps> = ({
  endorsements
}) => {
  // Randomize endorsements and show only 3
  const randomEndorsements = endorsements
    .sort(() => 0.5 - Math.random())
    .slice(0, 3);

  // Add unique id to each endorsement
  const randomEndorsementsWithId: EndorsementWithId[] = randomEndorsements.map(
    (endorsement, index) => ({
      ...endorsement,
      id: index.toString()
    })
  );

  return randomEndorsementsWithId.length > 0 ? (
    <Carousel items={randomEndorsementsWithId}>
      {randomEndorsementsWithId.map((endorsement) => (
        <CarouselItem key={endorsement.id}>
          <Testimonial
            user={endorsement.endorser}
            testimonial={endorsement.quote}
          />
        </CarouselItem>
      ))}
    </Carousel>
  ) : null;
};
