import React from "react";
import { useSelector } from "react-redux";

import { ExpertsListPageTab } from "@arbolus-technologies/api";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ProjectExpertsSelector } from "@arbolus-technologies/stores/project-experts-store";
import { Loader } from "@arbolus-technologies/ui/components";

import { ReferralsTable } from "../ComponentsTable/ReferralsTable/ReferralsTable";
import {
  adminExpertColumns,
  clientExpertColumns
} from "../ComponentsTable/constants";
import { NoData } from "./NoData";

interface CandidatesListProps {
  hasCompliance: boolean;
  handleClickRow: (
    expertId: string,
    referralId: string,
    openAvailabilityTab?: boolean
  ) => void;
  expertProfilePath?: (expertId: string) => string;
  handleSlidePanelExpertProfile?: (
    projectId: string,
    expertId: string,
    referralId: string
  ) => void;
  handleEditTagline?: (
    referralId: string,
    projectId: string,
    tagline: string,
    expertTitle: string
  ) => void;
  handleSlidePanelAvailability?: (
    projectId: string,
    expertId: string,
    referralId: string
  ) => void;
}

export const CandidatesList: React.FC<CandidatesListProps> = ({
  hasCompliance,
  handleClickRow,
  expertProfilePath,
  handleSlidePanelExpertProfile,
  handleEditTagline,
  handleSlidePanelAvailability
}) => {
  const data = useSelector(ProjectExpertsSelector.allExpertsList());
  const isAdmin = useSelector(CacheSelector.isAdmin());
  const isAllExpertsListLoading = useSelector(
    ProjectExpertsSelector.isAllExpertsListLoading()
  );

  if (isAllExpertsListLoading) {
    return <Loader />;
  }

  return data && data.length > 0 ? (
    <ReferralsTable
      defaultData={data}
      columnsOptions={
        isAdmin
          ? adminExpertColumns(ExpertsListPageTab.Candidate, hasCompliance)
          : clientExpertColumns(hasCompliance)
      }
      handleClickRow={handleClickRow}
      expertProfilePath={expertProfilePath}
      handleSlidePanelExpertProfile={handleSlidePanelExpertProfile}
      handleEditTagline={handleEditTagline}
      handleSlidePanelAvailability={handleSlidePanelAvailability}
    />
  ) : (
    <NoData tabActive={ExpertsListPageTab.Candidate} />
  );
};
