import { Button } from "arbolus-ui-components";
import dompurify from "dompurify";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { Canopy } from "@arbolus-technologies/api";
import { CANOPY_STATUS } from "@arbolus-technologies/models/canopy";
import { CANOPY_PAUSED } from "@arbolus-technologies/routes";
import { CanopyExpertStoreActions } from "@arbolus-technologies/stores/canopy-expert";
import { SurveyDocs } from "@arbolus-technologies/ui/canopy";
import { Drawer } from "@arbolus-technologies/ui/components";

import styles from "./CanopyBrief.module.scss";

interface CanopyBriefProps {
  expertCanopy: Canopy;
  redirectToFirstQuestion: () => void;
}

export const CanopyBrief: React.FC<CanopyBriefProps> = ({
  expertCanopy,
  redirectToFirstQuestion
}) => {
  const { t } = useTranslation("canopyDetailsExpertRightPanel");
  const { replace } = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (expertCanopy.status !== CANOPY_STATUS.ACTIVE) {
      replace(CANOPY_PAUSED);
    }
  }, [expertCanopy, replace]);

  const handleGoToQuestions = () => {
    dispatch(CanopyExpertStoreActions.enableExpertQuestions());
    redirectToFirstQuestion();
  };

  const { overview, links = [], documents = [] } = expertCanopy;

  return (
    <div className={styles.container}>
      <div className={styles.details}>
        <Drawer title={t("brief")} openByDefault>
          <div className={styles.briefMessage}>{t("briefMessage")}</div>
          <div className={styles.overview}>{overview}</div>
          <SurveyDocs documents={documents} isCanopyDetails links={links} />
        </Drawer>
        <Drawer title={t("usefulGuidelines")}>
          <div
            dangerouslySetInnerHTML={{
              __html: dompurify.sanitize(t("usefulGuidelinesMessage"))
            }}
          />
        </Drawer>
        <Drawer title={t("troubleshooting")}>
          <div
            dangerouslySetInnerHTML={{
              __html: dompurify.sanitize(t("troubleshootingMessage"))
            }}
          />
          <div className={styles.troubleshootingHelp}>{t("issuePersists")}</div>
        </Drawer>
        <Button text={t("goToQuestions")} onClick={handleGoToQuestions} />
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: dompurify.sanitize(t("note"))
        }}
      />
    </div>
  );
};
