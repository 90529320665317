export const FEDERATED_AUTH_PROVIDER = {
  GOOGLE: "Google",
  MICROSOFT: "AzureActiveDirectory",
  APPLE: "Apple"
};

export const UNDEFINED_ERROR = "Something wrong";

export enum RegisterStages {
  SELECTION = "SELECTION",
  TERMS = "TERMS",
  CREATE = "CREATE",
  INQUIRY = "INQUIRY"
}

export enum CreateAccountStages {
  CREATE_ACCOUNT = "CREATE_ACCOUNT",
  EXPERT_RATE = "EXPERT_RATE"
}

export const RECAPTCHA_SITE_KEY =
  process.env.NX_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY;

export const RECAPTCHA_ACTIONS = {
  REINVITE: "REINVITE",
  COMPANY_ENQUIRY: "COMPANY_ENQUIRY",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  CREATE_ACCOUNT: "CREATE_ACCOUNT",
  REGISTER: "REGISTER",
  LIST_CURRENCIES: "LIST_CURRENCIES"
};

export enum REFERRER_TYPE {
  EXPERT = "Expert"
}
