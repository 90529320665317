import { LocationDescriptor } from "history";
import React from "react";
import { Link } from "react-router-dom";

import { Button, ButtonProps } from "./Button";

// TO_REPLACE
interface LinkButtonProps extends ButtonProps {
  to: string | LocationDescriptor;
}
export const LinkButton: React.FC<LinkButtonProps> = ({ to, ...props }) => (
  <Link to={to}>
    <Button {...props} />
  </Link>
);
