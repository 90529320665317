import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { ProjectNote } from "@arbolus-technologies/api";
import { KEYWORDS_SEARCH_OPTIONS } from "@arbolus-technologies/models/project";
import { EXPERT_PROFILE } from "@arbolus-technologies/routes";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";

import { ProjectNoteCard } from "./ProjectNoteCard";

import styles from "./ProjectNotes.module.scss";

interface ProjectNotesProps {
  expertId: string;
  keywordsList: string[];
  projectNotes: ProjectNote[];
}
export const ProjectNotes: React.FC<ProjectNotesProps> = ({
  expertId,
  keywordsList,
  projectNotes
}) => {
  const { t } = useTranslation("discoverMatchingFiltersPanel");

  const hasKeywords = keywordsList.length > 0;

  const isProjectNotesOptionSelected = useSelector(
    ProjectNxSelector.isKeywordSearchOptionSelected(
      KEYWORDS_SEARCH_OPTIONS.PROJECTS_NOTES
    )
  );

  return (
    <div className={styles.projectNotes}>
      <div className={styles.projectNotesHeader}>
        <h2 className={styles.projectNotesTitle}>{t("projectNotes")}</h2>
        <Link
          to={{
            pathname: EXPERT_PROFILE(expertId).pathname,
            search: "?defaultTab=projectNotes"
          }}
          target="_blank"
        >
          <Button
            text={t("allProjectNotes")}
            endIcon="chevron_right"
            type="tertiary"
          />
        </Link>
      </div>
      {projectNotes.length > 0 &&
      isProjectNotesOptionSelected &&
      hasKeywords ? (
        <>
          {projectNotes.map((projectNote: ProjectNote) => (
            <ProjectNoteCard
              key={projectNote.project.id}
              projectNote={projectNote}
              keywordsList={keywordsList}
            />
          ))}
        </>
      ) : (
        <div className={styles.noResultContainer}>{t("noProjectNotes")}</div>
      )}
    </div>
  );
};
