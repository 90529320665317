import { Icon } from "arbolus-ui-components";
import React from "react";
import { useSelector } from "react-redux";

import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ARBOLUS_COLORS, arbolusLogo } from "@arbolus-technologies/theme";
import { HR } from "@arbolus-technologies/ui/components";

import { ReportIncidentButton } from "../../../ReportIncident/ReportIncidentButton/ReportIncidentButton";
import { MobileTabletMenuButton } from "../../Components/MobileTabletMenuButton/MobileTabletMenuButton";
import { ISideBarItems, handleActiveRoute } from "../../MenuItemsConfig";

import styles from "./TabletMobileMenuBody.module.scss";

interface TabletMobileMenuBodyProps {
  handleMenuItemOnClick: (menuItem: ISideBarItems) => void;
  menuItems: ISideBarItems[] | null;
  handleLogout: () => void;
}
export const TabletMobileMenuBody: React.FC<TabletMobileMenuBodyProps> = ({
  handleMenuItemOnClick,
  menuItems,
  handleLogout
}) => {
  const hasNotifications = useSelector(CacheSelector.hasNotifications());

  return (
    <div className={styles.body}>
      <div className={styles.topSection}>
        <HR margin={{ top: 0, bottom: 0 }} />
        <div className={styles.topButtonsContainer}>
          {menuItems &&
            menuItems.map((menuItem) => {
              if (menuItem.position === "main") {
                return (
                  <MobileTabletMenuButton
                    key={menuItem.name}
                    text={menuItem.name}
                    icon={menuItem.icon}
                    handleButtonClick={() => handleMenuItemOnClick(menuItem)}
                    isActive={
                      menuItem.route ? handleActiveRoute(menuItem.route) : false
                    }
                    to={menuItem.route}
                    hasNotifications={
                      menuItem.name === "Inbox" && hasNotifications
                    }
                  />
                );
              }
              return null;
            })}
        </div>
      </div>
      <div className={styles.bottomSection}>
        <div className={styles.bottomButtonsContainer}>
          {menuItems &&
            menuItems.map((menuItem) => {
              const isActive = menuItem.route
                ? handleActiveRoute(menuItem.route)
                : false;
              if (menuItem.position === "footer") {
                if (menuItem.name.toLowerCase() === "support") {
                  return <ReportIncidentButton key={menuItem.name} />;
                }
                if (menuItem.name.toLowerCase() === "logout") {
                  return (
                    <MobileTabletMenuButton
                      key={menuItem.name}
                      text={menuItem.name}
                      icon={menuItem.icon}
                      handleButtonClick={handleLogout}
                    />
                  );
                }
                return (
                  <div
                    key={menuItem.name}
                    className={styles.buttonContainer}
                    onClick={() => handleMenuItemOnClick(menuItem)}
                  >
                    <MobileTabletMenuButton
                      text={menuItem.name}
                      icon={menuItem.icon}
                      isActive={isActive}
                      to={menuItem.route}
                    />
                    {menuItem.subItems && (
                      <Icon
                        name="chevron_right"
                        fontSize="24px"
                        color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
                      />
                    )}
                  </div>
                );
              }
              return null;
            })}
        </div>
        <div className={styles.footer}>
          <HR margin={{ top: 0, bottom: 0 }} />
          <div className={styles.imageContainer}>
            <img
              src={arbolusLogo}
              alt="Arbolus Logo"
              className={styles.arbolusLogo}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
