import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  PanelSelector,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ArbolusListModal } from "@arbolus-technologies/ui/components";

export const MainListModal: React.FC = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const isOpen = useSelector(PanelSelector.isListMainModalOpen());
  const data = useSelector(PanelSelector.listMainModalData());

  const close = React.useCallback(
    () => dispatch(PanelStoreActions.closeListMainModal()),
    [dispatch]
  );

  const onConfirm = React.useCallback(() => {
    data.onConfirm();
    close();
  }, [close, data]);

  const rightButton = React.useMemo(
    () => ({
      onClick: onConfirm,
      type: data.type || "primary",
      text: data.confirmText || t("buttons.confirm")
    }),
    [data, onConfirm, t]
  );
  const leftButton = React.useMemo(
    () => ({
      onClick: close,
      type: "tertiary" as const,
      text: data.cancelText || t("buttons.cancel")
    }),
    [data, close, t]
  );

  return (
    <ArbolusListModal
      title={data.title}
      messages={data.messages}
      rightButton={rightButton}
      leftButton={data.onlyConfirm ? undefined : leftButton}
      isOpen={isOpen}
      toggle={close}
      isReduxService
    />
  );
};
