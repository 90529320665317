import clsx from "clsx";
import React from "react";
import { Popover, PopoverBody } from "reactstrap";

import styles from "./CIQPopover.module.scss";

export interface CIQPopoverProps {
  isOpen: boolean;
  id: string;
  title?: JSX.Element;
  onPopoverClose: () => void;
  customContainerClasses?: string;
  customBodyClasses?: string;
  children: React.ReactNode;
}

export const CIQPopover: React.FC<CIQPopoverProps> = ({
  isOpen,
  id,
  title,
  onPopoverClose,
  children,
  customContainerClasses,
  customBodyClasses
}) => (
  <Popover
    isOpen={isOpen}
    target={id}
    placement="bottom-end"
    toggle={onPopoverClose}
    trigger="legacy"
    className={clsx(styles.container, customContainerClasses)}
  >
    {title && title}
    <PopoverBody className={clsx(styles.body, customBodyClasses)}>
      {children}
    </PopoverBody>
  </Popover>
);
