import React from "react";
import { useTranslation } from "react-i18next";

import { ResponseAllExpertsTable } from "@arbolus-technologies/api";
import { AssignedAngle } from "@arbolus-technologies/ui/components";

interface AssignedAngleProps {
  row: ResponseAllExpertsTable;
}

export const AssignedAngleComponent: React.FC<AssignedAngleProps> = ({
  row
}) => {
  const { t } = useTranslation("expertsList");

  return row.angle ? (
    <AssignedAngle angle={row.angle} />
  ) : (
    <span>{t("noData")}</span>
  );
};
