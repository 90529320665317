import { Handlers, createReducer } from "reduxsauce";

import {
  CLOSE_INPUT_MAIN_MODAL,
  CLOSE_LIST_MAIN_MODAL,
  CLOSE_MAIN_MODAL,
  OPEN_INPUT_MAIN_MODAL,
  OPEN_LIST_MAIN_MODAL,
  OPEN_MAIN_MODAL,
  OpenInputMainModalAction,
  OpenListMainModalAction,
  OpenMainModalAction
} from "./actions/ModalActionTypes";
import {
  CLOSE_PANEL,
  ClosePanelAction,
  OPEN_PANEL,
  OpenPanelAction
} from "./actions/PanelActionTypes";
import { PanelReducerState, panels } from "./models/definitions";

const closedModal = Object.freeze({
  isOpen: false,
  title: "",
  subtitle: "",
  confirmText: "",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onConfirm: () => {}
});

export const initialState: PanelReducerState = {
  panels: panels,
  mainModal: { ...closedModal },
  listMainModal: { ...closedModal, messages: [] },
  inputMainModal: { ...closedModal }
};

// Open Panel
const openPanel = (
  state = initialState,
  { payload }: OpenPanelAction
): PanelReducerState => {
  panels.forEach((panel) => {
    if (panel.id === payload.panelId) {
      panel.isOpen = true;
      panel.data = payload.data;
    }
  });

  return {
    ...state
  };
};
// Close Panel
const closePanel = (
  state = initialState,
  { payload }: ClosePanelAction
): PanelReducerState => {
  panels.forEach((panel) => {
    if (panel.id === payload.panelId) {
      panel.isOpen = false;
      delete panel.data;
    }
  });
  return {
    ...state
  };
};

const openMainModal = (
  state = initialState,
  { payload }: OpenMainModalAction
): PanelReducerState => ({
  ...state,
  mainModal: {
    ...payload,
    isOpen: true
  }
});

const closeMainModal = (state = initialState): PanelReducerState => {
  const mainModal = {
    ...state.mainModal,
    isOpen: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onConfirm: () => {}
  };

  return {
    ...state,
    mainModal
  };
};

const openListMainModal = (
  state = initialState,
  { payload }: OpenListMainModalAction
): PanelReducerState => ({
  ...state,
  listMainModal: {
    ...payload,
    isOpen: true
  }
});

const closeListMainModal = (state = initialState): PanelReducerState => {
  const listMainModal = {
    ...state.listMainModal,
    isOpen: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onConfirm: () => {}
  };

  return {
    ...state,
    listMainModal
  };
};

const openInputMainModal = (
  state = initialState,
  { payload }: OpenInputMainModalAction
): PanelReducerState => ({
  ...state,
  inputMainModal: {
    ...payload,
    isOpen: true
  }
});

const closeInputMainModal = (state = initialState): PanelReducerState => {
  const inputMainModal = {
    ...state.inputMainModal,
    isOpen: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onConfirm: () => {}
  };

  return {
    ...state,
    inputMainModal
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handlers: Handlers<PanelReducerState, any> = {
  [OPEN_PANEL]: openPanel,
  [CLOSE_PANEL]: closePanel,
  [OPEN_MAIN_MODAL]: openMainModal,
  [CLOSE_MAIN_MODAL]: closeMainModal,
  [OPEN_LIST_MAIN_MODAL]: openListMainModal,
  [CLOSE_LIST_MAIN_MODAL]: closeListMainModal,
  [OPEN_INPUT_MAIN_MODAL]: openInputMainModal,
  [CLOSE_INPUT_MAIN_MODAL]: closeInputMainModal
};

export const PanelReducer = createReducer(initialState, handlers);
