import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import {
  BOOKMARK_COLLECTION_ORDER_BY,
  BookmarkCollectionURLParams,
  SORT_DIRECTION
} from "@arbolus-technologies/api";
import {
  BookmarksSelector,
  BookmarksStoreActions
} from "@arbolus-technologies/stores/bookmarks";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import {
  InfiniteScrollWithHeader,
  UI_BOOKMARKS,
  UI_COMMON
} from "@arbolus-technologies/ui/components";

import { ExpertProfileSidePanel } from "../../Panels/ExpertProfileSidePanel/ExpertProfileSidePanel";
import { CollectionHeader } from "../CollectionHeader/CollectionHeader";
import { BookmarkAnswer } from "./BookmarkAnswer/BookmarkAnswer";

import styles from "./BookmarksList.module.scss";

export const BookmarksList: React.FC = () => {
  const dispatch = useDispatch();
  const { bookmarkCollectionId } = useParams<BookmarkCollectionURLParams>();

  const list = useSelector(BookmarksSelector.bookmarksList());
  const isLoading = useSelector(BookmarksSelector.bookmarksListIsLoading());
  const hasNextPage = useSelector(BookmarksSelector.bookmarksListHasNextPage());
  const [expertId, setExpertId] = useState<string>();

  const nextItems = useCallback(
    (limit: number): void => {
      if (!hasNextPage) {
        return;
      }

      dispatch(
        BookmarksStoreActions.getBookmarksList("", {
          limit,
          offset: list?.length ?? 0,
          orderBy: BOOKMARK_COLLECTION_ORDER_BY.CREATED,
          orderDirection: SORT_DIRECTION.DESCENDING,
          bookmarkCollectionId
        })
      );
    },
    [dispatch, hasNextPage, list, bookmarkCollectionId]
  );

  useEffect(
    () =>
      // componentWillUnmount
      () => {
        dispatch(BookmarksStoreActions.resetBookmarksList());
      },
    [dispatch]
  );

  const handleOpenProfile = (expertId: string): void => {
    setExpertId(expertId);
    dispatch(PanelStoreActions.openPanel(PanelId.ExpertProfile));
  };

  return (
    <InfiniteScrollWithHeader
      header={<CollectionHeader />}
      items={list}
      itemHeight={
        UI_BOOKMARKS.COLLECTION_CARD_HEIGHT() /
        UI_BOOKMARKS.COLLECTION_CARD_PER_ROW(window.innerWidth)
      }
      isLoading={isLoading}
      nextItems={nextItems}
      renderer={(item) => (
        <BookmarkAnswer
          key={item.id}
          questionId={item.questionId}
          canopyId={item.canopyId}
          handleOpenProfile={handleOpenProfile}
          isSmartTranscript={item.videoAnswer.isSmartTranscript}
          transcript={item.videoAnswer.transcript}
          answerId={item.canopyAnswerId}
          responseDate={item.created}
          badgeText={item.canopyTitle}
          title={item.questionText}
          subtitle={item.questionDetails}
          expert={item.expert}
          videoUrl={item.videoAnswer.downloadUrl}
        />
      )}
      classnames={styles.scrollContainer}
      rendererContainerClassnames={styles.bookmarksContainer}
      style={{
        maxHeight: UI_COMMON.HAS_SIDE_MENU()
          ? "calc(100vh - 80px)"
          : "calc(100vh - 48px)",
        height: UI_COMMON.HAS_SIDE_MENU()
          ? "calc(100vh - 80px)"
          : "calc(100vh - 48px)",
        overflowX: "hidden"
      }}
    >
      <ExpertProfileSidePanel expertId={expertId} />
    </InfiniteScrollWithHeader>
  );
};
