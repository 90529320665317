/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { SharedRoutes } from "@arbolus-technologies/api";

import { APP_USER_ROLES, LISTING_TYPES } from "../../../../../constants/app";
import { PROJECT_CALENDAR } from "../../../../../constants/navigation/projectRoutes";
import { PROJECT_TAB_ROUTES } from "../../../../../constants/navigation/projectTabRoutes";
import { Event } from "../../../../../models/event";
import { EventService } from "../../../../../services";
import { CIQEmptyPlaceholder } from "../../../../app/components";
import Card from "../../card/Card";

interface GenericPanelItem {
  id: string;
  label: string;
  content: string;
  onClick?: () => void;
}

interface CalendarCardProps {
  loading: boolean;
  projectId: string;
  events: Event[];
  navigateToAnotherTab: (tabRouteName: string) => void;
}

type CalendarCardIntersectProps = CalendarCardProps & WithTranslation;

const CalendarCard = (props: CalendarCardIntersectProps): JSX.Element => {
  const { loading, events, t, navigateToAnotherTab, projectId } = props;
  const history = useHistory();

  const getViewableSchedules = (): GenericPanelItem[] =>
    events.map((event) => ({
      id: event.id,
      label: EventService.generateRelativeEventTime(
        event.startTime,
        event.endTime
      ),
      content: event.title
    }));

  const renderCalendarPanelItem = (
    item: GenericPanelItem,
    key: number
  ): JSX.Element => (
    <div className="bl-list-item list-text" key={key}>
      <div className="bl-list-icon">
        <span className="ciq-icon ciq-calender" />
      </div>
      <div className="bl-list-text-container">
        <div className="bl-list-text">{item.label}</div>

        <div
          className="bl-list-link"
          onClick={(
            event: React.MouseEvent<HTMLDivElement, MouseEvent>
          ): void => {
            event.stopPropagation();
            history.replace(SharedRoutes.EVENT_PANEL_ROUTE(item.id));
          }}
        >
          {item.content}
        </div>
      </div>
    </div>
  );

  const renderContent = (): JSX.Element | JSX.Element[] => {
    const data = getViewableSchedules();
    if (data.length > 0) {
      return data.map((item: GenericPanelItem, index: number) =>
        renderCalendarPanelItem(item, index)
      );
    }

    const description = new Map([
      [APP_USER_ROLES.client, t("noEventsDescription")],
      [APP_USER_ROLES.expert, t("expertNoEventsDescription")],
      [APP_USER_ROLES.adminClient, t("noEventsDescription")]
    ]);

    return (
      <CIQEmptyPlaceholder
        title={t("noEvents")}
        roleDescription={description}
        itemType={LISTING_TYPES.EVENTS}
        isCard
      />
    );
  };

  const handleCalendarCardPressed = (): void => {
    const nextTabRoute = PROJECT_TAB_ROUTES[PROJECT_CALENDAR];
    navigateToAnotherTab(nextTabRoute.route(projectId));
  };

  return (
    <Card
      loading={loading}
      title={t("calendar")}
      onTitleClick={handleCalendarCardPressed}
    >
      {renderContent()}
    </Card>
  );
};

CalendarCard.defaultProps = {
  events: [],
  handleEventDelete: (): void => {}
};

export default withTranslation("projectBasePage")(CalendarCard);
