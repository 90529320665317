import { Observable } from "rxjs";

import {
  CANOPY_AUDIO_REQUEST_CHANNEL_NAME,
  EXPERT_FOLLOW_UP_CHANNEL_NAME,
  NEW_FEATURE_REQUEST_CHANNEL_NAME
} from "@arbolus-technologies/models/expert";

import { WEBHOOKS_API } from "../constants/api";
import { SuccessResponse } from "../models/api";
import { restService } from "../restService";

export const WebHooksService = {
  sendSlackMessage: (
    // From now on, we will use the `sendSlackMessage` method to create/send messages to Slack.
    message: string,
    channelUrl: string
  ): Observable<SuccessResponse> =>
    restService.post(WEBHOOKS_API.SEND_SLACK_MESSAGE(), {
      message,
      channelUrl
    }),
  sendSlackMessageWithAttachments: (
    data: FormData
  ): Observable<SuccessResponse> =>
    restService.post(
      WEBHOOKS_API.SEND_SLACK_MESSAGE_WITH_ATTACHMENT(),
      data,
      undefined,
      {
        headers: { "Content-Type": "multipart/form-data" }
      }
    ),
  /**
   * @deprecated use sendSlackMessage
   */
  sendMessage: (
    message: string,
    channelName: string
  ): Observable<SuccessResponse> =>
    restService.post(WEBHOOKS_API.SEND_MESSAGE(), {
      channelName,
      message
    }),

  expertFollowUpSendMessage: (message: string): Observable<SuccessResponse> =>
    restService.post(WEBHOOKS_API.SEND_MESSAGE(), {
      channelName: EXPERT_FOLLOW_UP_CHANNEL_NAME,
      message
    }),

  canopyAudioRequestSendMessage: (
    message: string
  ): Observable<SuccessResponse> =>
    restService.post(WEBHOOKS_API.SEND_MESSAGE(), {
      channelName: CANOPY_AUDIO_REQUEST_CHANNEL_NAME,
      message
    }),

  newFeatureRequestSendMessage: (
    message: string
  ): Observable<SuccessResponse> =>
    restService.post(WEBHOOKS_API.SEND_MESSAGE(), {
      channelName: NEW_FEATURE_REQUEST_CHANNEL_NAME,
      message
    })
};
