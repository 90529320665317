import { Observable } from "rxjs";

import {
  ReferralSummary,
  SORT_DIRECTION,
  restService
} from "@arbolus-technologies/api";
import { ExpertRate } from "@arbolus-technologies/models/common";
import { REFERRAL_STATES } from "@arbolus-technologies/models/expert";
import { ComplianceQuestion } from "@arbolus-technologies/models/project";

import {
  MAX_PAGE_SIZE,
  PROJECTDRAFT_API,
  PROJECTS_API,
  PROJECT_CLIENTS_API,
  PROJECT_EXPERTS_API
} from "../constants/api";
import {
  ApiCreatedResponse,
  ApiDeletedResponse,
  ApiNonPaginatedResponse,
  ApiPaginatedResponse,
  ApiSuccessResponse
} from "../models/api";
import { ProjectQuestionsResponse } from "../models/expert";
import {
  BaseProject,
  ComplianceStatusRequest,
  EditProjectRequest,
  EditProjectResponse,
  ExpertProjectListResponse,
  InviteToProjectResponse,
  ListProject,
  Member,
  NoteOrDoc,
  Project,
  ProjectApplication,
  ProjectCreateRequest,
  ProjectDraftDeleteResponse,
  ProjectDraftResponse,
  ProjectDraftSaveRequest,
  ProjectDraftUpdateRequest,
  ProjectDraftUpdateResponse,
  ProjectMemberAddResponse,
  ProjectMemberDeleteResponse,
  ProjectSettings,
  ProjectSettingsUpdateRequest,
  ProjectsPaginatedRequest,
  ReferExpertRequest,
  Referral,
  ReferralDetail,
  UpdateReferralRequest,
  UpdateReferralResponse
} from "../models/project";
import { BaseClientMember } from "../models/user";

export const projectService = {
  saveProjectDraft: (
    projectDraft: ProjectDraftSaveRequest
  ): Observable<ApiCreatedResponse> =>
    restService.post<ApiCreatedResponse>(
      PROJECTDRAFT_API.CREATE_PROJECTDRAFT(),
      projectDraft
    ),

  updateProjectDraft: (
    projectDraft: ProjectDraftUpdateRequest
  ): Observable<ProjectDraftUpdateResponse> =>
    restService.put<ProjectDraftUpdateResponse>(
      PROJECTDRAFT_API.UPDATE_PROJECTDRAFT(projectDraft.id),
      projectDraft
    ),

  getDraftProject: (projectDraftId: string): Observable<ProjectDraftResponse> =>
    restService.get<ProjectDraftResponse>(
      PROJECTDRAFT_API.GET_PROJECTDRAFT(projectDraftId)
    ),

  deleteDraftProject: (
    projectDraftId: string
  ): Observable<ProjectDraftDeleteResponse> =>
    restService.delete<ProjectDraftDeleteResponse>(
      PROJECTDRAFT_API.DELETE_PROJECTDRAFT(projectDraftId)
    ),

  createProject: (
    project: ProjectCreateRequest
  ): Observable<ApiCreatedResponse> =>
    restService.post<ApiCreatedResponse>(
      PROJECTS_API.CREATE_PROJECT(),
      project
    ),

  editProject: (project: EditProjectRequest): Observable<EditProjectResponse> =>
    restService.put<EditProjectResponse>(
      PROJECTS_API.EDIT_PROJECT(project.projectId),
      project
    ),

  getProject: (projectId: string): Observable<Project> =>
    restService.get<Project>(PROJECTS_API.GET_PROJECT(projectId)),

  getClientProjectsList: (): Observable<ApiNonPaginatedResponse<ListProject>> =>
    restService.get<ApiNonPaginatedResponse<ListProject>>(
      PROJECT_CLIENTS_API.GET_PROJECTS_LIST()
    ),

  getExpertProjectsList: (): Observable<ExpertProjectListResponse> =>
    restService.get<ExpertProjectListResponse>(
      PROJECT_EXPERTS_API.GET_PROJECTS_LIST()
    ),

  getExpertProjectNotesAndDocuments: (
    projectId: string,
    limit: number = MAX_PAGE_SIZE,
    OrderBy = "modified",
    OrderDirection: string = SORT_DIRECTION.DESCENDING
  ): Observable<ApiPaginatedResponse<NoteOrDoc>> =>
    restService.get<ApiPaginatedResponse<NoteOrDoc>>(
      PROJECT_EXPERTS_API.GET_NOTES_AND_DOCS(projectId),
      { limit, OrderBy, OrderDirection }
    ),

  getClientProjectNotesAndDocuments: (
    projectId: string,
    limit: number = MAX_PAGE_SIZE,
    OrderBy = "modified",
    OrderDirection: string = SORT_DIRECTION.DESCENDING
  ): Observable<ApiPaginatedResponse<NoteOrDoc>> =>
    restService.get<ApiPaginatedResponse<NoteOrDoc>>(
      PROJECT_CLIENTS_API.GET_NOTES_AND_DOCS(projectId),
      { limit, OrderBy, OrderDirection }
    ),

  getProjectReferrals: (
    projectId: string,
    includeExpertDetails: boolean,
    includeSavedExpertStatus: boolean,
    status: string = REFERRAL_STATES.CANDIDATE,
    limit: number = MAX_PAGE_SIZE,
    offset = 0,
    orderBy = "modified",
    orderDirection: string = SORT_DIRECTION.DESCENDING
  ): Observable<ApiPaginatedResponse<Referral>> =>
    restService.get<ApiPaginatedResponse<Referral>>(
      PROJECTS_API.GET_REFERRALS(projectId),
      {
        includeExpertDetails,
        includeSavedExpertStatus,
        status,
        limit,
        offset,
        orderBy,
        orderDirection
      }
    ),

  getProjectMembers: (
    projectId: string,
    includeDeleted = true
  ): Observable<ApiNonPaginatedResponse<Member>> =>
    restService.get(PROJECTS_API.GET_MEMBERS(projectId), {
      includeDeleted
    }),

  addProjectMember: (
    projectId: string,
    userId: string
  ): Observable<ProjectMemberAddResponse> =>
    restService.post<ProjectMemberAddResponse>(
      PROJECTS_API.ADD_MEMBER(projectId),
      { userId }
    ),

  removeProjectMember: (
    projectId: string,
    memberId: string
  ): Observable<ProjectMemberDeleteResponse> =>
    restService.delete<ProjectMemberDeleteResponse>(
      PROJECTS_API.REMOVE_MEMBER(projectId, memberId),
      {}
    ),

  inviteToProject: (
    projectId: string,
    email: string
  ): Observable<InviteToProjectResponse> =>
    restService.post<InviteToProjectResponse>(
      PROJECTS_API.INVITE_TO_PROJECT(projectId),
      { email }
    ),

  getClientProjects: (
    apiParams: ProjectsPaginatedRequest
  ): Observable<ApiPaginatedResponse<BaseProject>> =>
    restService.get<ApiPaginatedResponse<BaseProject>>(
      PROJECT_CLIENTS_API.GET_PROJECTS(),
      apiParams
    ),

  getExpertProjects: (
    apiParams: ProjectsPaginatedRequest
  ): Observable<ApiPaginatedResponse<BaseProject>> =>
    restService.get<ApiPaginatedResponse<BaseProject>>(
      PROJECT_EXPERTS_API.GET_PROJECTS(),
      apiParams
    ),

  updateReferralsStatus: (
    projectId: string,
    referralId: string,
    status: UpdateReferralRequest
  ): Observable<UpdateReferralResponse> =>
    restService.patch<UpdateReferralResponse>(
      PROJECTS_API.UPDATE_REFERRALS(projectId),
      status,
      { referralId }
    ),

  getProjectCandidates: (
    projectId: string,
    searchTerm = "",
    offset = 0,
    limit: number = MAX_PAGE_SIZE
  ): Observable<ApiPaginatedResponse<BaseClientMember>> =>
    restService.get(PROJECTS_API.GET_CANDIDATES(projectId), {
      searchTerm,
      limit,
      offset
    }),

  updateProjectState: (
    projectId: string,
    projectState: string
  ): Observable<ApiSuccessResponse> =>
    restService.post<ApiSuccessResponse>(
      PROJECTS_API.UPDATE_PROJECT_STATE(projectId),
      {
        projectState
      }
    ),

  getExpertApplicationBrief: (
    projectId: string
  ): Observable<ProjectApplication> =>
    restService.get(
      PROJECT_EXPERTS_API.GET_PROJECT_APPLICATION_BRIEF(projectId)
    ),

  getProjectQuestions: (
    projectId: string
  ): Observable<ProjectQuestionsResponse> =>
    restService.get(PROJECTS_API.GET_QUESTIONS(projectId)),

  getProjectComplianceQuestions: (
    projectId: string
  ): Observable<ApiNonPaginatedResponse<ComplianceQuestion>> =>
    restService.get(PROJECTS_API.GET_COMPLIANCE_QUESTIONS(projectId)),

  acceptAgreement: (
    projectId: string,
    referralId: string,
    agreementId: string,
    application: string
  ): Observable<ApiSuccessResponse> =>
    restService.patch(
      PROJECT_EXPERTS_API.ACCEPT_PROJECT_AGREEMENT(projectId),
      { agreementId, application },
      { referralId }
    ),

  getReferralSummary: (projectId: string): Observable<ReferralSummary> =>
    restService.get<ReferralSummary>(PROJECTS_API.REFERRAL_SUMMARY(projectId)),

  getReferral: (
    projectId: string,
    referralId: string
  ): Observable<ReferralDetail> =>
    restService.get<ReferralDetail>(
      PROJECTS_API.GET_REFERRAL(projectId, referralId)
    ),

  removeFromProject: (
    projectId: string,
    referralId: string
  ): Observable<ApiDeletedResponse> =>
    restService.delete<ApiDeletedResponse>(
      PROJECTS_API.DELETE_FROM_PROJECT(projectId, referralId)
    ),

  referExpert: (
    projectId: string,
    newExpert: ReferExpertRequest
  ): Observable<ProjectApplication> =>
    restService.post<ProjectApplication>(
      PROJECT_EXPERTS_API.REFER_EXPERT(projectId),
      newExpert
    ),

  updateReferralCompliance: (
    projectId: string,
    referralId: string,
    complianceStatus: ComplianceStatusRequest
  ): Observable<UpdateReferralResponse> =>
    restService.post<UpdateReferralResponse>(
      PROJECTS_API.UPDATE_COMPLIANCE_STATUS(projectId),
      complianceStatus,
      { referralId }
    ),

  getProjectSettings: (projectId: string): Observable<ProjectSettings> =>
    restService.get<ProjectSettings>(
      PROJECTS_API.GET_PROJECT_SETTINGS(projectId)
    ),

  updateProjectSettings: (
    projectId: string,
    projectSettings: ProjectSettingsUpdateRequest
  ): Observable<ApiSuccessResponse> =>
    restService.post<ApiSuccessResponse>(
      PROJECTS_API.GET_PROJECT_SETTINGS(projectId),
      projectSettings
    ),

  getRate: (projectId: string, expertId: string): Observable<ExpertRate> =>
    restService.get<ExpertRate>(PROJECT_EXPERTS_API.GET_RATE(), {
      projectId,
      expertId
    })
};
