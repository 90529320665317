import React from "react";
import { useTranslation } from "react-i18next";

import hand from "../../../assets/images/hand.png";

import styles from "./EmptyCanopyBox.module.scss";
interface EmptyCanopyBoxProps {
  children: React.ReactNode;
}
export const EmptyCanopyBox: React.FC<EmptyCanopyBoxProps> = ({ children }) => {
  const { t } = useTranslation("emptyCanopyBox");

  return (
    <div className={styles.container}>
      <div className={styles.mainComponent}>
        <div className={styles.imageTitleContainer}>
          <img className={styles.image} src={hand} alt="title" />
          <span className={styles.leftTitle}>{t("emptyStateTitle")}</span>
        </div>
        <div className={styles.subtitle}>{t("emptyStateSubtitle")}</div>
        <div className={styles.action}>{children}</div>
      </div>
      <div className={styles.rightColumn}>
        <span className={styles.rightTitle}>{t("needSomeHelp")}</span>
        <div className={styles.linksContainer}>
          <a
            className={styles.link}
            target="_blank"
            rel="noreferrer"
            href="https://help.arbolus.com/articles/72170-arboluscanopy-faqs"
          >
            {t("link1")}
          </a>
          <a
            className={styles.link}
            target="_blank"
            rel="noreferrer"
            href="https://help.arbolus.com/articles/72170-arboluscanopy-faqs"
          >
            {t("link2")}
          </a>
          <a
            className={styles.link}
            target="_blank"
            rel="noreferrer"
            href="https://help.arbolus.com/articles/72170-arboluscanopy-faqs"
          >
            {t("link3")}
          </a>
          <a
            className={styles.link}
            target="_blank"
            rel="noreferrer"
            href="https://help.arbolus.com/articles/72170-arboluscanopy-faqs"
          >
            {t("link4")}
          </a>
        </div>
      </div>
    </div>
  );
};
