import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import styles from "./CreateNewExpert.module.scss";

interface CreateNewExpertProps {
  createExpertRoute: string;
}

export const CreateNewExpert: React.FC<CreateNewExpertProps> = ({
  createExpertRoute
}) => {
  const { t } = useTranslation("inviteExperts");

  return (
    <div className={styles.createNewExpertContainer}>
      <h2 className={styles.createNewExpertTitle}>{t("createNewExpert")}</h2>
      <p className={styles.createNewExpertParagraph}>
        {t("createNewExpertText")}
      </p>
      <Link to={createExpertRoute} className={styles.buttonContainer}>
        <Button
          text={t("createExpert")}
          endIcon="chevron_right"
          type="primary"
        />
      </Link>
    </div>
  );
};
