import { Icon } from "arbolus-ui-components";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { NumberCircle } from "@arbolus-technologies/ui/components";
import { CANOPY_DATE_TIME_FORMAT } from "@arbolus-technologies/utils";

import { AnswerQuestion } from "../types";

import styles from "./ShortTextTypeViewerByExpert.module.scss";

interface ShortTextTypeViewerByExpertProps {
  question: AnswerQuestion;
  shortText: string;
  created: string;
  redirectToQuestion: (questionId: string) => void;
}

export const ShortTextTypeViewerByExpert: React.FC<
  ShortTextTypeViewerByExpertProps
> = ({ question, shortText, created, redirectToQuestion }) => {
  const { t } = useTranslation("shortTextTypeViewerByExpert");

  const { id, text, details, sortOrder } = question;

  const lastResponse = `${t("responseDate")}: ${moment(created).format(
    CANOPY_DATE_TIME_FORMAT
  )}`;

  return (
    <div className={styles.container}>
      <div className={styles.number}>
        <NumberCircle
          number={sortOrder ? sortOrder + 1 : 1}
          classnames={styles.number}
        />
        <Icon
          name="short_text"
          color={ARBOLUS_COLORS.bColorGrayIcon}
          fontSize="24px"
          tooltip={t("shortText")}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.header}>
          <h1 className={styles.title} onClick={() => redirectToQuestion(id)}>
            {text}
          </h1>
          <h2 className={styles.subtitle}>{details}</h2>
        </div>
        <div className={styles.shortText}>{shortText}</div>
        <p className={styles.responseDate}>{lastResponse}</p>
      </div>
    </div>
  );
};
