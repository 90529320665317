import React from "react";

import { RejectedTable } from "../../Modules/ReferralsTable/RejectedTable";
import { ReferralsPage } from "./ReferralsPage";

export const RejectedPage: React.FC = () => (
  <ReferralsPage page="rejected">
    <RejectedTable />
  </ReferralsPage>
);
