import { Button } from "arbolus-ui-components";
import dompurify from "dompurify";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./ActionsAvailability.module.scss";

const sanitizer = dompurify.sanitize;

interface ActionsAvailabilityProps {
  handleArrangeCallClicked: () => void;
  handleRequestTimeSlots: () => void;
  isArrangeCallButtonDisabled: boolean;
  isRequestAdditionalTimeSlotsDisabled: boolean;
  availabilityRequiredDate?: Date;
  availabilityRequesterName?: string;
  isRequiredMoreAvailabilitySlotsAllowed: boolean;
  waitingHours: number;
  isRequestTimeSlotsAlreadyClicked: boolean;
}

export const ActionsAvailability: React.FC<ActionsAvailabilityProps> = ({
  handleArrangeCallClicked,
  handleRequestTimeSlots,
  isArrangeCallButtonDisabled,
  isRequestAdditionalTimeSlotsDisabled,
  availabilityRequiredDate,
  availabilityRequesterName,
  isRequiredMoreAvailabilitySlotsAllowed,
  waitingHours,
  isRequestTimeSlotsAlreadyClicked
}) => {
  const { t } = useTranslation("expertAvailabilityTab");

  const formattedDate = moment(availabilityRequiredDate).format("ll - h:mm a");

  return (
    <div className={styles.expertAvailabilityFooter}>
      <div className={styles.actionButtonsContainer}>
        <Button
          type="secondary"
          onClick={handleRequestTimeSlots}
          disabled={
            isRequestAdditionalTimeSlotsDisabled ||
            isRequestTimeSlotsAlreadyClicked ||
            !isRequiredMoreAvailabilitySlotsAllowed
          }
          text={t("requestAdditionalTimeSlots")}
        />
        <Button
          onClick={handleArrangeCallClicked}
          disabled={isArrangeCallButtonDisabled}
          text={t("arrangeCall")}
        />
      </div>
      {!isRequiredMoreAvailabilitySlotsAllowed && (
        <div
          className={styles.requireAvailabilityMessage}
          dangerouslySetInnerHTML={{
            __html: sanitizer(
              t("requireAvailabilityMessage", {
                date: formattedDate,
                name: availabilityRequesterName,
                hours: waitingHours
              })
            )
          }}
        />
      )}
    </div>
  );
};
