import clsx from "clsx";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

import { WORKSPACE_TYPES } from "@arbolus-technologies/utils";

import { ExpertDocument } from "../../../../../models/documents";
import { User } from "../../../../../models/user";
import { UtilsService } from "../../../../../services";
import { CIQAvatar } from "../../../../app/components";

interface ExpertsDocumentItemProps {
  expertsDocument: ExpertDocument;
  onShareClicked: (chatId: string) => void;
  isLoading: boolean;
}

const ExpertsDocumentItem: React.FC<ExpertsDocumentItemProps> = ({
  expertsDocument,
  onShareClicked,
  isLoading
}) => {
  const {
    firstName,
    lastName,
    workspaceId,
    workspaceType,
    profileImageUrl,
    shared
  } = expertsDocument;
  const [readyToShare, setReadyToShare] = useState(isLoading);

  const { t } = useTranslation("expertsDocumentItem");

  const { EXPERT_SUPPORT, CLIENT_SUPPORT } = WORKSPACE_TYPES;

  const isSupportChat = [EXPERT_SUPPORT, CLIENT_SUPPORT].includes(
    workspaceType
  );

  const handleReadyShareToggle = (): void => {
    setReadyToShare(!readyToShare);
  };

  const handleShareClicked = (): void => {
    onShareClicked(workspaceId);
  };

  useEffect(() => {
    setReadyToShare(isLoading);
  }, [isLoading]);

  return (
    <div className="user-item">
      <div className="left-container">
        <div className="users-avatar">
          <CIQAvatar
            username={
              isSupportChat
                ? ""
                : UtilsService.displayUserName({
                    firstName,
                    lastName
                  } as User)
            }
            profileImageUrl={profileImageUrl}
            fallBackImageClass={clsx("fallback-avatar", {
              supportHead: isSupportChat
            })}
          />
        </div>
        <div className="user-name">{`${firstName} ${lastName}`}</div>
      </div>
      <div className="right-container">
        {readyToShare && (
          <Button
            size="sm"
            color="primary"
            disabled={isLoading}
            className="share-btn"
            onClick={handleShareClicked}
          >
            {t("share")}
          </Button>
        )}
        <span
          className={clsx("ciq-icon", {
            "ciq-check": shared,
            "ciq-close": readyToShare,
            "ciq-plus": !shared && !readyToShare
          })}
          onClick={handleReadyShareToggle}
        />
      </div>
    </div>
  );
};

export default ExpertsDocumentItem;
