import { yupResolver } from "@hookform/resolvers/yup";
import { Eventcalendar } from "@mobiscroll/react";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { SelectOption } from "@arbolus-technologies/models/common";
import { PanelId } from "@arbolus-technologies/stores/panels";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { SlidePanel } from "../../../Panels/SlidePanel/SlidePanel";
import { AvailabilityActions } from "./AvailabilityActions/AvailabilityActions";
import { DateTimeDropdown } from "./DateTimeDropdown/DateTimeDropdown";
import { ExpertAvailabilitySchema } from "./ExpertAvailabilitySchema";
import { TimezonesDetails } from "./TimezoneDetails/TimezonesDetails";
import {
  DATE,
  END_TIME,
  EXPERT_TIMEZONE,
  ExpertAvailabilityPanelBase,
  ID,
  START_TIME
} from "./types";

import styles from "./AvailabilitySlidePanelForm.module.scss";

export interface SelectedEventDetails {
  id: number;
  date: Date;
  startTime: Date;
  endTime: Date;
  inst: Eventcalendar;
}

interface AvailabilitySlidePanelFormProps {
  slidePanelId: PanelId;
  projectTimezone: string;
  expertTimezone: string;
  timezones: Map<string, SelectOption>;
  selectedEvent?: SelectedEventDetails;
  onUpdateEvent: (data: ExpertAvailabilityPanelBase) => void;
  onDeleteEvent: (id: number) => void;
  onClosePanel: () => void;
}

export const AvailabilitySlidePanelForm: React.FC<
  AvailabilitySlidePanelFormProps
> = ({
  slidePanelId,
  projectTimezone,
  expertTimezone,
  timezones,
  selectedEvent,
  onUpdateEvent,
  onDeleteEvent,
  onClosePanel
}) => {
  const { t } = useTranslation("expertAvailabilityScheduler");

  const handleDeleteEvent = (): void => {
    selectedEvent && onDeleteEvent?.(selectedEvent.id);
  };

  const onSubmit = (values: ExpertAvailabilityPanelBase): void => {
    onUpdateEvent?.(values);
  };

  const methods = useForm<ExpertAvailabilityPanelBase>({
    resolver: yupResolver(ExpertAvailabilitySchema),
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      [ID]: selectedEvent?.id,
      [DATE]: selectedEvent?.date,
      [START_TIME]: selectedEvent?.startTime,
      [END_TIME]: selectedEvent?.endTime,
      [EXPERT_TIMEZONE]: expertTimezone
    }
  });

  const { handleSubmit } = methods;

  return (
    <SlidePanel
      title={<div>{t("expertAvailabilitySchedulerPanel.updateTimeSlot")}</div>}
      panelId={slidePanelId}
      closeButtonDirection="right"
      width={SIDE_PANEL_SIZE._400}
      customCloseRequest={onClosePanel}
    >
      <div className={styles.slidePanelContainer}>
        <FormProvider {...methods}>
          <div>
            <DateTimeDropdown
              eventInstance={selectedEvent?.inst!}
              expertTimezone={expertTimezone}
            />
            <TimezonesDetails
              expertTimezone={expertTimezone}
              projectTimezone={projectTimezone}
              timezones={timezones}
            />
          </div>
          <AvailabilityActions
            handleDeleteEvent={handleDeleteEvent}
            handleSubmit={handleSubmit(onSubmit)}
          />
        </FormProvider>
      </div>
    </SlidePanel>
  );
};
