import i18next from "i18next";
import * as yup from "yup";

import { ANSWER_PREFERENCES_TYPE_VALUE } from "@arbolus-technologies/models/canopy";
import { MultiRatingChoice } from "@arbolus-technologies/models/canopy-panels";

import { MultiFormData } from "./ValueForExpertProvider";

const form = "answerValueCanopyExpert";

export const createValueExpertSchema = (
  minValue: number,
  maxValue: number,
  answerPreferenceType: ANSWER_PREFERENCES_TYPE_VALUE
): yup.ObjectSchema<MultiFormData> =>
  yup.object().shape({
    value: yup.number().when([], (schema: yup.NumberSchema<number>) => {
      if (
        answerPreferenceType === ANSWER_PREFERENCES_TYPE_VALUE.NUMBER ||
        answerPreferenceType === ANSWER_PREFERENCES_TYPE_VALUE.PERCENTAGE
      ) {
        return schema
          .transform((value) => (Number.isNaN(value) ? null : value))
          .min(
            minValue,
            i18next.t(`${form}:valueNumberMinError`, {
              minValue
            })
          )
          .max(
            maxValue,
            i18next.t(`${form}:valueNumberMaxError`, {
              maxValue
            })
          )
          .nullable();
      } else {
        return schema.nullable();
      }
    }),
    multiRatingValues: yup
      .array()
      .of(
        yup.object().shape({
          multiRatingChoiceId: yup.string().required(),
          multiRatingChoiceText: yup.string().required(),
          sortOrder: yup.number().required(),
          multiRatingValue: yup
            .number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .min(
              minValue,
              i18next.t(`${form}:valueNumberMinError`, {
                minValue
              })
            )
            .max(
              maxValue,
              i18next.t(`${form}:valueNumberMaxError`, {
                maxValue
              })
            )
            .nullable()
        })
      )
      .when([], (schema: yup.ArraySchema<MultiRatingChoice>) => {
        if (
          answerPreferenceType === ANSWER_PREFERENCES_TYPE_VALUE.MULTI_RATING
        ) {
          return schema.required();
        } else {
          return schema.nullable();
        }
      })
  });
