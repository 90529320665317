import { Epic } from "redux-observable";
import { of } from "rxjs";
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mergeMap,
  switchMap,
  tap
} from "rxjs/operators";
import { isOfType } from "typesafe-actions";

import { CIQError, ErrorResponse } from "@arbolus-technologies/api";

import {
  COPY_CANOPY_INVITATION_LINK_TO_CLIPBOARD,
  GET_CANOPY_CLIENT_V2,
  GET_CANOPY_EXPERTS_COUNT,
  GET_CANOPY_QUESTION,
  GET_CANOPY_QUESTIONS,
  GetCanopyQuestionAction
} from "../actions/CanopyV2ActionTypes";
import { CanopyV2StoreActions } from "../actions/CanopyV2Actions";
import {
  CanopyV2AppState,
  CanopyV2StoreAction,
  CanopyV2StoreDependencies
} from "../models/definitions";

const getCanopyByClientV2Epic: Epic<
  CanopyV2StoreAction,
  CanopyV2StoreAction,
  CanopyV2AppState,
  CanopyV2StoreDependencies
> = (action$, _, { canopyService, notificationService }) =>
  action$.pipe(
    filter(isOfType(GET_CANOPY_CLIENT_V2)),
    switchMap(({ payload: { canopyId } }) =>
      canopyService.getCanopy(canopyId).pipe(
        mergeMap((canopy) =>
          of(CanopyV2StoreActions.getCanopyClientV2Success(canopy))
        ),
        catchError((error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
          return of(CanopyV2StoreActions.getCanopyClientV2Failure(error));
        })
      )
    )
  );

const getCanopyExpertsCountEpic: Epic<
  CanopyV2StoreAction,
  CanopyV2StoreAction,
  CanopyV2AppState,
  CanopyV2StoreDependencies
> = (action$, _, { canopyService, notificationService }) =>
  action$.pipe(
    filter(isOfType(GET_CANOPY_EXPERTS_COUNT)),
    switchMap(({ payload: { canopyId } }) =>
      canopyService.getExpertsCount(canopyId).pipe(
        mergeMap((expertsCount) =>
          of(CanopyV2StoreActions.getCanopyExpertsCountSuccess(expertsCount))
        ),
        catchError((error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
          return of(CanopyV2StoreActions.getCanopyExpertsCountFailure(error));
        })
      )
    )
  );

const copyCanopyInvitationLinkToClipboardEpic: Epic<
  CanopyV2StoreAction,
  CanopyV2StoreAction,
  CanopyV2AppState,
  CanopyV2StoreDependencies
> = (action$, _, { notificationService, routing }) =>
  action$.pipe(
    filter(isOfType(COPY_CANOPY_INVITATION_LINK_TO_CLIPBOARD)),
    tap(({ payload: { canopyId, successMessage } }) => {
      navigator.clipboard.writeText(routing.invitationLink(canopyId));
      notificationService.showSuccess(successMessage);
    }),
    ignoreElements()
  );

const getQuestion: Epic<
  CanopyV2StoreAction,
  CanopyV2StoreAction,
  CanopyV2AppState,
  CanopyV2StoreDependencies
> = (action$, _, { notificationService, canopyService }) =>
  action$.pipe(
    filter(isOfType(GET_CANOPY_QUESTION)),
    switchMap(({ payload: { questionId } }: GetCanopyQuestionAction) =>
      canopyService.getQuestionFromApi(questionId).pipe(
        map((res) => CanopyV2StoreActions.getCanopyQuestionSuccess(res)),
        catchError((error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
          return of(CanopyV2StoreActions.getCanopyQuestionFailure(error));
        })
      )
    )
  );

const getCanopyQuestions: Epic<
  CanopyV2StoreAction,
  CanopyV2StoreAction,
  CanopyV2AppState,
  CanopyV2StoreDependencies
> = (action$, _, { canopyService, notificationService }) =>
  action$.pipe(
    filter(isOfType(GET_CANOPY_QUESTIONS)),
    switchMap(({ payload: { canopyId } }) =>
      canopyService.getCanopyQuestionsV2(canopyId).pipe(
        mergeMap((questions) =>
          of(CanopyV2StoreActions.getCanopyQuestionsSuccess(questions.items))
        ),
        catchError((error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
          return of(CanopyV2StoreActions.getCanopyQuestionsFailure(error));
        })
      )
    )
  );

export const CanopyV2Epics = [
  getCanopyByClientV2Epic,
  copyCanopyInvitationLinkToClipboardEpic,
  getQuestion,
  getCanopyQuestions,
  getCanopyExpertsCountEpic
];
