import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useParams } from "react-router";

import {
  ChromeExtensionBanner,
  ExpertFollowUpSlidePanel,
  ExpertProfileSidePanel,
  MixPanelEventNames,
  useArbolusTracking,
  useTimeTracking
} from "@arbolus-technologies/features/common";
import {
  CANOPY_STATUS,
  CanopyParamUrlTypes
} from "@arbolus-technologies/models/canopy";
import {
  CANOPY_DETAILS,
  CANOPY_DETAILS_ROUTE,
  CANOPY_EXPERTS_ROUTE,
  CANOPY_INDIVIDUAL_RESPONSE,
  CANOPY_QUESTIONS_ROUTE,
  CANOPY_SUMMARY,
  CANOPY_SUMMARY_ROUTE,
  CLIENT_ACTIVE_CANOPY_EXPERTS_V2_ROUTES,
  CLIENT_ACTIVE_CANOPY_QUESTIONS_V2_ROUTES,
  PROJECT_ROUTE
} from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  CanopyV2Selector,
  CanopyV2StoreActions
} from "@arbolus-technologies/stores/canopy-V2";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  BackButton,
  Badge,
  MenuButton
} from "@arbolus-technologies/ui/components";

import { useCanopyTitle } from "../Hooks/useCanopyTitle";
import { CanopyExperts } from "../Pages/CanopyExperts/CanopyExperts";
import { CanopyIndividualResponse } from "../Pages/CanopyIndividualResponse/CanopyIndividualResponse";
import { CanopyQuestions } from "../Pages/CanopyQuestions/CanopyQuestions";
import { CanopySummary } from "../Pages/CanopySummary/CanopySummary";
import { Details } from "../Pages/Details/Details";
import { DETAILS_TAB, EXPERTS_TAB, QUESTIONS_TAB, SUMMARY_TAB } from "./types";

import styles from "./CanopyClientWrapper.module.scss";

const SUMMARY_REGEX =
  /canopy\/[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}\/summary/i;
const QUESTIONS_REGEX =
  /canopy\/[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}\/questions/i;
const EXPERTS_REGEX =
  /canopy\/[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}\/experts/i;
const DETAILS_REGEX =
  /canopy\/[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}\/details/i;

export const CanopyClientWrapper: React.FC = () => {
  useCanopyTitle(true);
  const { t } = useTranslation("canopyV2");
  const { t: tMenu } = useTranslation("canopyPanels");
  const history = useHistory();
  const dispatch = useDispatch();
  const { trackClick } = useArbolusTracking();
  const { startTrackingTime, endTrackingTime } = useTimeTracking(
    MixPanelEventNames.CanopyTimeSpent
  );
  const { canopyId } = useParams<CanopyParamUrlTypes>();

  const isAdmin = useSelector(CacheSelector.isAdmin());
  const canopyData = useSelector(CanopyV2Selector.canopyData(canopyId));
  const canopyExpertsCount = useSelector(CanopyV2Selector.canopyExpertsCount());

  useEffect(() => {
    canopyId && startTrackingTime();

    return () => {
      canopyId && endTrackingTime({ canopyId, canopyName: canopyData?.title });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (canopyId) {
      dispatch(CanopyV2StoreActions.resetCanopyV2());
      dispatch(CanopyV2StoreActions.getCanopyClientV2(canopyId));
      dispatch(CanopyV2StoreActions.getCanopyExpertsCount(canopyId));
    }
  }, [canopyId, dispatch]);

  const {
    complete = 0,
    inReview = 0,
    rejected = 0,
    incomplete = 0
  } = canopyExpertsCount ?? {};
  const totalExperts = isAdmin
    ? complete + rejected + inReview + incomplete
    : complete;

  return (
    <>
      <ExpertProfileSidePanel hasFollowUp />
      <ExpertFollowUpSlidePanel />

      <div className={styles.mainContainer}>
        {canopyData && (
          <>
            {!isMobile && (
              <div className={styles.leftContainer}>
                <div className={styles.wrapperMenu}>
                  <BackButton
                    title={t("backToProject")}
                    onClick={() => {
                      dispatch(CanopyV2StoreActions.resetCanopyV2());
                      history.push(PROJECT_ROUTE(canopyData.projectId));
                    }}
                  />

                  <div className={styles.titleContainer}>
                    <span className={styles.title} title={canopyData.title}>
                      {canopyData.title}
                    </span>
                    {canopyData.status === CANOPY_STATUS.ARCHIVED && (
                      <Badge
                        text={canopyData.status}
                        background={ARBOLUS_COLORS.bColorSecondaryGreyDark}
                        textStyle="capitalize"
                      />
                    )}
                  </div>
                  <div className={styles.menuContainer}>
                    <MenuButton
                      label={`${tMenu(
                        EXPERTS_TAB.toLowerCase()
                      )} · ${totalExperts}`}
                      icon="groups"
                      isActive={EXPERTS_REGEX.test(window.location.toString())}
                      path={CANOPY_EXPERTS_ROUTE(canopyId)}
                      onClick={() => {
                        trackClick(MixPanelEventNames.CanopyExperts, {
                          canopyId
                        });
                      }}
                    />
                    <MenuButton
                      label={tMenu(QUESTIONS_TAB.toLowerCase())}
                      icon="quiz"
                      isActive={QUESTIONS_REGEX.test(
                        window.location.toString()
                      )}
                      path={CANOPY_QUESTIONS_ROUTE(canopyId)}
                      onClick={() => {
                        dispatch(
                          CanopyV2StoreActions.getCanopyExpertsCount(canopyId)
                        );
                        trackClick(MixPanelEventNames.CanopyQuestions, {
                          canopyId
                        });
                      }}
                    />
                    <MenuButton
                      label={tMenu(SUMMARY_TAB.toLowerCase())}
                      icon="list"
                      isActive={SUMMARY_REGEX.test(window.location.toString())}
                      path={CANOPY_SUMMARY_ROUTE(canopyId)}
                      onClick={() => {
                        dispatch(
                          CanopyV2StoreActions.getCanopyExpertsCount(canopyId)
                        );
                        trackClick(MixPanelEventNames.CanopySummary, {
                          canopyId
                        });
                      }}
                    />
                    <MenuButton
                      label={tMenu(DETAILS_TAB.toLowerCase())}
                      icon="dashboard"
                      isActive={DETAILS_REGEX.test(window.location.toString())}
                      path={CANOPY_DETAILS_ROUTE(canopyId)}
                      onClick={() => {
                        dispatch(
                          CanopyV2StoreActions.getCanopyExpertsCount(canopyId)
                        );
                        trackClick(MixPanelEventNames.CanopyDetails, {
                          canopyId
                        });
                      }}
                    />
                  </div>
                </div>
                <div>
                  {canopyData?.targetCompanyId && (
                    <ChromeExtensionBanner isCanopy />
                  )}
                </div>
              </div>
            )}
            <div className={styles.contentContainer}>
              <Switch>
                <Route path={CANOPY_DETAILS} component={Details} exact />
                <Route path={CANOPY_SUMMARY} component={CanopySummary} exact />
                <Route
                  path={CLIENT_ACTIVE_CANOPY_QUESTIONS_V2_ROUTES}
                  component={CanopyQuestions}
                  exact
                />
                <Route
                  path={CLIENT_ACTIVE_CANOPY_EXPERTS_V2_ROUTES}
                  component={CanopyExperts}
                  exact
                />
                <Route
                  path={CANOPY_INDIVIDUAL_RESPONSE}
                  component={CanopyIndividualResponse}
                  exact
                />
              </Switch>
            </div>
          </>
        )}
      </div>
    </>
  );
};
