import { MbscCalendarColor, MbscEventcalendarView } from "@mobiscroll/react";
import { IValidateProps } from "@mobiscroll/react/dist/src/core/util/datetime";
import moment from "moment";
import { useMemo } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import { SelectOption } from "@arbolus-technologies/models/common";
import { DATE_TIME_FORMAT } from "@arbolus-technologies/utils";

import {
  getClientInactiveTimes,
  getHighlightedClientDays
} from "./AvailabilityUtils";
import { noWorkingDaysColor } from "./constants";

export const useSchedulerSettings = (
  expertTimezone: string,
  projectTimezone: string,
  timezones: Map<string, SelectOption>
): {
  minDate: string;
  maxDate: string;
  viewSettings: MbscEventcalendarView;
  invalidDates: IValidateProps[];
  colorSettings: MbscCalendarColor[];
} => {
  const { t } = useTranslation("expertAvailabilityScheduler");

  const minDate = moment().format(DATE_TIME_FORMAT);
  const maxDate = moment().add(13, "day").format(DATE_TIME_FORMAT);

  const viewSettings: MbscEventcalendarView = useMemo(
    () => ({
      schedule: {
        type: "day",
        size: isMobile ? 1 : 7,
        allDay: false,
        timezones: [
          {
            timezone: expertTimezone,
            label: t("expertTimezoneTitle", {
              timezone: timezones.get(expertTimezone)?.customLabel
            })
          },
          {
            timezone: projectTimezone,
            label: t("clientTimezoneTitle", {
              timezone: timezones.get(projectTimezone)?.customLabel
            })
          }
        ]
      }
    }),
    [expertTimezone, projectTimezone, t, timezones]
  );

  const colorSettings: MbscCalendarColor[] = useMemo(() => {
    const {
      startHighlightDateToday,
      endHighlightDateToday,
      startHighlightDateNextDay,
      endHighlightDateNextDay
    } = getHighlightedClientDays(expertTimezone, projectTimezone); // returns highlighted timeslots based on client's nearest availability

    const inactiveTimes = getClientInactiveTimes(
      expertTimezone,
      projectTimezone
    ); // returns inactive timeslots based on client's timezone

    return [
      ...inactiveTimes,
      // inactive expert's weekends time
      {
        start: "00:00",
        end: "24:00",
        recurring: {
          repeat: "weekly",
          weekDays: "SA,SU"
        },
        background: noWorkingDaysColor
      },
      // highlight client's today and next day
      {
        start: startHighlightDateToday,
        end: endHighlightDateToday,
        cssClass: "highlight"
      },
      {
        start: startHighlightDateNextDay,
        end: endHighlightDateNextDay,
        cssClass: "highlight"
      }
    ];
  }, [expertTimezone, projectTimezone]);

  const invalidDates = [
    {
      start: moment().add(-1, "day").toDate(),
      end: moment().toDate()
    }
  ];

  return { minDate, maxDate, viewSettings, invalidDates, colorSettings };
};
