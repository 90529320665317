import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { CanopyParamUrlTypes } from "@arbolus-technologies/models/canopy";
import { CANOPY_SEND_ANSWERS_ROUTE } from "@arbolus-technologies/routes";
import { CanopyExpertSelector } from "@arbolus-technologies/stores/canopy-expert";

import PointUp from "../../../assets/pointUp.png";

import styles from "./SubmitAnswers.module.scss";

interface SubmitAnswersProps {
  isInReview: boolean;
}

export const SubmitAnswers: React.FC<SubmitAnswersProps> = ({ isInReview }) => {
  const { t } = useTranslation("submitAnswers");
  const history = useHistory();
  const { canopyId } = useParams<CanopyParamUrlTypes>();

  const expertCanopy = useSelector(CanopyExpertSelector.canopySelector());

  return (
    <div className={styles.submitContainer}>
      <div className={styles.details}>
        <div className={styles.title}>
          <img src={PointUp} alt="pointUp" className={styles.pointUp} />
          <h3>{t("oneLastThing")}</h3>
        </div>
        <p className={styles.subtitle}>{t("reviewAndSubmit")}</p>
      </div>
      <Button
        type="secondary"
        onClick={() =>
          history.replace(CANOPY_SEND_ANSWERS_ROUTE(canopyId), {
            canopyTitle: expertCanopy.title
          })
        }
        text={isInReview ? t("updateAnswers") : t("submitAnswers")}
      />
    </div>
  );
};
