import {
  ListCollectionsAction,
  ListCollectionsSuccessAction
} from "../actions/ListCollections/ListCollectionsActionTypes";
import { BookmarkCollectionsReducerState } from "../models/definitions";
import { initialState } from "./InitialState";

export const handleListCollections = (
  state = initialState,
  { payload: { queryParams, searchTerm } }: ListCollectionsAction
): BookmarkCollectionsReducerState => {
  const store = queryParams.offset === 0 ? undefined : state.collections.store;
  return {
    ...state,
    collections: {
      store,
      isLoading: true,
      searchTerm: searchTerm
    }
  };
};

export const handleListCollectionsSuccess = (
  state = initialState,
  { payload: { response } }: ListCollectionsSuccessAction
): BookmarkCollectionsReducerState => ({
  ...state,
  collections: {
    ...state.collections,
    isLoading: false,
    store: {
      items:
        response.pagination.offset === 0
          ? response.items
          : [...(state.collections.store?.items || []), ...response.items],
      total: response.pagination.count
    }
  }
});

export const handleListCollectionsFailure = (
  state = initialState
): BookmarkCollectionsReducerState => ({
  ...state,
  collections: {
    ...state.collections,
    isLoading: false
  }
});

export const handleListCollectionsReset = (
  state = initialState
): BookmarkCollectionsReducerState => ({
  ...state,
  collections: {
    searchTerm: state.collections.searchTerm,
    isLoading: false
  }
});
