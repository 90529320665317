import { FieldProps } from "formik";
import React from "react";
import { FormFeedback, Input } from "reactstrap";
import { InputType } from "reactstrap/lib/Input";

const TRIMMING_INPUT_TYPES = ["text", "textarea"];

interface CIQFormInputProps extends FieldProps {
  type?: InputType;
  disabledBlur?: boolean;
}

export function CIQFormInput({
  field,
  form: { touched, errors, setFieldValue, handleBlur },
  type,
  disabledBlur = false,
  ...props
}: CIQFormInputProps): JSX.Element {
  const handleBlurState = (event: React.FocusEvent<HTMLInputElement>): void => {
    if (!disabledBlur) {
      handleBlur(event);

      if (type) {
        const isTrimField = TRIMMING_INPUT_TYPES.includes(type);
        if (isTrimField) {
          setFieldValue(field.name, event.target.value.trim(), true);
        }
      }
    }
  };

  return (
    <>
      <Input
        autoComplete="off"
        invalid={!!(touched[field.name] && errors[field.name])}
        {...field}
        {...props}
        type={type}
        onBlur={handleBlurState}
      />
      {touched[field.name] && errors[field.name] && (
        <FormFeedback>{errors[field.name]}</FormFeedback>
      )}
    </>
  );
}
