import i18next from "i18next";
import moment from "moment";
import * as Yup from "yup";

import { CALENDAR_DATE_TIME_FORMAT } from "@arbolus-technologies/utils";

import { DATE, END_TIME, EXPERT_TIMEZONE, ID, START_TIME } from "./types";

export const ExpertAvailabilitySchema = Yup.object().shape({
  [ID]: Yup.number(),
  [DATE]: Yup.date().typeError(
    i18next.t(
      "expertAvailabilityScheduler:expertAvailabilitySchedulerPanel:dateRequired"
    )
  ),
  [START_TIME]: Yup.date()
    .typeError(
      i18next.t(
        "expertAvailabilityScheduler:expertAvailabilitySchedulerPanel:startTimeRequired"
      )
    )
    .test(
      "past-time-check",
      i18next.t(
        "expertAvailabilityScheduler:expertAvailabilitySchedulerPanel:passedStartTime"
      ),
      function validate() {
        const { date, startTime, endTime, expertTimezone } = this.parent;
        if (date && startTime && endTime) {
          return (
            moment(
              moment().tz(expertTimezone).format(CALENDAR_DATE_TIME_FORMAT)
            ).diff(startTime) < 0
          );
        }
        return true;
      }
    ),
  [END_TIME]: Yup.date()
    .typeError(
      i18next.t(
        "expertAvailabilityScheduler:expertAvailabilitySchedulerPanel:endTimeRequired"
      )
    )
    .test(
      "equal-check",
      i18next.t(
        "expertAvailabilityScheduler:expertAvailabilitySchedulerPanel:endAndStartTime"
      ),
      function validate() {
        const { date, startTime, endTime } = this.parent;
        if (date && startTime && endTime) {
          return !moment(startTime)
            .startOf("minute")
            .isSameOrAfter(moment(endTime).startOf("minute"));
        }
        return true;
      }
    )
    .test(
      "duration-check",
      i18next.t(
        "expertAvailabilityScheduler:expertAvailabilitySchedulerPanel:maxDuration"
      ),
      function validate() {
        const { date, startTime, endTime } = this.parent;
        if (date && startTime && endTime) {
          const durationExceed = moment(endTime).diff(
            moment(startTime),
            "seconds"
          );
          return durationExceed <= 60 * 60;
        }
        return true;
      }
    ),
  [EXPERT_TIMEZONE]: Yup.string()
});
