import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import {
  PanelId,
  PanelSelector,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { SlidePanel } from "../SlidePanel/SlidePanel";
import { ExpertProfile } from "./ExpertProfile";

interface ExpertProfileSidePanelProps {
  expertId?: string;
  redirectUrl?: string;
  hasFollowUp?: boolean;
}

export const ExpertProfileSidePanel: React.FC<ExpertProfileSidePanelProps> = ({
  expertId,
  redirectUrl,
  hasFollowUp
}) => {
  const { t } = useTranslation("expertProfileSidePanel");
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    expertId: panelExpertId,
    projectId,
    canopyId
  } = useSelector(
    PanelSelector.panelData<{
      expertId: string;
      projectId: string;
      canopyId: string;
    }>(PanelId.ExpertProfile)
  ) || {};

  const handleCloseProfile = (): void => {
    dispatch(PanelStoreActions.closePanel(PanelId.ExpertProfile));
    if (redirectUrl) {
      history.replace(redirectUrl);
    }
  };

  return (
    <SlidePanel
      panelId={PanelId.ExpertProfile}
      width={SIDE_PANEL_SIZE._720}
      title={t("expertProfile")}
      closeButtonDirection="right"
      visibleScroll
      customCloseRequest={handleCloseProfile}
    >
      <ExpertProfile
        expertId={expertId ?? panelExpertId}
        projectId={projectId}
        hasFollowUp={hasFollowUp}
        canopyId={canopyId}
      />
    </SlidePanel>
  );
};
