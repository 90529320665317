import React from "react";

import styles from "./WordCounter.module.scss";

interface WordCounterProps {
  textLength: number;
  maxLength: number;
}

export const WordCounter: React.FC<WordCounterProps> = ({
  textLength,
  maxLength
}) => (
  <div className={textLength > maxLength ? styles.outOfRange : styles.inRange}>
    {textLength}/{maxLength}
  </div>
);
