import React from "react";

import { Nps } from "@arbolus-technologies/features/common";
import { CanopyAnswerSummaryItemNps } from "@arbolus-technologies/models/canopy-panels";

import styles from "./CanopySummaryComponentNps.module.scss";

interface CanopySummaryComponentNpsProps {
  summary: CanopyAnswerSummaryItemNps;
}

export const CanopySummaryComponentNps: React.FC<
  CanopySummaryComponentNpsProps
> = ({ summary }) => (
  <div className={styles.container}>
    <span className={styles.subtitle}>{summary.questionDetails}</span>
    <Nps data={summary} />
  </div>
);
