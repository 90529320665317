import React from "react";
import { useTranslation } from "react-i18next";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { LinkBoxButton } from "../../BoxButton/LinkBoxButton";
import { ProjectManagementButtonProps } from "./ProjectManagementButtonProps";

export const ProjectDetailsButton: React.FC<ProjectManagementButtonProps> = ({
  to
}) => {
  const { t } = useTranslation("projectDetailsButton");
  return (
    <LinkBoxButton
      text={t("projectDetails")}
      icon={{
        color: ARBOLUS_COLORS.bColorSecondaryGreyDark,
        background: ARBOLUS_COLORS.bColorBaseWhite,
        name: "info"
      }}
      to={to}
    />
  );
};
