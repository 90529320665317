import React from "react";
import { Route, Switch } from "react-router";

import { ExpertContainer } from "@arbolus-technologies/features/expert-profile";

import {
  EXPERT_NETWORK,
  FIND_EXPERTS_PROFILE
} from "../../constants/navigation/authRoutes";
import { withFeatureProtection } from "../app/components/featureManagement/FeatureProtectionHOC";
import ExpertNetworkPage from "./pages/network/ExpertNetworkPage";

const ExpertNetworkContainer: React.FC = () => (
  <Switch>
    <Route exact path={FIND_EXPERTS_PROFILE} component={ExpertContainer} />
    <Route
      exact
      path={EXPERT_NETWORK}
      // @ts-ignore
      component={ExpertNetworkPage}
    />
  </Switch>
);

export default withFeatureProtection(ExpertNetworkContainer, "networkEnabled");
