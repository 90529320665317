import i18next from "i18next";
import * as Yup from "yup";

import {
  ANSWER_PREFERENCES_TYPE_VALUE,
  Choice,
  CreateEditQuestionFields,
  FormConstraints
} from "@arbolus-technologies/models/canopy";

import { CanopyBaseSchema } from "./CanopyBaseSchema";

const form = "canopyBuilder";

export const CanopyValueSchema = CanopyBaseSchema.concat(
  Yup.object().shape(
    {
      [CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE]:
        Yup.mixed<ANSWER_PREFERENCES_TYPE_VALUE>()
          .oneOf(Object.values(ANSWER_PREFERENCES_TYPE_VALUE))
          .required(i18next.t(`${form}:answerPreferencesTypeValueRequired`)),
      [CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE_NUMBER_INITIAL_RANGE]:
        Yup.number().when(
          [
            CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE,
            CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE_NUMBER_FINAL_RANGE
          ],
          (
            preferenceType: ANSWER_PREFERENCES_TYPE_VALUE,
            finalRangeNumber: number,
            schema: Yup.NumberSchema<number>
          ) =>
            schema
              .typeError(i18next.t(`${form}:valueNumberTypeError`))
              .required(i18next.t(`${form}:valueNumberRequiredError`))
              .min(
                FormConstraints.MIN_VALUE[preferenceType],
                i18next.t(`${form}:valueNumberMinError`, {
                  minValue: FormConstraints.MIN_VALUE[preferenceType]
                })
              )
              .max(
                FormConstraints.MAX_VALUE[preferenceType] - 1,
                i18next.t(`${form}:valueNumberMaxError`, {
                  maxValue: FormConstraints.MAX_VALUE[preferenceType] - 1
                })
              )
              .test(
                "max-check",
                i18next.t(`${form}:fromGreaterThanToError`),
                (initialRangeNumber) => {
                  if (initialRangeNumber && finalRangeNumber) {
                    return initialRangeNumber < finalRangeNumber;
                  }
                  return true;
                }
              )
        ),
      [CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE_NUMBER_FINAL_RANGE]:
        Yup.number().when(
          [
            CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE,
            CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE_NUMBER_INITIAL_RANGE
          ],
          (
            preferenceType: ANSWER_PREFERENCES_TYPE_VALUE,
            initialRangeNumber: number,
            schema: Yup.NumberSchema<number>
          ) =>
            schema
              .typeError(i18next.t(`${form}:valueNumberTypeError`))
              .required(i18next.t(`${form}:valueNumberRequiredError`))
              .min(
                FormConstraints.MIN_VALUE[preferenceType] + 1,
                i18next.t(`${form}:valueNumberMinError`, {
                  minValue: FormConstraints.MIN_VALUE[preferenceType] + 1
                })
              )
              .max(
                FormConstraints.MAX_VALUE[preferenceType],
                i18next.t(`${form}:valueNumberMaxError`, {
                  maxValue: FormConstraints.MAX_VALUE[preferenceType]
                })
              )
              .test(
                "min-check",
                i18next.t(`${form}:toSmallerThanFromError`),
                (finalRangeNumber) => {
                  if (initialRangeNumber && finalRangeNumber) {
                    return initialRangeNumber < finalRangeNumber;
                  }
                  return true;
                }
              )
        ),
      [CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE_MULTI_RATING_CHOICES]:
        Yup.lazy((choices: unknown[]) =>
          Yup.array()
            .of(
              Yup.object({
                text: Yup.string()
                  .required(i18next.t(`${form}:choiceRequired`))
                  .max(
                    FormConstraints.MAX_CHOICE_LENGTH,
                    i18next.t(`${form}:choiceMaxLength`, {
                      length: FormConstraints.MAX_CHOICE_LENGTH
                    })
                  )
                  .test(
                    "is-unique",
                    i18next.t(`${form}:uniqueChoices`),
                    function (value) {
                      const { sortOrder } = this.parent;

                      return !(
                        choices as { text: string; sortOrder: string }[]
                      ).some(
                        (choice) =>
                          choice.text === value &&
                          sortOrder !== choice.sortOrder
                      );
                    }
                  ),
                sortOrder: Yup.number()
              })
            )
            .when(
              [CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE],
              (
                preferenceType: ANSWER_PREFERENCES_TYPE_VALUE,
                schema: Yup.ArraySchema<Choice>
              ) => {
                if (
                  preferenceType === ANSWER_PREFERENCES_TYPE_VALUE.MULTI_RATING
                ) {
                  return schema.max(FormConstraints.MAX_CHOICES).required();
                } else {
                  return schema.nullable();
                }
              }
            )
        )
    },
    [
      [
        CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE_NUMBER_INITIAL_RANGE,
        CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE_NUMBER_FINAL_RANGE
      ]
    ]
  )
);
