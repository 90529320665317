import { Button } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import {
  BaseSurvey,
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  SurveyService,
  ToasterService
} from "@arbolus-technologies/api";
import { SurveyCard } from "@arbolus-technologies/features/common";
import { SURVEY_STATUS } from "@arbolus-technologies/models/survey";
import {
  PROJECT_CREATE_SURVEY_ROUTE,
  PROJECT_ROUTE
} from "@arbolus-technologies/routes";
import { Loader } from "@arbolus-technologies/ui/components";
import { MainPageLayout } from "@arbolus-technologies/ui/layout";
import { useDocumentTitle } from "@arbolus-technologies/utils";

import { SurveyListFilters } from "../../Components/SurveyListFilters";

import styles from "./SurveyListPage.module.scss";

interface SurveyListPageProps {
  surveyService?: typeof SurveyService;
  notificationService?: ToasterService;
}

export const SurveyListPage: React.FC<SurveyListPageProps> = ({
  surveyService = SurveyService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("surveyListPage");
  const { projectId } = useParams<{ projectId: string }>();
  useDocumentTitle("surveyListPage", "title");

  const [surveys, setSurveys] = useState<BaseSurvey[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getSurveys();
  }, []);

  const getSurveys = useCallback(
    (searchTerm?: string, surveyStatus?: SURVEY_STATUS) => {
      setIsLoading(true);
      surveyService
        .getAllSurveys({ projectId, searchTerm, surveyStatus })
        .subscribe(
          (response) => {
            setSurveys(response.items);
            setIsLoading(false);
          },
          (error: ErrorResponse<CIQError>) => {
            notificationService.showApiErrors(error);
            setIsLoading(false);
          }
        );
    },
    [surveyService, surveys?.length, projectId, notificationService]
  );

  return (
    <MainPageLayout
      title={t("title")}
      stickyHeader
      rightButtonContainer={
        <Link to={PROJECT_CREATE_SURVEY_ROUTE(projectId)}>
          <Button type="primary">{t("newSurvey")}</Button>
        </Link>
      }
      backLink={PROJECT_ROUTE(projectId).pathname as string}
    >
      <SurveyListFilters onFilterChange={getSurveys} />
      {isLoading && <Loader isFull />}
      {!isLoading && (
        <div className={styles.cardsContainer}>
          {surveys.map((survey) => (
            <SurveyCard key={survey.id} {...survey} />
          ))}
        </div>
      )}
    </MainPageLayout>
  );
};
