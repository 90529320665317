import { replace } from "connected-react-router";
import i18next from "i18next";
import moment from "moment";
import { Epic, ofType } from "redux-observable";
import { of, zip } from "rxjs";
import {
  catchError,
  filter,
  flatMap,
  map,
  switchMap,
  takeUntil
} from "rxjs/operators";
import { isOfType } from "typesafe-actions";

import { ToasterService } from "@arbolus-technologies/api";
import { REFERRAL_SUB_STATE } from "@arbolus-technologies/models/common";
import { ProjectNxStoreActions } from "@arbolus-technologies/stores/project";
import { ProjectExpertsStoreActions } from "@arbolus-technologies/stores/project-experts-store";

import { ProjectStoreActions } from "..";
import { AppConstants, AuthRouteConstants } from "../../../../constants";
import { APP_USER_ROLES } from "../../../../constants/app";
import { PROJECT_STATES } from "../../../../constants/project";
import { CIQError, ErrorResponse } from "../../../../models/api";
import {
  EventService,
  ProjectService,
  UtilsService
} from "../../../../services";
import { AppAction } from "../../../../store/actions";
import { AppState } from "../../../../store/reducers";
import { NavBarStoreActions } from "../../../app/store";
import {
  EXIT_FROM_PROJECT,
  GET_PROJECT,
  GET_PROJECT_SUCCESS,
  REFETCH_PROJECT_REFERRALS_DATA,
  REFETCH_REFERRAL_SUMMARY,
  UPDATE_PROJECT_STATE
} from "../actions/ProjectActionTypes";

const notification = new ToasterService();

const projectGetEpic: Epic<AppAction, AppAction, AppState> = (action$) =>
  action$.pipe(
    filter(isOfType(GET_PROJECT)),
    switchMap((action) =>
      ProjectService.getProject(action.payload.projectId).pipe(
        switchMap(
          ({
            id,
            name,
            projectDetailsUpdate,
            expertDetailsUpdate,
            projectState,
            clientId,
            created,
            hasComplianceCheck,
            hasScreeningQuestions,
            invitationUrl,
            timezone,
            defaultEventGuests
          }) => {
            const updatedDates: moment.Moment[] = [
              moment(UtilsService.convertUTCToActiveZone(projectDetailsUpdate)),
              moment(UtilsService.convertUTCToActiveZone(expertDetailsUpdate)),
              moment(UtilsService.convertUTCToActiveZone(created))
            ];

            const latestUpdateDate = moment.max(updatedDates);

            return of(
              ProjectStoreActions.getProjectSuccess(
                id,
                name,
                latestUpdateDate,
                projectState,
                clientId,
                hasComplianceCheck,
                hasScreeningQuestions,
                defaultEventGuests,
                invitationUrl,
                timezone
              ),
              NavBarStoreActions.navbarUpdateProjectName(id, name),
              ProjectNxStoreActions.getProjectClientIdSuccess(clientId)
            );
          }
        ),
        takeUntil(action$.pipe(ofType(EXIT_FROM_PROJECT))),
        catchError((error: ErrorResponse<CIQError>) => {
          notification.showError(
            error.message || i18next.t("projectService:projectNotFound")
          );
          return of(
            ProjectStoreActions.getProjectFailure(),
            replace(AuthRouteConstants.PROJECTS)
          );
        })
      )
    )
  );

const projectBaseDataEpic: Epic<AppAction, AppAction, AppState> = (action$) =>
  action$.pipe(
    filter(isOfType(GET_PROJECT_SUCCESS)),
    switchMap(({ payload: { projectId } }) =>
      zip(
        ProjectService.getProjectMembers(projectId, false),
        EventService.getEventsDuration(projectId)
      ).pipe(
        map(([members, eventDuration]) =>
          ProjectStoreActions.getProjectBaseDataSuccess(members.items, {
            start: moment(eventDuration.start).add(-1, "minutes").toISOString(),
            end: moment(eventDuration.end).add(1, "days").toISOString()
          })
        ),
        takeUntil(action$.pipe(ofType(EXIT_FROM_PROJECT))),
        catchError((error: ErrorResponse<CIQError>) => {
          notification.showError(
            error.message || i18next.t("restService:somethingWrong")
          );
          return of(
            ProjectStoreActions.getProjectFailure(),
            replace(AuthRouteConstants.PROJECTS)
          );
        })
      )
    )
  );

const projectGetClientDataEpic: Epic<AppAction, AppAction, AppState> = (
  action$,
  store
) =>
  action$.pipe(
    filter(isOfType(GET_PROJECT_SUCCESS)),
    filter(() =>
      store.value.auth.roles.some((role) =>
        [APP_USER_ROLES.client, APP_USER_ROLES.adminClient].includes(role)
      )
    ),
    switchMap(({ payload: { clientId, projectId } }) =>
      zip(
        ProjectService.getReferralSummary(projectId),
        ProjectService.getProjectReferrals(projectId, false, false)
      ).pipe(
        flatMap(([referralSummary, referrals]) =>
          of(
            ProjectStoreActions.getProjectClientDataSuccess(
              referrals.items
                .filter(
                  (r) => r.application.subStatus !== REFERRAL_SUB_STATE.REJECT
                )
                .map(({ expertId, id, status }) => ({
                  referralId: id,
                  expertId,
                  status
                })),
              referralSummary
            ),
            ProjectExpertsStoreActions.getProjectSummarySuccess(referralSummary)
          )
        ),
        takeUntil(action$.pipe(ofType(EXIT_FROM_PROJECT))),
        catchError((error: ErrorResponse<CIQError>) => {
          notification.showError(
            error.message || i18next.t("restService:somethingWrong")
          );
          return of(
            ProjectStoreActions.getProjectFailure(),
            replace(AuthRouteConstants.PROJECTS)
          );
        })
      )
    )
  );

const refetchReferralsEpic: Epic<AppAction, AppAction, AppState> = (action$) =>
  action$.pipe(
    filter(isOfType(REFETCH_PROJECT_REFERRALS_DATA)),
    switchMap((action) =>
      ProjectService.getProjectReferrals(
        action.payload.projectId,
        false,
        false
      ).pipe(
        map((referrals) =>
          ProjectStoreActions.refetchReferralDataSuccess(
            referrals.items
              .filter(
                (r) => r.application.subStatus !== REFERRAL_SUB_STATE.REJECT
              )
              .map(({ expertId, id, status }) => ({
                referralId: id,
                expertId,
                status
              }))
          )
        ),
        takeUntil(action$.pipe(ofType(EXIT_FROM_PROJECT))),
        catchError((error: ErrorResponse<CIQError>) => {
          notification.showError(
            error.message || i18next.t("restService:somethingWrong")
          );
          return of(ProjectStoreActions.refetchReferralDataFailure(error));
        })
      )
    )
  );

const updateProjectState: Epic<AppAction, AppAction, AppState> = (
  action$,
  store
) =>
  action$.pipe(
    filter(isOfType(UPDATE_PROJECT_STATE)),
    switchMap((action) => {
      const { projectId, projectState } = action.payload;
      return ProjectService.updateProjectState(projectId, projectState).pipe(
        flatMap(() => {
          const isActivated = projectState === PROJECT_STATES.ACTIVE;

          notification.showSuccess(
            i18next.t(
              isActivated
                ? "projectBasePage:projectActiveSuccess"
                : "projectBasePage:projectArchivedSuccess"
            )
          );

          const observableOpr: AppAction[] = [
            ProjectStoreActions.updateProjectStateSuccess(projectState)
          ];

          const {
            project,
            app: { projectListForNavbar }
          } = store.value;

          if (isActivated) {
            observableOpr.push(
              NavBarStoreActions.getProjectListForNavbar(false)
            );
          } else {
            const isExistInNavbarProjects = projectListForNavbar.find(
              (projectListItem) => projectListItem.id === project.projectId
            );

            // If project only exist in navbar list, then dispatch following actions
            if (isExistInNavbarProjects) {
              observableOpr.push(
                NavBarStoreActions.navbarRemoveProject(project.projectId),
                NavBarStoreActions.getProjectListForNavbar(false)
              );
            }
          }

          return observableOpr;
        }),
        catchError((error) => {
          notification.showError(
            error.message || i18next.t("restService:somethingWrong")
          );
          return of(ProjectStoreActions.updateProjectStateFailure());
        })
      );
    })
  );

const refetchReferralSummaryEpic: Epic<AppAction, AppAction, AppState> = (
  action$,
  store
) =>
  action$.pipe(
    filter(isOfType(REFETCH_REFERRAL_SUMMARY)),
    filter(() =>
      [
        AppConstants.APP_USER_ROLES.client,
        AppConstants.APP_USER_ROLES.adminClient
      ].includes(store.value.auth.currentRole)
    ),
    switchMap(({ payload: { projectId } }) =>
      ProjectService.getReferralSummary(projectId).pipe(
        map((referralSummary) =>
          ProjectStoreActions.refetchReferralSummarySuccess(referralSummary)
        ),
        catchError((error: ErrorResponse<CIQError>) => {
          notification.showError(
            error.message || i18next.t("restService:somethingWrong")
          );
          return of(ProjectStoreActions.refetchReferralSummaryFailure(error));
        })
      )
    )
  );

const projectEpics = [
  projectGetEpic,
  projectBaseDataEpic,
  projectGetClientDataEpic,
  updateProjectState,
  refetchReferralSummaryEpic,
  refetchReferralsEpic
];

export default projectEpics;
