import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Angle } from "@arbolus-technologies/api";
import { AngleDetails } from "@arbolus-technologies/features/angles";
import { SlidePanel } from "@arbolus-technologies/features/common";
import { AngleModel } from "@arbolus-technologies/models/common";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import {
  ProjectAnglesSelector,
  ProjectAnglesStoreActions
} from "@arbolus-technologies/stores/project-angles-store";
import { LoaderOrComponent } from "@arbolus-technologies/ui/components";

import { AnglesList } from "../AnglesList/AnglesList";
import { ViewAngle } from "./ViewAngle";

// TODO: Project Legacy that will be moved on project dashboard v2

interface AnglesSlidePanelProps {
  projectId: string;
  angles: Angle[];
  isAdmin?: boolean;
  updateAngles: () => void;
}

enum PANEL_STATE {
  LIST,
  VIEW,
  NEW
}

export const AnglesSlidePanel: React.FC<AnglesSlidePanelProps> = ({
  projectId,
  angles,
  updateAngles,
  isAdmin = false
}) => {
  const { t } = useTranslation("projectAngles");

  const [panelState, setPanelState] = useState(PANEL_STATE.LIST);
  const [creatingAngle, setCreatingAngle] = useState(false);
  const dispatch = useDispatch();
  const isCreateAngleLoading = useSelector(
    ProjectAnglesSelector.isCreateAngleLoading()
  );

  const handleAngleClick = useCallback(
    (angle: Angle | AngleModel) => {
      setPanelState(PANEL_STATE.VIEW);
      dispatch(ProjectAnglesStoreActions.getSingleAngle(angle.id));
    },
    [dispatch]
  );

  const handleNewAngleClick = useCallback(() => {
    dispatch(ProjectAnglesStoreActions.setAvailableColors(angles));
    setPanelState(PANEL_STATE.NEW);
  }, [dispatch, angles]);

  const closePanel = useCallback(() => {
    dispatch(PanelStoreActions.closePanel(PanelId.ManageAngles));
    dispatch(ProjectAnglesStoreActions.setFormCreateEditAngleEnabled());
    dispatch(ProjectAnglesStoreActions.resetSelectedAngle());
    setCreatingAngle(false);
    setTimeout(() => {
      // slight delay for the panel animation to finish
      setPanelState(PANEL_STATE.LIST);
    }, 500);
  }, [dispatch]);

  // Creating a new angle goes through redux and doesn't have any success callback
  // so we have to deal with it with multiple variables and this 2 `useEffect`
  useEffect(() => {
    if (isCreateAngleLoading) {
      setCreatingAngle(true);
    }
  }, [isCreateAngleLoading]);

  useEffect(() => {
    if (creatingAngle && !isCreateAngleLoading) {
      closePanel();
      updateAngles();
    }
  }, [creatingAngle, isCreateAngleLoading, closePanel, updateAngles]);

  return (
    <SlidePanel
      panelId={PanelId.ManageAngles}
      title={t("manage")}
      hideHeader={panelState === PANEL_STATE.VIEW}
      customCloseRequest={closePanel}
    >
      <LoaderOrComponent isLoading={angles === undefined}>
        {panelState === PANEL_STATE.LIST && (
          <AnglesList
            angles={angles!}
            onAngleClick={handleAngleClick}
            onNewClick={handleNewAngleClick}
          />
        )}
        {panelState === PANEL_STATE.VIEW && (
          <ViewAngle
            projectId={projectId}
            isAdmin={isAdmin}
            closePanel={closePanel}
          />
        )}
        {panelState === PANEL_STATE.NEW && (
          <AngleDetails projectId={projectId} isAdmin={isAdmin} />
        )}
      </LoaderOrComponent>
    </SlidePanel>
  );
};
