/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/display-name */
/* eslint-disable max-classes-per-file */
import queryString from "query-string";
import React from "react";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import {
  AuthRouteConstants,
  CanopyRouteConstants,
  PublicRouteConstants
} from "../../../constants";
import { CANOPY_WELCOME_ROUTE } from "../../../constants/validation";
import { AppState } from "../../../store/reducers";
import { AuthSelector } from "../../auth/store";

enum ROUTE_HOC_STATE {
  AUTHORIZED,
  GUEST_DIRECT_ACCESS,
  LOGOUT
}

interface RouteHOCStoreProps {
  isAuthenticated: boolean;
  userId?: string;
}

interface RouteHOCState {
  isAuthenticated: boolean;
  status: ROUTE_HOC_STATE;
}

type WithAuthAccessProps = RouteHOCStoreProps & RouteComponentProps;

export function withAuthAccess(wrappedComponent: React.FC) {
  const WithAccessComponent = class extends React.Component<
    WithAuthAccessProps,
    RouteHOCState
  > {
    constructor(props: WithAuthAccessProps) {
      super(props);

      const { AUTHORIZED, GUEST_DIRECT_ACCESS } = ROUTE_HOC_STATE;
      this.state = {
        isAuthenticated: props.isAuthenticated,
        status: props.userId ? AUTHORIZED : GUEST_DIRECT_ACCESS
      };
    }

    static getDerivedStateFromProps(
      nextProps: RouteHOCStoreProps,
      prevState: RouteHOCState
    ): RouteHOCState {
      const { AUTHORIZED, GUEST_DIRECT_ACCESS, LOGOUT } = ROUTE_HOC_STATE;
      const nextState = {} as RouteHOCState;
      nextState.isAuthenticated = nextProps.isAuthenticated;

      if (nextProps.isAuthenticated) {
        nextState.status = AUTHORIZED;
      } else if (prevState.isAuthenticated) {
        nextState.status = LOGOUT;
      } else {
        nextState.status = GUEST_DIRECT_ACCESS;
      }

      return nextState;
    }

    renderRedirection = (): JSX.Element => {
      const { AUTHORIZED, LOGOUT } = ROUTE_HOC_STATE;
      const { status } = this.state;
      const { location } = this.props;

      const desiredLocation = {
        hash: location.hash,
        redirect: location.pathname,
        search: location.search
      };

      if (status !== AUTHORIZED) {
        const isUserLoggingOut = status === LOGOUT;

        return (
          <Redirect
            to={{
              pathname: PublicRouteConstants.LOGIN,
              search: isUserLoggingOut
                ? undefined
                : queryString.stringify(desiredLocation)
            }}
          />
        );
      }

      return React.createElement(wrappedComponent);
    };

    render() {
      return this.renderRedirection();
    }
  };

  const mapStateToProps = createStructuredSelector<
    AppState,
    RouteHOCStoreProps
  >({
    userId: AuthSelector.authUserIdSelector(),
    isAuthenticated: AuthSelector.authIsUserAuthenticatedSelector()
  });

  return connect(mapStateToProps)(WithAccessComponent);
}

const returnPath = (pathname: string) => {
  let routeName = AuthRouteConstants.DASHBOARD;
  if (isCanopyWelcomeRoute(pathname)) {
    const newExpertApplicationFF = false; //isDevEnv();

    routeName = newExpertApplicationFF
      ? CanopyRouteConstants.CANOPY_APPLICATION_PAGE_ROUTE(
          getCanopyIdFromWelcomePath(pathname)
        )
      : CanopyRouteConstants.CANOPY_DETAILS_PAGE_ROUTE(
          getCanopyIdFromWelcomePath(pathname)
        );
  }
  return routeName;
};

export function withPublicAccess(wrappedComponent: React.FC<{}>) {
  const PublicAccessComponent = class extends React.Component<
    RouteHOCStoreProps & RouteComponentProps,
    {}
  > {
    renderRedirection = (): JSX.Element => {
      const { isAuthenticated, location } = this.props;

      if (isAuthenticated) {
        const { redirect, hash, search } = queryString.parse(
          location.search
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ) as any;

        return (
          <Redirect
            to={{
              pathname: redirect || returnPath(location.pathname),
              hash,
              search
            }}
          />
        );
      }
      return React.createElement(wrappedComponent, {});
    };

    render() {
      return this.renderRedirection();
    }
  };

  const mapStateToProps = createStructuredSelector<
    AppState,
    RouteHOCStoreProps
  >({
    isAuthenticated: AuthSelector.authIsUserAuthenticatedSelector()
  });

  return connect(mapStateToProps)(PublicAccessComponent);
}

const isCanopyWelcomeRoute = (pathname: string): boolean =>
  CANOPY_WELCOME_ROUTE.test(pathname);

const getCanopyIdFromWelcomePath = (canopyWelcomePath: string): string =>
  canopyWelcomePath.split("/")[2];
