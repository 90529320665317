import { useCallback, useEffect, useState } from "react";

import {
  Angle,
  AnglesService,
  DefaultToasterService,
  ToasterService
} from "@arbolus-technologies/api";

interface UseAngleListProps {
  projectId: string;
  anglesService?: typeof AnglesService;
  notificationService?: ToasterService;
}

export const useAngleList = ({
  projectId,
  anglesService = AnglesService,
  notificationService = DefaultToasterService
}: UseAngleListProps): Angle[] => {
  const [angles, setAngles] = useState<Angle[]>([]);

  const getAngles = useCallback(() => {
    anglesService.getAnglesCards(projectId, 0, 200).subscribe({
      next: (response) => setAngles(response.items),
      error: notificationService.showApiErrors
    });
  }, [anglesService, notificationService.showApiErrors, projectId]);

  useEffect(() => {
    getAngles();
  }, [getAngles]);

  return angles;
};
