import { Alert, Card, Flex, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  LandingSurvey,
  SurveyService,
  ToasterService
} from "@arbolus-technologies/api";
import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import {
  SURVEY_EXPERT_STATUS,
  SURVEY_STATUS,
  SurveyParamUrlType
} from "@arbolus-technologies/models/survey";
import { PublicRoutes } from "@arbolus-technologies/routes";

import { ShareThoughts } from "../ShareThoughts";
import { SurveyData } from "../SurveyData/SurveyData";
import { SurveyInformation } from "../SurveyInformation/SurveyInformation";

import styles from "./DetailsAndInformation.module.scss";

const { Title } = Typography;

interface DetailsAndInformationProps {
  survey: LandingSurvey;
  surveyService?: typeof SurveyService;
  notificationService?: ToasterService;
}

export const DetailsAndInformation: React.FC<DetailsAndInformationProps> = ({
  survey,
  surveyService = SurveyService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("surveyDetailsAndInformation");
  const { surveyId } = useParams<SurveyParamUrlType>();
  const { trackClick } = useArbolusTracking();
  const history = useHistory();

  const [surveyExpertStatus, setSurveyExpertStatus] =
    useState<SURVEY_EXPERT_STATUS>();

  useEffect(() => {
    surveyService.getSurveyExpertStatus(surveyId).subscribe(
      ({ surveyExpertStatus }) => {
        setSurveyExpertStatus(surveyExpertStatus);
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
      }
    );
  }, []);

  const handleMixpanelTracking = () => {
    const survey = localStorage.getItem(`survey_${surveyId}`);
    const surveyObj = JSON.parse(survey ?? "{}");

    if (!surveyObj.started) {
      localStorage.setItem(
        `survey_${surveyId}`,
        JSON.stringify({ ...surveyObj, started: true })
      );

      trackClick(MixPanelEventNames.SurveyStartSurveyButton);
    }
  };

  const handleStartSurvey = () => {
    const isSurveyClosed = survey.status === SURVEY_STATUS.CLOSED;
    const isSurveyCompleted =
      surveyExpertStatus === SURVEY_EXPERT_STATUS.COMPLETED;

    if (isSurveyClosed || isSurveyCompleted) {
      history.push(PublicRoutes.SURVEY_FEEDBACK_ROUTE(survey.id), {
        isSurveyClosed,
        isSurveyCompleted
      });
    } else {
      history.push(PublicRoutes.SURVEY_COMPLIANCE_ROUTE(survey.id), {
        surveyLink: survey.link,
        surveyProvider: survey.provider
      });
      handleMixpanelTracking();
    }
  };

  return (
    <Card className={styles.detailsAndInformationCard}>
      <Flex vertical gap={48}>
        <Title level={1} className={styles.surveyTitle}>
          {survey.title}
        </Title>
        <SurveyData survey={survey} onStartSurvey={handleStartSurvey} />
        <Alert
          message={t("importantInfoTitle")}
          description={t("importantInfoDescription")}
          type="info"
          showIcon
        />
        <SurveyInformation />
        <ShareThoughts onStartSurvey={handleStartSurvey} />
      </Flex>
    </Card>
  );
};
