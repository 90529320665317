import clsx from "clsx";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { SurveyQuestion } from "../../../../pages/models";

import styles from "./ScoreSelect.module.scss";

const SCORES = Array.from(Array(11).keys());

export function ScoreSelect({ question }: { question: SurveyQuestion }) {
  const { setValue, getValues } = useFormContext();
  const [selectedScore, setSelectedScore] = useState<number>(
    getValues(question.fieldName)
  );
  const { t } = useTranslation("expertSurvey");

  function selectScore(score: number) {
    setValue(question.fieldName, score);
    setSelectedScore(score);
  }

  return (
    <div className={styles.scores}>
      <span className={styles.label}>{t("recommendLowChance")}</span>
      {SCORES.map((score) => (
        <Score
          key={score}
          value={score}
          selected={selectedScore === score}
          onClick={selectScore}
        />
      ))}
      <span className={styles.labelEnd}>{t("recommendHighChance")}</span>
    </div>
  );
}

function Score({
  value,
  onClick,
  selected = false
}: {
  value: number;
  onClick: (value: number) => void;
  selected: boolean;
}) {
  return (
    <div
      className={clsx(styles.score, { [styles.selected]: selected })}
      onClick={() => onClick(value)}
    >
      {value}
    </div>
  );
}
