import moment from "moment";
import { Observable } from "rxjs";
import "moment-timezone";

import { SORT_DIRECTION, restService } from "@arbolus-technologies/api";
import {
  APP_DATE_CHAT_EVENT_END_FORMAT,
  APP_DATE_CHAT_EVENT_START_FORMAT,
  PROJECT_EVENT_SIDE_PANEL_FORMAT,
  PROJECT_EVENT_SIDE_PANEL_START_TIME_FORMAT,
  PROJECT_EVENT_SIDE_PANEL_START_TIME_FORMAT_WITH_MINUTES,
  PROJECT_EVENT_SIDE_PANEL_VIEW_DATE_FORMAT,
  formatDate
} from "@arbolus-technologies/utils";

import { EVENTS_API, MAX_PAGE_SIZE } from "../constants/api";
import {
  ApiCreatedResponse,
  ApiDeletedResponse,
  ApiPaginatedResponse
} from "../models/api";
import {
  CreateEventRequest,
  Event,
  EventGetPaginatedRequest,
  EventGuest,
  EventsDuration,
  SaveEventRequest
} from "../models/event";
import { User } from "../models/user";

import { UtilsService } from ".";

export const eventService = {
  getEvents: (
    projectId: string,
    apiParams: EventGetPaginatedRequest
  ): Observable<ApiPaginatedResponse<Event>> =>
    restService.get<ApiPaginatedResponse<Event>>(
      EVENTS_API.GET_EVENTS(projectId),
      apiParams
    ),

  getEventsDuration: (projectId: string): Observable<EventsDuration> =>
    restService.get<EventsDuration>(EVENTS_API.GET_EVENTS_DURATION(projectId)),

  getEvent: (projectId: string, eventId: string): Observable<Event> =>
    restService.get<Event>(EVENTS_API.GET_EVENT(projectId, eventId)),

  getEventCandidates: (
    projectId: string,
    Offset = 0,
    limit = MAX_PAGE_SIZE,
    searchTerm = "",
    orderBy = "firstName",
    orderDirection = SORT_DIRECTION.ASCENDING
  ): Observable<ApiPaginatedResponse<User>> =>
    restService.get<ApiPaginatedResponse<User>>(
      EVENTS_API.GET_EVENT_CANDIDATES(projectId),
      {
        searchTerm,
        Offset,
        limit,
        orderBy,
        orderDirection
      }
    ),

  createEvent: (
    projectId: string,
    createEvent: CreateEventRequest
  ): Observable<ApiCreatedResponse> =>
    restService.post<Event>(EVENTS_API.CREATE_EVENT(projectId), createEvent),

  saveEvent: (
    projectId: string,
    eventId: string,
    saveEvent: SaveEventRequest
  ): Observable<Event> =>
    restService.put<Event>(
      EVENTS_API.SAVE_EVENTS(projectId, eventId),
      saveEvent
    ),

  deleteEvent: (
    projectId: string,
    eventId: string
  ): Observable<ApiDeletedResponse> =>
    restService.delete<ApiDeletedResponse>(
      EVENTS_API.DELETE_EVENT(projectId, eventId)
    ),

  parseMemberToEventGuest: (member: User): EventGuest => {
    const guest = {} as EventGuest;
    guest.user = member;
    guest.email = member.email;
    return guest;
  },

  mergeDateTimeWithTimeZone: (
    date: Date,
    time: Date,
    timeZone: string
  ): string => {
    const timeMoment = moment(time);
    const moderateDate = moment(date);
    moderateDate.set({
      hour: timeMoment.get("hour"),
      minute: timeMoment.get("minute"),
      second: timeMoment.get("second")
    });

    const timeZoned = moderateDate.clone().tz(timeZone, true);
    return timeZoned.toDate().toISOString();
  },

  generateEventMessageTime: (
    start: Date,
    end: Date,
    startFormat: string = APP_DATE_CHAT_EVENT_START_FORMAT,
    endFormat: string = APP_DATE_CHAT_EVENT_END_FORMAT,
    timeSeparator = " - "
  ): string => {
    const utcStart = UtilsService.convertUTCToActiveZone(start);
    const utcEnd = UtilsService.convertUTCToActiveZone(end);

    const a = formatDate(utcStart, startFormat);

    if (moment(utcStart).isSame(moment(utcEnd), "date")) {
      const b = formatDate(utcEnd, endFormat);
      return `${a}${timeSeparator}${b}`;
    }
    const c = formatDate(utcEnd, startFormat);
    return `${a} - ${c}`;
  },

  generateEventDate: (start: Date, end: Date): string => {
    const utcStartDate = UtilsService.convertUTCToActiveZone(start);
    const utcEndDate = UtilsService.convertUTCToActiveZone(end);

    if (moment(utcStartDate).isSame(moment(utcEndDate), "date")) {
      const date = formatDate(
        utcStartDate,
        PROJECT_EVENT_SIDE_PANEL_VIEW_DATE_FORMAT
      );
      return date;
    }

    const startData = formatDate(
      utcStartDate,
      PROJECT_EVENT_SIDE_PANEL_VIEW_DATE_FORMAT
    );
    const endDate = formatDate(
      utcEndDate,
      PROJECT_EVENT_SIDE_PANEL_VIEW_DATE_FORMAT
    );
    return `${startData} - ${endDate}`;
  },

  generateEventTime: (eventStart: Date, eventEnd: Date): string => {
    const utcStartDate = UtilsService.convertUTCToActiveZone(eventStart);
    const utcEndDate = UtilsService.convertUTCToActiveZone(eventEnd);

    let start;
    let end;
    if (moment(utcStartDate).isSame(moment(utcEndDate), "date")) {
      if (
        (+moment(utcStartDate).format("HH") > 12 &&
          +moment(utcEndDate).format("HH") > 12) ||
        (+moment(utcStartDate).format("HH") < 12 &&
          +moment(utcEndDate).format("HH") < 12)
      ) {
        if (utcStartDate.getMinutes() === 0) {
          start = formatDate(
            utcStartDate,
            PROJECT_EVENT_SIDE_PANEL_START_TIME_FORMAT
          );
        } else {
          start = formatDate(
            utcStartDate,
            PROJECT_EVENT_SIDE_PANEL_START_TIME_FORMAT_WITH_MINUTES
          );
        }
        end = formatDate(utcEndDate, PROJECT_EVENT_SIDE_PANEL_FORMAT);
        return `${start} - ${end}`;
      }
      start = formatDate(utcStartDate, PROJECT_EVENT_SIDE_PANEL_FORMAT);
      end = formatDate(utcEndDate, PROJECT_EVENT_SIDE_PANEL_FORMAT);
      return `${start} - ${end}`;
    }
    start = formatDate(utcStartDate, PROJECT_EVENT_SIDE_PANEL_FORMAT);
    end = formatDate(utcEndDate, PROJECT_EVENT_SIDE_PANEL_FORMAT);
    return `${start} - ${end}`;
  },

  generateRelativeEventTime: (
    eventStartTime: Date,
    eventEndTime: Date
  ): string => {
    let label = "";

    const currentTime = moment().startOf("date");
    const startTime = moment(
      UtilsService.convertUTCToActiveZone(eventStartTime)
    );
    const endTime = moment(UtilsService.convertUTCToActiveZone(eventEndTime));

    if (currentTime.diff(startTime, "days") === 0) {
      label += "Today";
    } else if (startTime.diff(currentTime, "days") === 1) {
      label += "Tomorrow";
    } else if (currentTime.isSame(startTime, "year")) {
      label += formatDate(startTime.toDate(), "D MMM");
    } else {
      label += formatDate(startTime.toDate(), "D MMM YYYY");
    }
    label += `, ${formatDate(startTime.toDate(), "h:mma")} - `;

    if (endTime.diff(startTime, "days") !== 0) {
      if (endTime.diff(currentTime, "days") === 1) {
        label += "Tomorrow";
      } else if (currentTime.isSame(endTime, "year")) {
        label += formatDate(endTime.toDate(), "D MMM");
      } else {
        label += formatDate(endTime.toDate(), "D MMM YYYY");
      }
      label += ", ";
    }
    label += formatDate(endTime.toDate(), "h:mma");

    return label;
  }
};
