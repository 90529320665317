import React from "react";
import { Control, FieldErrors } from "react-hook-form";

import { CheckboxController } from "../../CheckboxController/CheckboxController";
import { ClientReportExpertForm } from "./types";

import styles from "./ArbolusModalClientReportExpert.module.scss";

interface CheckBoxItemProps {
  name: string;
  text: string;
  control: Control<ClientReportExpertForm>;
  errors: FieldErrors<ClientReportExpertForm>;
}

export const CheckBoxItem: React.FC<CheckBoxItemProps> = ({
  name,
  text,
  control,
  errors
}) => (
  <div className={styles.item}>
    <CheckboxController
      control={control}
      errors={errors}
      name={name}
      text={text}
    />
  </div>
);
