import i18next from "i18next";
import * as Yup from "yup";

import {
  ANSWER_TYPE,
  CreateEditQuestionFields,
  FormConstraints
} from "@arbolus-technologies/models/canopy";

const form = "canopyBuilder";

export const CanopyBaseSchema = Yup.object().shape({
  [CreateEditQuestionFields.ID]: Yup.string(),
  [CreateEditQuestionFields.ORDER]: Yup.number(),
  [CreateEditQuestionFields.QUESTION]: Yup.string()
    .required(i18next.t(`${form}:questionRequired`))
    .max(
      FormConstraints.MAX_QUESTION_LENGTH,
      i18next.t(`${form}:questionMaxLength`, {
        length: FormConstraints.MAX_QUESTION_LENGTH
      })
    ),
  [CreateEditQuestionFields.DETAILS]: Yup.string().max(
    FormConstraints.MAX_DETAILS_LENGTH,
    i18next.t(`${form}:detailsMaxLength`, {
      length: FormConstraints.MAX_DETAILS_LENGTH
    })
  ),
  [CreateEditQuestionFields.ANSWER_TYPE]: Yup.mixed<ANSWER_TYPE>()
    .oneOf(Object.values(ANSWER_TYPE))
    .required(i18next.t(`${form}:answerTypeRequired`))
});
