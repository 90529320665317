import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  DefaultToasterService,
  ToasterService,
  WebHooksService
} from "@arbolus-technologies/api";

import { useCurrentPath } from "@arbolus-technologies/utils";
import { useLocation } from "react-router";
import {
  InternalArbolusModal,
  InternalArbolusModalProps
} from "../InternalArbolusModal";
import { GetClientReportExpertSchema } from "./ArbolusModalClientReportExpertSchema";
import { Body } from "./Body";
import {
  CLIENT_REPORT_EXPERT_SLACK_CHANNEL_URL,
  checkBoxesDefaultValues
} from "./constants";
import { ClientReportExpertForm } from "./types";

type ArbolusModalClientReportExpertProps = Omit<
  InternalArbolusModalProps,
  "subtitle" | "title" | "rightButton"
> & {
  email: string;
  expertName?: string;
  transcriptTitle?: string;
  trackSubmitMixPanel: (reason: string, feedbackText: string) => void;
  notificationService?: ToasterService;
  webHooksService?: typeof WebHooksService;
};

const getIndexChanged = (name: string): number => {
  const indexChanged = name.substring(
    name.indexOf("[") + 1,
    name.lastIndexOf("]")
  );
  return parseInt(indexChanged);
};

export const ArbolusModalClientReportExpert: React.FC<
  ArbolusModalClientReportExpertProps
> = ({
  isOpen,
  toggle,
  email,
  expertName,
  transcriptTitle,
  trackSubmitMixPanel,
  notificationService = DefaultToasterService,
  webHooksService = WebHooksService
}) => {
  const { t } = useTranslation("reportExpert");
  const currentPath = useCurrentPath();
  const location = useLocation();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    clearErrors,
    reset,
    watch,
    trigger
  } = useForm<ClientReportExpertForm>({
    mode: "all",
    reValidateMode: "onSubmit",
    // @ts-ignore
    resolver: yupResolver(GetClientReportExpertSchema),
    defaultValues: {
      feedbackText: "",
      reportReason: checkBoxesDefaultValues
    }
  });

  const { fields, update } = useFieldArray({
    control,
    name: "reportReason",
    keyName: "id"
  });

  const handleLeftButton = () => {
    clearErrors();
    reset();
    toggle();
  };

  const getSlackMessage = (reason: string, feedbackText: string): string =>
    expertName
      ? t("reportedMessageCanopy", {
          email,
          expertName,
          reason,
          feedbackText
        })
      : t("reportedMessageTranscript", {
          email,
          transcriptTitle,
          reason,
          feedbackText
        });

  const onSubmit = (data: ClientReportExpertForm) => {
    const reason = data.reportReason.find((item) => item.isSelected)?.text;
    trackSubmitMixPanel(reason!, data.feedbackText);
    const feedbackText = data.feedbackText;

    const message = getSlackMessage(reason!, feedbackText);

    const link = `<${currentPath}|${expertName ? "Canopy" : "Transcript"}>`;

    webHooksService
      .sendSlackMessage(
        `${link}${message}`,
        CLIENT_REPORT_EXPERT_SLACK_CHANNEL_URL!
      )
      .subscribe(
        () => {
          notificationService.showSuccess(t("reportSuccess"), "bottom-center");
        },
        () => {
          notificationService.showError(t("reportFailure"), "bottom-center");
        },
        () => {
          handleLeftButton();
        }
      );
  };

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name?.includes("reportReason[")) {
        const lastUpdated = getIndexChanged(name);

        value?.reportReason?.forEach((item, index) => {
          if (item?.isSelected && index !== lastUpdated) {
            update(index, { isSelected: false, text: item.text! });
            trigger("feedbackText");
          }
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger, isDirty, update]);

  return (
    <InternalArbolusModal
      title={t("reportExpertTitle")}
      body={
        <Body
          fields={fields}
          control={control}
          errors={errors}
          isDirty={isDirty}
        />
      }
      isOpen={isOpen}
      toggle={toggle}
      leftButton={{
        text: t("cancel"),
        onClick: handleLeftButton,
        type: "tertiary"
      }}
      rightButton={{
        text: t("reportExpert"),
        type: "rejection",
        onClick: handleSubmit(onSubmit),
        disabled: !isDirty
      }}
    />
  );
};
