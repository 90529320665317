export enum INBOX_NOTIFICATION_CATEGORY {
  Surveys = "Surveys",
  ThingsTodo = "ThingsTodo",
  Messages = "Messages",
  EventInvites = "EventInvites",
  SharedDocs = "SharedDocs",
  NewJoins = "NewJoins",
  NewReferrals = "NewReferrals",
  Approvals = "Approvals",
  Applications = "Applications"
}

export enum INBOX_NOTIFICATION_TYPE {
  // Updates
  ExpertAddedToProjectAdminNotification = "ExpertAddedToProjectAdminNotification",
  ProjectLiveAdminNotification = "ProjectLiveAdminNotification",
  ProjectBriefUpdatedNotification = "ProjectBriefUpdatedNotification",
  // Applications
  ExpertCandidateRejectedAdminNotification = "ExpertCandidateRejectedAdminNotification",
  ApplicationCompletionAdminNotification = "ApplicationCompletionAdminNotification",
  DeclinedApplicationAdminNotification = "DeclinedApplicationAdminNotification",
  // SharedDoc
  TranscriptAvailableNotification = "TranscriptAvailableNotification",
  // NewJoins
  MemberAcceptInvitationAdminNotification = "MemberAcceptInvitationAdminNotification",
  ExpertAcceptInvitationAdminNotification = "ExpertAcceptInvitationAdminNotification",
  // NewReferrals
  ExpertsAssignedToProjectNotification = "ExpertsAssignedToProjectNotification",
  ExpertAddedToProjectClientNotification = "ExpertAddedToProjectClientNotification",
  // Messages
  ExpertMessagesClientTeamNotification = "ExpertMessagesClientTeamNotification",
  // ThingsTodo
  PendingProjectReviewNotification = "PendingProjectReviewNotification",
  ProjectUnreadMessagesNotification = "ProjectUnreadMessagesNotification",
  // Surveys
  CanopySurveyLaunchedNotification = "CanopySurveyLaunchedNotification",
  ExpertSubmittedCanopyNotification = "ExpertSubmittedCanopyNotification",
  // EventInvites
  EventNotification = "EventNotification"
}

export enum EVENT_NOTIFICATION_STATUS {
  New = "New",
  Modified = "Modified"
}

export enum NOTIFICATION_VERSION_TYPE {
  V1 = "V1",
  V2 = "V2" // CIQ-1365 FE - Inbox APIs - Allow messages to be in two categories
}
