import { ICellRendererParams } from "ag-grid-community";
import { IconButton } from "arbolus-ui-components";
import React from "react";
import { useDispatch } from "react-redux";

import {
  APPLICATION_STATUS,
  DO_NOT_CONTACT_STATUS,
  Referral
} from "@arbolus-technologies/models/common";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { ContextMenuRenderer } from "@arbolus-technologies/ui/components";
import { isBainClient } from "@arbolus-technologies/utils";

import { ReferralGridContext } from "../../Models/ReferralsTable";
import { ApproveReferralService } from "../../Services/Referrals/ApproveReferralService";
import { RejectFeedbackPanelData } from "../RejectFeedback/models";

import styles from "./CandidateActionRenderer.module.scss";

interface CandidateActionRendererProps
  extends ICellRendererParams<Referral, unknown, ReferralGridContext> {
  approveReferralService: ApproveReferralService;
}

export const CandidateActionRenderer: React.FC<CandidateActionRendererProps> = (
  params
) => {
  const dispatch = useDispatch();
  const { data, approveReferralService, api, context } = params;

  const isBain = isBainClient(context.project.client.id);

  const actionsDisabled =
    data?.application === APPLICATION_STATUS.REJECT || isBain;

  const onReject = React.useCallback(() => {
    const onSuccess = () => {
      api.applyTransaction({ remove: [data!] });
      context.reloadReferralsCount(context.project.id);
    };
    const panelData: RejectFeedbackPanelData = {
      onSuccess,
      referralId: data!.id,
      projectId: context.project.id,
      expert: data!.expert
    };
    dispatch(
      PanelStoreActions.openPanel(PanelId.RejectFeedbackPanel, panelData)
    );
  }, [api, context, data, dispatch]);

  const onApprove = React.useCallback(() => {
    approveReferralService.approve(
      context.project,
      [data!],
      context.modalService,
      api,
      context.reloadReferralsCount
    );
  }, [api, approveReferralService, context, data]);

  return (
    <div className="d-flex align-items-center w-100">
      <div className={styles.buttonsContainer}>
        {data?.expert.doNotContactStatus !== DO_NOT_CONTACT_STATUS.DNC && (
          <>
            <IconButton
              icon="close"
              disabled={actionsDisabled}
              iconColor={ARBOLUS_COLORS.bColorBaseWhite}
              backgroundColor={ARBOLUS_COLORS.bColorBaseOrange}
              borderColor={ARBOLUS_COLORS.bColorBaseOrange}
              onClick={onReject}
            />
            <IconButton
              icon="check"
              disabled={actionsDisabled}
              iconColor={ARBOLUS_COLORS.bColorBaseWhite}
              backgroundColor={ARBOLUS_COLORS.bColorSecondaryGreen}
              borderColor={ARBOLUS_COLORS.bColorSecondaryGreen}
              onClick={onApprove}
            />
          </>
        )}
      </div>
      <div className="d-flex align-items-center ml-auto">
        <ContextMenuRenderer {...params} />
      </div>
    </div>
  );
};
