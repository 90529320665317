import { Button, Card, Col, Form, Input, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NewPasswordField,
  PASSWORD_ERRORS
} from "./NewPasswordField/NewPasswordField";
import { useChangePassword } from "./useChangePassword";

import styles from "./SecuritySettings.module.scss";

const { Title } = Typography;

type FieldType = {
  oldPassword?: string;
  newPassword?: string;
  confirmNewPassword?: string;
};

export function SecuritySettings() {
  const { t } = useTranslation("newExpertProfileEditPage");
  const [formValid, setFormValid] = useState(false);
  const [form] = Form.useForm();
  const { changePassword, inProgress } = useChangePassword();

  return (
    <Card className={styles.card}>
      <Title level={5}>{t("securityPageHeader")}</Title>
      <p>{t("securityPageDescription")}</p>

      <Form
        form={form}
        layout="vertical"
        onFieldsChange={(_, allFields) => {
          setFormValid(
            allFields.every(
              (field) => field.errors?.length === 0 && field.touched
            )
          );
        }}
      >
        <Col span={12}>
          <Form.Item<FieldType>
            label={t("oldPasswordLabel")}
            name="oldPassword"
            rules={[
              { required: true, message: t("missingPasswordMessage") },
              { min: 6, message: t("missingPasswordMessage") }
            ]}
          >
            <Input.Password placeholder={t("oldPasswordPlaceholder")} />
          </Form.Item>
        </Col>

        <Form.Item<FieldType>
          label={t("newPasswordLabel")}
          name="newPassword"
          rules={[
            { required: true, message: PASSWORD_ERRORS.MIN_8_CHARS },
            { min: 8, message: PASSWORD_ERRORS.MIN_8_CHARS },
            {
              validator: (_, value: string) => {
                if (value.match(/\d/)) {
                  return Promise.resolve();
                }
                return Promise.reject(PASSWORD_ERRORS.INCLUDE_DIGIT);
              }
            },
            {
              validator: (_, value: string) => {
                if (value.match(/[!@#$%^&*(),.?":{}|<>]/)) {
                  return Promise.resolve();
                }
                return Promise.reject(PASSWORD_ERRORS.SPECIAL_CHAR);
              }
            },
            {
              validator: (_, value: string) => {
                if (value.match(/[a-z]/) && value.match(/[A-Z]/)) {
                  return Promise.resolve();
                }
                return Promise.reject(PASSWORD_ERRORS.MIX_LOWER_UPPER);
              }
            }
          ]}
          className={styles.newPassword}
        >
          {/* value and onChange props automatically supplied by antd */}
          {/* @ts-ignore */}
          <NewPasswordField />
        </Form.Item>
        <Col span={12}>
          <Form.Item<FieldType>
            label={t("confirmPasswordLabel")}
            name="confirmNewPassword"
            rules={[
              { required: true, message: t("missingPasswordMessage") },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue("newPassword") !== value) {
                    return Promise.reject(t("passwordsNotMatch"));
                  }
                  return Promise.resolve();
                }
              })
            ]}
          >
            <Input.Password placeholder={t("confirmPasswordPlaceholder")} />
          </Form.Item>
        </Col>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() =>
              changePassword(
                form.getFieldValue("oldPassword"),
                form.getFieldValue("newPassword")
              )
            }
            disabled={inProgress || !formValid}
          >
            {t("securityPageSaveButton")}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
