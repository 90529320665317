import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React, { useState } from "react";

import {
  DiscoverFilterOperator,
  DiscoverFilterOption
} from "@arbolus-technologies/models/project";

import styles from "./MobileFilterPill.module.scss";

interface MobileFilterPillProps {
  option: DiscoverFilterOption;
  onRemove: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onToggleIncludeExclude: () => void;
  disablePillToggle?: boolean;
  flagIcon?: React.ReactNode;
  isEditable?: boolean;
  onEdit?: () => void;
}

export const MobileFilterPill: React.FC<MobileFilterPillProps> = ({
  option,
  onRemove,
  onToggleIncludeExclude,
  disablePillToggle = false,
  flagIcon = false,
  isEditable = false,
  onEdit
}) => {
  const [showToggle, setShowToggle] = useState(false);

  const handleToggleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onToggleIncludeExclude();
  };

  return (
    <div
      className={clsx(styles.pill, {
        [styles.hasToggle]: showToggle,
        [styles.excluded]: option.operator === DiscoverFilterOperator.Exclude
      })}
    >
      <button
        className={styles.pillText}
        title={option.label}
        onClick={() =>
          (!disablePillToggle || isEditable) &&
          setShowToggle((previousState) => !previousState)
        }
      >
        {flagIcon}
        {option.label}
      </button>
      {(!disablePillToggle || isEditable) && (
        <div className={styles.pillIcon}>
          {!disablePillToggle && showToggle && (
            <Icon
              onClick={handleToggleClick}
              name={
                option.operator === DiscoverFilterOperator.Exclude
                  ? "add_circle"
                  : "do_not_disturb_on"
              }
              fontSize="16px"
            />
          )}
          {disablePillToggle && showToggle && isEditable && (
            <Icon onClick={onEdit} name="edit" fontSize="16px" />
          )}
        </div>
      )}
      <div className={styles.closeIcon}>
        <Icon onClick={onRemove} name="close" fontSize="16px" />
      </div>
    </div>
  );
};
