import React, { useState } from "react";

import { IncidentInfoContainer } from "../IncidentInfoContainer/IncidentInfoContainer";
import { ReportIncidentFormProvider } from "../ReportIncidentForm/ReportIncidentFormProvider";

enum IncidentReportSteps {
  INFO = "info",
  FORM = "form"
}

export const IncidentsSlidePanel: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<IncidentReportSteps>(
    IncidentReportSteps.INFO
  );

  const handleClickNextStep = () => setCurrentStep(IncidentReportSteps.FORM);

  return (
    <>
      {currentStep === IncidentReportSteps.INFO && (
        <IncidentInfoContainer nextStepOnClick={handleClickNextStep} />
      )}
      {currentStep === IncidentReportSteps.FORM && (
        <ReportIncidentFormProvider />
      )}
    </>
  );
};
