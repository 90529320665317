import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { matchPath } from "react-router";

import { ToasterService } from "@arbolus-technologies/api";

import { PROJECT_EVENT_TRANSCRIPT } from "../../../../../../../constants/navigation/projectRoutes";
import useTranscriptContext from "../../../../../../../contexts/transcript/UseTranscriptContext";
import { TranscriptHighLight } from "../../../../../../../models/transcript";
import { DraftJSUtilService } from "../../../../../../../services";
import { AuthSelector } from "../../../../../../auth/store";

const notification = new ToasterService();

interface HighlightPluginToolBar {
  highlightMap: Map<string, TranscriptHighLight[]>;
}

const HighlightPlugin: React.FC<HighlightPluginToolBar> = ({
  highlightMap
}): JSX.Element => {
  const match = matchPath<{ projectId: string; transcriptId: string }>(
    window.location.pathname,
    PROJECT_EVENT_TRANSCRIPT
  )?.params;
  const { t } = useTranslation("transcript");

  const { editorState, updateHighlights } = useTranscriptContext();
  const currentUserId = useSelector(AuthSelector.authUserIdSelector());

  const isHighlighted = DraftJSUtilService.isSelectionHighlighted(
    editorState!,
    currentUserId!
  );
  const [loading, setLoading] = useState(false);

  const propagationHandler = (event: React.MouseEvent): void => {
    event.preventDefault();
  };

  const highlightClickHandler = async (
    event: React.MouseEvent
  ): Promise<void> => {
    event.preventDefault();

    setLoading(true);
    try {
      if (isHighlighted) {
        const { monologueId, updatedEditorState, removedHighlightId } =
          await DraftJSUtilService.deleteHighlightForSelection(
            editorState!,
            match?.projectId!,
            match?.transcriptId!,
            currentUserId!,
            highlightMap
          );

        updateHighlights(updatedEditorState, monologueId, [removedHighlightId]);
      } else {
        const {
          monologueId,
          updatedEditorState,
          deletedHighlights,
          newHighlight
        } = await DraftJSUtilService.createHighlightForSelection(
          editorState!,
          match?.projectId!,
          match?.transcriptId!,
          currentUserId!
        );
        updateHighlights(
          updatedEditorState,
          monologueId,
          deletedHighlights,
          newHighlight
        );
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err.message) notification.showError(err?.message);
    }
  };

  return (
    <div
      className={clsx("highlight-option", {
        disabled: loading
      })}
      onClick={(event) => {
        highlightClickHandler(event);
      }}
      onMouseDown={propagationHandler}
    >
      <span className="ciq-icon ciq-component-icon-ic-bookmarks" />
      <span>
        {t(
          isHighlighted
            ? "transcript:removeHighLight"
            : "transcript:addHighLight"
        )}
      </span>
    </div>
  );
};

export default HighlightPlugin;
