import { useEffect, useRef, useState } from "react";
import * as workerTimers from "worker-timers";

import { getSupportedVideoType } from "../VideoRecordingUtils";

interface UseRecordVideo {
  startRecording: () => void;
  stopRecording: () => void;
  recordedChunks: Blob[] | null;
  recordingTime: number;
  supportedVideoType: string | null;
  isRecording: boolean;
  resetRecording: () => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const useRecordVideo = (videoRef: any): UseRecordVideo => {
  const stream: MediaStream = videoRef.current ? videoRef.current.stream : null;
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [recordedChunks, setRecordedChunks] = useState<Blob[] | null>(null);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [recordingTime, setRecordingTime] = useState<number>(0);
  const [supportedVideoType, setSupportedVideoType] = useState<string | null>(
    null
  );
  const defaultVideoType = "video/mp4;codecs=avc1";

  const startRecording = () => {
    setIsRecording(true);
    const mimeType = getSupportedVideoType();
    setSupportedVideoType(mimeType);
    if (mimeType) {
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType,
        bitsPerSecond: mimeType === defaultVideoType ? 100000 : 1000000 // 100k or 1M
      });

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data && event.data.size > 0) {
          setRecordedChunks([event.data]);
        }
      };

      mediaRecorderRef.current.start();
    } else {
      console.error("No supported mimeType found");
      setIsRecording(false);
    }
  };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      setIsRecording(false);
      mediaRecorderRef.current.stop();
      stream.getVideoTracks().forEach((track) => {
        track.stop();
      });
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let timer: any = null;
    if (isRecording) {
      timer = workerTimers.setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    } else if (!isRecording && recordingTime !== 0 && timer) {
      workerTimers.clearInterval(timer);
    }
    return () => timer && workerTimers.clearInterval(timer);
  }, [isRecording, recordingTime]);

  const resetRecording = (): void => {
    setRecordedChunks(null);
    setRecordingTime(0);
    setSupportedVideoType(null);
    setIsRecording(false);
  };

  return {
    startRecording,
    stopRecording,
    recordedChunks,
    recordingTime,
    supportedVideoType,
    isRecording,
    resetRecording
  };
};
