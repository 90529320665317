import React from "react";
import { useTranslation } from "react-i18next";

import {
  ExpertApplicationProject,
  PROJECT_APPLICATION_STATUS
} from "@arbolus-technologies/api";
import { ProjectStatusCircle } from "@arbolus-technologies/ui/components";
import { APP_DATE_FORMAT, formatDate } from "@arbolus-technologies/utils";

import styles from "./ProjectApplicationRow.module.scss";

export interface ProjectApplicationRowProps {
  project: ExpertApplicationProject;
  displayStatus?: boolean;
  displayDate?: boolean;
}

export const ProjectApplicationRow: React.FC<ProjectApplicationRowProps> = ({
  project,
  displayStatus = true,
  displayDate = false
}) => {
  const { t } = useTranslation("expertDrawer");

  const applicationText = {
    [PROJECT_APPLICATION_STATUS.ACCEPT]: "applicationCompleted",
    [PROJECT_APPLICATION_STATUS.REJECT]: "applicationRejected",
    [PROJECT_APPLICATION_STATUS.PENDING]: "applicationPending"
  };

  return (
    <div className={styles.applicationProject}>
      <div className={styles.titleAndDot}>
        <ProjectStatusCircle status={project.projectState} />
        <p className={styles.projectTitle}>{project.name}</p>
      </div>
      <p className={styles.projectApplicationStatus}>
        {displayStatus && t(applicationText[project.applicationStatus])}
        {displayDate && formatDate(project.created, APP_DATE_FORMAT)}
      </p>
    </div>
  );
};
