import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ExpertDetail,
  ExpertService,
  ToasterService,
  UserService
} from "@arbolus-technologies/api";
import { Card, Skeleton, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { zip } from "rxjs";
import styles from "./Details.module.scss";
import { DetailsForm } from "./DetailsForm";

const { Text, Title } = Typography;

export interface ExpertProfileFormDetails {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | undefined;
  linkedinProfile: string | undefined;
  profileImageUrl: string | undefined;
  title: string;
  overview: string | undefined;
  quickFacts: string[];
  funFact: string | undefined;
  experienceLevel: string | undefined;
  hourlyRate?: number;
  isoCurrencyCode?: string;
}

export interface DetailsProps {
  expertId: string;
  userId: string;
  userService?: typeof UserService;
  expertService?: typeof ExpertService;
  notificationService?: ToasterService;
}

const getExpertFormFormatted = (
  expert: ExpertDetail
): ExpertProfileFormDetails => {
  const {
    id,
    user: { firstName, lastName, phoneNumber, profileImageUrl, email, title },
    linkedinProfile,
    overview,
    funFact,
    quickFacts,
    experienceLevel,
    rateCards
  } = expert;

  return {
    id,
    firstName,
    lastName,
    email,
    phoneNumber,
    profileImageUrl,
    linkedinProfile,
    title,
    overview,
    quickFacts,
    funFact,
    experienceLevel,
    hourlyRate: rateCards && rateCards[0]?.price,
    isoCurrencyCode: rateCards && rateCards[0]?.isoCurrencyCode
  };
};

const linkedinProfileFormatter = (linkedinProfile: string | undefined) => {
  if (linkedinProfile === "" || linkedinProfile === undefined) {
    return linkedinProfile;
  }

  return linkedinProfile?.includes("https://")
    ? linkedinProfile
    : `https://${linkedinProfile}`;
};

export const Details: React.FC<DetailsProps> = ({
  expertId,
  userId,
  userService = UserService,
  expertService = ExpertService,
  notificationService = DefaultToasterService
}) => {
  const [expertProfile, setExpertProfile] =
    useState<ExpertProfileFormDetails | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation("expertDetailSection");

  useEffect(function getExpertDetails() {
    expertService.getExpertById({ expertId, includeRateCard: true }).subscribe(
      (expert: ExpertDetail) => {
        const expertForm = getExpertFormFormatted(expert);

        setExpertProfile(expertForm);
        setIsLoading(false);
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showError(error.message);
        setIsLoading(false);
      }
    );
  }, []);

  const updateExpertDetails = (values: ExpertProfileFormDetails) => {
    const {
      firstName,
      lastName,
      email,
      title,
      profileImageUrl,
      phoneNumber,
      linkedinProfile,
      overview,
      experienceLevel,
      quickFacts,
      funFact
    } = values;

    const userToUpdate = {
      id: userId,
      firstName,
      lastName,
      email,
      title,
      profileImageUrl,
      phoneNumber,
      linkedinProfile
    };

    const linkedinProfileUrl = linkedinProfileFormatter(linkedinProfile);

    zip(
      userService.updateProfile(userToUpdate),
      expertService.updateExpertProfile(
        expertId,
        overview ?? "",
        experienceLevel ?? "",
        quickFacts,
        linkedinProfileUrl,
        funFact
      )
    ).subscribe(
      () => {
        notificationService.showSuccess(t("expertDetailsUpdated"));
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showError(
          error?.message || t("expertDetailsUpdateError")
        );
      }
    );
  };

  const TitleCard = () => (
    <div className={styles.titleWrapper}>
      <Title level={5}>{t("profileDetails")}</Title>
      <Text className={styles.subtitle}>{t("subtitleCard")}</Text>
    </div>
  );

  if (isLoading) {
    return (
      <Card className={styles.card}>
        <Skeleton active paragraph={{ rows: 10 }} />
      </Card>
    );
  }

  return (
    <Space direction="vertical" size={16} className={styles.space}>
      <Card title={<TitleCard />}>
        {expertProfile && (
          <DetailsForm
            expert={expertProfile}
            updateExpertDetails={updateExpertDetails}
            isLoading={isLoading}
          />
        )}
      </Card>
    </Space>
  );
};
