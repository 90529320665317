/* spell-checker: disable */

import React from "react";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";

import "react-h5-audio-player/lib/styles.css";
import styles from "./CustomAudioPlayer.module.scss";

interface CustomAudioPlayerProps {
  src: string;
}

export const CustomAudioPlayer: React.FC<CustomAudioPlayerProps> = ({
  src
}) => (
  <AudioPlayer
    src={src}
    preload={"metadata"}
    autoPlay={false}
    autoPlayAfterSrcChange={false}
    customAdditionalControls={[]}
    showJumpControls={true}
    className={styles.audioPlayer}
    customProgressBarSection={[
      RHAP_UI.PROGRESS_BAR,
      RHAP_UI.CURRENT_TIME,
      <span key={"/"}>/</span>,
      RHAP_UI.DURATION
    ]}
  />
);
