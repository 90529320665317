import i18next from "i18next";
import * as Yup from "yup";

import { UserPr } from "@arbolus-technologies/api";
import { UserConstraints } from "@arbolus-technologies/ui/components";
import { utilService } from "@arbolus-technologies/utils";

import {
  ACTIVATE_CHAT,
  BRIEF,
  CASE_CODE,
  CONTACT_NUMBER,
  END_DATE,
  EXPERT_COUNT,
  MAIN_CONTACT,
  MANAGER_EMAIL,
  MANAGER_NAME,
  PARTNER_EMAIL,
  PARTNER_NAME,
  PROJECT_NAME,
  START_DATE,
  TIMEZONE
} from "./type";

const MIN_PROJECT_NAME_LENGTH = 3;
const MAX_PROJECT_NAME_LENGTH = 150;
const MIN_BRIEF_DESCRIPTION_LENGTH = 30;
const MAX_BRIEF_DESCRIPTION_LENGTH = 8000;
const MAX_CASE_CODE_LENGTH = 30;

const mainContactVal = Yup.object<UserPr>();

const baseSchema = {
  [PROJECT_NAME]: Yup.string()
    .required(i18next.t("projectSimplifiedForm:projectNameRequiredError"))
    .min(
      MIN_PROJECT_NAME_LENGTH,
      i18next.t("projectSimplifiedForm:projectNameMinLimitError", {
        length: MIN_PROJECT_NAME_LENGTH
      })
    )
    .max(
      MAX_PROJECT_NAME_LENGTH,
      i18next.t("projectSimplifiedForm:projectNameMaxLimitError", {
        length: MAX_PROJECT_NAME_LENGTH
      })
    ),
  [BRIEF]: Yup.string()
    .test(
      "html-len-min",
      i18next.t("projectSimplifiedForm:briefMinLimitError", {
        length: MIN_BRIEF_DESCRIPTION_LENGTH
      }),
      (val) => {
        const { length } = utilService.getStrippedTextFromHtml(val || "");
        return length === 0 || length >= MIN_BRIEF_DESCRIPTION_LENGTH;
      }
    )
    .test(
      "html-len-max",
      i18next.t("projectSimplifiedForm:briefMaxLimitError", {
        length: MAX_BRIEF_DESCRIPTION_LENGTH
      }),
      (val) => {
        const stripedHtml = utilService.getStrippedTextFromHtml(val || "");
        return stripedHtml.length <= MAX_BRIEF_DESCRIPTION_LENGTH;
      }
    ),
  [TIMEZONE]: Yup.string().required(
    i18next.t("projectSimplifiedForm:timezoneRequiredError")
  ),
  [MAIN_CONTACT]: mainContactVal
    .required(i18next.t("projectSimplifiedForm:mainContactRequiredError"))
    .test(
      "assignMainContact",
      i18next.t("projectSimplifiedForm:mainContactRequiredError"),
      (value) => value?.id !== undefined
    ),
  [CONTACT_NUMBER]: Yup.string().test(
    "phone-valid",
    i18next.t("projectSimplifiedForm:mainContactNumberError"),
    (phoneNumber) =>
      phoneNumber === undefined ||
      phoneNumber.length === 0 ||
      utilService.validateContactNumber(`+${phoneNumber}`)
  ),
  [ACTIVATE_CHAT]: Yup.boolean().default(true),
  [START_DATE]: Yup.date(),
  [END_DATE]: Yup.date().nullable(),
  [CASE_CODE]: Yup.string()
    .nullable()
    .max(
      MAX_CASE_CODE_LENGTH,
      i18next.t("projectSimplifiedForm:caseCodeLimitError", {
        length: MAX_CASE_CODE_LENGTH
      })
    ),
  [MANAGER_NAME]: Yup.string()
    .nullable()
    .max(
      UserConstraints.MAX_NAME_LENGTH,
      i18next.t("projectSimplifiedForm:nameLimitError", {
        length: UserConstraints.MAX_NAME_LENGTH
      })
    ),
  [MANAGER_EMAIL]: Yup.string()
    .nullable()
    .email(i18next.t("projectSimplifiedForm:emailFormatError"))
    .max(
      UserConstraints.MAX_EMAIL_LENGTH,
      i18next.t("projectSimplifiedForm:emailLimitError", {
        length: UserConstraints.MAX_EMAIL_LENGTH
      })
    ),
  [PARTNER_NAME]: Yup.string()
    .nullable()
    .max(
      UserConstraints.MAX_NAME_LENGTH,
      i18next.t("projectSimplifiedForm:nameLimitError", {
        length: UserConstraints.MAX_NAME_LENGTH
      })
    ),
  [PARTNER_EMAIL]: Yup.string()
    .nullable()
    .email(i18next.t("projectSimplifiedForm:emailFormatError"))
    .max(
      UserConstraints.MAX_EMAIL_LENGTH,
      i18next.t("projectSimplifiedForm:emailLimitError", {
        length: UserConstraints.MAX_EMAIL_LENGTH
      })
    )
};

export const ProjectCreateSchema = Yup.object().shape({
  ...baseSchema,
  [EXPERT_COUNT]: Yup.number()
    .typeError("Must be a number")
    .required(i18next.t("Required"))
    .min(1, i18next.t("Must be greater than or equal to 1"))
    .max(1000, i18next.t("Must be less than or equal to 1000"))
});

export const ProjectEditSchema = Yup.object().shape(baseSchema);
