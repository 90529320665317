import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  fiveSlotsIcon,
  fourSlotsIcon,
  oneSlotIcon,
  threeSlotsIcon,
  twoSlotsIcon,
  zeroSlotsIcon
} from "@arbolus-technologies/theme";

import styles from "./AvailabilityFooter.module.scss";

const icons = {
  icon_0: zeroSlotsIcon,
  icon_1: oneSlotIcon,
  icon_2: twoSlotsIcon,
  icon_3: threeSlotsIcon,
  icon_4: fourSlotsIcon,
  icon_5: fiveSlotsIcon
};

interface AvailabilityFooterProps {
  slotsCount: number;
  isDisabled: boolean;
  onSave: () => void;
  goToPrevStep: () => void;
}

export const AvailabilityFooter: React.FC<AvailabilityFooterProps> = ({
  slotsCount,
  isDisabled,
  onSave,
  goToPrevStep
}) => {
  const { t } = useTranslation("expertApplications");
  const initialIcon = slotsCount < 5 ? "icon_" + slotsCount : "icon_5";
  const icon = icons[initialIcon as keyof typeof icons];

  return (
    <div className={styles.availabilityFooter}>
      <div className={styles.footerLeft}>
        <img src={icon} alt="availabilityIcon" />
        <p>{t("availabilityDynamicMessage")}</p>
      </div>
      <div className={styles.btnContainer}>
        <Button type="tertiary" text={t("cancel")} onClick={goToPrevStep} />
        <Button
          onClick={onSave}
          disabled={isDisabled}
          text={t("confirmAndSubmit")}
        />
      </div>
    </div>
  );
};
