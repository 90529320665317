import { Icon } from "arbolus-ui-components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  ListProjectsRequest,
  PaginatedRequest,
  ProjectService
} from "@arbolus-technologies/api";
import { ProjectModel } from "@arbolus-technologies/models/common";
import { PanelId, PanelSelector } from "@arbolus-technologies/stores/panels";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  InfiniteScrollV2,
  SearchInput
} from "@arbolus-technologies/ui/components";
import { SEARCH_DEBOUNCE_TIMEOUT_COMMON } from "@arbolus-technologies/utils";

import styles from "./ProjectStep.module.scss";

interface ProjectStepProps {
  projectService?: typeof ProjectService;
  setProjectId: (projectId: string) => void;
  setIsAngleStep: () => void;
}

const initialParams = { offset: 0, limit: 50, projectName: "" };

export const ProjectStep: React.FC<ProjectStepProps> = ({
  projectService = ProjectService,
  setProjectId,
  setIsAngleStep
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [projects, setProjects] = useState<ProjectModel[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [queryParams, setQueryParams] =
    useState<PaginatedRequest<ListProjectsRequest>>(initialParams);

  const isAssignExpertToProjectPanelOpen = useSelector(
    PanelSelector.isPanelOpen(PanelId.AssignExpertToProject)
  );

  const { t } = useTranslation("assignExpertToProjectPanel");

  const getProjectList = useCallback(() => {
    setIsLoading(true);
    projectService
      ?.listProjects({
        ...queryParams,
        projectName: searchTerm,
        inProject: false
      })
      .subscribe({
        next: (projectResponse) => {
          const hasToUpdateProjects = projects === null || searchTerm !== "";

          const updatedProjects = hasToUpdateProjects
            ? projectResponse.items
            : [...projects, ...projectResponse.items];

          setProjects(updatedProjects);
          setQueryParams((prevState) => ({
            ...prevState,
            offset: projectResponse.pagination.offset + prevState.limit,
            count: projectResponse.pagination.count
          }));
          setIsLoading(false);
        },
        error: (error) => {
          console.error(error);
          setIsLoading(false);
        }
      });
  }, [projectService, queryParams, projects, searchTerm]);

  useEffect(() => {
    if (isAssignExpertToProjectPanelOpen) {
      getProjectList();
    } else {
      setProjects(null);
      setQueryParams(initialParams);
    }
    // Remove getProjectList as dependency to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAssignExpertToProjectPanelOpen]);

  useEffect(() => {
    getProjectList();
    // Remove getProjectList as dependency to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const onQueryChange = (searchValue: string) => {
    setSearchTerm(searchValue.toLowerCase());
    setQueryParams(initialParams);
    if (searchValue === "") {
      setProjects(null);
    }
  };

  const handleBottomReached = () => {
    if (queryParams.count && queryParams.count > queryParams.offset) {
      return getProjectList();
    }
    return null;
  };

  const handleOnClickProjectItem = (projectId: string) => {
    setProjectId(projectId);
    setIsAngleStep();
  };

  const rendererProjectList = (project: ProjectModel) => (
    <div
      className={styles.cardProject}
      key={project.id}
      onClick={() => handleOnClickProjectItem(project.id)}
    >
      <div className={styles.iconTextContainer}>
        <div className={styles.iconContainer}>
          <Icon
            name="folder"
            fontSize="24"
            color={ARBOLUS_COLORS.bColorBasePurple}
          />
        </div>
        <div className={styles.textContainer}>
          <span className={styles.projectName}>{project.name}</span>
          <span className={styles.clientName}>{project.client.name}</span>
        </div>
      </div>
      <div className={styles.arrowIconContainer}>
        <Icon
          name="chevron_right"
          fontSize="24px"
          color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
        />
      </div>
    </div>
  );

  return (
    <>
      <SearchInput
        onQueryChange={onQueryChange}
        isDebounced
        debouncingTime={SEARCH_DEBOUNCE_TIMEOUT_COMMON}
        placeholder={t("searchProjectPlaceholder")}
        hasSearchIcon
        containerClassnames={styles.searchInputContainer}
      />
      <div className={styles.infiniteContainer}>
        <InfiniteScrollV2
          onBottomReached={handleBottomReached}
          items={projects}
          isLoading={isLoading}
          renderer={rendererProjectList}
          customPadding={[0, 2, 0, 0]}
        />
      </div>
    </>
  );
};
