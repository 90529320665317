import { ActionCreator } from "redux";

import {
  CLOSE_CONTENT_PANEL,
  CloseContentPanelAction,
  OPEN_CONTENT_PANEL,
  OpenContentPanelAction
} from "./ContentPanelActionTypes";

export const openContentPanel: ActionCreator<OpenContentPanelAction> = (
  panelKey: string
) => ({
  type: OPEN_CONTENT_PANEL,
  payload: { panelKey }
});

export const closeContentPanel: ActionCreator<CloseContentPanelAction> = (
  panelKey: string
) => ({
  type: CLOSE_CONTENT_PANEL,
  payload: { panelKey }
});
