import { Observable } from "rxjs";

import { FeatureFlagsAppType } from "@arbolus-technologies/models/common";
import { CIQCrashAnalytics } from "@arbolus-technologies/utils";

import { TimeZone } from "../../index";
import { METADATA_API, USERS_API } from "../constants/api";
import {
  ApiPaginatedRequest,
  ApiPaginatedResponse,
  CityApiPaginatedRequest,
  CompaniesPaginatedResponse,
  ListResponse,
  SuccessResponse
} from "../models/api";
import {
  FederatedLoginUrlRequest,
  FederatedLoginUrlResponse
} from "../models/auth";
import {
  AgreementType,
  CityResponse,
  CompanyResponse,
  Country,
  Currency,
  EngagementAgreement,
  ExchangeRate,
  IndustriesRequest,
  Industry,
  Region,
  UserPermissions
} from "../models/meta";
import { restService } from "../restService";

export const MetaService = {
  getCountries: (): Observable<ListResponse<Country>> =>
    restService.get<ListResponse<Country>>(METADATA_API.GET_COUNTRIES()),
  getCities: (
    apiParams: CityApiPaginatedRequest
  ): Observable<ApiPaginatedResponse<CityResponse>> =>
    restService.get<ApiPaginatedResponse<CityResponse>>(
      METADATA_API.GET_CITIES(),
      apiParams
    ),
  getCurrencies: (): Observable<ListResponse<Currency>> =>
    restService.get<ListResponse<Currency>>(METADATA_API.GET_CURRENCIES()),
  getCompanies: (
    apiParams: ApiPaginatedRequest
  ): Observable<CompaniesPaginatedResponse<CompanyResponse>> =>
    restService.get<CompaniesPaginatedResponse<CompanyResponse>>(
      METADATA_API.GET_COMPANIES(),
      apiParams
    ),
  getCompanyById: (companyId: string): Observable<CompanyResponse> =>
    restService.get<CompanyResponse>(
      METADATA_API.GET_COMPANIES_BY_ID(companyId)
    ),
  federatedLoginUrl({
    authenticationProvider
  }: FederatedLoginUrlRequest): Observable<FederatedLoginUrlResponse> {
    return restService.get<FederatedLoginUrlResponse>(USERS_API.OPEN_ID(), {
      authenticationProvider,
      adminRedirect: true
    });
  },
  getIndustries: (
    level: IndustriesRequest
  ): Observable<ListResponse<Industry>> =>
    restService.get<ListResponse<Industry>>(
      METADATA_API.GET_INDUSTRIES(),
      level
    ),
  getTimezones: (): Observable<ListResponse<TimeZone>> =>
    restService.get<ListResponse<TimeZone>>(METADATA_API.GET_TIMEZONES()),
  getRegions: (): Observable<ListResponse<Region>> =>
    restService.get<ListResponse<Region>>(METADATA_API.GET_REGIONS()),
  getExchangeRates: (
    date: string
  ): Observable<ApiPaginatedResponse<ExchangeRate>> =>
    restService.get<ApiPaginatedResponse<ExchangeRate>>(
      METADATA_API.GET_EXCHANGE_RATES(),
      {
        date
      }
    ),
  sendAppCrashData: (
    errorData: CIQCrashAnalytics
  ): Observable<SuccessResponse> =>
    restService.post<SuccessResponse>(METADATA_API.POST_APP_CRASH_DATA(), {
      errorData
    }),
  getFeatureFlags: (
    frontendAppType: FeatureFlagsAppType
  ): Observable<UserPermissions> =>
    restService.get<UserPermissions>(METADATA_API.GET_FEATURE_FLAGS(), {
      frontendAppType
    }),
  getEngagementAgreement: (
    agreementType: AgreementType,
    clientId?: string,
    projectId?: string
  ): Observable<EngagementAgreement> =>
    restService.get<EngagementAgreement>(
      METADATA_API.GET_ENGAGEMENT_AGREEMENT(),
      { agreementType, clientId, projectId }
    )
};
