import React, { ReactNode, useState } from "react";
import { Collapse } from "reactstrap";

import styles from "./InfoDrawer.module.scss";

interface InfoDrawerProps {
  icon: string;
  title: string;
  collapseContent: ReactNode;
  isOpenByDefault?: boolean;
}

export const InfoDrawer: React.FC<InfoDrawerProps> = ({
  icon,
  title,
  collapseContent,
  isOpenByDefault
}) => {
  const [isInfoDrawerOpen, setIsInfoDrawerOpen] = useState(
    isOpenByDefault ? true : false
  );

  return (
    <div className={styles.container}>
      <div
        className={styles.header}
        onClick={() => setIsInfoDrawerOpen((prev) => !prev)}
      >
        <div className={styles.iconAndTitleContainer}>
          <img className={styles.icon} src={icon} alt="complementary icon" />
          <h1 className={styles.title} title={title}>
            {title}
          </h1>
        </div>

        <span className="material-symbols-sharp">
          {isInfoDrawerOpen ? "expand_less" : "expand_more"}
        </span>
      </div>
      <div
        style={{ marginBottom: isInfoDrawerOpen ? 24 : 0 }}
        className={styles.collapseContainer}
      >
        <Collapse isOpen={isInfoDrawerOpen}>{collapseContent}</Collapse>
      </div>
    </div>
  );
};
