import {
  PROJECT_CREATION_STEPS,
  ProjectCreationStep
} from "@arbolus-technologies/models/project";

export const getNextStep = (step: ProjectCreationStep): ProjectCreationStep => {
  const nextStepIndex = Object.values(PROJECT_CREATION_STEPS).indexOf(step) + 1;
  // In case we haven't found the step, the first one is returned (because -1 [not found] + 1 = 0 [first step])
  return Object.values(PROJECT_CREATION_STEPS)[nextStepIndex];
};

export function stepNumberToEnum(step: number): ProjectCreationStep {
  return PROJECT_CREATION_STEPS[
    Object.keys(PROJECT_CREATION_STEPS)[
      step
    ] as keyof typeof PROJECT_CREATION_STEPS
  ];
}

export function stepToNumber(step: ProjectCreationStep): number {
  return Object.values(PROJECT_CREATION_STEPS).indexOf(step);
}
