import i18next from "i18next";

import {
  DefaultToasterService,
  DocumentService
} from "@arbolus-technologies/api";
import {
  MAXIMUM_FILES_UPLOAD_AT_ONCE,
  MAXIMUM_FILE_NAME_LENGTH,
  MAXIMUM_FILE_UPLOADING_SIZE
} from "@arbolus-technologies/models/documents";
import { ChatUserRole } from "@arbolus-technologies/models/project";

export const handleCheckFiles = (
  fileList: FileList | null,
  fileInputRef: React.MutableRefObject<HTMLInputElement | null>
): File[] => {
  const files: File[] = Array.from(fileList ?? []);
  const validFiles: File[] = [];
  if (files.length >= MAXIMUM_FILES_UPLOAD_AT_ONCE) {
    DefaultToasterService.showError(
      i18next.t("maxFiles", { length: MAXIMUM_FILES_UPLOAD_AT_ONCE })
    );
    return [];
  }

  for (const element of files) {
    const file: File = element;

    if (file.size >= MAXIMUM_FILE_UPLOADING_SIZE) {
      DefaultToasterService.showError(
        i18next.t("fileSizeLimitError", {
          limit: MAXIMUM_FILE_UPLOADING_SIZE / 1024 ** 2
        })
      );
      break;
    }
    if (file.name.length >= MAXIMUM_FILE_NAME_LENGTH) {
      DefaultToasterService.showError(
        i18next.t("fileNameMaxLengthError", {
          length: MAXIMUM_FILE_NAME_LENGTH
        })
      );
      break;
    }
    validFiles.push(file);
  }
  if (fileInputRef.current) {
    // Clear the input value
    fileInputRef.current.value = "";
  }
  return validFiles;
};

export const uploadFileServiceByRole = {
  [ChatUserRole.Admin]: DocumentService.uploadAdminProjectDocument,
  [ChatUserRole.Client]: DocumentService.uploadClientProjectDocument,
  [ChatUserRole.Expert]: DocumentService.uploadExpertProjectDocument
};
