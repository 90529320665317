import React from "react";
import { useTracking } from "react-tracking";

import { DISABLE_MIXPANEL } from "@arbolus-technologies/utils";

import { MixPanelActions, MixpanelPages } from "../../Mixpanel/enums";

interface PageTrackerProps {
  readonly page: MixpanelPages;
  readonly from?: string;
  readonly children: React.ReactNode;
}

export function PageTracker({
  page,
  children,
  from
}: PageTrackerProps): React.ReactElement {
  const { Track } = useTracking(
    {
      page,
      eventName: page,
      properties: { action: MixPanelActions.VisitedPage, from }
    },
    { dispatchOnMount: true }
  );

  // Returning fragment is an exception - required for proper types
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (DISABLE_MIXPANEL) return <>{children}</>;

  return <Track>{children}</Track>;
}
