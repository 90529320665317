import clsx from "clsx";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Button, Col } from "reactstrap";

import { useIsChatAvailable } from "@arbolus-technologies/features/common";
import { STATUS_COLORS } from "@arbolus-technologies/models/canopy";
import {
  PROJECT_CHAT_BASE_ROUTE,
  PROJECT_TRANSCRIPTS_ROUTE
} from "@arbolus-technologies/routes";
import { ColoredBadge } from "@arbolus-technologies/ui/components";
import { APP_DATE_FORMAT, formatDate } from "@arbolus-technologies/utils";

import { DRAFT_PROJECT_ROUTE } from "../../../../constants/navigation/authRoutes";
import {
  PROJECT_CALENDAR_ROUTE,
  PROJECT_ROUTE
} from "../../../../constants/navigation/projectRoutes";
import { PROJECT_STATES } from "../../../../constants/project";
import { BaseProject } from "../../../../models/project";
import { UtilsService } from "../../../../services";

interface ProjectClientListItemProps {
  onDeleteClicked: (event: React.MouseEvent, projectId: string) => void;
  project: BaseProject;
}

const ProjectClientListItem: React.FC<ProjectClientListItemProps> = ({
  onDeleteClicked,
  project
}) => {
  const { projectState, id, hasNewCandidates, hasUnreadMessages, name } =
    project;
  const isActive = projectState === PROJECT_STATES.ACTIVE;
  const isArchive = projectState === PROJECT_STATES.ARCHIVE;
  const isDraft = projectState === PROJECT_STATES.DRAFT;
  const isChatAvailable = useIsChatAvailable();

  const formattedDate = formatDate(
    UtilsService.convertUTCToActiveZone(project.created),
    APP_DATE_FORMAT
  );
  const history = useHistory();
  const { t } = useTranslation("projectsPage");

  const handleCompleteDraftProject = (): void => {
    history.push(DRAFT_PROJECT_ROUTE(id));
  };

  const renderProjectActions = (): JSX.Element => (
    <>
      <Link
        className={clsx("ciq-icon ciq-card-view d-none d-md-block", {
          "notification-active": hasNewCandidates
        })}
        to={PROJECT_ROUTE(id)}
      >
        {hasNewCandidates && <span className="notification-dot" />}
      </Link>
      {isChatAvailable && (
        <Link
          className={clsx("ciq-icon ciq-comment d-none d-md-block", {
            "notification-active": hasUnreadMessages
          })}
          to={PROJECT_CHAT_BASE_ROUTE(id)}
        >
          {hasUnreadMessages && <span className="notification-dot" />}
        </Link>
      )}
      <Link
        className="ciq-icon ciq-calender d-none d-md-block"
        to={PROJECT_CALENDAR_ROUTE(id)}
      />
      <Link
        className="ciq-icon ciq-file d-none d-md-block"
        to={PROJECT_TRANSCRIPTS_ROUTE(id)}
      />
    </>
  );

  const statusText = useMemo(() => {
    if (isActive) {
      return t("active");
    }
    if (isDraft) {
      return t("draft");
    }
    if (isArchive) {
      return t("archived");
    }
    return "";
  }, [isActive, isDraft, isArchive, t]);

  const colorValue =
    STATUS_COLORS[projectState.toLowerCase() as keyof typeof STATUS_COLORS];

  return (
    <Col sm="12" key={id}>
      <div className="new-project-card-container">
        <Link
          className="left-container"
          to={isDraft ? DRAFT_PROJECT_ROUTE(id) : PROJECT_ROUTE(id)}
        >
          <div className="np-title">{name}</div>
          <div className="np-subtitle">
            <ColoredBadge text={statusText} backgroundColor={colorValue} />
            <span className="d-none d-md-block">
              {!isDraft &&
                t("createdBy", {
                  userName: UtilsService.displayUserName(project.owner),
                  formattedDate
                })}
            </span>
          </div>
        </Link>
        <div className="right-container">
          {!isDraft && renderProjectActions()}
          {isDraft && (
            <>
              <Button
                color="secondary"
                size="sm"
                onClick={handleCompleteDraftProject}
                className="d-none d-lg-flex"
              >
                {t("complete")}
              </Button>
              <span
                className="ciq-icon ciq-trash"
                onClick={(e): void => onDeleteClicked(e, id)}
              />
            </>
          )}
        </div>
      </div>
    </Col>
  );
};

export default ProjectClientListItem;
