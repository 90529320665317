import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Badge } from "reactstrap";

import {
  DefaultToasterService,
  PAYMENT_METHOD_TYPE,
  ProjectClientService,
  ProjectClientTotalSpendResponse,
  ToasterService
} from "@arbolus-technologies/api";
import {
  convertValueToCurrencyFormat,
  formatCredits
} from "@arbolus-technologies/utils";

import styles from "./ProjectSpendTotalAmount.module.scss";

interface ProjectSpendTotalAmountProps {
  projectId: string;
  projectClientService?: typeof ProjectClientService;
  notificationService?: ToasterService;
}

export const ProjectSpendTotalAmount: React.FC<
  ProjectSpendTotalAmountProps
> = ({
  projectId,
  projectClientService = ProjectClientService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("projectSpend");
  const [totalSpend, setTotalSpend] =
    React.useState<ProjectClientTotalSpendResponse>();

  useEffect(() => {
    projectClientService.getTotalSpent(projectId).subscribe({
      next: setTotalSpend,
      error: notificationService.showApiErrors
    });
  }, [projectId, projectClientService, notificationService]);

  if (!totalSpend) {
    return null;
  }
  const { totalSpent, isoCurrencyCode, moneyEquivalence, paymentMethodType } =
    totalSpend;

  const isCreditPaymentType = paymentMethodType === PAYMENT_METHOD_TYPE.CREDIT;

  const totalMoneyEquivalence = convertValueToCurrencyFormat(
    isCreditPaymentType ? moneyEquivalence : totalSpent,
    isoCurrencyCode,
    2
  );

  return (
    <div className={styles.container}>
      {isCreditPaymentType && (
        <Badge color="secondary" className={styles.badge}>
          {t("totalCredits")}&nbsp;
          <span className={styles.amount}>{formatCredits(totalSpent)}</span>
        </Badge>
      )}
      <Badge color="secondary" className={styles.badge}>
        {t("totalAmount")}&nbsp;
        <span className={styles.amount}>{totalMoneyEquivalence}</span>
      </Badge>
    </div>
  );
};
