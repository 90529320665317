import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";
import { useDispatch } from "react-redux";

import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import {
  ClipboardManager,
  DefaultClipboardManager
} from "../../../../../classes/ClipboardManager";

import styles from "./AngleInvitationLink.module.scss";

interface AngleInvitationLinkProps {
  link: string;
  clipboardManager?: ClipboardManager;
}

export const AngleInvitationLink: React.FC<AngleInvitationLinkProps> = ({
  link,
  clipboardManager = DefaultClipboardManager
}) => {
  const dispatch = useDispatch();
  // cspell:disable-next-line
  const isLinkDefined = link.includes("dev.arbol.us");

  const handleCopyLink = () => {
    dispatch(PanelStoreActions.closePanel(PanelId.AssignExperts));
    clipboardManager.copy(link);
  };

  return (
    <button
      className={clsx(styles.linkButton, {
        [styles.isLinkDefined]: isLinkDefined
      })}
      onClick={handleCopyLink}
    >
      {link}
      <Icon
        name="link"
        fontSize="24px"
        color={
          isLinkDefined
            ? ARBOLUS_COLORS.bColorBaseDark
            : ARBOLUS_COLORS.bColorGrayIcon
        }
      />
    </button>
  );
};
