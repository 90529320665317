import { UserNotificationSettings } from "../../../../models/user";

export const ACCEPT_LATEST_TERMS = "USER_ACCEPT_LATEST_TERMS";
export const UPDATE_USER_NOTIFICATION_SETTINGS =
  "USER_UPDATE_USER_NOTIFICATION_SETTINGS";

export interface UpdateUserNotificationSettingsAction {
  type: typeof UPDATE_USER_NOTIFICATION_SETTINGS;
  payload: {
    userNotificationSettings: UserNotificationSettings;
  };
}
