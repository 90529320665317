import clsx from "clsx";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import {
  AuthConstraints,
  DIGIT_REGEX_REQUIRED,
  LOWER_UPPERCASE_REGEX,
  SPECIAL_CHARACTERS_REGEX
} from "../../../../constants/validation";

interface PasswordInstructionsProps {
  password: string;
}

type PasswordInstructionsPropsIntersectProps = PasswordInstructionsProps &
  WithTranslation;

const MIN_PASSWORD_LENGTH = 8;

const PasswordInstructions: React.FC<
  PasswordInstructionsPropsIntersectProps
> = ({ password, t }) => (
  <ul className="password-guide">
    <li
      className={clsx({
        active: password.length >= MIN_PASSWORD_LENGTH
      })}
    >
      <span className="ciq-icon ciq-check" />
      {t("characterLengthError", {
        length: AuthConstraints.MIN_PASSWORD_LENGTH
      })}
    </li>
    <li
      className={clsx({
        active: LOWER_UPPERCASE_REGEX.test(password)
      })}
    >
      <span className="ciq-icon ciq-check" />
      {t("lowercaseUppercaseError")}
    </li>
    <li
      className={clsx({
        active: SPECIAL_CHARACTERS_REGEX.test(password)
      })}
    >
      <span className="ciq-icon ciq-check" />
      {t("specialCharactersError")}
    </li>
    <li
      className={clsx({
        active: DIGIT_REGEX_REQUIRED.test(password)
      })}
    >
      <span className="ciq-icon ciq-check" />
      {t("atLeastOneDigitError")}
    </li>
  </ul>
);

export default withTranslation("passwordInstructions")(PasswordInstructions);
