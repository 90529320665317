import { Icon } from "arbolus-ui-components";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useHistory } from "react-router";
import { Button, PopoverBody } from "reactstrap";
import { Subscription } from "rxjs";

import { ToasterService, TranscriptService } from "@arbolus-technologies/api";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { AppConstants } from "../../../../../constants";
import {
  PROJECT_EVENT_TRANSCRIPT,
  PROJECT_FILES_ROUTE
} from "../../../../../constants/navigation/projectRoutes";
import useContentPanelContext from "../../../../../contexts/contentPanel/UseContentPanelContext";
import AccessManager from "../../../../../contexts/roleBasedAccess/AccessManager";
import { CIQError, ErrorResponse } from "../../../../../models/api";
import { Event } from "../../../../../models/event";
import { CIQEventViewer } from "../../panels";

const notification = new ToasterService();

enum TranscriptOptionsPopoverState {
  OPTIONS,
  DELETE
}

interface TranscriptOptionsPopoverProps {
  eventData?: Event;
  onPopupToggle: () => void;
  onReportExpertToggle: () => void;
}

const TranscriptOptionsPopover = ({
  eventData,
  onPopupToggle,
  onReportExpertToggle
}: TranscriptOptionsPopoverProps): JSX.Element => {
  const routeMatch = matchPath<{ projectId: string; transcriptId: string }>(
    window.location.pathname,
    PROJECT_EVENT_TRANSCRIPT
  );

  const [popoverState, setPopoverState] = useState(
    TranscriptOptionsPopoverState.OPTIONS
  );
  const [isLoading, setIsLoading] = useState(false);
  const { replace } = useHistory();
  const { t } = useTranslation("transcript");
  const { setContent, closePanel } = useContentPanelContext();

  const isInitialMode = popoverState === TranscriptOptionsPopoverState.OPTIONS;
  const isDeleteMode = popoverState === TranscriptOptionsPopoverState.DELETE;

  const deleteTranscriptSubscription = useRef<Subscription>();

  const deleteTranscript = (): void => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { projectId, transcriptId } = routeMatch?.params!;

    setIsLoading(true);
    deleteTranscriptSubscription.current = TranscriptService.deleteTranscript(
      projectId,
      transcriptId
    ).subscribe(
      () => {
        setIsLoading(false);
        replace(PROJECT_FILES_ROUTE(projectId));
        notification.showSuccess(t("deleteSuccesses"));
      },
      (error: ErrorResponse<CIQError>) => {
        setIsLoading(false);
        notification.showError(error.message);
      }
    );
  };

  const handleCallDetailsClick = (): void => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { projectId } = routeMatch?.params!;
    setContent(
      // @ts-ignore
      <CIQEventViewer
        key={AppConstants.CONTENT_PANEL.EVENT_VIEWER}
        eventId={eventData?.id!}
        onPanelClose={closePanel}
        eventData={eventData}
        projectId={projectId}
      />,
      true
    );
    onPopupToggle();
  };

  const handleCancelClick = (): void => {
    onPopupToggle();
  };

  useEffect(
    () => (): void => {
      deleteTranscriptSubscription.current?.unsubscribe();
    },
    []
  );

  return (
    <PopoverBody className="transcript-action-popover">
      {isDeleteMode && (
        <>
          <div className="delete-message">
            <p>{t("deleteMessage")}</p>
          </div>
          <div className="actions-btn-container">
            <Button
              size="sm"
              color="danger"
              onClick={deleteTranscript}
              disabled={isLoading}
            >
              {t("delete")}
            </Button>
            <Button size="sm" color="secondary" onClick={handleCancelClick}>
              {t("cancel")}
            </Button>
          </div>
        </>
      )}
      {isInitialMode && (
        <ul>
          <li onClick={(): void => handleCallDetailsClick()}>
            <span>{t("viewDetails")}</span>

            <i className="ciq-icon ciq-component-icon-ic-description" />
          </li>
          <li onClick={onReportExpertToggle}>
            <span style={{ width: "100%" }}>{t("reportExpert")}</span>
            <div>
              <Icon
                name="warning"
                fontSize="18px"
                color={ARBOLUS_COLORS.bColorSecondaryGreyMid}
              />
            </div>
          </li>

          <AccessManager permission="transcript:transcriptDelete">
            <hr />
            <li
              onClick={(): void =>
                setPopoverState(TranscriptOptionsPopoverState.DELETE)
              }
            >
              <span>{t("delete")}</span>
              <i className="ciq-icon ciq-trash" />
            </li>
          </AccessManager>
        </ul>
      )}
    </PopoverBody>
  );
};

export default TranscriptOptionsPopover;
