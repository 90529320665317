import { useEffect, useState } from "react";

import {
  CustomersService,
  DefaultToasterService,
  GetSurveyAnswersResponse
} from "@arbolus-technologies/api";

export function useGetSurveyAnswers(surveyId: string, expertId: string) {
  const [surveyAnswers, setSurveyAnswers] =
    useState<GetSurveyAnswersResponse>();

  useEffect(() => {
    CustomersService.getSurveyAnswers(surveyId, expertId).subscribe(
      (response) => {
        setSurveyAnswers(response);
      },
      (error) => DefaultToasterService.showApiErrors(error)
    );
  }, [expertId, surveyId]);

  return surveyAnswers;
}
