import clsx from "clsx";
import React from "react";

import { Avatar } from "../Avatar/Avatar";
import { PublicCompanyIcon } from "../SquareWithIcon/Wrappers/PublicCompanyIcon";

import styles from "./AvatarWithTitle.module.scss";

interface AvatarWithTitleProps {
  username: string;
  title: string;
  subtitle?: string;
  imageUrl?: string;
  noMargin?: boolean;
  lastPublicCompanyDate?: string | null;
  countryTwoLetterCode?: string | null;
  countryName?: string | null;
}

export const AvatarWithTitle: React.FC<AvatarWithTitleProps> = ({
  username,
  title,
  subtitle,
  imageUrl,
  noMargin,
  lastPublicCompanyDate,
  countryTwoLetterCode,
  countryName
}) => (
  <>
    <div
      className={clsx(
        styles.imageContainer,
        noMargin ? styles.noMargin : undefined
      )}
    >
      <Avatar
        avatar={{
          name: username,
          imageUrl: imageUrl
        }}
      />
    </div>
    <div className={styles.content}>
      <div className={styles.titleWithFlags}>
        <h3 className={styles.title} title={title}>
          {title}
        </h3>
        {lastPublicCompanyDate && (
          <PublicCompanyIcon
            date={lastPublicCompanyDate}
            customFontSize="24px"
          />
        )}
        {countryTwoLetterCode && countryName && (
          <span
            className={`flag-icon flag-icon-${countryTwoLetterCode.toLowerCase()}`}
            id="countryFlag"
            title={countryName}
          />
        )}
      </div>
      <h6 className={styles.subtitle} title={subtitle}>
        {subtitle}
      </h6>
    </div>
  </>
);
