import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";

import { COMPLIANCE_STEPS } from "@arbolus-technologies/models/canopy-panels";

import styles from "./ComplianceBoxButtons.module.scss";

export interface ComplianceBoxButtonsProps {
  status:
    | COMPLIANCE_STEPS.REVIEW
    | COMPLIANCE_STEPS.CONFIRM_APPROVE
    | COMPLIANCE_STEPS.CONFIRM_REJECT;
  disabled: boolean;
  handleNext: (status: COMPLIANCE_STEPS) => void;
}

export const ComplianceBoxButtons: React.FC<ComplianceBoxButtonsProps> = ({
  status,
  disabled,
  handleNext
}) => {
  const { t } = useTranslation("canopyPanels");

  const isReview = status === COMPLIANCE_STEPS.REVIEW;

  const buttonDetails = {
    [COMPLIANCE_STEPS.REVIEW]: {
      icon: "close",
      text: t("reject"),
      action: COMPLIANCE_STEPS.CONFIRM_REJECT
    },
    [COMPLIANCE_STEPS.CONFIRM_APPROVE]: {
      icon: "check",
      text: t("approve"),
      action: COMPLIANCE_STEPS.APPROVED
    },
    [COMPLIANCE_STEPS.CONFIRM_REJECT]: {
      icon: "close",
      text: t("reject"),
      action: COMPLIANCE_STEPS.REJECTED
    }
  };

  return (
    <div className={styles.container}>
      {isReview ? (
        <Button
          onClick={() => handleNext(COMPLIANCE_STEPS.CONFIRM_APPROVE)}
          text={t("approve")}
          endIcon={"check"}
          type={"confirmation"}
          disabled={disabled}
        />
      ) : (
        <Button
          onClick={() => handleNext(COMPLIANCE_STEPS.REVIEW)}
          text={t("cancel")}
          type={"secondary"}
          disabled={disabled}
        />
      )}
      <Button
        onClick={() => handleNext(buttonDetails[status].action)}
        text={buttonDetails[status].text}
        endIcon={buttonDetails[status].icon}
        type={
          buttonDetails[status].icon === "close" ? "rejection" : "confirmation"
        }
        disabled={disabled}
      />
    </div>
  );
};
