import i18next from "i18next";
import * as Yup from "yup";

import { UserConstraints } from "@arbolus-technologies/ui/components";

import { EndorsementForm } from "./types";

export const EndorsementFormSchema: Yup.ObjectSchema<EndorsementForm> =
  Yup.object().shape({
    endorsementName: Yup.string()
      .required(i18next.t("expertEndorsementPage:nameRequiredError"))
      .max(
        UserConstraints.MAX_NAME_LENGTH,
        i18next.t("expertEndorsementPage:nameMaxLengthError", {
          length: UserConstraints.MAX_FIRST_NAME_LENGTH
        })
      ),
    endorsementEmail: Yup.string()
      .required(i18next.t("forgotPasswordPage:emailRequired"))
      .email(i18next.t("forgotPasswordPage:invalidEmail"))
      .max(
        UserConstraints.MAX_EMAIL_LENGTH,
        i18next.t("forgotPasswordPage:maxEmailLength", {
          length: UserConstraints.MAX_EMAIL_LENGTH
        })
      )
  });
