import React, { useCallback } from "react";

import { DocumentService, PaginatedRequest } from "@arbolus-technologies/api";

import { ProjectDocuments } from "./ProjectDocuments";

interface ClientProjectDocumentsProps {
  projectId: string;
  documentService?: typeof DocumentService;
}

export const ClientProjectDocuments: React.FC<ClientProjectDocumentsProps> = ({
  projectId,
  documentService = DocumentService
}) => {
  const getDocuments = useCallback(
    (queryParams: PaginatedRequest<"created">) =>
      documentService.getClientDocuments(projectId, queryParams, true),
    [documentService, projectId]
  );

  return <ProjectDocuments projectId={projectId} getDocuments={getDocuments} />;
};
