import clsx from "clsx";
import React from "react";

import { CanopyTemplateItem } from "@arbolus-technologies/api";

import { MixPanelEventNames } from "../../../../../Mixpanel/enums";
import { useArbolusTracking } from "../../../../../Mixpanel/useArbolusTracking";

import styles from "./TemplateBox.module.scss";

interface TemplateBoxProps {
  active: boolean;
  template: CanopyTemplateItem;
  handleOnClick: (value: string) => void;
}

export const TemplateBox: React.FC<TemplateBoxProps> = ({
  template,
  active,
  handleOnClick
}) => {
  const { trackClick } = useArbolusTracking();

  const handleSelectTemplate = (template: CanopyTemplateItem) => {
    if (template.templateName === "Customer") {
      trackClick(MixPanelEventNames.CanopyCreationPanelCustomerTemplate);
    } else if (template.templateName === "Competitor") {
      trackClick(MixPanelEventNames.CanopyCreationPanelCompetitorTemplate);
    }

    handleOnClick(template.canopyTemplateId);
  };

  return (
    <div
      className={clsx(styles.container, active && styles.activeContainer)}
      onClick={() => handleSelectTemplate(template)}
    >
      <div className={styles.valueContainer}>
        <div className={clsx(styles.likeInput, active && styles.active)} />
        <div className={styles.value}>{template.templateName}</div>
      </div>
      <div className={styles.info}>{template.templateDescription}</div>
    </div>
  );
};
