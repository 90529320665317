import { Observable } from "rxjs";

import {
  SURVEY_EXPERT_STATUS,
  SURVEY_ORDER_BY,
  SURVEY_STATUS
} from "@arbolus-technologies/models/survey";

import { SURVEY_API } from "../constants/api";
import { SORT_DIRECTION } from "../enums/apiEnums";
import { MultiSortRequest } from "../helpers";
import { ApiPaginatedResponse, CreatedResponse } from "../models/api";
import {
  BaseSurvey,
  LandingSurvey,
  Survey,
  SurveyExpert,
  SurveyExpertListRequest,
  UpdateSurvey
} from "../models/survey";
import { restService } from "../restService";

export const SurveyService = {
  getAllSurveys: ({
    projectId,
    surveyStatus,
    searchTerm,
    limit = 100,
    offset = 0
  }: {
    projectId: string;
    surveyStatus?: SURVEY_STATUS;
    searchTerm?: string;
    limit?: number;
    offset?: number;
  }): Observable<ApiPaginatedResponse<BaseSurvey>> =>
    restService.get(SURVEY_API.GET_PROJECT_SURVEYS(projectId), {
      surveyStatus,
      searchTerm,
      limit,
      offset,
      orderBy: SURVEY_ORDER_BY.CREATED,
      orderDirection: SORT_DIRECTION.DESCENDING
    }),
  getSurvey: (surveyId: string): Observable<Survey> =>
    restService.get(SURVEY_API.GET_SURVEY(surveyId)),
  createSurvey: (surveyDetails: UpdateSurvey): Observable<CreatedResponse> =>
    restService.post(SURVEY_API.CREATE_SURVEY(), surveyDetails),
  updateSurvey: (surveyDetails: UpdateSurvey): Observable<CreatedResponse> =>
    restService.put(SURVEY_API.UPDATE_SURVEY(surveyDetails.id!), surveyDetails),
  closeSurvey: (surveyId: string): Observable<CreatedResponse> =>
    restService.patch(SURVEY_API.CLOSE_SURVEY(surveyId), {
      status: SURVEY_STATUS.CLOSED
    }),
  getSurveyExperts: (
    surveyId: string,
    queryParams: MultiSortRequest<SurveyExpertListRequest>
  ): Observable<ApiPaginatedResponse<SurveyExpert>> =>
    restService.get<ApiPaginatedResponse<SurveyExpert>>(
      SURVEY_API.GET_SURVEY_EXPERTS(surveyId),
      queryParams
    ),
  getSurveyPublic: (surveyId: string): Observable<LandingSurvey> =>
    restService.get(SURVEY_API.GET_SURVEY_PUBLIC(surveyId)),
  getSurveyExpertStatus: (
    surveyId: string
  ): Observable<{ surveyExpertStatus: SURVEY_EXPERT_STATUS }> =>
    restService.get(SURVEY_API.GET_SURVEY_EXPERT_STATUS(surveyId)),
  startExpertSurvey: (surveyId: string): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(
      SURVEY_API.START_EXPERT_SURVEY(surveyId),
      {}
    )
};
