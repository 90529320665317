import React from "react";
import { useTranslation } from "react-i18next";

import { STATUSES } from "@arbolus-technologies/api";

import styles from "./FilterTypeComponents.module.scss";

interface FilterByStatusProps {
  status?: string;
  isCard?: boolean;
}

export const FilterByStatus: React.FC<FilterByStatusProps> = ({
  status,
  isCard
}) => {
  const { t } = useTranslation("scheduleTabFilters");

  if (!status) {
    return null;
  }

  const isApproved = status === STATUSES.ACCEPT;
  return (
    <div className={isCard ? styles.card : styles.badge}>
      <div
        className={isApproved ? styles.circleCompleted : styles.circlePending}
      />
      <h3>{t(status)}</h3>
    </div>
  );
};
