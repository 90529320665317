import { Handlers, createReducer } from "reduxsauce";

import { CANOPY_STATUS } from "@arbolus-technologies/models/canopy";

import {
  ARCHIVE_CANOPY,
  CHANGE_CANOPY_STATUS,
  ChangeCanopyStatusAction,
  GET_CANOPY_CLIENT_V2,
  GET_CANOPY_CLIENT_V2_FAILURE,
  GET_CANOPY_CLIENT_V2_SUCCESS,
  GET_CANOPY_EXPERTS_COUNT,
  GET_CANOPY_EXPERTS_COUNT_FAILURE,
  GET_CANOPY_EXPERTS_COUNT_SUCCESS,
  GET_CANOPY_QUESTION,
  GET_CANOPY_QUESTIONS,
  GET_CANOPY_QUESTIONS_FAILURE,
  GET_CANOPY_QUESTIONS_SUCCESS,
  GET_CANOPY_QUESTION_FAILURE,
  GET_CANOPY_QUESTION_SUCCESS,
  GetCanopyClientSuccessV2Action,
  GetCanopyExpertsCountSuccessAction,
  GetCanopyQuestionAction,
  GetCanopyQuestionFailureAction,
  GetCanopyQuestionSuccessAction,
  GetCanopyQuestionsSuccessAction,
  RESET_CANOPY_DATA,
  RESET_CANOPY_QUESTION,
  RESET_CANOPY_V2
} from "./actions/CanopyV2ActionTypes";
import { CanopyV2ReducerState } from "./models/definitions";

export const initialState: CanopyV2ReducerState = {
  canopyData: null,
  isCanopyDataLoading: false,
  canopyQuestions: [],
  isCanopyQuestionsLoading: false,
  questionData: null,
  isQuestionLoading: false,
  expertsCount: { incomplete: 0, inReview: 0, complete: 0, rejected: 0 }
};

// Get canopy Client
const handleGetCanopyV2Client = (
  state = initialState
): CanopyV2ReducerState => ({
  ...state,
  isCanopyDataLoading: true
});

const handleGetCanopyClientV2Success = (
  state = initialState,
  { payload }: GetCanopyClientSuccessV2Action
): CanopyV2ReducerState => ({
  ...state,
  canopyData: payload.canopyData,
  isCanopyDataLoading: false
});

const handleGetCanopyClientV2Failure = (
  state = initialState
): CanopyV2ReducerState => ({
  ...state,
  isCanopyDataLoading: false
});

// Get Canopy Expert count
const handleGetCanopyExpertsCount = (
  state = initialState
): CanopyV2ReducerState => ({
  ...state
});

const handleGetCanopyExpertsCountSuccess = (
  state = initialState,
  { payload }: GetCanopyExpertsCountSuccessAction
): CanopyV2ReducerState => ({
  ...state,
  expertsCount: payload.expertsCount
});

const handleGetCanopyExpertsCountFailure = (
  state = initialState
): CanopyV2ReducerState => ({
  ...state
});

// Get Canopy Questions
const handleGetCanopyQuestions = (
  state = initialState
): CanopyV2ReducerState => ({
  ...state,
  isCanopyQuestionsLoading: true
});

const handleGetCanopyQuestionsSuccess = (
  state = initialState,
  { payload }: GetCanopyQuestionsSuccessAction
): CanopyV2ReducerState => ({
  ...state,
  canopyQuestions: payload.questions,
  isCanopyQuestionsLoading: false
});

const handleGetCanopyQuestionsFailure = (
  state = initialState
): CanopyV2ReducerState => ({
  ...state,
  isCanopyQuestionsLoading: false
});

// GET CANOPY QUESTION

const getCanopyQuestion = (
  state = initialState,
  { payload }: GetCanopyQuestionAction
): CanopyV2ReducerState => ({
  ...state,
  isQuestionLoading: true,
  questionData: null
});

const getCanopyQuestionSuccess = (
  state = initialState,
  { payload }: GetCanopyQuestionSuccessAction
): CanopyV2ReducerState => ({
  ...state,
  questionData: payload.questionData,
  isQuestionLoading: false
});

const getCanopyQuestionFailure = (
  state = initialState,
  { payload }: GetCanopyQuestionFailureAction
): CanopyV2ReducerState => ({
  ...state,
  isQuestionLoading: false
});

const resetCanopyV2 = (state = initialState): CanopyV2ReducerState => ({
  ...initialState
});

const handleResetCanopyData = (state = initialState): CanopyV2ReducerState => ({
  ...state,
  canopyData: initialState.canopyData
});

export const handleArchiveCanopy = (
  state = initialState
): CanopyV2ReducerState => ({
  ...state,
  canopyData: {
    ...state.canopyData!,
    status: CANOPY_STATUS.ARCHIVED
  }
});

export const handleResetCanopyQuestion = (
  state = initialState
): CanopyV2ReducerState => ({
  ...state,
  questionData: null
});

export const changeCanopyStatus = (
  state = initialState,
  { payload }: ChangeCanopyStatusAction
): CanopyV2ReducerState => ({
  ...state,
  canopyData: {
    ...state.canopyData!,
    status: payload.status
  }
});
// Current `Handlers` definition not good enough for proper typing
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handlers: Handlers<CanopyV2ReducerState, any> = {
  [GET_CANOPY_CLIENT_V2]: handleGetCanopyV2Client,
  [GET_CANOPY_CLIENT_V2_SUCCESS]: handleGetCanopyClientV2Success,
  [GET_CANOPY_CLIENT_V2_FAILURE]: handleGetCanopyClientV2Failure,
  [GET_CANOPY_EXPERTS_COUNT]: handleGetCanopyExpertsCount,
  [GET_CANOPY_EXPERTS_COUNT_SUCCESS]: handleGetCanopyExpertsCountSuccess,
  [GET_CANOPY_EXPERTS_COUNT_FAILURE]: handleGetCanopyExpertsCountFailure,
  [GET_CANOPY_QUESTIONS]: handleGetCanopyQuestions,
  [GET_CANOPY_QUESTIONS_SUCCESS]: handleGetCanopyQuestionsSuccess,
  [GET_CANOPY_QUESTIONS_FAILURE]: handleGetCanopyQuestionsFailure,
  [GET_CANOPY_QUESTION]: getCanopyQuestion,
  [GET_CANOPY_QUESTION_SUCCESS]: getCanopyQuestionSuccess,
  [GET_CANOPY_QUESTION_FAILURE]: getCanopyQuestionFailure,
  [RESET_CANOPY_V2]: resetCanopyV2,
  [RESET_CANOPY_DATA]: handleResetCanopyData,
  [ARCHIVE_CANOPY]: handleArchiveCanopy,
  [RESET_CANOPY_QUESTION]: handleResetCanopyQuestion,
  [CHANGE_CANOPY_STATUS]: changeCanopyStatus
};

export const CanopyV2Reducer = createReducer(initialState, handlers);
