import React from "react";
import { useTranslation } from "react-i18next";

import { ExpertCompany, ExpertIndustry } from "@arbolus-technologies/api";

import { CustomerCompanyInfo } from "@arbolus-technologies/models/project";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { Tag } from "@arbolus-technologies/ui/components";
import styles from "./ExpertWorkDetails.module.scss";

export interface ExpertWorkDetailsProps {
  industries: ExpertIndustry[];
  companies: ExpertCompany[];
  customers: CustomerCompanyInfo[];
  isAdmin: boolean;
}

export const ExpertWorkDetails: React.FC<ExpertWorkDetailsProps> = ({
  industries,
  companies,
  customers,
  isAdmin
}) => {
  const { t } = useTranslation("expertWorkDetails");

  if (
    companies.length === 0 &&
    industries.length === 0 &&
    (!isAdmin || customers.length === 0)
  ) {
    return null;
  }

  return (
    <div className={styles.expertWorkDetailsContainer}>
      {isAdmin && customers.length > 0 && (
        <>
          <div className={styles.container}>
            <h3>{t("customers")}</h3>
            <div className={styles.customers}>
              {customers.map((customer) => (
                <Tag
                  key={customer.companyId}
                  text={customer.companyName}
                  color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
                />
              ))}
            </div>
          </div>
          <hr />
        </>
      )}
      {industries.length > 0 && (
        <>
          <div className={styles.container}>
            <h3>{t("industries")}</h3>
            <ul>
              {industries.map((industry) => (
                <li key={industry.name}>{industry.name}</li>
              ))}
            </ul>
          </div>
          <hr />
        </>
      )}
      {companies.length > 0 && (
        <div className={styles.container}>
          <h3>{t("companies")}</h3>
          <ul>
            {companies.map((country) => (
              <li key={country.name}>{country.name}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
