import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import {
  AllCanopy,
  CANOPY_ORDER_BY,
  CanopyService,
  DefaultToasterService,
  SORT_DIRECTION,
  ToasterService
} from "@arbolus-technologies/api";
import {
  CanopyCard,
  SimpleCanopyCreationProvider,
  SlidePanel
} from "@arbolus-technologies/features/common";
import {
  CANOPY_STATUS,
  CanopyPageType
} from "@arbolus-technologies/models/canopy";
import { PanelId } from "@arbolus-technologies/stores/panels";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { InfiniteScrollWithHeader } from "@arbolus-technologies/ui/components";
import { SIDE_PANEL_SIZE, useDocumentTitle } from "@arbolus-technologies/utils";

import {
  CanopyListFilterParams,
  CanopyListFilters
} from "./CanopyListFilters/CanopyListFilters";

import { PROJECT_ROUTE } from "@arbolus-technologies/routes";
import { AntDHeader } from "@arbolus-technologies/ui/layout";
import styles from "./CanopyListPage.module.scss";
import { HeaderButtons } from "./HeaderButtons/HeaderButtons";

interface CanopyListPageProps {
  canopyService?: typeof CanopyService;
  notificationService?: ToasterService;
}

const renderer = (canopy: AllCanopy) => {
  const projectInfo = { id: canopy.projectId, name: canopy.projectName };
  return (
    <CanopyCard
      key={canopy.id}
      {...canopy}
      projectInfo={projectInfo}
      canopyPageType={CanopyPageType.CanopyList}
    />
  );
};

export const CanopyListPage: React.FC<CanopyListPageProps> = ({
  canopyService = CanopyService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("canopyList");
  const { projectId } = useParams<{ projectId?: string }>();
  useDocumentTitle("canopyList", "title");

  const projectName = useSelector(ProjectNxSelector.projectName());

  const [canopies, setCanopies] = useState<AllCanopy[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [filterParams, setFilterParams] = useState<CanopyListFilterParams>({
    searchTerm: "",
    orderBy: CANOPY_ORDER_BY.LAST_ANSWER_DATE,
    orderDirection: SORT_DIRECTION.DESCENDING
  });

  const getCanopies = useCallback(
    (
      limit: number,
      searchTerm: string,
      orderBy: CANOPY_ORDER_BY,
      orderDirection: SORT_DIRECTION,
      status?: CANOPY_STATUS,
      restart = false
    ) => {
      if (!hasNextPage && !restart) {
        return;
      }

      if (restart) {
        setCanopies(null);
      }

      setIsLoading(true);
      canopyService
        .getAllCanopies(
          {
            limit,
            offset: (!restart && canopies?.length) || 0,
            orderBy: orderBy,
            orderDirection: orderDirection
          },
          searchTerm,
          status,
          projectId
        )
        .subscribe({
          next: (response) => {
            setCanopies((prev) =>
              prev && !restart
                ? [...prev, ...response.items]
                : [...response.items]
            );
            setHasNextPage(
              response.pagination.count >
                ((!restart && canopies?.length) || 0) + response.items.length
            );
          },
          error: notificationService.showApiErrors,
          complete: () => setIsLoading(false)
        });
    },
    [
      hasNextPage,
      canopyService,
      canopies?.length,
      projectId,
      notificationService
    ]
  );

  const onFilterChange = useCallback(
    (filterParams: CanopyListFilterParams) => {
      setFilterParams(filterParams);
      getCanopies(
        20,
        filterParams.searchTerm,
        filterParams.orderBy,
        filterParams.orderDirection,
        filterParams.status,
        true
      );
    },
    [getCanopies]
  );

  return (
    <div className={styles.container}>
      <AntDHeader
        stickyHeader
        title={t("title")}
        backLink={
          projectId ? (PROJECT_ROUTE(projectId).pathname as string) : undefined
        }
        rightContainer={<HeaderButtons projectId={projectId} />}
      />
      <InfiniteScrollWithHeader
        smallMarginBottom
        classnames={styles.infiniteScroll}
        rendererContainerClassnames={styles.cardsContainer}
        items={canopies}
        itemHeight={59}
        isLoading={isLoading}
        nextItems={(limit) =>
          getCanopies(
            limit,
            filterParams.searchTerm,
            filterParams.orderBy,
            filterParams.orderDirection,
            filterParams.status
          )
        }
        renderer={renderer}
      >
        <div className={styles.filterContainer}>
          <div className={styles.filter}>
            <CanopyListFilters onFilterChange={onFilterChange} />
          </div>
        </div>
      </InfiniteScrollWithHeader>
      <SlidePanel
        title={<div>{t("newCanopy")}</div>}
        panelId={PanelId.CreateSimpleCanopy}
        closeButtonDirection="right"
        width={SIDE_PANEL_SIZE._720}
      >
        <SimpleCanopyCreationProvider />
      </SlidePanel>
    </div>
  );
};
