import clsx from "clsx";
import React from "react";

import styles from "./StarButton.module.scss";

interface StarButtonProps {
  isStarred: boolean;
  onClick: () => void;
  disabled?: boolean;
}

export const StarButton: React.FC<StarButtonProps> = ({
  isStarred,
  onClick,
  disabled = false
}) => (
  <button
    className={clsx(
      styles.button,
      isStarred && styles.starred,
      disabled && styles.disabled
    )}
    onClick={onClick}
    disabled={disabled}
  >
    <span
      className={clsx("material-symbols-sharp", styles.icon, styles.startIcon)}
    >
      star
    </span>
  </button>
);
