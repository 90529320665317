import { EditorState, SelectionState } from "draft-js";

import { Event } from "../../models/event";
import { Monologue, TranscriptHighLight } from "../../models/transcript";
import { Speaker, TranscriptMetaState } from "../../models/view/transcript";
import { DraftJSUtilService } from "../../services";
import { TranscriptMode } from "./TranscriptContext";

export const SET_MODE = "SET_MODE";
export const SET_EDITOR_STATE = "SET_EDITOR_STATE";
export const RESET_EDITOR_SELECTION = "RESET_EDITOR_SELECTION";
export const TOGGLE_MONOLOGUE_TEXT = "TOGGLE_MONOLOGUE_TEXT";

export const UPDATE_HIGHLIGHTS = "UPDATE_HIGHLIGHTS";

export const UPDATE_SPEAKER_ASSIGNMENT = "UPDATE_SPEAKER_ASSIGNMENT";
export const UPDATE_SPEAKER_ASSIGNMENT_SUCCESS =
  "UPDATE_SPEAKER_ASSIGNMENT_SUCCESS";
export const UPDATE_SPEAKER_ASSIGNMENT_FAILURE =
  "UPDATE_SPEAKER_ASSIGNMENT_FAILURE";

interface UpdateSpeakerAssignmentType {
  type: typeof UPDATE_SPEAKER_ASSIGNMENT;
  payload: {
    speakerId: string;
  };
}

interface UpdateSpeakerAssignmentSuccessType {
  type: typeof UPDATE_SPEAKER_ASSIGNMENT_SUCCESS;
  payload: {
    speakerId: string;
    speaker: Speaker;
    isNewSpeaker: boolean;
  };
}

interface UpdateSpeakerAssignmentFailureType {
  type: typeof UPDATE_SPEAKER_ASSIGNMENT_FAILURE;
  payload: {
    speakerId: string;
  };
}

interface SetTranscriptModeType {
  type: typeof SET_MODE;
  payload: {
    mode: TranscriptMode;
    currentUserId: string;
  };
}

interface SetEditorStateType {
  type: typeof SET_EDITOR_STATE;
  payload: {
    editorState: EditorState;
  };
}

interface ResetEditorSelectionType {
  type: typeof RESET_EDITOR_SELECTION;
}

interface UpdateHighlightsType {
  type: typeof UPDATE_HIGHLIGHTS;
  payload: {
    updatedEditorState: EditorState;
    monologueId: string;
    removedHighlights: string[];
    currentUserId: string;
    newHighlight?: TranscriptHighLight;
  };
}

interface ToggleMonologueTextType {
  type: typeof TOGGLE_MONOLOGUE_TEXT;
  payload: {
    monologueId: string;
    currentUserId: string;
    isFullMode: boolean;
  };
}

export interface TranscriptContextReducerState {
  transcriptMeta: TranscriptMetaState;
  monologues: Map<string, Monologue>;
  event: Event;
  speakersMap: Map<string, Speaker>;
  speakersList: Speaker[];
  editorState: EditorState;
  mode: TranscriptMode;
  loadingSpeakers: string[];
  highlightsCount: number;
}

type TranscriptContextReducerActionType =
  | UpdateSpeakerAssignmentType
  | UpdateSpeakerAssignmentSuccessType
  | UpdateSpeakerAssignmentFailureType
  | SetTranscriptModeType
  | SetEditorStateType
  | ResetEditorSelectionType
  | UpdateHighlightsType
  | ToggleMonologueTextType;

export function transcriptContextReducer(
  state: TranscriptContextReducerState,
  action: TranscriptContextReducerActionType
): TranscriptContextReducerState {
  switch (action.type) {
    case UPDATE_SPEAKER_ASSIGNMENT: {
      return {
        ...state,
        loadingSpeakers: state.loadingSpeakers.concat(action.payload.speakerId),
        editorState: EditorState.forceSelection(
          state.editorState,
          state.editorState.getSelection()
        )
      };
    }

    case UPDATE_SPEAKER_ASSIGNMENT_SUCCESS: {
      const { speaker, speakerId, isNewSpeaker } = action.payload;
      const { name, userId, profileImageUrl } = speaker;
      const nextSpeakerList = [...state.speakersList];
      const nextSpeakersMap = new Map(state.speakersMap);

      if (isNewSpeaker) {
        nextSpeakerList.push({
          name,
          profileImageUrl,
          edited: true
        });
      }

      nextSpeakersMap.set(speakerId, {
        name,
        profileImageUrl,
        userId,
        edited: true
      });

      return {
        ...state,
        speakersList: isNewSpeaker
          ? nextSpeakerList.sort((a, b) => a.name.localeCompare(b.name))
          : state.speakersList,
        speakersMap: nextSpeakersMap,
        loadingSpeakers: state.loadingSpeakers.filter(
          (s) => s !== action.payload.speakerId
        ),
        editorState: EditorState.forceSelection(
          state.editorState,
          state.editorState.getSelection()
        )
      };
    }

    case UPDATE_SPEAKER_ASSIGNMENT_FAILURE: {
      return {
        ...state,
        loadingSpeakers: state.loadingSpeakers.filter(
          (s) => s !== action.payload.speakerId
        ),
        editorState: EditorState.forceSelection(
          state.editorState,
          state.editorState.getSelection()
        )
      };
    }

    case SET_MODE: {
      const { monologues } = state;
      const { mode, currentUserId } = action.payload;

      switch (mode) {
        case TranscriptMode.HIGHLIGHTS:
          return {
            ...state,
            editorState: DraftJSUtilService.applyHighlightsMode(
              monologues,
              currentUserId
            ),
            mode
          };

        default:
          return {
            ...state,
            editorState: DraftJSUtilService.applyFullTranscriptMode(
              Array.from(monologues.values()),
              currentUserId
            ),
            mode
          };
      }
    }

    case UPDATE_HIGHLIGHTS: {
      const { monologues, highlightsCount, mode } = state;

      const {
        monologueId,
        removedHighlights,
        newHighlight,
        currentUserId,
        updatedEditorState
      } = action.payload;

      const nextMonologueMap = new Map(monologues);
      const changedMonologue = nextMonologueMap.get(monologueId)!;
      const monologueHighlights = changedMonologue.highlights;

      let modifiedHighlights = [...monologueHighlights];
      let nextHighlightsCount = highlightsCount;

      // Removed deleted highlights
      if (removedHighlights.length > 0) {
        modifiedHighlights = modifiedHighlights.filter(
          (h) => !removedHighlights.includes(h.id)
        );
        nextHighlightsCount -= removedHighlights.length;
      }

      // Add new highlight
      if (newHighlight) {
        modifiedHighlights.push(newHighlight);
        nextHighlightsCount += 1;
      }

      nextMonologueMap.set(monologueId, {
        ...changedMonologue,
        highlights: modifiedHighlights
      });

      // Removal of last highlight will change mode back to Full Transcript
      const shouldModeChange =
        mode === TranscriptMode.HIGHLIGHTS && nextHighlightsCount === 0;
      const nextEditorState = shouldModeChange
        ? DraftJSUtilService.applyFullTranscriptMode(
            Array.from(nextMonologueMap.values()),
            currentUserId
          )
        : updatedEditorState;

      return {
        ...state,
        mode: shouldModeChange ? TranscriptMode.FULL_TRANSCRIPT : mode,
        editorState: nextEditorState,
        highlightsCount: nextHighlightsCount,
        monologues: nextMonologueMap
      };
    }

    case SET_EDITOR_STATE: {
      return {
        ...state,
        editorState: action.payload.editorState
      };
    }

    case RESET_EDITOR_SELECTION: {
      return {
        ...state,
        editorState: EditorState.forceSelection(
          state.editorState,
          SelectionState.createEmpty(
            state.editorState.getSelection().getAnchorKey()
          )
        )
      };
    }

    case TOGGLE_MONOLOGUE_TEXT: {
      const { editorState, monologues } = state;
      const { currentUserId, monologueId, isFullMode } = action.payload;

      const toggledEditorState = DraftJSUtilService.toggleMonologueHighlights(
        editorState,
        monologues,
        monologueId,
        currentUserId,
        isFullMode
      );

      const selectionClearedState = EditorState.forceSelection(
        toggledEditorState,
        SelectionState.createEmpty(
          toggledEditorState.getSelection().getAnchorKey()
        )
      );

      return {
        ...state,
        editorState: selectionClearedState
      };
    }

    default: {
      return state;
    }
  }
}
