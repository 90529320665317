import { Handlers, createReducer } from "reduxsauce";

import {
  EDIT_TAGLINE,
  EDIT_TAGLINE_FAILURE,
  EDIT_TAGLINE_SUCCESS,
  FILTER_TABLE_BY_ANGLE,
  FORCE_CLOSE_PANEL,
  FilterTableByAngleAction,
  GET_ALL_EXPERTS,
  GET_ALL_EXPERTS_FAILURE,
  GET_ALL_EXPERTS_SUCCESS,
  GET_ANGLE_EXPERT,
  GET_ANGLE_EXPERT_FAILURE,
  GET_ANGLE_EXPERT_SUCCESS,
  GET_PROJECT_RECOMMENDATIONS,
  GET_PROJECT_RECOMMENDATIONS_FAILURE,
  GET_PROJECT_RECOMMENDATIONS_SUCCESS,
  GET_PROJECT_SUMMARY,
  GET_PROJECT_SUMMARY_FAILURE,
  GET_PROJECT_SUMMARY_SUCCESS,
  GetAllExpertsSuccessAction,
  GetAngleExpertSuccessAction,
  GetProjectSummarySuccessAction,
  GetRecommendationsSuccessAction,
  RESET_TABLE_ACTION,
  SET_EXPERT_LIST_TAB,
  SET_SCHEDULE_TAB_FILTERS,
  SetExpertListTabAction,
  SetScheduleTabFiltersAction,
  UPDATE_ALL_EXPERTS_FAST_TRACK_STATUS,
  UPDATE_REFERRAL_STATUS,
  UPDATE_REFERRAL_STATUS_FAILURE,
  UPDATE_REFERRAL_STATUS_SUCCESS,
  UpdateAllExpertsFastTrackStatusAction
} from "./actions/ProjectExpertsActionTypes";
import { ProjectExpertsReducerState } from "./definitions";

export const initialState: ProjectExpertsReducerState = {
  originalAllExpertsList: null,
  allExpertsList: null,
  recommendationsList: [],
  isRecommendationsListLoading: false,
  angleExpert: null,
  isLoadingListExperts: false,
  isUpdateReferralLoading: false,
  tabActive: null,
  forceClosePanel: false,
  currentAnglesOnTable: null,
  summaryProjectList: null,
  isTaglineSaving: false,
  scheduleTabFilters: null
};

// GET EXPERTS LIST
const getExpertsList = (state = initialState): ProjectExpertsReducerState => ({
  ...state,
  isLoadingListExperts: true
});

const getExpertsListSuccess = (
  state = initialState,
  { payload }: GetAllExpertsSuccessAction
): ProjectExpertsReducerState => {
  const filteredAngleArr = payload.expertsList
    .map((exp) => exp.angle)
    .filter(Boolean)
    .filter((v, i, a) => a.findIndex((v2) => v2.title === v.title) === i);

  payload.expertsList.some((e) => e.angle === null) &&
    filteredAngleArr.unshift({
      id: "",
      title: "No Angle Assigned",
      color: "#ebebeb"
    });

  return {
    ...state,
    isLoadingListExperts: false,
    currentAnglesOnTable: filteredAngleArr,
    originalAllExpertsList: payload.expertsList.map((obj) => ({
      ...obj
    })),
    allExpertsList: payload.expertsList.map((obj) => ({
      ...obj
    }))
  };
};

const getExpertsListFailure = (
  state = initialState
): ProjectExpertsReducerState => ({
  ...state,
  isLoadingListExperts: false
});

// GET RECOMMENDATIONS
const getRecommendations = (
  state = initialState
): ProjectExpertsReducerState => ({
  ...state,
  isRecommendationsListLoading: true
});

const getRecommendationsSuccess = (
  state = initialState,
  { payload }: GetRecommendationsSuccessAction
): ProjectExpertsReducerState => ({
  ...state,
  recommendationsList: payload.recommendations,
  isRecommendationsListLoading: false
});

const getRecommendationsFailure = (
  state = initialState
): ProjectExpertsReducerState => ({
  ...state,
  isRecommendationsListLoading: false
});

// GET ANGLE EXPERT
const getAngleExpert = (state = initialState): ProjectExpertsReducerState => ({
  ...state
});

const getAngleExpertSuccess = (
  state = initialState,
  { payload }: GetAngleExpertSuccessAction
): ProjectExpertsReducerState => ({
  ...state,
  angleExpert: payload.angleExpert
});

const getAngleExpertFailure = (
  state = initialState
): ProjectExpertsReducerState => ({
  ...state
});

// Update Referral Status
const updateReferralStatus = (
  state = initialState
): ProjectExpertsReducerState => ({
  ...state,
  isUpdateReferralLoading: true
});

const updateReferralStatusSuccess = (
  state = initialState
): ProjectExpertsReducerState => ({
  ...state,
  isUpdateReferralLoading: false
});

const updateReferralStatusFailure = (
  state = initialState
): ProjectExpertsReducerState => ({
  ...state,
  isUpdateReferralLoading: false
});

const updateAllExpertsFastTrackStatus = (
  state = initialState,
  { payload }: UpdateAllExpertsFastTrackStatusAction
): ProjectExpertsReducerState => {
  const { id } = payload;

  if (state.allExpertsList) {
    const allExpertsList = state.allExpertsList.map((item) =>
      item.id === id
        ? {
            ...item,
            fastTrack: true
          }
        : item
    );

    return {
      ...state,
      allExpertsList
    };
  }

  return { ...state };
};

const setActiveTab = (
  state = initialState,
  { payload }: SetExpertListTabAction
): ProjectExpertsReducerState => ({
  ...state,
  tabActive: payload.tabActive
});

const forceClosePanel = (state = initialState): ProjectExpertsReducerState => ({
  ...state,
  forceClosePanel: !state.forceClosePanel
});

const filterTableByAngle = (
  state = initialState,
  { payload }: FilterTableByAngleAction
): ProjectExpertsReducerState => {
  let filteredArr;
  if (payload.values.includes(null)) {
    filteredArr = state.originalAllExpertsList?.filter((e) =>
      e.angle
        ? payload.values.includes(e.angle.title)
        : payload.values.includes(null)
    );
  } else {
    filteredArr = state.originalAllExpertsList
      ?.filter((val) => val.angle !== null)
      .filter((e) => payload.values.includes(e.angle.title));
  }

  return {
    ...state,
    allExpertsList:
      payload.values.length === 0 ? state.originalAllExpertsList : filteredArr!
  };
};

const ResetTableAction = (
  state = initialState
): ProjectExpertsReducerState => ({
  ...state,
  allExpertsList: state.originalAllExpertsList
});

const getProjectSummary = (
  state = initialState
): ProjectExpertsReducerState => ({
  ...state
});

const getProjectSummarySuccess = (
  state = initialState,
  { payload }: GetProjectSummarySuccessAction
): ProjectExpertsReducerState => ({
  ...state,
  summaryProjectList: payload.summaryList
});

const getProjectSummaryFailure = (
  state = initialState
): ProjectExpertsReducerState => ({
  ...state
});

// EDI TAGLINE
const handleEditTagline = (
  state = initialState
): ProjectExpertsReducerState => ({
  ...state,
  isTaglineSaving: true
});

const handleEditTaglineSuccess = (
  state = initialState
): ProjectExpertsReducerState => ({
  ...state,
  isTaglineSaving: false
});

const handleEditTaglineFailure = (
  state = initialState
): ProjectExpertsReducerState => ({
  ...state,
  isTaglineSaving: false
});

const handleSetScheduleTabFilters = (
  state = initialState,
  { payload }: SetScheduleTabFiltersAction
): ProjectExpertsReducerState => ({
  ...state,
  scheduleTabFilters: {
    ...state.scheduleTabFilters,
    [payload.projectId]: payload.filters
  }
});

// Current `Handlers` definition not good enough for proper typing
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handlers: Handlers<ProjectExpertsReducerState, any> = {
  [GET_ALL_EXPERTS]: getExpertsList,
  [GET_ALL_EXPERTS_SUCCESS]: getExpertsListSuccess,
  [GET_ALL_EXPERTS_FAILURE]: getExpertsListFailure,
  [GET_PROJECT_RECOMMENDATIONS]: getRecommendations,
  [GET_PROJECT_RECOMMENDATIONS_SUCCESS]: getRecommendationsSuccess,
  [GET_PROJECT_RECOMMENDATIONS_FAILURE]: getRecommendationsFailure,
  [GET_ANGLE_EXPERT]: getAngleExpert,
  [GET_ANGLE_EXPERT_SUCCESS]: getAngleExpertSuccess,
  [GET_ANGLE_EXPERT_FAILURE]: getAngleExpertFailure,
  [UPDATE_REFERRAL_STATUS]: updateReferralStatus,
  [UPDATE_REFERRAL_STATUS_SUCCESS]: updateReferralStatusSuccess,
  [UPDATE_REFERRAL_STATUS_FAILURE]: updateReferralStatusFailure,
  [UPDATE_ALL_EXPERTS_FAST_TRACK_STATUS]: updateAllExpertsFastTrackStatus,
  [SET_EXPERT_LIST_TAB]: setActiveTab,
  [FORCE_CLOSE_PANEL]: forceClosePanel,
  [FILTER_TABLE_BY_ANGLE]: filterTableByAngle,
  [RESET_TABLE_ACTION]: ResetTableAction,
  [GET_PROJECT_SUMMARY]: getProjectSummary,
  [GET_PROJECT_SUMMARY_SUCCESS]: getProjectSummarySuccess,
  [GET_PROJECT_SUMMARY_FAILURE]: getProjectSummaryFailure,
  [EDIT_TAGLINE]: handleEditTagline,
  [EDIT_TAGLINE_SUCCESS]: handleEditTaglineSuccess,
  [EDIT_TAGLINE_FAILURE]: handleEditTaglineFailure,
  [SET_SCHEDULE_TAB_FILTERS]: handleSetScheduleTabFilters
};

export const ProjectExpertsReducer = createReducer(initialState, handlers);
