export const convertTo = (amount: number, currency?: string): string => {
  switch (currency) {
    case "USD":
      return `${amount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD"
      })}`;
    case "EUR":
      return `${amount.toLocaleString("de-DE", {
        style: "currency",
        currency: "EUR"
      })}`;
    case "GBP":
      return `${amount.toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP"
      })}`;
    default:
      return `EUR | USD | GBP${amount}`;
  }
};

export function getCurrenciesSymbols(isoCurrencyCode: string): string {
  switch (isoCurrencyCode) {
    case "USD":
      return "$";
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    default:
      return "";
  }
}
