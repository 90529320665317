import { Style } from "@react-pdf/types";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

export const canopyTitlePDF: Style = {
  fontWeight: 600,
  fontSize: "16px",
  marginBottom: "8px",
  textTransform: "capitalize"
};

export const expertNamePDF: Style = {
  color: ARBOLUS_COLORS.bColorBaseOrange,
  fontWeight: 600,
  fontSize: "9px"
};

export const expertSectionPDF: Style = {
  color: "black",
  fontWeight: 600
};

export const titleQuestionPDF: Style = {
  color: ARBOLUS_COLORS.bColorBasePurple,
  fontWeight: 600,
  fontSize: "10px",
  marginBottom: "8px"
};

export const sectionPDF: Style = {
  color: "white",
  textAlign: "left",
  marginBottom: "16px",
  fontWeight: 600,
  paddingTop: "16px"
};

export const npsValuePDF: Style = {
  color: "black",
  fontSize: "8px",
  marginTop: "8px",
  marginBottom: "8px"
};

export const expertTitlePDF: Style = {
  color: ARBOLUS_COLORS.bColorBaseOrange,
  fontSize: "9px",
  marginBottom: "8px"
};

export const transcriptPDF: Style = {
  color: "black",
  fontSize: "8px",
  marginBottom: "8px"
};

export const videoPDFContainer: Style = {
  display: "flex",
  flexDirection: "column"
};

export const valueContainerPDF: Style = {
  color: "black",
  fontSize: "8px",
  marginBottom: "8px"
};

export const MCQContainerPDF: Style = {
  color: "black",
  fontSize: "8px",
  marginBottom: "8px",
  display: "flex",
  flexDirection: "column"
};

export const valueMultiPDF: Style = {
  fontSize: "8px",
  fontWeight: 600
};

export const multi: Style = {
  display: "flex",
  flexDirection: "row",
  gap: "4px"
};

export const questionDetailsPDF: Style = {
  color: "gray",
  marginBottom: "8px",
  fontSize: "8px"
};
