import { Button } from "arbolus-ui-components";
import { useFeature } from "flagged";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ProjectService,
  ToasterService,
  WebHooksService
} from "@arbolus-technologies/api";
import { FEATURE_FLAGS } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ModalWithChildren } from "@arbolus-technologies/ui/components";

import { ArbolusGPTPanel } from "../../Panels/ArbolusGPT/ArbolusGPTPanel";

import styles from "./ArbolusGPTButton.module.scss";

interface ArbolusGPTButtonProps {
  projectPath?: string;
  canopyPath?: string;
  projectId: string;
  onTrack?: () => void;
  projectService?: typeof ProjectService;
  webHooksService?: typeof WebHooksService;
  notificationService?: ToasterService;
}

export const ArbolusGPTButton: React.FC<ArbolusGPTButtonProps> = ({
  projectPath,
  canopyPath,
  projectId,
  onTrack,
  projectService = ProjectService,
  webHooksService = WebHooksService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("arbolusGPTButton");
  const dispatch = useDispatch();
  const isArbolusGPTFlagActive = useFeature(FEATURE_FLAGS.ArbolusGpt);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasProjectSearchVectors, setHasProjectSearchVectors] =
    useState<boolean>(false);

  const isAdmin = useSelector(CacheSelector.isAdmin());
  const currentUser = useSelector(CacheSelector.currentUserSelector());

  useEffect(() => {
    if (isArbolusGPTFlagActive) {
      projectService.hasProjectVectors(projectId).subscribe(
        (res) => {
          setHasProjectSearchVectors(res.hasProjectSearchVectors);
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showError(error.message);
        }
      );
    }
  }, [isArbolusGPTFlagActive, notificationService, projectId, projectService]);

  const isArbolusGPTActivated =
    hasProjectSearchVectors && isArbolusGPTFlagActive;
  const isArbolusGPTTestActivated =
    isArbolusGPTFlagActive && !hasProjectSearchVectors && !isAdmin;

  const openArbolusGpt = () => {
    dispatch(PanelStoreActions.openPanel(PanelId.ArbolusGpt));
  };

  const handleOnConfirmGPTRequest = () => {
    if (isLoading) return;
    setIsLoading(true);

    const clientName = `${currentUser.firstName} ${currentUser.lastName} (${currentUser.email})`;
    let messageTitle = "has requested information ArbolusGPT on this";
    if (projectPath) {
      messageTitle = `${messageTitle} Project: <${process.env.NX_PUBLIC_ADMIN_BASE_URL}${projectPath}>`;
    } else if (canopyPath) {
      messageTitle = `${messageTitle} Canopy: <${process.env.NX_PUBLIC_ADMIN_BASE_URL}${canopyPath}>`;
    }

    webHooksService
      .newFeatureRequestSendMessage(`${clientName} ${messageTitle}`)
      .subscribe(
        () => {
          setIsLoading(false);
          setIsModalOpen(false);
          notificationService.showSuccess(t("testGPTRequestSuccess"));
        },
        (error: ErrorResponse<CIQError>) => {
          setIsLoading(false);
          setIsModalOpen(false);
          notificationService.showError(error.message);
        }
      );
  };

  return (
    <>
      {isArbolusGPTActivated && (
        <Button
          text={t("arbolusGpt")}
          startIcon="magic_button"
          onClick={() => {
            onTrack?.();
            openArbolusGpt();
          }}
        />
      )}
      {isArbolusGPTTestActivated && (
        <Button
          text={t("arbolusGpt")}
          startIcon="magic_button"
          onClick={() => {
            setIsModalOpen(true);
            onTrack?.();
          }}
        />
      )}
      <ModalWithChildren
        customModalBody
        onCancel={() => setIsModalOpen(false)}
        onConfirm={handleOnConfirmGPTRequest}
        toggleModal={isModalOpen}
        className={styles.modal}
        confirmActionText={t("requestYourArbolusGPT")}
        cancelActionText="Close"
      >
        <div className={styles.gptTestContainer}>
          <div className={styles.titleTestGPT}>{t("titleTestGPT")}</div>
          <div className={styles.textContainer}>
            <span>{t("firstTextTestGPT")}</span>
            <span>{t("SecondTextTestGPT")}</span>
          </div>
          <div className={styles.textFooterTestGPT}>
            {t("textFooterTestGPT")}
          </div>
        </div>
      </ModalWithChildren>
      <ArbolusGPTPanel
        handleCloseRequest={() =>
          dispatch(PanelStoreActions.closePanel(PanelId.ArbolusGpt))
        }
        projectId={projectId}
      />
    </>
  );
};
