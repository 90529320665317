import { Button } from "arbolus-ui-components";
import clsx from "clsx";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { CanopyExpertSelector } from "@arbolus-technologies/stores/canopy-expert";

import styles from "./ButtonsRecordVideo.module.scss";

interface ButtonsRecordVideoProps {
  seconds: number;
  handleStopRecording: () => void;
  handleStartRecording: () => void;
}

export const ButtonsRecordVideo: React.FC<ButtonsRecordVideoProps> = ({
  seconds,
  handleStopRecording,
  handleStartRecording
}) => {
  const { t } = useTranslation("canopyVideo");

  const isRecording = useSelector(CanopyExpertSelector.isVideoRecording());

  return (
    <div
      className={clsx(styles.container, {
        [styles.isRecording]: isRecording
      })}
    >
      {isRecording ? (
        <>
          <Button
            type="rejection"
            text={t("stopButton")}
            onClick={handleStopRecording}
            endIcon="stop_circle"
          />
          <div className={styles.timer}>
            {moment.utc(seconds * 1000).format("mm:ss")}
          </div>
        </>
      ) : (
        <Button
          type="rejection"
          text={t("recordButton")}
          onClick={handleStartRecording}
        />
      )}
    </div>
  );
};
