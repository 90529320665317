import React from "react";

import {
  AdminService,
  ListProjectExpert,
  SORT_DIRECTION
} from "@arbolus-technologies/api";

import { UserSelector, UserSelectorProps } from "./UserSelector";

interface ProjectExpertSelectorProps
  extends Omit<UserSelectorProps<ListProjectExpert>, "getItems"> {
  projectId: string;
  allowInactive?: boolean;
}

export const ProjectExpertSelector: React.FC<ProjectExpertSelectorProps> = ({
  projectId,
  allowInactive = false,
  ...props
}) => (
  <UserSelector
    {...props}
    getItems={(pagination) =>
      AdminService.getProjectExperts(
        projectId,
        pagination.searchTerm,
        "firstName",
        pagination.offset,
        pagination.limit,
        SORT_DIRECTION.DESCENDING,
        allowInactive
      )
    }
  />
);
