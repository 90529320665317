import React from "react";
import { useTranslation } from "react-i18next";
import ReactPlaceholder from "react-placeholder";

import { AllCanopy } from "@arbolus-technologies/api";
import { CanopyPageType } from "@arbolus-technologies/models/canopy";
import { EmptyCanopyBox } from "@arbolus-technologies/ui/components";
import { GrayBox } from "@arbolus-technologies/ui/layout";

import { MixPanelEventNames } from "../../Mixpanel/enums";
import { useArbolusTracking } from "../../Mixpanel/useArbolusTracking";
import { CreateSimpleCanopyButton } from "../CreateSimpleCanopyButton/CreateSimpleCanopyButton";
import { CanopyCard } from "./CanopyCard/CanopyCard";

import styles from "./CanopyGrayBox.module.scss";

interface CanopyGrayBoxProps {
  to: string;
  isCanopiesLoading: boolean;
  canopies: AllCanopy[];
  canopyPageType: CanopyPageType;
}

export const CanopyGrayBox: React.FC<CanopyGrayBoxProps> = ({
  to,
  isCanopiesLoading,
  canopies,
  canopyPageType
}) => {
  const { t } = useTranslation("canopyGrayBox");
  const { trackClick } = useArbolusTracking();

  const isUserDashboardPage = canopyPageType === CanopyPageType.UserDashboard;

  return (
    <GrayBox
      title={{
        text: t("title"),
        to,
        onClick: () =>
          trackClick(
            isUserDashboardPage
              ? MixPanelEventNames.UserDashboardCanopyCanopies
              : MixPanelEventNames.ProjectDashboardCanopyCanopies
          )
      }}
      flexDirection="column"
      gap={[2, 0]}
      fullWidth
      actionComponent={
        <CreateSimpleCanopyButton
          buttonType="grayBoxButton"
          onTrack={() =>
            trackClick(
              isUserDashboardPage
                ? MixPanelEventNames.UserDashboardCanopyTopNewCanopy
                : MixPanelEventNames.ProjectDashboardTopNewCanopy
            )
          }
        />
      }
    >
      <ReactPlaceholder
        type="media"
        ready={!isCanopiesLoading}
        showLoadingAnimation
        color="#e8e8eb"
      >
        {canopies.length === 0 && (
          <EmptyCanopyBox>
            <CreateSimpleCanopyButton
              buttonType="grayBoxButton"
              onTrack={() =>
                trackClick(
                  isUserDashboardPage
                    ? MixPanelEventNames.UserDashboardCanopyBottomNewCanopy
                    : MixPanelEventNames.ProjectDashboardBottomNewCanopy
                )
              }
            />
          </EmptyCanopyBox>
        )}
        {canopies.length > 0 && (
          <div className={styles.cardsContainer}>
            {canopies.map((canopy) => (
              <CanopyCard
                key={canopy.id}
                {...canopy}
                projectInfo={{ id: canopy.projectId, name: canopy.projectName }}
                canopyPageType={canopyPageType}
              />
            ))}
          </div>
        )}
      </ReactPlaceholder>
    </GrayBox>
  );
};
