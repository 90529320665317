import React from "react";

import { AdminService, User } from "@arbolus-technologies/api";

import { UserSelector, UserSelectorProps } from "./UserSelector";

interface ClientManagerSelectorProps
  extends Omit<UserSelectorProps<User>, "getItems"> {
  clientId: string;
}

export const ClientManagerSelector: React.FC<ClientManagerSelectorProps> = ({
  clientId,
  ...props
}) => (
  <UserSelector
    {...props}
    getItems={(pagination) =>
      AdminService.getClientManagers(clientId, pagination)
    }
  />
);
