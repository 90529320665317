import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CIQError,
  ErrorResponse,
  ExpertService,
  ScreeningQuestion,
  ToasterService
} from "@arbolus-technologies/api";
import { Loader } from "@arbolus-technologies/ui/components";

import { ScreeningCardItem } from "../../Components/ScreeningCardItem/ScreeningCardItem";

import styles from "./Screening.module.scss";

export interface ScreeningProps {
  expertId: string;
  expertService?: typeof ExpertService;
}

const notificationService = new ToasterService();

export const Screening: React.FC<ScreeningProps> = ({
  expertId,
  expertService = ExpertService
}) => {
  const [screeningQuestions, setScreeningQuestions] = useState<
    ScreeningQuestion[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation("screeningQuestionsExpert");

  useEffect(() => {
    setIsLoading(true);
    expertService.getScreeningQuestions(expertId).subscribe(
      ({ items }) => {
        setScreeningQuestions(items);
        setIsLoading(false);
      },
      ({ message }: ErrorResponse<CIQError>) => {
        notificationService.showError(message);
        setIsLoading(false);
      }
    );
  }, [expertId, expertService]);

  if (isLoading) {
    return <Loader />;
  }

  return screeningQuestions.length > 0 ? (
    <div className={styles.container}>
      {screeningQuestions.map((question, key) => (
        <ScreeningCardItem key={`${key}_screening_item`} question={question} />
      ))}
    </div>
  ) : (
    <div className={styles.noResultContainer}>{t("noQuestions")}</div>
  );
};
