import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Media from "react-media";
import SimpleBar from "simplebar-react";

import { InfoCookieBoxPane } from "@arbolus-technologies/features/canopy";
import { useIsChatAvailable } from "@arbolus-technologies/features/common";

import { UIConstants } from "../../../../constants";
import { UI_PROJECT_BASE, UI_WINDOW_HEIGHT } from "../../../../constants/ui";
import { NavBarContextConsumer } from "../../../../contexts/navBar/NavBarContext";
import AccessManager from "../../../../contexts/roleBasedAccess/AccessManager";
import ProjectAdminPane from "../../components/projectBase/admin/ProjectAdminPane";
import AnglesPane from "../../components/projectBase/anglesPane/AnglesPane";
import ProjectHeaderPane from "../../components/projectBase/bannerPane/ProjectHeaderPane";
import ProjectCardsPane from "../../components/projectBase/cardsPane/ProjectCardsPane";
import SurveyPane from "../../components/projectBase/surveyPane/SurveyPane";

type ProjectBasePageProps = WithTranslation;

const ProjectBasePage: React.FC<ProjectBasePageProps> = ({
  t
}: ProjectBasePageProps): JSX.Element => {
  const isChatAvailable = useIsChatAvailable();

  return (
    <Media queries={UIConstants.APP_DEVICE_MEDIA_QUERIES}>
      {(matches): JSX.Element => (
        <NavBarContextConsumer>
          {({ isProjectActionsActive }): JSX.Element => {
            const containerHeight =
              UI_PROJECT_BASE.CONTAINER_EXCESS_HEIGHT(matches) +
              (isProjectActionsActive && !matches.large
                ? UI_PROJECT_BASE.TABS_ELEMENT_HEIGHT_MOBILE
                : 0);

            return (
              <SimpleBar
                style={{
                  minHeight: `calc(${UI_WINDOW_HEIGHT} - ${containerHeight}px)`,
                  height: `calc(${UI_WINDOW_HEIGHT} - ${containerHeight}px)`
                }}
                className="base-container simplebar-light"
              >
                <AccessManager permission="canopy:notification">
                  <div style={{ marginBottom: "32px" }}>
                    <InfoCookieBoxPane />
                  </div>
                </AccessManager>
                <ProjectHeaderPane />
                {/* @ts-ignore */}
                <ProjectCardsPane isChatAvailable={isChatAvailable} />
                <AccessManager permission="angles:dashboard">
                  <AnglesPane />
                </AccessManager>
                <AccessManager permission="canopy:dashboard">
                  <SurveyPane />
                </AccessManager>
                <h1 className="admin-title">{t("admin")}</h1>
                <ProjectAdminPane />
              </SimpleBar>
            );
          }}
        </NavBarContextConsumer>
      )}
    </Media>
  );
};

export default withTranslation("projectBasePage")(ProjectBasePage);
