import { ICellRendererParams } from "ag-grid-community";
import { Icon } from "arbolus-ui-components";
import React from "react";
import { Link } from "react-router-dom";

import {
  DefaultToasterService,
  ListTranscriptModel,
  ToasterService,
  Transcript,
  TranscriptService
} from "@arbolus-technologies/api";
import {
  DefaultTranscriptToBlob,
  TranscriptToBlob
} from "@arbolus-technologies/features/common";
import { PROJECT_TRANSCRIPT } from "@arbolus-technologies/routes";

import styles from "./TranscriptsTable.module.scss";

type ActionsCellRendererProps = ICellRendererParams<
  ListTranscriptModel,
  never,
  never
> & {
  projectId: string;
  transcriptService?: typeof TranscriptService;
  notificationService?: ToasterService;
  transcriptToBlob?: TranscriptToBlob;
};

export const ActionsCellRenderer: React.FC<ActionsCellRendererProps> = ({
  data,
  projectId,
  transcriptService = TranscriptService,
  notificationService = DefaultToasterService,
  transcriptToBlob = DefaultTranscriptToBlob
}) => {
  const handleDownloadDocx = () => {
    transcriptService.getTranscriptData(projectId, data?.id!).subscribe({
      next: (response: Transcript) => {
        const { transcriptionData } = response;
        transcriptToBlob.downloadTranscript(
          {
            transcriptSpeakerCount: transcriptionData.speakers.length,
            ...response
          },
          transcriptionData.monologues,
          new Map(transcriptionData.speakers.map((s) => [s.name, s]))
        );
      },
      error: notificationService.showApiErrors
    });
  };

  return (
    <>
      <Link
        to={PROJECT_TRANSCRIPT(projectId, data?.id!)}
        className={styles.eyeIcon}
      >
        <Icon name="visibility" fontSize="20px" />
      </Link>
      <Icon name="download" fontSize="20px" onClick={handleDownloadDocx} />
    </>
  );
};
