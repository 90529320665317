import clsx from "clsx";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Row } from "reactstrap";
import { Dispatch } from "redux";
import { createStructuredSelector } from "reselect";

import { PROJECT_BRIEF_ROUTE } from "../../../../../constants/navigation/projectRoutes";
import { PROJECT_STATES } from "../../../../../constants/project";
import AccessManager from "../../../../../contexts/roleBasedAccess/AccessManager";
import { AppState } from "../../../../../store/reducers";
import { ProjectSelector, ProjectStoreActions } from "../../../store";
import BriefCard from "./BriefCard";
import TeamCard from "./TeamCard";
import TimesheetCard from "./TimesheetCard";

interface ProjectAdminPaneStoreProps {
  projectId: string;
  membersCount: number;
  modified: Date;
  isProjectStateUpdating: boolean;
  projectState: string;
}

interface ProjectAdminPaneDispatchProps {
  onUpdateProjectState: (projectId: string, projectState: string) => void;
}

type ProjectAdminPaneIntersectProps = ProjectAdminPaneStoreProps &
  ProjectAdminPaneDispatchProps &
  WithTranslation;

const ProjectAdminPane: React.FC<ProjectAdminPaneIntersectProps> = ({
  projectId,
  membersCount,
  modified,
  isProjectStateUpdating,
  projectState,
  onUpdateProjectState,
  t
}: ProjectAdminPaneIntersectProps) => {
  const isActiveProject = projectState === PROJECT_STATES.ACTIVE;
  const history = useHistory();

  const navigateToProjectBrief = (): void => {
    history.push(PROJECT_BRIEF_ROUTE(projectId));
  };

  return (
    <div className="project-admin-action-container">
      <Row>
        <AccessManager permission="projectBase:clientTeam">
          <TeamCard membersCount={membersCount} title={t("team")} />
        </AccessManager>

        <AccessManager permission="projectBase:clientBrief">
          <BriefCard
            modified={modified}
            title={t("brief")}
            onProjectBriefClicked={navigateToProjectBrief}
          />
        </AccessManager>
        <AccessManager permission="projectBase:expertProjectBrief">
          <BriefCard
            modified={modified}
            title={t("projectBrief")}
            onProjectBriefClicked={navigateToProjectBrief}
          />
        </AccessManager>
        <AccessManager permission="projectBase:expertTeam">
          <TeamCard membersCount={membersCount} title={t("clientTeam")} />
        </AccessManager>
        <AccessManager permission="projectBase:expertTimesheet">
          <TimesheetCard hours={0} title={t("timesheet")} />
        </AccessManager>
      </Row>
      <AccessManager permission="projectBase:archiveProject">
        <div className="single-project-footer">
          <p
            className={clsx("archive-link-text", {
              disabled: isProjectStateUpdating
            })}
            onClick={(): void => {
              // If current state is Active, flip the values
              const newState = isActiveProject
                ? PROJECT_STATES.ARCHIVE
                : PROJECT_STATES.ACTIVE;

              onUpdateProjectState(projectId, newState);
            }}
          >
            {t(isActiveProject ? "archiveThis" : "activeThis")}
          </p>
        </div>
      </AccessManager>
    </div>
  );
};

const mapStateToProps = createStructuredSelector<
  AppState,
  ProjectAdminPaneStoreProps
>({
  projectId: ProjectSelector.projectIdSelector(),
  membersCount: ProjectSelector.projectMembersCountSelector(),
  modified: ProjectSelector.projectModifiedDateSelector(),
  projectState: ProjectSelector.projectStateSelector(),
  isProjectStateUpdating: ProjectSelector.projectStateUpdatingSelector()
});

const mapDispatchToProps = (
  dispatch: Dispatch
): ProjectAdminPaneDispatchProps => ({
  onUpdateProjectState: (projectId: string, projectState: string): void => {
    dispatch(ProjectStoreActions.updateProjectState(projectId, projectState));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("projectBasePage")(ProjectAdminPane));
