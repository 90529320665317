import dompurify from "dompurify";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Compliance } from "@arbolus-technologies/api";
import {
  InputController,
  RADIO_VARIANT,
  RadioChoicesController,
  RadioOption
} from "@arbolus-technologies/ui/components";

import { PROJECT_COMPLIANCE_QUESTION_TYPE } from "../../../helpers/constants";
import { ComplianceFormValues } from "../ComplianceQuestionsSchema";

import styles from "./ComplianceQuestion.module.scss";

interface ComplianceQuestionProps {
  complianceQuestion: Compliance;
  index: number;
}

export const ComplianceQuestion: React.FC<ComplianceQuestionProps> = ({
  complianceQuestion,
  index
}) => {
  const { t } = useTranslation("expertApplications");

  const {
    question,
    displayModel: { questionType, textRequiredValue }
  } = complianceQuestion.question;

  const radioOptions: RadioOption<string>[] = [
    { title: t("yes"), value: t("yes") },
    { title: t("no"), value: t("no") }
  ];

  const {
    control,
    formState: { errors },
    watch,
    resetField
  } = useFormContext<ComplianceFormValues>();

  const answer = watch("complianceAnswers")[index].answer;

  useEffect(() => {
    answer !== textRequiredValue &&
      resetField(`complianceAnswers.${index}.textAnswer`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer, index, textRequiredValue]);

  return (
    <div className={styles.container}>
      <div className={styles.questionInfo}>
        <span>{t("question", { index: index + 1 })}</span>
        <h4
          className={styles.question}
          dangerouslySetInnerHTML={{ __html: dompurify.sanitize(question) }}
        />
      </div>
      <RadioChoicesController
        name={`complianceAnswers.${index}.answer`}
        options={radioOptions}
        variant={RADIO_VARIANT.MAX_CONTENT}
        control={control}
      />
      {questionType === PROJECT_COMPLIANCE_QUESTION_TYPE.YES_NO_TEXT && (
        <div className={styles.textAnswer}>
          <h4>{t("additionalInformation")}</h4>
          <InputController
            name={`complianceAnswers.${index}.textAnswer`}
            error={errors.complianceAnswers?.[index]?.textAnswer}
            control={control}
            placeholder={t("yesNoTextPlaceholder")}
            type="textarea"
            size="big"
            disabled={answer !== textRequiredValue}
          />
        </div>
      )}
    </div>
  );
};
