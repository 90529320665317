import React from "react";
import { useSelector } from "react-redux";

import { VIDEO_MODE_ENUM } from "@arbolus-technologies/models/canopy";
import { CanopyExpertSelector } from "@arbolus-technologies/stores/canopy-expert";

import { VideoPlayer } from "./VideoPlayer/VideoPlayer";
import { VideoRecorder } from "./VideoRecorder/VideoRecorder";

import styles from "./VideoComponent.module.scss";

export const VideoComponent: React.FC = () => {
  const videoMode = useSelector(CanopyExpertSelector.videoMode());

  return (
    <div className={styles.container}>
      {videoMode === VIDEO_MODE_ENUM.RECORD ? (
        <VideoRecorder />
      ) : (
        <VideoPlayer />
      )}
    </div>
  );
};
