import { ICellRendererParams } from "ag-grid-community";
import { Button } from "arbolus-ui-components";
import React from "react";

import {
  DO_NOT_CONTACT_STATUS,
  SlotsReferral
} from "@arbolus-technologies/models/common";

import { ReferralGridContext } from "../../Models/ReferralsTable";
import { DefaultFormatDateService } from "../../Services/Referrals/FormatDateService";
import { Booking } from "../BookingButton/Booking";

export const EarliestAvailabilityRenderer: React.FC<
  ICellRendererParams<SlotsReferral, never, ReferralGridContext>
> = ({ data, context }) => {
  if (!data?.slots) {
    return null;
  }

  return (
    <Booking
      slot={data.getEarliestSlot()!}
      timezone={context.timezone}
      referral={data}
      project={context.project}
      render={(timezone, onClick, slot) => (
        <Button
          type="tertiary"
          endIcon="chevron_right"
          text={DefaultFormatDateService.formatDayTime(
            slot!.startTime,
            timezone
          )}
          disabled={
            data.expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC
          }
          onClick={onClick}
        />
      )}
    />
  );
};
