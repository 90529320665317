import React from "react";
import { useSelector } from "react-redux";

import { ProjectAnglesSelector } from "@arbolus-technologies/stores/project-angles-store";
import { NoAngles } from "@arbolus-technologies/ui/angles";
import { Loader } from "@arbolus-technologies/ui/components";

import { AnglesCard } from "../AnglesCard/AnglesCard";

import styles from "./AnglesList.module.scss";

interface AnglesListProps {
  navigateToExpertsListTab: string;
  projectId: string;
  isAdmin?: boolean;
  chatId?: string;
}

export const AnglesList: React.FC<AnglesListProps> = ({
  projectId,
  navigateToExpertsListTab,
  isAdmin = false,
  chatId
}) => {
  const anglesCards = useSelector(ProjectAnglesSelector.anglesCardsSelector());
  const isAnglesCardsLoading = useSelector(
    ProjectAnglesSelector.anglesCardsLoadingSelector()
  );

  return (
    <div className={styles.container}>
      {isAnglesCardsLoading ? (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <>
          {anglesCards.length > 0 &&
            anglesCards.slice(0, 3).map((angleCard) => (
              <div className={styles.surveyCard} key={angleCard.id}>
                <AnglesCard
                  key={angleCard.id}
                  angleCard={angleCard}
                  navigateToExpertsListTab={navigateToExpertsListTab}
                  isAdmin={isAdmin}
                  projectId={projectId}
                />
              </div>
            ))}
          {anglesCards.length === 0 && <NoAngles />}
        </>
      )}
    </div>
  );
};
