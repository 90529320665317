import {
  DefaultToasterService,
  ToasterService
} from "@arbolus-technologies/api";
import { MAXIMUM_IMAGE_UPLOAD_SIZE } from "@arbolus-technologies/models/documents";
import { EXPERT_EXPERIENCE } from "@arbolus-technologies/models/expert";
import {
  LINKEDIN_URL_REGEX,
  TiptapEditorMemoized
} from "@arbolus-technologies/ui/components";
import { convertValueToCurrencyFormat } from "@arbolus-technologies/utils";
import {
  Avatar,
  Button,
  Flex,
  Form,
  Input,
  Select,
  Typography,
  Upload
} from "antd";
import { RcFile } from "antd/es/upload/interface";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { ExpertProfileFormDetails } from "./Details";
import styles from "./Details.module.scss";

const { Text } = Typography;
const OVERVIEW_MAX_LENGTH = 8000;
const FLEX_GAP = 24;
const requiredRule = { required: true };

interface DetailsFormProps {
  expert: ExpertProfileFormDetails;
  isLoading: boolean;
  updateExpertDetails: (expert: ExpertProfileFormDetails) => void;
  notificationService?: ToasterService;
}
export const DetailsForm: React.FC<DetailsFormProps> = ({
  expert,
  isLoading,
  updateExpertDetails,
  notificationService = DefaultToasterService
}) => {
  const [imageUrl, setImageUrl] = useState<string>();
  const [form] = Form.useForm();
  const { t } = useTranslation("expertDetailSection");
  const { hourlyRate, isoCurrencyCode } = expert;

  const beforeUpload = (file: RcFile) => {
    const imageSize = Math.round(file.size / 1024);
    if (imageSize >= MAXIMUM_IMAGE_UPLOAD_SIZE) {
      notificationService.showError(t("maximum5MB"));
      return;
    }
    setImageUrl(URL.createObjectURL(file));
  };

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark="optional"
      disabled={isLoading}
      initialValues={expert}
      onFinish={updateExpertDetails}
    >
      <Flex gap={16} className={styles.imageWrapper}>
        <Avatar size={64} src={imageUrl ?? expert.profileImageUrl} />
        <Flex vertical gap={8}>
          <Upload
            accept=".jpg,.jpeg,.png,.gif"
            beforeUpload={beforeUpload}
            showUploadList={false}
          >
            <Button>{t("changeProfileImage")}</Button>
          </Upload>
          <Text type="secondary">{t("recommendedImageSize")}</Text>
        </Flex>
      </Flex>
      <Flex gap={FLEX_GAP}>
        <Form.Item
          label={t("name")}
          name="firstName"
          required
          rules={[requiredRule]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("surName")}
          name="lastName"
          required
          rules={[requiredRule]}
        >
          <Input />
        </Form.Item>
      </Flex>
      <Flex gap={FLEX_GAP}>
        <Form.Item label={t("email")} name="email">
          <Input disabled />
        </Form.Item>
        <Form.Item label={t("phoneNumber")} name="phoneNumber">
          <PhoneInput
            inputProps={{
              name: "phoneNumber"
            }}
            onChange={(value: string) =>
              form.setFieldValue("phoneNumber", value ?? "")
            }
            enableSearch
            country="us"
            disableSearchIcon
          />
        </Form.Item>
      </Flex>
      <Form.Item
        label={t("linkedinUrl")}
        name="linkedinProfile"
        rules={[
          {
            type: "url",
            pattern: LINKEDIN_URL_REGEX,
            message: t("invalidLinkedinUrl")
          }
        ]}
      >
        <Input addonBefore="https://" />
      </Form.Item>
      <Form.Item label={t("headline")} name="title">
        <Input />
      </Form.Item>
      <Form.Item
        label={t("internal")}
        name="overview"
        rules={[
          {
            max: OVERVIEW_MAX_LENGTH,
            message: t("internalLimitError", {
              length: OVERVIEW_MAX_LENGTH
            })
          }
        ]}
      >
        <TiptapEditorMemoized
          placeholder={t("introductionPlaceholder")}
          onChange={(content: string): void => {
            form.setFieldsValue({ overview: content });
          }}
          hasError={false}
          initialEditorState={expert.overview ?? ""}
        />
      </Form.Item>
      <Form.Item label={t("quickFact")} name={["quickFacts", 0]}>
        <Input placeholder={t("quickFactsPlaceholder1")} />
      </Form.Item>
      <Form.Item name={["quickFacts", 1]}>
        <Input placeholder={t("quickFactsPlaceholder2")} />
      </Form.Item>
      <Form.Item label={t("funFact")} name="funFact">
        <Input placeholder={t("funFactPlaceholder")} />
      </Form.Item>
      <Flex gap={FLEX_GAP}>
        <Form.Item label={t("seniority")} name="experienceLevel">
          <Select
            options={Array.from(EXPERT_EXPERIENCE.values())}
            placement="bottomLeft"
            className={styles.seniority}
          />
        </Form.Item>
        {isoCurrencyCode && hourlyRate && (
          <Form.Item label={t("hourlyRate")} name="hourlyRate">
            <Input
              type="number"
              value={convertValueToCurrencyFormat(hourlyRate, isoCurrencyCode)}
              disabled
            />
          </Form.Item>
        )}
      </Flex>
      <Button type="primary" htmlType="submit">
        {t("saveChanges")}
      </Button>
    </Form>
  );
};
