import { ColDef, GetContextMenuItemsParams } from "ag-grid-community";
import i18next from "i18next";
import moment from "moment";

import { DefaultClipboardManager } from "@arbolus-technologies/features/common";
import { CustomerSurvey } from "@arbolus-technologies/models/common";
import {
  AgGridCustomIcons,
  fixedWidth
} from "@arbolus-technologies/ui/components";
import {
  TRANSACTION_FORMAT,
  displayUserName
} from "@arbolus-technologies/utils";

import { AddCustomerSurveyStatusPanel } from "./AddCustomerSurveyStatusPanel/AddCustomerSurveyStatusPanel";

export const defaultColDef: ColDef = {
  resizable: true,
  sortable: false,
  suppressHeaderMenuButton: true,
  suppressHeaderContextMenu: true
};

export const statusBar = {
  statusPanels: [
    {
      statusPanel: AddCustomerSurveyStatusPanel,
      align: "left"
    }
  ]
};

export const colDefs: ColDef[] = [
  { field: "title", flex: 1, cellRenderer: "agGroupCellRenderer" },
  { field: "owner", valueGetter: ({ data }) => ownerValueGetter(data) },
  {
    field: "created",
    ...fixedWidth(120),
    valueGetter: ({ data }) =>
      moment.utc(data.created).format(TRANSACTION_FORMAT)
  }
];

function ownerValueGetter(customerSurvey: CustomerSurvey): string {
  return displayUserName(customerSurvey.owner);
}

export function getContextMenuItems(
  params: GetContextMenuItemsParams<CustomerSurvey>
) {
  const { node } = params;
  if (!node?.data) {
    return [];
  }

  const { id } = node.data;
  return [
    {
      name: i18next.t("customerSurveyTable:copyLinkMenuItem"),
      action: () =>
        DefaultClipboardManager.copy(
          `${process.env.NX_PUBLIC_BASE_URL}/customers/surveys/${id}`
        ),
      icon: AgGridCustomIcons.copy
    }
  ];
}
