import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  BOOKMARK_COLLECTION_ORDER_BY,
  SORT_DIRECTION
} from "@arbolus-technologies/api";
import {
  BookmarkCollectionsSelector,
  BookmarkCollectionsStoreActions
} from "@arbolus-technologies/stores/bookmark-collections";
import {
  InfiniteScrollWithHeader,
  UI_BOOKMARKS,
  UI_COMMON
} from "@arbolus-technologies/ui/components";

import { AddCollectionButton } from "../AddCollectionButton/AddCollectionButton";
import { BookmarkCollectionCard } from "../BookmarkCollectionCard/BookmarkCollectionCard";

import styles from "./BookmarkCollectionsList.module.scss";

export const BookmarkCollectionsList: React.FC = () => {
  const dispatch = useDispatch();
  const list = useSelector(BookmarkCollectionsSelector.collectionList());
  const isLoading = useSelector(
    BookmarkCollectionsSelector.collectionListIsLoading()
  );
  const hasNextPage = useSelector(
    BookmarkCollectionsSelector.collectionListHasNextPage()
  );

  const nextItems = useCallback(
    (limit: number): void => {
      if (!hasNextPage) {
        return;
      }

      dispatch(
        BookmarkCollectionsStoreActions.listCollections("", {
          limit,
          offset: list?.length || 0,
          orderBy: BOOKMARK_COLLECTION_ORDER_BY.CREATED,
          orderDirection: SORT_DIRECTION.ASCENDING
        })
      );
    },
    [dispatch, hasNextPage, list]
  );

  return (
    <InfiniteScrollWithHeader
      items={list}
      itemHeight={
        UI_BOOKMARKS.COLLECTION_CARD_HEIGHT() /
        UI_BOOKMARKS.COLLECTION_CARD_PER_ROW(window.innerWidth)
      }
      isLoading={isLoading}
      nextItems={nextItems}
      renderer={(item) => (
        <BookmarkCollectionCard key={item.id} collection={item} />
      )}
      classnames={styles.scrollContainer}
      rendererContainerClassnames={styles.bookmarksContainer}
      style={{
        maxHeight: UI_COMMON.HAS_SIDE_MENU()
          ? "calc(100vh - 80px)"
          : "calc(100vh - 48px)",
        height: UI_COMMON.HAS_SIDE_MENU()
          ? "calc(100vh - 80px)"
          : "calc(100vh - 48px)",
        overflowX: "hidden"
      }}
    >
      <div className={styles.actions}>
        <AddCollectionButton />
      </div>
    </InfiniteScrollWithHeader>
  );
};
