import { differenceBy } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { CustomerSurveyCompany } from "@arbolus-technologies/models/common";
import { arbolusLogo } from "@arbolus-technologies/theme";
import { Loader } from "@arbolus-technologies/ui/components";
import { useDocumentTitle } from "@arbolus-technologies/utils";

import { ChooseVendorsStep } from "../../modules/SurveySteps/ChooseVendorsStep/ChooseVendorsStep";
import { DoneStep } from "../../modules/SurveySteps/DoneStep/DoneStep";
import { GiveDetailsStep } from "../../modules/SurveySteps/GiveDetailsStep/GiveDetailsStep";
import { useSurveyTemplate } from "./useSurveyData";

import banner from "./banner.png";

import { Toaster } from "sonner";
import styles from "./ExpertSurveyPage.module.scss";

enum SurveyStep {
  ChooseVendors,
  GiveDetails,
  Done
}

export const ExpertSurveyPage: React.FC = () => {
  const { t } = useTranslation("expertSurvey");
  useDocumentTitle("expertSurvey", "browserTabTitle");
  const [surveyStep, setSurveyStep] = useState(SurveyStep.ChooseVendors);

  const { surveyId } = useParams<{ surveyId: string }>();

  const surveyTemplate = useSurveyTemplate(surveyId);

  if (surveyTemplate === undefined) return <Loader isFull isCentered />;

  const defaultVendors = surveyTemplate.companies.filter(
    (company) => company.visible === true
  );

  return (
    <div className={styles.page}>
      <div className={styles.logo}>
        <img
          src={arbolusLogo}
          className={styles.logoImage}
          alt="Arbolus Logo"
        />
      </div>

      <div className={styles.content}>
        {surveyStep === SurveyStep.Done ? null : (
          <div className={styles.header}>
            <h1>{t("pageTitle")}</h1>
            <p>
              {t("pageSubtitle1")}
              <br />
              <b>{t("pageSubtitle2")}</b>
            </p>
          </div>
        )}

        <SurveyPageSteps
          defaultVendors={defaultVendors}
          surveyStep={surveyStep}
          setSurveyStep={setSurveyStep}
        />
      </div>

      <div className={styles.footer}>
        <div className={styles.footerLogo}>
          <img
            src={arbolusLogo}
            className={styles.footerLogoImage}
            alt="Arbolus Logo"
          />
          <span>Fewer calls. Better Insights. Faster.</span>
        </div>
        <img
          className={styles.banner}
          src={banner}
          alt="1000 Europe's fastest growing companies 2023"
        />
      </div>

      {/* Because this page is not under DashboardContainer, we need to show notifications separately */}
      <Toaster visibleToasts={5} />
    </div>
  );
};

function SurveyPageSteps({
  defaultVendors,
  surveyStep,
  setSurveyStep
}: {
  defaultVendors: CustomerSurveyCompany[];
  surveyStep: SurveyStep;
  setSurveyStep: (step: SurveyStep) => void;
}) {
  const [vendors, setVendors] =
    useState<CustomerSurveyCompany[]>(defaultVendors);

  if (surveyStep === SurveyStep.ChooseVendors) {
    return (
      <ChooseVendorsStep
        next={() => {
          setSurveyStep(SurveyStep.GiveDetails);
        }}
        vendors={vendors}
        setVendors={setVendors}
      />
    );
  }
  if (surveyStep === SurveyStep.GiveDetails) {
    return (
      <GiveDetailsStep
        next={() => setSurveyStep(SurveyStep.Done)}
        vendors={vendors}
        defaultAlternatives={differenceBy(defaultVendors, vendors, "id")}
      />
    );
  }
  if (surveyStep === SurveyStep.Done) {
    return <DoneStep />;
  }
  return null;
}
