import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Button } from "reactstrap";

import { REGISTER } from "../../../../constants/navigation/publicRoutes";
import { ParamTypes } from "./ExpertEndorsementPage";

import { ReactComponent as TickIcon } from "../../../../assets/images/icons/tick.svg";

import styles from "./ExpertEndorsementConfirmation.module.scss";

interface ExpertEndorsementConfirmationProps {
  expertEndorsementData: ParamTypes;
}

const ExpertEndorsementConfirmation: React.FC<
  ExpertEndorsementConfirmationProps
> = ({ expertEndorsementData }) => {
  const history = useHistory();
  const { t } = useTranslation("expertEndorsementPage");
  const { expertName, endorserName } = expertEndorsementData;

  const handleSignUp = (): void => {
    history.push(REGISTER);
  };

  const handleRedirectAbout = (): void => {
    window.open(t("https://www.arbolus.com/about-us"), "_blank");
  };

  return (
    <div className={styles.successContainer}>
      <TickIcon />
      <h1>{t("thanks", { endorserName })}</h1>
      <p className={styles.appreciate}>{t("appreciate", { expertName })}</p>
      <h3 className={styles.join}>{t("join")}</h3>
      <p className={styles.message}>{t("message")}</p>
      <div className={styles.actionButtonContainer}>
        <Button
          size="lg"
          color="secondary"
          className="btn-bold"
          onClick={handleRedirectAbout}
        >
          {t("more")}
        </Button>
        <Button
          size="lg"
          color="primary"
          className="btn-bold"
          onClick={handleSignUp}
        >
          {t("sign")}
        </Button>
      </div>
    </div>
  );
};

export default ExpertEndorsementConfirmation;
