import clsx from "clsx";
import React from "react";
import { isMobile } from "react-device-detect";
import SlidingPane from "react-sliding-pane";

import "react-sliding-pane/dist/react-sliding-pane.css";
import styles from "./InternalSlidePanel.module.scss";

interface InternalSlidePanelProps {
  children: React.ReactNode;
  width?: string;
  from?: "left" | "right" | "bottom";
  closeComponent?: JSX.Element;
  customCloseRequest: () => void;
  title?: JSX.Element | string;
  closeButtonDirection?: "left" | "right";
  hideHeader?: boolean;
  isOpen?: boolean;
  noPadding?: boolean;
}

export const InternalSlidePanel: React.FC<InternalSlidePanelProps> = ({
  children,
  width = "600px",
  from = "right",
  closeComponent,
  customCloseRequest,
  title,
  closeButtonDirection = "left",
  hideHeader,
  isOpen,
  noPadding
}) => (
  <SlidingPane
    title={title}
    hideHeader={hideHeader}
    className={clsx(
      styles.container,
      noPadding && styles.noPadding,
      closeButtonDirection === "right"
        ? styles.closeButtonRight
        : styles.closeButtonLeft
    )}
    closeIcon={
      closeComponent ?? (
        <div className={clsx(styles.closeContainer)}>
          <span className="ciq-icon ciq-close" />
        </div>
      )
    }
    overlayClassName={styles.overlay}
    isOpen={isOpen}
    from={from}
    width={isMobile ? "100%" : width}
    onRequestClose={customCloseRequest}
  >
    {children}
  </SlidingPane>
);
