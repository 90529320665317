import { APP_DEVICE_MEDIA_QUERIES } from "@arbolus-technologies/models/common";
import clsx from "clsx";
import React, { ReactNode, useCallback, useState } from "react";
import Media from "react-media";

import InfiniteScroll from "../InfiniteScroll/InfiniteScroll";
import { Loader } from "../Loader/Loader";
import { UI_WINDOW_HEIGHT } from "../constants/UiConstants";

import styles from "./InfiniteScrollWithHeader.module.scss";

interface InfiniteScrollWithHeaderProps<T> {
  header?: JSX.Element;
  introItem?: ReactNode;
  items: T[] | null;
  itemHeight: number;
  isLoading: boolean;
  nextItems: (limit: number) => void;
  renderer: (item: T) => JSX.Element | null;
  children?: ReactNode;
  classnames?: string;
  rendererContainerClassnames?: string[] | string;
  isFixed?: boolean;
  smallMarginBottom?: boolean;
  overrideScrollHeight?: number;
  style?: React.CSSProperties;
}

export const InfiniteScrollWithHeader = <T,>({
  header,
  items,
  introItem,
  itemHeight,
  isLoading,
  nextItems,
  renderer,
  children,
  classnames,
  rendererContainerClassnames,
  isFixed = false,
  smallMarginBottom,
  overrideScrollHeight,
  style
}: InfiniteScrollWithHeaderProps<T>): JSX.Element => {
  const [limit, setLimit] = useState(0);

  const onInitialFetch = useCallback(
    (elementCount?: number) => {
      setLimit(elementCount || 0);
      if (!items?.length) {
        nextItems(elementCount || 0);
      }
    },
    [setLimit, nextItems, items]
  );

  return (
    <Media queries={APP_DEVICE_MEDIA_QUERIES}>
      {(): JSX.Element => (
        <InfiniteScroll
          onBottomReached={() => !isLoading && nextItems(limit)}
          onInitialFetch={onInitialFetch}
          bottomThreshold={10}
          elementHeight={itemHeight}
          overrideScrollHeight={overrideScrollHeight}
          style={
            style ?? {
              maxHeight: UI_WINDOW_HEIGHT,
              height: UI_WINDOW_HEIGHT,
              overflowX: "hidden"
            }
          }
          className={clsx("simplebar-light", styles.content, classnames)}
        >
          {header && (
            <div
              className={clsx(
                styles.header,
                {
                  [styles.isFixed]: isFixed
                },
                smallMarginBottom && styles.smallMarginBottom
              )}
            >
              {header}
            </div>
          )}
          {children}
          <div className={clsx(rendererContainerClassnames)}>
            {introItem}
            {items && items?.length > 0 && items?.map(renderer)}
            {!isLoading &&
              ((items && items.length === 0) || items === null) && (
                <span>No items</span>
              )}
          </div>
          {isLoading && <Loader />}
        </InfiniteScroll>
      )}
    </Media>
  );
};
