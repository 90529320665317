import React from "react";
import { useTranslation } from "react-i18next";

import { COMPLIANCE_QUESTION_IMPORTANCE } from "../../../../../../constants/project";
import { UtilsService } from "../../../../../../services";
import CompliancePill from "./compliancePill/CompliancePill";

interface ComplianceItemProps {
  question: string;
  answer?: string;
  textAnswer?: string;
  expectedAnswer?: string;
  importance?: string;
}

const ComplianceItem: React.FC<ComplianceItemProps> = ({
  question,
  answer,
  textAnswer,
  expectedAnswer,
  importance
}: ComplianceItemProps) => {
  const { t } = useTranslation("expertComplianceTab");

  const isHighImportance = importance === COMPLIANCE_QUESTION_IMPORTANCE.HIGH;
  const isAnswerMatch = !expectedAnswer || expectedAnswer === answer;

  return (
    <div className="compliance-item">
      <div className="row">
        <p className="question">
          {UtilsService.parseRichTextContent(question)}{" "}
          {isHighImportance && (
            <span className="question-importance ciq-icon ciq-ic-favourite-fill" />
          )}
        </p>
      </div>
      <div className="row">
        {answer && (
          <CompliancePill text={answer.toUpperCase()} isMatch={isAnswerMatch} />
        )}
        {expectedAnswer && (
          <CompliancePill
            text={t("preferredAnswer", {
              expectedAnswer: expectedAnswer.toUpperCase()
            })}
            isPreferred
          />
        )}
      </div>
      {textAnswer && <p className="text-answer">{textAnswer}</p>}
    </div>
  );
};

export default ComplianceItem;
