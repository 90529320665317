import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";

import { EXPERT_PROFILE_EDIT_INSIGHTS_ROUTE } from "@arbolus-technologies/routes";
import { useHistory } from "react-router";
import styles from "./EncouragementCard.module.scss";

export function EncouragementCard() {
  const { t } = useTranslation("expertProjectList");
  const history = useHistory();

  function redirectToProfileEdit() {
    history.push(EXPERT_PROFILE_EDIT_INSIGHTS_ROUTE);
  }

  return (
    <div className={styles.encouragementCard}>
      <h2>{t("encouragementTitle")}</h2>
      <p>{t("encouragementDescription")}</p>
      <div className={styles.redirectButton}>
        <Button
          text={t("encouragementButton")}
          type={"grey"}
          onClick={redirectToProfileEdit}
        />
      </div>
    </div>
  );
}
