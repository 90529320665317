/* spell-checker: disable */

import { LicenseManager } from "ag-grid-enterprise";
import "ag-grid-enterprise";

export const setupAgGridEnterprise = (): void => {
  LicenseManager.setLicenseKey(
    "Using_this_AG_Grid_Enterprise_key_( AG-046915 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Arbolus Technologies Spain S.L. )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Arbolus Platform )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Arbolus Platform )_need_to_be_licensed___( Arbolus Platform )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 22 September 2024 )____[v2]_MTcyNjk1OTYwMDAwMA==6395a10c808a6407cd8f44ab10dd0862"
  );
};
