import React from "react";
import { useTranslation } from "react-i18next";
import { Nav } from "reactstrap";

import { Tab } from "@arbolus-technologies/ui/components";

import { CLIENT_PROFILE_TABS } from "../type";

import styles from "./ClientUserTabHeader.module.scss";

interface TabsHeaderProps {
  activeTab: CLIENT_PROFILE_TABS;
  setActiveTab: (tabId: CLIENT_PROFILE_TABS) => void;
}

const { PROFILE, PROJECT, PERMISSIONS } = CLIENT_PROFILE_TABS;

export const ClientUserTabHeader: React.FC<TabsHeaderProps> = ({
  activeTab,
  setActiveTab
}) => {
  const { t } = useTranslation("clientUserSlidePanel");
  const tabs = [
    { tabId: PROFILE, text: t("profile") },
    { tabId: PROJECT, text: t("project") },
    { tabId: PERMISSIONS, text: t("permissions") }
  ];

  return (
    <div className={styles.nav}>
      <Nav pills>
        <div className={styles.tabs}>
          {tabs.map(({ tabId, text }) => (
            <Tab
              key={tabId}
              text={text}
              isActive={tabId === activeTab}
              onClick={() => setActiveTab(tabId)}
            />
          ))}
        </div>
      </Nav>
    </div>
  );
};
