import { Button } from "arbolus-ui-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  CanopyService,
  DefaultToasterService,
  ToasterService
} from "@arbolus-technologies/api";
import { CANOPY_STATUS } from "@arbolus-technologies/models/canopy";
import { CanopyV2StoreActions } from "@arbolus-technologies/stores/canopy-V2";
import { ModalWithChildren } from "@arbolus-technologies/ui/components";

import styles from "./PauseResumeCanopyButton.module.scss";

interface PauseResumeCanopyButtonProps {
  canopyId: string;
  status: CANOPY_STATUS.PAUSED | CANOPY_STATUS.ACTIVE;
  canopyService?: typeof CanopyService;
  notificationService?: ToasterService;
}

export const PauseResumeCanopyButton: React.FC<
  PauseResumeCanopyButtonProps
> = ({
  canopyId,
  status,
  canopyService = CanopyService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("canopyPauseButton");
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isActive = status === CANOPY_STATUS.ACTIVE;

  const pauseResumeCanopy = () => {
    setIsLoading(true);

    canopyService
      .changeCanopyStatus(
        canopyId,
        isActive ? CANOPY_STATUS.PAUSED : CANOPY_STATUS.ACTIVE
      )
      .subscribe({
        next: () => {
          setIsLoading(false);
          notificationService.showSuccess(
            isActive ? t("paused") : t("relaunched")
          );
          dispatch(
            CanopyV2StoreActions.changeCanopyStatus(
              isActive ? CANOPY_STATUS.PAUSED : CANOPY_STATUS.ACTIVE
            )
          );
        },
        error: (error) => {
          setIsLoading(false);
          notificationService.showApiErrors(error);
        }
      });
  };

  return (
    <>
      <ModalWithChildren
        className={styles.modal}
        toggleModal={showModal}
        confirmActionText={isActive ? t("yes") : t("yesResume")}
        cancelActionText={t("cancel")}
        onCancel={() => setShowModal(false)}
        isConfirmButtonDisabled={isLoading}
        customModalBody
        onConfirm={() => {
          pauseResumeCanopy();

          !isLoading && setShowModal(false);
        }}
      >
        <div className={styles.container}>
          <h2>{isActive ? t("areYouSurePause") : t("areYouSureResume")}</h2>
          <div className={styles.containerModal}>
            {isActive ? t("pauseDescription") : t("resumeDescription")}
          </div>
        </div>
      </ModalWithChildren>
      <Button
        onClick={() => {
          setShowModal(true);
        }}
        text={isActive ? t("pauseCanopy") : t("resumeCanopy")}
      />
    </>
  );
};
