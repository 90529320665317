import React from "react";
import { useTranslation } from "react-i18next";

import { hourglassIcon } from "@arbolus-technologies/theme";

import styles from "./RecommendationsPlaceholder.module.scss";

export const RecommendationsPlaceholder: React.FC = () => {
  const { t } = useTranslation("discoverPage");

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <img src={hourglassIcon} height="32px" width="32px" alt="hourglass" />
        <h1 className={styles.title}>{t("recommendationsTitle")}</h1>
      </div>
      <p className={styles.text}>{t("recommendationsText")}</p>
    </div>
  );
};
