import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import {
  CIQError,
  ChatService,
  DefaultToasterService,
  ErrorResponse
} from "@arbolus-technologies/api";
import { ProjectNxStoreActions } from "@arbolus-technologies/stores/project";

import { useChat } from "../../Contexts/ChatContext/ChatContext";

export function useDeleteMessage(): {
  deleteMessage: (messageId: string) => void;
  isDeleting: boolean;
} {
  const dispatch = useDispatch();
  const [isDeleting, setIsDeleting] = useState(false);
  const { projectId, currentChatId } = useChat();

  const deleteMessage = useCallback(
    (messageId: string) => {
      if (!projectId || !currentChatId) return;

      setIsDeleting(true);

      ChatService.deleteMessage(projectId, currentChatId, messageId).subscribe(
        (response) => {
          setIsDeleting(false);
          // This will trigger reload via Project epic
          dispatch(ProjectNxStoreActions.deleteChatMessageSuccess(response));
        },
        (error: ErrorResponse<CIQError>) => {
          setIsDeleting(false);
          DefaultToasterService.showApiErrors(error);
        }
      );
    },
    [projectId, currentChatId, dispatch]
  );

  return { deleteMessage, isDeleting };
}
