import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ProjectNotificationSubscription,
  ProjectService,
  ProjectSettings,
  ToasterService
} from "@arbolus-technologies/api";
import { LoaderOrComponent } from "@arbolus-technologies/ui/components";

import { validProjectSettings } from "../ProjectNotificationsSlidePanel/ExpertProjectNotificationsSlidePanel";
import { ExpertProjectNotificationsCheckboxForm } from "./ExpertProjectNotificationsCheckboxForm/ExpertProjectNotificationsCheckboxForm";

interface ExpertProjectNotificationsFormProps {
  projectId: string;
  notificationService?: ToasterService;
  projectService?: typeof ProjectService;
}

export const ExpertProjectNotificationsForm: React.FC<
  ExpertProjectNotificationsFormProps
> = ({
  projectId,
  notificationService = DefaultToasterService,
  projectService = ProjectService
}) => {
  const formName = "projectNotificationsForm";
  const { t } = useTranslation(formName);

  const [isLoading, setIsLoading] = useState(true);
  const [projectSettings, setProjectSettings] = useState<ProjectSettings>();

  useEffect(() => {
    setIsLoading(true);
    projectService.getProjectSettings(projectId).subscribe(
      (response) => {
        setProjectSettings(response);
        setIsLoading(false);
      },
      (error) => notificationService.showError(error)
    );
  }, [projectService, projectId, notificationService]);

  const handleSubmit = useCallback(
    (projectSettings: ProjectSettings) => {
      const updatedNotificationSubscription = Object.keys(
        projectSettings.notificationSubscription
      )
        .filter((notification) => validProjectSettings.includes(notification))
        .reduce((acc, key) => {
          acc[key as keyof ProjectNotificationSubscription] =
            projectSettings.notificationSubscription[
              key as keyof ProjectNotificationSubscription
            ];
          return acc;
        }, {} as ProjectNotificationSubscription);

      projectService
        .updateProjectSettings(projectId, {
          pushNotifications: projectSettings.pushNotifications,
          notificationSubscription: {
            ...updatedNotificationSubscription,
            MobileMessage:
              projectSettings.notificationSubscription.MobileMessage
          }
        })
        .subscribe(
          () => {
            notificationService.showSuccess(t("updateSuccess"));
            setProjectSettings(projectSettings);
          },
          (error: ErrorResponse<CIQError>) => {
            notificationService.showApiErrors(error);
          }
        );
    },
    [notificationService, projectId, projectService, t]
  );

  return (
    <LoaderOrComponent isLoading={isLoading}>
      {projectSettings && (
        <ExpertProjectNotificationsCheckboxForm
          formName={formName}
          projectSettings={projectSettings}
          onSave={handleSubmit}
        />
      )}
    </LoaderOrComponent>
  );
};
