import React from "react";
import { useTranslation } from "react-i18next";

import { CANOPY_FAQ_URL } from "@arbolus-technologies/models/canopy";
import { handshake, thinkingFace } from "@arbolus-technologies/theme";
import {
  ActionButton,
  InfoCookieBoxWithChildren,
  OrderedList
} from "@arbolus-technologies/ui/components";

import styles from "./NoSurvey.module.scss";

interface NoSurveyProps {
  handleNoSurveyClick: () => void;
}

export const NoSurvey: React.FC<NoSurveyProps> = ({ handleNoSurveyClick }) => {
  const { t } = useTranslation("surveyPane");
  const listSteps = [t("step1"), t("step2"), t("step3")];

  return (
    <InfoCookieBoxWithChildren cookie="canopyHelpBanner" withPadding>
      <div className={styles.container}>
        <div className={styles.firstContainer}>
          <div>
            <div className={styles.header}>
              <img className={styles.icon} src={handshake} alt="handshake" />
              <h1 className={styles.title}>{t("title1")}</h1>
            </div>
            <div className={styles.body}>
              <p className={styles.description}>{t("text1")}</p>
            </div>
          </div>
          <div className={styles.footer}>
            <ActionButton
              handleOnClick={handleNoSurveyClick}
              text={t("firstSurvey")}
              type="primary"
            />
          </div>
        </div>
        <div className={styles.secondContainer}>
          <div>
            <div className={styles.header}>
              <img
                className={styles.icon}
                src={thinkingFace}
                alt="thinkingFace"
              />
              <p className={styles.title}>{t("title2")}</p>
            </div>
            <div className={styles.body}>
              <OrderedList items={listSteps} />
            </div>
          </div>
          <div className={styles.footer}>
            <a
              target="_blank"
              rel="noreferrer"
              href={CANOPY_FAQ_URL}
              className={styles.helpLink}
            >
              {t("link")}
            </a>
          </div>
        </div>
      </div>
    </InfoCookieBoxWithChildren>
  );
};
