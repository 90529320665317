import {
  CIQError,
  Canopy,
  CanopyExpertsCount,
  CanopyQuestion,
  ErrorResponse
} from "@arbolus-technologies/api";
import { CANOPY_STATUS } from "@arbolus-technologies/models/canopy";
import { CanopyQuestionModel } from "@arbolus-technologies/models/canopy-panels";

import {
  ARCHIVE_CANOPY,
  ArchiveCanopyAction,
  CHANGE_CANOPY_STATUS,
  COPY_CANOPY_INVITATION_LINK_TO_CLIPBOARD,
  ChangeCanopyStatusAction,
  CopyCanopyInvitationLinkToClipboardAction,
  GET_CANOPY_CLIENT_V2,
  GET_CANOPY_CLIENT_V2_FAILURE,
  GET_CANOPY_CLIENT_V2_SUCCESS,
  GET_CANOPY_EXPERTS_COUNT,
  GET_CANOPY_EXPERTS_COUNT_FAILURE,
  GET_CANOPY_EXPERTS_COUNT_SUCCESS,
  GET_CANOPY_QUESTION,
  GET_CANOPY_QUESTIONS,
  GET_CANOPY_QUESTIONS_FAILURE,
  GET_CANOPY_QUESTIONS_SUCCESS,
  GET_CANOPY_QUESTION_FAILURE,
  GET_CANOPY_QUESTION_SUCCESS,
  GetCanopyClientFailureV2Action,
  GetCanopyClientSuccessV2Action,
  GetCanopyClientV2Action,
  GetCanopyExpertsCountAction,
  GetCanopyExpertsCountFailureAction,
  GetCanopyExpertsCountSuccessAction,
  GetCanopyQuestionAction,
  GetCanopyQuestionFailureAction,
  GetCanopyQuestionSuccessAction,
  GetCanopyQuestionsAction,
  GetCanopyQuestionsFailureAction,
  GetCanopyQuestionsSuccessAction,
  RESET_CANOPY_ANSWERS,
  RESET_CANOPY_DATA,
  RESET_CANOPY_QUESTION,
  RESET_CANOPY_V2,
  ResetCanopyAnswerAction,
  ResetCanopyDataAction,
  ResetCanopyQuestionAction,
  ResetCanopyV2Action
} from "./CanopyV2ActionTypes";

// Get Canopy by Client
export const getCanopyClientV2 = (
  canopyId: string
): GetCanopyClientV2Action => ({
  type: GET_CANOPY_CLIENT_V2,
  payload: {
    canopyId
  }
});

export const getCanopyClientV2Success = (
  canopyData: Canopy
): GetCanopyClientSuccessV2Action => ({
  type: GET_CANOPY_CLIENT_V2_SUCCESS,
  payload: { canopyData }
});

export const getCanopyClientV2Failure = (
  error: ErrorResponse<CIQError>
): GetCanopyClientFailureV2Action => ({
  type: GET_CANOPY_CLIENT_V2_FAILURE,
  payload: { error }
});

// Get Canopy Experts count
export const getCanopyExpertsCount = (
  canopyId: string
): GetCanopyExpertsCountAction => ({
  type: GET_CANOPY_EXPERTS_COUNT,
  payload: {
    canopyId
  }
});

export const getCanopyExpertsCountSuccess = (
  expertsCount: CanopyExpertsCount
): GetCanopyExpertsCountSuccessAction => ({
  type: GET_CANOPY_EXPERTS_COUNT_SUCCESS,
  payload: { expertsCount }
});

export const getCanopyExpertsCountFailure = (
  error: ErrorResponse<CIQError>
): GetCanopyExpertsCountFailureAction => ({
  type: GET_CANOPY_EXPERTS_COUNT_FAILURE,
  payload: { error }
});

// Get Canopy Questions
export const getCanopyQuestions = (
  canopyId: string
): GetCanopyQuestionsAction => ({
  type: GET_CANOPY_QUESTIONS,
  payload: {
    canopyId
  }
});

export const getCanopyQuestionsSuccess = (
  questions: CanopyQuestionModel[]
): GetCanopyQuestionsSuccessAction => ({
  type: GET_CANOPY_QUESTIONS_SUCCESS,
  payload: { questions }
});

export const getCanopyQuestionsFailure = (
  error: ErrorResponse<CIQError>
): GetCanopyQuestionsFailureAction => ({
  type: GET_CANOPY_QUESTIONS_FAILURE,
  payload: { error }
});

export const copyCanopyInvitationLinkToClipboard = (
  canopyId: string,
  successMessage: string
): CopyCanopyInvitationLinkToClipboardAction => ({
  type: COPY_CANOPY_INVITATION_LINK_TO_CLIPBOARD,
  payload: {
    canopyId,
    successMessage
  }
});

// GET CANOPY QUESTION

export const getCanopyQuestion = (
  questionId: string
): GetCanopyQuestionAction => ({
  type: GET_CANOPY_QUESTION,
  payload: { questionId }
});

export const getCanopyQuestionSuccess = (
  questionData: CanopyQuestion
): GetCanopyQuestionSuccessAction => ({
  type: GET_CANOPY_QUESTION_SUCCESS,
  payload: { questionData }
});

export const getCanopyQuestionFailure = (
  error: ErrorResponse<CIQError>
): GetCanopyQuestionFailureAction => ({
  type: GET_CANOPY_QUESTION_FAILURE,
  payload: { error }
});

export const resetCanopyQuestion = (): ResetCanopyQuestionAction => ({
  type: RESET_CANOPY_QUESTION
});

// RESET CANOPY V2

export const resetCanopyV2 = (): ResetCanopyV2Action => ({
  type: RESET_CANOPY_V2
});

export const resetCanopyData = (): ResetCanopyDataAction => ({
  type: RESET_CANOPY_DATA
});

export const resetCanopyAnswer = (): ResetCanopyAnswerAction => ({
  type: RESET_CANOPY_ANSWERS
});

export const archiveCanopy = (): ArchiveCanopyAction => ({
  type: ARCHIVE_CANOPY
});

export const changeCanopyStatus = (
  status: CANOPY_STATUS
): ChangeCanopyStatusAction => ({
  type: CHANGE_CANOPY_STATUS,
  payload: {
    status
  }
});

export const CanopyV2StoreActions = {
  getCanopyClientV2,
  getCanopyClientV2Success,
  getCanopyClientV2Failure,
  getCanopyExpertsCount,
  getCanopyExpertsCountSuccess,
  getCanopyExpertsCountFailure,
  getCanopyQuestions,
  getCanopyQuestionsSuccess,
  getCanopyQuestionsFailure,
  copyCanopyInvitationLinkToClipboard,
  getCanopyQuestion,
  getCanopyQuestionSuccess,
  getCanopyQuestionFailure,
  resetCanopyV2,
  resetCanopyData,
  archiveCanopy,
  resetCanopyAnswer,
  resetCanopyQuestion,
  changeCanopyStatus
};
