import { Eventcalendar, MbscCalendarEvent } from "@mobiscroll/react";
import clsx from "clsx";
import moment from "moment";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Col, Label, Row } from "reactstrap";

import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { DatePickerController } from "@arbolus-technologies/ui/components";
import {
  CALENDAR_DATE_TIME_FORMAT,
  SLOT_EDIT_DATE_FORMAT,
  TimezoneService,
  utilService
} from "@arbolus-technologies/utils";

import {
  DATE,
  END_TIME,
  ExpertAvailabilityPanelBase,
  ID,
  START_TIME
} from "./types";

import styles from "./DateTimeExpertAvailability.module.scss";

interface DateTimeExpertAvailabilityProps {
  eventInstance: Eventcalendar;
  expertTimezone: string;
}

export const DateTimeExpertAvailability: React.FC<
  DateTimeExpertAvailabilityProps
> = ({ eventInstance, expertTimezone }) => {
  const { t } = useTranslation("expertAvailabilityScheduler");
  const systemTimezone = useSelector(
    CacheSelector.appGuessCurrentTimeZoneSelector()
  );

  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    trigger,
    setError
  } = useFormContext<ExpertAvailabilityPanelBase>();

  const checkForOverlappingTimeSlot = () => {
    const values = getValues();
    const { start, end } = TimezoneService.convertDatesTimezone(
      `${moment(values[START_TIME]).format(CALENDAR_DATE_TIME_FORMAT)}`,
      `${moment(values[END_TIME]).format(CALENDAR_DATE_TIME_FORMAT)}`,
      systemTimezone.value,
      expertTimezone
    );

    const events = eventInstance
      .getEvents(start, end)
      .filter((e: MbscCalendarEvent) => e.id !== values[ID]);

    events.length > 0 &&
      setError(START_TIME, {
        type: "custom",
        message: t("overlapWarning")
      });
  };

  const onUpdateDate = async (date: Date) => {
    if (date) {
      const values = getValues();
      const startDateTime = utilService.mergeDateTime(date, values[START_TIME]);
      const endDateTime = utilService.mergeDateTime(date, values[END_TIME]);
      setValue(DATE, startDateTime);
      setValue(START_TIME, startDateTime);
      setValue(END_TIME, endDateTime);
      await updateErrors();
    }
  };

  const onUpdateStartTime = async (date: Date) => {
    if (date) {
      const startDateTime = utilService.mergeDateTime(getValues(DATE), date);
      const endDateTime = moment(startDateTime).add(1, "hour").toDate();

      setValue(DATE, startDateTime);
      setValue(START_TIME, startDateTime);
      setValue(END_TIME, endDateTime);
      await updateErrors();
    }
  };

  const updateErrors = async () => {
    await trigger([DATE, START_TIME, END_TIME]);
    checkForOverlappingTimeSlot();
  };

  return (
    <>
      <Row className={styles.row}>
        <Col md={12}>
          <Label>{t("expertAvailabilitySchedulerPanel.date")}</Label>
          <div>
            <span className={clsx("material-symbols-sharp", styles.icon)}>
              calendar_today
            </span>
            <DatePickerController
              name={DATE}
              control={control}
              error={errors[DATE]}
              dateFormat={SLOT_EDIT_DATE_FORMAT}
              minDate={moment().toDate()}
              maxDate={moment().add(13, "day").toDate()}
              customDateSelected={onUpdateDate}
              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                e.preventDefault();
              }}
            />
          </div>
        </Col>
      </Row>
      <Row className={styles.row}>
        <Col md={6}>
          <Label>{t("expertAvailabilitySchedulerPanel.startingTime")}</Label>
          <div>
            <span className={clsx("material-symbols-sharp", styles.icon)}>
              Schedule
            </span>
            <DatePickerController
              name={START_TIME}
              control={control}
              error={errors[START_TIME]}
              showTimeSelect={true}
              showTimeSelectOnly={true}
              timeIntervals={30}
              timeCaption="Time"
              dateFormat="hh:mm aa"
              customDateSelected={onUpdateStartTime}
              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                e.preventDefault();
              }}
            />
          </div>
        </Col>
        <Col md={6}>
          <Label>{t("expertAvailabilitySchedulerPanel.endingTime")}</Label>
          <div>
            <span className={clsx("material-symbols-sharp", styles.icon)}>
              Schedule
            </span>
            <DatePickerController
              name={END_TIME}
              control={control}
              error={errors[END_TIME]}
              showTimeSelect={true}
              showTimeSelectOnly={true}
              timeIntervals={30}
              timeCaption="Time"
              dateFormat="hh:mm aa"
              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                e.preventDefault();
              }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
