import { Icon } from "arbolus-ui-components";
import React from "react";
import ReactPlaceholder from "react-placeholder/lib";
import { Link } from "react-router-dom";

import {
  MixPanelActions,
  MixPanelEventNames,
  useMixpanel
} from "@arbolus-technologies/features/common";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { BoxWithIconTitleWithSubtitle } from "@arbolus-technologies/ui/components";

import { BaseNotification } from "./models/BaseNotification";
import { PlaceholderNotification } from "./models/PlaceholderNotification";

import styles from "./DashboardNotificationCard.module.scss";

interface DashboardNotificationCardProps {
  notification: BaseNotification;
  clearNotification: (notificationId: string) => void;
}

export const DashboardNotificationCard: React.FC<
  DashboardNotificationCardProps
> = ({ notification, clearNotification }) => {
  const { trackEvent } = useMixpanel();

  const handleClick = () => {
    trackEvent?.(MixPanelEventNames.DashboardNotification, {
      action: MixPanelActions.Clicked,
      button: "Dashboard Notification",
      notification
    });
  };

  return (
    <ReactPlaceholder
      type="media"
      ready={!(notification instanceof PlaceholderNotification)}
      showLoadingAnimation
      color="#e8e8eb"
    >
      <div className={styles.container}>
        <div className={styles.boxContainer}>
          <Link
            to={notification.link}
            className={styles.boxLink}
            onClick={handleClick}
          >
            <BoxWithIconTitleWithSubtitle
              title={notification.title}
              subtitle={notification.subtitle}
              icon={{
                iconName: notification.icon,
                color: ARBOLUS_COLORS.bColorBasePurple,
                background: "",
                border: `1px solid ${ARBOLUS_COLORS.bColorSecondaryGreyMid}`
              }}
            />
          </Link>
        </div>
        <div className={styles.iconContainer}>
          <Icon
            fontSize="24px"
            color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
            onClick={() => clearNotification(notification.id)}
            name="delete"
          />
        </div>
      </div>
    </ReactPlaceholder>
  );
};
