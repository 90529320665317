import { Handlers, createReducer } from "reduxsauce";

import { AngleLink } from "@arbolus-technologies/models/project";
import { maxLengthString } from "@arbolus-technologies/utils";

import {
  ADD_NEW_ANGLE,
  ADD_NEW_ANGLE_FAILURE,
  ADD_NEW_ANGLE_SUCCESS,
  ADD_SCREENING_QUESTIONS_VIEW,
  AddNewAngleFailureAction,
  EDIT_ANGLE,
  EDIT_ANGLE_FAILURE,
  EDIT_ANGLE_QUESTIONS_ADMIN,
  EDIT_ANGLE_QUESTIONS_ADMIN_FAILURE,
  EDIT_ANGLE_QUESTIONS_ADMIN_SUCCESS,
  EDIT_ANGLE_SUCCESS,
  EditAngleAction,
  EditAngleFailureAction,
  EditAngleQuestionsAdminSuccessAction,
  EditAngleSuccessAction,
  GET_ANGLES_CARDS,
  GET_ANGLES_CARDS_FAILURE,
  GET_ANGLES_CARDS_SUCCESS,
  GET_ANGLES_LINK,
  GET_ANGLES_LINK_FAILURE,
  GET_ANGLES_LINK_SUCCESS,
  GET_ANGLE_QUESTIONS,
  GET_ANGLE_QUESTIONS_FAILURE,
  GET_ANGLE_QUESTIONS_SUCCESS,
  GET_CLIENT_ID_BY_ADMIN,
  GET_SINGLE_ANGLE,
  GET_SINGLE_ANGLE_FAILURE,
  GET_SINGLE_ANGLE_SUCCESS,
  GetAngleQuestionsSuccessAction,
  GetAnglesCardsSuccessAction,
  GetAnglesLinkSuccessAction,
  GetClientIdByAdminAction,
  GetSingleAngleSuccessAction,
  NO_SCREENING_QUESTIONS_VIEW,
  RESET_ANGLES_LINK,
  RESET_SELECTED_ANGLE,
  SET_FORM_CREATE_EDIT_ANGLE_DISABLED,
  SET_FORM_CREATE_EDIT_ANGLE_ENABLED
} from "./actions/ProjectAnglesActionTypes";
import { ProjectAnglesReducerState } from "./definitions";

export const initialState: ProjectAnglesReducerState = {
  isAnglesCardsLoading: true,
  anglesCards: [],
  selectedAngle: {
    title: "",
    color: "",
    id: "",
    owner: {
      id: "",
      firstName: "",
      lastName: "",
      profileImageUrl: ""
    },
    expertDescription: "",
    expertCount: 0,
    hasScreeningQuestions: false,
    questions: [],
    isEdit: false,
    expertDetailsModifiedBy: {
      id: "",
      firstName: "",
      lastName: ""
    },
    expertDetailsModifiedOn: "",
    questionsModifiedBy: {
      id: "",
      firstName: "",
      lastName: ""
    },
    questionsModifiedOn: "",
    questionVersion: 0
  },
  angleLinks: null,
  isAngleLinksLoading: false,
  isSingleAngleLoading: false,
  clientIdByAdmin: "",
  isFormCreateEditAngleDisabled: false,
  isCreateAngleLoading: false,
  isEditAngleLoading: false,
  createAngleErrors: {
    message: "",
    status: ""
  },
  editAngleErrors: {
    message: "",
    status: ""
  },
  addScreeningQuestionsView: false,
  noScreeningQuestionsView: false
};

const handleGetAnglesCards = (
  state = initialState
): ProjectAnglesReducerState => ({
  ...state,
  isAnglesCardsLoading: true
});

const handleGetAnglesCardsSuccess = (
  state = initialState,
  { payload }: GetAnglesCardsSuccessAction
): ProjectAnglesReducerState => ({
  ...state,
  anglesCards: payload.anglesCards,
  isAnglesCardsLoading: false
});

const handleGetAnglesCardsFailure = (
  state = initialState
): ProjectAnglesReducerState => ({
  ...state,
  isAnglesCardsLoading: false
});

// GET ANGLE LINKS
const handleGetAngleLinks = (
  state = initialState
): ProjectAnglesReducerState => ({
  ...state,
  isAngleLinksLoading: true
});

const handleGetAngleLinksSuccess = (
  state = initialState,
  { payload }: GetAnglesLinkSuccessAction
): ProjectAnglesReducerState => {
  const anglesLinkResponse = payload.linksResponse.map((e: AngleLink) => ({
    value: e.invitationUrl,
    label: maxLengthString(e.title, 20),
    id: e.id
  }));

  return {
    ...state,
    angleLinks: payload.angleId
      ? anglesLinkResponse.filter((e) => e.id === payload.angleId)
      : anglesLinkResponse,
    isAngleLinksLoading: false
  };
};

const handleGetAngleLinksFailure = (
  state = initialState
): ProjectAnglesReducerState => ({
  ...state
});

const handleResetAngleLinks = (
  state = initialState
): ProjectAnglesReducerState => ({
  ...state,
  angleLinks: null
});

const getSingleAngle = (state = initialState): ProjectAnglesReducerState => ({
  ...state,
  isSingleAngleLoading: true
});

const getSingleAngleSuccess = (
  state = initialState,
  { payload }: GetSingleAngleSuccessAction
): ProjectAnglesReducerState => ({
  ...state,
  selectedAngle: {
    ...state.selectedAngle,
    ...payload.selectedAngle
  },
  isSingleAngleLoading: false,
  addScreeningQuestionsView: payload.selectedAngle.hasScreeningQuestions,
  noScreeningQuestionsView: !payload.selectedAngle.hasScreeningQuestions
});

const getSingleAngleFailure = (
  state = initialState
): ProjectAnglesReducerState => ({
  ...state,
  isSingleAngleLoading: false
});

const getAngleQuestions = (
  state = initialState
): ProjectAnglesReducerState => ({
  ...state,
  isSingleAngleLoading: true
});

const getAngleQuestionsSuccess = (
  state = initialState,
  { payload }: GetAngleQuestionsSuccessAction
): ProjectAnglesReducerState => {
  const {
    response: { questions, modifiedOn, modifiedBy, version }
  } = payload;

  const formattedQuestions = questions.map((q) => ({ question: q.question }));

  return {
    ...state,
    selectedAngle: {
      ...state.selectedAngle,
      questions: formattedQuestions,
      questionsModifiedOn: modifiedOn,
      questionsModifiedBy: {
        id: modifiedBy.id,
        firstName: modifiedBy.firstName,
        lastName: modifiedBy.lastName
      },
      questionVersion: version
    }
  };
};

const getAngleQuestionsFailure = (
  state = initialState
): ProjectAnglesReducerState => ({
  ...state,
  isSingleAngleLoading: false
});

const editAngleQuestionsAdmin = (
  state = initialState
): ProjectAnglesReducerState => ({
  ...state
});

const editAngleQuestionsAdminSuccess = (
  state = initialState,
  { payload }: EditAngleQuestionsAdminSuccessAction
): ProjectAnglesReducerState => {
  const { version, modifiedBy, modifiedOn, questions } = payload.response;
  return {
    ...state,
    selectedAngle: {
      ...state.selectedAngle,
      questionsModifiedBy: { ...modifiedBy },
      questionsModifiedOn: modifiedOn,
      questionVersion: version,
      questions
    }
  };
};

const editAngleQuestionsAdminFailure = (
  state = initialState
): ProjectAnglesReducerState => ({
  ...state
});

const getClientIdByAdmin = (
  state = initialState,
  { payload }: GetClientIdByAdminAction
): ProjectAnglesReducerState => ({
  ...state,
  clientIdByAdmin: payload.clientId
});

const editAngle = (
  state = initialState,
  { payload }: EditAngleAction
): ProjectAnglesReducerState => ({
  ...state,
  isEditAngleLoading: true
});

const editAngleSuccess = (
  state = initialState,
  { payload }: EditAngleSuccessAction
): ProjectAnglesReducerState => ({
  ...state,
  isEditAngleLoading: false,
  selectedAngle: {
    ...state.selectedAngle,
    ...payload.selectedAngle
  }
});

const editAngleFailure = (
  state = initialState,
  { payload }: EditAngleFailureAction
): ProjectAnglesReducerState => ({
  ...state,
  isEditAngleLoading: false,
  editAngleErrors: { ...payload.error }
});

const addNewAngle = (state = initialState): ProjectAnglesReducerState => ({
  ...state,
  isCreateAngleLoading: true
});

const addNewAngleSuccess = (
  state = initialState
): ProjectAnglesReducerState => ({
  ...state,
  isCreateAngleLoading: false,
  createAngleErrors: initialState.createAngleErrors
});

const addNewAngleFailure = (
  state = initialState,
  { payload }: AddNewAngleFailureAction
): ProjectAnglesReducerState => ({
  ...state,
  isCreateAngleLoading: false,
  createAngleErrors: { ...payload.error }
});

const resetSelectedAngle = (
  state = initialState
): ProjectAnglesReducerState => ({
  ...state,
  selectedAngle: initialState.selectedAngle,
  createAngleErrors: initialState.createAngleErrors
});

const setFormDisabled = (state = initialState): ProjectAnglesReducerState => ({
  ...state,
  isFormCreateEditAngleDisabled: true
});

const setFormEnabled = (state = initialState): ProjectAnglesReducerState => ({
  ...state,
  isFormCreateEditAngleDisabled: false,
  editAngleErrors: { ...initialState.editAngleErrors }
});

// CHANGE SCREENING QUESTIONS VIEW
const handleAddScreeningQuestionsView = (
  state = initialState
): ProjectAnglesReducerState => ({
  ...state,
  addScreeningQuestionsView: true,
  noScreeningQuestionsView: false
});

const handleNoScreeningQuestionsView = (
  state = initialState
): ProjectAnglesReducerState => ({
  ...state,
  addScreeningQuestionsView: false,
  noScreeningQuestionsView: true
});

// Current `Handlers` definition not good enough for proper typing
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handlers: Handlers<ProjectAnglesReducerState, any> = {
  [GET_ANGLES_CARDS]: handleGetAnglesCards,
  [GET_ANGLES_CARDS_SUCCESS]: handleGetAnglesCardsSuccess,
  [GET_ANGLES_CARDS_FAILURE]: handleGetAnglesCardsFailure,
  [GET_ANGLES_LINK]: handleGetAngleLinks,
  [GET_ANGLES_LINK_SUCCESS]: handleGetAngleLinksSuccess,
  [GET_ANGLES_LINK_FAILURE]: handleGetAngleLinksFailure,
  [RESET_ANGLES_LINK]: handleResetAngleLinks,
  [GET_SINGLE_ANGLE]: getSingleAngle,
  [GET_SINGLE_ANGLE_SUCCESS]: getSingleAngleSuccess,
  [GET_SINGLE_ANGLE_FAILURE]: getSingleAngleFailure,
  [GET_CLIENT_ID_BY_ADMIN]: getClientIdByAdmin,
  [GET_ANGLE_QUESTIONS]: getAngleQuestions,
  [GET_ANGLE_QUESTIONS_SUCCESS]: getAngleQuestionsSuccess,
  [GET_ANGLE_QUESTIONS_FAILURE]: getAngleQuestionsFailure,
  [EDIT_ANGLE_QUESTIONS_ADMIN]: editAngleQuestionsAdmin,
  [EDIT_ANGLE_QUESTIONS_ADMIN_SUCCESS]: editAngleQuestionsAdminSuccess,
  [EDIT_ANGLE_QUESTIONS_ADMIN_FAILURE]: editAngleQuestionsAdminFailure,
  [EDIT_ANGLE]: editAngle,
  [EDIT_ANGLE_SUCCESS]: editAngleSuccess,
  [EDIT_ANGLE_FAILURE]: editAngleFailure,
  [ADD_NEW_ANGLE]: addNewAngle,
  [ADD_NEW_ANGLE_SUCCESS]: addNewAngleSuccess,
  [ADD_NEW_ANGLE_FAILURE]: addNewAngleFailure,
  [SET_FORM_CREATE_EDIT_ANGLE_DISABLED]: setFormDisabled,
  [SET_FORM_CREATE_EDIT_ANGLE_ENABLED]: setFormEnabled,
  [RESET_SELECTED_ANGLE]: resetSelectedAngle,
  [ADD_SCREENING_QUESTIONS_VIEW]: handleAddScreeningQuestionsView,
  [NO_SCREENING_QUESTIONS_VIEW]: handleNoScreeningQuestionsView
};

export const ProjectAnglesReducer = createReducer(initialState, handlers);
