/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from "clsx";
import React, { Component } from "react";
import {
  Button,
  FormFeedback,
  Input,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";

interface CustomPasswordInputProps {
  form: any;
  field: any;
  disableErrorMessage: boolean;
}
interface CustomPasswordInputState {
  showPassword: boolean;
}

class CustomPasswordInput extends Component<
  CustomPasswordInputProps,
  CustomPasswordInputState
> {
  // eslint-disable-next-line react/static-property-placement
  public static defaultProps: Partial<CustomPasswordInputProps> = {
    disableErrorMessage: false
  };

  constructor(props: CustomPasswordInputProps) {
    super(props);

    this.state = {
      showPassword: false
    };
  }

  getPasswordInputType = (): string => {
    const { showPassword } = this.state;
    return showPassword ? "text" : "password";
  };

  render(): JSX.Element {
    const { showPassword } = this.state;
    const {
      field,
      form: { touched, errors },
      disableErrorMessage,
      ...otherProps
    } = this.props;

    return (
      <>
        <Input
          invalid={!!(touched[field.name] && errors[field.name])}
          {...field}
          {...otherProps}
          type={this.getPasswordInputType()}
        />
        <InputGroupAddon
          className={clsx({
            "is-invalid": touched[field.name] && errors[field.name]
          })}
          addonType="append"
        >
          <InputGroupText>
            <Button
              onClick={(): void => {
                this.setState({ showPassword: !showPassword });
              }}
              className="show-hide-btn"
            >
              <i
                className={clsx("ciq-icon", {
                  "ciq-view-fill-2": showPassword,
                  "ciq-not-visible": !showPassword
                })}
              />
            </Button>
          </InputGroupText>
        </InputGroupAddon>
        {!disableErrorMessage && touched[field.name] && errors[field.name] && (
          <FormFeedback>{errors[field.name]}</FormFeedback>
        )}
      </>
    );
  }
}

export default CustomPasswordInput;
