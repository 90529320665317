import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { AdminChatMode } from "@arbolus-technologies/models/project";
import { PROJECT_CHAT_ROUTE } from "@arbolus-technologies/routes";
import {
  CustomSwitch,
  CustomSwitchType,
  NotificationBadge
} from "@arbolus-technologies/ui/components";

import { ANGLE_DROPDOWN_DEFAULT_OPTION } from "../../../Constants/angle";
import { useChat } from "../../../Contexts/ChatContext/ChatContext";
import { getExpertSupportChats, isMobileChat } from "../utils";

import styles from "./AdminChatSwitch.module.scss";

interface AdminChatSwitchProps {
  projectId: string;
}

export const AdminChatSwitch: React.FC<AdminChatSwitchProps> = ({
  projectId
}) => {
  const { t } = useTranslation("chat");
  const history = useHistory();
  const {
    setActiveAdminMode,
    activeAdminMode,
    unreadMessagesCountByAdminMode,
    expertToArbolusChats,
    changeChat,
    projectChat
  } = useChat();
  const [isExpertModeActive, setIsExpertModeActive] = useState(
    activeAdminMode === AdminChatMode.ExpertSupport
  );
  const hasSupportChats = Object.keys(expertToArbolusChats ?? {}).length === 0;

  const handleModeChange = (mode: AdminChatMode) => {
    setActiveAdminMode(mode);
    if (!isMobileChat()) {
      if (mode === AdminChatMode.ClientChat && projectChat) {
        changeChat(projectChat);
        history.push(PROJECT_CHAT_ROUTE(projectId, projectChat.id));
      } else if (mode === AdminChatMode.ExpertSupport) {
        const defaultSupportChat = getExpertSupportChats({
          showAllAngles: true,
          selectedAngle: ANGLE_DROPDOWN_DEFAULT_OPTION,
          expertToArbolusChats
        })[0].chats[0];
        changeChat(defaultSupportChat);
        history.push(PROJECT_CHAT_ROUTE(projectId, defaultSupportChat.id));
      }
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsExpertModeActive(event?.target.checked);
    const mode = event?.target.checked
      ? AdminChatMode.ExpertSupport
      : AdminChatMode.ClientChat;
    handleModeChange(mode);
  };

  const isClientChat = activeAdminMode === AdminChatMode.ClientChat;
  const isSupportChat = activeAdminMode === AdminChatMode.ExpertSupport;

  const unreadCountClient =
    unreadMessagesCountByAdminMode[AdminChatMode.ClientChat];
  const unreadCountSupport =
    unreadMessagesCountByAdminMode[AdminChatMode.ExpertSupport];

  return (
    <div className={styles.adminChatSwitch}>
      <span className={isClientChat ? styles.active : styles.inactive}>
        {t("clientChatSwitch")}
      </span>
      {unreadCountClient > 0 && <NotificationBadge count={unreadCountClient} />}
      <CustomSwitch
        name="admin-chat-switch"
        checked={isExpertModeActive}
        onChange={handleOnChange}
        disabled={hasSupportChats}
        type={CustomSwitchType.ALTERNATIVES}
      />
      <span className={isSupportChat ? styles.active : styles.inactive}>
        {t("supportChatSwitch")}
      </span>
      {unreadCountSupport > 0 && (
        <NotificationBadge count={unreadCountSupport} />
      )}
    </div>
  );
};
