import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import {
  SquareWithIcon,
  SquareWithIconProps
} from "../SquareWithIcon/SquareWithIcon";

type BaseDocumentIconProps = Omit<
  SquareWithIconProps,
  "color" | "background" | "border"
>;

export type DocumentIconProps = Omit<BaseDocumentIconProps, "icon">;

const BlueIcon: React.FC<BaseDocumentIconProps> = (props) => (
  <SquareWithIcon
    {...props}
    color={ARBOLUS_COLORS.bColorBaseWhite}
    background={ARBOLUS_COLORS.bColorBaseBlue}
  />
);

const RedIcon: React.FC<BaseDocumentIconProps> = (props) => (
  <SquareWithIcon
    {...props}
    color={ARBOLUS_COLORS.bColorBaseWhite}
    background={ARBOLUS_COLORS.bColorBaseOrange}
  />
);

const YellowIcon: React.FC<BaseDocumentIconProps> = (props) => (
  <SquareWithIcon
    {...props}
    color={ARBOLUS_COLORS.bColorBaseWhite}
    background={ARBOLUS_COLORS.bColorSecondaryAmber}
  />
);

const GreenIcon: React.FC<BaseDocumentIconProps> = (props) => (
  <SquareWithIcon
    {...props}
    color={ARBOLUS_COLORS.bColorBaseWhite}
    background={ARBOLUS_COLORS.bColorAccentGreenDark}
  />
);

const GrayIcon: React.FC<BaseDocumentIconProps> = (props) => (
  <SquareWithIcon
    {...props}
    color={ARBOLUS_COLORS.bColorBaseWhite}
    background={ARBOLUS_COLORS.bColorSecondaryGreyDark}
  />
);

export const DocIcon: React.FC<DocumentIconProps> = (props) => (
  <BlueIcon {...props} icon="description" />
);

export const LinkIcon: React.FC<DocumentIconProps> = (props) => (
  <BlueIcon {...props} icon="link" />
);

export const TranscriptIcon: React.FC<DocumentIconProps> = (props) => (
  <BlueIcon {...props} icon="speech_to_text" />
);

export const CallIcon: React.FC<DocumentIconProps> = (props) => (
  <BlueIcon {...props} icon="calendar_today" />
);

export const PdfIcon: React.FC<DocumentIconProps> = (props) => (
  <RedIcon {...props} icon="picture_as_pdf" />
);

export const PptIcon: React.FC<DocumentIconProps> = (props) => (
  <YellowIcon {...props} icon="width_full" />
);

export const ExcelIcon: React.FC<DocumentIconProps> = (props) => (
  <GreenIcon {...props} icon="table_chart" />
);

export const PictureIcon: React.FC<DocumentIconProps> = (props) => (
  <GrayIcon {...props} icon="image" />
);

export const ZipIcon: React.FC<DocumentIconProps> = (props) => (
  <GrayIcon {...props} icon="folder_zip" />
);

export const GenericAttachmentIcon: React.FC<DocumentIconProps> = (props) => (
  <GrayIcon {...props} icon="attach_file" />
);
