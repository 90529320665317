import { GridApi, IRowNode } from "ag-grid-community";
import i18next from "i18next";
import moment from "moment";
import { forkJoin } from "rxjs";

import {
  DefaultReferralsService,
  DefaultToasterService,
  IReferralsService,
  Project,
  ToasterService
} from "@arbolus-technologies/api";
import { IModalService } from "@arbolus-technologies/features/common";
import {
  APPLICATION_STATUS,
  COMPLIANCE_STATUS,
  REFERRAL_SUB_STATUS,
  Referral
} from "@arbolus-technologies/models/common";
import { ExtractMethods, isValueTruthy } from "@arbolus-technologies/utils";

import { ReloadReferralsCount } from "../../Models/ReferralsTable";
import { BulkBaseService, ForkJoinResults } from "./BulkBaseService";

const t = (key: string) => i18next.t(`referrals:actions:${key}`);

export type IApproveReferralService = ExtractMethods<ApproveReferralService>;

export class ApproveReferralService extends BulkBaseService {
  private _referralsService: IReferralsService;

  constructor(
    referralsService: IReferralsService,
    toasterService: ToasterService
  ) {
    super(toasterService);
    this._referralsService = referralsService;
  }

  public approve(
    project: Project,
    referrals: Referral[],
    modalService: IModalService,
    api: GridApi,
    reloadReferralsCount: ReloadReferralsCount
  ): void {
    const onConfirm = () => {
      const candidateRequest = (referral: Referral) =>
        this._referralsService.approve(project.id, referral.expert.expertId);
      const next = (results: ForkJoinResults) => {
        this.next(
          results,
          referrals,
          (results) => {
            this.updateApprovedNodes(api, results, project.hasComplianceCheck);
            reloadReferralsCount(project.id);
          },
          t("approveExpertSuccess"),
          modalService
        );
      };
      const requests = this.createObservables(referrals, candidateRequest);
      forkJoin(requests).subscribe({
        next,
        error: this._toasterService.showApiErrors
      });
    };
    modalService.openMainModal({
      title: t("approveExpertTitle"),
      subtitle: t("approveExpertSubtitle"),
      confirmText: t("approveExpertConfirmText"),
      type: "confirmation",
      onConfirm
    });
  }

  private updateApprovedNodes(
    api: GridApi,
    results: ForkJoinResults,
    hasCompliance: boolean
  ): void {
    const update: Referral[] = [];
    const remove: Referral[] = [];
    const modified = new Date(moment.utc().format());
    const rowNodes: IRowNode<Referral>[] = Object.keys(results)
      .map((id) => api.getRowNode(id))
      .filter(isValueTruthy);
    rowNodes.forEach((rowNode) => {
      if (
        rowNode.data!.application === APPLICATION_STATUS.ACCEPT &&
        (!hasCompliance ||
          rowNode.data!.compliance === COMPLIANCE_STATUS.ACCEPT)
      ) {
        remove.push(rowNode.data!);
        return;
      }

      rowNode.setSelected(false);
      const application =
        rowNode.data!.application === APPLICATION_STATUS.ACCEPT
          ? APPLICATION_STATUS.ACCEPT
          : APPLICATION_STATUS.SENT;
      update.push(
        Referral.fromObject({
          ...rowNode.data!,
          application,
          modified,
          review: REFERRAL_SUB_STATUS.ACCEPT
        })
      );
    });

    api.applyTransaction({
      update,
      remove
    });
  }
}

export const DefaultApproveReferralService = new ApproveReferralService(
  DefaultReferralsService,
  DefaultToasterService
);
