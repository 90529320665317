import { LocationDescriptorObject } from "history";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  AngleModel,
  EventModel,
  ExpertTaglineModel
} from "@arbolus-technologies/models/common";
import { transformedDateTime } from "@arbolus-technologies/utils";

import { AssignedAngle } from "../AssignedAngle/AssignedAngle";
import { ChevronButton } from "../Buttons/ChevronButton/ChevronButton";
import { UserPortrait } from "../Portraits/components/UserPortrait/UserPortrait";
import { PublicCompanyIcon } from "../SquareWithIcon/Wrappers/PublicCompanyIcon";

import styles from "./EventCard.module.scss";

interface EventCardProps {
  event: EventModel;
  angle?: AngleModel;
  expert?: ExpertTaglineModel;
  onClick: () => void;
  toExpertProfile?: LocationDescriptorObject | string;
}

export const EventCard: React.FC<EventCardProps> = ({
  event,
  angle,
  expert,
  onClick,
  toExpertProfile
}) => {
  const { t } = useTranslation("eventCard");

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {angle && <AssignedAngle angle={angle} />}
        <p className={styles.slot}>
          {transformedDateTime({
            startTime: event.startTime,
            endTime: event.endTime,
            timezone: event.timezone
          })}
        </p>
      </div>
      <div className={styles.user}>
        <div className={styles.userContainer}>
          {expert ? (
            <Link to={toExpertProfile!} target="_blank">
              <UserPortrait
                user={{ ...expert, title: expert.tagline }}
                icon={
                  (expert.lastPublicCompanyExpDate !== null && (
                    <PublicCompanyIcon date={expert.lastPublicCompanyExpDate} />
                  )) ||
                  undefined
                }
              />
            </Link>
          ) : (
            <p className="m-0 text-left">{event.title}</p>
          )}
        </div>
        <div className={styles.joinButton}>
          <ChevronButton
            text={t("joinCall")}
            icon={{ direction: "right", position: "end" }}
            onClick={onClick}
          />
        </div>
      </div>
    </div>
  );
};
