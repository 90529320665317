import { GridApi } from "ag-grid-community";
import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { Referral } from "@arbolus-technologies/models/common";

import {
  SchedulingContext,
  SchedulingDispatchContext
} from "../scheduling/SchedulingContext";

import styles from "./StatusPanel.module.scss";

interface CustomStatusPanelProps {
  api: GridApi<Referral>;
}

export const SchedulingViewStatusPanel: React.FC<CustomStatusPanelProps> = ({
  api
}) => {
  const { t } = useTranslation("referrals", { keyPrefix: "schedulingView" });
  const state = React.useContext(SchedulingContext);
  const dispatch = React.useContext(SchedulingDispatchContext)!;
  const isExpert = state === "expert";

  return (
    <div className="flex-row-8 h-100">
      <div
        onClick={isExpert ? undefined : dispatch}
        className={clsx(styles.button, { [styles.active]: isExpert })}
      >
        <Icon name="person" fontSize="18px" tooltip={t("experts")} />
        <span>{t("experts")}</span>
      </div>
      <div
        onClick={isExpert ? dispatch : undefined}
        className={clsx(styles.button, { [styles.active]: !isExpert })}
      >
        <Icon name="calendar_today" fontSize="18px" tooltip={t("slots")} />
        <span>{t("slots")}</span>
      </div>
    </div>
  );
};
