import { ColDef, GridApi, GridReadyEvent } from "ag-grid-community";
import clsx from "clsx";
import React from "react";
import { useSelector } from "react-redux";

import {
  DefaultReferralsService,
  DefaultToasterService,
  IReferralsService,
  ToasterService
} from "@arbolus-technologies/api";
import { Referral } from "@arbolus-technologies/models/common";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { ArbolusGrid } from "@arbolus-technologies/ui/components";

import { IContextMenuService } from "../../Services/Referrals/ContextMenuService";
import { DefaultRejectedMenuService } from "../../Services/Referrals/RejectedMenuService";
import { actionsCol } from "./columns/ActionColumns";
import { angleCol, taglineCol } from "./columns/ColumnDefinitions";
import { rejectedDateCol } from "./columns/DateColumns";
import { expertNameCol } from "./columns/ExpertColumn";
import { rejectReasonCol } from "./columns/RejectReasonColumn";
import { applicationCircleCol, complianceCol } from "./columns/StatusColumns";
import { useDefaultReferralGridColumns } from "./hooks/useDefaultReferralGridColumns";
import { useReferralGridContext } from "./hooks/useReferralGridContext";
import { commonProps } from "./referralsTableCommonProps";

import styles from "./ReferralsTable.module.scss";

interface RejectedTableProps {
  referralsService?: IReferralsService;
  notificationService?: ToasterService;
  contextMenuService?: IContextMenuService;
}

export const RejectedTable: React.FC<RejectedTableProps> = ({
  referralsService = DefaultReferralsService,
  notificationService = DefaultToasterService,
  contextMenuService = DefaultRejectedMenuService
}) => {
  const [api, setApi] = React.useState<GridApi<Referral>>();
  const project = useSelector(ProjectNxSelector.projectData());
  const [referrals, setReferrals] = React.useState<Referral[]>();

  const defaultColDef = useDefaultReferralGridColumns();
  const gridContext = useReferralGridContext(api);

  const onGridReady = React.useCallback(
    ({ api }: GridReadyEvent<Referral>) => {
      setApi(api);
      referralsService.getReject(project!.id).subscribe({
        next: setReferrals,
        error: notificationService.showApiErrors
      });
    },
    [notificationService, project, referralsService]
  );

  const columnDefs = React.useMemo<ColDef<Referral>[]>(() => {
    const cols = [
      { ...expertNameCol, checkboxSelection: false },
      taglineCol,
      rejectReasonCol,
      angleCol,
      rejectedDateCol,
      applicationCircleCol,
      actionsCol
    ];
    if (project?.hasComplianceCheck) {
      cols.splice(6, 0, complianceCol);
    }

    return cols;
  }, [project]);

  return (
    <div className="d-flex h-100 flex-column">
      <div className={clsx(styles.statusBarOnTop, "h-100")}>
        <ArbolusGrid
          {...commonProps}
          onGridReady={onGridReady}
          context={gridContext}
          rowData={referrals}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          getContextMenuItems={contextMenuService.getItems}
        />
      </div>
    </div>
  );
};
