import { LocationDescriptorObject } from "history";
import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { LinkBoxButton } from "../BoxButton/LinkBoxButton";

interface ClientDashboardButtonProps {
  to: LocationDescriptorObject;
  clientName: string;
}

export const ClientDashboardButton: React.FC<ClientDashboardButtonProps> = ({
  to,
  clientName
}) => (
  <LinkBoxButton
    text={clientName}
    icon={{
      color: ARBOLUS_COLORS.bColorSecondaryGreyDark,
      background: ARBOLUS_COLORS.bColorSecondaryGreyLight,
      name: "domain"
    }}
    to={to}
    chevronColor={ARBOLUS_COLORS.bColorBasePurple}
  />
);
