import {
  CanopyQuestion,
  CreateEditCanopyQuestion
} from "@arbolus-technologies/api";
import {
  ANSWER_PREFERENCES_TYPE,
  ANSWER_TYPE,
  AnswersRange,
  CanopyQuestionFields,
  CanopyQuestionMultiChoice,
  CanopyQuestionValue,
  Choice,
  FormConstraints
} from "@arbolus-technologies/models/canopy";

export const rangeValuesForMultiChoice = (
  data: CanopyQuestionMultiChoice
): { minimum: number; maximum: number } => {
  const {
    answerPreferencesType,
    answerPreferencesNumberExact,
    answerChoices,
    answerPreferencesNumberInitialRange,
    answerPreferencesNumberFinalRange
  } = data;
  if (answerPreferencesType === ANSWER_PREFERENCES_TYPE.EXACT) {
    return {
      minimum: answerPreferencesNumberExact,
      maximum: answerPreferencesNumberExact
    };
  }

  if (answerPreferencesType === ANSWER_PREFERENCES_TYPE.UNLIMITED) {
    return {
      minimum: FormConstraints.MIN_ANSWER_CHOICES,
      maximum: answerChoices.length
    };
  }

  if (answerPreferencesType === ANSWER_PREFERENCES_TYPE.RANGE) {
    return {
      minimum: answerPreferencesNumberInitialRange,
      maximum: answerPreferencesNumberFinalRange
    };
  }

  return {
    minimum: answerPreferencesNumberExact,
    maximum: answerPreferencesNumberExact
  };
};

export const prepareCanopyRequest = (
  data: CanopyQuestionFields,
  canopyId: string
): CreateEditCanopyQuestion => {
  const preparedCanopyRequest = {
    canopyId,
    order: data.order,
    text: data.question,
    details: data.details,
    type: data.answerType,
    questionId: data.id ?? undefined
  };

  switch (data.answerType) {
    case ANSWER_TYPE.MULTIPLE_CHOICE: {
      const multiChoiceData = data as CanopyQuestionMultiChoice;
      const multiChoiceRangeValues = rangeValuesForMultiChoice(multiChoiceData);

      return {
        ...preparedCanopyRequest,
        maximumRange: multiChoiceRangeValues.maximum,
        minimumRange: multiChoiceRangeValues.minimum,
        optionChoices: multiChoiceData.answerChoices
      };
    }
    case ANSWER_TYPE.VALUE: {
      const valueData = data as CanopyQuestionValue;

      return {
        ...preparedCanopyRequest,
        valuePreferencesType: valueData.answerPreferencesTypeValue,
        minimumRange: valueData.answerPreferencesTypeValueNumberInitialRange,
        maximumRange: valueData.answerPreferencesTypeValueNumberFinalRange,
        multiRatingChoices:
          valueData.answerPreferencesTypeValueMultiRatingChoices
      };
    }
    default: {
      return preparedCanopyRequest;
    }
  }
};

export const prepareEditQuestion = (
  question: CanopyQuestion
): CanopyQuestion => {
  if (question.type === ANSWER_TYPE.MULTIPLE_CHOICE) {
    const preferenceType: ANSWER_PREFERENCES_TYPE =
      handleAnswerPreferenceType(question);

    const rangeNumbers: AnswersRange = answerPreferenceRangeNumbers(
      question,
      preferenceType
    );

    return {
      ...question,
      preferenceType: preferenceType,
      exactNumber:
        question.answersRange?.maximum ?? FormConstraints.MAX_ANSWER_CHOICES,
      answersRange: {
        minimum: rangeNumbers.minimum,
        maximum: rangeNumbers.maximum
      }
    };
  }
  return question;
};

export const handleAnswerPreferenceType = (
  question: CanopyQuestion
): ANSWER_PREFERENCES_TYPE => {
  const { answersRange, optionChoices } = question;

  if (answersRange && optionChoices) {
    const { minimum, maximum } = answersRange;

    if (minimum === maximum) {
      return ANSWER_PREFERENCES_TYPE.EXACT;
    } else if (minimum === 1 && maximum === optionChoices.length) {
      return ANSWER_PREFERENCES_TYPE.UNLIMITED;
    }
    return ANSWER_PREFERENCES_TYPE.RANGE;
  }

  return ANSWER_PREFERENCES_TYPE.EXACT;
};

export const answerPreferenceRangeNumbers = (
  question: CanopyQuestion,
  preferenceType: ANSWER_PREFERENCES_TYPE
): AnswersRange => {
  const { answersRange, optionChoices } = question;

  if (answersRange && optionChoices) {
    if (preferenceType === ANSWER_PREFERENCES_TYPE.RANGE) {
      return {
        minimum: answersRange.minimum,
        maximum: answersRange.maximum
      };
    }
    // When default value is not range, return length - 1 as min and choices length as max
    // to fit range numbers when coming from unlimited or exact types
    return {
      minimum: optionChoices.length - 1 || FormConstraints.MIN_ANSWER_CHOICES,
      maximum: optionChoices?.length || FormConstraints.MAX_ANSWER_CHOICES
    };
  }

  return {
    minimum: FormConstraints.MIN_ANSWER_CHOICES,
    maximum: FormConstraints.MAX_ANSWER_CHOICES
  };
};

export const isMinNumberOfOptionsValid = ({
  answerType,
  answerChoices,
  answerPreferencesType,
  answerExactNumber,
  answerFinalRangeNumber
}: {
  answerType: ANSWER_TYPE;
  answerChoices: Choice[];
  answerPreferencesType: ANSWER_PREFERENCES_TYPE;
  answerExactNumber: number;
  answerFinalRangeNumber: number;
}): boolean => {
  if (answerType === ANSWER_TYPE.MULTIPLE_CHOICE) {
    const answerChoicesLength = answerChoices.length;

    if (answerPreferencesType === ANSWER_PREFERENCES_TYPE.EXACT) {
      return answerChoicesLength > answerExactNumber;
    }

    if (answerPreferencesType === ANSWER_PREFERENCES_TYPE.UNLIMITED) {
      return answerChoicesLength >= FormConstraints.MIN_CHOICES;
    }

    if (answerPreferencesType === ANSWER_PREFERENCES_TYPE.RANGE) {
      return answerChoicesLength >= answerFinalRangeNumber;
    }
  }
  return true;
};
