import React, { ReactNode } from "react";

import {
  AccessControlContextConsumer,
  AccessControlContextProps
} from "./AccessControlContext";
import accessControlRules from "./rbac-rules";

interface AccessManagerProps {
  permission: string;
  deniedElement?: ReactNode;
  children: ReactNode;
}

const permit = (action: string, role: string): boolean => {
  const userPermissions = accessControlRules[role];
  if (!userPermissions) {
    return false;
  }

  return userPermissions.includes(action);
};

const AccessManager: React.FC<AccessManagerProps> = ({
  permission,
  deniedElement = <></>,
  children
}) => (
  <AccessControlContextConsumer>
    {({ role }: AccessControlContextProps): ReactNode =>
      permit(permission, role) ? children : deniedElement
    }
  </AccessControlContextConsumer>
);

export default AccessManager;
