import { Button } from "arbolus-ui-components";
import React from "react";
import { useHistory } from "react-router";

import { ChatUserRole } from "@arbolus-technologies/models/project";
import { PROJECT_ROUTE } from "@arbolus-technologies/routes";
import { Loader } from "@arbolus-technologies/ui/components";

import { AdminChatSwitch } from "../../../Components/Chat/AdminChatSwitch/AdminChatSwitch";
import { ChatContent } from "../../../Components/Chat/ChatContent/ChatContent";
import { ChatList } from "../../../Components/Chat/ChatList/ChatList";
import { EmptyChatGuard } from "../../../Components/Chat/EmptyChatGuard/EmptyChatGuard";
import { ExpertChatList } from "../../../Components/Chat/ExpertChatList/ExpertChatList";
import { useChat } from "../../../Contexts/ChatContext/ChatContext";

import styles from "./DesktopTabletChatPage.module.scss";

// Exported ONLY for testing purposes
export const DesktopTabletChatPage: React.FC<{
  projectId: string;
  handleOpenExpertSidePanel: (expertId: string, referralId: string) => void;
}> = ({ projectId, handleOpenExpertSidePanel }) => {
  const history = useHistory();
  const { chatSenderRole, isLoading, projectData, isChatEnabled } = useChat();
  // When chat is disabled only the admin mode is available - we can hide the switch
  const showAdminSwitch =
    chatSenderRole === ChatUserRole.Admin && isChatEnabled;

  if (isLoading) return <Loader isFull />;

  return (
    <div className={styles.wrapper}>
      <EmptyChatGuard>
        <div className={styles.page}>
          <div className={styles.header}>
            {projectData && (
              <Button
                text={projectData.name}
                type="tertiary"
                onClick={() => history.push(PROJECT_ROUTE(projectData.id))}
                startIcon="chevron_left"
              />
            )}
            {showAdminSwitch && <AdminChatSwitch projectId={projectId} />}
          </div>
          <div className={styles.sidePanel}>
            {chatSenderRole === ChatUserRole.Expert ? (
              <ExpertChatList projectId={projectId} />
            ) : (
              <ChatList projectId={projectId} />
            )}
          </div>
          <div className={styles.content}>
            <ChatContent
              handleOpenExpertSidePanel={handleOpenExpertSidePanel}
            />
          </div>
        </div>
      </EmptyChatGuard>
    </div>
  );
};
