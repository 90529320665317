/* spell-checker: disable */

import i18next from "i18next";
import moment from "moment";

import { APP_DATE_FORMAT_WITH_DAY } from "./constants/DateConstants";

export const getFirstDayOfMonth = (date: Date | undefined = new Date()): Date =>
  new Date(date.getFullYear(), date.getMonth(), 1);

export const getFirstDayOfLastMonth = (
  date: Date | undefined = new Date()
): Date => new Date(date.getFullYear(), date.getMonth() - 1, 1);

export const getLastMillisecondOfCurrentMonth = (): Date =>
  moment()
    .startOf("month")
    .add(1, "month")
    .subtract(1, "milliseconds")
    .toDate();

export const formatDate = (
  date: Date | string,
  format: string = APP_DATE_FORMAT_WITH_DAY
): string => moment(date).format(format);

export const daysFromToday = (days: number): Date =>
  moment().add(days, "days").toDate();

export const daysToToday = (days: number): Date =>
  moment().subtract(days, "days").toDate();

export const monthsToToday = (months: number): Date =>
  moment().subtract(months, "months").toDate();

export const monthsBetweenDates = (date1: Date, date2: Date): number =>
  moment(date1).diff(moment(date2), "months");

export const monthsFromDate = (date: Date, number: number): Date =>
  moment(date).add(number, "months").toDate();

export const monthsFromToday = (months: number): Date =>
  monthsFromDate(new Date(), months);

// Ouputs:
// Today, 8:30 PM
// Tomorrow, 8:30 PM
// Yesterday, 8:30 PM
// Then, if current date is same:
//   - month: Mon 8th, 8:30 PM
//   - year: Mon 8th Jan, 8:30 PM
// Default: Mon 8th Jan 2018, 8:30 PM
export const formatDateBasedOnToday = (stringDate: string): string => {
  const currentDate = moment.utc().local();
  const momentDate = moment.utc(stringDate).local();

  if (momentDate.isSame(currentDate, "day")) {
    return `Today, ${momentDate.format("LT")}`;
  }

  if (momentDate.isSame(moment.utc().local().subtract(1, "days"), "day")) {
    return `Yesterday, ${momentDate.format("LT")}`;
  }

  if (momentDate.isSame(moment.utc().local().add(1, "days"), "day")) {
    return `Tomorrow, ${momentDate.format("LT")}`;
  }

  if (!momentDate.isSame(currentDate, "year")) {
    return momentDate.format("ddd Do MMM YYYY, LT");
  }

  if (!momentDate.isSame(currentDate, "month")) {
    return momentDate.format("ddd Do MMM, LT");
  }

  return momentDate.format("ddd Do, LT");
};

interface TransformedDateTime {
  startTime: string;
  endTime?: string;
  timezone: string;
  showMonth?: boolean;
}

export const transformedDateTime = ({
  startTime,
  endTime,
  timezone,
  showMonth = false
}: TransformedDateTime): string => {
  // Create Moment objects for the start and end dates
  const startMoment = moment(startTime).tz(timezone);

  // Create a Moment object for today's date
  const todayMoment = moment().tz(timezone).startOf("day");

  // Calculate the difference in days between today and the start date
  const daysDifference = startMoment.diff(todayMoment, "days");

  // Determine the display format based on the difference in days
  let displayFormat = "";

  if (daysDifference === 0) {
    displayFormat = "Today";
  } else if (daysDifference === 1) {
    displayFormat = "Tomorrow";
  } else {
    displayFormat = startMoment.format(showMonth ? "ddd DD MMM" : "ddd DD");
  }

  // Format the time range
  let formattedTime = `${startMoment.format("hh:mm a")}`;
  if (endTime) {
    const endMoment = moment(endTime).tz(timezone);
    formattedTime += ` - ${endMoment.format("hh:mm a")}`;
  }

  // Create the final transformed date and time string
  const transformedDateTime = `${displayFormat}, ${formattedTime}`;
  return transformedDateTime;
};

interface ElapsedTime {
  elapsedTimeUnits: number | null;
  elapsedTimeName: string;
}

export const getElapsedTimePeriod = (date: Date | null): ElapsedTime => {
  if (date) {
    const currentDate = moment(new Date());
    const referenceDate = moment(date);
    const elapsedDays = currentDate.diff(referenceDate, "days");
    const elapsedWeeks = currentDate.diff(referenceDate, "weeks");
    const elapsedMonths = currentDate.diff(referenceDate, "months");
    const elapsedYears = currentDate.diff(referenceDate, "years");

    if (elapsedDays === 0) {
      return {
        elapsedTimeUnits: elapsedDays,
        elapsedTimeName: i18next.t("common:dates:today")
      };
    } else if (elapsedDays === 1) {
      return {
        elapsedTimeUnits: 1,
        elapsedTimeName: i18next.t("common:dates:day")
      };
    } else if (elapsedDays < 7) {
      return {
        elapsedTimeUnits: elapsedDays,
        elapsedTimeName: i18next.t("common:dates:days")
      };
    } else if (elapsedWeeks === 1) {
      return {
        elapsedTimeUnits: 1,
        elapsedTimeName: i18next.t("common:dates:week")
      };
    } else if (elapsedWeeks < 4) {
      return {
        elapsedTimeUnits: elapsedWeeks,
        elapsedTimeName: i18next.t("common:dates:weeks")
      };
    } else if (elapsedMonths === 1) {
      return {
        elapsedTimeUnits: 1,
        elapsedTimeName: i18next.t("common:dates:month")
      };
    } else if (elapsedMonths < 12) {
      return {
        elapsedTimeUnits: elapsedMonths,
        elapsedTimeName: i18next.t("common:dates:months")
      };
    } else if (elapsedYears === 1) {
      return {
        elapsedTimeUnits: 1,
        elapsedTimeName: i18next.t("common:dates:year")
      };
    } else {
      return {
        elapsedTimeUnits: elapsedYears,
        elapsedTimeName: i18next.t("common:dates:years")
      };
    }
  }
  return {
    elapsedTimeUnits: null,
    elapsedTimeName: ""
  };
};

export const addDays = (date: Date, days: number): Date => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

export const isDateAfter = (
  date: Date,
  timezoneId: string,
  toCompare?: Date // defaults to now
): boolean => {
  const momentDate = moment(date).tz(timezoneId, true);
  const momentCompare = moment(toCompare).tz(
    timezoneId,
    toCompare ? true : false
  );
  return momentDate.isAfter(momentCompare);
};

export const isDayBefore = (
  date: Date,
  timezoneId: string,
  toCompare?: Date
): boolean => {
  const momentDate = moment(date)
    .tz(timezoneId)
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const currentMoment = moment(toCompare)
    .tz(timezoneId, toCompare ? true : false)
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  return momentDate.isBefore(currentMoment, "day");
};

export const convertUTCToActiveZone = (date: Date | string): Date => {
  const utcDate = moment.utc(date);
  const activeZoneDate = utcDate.clone().local();
  return activeZoneDate.toDate();
};
