import clsx from "clsx";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";

import {
  DefaultRejectFeedbackExpert,
  RejectFeedbackExpert
} from "@arbolus-technologies/features/project-experts-list";
import {
  DO_NOT_CONTACT_STATUS,
  DateString
} from "@arbolus-technologies/models/common";
import { REFERRAL_STATES } from "@arbolus-technologies/models/expert";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { PublicCompanyBadge } from "@arbolus-technologies/ui/components";

import useContentPanelContext from "../../../../../contexts/contentPanel/UseContentPanelContext";
import { CandidatePickerActionProps } from "../../../../../models/view/candidatePicker";
import { ReferralReviewStats } from "../../../../../models/view/project";
import { UtilsService } from "../../../../../services";
import { CIQAvatar } from "../../../../app/components";
import { AppSelector } from "../../../../app/store";
import { ExpertProfileMode, ExpertProjectProps } from "./ExpertProfileHolder";
import ExpertReferralState from "./ExpertReferralState";

interface ExpertProfileHeaderProps {
  candidatePickerProps?: CandidatePickerActionProps;
  usageMode: ExpertProfileMode;
  uiReferral: ReferralReviewStats;
  projectId: string;
  projectData: ExpertProjectProps;
  tagline: string | null;
  rejectData?: RejectFeedbackExpert;
  lastPublicCompanyExpDate: DateString | null;
  doNotContactStatus: DO_NOT_CONTACT_STATUS;
}

const ExpertProfileHeader: React.FC<ExpertProfileHeaderProps> = ({
  candidatePickerProps,
  usageMode,
  uiReferral,
  projectId,
  projectData,
  tagline,
  rejectData = DefaultRejectFeedbackExpert,
  lastPublicCompanyExpDate,
  doNotContactStatus
}): JSX.Element => {
  const dispatch = useDispatch();
  const countryMap = useSelector(AppSelector.appCountriesMapSelector());
  const { closePanel } = useContentPanelContext();

  const { expertUser } = uiReferral;
  const { title, isoCountryCode, profileImageUrl } = expertUser;
  const country = countryMap.get(isoCountryCode ?? "");
  const isReviewMode = usageMode === ExpertProfileMode.REVIEW;

  const referralState = uiReferral.referralStatus;
  const isAnimateApproved =
    candidatePickerProps?.isAnimationState === REFERRAL_STATES.ACCEPT;
  const isAnimateRejected =
    candidatePickerProps?.isAnimationState === REFERRAL_STATES.REJECT;

  const isApproved = referralState === REFERRAL_STATES.ACCEPT;
  const isRejected = referralState === REFERRAL_STATES.REJECT;
  const isCandidate = referralState === REFERRAL_STATES.CANDIDATE;
  const isReviewed = isApproved || isRejected;

  const isPrevControl = !candidatePickerProps?.isFirstCandidate;

  const rejectExpert = (): void => {
    closePanel();
    rejectData.setData({
      projectId,
      referralId: uiReferral.referralId,
      expert: {
        firstName: expertUser?.firstName || "",
        lastName: expertUser?.lastName || "",
        profileImageUrl: expertUser?.profileImageUrl || "",
        id: expertUser?.id || "",
        expertId: "",
        email: expertUser?.email || ""
      }
    });
    dispatch(PanelStoreActions.openPanel(PanelId.RejectFeedback));
  };

  const isDnc = doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC;

  return (
    <div className="expert-header-container">
      <Row className="expert-header">
        <Col className="left-action">
          {isReviewMode && isPrevControl && (
            <div
              className="transparent-btn nav-btn"
              onClick={candidatePickerProps?.onPrevious}
            >
              <span className="ciq-icon ciq-chevron-left" />
            </div>
          )}
        </Col>

        <Col className="expert-avatar">
          {isReviewMode && !isDnc && (
            <Button
              size="sm"
              color="danger"
              className={clsx("with-icon", {
                disabled: isReviewed || candidatePickerProps?.isAnimationState
              })}
              onClick={rejectExpert}
            >
              <span className="ciq-icon ciq-like-thumb invert" />
            </Button>
          )}
          <div className="ea-avatar">
            <div className="ea-animation">
              <div className="radial">
                <div className="circle left rotate">
                  <span
                    className={clsx({
                      dark: !candidatePickerProps?.isAnimating || !isReviewed,
                      animate: candidatePickerProps?.isAnimating,
                      reject: isAnimateRejected || (isReviewMode && isRejected),
                      add: isAnimateApproved || (isReviewMode && isApproved)
                    })}
                  />
                </div>
                <div className="circle right rotate">
                  <span
                    className={clsx({
                      dark: !candidatePickerProps?.isAnimating || !isReviewed,
                      animate: candidatePickerProps?.isAnimating,
                      reject: isAnimateRejected || (isReviewMode && isRejected),
                      add: isAnimateApproved || (isReviewMode && isApproved)
                    })}
                  />
                </div>
              </div>
            </div>
            <CIQAvatar
              username={UtilsService.displayUserName(expertUser)}
              profileImageUrl={profileImageUrl}
            />
            {!isReviewMode && (
              <div
                className={clsx("referral-stat-indicator", {
                  candidate: isCandidate,
                  approve: isApproved,
                  reject: isRejected
                })}
              />
            )}
          </div>
          {isReviewMode && !isDnc && (
            <Button
              size="sm"
              color="success"
              className={clsx("with-icon", {
                disabled: isReviewed || candidatePickerProps?.isAnimationState
              })}
              onClick={(): void =>
                candidatePickerProps?.onCandidateAccept(uiReferral.referralId)
              }
            >
              <span className="ciq-icon ciq-like-thumb" />
            </Button>
          )}
        </Col>

        <Col className="right-action">
          {isReviewMode && (
            <div
              className="transparent-btn nav-btn"
              onClick={candidatePickerProps?.onNext}
            >
              <span className="ciq-icon ciq-chevron-right" />
            </div>
          )}
        </Col>
      </Row>
      <Row className="expert-name-container">
        <Col>
          <h2>{UtilsService.displayUserName(expertUser)}</h2>{" "}
          <>
            {country && (
              <span
                className={`flag-icon flag-icon-${country.twoLetterCode.toLowerCase()}`}
              />
            )}
            {lastPublicCompanyExpDate !== null && (
              <div className="public-company-container">
                <PublicCompanyBadge date={lastPublicCompanyExpDate} />
              </div>
            )}
          </>
          <p className="text-center">{tagline || title} </p>
        </Col>
      </Row>
      <ExpertReferralState uiReferral={uiReferral} projectData={projectData} />
    </div>
  );
};

export default ExpertProfileHeader;
