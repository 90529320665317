import {
  FilterModel,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest
} from "ag-grid-community";

import {
  ProjectClientBillingItem,
  ProjectClientBillingQueryParams,
  ProjectClientService,
  SORT_DIRECTION,
  TransactionType
} from "@arbolus-technologies/api";

export class ProjectSpendDataSource implements IServerSideDatasource {
  private readonly _projectClientService: typeof ProjectClientService;
  private readonly _projectId: string;

  constructor(
    projectClientService: typeof ProjectClientService,
    projectId: string
  ) {
    this._projectClientService = projectClientService;
    this._projectId = projectId;
  }

  getRows(
    params: IServerSideGetRowsParams<ProjectClientBillingItem, never>
  ): void {
    const { success, fail, request } = params;

    const queryParams = this.getQueryParams(request);

    this._projectClientService.getBilling(queryParams).subscribe({
      next: (response) => {
        success({
          rowData: response.items,
          rowCount: response.pagination.count
        });
      },
      error: () => fail()
    });
  }

  private getQueryParams(
    request: IServerSideGetRowsRequest
  ): ProjectClientBillingQueryParams {
    const { startRow, endRow } = request;
    const filterModel = request.filterModel as FilterModel;

    const queryParams: ProjectClientBillingQueryParams = {
      limit: endRow! - startRow!,
      offset: startRow!,
      projectId: this._projectId,
      orderBy: "TransactionDate",
      orderDirection: SORT_DIRECTION.DESCENDING
    };

    if (filterModel.expertName) {
      queryParams.expertName = filterModel.expertName.filter || "";
    }

    if (filterModel.expertTitle) {
      queryParams.expertTitle = filterModel.expertTitle.filter || "";
    }

    if (filterModel.type) {
      const types = filterModel.type.values as string[];
      const hasFollowUp = types.includes("FollowUp");
      const hasCall = types.includes(TransactionType.Call);
      if ((hasFollowUp && !hasCall) || (!hasFollowUp && hasCall)) {
        queryParams.isFollowUp = hasFollowUp;
      }

      queryParams.type = (
        hasFollowUp
          ? [
              ...types.filter((type) => type !== "FollowUp"),
              TransactionType.Call
            ]
          : types
      ) as TransactionType[];
    }

    return queryParams;
  }
}
