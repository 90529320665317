import { OutputSelector, createSelector } from "reselect";

import {
  CALL_STATUSES,
  ExpertDetail,
  ExpertsListPageTab,
  RecommendationResponse,
  ReferralSummary,
  ResponseAllExpertsTable,
  SelectedFilterEnum,
  WorkHistory
} from "@arbolus-technologies/api";

import {
  ProjectExpertsAppState,
  ProjectExpertsReducerState,
  ScheduleFilterDefinitions
} from "../definitions";

// Defined as constant to avoid re-renders caused by new object references
const DEFAULT_SCHEDULE_FILTERS = [
  {
    id: CALL_STATUSES.UNSCHEDULED,
    key: SelectedFilterEnum.CALL_STATUSES
  }
];

const projectExpertsStateSelector = (
  state: ProjectExpertsAppState
): ProjectExpertsReducerState => state.projectExperts;

const allExpertsList = (): OutputSelector<
  ProjectExpertsAppState,
  ResponseAllExpertsTable[] | null,
  (res: ProjectExpertsReducerState) => ResponseAllExpertsTable[] | null
> =>
  createSelector<
    ProjectExpertsAppState,
    ProjectExpertsReducerState,
    ResponseAllExpertsTable[] | null
  >(projectExpertsStateSelector, (expertsReducerState) => {
    if (expertsReducerState.allExpertsList?.length) {
      return expertsReducerState.allExpertsList;
    }
    return [];
  });

const recommendationsList = (): OutputSelector<
  ProjectExpertsAppState,
  RecommendationResponse[],
  (res: ProjectExpertsReducerState) => RecommendationResponse[]
> =>
  createSelector<
    ProjectExpertsAppState,
    ProjectExpertsReducerState,
    RecommendationResponse[]
  >(
    projectExpertsStateSelector,
    (expertsReducerState) => expertsReducerState.recommendationsList
  );

const isRecommendationsListLoading = (): OutputSelector<
  ProjectExpertsAppState,
  boolean,
  (res: ProjectExpertsReducerState) => boolean
> =>
  createSelector<ProjectExpertsAppState, ProjectExpertsReducerState, boolean>(
    projectExpertsStateSelector,
    (expertsReducerState) => expertsReducerState.isRecommendationsListLoading
  );

const getAngleExpert = (): OutputSelector<
  ProjectExpertsAppState,
  ExpertDetail | null,
  (res: ProjectExpertsReducerState) => ExpertDetail | null
> =>
  createSelector<
    ProjectExpertsAppState,
    ProjectExpertsReducerState,
    ExpertDetail | null
  >(
    projectExpertsStateSelector,
    (expertsReducerState) => expertsReducerState.angleExpert
  );

const expertCurrentCompanies = (): OutputSelector<
  ProjectExpertsAppState,
  WorkHistory[] | undefined,
  (res: ProjectExpertsReducerState) => WorkHistory[] | undefined
> =>
  createSelector<
    ProjectExpertsAppState,
    ProjectExpertsReducerState,
    WorkHistory[] | undefined
  >(projectExpertsStateSelector, (expertsReducerState) =>
    expertsReducerState.angleExpert?.workHistories.filter(
      (workHistory) => workHistory.to === null
    )
  );

const isAllExpertsListLoading = (): OutputSelector<
  ProjectExpertsAppState,
  boolean,
  (res: ProjectExpertsReducerState) => boolean
> =>
  createSelector<ProjectExpertsAppState, ProjectExpertsReducerState, boolean>(
    projectExpertsStateSelector,
    (expertsReducerState) => expertsReducerState.isLoadingListExperts
  );

const activeTab = (): OutputSelector<
  ProjectExpertsAppState,
  ExpertsListPageTab | null,
  (res: ProjectExpertsReducerState) => ExpertsListPageTab | null
> =>
  createSelector<
    ProjectExpertsAppState,
    ProjectExpertsReducerState,
    ExpertsListPageTab | null
  >(
    projectExpertsStateSelector,
    (expertsReducerState) => expertsReducerState.tabActive
  );

const forceClosePanel = (): OutputSelector<
  ProjectExpertsAppState,
  boolean,
  (res: ProjectExpertsReducerState) => boolean
> =>
  createSelector<ProjectExpertsAppState, ProjectExpertsReducerState, boolean>(
    projectExpertsStateSelector,
    (expertsReducerState) => expertsReducerState.forceClosePanel
  );

const currentAnglesOnTable = (): OutputSelector<
  ProjectExpertsAppState,
  { title: string; color: string }[] | null,
  (res: ProjectExpertsReducerState) => { title: string; color: string }[] | null
> =>
  createSelector<
    ProjectExpertsAppState,
    ProjectExpertsReducerState,
    { title: string; color: string }[] | null
  >(
    projectExpertsStateSelector,
    (expertsReducerState) => expertsReducerState.currentAnglesOnTable
  );

const summaryList = (): OutputSelector<
  ProjectExpertsAppState,
  ReferralSummary | null,
  (res: ProjectExpertsReducerState) => ReferralSummary | null
> =>
  createSelector<
    ProjectExpertsAppState,
    ProjectExpertsReducerState,
    ReferralSummary | null
  >(
    projectExpertsStateSelector,
    (expertsReducerState) => expertsReducerState.summaryProjectList
  );

const isTaglineBeingSaved = (): OutputSelector<
  ProjectExpertsAppState,
  boolean,
  (res: ProjectExpertsReducerState) => boolean
> =>
  createSelector<ProjectExpertsAppState, ProjectExpertsReducerState, boolean>(
    projectExpertsStateSelector,
    (expertsReducerState) => expertsReducerState.isTaglineSaving
  );

const scheduleTabFilters = (
  projectId: string
): OutputSelector<
  ProjectExpertsAppState,
  ScheduleFilterDefinitions,
  (res: ProjectExpertsReducerState) => ScheduleFilterDefinitions
> =>
  createSelector<
    ProjectExpertsAppState,
    ProjectExpertsReducerState,
    ScheduleFilterDefinitions
  >(projectExpertsStateSelector, (expertsReducerState) =>
    expertsReducerState.scheduleTabFilters === null ||
    !(projectId in expertsReducerState.scheduleTabFilters)
      ? DEFAULT_SCHEDULE_FILTERS
      : expertsReducerState.scheduleTabFilters[projectId]
  );

export const ProjectExpertsSelector = {
  projectExpertsStateSelector,
  allExpertsList,
  isAllExpertsListLoading,
  recommendationsList,
  isRecommendationsListLoading,
  getAngleExpert,
  activeTab,
  forceClosePanel,
  currentAnglesOnTable,
  summaryList,
  expertCurrentCompanies,
  isTaglineBeingSaved,
  scheduleTabFilters
};
