import React from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import { ListCanopy } from "@arbolus-technologies/api";
import { CANOPY_STATUS } from "@arbolus-technologies/models/canopy";
import { Responses } from "@arbolus-technologies/ui/canopy";
import { AvatarGroup } from "@arbolus-technologies/ui/components";
import { displayUserName } from "@arbolus-technologies/utils";

import styles from "./SurveyCard.module.scss";

interface SurveyCardProps {
  canopy: ListCanopy;
  canopyDetailsPageRoute: (canopyId: string) => string;
  canopyExpertsRoute: (canopyId: string) => string;
}

export const SurveyCard: React.FC<SurveyCardProps> = ({
  canopy,
  canopyDetailsPageRoute,
  canopyExpertsRoute
}) => {
  const { t } = useTranslation("surveyCard");
  const { id, status, title, experts, expertsCount } = canopy;

  const handleLink = (): string => {
    if (isMobile || status !== CANOPY_STATUS.DRAFT) {
      return canopyExpertsRoute(id);
    }

    return canopyExpertsRoute(id);
  };

  const history = useHistory();

  return (
    <div className={styles.container}>
      <div
        className={styles.linkCard}
        onClick={(e) => {
          history.push(handleLink());
          e.preventDefault();
        }}
      >
        <div className={styles[status]}>
          {status === CANOPY_STATUS.GENERATING ? "Generating..." : status}
        </div>
        <div className={styles.description}>{title}</div>

        <div className={styles.expertsResponsesContainer}>
          {expertsCount > 0 ? (
            <AvatarGroup
              avatars={experts.map((e) => ({
                name: displayUserName(e),
                imageUrl: e.profileImageUrl
              }))}
              totalCount={expertsCount}
            />
          ) : (
            <Responses responses="Empty" />
          )}
        </div>
      </div>

      <div className={styles.buttonContainer}>
        {status === CANOPY_STATUS.DRAFT ||
        status === CANOPY_STATUS.GENERATING ? (
          <Link className={styles.fullWidth} to={canopyExpertsRoute(id)}>
            <span>{t("buildingSurvey")}</span>
          </Link>
        ) : (
          <>
            <Link className={styles.cardButton} to={canopyDetailsPageRoute(id)}>
              <span>{t("details")}</span>
            </Link>
            <Link className={styles.cardButton} to={canopyExpertsRoute(id)}>
              <span>{t("responses")}</span>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};
