import { useLocalStorage } from "usehooks-ts";

import { ArbolusFeatureFlags } from "@arbolus-technologies/models/common";

export function useCachedFlags(): {
  cachedOverrides: ArbolusFeatureFlags | null;
  setCachedOverrides: (value: ArbolusFeatureFlags | null) => void;
} {
  const [cachedOverrides, setCachedOverrides] =
    useLocalStorage<ArbolusFeatureFlags | null>("featureFlags", null);
  return { cachedOverrides, setCachedOverrides };
}
