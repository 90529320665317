import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "arbolus-ui-components";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ExpertService,
  ProjectAnswer,
  ToasterService
} from "@arbolus-technologies/api";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

import { ScreeningQuestion } from "./ScreeningQuestion/ScreeningQuestion";
import {
  ScreeningQuestionsFormValues,
  ScreeningQuestionsSchema
} from "./ScreeningQuestionsSchema";

import styles from "./ScreeningQuestionsForm.module.scss";

interface ScreeningQuestionsFormProps {
  screeningQuestions: ProjectAnswer[];
  nextStepName: string;
  projectId: string;
  goToPrevStep: () => void;
  onAfterSubmit: () => void;
  expertService?: typeof ExpertService;
  notificationService?: ToasterService;
}

export const ScreeningQuestionsForm: React.FC<ScreeningQuestionsFormProps> = ({
  screeningQuestions,
  nextStepName,
  projectId,
  goToPrevStep,
  onAfterSubmit,
  expertService = ExpertService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("expertApplications");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const expertId = useSelector(CacheSelector.loggedInUserExpertId());

  const methods = useForm<ScreeningQuestionsFormValues>({
    // @ts-ignore
    resolver: yupResolver(ScreeningQuestionsSchema),
    mode: "all",
    defaultValues: {
      questions: screeningQuestions.map((question) => ({
        questionId: question.questionId,
        answer: question.answer
      }))
    }
  });

  const onSubmit = (values: ScreeningQuestionsFormValues): void => {
    setIsLoading(true);

    expertService
      .addProjectAnswers(expertId, {
        answers: values.questions,
        projectId
      })
      .subscribe(
        () => {
          onAfterSubmit();
          setIsLoading(false);
        },
        (error: ErrorResponse<CIQError>) => {
          setIsLoading(false);
          notificationService.showError(error.message);
        }
      );
  };

  return (
    <FormProvider {...methods}>
      <div className={styles.questionList}>
        {screeningQuestions.map((screeningQuestion, index) => (
          <ScreeningQuestion
            key={screeningQuestion.questionId}
            screeningQuestion={screeningQuestion}
            index={index}
          />
        ))}
      </div>
      <div className={styles.btnContainer}>
        <Button
          type="tertiary"
          text={t("back")}
          onClick={goToPrevStep}
          disabled={isLoading}
        />
        <Button
          text={nextStepName}
          onClick={methods.handleSubmit(onSubmit)}
          disabled={isLoading || !methods.formState.isValid}
        />
      </div>
    </FormProvider>
  );
};
