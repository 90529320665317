import { Icon } from "arbolus-ui-components";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";

import { ANSWER_TYPE } from "@arbolus-technologies/models/canopy";
import {
  CanopyAnswerSummaryItemMultipleChoice,
  CanopyAnswerSummaryItemNps,
  CanopyAnswerSummaryItemShortText,
  CanopyAnswerSummaryItemValue,
  CanopyAnswerSummaryItemVideo,
  CanopyAnswersSummariesTypes
} from "@arbolus-technologies/models/canopy-panels";
import { CANOPY_SINGLE_QUESTION_ROUTE_CLIENT } from "@arbolus-technologies/routes";
import { CanopyV2Selector } from "@arbolus-technologies/stores/canopy-V2";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { SquareNumber } from "@arbolus-technologies/ui/components";

import { CanopySummaryComponentMultiChoice } from "./CanopySummaryComponentMultiChoice/CanopySummaryComponentMultiChoice";
import { CanopySummaryComponentNps } from "./CanopySummaryComponentNps/CanopySummaryComponentNps";
import { CanopySummaryComponentShortText } from "./CanopySummaryComponentShortText/CanopySummaryComponentShortText";
import { CanopySummaryComponentValue } from "./CanopySummaryComponentValue/CanopySummaryComponentValue";
import { CanopySummaryComponentVideo } from "./CanopySummaryComponentVideo/CanopySummaryComponentVideo";

import styles from "./CanopySummaryComponent.module.scss";

interface CanopySummaryComponentProps {
  summary: CanopyAnswersSummariesTypes;
  type: ANSWER_TYPE;
  updateSummaries: (id: string) => void;
}

export const CanopySummaryComponent: React.FC<CanopySummaryComponentProps> = ({
  summary,
  type,
  updateSummaries
}) => {
  const canopyData = useSelector(CanopyV2Selector.canopyData());
  const isVideo = type === ANSWER_TYPE.VIDEO;
  const isMultiChoice = type === ANSWER_TYPE.MULTIPLE_CHOICE;
  const isValue = type === ANSWER_TYPE.VALUE;
  const isShortText = type === ANSWER_TYPE.SHORT_TEXT;
  const isNps = type === ANSWER_TYPE.NPS;

  return (
    <div className={styles.container}>
      <div className={styles.collapseContainer}>
        <div className={styles.headerCollapse}>
          <div className={styles.squareContainer}>
            <SquareNumber
              number={summary.sortOrder.toString()}
              isActive={!!summary.isOpen}
            />
            <div
              onClick={() => updateSummaries(summary.canopyQuestionId)}
              className={styles.titleHeader}
            >
              <div title={summary.questionText}>{summary.questionText}</div>
            </div>
          </div>

          <div className={styles.iconContainer}>
            <Link
              to={CANOPY_SINGLE_QUESTION_ROUTE_CLIENT(
                canopyData?.id!,
                summary.canopyQuestionId
              )}
            >
              <Icon
                name="open_in_new"
                fontSize="24px"
                color={ARBOLUS_COLORS.bColorGrayIcon}
              />
            </Link>
            <Icon
              name={summary.isOpen ? "expand_less" : "expand_more"}
              onClick={() => updateSummaries(summary.canopyQuestionId)}
              fontSize="24px"
              color={ARBOLUS_COLORS.bColorGrayIcon}
            />
          </div>
        </div>
        <Collapse isOpen={summary.isOpen}>
          {isVideo && (
            <CanopySummaryComponentVideo
              summary={summary as CanopyAnswerSummaryItemVideo}
              canopyId={canopyData?.id!}
            />
          )}
          {isMultiChoice && (
            <CanopySummaryComponentMultiChoice
              summary={summary as CanopyAnswerSummaryItemMultipleChoice}
              canopyId={canopyData?.id!}
            />
          )}
          {isValue && (
            <CanopySummaryComponentValue
              summary={summary as CanopyAnswerSummaryItemValue}
            />
          )}
          {isShortText && (
            <CanopySummaryComponentShortText
              summary={summary as CanopyAnswerSummaryItemShortText}
              canopyId={canopyData?.id!}
            />
          )}
          {isNps && (
            <CanopySummaryComponentNps
              summary={summary as CanopyAnswerSummaryItemNps}
            />
          )}
        </Collapse>
      </div>
    </div>
  );
};
