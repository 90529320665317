import React from "react";

import { BookedTable } from "../../Modules/ReferralsTable/BookedTable";
import { ReferralsPage } from "./ReferralsPage";

export const BookedPage: React.FC = () => (
  <ReferralsPage page="booked">
    <BookedTable />
  </ReferralsPage>
);
