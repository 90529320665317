/* eslint-disable @typescript-eslint/no-empty-function */
import { push } from "connected-react-router";
import moment from "moment";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button, ButtonGroup, TabContent, TabPane } from "reactstrap";
import { Dispatch } from "redux";
import { createStructuredSelector } from "reselect";
import { Subscription, forkJoin, of } from "rxjs";
import { catchError } from "rxjs/operators";

import { Slot, ToasterService, WorkHistory } from "@arbolus-technologies/api";
import {
  ExpertAvailability,
  generateEventName
} from "@arbolus-technologies/features/common";
import { BainConfirmation } from "@arbolus-technologies/features/project-experts-list";
import {
  APP_TRACKING_ROUTES,
  DO_NOT_CONTACT_STATUS,
  ExpertRate,
  REFERRAL_COMPLIANCE_STATE,
  REFERRAL_SUB_STATE,
  SelectOption
} from "@arbolus-technologies/models/common";
import {
  EXPERT_STATUS,
  REFERRAL_STATES
} from "@arbolus-technologies/models/expert";
import {
  AvailableSlot,
  ExpertUser
} from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  ProjectExpertsSelector,
  ProjectExpertsStoreActions
} from "@arbolus-technologies/stores/project-experts-store";
import {
  AssignedAngle,
  DncInfoBox,
  Loader,
  ModalWithChildren
} from "@arbolus-technologies/ui/components";
import { EVENT_DATE_TIME_FORMAT } from "@arbolus-technologies/utils";

import {
  PROJECT_CALENDAR,
  PROJECT_NEW_EVENT
} from "../../../../../constants/navigation/projectRoutes";
import { PROJECT_TAB_ROUTES } from "../../../../../constants/navigation/projectTabRoutes";
import { CIQError, ErrorResponse } from "../../../../../models/api";
import {
  ComplianceStatusRequest,
  UpdateReferralResponse
} from "../../../../../models/project";
import {
  CandidatePickerActionProps,
  UIExpertProfile
} from "../../../../../models/view/candidatePicker";
import { CreateEventRouterData } from "../../../../../models/view/event";
import { CIQUserFeatures } from "../../../../../models/view/user";
import {
  ClientService,
  ExpertReferralService,
  ExpertService,
  ProjectService,
  UtilsService
} from "../../../../../services";
import { AppAction } from "../../../../../store/actions";
import { AppState } from "../../../../../store/reducers";
import { AppSelector } from "../../../../app/store";
import { AuthSelector } from "../../../../auth/store";
import ExpertProfilePreview from "../../../../dashboard/components/expertProfile/profilePreview/ExpertProfilePreview";
import ExpertReferralNote from "../../../../dashboard/components/expertProfile/profilePreview/ReferralNote";
import ProjectExpertEvents from "../../../pages/experts/ProjectExpertEvents";
import { ProjectSelector, ProjectStoreActions } from "../../../store";
import ExpertAnswersTab from "./ExpertAnswersTab";
import ExpertProfileHeader from "./ExpertProfileHeader";
import ComplianceTab from "./compliance/ComplianceTab";

const notification = new ToasterService();

const bainId = process.env.NX_PUBLIC_BAIN_ID;

export enum ExpertProfileMode {
  REVIEW,
  PREVIEW,
  COMPLIANCE,
  AVAILABILITY
}

export enum ExpertProfileTab {
  PROFILE = "PROFILE",
  SCREENING_QUESTIONS = "SCREENING_QUESTIONS",
  COMPLIANCE = "COMPLIANCE",
  AVAILABILITY = "AVAILABILITY"
}

export interface ExpertProjectProps {
  hasCompliance: boolean;
}

interface ExpertProfileHolderStoreProps {
  projectName: string;
  clientId?: string;
  activeProjectId?: string;
  hasCompliance: boolean;
  clientFeatures: CIQUserFeatures;
  projectTimezone?: string;
  timezones?: SelectOption[];
  loggedInUserClientId?: string;
  expertCurrentCompanies?: WorkHistory[];
}

interface ExpertProfileHolderProps extends ExpertProfileHolderStoreProps {
  projectId: string;
  expertId: string;
  referralId: string;
  usageMode: ExpertProfileMode;
  onPanelClose?: () => void;
  onProfileFetched?: () => void;
  refetchReferralSummary: (projectId: string) => void;
  candidatePickerProps?: CandidatePickerActionProps;
  showAvailabilityTab?: boolean;
  navigateToCreateEvent: (
    tabRouteName: string,
    createEventInitData: CreateEventRouterData
  ) => void;
  navigateToCalendar: (calendarRouteName: string) => void;
  updateReferralStatus: (
    projectId: string,
    referralId: string,
    status: "Accept" | "Reject"
  ) => void;
}

interface ExpertProfileHolderState {
  activeTab: ExpertProfileTab;
  uiExpertProfile: UIExpertProfile;
  isProfileLoading: boolean;
  isComplianceLoading: boolean;
  uiExpertRate: ExpertRate;
  isBainModalOpen: boolean;
  isRequestTimeSlot: boolean;
  selectedSlot: AvailableSlot;
  timezoneEventData: string;
}

type ExpertProfileHolderIntersectProps = ExpertProfileHolderProps &
  WithTranslation;

class ExpertProfileHolder extends Component<
  ExpertProfileHolderIntersectProps,
  ExpertProfileHolderState
> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    hasCompliance: false,
    clientFeatures: {},
    activeProjectId: "",
    projectName: "",
    refetchReferralSummary: (): void => {},
    navigateToCreateEvent: (): void => {},
    navigateToCalendar: (): void => {},
    updateReferralStatus: (): void => {}
  };

  constructor(props: ExpertProfileHolderIntersectProps) {
    super(props);

    const { hasCompliance } = props;

    this.state = {
      activeTab: ExpertProfileTab.PROFILE,
      uiExpertProfile: {} as UIExpertProfile,
      isProfileLoading: true,
      isComplianceLoading: false,
      uiExpertRate: {} as ExpertRate,
      isBainModalOpen: false,
      isRequestTimeSlot: false,
      selectedSlot: {} as AvailableSlot,
      timezoneEventData: ""
    };

    this.projectData = {
      hasCompliance
    };
  }

  componentDidMount(): void {
    const { activeProjectId } = this.props;

    if (activeProjectId) {
      this.fetchExpert((uiExpertProfile: UIExpertProfile) => {
        const { showAvailabilityTab, onProfileFetched } = this.props;
        if (
          showAvailabilityTab &&
          uiExpertProfile.referral.expertAvailabilitySlots?.length
        ) {
          this.handleTabChangeEvent(ExpertProfileTab.AVAILABILITY);
        }
        onProfileFetched?.();
      });
    } else {
      this.fetchProject();
    }
  }

  componentWillUnmount(): void {
    this.expertProfileFetchSubscription?.unsubscribe();
    this.removeFromReferralSubscription?.unsubscribe();
    this.referralComplianceUpdate?.unsubscribe();
    this.getProjectSubscription?.unsubscribe();
  }

  private removeFromReferralSubscription?: Subscription;

  private getProjectSubscription?: Subscription;

  private expertProfileFetchSubscription?: Subscription;

  private referralComplianceUpdate?: Subscription;

  private projectData?: ExpertProjectProps;

  fetchProject = (): void => {
    const { projectId, onPanelClose } = this.props;

    this.getProjectSubscription = ProjectService.getProject(
      projectId
    ).subscribe(
      (project) => {
        const { hasComplianceCheck } = project;

        this.projectData = {
          hasCompliance: hasComplianceCheck
        };

        this.fetchExpert((uiExpertProfile: UIExpertProfile) => {
          const { showAvailabilityTab, onProfileFetched } = this.props;
          if (
            showAvailabilityTab &&
            uiExpertProfile.referral.expertAvailabilitySlots?.length
          ) {
            this.handleTabChangeEvent(ExpertProfileTab.AVAILABILITY);
          }
          onProfileFetched?.();
        });
      },
      (err: ErrorResponse<CIQError>) => {
        notification.showError(err.message);
        onPanelClose?.();
      }
    );
  };

  fetchExpert = (
    onProfileFetched: (uiExpertProfile: UIExpertProfile) => void
  ): void => {
    const {
      expertId,
      referralId,
      projectId,
      clientId,
      clientFeatures,
      onPanelClose,
      usageMode
    } = this.props;

    const { networkEnabled } = clientFeatures;

    this.expertProfileFetchSubscription = forkJoin([
      ProjectService.getReferral(projectId, referralId),
      ExpertService.getExpert(expertId, clientId, projectId),
      ExpertService.getAnswers(expertId, projectId).pipe(
        catchError(() => of(null))
      ),
      ExpertService.getComplianceAnswers(expertId, projectId),
      networkEnabled
        ? ClientService.getSavedExpertStatus(clientId!, expertId)
        : of(undefined)
    ]).subscribe(
      ([referral, expert, answers, compliances, networkStat]) => {
        // Getting the availability here
        const uiExpertProfile = {
          expert,
          referralId,
          referral,
          answers,
          compliances: compliances.items,
          savedOnNetwork: networkStat?.savedExpert
        } as UIExpertProfile;

        const { application, review } = referral;
        const isComplianceReview = usageMode === ExpertProfileMode.COMPLIANCE;
        const isReadyToComplianceCheck =
          application.subStatus === REFERRAL_SUB_STATE.ACCEPT &&
          review.subStatus === REFERRAL_SUB_STATE.ACCEPT;

        const activeTab =
          isComplianceReview && isReadyToComplianceCheck
            ? ExpertProfileTab.COMPLIANCE
            : ExpertProfileTab.PROFILE;

        this.setState(
          {
            uiExpertProfile,
            isProfileLoading: false,
            activeTab
          },
          () => onProfileFetched(uiExpertProfile)
        );
      },
      (err: ErrorResponse<CIQError>) => {
        notification.showError(err.message);
        onPanelClose?.();
      }
    );

    ProjectService.getRate(projectId, expertId).subscribe(
      (rate) => {
        this.setState({
          uiExpertRate: rate
        });
      },
      (err: ErrorResponse<CIQError>) => {
        notification.showError(err.message);
      }
    );
  };

  handleTabChangeEvent = (nextTab: ExpertProfileTab): void => {
    this.setState({
      activeTab: nextTab
    });
  };

  renderCalendarPage = (): void => {
    const { navigateToCalendar, onPanelClose, projectId } = this.props;
    const calendarRoute = PROJECT_TAB_ROUTES[PROJECT_CALENDAR];
    onPanelClose?.();
    navigateToCalendar(calendarRoute.route(projectId));
  };

  handleCreateEvent = (selectedSlot: AvailableSlot, timezone?: string) => {
    const { loggedInUserClientId } = this.props;
    const isBain = loggedInUserClientId === bainId;
    const isNotExpertApproved =
      this.state.uiExpertProfile.referral.status === REFERRAL_STATES.CANDIDATE;

    if (isBain && isNotExpertApproved) {
      this.setState({
        isBainModalOpen: true,
        selectedSlot: selectedSlot,
        timezoneEventData: timezone ?? ""
      });
    } else {
      this.createEvent(selectedSlot, timezone);
    }
  };

  requestSlotsForBain = () => {
    this.setState({
      isBainModalOpen: true,
      isRequestTimeSlot: true
    });
  };

  requestTimesSlots = () => {
    const { projectId, expertId, t, onPanelClose } = this.props;
    ExpertService.requestExpertAvailability(projectId, expertId).subscribe(
      () => {
        onPanelClose?.();
        notification.showSuccess(t("requestTimeSlotSuccessMessage"));
      },
      (err: ErrorResponse<CIQError>) => {
        notification.showError(err.message);
      }
    );
  };

  createEvent = (selectedSlot: AvailableSlot, timezone?: string) => {
    const {
      navigateToCreateEvent,
      onPanelClose,
      projectId,
      projectName,
      referralId,
      updateReferralStatus
    } = this.props;

    const {
      uiExpertProfile: { referral, expert }
    } = this.state;
    const nextTabRoute = PROJECT_TAB_ROUTES[PROJECT_NEW_EVENT];

    if (referral.status === REFERRAL_STATES.CANDIDATE) {
      updateReferralStatus(projectId, referralId, EXPERT_STATUS.ACCEPT);
    }

    const expertName = `${expert.user.firstName} ${expert.user.lastName}`;
    const title = generateEventName(expertName, projectName);

    const startMoment = timezone
      ? moment.utc(selectedSlot.slotStartValue).tz(timezone)
      : moment(selectedSlot.slotStartValue);
    const startDate = new Date(startMoment.format(EVENT_DATE_TIME_FORMAT));

    const endMoment = timezone
      ? moment.utc(selectedSlot.slotEndValue).tz(timezone)
      : moment(selectedSlot.slotEndValue);
    const endDate = new Date(endMoment.format(EVENT_DATE_TIME_FORMAT));

    const createEventInitData: CreateEventRouterData = {
      title: title,
      workspaceId: "",
      expert: {
        ...expert.user,
        hasMinimumCallTimeRate: expert.hasMinimumCallTimeRate
      } as ExpertUser,
      startDate,
      endDate,
      timezone,
      from: APP_TRACKING_ROUTES.EXPERT_AVAILABILITY
    };

    navigateToCreateEvent(nextTabRoute.route(projectId), createEventInitData);
    onPanelClose?.();
  };

  renderAvailabilityTab = (): JSX.Element => {
    const { uiExpertProfile } = this.state;
    const {
      projectTimezone,
      timezones,
      projectId,
      expertId,
      loggedInUserClientId,
      onPanelClose,
      referralId
    } = this.props;
    const {
      expertAvailabilitySlots = [],
      status,
      compliance,
      complianceRequired,
      expert: { timezone, hasMinimumCallTimeRate } = {},
      application,
      referralAvailability
    } = uiExpertProfile.referral;

    const timezoneDetails = {
      timezones,
      projectTimezone,
      expertTimezone: timezone ?? "UTC"
    };

    const validAvailabilitySlots = expertAvailabilitySlots.filter(
      (slot: Slot) => !slot.isExpired
    );

    return (
      <ExpertAvailability
        expertAvailabilitySlots={validAvailabilitySlots}
        createEvent={this.handleCreateEvent}
        complianceStatus={complianceRequired ? compliance.subStatus : null}
        expertStatus={status}
        onCallScheduled={this.renderCalendarPage}
        timezoneDetails={timezoneDetails}
        hasMinimumCallTimeRate={hasMinimumCallTimeRate}
        applicationStatus={application.subStatus}
        projectId={projectId}
        expertId={expertId}
        clientId={loggedInUserClientId}
        requestSlotsForBain={this.requestSlotsForBain}
        referralAvailability={referralAvailability}
        onPanelClose={onPanelClose}
        referralId={referralId}
        doNotContactStatus={uiExpertProfile.expert.doNotContactStatus}
      />
    );
  };

  handleComplianceStatusSubmit = (
    complianceStatus: ComplianceStatusRequest
  ): void => {
    const { activeProjectId, projectId, refetchReferralSummary } = this.props;
    const { uiExpertProfile } = this.state;
    const { t } = this.props;

    const { referral } = uiExpertProfile;
    const { id } = referral;
    const {
      chaperoneCall,
      complianceNote,
      compliance: complianceState
    } = complianceStatus;

    this.setState({ isComplianceLoading: true });
    this.referralComplianceUpdate = ProjectService.updateReferralCompliance(
      projectId,
      id,
      complianceStatus
    ).subscribe(
      ({ compliance, status }: UpdateReferralResponse) => {
        this.setState(
          {
            isComplianceLoading: false,
            uiExpertProfile: {
              ...uiExpertProfile,
              referral: {
                ...referral,
                status,
                compliance,
                chaperoneCall,
                complianceNote
              }
            }
          },
          () => notification.showSuccess(t("reviewalSuccess"))
        );

        if (complianceState === REFERRAL_COMPLIANCE_STATE.REJECT) {
          ProjectExpertEvents.updateReferral(id, REFERRAL_STATES.REJECT);
        } else {
          // Mark expert as active referral
          ProjectExpertEvents.updateCompliancePassed(id);
        }

        // Check compliance changed within project context
        if (activeProjectId) {
          // Refetch referral summary
          refetchReferralSummary(projectId);
        }
      },
      (error: ErrorResponse<CIQError>) => {
        this.setState({ isComplianceLoading: false }, () =>
          notification.showError(error.message)
        );
      }
    );
  };

  handleTabAvailabilityEvent = () => {
    this.handleTabChangeEvent(ExpertProfileTab.AVAILABILITY);
  };

  renderExpertProfile = (): JSX.Element => {
    const { usageMode, t, projectId, candidatePickerProps } = this.props;

    const {
      uiExpertProfile,
      activeTab,
      isComplianceLoading,
      uiExpertRate,
      isRequestTimeSlot
    } = this.state;

    const { expert, referral, answers, compliances, savedOnNetwork } =
      uiExpertProfile;

    const {
      note,
      application: { subStatus },
      review: { subStatus: ReviewStatus },
      compliance: { subStatus: ComplianceStatus, updateUser, updateDate },
      complianceNote,
      chaperoneCall,
      expertAvailabilitySlots,
      angle
    } = referral;

    const { hasCompliance } = this.projectData!;
    const isApplied = subStatus === REFERRAL_SUB_STATE.ACCEPT;

    const {
      user,
      endorsements,
      background,
      workHistories,
      linkedinProfile,
      doNotContactStatus
    } = expert;

    const isApplicationDeclined = subStatus === REFERRAL_SUB_STATE.REJECT;
    const hasScreeningQuestions = answers ? answers.items.length > 0 : false;

    // this checks whether at least one answer in the answer responses
    const isAnyAnswered = answers
      ? answers.items.some((answerResponse?) => answerResponse?.answer)
      : false;

    const hasAvailabilitySlots =
      expertAvailabilitySlots && expertAvailabilitySlots.length > 0;

    // this is true when multiple tab are available
    const showTabButtonContainer =
      ((hasScreeningQuestions && isAnyAnswered) ||
        (isApplied && hasCompliance) ||
        hasAvailabilitySlots) &&
      !isApplicationDeclined;

    const isValidForComplianceReview =
      ReviewStatus === REFERRAL_SUB_STATE.ACCEPT ||
      ReviewStatus === REFERRAL_SUB_STATE.PENDING;

    const canReviewCompliance = isApplied && isValidForComplianceReview;

    const expertName = UtilsService.displayUserName(expert.user, false);

    return (
      <div className="expert-container">
        <ExpertProfileHeader
          projectId={projectId}
          candidatePickerProps={candidatePickerProps}
          usageMode={usageMode}
          uiReferral={ExpertReferralService.getExpertReferralDetailState(
            referral,
            user,
            hasCompliance,
            savedOnNetwork
          )}
          projectData={this.projectData!}
          tagline={uiExpertProfile.referral.tagline}
          lastPublicCompanyExpDate={
            uiExpertProfile.expert.lastPublicCompanyExpDate
          }
          doNotContactStatus={doNotContactStatus}
        />
        {doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC && (
          <div className="dncContainer">
            <DncInfoBox
              doNotContactStatus={doNotContactStatus}
              isAdmin={false}
            />
          </div>
        )}
        <div className="expert-body">
          {showTabButtonContainer && (
            <div className="tab-button-container">
              <ButtonGroup>
                <Button
                  size="sm"
                  color="default"
                  active={activeTab === ExpertProfileTab.PROFILE}
                  onClick={(): void =>
                    this.handleTabChangeEvent(ExpertProfileTab.PROFILE)
                  }
                >
                  {t("profile")}
                </Button>
                {hasScreeningQuestions && isAnyAnswered && (
                  <Button
                    size="sm"
                    color="default"
                    active={activeTab === ExpertProfileTab.SCREENING_QUESTIONS}
                    onClick={(): void =>
                      this.handleTabChangeEvent(
                        ExpertProfileTab.SCREENING_QUESTIONS
                      )
                    }
                  >
                    {t("qna")}
                  </Button>
                )}
                {isApplied && hasCompliance && (
                  <Button
                    size="sm"
                    color="default"
                    active={activeTab === ExpertProfileTab.COMPLIANCE}
                    onClick={(): void =>
                      this.handleTabChangeEvent(ExpertProfileTab.COMPLIANCE)
                    }
                  >
                    {t("compliance")}
                  </Button>
                )}
                {hasAvailabilitySlots && (
                  <Button
                    size="sm"
                    color="default"
                    active={activeTab === ExpertProfileTab.AVAILABILITY}
                    onClick={(): void =>
                      this.handleTabChangeEvent(ExpertProfileTab.AVAILABILITY)
                    }
                  >
                    {t("availability")}
                  </Button>
                )}
              </ButtonGroup>
            </div>
          )}

          <TabContent activeTab={activeTab}>
            <TabPane tabId={ExpertProfileTab.PROFILE}>
              {angle && (
                <div className="angleContainer">
                  <span className="angleText">{t("assignedAngle")}</span>
                  <AssignedAngle angle={angle} />
                </div>
              )}
              {note?.note && <ExpertReferralNote note={note} />}
              <ExpertProfilePreview
                endorsements={endorsements}
                background={background}
                workHistory={workHistories}
                linkedInUrl={linkedinProfile}
                uiExpertRate={uiExpertRate}
                availabilitySlots={expertAvailabilitySlots}
                renderAvailabilityTab={this.handleTabAvailabilityEvent}
                hideChooseTimeSlots={false}
                displayPublicCompanies={
                  expert.lastPublicCompanyExpDate !== null
                }
                doNotContactStatus={doNotContactStatus}
              />
            </TabPane>
            {!isApplicationDeclined && (
              <>
                {hasScreeningQuestions && isAnyAnswered && (
                  <TabPane tabId={ExpertProfileTab.SCREENING_QUESTIONS}>
                    <ExpertAnswersTab questions={answers} />
                  </TabPane>
                )}
                {isApplied && hasCompliance && (
                  <TabPane tabId={ExpertProfileTab.COMPLIANCE}>
                    <ComplianceTab
                      compliances={compliances}
                      expertName={expertName}
                      complianceStatus={ComplianceStatus}
                      complianceUpdatedBy={updateUser}
                      complianceNote={complianceNote}
                      chaperoneCall={chaperoneCall}
                      isComplianceLoading={isComplianceLoading}
                      canReviewCompliance={canReviewCompliance}
                      updateDate={updateDate}
                      onComplianceSubmit={this.handleComplianceStatusSubmit}
                      doNotContactStatus={doNotContactStatus}
                    />
                  </TabPane>
                )}
                {hasAvailabilitySlots && (
                  <TabPane tabId={ExpertProfileTab.AVAILABILITY}>
                    {this.renderAvailabilityTab()}
                  </TabPane>
                )}
              </>
            )}
          </TabContent>
        </div>
        <ModalWithChildren
          toggleModal={this.state.isBainModalOpen}
          messageTitle={t("companiesCID")}
          confirmActionText={t("passCID")}
          cancelActionText={t("reviewLater")}
          onCancel={() => this.setState({ isBainModalOpen: false })}
          onConfirm={() => {
            this.setState({ isBainModalOpen: false });
            if (isRequestTimeSlot) {
              this.requestTimesSlots();
            } else {
              this.createEvent(
                this.state.selectedSlot,
                this.state.timezoneEventData
              );
            }
          }}
        >
          <BainConfirmation
            expertId={this.state.uiExpertProfile.expert.id}
            expertCurrentCompanies={this.props.expertCurrentCompanies}
          />
        </ModalWithChildren>
      </div>
    );
  };

  render(): JSX.Element {
    const { isProfileLoading } = this.state;

    if (isProfileLoading) return <Loader isSidePanel />;

    return this.renderExpertProfile();
  }
}

const mapStateToProps = createStructuredSelector<
  AppState,
  ExpertProfileHolderProps,
  ExpertProfileHolderStoreProps
>({
  projectName: ProjectSelector.projectNameSelector(),
  activeProjectId: ProjectSelector.projectIdSelector(),
  clientId: AuthSelector.authClientIdSelector(),
  hasCompliance: ProjectSelector.projectHasComplianceSelector(),
  clientFeatures: AuthSelector.authClientUserFeaturesSelector(),
  projectTimezone: ProjectSelector.projectTimezoneSelector(),
  timezones: AppSelector.appTimeZoneSelector(),
  loggedInUserClientId: CacheSelector.loggedInUserClientId(),
  expertCurrentCompanies: ProjectExpertsSelector.expertCurrentCompanies()
});

const mapDispatchToProps = (dispatch: Dispatch): Record<string, AppAction> => ({
  refetchReferralSummary: (projectId: string): AppAction =>
    dispatch(ProjectStoreActions.refetchReferralSummary(projectId)),
  navigateToCreateEvent: (
    tabRouteName: string,
    initData: CreateEventRouterData
  ): AppAction => dispatch(push(tabRouteName, initData)),
  navigateToCalendar: (calendarRouteName: string): AppAction =>
    dispatch(push(calendarRouteName)),
  updateReferralStatus: (
    projectId: string,
    referralId: string,
    status: "Accept" | "Reject"
  ): AppAction =>
    dispatch(
      ProjectExpertsStoreActions.updateReferralStatus(projectId, referralId, {
        review: status
      })
    )
});

const storeConnectedExpertProfileHolder = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpertProfileHolder);

const WrappedExpertProfileHolder = withTranslation("expertProfile")(
  // @ts-ignore
  storeConnectedExpertProfileHolder
);

export default WrappedExpertProfileHolder;
