import React from "react";

import { DateString } from "@arbolus-technologies/models/common";
import { ExpertUser } from "@arbolus-technologies/models/expert";

import { ArbolusAvatar } from "../ArbolusAvatar/ArbolusAvatar";

import styles from "./ExpertHeader.module.scss";

interface ExpertHeaderProps {
  expertUser: ExpertUser;
  countryTwoLetterCode?: string | null;
  lastPublicCompanyDate: DateString | null;
  countryName?: string;
  linkedInProfile?: string;
  children?: React.ReactNode;
}

export const ExpertHeader: React.FC<ExpertHeaderProps> = ({
  expertUser,
  countryTwoLetterCode,
  lastPublicCompanyDate,
  countryName,
  linkedInProfile,
  children
}) => {
  const { firstName, lastName, profileImageUrl, title } = expertUser;

  return (
    <div className={styles.userInfoWrapper}>
      <ArbolusAvatar
        firstName={firstName}
        lastName={lastName}
        avatarType="mediumCircle"
        profileImageUrl={profileImageUrl}
        title={title}
        countryTwoLetterCode={countryTwoLetterCode}
        lastPublicCompanyDate={lastPublicCompanyDate}
        countryName={countryName}
        linkedInProfile={linkedInProfile}
      />
      {children}
    </div>
  );
};
