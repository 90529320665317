import clsx from "clsx";
import React from "react";
import { Progress } from "reactstrap";

import styles from "./ProgressComponent.module.scss";

interface ProgressComponentProps {
  value: number;
  hasValue?: boolean;
}

export const ProgressComponent: React.FC<ProgressComponentProps> = ({
  value,
  hasValue
}) => (
  <div className={clsx(styles.container, { [styles.borderLeft]: hasValue })}>
    <Progress value={value} data-testid="progress-bar" />
  </div>
);
