import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Table } from "reactstrap";

import { Optional } from "@arbolus-technologies/utils";

import { Loader } from "../Loader/Loader";
import { CIQTableHeader } from "./CIQTableHeader";
import { SetOption } from "./filters/TableSetFilter";

import styles from "./CIQTable.module.scss";

type HasRenderer<T> = {
  renderer: (value: T) => JSX.Element;
  valueName?: keyof T & string;
};

type HasValueName<T> = {
  renderer?: (value: T) => JSX.Element;
  valueName: keyof T & string;
};

type RendererOrValueName<T> = HasRenderer<T> | HasValueName<T>;

export type TextFilterOptions = {
  type: "text";
  debounceTime?: number;
  minLength?: number;
  onFilterChange: (text: Optional<string>) => void;
};

type SetFilterOptions<T> = {
  type: "set";
  options: SetOption<T>[];
  onFilterChange: (option: Optional<T>) => void;
};

export type CIQTableColumn<T, U = never> = RendererOrValueName<T> & {
  displayName: string;
  width?: number;
  filterOptions?: TextFilterOptions | SetFilterOptions<U>;
};

interface CIQTableProps<T, U> {
  list: Readonly<T[]>;
  columns: CIQTableColumn<T, U>[];
  elementKey: keyof T & string;
  selectable?: boolean;
  multipleSelection?: boolean;
  initialSelectionKey?: PropertyType<T> | PropertyType<T>[];
  isLoading?: boolean;
  noResultsMessage?: string;
  onSelectionChange?: (elements: T[]) => void;
}

type PropertyType<T> = T[keyof T];

export const CIQTable = <T, U>({
  list,
  columns,
  elementKey,
  selectable = false,
  multipleSelection = false,
  isLoading = false,
  initialSelectionKey,
  noResultsMessage,
  onSelectionChange
}: CIQTableProps<T, U>): JSX.Element => {
  const { t } = useTranslation("table");
  const getInitialSelectionState = useCallback(():
    | PropertyType<T>[]
    | undefined => {
    if (!initialSelectionKey) {
      return undefined;
    }

    return Array.isArray(initialSelectionKey)
      ? initialSelectionKey
      : [initialSelectionKey];
    // TS does not know how to treat `T` https://github.com/facebook/react/issues/19808
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSelectionKey]);

  const [currentSelection, setCurrentSelection] = useState<
    PropertyType<T>[] | undefined
  >(getInitialSelectionState());

  const getElements = (keys: PropertyType<T>[]): T[] =>
    list.filter((element) => keys.some((key) => element[elementKey] === key));

  const handleCheckboxClick = (element: T): void => {
    if (multipleSelection) {
      handleMultiSelectCheckboxClick(element);
    } else {
      handleSingleCheckboxClick(element);
    }
  };

  const handleSingleCheckboxClick = (element: T): void => {
    if (currentSelection?.length) {
      setCurrentSelection([]);
      onSelectionChange && onSelectionChange([]);
    } else {
      setCurrentSelection([element[elementKey]]);
      onSelectionChange && onSelectionChange([element]);
    }
  };

  const handleMultiSelectCheckboxClick = (element: T): void => {
    if (currentSelection?.some((key) => key === element[elementKey])) {
      const current = currentSelection ?? [];
      const newSelection = current.filter((key) => key !== element[elementKey]);
      setCurrentSelection(newSelection);
      onSelectionChange && onSelectionChange(getElements(newSelection));
    } else {
      const current = currentSelection ?? [];
      const newSelection = [...current, element[elementKey]];
      setCurrentSelection(newSelection);
      onSelectionChange && onSelectionChange(getElements(newSelection));
    }
  };

  const isSelected = (element: T): boolean =>
    currentSelection?.some((key) => element[elementKey] === key) ?? false;

  useEffect(() => {
    setCurrentSelection(getInitialSelectionState());
  }, [list, getInitialSelectionState]);

  return (
    <>
      <Table className={clsx(styles.table, { [styles.loading]: isLoading })}>
        <thead>
          <tr className={styles.row}>
            {selectable && (
              <th
                className={clsx(styles.headerCell, styles.checkboxHeader)}
                aria-label="select-column"
              />
            )}
            {columns.map((column) => (
              <CIQTableHeader key={column.displayName} column={column} />
            ))}
          </tr>
        </thead>
        <tbody className={styles.tableBody}>
          {list.map((e) => (
            <tr
              className={styles.row}
              key={e[elementKey] as unknown as React.Key}
            >
              {selectable && (
                <td className={styles.checkboxContainer}>
                  <Input
                    className={styles.checkbox}
                    type="checkbox"
                    checked={isSelected(e)}
                    onChange={() => handleCheckboxClick(e)}
                  />
                </td>
              )}
              {columns.map((c, i) => (
                <td
                  key={`${c.valueName ?? i}_${e[elementKey]}`}
                  style={c.width ? { width: `${c.width}px` } : {}}
                >
                  {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                  {c?.renderer ? c.renderer(e) : (e[c.valueName!] as any)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      {list.length === 0 && <div>{noResultsMessage ?? t("noResults")}</div>}
      {isLoading && (
        <div className={clsx(styles.loadingContainer)}>
          <Loader />
        </div>
      )}
    </>
  );
};
