export const mainRoute = "/";
export const projectsRoute = "/projects";
export const canopiesRoute = "/canopies";
export const arbolusCanopyRoute = "/surveys";
export const bookmarksRoute = "/bookmarks";
export const clientsAdRoute = "/clients";
export const clientsRoute = "/client-network";
export const clientAccountRoute = "/client-account";
export const teamMembersRoute = "/users";
export const inboxRoute = "/inbox";
export const profileEditRoute = "/profile-edit";
export const expertNetworkRoute = "/expert-network";
export const accountRoute = "/account";
export const accountRouteAdmin = "/client-account";
export const accountRouteForAdmin = (clientId: string): string =>
  `/client/${clientId}/details`;
export const transactionsRoute = "/transactions";
export const invoicesRoute = "/invoices";
