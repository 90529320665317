import {
  AlignmentType,
  Document,
  Footer,
  Header,
  HorizontalPositionRelativeFrom,
  HyperlinkRef,
  HyperlinkType,
  Media,
  PageNumber,
  Paragraph,
  PictureRun,
  TextRun,
  TextWrappingType,
  VerticalPositionRelativeFrom
} from "docx";
import i18next from "i18next";

import { MonologueData } from "@arbolus-technologies/models/common";

import { PLATFORM_AGREEMENT } from "../externalLink";
import {
  ARBOLUS_LOGO_BASE_64,
  FONT_FAMILY,
  HEADER_TITLE,
  TRANSCRIPT_BORDER_SIZE,
  TRANSCRIPT_COLORS,
  TRANSCRIPT_FONT_SIZE
} from "../transcriptCreator";

export class TranscriptCreator {
  public create(
    transcript: MonologueData[],
    meetingTitle: string,
    meetingDetails: string
  ): Document {
    const document = new Document({
      styles: {
        paragraphStyles: [
          {
            id: "exLinkStyle",
            run: {
              font: FONT_FAMILY,
              color: TRANSCRIPT_COLORS.HEADER_MESSAGE_BLUE
            }
          }
        ]
      },
      hyperlinks: {
        arbolusLink: {
          link: PLATFORM_AGREEMENT,
          text: i18next.t("transcript:messagePart2"),
          type: HyperlinkType.EXTERNAL
        }
      }
    });

    document.addSection({
      properties: {
        titlePage: true
      },
      margins: {
        right: 1000,
        left: 1000,
        top: 2000,
        header: 800,
        footer: 1000
      },
      headers: {
        first: new Header({
          children: [
            new Paragraph({
              children: [
                this.generateLogo(document),
                new TextRun({
                  text: HEADER_TITLE,
                  font: FONT_FAMILY
                })
              ]
            })
          ]
        })
      },
      footers: {
        default: new Footer({
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  font: FONT_FAMILY,
                  children: ["Page ", PageNumber.CURRENT]
                })
              ]
            })
          ]
        })
      },
      children: [
        this.createHeaderMessage(),
        this.createMeetingTitle(meetingTitle),
        this.createMeetingDetails(meetingDetails),
        ...transcript
          .map((tr) => [
            this.createSpeakerName(tr.speakerName),
            this.createMonologue(tr.monologue)
          ])
          .reduce((prev, curr) => prev.concat(curr), [])
      ]
    });

    return document;
  }

  private generateLogo(doc: Document): PictureRun {
    return Media.addImage(
      doc,
      Uint8Array.from(atob(ARBOLUS_LOGO_BASE_64), (c) => c.charCodeAt(0)),
      20,
      20,
      {
        floating: {
          horizontalPosition: {
            relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
            offset: 621410
          },
          verticalPosition: {
            relative: VerticalPositionRelativeFrom.TOP_MARGIN,
            offset: 481400
          },
          wrap: {
            type: TextWrappingType.SQUARE
          }
        }
      }
    );
  }

  private createHeaderMessage(): Paragraph {
    return new Paragraph({
      alignment: AlignmentType.CENTER,
      style: "exLinkStyle",
      spacing: {
        after: 700,
        before: 700
      },
      border: {
        bottom: {
          color: TRANSCRIPT_COLORS.RED_BORDER,
          size: TRANSCRIPT_BORDER_SIZE,
          space: 5,
          value: "single"
        },
        top: {
          color: TRANSCRIPT_COLORS.RED_BORDER,
          size: TRANSCRIPT_BORDER_SIZE,
          space: 5,
          value: "single"
        },
        right: {
          color: TRANSCRIPT_COLORS.RED_BORDER,
          size: TRANSCRIPT_BORDER_SIZE,
          space: 5,
          value: "single"
        },
        left: {
          color: TRANSCRIPT_COLORS.RED_BORDER,
          size: TRANSCRIPT_BORDER_SIZE,
          space: 5,
          value: "single"
        }
      },
      children: [
        new TextRun({
          text: i18next.t("transcript:messagePart1"),
          font: FONT_FAMILY,
          color: TRANSCRIPT_COLORS.HEADER_MESSAGE
        }),
        new HyperlinkRef("arbolusLink"),
        new TextRun({
          text: i18next.t("transcript:messagePart3"),
          font: FONT_FAMILY,
          color: TRANSCRIPT_COLORS.HEADER_MESSAGE
        }).break()
      ]
    });
  }

  private createMeetingTitle(meetingTitle: string): Paragraph {
    return new Paragraph({
      alignment: AlignmentType.LEFT,
      spacing: {
        after: 150
      },
      children: [
        new TextRun({
          text: meetingTitle,
          bold: true,
          font: FONT_FAMILY,
          size: TRANSCRIPT_FONT_SIZE.MEETING_TITLE,
          color: TRANSCRIPT_COLORS.TITLE
        })
      ]
    });
  }

  private createMeetingDetails(meetingDetails: string): Paragraph {
    return new Paragraph({
      alignment: AlignmentType.LEFT,
      border: {
        bottom: {
          color: TRANSCRIPT_COLORS.BORDER,
          space: 15,
          value: "single",
          size: TRANSCRIPT_BORDER_SIZE
        }
      },

      spacing: {
        after: 700
      },
      children: [
        new TextRun({
          text: `${i18next.t("transcript:transcript")} - ${meetingDetails}`,
          font: FONT_FAMILY,
          color: TRANSCRIPT_COLORS.DETAILS
        })
      ]
    });
  }

  private createSpeakerName(speakerName: string): Paragraph {
    return new Paragraph({
      alignment: AlignmentType.LEFT,
      spacing: {
        after: 300
      },
      children: [
        new TextRun({
          bold: true,
          text: speakerName,
          font: FONT_FAMILY,
          size: TRANSCRIPT_FONT_SIZE.SPEAKER_NAME,
          color: TRANSCRIPT_COLORS.SPEAKER
        })
      ]
    });
  }

  private createMonologue(caption: string): Paragraph {
    return new Paragraph({
      alignment: AlignmentType.LEFT,
      spacing: {
        after: 700
      },
      children: [
        new TextRun({
          text: caption,
          font: FONT_FAMILY,
          size: TRANSCRIPT_FONT_SIZE.SPEAKER_MONOLOGUE
        })
      ]
    });
  }
}

export const DefaultTranscriptCreator = new TranscriptCreator();
