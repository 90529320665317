import { saveAs } from "file-saver";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  BookmarkService,
  DefaultToasterService,
  FileResponse,
  ToasterService
} from "@arbolus-technologies/api";
import { Button } from "@arbolus-technologies/ui/components";
import { string64toBlob } from "@arbolus-technologies/utils";

import styles from "./ExportCollectionButton.module.scss";

const blobTypeExtension =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

interface ExportCollectionButtonProps {
  bookmarkCollectionId: string;
  bookmarkService?: typeof BookmarkService;
  notificationService?: ToasterService;
}

// TO_REPLACE (this one needs the single icon component)

export const ExportCollectionButton: React.FC<ExportCollectionButtonProps> = ({
  bookmarkCollectionId,
  bookmarkService = BookmarkService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("bookmarkCollectionActions");
  const [disabled, setDisabled] = useState(false);

  const handleExportCollection = useCallback(() => {
    setDisabled(true);

    bookmarkService.exportBookmarks(bookmarkCollectionId).subscribe({
      next: (response: FileResponse) => {
        const blob = string64toBlob(response.file, blobTypeExtension);
        saveAs(blob, response.name);
        setDisabled(false);
      },
      error: (error) => {
        notificationService.showError(error.message);
        setDisabled(false);
      }
    });
  }, [bookmarkCollectionId, bookmarkService]);

  return (
    <>
      <div className={styles.onlyIcon}>
        <Button
          type="iconButton"
          centerIcon="download"
          onClick={handleExportCollection}
          disabled={disabled}
        />
      </div>
      <div className={styles.fullButton}>
        <Button
          type="secondary"
          text={t("export")}
          endIcon="download"
          onClick={handleExportCollection}
          disabled={disabled}
        />
      </div>
    </>
  );
};
