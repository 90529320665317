import { PROJECT_APPLICATION_STEPS } from "./projectApplicationTypes";

export function getStepAfter(
  applicationSteps: PROJECT_APPLICATION_STEPS[],
  stepToMoveFrom: PROJECT_APPLICATION_STEPS
): PROJECT_APPLICATION_STEPS {
  // We are assuming that the stepToMoveFrom is always in the applicationSteps array
  const nextStep = applicationSteps.indexOf(stepToMoveFrom) + 1;
  return applicationSteps[nextStep];
}
