import { LocationDescriptorObject } from "history";

import { CANOPY_ROUTE } from "@arbolus-technologies/routes";
import { formatDateBasedOnToday } from "@arbolus-technologies/utils";

import { BaseNotification } from "./BaseNotification";

export abstract class BaseCanopyNotification extends BaseNotification {
  subtitle: string;
  link: LocationDescriptorObject;

  constructor(id: string, canopyId: string, created: string) {
    super(id, "workspaces");
    this.subtitle = formatDateBasedOnToday(created);
    this.link = { pathname: CANOPY_ROUTE(canopyId) };
  }
}
