import { sample } from "lodash";

import { AngleColors } from "@arbolus-technologies/models/project";

export const angleColors: AngleColors[] = [
  "#FFCC00",
  "#FFAD9D",
  "#96BFFF",
  "#94E8A2",
  "#DBA8ED",
  "#B1B9FF",
  "#ADE4F7",
  "#CDE894",
  "#F5CDED",
  "#FFE580"
];

export const getRandomAngleColor = (): AngleColors =>
  sample(angleColors) as AngleColors;
