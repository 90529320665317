import i18next from "i18next";

import {
  PROJECT_REFERRAL_STATE,
  ReferralDetail
} from "@arbolus-technologies/api";
import {
  IAvatar,
  Permission,
  REFERRAL_COMPLIANCE_STATE,
  REFERRAL_SUB_STATE
} from "@arbolus-technologies/models/common";
import {
  AngleChat,
  AngleChatData,
  ChatType,
  ChatUserRole,
  DropdownAngle
} from "@arbolus-technologies/models/project";
import { arbolusLogoWithBackground } from "@arbolus-technologies/theme";

import { isAngleChat, isProjectChat } from "../../helpers";

export const getExpertSupportChats = (getExpertSupportChats: {
  showAllAngles: boolean;
  selectedAngle: DropdownAngle;
  expertToArbolusChats?: Record<string, AngleChat>;
}): AngleChat[] => {
  const { showAllAngles, selectedAngle, expertToArbolusChats } =
    getExpertSupportChats;
  if (!expertToArbolusChats) {
    return [];
  }
  if (showAllAngles) {
    return Object.values(expertToArbolusChats);
  } else if (selectedAngle && expertToArbolusChats[selectedAngle.id]) {
    return [expertToArbolusChats[selectedAngle.id]];
  }
  return [];
};

export function getTitle(
  chatType: ChatType,
  name?: string
): string | undefined {
  const t = (key: string) => i18next.t(`chat:${key}`);

  if (isAngleChat(chatType)) {
    return t("angleChatTitle");
  }
  if (isProjectChat(chatType)) {
    return t("projectChatTitle");
  }
  if (chatType === ChatType.ExpertToAdminTeam) {
    return t("arbolusTeamChatTitle");
  }
  return name;
}

export const getChatListItemType = (
  chatSenderRole: ChatUserRole,
  expertSupportChatActive?: boolean
): ChatType => {
  if (expertSupportChatActive) {
    return ChatType.AdminToExpertSupport;
  } else if (chatSenderRole === ChatUserRole.Admin) {
    return ChatType.AdminToExpertAndClient;
  }
  return ChatType.ClientToExpertAndAdmin;
};

export const chatListItemAvatarsSorted = (
  chat: AngleChatData,
  expertSupportChatActive: boolean,
  client: { name?: string; imageUrl?: string }
): IAvatar[] => {
  let chatListItemAvatars: IAvatar[] = [
    {
      name: i18next.t("chat:arbolusTeamAvatarName"),
      imageUrl: arbolusLogoWithBackground
    },
    { name: chat.expertName ?? "", imageUrl: chat.expertAvatar }
  ];
  if (!expertSupportChatActive) {
    chatListItemAvatars = [
      ...chatListItemAvatars.slice(0, 1),
      { name: client.name ?? "", imageUrl: client.imageUrl },
      ...chatListItemAvatars.slice(1)
    ];
  }
  return chatListItemAvatars;
};

export type ChatErrors = {
  referralNotAccepted: boolean;
  isReviewPending: boolean;
  isReviewRejected: boolean;
  isApplicationPending: boolean;
  isApplicationRejected: boolean;
  isCompliancePending: boolean;
  isComplianceRejected: boolean;
  doNotContact: boolean;
};

export function getChatToExpertErrors(
  referral: ReferralDetail,
  hasComplianceCheck: boolean,
  isDncExpertChat: boolean
): ChatErrors {
  return {
    referralNotAccepted: referral.status !== PROJECT_REFERRAL_STATE.ACCEPT,
    isReviewPending: referral.review.subStatus === REFERRAL_SUB_STATE.PENDING,
    isReviewRejected: referral.review.subStatus === REFERRAL_SUB_STATE.REJECT,
    isApplicationPending:
      referral.application.subStatus === REFERRAL_SUB_STATE.PENDING,
    isApplicationRejected:
      referral.application.subStatus === REFERRAL_SUB_STATE.REJECT,
    isCompliancePending: hasComplianceCheck
      ? referral.compliance.subStatus === REFERRAL_COMPLIANCE_STATE.PENDING
      : false,
    isComplianceRejected: hasComplianceCheck
      ? referral.compliance.subStatus === REFERRAL_COMPLIANCE_STATE.REJECT
      : false,
    doNotContact: isDncExpertChat
  };
}

export function isChatToExpertEnabled(
  referral: ReferralDetail,
  hasComplianceCheck: boolean,
  isDncExpertChat: boolean
): boolean {
  const compliancePassed = hasComplianceCheck
    ? referral.compliance.subStatus === REFERRAL_COMPLIANCE_STATE.ACCEPT
    : true;

  return (
    referral.status === PROJECT_REFERRAL_STATE.ACCEPT &&
    referral.review.subStatus === REFERRAL_SUB_STATE.ACCEPT &&
    referral.application.subStatus === REFERRAL_SUB_STATE.ACCEPT &&
    compliancePassed &&
    !isDncExpertChat
  );
}

export function isMobileChat(): boolean {
  return window.matchMedia("(max-width: 895px)").matches;
}

export const adminCanDeleteIncomingMessages = (
  chatSenderRole: ChatUserRole,
  adminSpecialPermissions: Permission[]
): boolean => {
  if (chatSenderRole !== ChatUserRole.Admin) {
    return false;
  }
  const chatDeleteMessagesPermission = adminSpecialPermissions.find(
    (specialPermission) =>
      specialPermission.claimValue === "CHAT_DELETE_MESSAGES"
  );
  return chatDeleteMessagesPermission?.active === true;
};
