import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import { LocationDescriptorObject } from "history";
import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { HR, LinkNoBorder } from "@arbolus-technologies/ui/components";

import styles from "./MainPageHeader.module.scss";

export interface MainPageBackLink {
  text: string;
  to: string | LocationDescriptorObject;
}

interface MainPageHeaderProps {
  title?: string;
  iconName?: string;
  rightButtonContainer?: React.ReactNode;
  topRightButtonContainer?: React.ReactNode;
  backLink?: MainPageBackLink;
}

export const MainPageHeader: React.FC<MainPageHeaderProps> = ({
  title,
  iconName,
  rightButtonContainer,
  topRightButtonContainer,
  backLink
}) => (
  <>
    <div
      className={clsx(
        styles.headerContainer,
        backLink && styles.withBackLink,
        !title && styles.withoutTitle
      )}
    >
      {(backLink || topRightButtonContainer) && (
        <div className={styles.topContainer}>
          {backLink && (
            <LinkNoBorder
              name={backLink.text}
              to={backLink.to}
              leftIconName="chevron_left"
              size="regular"
            />
          )}
          {topRightButtonContainer ?? null}
        </div>
      )}
      {title && (
        <div className={styles.bottomContainer}>
          <div className={styles.titleContainer}>
            {iconName && (
              <Icon
                name={iconName}
                fontSize="22px"
                color={ARBOLUS_COLORS.bColorBasePurple}
              />
            )}
            <h1 className={styles.mainHeader}>{title}</h1>
          </div>
          {rightButtonContainer ?? null}
        </div>
      )}
    </div>
    <HR margin={{ top: 0, bottom: 3 }} />
  </>
);
