import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Collapse } from "reactstrap";

import { ExpertAvailability } from "@arbolus-technologies/api";
import {
  REFERRAL_STATUS,
  REFERRAL_SUB_STATE
} from "@arbolus-technologies/models/common";
import { ScheduleProjectData } from "@arbolus-technologies/models/project";
import {
  AssignedAngle,
  Avatar,
  ChevronButton,
  HR,
  PublicCompanyIcon
} from "@arbolus-technologies/ui/components";
import { displayUserName } from "@arbolus-technologies/utils";

import { TimeSlot } from "./TimeSlot/TimeSlot";

import styles from "./ScheduleCard.module.scss";

interface ScheduleCardProps {
  expertAvailability: ExpertAvailability;
  project: ScheduleProjectData;
  handleClickRow: (
    expertId: string,
    referralId: string,
    openAvailabilityTab?: boolean
  ) => void;
}

export const ScheduleCard: React.FC<ScheduleCardProps> = ({
  expertAvailability,
  project,
  handleClickRow
}) => {
  const { t } = useTranslation("scheduleTab");
  const { expert, slots } = expertAvailability;
  const {
    email,
    firstName,
    lastName,
    profileImageUrl,
    angle,
    status,
    complianceStatus,
    tagline,
    referralId,
    expertId
  } = expert;

  const isCompliancePending = complianceStatus === REFERRAL_SUB_STATE.PENDING;
  const expertName = displayUserName({ email, firstName, lastName }, true);
  const [isSlotsOpen, setIsSlotsOpen] = useState(false);

  return (
    <div className={styles.scheduleCard}>
      <div className={styles.topContainer}>
        <AssignedAngle angle={angle} />
        <div className={styles.status}>
          {status === REFERRAL_STATUS.ACCEPT && (
            <>
              <div className={styles.circleCompleted} />
              <span>{t("approved")}</span>
            </>
          )}
          {status === REFERRAL_STATUS.CANDIDATE && (
            <>
              <div className={styles.circleCandidate} />
              <div>{t("candidate")}</div>
            </>
          )}
        </div>
      </div>
      <div
        className={styles.body}
        onClick={() => handleClickRow(expertId, referralId)}
      >
        <Avatar
          avatar={{
            name: expertName,
            imageUrl: profileImageUrl
          }}
          type="mediumXSCircle"
        />
        <div className={styles.nameTaglineWrapper}>
          <div className={styles.nameWrapper}>
            <span className={styles.name} title={expertName}>
              {expertName}
            </span>
            {expert.lastPublicCompanyExpDate && (
              <div className={styles.publicCompanyIcon}>
                <PublicCompanyIcon date={expert.lastPublicCompanyExpDate} />
              </div>
            )}
          </div>
          <span className={styles.tagline} title={tagline || ""}>
            {tagline}
          </span>
        </div>
      </div>
      <TimeSlot
        index={0}
        slot={slots[0]}
        isCompliancePending={isCompliancePending}
        expert={expert}
        project={project}
      />
      {isSlotsOpen && <HR noMargin />}
      <Collapse isOpen={isSlotsOpen}>
        {slots.map((slot, index) => (
          <React.Fragment key={slot.startTime.toString()}>
            {index > 1 && <HR noMargin />}
            {index > 0 && (
              <TimeSlot
                index={index}
                slot={slot}
                isCompliancePending={isCompliancePending}
                expert={expert}
                project={project}
              />
            )}
          </React.Fragment>
        ))}
      </Collapse>
      {slots.length > 1 && (
        <div className={styles.toggleButtonContainer}>
          <ChevronButton
            icon={{
              direction: isSlotsOpen ? "up" : "down",
              position: "end"
            }}
            text={t(isSlotsOpen ? "hideSlots" : "showSlots", {
              slots: slots.length - 1
            })}
            fontWeight={400}
            onClick={() => setIsSlotsOpen((prev) => !prev)}
          />
        </div>
      )}
    </div>
  );
};
