import clsx from "clsx";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Input, InputGroup, InputGroupAddon } from "reactstrap";
import { Subscription } from "rxjs";
import SimpleBar from "simplebar-react";

import { ToasterService } from "@arbolus-technologies/api";
import { Loader } from "@arbolus-technologies/ui/components";

import { CIQError, ErrorResponse } from "../../../../models/api";
import { ListProjectBase } from "../../../../models/project";
import { ProjectService } from "../../../../services";
import { clientService } from "../../../../services/ClientService";
import { CIQNoResults } from "../../../app/components";

const notification = new ToasterService();

interface UserProjectSearchProps {
  clientId: string;
  userId: string;
  onProjectClick: (project: ListProjectBase) => void;
}

interface UserProjectSearchState {
  projects: ListProjectBase[];
  isLoading: boolean;
  searchText: string;
}

type UsersPageIntersectProps = UserProjectSearchProps & WithTranslation;

class UserProjectSearch extends React.Component<
  UsersPageIntersectProps,
  UserProjectSearchState
> {
  constructor(props: UsersPageIntersectProps) {
    super(props);
    this.state = {
      projects: [],
      isLoading: false,
      searchText: ""
    };
  }

  componentDidMount(): void {
    const { clientId, userId } = this.props;
    this.setState({ isLoading: true });

    this.getTeamMembersProjectsSubscription = clientService
      .getTeamMembersCandidateProjects(clientId, userId)
      .subscribe(
        ({ items }) => {
          this.setState({ projects: items });
        },
        (err: ErrorResponse<CIQError>) => notification.showError(err.message),
        () => this.setState({ isLoading: false })
      );
  }

  componentWillUnmount(): void {
    this.getTeamMembersProjectsSubscription?.unsubscribe();
    this.addProjectMemberSubscription?.unsubscribe();
  }

  private getTeamMembersProjectsSubscription?: Subscription;

  private addProjectMemberSubscription?: Subscription;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleInputChangeEvent = (event: any): void => {
    this.setState({ searchText: event.target.value });
  };

  handleOnProjectClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    project: ListProjectBase
  ): void => {
    const { onProjectClick, userId, t } = this.props;
    this.setState({ isLoading: true });

    this.addProjectMemberSubscription = ProjectService.addProjectMember(
      project.id,
      userId
    ).subscribe(
      () => {
        notification.showSuccess(t("userAddedSuccessMessage"));
        onProjectClick(project);
      },
      (error: ErrorResponse<CIQError>) => notification.showError(error.message),
      () => this.setState({ isLoading: false })
    );
  };

  renderProjectList = (): JSX.Element => {
    const { t } = this.props;
    let projectList = this.state.projects;

    if (this.state.searchText) {
      projectList = this.state.projects.filter((project) =>
        project.name.toLowerCase().includes(this.state.searchText.toLowerCase())
      );
    }

    if (!projectList.length) {
      return <CIQNoResults message={t("noResults")} />;
    }

    return (
      <ul>
        {projectList.map((project) => (
          <li
            style={{ cursor: "pointer" }}
            key={project.id}
            onClick={(
              event: React.MouseEvent<HTMLLIElement, MouseEvent>
            ): void => this.handleOnProjectClick(event, project)}
          >
            {project.name}
          </li>
        ))}
      </ul>
    );
  };

  renderContent = (): JSX.Element => {
    const { t } = this.props;
    return this.state.projects.length ? (
      <>
        <div className="search-column">
          <InputGroup className="search-input-white">
            <Input
              autoComplete="off"
              placeholder="Search Project"
              name="searchQuery"
              value={this.state.searchText}
              onClick={(event): void => event.stopPropagation()}
              onChange={this.handleInputChangeEvent}
            />
            <InputGroupAddon addonType="append">
              <span
                onClick={(): void => {
                  this.setState({ searchText: "" });
                }}
                className={clsx("ciq-icon", {
                  "ciq-search": !this.state.searchText,
                  "ciq-close-circle": this.state.searchText
                })}
              />
            </InputGroupAddon>
          </InputGroup>
        </div>
        <SimpleBar
          className="simplebar-light"
          style={{
            width: `100%`,
            maxHeight: `200px`,
            overflowX: "hidden"
          }}
        >
          <div className="search-list">{this.renderProjectList()}</div>
        </SimpleBar>
      </>
    ) : (
      <CIQNoResults message={t("noProjectsToAdd")} />
    );
  };

  render(): JSX.Element {
    const { isLoading } = this.state;

    return (
      <div className="project-popover-container">
        {isLoading ? <Loader /> : this.renderContent()}
      </div>
    );
  }
}

export default withTranslation("usersPage")(UserProjectSearch);
