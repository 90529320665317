import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "arbolus-ui-components";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { TiptapEditorController } from "@arbolus-technologies/ui/components";

import {
  ExpertBackgroundFormValues,
  ExpertBackgroundSchema
} from "./ExpertBackgroundSchema";

import styles from "./EditExpertBackground.module.scss";

interface EditExpertBackgroundProps {
  background?: string;
  updateExpertExperience: (background: string) => void;
}

export const EditExpertBackground: React.FC<EditExpertBackgroundProps> = ({
  background,
  updateExpertExperience
}) => {
  const { t } = useTranslation("editExpertBackground");

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm<ExpertBackgroundFormValues>({
    resolver: yupResolver(ExpertBackgroundSchema),
    mode: "onSubmit",
    defaultValues: {
      background: background || ""
    }
  });

  const onSubmit = (data: ExpertBackgroundFormValues) => {
    updateExpertExperience(data.background);
  };

  return (
    <div className={styles.backgroundContainer}>
      <h3 className={styles.title}>{t("background")}</h3>
      <p className={styles.details}>{t("backgroundInfo")}</p>
      <TiptapEditorController
        control={control}
        placeholder={t("briefPlaceholder")}
        name="background"
        error={errors.background}
      />
      <div className={styles.btnContainer}>
        <Button
          disabled={!isDirty}
          onClick={handleSubmit(onSubmit)}
          text={t("save")}
        />
      </div>
    </div>
  );
};
