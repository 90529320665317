import clsx from "clsx";
import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import {
  DefaultToasterService,
  ToasterService
} from "@arbolus-technologies/api";
import {
  ANSWER_TYPE,
  CanopyParamUrlTypes,
  EXPERT_CANOPY_STATUS,
  QuestionParamUrlTypes,
  VIDEO_MODE_ENUM
} from "@arbolus-technologies/models/canopy";
import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import {
  CANOPY_SEND_ANSWERS_ROUTE,
  EXPERT_CANOPY
} from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  CanopyExpertSelector,
  CanopyExpertStoreActions
} from "@arbolus-technologies/stores/canopy-expert";
import { Button, Loader } from "@arbolus-technologies/ui/components";

import { CanopyEngagementAgreementPanel } from "../../Components/CanopyEngagementAgreementPanel/CanopyEngagementAgreementPanel";
import { QuestionsView } from "../../Components/QuestionsView/QuestionsView";
import { CanopyNavigation } from "./CanopyNavigation";
import { QuestionRoutes } from "./QuestionRoutes";

import styles from "./CanopyDetailsContainer.module.scss";

interface CanopyDetailsContainerProps {
  notificationService?: ToasterService;
}

export const CanopyDetailsContainer: React.FC<CanopyDetailsContainerProps> = ({
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("canopyExpertPage");
  const dispatch = useDispatch();
  const { questionId } = useParams<QuestionParamUrlTypes>();
  const { canopyId } = useParams<CanopyParamUrlTypes>();
  const history = useHistory();

  useEffect(() => {
    dispatch(CanopyExpertStoreActions.resetCanopyAnswerData());
  }, [dispatch]);

  const isVideoSaving = useSelector(CanopyExpertSelector.isCanopyVideoSaving());
  const expertCanopy = useSelector(CanopyExpertSelector.canopySelector());
  const loggedInExpert = useSelector(CacheSelector.loggedInUser());
  const expertId = loggedInExpert.expertId ?? "";
  const isDnc = loggedInExpert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC;

  const isEverythingAnswered = useSelector(
    CanopyExpertSelector.isEverythingAnswered()
  );
  const showRightComponent = useSelector(
    CanopyExpertSelector.showRightComponent()
  );
  const expertQuestionList = useSelector(
    CanopyExpertSelector.questionsSelector()
  );

  const currentQuestion = expertQuestionList.find(
    (question) => question.id === questionId
  );

  const isAnyQuestionAnswered = expertQuestionList.some(
    (question) => question.isAnswered
  );

  useEffect(() => {
    if (questionId) {
      dispatch(CanopyExpertStoreActions.showRightComponent(true));
    }
  }, [dispatch, questionId]);

  useEffect(() => {
    dispatch(
      CanopyExpertStoreActions.enableExpertQuestions(isAnyQuestionAnswered)
    );
  }, [dispatch, isAnyQuestionAnswered]);

  useEffect(() => {
    dispatch(CanopyExpertStoreActions.getCanopyExpert(canopyId, expertId));
  }, [dispatch, canopyId, expertId]);

  useEffect(() => {
    if (expertCanopy) {
      const { canopyExpertStatus } = expertCanopy;
      const isCanopyIncomplete =
        canopyExpertStatus === EXPERT_CANOPY_STATUS.INCOMPLETE;

      if (isCanopyIncomplete && isDnc) {
        history.push(EXPERT_CANOPY);
        notificationService.showError(t("canopyNotAvailableError"));
        dispatch(CanopyExpertStoreActions.resetCanopyAnswerData());
      }
    }
  }, [dispatch, expertCanopy, history, isDnc, notificationService, t]);

  useEffect(() => {
    if (expertCanopy.title) {
      document.title =
        expertCanopy.title.charAt(0).toUpperCase() +
        expertCanopy.title.slice(1);
    }
  }, [expertCanopy]);

  // Initialize question data (load video or record mode)
  useEffect(() => {
    if (currentQuestion) {
      if (currentQuestion.isAnswered) {
        dispatch(
          CanopyExpertStoreActions.getCanopyAnswerData(
            currentQuestion.id,
            expertId
          )
        );
        if (currentQuestion.type === ANSWER_TYPE.VIDEO) {
          dispatch(
            CanopyExpertStoreActions.changeVideoMode(VIDEO_MODE_ENUM.PLAY)
          );
        }
      } else {
        dispatch(CanopyExpertStoreActions.recordQuestion(currentQuestion));
        if (currentQuestion.type === ANSWER_TYPE.VIDEO) {
          dispatch(
            CanopyExpertStoreActions.changeVideoMode(VIDEO_MODE_ENUM.RECORD)
          );
        }
      }
    }
  }, [dispatch, expertId, currentQuestion]);

  return (
    <>
      {!expertCanopy.id && <Loader isFull />}
      {expertCanopy.id && (
        <>
          <div
            className={
              isVideoSaving ? styles.blurContainer : styles.doNotBlurContainer
            }
          >
            <div
              className={clsx(
                isMobile && isEverythingAnswered && showRightComponent
                  ? styles.mainContainerWithMargin
                  : styles.mainContainer
              )}
              data-testid="canopyPage"
            >
              {isMobile ? (
                <>
                  {!showRightComponent && <QuestionsView isDnc={isDnc} />}
                  {showRightComponent && (
                    <div className={styles.rightContainer}>
                      <CanopyNavigation />
                      <QuestionRoutes />
                    </div>
                  )}
                </>
              ) : (
                <div className={styles.desktopContainer}>
                  <QuestionsView isDnc={isDnc} />
                  <div className={styles.rightContainer}>
                    <CanopyNavigation />
                    <QuestionRoutes />
                  </div>
                </div>
              )}
              {!isDnc &&
                isMobile &&
                showRightComponent &&
                isEverythingAnswered && (
                  <div className={styles.isAnswered}>
                    <Button
                      type="primary"
                      startIcon="publish"
                      onClick={() =>
                        history.replace(CANOPY_SEND_ANSWERS_ROUTE(canopyId))
                      }
                      text={t("submitAnswers")}
                    />
                  </div>
                )}
            </div>
          </div>
          {isVideoSaving && (
            <div className={styles.loadingMessageContainer}>
              <div className={styles.loadingMessage}>
                {t("savingVideoAnswer")}
              </div>
              <div className={styles.progressMessage}>
                <Loader isLight />
              </div>
            </div>
          )}
          <CanopyEngagementAgreementPanel
            canopyId={canopyId}
            expertId={expertId}
            isCanopyAgreementAccepted={expertCanopy.isCanopyAgreementAccepted}
          />
        </>
      )}
    </>
  );
};
