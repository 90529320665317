import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  ANSWER_TYPE,
  CanopyQuestionFields,
  CreateEditQuestionFields
} from "@arbolus-technologies/models/canopy";

import { MultiChoiceAnswers } from "./MultiChoiceAnswers/MultiChoiceAnswers";
import { ValueAnswers } from "./ValueAnswers/ValueAnswers";

import styles from "./AnswerTypeContent.module.scss";

export const AnswerTypeContent: React.FC = () => {
  const { t } = useTranslation("canopyBuilder");
  const { watch } = useFormContext<CanopyQuestionFields>();
  const answerType: ANSWER_TYPE = watch(CreateEditQuestionFields.ANSWER_TYPE);

  const renderTypeForm = {
    [ANSWER_TYPE.VIDEO]: null,
    [ANSWER_TYPE.MULTIPLE_CHOICE]: <MultiChoiceAnswers />,
    [ANSWER_TYPE.VALUE]: <ValueAnswers />,
    [ANSWER_TYPE.SHORT_TEXT]: (
      <p className={styles.message}>{t(`shortTextAnswerMessage`)}</p>
    ),
    [ANSWER_TYPE.NPS]: (
      <p className={styles.message}>{t(`npsAnswersMessage`)}</p>
    )
  };

  return (
    renderTypeForm[answerType] && (
      <div className={styles.answerTypeContent}>
        {renderTypeForm[answerType]}
      </div>
    )
  );
};
