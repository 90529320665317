import { FieldArray, Formik, FormikProps } from "formik";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import {
  Answer,
  ProjectApplicationStepProps
} from "@arbolus-technologies/models/project";

import QuestionItem from "../../questionItem/QuestionItem";
import { projectQuestionsValidationSchema } from "../ProjectApplicationSchema";
import { ProjectApplicationWrapper } from "../ProjectApplicationWrapper/ProjectApplicationWrapper";

import styles from "./ProjectQuestions.module.scss";

export interface ProjectQuestionsFormValues {
  questions: Answer[];
}

interface ProjectQuestionsProps extends ProjectApplicationStepProps {
  questions: Answer[];
}

export const ProjectQuestions: React.FC<ProjectQuestionsProps> = ({
  questions,
  onWorkHistoryConfirmed,
  stepDetails,
  isLoading,
  onBack
}) => {
  const { t } = useTranslation("projectApplication");
  const formikRef = useRef<FormikProps<ProjectQuestionsFormValues> | null>(
    null
  );

  const handleSubmit = (values: ProjectQuestionsFormValues): void => {
    if (formikRef.current) {
      onWorkHistoryConfirmed(values, false);
    }
  };

  const renderForm = ({
    values
  }: FormikProps<ProjectQuestionsFormValues>): JSX.Element => (
    <FieldArray name="questions">
      {(): JSX.Element => (
        <div className={styles.projectQuestions}>
          {values.questions.map((question, index) => {
            const name = `questions[${index}].answer`;

            return (
              <QuestionItem
                key={name}
                label={question.question}
                placeholder={t("answer")}
                name={name}
                value={question.answer || ""}
              />
            );
          })}
        </div>
      )}
    </FieldArray>
  );

  return (
    <Formik<ProjectQuestionsFormValues>
      initialValues={{ questions }}
      onSubmit={handleSubmit}
      validateOnChange
      validateOnBlur
      validateOnMount
      validationSchema={projectQuestionsValidationSchema}
      innerRef={formikRef}
    >
      {(formikProps: FormikProps<ProjectQuestionsFormValues>): JSX.Element => {
        const { isValid, values, handleSubmit, dirty } = formikProps;

        return (
          <ProjectApplicationWrapper
            isValid={isValid}
            dirty={dirty}
            stepDetails={stepDetails}
            onSaveProgress={() => onWorkHistoryConfirmed(values, true, dirty)}
            onNext={handleSubmit}
            onBack={onBack}
            isLoading={isLoading}
          >
            <div className={styles.projectQuestionsContainer}>
              <h1>{t("screeningQuestions")}</h1>
              {renderForm(formikProps)}
            </div>
          </ProjectApplicationWrapper>
        );
      }}
    </Formik>
  );
};
