import React from "react";
import { TypeaheadResult } from "react-bootstrap-typeahead";
import { useTranslation } from "react-i18next";
import { Observable } from "rxjs";

import {
  ApiPaginatedRequest,
  ApiPaginatedResponse
} from "@arbolus-technologies/api";
import { Avatar } from "@arbolus-technologies/ui/components";

import {
  SelectController,
  TypeaheadSelectorControllerProps
} from "../SelectTypeahead/SelectController";

import styles from "./ProjectSelector.module.scss";

interface DisplayProject {
  name: string;
  client: {
    name: string;
    logoUrl?: string;
  };
}

export interface ProjectSelectorProps<T extends DisplayProject>
  extends TypeaheadSelectorControllerProps<DisplayProject> {
  getItems: (
    pagination: ApiPaginatedRequest
  ) => Observable<ApiPaginatedResponse<T>>;
}

export const ProjectSelector = <T extends DisplayProject>({
  placeholderText,
  ...props
}: ProjectSelectorProps<T>): React.ReactElement => {
  const { t } = useTranslation();
  const placeholder =
    placeholderText ?? t("common:projectSelection:findProject");

  const renderer = ({
    name,
    client
  }: TypeaheadResult<DisplayProject>): JSX.Element => (
    <div className={styles.itemContainer}>
      <Avatar
        avatar={{
          name: client.name,
          imageUrl: client.logoUrl
        }}
      />
      <span className={styles.itemText}>{name}</span>
      <span className={styles.itemText}>{client.name}</span>
    </div>
  );

  return (
    <SelectController
      {...props}
      placeholderText={placeholder}
      promptText={t("common:projectSelection:findingProjects")}
      searchText={t("common:projectSelection:findingProjects")}
      paginationText={t("common:projectSelection:loadMore")}
      orderBy="name"
      renderer={renderer}
      getLabelKey={(project) => project?.name ?? ""}
    />
  );
};
