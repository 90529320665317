import React, { useCallback } from "react";

import { BookmarkCollection } from "@arbolus-technologies/models/common";
import { CollectionCard } from "@arbolus-technologies/ui/bookmarks";
import { IThreeDotsItem } from "@arbolus-technologies/ui/components";

import { BOOKMARKS_ROUTE } from "../../Routes/bookmarksRoutes";
import { CollectionDotActions } from "../CollectionDotActions/CollectionDotActions";

interface BookmarkCollectionCardProps {
  collection: BookmarkCollection;
}

export const BookmarkCollectionCard: React.FC<BookmarkCollectionCardProps> = ({
  collection
}) => {
  const render = useCallback(
    (actionItems: IThreeDotsItem[]) => (
      <CollectionCard
        collection={collection}
        to={BOOKMARKS_ROUTE(collection.id)}
        actionItems={actionItems}
      />
    ),
    [collection]
  );

  return (
    <CollectionDotActions collection={collection} childrenRenderer={render} />
  );
};
