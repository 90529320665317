import { Button, Icon } from "arbolus-ui-components";
import React, { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  BOOKMARK_COLLECTION_ORDER_BY,
  BookmarkService,
  CIQError,
  CanopyService,
  CreateBookmark,
  DefaultToasterService,
  DeleteBookmark,
  ErrorResponse,
  ListBookmarkCollectionsQueryParams,
  SORT_DIRECTION,
  ToasterService
} from "@arbolus-technologies/api";
import { BookmarkCollection } from "@arbolus-technologies/models/common";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  CIQPopover,
  Checkbox,
  InfiniteScrollV2,
  Loader
} from "@arbolus-technologies/ui/components";

import { AddCollectionModal } from "../AddCollectionModal/AddCollectionModal";
import {
  BookmarkActions,
  initialState,
  reducer
} from "./BookmarkButtonReducer";

import styles from "./BookmarkButton.module.scss";
interface BookmarkButtonProps {
  answer: { id: string; isBookmarked: boolean };
  bookmarkService?: typeof BookmarkService;
  canopyService?: typeof CanopyService;
  notificationService?: ToasterService;
}
export const BookmarkButton: React.FC<BookmarkButtonProps> = ({
  answer,
  bookmarkService = BookmarkService,
  canopyService = CanopyService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("bookmarkButton");
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isCollectionListLoading, setIsCollectionListLoading] = useState(false);
  const [isAnswerBookmarked, setIsAnswerBookmarked] = useState(
    answer.isBookmarked
  );
  const [updatedState, setUpdatedState] = useState(state);

  const initialQueryParams: ListBookmarkCollectionsQueryParams = {
    limit: 10,
    offset: 0,
    count: 10,
    orderBy: BOOKMARK_COLLECTION_ORDER_BY.CREATED,
    orderDirection: SORT_DIRECTION.DESCENDING,
    referenceId: answer.id
  };

  const handleBottomReached = () => {
    if (
      (updatedState.queryParams.count ?? 0) > updatedState.queryParams.offset
    ) {
      return getBookmarkCollectionList();
    }
    return null;
  };

  useEffect(() => {
    setUpdatedState(state);
  }, [state]);

  const handleOnClickBookmarkCollection = (
    bookmarkCollection: BookmarkCollection
  ): void => {
    const { hasReference, id } = bookmarkCollection;
    if (hasReference) {
      deleteBookmark(id);
    } else {
      postBookmark(id);
    }
  };

  const handleOpenPopover = () => {
    if (!isPopoverOpen) {
      setIsCollectionListLoading(true);
      setIsPopoverOpen(true);
      getBookmarkCollectionList(true);
    }
  };

  const handleReset = () => {
    dispatch({
      type: BookmarkActions.RESET_REDUCER
    });
    setIsPopoverOpen(false);
    setIsCollectionListLoading(false);
  };

  const getBookmarkCollectionList = (isInitialFetch?: boolean) => {
    bookmarkService
      .listBookmarkCollections(
        isInitialFetch ? initialQueryParams : updatedState.queryParams
      )
      .subscribe({
        next: ({ items, pagination }) => {
          const { count, offset } = pagination;
          dispatch({
            type: BookmarkActions.UPDATE_REDUCER,
            payload: {
              collectionList:
                updatedState.collectionList === null
                  ? items
                  : [...updatedState.collectionList, ...items],
              queryParams: {
                limit: initialQueryParams.limit,
                offset: offset + initialQueryParams.limit,
                orderBy: BOOKMARK_COLLECTION_ORDER_BY.CREATED,
                orderDirection: SORT_DIRECTION.DESCENDING,
                count,
                referenceId: answer.id
              }
            }
          });
          getUpdatedAnswer(isInitialFetch);
        },
        error: (error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
          handleReset();
        }
      });
  };

  const reloadBookmarkCollectionList = () => {
    const reloadQueryParams: ListBookmarkCollectionsQueryParams = {
      limit: updatedState.collectionList?.length || 10,
      offset: 0,
      count: 10,
      orderBy: BOOKMARK_COLLECTION_ORDER_BY.CREATED,
      orderDirection: SORT_DIRECTION.DESCENDING,
      referenceId: answer.id
    };
    bookmarkService.listBookmarkCollections(reloadQueryParams).subscribe({
      next: ({ items }) => {
        dispatch({
          type: BookmarkActions.UPDATE_REDUCER,
          payload: {
            collectionList: items,
            isProcessingClick: false
          }
        });
        getUpdatedAnswer();
      },
      error: (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
        handleReset();
      }
    });
  };

  const getUpdatedAnswer = (isInitialFetch?: boolean) => {
    canopyService.getCanopyExpertAnswer(answer.id).subscribe({
      next: ({ isBookmarked }) => {
        // If answer is bookmarked open popover, if not post new bookmark to default list on initial click
        setIsAnswerBookmarked(isBookmarked);
        if (isBookmarked) {
          setIsCollectionListLoading(false);
        } else if (isInitialFetch) {
          postBookmark();
        }
      },
      error: (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
        handleReset();
      }
    });
  };

  const postBookmark = (
    bookmarkCollectionId?: string,
    fromCreateList?: boolean
  ) => {
    const body: CreateBookmark = {
      bookmarkCollectionId: bookmarkCollectionId ?? null,
      referenceId: answer.id
    };
    dispatch({
      type: BookmarkActions.UPDATE_REDUCER,
      payload: {
        isProcessingClick: true
      }
    });
    bookmarkService.postBookmark(body).subscribe({
      next: () => {
        if (!fromCreateList) {
          reloadBookmarkCollectionList();
        }
      },
      error: (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
        handleReset();
      }
    });
  };

  const deleteBookmark = (bookmarkCollectionId: string) => {
    const body: DeleteBookmark = {
      referenceId: answer.id,
      bookmarkCollectionId: bookmarkCollectionId
    };
    dispatch({
      type: BookmarkActions.UPDATE_REDUCER,
      payload: {
        isProcessingClick: true
      }
    });
    bookmarkService.deleteBookmark(body).subscribe({
      next: () => {
        reloadBookmarkCollectionList();
      },
      error: (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
        handleReset();
      }
    });
  };

  const renderBookmarkCollectionCheckbox = (
    bookmarkCollection: BookmarkCollection
  ): JSX.Element => (
    <Checkbox
      key={bookmarkCollection.id}
      isChecked={bookmarkCollection.hasReference}
      isDisabled={updatedState.isProcessingClick}
      onChange={() => handleOnClickBookmarkCollection(bookmarkCollection)}
      text={bookmarkCollection.name}
    />
  );

  const handleOpenCreateListModal = () => {
    handleReset();
    setIsModalOpen(true);
  };

  const handleOnSuccess = (listId: string) => {
    postBookmark(listId, true);
  };

  return (
    <>
      <Icon
        name="bookmark"
        filled={isAnswerBookmarked}
        fontSize="24px"
        color={
          isAnswerBookmarked
            ? ARBOLUS_COLORS.bColorBasePurple
            : ARBOLUS_COLORS.bColorSecondaryGreyDark
        }
        onClick={handleOpenPopover}
        id={`popover_${answer.id}`}
        tooltip={t("bookmark")}
      />
      <CIQPopover
        isOpen={isPopoverOpen}
        id={`popover_${answer.id}`}
        onPopoverClose={handleReset}
        customContainerClasses={styles.popoverContainer}
      >
        <>
          {isCollectionListLoading && (
            <div className={styles.loader}>
              <Loader />
            </div>
          )}

          {!isCollectionListLoading && updatedState.collectionList && (
            <div className={styles.infiniteContainer}>
              <InfiniteScrollV2
                onBottomReached={handleBottomReached}
                items={updatedState.collectionList}
                renderer={renderBookmarkCollectionCheckbox}
                customPadding={[2]}
                isLoading={isCollectionListLoading}
              />
            </div>
          )}

          <hr className={styles.divisor} />
          <div className={styles.buttonContainer}>
            <Button
              text={t("createNewList")}
              endIcon="add"
              type="tertiary"
              onClick={handleOpenCreateListModal}
              disabled={isCollectionListLoading}
            />
          </div>
        </>
      </CIQPopover>
      <AddCollectionModal
        isOpen={isModalOpen}
        close={() => setIsModalOpen(false)}
        onSuccess={handleOnSuccess}
      />
    </>
  );
};
