import clsx from "clsx";
import React from "react";

import { EventGuest } from "../../../../models/event";
import { UtilsService } from "../../../../services";
import { CIQAvatar } from "../../../app/components";

interface EventGuestTagProps {
  guest: EventGuest;
  isCreator?: boolean;
  onRemove?: (email: string) => void;
}

const EventGuestTag: React.FC<EventGuestTagProps> = ({
  guest,
  isCreator,
  onRemove
}: EventGuestTagProps) => {
  const { email, user } = guest;

  return (
    <button
      type="button"
      className={clsx("avatar-tag", {
        me: isCreator
      })}
    >
      <CIQAvatar
        username={
          user
            ? UtilsService.displayUserName(user)
            : UtilsService.nameFromEmail(email)
        }
        profileImageUrl={user?.profileImageUrl}
      />

      <span>{user ? UtilsService.displayUserName(user) : email}</span>
      {!isCreator && onRemove && (
        <span
          className="ciq-icon ciq-close"
          onClick={(): void => onRemove(guest.email)}
        />
      )}
    </button>
  );
};

export default EventGuestTag;
