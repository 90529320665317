import { push } from "connected-react-router";
import moment from "moment";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Media from "react-media";
import { connect } from "react-redux";
import { Row } from "reactstrap";
import { Dispatch } from "redux";
import { createStructuredSelector } from "reselect";
import { Subscription } from "rxjs";
import SimpleBar from "simplebar-react";

import { TimeZone, ToasterService } from "@arbolus-technologies/api";
import { Loader } from "@arbolus-technologies/ui/components";

import {
  PROJECT_EDIT_ROUTE,
  PROJECT_ROUTE
} from "../../../../constants/navigation/projectRoutes";
import {
  APP_DEVICE_MEDIA_QUERIES,
  UI_PROJECT_BRIEF,
  UI_WINDOW_HEIGHT
} from "../../../../constants/ui";
import { NavBarContextConsumer } from "../../../../contexts/navBar/NavBarContext";
import { CIQError, ErrorResponse } from "../../../../models/api";
import { Project } from "../../../../models/project";
import { ProjectService, UtilsService } from "../../../../services";
import { AppAction } from "../../../../store/actions";
import { AppState } from "../../../../store/reducers";
import { AppSelector } from "../../../app/store";
import { AuthSelector } from "../../../auth/store";
import ProjectDetailsPane from "../../components/projectBrief/projectDetails/DetailsPane";
import { ProjectSelector, ProjectStoreActions } from "../../store";

const notification = new ToasterService();

interface ProjectBriefStoreProps {
  projectId: string;
  projectName: string;
  timezoneMap: Map<string, TimeZone>;
  isClientUser: boolean;
}

interface ProjectBriefPageProps
  extends ProjectBriefStoreProps,
    WithTranslation {
  navigateBackToBase: (projectId: string) => void;
  refetchReferralSummary: (projectId: string) => void;
  refetchProjectData: (projectId: string) => void;
  navigateToEdit: (projectId: string) => void;
}

interface ProjectBriefPageState {
  isLoading: boolean;
}

class LegacyProjectBriefPage extends React.Component<
  ProjectBriefPageProps,
  ProjectBriefPageState
> {
  constructor(props: ProjectBriefPageProps) {
    super(props);
    this.state = {
      isLoading: true
    };
  }

  componentDidMount(): void {
    this.fetchProject();
  }

  componentWillUnmount(): void {
    this.projectFetchSubscription?.unsubscribe();
  }

  private projectFetchSubscription?: Subscription;

  private project?: Project;

  fetchProject = (): void => {
    const { projectId } = this.props;

    ProjectService.getProject(projectId).subscribe(
      (projectResponse) => {
        this.project = projectResponse;

        this.setState({
          isLoading: false
        });
      },
      (error: ErrorResponse<CIQError>) => {
        notification.showError(error.message);
      }
    );
  };

  handleProjectNameClicked = (): void => {
    const {
      navigateBackToBase,
      projectId,
      refetchProjectData,
      refetchReferralSummary,
      isClientUser
    } = this.props;

    if (isClientUser) {
      refetchProjectData(projectId);
      refetchReferralSummary(projectId);
    }
    navigateBackToBase(projectId);
  };

  handleDetailsEditClick = (): void => {
    const { navigateToEdit, projectId } = this.props;
    navigateToEdit(projectId);
  };

  renderProjectBrief = (): JSX.Element => {
    const { projectName, timezoneMap } = this.props;
    const {
      mainContactUser,
      created,
      description,
      internalDescription,
      adminContactNo,
      caseCode,
      endDate,
      projectDetailsUpdate,
      projectDetailsUpdateBy,
      startDate,
      timezone,
      classification,
      classificationDescription,
      industry
    } = this.project!;

    const projectDetailsUpdatedUser =
      projectDetailsUpdateBy &&
      UtilsService.displayUserName(projectDetailsUpdateBy);

    let projectLastUpdated;
    if (moment(projectDetailsUpdate).isAfter(created))
      projectLastUpdated = projectDetailsUpdate;

    return (
      <div className="brief-sections">
        <div className="project-details-pane">
          <ProjectDetailsPane
            timezoneMap={timezoneMap}
            timezone={timezone}
            mainContact={mainContactUser}
            caseCode={caseCode}
            contactNumber={adminContactNo}
            endDate={endDate}
            lastUpdated={projectLastUpdated}
            updatedUsername={projectDetailsUpdatedUser}
            overview={description}
            internalOverview={internalDescription}
            projectName={projectName}
            startDate={startDate}
            onDetailsEditClick={(): void => this.handleDetailsEditClick()}
            classification={classification}
            classificationDescription={classificationDescription}
            industry={industry}
          />
        </div>
      </div>
    );
  };

  render(): JSX.Element {
    const { isLoading } = this.state;
    const { projectName, t } = this.props;

    if (!isLoading) {
      return (
        <div className="project-base-brief-container">
          <Row className="header-container m-0">
            <div className="left-container">
              <h3 onClick={this.handleProjectNameClicked}>{projectName}</h3>
              <h1>{t("brief")}</h1>
            </div>
          </Row>
          <Media queries={APP_DEVICE_MEDIA_QUERIES}>
            {(matches): JSX.Element => (
              <NavBarContextConsumer>
                {({ isProjectActionsActive }): JSX.Element => {
                  const containerHeight =
                    UI_PROJECT_BRIEF.CONTAINER_EXCESS_HEIGHT(matches) +
                    (isProjectActionsActive && !matches.large
                      ? UI_PROJECT_BRIEF.TABS_ELEMENT_HEIGHT_MOBILE
                      : 0);

                  return (
                    <SimpleBar
                      className="container form-container simplebar-light"
                      style={{
                        minHeight: `calc(${UI_WINDOW_HEIGHT} - ${containerHeight}px)`,
                        height: `calc(${UI_WINDOW_HEIGHT} - ${containerHeight}px)`,
                        maxWidth: "100vw",
                        overflowX: "hidden"
                      }}
                    >
                      {this.renderProjectBrief()}
                    </SimpleBar>
                  );
                }}
              </NavBarContextConsumer>
            )}
          </Media>
        </div>
      );
    }
    return <Loader isFull />;
  }
}

const mapStateToProps = createStructuredSelector<
  AppState,
  ProjectBriefPageProps,
  ProjectBriefStoreProps
>({
  projectId: ProjectSelector.projectIdSelector(),
  projectName: ProjectSelector.projectNameSelector(),
  timezoneMap: AppSelector.appTimezoneMapSelector(),
  isClientUser: AuthSelector.authIsClientUserSelector()
});

const mapDispatchToProps = (dispatch: Dispatch): Record<string, AppAction> => ({
  navigateBackToBase: (projectId: string): AppAction =>
    dispatch(push(PROJECT_ROUTE(projectId))),
  refetchReferralSummary: (projectId: string): AppAction =>
    dispatch(ProjectStoreActions.refetchReferralSummary(projectId)),
  refetchProjectData: (projectId: string): AppAction => {
    dispatch(ProjectStoreActions.refetchReferralData(projectId));
  },
  navigateToEdit: (projectId: string): AppAction =>
    dispatch(push(PROJECT_EDIT_ROUTE(projectId)))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("projectBrief")(LegacyProjectBriefPage));
