import React from "react";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";

import { AnswerTypeContent } from "./AnswerTypeContent/AnswerTypeContent";
import { AnswerTypeTabs } from "./AnswerTypeTabs/AnswerTypeTabs";

import styles from "./AnswerTypes.module.scss";

export const AnswerTypes: React.FC = () => {
  const { t } = useTranslation("canopyBuilder");

  return (
    <div className={styles.container}>
      <Label className={styles.label}>{t("answerType")}</Label>
      <AnswerTypeTabs />
      <AnswerTypeContent />
    </div>
  );
};
