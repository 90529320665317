import React from "react";
import { Progress } from "reactstrap";

import styles from "./ProgressBar.module.scss";

interface ProgressBarProps {
  percentage: string;
  result: {
    title: string;
    value: number;
  };
}
export const ProgressBar: React.FC<ProgressBarProps> = ({
  percentage,
  result
}) => (
  <div className={styles.container}>
    <Progress value={result.value} />
    {percentage !== "NaN" && (
      <div className={styles.percentage}>{`${percentage} %`}</div>
    )}
  </div>
);
