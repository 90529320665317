import React from "react";

import { linkedin } from "@arbolus-technologies/theme";

import { IconWithText } from "../IconWithText/IconWithText";

import { LinkButton } from "../Buttons/Button/LinkButton";
import { LinkWrapper } from "../LinkWrapper/LinkWrapper";
import styles from "./ExpertLinkedinIcon.module.scss";

interface ExpertLinkedinIconProps {
  linkedInUrl?: string;
}

export const ExpertLinkedinIcon: React.FC<ExpertLinkedinIconProps> = ({
  linkedInUrl
}) => {
  return linkedInUrl ? (
    <LinkWrapper to={linkedInUrl} target="_blank" isExternal>
      <IconWithText img={linkedin} />
    </LinkWrapper>
  ) : (
    <></>
  );
};
