import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";

import { Project } from "@arbolus-technologies/api";
import {
  DO_NOT_CONTACT_STATUS,
  Referral
} from "@arbolus-technologies/models/common";
import { PROJECT_EXPERT_AVAILABILITY_ROUTE } from "@arbolus-technologies/routes";
import { LinkButton } from "@arbolus-technologies/ui/components";

import { Booking } from "../BookingButton/Booking";
import { RequestAvailabilityButton } from "../RequestAvailabilityButton/RequestAvailabilityButton";

interface SlotsActionButtonsProps {
  referral: Referral;
  project: Project;
  timezone: string;
}

export const SlotsActionButtons: React.FC<SlotsActionButtonsProps> = ({
  referral,
  project,
  timezone
}) => {
  const { t } = useTranslation("referrals", { keyPrefix: "availability" });

  return (
    <>
      <LinkButton
        to={PROJECT_EXPERT_AVAILABILITY_ROUTE(
          project.id,
          referral.expert.expertId,
          referral.expertName
        )}
        text={t("edit")}
        type="tertiary"
        size="small"
      />
      {(referral.isBookable || referral.onlyMissingReview) && (
        <Booking
          referral={referral}
          timezone={timezone}
          project={project}
          render={(_, onClick) => (
            <Button
              text={t("bookCustom")}
              type="secondary"
              size="small"
              onClick={onClick}
              disabled={
                referral.expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC
              }
            />
          )}
        />
      )}
      <RequestAvailabilityButton referral={referral} project={project} />
    </>
  );
};
