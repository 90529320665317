import i18next from "i18next";
import * as Yup from "yup";

import { ClientSettingsConstraints } from "../../../../constants/validation";

export const DetailsTabValidationSchema = Yup.object().shape({
  companyName: Yup.string()
    .max(
      ClientSettingsConstraints.MAX_COMPANY_NAME_LENGTH,
      i18next.t("detailsTab:companyNameMaxLengthError", {
        length: ClientSettingsConstraints.MAX_COMPANY_NAME_LENGTH
      })
    )
    .required(i18next.t("detailsTab:companyNameRequired")),
  externalName: Yup.string()
    .max(
      ClientSettingsConstraints.MAX_EXTERNAL_NAME_LENGTH,
      i18next.t("detailsTab:externalNameMaxLengthError", {
        length: ClientSettingsConstraints.MAX_EXTERNAL_NAME_LENGTH
      })
    )
    .required(i18next.t("detailsTab:externalNameRequired")),
  mainContact: Yup.object().required(
    i18next.t("detailsTab:mainContactRequired")
  )
});
