import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  ArbolusTooltip,
  ArbolusTooltipPlace,
  Avatar,
  PublicCompanyIcon
} from "@arbolus-technologies/ui/components";
import { DisplayUser, utilService } from "@arbolus-technologies/utils";

import styles from "./UserName.module.scss";

export interface UserNameProps {
  row: DisplayUser & { profileImageUrl?: string; emailConfirmed?: boolean };
  lastPublicCompanyExpDate?: string | null;
  onClick?: () => void;
  bold?: boolean;
  onboarded?: boolean;
}

export const UserName: React.FC<UserNameProps> = ({
  row,
  lastPublicCompanyExpDate,
  onClick,
  bold = false,
  onboarded = false
}) => {
  const { profileImageUrl, emailConfirmed } = row;
  const { t } = useTranslation("usersList");

  return (
    <div className={styles.userNameContainer} onClick={onClick}>
      <div className={styles.userName}>
        <Avatar
          avatar={{
            name: utilService.displayUserName(row),
            imageUrl: profileImageUrl
          }}
          withGreenTick={onboarded}
        />
        <div className={clsx(styles.name, { [styles.bold]: bold })}>
          {utilService.displayUserName(row)}
        </div>
        {lastPublicCompanyExpDate && (
          <PublicCompanyIcon date={lastPublicCompanyExpDate} />
        )}
      </div>
      {!emailConfirmed && (
        <ArbolusTooltip
          content={t("invitePending")}
          place={ArbolusTooltipPlace.RIGHT}
        >
          <Icon
            fontSize="20px"
            name="error"
            id="projectName"
            color={ARBOLUS_COLORS.bColorSecondaryAmber}
          />
        </ArbolusTooltip>
      )}
    </div>
  );
};
