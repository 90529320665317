import dompurify from "dompurify";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  ProjectNxSelector,
  ProjectNxStoreActions
} from "@arbolus-technologies/stores/project";
import { Drawer, Loader } from "@arbolus-technologies/ui/components";

import { StepHeader } from "../StepHeader/StepHeader";

import styles from "./AgreementStep.module.scss";

const sanitizer = dompurify.sanitize;

interface AgreementStepProps {
  name: string;
  clientId: string;
}

export const AgreementStep: React.FC<AgreementStepProps> = ({
  name,
  clientId
}) => {
  const { t } = useTranslation("createNewProject");
  const dispatch = useDispatch();

  const agreementData = useSelector(ProjectNxSelector.engagementAgreement());
  const isAgreementDataLoading = useSelector(
    ProjectNxSelector.isEngagementAgreementsLoading()
  );

  useEffect(() => {
    dispatch(ProjectNxStoreActions.getEngagementAgreements(clientId));
  }, [clientId, dispatch]);

  const getSanitizedText = (text: string) => (
    <span
      dangerouslySetInnerHTML={{
        __html: sanitizer(text).replace(/href/g, "target='_blank' href")
      }}
    />
  );

  if (isAgreementDataLoading) {
    return <Loader />;
  }

  if (!isAgreementDataLoading && !agreementData) {
    return <span>{t("noAgreementInfo")}</span>;
  }

  return (
    agreementData && (
      <div className={styles.agreementContainer}>
        <Drawer
          titleChildren={() => <StepHeader title={name} stepNum={2} />}
          openByDefault
          disableCollapse
        >
          <span>{t("engagementAgreementMessage")}</span>
        </Drawer>
        <Drawer title={agreementData.headerTitle} openByDefault>
          {getSanitizedText(agreementData.headerText)}
        </Drawer>
        {agreementData.agreementTerms.map((agreement) => (
          <Drawer title={agreement.termTitle} key={agreement.termTitle}>
            {getSanitizedText(agreement.termText)}
          </Drawer>
        ))}
        <Drawer title={agreementData.footerTitle} openByDefault>
          {getSanitizedText(agreementData.footerText)}
        </Drawer>
      </div>
    )
  );
};
