import { useSelector } from "react-redux";

import { CanopyV2Selector } from "@arbolus-technologies/stores/canopy-V2";
import { useDocumentTitle } from "@arbolus-technologies/utils";

export const useCanopyTitle = (includeCanopyTitle = false): void => {
  const canopyData = useSelector(CanopyV2Selector.canopyData());

  useDocumentTitle(
    "canopyTitles",
    includeCanopyTitle ? "canopyWithTitle" : "canopy",
    {
      title: canopyData?.title || ""
    }
  );
};
