import { Icon } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { STATUS_COLORS } from "@arbolus-technologies/models/canopy";
import {
  PROJECT_CALENDAR_ROUTE,
  PROJECT_CHAT_BASE_ROUTE,
  PROJECT_FILES_ROUTE,
  PROJECT_ROUTE
} from "@arbolus-technologies/routes";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  BoxWithChildren,
  ColoredBadge
} from "@arbolus-technologies/ui/components";
import { APP_DATE_FORMAT, formatDate } from "@arbolus-technologies/utils";

import { useIsChatAvailable } from "../../Hooks/useIsChatAvailable";

import styles from "./ProjectCard.module.scss";

interface ProjectCardProps {
  id: string;
  name: string;
  status: string;
  created: string;
}

export const ProjectCard: React.FC<ProjectCardProps> = ({
  id,
  name,
  status,
  created
}) => {
  const { t } = useTranslation("projectCard");
  const colorValue =
    STATUS_COLORS[status.toLowerCase() as keyof typeof STATUS_COLORS];
  const displayChat = useIsChatAvailable();

  return (
    <BoxWithChildren classnames={styles.card}>
      <div className="d-flex flex-column h-100">
        <div className={styles.header}>
          <ColoredBadge text={status} backgroundColor={colorValue} />
          <div className={styles.linksContainer}>
            {displayChat && (
              <Link
                to={PROJECT_CHAT_BASE_ROUTE(id)}
                title={t("workspaces")}
                className="ml-auto"
              >
                <Icon
                  name="forum"
                  fontSize="22px"
                  color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
                />
              </Link>
            )}
            <Link to={PROJECT_CALENDAR_ROUTE(id)} title={t("calendar")}>
              <Icon
                name="calendar_today"
                fontSize="22px"
                color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
              />
            </Link>
            <Link to={PROJECT_FILES_ROUTE(id)} title={t("documents")}>
              <Icon
                name="description"
                fontSize="22px"
                color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
              />
            </Link>
          </div>
        </div>
        <Link
          to={PROJECT_ROUTE(id)}
          className="d-flex align-items-center h-100"
        >
          <h4 className={styles.name} title={name}>
            {name}
          </h4>
        </Link>
        <p className={styles.created}>
          {t("created", { date: formatDate(created, APP_DATE_FORMAT) })}
        </p>
      </div>
    </BoxWithChildren>
  );
};
