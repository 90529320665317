import { Icon } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";

import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { ArbolusTooltip } from "../Tooltip/ArbolusTooltip";

interface DNCIconProps {
  dncStatus: DO_NOT_CONTACT_STATUS;
  size?: string;
  isAdmin?: boolean;
}

export const DNCIcon: React.FC<DNCIconProps> = ({
  dncStatus,
  size = "16px",
  isAdmin = false
}) => {
  const { t } = useTranslation("dnc");

  const isDnc = dncStatus === DO_NOT_CONTACT_STATUS.DNC;
  const isCaution = dncStatus === DO_NOT_CONTACT_STATUS.CAUTION;

  const getIcon = () => {
    if (isDnc) {
      return (
        <Icon
          color={ARBOLUS_COLORS.bColorBaseOrange}
          name="block"
          fontSize={size}
        />
      );
    }

    if (isAdmin && isCaution) {
      return (
        <Icon
          color={ARBOLUS_COLORS.bColorSecondaryOrange}
          name="warning"
          fontSize={size}
        />
      );
    }

    return null;
  };

  const dncTagTooltip = isDnc ? t("dncTooltip") : t("cautionTooltip");

  return <ArbolusTooltip content={dncTagTooltip}>{getIcon()}</ArbolusTooltip>;
};
