import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { isChrome, isEdgeChromium } from "react-device-detect";
import { useTranslation } from "react-i18next";

import { READ_MORE_OVERFLOW_DEBOUNCE } from "../../../../constants/timer";

const CustomReadMore: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const { t } = useTranslation("customReadMore");
  const childrenDivRef = useRef<HTMLDivElement>(null);
  const resizeObserver = useRef<ResizeObserver>();
  const isChromeBased = isChrome || isEdgeChromium;
  const [showMore, setShowMore] = useState<boolean>(true);
  const [isOverflowContent, setIsOverflowContent] = useState<boolean>();

  useEffect(() => {
    if (childrenDivRef.current) {
      const { current } = childrenDivRef;
      resizeObserver.current = new ResizeObserver(
        _.debounce(() => {
          const { offsetHeight, scrollHeight } = current;

          if (scrollHeight === 0) {
            setShowMore(true);
          }

          const hasOverflowingChildren = offsetHeight < scrollHeight;

          if (isOverflowContent !== hasOverflowingChildren) {
            setIsOverflowContent(hasOverflowingChildren);
          }
        }, READ_MORE_OVERFLOW_DEBOUNCE)
      );

      resizeObserver.current.observe(current as Element);
    }

    return (): void => {
      resizeObserver.current?.disconnect();
    };
  }, [isOverflowContent, children]);

  const handleToggleReadMore = (): void => {
    setShowMore(!showMore);
  };

  return (
    <div className="read-more-container">
      <div
        className={clsx("read-more-text", {
          chrome: isChromeBased,
          "not-chrome": !isChromeBased,
          collapse: showMore
        })}
        ref={childrenDivRef}
      >
        {children}
      </div>
      {(isOverflowContent || !showMore) && (
        <span className="read-more-btn" onClick={handleToggleReadMore}>
          {showMore ? t("seeMore") : t("seeLess")}
        </span>
      )}
    </div>
  );
};

export default CustomReadMore;
