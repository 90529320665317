import { ChatListResponseItem } from "@arbolus-technologies/api";
import {
  AngleChat,
  ChatData,
  ChatType,
  ChatTypes,
  ChatUserRole,
  DatabaseChatType
} from "@arbolus-technologies/models/project";

import { AngleWithChat, AngleWithChatById } from "../../Models/chat";

export const extractChatsByAngle = (
  chats: ChatListResponseItem[]
): AngleWithChatById => {
  const chatsByAngle = {} as AngleWithChatById;
  for (const chat of chats) {
    if (
      chat.chatType === DatabaseChatType.ClientSupport ||
      chat.chatType === DatabaseChatType.Workspace ||
      !chat.angle
    ) {
      continue;
    }
    chatsByAngle[chat.angle.id] = {
      id: chat.angle.id,
      title: chat.angle.title,
      color: chat.angle.color,
      angleChatId: chat.chatId,
      channelName: chat.channelName,
      chatMembers: chat.chatMembers,
      chats: chat.expertChats?.map((expertChat) => ({
        // Chat database ID is used when SENDING messages
        id: expertChat.chatId,
        // Channel name (presence) is used when RECEIVING messages
        channelName: expertChat.channelName,
        title: expertChat.chatName,
        expertName: expertChat.chatName,
        expertAvatar: expertChat.userProfileImageUrl,
        referralId: expertChat.referralId,
        expertId: expertChat.expertId,
        userId: expertChat.chatUserId,
        referralApplicationStatus: expertChat.referralApplicationStatus,
        referralComplianceStatus: expertChat.referralComplianceStatus,
        chatType: expertChat.chatType,
        doNotContactStatus: expertChat.doNotContactStatus,
        chatMembers: chat.chatMembers
      }))
    };
  }
  return chatsByAngle;
};

export const extractSupportChatsByAngle = (
  chatArray: ChatListResponseItem[]
): Record<string, AngleChat> => {
  // Create an empty object to store angle-related chats
  const angleChatMap: Record<string, AngleChat> = {};

  // Loop through each chat item in the input array
  chatArray.forEach((chat) => {
    // Extract the angle ID from the chat item
    const angleId = chat.angle?.id;

    // Check if the angle ID exists
    if (angleId) {
      // If this angle ID is not already in the angleChatMap, create a new entry
      if (!angleChatMap[angleId]) {
        angleChatMap[angleId] = {
          id: chat.angle?.id ?? "",
          title: chat.angle?.title ?? "",
          color: chat.angle?.color ?? "",
          angleChatId: chat.chatId,
          channelName: chat.channelName,
          chatMembers: chat.chatMembers,
          chats: []
        };
      }

      // Check if the chat item contains expert chats
      if (chat.expertChats) {
        // Create a Set to keep track of expert IDs already added to this angle
        const expertIdsInAngle = new Set<string>(
          angleChatMap[angleId].chats.map((expert) => expert.expertId!)
        );

        // Loop through expert chats within the chat item
        chat.expertChats.forEach((expertChat) => {
          // Check if this expert ID is not already in the angle's chats
          if (!expertIdsInAngle.has(expertChat.expertId)) {
            // Add the expert chat to the angle's chats array
            angleChatMap[angleId].chats.push({
              id: expertChat.chatId,
              title: expertChat.chatName,
              expertName: expertChat.chatName,
              expertAvatar: expertChat.userProfileImageUrl,
              expertId: expertChat.expertId,
              userId: expertChat.chatUserId,
              referralApplicationStatus: expertChat.referralApplicationStatus,
              referralComplianceStatus: expertChat.referralComplianceStatus,
              referralId: expertChat.referralId,
              channelName: expertChat.channelName,
              chatMembers: chat.chatMembers,
              chatType: expertChat.chatType
            });

            // Add the expert ID to the Set to prevent duplicates
            expertIdsInAngle.add(expertChat.expertId);
          }
        });
      }
    }
  });

  // Return the resulting angleChatMap
  return angleChatMap;
};

export const getInitialChatType = (
  isAdmin: boolean,
  isExpert: boolean
): ChatType => {
  if (isExpert) return ChatType.ExpertToAdminTeam;
  if (isAdmin) return ChatType.AdminToProject;
  return ChatType.ClientToProject;
};

const chatTypes: ChatTypes = {
  [DatabaseChatType.Workspace]: {
    [ChatUserRole.Expert]: ChatType.ExpertToProject,
    [ChatUserRole.Client]: ChatType.ClientToExpertAndAdmin,
    [ChatUserRole.Admin]: ChatType.AdminToExpertAndClient
  },
  [DatabaseChatType.ExpertSupport]: {
    [ChatUserRole.Expert]: ChatType.ExpertToAdminTeam,
    [ChatUserRole.Client]: null,
    [ChatUserRole.Admin]: ChatType.AdminToExpertSupport
  },
  [DatabaseChatType.Angle]: {
    [ChatUserRole.Expert]: null,
    [ChatUserRole.Client]: ChatType.ClientToAngle,
    [ChatUserRole.Admin]: ChatType.AdminToAngle
  },
  [DatabaseChatType.ClientSupport]: {
    [ChatUserRole.Expert]: null,
    [ChatUserRole.Client]: ChatType.ClientToProject,
    [ChatUserRole.Admin]: ChatType.AdminToProject
  }
};

export const getChatType = (
  userRole: ChatUserRole,
  databaseChatType: DatabaseChatType
): ChatType | null => chatTypes[databaseChatType][userRole];

export const convertChat = (chat: ChatListResponseItem): ChatData => ({
  id: chat.chatId,
  channelName: chat.channelName,
  chatType: chat.chatType,
  title: chat.chatName,
  expertName: chat.chatName,
  expertAvatar: chat.userProfileImageUrl,
  expertId: chat.expertId,
  userId: chat.chatUserId,
  referralId: chat.referralId,
  referralApplicationStatus: chat.referralApplicationStatus,
  referralComplianceStatus: chat.referralComplianceStatus,
  referralStatus: chat.referralStatus,
  chatMembers: chat.chatMembers
});

export function mapChatsById(
  chatListItems: ChatListResponseItem[]
): Record<string, ChatData> {
  const chatIdToChatData: Record<string, ChatData> = {};
  for (const chatListItem of chatListItems) {
    chatIdToChatData[chatListItem.chatId] = convertChat(chatListItem);
    if (chatListItem.expertChats) {
      for (const expertChat of chatListItem.expertChats) {
        chatIdToChatData[expertChat.chatId] = convertChat(
          expertChat as unknown as ChatListResponseItem
        );
      }
    }
  }
  return chatIdToChatData;
}

export function buildChatFromAngle(angle: AngleWithChat): ChatData {
  return {
    id: angle.angleChatId,
    channelName: angle.channelName,
    chatType: DatabaseChatType.Angle,
    chatMembers: angle.chatMembers
  };
}
