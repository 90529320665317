import {
  ApiNonPaginatedResponse,
  ApiPaginatedResponse,
  MultiSortPaginatedRequest
} from "./models/api";

export const mapNonPaginatedToPaginated = <T>({
  items
}: ApiNonPaginatedResponse<T>): ApiPaginatedResponse<T> => ({
  items,
  pagination: {
    count: items.length,
    limit: items.length,
    offset: 0
  }
});

export type MultiSortRequest<T> = Omit<MultiSortPaginatedRequest<T>, "sort"> & {
  sorting?: string[];
};

export const getMultiSortParams = <T>(
  request: MultiSortPaginatedRequest<T>
): MultiSortRequest<T> => {
  if (!request.sort) {
    return request;
  }

  const sorting = request.sort.map(
    ({ orderBy, orderDirection }) => `${orderBy},${orderDirection}`
  );
  const { sort, ...rest } = request;
  return { ...rest, sorting };
};
