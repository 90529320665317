import { Form, Input } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { Company } from "@arbolus-technologies/api";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { CompanySelectionPanel } from "../../../../Panels/CompanySelectionPanel/CompanySelectionPanel";
import { EMPLOYED_STATUS_OPTIONS } from "../../helpers/constants";

interface CompanySelectorProps {
  isDisabled: boolean;
}

export const CompanySelector: React.FC<CompanySelectorProps> = ({
  isDisabled
}) => {
  const { t } = useTranslation("workHistoryEditForm");
  const form = Form.useFormInstance();
  const employedStatus = Form.useWatch("employedStatus", form);
  const company = Form.useWatch("company", form);

  const [open, setOpen] = useState(false);

  const handleOnChange = (value: Company) => {
    form.setFieldsValue({ company: { id: value.id, name: value.name } });
  };

  return (
    <>
      <Input
        placeholder={t("typeToSearch")}
        readOnly
        onClick={() => setOpen(true)}
        value={company?.name}
        disabled={
          !EMPLOYED_STATUS_OPTIONS.includes(employedStatus) || isDisabled
        }
        prefix={
          <AntDIcon name="search" color={ARBOLUS_COLORS.bColorGrayIcon} />
        }
      />
      <CompanySelectionPanel
        company={company}
        onCompanySelected={handleOnChange}
        isOpen={open}
        handleClose={() => setOpen(false)}
      />
    </>
  );
};
