import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import {
  CIQError,
  Compliance as ComplianceQuestion,
  DefaultToasterService,
  ErrorResponse,
  ExpertService,
  ToasterService
} from "@arbolus-technologies/api";
import { PageStep } from "@arbolus-technologies/models/common";
import { CANOPY_DETAILS_PAGE_ROUTE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

import { ComplianceQuestionsForm } from "../../Components/ComplianceQuestionsForm/ComplianceQuestionsForm";
import { ExpertApplicationWrapper } from "../../Components/ExpertApplicationWrapper/ExpertApplicationWrapper";

export interface ComplianceQuestionProps {
  compliance: ComplianceQuestion;
  index: number;
}

interface ComplianceProps {
  name: string;
  setCurrentStep: (step: number) => void;
  steps: PageStep[];
  isLastStep: boolean;
  projectId: string;
  expertService: typeof ExpertService;
  notificationService: ToasterService;
}

export const Compliance: React.FC<ComplianceProps> = ({
  name,
  setCurrentStep,
  steps,
  isLastStep,
  projectId,
  expertService = ExpertService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("expertApplications");
  const history = useHistory();
  const { canopyId } = useParams<{ canopyId: string }>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [complianceQuestions, setComplianceQuestions] = useState<
    ComplianceQuestion[]
  >([]);

  const expertId = useSelector(CacheSelector.loggedInUserExpertId());

  useEffect(() => {
    setIsLoading(true);

    expertService.getComplianceAnswers(expertId, projectId).subscribe(
      (questions) => {
        setComplianceQuestions(questions.items);
        setIsLoading(false);
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showError(error.message);
        setIsLoading(false);
      }
    );
  }, [expertId, expertService, notificationService, projectId, t]);

  const currentStep = steps.findIndex((step) => step.name === name);

  return (
    <ExpertApplicationWrapper
      currentStep={currentStep}
      name={name}
      isLoading={isLoading}
    >
      {complianceQuestions.length > 0 && (
        <ComplianceQuestionsForm
          complianceQuestions={complianceQuestions}
          nextStepName={
            isLastStep
              ? t("goToCanopy")
              : t("nextStep", { step: steps[currentStep + 1].name })
          }
          projectId={projectId}
          goToPrevStep={() => setCurrentStep(currentStep - 1)}
          onAfterSubmit={() => {
            isLastStep
              ? history.push(CANOPY_DETAILS_PAGE_ROUTE(canopyId))
              : setCurrentStep(currentStep + 1);
          }}
        />
      )}
    </ExpertApplicationWrapper>
  );
};
