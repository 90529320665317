import React from "react";
import { Link } from "react-router-dom";

import {
  PROJECT_ROUTE,
  PROJECT_ROUTE_BY_ANGLE
} from "@arbolus-technologies/routes";
import {
  AssignedAngle,
  BadgeWithIcon
} from "@arbolus-technologies/ui/components";

import { CardHeaderData } from "../../../interfaces";

import styles from "./CardHeader.module.scss";

interface CardHeaderProps {
  headerData: CardHeaderData;
}

export const CardHeader: React.FC<CardHeaderProps> = ({ headerData }) => {
  const { angle, project } = headerData;

  return (
    <div className={styles.header}>
      <Link to={PROJECT_ROUTE(project.id)} target="_blank">
        <BadgeWithIcon text={project.name} icon="topic" enableCursorPointer />
      </Link>
      <Link to={PROJECT_ROUTE_BY_ANGLE(project.id, angle.id)} target="_blank">
        <AssignedAngle
          angle={{
            color: angle.color,
            title: angle.title
          }}
        />
      </Link>
    </div>
  );
};
