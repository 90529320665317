import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";

import { InputController } from "../InputController/InputController";
import { GetDangerDeleteSchema } from "./DangerDeleteModalSchema";

import styles from "./DangerDeleteModal.module.scss";

interface DangerDeleteModalProps {
  onCancel: () => void;
  onConfirm: () => void;
  toggleModal: boolean;
  modalTitle: string;
  confirmationText: string;
  deleteActionText?: string;
  cancelActionText?: string;
  warningMessage?: string;
  errorComponent?: JSX.Element;
  loading?: boolean;
}

interface DangerDeleteModalForm {
  confirmationText: string;
}

export const DangerDeleteModal: React.FC<DangerDeleteModalProps> = ({
  onCancel,
  onConfirm,
  toggleModal,
  deleteActionText,
  cancelActionText,
  modalTitle,
  warningMessage,
  confirmationText,
  errorComponent,
  loading
}) => {
  const { t } = useTranslation("dangerDeleteModal");
  const {
    control,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors, isDirty, isValid }
  } = useForm<DangerDeleteModalForm>({
    mode: "onChange",
    // @ts-ignore
    resolver: yupResolver(GetDangerDeleteSchema(confirmationText)),
    defaultValues: {
      confirmationText: ""
    }
  });

  const onSubmit: SubmitHandler<DangerDeleteModalForm> = (data) => {
    reset();
    clearErrors();
    onConfirm();
  };

  const cancelForm = () => {
    reset();
    clearErrors();
    onCancel();
  };

  return (
    <Modal isOpen={toggleModal} size="lg" className="modal-custom danger">
      <ModalHeader>
        <div
          className="modal-body-container-title"
          data-testid="warningModalTitle"
        >
          {modalTitle}
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="modal-body-container">
          <div className="warning-container">
            <div className="warning-icon">
              <i className="ciq-icon ciq-ic-info" />
            </div>
            <div>
              <div className="warning-message-title">{t("warningTitle")}</div>
              <div className="warning-message-text">
                {warningMessage || t("warningMessage", { confirmationText })}
              </div>
            </div>
          </div>
          {errorComponent && (
            <Alert className={clsx(styles.alert)} color="danger">
              {errorComponent}
            </Alert>
          )}
          <div className="input-container">
            <div className="warning-message">
              <div className="warning-message-title">
                {t("pleaseTypeA")}
                <span className="warning-expert-name">{confirmationText}</span>
                {t("pleaseTypeB")}
              </div>
              <div className="warning-message">
                <InputController
                  name="confirmationText"
                  error={errors.confirmationText}
                  control={control}
                  placeholder=""
                />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="footerContainer">
          <div className="cancelContainer" onClick={cancelForm}>
            <span className="cancelText">
              {cancelActionText || t("cancel")}
            </span>
          </div>
          <Button
            size="md"
            color="primary"
            className="btn-remove"
            style={{ borderRadius: "8px" }}
            onClick={() => handleSubmit(onSubmit)}
            disabled={!isDirty || !isValid || loading}
          >
            <span className="confirmText">
              {deleteActionText || t("delete")}
            </span>
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
