import {
  CIQError,
  CanopyExpertBaseAnswer,
  CanopyQuestion,
  ErrorResponse,
  ExpertCanopy
} from "@arbolus-technologies/api";
import { VIDEO_MODE_ENUM } from "@arbolus-technologies/models/canopy";

import {
  ADD_EXPERT_CANOPY_AGREEMENT,
  ADD_EXPERT_CANOPY_AGREEMENT_FAILURE,
  ADD_EXPERT_CANOPY_AGREEMENT_SUCCESS,
  ADD_RETRY,
  ASSIGN_EXPERT_TO_CANOPY,
  ASSIGN_EXPERT_TO_CANOPY_FAILURE,
  ASSIGN_EXPERT_TO_CANOPY_SUCCESS,
  AddExpertCanopyAgreementAction,
  AddExpertCanopyAgreementFailureAction,
  AddExpertCanopyAgreementSuccessAction,
  AddRetryAction,
  AssignExpertToCanopyAction,
  AssignExpertToCanopyFailureAction,
  AssignExpertToCanopySuccessAction,
  CANOPY_RESET_CAMERA,
  CanopyCameraResetAction,
  DELETE_AND_UPLOAD_VIDEO,
  DELETE_AND_UPLOAD_VIDEO_FAILURE,
  DeleteAndUploadVideoAction,
  DeleteAndUploadVideoFailureAction,
  ENABLE_EXPERT_QUESTIONS,
  EnableExpertQuestionsAction,
  GET_CANOPY_ANSWER_DATA,
  GET_CANOPY_ANSWER_DATA_FAILURE,
  GET_CANOPY_ANSWER_DATA_SUCCESS,
  GET_CANOPY_EXPERT,
  GET_CANOPY_EXPERT_FAILURE,
  GET_CANOPY_EXPERT_QUESTIONS,
  GET_CANOPY_EXPERT_QUESTIONS_FAILURE,
  GET_CANOPY_EXPERT_QUESTIONS_SUCCESS,
  GET_CANOPY_EXPERT_SUCCESS,
  GetCanopyAnswerDataAction,
  GetCanopyAnswerDataFailureAction,
  GetCanopyAnswerDataSuccessAction,
  GetCanopyExpertAction,
  GetCanopyExpertFailureAction,
  GetCanopyExpertQuestionsAction,
  GetCanopyExpertQuestionsFailureAction,
  GetCanopyExpertQuestionsSuccessAction,
  GetCanopyExpertSuccessAction,
  MARK_QUESTION_AS_ANSWERED,
  MarkQuestionAsAnsweredAction,
  PLAYING_VIDEO,
  PlayingVideoAction,
  RECORDING_VIDEO,
  RECORD_QUESTION,
  RESET_CANOPY_ANSWER_DATA,
  RESET_MOBILE,
  RETAKE_VIDEO,
  RecordQuestionAction,
  RecordingVideoAction,
  ResetCanopyAnswerDataAction,
  ResetMobileAction,
  RetakeVideoAction,
  SEND_ANSWERS,
  SEND_ANSWERS_ERROR,
  SEND_ANSWERS_SUCCESS,
  SET_CANOPY_VIDEO,
  SHOW_RIGHT_COMPONENT,
  SendCanopyAnswersAction,
  SendCanopyAnswersErrorAction,
  SendCanopyAnswersSuccessAction,
  SetCanopyVideoAction,
  ShowRightComponentAction,
  UPLOAD_CANOPY_VIDEO,
  UPLOAD_CANOPY_VIDEO_ERROR,
  UPLOAD_CANOPY_VIDEO_SUCCESS,
  UploadCanopyVideoAction,
  UploadCanopyVideoErrorAction,
  UploadCanopyVideoSuccessAction,
  VIDEO_MODE,
  VideoModeAction
} from "./CanopyExpertActionTypes";

// Get Canopy by Expert
export const getCanopyExpert = (
  canopyId: string,
  expertId: string
): GetCanopyExpertAction => ({
  type: GET_CANOPY_EXPERT,
  payload: {
    canopyId,
    expertId
  }
});

export const getCanopyExpertSuccess = (
  canopyData: ExpertCanopy
): GetCanopyExpertSuccessAction => ({
  type: GET_CANOPY_EXPERT_SUCCESS,
  payload: { canopyData }
});

export const getCanopyExpertFailure = (
  error: ErrorResponse<CIQError>
): GetCanopyExpertFailureAction => ({
  type: GET_CANOPY_EXPERT_FAILURE,
  error
});

// Assign expert to Canopy
export const assignExpertToCanopy = (
  expertId: string,
  canopyId: string
): AssignExpertToCanopyAction => ({
  type: ASSIGN_EXPERT_TO_CANOPY,
  payload: {
    expertId,
    canopyId
  }
});

export const assignExpertToCanopySuccess =
  (): AssignExpertToCanopySuccessAction => ({
    type: ASSIGN_EXPERT_TO_CANOPY_SUCCESS
  });

export const assignExpertToCanopyFailure = (
  error: ErrorResponse<CIQError>
): AssignExpertToCanopyFailureAction => ({
  type: ASSIGN_EXPERT_TO_CANOPY_FAILURE,
  error
});

export const addRetry = (addRetry: number): AddRetryAction => ({
  type: ADD_RETRY,
  payload: {
    addRetry
  }
});

// Get survey list Expert

export const getCanopyExpertQuestions = (
  canopyId: string,
  expertId?: string
): GetCanopyExpertQuestionsAction => ({
  type: GET_CANOPY_EXPERT_QUESTIONS,
  payload: {
    canopyId,
    expertId
  }
});

export const getCanopyExpertQuestionsSuccess = (
  canopyQuestions: CanopyQuestion[]
): GetCanopyExpertQuestionsSuccessAction => ({
  type: GET_CANOPY_EXPERT_QUESTIONS_SUCCESS,
  payload: {
    canopyQuestions
  }
});

export const getCanopyExpertQuestionsFailure = (
  error: ErrorResponse<CIQError>
): GetCanopyExpertQuestionsFailureAction => ({
  type: GET_CANOPY_EXPERT_QUESTIONS_FAILURE,
  error
});

export const resetCanopyCamera = (): CanopyCameraResetAction => ({
  type: CANOPY_RESET_CAMERA
});

// Handle is RecordingVideo
export const setRecordingVideo = (
  isVideoRecording: boolean
): RecordingVideoAction => ({
  type: RECORDING_VIDEO,
  payload: {
    isVideoRecording
  }
});

// Handle is PlayingVideo
export const setPlayingVideo = (
  isVideoPlaying: boolean
): PlayingVideoAction => ({
  type: PLAYING_VIDEO,
  payload: {
    isVideoPlaying
  }
});

// Record Canopy Question
export const recordQuestion = (
  question: CanopyQuestion | null
): RecordQuestionAction => ({
  type: RECORD_QUESTION,
  payload: {
    question
  }
});

// Set Canopy Video
export const setCanopyVideo = (
  videoURL: string | null,
  videoBlob: Blob | null,
  isVideoSaving: boolean,
  isVideoUploaded: boolean,
  time: number,
  supportedVideoType: "webm" | "mp4" | "mpeg" | "quicktime" | null
): SetCanopyVideoAction => ({
  type: SET_CANOPY_VIDEO,
  payload: {
    videoURL,
    videoBlob,
    isVideoSaving,
    isVideoUploaded,
    time,
    supportedVideoType
  }
});

// Upload Canopy Video
export const uploadCanopyVideo = (
  questionId: string,
  videoBlob: FormData
): UploadCanopyVideoAction => ({
  type: UPLOAD_CANOPY_VIDEO,
  payload: {
    questionId,
    videoBlob
  }
});

export const uploadCanopyVideoSuccess = (
  questionId: string,
  answerId: string
): UploadCanopyVideoSuccessAction => ({
  type: UPLOAD_CANOPY_VIDEO_SUCCESS,
  payload: {
    questionId,
    answerId
  }
});

export const uploadCanopyVideoError = (
  error: ErrorResponse<CIQError>
): UploadCanopyVideoErrorAction => ({
  type: UPLOAD_CANOPY_VIDEO_ERROR,
  payload: { error }
});

// Canopy Answered Data

export const getCanopyAnswerData = (
  questionId: string,
  expertId: string
): GetCanopyAnswerDataAction => ({
  type: GET_CANOPY_ANSWER_DATA,
  payload: {
    questionId,
    expertId
  }
});

export const getCanopyAnswerDataSuccess = (
  canopyAnswerData: CanopyExpertBaseAnswer
): GetCanopyAnswerDataSuccessAction => ({
  type: GET_CANOPY_ANSWER_DATA_SUCCESS,
  payload: {
    canopyAnswerData
  }
});

export const getCanopyAnswerDataFailure = (
  error: ErrorResponse<CIQError>
): GetCanopyAnswerDataFailureAction => ({
  type: GET_CANOPY_ANSWER_DATA_FAILURE,
  error
});

export const retakeVideo = (): RetakeVideoAction => ({
  type: RETAKE_VIDEO
});

// DELETE AND UPLOAD VIDEO
export const deleteAndUploadVideo = (
  answerId: string,
  questionId: string,
  videoFormData: FormData
): DeleteAndUploadVideoAction => ({
  type: DELETE_AND_UPLOAD_VIDEO,
  payload: {
    answerId,
    questionId,
    videoFormData
  }
});

export const deleteAndUploadVideoFailure = (
  error: ErrorResponse<CIQError>
): DeleteAndUploadVideoFailureAction => ({
  type: DELETE_AND_UPLOAD_VIDEO_FAILURE,
  error
});

export const changeVideoMode = (mode: VIDEO_MODE_ENUM): VideoModeAction => ({
  type: VIDEO_MODE,
  payload: {
    mode
  }
});

// Update Canopy answers complete
export const sendCanopyAnswers = (
  canopyId: string,
  expertId: string
): SendCanopyAnswersAction => ({
  type: SEND_ANSWERS,
  payload: {
    canopyId,
    expertId
  }
});

export const sendCanopyAnswersSuccess = (): SendCanopyAnswersSuccessAction => ({
  type: SEND_ANSWERS_SUCCESS
});

export const sendCanopyAnswersError = (
  error: ErrorResponse<CIQError>
): SendCanopyAnswersErrorAction => ({
  type: SEND_ANSWERS_ERROR,
  error
});

export const resetMobile = (): ResetMobileAction => ({
  type: RESET_MOBILE
});

export const resetCanopyAnswerData = (): ResetCanopyAnswerDataAction => ({
  type: RESET_CANOPY_ANSWER_DATA
});

export const showRightComponent = (
  showRightComponent: boolean
): ShowRightComponentAction => ({
  type: SHOW_RIGHT_COMPONENT,
  payload: {
    showRightComponent
  }
});

// Add Expert Canopy agreement
export const addExpertCanopyAgreement = (
  canopyId: string,
  expertId: string
): AddExpertCanopyAgreementAction => ({
  type: ADD_EXPERT_CANOPY_AGREEMENT,
  payload: {
    canopyId,
    expertId
  }
});

export const addExpertCanopyAgreementSuccess =
  (): AddExpertCanopyAgreementSuccessAction => ({
    type: ADD_EXPERT_CANOPY_AGREEMENT_SUCCESS
  });

export const addExpertCanopyAgreementFailure = (
  error: ErrorResponse<CIQError>
): AddExpertCanopyAgreementFailureAction => ({
  type: ADD_EXPERT_CANOPY_AGREEMENT_FAILURE,
  error
});

export const enableExpertQuestions = (
  enabled = true
): EnableExpertQuestionsAction => ({
  type: ENABLE_EXPERT_QUESTIONS,
  payload: { enabled }
});

export const markQuestionAsAnswered = (
  questionId: string
): MarkQuestionAsAnsweredAction => ({
  type: MARK_QUESTION_AS_ANSWERED,
  payload: {
    questionId
  }
});

export const CanopyExpertStoreActions = {
  getCanopyExpert,
  getCanopyExpertSuccess,
  getCanopyExpertFailure,
  assignExpertToCanopy,
  assignExpertToCanopySuccess,
  assignExpertToCanopyFailure,
  addRetry,
  getCanopyExpertQuestions,
  getCanopyExpertQuestionsSuccess,
  getCanopyExpertQuestionsFailure,
  resetCanopyCamera,
  setRecordingVideo,
  setPlayingVideo,
  recordQuestion,
  setCanopyVideo,
  uploadCanopyVideo,
  uploadCanopyVideoSuccess,
  uploadCanopyVideoError,
  getCanopyAnswerData,
  getCanopyAnswerDataSuccess,
  getCanopyAnswerDataFailure,
  retakeVideo,
  deleteAndUploadVideo,
  deleteAndUploadVideoFailure,
  changeVideoMode,
  sendCanopyAnswers,
  sendCanopyAnswersSuccess,
  sendCanopyAnswersError,
  resetMobile,
  resetCanopyAnswerData,
  showRightComponent,
  addExpertCanopyAgreement,
  addExpertCanopyAgreementSuccess,
  addExpertCanopyAgreementFailure,
  enableExpertQuestions,
  markQuestionAsAnswered
};
