import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavItem, NavLink } from "reactstrap";

import { INBOX_NOTIFICATION_CATEGORY } from "../../../../../constants/notifications";
import { UtilsService } from "../../../../../services";
import { AuthSelector } from "../../../../auth/store";

const notificationTypeClassName = {
  [`${INBOX_NOTIFICATION_CATEGORY.ThingsTodo}`]: "things-todo",
  [`${INBOX_NOTIFICATION_CATEGORY.Messages}`]: "messages",
  [`${INBOX_NOTIFICATION_CATEGORY.EventInvites}`]: "event-invites",
  [`${INBOX_NOTIFICATION_CATEGORY.SharedDocs}`]: "shared-docs",
  [`${INBOX_NOTIFICATION_CATEGORY.NewJoins}`]: "new-joins",
  [`${INBOX_NOTIFICATION_CATEGORY.NewReferrals}`]: "new-referrals",
  [`${INBOX_NOTIFICATION_CATEGORY.Approvals}`]: "approvals",
  [`${INBOX_NOTIFICATION_CATEGORY.Applications}`]: "applications",
  [`${INBOX_NOTIFICATION_CATEGORY.Compliance}`]: "compliance",
  [`${INBOX_NOTIFICATION_CATEGORY.Surveys}`]: "surveys"
};

export const notificationTypeLabel = {
  [`${INBOX_NOTIFICATION_CATEGORY.ThingsTodo}`]: "thingsToDo",
  [`${INBOX_NOTIFICATION_CATEGORY.Messages}`]: "messages",
  [`${INBOX_NOTIFICATION_CATEGORY.EventInvites}`]: "eventInvites",
  [`${INBOX_NOTIFICATION_CATEGORY.SharedDocs}`]: "sharedDocs",
  [`${INBOX_NOTIFICATION_CATEGORY.NewJoins}`]: "newJoins",
  [`${INBOX_NOTIFICATION_CATEGORY.NewReferrals}`]: "candidates",
  [`${INBOX_NOTIFICATION_CATEGORY.NewReferrals}Expert`]: "newReferrals",
  [`${INBOX_NOTIFICATION_CATEGORY.Approvals}`]: "updates",
  [`${INBOX_NOTIFICATION_CATEGORY.Applications}`]: "updates",
  [`${INBOX_NOTIFICATION_CATEGORY.Compliance}`]: "compliance",
  [`${INBOX_NOTIFICATION_CATEGORY.Surveys}`]: "surveys"
};

interface CategoryItemProps {
  unreadCount: number;
  isActive: boolean;
  type: INBOX_NOTIFICATION_CATEGORY;
  onClick: (category: INBOX_NOTIFICATION_CATEGORY) => void;
}

const CategoryItem: React.FC<CategoryItemProps> = ({
  unreadCount,
  isActive,
  type,
  onClick
}): JSX.Element => {
  const { t } = useTranslation("inbox");
  const isClientUser = useSelector(AuthSelector.authIsClientUserSelector());
  const typeKey = `${type}${isClientUser ? "" : "Expert"}`;
  const personalizedTypeNameKey =
    notificationTypeLabel[typeKey] || notificationTypeLabel[type];

  return (
    <NavItem>
      <NavLink
        onClick={(): void => onClick(type)}
        className={clsx({
          active: isActive
        })}
      >
        <div className="img-container">
          <div className={`category-icon ${notificationTypeClassName[type]}`} />
        </div>
        <h4>{t(personalizedTypeNameKey)}</h4>
        {unreadCount > 0 && (
          <span className="notification-counter">
            {UtilsService.formatCountIndicator(unreadCount)}
          </span>
        )}
      </NavLink>
    </NavItem>
  );
};

export default CategoryItem;
