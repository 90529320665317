import { ICellRendererParams } from "ag-grid-community";
import React from "react";

import { SlotsReferral } from "@arbolus-technologies/models/common";

import { ReferralGridContext } from "../../Models/ReferralsTable";
import { RequestAvailabilityButton } from "../RequestAvailabilityButton/RequestAvailabilityButton";

import styles from "./RequestAvailabilityRenderer.module.scss";

export const RequestAvailabilityRenderer: React.FC<
  ICellRendererParams<SlotsReferral, never, ReferralGridContext>
> = ({ data, context }) => {
  if (!data?.slots) {
    return null;
  }

  return (
    <div className={styles.container}>
      <RequestAvailabilityButton
        referral={data}
        project={context.project}
        type="tertiary"
        size="large"
      />
    </div>
  );
};
