/* eslint-disable @typescript-eslint/no-empty-function */
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { createStructuredSelector } from "reselect";
import { Subscription } from "rxjs";
import SimpleBar from "simplebar-react";

import {
  ExpertsListPageTab,
  ToasterService,
  WorkHistory
} from "@arbolus-technologies/api";
import { BainConfirmation } from "@arbolus-technologies/features/project-experts-list";
import { REFERRAL_SUB_STATE } from "@arbolus-technologies/models/common";
import { REFERRAL_STATES } from "@arbolus-technologies/models/expert";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  ProjectExpertsSelector,
  ProjectExpertsStoreActions
} from "@arbolus-technologies/stores/project-experts-store";
import { Loader, ModalWithChildren } from "@arbolus-technologies/ui/components";

import { REST_ERROR } from "../../../../../constants/api";
import { CANDIDATE_PICKER_REFERRAL_FAKE_ANIMATE_TIMEOUT } from "../../../../../constants/timer";
import { CIQError, ErrorResponse } from "../../../../../models/api";
import { UpdateReferralRequest } from "../../../../../models/project";
import { StatusReferral } from "../../../../../models/view/candidatePicker";
import { ProjectService } from "../../../../../services";
import { AppAction } from "../../../../../store/actions";
import { AppState } from "../../../../../store/reducers";
import { ProjectSelector, ProjectStoreActions } from "../../../store";
import WrappedExpertProfileHolder, {
  ExpertProfileMode
} from "./ExpertProfileHolder";
import ReviewCompleted from "./ReviewCompleted";

const notification = new ToasterService();

interface CandidatePickerPanelStoreProps {
  referrals: StatusReferral[];
  projectId: string;
  isProjectLoading: boolean;
  loggedInUserClientId?: string;
  expertCurrentCompanies?: WorkHistory[];
  activeTab: ExpertsListPageTab | null;
}

interface CandidatePickerPanelProps extends CandidatePickerPanelStoreProps {
  onPanelClose: () => void;
  updateCandidateReferralStatus: (referralId: string, status: string) => void;
  updateProjectList: (projectId: string, activeTab: ExpertsListPageTab) => void;
}

interface CandidatePickerPanelState {
  referrals: StatusReferral[];
  currentEvaluationIndex: number;
  isReviewAnimate: boolean;
  isAnimationState: string;
  referralId: string;
  expertId: string;
  isBainModal: boolean;
}

type CandidatePickerPanelIntersectProps = CandidatePickerPanelProps &
  WithTranslation;

const bainId = process.env.NX_PUBLIC_BAIN_ID;

class CandidatePickerPanel extends Component<
  CandidatePickerPanelIntersectProps,
  CandidatePickerPanelState
> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    referrals: [],
    projectId: "",
    onPanelClose: (): void => {},
    updateCandidateReferralStatus: (): void => {},
    updateProjectList: (): void => {},
    isProjectLoading: false,
    loggedInUserClientId: "",
    expertCurrentCompanies: [],
    activeTab: null
  };

  constructor(props: CandidatePickerPanelIntersectProps) {
    super(props);
    this.state = {
      currentEvaluationIndex: 0,
      referrals: props.referrals,
      isReviewAnimate: false,
      isAnimationState: "",
      referralId: "",
      expertId: "",
      isBainModal: false
    };
  }

  static getDerivedStateFromProps(
    nextProps: CandidatePickerPanelIntersectProps
  ): CandidatePickerPanelState {
    const nextState = {} as CandidatePickerPanelState;
    nextState.referrals = nextProps.referrals;
    return nextState;
  }

  componentWillUnmount(): void {
    this.candidateFetchSubscription?.unsubscribe();
    this.candidateUpdateSubscription?.unsubscribe();
    this.referralFetchSubscription?.unsubscribe();

    if (this.updateReferralTimeout) {
      clearTimeout(this.updateReferralTimeout);
    }
  }

  private candidateFetchSubscription?: Subscription;

  private candidateUpdateSubscription?: Subscription;

  private referralFetchSubscription?: Subscription;

  private updateReferralTimeout?: number;

  updateReferralReviewState = (referralId: string, status: string): void => {
    const { updateCandidateReferralStatus } = this.props;
    updateCandidateReferralStatus(referralId, status);
  };

  propagateUpdateReferralState = (
    referralId: string,
    state: UpdateReferralRequest
  ): void => {
    const { projectId, t, activeTab, updateProjectList } = this.props;

    this.candidateUpdateSubscription = ProjectService.updateReferralsStatus(
      projectId,
      referralId,
      state
    ).subscribe(
      () => {
        updateProjectList(projectId, activeTab!);
        this.updateReferralReviewState(referralId, state.review!);
      },
      ({ message, status }: ErrorResponse<CIQError>) => {
        this.updateReferralTimeout && clearTimeout(this.updateReferralTimeout);
        this.setState({
          isReviewAnimate: false,
          isAnimationState: ""
        });
        if (status === REST_ERROR.INVALID_REFERRAL_STATE) {
          notification.showError(t("invalidStateError"));
        } else {
          notification.showError(message);
        }
      }
    );
  };

  handlePanelCloseClicked = (): void => {
    const { onPanelClose } = this.props;
    this.setState({
      currentEvaluationIndex: 0
    });
    onPanelClose();
  };

  handlePreviousCandidateClicked = (): void => {
    const { currentEvaluationIndex } = this.state;
    this.setState({
      currentEvaluationIndex: currentEvaluationIndex - 1
    });
  };

  handleNextCandidateClicked = (): void => {
    const { currentEvaluationIndex } = this.state;

    this.setState({
      currentEvaluationIndex: currentEvaluationIndex + 1
    });
  };

  handleAcceptCandidate = (referralId: string): void => {
    const { ACCEPT } = REFERRAL_SUB_STATE;
    this.setState({
      isReviewAnimate: true,
      isAnimationState: REFERRAL_STATES.ACCEPT
    });

    this.propagateUpdateReferralState(referralId, { review: ACCEPT });

    this.updateReferralTimeout = setTimeout(() => {
      this.handleNextCandidateClicked();
      this.setState({
        isReviewAnimate: false,
        isAnimationState: ""
      });
    }, CANDIDATE_PICKER_REFERRAL_FAKE_ANIMATE_TIMEOUT);
  };

  handleIsBainModal = (referralId: string, expertId: string): void => {
    this.setState({
      expertId: expertId,
      referralId: referralId,
      isBainModal: true
    });
  };

  renderProfile = (): JSX.Element => {
    const { projectId, loggedInUserClientId } = this.props;
    const {
      currentEvaluationIndex,
      referrals,
      isReviewAnimate,
      isAnimationState
    } = this.state;
    const isBain = loggedInUserClientId === bainId;
    const isLastStep = referrals.length === currentEvaluationIndex;
    const noCandidates = referrals.length === 0;

    if (isLastStep) {
      return (
        <ReviewCompleted
          noCandidates={noCandidates}
          onGoBackClicked={this.handlePreviousCandidateClicked}
          projectId={projectId}
          onPanelClose={this.props.onPanelClose}
        />
      );
    }

    const candidates = referrals.map((r) => (
      // @ts-ignore
      <WrappedExpertProfileHolder
        key={r.referralId}
        projectId={projectId}
        expertId={r.expertId}
        referralId={r.referralId}
        usageMode={ExpertProfileMode.REVIEW}
        onPanelClose={this.props.onPanelClose}
        candidatePickerProps={{
          onNext: this.handleNextCandidateClicked,
          onPrevious: this.handlePreviousCandidateClicked,
          isFirstCandidate: currentEvaluationIndex === 0,
          onCandidateAccept: () => {
            isBain
              ? this.handleIsBainModal(r.referralId, r.expertId)
              : this.handleAcceptCandidate(r.referralId);
          },
          isAnimating: isReviewAnimate,
          isAnimationState
        }}
      />
    ));

    return candidates[currentEvaluationIndex];
  };

  render(): JSX.Element {
    const { t, isProjectLoading, expertCurrentCompanies } = this.props;
    const { expertId, referralId, isBainModal } = this.state;
    return (
      <SimpleBar
        className="content-panel-body expert-profile-body simplebar-light"
        style={{ overflowX: "hidden" }}
      >
        <div className="panel-header candidate-picker">
          <h2>{t("review")}</h2>
          <div className="btn-close" onClick={this.handlePanelCloseClicked}>
            <i className="ciq-icon ciq-close" />
          </div>
        </div>

        <div className="panel-body">
          {isProjectLoading ? (
            <Loader isSidePanel />
          ) : (
            <>
              <ModalWithChildren
                toggleModal={isBainModal}
                messageTitle={t("companiesCID")}
                confirmActionText={t("passCID")}
                cancelActionText={t("reviewLater")}
                onCancel={() => this.setState({ isBainModal: false })}
                onConfirm={() => {
                  this.setState({ isBainModal: false }, () =>
                    this.handleAcceptCandidate(referralId)
                  );
                }}
              >
                <BainConfirmation
                  expertId={expertId}
                  expertCurrentCompanies={expertCurrentCompanies}
                />
              </ModalWithChildren>
              <div className="middle-container">{this.renderProfile()}</div>
            </>
          )}
        </div>
      </SimpleBar>
    );
  }
}

const mapStateToProps = createStructuredSelector<
  AppState,
  CandidatePickerPanelProps,
  CandidatePickerPanelStoreProps
>({
  projectId: ProjectSelector.projectIdSelector(),
  referrals: ProjectSelector.projectReferralsSelector(),
  isProjectLoading: ProjectSelector.projectLoadingSelector(),
  loggedInUserClientId: CacheSelector.loggedInUserClientId(),
  expertCurrentCompanies: ProjectExpertsSelector.expertCurrentCompanies(),
  activeTab: ProjectExpertsSelector.activeTab()
});

const mapDispatchToProps = (dispatch: Dispatch): Record<string, AppAction> => ({
  updateCandidateReferralStatus: (
    referralId: string,
    status: string
  ): AppAction =>
    dispatch(
      ProjectStoreActions.updateCandidateReferralStatus(referralId, status)
    ),
  updateProjectList: (
    projectId: string,
    activeTab: ExpertsListPageTab
  ): AppAction => {
    dispatch(ProjectExpertsStoreActions.getAllExperts(projectId, activeTab));
    dispatch(ProjectExpertsStoreActions.getProjectSummary(projectId));
  }
});

export default withTranslation("candidatePickerPanel")(
  connect(mapStateToProps, mapDispatchToProps)(CandidatePickerPanel)
);
