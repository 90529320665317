import clsx from "clsx";
import React, { useCallback, useRef, useState } from "react";
import {
  AsyncTypeahead,
  TypeaheadLabelKey,
  TypeaheadModel,
  TypeaheadResult
} from "react-bootstrap-typeahead";
import { FieldError } from "react-hook-form";
import { Observable } from "rxjs";

import {
  ApiErrorResponse,
  ApiPaginatedRequest,
  ApiPaginatedResponse,
  CIQError,
  SORT_DIRECTION
} from "@arbolus-technologies/api";
import { MINIMUM_OWNER_SEARCH_TERM_LENGTH } from "@arbolus-technologies/ui/components";

import { SelectCloseButton } from "./SelectCloseButton";

import styles from "./SelectTypeahead.module.scss";

interface SelectProps<T extends TypeaheadModel & object> {
  selected?: T[];
  name: string;
  onChange: (selected: T[]) => void;
  onBlur: () => void;
  orderBy: string;
  promptText: string;
  searchText: string;
  paginationText: string;
  error?: FieldError;
  placeholderText?: string;
  disabled?: boolean;
  displayResults?: number;
  defaultSelected?: T[];
  getItems: (
    pagination: ApiPaginatedRequest
  ) => Observable<ApiPaginatedResponse<T>>;
  renderer: (item: TypeaheadResult<T>) => JSX.Element;
  getLabelKey: TypeaheadLabelKey<T>;
  clientFilter?: (item: T, query: string) => boolean;
}

export const SelectTypeahead = <T extends TypeaheadModel & object>({
  name,
  selected,
  onChange,
  onBlur,
  error,
  orderBy,
  promptText,
  searchText,
  paginationText,
  defaultSelected,
  placeholderText = "",
  disabled = false,
  displayResults = 3,
  getItems,
  getLabelKey,
  renderer,
  clientFilter
}: SelectProps<T>): React.ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [cachedItems, setCachedItems] = useState<T[]>([]);
  const [items, setItems] = useState<T[]>([]);

  const typeaheadRef = useRef<AsyncTypeahead<T>>(null);
  const searchQuery = useRef("");

  const clientFiltering = useCallback(
    (query: string, items?: T[]) => {
      const allItems = items ?? cachedItems;
      const filtered = allItems.filter((item) => clientFilter!(item, query));
      setItems(filtered);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cachedItems, clientFilter, setItems]
  );

  const handleSearch = useCallback(
    (query: string, offset = 0): void => {
      searchQuery.current = query;

      if (clientFilter && cachedItems.length > 0) {
        clientFiltering(query);
        return;
      }

      const pagination: ApiPaginatedRequest = {
        offset,
        orderBy,
        searchTerm: query,
        orderDirection: SORT_DIRECTION.DESCENDING,
        limit: displayResults
      };

      setIsLoading(true);
      getItems(pagination).subscribe(
        ({ items }) => {
          setItems((prevState) => {
            if (offset) {
              return prevState.concat(items);
            }

            return items;
          });
          setCachedItems(items);
          setIsLoading(false);
          if (clientFilter) {
            clientFiltering(query, items);
          }
        },
        (error: ApiErrorResponse<CIQError>) => {
          setIsLoading(false);
        }
      );
    },
    [
      getItems,
      orderBy,
      cachedItems,
      clientFilter,
      clientFiltering,
      displayResults
    ]
  );

  const handlePagination = (): void => {
    handleSearch(searchQuery.current, items.length);
  };

  const handleOnChange = useCallback(
    (selection: T[]) => {
      onChange(selection);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange]
  );

  return (
    <AsyncTypeahead
      id={name}
      ref={typeaheadRef}
      minLength={MINIMUM_OWNER_SEARCH_TERM_LENGTH}
      labelKey={getLabelKey}
      maxResults={displayResults - 1}
      className={clsx(error && styles.errorInput, styles.input)}
      delay={300}
      isLoading={isLoading}
      options={items}
      defaultSelected={selected}
      filterBy={() => true}
      onPaginate={handlePagination}
      onChange={handleOnChange}
      onBlur={onBlur}
      onSearch={handleSearch}
      renderMenuItemChildren={renderer}
      placeholder={placeholderText}
      promptText={promptText}
      searchText={searchText}
      paginationText={paginationText}
      paginate
      useCache={false}
      disabled={disabled}
    >
      {!disabled && (selected?.length ?? 0) > 0 && (
        <SelectCloseButton
          onChange={(user) => handleOnChange(user ? [user] : [])}
          typeaheadRef={typeaheadRef}
        />
      )}
    </AsyncTypeahead>
  );
};
