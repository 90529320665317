import {
  ExpertSlotDetails,
  ProjectReferralAvailability
} from "@arbolus-technologies/api";
import {
  AngleModel,
  DateString,
  REFERRAL_STATUS
} from "@arbolus-technologies/models/common";
import { AvailabilityStatus } from "@arbolus-technologies/models/project";
import { displayUserName, isString } from "@arbolus-technologies/utils";

export interface ExpertAvailability {
  expert: {
    id: string;
    expertId: string;
    fullName: string;
    imageUrl: string;
    title: string;
    status: AvailabilityStatus;
    reviewStatus: "Accept" | "Pending";
    lastPublicCompanyExpDate: DateString | null;
  };
  slots: {
    startTime: string;
    endTime: string;
  }[];
  angle: AngleModel;
  referralId: string;
}

export class ProjectAvailabilitiesService {
  private static _statusSortOrder = {
    [AvailabilityStatus.BOOK]: 1,
    [AvailabilityStatus.PENDING_COMPLIANCE]: 2,
    [AvailabilityStatus.CANDIDATE]: 3
  };

  mapAvailabilities = ({
    slots,
    experts
  }: ProjectReferralAvailability): ExpertAvailability[] =>
    experts
      .map((expert) => ({
        expert: {
          id: expert.id,
          expertId: expert.expertId,
          fullName: displayUserName(expert),
          imageUrl: expert.profileImageUrl,
          title: expert.tagline || "",
          status: this.mapToStatus(expert),
          reviewStatus: expert.reviewStatus,
          lastPublicCompanyExpDate: expert.lastPublicCompanyExpDate ?? null
        },
        slots: slots
          .filter((slot) => slot.expertId === expert.expertId)
          .sort(
            (a, b) =>
              new Date(
                isString(a.startTime)
                  ? (a.startTime as string)
                  : a.startTime.toString()
              ).getTime() -
              new Date(
                isString(b.startTime)
                  ? (b.startTime as string)
                  : b.startTime.toString()
              ).getTime()
          )
          .map((slot) => ({
            startTime: isString(slot.startTime)
              ? (slot.startTime as string)
              : slot.startTime.toString(),
            endTime: isString(slot.endTime)
              ? (slot.endTime as string)
              : slot.endTime.toString()
          })),
        angle: expert.angle,
        referralId: expert.referralId
      }))
      .sort(this.sortByStatusAndDate);

  private mapToStatus = (expert: ExpertSlotDetails): AvailabilityStatus => {
    if (expert.status === REFERRAL_STATUS.CANDIDATE) {
      return AvailabilityStatus.CANDIDATE;
    }

    return expert.complianceStatus === "Pending"
      ? AvailabilityStatus.PENDING_COMPLIANCE
      : AvailabilityStatus.BOOK;
  };

  private sortByStatusAndDate = (
    a: ExpertAvailability,
    b: ExpertAvailability
  ): number => {
    if (a.expert.status === b.expert.status) {
      return (
        new Date(a.slots[0].startTime).getTime() -
        new Date(b.slots[0].startTime).getTime()
      );
    }

    return (
      ProjectAvailabilitiesService._statusSortOrder[a.expert.status] -
      ProjectAvailabilitiesService._statusSortOrder[b.expert.status]
    );
  };
}

export const DefaultProjectAvailabilitiesService =
  new ProjectAvailabilitiesService();
