import React from "react";
import { useTranslation } from "react-i18next";

import { PROJECT_DRAFTS_ROUTE } from "@arbolus-technologies/routes";
import { useDocumentTitle } from "@arbolus-technologies/utils";

import { ProjectListLayout } from "../Modules/ProjectsTable/ProjectListLayout";
import { ProjectsTable } from "../Modules/ProjectsTable/ProjectsTable";

export const ProjectsListPage: React.FC = () => {
  const { t } = useTranslation("projectsTable");
  useDocumentTitle("projectsTable", "title");

  return (
    <ProjectListLayout title={t("title")} to={PROJECT_DRAFTS_ROUTE()}>
      <ProjectsTable />
    </ProjectListLayout>
  );
};
