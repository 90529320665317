import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ANSWER_TYPE,
  AnswerMultiChoice,
  ChoiceAnswer,
  MultiChoiceSelectedQuestion
} from "@arbolus-technologies/models/canopy-panels";
import { HorizontalProgressComponentGroup } from "@arbolus-technologies/ui/components";
import { APP_DATE_FORMAT } from "@arbolus-technologies/utils";

import { convertToPercentage } from "../../../utils";
import { ExpertsListForCanopyAnswersSlidePanel } from "../../ExpertsListForCanopyAnswersSlidePanel/ExpertsListForCanopyAnswersSlidePanel";

interface MultiChoiceViewerProps {
  result: AnswerMultiChoice;
}

export const MultiChoiceViewer: React.FC<MultiChoiceViewerProps> = ({
  result
}) => {
  const { t } = useTranslation("multiChoice");

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedQuestionDetails, setSelectedQuestionDetails] =
    useState<MultiChoiceSelectedQuestion | null>(null);

  const { lastResponseDate, choiceAnswers, totalAnswersCount } = result;

  const optionChoices = (): string => {
    if (result.rangeMax === result.rangeMin && result.rangeMax > 1) {
      return t("rangeSpecificMax", { rangeMax: result.rangeMax });
    }
    if (result.rangeMax === result.rangeMin && result.rangeMax === 1) {
      return t("rangeSpecificOne", { rangeMax: result.rangeMax });
    } else {
      return t("rangeBetween", {
        rangeMin: result.rangeMin,
        rangeMax: result.rangeMax
      });
    }
  };

  const handleExpertsLink = useCallback(
    (percentage: number, choice: ChoiceAnswer) => {
      setSelectedQuestionDetails({
        percentage,
        choice: { id: choice.optionChoiceId, text: choice.optionChoiceText }
      });
      setIsOpen(true);
    },
    []
  );

  const convertedArray = useMemo(
    () =>
      choiceAnswers.map((answer) => {
        const percentage = convertToPercentage(
          0,
          totalAnswersCount,
          answer.answersCount
        );

        return {
          id: answer.optionChoiceId,
          title: answer.optionChoiceText ?? "",
          progressEndClick: () => handleExpertsLink(percentage, answer),
          progressEndText: t("expertsOutOf", {
            count: answer.answersCount,
            total: totalAnswersCount
          }),
          progressText: `${percentage.toFixed(2)} %`,
          value: percentage,
          isDisabled: answer.answersCount === 0
        };
      }),
    [choiceAnswers, handleExpertsLink, t, totalAnswersCount]
  );

  const lastResponse =
    lastResponseDate === null
      ? ""
      : `${t("lastResponse")}: ${moment(lastResponseDate).format(
          APP_DATE_FORMAT
        )}`;

  return (
    <>
      <HorizontalProgressComponentGroup
        headerText={optionChoices()}
        footerText={lastResponse}
        data={convertedArray}
      />
      <ExpertsListForCanopyAnswersSlidePanel
        selectedQuestionDetails={selectedQuestionDetails}
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
        panelTitle={t("experts")}
        panelHeader={{
          title: selectedQuestionDetails?.choice.text ?? "",
          message: `${selectedQuestionDetails?.percentage.toFixed(2)} %`
        }}
        type={ANSWER_TYPE.MULTIPLE_CHOICE}
      />
    </>
  );
};
