import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { NumberCircle } from "../../NumberCircle/NumberCircle";
import { ProgressComponentWithText } from "../../ProgressComponentWithText/ProgressComponentWithText";
import { Header } from "../Header/Header";

import styles from "./HorizontalProgressComponentGroup.module.scss";

export interface HorizontalProgressComponentChoices {
  id: string;
  title: string;
  progressEndClick?: () => void;
  progressEndText?: string;
  progressText: string;
  value: number;
  hasValue?: boolean;
  isDisabled?: boolean;
}

interface HorizontalProgressComponentGroupProps {
  headerText?: string;
  headerSubtitle?: string;
  headerTitle?: string;
  footerText?: string;
  data: HorizontalProgressComponentChoices[];
  questionNumber?: {
    value: number;
    icon: {
      name: string;
      color?: string;
      fontSize?: string;
    };
  };
  hideBorder?: boolean;
}

export const HorizontalProgressComponentGroup: React.FC<
  HorizontalProgressComponentGroupProps
> = ({
  headerText,
  headerSubtitle,
  headerTitle,
  data,
  footerText,
  questionNumber,
  hideBorder
}) => (
  <div className={clsx(styles.container, { [styles.hideBorder]: hideBorder })}>
    <div className={styles.progressGroupTitle}>
      {questionNumber && (
        <div className={styles.questionNumber}>
          <NumberCircle number={questionNumber.value} />
          <Icon
            name={questionNumber.icon.name}
            color={
              questionNumber.icon.color ?? ARBOLUS_COLORS.bColorSecondaryGreyMid
            }
            fontSize={questionNumber.icon.fontSize ?? "24px"}
          />
        </div>
      )}
      {(headerText || headerTitle) && (
        <Header
          text={headerText}
          title={headerTitle}
          subtitle={headerSubtitle}
        />
      )}
    </div>
    <div className={styles.progressGroupContainer}>
      {data.map((e) => (
        <ProgressComponentWithText
          key={e.id}
          progressTitle={e.title}
          progressEndClick={e.progressEndClick}
          progressEndTextClick={e.progressEndText}
          progressText={e.progressText}
          value={e.value}
          hasValue={!!e.hasValue}
          isDisabled={e.isDisabled}
        />
      ))}
    </div>
    {footerText && <div className={styles.footer}>{footerText}</div>}
  </div>
);
