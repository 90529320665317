import React from "react";
import { Control, Controller } from "react-hook-form";

import { ColorPicker } from "../ColorPicker/ColorPicker";

interface ColorPickerControllerProps {
  control: Control<any>;
  name: string;
  colors: string[];
  disabled?: boolean;
  triangle?: "hide" | "top-left" | "top-right";
}

export const ColorPickerController: React.FC<ColorPickerControllerProps> = ({
  control,
  name,
  colors,
  disabled,
  triangle
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value } }) => (
      <ColorPicker
        colors={colors}
        handleColorChange={onChange}
        selectedColor={value}
        disabled={disabled}
        triangle={triangle}
      />
    )}
  />
);
