import React from "react";

import styles from "./SquareWithIcon.module.scss";

export interface SquareWithIconProps {
  icon: string;
  color: string;
  background: string;
  border?: string;
  customWidth?: string;
  customHeight?: string;
  fontSize?: string;
  tooltip?: string;
}
export const SquareWithIcon: React.FC<SquareWithIconProps> = ({
  icon,
  color,
  background,
  border,
  customWidth,
  customHeight,
  fontSize,
  tooltip
}) => (
  <div
    className={styles.square}
    style={{
      color,
      background,
      border,
      minWidth: customWidth,
      minHeight: customHeight,
      maxWidth: customWidth,
      maxHeight: customHeight
    }}
  >
    <span
      style={{ fontSize: fontSize }}
      className="material-symbols-sharp"
      title={tooltip}
    >
      {icon}
    </span>
  </div>
);
