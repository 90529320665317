import React from "react";

import styles from "./ContainerHeader.module.scss";

interface ContainerHeaderProps {
  title: string;
  subtitle: string;
  leftComponent?: React.ReactNode;
  rightComponent?: React.ReactNode;
}

export const ContainerHeader: React.FC<ContainerHeaderProps> = ({
  title,
  subtitle,
  leftComponent,
  rightComponent
}) => (
  <div className={styles.container}>
    {leftComponent}
    <div className={styles.text}>
      <p className={styles.title}>{title}</p>
      <p className={styles.subtitle}>{subtitle}</p>
    </div>
    {rightComponent}
  </div>
);
