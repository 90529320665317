export enum MENU_BUTTON {
  Details = "Details",
  Questions = "Questions",
  Experts = "Experts"
}

export const enum CANOPY_TABS {
  VIDEOS = "videos",
  TRANSCRIPTS = "transcripts"
}

export enum COMPLIANCE_STEPS {
  REVIEW = "Review",
  CONFIRM_APPROVE = "ConfirmApprove",
  CONFIRM_REJECT = "ConfirmReject",
  APPROVED = "Approved",
  REJECTED = "Rejected"
}

export enum TRANSCRIPT_STATUS {
  NO_TRANSCRIPT = "NoTranscript",
  AUTOMATIC_TRANSCRIPT = "AutomaticTranscript",
  SMART_TRANSCRIPT = "SmartTranscript"
}

export enum TRANSCRIPTS_MODE {
  QUESTIONS_MODE = "questionsMode",
  EXPERTS_MODE = "expertsMode"
}

export enum TRANSCRIPT_EDIT_MODE {
  VIEW = "view",
  EDIT = "edit"
}

export enum ANSWER_TYPE {
  VIDEO = "Video",
  MULTIPLE_CHOICE = "MultipleChoice",
  VALUE = "Value",
  SHORT_TEXT = "ShortText",
  NPS = "Nps"
}
