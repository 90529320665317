import {
  InputModalData,
  ListModalData,
  ModalData
} from "../models/definitions";

export const OPEN_MAIN_MODAL = "PANELS_OPEN_MAIN_MODAL";
export const CLOSE_MAIN_MODAL = "PANELS_CLOSE_MAIN_MODAL";
export const OPEN_LIST_MAIN_MODAL = "PANELS_OPEN_LIST_MAIN_MODAL";
export const CLOSE_LIST_MAIN_MODAL = "PANELS_CLOSE_LIST_MAIN_MODAL";
export const OPEN_INPUT_MAIN_MODAL = "PANELS_OPEN_INPUT_MAIN_MODAL";
export const CLOSE_INPUT_MAIN_MODAL = "PANELS_CLOSE_INPUT_MAIN_MODAL";

export interface OpenMainModalAction {
  type: typeof OPEN_MAIN_MODAL;
  payload: ModalData;
}

export interface CloseMainModalAction {
  type: typeof CLOSE_MAIN_MODAL;
}

export interface OpenListMainModalAction {
  type: typeof OPEN_LIST_MAIN_MODAL;
  payload: ListModalData;
}

export interface CloseListMainModalAction {
  type: typeof CLOSE_LIST_MAIN_MODAL;
}

export interface OpenInputMainModalAction {
  type: typeof OPEN_INPUT_MAIN_MODAL;
  payload: InputModalData;
}

export interface CloseInputMainModalAction {
  type: typeof CLOSE_INPUT_MAIN_MODAL;
}
