import React from "react";
import { useTranslation } from "react-i18next";

import { MainPageLayout } from "@arbolus-technologies/ui/layout";
import { useDocumentTitle } from "@arbolus-technologies/utils";

import { CustomerSurveyTable } from "../modules/CustomerSurveyTable/CustomerSurveyTable";

export const CustomerSurveysPage: React.FC = () => {
  const { t } = useTranslation("customerSurveyTable");
  useDocumentTitle("customerSurveyTable", "pageTitle");

  return (
    <MainPageLayout title={t("pageTitle")} stickyHeader>
      <CustomerSurveyTable />
    </MainPageLayout>
  );
};
