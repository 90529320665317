import React from "react";
import { Button } from "reactstrap";

import styles from "./DateRangeButton.module.scss";

export interface DateRange {
  name: string;
  value: number | null;
  type: "days" | "months" | "all";
  isSelected: boolean;
  id: number;
}

interface DateRangeButtonProps {
  dateRangeItem: DateRange;
  setDateValues: (dateRangeItem: DateRange) => void;
}

export const DateRangeButton: React.FC<DateRangeButtonProps> = ({
  dateRangeItem,
  setDateValues
}) => {
  const dateSelected = (dateRangeItem: DateRange) => {
    setDateValues(dateRangeItem);
  };

  return (
    <Button
      color={dateRangeItem.isSelected ? "primary" : "tertiary"}
      onClick={() => dateSelected(dateRangeItem)}
      className={styles.rangeButton}
    >
      {dateRangeItem.name}
    </Button>
  );
};
