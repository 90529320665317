import { Button } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { DangerouslySetInnerHTML } from "../DangerouslySetInnerHTML/DangerouslySetInnerHTML";
import { ModalButton } from "./ModalButton";

import styles from "./InternalArbolusModal.module.scss";

export interface InternalArbolusModalProps {
  title: string;
  iconTitle?: string;
  subtitle?: string;
  toggle: () => void; // setState to False to dismiss the modal when click outside
  isOpen: boolean;
  leftButton?: ModalButton;
  rightButton: ModalButton;
}

interface ForbiddenExportInternalArbolusModalProps {
  size?: "sm" | "lg";
  body?: React.ReactNode;
}

type Props = InternalArbolusModalProps &
  ForbiddenExportInternalArbolusModalProps;

// Never make it available outside of the library
export const InternalArbolusModal: React.FC<Props> = ({
  title,
  subtitle,
  body,
  isOpen,
  toggle,
  iconTitle,
  leftButton,
  rightButton,
  size = "sm"
}) => (
  <Modal
    isOpen={isOpen}
    className={clsx("modal-alert", styles.container, styles[size])}
    toggle={toggle}
  >
    {title && (
      <ModalHeader className={styles.title}>
        {iconTitle && (
          <img src={iconTitle} alt="iconTitle" className={styles.image} />
        )}
        {title}
      </ModalHeader>
    )}
    <ModalBody className={styles.body}>
      {subtitle && (
        <DangerouslySetInnerHTML
          text={subtitle}
          color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
        />
      )}
      {body}
    </ModalBody>
    <ModalFooter className={styles.footer}>
      <div className={styles.buttonsContainer}>
        {leftButton && <Button {...leftButton} />}
        <Button {...rightButton} />
      </div>
    </ModalFooter>
  </Modal>
);
