import dompurify from "dompurify";
import React from "react";
import { useTranslation } from "react-i18next";

import { VideoAnswer } from "@arbolus-technologies/models/canopy-panels";
import { TRANSCRIPT_STATUS } from "@arbolus-technologies/models/common";

import { TranscriptStatusTag } from "../TranscriptStatusTag/TranscriptStatusTag";

import styles from "./IndividualTranscriptResponse.module.scss";

interface IndividualTranscriptProps {
  videoAnswer: VideoAnswer;
}

export const IndividualTranscript: React.FC<IndividualTranscriptProps> = ({
  videoAnswer
}) => {
  const { t } = useTranslation("individualResponse");
  const sanitizer = dompurify.sanitize;

  const handleTranscriptStatus = (): TRANSCRIPT_STATUS => {
    if (videoAnswer && videoAnswer.transcript === null) {
      return TRANSCRIPT_STATUS.NO_TRANSCRIPT;
    } else if (videoAnswer && videoAnswer.isSmartTranscript) {
      return TRANSCRIPT_STATUS.SMART_TRANSCRIPT;
    } else {
      return TRANSCRIPT_STATUS.AUTOMATIC_TRANSCRIPT;
    }
  };

  return (
    <>
      <div className={styles.buttonsContainer}>
        <TranscriptStatusTag transcriptStatus={handleTranscriptStatus()} />
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizer(
            videoAnswer.transcript === "" ||
              videoAnswer.transcript === null ||
              videoAnswer.transcript === undefined
              ? t("notAvailable")
              : videoAnswer.transcript || ""
          )
        }}
        className={styles.transcript}
      />
    </>
  );
};
