import { Button } from "arbolus-ui-components";
import dompurify from "dompurify";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { map } from "rxjs/operators";

import {
  CanopyService,
  DefaultToasterService,
  ToasterService
} from "@arbolus-technologies/api";
import { CanopyAgreement } from "@arbolus-technologies/models/canopy";
import { CanopyExpertStoreActions } from "@arbolus-technologies/stores/canopy-expert";
import { Drawer } from "@arbolus-technologies/ui/components";

import styles from "./CanopyEngagementAgreement.module.scss";

interface CanopyEngagementAgreementProps {
  canopyId: string;
  expertId: string;
  canopyService?: typeof CanopyService;
  notificationService?: ToasterService;
}

export const CanopyEngagementAgreement: React.FC<
  CanopyEngagementAgreementProps
> = ({
  canopyId,
  expertId,
  canopyService = CanopyService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("canopyEngagementAgreement");
  const dispatch = useDispatch();
  const [agreement, setAgreement] = useState<CanopyAgreement>();

  useEffect(() => {
    canopyService
      .getCanopyAgreement(canopyId)
      .pipe(map((response) => response.canopyAgreement))
      .subscribe({
        next: setAgreement,
        error: notificationService.showApiErrors
      });
  }, [canopyId, canopyService, notificationService]);

  const handleAcceptAgreement = () => {
    dispatch(
      CanopyExpertStoreActions.addExpertCanopyAgreement(canopyId, expertId)
    );
  };

  return (
    <div className={styles.canopyEngagementAgreement}>
      <h2>{t("title")}</h2>
      {agreement?.headerTitle && (
        <Drawer title={agreement.headerTitle} openByDefault>
          <div
            dangerouslySetInnerHTML={{
              __html: dompurify
                .sanitize(agreement.headerText)
                .replace(/href/g, "target='_blank' href")
            }}
          />
        </Drawer>
      )}
      {agreement?.agreementTerms
        ?.sort((a, b) => a.sortOrder - b.sortOrder)
        .map((term) => (
          <Drawer key={term.termTitle} title={term.termTitle}>
            <div
              dangerouslySetInnerHTML={{
                __html: dompurify.sanitize(`<p>${term.termText}</p>`)
              }}
            />
          </Drawer>
        ))}
      {agreement?.footerTitle && (
        <Drawer title={agreement.footerTitle} openByDefault>
          <div
            dangerouslySetInnerHTML={{
              __html: dompurify
                .sanitize(agreement.footerText)
                .replace(/href/g, "target='_blank' href")
            }}
          />
        </Drawer>
      )}

      <div className={styles.btnAgreement}>
        <Button
          onClick={handleAcceptAgreement}
          disabled={!agreement}
          text={t("acceptAndContinue")}
        />
      </div>
    </div>
  );
};
