import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./EngageWithMe.module.scss";

export interface EngageWithMeProps {
  handleOpenEngageSidePanel: () => void;
}

export const EngageWithMe: React.FC<EngageWithMeProps> = ({
  handleOpenEngageSidePanel
}) => {
  const { t } = useTranslation("engageWithMe");

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <h2 className={styles.title}>{t("askMore")}</h2>
        <p className={styles.subtitle}>{t("engageText")}</p>
      </div>
      <Button
        text={t("askMore")}
        endIcon="chevron_right"
        onClick={handleOpenEngageSidePanel}
      />
    </div>
  );
};
