export interface BookmarksUrlParams {
  bookmarkCollectionId: string;
}

export const BOOKMARK_COLLECTIONS = "/bookmarks";
export const BOOKMARKS = `${BOOKMARK_COLLECTIONS}/:bookmarkCollectionId`;

export const BOOKMARKS_ROUTE = (bookmarkCollectionId: string): string =>
  `${BOOKMARK_COLLECTIONS}/${bookmarkCollectionId}`;

export const BOOKMARKS_ROUTES = [BOOKMARK_COLLECTIONS, BOOKMARKS];
