import { RELATIONSHIP_TYPE } from "../enums/companiesEnums";
import { Company } from "./company";
import { ExpertCompany, ExpertCountry, ExpertIndustry } from "./expert";
import { Country, Industry } from "./meta";

export const industryToExpertIndustry = ({
  id,
  name,
  naicsId,
  parentId
}: Industry): ExpertIndustry => ({ id, name, naicsId, parentId });

export const expertIndustryToIndustry = ({
  id,
  name,
  naicsId,
  parentId
}: ExpertIndustry): Industry => ({ id, name, naicsId, parentId });

export const companyToExpertCompany = ({
  id,
  name
}: Company): ExpertCompany => ({ id, name });

export const expertCompanyToCompany = ({
  id,
  name
}: ExpertCompany): Company => ({
  id,
  name,
  relationship: { type: RELATIONSHIP_TYPE.None }
});

export const countryToExpertCountry = (country: Country): ExpertCountry => ({
  id: country.id,
  name: country.name,
  isoCountryCode: country.threeLetterCode
});

export const expertCountryToCountry = (country: ExpertCountry): Country => ({
  id: country.id,
  name: country.name,
  threeLetterCode: country.isoCountryCode,
  twoLetterCode: "",
  sortOrder: 0
});
