import { Button } from "arbolus-ui-components";
import queryString from "query-string";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import { ProjectExpertManagementWrapper } from "@arbolus-technologies/features/common";

import { ExpertList } from "../../../../Components/Discover/ExpertList/ExpertList";
import { SearchFilter } from "../../../../Components/SearchFilter/SearchFilter";
import { useDiscoverExperts } from "../../../../Hooks/Discover/useDiscoverExperts";

import styles from "./MobileExpertsPage.module.scss";

interface MobileExpertsPageProps {
  projectId: string;
  projectName: string;
  setAreFiltersOpen: (value: boolean) => void;
  areFiltersOpen: boolean;
}

export const MobileExpertsPage: React.FC<MobileExpertsPageProps> = ({
  projectId,
  projectName,
  setAreFiltersOpen,
  areFiltersOpen
}) => {
  const { t } = useTranslation("discoverPage");
  const location = useLocation();

  const parameters = queryString.parse(location.search);
  const { query = "" }: { query?: string } = parameters;

  const { handleSearchTerm } = useDiscoverExperts({ projectId });

  return (
    <ProjectExpertManagementWrapper>
      <div className={styles.search}>
        <SearchFilter initialValue={query} onSearchTerm={handleSearchTerm} />
        <Button
          type="grey"
          text={t("mobileFilterPanelButton")}
          endIcon="filter_list"
          onClick={() => setAreFiltersOpen(!areFiltersOpen)}
        />
      </div>
      <ExpertList projectId={projectId} projectName={projectName} />
    </ProjectExpertManagementWrapper>
  );
};
