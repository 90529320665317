import React from "react";

import { CIQTableColumn } from "./CIQTable";
import { CIQTableSetFilter } from "./filters/TableSetFilter";
import { CIQTableTextFilter } from "./filters/TableTextFilter";

import styles from "./CIQTableHeader.module.scss";

interface CIQTableHeaderProps<T, U> {
  column: CIQTableColumn<T, U>;
}

export const CIQTableHeader = <T, U>({
  column
}: CIQTableHeaderProps<T, U>): React.ReactElement => (
  <th
    key={column.valueName ?? column.displayName}
    className={styles.headerCell}
    style={column.width ? { width: `${column.width}px` } : {}}
  >
    {!column.filterOptions && (column.displayName ?? column.valueName)}
    {column.filterOptions && column.filterOptions.type === "set" && (
      <CIQTableSetFilter
        displayName={column.displayName ?? column.valueName}
        options={column.filterOptions.options}
        onSetFilterChange={column.filterOptions.onFilterChange}
      />
    )}
    {column.filterOptions && column.filterOptions.type === "text" && (
      <CIQTableTextFilter
        displayName={column.displayName ?? column.valueName}
        debounceTime={column.filterOptions.debounceTime}
        minLength={column.filterOptions.minLength}
        onTextFilterChange={column.filterOptions.onFilterChange}
      />
    )}
  </th>
);
