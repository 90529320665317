import { SEARCH_DEBOUNCE_TIMEOUT as _SEARCH_DEBOUNCE_TIMEOUT } from "@arbolus-technologies/utils";

/**
 * @deprecated use libs/utils/src/lib/constants/Timer.ts
 */
export const CONTENT_PANEL_ANIMATION_TIME = 250;

/**
 * @deprecated use libs/utils/src/lib/constants/Timer.ts
 */
export const SEARCH_DEBOUNCE_TIMEOUT = _SEARCH_DEBOUNCE_TIMEOUT;

/**
 * @deprecated use libs/utils/src/lib/constants/Timer.ts
 */
export const DOCUMENT_UPLOAD_COMPLETE_TIMEOUT = 2000;

/**
 * @deprecated use libs/utils/src/lib/constants/Timer.ts
 */
export const CANDIDATE_PICKER_REFERRAL_FAKE_ANIMATE_TIMEOUT = 2000;
export const CANDIDATE_ENDORSEMENT_CAROUSEL_SLIDE_INTERVAL = 5000;

/**
 * @deprecated use libs/utils/src/lib/constants/Timer.ts
 */
export const EXPERT_PROJECT_APPLICATION_IDLE_TIMEOUT_HOURS = 1;

/**
 * @deprecated use libs/utils/src/lib/constants/Timer.ts
 */
export const READ_MORE_OVERFLOW_DEBOUNCE = 50;

/**
 * @deprecated use libs/utils/src/lib/constants/Timer.ts
 */
export const TRANSCRIPT_HEADER_RESIZE_DEBOUNCE = 300;
