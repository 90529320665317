import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import {
  CIQError,
  CanopyService,
  ErrorResponse
} from "@arbolus-technologies/api";
import { NpsTypeViewer } from "@arbolus-technologies/features/common";
import { CanopyParamUrlTypes } from "@arbolus-technologies/models/canopy";
import { NpsAnswers } from "@arbolus-technologies/models/canopy-panels";
import { Loader } from "@arbolus-technologies/ui/components";

interface QuestionNpsAnswersProps {
  canopyService?: typeof CanopyService;
}

export const QuestionNpsAnswers: React.FC<QuestionNpsAnswersProps> = ({
  canopyService = CanopyService
}) => {
  const { canopyQuestionId } = useParams<CanopyParamUrlTypes>();

  const [isLoading, setIsLoading] = useState(false);
  const [npsAnswer, setNpsAnswer] = useState<NpsAnswers | undefined>();

  const getNpsVideoAnswers = () => {
    setIsLoading(true);
    canopyService.getCanopyQuestionNPSAnswer(canopyQuestionId).subscribe(
      (response) => {
        setNpsAnswer(response);
        setIsLoading(false);
      },
      (error: ErrorResponse<CIQError>) => {
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    getNpsVideoAnswers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && npsAnswer && (
        <NpsTypeViewer data={npsAnswer} questionId={canopyQuestionId} />
      )}
    </>
  );
};
