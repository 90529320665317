import React from "react";
import { Col } from "reactstrap";

import { Loader } from "@arbolus-technologies/ui/components";

interface CardProps {
  loading: boolean;
  key?: string;
  title?: string;
  onTitleClick?: () => void;
  children: React.ReactNode;
}

const Card = (props: CardProps): JSX.Element => {
  const { loading, key, title, children, onTitleClick } = props;

  const renderLoader = (): JSX.Element => <Loader isLight isFull />;

  return (
    <Col xl="4" lg="6" md="12" sm="12" xs="12" key={key}>
      <div className="base-list-box">
        {title && (
          <div className="bl-title" onClick={onTitleClick}>
            {title}
          </div>
        )}
        {loading ? renderLoader() : children}
      </div>
    </Col>
  );
};

Card.defaultProps = {
  loading: false
};
export default Card;
