import { ReferralsTabCount } from "../../models/definitions";
import {
  LOAD_REFERRAL_TAB_COUNT,
  LOAD_REFERRAL_TAB_COUNT_FAILURE,
  LOAD_REFERRAL_TAB_COUNT_SUCCESS,
  LoadReferralTabCountAction,
  LoadReferralTabCountFailureAction,
  LoadReferralTabCountSuccessAction
} from "./ReferralActionTypes";

export function loadReferralTabCount(
  projectId: string
): LoadReferralTabCountAction {
  return {
    type: LOAD_REFERRAL_TAB_COUNT,
    payload: {
      projectId
    }
  };
}

export function loadReferralTabCountSuccess(
  tabCount: ReferralsTabCount
): LoadReferralTabCountSuccessAction {
  return {
    type: LOAD_REFERRAL_TAB_COUNT_SUCCESS,
    payload: {
      ...tabCount
    }
  };
}

export function loadReferralTabCountFailure(): LoadReferralTabCountFailureAction {
  return {
    type: LOAD_REFERRAL_TAB_COUNT_FAILURE
  };
}
