import { ExpertsListPageTab } from "@arbolus-technologies/api";

export const handleNameItems = {
  EDIT_PROFILE: "editProfile",
  EDIT_ANGLE: "editAngle",
  ADD_NOTE: "addNote",
  CANDIDATE: "candidate",
  SHORTLIST: "shortlist",
  REMOVE: "remove",
  RESET_APP: "resetApp",
  APPROVE: "approve",
  REJECT: "reject",
  SEND_APPLICATION_REMINDER: "SendApplicationReminder",
  COPY_APPLICATION_LINK: "CopyApplicationLink",
  COPY_BOOKING_LINK: "CopyBookingLink"
};

export interface ItemInterface {
  title: string;
  iconName: string;
  showDivider: boolean;
  handleName: string;
}

interface ItemListI {
  [key: string]: ItemInterface[];
}

// Translation keys for "projectExpertListMenu" (see useTranslation in ThreeDots)
const moveToCandidates = "moveToCandidates";
const editProfile = "editProfile";
const editAngle = "editAngle";
const addNote = "addNote";
const remove = "remove";
const resetApp = "resetApp";

export const itemList: ItemListI = {
  [ExpertsListPageTab.ShortList]: [
    {
      title: moveToCandidates,
      iconName: "candidate",
      showDivider: false,
      handleName: handleNameItems.CANDIDATE
    },
    {
      title: editProfile,
      iconName: "edit",
      showDivider: true,
      handleName: handleNameItems.EDIT_PROFILE
    },
    {
      title: editAngle,
      iconName: "zoom_in_map",
      showDivider: false,
      handleName: handleNameItems.EDIT_ANGLE
    },
    {
      title: addNote,
      iconName: "notes",
      showDivider: false,
      handleName: handleNameItems.ADD_NOTE
    },
    {
      title: remove,
      iconName: "delete",
      showDivider: false,
      handleName: handleNameItems.REMOVE
    }
  ],
  [ExpertsListPageTab.Candidate]: [
    {
      title: editProfile,
      iconName: "edit",
      showDivider: false,
      handleName: handleNameItems.EDIT_PROFILE
    },
    {
      title: editAngle,
      iconName: "zoom_in_map",
      showDivider: false,
      handleName: handleNameItems.EDIT_ANGLE
    },
    {
      title: addNote,
      iconName: "notes",
      showDivider: false,
      handleName: handleNameItems.ADD_NOTE
    },
    {
      title: resetApp,
      iconName: "replay",
      showDivider: false,
      handleName: handleNameItems.RESET_APP
    },
    {
      title: remove,
      iconName: "delete",
      showDivider: true,
      handleName: handleNameItems.REMOVE
    }
  ],
  [ExpertsListPageTab.Accept]: [
    {
      title: editProfile,
      iconName: "edit",
      showDivider: false,
      handleName: handleNameItems.EDIT_PROFILE
    },
    {
      title: editAngle,
      iconName: "zoom_in_map",
      showDivider: false,
      handleName: handleNameItems.EDIT_ANGLE
    },
    {
      title: addNote,
      iconName: "notes",
      showDivider: false,
      handleName: handleNameItems.ADD_NOTE
    },
    {
      title: resetApp,
      iconName: "replay",
      showDivider: false,
      handleName: handleNameItems.RESET_APP
    },
    {
      title: remove,
      iconName: "delete",
      showDivider: true,
      handleName: handleNameItems.REMOVE
    }
  ],
  [ExpertsListPageTab.Reject]: [
    {
      title: editProfile,
      iconName: "edit",
      showDivider: false,
      handleName: handleNameItems.EDIT_PROFILE
    },
    {
      title: addNote,
      iconName: "notes",
      showDivider: false,
      handleName: handleNameItems.ADD_NOTE
    },
    {
      title: resetApp,
      iconName: "replay",
      showDivider: false,
      handleName: handleNameItems.RESET_APP
    },
    {
      title: remove,
      iconName: "delete",
      showDivider: true,
      handleName: handleNameItems.REMOVE
    }
  ]
};
