import React from "react";
import { useTranslation } from "react-i18next";

import { DateString } from "@arbolus-technologies/models/common";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import {
  PublicCompanyIconColors,
  getPublicBadgeType
} from "../../../models/Company";
import { BadgeWithIcon } from "../BadgeWithIcon";

interface PublicCompanyBadgeProps {
  date?: Date | DateString | null;
}

export const PublicCompanyBadge: React.FC<PublicCompanyBadgeProps> = ({
  date
}) => {
  const { t } = useTranslation("publicCompanyIcon");
  const badgeType = getPublicBadgeType(date);

  return (
    <BadgeWithIcon
      text={t(badgeType)}
      icon="trending_up"
      backgroundColor={PublicCompanyIconColors[badgeType]}
      color={ARBOLUS_COLORS.bColorBaseWhite}
      fontSize="12px"
    />
  );
};
