import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./ComplianceWarning.module.scss";

export const ComplianceWarning: React.FC<{
  isApplicationPending: boolean;
  isCompliancePending: boolean;
  isBainCIDPending: boolean;
}> = ({ isApplicationPending, isCompliancePending, isBainCIDPending }) => {
  const { t } = useTranslation("scheduleTab");

  const hasComplianceWarning =
    isApplicationPending || isCompliancePending || isBainCIDPending;

  let complianceWarningTranslation = "";
  if (isBainCIDPending) {
    complianceWarningTranslation = "pendingCID";
  } else if (isApplicationPending) {
    complianceWarningTranslation = "pendingApplication";
  } else if (isCompliancePending) {
    complianceWarningTranslation = "pendingCompliance";
  }

  if (hasComplianceWarning) return null;

  return (
    <span className={styles.compliance}>{t(complianceWarningTranslation)}</span>
  );
};
