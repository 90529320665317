import moment from "moment";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ANSWER_PREFERENCES_TYPE_VALUE } from "@arbolus-technologies/models/canopy";
import {
  MultiRatingChoice,
  RangeBetween
} from "@arbolus-technologies/models/canopy-panels";
import { HorizontalProgressComponentGroup } from "@arbolus-technologies/ui/components";
import { CANOPY_DATE_TIME_FORMAT } from "@arbolus-technologies/utils";

import { convertToPercentage } from "../../../utils";
import { AnswerQuestion } from "../types";

interface ValueTypeViewerByExpertProps {
  question: AnswerQuestion;
  multiRatingChoices: MultiRatingChoice[];
  value: number | null;
  valuePreferencesType: ANSWER_PREFERENCES_TYPE_VALUE;
  valuePreferencesRange: RangeBetween;
  created: string;
}

export const ValueTypeViewerByExpert: React.FC<
  ValueTypeViewerByExpertProps
> = ({
  question,
  multiRatingChoices,
  value,
  valuePreferencesType,
  valuePreferencesRange,
  created
}) => {
  const { t } = useTranslation("valueTypeViewerByExpert");

  const { id, text, details, sortOrder } = question;
  const { minimum, maximum } = valuePreferencesRange;

  const convertedArray = useMemo(() => {
    if (valuePreferencesType === ANSWER_PREFERENCES_TYPE_VALUE.MULTI_RATING) {
      return multiRatingChoices.map((item) => {
        const avg = t("expertValue", {
          value: item.multiRatingValue ?? t("n/a")
        });
        const valuePercentage = item.multiRatingValue
          ? convertToPercentage(minimum, maximum, item.multiRatingValue)
          : 0;

        return {
          id: item.multiRatingChoiceId ?? id,
          title: item.multiRatingChoiceText ?? "",
          progressText: avg,
          value: valuePercentage,
          hasValue: item.multiRatingValue !== null
        };
      });
    }

    const isTypePercentage =
      valuePreferencesType === ANSWER_PREFERENCES_TYPE_VALUE.PERCENTAGE;
    const avg =
      value === null ? t("n/a") : `${value}${isTypePercentage ? "%" : ""}`;
    const valuePercentage = value
      ? convertToPercentage(minimum, maximum, value)
      : 0;

    return [
      {
        id,
        title: "",
        progressText: avg,
        value: valuePercentage,
        hasValue: value !== null
      }
    ];
  }, [
    valuePreferencesType,
    value,
    t,
    minimum,
    maximum,
    id,
    multiRatingChoices
  ]);

  const lastResponse = `${t("responseDate")}: ${moment(created).format(
    CANOPY_DATE_TIME_FORMAT
  )}`;

  return (
    <HorizontalProgressComponentGroup
      headerTitle={text}
      headerSubtitle={details}
      questionNumber={{
        value: sortOrder ? sortOrder + 1 : 1,
        icon: { name: "numbers" }
      }}
      footerText={lastResponse}
      data={convertedArray}
    />
  );
};
