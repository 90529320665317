import { ActionCreator } from "redux";

import {
  CIQError,
  ErrorResponse,
  ExpertDetail
} from "@arbolus-technologies/api";
import { EXPERT_FOLLOW_UP_STEPS } from "@arbolus-technologies/models/expert";

import {
  EXIT_FROM_EXPERT,
  ExitFromExpertAction,
  GET_EXPERT,
  GET_EXPERT_FAILURE,
  GET_EXPERT_SUCCESS,
  GetExpertAction,
  GetExpertFailureAction,
  GetExpertSuccessAction,
  RESET_EXPERT_FOLLOW_UP_STEP,
  ResetExpertFollowUpStepAction,
  SET_EXPERT_FOLLOW_UP_STEP,
  SetExpertFollowUpStepAction
} from "./ExpertActionTypes";

const getExpert: ActionCreator<GetExpertAction> = (
  expertId: string,
  initial = false
) => ({
  type: GET_EXPERT,
  payload: {
    expertId,
    initial
  }
});

const getExpertSuccess: ActionCreator<GetExpertSuccessAction> = (
  expertId: string,
  expertDetails: ExpertDetail
) => ({
  type: GET_EXPERT_SUCCESS,
  payload: {
    expertId,
    expertDetails
  }
});

const getExpertFailure: ActionCreator<GetExpertFailureAction> = (
  error: ErrorResponse<CIQError>
) => ({
  type: GET_EXPERT_FAILURE,
  payload: { error }
});

export const exitFromExpert: ActionCreator<ExitFromExpertAction> = () => ({
  type: EXIT_FROM_EXPERT,
  payload: {}
});

export const setExpertFollowUpStep = (
  step: EXPERT_FOLLOW_UP_STEPS
): SetExpertFollowUpStepAction => ({
  type: SET_EXPERT_FOLLOW_UP_STEP,
  payload: { step }
});

export const resetExpertFollowUpStep = (): ResetExpertFollowUpStepAction => ({
  type: RESET_EXPERT_FOLLOW_UP_STEP
});

export const ExpertStoreActions = {
  getExpert,
  getExpertSuccess,
  getExpertFailure,
  exitFromExpert,
  setExpertFollowUpStep,
  resetExpertFollowUpStep
};
