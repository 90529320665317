import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Button } from "reactstrap";

import styles from "./EmptyResultStarred.module.scss";

const EmptyResultStarred: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation("starredEmptyResult");

  const handleGoBack = (): void => {
    history.goBack();
  };

  return (
    <div className={styles.noResultContainer}>
      <div className={styles.message}>
        <div className={styles.stars}>
          <i className="ciq-stars">
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
          </i>
        </div>
        <div className={styles.title}>{t("title")}</div>
        <div className={styles.firstLine}>{t("firstLine")}</div>
        <div className={styles.secondLine}>{t("secondLine")}</div>
        <div className={styles.buttonContainer}>
          <Button
            type="submit"
            size="md"
            color="primary"
            className="btn-bold"
            onClick={(e): void => {
              e.preventDefault();
              e.stopPropagation();
              handleGoBack();
            }}
          >
            {t("backToSearch")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EmptyResultStarred;
