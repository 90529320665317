import { LocationDescriptorObject } from "history";
import React from "react";
import { useTranslation } from "react-i18next";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { LinkBoxButton } from "../../BoxButton/LinkBoxButton";
import { StatButtonProps } from "./StatButtonProps";

interface ReferralsButtonProps extends Omit<StatButtonProps, "to"> {
  to: LocationDescriptorObject;
}

export const ReviewAllReferralsButton: React.FC<ReferralsButtonProps> = ({
  to,
  itemCount,
  ...props
}) => {
  const { t } = useTranslation("referralsButton");

  return (
    <LinkBoxButton
      text={itemCount > 0 ? t("review") : t("view")}
      icon={{
        name: "groups",
        color: ARBOLUS_COLORS.bColorBaseWhite,
        background:
          itemCount > 0
            ? ARBOLUS_COLORS.bColorBaseOrange
            : ARBOLUS_COLORS.bColorBasePurple
      }}
      subTitle={
        itemCount > 0
          ? t("expertCount", {
              count: itemCount
            })
          : t("viewAllExperts")
      }
      to={to}
      chevronColor={ARBOLUS_COLORS.bColorBasePurple}
      {...props}
    />
  );
};
