import React from "react";

import { STATUS_COLORS } from "@arbolus-technologies/models/common";

import { ColorCircle } from "./ColorCircle";

interface ProjectStatusCircleProps {
  status: "Active" | "Archive" | "Draft";
  size?: number;
}

export const ProjectStatusCircle: React.FC<ProjectStatusCircleProps> = ({
  status,
  size = 8
}) => {
  const colorValue =
    STATUS_COLORS[status.toLowerCase() as keyof typeof STATUS_COLORS];

  return <ColorCircle color={colorValue} size={size} />;
};
