import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  ANSWER_PREFERENCES_TYPE,
  ANSWER_TYPE,
  CanopyQuestionMultiChoice,
  CreateEditQuestionFields
} from "@arbolus-technologies/models/canopy";
import { CanopyBuilderSelector } from "@arbolus-technologies/stores/canopy-builder";
import { CustomSelectLabelController } from "@arbolus-technologies/ui/components";

import { CanopyCreateFormValues } from "../../../../../../helpers/CanopyInitialFormValues";
import { useCanopyContext } from "../../../../../../helpers/useCanopyContext";
import { MultiChoiceTypeExact } from "./MultiChoiceTypeExact";
import { MultiChoiceTypeRange } from "./MultiChoiceTypeRange";

import styles from "./MultiChoiceTypes.module.scss";

export const MultiChoiceTypes: React.FC = () => {
  const { t } = useTranslation("canopyBuilder");
  const { isEditMode, isCanopyActive } = useCanopyContext();

  const questionSelected = useSelector(
    CanopyBuilderSelector.questionSelected()
  );

  const {
    control,
    formState: { errors },
    watch,
    reset,
    getValues,
    trigger
  } = useFormContext<CanopyQuestionMultiChoice>();

  const formValues = getValues();
  const [
    answerPreferencesType,
    answerType,
    answerPreferencesNumberInitialRange,
    answerPreferencesNumberFinalRange
  ] = watch([
    CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE,
    CreateEditQuestionFields.ANSWER_TYPE,
    CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_INITIAL_RANGE,
    CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_FINAL_RANGE
  ]);

  useEffect(() => {
    if (
      answerPreferencesNumberInitialRange ||
      answerPreferencesNumberFinalRange
    ) {
      trigger([
        CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_INITIAL_RANGE,
        CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_FINAL_RANGE
      ]);
    }
  }, [
    answerPreferencesNumberInitialRange,
    answerPreferencesNumberFinalRange,
    trigger
  ]);

  const answerPreferencesValues = () => [
    {
      value: ANSWER_PREFERENCES_TYPE.EXACT,
      label: t("exact")
    },
    {
      value: ANSWER_PREFERENCES_TYPE.UNLIMITED,
      label: t("unlimited")
    },
    {
      value: ANSWER_PREFERENCES_TYPE.RANGE,
      label: t("range")
    }
  ];

  const answerPreferencesLabel = answerPreferencesValues().find(
    (preference) => preference.value === answerPreferencesType
  );

  // Reset form values only when answer preference type changes
  useEffect(() => {
    if (answerType === ANSWER_TYPE.MULTIPLE_CHOICE) {
      if (isEditMode) {
        reset({
          ...formValues,
          [CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_EXACT]:
            questionSelected?.exactNumber,
          [CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_INITIAL_RANGE]:
            questionSelected?.answersRange?.minimum,
          [CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_FINAL_RANGE]:
            questionSelected?.answersRange?.maximum
        });
      } else {
        reset({
          ...CanopyCreateFormValues(formValues.order),
          [CreateEditQuestionFields.QUESTION]: formValues.question,
          [CreateEditQuestionFields.DETAILS]: formValues.details,
          [CreateEditQuestionFields.ANSWER_TYPE]: formValues.answerType,
          [CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE]:
            formValues.answerPreferencesType,
          [CreateEditQuestionFields.ANSWER_CHOICES]: formValues.answerChoices
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerPreferencesType]);

  const multichoiceMessage = {
    [ANSWER_PREFERENCES_TYPE.EXACT]: t("expertCanChooseExact"),
    [ANSWER_PREFERENCES_TYPE.UNLIMITED]: t("expertCanChooseUnlimited"),
    [ANSWER_PREFERENCES_TYPE.RANGE]: t("expertCanChooseRange")
  };

  const renderMultiChoiceTypeForm = {
    [ANSWER_PREFERENCES_TYPE.UNLIMITED]: null,
    [ANSWER_PREFERENCES_TYPE.EXACT]: <MultiChoiceTypeExact />,
    [ANSWER_PREFERENCES_TYPE.RANGE]: <MultiChoiceTypeRange />
  };

  return (
    <div className={styles.container}>
      <div className={styles.inputsContainer}>
        <div className={styles.selectContainer}>
          <CustomSelectLabelController
            name={CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE}
            control={control}
            options={answerPreferencesValues()}
            noOptionsMessage={t("noOptionsAvailable")}
            placeholder={t("selectPlaceholder")}
            error={
              errors[CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE]?.message
            }
            customLabel={answerPreferencesLabel?.label}
            disabled={isCanopyActive}
          />
        </div>
        {renderMultiChoiceTypeForm[answerPreferencesType]}
      </div>
      <p className={styles.multichoiceMessage}>
        {multichoiceMessage[answerPreferencesType]}
      </p>
    </div>
  );
};
