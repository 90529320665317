import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "reactstrap";

import {
  DefaultToasterService,
  ToasterService
} from "@arbolus-technologies/api";
import {
  DocumentType,
  MAXIMUM_IMAGE_UPLOAD_SIZE
} from "@arbolus-technologies/models/documents";
import { Loader } from "@arbolus-technologies/ui/components";

import { isSupportedFileType } from "../../documentUtils";

import styles from "./CustomAvatarSelect.module.scss";

interface CustomAvatarSelectProps {
  profileImageUrl?: string;
  onImageSelected: (imageFile: File) => void;
  isLoading?: boolean;
  notificationService?: ToasterService;
}

export const CustomAvatarSelect: React.FC<CustomAvatarSelectProps> = ({
  onImageSelected,
  profileImageUrl,
  isLoading = false,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("customAvatarSelect");

  const [imageUrl, setImageUrl] = useState<string>(profileImageUrl || "");

  const handleImageSelected = (fileList: FileList | null): void => {
    if (fileList !== null) {
      const image = fileList[0];
      const isSupported = isSupportedFileType(image, [DocumentType.IMAGE]);

      if (!isSupported) {
        notificationService.showError(t("unsupportedFile"));
        return;
      }

      const imageSize = Math.round(image.size / 1024);
      if (imageSize >= MAXIMUM_IMAGE_UPLOAD_SIZE) {
        notificationService.showError(t("maximum5MB"));
        return;
      }

      onImageSelected(image);
      setImageUrl(URL.createObjectURL(image));
    }
  };

  function handleImageOnChange(target: EventTarget & HTMLInputElement): void {
    if (target.files && target.files?.length > 0) {
      handleImageSelected(target.files);
    }

    // eslint-disable-next-line no-param-reassign
    target.value = "";
  }

  return (
    <div className={clsx(styles.formImageUpload, "form-image-upload")}>
      {imageUrl ? (
        <>
          {isLoading && <Loader />}
          <div className={styles.imageContainer}>
            <img src={imageUrl} alt="Profile Avatar" />
          </div>
        </>
      ) : (
        <div className={styles.imgFallbackIcon}>
          <span className="ciq-icon ciq-landscape-icon" />
        </div>
      )}
      {!isLoading && (
        <span className="ciq-icon ciq-plus">
          <Input
            type="file"
            accept=".jpg, .png, .jpeg|image/*"
            onChange={({ target }): void => handleImageOnChange(target)}
          />
        </span>
      )}
    </div>
  );
};
