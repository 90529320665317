import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { SharedRoutes } from "@arbolus-technologies/api";
import { SelectOption } from "@arbolus-technologies/models/common";

import { BaseEvent } from "../../../../../models/event";
import { EventService } from "../../../../../services";

interface EventItemProps {
  isRealEvent: boolean;
  isLastIndex: boolean;
  isNextEventSameMonth: boolean;
  isNextEventSameDate: boolean;
  event: BaseEvent;
  timeZone?: SelectOption;
}

const EventItem = ({
  event,
  isNextEventSameDate,
  isNextEventSameMonth,
  isLastIndex,
  timeZone
}: EventItemProps): JSX.Element => {
  const { endTime, id, startTime, title, meetingPlatform, location } = event;

  const history = useHistory();
  const { t } = useTranslation("calenderPage");

  const timeZoneLiteral = timeZone?.customLabel || "";

  return (
    <div
      className={clsx("event-item", {
        "no-border": isLastIndex || !isNextEventSameMonth,
        "margin-bottom": !isNextEventSameDate
      })}
      onClick={(): void => history.replace(SharedRoutes.EVENT_PANEL_ROUTE(id))}
    >
      <div className="left-container">
        <div className="top-container">
          {EventService.generateEventTime(startTime, endTime)}
        </div>
        <div className="bottom-container">{timeZoneLiteral}</div>
      </div>
      <div className="middle-container">
        <h4>{title}</h4>
        <p>{meetingPlatform ? t("conferenceCall") : location}</p>
      </div>
    </div>
  );
};

export default EventItem;
