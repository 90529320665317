import { Icon } from "arbolus-ui-components";
import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

interface TiptapEditorToolbarButtonProps {
  isActive: boolean;
  icon: string;
  onClick: () => void;
}

// Custom button for Toolbar, don't use anywhere else
export const TiptapEditorToolbarButton: React.FC<
  TiptapEditorToolbarButtonProps
> = ({ icon, onClick, isActive }) => (
  <Icon
    name={icon}
    onClick={onClick}
    fontSize="16px"
    color={
      isActive
        ? ARBOLUS_COLORS.bColorBaseDark
        : ARBOLUS_COLORS.bColorSecondaryGreyDark
    }
  />
);
