import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "arbolus-ui-components";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Label } from "reactstrap";

import {
  DefaultToasterService,
  ExpertService,
  ToasterService
} from "@arbolus-technologies/api";
import {
  DNC_CATEGORY_ENUM,
  DO_NOT_CONTACT_STATUS,
  SelectOption
} from "@arbolus-technologies/models/common";
import {
  CustomSelect,
  InputController,
  RADIO_VARIANT,
  RadioChoicesController,
  RadioOption,
  WordCounter
} from "@arbolus-technologies/ui/components";

import { DNC_MAX_NOTE_LENGTH } from "../../../constants";
import { DNCFormEnum } from "../../../enums";
import { DNCFormValues, DNCSchema } from "./DNCSchema";

import styles from "./DNCSection.module.scss";

const radioOptions: RadioOption<DO_NOT_CONTACT_STATUS>[] = [
  {
    title: i18next.t("dncSection:freeToContact"),
    value: DO_NOT_CONTACT_STATUS.OK,
    subtitle: i18next.t("dncSection:noIssues")
  },
  {
    title: i18next.t("dncSection:proceedWithCaution"),
    value: DO_NOT_CONTACT_STATUS.CAUTION,
    subtitle: i18next.t("dncSection:avoidContacting")
  },
  {
    title: i18next.t("dncSection:doNotContact"),
    value: DO_NOT_CONTACT_STATUS.DNC,
    subtitle: i18next.t("dncSection:forbidden")
  }
];

const dncCategories = Object.values(DNC_CATEGORY_ENUM)
  .filter((category) => category !== DNC_CATEGORY_ENUM.COMPANY_DNC)
  .map(
    (category): SelectOption => ({
      value: category,
      label: i18next.t(`dncCategories:${category}`)
    })
  );

interface DNCSectionProps {
  doNotContactStatus: DO_NOT_CONTACT_STATUS;
  doNotContactStatusCategory?: DNC_CATEGORY_ENUM | null;
  onUpdateDnc: (
    dncStatus: DO_NOT_CONTACT_STATUS,
    dncCategory?: DNC_CATEGORY_ENUM | null,
    dncDescription?: string
  ) => void;
  expertService?: typeof ExpertService;
  notificationService?: ToasterService;
}

export const DNCSection: React.FC<DNCSectionProps> = ({
  doNotContactStatus,
  doNotContactStatusCategory,
  onUpdateDnc,
  expertService = ExpertService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("dncSection");
  const { expertId } = useParams<{ expertId: string }>();

  const [isSaving, setIsSaving] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty, isValid, errors, dirtyFields },
    setValue,
    getValues,
    trigger
  } = useForm<DNCFormValues>({
    resolver: yupResolver(DNCSchema),
    mode: "all",
    defaultValues: {
      dncStatus: doNotContactStatus,
      dncCategory: doNotContactStatusCategory,
      dncNote: ""
    }
  });

  const expertDncStatus = watch("dncStatus");
  const isExpertOK = expertDncStatus === DO_NOT_CONTACT_STATUS.OK;

  useEffect(() => {
    if (expertDncStatus === DO_NOT_CONTACT_STATUS.OK) {
      // @ts-ignore
      setValue(DNCFormEnum.DNC_CATEGORY, null);
    } else {
      trigger(DNCFormEnum.DNC_NOTE);
    }
    // @ts-ignore
    setValue(DNCFormEnum.DNC_NOTE, "");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expertDncStatus]);

  const handleStatusChange = (status: string) => {
    setValue(
      DNCFormEnum.DNC_CATEGORY,
      // @ts-ignore
      status as DNC_CATEGORY_ENUM,
      { shouldDirty: true }
    );
    trigger(DNCFormEnum.DNC_CATEGORY);
  };

  const onSubmit = (data: DNCFormValues) => {
    setIsSaving(true);
    expertService
      .updateExpertsSettings(
        expertId,
        data.dncStatus,
        data.dncCategory,
        data.dncNote
      )
      .subscribe(
        () => {
          notificationService.showSuccess(
            t(dirtyFields.dncStatus ? "dncUpdateSuccess" : "dncNoteUpdate")
          );
          onUpdateDnc(data.dncStatus, data.dncCategory, data.dncNote);
          reset({
            [DNCFormEnum.DNC_STATUS]: data.dncStatus,
            [DNCFormEnum.DNC_CATEGORY]: data.dncCategory
          });
          setIsSaving(false);
        },
        () => {
          notificationService.showError(t("dncUpdateFailed"));
          setIsSaving(false);
        }
      );
  };

  return (
    <div className={styles.dncContainer}>
      <RadioChoicesController
        name={DNCFormEnum.DNC_STATUS}
        options={radioOptions}
        variant={RADIO_VARIANT.MAX_CONTENT}
        control={control}
      />
      <div className={styles.inputContainer}>
        <Label className={styles.label}>{t("reason")}</Label>
        <CustomSelect
          options={dncCategories}
          noOptionsMessage={""}
          displayIcon
          placeholder={t("reasonPlaceholder")}
          disabled={isExpertOK}
          clearValue={isExpertOK}
          defaultValue={dncCategories.find(
            (category) => category.value === getValues(DNCFormEnum.DNC_CATEGORY)
          )}
          onSelectChange={handleStatusChange}
        />
      </div>
      <div className={styles.inputContainer}>
        <Label className={styles.label}>{t("note")}</Label>
        <InputController
          name={DNCFormEnum.DNC_NOTE}
          error={errors.dncNote}
          classnames={styles.input}
          control={control}
          placeholder={t("notePlaceholder")}
          type="textarea"
          size="big"
        />
        <WordCounter
          maxLength={DNC_MAX_NOTE_LENGTH}
          textLength={watch("dncNote")?.length ?? 0}
        />
      </div>
      <div className={styles.btnContainer}>
        <Button
          text={t("submit")}
          onClick={handleSubmit(onSubmit)}
          disabled={!isDirty || !isValid || isSaving}
        />
      </div>
    </div>
  );
};
