import { Icon } from "arbolus-ui-components";
import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { ConditionalLink } from "@arbolus-technologies/ui/components";

import styles from "./MenuButton.module.scss";

interface MenuButtonProps {
  text: string;
  isActive?: boolean;
  icon: string;
  handleButtonClick: () => void;
  to?: string;
  hasNotifications?: boolean;
}

export const MenuButton: React.FC<MenuButtonProps> = ({
  text,
  icon,
  isActive,
  handleButtonClick,
  to,
  hasNotifications
}) => (
  <ConditionalLink to={to}>
    <div
      className={styles.container}
      style={{
        background: isActive ? ARBOLUS_COLORS.bColorBaseWhite : "transparent",
        borderRadius: "8px"
      }}
    >
      {hasNotifications && <div className={styles.notificationCount} />}
      <div className={styles.buttonContainer} onClick={handleButtonClick}>
        <Icon
          name={icon}
          fontSize="24px"
          color={
            isActive
              ? ARBOLUS_COLORS.bColorBasePurple
              : ARBOLUS_COLORS.bColorSecondaryGreyDark
          }
        />
        <span
          className={styles.text}
          style={{
            color: isActive
              ? ARBOLUS_COLORS.bColorBasePurple
              : ARBOLUS_COLORS.bColorSecondaryGreyDark
          }}
        >
          {text}
        </span>
      </div>
    </div>
  </ConditionalLink>
);
