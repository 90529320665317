/* eslint-disable dot-notation */
import clsx from "clsx";
import React from "react";

interface CompliancePillProps {
  text: string;
  isMatch?: boolean;
  isPreferred?: boolean;
  isComplianceStatus?: boolean;
}

const CompliancePill: React.FC<CompliancePillProps> = ({
  text,
  isMatch,
  isPreferred,
  isComplianceStatus
}: CompliancePillProps) => (
  <div
    className={clsx("compliance-pill", {
      "answer-match": isMatch,
      "preferred-pill": isPreferred,
      "compliance-status-pill": isComplianceStatus
    })}
  >
    <p>{text}</p>
  </div>
);

export default CompliancePill;
