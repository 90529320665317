/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */
import i18next from "i18next";
import * as Yup from "yup";

import {
  AuthConstraints,
  DIGIT_REGEX_REQUIRED,
  LOWER_UPPERCASE_REGEX,
  SPECIAL_CHARACTERS_REGEX,
  UserConstraints
} from "../../../../../constants/validation";

export const PasswordValidationSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .min(
      AuthConstraints.MIN_PASSWORD_LENGTH,
      i18next.t("userProfilePage:currentPasswordInvalidError")
    )
    .required(i18next.t("userProfilePage:currentPasswordRequiredError")),
  newPassword: Yup.string()
    .required(i18next.t("userProfilePage:newPasswordRequiredError"))
    .matches(LOWER_UPPERCASE_REGEX, " ")
    .matches(SPECIAL_CHARACTERS_REGEX, " ")
    .matches(DIGIT_REGEX_REQUIRED, " ")
    .min(UserConstraints.MIN_PASSWORD_LENGTH, " ") // Note: Empty string will replace by yup, use a space for empty error message
    .test(
      "not-equal-check",
      i18next.t("userProfilePage:newPasswordMatchWithCurrent"),
      function (val: any) {
        const { currentPassword } = this.parent;
        return currentPassword !== val;
      }
    ),
  confirmNewPassword: Yup.string()
    .required(i18next.t("userProfilePage:confirmPasswordRequiredError"))
    .test(
      "equal-check",
      i18next.t("userProfilePage:confirmPasswordNotMatchNewPassword"),
      function (val: any) {
        const { newPassword } = this.parent;
        return newPassword === val;
      }
    )
});
