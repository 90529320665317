import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { Avatar } from "@arbolus-technologies/ui/components";
import { fullName } from "@arbolus-technologies/utils";

import styles from "./Expert.module.scss";

interface ExpertProps {
  canopyId: string;
  expert: {
    expertId: string;
    firstName: string;
    lastName: string;
    profileImageUrl: string;
  };
  onClick: (canopyId: string) => void;
  disabled?: boolean;
  isSelected?: boolean;
  status: "InReview" | "Rejected" | "Complete" | "Incomplete";
  isAdmin: boolean | null;
  hasComplianceCheck: boolean;
}

export const Expert: React.FC<ExpertProps> = ({
  canopyId,
  expert,
  onClick,
  disabled,
  isSelected,
  status,
  isAdmin,
  hasComplianceCheck
}) => {
  const { t } = useTranslation("canopyExpertsList");
  return (
    <div className={styles.expertContainer}>
      <div
        className={clsx(
          disabled ? styles.containerDisabled : styles.container,
          { [styles.selected]: isSelected }
        )}
        onClick={() => onClick(canopyId)}
      >
        <div className={styles.avatar}>
          <Avatar
            avatar={{
              name: fullName(expert.firstName, expert.lastName),
              imageUrl: expert.profileImageUrl
            }}
          />
        </div>
        <div className={styles.expert}>
          <span
            className={clsx(styles.expertName, {
              [styles.selected]: isSelected
            })}
          >
            {expert.firstName} {expert.lastName}
          </span>
        </div>
        {isAdmin && hasComplianceCheck && (
          <div className={styles.option}>
            {status === "InReview" && (
              <div className={styles.statusContainer}>
                <div className={styles.iconContainer}>
                  <i
                    className={clsx("ciq-circle-orange", styles.iconDimension)}
                  />
                </div>
                <span className={styles.reviewStatus}>
                  {t("pendingReview")}
                </span>
              </div>
            )}
            {status === "Rejected" && (
              <div className={styles.statusContainer}>
                <div className={styles.iconContainer}>
                  <i className={clsx("ciq-circle-red", styles.iconDimension)} />
                </div>
                <span className={styles.reviewStatus}>{t("rejected")}</span>
              </div>
            )}
            {status === "Complete" && (
              <div className={styles.statusContainer}>
                <div className={styles.iconContainer}>
                  <i
                    className={clsx("ciq-circle-green", styles.iconDimension)}
                  />
                </div>
                <span className={styles.reviewStatus}>{t("sentToClient")}</span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
