import { ClipboardService } from "@arbolus-technologies/api";
import { PublicRoutes } from "@arbolus-technologies/routes";
import { Button, Card, Flex, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const { Text, Title } = Typography;

interface LandingLinkCardProps {
  surveyId?: string;
  isSurveyClosed?: boolean;
  clipboardService?: typeof ClipboardService;
}

export const LandingLinkCard: React.FC<LandingLinkCardProps> = ({
  surveyId,
  isSurveyClosed,
  clipboardService = ClipboardService
}) => {
  const { t } = useTranslation("landingLinkCard");

  const landingPageLink = surveyId
    ? `${process.env.NX_PUBLIC_BASE_URL}${PublicRoutes.SURVEY_ROUTE(surveyId)}`
    : "";

  const isDisabled = !landingPageLink || isSurveyClosed;

  const handleCopyLink = () => {
    landingPageLink && clipboardService.copyToClipboard(landingPageLink);
  };

  return (
    <Card>
      <Flex vertical gap={24}>
        <Flex vertical gap={16}>
          <Text strong>{t("landingPageLink")}</Text>
          <Text>{t("landingPageLinkDesc")}</Text>
        </Flex>
        <Title level={3} disabled={isDisabled}>
          {landingPageLink ?? t("surveyLinkPlaceholder")}
        </Title>
        <Flex justify="flex-end">
          <Button type="primary" disabled={isDisabled} onClick={handleCopyLink}>
            {t("copySurveyLink")}
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
};
