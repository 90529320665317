import i18next from "i18next";
import { Epic } from "redux-observable";
import { of } from "rxjs";
import { catchError, filter, flatMap, map, switchMap } from "rxjs/operators";
import { isOfType } from "typesafe-actions";

import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";

import {
  ADD_NEW_ANGLE,
  EDIT_ANGLE,
  EDIT_ANGLE_QUESTIONS_ADMIN,
  GET_ANGLES_CARDS,
  GET_ANGLES_LINK,
  GET_ANGLE_QUESTIONS,
  GET_SINGLE_ANGLE
} from "../actions/ProjectAnglesActionTypes";
import { ProjectAnglesStoreActions } from "../actions/ProjectAnglesActions";
import {
  ProjectAnglesAppState,
  ProjectAnglesStoreAction,
  ProjectAnglesStoreDependencies
} from "../definitions";

const getAnglesCards: Epic<
  ProjectAnglesStoreAction,
  ProjectAnglesStoreAction,
  ProjectAnglesAppState,
  ProjectAnglesStoreDependencies
> = (action$, _, { anglesService, notificationService }) =>
  action$.pipe(
    filter(isOfType(GET_ANGLES_CARDS)),
    switchMap(({ payload: { projectId } }) =>
      anglesService.getAnglesCards(projectId).pipe(
        map((angles) =>
          ProjectAnglesStoreActions.getAnglesCardsSuccess(angles.items)
        ),
        catchError((error) => {
          notificationService.showError(
            i18next.t("restService:somethingWrong")
          );
          return of(ProjectAnglesStoreActions.getAnglesCardsFailure(error));
        })
      )
    )
  );

const getAngleLinks: Epic<
  ProjectAnglesStoreAction,
  ProjectAnglesStoreAction,
  ProjectAnglesAppState,
  ProjectAnglesStoreDependencies
> = (action$, _, { anglesService, notificationService }) =>
  action$.pipe(
    filter(isOfType(GET_ANGLES_LINK)),
    switchMap(({ payload: { projectId, angleId } }) =>
      anglesService.getAngleLinks(projectId).pipe(
        map((res) =>
          ProjectAnglesStoreActions.getAnglesLinkSuccess(res.items, angleId)
        ),
        catchError((error) => {
          notificationService.showError(
            i18next.t("restService:somethingWrong")
          );
          return of(ProjectAnglesStoreActions.getAnglesLinkFailure(error));
        })
      )
    )
  );

const getAngle: Epic<
  ProjectAnglesStoreAction,
  ProjectAnglesStoreAction,
  ProjectAnglesAppState,
  ProjectAnglesStoreDependencies
> = (action$, _, { anglesService, notificationService }) =>
  action$.pipe(
    filter(isOfType(GET_SINGLE_ANGLE)),
    switchMap(({ payload: { angleId } }) =>
      anglesService.getAngle(angleId).pipe(
        flatMap((selectedAngle) => {
          if (selectedAngle.hasScreeningQuestions) {
            return of(
              ProjectAnglesStoreActions.getAngleQuestions(angleId),
              ProjectAnglesStoreActions.getSingleAngleSuccess(selectedAngle),
              ProjectAnglesStoreActions.setFormCreateEditAngleDisabled()
            );
          }
          return of(
            ProjectAnglesStoreActions.getSingleAngleSuccess(selectedAngle),
            ProjectAnglesStoreActions.setFormCreateEditAngleDisabled()
          );
        }),
        catchError((error) => {
          notificationService.showError(
            i18next.t("restService:somethingWrong")
          );
          return of(ProjectAnglesStoreActions.getSingleAngleFailure(error));
        })
      )
    )
  );

const createNewAngle: Epic<
  ProjectAnglesStoreAction,
  ProjectAnglesStoreAction,
  ProjectAnglesAppState,
  ProjectAnglesStoreDependencies
> = (action$, _, { anglesService, notificationService }) =>
  action$.pipe(
    filter(isOfType(ADD_NEW_ANGLE)),
    switchMap(({ payload: { angleData, projectId } }) =>
      anglesService.addAngle(angleData).pipe(
        flatMap(() => {
          notificationService.showSuccess(
            i18next.t("viewEditAngle:angleCreatedSuccess")
          );
          return of(
            ProjectAnglesStoreActions.addNewAngleSuccess(),
            ProjectAnglesStoreActions.getAnglesCards(projectId),
            PanelStoreActions.closePanel(PanelId.CreateAngle)
          );
        }),
        catchError((error) => {
          notificationService.showError(
            i18next.t("restService:somethingWrong")
          );
          return of(ProjectAnglesStoreActions.addNewAngleFailure(error));
        })
      )
    )
  );

const updateAngle: Epic<
  ProjectAnglesStoreAction,
  ProjectAnglesStoreAction,
  ProjectAnglesAppState,
  ProjectAnglesStoreDependencies
> = (action$, _, { anglesService, notificationService }) =>
  action$.pipe(
    filter(isOfType(EDIT_ANGLE)),
    switchMap(({ payload: { angleId, angleData } }) =>
      anglesService.editAngle(angleId, angleData).pipe(
        flatMap((angle) => {
          notificationService.showSuccess(
            i18next.t("viewEditAngle:angleEditedSuccess")
          );
          return of(
            ProjectAnglesStoreActions.editAngleSuccess(angle),
            ProjectAnglesStoreActions.getAnglesCards(angleData.projectId)
          );
        }),
        catchError((error) => {
          notificationService.showError(error.message);
          return of(ProjectAnglesStoreActions.editAngleFailure(error));
        })
      )
    )
  );

const getAnglesQuestions: Epic<
  ProjectAnglesStoreAction,
  ProjectAnglesStoreAction,
  ProjectAnglesAppState,
  ProjectAnglesStoreDependencies
> = (action$, _, { anglesService, notificationService }) =>
  action$.pipe(
    filter(isOfType(GET_ANGLE_QUESTIONS)),
    switchMap(({ payload: { angleId } }) =>
      anglesService.getAngleQuestions(angleId).pipe(
        flatMap((response) =>
          of(ProjectAnglesStoreActions.getAngleQuestionsSuccess(response))
        ),
        catchError((error) => {
          notificationService.showError(
            i18next.t("restService:somethingWrong")
          );
          return of(ProjectAnglesStoreActions.getAngleQuestionsFailure(error));
        })
      )
    )
  );

const editAnglesQuestionsAdmin: Epic<
  ProjectAnglesStoreAction,
  ProjectAnglesStoreAction,
  ProjectAnglesAppState,
  ProjectAnglesStoreDependencies
> = (action$, _, { adminService, notificationService }) =>
  action$.pipe(
    filter(isOfType(EDIT_ANGLE_QUESTIONS_ADMIN)),
    switchMap(({ payload: { angleId, questions } }) =>
      adminService.editAngleQuestionsAdmin(angleId, questions).pipe(
        flatMap((response) => {
          notificationService.showSuccess(
            i18next.t("viewEditAngle:questionsEditedSuccess")
          );
          return of(
            ProjectAnglesStoreActions.setFormCreateEditAngleDisabled(),
            ProjectAnglesStoreActions.editAngleQuestionsAdminSuccess(response)
          );
        }),
        catchError((error) => {
          notificationService.showError(
            i18next.t("restService:somethingWrong")
          );
          return of(
            ProjectAnglesStoreActions.editAngleQuestionsAdminFailure(error)
          );
        })
      )
    )
  );

export const ProjectAnglesEpics = [
  getAnglesCards,
  getAngle,
  getAnglesQuestions,
  updateAngle,
  getAngleLinks,
  createNewAngle,
  editAnglesQuestionsAdmin
];
