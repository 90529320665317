import { Button, Flex } from "antd";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { CanopyQuestion } from "@arbolus-technologies/api";
import {
  ANSWER_TYPE,
  EXPERT_CANOPY_STATUS,
  QuestionParamUrlTypes,
  VIDEO_MODE_ENUM
} from "@arbolus-technologies/models/canopy";
import {
  CANOPY_DETAILS_PAGE_ROUTE,
  EXPERT_CANOPY
} from "@arbolus-technologies/routes";
import {
  CanopyExpertSelector,
  CanopyExpertStoreActions
} from "@arbolus-technologies/stores/canopy-expert";
import {
  useCheckCamera,
  useCheckMicrophone
} from "@arbolus-technologies/utils";

import { QUESTION_PAGE_ROUTES } from "../../helpers/routes";
import { AnswersProgress } from "../AnswersProgress/AnswersProgress";
import { CameraMicrophoneWarning } from "../CameraMicrophoneWarning/CameraMicrophoneWarning";
import { ConfirmationModals } from "../ConfirmationModals/ConfirmationModals";
import { Question } from "../Question/Question";
import { QuestionsTimer } from "../QuestionsTimer/QuestionsTimer";
import { SubmitAnswers } from "../SubmitAnswers/SubmitAnswers";

import canopyBrief from "../../../assets/canopyBrief.png";

import styles from "./QuestionsView.module.scss";

interface QuestionsViewProps {
  isDnc: boolean;
}

export const QuestionsView: React.FC<QuestionsViewProps> = ({ isDnc }) => {
  const { t } = useTranslation("canopyExpert");
  const dispatch = useDispatch();
  const history = useHistory();
  const { questionId } = useParams<QuestionParamUrlTypes>();
  const { getCameraStatus, isCameraAvailable } = useCheckCamera();
  const { getMicrophoneStatus, isMicrophoneAvailable } = useCheckMicrophone();

  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isGoBackModalOpen, setIsGoBackModalOpen] = useState(false);
  const [questionToBeChanged, setQuestionToBeChanged] =
    useState<CanopyQuestion | null>(null);
  const [showCameraMicrophoneWarning, setShowCameraMicrophoneWarning] =
    useState<boolean>(false);

  const expertCanopy = useSelector(CanopyExpertSelector.canopySelector());
  const isRecording = useSelector(CanopyExpertSelector.isVideoRecording());
  const isVideoPlaying = useSelector(CanopyExpertSelector.isVideoPlaying());
  const videoURL = useSelector(CanopyExpertSelector.canopyVideoURL());
  const isVideoUploaded = useSelector(CanopyExpertSelector.isVideoUploaded());
  const videoMode = useSelector(CanopyExpertSelector.videoMode());
  const expertQuestionList = useSelector(
    CanopyExpertSelector.questionsSelector()
  );
  const isExpertsQuestionsEnabled = useSelector(
    CanopyExpertSelector.isExpertsQuestionsEnabled()
  );

  const { canopyExpertStatus } = expertCanopy;
  const isCanopyCompleted =
    canopyExpertStatus === EXPERT_CANOPY_STATUS.COMPLETE ||
    canopyExpertStatus === EXPERT_CANOPY_STATUS.REJECTED;

  const isCanopyReadyToBeSubmitted =
    !isDnc &&
    expertQuestionList.length > 0 &&
    expertQuestionList.every((question) => question.isAnswered) &&
    !isCanopyCompleted;

  const hasVideoQuestion = !!expertQuestionList.find(
    (question) => question.type === ANSWER_TYPE.VIDEO
  );

  const showWarningModal =
    videoMode === VIDEO_MODE_ENUM.PLAY && videoURL && !isVideoUploaded;

  useEffect(() => {
    getMicrophoneStatus();
    getCameraStatus();

    if (isMobile) window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowCameraMicrophoneWarning(
      !isCanopyCompleted &&
        hasVideoQuestion &&
        (!isMicrophoneAvailable || !isCameraAvailable)
    );
  }, [
    isCameraAvailable,
    isCanopyCompleted,
    hasVideoQuestion,
    isMicrophoneAvailable
  ]);

  // This reset is executed on questions view initialization
  // so we can avoid weird bugs on native browser navigation (mobile and desktop)
  useEffect(() => {
    dispatch(CanopyExpertStoreActions.resetMobile());
    dispatch(CanopyExpertStoreActions.showRightComponent(false));
    dispatch(CanopyExpertStoreActions.setRecordingVideo(false));
    dispatch(
      CanopyExpertStoreActions.setCanopyVideo(null, null, false, false, 0, null)
    );
  }, [dispatch]);

  const handleClickQuestion = (question: CanopyQuestion) => {
    setQuestionToBeChanged(question);
    if (showWarningModal) {
      setIsQuestionModalOpen(true);
    } else {
      handleReset();
      history.push(
        QUESTION_PAGE_ROUTES[question.type](expertCanopy.id, question.id)
      );
    }
  };

  const handleConfirmQuestion = () => {
    if (questionToBeChanged) {
      setIsQuestionModalOpen(false);
      handleReset();

      history.push(
        QUESTION_PAGE_ROUTES[questionToBeChanged.type](
          expertCanopy.id,
          questionToBeChanged.id
        )
      );
    }
  };

  const handleConfirmDetails = () => {
    handleReset();
    setIsDetailsModalOpen(false);
    history.push(CANOPY_DETAILS_PAGE_ROUTE(expertCanopy.id));
  };

  const handleConfirmGoBack = () => {
    history.push(EXPERT_CANOPY);
    setIsGoBackModalOpen(false);
  };

  const handleClickDetails = () => {
    if (showWarningModal) {
      setIsDetailsModalOpen(true);
    } else {
      handleReset();
      history.push(CANOPY_DETAILS_PAGE_ROUTE(expertCanopy.id));
    }
  };

  const handleReset = () => {
    dispatch(CanopyExpertStoreActions.resetCanopyCamera());
    dispatch(CanopyExpertStoreActions.showRightComponent(true));
    dispatch(CanopyExpertStoreActions.recordQuestion(null));
  };

  const handleBack = () => {
    if (showWarningModal) {
      setIsGoBackModalOpen(true);
    } else {
      history.push(EXPERT_CANOPY);
    }
  };

  return (
    <>
      <ConfirmationModals
        questionModal={{
          handleConfirmQuestion,
          isQuestionModalOpen,
          setIsQuestionModalOpen
        }}
        backModal={{
          handleConfirmGoBack,
          isGoBackModalOpen,
          setIsGoBackModalOpen
        }}
        detailsModal={{
          handleConfirmDetails,
          isDetailsModalOpen,
          setIsDetailsModalOpen
        }}
      />
      <div className={styles.container}>
        <Flex justify="flex-end">
          <Button
            type="link"
            icon={<AntDIcon name="chevronLeft" />}
            onClick={handleBack}
          >
            {t("back")}
          </Button>
        </Flex>
        <div className={styles.title} title={expertCanopy.title}>
          {expertCanopy.title}
        </div>
        {isCanopyReadyToBeSubmitted && (
          <SubmitAnswers
            isInReview={canopyExpertStatus === EXPERT_CANOPY_STATUS.IN_REVIEW}
          />
        )}
        {showCameraMicrophoneWarning && (
          <CameraMicrophoneWarning
            handleHideCameraMicrophoneWarning={() =>
              setShowCameraMicrophoneWarning(false)
            }
          />
        )}
        <div
          className={clsx(styles.canopyBriefButton, {
            [styles.isDisabled]: isRecording
          })}
          onClick={handleClickDetails}
          onKeyDown={handleClickDetails}
        >
          <div className={styles.briefTitle}>
            <img src={canopyBrief} alt="brief" />
            <h2>{t("canopyBrief")}</h2>
          </div>
          {isMobile && (
            <Button
              type="link"
              icon={<AntDIcon name="chevronRight" />}
              onClick={handleBack}
            >
              {t("goToBrief")}
            </Button>
          )}
        </div>
        <div className={styles.progressHeader}>
          <div className={styles.progressTitle}>{t("questions")}</div>
          <div className={styles.progressDetails}>
            <QuestionsTimer questions={expertQuestionList} />
            <AnswersProgress questions={expertQuestionList} />
          </div>
        </div>

        <div
          className={clsx(styles.containerQuestions, {
            [styles.submitReady]: isCanopyReadyToBeSubmitted,
            [styles.cameraWarning]: showCameraMicrophoneWarning
          })}
        >
          {expertQuestionList.map((expertQuestion) => (
            <Question
              key={expertQuestion.id}
              order={expertQuestion.order + 1}
              title={expertQuestion.text}
              type={expertQuestion.type}
              isAnswered={expertQuestion.isAnswered}
              isSelected={expertQuestion.id === questionId}
              onClick={() => handleClickQuestion(expertQuestion)}
              disableQuestion={
                isRecording || isVideoPlaying || !isExpertsQuestionsEnabled
              }
            />
          ))}
        </div>
      </div>
    </>
  );
};
