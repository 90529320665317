import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  CONTACT_METHOD_FILTERS_ENUM,
  CONTACT_TYPE_FILTERS_ENUM,
  ExternalExpertStats
} from "@arbolus-technologies/models/expert";

import styles from "./FilterType.module.scss";

interface FilterTypeProps<
  T extends CONTACT_TYPE_FILTERS_ENUM | CONTACT_METHOD_FILTERS_ENUM
> {
  filterTypes: T[];
  filterCounts?: ExternalExpertStats | null;
  selectedValue: T;
  onClick: (filterType: T) => void;
}

export const FilterType = <
  T extends CONTACT_TYPE_FILTERS_ENUM | CONTACT_METHOD_FILTERS_ENUM
>({
  filterTypes,
  filterCounts,
  selectedValue,
  onClick
}: FilterTypeProps<T>) => {
  const { t } = useTranslation("externalSourcingFilters");

  return (
    <div className={styles.filterTypeContainer}>
      {filterTypes.map((filterType) => (
        <button
          key={filterType}
          className={clsx(styles.filterType, {
            [styles.selected]: filterType === selectedValue
          })}
          onClick={() => onClick(filterType)}
        >
          {t(filterType)}
          {filterCounts && (
            <span>
              {filterCounts[
                `${
                  filterType.charAt(0).toLowerCase() + filterType.slice(1)
                }` as keyof ExternalExpertStats
              ] ?? 0}
            </span>
          )}
        </button>
      ))}
    </div>
  );
};
