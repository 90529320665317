import { VIDEO_TYPE } from "@arbolus-technologies/models/canopy";

export const searchAndReplaceSupported = (supportedVideoType: string) => {
  if (supportedVideoType.includes(VIDEO_TYPE.WEBM)) {
    return VIDEO_TYPE.WEBM;
  } else if (supportedVideoType.includes(VIDEO_TYPE.MP4)) {
    return VIDEO_TYPE.MP4;
  } else if (supportedVideoType.includes(VIDEO_TYPE.MPEG)) {
    return VIDEO_TYPE.MPEG;
  } else if (supportedVideoType.includes(VIDEO_TYPE.QUICKTIME)) {
    return VIDEO_TYPE.QUICKTIME;
  }
  return null;
};
