import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { PROJECT_CHAT_ROUTE } from "@arbolus-technologies/routes";
import {
  arbolusLogoWithBackground,
  businessIcon
} from "@arbolus-technologies/theme";

import { useChat } from "../../../Contexts/ChatContext/ChatContext";
import { ExpertChatListItem } from "./ExpertChatListItem/ExpertChatListItem";

import styles from "./ExpertChatList.module.scss";

export const ExpertChatList: React.FC<{ projectId: string }> = ({
  projectId
}) => {
  const { t } = useTranslation("chat");

  const {
    currentChat,
    changeChat,
    expertToArbolusChat,
    expertProjectChat,
    unreadMessagesCount,
    isChatEnabled
  } = useChat();

  return (
    <div className={styles.chatList}>
      {expertToArbolusChat && (
        <Link to={PROJECT_CHAT_ROUTE(projectId, expertToArbolusChat.id)}>
          <ExpertChatListItem
            name={t("arbolusTeamChatTitle")}
            imageUrl={arbolusLogoWithBackground}
            isActive={currentChat?.id === expertToArbolusChat.id}
            onClick={() => {
              changeChat(expertToArbolusChat);
            }}
            unreadCount={unreadMessagesCount[expertToArbolusChat.id]}
          />
        </Link>
      )}
      {isChatEnabled && expertProjectChat && (
        <Link to={PROJECT_CHAT_ROUTE(projectId, expertProjectChat.id)}>
          <ExpertChatListItem
            name={t("projectChatTitle")}
            imageUrl={businessIcon}
            isActive={currentChat?.id === expertProjectChat.id}
            onClick={() => {
              changeChat(expertProjectChat);
            }}
            unreadCount={unreadMessagesCount[expertProjectChat.id]}
          />
        </Link>
      )}
    </div>
  );
};
