import { Icon } from "arbolus-ui-components";
import React from "react";
import { useHistory } from "react-router";

import {
  CIQError,
  ChatAttachment,
  DefaultToasterService,
  DocumentService,
  ErrorResponse,
  FILE_TYPE,
  ToasterService
} from "@arbolus-technologies/api";
import { PROJECT_EVENT_TRANSCRIPT_DOCUMENT_ROUTE } from "@arbolus-technologies/routes";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { DocumentIcon } from "@arbolus-technologies/ui/components";
import { getFileExtension } from "@arbolus-technologies/utils";

import { useChat } from "../../../../../Contexts/ChatContext/ChatContext";

import styles from "./FileMessageContent.module.scss";

interface FileMessageContentProps {
  file: ChatAttachment;
  isDncExpertChat: boolean;
  notificationService?: ToasterService;
}

export const FileMessageContent: React.FC<FileMessageContentProps> = ({
  file,
  isDncExpertChat,
  notificationService = DefaultToasterService
}) => {
  const history = useHistory();
  const { projectData } = useChat();

  const fileExtension = getFileExtension(file.fileName);
  const fileSize = (file.fileSize / 1024 / 1024).toFixed(2);

  const isTranscriptFile = file.type === FILE_TYPE.TRANSCRIPT;

  const handleDownload = (): void => {
    if (isDncExpertChat || !projectData) {
      return;
    }

    if (isTranscriptFile) {
      history.push(
        PROJECT_EVENT_TRANSCRIPT_DOCUMENT_ROUTE(projectData.id, file.id),
        true
      );
    } else {
      downloadFile(file.id);
    }
  };

  const downloadFile = (documentId: string): void => {
    if (!projectData) return;

    DocumentService.downloadDocument(projectData.id, documentId).subscribe({
      error: (error: ErrorResponse<CIQError>) => {
        notificationService.showError(error.message);
      }
    });
  };

  return (
    <div className={styles.fileMessage}>
      <div className={styles.fileIconAndDetails}>
        <DocumentIcon
          fileExtension={fileExtension}
          customHeight="48px"
          customWidth="48x"
          fontSize="24px"
        />
        <div className={styles.fileDetails}>
          <span className={styles.fileName} onClick={handleDownload}>
            {file.fileName}
          </span>
          <span className={styles.fileSizeExtension}>
            {fileExtension} - {fileSize} MB
          </span>
        </div>
      </div>
      {!isDncExpertChat && (
        <Icon
          name="download"
          fontSize="24px"
          color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
          onClick={handleDownload}
        />
      )}
    </div>
  );
};
