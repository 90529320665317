import clsx from "clsx";
import React, { useEffect, useState } from "react";

import { EXPERT_CANOPY_STATUS } from "@arbolus-technologies/models/canopy";
import { IAvatar } from "@arbolus-technologies/models/common";

import { initialsFromFullName } from "@arbolus-technologies/utils";
import styles from "./Avatar.module.scss";

const STATUS_STYLES = {
  InReview: styles["inReview"],
  Complete: styles["approved"],
  Rejected: styles["reject"],
  Incomplete: styles["noResponse"]
};

export type AvatarType =
  | "card"
  | "bigCircle"
  | "bigXSCircle"
  | "adding"
  | "mediumCircle"
  | "mediumXSCircle"
  | "smallXSCircle"
  | "smallCircle"
  | "canopyCircle"
  | "avatarGroupCircle"
  | "avatarGroupCircleSmall";

export interface AvatarProps {
  avatar: IAvatar;
  imageClass?: string;
  fallBackImageClass?: string;
  onClick?: () => void;
  id?: string;
  type?: AvatarType;
  adding?: boolean;
  expertCanopyStatus?: EXPERT_CANOPY_STATUS;
  withGreenTick?: boolean;
}

export const Avatar: React.FC<AvatarProps> = ({
  avatar,
  imageClass = "user-avatar",
  fallBackImageClass = "fallback-avatar",
  onClick,
  id,
  type,
  adding,
  expertCanopyStatus,
  withGreenTick
}) => {
  const [error, setError] = useState<boolean>(false);
  useEffect(() => {
    setError(!avatar.imageUrl);
  }, [avatar.imageUrl]);

  let avatarName = avatar.name;
  if (!adding) {
    avatarName = initialsFromFullName(avatarName);
  }

  const avatarClass = type
    ? styles[`avatar-container-${type}`]
    : styles["avatar-container"];

  const typeClass = expertCanopyStatus ? STATUS_STYLES[expertCanopyStatus] : "";

  return (
    <>
      <div className={avatarClass} onClick={onClick} id={id}>
        {error ? (
          <div
            className={clsx(styles.fallback, fallBackImageClass, {
              [styles.adding]: adding
            })}
          >
            {adding && "+"}
            {avatarName}
          </div>
        ) : (
          <img
            className={imageClass}
            src={avatar.imageUrl}
            onError={(): void => setError(true)}
            alt={avatarName}
          />
        )}
        {withGreenTick && <div className={styles.greenTick} />}
      </div>
      {expertCanopyStatus && <div className={typeClass} />}
    </>
  );
};
