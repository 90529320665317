import React from "react";
import { useTranslation } from "react-i18next";

import { EmptyComponent } from "../EmptyComponent";

import clock from "../../../assets/images/clock.png";

export const BuildingSummaryCreatingCanopy: React.FC = () => {
  const { t } = useTranslation("canopySummary");
  return (
    <EmptyComponent
      title={t("canopySummary")}
      subtitle={t("generatingSummarySubtitle")}
      logo={clock}
    />
  );
};
