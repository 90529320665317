import React from "react";
import { useTranslation } from "react-i18next";

import {
  PROJECT_REFERRAL_STATE,
  ResponseAllExpertsTable
} from "@arbolus-technologies/api";
import { APP_DATE_FORMAT, formatDate } from "@arbolus-technologies/utils";

import styles from "./ActivityDate.module.scss";

const { CANDIDATE, ACCEPT, REJECT, SHORTLIST } = PROJECT_REFERRAL_STATE;

interface ActivityDateProps {
  row: ResponseAllExpertsTable;
}

export const ActivityDate: React.FC<ActivityDateProps> = ({ row }) => {
  const { t } = useTranslation("expertActivityStatus");

  const statuses = {
    [CANDIDATE.toLowerCase()]: t("assigned"),
    [ACCEPT.toLowerCase()]: t("hired"),
    [REJECT.toLowerCase()]: t("rejected"),
    [SHORTLIST.toLowerCase()]: t("shortlisted")
  };
  const status = statuses[row.status.toLowerCase()] || t("noStatus");

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <span>{status}</span>
        <span>{formatDate(row.currentStatusDate, APP_DATE_FORMAT)}</span>
      </div>
    </div>
  );
};
