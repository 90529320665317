import clsx from "clsx";
import React from "react";

import styles from "./ListItemButton.module.scss";

export interface ListItemButtonProps {
  title: string;
  onClick: () => void;
  disabled?: boolean;
  icon: string;
}

export const ListItemButton: React.FC<ListItemButtonProps> = ({
  title,
  onClick,
  disabled,
  icon
}) => (
  <button
    className={clsx(
      styles.listItemButton,
      disabled ? styles.disabled : undefined
    )}
    onClick={onClick}
    disabled={disabled}
  >
    <p className={styles.title}>{title}</p>
    <span className={clsx("material-symbols-sharp", styles.icon)}>{icon}</span>
  </button>
);
