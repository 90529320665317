/* eslint-disable @typescript-eslint/no-empty-function */
import clsx from "clsx";
import { Formik, FormikProps } from "formik";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Media from "react-media";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupAddon,
  Row
} from "reactstrap";
import { createStructuredSelector } from "reselect";
import { Subscription } from "rxjs";

import { SortCriteria, ToasterService } from "@arbolus-technologies/api";
import {
  MixpanelPages,
  trackPageView
} from "@arbolus-technologies/features/common";
import { Loader } from "@arbolus-technologies/ui/components";

import { MAX_PAGE_SIZE } from "../../../../constants/api";
import { LISTING_TYPES } from "../../../../constants/app";
import { CLIENT_SORT_CRITERIA } from "../../../../constants/client";
import { PROJECTS } from "../../../../constants/navigation/authRoutes";
import { SEARCH_DEBOUNCE_TIMEOUT } from "../../../../constants/timer";
import {
  APP_DEVICE_MEDIA_QUERIES,
  UI_CLIENT_NETWORK_PAGE
} from "../../../../constants/ui";
import { MINIMUM_SEARCH_TERM_LENGTH } from "../../../../constants/validation";
import { CIQError, ErrorResponse } from "../../../../models/api";
import { SavedClient, SavedClientBase } from "../../../../models/client";
import { ExpertService } from "../../../../services";
import { AppState } from "../../../../store/reducers";
import {
  CIQEmptyPlaceholder,
  CIQInfiniteScroll
} from "../../../app/components";
import { AuthSelector } from "../../../auth/store";
import ClientNetworkCard from "../../components/networkCard/ClientNetworkCard";

const notification = new ToasterService();

interface ClientNetworkFilterFormValues {
  sortCriteria: SortCriteria;
  searchQuery: string;
}

interface ClientNetworkPageState {
  clients: SavedClient[];
  isLoading: boolean;
  isMore: boolean;
  currentPage: number;
  isSortOptionsOpen: boolean;
}

interface ClientNetworkStorePageProps {
  expertUserId?: string;
}

interface ClientNetworkPageProps
  extends ClientNetworkStorePageProps,
    RouteComponentProps,
    WithTranslation {}

class ClientNetworkPage extends React.PureComponent<
  ClientNetworkPageProps,
  ClientNetworkPageState
> {
  constructor(props: ClientNetworkPageProps) {
    super(props);
    this.state = {
      currentPage: 0,
      clients: [],
      isLoading: false,
      isMore: false,
      isSortOptionsOpen: false
    };
  }

  componentDidMount(): void {
    const { t } = this.props;
    document.title = t("clientNetwork");

    trackPageView({ page: MixpanelPages.Network });
  }

  componentWillUnmount(): void {
    this.getClientNetworkSubscription?.unsubscribe();
    if (this.searchTermDebounceTimeout)
      clearTimeout(this.searchTermDebounceTimeout);
  }

  private getClientNetworkSubscription?: Subscription;

  private searchTermDebounceTimeout?: number;

  private initialElementCount?: number;

  private formInstance: FormikProps<ClientNetworkFilterFormValues> | null =
    null;

  fetchClientNetwork = (): void => {
    if (this.formInstance) {
      const { expertUserId } = this.props;
      const { clients, currentPage } = this.state;
      const nextOffset = clients.length;
      const { values } = this.formInstance;
      const { searchQuery, sortCriteria } = values;

      const limit =
        currentPage === 0 ? this.initialElementCount : MAX_PAGE_SIZE;

      this.setState({
        isLoading: true
      });

      this.getClientNetworkSubscription = ExpertService.getSavedClients(
        expertUserId!,
        searchQuery,
        nextOffset,
        limit,
        sortCriteria.value,
        sortCriteria.direction
      ).subscribe(
        ({ items, pagination }) => {
          const nextClients = clients.concat(items);
          const nextClientCount = nextClients.length;
          this.setState({
            clients: nextClients,
            isLoading: false,
            currentPage: currentPage + 1,
            isMore: nextClientCount < pagination.count
          });
        },
        (error: ErrorResponse<CIQError>) => {
          notification.showError(error.message);
          this.setState({
            isLoading: false
          });
        }
      );
    }
  };

  handleInitialFetch = (elementCount?: number): void => {
    this.initialElementCount = elementCount! * 4;
    this.fetchClientNetwork();
  };

  handleProjectsBottomReached = (): void => {
    const { isLoading, isMore } = this.state;
    if (isMore && !isLoading) {
      this.fetchClientNetwork();
    }
  };

  handleFormStateChange = (): void => {
    this.getClientNetworkSubscription?.unsubscribe();
    this.setState(
      {
        clients: [],
        currentPage: 0
      },
      () => {
        this.fetchClientNetwork();
      }
    );
  };

  handleNavigateToProjects = ({ id, name }: SavedClient): void => {
    const { history } = this.props;

    history.push(PROJECTS, {
      clientFromNetwork: { id, name } as SavedClientBase
    });
  };

  handleSortTypeToggle = (): void => {
    const { isSortOptionsOpen } = this.state;

    this.setState({
      isSortOptionsOpen: !isSortOptionsOpen
    });
  };

  renderFilterForm = ({
    values,
    setFieldValue
  }: FormikProps<ClientNetworkFilterFormValues>): JSX.Element => {
    const { isSortOptionsOpen } = this.state;
    const { t } = this.props;

    const handleSortTypeChange = (sortCriteria: SortCriteria): void => {
      setFieldValue("sortCriteria", sortCriteria);
    };

    const handleQueryChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ): void => {
      const searchQuery = event.target.value;
      if (
        searchQuery === "" ||
        searchQuery.length >= MINIMUM_SEARCH_TERM_LENGTH
      ) {
        const nextQuery = event.target.value;
        if (this.searchTermDebounceTimeout)
          clearTimeout(this.searchTermDebounceTimeout);

        this.searchTermDebounceTimeout = setTimeout(() => {
          setFieldValue("searchQuery", nextQuery);
        }, SEARCH_DEBOUNCE_TIMEOUT);
      }
    };

    return (
      <div className="search-filter-bar">
        <Row>
          <Col className="search-column">
            <InputGroup className="search-input-grey">
              <InputGroupAddon addonType="prepend">
                <span className="ciq-icon ciq-search" />
              </InputGroupAddon>
              <Input
                type="search"
                autoComplete="off"
                placeholder={t("searchClients")}
                name="searchQuery"
                onChange={handleQueryChange}
              />
            </InputGroup>
          </Col>
          <Col className="filter-column">
            <Dropdown
              isOpen={isSortOptionsOpen}
              toggle={this.handleSortTypeToggle}
              className={clsx("d-none d-sm-flex", {
                disabled: false
              })}
            >
              <DropdownToggle caret>
                {t("clientNetwork:sort")}
                {values.sortCriteria.name}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={(): void => {
                    handleSortTypeChange(CLIENT_SORT_CRITERIA.NAME_ASC);
                  }}
                >
                  {CLIENT_SORT_CRITERIA.NAME_ASC.name}
                </DropdownItem>
                <DropdownItem
                  onClick={(): void => {
                    handleSortTypeChange(CLIENT_SORT_CRITERIA.NAME_DESC);
                  }}
                >
                  {CLIENT_SORT_CRITERIA.NAME_DESC.name}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Col>
        </Row>
      </div>
    );
  };

  renderClientNetwork = (): JSX.Element => {
    const { isLoading, clients } = this.state;
    const { t } = this.props;

    let title = t("emptyTitleDefault");
    let description = t("emptyDescriptionDefault");

    if (this.formInstance) {
      const { values } = this.formInstance;
      const { searchQuery } = values;
      if (searchQuery.length) {
        title = t("emptyTitleSecondary");
        description = t("emptyDescriptionSecondary");
      }
    }

    return (
      <>
        <Row>
          {clients.map((e) => (
            <ClientNetworkCard
              key={e.id}
              client={e}
              navigateToProjects={(): void => this.handleNavigateToProjects(e)}
            />
          ))}
        </Row>

        {isLoading && <Loader />}
        {!isLoading && !clients.length && (
          <CIQEmptyPlaceholder
            itemType={LISTING_TYPES.CLIENT_NETWORK}
            title={title}
            description={description}
          />
        )}
      </>
    );
  };

  render(): JSX.Element {
    const { t } = this.props;
    return (
      <div className="page-wrapper">
        <div className="client-network-container page-content">
          <div className="page-content-header">
            <div className="top-container">
              <h1>{t("clientNetwork")}</h1>
              <div className="btn-container" />
            </div>
          </div>
          <div className="client-network-body">
            <Formik<ClientNetworkFilterFormValues>
              initialValues={{
                searchQuery: "",
                sortCriteria: CLIENT_SORT_CRITERIA.NAME_ASC
              }}
              innerRef={(ref): void => {
                this.formInstance = ref;
              }}
              validateOnChange
              validate={this.handleFormStateChange}
              onSubmit={(): void => {}}
            >
              {this.renderFilterForm}
            </Formik>

            <Media queries={APP_DEVICE_MEDIA_QUERIES}>
              {(matches): JSX.Element => (
                <CIQInfiniteScroll
                  onBottomReached={this.handleProjectsBottomReached}
                  onInitialFetch={this.handleInitialFetch}
                  className="clients-container simplebar-light"
                  bottomThreshold={40}
                  elementHeight={UI_CLIENT_NETWORK_PAGE.CLIENT_ROW_ELEMENT_HEIGHT(
                    matches
                  )}
                  style={{
                    maxHeight: UI_CLIENT_NETWORK_PAGE.CONTAINER_HEIGHT(matches),
                    height: UI_CLIENT_NETWORK_PAGE.CONTAINER_HEIGHT(matches),
                    overflowX: "hidden"
                  }}
                >
                  {this.renderClientNetwork()}
                </CIQInfiniteScroll>
              )}
            </Media>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<
  AppState,
  ClientNetworkPageProps,
  ClientNetworkStorePageProps
>({
  expertUserId: AuthSelector.authExpertIdSelector()
});

export default withTranslation("clientNetwork")(
  connect(mapStateToProps)(ClientNetworkPage)
);
