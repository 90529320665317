import React from "react";
import { useTranslation } from "react-i18next";

import { Referral, Slot } from "@arbolus-technologies/models/common";

import { ReferralGridContext } from "../../Models/ReferralsTable";
import { SlotsActionButtons } from "./SlotsActionButtons";
import { SlotsByDay } from "./SlotsByDay";

import styles from "./ReferralSlotsDetail.module.scss";

interface ReferralSlotsDetailProps {
  referral: Referral;
  slots: Slot[];
  context: ReferralGridContext;
}

export const ReferralSlotsDetail: React.FC<ReferralSlotsDetailProps> = ({
  referral,
  slots,
  context
}) => {
  const { t } = useTranslation("referrals", { keyPrefix: "availability" });

  return (
    <div className={styles.container}>
      <SlotsByDay referral={referral} slots={slots} {...context} />
      <div className="d-flex align-items-center">
        {!slots.length && (
          <span className={styles.noSlots}>{t("noSlots")}</span>
        )}
        <div className="ml-auto flex-row-16">
          <SlotsActionButtons referral={referral} {...context} />
        </div>
      </div>
      {/* border from table is eating padding and this nasty stuff works ¯\(°_o)/¯ */}
      <div style={{ height: "4px" }} />
    </div>
  );
};
