import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CanopyAnswersSummariesTypes,
  CanopySummary
} from "@arbolus-technologies/models/canopy-panels";
import { Button } from "@arbolus-technologies/ui/components";
import { APP_DATE_FORMAT } from "@arbolus-technologies/utils";

import { CanopySummaryComponent } from "../../../Components/Components/CanopySummaryComponent/CanopySummaryComponent";
import { CanopySummaryInfoHeader } from "../../../Components/Components/CanopySummaryInfoHeader/CanopySummaryInfoHeader";

import styles from "./GeneratedSummary.module.scss";

interface GeneratedSummaryProps {
  canopyTitle: string;
  summaryData: CanopySummary;
  openAll: boolean;
  printPDF: boolean;
  isGeneratingSummary: boolean;
  setIsOpenAll: (openAll: boolean) => void;
}

export const GeneratedSummary: React.FC<GeneratedSummaryProps> = ({
  canopyTitle,
  summaryData,
  openAll,
  printPDF,
  isGeneratingSummary,
  setIsOpenAll
}) => {
  const { t } = useTranslation("canopySummary");

  const [summaries, setSummaries] = useState<CanopyAnswersSummariesTypes[]>(
    summaryData.canopyAnswersSummaries
  );
  const hasToShow = summaries.length > 0;

  useEffect(() => {
    if (openAll) {
      setSummaries((summaries) =>
        summaries.map((item) => ({
          ...item,
          isOpen: openAll
        }))
      );
    }
  }, [openAll]);

  const updateSummaries = (id: string) => {
    const updatedSummaries = summaries.map((item) => {
      if (item.canopyQuestionId === id) {
        return {
          ...item,
          isOpen: !item.isOpen
        };
      }
      return item;
    });
    setSummaries(updatedSummaries);
    setIsOpenAll(updatedSummaries.every((item) => item.isOpen));
  };

  const handleOpenCloseAllButtonClick = () => {
    const updatedSummaries = summaries.map((item) => ({
      ...item,
      isOpen: !openAll
    }));
    setSummaries(updatedSummaries);
    setIsOpenAll(!openAll);
  };

  return (
    <div className={styles.generatedSummary}>
      {!isGeneratingSummary && hasToShow && (
        <CanopySummaryInfoHeader
          canopyTitle={canopyTitle}
          lastUpdate={moment(summaryData.lastUpdate).format(APP_DATE_FORMAT)}
          numberOfExperts={summaryData.numExpertsInSummary}
          projectName={summaryData.projectName}
        />
      )}
      {hasToShow && (
        <div className={styles.buttonContainer}>
          {!printPDF && (
            <Button
              type="filter"
              filterIcon="unfold_more"
              text={t(openAll ? "closeAll" : "openAll")}
              onClick={handleOpenCloseAllButtonClick}
            />
          )}
        </div>
      )}
      <div className={styles.summaryContainer}>
        {hasToShow &&
          summaries
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .map((item) => (
              <CanopySummaryComponent
                key={item.canopyQuestionId}
                summary={{
                  ...item,
                  sortOrder: item.sortOrder + 1
                }}
                updateSummaries={updateSummaries}
                type={item.questionType}
              />
            ))}
      </div>
    </div>
  );
};
