import { ColDef } from "ag-grid-community";
import i18next from "i18next";

import {
  APPLICATION_STATUS,
  COMPLIANCE_STATUS,
  Referral
} from "@arbolus-technologies/models/common";
import {
  fixedWidth,
  floatingFilter
} from "@arbolus-technologies/ui/components";

import {
  CompletedRenderer,
  DeclinedRenderer,
  PassedRenderer,
  PendingRenderer,
  RejectedRenderer,
  UnavailableRenderer
} from "../../../Components/CellRenderers/StatusRenderer";

const ComplianceToRenderer = {
  [COMPLIANCE_STATUS.PENDING]: PendingRenderer,
  [COMPLIANCE_STATUS.ACCEPT]: PassedRenderer,
  [COMPLIANCE_STATUS.REJECT]: RejectedRenderer,
  [COMPLIANCE_STATUS.UNAVAILABLE]: UnavailableRenderer
};

export const complianceCol: ColDef<Referral> = {
  field: "compliance",
  headerName: i18next.t("referrals:headers:compliance"),
  headerTooltip: i18next.t("referrals:headers:compliance"),
  cellRendererSelector: ({ data }) => ({
    component: ComplianceToRenderer[data!.compliance]
  }),
  filter: "agSetColumnFilter",
  filterParams: {
    suppressSelectAll: true,
    suppressFilterSearch: true,
    suppressMiniFilter: true,
    buttons: ["reset"]
  },
  ...floatingFilter,
  ...fixedWidth(135)
};

const ApplicationToRenderer = {
  [APPLICATION_STATUS.PENDING]: UnavailableRenderer,
  [APPLICATION_STATUS.ACCEPT]: CompletedRenderer,
  [APPLICATION_STATUS.REJECT]: DeclinedRenderer,
  [APPLICATION_STATUS.SENT]: UnavailableRenderer
};

export const applicationCircleCol: ColDef<Referral> = {
  field: "application",
  headerName: i18next.t("referrals:headers:application"),
  headerTooltip: i18next.t("referrals:headers:application"),
  cellRendererSelector: ({ data }) => ({
    component: ApplicationToRenderer[data!.application]
  }),
  filter: "agSetColumnFilter",
  filterParams: {
    suppressSelectAll: true,
    suppressFilterSearch: true,
    suppressMiniFilter: true,
    buttons: ["reset"]
  },
  ...floatingFilter,
  ...fixedWidth(130)
};
