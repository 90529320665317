import React from "react";
import { useTranslation } from "react-i18next";

import { AvailableSlot } from "@arbolus-technologies/models/project";

import styles from "./SelectedSlot.module.scss";

interface SelectedSlotProps {
  isAdmin: boolean;
  selectedSlot?: AvailableSlot;
  timezoneDisplayTxt?: string;
}

export const SelectedSlot: React.FC<SelectedSlotProps> = ({
  isAdmin,
  selectedSlot,
  timezoneDisplayTxt
}) => {
  const { t } = useTranslation("expertAvailabilityTab");

  return (
    <div className={styles.selectedSlotContainerMargin}>
      {selectedSlot && (
        <div className={styles.selectedSlotContainer}>
          <div className={styles.selectedSlot}>
            <div className={styles.selectedSlotTitle}>
              {t("availabilitySelected")}
            </div>
            <div className={styles.selectedSlotText}>
              {selectedSlot?.slotDate} | {selectedSlot?.slotTimeStart} -{" "}
              {selectedSlot?.slotTimeEnd}
            </div>
            <div className={styles.selectedSlotText}>
              {isAdmin && t("projectsTimezone")} {timezoneDisplayTxt}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
