import clsx from "clsx";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ProjectHistory } from "@arbolus-technologies/api";
import { PROJECT_ROUTE } from "@arbolus-technologies/routes";
import { Avatar, HR } from "@arbolus-technologies/ui/components";

import styles from "./ProjectItem.module.scss";

interface ProjectItemProps {
  project: ProjectHistory;
  isLastItem?: boolean;
}

export const ProjectItem: React.FC<ProjectItemProps> = ({
  project,
  isLastItem = false
}) => {
  const { t } = useTranslation("clientUserSlidePanel");
  const {
    projectCreationDate,
    projectCallsSold,
    projectName,
    projectPrimaryLeadName,
    projectPrimaryLeadUserProfileImageUrl,
    projectStatus
  } = project;
  const creationDateFormatted =
    moment(projectCreationDate).format("DD/MM/YYYY");
  const isActive = projectStatus === "Active";

  return (
    <React.Fragment>
      <div className={styles.projectWrapper}>
        <Link className={styles.text} to={PROJECT_ROUTE(project.projectId)}>
          {projectName}
        </Link>
        <div className={styles.statusWrapper}>
          <i
            className={clsx(
              `status-dot ${isActive ? "active" : "archived"}`,
              styles.status
            )}
          />
          <span className={styles.statusText}>
            {isActive ? t("active") : t("archived")}
          </span>
        </div>
      </div>
      <div className={styles.ownerProject}>
        <div className={styles.ownerProjectNameWrapper}>
          <Avatar
            avatar={{
              name: projectPrimaryLeadName ?? "",
              imageUrl: projectPrimaryLeadUserProfileImageUrl ?? ""
            }}
            type="smallCircle"
          />
          <span className={styles.ownerProjectName}>
            {projectPrimaryLeadName ?? t("notSet")}
          </span>
        </div>
        <span className={styles.callsNumber}>
          {projectCallsSold}
          <span className={styles.callsSold}>
            {t("callSoldCount", { count: projectCallsSold })}
          </span>
        </span>
        <span className={styles.callsSold}>
          {projectCreationDate ? creationDateFormatted : ""}
        </span>
      </div>
      {!isLastItem && <HR margin={{ top: 2, bottom: 2 }} />}
    </React.Fragment>
  );
};
