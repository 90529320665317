import clsx from "clsx";
import React from "react";
import Media from "react-media";
import Select, { components, SingleValueProps } from "react-select";

import { SelectOption } from "@arbolus-technologies/models/common";

import { UIConstants } from "../../../constants";

interface CustomSelectProps {
  options: SelectOption[];
  defaultValue?: SelectOption;
  noOptionsMessage: string;
  onSelectChange: (value: string) => void;
  customStyle?: boolean;
  isSearchable?: boolean;
  placeholder?: string;
  onBlur?: () => void;
  disabled?: boolean;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  onSelectChange,
  defaultValue,
  customStyle,
  isSearchable = false,
  noOptionsMessage,
  placeholder,
  onBlur,
  disabled = false
}: CustomSelectProps) => {
  const SingleValue = (
    singleValueProps: SingleValueProps<SelectOption>
  ): JSX.Element => {
    const {
      data: { customLabel, label }
    } = singleValueProps;
    return (
      <components.SingleValue {...singleValueProps}>
        {customLabel || label}
      </components.SingleValue>
    );
  };

  const handleOnFocus = (
    e: React.BaseSyntheticEvent,
    isMobile: boolean
  ): void => {
    if (isMobile) {
      e.preventDefault();
      e.target.readOnly = true;
    } else {
      e.target.readOnly = false;
    }
  };

  return (
    <Media queries={UIConstants.APP_DEVICE_MEDIA_QUERIES}>
      {(matches): JSX.Element => (
        <Select
          onFocus={(e): void => handleOnFocus(e, !matches.large)}
          defaultValue={defaultValue}
          options={options}
          noOptionsMessage={(): string => noOptionsMessage}
          className={clsx("ciqs-select", { "with-icon": customStyle })}
          classNamePrefix="ciqs-select"
          isSearchable={isSearchable}
          placeholder={placeholder}
          components={{
            DropdownIndicator: (): null => null,
            IndicatorSeparator: (): null => null,
            SingleValue
          }}
          onChange={(value): void => {
            onSelectChange((value as SelectOption).value);
          }}
          onBlur={onBlur}
          isDisabled={disabled}
        />
      )}
    </Media>
  );
};

/**
 * @deprecated
 * use CustomSelect from @arbolus-technologies/libs/ui/components
 */

export default CustomSelect;
