import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { BaseUser } from "@arbolus-technologies/models/common";
import { displayUserName } from "@arbolus-technologies/utils";

import { Avatar } from "../../../Avatar/Avatar";
import {
  ArbolusTooltip,
  ArbolusTooltipPlace
} from "../../../Tooltip/ArbolusTooltip";

import styles from "./UserPortrait.module.scss";

interface UserPortraitProps {
  user: BaseUser;
  avatarSize?:
    | "card"
    | "bigCircle"
    | "bigXSCircle"
    | "adding"
    | "mediumCircle"
    | "mediumXSCircle"
    | "smallXSCircle"
    | "smallCircle"
    | "canopyCircle"
    | "avatarGroupCircle";
  onHoverTooltip?: boolean;
  hideAvatar?: boolean;
  icon?: JSX.Element;
  isTruncate?: boolean;
}
export const UserPortrait: React.FC<UserPortraitProps> = ({
  user,
  avatarSize = "mediumXSCircle",
  onHoverTooltip,
  hideAvatar = false,
  icon,
  isTruncate = false
}) => {
  const { t } = useTranslation("avatar");

  return (
    <div className={styles.container}>
      {!hideAvatar && (
        <ArbolusTooltip
          content={t("clickMyImage")}
          place={ArbolusTooltipPlace.RIGHT}
          disabled={!onHoverTooltip}
        >
          <div className={styles.avatarContainer} id="avatar">
            <Avatar
              avatar={{
                name: displayUserName(user, true),
                imageUrl: user.profileImageUrl
              }}
              type={avatarSize}
              expertCanopyStatus={user?.status}
            />
          </div>
        </ArbolusTooltip>
      )}
      <div className={styles.userDetails}>
        <div className={styles.headerContainer}>
          <p className={clsx(styles.userName, isTruncate && styles.truncate)}>
            {displayUserName(user)}
          </p>
          {icon && icon !== null && <div className={styles.icon}>{icon}</div>}
        </div>
        <p className={styles.title} title={user.title}>
          {user.title}
        </p>
      </div>
    </div>
  );
};
