import {
  CIQError,
  Canopy,
  CanopyExpertsCount,
  CanopyQuestion,
  ErrorResponse
} from "@arbolus-technologies/api";
import { CANOPY_STATUS } from "@arbolus-technologies/models/canopy";
import { CanopyQuestionModel } from "@arbolus-technologies/models/canopy-panels";

export const GET_CANOPY_CLIENT_V2 = "CANOPY_V2_GET_CANOPY_CLIENT_V2";
export const GET_CANOPY_CLIENT_V2_SUCCESS =
  "CANOPY_V2_GET_CANOPY_CLIENT_V2_SUCCESS";
export const GET_CANOPY_CLIENT_V2_FAILURE =
  "CANOPY_V2_GET_CANOPY_CLIENT_V2_FAILURE";

export const GET_CANOPY_EXPERTS_COUNT = "GET_CANOPY_EXPERTS_COUNT";
export const GET_CANOPY_EXPERTS_COUNT_SUCCESS =
  "GET_CANOPY_EXPERTS_COUNT_SUCCESS";
export const GET_CANOPY_EXPERTS_COUNT_FAILURE =
  "GET_CANOPY_EXPERTS_COUNT_FAILURE";

export const COPY_CANOPY_INVITATION_LINK_TO_CLIPBOARD =
  "CANOPY_V2_COPY_CANOPY_INVITATION_LINK_TO_CLIPBOARD";

export const GET_CANOPY_QUESTION = "CANOPY_V2_GET_CANOPY_QUESTION";
export const GET_CANOPY_QUESTION_SUCCESS =
  "CANOPY_V2_GET_CANOPY_QUESTION_SUCCESS";
export const GET_CANOPY_QUESTION_FAILURE =
  "CANOPY_V2_GET_CANOPY_QUESTION_FAILURE";
export const RESET_CANOPY_QUESTION = "CANOPY_V2_RESET_CANOPY_QUESTION";

export const GET_CANOPY_QUESTIONS = "CANOPY_V2_GET_CANOPY_QUESTIONS";
export const GET_CANOPY_QUESTIONS_SUCCESS =
  "CANOPY_V2_GET_CANOPY_QUESTIONS_SUCCESS";
export const GET_CANOPY_QUESTIONS_FAILURE =
  "CANOPY_V2_GET_CANOPY_QUESTIONS_FAILURE";

export const GET_CANOPY_EXPERT_ANSWERS = "CANOPY_V2_GET_CANOPY_EXPERT_ANSWERS";
export const GET_CANOPY_EXPERT_ANSWERS_SUCCESS =
  "CANOPY_V2_GET_CANOPY_EXPERT_ANSWERS_SUCCESS";
export const GET_CANOPY_EXPERT_ANSWERS_FAILURE =
  "CANOPY_V2_GET_CANOPY_EXPERT_ANSWERS_FAILURE";

export const RESET_CANOPY_V2 = "CANOPY_V2_RESET_CANOPY_V2";

export const RESET_CANOPY_DATA = "CANOPY_V2_RESET_CANOPY_DATA";

export const ARCHIVE_CANOPY = "CANOPY_ARCHIVE_CANOPY";

export const RESET_CANOPY_ANSWERS = "CANOPY_RESET_CANOPY_ANSWERS";

export const CHANGE_CANOPY_STATUS = "CANOPY_CHANGE_CANOPY_STATUS";

// Get canopy
export interface GetCanopyClientV2Action {
  type: typeof GET_CANOPY_CLIENT_V2;
  payload: {
    canopyId: string;
  };
}

export interface GetCanopyClientSuccessV2Action {
  type: typeof GET_CANOPY_CLIENT_V2_SUCCESS;
  payload: {
    canopyData: Canopy;
  };
}

export interface GetCanopyClientFailureV2Action {
  type: typeof GET_CANOPY_CLIENT_V2_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

export interface GetCanopyExpertsCountAction {
  type: typeof GET_CANOPY_EXPERTS_COUNT;
  payload: {
    canopyId: string;
  };
}

export interface GetCanopyExpertsCountSuccessAction {
  type: typeof GET_CANOPY_EXPERTS_COUNT_SUCCESS;
  payload: {
    expertsCount: CanopyExpertsCount;
  };
}

export interface GetCanopyExpertsCountFailureAction {
  type: typeof GET_CANOPY_EXPERTS_COUNT_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

// Get Canopy Questions
export interface GetCanopyQuestionsAction {
  type: typeof GET_CANOPY_QUESTIONS;
  payload: {
    canopyId: string;
  };
}

export interface GetCanopyQuestionsSuccessAction {
  type: typeof GET_CANOPY_QUESTIONS_SUCCESS;
  payload: {
    questions: CanopyQuestionModel[];
  };
}

export interface GetCanopyQuestionsFailureAction {
  type: typeof GET_CANOPY_QUESTIONS_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

// CANOPY LINK INVITATION

export interface CopyCanopyInvitationLinkToClipboardAction {
  type: typeof COPY_CANOPY_INVITATION_LINK_TO_CLIPBOARD;
  payload: {
    canopyId: string;
    successMessage: string;
  };
}

// GET CANOPY QUESTION

export interface GetCanopyQuestionAction {
  type: typeof GET_CANOPY_QUESTION;
  payload: {
    questionId: string;
  };
}

export interface GetCanopyQuestionSuccessAction {
  type: typeof GET_CANOPY_QUESTION_SUCCESS;
  payload: {
    questionData: CanopyQuestion;
  };
}

export interface GetCanopyQuestionFailureAction {
  type: typeof GET_CANOPY_QUESTION_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

export interface ResetCanopyQuestionAction {
  type: typeof RESET_CANOPY_QUESTION;
}

// RESET CANOPY V2

export interface ResetCanopyV2Action {
  type: typeof RESET_CANOPY_V2;
}

export interface ResetCanopyDataAction {
  type: typeof RESET_CANOPY_DATA;
}

export interface ArchiveCanopyAction {
  type: typeof ARCHIVE_CANOPY;
}
export interface ResetCanopyAnswerAction {
  type: typeof RESET_CANOPY_ANSWERS;
}

export interface ChangeCanopyStatusAction {
  type: typeof CHANGE_CANOPY_STATUS;
  payload: {
    status: CANOPY_STATUS;
  };
}
