import clsx from "clsx";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";

import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import {
  CanopyQuestionBase,
  CreateEditQuestionFields,
  FormConstraints
} from "@arbolus-technologies/models/canopy";
import {
  InputController,
  WordCounter
} from "@arbolus-technologies/ui/components";

import { useCanopyContext } from "../../../helpers/useCanopyContext";

import styles from "./QuestionInfo.module.scss";

export const QuestionInfo: React.FC = () => {
  const { t } = useTranslation("canopyBuilder");
  const { trackClick } = useArbolusTracking();
  const { showUnsavedChanges } = useCanopyContext();

  const {
    control,
    formState: { errors, dirtyFields },
    watch
  } = useFormContext<CanopyQuestionBase>();

  return (
    <div className={styles.container}>
      <div className={styles.inputContainer}>
        <Label className={styles.label}>{t("question")}</Label>
        <InputController
          name={CreateEditQuestionFields.QUESTION}
          error={errors[CreateEditQuestionFields.QUESTION]}
          classnames={clsx(styles.input, {
            [styles.unsavedField]:
              showUnsavedChanges &&
              dirtyFields[CreateEditQuestionFields.QUESTION]
          })}
          control={control}
          placeholder={t("questionPlaceholder")}
          type="textarea"
          size="big"
          onTrackInput={() =>
            trackClick(MixPanelEventNames.CanopyBuilderPanelQuestion)
          }
        />
        <WordCounter
          maxLength={FormConstraints.MAX_QUESTION_LENGTH}
          textLength={watch(CreateEditQuestionFields.QUESTION)?.length}
        />
      </div>
      <div className={styles.inputContainer}>
        <Label className={styles.label}>{t("details")}</Label>
        <InputController
          name={CreateEditQuestionFields.DETAILS}
          error={errors[CreateEditQuestionFields.DETAILS]}
          classnames={clsx(styles.input, {
            [styles.unsavedField]:
              showUnsavedChanges &&
              dirtyFields[CreateEditQuestionFields.DETAILS]
          })}
          control={control}
          placeholder={t("detailsPlaceholder")}
          type="textarea"
          size="big"
          onTrackInput={() =>
            trackClick(MixPanelEventNames.CanopyBuilderPanelDescription)
          }
        />
        <WordCounter
          maxLength={FormConstraints.MAX_DETAILS_LENGTH}
          textLength={watch(CreateEditQuestionFields.DETAILS)?.length}
        />
      </div>
    </div>
  );
};
