import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { ScreeningQuestion } from "@arbolus-technologies/api";
import { KEYWORDS_SEARCH_OPTIONS } from "@arbolus-technologies/models/project";
import { EXPERT_PROFILE } from "@arbolus-technologies/routes";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";

import { ScreeningAnswersCard } from "./ScreeningAnswersCard";

import styles from "./ScreeningAnswers.module.scss";

interface ScreeningAnswersProps {
  expertId: string;
  keywordsList: string[];
  screeningQuestions: ScreeningQuestion[];
}
export const ScreeningAnswers: React.FC<ScreeningAnswersProps> = ({
  expertId,
  keywordsList,
  screeningQuestions
}) => {
  const { t } = useTranslation("discoverMatchingFiltersPanel");

  const hasKeywords = keywordsList.length > 0;

  const isProjectAnswersOptionSelected = useSelector(
    ProjectNxSelector.isKeywordSearchOptionSelected(
      KEYWORDS_SEARCH_OPTIONS.PROJECTS_ANSWERS
    )
  );

  return (
    <div className={styles.screeningAnswers}>
      <div className={styles.screeningAnswersHeader}>
        <h2 className={styles.screeningAnswersTitle}>
          {t("screeningAnswers")}
        </h2>

        <Link
          to={{
            pathname: EXPERT_PROFILE(expertId).pathname,
            search: "?defaultTab=screeningQuestions"
          }}
          target="_blank"
        >
          <Button
            onClick={() => false}
            text={t("allScreeningAnswers")}
            endIcon="chevron_right"
            type="tertiary"
          />
        </Link>
      </div>
      {screeningQuestions.length > 0 &&
      isProjectAnswersOptionSelected &&
      hasKeywords ? (
        <>
          {screeningQuestions.map((screeningQuestion: ScreeningQuestion) => (
            <ScreeningAnswersCard
              key={screeningQuestion.project.id}
              screeningQuestion={screeningQuestion}
              keywordsList={keywordsList}
            />
          ))}
        </>
      ) : (
        <div className={styles.noResultContainer}>
          {t("noScreeningAnswers")}
        </div>
      )}
    </div>
  );
};
