import { CanopyQuestion } from "@arbolus-technologies/api";
import {
  ANSWER_PREFERENCES_TYPE,
  ANSWER_PREFERENCES_TYPE_VALUE,
  ANSWER_TYPE,
  CanopyQuestionFields,
  CreateEditQuestionFields,
  FormConstraints
} from "@arbolus-technologies/models/canopy";

const DUMMY_QUESTION_ID = "xyz";

export const CanopyCreateFormValues = (
  order: number
): CanopyQuestionFields => ({
  [CreateEditQuestionFields.ID]: DUMMY_QUESTION_ID,
  [CreateEditQuestionFields.ORDER]: order,
  [CreateEditQuestionFields.QUESTION]: "",
  [CreateEditQuestionFields.DETAILS]: "",
  [CreateEditQuestionFields.ANSWER_TYPE]: ANSWER_TYPE.VIDEO,
  // MULTICHOICE
  [CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE]:
    ANSWER_PREFERENCES_TYPE.UNLIMITED,
  [CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_EXACT]:
    FormConstraints.MIN_ANSWER_CHOICES,
  [CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_INITIAL_RANGE]:
    FormConstraints.MIN_ANSWER_CHOICES,
  [CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_FINAL_RANGE]:
    FormConstraints.MAX_ANSWER_CHOICES,
  [CreateEditQuestionFields.ANSWER_CHOICES]: [],
  // VALUE
  [CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE]:
    ANSWER_PREFERENCES_TYPE_VALUE.NUMBER,
  [CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE_MULTI_RATING_CHOICES]:
    []
});

export const CanopyEditFormValues = (
  questionSelected: CanopyQuestion
): CanopyQuestionFields => ({
  [CreateEditQuestionFields.ID]: questionSelected.id,
  [CreateEditQuestionFields.ORDER]: questionSelected.order,
  [CreateEditQuestionFields.QUESTION]: questionSelected.text,
  [CreateEditQuestionFields.DETAILS]: questionSelected.details,
  [CreateEditQuestionFields.ANSWER_TYPE]: questionSelected.type,
  // MULTICHOICE
  [CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE]:
    questionSelected.preferenceType ?? ANSWER_PREFERENCES_TYPE.UNLIMITED,
  [CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_EXACT]:
    questionSelected.exactNumber ?? FormConstraints.MAX_ANSWER_CHOICES,
  [CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_INITIAL_RANGE]:
    questionSelected.answersRange?.minimum ??
    FormConstraints.MIN_ANSWER_CHOICES,
  [CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_FINAL_RANGE]:
    questionSelected.answersRange?.maximum ??
    FormConstraints.MAX_ANSWER_CHOICES,
  [CreateEditQuestionFields.ANSWER_CHOICES]:
    questionSelected.optionChoices ?? [],
  // VALUE
  [CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE]:
    questionSelected.valuePreferencesType ??
    ANSWER_PREFERENCES_TYPE_VALUE.NUMBER,
  [CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE_NUMBER_INITIAL_RANGE]:
    questionSelected.valuePreferencesRange?.minimum ??
    FormConstraints.MIN_VALUE[ANSWER_PREFERENCES_TYPE_VALUE.NUMBER],
  [CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE_NUMBER_FINAL_RANGE]:
    questionSelected.valuePreferencesRange?.maximum ??
    FormConstraints.MAX_VALUE[ANSWER_PREFERENCES_TYPE_VALUE.NUMBER],
  [CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE_MULTI_RATING_CHOICES]:
    questionSelected.multiRatingChoices?.map((choice) => ({
      text: choice.multiRatingChoiceText,
      sortOrder: choice.sortOrder
    })) ?? []
});
