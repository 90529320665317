import { ICellRendererParams } from "ag-grid-community";
import React from "react";

import { ListTranscriptModel } from "@arbolus-technologies/api";

import { useTranslation } from "react-i18next";
import styles from "./TranscriptsTable.module.scss";

type TranscriptStatusCellRendererProps = ICellRendererParams<
  ListTranscriptModel,
  never,
  never
>;

export const TranscriptStatusCellRenderer: React.FC<
  TranscriptStatusCellRendererProps
> = ({ data }) => {
  const { t } = useTranslation("transcriptListPage");
  return (
    <div>
      <span className={getClassName(data!.expiresIn)}>
        {t("expiresInXDays", { days: data!.expiresIn })}
      </span>
    </div>
  );
};

function getClassName(expiresIn: number) {
  return expiresIn <= 7 ? styles.statusExpiringCellRenderer : undefined;
}
