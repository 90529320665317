import React from "react";
import { useTranslation } from "react-i18next";

import {
  ResponseAllExpertsTable,
  StatusExpertTable
} from "@arbolus-technologies/api";

import styles from "./Application.module.scss";

interface ApplicationProps {
  row: ResponseAllExpertsTable;
}

export const Application: React.FC<ApplicationProps> = ({ row }) => {
  const { t } = useTranslation("applicationColumn");

  const applicationStatus = (applicationStatus: StatusExpertTable) => {
    if (row.reviewStatus === StatusExpertTable.Pending) {
      if (!row.fastTrack) {
        return <div>{t("reviewPending")}</div>;
      }
    }

    switch (applicationStatus) {
      case StatusExpertTable.Accept:
        return (
          <>
            <div className={styles.circleCompleted} />
            <span>{t("completed")}</span>
          </>
        );
      case StatusExpertTable.Pending:
        return (
          <>
            <div className={styles.circlePending} />
            <div>{t("pending")}</div>
          </>
        );
      case StatusExpertTable.Reject:
        return (
          <>
            <div className={styles.circleDeclined} />
            <div>{t("declined")}</div>
          </>
        );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {applicationStatus(row.applicationStatus)}
      </div>
    </div>
  );
};
