import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { CALL_STATUSES } from "@arbolus-technologies/api";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { CIQPopover } from "@arbolus-technologies/ui/components";

import styles from "./StatusFilter.module.scss";

interface StatusFilterProps {
  tanStackSortFn: (sortValue: string) => void;
}

export const StatusFilter: React.FC<StatusFilterProps> = ({
  tanStackSortFn
}) => {
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const { t } = useTranslation("expertsList");

  const toggle = () => setShowFilters((prev) => !prev);

  const renderItem = (status: CALL_STATUSES): JSX.Element => (
    <div
      key={`${status}`}
      className={styles.item}
      onClick={() => {
        tanStackSortFn(status);
        toggle();
      }}
    >
      {t(`orderBy_${status.toLowerCase()}`)}
    </div>
  );

  return (
    <>
      <div
        id="popover_statusCall"
        onClick={toggle}
        className={clsx("d-flex flex-row align-items-center", styles.header)}
      >
        <span>{t("expertsList:statusCall")}</span>
        <div className={styles.expandIcon}>
          <Icon
            name={showFilters ? "expand_less" : "expand_more"}
            fontSize="18px"
            color={ARBOLUS_COLORS.bColorGrayIcon}
          />
        </div>
      </div>
      <CIQPopover
        isOpen={showFilters}
        id={`popover_statusCall`}
        onPopoverClose={toggle}
      >
        <div className={styles.itemsContainer}>
          {Object.values(CALL_STATUSES).map(renderItem)}
        </div>
      </CIQPopover>
    </>
  );
};
