import { Icon } from "arbolus-ui-components";
import React from "react";

import styles from "./BoxOption.module.scss";

interface BoxOptionProps {
  title: string;
  subtitle?: string;
  onClick: () => void;
}
export const BoxOption: React.FC<BoxOptionProps> = ({
  title,
  subtitle,
  onClick
}) => (
  <div className={styles.container} onClick={onClick}>
    <div className={styles.textContainer}>
      <h1 className={styles.title}>{title}</h1>
      {subtitle && <h2 className={styles.subtitle}>{subtitle}</h2>}
    </div>
    <Icon name="arrow_forward_ios" fontSize="16px" color="#80859F" />
  </div>
);
