import React from "react";
import { useTranslation } from "react-i18next";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { AdminProjectStatsCard } from "../AdminProjectStatsCard/AdminProjectStatsCard";
import { StatButtonProps } from "./StatButtonProps";

export const ShortlistButton: React.FC<StatButtonProps> = ({
  to,
  itemCount
}) => {
  const { t } = useTranslation("shortlistButton");

  return (
    <AdminProjectStatsCard
      title={t("shortlist")}
      subtitle={
        itemCount > 0
          ? t("expertCount", {
              count: itemCount
            })
          : t("noExpertsAvailable")
      }
      iconName="circle"
      color={ARBOLUS_COLORS.bColorSecondaryAmber}
      to={to}
    />
  );
};
