import { useSelector } from "react-redux";

import { LoggedInUser, UserProfile } from "@arbolus-technologies/api";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

export function useUserProfile(): LoggedInUser | UserProfile {
  const isAdmin = useSelector(CacheSelector.isAdmin());
  // Used for client and expert
  const loggedInUser = useSelector(CacheSelector.loggedInUser());
  // Used for admins
  const userProfile = useSelector(CacheSelector.userProfile());
  return isAdmin ? userProfile : loggedInUser;
}
