import { Button } from "arbolus-ui-components";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  ANSWER_PREFERENCES_TYPE,
  ANSWER_TYPE,
  CanopyQuestionFields,
  Choice,
  CreateEditQuestionFields
} from "@arbolus-technologies/models/canopy";

import { isMinNumberOfOptionsValid } from "../../../helpers/CanopyQuestionHelpers";
import { useCanopyContext } from "../../../helpers/useCanopyContext";
import { DeleteQuestion } from "./DeleteQuestion";

import styles from "./QuestionActions.module.scss";

interface QuestionActionsProps {
  onSubmit: (question: CanopyQuestionFields) => void;
  onDelete: () => void;
}

export const QuestionActions: React.FC<QuestionActionsProps> = ({
  onSubmit,
  onDelete
}) => {
  const { t } = useTranslation("canopyBuilder");
  const { isEditMode, isCanopyActive } = useCanopyContext();

  const {
    handleSubmit,
    formState: { isValid, isDirty },
    watch,
    reset
  } = useFormContext<CanopyQuestionFields>();

  const [
    answerPreferencesType,
    answerType,
    answerExactNumber,
    answerFinalRangeNumber,
    answerChoices
  ]: [ANSWER_PREFERENCES_TYPE, ANSWER_TYPE, number, number, Choice[]] = watch([
    CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE,
    CreateEditQuestionFields.ANSWER_TYPE,
    CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_EXACT,
    CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_FINAL_RANGE,
    CreateEditQuestionFields.ANSWER_CHOICES
  ]);

  const hasMinNumberOdValidOptions = isMinNumberOfOptionsValid({
    answerPreferencesType,
    answerType,
    answerExactNumber,
    answerFinalRangeNumber,
    answerChoices
  });

  const isAddQuestionButtonEnabled =
    isValid && (!isEditMode || isDirty) && hasMinNumberOdValidOptions;

  return (
    <div className={styles.footer}>
      <DeleteQuestion onDelete={onDelete} disabled={isCanopyActive} />
      <div className={styles.rightButtonsContainer}>
        {isEditMode && (
          <Button onClick={() => reset()} text={t("cancel")} type="tertiary" />
        )}
        <Button
          onClick={handleSubmit(onSubmit)}
          text={isEditMode ? t("update") : t("save")}
          type="primary"
          disabled={!isAddQuestionButtonEnabled}
        />
      </div>
    </div>
  );
};
