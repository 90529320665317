import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  BookmarkService,
  DefaultToasterService,
  ToasterService
} from "@arbolus-technologies/api";
import { BookmarkCollectionsStoreActions } from "@arbolus-technologies/stores/bookmark-collections";
import { BookmarksStoreActions } from "@arbolus-technologies/stores/bookmarks";
import { ArbolusModalFillable } from "@arbolus-technologies/ui/components";

import {
  AddCollectionFormObject,
  AddCollectionSchema,
  NAME
} from "./AddCollectionSchema";

interface AddCollectionModalProps {
  isOpen: boolean;
  close: () => void;
  onSuccess?: (id: string) => void;
  bookmarkService?: typeof BookmarkService;
  notificationService?: ToasterService;
}

export const AddCollectionModal: React.FC<AddCollectionModalProps> = ({
  isOpen,
  close,
  onSuccess,
  bookmarkService = BookmarkService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("bookmarkCollections");
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);

  const formReturn = useForm<AddCollectionFormObject>({
    resolver: yupResolver(AddCollectionSchema),
    mode: "all",
    defaultValues: {
      [NAME]: ""
    }
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, errors }
  } = formReturn;

  const closeModal = useCallback(
    (force = false) => {
      if (submitting && !force) {
        return;
      }

      setSubmitting(false);
      reset();
      close();
    },
    [close, reset, submitting]
  );

  const createCollection = useCallback(
    (formObject: AddCollectionFormObject) => {
      if (submitting) {
        return;
      }

      setSubmitting(true);

      bookmarkService
        .postBookmarkCollection({ name: formObject[NAME] })
        .subscribe({
          next: (response) => {
            dispatch(BookmarkCollectionsStoreActions.listCollectionsReload());
            dispatch(BookmarksStoreActions.resetBookmarksList());
            closeModal(true);
            reset();
            onSuccess && onSuccess(response.id);
          },
          error: (error) => {
            setSubmitting(false);
            notificationService.showApiErrors(error);
          }
        });
    },
    [
      bookmarkService,
      closeModal,
      dispatch,
      notificationService,
      onSuccess,
      submitting,
      reset
    ]
  );

  return (
    <ArbolusModalFillable
      title={t("newList")}
      toggle={closeModal}
      rightButton={{
        type: "primary",
        onClick: handleSubmit(createCollection),
        text: t("confirm"),
        disabled: !isValid || submitting
      }}
      leftButton={{
        type: "tertiary",
        onClick: closeModal,
        text: t("cancel"),
        disabled: submitting
      }}
      control={control}
      error={errors[NAME]}
      isOpen={isOpen}
      name={NAME}
      placeholder={t("listName")}
    />
  );
};
