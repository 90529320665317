/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from "clsx";
import React from "react";
import { Control, Controller, FieldError } from "react-hook-form";
import { Label } from "reactstrap";

import { RichTextEditor } from "../RichTextEditor/RichTextEditor";

import styles from "./RichTextEditorController.module.scss";

interface RichTextEditorControllerProps {
  control: Control<any>;
  placeholder?: string;
  name: string;
  error?: FieldError;
  disabled?: boolean;
  onTrackRichText?: () => void;
}

export const RichTextEditorController: React.FC<
  RichTextEditorControllerProps
> = ({
  control,
  placeholder = "",
  name,
  error,
  disabled = false,
  onTrackRichText
}): JSX.Element => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, onBlur, value } }) => (
      <>
        <div
          className={clsx("richtexteditor-container", error && "is-invalid")}
        >
          <RichTextEditor
            placeholder={placeholder}
            onChange={(content: string): void => {
              onChange(content);
            }}
            onBlur={onBlur}
            editorState={value}
            disabled={disabled}
            onTrackRichText={onTrackRichText}
          />
        </div>

        <div className={styles.errorContainer}>
          {error && (
            <Label className={styles.errorMessage}>{error.message}</Label>
          )}
        </div>
      </>
    )}
  />
);
