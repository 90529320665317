import React from "react";

import { InternalComment } from "@arbolus-technologies/api";
import { SlidePanel } from "@arbolus-technologies/features/common";
import { PanelId } from "@arbolus-technologies/stores/panels";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { CreateUpdateComment } from "./CreateUpdateComment/CreateUpdateComment";

interface CreateUpdateCommentSlidePanelProps {
  expertId: string;
  selectedComment?: InternalComment;
  setSelectedComment: () => void;
  customCloseRequest: () => void;
  handleHasToUpdateInternalComments: () => void;
}

export const CreateUpdateCommentSlidePanel: React.FC<
  CreateUpdateCommentSlidePanelProps
> = ({
  expertId,
  selectedComment,
  setSelectedComment,
  customCloseRequest,
  handleHasToUpdateInternalComments
}) => (
  <SlidePanel
    panelId={PanelId.NewComment}
    width={SIDE_PANEL_SIZE._400}
    hideHeader
    customCloseRequest={customCloseRequest}
  >
    <CreateUpdateComment
      expertId={expertId}
      selectedComment={selectedComment}
      setSelectedComment={setSelectedComment}
      handleHasToUpdateInternalComments={handleHasToUpdateInternalComments}
    />
  </SlidePanel>
);
