import { ErrorInfo } from "react";

/* eslint-disable no-underscore-dangle */
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    __insp: any;
  }
}

const INSPECTLET_TAGS = {
  APP_UNHANDLED_EXCEPTION: "APP_UNHANDLED_EXCEPTION"
};

class AnalyticsService {
  constructor() {
    /* eslint-disable */
    window.__insp = window.__insp || [];
  }

  errorObjectBuilder(object: any) {
    const timestampAdded = { ...object, timeStamp: new Date().toISOString() };
    return JSON.stringify(timestampAdded);
  }

  identifyLoggedInUser(email: string): void {
    window.__insp.push(["identify", email]);
  }

  appUnhandledException(error: Error, errorInfo: ErrorInfo) {
    const errorObj = this.errorObjectBuilder({
      error: error.message,
      errorInfo: errorInfo.componentStack
    });
    window.__insp.push([
      "tagSession",
      { [`${INSPECTLET_TAGS.APP_UNHANDLED_EXCEPTION}`]: errorObj }
    ]);
  }
}

export default new AnalyticsService();
