import { APP_USER_ROLES } from "../../constants/app";

const adminClientPermissions = [
  "navBar:spendTracker",
  "navBar:invoices",
  "navBar:users",
  "navBar:clientAccount",

  "usersPage:addUser",

  "projectTeam:inviteTeamByEmail"
];

const clientPermissions = [
  "navBar:addNewProject",

  "projectBase:addExpert",
  "projectBase:clientBilling",
  "projectBase:clientTeam",
  "projectBase:clientBrief",
  "projectBase:clientWelcome",
  "projectBase:clientWelcomeBannerLink",
  "projectBase:candidatePicker",
  "projectBase:referralStatIndication",
  "projectBase:archiveProject",
  "projectBase:clientMembers",

  "projectExperts:referralView",

  "projectTeam:inviteTeam",
  "projectTeam:showClientUserInfo",
  "projectTeam:removeMember",

  "calendarPage:createEvent",

  "documentsPage:emptyDocumentsList",

  "projectsHome:newProject",
  "projectsHome:filterProjects",
  "projectsHome:clientProject",
  "projectHome:clientUserEmptyState",

  "projectTeam:teamPanelTitle",

  "navBar:findExperts",
  "navBar:expertNetwork",
  "navBar:clientLogo",
  "navBar:clientUserProfileImage",
  "navBar:logoutUser",
  "navBar:bookmarks",

  "eventViewPanel:editEvent",
  "eventViewPanel:deleteEvent",
  "eventViewPanel:showClientUserInfo",

  "welcome:clientWelcome",
  "welcome:clientActions",

  "projectBrief:editProject",
  "projectBrief:expertDetails",
  "projectBrief:externalOverview",

  "inbox:categoryEmptyState",

  "transcript:transcriptDelete",

  "canopy:clientCanopyDetails",
  "canopy:dashboard",
  "angles:dashboard",
  "canopy:notification"
];

const expertPermissions = [
  "projectBase:referSomeone",
  "projectBase:help",
  "projectBase:expertProjectBrief",
  "projectBase:expertTeam",
  "projectBase:expertTimesheet",
  "projectBase:expertWelcome",
  "projectBase:expertCalendarEmptyState",
  "projectBase:expertDocsEmptyState",
  "projectBase:expertWelcomeBannerLink",

  "projectsHome:expertProject",
  "projectsHome:expertProjectsEmptyState",

  "projectTeam:teamPanelExpertTitle",

  "navBar:profile",
  "navBar:referrals",
  "navBar:clientNetwork",
  "navBar:earningNTransfers",
  "navBar:expertProfileImage",
  "navBar:logoutUser",
  "navBar:canopySurveys",

  "documentsPage:expertEmptyDocumentsList",

  "welcome:expertWelcome",
  "welcome:expertActions",

  "projectBrief:overview",

  "inbox:categoryExpertEmptyState",
  "inbox:applicationRejection",

  "canopy:expertCanopyDetails",

  "calendarPage:updateAvailability"
];

const accessControlRules = {
  [`${APP_USER_ROLES.adminClient}`]: [
    ...clientPermissions,
    ...adminClientPermissions
  ],
  [`${APP_USER_ROLES.client}`]: clientPermissions,
  [`${APP_USER_ROLES.expert}`]: expertPermissions,
  [`${APP_USER_ROLES.admin}`]: []
};

export default accessControlRules;
