import { Formik, FormikProps } from "formik";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import {
  Compliance,
  ProjectApplicationStepProps,
  ProjectComplianceFormValues
} from "@arbolus-technologies/models/project";

import { ProjectComplianceQuestionType } from "../../../../../constants/expert";
import { ProjectApplicationWrapper } from "../ProjectApplicationWrapper/ProjectApplicationWrapper";
import TextCompliance from "./TextCompliance";
import YesNo from "./YesNo";
import YesNoNa from "./YesNoNa";
import YesNoText from "./YesNoText";

import styles from "./ProjectCompliance.module.scss";

export interface ComplianceQuestionProps {
  compliance: Compliance;
  index: number;
  formProps: FormikProps<ProjectComplianceFormValues>;
}

export interface ProjectComplianceProps extends ProjectApplicationStepProps {
  compliances: Compliance[];
}

export const ProjectCompliance: React.FC<ProjectComplianceProps> = ({
  stepDetails,
  onWorkHistoryConfirmed: onSaveProgress,
  onBack,
  isLoading,
  compliances
}) => {
  const { t } = useTranslation("projectApplication");
  const formikRef = useRef<FormikProps<ProjectComplianceFormValues> | null>(
    null
  );

  const preprocessAnswers = (): Compliance[] =>
    compliances.map((compliance) => {
      const { question, answer, questionId, answerId } = compliance;
      const { displayModel } = question;
      const { defaultValue, textRequiredValue, questionType } = displayModel;

      const isNotTextCompliance =
        questionType !== ProjectComplianceQuestionType.Text;
      const createDefaultValue = isNotTextCompliance ? defaultValue : "";
      const createdAnswer = answer?.answer || createDefaultValue;
      const createdAnswerText =
        textRequiredValue === createdAnswer
          ? answer?.textAnswer || ""
          : undefined;

      return {
        answer: {
          answer: createdAnswer,
          textAnswer: createdAnswerText
        },
        question,
        questionId,
        answerId
      } as Compliance;
    });

  const handleFormSubmit = (values: ProjectComplianceFormValues): void => {
    if (formikRef.current) {
      const { dirty } = formikRef.current;
      onSaveProgress(values, false, dirty);
    }
  };

  const renderCompliance = (
    compliance: Compliance,
    index: number,
    formProps: FormikProps<ProjectComplianceFormValues>
  ): JSX.Element => {
    const {
      question: { displayModel }
    } = compliance;
    const { questionType } = displayModel;
    const {
      Text,
      YesNoNa: YesNoNaType,
      YesNoText: YesNoTextType
    } = ProjectComplianceQuestionType;

    switch (questionType) {
      case Text:
        return (
          <TextCompliance
            key={compliance.questionId}
            compliance={compliance}
            formProps={formProps}
            index={index}
          />
        );
      case YesNoNaType:
        return (
          <YesNoNa
            key={compliance.questionId}
            compliance={compliance}
            formProps={formProps}
            index={index}
          />
        );
      case YesNoTextType:
        return (
          <YesNoText
            key={compliance.questionId}
            compliance={compliance}
            formProps={formProps}
            index={index}
          />
        );
      default:
        return (
          <YesNo
            key={compliance.questionId}
            compliance={compliance}
            formProps={formProps}
            index={index}
          />
        );
    }
  };

  const complianceQuestions = preprocessAnswers();
  const nextButtonShouldBeDisabled = complianceQuestions.some(
    (compliance) =>
      compliance?.answer?.answer === "" &&
      compliance.question.displayModel.required
  );

  return (
    <Formik<ProjectComplianceFormValues>
      initialValues={{ compliances: complianceQuestions }}
      onSubmit={handleFormSubmit}
      innerRef={formikRef}
      validateOnChange
      validateOnBlur
      validateOnMount
    >
      {(formProps: FormikProps<ProjectComplianceFormValues>): JSX.Element => {
        const { dirty, isValid, handleSubmit, values } = formProps;
        return (
          <ProjectApplicationWrapper
            isValid={!nextButtonShouldBeDisabled || isValid}
            dirty={dirty}
            stepDetails={stepDetails}
            onSaveProgress={(): void => onSaveProgress(values, true, dirty)}
            onNext={handleSubmit}
            onBack={onBack}
            isLoading={isLoading}
          >
            <div className={styles.projectComplianceContainer}>
              <h1>{t("compliance")}</h1>
              <div className={styles.projectCompliance}>
                {compliances.map((compliance, index) =>
                  renderCompliance(compliance, index, formProps)
                )}
              </div>
            </div>
          </ProjectApplicationWrapper>
        );
      }}
    </Formik>
  );
};
