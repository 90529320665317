import React from "react";
import { useTranslation } from "react-i18next";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  ButtonNoBorderWithIcon,
  CircleWithIcon
} from "@arbolus-technologies/ui/components";

import styles from "./ActionComponent.module.scss";

export enum ActionComponentType {
  copyLink = "copyLink",
  cloneCanopy = "cloneCanopy"
}

interface ActionComponentProps {
  type: ActionComponentType;
  onClick: () => void;
}

export const ActionComponent: React.FC<ActionComponentProps> = ({
  type,
  onClick
}) => {
  const { t } = useTranslation("canopy");

  const CANOPY_ACTIONS = {
    [ActionComponentType.copyLink]: {
      circleIcon: "link",
      title: t("SendALink"),
      subtitle: t("copyAndSend"),
      buttonIcon: "content_copy",
      buttonName: t("copyLink")
    },
    [ActionComponentType.cloneCanopy]: {
      circleIcon: "file_copy",
      title: t("cloneCanopy"),
      subtitle: t("cloneAndSend"),
      buttonIcon: "chevron_right",
      buttonName: t("cloneCanopy")
    }
  };

  const canopyAction = CANOPY_ACTIONS[type];

  return (
    <div className={styles.container}>
      <CircleWithIcon
        icon={canopyAction.circleIcon}
        background={ARBOLUS_COLORS.bColorBaseWhite}
        color={ARBOLUS_COLORS.bColorBasePurple}
        border={`1px solid ${ARBOLUS_COLORS.bColorSecondaryGreyMid}`}
      />
      <div className={styles.leftContainer}>
        <span className={styles.title}>{canopyAction.title}</span>
        <span className={styles.subtitle}>{canopyAction.subtitle}</span>
      </div>
      <div className={styles.rightContainer}>
        <ButtonNoBorderWithIcon
          iconName={canopyAction.buttonIcon}
          name={canopyAction.buttonName}
          onClick={onClick}
          style={{ gap: "8px" }}
        />
      </div>
    </div>
  );
};
