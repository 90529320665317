import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CanopyV2StoreActions } from "@arbolus-technologies/stores/canopy-V2";
import {
  ProjectCanopySelector,
  ProjectCanopyStoreActions
} from "@arbolus-technologies/stores/project-canopy-store";
import { NoSurvey } from "@arbolus-technologies/ui/canopy";
import { Loader } from "@arbolus-technologies/ui/components";

import { SurveyCard } from "../SurveyCard/SurveyCard";

import styles from "./SurveyList.module.scss";

interface SurveyListProps {
  projectId: string;
  createNewSurvey: () => void;
  canopyDetailsPageRoute: (canopyId: string) => string;
  canopyExpertsRoute: (canopyId: string) => string;
}

export const SurveyList = ({
  projectId,
  createNewSurvey,
  canopyDetailsPageRoute,
  canopyExpertsRoute
}: SurveyListProps): JSX.Element => {
  const dispatch = useDispatch();
  const canopies = useSelector(ProjectCanopySelector.canopiesSelector());
  const isCanopiesLoading = useSelector(
    ProjectCanopySelector.canopiesLoadingSelector()
  );

  useEffect(() => {
    dispatch(ProjectCanopyStoreActions.getCanopies(projectId));
    dispatch(ProjectCanopyStoreActions.setCanopyPreviousPage("project"));
  }, [dispatch, projectId]);

  useEffect(() => {
    dispatch(CanopyV2StoreActions.resetCanopyV2());
  }, [dispatch]);

  return (
    <div className={styles.container}>
      {isCanopiesLoading ? (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <>
          {canopies.length > 0 &&
            canopies.slice(0, 3).map((canopy) => (
              <div className={styles.surveyCard} key={canopy.id}>
                <SurveyCard
                  canopy={canopy}
                  canopyDetailsPageRoute={canopyDetailsPageRoute}
                  canopyExpertsRoute={canopyExpertsRoute}
                />
              </div>
            ))}
          {canopies.length === 0 && (
            <NoSurvey handleNoSurveyClick={createNewSurvey} />
          )}
        </>
      )}
    </div>
  );
};
