import clsx from "clsx";
import React from "react";

import styles from "./RowItem.module.scss";

interface RowItemProps {
  item: { id: string; name: string };
  handleOnClickItem: () => void;
  itemSelectedId?: string;
  isInternalPanel?: boolean;
}

export const RowItem: React.FC<RowItemProps> = ({
  item,
  handleOnClickItem,
  itemSelectedId = "",
  isInternalPanel = false
}) => {
  const isSelected = item.id === itemSelectedId;
  const isDisabled = itemSelectedId !== "";
  return (
    <div
      className={clsx(
        styles.itemContainer,
        isInternalPanel
          ? styles.itemContainerNoPadding
          : styles.itemContainerPadding
      )}
      key={item.id}
    >
      <div className={clsx(styles.text, isSelected && styles.selected)}>
        {item.name}
      </div>
      <div className={styles.iconsContainer}>
        {isSelected && (
          <span className={clsx("ciq-icon ciq-check", styles.checkIcon)} />
        )}
        <span
          className={clsx(
            `ciq-icon ciq-close`,
            styles.plusButton,
            !isSelected && isDisabled && styles.disabled,
            !isSelected && styles.hidden
          )}
          onClick={handleOnClickItem}
        />
        <span
          className={clsx(
            `ciq-icon ciq-plus`,
            styles.plusButton,
            !isSelected && isDisabled && styles.disabled,
            isSelected && styles.hidden
          )}
          onClick={handleOnClickItem}
        />
      </div>
    </div>
  );
};
