import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";

interface HelloMessageProps {
  navigateToLogin: () => void;
  enquirer: string;
}

export function HelloMessage({
  navigateToLogin,
  enquirer
}: HelloMessageProps): React.ReactElement {
  const { t } = useTranslation("register");

  return (
    <>
      <div className="message-banner green">
        <p className="message">{t("receivedMessage")}</p>
        <span className="ciq-icon ciq-check" />
      </div>

      <Row>
        <Col sm="12">
          <div className="message-container">
            <h1>
              {t("hello")}, {enquirer}
            </h1>
            <h3>{t("thanksForContact")}</h3>
            <p>{t("beInTouchMessage")}</p>
          </div>
          <div className="btn-container">
            <Button onClick={(): void => navigateToLogin()} text={t("ok")} />
          </div>
        </Col>
      </Row>
    </>
  );
}
