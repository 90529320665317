import { push } from "connected-react-router";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { SlidePanel } from "@arbolus-technologies/features/common";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import {
  ProjectAnglesSelector,
  ProjectAnglesStoreActions
} from "@arbolus-technologies/stores/project-angles-store";
import {
  InformModal,
  SectionHeader
} from "@arbolus-technologies/ui/components";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { AnglesList } from "../AnglesList/AnglesList";
import { AngleDetails } from "../ViewEditAnglePanel/AngleDetails";

import styles from "./AnglesSummary.module.scss";

const ANGLES_LIMIT_NUMBER = 10;

export interface AnglesSummaryProps {
  viewAllAnglesPageRoute: () => string;
  projectId: string;
  isAdmin?: boolean;
  chatId?: string;
  navigateToExpertsListTab: string;
}

export const AnglesSummary: React.FC<AnglesSummaryProps> = ({
  viewAllAnglesPageRoute,
  navigateToExpertsListTab,
  projectId,
  isAdmin = false,
  chatId
}) => {
  const [showInformModal, setShowInformModal] = useState(false);
  const [showLimitAngleModal, setShowLimitAngleModal] = useState(false);
  const anglesCards = useSelector(ProjectAnglesSelector.anglesCardsSelector());

  const dispatch = useDispatch();
  const { t } = useTranslation("anglesPane");

  const handleViewAllAngles = (): void => {
    dispatch(push(viewAllAnglesPageRoute()));
  };

  const hideModal = (): void => setShowInformModal(false);

  const handleCreateNewAngle = () => {
    if (anglesCards.length >= ANGLES_LIMIT_NUMBER) {
      setShowLimitAngleModal(true);
    } else {
      dispatch(PanelStoreActions.openPanel(PanelId.CreateAngle));
      dispatch(ProjectAnglesStoreActions.setFormCreateEditAngleEnabled());
    }
  };

  const customCloseRequest = () => {
    dispatch(PanelStoreActions.closePanel(PanelId.CreateAngle));
    dispatch(ProjectAnglesStoreActions.resetSelectedAngle());
  };

  return (
    <>
      <InformModal
        toggleModal={showInformModal}
        messageTitle={t("titleInformModal")}
        warningMessage={t("bodyInformModal")}
        hideModal={hideModal}
      />
      <InformModal
        toggleModal={showLimitAngleModal}
        messageTitle={t("anglesLimitTitle")}
        warningMessage={t("anglesLimitBody")}
        hideModal={() => setShowLimitAngleModal(false)}
      />
      <div className={styles.container}>
        <SlidePanel
          panelId={PanelId.CreateAngle}
          width={SIDE_PANEL_SIZE._720}
          customCloseRequest={customCloseRequest}
        >
          <AngleDetails projectId={projectId} isAdmin={isAdmin} />
        </SlidePanel>
        <AnglesList
          navigateToExpertsListTab={navigateToExpertsListTab}
          projectId={projectId}
          isAdmin={isAdmin}
          chatId={chatId}
        />
        <SectionHeader
          mainTitle={t("Angles")}
          bodyInformModal={t("bodyInformModal")}
          buttonCreate={{
            onClick: handleCreateNewAngle,
            title: t("angle")
          }}
          buttonViewAll={{
            onClick: handleViewAllAngles,
            title: t("viewAll"),
            disable: anglesCards.length === 0
          }}
          titleInformModal={t("titleInformModal")}
        />
      </div>
    </>
  );
};
