import { Icon } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { Vendor } from "@arbolus-technologies/models/common";
import { EXPERT_EXPERIENCE_EDIT_TAB } from "@arbolus-technologies/routes";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  Button,
  Chip,
  Drawer,
  HR,
  IconCircleFeedback
} from "@arbolus-technologies/ui/components";

import { SLOT_ICONS } from "./slotIcons";

import styles from "./EditExpertVendors.module.scss";

interface EditExpertVendorItemProps {
  isFirst: boolean;
  companyName: string;
  workHistoryId: string;
  editVendorsList: Vendor[];
  onRemoveVendor: (workHistoryId: string, vendor: Vendor) => void;
  isCurrentJob: boolean;
}

export const EditExpertVendorItem: React.FC<EditExpertVendorItemProps> = ({
  isFirst,
  companyName,
  workHistoryId,
  editVendorsList,
  onRemoveVendor,
  isCurrentJob
}) => {
  const { t } = useTranslation("editExpertVendors");
  const dispatch = useDispatch();
  const vendorsCount = editVendorsList.length;
  const initialIcon = vendorsCount < 5 ? "icon_" + vendorsCount : "icon_5";
  const iconFeedback = SLOT_ICONS[initialIcon as keyof typeof SLOT_ICONS];
  const history = useHistory();

  const openSlidePanel = () =>
    dispatch(
      PanelStoreActions.openPanel(PanelId.AddVendor, {
        companyId: "",
        vendors: editVendorsList,
        workHistoryId
      })
    );

  return (
    <Drawer
      title={companyName}
      openByDefault={isFirst}
      backgroundColor={
        isCurrentJob
          ? ARBOLUS_COLORS.bColorBaseWhite
          : ARBOLUS_COLORS.bColorSecondaryGreyLightBackground
      }
    >
      <div className={styles.containerButtons}>
        {isCurrentJob && (
          <Button
            text={t("updateCurrentJobTitle")}
            type="tertiary"
            endIcon="chevron_right"
            onClick={() => history.push(EXPERT_EXPERIENCE_EDIT_TAB)}
          />
        )}

        <div className={styles.chipsAddButtonWrapper}>
          {editVendorsList.map((vendor) => (
            <Chip key={vendor.vendorCompanyId}>
              <div className={styles.wrapperChip}>
                <span className={styles.vendorName}>
                  {vendor.vendorCompanyName}
                </span>
                <Icon
                  name="close"
                  fontSize="16px"
                  color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
                  onClick={() => onRemoveVendor(workHistoryId, vendor)}
                  disabled={!isCurrentJob}
                />
              </div>
            </Chip>
          ))}

          {isCurrentJob && (
            <Button
              text={t("addVendors")}
              type="tertiary"
              endIcon="add"
              onClick={openSlidePanel}
            />
          )}
        </div>
      </div>

      {isCurrentJob && (
        <>
          <HR margin={{ top: 3, bottom: 3 }} />
          <div className={styles.feedbackWrapper}>
            <IconCircleFeedback
              icon={iconFeedback}
              message={t("iconFeedbackText")}
            />
          </div>
        </>
      )}
    </Drawer>
  );
};
