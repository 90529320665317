import {
  PROJECT_BASE_ROUTE,
  PROJECT_CHAT,
  PROJECT_CHAT_BASE,
  PROJECT_DISCOVER_BASE
} from "@arbolus-technologies/routes";

const SINGLE_PROJECT_BASE_ROUTE = `${PROJECT_BASE_ROUTE}/:projectId`;
export const PROJECT_BASE = SINGLE_PROJECT_BASE_ROUTE;
export const PROJECT_FILES = `${SINGLE_PROJECT_BASE_ROUTE}/files`;
export const PROJECT_CALENDAR = `${SINGLE_PROJECT_BASE_ROUTE}/calendar`;
export const PROJECT_CANOPIES = `${SINGLE_PROJECT_BASE_ROUTE}/canopies`;
export const PROJECT_ANGLES = `${SINGLE_PROJECT_BASE_ROUTE}/angles`;
export const PROJECT_TRANSCRIPTS = `${SINGLE_PROJECT_BASE_ROUTE}/transcripts`;
export const PROJECT_SPEND = `${SINGLE_PROJECT_BASE_ROUTE}/spend`;
export const PROJECT_NEW_EVENT = `${SINGLE_PROJECT_BASE_ROUTE}/event`;
export const PROJECT_EVENT = `${SINGLE_PROJECT_BASE_ROUTE}/event/:eventId`;
export const EDIT_PROJECT = `${SINGLE_PROJECT_BASE_ROUTE}/edit`;
export const PROJECT_EXPERTS = `${SINGLE_PROJECT_BASE_ROUTE}/experts`;
export const PROJECT_BRIEF = `${SINGLE_PROJECT_BASE_ROUTE}/brief`;
export const PROJECT_REFER_EXPERT = `${SINGLE_PROJECT_BASE_ROUTE}/refer-expert`;
export const PROJECT_EXPERT_AVAILABILITY = `${SINGLE_PROJECT_BASE_ROUTE}/availability`;

export const PROJECT_EVENT_TRANSCRIPT = `${SINGLE_PROJECT_BASE_ROUTE}/files/transcript/:transcriptId`;

const PROJECT_BASE_ROUTE_NX = "/projects";
export const PROJECT_BASE_URL_NX = (): string => PROJECT_BASE_ROUTE_NX;

export const PROJECT_ROUTE = (projectId: string): string =>
  `${PROJECT_BASE_ROUTE}/${projectId}`;
export const PROJECT_CALENDAR_ROUTE = (projectId: string): string =>
  `${PROJECT_BASE_ROUTE}/${projectId}/calendar`;
export const PROJECT_CANOPIES_ROUTE = (projectId: string): string =>
  `${PROJECT_BASE_ROUTE}/${projectId}/canopies`;
export const PROJECT_ANGLES_ROUTE = (projectId: string): string =>
  `${PROJECT_BASE_ROUTE}/${projectId}/angles`;
export const PROJECT_TRANSCRIPTS_ROUTE = (projectId: string): string =>
  `${PROJECT_BASE_ROUTE}/${projectId}/transcripts`;
export const PROJECT_FILES_ROUTE = (projectId: string): string =>
  `${PROJECT_BASE_ROUTE}/${projectId}/files`;
export const PROJECT_NEW_EVENT_ROUTE = (projectId: string): string =>
  `${PROJECT_BASE_ROUTE}/${projectId}/event`;
export const PROJECT_EVENT_ROUTE = (projectId: string, eventId = ""): string =>
  `${PROJECT_BASE_ROUTE}/${projectId}/event/${eventId}`;
export const PROJECT_EDIT_ROUTE = (projectId: string): string =>
  `${PROJECT_BASE_ROUTE}/${projectId}/edit`;
export const PROJECT_EXPERTS_ROUTE = (
  projectId: string,
  referralState = ""
): string =>
  `${PROJECT_BASE_ROUTE}/${projectId}/experts?referralState=${referralState}`;
export const PROJECT_BRIEF_ROUTE = (projectId: string): string =>
  `${PROJECT_BASE_ROUTE}/${projectId}/brief`;
export const PROJECT_REFER_EXPERT_ROUTE = (projectId: string): string =>
  `${PROJECT_BASE_ROUTE}/${projectId}/refer-expert`;

const PROJECT_BASE_TABS: string[] = [
  PROJECT_BASE,
  PROJECT_DISCOVER_BASE,
  PROJECT_FILES,
  PROJECT_CALENDAR,
  PROJECT_CANOPIES,
  PROJECT_ANGLES
];

const PROJECT_CLIENT_TABS = [PROJECT_NEW_EVENT, PROJECT_EVENT, PROJECT_SPEND];

export const PROJECT_TABS = [...PROJECT_BASE_TABS, ...PROJECT_CLIENT_TABS];

export const PROJECT_COMMON_ROUTES: string[] = [
  ...PROJECT_BASE_TABS,
  PROJECT_BRIEF,
  PROJECT_EVENT_TRANSCRIPT,
  PROJECT_TRANSCRIPTS,
  PROJECT_CHAT_BASE,
  PROJECT_CHAT
];

export const PROJECT_CLIENT_ROUTES = [
  EDIT_PROJECT,
  PROJECT_EXPERTS,
  ...PROJECT_CLIENT_TABS
];

export const PROJECT_EXPERT_ROUTES = [PROJECT_REFER_EXPERT];

export const PROJECT_ROUTES: string[] = [
  ...PROJECT_COMMON_ROUTES,
  ...PROJECT_CLIENT_ROUTES,
  ...PROJECT_EXPERT_ROUTES
];
