import React from "react";
import { useTranslation } from "react-i18next";

export default function ExpertInsightBanner() {
  const { t } = useTranslation("expertProfileEditPage");

  return (
    <div className="insight-guide-box">
      <div className="guide-container">
        <h3>{t("guideToInsightTitle")}</h3>
        <p>
          {t("guideTextFirstPart") + " "}
          <b>{t("guideTextSecondPart")}</b>
        </p>
      </div>
    </div>
  );
}
