import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SelectOption } from "@arbolus-technologies/models/common";
import { CustomSelect } from "@arbolus-technologies/ui/components";
import { TimezoneService } from "@arbolus-technologies/utils";

import styles from "./AvailabilityTimezone.module.scss";

interface AvailabilityTimezoneProps {
  projectTimezone: string;
  expertTimezone: string;
  timezones: Map<string, SelectOption>;
  handleTimezoneChange: (value: string) => void;
}

export const AvailabilityTimezone: React.FC<AvailabilityTimezoneProps> = ({
  projectTimezone,
  expertTimezone,
  timezones,
  handleTimezoneChange
}) => {
  const { t } = useTranslation("expertApplications");

  const [timezoneDifference, setTimezoneDifference] = useState<string>("");
  const [showTooltip, setShowTooltip] = useState<boolean>(true);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setShowTooltip(false);
    }, 5000);

    return () => {
      clearTimeout(timeOutId);
    };
  }, []);

  useEffect(() => {
    const timeDifferenceText = TimezoneService.getTimezoneDifference(
      expertTimezone,
      projectTimezone
    );

    setTimezoneDifference(timeDifferenceText);
  }, [projectTimezone, expertTimezone]);

  return (
    <div className={styles.timezoneContainer}>
      <div>{t("timezoneTitle")}</div>
      <div className={styles.dropdownContainer}>
        <span
          data-title={t("timezoneTooltip")}
          className={clsx(
            styles.dropdownWrapper,
            showTooltip ? styles.show : ""
          )}
        >
          <Icon name="public" fontSize="24px" />
          <CustomSelect
            options={Array.from(timezones.values())}
            defaultValue={timezones.get(expertTimezone)}
            onSelectChange={handleTimezoneChange}
            noOptionsMessage={t("noTimezone")}
            displayIcon
            isSearchable
            placeholder={t("timezoneNotSet")}
            classNamePrefix="ciqs-select"
          />
        </span>
        <p className={styles.timezoneDifference}>{timezoneDifference}</p>
      </div>
    </div>
  );
};
