/* eslint-disable react/no-danger */
import clsx from "clsx";
import React, { useState } from "react";

import Approve from "./Approve/Approve";

import styles from "./ActionBox.module.scss";

type ActionBoxType = "approve" | "confirmApprove" | "confirmReject";

interface ActionBoxInterface {
  type: ActionBoxType;
  title: string;
  description: string;
  icon: string;
  icon2: string;
  text: string;
  text2: string;
}

interface BoxInterface {
  approve: ActionBoxInterface;
  confirmApprove: ActionBoxInterface;
  confirmReject: ActionBoxInterface;
}

interface ActionBoxInterfaceProps {
  actionBoxData: BoxInterface;
  handleApprovedAnswers: () => void;
  handleRejectedAnswers: () => void;
}

export const ActionBox: React.FC<ActionBoxInterfaceProps> = ({
  actionBoxData,
  handleApprovedAnswers,
  handleRejectedAnswers
}) => {
  const { approve, confirmApprove, confirmReject } = actionBoxData;
  const [actionType, setActionType] = useState<ActionBoxType>("approve");
  const handleApprove = () => {
    setActionType("confirmApprove");
  };
  const handleReject = () => {
    setActionType("confirmReject");
  };
  const handleCancel = () => {
    setActionType("approve");
  };

  return (
    <div
      data-testid="ActionBoxContainer"
      className={clsx(styles.container, styles.iconContainer, styles.box)}
    >
      <div className={clsx(styles.flexContainer)}>
        {actionType === "approve" && (
          <Approve
            type={actionType}
            data={approve}
            handleAction={handleApprove}
            handleAction2={handleReject}
          />
        )}
        {actionType === "confirmApprove" && (
          <Approve
            type={actionType}
            data={confirmApprove}
            handleAction={handleCancel}
            handleAction2={handleApprovedAnswers}
          />
        )}
        {actionType === "confirmReject" && (
          <Approve
            type={actionType}
            data={confirmReject}
            handleAction={handleCancel}
            handleAction2={handleRejectedAnswers}
          />
        )}
      </div>
    </div>
  );
};
