import { ConfirmedEmailExpertModel } from "@arbolus-technologies/models/common";

interface Data {
  referralId: string;
  projectId: string;
  expert: ConfirmedEmailExpertModel;
}

export class RejectFeedbackExpert {
  private _data?: Data;

  get data(): Data | undefined {
    return this._data;
  }

  setData(value: Data): void {
    this._data = value;
  }
}

export default new RejectFeedbackExpert();
