import moment from "moment";
import { OutputSelector, createSelector } from "reselect";

import { TimeZone } from "@arbolus-technologies/api";
import { SelectOption } from "@arbolus-technologies/models/common";

import { REDIRECT_STATES } from "../../../constants/app";
import { INBOX_NOTIFICATION_CATEGORY } from "../../../constants/notifications";
import { Country, Industry, UserPermissions } from "../../../models/meta";
import { AppState } from "../../../store/reducers";
import { sortByName } from "./helpers";
import { AppReducerState, initialState } from "./reducer";

const appStateSelector = (state: AppState): AppReducerState =>
  state.app || initialState;

const appRedirectionTypeSelector = (): OutputSelector<
  AppState,
  REDIRECT_STATES,
  (res: AppReducerState) => REDIRECT_STATES
> =>
  createSelector<AppState, AppReducerState, REDIRECT_STATES>(
    appStateSelector,
    (appReducerState) => appReducerState.redirectionType
  );

const appCountriesSelector = (): OutputSelector<
  AppState,
  Country[],
  (res: AppReducerState) => Country[]
> =>
  createSelector<AppState, AppReducerState, Country[]>(
    appStateSelector,
    (appReducerState) => appReducerState.countries
  );

const appIndustriesSelector = (
  level?: number
): OutputSelector<AppState, Industry[], (res: AppReducerState) => Industry[]> =>
  createSelector<AppState, AppReducerState, Industry[]>(
    appStateSelector,
    (appReducerState) =>
      level === 0
        ? (sortByName(appReducerState.industriesLevel0) as Industry[])
        : (sortByName(appReducerState.industriesLevel2) as Industry[])
  );

const appIndustriesDropdownSelector = (
  level?: number
): OutputSelector<
  AppState,
  SelectOption[],
  (res: Industry[]) => SelectOption[]
> =>
  createSelector<AppState, Industry[], SelectOption[]>(
    appIndustriesSelector(level),
    (industriesReducerState) =>
      industriesReducerState.map((t) => ({
        value: t.id,
        label: t.name,
        customLabel: t.name
      }))
  );

const isAppMetaReadySelector = (): OutputSelector<
  AppState,
  boolean,
  (res: AppReducerState) => boolean
> =>
  createSelector<AppState, AppReducerState, boolean>(
    appStateSelector,
    ({ countries, timezones }) => countries.length > 0 && timezones.length > 0
  );

const appGuessCurrentTimeZoneSelector = (): OutputSelector<
  AppState,
  SelectOption,
  (res: AppReducerState) => SelectOption
> =>
  createSelector<AppState, AppReducerState, SelectOption>(
    appStateSelector,
    (projectReducerState) => {
      const currentTimeZone =
        projectReducerState.timezones.find(
          (tz) => tz.offset === moment().utcOffset()
        )! || projectReducerState.timezones[0];

      return {
        value: currentTimeZone.id,
        label: currentTimeZone.displayText,
        customLabel: currentTimeZone.displayText.slice(
          0,
          currentTimeZone.displayText.indexOf(" ")
        )
      };
    }
  );

const appTimeZoneSelector = (): OutputSelector<
  AppState,
  SelectOption[],
  (res: AppReducerState) => SelectOption[]
> =>
  createSelector<AppState, AppReducerState, SelectOption[]>(
    appStateSelector,
    (projectReducerState) =>
      projectReducerState.timezones.map((t) => ({
        value: t.id,
        label: t.displayText,
        customLabel: t.displayText.slice(0, t.displayText.indexOf(" "))
      }))
  );

const appCurrencySelector = (): OutputSelector<
  AppState,
  SelectOption[],
  (res: AppReducerState) => SelectOption[]
> =>
  createSelector<AppState, AppReducerState, SelectOption[]>(
    appStateSelector,
    (projectReducerState) =>
      projectReducerState.currencies.map((t) => ({
        value: t.isoCurrencyCode,
        label: t.isoCurrencyCode,
        customLabel: ""
      }))
  );

const appCountriesMapSelector = (): OutputSelector<
  AppState,
  Map<string, Country>,
  (res: AppReducerState) => Map<string, Country>
> =>
  createSelector<AppState, AppReducerState, Map<string, Country>>(
    appStateSelector,
    (appReducerState) =>
      new Map(
        appReducerState.countries.map((country) => [
          country.threeLetterCode,
          country
        ])
      )
  );

const appTimezoneMapSelector = (): OutputSelector<
  AppState,
  Map<string, TimeZone>,
  (res: AppReducerState) => Map<string, TimeZone>
> =>
  createSelector<AppState, AppReducerState, Map<string, TimeZone>>(
    appStateSelector,
    (appReducerState) =>
      new Map(
        appReducerState.timezones.map((timezone) => [timezone.id, timezone])
      )
  );

const appTimezoneSelectOptionMapSelector = (): OutputSelector<
  AppState,
  Map<string, SelectOption>,
  (res: AppReducerState) => Map<string, SelectOption>
> =>
  createSelector<AppState, AppReducerState, Map<string, SelectOption>>(
    appStateSelector,
    (appReducerState) =>
      new Map(
        appReducerState.timezones.map((timezone) => [
          timezone.id,
          {
            value: timezone.id,
            label: timezone.displayText,
            customLabel: timezone.displayText.slice(
              0,
              timezone.displayText.indexOf(" ")
            )
          }
        ])
      )
  );

const appInboxCategoriesSelector = (): OutputSelector<
  AppState,
  Map<INBOX_NOTIFICATION_CATEGORY, number>,
  (res: AppReducerState) => Map<INBOX_NOTIFICATION_CATEGORY, number>
> =>
  createSelector<
    AppState,
    AppReducerState,
    Map<INBOX_NOTIFICATION_CATEGORY, number>
  >(appStateSelector, ({ notificationCounts }) => notificationCounts);

const isFeatureFlagsReadySelector = (): OutputSelector<
  AppState,
  boolean,
  (res: AppReducerState) => boolean
> =>
  createSelector<AppState, AppReducerState, boolean>(
    appStateSelector,
    (appState) => appState.isFeatureFlagsReady
  );

const appUserPermissionsSelector = (): OutputSelector<
  AppState,
  UserPermissions,
  (res: AppReducerState) => UserPermissions
> =>
  createSelector<AppState, AppReducerState, UserPermissions>(
    appStateSelector,
    (appState) => appState.userPermissions
  );

export {
  appCountriesSelector,
  appIndustriesSelector,
  appIndustriesDropdownSelector,
  appGuessCurrentTimeZoneSelector,
  appTimeZoneSelector,
  isAppMetaReadySelector,
  appCurrencySelector,
  appCountriesMapSelector,
  appRedirectionTypeSelector,
  appTimezoneMapSelector,
  appTimezoneSelectOptionMapSelector,
  appInboxCategoriesSelector,
  isFeatureFlagsReadySelector,
  appUserPermissionsSelector
};
