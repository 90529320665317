import { useDispatch, useSelector } from "react-redux";

import {
  DiscoverFilterOption,
  DiscoverFilterType,
  ExpertDiscoverFilters
} from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  ProjectNxSelector,
  ProjectNxStoreActions
} from "@arbolus-technologies/stores/project";

interface UseDiscoverFiltersProps {
  filterType: DiscoverFilterType;
}

export interface UseDiscoverFilters {
  selectedOptions: DiscoverFilterOption[];
  preSelectedOptions: DiscoverFilterOption[];
  setSelectedOptions: (options: DiscoverFilterOption[]) => void;
  setPreSelectedOptions: (options: DiscoverFilterOption[]) => void;
  canClearFilter: boolean;
  clearFilter: () => void;
}

export const useDiscoverFilters = ({
  filterType
}: UseDiscoverFiltersProps): UseDiscoverFilters => {
  const dispatch = useDispatch();

  const discoverFilters = useSelector(ProjectNxSelector.discoverFilters());
  const isAdmin = useSelector(CacheSelector.isAdmin());
  const searchTerm = useSelector(
    ProjectNxSelector.getDiscoverExpertSearchTerm()
  );
  const discoverFiltersPreSelections = useSelector(
    ProjectNxSelector.discoverFiltersPreSelections()
  );

  const selectedOptions = filterType ? discoverFilters[filterType] : [];
  const preSelectedOptions = filterType
    ? discoverFiltersPreSelections[filterType]
    : [];

  const clearFilter = () => {
    handleRecommendations();
    dispatch(ProjectNxStoreActions.clearDiscoverFilter(filterType));
  };

  const setSelectedOptions = (options: DiscoverFilterOption[]) => {
    const noNewOptionsSelected = checkIfNoNewOptionsSelected(options);
    if (!noNewOptionsSelected) {
      handleRecommendations(options);
      dispatch(ProjectNxStoreActions.setDiscoverFilter(filterType, options));
    }
  };

  // to prevent api call without any changes
  const checkIfNoNewOptionsSelected = (options: DiscoverFilterOption[]) => {
    if (
      preSelectedOptions.length !== options.length ||
      selectedOptions.length !== options.length
    ) {
      return false;
    }

    const optionValues = options.map((option) => option.value);
    return selectedOptions
      .map((option) => option.value)
      .every((value) => optionValues.includes(value));
  };

  const handleRecommendations = (options?: DiscoverFilterOption[]) => {
    // handle recommendations toggle on filter basis
    const hasFilters = !!Object.keys(discoverFilters).find((key) => {
      if (key !== filterType) {
        return discoverFilters[key as keyof ExpertDiscoverFilters].length > 0;
      }
      return options ? options.length > 0 : false;
    });

    dispatch(
      ProjectNxStoreActions.toggleRecommendations(
        isAdmin ? !searchTerm && !hasFilters : false
      )
    );
  };

  const setPreSelectedOptions = (options: DiscoverFilterOption[]) => {
    dispatch(
      ProjectNxStoreActions.setDiscoverFilterPreSelections(filterType, options)
    );
  };

  return {
    selectedOptions,
    preSelectedOptions,
    setSelectedOptions,
    setPreSelectedOptions,
    canClearFilter: selectedOptions.length > 0,
    clearFilter
  };
};
