import {
  FilterModel,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest
} from "ag-grid-community";

import {
  AdminExpertListRequest,
  AdminService,
  SORT_DIRECTION,
  getMultiSortParams
} from "@arbolus-technologies/api";

export class ExpertTableDataSource implements IServerSideDatasource {
  getRows(params: IServerSideGetRowsParams): void {
    const { success, fail, request } = params;

    AdminService.getExperts(
      getMultiSortParams(this.getQueryParams(request))
    ).subscribe({
      next: (response) => {
        success({
          rowData: response.items,
          rowCount: response.pagination.count
        });
      },
      error: () => fail()
    });
  }

  private getQueryParams(
    request: IServerSideGetRowsRequest
  ): AdminExpertListRequest {
    const { filterModel, sortModel } = request;

    const { offset, limit } = this.getPagination(request);

    // Keys equal to column IDs in column definitions
    const {
      expertName = {},
      phoneNumber = {},
      email = {},
      isoCountryCode = {},
      title = {},
      doNotContactStatus = {}
    } = filterModel as FilterModel;

    const queryParams: AdminExpertListRequest = {
      fullName: expertName.filter,
      phoneNumber: phoneNumber.filter,
      email: email.filter,
      title: title.filter,
      isoCountryCodes: isoCountryCode.values,
      offset,
      limit,
      doNotContactStatus: doNotContactStatus.values,
      sort:
        sortModel?.length > 0
          ? sortModel.map(({ colId, sort }) => ({
              orderBy: colId,
              orderDirection: sort as SORT_DIRECTION
            }))
          : []
    };

    return queryParams;
  }

  private getPagination(request: IServerSideGetRowsRequest) {
    const { startRow, endRow } = request;
    const queryParams = {
      limit: endRow! - startRow!,
      offset: startRow!
    };
    return queryParams;
  }
}
