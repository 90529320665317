import { EditExpertVendors } from "@arbolus-technologies/features/expert-profile";

import ExpertInsightBanner from "../expertInsightBanner/ExpertInsightBanner";

export default function ExpertInsightsPane({ expertId }: { expertId: string }) {
  return (
    <div style={{ maxWidth: "720px", margin: "0 auto" }}>
      <ExpertInsightBanner />
      <EditExpertVendors expertId={expertId} />
    </div>
  );
}
