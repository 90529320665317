import i18next from "i18next";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  DefaultToasterService,
  ReferralDetail
} from "@arbolus-technologies/api";
import { SelectAvailabilitySlidePanel } from "@arbolus-technologies/features/common";
import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import { ChatType, ChatUserRole } from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

import { useChat } from "../../../Contexts/ChatContext/ChatContext";
import { useGetReferral } from "../../../Hooks/Chat/useGetReferral";
import { getChatToExpertErrors, isChatToExpertEnabled } from "../utils";
import { ChatEditorMemoized } from "./ChatEditor/ChatEditor";
import { ChatHeader } from "./ChatHeader/ChatHeader";
import { ChatMessages } from "./ChatMessages/ChatMessages";

import styles from "./ChatContent.module.scss";

interface ChatContentProps {
  handleOpenExpertSidePanel: (expertId: string, referralId: string) => void;
}

export const ChatContent: React.FC<ChatContentProps> = ({
  handleOpenExpertSidePanel
}) => {
  const [isAvailabilityPanelOpen, setIsAvailabilityPanelOpen] = useState(false);

  const { chatType, currentChat, projectData, projectId, chatSenderRole } =
    useChat();

  const loggedInUser = useSelector(CacheSelector.loggedInUser());

  const isExpert = chatSenderRole === ChatUserRole.Expert;
  // Load referral only if current user NOT is an expert
  const { isLoadingReferral, referral } = useGetReferral(
    isExpert ? undefined : projectId,
    isExpert ? undefined : currentChat?.referralId
  );
  const hasComplianceCheck = Boolean(projectData?.hasComplianceCheck);

  const doNotContactStatus = isExpert
    ? loggedInUser.doNotContactStatus
    : referral?.expert.doNotContactStatus;

  const isDncExpertChat = doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC;

  const { chatEnabled, chatErrors } = checkChatAvailability(
    chatType,
    referral,
    hasComplianceCheck,
    isDncExpertChat
  );

  const handleOpenAvailabilityPanel = () => {
    setIsAvailabilityPanelOpen(true);
  };

  if (!currentChat || !projectData) return null;

  return (
    <div className={styles.chatContent}>
      <ChatHeader
        chatType={chatType!}
        isLoadingReferral={isLoadingReferral}
        canBook={chatEnabled}
        openAvailabilityPanel={handleOpenAvailabilityPanel}
        handleOpenExpertSidePanel={handleOpenExpertSidePanel}
        doNotContactStatus={referral?.expert.doNotContactStatus}
      />
      <ChatMessages
        openAvailabilityPanel={handleOpenAvailabilityPanel}
        isLoadingReferral={isLoadingReferral}
        chatErrors={chatErrors}
        isDncExpertChat={isDncExpertChat}
      />
      <ChatEditorMemoized projectId={projectId!} isDisabled={!chatEnabled} />
      <SelectAvailabilitySlidePanel
        chatId={currentChat.id}
        projectId={projectId ?? ""}
        projectName={projectData.name}
        projectTimezone={projectData.timezone}
        expertId={currentChat.expertId ?? ""}
        referral={referral}
        isOpen={isAvailabilityPanelOpen}
        setIsOpen={setIsAvailabilityPanelOpen}
        showHeader={false}
      />
    </div>
  );
};

function checkChatAvailability(
  chatType: ChatType | undefined,
  referral: ReferralDetail | undefined,
  hasComplianceCheck: boolean,
  isDncExpertChat: boolean
) {
  if (!chatType) {
    DefaultToasterService.showError(i18next.t("chat:unknownChatError"));
    return { chatEnabled: false, chatErrors: null };
  }

  const isWritingToExpert = [
    ChatType.AdminToExpertSupport,
    ChatType.AdminToExpertAndClient,
    ChatType.ClientToExpertAndAdmin
  ].includes(chatType);

  const isExpertWriting = [
    ChatType.ExpertToAdminTeam,
    ChatType.ExpertToProject
  ];

  if (isExpertWriting && isDncExpertChat) {
    return { chatEnabled: false, chatErrors: null };
  }

  if (!isWritingToExpert) {
    return { chatEnabled: true, chatErrors: null };
  }

  try {
    if (isWritingToExpert && !referral) {
      return { chatEnabled: false, chatErrors: null };
    }

    const chatEnabled = isWritingToExpert
      ? isChatToExpertEnabled(referral!, hasComplianceCheck, isDncExpertChat)
      : true;
    const chatErrors = isWritingToExpert
      ? getChatToExpertErrors(referral!, hasComplianceCheck, isDncExpertChat)
      : null;
    return { chatEnabled, chatErrors };
  } catch (error) {
    DefaultToasterService.showError(i18next.t("chat:chatAvailabilityError"));
    return {
      chatEnabled: false,
      chatErrors: null
    };
  }
}
