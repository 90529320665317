import React from "react";
import { useSelector } from "react-redux";

import { CanopyExpertSelector } from "@arbolus-technologies/stores/canopy-expert";

import styles from "./VideoInfo.module.scss";

export const VideoInfo: React.FC = () => {
  const questionInfo = useSelector(CanopyExpertSelector.questionToRecordInfo());

  return questionInfo ? (
    <div className={styles.container}>
      <span className={styles.title}>{questionInfo?.title}</span>
      <div className={styles.detailsContainer}>
        <span className={styles.details}>{questionInfo?.details}</span>
      </div>
    </div>
  ) : null;
};
