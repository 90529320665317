import clsx from "clsx";
import { Field, FieldProps, getIn, useField } from "formik";
import React from "react";
import { Button, ButtonGroup, Col, FormGroup, Row } from "reactstrap";
import * as Yup from "yup";

import { Elevation } from "@arbolus-technologies/theme";

import { ButtonValue } from "../../../../models/view/project";

interface RadioButtonGroupProps {
  questionSchema?: Yup.ObjectSchema;
  fieldKey: string;
  buttonValues: ButtonValue[];
  customClass?: string;
  onValueChange?: (value: string) => void;
}

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  questionSchema,
  fieldKey,
  buttonValues,
  customClass,
  onValueChange
}) => {
  const [, fieldMeta] = useField<string>(fieldKey);
  const error = getIn(fieldMeta.error, fieldKey);
  const isError = error && getIn(fieldMeta.touched, fieldKey);

  const validateAsync = async (value: string): Promise<string | null> => {
    try {
      await questionSchema?.validate({ value });
      return null;
    } catch (err) {
      return err.message;
    }
  };

  return (
    <Row className="question-item">
      <Col>
        <FormGroup
          className={clsx({
            "is-invalid": isError
          })}
        >
          <Field name={fieldKey} validate={validateAsync}>
            {({
              field: { value },
              form: { setFieldValue }
            }: FieldProps): JSX.Element => (
              <ButtonGroup className={`custom-radio-btn-group ${customClass}`}>
                {buttonValues.map((b) => (
                  <Button
                    key={b.buttonText}
                    style={{ zIndex: Elevation.Z_INDEX_0 }}
                    onClick={(): void => {
                      onValueChange?.(b.value);
                      setFieldValue(fieldKey, b.value);
                    }}
                    active={value === b.value}
                  >
                    {b.buttonText}
                  </Button>
                ))}
              </ButtonGroup>
            )}
          </Field>
          {isError && <div className="invalid-feedback">{error}</div>}
        </FormGroup>
      </Col>
    </Row>
  );
};

export default RadioButtonGroup;
