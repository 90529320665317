import { EXPERT_SELF_PROFILE_EDIT } from "@arbolus-technologies/routes";

export const mainRoute = "/";
export const projectsRoute = "/projects";
export const canopiesRoute = "/canopies";
export const expertCanopyRoute = "/expert-canopies";
export const bookmarksRoute = "/bookmarks";
export const clientsAdRoute = "/clients";
export const clientsRoute = "/client-network";
export const clientAccountRoute = "/client-account";
export const teamMembersRoute = "/users";
export const inboxRoute = "/inbox";
export const profileEditRoute = EXPERT_SELF_PROFILE_EDIT;
export const expertNetworkRoute = "/expert-network";
export const accountRoute = "/account";
export const accountRouteAdmin = "/client-account";
export const accountRouteForAdmin = (clientId: string): string =>
  `/client/${clientId}/details`;
export const transactionsRoute = "/transactions";
export const invoicesRoute = "/invoices";
