import clsx from "clsx";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { BackButton } from "../../../../Buttons/components/BackButton/BackButton";

import styles from "./MobileHeader.module.scss";

export interface MobileHeaderProps {
  goBackRoute: string;
  children?: ReactNode;
  classnames?: string;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({
  goBackRoute,
  children,
  classnames
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const renderHeader = () => (
    <div className={clsx(styles.container, classnames)}>
      <BackButton
        title={t("common:header:back")}
        onClick={() => history.push(goBackRoute)}
      />
      {children}
    </div>
  );

  return renderHeader();
};
