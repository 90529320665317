import { useState } from "react";

interface UseCheckCamera {
  getCameraStatus: () => void;
  isCameraAvailable: boolean | null;
}

export const useCheckCamera = (): UseCheckCamera => {
  const [isCameraAvailable, setIsCameraAvailable] = useState<boolean>(true);

  const getCameraStatus = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: true
      })
      .then((stream) => {
        const videoTracks = stream.getVideoTracks();
        // videoTracks.length === 0 => No video track available
        if (videoTracks.length === 0) {
          setIsCameraAvailable(false);
        }
        if (videoTracks.length > 0) {
          const track = videoTracks[0];
          if (!track.enabled || track.readyState === "ended") {
            setIsCameraAvailable(false);
          } else {
            // Check camera status every time there is a physical device change
            navigator.mediaDevices.ondevicechange = () => getCameraStatus();

            // Listen for the camera track's "ended" event
            track.addEventListener("ended", function () {
              setIsCameraAvailable(false);
            });
          }
        }
        return stream.getVideoTracks().forEach((track) => track.stop());
      })
      .catch(() => {
        // Camera not available getUserMedia error
        setIsCameraAvailable(false);
      });
  };

  return {
    getCameraStatus,
    isCameraAvailable
  };
};
