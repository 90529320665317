import { useFeatures } from "flagged";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  ArbolusFeatureFlags,
  DO_NOT_CONTACT_STATUS,
  UserRole
} from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

import { sideBarItemsAdminEnv } from "./MenuItemsAdminEnv";
import { sideBarItemsClientEnv } from "./MenuItemsClientEnv";
import { ISideBarItems, ISubItems } from "./MenuItemsConfig";

export const useMenuItems = (
  isAdmin: boolean,
  userRole: UserRole,
  clientId?: string
): {
  menuItems: ISideBarItems[];
} => {
  const [menuItems, setMenuItems] = useState<ISideBarItems[]>([]);
  const features = useFeatures() as ArbolusFeatureFlags;

  const loggedInUser = useSelector(CacheSelector.loggedInUser());
  const isLoggedInUserExpert = !!loggedInUser.expertId;
  const isDncExpert =
    isLoggedInUserExpert &&
    loggedInUser.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC;

  useEffect(() => {
    const items =
      isAdmin && clientId
        ? sideBarItemsAdminEnv(clientId)
        : filterItemsByUserRole(sideBarItemsClientEnv(), userRole);

    items.forEach((item) => {
      item.subItems = item.subItems?.filter((subItem) =>
        filterByFeatureFlag(subItem, features)
      );
    });

    setMenuItems(
      items.filter((item) => {
        if (item.name === "Inbox" && isDncExpert) {
          return false;
        }
        return filterByFeatureFlag(item, features);
      })
    );
  }, [clientId, features, isAdmin, isDncExpert, userRole]);

  return { menuItems };
};

const filterItemsByUserRole = (items: ISideBarItems[], userRole: UserRole) =>
  items.filter((item: ISideBarItems) => {
    if (item.userRole?.includes(userRole)) {
      if (item.subItems && item.subItems.length > 0) {
        item.subItems = filterItemsByUserRole(item.subItems, userRole);
      }
      return true;
    }
    return false;
  });

const filterByFeatureFlag = (
  item: ISideBarItems | ISubItems,
  features: ArbolusFeatureFlags
) => {
  if (!item.featureFlag) {
    return true;
  }

  return features[item.featureFlag];
};
