export const ALLOWED_RICH_TEXT_ELEMENTS = [
  "p",
  "strong",
  "ul",
  "ol",
  "li",
  "em",
  "br",
  "ins"
];
