import { DateString } from "@arbolus-technologies/models/common";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

export const PublicCompanyIconColors = {
  current: ARBOLUS_COLORS.bColorBaseOrange,
  former: ARBOLUS_COLORS.bColorBasePurple
};

export const PrivateCompanyIconColor = ARBOLUS_COLORS.bColorSecondaryGreyDark;
export const UnknownCompanyIconColor = ARBOLUS_COLORS.bColorSecondaryAmber;

export const getPublicBadgeType = (
  date?: DateString | Date | null
): "current" | "former" => {
  if (!date) {
    return "current";
  }
  const now = new Date();

  const formattedDate = date instanceof Date ? date : new Date(date);
  return now.getFullYear() === formattedDate.getFullYear() &&
    now.getMonth() === formattedDate.getMonth()
    ? "current"
    : "former";
};
