import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { PageStep } from "@arbolus-technologies/models/common";

import styles from "./PageWithStepsFooter.module.scss";

interface PageWithStepsFooterProps {
  steps: PageStep[];
  currentStep: number;
  setCurrentStep: (step: number) => void;
  onAfterLastStep: () => void;
  canGoToNextStep: boolean;
  isProcessingLastStep: boolean;
}
export const PageWithStepsFooter: React.FC<PageWithStepsFooterProps> = ({
  steps,
  currentStep,
  setCurrentStep,
  onAfterLastStep,
  canGoToNextStep,
  isProcessingLastStep
}) => {
  const { t } = useTranslation("pageWithSteps");
  const history = useHistory();

  const handlePrev = () => {
    if (currentStep === 0) return history.goBack();
    setCurrentStep(currentStep - 1);
  };

  const handleNext = () => {
    if (currentStep === steps.length - 1) return onAfterLastStep();
    setCurrentStep(currentStep + 1);
  };

  return (
    <div className={styles.footer}>
      <Button type="tertiary" text={t("back")} onClick={handlePrev} />
      <Button
        text={steps[currentStep].nextStepButtonText ?? t("next")}
        onClick={handleNext}
        disabled={!canGoToNextStep || isProcessingLastStep}
      />
    </div>
  );
};
