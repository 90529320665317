/* eslint-disable @typescript-eslint/no-empty-function */
import { EditorState } from "draft-js";
import React from "react";

import { Event } from "../../models/event";
import { Monologue, TranscriptHighLight } from "../../models/transcript";
import { Speaker, TranscriptMetaState } from "../../models/view/transcript";

export enum TranscriptMode {
  FULL_TRANSCRIPT,
  HIGHLIGHTS
}

export interface TranscriptContextProps {
  mode: TranscriptMode;
  transcriptMeta: TranscriptMetaState;
  monologues: Map<string, Monologue>;
  event: Event;
  setTranscriptMode: (mode: TranscriptMode) => void;
  editorState?: EditorState;
  speakersList: Speaker[];
  speakersMap?: Map<string, Speaker>;
  highlightsCount: number;
  loadingSpeakers: string[];
  updateSpeakerAssignment: (
    speakerId: string,
    speaker: Speaker,
    isNewSpeaker: boolean
  ) => void;
  setEditorState: (editorState: EditorState) => void;
  resetEditorSelection: () => void;
  toggleMonologueText: (monologueId: string, isFullMode: boolean) => void;
  updateHighlights: (
    updatedEditorState: EditorState,
    monologueId: string,
    removedHighlights: string[],
    newHighlight?: TranscriptHighLight
  ) => void;
}

const defaultTranscriptContext: TranscriptContextProps = {
  event: {} as Event,
  transcriptMeta: {} as TranscriptMetaState,
  monologues: new Map(),
  mode: TranscriptMode.FULL_TRANSCRIPT,
  setTranscriptMode: () => {},
  speakersList: [],
  loadingSpeakers: [],
  updateSpeakerAssignment: () => {},
  setEditorState: () => {},
  resetEditorSelection: () => {},
  highlightsCount: 0,
  toggleMonologueText: () => {},
  updateHighlights: () => {}
};

export const TranscriptContext = React.createContext<TranscriptContextProps>(
  defaultTranscriptContext
);

export const TranscriptContextProvider = TranscriptContext.Provider;
export const TranscriptContextConsumer = TranscriptContext.Consumer;
