import React from "react";

import styles from "./EmptyComponent.module.scss";

interface EmptyComponentProps {
  title: string;
  subtitle: string;
  logo: string;
  children?: React.ReactNode;
}
export const EmptyComponent: React.FC<EmptyComponentProps> = ({
  title,
  logo,
  subtitle,
  children
}) => (
  <div className={styles.container}>
    <div className={styles.mainComponent}>
      <div className={styles.imageTitleContainer}>
        <img className={styles.image} src={logo} alt="title" />
        <span className={styles.title}>{title}</span>
      </div>
      <div className={styles.subtitle}>{subtitle}</div>
    </div>
    {children && <div className={styles.childrenContainer}>{children}</div>}
  </div>
);
