import {
  SurveyCompliancePage,
  SurveyFeedbackPage,
  SurveyLandingPage
} from "@arbolus-technologies/features/surveys";
import {
  SURVEY,
  SURVEY_COMPLIANCE,
  SURVEY_FEEDBACK
} from "@arbolus-technologies/routes";
import { Route } from "react-router";

export const SharedRouter: React.FC = () => {
  return (
    <>
      <Route exact path={SURVEY} component={SurveyLandingPage} />
      <Route exact path={SURVEY_COMPLIANCE} component={SurveyCompliancePage} />
      <Route exact path={SURVEY_FEEDBACK} component={SurveyFeedbackPage} />
    </>
  );
};
