import { Icon } from "arbolus-ui-components";
import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./Badge.module.scss";

interface BadgeProps {
  text: string;
  background: string;
  textStyle?: "lowercase" | "uppercase" | "capitalize";
  borderColor?: string;
  fontColor?: string;
  fontWeight?: number;
  customStyle?: object;
  children?: JSX.Element;
  onClose?: () => void;
  onClick?: () => void;
}
export const Badge: React.FC<BadgeProps> = ({
  text,
  background,
  textStyle,
  borderColor,
  fontColor,
  fontWeight,
  customStyle,
  children,
  onClose,
  onClick
}) => (
  <span
    className={styles.badge}
    onClick={onClick}
    style={{
      ...customStyle,
      background,
      textTransform: textStyle,
      border: `1px solid ${borderColor}`,
      color: fontColor,
      fontWeight: fontWeight,
      cursor: onClick && "pointer"
    }}
  >
    {children}
    <span className={styles.text} title={text}>
      {text}
    </span>
    {onClose && (
      <div className={styles.closeIcon}>
        <Icon
          name="close"
          color={ARBOLUS_COLORS.bColorBaseDark}
          fontSize="20px"
          onClick={onClose}
        />
      </div>
    )}
  </span>
);
