import React from "react";

import { ShortlistTable } from "../../Modules/ReferralsTable/ShortlistTable";
import { ReferralsPage } from "./ReferralsPage";

export const ShortlistPage: React.FC = () => (
  <ReferralsPage page="shortlist">
    <ShortlistTable />
  </ReferralsPage>
);
