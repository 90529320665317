import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  EventService,
  LIST_EVENTS_ORDER_BY,
  ListEventModel,
  ListEventsParams,
  PaginationData,
  SORT_DIRECTION,
  ToasterService
} from "@arbolus-technologies/api";
import { useDisplayTimezone } from "@arbolus-technologies/features/common";
import {
  EXPERT_PROFILE,
  PROJECT_CALENDAR_ROUTE,
  PROJECT_EVENT
} from "@arbolus-technologies/routes";
import {
  EventCard,
  LoaderOrComponent,
  ProjectEmptyEvents
} from "@arbolus-technologies/ui/components";
import { GrayBox } from "@arbolus-technologies/ui/layout";

import { Pagination } from "../../Components/Pagination/Pagination";
import { useAngleQuery } from "../../Hooks/useAngleQuery";
import { getCurrentAngleId } from "../../helpers";

interface ProjectEventsProps {
  projectId: string;
  timezone: string;
  eventService?: typeof EventService;
  notificationService?: ToasterService;
}

const queryParams: ListEventsParams = {
  limit: 3,
  offset: 0,
  excludePastEvents: true,
  orderBy: LIST_EVENTS_ORDER_BY.StartTime,
  orderDirection: SORT_DIRECTION.ASCENDING
};

export const ProjectEvents: React.FC<ProjectEventsProps> = ({
  projectId,
  timezone,
  eventService = EventService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("projectEvents");
  const { push } = useHistory();
  const angleId = useAngleQuery();

  const displayTimezone = useDisplayTimezone(timezone);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [events, setEvents] = useState<ListEventModel[]>([]);
  const [paginationDetails, setPaginationDetails] = useState<
    Omit<PaginationData, "offset">
  >({ limit: 0, count: 0 });
  const [isLoading, setIsLoading] = useState(true);

  const fetchEvents = useCallback(
    (angleId?: string | null, offset = 0) => {
      setIsLoading(true);
      eventService
        .listEvents(projectId, {
          angleId,
          ...queryParams,
          offset
        })
        .subscribe(
          (response) => {
            setEvents(response.items);
            setPaginationDetails(response.pagination);
            setIsLoading(false);
          },
          (error: ErrorResponse<CIQError>) => {
            notificationService.showApiErrors(error);
            setIsLoading(false);
          }
        );
    },
    [eventService, notificationService, projectId]
  );

  useEffect(() => {
    if (getCurrentAngleId() === angleId) {
      fetchEvents(angleId, pageNumber * queryParams.limit);
    }
  }, [angleId, fetchEvents, pageNumber]);

  return (
    <GrayBox
      title={{
        text: t("title", { count: paginationDetails.count }),
        to: PROJECT_CALENDAR_ROUTE(projectId)
      }}
      subtitle={displayTimezone}
      flexDirection="column"
      gap={[2, 0]}
      actionComponent={
        <Pagination
          limit={paginationDetails.limit}
          count={paginationDetails.count}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      }
    >
      <LoaderOrComponent isLoading={isLoading}>
        {!events?.length && <ProjectEmptyEvents />}
        {events?.map((event) => (
          <EventCard
            key={event.id}
            event={event}
            angle={event.angle}
            expert={event.expert}
            onClick={() => push(PROJECT_EVENT(projectId, event.id))}
            toExpertProfile={
              event.expert ? EXPERT_PROFILE(event.expert?.expertId) : undefined
            }
          />
        ))}
      </LoaderOrComponent>
    </GrayBox>
  );
};
