import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React, { HTMLProps, useEffect, useRef } from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./SearchInputBase.module.scss";

interface CommonProps
  extends Omit<HTMLProps<HTMLInputElement>, "onChange" | "onKeyDown"> {
  className?: string;
  placeholder?: string;
  containerClassnames?: string;
  hasSearchIcon?: boolean;
  autoFocus?: boolean;
  handleClearQueryClicked: () => void;
  searchQuery: string;
  handleInputChangeEvent: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  handleKeyDown?: (
    event:
      | React.KeyboardEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLTextAreaElement>
  ) => void;
  disabled?: boolean;
  onInputFocus?: () => void;
  onInputBlur?: () => void;
}

interface InputProps extends CommonProps {
  multiline?: false;
}

interface TextareaProps extends Omit<CommonProps, "multiline"> {
  multiline: true;
}

type SearchInputBaseProps = InputProps | TextareaProps;

export const SearchInputBase: React.FC<SearchInputBaseProps> = ({
  className,
  placeholder,
  containerClassnames,
  hasSearchIcon = false,
  autoFocus = false,
  handleClearQueryClicked,
  searchQuery,
  handleInputChangeEvent,
  handleKeyDown,
  disabled = false,
  onInputFocus,
  onInputBlur,
  multiline = false
}) => {
  const hasValue = searchQuery.length > 0;
  const showSearchIcon = hasSearchIcon && !hasValue;

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (multiline && textareaRef.current) {
      adjustHeight(textareaRef.current);
    }
  }, [searchQuery, multiline]);

  const adjustHeight = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = "0";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    handleInputChangeEvent(event);
    if (multiline && event.target instanceof HTMLTextAreaElement) {
      adjustHeight(event.target);
    }
  };

  return (
    <div
      className={clsx(
        multiline ? styles.ciqSearchInputMultiLine : styles.ciqSearchInput,
        containerClassnames,
        {
          [styles.disabled]: disabled
        }
      )}
    >
      {hasValue && !multiline && (
        <div className={styles.icon}>
          <Icon
            name="close"
            color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
            fontSize="24px"
            onClick={handleClearQueryClicked}
          />
        </div>
      )}
      {showSearchIcon && (
        <div className={styles.icon}>
          <Icon
            fontSize="24px"
            color={ARBOLUS_COLORS.bColorGrayIcon}
            name="search"
          />
        </div>
      )}

      {multiline ? (
        <textarea
          ref={textareaRef}
          value={searchQuery}
          className={className}
          placeholder={placeholder}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          autoFocus={autoFocus}
          disabled={disabled}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
        />
      ) : (
        <input
          value={searchQuery}
          className={className}
          placeholder={placeholder}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          autoFocus={autoFocus}
          disabled={disabled}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
        />
      )}
    </div>
  );
};
