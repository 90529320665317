import i18next from "i18next";

import { SelectOption } from "@arbolus-technologies/models/common";

export const EXPERT_FOLLOW_UP_CHANNEL_NAME = "CanopyFollowUp";
export const NEW_FEATURE_REQUEST_CHANNEL_NAME = "NewFeaturesRequest";
export const CANOPY_AUDIO_REQUEST_CHANNEL_NAME = "CanopyAudioRequest";

export const EXPERT_EXPERIENCE: Map<string, SelectOption> = new Map([
  [
    "ManagerLevel",
    {
      label: i18next.t("expertConstants:manager"),
      customLabel: i18next.t("expertConstants:manager"),
      value: "ManagerLevel"
    }
  ],
  [
    "DirectorLevel",
    {
      label: i18next.t("expertConstants:director"),
      customLabel: i18next.t("expertConstants:director"),
      value: "DirectorLevel"
    }
  ],
  [
    "CSuiteLevel",
    {
      label: i18next.t("expertConstants:cSuite"),
      customLabel: i18next.t("expertConstants:cSuite"),
      value: "CSuiteLevel"
    }
  ]
]);
