import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import {
  DefaultToasterService,
  ToasterService,
  WebHooksService
} from "@arbolus-technologies/api";
import { CanopyParamUrlTypes } from "@arbolus-technologies/models/canopy";
import { ExpertProfile } from "@arbolus-technologies/models/canopy-panels";
import { EXPERT_FOLLOW_UP_STEPS } from "@arbolus-technologies/models/expert";
import { CANOPY_DETAILS_ROUTE_ADMIN } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { CanopyV2Selector } from "@arbolus-technologies/stores/canopy-V2";
import { ExpertStoreActions } from "@arbolus-technologies/stores/expert";
import {
  PanelId,
  PanelSelector,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { SlidePanel } from "../SlidePanel/SlidePanel";
import { ExpertFollowUpSteps } from "./ExpertFollowUpSteps/ExpertFollowUpSteps";

interface ExpertFollowUpSlidePanelProps {
  onPanelClose?: () => void;
  webHooksService?: typeof WebHooksService;
  notificationService?: ToasterService;
}

export const ExpertFollowUpSlidePanel: React.FC<
  ExpertFollowUpSlidePanelProps
> = ({
  onPanelClose,
  webHooksService = WebHooksService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("expertFollowUpSlidePanel");
  const dispatch = useDispatch();
  const { canopyId } = useParams<CanopyParamUrlTypes>();

  const loggedInUser = useSelector(CacheSelector.loggedInUser());
  const canopyTitle = useSelector(CanopyV2Selector.canopyTitle());
  const expertDetails = useSelector(
    PanelSelector.panelData<ExpertProfile>(PanelId.ExpertEngageWithMe)
  );

  const handleCloseSlidePanel = (): void => {
    dispatch(PanelStoreActions.closePanel(PanelId.ExpertEngageWithMe));
    dispatch(
      ExpertStoreActions.setExpertFollowUpStep(
        EXPERT_FOLLOW_UP_STEPS.ENGAGE_EXPERT
      )
    );
    onPanelClose?.();
  };

  const handleSendMessage = (question?: string) => {
    const clientName = `${loggedInUser.firstName} ${loggedInUser.lastName}`;
    const messageTitle = `<${CANOPY_DETAILS_ROUTE_ADMIN(
      canopyId
    )} | ${canopyTitle}>`;

    const message = question
      ? t("followUpQuestionsMessage", {
          clientName,
          clientEmail: loggedInUser.email,
          expertName: expertDetails.fullName,
          question
        })
      : t("followUpCallMessage", {
          clientName,
          clientEmail: loggedInUser.email,
          expertName: expertDetails.fullName
        });

    webHooksService
      .expertFollowUpSendMessage(`${messageTitle}\n${message}`)
      .subscribe(
        () => {
          notificationService.showSuccess(t("messageSentSuccess"));
          handleCloseSlidePanel();
        },
        () => {
          notificationService.showError(t("messageSentFailure"));
        }
      );
  };

  return (
    <SlidePanel
      panelId={PanelId.ExpertEngageWithMe}
      width={SIDE_PANEL_SIZE._720}
      title={t("askMore")}
      closeButtonDirection="right"
      customCloseRequest={handleCloseSlidePanel}
    >
      {expertDetails && (
        <ExpertFollowUpSteps
          expertDetails={expertDetails}
          canopyId={canopyId}
          onSendMessage={handleSendMessage}
        />
      )}
    </SlidePanel>
  );
};
