import { PROJECT_CALENDAR_ROUTE, PROJECT_ROUTE } from "./api";

export const EVENT_PANEL_HASH = "eventId";

export const SharedRoutes = {
  EXPERT_ENDPOINT: "/experts",
  EXPERT_NETWORK: "/expert-network",
  EVENT_PANEL_ROUTE: (eventId: string, projectId?: string): string =>
    `${
      projectId ? PROJECT_CALENDAR_ROUTE(projectId) : window.location.pathname
    }#${EVENT_PANEL_HASH}=${eventId}`,
  EDIT_AVAILABILITY_UPDATE_ROUTE: (
    isAdmin: boolean,
    projectId: string
  ): string =>
    `${isAdmin ? PROJECT_ROUTE(projectId) : PROJECT_CALENDAR_ROUTE(projectId)}`,
  PROJECT_EVENT_ROUTE: (projectId: string): string =>
    `/project/${projectId}/event`
};
