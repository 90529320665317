import { OutputSelector, createSelector } from "reselect";

import { TimeZone } from "@arbolus-technologies/api";
import { SelectOption } from "@arbolus-technologies/models/common";

import { CacheStateSelector } from "../CacheSelector";
import { CacheAppState, CacheReducerState } from "../models/definitions";

export const defaultTimezones = (): OutputSelector<
  CacheAppState,
  TimeZone[],
  (res: CacheReducerState) => TimeZone[]
> =>
  createSelector<CacheAppState, CacheReducerState, TimeZone[]>(
    CacheStateSelector,
    (cacheReducerState) => cacheReducerState.timezones
  );

export const timezones = (): OutputSelector<
  CacheAppState,
  SelectOption[],
  (res: CacheReducerState) => SelectOption[]
> =>
  createSelector<CacheAppState, CacheReducerState, SelectOption[]>(
    CacheStateSelector,
    (cacheReducerState) => {
      const arr: SelectOption[] = [];
      cacheReducerState.timezones.map((timezone) =>
        arr.push({ value: timezone.id, label: timezone.displayText })
      );
      return arr;
    }
  );

export const appTimezoneSelectOptionMapSelector = (): OutputSelector<
  CacheAppState,
  Map<string, SelectOption>,
  (res: CacheReducerState) => Map<string, SelectOption>
> =>
  createSelector<CacheAppState, CacheReducerState, Map<string, SelectOption>>(
    CacheStateSelector,
    (cacheReducerState) =>
      new Map(
        cacheReducerState.timezones.map((timezone) => [
          timezone.id,
          {
            value: timezone.id,
            label: timezone.displayText,
            customLabel: timezone.displayText.slice(
              0,
              timezone.displayText.indexOf(" ")
            )
          }
        ])
      )
  );

export const appGuessCurrentTimeZoneSelector = (): OutputSelector<
  CacheAppState,
  SelectOption,
  (res: CacheReducerState) => SelectOption
> =>
  createSelector<CacheAppState, CacheReducerState, SelectOption>(
    CacheStateSelector,
    (cacheReducerState) => {
      const currentTimeZone =
        cacheReducerState.timezones.find(
          (tz) => tz.offset === -new Date().getTimezoneOffset()
        )! || cacheReducerState.timezones[0];

      return {
        value: currentTimeZone.id,
        label: currentTimeZone.displayText,
        customLabel: currentTimeZone.displayText.slice(
          0,
          currentTimeZone.displayText.indexOf(" ")
        )
      };
    }
  );
