import clsx from "clsx";
import { LocationDescriptorObject } from "history";
import React from "react";
import { Link } from "react-router-dom";

import styles from "./ButtonNoBorderWithIcon.module.scss";

interface LinkNoBorderProps {
  name: string;
  to: string | LocationDescriptorObject;
  iconName?: string;
  leftIconName?: string;
  align?: "left" | "right" | "center";
  classnames?: string;
  disabled?: boolean;
  onClick?: () => void;
  rightIconClasses?: string;
  size?: "small" | "regular" | "large";
}
export const LinkNoBorder: React.FC<LinkNoBorderProps> = ({
  name,
  to,
  iconName,
  leftIconName,
  align,
  classnames,
  disabled = false,
  onClick,
  rightIconClasses,
  size = "large"
}) => (
  <Link
    to={to}
    onClick={onClick}
    className={clsx(
      styles.button,
      styles[align || ""],
      classnames,
      disabled && styles.disabled,
      styles[`size${size}`]
    )}
  >
    {leftIconName && (
      <span
        className={clsx(
          "material-symbols-sharp",
          styles.icon,
          styles[`icon${size}`]
        )}
      >
        {leftIconName}
      </span>
    )}
    {name}
    {iconName && (
      <span
        className={clsx(
          "material-symbols-sharp",
          styles.icon,
          rightIconClasses,
          styles[`icon${size}`]
        )}
      >
        {iconName}
      </span>
    )}
  </Link>
);
