import React, { useCallback, useEffect, useState } from "react";

import { ReferralDetail } from "@arbolus-technologies/api";
import { AvailableSlot } from "@arbolus-technologies/models/project";
import { SlotsList, UserPortrait } from "@arbolus-technologies/ui/components";
import { useFormatSlotsValues } from "@arbolus-technologies/utils";

import { AvailabilityExpired } from "../AvailabilityExpired/AvailabilityExpired";

import styles from "./EventAvailabilities.module.scss";

interface EventAvailabilitiesProps {
  projectTimezone: string;
  referral: ReferralDetail;
  onSlotSelected: (startTime: Date, endTime: Date) => void;
}

export const EventAvailabilities: React.FC<EventAvailabilitiesProps> = ({
  projectTimezone,
  referral,
  onSlotSelected
}) => {
  const [slots] = useState(
    referral.expertAvailabilitySlots?.filter(
      (s) => !s.isExpired && !s.isScheduled
    ) || []
  );
  const [availableSlots, setAvailableSlots] = useState<AvailableSlot[]>();

  const { getFormattedSlotsValues, sortFormattedSlotsValues } =
    useFormatSlotsValues(slots, projectTimezone);

  useEffect(() => {
    const slotsFormatted = getFormattedSlotsValues();
    const sortedSlots = sortFormattedSlotsValues(slotsFormatted);
    setAvailableSlots(sortedSlots);
  }, [getFormattedSlotsValues, sortFormattedSlotsValues, slots]);

  const handleSlotSelected = useCallback(
    (slotId: number) => {
      if (availableSlots === undefined) {
        return;
      }

      const updatedSlots = availableSlots.map((slot) => ({
        ...slot,
        slotSelected: slot.slotId === slotId ? !slot.slotSelected : false
      }));
      const selectedSlot = updatedSlots.find((slot) => slot.slotSelected)!;
      setAvailableSlots(updatedSlots);
      onSlotSelected(selectedSlot.slotStartValue, selectedSlot.slotEndValue);
    },
    [availableSlots, onSlotSelected]
  );

  return (
    <div className={styles.container}>
      <UserPortrait
        user={{
          ...referral.expert,
          title: referral.tagline || referral.expert.title
        }}
      />
      {slots.length > 0 ? (
        <SlotsList
          availableSlots={availableSlots ?? []}
          sliceSlots={availableSlots?.length ?? 0}
          handleSlotSelected={handleSlotSelected}
          disableTimeSlots={false}
        />
      ) : (
        <AvailabilityExpired referral={referral} />
      )}
    </div>
  );
};
