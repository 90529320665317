import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { NotificationBadge } from "../Badges/NotificationBadge/NotificationBadge";
import { CircleWithIcon } from "../CirceWithIcon/CircleWithIcon";

import styles from "./BoxButton.module.scss";

export interface BoxButtonProps {
  text: string;
  icon: {
    name: string;
    color: ARBOLUS_COLORS | string;
    background: ARBOLUS_COLORS | string;
  };
  subTitle?: string;
  onClick?: () => void;
  disabled?: boolean;
  chevronColor?: ARBOLUS_COLORS | string;
  displayRightIcon?: boolean;
  displayBadge?: boolean;
  badgeCount?: number;
  isPurple?: boolean;
  isSelected?: boolean;
}

export const BoxButton: React.FC<BoxButtonProps> = ({
  text,
  icon,
  subTitle,
  onClick,
  disabled,
  chevronColor = ARBOLUS_COLORS.bColorSecondaryGreyMid,
  displayRightIcon = true,
  displayBadge = false,
  badgeCount = 0,
  isPurple,
  isSelected
}) => (
  <button
    onClick={onClick}
    className={clsx(
      styles.container,
      styles.link,
      disabled && styles.disabled,
      isPurple && styles.isPurple,
      isSelected && styles.isSelected
    )}
  >
    <CircleWithIcon
      color={isPurple ? ARBOLUS_COLORS.bColorBasePurple : icon.color}
      icon={icon.name}
      fontSize="22px"
      background={isPurple ? ARBOLUS_COLORS.bColorBaseWhite : icon.background}
    />
    <div className={styles.body}>
      <div className={styles.textContainer}>
        <span>{text}</span>
      </div>
      <span className={styles.subtitleContainer}>{subTitle}</span>
    </div>
    <div className={styles.rightContainer}>
      {displayBadge && <NotificationBadge count={badgeCount} />}
      {displayRightIcon && (
        <div className={styles.chevronContainer}>
          <Icon
            disabled={disabled}
            name={isSelected ? "done" : "chevron_right"}
            fontSize="22px"
            color={isSelected ? ARBOLUS_COLORS.bColorBasePurple : chevronColor}
          />
        </div>
      )}
    </div>
  </button>
);
