import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody } from "reactstrap";

interface ReInviteModalProps {
  isModalOpen: boolean;
  isRequestLoading: boolean;
  onModalDismiss: () => void;
  onRequestInvite: () => void;
}

/**
 * @deprecated
 * use ArbolusModal from @arbolus-technologies/libs/ui/components
 */

const ReInviteModal: React.FC<ReInviteModalProps> = ({
  isModalOpen,
  isRequestLoading,
  onModalDismiss,
  onRequestInvite
}): JSX.Element => {
  const [t] = useTranslation("reinviteModal");

  return (
    <Modal isOpen={isModalOpen} className="reinvite-modal">
      <ModalBody>
        <div className="top-container">
          <span className="ciq-icon ciq-sent" />
        </div>
        <div className="middle-container">
          <h3>{t("title")}</h3>
          <p>{t("description")}</p>
        </div>
        <div className="bottom-container">
          <Button
            size="lg"
            color="primary"
            className="btn-bold"
            disabled={isRequestLoading}
            onClick={onRequestInvite}
          >
            {t("resendAction")}
          </Button>
          <Button
            size="lg"
            className="btn-bold"
            disabled={isRequestLoading}
            onClick={onModalDismiss}
          >
            {t("dismissAction")}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ReInviteModal;
