import i18next from "i18next";
import moment from "moment";
import * as Yup from "yup";

import { isDateAfter, isDayBefore } from "@arbolus-technologies/utils";

import { EventConstraints } from "../../../../constants/validation";

export const EventValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required(i18next.t("eventPage:titleRequired"))
    .min(
      EventConstraints.MIN_EVENT_NAME_LENGTH,
      i18next.t("eventPage:titleLengthMinError", {
        length: EventConstraints.MIN_EVENT_NAME_LENGTH
      })
    )
    .max(
      EventConstraints.MAX_EVENT_NAME_LENGTH,
      i18next.t("eventPage:titleLengthMaxError", {
        length: EventConstraints.MAX_EVENT_NAME_LENGTH
      })
    ),
  startDate: Yup.date()
    .required(i18next.t("eventPage:startDateRequired"))
    .typeError(i18next.t("eventPage:startDateRequired"))
    .test(
      "future-day",
      i18next.t("eventPage:futureDayError"),
      function validate() {
        const { startDate, timezone } = this.parent;
        return !isDayBefore(startDate, timezone.id);
      }
    ),
  startTime: Yup.date()
    .required(i18next.t("eventPage:startTimeRequired"))
    .typeError(i18next.t("eventPage:startTimeRequired"))
    .test(
      "future-event",
      i18next.t("eventPage:futureEventError"),
      function validate() {
        const { startDate, timezone } = this.parent;
        return isDateAfter(startDate, timezone.id);
      }
    ),
  endDate: Yup.date()
    .required(i18next.t("eventPage:endDateRequired"))
    .typeError(i18next.t("eventPage:endDateRequired"))
    .test(
      "equal-check",
      i18next.t("eventPage:endAndStartDatesTime"),
      function validate() {
        const { startDate, endDate, startTime, endTime } = this.parent;
        if (startDate && endDate && startTime && endTime) {
          const isSameOrAfter = moment(startDate)
            .startOf("minute")
            .isSameOrAfter(moment(endDate).startOf("minute"));
          return !isSameOrAfter;
        }
        return true;
      }
    )
    .test(
      "duration-check",
      i18next.t("eventPage:maxDuration", {
        limit: EventConstraints.MAX_EVENT_DURATION_IN_DAYS
      }),
      function validate() {
        const { startDate, endDate, startTime, endTime } = this.parent;
        if (startDate && endDate && startTime && endTime) {
          const durationExceed = moment(endDate).diff(
            moment(startDate),
            "seconds"
          );
          return !(
            durationExceed >
            EventConstraints.MAX_EVENT_DURATION_IN_DAYS * 24 * 60 * 60
          );
        }
        return true;
      }
    ),
  endTime: Yup.date()
    .required(i18next.t("eventPage:endTimeRequired"))
    .typeError(i18next.t("eventPage:endTimeRequired"))
    .test(
      "equal-check",
      i18next.t("eventPage:endAndStartDatesTime"),
      function validate() {
        const { startDate, endDate, startTime, endTime, timezone } =
          this.parent;
        if (startDate && endDate && startTime && endTime) {
          return isDateAfter(endDate, timezone.id, startDate);
        }
        return true;
      }
    ),
  location: Yup.string().max(
    EventConstraints.MAX_LOCATION_LENGTH,
    i18next.t("eventPage:locationLengthError", {
      length: EventConstraints.MAX_LOCATION_LENGTH
    })
  ),
  notes: Yup.string().max(
    EventConstraints.MAX_EVENT_NOTE_LENGTH,
    i18next.t("eventPage:notesLengthError", {
      length: EventConstraints.MAX_EVENT_NOTE_LENGTH
    })
  )
});
