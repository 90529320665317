import { ICellRendererParams } from "ag-grid-community";
import React, { useState } from "react";

import { DNCTag } from "@arbolus-technologies/ui/components";

import { ExpertDncHistorySlidePanel } from "../../../Panels/ExpertDncHistorySlidePanel/ExpertDncHistorySlidePanel";

import styles from "./DNCStatusTag.module.scss";

export function DNCStatusTag({ data }: Readonly<ICellRendererParams>) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <button className={styles.wrapper} onClick={() => setIsModalOpen(true)}>
        <DNCTag dncStatus={data.doNotContactStatus} skipOk={false} isAdmin />
      </button>
      <ExpertDncHistorySlidePanel
        expert={data}
        handleClosePanel={() => setIsModalOpen(false)}
        isModalOpen={isModalOpen}
      />
    </>
  );
}
