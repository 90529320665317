import { Icon } from "arbolus-ui-components";
import React from "react";

import styles from "./IconWithNumber.module.scss";

interface IconWithNumberProps {
  name: string;
  amount: number;
  color: string;
  tooltip?: string;
  iconSize?: string;
}

export const IconWithNumber: React.FC<IconWithNumberProps> = ({
  name,
  amount,
  color,
  tooltip,
  iconSize = "24px"
}) => (
  <div className={styles.status}>
    <Icon name={name} fontSize={iconSize} color={color} tooltip={tooltip} />
    <p className={styles.number} style={{ color }}>
      {amount}
    </p>
  </div>
);
