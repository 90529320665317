import React from "react";

import styles from "./TipaltiIframe.module.scss";

interface TipaltiIframeProps {
  src: string;
  title?: string;
}

export const TipaltiIframe: React.FC<TipaltiIframeProps> = ({
  src,
  title = "tipalti"
}) => (
  <div className={styles.container}>
    <iframe title={title} src={src} />
  </div>
);
