/* eslint-disable react/static-property-placement */
import { replace } from "connected-react-router";
import i18next from "i18next";
import queryString from "query-string";
import React from "react";
import {
  IGoogleReCaptchaConsumerProps,
  IWithGoogleReCaptchaProps,
  withGoogleReCaptcha
} from "react-google-recaptcha-v3";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Subscription } from "rxjs";

import { ToasterService } from "@arbolus-technologies/api";
import {
  AuthPageBase,
  MixPanelActions,
  MixPanelEventNames,
  MixpanelPages,
  trackEvent,
  trackPageView
} from "@arbolus-technologies/features/common";
import { Loader } from "@arbolus-technologies/ui/components";
import { utilService } from "@arbolus-technologies/utils";

import { REST_ERROR } from "../../../../constants/api";
import { RECAPTCHA_ACTIONS, RegisterStages } from "../../../../constants/auth";
import { REGISTER } from "../../../../constants/navigation/publicRoutes";
import { CIQError, ErrorResponse } from "../../../../models/api";
import { Invitation, InviteQueryParams, User } from "../../../../models/user";
import { UserService } from "../../../../services";
import { AppAction } from "../../../../store/actions";
import AcceptTerms from "../../components/onboarding/acceptTerm/AcceptTerm";
import CreateAccount from "../../components/onboarding/createAccount/CreateAccount";
import CompanyEnquiry from "../../components/onboarding/inquire/CompanyEnquiry";
import OnboardSelection from "../../components/onboarding/onboardingSelection/OnboardSelection";
import ReInviteModal from "../../components/reinviteModal/ReinviteModal";

const notification = new ToasterService();

interface RegisterPageProps {
  resetToInitial: () => void;
}

interface RegisterPageState {
  stage: RegisterStages;
  isInviteModalOpen: boolean;
  isReinviteLoading: boolean;
}

export interface ExpiredInvite {
  email: string;
  token: string;
}

type RegisterPageIntersectProps = RegisterPageProps &
  WithTranslation &
  IWithGoogleReCaptchaProps;

class RegisterPage extends React.Component<
  RegisterPageIntersectProps,
  RegisterPageState
> {
  static defaultProps = {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    resetToInitial: (): void => {},
    googleReCaptchaProps: {} as IGoogleReCaptchaConsumerProps
  };

  constructor(props: RegisterPageIntersectProps) {
    super(props);
    this.state = {
      isInviteModalOpen: false,
      isReinviteLoading: false,
      stage: verifyURLParams(window.location)
        ? RegisterStages.TERMS
        : RegisterStages.SELECTION
    };
  }

  componentDidMount(): void {
    const { t } = this.props;
    document.title = t("register");

    trackPageView({ page: MixpanelPages.UserRegistration });
  }

  componentWillUnmount(): void {
    this.reInviteSubscription?.unsubscribe();
  }

  private invitedUser: User | undefined;

  private isExpert = true;

  private termsId = "";

  private isEmailInvite = false;

  private isCanopySignUp? = false;

  private invitation?: Invitation;

  private invitationCode?: string;

  private expiredInviteData?: ExpiredInvite;

  private reInviteSubscription?: Subscription;

  private clientName?: string;

  extractParams = (): InviteQueryParams => {
    const { email, token, invitation, signUpType, canopyId } =
      queryString.parse(window.location.search);
    return {
      email,
      token,
      invitation,
      signUpType,
      canopyId
    } as InviteQueryParams;
  };

  handleInviteError = (email?: string, token?: string): void => {
    const { resetToInitial } = this.props;
    this.handleOptionSelection(RegisterStages.SELECTION);
    resetToInitial();

    if (email && token) {
      this.expiredInviteData = { email, token };
      this.setState({
        isInviteModalOpen: true
      });
    }
  };

  handleReinviteExpiredInvitation = async (): Promise<void> => {
    const { googleReCaptchaProps, t } = this.props;
    const { executeRecaptcha } = googleReCaptchaProps;

    try {
      const recaptchaToken = await executeRecaptcha?.(
        RECAPTCHA_ACTIONS.REINVITE
      );
      this.setState({
        isReinviteLoading: true
      });

      const { email, token } = this.expiredInviteData!;

      this.reInviteSubscription = UserService.reinviteExpiredInvitation({
        email,
        oldToken: token,
        recaptchaToken: recaptchaToken!
      }).subscribe(
        () => {
          this.expiredInviteData = undefined;
          this.setState({
            isReinviteLoading: false,
            isInviteModalOpen: false
          });

          notification.showSuccess(i18next.t("register:reinviteSuccess"));
        },
        (error: ErrorResponse<CIQError>) => {
          const isNetworkError = error.status === REST_ERROR.NETWORK_ERROR;

          this.setState({
            isReinviteLoading: false,
            isInviteModalOpen: isNetworkError
          });

          notification.showError(error.message);
        }
      );
    } catch (err) {
      this.setState({
        isReinviteLoading: false
      });
      notification.showError(t("reinviteFailed"));
    }
  };

  handleTermsAccept = (
    isClient: boolean,
    termsId: string,
    isEmailInvite: boolean,
    user?: User,
    invitation?: Invitation,
    clientName?: string,
    isCanopySignUp?: boolean
  ): void => {
    this.invitedUser = user;
    this.isExpert = !isClient;
    this.termsId = termsId;
    this.invitation = invitation;
    this.clientName = clientName;
    this.isEmailInvite = isEmailInvite;
    this.isCanopySignUp = isCanopySignUp;
    this.handleOptionSelection(RegisterStages.CREATE);
  };

  handleOptionSelection = (newStage: RegisterStages): void => {
    this.setState({
      stage: newStage
    });

    if (newStage === RegisterStages.TERMS) {
      trackEvent(MixPanelEventNames.UserRegistration, {
        action: MixPanelActions.Clicked,
        button: "Expert Account"
      });
    } else if (newStage === RegisterStages.INQUIRY) {
      trackEvent(MixPanelEventNames.UserRegistration, {
        action: MixPanelActions.Clicked,
        button: "Company Signup"
      });
    }
  };

  handleReinviteModalDismiss = (): void => {
    this.expiredInviteData = undefined;
    this.setState({
      isInviteModalOpen: false
    });
  };

  renderStages = (): JSX.Element => {
    const { googleReCaptchaProps } = this.props;
    const { stage } = this.state;
    const { executeRecaptcha } = googleReCaptchaProps;

    const { email, token, invitation, signUpType, canopyId } =
      this.extractParams();
    const { TERMS, CREATE, INQUIRY } = RegisterStages;

    this.invitationCode = invitation;

    switch (stage) {
      case TERMS:
        return (
          <AcceptTerms
            email={email}
            token={token}
            inviteToken={invitation}
            signUpType={signUpType}
            onError={this.handleInviteError}
            onTermsAccept={this.handleTermsAccept}
            executeRecaptcha={executeRecaptcha!}
          />
        );
      case CREATE:
        return (
          <CreateAccount
            termId={this.termsId}
            isExpert={this.isExpert}
            user={this.invitedUser}
            token={token}
            isEmailInvite={this.isEmailInvite}
            invitationCode={this.invitationCode}
            executeRecaptcha={executeRecaptcha!}
            invitation={this.invitation}
            onError={this.handleInviteError}
            clientName={this.clientName}
            isCanopySignUp={this.isCanopySignUp}
            canopyId={canopyId}
          />
        );
      case INQUIRY:
        return <CompanyEnquiry executeRecaptcha={executeRecaptcha!} />;
      default:
        return <OnboardSelection onOptionSelect={this.handleOptionSelection} />;
    }
  };

  render(): JSX.Element {
    const { googleReCaptchaProps } = this.props;
    const { isInviteModalOpen, isReinviteLoading } = this.state;

    return (
      <AuthPageBase>
        <ReInviteModal
          isRequestLoading={isReinviteLoading}
          isModalOpen={isInviteModalOpen}
          onRequestInvite={this.handleReinviteExpiredInvitation}
          onModalDismiss={this.handleReinviteModalDismiss}
        />
        {googleReCaptchaProps?.executeRecaptcha ? (
          this.renderStages()
        ) : (
          <Loader isFull />
        )}
      </AuthPageBase>
    );
  }
}

const verifyURLParams = (location: Location): boolean => {
  const { email, token, invitation, signUpType } = queryString.parse(
    location.search
  );
  if (email && token) {
    return utilService.validateEmail(email as string);
  }
  if (invitation) {
    return true;
  }
  if (signUpType) {
    return true;
  }
  return false;
};

const mapDispatchToProps = (dispatch: Dispatch): Record<string, AppAction> => ({
  resetToInitial: (): AppAction => dispatch(replace(REGISTER, { search: "" }))
});

const storeConnectedRegisterPage = connect(
  null,
  mapDispatchToProps
)(RegisterPage);

const translatedRegisterPage = withTranslation("register")(
  // @ts-ignore
  storeConnectedRegisterPage
);

export default withGoogleReCaptcha(translatedRegisterPage);
