import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { ConditionalLink } from "@arbolus-technologies/ui/components";

import styles from "./MobileTabletMenuButton.module.scss";

interface MobileTabletMenuButtonProps {
  text: string;
  icon: string;
  handleButtonClick?: () => void;
  isActive?: boolean;
  to?: string;
  hasNotifications?: boolean;
}
export const MobileTabletMenuButton: React.FC<MobileTabletMenuButtonProps> = ({
  text,
  icon,
  handleButtonClick,
  isActive,
  to,
  hasNotifications
}) => (
  <ConditionalLink to={to}>
    {hasNotifications && <div className={styles.notificationCount} />}
    <div
      className={clsx(styles.container, { [styles.isActive]: isActive })}
      onClick={handleButtonClick}
    >
      <Icon
        name={icon}
        fontSize="24px"
        color={
          isActive
            ? ARBOLUS_COLORS.bColorBasePurple
            : ARBOLUS_COLORS.bColorSecondaryGreyDark
        }
      />
      <h1 className={clsx(styles.text, { [styles.isActive]: isActive })}>
        {text}
      </h1>
    </div>
  </ConditionalLink>
);
