import React from "react";

import { ScreeningQuestion } from "@arbolus-technologies/api";

import { CardFooterData, CardHeaderData } from "../../../interfaces";
import { MatchingFiltersWrapper } from "../MatchingFiltersWrapper/MatchingFiltersWrapper";
import { ScreeningAnswer } from "./ScreeningAnswer";

interface ScreeningAnswersCardProps {
  screeningQuestion: ScreeningQuestion;
  keywordsList: string[];
}
export const ScreeningAnswersCard: React.FC<ScreeningAnswersCardProps> = ({
  screeningQuestion,
  keywordsList
}) => {
  const {
    angle,
    client,
    project,
    projectQuestionsAnswers,
    referralApplicationUpdateDate
  } = screeningQuestion;

  const cardHeader: CardHeaderData = {
    angle,
    project
  };

  const cardFooter: CardFooterData = {
    client,
    date: referralApplicationUpdateDate
  };

  return (
    <MatchingFiltersWrapper
      cardFooterData={cardFooter}
      cardHeaderData={cardHeader}
    >
      {projectQuestionsAnswers.map((projectQuestionsAnswer, index) => (
        <ScreeningAnswer
          key={projectQuestionsAnswer.projectQuestion.id}
          questionAnswer={projectQuestionsAnswer}
          index={index}
          keywordsList={keywordsList}
        />
      ))}
    </MatchingFiltersWrapper>
  );
};
