import { Observable } from "rxjs";

import { restService } from "@arbolus-technologies/api";

import { USERS_API } from "../constants/api";
import { APP_USER_ROLES } from "../constants/app";
import {
  ApiDeletedResponse,
  ApiPaginatedResponse,
  ApiSuccessResponse
} from "../models/api";
import {
  FederatedLoginRequest,
  FederatedRegisterResponse
} from "../models/auth";
import { InboxNotification } from "../models/notifications";
import {
  FederateLoginResponse,
  LoggedInUser,
  NotificationsGetRequest,
  PusherBatchAuthenticateRequest,
  PusherBatchAuthenticateResponse,
  RegisterRequest,
  RegisterUserRequest,
  ReinviteExpiredInviteRequest,
  ResetPasswordRequestEmail,
  Terms,
  User,
  UserCredentials,
  UserEntryRequest,
  UserLoginResponse,
  UserNotificationCountsResponse,
  UserNotificationSettingsUpdateRequest,
  UserPasswordReset,
  UserRegisterResponse
} from "../models/user";

export const userService = {
  authenticateUser: (
    userCredentials: UserCredentials
  ): Observable<UserLoginResponse> =>
    restService.post<UserLoginResponse>(USERS_API.LOGIN(), userCredentials),
  registerUser: (
    registerUser: RegisterUserRequest
  ): Observable<ApiSuccessResponse> =>
    restService.post<ApiSuccessResponse>(USERS_API.REGISTER(), registerUser),
  getUserProfile: (): Observable<LoggedInUser> =>
    restService.get<LoggedInUser>(USERS_API.GET_PROFILE()),
  logoutUser: (): Observable<ApiSuccessResponse> =>
    restService.post<ApiSuccessResponse>(USERS_API.LOGOUT(), {}),
  resetPasswordRequestEmail: (
    resetUser: ResetPasswordRequestEmail
  ): Observable<ApiSuccessResponse> =>
    restService.post<ApiSuccessResponse>(
      USERS_API.REQUEST_RESET_PASSWORD(),
      resetUser
    ),
  reinviteExpiredInvitation: (
    expiredInvite: ReinviteExpiredInviteRequest
  ): Observable<ApiSuccessResponse> =>
    restService.post<ApiSuccessResponse>(
      USERS_API.REINVITE_EXPIRED_INVITATION(),
      expiredInvite
    ),
  federatedLogin: (
    loginRq: FederatedLoginRequest
  ): Observable<FederateLoginResponse> =>
    restService.post<FederateLoginResponse>(USERS_API.OPEN_ID(), loginRq),
  getFederatedLogin: (
    authenticationProvider: string,
    termId: string
  ): Observable<FederatedRegisterResponse> =>
    restService.get(USERS_API.FEDERATED_REGISTER(), {
      authenticationProvider,
      termId
    }),
  pusherAuthenticateAll: (
    pusherAuthenticateRequest: PusherBatchAuthenticateRequest
  ): Observable<PusherBatchAuthenticateResponse> =>
    restService.post<PusherBatchAuthenticateResponse>(
      USERS_API.PUSHER_AUTHENTICATE(),
      pusherAuthenticateRequest
    ),
  changeUserPassword: (
    passwordResetRequest: UserPasswordReset
  ): Observable<ApiSuccessResponse> =>
    restService.put(USERS_API.USER_PASSWORD_CHANGE(), passwordResetRequest),
  updateUserProfile: (
    userId: string,
    userProfile: UserEntryRequest
  ): Observable<ApiSuccessResponse> =>
    restService.put(USERS_API.UPDATE_USER_PROFILE(userId), userProfile),
  isUserMatchesSearchCriteria: (
    { firstName, lastName, email }: User,
    query: string
  ): boolean => {
    const lowerCasedQuery = query.toLocaleLowerCase();

    return (
      (firstName && firstName.toLowerCase().includes(lowerCasedQuery)) ||
      (lastName && lastName.toLowerCase().includes(lowerCasedQuery)) ||
      (firstName &&
        lastName &&
        `${firstName} ${lastName}`.toLowerCase().includes(lowerCasedQuery)) ||
      email.toLowerCase().includes(lowerCasedQuery)
    );
  },
  generateActiveUserRole: (userRoles: string[]): string => {
    const isClient = userRoles.includes(APP_USER_ROLES.client);
    const isAdminClient = userRoles.includes(APP_USER_ROLES.admin);
    const isExpert = userRoles.includes(APP_USER_ROLES.expert);

    if (isExpert) return APP_USER_ROLES.expert;
    if (isClient) {
      if (isAdminClient) return APP_USER_ROLES.adminClient;
      return APP_USER_ROLES.client;
    }

    return APP_USER_ROLES.guest;
  },
  getLatestTerm: (): Observable<Terms> =>
    restService.get<Terms>(USERS_API.GET_LATEST_TERM()),

  acceptTerm: (termId: string): Observable<ApiSuccessResponse> =>
    restService.post<ApiSuccessResponse>(USERS_API.ACCEPT_TERM(termId), {}),
  userRegistration: (
    registerRequest: RegisterRequest
  ): Observable<UserRegisterResponse> =>
    restService.get(USERS_API.REGISTER(), registerRequest),
  getNotificationCounts: (): Observable<UserNotificationCountsResponse> =>
    restService.get<UserNotificationCountsResponse>(
      USERS_API.GET_NOTIFICATION_CATEGORIES(),
      {}
    ),
  getNotifications: (
    notificationsRequest: NotificationsGetRequest
  ): Observable<ApiPaginatedResponse<InboxNotification>> =>
    restService.get<ApiPaginatedResponse<InboxNotification>>(
      USERS_API.GET_NOTIFICATIONS(),
      {
        ...notificationsRequest
      }
    ),
  markNotificationsAsRead: (
    notificationIds: string[]
  ): Observable<ApiSuccessResponse> =>
    restService.post<ApiSuccessResponse>(USERS_API.GET_NOTIFICATIONS(), {
      notificationIds
    }),
  markNotificationsAsDone: (
    notificationIds: string[]
  ): Observable<ApiDeletedResponse> =>
    restService.delete<ApiDeletedResponse>(
      USERS_API.GET_NOTIFICATIONS(),
      {},
      { notificationIds }
    ),

  updateUserNotificationSettings: (
    userNotificationSettings: UserNotificationSettingsUpdateRequest
  ): Observable<ApiSuccessResponse> =>
    restService.post<ApiSuccessResponse>(
      USERS_API.UPDATE_NOTIFICATIONS_SETTINGS(),
      userNotificationSettings
    ),
  sendFcmToken: (fcmToken: string): Observable<ApiSuccessResponse> =>
    restService.post<ApiSuccessResponse>(USERS_API.SEND_FCM_TOKEN(), {
      token: fcmToken
    }),
  updateStarredExpert: (expertId: string): Observable<ApiSuccessResponse> =>
    restService.put<ApiSuccessResponse>(
      USERS_API.UPDATE_STARRED_EXPERT(expertId),
      {}
    ),
  deleteStarredExpert: (expertId: string): Observable<ApiSuccessResponse> =>
    restService.delete<ApiSuccessResponse>(
      USERS_API.DELETE_STARRED_EXPERT(expertId),
      {}
    )
};
