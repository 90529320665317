import clsx from "clsx";
import moment from "moment";
import React from "react";

import { ArbolusAvatar } from "@arbolus-technologies/ui/components";
import { PIPELINE_UPDATE_DATE_FORMAT } from "@arbolus-technologies/utils";

import styles from "./PipelineLogCard.module.scss";

interface PipelineLogCardProps {
  createdBy: string;
  createdDate: string;
  createdByProfileImageUrl?: string | null;
  text?: string;
  classnames?: string;
}

export const PipelineLogCard: React.FC<PipelineLogCardProps> = ({
  createdByProfileImageUrl,
  createdDate,
  createdBy,
  text,
  classnames
}) => (
  <div className={clsx(styles.logStatusCardContainer, classnames)}>
    {text && <span>{text}</span>}
    <div className={styles.imageContainer}>
      <ArbolusAvatar
        profileImageUrl={createdByProfileImageUrl}
        avatarType="avatarGroupCircleSmall"
        firstName={`${createdBy}:`}
        title=""
      />
    </div>
    <span>{moment(createdDate).format(PIPELINE_UPDATE_DATE_FORMAT)}</span>
  </div>
);
