import {
  EditorPlugin,
  EditorRef,
  PluginFunctions
} from "@draft-js-plugins/editor";
import { Store, createStore } from "@draft-js-plugins/utils";
import { EditorState, SelectionState } from "draft-js";
import React, { ReactElement } from "react";

import {
  Monologue,
  TranscriptHighLight
} from "../../../../../../../models/transcript";
import ToolbarHolder from "./ToolbarHolder";

export interface HighLightToolBarProps {
  monologues: Monologue[];
}

export type InlineToolBarPlugin = EditorPlugin & {
  HighLightToolBar: React.FC<HighLightToolBarProps>;
};

export interface ToolbarStoreState {
  selection?: SelectionState;
  getEditorRef?(): EditorRef;
}

export type ToolBarStoreType = Store<ToolbarStoreState>;

const inlineToolBarPlugin = (): InlineToolBarPlugin => {
  const store = createStore<ToolbarStoreState>({});

  const HighLightToolBar: React.FC<{
    monologues: Monologue[];
  }> = ({ monologues }): ReactElement => {
    const highlightMap = new Map<string, TranscriptHighLight[]>();
    monologues.forEach(({ highlights, id }) => {
      highlightMap.set(id, highlights);
    });

    return <ToolbarHolder highlightMap={highlightMap} store={store} />;
  };

  return {
    initialize: ({ getEditorRef }: PluginFunctions): void => {
      store.updateItem("getEditorRef", getEditorRef);
    },
    onChange: (editorState: EditorState): EditorState => {
      store.updateItem("selection", editorState.getSelection());
      return editorState;
    },
    HighLightToolBar
  };
};

export default inlineToolBarPlugin;
