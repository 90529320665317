/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { PROJECT_EVENT_TRANSCRIPT_DOCUMENT_ROUTE } from "@arbolus-technologies/routes";
import {
  PROJECT_PAGE_CARD_DATE_FORMAT,
  formatDate
} from "@arbolus-technologies/utils";

import { APP_USER_ROLES, LISTING_TYPES } from "../../../../../constants/app";
import { DOCUMENT_PANEL_ROUTE } from "../../../../../constants/navigation/panelRoutes";
import { PROJECT_FILES } from "../../../../../constants/navigation/projectRoutes";
import { PROJECT_TAB_ROUTES } from "../../../../../constants/navigation/projectTabRoutes";
import { FILE_TYPE } from "../../../../../models/documents";
import { NoteOrDoc } from "../../../../../models/project";
import { DocumentService, UtilsService } from "../../../../../services";
import { CIQEmptyPlaceholder } from "../../../../app/components";
import Card from "../../card/Card";

interface NotesAndDocsCardProps {
  loading: boolean;
  projectId: string;
  data: NoteOrDoc[];
  navigateToAnotherTab: (tabRouteName: string) => void;
}
type NotesAndDocsCardIntersectProps = NotesAndDocsCardProps & WithTranslation;

const NotesAndDocsCard: React.FC<NotesAndDocsCardIntersectProps> = (
  props: NotesAndDocsCardIntersectProps
): JSX.Element => {
  const history = useHistory();
  const { loading, projectId, data, navigateToAnotherTab, t } = props;

  const handleNotesNDocsCardPressed = (): void => {
    const nextTabRoute = PROJECT_TAB_ROUTES[PROJECT_FILES];
    navigateToAnotherTab(nextTabRoute.route(projectId));
  };

  const renderNotesAndDocsPanelItem = ({
    id,
    title,
    created,
    type
  }: NoteOrDoc): JSX.Element => {
    const isTranscriptFile = type === FILE_TYPE.TRANSCRIPT;
    const fileName = isTranscriptFile
      ? title
      : DocumentService.prettifyFileName(title);
    return (
      <div className="bl-list-item list-text" key={id}>
        <div className="bl-list-icon">
          <span className="ciq-icon ciq-file" />
        </div>
        <div className="bl-list-text-container">
          <div className="bl-list-text">
            {formatDate(
              UtilsService.convertUTCToActiveZone(created),
              PROJECT_PAGE_CARD_DATE_FORMAT
            )}
          </div>
          <div
            className="bl-list-link"
            onClick={(event: React.MouseEvent): void => {
              event.stopPropagation();
              if (isTranscriptFile) {
                history.push(
                  PROJECT_EVENT_TRANSCRIPT_DOCUMENT_ROUTE(projectId, id),
                  true
                );
              } else {
                history.replace(DOCUMENT_PANEL_ROUTE(id));
              }
            }}
          >
            {fileName}
          </div>
        </div>
      </div>
    );
  };

  const renderContent = (): JSX.Element | JSX.Element[] => {
    if (data.length > 0) {
      return data.map((item) => renderNotesAndDocsPanelItem(item));
    }

    const description = new Map([
      [APP_USER_ROLES.client, t("noDocsDescription")],
      [APP_USER_ROLES.expert, t("expertNoDocsDescription")],
      [APP_USER_ROLES.adminClient, t("noDocsDescription")]
    ]);

    return (
      <CIQEmptyPlaceholder
        title={t("noDocs")}
        roleDescription={description}
        itemType={LISTING_TYPES.DOCUMENTS}
        isCard
      />
    );
  };

  return (
    <Card
      loading={loading}
      title={t("notes&Docs")}
      onTitleClick={handleNotesNDocsCardPressed}
    >
      {renderContent()}
    </Card>
  );
};

NotesAndDocsCard.defaultProps = {
  data: []
};

export default withTranslation("projectBasePage")(NotesAndDocsCard);
