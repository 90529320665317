import { ConfirmedEmailUserModel } from "../../User";
import { BookmarkCollectionModel } from "../Bookmarks";

export class BookmarkCollection {
  private readonly _name: string;

  readonly id: string;
  readonly isDefault: boolean;
  readonly hasReference: boolean;
  readonly created: Date;
  readonly owner: ConfirmedEmailUserModel;
  readonly count: number;

  constructor(model: BookmarkCollectionModel) {
    this._name = model.name;
    this.id = model.id;
    this.isDefault = model.isDefault;
    this.hasReference = model.hasReference;
    this.created = new Date(model.created);
    this.owner = model.owner;
    this.count = model.count;
  }

  get name(): string {
    return this.isDefault ? "Saved Insights" : this._name;
  }

  static create(model: BookmarkCollectionModel): BookmarkCollection {
    return new BookmarkCollection(model);
  }

  isShared(currentUserId: string): boolean {
    return currentUserId !== this.owner.id;
  }

  toModel(): BookmarkCollectionModel {
    return {
      id: this.id,
      name: this._name,
      isDefault: this.isDefault,
      hasReference: this.hasReference,
      created: this.created.toISOString(),
      owner: this.owner,
      count: this.count
    };
  }
}
