import React from "react";

import styles from "./ChipPlusCounter.module.scss";

interface ChipPlusCounterProps {
  count: number;
}

export const ChipPlusCounter: React.FC<ChipPlusCounterProps> = ({ count }) => (
  <div className={styles.container}>+{count}</div>
);
