/* spell-checker: disable */

enum FindExpertsMixpanelEventNames {
  ProjectDashboardFindExperts = "project_dashboard_findexpert",
  MoveToShortlist = "findexpert_search_movetoshortlist",
  SelectAngleSidePanelConfirm = "findexpert_search_selectanglesidepanel_confirm"
}

enum InternalSearchMixpanelEventNames {
  InternalSearchSearchBar = "internal_search_search_bar",
  InternalSearchCustomerSearch = "internal_search_customer_search",
  InternalSearchProjectsSearch = "internal_search_projects_search",
  InternalSearchCompaniesSearch = "internal_search_companies_search",
  InternalSearchWorkHistorySearch = "internal_search_workhistory_search",
  InternalSearchCurrentLocationSearch = "internal_search_currentlocation_search",
  InternalSearchKeywordSearch = "internal_search_keyword_search",
  InternalSearchAddToShortlist = "internal_search_add_to_shortlist"
}

export const DiscoverMixpanelEventNames = {
  ...FindExpertsMixpanelEventNames,
  ...InternalSearchMixpanelEventNames
};

export type DiscoverMixpanelEvent =
  | FindExpertsMixpanelEventNames
  | InternalSearchMixpanelEventNames;
