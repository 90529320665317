import { Button, Icon } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { CanopySummaryQuotesExpert } from "@arbolus-technologies/models/canopy-panels";
import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import { CANOPY_INDIVIDUAL_RESPONSE_FROM_SUMMARY_ROUTE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { CanopyV2Selector } from "@arbolus-technologies/stores/canopy-V2";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { DNCTag, UserPortrait } from "@arbolus-technologies/ui/components";

import styles from "./CanopySummaryQuote.module.scss";

export interface CanopySummaryQuoteProps {
  quote: string;
  canopyId: string;
  canopyAnswerId: string;
  expert: CanopySummaryQuotesExpert;
  hideLink?: boolean;
}

export const CanopySummaryQuote: React.FC<CanopySummaryQuoteProps> = ({
  quote,
  expert,
  canopyId,
  canopyAnswerId,
  hideLink = false
}) => {
  const { t } = useTranslation(`canopySummary`);
  const dispatch = useDispatch();
  const { trackClick } = useArbolusTracking();

  const isAdmin = useSelector(CacheSelector.isAdmin());
  const canopyProjectId = useSelector(CanopyV2Selector.canopyProjectId());

  const handleOpenExpertFollowUpSlidePanel = (): void => {
    trackClick(MixPanelEventNames.CanopySummaryFollowup, {
      canopyId,
      expertId: expert.expertId
    });

    dispatch(
      PanelStoreActions.openPanel(PanelId.ExpertEngageWithMe, {
        ...expert
      })
    );
  };

  const handleOpenExpertProfileSlidePanel = (): void => {
    dispatch(
      PanelStoreActions.openPanel(PanelId.ExpertProfile, {
        expertId: expert.expertId,
        projectId: canopyProjectId,
        canopyId
      })
    );
  };

  return (
    <div className={styles.quoteContainer}>
      <div className={styles.userPortraitContainer}>
        <div
          className={styles.userPortrait}
          onClick={handleOpenExpertProfileSlidePanel}
        >
          <UserPortrait
            user={{
              firstName: expert.firstName,
              lastName: expert.lastName,
              id: expert.expertId,
              profileImageUrl: expert.profileImageUrl ?? "",
              title: expert.title
            }}
            icon={
              <DNCTag dncStatus={expert.doNotContactStatus} isAdmin={isAdmin} />
            }
          />
        </div>
        {!isAdmin &&
          expert.doNotContactStatus !== DO_NOT_CONTACT_STATUS.DNC && (
            <div className={styles.hideWhenPDF}>
              <Button
                text={t("askMore")}
                endIcon="chevron_right"
                onClick={handleOpenExpertFollowUpSlidePanel}
              />
            </div>
          )}
      </div>
      <div className={styles.quote}>
        <span className={styles.quoteText}>{`"${quote}"`}</span>
        {!hideLink && (
          <Link
            to={CANOPY_INDIVIDUAL_RESPONSE_FROM_SUMMARY_ROUTE(
              canopyId,
              canopyAnswerId
            )}
          >
            <Icon
              name="open_in_new"
              fontSize="24px"
              color={ARBOLUS_COLORS.bColorBasePurple}
            />
          </Link>
        )}
      </div>
    </div>
  );
};
