import { useCallback, useEffect, useState } from "react";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ProjectService,
  ReferralDetail
} from "@arbolus-technologies/api";

export function useGetReferral(
  projectId: string | undefined,
  referralId: string | undefined
): {
  isLoadingReferral: boolean;
  referral: ReferralDetail | undefined;
} {
  const [isLoadingReferral, setIsLoadingReferral] = useState(false);
  const [referral, setReferral] = useState<ReferralDetail>();

  const getReferral = useCallback(() => {
    if (!referralId || !projectId) {
      setReferral(undefined);
      return;
    }

    setIsLoadingReferral(true);
    ProjectService.getReferral(projectId, referralId).subscribe(
      (referral) => {
        setReferral(referral);
        setIsLoadingReferral(false);
      },
      (error: ErrorResponse<CIQError>) => {
        setIsLoadingReferral(false);
        DefaultToasterService.showError(error.message);
      }
    );
  }, [projectId, referralId]);

  useEffect(() => {
    getReferral();
  }, [getReferral]);

  return { isLoadingReferral, referral };
}
