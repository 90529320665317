import i18next from "i18next";
import * as Yup from "yup";

const PROJECT_ANSWER_MAX_LENGTH = 4000;

export interface ScreeningQuestionsFormValues {
  questions: { questionId: string; answer: string }[];
}

const ScreeningQuestionSchema = Yup.object().shape({
  questionId: Yup.string().required(),
  answer: Yup.string()
    .nullable()
    .max(
      PROJECT_ANSWER_MAX_LENGTH,
      i18next.t("expertApplications:answerMaxLengthError", {
        length: PROJECT_ANSWER_MAX_LENGTH
      })
    )
    .required(i18next.t("expertApplications:answerRequiredError"))
});

export const ScreeningQuestionsSchema = Yup.object().shape({
  questions: Yup.array().of(ScreeningQuestionSchema)
});
