import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";

import { CanopyParamUrlTypes } from "@arbolus-technologies/models/canopy";
import {
  CANOPY_DETAILS_PAGE_ROUTE,
  EXPERT_DETAILS_EDIT_TAB,
  EXPERT_SELF_PROFILE_EDIT
} from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  CanopyExpertSelector,
  CanopyExpertStoreActions
} from "@arbolus-technologies/stores/canopy-expert";
import { rocket } from "@arbolus-technologies/theme";
import { maxLengthString } from "@arbolus-technologies/utils";

import tickIcon from "../../../assets/tick.svg";

import styles from "./SendAnswers.module.scss";

interface SendAnswersRouterState {
  canopyTitle: string;
}

export const SendAnswers: React.FC = (): JSX.Element => {
  const { t } = useTranslation("sendAnswers");
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation<SendAnswersRouterState>();
  const { canopyId } = useParams<CanopyParamUrlTypes>();

  const isSubmitAnswersComplete = useSelector(
    CanopyExpertSelector.isSubmitAnswersComplete()
  );
  const expertId = useSelector(CacheSelector.loggedInUserExpertId());

  const handleConfirm = () => {
    dispatch(CanopyExpertStoreActions.sendCanopyAnswers(canopyId, expertId!));
    dispatch(CanopyExpertStoreActions.resetCanopyAnswerData());
  };

  return (
    <div className={styles.container}>
      {isSubmitAnswersComplete ? (
        <>
          <img src={tickIcon} alt="" />
          <span className={styles.title}>{t("submissionCompletedTitle")}</span>
          <span>{t("submissionCompletedDescription")}</span>
          <Button
            onClick={() => history.replace(EXPERT_SELF_PROFILE_EDIT)}
            text={t("submissionCompletedAction")}
          />
        </>
      ) : (
        <>
          <img src={rocket} alt="rocket" />
          <span className={styles.title}>{t("readyToSubmit")}</span>
          <div className={styles.canopyTitle}>
            <span>{t("descLaunch1")}</span>
            {state?.canopyTitle && (
              <span className={styles.name} title={state?.canopyTitle}>
                {maxLengthString(state?.canopyTitle, 45)}
              </span>
            )}
          </div>
          <span>{t("descLaunch2")}</span>
          <div className={styles.buttonContainer}>
            <Button
              type="secondary"
              onClick={() =>
                history.replace(CANOPY_DETAILS_PAGE_ROUTE(canopyId))
              }
              text={t("goBack")}
            />
            <Button onClick={handleConfirm} text={t("Confirm")} />
          </div>
        </>
      )}
    </div>
  );
};
