import React from "react";
import { useSelector } from "react-redux";

import {
  DefaultReferralsService,
  DefaultToasterService,
  IReferralsService,
  ToasterService
} from "@arbolus-technologies/api";
import { SlotsReferral } from "@arbolus-technologies/models/common";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";

import { ExpertSchedulingTable } from "./scheduling/ExpertSchedulingTable";
import { SchedulingProvider } from "./scheduling/SchedulingContext";
import { SlotsSchedulingTable } from "./scheduling/SlotsSchedulingTable";

interface SchedulingTableProps {
  referralsService?: IReferralsService;
  toasterService?: ToasterService;
}

export const SchedulingTable: React.FC<SchedulingTableProps> = ({
  referralsService = DefaultReferralsService,
  toasterService = DefaultToasterService
}) => {
  const project = useSelector(ProjectNxSelector.projectData());
  const [referrals, setReferrals] = React.useState<SlotsReferral[]>();

  React.useEffect(
    function fetchReferrals() {
      referralsService.getScheduling(project!.id).subscribe({
        next: setReferrals,
        error: toasterService.showApiErrors
      });
    },
    [project, referralsService, toasterService]
  );

  return (
    <SchedulingProvider
      render={(state) =>
        state === "expert" ? (
          <ExpertSchedulingTable referrals={referrals} />
        ) : (
          <SlotsSchedulingTable referrals={referrals} />
        )
      }
    />
  );
};
