import {
  FeedbackCollector,
  FeedbackCollectorDirection,
  IFeedbackCollector
} from "arbolus-ui-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import {
  CIQError,
  CanopyService,
  DefaultToasterService,
  ErrorResponse,
  FeedbackCollectorType,
  ToasterService
} from "@arbolus-technologies/api";
import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { CanopyParamUrlTypes } from "@arbolus-technologies/models/canopy";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { useCookie } from "@arbolus-technologies/utils";

interface CanopyFeedbackCollectorProps {
  onClickClose: () => void;
  cookie: string;
  canopyService?: typeof CanopyService;
  notificationService?: ToasterService;
  noBorder?: boolean;
  purple?: boolean;
}

export const CanopyFeedbackCollector: React.FC<
  CanopyFeedbackCollectorProps
> = ({
  onClickClose,
  cookie,
  canopyService = CanopyService,
  notificationService = DefaultToasterService,
  noBorder,
  purple
}) => {
  const { t } = useTranslation("canopyFeedbackCollector");
  const { trackClick, trackRender } = useArbolusTracking();
  const { canopyId } = useParams<CanopyParamUrlTypes>();
  const { setCookie } = useCookie(cookie);

  const [feedbackId, setFeedbackId] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [question, setQuestion] = useState<string | undefined>();

  const emailUserConnected = useSelector(CacheSelector.loggedInUser());

  useEffect(() => {
    trackRender(MixPanelEventNames.CanopyFeedbackCollector);

    CanopyService.getFeedbackQuestion(FeedbackCollectorType.CANOPY).subscribe(
      (response) => {
        setQuestion(response.question);
      },
      (error: ErrorResponse<CIQError>) => {
        setQuestion(t("areYouHappy"));
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClickedStar = (value: number) => {
    if (!isLoading && question) {
      trackClick(MixPanelEventNames.CanopyFeedbackCollectorSelectedStar);
      setIsLoading(true);

      if (isFirstTime) {
        canopyService
          .canopyFeedback({
            email: emailUserConnected.email,
            rate: value,
            canopyId,
            question: question,
            type: FeedbackCollectorType.CANOPY
          })
          .subscribe(
            (response) => {
              setFeedbackId(response.id);
              setIsFirstTime(false);
              setIsLoading(false);
            },
            (error: ErrorResponse<CIQError>) => {
              notificationService.showApiErrors(error);
              setIsLoading(false);
            }
          );
      } else {
        canopyService
          .canopyFeedbackUpdate({
            rate: value,
            id: feedbackId,
            question: question,
            type: FeedbackCollectorType.CANOPY
          })
          .subscribe(
            () => {
              setIsFirstTime(false);
              setIsLoading(false);
            },
            (error: ErrorResponse<CIQError>) => {
              notificationService.showApiErrors(error);
              setIsLoading(false);
            }
          );
      }
    }
  };

  const onSubmit = (data: IFeedbackCollector) => {
    if (question) {
      trackClick(MixPanelEventNames.CanopyFeedbackCollectorSubmit);
      setIsLoading(true);

      canopyService
        .canopyFeedbackUpdate({
          rate: data.feedbackCollectorStars || 0,
          comment: data.feedbackCollector ?? undefined,
          id: feedbackId,
          question: question,
          type: FeedbackCollectorType.CANOPY
        })
        .subscribe(
          () => {
            setIsFirstTime(false);
            onClickClose();
            setCookie();
            setIsLoading(false);
            notificationService.showSuccess(t("weHaveGotFeedback"));
          },
          (error: ErrorResponse<CIQError>) => {
            notificationService.showApiErrors(error);
            setIsLoading(false);
            onClickClose();
          }
        );
    }
  };

  const handleClose = () => {
    onClickClose();
    trackClick(MixPanelEventNames.CanopyFeedbackCollectorClose);
  };

  return question ? (
    <FeedbackCollector
      question={question}
      noBorder={noBorder}
      onClickClose={handleClose}
      handleOnClickedStar={handleOnClickedStar}
      onSubmit={onSubmit}
      direction={FeedbackCollectorDirection.CENTERED}
      cookie={cookie}
    />
  ) : null;
};
