import { Button } from "arbolus-ui-components";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { useReactToPrint } from "react-to-print";

import {
  CIQError,
  CanopyService,
  DefaultToasterService,
  ErrorResponse,
  ToasterService,
  WebHooksService
} from "@arbolus-technologies/api";
import {
  MixPanelEventNames,
  MixpanelPages,
  PageTracker,
  useArbolusTracking,
  useTimeTracking
} from "@arbolus-technologies/features/common";
import { CanopyParamUrlTypes } from "@arbolus-technologies/models/canopy";
import {
  CanopySummary as CanopySummaryI,
  CanopySummaryStatus
} from "@arbolus-technologies/models/canopy-panels";
import {
  CANOPY_DETAILS_ROUTE_ADMIN,
  CANOPY_MOBILE_MENU_ROUTE
} from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { CanopyV2Selector } from "@arbolus-technologies/stores/canopy-V2";
import { Header } from "@arbolus-technologies/ui/canopy-panels";
import {
  AISummaryWarning,
  BuildingSummaryCreatingCanopy,
  Loader,
  ModalWithChildren,
  PrinterWrapper,
  SomethingWentWrongWarning
} from "@arbolus-technologies/ui/components";

import { EmptySummary } from "./EmptySummary/EmptySummary";
import { GeneratedSummary } from "./GeneratedSummary/GeneratedSummary";

import styles from "./CanopySummary.module.scss";

const { COMPLETED, IN_PROGRESS, FAILED } = CanopySummaryStatus;
const checkIsEmail = "isEmail";

interface CanopySummaryProps {
  webhooksService: typeof WebHooksService;
  notificationService?: ToasterService;
}

export const CanopySummary: React.FC<CanopySummaryProps> = ({
  webhooksService = WebHooksService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("canopySummary");
  const history = useHistory();
  const { trackUnMount, trackClick } = useArbolusTracking();
  const { startTrackingTime, endTrackingTime } = useTimeTracking(
    MixPanelEventNames.CanopySummaryTimeSpent
  );
  const { canopyId } = useParams<CanopyParamUrlTypes>();
  const contentToPrint = useRef(null);

  const [canopySummary, setCanopySummary] = useState<CanopySummaryI>();
  const [openAll, setOpenAll] = useState<boolean>(true);
  const [printPDF, setPrintPDF] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [generatingSummary, setGeneratingSummary] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isAdmin = useSelector(CacheSelector.isAdmin());
  const currentUser = useSelector(CacheSelector.currentUserSelector());
  const canopyData = useSelector(CanopyV2Selector.canopyData(canopyId));

  const handlePrint = useReactToPrint({
    content: () => contentToPrint?.current,
    onAfterPrint: () => setPrintPDF(false)
  });

  const generateSummary = () => {
    setGeneratingSummary(true);
    setIsLoading(true);
    if (canopyData?.id) {
      CanopyService.postCanopySummary(canopyData?.id).subscribe(
        () => {
          notificationService.showSuccess(t("summaryGenerated"));
          setIsLoading(false);
        },
        ({ message }: ErrorResponse<CIQError>) => {
          notificationService.showError(message);
          setIsLoading(false);
          setGeneratingSummary(false);
        }
      );
    }
  };

  useEffect(() => {
    startTrackingTime();

    return () => {
      endTrackingTime({ canopyId, canopyName: canopyData?.title });
      trackUnMount(MixPanelEventNames.CanopySummary, { canopyId });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (openAll && printPDF) {
      setTimeout(() => {
        handlePrint();
      }, 1000);
    }
  }, [openAll, printPDF, handlePrint]);

  useEffect(() => {
    setIsLoading(true);

    if (canopyData?.id) {
      CanopyService.getCanopySummary(canopyData?.id).subscribe(
        (response: CanopySummaryI) => {
          setCanopySummary({ ...response });
          setIsLoading(false);
        },
        ({ message }: ErrorResponse<CIQError>) => {
          notificationService.showError(message);
          setIsLoading(false);
        }
      );
    }
  }, [canopyData, notificationService]);

  const hasLessThan2Experts = canopySummary?.numExpertsInCanopyAnswers! < 2;
  const hasSummaryError = canopySummary?.canopySummaryStatus === FAILED;
  const isSummaryGenerating =
    canopySummary?.canopySummaryStatus === IN_PROGRESS;
  const isSummaryCompleted = canopySummary?.canopySummaryStatus === COMPLETED;

  const showGeneratingWarning = isSummaryGenerating || generatingSummary;
  const isEmptySummary = canopySummary?.canopyAnswersSummaries.length === 0;
  const standardSummaryMessage =
    isEmptySummary && !hasLessThan2Experts && !showGeneratingWarning;
  const showReGenerateWarningMessage =
    canopySummary?.isSummaryRequestAllowed &&
    isSummaryCompleted &&
    !generatingSummary;

  const pathname = window.location.href;
  const comingFromEmail = pathname.includes(checkIsEmail);

  const handleOnClickConfirmModal = () => {
    setIsLoading(true);

    const clientName = `${currentUser.firstName} ${currentUser.lastName} (${currentUser.email})`;
    const messageTitle = `has requested information about AudioCanopy on this Canopy: <${CANOPY_DETAILS_ROUTE_ADMIN(
      canopyId
    )} | ${canopyData?.title}>`;

    webhooksService
      .canopyAudioRequestSendMessage(`${clientName} ${messageTitle}`)
      .subscribe(
        () => {
          setIsLoading(false);
          notificationService.showSuccess(t("thanks"));
          setIsModalOpen(false);
        },
        (error: ErrorResponse<CIQError>) => {
          setIsLoading(false);
          notificationService.showError(error.message);
          setIsModalOpen(false);
        }
      );
  };

  return (
    <PageTracker
      page={
        comingFromEmail
          ? MixpanelPages.CanopySummaryFromEmail
          : MixpanelPages.CanopySummary
      }
    >
      <PrinterWrapper ref={contentToPrint}>
        <Header
          customPadding={"42px"}
          title={t("summary")}
          backButton={
            isMobile && canopyData
              ? {
                  onClick: () =>
                    history.push(CANOPY_MOBILE_MENU_ROUTE(canopyData.id)),
                  title: t("back")
                }
              : undefined
          }
          actionButton={
            <div className={styles.hideButtonFromPrint}>
              <Button
                onClick={generateSummary}
                text={t("regenerate")}
                disabled={
                  !canopySummary?.isSummaryRequestAllowed || generatingSummary
                }
                endIcon="refresh"
                type="tertiary"
              />
              {isSummaryCompleted && (
                <Button
                  text={printPDF ? t("preparing") : t("pdfText")}
                  onClick={() => {
                    setOpenAll(true);
                    setPrintPDF(true);
                    trackClick(MixPanelEventNames.CanopySummaryPDF, {
                      canopyId
                    });
                  }}
                  endIcon="download"
                  disabled={printPDF}
                />
              )}
              {!isAdmin && (
                <Button
                  type="confirmation"
                  text="Get AudioCanopy"
                  endIcon="headphones"
                  onClick={() => {
                    trackClick(MixPanelEventNames.CanopyAudioRequestButton, {
                      canopyId
                    });
                    setIsModalOpen(true);
                  }}
                />
              )}
            </div>
          }
        />
        <div className={styles.container}>
          {showReGenerateWarningMessage && (
            <div className={styles.reGenerateWarningContainer}>
              <span className={styles.text}>
                {t("reGenerateWarningMessage")}
              </span>
            </div>
          )}
          {showGeneratingWarning && <BuildingSummaryCreatingCanopy />}
          {(standardSummaryMessage || hasLessThan2Experts) && (
            <AISummaryWarning />
          )}
          {hasSummaryError && !showGeneratingWarning && (
            <SomethingWentWrongWarning />
          )}
          {isEmptySummary && <EmptySummary />}
          {canopyData &&
            canopySummary &&
            !isEmptySummary &&
            !showGeneratingWarning &&
            !hasSummaryError && (
              <GeneratedSummary
                canopyTitle={canopyData?.title}
                summaryData={canopySummary}
                openAll={openAll}
                printPDF={printPDF}
                setIsOpenAll={setOpenAll}
                isGeneratingSummary={generatingSummary}
              />
            )}
          {isLoading && <Loader />}
        </div>
      </PrinterWrapper>

      {/* AUDIO PILOT */}

      <ModalWithChildren
        customModalBody
        onCancel={() => setIsModalOpen(false)}
        onConfirm={() => {
          handleOnClickConfirmModal();
          trackClick(MixPanelEventNames.CanopyAudioRequestConfirmButton, {
            canopyId
          });
        }}
        toggleModal={isModalOpen}
        className={styles.modal}
        confirmActionText="Request your AudioCanopy"
        cancelActionText="Close"
        isConfirmButtonDisabled={isLoading}
      >
        <div className={styles.audioContainer}>
          <div className={styles.audioTitle}>
            Introducing AudioCanopy: Insights anytime, anywhere
          </div>
          <div className={styles.audioSubtitle1}>
            Interested in consuming expert insights on-the-go? Request an
            AudioCanopy! We are piloting a new feature to request an audio
            version of our expert content.
          </div>
          <div className={styles.audioSubtitle2}>
            Click the button below, and an account manager will reach out to
            explain more about the feature and discuss its pricing. Your
            interest will help us tailor AudioCanopy to better meet your needs.
          </div>
        </div>
      </ModalWithChildren>
    </PageTracker>
  );
};
