import clsx from "clsx";
import { Field, getIn } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormGroup, Label } from "reactstrap";

import { UtilsService } from "../../../../../services";
import { CIQFormInput } from "../../../../app/components";
import { textQuestionSchema } from "../ProjectApplicationSchema";
import { ComplianceQuestionProps } from "./ProjectCompliance";

const TextCompliance: React.FC<ComplianceQuestionProps> = ({
  compliance: { question },
  index,
  formProps: { touched, errors, values }
}): JSX.Element => {
  const { t } = useTranslation("projectApplication");
  const { question: QuestionText, displayModel } = question;
  const { required, defaultValue } = displayModel;

  const fieldKey = `compliances[${index}].answer.answer`;
  const error = getIn(errors, fieldKey);
  const formValue = getIn(values, fieldKey);
  const isError = error && getIn(touched, fieldKey);
  const answerText = formValue || "";

  const validateAsync = async (value: string): Promise<string | null> => {
    try {
      await textQuestionSchema.validate({ value, required });
      return null;
    } catch (err) {
      return err.message;
    }
  };

  return (
    <div className="compliance-item">
      <FormGroup
        className={clsx({
          "is-invalid": isError
        })}
      >
        <Label>{UtilsService.parseRichTextContent(QuestionText)}</Label>
        <Field
          validate={validateAsync}
          name={fieldKey}
          type="textarea"
          value={answerText}
          component={CIQFormInput}
          placeholder={defaultValue || t("textAnswerPlaceholder")}
        />
        {isError && <div className="invalid-feedback">{error}</div>}
      </FormGroup>
    </div>
  );
};

export default TextCompliance;
