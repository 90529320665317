import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { Badge } from "../Badge";
import { BadgeIcon } from "./BadgeIcon";

import styles from "./BadgeGroup.module.scss";

export interface BadgeData {
  id: string;
  text: string;
  imageUrl?: string;
  iconComponent?: JSX.Element;
  onClose?: () => void;
  onClick?: () => void;
}

interface BadgeGroupProps {
  badges: BadgeData[];
  background: string;
  fontColor: string;
  defaultIcon?: string;
  total?: number;
  classnames?: string;
}

export const BadgeGroup: React.FC<BadgeGroupProps> = ({
  badges,
  background,
  fontColor,
  defaultIcon,
  total,
  classnames
}) => {
  const { t } = useTranslation("common");

  return (
    <div className={clsx(styles.group, classnames)} data-testid="badgeGroup">
      {badges.length > 0 &&
        badges.map((badge) => (
          <Badge
            onClick={badge.onClick}
            key={badge.id}
            background={background}
            fontColor={fontColor}
            text={badge.text}
            textStyle="capitalize"
            onClose={badge.onClose}
            customStyle={{ height: "32px" }}
          >
            <div className={styles.children}>
              {badge.iconComponent ? (
                badge.iconComponent
              ) : (
                <BadgeIcon
                  imageUrl={badge.imageUrl}
                  defaultIcon={defaultIcon}
                />
              )}
            </div>
          </Badge>
        ))}
      {!!total && total > badges.length && (
        <Badge
          textStyle="lowercase"
          text={`+${total - badges.length} ${t("more")}`}
          background={background}
          fontColor={fontColor}
          customStyle={{ height: "32px" }}
        />
      )}
    </div>
  );
};
