import i18next from "i18next";
import * as Yup from "yup";

export const NAME = "name";

export type AddCollectionFormObject = {
  [NAME]: string;
};

export const AddCollectionSchema = Yup.object().shape<AddCollectionFormObject>({
  name: Yup.string()
    .required(i18next.t("common:forms:fieldRequired"))
    .min(1)
    .max(50)
    .label(i18next.t("common:forms:text"))
});
