import React from "react";

import { formatDate } from "@arbolus-technologies/utils";

interface DateRendererProps {
  date: string | Date;
  format?: string;
}

export const DateRenderer: React.FC<DateRendererProps> = ({
  date,
  format = "DD MMM ‘YY"
}: DateRendererProps) => (
  <span>
    {formatDate(typeof date === "string" ? new Date(date) : date, format)}
  </span>
);
