import { ICellRendererParams } from "ag-grid-community";
import React from "react";

import { SlotsReferral } from "@arbolus-technologies/models/common";

import { ReferralGridContext } from "../../Models/ReferralsTable";
import { ReferralSlotsDetail } from "./ReferralSlotsDetail";

export const SlotsDetail: React.FC<
  ICellRendererParams<SlotsReferral, unknown, ReferralGridContext>
> = ({ data, context }) => {
  if (!data) {
    return null;
  }

  return (
    <ReferralSlotsDetail slots={data.slots} context={context} referral={data} />
  );
};
