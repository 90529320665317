import React from "react";

import { Ownership } from "@arbolus-technologies/api";
import { Avatar } from "@arbolus-technologies/ui/components";

import styles from "./OwnershipItem.module.scss";

interface OwnershipItemProps {
  ownership: Ownership;
  onClick: () => void;
}
export const OwnershipItem: React.FC<OwnershipItemProps> = ({
  ownership,
  onClick
}) => {
  const fullName = `${ownership.firstName} ${ownership.lastName}`;
  return (
    <div className={styles.wrapperItem} key={ownership.id}>
      <div className={styles.boxClickable} onClick={onClick}>
        <Avatar
          avatar={{
            name: fullName,
            imageUrl: ownership.profileImageUrl
          }}
        />
        <span className={styles.text}>{fullName}</span>
      </div>
      <div className={styles.box}>
        <Avatar
          avatar={{
            name: ownership.clientName,
            imageUrl: ownership.clientLogoUrl
          }}
        />
        <span className={styles.clientName}>{ownership.clientName}</span>
      </div>
    </div>
  );
};
