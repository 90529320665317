import { LocationDescriptorObject } from "history";
import React from "react";
import { Link } from "react-router-dom";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { CircleWithIcon } from "../../CirceWithIcon/CircleWithIcon";

import styles from "./AdminProjectStatsCard.module.scss";

interface AdminProjectStatsCardProps {
  title: string;
  subtitle: string;
  iconName: string;
  color: string;
  to: string | LocationDescriptorObject;
}

export const AdminProjectStatsCard: React.FC<AdminProjectStatsCardProps> = ({
  title,
  subtitle,
  iconName,
  color,
  to
}) => (
  <Link to={to}>
    <div className={styles.button}>
      <CircleWithIcon
        color={color}
        border={`2px solid ${color}`}
        background={ARBOLUS_COLORS.bColorBaseWhite}
        icon={iconName}
        fontSize="22px"
      />
      <div className={styles.contents}>
        <span className={styles.title}>{title}</span>
        <span className={styles.subtitle}>{subtitle}</span>
      </div>
    </div>
  </Link>
);
