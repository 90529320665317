import { ColDef } from "ag-grid-community";
import i18next from "i18next";
import moment from "moment";

import {
  BookedReferral,
  Referral,
  SlotMoment
} from "@arbolus-technologies/models/common";
import {
  fixedWidth,
  floatingSetFilter
} from "@arbolus-technologies/ui/components";
import { TRANSACTION_FORMAT } from "@arbolus-technologies/utils";

import {
  ApprovedDateRenderer,
  BookedDateRenderer,
  CallDateRenderer,
  PresentedDateRenderer,
  RejectedDateRenderer,
  ShortlistDateRenderer
} from "../../../Components/CellRenderers/ReferralDateRenderer";

export const modifiedDateCol: ColDef<Referral, Date> = {
  field: "modified",
  valueFormatter: ({ value, context: { timezone } }) =>
    moment.utc(value!).tz(timezone).format(TRANSACTION_FORMAT),
  headerName: i18next.t("referrals:headers:modified"),
  headerTooltip: i18next.t("referrals:headers:modified"),
  sort: "desc",
  cellClass: "d-flex align-items-center",
  filter: "agDateColumnFilter",
  filterParams: {
    buttons: ["reset"],
    filterOptions: ["inRange"],
    maxNumConditions: 1
  },
  ...floatingSetFilter,
  ...fixedWidth(110)
};

const shared = {
  headerName: i18next.t("referrals:headers:date"),
  headerTooltip: i18next.t("referrals:headers:date"),
  cellClass: "d-flex align-items-center",
  filter: "agDateColumnFilter",
  filterParams: {
    buttons: ["reset"],
    filterOptions: ["inRange"],
    maxNumConditions: 1
  },
  ...floatingSetFilter,
  ...fixedWidth(100)
};

const stageDateCol: ColDef<Referral, Date> = {
  field: "updateDate",
  ...shared
};

export const shortlistDateCol: ColDef<Referral, Date> = {
  ...stageDateCol,
  cellRenderer: ShortlistDateRenderer
};

export const presentedDateCol: ColDef<Referral, Date> = {
  ...stageDateCol,
  cellRenderer: PresentedDateRenderer
};

export const approvedDateCol: ColDef<Referral, Date> = {
  ...stageDateCol,
  cellRenderer: ApprovedDateRenderer
};

export const bookedDateCol: ColDef<BookedReferral, Date> = {
  ...shared,
  ...fixedWidth(125),
  colId: "bookedDate",
  cellRendererSelector: ({ data }) => ({
    component: data?.latestEvent.isInFuture
      ? BookedDateRenderer
      : CallDateRenderer
  }),
  valueGetter: ({ data }) =>
    data?.latestEvent.isInFuture
      ? data?.updateDate
      : data?.latestEvent.startTime,
  valueFormatter: undefined,
  tooltipValueGetter: ({ data, context: { timezone } }) => {
    if (!data || data.events.length < 2) {
      return undefined;
    }

    return [
      i18next.t("referrals:availability:previousEvents"),
      ...data.events
        .slice(1)
        .map((event) =>
          new SlotMoment(event.slot, timezone).startTime.format("DD MMM LT")
        )
    ].join("\n");
  }
};

export const rejectedDateCol: ColDef<Referral, Date> = {
  ...stageDateCol,
  cellRenderer: RejectedDateRenderer
};
