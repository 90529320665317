import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import {
  CONTACT_METHOD_FILTERS_ENUM,
  CONTACT_TYPE_FILTERS_ENUM
} from "@arbolus-technologies/models/expert";
import { Checkbox } from "@arbolus-technologies/ui/components";

import { useExternalSourcing } from "../../Contexts/ExternalSourcingContext/ExternalSourcingContext";
import { useAngleList } from "../../Hooks/useAngleList";
import { AnglesDropdown } from "../AnglesDropdown/AnglesDropdown";
import { SearchFilter } from "../SearchFilter/SearchFilter";
import { FilterType } from "./FilterType/FilterType";

import styles from "./ExternalSourcingFilters.module.scss";

export const ExternalSourcingFilters: React.FC = () => {
  const { t } = useTranslation("externalSourcingFilters");
  const { projectId } = useParams<{ projectId: string }>();
  const angles = useAngleList({ projectId });
  const {
    setSearchTerm,
    selectedAngle,
    setSelectedAngle,
    excludeAlreadyAdded,
    setExcludeAlreadyAdded,
    contactType,
    setContactType,
    contactMethod,
    setContactMethod,
    externalExpertStats,
    fetchStats
  } = useExternalSourcing();

  useEffect(() => {
    fetchStats();
  }, [fetchStats, excludeAlreadyAdded]);

  return (
    <div className={styles.filtersColumn}>
      <div className={styles.search}>
        <SearchFilter
          onSearchTerm={(searchTerm) => setSearchTerm(searchTerm)}
        />
      </div>
      <div className={styles.filtersContainer}>
        <AnglesDropdown
          angles={angles}
          projectId={projectId}
          selectedAngle={selectedAngle}
          setSelectedAngle={setSelectedAngle}
        />
        <div className={styles.checkboxContainer}>
          <Checkbox
            isChecked={excludeAlreadyAdded}
            onChange={() => setExcludeAlreadyAdded((prevState) => !prevState)}
            text={t("excludeShortlisted")}
          />
        </div>
        <FilterType
          filterTypes={Object.values(CONTACT_TYPE_FILTERS_ENUM)}
          filterCounts={externalExpertStats ?? null}
          selectedValue={contactType}
          onClick={(contactType) => setContactType(contactType)}
        />
        <FilterType
          filterTypes={Object.values(CONTACT_METHOD_FILTERS_ENUM)}
          selectedValue={contactMethod}
          onClick={(contactMethod) => setContactMethod(contactMethod)}
        />
      </div>
    </div>
  );
};
