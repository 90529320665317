import { Observable } from "rxjs";

import {
  EXPERTS_API,
  ExpertService,
  restService
} from "@arbolus-technologies/api";

import { MAX_PAGE_SIZE } from "../constants/api";
import { ApiPaginatedResponse, ApiSuccessResponse } from "../models/api";
import { SavedClient } from "../models/client";
import { ExpertUpdateRequest } from "../models/expert";

export const expertService = {
  ...ExpertService,

  updateExpertAccount: (
    expertId: string,
    values: ExpertUpdateRequest
  ): Observable<ApiSuccessResponse> =>
    restService.put(EXPERTS_API.UPDATE_EXPERT_ACCOUNT(expertId), { ...values }),

  getSavedClients: (
    expertId: string,
    searchTerm: string,
    offset = 0,
    limit = MAX_PAGE_SIZE,
    orderBy = "",
    orderDirection = ""
  ): Observable<ApiPaginatedResponse<SavedClient>> =>
    restService.get<ApiPaginatedResponse<SavedClient>>(
      EXPERTS_API.GET_SAVED_CLIENTS(expertId),
      {
        searchTerm,
        offset,
        limit,
        orderBy,
        orderDirection
      }
    )
};
