import React from "react";
import { useTranslation } from "react-i18next";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { AdminProjectStatsCard } from "../AdminProjectStatsCard/AdminProjectStatsCard";
import { StatButtonProps } from "./StatButtonProps";

export const BookedButton: React.FC<StatButtonProps> = ({ to, itemCount }) => {
  const { t } = useTranslation("bookedButton");

  return (
    <AdminProjectStatsCard
      title={t("booked")}
      subtitle={
        itemCount > 0
          ? t("expertCount", {
              count: itemCount
            })
          : t("noExpertsAvailable")
      }
      iconName="phone_forwarded"
      color={ARBOLUS_COLORS.bColorBasePurple}
      to={to}
    />
  );
};
