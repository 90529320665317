import { Icon } from "arbolus-ui-components";
import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { Badge } from "@arbolus-technologies/ui/components";

interface CompanyBadgeProps {
  name: string;
  visible: boolean;
  onClick: () => void;
}

export const CompanyBadge: React.FC<CompanyBadgeProps> = ({
  name,
  visible,
  onClick
}) => (
  <Badge
    text={name}
    fontColor={
      visible
        ? ARBOLUS_COLORS.bColorBaseDark
        : ARBOLUS_COLORS.bColorSecondaryGreyDark
    }
    background={ARBOLUS_COLORS.bColorSecondaryGreyLight}
    onClick={onClick}
    customStyle={{ height: "32px" }}
  >
    <div className="d-flex align-items-center" style={{ marginRight: "8px" }}>
      <Icon name={visible ? "visibility" : "visibility_off"} fontSize="14px" />
    </div>
  </Badge>
);
