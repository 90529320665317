import clsx from "clsx";
import React, { useEffect, useRef } from "react";
import Media from "react-media";
import Select, {
  components,
  SelectInstance,
  SingleValueProps
} from "react-select";

import {
  APP_DEVICE_MEDIA_QUERIES,
  SelectOption
} from "@arbolus-technologies/models/common";

import styles from "./CustomSelect.module.scss";

interface CustomSelectProps {
  options: SelectOption[];
  defaultValue?: SelectOption;
  value?: SelectOption;
  noOptionsMessage: string;
  onSelectChange?: (value: string) => void;
  displayIcon?: boolean;
  isSearchable?: boolean;
  placeholder?: string;
  onBlur?: () => void;
  disabled?: boolean;
  isError?: boolean;
  classNamePrefix?: string;
  customStyle?: string;
  ignoreCustomLabel?: boolean;
  hasMenuPositionFixed?: boolean;
  clearValue?: boolean;
}

export const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  onSelectChange,
  defaultValue,
  value,
  displayIcon = false,
  isSearchable = false,
  noOptionsMessage,
  placeholder,
  onBlur,
  disabled = false,
  isError,
  classNamePrefix,
  customStyle,
  ignoreCustomLabel,
  hasMenuPositionFixed,
  clearValue = false
}: CustomSelectProps) => {
  const selectRef = useRef<SelectInstance<SelectOption> | null>(null);

  const SingleValue = (
    singleValueProps: SingleValueProps<SelectOption>
  ): JSX.Element => {
    const {
      data: { customLabel, label }
    } = singleValueProps;
    return (
      <components.SingleValue {...singleValueProps}>
        {ignoreCustomLabel ? label : customLabel || label}
      </components.SingleValue>
    );
  };

  const handleOnFocus = (
    e: React.BaseSyntheticEvent,
    isMobile: boolean
  ): void => {
    if (isMobile) {
      e.preventDefault();
      e.target.readOnly = true;
    } else {
      e.target.readOnly = false;
    }
  };

  useEffect(() => {
    if (clearValue) {
      selectRef.current?.clearValue();
    }
  }, [clearValue]);

  return (
    <Media queries={APP_DEVICE_MEDIA_QUERIES}>
      {(matches): JSX.Element => (
        <Select
          ref={selectRef}
          menuPosition={hasMenuPositionFixed ? "fixed" : "absolute"}
          onFocus={(e): void => handleOnFocus(e, !matches.large)}
          defaultValue={defaultValue}
          value={value}
          options={options}
          noOptionsMessage={(): string => noOptionsMessage}
          className={clsx(
            "ciqs-select",
            styles.customSelect,
            { "with-icon": displayIcon },
            { [styles.isError]: isError },
            customStyle
          )}
          classNamePrefix={classNamePrefix}
          isSearchable={isSearchable}
          placeholder={placeholder}
          components={{
            IndicatorSeparator: (): null => null,
            SingleValue
          }}
          onChange={(value): void => {
            onSelectChange?.((value as SelectOption)?.value);
          }}
          onBlur={onBlur}
          isDisabled={disabled}
        />
      )}
    </Media>
  );
};
