import moment from "moment";
import "moment-timezone";
import { useCallback } from "react";

export interface Slot {
  startTime: string;
  endTime: string;
  eventId?: string;
  isExpired?: boolean;
  isScheduled?: boolean;
}

interface AvailableSlot {
  slotId: number;
  slotStartValue: Date;
  slotEndValue: Date;
  slotDate: string;
  slotTimeStart: string;
  slotTimeEnd: string;
  slotSelected: boolean;
  slotScheduled: boolean;
}

const SLOT_DATE_FORMAT = "dddd, MMMM D, YYYY";

export const formatSlotDate = (slotDateWithTimezone: moment.Moment): string => {
  // Create NOW but in the same timezone as slot
  const nowWithZone = moment.utc().utcOffset(slotDateWithTimezone.utcOffset());
  return slotDateWithTimezone.calendar(nowWithZone, {
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    // Absolute values, e.g. "Saturday, September 30, 2023"
    lastDay: SLOT_DATE_FORMAT,
    lastWeek: SLOT_DATE_FORMAT,
    nextWeek: SLOT_DATE_FORMAT,
    sameElse: SLOT_DATE_FORMAT
  });
};

export const useFormatSlotsValues = (
  expertAvailabilitySlots: Slot[],
  timezone?: string
): {
  getFormattedSlotsValues: () => AvailableSlot[];
  sortFormattedSlotsValues: (
    slotsFormatted: AvailableSlot[]
  ) => AvailableSlot[];
} => {
  const getSlotTimeStart = (slotDateToTimeZone: moment.Moment) =>
    moment(slotDateToTimeZone).format("hh:mm");

  const getSlotTimeEnd = (slotDateToTimeZone: moment.Moment) =>
    moment(slotDateToTimeZone).format("hh:mm a");

  const getFormattedSlotsValues = useCallback(
    (): AvailableSlot[] =>
      expertAvailabilitySlots.map((slot: Slot, index: number) => {
        // slot.startTime a string is in UTC, e.g. "2023-09-29T16:00:00Z"
        const slotStartWithTimezone = timezone
          ? moment.utc(slot.startTime).tz(timezone)
          : moment(slot.startTime);

        const slotEndDateToTimeZone = timezone
          ? moment.utc(slot.endTime).tz(timezone)
          : moment(slot.startTime);
        return {
          slotId: index,
          slotStartValue: new Date(slot.startTime),
          slotEndValue: new Date(slot.endTime),
          slotDate: formatSlotDate(slotStartWithTimezone),
          slotTimeStart: getSlotTimeStart(slotStartWithTimezone),
          slotTimeEnd: getSlotTimeEnd(slotEndDateToTimeZone),
          slotSelected: false,
          slotScheduled: !!slot.isScheduled
        };
      }),
    [expertAvailabilitySlots, timezone]
  );
  const sortFormattedSlotsValues = useCallback(
    (slotsFormatted: AvailableSlot[]) =>
      [...slotsFormatted].sort(
        /* cspell:disable */
        (slota, slotb) =>
          new Date(slota.slotStartValue).getTime() -
          new Date(slotb.slotStartValue).getTime()
      ),
    /* cspell:enable */
    []
  );

  return {
    getFormattedSlotsValues,
    sortFormattedSlotsValues
  };
};
