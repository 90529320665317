import { ColDef } from "ag-grid-community";
import i18next from "i18next";

import {
  APPLICATION_STATUS,
  REFERRAL_SUB_STATUS,
  Referral
} from "@arbolus-technologies/models/common";

import { ApplicationGroupRenderer } from "../../../Components/CellRenderers/ChipGroupRenderer";

export type ApplicationGroups = APPLICATION_STATUS | "approved";

const applicationToText: Record<ApplicationGroups, string> = {
  [APPLICATION_STATUS.PENDING]: i18next.t("referrals:gridGroups:pending"),
  [APPLICATION_STATUS.SENT]: i18next.t("referrals:gridGroups:sent"),
  [APPLICATION_STATUS.ACCEPT]: i18next.t("referrals:gridGroups:accepted"),
  [APPLICATION_STATUS.REJECT]: i18next.t("referrals:gridGroups:rejected"),
  approved: i18next.t("referrals:gridGroups:approved")
};

export const applicationValueGetter = ({
  data
}: {
  data?: Referral;
}): ApplicationGroups => {
  if (data!.review === REFERRAL_SUB_STATUS.ACCEPT) {
    return "approved";
  }

  return data!.application;
};

export const applicationGroupRowRendererParams = {
  innerRenderer: ApplicationGroupRenderer,
  checkbox: true,
  suppressCount: true
};

export const applicationCol: ColDef<Referral> = {
  field: "application",
  rowGroup: true,
  hide: true,
  valueGetter: applicationValueGetter,
  valueFormatter: ({ value }) => applicationToText[value as APPLICATION_STATUS]
};

export const cidCol: ColDef<Referral> = {
  colId: "cid",
  headerName: "CID",
  valueGetter: (v: { data?: Referral }) => v.data?.review,
  valueFormatter: formatCIDStatus
};

function formatCIDStatus(columnValue: { value: REFERRAL_SUB_STATUS }): string {
  const referralStatus = columnValue.value;
  if (referralStatus === REFERRAL_SUB_STATUS.ACCEPT) {
    return i18next.t("referrals:stage:cidAccepted");
  } else if (referralStatus === REFERRAL_SUB_STATUS.REJECT) {
    return i18next.t("referrals:stage:cidRejected");
  }
  return i18next.t("referrals:stage:cidPending");
}
