import { Button, Icon } from "arbolus-ui-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ProjectService,
  ToasterService,
  User
} from "@arbolus-technologies/api";
import { AngleModel } from "@arbolus-technologies/models/common";
import { ProjectAnglesSelector } from "@arbolus-technologies/stores/project-angles-store";
import { ProjectExpertsStoreActions } from "@arbolus-technologies/stores/project-experts-store";
import {
  ArbolusModal,
  HR,
  UserHeader
} from "@arbolus-technologies/ui/components";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { SelectableCardList } from "../../Components/SelectableCardList/SelectableCardList";
import { InternalSlidePanel } from "../InternalSlidePanel/InternalSlidePanel";

import styles from "./AddEditAngleSlidePanel.module.scss";

interface AddEditAngleSlidePanelProps {
  panelOpen: boolean;
  setPanelOpen: (panelOpen: boolean) => void;
  isEdit: boolean;
  projectId: string;
  referralId: string;
  angle?: AngleModel;
  expert: User;
  keepReferralStatus?: boolean;
  removeFromProjectOnCancel?: boolean;
  onAfterApply?: () => void;
  notificationService?: ToasterService;
}

export const AddEditAngleSlidePanel: React.FC<AddEditAngleSlidePanelProps> = ({
  panelOpen,
  setPanelOpen,
  isEdit,
  projectId,
  referralId,
  angle,
  expert,
  keepReferralStatus = false,
  removeFromProjectOnCancel = false,
  onAfterApply = false,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("addEditAngleSlidePanel");
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedAngle, setSelectedAngle] = useState<string>("");

  const anglesList = useSelector(ProjectAnglesSelector.anglesList());

  useEffect(() => {
    angle && setSelectedAngle(angle.id);
  }, [angle]);

  const handleAngleChange = (angleId: string) => {
    setSelectedAngle(angleId);
  };

  const handleApplyButtonClick = () => {
    if (isEdit) {
      setShowModal(true);
      return;
    }

    if (keepReferralStatus) {
      dispatch(
        ProjectExpertsStoreActions.updateReferralAngle(
          referralId,
          selectedAngle,
          projectId
        )
      );
    } else {
      // Assigns the angle AND moves the referral to the candidate state
      dispatch(
        ProjectExpertsStoreActions.assignAngleShortlistToCandidate(
          referralId,
          selectedAngle,
          projectId
        )
      );
    }

    if (typeof onAfterApply === "function") onAfterApply();
  };

  const removeExpertFromProject = () => {
    ProjectService.removeFromProject(projectId, referralId).subscribe(
      null,
      (error: ErrorResponse<CIQError>) => {
        notificationService.showError(error.message);
      }
    );
  };

  const handleCloseRequest = () => {
    if (removeFromProjectOnCancel) removeExpertFromProject();
    angle && setSelectedAngle(angle.id);
    setPanelOpen(false);
  };

  const handleOnConfirm = () => {
    dispatch(
      ProjectExpertsStoreActions.updateReferralAngle(
        referralId,
        selectedAngle,
        projectId
      )
    );
    setShowModal(false);
    if (typeof onAfterApply === "function") onAfterApply();
  };

  return (
    <>
      <InternalSlidePanel
        width={SIDE_PANEL_SIZE._720}
        isOpen={panelOpen}
        hideHeader
        customCloseRequest={handleCloseRequest}
      >
        <div className={styles.angleSlidePanelHeader}>
          <h2>{isEdit ? t("editAngle") : t("assignAngle")}</h2>
          <Icon name="close" fontSize="18px" onClick={handleCloseRequest} />
        </div>
        <HR margin={{ top: 3, bottom: 3 }} />
        <UserHeader user={expert} />
        <div className={styles.angleSlidePanelBody}>
          <SelectableCardList
            items={anglesList.map((angle) => ({
              key: angle.id,
              text: angle.name,
              iconName: "zoom_in_map",
              iconCustomStyle: { backgroundColor: angle.color },
              iconCustomClasses: styles.angleIcon
            }))}
            onClick={handleAngleChange}
            filters={[selectedAngle]}
          />
        </div>
        <HR margin={{ top: 3, bottom: 3 }} />
        <div className={styles.angleSlidePanelFooter}>
          <Button
            type="tertiary"
            text={t("cancel")}
            onClick={handleCloseRequest}
          />
          <Button
            text={isEdit ? t("applyChanges") : t("assignAngle")}
            onClick={handleApplyButtonClick}
            disabled={angle?.id === selectedAngle}
          />
        </div>
      </InternalSlidePanel>
      <ArbolusModal
        title={t("angleConfirmationTitle")}
        subtitle={t("angleConfirmationMessage")}
        toggle={() => setShowModal((prev: boolean) => !prev)}
        isOpen={showModal}
        leftButton={{
          text: t("Cancel"),
          type: "secondary",
          onClick: () => setShowModal((prev: boolean) => !prev)
        }}
        rightButton={{
          text: t("confirm"),
          type: "primary",
          onClick: handleOnConfirm
        }}
      />
    </>
  );
};
