import { CIQError, ErrorResponse } from "../../../../models/api";
import { ListProject } from "../../../../models/project";

export const NAVBAR_ADD_NEW_PROJECT = "NAVBAR_ADD_NEW_PROJECT";
export const NAVBAR_REMOVE_PROJECT = "NAVBAR_REMOVE_PROJECT";

export const GET_PROJECT_LIST_FOR_NAVBAR = "NAVBAR_GET_PROJECT_LIST_FOR_NAVBAR";
export const GET_PROJECT_LIST_FOR_NAVBAR_SUCCESS =
  "NAVBAR_GET_PROJECT_LIST_FOR_NAVBAR_SUCCESS";
export const GET_PROJECT_LIST_FOR_NAVBAR_FAILURE =
  "NAVBAR_GET_PROJECT_LIST_FOR_NAVBAR_FAILURE";
export const NAVBAR_UPDATE_PROJECT_NAME = "NAVBAR_UPDATE_PROJECT_NAME";

export const NAVBAR_UPDATE_EXPERT_NEW_APPLICATIONS_COUNT =
  "NAVBAR_UPDATE_EXPERT_NEW_APPLICATIONS_COUNT";

export interface NavbarAddNewProjectAction {
  type: typeof NAVBAR_ADD_NEW_PROJECT;
  payload: {
    addedProject: ListProject;
  };
}

export interface NavbarRemoveProjectAction {
  type: typeof NAVBAR_REMOVE_PROJECT;
  payload: {
    projectId: string;
  };
}

export interface GetProjectListForNavbarAction {
  type: typeof GET_PROJECT_LIST_FOR_NAVBAR;
  payload: {
    showLoading: boolean;
  };
}

export interface GetProjectListForNavbarSuccessAction {
  type: typeof GET_PROJECT_LIST_FOR_NAVBAR_SUCCESS;
  payload: {
    projectList: ListProject[];
    expertNewProjectCount?: number;
  };
}

export interface GetProjectListForNavbarFailureAction {
  type: typeof GET_PROJECT_LIST_FOR_NAVBAR_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

export interface NavbarUpdateProjectNameAction {
  type: typeof NAVBAR_UPDATE_PROJECT_NAME;
  payload: {
    projectId: string;
    projectName: string;
  };
}

export interface NavbarUpdateExpertNewApplicationsCount {
  type: typeof NAVBAR_UPDATE_EXPERT_NEW_APPLICATIONS_COUNT;
}
