import { ICellRendererParams } from "ag-grid-community";
import { LocationDescriptorObject } from "history";
import React from "react";
import { Link } from "react-router-dom";

import { ProjectModel } from "@arbolus-technologies/models/common";
import {
  CLIENT_DRAFT_PROJECT_ROUTE,
  PROJECT_ROUTE
} from "@arbolus-technologies/routes";

import styles from "./NameRenderer.module.scss";

type NameRendererComponent = React.FC<
  ICellRendererParams<ProjectModel, never, never>
>;
interface NameRendererProps {
  name: string;
  to: LocationDescriptorObject;
}

const NameRenderer: React.FC<NameRendererProps> = ({ name, to }) => (
  <Link to={to} className={styles.link}>
    {name}
  </Link>
);

export const ActiveProjectName: NameRendererComponent = ({ data }) => (
  <NameRenderer to={PROJECT_ROUTE(data!.id)} name={data!.name} />
);

export const DraftProjectName: NameRendererComponent = ({ data }) => (
  <NameRenderer
    to={CLIENT_DRAFT_PROJECT_ROUTE(data!.client.id, data!.id)}
    name={data!.name}
  />
);
