import { ICellRendererParams } from "ag-grid-community";
import React from "react";

import { ProjectDraftModel } from "@arbolus-technologies/models/common";

import { DeleteProjectDraftButton } from "../../../Components/DeleteProjectDraftButton/DeleteProjectDraftButton";

export const DraftActionsRenderer: React.FC<
  ICellRendererParams<ProjectDraftModel, never, never>
> = ({ data, api }) => (
  <div className="flex-row-8 align-items-center h-100">
    <DeleteProjectDraftButton
      id={data!.id}
      onDelete={() =>
        api.applyServerSideTransactionAsync({ remove: [{ id: data!.id }] })
      }
    />
  </div>
);
