import React from "react";
import { FieldError } from "react-hook-form";

import {
  AdminService,
  ApiPaginatedRequest,
  Client,
  SORT_DIRECTION
} from "@arbolus-technologies/api";

import { ClientSelector, ClientSelectorProps } from "./ClientSelector";

export const MAX_PAGE_SIZE = 10;

interface ClientAdminSelectorProps
  extends Omit<ClientSelectorProps<Client>, "getItems"> {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  error?: FieldError;
}

export const ClientAdminSelector: React.FC<ClientAdminSelectorProps> = ({
  ...props
}) => (
  <ClientSelector
    {...props}
    getItems={(pagination: ApiPaginatedRequest) =>
      AdminService.getClients(
        pagination.offset,
        pagination.searchTerm,
        "name",
        pagination.limit,
        SORT_DIRECTION.ASCENDING
      )
    }
  />
);
