import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import {
  CIQError,
  CanopyService,
  DefaultToasterService,
  ErrorResponse,
  ToasterService
} from "@arbolus-technologies/api";
import {
  CanopyParamUrlTypes,
  CanopyQuestionFields
} from "@arbolus-technologies/models/canopy";
import { HR, Loader } from "@arbolus-technologies/ui/components";

import { prepareCanopyRequest } from "../../helpers/CanopyQuestionHelpers";
import { useCanopyContext } from "../../helpers/useCanopyContext";
import { AnswerTypes } from "./AnswerTypes/AnswerTypes";
import { CanopySchema } from "./CanopySchemas/CanopySchema";
import { QuestionActions } from "./QuestionActions/QuestionActions";
import { QuestionInfo } from "./QuestionInfo/QuestionInfo";

interface QuestionFormProps {
  question: CanopyQuestionFields;
  onQuestionsUpdate: (removeNewQuestionBlock?: boolean) => void;
  handleShowUnsavedQuestion: (isDirty: boolean) => void;
  canopyService?: typeof CanopyService;
  notificationService?: ToasterService;
}

export const QuestionForm: React.FC<QuestionFormProps> = ({
  question,
  onQuestionsUpdate,
  handleShowUnsavedQuestion,
  canopyService = CanopyService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("canopyBuilder");
  const { canopyId } = useParams<CanopyParamUrlTypes>();
  const { isEditMode } = useCanopyContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const methods = useForm<CanopyQuestionFields>({
    resolver: async (values, context, options) =>
      await yupResolver(CanopySchema(values.answerType))(
        values,
        context,
        options
      ),
    mode: "all",
    defaultValues: question
  });

  useEffect(() => {
    handleShowUnsavedQuestion(methods.formState.isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.formState.isDirty]);

  const handleSubmit = (questionDetails: CanopyQuestionFields) => {
    setIsLoading(true);
    if (isEditMode) {
      canopyService
        .editCanopyQuestion(
          prepareCanopyRequest(
            { ...questionDetails, order: question.order },
            canopyId
          )
        )
        .subscribe(
          () => {
            methods.reset({}, { keepValues: true });
            onQuestionsUpdate();
            notificationService.showSuccess(t("questionUpdatedSuccessfully"));
            setIsLoading(false);
          },
          (error: ErrorResponse<CIQError>) => {
            notificationService.showError(error.message);
            setIsLoading(false);
          }
        );
    } else {
      canopyService
        .createCanopyQuestion(
          prepareCanopyRequest(
            { ...questionDetails, id: "", order: question.order },
            canopyId
          )
        )
        .subscribe(
          () => {
            onQuestionsUpdate(true);
            notificationService.showSuccess(t("questionCreatedSuccessfully"));
            setIsLoading(false);
          },
          (error: ErrorResponse<CIQError>) => {
            notificationService.showError(error.message);
            setIsLoading(false);
          }
        );
    }
  };

  const handleDelete = () => {
    if (isEditMode) {
      setIsLoading(true);

      canopyService.deleteCanopyQuestion(question.id).subscribe(
        () => {
          onQuestionsUpdate();
          notificationService.showSuccess(t("questionDeletedSuccessfully"));
          setIsLoading(false);
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showError(error.message);
          setIsLoading(false);
        }
      );
    } else {
      onQuestionsUpdate(true);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <QuestionInfo />
        <AnswerTypes />
        <HR margin={{ top: 2, bottom: 2 }} />
        <QuestionActions onSubmit={handleSubmit} onDelete={handleDelete} />
      </FormProvider>
      {isLoading && <Loader isFull />}
    </>
  );
};
