import { UserProfile } from "@arbolus-technologies/api";

export const GET_ADMIN_USER_PROFILE = "CACHE_GET_ADMIN_USER_PROFILE";

export interface GetAdminUserProfileAction {
  type: typeof GET_ADMIN_USER_PROFILE;
  payload: {
    userProfile: UserProfile;
  };
}
