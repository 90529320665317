import { Button, Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  ExpertProfileSidePanel,
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import { ChatType } from "@arbolus-technologies/models/project";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  ArbolusTooltip,
  Avatar,
  DNCTag,
  Loader
} from "@arbolus-technologies/ui/components";

import { useChat } from "../../../../Contexts/ChatContext/ChatContext";
import { getTitle } from "../../utils";

import styles from "./ChatHeader.module.scss";

interface ChatHeaderProps {
  chatType: ChatType;
  isLoadingReferral: boolean;
  canBook: boolean;
  openAvailabilityPanel: () => void;
  handleOpenExpertSidePanel: (expertId: string, referralId: string) => void;
  doNotContactStatus?: DO_NOT_CONTACT_STATUS;
}

export const ChatHeader: React.FC<ChatHeaderProps> = ({
  chatType,
  isLoadingReferral,
  canBook,
  openAvailabilityPanel,
  handleOpenExpertSidePanel,
  doNotContactStatus
}) => {
  const { t } = useTranslation("chat");
  const { projectData, currentChat } = useChat();
  const { trackClick } = useArbolusTracking();

  const title = getTitle(chatType, currentChat?.title);

  const showBookButton = [
    ChatType.ClientToExpertAndAdmin,
    ChatType.AdminToExpertAndClient,
    ChatType.AdminToExpertSupport
  ].includes(chatType);

  const handleBookButton = () => {
    openAvailabilityPanel();
    trackClick(MixPanelEventNames.ChatExpertBooking);
  };

  const canOpenExpertSidePanel =
    showBookButton &&
    projectData &&
    currentChat &&
    currentChat.referralId &&
    currentChat.expertId;

  return (
    <>
      <div className={styles.chatHeader}>
        <div
          className={clsx(styles.titleWithAvatar, {
            [styles.cursor]: showBookButton
          })}
          onClick={() =>
            canOpenExpertSidePanel &&
            handleOpenExpertSidePanel(
              currentChat.expertId!,
              currentChat.referralId!
            )
          }
          onKeyDown={() =>
            canOpenExpertSidePanel &&
            handleOpenExpertSidePanel(
              currentChat.expertId!,
              currentChat.referralId!
            )
          }
        >
          <Avatar
            avatar={{
              name: title ?? "",
              imageUrl: currentChat?.expertAvatar
            }}
            type="avatarGroupCircleSmall"
          />
          <span className={styles.title}>{title}</span>
          <ArbolusTooltip content={t(chatType)}>
            <Icon
              id="disclaimer"
              name="info"
              fontSize="16px"
              color={ARBOLUS_COLORS.bColorBasePurple}
            />
          </ArbolusTooltip>
          {doNotContactStatus && <DNCTag dncStatus={doNotContactStatus} />}
        </div>
        {showBookButton && (
          // Book button
          <div className={styles.bookButton}>
            {isLoadingReferral ? (
              <Loader isLight isCentered size="20px" />
            ) : (
              <Button
                type="primary"
                text={t("bookButton")}
                endIcon="chevron_right"
                onClick={handleBookButton}
                disabled={isLoadingReferral || !canBook}
              />
            )}
          </div>
        )}
      </div>
      {currentChat?.expertId && (
        <ExpertProfileSidePanel expertId={currentChat.expertId} />
      )}
    </>
  );
};
