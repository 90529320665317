import i18next from "i18next";
import * as Yup from "yup";

import { COMPLIANCE_STATUS } from "../../../../../../../constants/project";
import { MAX_COMPLIANCE_COMMENT_LENGTH } from "../../../../../../../constants/validation";

export const ComplianceApprovalSchema = Yup.object({
  status: Yup.string().required(
    i18next.t("expertComplianceTab:statusRequiredError")
  ),
  firstCall: Yup.string().when("status", {
    is: (val) => val === COMPLIANCE_STATUS.PASSED,
    then: Yup.string().required(
      i18next.t("expertComplianceTab:chaperoneCallRequireError")
    ),
    otherwise: Yup.string().notRequired()
  }),
  comments: Yup.string().max(
    MAX_COMPLIANCE_COMMENT_LENGTH,
    i18next.t("expertComplianceTab:maxCommentLength", {
      length: MAX_COMPLIANCE_COMMENT_LENGTH
    })
  )
});
