import React from "react";

import styles from "./CanopyVideoPlayer.module.scss";

interface CanopyVideoPlayerProps {
  src: string;
  answerId: string;
  disablePictureInPicture?: boolean;
}

export const CanopyVideoPlayer: React.FC<CanopyVideoPlayerProps> = ({
  src,
  answerId,
  disablePictureInPicture
}) => (
  <div className={styles.canopyVideoPlayer}>
    <video
      id={answerId}
      preload="metadata"
      controls
      disablePictureInPicture={disablePictureInPicture}
    >
      <source src={`${src}#t=0.5`} />
    </video>
  </div>
);
