import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  BaseClientMember,
  ClientService,
  DefaultToasterService,
  IErrorResponse,
  ToasterService
} from "@arbolus-technologies/api";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { Avatar, Loader } from "@arbolus-technologies/ui/components";
import { fullName } from "@arbolus-technologies/utils";

import { IconTextItem } from "./IconTextItem";
import { ProfileDetailItem } from "./ProfileDetailItem";

import styles from "./Profile.module.scss";

interface ProfileProps {
  clientId: string;
  userId: string;
  notificationService?: ToasterService;
}

const profileInitialState = {
  id: "",
  firstName: "",
  lastName: "",
  title: "",
  email: "",
  profileImageUrl: "",
  isoCountryCode: "",
  phoneNumber: "",
  timezone: "",
  emailConfirmed: false,
  accessLevel: "",
  clientUserProfile: {
    priorities: "",
    contactingPreferences: "",
    general: "",
    relationshipOwner: null,
    relationshipOwnerId: "",
    displayName: "",
    isRealNameEnabled: false,
    industryId: ""
  },
  ciqManager: undefined,
  country: {
    id: "",
    name: "",
    city: {
      id: "",
      name: ""
    }
  }
};

export const Profile: React.FC<ProfileProps> = ({
  clientId,
  userId,
  notificationService = DefaultToasterService
}) => {
  const [profileData, setProfileData] =
    useState<BaseClientMember>(profileInitialState);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const timezones = useSelector(CacheSelector.timezones());
  const { t } = useTranslation("clientUserSlidePanel");

  useEffect(() => {
    ClientService.getUser(clientId, userId).subscribe(
      (res: BaseClientMember) => {
        const timezone = timezones.find((t) => t.value === res.timezone);

        const profileClientData: BaseClientMember = {
          ...res,
          timezone: timezone?.label as string
        };
        setProfileData(profileClientData);
        setIsLoading(false);
      },
      ({ message }: IErrorResponse) => {
        notificationService.showError(message);
        setIsLoading(false);
      }
    );
    //the timezones selector dependency causes infinite loop error
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, userId, notificationService]);

  const { email, phoneNumber, timezone, clientUserProfile } = profileData;

  return isLoading ? (
    <Loader isFull />
  ) : (
    <>
      <div className={styles.profileDetails}>
        <IconTextItem iconName="email" text={email} />
        <IconTextItem iconName="phone" text={phoneNumber} />
        <IconTextItem iconName="public" text={timezone} />
      </div>
      <hr />
      <div className={styles.relationShipWrapper}>
        <span className={styles.relationShipLabel}>
          {t("relationShipOwner")}
        </span>
        <div className={styles.relationShip}>
          <Avatar
            avatar={{
              name: fullName(
                clientUserProfile?.relationshipOwner?.firstName ?? "",
                clientUserProfile?.relationshipOwner?.lastName ?? ""
              ),
              imageUrl:
                clientUserProfile?.relationshipOwner?.profileImageUrl ?? ""
            }}
            type="smallCircle"
          />
          <span className={styles.relationShipName}>
            {clientUserProfile?.relationshipOwner?.firstName ||
            clientUserProfile?.relationshipOwner?.lastName
              ? fullName(
                  clientUserProfile?.relationshipOwner?.firstName,
                  clientUserProfile?.relationshipOwner?.lastName
                )
              : t("notSet")}
          </span>
        </div>
      </div>
      <ProfileDetailItem
        text={clientUserProfile?.priorities}
        labelText={t("priorities")}
      />
      <ProfileDetailItem
        text={clientUserProfile?.contactingPreferences}
        labelText={t("contactingCommsPreferences")}
      />
      <ProfileDetailItem
        text={clientUserProfile?.general}
        labelText={t("general")}
      />
    </>
  );
};
