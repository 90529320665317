/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import i18next from "i18next";
import React, { ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { LISTING_TYPES } from "../../../../../constants/app";
import { EX_LINK_WELCOME_BANNER } from "../../../../../constants/externalLink";
import { USERS } from "../../../../../constants/navigation/authRoutes";
import { INBOX_NOTIFICATION_CATEGORY } from "../../../../../constants/notifications";
import AccessManager from "../../../../../contexts/roleBasedAccess/AccessManager";
import { CIQEmptyPlaceholder } from "../../../../app/components";

interface IInboxEmptyState {
  titleKey?: string;
  expertTitleKey?: string;
  subTitle?: ReactElement;
  expertSubTitle?: string;
  placeholderIcon: string;
}

const InboxEmptyStates = new Map<INBOX_NOTIFICATION_CATEGORY, IInboxEmptyState>(
  [
    [
      INBOX_NOTIFICATION_CATEGORY.Applications,
      {
        expertTitleKey: "noResultsExpertApplicationsTitle",
        expertSubTitle: "noResultsExpertApplicationsDescription",
        placeholderIcon: LISTING_TYPES.INBOX_APPLICATIONS
      }
    ],
    [
      INBOX_NOTIFICATION_CATEGORY.Approvals,
      {
        titleKey: "noResultsApprovalsTitle",
        subTitle: i18next.t("inbox:noResultsApprovalsDescription"),
        placeholderIcon: LISTING_TYPES.INBOX_APPROVALS
      }
    ],
    [
      INBOX_NOTIFICATION_CATEGORY.NewReferrals,
      {
        titleKey: "noResultsReferralsTitle",
        expertTitleKey: "noResultsExpertReferralsTitle",
        subTitle: (
          <Trans
            i18nKey="inbox:noResultsReferralsDescription"
            components={[
              <br key="1" />,
              <br key="2" />,
              <a
                href={EX_LINK_WELCOME_BANNER.HOW_TO_ADD_EXPERTS}
                rel="noopener noreferrer"
                target="_blank"
                key="3"
              />
            ]}
          />
        ),
        expertSubTitle: "noResultsExpertReferralsDescription",
        placeholderIcon: LISTING_TYPES.INBOX_NEW_REFERRALS
      }
    ],
    [
      INBOX_NOTIFICATION_CATEGORY.NewJoins,
      {
        titleKey: "noResultsJoinsTitle",
        subTitle: (
          <Trans
            i18nKey="inbox:noResultsJoinsDescription"
            components={[<Link to={USERS} key="0" />]}
          />
        ),
        placeholderIcon: LISTING_TYPES.INBOX_NEW_JOINS
      }
    ],
    [
      INBOX_NOTIFICATION_CATEGORY.SharedDocs,
      {
        titleKey: "noResultsDocsTitle",
        expertTitleKey: "noResultsDocsTitle",
        subTitle: i18next.t("inbox:noResultsDocsDescription"),
        expertSubTitle: "noResultsExpertDocsDescription",
        placeholderIcon: LISTING_TYPES.INBOX_SHARED_DOCS
      }
    ],
    [
      INBOX_NOTIFICATION_CATEGORY.EventInvites,
      {
        titleKey: "noResultsEventsTitle",
        expertTitleKey: "noResultsEventsTitle",
        subTitle: i18next.t("inbox:noResultsEventsDescription"),
        expertSubTitle: "noResultsEventsDescription",
        placeholderIcon: LISTING_TYPES.INBOX_EVENT_INVITES
      }
    ],
    [
      INBOX_NOTIFICATION_CATEGORY.Messages,
      {
        titleKey: "noResultsMessagesTitle",
        expertTitleKey: "noResultsMessagesTitle",
        subTitle: i18next.t("inbox:noResultsMessagesDescription"),
        expertSubTitle: "noResultsExpertMessagesDescription",
        placeholderIcon: LISTING_TYPES.INBOX_MESSAGES
      }
    ],
    [
      INBOX_NOTIFICATION_CATEGORY.ThingsTodo,
      {
        titleKey: "noResultsThingsToDoTitle",
        expertTitleKey: "noResultsThingsToDoTitle",
        subTitle: i18next.t("inbox:noResultsThingsToDoDescription"),
        expertSubTitle: "noResultsThingsToDoDescription",
        placeholderIcon: LISTING_TYPES.INBOX_THINGS_TO_DO
      }
    ],
    [
      INBOX_NOTIFICATION_CATEGORY.Surveys,
      {
        titleKey: "noResultsSurveysTitle",
        expertTitleKey: "noResultsSurveysTitle",
        subTitle: i18next.t("inbox:noResultsSurveyDescription"),
        expertSubTitle: "noResultsSurveyDescription",
        placeholderIcon: LISTING_TYPES.SURVEYS
      }
    ],
    [
      INBOX_NOTIFICATION_CATEGORY.Compliance,
      {
        titleKey: "noResultsComplianceTitle",
        subTitle: i18next.t("inbox:noResultsComplianceDescription"),
        placeholderIcon: LISTING_TYPES.INBOX_COMPLIANCE
      }
    ]
  ]
);

interface InboxEmptyProps {
  category: INBOX_NOTIFICATION_CATEGORY;
}

const InboxEmptyState = ({ category }: InboxEmptyProps): JSX.Element => {
  const { t } = useTranslation("inbox");
  const {
    titleKey,
    expertTitleKey,
    placeholderIcon,
    subTitle,
    expertSubTitle
  } = InboxEmptyStates.get(category)!;

  return (
    <>
      <AccessManager permission="inbox:categoryEmptyState">
        {titleKey && (
          <CIQEmptyPlaceholder title={t(titleKey)} itemType={placeholderIcon} />
        )}
        <div className="message-banner yellow">
          <div className="message">{subTitle}</div>
        </div>
      </AccessManager>
      <AccessManager permission="inbox:categoryExpertEmptyState">
        {expertTitleKey && (
          <CIQEmptyPlaceholder
            title={t(expertTitleKey)}
            itemType={placeholderIcon}
          />
        )}
        {expertSubTitle && (
          <div className="message-banner yellow">
            <div className="message">{t(expertSubTitle)}</div>
          </div>
        )}
      </AccessManager>
    </>
  );
};

export default InboxEmptyState;
