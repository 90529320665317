import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";

import { SelectOption } from "@arbolus-technologies/models/common";
import { TimezoneService } from "@arbolus-technologies/utils";

import styles from "./TimezonesDetails.module.scss";

interface TimezonesDetailsProps {
  expertTimezone: string;
  projectTimezone: string;
  timezones: Map<string, SelectOption>;
}

export const TimezonesDetails: React.FC<TimezonesDetailsProps> = ({
  expertTimezone,
  projectTimezone,
  timezones
}) => {
  const { t } = useTranslation("expertAvailabilityScheduler");
  const [timezoneDifference, setTimezoneDifference] = useState<string>("");
  const timezoneDisplayTxt = timezones.get(projectTimezone)?.label;
  const expertTimezoneDisplayTxt = timezones.get(expertTimezone)?.label;

  useEffect(() => {
    const timeDifferenceText = TimezoneService.getTimezoneDifference(
      expertTimezone,
      projectTimezone
    );

    setTimezoneDifference(timeDifferenceText);
  }, [expertTimezone, projectTimezone]);

  return (
    <Row className={styles.timezonesContainer}>
      <Col md={12}>
        {t("expertAvailabilitySchedulerPanel.myTimezone")}
        {": "}
        <span>{expertTimezoneDisplayTxt}</span>
      </Col>
      <Col md={12}>{timezoneDifference}</Col>
      <Col md={12}>
        {`${t(
          "expertAvailabilitySchedulerPanel.clientsTimezone"
        )}: ${timezoneDisplayTxt}`}
      </Col>
    </Row>
  );
};
