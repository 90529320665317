import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select, { MultiValue, StylesConfig } from "react-select";

import {
  ProjectExpertsSelector,
  ProjectExpertsStoreActions
} from "@arbolus-technologies/stores/project-experts-store";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { IconWithText } from "@arbolus-technologies/ui/components";

import styles from "./AngleFilter.module.scss";

export const AngleFilter: React.FC = () => {
  const { t } = useTranslation("angleFilter");
  const dispatch = useDispatch();

  const [showFilter, setShowFilter] = useState(false);
  const currentAnglesOnTable = useSelector(
    ProjectExpertsSelector.currentAnglesOnTable()
  );

  const handleChange = (
    e: MultiValue<{
      label: string;
      value: string;
    }>
  ) => {
    const values = e.map((e) => {
      if (e.label === "No Angle Assigned") {
        return null;
      }
      return e.value;
    });
    dispatch(ProjectExpertsStoreActions.filterTableByAngleS(values));
  };

  const getBackgroundColor = (
    isDisabled: boolean,
    isSelected: boolean,
    isFocused: boolean,
    data: { color: string }
  ) => {
    if (isDisabled) {
      return undefined;
    }

    if (isSelected) {
      return data.color;
    }

    if (isFocused) {
      return ARBOLUS_COLORS.transparentGrey;
    }
  };

  const colorStyles: StylesConfig<any, true> = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: getBackgroundColor(
        isDisabled,
        isSelected,
        isFocused,
        data
      ),
      color: "black",
      cursor: isDisabled ? "not-allowed" : "default"
    }),
    multiValue: (styles, { data }) => ({
      ...styles,
      backgroundColor: ARBOLUS_COLORS.bColorBaseWhite
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "black"
    }),
    multiValueRemove: (styles, { data, isFocused }) => ({
      ...styles,
      color: "black",
      "&:hover": {
        backgroundColor: "transparent",
        color: "black"
      }
    })
  };

  return showFilter ? (
    <div className={styles.container}>
      <Select
        options={currentAnglesOnTable?.map(
          ({ title: value, title: label, color }) => ({
            label: (
              <IconWithText
                text={{ content: label }}
                icon={{
                  name: "zoom_in_map",
                  color: ARBOLUS_COLORS.bColorBaseDark,
                  customStyle: { backgroundColor: color },
                  customClasses: styles.icon
                }}
                customStyle={{ borderColor: color }}
                customClasses={styles.angleContainer}
              />
            ),
            value,
            color
          })
        )}
        isMulti
        placeholder={t("selectPlaceholder")}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={handleChange}
        styles={colorStyles}
      />
      <button
        onClick={() => {
          setShowFilter(false);
          dispatch(ProjectExpertsStoreActions.resetTable());
        }}
        className={clsx("ciq-close", styles.closeIcon)}
      />
    </div>
  ) : (
    <button
      className={styles.headerContainer}
      onClick={() => setShowFilter(true)}
    >
      <span className={styles.title}>{t("angleAssigned")}</span>
      <span className={clsx("ciq-search", "ml-auto", styles.searchIcon)} />
    </button>
  );
};
