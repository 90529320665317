import { GridApi } from "ag-grid-community";
import { Icon } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  CustomersService,
  DefaultToasterService
} from "@arbolus-technologies/api";
import { useModalService } from "@arbolus-technologies/features/common";
import { Referral } from "@arbolus-technologies/models/common";

import styles from "./AddCustomerSurveyStatusPanel.module.scss";

interface CustomStatusPanelProps {
  api: GridApi<Referral>;
}

export const AddCustomerSurveyStatusPanel: React.FC<CustomStatusPanelProps> = ({
  api
}) => {
  const { t } = useTranslation("customerSurveyTable");
  const modalService = useModalService();

  const create = React.useCallback(() => {
    const action = (title: string) => {
      CustomersService.createSurveyTemplate(title).subscribe({
        next: () => {
          api.refreshServerSide();
          DefaultToasterService.showSuccess(t("createSuccess", { title }));
        },
        error: DefaultToasterService.showApiErrors
      });
    };

    modalService.openInputMainModal({
      title: t("createSurveyModalTitle"),
      confirmText: t("createSurveyModalConfirm"),
      placeholder: t("createSurveyModalPlaceholder"),
      onConfirm: action
    });
  }, [api, modalService, t]);

  return (
    <div className="flex-row-8 h-100">
      <div onClick={create} className={styles.button}>
        <Icon name="add" fontSize="18px" />
        <span>{t("createSurveyButton")}</span>
      </div>
    </div>
  );
};
