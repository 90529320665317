import { Button, Icon } from "arbolus-ui-components";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { ExpertAvailabilityPanelBase } from "../types";

import styles from "./AvailabilityActions.module.scss";

interface AvailabilityActionsProps {
  handleDeleteEvent: () => void;
  handleSubmit: () => void;
}

export const AvailabilityActions: React.FC<AvailabilityActionsProps> = ({
  handleDeleteEvent,
  handleSubmit
}) => {
  const { t } = useTranslation("expertAvailabilityScheduler");
  const {
    formState: { errors }
  } = useFormContext<ExpertAvailabilityPanelBase>();

  const hasErrors = !!Object.keys(errors).length;

  return (
    <div className={styles.buttonContainer}>
      <Icon
        name="delete"
        fontSize="24px"
        color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
        onClick={handleDeleteEvent}
      />
      <Button
        disabled={hasErrors}
        onClick={handleSubmit}
        text={t("expertAvailabilitySchedulerPanel.confirm")}
      />
    </div>
  );
};
