import {
  ArbolusFeatureFlag,
  UserRole
} from "@arbolus-technologies/models/common";

import { IconName } from "libs/antDComponents/src/lib/Icon/IconDictionary";
import { projectsRoute } from "./MenuItemsRoutes";

interface IMenuItem {
  name: string;
  icon: IconName;
  route?: string;
  position: "main" | "footer";
  userRole?: UserRole[];
  featureFlag?: ArbolusFeatureFlag;
}

export interface ISubItems extends IMenuItem {
  onClick?: () => void;
}

export interface ISideBarItems extends IMenuItem {
  subItemsTitle?: string;
  subItems?: ISubItems[];
}

export const handleActiveRoute = (route: string): boolean => {
  if (route === projectsRoute) {
    return window.location.pathname.includes("project");
  }
  return window.location.pathname === route;
};
