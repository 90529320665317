import { useFeature } from "flagged";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { EXPERT_CANOPY_STATUS } from "@arbolus-technologies/models/canopy";
import { FEATURE_FLAGS } from "@arbolus-technologies/models/common";
import {
  CANOPY_APPLICATION_PAGE_ROUTE,
  CANOPY_DETAILS_ROUTE
} from "@arbolus-technologies/routes";
import {
  BoxWithChildren,
  ChevronButton,
  ColoredBadge
} from "@arbolus-technologies/ui/components";

import "react-placeholder/lib/reactPlaceholder.css";

import { CANOPY_STATUS_COLORS } from "../../helpers/constants";

import styles from "./CanopyCard.module.scss";

interface CanopyCardProps {
  id: string;
  title: string;
  status: EXPERT_CANOPY_STATUS;
}

export const CanopyCard: React.FC<CanopyCardProps> = ({
  id,
  title,
  status
}) => {
  const { t } = useTranslation("expertCanopyList");
  const newExpertApplicationFF = useFeature(FEATURE_FLAGS.NewExpertApplication);

  const colorValue = CANOPY_STATUS_COLORS[status];
  const isCanopyIncomplete = status === EXPERT_CANOPY_STATUS.INCOMPLETE;
  const route =
    isCanopyIncomplete && newExpertApplicationFF
      ? CANOPY_APPLICATION_PAGE_ROUTE(id)
      : CANOPY_DETAILS_ROUTE(id);

  return (
    <BoxWithChildren classnames={styles.card}>
      <>
        <ColoredBadge
          text={isCanopyIncomplete ? t("new") : t("submitted")}
          backgroundColor={colorValue}
        />
        <Link to={route}>
          <h4 className={styles.title} title={title}>
            {title}
          </h4>
        </Link>
        <div className={styles.footer}>
          <ChevronButton
            to={route}
            text={isCanopyIncomplete ? t("answerNow") : t("reviewAnswers")}
            icon={{ direction: "right", position: "end" }}
          />
        </div>
      </>
    </BoxWithChildren>
  );
};
