import { GetContextMenuItemsParams, MenuItemDef } from "ag-grid-community";

import { DefaultClipboardManager } from "@arbolus-technologies/features/common";
import {
  DO_NOT_CONTACT_STATUS,
  Referral
} from "@arbolus-technologies/models/common";

import {
  IBulkItemActionParams,
  ItemActionParams,
  ReferralGridContext
} from "../../Models/ReferralsTable";
import { BULK_ACTIONS } from "../../Modules/ReferralsTable/statusPanels/BulkActionsStatusPanel";
import { DefaultBulkReferralActionsService } from "./BulkReferralActionsService";
import { ContextMenuService } from "./ContextMenuService";
import { DefaultExportMenuService } from "./ExportMenuService";

export class SchedulingMenuService extends ContextMenuService {
  getItems = ({
    value,
    api,
    node,
    context
  }: GetContextMenuItemsParams<Referral, ReferralGridContext>): (
    | string
    | MenuItemDef
  )[] => {
    if (value === BULK_ACTIONS) {
      const bulkParams: IBulkItemActionParams = {
        api,
        ...context
      };
      return [
        this.getAddToAnotherProjectItem(bulkParams),
        this.exportMenuService.getExportMenuItems(bulkParams, "Scheduling")
      ];
    }

    if (!node?.data || node.group) {
      return [];
    }

    const params: ItemActionParams = {
      api,
      referral: node.data,
      ...context
    };

    if (
      params.referral.expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC
    ) {
      return [];
    }

    return [
      this.getCopyBookingItem(params),
      "separator",
      this.getCopyEmailItem(params),
      this.getCopyPhoneItem(params)
    ];
  };
}

export const DefaultSchedulingMenuService = new SchedulingMenuService(
  DefaultExportMenuService,
  DefaultBulkReferralActionsService,
  DefaultClipboardManager
);
