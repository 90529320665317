import clsx from "clsx";
import React from "react";

import styles from "./CrossButton.module.scss";

interface CrossButtonProps {
  onClick: () => void;
}

export const CrossButton: React.FC<CrossButtonProps> = ({ onClick }) => (
  <button
    className={clsx("material-symbols-sharp", styles.icon)}
    onClick={onClick}
  >
    Close
  </button>
);
