import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { DNCHistory, ExpertUser } from "@arbolus-technologies/api";
import {
  DNC_CATEGORY_ENUM,
  DO_NOT_CONTACT_STATUS,
  DateString
} from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { PanelId } from "@arbolus-technologies/stores/panels";
import { DncInfoBox, ExpertHeader } from "@arbolus-technologies/ui/components";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { DNCHistoryList } from "../../Components/DNC/DNCHistoryList/DNCHistoryList";
import { SlidePanel } from "../../Panels/SlidePanel/SlidePanel";
import { DNCSection } from "./DNCSection/DNCSection";

import styles from "./ExpertDncStatusSlidePanel.module.scss";

interface ExpertDncStatusSlidePanelProps {
  expertUser: ExpertUser;
  expertId: string;
  lastPublicCompanyExpDate: DateString | null;
}

export const ExpertDncStatusSlidePanel: React.FC<
  ExpertDncStatusSlidePanelProps
> = ({ expertUser, expertId, lastPublicCompanyExpDate }) => {
  const { t } = useTranslation("expertStatus");

  const [shouldRefetchHistory, setShouldRefetchHistory] =
    useState<boolean>(true);
  const [expertDncHistory, setExpertDncHistory] = useState<DNCHistory | null>(
    null
  );

  const metadataCountries = useSelector(CacheSelector.metadataCountries());

  const expertCountry = metadataCountries.find(
    (metadataCountry) =>
      metadataCountry.threeLetterCode === expertUser.isoCountryCode
  );

  return (
    <SlidePanel
      panelId={PanelId.ExpertStatus}
      width={SIDE_PANEL_SIZE._720}
      title={t("expertStatus")}
      closeButtonDirection="right"
    >
      <div className={styles.container}>
        <ExpertHeader
          expertUser={expertUser}
          countryTwoLetterCode={expertCountry?.twoLetterCode ?? null}
          lastPublicCompanyDate={lastPublicCompanyExpDate}
        />
        {expertDncHistory?.currentDoNotContactStatusCategory ===
          DNC_CATEGORY_ENUM.COMPANY_DNC && (
          <DncInfoBox
            doNotContactStatus={expertDncHistory.currentDoNotContactStatus}
            doNotContactStatusCategory={
              expertDncHistory.currentDoNotContactStatusCategory
            }
            isAdmin
          />
        )}
        {expertDncHistory && (
          <DNCSection
            doNotContactStatus={
              expertDncHistory?.items[0]?.doNotContactStatus ??
              DO_NOT_CONTACT_STATUS.OK
            }
            doNotContactStatusCategory={
              expertDncHistory.items[0]?.doNotContactCategory
            }
            onUpdateDnc={() => setShouldRefetchHistory(true)}
          />
        )}
        <DNCHistoryList
          expertId={expertId}
          canEditComment
          shouldRefetchHistory={shouldRefetchHistory}
          handleSetShouldRefetchHistory={(shouldRefetchHistory) =>
            setShouldRefetchHistory(shouldRefetchHistory)
          }
          updateExpertDncHistory={(dncHistory) =>
            setExpertDncHistory(dncHistory)
          }
        />
      </div>
    </SlidePanel>
  );
};
