import dompurify from "dompurify";
import React from "react";
import { useTranslation } from "react-i18next";

import { Drawer, LoaderOrComponent } from "@arbolus-technologies/ui/components";

import { StepHeader } from "../../Components/StepHeader/StepHeader";

import styles from "./ExpertApplicationWrapper.module.scss";

interface ExpertApplicationWrapperProps {
  name: string;
  currentStep: number;
  isLoading?: boolean;
  children: React.ReactNode;
}

export const ExpertApplicationWrapper: React.FC<
  ExpertApplicationWrapperProps
> = ({ name, currentStep, isLoading = false, children }) => {
  const { t } = useTranslation("expertApplications");

  return (
    <div className={styles.container}>
      <Drawer
        titleChildren={() => (
          <StepHeader title={t(name)} stepNum={currentStep + 1} />
        )}
        openByDefault
        disableCollapse
      >
        <div
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(t(`${name} Description`))
          }}
        />
      </Drawer>
      <LoaderOrComponent isLoading={isLoading}>{children}</LoaderOrComponent>
    </div>
  );
};
