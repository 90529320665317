import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import {
  CIQError,
  CanopyService,
  ErrorResponse
} from "@arbolus-technologies/api";
import { ShortTextTypeViewer } from "@arbolus-technologies/features/common";
import { CanopyParamUrlTypes } from "@arbolus-technologies/models/canopy";
import { ShortTextAnswer } from "@arbolus-technologies/models/canopy-panels";
import { CanopyV2Selector } from "@arbolus-technologies/stores/canopy-V2";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { InfiniteScrollV2 } from "@arbolus-technologies/ui/components";

import styles from "./QuestionShortTextAnswers.module.scss";

interface QuestionShortTextAnswersProps {
  canopyService?: typeof CanopyService;
}

export const QuestionShortTextAnswers: React.FC<
  QuestionShortTextAnswersProps
> = ({ canopyService = CanopyService }) => {
  const dispatch = useDispatch();
  const { canopyQuestionId, canopyId } = useParams<CanopyParamUrlTypes>();

  const [shortTextAnswers, setShortTextAnswers] = useState<ShortTextAnswer[]>(
    []
  );
  const [isShortTextAnswersLoading, setIsShortTextAnswersLoading] =
    useState<boolean>(false);
  const [isMore, setIsMore] = useState<boolean>(false);

  const canopyProjectId = useSelector(CanopyV2Selector.canopyProjectId());

  useEffect(() => {
    getQuestionShortTextAnswers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getQuestionShortTextAnswers = () => {
    setIsShortTextAnswersLoading(true);

    canopyService
      .getCanopyQuestionShortTextAnswers(
        canopyQuestionId,
        shortTextAnswers.length
      )
      .subscribe(
        ({ items, pagination }) => {
          const updatedShortTextAnswers = shortTextAnswers.concat(items);

          setShortTextAnswers(updatedShortTextAnswers);
          setIsMore(
            items.length
              ? updatedShortTextAnswers.length < pagination.count
              : false
          );
          setIsShortTextAnswersLoading(false);
        },
        (error: ErrorResponse<CIQError>) => {
          setIsShortTextAnswersLoading(false);
        }
      );
  };

  const handleBottomReached = () => {
    if (!isMore || isShortTextAnswersLoading) {
      return;
    }

    getQuestionShortTextAnswers();
  };

  const renderShortTextAnswer = (shortTextAnswer: ShortTextAnswer) => (
    <ShortTextTypeViewer
      key={shortTextAnswer.expertId}
      data={shortTextAnswer}
      handleOpenProfile={handleOpenProfile}
    />
  );

  const handleOpenProfile = (expertId: string) => {
    dispatch(
      PanelStoreActions.openPanel(PanelId.ExpertProfile, {
        expertId,
        projectId: canopyProjectId,
        canopyId
      })
    );
  };

  return (
    <div className={styles.infiniteContainer}>
      <InfiniteScrollV2
        onBottomReached={handleBottomReached}
        items={shortTextAnswers}
        renderer={renderShortTextAnswer}
        isLoading={isShortTextAnswersLoading}
      />
    </div>
  );
};
