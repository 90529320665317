import { OutputSelector, createSelector } from "reselect";

import { LoggedInUser } from "@arbolus-technologies/api";

import { CacheStateSelector } from "../CacheSelector";
import { CacheAppState, CacheReducerState } from "../models/definitions";

export const loggedInUser = (): OutputSelector<
  CacheAppState,
  LoggedInUser,
  (res: CacheReducerState) => LoggedInUser
> =>
  createSelector<CacheAppState, CacheReducerState, LoggedInUser>(
    CacheStateSelector,
    (cacheReducerState) => cacheReducerState.loggedInUser
  );
