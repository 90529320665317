import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { UncontrolledTooltip } from "reactstrap";

import {
  INBOX_NOTIFICATION_FORMAT,
  formatDate
} from "@arbolus-technologies/utils";

import { INBOX_NOTIFICATION_CATEGORY } from "../../../../../constants/notifications";
import { InboxNotification } from "../../../../../models/notifications";
import { InboxService } from "../../../../../services";
import { InboxStoreActions } from "../../../../app/store";

interface NotificationItemProps {
  isLoading: boolean;
  onDelete: () => void;
  notification: InboxNotification;
  onNotificationClick: () => void;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  onDelete,
  isLoading,
  onNotificationClick
}): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation("inbox");
  const { created, seen, id, notificationType } = notification;
  const timeLabel = formatDate(created, INBOX_NOTIFICATION_FORMAT);
  const {
    title,
    subTitle,
    subInfo,
    icon,
    link,
    openNewTab,
    hideTime,
    data,
    linkToInboxSidePanel
  } = InboxService.generateInboxView(notification);

  const { ThingsTodo, Compliance } = INBOX_NOTIFICATION_CATEGORY;

  const isThingsToDo = notificationType === ThingsTodo;
  const isActionEnabled = notificationType !== Compliance;

  const handleNavigateContent = (): void => {
    if (link) {
      if (openNewTab || linkToInboxSidePanel) {
        onNotificationClick();
      }

      if (openNewTab) {
        window.open(link, "_blank");
      } else {
        history.push(link, data);
      }

      if (!seen)
        dispatch(
          InboxStoreActions.markNotificationsAsRead(id, notificationType)
        );
    }
  };

  return (
    <tr
      className={clsx("notification-item", {
        seen
      })}
    >
      <td className="icon-cell" onClick={handleNavigateContent}>
        <div className={`notification-avatar ${icon}`}>
          {!seen && <div className="notification-dot" />}
        </div>
      </td>
      <td className="info-cell" onClick={handleNavigateContent}>
        <span className="title">{title}</span>
        <div>
          <div className="sub-info">
            {subTitle}
            {subInfo && (
              <>
                {" "}
                <span>&middot;</span> {subInfo}
              </>
            )}
          </div>
        </div>
      </td>
      <td
        className={clsx("time-cell desktop-only", {
          "no-action": !isActionEnabled
        })}
      >
        {!hideTime && timeLabel}
      </td>
      {isActionEnabled && (
        <>
          <td className="action-cell desktop-only">
            <div
              className={clsx("mark-as-done", {
                animate: isLoading,
                disabled: isLoading
              })}
              onClick={onDelete}
              id={`tooltip-${id}`}
            >
              <span className="ciq-icon ciq-check" />
            </div>
          </td>
          <td className="action-cell mobile-only">
            <div
              className={clsx("mark-as-done", {
                animate: isLoading,
                disabled: isLoading
              })}
              onClick={onDelete}
            >
              <span
                className={clsx("ciq-icon", {
                  "ciq-trash": !isThingsToDo,
                  "ciq-check": isThingsToDo
                })}
              />
            </div>
          </td>
          <UncontrolledTooltip
            placement="bottom"
            trigger="hover"
            hideArrow
            target={`tooltip-${id}`}
            popperClassName="notification-item-tool-tip"
          >
            {t("markAsDone")}
          </UncontrolledTooltip>
        </>
      )}
    </tr>
  );
};

export default NotificationItem;
