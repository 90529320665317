import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  ProjectAnglesSelector,
  ProjectAnglesStoreActions
} from "@arbolus-technologies/stores/project-angles-store";
import { CustomSelect } from "@arbolus-technologies/ui/components";

import { AngleInvitationLink } from "./AngleInvitationLink/AngleInvitationLink";

import styles from "./InviteDirectory.module.scss";

interface InviteDirectoryProps {
  angleId?: string;
  projectId: string;
}

export const InviteDirectory: React.FC<InviteDirectoryProps> = ({
  angleId,
  projectId
}) => {
  const { t } = useTranslation("inviteExperts");
  const dispatch = useDispatch();

  const [link, setLink] = useState<string>("https://");
  const linkOptions = useSelector(ProjectAnglesSelector.anglesCardLink());
  const isLoadingLinks = useSelector(
    ProjectAnglesSelector.isLoadingAngleLinks()
  );

  useEffect(() => {
    dispatch(ProjectAnglesStoreActions.getAnglesLink(projectId, angleId));
    return () => {
      dispatch(ProjectAnglesStoreActions.resetAnglesLink());
    };
  }, [projectId, dispatch, angleId]);

  useEffect(() => {
    if (linkOptions?.length === 1 && linkOptions[0].value !== null) {
      setLink(linkOptions[0].value);
    }
  }, [linkOptions]);

  return (
    <div className={styles.inviteDirectoryContainer}>
      <h2 className={styles.inviteDirectoryTitle}>{t("inviteDirectory")}</h2>
      <div>
        <p className={styles.inviteDirectoryParagraph}>
          {t("inviteDirectoryText1")}
        </p>
        <p className={styles.inviteDirectoryParagraph}>
          {t("inviteDirectoryText2")}
        </p>
      </div>
      <p className={styles.inviteDirectoryParagraph}>
        {t("inviteDirectoryText3")}
      </p>
      {!isLoadingLinks && linkOptions && (
        <>
          <CustomSelect
            noOptionsMessage={t("selectAngle")}
            placeholder={t("selectAngle")}
            onSelectChange={(value) => setLink(value)}
            options={linkOptions}
            displayIcon
            defaultValue={linkOptions.length === 1 ? linkOptions[0] : undefined}
            customStyle={styles.customSelect}
            disabled={linkOptions.length === 1 && linkOptions[0].value === null}
          />
          <AngleInvitationLink link={link} />
        </>
      )}
    </div>
  );
};
