import clsx from "clsx";
import React, { ReactNode } from "react";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router";

import { BackButton } from "../../Buttons/components/BackButton/BackButton";

import styles from "./HeaderWithBackButton.module.scss";

interface HeaderWithBackButtonProps {
  title?: string;
  backButton: {
    title: string;
    desktopRoute: string;
    mobileRoute: string;
  };
  classnames?: string[] | string;
  children?: ReactNode;
}

export const HeaderWithBackButton: React.FC<HeaderWithBackButtonProps> = ({
  backButton,
  title,
  classnames,
  children
}) => {
  const history = useHistory();
  return (
    <div className={clsx(classnames)}>
      <div className={styles.backButtonContainer}>
        <BackButton
          onClick={() =>
            history.push(
              isMobile ? backButton.mobileRoute : backButton.desktopRoute
            )
          }
          title={backButton.title}
        />
      </div>
      <div className={styles.container}>
        {title && <h1 className={styles.title}>{title}</h1>}
        {children}
      </div>
    </div>
  );
};
