import { ActionCreator } from "redux";

import { CIQError, ErrorResponse, ListCanopy } from "@arbolus-technologies/api";
import { PreviousPage } from "@arbolus-technologies/models/canopy";

import {
  ARCHIVE_CANOPY,
  ArchiveCanopyAction,
  GET_CANOPIES,
  GET_CANOPIES_FAILURE,
  GET_CANOPIES_SUCCESS,
  GetCanopiesAction,
  GetCanopiesFailureAction,
  GetCanopiesSuccessAction,
  SET_CANOPY_PREVIOUS_PAGE,
  SetCanopyPreviousPageAction
} from "./ProjectCanopyActionTypes";

export const getCanopies: ActionCreator<GetCanopiesAction> = (
  projectId: string
) => ({
  type: GET_CANOPIES,
  payload: {
    projectId
  }
});

export const getCanopiesSuccess: ActionCreator<GetCanopiesSuccessAction> = (
  canopies: ListCanopy[]
) => ({
  type: GET_CANOPIES_SUCCESS,
  payload: {
    canopies
  }
});

export const getCanopiesFailure: ActionCreator<GetCanopiesFailureAction> = (
  error: ErrorResponse<CIQError>
) => ({
  type: GET_CANOPIES_FAILURE,
  payload: {
    error
  }
});

export const setCanopyPreviousPage: ActionCreator<
  SetCanopyPreviousPageAction
> = (previousPage: PreviousPage) => ({
  type: SET_CANOPY_PREVIOUS_PAGE,
  payload: {
    previousPage
  }
});

export const archiveCanopy: ActionCreator<ArchiveCanopyAction> = (
  canopyId: string
) => ({
  type: ARCHIVE_CANOPY,
  payload: {
    canopyId
  }
});

export const ProjectCanopyStoreActions = {
  getCanopies,
  getCanopiesSuccess,
  getCanopiesFailure,
  setCanopyPreviousPage,
  archiveCanopy
};
