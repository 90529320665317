import { Button } from "arbolus-ui-components";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  CONTACT_TYPE_ENUM,
  ExternalExpertFormEnum,
  ExternalExpertFormValues
} from "@arbolus-technologies/models/expert";

import styles from "./ExternalExpertFormActions.module.scss";

interface ExternalExpertFormActionsProps {
  isUpdating: boolean;
  isInternal: boolean;
  onUpdate: (data: ExternalExpertFormValues) => void;
  moveToShortlist: () => void;
}

export const ExternalExpertFormActions: React.FC<
  ExternalExpertFormActionsProps
> = ({ isUpdating, isInternal, onUpdate, moveToShortlist }) => {
  const { t } = useTranslation("externalExpertForm");

  const {
    formState: { isDirty, isValid, errors },
    getValues
  } = useFormContext<ExternalExpertFormValues>();

  const { customEmail, customPhone, projectPipelineStatus } = getValues();

  const canShortlist =
    isValid &&
    !isDirty &&
    !isUpdating &&
    projectPipelineStatus !== CONTACT_TYPE_ENUM.REJECTED_DECLINED;

  const canUpdate =
    isDirty &&
    !isUpdating &&
    !customEmail &&
    !customPhone &&
    !isInternal &&
    typeof errors[ExternalExpertFormEnum.RATE_CARD]?.price === "undefined";

  const handleUpdate = () => {
    const data = getValues();
    onUpdate(data);
  };

  return (
    <div className={styles.btnContainer}>
      <Button
        type="secondary"
        text={t("moveToShortlist")}
        onClick={moveToShortlist}
        disabled={!canShortlist}
      />
      <Button text={t("update")} onClick={handleUpdate} disabled={!canUpdate} />
    </div>
  );
};
