import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { MessageActionType } from "@arbolus-technologies/api";
import { ChatUserRole } from "@arbolus-technologies/models/project";
import { PROJECT_AVAILABILITY_ROUTE } from "@arbolus-technologies/routes";

import { useChat } from "../../../../../../Contexts/ChatContext/ChatContext";

import styles from "./ChatAvailabilityButtons.module.scss";

interface ChatAvailabilityButtonsProps {
  isOutgoingMessage: boolean;
  messageAction: MessageActionType | undefined;
  openAvailabilityPanel: () => void;
}

export const ChatAvailabilityButtons: React.FC<
  ChatAvailabilityButtonsProps
> = ({ isOutgoingMessage, messageAction, openAvailabilityPanel }) => {
  const { t } = useTranslation("chat");
  const { push } = useHistory();
  const { chatSenderRole, projectId } = useChat();

  const hasViewAvailabilityButton =
    !isOutgoingMessage &&
    messageAction === MessageActionType.EXPERT_AVAILABILITY &&
    chatSenderRole !== ChatUserRole.Expert;
  const hasRequireAvailabilityButton =
    !isOutgoingMessage &&
    messageAction === MessageActionType.REQUIRE_AVAILABILITY &&
    chatSenderRole !== ChatUserRole.Client;
  const goToAvailabilityScheduler = () => {
    if (!projectId) return;
    push(PROJECT_AVAILABILITY_ROUTE(projectId, true));
  };

  return (
    <>
      {hasViewAvailabilityButton && (
        <div className={styles.availabilityButton}>
          <Button
            onClick={() => openAvailabilityPanel()}
            text={t("viewAvailabilityButton")}
          />
        </div>
      )}
      {hasRequireAvailabilityButton && (
        <div className={styles.availabilityButton}>
          <Button
            type={
              chatSenderRole === ChatUserRole.Admin ? "secondary" : "primary"
            }
            onClick={goToAvailabilityScheduler}
            text={t("requireAvailabilityButton")}
          />
        </div>
      )}
    </>
  );
};
