import i18next from "i18next";

import {
  CustomersService,
  DefaultToasterService
} from "@arbolus-technologies/api";
import { CustomerSurveyCompany } from "@arbolus-technologies/models/common";

export function requestUpdateCompanies(
  surveyId: string,
  companies: CustomerSurveyCompany[]
) {
  const filtered = companies.map((company) => ({
    companyId: company.id,
    visible: company.visible
  }));

  CustomersService.updateSurveyTemplateCompanies(surveyId, filtered).subscribe({
    next: () => {
      DefaultToasterService.showSuccess(
        i18next.t("customerSurveyTable:updateSuccess")
      );
    },
    error: DefaultToasterService.showApiErrors
  });
}
