import { Button } from "arbolus-ui-components";
import dompurify from "dompurify";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  EngagementAgreement,
  ProjectReferral,
  ProjectService
} from "@arbolus-technologies/api";
import { Drawer } from "@arbolus-technologies/ui/components";

import styles from "./EngagementAgreementInfo.module.scss";

interface EngagementAgreementInfoProps {
  engagementAgreement: EngagementAgreement;
  isAgreementAccepted: boolean;
  nextStepName: string;
  referral: ProjectReferral;
  goToWelcomeScreen: () => void;
  goToNextStep: () => void;
}

export const EngagementAgreementInfo: React.FC<
  EngagementAgreementInfoProps
> = ({
  engagementAgreement,
  isAgreementAccepted,
  nextStepName,
  referral,
  goToWelcomeScreen,
  goToNextStep
}) => {
  const { t } = useTranslation("expertApplications");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleAcceptEngagementAgreement = () => {
    goToNextStep();
  };

  const { headerTitle, headerText, agreementTerms, footerTitle, footerText } =
    engagementAgreement;

  return (
    <>
      {headerTitle && (
        <Drawer title={headerTitle} openByDefault>
          {headerText}
        </Drawer>
      )}
      {agreementTerms.map((agreement) => (
        <Drawer key={agreement.termTitle} title={agreement.termTitle}>
          {agreement.termText}
        </Drawer>
      ))}
      {footerTitle && (
        <Drawer title={footerTitle} openByDefault disableCollapse>
          <div
            dangerouslySetInnerHTML={{
              __html: dompurify.sanitize(footerText || "")
            }}
          />
        </Drawer>
      )}
      <div className={styles.btnContainer}>
        <Button
          type="tertiary"
          text={t("cancel")}
          onClick={goToWelcomeScreen}
        />
        <Button
          type="secondary"
          text={t("download")}
          endIcon="download"
          disabled={isLoading}
        />
        {isAgreementAccepted ? (
          <Button
            text={t("nextStep", { step: nextStepName })}
            onClick={goToNextStep}
          />
        ) : (
          <Button
            text={t("acceptAgreement")}
            disabled={isLoading}
            onClick={handleAcceptEngagementAgreement}
          />
        )}
      </div>
    </>
  );
};
