import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import { LocationDescriptorObject } from "history";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

import {
  AngleModel,
  ExpertTaglineModel,
  TranscriptModel
} from "@arbolus-technologies/models/common";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { transformedDateTime } from "@arbolus-technologies/utils";

import { AssignedAngle } from "../AssignedAngle/AssignedAngle";
import { UserPortrait } from "../Portraits/components/UserPortrait/UserPortrait";

import { useTranslation } from "react-i18next";
import styles from "./TranscriptCard.module.scss";

interface TranscriptCardProps {
  transcript: TranscriptModel;
  angle?: AngleModel;
  expert?: ExpertTaglineModel;
  timezone: string;
  to: LocationDescriptorObject | string;
  handleDownloadDocx: (id: string) => void;
}

export const TranscriptCard: React.FC<TranscriptCardProps> = ({
  transcript,
  angle,
  expert,
  timezone,
  to,
  handleDownloadDocx
}) => {
  const { id, durationSeconds, created } = transcript;
  const { t } = useTranslation("projectTranscripts");
  return (
    <div className={styles.container}>
      {expert ? (
        <Link to={to} className={styles.wrapperUserName}>
          <UserPortrait
            hideAvatar
            user={{ ...expert, title: expert.tagline ?? "" }}
            isTruncate
          />
        </Link>
      ) : (
        <span className={clsx(styles.wrapperUserName, "text-left")}>
          {transcript.name}
        </span>
      )}
      {angle && (
        <div className={styles.wrapperAngle}>
          <AssignedAngle angle={angle} />
        </div>
      )}
      <div className={styles.wrapperTime}>
        <p className={styles.date}>
          {transformedDateTime({
            startTime: created,
            timezone: timezone,
            showMonth: true
          })}
        </p>
        {durationSeconds && (
          <p className={styles.date}>
            {moment.utc(durationSeconds * 1000).format("H:mm:ss")}
          </p>
        )}
      </div>
      <div className={styles.wrapperIcons}>
        {transcript.expiresIn <= 7 && (
          <Icon
            name="warning"
            fontSize="24px"
            tooltip={t("transcriptExpiresIn", {
              days: transcript.expiresIn
            })}
            color={ARBOLUS_COLORS.bColorAccentOrangeDark}
          />
        )}
        <Link to={to}>
          <Icon
            name="visibility"
            fontSize="24px"
            color={ARBOLUS_COLORS.bColorGrayIcon}
          />
        </Link>
        <Icon
          name="download"
          fontSize="24px"
          color={ARBOLUS_COLORS.bColorGrayIcon}
          onClick={() => handleDownloadDocx(id)}
        />
      </div>
    </div>
  );
};
