import moment from "moment";
import React from "react";

import { QuestionAnswer } from "@arbolus-technologies/api";
import { AssignedAngle } from "@arbolus-technologies/features/common";
import { AngleModel } from "@arbolus-technologies/models/common";
import { Avatar } from "@arbolus-technologies/ui/components";
import { APP_DATE_FORMAT } from "@arbolus-technologies/utils";

import styles from "./ScreeningCardItem.module.scss";

export interface ScreeningCardItemProps {
  question: {
    angle: AngleModel;
    client: {
      id: string;
      name: string;
      logoUrl: string;
    };
    project: {
      id: string;
      name: string;
    };
    projectQuestionsAnswers: QuestionAnswer[];
    referralApplicationUpdateDate: string;
  };
}

export const ScreeningCardItem: React.FC<ScreeningCardItemProps> = ({
  question
}) => {
  const {
    angle,
    client,
    project,
    projectQuestionsAnswers,
    referralApplicationUpdateDate
  } = question;

  const dateFormatted = moment(referralApplicationUpdateDate).format(
    APP_DATE_FORMAT
  );

  return (
    <div className={styles.cardContainer}>
      <div className={styles.header}>
        <div className={styles.logoNameWrapper}>
          <Avatar
            avatar={{
              name: client.name,
              imageUrl: client.logoUrl
            }}
          />
          <span className={styles.companyName}>{client.name}</span>
        </div>
        <span className={styles.date}>{dateFormatted}</span>
      </div>
      <hr />
      <span className={styles.title}>{project.name}</span>
      <AssignedAngle angle={angle} angleClassName={styles.angle} />
      <div className={styles.questionsContainer}>
        {projectQuestionsAnswers.map((q, key) => (
          <div className={styles.questionWrapper} key={q.projectQuestion.id}>
            <span className={styles.question}>
              {`${key + 1}. ${q.projectQuestion.question}`}
            </span>
            <span className={styles.answer}>{q.projectAnswer.answer}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
