import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { INBOX_NOTIFICATION_CATEGORY } from "../../../../constants/notifications";
import { AppSelector } from "../../../app/store";
import CategoryItem from "./categoryItem/CategoryItem";

interface InboxCategoryListProps {
  onCategorySelect: (category: INBOX_NOTIFICATION_CATEGORY) => void;
  activeCategory: INBOX_NOTIFICATION_CATEGORY;
  categories: INBOX_NOTIFICATION_CATEGORY[];
}

const InboxCategoryList: React.FC<InboxCategoryListProps> = ({
  onCategorySelect,
  activeCategory,
  categories
}): JSX.Element => {
  const [selectedCategory, setSelectedCategory] = useState(activeCategory);
  const notificationCount = useSelector(
    AppSelector.appInboxCategoriesSelector()
  );

  useEffect(() => {
    setSelectedCategory(activeCategory);
  }, [activeCategory]);

  const handleCategoryClick = (category: INBOX_NOTIFICATION_CATEGORY): void => {
    onCategorySelect(category);
    setSelectedCategory(category);
  };

  return (
    <div className="categories-container">
      {categories.map((category) => (
        <CategoryItem
          key={category}
          onClick={(): void => handleCategoryClick(category)}
          isActive={selectedCategory === category}
          unreadCount={notificationCount.get(category) || 0}
          type={category}
        />
      ))}
    </div>
  );
};

export default InboxCategoryList;
