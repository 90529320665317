import { UserRole } from "@arbolus-technologies/models/common";

export const generateActiveUserRoleF = (userRoles: string[]): UserRole => {
  const isExpert = userRoles.includes("Expert");
  const isAdmin = userRoles.includes("Admin");
  const isManager = userRoles.includes("Manager");
  const isCIQAdmin = userRoles.includes("CIQAdmin");

  if (isAdmin && isManager && isCIQAdmin) return UserRole.arbolusTeam;
  if (isExpert) return UserRole.expert;

  if (isAdmin && isManager) return UserRole.clientAdmin;
  if (isManager) return UserRole.client;

  return UserRole.guest;
};
