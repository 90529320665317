import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { Badge } from "../Badges/Badge";

import styles from "./ColoredBadge.module.scss";

const badgeColors = {
  green: ARBOLUS_COLORS.bColorSecondaryGreen,
  amber: ARBOLUS_COLORS.bColorSecondaryAmber,
  red: ARBOLUS_COLORS.bColorBaseOrange,
  grey: ARBOLUS_COLORS.bColorSecondaryGreyMid,
  orange: ARBOLUS_COLORS.bColorBaseOrange,
  purple: ARBOLUS_COLORS.bColorBasePurple
} as const;

export interface ColoredBadgeProps {
  text: string;
  backgroundColor: keyof typeof badgeColors;
}

export const ColoredBadge: React.FC<ColoredBadgeProps> = ({
  text,
  backgroundColor
}) => (
  <div className={styles.coloredBadge}>
    <Badge
      text={text}
      background={badgeColors[backgroundColor]}
      textStyle="capitalize"
      customStyle={{ fontSize: "12px" }}
    />
  </div>
);
