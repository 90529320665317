import firebase from "firebase/app";
import "firebase/messaging";
import { Subscription } from "rxjs";

import { ERROR_TYPE, utilService } from "@arbolus-technologies/utils";

import { userService } from "./UserService";

import { MetaService } from ".";

const firebaseConfig = {
  apiKey: "AIzaSyDc5obSU_b7l28JvKgB9nCRe1z-IAfk2Lk",
  authDomain: "impressive-gear-253503.firebaseapp.com",
  databaseURL: "https://impressive-gear-253503.firebaseio.com/",
  projectId: "impressive-gear-253503",
  storageBucket: "impressive-gear-253503.appspot.com",
  messagingSenderId: "870520709057",
  appId: "1:870520709057:web:7d40161b966200210ed940",
  measurementId: "G-10FF3NG3TM"
};

class FirebaseService {
  sendFcmTokenSubscription?: Subscription;

  tokenFailureSubscription?: Subscription;

  constructor() {
    firebase.initializeApp(firebaseConfig);
  }

  deleteToken = (userId?: string): Promise<void> =>
    new Promise((resolve, reject) => {
      if (firebase.messaging.isSupported()) {
        firebase
          .messaging()
          .deleteToken()
          .then(() => resolve())
          .catch((error: string) => {
            const errorData = utilService.getCrashAnalyticsData({
              crashId: utilService.generateUUID(),
              errorMessage: error,
              errorType: ERROR_TYPE.FIREBASE_DELETE_TOKEN_FAILURE,
              userId
            });

            this.tokenFailureSubscription =
              MetaService.sendAppCrashData(errorData).subscribe();

            reject(ERROR_TYPE.FIREBASE_DELETE_TOKEN_FAILURE);
          });
      } else {
        resolve();
      }
    });

  sendFcmToken = (fcmToken: string): void => {
    this.sendFcmTokenSubscription = userService
      .sendFcmToken(fcmToken)
      .subscribe();
  };

  getNewToken = (userId: string): void => {
    if (firebase.messaging.isSupported()) {
      firebase
        .messaging()
        .getToken()
        .then((token) => {
          this.sendFcmToken(token);
        })
        .catch((error: string) => {
          const errorData = utilService.getCrashAnalyticsData({
            crashId: utilService.generateUUID(),
            errorType: ERROR_TYPE.FIREBASE_GET_TOKEN_FAILURE,
            errorMessage: error,
            userId
          });

          this.tokenFailureSubscription =
            MetaService.sendAppCrashData(errorData).subscribe();
        });
    }
  };

  unregisterServiceWorker = (): void => {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.map((registration) => registration.unregister());
    });
  };
}

export default new FirebaseService();
