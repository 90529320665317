import { ICellRendererParams } from "ag-grid-community";
import React from "react";

import { ProjectModel } from "@arbolus-technologies/models/common";
import { ProjectStatusCircle } from "@arbolus-technologies/ui/components";

import styles from "./StatusRenderer.module.scss";

type StatusRendererProps = ICellRendererParams<ProjectModel, never, never>;

export const StatusRenderer: React.FC<StatusRendererProps> = ({ data }) => (
  <div className={styles.container}>
    <ProjectStatusCircle status={data!.state} size={8} />
  </div>
);
