import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "arbolus-ui-components";
import React, { useState } from "react";
import { FieldError, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Label } from "reactstrap";

import {
  CIQError,
  ErrorResponse,
  ExpertService,
  InternalComment,
  NewInternalComment,
  ToasterService
} from "@arbolus-technologies/api";
import { ClientAdminSelector } from "@arbolus-technologies/features/common";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import {
  InputController,
  Loader,
  RichTextEditorController
} from "@arbolus-technologies/ui/components";

import {
  CreateNewCommentBase,
  INTERNAL_COMMENT_COMMENT,
  INTERNAL_COMMENT_SELECT_CLIENT,
  INTERNAL_COMMENT_TITLE
} from "../../../helpers/types";
import { NewCommentSchema } from "./CreateUpdateCommentSchema";

import styles from "./CreateUpdateComment.module.scss";

const notificationService = new ToasterService();

interface CreateUpdateCommentProps {
  expertId: string;
  selectedComment?: InternalComment;
  setSelectedComment: () => void;
  handleHasToUpdateInternalComments: () => void;
}

export const CreateUpdateComment: React.FC<CreateUpdateCommentProps> = ({
  expertId,
  selectedComment,
  setSelectedComment,
  handleHasToUpdateInternalComments
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation("createNewComment");
  const dispatch = useDispatch();
  const hasToUpdateComment = selectedComment ? true : false;
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors }
  } = useForm<CreateNewCommentBase>({
    resolver: yupResolver(NewCommentSchema),
    mode: "onChange",
    defaultValues: {
      [INTERNAL_COMMENT_TITLE]: hasToUpdateComment
        ? selectedComment?.title
        : "",
      [INTERNAL_COMMENT_COMMENT]: hasToUpdateComment
        ? selectedComment?.note
        : "",
      [INTERNAL_COMMENT_SELECT_CLIENT]: hasToUpdateComment
        ? {
            name: selectedComment?.clientName!,
            id: selectedComment?.clientId!,
            logoUrl: "",
            subdomain: "",
            externalName: ""
          }
        : null
    }
  });

  const hasErrors = Object.keys(errors).length > 0;

  const handleCloseSlidePanel = () => {
    setSelectedComment();
    dispatch(PanelStoreActions.closePanel(PanelId.NewComment));
  };

  const handleService = (data: NewInternalComment) =>
    hasToUpdateComment
      ? ExpertService.updateInternalComment(selectedComment?.id!, data)
      : ExpertService.addInternalNewComment(expertId, data);

  const onSubmit = () => {
    setLoading(true);
    const values = getValues();
    const { title, note, selectClient } = values;
    const data = { title, note, clientId: selectClient?.id || "" };

    handleService(data).subscribe(
      () => {
        setLoading(false);
        handleHasToUpdateInternalComments();
        notificationService.showSuccess(
          t(hasToUpdateComment ? "commentUpdated" : "newCommentCreated")
        );
        handleCloseSlidePanel();
      },
      ({ message }: ErrorResponse<CIQError>) => {
        console.error(message);
        notificationService.showError(message);
        setLoading(false);
      }
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className={styles.newCommentSlidePanelHeader}>
        <span className={styles.title}>
          {t(hasToUpdateComment ? "headerTitleUpdate" : "headerTitleCreate")}
        </span>
        <div className={styles.wrapperButtons}>
          <Button
            type="secondary"
            onClick={handleCloseSlidePanel}
            text={t("Cancel")}
          />
          <Button
            type="primary"
            onClick={handleSubmit(onSubmit)}
            text={t("Save")}
            disabled={hasErrors}
          />
        </div>
      </div>
      <hr className={styles.divider} />
      <div className={styles.container}>
        <div>
          <Label className={styles.label}>{t("title")}</Label>
          <InputController
            name={INTERNAL_COMMENT_TITLE}
            error={errors[INTERNAL_COMMENT_TITLE]}
            control={control}
            placeholder={t("titlePlaceholder")}
          />
        </div>
        <div>
          <Label className={styles.label}>{t("comment")}</Label>
          <RichTextEditorController
            control={control}
            placeholder={t("commentPlaceholder")}
            name={INTERNAL_COMMENT_COMMENT}
            error={errors[INTERNAL_COMMENT_COMMENT]}
          />
        </div>
        <div>
          <Label className={styles.label}>{t("selectClient")}</Label>
          <ClientAdminSelector
            name={INTERNAL_COMMENT_SELECT_CLIENT}
            control={control}
            error={errors[INTERNAL_COMMENT_SELECT_CLIENT] as FieldError}
          />
        </div>
      </div>
    </>
  );
};
