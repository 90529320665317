import { AntDAvatar, AntDIcon } from "@arbolus-technologies/antDComponents";
import { ReportIncidentButtonForAnt } from "@arbolus-technologies/features/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ARBOLUS_COLORS, arbolusLogo } from "@arbolus-technologies/theme";
import { LinkWrapper } from "@arbolus-technologies/ui/components";
import { Button, Drawer, Menu } from "antd";
import { IconName } from "libs/antDComponents/src/lib/Icon/IconDictionary";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { isInternalLink } from "../DesktopMenu/DesktopMenu";
import { ISideBarItems } from "../MenuItemsConfig";
import { useMenuItems } from "../useMenuItems";
import styles from "./AntDMenuMobile.module.scss";

interface AntDMenuMobileProps {
  isAdmin?: boolean;
  profileRoute: string;
  handleLogout: () => void;
}

export const AntDMenuMobile: React.FC<AntDMenuMobileProps> = ({
  isAdmin = false,
  profileRoute,
  handleLogout
}) => {
  const [open, setIsOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);

  const authUser = useSelector(CacheSelector.loggedInUser());
  const adminUser = useSelector(CacheSelector.userAdminProfile());
  const userRole = useSelector(CacheSelector.generateActiveUserRole());
  const clientId = isAdmin ? adminUser.clientId : undefined;
  const avatarUser = isAdmin ? adminUser : authUser;

  const { menuItems } = useMenuItems(isAdmin, userRole, clientId);
  const location = useLocation();

  const handleOnClick = (menuItem: ISideBarItems) => {
    const isInternal = menuItem.route ? isInternalLink(menuItem.route) : true;
    if (!isInternal) {
      window.open(menuItem.route, "_blank");
    }
  };

  const isActive = (route: string) => {
    if ([location.pathname].includes(route as string)) {
      return ARBOLUS_COLORS.bColorBasePurple;
    }
    return undefined;
  };

  return (
    <div className={styles.container}>
      <div className={styles.mobileContainer}>
        <img src={arbolusLogo} alt="Arbolus Logo" className={styles.logo} />
        <Button
          type="default"
          onClick={() => setIsOpen(true)}
          icon={<AntDIcon name="menu" fontSize="16" />}
        />
      </div>
      <Drawer
        placement="left"
        className={styles.drawer}
        open={open}
        style={{ overflowY: "hidden", height: "100vh" }}
        width={"100%"}
        closeIcon={false}
        onClose={() => setIsOpen(false)}
      >
        <div className={styles.headerContainer}>
          <img src={arbolusLogo} alt="Arbolus Logo" className={styles.logo} />
          <Button
            type="default"
            onClick={() => setIsOpen(false)}
            icon={<AntDIcon name="close" fontSize="16" />}
          />
        </div>

        <Menu
          mode={"inline"}
          style={{
            overflowY: "hidden",
            borderInlineEnd: "none"
          }}
          className={styles.menu}
          expandIcon={
            submenuOpen ? (
              <AntDIcon name="arrowUp" fontSize="16" />
            ) : (
              <AntDIcon name="arrowDown" fontSize="16" />
            )
          }
          onOpenChange={() => setSubmenuOpen(!submenuOpen)}
        >
          {menuItems.map((item) => {
            if (!item.route && item.name === "Support") {
              return (
                <ReportIncidentButtonForAnt
                  key={item.name}
                  isMobile
                  onSupportClick={() => setIsOpen(false)}
                >
                  {item.name}
                </ReportIncidentButtonForAnt>
              );
            }

            if (item.subItems) {
              return (
                <Menu.SubMenu
                  key={item.name}
                  title={item.name}
                  className={styles.mobileSubMenuItem}
                  icon={
                    <AntDIcon name={item.icon as IconName} fontSize="16px" />
                  }
                >
                  {item.subItems?.map((subItem) => (
                    <Menu.Item
                      key={subItem.route}
                      className={styles.mobileSubMenuItemComp}
                      onClick={() => {
                        subItem.icon === "logout" && handleLogout();
                      }}
                      accessKey={subItem.route}
                      title={null}
                      icon={
                        <AntDIcon
                          name={subItem.icon as IconName}
                          fontSize="16px"
                          color={isActive(subItem.route!)}
                        />
                      }
                    >
                      {subItem.route ? (
                        <Link
                          to={subItem.route as string}
                          onClick={() => setIsOpen(false)}
                        >
                          {subItem.name}
                        </Link>
                      ) : (
                        subItem.name
                      )}
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              );
            }
            return (
              <Menu.Item
                key={item.route}
                className={styles.mobileMenuItem}
                accessKey={item.route}
                onClick={() => {
                  if (item.position === "footer") {
                    return handleOnClick(item);
                  }
                }}
                title={item.name}
                icon={
                  <AntDIcon
                    name={item.icon as IconName}
                    fontSize="16px"
                    color={isActive(item.route!)}
                  />
                }
              >
                <Link
                  to={item.route as string}
                  onClick={() => setIsOpen(false)}
                >
                  {item.name}
                </Link>
              </Menu.Item>
            );
          })}
        </Menu>
        <div className={styles.avatarContainer}>
          <LinkWrapper to={profileRoute} onClick={() => setIsOpen(false)}>
            <AntDAvatar
              firstName={avatarUser.firstName}
              lastName={avatarUser.lastName}
              profileImageUrl={avatarUser.profileImageUrl}
            />
          </LinkWrapper>
        </div>
      </Drawer>
    </div>
  );
};
