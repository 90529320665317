import { Button } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import SimpleBar from "simplebar-react";

import { SlidePanel } from "@arbolus-technologies/features/common";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { HR } from "@arbolus-technologies/ui/components";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { NEW_USER } from "../../../../../constants/navigation/authRoutes";
import { CIQInviteTag } from "../../../../app/components";
import { AuthSelector } from "../../../../auth/store";

import styles from "./ClientTeamInvitePanel.module.scss";

export const ClientTeamInvitePanel: React.FC = () => {
  const { t } = useTranslation("clientTeamInvitePanel");
  const dispatch = useDispatch();
  const history = useHistory();

  const teamInvitationUrl = useSelector(
    AuthSelector.authClientTeamInvitationURLSelector()
  );

  const handleAddNewUser = (): void => {
    closePanel();
    history.push(NEW_USER);
  };

  const closePanel = () =>
    dispatch(PanelStoreActions.closePanel(PanelId.ClientTeamInvite));

  return (
    <SlidePanel
      panelId={PanelId.ClientTeamInvite}
      title={t("title")}
      width={SIDE_PANEL_SIZE._400}
      closeButtonDirection="right"
      customCloseRequest={closePanel}
    >
      <SimpleBar
        className={clsx(
          "content-panel-body",
          "simplebar-light",
          styles.teamInviteContainer
        )}
      >
        <div className="panel-body">
          <h3>{t("multipleUsers")}</h3>
          <p className={styles.text}>{t("multipleUsersDesc")}</p>
          {teamInvitationUrl && (
            <div className="invite-tag-item">
              <CIQInviteTag url={teamInvitationUrl} />
            </div>
          )}
          <HR margin={{ top: 3, bottom: 3 }} />
          <h3>{t("singleUser")}</h3>
          <p className={styles.text}>{t("singleUserDesc")}</p>
          <Button onClick={handleAddNewUser} text={t("addNow")} />
        </div>
      </SimpleBar>
    </SlidePanel>
  );
};
