import { Button } from "arbolus-ui-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  DefaultToasterService,
  TranscriptService
} from "@arbolus-technologies/api";
import {
  ArbolusDropdown,
  ArbolusModal
} from "@arbolus-technologies/ui/components";

import styles from "./RegenerateTranscript.module.scss";

const DROPDOWN_OPTIONS: Record<string, { label: string; value: string }> = {
  en: { label: "English", value: "en" },
  es: { label: "Spanish", value: "es" },
  fr: { label: "French", value: "fr" },
  de: { label: "German", value: "de" },
  it: { label: "Italian", value: "it" },
  pt: { label: "Portuguese", value: "pt" },
  ar: { label: "Arabic", value: "ar" },
  da: { label: "Danish", value: "da" },
  nl: { label: "Dutch", value: "nl" },
  cmn: { label: "Mandarin", value: "cmn" }
} as const;

export const RegenerateTranscript: React.FC<{
  projectId: string;
  transcriptId: string;
}> = ({ projectId, transcriptId }) => {
  const { t } = useTranslation("regenerateTranscript");
  const [language, setLanguage] = useState(DROPDOWN_OPTIONS.en);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    TranscriptService.getTranscriptStatus(projectId, transcriptId).subscribe(
      (response) => {
        setLanguage(DROPDOWN_OPTIONS[response.language]);
        if (response.jobStatus !== "Completed") setIsGenerating(true);
      },
      (error) => {
        DefaultToasterService.showApiErrors(error);
      }
    );
  }, [projectId, transcriptId]);

  const handleRegenerate = () => {
    setIsOpen(false);
    setIsGenerating(true);
    TranscriptService.regenerateTranscript(
      projectId,
      transcriptId,
      language.value
    ).subscribe(null, (error) => {
      DefaultToasterService.showApiErrors(error);
      setIsGenerating(false);
    });
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.regenerateTranscript}>
          <div className={styles.selectLanguage}>
            <span className={styles.languageLabel}>
              {t("selectLanguageLabel")}
            </span>
            <ArbolusDropdown
              options={Object.values(DROPDOWN_OPTIONS)}
              selectedLabel={language.label}
              onSelect={setLanguage}
              disabled={isGenerating}
            />
          </div>
          <Button
            type={"secondary"}
            text={t("regenerateButton")}
            endIcon="refresh"
            onClick={() => setIsOpen(true)}
            disabled={isGenerating}
          />
        </div>
        {isGenerating && (
          <span className={styles.generatingLabel}>
            {t("regeneratingInProgress")}
          </span>
        )}
      </div>
      <ArbolusModal
        title={t("confirmationModalTitle")}
        subtitle={t("confirmationModalSubtitle")}
        leftButton={{
          text: t("confirmationModalReject"),
          type: "tertiary",
          onClick: () => setIsOpen(false)
        }}
        rightButton={{
          text: t("confirmationModalApprove"),
          type: "confirmation",
          onClick: handleRegenerate
        }}
        isOpen={isOpen}
        toggle={() => setIsOpen((prev) => !prev)}
      />
    </>
  );
};
