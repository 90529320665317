import {
  EVENT_NOTIFICATION_STATUS,
  INBOX_NOTIFICATION_CATEGORY,
  INBOX_NOTIFICATION_TYPE,
  NOTIFICATION_VERSION_TYPE
} from "../enums/notificationEnums";

export enum ExpertRejectionType {
  REVIEW = "Review",
  COMPLIANCE = "Compliance"
}

export interface InboxNotification<
  T extends NotificationDataType = NotificationDataType
> {
  id: string;
  seen: boolean;
  notificationType: INBOX_NOTIFICATION_CATEGORY;
  notificationData: T;
  project: {
    id: string;
    name: string;
  };
  created: string;
  version?: NOTIFICATION_VERSION_TYPE;
}

export type NotificationDataType =
  // Updates
  | ExpertAddedToProjectAdminNotificationData
  | ProjectLiveAdminNotificationData
  | ProjectBriefUpdatedNotificationData
  // Applications
  | ExpertCandidateRejectedAdminNotificationData
  | ApplicationCompletionAdminNotificationData
  | DeclinedApplicationAdminNotificationData
  // SharedDoc
  | TranscriptAvailableNotificationData
  // NewJoins
  | MemberAcceptInvitationAdminNotificationData
  | ExpertAcceptInvitationAdminNotificationData
  // NewReferrals
  | ExpertsAssignedToProjectNotificationData
  | ExpertAddedToProjectClientNotificationData
  // Messages
  | ExpertMessagesClientTeamNotificationData
  // ThingsTodo
  | PendingProjectReviewNotificationData
  | ProjectUnreadMessagesNotificationData
  // Surveys
  | CanopySurveyLaunchedNotificationData
  | ExpertSubmittedCanopyNotificationData
  // EventInvites
  | EventInvitesNotificationData;

export interface BaseEventNotificationData {
  notificationType: INBOX_NOTIFICATION_CATEGORY;
  type: INBOX_NOTIFICATION_TYPE;
}

export interface CanopySurveyLaunchedNotificationData
  extends BaseEventNotificationData {
  canopyId: string;
  canopyTitle: string;
  projectName: string;
  type: INBOX_NOTIFICATION_TYPE.CanopySurveyLaunchedNotification;
}

export interface ExpertSubmittedCanopyNotificationData
  extends BaseEventNotificationData {
  canopyId: string;
  canopyTitle: string;
  projectName: string;
  expertName: string;
  type: INBOX_NOTIFICATION_TYPE.ExpertSubmittedCanopyNotification;
}

export interface PendingProjectReviewNotificationData
  extends BaseEventNotificationData {
  clientId: string;
  clientName: string;
  projectName: string;
  draftId: string;
  firstName: string;
  type: INBOX_NOTIFICATION_TYPE.PendingProjectReviewNotification;
}

export interface MemberAcceptInvitationAdminNotificationData
  extends BaseEventNotificationData {
  clientId: string;
  clientName: string;
  memberName: string;
  type: INBOX_NOTIFICATION_TYPE.MemberAcceptInvitationAdminNotification;
}

export interface ExpertAddedToProjectAdminNotificationData
  extends BaseEventNotificationData {
  expertName: string;
  projectId: string;
  projectName: string;
  workspaceId: string;
  type: INBOX_NOTIFICATION_TYPE.ExpertAddedToProjectAdminNotification;
}

export interface ProjectLiveAdminNotificationData
  extends BaseEventNotificationData {
  clientName: string;
  projectId: string;
  projectName: string;
  type: INBOX_NOTIFICATION_TYPE.ProjectLiveAdminNotification;
}

export interface ExpertCandidateRejectedAdminNotificationData
  extends BaseEventNotificationData {
  clientName: string;
  complianceNote: string;
  expertId: string;
  expertName: string;
  projectId: string;
  projectName: string;
  referralId: string;
  rejectionType: ExpertRejectionType;
  type: INBOX_NOTIFICATION_TYPE.ExpertCandidateRejectedAdminNotification;
}

export interface ApplicationCompletionAdminNotificationData
  extends BaseEventNotificationData {
  expertName: string;
  projectId: string;
  projectName: string;
  referralId: string;
  expertId: string;
  type: INBOX_NOTIFICATION_TYPE.ApplicationCompletionAdminNotification;
}

export interface DeclinedApplicationAdminNotificationData
  extends BaseEventNotificationData {
  clientName: string;
  expertId: string;
  expertName: string;
  projectId: string;
  projectName: string;
  referralId: string;
  type: INBOX_NOTIFICATION_TYPE.DeclinedApplicationAdminNotification;
}

export interface EventInvitesNotificationData
  extends BaseEventNotificationData {
  eventName: string;
  eventId: string;
  projectName: string;
  projectId: string;
  eventStatus: EVENT_NOTIFICATION_STATUS;
  startTime: string;
  endTime: string;
  type: INBOX_NOTIFICATION_TYPE.EventNotification;
}

export interface ExpertMessagesClientTeamNotificationData
  extends BaseEventNotificationData {
  expertFirstName: string;
  expertUserId: string;
  projectId: string;
  projectName: string;
  workspaceId: string;
  workspaceTitle: string;
  workspaceType: string;
  type: INBOX_NOTIFICATION_TYPE.ExpertMessagesClientTeamNotification;
}

export interface ProjectUnreadMessagesNotificationData
  extends BaseEventNotificationData {
  projectId: string;
  projectName: string;
  messageCount: number;
}

export interface ProjectBriefUpdatedNotificationData
  extends BaseEventNotificationData {
  projectName: string;
  projectId: string;
}

export interface ExpertAcceptInvitationAdminNotificationData
  extends BaseEventNotificationData {
  expertFirstName: string;
  expertLastName: string;
  expertId: string;
}

export interface TranscriptAvailableNotificationData
  extends BaseEventNotificationData {
  documentId: string;
  clientId: string;
  eventName: string;
  projectId: string;
  clientName: string;
  projectName: string;
  documentType: string;
  documentFileName: string;
  type: INBOX_NOTIFICATION_TYPE.TranscriptAvailableNotification;
}

export interface ExpertsAssignedToProjectNotificationData
  extends BaseEventNotificationData {
  projectId: string;
  projectName: string;
  numOfCandidates: number;
  type: INBOX_NOTIFICATION_TYPE.ExpertsAssignedToProjectNotification;
}

export interface ExpertAddedToProjectClientNotificationData
  extends BaseEventNotificationData {
  projectId: string;
  projectName: string;
  numOfApprovedCandidates: number;
  type: INBOX_NOTIFICATION_TYPE.ExpertAddedToProjectClientNotification;
}
