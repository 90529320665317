import React from "react";

import { ClientService, ClientUser } from "@arbolus-technologies/api";

import { UserSelector, UserSelectorProps } from "./UserSelector";

interface ClientUserSelectorProps
  extends Omit<UserSelectorProps<ClientUser>, "getItems"> {
  clientId: string;
  hasEmailConfirmed?: boolean;
}

export const ClientUserSelector: React.FC<ClientUserSelectorProps> = ({
  clientId,
  hasEmailConfirmed,
  ...props
}) => (
  <UserSelector
    {...props}
    getItems={(pagination) =>
      ClientService.getTeamMembers(clientId, {
        ...pagination,
        hasEmailConfirmed
      })
    }
  />
);
