import { Icon } from "arbolus-ui-components";
import React from "react";
import { Link } from "react-router-dom";

import { PROJECT_ROUTE } from "@arbolus-technologies/routes";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./BackToProjectLink.module.scss";

export const BackToProjectLink: React.FC<{
  projectId: string;
  projectName: string;
}> = ({ projectId, projectName }) => (
  <Link to={PROJECT_ROUTE(projectId)} className={styles.backLink}>
    <Icon
      name="arrow_back_ios"
      fontSize="12px"
      color={ARBOLUS_COLORS.bColorBasePurple}
    />
    {projectName}
  </Link>
);
