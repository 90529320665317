import clsx from "clsx";
import { Field, Form, Formik, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import SimpleBar from "simplebar-react";

import { ToasterService } from "@arbolus-technologies/api";

import { useQuery } from "../../../../common/hooks/UseQuery";
import { PublicRouteConstants } from "../../../../constants";
import { LOGIN } from "../../../../constants/navigation/publicRoutes";
import { CIQError, ErrorResponse } from "../../../../models/api";
import { EndorsementService } from "../../../../services";
import { CIQFormInput } from "../../../app/components";
import ExpertEndorsementConfirmation from "./ExpertEndorsementConfirmation";
import { ExpertEndorsementValidationSchema } from "./ExpertEndorsementPageSchema";

import arbolusLogo from "../../../../assets/images/logos/logo-tree-blue.png";

import { PRIVACY_POLICY } from "@arbolus-technologies/features/common";
import styles from "./ExpertEndorsementPage.module.scss";

const notification = new ToasterService();

interface ExpertEndorsementFormValues {
  name: string;
  jobPosition: string;
  quote: string;
  privacyPolicyAgreement: boolean;
}
export interface ParamTypes {
  expertName: string;
  endorserName: string;
  token: string;
}

const ExpertEndorsementPage: React.FC = () => {
  const history = useHistory();
  const query = useQuery();
  const { t } = useTranslation("expertEndorsementPage");
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isSent, setIsSent] = useState<boolean>(false);
  const [expertEndorsementData, setExpertEndorsementData] =
    useState<ParamTypes>();

  useEffect(() => {
    document.title = t("endorsement");
    const expertName = query.get("expertName");
    const token = query.get("token");
    const endorserName = query.get("endorserName");
    if (!expertName || !token || !endorserName) {
      history.replace(PublicRouteConstants.LOGIN);
    } else {
      setExpertEndorsementData({ expertName, endorserName, token });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, t]);

  const handleSubmit = (values: ExpertEndorsementFormValues): void => {
    setIsSending(true);
    EndorsementService.endorseExpert({
      token: expertEndorsementData?.token ?? "",
      quote: values.quote,
      name: values.name,
      title: values.jobPosition
    }).subscribe(
      () => {
        setIsSending(false);
        setIsSent(true);
      },
      (error: ErrorResponse<CIQError>) => {
        notification.showError(error.message);
        setIsSending(false);
      }
    );
  };

  const handleCancel = (): void => {
    history.push(LOGIN);
  };

  const handleRedirectPrivacyPolicy = (): void => {
    window.open(PRIVACY_POLICY, "_blank");
  };

  const renderForm = ({
    errors,
    touched,
    isValid,
    dirty,
    values
  }: FormikProps<ExpertEndorsementFormValues>): JSX.Element => {
    const submitButtonEnabled =
      isValid && dirty && values.privacyPolicyAgreement;

    return (
      <Form style={{ width: "100%" }}>
        <section className={styles.firstSection}>
          <Row>
            <Col xs={12} md={6}>
              <FormGroup
                className={clsx(
                  {
                    "is-invalid": errors.name && touched.name
                  },
                  styles.nameAndTitleInputs
                )}
                data-testid="endorsement-fields"
              >
                <Label className={styles.mainLabels}>{t("endorserName")}</Label>
                <Field
                  name="name"
                  placeholder={t("name")}
                  type="text"
                  component={CIQFormInput}
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup
                className={clsx(
                  {
                    "is-invalid": errors.jobPosition && touched.jobPosition
                  },
                  styles.nameAndTitleInputs
                )}
                data-testid="endorsement-fields"
              >
                <Label className={styles.mainLabels}>
                  {t("endorserPosition")}
                </Label>
                <Field
                  name="jobPosition"
                  placeholder={t("jobPosition")}
                  type="text"
                  component={CIQFormInput}
                />
              </FormGroup>
            </Col>
          </Row>
        </section>
        <section>
          <h2 className={styles.endorsementForTitle}>
            {t("endorsementFor")} {expertEndorsementData?.expertName}
          </h2>
          <Row>
            <Col>
              <FormGroup
                className={clsx({
                  "is-invalid": errors.quote && touched.quote
                })}
                data-testid="endorsement-fields"
              >
                <Label>{t("endorserReason")}</Label>
                <Field
                  rows={9}
                  name="quote"
                  placeholder={t("endorsementPlaceholder")}
                  type="textarea"
                  component={CIQFormInput}
                />
              </FormGroup>
            </Col>
          </Row>
        </section>
        <section>
          <div className={styles.bottomContainer}>
            <FormGroup className="custom-checkbox">
              <Label check>
                <Field
                  name="privacyPolicyAgreement"
                  type="checkbox"
                  component={CIQFormInput}
                />
                {t("checkboxAgreement")}
                <span
                  className={styles.privacyPolicy}
                  onClick={handleRedirectPrivacyPolicy}
                >
                  {t("privacyPolicy")}
                </span>
                <span className="checkmark" />
              </Label>
            </FormGroup>
            <Button
              type="submit"
              size="lg"
              color="primary"
              className={clsx(styles.btnEndorsement, "btn-bold")}
              disabled={!submitButtonEnabled || isSending}
            >
              {t("submitEndorsement")}
            </Button>
          </div>
        </section>
      </Form>
    );
  };

  return (
    <SimpleBar className="simplebar-light" style={{ width: "100%" }}>
      <div className={clsx(styles.expertEndorsementPage, "w-100")}>
        <div className={styles.fullPageLogo}>
          <img src={arbolusLogo} height="30px" width="30px" alt="logo" />
        </div>
        <div className={styles.endorseExpertHeader}>
          <Col>
            <p className={styles.arbolusTitle}>Arbolus</p>
          </Col>
          <Col>
            {!isSent && (
              <Button
                className={clsx(styles.rightBtn, "btn-link")}
                onClick={handleCancel}
              >
                {t("cancel")}
              </Button>
            )}
          </Col>
        </div>
        <Row className={styles.endorseExpertBody}>
          {isSent && expertEndorsementData ? (
            <ExpertEndorsementConfirmation
              expertEndorsementData={expertEndorsementData}
            />
          ) : (
            <>
              <h1>{t("endorsement")}</h1>
              <h2 className={styles.endorserDetailsTitle}>
                {t("endorserDetails")}
              </h2>
              <Formik<ExpertEndorsementFormValues>
                initialValues={{
                  quote: "",
                  jobPosition: "",
                  name: "",
                  privacyPolicyAgreement: false
                }}
                validateOnBlur
                validateOnChange
                validationSchema={ExpertEndorsementValidationSchema}
                onSubmit={handleSubmit}
              >
                {renderForm}
              </Formik>
            </>
          )}
        </Row>
      </div>
    </SimpleBar>
  );
};

export default ExpertEndorsementPage;
