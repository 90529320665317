import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { ContainerHeader } from "../../ContainerHeader/ContainerHeader";
import { Drawer } from "../Drawer/Drawer";

interface TitledDrawerProps {
  name: string;
  children: React.ReactNode;
  leftHeaderComponent?: React.ReactNode;
  rightHeaderComponent?: React.ReactNode;
  openByDefault?: boolean;
}

export const TitledDrawer: React.FC<TitledDrawerProps> = ({
  name,
  children,
  leftHeaderComponent,
  rightHeaderComponent,
  openByDefault = true
}) => {
  const { t } = useTranslation(name);
  const getTitleChildren = useCallback(
    (_: boolean) => (
      <ContainerHeader
        title={t("title")}
        subtitle={t("subtitle")}
        rightComponent={rightHeaderComponent}
        leftComponent={leftHeaderComponent}
      />
    ),
    [leftHeaderComponent, rightHeaderComponent, t, name]
  );

  return (
    <Drawer openByDefault={openByDefault} titleChildren={getTitleChildren}>
      {children}
    </Drawer>
  );
};
