import i18next from "i18next";
import * as yup from "yup";

import { CanopyConstraints } from "@arbolus-technologies/models/canopy";

import { MultiFormData } from "./ShortTextForExpertProvider";

export const createShortTextExpertSchema: yup.ObjectSchema<MultiFormData> = yup
  .object()
  .shape({
    shortText: yup
      .string()
      .required(i18next.t("canopyExpert:requiredShortText"))
      .max(
        CanopyConstraints.MAX_SHORT_TEXT_ANSWER_LENGTH,
        i18next.t("canopyExpert:shortTextMaxLength", {
          length: CanopyConstraints.MAX_SHORT_TEXT_ANSWER_LENGTH
        })
      )
  });
