import queryString from "query-string";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Dispatch } from "redux";

import { ToasterService, UserService } from "@arbolus-technologies/api";
import { Loader } from "@arbolus-technologies/ui/components";

import { LOGIN } from "../../../../constants/navigation/publicRoutes";
import { FederatedLoginRequest } from "../../../../models/auth";
import { AppAction } from "../../../../store/actions";
import { LoginStoreActions } from "../../store";

interface FederatedRedirectProps extends RouteComponentProps {
  federatedLoginUser: (loginRequest: FederatedLoginRequest) => void;
}

class FederatedRedirectPage extends React.Component<FederatedRedirectProps> {
  private notificationService = new ToasterService();
  componentDidMount(): void {
    const { federatedLoginUser, location, history } = this.props;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any = queryString.parse(location.search);
    if (params.iss) {
      const filteredIss = params.iss.replace(/^https?:\/\//, "");
      UserService.authenticateUserOpenId(filteredIss).subscribe(
        (response) => {
          window.location.replace(response.url);
        },
        (error) => {
          this.notificationService.showError("Invalid company");
        }
      );
    } else if (!params.code || !params.state) {
      history.replace(LOGIN);
    } else {
      federatedLoginUser({
        code: params.code,
        state: params.state,
        user: params.user
      });
    }
  }

  render(): JSX.Element {
    return <Loader isFull />;
  }
}

const mapDispatchToProps = (dispatch: Dispatch): Record<string, AppAction> => ({
  federatedLoginUser: ({
    code,
    state,
    user
  }: FederatedLoginRequest): AppAction => {
    dispatch(LoginStoreActions.authenticateFederatedUser(code, state, user));
  }
});

export default connect(null, mapDispatchToProps)(FederatedRedirectPage);
