import { Icon } from "arbolus-ui-components";
import React from "react";
import { Control, FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { InputController } from "../../InputController/InputController";
import { AdminReportExpertForm } from "./ArbolusModalAdminReportExpertSchema";

import styles from "./ModalBody.module.scss";

interface ModalBodyProps {
  control: Control<AdminReportExpertForm>;
  errors: FieldErrors<AdminReportExpertForm>;
}

export const ModalBody: React.FC<ModalBodyProps> = ({ control, errors }) => {
  const { t } = useTranslation("reportExpert");

  return (
    <div className={styles.modalBody}>
      <p className={styles.subtitle}>{t("reportExpertSubtitle")}</p>
      <div>
        <p className={styles.textAreaTitle}>{t("textFieldTitle")}</p>
        <InputController
          size="big"
          type="textarea"
          name="feedbackText"
          control={control}
          placeholder={t("textFieldPlaceholder")}
          required
          error={errors.feedbackText}
        />
        <div className={styles.info}>
          <Icon
            name="info"
            fontSize="16px"
            color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
          />
          <span>{t("minLengthChars")}</span>
        </div>
      </div>
    </div>
  );
};
