import clsx from "clsx";
import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { SquareWithIcon } from "@arbolus-technologies/ui/components";

import styles from "./AngleName.module.scss";

interface AngleNameProps {
  title: string;
  color: string;
  textColor?: string;
  bold?: boolean;
  size?: "small" | "medium" | "large" | "default";
}

export const AngleName: React.FC<AngleNameProps> = ({
  title,
  color,
  textColor,
  bold = false,
  size = "default"
}) => (
  <div className={styles.container}>
    <SquareWithIcon
      icon="zoom_in_map"
      fontSize="16px"
      customHeight="24px"
      customWidth="24px"
      background={color}
      color={textColor || ARBOLUS_COLORS.bColorBaseDark}
    />
    <span
      className={clsx(styles.text, styles[size], bold && styles.bold)}
      title={title}
    >
      {title}
    </span>
  </div>
);
