import { Button, Flex } from "antd";
import clsx from "clsx";
import { Field, Form, Formik, FormikProps } from "formik";
import React, { createRef } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { connect } from "react-redux";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { Dispatch } from "redux";
import { createStructuredSelector } from "reselect";
import { Subscription } from "rxjs";

import { ToasterService } from "@arbolus-technologies/api";
import { SelectOption } from "@arbolus-technologies/models/common";

import { CIQError, ErrorResponse } from "../../../../../models/api";
import { LoggedInUser, UserEntryRequest } from "../../../../../models/user";
import { UserService } from "../../../../../services";
import { AppAction } from "../../../../../store/actions";
import { AppState } from "../../../../../store/reducers";
import { CIQFormInput, CIQSelect } from "../../../../app/components";
import { AppSelector, AppStoreActions } from "../../../../app/store";
import { AuthSelector } from "../../../../auth/store";
import { UserDetailsValidationSchema } from "./UserDetailsSchema";

const notification = new ToasterService();

interface UserProfileDetailsStoreProps {
  userProfile: LoggedInUser;
  timezones: SelectOption[];
  currentTimeZone: SelectOption;
}

interface UserProfileDetailsState {
  isLoading: boolean;
}

interface UserProfileDetailsProps extends UserProfileDetailsStoreProps {
  getUserProfile: () => void;
}

interface UserDetailsFormValues {
  firstName?: string;
  lastName?: string;
  title: string;
  email: string;
  phoneNumber?: string;
  timezone?: string;
}

type UserProfileDetailsIntersectProps = UserProfileDetailsProps &
  WithTranslation;

class UserProfileDetails extends React.Component<
  UserProfileDetailsIntersectProps,
  UserProfileDetailsState
> {
  static defaultProps = {
    userProfile: {} as LoggedInUser,
    timezones: [],
    currentTimeZone: {} as SelectOption,
    getUserProfile: (): void => {}
  };

  constructor(props: UserProfileDetailsIntersectProps) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  componentDidMount = (): void => {
    if (this.phoneInputRef.current && this.formRef) {
      const { userProfile } = this.props;
      const phoneNumber = userProfile.phoneNumber?.split("+")[1];
      this.formRef.setFieldValue("phoneNumber", phoneNumber);
      this.formRef.resetForm();
    }
  };

  componentDidUpdate(
    prevProps: UserProfileDetailsIntersectProps,
    prevState: UserProfileDetailsState
  ): void {
    const { isLoading } = this.state;

    if (isLoading === prevState.isLoading) {
      if (this.formRef) {
        this.formRef.resetForm();
      }
    }
  }

  componentWillUnmount(): void {
    this.userProfileUpdateSubscription?.unsubscribe();
  }

  private formRef: FormikProps<UserDetailsFormValues> | null = null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private phoneInputRef = createRef<any>();

  private userProfileUpdateSubscription?: Subscription;

  updateUserProfileDetails = (values: UserDetailsFormValues): void => {
    const { userProfile, t, getUserProfile } = this.props;

    const { firstName, lastName, title, phoneNumber, timezone } = values;

    const numberWithPlus: string = phoneNumber ? `+${phoneNumber}` : "";
    this.setState({
      isLoading: true
    });
    this.userProfileUpdateSubscription = UserService.updateUserProfile(
      userProfile.id,
      {
        firstName,
        lastName,
        title,
        phoneNumber: numberWithPlus,
        timezone
      } as UserEntryRequest
    ).subscribe(
      () => {
        this.setState({ isLoading: false }, () => {
          notification.showSuccess(t("userProfileDetailsUpdate"));
          getUserProfile();
        });
      },
      (error: ErrorResponse<CIQError>) => {
        this.setState({ isLoading: false }, () =>
          notification.showError(error.message)
        );
      }
    );
  };

  renderUserDetailsForm = ({
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleBlur,
    setFieldTouched,
    setFieldValue
  }: FormikProps<UserDetailsFormValues>): JSX.Element => {
    const { t, timezones } = this.props;
    const { isLoading } = this.state;

    const saveButtonDisabled = !(isValid && dirty) || isLoading;

    const handlePhoneChange = (value: string): void => {
      setFieldTouched("phoneNumber", true);
      setFieldValue("phoneNumber", value);
    };

    const handleTimeZoneChange = (value: string): void => {
      setFieldValue("timezone", value);
    };

    return (
      <Form>
        <Row>
          <Col md={6}>
            <FormGroup
              className={clsx({
                "is-invalid": errors.firstName && touched.firstName
              })}
            >
              <Label for="">{t("firstName")}</Label>
              <Field
                placeholder={t("firstName")}
                name="firstName"
                type="text"
                component={CIQFormInput}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup
              className={clsx({
                "is-invalid": errors.lastName && touched.lastName
              })}
            >
              <Label className="space-between">{t("lastName")}</Label>
              <Field
                placeholder={t("lastName")}
                name="lastName"
                type="text"
                component={CIQFormInput}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup
              className={clsx({
                "is-invalid": errors.title && touched.title
              })}
            >
              <Label className="space-between">{t("jobTitle")}</Label>
              <Field
                placeholder={t("jobTitle")}
                name="title"
                type="text"
                value={values.title || ""}
                component={CIQFormInput}
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup
              className={clsx({
                "is-invalid": touched.timezone && errors.timezone
              })}
            >
              <Label className="space-between">
                {t("timezone")} <span>{t("optional")}</span>
              </Label>
              <CIQSelect
                options={timezones}
                defaultValue={timezones.find(
                  (tz) => tz.value === values.timezone
                )}
                onSelectChange={handleTimeZoneChange}
                noOptionsMessage={t("noTimezone")}
                customStyle
                isSearchable
                placeholder={t("timezoneNotSet")}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="space-between">{t("email")}</Label>
              <Field
                disabled
                placeholder={t("email")}
                name="email"
                component={CIQFormInput}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup
              className={clsx({
                "is-invalid": errors.phoneNumber && touched.phoneNumber
              })}
            >
              <Label className="space-between">
                {t("contactNumber")}
                <span>{t("optional")}</span>
              </Label>
              <PhoneInput
                inputProps={{
                  name: "phoneNumber",
                  autoComplete: "off"
                }}
                ref={this.phoneInputRef}
                value={values.phoneNumber || ""}
                placeholder={t("contactNumberPlaceholder")}
                onChange={handlePhoneChange}
                onBlur={handleBlur("phoneNumber")}
                enableSearch
                country="us"
                disableSearchIcon
                prefix=""
              />
              {errors.phoneNumber && touched.phoneNumber && (
                <Label className="invalid-feedback">{errors.phoneNumber}</Label>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Flex justify="flex-end">
          <Button
            htmlType="submit"
            type="primary"
            disabled={saveButtonDisabled}
            size="large"
          >
            {t("saveDetails")}
          </Button>
        </Flex>
      </Form>
    );
  };

  render(): JSX.Element {
    const { userProfile } = this.props;
    const { firstName, lastName, email, title, phoneNumber, timezone } =
      userProfile;

    const formatterPhone = phoneNumber?.split("+")[1];

    return (
      <div className="user-details-tab">
        <Formik<UserDetailsFormValues>
          initialValues={{
            firstName,
            lastName,
            email,
            title,
            phoneNumber: formatterPhone,
            timezone
          }}
          validationSchema={UserDetailsValidationSchema}
          validateOnChange
          enableReinitialize
          validateOnBlur
          innerRef={(instance): void => {
            this.formRef = instance;
          }}
          onSubmit={this.updateUserProfileDetails}
        >
          {this.renderUserDetailsForm}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<
  AppState,
  UserProfileDetailsProps,
  UserProfileDetailsStoreProps
>({
  userProfile: AuthSelector.authUserSelector(),
  timezones: AppSelector.appTimeZoneSelector(),
  currentTimeZone: AppSelector.appGuessCurrentTimeZoneSelector()
});

const mapDispatchToProps = (dispatch: Dispatch): Record<string, AppAction> => ({
  getUserProfile: (): AppAction => dispatch(AppStoreActions.getUserProfile())
});

export default withTranslation("userProfilePage")(
  connect(mapStateToProps, mapDispatchToProps)(UserProfileDetails)
);
