import clsx from "clsx";
import React from "react";

import styles from "./ActionButton.module.scss";

interface ActionButtonProps {
  handleOnClick: () => void;
  text: string;
  type: "primary" | "secondary";
}

const buttonType = {
  primary: styles.primary,
  secondary: styles.secondary
};

export const ActionButton: React.FC<ActionButtonProps> = ({
  handleOnClick,
  text,
  type
}) => (
  <button
    onClick={handleOnClick}
    className={clsx(styles.actionButton, buttonType[type])}
  >
    {text}
  </button>
);
