import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { IChatMessage } from "@arbolus-technologies/api";
import { ChannelNameByChatId } from "@arbolus-technologies/models/project";
import { ProjectNxStoreActions } from "@arbolus-technologies/stores/project";

import { ChatMessagingService } from "../../Services/Chat/ChatMessagingService";

export function useLiveChatUpdates(
  channelNameByChatId: ChannelNameByChatId,
  currentChatId: string,
  incrementUnreadCount: (chatId: string) => void
): void {
  const dispatch = useDispatch();

  const serviceInstance = useRef<ChatMessagingService>();
  useEffect(() => {
    serviceInstance.current = new ChatMessagingService(
      process.env.NX_PUBLIC_PUSHER_APP_KEY!,
      process.env.NX_PUBLIC_PUSHER_APP_CLUSTER!,
      dispatch
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(channelNameByChatId).length < 1) return;

    for (const [chatId, channelName] of Object.entries(channelNameByChatId)) {
      serviceInstance.current?.subscribeToChannel(channelName, chatId);
    }

    return () => {
      for (const channelName of Object.values(channelNameByChatId)) {
        serviceInstance.current?.unsubscribeFromChannel(channelName);
      }
    };
  }, [channelNameByChatId]);

  useEffect(
    function setCallbackWhenSwitchingChat() {
      function receiveNewMessage(chatId: string, message: IChatMessage) {
        if (chatId === currentChatId) {
          dispatch(ProjectNxStoreActions.receiveNewChatMessage(message));
        } else {
          incrementUnreadCount(chatId);
        }
      }

      if (serviceInstance.current) {
        serviceInstance.current.setCallback(receiveNewMessage);
      }
    },
    [serviceInstance, currentChatId, dispatch, incrementUnreadCount]
  );
}
