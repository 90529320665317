import React, { useCallback, useEffect, useState } from "react";

import {
  AdminService,
  CIQError,
  ErrorResponse,
  ListBaseClient,
  ToasterService,
  User
} from "@arbolus-technologies/api";
import { CardItem } from "@arbolus-technologies/ui/canopy-panels";
import { InfiniteScrollV2 } from "@arbolus-technologies/ui/components";

import { INITIAL_QUERY_PARAMS_COUNT, IQueryParams } from "./types";

import styles from "./ClientsList.module.scss";

const notificationService = new ToasterService();

interface ClientsProps {
  user: User;
}

export const ClientsList: React.FC<ClientsProps> = ({ user }) => {
  const [clients, setClients] = useState<ListBaseClient[]>([]);
  const [isClientsLoading, setIsClientsLoading] = useState<boolean>(false);
  const [queryParams, setQueryParams] = useState<IQueryParams>({
    limit: INITIAL_QUERY_PARAMS_COUNT,
    offset: 0,
    count: 0
  });

  const fetchClientList = useCallback(() => {
    setIsClientsLoading(true);
    const limitRequestList =
      clients.length > 0 ? clients.length : INITIAL_QUERY_PARAMS_COUNT;

    AdminService.getUserClients(
      user.id,
      queryParams.offset,
      limitRequestList
    ).subscribe(
      ({ items, pagination }) => {
        setClients((prevItems) => [...prevItems, ...items]);
        setIsClientsLoading(false);
        setQueryParams((prevState) => ({
          ...prevState,
          limit: pagination.limit,
          offset: pagination.offset + INITIAL_QUERY_PARAMS_COUNT,
          count: pagination.count
        }));
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showError(error.message);
        setIsClientsLoading(false);
      }
    );
  }, [clients.length, user.id, queryParams]);

  useEffect(() => {
    fetchClientList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBottomReached = () => {
    if (queryParams.count > queryParams.offset) {
      return fetchClientList();
    }

    return null;
  };

  const renderClient = (client: ListBaseClient): JSX.Element => (
    <CardItem key={client.id} title={client.name} imageUrl={client.logoUrl} />
  );

  return (
    <div className={styles.infiniteContainer}>
      <InfiniteScrollV2
        onBottomReached={handleBottomReached}
        items={clients}
        renderer={renderClient}
        isLoading={isClientsLoading}
        customPadding={[0, 1, 0, 0]}
      />
    </div>
  );
};
