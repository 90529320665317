import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React, { useState } from "react";

import { CIQPopover } from "../CIQPopover/CIQPopover";
import { ThreeDotsUi } from "../ThreeDotsUI/ThreeDotsUi";

import styles from "./ThreeDots.module.scss";

export interface IThreeDotsItem {
  title: string;
  icon: string;
  iconColor?: string;
  iconComponent?: React.ElementType;
  color?: string;
  onClick: () => void;
  disabled?: boolean;
  disableForceClose?: boolean;
}

interface ThreeDotsProps {
  popoverId: string;
  items: IThreeDotsItem[];
  classnames?: string;
}

export const ThreeDots: React.FC<ThreeDotsProps> = ({
  popoverId,
  items,
  classnames
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const toggle = () => setIsPopoverOpen((prev) => !prev);

  return (
    <>
      <div
        onClick={toggle}
        id={`popover_${popoverId}`}
        className={clsx(styles.container, classnames)}
      >
        <ThreeDotsUi isPopoverOpen={isPopoverOpen} />
      </div>

      <CIQPopover
        isOpen={isPopoverOpen}
        id={`popover_${popoverId}`}
        onPopoverClose={toggle}
      >
        <div className={styles.itemsContainer}>
          {items.map((item, index) => (
            <ThreeDotsMenuItem
              key={item.title}
              item={item}
              toggle={toggle}
              showRuler={index !== items.length - 1}
            />
          ))}
        </div>
      </CIQPopover>
    </>
  );
};

const ThreeDotsMenuItem: React.FC<{
  item: IThreeDotsItem;
  toggle: () => void;
  showRuler: boolean;
}> = ({ item, toggle, showRuler }) => {
  const onClickItem = (item: IThreeDotsItem): void => {
    !item.disabled && item.onClick();
    toggle();
  };
  const IconComponent = item.iconComponent;

  return (
    <>
      <div
        className={clsx(
          styles.popoverItem,
          item.disabled ? styles.disabled : null
        )}
        onClick={() => onClickItem(item)}
      >
        <span className={styles.title} style={{ color: item.color }}>
          {item.title}
        </span>
        {IconComponent ? (
          <div className={styles.icon}>
            <IconComponent />
          </div>
        ) : (
          <div className={styles.icon}>
            <Icon name={item.icon} color={item.iconColor} fontSize="16px" />
          </div>
        )}
      </div>
      {showRuler && <hr />}
    </>
  );
};
