import { OutputSelector, createSelector } from "reselect";

import { PagesStateSelector } from "../PagesSelector";
import {
  PageState,
  PagesAppState,
  PagesReducerState
} from "../models/definitions";

export const getPageState = (
  pageId: string
): OutputSelector<
  PagesAppState,
  PageState,
  (res: PagesReducerState) => PageState
> =>
  createSelector<PagesAppState, PagesReducerState, PageState>(
    PagesStateSelector,
    (pagesReducerState) => pagesReducerState[pageId]
  );
