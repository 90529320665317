import i18next from "i18next";
import { Epic } from "redux-observable";
import { of } from "rxjs";
import {
  catchError,
  filter,
  flatMap,
  map,
  mergeMap,
  switchMap
} from "rxjs/operators";
import { isOfType } from "typesafe-actions";

import {
  CIQError,
  ErrorResponse,
  somethingWentWrongLabel
} from "@arbolus-technologies/api";

import {
  ADD_EXPERT_CANOPY_AGREEMENT,
  ASSIGN_EXPERT_TO_CANOPY,
  DELETE_AND_UPLOAD_VIDEO,
  DeleteAndUploadVideoAction,
  GET_CANOPY_ANSWER_DATA,
  GET_CANOPY_EXPERT,
  GET_CANOPY_EXPERT_QUESTIONS,
  GetCanopyAnswerDataAction,
  SEND_ANSWERS,
  UPLOAD_CANOPY_VIDEO
} from "../actions/CanopyExpertActionTypes";
import { CanopyExpertStoreActions } from "../actions/CanopyExpertActions";
import {
  CanopyExpertAppState,
  CanopyExpertStoreAction,
  CanopyExpertStoreDependencies
} from "../models/definitions";

const getCanopyByExpert: Epic<
  CanopyExpertStoreAction,
  CanopyExpertStoreAction,
  CanopyExpertAppState,
  CanopyExpertStoreDependencies
> = (action$, state$, { notificationService, canopyService }) =>
  action$.pipe(
    filter(isOfType(GET_CANOPY_EXPERT)),
    switchMap(({ payload: { canopyId, expertId } }) => {
      if (state$.value.canopyExpert.addRetry === 2) {
        return of(
          CanopyExpertStoreActions.getCanopyExpertFailure({
            message: "error"
          } as ErrorResponse<CIQError>),
          CanopyExpertStoreActions.addRetry(0)
        );
      }

      return canopyService.getExpertCanopy(canopyId, expertId).pipe(
        mergeMap((canopy) =>
          of(
            CanopyExpertStoreActions.getCanopyExpertSuccess(canopy),
            CanopyExpertStoreActions.getCanopyExpertQuestions(
              canopyId,
              expertId
            ),
            CanopyExpertStoreActions.addRetry(0)
          )
        ),

        catchError((error) => {
          if (error.status !== "INTERNAL_ERROR") {
            return of(
              CanopyExpertStoreActions.assignExpertToCanopy(expertId, canopyId),
              CanopyExpertStoreActions.addRetry(
                state$.value.canopyExpert.addRetry + 1
              )
            );
          }
          notificationService.showError(i18next.t(somethingWentWrongLabel));
          return of(CanopyExpertStoreActions.getCanopyExpertFailure(error));
        })
      );
    })
  );

const assignExpertToCanopy: Epic<
  CanopyExpertStoreAction,
  CanopyExpertStoreAction,
  CanopyExpertAppState,
  CanopyExpertStoreDependencies
> = (action$, _, { notificationService, canopyService, history, routing }) =>
  action$.pipe(
    filter(isOfType(ASSIGN_EXPERT_TO_CANOPY)),
    switchMap(({ payload: { expertId, canopyId } }) =>
      canopyService.assignExpertToCanopy(expertId, canopyId).pipe(
        mergeMap(() =>
          of(
            CanopyExpertStoreActions.assignExpertToCanopySuccess(),
            CanopyExpertStoreActions.getCanopyExpert(canopyId, expertId)
          )
        ),
        catchError((error) => {
          if (error.status === "1190") {
            history.replace(routing.canopyPaused());
          } else {
            notificationService.showError(i18next.t(somethingWentWrongLabel));
          }

          return of(
            CanopyExpertStoreActions.assignExpertToCanopyFailure(error)
          );
        })
      )
    )
  );

const getCanopyExpertQuestions: Epic<
  CanopyExpertStoreAction,
  CanopyExpertStoreAction,
  CanopyExpertAppState,
  CanopyExpertStoreDependencies
> = (action$, _, { notificationService, canopyService }) =>
  action$.pipe(
    filter(isOfType(GET_CANOPY_EXPERT_QUESTIONS)),
    switchMap(({ payload: { canopyId, expertId } }) =>
      canopyService.getCanopyQuestions(canopyId, expertId).pipe(
        map((questions) =>
          CanopyExpertStoreActions.getCanopyExpertQuestionsSuccess(
            questions.items
          )
        ),
        catchError((error) => {
          notificationService.showError(i18next.t(somethingWentWrongLabel));
          return of(
            CanopyExpertStoreActions.getCanopyExpertQuestionsFailure(error)
          );
        })
      )
    )
  );

const uploadCanopyVideo: Epic<
  CanopyExpertStoreAction,
  CanopyExpertStoreAction,
  CanopyExpertAppState,
  CanopyExpertStoreDependencies
> = (action$, _, { notificationService, canopyService }) =>
  action$.pipe(
    filter(isOfType(UPLOAD_CANOPY_VIDEO)),
    switchMap(({ payload: { questionId, videoBlob } }) =>
      canopyService.uploadCanopyVideo(questionId, videoBlob).pipe(
        flatMap((response) => {
          notificationService.showSuccess(
            i18next.t("canopy:canopyVideoUploadedSuccessfully")
          );
          return of(
            CanopyExpertStoreActions.uploadCanopyVideoSuccess(
              questionId,
              response.id
            )
          );
        }),
        catchError((error) => {
          notificationService.showError(i18next.t(somethingWentWrongLabel));
          return of(CanopyExpertStoreActions.uploadCanopyVideoError(error));
        })
      )
    )
  );

const getCanopyAnswerData: Epic<
  CanopyExpertStoreAction,
  CanopyExpertStoreAction,
  CanopyExpertAppState,
  CanopyExpertStoreDependencies
> = (action$, _, { notificationService, canopyService }) =>
  action$.pipe(
    filter(isOfType(GET_CANOPY_ANSWER_DATA)),
    switchMap(
      ({ payload: { questionId, expertId } }: GetCanopyAnswerDataAction) =>
        canopyService.getCanopyAnswerFromApi(questionId, expertId).pipe(
          flatMap((canopyAnswerData) =>
            of(
              CanopyExpertStoreActions.getCanopyAnswerDataSuccess(
                canopyAnswerData
              )
            )
          ),
          catchError((error: ErrorResponse<CIQError>) => {
            notificationService.showApiErrors(error);
            return of(
              CanopyExpertStoreActions.getCanopyAnswerDataFailure(error)
            );
          })
        )
    )
  );

const deleteAndUploadVideo: Epic<
  CanopyExpertStoreAction,
  CanopyExpertStoreAction,
  CanopyExpertAppState,
  CanopyExpertStoreDependencies
> = (action$, _, { notificationService, canopyService }) =>
  action$.pipe(
    filter(isOfType(DELETE_AND_UPLOAD_VIDEO)),
    switchMap(
      ({
        payload: { answerId, questionId, videoFormData }
      }: DeleteAndUploadVideoAction) =>
        canopyService.deleteCanopyAnswer(answerId).pipe(
          flatMap(() =>
            of(
              CanopyExpertStoreActions.uploadCanopyVideo(
                questionId,
                videoFormData
              )
            )
          ),
          catchError((error: ErrorResponse<CIQError>) => {
            notificationService.showApiErrors(error);
            return of(
              CanopyExpertStoreActions.deleteAndUploadVideoFailure(error)
            );
          })
        )
    )
  );

const sendCanopyAnswers: Epic<
  CanopyExpertStoreAction,
  CanopyExpertStoreAction,
  CanopyExpertAppState,
  CanopyExpertStoreDependencies
> = (action$, _, { notificationService, canopyService, history, routing }) =>
  action$.pipe(
    filter(isOfType(SEND_ANSWERS)),
    switchMap(({ payload: { canopyId } }) =>
      canopyService.sendCanopyAnswers(canopyId).pipe(
        flatMap(() => {
          notificationService.showSuccess(
            i18next.t("sendAnswers:sendAnswersSuccess")
          );
          return of(CanopyExpertStoreActions.sendCanopyAnswersSuccess());
        }),
        catchError((error) => {
          notificationService.showError(i18next.t(somethingWentWrongLabel));
          history.replace(routing.canopyDetailsPageRoute(canopyId));
          return of(CanopyExpertStoreActions.sendCanopyAnswersError(error));
        })
      )
    )
  );

const addExpertCanopyAgreement: Epic<
  CanopyExpertStoreAction,
  CanopyExpertStoreAction,
  CanopyExpertAppState,
  CanopyExpertStoreDependencies
> = (action$, _, { notificationService, canopyService }) =>
  action$.pipe(
    filter(isOfType(ADD_EXPERT_CANOPY_AGREEMENT)),
    switchMap(({ payload: { canopyId, expertId } }) =>
      canopyService.submitCanopyEngagementAgreement(canopyId, expertId).pipe(
        flatMap(() =>
          of(CanopyExpertStoreActions.addExpertCanopyAgreementSuccess())
        ),
        catchError((error) => {
          notificationService.showError(i18next.t(somethingWentWrongLabel));
          return of(
            CanopyExpertStoreActions.addExpertCanopyAgreementFailure(error)
          );
        })
      )
    )
  );

export const CanopyExpertEpics = [
  getCanopyByExpert,
  assignExpertToCanopy,
  getCanopyExpertQuestions,
  uploadCanopyVideo,
  getCanopyAnswerData,
  deleteAndUploadVideo,
  sendCanopyAnswers,
  addExpertCanopyAgreement
];
