import type { CustomerSurveyCompany } from "@arbolus-technologies/models/common";

export interface GetSurveyTemplateResponse {
  id: string;
  title: string;
  created: string;
  companies: CustomerSurveyCompany[];
}

export interface CreateInsightResponse {
  customerId?: string;
  // Response only for profile, for survey response is empty
  // Those are returned only if they were created - duplicates are ignored;
  // therefore, an empty array is an acceptable result
  insightVendors?: {
    customerInsightId: string;
    companyId: string;
    companyName: string;
  }[];
}

export interface SurveyCompanyUpdate {
  companyId: string;
  visible: boolean;
}

export enum CustomerPersona {
  User = "User",
  Influencer = "Influencer"
}

export enum ImplementationStage {
  Pilot = "Pilot",
  LessThan1Year = "LessThan1Year",
  MoreThan1Year = "MoreThan1Year",
  OffBoarding = "OffBoarding"
}

export enum RenewalIntent {
  Yes = "Yes",
  NotSure = "NotSure",
  No = "No"
}

export interface SurveyAnswer {
  customerInsightId: string;
  persona: CustomerPersona;
  implementationStage: ImplementationStage;
  spend: number;
  nps: number;
  renewalIntent: RenewalIntent;
  vendorCompanyId: string;
  vendorCompanyName: string;
}

export interface GetSurveyAnswersResponse {
  customerId: string;
  customerInsights: SurveyAnswer[];
  competitors: { companyId: string; companyName: string }[];
}
