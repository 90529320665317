import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { ArbolusModal, ThreeDots } from "@arbolus-technologies/ui/components";

import { useChat } from "../../../../../../Contexts/ChatContext/ChatContext";
import { useDeleteMessage } from "../../../../../../Hooks/Chat/useDeleteMessage";
import {
  adminCanDeleteIncomingMessages,
  isMobileChat
} from "../../../../utils";

interface ChatMessageActionsProps {
  messageId: string;
  isOutgoingMessage: boolean;
}

export const ChatMessageActions: React.FC<ChatMessageActionsProps> = ({
  messageId,
  isOutgoingMessage
}) => {
  const { t } = useTranslation("chat");

  const { chatSenderRole } = useChat();

  const { deleteMessage, isDeleting } = useDeleteMessage();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const adminSpecialPermissions = useSelector(
    CacheSelector.currentAdminSpecialPermissionsSelector()
  );
  const canDeleteMessage =
    isOutgoingMessage ||
    adminCanDeleteIncomingMessages(chatSenderRole, adminSpecialPermissions);

  const menuItems = useMemo(
    () => [
      {
        title: t("delete"),
        icon: "delete",
        color: ARBOLUS_COLORS.bColorBaseOrange,
        iconColor: ARBOLUS_COLORS.bColorBaseOrange,
        onClick: () => setIsDeleteModalOpen(true),
        disabled: isDeleting
      }
    ],
    [isDeleting, t]
  );

  if (!canDeleteMessage) return null;

  return (
    <>
      <ThreeDots popoverId={`chat-message-${messageId}`} items={menuItems} />
      <ArbolusModal
        title={t("deleteChatMessageTitle")}
        subtitle={t("deleteChatMessageSubtitle")}
        toggle={() =>
          // Otherwise the modal is accidentally closed
          isMobileChat() ? false : setIsDeleteModalOpen(false)
        }
        isOpen={isDeleteModalOpen}
        leftButton={{
          text: t("cancel"),
          type: "secondary",
          onClick: () => setIsDeleteModalOpen(false)
        }}
        rightButton={{
          text: t("delete"),
          type: "primary",
          onClick: () => {
            setIsDeleteModalOpen(false);
            return deleteMessage(messageId);
          }
        }}
      />
    </>
  );
};
