import { PanelId } from "../models/definitions";

export const OPEN_PANEL = "PANELS_OPEN_PANEL";
export const CLOSE_PANEL = "PANELS_CLOSE_PANEL";

// OPEN PANEL
export interface OpenPanelAction {
  type: typeof OPEN_PANEL;
  payload: {
    panelId: PanelId;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any;
  };
}

export interface ClosePanelAction {
  type: typeof CLOSE_PANEL;
  payload: {
    panelId: PanelId;
  };
}
