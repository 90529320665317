import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Col } from "reactstrap";

import { SavedClient } from "../../../../models/client";

interface ClientNetworkCardProps {
  client: SavedClient;
  navigateToProjects: () => void;
}

const ClientNetworkCard = ({
  client,
  navigateToProjects
}: ClientNetworkCardProps): JSX.Element => {
  const { name, projectCount } = client;
  const { t } = useTranslation("clientNetworkCard");

  return (
    <Col
      xl={3}
      lg={4}
      md={4}
      sm={6}
      xs={12}
      onClick={(e): void => {
        e.stopPropagation();
        navigateToProjects();
      }}
    >
      <div className="client-network-card">
        <div className="top-container">
          <div className="name-container">
            <h4>{name}</h4>
          </div>
          <p>{t("project", { count: projectCount })}</p>
        </div>

        <div className="bottom-container">
          <Button size="sm" color="primary" type="button">
            <span className="ciq-chevron-right" />
          </Button>
        </div>
      </div>
    </Col>
  );
};

export default ClientNetworkCard;
