import { Icon } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { CanopyV2Selector } from "@arbolus-technologies/stores/canopy-V2";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { UserPortrait } from "@arbolus-technologies/ui/components";
import { APP_DATE_FORMAT, formatDate } from "@arbolus-technologies/utils";

import { TranscriptStatusTag } from "../../../../TranscriptStatusTag/TranscriptStatusTag";
import { Data, ExpertModel } from "../../../types";
import { Transcript } from "../Transcript/Transcript";
import { TranscriptButtons } from "../TranscriptButtons/TranscriptButtons";

import styles from "./WithExpert.module.scss";

interface WithExpertProps {
  expert: ExpertModel;
  redirectToExpert?: (expertId: string) => void;
  data: Data;
  videoDuration: string;
}

export const WithExpert: React.FC<WithExpertProps> = ({
  expert,
  redirectToExpert,
  data,
  videoDuration
}) => {
  const {
    answer,
    wordsToHighlight,
    transcriptState,
    resetTranscript,
    internalTranscript,
    transcriptStatus,
    isDrawerOpen,
    saveTranscript,
    handleSetTranscriptState,
    handleSetResetModal,
    resetModal,
    handleShareLink,
    handleTranscriptClick
  } = data;
  const { t } = useTranslation("answer");
  const dispatch = useDispatch();

  const canopyData = useSelector(CanopyV2Selector.canopyData());

  const handleUserClick = () => {
    redirectToExpert
      ? redirectToExpert(expert.expertId)
      : dispatch(
          PanelStoreActions.openPanel(PanelId.ExpertProfile, {
            expertId: expert.expertId,
            canopyId: canopyData?.id
          })
        );
  };

  const { created } = answer;

  return (
    <>
      <div
        className={styles.userPortraitContainer}
        onClick={handleUserClick}
        onKeyDown={handleUserClick}
      >
        <UserPortrait user={expert} onHoverTooltip />
      </div>
      {isDrawerOpen && (
        <div className={styles.buttonsContainer}>
          <div className={styles.innerButtonsContainer}>
            <TranscriptStatusTag transcriptStatus={transcriptStatus} />
            <div
              title={t("copy")}
              className={styles.icon}
              onClick={() => handleShareLink(answer.id, expert.expertId)}
              onKeyDown={() => handleShareLink(answer.id, expert.expertId)}
            >
              <Icon name="link" color="#7f849f" fontSize="24px" />
            </div>
          </div>
          <TranscriptButtons
            handleSetResetModal={handleSetResetModal}
            handleSetTranscriptState={handleSetTranscriptState}
            transcriptStatus={transcriptStatus}
          />
        </div>
      )}

      <Transcript
        transcriptState={transcriptState}
        resetTranscript={resetTranscript}
        internalTranscript={internalTranscript}
        isDrawerOpen={isDrawerOpen}
        saveTranscript={saveTranscript}
        handleSetTranscriptState={handleSetTranscriptState}
        handleSetResetModal={handleSetResetModal}
        resetModal={resetModal}
        wordsToHighlight={wordsToHighlight}
        handleTranscriptClick={handleTranscriptClick}
      />
      <p className={styles.responseDateInner}>
        <span className={styles.videoDuration}>{videoDuration}</span>
        {` - `}
        {formatDate(created, APP_DATE_FORMAT)}
      </p>
    </>
  );
};
