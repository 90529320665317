import React from "react";
import { Control, UseFormWatch } from "react-hook-form";

import { SelectOption } from "@arbolus-technologies/models/common";
import { CustomSelectLabelController } from "@arbolus-technologies/ui/components";

import { INCIDENT_FORM, INCIDENT_PRIORITY } from "../../../../enums";

interface IncidentPrioritySelectorProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, object>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<any>;
  error?: string;
  placeholder: string;
  noOptionsMessage: string;
}

export const IncidentPrioritySelector: React.FC<
  IncidentPrioritySelectorProps
> = ({ control, watch, error, placeholder, noOptionsMessage }) => {
  const prioritySelected: string = watch(INCIDENT_FORM.PRIORITY);

  const priorities: SelectOption[] = Object.values(INCIDENT_PRIORITY).map(
    (priority: string): SelectOption => ({
      value: priority,
      label: priority
    })
  );

  const customLabel = priorities.find(
    (priority) => priority.value === prioritySelected
  );

  return (
    <CustomSelectLabelController
      name={INCIDENT_FORM.PRIORITY}
      control={control}
      options={priorities}
      placeholder={placeholder}
      noOptionsMessage={noOptionsMessage}
      error={error}
      customLabel={customLabel?.label || INCIDENT_PRIORITY.Lowest}
    />
  );
};
