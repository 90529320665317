import { Form, Input } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { CountrySelectionPanel } from "../../../../Panels/CountrySelectionPanel/CountrySelectionPanel";
import { Country } from "../../../../interfaces";
import { EMPLOYED_STATUS_OPTIONS } from "../../helpers/constants";

interface CountrySelectorProps {
  isDisabled: boolean;
}

export const CountrySelector: React.FC<CountrySelectorProps> = ({
  isDisabled
}) => {
  const { t } = useTranslation("workHistoryEditForm");
  const form = Form.useFormInstance();
  const employedStatus = Form.useWatch("employedStatus", form);
  const country = Form.useWatch("country", form);

  const [open, setOpen] = useState(false);

  const handleOnChange = (value: Country) => {
    form.setFieldsValue({
      country: { name: value.name, threeLetterCode: value.threeLetterCode }
    });
  };

  return (
    <>
      <Input
        placeholder={t("typeToSearch")}
        readOnly
        onClick={() => setOpen(true)}
        value={country?.name}
        disabled={
          !EMPLOYED_STATUS_OPTIONS.includes(employedStatus) || isDisabled
        }
        prefix={
          <AntDIcon name="search" color={ARBOLUS_COLORS.bColorGrayIcon} />
        }
      />
      <CountrySelectionPanel
        country={country}
        onCountrySelected={handleOnChange}
        isOpen={open}
        handleClose={() => setOpen(false)}
      />
    </>
  );
};
