import { ICellRendererParams } from "ag-grid-community";
import { Icon } from "arbolus-ui-components";
import React from "react";

import styles from "./OnboardedMarker.module.scss";

export function OnboardedMarker({ value }: ICellRendererParams) {
  return value ? (
    <div className={styles.wrapper}>
      <div className={styles.marker}>
        <Icon name="check" color="white" fontSize="12px" />
      </div>
    </div>
  ) : null;
}
