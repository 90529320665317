import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "arbolus-ui-components";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form } from "reactstrap";

import { REFERRAL_REJECT_REASON } from "@arbolus-technologies/models/project";
import {
  InputController,
  InputRadio
} from "@arbolus-technologies/ui/components";

import {
  REJECT_REASON,
  REJECT_TEXT,
  RejectFeedbackFormObject,
  RejectFeedbackSchema
} from "./RejectFeedbackSchema";

import styles from "./RejectFeedbackForm.module.scss";

export interface RejectFeedbackFormProps {
  submit: (reject: RejectFeedbackFormObject) => void;
  skip: () => void;
}

export const RejectFeedbackForm: React.FC<RejectFeedbackFormProps> = ({
  submit,
  skip
}) => {
  const { t } = useTranslation("rejectExpert");

  const options = Object.values(REFERRAL_REJECT_REASON).map((value) => ({
    value,
    label: t(value)
  }));

  const formReturn = useForm<RejectFeedbackFormObject>({
    resolver: yupResolver(RejectFeedbackSchema),
    mode: "all",
    defaultValues: {
      [REJECT_REASON]: null,
      [REJECT_TEXT]: ""
    }
  });

  const {
    control,
    watch,
    handleSubmit,
    formState: { isValid, errors, submitCount }
  } = formReturn;

  const watchRejectReason = watch(REJECT_REASON);

  return (
    <div className={styles.container}>
      <p className={styles.info}>{t("info")}</p>
      <p className={styles.subInfo}>{t("subInfo")}</p>
      <FormProvider {...formReturn}>
        <Form onSubmit={handleSubmit(submit)} className={styles.form}>
          <InputRadio
            control={control}
            name={REJECT_REASON}
            options={options}
          />
          {watchRejectReason === REFERRAL_REJECT_REASON.OTHER && (
            <InputController
              control={control}
              type="textarea"
              placeholder={t("placeholder")}
              name={REJECT_TEXT}
              error={errors[REJECT_TEXT]}
              size="big"
              required
            />
          )}

          <hr className="mt-auto" />
          <div className={styles.buttons}>
            <Button text={t("skip")} type="secondary" onClick={skip} />
            <Button
              text={t("submit")}
              type="primary"
              nativeType="submit"
              disabled={!isValid || submitCount > 0}
            />
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};
