/* spell-checker: disable */

enum CanopyFollowUpPanelMixpanelEventNames {
  CanopyQuestionsAnswersFollowup = "canopy_questions_answers_followup",
  CanopyExpertsAnswersFollowup = "canopy_experts_answers_followup",
  CanopyExpertsProfileFollowup = "canopy_experts_profile_followup",
  CanopySummaryFollowup = "canopy_summary_followup",
  CanopySummaryQuoteLinkFollowup = "canopy_summary_quote_link_followup",
  CanopyFollowUpPanel = "canopy_followup_panel",
  CanopyFollowupBook = "canopy_followup_book",
  CanopyFollowupAsk = "canopy_followup_ask",
  CanopyFollowupAskTypeQuestion = "canopy_followup_ask_typeQuestion",
  CanopyFollowUpAskCancel = "canopy_followup_ask_cancel",
  CanopyFollowUpAskTypeQuestionSend = "canopy_followup_ask_typeQuestion_send"
}

enum CanopyCreationPanelMixpanelEventNames {
  CanopyCreationPanel = "canopy_creation_panel",
  CanopyCreationPanelTitle = "canopy_creation_panel_title",
  CanopyCreationPanelDescription = "canopy_creation_panel_description",
  CanopyCreationPanelAI = "canopy_creation_panel_AI",
  CanopyCreationPanelScratch = "canopy_creation_panel_scratch",
  CanopyCreationPanelTemplates = "canopy_creation_panel_templates",
  CanopyCreationPanelCustomerTemplate = "canopy_creation_panel_templates_customer",
  CanopyCreationPanelCompetitorTemplate = "canopy_creation_panel_templates_competitor",
  CanopyCreationPanelCancel = "canopy_creation_panel_cancel",
  CanopyCreationPanelAddQuestions = "canopy_creation_panel_add_questions"
}

enum CanopyBuilderMixpanelEventNames {
  CanopyBuilderAddNewQuestion = "canopy_builder_add_new_question",
  CanopyBuilderEditQuestion = "canopy_builder_edit_question",
  CanopyBuilderPanel = "canopy_builder_panel",
  CanopyBuilderPanelQuestion = "canopy_builder_panel_Question",
  CanopyBuilderPanelDescription = "canopy_builder_panel_Description",
  canopyBuilderPanelAnswerType = "canopy_builder_panel_answer_type",
  CanopyBuilderPanelAddQuestion = "canopy_builder_panel_add_question",
  CanopyBuilderAddQuestionCancel = "canopy_builder_add_question_cancel",
  CanopyBuilderTopLaunchCanopy = "canopy_builder_top_LaunchCanopy",
  CanopyBuilderBottomLaunchCanopy = "canopy_builder_bottom_LaunchCanopy",
  CanopyBuilderDetailsBriefTitle = "CanopyBuilder_details_brief_title",
  CanopyBuilderDetailsBriefDescription = "CanopyBuilder_details_brief_description",
  CanopyBuilderQuestionsTopAdd = "CanopyBuilder_questions_top_Add",
  CanopyBuilderQuestionsBottomAdd = "CanopyBuilder_questions_bottom_Add",
  CanopyBuilderQuestionsTypeVideo = "CanopyBuilder_questions_type_video",
  CanopyBuilderQuestionsTypeMulti = "CanopyBuilder_questions_type_multi",
  CanopyBuilderQuestionsTypeValue = "CanopyBuilder_questions_type_value",
  CanopyBuilderQuestionsTypeShortText = "CanopyBuilder_questions_type_shortext",
  CanopyBuilderQuestionsTypeNPS = "CanopyBuilder_questions_type_NPS",
  CanopyBuilderLaunch = "canopy_builder_launch"
}

enum CanopyPageMixpanelEventNames {
  // Canopy Tabs
  CanopyDetails = "canopy_details",
  CanopyQuestions = "canopy_questions",
  CanopyExperts = "canopy_experts",
  CanopySummary = "canopy_summary"
}

enum CanopiesListPageMixpanelEventNames {
  CanopyListTopNewCanopy = "canopy_list_top_NewCanopy",
  CanopyListCanopyCardTitle = "canopy_list_canopy_card_title",
  CanopyListCanopyCardExperts = "canopy_list_canopy_card_experts",
  CanopyListCanopyCardProjectName = "canopy_list_canopy_card_ProjectName"
}

enum CanopyLaunchMixpanelEventNames {
  LaunchCanopyGoBack = "launch_canopy_GoBack",
  LaunchCanopyLaunchNow = "launch_canopy_LaunchNow"
}

enum CanopyExportAnswerMixpanelEventNames {
  CanopyQuestionsExportAnswers = "canopy_questions_ExportAnswers",
  CanopyQuestionsExportAnswersPDF = "canopy_questions_ExportAnswers_PDF",
  CanopyQuestionsExportAnswersExcel = "canopy_questions_ExportAnswers_Excel",
  CanopyExpertsExportAnswers = "canopy_experts_ExportAnswers",
  CanopyExpertsExportAnswersPDF = "canopy_experts_ExportAnswers_PDF",
  CanopyExpertsExportAnswersExcel = "canopy_experts_ExportAnswers_Excel",
  CanopyAudioRequestButton = "canopy_summary_audio_request",
  CanopyAudioRequestConfirmButton = "canopy_summary_audio_request_confirmation"
}

enum CanopyArbolusGPTMixpanelEventNames {
  CanopyQuestionsArbolusGPT = "canopy_questions_ArbolusGPT",
  CanopyExpertsArbolusGPT = "canopy_experts_ArbolusGPT"
}

enum CanopyReportExpertMixpanelEventNames {
  CanopyReportExpertButton = "canopy_report_expert_button",
  CanopyReportExpertSubmit = "canopy_report_expert_submit"
}

enum CanopyFeedbackCollectorMixpanelEventNames {
  CanopyFeedbackCollector = "canopy_feedback_collector",
  CanopyFeedbackCollectorClose = "canopy_feedback_collector_close",
  CanopyFeedbackCollectorSelectedStar = "canopy_feedback_collector_selectedStar",
  CanopyFeedbackCollectorSubmit = "canopy_feedback_collector_submit"
}

enum CanopyTimeEventNames {
  CanopyTimeSpent = "canopy_time_spent",
  CanopySummaryTimeSpent = "canopy_summary_time_spent",
  CanopyQuestionsTimeSpent = "canopy_questions_time_spent",
  CanopyPerQuestionTimeSpent = "canopy_per_question_time_spent",
  CanopyExpertsTimeSpent = "canopy_experts_time_spent",
  CanopyPerExpertTimeSpent = "canopy_per_expert_time_spent"
}

enum CanopyProjectPageMixpanelEventNames {
  CanopiesListProjectArbolusGPT = "canopies_list_ArbolusGPT"
}

export const CanopyMixpanelEventNames = {
  ...CanopyFollowUpPanelMixpanelEventNames,
  ...CanopyCreationPanelMixpanelEventNames,
  ...CanopyBuilderMixpanelEventNames,
  ...CanopyPageMixpanelEventNames,
  ...CanopiesListPageMixpanelEventNames,
  ...CanopyLaunchMixpanelEventNames,
  ...CanopyExportAnswerMixpanelEventNames,
  ...CanopyFeedbackCollectorMixpanelEventNames,
  ...CanopyTimeEventNames,
  ...CanopyProjectPageMixpanelEventNames,
  ...CanopyArbolusGPTMixpanelEventNames,
  ...CanopyReportExpertMixpanelEventNames
};

export type CanopyMixpanelEvent =
  | CanopyFollowUpPanelMixpanelEventNames
  | CanopyCreationPanelMixpanelEventNames
  | CanopyBuilderMixpanelEventNames
  | CanopyPageMixpanelEventNames
  | CanopiesListPageMixpanelEventNames
  | CanopyLaunchMixpanelEventNames
  | CanopyExportAnswerMixpanelEventNames
  | CanopyFeedbackCollectorMixpanelEventNames
  | CanopyTimeEventNames
  | CanopyProjectPageMixpanelEventNames
  | CanopyArbolusGPTMixpanelEventNames
  | CanopyReportExpertMixpanelEventNames;
