import { Button } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ExpertAvailabilityPanelBase } from "./types";

import styles from "./ActionsExpertAvailability.module.scss";

interface ActionsExpertAvailabilityProps {
  handleDeleteEvent: () => void;
  handleSubmit: () => void;
}

export const ActionsExpertAvailability: React.FC<
  ActionsExpertAvailabilityProps
> = ({ handleDeleteEvent, handleSubmit }) => {
  const { t } = useTranslation("expertAvailabilityScheduler");
  const {
    formState: { errors }
  } = useFormContext<ExpertAvailabilityPanelBase>();

  const hasErrors = !!Object.keys(errors).length;

  return (
    <div className={styles.buttonContainer}>
      <span
        className={clsx("material-symbols-sharp", styles.icon)}
        onClick={handleDeleteEvent}
      >
        delete
      </span>
      <Button
        disabled={hasErrors}
        onClick={handleSubmit}
        text={t("expertAvailabilitySchedulerPanel.confirm")}
      />
    </div>
  );
};
