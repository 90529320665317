import clsx from "clsx";
import React from "react";
import { Col } from "reactstrap";

interface AdminCardProps {
  key?: string;
  title?: string;
  onCardClick?: () => void;
  children: React.ReactNode;
}

const AdminCard = (props: AdminCardProps): JSX.Element => {
  const { key, title, children, onCardClick } = props;

  return (
    <Col sm="12" md="6" lg="4" className="mb-3">
      <div
        className={clsx("project-admin-actions", {
          "cursor-on": onCardClick
        })}
        key={key}
        onClick={onCardClick}
      >
        <div className="action-title">{title}</div>
        <p className="action-desc">{children}</p>
      </div>
    </Col>
  );
};

export default AdminCard;
