export const getFileExtension = (fileName: string): string => {
  const lastIndex = fileName.lastIndexOf(".");
  if (lastIndex > -1) {
    return fileName.slice(lastIndex + 1).toLowerCase();
  }

  return "";
};

export const isFileExtensionAllowed = (
  fileName: string,
  allowedExtensions: ReadonlyArray<string>
): boolean => {
  const fileExtension = getFileExtension(fileName);

  return allowedExtensions
    .map((ext) => ext.toLowerCase())
    .includes(fileExtension);
};

export const normalizeFileName = (name: string): string => {
  // eslint-disable-next-line no-useless-escape
  const regexFileExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i;
  const fileExtension = regexFileExtension.exec(name) || ".undefined";

  const fileName = name.split(fileExtension[0])[0];

  const fileNameNoSpaces = fileName.replace(/\s/g, "_");
  const fileNameNoPeriods = fileNameNoSpaces.replace(/\./g, "_");

  return fileNameNoPeriods.concat(fileExtension[0]);
};
