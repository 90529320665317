import { ICellRendererParams } from "ag-grid-community";
import { Icon } from "arbolus-ui-components";
import React from "react";

export const ContextMenuRenderer: React.FC<ICellRendererParams> = ({
  api,
  node,
  column,
  value
}) => {
  const openContextMenu = React.useCallback(
    (event: React.MouseEvent) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- hidden API
      (api as any).menuService.contextMenuFactory.showMenu(
        node,
        column,
        value,
        event
      );
    },
    [api, column, node, value]
  );

  return <Icon name="more_horiz" fontSize="18px" onClick={openContextMenu} />;
};
