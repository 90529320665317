import i18next from "i18next";
import * as Yup from "yup";

import { UserConstraints } from "../../../../constants/validation";

export const ForgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required(i18next.t("forgotPassword:emailRequiredError"))
    .email(i18next.t("forgotPassword:emailInvalidError"))
    .max(
      UserConstraints.MAX_EMAIL_LENGTH,
      i18next.t("forgotPassword:maxEmailLength", {
        length: UserConstraints.MAX_EMAIL_LENGTH
      })
    )
});
