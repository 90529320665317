import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { KEYWORDS_SEARCH_OPTIONS } from "@arbolus-technologies/models/project";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { Checkbox } from "@arbolus-technologies/ui/components";

import styles from "./KeywordsSearchOptions.module.scss";

interface KeywordsListProps {
  handleSetKeywordsSearchOptions: (keywordSearchOption: string) => void;
}

export const KeywordsSearchOptions: React.FC<KeywordsListProps> = ({
  handleSetKeywordsSearchOptions
}) => {
  const { t } = useTranslation("discoverPage");
  const keywordsSearchOptionsSelected = useSelector(
    ProjectNxSelector.keywordsSearchOptions()
  );

  const keywordsListItems = [
    {
      translationKey: "tagline",
      enumValue: KEYWORDS_SEARCH_OPTIONS.USER_TITLE
    },
    {
      translationKey: "jobTitle",
      enumValue: KEYWORDS_SEARCH_OPTIONS.WORK_HISTORIES_TITLE
    },
    {
      translationKey: "companyName",
      enumValue: KEYWORDS_SEARCH_OPTIONS.WORK_HISTORIES_COMPANY
    },
    {
      translationKey: "projectNotes",
      enumValue: KEYWORDS_SEARCH_OPTIONS.PROJECTS_NOTES
    },
    {
      translationKey: "screeningAnswers",
      enumValue: KEYWORDS_SEARCH_OPTIONS.PROJECTS_ANSWERS
    }
  ];

  return (
    <div className={styles.container}>
      {keywordsListItems.map((item) => {
        const isChecked = keywordsSearchOptionsSelected.includes(
          item.enumValue
        );
        return (
          <Checkbox
            key={item.enumValue}
            isChecked={isChecked}
            onChange={() => handleSetKeywordsSearchOptions(item.enumValue)}
            text={t(item.translationKey)}
            isDisabled={keywordsSearchOptionsSelected.length === 1 && isChecked}
          />
        );
      })}
    </div>
  );
};
