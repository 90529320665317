/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from "clsx";
import React from "react";
import { Control, FieldError } from "react-hook-form";
import { Label } from "reactstrap";

import { InputController } from "../InputController/InputController";

import styles from "./TextInput.module.scss";

interface TextInputProps {
  control: Control<any>;
  error?: FieldError;
  label?: string;
  name: string;
  placeholder?: string;
  autoFocus?: boolean;
  [x: string]: unknown;
}
export const TextInput: React.FC<TextInputProps> = ({
  control,
  error,
  label,
  name,
  placeholder = "",
  autoFocus = false,
  ...inputProps
}) => (
  <div className={styles.inputContainer}>
    {label && (
      <Label
        className={clsx(styles.label, {
          required: inputProps.required
        })}
      >
        {label}
      </Label>
    )}
    <InputController
      autoFocus={autoFocus}
      classnames={styles.input}
      name={name}
      error={error}
      control={control}
      placeholder={placeholder}
      {...inputProps}
    />
  </div>
);
