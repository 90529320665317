import { SavePageStateAction } from "../actions/PagesActionTypes";
import { PagesReducerState } from "../models/definitions";
import { initialState } from "./PagesInitialState";

export const handleSavePageState = (
  state: PagesReducerState = initialState,
  { payload }: SavePageStateAction
): PagesReducerState => ({
  ...state,
  [payload.pageId]: payload.pageState
});
