import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router";

import {
  MixPanelEventNames,
  useTimeTracking
} from "@arbolus-technologies/features/common";
import {
  CANOPY_QUESTIONS,
  CANOPY_SINGLE_QUESTION
} from "@arbolus-technologies/routes";
import { CanopyV2Selector } from "@arbolus-technologies/stores/canopy-V2";

import { QuestionDetails } from "./QuestionDetails/QuestionDetails";
import { QuestionsList } from "./QuestionsList/QuestionsList";

export const CanopyQuestions: React.FC = () => {
  const { startTrackingTime, endTrackingTime } = useTimeTracking(
    MixPanelEventNames.CanopyQuestionsTimeSpent
  );

  const canopyData = useSelector(CanopyV2Selector.canopyData());

  useEffect(() => {
    startTrackingTime();

    return () => {
      endTrackingTime({
        canopyId: canopyData?.id,
        canopyName: canopyData?.title
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      <Route path={CANOPY_QUESTIONS} component={QuestionsList} exact />
      <Route path={CANOPY_SINGLE_QUESTION} component={QuestionDetails} exact />
    </Switch>
  );
};
