import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, InputGroup, InputGroupAddon } from "reactstrap";
import SimpleBar from "simplebar-react";

import { UserConstraints } from "../../../../../constants/validation";
import { Speaker } from "../../../../../models/view/transcript";
import { CIQAvatar, CIQNoResults } from "../../../../app/components";

const MAX_SPEAKER_NAME_LENGTH =
  UserConstraints.MAX_FIRST_NAME_LENGTH + UserConstraints.MAX_LAST_NAME_LENGTH;

interface SpeakerSearchResultState {
  isDirectMatch: boolean;
  filteredSpeakers: Speaker[];
  searchQuery: string;
}
interface SpeakerSearchProps {
  currentSpeakerName: string;
  speakers: Speaker[];
  onSpeakerAssign: (newSpeaker: Speaker) => void;
  onSpeakerAdd: (newSpeaker: Speaker) => void;
  togglePopover: (toggle: boolean) => void;
}

const SpeakerSearch: React.FC<SpeakerSearchProps> = ({
  speakers,
  onSpeakerAssign,
  onSpeakerAdd,
  togglePopover,
  currentSpeakerName
}): JSX.Element => {
  const { t } = useTranslation("transcript");
  const [resultsState, setResultState] = useState<SpeakerSearchResultState>({
    isDirectMatch: true,
    filteredSpeakers: speakers,
    searchQuery: ""
  });

  const { isDirectMatch, filteredSpeakers, searchQuery } = resultsState;
  const isSpeakerExist = filteredSpeakers.length > 0;
  const isSearchQueryExceed = searchQuery.length > MAX_SPEAKER_NAME_LENGTH;
  const trimmedQuery = searchQuery.trim();
  const isValidQuery = trimmedQuery.length > 0;
  const isSameUserName = currentSpeakerName === trimmedQuery;
  const noDirectMatch = isValidQuery && !isSameUserName && !isDirectMatch;

  const clearSearchQuery = (): void => {
    setResultState({
      filteredSpeakers: speakers,
      isDirectMatch: true,
      searchQuery: ""
    });
  };

  const handleInputChangeEvent = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const query = event.target.value;
    if (query) {
      const filtered = speakers.filter((speaker) =>
        speaker.name.toLowerCase().includes(query.trim().toLowerCase())
      );

      setResultState({
        filteredSpeakers: filtered,
        isDirectMatch: !!filtered.find((fs) => fs.name === query.trim()),
        searchQuery: query
      });
    } else {
      setResultState({
        filteredSpeakers: speakers,
        isDirectMatch: false,
        searchQuery: query
      });
    }
  };

  const handleSpeakerClicked = (newSpeaker: Speaker): void => {
    onSpeakerAssign(newSpeaker);
    togglePopover(false);
  };

  const handleAddSpeaker = (newSpeaker: Speaker): void => {
    onSpeakerAdd(newSpeaker);
    togglePopover(false);
  };

  const renderEmptyState = (): JSX.Element => {
    if (isSearchQueryExceed) {
      return (
        <CIQNoResults
          message={t("maxSpeakerLength", { length: MAX_SPEAKER_NAME_LENGTH })}
        />
      );
    }

    return isValidQuery && !isSameUserName ? (
      renderAddNewSpeaker()
    ) : (
      <CIQNoResults message={t("noSpeakerQuery")} />
    );
  };

  const renderAddNewSpeaker = (): JSX.Element => (
    <div
      className="new-speaker-item"
      onClick={(): void => handleAddSpeaker({ name: trimmedQuery })}
    >
      <i className="ciq-icon ciq-plus" />

      <div className="speaker-name">
        {t("addSpeaker", { speakerName: searchQuery })}
      </div>
    </div>
  );

  return (
    <div className="speaker-popover-container">
      <div className="search-column">
        <InputGroup className="search-input-white">
          <InputGroupAddon addonType="prepend">
            <span className="ciq-icon ciq-search" />
          </InputGroupAddon>
          <Input
            autoComplete="off"
            placeholder="Speaker name"
            name="searchQuery"
            value={searchQuery}
            onClick={(event): void => event.stopPropagation()}
            onChange={handleInputChangeEvent}
          />
          {searchQuery && (
            <InputGroupAddon addonType="append">
              <span className="clear-text" onClick={clearSearchQuery}>
                Clear
              </span>
            </InputGroupAddon>
          )}
        </InputGroup>
      </div>
      <SimpleBar
        className="simplebar-light user-list-container"
        style={{
          width: `100%`,
          maxHeight: `335px`,
          height: `280px`,
          overflowX: "hidden"
        }}
      >
        <div className="users-list">
          {isSpeakerExist ? (
            <>
              {filteredSpeakers.map((speaker, index) => (
                <div
                  className="user-item"
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${speaker.name}_${index}`}
                  onClick={(): void => handleSpeakerClicked(speaker)}
                >
                  <div className="users-avatar">
                    <CIQAvatar
                      username={speaker.userId ? speaker.name : ""}
                      profileImageUrl={speaker.profileImageUrl}
                      fallBackImageClass={clsx("fallback-avatar", {
                        newSpeaker: !speaker.userId
                      })}
                    />
                  </div>
                  <div className="user-name">{speaker.name}</div>
                </div>
              ))}
              {noDirectMatch && renderAddNewSpeaker()}
            </>
          ) : (
            renderEmptyState()
          )}
        </div>
      </SimpleBar>
    </div>
  );
};

export default SpeakerSearch;
