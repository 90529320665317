import { INBOX_NOTIFICATION_TYPE } from "../enums/notificationEnums";
import {
  ApplicationCompletionAdminNotificationData,
  CanopySurveyLaunchedNotificationData,
  DeclinedApplicationAdminNotificationData,
  EventInvitesNotificationData,
  ExpertAcceptInvitationAdminNotificationData,
  ExpertAddedToProjectAdminNotificationData,
  ExpertAddedToProjectClientNotificationData,
  ExpertCandidateRejectedAdminNotificationData,
  ExpertSubmittedCanopyNotificationData,
  ExpertsAssignedToProjectNotificationData,
  InboxNotification,
  MemberAcceptInvitationAdminNotificationData,
  PendingProjectReviewNotificationData,
  ProjectBriefUpdatedNotificationData,
  ProjectLiveAdminNotificationData,
  ProjectUnreadMessagesNotificationData,
  TranscriptAvailableNotificationData
} from "../models/notifications";

export const isExpertAddedToProjectAdminNotification = (
  inboxNotification: InboxNotification
): inboxNotification is InboxNotification<ExpertAddedToProjectAdminNotificationData> =>
  inboxNotification.notificationData.type ===
  INBOX_NOTIFICATION_TYPE.ExpertAddedToProjectAdminNotification;

export const isProjectLiveAdminNotification = (
  inboxNotification: InboxNotification
): inboxNotification is InboxNotification<ProjectLiveAdminNotificationData> =>
  inboxNotification.notificationData.type ===
  INBOX_NOTIFICATION_TYPE.ProjectLiveAdminNotification;

export const isProjectBriefUpdatedNotification = (
  inboxNotification: InboxNotification
): inboxNotification is InboxNotification<ProjectBriefUpdatedNotificationData> =>
  inboxNotification.notificationData.type ===
  INBOX_NOTIFICATION_TYPE.ProjectBriefUpdatedNotification;

export const isExpertCandidateRejectedAdminNotification = (
  inboxNotification: InboxNotification
): inboxNotification is InboxNotification<ExpertCandidateRejectedAdminNotificationData> =>
  inboxNotification.notificationData.type ===
  INBOX_NOTIFICATION_TYPE.ExpertCandidateRejectedAdminNotification;

export const isApplicationCompletionAdminNotification = (
  inboxNotification: InboxNotification
): inboxNotification is InboxNotification<ApplicationCompletionAdminNotificationData> =>
  inboxNotification.notificationData.type ===
  INBOX_NOTIFICATION_TYPE.ApplicationCompletionAdminNotification;

export const isDeclinedApplicationAdminNotification = (
  inboxNotification: InboxNotification
): inboxNotification is InboxNotification<DeclinedApplicationAdminNotificationData> =>
  inboxNotification.notificationData.type ===
  INBOX_NOTIFICATION_TYPE.DeclinedApplicationAdminNotification;

export const isTranscriptAvailableNotification = (
  inboxNotification: InboxNotification
): inboxNotification is InboxNotification<TranscriptAvailableNotificationData> =>
  inboxNotification.notificationData.type ===
  INBOX_NOTIFICATION_TYPE.TranscriptAvailableNotification;

export const isMemberAcceptInvitationAdminNotification = (
  inboxNotification: InboxNotification
): inboxNotification is InboxNotification<MemberAcceptInvitationAdminNotificationData> =>
  inboxNotification.notificationData.type ===
  INBOX_NOTIFICATION_TYPE.MemberAcceptInvitationAdminNotification;

export const isExpertAcceptInvitationAdminNotification = (
  inboxNotification: InboxNotification
): inboxNotification is InboxNotification<ExpertAcceptInvitationAdminNotificationData> =>
  inboxNotification.notificationData.type ===
  INBOX_NOTIFICATION_TYPE.ExpertAcceptInvitationAdminNotification;

export const isPendingProjectReviewNotification = (
  inboxNotification: InboxNotification
): inboxNotification is InboxNotification<PendingProjectReviewNotificationData> =>
  inboxNotification.notificationData.type ===
  INBOX_NOTIFICATION_TYPE.PendingProjectReviewNotification;

export const isProjectUnreadMessagesNotification = (
  inboxNotification: InboxNotification
): inboxNotification is InboxNotification<ProjectUnreadMessagesNotificationData> =>
  inboxNotification.notificationData.type ===
  INBOX_NOTIFICATION_TYPE.ProjectUnreadMessagesNotification;

export const isCanopySurveyLaunchedNotification = (
  inboxNotification: InboxNotification
): inboxNotification is InboxNotification<CanopySurveyLaunchedNotificationData> =>
  inboxNotification.notificationData.type ===
  INBOX_NOTIFICATION_TYPE.CanopySurveyLaunchedNotification;

export const isExpertSubmittedCanopyNotification = (
  inboxNotification: InboxNotification
): inboxNotification is InboxNotification<ExpertSubmittedCanopyNotificationData> =>
  inboxNotification.notificationData.type ===
  INBOX_NOTIFICATION_TYPE.ExpertSubmittedCanopyNotification;

export const isEventInvitesNotification = (
  inboxNotification: InboxNotification
): inboxNotification is InboxNotification<EventInvitesNotificationData> =>
  inboxNotification.notificationData.type ===
  INBOX_NOTIFICATION_TYPE.EventNotification;

export const isExpertsAssignedToProjectNotification = (
  inboxNotification: InboxNotification
): inboxNotification is InboxNotification<ExpertsAssignedToProjectNotificationData> =>
  inboxNotification.notificationData.type ===
  INBOX_NOTIFICATION_TYPE.ExpertsAssignedToProjectNotification;

export const isExpertAddedToProjectClientNotification = (
  inboxNotification: InboxNotification
): inboxNotification is InboxNotification<ExpertAddedToProjectClientNotificationData> =>
  inboxNotification.notificationData.type ===
  INBOX_NOTIFICATION_TYPE.ExpertAddedToProjectClientNotification;
