import React from "react";
import { useTranslation } from "react-i18next";

import { InfoCookieModal } from "@arbolus-technologies/ui/components";

import { TextIconItem } from "./TextIconItem";

import styles from "./DemoVideoPopUp.module.scss";

const iFrameUrl =
  "https://www.floik.com/embed/0b89f4a4-9bdf-4e9f-8232-8e6a11db6e70/c29fc84f-d1f8-428e-90e5-791012fca5f2-flo.html";

const listTextArray = new Array(5).fill(0);

export const DemoVideoPopUp: React.FC = () => {
  const { t } = useTranslation("demoVideoPopUp");
  return (
    <InfoCookieModal
      cookie="demoVideo"
      buttonText={t("buttonText")}
      containerStyle={styles.modalContainer}
    >
      <div>
        <div className={styles.wrapperHeader}>
          <span className={styles.headerIcon} role="img" aria-label="hello">
            👋
          </span>
          <h2 className={styles.title}>{t("title")}</h2>
        </div>
        <div className={styles.marginBottom}>
          <p className={styles.textColor}>
            {t("description1")}
            <span role="img" aria-label="boat">
              🛳️
            </span>
          </p>
          <p className={styles.textColor}>{t("description2")}</p>
        </div>
        <iframe
          id="demo-video"
          title={t("iframeTitle")}
          src={iFrameUrl}
          className={styles.iFrame}
          width={"100%"}
          height={350}
          allow="fullscreen"
        />
        <div className={styles.wrapperSubTitle}>
          <span className={styles.iconSubTitle} role="img" aria-label="here">
            👉
          </span>
          <span className={styles.subTitle}>{t("subTitle")}</span>
        </div>
        <div className={styles.marginBottom}>
          {listTextArray.map((_, index) => (
            <TextIconItem key={index} text={t(`listText${index + 1}`)} />
          ))}
        </div>
      </div>
    </InfoCookieModal>
  );
};
