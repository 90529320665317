import clsx from "clsx";
import React from "react";

import styles from "./IconWithTitle.module.scss";

interface IconWithTitleProps {
  icon: string;
  title: string;
  subtitle?: string;
  classnames?: string;
}

export const IconWithTitle: React.FC<IconWithTitleProps> = ({
  icon,
  title,
  subtitle,
  classnames
}) => (
  <div className={clsx(styles.container, classnames)}>
    <div className={styles.circle}>
      <span className="material-symbols-sharp">{icon}</span>
    </div>
    <div className={styles.content}>
      <h3 className={styles.title}>{title}</h3>
      <h6 className={styles.subtitle}>{subtitle}</h6>
    </div>
  </div>
);
