/* eslint-disable react/static-property-placement */
/* eslint-disable react/no-did-update-set-state */
import React from "react";
import { useSelector } from "react-redux";

import { REDIRECT_STATES } from "../../constants/app";
import RedirectPage from "../../containers/app/pages/errors/redirect/RedirectPage";
import { AppSelector } from "../../containers/app/store";
import { AuthSelector } from "../../containers/auth/store";
import { AccessControlContextProvider } from "./AccessControlContext";

const AccessControlProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const redirectionType = useSelector(AppSelector.appRedirectionTypeSelector());
  const currentRole = useSelector(AuthSelector.authUserRoleSelector());

  const isRedirection = redirectionType !== REDIRECT_STATES.IDLE;

  return (
    <AccessControlContextProvider value={{ role: currentRole }}>
      {isRedirection ? (
        <RedirectPage redirectionType={redirectionType} />
      ) : (
        children
      )}
    </AccessControlContextProvider>
  );
};

export default AccessControlProvider;
