import { Button } from "arbolus-ui-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { EXPERT_FOLLOW_UP_STEPS } from "@arbolus-technologies/models/expert";
import { ExpertStoreActions } from "@arbolus-technologies/stores/expert";

import { MixPanelEventNames } from "../../../../Mixpanel/enums";
import { useArbolusTracking } from "../../../../Mixpanel/useArbolusTracking";

import styles from "./SendAdditionalQuestions.module.scss";

interface SendAdditionalQuestionsProps {
  onSendMessage: (question: string) => void;
  expertId: string;
  canopyId: string;
}

export const SendAdditionalQuestions: React.FC<
  SendAdditionalQuestionsProps
> = ({ onSendMessage, expertId, canopyId }) => {
  const { t } = useTranslation("expertFollowUpSlidePanel");
  const dispatch = useDispatch();
  const { trackClick } = useArbolusTracking();

  const [question, setQuestion] = useState<string>("");

  const handleCancel = () => {
    trackClick(MixPanelEventNames.CanopyFollowUpAskCancel, {
      canopyId,
      expertId
    });
    dispatch(ExpertStoreActions.resetExpertFollowUpStep());
    dispatch(
      ExpertStoreActions.setExpertFollowUpStep(
        EXPERT_FOLLOW_UP_STEPS.ENGAGE_EXPERT
      )
    );
  };

  const handleSendAdditionalQuestion = () => {
    trackClick(MixPanelEventNames.CanopyFollowUpAskTypeQuestionSend, {
      canopyId,
      expertId
    });
    onSendMessage(question);
  };

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <h1 className={styles.title}>{t("sendAdditionalQuestionTitle")}</h1>
        <div className={styles.inputContainer}>
          <p className={styles.subtitle}>{t("yourQuestions")}</p>
          <textarea
            className={styles.textArea}
            placeholder={t("placeholder")}
            value={question}
            onChange={(event) => setQuestion(event.target.value)}
            onClick={() =>
              trackClick(MixPanelEventNames.CanopyFollowupAskTypeQuestion, {
                canopyId,
                expertId
              })
            }
          />
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <Button text={t("cancel")} type="tertiary" onClick={handleCancel} />
        <Button
          text={t("confirm")}
          onClick={handleSendAdditionalQuestion}
          disabled={!question}
        />
      </div>
    </div>
  );
};
