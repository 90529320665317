import React from "react";
import { Control, FieldArrayWithId, FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { InputController } from "../../InputController/InputController";
import { CheckBoxItem } from "./CheckBoxItem";
import { ClientReportExpertForm } from "./types";

import styles from "./ArbolusModalClientReportExpert.module.scss";

interface BodyProps {
  fields: FieldArrayWithId<ClientReportExpertForm, "reportReason", "id">[];
  control: Control<ClientReportExpertForm>;
  errors: FieldErrors<ClientReportExpertForm>;
  isDirty: boolean;
}

export const Body: React.FC<BodyProps> = ({
  fields,
  control,
  errors,
  isDirty
}) => {
  const { t } = useTranslation("reportExpert");

  return (
    <div>
      <p>{t("reportExpertSubtitle")}</p>
      <div className={styles.checkboxesWrapper}>
        {fields.map((field, index) => (
          <CheckBoxItem
            key={field.id}
            name={`reportReason[${index}].isSelected`}
            text={field.text}
            control={control}
            errors={errors}
          />
        ))}
      </div>
      <div>
        <p className={styles.textAreaTitle}>{t("textFieldTitle")}</p>
        <InputController
          size="big"
          type="textarea"
          name="feedbackText"
          control={control}
          placeholder={t("textFieldPlaceholder")}
          required
          error={errors.feedbackText}
          disabled={!isDirty}
        />
      </div>
    </div>
  );
};
