import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import { PROJECT_ROUTE } from "@arbolus-technologies/routes";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { MainPageLayout } from "@arbolus-technologies/ui/layout";

import { ProjectExpertManagementLinks } from "./ProjectExpertManagementLinks/ProjectExpertManagementLinks";

interface ProjectExpertManagementWrapperProps {
  stickyHeader?: boolean;
  children: React.ReactNode;
}

export const ProjectExpertManagementWrapper: React.FC<
  ProjectExpertManagementWrapperProps
> = ({ stickyHeader = false, children }) => {
  const { projectId } = useParams<{ projectId: string }>();

  const projectName = useSelector(ProjectNxSelector.projectName());

  return (
    <MainPageLayout
      title={projectName}
      backLink={{
        text: projectName,
        to: PROJECT_ROUTE(projectId)
      }}
      stickyHeader={stickyHeader}
      rightButtonContainer={<ProjectExpertManagementLinks />}
    >
      {children}
    </MainPageLayout>
  );
};
