import dompurify from "dompurify";
import moment from "moment";
import React from "react";

import { ProjectNote } from "@arbolus-technologies/api";
import { Avatar } from "@arbolus-technologies/ui/components";

import { AssignedAngle } from "../../AssignedAngle/AssignedAngle";

import styles from "./ProjectNoteCard.module.scss";

export interface ProjectNoteCardProps {
  projectNote: ProjectNote;
}

export const ProjectNoteCard: React.FC<ProjectNoteCardProps> = ({
  projectNote
}) => {
  const { note, noteDate, project, angle, client, createdUser } = projectNote;

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className={styles.user}>
          <Avatar
            avatar={{
              name: client.name,
              imageUrl: client.logoUrl
            }}
          />
          <span className={styles.name}>{client.name}</span>
        </div>
        <span className={styles.date}>
          {moment(noteDate).format("DD MMM YYYY")}
        </span>
      </div>
      <hr className={styles.divider} />
      <div className={styles.body}>
        <div className={styles.title}>{project.name}</div>
        <AssignedAngle angle={angle} angleClassName={styles.angle} />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(note)
          }}
        />
      </div>
      <div className={styles.footer}>
        <div className={styles.user}>
          <Avatar
            avatar={{
              name: createdUser.name,
              imageUrl: createdUser.profileImageUrl
            }}
          />
          <span className={styles.name}> {createdUser.name}</span>
        </div>
      </div>
    </div>
  );
};
