import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  AnglesService,
  DefaultToasterService,
  ProjectExpertsService,
  ToasterService
} from "@arbolus-technologies/api";
import {
  ModalService,
  SelectableCardList
} from "@arbolus-technologies/features/common";
import { AngleModel } from "@arbolus-technologies/models/common";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { HR, Loader, UserHeader } from "@arbolus-technologies/ui/components";

import { UpdateAngleParams } from "../../Models/ReferralsTable";

import styles from "./UpdateExpertAngle.module.scss";

interface UpdateExpertAngleProps extends UpdateAngleParams {
  projectsService?: typeof ProjectExpertsService;
  anglesService?: typeof AnglesService;
  toasterService?: ToasterService;
}

export const UpdateExpertAngle: React.FC<UpdateExpertAngleProps> = ({
  referral,
  projectId,
  onSuccess,
  projectsService = ProjectExpertsService,
  anglesService = AnglesService,
  toasterService = DefaultToasterService
}) => {
  const { t } = useTranslation("addEditAngleSlidePanel");
  const dispatch = useDispatch();
  const [angles, setAngles] = React.useState<AngleModel[]>();
  const [selectedAngle, setSelectedAngle] = React.useState<AngleModel>(
    referral.angle
  );
  const user = {
    ...referral.expert,
    title: referral.expert.tagline,
    phoneNumber: referral.expert.phoneNumber ?? undefined
  };

  React.useEffect(
    function fetchAngles() {
      anglesService.getAnglesCards(projectId, 0, 50).subscribe({
        next: ({ items }) => setAngles(items),
        error: toasterService.showApiErrors
      });
    },
    [anglesService, projectId, toasterService.showApiErrors]
  );

  const onSelectAngle = React.useCallback(
    (id: string) => {
      setSelectedAngle(angles!.find((angle) => angle.id === id)!);
    },
    [angles]
  );

  const updateAngle = React.useCallback(() => {
    const next = () => {
      toasterService.showSuccess(t("angleUpdateSuccess"));
      onSuccess(selectedAngle);
      dispatch(PanelStoreActions.closePanel(PanelId.UpdateExpertAngle));
    };
    const onConfirm = () => {
      projectsService
        .updateReferralAngle(referral.id, selectedAngle.id)
        .subscribe({ next, error: toasterService.showApiErrors });
    };
    if (!referral.angle) {
      onConfirm();
      return;
    }

    const modalService = new ModalService(dispatch);
    modalService.openMainModal({
      title: t("angleConfirmationTitle"),
      subtitle: t("angleConfirmationMessage"),
      onConfirm
    });
  }, [
    projectsService,
    referral,
    selectedAngle,
    toasterService,
    onSuccess,
    dispatch,
    t
  ]);

  return (
    <>
      <h2 className="text-left">Edit angle</h2>
      <HR />
      <UserHeader user={user} />
      {!angles && <Loader />}
      {angles && (
        <div className={styles.angles}>
          <SelectableCardList
            items={angles.map((angle) => ({
              key: angle.id,
              text: angle.title,
              iconName: "zoom_in_map",
              iconCustomStyle: { backgroundColor: angle.color }
            }))}
            filters={selectedAngle?.id ? [selectedAngle.id] : []}
            onClick={onSelectAngle}
          />
        </div>
      )}
      <div className="flex-row-16 justify-content-end">
        <Button
          text={t("cancel")}
          type="tertiary"
          onClick={() =>
            dispatch(PanelStoreActions.closePanel(PanelId.UpdateExpertAngle))
          }
        />
        <Button
          text={t("applyChanges")}
          onClick={updateAngle}
          disabled={
            !angles || !selectedAngle || selectedAngle.id === referral.angle?.id
          }
        />
      </div>
    </>
  );
};
