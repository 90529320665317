import { Icon } from "arbolus-ui-components";
import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./SignUpOption.module.scss";

interface SignUpOptionProps {
  title: string;
  description: string;
  icon: string;
  onClick: () => void;
}

const SignUpOption = ({
  title,
  description,
  icon,
  onClick
}: SignUpOptionProps): JSX.Element => (
  <div className={styles.signUpOption} onClick={onClick}>
    <div className={styles.leftIcon}>
      <Icon
        name={icon}
        color={ARBOLUS_COLORS.bColorBasePurple}
        fontSize="24px"
      />
    </div>
    <div className={styles.titleAndDescription}>
      <span className={styles.title}>{title}</span>
      <span className={styles.description}>{description}</span>
    </div>
    <Icon
      name="chevron_right"
      color={ARBOLUS_COLORS.bColorBasePurple}
      fontSize="24px"
    />
  </div>
);

export default SignUpOption;
