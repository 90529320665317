import { GetAppTypeAction } from "../actions/CacheGetAppType/CacheGetAppTypeActionTypes";
import { CacheReducerState } from "../models/definitions";
import { initialState } from "./CacheInitialState";

export const checkIfIsAdmin = (
  state = initialState,
  { payload }: GetAppTypeAction
): CacheReducerState => ({
  ...state,
  isAdmin: payload.isAdmin
});
