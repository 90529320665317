import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import {
  MixPanelEventNames,
  MixpanelPages,
  PageTracker,
  useTimeTracking
} from "@arbolus-technologies/features/common";
import {
  ANSWER_TYPE,
  CanopyParamUrlTypes
} from "@arbolus-technologies/models/canopy";
import {
  CANOPY_QUESTIONS_ROUTE,
  CANOPY_SINGLE_QUESTION_ROUTE
} from "@arbolus-technologies/routes";
import {
  CanopyV2Selector,
  CanopyV2StoreActions
} from "@arbolus-technologies/stores/canopy-V2";
import { Header } from "@arbolus-technologies/ui/canopy-panels";
import {
  ButtonToggle,
  LinkButton,
  NumberCircle
} from "@arbolus-technologies/ui/components";

import { QuestionMultiAnswers } from "./QuestionMultiAnswers";
import { QuestionNpsAnswers } from "./QuestionNpsAnswers";
import { QuestionShortTextAnswers } from "./QuestionShortTextAnswers";
import { QuestionValueAnswers } from "./QuestionValueAnswers";
import { QuestionVideoAnswers } from "./QuestionVideoAnswers";

import styles from "./QuestionDetails.module.scss";

export const QuestionDetails: React.FC = () => {
  const { t } = useTranslation("canopyV2");
  const dispatch = useDispatch();
  const history = useHistory();
  const { startTrackingTime, endTrackingTime } = useTimeTracking(
    MixPanelEventNames.CanopyPerQuestionTimeSpent
  );
  const { canopyQuestionId } = useParams<CanopyParamUrlTypes>();

  const [toggleState, setToggleState] = useState<boolean>(false);

  const canopyData = useSelector(CanopyV2Selector.canopyData());
  const question = useSelector(CanopyV2Selector.questionData());

  useEffect(() => {
    startTrackingTime();

    return () => {
      endTrackingTime({
        canopyId: canopyData?.id,
        canopyName: canopyData?.title,
        questionId: canopyQuestionId
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (canopyQuestionId) {
      dispatch(CanopyV2StoreActions.getCanopyQuestion(canopyQuestionId));
    }
  }, [canopyQuestionId, dispatch]);

  const renderHeaderButton = (): JSX.Element | undefined => {
    if (canopyQuestionId && canopyData && question) {
      const { previousQuestionId, nextQuestionId } = question;
      return (
        <div className={styles.navigationContainer}>
          <LinkButton
            to={CANOPY_SINGLE_QUESTION_ROUTE(
              canopyData.id,
              previousQuestionId!
            )}
            type="tertiary"
            text={t("prev")}
            startIcon="chevron_left"
            disabled={!previousQuestionId}
          />
          <LinkButton
            to={CANOPY_SINGLE_QUESTION_ROUTE(canopyData.id, nextQuestionId!)}
            type="tertiary"
            text={t("next")}
            endIcon="chevron_right"
            disabled={!nextQuestionId}
          />
        </div>
      );
    }
  };

  const QUESTION_TYPE_COMPONENTS = {
    [ANSWER_TYPE.VIDEO]: <QuestionVideoAnswers toggleState={toggleState} />,
    [ANSWER_TYPE.MULTIPLE_CHOICE]: <QuestionMultiAnswers />,
    [ANSWER_TYPE.VALUE]: <QuestionValueAnswers />,
    [ANSWER_TYPE.SHORT_TEXT]: <QuestionShortTextAnswers />,
    [ANSWER_TYPE.NPS]: <QuestionNpsAnswers />
  };

  if (!question) {
    return <span>{t("answerTypeNotFound")}</span>;
  }

  return (
    <PageTracker page={MixpanelPages.CanopyQuestionsDeepDive}>
      <Header
        backButton={{
          onClick: () => history.push(CANOPY_QUESTIONS_ROUTE(canopyData?.id!)),
          title: t("questions")
        }}
        actionButton={renderHeaderButton()}
      />
      <div className={styles.container}>
        <div className={styles.questionNumberContainer}>
          <NumberCircle
            number={(question.order || 0) + 1}
            classnames={styles.number}
          />

          <div className={styles.titleContainer}>
            <span className={styles.title}>{question.text}</span>
            {question.details && (
              <span className={styles.subtitle}>{question.details}</span>
            )}
          </div>
        </div>
        {question?.type === ANSWER_TYPE.VIDEO && (
          <div className={styles.toggleContainer}>
            <ButtonToggle
              handleToggle={() => setToggleState(!toggleState)}
              toggle={toggleState}
            />
          </div>
        )}
        {QUESTION_TYPE_COMPONENTS[question.type]}
      </div>
    </PageTracker>
  );
};
