import { ADMIN_ENDPOINT } from "../endpoints/admin";

export const BASE_URL = process.env.NX_PUBLIC_BASE_URL;
export const API_VERSION = process.env.NX_PUBLIC_API_VERSION;
export const API_URL = `${BASE_URL}/api/v${API_VERSION}`;
export const API_URL_V2 = `${BASE_URL}/api/v2`;
export const API_URL_V3 = `${BASE_URL}/api/v3`;

// Edit Tagline
export const TAGLINE = "tagline";
export const TAGLINE_MAX_LENGTH = 200;

// Limits
export const MAX_PAGE_SIZE = 10;
export const MAX_PAGE_SIZE_20 = 20;
export const MAX_PAGE_SIZE_25 = 25;
export const USER_PAGE_SIZE = 50;
export const USERS_MAX_PAGINATION_PAGES = 5;
export const MIN_EVENTS_LENGTH = 3;

// Endpoints
const CLIENTS_ENDPOINT = `/clients`;
const USERS_ENDPOINT = `/users`;
const REVIEWS_ENDPOINT = `/reviews`;
const PROJECT_DRAFT_ENDPOINT = `/projectdrafts`;
const PROJECT_ENDPOINT = `/projects`;
const PROJECT_EXPERT_ENDPOINT = `/projectexperts`;
const DISCOVER_ENDPOINT = `/discover`;
const PROJECT_CLIENTS_ENDPOINT = `/projectclients`;
const METADATA_ENDPOINT = `/metadata`;
const PROJECT_BILLING_ENDPOINT = "/projectbilling";
const TRANSACTIONS_ENDPOINT = "/transactions";
const CANOPY_ENDPOINT = `/canopies`;
// cspell:disable-next-line
const BOOKMARKS_ENDPOINT = `/bookmarkcollections`;
const EVENTS_ENDPOINT = `/events`;
const WEBHOOKs_ENDPOINT = `/webhooks`;
const FEEDBACK_ENDPOINT = `/feedback`;

export const REVIEWS_API = {
  GET_REVIEWS: (): string => `${REVIEWS_ENDPOINT}`,
  GET_RANDOM_REVIEW: (): string => `${REVIEWS_ENDPOINT}/random`
};

export const CLIENTS_API = {
  CREATE_USER: (clientId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}/team-members/invitation`,
  GET_USER: (clientId: string, userId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}/team-members/${userId}`,
  UPDATE_USER: (clientId: string, userId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}/team-members/${userId}`,
  GET_TEAM_MEMBERS: (clientId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}/team-members`,
  GET_TEAM_MEMBER: (clientId: string, userId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}/team-members/${userId}`,
  LOGO_SAVE: (clientId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}/logo`,
  GET_TEAM_MEMBER_PROJECTS_HISTORY: (
    clientId: string,
    userId: string
  ): string => `${CLIENTS_ENDPOINT}/${clientId}/user/${userId}/projects`,
  GET_TEAM_MEMBERS_CANDIDATE_PROJECTS: (clientId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}/team-members/candidate-projects`,
  REINVITE: (clientId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}/team-members/resend-invitation`,
  GET_INVITE_LINK: (clientId: string, userId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}/team-members/invitation-link/${userId}`,
  GET_FREQUENT_USED_COUNTRIES: (clientId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}/countries`,
  GET_SAVED_EXPERTS: (clientId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}/saved-experts`,
  GET_SAVED_EXPERT_STATUS: (clientId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}/saved-experts/status`,
  DELETE_SAVED_EXPERT: (clientId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}/saved-experts`,
  GET_BUSINESS_ENTITY: (businessEntityId: string): string =>
    `${CLIENTS_ENDPOINT}/business-entities/${businessEntityId}`,
  UPDATE_BUSINESS_ENTITY: (businessEntityId: string): string =>
    `${CLIENTS_ENDPOINT}/business-entities/${businessEntityId}`,
  CREATE_BUSINESS_ENTITY: (clientId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}/business-entities`,
  GET_BUSINESS_ENTITIES: (clientId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}/business-entities`,
  GET_TEAM_MEMBERS_PROJECTS: (clientId: string): string =>
    `${CLIENTS_ENDPOINT}/${clientId}/team-members/projects`,
  GET_TEAM_MEMBER_PERMISSIONS: (userId: string): string =>
    `${CLIENTS_ENDPOINT}/team-members/${userId}/permissions`,
  UPDATE_TEAM_MEMBER_PERMISSIONS: (userId: string): string =>
    `${CLIENTS_ENDPOINT}/team-members/${userId}/permissions`,
  GET_SPECIAL_PERMISSIONS: (): string =>
    `${CLIENTS_ENDPOINT}/team-members/permissions`
};

export const USERS_API = {
  GET_PROFILE: (): string => `${USERS_ENDPOINT}/profile`,
  LOGIN: (): string => `${USERS_ENDPOINT}/login`,
  LOGIN_OPEN_ID: (domain: string): string =>
    `${USERS_ENDPOINT}/open-id/${domain}`,
  LOGOUT: (): string => `${USERS_ENDPOINT}/logout`,
  OPEN_ID: (): string => `${USERS_ENDPOINT}/open-id`,
  REQUEST_RESET_PASSWORD: (): string => `${USERS_ENDPOINT}/password`,
  RESET_PASSWORD: (): string => `${USERS_ENDPOINT}/password`,
  USER_PASSWORD_CHANGE: (): string => `${USERS_ENDPOINT}/password/reset`,
  REFRESH_TOKEN: (): string => `${USERS_ENDPOINT}/token`,
  PUSHER_AUTHENTICATE: (): string => `${USERS_ENDPOINT}/pusher-authenticate`,
  ADD_USER_PROFILE_IMAGE: (userId: string): string =>
    `${USERS_ENDPOINT}/${userId}/profile-image`,
  UPDATE_PROFILE: (userId: string): string => `${USERS_ENDPOINT}/${userId}`,
  GET_NOTIFICATION_CATEGORIES: (): string =>
    `${USERS_ENDPOINT}/notifications/categories`,
  GET_NOTIFICATIONS: (): string => `${USERS_ENDPOINT}/notifications`,
  GET_NOTIFICATIONS_PROJECTS: (): string =>
    `${USERS_ENDPOINT}/notifications/projects`,
  SEND_FCM_TOKEN: (): string => `${USERS_ENDPOINT}/notifications/fcm-token`,
  UPDATE_NOTIFICATIONS_SETTINGS: (): string =>
    `${USERS_ENDPOINT}/notifications/settings`,
  UPDATE_STARRED_EXPERT: (expertId: string): string =>
    `${USERS_ENDPOINT}/starred-expert/${expertId}`,
  DELETE_STARRED_EXPERT: (expertId: string): string =>
    `${USERS_ENDPOINT}/starred-expert/${expertId}`,
  CLEAR_ALL_NOTIFICATIONS: (): string => `${USERS_ENDPOINT}/inbox`,
  CLEAR_SECTION_NOTIFICATIONS: (): string =>
    `${USERS_ENDPOINT}/notifications/type`
};

// Projects
export const PROJECTS_API = {
  GET_MEMBERS: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/members`,
  ADD_MEMBER: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/members`,
  GET_PROJECT: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}`,
  DELETE_PROJECT: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}`,
  GET_EXPERTS: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/experts`,
  GET_REFERRAL: (projectId: string, referralId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/referrals/${referralId}`,
  GET_REFERRALS: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/referrals/details`,
  GET_EXPERT_CANDIDATES: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/experts/candidates`,
  DELETE_FROM_PROJECT: (projectId: string, referralId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/referrals/${referralId}`,
  ADD_EXPERT_REFERRAL: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/referrals`,
  ADD_MULTIPLE_REFERRALS: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/referrals/collection`,
  GET_EARLIEST_AVAILABILITIES: (): string =>
    `${PROJECT_ENDPOINT}/referrals/availabilities`,
  GET_PROJECT_CANDIDATES: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/members/candidates`,
  REMOVE_MEMBER: (projectId: string, memberId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/members/${memberId}`,
  UPDATE_REFERRAL_STATE: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/referrals/status`,
  UPDATE_PROJECT_STATE: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/state`,
  REFERRAL_SUMMARY: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/referral/summary`,
  INVITE_TO_PROJECT: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/members/invitation`,
  ADD_REFERRAL_NOTE: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/referrals/note`,
  GET_QUESTIONS: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/questions`,
  EDIT_PROJECT: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}`,
  CREATE_PROJECT: (): string => `${PROJECT_ENDPOINT}`,
  GET_PROJECT_SETTINGS: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/settings`,
  GET_IS_EXPERT_IN_PROJECT: (projectId: string, expertId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/referrals/experts/${expertId}`,
  EDIT_TAGLINE: (projectId: string, referralId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/referrals/tagline?referralId=${referralId}`,
  EXPORT_EXPERTS: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/referrals/export`,
  PROJECT_CALENDAR_ROUTE: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/calendar`,
  GET_PROJECT_REFERRALS: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/referrals`,
  UPDATE_REFERRAL_ANGLE: (referralId: string, angleId: string): string =>
    `${PROJECT_ENDPOINT}/referrals/${referralId}/angle?angleId=${angleId}`,
  GET_PROJECT_REFERRALS_AVAILABILITIES: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/experts/availability`,
  SEND_APPLICATION_REMINDER: (projectId: string, expertId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/experts/${expertId}/remind`,
  GET_PROJECT_STATS: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/stats`,
  GET_PROJECT_RECOMMENDATIONS: (projectId: string): string =>
    `/recommendations/projects/${projectId}/expert-recommendations`,
  GET_PROJECT_COMPLIANCE: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/compliance`,
  LIST_PROJECTS: (): string => `${PROJECT_ENDPOINT}`,
  ASK_GPT: (): string => `projectSearch/question`,
  EXPERT_SEARCH: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/experts/search`,
  PROJECT_COLLECTION: (): string => `${PROJECT_ENDPOINT}/collection`,
  HAS_PROJECT_VECTORS: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/has-project-vectors`,
  GET_SEARCH_STATS: (projectId: string) =>
    `${PROJECT_ENDPOINT}/${projectId}/experts/search-stats`,
  GET_PROJECT_PIPELINE_EXPERTS: (projectId: string) =>
    `${PROJECT_ENDPOINT}/${projectId}/project-pipelines`,
  GET_PROJECT_PIPELINE_EXPERT: (projectId: string, projectPipelineId: string) =>
    `${PROJECT_ENDPOINT}/${projectId}/project-pipeline/${projectPipelineId}`,
  UPDATE_PROJECT_PIPELINE_EXPERT: (projectPipelineId: string) =>
    `${PROJECT_ENDPOINT}/project-pipeline/${projectPipelineId}`,
  GET_PROJECT_PIPELINE_STATS: (projectId: string) =>
    `${PROJECT_ENDPOINT}/${projectId}/project-pipelines/stats`,
  CREATE_PROJECT_PIPELINE_REFERRAL: (projectPipelineId: string) =>
    `${PROJECT_ENDPOINT}/project-pipeline/${projectPipelineId}/create-referral`,
  EXPORT_PROJECT_PIPELINES: (projectId: string) =>
    `${PROJECT_ENDPOINT}/${projectId}/project-pipelines/export`,
  GET_PROJECT_PIPELINE_EXPERT_HISTORY: (projectPipelineId: string) =>
    `${PROJECT_ENDPOINT}/project-pipeline/${projectPipelineId}/history`,
  UPDATE_PROJECT_PIPELINE_STATUS_BULK: () =>
    `${PROJECT_ENDPOINT}/project-pipelines/status`,
  CHANGE_EXPERT_TO_INTERNAL: (projectPipelineId: string) =>
    `${PROJECT_ENDPOINT}/project-pipeline/${projectPipelineId}/change-expert`
};

export const PROJECT_ROUTE = (projectId: string): string =>
  `/project/${projectId}`;

export const PROJECT_CALENDAR_ROUTE = (projectId: string): string =>
  `/project/${projectId}/calendar`;

// ProjectExperts
export const PROJECT_EXPERTS_API = {
  GET_PROJECTS: (): string => `${PROJECT_EXPERT_ENDPOINT}`,
  GET_RATE: (): string => `${PROJECT_EXPERT_ENDPOINT}/rate`,
  GET_PROJECT_EXPERT_AVAILABILITY: (
    projectId: string,
    expertId: string
  ): string =>
    `${PROJECT_EXPERT_ENDPOINT}/${projectId}/expert/${expertId}/expert-availability`,
  ADD_PROJECT_EXPERT_AVAILABILITY: (
    projectId: string,
    expertId: string
  ): string =>
    `${PROJECT_EXPERT_ENDPOINT}/${projectId}/expert/${expertId}/expert-availability`,
  REQUEST_PROJECT_EXPERT_AVAILABILITY: (
    projectId: string,
    expertId: string
  ): string =>
    `${PROJECT_EXPERT_ENDPOINT}/${projectId}/expert/${expertId}/require-expert-availability`,
  GET_PROJECT_APPLICATION_BRIEF: (projectId: string): string =>
    `${PROJECT_EXPERT_ENDPOINT}/${projectId}`
};

// Project events
export const PROJECT_EVENTS_API = {
  GET_EVENTS: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/events`,
  GET_EVENT_CANDIDATES: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/events/candidates`,
  GET_EVENTS_DURATION: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/events/duration`,
  CREATE_EVENT: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/events`,
  GET_EVENT: (projectId: string, eventId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/events/${eventId}`,
  SAVE_EVENTS: (projectId: string, eventId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/events/${eventId}`,
  DELETE_EVENT: (projectId: string, eventId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/events/${eventId}`
};

// Documents
export const DOCUMENTS_API = {
  GET_ADMIN_DOCUMENTS: (projectId: string): string =>
    `${ADMIN_ENDPOINT}/projects/${projectId}/documents`,
  GET_CLIENT_DOCUMENTS: (projectId: string): string =>
    `${PROJECT_CLIENTS_ENDPOINT}/${projectId}/documents`,
  GET_EXPERT_DOCUMENTS: (projectId: string): string =>
    `${PROJECT_EXPERT_ENDPOINT}/${projectId}/documents`,
  CREATE_ADMIN_DOCUMENT: (projectId: string): string =>
    `${ADMIN_ENDPOINT}/${projectId}/documents`,
  CREATE_CLIENT_DOCUMENT: (projectId: string): string =>
    `${PROJECT_CLIENTS_ENDPOINT}/${projectId}/documents`,
  CREATE_EXPERT_DOCUMENT: (projectId: string): string =>
    `${PROJECT_EXPERT_ENDPOINT}/${projectId}/documents`,
  GET_DOCUMENT: (projectId: string, documentId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/documents/${documentId}`,
  GET_DOCUMENT_DOWNLOAD_URL: (projectId: string, documentId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/documents/${documentId}`,
  DELETE_DOCUMENT: (projectId: string, documentId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/documents/${documentId}`,
  RENAME_DOCUMENT_URL: (projectId: string, documentId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/documents/${documentId}`,
  CHAT_DOCUMENT_SHARE: (projectId: string, chatId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/workspaces/${chatId}/documents`
};

// METADATA
export const METADATA_API = {
  GET_COUNTRIES: (): string => `${METADATA_ENDPOINT}/countries`,
  GET_CITIES: (): string => `${METADATA_ENDPOINT}/cities`,
  GET_INDUSTRIES: (): string => `${METADATA_ENDPOINT}/industries`,
  GET_CURRENCIES: (): string => `${METADATA_ENDPOINT}/currencies`,
  GET_COMPANIES: (): string => `${METADATA_ENDPOINT}/companies`,
  GET_COMPANIES_BY_ID: (companyId: string): string =>
    `${METADATA_ENDPOINT}/companies/${companyId}`,
  GET_TIMEZONES: (): string => `${METADATA_ENDPOINT}/timezones`,
  GET_REGIONS: (): string => `${METADATA_ENDPOINT}/regions`,
  GET_ENGAGEMENT_AGREEMENT: (): string =>
    `${METADATA_ENDPOINT}/engagement-agreement`,
  GET_EXCHANGE_RATES: (): string => `${METADATA_ENDPOINT}/exchange-rates`,
  POST_APP_CRASH_DATA: (): string => `${METADATA_ENDPOINT}/client-crash`,
  GET_FEATURE_FLAGS: (): string => `${METADATA_ENDPOINT}/feature-flags`
};

// Discover
export const DISCOVER_API = {
  GET_DISCOVER_EXPERTS: (): string => `${DISCOVER_ENDPOINT}/experts`
};

// ProjectClients
export const PROJECT_CLIENTS_API = {
  GET_DOCUMENT_STATUS: (projectId: string, documentId: string): string =>
    `${PROJECT_CLIENTS_ENDPOINT}/${projectId}/documents/${documentId}/status`,
  CREATE_DOCUMENT: (projectId: string): string =>
    `${PROJECT_CLIENTS_ENDPOINT}/${projectId}/documents`,
  BILLING: (): string => `${PROJECT_CLIENTS_ENDPOINT}/billing`,
  TOTAL_SPENT: (): string => `${PROJECT_CLIENTS_ENDPOINT}/billing/total`,
  EXPORT_TRANSACTIONS: (): string => `${PROJECT_CLIENTS_ENDPOINT}/export`,
  GET_PROJECTS: (): string => `${PROJECT_CLIENTS_ENDPOINT}`
};

// PROJECT DRAFTS
export const DRAFT_PROJECT_API = {
  START_PROJECT_DRAFT_V2: (): string => `${PROJECT_DRAFT_ENDPOINT}/v2`,
  GET_DRAFT_PROJECTS: (): string => `${PROJECT_DRAFT_ENDPOINT}`,
  GET_DRAFT_PROJECT: (draftId: string): string =>
    `${PROJECT_DRAFT_ENDPOINT}/v2/${draftId}`,
  DELETE_DRAFT_PROJECT: (draftId: string): string =>
    `${PROJECT_DRAFT_ENDPOINT}/v2/${draftId}`,
  PUT_PROJECT_DRAFT: (draftId: string): string =>
    `${PROJECT_DRAFT_ENDPOINT}/${draftId}`,
  PUT_PROJECT_DRAFT_V2: (draftId: string): string =>
    `${PROJECT_DRAFT_ENDPOINT}/v2/${draftId}`,
  CREATE_ANGLE_DRAFT: (): string => `${PROJECT_DRAFT_ENDPOINT}/angledrafts`,
  GET_ANGLE_DRAFT_LIST: (): string => `${PROJECT_DRAFT_ENDPOINT}/angledrafts`,
  DELETE_ANGLE: (angleDraftId: string): string =>
    `${PROJECT_DRAFT_ENDPOINT}/angledrafts/${angleDraftId}`,
  GET_ANGLE: (angleDraftId: string): string =>
    `${PROJECT_DRAFT_ENDPOINT}/angledrafts/${angleDraftId}`,
  PUT_ANGLE: (angleDraftId: string): string =>
    `${PROJECT_DRAFT_ENDPOINT}/angledrafts/${angleDraftId}`,
  DELETE_PROJECT_DRAFT_V2: (draftId: string): string =>
    `${PROJECT_DRAFT_ENDPOINT}/v2/${draftId}`,
  LIST_PROJECT_DRAFTS_V3: (): string => `${PROJECT_DRAFT_ENDPOINT}/v3`
};

// ProjectBilling
export const PROJECT_BILLING_API = {
  getClientTotalSpend: (): string =>
    `${PROJECT_BILLING_ENDPOINT}/client/total-spend`,
  getClientSpendDistribution: (): string =>
    `${PROJECT_BILLING_ENDPOINT}/client/spend-distribution`,
  getClientProjectSpend: (): string =>
    `${PROJECT_BILLING_ENDPOINT}/client/project-spend`,
  getClientExpertSpend: (): string =>
    `${PROJECT_BILLING_ENDPOINT}/client/expert-spend`
};

// Transactions
export const TRANSACTIONS_API = {
  GET_TRANSACTIONS: (): string => `${TRANSACTIONS_ENDPOINT}`,
  CREATE_TRANSACTION: (): string => `${TRANSACTIONS_ENDPOINT}`,
  EDIT_TRANSACTION: (transactionId: string): string =>
    `${TRANSACTIONS_ENDPOINT}/${transactionId}`,
  DELETE_TRANSACTION: (transactionId: string): string =>
    `${TRANSACTIONS_ENDPOINT}/${transactionId}`,
  GET_TRANSACTION: (transactionId: string): string =>
    `${TRANSACTIONS_ENDPOINT}/${transactionId}`,
  GET_TRANSACTIONS_STATS: (): string => `${TRANSACTIONS_ENDPOINT}/stats`,
  UPDATE_TRANSACTIONS_STATUS: (): string => `${TRANSACTIONS_ENDPOINT}/status`,
  GET_TRANSACTION_PROJECTS: (): string =>
    `${TRANSACTIONS_ENDPOINT}/transaction-projects`,
  GET_TRANSACTION_BUSINESS_ENTITIES: (): string =>
    `${TRANSACTIONS_ENDPOINT}/business-entities/pending-invoices`,
  INVOICE: (): string => `${TRANSACTIONS_ENDPOINT}/invoice`,
  INVOICE_PROJECTS: (): string => `${TRANSACTIONS_ENDPOINT}/invoice-projects`,
  INVOICE_BUSINESS_ENTITIES: (): string =>
    `${TRANSACTIONS_ENDPOINT}/business-entities/invoice`,
  GET_FOLLOW_UP_CALL: (): string => `${TRANSACTIONS_ENDPOINT}/is-follow-up-call`
};

// Canopy
export const CANOPY_API = {
  GET_CANOPIES: (projectId: string): string =>
    `${CANOPY_ENDPOINT}/project/${projectId}`,
  GET_CANOPIES_V2: (): string => `${CANOPY_ENDPOINT}`,
  GET_PUBLIC_CANOPY: (canopyId: string): string =>
    `${CANOPY_ENDPOINT}/${canopyId}/public`,
  GET_CANOPY: (canopyId: string): string => `${CANOPY_ENDPOINT}/${canopyId}`,
  GET_EXPERTS_COUNT: (canopyId: string): string =>
    `${CANOPY_ENDPOINT}/${canopyId}/expert-count`,
  GET_EXPERT_CANOPY: (canopyId: string, expertId: string): string =>
    `${CANOPY_ENDPOINT}/${canopyId}/details/expert/${expertId}`,
  DELETE_CANOPY: (): string => `${CANOPY_ENDPOINT}`,
  GET_CANOPY_QUESTIONS: (canopyId: string, expertId?: string): string =>
    expertId
      ? `${CANOPY_ENDPOINT}/${canopyId}/questions/expert/${expertId}`
      : `${CANOPY_ENDPOINT}/${canopyId}/questions`,
  GET_CANOPY_QUESTIONS_V2: (canopyId: string): string =>
    `${CANOPY_ENDPOINT}/${canopyId}/questions`,
  GET_CANOPY_QUESTION_TRANSCRIPTS: (questionId: string): string =>
    `${CANOPY_ENDPOINT}/questions/${questionId}/transcripts`,
  GET_CANOPY_QUESTION_VIDEO_ANSWERS: (questionId: string): string =>
    `${CANOPY_ENDPOINT}/videoQuestions/${questionId}/answers`,
  GET_CANOPY_QUESTION_MULTI_ANSWERS: (questionId: string): string =>
    `${CANOPY_ENDPOINT}/multiChoiceQuestions/${questionId}/answersResult`,
  GET_CANOPY_QUESTION_VALUE_ANSWERS: (questionId: string): string =>
    `${CANOPY_ENDPOINT}/valueQuestion/${questionId}/answersResult`,
  GET_CANOPY_QUESTION_SHORT_TEXT_ANSWERS: (questionId: string): string =>
    `${CANOPY_ENDPOINT}/shortTextQuestion/${questionId}/answers`,
  GET_CANOPY_QUESTION_NPS_ANSWERS: (questionId: string): string =>
    `${CANOPY_ENDPOINT}/npsQuestion/${questionId}/answersResult`,
  GET_CANOPY_EXPERT_TRANSCRIPTS: (canopyId: string, expertId: string): string =>
    `${CANOPY_ENDPOINT}/${canopyId}/answers/${expertId}/transcripts`,
  GET_CANOPY_EXPERT_ANSWERS: (expertId: string, canopyId: string): string =>
    `${CANOPY_ENDPOINT}/${canopyId}/experts/${expertId}/answers`,
  CREATE_CANOPY: (): string => `${CANOPY_ENDPOINT}`,
  EDIT_CANOPY: (): string => `${CANOPY_ENDPOINT}`,
  UPLOAD_DOCUMENT_TO_CANOPY: (canopyId: string): string =>
    `${CANOPY_ENDPOINT}/${canopyId}/documents`,
  CHANGE_CANOPY_STATUS: (): string => `${CANOPY_ENDPOINT}/status`,
  REMOVE_DOCUMENT: (): string => `${CANOPY_ENDPOINT}/documents`,
  CREATE_QUESTION: (): string => `${CANOPY_ENDPOINT}/questions`,
  GET_QUESTION: (questionId: string): string =>
    `${CANOPY_ENDPOINT}/questions/${questionId}`,
  EDIT_CANOPY_QUESTION: (): string => `${CANOPY_ENDPOINT}/questions`,
  DELETE_CANOPY_QUESTION: (questionId: string): string =>
    `${CANOPY_ENDPOINT}/questions/${questionId}`,
  ASSIGN_EXPERT_TO_CANOPY: (): string => `${CANOPY_ENDPOINT}/expert`,
  UPLOAD_VIDEO_TO_CANOPY: (questionId: string): string =>
    `${CANOPY_ENDPOINT}/question/${questionId}/answer/video`,
  CANOPY_ANSWER_DATA: (expertId: string, questionId: string): string =>
    `${CANOPY_ENDPOINT}/experts/${expertId}/questions/${questionId}/answer`,
  CANOPY_ANSWER_MULTI_DATA: (questionId: string): string =>
    `${CANOPY_ENDPOINT}/question/${questionId}/answer/multiple-choice`,
  CANOPY_ANSWER_VALUE_DATA: (questionId: string): string =>
    `${CANOPY_ENDPOINT}/question/${questionId}/answer/value`,
  CANOPY_ANSWER_SHORT_TEXT_DATA: (questionId: string): string =>
    `${CANOPY_ENDPOINT}/question/${questionId}/answer/short-text`,
  CANOPY_ANSWER_NPS_DATA: (questionId: string): string =>
    `${CANOPY_ENDPOINT}/question/${questionId}/answer/nps`,
  CLONE_CANOPY: (questionId: string): string =>
    `${ADMIN_ENDPOINT}${CANOPY_ENDPOINT}/${questionId}/clone`,

  DELETE_CANOPY_ANSWER: (answerId: string): string =>
    `${CANOPY_ENDPOINT}/answers/${answerId}`,
  EDIT_CANOPY_ANSWERS: (): string => `${CANOPY_ENDPOINT}/answers/submit`,
  GET_SURVEYS: (): string => `${CANOPY_ENDPOINT}/expert/list`,
  GET_EXPERTS_IN_A_CANOPY: (canopyId: string): string =>
    `${CANOPY_ENDPOINT}/${canopyId}/experts`,
  GET_VIDEO_TRANSCRIPT: (answerId: string): string =>
    `${CANOPY_ENDPOINT}/answers/${answerId}/transcript`,
  EDIT_VIDEO_TRANSCRIPT: (answerId: string): string =>
    `${CANOPY_ENDPOINT}/answers/${answerId}/transcript`,
  RESET_VIDEO_TRANSCRIPT: (answerId: string): string =>
    `${CANOPY_ENDPOINT}/answers/${answerId}/transcript/reset`,
  GET_QUESTION_ANSWERS: (questionId: string): string =>
    `${CANOPY_ENDPOINT}/questions/${questionId}/answers`,
  GET_ALL_EXPERTS_ANSWERS: (canopyId: string, expertId: string): string =>
    `${CANOPY_ENDPOINT}/${canopyId}/experts/${expertId}/answers`,
  SET_COMPLIANCE_REVIEW_STATUS: (): string =>
    `${CANOPY_ENDPOINT}/answers/review`,
  ADD_EXPERT_CANOPY_AGREEMENT: (canopyId: string, expertId: string): string =>
    `${CANOPY_ENDPOINT}/${canopyId}/experts/${expertId}/agreement`,
  RESET_EXPERT_ANSWERS: (canopyId: string, expertId: string): string =>
    `${CANOPY_ENDPOINT}/${canopyId}/experts/${expertId}/status/reset`,
  EXPORT_ANSWERS: (canopyId: string): string =>
    `${CANOPY_ENDPOINT}/${canopyId}/answers/transcripts/export`,
  GET_EXPERT_ANSWER: (answerId: string): string =>
    `${CANOPY_ENDPOINT}/answers/${answerId}`,
  GET_MULTICHOICE_ANSWER_EXPERTS: (): string =>
    `${CANOPY_ENDPOINT}/multiChoiceQuestion/experts`,
  GET_VALUE_ANSWER_EXPERTS: (): string =>
    `${CANOPY_ENDPOINT}/valueQuestion/experts`,
  GET_NPS_ANSWER_EXPERTS: (): string =>
    `${CANOPY_ENDPOINT}/npsQuestions/experts`,
  POST_CANOPY_SUMMARY: (canopyId: string): string =>
    `${CANOPY_ENDPOINT}/${canopyId}/summary`,
  GET_CANOPY_SUMMARY: (canopyId: string): string =>
    `${CANOPY_ENDPOINT}/${canopyId}/summary`,
  GET_ANSWERS_PDF: (canopyId: string): string =>
    `${CANOPY_ENDPOINT}/${canopyId}/answers/all`,
  GET_CANOPY_AGREEMENT: (canopyId: string): string =>
    `${CANOPY_ENDPOINT}/${canopyId}/agreement`,
  REQUEST_TEMPLATE_LIST: (): string =>
    `${CANOPY_ENDPOINT}/canopy-templates?includePublic=true`
};

// Angles
export const ANGLES_API = {
  GET_ANGLES_CARDS: (): string => `${PROJECT_ENDPOINT}/angles`,
  GET_ANGLES_LINKS: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/angles/invitation-link`,
  GET_ANGLE: (angleId: string): string =>
    `${PROJECT_ENDPOINT}/angles/${angleId}`,
  GET_ANGLE_QUESTIONS: (angleId: string): string =>
    `${PROJECT_ENDPOINT}/angle/${angleId}/questions`,
  EDIT_ANGLE: (angleId: string): string =>
    `${PROJECT_ENDPOINT}/angle/${angleId}`,
  ADD_ANGLE: (): string => `${PROJECT_ENDPOINT}/angles`,
  ASSIGN_ANGLE: (angleId: string): string =>
    `${PROJECT_ENDPOINT}/angles/${angleId}/referrals`
};

// Bookmarks
export const BOOKMARKS_API = {
  LIST_BOOKMARK_COLLECTIONS: (): string => BOOKMARKS_ENDPOINT,
  POST_BOOKMARK_COLLECTION: (): string => BOOKMARKS_ENDPOINT,
  GET_BOOKMARK_COLLECTION: (id: string): string =>
    `${BOOKMARKS_ENDPOINT}/${id}`,
  PUT_BOOKMARK_COLLECTION: (id: string): string =>
    `${BOOKMARKS_ENDPOINT}/${id}`,
  DELETE_BOOKMARK_COLLECTION: (id: string): string =>
    `${BOOKMARKS_ENDPOINT}/${id}`,
  GET_BOOKMARKS_LIST: (id: string): string =>
    `${BOOKMARKS_ENDPOINT}/${id}/bookmarks`,
  EXPORT_BOOKMARK_COLLECTION: (id: string): string =>
    `${BOOKMARKS_ENDPOINT}/${id}/export`,
  POST_BOOKMARK: (): string => `${BOOKMARKS_ENDPOINT}/bookmarks`,
  DELETE_BOOKMARK: (): string => `${BOOKMARKS_ENDPOINT}/bookmarks`
};

// Transcripts
export const TRANSCRIPTS_API = {
  GET_TRANSCRIPT_DATA: (projectId: string, transcriptId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/transcriptions/${transcriptId}`,
  LIST_TRANSCRIPTS: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/transcriptions`,
  UPDATE_TRANSCRIPT_SPEAKER: (
    projectId: string,
    transcriptId: string
  ): string =>
    `${PROJECT_ENDPOINT}/${projectId}/transcriptions/${transcriptId}/speakers`,
  DELETE_TRANSCRIPT: (projectId: string, transcriptId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/transcriptions/${transcriptId}`,
  TRANSCRIPT_HIGHLIGHT: (projectId: string, transcriptId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/transcriptions/${transcriptId}/highlight`,
  REQUEST_HUMAN_TRANSCRIPT: (projectId: string, transcriptId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/transcriptions/${transcriptId}/human`,
  REGENERATE_TRANSCRIPT: (
    projectId: string,
    transcriptId: string,
    language: string
  ): string =>
    `${PROJECT_ENDPOINT}/${projectId}/transcriptions/${transcriptId}/regenerate/${language}`,
  GET_TRANSCRIPT_STATUS: (projectId: string, transcriptId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/transcriptions/${transcriptId}/status`
};

// Events
export const EVENTS_API = {
  LIST_EVENTS: (projectId: string): string =>
    `${EVENTS_ENDPOINT}/project/${projectId}`
};

// Chat
export const CHAT_API = {
  GET_CHAT_LIST: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/chats`,
  GET_CHAT_UNREAD_MESSAGES_COUNT: (projectId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/chats/unread-messages-count`,
  // Legacy endpoints
  GET_MESSAGES: (projectId: string, chatId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/workspaces/${chatId}`,
  SET_LAST_ACTIVE_CHAT: (projectId: string, chatId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/workspaces/${chatId}/activity`,
  SEND_MESSAGE: (projectId: string, chatId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/workspaces/${chatId}`,
  SEND_FILE: (projectId: string, chatId: string): string =>
    `${PROJECT_ENDPOINT}/${projectId}/workspaces/${chatId}/documents`,
  DELETE_MESSAGE: (
    projectId: string,
    chatId: string,
    messageId: string
  ): string =>
    `${PROJECT_ENDPOINT}/${projectId}/workspaces/${chatId}/message/${messageId}`
};

// WebHooks
export const WEBHOOKS_API = {
  SEND_MESSAGE: (): string => `${WEBHOOKs_ENDPOINT}/send-message`,
  SEND_SLACK_MESSAGE: (): string => `${WEBHOOKs_ENDPOINT}/send-slack-message`,
  SEND_SLACK_MESSAGE_WITH_ATTACHMENT: (): string =>
    `${WEBHOOKs_ENDPOINT}/send-slack-message-with-attachment`
};

// Feedback

export const FEEDBACK_API = {
  CANOPY_FEEDBACK_COLLECTOR: (): string => `${FEEDBACK_ENDPOINT}`,
  GET_CANOPY_FEEDBACK_QUESTION: (): string => `${FEEDBACK_ENDPOINT}/question`
};
