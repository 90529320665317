export enum UserDashboardMixpanelEventNames {
  // Canopy
  UserDashboardTopVideoInsights = "user_dashboard_top_CreateAICanopy",
  UserDashboardCanopyTopNewCanopy = "user_dashboard_canopy_top_NewCanopy",
  UserDashboardCanopyBottomNewCanopy = "user_dashboard_canopy_bottom_NewCanopy",
  UserDashboardCanopyCanopies = "user_dashboard_canopy_canopies",
  UserDashboardCanopyCardTitle = "user_dashboard_canopy_card_title",
  UserDashboardCanopyCardExperts = "user_dashboard_canopy_card_experts",
  UserDashboardCanopyCardProjectName = "user_dashboard_canopy_card_ProjectName"
}
