import React from "react";

import {
  DocIcon,
  DocumentIconProps,
  ExcelIcon,
  GenericAttachmentIcon,
  PdfIcon,
  PictureIcon,
  PptIcon,
  ZipIcon
} from "./DocumentIcons";

const fileExtensionToIcon: {
  [key: string]: (props: DocumentIconProps) => JSX.Element;
} = {
  pdf: (props) => <PdfIcon {...props} />,
  doc: (props) => <DocIcon {...props} />,
  docx: (props) => <DocIcon {...props} />,
  txt: (props) => <DocIcon {...props} />,
  xls: (props) => <ExcelIcon {...props} />,
  csv: (props) => <ExcelIcon {...props} />,
  xlsx: (props) => <ExcelIcon {...props} />,
  xlt: (props) => <ExcelIcon {...props} />,
  ppt: (props) => <PptIcon {...props} />,
  pptx: (props) => <PptIcon {...props} />,
  zip: (props) => <ZipIcon {...props} />,
  "7zip": (props) => <ZipIcon {...props} />,
  rar: (props) => <ZipIcon {...props} />,
  mng: (props) => <PictureIcon {...props} />,
  tiff: (props) => <PictureIcon {...props} />,
  ico: (props) => <PictureIcon {...props} />,
  bmp: (props) => <PictureIcon {...props} />,
  png: (props) => <PictureIcon {...props} />,
  jpeg: (props) => <PictureIcon {...props} />,
  jpg: (props) => <PictureIcon {...props} />,
  gif: (props) => <PictureIcon {...props} />,
  default: (props) => <GenericAttachmentIcon {...props} />
};

export const DocumentIcon: React.FC<
  DocumentIconProps & {
    fileExtension: string;
  }
> = ({ fileExtension, ...props }) =>
  (
    fileExtensionToIcon[fileExtension.toLowerCase()] ||
    fileExtensionToIcon.default
  )(props);
