/* eslint-disable react/no-danger */
import clsx from "clsx";
import dompurify from "dompurify";
import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";

import { ActionButton } from "../ActionButton/ActionButton";
import ImageBox from "./ImageBox/ImageBox";

import styles from "./InfoCookieBox.module.scss";

interface ButtonProps {
  display: boolean;
  buttonText: string;
  buttonAction: () => void;
}

interface InfoboxCookie {
  cookie: string;
  title: string;
  description1: string;
  description2?: string;
  icon?: string;
  buttonProps?: ButtonProps;
}

export const InfoCookieBox: React.FC<InfoboxCookie> = ({
  cookie,
  title,
  description1,
  description2,
  icon,
  buttonProps
}) => {
  const { display, buttonText, buttonAction } = buttonProps || {};
  const [toggleMessage, setToggleMessage] = useState(false);

  useEffect(() => {
    const cookies = new Cookies();
    if (!cookies.get(cookie)) {
      setToggleMessage(true);
    }
  }, [cookie]);

  const setCookie = (): void => {
    const cookies = new Cookies();
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    cookies.set(cookie, cookie, { path: "/", expires: oneYearFromNow });
  };

  const handleCloseClicked = () => {
    setCookie();
    setToggleMessage(false);
  };

  const sanitizer = dompurify.sanitize;

  return (
    <div
      data-testid="infoboxContainer"
      className={clsx(
        styles.container,
        icon && styles.iconContainer,
        toggleMessage === true ? styles.visible : styles.hidden
      )}
    >
      <div
        className={clsx(
          styles.flexContainer,
          (display || icon) && styles.flexContainerImage
        )}
      >
        <div className={styles.leftContainer}>
          {icon && (
            <div className={styles.iconLeftContainer}>
              <img className="wave-icon" src={icon} alt="wave" />
            </div>
          )}
          {display && <ImageBox />}
        </div>
        <div
          className={clsx(
            styles.textContainer,
            display && styles.textContainerImage
          )}
        >
          <div className={styles.title}>{title}</div>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: sanitizer(description1).replace(
                /href/g,
                "target='_blank' href"
              )
            }}
          />
          {description2 && (
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: sanitizer(description2).replace(
                  /href/g,
                  "target='_blank' href"
                )
              }}
            />
          )}
          {display && (
            <div style={{ marginRight: "0px", marginTop: "16px" }}>
              <ActionButton
                text={buttonText!}
                type="primary"
                handleOnClick={buttonAction!}
              />
            </div>
          )}
        </div>
        <div className={display ? styles.closeContainer : undefined}>
          <button
            className={clsx("ciq-icon ciq-close", styles.closeIcon)}
            onClick={handleCloseClicked}
            aria-label="CloseIcon"
          />
        </div>
      </div>
    </div>
  );
};
