import React from "react";
import { useSelector } from "react-redux";

import { ExpertsListPageTab } from "@arbolus-technologies/api";
import { AnglesSummary } from "@arbolus-technologies/features/angles";

import {
  PROJECT_ANGLES,
  PROJECT_EXPERTS_ROUTE
} from "../../../../../constants/navigation/projectRoutes";
import { PROJECT_TAB_ROUTES } from "../../../../../constants/navigation/projectTabRoutes";
import { ProjectSelector } from "../../../store";

const AnglesPane: React.FC = () => {
  const projectId = useSelector(ProjectSelector.projectIdSelector());
  const viewAllAnglesPageRoute = () =>
    PROJECT_TAB_ROUTES[PROJECT_ANGLES].route(projectId);

  return (
    <AnglesSummary
      viewAllAnglesPageRoute={viewAllAnglesPageRoute}
      navigateToExpertsListTab={PROJECT_EXPERTS_ROUTE(
        projectId,
        ExpertsListPageTab.AllExperts
      )}
      projectId={projectId}
      // [ARB-10399] workspaceId (chatId) removed with old chat
    />
  );
};

export default AnglesPane;
