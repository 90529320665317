export enum SurveyQuestionType {
  SingleChoiceRadio,
  MoneyTextInput,
  ScoreSelect
}

export interface SurveyQuestion {
  title: string;
  fieldName: string;
  type: SurveyQuestionType;
  options?: { label: string; value: string }[];
}
