import React from "react";
import { useTranslation } from "react-i18next";

import arbolusLogo from "../../../../assets/arbolus-logo.svg";

import styles from "./CanopySummaryInfoHeader.module.scss";

interface CanopySummaryInfoHeaderProps {
  projectName: string;
  canopyTitle: string;
  numberOfExperts: number;
  lastUpdate: string;
}

export const CanopySummaryInfoHeader: React.FC<
  CanopySummaryInfoHeaderProps
> = ({ projectName, canopyTitle, numberOfExperts, lastUpdate }) => {
  const { t } = useTranslation("canopySummary");
  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <img src={arbolusLogo} alt="arbolus-logo" />
      </div>
      <div className={styles.firstContainer}>
        <div className={styles.infoTitle}>
          <span className={styles.project}>{projectName}</span>
          <span className={styles.canopyTitle}>{canopyTitle}</span>
        </div>
        <div className={styles.dataContainer}>
          <span className={styles.totalExperts}>{`${t(
            "totalExperts"
          )} ${numberOfExperts}`}</span>
          <span className={styles.lastUpdate}>{`${t(
            "lastUpdate"
          )} ${lastUpdate}`}</span>
        </div>
      </div>
    </div>
  );
};
