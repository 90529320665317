import {
  LoggedInUser,
  UserNotificationCounts,
  UserProfile
} from "@arbolus-technologies/api";

import { CacheReducerState } from "../models/definitions";

export const initialState: CacheReducerState = {
  countries: [],
  industriesLevel0: [],
  industriesLevel2: [],
  timezones: [],
  regions: [],
  isMetaLoading: false,
  currencies: [],
  loggedInUser: {} as LoggedInUser,
  isAdmin: false,
  userProfile: {} as UserProfile,
  notificationCounts: {} as UserNotificationCounts,
  specialPermissions: []
};
