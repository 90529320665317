import React from "react";
import { useTranslation } from "react-i18next";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { BoxButton } from "../../BoxButton/BoxButton";

interface ReferExpertButtonProps {
  onClick: () => void;
}

export const ReferExpertButton: React.FC<ReferExpertButtonProps> = ({
  onClick
}) => {
  const { t } = useTranslation("referExpertButton");

  return (
    <BoxButton
      text={t("refer")}
      icon={{
        color: ARBOLUS_COLORS.bColorBaseWhite,
        background: ARBOLUS_COLORS.bColorBasePurple,
        name: "group"
      }}
      onClick={onClick}
      subTitle={t("referAColleague")}
      chevronColor={ARBOLUS_COLORS.bColorBasePurple}
    />
  );
};
