import { replace } from "connected-react-router";
import { Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, flatMap, switchMap } from "rxjs/operators";
import { isOfType } from "typesafe-actions";

import { ToasterService } from "@arbolus-technologies/api";
import {
  MixPanelActions,
  MixPanelEventNames,
  resetMixpanelOnLogout,
  trackEvent
} from "@arbolus-technologies/features/common";
import { ERROR_TYPE } from "@arbolus-technologies/utils";

import { LoginStoreActions } from "..";
import { AppConstants } from "../../../../constants";
import { REDIRECT_STATES } from "../../../../constants/app";
import { ENDORSEMENT } from "../../../../constants/navigation/authRoutes";
import { LOGIN } from "../../../../constants/navigation/publicRoutes";
import { CIQError, ErrorResponse } from "../../../../models/api";
import { FederatedLoginRequest } from "../../../../models/auth";
import { UserService } from "../../../../services";
import FirebaseService from "../../../../services/FirebaseService";
import { AppAction } from "../../../../store/actions";
import { AppState } from "../../../../store/reducers";
import { AppStoreActions } from "../../../app/store";
import {
  AUTHENTICATE_FEDERATED_USER,
  AUTHENTICATE_USER_SUCCESS,
  AuthenticateFederatedUserAction,
  LOGOUT_USER
} from "../actions/LoginActionTypes";

const notification = new ToasterService();

const authenticateFederatedUserEpic: Epic<AppAction, AppAction, AppState> = (
  action$
) =>
  action$.pipe(
    filter(isOfType(AUTHENTICATE_FEDERATED_USER)),
    switchMap((action: AuthenticateFederatedUserAction) => {
      const { state, code, user } = action.payload;
      return UserService.federatedLogin({
        code,
        state,
        user
      } as FederatedLoginRequest).pipe(
        flatMap(({ userId, userRoles, showWelcomeScreen }) => {
          localStorage.setItem(
            AppConstants.LOCALSTORAGE.LAST_LOGIN,
            new Date().getTime().toString()
          );

          const loginObservableMap: AppAction[] = [
            LoginStoreActions.authenticateUserSuccess(userId, userRoles)
          ];

          if (showWelcomeScreen) {
            loginObservableMap.push(replace(ENDORSEMENT));
          }

          return loginObservableMap;
        }),
        catchError((error: ErrorResponse<CIQError>) => {
          if (error.message) notification.showError(error.message);
          return of(
            LoginStoreActions.authenticateUserFailure(error),
            replace(LOGIN)
          );
        })
      );
    })
  );

const authenticateUserSuccessEpic: Epic<AppAction, AppAction, AppState> = (
  action$
) =>
  action$.pipe(
    filter(isOfType(AUTHENTICATE_USER_SUCCESS)),
    switchMap(() => of(AppStoreActions.getUserProfile()))
  );

const logoutUserEpic: Epic<AppAction, AppAction, AppState> = (action$, state) =>
  action$.pipe(
    filter(isOfType(LOGOUT_USER)),
    switchMap(() =>
      UserService.logoutUser().pipe(
        switchMap(() =>
          from(FirebaseService.deleteToken(state.value.auth.user.id))
        ),
        flatMap(() => {
          // clear localstorage
          localStorage.removeItem(AppConstants.LOCALSTORAGE.CULTURE);
          localStorage.removeItem(AppConstants.LOCALSTORAGE.LAST_LOGIN);

          const observableOpr: AppAction[] = [
            LoginStoreActions.logoutUserSuccess()
          ];

          // Validate domain
          const { host, protocol } = window.location;
          const baseDomain = process.env.NX_PUBLIC_BASE_URL;
          const isProduction = process.env.NODE_ENV !== "development";
          const currentDomain = `${protocol}//${host}`;
          if (isProduction) {
            if (baseDomain !== currentDomain) {
              window.location.href = baseDomain! + LOGIN;
              observableOpr.push(
                AppStoreActions.switchSubdomain(
                  REDIRECT_STATES.GUEST_SUBDOMAIN_SWITCH
                )
              );
            }
          }

          if (state.value.auth.user.id) {
            trackEvent(MixPanelEventNames.Logout, {
              action: MixPanelActions.LoggedOut
            });
            resetMixpanelOnLogout();
          }
          return observableOpr;
        }),
        catchError((error) => {
          // In case of Firebase token delete failure; continue on logout success flow
          if (error === ERROR_TYPE.FIREBASE_DELETE_TOKEN_FAILURE) {
            // clear localstorage
            localStorage.removeItem(AppConstants.LOCALSTORAGE.CULTURE);
            localStorage.removeItem(AppConstants.LOCALSTORAGE.LAST_LOGIN);

            const observableOpr: AppAction[] = [
              LoginStoreActions.logoutUserSuccess()
            ];

            // Validate domain
            const { host, protocol } = window.location;
            const baseDomain = process.env.NX_PUBLIC_BASE_URL;
            const isProduction = process.env.NODE_ENV !== "development";
            const currentDomain = `${protocol}//${host}`;
            if (isProduction) {
              if (baseDomain !== currentDomain) {
                window.location.href = baseDomain! + LOGIN;
                observableOpr.push(
                  AppStoreActions.switchSubdomain(
                    REDIRECT_STATES.GUEST_SUBDOMAIN_SWITCH
                  )
                );
              }
            }

            trackEvent(MixPanelEventNames.Logout, {
              action: MixPanelActions.LoggedOut
            });
            resetMixpanelOnLogout();

            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            return of(...observableOpr);
          }

          trackEvent(MixPanelEventNames.Logout, {
            action: MixPanelActions.LogoutFailed
          });

          return of(LoginStoreActions.logoutUserFailed());
        })
      )
    )
  );

const loginEpics = [
  authenticateFederatedUserEpic,
  logoutUserEpic,
  authenticateUserSuccessEpic
];

export default loginEpics;
