import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";

import { ProjectAnglesStoreActions } from "@arbolus-technologies/stores/project-angles-store";

import styles from "./AngleScreeningQuestionsDisabled.module.scss";

interface AngleScreeningQuestionsDisabledProps {
  isAdmin?: boolean;
}

export const AngleScreeningQuestionsDisabled: React.FC<
  AngleScreeningQuestionsDisabledProps
> = ({ isAdmin }) => {
  const { t } = useTranslation("viewEditAngle");
  const dispatch = useDispatch();

  const handleAddQuestion = () => {
    dispatch(ProjectAnglesStoreActions.setFormCreateEditAngleEnabled());
    dispatch(ProjectAnglesStoreActions.addScreeningQuestionsView());
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t("screeningQuestionsDisabled")}</h1>
      {isAdmin && (
        <Button
          color="secondary"
          className={styles.addScreeningQuestion}
          onClick={handleAddQuestion}
        >
          {t("addScreeningQuestion")}
        </Button>
      )}
    </div>
  );
};
