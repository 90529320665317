import clsx from "clsx";
import React from "react";
import { Button } from "reactstrap";

interface EmptyResultContainerProps {
  title: string;
  itemType?: string;
  isCard?: boolean;
  buttonText?: string;
  onButtonClick?: () => void;
  description?: string;
  roleDescription?: Map<string, string>;
  buttonVisibleRoles?: string[];
}

interface SimpleEmptyResultProps {
  message?: string;
}

export const EmptyResultContainer: React.FC<EmptyResultContainerProps> = ({
  title,
  description,
  roleDescription,
  itemType,
  isCard,
  buttonText,
  onButtonClick,
  buttonVisibleRoles
}) => (
  <div className="no-result-container">
    <div
      className={clsx({
        "card-container": isCard,
        "standard-container": !isCard
      })}
    >
      <div className="empty-icon">
        <div className={`empty-state ${itemType}`} />
      </div>

      <p className="message">{title}</p>
      {description && (
        <p className="description">
          {description.split("\n").map((t, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              {t}
              <br />
            </React.Fragment>
          ))}
        </p>
      )}
      {roleDescription && <p className="description">{""}</p>}
    </div>
    {buttonText && !buttonVisibleRoles && (
      <Button
        size="md"
        color="primary"
        className="btn-bold"
        onClick={onButtonClick}
      >
        {buttonText}
      </Button>
    )}
  </div>
);

export const SimpleEmptyResult: React.FC<SimpleEmptyResultProps> = ({
  message = "No Result"
}) => (
  <div className="no-result-container">
    <p className="description">{message}</p>
  </div>
);
