import { GridReadyEvent } from "ag-grid-community";
import React from "react";
import { useParams } from "react-router";

import { SurveyParamUrlType } from "@arbolus-technologies/models/survey";
import { ArbolusGrid } from "@arbolus-technologies/ui/components";

import { SurveyExpertTableDataSource } from "./SurveyExpertTableDataSource";
import { COLUMN_DEFINITIONS, DEFAULT_COLUMN_DEFINITION } from "./constants";

export function SurveyExpertTable(): React.ReactElement {
  const { surveyId } = useParams<SurveyParamUrlType>();

  const onGridReady = (event: GridReadyEvent) => {
    const { api } = event;
    api.setGridOption(
      "serverSideDatasource",
      new SurveyExpertTableDataSource(surveyId)
    );
    api.sizeColumnsToFit();
  };

  return (
    <ArbolusGrid
      rowModelType="serverSide"
      columnDefs={COLUMN_DEFINITIONS}
      defaultColDef={DEFAULT_COLUMN_DEFINITION}
      onGridReady={onGridReady}
      getRowId={({ data }) => data.id}
      suppressRowClickSelection
      suppressMenuHide
      tooltipShowDelay={500}
      skipHeaderOnAutoSize
      pagination
      paginationAutoPageSize
    />
  );
}
