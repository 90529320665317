import clsx from "clsx";
import React from "react";
import { isMobile } from "react-device-detect";

import { ProjectExpertManagementWrapper } from "@arbolus-technologies/features/common";
import { ScheduleProjectData } from "@arbolus-technologies/models/project";

import { ExpertsListPageBody } from "./ExpertsListPageBody/ExpertsListPageBody";
import { ExportExpertsButton } from "./ExportExpertsButton/ExportExpertsButton";

import styles from "./ExpertListPage.module.scss";

interface ExpertListPageProps {
  projectData: ScheduleProjectData;
  handleClickRow: (
    expertId: string,
    referralId: string,
    openAvailabilityTab?: boolean
  ) => void;
  expertProfilePath?: (expertId: string) => string;
  handleSlidePanelExpertProfile?: (
    projectId: string,
    expertId: string,
    referralId: string
  ) => void;
  handleEditTagline?: (
    referralId: string,
    projectId: string,
    tagline: string,
    expertTitle: string
  ) => void;
  handleReviewNow?: () => void;
  handleSlidePanelAvailability?: (
    projectId: string,
    expertId: string,
    referralId: string
  ) => void;
}

export const ExpertListPage: React.FC<ExpertListPageProps> = ({
  projectData,
  handleClickRow,
  expertProfilePath,
  handleSlidePanelExpertProfile,
  handleEditTagline,
  handleReviewNow,
  handleSlidePanelAvailability
}) => (
  <ProjectExpertManagementWrapper stickyHeader>
    <div
      className={clsx(styles.container, { [styles.mobileContainer]: isMobile })}
    >
      <div className={styles.exportButton}>
        <ExportExpertsButton projectId={projectData.id} />
      </div>
      <ExpertsListPageBody
        project={projectData}
        handleClickRow={handleClickRow}
        expertProfilePath={expertProfilePath}
        handleSlidePanelExpertProfile={handleSlidePanelExpertProfile}
        handleSlidePanelAvailability={handleSlidePanelAvailability}
        handleEditTagline={handleEditTagline}
        onReviewNow={handleReviewNow}
      />
    </div>
  </ProjectExpertManagementWrapper>
);
