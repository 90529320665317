import React from "react";
import { useTranslation } from "react-i18next";

import { ExpertRate } from "@arbolus-technologies/models/common";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { IconWithText } from "@arbolus-technologies/ui/components";

import { RateAmount } from "./RateAmount/RateAmount";
import { RateInformation } from "./RateInformation/RateInformation";

import styles from "./ExpertRates.module.scss";

const baseIcon = {
  color: ARBOLUS_COLORS.bColorBasePurple,
  filled: false,
  size: "16px"
};

const infoIcon = {
  name: "info",
  ...baseIcon
};

const timerIcon = {
  name: "timer",
  ...baseIcon
};

interface ExpertRatesProps {
  expertRate: ExpertRate;
}

export const ExpertRates: React.FC<ExpertRatesProps> = ({ expertRate }) => {
  const { t } = useTranslation("expertRates");
  const { pricing, isInClientNetwork } = expertRate;

  return pricing ? (
    <div className={styles.container}>
      <div className={styles.callPriceRow}>
        <h3 className={styles.callPrice}>{t("callPrice")}</h3>
        <RateAmount pricing={pricing} />
      </div>
      <div className={styles.informationRow}>
        <RateInformation expertRate={expertRate} />
        {isInClientNetwork && (
          <IconWithText
            icon={infoIcon}
            text={{ content: t("creditRateInNetwork_true") }}
          />
        )}
        {pricing.hasMinimumCallTimeRate && (
          <IconWithText icon={timerIcon} text={{ content: t("oneHourMin") }} />
        )}
      </div>
      {pricing.rate && (
        <span className={styles.fluctuations}>{t("currencyFluctuations")}</span>
      )}
    </div>
  ) : null;
};
