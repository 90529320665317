import clsx from "clsx";
import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import SlidingPane from "react-sliding-pane";

import {
  PanelId,
  PanelSelector,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import "react-sliding-pane/dist/react-sliding-pane.css";
import styles from "./SlidePanel.module.scss";

interface SlidingPanelProps {
  children: React.ReactNode;
  width?: SIDE_PANEL_SIZE;
  from?: "left" | "right" | "bottom";
  closeComponent?: JSX.Element;
  panelId: PanelId;
  customCloseRequest?: () => void;
  title?: JSX.Element | string;
  closeButtonDirection?: "left" | "right";
  hideHeader?: boolean;
  isMapped?: boolean;
  isOpenInternally?: boolean;
  noPadding?: boolean;
  visibleScroll?: boolean;
  classnames?: { overlay?: string };
  skipClose?: boolean;
}

export const SlidePanel: React.FC<SlidingPanelProps> = ({
  children,
  width = SIDE_PANEL_SIZE._720,
  from = "right",
  closeComponent,
  panelId,
  customCloseRequest,
  title,
  closeButtonDirection = "left",
  hideHeader,
  isMapped,
  isOpenInternally,
  noPadding = false,
  visibleScroll = false,
  classnames,
  skipClose = false
}) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(PanelSelector.isPanelOpen(panelId));

  /**
   * If the slidePanel needs to receive props from a map, we'll use isMapped prop and we've to
   * pass the state by props. Also, to close the slidePanel we'll have to use customCloseRequest.
   * you can check an example on AnglesCards.tsx
   */

  useEffect(() => {
    dispatch(PanelStoreActions.closePanel(panelId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return (
    <SlidingPane
      title={title}
      hideHeader={hideHeader}
      className={clsx(
        styles.container,
        noPadding && styles.noPadding,
        visibleScroll && styles.visibleScroll,
        closeButtonDirection === "right"
          ? styles.closeButtonRight
          : styles.closeButtonLeft
      )}
      closeIcon={
        closeComponent ?? (
          <div className={clsx(styles.closeContainer)}>
            <span className="ciq-icon ciq-close" data-testid="close-icon" />
          </div>
        )
      }
      overlayClassName={clsx(styles.overlay, classnames?.overlay)}
      isOpen={isMapped ? isOpenInternally : isOpen}
      from={from}
      width={isMobile ? "100%" : width}
      onRequestClose={() => {
        !skipClose && dispatch(PanelStoreActions.closePanel(panelId));
        customCloseRequest?.();
      }}
    >
      {children}
    </SlidingPane>
  );
};
