import i18next from "i18next";
import { Epic } from "redux-observable";
import { of } from "rxjs";
import { catchError, filter, switchMap } from "rxjs/operators";
import { isOfType } from "typesafe-actions";

import {
  CIQError,
  ErrorResponse,
  ExpertsListPageTab,
  ProjectService,
  StatusExpertTable
} from "@arbolus-technologies/api";
import { EXPERT_STATUS } from "@arbolus-technologies/models/expert";

import {
  ASSIGN_ANGLE_SHORTLIST_TO_CANDIDATE,
  EDIT_TAGLINE,
  GET_ALL_EXPERTS,
  GET_ANGLE_EXPERT,
  GET_PROJECT_RECOMMENDATIONS,
  GET_PROJECT_SUMMARY,
  UPDATE_REFERRAL_ANGLE,
  UPDATE_REFERRAL_STATUS
} from "../actions/ProjectExpertsActionTypes";
import { ProjectExpertsStoreActions } from "../actions/ProjectExpertsActions";
import {
  ProjectExpertsAppState,
  ProjectExpertsStoreAction,
  ProjectExpertsStoreDependencies
} from "../definitions";

const getAllExpertsList: Epic<
  ProjectExpertsStoreAction,
  ProjectExpertsStoreAction,
  ProjectExpertsAppState,
  ProjectExpertsStoreDependencies
> = (action$, _, { projectExpertsService, notificationService }) =>
  action$.pipe(
    filter(isOfType(GET_ALL_EXPERTS)),
    switchMap(({ payload: { projectId, activeTab } }) =>
      projectExpertsService
        .getAllExpertsList(
          projectId,
          activeTab === ExpertsListPageTab.AllExperts ? null : activeTab
        )
        .pipe(
          switchMap((response) =>
            of(ProjectExpertsStoreActions.getAllExpertsSuccess(response.items))
          ),
          catchError(() => {
            notificationService.showError(
              i18next.t("restService:somethingWrong")
            );
            return of(ProjectExpertsStoreActions.getAllExpertsFailure());
          })
        )
    )
  );

const getRecommendations: Epic<
  ProjectExpertsStoreAction,
  ProjectExpertsStoreAction,
  ProjectExpertsAppState,
  ProjectExpertsStoreDependencies
> = (action$, _, { projectExpertsService, notificationService }) =>
  action$.pipe(
    filter(isOfType(GET_PROJECT_RECOMMENDATIONS)),
    switchMap(({ payload: { projectId } }) =>
      projectExpertsService.getRecommendations(projectId).pipe(
        switchMap((response) =>
          of(
            ProjectExpertsStoreActions.getRecommendationsSuccess(response.items)
          )
        ),
        catchError(() => {
          notificationService.showError(
            i18next.t("restService:somethingWrong")
          );
          return of(ProjectExpertsStoreActions.getRecommendationsFailure());
        })
      )
    )
  );

const getExpert: Epic<
  ProjectExpertsStoreAction,
  ProjectExpertsStoreAction,
  ProjectExpertsAppState,
  ProjectExpertsStoreDependencies
> = (action$, _, { projectExpertsService, notificationService }) =>
  action$.pipe(
    filter(isOfType(GET_ANGLE_EXPERT)),
    switchMap(({ payload: { expertId } }) =>
      projectExpertsService.getExpert(expertId).pipe(
        switchMap((expert) =>
          of(ProjectExpertsStoreActions.getAngleExpertSuccess(expert))
        ),
        catchError((error) => {
          notificationService.showError(error.message);
          return of(ProjectExpertsStoreActions.getAngleExpertFailure());
        })
      )
    )
  );

const updateReferralStatus: Epic<
  ProjectExpertsStoreAction,
  ProjectExpertsStoreAction,
  ProjectExpertsAppState,
  ProjectExpertsStoreDependencies
> = (action$, state$, { notificationService }) =>
  action$.pipe(
    filter(isOfType(UPDATE_REFERRAL_STATUS)),
    switchMap(
      ({ payload: { projectId, referralId, status, successMessage } }) =>
        ProjectService.updateReferralState(projectId, referralId, status).pipe(
          switchMap(() => {
            const message: string =
              successMessage ||
              i18next.t(
                status.review === EXPERT_STATUS.ACCEPT
                  ? "expertsList:expertApproved"
                  : "expertsList:status"
              );

            notificationService.showSuccess(message);

            const observables: ProjectExpertsStoreAction[] = [
              ProjectExpertsStoreActions.updateReferralStatusSuccess(
                referralId,
                status.review as StatusExpertTable
              ),
              ProjectExpertsStoreActions.getProjectSummary(projectId),
              ProjectExpertsStoreActions.refetchReferralSummary(projectId)
            ];

            if (status.fastTrack) {
              observables.push(
                ProjectExpertsStoreActions.updateAllExpertsFastTrackStatus(
                  referralId
                )
              );
            } else if (
              !status.reset ||
              state$.value.projectExperts.tabActive ===
                ExpertsListPageTab.Reject
            ) {
              observables.push(
                ProjectExpertsStoreActions.getAllExperts(
                  projectId,
                  state$.value.projectExperts.tabActive!
                )
              );
            }

            return of(...observables);
          }),
          catchError((error) => {
            notificationService.showError(error.message);
            return of(ProjectExpertsStoreActions.updateReferralStatusFailure());
          })
        )
    )
  );

const assignAngleAndShortlistToCandidate: Epic<
  ProjectExpertsStoreAction,
  ProjectExpertsStoreAction,
  ProjectExpertsAppState,
  ProjectExpertsStoreDependencies
> = (
  action$,
  state$,
  { projectExpertsService, projectService, notificationService }
) =>
  action$.pipe(
    filter(isOfType(ASSIGN_ANGLE_SHORTLIST_TO_CANDIDATE)),
    switchMap(({ payload: { referralId, angleId, projectId } }) =>
      projectExpertsService.updateReferralAngle(referralId, angleId).pipe(
        switchMap((_) =>
          projectService.updateReferralState(projectId, referralId, {
            status: "Candidate"
          })
        ),
        switchMap((_) => {
          notificationService.showSuccess(
            i18next.t("expertsList:angleAssigned")
          );
          return of(
            ProjectExpertsStoreActions.assignAngleShortlistToCandidateSuccess(),
            ProjectExpertsStoreActions.forceClosePanel(),
            ProjectExpertsStoreActions.getAllExperts(
              projectId,
              state$.value.projectExperts.tabActive!
            ),
            ProjectExpertsStoreActions.getProjectSummary(projectId)
          );
        }),
        catchError((_) => {
          notificationService.showError(
            i18next.t("restService:somethingWrong")
          );
          return of(
            ProjectExpertsStoreActions.assignAngleShortlistToCandidateFailure()
          );
        })
      )
    )
  );

const getSummaryList: Epic<
  ProjectExpertsStoreAction,
  ProjectExpertsStoreAction,
  ProjectExpertsAppState,
  ProjectExpertsStoreDependencies
> = (action$, _, { projectService }) =>
  action$.pipe(
    filter(isOfType(GET_PROJECT_SUMMARY)),
    switchMap(({ payload: { projectId, angleId } }) =>
      projectService.getReferralSummary(projectId, angleId).pipe(
        switchMap((response) =>
          of(ProjectExpertsStoreActions.getProjectSummarySuccess(response))
        ),
        catchError((error) =>
          of(ProjectExpertsStoreActions.getProjectSummaryFailure())
        )
      )
    )
  );

const editTagline: Epic<
  ProjectExpertsStoreAction,
  ProjectExpertsStoreAction,
  ProjectExpertsAppState,
  ProjectExpertsStoreDependencies
> = (action$, _, { notificationService, projectService }) =>
  action$.pipe(
    filter(isOfType(EDIT_TAGLINE)),
    switchMap(({ payload: { taglineRequestObject, closePanel, activeTab } }) =>
      projectService.editTagline(taglineRequestObject).pipe(
        switchMap(() => {
          notificationService.showSuccess(
            i18next.t("taglinePanel:editSuccess")
          );
          closePanel();
          return of(
            ProjectExpertsStoreActions.editTaglineSuccess(),
            ProjectExpertsStoreActions.getAllExperts(
              taglineRequestObject.projectId,
              activeTab
            )
          );
        }),
        catchError((error: ErrorResponse<CIQError>) => {
          notificationService.showError(error.message);
          closePanel();
          return of(ProjectExpertsStoreActions.editTaglineFailure(error));
        })
      )
    )
  );

const updateReferralAngle: Epic<
  ProjectExpertsStoreAction,
  ProjectExpertsStoreAction,
  ProjectExpertsAppState,
  ProjectExpertsStoreDependencies
> = (action$, state$, { projectExpertsService, notificationService }) =>
  action$.pipe(
    filter(isOfType(UPDATE_REFERRAL_ANGLE)),
    switchMap(({ payload: { referralId, angleId, projectId } }) =>
      projectExpertsService.updateReferralAngle(referralId, angleId).pipe(
        switchMap((_) => {
          notificationService.showSuccess(
            i18next.t("expertsList:angleUpdated")
          );
          return of(
            ProjectExpertsStoreActions.updateReferralAngleSuccess(),
            ProjectExpertsStoreActions.forceClosePanel(),
            ProjectExpertsStoreActions.getAllExperts(
              projectId,
              state$.value.projectExperts.tabActive!
            )
          );
        }),
        catchError((_) => {
          notificationService.showError(
            i18next.t("restService:somethingWrong")
          );
          return of(ProjectExpertsStoreActions.updateReferralAngleFailure());
        })
      )
    )
  );

export const ProjectExpertsEpics = [
  getAllExpertsList,
  getRecommendations,
  getExpert,
  updateReferralStatus,
  assignAngleAndShortlistToCandidate,
  getSummaryList,
  editTagline,
  updateReferralAngle
];
