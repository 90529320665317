import { BookmarkCollection } from "@arbolus-technologies/models/common";

import { RenameCollectionAction } from "../actions/RenameCollection/RenameCollectionActionTypes";
import { BookmarkCollectionsReducerState } from "../models/definitions";
import { initialState } from "./InitialState";

export const handleRenameCollection = (
  state = initialState,
  { payload: { id, name } }: RenameCollectionAction
): BookmarkCollectionsReducerState => {
  if (!state.collections.store) {
    return state;
  }

  const oldCollection = state.collections.store.items.find((c) => c.id === id);
  if (!oldCollection) {
    return state;
  }

  const index = state.collections.store.items.indexOf(oldCollection);

  const renamedCollection = BookmarkCollection.create({
    ...oldCollection.toModel(),
    name
  });

  const items = [...state.collections.store.items];
  items.splice(index, 1, renamedCollection);

  return {
    ...state,
    collections: {
      ...state.collections,
      store: {
        ...state.collections.store,
        items
      }
    }
  };
};
