import clsx from "clsx";
import React from "react";

import styles from "./CustomSelectOption.module.scss";

interface CustomSelectOptionProps {
  text: string;
  icon: string;
  color?: string;
  backgroundColor?: string;
}
export const CustomSelectOption: React.FC<CustomSelectOptionProps> = ({
  text,
  icon,
  color,
  backgroundColor
}) => (
  <div className={styles.typeOption}>
    <div
      className={styles.iconContainer}
      style={{ color: color, backgroundColor: backgroundColor }}
    >
      <span className={clsx("material-symbols-sharp", styles.icon)}>
        {icon}
      </span>
    </div>
    <p className={styles.text}>{text}</p>
  </div>
);
