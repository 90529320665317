import { Observable } from "rxjs";

import { restService } from "@arbolus-technologies/api";

import { ENDORSEMENTS_API } from "../constants/api";
import { ApiSuccessResponse } from "../models/api";
import { EndorseExpertRequest } from "../models/endorsements";

export const endorsementService = {
  endorseExpert: (
    endorsement: EndorseExpertRequest
  ): Observable<ApiSuccessResponse> =>
    restService.post(ENDORSEMENTS_API.ADD_EXPERT_ENDORSEMENT(), endorsement)
};
