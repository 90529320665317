import { Button } from "antd";
import clsx from "clsx";
import { Formik, FormikProps } from "formik";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Media from "react-media";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupAddon,
  Row
} from "reactstrap";
import { createStructuredSelector } from "reselect";
import { Subscription } from "rxjs";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import {
  FilterCriteria,
  SortCriteria,
  ToasterService
} from "@arbolus-technologies/api";
import {
  MixpanelPages,
  trackPageView
} from "@arbolus-technologies/features/common";
import { Loader } from "@arbolus-technologies/ui/components";
import { AntDHeader } from "@arbolus-technologies/ui/layout";

import { MAX_PAGE_SIZE } from "../../../../constants/api";
import { LISTING_TYPES } from "../../../../constants/app";
import {
  EXPERT_FILTER_CRITERIA,
  EXPERT_SORT_CRITERIA
} from "../../../../constants/expert";
import { EXPERT_INVITE_PANEL_ROUTE } from "../../../../constants/navigation/panelRoutes";
import { SEARCH_DEBOUNCE_TIMEOUT } from "../../../../constants/timer";
import {
  APP_DEVICE_MEDIA_QUERIES,
  UI_EXPERT_NETWORK_PAGE
} from "../../../../constants/ui";
import { MINIMUM_SEARCH_TERM_LENGTH } from "../../../../constants/validation";
import { ContentPanelContextConsumer } from "../../../../contexts/contentPanel/ContentPanelContext";
import { CIQError, ErrorResponse } from "../../../../models/api";
import { SavedExpert } from "../../../../models/expert";
import { Country } from "../../../../models/meta";
import { ClientService } from "../../../../services";
import { AppState } from "../../../../store/reducers";
import {
  CIQEmptyPlaceholder,
  CIQInfiniteScroll
} from "../../../app/components";
import { AppSelector } from "../../../app/store";
import { AuthSelector } from "../../../auth/store";
import ExpertNetworkCard from "../../components/networkCard/ExpertNetworkCard";

const notification = new ToasterService();

interface ExpertNetworkStorePageProps {
  clientId?: string;
  countriesMap: Map<string, Country>;
  invitationURL?: string;
}

interface ExpertNetworkPageProps
  extends ExpertNetworkStorePageProps,
    WithTranslation,
    RouteComponentProps {}

interface NetworkFilterFormValues {
  expertOption: FilterCriteria;
  sortCriteria: SortCriteria;
  searchQuery: string;
}

interface ExpertNetworkPageState {
  experts: SavedExpert[];
  isLoading: boolean;
  isMore: boolean;
  currentPage: number;
  isFilterOptionsOpen: boolean;
  isSortOptionsOpen: boolean;
}

class ExpertNetworkPage extends React.PureComponent<
  ExpertNetworkPageProps,
  ExpertNetworkPageState
> {
  constructor(props: ExpertNetworkPageProps) {
    super(props);
    this.state = {
      currentPage: 0,
      experts: [],
      isLoading: false,
      isMore: false,
      isFilterOptionsOpen: false,
      isSortOptionsOpen: false
    };
  }

  componentDidMount(): void {
    const { t } = this.props;
    document.title = t("expertsNetwork");

    trackPageView({ page: MixpanelPages.Network });
  }

  componentWillUnmount(): void {
    this.getExpertNetworkSubscription?.unsubscribe();
    if (this.searchTermDebounceTimeout)
      clearTimeout(this.searchTermDebounceTimeout);
  }

  private getExpertNetworkSubscription?: Subscription;

  private initialElementCount?: number;

  private formInstance?: FormikProps<NetworkFilterFormValues> | null = null;

  private searchTermDebounceTimeout?: number;

  fetchExpertNetwork = (): void => {
    if (this.formInstance) {
      const { clientId } = this.props;
      const { experts, currentPage } = this.state;
      const nextOffset = experts.length;
      const { values } = this.formInstance;
      const { searchQuery, sortCriteria, expertOption } = values;

      const limit =
        currentPage === 0 ? this.initialElementCount : MAX_PAGE_SIZE;

      this.setState({
        isLoading: true
      });

      this.getExpertNetworkSubscription = ClientService.getSavedExperts(
        clientId!,
        searchQuery,
        expertOption.value,
        nextOffset,
        limit,
        sortCriteria.value,
        sortCriteria.direction
      ).subscribe(
        ({ items, pagination }) => {
          const nextExperts = experts.concat(items);
          const nextExpertCount = nextExperts.length;
          this.setState({
            experts: nextExperts,
            isLoading: false,
            currentPage: currentPage + 1,
            isMore: nextExpertCount < pagination.count
          });
        },
        (error: ErrorResponse<CIQError>) => {
          notification.showError(error.message);
          this.setState({
            isLoading: false
          });
        }
      );
    }
  };

  handleFilterTypeToggle = (): void => {
    const { isFilterOptionsOpen } = this.state;
    this.setState({
      isFilterOptionsOpen: !isFilterOptionsOpen
    });
  };

  handleSortTypeToggle = (): void => {
    const { isSortOptionsOpen } = this.state;

    this.setState({
      isSortOptionsOpen: !isSortOptionsOpen
    });
  };

  handleInitialFetch = (elementCount?: number): void => {
    this.initialElementCount = elementCount! * 4;
    this.fetchExpertNetwork();
  };

  handleProjectsBottomReached = (): void => {
    const { isLoading, isMore } = this.state;
    if (isMore && !isLoading) {
      this.fetchExpertNetwork();
    }
  };

  handleFormStateChange = (): void => {
    this.getExpertNetworkSubscription?.unsubscribe();
    this.setState(
      {
        experts: [],
        currentPage: 0
      },
      () => {
        this.fetchExpertNetwork();
      }
    );
  };

  handleInviteClicked = (): void => {
    const { history } = this.props;
    history.push(EXPERT_INVITE_PANEL_ROUTE);
  };

  renderFilterForm = ({
    values,
    setFieldValue
  }: FormikProps<NetworkFilterFormValues>): JSX.Element => {
    const { isFilterOptionsOpen, isSortOptionsOpen } = this.state;
    const { t } = this.props;

    const handleFilterTypeChange = (filterCriteria: FilterCriteria): void => {
      setFieldValue("expertOption", filterCriteria);
    };

    const handleSortTypeChange = (sortCriteria: SortCriteria): void => {
      setFieldValue("sortCriteria", sortCriteria);
    };

    const handleQueryChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ): void => {
      const searchQuery = event.target.value;
      if (
        searchQuery === "" ||
        searchQuery.length >= MINIMUM_SEARCH_TERM_LENGTH
      ) {
        const nextQuery = event.target.value;
        if (this.searchTermDebounceTimeout)
          clearTimeout(this.searchTermDebounceTimeout);

        this.searchTermDebounceTimeout = setTimeout(() => {
          setFieldValue("searchQuery", nextQuery);
        }, SEARCH_DEBOUNCE_TIMEOUT);
      }
    };

    return (
      <div className="search-filter-bar">
        <Row>
          <Col className="search-column">
            <InputGroup className="search-input-grey">
              <InputGroupAddon addonType="prepend">
                <span className="ciq-icon ciq-search" />
              </InputGroupAddon>
              <Input
                type="search"
                autoComplete="off"
                placeholder={t("searchExperts")}
                name="searchQuery"
                onChange={handleQueryChange}
              />
            </InputGroup>
          </Col>
          <Col className="filter-column">
            <Dropdown
              isOpen={isFilterOptionsOpen}
              toggle={this.handleFilterTypeToggle}
              className="d-none d-sm-flex"
            >
              <DropdownToggle caret>
                {t("show")}
                {values.expertOption.name}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={(): void => {
                    handleFilterTypeChange(EXPERT_FILTER_CRITERIA.ALL);
                  }}
                  value={EXPERT_FILTER_CRITERIA.ALL}
                >
                  {t("all")}
                </DropdownItem>
                <DropdownItem
                  onClick={(): void => {
                    handleFilterTypeChange(EXPERT_FILTER_CRITERIA.WORKED);
                  }}
                  value={EXPERT_FILTER_CRITERIA.WORKED}
                >
                  {t("worked")}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>

            <Dropdown
              isOpen={isSortOptionsOpen}
              toggle={this.handleSortTypeToggle}
              className={clsx("d-none d-sm-flex", {
                disabled: false
              })}
            >
              <DropdownToggle caret>
                {t("expertNetwork:sort")}
                {values.sortCriteria.name}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={(): void => {
                    handleSortTypeChange(EXPERT_SORT_CRITERIA.NAME);
                  }}
                >
                  {t("name")}
                </DropdownItem>
                <DropdownItem
                  onClick={(): void => {
                    handleSortTypeChange(EXPERT_SORT_CRITERIA.DATE);
                  }}
                >
                  {t("date")}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Col>
        </Row>
      </div>
    );
  };

  renderExpertsNetwork = (): JSX.Element => {
    const { isLoading, experts } = this.state;
    const { countriesMap, t } = this.props;

    let title = t("emptyTitleDefault");
    let description = t("emptyDescriptionDefault");

    if (this.formInstance) {
      const { values } = this.formInstance;
      const { searchQuery } = values;
      if (searchQuery.length) {
        title = t("emptyTitleSecondary");
        description = t("emptyDescriptionSecondary");
      }
    }

    return (
      <>
        <ContentPanelContextConsumer>
          {(contentPanelProps): JSX.Element => (
            <Row>
              {experts.map((e) => {
                const country = countriesMap.get(e.isoCountryCode || "");

                return (
                  <ExpertNetworkCard key={e.id} expert={e} country={country} />
                );
              })}
            </Row>
          )}
        </ContentPanelContextConsumer>

        {isLoading && <Loader />}
        {!isLoading && !experts.length && (
          <CIQEmptyPlaceholder
            itemType={LISTING_TYPES.USERS}
            title={title}
            description={description}
          />
        )}
      </>
    );
  };

  render(): JSX.Element {
    const { t } = this.props;

    return (
      <div className="page-wrapper">
        <div className="expert-network-container page-content">
          <AntDHeader
            title={t("network")}
            rightContainer={
              <Button
                type="primary"
                onClick={(): void => this.handleInviteClicked()}
                icon={<AntDIcon name="add" />}
              >
                Invite
              </Button>
            }
          />
          <div className="expert-network-body">
            <Formik<NetworkFilterFormValues>
              initialValues={{
                expertOption: EXPERT_FILTER_CRITERIA.ALL,
                searchQuery: "",
                sortCriteria: EXPERT_SORT_CRITERIA.DATE
              }}
              innerRef={(ref): void => {
                this.formInstance = ref;
              }}
              validateOnChange
              validate={this.handleFormStateChange}
              onSubmit={(): void => {}}
            >
              {this.renderFilterForm}
            </Formik>

            <Media queries={APP_DEVICE_MEDIA_QUERIES}>
              {(matches): JSX.Element => (
                <CIQInfiniteScroll
                  onBottomReached={this.handleProjectsBottomReached}
                  onInitialFetch={this.handleInitialFetch}
                  className="experts-container simplebar-light"
                  bottomThreshold={40}
                  elementHeight={UI_EXPERT_NETWORK_PAGE.EXPERT_ROW_ELEMENT_HEIGHT(
                    matches
                  )}
                  style={{
                    maxHeight: UI_EXPERT_NETWORK_PAGE.CONTAINER_HEIGHT(matches),
                    height: UI_EXPERT_NETWORK_PAGE.CONTAINER_HEIGHT(matches),
                    overflowX: "hidden"
                  }}
                >
                  {this.renderExpertsNetwork()}
                </CIQInfiniteScroll>
              )}
            </Media>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<
  AppState,
  ExpertNetworkPageProps,
  ExpertNetworkStorePageProps
>({
  clientId: AuthSelector.authClientIdSelector(),
  countriesMap: AppSelector.appCountriesMapSelector(),
  invitationURL: AuthSelector.authClientNetworkInvitationURLSelector()
});

export default withTranslation("expertNetwork")(
  connect(mapStateToProps)(ExpertNetworkPage)
);
