import { Icon } from "arbolus-ui-components";
import { Field, Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FormGroup, InputGroup, Label } from "reactstrap";
import { Dispatch } from "redux";
import { Subscription, zip } from "rxjs";

import { ToasterService } from "@arbolus-technologies/api";
import {
  AuthPageBase,
  AuthPageHeader,
  MixPanelEventNames,
  MixpanelPages,
  addEventInitialization,
  trackEvent,
  trackPageView
} from "@arbolus-technologies/features/common";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import {
  AppConstants,
  AuthConstants,
  PublicRouteConstants
} from "../../../../constants";
import { FEDERATED_AUTH_PROVIDER } from "../../../../constants/auth";
import { CIQError, ErrorResponse } from "../../../../models/api";
import { FederatedLoginUrlRequest } from "../../../../models/auth";
import { MetaService, UserService } from "../../../../services";
import { AppAction } from "../../../../store/actions";
import { CIQFormInput } from "../../../app/components";
import CustomPasswordInput from "../../../app/components/CustomPasswordInput";
import { Alert, AsyncAwareSubmitButton } from "../../components";
import { LoginStoreActions } from "../../store";
import AutoLogin from "./AutoLogin";
import { LoginFormValidationSchema } from "./LoginFormSchema";

const notification = new ToasterService();

interface LoginPageProps extends WithTranslation {
  authenticateUserSuccess: (userId: string, roles: string[]) => void;
  federatedLoginUrls: (urlRequest: FederatedLoginUrlRequest) => Promise<string>;
}

interface LoginPageState {
  isLoginLoading: boolean;
  isFederateReady: boolean;
  federatedLoginUrls: Map<string, string>;
  loginError?: ErrorResponse<CIQError>;
}

type LoginPageIntersectProps = LoginPageProps & WithTranslation;

interface LoginFormValues {
  email: string;
  password: string;
  remember: boolean;
}

class LoginPage extends React.Component<LoginPageProps, LoginPageState> {
  constructor(props: LoginPageIntersectProps) {
    super(props);
    this.state = {
      isFederateReady: false,
      isLoginLoading: false,
      federatedLoginUrls: new Map()
    };
  }

  componentDidMount(): void {
    const { t } = this.props;
    document.title = t("login");
    this.federateUrlFetchSubscription = zip(
      MetaService.federatedLoginUrl({
        authenticationProvider: AuthConstants.FEDERATED_AUTH_PROVIDER.GOOGLE
      }),
      MetaService.federatedLoginUrl({
        authenticationProvider: AuthConstants.FEDERATED_AUTH_PROVIDER.MICROSOFT
      }),
      MetaService.federatedLoginUrl({
        authenticationProvider: AuthConstants.FEDERATED_AUTH_PROVIDER.APPLE
      })
    ).subscribe(
      ([google, microsoft, apple]) => {
        const { federatedLoginUrls } = this.state;
        federatedLoginUrls.set(
          AuthConstants.FEDERATED_AUTH_PROVIDER.GOOGLE,
          google.url
        );
        federatedLoginUrls.set(
          AuthConstants.FEDERATED_AUTH_PROVIDER.MICROSOFT,
          microsoft.url
        );
        federatedLoginUrls.set(
          AuthConstants.FEDERATED_AUTH_PROVIDER.APPLE,
          apple.url
        );
        this.setState({
          federatedLoginUrls,
          isFederateReady: true
        });
      },
      (error: ErrorResponse<CIQError>) => notification.showError(error.message)
    );

    trackPageView({ page: MixpanelPages.Login });
  }

  componentWillUnmount(): void {
    this.federateUrlFetchSubscription?.unsubscribe();
    this.authenticateUserSubscription?.unsubscribe();
  }

  private authenticateUserSubscription?: Subscription;

  private federateUrlFetchSubscription?: Subscription;

  private cookiesEnabled: boolean = navigator.cookieEnabled;

  handleLoginClicked = (
    { email, password, remember }: LoginFormValues,
    formikHelpers: FormikHelpers<LoginFormValues>
  ): void => {
    // Set time taken for logging in seconds
    addEventInitialization(MixPanelEventNames.LoggedIn);

    this.setState({ isLoginLoading: true, loginError: undefined });
    const { authenticateUserSuccess } = this.props;

    this.authenticateUserSubscription = UserService.authenticateUser({
      email,
      password,
      remember
    }).subscribe(
      ({ userId, userRoles }) => {
        this.setState(
          {
            isLoginLoading: false
          },
          () => {
            localStorage.setItem(
              AppConstants.LOCALSTORAGE.LAST_LOGIN,
              new Date().getTime().toString()
            );

            authenticateUserSuccess(userId, userRoles);
            trackEvent(MixPanelEventNames.LoggedIn, { userId });
          }
        );
      },
      (loginError: ErrorResponse<CIQError>) => {
        this.setState({
          loginError,
          isLoginLoading: false
        });

        formikHelpers.setFieldValue("password", "");
        formikHelpers.setFieldTouched("password", false);
      }
    );
  };

  handleFederatedLoginClicked = (provider: string): void => {
    const { federatedLoginUrls } = this.state;
    window.location.assign(federatedLoginUrls.get(provider)!);
  };

  autoLogin = (email: string, password: string) => {
    const formValues = {
      email,
      password,
      remember: false
    };
    const fakeFormik = {
      setFieldValue: () => {},
      setFieldTouched: () => {}
    };
    this.handleLoginClicked(formValues, fakeFormik as never);
  };

  renderLoginForm = (): JSX.Element => {
    const { isLoginLoading, loginError } = this.state;
    const { t } = this.props;

    return (
      <>
        <Form>
          {loginError && <Alert feedback={loginError} isSuccess={false} />}

          <div>
            <Field
              name="email"
              placeholder={t("email")}
              component={CIQFormInput}
            />
          </div>
          <InputGroup className="append">
            <Field
              name="password"
              placeholder={t("password")}
              type="password"
              className="inspectletIgnore"
              component={CustomPasswordInput}
            />
          </InputGroup>

          <div className="additional-options">
            <FormGroup className="custom-checkbox">
              <Label check>
                <Field
                  name="remember"
                  type="checkbox"
                  component={CIQFormInput}
                />
                {t("remember")}
                <span className="checkmark" />
              </Label>
            </FormGroup>

            <Link
              className="forgot-password-text"
              to={PublicRouteConstants.FORGOT_PASSWORD}
            >
              {t("forgot")}
            </Link>
          </div>

          <div className="login-page-footer">
            <div>
              <Trans
                ns="login"
                i18nKey="newMember"
                components={[
                  <Link key="0" to={PublicRouteConstants.REGISTER} />
                ]}
              />
            </div>
            <FormGroup>
              <AsyncAwareSubmitButton
                isLoading={isLoginLoading}
                text={t("login")}
              />
            </FormGroup>
          </div>
        </Form>

        <AutoLogin login={this.autoLogin} />
      </>
    );
  };

  renderCookiesRequired = (): JSX.Element => (
    <>
      <AuthPageHeader title={this.props.t("cookiesTitle")} />
      <h3>{this.props.t("cookiesMsg")}</h3>
    </>
  );

  renderLoginFormSection = (): JSX.Element => {
    const { isFederateReady } = this.state;

    const { t } = this.props;

    return (
      <div className="login-page">
        <AuthPageHeader title="Sign In" />

        <Formik<LoginFormValues>
          initialValues={{
            email: "",
            password: "",
            remember: false
          }}
          validationSchema={LoginFormValidationSchema}
          onSubmit={this.handleLoginClicked}
        >
          {this.renderLoginForm}
        </Formik>

        {isFederateReady && (
          <div className="another-login-section">
            <span>{t("socialAuth")}</span>

            <div className="social-login-container">
              <Link to={PublicRouteConstants.SSO} className="external-provider">
                <Icon
                  name="key"
                  color={ARBOLUS_COLORS.bColorGrayIcon}
                  fontSize="24px"
                />
                SSO Okta
              </Link>

              <div
                className="external-provider"
                onClick={(): void =>
                  this.handleFederatedLoginClicked(
                    FEDERATED_AUTH_PROVIDER.APPLE
                  )
                }
              >
                <div className="apple-icon" />
                Apple
              </div>

              <div
                className="external-provider"
                onClick={(): void =>
                  this.handleFederatedLoginClicked(
                    FEDERATED_AUTH_PROVIDER.GOOGLE
                  )
                }
              >
                <div className="google-icon" />
                Google
              </div>

              <div
                className="external-provider"
                onClick={(): void =>
                  this.handleFederatedLoginClicked(
                    FEDERATED_AUTH_PROVIDER.MICROSOFT
                  )
                }
              >
                <div className="microsoft-icon" />
                Microsoft
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  render(): JSX.Element {
    return (
      <AuthPageBase>
        {this.cookiesEnabled
          ? this.renderLoginFormSection()
          : this.renderCookiesRequired()}
      </AuthPageBase>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): Record<string, AppAction> => ({
  authenticateUserSuccess: (userId: string, roles: string[]): AppAction =>
    dispatch(LoginStoreActions.authenticateUserSuccess(userId, roles))
});

export default connect(
  null,
  mapDispatchToProps
)(withTranslation("login")(LoginPage));
