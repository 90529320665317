import { ActionCreator } from "redux";

import { CIQError, ErrorResponse } from "../../../../models/api";
import { ListProject } from "../../../../models/project";
import {
  GET_PROJECT_LIST_FOR_NAVBAR,
  GET_PROJECT_LIST_FOR_NAVBAR_FAILURE,
  GET_PROJECT_LIST_FOR_NAVBAR_SUCCESS,
  GetProjectListForNavbarAction,
  GetProjectListForNavbarFailureAction,
  GetProjectListForNavbarSuccessAction,
  NAVBAR_REMOVE_PROJECT,
  NAVBAR_UPDATE_EXPERT_NEW_APPLICATIONS_COUNT,
  NAVBAR_UPDATE_PROJECT_NAME,
  NavbarRemoveProjectAction,
  NavbarUpdateExpertNewApplicationsCount,
  NavbarUpdateProjectNameAction
} from "./NavbarActionTypes";

export const navbarRemoveProject: ActionCreator<NavbarRemoveProjectAction> = (
  projectId: string
) => ({
  type: NAVBAR_REMOVE_PROJECT,
  payload: {
    projectId
  }
});

export const getProjectListForNavbar: ActionCreator<
  GetProjectListForNavbarAction
> = (showLoading = true) => ({
  type: GET_PROJECT_LIST_FOR_NAVBAR,
  payload: {
    showLoading
  }
});

export const getProjectListForNavbarSuccess: ActionCreator<
  GetProjectListForNavbarSuccessAction
> = (projectList: ListProject[], expertNewProjectCount?: number) => ({
  type: GET_PROJECT_LIST_FOR_NAVBAR_SUCCESS,
  payload: {
    projectList,
    expertNewProjectCount
  }
});

export const getProjectListForNavbarFailure: ActionCreator<
  GetProjectListForNavbarFailureAction
> = (error: ErrorResponse<CIQError>) => ({
  type: GET_PROJECT_LIST_FOR_NAVBAR_FAILURE,
  payload: {
    error
  }
});

export const navbarUpdateProjectName: ActionCreator<
  NavbarUpdateProjectNameAction
> = (projectId: string, projectName: string) => ({
  type: NAVBAR_UPDATE_PROJECT_NAME,
  payload: {
    projectId,
    projectName
  }
});

export const navbarUpdateExpertNewApplicationsCount: ActionCreator<
  NavbarUpdateExpertNewApplicationsCount
> = () => ({
  type: NAVBAR_UPDATE_EXPERT_NEW_APPLICATIONS_COUNT
});
