/* eslint-disable @typescript-eslint/no-explicit-any */
import Axios, { Method, AxiosRequestConfig, AxiosError } from "axios";
import i18next from "i18next";
import { stringify } from "query-string";
import { Observable } from "rxjs";

import { API_URL, USERS_API } from "./constants/api";
import { REST_ERROR } from "./enums/apiEnums";
import { IApiError, IErrorResponse } from "./models/api";

const loginRequestUrl = `${API_URL}${USERS_API.LOGIN()}`;
const openIdRequestUrl = `${API_URL}${USERS_API.OPEN_ID()}`;

interface FailedRequestHandler<T> {
  resolve: (value?: T | PromiseLike<T>) => void;
  reject: (reason?: any) => void;
}

export class BaseRestService {
  private axiosInstance;
  private isRefreshing = false;
  private failedRequestHandlers: FailedRequestHandler<any>[] = [];

  constructor(baseURL: string) {
    this.axiosInstance = Axios.create({
      baseURL,
      withCredentials: true
    });
  }

  get<T>(
    endPoint: string,
    queryParams: any | undefined = undefined
  ): Observable<T> {
    return this.apiCall<T>("GET", endPoint, undefined, queryParams);
  }

  post<T>(
    endPoint: string,
    data: any,
    queryParams: any | undefined = undefined,
    customConfig: AxiosRequestConfig = {}
  ): Observable<T> {
    return this.apiCall<T>("POST", endPoint, data, queryParams, customConfig);
  }

  put<T>(
    endPoint: string,
    data: any,
    queryParams: any | undefined = undefined,
    customConfig: AxiosRequestConfig = {}
  ): Observable<T> {
    return this.apiCall<T>("PUT", endPoint, data, queryParams, customConfig);
  }

  patch<T>(
    endPoint: string,
    data: any,
    queryParams: any | undefined = undefined
  ): Observable<T> {
    return this.apiCall<T>("PATCH", endPoint, data, queryParams);
  }

  delete<T>(
    endPoint: string,
    queryParams: any | undefined = undefined,
    data?: any
  ): Observable<T> {
    return this.apiCall<T>("DELETE", endPoint, data, queryParams);
  }

  configureAxios(kickOutUser: () => void): void {
    const { axiosInstance } = this;
    axiosInstance.defaults.headers.common["Content-Type"] = "application/json";
    axiosInstance.defaults.headers.common.Accept = "application/json";

    axiosInstance.interceptors.response.use(
      undefined,
      (error: AxiosError<any>) => {
        let errorResponse = {} as IErrorResponse;

        const status = error.response ? error.response.status : null;
        const { config } = error;
        const isAuthRequest = ![loginRequestUrl, openIdRequestUrl].includes(
          `${config?.baseURL}${config?.url}`
        );

        if (status === 500) {
          errorResponse.message = i18next.t(
            "restService:somethingWrongTryLater"
          );
          errorResponse.status = REST_ERROR.INTERNAL_ERROR;
          throw errorResponse;
        }

        if (isAuthRequest && status === 401) {
          return this.handleUnauthorized(error, kickOutUser);
        }

        if (status === 403) {
          errorResponse.message = i18next.t("restService:accessDenied");
          throw errorResponse;
        } else if (error.response && error.response.data) {
          errorResponse = error.response.data as IErrorResponse;
          throw errorResponse;
        } else if (Axios.isCancel(error)) {
          errorResponse.message = i18next.t("restService:userCanceledRequest");
          errorResponse.status = REST_ERROR.REQUEST_CANCELED;
          throw errorResponse;
        } else if (error.message === "Network Error") {
          errorResponse.message = i18next.t("restService:noInternet");
          errorResponse.status = REST_ERROR.NETWORK_ERROR;
          throw errorResponse;
        } else {
          errorResponse.message = i18next.t("restService:somethingWrong");
          throw errorResponse;
        }
      }
    );
  }

  protected async handleUnauthorized(
    error: AxiosError<IApiError>,
    kickOutUser: () => void
  ): Promise<any> {
    const requestConfig = error.config;

    if (requestConfig) {
      if (this.isRefreshing) {
        await new Promise<string>((resolve, reject) =>
          this.failedRequestHandlers.push({ resolve, reject })
        );
        // resend queued requests
        return Axios(requestConfig);
      }

      this.isRefreshing = true;
      try {
        await Axios.request({
          baseURL: API_URL,
          withCredentials: true,
          url: USERS_API.REFRESH_TOKEN(),
          method: "POST",
          data: {}
        });

        this.isRefreshing = false;
        // Process all incoming requested queued while refreshing
        this.failedRequestHandlers.forEach((promise) => promise.resolve());
        this.failedRequestHandlers = [];

        // Resend origin request, cookies are refreshed by now
        return Axios(requestConfig);
      } catch (err) {
        this.isRefreshing = false;

        kickOutUser();
        // Process all incoming requested queued while refreshing
        this.failedRequestHandlers.forEach((promise) =>
          promise.reject(new Error(i18next.t("restService:unauthorized")))
        );
        this.failedRequestHandlers = [];

        const errorResponse = {} as IErrorResponse;
        errorResponse.message = i18next.t("restService:unauthorized");
        throw errorResponse;
      }
    }
    return Promise.reject(error);
  }

  protected apiCall<T>(
    method: Method,
    endPoint: string,
    data?: any,
    queryParams: any | undefined = undefined,
    customConfig?: AxiosRequestConfig
  ): Observable<T> {
    const url = this.createUrl(endPoint, queryParams);
    const options = this.createRequestOptions(url, method, data, customConfig);

    return new Observable<T>((subscriber) => {
      this.axiosInstance
        .request<T>(options)
        .then((result) => {
          subscriber.next(result && result.data);
          subscriber.complete();
        })
        .catch((error: IErrorResponse) => {
          subscriber.error(error);
        });
    });
  }

  protected createUrl(
    endPoint: string,
    queryParams: any | undefined = undefined
  ): string {
    let params = "";
    if (queryParams) {
      params += `?${stringify(queryParams)}`;
    }

    return endPoint + params;
  }

  protected createRequestOptions(
    url: string,
    method: Method,
    data?: any,
    customConfig?: AxiosRequestConfig
  ): AxiosRequestConfig {
    if (customConfig) {
      return {
        ...customConfig,
        url,
        method,
        data
      };
    }

    return { url, method, data };
  }
}
