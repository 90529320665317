import clsx from "clsx";
import { push } from "connected-react-router";
import { Feature } from "flagged";
import queryString from "query-string";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Route,
  RouteComponentProps,
  StaticContext,
  Switch
} from "react-router";
import { Button } from "reactstrap";
import { Dispatch } from "redux";
import { createStructuredSelector } from "reselect";

import { ExpertsListPageTab, ToasterService } from "@arbolus-technologies/api";
import {
  ExpertInvitePanel,
  ProjectData,
  SlidePanel
} from "@arbolus-technologies/features/common";
import { ExpertListPage } from "@arbolus-technologies/features/project-experts-list";
import { ChatPage } from "@arbolus-technologies/features/projects";
import { FEATURE_FLAGS } from "@arbolus-technologies/models/common";
import {
  NEW_EXPERT,
  PROJECT_CHAT,
  PROJECT_CHAT_BASE
} from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import {
  ProjectNxSelector,
  ProjectNxStoreActions
} from "@arbolus-technologies/stores/project";
import { Loader } from "@arbolus-technologies/ui/components";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { CONTENT_PANEL } from "../../constants/app";
import { EX_LINK_ARBOLUS_HELP } from "../../constants/externalLink";
import { EXPERT_NETWORK } from "../../constants/navigation/authRoutes";
import {
  PROJECT_BASE,
  PROJECT_BRIEF,
  PROJECT_CALENDAR,
  PROJECT_EXPERTS,
  PROJECT_FILES,
  PROJECT_REFER_EXPERT_ROUTE,
  PROJECT_TABS
} from "../../constants/navigation/projectRoutes";
import {
  PROJECT_BASE_TAB,
  PROJECT_CALENDAR_TAB,
  PROJECT_FILES_TAB,
  PROJECT_TAB_ROUTES
} from "../../constants/navigation/projectTabRoutes";
import {
  ContentPanelContextConsumer,
  ContentPanelContextProps
} from "../../contexts/contentPanel/ContentPanelContext";
import {
  NavBarContextConsumer,
  NavBarContextProps
} from "../../contexts/navBar/NavBarContext";
import AccessManager from "../../contexts/roleBasedAccess/AccessManager";
import { UserClient } from "../../models/client";
import { UserPermissions } from "../../models/meta";
import { AppAction } from "../../store/actions";
import { AppState } from "../../store/reducers";
import { AppSelector } from "../app/store";
import { AuthSelector } from "../auth/store";
import { NavigationSelector } from "../dashboard/store";
import { ClientProjectRouter, ExpertProjectRouter } from "./ProjectRouter";
import {
  CIQCandidatePicker,
  CIQExpertProfile,
  CIQProjectNotificationSettings
} from "./components/panels";
import ReferExpertPanel from "./components/panels/referExpert/ReferExpertPanel";
import { ProjectTabs } from "./components/projectBase/projectTabs/ProjectTabs";
import { SimplifiedProjectBriefPage } from "./pages/SimplifiedProjectBriefPage/SimplifiedProjectBriefPage";
import LegacyProjectBriefPage from "./pages/legacyProjectBrief/LegacyProjectBriefPage";
import { ProjectSelector, ProjectStoreActions } from "./store";

const notification = new ToasterService();

interface ProjectContainerStoreProps {
  projectId: string;
  isProjectLoading: boolean;
  isProjectNxLoading: boolean;
  projectName: string;
  projectData: ProjectData | null;
  client?: UserClient;
  activeTab: string;
  invitationUrl?: string;
  hasCompliance: boolean;
  loggedInUserClientId?: string;
  projectTimezone: string;
  userPermissions: UserPermissions;
}

interface ProjectContainerProps
  extends RouteComponentProps<
      { projectId: string; eventId: string },
      StaticContext,
      { eventPageUuid?: string }
    >,
    ProjectContainerStoreProps,
    WithTranslation {
  getProject: (projectId: string) => void;
  getProjectNx: (projectId: string) => void;
  switchProjectTab: (tabRoute: string) => void;
  exitFromProject: () => void;
  onNavigateToExpertNetwork: () => void;
  onNavigateToChat: () => void;
  openExpertsPanel: () => void;
  handleClickRow: () => void;
}

interface ProjectContainerState {
  projectId: string;
  projectName: string;
  activeTab: string;
  showNewDashboard: boolean;
  showNewProjectBrief: boolean;
}

interface LocationState {
  referralState?: ExpertsListPageTab;
}

const bainId = process.env.NX_PUBLIC_BAIN_ID;

class ProjectContainer extends React.Component<
  ProjectContainerProps,
  ProjectContainerState
> {
  constructor(props: ProjectContainerProps) {
    super(props);

    const {
      match,
      getProject,
      getProjectNx,
      isProjectLoading,
      isProjectNxLoading
    } = props;
    this.state = {
      projectId: "",
      projectName: "",
      activeTab: props.activeTab,
      showNewDashboard: false,
      showNewProjectBrief: false
    };

    if (!isProjectLoading && !isProjectNxLoading) {
      const { projectId } = match.params;
      getProject(projectId);
      getProjectNx(projectId);
    }
  }

  static getDerivedStateFromProps(
    nextProps: ProjectContainerProps
  ): ProjectContainerState {
    const nextState = {} as ProjectContainerState;
    nextState.projectId = nextProps.projectId;
    nextState.projectName =
      nextProps.projectData?.name || nextProps.projectName;
    nextState.activeTab = nextProps.activeTab;
    return nextState;
  }

  componentDidMount = (): void => {
    const { t, userPermissions, loggedInUserClientId } = this.props;
    if (window.history.state && "pusherRefresh" in window.history.state) {
      notification.showSuccess(t("pusherRefresh"));

      // Clear state to avoid showing toast on refresh window
      window.history.replaceState({}, document.title);
    }

    const { featureFlags } = userPermissions;

    // New FF and client user only
    this.setState({
      showNewDashboard: featureFlags.NewProjectDashboard,
      showNewProjectBrief:
        !!loggedInUserClientId && featureFlags.SimplifiedProjectCreation
    });
  };

  componentDidUpdate = (): void => {
    const { projectName } = this.state;

    if (projectName) {
      document.title = projectName;
    }
  };

  componentWillUnmount(): void {
    const { exitFromProject } = this.props;
    exitFromProject();
  }

  handleSwitchProjectTabs = (
    nextPath: keyof typeof PROJECT_TAB_ROUTES
  ): void => {
    const { projectId, switchProjectTab } = this.props;
    const nextTabRoute = PROJECT_TAB_ROUTES[nextPath];

    switchProjectTab(nextTabRoute.route(projectId));
  };

  handleNavigateToReferExpert = (): void => {
    const { history, projectId } = this.props;
    history.push(PROJECT_REFER_EXPERT_ROUTE(projectId));
  };

  handleReferSomeone = ({
    setContent,
    closePanel
  }: ContentPanelContextProps): void => {
    setContent(
      <ReferExpertPanel
        key={CONTENT_PANEL.REFER_EXPERT}
        onClose={closePanel}
        onReferExpert={this.handleNavigateToReferExpert}
      />,
      true
    );
  };

  handleProjectNotificationSettings = ({
    setContent,
    closePanel
  }: ContentPanelContextProps): void => {
    const { projectId } = this.props;
    setContent(
      <CIQProjectNotificationSettings
        key={CONTENT_PANEL.PROJECT_NOTIFICATION_SETTINGS}
        onPanelClose={closePanel}
        projectId={projectId}
      />,
      true
    );
  };

  handleClickRow = (
    expertId: string,
    referralId: string,
    { setContent }: ContentPanelContextProps,
    openAvailabilityTab = false
  ): void => {
    const { projectId } = this.props;
    setContent(
      <CIQExpertProfile
        projectId={projectId}
        referralId={referralId}
        expertId={expertId}
        showAvailabilityTab={openAvailabilityTab}
      />,
      true,
      "panel-large"
    );
  };

  handleReviewNow = ({
    setContent,
    closePanel
  }: ContentPanelContextProps): void => {
    const { projectId, history, location } = this.props;
    const { referralState }: LocationState = queryString.parse(
      window.location.search
    );
    setContent(
      // @ts-ignore
      <CIQCandidatePicker projectId={projectId} onPanelClose={closePanel} />,
      true,
      "panel-large",
      () => {
        // Reset the url when closing the side panel and remove the showReview parameter
        const newUrl = `${location.pathname}?referralState=${referralState}`;
        history.replace(newUrl);
        return closePanel;
      }
    );
  };

  handleSlidePanelAvailability = (
    { setContent }: ContentPanelContextProps,
    projectId: string,
    expertId: string,
    referralId: string
  ): void => {
    setContent(
      <CIQExpertProfile
        projectId={projectId}
        expertId={expertId}
        referralId={referralId}
        showAvailabilityTab
      />,
      true,
      "panel-large"
    );
  };

  render(): JSX.Element {
    const {
      projectId,
      activeTab,
      projectName,
      showNewDashboard,
      showNewProjectBrief
    } = this.state;
    const {
      t,
      isProjectLoading,
      isProjectNxLoading,
      openExpertsPanel,
      hasCompliance,
      loggedInUserClientId,
      projectTimezone,
      projectData,
      client,
      onNavigateToChat,
      match,
      location
    } = this.props;

    const isOnChatPage =
      match.path === PROJECT_CHAT_BASE || match.path === PROJECT_CHAT;

    if (isProjectLoading || isProjectNxLoading || !projectId) {
      return <Loader isFull />;
    }

    return (
      <div
        className={
          showNewDashboard || isOnChatPage
            ? ""
            : "single-project-container page-content"
        }
      >
        {!showNewDashboard && !isOnChatPage && (
          <>
            <NavBarContextConsumer>
              {({
                isProjectActionsActive,
                projectActionToggle
              }: NavBarContextProps): JSX.Element => (
                <div
                  className={clsx("tab-page-header page-content-header", {
                    "project-actions": isProjectActionsActive,
                    "header-active": activeTab === PROJECT_BASE_TAB
                  })}
                >
                  <div className="top-container">
                    <h1>{projectName}</h1>
                    <div className="right-container">
                      <div className="btn-container">
                        <ContentPanelContextConsumer>
                          {(
                            contentPanelContext: ContentPanelContextProps
                          ): JSX.Element => (
                            <>
                              <Button
                                size="sm"
                                id="settings-btn"
                                color="secondary"
                                className="settings-icon ciq-icon ciq-component-icon-ic-call-settings-fill"
                                onClick={(): void =>
                                  this.handleProjectNotificationSettings(
                                    contentPanelContext
                                  )
                                }
                              />
                              <AccessManager permission="projectBase:addExpert">
                                <Button
                                  id="invite-btn"
                                  size="sm"
                                  color="primary"
                                  className="with-icon"
                                  onClick={openExpertsPanel}
                                >
                                  <span className="ciq-plus" />
                                  {t("addExperts")}
                                </Button>
                              </AccessManager>
                              <AccessManager permission="projectBase:referSomeone">
                                <Button
                                  id="refer-btn"
                                  className="with-icon"
                                  size="sm"
                                  color="primary"
                                  onClick={(): void =>
                                    this.handleReferSomeone(contentPanelContext)
                                  }
                                >
                                  <span className="ciq-plus" />
                                  {t("referSomeone")}
                                </Button>
                              </AccessManager>
                            </>
                          )}
                        </ContentPanelContextConsumer>
                        <AccessManager permission="projectBase:help">
                          <a
                            id="help-btn"
                            className="btn-help btn btn-secondary btn-sm"
                            href={EX_LINK_ARBOLUS_HELP}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {t("help")}
                          </a>
                        </AccessManager>
                        <Button
                          size="sm"
                          color="primary"
                          id="project-actions"
                          className="with-icon dropdown-btn"
                          onClick={projectActionToggle}
                        >
                          <i className="ciq-icon ciq-chevron-down" />
                        </Button>
                      </div>
                    </div>
                    <div className="bottom-container">
                      <div
                        className={clsx("action-btn-container", {
                          "project-actions": isProjectActionsActive
                        })}
                      >
                        <div className="btn-container">
                          <Button
                            color="default"
                            onClick={(): void =>
                              this.handleSwitchProjectTabs(PROJECT_BASE)
                            }
                            className={clsx("nav-shortcut-btn", {
                              active: activeTab === PROJECT_BASE_TAB
                            })}
                          >
                            <i className="ciq-icon ciq-card-view" />
                          </Button>
                          <Button
                            color="default"
                            onClick={() => onNavigateToChat()}
                            className={clsx("nav-shortcut-btn")}
                          >
                            <i className="ciq-icon ciq-comment" />
                          </Button>
                          <Button
                            color="default"
                            onClick={(): void =>
                              this.handleSwitchProjectTabs(PROJECT_CALENDAR)
                            }
                            className={clsx("nav-shortcut-btn", {
                              active: activeTab === PROJECT_CALENDAR_TAB
                            })}
                          >
                            <i className="ciq-icon ciq-calender" />
                          </Button>
                          <Button
                            color="default"
                            onClick={(): void =>
                              this.handleSwitchProjectTabs(PROJECT_FILES)
                            }
                            className={clsx("nav-shortcut-btn", {
                              active: activeTab === PROJECT_FILES_TAB
                            })}
                          >
                            <i className="ciq-icon ciq-file" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </NavBarContextConsumer>
            <SlidePanel
              panelId={PanelId.AssignExperts}
              width={SIDE_PANEL_SIZE._400}
              closeButtonDirection="right"
              title={t("addExperts")}
            >
              <ExpertInvitePanel
                projectId={projectId}
                createExpertRoute={NEW_EXPERT}
              />
            </SlidePanel>
          </>
        )}
        <Switch>
          {loggedInUserClientId ? ClientProjectRouter : ExpertProjectRouter}
          {showNewProjectBrief ? (
            <Route
              path={PROJECT_BRIEF}
              component={SimplifiedProjectBriefPage}
            />
          ) : (
            <Route path={PROJECT_BRIEF} component={LegacyProjectBriefPage} />
          )}
          <Route
            path={PROJECT_TABS}
            exact
            render={() => (
              <ProjectTabs
                activeTab={activeTab}
                location={location}
                match={match}
              />
            )}
          />
          <Route
            path={PROJECT_EXPERTS}
            exact
            render={() => (
              <ContentPanelContextConsumer>
                {(
                  contentPanelContext: ContentPanelContextProps
                ): JSX.Element => (
                  <ExpertListPage
                    projectData={{
                      id: projectId,
                      name: projectName,
                      hasCompliance,
                      timezone: projectData?.timezone || projectTimezone,
                      isBain: loggedInUserClientId === bainId
                    }}
                    handleReviewNow={() =>
                      this.handleReviewNow(contentPanelContext)
                    }
                    handleClickRow={(
                      expertId: string,
                      referralId: string,
                      openAvailabilityTab?: boolean
                    ) =>
                      this.handleClickRow(
                        expertId,
                        referralId,
                        contentPanelContext,
                        openAvailabilityTab
                      )
                    }
                    handleSlidePanelAvailability={(
                      projectId,
                      expertId,
                      referralId
                    ) =>
                      this.handleSlidePanelAvailability(
                        contentPanelContext,
                        projectId,
                        expertId,
                        referralId
                      )
                    }
                  />
                )}
              </ContentPanelContextConsumer>
            )}
          />
          <Feature name={FEATURE_FLAGS.Chat}>
            <Route
              path={[PROJECT_CHAT_BASE, PROJECT_CHAT]}
              exact
              render={() => (
                <ContentPanelContextConsumer>
                  {(
                    contentPanelContext: ContentPanelContextProps
                  ): JSX.Element => (
                    <ChatPage
                      projectId={projectId}
                      client={client}
                      handleOpenExpertSidePanel={(
                        expertId: string,
                        referralId: string
                      ) =>
                        this.handleClickRow(
                          expertId,
                          referralId,
                          contentPanelContext
                        )
                      }
                    />
                  )}
                </ContentPanelContextConsumer>
              )}
            />
          </Feature>
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<
  AppState,
  ProjectContainerProps,
  ProjectContainerStoreProps
>({
  projectId: ProjectSelector.projectIdSelector(),
  activeTab: NavigationSelector.navigationActiveProjectTabSelector(),
  projectName: ProjectSelector.projectNameSelector(),
  projectData: ProjectNxSelector.projectData(),
  client: AuthSelector.authClientSelector(),
  isProjectLoading: ProjectSelector.projectLoadingSelector(),
  isProjectNxLoading: ProjectNxSelector.isLoadingProjectData(),
  invitationUrl: ProjectSelector.projectInvitationUrlSelector(),
  hasCompliance: ProjectSelector.projectHasComplianceSelector(),
  loggedInUserClientId: CacheSelector.loggedInUserClientId(),
  projectTimezone: ProjectSelector.projectTimezoneSelector(),
  userPermissions: AppSelector.appUserPermissionsSelector()
});

const mapDispatchToProps = (dispatch: Dispatch): Record<string, AppAction> => ({
  getProject: (projectId: string): AppAction =>
    dispatch(ProjectStoreActions.getProject(projectId)),
  getProjectNx: (projectId: string): AppAction =>
    dispatch(ProjectNxStoreActions.loadProject(projectId)),
  exitFromProject: (): AppAction =>
    dispatch(ProjectStoreActions.exitFromProject()),
  switchProjectTab: (tabRoute: string): AppAction => {
    dispatch(push(tabRoute));
  },
  onNavigateToExpertNetwork: (): AppAction => dispatch(push(EXPERT_NETWORK)),
  onNavigateToChat: (): AppAction => dispatch(push(PROJECT_CHAT_BASE)),
  openExpertsPanel: (): AppAction => {
    dispatch(PanelStoreActions.openPanel(PanelId.AssignExperts));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(withTranslation("projectBasePage")(ProjectContainer));
