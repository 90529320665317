import { ANSWER_PREFERENCES_TYPE_VALUE } from "./enums";

export const CANOPY_FAQ_URL =
  "https://help.arbolus.com/articles/72170-arbolus-canopy-faqs";

export const CanopyConstraints = {
  MAX_CANOPY_TITLE_LENGTH: 100,
  MAX_CANOPY_OVERVIEW_LENGTH: 2000,
  MAX_INVOICE_VALUE: 100000,
  MIN_INVOICE_VALUE: -100000,
  MIN_NUMBER_OF_QUESTIONS: 1,
  MAX_NUMBER_OF_QUESTIONS: 20,
  MAX_SHORT_TEXT_ANSWER_LENGTH: 280
};

export const FormConstraints = {
  MAX_QUESTION_LENGTH: 200,
  MAX_DETAILS_LENGTH: 250,
  MIN_CHOICES: 2,
  MAX_CHOICES: 15,
  MIN_ANSWER_CHOICES: 1,
  MAX_ANSWER_CHOICES: 10,
  MAX_CHOICE_LENGTH: 100,
  MIN_VALUE: {
    [ANSWER_PREFERENCES_TYPE_VALUE.NUMBER]: 0,
    [ANSWER_PREFERENCES_TYPE_VALUE.PERCENTAGE]: 0,
    [ANSWER_PREFERENCES_TYPE_VALUE.MULTI_RATING]: 0
  },
  MAX_VALUE: {
    [ANSWER_PREFERENCES_TYPE_VALUE.NUMBER]: 999999999,
    [ANSWER_PREFERENCES_TYPE_VALUE.PERCENTAGE]: 999999999,
    [ANSWER_PREFERENCES_TYPE_VALUE.MULTI_RATING]: 999999999
  },
  MIN_NPS_VALUE: 0,
  MAX_NPS_VALUE: 10
};
