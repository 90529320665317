import queryString from "query-string";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

import { ExpertsListPageTab, ReferralSummary } from "@arbolus-technologies/api";
import { PROJECT_CALENDAR_ROUTE } from "@arbolus-technologies/routes";
import { ChevronButton, Tab } from "@arbolus-technologies/ui/components";

import { TABS, TabsWithPage } from "./tabDefinitions";

import styles from "./ExpertsListTabs.module.scss";

interface LocationState {
  referralState?: ExpertsListPageTab;
}

export const ExpertListTabs: React.FC<{
  tabs: TabsWithPage[];
  activeTab: TabsWithPage;
  onChangeTab: (tab: TabsWithPage) => void;
  projectId: string;
  summaryList: ReferralSummary | null;
  showReviewNow?: boolean;
}> = ({
  tabs,
  activeTab,
  onChangeTab,
  projectId,
  summaryList,
  showReviewNow
}) => {
  const { t } = useTranslation("expertsList");
  const availableItemsCount = summaryList ? mapSummaryToTabs(summaryList) : {};
  const getTabText = (tab: TabsWithPage) =>
    tab in availableItemsCount
      ? `${TABS[tab].title} · ${availableItemsCount[tab]}`
      : TABS[tab].title;

  const history = useHistory();
  const location = useLocation();
  const { referralState }: LocationState = queryString.parse(
    window.location.search
  );

  const replaceBySidePanelUrl = () => {
    const newUrl = `${location.pathname}?referralState=${referralState}&reviewExperts=true`;
    history.replace(newUrl);
  };

  return (
    <div className={styles.menu}>
      <div className={styles.tabs}>
        {tabs.map((tab) => (
          <Tab
            key={tab}
            text={getTabText(tab)}
            onClick={() => onChangeTab(tab)}
            isActive={activeTab === tab}
          />
        ))}
        {/* This is not really a tab, only looks like one. Opens a panel when clicked */}
        {showReviewNow && (
          <Tab
            className={styles.reviewNow}
            icon={"notifications"}
            text={`${t("reviewNow")} · ${
              availableItemsCount[ExpertsListPageTab.Review]
            }`}
            onClick={() => replaceBySidePanelUrl()}
          />
        )}
      </div>
      <ChevronButton
        to={PROJECT_CALENDAR_ROUTE(projectId)}
        text={t("callsScheduled", {
          count: summaryList?.callsScheduled || 0
        })}
        icon={{ direction: "right", position: "end" }}
        disabled={summaryList?.callsScheduled === 0}
      />
    </div>
  );
};

function mapSummaryToTabs(
  summary: ReferralSummary
): Partial<Record<ExpertsListPageTab, number>> {
  return {
    [ExpertsListPageTab.AllExperts]: summary.allExperts,
    [ExpertsListPageTab.Recommendations]: summary.recommendations,
    [ExpertsListPageTab.ShortList]: summary.shortList,
    [ExpertsListPageTab.Candidate]: summary.candidate,
    [ExpertsListPageTab.Accept]: summary.accept,
    [ExpertsListPageTab.Reject]: summary.reject,
    [ExpertsListPageTab.Review]: summary.review
  };
}
