import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button } from "reactstrap";

import { ToasterService } from "@arbolus-technologies/api";

import { WELCOME } from "../../../../../constants/navigation/authRoutes";
import { CIQError, ErrorResponse } from "../../../../../models/api";
import { ExpertService, UtilsService } from "../../../../../services";
import InputController from "../../../../app/components/inputsHooks/InputController";
import { AuthSelector } from "../../../../auth/store";
import { prepareToSend } from "./helpers";
import { NewEndorsementSchema } from "./newEndorsementSchema";

import arbolusLogo from "../../../../../assets/images/logos/logo-tree-blue.png";

import styles from "./NewEndorsement.module.scss";

const notification = new ToasterService();

export interface FormInterface {
  name1: string;
  email1: string;
  name2: string;
  email2: string;
  name3: string;
  email3: string;
}

const NewEndorsement: React.FC<{}> = () => {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm<FormInterface>({
    resolver: yupResolver(NewEndorsementSchema),
    defaultValues: {
      name1: "",
      name2: "",
      name3: "",
      email1: "",
      email2: "",
      email3: ""
    }
  });

  const history = useHistory();
  const user = useSelector(AuthSelector.authUserSelector());

  const { t } = useTranslation("register");

  const onSubmit: SubmitHandler<FormInterface> = (data) => {
    ExpertService.requestEndorsement(
      prepareToSend(data),
      user.expertId || ""
    ).subscribe(
      () => {
        notification.showSuccess(t("register:reinviteSuccess"));
        history.replace(WELCOME);
      },
      (error: ErrorResponse<CIQError>) => {
        notification.showError(error.message);
      }
    );
  };

  return (
    <div className="page-wrapper">
      <div className={styles.mainContainer}>
        <div className={styles.container}>
          <div className={styles.logoContainer}>
            <img src={arbolusLogo} alt="logo" />
          </div>
          <div className={styles.messageBannerContainer}>
            {t("accountCreated")}
          </div>
          <div />
          <h1 className={styles.title}>
            {t("hello")}, {UtilsService.displayUserName(user, false)}
          </h1>
          <div className={styles.sentContainer}>
            <div className={styles.boldSent}>{t("sent1")}</div>
            <div className={styles.secondarySent}>{t("sent2")}</div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              className={styles.clientContainer}
              data-testid="endorsement-container"
            >
              <InputController
                name="name1"
                error={errors.name1}
                control={control}
                placeholder="Name"
              />
              <InputController
                name="email1"
                error={errors.email1}
                control={control}
                placeholder="Email"
              />
            </div>
            <div
              className={styles.clientContainer}
              data-testid="endorsement-container"
            >
              <InputController
                name="name2"
                error={errors.name2}
                control={control}
                placeholder="Name"
              />
              <InputController
                name="email2"
                error={errors.email2}
                control={control}
                placeholder="Email"
              />
            </div>
            <div
              className={styles.clientContainer}
              data-testid="endorsement-container"
            >
              <InputController
                name="name3"
                error={errors.name3}
                control={control}
                placeholder="Name"
              />
              <InputController
                name="email3"
                error={errors.email3}
                control={control}
                placeholder="Email"
              />
            </div>

            <Button
              color="primary"
              type="submit"
              className={styles.bold}
              disabled={!isDirty}
              data-testid="submit"
            >
              {t("requestEndorsements")}
            </Button>
            <Button
              className={styles.bold}
              onClick={() => history.replace(WELCOME)}
            >
              {t("illDoThisLater")}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewEndorsement;
