import React from "react";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";

import { MultiChoiceTypes } from "./MultiChoiceTypes/MultiChoiceTypes";
import { MultiChoices } from "./MultiChoices/MultiChoices";

import styles from "./MultiChoiceAnswers.module.scss";

export const MultiChoiceAnswers: React.FC = () => {
  const { t } = useTranslation("canopyBuilder");

  return (
    <div className={styles.container}>
      <Label className={styles.label}>{t("answerPreferences")}</Label>
      <MultiChoiceTypes />
      <MultiChoices />
    </div>
  );
};
