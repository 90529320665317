import { useFeature } from "flagged";
import React from "react";
import { Route } from "react-router";

import { CanopyApplication } from "@arbolus-technologies/features/expert-applications";
import {
  CanopyDetailsContainer,
  CanopyPaused,
  ExpertCanopyList,
  SendAnswers
} from "@arbolus-technologies/features/expert-canopy";
import { FEATURE_FLAGS } from "@arbolus-technologies/models/common";
import {
  EXPERT_CANOPY,
  EXPERT_CANOPY_ROUTES
} from "@arbolus-technologies/routes";

import {
  CANOPY_APPLICATION_PAGE,
  CANOPY_PAUSED,
  CANOPY_SEND_ANSWERS
} from "../../constants/navigation/canopyRoutes";

export const CanopyExpertRouter: React.FC = () => {
  const newExpertApplicationFF = useFeature(FEATURE_FLAGS.NewExpertApplication);

  return (
    <>
      {newExpertApplicationFF && (
        <Route
          exact
          path={CANOPY_APPLICATION_PAGE}
          component={CanopyApplication}
        />
      )}
      <Route
        path={EXPERT_CANOPY_ROUTES}
        component={CanopyDetailsContainer}
        exact
      />
      <Route path={EXPERT_CANOPY} exact component={ExpertCanopyList} />
      <Route path={CANOPY_SEND_ANSWERS} exact component={SendAnswers} />
      <Route exact path={CANOPY_PAUSED} component={CanopyPaused} />
    </>
  );
};
