import i18next from "i18next";

import {
  FilterCriteria,
  SORT_DIRECTION,
  SortCriteria
} from "@arbolus-technologies/api";

export const ProjectComplianceQuestionType = {
  Text: "Text",
  YesNo: "YesNo",
  YesNoNa: "YesNoNa",
  YesNoText: "YesNoText"
};

export const ComplianceAnswerOptions = {
  Yes: "Yes",
  No: "No",
  NotSet: "NotSet",
  NotApplicable: "NotApplicable"
};

export const EXPERT_FILTER_CRITERIA = {
  ALL: {
    name: i18next.t("expertNetwork:all"),
    value: "All"
  } as FilterCriteria,
  WORKED: {
    name: i18next.t("expertNetwork:worked"),
    value: "ExpertsWorkedWith"
  } as FilterCriteria
};

export const EXPERT_SORT_CRITERIA = {
  NAME: {
    name: i18next.t("expertNetwork:sortByName"),
    value: "firstName",
    direction: SORT_DIRECTION.ASCENDING
  } as SortCriteria,
  DATE: {
    name: i18next.t("expertNetwork:sortByDate"),
    value: "created",
    direction: SORT_DIRECTION.DESCENDING
  } as SortCriteria
};
