import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { CanopyParamUrlTypes } from "@arbolus-technologies/models/canopy";
import {
  CANOPY_DETAILS_ROUTE,
  CANOPY_EXPERTS_ROUTE,
  CANOPY_QUESTIONS_ROUTE,
  CANOPY_SUMMARY_ROUTE,
  PROJECT_ROUTE
} from "@arbolus-technologies/routes";
import {
  CanopyV2Selector,
  CanopyV2StoreActions
} from "@arbolus-technologies/stores/canopy-V2";
import { BackButton, MenuButton } from "@arbolus-technologies/ui/components";

import {
  DETAILS_TAB,
  EXPERTS_TAB,
  QUESTIONS_TAB,
  SUMMARY_TAB
} from "../../CanopyClientWrapper/types";

import styles from "./MobileMenu.module.scss";

export const MobileMenu: React.FC = () => {
  const { t } = useTranslation("mobileMenu");
  const history = useHistory();
  const dispatch = useDispatch();
  const { canopyId } = useParams<CanopyParamUrlTypes>();

  const canopyData = useSelector(CanopyV2Selector.canopyData());

  useEffect(() => {
    if (!canopyData) {
      dispatch(CanopyV2StoreActions.getCanopyClientV2(canopyId));
    }
  }, [canopyId, dispatch, canopyData]);

  useEffect(() => {
    if (!isMobile) {
      history.push(CANOPY_DETAILS_ROUTE(canopyId));
    }
  }, [history, canopyId]);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        {canopyData && (
          <BackButton
            title={t("backToProject")}
            onClick={() => history.push(PROJECT_ROUTE(canopyData.projectId))}
          />
        )}

        <span className={styles.title}>{canopyData?.title}</span>
      </div>

      <div className={styles.menuContainer}>
        <MenuButton
          label={EXPERTS_TAB}
          path={CANOPY_EXPERTS_ROUTE(canopyId)}
          icon="groups"
        />
        <MenuButton
          label={QUESTIONS_TAB}
          path={CANOPY_QUESTIONS_ROUTE(canopyId)}
          icon="quiz"
        />
        <MenuButton
          label={SUMMARY_TAB}
          path={CANOPY_SUMMARY_ROUTE(canopyId)}
          icon="list"
        />
        <MenuButton
          label={DETAILS_TAB}
          path={CANOPY_DETAILS_ROUTE(canopyId)}
          icon="dashboard"
        />
      </div>
    </div>
  );
};
