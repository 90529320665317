import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ANSWER_TYPE,
  NpsAnswers,
  NpsSelectedQuestion
} from "@arbolus-technologies/models/canopy-panels";

import { ExpertsListForCanopyAnswersSlidePanel } from "../../ExpertsListForCanopyAnswersSlidePanel/ExpertsListForCanopyAnswersSlidePanel";
import { Nps } from "../../Nps/Nps";

import styles from "./NpsTypeViewer.module.scss";

interface NpsTypeViewerProps {
  data: NpsAnswers;
  questionId: string;
}

export const NpsTypeViewer: React.FC<NpsTypeViewerProps> = ({
  data,
  questionId
}) => {
  const { t } = useTranslation("npsTypeViewer");

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedQuestionDetails, setSelectedQuestionDetails] =
    useState<NpsSelectedQuestion | null>(null);

  const handleOnClickExperts = (
    npsValue: number | null,
    totalExperts: number
  ) => {
    setSelectedQuestionDetails({ npsValue, totalExperts });
    setIsOpen(true);
  };

  return (
    <>
      <div className={styles.npsContainer}>
        <Nps data={data} handleOnClickExperts={handleOnClickExperts} />
      </div>
      <ExpertsListForCanopyAnswersSlidePanel
        questionId={questionId}
        selectedQuestionDetails={selectedQuestionDetails}
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
        panelTitle={t("npsChoices")}
        panelHeader={{
          title: t("npsDescription", {
            npsValue: selectedQuestionDetails?.npsValue ?? "N/A",
            count: selectedQuestionDetails?.totalExperts ?? 0
          })
        }}
        type={ANSWER_TYPE.NPS}
      />
    </>
  );
};
