import { useContext } from "react";

import { TranscriptContext, TranscriptContextProps } from "./TranscriptContext";

export default function useTranscriptContext(): TranscriptContextProps {
  const context = useContext(TranscriptContext);
  if (!context) {
    throw new Error(
      "useTranscriptContext must be used within a TranscriptContextProvider"
    );
  }
  return context;
}
