import { deleteCollection } from "./actions/DeleteCollection/DeleteCollectionActions";
import {
  listCollections,
  listCollectionsFailure,
  listCollectionsReload,
  listCollectionsReset,
  listCollectionsSuccess
} from "./actions/ListCollections/ListCollectionsActions";
import { renameCollection } from "./actions/RenameCollection/RenameCollectionActions";

export const BookmarkCollectionsStoreActions = {
  listCollections,
  listCollectionsSuccess,
  listCollectionsFailure,
  listCollectionsReload,
  listCollectionsReset,
  renameCollection,
  deleteCollection
};
