export interface PaginatedStore<T> {
  items: T[];
  total: number;
}

export interface PaginatedLoadingStore<T> {
  isLoading: boolean;
  store?: PaginatedStore<T>;
}

export interface LoadingStoreItem<T> {
  isLoading: boolean;
  item?: T;
}

export interface ISearchTerm {
  searchTerm: string;
}

export function PaginatedStoreHasNextPage<T>(
  paginatedStore?: PaginatedStore<T> | null
): boolean {
  return (paginatedStore?.items.length ?? -1) < (paginatedStore?.total ?? 0);
}
