import { Button } from "arbolus-ui-components";
import clsx from "clsx";
import dompurify from "dompurify";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { REFERRAL_SUB_STATE } from "@arbolus-technologies/models/common";
import { DeleteModal } from "@arbolus-technologies/ui/components";

import { UtilsService } from "../../../../../services";
import { ProjectBriefTitle } from "./ProjectBriefTitle";

import styles from "./ProjectBrief.module.scss";

const sanitizer = dompurify.sanitize;

export interface ProjectBriefProps {
  projectId: string;
  projectDescription: string;
  applicationStatus: string;
  hasScreeningQuestions: boolean;
  hasComplianceQuestions: boolean;
  isLoading: boolean;
  onApply: () => void;
  onDecline: () => void;
}

const STEPS = {
  2: "two",
  3: "three",
  4: "four"
};

export const ProjectBrief: React.FC<ProjectBriefProps> = ({
  projectId,
  projectDescription,
  applicationStatus,
  hasScreeningQuestions,
  hasComplianceQuestions,
  isLoading,
  onApply,
  onDecline
}) => {
  const { t } = useTranslation("projectApplication");
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState<boolean>(false);

  const handleDeclineCancelClicked = (): void => {
    setIsDeclineModalOpen(false);
  };

  const handleDeclineConfirmClicked = (): void => {
    setIsDeclineModalOpen(false);
    onDecline();
  };

  const handleDeclineClick = (): void => {
    setIsDeclineModalOpen(true);
  };

  const renderBottomActions = (): JSX.Element => (
    <div className={styles.btnContainer}>
      <Button
        type="secondary"
        onClick={handleDeclineClick}
        disabled={isLoading}
        text={t("declineProject")}
      />
      <Button
        type="primary"
        onClick={onApply}
        disabled={isLoading}
        text={t("getStarted")}
      />
    </div>
  );

  const renderDeclineModal = (): JSX.Element => (
    <DeleteModal
      toggleModal={isDeclineModalOpen}
      messageTitle={t("declineModalTitle")}
      warningMessage={t("declineWarningMessage")}
      deleteActionText={t("decline")}
      onCancel={handleDeclineCancelClicked}
      onConfirm={handleDeclineConfirmClicked}
    />
  );

  const isAccepted = applicationStatus === REFERRAL_SUB_STATE.ACCEPT;
  const steps = [];
  if (hasScreeningQuestions) {
    steps.push(t("completeScreening"));
  }
  if (hasComplianceQuestions) {
    steps.push(t("completeCompliance"));
  }
  steps.push(t("shareAvailability"));
  steps.push(t("validateCurrentPosition"));
  steps.push(t("acceptAgreement"));

  return (
    <div className={styles.projectBriefContainer}>
      <div className={styles.briefContainer}>
        <ProjectBriefTitle projectId={projectId} isAccepted={isAccepted} />
        <div
          className={clsx(styles.richContent, {
            [styles.horizontalPadding]: !isAccepted
          })}
        >
          {UtilsService.parseRichTextContent(projectDescription)}
        </div>
      </div>
      {!isAccepted && (
        <>
          <div className={styles.separator} />
          <div className={styles.instructionsContainer}>
            <h3>{t("nextSteps")}</h3>
            <p
              className={styles.applyMessage}
              dangerouslySetInnerHTML={{
                __html: sanitizer(
                  t("applyToProject", {
                    stepCount: STEPS[steps.length as keyof typeof STEPS]
                  })
                )
              }}
            />
            <div className={styles.stepsContainer}>
              {steps.map((stepItem, key) => (
                <div className={styles.step} key={stepItem}>
                  <div className={styles.stepCount}>{key + 1}</div>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(stepItem)
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          {renderBottomActions()}
          {renderDeclineModal()}
        </>
      )}
    </div>
  );
};
