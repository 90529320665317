import { APP_TRACKING_ROUTES } from "@arbolus-technologies/models/common";

export const SHARE_PROJECT_EXPERT_APPLICATION = (projectId: string): string =>
  `${process.env.NX_PUBLIC_BASE_URL}/application/${projectId}`;

export const SHARE_PROJECT_BOOKING = (
  projectId: string,
  referralId: string
): string =>
  `${process.env.NX_PUBLIC_BASE_URL}/project/${projectId}/event?referralId=${referralId}&from=${APP_TRACKING_ROUTES.SHARE_BOOKING_LINK}`;
