import React, { useState } from "react";
import { Button } from "reactstrap";

import { InformModal } from "../InformModal/InformModal";

import styles from "./SectionHeader.module.scss";

interface SectionHeaderProps {
  mainTitle: string;
  buttonCreate: {
    title: string;
    onClick: () => void;
    disabled?: boolean;
  };
  buttonViewAll: {
    title: string;
    onClick: () => void;
    disable: boolean;
  };
  titleInformModal: string;
  bodyInformModal: string;
}
export const SectionHeader: React.FC<SectionHeaderProps> = ({
  titleInformModal,
  bodyInformModal,
  buttonCreate,
  buttonViewAll,
  mainTitle
}) => {
  const [showInformModal, setShowInformModal] = useState(false);

  return (
    <div className={styles.buttonContainer}>
      <span className={styles.surveysTitle}>{mainTitle}</span>
      <div className={styles.rightButtonsContainer}>
        <Button
          size="md"
          color="primary"
          className="with-icon"
          onClick={buttonCreate.onClick}
        >
          <span className="ciq-icon ciq-plus" /> {buttonCreate.title}
        </Button>
        <Button
          size="md"
          color="secondary"
          className={styles.transparent}
          onClick={buttonViewAll.onClick}
          disabled={buttonViewAll.disable}
        >
          {buttonViewAll.title}
        </Button>
      </div>
      <InformModal
        toggleModal={showInformModal}
        messageTitle={titleInformModal}
        warningMessage={bodyInformModal}
        hideModal={() => setShowInformModal(false)}
      />
    </div>
  );
};
