/* eslint-disable @typescript-eslint/no-empty-function */
import { push } from "connected-react-router";
import React from "react";
import { connect } from "react-redux";
import { Row } from "reactstrap";
import { Dispatch } from "redux";
import { createStructuredSelector } from "reselect";
import { Subscription, zip } from "rxjs";
import { filter } from "rxjs/operators";

import { SORT_DIRECTION, ToasterService } from "@arbolus-technologies/api";
import { PROJECT_CHAT_ROUTE } from "@arbolus-technologies/routes";
import { monthsFromToday } from "@arbolus-technologies/utils";

import { PROJECT_BASE_TAB } from "../../../../../constants/navigation/projectTabRoutes";
import ContentPanelEvents, {
  ContentPanelEvent,
  ContentPanelEventType
} from "../../../../../contexts/contentPanel/ContentPanelEvents";
import { CIQError, ErrorResponse } from "../../../../../models/api";
import {
  Event,
  EventGetPaginatedRequest,
  EventsDuration
} from "../../../../../models/event";
import { NoteOrDoc } from "../../../../../models/project";
import {
  EventService,
  ProjectService,
  RBServiceManager,
  UtilsService
} from "../../../../../services";
import { AppAction } from "../../../../../store/actions";
import { AppState } from "../../../../../store/reducers";
import { AuthSelector } from "../../../../auth/store";
import { NavigationSelector } from "../../../../dashboard/store";
import { ProjectSelector, ProjectStoreActions } from "../../../store";
import CalendarCard from "./CalendarCard";
import ChatCard from "./ChatCard";
import NotesAndDocsCard from "./NotesAndDocsCard";

const notification = new ToasterService();

interface ProjectCardsStoreProps {
  activeTab: string;
  projectId: string;
  eventsDuration: EventsDuration;
  isClientUser: boolean;
}

interface ProjectCardsProps extends ProjectCardsStoreProps {
  navigateToAnotherTab: (tabRouteName: string) => void;
  goToChat: (projectId: string, chatId: string) => void;
  refetchReferralData: (projectId: string) => void;
  isChatAvailable: boolean;
}

interface ProjectCardsState {
  isLoading: boolean;
  notesAndDocs: NoteOrDoc[];
  events: Event[];
}

const MIN_EVENTS_LENGTH = 3;

class ProjectCards extends React.Component<
  ProjectCardsProps,
  ProjectCardsState
> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    activeTab: "",
    projectId: "",
    isClientUser: false,
    eventsDuration: {} as EventsDuration,
    navigateToAnotherTab: (): void => {},
    goToChat: (): void => {},
    refetchReferralData: (): void => {},
    isChatAvailable: false
  };

  constructor(props: ProjectCardsProps) {
    super(props);
    this.state = {
      isLoading: false,
      notesAndDocs: [],
      events: []
    };
  }

  componentDidMount(): void {
    this.fetchCardsData();

    this.panelEventSubscription = ContentPanelEvents.contentPanelEventSubject
      .pipe(
        filter(({ eventType }) =>
          [
            ContentPanelEventType.DELETE_DOCUMENT,
            ContentPanelEventType.DELETE_EVENT,
            ContentPanelEventType.RENAME_DOCUMENT
          ].includes(eventType)
        ),
        filter(({ itemId }) => {
          const { events, notesAndDocs } = this.state;
          return (
            events.filter((d) => d.id === itemId).length > 0 ||
            notesAndDocs.filter((d) => d.id === itemId).length > 0
          );
        })
      )
      .subscribe(this.handlePanelUpdates);
  }

  componentDidUpdate(prevProps: ProjectCardsProps): void {
    const { activeTab, projectId, refetchReferralData, isClientUser } =
      this.props;
    if (
      prevProps.activeTab !== PROJECT_BASE_TAB &&
      activeTab === PROJECT_BASE_TAB
    ) {
      this.fetchCardsData();

      if (isClientUser) {
        refetchReferralData(projectId);
      }
    }
  }

  componentWillUnmount(): void {
    if (this.projectCardDataSubscription) {
      this.projectCardDataSubscription.unsubscribe();
    }
    this.panelEventSubscription?.unsubscribe();
  }

  private projectCardDataSubscription?: Subscription;

  private panelEventSubscription?: Subscription;

  fetchCardsData = (): void => {
    const { projectId } = this.props;

    this.setState({ isLoading: true });

    const getEventRequest: EventGetPaginatedRequest = {
      startTime: UtilsService.convertActiveZoneToUTC(),
      endTime: UtilsService.convertActiveZoneToUTC(monthsFromToday(1)),
      offset: 0,
      OrderDirection: SORT_DIRECTION.ASCENDING,
      limit: MIN_EVENTS_LENGTH
    };

    this.projectCardDataSubscription = zip(
      RBServiceManager.serviceCaller(
        ProjectService.getClientProjectNotesAndDocuments(projectId, 3),
        ProjectService.getExpertProjectNotesAndDocuments(projectId, 3)
      ),
      EventService.getEvents(projectId, getEventRequest)
    ).subscribe(
      ([notesAndDocs, events]) => {
        this.setState({
          notesAndDocs: notesAndDocs.items,
          events: events.items
        });
        this.setState({ isLoading: false });
      },
      (error: ErrorResponse<CIQError>) => {
        this.setState({ isLoading: false }, () =>
          notification.showError(error.message)
        );
      }
    );
  };

  handlePanelUpdates = ({
    eventType,
    itemId,
    documentName
  }: ContentPanelEvent): void => {
    const { DELETE_DOCUMENT, DELETE_EVENT, RENAME_DOCUMENT } =
      ContentPanelEventType;

    switch (eventType) {
      case DELETE_DOCUMENT:
        this.handleNoteOrDocDelete(itemId);
        break;

      case RENAME_DOCUMENT:
        this.handleNoteOrDocRename(itemId, documentName!);
        break;

      case DELETE_EVENT:
        this.handleEventDelete(itemId);
        break;
      default:
    }
  };

  handleNoteOrDocRename = (docId: string, filename: string): void => {
    const { notesAndDocs } = this.state;
    notesAndDocs.find((n) => n.id === docId)!.title = filename;
    this.setState({ notesAndDocs });
  };

  handleNoteOrDocDelete = (docId: string): void => {
    const { notesAndDocs } = this.state;
    const filteredNotesAndDocs = notesAndDocs.filter((n) => n.id !== docId);
    this.setState({ notesAndDocs: filteredNotesAndDocs });
  };

  handleEventDelete = (eventId: string): void => {
    const { events } = this.state;
    this.setState({ events: events.filter((e) => e.id !== eventId) });
  };

  render(): JSX.Element {
    const { projectId, goToChat, navigateToAnotherTab, isChatAvailable } =
      this.props;
    const { isLoading, events, notesAndDocs } = this.state;

    return (
      <Row className="project-cards-container">
        {isChatAvailable && (
          <ChatCard
            projectId={projectId}
            goToChat={goToChat}
            navigateToAnotherTab={navigateToAnotherTab}
          />
        )}
        <CalendarCard
          loading={isLoading}
          projectId={projectId}
          events={events}
          navigateToAnotherTab={navigateToAnotherTab}
        />
        <NotesAndDocsCard
          loading={isLoading}
          projectId={projectId}
          data={notesAndDocs}
          navigateToAnotherTab={navigateToAnotherTab}
        />
      </Row>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): Record<string, AppAction> => ({
  navigateToAnotherTab: (tabRouteName: string): AppAction => {
    dispatch(push(tabRouteName));
  },
  goToChat: (projectId: string, chatId: string): AppAction => {
    dispatch(push(PROJECT_CHAT_ROUTE(projectId, chatId)));
  },
  refetchReferralData: (projectId: string): AppAction => {
    dispatch(ProjectStoreActions.refetchReferralData(projectId));
  }
});

const mapStateToProps = createStructuredSelector<
  AppState,
  ProjectCardsProps,
  ProjectCardsStoreProps
>({
  projectId: ProjectSelector.projectIdSelector(),
  isClientUser: AuthSelector.authIsClientUserSelector(),
  eventsDuration: ProjectSelector.projectEventsDurationSelector(),
  activeTab: NavigationSelector.navigationActiveProjectTabSelector()
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCards);
