import { Icon } from "arbolus-ui-components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import {
  CIQError,
  Canopy,
  CanopyQuestion,
  CanopyService,
  DefaultToasterService,
  ErrorResponse,
  ToasterService
} from "@arbolus-technologies/api";
import {
  CANOPY_STATUS,
  CanopyParamUrlTypes
} from "@arbolus-technologies/models/canopy";
import { CANOPY_EXPERTS_ROUTE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { HR, Loader, PageWithSteps } from "@arbolus-technologies/ui/components";

import { CanopyBuilderHeader } from "../Components/CanopyBuilderHeader/CanopyBuilderHeader";
import {
  CANOPY_CREATION_STEPS,
  CANOPY_CREATION_STEPS_GENERATING
} from "../helpers/constants";

import styles from "./CanopyBuilder.module.scss";

interface CanopyBuilderProps {
  canopyService?: typeof CanopyService;
  notificationService?: ToasterService;
}

export const CanopyBuilder: React.FC<CanopyBuilderProps> = ({
  notificationService = DefaultToasterService,
  canopyService = CanopyService
}) => {
  const { t } = useTranslation("canopyBuilder");
  const { canopyId } = useParams<CanopyParamUrlTypes>();
  const history = useHistory();

  const [canopyDetails, setCanopyDetails] = useState<Canopy | null>(null);
  const [questions, setQuestions] = useState<CanopyQuestion[]>([]);
  const [showUnsavedChanges, setShowUnsavedChanges] = useState<boolean>(false);

  const isAdmin = useSelector(CacheSelector.isAdmin());

  const getCanopyDetails = useCallback(() => {
    canopyService.getCanopy(canopyId).subscribe(
      (canopyInfo) => {
        setCanopyDetails(canopyInfo);
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
      }
    );
  }, [canopyId, canopyService, notificationService]);

  const getQuestions = useCallback(() => {
    canopyService.getCanopyQuestions(canopyId).subscribe(
      (questionsItems) => {
        setQuestions(questionsItems.items);
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
      }
    );
  }, [canopyId, canopyService, notificationService]);

  useEffect(() => {
    getCanopyDetails();
    getQuestions();
  }, [getCanopyDetails, getQuestions]);

  const questionsGeneratingMessage = useCallback(
    () => (
      <div className={styles.generating}>
        <Icon
          name="smart_toy"
          fontSize="24px"
          color={ARBOLUS_COLORS.bColorBasePurple}
        />
        {t("aiBeingGenerated")}
      </div>
    ),
    [t]
  );

  if (!canopyDetails) {
    return <Loader isFull />;
  }

  if (
    !isAdmin &&
    canopyDetails.status !== CANOPY_STATUS.DRAFT &&
    canopyDetails.status !== CANOPY_STATUS.GENERATING
  ) {
    history.push(CANOPY_EXPERTS_ROUTE(canopyId));
  }

  const isActiveCanopy = canopyDetails.status === CANOPY_STATUS.ACTIVE;
  const isGeneratingCanopy = canopyDetails.status === CANOPY_STATUS.GENERATING;

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.pageContainer}>
        <CanopyBuilderHeader
          hasQuestions={questions.length > 0}
          projectId={canopyDetails.projectId}
          isActiveCanopy={isActiveCanopy}
          handleShowUnsavedChanges={() => setShowUnsavedChanges(true)}
        />
        <HR margin={{ top: 2, bottom: 3 }} />
        <PageWithSteps
          steps={
            isGeneratingCanopy
              ? CANOPY_CREATION_STEPS_GENERATING
              : CANOPY_CREATION_STEPS
          }
          sidebarComponent={
            isGeneratingCanopy ? questionsGeneratingMessage : undefined
          }
          changeStepOnClick
          stepComponentProps={{
            canopyDetails,
            getCanopyDetails,
            questionList: questions,
            getQuestions,
            showUnsavedChanges
          }}
        />
      </div>
    </div>
  );
};
