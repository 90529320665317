import { UserProfile } from "@arbolus-technologies/api";

import {
  GET_ADMIN_USER_PROFILE,
  GetAdminUserProfileAction
} from "./CacheGetAdminUserProfileActionTypes";

export const getAdminUserProfile = (
  userProfile: UserProfile
): GetAdminUserProfileAction => ({
  type: GET_ADMIN_USER_PROFILE,
  payload: {
    userProfile
  }
});
