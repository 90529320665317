import React from "react";

import { arbolusLogo } from "@arbolus-technologies/theme";

import styles from "./AuthPageHeader.module.scss";

export function AuthPageHeader({
  title
}: {
  title: string;
}): React.ReactElement {
  return (
    <div className={styles.header}>
      <h1>{title}</h1>
      <img className={styles.arbolusLogo} src={arbolusLogo} alt="Arbolus" />
    </div>
  );
}
