import { GetAppDataSuccessAction } from "../actions/CacheGetAppData/CacheGetAppDataActionTypes";
import { CacheReducerState } from "../models/definitions";
import { initialState } from "./CacheInitialState";

export const handleGetMetaData = (state = initialState): CacheReducerState => ({
  ...state,
  isMetaLoading: true
});

export const handleGetMetaDataSuccess = (
  state = initialState,
  { payload }: GetAppDataSuccessAction
): CacheReducerState => ({
  ...state,
  isMetaLoading: false,
  countries: payload.countries,
  industriesLevel0: payload.industriesLevel0,
  industriesLevel2: payload.industriesLevel2,
  timezones: payload.timezones,
  regions: payload.regions,
  currencies: payload.currencies,
  notificationCounts: payload.notificationCounts,
  specialPermissions: payload.specialPermissions
});

export const handleGetMetaDataFailure = (
  state = initialState
): CacheReducerState => ({
  ...state,
  isMetaLoading: false
});
