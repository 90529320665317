import React, { useCallback, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";

import { ExpertRate } from "@arbolus-technologies/models/common";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { IconWithText } from "@arbolus-technologies/ui/components";

import {
  RateTypeActions,
  initialState,
  reducer
} from "./RateInformationReducer";

export interface RateInformationProps {
  expertRate: ExpertRate;
}

export const RateInformation: React.FC<RateInformationProps> = ({
  expertRate
}) => {
  const { t } = useTranslation("expertRates");
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isInClientNetwork, pricing } = expertRate;

  // If rate or creditRate come as null, it means the price is not available.
  // Once one of these is defined (not null), the other one comes undefined. Ex.: creditRate: 1 / rate -> does not exist
  // Check if the variable is not undefined and has a value different than null
  // This will define if the price is marked in credits or cash
  const creditRate: boolean =
    typeof pricing?.creditRate !== "undefined" && pricing?.creditRate !== null;
  const hourlyRate: boolean =
    typeof pricing?.rate !== "undefined" && pricing?.rate !== null;

  const informationIconWithText = useCallback((): void => {
    // Define the text based on if it's creditRate or hourlyRate and if the expert is in the client's network.
    // Lastly, if none of them exist, the price is not available.

    let info = "";
    if (creditRate && !isInClientNetwork) {
      info = "creditRateInNetwork_" + isInClientNetwork;
    } else if (hourlyRate) {
      info = "hourlyRateInNetwork_" + isInClientNetwork;
    } else if (!creditRate && !hourlyRate) {
      info = "expertRateNotAvailable";
    }

    // isInClientNetwork has to be assigned always except if the pricing does not exist.
    dispatch({
      type: RateTypeActions.RATE_TYPE,
      payload: {
        isInClientNetwork: creditRate || hourlyRate ? isInClientNetwork : false,
        info: t(info)
      }
    });
  }, [creditRate, hourlyRate, isInClientNetwork, t]);

  useEffect(() => {
    informationIconWithText();
  }, [informationIconWithText]);

  return state.info ? (
    <IconWithText
      icon={{
        name: "info",
        color: ARBOLUS_COLORS.bColorBasePurple,
        filled: false,
        size: "16px"
      }}
      text={{ content: state.info }}
    />
  ) : null;
};
