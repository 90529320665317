import clsx from "clsx";
import React from "react";

import { RecommendationResponse } from "@arbolus-technologies/api";
import { Avatar } from "@arbolus-technologies/ui/components";
import { displayUserName } from "@arbolus-technologies/utils";

import styles from "./NameIconAngleBorder.module.scss";

interface NameIconAngleBorderProps {
  row: RecommendationResponse;
}

export const NameIconBorderRecommendation: React.FC<
  NameIconAngleBorderProps
> = ({ row }) => {
  const { firstName, lastName, profileImageUrl } = row;

  return (
    <div className={clsx(styles.container, styles.pending)}>
      <div className={styles.avatarContainer}>
        <Avatar
          avatar={{
            name: displayUserName({
              firstName,
              lastName
            }),
            imageUrl: profileImageUrl || ""
          }}
        />
        <div
          className={styles.avatarSpan}
          title={displayUserName({
            firstName,
            lastName
          })}
        >
          {displayUserName({
            firstName,
            lastName
          })}
        </div>
      </div>
    </div>
  );
};
