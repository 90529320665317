import i18next from "i18next";
import React from "react";
import { Input } from "reactstrap";

import {
  InternalArbolusModal,
  InternalArbolusModalProps
} from "./InternalArbolusModal";

import styles from "./InputModal.module.scss";

const defaultConfirmText = i18next.t("common:buttons:confirm");
const defaultCancelText = i18next.t("common:buttons:cancel");

type InternalProps = Omit<
  InternalArbolusModalProps,
  "leftButton" | "rightButton" | "subtitle"
>;

interface InputModalProps {
  confirmText?: string;
  cancelText?: string;
  onConfirm: (value: string) => void;
  onCancel: () => void;
  type?: "primary" | "confirmation" | "rejection";
  placeholder?: string;
}

type InputProps = InternalProps & InputModalProps;

export const InputModal: React.FC<InputProps> = ({
  type = "primary",
  confirmText = defaultConfirmText,
  cancelText = defaultCancelText,
  onConfirm,
  onCancel,
  isOpen,
  placeholder,
  ...rest
}) => {
  const [input, setInput] = React.useState("");

  React.useEffect(() => {
    if (!isOpen) {
      setInput("");
    }
  }, [isOpen]);

  return (
    <InternalArbolusModal
      {...rest}
      isOpen={isOpen}
      leftButton={{
        type: "tertiary",
        text: cancelText,
        onClick: onCancel
      }}
      rightButton={{
        type: type,
        text: confirmText,
        onClick: () => onConfirm(input),
        disabled: !input
      }}
      body={
        <Input
          value={input}
          placeholder={placeholder}
          onChange={({ target }) => setInput(target.value)}
          className={styles.input}
        />
      }
    />
  );
};
