import { Icon } from "arbolus-ui-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  CIQError,
  EARLIEST_AVAILABILITIES_ORDER_BY,
  EarliestAvailability,
  ErrorResponse,
  PaginatedRequest,
  ProjectService,
  SORT_DIRECTION
} from "@arbolus-technologies/api";
import {
  EarlyAvailabilityCard,
  SlidePanel
} from "@arbolus-technologies/features/common";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { HR, InfiniteScrollV2 } from "@arbolus-technologies/ui/components";
import { SIDE_PANEL_SIZE, displayUserName } from "@arbolus-technologies/utils";

import styles from "./EarliestAvailabilitiesSidePanel.module.scss";

interface EarliestAvailabilitiesSidePanelProps {
  isPanelOpen: boolean;
  projectService?: typeof ProjectService;
}

const initialQueryParams: PaginatedRequest<EARLIEST_AVAILABILITIES_ORDER_BY> = {
  limit: 10,
  offset: 0,
  orderBy: EARLIEST_AVAILABILITIES_ORDER_BY.EARLIEST_AVAILABILITIES,
  orderDirection: SORT_DIRECTION.ASCENDING
};

export const EarliestAvailabilitiesSidePanel: React.FC<
  EarliestAvailabilitiesSidePanelProps
> = ({ isPanelOpen, projectService = ProjectService }) => {
  const { t } = useTranslation("earliestAvailabilities");
  const dispatch = useDispatch();
  const [earliestAvailabilities, setEarliestAvailabilities] = useState<
    EarliestAvailability[] | null
  >(null);
  const [isAvailabilitiesLoading, setIsAvailabilitiesLoading] =
    useState<boolean>(false);
  const [queryParams, setQueryParams] =
    useState<PaginatedRequest<EARLIEST_AVAILABILITIES_ORDER_BY>>(
      initialQueryParams
    );

  const getEarliestAvailabilities = () => {
    setIsAvailabilitiesLoading(true);
    projectService.getEarliestAvailabilities(queryParams).subscribe(
      (response) => {
        const updatedAvailabilities =
          earliestAvailabilities === null
            ? response.items
            : [...earliestAvailabilities, ...response.items];
        setEarliestAvailabilities(updatedAvailabilities);
        setQueryParams({
          ...queryParams,
          limit: initialQueryParams.limit,
          offset: response.pagination.offset + initialQueryParams.limit,
          count: response.pagination.count
        });
        setIsAvailabilitiesLoading(false);
      },
      (error: ErrorResponse<CIQError>) => {
        console.error(error);
        setIsAvailabilitiesLoading(false);
      }
    );
  };

  useEffect(() => {
    if (isPanelOpen) {
      getEarliestAvailabilities();
    } else {
      setEarliestAvailabilities(null);
      setQueryParams(initialQueryParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPanelOpen]);

  const handleBottomReached = () => {
    if (queryParams.count && queryParams.count > queryParams.offset) {
      return getEarliestAvailabilities();
    }
    return null;
  };

  const renderAvailabilityCard = (
    earliestAvailability: EarliestAvailability
  ) => {
    const {
      expert,
      expertAvailabilitySlots,
      projectId,
      projectName,
      referralId,
      timezone
    } = earliestAvailability;
    const {
      expertId,
      tagline,
      profileImageUrl,
      lastPublicCompanyExpDate,
      doNotContactStatus
    } = expert;

    return (
      <EarlyAvailabilityCard
        key={referralId}
        earliestAvailability={{
          startTime: expertAvailabilitySlots[0].startTime,
          endTime: expertAvailabilitySlots[0].endTime
        }}
        project={{
          title: projectName,
          id: projectId
        }}
        expert={{
          title: tagline,
          imageUrl: profileImageUrl || "",
          fullName: displayUserName(expert),
          id: expertId,
          lastPublicCompanyExpDate,
          doNotContactStatus
        }}
        referralId={referralId}
        remainingSlots={expertAvailabilitySlots.length - 1}
        timezone={timezone}
      />
    );
  };

  return (
    <SlidePanel
      panelId={PanelId.EarliestAvailabilities}
      width={SIDE_PANEL_SIZE._720}
      hideHeader
      noPadding
    >
      <div className={styles.header}>
        <h1 className={styles.title}>{t("earliestAvailabilities")}</h1>
        <Icon
          name="close"
          fontSize="24px"
          color={ARBOLUS_COLORS.bColorGrayIcon}
          onClick={() =>
            dispatch(
              PanelStoreActions.closePanel(PanelId.EarliestAvailabilities)
            )
          }
        />
      </div>
      <div className={styles.divisorContainer}>
        <HR margin={{ top: 0, bottom: 0 }} />
      </div>
      <div className={styles.infiniteContainer}>
        <InfiniteScrollV2
          onBottomReached={handleBottomReached}
          items={earliestAvailabilities}
          renderer={renderAvailabilityCard}
          customPadding={[0, 4, 5, 5]}
          isLoading={isAvailabilitiesLoading}
        />
      </div>
    </SlidePanel>
  );
};
