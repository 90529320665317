import React from "react";

import { displayUserName } from "@arbolus-technologies/utils";

import { Avatar } from "../../Avatar/Avatar";

import styles from "./PersonRenderer.module.scss";

interface FullName {
  fullName: string;
}

interface SplitName {
  firstName: string;
  lastName: string;
}

interface PersonRendererProps {
  person: (FullName | SplitName) & { profileImageUrl?: string };
}

export const PersonRenderer: React.FC<PersonRendererProps> = ({ person }) => {
  const fullName = displayUserName(person);

  return (
    <div className={styles.container}>
      <Avatar
        avatar={{
          name: fullName,
          imageUrl: person.profileImageUrl
        }}
      />
      <span className={styles.text}>{fullName}</span>
    </div>
  );
};
