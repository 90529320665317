import React from "react";

import { EventAttachment } from "../../../../models/event";
import { DocumentService } from "../../../../services";

interface EventFileTagProps {
  document: EventAttachment;
  onRemoveClicked: (documentId: string) => void;
}

const EventFileTag: React.FC<EventFileTagProps> = ({
  document,
  onRemoveClicked
}: EventFileTagProps) => (
  <button className="file-tag" type="button">
    <span className="ciq-icon ciq-attachment" />
    <p>{DocumentService.prettifyFileName(document.fileName)}</p>
    <span
      className="ciq-icon ciq-close"
      onClick={(): void => onRemoveClicked(document.id)}
    />
  </button>
);

export default EventFileTag;
