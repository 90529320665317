import { Flex, Typography } from "antd";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";

import { EXPERT_WORK_HISTORY_TIME_FORMAT } from "@arbolus-technologies/utils";
import { WorkHistoryItemModel } from "../../../interfaces";
import { EMPLOYED_STATUS_OPTIONS } from "../helpers/constants";

const { Text } = Typography;

interface WorkHistoryTitleProps {
  workItem: WorkHistoryItemModel;
}

export const WorkHistoryTitle: React.FC<WorkHistoryTitleProps> = ({
  workItem
}) => {
  const { t } = useTranslation("workHistoryTitle");

  const isCountryMandatory = (workItem: WorkHistoryItemModel) => {
    if (
      !workItem.country &&
      workItem.employedStatus &&
      EMPLOYED_STATUS_OPTIONS.includes(workItem.employedStatus)
    ) {
      return true;
    }
    return false;
  };

  const fromDate = dayjs(workItem.from).format(EXPERT_WORK_HISTORY_TIME_FORMAT);
  const toDate = workItem.to
    ? dayjs(workItem.to).format(EXPERT_WORK_HISTORY_TIME_FORMAT)
    : t("current");

  const subtitle = [];
  if (workItem.title) {
    subtitle.push(workItem.title);
  }
  subtitle.push(`${fromDate} - ${toDate}`);

  return (
    <Flex vertical align="flex-start">
      <Flex gap="8px">
        <Text strong>{workItem.company?.name ?? workItem.employedStatus}</Text>
        {workItem.id && isCountryMandatory(workItem) && (
          <Text type="danger">{t("locationMissing")}</Text>
        )}
      </Flex>
      <Text>{subtitle.join(" - ")}</Text>
    </Flex>
  );
};
