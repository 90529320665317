import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { CanopyAnswerSummaryItemVideo } from "@arbolus-technologies/models/canopy-panels";
import { HR } from "@arbolus-technologies/ui/components";

import { CanopySummaryQuote } from "../../CanopySummaryQuote/CanopySummaryQuote";
import { CanopySummaryTitleComponent } from "../../CanopySummaryTitleComponent/CanopySummaryTitleComponent";

import styles from "./CanopySummaryComponentVideo.module.scss";

interface CanopySummaryComponentVideoProps {
  summary: CanopyAnswerSummaryItemVideo;
  canopyId: string;
}

export const CanopySummaryComponentVideo: React.FC<
  CanopySummaryComponentVideoProps
> = ({ summary, canopyId }) => {
  const { t } = useTranslation(`canopySummary`);
  const { summaryQuotes, questionDetails } = summary;

  return (
    <>
      <div className={styles.subtitleHeader}>{questionDetails}</div>
      <CanopySummaryTitleComponent title={t("summary")} />
      <div className={styles.summary}>{summary.summary}</div>
      <CanopySummaryTitleComponent title={t("supportingQuotes")} />
      <div>
        {summaryQuotes.map((quote, index) => (
          <Fragment key={index}>
            <CanopySummaryQuote
              quote={quote.quote}
              expert={quote.expert}
              canopyAnswerId={quote.canopyAnswerId}
              canopyId={canopyId}
            />
            {!(summary.summaryQuotes.length === index + 1) && <HR />}
          </Fragment>
        ))}
      </div>
    </>
  );
};
