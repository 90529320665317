import React from "react";
import { useDispatch } from "react-redux";

import {
  DefaultReferralsService,
  DefaultToasterService,
  ReferralsService,
  ToasterService
} from "@arbolus-technologies/api";
import { RejectDescription } from "@arbolus-technologies/models/project";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { UserPortrait } from "@arbolus-technologies/ui/components";

import { RejectFeedbackForm } from "./RejectFeedbackForm";
import {
  REJECT_REASON,
  REJECT_TEXT,
  RejectFeedbackFormObject
} from "./RejectFeedbackSchema";
import { RejectFeedbackPanelData } from "./models";

import styles from "./RejectFeedback.module.scss";

interface RejectFeedbackProps extends RejectFeedbackPanelData {
  referralsService?: ReferralsService;
  toasterService?: ToasterService;
}

export const RejectFeedback: React.FC<RejectFeedbackProps> = ({
  referralId,
  projectId,
  expert,
  onSuccess,
  referralsService = DefaultReferralsService,
  toasterService = DefaultToasterService
}) => {
  const dispatch = useDispatch();
  const sendReject = (feedback?: RejectFeedbackFormObject) => {
    const rejectDescription: RejectDescription | undefined = feedback
      ? { reason: feedback[REJECT_REASON]!, description: feedback[REJECT_TEXT] }
      : undefined;
    referralsService
      .reject(projectId, expert.expertId, rejectDescription)
      .subscribe({
        next: () => {
          onSuccess();
          dispatch(PanelStoreActions.closePanel(PanelId.RejectFeedbackPanel));
        },
        error: toasterService.showApiErrors
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.portrait}>
        <UserPortrait user={expert} avatarSize="bigXSCircle" />
      </div>
      <hr />
      <RejectFeedbackForm submit={sendReject} skip={() => sendReject()} />
    </div>
  );
};
