import React from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";

import { TextInput } from "@arbolus-technologies/ui/components";

import { SurveyQuestion } from "../../../../pages/models";

import styles from "./MoneyTextInput.module.scss";

export function MoneyTextInput({ question }: { question: SurveyQuestion }) {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <div className={styles.moneyInput}>
      <Controller
        name={question.fieldName}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            placeholder="USD"
            name={question.fieldName}
            control={control}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={errors?.spend as FieldError}
          />
        )}
      />
    </div>
  );
}
