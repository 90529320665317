import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { IconWithText } from "@arbolus-technologies/ui/components";

import styles from "./SelectableCardList.module.scss";

export interface CardItem {
  key: string;
  text: string;
  iconName?: string;
  iconCustomStyle?: React.CSSProperties;
  iconCustomClasses?: string;
}

interface SelectableCardListProps {
  items: CardItem[];
  onClick: (value: string) => void;
  classnames?: string;
  filters?: string[];
}

// TO_REPLACE

export const SelectableCardList: React.FC<SelectableCardListProps> = ({
  items,
  onClick,
  classnames,
  filters
}) => (
  <div className={styles.container}>
    {items?.map((cardItem: CardItem) => {
      const isSelected = filters?.includes(cardItem.key);

      return (
        <section
          key={cardItem.key}
          className={clsx(
            styles.cardItemContainer,
            classnames,
            isSelected && styles.selected
          )}
          onClick={() => onClick(cardItem.key)}
        >
          <IconWithText
            text={{
              content: cardItem.text,
              color: ARBOLUS_COLORS.bColorBaseDark
            }}
            icon={{
              name: cardItem.iconName!,
              size: "24px",
              color: ARBOLUS_COLORS.bColorBaseDark,
              customStyle: cardItem.iconCustomStyle,
              customClasses: clsx(styles.icon, cardItem.iconCustomClasses)
            }}
            customClasses={styles.cardItem}
          />
          <Icon
            name={isSelected ? "check" : "add"}
            fontSize="18px"
            color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
          />
        </section>
      );
    })}
  </div>
);
