import getBlobDuration from "get-blob-duration";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";

import { GetCanopyExpertVideoAnswer } from "@arbolus-technologies/api";
import {
  CanopyExpertSelector,
  CanopyExpertStoreActions
} from "@arbolus-technologies/stores/canopy-expert";

import { ButtonsPlayVideo } from "../ButtonsPlayVideo/ButtonsPlayVideo";
import { VideoInfo } from "../VideoInfo/VideoInfo";
import { VideoLoader } from "../VideoLoader/VideoLoader";

import styles from "./VideoPlayer.module.scss";

export const VideoPlayer: React.FC = () => {
  const dispatch = useDispatch();

  const [isReady, setIsReady] = useState(false);

  const questionToRecord = useSelector(
    CanopyExpertSelector.questionToRecordSelector()
  );
  const isVideoPlaying = useSelector(CanopyExpertSelector.isVideoPlaying());
  const videoBlob = useSelector(CanopyExpertSelector.canopyVideoBlob());
  const supportedVideoType = useSelector(
    CanopyExpertSelector.supportedVideoType()
  );
  const videoURL = useSelector(CanopyExpertSelector.canopyVideoURL());
  const isVideoUploaded = useSelector(CanopyExpertSelector.isVideoUploaded());

  const canopyAnswerData = useSelector(
    CanopyExpertSelector.canopyAnswerData<GetCanopyExpertVideoAnswer>()
  );
  const canopyAnswerLoading = useSelector(
    CanopyExpertSelector.isCanopyAnswerLoading()
  );
  const isVideoAlreadyUploaded = useSelector(
    CanopyExpertSelector.isVideoAlreadyUploaded()
  );

  useEffect(() => {
    if (isReady) {
      resolveDurationBug();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  const saveRecording = () => {
    if (questionToRecord?.id) {
      const formData = new FormData();
      formData.append(
        "video",
        videoBlob!,
        `${Math.floor(Date.now() / 1000)}.${supportedVideoType}`.toString()
      );

      if (
        canopyAnswerData &&
        (isVideoAlreadyUploaded || questionToRecord?.isAnswered)
      ) {
        dispatch(
          CanopyExpertStoreActions.deleteAndUploadVideo(
            canopyAnswerData.id,
            questionToRecord.id,
            formData
          )
        );
      } else {
        dispatch(
          CanopyExpertStoreActions.uploadCanopyVideo(
            questionToRecord.id,
            formData
          )
        );
      }
    }
  };

  const resolveDurationBug = async () => {
    const video = document.getElementsByTagName("video")[0];
    if (video && videoURL) {
      const duration = await getBlobDuration(videoURL);
      video.currentTime = duration;
      setTimeout(() => (video.currentTime = 0), 40);
    }
  };

  return (canopyAnswerData?.downloadUrl || videoURL) && !canopyAnswerLoading ? (
    <>
      <ReactPlayer
        config={{
          file: {
            attributes: {
              // cspell:disable-next-line
              controlsList: "nodownload",
              onContextMenu: (e: any) => e.preventDefault()
            }
          }
        }}
        url={canopyAnswerData?.downloadUrl ?? videoURL!}
        playing={isVideoPlaying}
        onEnded={() =>
          dispatch(CanopyExpertStoreActions.setPlayingVideo(false))
        }
        className={styles.videoPlayer}
        onPlay={() => dispatch(CanopyExpertStoreActions.setPlayingVideo(true))}
        onPause={() =>
          dispatch(CanopyExpertStoreActions.setPlayingVideo(false))
        }
        controls
        playsinline
        onReady={() => setIsReady(true)}
      />
      <ButtonsPlayVideo
        showSaveButton={!isVideoUploaded}
        handleOnClickSave={saveRecording}
      />
      <VideoInfo />
    </>
  ) : (
    <VideoLoader downloadUrl={canopyAnswerData?.downloadUrl} />
  );
};
