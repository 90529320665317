import i18next from "i18next";

import { FilterTimeSelectorOptions } from "@arbolus-technologies/models/project";

export const periodFilterOptions = [
  {
    title: i18next.t("discoverPage:filterSelectionAll"),
    value: FilterTimeSelectorOptions.All
  },
  {
    title: i18next.t("discoverPage:filterSelectionCurrent"),
    value: FilterTimeSelectorOptions.Current
  },
  {
    title: i18next.t("discoverPage:filterSelectionPast"),
    value: FilterTimeSelectorOptions.PastNotCurrent
  }
];
