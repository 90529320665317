import { useEffect, useState } from "react";

import { formatDuration, getVideoDuration } from "./VIdeoPlayerHelpers";

export const useVideoDuration = (
  videoUrl: string
): { videoDuration: string } => {
  const [duration, setDuration] = useState<number>(0);

  useEffect(() => {
    const fetchVideoDuration = async () => {
      try {
        const videoDuration = await getVideoDuration(videoUrl);
        setDuration(videoDuration);
      } catch (error) {
        console.error(error);
      }
    };

    fetchVideoDuration();
  }, [videoUrl]);

  return {
    videoDuration: formatDuration(duration)
  };
};
