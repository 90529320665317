import linkifyHtml from "linkify-html";

export function linkifyMessage(textIncludingHtml: string): string {
  return linkifyHtml(textIncludingHtml, {
    defaultProtocol: "https",
    target: "_blank",
    rel: "noopener noreferrer nofollow"
  });
}

export function getUrlWithProtocol(url: string): string {
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    return `https://${url}`;
  }
  return url;
}
