import React from "react";
import { useTranslation } from "react-i18next";

import { Pricing } from "@arbolus-technologies/models/common";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { Badge } from "@arbolus-technologies/ui/components";
import { getCurrenciesSymbols } from "@arbolus-technologies/utils";

interface RateAmountProps {
  pricing: Pricing;
}

export const RateAmount: React.FC<RateAmountProps> = ({ pricing }) => {
  const { t } = useTranslation("expertRates");

  const pricingText = (): string => {
    if (pricing?.rate) {
      return `${pricing.rate.amount} ${getCurrenciesSymbols(
        pricing.rate.isoCurrencyCode
      )}`;
    }
    if (pricing?.creditRate) {
      return `${pricing.creditRate} ${
        pricing.creditRate > 1 ? t("credits") : t("credit")
      }`;
    }
    return t("notAssigned");
  };

  return pricing?.rate === null || pricing?.creditRate === null ? (
    <Badge
      background={ARBOLUS_COLORS.bColorSecondaryGreyMid}
      text={t("notAssigned")}
      textStyle="uppercase"
      fontWeight={400}
      customStyle={{ padding: "8px 16px" }}
    />
  ) : (
    <Badge
      background={ARBOLUS_COLORS.bColorSecondaryGreen}
      text={pricingText()}
      textStyle="capitalize"
      fontWeight={400}
      customStyle={{ padding: "8px 16px" }}
    />
  );
};
