import { Icon } from "arbolus-ui-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { AngleChatData } from "@arbolus-technologies/models/project";
import { PROJECT_CHAT_ROUTE } from "@arbolus-technologies/routes";
import {
  ARBOLUS_COLORS,
  arbolusLogoWithBackground
} from "@arbolus-technologies/theme";

import { useChat } from "../../../../Contexts/ChatContext/ChatContext";
import { buildChatFromAngle } from "../../../../Contexts/ChatContext/ChatContextUtils";
import { useOnlineChatMembers } from "../../../../Hooks/Chat/useOnlineChatMembers";
import { AngleWithChat } from "../../../../Models/chat";
import { AngleName } from "../../../AngleName/AngleName";
import { chatListItemAvatarsSorted } from "../../utils";
import { ChatListItem } from "../ChatListItem/ChatListItem";

import styles from "./ChatsPerAngle.module.scss";

export const ChatsPerAngle: React.FC<{
  angle: AngleWithChat;
  projectId: string;
}> = ({ angle, projectId }) => {
  const { t } = useTranslation("chat");
  const {
    currentChat,
    changeChat,
    expertSupportChatActive,
    client,
    unreadMessagesCount,
    unreadMessagesCountByAngle,
    isChatEnabled
  } = useChat();

  const { isClientOnlineInAngleChat, isMemberOnlineInChat } =
    useOnlineChatMembers();

  const [isCollapsed, setIsCollapsed] = useState(false);

  const showMainAngleChat = !expertSupportChatActive && isChatEnabled;
  const hasNoChats = (angle.chats?.length ?? 0) === 0;
  const unreadCount = expertSupportChatActive
    ? unreadMessagesCountByAngle.ExpertSupport
    : unreadMessagesCountByAngle.ClientChat;
  const hasUnreadMessages = unreadCount[angle.id] > 0;

  // Hide empty angles in case if admin is on "Expert support" tab or chat is disabled
  if (hasNoChats && !showMainAngleChat) {
    return null;
  }

  return (
    <div className={styles.angleChats} data-testid="angle-chats">
      <div
        className={styles.header}
        onClick={(): void => setIsCollapsed((currentValue) => !currentValue)}
        role="button"
      >
        <AngleName title={angle.title} color={angle.color} size="small" bold />
        <div className={styles.expandAndNotificationContainer}>
          {hasUnreadMessages && <div className={styles.notificationDot} />}
          <Icon
            name={isCollapsed ? "expand_more" : "expand_less"}
            fontSize="24px"
            color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
          />
        </div>
      </div>
      {!isCollapsed && (
        <>
          {/* ANGLE CHAT: for Clients and Admins (Admin sees when not on "Expert support" tab) */}
          {showMainAngleChat && (
            <Link to={PROJECT_CHAT_ROUTE(projectId, angle.angleChatId)}>
              <ChatListItem
                name={t("angleChatTitle")}
                isActive={angle.angleChatId === currentChat?.id}
                avatars={[
                  {
                    name: t("arbolusTeamAvatarName"),
                    imageUrl: arbolusLogoWithBackground
                  },
                  {
                    name: client.name ?? "",
                    imageUrl: client.imageUrl
                  }
                ]}
                onClick={() => {
                  changeChat(buildChatFromAngle(angle));
                }}
                unreadCount={unreadMessagesCount[angle.angleChatId]}
                isCurrentChatMemberOnline={isClientOnlineInAngleChat(angle)}
              />
            </Link>
          )}
          {/* EXPERT CHATS */}
          {angle.chats?.map((chat: AngleChatData) => (
            <Link key={chat.title} to={PROJECT_CHAT_ROUTE(projectId, chat.id)}>
              <ChatListItem
                name={chat.title}
                isActive={chat.id === currentChat?.id}
                onClick={() => {
                  changeChat(chat);
                }}
                unreadCount={unreadMessagesCount[chat.id]}
                avatars={chatListItemAvatarsSorted(
                  chat,
                  expertSupportChatActive,
                  client
                )}
                isCurrentChatMemberOnline={isMemberOnlineInChat(chat)}
              />
            </Link>
          ))}
        </>
      )}
    </div>
  );
};
