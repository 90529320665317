import { useEffect, useState } from "react";

import { useQuery } from "./useQuery";

export const useMultiQuery = (key: string): string[] => {
  const searchParams = useQuery();
  const [values, setValues] = useState<string[]>([]);

  useEffect(() => {
    const valuesList: string[] = [];
    searchParams.forEach((v, k) => {
      if (k === key) {
        valuesList.push(v);
      }
    });

    setValues(valuesList);
  }, [searchParams, setValues, key]);

  return values;
};
