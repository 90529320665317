import React from "react";
import { useTranslation } from "react-i18next";

import {
  DefaultToasterService,
  ToasterService,
  User
} from "@arbolus-technologies/api";

import { ClientMemberPermissionsTab } from "../../TeamMemberContentClient/TabsContent/ClientMemberPermissionsTab/ClientMemberPermissionsTab";
import { CLIENT_PROFILE_TABS } from "../type";
import { Profile } from "./Profile";
import { Project } from "./Project";

interface ClientUserTabContentProps {
  activeTab: string;
  clientId: string;
  user: User;
  notificationService?: ToasterService;
  onUserDecision?: () => void;
}

const { PROFILE, PROJECT, PERMISSIONS } = CLIENT_PROFILE_TABS;

export const ClientUserTabContent: React.FC<ClientUserTabContentProps> = ({
  activeTab,
  clientId,
  user,
  notificationService = DefaultToasterService,
  onUserDecision
}) => {
  const { t } = useTranslation("clientUserSlidePanel");

  switch (activeTab) {
    case PROJECT:
      return (
        <Project
          clientId={clientId}
          userId={user.id}
          notificationService={notificationService}
        />
      );
    case PROFILE:
      return (
        <Profile
          clientId={clientId}
          userId={user.id}
          notificationService={notificationService}
        />
      );
    case PERMISSIONS:
      return (
        <ClientMemberPermissionsTab
          user={user}
          onUserDecision={onUserDecision}
        />
      );
    default:
      return <h3>{t("notFound")}</h3>;
  }
};
