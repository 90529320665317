import { OutputSelector, createSelector } from "reselect";

import { UserProfile } from "@arbolus-technologies/api";

import { CacheStateSelector } from "../CacheSelector";
import { CacheAppState, CacheReducerState } from "../models/definitions";

export const userAdminProfile = (): OutputSelector<
  CacheAppState,
  UserProfile,
  (res: CacheReducerState) => UserProfile
> =>
  createSelector<CacheAppState, CacheReducerState, UserProfile>(
    CacheStateSelector,
    (cacheReducerState) => cacheReducerState.userProfile
  );
