import { EditorState, getVisibleSelectionRect } from "draft-js";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { Subscription } from "rxjs";

import useTranscriptContext from "../../../../../../../contexts/transcript/UseTranscriptContext";
import { TranscriptHighLight } from "../../../../../../../models/transcript";
import { DraftJSHighlightEntity } from "../../../../../../../models/view/transcript";
import { DraftJSUtilService } from "../../../../../../../services";
import HighlightEvents, { TranscriptHighLightEvent } from "../HighlightEvents";
import HighlightPlugin from "./HighlightPlugin";

import { ToolBarStoreType } from ".";

interface ToolbarHolderProps {
  store: ToolBarStoreType;
  highlightMap: Map<string, TranscriptHighLight[]>;
}

interface ToolbarPosition {
  top: number;
  left: number;
}

const ToolbarHolder: React.FC<ToolbarHolderProps> = ({
  store,
  highlightMap
}): JSX.Element => {
  const [position, setPosition] = useState<ToolbarPosition | undefined>(
    undefined
  );

  const { editorState, setEditorState } = useTranscriptContext();
  const toolbarRef = useRef<HTMLDivElement>(null);
  const highlighEventSubscription = useRef<Subscription>();

  const getToolbarStyles = (): CSSProperties => {
    const selection = editorState!.getSelection();
    const selectedText = DraftJSUtilService.getSelectionText(editorState!);

    const isVisible =
      editorState!.getSelection() &&
      !selection.isCollapsed() &&
      selection.getHasFocus() &&
      selectedText.trim();

    const style: CSSProperties = { ...position };

    if (isVisible) {
      style.visibility = "visible";
      style.transform = "translate(-50%) scale(1)";
      style.transition = "transform 0.15s cubic-bezier(.3,1.2,.2,1)";
    } else {
      style.transform = "translate(-50%) scale(0)";
      style.visibility = "hidden";
    }

    return style;
  };

  useEffect(() => {
    const onSelectionChanged = (): void => {
      // need to wait a tick for window.getSelection() to be accurate
      // need zero delay setTimeout to run after browser "focus" action finishes
      setTimeout(() => {
        if (!toolbarRef.current) return;

        const editorRef = store.getItem("getEditorRef")!();
        if (!editorRef) return;

        // The editor root should be two levels above the node from getEditorRef
        const editorRoot = editorRef.editor.parentNode
          ?.parentNode as HTMLElement;

        const editorRootRect = editorRoot.getBoundingClientRect();

        const parentWindow =
          editorRoot.ownerDocument && editorRoot.ownerDocument.defaultView;
        const selectionRect = getVisibleSelectionRect(parentWindow || window);

        if (!selectionRect) return;

        // Offset to position toolbar instead positioning exactly on top of the selected text
        const extraTopOffset = -5;

        setPosition({
          top:
            editorRoot.offsetTop -
            toolbarRef.current?.offsetHeight +
            (selectionRect.top - editorRootRect.top) +
            extraTopOffset,
          left:
            editorRoot.offsetLeft +
            (selectionRect.left - editorRootRect.left) +
            selectionRect.width
        });
      });
    };

    const onOwnHighlightSelected = ({
      highlightId
    }: TranscriptHighLightEvent): void => {
      const currentSelection = editorState!.getSelection();
      const { startIndex, endIndex } = editorState!
        .getCurrentContent()
        .getEntity(highlightId)
        .getData() as DraftJSHighlightEntity;

      // Create custom selection with whole highlight
      const customSelection =
        DraftJSUtilService.createCustomSelectionWithSelection(
          currentSelection,
          startIndex,
          endIndex
        );

      // Force editor to new selection
      setEditorState(EditorState.forceSelection(editorState!, customSelection));
    };

    highlighEventSubscription.current = HighlightEvents.eventSubject.subscribe(
      onOwnHighlightSelected
    );
    store.subscribeToItem("selection", onSelectionChanged);
    return (): void => {
      store.unsubscribeFromItem("selection", onSelectionChanged);
      highlighEventSubscription.current?.unsubscribe();
    };
  }, [store, editorState, setEditorState]);

  return (
    <div
      className="highlight-toolbar"
      style={getToolbarStyles()}
      ref={toolbarRef}
    >
      <HighlightPlugin highlightMap={highlightMap} />
    </div>
  );
};

export default ToolbarHolder;
