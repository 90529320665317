import moment from "moment";
import React from "react";

import {
  PROJECT_CALENDAR_MONTH_HEADER_FORMAT,
  formatDate
} from "@arbolus-technologies/utils";

import { BaseEvent } from "../../../../../models/event";
import { UtilsService } from "../../../../../services";

interface MonthHeaderProps {
  isFirstEvent: boolean;
  event: BaseEvent;
}

const MonthHeader: React.FC<MonthHeaderProps> = ({
  isFirstEvent,
  event: { startTime }
}: MonthHeaderProps) => {
  const startMoment = moment(UtilsService.convertUTCToActiveZone(startTime));
  const formattedMonth = formatDate(
    startMoment.toDate(),
    PROJECT_CALENDAR_MONTH_HEADER_FORMAT
  );
  return (
    <div
      className="month-header"
      style={{ display: isFirstEvent ? "none" : "flex" }}
    >
      <h2>{formattedMonth}</h2>
    </div>
  );
};

export default MonthHeader;
