import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";

import {
  DiscoverFilterOperator,
  DiscoverFilterOption
} from "@arbolus-technologies/models/project";

import styles from "./FilterPill.module.scss";

interface FilterPillProps {
  option: DiscoverFilterOption;
  onRemove: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onToggleIncludeExclude: () => void;
  disablePillToggle?: boolean;
  flagIcon?: React.ReactNode;
  isEditable?: boolean;
  onEdit?: () => void;
}

export const FilterPill: React.FC<FilterPillProps> = ({
  option,
  onRemove,
  onToggleIncludeExclude,
  disablePillToggle = false,
  flagIcon = false,
  isEditable = false,
  onEdit
}) => (
  <div
    className={clsx(styles.pill, {
      [styles.excluded]: option.operator === DiscoverFilterOperator.Exclude
    })}
  >
    <span className={styles.pillText} title={option.label}>
      {flagIcon}
      {option.label}
    </span>
    {(!disablePillToggle || isEditable) && (
      <div className={styles.pillIcon}>
        {!disablePillToggle && (
          <Icon
            onClick={onToggleIncludeExclude}
            name={
              option.operator === DiscoverFilterOperator.Exclude
                ? "add_circle"
                : "do_not_disturb_on"
            }
            fontSize="16px"
          />
        )}
        {disablePillToggle && isEditable && (
          <Icon onClick={onEdit} name="edit" fontSize="16px" />
        )}
      </div>
    )}
    <div className={styles.closeIcon}>
      <Icon onClick={onRemove} name="close" fontSize="16px" />
    </div>
  </div>
);
