import { Icon } from "arbolus-ui-components";
import React, { useState } from "react";
import { Collapse } from "reactstrap";

import {
  BookmarkExpert,
  TRANSCRIPT_STATUS
} from "@arbolus-technologies/models/common";
import {
  CANOPY_DETAILS_ROUTE,
  CANOPY_SINGLE_QUESTION_ROUTE
} from "@arbolus-technologies/routes";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  BadgeWithIcon,
  LinkWrapper,
  UserPortrait,
  VideoPlayer
} from "@arbolus-technologies/ui/components";
import { displayUserName } from "@arbolus-technologies/utils";

import { BookmarkButton } from "../../BookmarkButton/BookmarkButton";
import { TranscriptStatusTag } from "../../TranscriptStatusTag/TranscriptStatusTag";
import { ResponseDate } from "./ResponseDate";
import { ResponseDateAndExpert } from "./ResponseDateAndExpert";

import styles from "./BookmarkAnswer.module.scss";

interface BookmarkAnswerProps {
  title: string;
  subtitle: string;
  badgeText: string;
  expert: BookmarkExpert;
  responseDate: Date;
  answerId: string;
  videoUrl: string;
  transcript: string;
  isSmartTranscript: boolean;
  handleOpenProfile: (id: string) => void;
  canopyId: string;
  questionId: string;
}
export const BookmarkAnswer: React.FC<BookmarkAnswerProps> = ({
  title,
  subtitle,
  expert,
  responseDate,
  badgeText,
  answerId,
  videoUrl,
  transcript,
  isSmartTranscript,
  handleOpenProfile,
  canopyId,
  questionId
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleTranscriptStatus = (): TRANSCRIPT_STATUS => {
    if (transcript === null) {
      return TRANSCRIPT_STATUS.NO_TRANSCRIPT;
    } else if (isSmartTranscript) {
      return TRANSCRIPT_STATUS.SMART_TRANSCRIPT;
    } else {
      return TRANSCRIPT_STATUS.AUTOMATIC_TRANSCRIPT;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.notCollapsedContainer}>
        <div className={styles.videoContainer}>
          <VideoPlayer src={videoUrl} size={225} />
        </div>
        <div className={styles.infoContainer}>
          <div className={styles.actionsContainer}>
            <LinkWrapper to={CANOPY_DETAILS_ROUTE(canopyId)}>
              <BadgeWithIcon
                icon={"topic"}
                text={badgeText}
                enableCursorPointer={true}
              />
            </LinkWrapper>
            <div className={styles.rightIcons}>
              <BookmarkButton answer={{ id: answerId, isBookmarked: true }} />
              <Icon
                name={isDrawerOpen ? "expand_less" : "expand_more"}
                fontSize="24px"
                color={ARBOLUS_COLORS.bColorBaseDark}
                onClick={() => setIsDrawerOpen((prevState) => !prevState)}
              />
            </div>
          </div>
          <LinkWrapper
            classnames={styles.titleLink}
            to={CANOPY_SINGLE_QUESTION_ROUTE(canopyId, questionId)}
          >
            <div className={styles.title}>{title}</div>
          </LinkWrapper>
          {isDrawerOpen ? (
            <>
              <div className={styles.subtitleIsOpen}>{subtitle}</div>
              <div className={styles.transcriptStatusTag}>
                <TranscriptStatusTag
                  transcriptStatus={handleTranscriptStatus()}
                />
              </div>
              <Collapse isOpen={isDrawerOpen}>
                <div className={styles.openContainer}>
                  <div className={styles.infoContainer}>
                    <span className={styles.transcript}>{transcript}</span>
                    <div className={styles.openExpertContainer}>
                      <div
                        className={styles.openUserPortraitContainer}
                        onClick={() => handleOpenProfile(expert.expertId)}
                      >
                        <UserPortrait user={expert} />
                      </div>
                    </div>
                    <ResponseDate responseDate={responseDate} />
                  </div>
                </div>
              </Collapse>
            </>
          ) : (
            <>
              <div className={styles.subtitle}>{transcript}</div>
              <ResponseDateAndExpert
                handleOpenProfile={handleOpenProfile}
                expertId={expert.expertId}
                imageUrl={expert.profileImageUrl}
                name={displayUserName(expert)}
                responseDate={responseDate}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
