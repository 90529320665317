import i18next from "i18next";
import * as Yup from "yup";

import { UserConstraints } from "../../../../../constants/validation";
import { UtilsService } from "../../../../../services";

export const UserDetailsValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(
      UserConstraints.MAX_FIRST_NAME_LENGTH,
      i18next.t("userProfilePage:firstNameMaxLengthError", {
        length: UserConstraints.MAX_FIRST_NAME_LENGTH
      })
    )
    .test(
      "firstName-valid",
      i18next.t("userProfilePage:urlValuesAreInvalid"),
      (val: string) => !UtilsService.isStringUrl(val)
    )
    .required(i18next.t("userProfilePage:firstNameRequiredError")),
  lastName: Yup.string()
    .max(
      UserConstraints.MAX_LAST_NAME_LENGTH,
      i18next.t("newUserPage:lastNameMaxLengthError", {
        length: UserConstraints.MAX_LAST_NAME_LENGTH
      })
    )
    .test(
      "lastName-valid",
      i18next.t("userProfilePage:urlValuesAreInvalid"),
      (val: string) => !UtilsService.isStringUrl(val)
    )
    .required(i18next.t("userProfilePage:lastNameRequiredError")),
  title: Yup.string()
    .max(
      UserConstraints.MAX_TITLE_LENGTH,
      i18next.t("userProfilePage:jobTitleMaxLengthError", {
        length: UserConstraints.MAX_TITLE_LENGTH
      })
    )
    .test(
      "title-valid",
      i18next.t("userProfilePage:urlValuesAreInvalid"),
      (val: string) => !UtilsService.isStringUrl(val)
    )
    .required(i18next.t("userProfilePage:jobTitleRequiredError")),
  phoneNumber: Yup.string()
    .test(
      "phone-valid",
      i18next.t("userProfilePage:phoneNumberInvalidError"),
      function validate(val: string) {
        return val ? UtilsService.validateContactNumber(`+${val}`) : true;
      }
    )
    .nullable()
});
