import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-media";

import { UIConstants } from "../../../../../constants";
import { TRANSCRIPT_HEADER_RESIZE_DEBOUNCE } from "../../../../../constants/timer";
import { TranscriptMode } from "../../../../../contexts/transcript/TranscriptContext";
import useTranscriptContext from "../../../../../contexts/transcript/UseTranscriptContext";
import { TranscriptService } from "../../../../../services/TranscriptService";
import { CIQAvatar } from "../../../../app/components";

const TranscriptHeader: React.FC = (): JSX.Element => {
  const transcriptHeaderRef = useRef<HTMLDivElement>(null);
  const headerResizeObserver = useRef<ResizeObserver>();
  const { mode, setTranscriptMode, highlightsCount, transcriptMeta } =
    useTranscriptContext();
  const {
    title,
    created,
    durationSeconds,
    clientLogoUrl,
    clientName,
    transcriptSpeakerCount
  } = transcriptMeta;

  const { t } = useTranslation("transcript");
  const matches = useMedia({ queries: UIConstants.APP_DEVICE_MEDIA_QUERIES });

  useEffect(() => {
    // attach transcript header element observer
    const transcriptHeaderElement = transcriptHeaderRef.current;
    if (transcriptHeaderElement) {
      headerResizeObserver.current = new ResizeObserver(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        _.debounce((entries: any) => {
          // eslint-disable-next-line no-restricted-syntax
          for (const entry of entries) {
            const { height, top } = entry.contentRect;

            // Transcript scroll element
            const transcriptScroll =
              document.getElementById("transcript-scroll");
            if (transcriptScroll) {
              const headerHeight = top + height;
              const topContainerHeight =
                UIConstants.UI_TRANSCRIPT_PAGE.TOP_CONTAINER_HEIGHT(matches);

              const bottomContainerHeight =
                UIConstants.UI_TRANSCRIPT_PAGE.BOTTOM_CONTAINER_HEIGHT(matches);

              const excessHeight =
                topContainerHeight + headerHeight + bottomContainerHeight;
              const heightStyle =
                UIConstants.UI_TRANSCRIPT_PAGE.CONTAINER_HEIGHT(excessHeight);
              transcriptScroll.style.maxHeight = heightStyle;
              transcriptScroll.style.height = heightStyle;
            }
          }
        }, TRANSCRIPT_HEADER_RESIZE_DEBOUNCE)
      );

      // Observe element changes
      headerResizeObserver.current?.observe(transcriptHeaderElement);
    }

    return (): void => {
      if (transcriptHeaderElement) {
        // Detach listener
        headerResizeObserver.current?.unobserve(transcriptHeaderElement);
      }
    };
  }, [matches]);

  const isHighlightsExist = highlightsCount > 0;
  const meetingDetails = TranscriptService.generateEventDetails(
    created,
    durationSeconds,
    transcriptSpeakerCount
  );

  return (
    <div className="event-details" ref={transcriptHeaderRef}>
      <div className="transcript-meta">
        <div className="partnership">
          <CIQAvatar username={clientName} profileImageUrl={clientLogoUrl} />
          <div className="arbolus-connect" />
        </div>

        <p>{meetingDetails}</p>
      </div>
      <h1 title={title}>{title}</h1>

      <div className="modes-container">
        <div
          className={clsx("mode-tag", {
            active: mode === TranscriptMode.FULL_TRANSCRIPT
          })}
          onClick={(): void => {
            setTranscriptMode(TranscriptMode.FULL_TRANSCRIPT);
          }}
        >
          <div className="mode-info">
            <span className="ciq-icon ciq-component-icon-ic-description" />
            <span>{t("fullTranscript")}</span>
          </div>
        </div>
        {isHighlightsExist && (
          <div
            className={clsx("mode-tag", {
              active: mode === TranscriptMode.HIGHLIGHTS
            })}
            onClick={(): void => {
              setTranscriptMode(TranscriptMode.HIGHLIGHTS);
            }}
          >
            <div className="mode-info">
              <span className="ciq-icon ciq-component-icon-ic-bookmarks" />
              <span>{t("highlights")}</span>
            </div>
            <span className="mode-value">{highlightsCount}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default TranscriptHeader;
