import React from "react";

import { PaginatedResponseWithStatus } from "@arbolus-technologies/api";
import {
  AngleModel,
  DO_NOT_CONTACT_STATUS
} from "@arbolus-technologies/models/common";
import { DiscoverExpert } from "@arbolus-technologies/models/project";
import { CheckboxWrapper } from "@arbolus-technologies/ui/components";

import { ExpertDiscoverCard } from "../ExpertCard/ExpertDiscoverCard";

interface ExpertListWithCheckboxesProps {
  handleSelectExpert: (expertId: string) => void;
  expertListPaginated: PaginatedResponseWithStatus<DiscoverExpert>;
  selectedExpertsIds: string[];
  shortlistedExpertsIds: string[];
  projectId: string;
  projectName: string;
  angles: AngleModel[];
}

export const ExpertListWithCheckboxes: React.FC<
  ExpertListWithCheckboxesProps
> = ({
  expertListPaginated,
  handleSelectExpert,
  selectedExpertsIds,
  shortlistedExpertsIds,
  projectId,
  projectName,
  angles
}) => (
  <>
    {expertListPaginated.items.map((expert) => (
      <CheckboxWrapper
        key={expert.id}
        isChecked={selectedExpertsIds.includes(expert.id)}
        onChange={() => handleSelectExpert(expert.id)}
        hidden={
          shortlistedExpertsIds.includes(expert.id) ||
          expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC
        }
      >
        <ExpertDiscoverCard
          key={expert.id}
          expert={expert}
          projectId={projectId}
          projectName={projectName}
          angles={angles}
        />
      </CheckboxWrapper>
    ))}
  </>
);
