import i18next from "i18next";
import Pusher, { Channel } from "pusher-js";

import { DefaultToasterService, IChatMessage } from "@arbolus-technologies/api";
import {
  OnlineMembers,
  UpdatedMember
} from "@arbolus-technologies/models/project";
import { ProjectNxStoreActions } from "@arbolus-technologies/stores/project";

import { pusherBufferedAuthManager } from "./PusherBufferedAuthManager";

export class ChatMessagingService {
  pusherInstance: Pusher;
  callback: unknown;
  dispatch: Function | null; // Add a dispatch property

  constructor(
    pusherAppKey: string,
    pusherAppCluster: string,
    dispatch: Function
  ) {
    this.pusherInstance = new Pusher(pusherAppKey, {
      cluster: pusherAppCluster,
      forceTLS: true,
      activityTimeout: 900000, // 15 seconds
      authorizer: pusherBufferedAuthManager.authorizer
    });
    this.dispatch = dispatch;
  }

  subscribeToChannel(channelName: string, chatId: string): void {
    const channel: Channel = this.pusherInstance.subscribe(channelName);

    channel.bind(
      "pusher:subscription_succeeded",
      (onlineMembers: OnlineMembers) => {
        if (!this.dispatch) return;
        this.dispatch(
          ProjectNxStoreActions.storeOnlineMembersByChat(
            channel.name,
            onlineMembers
          )
        );
      }
    );

    channel.bind("pusher:member_removed", (updatedMember: UpdatedMember) => {
      if (!this.dispatch) return;
      this.dispatch(
        ProjectNxStoreActions.updateOnlineMembersByChat(
          channel.name,
          updatedMember
        )
      );
    });

    channel.bind("pusher:member_added", (updatedMember: UpdatedMember) => {
      if (!this.dispatch) return;
      this.dispatch(
        ProjectNxStoreActions.updateOnlineMembersByChat(
          channel.name,
          updatedMember
        )
      );
    });

    channel.bind("pusher:subscription_error", () => {
      DefaultToasterService.showError(i18next.t("pusherSubscriptionError"));
    });

    channel.bind("new-message", (message: IChatMessage) => {
      if (typeof this.callback === "function") {
        this.callback(chatId, message);
      }
    });
  }

  unsubscribeFromChannel(channelName: string): void {
    if (this.pusherInstance) {
      this.pusherInstance.unsubscribe(channelName);
    }
  }

  setCallback(callback: unknown) {
    this.callback = callback;
  }
}
