import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { HR } from "@arbolus-technologies/ui/components";

import styles from "./Footer.module.scss";

interface FooterProps {
  confirmDisabled: boolean;
  onConfirm: () => void;
}
export const Footer: React.FC<FooterProps> = ({
  confirmDisabled,
  onConfirm
}) => {
  const { t } = useTranslation("assignExpertToProjectPanel");
  const dispatch = useDispatch();

  const handleCancel = () =>
    dispatch(PanelStoreActions.closePanel(PanelId.AssignExpertToProject));

  return (
    <div className={styles.container}>
      <HR />
      <div className={styles.buttonsContainer}>
        <Button text={t("cancel")} type="tertiary" onClick={handleCancel} />
        <Button
          text={t("confirm")}
          disabled={confirmDisabled}
          onClick={onConfirm}
        />
      </div>
    </div>
  );
};
