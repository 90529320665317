import React, { useCallback, useEffect, useState } from "react";

import {
  AllCanopy,
  CANOPY_ORDER_BY,
  CIQError,
  CanopyService,
  ErrorResponse,
  PaginatedRequest,
  SORT_DIRECTION
} from "@arbolus-technologies/api";
import { CanopyGrayBox } from "@arbolus-technologies/features/common";
import { CanopyPageType } from "@arbolus-technologies/models/canopy";
import { PROJECT_CANOPIES_ROUTE } from "@arbolus-technologies/routes";

interface ProjectCanopyProps {
  projectId: string;
  canopyService?: typeof CanopyService;
}

const queryParams: PaginatedRequest<CANOPY_ORDER_BY> = {
  limit: 3,
  offset: 0,
  orderBy: CANOPY_ORDER_BY.LAST_ANSWER_DATE,
  orderDirection: SORT_DIRECTION.DESCENDING
};

export const ProjectCanopy: React.FC<ProjectCanopyProps> = ({
  projectId,
  canopyService = CanopyService
}) => {
  const [canopies, setCanopies] = useState<AllCanopy[]>([]);
  const [isCanopiesLoading, setIsCanopiesLoading] = useState<boolean>(true);

  const getCanopies = useCallback(() => {
    canopyService
      .getAllCanopies(queryParams, undefined, undefined, projectId)
      .subscribe(
        (response) => {
          setCanopies(
            response.items.map((canopy) => ({
              id: canopy.id,
              status: canopy.status,
              title: canopy.title,
              lastAnswerDate: canopy.lastAnswerDate,
              expertCount: canopy.expertCount,
              created: canopy.created
            }))
          );
          setIsCanopiesLoading(false);
        },
        (error: ErrorResponse<CIQError>) => {
          console.error(error);
          setIsCanopiesLoading(false);
        }
      );
  }, [canopyService, projectId]);

  useEffect(() => {
    getCanopies();
  }, [getCanopies]);

  return (
    <CanopyGrayBox
      to={PROJECT_CANOPIES_ROUTE(projectId)}
      isCanopiesLoading={isCanopiesLoading}
      canopies={canopies}
      canopyPageType={CanopyPageType.ProjectDashboard}
    />
  );
};
