import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Subscription } from "rxjs";

import { ToasterService } from "@arbolus-technologies/api";

import { INBOX } from "../../../../../constants/navigation/authRoutes";
import { CIQError, ErrorResponse } from "../../../../../models/api";
import { ProjectSettingsUpdateRequest } from "../../../../../models/project";
import { UserNotificationSettingsUpdateRequest } from "../../../../../models/user";
import { UserService } from "../../../../../services";
import { AuthSelector, UserStoreActions } from "../../../../auth/store";
import NotificationSettingsList from "../../../../project/components/panels/notificationSettings/notificationSettingList/NotificationSettingsList";

const notification = new ToasterService();

const UserNotificationSettings: React.FC = (): JSX.Element => {
  const { t } = useTranslation("notificationSettings");
  const [isSaving, setIsSaving] = useState(false);
  const [isBannerDismiss, setIsBannerDismiss] = useState(false);

  const dispatch = useDispatch();

  const { dismissNotificationBanner, notificationSubscription } = useSelector(
    AuthSelector.authUserNotificationSettingsSelector()
  );

  const updateUserNotificationSettingsSubscription = useRef<Subscription>();

  const dismissBannerSubscription = useRef<Subscription>();

  useEffect(
    () => (): void => {
      updateUserNotificationSettingsSubscription.current?.unsubscribe();
      dismissBannerSubscription.current?.unsubscribe();
    },
    []
  );

  const updateUserNotificationSettings = (
    userNotificationSettings: UserNotificationSettingsUpdateRequest
  ): void => {
    setIsSaving(true);
    updateUserNotificationSettingsSubscription.current =
      UserService.updateUserNotificationSettings({
        ...userNotificationSettings,
        notificationSubscription: {
          ...userNotificationSettings.notificationSubscription!,
          MobileMessage: true
        }
      }).subscribe(
        () => {
          setIsSaving(false);
          dispatch(
            UserStoreActions.updateUserNotificationSettings(
              userNotificationSettings
            )
          );
          notification.showSuccess(t("profileSettingsSaveSuccess"));
        },
        (error: ErrorResponse<CIQError>) => {
          setIsSaving(false);
          notification.showError(error.message);
        }
      );
  };

  const bannerDismiss = (): void => {
    setIsBannerDismiss(true);
    dismissBannerSubscription.current =
      UserService.updateUserNotificationSettings({
        dismissNotificationBanner: true
      }).subscribe(
        () => {
          setIsBannerDismiss(false);
          dispatch(
            UserStoreActions.updateUserNotificationSettings({
              dismissNotificationBanner: true,
              notificationSubscription
            })
          );
        },
        (error: ErrorResponse<CIQError>) => {
          setIsBannerDismiss(false);
          notification.showError(error.message);
        }
      );
  };

  const handleNotificationSettingsUpdate = (
    userNotificationSettings: ProjectSettingsUpdateRequest
  ): void => {
    updateUserNotificationSettings({
      ...userNotificationSettings,
      dismissNotificationBanner
    });
  };

  return (
    <div className="user-notification-settings">
      {!dismissNotificationBanner && (
        <div className="user-info-container">
          <div className="header">
            <div className="bell-icon ciq-icon ciq-notification" />
            <h3>{t("howNotificationWorks")}</h3>
            <div
              className={clsx("ciq-icon ciq-close", {
                disabled: isBannerDismiss
              })}
              onClick={bannerDismiss}
            />
          </div>
          <div className="info-container">
            <p>
              <Trans
                i18nKey="notificationSettings:message_1"
                components={[<Link to={INBOX} key={1} />, <br key={1} />]}
              />
            </p>
          </div>
        </div>
      )}
      <NotificationSettingsList
        notificationSubscription={notificationSubscription}
        isLoading={isSaving}
        onUpdateSettings={handleNotificationSettingsUpdate}
      />
    </div>
  );
};

export default UserNotificationSettings;
