import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";

import { LinkNoBorder } from "@arbolus-technologies/ui/components";

import {
  BOOKMARKS_ROUTE,
  BOOKMARK_COLLECTIONS,
  BookmarksUrlParams
} from "../../Routes/bookmarksRoutes";
import { AddCollectionButton } from "../AddCollectionButton/AddCollectionButton";
import { ExportCollectionButton } from "../ExportCollectionButton/ExportCollectionButton";

import styles from "./CollectionPageActions.module.scss";

interface CollectionPageActionsProps {
  classnames?: string;
}

export const CollectionPageActions: React.FC<CollectionPageActionsProps> = ({
  classnames
}) => {
  const { t } = useTranslation("bookmarks");
  const { push } = useHistory();
  const { bookmarkCollectionId } = useParams<BookmarksUrlParams>();

  const goToCollectionPage = (id: string) => {
    push(BOOKMARKS_ROUTE(id));
  };

  return (
    <div className={clsx(styles.container, classnames)}>
      <LinkNoBorder
        name={t("backToCollections")}
        to={BOOKMARK_COLLECTIONS}
        leftIconName="chevron_left"
      />
      <div className={styles.rightSide}>
        <ExportCollectionButton bookmarkCollectionId={bookmarkCollectionId} />
        <AddCollectionButton onSuccess={goToCollectionPage} />
      </div>
    </div>
  );
};
