import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import { useTranslation } from "react-i18next";

import { CanopyQuestionPDF } from "@arbolus-technologies/api";
import { maxLengthString } from "@arbolus-technologies/utils";

import {
  MCQContainerPDF,
  expertNamePDF,
  expertSectionPDF,
  expertTitlePDF,
  multi,
  questionDetailsPDF,
  sectionPDF,
  titleQuestionPDF,
  transcriptPDF,
  videoPDFContainer
} from "./styles";

interface MultiChoicePDFViewProps {
  data: CanopyQuestionPDF;
}

export const MultiChoicePDFView: React.FC<MultiChoicePDFViewProps> = ({
  data
}) => {
  const { t } = useTranslation("PDF");
  const styles = StyleSheet.create({
    section: sectionPDF,
    title: titleQuestionPDF,
    expertSection: expertSectionPDF,
    expertName: expertNamePDF,
    expertTitle: expertTitlePDF,
    transcript: transcriptPDF,
    videoPDFContainer,
    MCQContainer: MCQContainerPDF,
    questionDetails: questionDetailsPDF,
    multi
  });

  return (
    <>
      <View style={styles.section}>
        <Text style={styles.title}>
          {data.sortOrder + 1}. {data.questionText}
        </Text>
        <Text style={styles.questionDetails}>{data.questionDetails}</Text>
      </View>
      {data.answers.map((e) => (
        <View style={styles.expertSection} key={e.expert.lastName}>
          <div style={{ display: "flex", flexDirection: "row", gap: "4px" }}>
            <Text style={styles.expertName}>
              {maxLengthString(
                `${e.expert.firstName} ${e.expert.lastName}`,
                30
              )}
            </Text>
            <Text style={styles.expertName}>-</Text>
            <Text style={styles.expertTitle}>
              {maxLengthString(e.expert.title, 80)}
            </Text>
          </div>

          <View style={styles.MCQContainer}>
            {e.multiChoiceAnswer!.map((f, index) => (
              <View key={index} style={styles.multi}>
                {f === "Other" && <Text>{t("other")}</Text>}
                <Text>{f === "Other" ? e.otherText : f}</Text>
              </View>
            ))}
          </View>
        </View>
      ))}
    </>
  );
};
