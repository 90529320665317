import { Observable } from "rxjs";

import { restService } from "@arbolus-technologies/api";

import { CLIENTS_API, MAX_PAGE_SIZE } from "../constants/api";
import {
  ApiCreatedResponse,
  ApiDeletedResponse,
  ApiNonPaginatedResponse,
  ApiPaginatedResponse,
  ApiSuccessResponse
} from "../models/api";
import {
  Client,
  ClientUsersGetRequest,
  UpdateEnquiriesRequest
} from "../models/client";
import { SavedExpert, SavedExpertStatusResponse } from "../models/expert";
import { Country } from "../models/meta";
import { ListProjectBase } from "../models/project";
import {
  ClientMember,
  CreateUserRequest,
  ListClientMember,
  UpdateClientRequest,
  UpdateUserRequest
} from "../models/user";

export const clientService = {
  getTeamMembers: (
    clientId: string,
    apiParams: ClientUsersGetRequest
  ): Observable<ApiPaginatedResponse<ListClientMember>> =>
    restService.get<ApiPaginatedResponse<ListClientMember>>(
      CLIENTS_API.GET_TEAM_MEMBERS(clientId),
      apiParams
    ),
  getTeamMembersProjects: (
    clientId: string,
    userId: string
  ): Observable<ApiNonPaginatedResponse<ListProjectBase>> =>
    restService.get<ApiNonPaginatedResponse<ListProjectBase>>(
      CLIENTS_API.GET_TEAM_MEMBERS_PROJECTS(clientId),
      {
        userId
      }
    ),
  getTeamMembersCandidateProjects: (
    clientId: string,
    userId: string
  ): Observable<ApiNonPaginatedResponse<ListProjectBase>> =>
    restService.get<ApiNonPaginatedResponse<ListProjectBase>>(
      CLIENTS_API.GET_TEAM_MEMBERS_CANDIDATE_PROJECTS(clientId),
      {
        userId
      }
    ),
  getFrequentUsedCountries: (
    clientId: string,
    limit = 3
  ): Observable<ApiNonPaginatedResponse<Country>> =>
    restService.get<ApiNonPaginatedResponse<Country>>(
      CLIENTS_API.GET_FREQUENT_USED_COUNTRIES(clientId),
      { limit }
    ),

  createUser: (
    clientId: string,
    createUser: CreateUserRequest
  ): Observable<ApiCreatedResponse> =>
    restService.post<ApiCreatedResponse>(
      CLIENTS_API.CREATE_USER(clientId),
      createUser
    ),

  updateUser: (
    clientId: string,
    userId: string,
    updateUser: UpdateUserRequest
  ): Observable<ApiSuccessResponse> =>
    restService.put<ApiSuccessResponse>(
      CLIENTS_API.UPDATE_USER(clientId, userId),
      updateUser
    ),

  getUser: (clientId: string, userId: string): Observable<ClientMember> =>
    restService.get<ClientMember>(CLIENTS_API.GET_USER(clientId, userId)),

  getClient: (clientId: string): Observable<Client> =>
    restService.get<Client>(CLIENTS_API.GET_CLIENT(clientId)),

  updateClient: (
    clientId: string,
    updateClient: UpdateClientRequest
  ): Observable<ApiSuccessResponse> =>
    restService.put<ApiSuccessResponse>(
      CLIENTS_API.CLIENT_UPDATE(clientId),
      updateClient
    ),

  createEnquiries: (
    updateEnquiry: UpdateEnquiriesRequest
  ): Observable<ApiSuccessResponse> =>
    restService.post<ApiSuccessResponse>(
      CLIENTS_API.UPDATE_ENQUIRIES(),
      updateEnquiry
    ),
  getSavedExperts: (
    clientId: string,
    searchTerm: string,
    filter = "",
    offset = 0,
    limit = MAX_PAGE_SIZE,
    orderBy = "",
    orderDirection = ""
  ): Observable<ApiPaginatedResponse<SavedExpert>> =>
    restService.get<ApiPaginatedResponse<SavedExpert>>(
      CLIENTS_API.GET_SAVED_EXPERTS(clientId),
      {
        searchTerm,
        filter,
        offset,
        limit,
        orderBy,
        orderDirection
      }
    ),
  getSavedExpertStatus: (
    clientId: string,
    expertId: string
  ): Observable<SavedExpertStatusResponse> =>
    restService.get<SavedExpertStatusResponse>(
      CLIENTS_API.GET_SAVED_EXPERT_STATUS(clientId),
      {
        expertId
      }
    ),
  removeSavedExpert: (
    clientId: string,
    expertId: string
  ): Observable<ApiDeletedResponse> =>
    restService.delete<ApiDeletedResponse>(
      CLIENTS_API.DELETE_SAVED_EXPERT(clientId),
      { expertId }
    )
};
