export enum STATUSES {
  SHORT_LIST = "ShortList",
  CANDIDATE = "Candidate",
  ACCEPT = "Accept",
  REJECT = "Reject"
}

export enum CALL_STATUSES {
  UNSCHEDULED = "Unscheduled",
  SCHEDULED = "Scheduled",
  CALL_DONE = "Done"
}

export enum SelectedFilterEnum {
  ANGLE_IDS = "angleIds",
  STATUSES = "statuses",
  CALL_STATUSES = "callStatuses"
}
