import clsx from "clsx";
import { Field, useField } from "formik";
import React from "react";
import { Button, FormGroup, Label } from "reactstrap";

import { CIQFormInput } from "../../../app/components";

interface QuestionItemProps {
  placeholder: string;
  label: string;
  name: string;
  onRemove?: () => void;
  value: string;
}

const QuestionItem: React.FC<QuestionItemProps> = ({
  label,
  name,
  placeholder,
  value,
  onRemove
}: QuestionItemProps) => {
  const [, questionFieldMeta] = useField<string>(name);

  return (
    <>
      {onRemove && (
        <Button
          className="question-delete"
          type="button"
          color="danger"
          size="sm"
          onClick={onRemove}
        >
          <span className="ciq-icon ciq-trash" />
        </Button>
      )}

      <FormGroup
        className={clsx({
          "is-invalid": questionFieldMeta.error && questionFieldMeta.touched
        })}
      >
        <Label>{label}</Label>
        <Field
          autoComplete="off"
          name={name}
          placeholder={placeholder}
          component={CIQFormInput}
          type="textarea"
          value={value}
          rows="3"
        />
        {questionFieldMeta.error && questionFieldMeta.touched && (
          <div className="invalid-feedback">{questionFieldMeta.error}</div>
        )}
      </FormGroup>
    </>
  );
};

export default QuestionItem;
