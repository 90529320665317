import clsx from "clsx";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { BookmarkCollectionsStoreActions } from "@arbolus-technologies/stores/bookmark-collections";

import { AddCollectionButton } from "../../Components/AddCollectionButton/AddCollectionButton";
import { BookmarkCollectionsList } from "../../Components/BookmarkCollectionsList/BookmarkCollectionsList";
import { useBookmarkTitle } from "../../Components/Hooks/useBookmarkTitle";

import styles from "./BookmarkCollectionsPage.module.scss";

export const BookmarkCollectionsPage: React.FC = () => {
  const { t } = useTranslation("bookmarkCollections");
  const dispatch = useDispatch();

  useBookmarkTitle();

  useEffect(
    () => () => {
      dispatch(BookmarkCollectionsStoreActions.listCollectionsReset());
    },
    [dispatch]
  );

  return (
    <div className={clsx("page-content", styles.whiteBackground)}>
      <div className={clsx("page-content-header", styles.pageHeader)}>
        <div className="top-container">
          <h1>{t("title")}</h1>
          <AddCollectionButton />
        </div>
      </div>
      <div className={styles.container}>
        <BookmarkCollectionsList />
      </div>
    </div>
  );
};
