import React from "react";
import { useTranslation } from "react-i18next";

import { arbolusLogoWithBackground } from "@arbolus-technologies/theme";

import { useChat } from "../../../../Contexts/ChatContext/ChatContext";
import { ChatListItem } from "../ChatListItem/ChatListItem";

import styles from "./ProjectChat.module.scss";

interface ProjectChatProps {
  onClick: () => void;
  isActive: boolean;
  isCurrentChatMemberOnline?: boolean;
}

export const ProjectChat: React.FC<ProjectChatProps> = ({
  onClick,
  isActive,
  isCurrentChatMemberOnline = false
}) => {
  const { t } = useTranslation("chat");
  const { client, unreadMessagesCount, projectChat } = useChat();

  const avatars = [
    { name: t("arbolusTeamAvatarName"), imageUrl: arbolusLogoWithBackground },
    { name: t("clientTeamAvatarName"), imageUrl: client.imageUrl }
  ];

  if (!projectChat) {
    return null;
  }

  return (
    <div className={styles.projectChat}>
      <ChatListItem
        name={t("projectChatTitle")}
        avatars={avatars}
        onClick={onClick}
        isActive={isActive}
        unreadCount={unreadMessagesCount[projectChat.id]}
        isCurrentChatMemberOnline={isCurrentChatMemberOnline}
      />
    </div>
  );
};
