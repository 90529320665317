import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { CustomerCompanyInfo } from "@arbolus-technologies/models/project";
import { EXPERT_PROFILE } from "@arbolus-technologies/routes";

import { MatchingFiltersWrapper } from "../MatchingFiltersWrapper/MatchingFiltersWrapper";

import styles from "./CustomersList.module.scss";

interface CustomersListProps {
  customers: CustomerCompanyInfo[];
  expertId: string;
}
export const CustomersList: React.FC<CustomersListProps> = ({
  customers,
  expertId
}) => {
  const { t } = useTranslation("discoverMatchingFiltersPanel");
  const hasCustomers = customers.length > 0;

  return (
    <>
      {hasCustomers && (
        <div className={styles.customers}>
          <div className={styles.customersHeader}>
            <h2 className={styles.customersTitle}>{t("customers")}</h2>
            <Link to={EXPERT_PROFILE(expertId)} target="_blank">
              <Button
                onClick={() => false}
                text={t("allCustomers")}
                endIcon="chevron_right"
                type="tertiary"
              />
            </Link>
          </div>
          <MatchingFiltersWrapper>
            <ul className={styles.customersList}>
              {customers.map((customer: CustomerCompanyInfo) => (
                <li key={customer.companyId} className={styles.customerName}>
                  {customer.companyName}
                </li>
              ))}
            </ul>
          </MatchingFiltersWrapper>
        </div>
      )}
    </>
  );
};
