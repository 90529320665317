import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  ButtonDropdown,
  CheckboxWrapper
} from "@arbolus-technologies/ui/components";

import styles from "./PipelineResultsAndActions.module.scss";

interface PipelineResultsAndActionsProps {
  totalSelectedExperts: number;
  totalExperts: number;
  isExportDisabled: boolean;
  isChangeStatusDisabled: boolean;
  handleSelectAllExperts: (selectAll: boolean) => void;
  onExport: (exportAll?: boolean) => void;
  openStatusChangeModal: () => void;
}

export const PipelineResultsAndActions: React.FC<
  PipelineResultsAndActionsProps
> = ({
  totalSelectedExperts,
  totalExperts,
  isExportDisabled,
  isChangeStatusDisabled,
  handleSelectAllExperts,
  onExport,
  openStatusChangeModal
}) => {
  const { t } = useTranslation("pipelineResultsAndActions");

  const isExpertSelected = totalSelectedExperts > 0;
  const isIndeterminate =
    isExpertSelected && totalSelectedExperts < totalExperts;
  const hasNoExperts = totalExperts === 0;
  const isDownloadDisabled = hasNoExperts || isExportDisabled;

  return (
    <div className={styles.container}>
      <CheckboxWrapper
        isChecked={isExpertSelected}
        onChange={() => handleSelectAllExperts(totalSelectedExperts === 0)}
        indeterminate={isIndeterminate}
        isDisabled={hasNoExperts}
      >
        <p
          className={styles.experts}
          dangerouslySetInnerHTML={{
            __html: t("expertsSelected", { count: totalSelectedExperts })
          }}
        />
      </CheckboxWrapper>
      <div className={styles.btnContainer}>
        <Button
          endIcon="published_with_changes"
          onClick={openStatusChangeModal}
          disabled={isChangeStatusDisabled}
          type="secondary"
          text={t("changeStatus")}
        />
        <ButtonDropdown
          text={t("action")}
          options={[
            {
              name: t("exportPrimary"),
              onClick: () => onExport(),
              iconName: "file_download"
            },
            {
              name: t("exportAll"),
              onClick: () => onExport(true),
              iconName: "file_download"
            }
          ]}
          disabled={isDownloadDisabled}
        />
      </div>
    </div>
  );
};
