import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { PROJECT_MEMBER_PANEL_ROUTE } from "../../../../../constants/navigation/panelRoutes";
import AdminCard from "../../card/AdminCard";

interface TeamCardProps {
  title: string;
  membersCount: number;
}

type TeamCardPropsIntersectProps = TeamCardProps & WithTranslation;

const TeamCard = ({
  title,
  membersCount,
  t
}: TeamCardPropsIntersectProps): JSX.Element => {
  const history = useHistory();

  return (
    <AdminCard
      title={title}
      onCardClick={(): void => {
        history.push(PROJECT_MEMBER_PANEL_ROUTE());
      }}
    >
      {t("colleague", { count: membersCount })}
    </AdminCard>
  );
};

export default withTranslation("projectBasePage")(TeamCard);
