export enum EXPERT_STATUS {
  PENDING = "Pending",
  ACCEPT = "Accept",
  REJECT = "Reject"
}

export enum REFERRAL_STATES {
  CANDIDATE = "Candidate",
  ACCEPT = "Accept",
  REJECT = "Reject",
  ALLEXPERTS = "AllExperts"
}

export enum EXPERT_FOLLOW_UP_STEPS {
  ENGAGE_EXPERT = "EngageExpert",
  SEND_ADDITIONAL_QUESTIONS = "SendAdditionalQuestions"
}

export enum ExpertRateCardType {
  AdHoc = "AdHoc",
  Package = "Package"
}

export enum CONTACT_TYPE_FILTERS_ENUM {
  ALL_CONTACT_TYPES = "AllStatus",
  UN_CONTACTED = "Uncontacted",
  CONTACTED = "Contacted",
  FIRST_FOLLOW_UP = "FirstFollowUp",
  SECOND_FOLLOW_UP = "SecondFollowUp",
  REPLIED = "Replied",
  REJECTED_DECLINED = "RejectedDeclined"
}

export enum CONTACT_METHOD_FILTERS_ENUM {
  ALL_CONTACT_METHODS = "AllContactMethods",
  EMAIL_AND_PHONE = "EmailAndPhone",
  EMAIL = "Email",
  PHONE = "Phone",
  NO_CONTACT_INFO = "NoContactMethod"
}

export enum CONTACT_TYPE_ENUM {
  UN_CONTACTED = "Uncontacted",
  CONTACTED_BY_PHONE = "ContactedByPhone",
  CONTACTED_BY_EMAIL = "ContactedByEmail",
  FIRST_FOLLOW_UP_BY_PHONE = "FirstFollowUpByPhone",
  FIRST_FOLLOW_UP_BY_EMAIL = "FirstFollowUpByEmail",
  SECOND_FOLLOW_UP_BY_PHONE = "SecondFollowUpByPhone",
  SECOND_FOLLOW_UP_BY_EMAIL = "SecondFollowUpByEmail",
  REPLIED = "Replied",
  REJECTED_DECLINED = "RejectedDeclined"
}

export enum EXPERT_TYPE {
  EXTERNAL = "External",
  INTERNAL = "Internal"
}

export enum ExternalExpertFormEnum {
  PROJECT_PIPELINE_STATUS = "projectPipelineStatus",
  PROJECT_PIPELINE_STATUS_COMMENT = "projectPipelineStatusComment",
  PROJECT_PIPELINE_EMAILS = "projectPipelineEmails",
  CUSTOM_EMAIL = "customEmail",
  PROJECT_PIPELINE_PHONES = "projectPipelinePhones",
  CUSTOM_PHONE = "customPhone",
  RATE_CARD = "rateCard",
  RATE_CARD_ID = "rateCard.id",
  RATE_CARD_EXPERIENCE_LEVEL = "rateCard.experienceLevel",
  RATE_CARD_ISO_CURRENCY_CODE = "rateCard.isoCurrencyCode",
  RATE_CARD_PRICE = "rateCard.price"
}
