import { Button, Icon } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { HR } from "@arbolus-technologies/ui/components";

import styles from "../AssignExpertToProjectPanel.module.scss";

interface HeaderProps {
  isAngleStep: boolean;
  setIsAngleStep: () => void;
}
export const Header: React.FC<HeaderProps> = ({
  isAngleStep,
  setIsAngleStep
}) => {
  const { t } = useTranslation("assignExpertToProjectPanel");
  const dispatch = useDispatch();

  const closePanel = () =>
    dispatch(PanelStoreActions.closePanel(PanelId.AssignExpertToProject));

  return (
    <div className={styles.headerContainer}>
      <div className={styles.contentContainer}>
        <div>
          {isAngleStep && (
            <Button
              text={t("back")}
              type="tertiary"
              startIcon="chevron_left"
              onClick={setIsAngleStep}
            />
          )}
          <h2 className={styles.title}>
            {t(isAngleStep ? "angleTitle" : "projectTitle")}
          </h2>
        </div>
        <Icon name="close" fontSize="24" onClick={closePanel} />
      </div>
      <HR />
    </div>
  );
};
