/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";

import { CIQUserFeatures } from "../../../../models/view/user";
import { AuthSelector } from "../../../auth/store";

interface FeatureManagerProps {
  featureName: keyof CIQUserFeatures;
  children: React.ReactNode;
}

const FeatureProtectedElement: React.FC<FeatureManagerProps> = ({
  featureName,
  children
}) => {
  const features = useSelector(AuthSelector.authClientUserFeaturesSelector());
  const featureEnabled = _.get(features, featureName);

  return <>{featureEnabled && children}</>;
};

export default FeatureProtectedElement;
