import React, { useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import {
  ChatListResponse,
  ChatListResponseItem
} from "@arbolus-technologies/api";
import { useGetChatList } from "@arbolus-technologies/features/projects";
import { DatabaseChatType } from "@arbolus-technologies/models/project";
import { PROJECT_CHAT_BASE } from "@arbolus-technologies/routes";
import {
  arbolusLogoWithBackground,
  businessIcon
} from "@arbolus-technologies/theme";

import { PROJECT_TAB_ROUTES } from "../../../../../constants/navigation/projectTabRoutes";
import Card from "../../card/Card";
import { ChatCardRow } from "./ChatCardRow";

interface ChatCardProps {
  projectId: string;
  navigateToAnotherTab: (tabRouteName: string) => void;
  goToChat: (projectId: string, chatId: string) => void;
}
type ChatCardIntersectProps = ChatCardProps & WithTranslation;

const CHAT_DETAILS_BY_TYPE = {
  [DatabaseChatType.ExpertSupport]: {
    name: "Arbolus Team",
    imageUrl: arbolusLogoWithBackground
  },
  [DatabaseChatType.Workspace]: {
    name: "Project Chat",
    imageUrl: businessIcon
  },
  [DatabaseChatType.Angle]: {
    // Not needed here - dashboard only used by experts
    name: "",
    imageUrl: ""
  },
  [DatabaseChatType.ClientSupport]: {
    // Ditto
    name: "",
    imageUrl: ""
  }
};

const ChatCard = (props: ChatCardIntersectProps): JSX.Element => {
  const { projectId, navigateToAnotherTab, goToChat, t } = props;

  const [chats, setChats] = useState<ChatListResponse>();
  const { isLoading } = useGetChatList(projectId, (chats: ChatListResponse) =>
    setChats(chats)
  );

  const handleChatClick = (id: string) => goToChat(projectId, id);

  const handleNavigateToChat = (): void => {
    const nextTabRoute = PROJECT_TAB_ROUTES[PROJECT_CHAT_BASE];
    navigateToAnotherTab(nextTabRoute.route(projectId));
  };

  return (
    <Card
      loading={isLoading}
      title={t("chat")}
      onTitleClick={handleNavigateToChat}
    >
      {chats?.items.map((chat: ChatListResponseItem) => (
        <ChatCardRow
          key={chat.chatId}
          id={chat.chatId}
          name={CHAT_DETAILS_BY_TYPE[chat.chatType].name}
          imageUrl={CHAT_DETAILS_BY_TYPE[chat.chatType].imageUrl}
          hasUnreadMessages={false}
          handleClick={() => handleChatClick(chat.chatId)}
        />
      ))}
    </Card>
  );
};

export default withTranslation("projectBasePage")(ChatCard);
