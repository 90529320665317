import React from "react";
import { Control, Controller, FieldError } from "react-hook-form";
import { Label } from "reactstrap";

import { TiptapEditorMemoized } from "../TiptapEditor/TiptapEditor";

import styles from "./TiptapEditorController.module.scss";

interface TiptapEditorControllerProps {
  control: Control<any>;
  placeholder?: string;
  name: string;
  error?: FieldError;
  disabled?: boolean;
}

export const TiptapEditorController: React.FC<TiptapEditorControllerProps> = ({
  control,
  placeholder = "",
  name,
  error,
  disabled = false
}): JSX.Element => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, onBlur, value } }) => (
      <div>
        <TiptapEditorMemoized
          placeholder={placeholder}
          onChange={(content: string): void => {
            onChange(content);
          }}
          onBlur={onBlur}
          initialEditorState={value}
          disabled={disabled}
          hasError={!!error}
        />
        <div className={styles.errorContainer}>
          {error && (
            <Label className={styles.errorMessage}>{error.message}</Label>
          )}
        </div>
      </div>
    )}
  />
);
