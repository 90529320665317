import clsx from "clsx";
import React, { useEffect, useState } from "react";

import styles from "./RadioChoice.module.scss";

export interface RadioOption<T> {
  title: string;
  subtitle?: string;
  value: T;
}

interface RadioChoiceProps<T extends string | boolean> {
  onChange: (value: T) => void;
  defaultValue?: T;
  radioOption: RadioOption<T>;
}

export const RadioChoice = <T extends string | boolean>({
  onChange,
  defaultValue,
  radioOption
}: RadioChoiceProps<T>): JSX.Element => {
  const [active, setActive] = useState(defaultValue);
  const isActive = (value: T) => active === value;

  useEffect(() => {
    setActive(defaultValue);
  }, [defaultValue]);

  return (
    <div
      className={clsx(
        styles.optionContainer,
        isActive(radioOption.value) && styles.active
      )}
      onClick={() => {
        setActive(radioOption.value);
        onChange(radioOption.value);
      }}
    >
      <div className={styles.buttonContainer}>
        <div
          className={clsx(
            styles.button,
            isActive(radioOption.value) && styles.activeButton
          )}
        />
      </div>
      <div className={styles.infoContainer}>
        <span className={styles.title}>{radioOption.title}</span>
        {radioOption.subtitle && (
          <span className={styles.subtitle}>{radioOption.subtitle}</span>
        )}
      </div>
    </div>
  );
};
