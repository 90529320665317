import i18next from "i18next";
import * as Yup from "yup";

import { Client } from "@arbolus-technologies/api";

import {
  INTERNAL_COMMENT_MAX_NOTE_LENGTH,
  INTERNAL_COMMENT_MAX_TITLE_LENGTH
} from "../../../helpers/constants";
import {
  INTERNAL_COMMENT_COMMENT,
  INTERNAL_COMMENT_SELECT_CLIENT,
  INTERNAL_COMMENT_TITLE
} from "../../../helpers/types";

export const NewCommentSchema = Yup.object().shape({
  [INTERNAL_COMMENT_TITLE]: Yup.string()
    .required(i18next.t("createNewComment:titleRequired"))
    .max(
      INTERNAL_COMMENT_MAX_TITLE_LENGTH,
      i18next.t("createNewComment:titleLimitError", {
        length: INTERNAL_COMMENT_MAX_TITLE_LENGTH
      })
    ),
  [INTERNAL_COMMENT_COMMENT]: Yup.string()
    .required(i18next.t("createNewComment:noteRequired"))
    .max(
      INTERNAL_COMMENT_MAX_NOTE_LENGTH,
      i18next.t("createNewComment:noteMaxLimit", {
        length: INTERNAL_COMMENT_MAX_NOTE_LENGTH
      })
    ),
  [INTERNAL_COMMENT_SELECT_CLIENT]: Yup.object<Client>()
    .required(i18next.t("createNewComment:clientRequired"))
    .nullable()
});
