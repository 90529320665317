import {
  DefaultToasterService,
  ExpertService,
  ToasterService
} from "@arbolus-technologies/api";
import { DangerouslySetInnerHTML } from "@arbolus-technologies/ui/components";
import { Button, Flex, Form, Input, Modal } from "antd";
import { ChangeEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { LoginStoreActions } from "../../../../auth/store";
interface ExpertDeactivationProps {
  isActive: boolean;
  expertName: string;
  expertId: string;
  notificationService?: ToasterService;
}

const ExpertDeactivation: React.FC<ExpertDeactivationProps> = ({
  isActive,
  expertName,
  expertId,
  notificationService = DefaultToasterService
}: ExpertDeactivationProps) => {
  const { t } = useTranslation("expertDeactivation");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [validationInput, setValidationInput] = useState("");
  const showModal = useCallback(() => setIsModalOpen(true), []);
  const hideModal = useCallback(() => setIsModalOpen(false), []);

  const dispatch = useDispatch();

  const handleValidationChange = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setValidationInput(event.target.value);
  };

  const handleDeactivateAccount = (): void => {
    ExpertService.deactivateExpert(expertId).subscribe({
      next: () => {
        notificationService.showSuccess(t("expertDeactivationSuccess"));
        hideModal();
        dispatch(LoginStoreActions.logoutUser());
      },
      error: (error) => {
        notificationService.showApiErrors(error);
      }
    });
  };

  const modalFooter = [
    <Button key="back" onClick={hideModal}>
      {t("expertDeactivationModalCancel")}
    </Button>,
    <Button
      key="remove"
      type="primary"
      disabled={validationInput !== expertName}
      danger
      onClick={handleDeactivateAccount}
    >
      {t("expertDeactivationModalConfirm")}
    </Button>
  ];
  return (
    <div className="user-deactivation">
      <div className="middle-container">
        <div className="section-description">
          <h3>{t("expertDeactivationTitle")}</h3>
          <p>{t("expertDeactivationMessage1")}</p>
          <p>{t("expertDeactivationMessage2")}</p>
        </div>
      </div>
      <Flex justify="flex-end">
        <Button
          // @ts-ignore
          onClick={showModal}
          danger
          type="primary"
          size="large"
          disabled={!isActive}
        >
          {t("expertDeactivationTitle")}
        </Button>
      </Flex>
      <Modal
        title={t("expertDeactivationModalTitle")}
        open={isModalOpen}
        onOk={hideModal}
        onCancel={hideModal}
        footer={modalFooter}
      >
        <span>{t("expertDeactivationModalMessage")}</span>

        <Form layout="vertical">
          <DangerouslySetInnerHTML
            text={t("expertDeactivationModalInputLabel", { expertName })}
          />
          <Input onChange={handleValidationChange} value={validationInput} />
        </Form>
      </Modal>
    </div>
  );
};

export default ExpertDeactivation;
