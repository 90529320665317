import { ICellRendererParams } from "ag-grid-community";
import React from "react";
import { Link } from "react-router-dom";

import { ListTranscriptModel } from "@arbolus-technologies/api";
import { PROJECT_TRANSCRIPT } from "@arbolus-technologies/routes";
import { Avatar } from "@arbolus-technologies/ui/components";

import styles from "./TranscriptsTable.module.scss";

type TranscriptNameCellRendererProps = ICellRendererParams<
  ListTranscriptModel,
  never,
  never
> & {
  projectId: string;
};

export const TranscriptNameRenderer: React.FC<
  TranscriptNameCellRendererProps
> = ({ projectId, data }) => {
  const expertName = `${data?.expert?.firstName} ${data?.expert?.lastName}`;
  return (
    <Link
      to={PROJECT_TRANSCRIPT(projectId, data?.id!)}
      className={styles.transcriptCell}
    >
      <Avatar
        avatar={{ name: expertName, imageUrl: data?.expert?.profileImageUrl }}
      />
      <p className={styles.transcriptName} title={data?.name}>
        {data?.name}
      </p>
    </Link>
  );
};
