import React from "react";
import { useTranslation } from "react-i18next";

import { ListProjectBase } from "@arbolus-technologies/api";

import { ProjectList } from "./ProjectList";

import styles from "./Projects.module.scss";

export interface ProjectsProps {
  activeProjects: ListProjectBase[];
  archiveProjects: ListProjectBase[];
}
export const Projects: React.FC<ProjectsProps> = ({
  activeProjects,
  archiveProjects
}) => {
  const { t } = useTranslation("clientUserProfilePanel");

  const hasActiveProjects = activeProjects.length > 0;
  const hasArchiveProjects = archiveProjects.length > 0;
  const hasProjects = hasActiveProjects || hasArchiveProjects;

  return (
    <div className={styles.collapsibleWrapper}>
      {hasActiveProjects && <ProjectList projects={activeProjects} isActive />}
      {hasArchiveProjects && (
        <ProjectList projects={archiveProjects} isActive={false} />
      )}
      {!hasProjects && <span>{t("noProjects")}</span>}
    </div>
  );
};
