import clsx from "clsx";
import React from "react";
import { Input, Label } from "reactstrap";

import styles from "./CheckboxWrapper.module.scss";

interface CheckboxWrapperProps {
  children: JSX.Element;
  isChecked: boolean;
  isDisabled?: boolean;
  hidden?: boolean;
  onChange: (item: unknown) => void;
  onBlur?: (item: unknown) => void;
  isInvalid?: boolean;
  indeterminate?: boolean;
}

export const CheckboxWrapper: React.FC<CheckboxWrapperProps> = ({
  children,
  isChecked,
  isDisabled = false,
  hidden = false,
  onChange,
  onBlur,
  isInvalid,
  indeterminate = false
}) => (
  <div className={styles.container}>
    <Label
      check
      className={clsx(styles.label, {
        [styles.hidden]: hidden,
        [styles.disabled]: isDisabled,
        [styles.indeterminate]: indeterminate
      })}
    >
      <Input
        type="checkbox"
        checked={isChecked}
        onChange={(item): void => onChange(item)}
        disabled={isDisabled || hidden}
        onBlur={(item): void => onBlur && onBlur(item)}
        invalid={isInvalid}
      />
      <span className={styles.checkmark} />
    </Label>
    {children}
  </div>
);
