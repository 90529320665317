import i18next from "i18next";

import {
  ALLOWED_FILE_TYPES,
  MAXIMUM_FILE_NAME_LENGTH,
  MAXIMUM_FILE_UPLOADING_SIZE
} from "@arbolus-technologies/models/documents";
import { Result, isFileExtensionAllowed } from "@arbolus-technologies/utils";

export class DocumentUtils {
  isFileAllowed(file: File, allowedFileTypes?: ReadonlyArray<string>): Result {
    if (
      !isFileExtensionAllowed(file.name, allowedFileTypes ?? ALLOWED_FILE_TYPES)
    ) {
      return {
        ok: false,
        error: i18next.t("documentValidation:unsupportedFileType")
      };
    }

    if (file.size >= MAXIMUM_FILE_UPLOADING_SIZE) {
      return { ok: false, error: i18next.t("documentValidation:sizeTooLarge") };
    }

    if (file.name.length >= MAXIMUM_FILE_NAME_LENGTH) {
      return { ok: false, error: i18next.t("documentValidation:nameTooLong") };
    }

    return { ok: true, value: true };
  }
}

export const DefaultDocumentUtils = new DocumentUtils();
