import clsx from "clsx";
import React, { ReactNode } from "react";
import { isMobile } from "react-device-detect";

import { MobileHeader } from "./MobileHeader/MobileHeader";

import styles from "./Header.module.scss";

export interface HeaderProps {
  title: string;
  goBackRoute?: string;
  hideBorder?: boolean;
  children?: ReactNode;
  classnames?: string;
  skipMobile?: boolean;
  isFixed?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  title,
  goBackRoute,
  children,
  classnames,
  hideBorder = false,
  skipMobile = false,
  isFixed = false
}) => {
  if (isMobile && !skipMobile && goBackRoute) {
    return (
      <MobileHeader goBackRoute={goBackRoute} classnames={classnames}>
        {children}
      </MobileHeader>
    );
  }
  return (
    <div
      className={clsx(
        styles.container,
        {
          [styles.showBorder]: !hideBorder,
          [styles.isFixed]: isFixed
        },
        classnames
      )}
    >
      <h2 className={styles.title}>{title}</h2>
      {children}
    </div>
  );
};
