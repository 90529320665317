import React, { useState } from "react";
import { FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useArbolusTracking } from "../../../../Mixpanel/useArbolusTracking";
import { mixPanelTrackerClick } from "../../SimpleCanopyCreationData";
import {
  ICanopyCreationType,
  ISimpleCanopyCreationForm
} from "../../SimpleCanopyCreationProvider";
import { SelectableBox } from "../SelectableBox";

import styles from "./SelectableBoxList.module.scss";

interface SelectableBoxListProps {
  selectedValue: ICanopyCreationType;
  selectedTemplate?: string;
  handleSelectedTemplate: (value: string) => void;
  handleSelectedValue: (value: ICanopyCreationType) => void;
  errors: FieldErrors<ISimpleCanopyCreationForm>;
}

export const SelectableBoxList: React.FC<SelectableBoxListProps> = ({
  selectedValue,
  handleSelectedValue,
  selectedTemplate,
  handleSelectedTemplate,
  errors
}) => {
  const { t } = useTranslation("simpleCanopyCreationProvider");
  const { trackClick } = useArbolusTracking();

  const [selectableBoxSelected, setSelectableBoxSelected] =
    useState<ICanopyCreationType>(selectedValue);
  const [selectableTemplate, setSelectableTemplate] = useState<
    string | undefined
  >(selectedTemplate);

  const handleOnClick = (canopyCreationType: ICanopyCreationType) => {
    trackClick(mixPanelTrackerClick(canopyCreationType));
    setSelectableBoxSelected(canopyCreationType);
    handleSelectedValue(canopyCreationType);
  };

  const handleClickOnTemplate = (canopyTemplate: string) => {
    setSelectableTemplate(canopyTemplate);
    handleSelectedTemplate(canopyTemplate);
  };

  return (
    <div className={styles.container}>
      <SelectableBox
        title={t("fromScratch")}
        subtitle={t("scratchSubtitle")}
        canopyCreationType={ICanopyCreationType.MANUAL}
        handleOnClick={handleOnClick}
        active={selectableBoxSelected === ICanopyCreationType.MANUAL}
        firstIcon="add"
        selectedTemplate={selectableTemplate}
        handleOnCanopyTemplateTypeClick={handleClickOnTemplate}
        errors={errors}
      />
      <SelectableBox
        title={t("fromAI")}
        subtitle={t("AIsubtitle")}
        canopyCreationType={ICanopyCreationType.CANOPAI}
        handleOnClick={handleOnClick}
        active={selectableBoxSelected === ICanopyCreationType.CANOPAI}
        firstIcon="smart_toy"
        selectedTemplate={selectableTemplate}
        handleOnCanopyTemplateTypeClick={handleClickOnTemplate}
        errors={errors}
      />
      <SelectableBox
        title={t("template")}
        subtitle={t("templateSubtitle")}
        canopyCreationType={ICanopyCreationType.TEMPLATE}
        handleOnClick={handleOnClick}
        active={selectableBoxSelected === ICanopyCreationType.TEMPLATE}
        firstIcon="article"
        selectedTemplate={selectableTemplate}
        handleOnCanopyTemplateTypeClick={handleClickOnTemplate}
        errors={errors}
      />
    </div>
  );
};
