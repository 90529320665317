import React from "react";

import { PaginatedResponseWithStatus } from "@arbolus-technologies/api";
import { AngleModel } from "@arbolus-technologies/models/common";
import { DiscoverExpert } from "@arbolus-technologies/models/project";

import { ExpertDiscoverCard } from "../ExpertCard/ExpertDiscoverCard";

interface ExpertListWithoutCheckboxesProps {
  expertListPaginated: PaginatedResponseWithStatus<DiscoverExpert>;
  projectId: string;
  projectName: string;
  angles: AngleModel[];
}

export const ExpertListWithoutCheckboxes: React.FC<
  ExpertListWithoutCheckboxesProps
> = ({ expertListPaginated, projectId, projectName, angles }) => (
  <>
    {expertListPaginated.items.map((expert) => (
      <ExpertDiscoverCard
        key={expert.id}
        expert={expert}
        projectId={projectId}
        projectName={projectName}
        angles={angles}
      />
    ))}
  </>
);
