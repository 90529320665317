import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import {
  DraftBlockType,
  DraftEditorCommand,
  Editor,
  EditorState,
  RichUtils,
  getDefaultKeyBinding
} from "draft-js";
import React, { useEffect, useRef, useState } from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { emptyParagraphTag, utilService } from "@arbolus-technologies/utils";

import styles from "./RichTextEditor.module.scss";

const INLINE_STYLES = [
  { label: "Bold", style: "BOLD", iconName: "format_bold" },
  { label: "Italic", style: "ITALIC", iconName: "format_italic" },
  { label: "Underline", style: "UNDERLINE", iconName: "format_underlined" }
];

const BLOCK_TYPES = [
  {
    label: "UL",
    style: "unordered-list-item",
    iconName: "format_list_bulleted"
  },
  { label: "OL", style: "ordered-list-item", iconName: "format_list_numbered" }
];

interface RichTextProps {
  onChange: (content: string) => void;
  editorState: string;
  placeholder?: string;
  onBlur?: (e: React.SyntheticEvent) => void;
  disabled?: boolean;
  isMessageSending?: boolean;
  maxHeight?: string;
  onTrackRichText?: () => void;
}

export const RichTextEditor: React.FC<RichTextProps> = ({
  editorState: initialEditorState,
  onBlur,
  onChange,
  placeholder,
  disabled = false,
  isMessageSending = false,
  maxHeight,
  onTrackRichText
}) => {
  const [editorState, setEditorState] = useState<EditorState>(
    utilService.parseHTMLToRichText(initialEditorState)
  );
  const [changed, setChanged] = useState<boolean>(false);
  const editorRef = useRef<Editor>(null);

  useEffect(() => {
    if (isMessageSending) {
      setEditorState(EditorState.createEmpty());
    }
  }, [initialEditorState, isMessageSending]);

  const handleKeyCommand = (command: string): "handled" | "not-handled" => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const mapKeyToEditorCommand = (
    e: React.KeyboardEvent<{}>
  ): DraftEditorCommand | null => {
    const isTabKeyPressed = e.keyCode === 9;

    // prevent tab to focus on next element when block type(ordered list and unordered list) styling is selected
    if (isTabKeyPressed) {
      const newEditorState = RichUtils.onTab(e, editorState, 4 /* maxDepth */);
      if (newEditorState !== editorState) {
        setEditorState(newEditorState);
      }
      return null;
    }
    return getDefaultKeyBinding(e);
  };

  const toggleBlockType = (
    e: React.MouseEvent<HTMLSpanElement>,
    blockType: DraftBlockType
  ) => {
    e.preventDefault();
    const editorObject = RichUtils.toggleBlockType(editorState, blockType);
    handleEditorOptionApply(editorObject);
    editorRef.current?.focus();
  };

  const toggleInlineStyle = (
    e: React.MouseEvent<HTMLSpanElement>,
    inlineStyle: string
  ) => {
    e.preventDefault();
    const editorObject = RichUtils.toggleInlineStyle(editorState, inlineStyle);
    handleEditorOptionApply(editorObject);
    editorRef.current?.focus();
  };

  const handleEditorOptionApply = (editorObject: EditorState): void => {
    setEditorState(editorObject);
    const parsedContent = utilService.parseRichTextToHTML(editorObject);

    const isBlacklisted = emptyParagraphTag === parsedContent.trim();

    setChanged(parsedContent.length > 10);
    onChange(isBlacklisted ? "" : parsedContent);
  };

  const handleOnChange = (editorObject: EditorState): void => {
    handleEditorOptionApply(editorObject);
  };

  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <div
      className={clsx(
        "rt-input",
        styles.container,
        disabled && styles.disabled
      )}
      style={{ maxHeight }}
    >
      <div className="rt-controls">
        {INLINE_STYLES.map((type) => (
          <div
            className={styles.iconDiv}
            key={type.label}
            onMouseDown={(e: React.MouseEvent<HTMLSpanElement>) =>
              toggleInlineStyle(e, type.style)
            }
          >
            <Icon
              key={type.label}
              name={type.iconName}
              fontSize="20px"
              color={
                currentStyle.has(type.style)
                  ? ARBOLUS_COLORS.bColorBaseDark
                  : ARBOLUS_COLORS.bColorGrayIcon
              }
            />
          </div>
        ))}
        {BLOCK_TYPES.map((type) => (
          <div
            className={styles.iconDiv}
            key={type.label}
            onMouseDown={(e: React.MouseEvent<HTMLSpanElement>) =>
              toggleBlockType(e, type.style)
            }
          >
            <Icon
              key={type.label}
              name={type.iconName}
              fontSize="20px"
              color={
                type.style === blockType
                  ? ARBOLUS_COLORS.bColorBaseDark
                  : ARBOLUS_COLORS.bColorGrayIcon
              }
            />
          </div>
        ))}
      </div>
      <div onClick={onTrackRichText}>
        <Editor
          ref={editorRef}
          placeholder={changed ? "" : placeholder}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          onBlur={onBlur}
          onChange={handleOnChange}
          readOnly={disabled}
          keyBindingFn={mapKeyToEditorCommand}
          spellCheck={true}
        />
      </div>
    </div>
  );
};
