import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { SlidePanel } from "@arbolus-technologies/features/common";
import { PanelId, PanelSelector } from "@arbolus-technologies/stores/panels";

import { UpdateAngleParams } from "../../Models/ReferralsTable";
import { UpdateExpertAngle } from "./UpdateExpertAngle";

export const UpdateExpertAnglePanel: React.FC = () => {
  const { t } = useTranslation("addEditAngleSlidePanel");
  const updateAngleParams = useSelector(
    PanelSelector.panelData<UpdateAngleParams | undefined>(
      PanelId.UpdateExpertAngle
    )
  );

  return (
    <SlidePanel
      panelId={PanelId.UpdateExpertAngle}
      title={t("editAngle")}
      hideHeader
      skipClose
    >
      {updateAngleParams ? <UpdateExpertAngle {...updateAngleParams} /> : <></>}
    </SlidePanel>
  );
};
