import moment from "moment";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { FormConstraints } from "@arbolus-technologies/models/canopy";
import { HorizontalProgressComponentGroup } from "@arbolus-technologies/ui/components";
import { CANOPY_DATE_TIME_FORMAT } from "@arbolus-technologies/utils";

import { convertToPercentage } from "../../../utils";
import { AnswerQuestion } from "../types";

interface NpsTypeViewerByExpertProps {
  question: AnswerQuestion;
  npsValue: number | null;
  created: string;
}

export const NpsTypeViewerByExpert: React.FC<NpsTypeViewerByExpertProps> = ({
  question,
  npsValue,
  created
}) => {
  const { t } = useTranslation("npsTypeViewerByExpert");

  const { id, text, details, sortOrder } = question;

  const convertedArray = useMemo(() => {
    const avg = t("expertValue", {
      value: npsValue ?? t("n/a"),
      maxValue: FormConstraints.MAX_NPS_VALUE
    });
    const valuePercentage = npsValue
      ? convertToPercentage(
          FormConstraints.MIN_NPS_VALUE,
          FormConstraints.MAX_NPS_VALUE,
          npsValue
        )
      : 0;

    return [
      {
        id,
        title: "",
        progressText: avg,
        value: valuePercentage,
        hasValue: npsValue !== null
      }
    ];
  }, [npsValue, t, id]);

  const lastResponse = `${t("responseDate")}: ${moment(created).format(
    CANOPY_DATE_TIME_FORMAT
  )}`;

  return (
    <HorizontalProgressComponentGroup
      headerTitle={text}
      headerSubtitle={details}
      questionNumber={{
        value: sortOrder ? sortOrder + 1 : 1,
        icon: { name: "analytics" }
      }}
      footerText={lastResponse}
      data={convertedArray}
    />
  );
};
