import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";

import {
  MixpanelPages,
  PageTracker
} from "@arbolus-technologies/features/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";

import { DiscoverMatchingFiltersPanel } from "../../Components/Discover/DiscoverMatchingFiltersPanel/DiscoverMatchingFiltersPanel";
import { DiscoverDesktopLayout } from "./DiscoverDesktopLayout/DiscoverDesktopLayout";
import { DiscoverMobileLayout } from "./DiscoverMobileLayout/DiscoverMobileLayout";

export const DiscoverPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { projectId } = useParams<{ projectId: string }>();

  const projectName = useSelector(ProjectNxSelector.projectName());
  const isAdmin = useSelector(CacheSelector.isAdmin());

  useEffect(() => {
    if (!isAdmin) {
      history.replace({
        pathname: location.pathname
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DiscoverPageLayout = isMobile
    ? DiscoverMobileLayout
    : DiscoverDesktopLayout;

  return (
    <PageTracker page={MixpanelPages.FindExperts}>
      <DiscoverPageLayout projectId={projectId} projectName={projectName} />
      <DiscoverMatchingFiltersPanel />
    </PageTracker>
  );
};
