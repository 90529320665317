import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Document, SORT_DIRECTION } from "@arbolus-technologies/api";
import {
  ProjectNxSelector,
  ProjectNxStoreActions
} from "@arbolus-technologies/stores/project";
import {
  Checkbox,
  HR,
  InfiniteScrollV2,
  SearchInput
} from "@arbolus-technologies/ui/components";
import {
  SEARCH_DEBOUNCE_TIMEOUT_COMMON,
  getFileExtension
} from "@arbolus-technologies/utils";

import { useChat } from "../../../../../../Contexts/ChatContext/ChatContext";
import { FilesListButtons } from "../FilesListButtons/FilesListButtons";

import styles from "./FilesList.module.scss";

const MINIMUM_SEARCH_LENGTH = 2;
interface FilesListProps {
  projectId: string;
}

export const FilesList: React.FC<FilesListProps> = ({ projectId }) => {
  const { t } = useTranslation("chat");
  const dispatch = useDispatch();
  const { chatSenderRole } = useChat();
  const chatFiles = useSelector(ProjectNxSelector.chatFiles());
  const chatFilesIsLoading = useSelector(
    ProjectNxSelector.chatFilesIsLoading()
  );
  const chatFilesHasNextPage = useSelector(
    ProjectNxSelector.chatFilesHasNextPage()
  );
  const selectedFilesToShare = useSelector(
    ProjectNxSelector.selectedFilesToShare()
  );

  const [searchTerm, setSearchTerm] = useState("");

  const handleBottomReached = () => {
    if (chatFilesHasNextPage && !chatFilesIsLoading) {
      return getChatFiles();
    }
    return null;
  };

  const getChatFiles = useCallback((): void => {
    dispatch(
      ProjectNxStoreActions.getChatFiles(
        projectId,
        {
          searchTerm,
          limit: 20,
          offset: chatFiles?.length ?? 0,
          orderBy: "Created",
          orderDirection: SORT_DIRECTION.DESCENDING
        },
        chatSenderRole
      )
    );
  }, [chatFiles?.length, chatSenderRole, dispatch, projectId, searchTerm]);

  useEffect(() => {
    getChatFiles();
    return () => {
      dispatch(ProjectNxStoreActions.resetChatFiles());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClickFile = useCallback(
    (fileId: string) => {
      dispatch(ProjectNxStoreActions.selectFileToShare(fileId));
    },
    [dispatch]
  );

  const renderChatFile = (file: Document) => {
    const fileExtension = getFileExtension(file.fileName);

    return (
      <Checkbox
        key={file.id}
        isChecked={selectedFilesToShare.includes(file.id)}
        onChange={() => handleOnClickFile(file.id)}
        text={file.fileName}
        documentIcon={{
          fileExtension: fileExtension,
          fontSize: "16px",
          iconSize: "24px"
        }}
      />
    );
  };

  const searchTermUpdated = useCallback(
    (nextSearchTerm: string) => {
      setSearchTerm(nextSearchTerm);
      if (nextSearchTerm.length < MINIMUM_SEARCH_LENGTH) {
        return;
      }
      dispatch(
        ProjectNxStoreActions.getChatFiles(
          projectId,
          {
            searchTerm: nextSearchTerm,
            limit: 20,
            offset: 0,
            orderBy: "Created",
            orderDirection: SORT_DIRECTION.DESCENDING
          },
          chatSenderRole
        )
      );
    },
    [chatSenderRole, dispatch, projectId]
  );

  return (
    <div className={styles.filesListContainer}>
      <p className={styles.subtitle}>{t("shareSidePanelSubtitle")}</p>
      <div className={styles.search}>
        <SearchInput
          onQueryChange={searchTermUpdated}
          isDebounced
          debouncingTime={SEARCH_DEBOUNCE_TIMEOUT_COMMON}
          placeholder={t("searchAttachment")}
          initialValue={searchTerm}
          minimumSearchLength={MINIMUM_SEARCH_LENGTH}
          hasSearchIcon
        />
      </div>
      <div className={styles.infiniteContainer}>
        <InfiniteScrollV2
          onBottomReached={handleBottomReached}
          items={chatFiles}
          renderer={renderChatFile}
          isLoading={chatFilesIsLoading}
          customPadding={[0, 2, 0, 0]}
        />
      </div>
      <HR noMargin />
      <FilesListButtons projectId={projectId} />
    </div>
  );
};
