import { Button, Icon } from "arbolus-ui-components";
import clsx from "clsx";
import moment from "moment";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { NpsAnswers } from "@arbolus-technologies/models/canopy-panels";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  ArbolusTooltip,
  ProgressVerticalComponent
} from "@arbolus-technologies/ui/components";
import { APP_DATE_FORMAT } from "@arbolus-technologies/utils";

import styles from "./Nps.module.scss";

interface NpsProps {
  data: NpsAnswers;
  handleOnClickExperts?: (
    npsValue: number | null,
    totalExperts: number
  ) => void;
}

export const Nps: React.FC<NpsProps> = ({ data, handleOnClickExperts }) => {
  const { t } = useTranslation("nps");

  const { netPromoterScore, nullAnswersCount, npsAnswers, lastResponseDate } =
    data;

  const actionComponent = useCallback(
    (npsValue: number, numberOfExperts: number) =>
      numberOfExperts > 0 &&
      (handleOnClickExperts ? (
        <Button
          type="tertiary"
          text={t("expert", { count: numberOfExperts })}
          onClick={() => handleOnClickExperts?.(npsValue, numberOfExperts)}
        />
      ) : (
        <div className={styles.text}>
          {t("expert", { count: numberOfExperts })}
        </div>
      )),
    [handleOnClickExperts, t]
  );

  return (
    <div className={styles.container}>
      <div className={styles.npsHeader}>
        <div className={styles.netScore}>
          {`${t("netPromoterScore")} ${netPromoterScore}`}
          <ArbolusTooltip content={t("nps")}>
            <Icon
              fontSize="18px"
              color={ARBOLUS_COLORS.bColorGrayIcon}
              name="info"
              id="promoterScore"
            />
          </ArbolusTooltip>
        </div>
        {nullAnswersCount && handleOnClickExperts ? (
          <Button
            type="tertiary"
            text={t("naAnswers", { count: nullAnswersCount })}
            onClick={() => handleOnClickExperts?.(null, nullAnswersCount)}
          />
        ) : (
          <div className={styles.text}>
            {t("naAnswers", { count: nullAnswersCount })}
          </div>
        )}
      </div>
      <div className={styles.insiders}>
        <div className={styles.progressWrapper}>
          <div>{t("detractors")}</div>
          <div className={clsx(styles.containerProgress, styles.detractor)}>
            {npsAnswers.slice(0, 7).map((response) => (
              <ProgressVerticalComponent
                key={response.npsValue}
                number={response.npsValue}
                percentage={response.npsPercentage}
                actionComponent={actionComponent(
                  response.npsValue,
                  response.expertCount
                )}
              />
            ))}
          </div>
        </div>
        <div className={styles.progressWrapper}>
          <div>{t("passives")}</div>
          <div className={clsx(styles.containerProgress, styles.passive)}>
            {npsAnswers.slice(7, 9).map((response) => (
              <ProgressVerticalComponent
                key={response.npsValue}
                number={response.npsValue}
                percentage={response.npsPercentage}
                color={ARBOLUS_COLORS.bColorSecondaryAmber}
                actionComponent={actionComponent(
                  response.npsValue,
                  response.expertCount
                )}
              />
            ))}
          </div>
        </div>
        <div className={styles.progressWrapper}>
          <div>{t("promoters")}</div>
          <div className={clsx(styles.containerProgress, styles.promoter)}>
            {npsAnswers.slice(-2).map((response) => (
              <ProgressVerticalComponent
                key={response.npsValue}
                number={response.npsValue}
                percentage={response.npsPercentage}
                color={ARBOLUS_COLORS.bColorAccentGreenDark}
                actionComponent={actionComponent(
                  response.npsValue,
                  response.expertCount
                )}
              />
            ))}
          </div>
        </div>
      </div>
      <div className={styles.legend}>
        <span>{t("notLikely")}</span>
        <span>{t("extremelyLikely")}</span>
      </div>
      {lastResponseDate && (
        <div className={styles.date}>
          {t("lastResponse")}
          {moment(lastResponseDate).format(APP_DATE_FORMAT)}
        </div>
      )}
    </div>
  );
};
