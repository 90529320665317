import {
  AngleResponse,
  AngleToSend,
  CIQError,
  ErrorResponse,
  QuestionsResponse
} from "@arbolus-technologies/api";
import { AngleModel } from "@arbolus-technologies/models/common";
import { AngleLink } from "@arbolus-technologies/models/project";

export const GET_ANGLES_CARDS = "PROJECT_ANGLES_GET_ANGLES_CARDS";
export const GET_ANGLES_CARDS_SUCCESS =
  "PROJECT_ANGLES_GET_ANGLES_CARDS_SUCCESS";
export const GET_ANGLES_CARDS_FAILURE =
  "PROJECT_ANGLES_GET_ANGLES_CARDS_FAILURE";

export const GET_ANGLES_LINK = "PROJECT_ANGLES_GET_ANGLES_LINK";
export const GET_ANGLES_LINK_SUCCESS = "PROJECT_ANGLES_GET_ANGLES_LINK_SUCCESS";
export const GET_ANGLES_LINK_FAILURE = "PROJECT_ANGLES_GET_ANGLES_LINK_FAILURE";
export const RESET_ANGLES_LINK = "PROJECT_ANGLES_RESET_ANGLES_LINK";

export const GET_SINGLE_ANGLE = "PROJECT_ANGLES_GET_SINGLE_ANGLE";
export const GET_SINGLE_ANGLE_SUCCESS =
  "PROJECT_ANGLES_GET_SINGLE_ANGLE_SUCCESS";
export const GET_SINGLE_ANGLE_FAILURE =
  "PROJECT_ANGLES_GET_SINGLE_ANGLE_FAILURE";

export const GET_ANGLE_QUESTIONS = "PROJECT_ANGLES_GET_ANGLE_QUESTIONS";
export const GET_ANGLE_QUESTIONS_SUCCESS =
  "PROJECT_ANGLES_GET_ANGLE_QUESTIONS_SUCCESS";
export const GET_ANGLE_QUESTIONS_FAILURE =
  "PROJECT_ANGLES_GET_ANGLE_QUESTIONS_FAILURE";

export const EDIT_ANGLE_QUESTIONS_ADMIN =
  "PROJECT_ANGLES_EDIT_ANGLE_QUESTIONS_ADMIN";
export const EDIT_ANGLE_QUESTIONS_ADMIN_SUCCESS =
  "PROJECT_ANGLES_EDIT_ANGLE_QUESTIONS_ADMIN_SUCCESS";
export const EDIT_ANGLE_QUESTIONS_ADMIN_FAILURE =
  "PROJECT_ANGLES_EDIT_ANGLE_QUESTIONS_ADMIN_FAILURE";

export const GET_CLIENT_ID_BY_ADMIN = "PROJECT_ANGLES_GET_CLIENT_ID_BY_ADMIN";

export const EDIT_ANGLE = "PROJECT_ANGLES_EDIT_ANGLE";
export const EDIT_ANGLE_SUCCESS = "PROJECT_ANGLES_EDIT_ANGLE_SUCCESS";
export const EDIT_ANGLE_FAILURE = "PROJECT_ANGLES_EDIT_ANGLE_FAILURE";

export const ADD_NEW_ANGLE = "PROJECT_ANGLES_ADD_NEW_ANGLE";
export const ADD_NEW_ANGLE_SUCCESS = "PROJECT_ANGLES_ADD_NEW_ANGLE_SUCCESS";
export const ADD_NEW_ANGLE_FAILURE = "PROJECT_ANGLES_ADD_NEW_ANGLE_FAILURE";

export const SET_FORM_CREATE_EDIT_ANGLE_DISABLED =
  "PROJECT_ANGLES_SET_FORM_CREATE_EDIT_ANGLE_DISABLED";
export const SET_FORM_CREATE_EDIT_ANGLE_ENABLED =
  "PROJECT_ANGLES_SET_FORM_CREATE_EDIT_ANGLE_ENABLED";

export const RESET_SELECTED_ANGLE = "PROJECT_ANGLES_RESET_SELECTED_ANGLE";

export const ADD_SCREENING_QUESTIONS_VIEW =
  "PROJECT_ANGLES_ADD_SCREENING_QUESTIONS_VIEW";
export const NO_SCREENING_QUESTIONS_VIEW =
  "PROJECT_ANGLES_NO_SCREENING_QUESTIONS_VIEW";

export const SET_AVAILABLE_COLORS = "PROJECT_ANGLES_SET_AVAILABLE_COLORS";

export interface GetAnglesCardsAction {
  type: typeof GET_ANGLES_CARDS;
  payload: {
    projectId: string;
  };
}

export interface GetAnglesCardsSuccessAction {
  type: typeof GET_ANGLES_CARDS_SUCCESS;
  payload: {
    anglesCards: AngleModel[];
  };
}

export interface GetAnglesCardsFailureAction {
  type: typeof GET_ANGLES_CARDS_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

// GET ANGLES LINK

export interface GetAnglesLinkAction {
  type: typeof GET_ANGLES_LINK;
  payload: {
    projectId: string;
    angleId?: string;
  };
}

export interface GetAnglesLinkSuccessAction {
  type: typeof GET_ANGLES_LINK_SUCCESS;
  payload: {
    linksResponse: AngleLink[];
    angleId?: string;
  };
}

export interface GetAnglesLinkFailureAction {
  type: typeof GET_ANGLES_LINK_FAILURE;
}

export interface ResetAnglesLinkAction {
  type: typeof RESET_ANGLES_LINK;
}

//GET SINGLE ANGLE
export interface GetSingleAngleAction {
  type: typeof GET_SINGLE_ANGLE;
  payload: {
    angleId: string;
  };
}

export interface GetSingleAngleSuccessAction {
  type: typeof GET_SINGLE_ANGLE_SUCCESS;
  payload: {
    selectedAngle: AngleResponse;
  };
}

export interface GetSingleAngleFailureAction {
  type: typeof GET_SINGLE_ANGLE_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

//GET ANGLE QUESTIONS
export interface GetAngleQuestionsAction {
  type: typeof GET_ANGLE_QUESTIONS;
  payload: {
    angleId: string;
  };
}

export interface GetAngleQuestionsSuccessAction {
  type: typeof GET_ANGLE_QUESTIONS_SUCCESS;
  payload: {
    response: QuestionsResponse;
  };
}

export interface GetAngleQuestionsFailureAction {
  type: typeof GET_ANGLE_QUESTIONS_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

//GET CLIENT ID BY ADMIN
export interface GetClientIdByAdminAction {
  type: typeof GET_CLIENT_ID_BY_ADMIN;
  payload: {
    clientId: string;
  };
}

//EDIT ANGLE QUESTIONS ADMIN
export interface EditAngleQuestionsAdminAction {
  type: typeof EDIT_ANGLE_QUESTIONS_ADMIN;
  payload: {
    angleId: string;
    questions: { questions: string[] };
  };
}

export interface EditAngleQuestionsAdminSuccessAction {
  type: typeof EDIT_ANGLE_QUESTIONS_ADMIN_SUCCESS;
  payload: { response: QuestionsResponse };
}

export interface EditAngleQuestionsAdminFailureAction {
  type: typeof EDIT_ANGLE_QUESTIONS_ADMIN_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

//EDIT ANGLE
export interface EditAngleAction {
  type: typeof EDIT_ANGLE;
  payload: {
    angleId: string;
    angleData: AngleToSend;
  };
}

export interface EditAngleSuccessAction {
  type: typeof EDIT_ANGLE_SUCCESS;
  payload: {
    selectedAngle: AngleResponse;
  };
}

export interface EditAngleFailureAction {
  type: typeof EDIT_ANGLE_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

//ADD ANGLE
export interface AddNewAngleAction {
  type: typeof ADD_NEW_ANGLE;
  payload: {
    angleData: AngleToSend;
    projectId: string;
  };
}

export interface AddNewAngleSuccessAction {
  type: typeof ADD_NEW_ANGLE_SUCCESS;
}

export interface AddNewAngleFailureAction {
  type: typeof ADD_NEW_ANGLE_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

export interface ResetSelectedAngleAction {
  type: typeof RESET_SELECTED_ANGLE;
}

//IS FORM DISABLED
export interface SetFormCreateEditAngleDisabledAction {
  type: typeof SET_FORM_CREATE_EDIT_ANGLE_DISABLED;
}

export interface SetFormCreateEditAngleEnabledAction {
  type: typeof SET_FORM_CREATE_EDIT_ANGLE_ENABLED;
}

export interface AddScreeningQuestionsView {
  type: typeof ADD_SCREENING_QUESTIONS_VIEW;
}

export interface NoScreeningQuestionsView {
  type: typeof NO_SCREENING_QUESTIONS_VIEW;
}

export interface SetAvailableColorsAction {
  type: typeof SET_AVAILABLE_COLORS;
  payload: {
    anglesCards: AngleModel[];
  };
}
