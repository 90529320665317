import { DeleteCollectionAction } from "../actions/DeleteCollection/DeleteCollectionActionTypes";
import { BookmarkCollectionsReducerState } from "../models/definitions";
import { initialState } from "./InitialState";

export const handleDeleteCollection = (
  state = initialState,
  { payload: { id } }: DeleteCollectionAction
): BookmarkCollectionsReducerState => {
  if (!state.collections.store) {
    return state;
  }

  const hasDeletedCollection = state.collections.store.items.some(
    (c) => c.id === id
  );
  if (!hasDeletedCollection) {
    return state;
  }

  const items = state.collections.store.items.filter((c) => c.id !== id);
  return {
    ...state,
    collections: {
      ...state.collections,
      store: {
        ...state.collections.store,
        items
      }
    }
  };
};
