import { CIQError, ErrorResponse } from "@arbolus-technologies/api";
import { BookmarkCollection } from "@arbolus-technologies/models/common";

import {
  GET_COLLECTION,
  GET_COLLECTION_FAILURE,
  GET_COLLECTION_SUCCESS,
  GetCollectionAction,
  GetCollectionFailureAction,
  GetCollectionSuccessAction
} from "./GetCollectionActionTypes";

export const getCollection = (id: string): GetCollectionAction => ({
  type: GET_COLLECTION,
  payload: { id }
});

export const getCollectionSuccess = (
  response: BookmarkCollection
): GetCollectionSuccessAction => ({
  type: GET_COLLECTION_SUCCESS,
  payload: { response }
});

export const getCollectionFailure = (
  error: ErrorResponse<CIQError>
): GetCollectionFailureAction => ({
  type: GET_COLLECTION_FAILURE,
  payload: { error }
});
