import {
  AllCanopyAnswersByExpert,
  CIQError,
  CanopyAnswersByQuestion,
  CanopyVideoAnswers,
  ErrorResponse,
  ExpertInACanopy
} from "@arbolus-technologies/api";

import {
  CanopyClientsActiveTab,
  CanopyClientsAnswerState
} from "../models/enums";

export const CANOPY_CLIENT_ANSWER_STATE = "CANOPY_CLIENT_CANOPY_ANSWER_STATE";
export const CANOPY_CLIENT_ACTIVE_TAB =
  "CANOPY_CLIENT_CANOPY_CLIENT_ACTIVE_TAB";
export const GET_ANSWERS_BY_QUESTION = "CANOPY_CLIENT_GET_ANSWERS_BY_QUESTION";
export const GET_ANSWERS_BY_QUESTION_SUCCESS =
  "CANOPY_CLIENT_GET_ANSWERS_BY_QUESTION_SUCCESS";
export const GET_ANSWERS_BY_QUESTION_FAILURE =
  "CANOPY_CLIENT_GET_ANSWERS_BY_QUESTION_FAILURE";
export const RESET_ANSWER_EXPERT = "CANOPY_CLIENT_RESET_ANSWER_EXPERT";
export const RESET_CANOPY_ANSWERS_BY_EXPERT =
  "CANOPY_CLIENT_RESET_CANOPY_ANSWERS_BY_EXPERT";
export const SHOW_RESPONSES = "CANOPY_CLIENT_SHOW_RESPONSES";
export const GET_VIDEO_TRANSCRIPT = "CANOPY_CLIENT_GET_VIDEO_TRANSCRIPT";
export const GET_VIDEO_TRANSCRIPT_SUCCESS =
  "CANOPY_CLIENT_GET_VIDEO_TRANSCRIPT_SUCCESS";
export const GET_VIDEO_TRANSCRIPT_FAILURE =
  "CANOPY_CLIENT_GET_VIDEO_TRANSCRIPT_FAILURE";
export const EDIT_VIDEO_TRANSCRIPT = "CANOPY_CLIENT_EDIT_VIDEO_TRANSCRIPT";
export const EDIT_VIDEO_TRANSCRIPT_SUCCESS =
  "CANOPY_CLIENT_EDIT_VIDEO_TRANSCRIPT_SUCCESS";
export const EDIT_VIDEO_TRANSCRIPT_FAILURE =
  "CANOPY_CLIENT_EDIT_VIDEO_TRANSCRIPT_FAILURE";
export const RESET_VIDEO_TRANSCRIPT = "CANOPY_CLIENT_RESET_VIDEO_TRANSCRIPT";
export const RESET_VIDEO_TRANSCRIPT_FAILURE =
  "CANOPY_CLIENT_RESET_VIDEO_TRANSCRIPT_FAILURE";
export const SET_CANOPY_ANSWER_SELECTED =
  "CANOPY_CLIENT_SET_CANOPY_ANSWER_SELECTED";
export const SET_CANOPY_ANSWER_SELECTED_BY_EXPERTS =
  "CANOPY_CLIENT_SET_CANOPY_ANSWER_SELECTED_BY_EXPERTS";
export const SET_CANOPY_ANSWER_SELECTED_BY_EXPERTS_SUCCESS =
  "CANOPY_CLIENT_SET_CANOPY_ANSWER_SELECTED_BY_EXPERTS_SUCCESS";
export const SET_CANOPY_ANSWER_SELECTED_BY_EXPERTS_FAILURE =
  "CANOPY_CLIENT_SET_CANOPY_ANSWER_SELECTED_BY_EXPERTS_FAILURE";
export const GET_CANOPY_CLIENT_EXPERTS =
  "CANOPY_CLIENT_GET_CANOPY_CLIENT_EXPERTS";
export const GET_CANOPY_CLIENT_EXPERTS_SUCCESS =
  "CANOPY_CLIENT_GET_CANOPY_CLIENT_EXPERTS_SUCCESS";
export const GET_CANOPY_CLIENT_EXPERTS_FAILURE =
  "CANOPY_CLIENT_GET_CANOPY_CLIENT_EXPERTS_FAILURE";

export const GET_CANOPY_ANSWERS_BY_EXPERT =
  "CANOPY_CLIENT_GET_CANOPY_ANSWERS_BY_EXPERT";
export const GET_CANOPY_ANSWERS_BY_EXPERT_SUCCESS =
  "CANOPY_CLIENT_GET_CANOPY_ANSWERS_BY_EXPERT_SUCCESS";
export const SET_SELECTED_EXPERT_SUCCESS =
  "CANOPY_CLIENT_SET_SELECTED_EXPERT_SUCCESS";
export const GET_CANOPY_ANSWERS_BY_EXPERT_FAILURE =
  "CANOPY_CLIENT_GET_CANOPY_ANSWERS_BY_EXPERT_FAILURE";

export const RESET_SELECTED_QUESTION_AND_EXPERT =
  "CANOPY_CLIENT_RESET_SELECTED_QUESTION_AND_EXPERT";
export const COPY_CANOPY_INVITATION_LINK_TO_CLIPBOARD =
  "CANOPY_CLIENT_COPY_CANOPY_INVITATION_LINK_TO_CLIPBOARD";
export const SHOW_RIGHT_COMPONENT = "CANOPY_CLIENT_SHOW_RIGHT_COMPONENT";
export const GET_IS_EXPERT_IN_PROJECT =
  "CANOPY_CLIENT_GET_IS_EXPERT_IN_PROJECT";
export const GET_IS_EXPERT_IN_PROJECT_SUCCESS =
  "CANOPY_CLIENT_GET_IS_EXPERT_IN_PROJECT_SUCCESS";
export const GET_IS_EXPERT_IN_PROJECT_FAILURE =
  "CANOPY_CLIENT_GET_IS_EXPERT_IN_PROJECT_FAILURE";
export const SET_COMPLIANCE_REVIEW_STATUS =
  "CANOPY_CLIENT_SET_COMPLIANCE_REVIEW_STATUS";

export interface CanopyClientAnswerStateAction {
  type: typeof CANOPY_CLIENT_ANSWER_STATE;
  payload: {
    state: CanopyClientsAnswerState;
  };
}

export interface CanopyClientActiveTabAction {
  type: typeof CANOPY_CLIENT_ACTIVE_TAB;
  payload: {
    activeTab: CanopyClientsActiveTab;
  };
}

export interface GetApiAnswersByExpertsAction {
  type: typeof GET_ANSWERS_BY_QUESTION;
  payload: {
    questionId: string;
    offset: number;
  };
}

export interface GetApiAnswersByExpertsSuccessAction {
  type: typeof GET_ANSWERS_BY_QUESTION_SUCCESS;
  payload: {
    questionId: string;
    answersByQuestion: CanopyAnswersByQuestion;
  };
}

export interface GetApiAnswersByExpertsFailureAction {
  type: typeof GET_ANSWERS_BY_QUESTION_FAILURE;
  error: ErrorResponse<CIQError>;
}

export interface SetCanopyAnswerSelectedByExpertsAction {
  type: typeof SET_CANOPY_ANSWER_SELECTED_BY_EXPERTS;
  payload: {
    questionId: string;
    answerId: string;
  };
}

export interface SetCanopyAnswerSelectedByExpertsSuccessAction {
  type: typeof SET_CANOPY_ANSWER_SELECTED_BY_EXPERTS_SUCCESS;
  payload: {
    answerId: string;
    answersByExpert: CanopyAnswersByQuestion;
  };
}

export interface SetCanopyAnswerSelectedByExpertsFailureAction {
  type: typeof SET_CANOPY_ANSWER_SELECTED_BY_EXPERTS_FAILURE;
  error: ErrorResponse<CIQError>;
}

export interface ResetAnswerExpertAction {
  type: typeof RESET_ANSWER_EXPERT;
}

export interface ResetCanopyAnswersByExpertAction {
  type: typeof RESET_CANOPY_ANSWERS_BY_EXPERT;
}

export interface ShowResponsesAction {
  type: typeof SHOW_RESPONSES;
  payload: {
    canopyId: string;
    showOnlyComplete: boolean;
  };
}

// GET Experts list who answered canopy
export interface GetCanopyClientExpertsAction {
  type: typeof GET_CANOPY_CLIENT_EXPERTS;
  payload: {
    canopyId: string;
    onlyComplete: boolean;
  };
}

export interface GetCanopyClientExpertsSuccessAction {
  type: typeof GET_CANOPY_CLIENT_EXPERTS_SUCCESS;
  payload: {
    canopyExperts: ExpertInACanopy[];
  };
}

export interface GetCanopyClientExpertsFailureAction {
  type: typeof GET_CANOPY_CLIENT_EXPERTS_FAILURE;
  error: ErrorResponse<CIQError>;
}

export interface SetCanopyAnswerSelectedAction {
  type: typeof SET_CANOPY_ANSWER_SELECTED;
  payload: {
    answer: CanopyVideoAnswers;
  };
}

export interface GetVideoTranscriptAction {
  type: typeof GET_VIDEO_TRANSCRIPT;
  payload: {
    answerId: string;
    transcriptWarning: string;
  };
}

export interface GetVideoTranscriptSuccessAction {
  type: typeof GET_VIDEO_TRANSCRIPT_SUCCESS;
  payload: {
    transcript: string;
    answerId: string;
    isSmartTranscript: boolean;
  };
}

export interface GetVideoTranscriptFailureAction {
  type: typeof GET_VIDEO_TRANSCRIPT_FAILURE;
  error: ErrorResponse<CIQError>;
}

export interface EditVideoTranscriptAction {
  type: typeof EDIT_VIDEO_TRANSCRIPT;
  payload: {
    answerId: string;
    content: string;
  };
}

export interface EditVideoTranscriptSuccessAction {
  type: typeof EDIT_VIDEO_TRANSCRIPT_SUCCESS;
  payload: {
    transcript: string;
    answerId: string;
    isSmartTranscript: boolean;
  };
}

export interface EditVideoTranscriptFailureAction {
  type: typeof EDIT_VIDEO_TRANSCRIPT_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

export interface ResetVideoTranscriptAction {
  type: typeof RESET_VIDEO_TRANSCRIPT;
  payload: {
    answerId: string;
  };
}

export interface ResetVideoTranscriptFailureAction {
  type: typeof RESET_VIDEO_TRANSCRIPT_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

// GET CANOPY ANSWERS BY EXPERT
export interface GetCanopyAnswersByExpertAction {
  type: typeof GET_CANOPY_ANSWERS_BY_EXPERT;
  payload: {
    canopyId: string;
    expertId: string;
    offset: number;
    onlyComplete: boolean;
  };
}

export interface GetCanopyAnswersByExpertSuccessAction {
  type: typeof GET_CANOPY_ANSWERS_BY_EXPERT_SUCCESS;
  payload: {
    answersByExpert: AllCanopyAnswersByExpert;
    canopyId: string;
    expertId: string;
  };
}

export interface SetSelectedExpertSuccessAction {
  type: typeof SET_SELECTED_EXPERT_SUCCESS;
  payload: {
    ExpertInACanopy: ExpertInACanopy[];
    expertId: string;
  };
}

export interface GetCanopyAnswersByExpertFailureAction {
  type: typeof GET_CANOPY_ANSWERS_BY_EXPERT_FAILURE;
  error: ErrorResponse<CIQError>;
}

// RESET SELECTED QUESTION AND SELECTED EXPERT

export interface ResetSelectedQuestionAndExpertAction {
  type: typeof RESET_SELECTED_QUESTION_AND_EXPERT;
}

export interface CopyCanopyInvitationLinkToClipboardAction {
  type: typeof COPY_CANOPY_INVITATION_LINK_TO_CLIPBOARD;
  payload: {
    canopyId: string;
    successMessage: string;
  };
}

export interface ShowRightComponentAction {
  type: typeof SHOW_RIGHT_COMPONENT;
  payload: {
    showRightComponent: boolean;
  };
}

export interface GetIsExpertInProjectAction {
  type: typeof GET_IS_EXPERT_IN_PROJECT;
  payload: {
    projectId: string;
    expertId: string;
  };
}

export interface GetIsExpertInProjectSuccessAction {
  type: typeof GET_IS_EXPERT_IN_PROJECT_SUCCESS;
  payload: {
    hasExpertClientVisibleReferral: boolean;
  };
}

export interface GetIsExpertInProjectFailureAction {
  type: typeof GET_IS_EXPERT_IN_PROJECT_FAILURE;
  error: ErrorResponse<CIQError>;
}

export interface SetComplianceReviewStatusAction {
  type: typeof SET_COMPLIANCE_REVIEW_STATUS;
  payload: {
    canopyId: string;
    expertId: string;
    hasPassedCompliance: boolean;
  };
}
