import i18next from "i18next";
import moment from "moment";

export class FormatDateService {
  public formatDay(date: Date, timezone: string): string {
    const dateMoment = moment.utc(date).tz(timezone);
    return this.formatDayMoment(dateMoment);
  }

  public formatDayMoment(date: moment.Moment): string {
    const todayMoment = moment().tz(date.tz() || "UTC");
    const tomorrowMoment = moment()
      .add(1, "day")
      .tz(date.tz() || "UTC");
    if (date.isSame(todayMoment, "day")) {
      return i18next.t("dayHeader:today");
    }

    if (date.isSame(tomorrowMoment, "day")) {
      return i18next.t("dayHeader:tomorrow");
    }

    return date.format("ddd D");
  }

  public formatDayTime(date: Date, timezone: string): string {
    const dateMoment = moment.utc(date).tz(timezone);
    return `${this.formatDayMoment(dateMoment)}, ${dateMoment.format("LT")}`;
  }
}

export const DefaultFormatDateService = new FormatDateService();
