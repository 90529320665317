import i18next from "i18next";

import {
  DefaultToasterService,
  ToasterService
} from "@arbolus-technologies/api";

export class ClipboardManager {
  private readonly _notificationService: ToasterService;

  constructor(notificationService: ToasterService) {
    this._notificationService = notificationService;
  }

  copy(text: string): void {
    navigator.clipboard
      .writeText(text)
      .then(() =>
        this._notificationService.showInfo(
          i18next.t("clipboard:copiedToClipboard")
        )
      )
      .catch(() => {
        this._notificationService.showError(
          i18next.t("clipboard:copiedToClipboardError")
        );
      });
  }
}

export const DefaultClipboardManager = new ClipboardManager(
  DefaultToasterService
);
