import { Icon } from "arbolus-ui-components";
import React from "react";

import styles from "./Tag.module.scss";

interface TagProps {
  icon?: string;
  text: string;
  color: string;
}

export const Tag: React.FC<TagProps> = ({ icon, color, text }) => (
  <div className={styles.container} style={{ borderColor: color }}>
    {icon && <Icon name={icon} fontSize="16px" color={color} />}
    <span className={styles.text} style={{ color: color }}>
      {text}
    </span>
  </div>
);
