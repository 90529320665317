import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

import { CLIENT_PAGE_ROUTE } from "@arbolus-technologies/routes";
import { Avatar } from "@arbolus-technologies/ui/components";
import { APP_DATE_FORMAT } from "@arbolus-technologies/utils";

import { CardFooterData } from "../../../interfaces";

import styles from "./CardFooter.module.scss";

interface CardFooterProps {
  footerData: CardFooterData;
}

export const CardFooter: React.FC<CardFooterProps> = ({ footerData }) => {
  const { client, date } = footerData;

  return (
    <div className={styles.footer}>
      <Link
        to={CLIENT_PAGE_ROUTE(client.id)}
        target="_blank"
        className={styles.link}
      >
        <div className={styles.clientAvatar}>
          <Avatar
            avatar={{
              name: client.name,
              imageUrl: client.logoUrl
            }}
            type="avatarGroupCircleSmall"
          />
          <span className={styles.clientName}>{client.name}</span>
        </div>
      </Link>
      <span className={styles.noteDate}>
        {moment(date).format(APP_DATE_FORMAT)}
      </span>
    </div>
  );
};
