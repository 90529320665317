import { useFeature } from "flagged";
import React from "react";
import { useTranslation } from "react-i18next";

import { FEATURE_FLAGS } from "@arbolus-technologies/models/common";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { LinkBoxButton } from "../../BoxButton/LinkBoxButton";
import { StatButtonProps } from "./StatButtonProps";

export const DiscoverButton: React.FC<StatButtonProps> = ({
  to,
  itemCount,
  trackClick,
  ...props
}) => {
  const { t } = useTranslation("discoverButton");
  const isDiscoverEnabled = useFeature(FEATURE_FLAGS.ExpertDiscover);

  return (
    <LinkBoxButton
      text={t("findExperts")}
      icon={{
        color: ARBOLUS_COLORS.bColorGrayIcon,
        background: ARBOLUS_COLORS.bColorSecondaryGreyLight,
        name: "travel_explore"
      }}
      subTitle={
        isDiscoverEnabled
          ? t("totalExperts", { totalExperts: itemCount })
          : "Coming soon..."
      }
      to={to}
      trackClick={trackClick}
      chevronColor={ARBOLUS_COLORS.bColorBasePurple}
      disabled={!isDiscoverEnabled}
      {...props}
    />
  );
};
