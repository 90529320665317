import React, { useEffect, useState } from "react";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ExpertApplicationProject,
  ExpertService,
  PROJECT_STATUS,
  ToasterService
} from "@arbolus-technologies/api";
import { Drawer } from "@arbolus-technologies/ui/components";

import { ProjectApplicationRow } from "./ProjectApplicationRow/ProjectApplicationRow";

import styles from "./ProjectsApplicationDrawer.module.scss";

export interface ProjectsApplicationDrawerProps {
  expertId: string;
  projectStatus: PROJECT_STATUS[];
  title: string;
  expertService?: typeof ExpertService;
  notificationService?: ToasterService;
}

export const ProjectsApplicationDrawer: React.FC<
  ProjectsApplicationDrawerProps
> = ({
  expertId,
  projectStatus,
  title,
  expertService = ExpertService,
  notificationService = DefaultToasterService
}) => {
  const [projects, setProjects] = useState<ExpertApplicationProject[]>([]);

  useEffect(() => {
    expertService.getExpertSharedProjects(expertId, projectStatus).subscribe(
      (response) => {
        setProjects(response.items);
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
      }
    );
  }, [expertId, expertService, notificationService, projectStatus]);

  return projects.length > 0 ? (
    <Drawer title={title}>
      <div className={styles.projectsApplicationList}>
        {projects.map((project) => (
          <ProjectApplicationRow
            key={project.id}
            project={project}
            displayStatus
          />
        ))}
      </div>
    </Drawer>
  ) : null;
};
