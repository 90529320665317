import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  ANSWER_TYPE,
  CanopyQuestionModel,
  QuestionModel
} from "@arbolus-technologies/models/canopy-panels";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  AvatarGroup,
  ButtonNoBorderWithIcon,
  NumberCircle
} from "@arbolus-technologies/ui/components";
import { displayUserName } from "@arbolus-technologies/utils";

import styles from "./Question.module.scss";

export interface QuestionProps {
  question: QuestionModel;
  toAnswer: string;
  onClick?: () => void;
  disableOnClick?: boolean;
}

export const Question: React.FC<QuestionProps> = ({
  question,
  toAnswer,
  onClick,
  disableOnClick
}) => {
  const { t } = useTranslation("canopyPanels");
  const canopyQuestion =
    (question as CanopyQuestionModel).sortOrder === undefined
      ? null
      : (question as CanopyQuestionModel);

  const questionIcon = {
    [ANSWER_TYPE.VIDEO]: { icon: "videocam", tooltip: t("video") },
    [ANSWER_TYPE.MULTIPLE_CHOICE]: { icon: "sort", tooltip: t("multiChoice") },
    [ANSWER_TYPE.VALUE]: { icon: "numbers", tooltip: t("value") },
    [ANSWER_TYPE.SHORT_TEXT]: { icon: "short_text", tooltip: t("shortText") },
    [ANSWER_TYPE.NPS]: { icon: "analytics", tooltip: t("nps") }
  };

  return (
    <Link
      className={clsx(styles.container, { disabled: disableOnClick })}
      to={toAnswer}
      onClick={onClick}
    >
      {canopyQuestion && (
        <div className={styles.infoContainer}>
          <NumberCircle
            number={canopyQuestion.sortOrder + 1}
            classnames={styles.number}
          />
          <Icon
            name={questionIcon[canopyQuestion.type].icon}
            color={ARBOLUS_COLORS.bColorGrayIcon}
            fontSize="24px"
            tooltip={questionIcon[canopyQuestion.type].tooltip}
          />
        </div>
      )}
      <div className={styles.contentContainer}>
        <div className={styles.titleContainer}>
          <h1 className={styles.title} title={question.text}>
            {question.text}
          </h1>
        </div>
        <p className={styles.subtitle} title={question.details}>
          {question.details}
        </p>
        <div className={styles.bottomContainer}>
          <div className={styles.answersButtonContainer}>
            <ButtonNoBorderWithIcon
              name={t("answers")}
              iconName="chevron_right"
              classnames={clsx(styles.answersLink, "disabled-if-no-access")}
              disabled={disableOnClick}
            />
          </div>
          {question.experts && question.experts.length > 0 && (
            <div className={styles.experts}>
              <AvatarGroup
                avatars={question.experts.map((e) => ({
                  name: displayUserName(e),
                  imageUrl: e.profileImageUrl
                }))}
                totalCount={question.expertsCount}
              />
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};
