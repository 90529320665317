import React, { useState } from "react";

import { CanopyQuestionFields } from "@arbolus-technologies/models/canopy";
import { Drawer } from "@arbolus-technologies/ui/components";

import { CANOPY_QUESTION_ICONS } from "../../helpers/constants";
import { useCanopyContext } from "../../helpers/useCanopyContext";
import { QuestionForm } from "../QuestionForm/QuestionForm";
import { QuestionTitle } from "../QuestionTitle/QuestionTitle";

import styles from "./QuestionItem.module.scss";

interface QuestionItemProps {
  title: string;
  question: CanopyQuestionFields;
  questionNumber: number;
  onQuestionsUpdate: (removeNewQuestionBlock?: boolean) => void;
  showUnsavedNewQuestion: boolean;
}

export const QuestionItem: React.FC<QuestionItemProps> = ({
  title,
  question,
  questionNumber,
  onQuestionsUpdate,
  showUnsavedNewQuestion
}) => {
  const { isEditMode, showUnsavedChanges } = useCanopyContext();

  const [isQuestionUnsaved, setIsQuestionUnsaved] = useState<boolean>(false);

  const handleQuestionsUpdate = (removeNewQuestionBlock?: boolean) => {
    setIsQuestionUnsaved(false);
    onQuestionsUpdate(removeNewQuestionBlock);
  };

  const QuestionDrawer = () => (
    <Drawer
      titleChildren={(isDrawerOpen) => (
        <QuestionTitle
          number={`${questionNumber}`}
          text={title}
          isActive={isDrawerOpen}
          icon={isEditMode ? CANOPY_QUESTION_ICONS[question.answerType] : ""}
          showUnsavedMessage={showUnsavedChanges && isQuestionUnsaved}
        />
      )}
      openByDefault={!isEditMode}
    >
      <QuestionForm
        question={question}
        onQuestionsUpdate={handleQuestionsUpdate}
        handleShowUnsavedQuestion={(isDirty: boolean) =>
          setIsQuestionUnsaved(isDirty)
        }
      />
    </Drawer>
  );

  return showUnsavedNewQuestion ? (
    <div className={styles.unsavedQuestion}>{QuestionDrawer()}</div>
  ) : (
    QuestionDrawer()
  );
};
