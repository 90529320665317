import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  AntDSelectMenu,
  AntDSelectValueType
} from "@arbolus-technologies/antDComponents";
import {
  ApiErrorResponse,
  CIQError,
  CompaniesPaginatedRequest,
  Company,
  CompanyService,
  DefaultToasterService,
  ToasterService
} from "@arbolus-technologies/api";

interface CompanySelectorProps {
  mode?: "multiple" | "tags";
  initialValue: AntDSelectValueType | null;
  allowClear?: boolean;
  disabled?: boolean;
  open?: boolean;
  addNewCompany?: {
    placeholder: string;
    buttonName: string;
    onAddItem: (name: string) => void;
  };
  onChange: (values: AntDSelectValueType, selectedCompanies: Company[]) => void;
  companyService?: typeof CompanyService;
  notificationService?: ToasterService;
}

export const CompanySelector: React.FC<CompanySelectorProps> = ({
  mode,
  initialValue,
  allowClear,
  disabled,
  open,
  addNewCompany,
  onChange,
  companyService = CompanyService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("companySelector");

  const [companies, setCompanies] = useState<Company[]>([]);

  const handleGetCompanies = (query: string, offset = 0) => {
    const pagination: CompaniesPaginatedRequest = {
      offset,
      searchTerm: query,
      limit: 5,
      sorting: ["name,asc"]
    };

    companyService.listCompanies(pagination).subscribe(
      ({ items }) => {
        const newList = items.map((item) => ({
          ...item,
          value: item.id,
          label: item.name
        }));
        setCompanies((prevState) =>
          (offset === 0 ? [] : prevState).concat(newList)
        );
      },
      (error: ApiErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
      }
    );
  };

  const handleOnChange = (values: AntDSelectValueType) => {
    onChange(values, companies);
  };

  return (
    <AntDSelectMenu
      options={companies}
      placeholder={t("placeholder")}
      optionFilterProp="name"
      labelInValue
      showLoadMoreButton
      open={open}
      mode={mode}
      disabled={disabled}
      value={initialValue}
      allowClear={allowClear}
      addNewItem={addNewCompany}
      onChange={handleOnChange}
      onGetItems={handleGetCompanies}
      handleClearList={() => setCompanies([])}
    />
  );
};
