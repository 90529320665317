import { OutputSelector, createSelector } from "reselect";

import { ListCanopy } from "@arbolus-technologies/api";
import { PreviousPage } from "@arbolus-technologies/models/canopy";

import {
  ProjectCanopyAppState,
  ProjectCanopyReducerState
} from "../definitions";

const projectCanopyStateSelector = (
  state: ProjectCanopyAppState
): ProjectCanopyReducerState => state.projectCanopy;

// Canopies
const canopiesSelector = (): OutputSelector<
  ProjectCanopyAppState,
  ListCanopy[],
  (res: ProjectCanopyReducerState) => ListCanopy[]
> =>
  createSelector<
    ProjectCanopyAppState,
    ProjectCanopyReducerState,
    ListCanopy[]
  >(
    projectCanopyStateSelector,
    (canopyReducerState) => canopyReducerState.canopies
  );

const canopiesLoadingSelector = (): OutputSelector<
  ProjectCanopyAppState,
  boolean,
  (res: ProjectCanopyReducerState) => boolean
> =>
  createSelector<ProjectCanopyAppState, ProjectCanopyReducerState, boolean>(
    projectCanopyStateSelector,
    (canopyReducerState) => canopyReducerState.isCanopiesLoading
  );

const canopyPreviousPageSelector = (): OutputSelector<
  ProjectCanopyAppState,
  PreviousPage,
  (res: ProjectCanopyReducerState) => PreviousPage
> =>
  createSelector<
    ProjectCanopyAppState,
    ProjectCanopyReducerState,
    PreviousPage
  >(
    projectCanopyStateSelector,
    (canopyReducerState) => canopyReducerState.previousPage
  );

export const ProjectCanopySelector = {
  projectCanopyStateSelector,
  canopiesSelector,
  canopiesLoadingSelector,
  canopyPreviousPageSelector
};
