import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import {
  BookmarksSelector,
  BookmarksStoreActions
} from "@arbolus-technologies/stores/bookmarks";
import {
  IThreeDotsItem,
  LoaderOrComponent,
  ThreeDots
} from "@arbolus-technologies/ui/components";

import {
  BOOKMARK_COLLECTIONS,
  BookmarksUrlParams
} from "../../Routes/bookmarksRoutes";
import { CollectionDotActions } from "../CollectionDotActions/CollectionDotActions";

import styles from "./CollectionHeader.module.scss";

export const CollectionHeader: React.FC = () => {
  const { bookmarkCollectionId } = useParams<BookmarksUrlParams>();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const collection = useSelector(BookmarksSelector.collection());
  const isLoading = useSelector(BookmarksSelector.collectionIsLoading());

  useEffect(() => {
    if (collection === null || collection?.id !== bookmarkCollectionId) {
      dispatch(BookmarksStoreActions.getCollection(bookmarkCollectionId));
    }
  }, [bookmarkCollectionId, collection, dispatch]);

  const render = useCallback(
    (actionItems: IThreeDotsItem[]) => (
      <div className={styles.container}>
        <h1>{collection?.name}</h1>
        <ThreeDots
          popoverId={collection?.id || "collectionPopover"}
          items={actionItems}
          classnames={styles.threeDots}
        />
      </div>
    ),
    [collection]
  );

  return (
    <LoaderOrComponent isLoading={isLoading || !collection}>
      <CollectionDotActions
        collection={collection!}
        childrenRenderer={render}
        onDeleteSuccess={() => push(BOOKMARK_COLLECTIONS)}
      />
    </LoaderOrComponent>
  );
};
