/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";

import styles from "./InputGroupController.module.scss";

type InputType = "text" | "file";

interface InputGroupControllerProps {
  control: Control<any>;
  defaultValue?: string;
  placeholder: string;
  name: string;
  index: number;
  errors: FieldErrors<any>;
  disabled?: boolean;
  readonly?: boolean;
  inputType: InputType;
  remove: (index: number) => void;
}

export const InputGroupController: React.FC<InputGroupControllerProps> = ({
  control,
  defaultValue,
  placeholder,
  name,
  index,
  errors,
  readonly = false,
  disabled,
  inputType,
  remove
}): JSX.Element => {
  const errorName = _.get(errors, name, false) as FieldErrors<any>;
  let isError = false;
  if (Object.keys(errors).length > 0 && errorName) {
    isError = true;
  }
  return (
    <div className={styles.inputContainer}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue ?? ""}
        render={({ field }) => (
          <InputGroup className="append">
            <Input
              disabled={disabled}
              invalid={isError}
              aria-describedby="button-addon2"
              placeholder={placeholder}
              type={inputType}
              readOnly={readonly}
              {...field}
            />
            <InputGroupAddon
              className={isError ? "is-invalid" : ""}
              addonType="append"
            >
              <InputGroupText>
                <Button
                  id="button-addon2"
                  className="show-hide-btn"
                  onClick={() => remove(index)}
                >
                  <i className={clsx("ciq-icon ciq-trash")} />
                </Button>
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        )}
      />
      {isError && (
        <div className={styles.error} data-testid="error-yup">
          {errorName.message}
        </div>
      )}
    </div>
  );
};
