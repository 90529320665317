import queryString from "query-string";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { zip } from "rxjs";

import {
  ApiPaginatedRequest,
  CIQError,
  CompaniesPaginatedResponse,
  CompanyResponse,
  DefaultToasterService,
  ErrorResponse,
  MetaService,
  SORT_DIRECTION,
  ToasterService
} from "@arbolus-technologies/api";
import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import {
  DiscoverFilterOption,
  DiscoverFilterType
} from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  ProjectNxSelector,
  ProjectNxStoreActions
} from "@arbolus-technologies/stores/project";
import { useDebounce, utilService } from "@arbolus-technologies/utils";

import { SEARCH_LIMIT } from "../../Modules/DiscoverFilters/DiscoverFilters";
import { cloneAndFilter } from "../../Utils/DiscoverExpertUtils";
import { useDiscoverFilters } from "./useDiscoverFilters";

interface UseDiscoverCustomerFilterProps {
  metaService?: typeof MetaService;
  notificationService?: ToasterService;
}

export interface UseDiscoverCustomerFilter {
  handleBottomReached: (query: string) => void | null;
  handleInputChangeEvent: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  handleClearQueryClicked: () => void;
  handleSelectOption: (option: DiscoverFilterOption) => void;
  handleSelectAll: () => void;
  filterOptions: DiscoverFilterOption[];
  applyCustomerFilter: () => void;
  urlChangeOnCustomerFilterUpdate: (options: DiscoverFilterOption[]) => void;
  handleClearFilter: () => void;
  updateCustomerInfoFromUrl: () => Promise<DiscoverFilterOption[]>;
}

const MINIMUM_SEARCH_LENGTH = 2;

export const useDiscoverCustomerFilter = ({
  metaService = MetaService,
  notificationService = DefaultToasterService
}: UseDiscoverCustomerFilterProps = {}): UseDiscoverCustomerFilter => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { trackFiltered } = useArbolusTracking();

  const {
    selectedOptions,
    setSelectedOptions,
    preSelectedOptions,
    setPreSelectedOptions,
    clearFilter
  } = useDiscoverFilters({
    filterType: DiscoverFilterType.Customers
  });

  const [isCustomersLoading, setIsCustomersLoading] = useState<boolean>(false);
  const [customersPaginatedResponse, setCustomersPaginatedResponse] =
    useState<CompaniesPaginatedResponse<CompanyResponse> | null>(null);
  const [filterOptions, setFilterOptions] = useState<DiscoverFilterOption[]>(
    []
  );

  const isAdmin = useSelector(CacheSelector.isAdmin());
  const projectName = useSelector(ProjectNxSelector.projectName());
  const projectClientName = useSelector(ProjectNxSelector.projectClientName());
  const customerFilterQuery = useSelector(ProjectNxSelector.filtersQuery())[
    DiscoverFilterType.Customers
  ];
  const debouncedSearchQuery = useDebounce<string>(customerFilterQuery, 300);

  const getCustomersSearchResults = (query?: string) => {
    setIsCustomersLoading(true);
    const queryParams: ApiPaginatedRequest = {
      searchTerm: query,
      offset: customersPaginatedResponse?.items.length ?? 0,
      limit: SEARCH_LIMIT,
      orderBy: "Name",
      orderDirection: SORT_DIRECTION.ASCENDING
    };

    metaService.getCompanies(queryParams).subscribe(
      (customersResponse) => {
        const filterOptionsArray = customersResponse.items.map((company) => ({
          value: company.id,
          label: company.name
        }));

        setCustomersPaginatedResponse({
          items: [
            ...(customersPaginatedResponse?.items ?? []),
            ...customersResponse.items
          ],
          pagination: customersResponse.pagination,
          exactMatch: customersResponse.exactMatch
        });
        setIsCustomersLoading(false);
        setFilterOptions([...filterOptions, ...filterOptionsArray]);
      },
      (error: ErrorResponse<CIQError>) => {
        setIsCustomersLoading(false);
        notificationService.showError(error.message);
        return [];
      }
    );
  };

  const handleBottomReached = (query: string) => {
    if (
      customersPaginatedResponse &&
      customersPaginatedResponse?.pagination.count >
        customersPaginatedResponse?.pagination.offset + SEARCH_LIMIT &&
      !isCustomersLoading
    ) {
      return getCustomersSearchResults(query);
    }
    return null;
  };

  const handleInputChangeEvent = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const newQuery = event.target.value;
    dispatch(
      ProjectNxStoreActions.setFilterQuery(
        DiscoverFilterType.Customers,
        newQuery
      )
    );
    setCustomersPaginatedResponse(null);
    setFilterOptions([]);
  };

  useEffect(() => {
    if (debouncedSearchQuery.length >= MINIMUM_SEARCH_LENGTH) {
      getCustomersSearchResults(debouncedSearchQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  const handleClearQueryClicked = () => {
    dispatch(
      ProjectNxStoreActions.setFilterQuery(DiscoverFilterType.Customers, "")
    );
  };

  const handleSelectOption = (option: DiscoverFilterOption) => {
    const isOptionSelected = preSelectedOptions
      .map((selectedOption) => selectedOption.value)
      .includes(option.value);

    if (isOptionSelected) {
      const filteredOptions = preSelectedOptions.filter(
        (selectedOption) => selectedOption.value !== option.value
      );
      setPreSelectedOptions(filteredOptions);
    } else {
      setPreSelectedOptions([...preSelectedOptions, option]);
    }
  };

  const handleSelectAll = () => {
    const newSelectedOptions = filterOptions.filter(
      (option) =>
        !preSelectedOptions.some(
          (selectedOption) => selectedOption.value === option.value
        )
    );
    setPreSelectedOptions([...preSelectedOptions, ...newSelectedOptions]);
  };

  useEffect(() => {
    if (customerFilterQuery.length === 0) {
      setCustomersPaginatedResponse(null);
      setFilterOptions([]);
    }
  }, [customerFilterQuery]);

  const applyCustomerFilter = () => {
    const selectedOptionsUpdated = cloneAndFilter(
      preSelectedOptions,
      selectedOptions
    );
    setSelectedOptions(selectedOptionsUpdated);
    urlChangeOnCustomerFilterUpdate(selectedOptionsUpdated);
    trackFiltered(MixPanelEventNames.InternalSearchCustomerSearch, {
      Customers: selectedOptionsUpdated.map((customer) => customer.label),
      Project: projectName,
      Client: projectClientName
    });
  };

  const parameters = queryString.parse(location.search);
  const { customers }: { customers?: string } = parameters;

  const updateCustomerInfoFromUrl = () => {
    const customerCompanyIds = customers ? customers.split(",") : [];
    if (!customerCompanyIds.length) {
      return Promise.resolve([]);
    }

    return new Promise<DiscoverFilterOption[]>((resolve, reject) => {
      const promises = customerCompanyIds.map((value) =>
        metaService.getCompanyById(value)
      );
      const subscription = zip(...promises).subscribe({
        next: (companies) => {
          const filterOptionsArray = companies.map(
            (company: CompanyResponse) => ({
              value: company.id,
              label: company.name
            })
          );

          resolve(filterOptionsArray);
          subscription.unsubscribe();
        },
        error: (error) => {
          reject(error);
          subscription.unsubscribe();
        }
      });
    });
  };

  const urlChangeOnCustomerFilterUpdate = (options: DiscoverFilterOption[]) => {
    if (isAdmin) {
      const nonEmptyParams = utilService.getNonEmptyQueryString(parameters);
      const customers = options.map((option) => option.value).join(",");
      if (customers) {
        nonEmptyParams["customers"] = customers;
      } else {
        delete nonEmptyParams["customers"];
      }

      const params = new URLSearchParams(nonEmptyParams);
      history.replace({
        pathname: location.pathname,
        search: params.toString()
      });
    }
  };

  const handleClearFilter = () => {
    clearFilter();
    if (isAdmin) {
      const nonEmptyParams = utilService.getNonEmptyQueryString(parameters);
      delete nonEmptyParams["customers"];

      const params = new URLSearchParams(nonEmptyParams);
      history.replace({
        pathname: location.pathname,
        search: params.toString()
      });
    }
  };

  return {
    handleBottomReached,
    handleInputChangeEvent,
    handleClearQueryClicked,
    handleSelectOption,
    handleSelectAll,
    filterOptions,
    applyCustomerFilter,
    urlChangeOnCustomerFilterUpdate,
    handleClearFilter,
    updateCustomerInfoFromUrl
  };
};
