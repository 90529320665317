import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ExpertService,
  Project,
  ToasterService
} from "@arbolus-technologies/api";
import {
  DO_NOT_CONTACT_STATUS,
  Referral
} from "@arbolus-technologies/models/common";

const AVAILABILITY_ALREADY_REQUESTED = "1240";

interface RequestAvailabilityButtonProps {
  referral: Referral;
  project: Project;
  expertService?: typeof ExpertService;
  toasterService?: ToasterService;
  type?: "tertiary" | "primary";
  size?: "small" | "large";
}

export const RequestAvailabilityButton: React.FC<
  RequestAvailabilityButtonProps
> = ({
  referral,
  project,
  type = "primary",
  size = "small",
  expertService = ExpertService,
  toasterService = DefaultToasterService
}) => {
  const { t } = useTranslation("referrals", { keyPrefix: "availability" });
  const [loading, setLoading] = React.useState(false);

  const requestTimeslots = React.useCallback(() => {
    setLoading(true);
    expertService
      .requestExpertAvailability(project.id, referral.expert.expertId)
      .subscribe({
        next: () => toasterService.showSuccess(t("requestSuccess")),
        error: (error: ErrorResponse<CIQError>) => {
          setLoading(false);
          if (error.status === AVAILABILITY_ALREADY_REQUESTED) {
            toasterService.showWarning(t("requestAlreadyDone"));
          } else {
            toasterService.showApiErrors(error);
          }
        }
      });
  }, [expertService, project, referral, toasterService, t]);

  return (
    <Button
      disabled={
        loading ||
        referral.expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC
      }
      onClick={requestTimeslots}
      text={t("requestMore")}
      type={type}
      size={size}
    />
  );
};
