import React from "react";

import { useUserProfile } from "@arbolus-technologies/features/common";
import { Loader } from "@arbolus-technologies/ui/components";

import { useGetMessages } from "../../../../Hooks/Chat/useGetMessages";
import { useOnlineChatMembers } from "../../../../Hooks/Chat/useOnlineChatMembers";
import { useScrollChat } from "../../../../Hooks/Chat/useScrollChat";
import { ChatErrors } from "../../utils";
import { PendingWarningNotification } from "../PendingWarningNotification/PendingWarningNotification";
import { ChatMessage } from "./ChatMessage/ChatMessage";

import styles from "./ChatMessages.module.scss";

interface ChatMessagesProps {
  openAvailabilityPanel: () => void;
  isLoadingReferral: boolean;
  chatErrors: ChatErrors | null;
  isDncExpertChat: boolean;
}

export const ChatMessages: React.FC<ChatMessagesProps> = ({
  openAvailabilityPanel,
  chatErrors,
  isLoadingReferral,
  isDncExpertChat
}) => {
  const userProfile = useUserProfile();

  const {
    getNewChatMessages,
    chatMessages,
    areMessagesLoading,
    messagesHaveNextPage,
    initialFetchPending,
    messageContainerRef
  } = useGetMessages();

  const enableAutoScroll = useScrollChat(
    chatMessages,
    getNewChatMessages,
    messagesHaveNextPage,
    areMessagesLoading,
    messageContainerRef
  );

  const { isMemberOnlineInMessages } = useOnlineChatMembers();

  const hasChatErrors = chatErrors
    ? Object.values(chatErrors).some(Boolean)
    : false;

  const isLoading = initialFetchPending || isLoadingReferral;

  function assignRefAndScroll(element: HTMLDivElement) {
    messageContainerRef.current = element;
    if (!element || !enableAutoScroll) return;
    scrollToLatestMessage(element);
  }

  return (
    <div className={styles.messages} ref={assignRefAndScroll}>
      {isLoading && <Loader isCentered />}
      {!isLoading && hasChatErrors && (
        <PendingWarningNotification chatErrors={chatErrors} />
      )}
      {!isLoading && !hasChatErrors && (
        <>
          {areMessagesLoading && (
            <div className={styles.loaderContainer}>
              <Loader />
            </div>
          )}
          {chatMessages?.map((message) => (
            <ChatMessage
              key={message.id}
              message={message}
              currentUserId={userProfile.id}
              openAvailabilityPanel={openAvailabilityPanel}
              isCurrentChatMemberOnline={isMemberOnlineInMessages(message)}
              isDncExpertChat={isDncExpertChat}
            />
          ))}
        </>
      )}
    </div>
  );
};

function scrollToLatestMessage(messagesDivRef: HTMLDivElement): void {
  messagesDivRef.scrollTo({ top: messagesDivRef.scrollHeight });
}
