import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  ProjectNxSelector,
  ProjectNxStoreActions
} from "@arbolus-technologies/stores/project";
import { TimezoneSelect } from "@arbolus-technologies/ui/components";

import styles from "./TimezoneDisplaySelect.module.scss";

export const TimezoneDisplaySelect: React.FC = () => {
  const dispatch = useDispatch();
  const currentTimezoneId = useSelector(ProjectNxSelector.displayTimezoneId());
  const timezones = useSelector(CacheSelector.defaultTimezones());

  return (
    <div className={styles.container}>
      <TimezoneSelect
        timezones={timezones}
        initialTimezone={timezones.find((tz) => tz.id === currentTimezoneId)}
        size="small"
        handleTimezoneChange={(tz) =>
          dispatch(ProjectNxStoreActions.setDisplayTimezone(tz.id))
        }
      />
    </div>
  );
};
