import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";

import { AngleModel } from "@arbolus-technologies/models/common";
import {
  ANGLE_SEARCH_PARAM,
  DropdownAngle
} from "@arbolus-technologies/models/project";
import {
  ProjectNxSelector,
  ProjectNxStoreActions
} from "@arbolus-technologies/stores/project";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { ANGLE_DROPDOWN_DEFAULT_OPTION } from "../../Constants/angle";
import { getCurrentAngleId } from "../../helpers";
import { AngleName } from "../AngleName/AngleName";

import styles from "./AnglesDropdown.module.scss";

interface AnglesDropdownProps {
  projectId: string;
  angles: AngleModel[];
  selectedAngle: DropdownAngle;
  size?: "large" | "medium";
  setSelectedAngle: (angle: DropdownAngle) => void;
}

export const AnglesDropdown: React.FC<AnglesDropdownProps> = ({
  projectId,
  angles,
  selectedAngle,
  size = "medium",
  setSelectedAngle
}) => {
  const {
    replace,
    location: { pathname }
  } = useHistory();
  const [firstRenderDone, setFirstRenderDone] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const storeFilter = useSelector(ProjectNxSelector.dashboardAngleFilter());

  const replacePath = useCallback(
    (angleId?: string) => {
      const search =
        angleId === ANGLE_DROPDOWN_DEFAULT_OPTION.id
          ? ""
          : `${ANGLE_SEARCH_PARAM}=${angleId}`;
      replace({ pathname, search });
    },
    [pathname, replace]
  );

  const handleAngleClick = useCallback(
    (angle: DropdownAngle) => {
      setSelectedAngle(angle);
      setDropdownOpen(false);
      replacePath(angle.id);
      dispatch(
        ProjectNxStoreActions.filterDashboardByAngle(projectId, angle.id)
      );
    },
    [setSelectedAngle, replacePath, dispatch, projectId]
  );

  const setInitialAngleFromRoute = useCallback(
    (angleId: string): void => {
      const initialAngle = angles.find((angle) => angle.id === angleId);
      if (initialAngle) {
        setSelectedAngle(initialAngle);
        dispatch(
          ProjectNxStoreActions.filterDashboardByAngle(projectId, angleId)
        );
      }
    },
    [angles, dispatch, projectId, setSelectedAngle]
  );

  const setInitialAngleFromStore = useCallback(
    (angleId?: string): void => {
      const initialAngle = angles.find((angle) => angle.id === angleId);
      if (initialAngle) {
        setSelectedAngle(initialAngle);
        replacePath(angleId);
      }
    },
    [angles, replacePath, setSelectedAngle]
  );

  useEffect(() => {
    if (firstRenderDone) {
      return;
    }

    setFirstRenderDone(true);

    const currentAngleId = getCurrentAngleId();
    if (currentAngleId) {
      setInitialAngleFromRoute(currentAngleId);
      return;
    }

    if (storeFilter?.projectId === projectId && storeFilter?.angleId) {
      setInitialAngleFromStore(storeFilter.angleId);
    }
  }, [
    angles,
    dispatch,
    firstRenderDone,
    projectId,
    replacePath,
    setInitialAngleFromRoute,
    setInitialAngleFromStore,
    storeFilter
  ]);

  return (
    <Dropdown
      toggle={() => setDropdownOpen((prev) => !prev)}
      isOpen={dropdownOpen}
    >
      <DropdownToggle data-toggle="dropdown" tag="span">
        <button
          className={clsx(styles.dropdownButton, styles[size])}
          data-testid="angles-dropdown-trigger"
        >
          <AngleName
            bold
            size={size}
            title={selectedAngle.title}
            color={selectedAngle.color || ARBOLUS_COLORS.bColorBasePurple}
            textColor={selectedAngle.textColor}
          />
          <Icon
            name="arrow_drop_down"
            fontSize="24px"
            color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
          />
        </button>
      </DropdownToggle>
      <DropdownMenu className={styles.dropdownMenu}>
        <div className={styles.dropdownList}>
          {[ANGLE_DROPDOWN_DEFAULT_OPTION, ...(angles as DropdownAngle[])].map(
            (angle) => (
              <button
                key={angle.id}
                className={styles.dropdownItemButton}
                onClick={() => handleAngleClick(angle)}
              >
                <AngleName
                  bold={angle.id === selectedAngle.id}
                  title={angle.title}
                  color={angle.color || ARBOLUS_COLORS.bColorBasePurple}
                  textColor={angle.textColor}
                />
              </button>
            )
          )}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};
