import React from "react";
import { useSelector } from "react-redux";

import { WorkHistoryEditList } from "@arbolus-technologies/features/common";
import { PageStep } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

import { ExpertApplicationWrapper } from "../../Components/ExpertApplicationWrapper/ExpertApplicationWrapper";

interface CompanyValidationProps {
  name: string;
  setCurrentStep: (step: number) => void;
  steps: PageStep[];
}

export const CompanyValidation: React.FC<CompanyValidationProps> = ({
  name,
  setCurrentStep,
  steps
}) => {
  const expertId = useSelector(CacheSelector.loggedInUserExpertId());

  const currentStep = steps.findIndex((step) => step.name === name);

  return (
    <ExpertApplicationWrapper currentStep={currentStep} name={name}>
      <WorkHistoryEditList
        expertId={expertId}
        onSave={() => setCurrentStep(currentStep + 1)}
        mandatoryTimeFrame
      />
    </ExpertApplicationWrapper>
  );
};
