import { GetContextMenuItemsParams, MenuItemDef } from "ag-grid-community";
import i18next from "i18next";
import moment from "moment";

import { ClipboardManager } from "@arbolus-technologies/features/common";
import {
  AngleModel,
  Referral,
  SlotsReferral
} from "@arbolus-technologies/models/common";
import {
  SHARE_PROJECT_BOOKING,
  SHARE_PROJECT_EXPERT_APPLICATION
} from "@arbolus-technologies/routes";
import { AgGridCustomIcons } from "@arbolus-technologies/ui/components";
import { ExtractMethods } from "@arbolus-technologies/utils";

import {
  IBulkItemActionParams,
  ItemActionParams,
  ReferralGridContext
} from "../../Models/ReferralsTable";
import { IBulkReferralActionsService } from "./BulkReferralActionsService";
import { IExportMenuService } from "./ExportMenuService";

const t = (key: string) => i18next.t(`referrals:actions:${key}`);
const purpleCss = ["base-purple"];
const dangerCss = ["danger-color"];

export type IContextMenuService = ExtractMethods<ContextMenuService>;

export abstract class ContextMenuService {
  protected readonly exportMenuService: IExportMenuService;
  private readonly _bulkActionsService: IBulkReferralActionsService;
  private readonly _clipboardManager: ClipboardManager;

  constructor(
    exportMenuService: IExportMenuService,
    bulkActionsService: IBulkReferralActionsService,
    clipboardManager: ClipboardManager
  ) {
    this.exportMenuService = exportMenuService;
    this._bulkActionsService = bulkActionsService;
    this._clipboardManager = clipboardManager;
  }

  abstract getItems(
    params: GetContextMenuItemsParams<Referral, ReferralGridContext>
  ): (string | MenuItemDef)[];

  protected getSendApplicationItem(
    { api, project, referral, modalService }: ItemActionParams,
    disabled = false
  ): MenuItemDef {
    const action = () =>
      this._bulkActionsService.sendApplication(
        project.id,
        [referral],
        modalService,
        api
      );
    return {
      action,
      disabled,
      name: t("sendApplication"),
      icon: AgGridCustomIcons.send,
      cssClasses: purpleCss
    };
  }

  protected getSendApplicationReminderItem(
    { project, referral, modalService }: ItemActionParams,
    disabled = false
  ): MenuItemDef {
    const action = () =>
      this._bulkActionsService.sendApplicationReminder(
        project.id,
        [referral],
        modalService
      );
    return {
      action,
      disabled,
      name: t("sendApplicationReminder"),
      icon: AgGridCustomIcons.send,
      cssClasses: purpleCss
    };
  }

  protected getMoveToCandidateItem(
    {
      referral,
      project,
      modalService,
      api,
      reloadReferralsCount
    }: ItemActionParams,
    disabled = false
  ): MenuItemDef {
    const action = () =>
      this._bulkActionsService.moveToCandidate(
        project.id,
        [referral],
        modalService,
        api,
        reloadReferralsCount
      );
    return {
      action,
      disabled,
      name: t("moveToCandidate"),
      icon: AgGridCustomIcons.equal,
      cssClasses: purpleCss
    };
  }

  protected getEditAngleItem(
    { referral, project, api, updateAngle }: ItemActionParams,
    disabled = false
  ): MenuItemDef {
    const action = () => {
      const onSuccess = (angle: AngleModel) => {
        const rowNode = api.getRowNode(referral.id);
        if (!rowNode?.data) {
          return;
        }

        const modified = moment.utc().format();
        const update = [
          Referral.fromObject({ ...rowNode.data, angle, modified })
        ];
        api.applyTransaction({ update });
      };
      updateAngle({ referral, projectId: project.id, onSuccess });
    };
    return {
      action,
      disabled,
      name: referral.angle ? t("editAngle") : t("assignAngle"),
      icon: AgGridCustomIcons.angle
    };
  }

  protected getAddToAnotherProjectItem(
    params: IBulkItemActionParams
  ): MenuItemDef {
    const { openAssignExpertToProject, api } = params;
    const action = () => {
      const referrals = api.getSelectedRows();
      api.deselectAll();
      openAssignExpertToProject(referrals);
    };
    return {
      action,
      name: t("addToAnotherProject"),
      icon: AgGridCustomIcons.newFolder
    };
  }

  protected getCopyApplicationItem(
    { project }: ItemActionParams,
    disabled = false
  ): MenuItemDef {
    const action = () => {
      this._clipboardManager.copy(SHARE_PROJECT_EXPERT_APPLICATION(project.id));
    };
    return {
      action,
      disabled,
      name: t("copyApplicationLink"),
      icon: AgGridCustomIcons.copy
    };
  }

  protected getCopyBookingItem(
    { project, referral }: ItemActionParams,
    disabled = false
  ): MenuItemDef {
    const action = () => {
      this._clipboardManager.copy(
        SHARE_PROJECT_BOOKING(project.id, referral.id)
      );
    };
    return {
      action,
      disabled: disabled || !(referral as SlotsReferral).slots?.length,
      name: t("copyBookingLink"),
      icon: AgGridCustomIcons.copy
    };
  }

  protected getCopyEmailItem(
    { referral }: ItemActionParams,
    disabled = false
  ): MenuItemDef {
    const action = () => {
      this._clipboardManager.copy(referral.expert.email);
    };
    return {
      action,
      disabled,
      name: referral.expert.email,
      icon: AgGridCustomIcons.email
    };
  }

  protected getCopyPhoneItem(
    { referral }: ItemActionParams,
    disabled = false
  ): MenuItemDef {
    const hasPhone = !!referral.expert.phoneNumber;
    const action = () => {
      this._clipboardManager.copy(referral.expert.phoneNumber!);
    };
    return {
      action,
      disabled: disabled || !hasPhone,
      name: hasPhone
        ? referral.expert.phoneNumber!
        : i18next.t("referrals:noPhone"),
      icon: AgGridCustomIcons.phone
    };
  }

  protected getResetApplicationItem(
    { api, project, referral, modalService }: ItemActionParams,
    disabled = false
  ): MenuItemDef {
    const action = () =>
      this._bulkActionsService.resetApplication(
        project.id,
        [referral],
        modalService,
        api
      );
    return {
      action,
      disabled,
      name: t("resetApplication"),
      icon: AgGridCustomIcons.reset
    };
  }

  protected getRemoveItem(
    {
      api,
      project,
      referral,
      modalService,
      reloadReferralsCount
    }: ItemActionParams,
    disabled = false
  ): MenuItemDef {
    const action = () => {
      this._bulkActionsService.removeFromProject(
        project.id,
        [referral],
        modalService,
        api,
        reloadReferralsCount
      );
    };
    return {
      action,
      disabled,
      name: t("removeExpert"),
      icon: AgGridCustomIcons.delete,
      cssClasses: dangerCss
    };
  }

  protected getReactivateItem(
    {
      referral,
      project,
      modalService,
      api,
      reloadReferralsCount
    }: ItemActionParams,
    disabled = false
  ): MenuItemDef {
    const action = () =>
      this._bulkActionsService.reactivate(
        project.id,
        [referral],
        modalService,
        api,
        reloadReferralsCount
      );
    return {
      action,
      disabled,
      name: t("reactivate"),
      icon: AgGridCustomIcons.equal,
      cssClasses: purpleCss
    };
  }
}
