import React from "react";
import { useTranslation } from "react-i18next";

import { DiscoverFilterType } from "@arbolus-technologies/models/project";
import { SearchInputBase } from "@arbolus-technologies/ui/components";

import { useDiscoverFilters } from "../../../../Hooks/Discover/useDiscoverFilters";
import { useDiscoverKeywordFilter } from "../../../../Hooks/Discover/useDiscoverKeywordFilter";
import { FilterWrapper } from "../FilterWrapper/FilterWrapper";
import { SelectedFilterPills } from "../SelectedFIlterPills/SelectedFilterPills";
import { KeywordsSearchOptions } from "./KeywordsSearchOptions";

export const KeywordsFilter: React.FC = () => {
  const { t } = useTranslation("discoverPage");

  const { selectedOptions, setSelectedOptions, canClearFilter } =
    useDiscoverFilters({ filterType: DiscoverFilterType.Keywords });

  const {
    handleClearQueryClicked,
    handleInputChangeEvent,
    searchQuery,
    handleKeyDown,
    urlChangeOnKeywordFilterUpdate,
    handleClearFilter,
    handleSetKeywordsSearchOptions,
    handleKeywordEdit
  } = useDiscoverKeywordFilter();

  return (
    <FilterWrapper
      title={t("keywordsFilterTitle")}
      tooltipText={t("keywordsFilterDescription")}
      canClearFilter={canClearFilter}
      onClear={handleClearFilter}
      filterCounter={selectedOptions.length}
      isByDefaultOpen
    >
      <SelectedFilterPills
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        disablePillToggle
        urlChangeOnFilterUpdate={urlChangeOnKeywordFilterUpdate}
        isEditable
        onEdit={handleKeywordEdit}
      />
      <SearchInputBase
        handleClearQueryClicked={handleClearQueryClicked}
        searchQuery={searchQuery}
        handleInputChangeEvent={handleInputChangeEvent}
        handleKeyDown={handleKeyDown}
        placeholder={t("keywordsFilterPlaceholder")}
        disabled={selectedOptions.length === 1}
        multiline
      />
      <KeywordsSearchOptions
        handleSetKeywordsSearchOptions={handleSetKeywordsSearchOptions}
      />
    </FilterWrapper>
  );
};
