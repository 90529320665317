import clsx from "clsx";
import React from "react";

import { CircleWithIcon } from "../../CirceWithIcon/CircleWithIcon";
import { IThreeDotsItem, ThreeDots } from "../../ThreeDots/ThreeDots";
import { BoxWithChildren } from "../BoxWithChildren/BoxWithChildren";

import styles from "./BoxWithIconTitleWithSubtitle.module.scss";

interface BoxWithIconTitleWithSubtitleProps {
  title: string;
  subtitle: string;
  specialBorder?: string;
  icon: {
    iconName: string;
    color: string;
    background: string;
    border?: string;
  };
  threeDots?: {
    popoverId: string;
    threeDotsItems: IThreeDotsItem[];
  };
  classnames?: string;
  to?: string;
  noEllipsis?: boolean;
}
export const BoxWithIconTitleWithSubtitle: React.FC<
  BoxWithIconTitleWithSubtitleProps
> = ({
  title,
  subtitle,
  icon,
  specialBorder,
  threeDots,
  classnames,
  to,
  noEllipsis
}) => {
  const { iconName, color, background, border } = icon;
  return (
    <BoxWithChildren
      fullWidth
      to={to}
      specialBorder={specialBorder}
      classnames={classnames}
    >
      <div
        className={clsx(
          styles.container,
          noEllipsis && styles.noEllipsisContainer
        )}
      >
        <div className={styles.iconContainer}>
          <div>
            <CircleWithIcon
              icon={iconName}
              color={color}
              background={background}
              border={border}
            />
          </div>
        </div>
        <div className={styles.textContainer}>
          <div className={styles.title} title={title}>
            {title}
          </div>
          <div className={styles.subtitle} title={subtitle}>
            {subtitle}
          </div>
        </div>
        {threeDots && (
          <div className={styles.threeDotsContainer}>
            <ThreeDots
              popoverId={threeDots.popoverId}
              items={threeDots.threeDotsItems}
            />
          </div>
        )}
      </div>
    </BoxWithChildren>
  );
};
