import i18next from "i18next";
import React from "react";
import { Control, FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, Input } from "reactstrap";

import {
  DownloadCanopyInterface,
  FileInterface,
  UploadingAttachment
} from "@arbolus-technologies/api";
import {
  MAXIMUM_FILES_UPLOAD_AT_ONCE,
  MAXIMUM_FILE_NAME_LENGTH,
  MAXIMUM_FILE_UPLOADING_SIZE,
  MAXIMUM_LINKS_AT_ONCE
} from "@arbolus-technologies/models/documents";
import {
  InputGroupController,
  SURVEY_FORM_TYPE
} from "@arbolus-technologies/ui/components";
import { utilService } from "@arbolus-technologies/utils";

import { isSupportedFileType } from "../../documentUtils";

import styles from "./ReferenceMaterialController.module.scss";

interface ReferenceMaterialControllerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: FieldErrors<any>;
  uploadingFiles: UploadingAttachment[];
  links: FileInterface[];
  documents: DownloadCanopyInterface[];
  type?: string;
  linkAppend: (link: FileInterface) => void;
  linkRemove: (index: number) => void;
  downloadAppend: (document: DownloadCanopyInterface) => void;
  downloadRemove: (index: number) => void;
  showError: (error: string) => void;
  setUploadingFiles: (files: UploadingAttachment[]) => void;
  setDocumentsToRemove?: (id: string) => void;
}

const handleBrowserCloseListener = (event: BeforeUnloadEvent): void => {
  event.preventDefault();
  // eslint-disable-next-line no-param-reassign
  event.returnValue = "";
};

const handleUploadStateChanges = (
  uploadingFiles: UploadingAttachment[]
): void => {
  const isUploading = Boolean(uploadingFiles.length);
  if (isUploading) {
    window.addEventListener("beforeunload", handleBrowserCloseListener);
  } else {
    window.removeEventListener("beforeunload", handleBrowserCloseListener);
  }
};

export const ReferenceMaterialController: React.FC<
  ReferenceMaterialControllerProps
> = ({
  control,
  errors,
  uploadingFiles,
  links,
  documents,
  type = SURVEY_FORM_TYPE.CREATE,
  linkAppend,
  linkRemove,
  downloadRemove,
  downloadAppend,
  showError,
  setUploadingFiles,
  setDocumentsToRemove
}) => {
  const { t } = useTranslation("createSurvey");

  const ValidateFileAttributes = (file: File) => {
    // Validate file type
    const isSupported = isSupportedFileType(file);
    if (!isSupported) {
      showError(i18next.t("createSurvey:unsupported"));
      return false;
    }
    // Validate file size
    if (file.size >= MAXIMUM_FILE_UPLOADING_SIZE) {
      showError(
        i18next.t("createSurvey:fileSizeLimitError", {
          limit: MAXIMUM_FILE_UPLOADING_SIZE / 1024 ** 2
        })
      );
      return false;
    }
    // Validate file name
    const isFileNameTooLong = file.name.length >= MAXIMUM_FILE_NAME_LENGTH;
    if (isFileNameTooLong) {
      showError(
        i18next.t("createSurvey:fileNameMaxLengthError", {
          length: MAXIMUM_FILE_NAME_LENGTH
        })
      );
      return false;
    }
    return true;
  };

  const getNewUploadFiles = (
    uploadingFiles: UploadingAttachment[],
    selectedFiles: FileList
  ) => {
    const newUploadFiles = Array.from(selectedFiles)
      .filter((file: File) => ValidateFileAttributes(file))
      .map((file: File) => ({
        attachment: file,
        attachmentId: utilService.generateUUID()
      }));
    const availableUploadSlots =
      MAXIMUM_FILES_UPLOAD_AT_ONCE - uploadingFiles.length;
    if (availableUploadSlots < newUploadFiles.length) {
      showError(i18next.t("createSurvey:fileCountLimitError"));
    }
    newUploadFiles.splice(MAXIMUM_FILES_UPLOAD_AT_ONCE - uploadingFiles.length);
    return newUploadFiles;
  };

  const removeFile = (indexId: number, downloadsFieldId: string) => {
    setUploadingFiles(
      uploadingFiles.filter((file) => file.attachmentId !== downloadsFieldId)
    );
    const initialDocumentsIds = documents?.map((el) => el.id);
    downloadRemove(indexId);
    if (
      type === SURVEY_FORM_TYPE.EDIT &&
      initialDocumentsIds.includes(downloadsFieldId) &&
      setDocumentsToRemove
    ) {
      setDocumentsToRemove(downloadsFieldId);
    }
  };

  const handleFileSelected = (selectedFiles: FileList): void => {
    if (documents.length >= MAXIMUM_FILES_UPLOAD_AT_ONCE) {
      showError(
        i18next.t("createSurvey:maxFiles", {
          length: MAXIMUM_FILES_UPLOAD_AT_ONCE
        })
      );
    } else {
      if (uploadingFiles.length < MAXIMUM_FILES_UPLOAD_AT_ONCE) {
        let newUploadFiles = getNewUploadFiles(uploadingFiles, selectedFiles);
        newUploadFiles.forEach((newUpload) => {
          downloadAppend({
            fileName: newUpload.attachment.name,
            id: newUpload.attachmentId,
            fileKey: newUpload.attachmentId,
            downloadUrl: ""
          });
        });
        newUploadFiles = uploadingFiles.concat(newUploadFiles);
        setUploadingFiles(newUploadFiles);
        handleUploadStateChanges(uploadingFiles);
      }
    }
  };

  return (
    <>
      <FormGroup className={styles.referenceMaterialGroup}>
        {links.map((link, index) => (
          <InputGroupController
            key={link?.id}
            name={`links.${index}.url`}
            index={index}
            errors={errors}
            control={control}
            placeholder={t("enterUrl")}
            inputType="text"
            remove={() => linkRemove(index)}
          />
        ))}
        {documents.map((downloadsField, index) => (
          <div key={downloadsField.id}>
            <InputGroupController
              name={`documents.${index}.fileName`}
              index={index}
              errors={errors}
              control={control}
              placeholder={t("selectFile")}
              readonly
              inputType="text"
              remove={(indexId) => removeFile(indexId, downloadsField.id)}
            />
          </div>
        ))}
      </FormGroup>
      <div className={styles.addContainer}>
        <div className={styles.addButtonContainerTop}>
          <div className={styles.addButtonContainerTopInner}>
            <div className="form-file-upload">
              <Button size="sm" color="primary" className={styles.addField}>
                <span className="ciq-icon ciq-plus" />
              </Button>
              <Input
                id="upload_doc_input"
                type="file"
                onChange={({
                  target
                }: React.ChangeEvent<HTMLInputElement>): void => {
                  if (target.files && target.files?.length > 0) {
                    handleFileSelected(target.files);
                  }
                  // eslint-disable-next-line no-param-reassign
                  target.value = "";
                }}
              />
            </div>
            <span className={styles.labelAddField}>
              {t("documentsOrImages")}
            </span>
          </div>
          <div className={styles.infoContainer}>
            <p>{t("fileFormats")}</p>
            <p>{t("maximumSize")}</p>
          </div>
          <hr />
        </div>
        <div className={styles.addButtonContainerBottom}>
          <div className={styles.addButtonContainerBottomInner}>
            <Button
              size="sm"
              color="primary"
              className={styles.addField}
              onClick={() => {
                if (links.length < MAXIMUM_LINKS_AT_ONCE) {
                  linkAppend({ url: "" });
                } else {
                  showError(
                    i18next.t("createSurvey:maxLinks", {
                      length: MAXIMUM_LINKS_AT_ONCE
                    })
                  );
                }
              }}
            >
              <i className="ciq-icon ciq-plus" />
            </Button>
            <span className={styles.labelAddField}>{t("link")}</span>
          </div>
        </div>
      </div>
      <hr />
    </>
  );
};
