import i18next from "i18next";
import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { OutlinedChip } from "@arbolus-technologies/ui/components";

const pending = i18next.t("referrals:circles:pending");
const declined = i18next.t("referrals:circles:declined");
const passed = i18next.t("referrals:circles:passed");
const completed = i18next.t("referrals:circles:completed");
const rejected = i18next.t("referrals:circles:rejected");
const approved = i18next.t("referrals:circles:approved");
const unavailable = i18next.t("referrals:circles:unavailable");

export const PendingRenderer: React.FC = () => (
  <OutlinedChip text={pending} color={ARBOLUS_COLORS.bColorSecondaryOrange} />
);

export const PassedRenderer: React.FC = () => (
  <OutlinedChip text={passed} color={ARBOLUS_COLORS.bColorAccentGreenDark} />
);

export const CompletedRenderer: React.FC = () => (
  <OutlinedChip text={completed} color={ARBOLUS_COLORS.bColorAccentGreenDark} />
);

export const RejectedRenderer: React.FC = () => (
  <OutlinedChip text={rejected} color={ARBOLUS_COLORS.bColorBaseOrange} />
);

export const DeclinedRenderer: React.FC = () => (
  <OutlinedChip text={declined} color={ARBOLUS_COLORS.bColorBaseOrange} />
);

export const UnavailableRenderer: React.FC = () => (
  <OutlinedChip
    text={unavailable}
    color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
  />
);

export const ApprovedRenderer: React.FC = () => (
  <OutlinedChip text={approved} color={ARBOLUS_COLORS.bColorAccentGreenDark} />
);
