import React from "react";
import { useTranslation } from "react-i18next";

import { EmptyComponent } from "../EmptyComponent";

import robot from "../../../assets/images/robot.png";

export const AISummaryWarning: React.FC = () => {
  const { t } = useTranslation("canopySummary");
  return (
    <EmptyComponent
      title={t("aISummaryWarningTitleWarning")}
      subtitle={t("aISummaryWarningSubtitleWarning")}
      logo={robot}
    />
  );
};
