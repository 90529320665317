import i18next from "i18next";

import { ANSWER_TYPE } from "@arbolus-technologies/models/canopy";
import { PageStep } from "@arbolus-technologies/models/common";

import { Details } from "../Modules/Details/Details";
import { QuestionsList } from "../Modules/QuestionsList/QuestionsList";

export const DEFAULT_VALUE = "X";

export const CANOPY_CREATION_STEPS: PageStep[] = [
  {
    name: i18next.t("canopyBuilder:canopyDetails"),
    StepComponent: Details,
    stepIcon: "info"
  },
  {
    name: i18next.t("canopyBuilder:questions"),
    StepComponent: QuestionsList,
    stepIcon: "quiz"
  }
];

export const CANOPY_CREATION_STEPS_GENERATING: PageStep[] = [
  {
    name: i18next.t("canopyBuilder:canopyDetails"),
    StepComponent: Details,
    stepIcon: "info"
  },
  {
    name: i18next.t("canopyBuilder:generatingQuestions"),
    StepComponent: QuestionsList,
    stepIcon: "quiz",
    disabled: true
  }
];

export const CANOPY_QUESTION_ICONS = {
  [ANSWER_TYPE.VIDEO]: "videocam",
  [ANSWER_TYPE.MULTIPLE_CHOICE]: "notes",
  [ANSWER_TYPE.VALUE]: "numbers",
  [ANSWER_TYPE.SHORT_TEXT]: "short_text",
  [ANSWER_TYPE.NPS]: "analytics"
};
