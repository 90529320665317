import { ICellRendererParams } from "ag-grid-community";
import React from "react";

import { IReferralsService } from "@arbolus-technologies/api";
import { Referral } from "@arbolus-technologies/models/common";
import { Loader } from "@arbolus-technologies/ui/components";

import { ReferralGridContext } from "../../Models/ReferralsTable";
import { ReferralSlotsDetail } from "./ReferralSlotsDetail";

type Props = ICellRendererParams<Referral, unknown, ReferralGridContext> & {
  referralsService: IReferralsService;
};

export const LoadSlotsDetail: React.FC<Props> = (props) => {
  const { data, node, context, api, referralsService } = props;

  React.useEffect(
    function fetchSlots() {
      if (data && data.slots === undefined) {
        referralsService
          .getSlots(context.project.id, data.expert.expertId)
          .subscribe({
            next: (slots) => {
              node.data?.setSlots(slots);
              api.applyTransaction({ update: [node.data!] });
            }
          });
      }
    },
    [context, data, referralsService, api, node]
  );

  if (!data) {
    return null;
  }

  if (data.slots === undefined) {
    return <Loader />;
  }

  return (
    <ReferralSlotsDetail slots={data.slots} context={context} referral={data} />
  );
};
