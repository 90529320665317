import { useField } from "formik";
import React from "react";

import { CustomSwitch } from "@arbolus-technologies/ui/components";

interface NotificationSettingItemProps {
  title: string;
  subTitle?: string;
  fieldKey: string;
  isSaving: boolean;
}

const NotificationSettingItem: React.FC<NotificationSettingItemProps> = ({
  title,
  subTitle,
  fieldKey,
  isSaving
}: NotificationSettingItemProps): JSX.Element => {
  const [value, , helpers] = useField<boolean>(fieldKey);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    helpers.setValue(e.target.checked);
  };

  return (
    <div className="notification-setting-item">
      <div className="left-container">
        <h5>{title}</h5>
        <p>{subTitle}</p>
      </div>
      <div className="right-container">
        <CustomSwitch
          name={title}
          checked={value.value}
          onChange={handleChange}
          disabled={isSaving}
        />
      </div>
    </div>
  );
};

export default NotificationSettingItem;
