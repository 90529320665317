import { Handlers, createReducer } from "reduxsauce";

import {
  GET_BOOKMARKS_LIST,
  GET_BOOKMARKS_LIST_FAILURE,
  GET_BOOKMARKS_LIST_SUCCESS,
  RESET_BOOKMARKS_LIST
} from "./actions/GetBookmarksList/GetBookmarksListActionTypes";
import {
  GET_COLLECTION,
  GET_COLLECTION_FAILURE,
  GET_COLLECTION_SUCCESS
} from "./actions/GetCollection/GetCollectionActionTypes";
import { RENAME_COLLECTION } from "./actions/RenameCollection/RenameCollectionActionTypes";
import { BookmarksReducerState } from "./models/definitions";
import {
  handleGetBookmarksList,
  handleGetBookmarksListFailure,
  handleGetBookmarksListSuccess,
  handleResetBookmarksList
} from "./reducers/HandleGetBookmarksListReducer";
import {
  handleGetCollection,
  handleGetCollectionFailure,
  handleGetCollectionSuccess
} from "./reducers/HandleGetCollectionReducer";
import { handleRenameCollection } from "./reducers/HandleRenameCollectionReducer";
import { initialState } from "./reducers/InitialState";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handlers: Handlers<BookmarksReducerState, any> = {
  [GET_COLLECTION]: handleGetCollection,
  [GET_COLLECTION_SUCCESS]: handleGetCollectionSuccess,
  [GET_COLLECTION_FAILURE]: handleGetCollectionFailure,
  [RENAME_COLLECTION]: handleRenameCollection,
  [GET_BOOKMARKS_LIST]: handleGetBookmarksList,
  [GET_BOOKMARKS_LIST_SUCCESS]: handleGetBookmarksListSuccess,
  [GET_BOOKMARKS_LIST_FAILURE]: handleGetBookmarksListFailure,
  [RESET_BOOKMARKS_LIST]: handleResetBookmarksList
};

export const BookmarksReducer = createReducer(initialState, handlers);
