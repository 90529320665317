import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import { LocationDescriptor } from "history";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import { NotificationBadge } from "../Badges/NotificationBadge/NotificationBadge";

import styles from "./Tab.module.scss";

interface TabProps {
  className?: string;
  text: string;
  badgeCount?: number;
  icon?: string;
  onClick?: () => void;
  isActive?: boolean;
  to?: LocationDescriptor;
  disabled?: boolean;
}

export const Tab: React.FC<TabProps> = ({
  className,
  text,
  badgeCount,
  icon,
  isActive = false,
  onClick,
  to,
  disabled
}) => {
  const buttonMemoized = useMemo(
    () => (
      <button
        className={clsx(styles.tab, className, isActive && styles.active)}
        onClick={onClick}
        disabled={disabled}
      >
        {icon && (
          <div className={styles.icon}>
            <Icon name={icon} fontSize="16px" />
          </div>
        )}
        {text}
        {badgeCount && badgeCount > 0 ? (
          <NotificationBadge count={badgeCount} />
        ) : null}
      </button>
    ),
    [badgeCount, className, disabled, icon, isActive, onClick, text]
  );

  return to ? <Link to={to}>{buttonMemoized}</Link> : buttonMemoized;
};
