import { MbscCalendarEvent } from "@mobiscroll/react";
import React, { useState } from "react";

import { ProjectExpertAvailability, Slot } from "@arbolus-technologies/api";
import { ExpertAvailabilityScheduler } from "@arbolus-technologies/features/availability";
import { SelectOption } from "@arbolus-technologies/models/common";
import { ProjectApplicationStepDetails } from "@arbolus-technologies/models/project";

import { ProjectApplicationWrapper } from "../ProjectApplicationWrapper/ProjectApplicationWrapper";

interface ExpertSchedulerWrapperProps {
  projectTimezone: string;
  currentTimezone: string;
  expertAvailabilitySlotsSelected: Slot[];
  timezones: Map<string, SelectOption>;
  isLoading: boolean;
  stepDetails: ProjectApplicationStepDetails;
  onSaveProgress: (
    values: ProjectExpertAvailability,
    isManualSave: boolean
  ) => void;
  onBack?: () => void;
}

export const ExpertSchedulerWrapper: React.FC<ExpertSchedulerWrapperProps> = ({
  projectTimezone,
  currentTimezone,
  expertAvailabilitySlotsSelected,
  timezones,
  isLoading,
  stepDetails,
  onSaveProgress,
  onBack
}) => {
  const [selectedSlots, setSelectedSlots] = useState<MbscCalendarEvent[]>([]);
  const [expertTimezone, setExpertTimezone] = useState<string>(currentTimezone);

  const isValid = !!selectedSlots.length;

  const updateAvailabilityDetails = (availabilityDetails: {
    expertAvailabilitySlots: MbscCalendarEvent[];
    expertTimezone: string;
  }): void => {
    setSelectedSlots(availabilityDetails.expertAvailabilitySlots);
    setExpertTimezone(availabilityDetails.expertTimezone);
  };

  const handleSaveProgress = (isManualSave = true) => {
    const expertAvailabilitySlots: Slot[] = selectedSlots.map((slot) => ({
      startTime: slot.start as string,
      endTime: slot.end as string
    }));

    onSaveProgress(
      {
        expertAvailabilitySlots,
        expertTimezone,
        hasBeenRequiredMoreAvailabilitySlots: false
      },
      isManualSave
    );
  };

  return (
    <ProjectApplicationWrapper
      isValid={isValid}
      dirty
      stepDetails={stepDetails}
      onSaveProgress={() => handleSaveProgress(true)}
      onNext={() => handleSaveProgress(false)}
      onBack={onBack}
      isLoading={isLoading}
    >
      <ExpertAvailabilityScheduler
        projectTimezone={projectTimezone}
        currentTimezone={currentTimezone}
        timezones={timezones}
        expertAvailabilitySlotsSelected={expertAvailabilitySlotsSelected}
        onSaveProgress={onSaveProgress}
        updateAvailabilityDetails={updateAvailabilityDetails}
        isAdmin={false}
        isApplicationProcess
      />
    </ProjectApplicationWrapper>
  );
};
