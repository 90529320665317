import { Slider } from "antd";
import { RadioButton } from "arbolus-ui-components";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  DiscoverFilterOperator,
  DiscoverFilterType,
  FilterTimeSelectorOptions
} from "@arbolus-technologies/models/project";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";

import { useDiscoverCompanyFilter } from "../../../../Hooks/Discover/useDiscoverCompanyFilter";
import { useDiscoverFilters } from "../../../../Hooks/Discover/useDiscoverFilters";
import { FilterWrapper } from "../FilterWrapper/FilterWrapper";
import { SearchWithCheckboxPills } from "../SearchWithCheckboxPills/SearchWithCheckboxPills";
import { periodFilterOptions } from "../utils";

import styles from "../FilterWrapper/FilterWrapper.module.scss";

const marks = [
  {
    label: i18next.t("discoverPage:notSet"),
    style: { transform: "translateX(-7px)", marginTop: "4px" }
  },
  {
    label: i18next.t("discoverPage:6months"),
    style: { marginTop: "4px" }
  },
  {
    label: i18next.t("discoverPage:12months"),
    style: { marginTop: "4px" }
  },
  {
    label: i18next.t("discoverPage:18months"),
    style: { marginTop: "4px" }
  },
  {
    label: i18next.t("discoverPage:24months"),
    style: { marginTop: "4px" }
  },
  {
    label: i18next.t("discoverPage:30months"),
    style: { width: "64px", marginTop: "4px" }
  },
  {
    label: i18next.t("discoverPage:36months"),
    style: { transform: "translateX(-58px)", width: "64px", marginTop: "4px" }
  }
];

export const CompaniesFilter: React.FC = () => {
  const { t } = useTranslation("discoverPage");

  const {
    selectedOptions,
    setSelectedOptions,
    canClearFilter,
    preSelectedOptions,
    setPreSelectedOptions
  } = useDiscoverFilters({ filterType: DiscoverFilterType.Company });
  const {
    handleBottomReached,
    handleClearQueryClicked,
    handleInputChangeEvent,
    handleSelectOption,
    handleSetCompanyFilterPeriod,
    filterOptions,
    applyCompanyFilter,
    urlChangeOnCompanyFilterUpdate,
    handleClearFilter,
    handleSelectAll,
    handleSetCompanyInPastMonths
  } = useDiscoverCompanyFilter();

  const companyFilterPeriod = useSelector(
    ProjectNxSelector.companyFilterPeriod()
  );
  const companyFilterQuery = useSelector(ProjectNxSelector.filtersQuery())[
    DiscoverFilterType.Company
  ];

  const companyInPastMonths =
    useSelector(ProjectNxSelector.companyInPastMonths()) ?? 0;

  const isAnyCompanyExcluded = selectedOptions.some(
    (option) => option.operator === DiscoverFilterOperator.Exclude
  );

  return (
    <FilterWrapper
      title={t("companiesFilterTitle")}
      canClearFilter={canClearFilter}
      onClear={handleClearFilter}
      filterCounter={selectedOptions.length}
    >
      <SearchWithCheckboxPills
        onClearQueryClicked={handleClearQueryClicked}
        filterOptions={filterOptions}
        onInputChangeEvent={handleInputChangeEvent}
        searchQuery={companyFilterQuery}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        placeholder={t("companiesFilterPlaceholder")}
        onBottomReached={handleBottomReached}
        onSelectOption={handleSelectOption}
        applyFilter={applyCompanyFilter}
        preSelectedOptions={preSelectedOptions}
        setPreSelectedOptions={setPreSelectedOptions}
        urlChangeOnFilterUpdate={urlChangeOnCompanyFilterUpdate}
        handleSelectAll={handleSelectAll}
      />
      <div className={styles.radioButtonsContainer}>
        {periodFilterOptions.map((option) => (
          <RadioButton
            key={option.value}
            option={option}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleSetCompanyFilterPeriod(event.target.value);
            }}
            isChecked={companyFilterPeriod === option.value}
          />
        ))}
      </div>
      {!isAnyCompanyExcluded &&
        companyFilterPeriod === FilterTimeSelectorOptions.PastNotCurrent && (
          <Slider
            min={0}
            max={marks.length - 1}
            step={1}
            value={companyInPastMonths}
            dots
            tooltip={{ formatter: null }}
            marks={{
              [companyInPastMonths]: marks[companyInPastMonths]
            }}
            onChange={handleSetCompanyInPastMonths}
          />
        )}
    </FilterWrapper>
  );
};
