import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { createStructuredSelector } from "reselect";

import { AuthRouteConstants } from "../../../../constants";
import { CIQUserFeatures } from "../../../../models/view/user";
import { AppState } from "../../../../store/reducers";
import { AuthSelector } from "../../../auth/store";

interface FeatureProtectionStoreProps {
  features: CIQUserFeatures;
}

type FeatureProtectionProps = FeatureProtectionStoreProps;

export function withFeatureProtection(
  WrappedComponent: React.ComponentType,
  featureName: keyof CIQUserFeatures
) {
  const featureProtectedComponent = class FeaturedComponent extends React.PureComponent<FeatureProtectionStoreProps> {
    render() {
      const { features, ...wrappedComponentProps } = this.props;
      const featureEnabled = _.get(features, featureName);

      return featureEnabled ? (
        <WrappedComponent {...wrappedComponentProps} />
      ) : (
        <Redirect to={{ pathname: AuthRouteConstants.DASHBOARD }} />
      );
    }
  };

  const mapStateToProps = createStructuredSelector<
    AppState,
    FeatureProtectionProps,
    FeatureProtectionStoreProps
  >({
    features: AuthSelector.authClientUserFeaturesSelector()
  });

  return connect(mapStateToProps)(featureProtectedComponent);
}
