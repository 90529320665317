import {
  CalendarNav,
  CalendarNext,
  CalendarPrev,
  CalendarToday
} from "@mobiscroll/react";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import { SelectOption } from "@arbolus-technologies/models/common";
import { CustomSelect } from "@arbolus-technologies/ui/components";
import { TimezoneService } from "@arbolus-technologies/utils";

import styles from "./ExpertAvailabilitySchedulerHeader.module.scss";

interface ExpertAvailabilitySchedulerHeaderProps {
  projectTimezone: string;
  expertTimezone: string;
  timezones: Map<string, SelectOption>;
  handleTimezoneChange: (value: string) => void;
  isAdmin: boolean;
}

const ExpertAvailabilitySchedulerHeader: React.FC<
  ExpertAvailabilitySchedulerHeaderProps
> = ({
  projectTimezone,
  expertTimezone,
  timezones,
  handleTimezoneChange,
  isAdmin
}) => {
  const { t } = useTranslation("expertAvailabilityScheduler");
  const [timezoneDifference, setTimezoneDifference] = useState<string>("");
  const [showTooltip, setShowTooltip] = useState<boolean>(true);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setShowTooltip(false);
    }, 5000);

    return () => {
      clearTimeout(timeOutId);
    };
  }, []);

  useEffect(() => {
    const timeDifferenceText = TimezoneService.getTimezoneDifference(
      expertTimezone,
      projectTimezone
    );

    setTimezoneDifference(timeDifferenceText);
  }, [projectTimezone, expertTimezone]);

  return (
    <div
      className={clsx(
        styles.expertAvailabilitySchedulerHeader,
        isMobile ? styles.mobileOnly : ""
      )}
    >
      <div className={styles.expertAvailabilitySchedulerHeaderTitleGroup}>
        <CalendarNav
          className={styles.expertAvailabilitySchedulerHeaderTitle}
        />
        <div className={styles.expertAvailabilitySchedulerHeaderNavigation}>
          <CalendarPrev />
          <CalendarToday />
          <CalendarNext />
        </div>
      </div>
      {!isAdmin && (
        <div className={styles.dropdownWrapper}>
          <span
            data-title={t("timezoneTooltip")}
            className={showTooltip ? styles.show : ""}
          >
            <span className="material-symbols-sharp">public</span>
            <CustomSelect
              options={Array.from(timezones.values())}
              defaultValue={timezones.get(expertTimezone)}
              onSelectChange={handleTimezoneChange}
              noOptionsMessage={t("noTimezone")}
              displayIcon
              isSearchable
              placeholder={t("timezoneNotSet")}
              classNamePrefix="ciqs-select"
            />
          </span>
          {timezoneDifference}
        </div>
      )}
    </div>
  );
};

export default ExpertAvailabilitySchedulerHeader;
