import { ReferralsTabCount } from "../../models/definitions";
import { PREFIX } from "../ProjectActionTypes";

export const LOAD_REFERRAL_TAB_COUNT = `${PREFIX}_LOAD_REFERRAL_TAB_COUNT`;
export interface LoadReferralTabCountAction {
  type: typeof LOAD_REFERRAL_TAB_COUNT;
  payload: {
    projectId: string;
  };
}

export const LOAD_REFERRAL_TAB_COUNT_SUCCESS = `${PREFIX}_LOAD_REFERRAL_TAB_COUNT_SUCCESS`;
export interface LoadReferralTabCountSuccessAction {
  type: typeof LOAD_REFERRAL_TAB_COUNT_SUCCESS;
  payload: ReferralsTabCount;
}

export const LOAD_REFERRAL_TAB_COUNT_FAILURE = `${PREFIX}_LOAD_REFERRAL_TAB_COUNT_FAILURE`;
export interface LoadReferralTabCountFailureAction {
  type: typeof LOAD_REFERRAL_TAB_COUNT_FAILURE;
}
