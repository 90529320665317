import i18next from "i18next";
import * as Yup from "yup";

import {
  CONTACT_TYPE_ENUM,
  ExpertPipelineRateCard,
  ExternalExpertFormEnum,
  ExternalExpertFormValues
} from "@arbolus-technologies/models/expert";
import {
  ExpertProfileConstraints,
  UserConstraints
} from "@arbolus-technologies/ui/components";
import { utilService } from "@arbolus-technologies/utils";

const {
  PROJECT_PIPELINE_STATUS,
  PROJECT_PIPELINE_STATUS_COMMENT,
  PROJECT_PIPELINE_EMAILS,
  CUSTOM_EMAIL,
  PROJECT_PIPELINE_PHONES,
  CUSTOM_PHONE,
  RATE_CARD
} = ExternalExpertFormEnum;

export const ExternalExpertFormSchema: Yup.ObjectSchema<ExternalExpertFormValues> =
  Yup.object<ExternalExpertFormValues>().shape({
    [PROJECT_PIPELINE_STATUS]: Yup.mixed<CONTACT_TYPE_ENUM>(),
    [PROJECT_PIPELINE_STATUS_COMMENT]: Yup.string().nullable(),
    [PROJECT_PIPELINE_EMAILS]: Yup.array()
      .of(
        Yup.object<{ email: string }>().shape({
          email: Yup.string()
        })
      )
      .test(
        "min-length",
        i18next.t("externalExpertForm:emailLengthError"),
        (arr: string[]) => arr.length > 0
      ),
    [CUSTOM_EMAIL]: Yup.string()
      .email(i18next.t("externalExpertForm:emailFormatError"))
      .max(
        UserConstraints.MAX_EMAIL_LENGTH,
        i18next.t("externalExpertForm:emailLimitError", {
          length: UserConstraints.MAX_EMAIL_LENGTH
        })
      ),
    [PROJECT_PIPELINE_PHONES]: Yup.array().of(
      Yup.object<{ phone: string }>().shape({
        phone: Yup.string()
      })
    ),
    [CUSTOM_PHONE]: Yup.string().test(
      "phone-valid",
      i18next.t("externalExpertForm:phoneNumberInvalidError"),
      function validate(val: string) {
        return val ? utilService.validateContactNumber(`+${val}`) : true;
      }
    ),
    [RATE_CARD]: Yup.object<ExpertPipelineRateCard>().shape({
      id: Yup.string(),
      experienceLevel: Yup.string().required(
        "externalExpertForm:seniorityRequiredError"
      ),
      isoCurrencyCode: Yup.string().required(
        i18next.t("externalExpertForm:currencyRequired")
      ),
      price: Yup.number()
        .typeError(i18next.t("externalExpertForm:hourlyRateType"))
        .required(i18next.t("externalExpertForm:hourlyRateRequired"))
        .max(
          ExpertProfileConstraints.MAX_HOURLY_RATE,
          i18next.t("externalExpertForm:hourlyRateLimit")
        )
        .min(
          ExpertProfileConstraints.MIN_HOURLY_RATE,
          i18next.t("externalExpertForm:hourlyRatePositive")
        )
        .test(
          "min-price",
          i18next.t("externalExpertForm:hourlyRateGreaterThanZero"),
          (val: number) => val > 0
        )
    })
  });
