import i18next from "i18next";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { SORT_DIRECTION, SortingParams } from "@arbolus-technologies/api";
import {
  ArbolusDropdown,
  SearchInput
} from "@arbolus-technologies/ui/components";

import { PROJECT_ORDER_BY } from "../../helpers/constants";

import styles from "./ProjectListFilters.module.scss";

const options: { label: string; value: SortingParams<PROJECT_ORDER_BY> }[] = [
  {
    label: i18next.t("expertProjectList:sortByName"),
    value: {
      orderBy: PROJECT_ORDER_BY.NAME,
      orderDirection: SORT_DIRECTION.ASCENDING
    }
  },
  {
    label: i18next.t("expertProjectList:sortByDate"),
    value: {
      orderBy: PROJECT_ORDER_BY.CREATED,
      orderDirection: SORT_DIRECTION.DESCENDING
    }
  }
];

export type ProjectListFilterParams = SortingParams<PROJECT_ORDER_BY> & {
  searchTerm: string;
};

interface ProjectListFiltersProps {
  onFilterChange: (filterParams: ProjectListFilterParams) => void;
}

export const ProjectListFilters: React.FC<ProjectListFiltersProps> = ({
  onFilterChange
}) => {
  const { t } = useTranslation("expertProjectList");
  const [params, setParams] = useState<
    ProjectListFilterParams & { label: string }
  >({
    searchTerm: "",
    orderBy: PROJECT_ORDER_BY.CREATED,
    orderDirection: SORT_DIRECTION.DESCENDING,
    label: t("sortByDate")
  });

  const onQueryChange = useCallback(
    (searchTerm: string) => {
      setParams((prev) => ({ ...prev, searchTerm }));
      onFilterChange({ ...params, searchTerm });
    },
    [onFilterChange, params]
  );

  const onSortChange = useCallback(
    ({
      label,
      value
    }: {
      label: string;
      value: SortingParams<PROJECT_ORDER_BY>;
    }) => {
      setParams((prev) => ({ ...prev, label, ...value }));
      onFilterChange({ ...params, ...value });
    },
    [onFilterChange, params]
  );

  return (
    <div className={styles.projectFilter}>
      <div className={styles.search}>
        <SearchInput
          onQueryChange={onQueryChange}
          minimumSearchLength={3}
          debouncingTime={500}
          isDebounced
          hasSearchIcon
          placeholder={t("searchProjects")}
        />
      </div>

      <ArbolusDropdown
        options={options}
        onSelect={onSortChange}
        selectedLabel={params.label}
      />
    </div>
  );
};
