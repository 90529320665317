import React from "react";

import {
  MultiChoiceAnswer,
  OptionChoiceAnswer
} from "@arbolus-technologies/models/canopy-panels";

import { OptionCheckbox } from "../OptionCheckbox/OptionCheckbox";

import styles from "./IndividualMultiChoiceResponse.module.scss";

interface IndividualMultiChoiceProps {
  multiChoiceAnswer: MultiChoiceAnswer;
}

const mapAnswerChoices = (
  multiChoiceAnswer: MultiChoiceAnswer
): OptionChoiceAnswer[] => {
  const selected = multiChoiceAnswer.selectedOptionChoicesIds;
  return multiChoiceAnswer.question.optionChoices
    .map((choice) => ({
      ...choice,
      isSelected: selected.includes(choice.id),
      otherText: (choice.isOther && multiChoiceAnswer.otherText) || null
    }))
    .sort((a, b) => +b.isSelected - +a.isSelected || a.sortOrder - b.sortOrder);
};

export const IndividualMultiChoice: React.FC<IndividualMultiChoiceProps> = ({
  multiChoiceAnswer
}) => {
  const choices = mapAnswerChoices(multiChoiceAnswer);

  return (
    <div className={styles.container}>
      {choices.map((choice) => (
        <OptionCheckbox key={choice.text} option={choice} />
      ))}
    </div>
  );
};
