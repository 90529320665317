import { Client } from "@arbolus-technologies/api";

export enum EXPERT_PROFILE_TABS {
  PROFILE = "profile",
  PROJECT = "project",
  INTERNAL_COMMENTS = "internalComments",
  SCREENING = "screeningQuestions",
  PROJECT_NOTES = "projectNotes",
  PAYMENTS = "payments"
}

export const INTERNAL_COMMENT_TITLE = "title";
export const INTERNAL_COMMENT_COMMENT = "note";
export const INTERNAL_COMMENT_SELECT_CLIENT = "selectClient";

export interface CreateNewCommentBase {
  [INTERNAL_COMMENT_TITLE]: string;
  [INTERNAL_COMMENT_COMMENT]: string;
  [INTERNAL_COMMENT_SELECT_CLIENT]: Client | null;
}
