import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React, { useMemo } from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { Country } from "../../../../interfaces";

import styles from "./SelectableCountry.module.scss";

interface SelectableCountryProps {
  country: Country;
  isSelected: boolean;
  handleSelectedCountry: (country: Country) => void;
}
export const SelectableCountry: React.FC<SelectableCountryProps> = ({
  country,
  isSelected,
  handleSelectedCountry
}) => {
  const icon = useMemo(
    () =>
      isSelected
        ? { color: ARBOLUS_COLORS.bColorBasePurple, name: "check" }
        : { color: ARBOLUS_COLORS.bColorSecondaryGreyMid, name: "add" },
    [isSelected]
  );

  return (
    <div
      key={country.name}
      className={clsx(
        styles.countryItem,
        isSelected && styles.countryItemSelected
      )}
      onClick={() => handleSelectedCountry(country)}
    >
      <span className={styles.countryName}>{country.name}</span>
      <Icon {...icon} fontSize="24px" />
    </div>
  );
};
