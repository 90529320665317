import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import dompurify from "dompurify";
import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./InfoBox.module.scss";

type InfoBoxType = "notification" | "warning" | "error" | "caution";

interface InfoBoxProps {
  title: string;
  description?: string;
  iconName?: string;
  infoBoxType: InfoBoxType;
}

export const InfoBox: React.FC<InfoBoxProps> = ({
  title,
  description,
  iconName,
  infoBoxType
}) => {
  const sanitizer = dompurify.sanitize;

  const boxStyle = {
    notification: {
      box: styles.boxNotification,
      circle: styles.circleNotification
    },
    warning: {
      box: styles.boxWarning,
      circle: styles.circleWarning
    },
    error: {
      box: styles.boxError,
      circle: styles.circleError
    },
    caution: {
      box: styles.boxCaution,
      circle: styles.circleCaution
    }
  };

  return (
    <div
      data-testid="infoboxContainer"
      className={clsx(styles.container, boxStyle[infoBoxType].box)}
    >
      <div className={clsx(styles.flexContainer)}>
        {iconName && (
          <div className={boxStyle[infoBoxType].circle}>
            <Icon
              name={iconName}
              fontSize="24px"
              color={ARBOLUS_COLORS.bColorBaseWhite}
            />
          </div>
        )}
        <div className={clsx(styles.textContainer)}>
          <div className={styles.title}>{title}</div>
          {description && (
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: sanitizer(description).replace(
                  /href/g,
                  "target='_blank' href"
                )
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
