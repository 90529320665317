import i18next from "i18next";
import * as Yup from "yup";

import {
  ExpertProfileConstraints,
  UserConstraints
} from "../../../../constants/validation";

export const ReferExpertValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(
      UserConstraints.MAX_FIRST_NAME_LENGTH,
      i18next.t("referExpertPage:firstNameMaxLengthError", {
        length: UserConstraints.MAX_FIRST_NAME_LENGTH
      })
    )
    .required(i18next.t("referExpertPage:firstNameRequiredError")),
  lastName: Yup.string()
    .max(
      UserConstraints.MAX_LAST_NAME_LENGTH,
      i18next.t("referExpertPage:lastNameMaxLengthError", {
        length: UserConstraints.MAX_LAST_NAME_LENGTH
      })
    )
    .required(i18next.t("referExpertPage:lastNameRequiredError")),
  jobTitle: Yup.string()
    .max(
      UserConstraints.MAX_TITLE_LENGTH,
      i18next.t("referExpertPage:jobTitleMaxLengthError", {
        length: UserConstraints.MAX_TITLE_LENGTH
      })
    )
    .nullable(),
  email: Yup.string()
    .required(i18next.t("referExpertPage:emailRequired"))
    .email(i18next.t("referExpertPage:emailInvalid"))
    .max(
      UserConstraints.MAX_EMAIL_LENGTH,
      i18next.t("referExpertPage:maxEmailLength", {
        length: UserConstraints.MAX_EMAIL_LENGTH
      })
    ),
  description: Yup.string()
    .max(
      ExpertProfileConstraints.MAX_ENDORSEMENT_LENGTH,
      i18next.t("referExpertPage:endorsementMaxLengthError", {
        length: ExpertProfileConstraints.MAX_ENDORSEMENT_LENGTH
      })
    )
    .required(i18next.t("referExpertPage:endorsementRequired"))
});
