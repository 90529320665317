import React from "react";
import { useTranslation } from "react-i18next";

import { SearchInput } from "@arbolus-technologies/ui/components";
import { SEARCH_DEBOUNCE_TIMEOUT_COMMON } from "@arbolus-technologies/utils";

interface SearchFilterProps {
  initialValue?: string;
  onSearchTerm: (search: string) => void;
}

export const SearchFilter: React.FC<SearchFilterProps> = ({
  initialValue = "",
  onSearchTerm
}) => {
  const { t } = useTranslation("expertSearch");

  return (
    <SearchInput
      initialValue={initialValue}
      onQueryChange={onSearchTerm}
      hasSearchIcon
      placeholder={t("expertSearchInputLabel")}
      isDebounced
      debouncingTime={SEARCH_DEBOUNCE_TIMEOUT_COMMON}
      minimumSearchLength={2}
    />
  );
};
