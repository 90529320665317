export const getVideoDuration = (videoUrl: string): Promise<number> =>
  new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      const duration = video.duration;
      resolve(duration);
    };

    video.onerror = () => {
      reject("Error loading video");
    };

    video.src = videoUrl;
    video.style.display = "none";
    document.body.appendChild(video);
  });

export const formatDuration = (duration: number): string => {
  const roundedSeconds = Math.ceil(duration);
  const minutes = Math.floor(roundedSeconds / 60);
  const seconds = roundedSeconds % 60;
  return `${minutes.toString().padStart(1, "0")}:${seconds
    .toString()
    .padStart(2, "0")}m`;
};
