import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { map } from "rxjs/operators";

import {
  CALL_STATUSES,
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ProjectExpertsService,
  ToasterService
} from "@arbolus-technologies/api";
import {
  EarlyAvailabilityCard,
  MixPanelEventNames,
  useArbolusTracking,
  useDisplayTimezone
} from "@arbolus-technologies/features/common";
import {
  PROJECT_BOOK,
  PROJECT_SCHEDULING_ROUTE
} from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  LoaderOrComponent,
  ProjectEmptyAvailabilities
} from "@arbolus-technologies/ui/components";
import { GrayBox } from "@arbolus-technologies/ui/layout";

import { Pagination } from "../../Components/Pagination/Pagination";
import { useAngleQuery } from "../../Hooks/useAngleQuery";
import { getCurrentAngleId } from "../../helpers";
import {
  DefaultProjectAvailabilitiesService,
  ExpertAvailability,
  ProjectAvailabilitiesService
} from "./ProjectAvailabilitiesService";

const LIMIT = 3;

interface ProjectAvailabilitiesProps {
  projectId: string;
  projectName: string;
  timezone: string;
  projectExpertsService?: typeof ProjectExpertsService;
  projectAvailabilitiesService?: ProjectAvailabilitiesService;
  notificationService?: ToasterService;
}

export const ProjectAvailabilities: React.FC<ProjectAvailabilitiesProps> = ({
  projectId,
  projectName,
  timezone,
  projectExpertsService = ProjectExpertsService,
  projectAvailabilitiesService = DefaultProjectAvailabilitiesService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("projectAvailabilities");
  const angleId = useAngleQuery();
  const { trackRedirect } = useArbolusTracking();
  const displayTimezone = useDisplayTimezone(timezone);

  const [availabilities, setAvailabilities] = useState<ExpertAvailability[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const isAdmin = useSelector(CacheSelector.isAdmin());

  const fetchAvailabilities = useCallback(
    (angleId?: string | null) => {
      setIsLoading(true);
      projectExpertsService
        .getScheduleAvailabilityList(
          projectId,
          new Date().toISOString(),
          undefined,
          undefined,
          angleId ? [angleId] : undefined,
          [CALL_STATUSES.UNSCHEDULED]
        )
        .pipe(map(projectAvailabilitiesService.mapAvailabilities))
        .subscribe(
          (response) => {
            setAvailabilities(response);
            setIsLoading(false);
          },
          (error: ErrorResponse<CIQError>) => {
            notificationService.showApiErrors(error);
            setIsLoading(false);
          }
        );
    },
    [
      notificationService,
      projectAvailabilitiesService,
      projectExpertsService,
      projectId
    ]
  );

  useEffect(() => {
    if (getCurrentAngleId() === angleId) {
      fetchAvailabilities(angleId);
    }
  }, [angleId, fetchAvailabilities]);

  return (
    <GrayBox
      title={{
        text: t("title", { count: availabilities.length }),
        to: isAdmin
          ? PROJECT_SCHEDULING_ROUTE(projectId)
          : PROJECT_BOOK(projectId),
        onClick: () => {
          trackRedirect(MixPanelEventNames.Book, PROJECT_BOOK(projectId));
        }
      }}
      subtitle={displayTimezone}
      flexDirection="column"
      gap={[2, 0]}
      actionComponent={
        <Pagination
          limit={LIMIT}
          count={availabilities.length}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      }
    >
      <LoaderOrComponent isLoading={isLoading}>
        {!availabilities?.length && <ProjectEmptyAvailabilities />}
        {availabilities
          ?.slice(pageNumber * LIMIT, (pageNumber + 1) * LIMIT)
          .map(({ expert, angle, slots, referralId }) => (
            <EarlyAvailabilityCard
              key={expert.id}
              project={{
                id: projectId,
                title: projectName
              }}
              angle={angle}
              earliestAvailability={slots[0]}
              remainingSlots={slots.length - 1}
              expert={{
                ...expert,
                id: expert.expertId
              }}
              referralId={referralId}
              timezone={timezone}
              status={expert.status}
            />
          ))}
      </LoaderOrComponent>
    </GrayBox>
  );
};
