import i18next from "i18next";
import * as Yup from "yup";

import {
  ExpertProfileConstraints,
  UserConstraints
} from "../../../../constants/validation";

export const ExpertEndorsementValidationSchema = Yup.object().shape({
  name: Yup.string()
    .max(
      UserConstraints.MAX_NAME_LENGTH,
      i18next.t("expertEndorsementPage:nameMaxLengthError", {
        length: UserConstraints.MAX_FIRST_NAME_LENGTH
      })
    )
    .required(i18next.t("expertEndorsementPage:nameRequiredError")),
  jobPosition: Yup.string()
    .max(
      UserConstraints.MAX_FIRST_NAME_LENGTH,
      i18next.t("expertEndorsementPage:jobPositionMaxLengthError", {
        length: UserConstraints.MAX_FIRST_NAME_LENGTH
      })
    )
    .required(i18next.t("expertEndorsementPage:jobPositionRequiredError")),
  quote: Yup.string()
    .max(
      ExpertProfileConstraints.MAX_ENDORSEMENT_LENGTH,
      i18next.t("expertEndorsementPage:endorsementMaxLengthError", {
        length: ExpertProfileConstraints.MAX_ENDORSEMENT_LENGTH
      })
    )
    .required(i18next.t("expertEndorsementPage:endorsementRequiredError"))
});
