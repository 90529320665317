import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./LoadMore.module.scss";

interface LoadMoreProps {
  loadMore: () => void;
}

export const LoadMore: React.FC<LoadMoreProps> = ({ loadMore }) => {
  const { t } = useTranslation("expertAvailabilityTab");

  return (
    <div className={styles.loadMoreContainer}>
      <div className={styles.loadMore} onClick={loadMore}>
        {t("loadMore")}
      </div>
    </div>
  );
};
