import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { DropdownAngle } from "@arbolus-technologies/models/project";
import { PROJECT_CHAT_ROUTE } from "@arbolus-technologies/routes";

import { ANGLE_DROPDOWN_DEFAULT_OPTION } from "../../../Constants/angle";
import { useChat } from "../../../Contexts/ChatContext/ChatContext";
import { useOnlineChatMembers } from "../../../Hooks/Chat/useOnlineChatMembers";
import { AnglesDropdown } from "../../AnglesDropdown/AnglesDropdown";
import { getExpertSupportChats } from "../utils";
import { ChatsPerAngle } from "./ChatsPerAngle/ChatsPerAngle";
import { ProjectChat } from "./ProjectChat/ProjectChat";

import styles from "./ChatList.module.scss";

export const ChatList: React.FC<{
  projectId: string;
}> = ({ projectId }) => {
  const {
    currentChat,
    chatsByAngle,
    changeChat,
    projectChat,
    expertSupportChatActive,
    expertToArbolusChats,
    isChatEnabled
  } = useChat();
  const [selectedAngle, setSelectedAngle] = useState<DropdownAngle>(
    ANGLE_DROPDOWN_DEFAULT_OPTION
  );

  const { isClientOnlineInProjectChat } = useOnlineChatMembers();

  const showClientChatList = !expertSupportChatActive && isChatEnabled;

  const anglesDropdownMemoized = useMemo(
    () => (
      <AnglesDropdown
        projectId={projectId}
        angles={Object.values(chatsByAngle)}
        selectedAngle={selectedAngle}
        setSelectedAngle={setSelectedAngle}
      />
    ),
    [chatsByAngle, projectId, selectedAngle]
  );

  const chatsPerAngleMemoized = useMemo(() => {
    const showAllAngles = selectedAngle.id === ANGLE_DROPDOWN_DEFAULT_OPTION.id;
    const angleChats = showAllAngles
      ? Object.values(chatsByAngle)
      : [chatsByAngle[selectedAngle.id]];
    const chats = expertSupportChatActive
      ? getExpertSupportChats({
          showAllAngles,
          selectedAngle,
          expertToArbolusChats
        })
      : angleChats;

    return chats.map((angle) => (
      <ChatsPerAngle key={angle.id} angle={angle} projectId={projectId} />
    ));
  }, [
    chatsByAngle,
    expertSupportChatActive,
    expertToArbolusChats,
    projectId,
    selectedAngle
  ]);

  return (
    <div className={styles.chatList}>
      {expertSupportChatActive && (
        <>
          {anglesDropdownMemoized}
          {chatsPerAngleMemoized}
        </>
      )}
      {showClientChatList && (
        <>
          {projectChat && (
            <>
              <Link to={PROJECT_CHAT_ROUTE(projectId, projectChat.id)}>
                <ProjectChat
                  onClick={() => {
                    changeChat(projectChat);
                  }}
                  isActive={currentChat?.id === projectChat.id}
                  isCurrentChatMemberOnline={isClientOnlineInProjectChat()}
                />
              </Link>
              {anglesDropdownMemoized}
            </>
          )}
          {chatsPerAngleMemoized}
        </>
      )}
    </div>
  );
};
