import { Icon } from "arbolus-ui-components";
import React from "react";

import { Company } from "@arbolus-technologies/api";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./VendorCardItem.module.scss";

interface VendorCardItemProps {
  vendor: Company;
  isSelected: boolean;
  onClick: () => void;
}
export const VendorCardItem: React.FC<VendorCardItemProps> = ({
  vendor,
  isSelected,
  onClick
}) => {
  const { name } = vendor;
  return (
    <div
      className={isSelected ? styles.cardSelected : styles.card}
      onClick={onClick}
    >
      <span className={styles.name}>{name}</span>
      {isSelected ? (
        <Icon
          name="check"
          fontSize="24px"
          color={ARBOLUS_COLORS.bColorBasePurple}
        />
      ) : (
        <Icon name="add" fontSize="24" />
      )}
    </div>
  );
};
