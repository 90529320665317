import { ActionCreator } from "redux";

import { ReferralSummary } from "@arbolus-technologies/api";
import { REFERRAL_STATES } from "@arbolus-technologies/models/expert";

import { CIQError, ErrorResponse } from "../../../../models/api";
import { ClientTotalSpend } from "../../../../models/client";
import { EventsDuration } from "../../../../models/event";
import { Member } from "../../../../models/project";
import { User } from "../../../../models/user";
import { StatusReferral } from "../../../../models/view/candidatePicker";
import {
  ADD_PROJECT_MEMBER,
  AddProjectMemberAction,
  COMPLETE_CANDIDATE_REVIEWAL,
  CompleteCandidateReviewalAction,
  EXIT_FROM_PROJECT,
  ExitFromProjectAction,
  GET_PROJECT,
  GET_PROJECT_BASE_DATA_SUCCESS,
  GET_PROJECT_CLIENT_DATA_SUCCESS,
  GET_PROJECT_FAILURE,
  GET_PROJECT_SUCCESS,
  GetProjectAction,
  GetProjectActionFailureAction,
  GetProjectBaseDataSuccessAction,
  GetProjectClientDataSuccessAction,
  GetProjectSuccessAction,
  REFETCH_PROJECT_REFERRALS_DATA,
  REFETCH_PROJECT_REFERRALS_DATA_FAILURE,
  REFETCH_PROJECT_REFERRALS_DATA_SUCCESS,
  REFETCH_REFERRAL_SUMMARY,
  REFETCH_REFERRAL_SUMMARY_FAILURE,
  REFETCH_REFERRAL_SUMMARY_SUCCESS,
  REMOVE_PROJECT_MEMBER,
  RefetchReferralDataAction,
  RefetchReferralDataFailureAction,
  RefetchReferralDataSuccessAction,
  RefetchReferralSummaryAction,
  RefetchReferralSummaryFailureAction,
  RefetchReferralSummarySuccessAction,
  RemoveProjectMemberSuccessAction,
  UPDATE_CANDIDATE_REFERRAL_STATUS,
  UPDATE_PROJECT_DETAILS,
  UPDATE_PROJECT_EVENT_DURATION,
  UPDATE_PROJECT_STATE,
  UPDATE_PROJECT_STATE_FAILURE,
  UPDATE_PROJECT_STATE_SUCCESS,
  UpdateCandidateReferralStatusAction,
  UpdateEventDurationAction,
  UpdateProjectDetailsAction,
  UpdateProjectStateAction,
  UpdateProjectStateFailureAction,
  UpdateProjectStateSuccessAction
} from "./ProjectActionTypes";

export const getProject: ActionCreator<GetProjectAction> = (
  projectId: string
) => ({
  type: GET_PROJECT,
  payload: {
    projectId
  }
});

export const getProjectSuccess: ActionCreator<GetProjectSuccessAction> = (
  projectId: string,
  projectName: string,
  modified: Date,
  projectState: string,
  clientId: string,
  hasCompliance: boolean,
  hasScreeningQuestions: boolean,
  defaultEventGuests: User[],
  invitationUrl?: string,
  timezone?: string
) => ({
  type: GET_PROJECT_SUCCESS,
  payload: {
    projectName,
    projectId,
    modified,
    projectState,
    clientId,
    hasCompliance,
    hasScreeningQuestions,
    invitationUrl,
    timezone,
    defaultEventGuests
  }
});

export const getProjectFailure: ActionCreator<
  GetProjectActionFailureAction
> = () => ({
  type: GET_PROJECT_FAILURE,
  payload: {}
});

export const getProjectBaseDataSuccess: ActionCreator<
  GetProjectBaseDataSuccessAction
> = (members: Member[], eventsDuration: EventsDuration) => ({
  type: GET_PROJECT_BASE_DATA_SUCCESS,
  payload: {
    members,
    eventsDuration
  }
});

export const getProjectClientDataSuccess: ActionCreator<
  GetProjectClientDataSuccessAction
> = (
  referrals: StatusReferral[],
  referralSummary: ReferralSummary,
  totalSpend: ClientTotalSpend
) => ({
  type: GET_PROJECT_CLIENT_DATA_SUCCESS,
  payload: {
    referrals,
    referralSummary,
    totalSpend
  }
});

export const refetchReferralData: ActionCreator<RefetchReferralDataAction> = (
  projectId: string
) => ({
  type: REFETCH_PROJECT_REFERRALS_DATA,
  payload: {
    projectId
  }
});

export const refetchReferralDataSuccess: ActionCreator<
  RefetchReferralDataSuccessAction
> = (referrals: StatusReferral[]) => ({
  type: REFETCH_PROJECT_REFERRALS_DATA_SUCCESS,
  payload: {
    referrals
  }
});

export const refetchReferralDataFailure: ActionCreator<
  RefetchReferralDataFailureAction
> = (error: ErrorResponse<CIQError>) => ({
  type: REFETCH_PROJECT_REFERRALS_DATA_FAILURE,
  payload: {
    error
  }
});

export const exitFromProject: ActionCreator<ExitFromProjectAction> = () => ({
  type: EXIT_FROM_PROJECT,
  payload: {}
});

export const completeCandidateReviewal: ActionCreator<
  CompleteCandidateReviewalAction
> = () => ({
  type: COMPLETE_CANDIDATE_REVIEWAL,
  payload: {}
});

export const updateCandidateReferralStatus: ActionCreator<
  UpdateCandidateReferralStatusAction
> = (referralId: string, status: REFERRAL_STATES) => ({
  type: UPDATE_CANDIDATE_REFERRAL_STATUS,
  payload: {
    referralId,
    status
  }
});

export const addMemberToProject: ActionCreator<AddProjectMemberAction> = (
  member: Member
) => ({
  type: ADD_PROJECT_MEMBER,
  payload: {
    member
  }
});

export const removeMemberFromProject: ActionCreator<
  RemoveProjectMemberSuccessAction
> = (memberId: string) => ({
  type: REMOVE_PROJECT_MEMBER,
  payload: {
    memberId
  }
});

export const updateEventDuration: ActionCreator<UpdateEventDurationAction> = (
  eventStart: string
) => ({
  type: UPDATE_PROJECT_EVENT_DURATION,
  payload: {
    eventStart
  }
});

export const updateProjectDetails: ActionCreator<UpdateProjectDetailsAction> = (
  projectName: string,
  modifiedDate: Date,
  timezone?: string
) => ({
  type: UPDATE_PROJECT_DETAILS,
  payload: {
    modifiedDate,
    projectName,
    timezone
  }
});

export const updateProjectState: ActionCreator<UpdateProjectStateAction> = (
  projectId,
  projectState: string
) => ({
  type: UPDATE_PROJECT_STATE,
  payload: {
    projectId,
    projectState
  }
});

export const updateProjectStateSuccess: ActionCreator<
  UpdateProjectStateSuccessAction
> = (projectState: string) => ({
  type: UPDATE_PROJECT_STATE_SUCCESS,
  payload: {
    projectState
  }
});

export const updateProjectStateFailure: ActionCreator<
  UpdateProjectStateFailureAction
> = () => ({
  type: UPDATE_PROJECT_STATE_FAILURE,
  payload: {}
});

export const refetchReferralSummary: ActionCreator<
  RefetchReferralSummaryAction
> = (projectId: string) => ({
  type: REFETCH_REFERRAL_SUMMARY,
  payload: {
    projectId
  }
});

export const refetchReferralSummarySuccess: ActionCreator<
  RefetchReferralSummarySuccessAction
> = (referralSummary: ReferralSummary) => ({
  type: REFETCH_REFERRAL_SUMMARY_SUCCESS,
  payload: {
    referralSummary
  }
});

export const refetchReferralSummaryFailure: ActionCreator<
  RefetchReferralSummaryFailureAction
> = (error: ErrorResponse<CIQError>) => ({
  type: REFETCH_REFERRAL_SUMMARY_FAILURE,
  payload: {
    error
  }
});
