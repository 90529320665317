import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Form } from "reactstrap";

import {
  BookmarkService,
  DefaultToasterService,
  ToasterService
} from "@arbolus-technologies/api";
import { BookmarkCollectionsStoreActions } from "@arbolus-technologies/stores/bookmark-collections";
import { BookmarksStoreActions } from "@arbolus-technologies/stores/bookmarks";
import {
  InputController,
  ModalWithChildren
} from "@arbolus-technologies/ui/components";

import {
  NAME,
  RenameCollectionFormObject,
  renameCollectionSchemaBuilder
} from "./RenameCollectionSchema";

import styles from "./RenameCollectionModal.module.scss";

interface RenameCollectionModalProps {
  id: string;
  currentName: string;
  isOpen: boolean;
  close: () => void;
  bookmarkService?: typeof BookmarkService;
  notificationService?: ToasterService;
}

export const RenameCollectionModal: React.FC<RenameCollectionModalProps> = ({
  id,
  currentName,
  isOpen,
  close,
  bookmarkService = BookmarkService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("bookmarkCollectionActions");
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);

  const formReturn = useForm<RenameCollectionFormObject>({
    resolver: yupResolver(renameCollectionSchemaBuilder(currentName)),
    mode: "all",
    defaultValues: {
      [NAME]: currentName
    }
  });

  const {
    control,
    handleSubmit,
    formState: { isValid, errors }
  } = formReturn;

  const closeModal = useCallback(
    (force = false) => {
      if (submitting && !force) {
        return;
      }

      setSubmitting(false);
      close();
    },
    [close, submitting]
  );

  const renameCollection = useCallback(
    (formObject: RenameCollectionFormObject) => {
      if (submitting) {
        return;
      }

      setSubmitting(true);

      const name = formObject[NAME];
      bookmarkService.updateBookmarkCollection(id, { name }).subscribe({
        next: () => {
          dispatch(BookmarkCollectionsStoreActions.renameCollection(id, name));
          dispatch(BookmarksStoreActions.renameCollection(id, name));
          closeModal(true);
        },
        error: (error) => {
          setSubmitting(false);
          notificationService.showApiErrors(error);
        }
      });
    },
    [bookmarkService, closeModal, dispatch, id, notificationService, submitting]
  );

  return (
    <ModalWithChildren
      messageTitle={t("rename")}
      onCancel={closeModal}
      onConfirm={handleSubmit(renameCollection)}
      toggleModal={isOpen}
      isConfirmButtonDisabled={!isValid || submitting}
      customModalBody
    >
      <div className={styles.content}>
        <FormProvider {...formReturn}>
          <Form
            onSubmit={handleSubmit(renameCollection)}
            className={styles.form}
          >
            <InputController
              control={control}
              placeholder={currentName}
              name={NAME}
              error={errors[NAME]}
            />
          </Form>
        </FormProvider>
      </div>
    </ModalWithChildren>
  );
};
