export enum LISTING_TYPES {
  SURVEYS = "surveys",
  PROJECTS = "projects",
  EVENTS = "events",
  DOCUMENTS = "documents",
  USERS = "users",
  EXPERT_APPROVED = "expert-approved",
  EXPERT_CANDIDATE = "expert-candidate",
  EXPERT_REJECTED = "expert-rejected",
  CLIENT_NETWORK = "client-network",
  FIND_EXPERTS = "find-experts",
  INBOX_THINGS_TO_DO = "inbox-things-do",
  INBOX_MESSAGES = "inbox-messages",
  INBOX_EVENT_INVITES = "inbox-event-invites",
  INBOX_SHARED_DOCS = "inbox-shared-docs",
  INBOX_NEW_JOINS = "inbox-new-joins",
  INBOX_NEW_REFERRALS = "inbox-new-referrals",
  INBOX_APPROVALS = "inbox-approvals",
  INBOX_APPLICATIONS = "inbox-applications",
  INBOX_COMPLIANCE = "inbox-compliance"
}
