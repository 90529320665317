import { OutputSelector, createSelector } from "reselect";

import { Angle, CIQError } from "@arbolus-technologies/api";
import { AngleModel, SelectOption } from "@arbolus-technologies/models/common";

import {
  ProjectAnglesAppState,
  ProjectAnglesReducerState
} from "../definitions";

const projectAnglesStateSelector = (
  state: ProjectAnglesAppState
): ProjectAnglesReducerState => state.projectAngles;

// Angles
const anglesCardsSelector = (): OutputSelector<
  ProjectAnglesAppState,
  AngleModel[],
  (res: ProjectAnglesReducerState) => AngleModel[]
> =>
  createSelector<
    ProjectAnglesAppState,
    ProjectAnglesReducerState,
    AngleModel[]
  >(
    projectAnglesStateSelector,
    (anglesReducerState) => anglesReducerState.anglesCards
  );

const anglesCardsLoadingSelector = (): OutputSelector<
  ProjectAnglesAppState,
  boolean,
  (res: ProjectAnglesReducerState) => boolean
> =>
  createSelector<ProjectAnglesAppState, ProjectAnglesReducerState, boolean>(
    projectAnglesStateSelector,
    (anglesReducerState) => anglesReducerState.isAnglesCardsLoading
  );

const selectedAngle = (): OutputSelector<
  ProjectAnglesAppState,
  Angle,
  (res: ProjectAnglesReducerState) => Angle
> =>
  createSelector<ProjectAnglesAppState, ProjectAnglesReducerState, Angle>(
    projectAnglesStateSelector,
    (anglesReducerState) => anglesReducerState.selectedAngle
  );

const isSingleAngleLoading = (): OutputSelector<
  ProjectAnglesAppState,
  boolean,
  (res: ProjectAnglesReducerState) => boolean
> =>
  createSelector<ProjectAnglesAppState, ProjectAnglesReducerState, boolean>(
    projectAnglesStateSelector,
    (anglesReducerState) => anglesReducerState.isSingleAngleLoading
  );
const clientIdByAdmin = (): OutputSelector<
  ProjectAnglesAppState,
  string,
  (res: ProjectAnglesReducerState) => string
> =>
  createSelector<ProjectAnglesAppState, ProjectAnglesReducerState, string>(
    projectAnglesStateSelector,
    (anglesReducerState) => anglesReducerState.clientIdByAdmin
  );
const isFormCreateEditAngleDisabled = (): OutputSelector<
  ProjectAnglesAppState,
  boolean,
  (res: ProjectAnglesReducerState) => boolean
> =>
  createSelector<ProjectAnglesAppState, ProjectAnglesReducerState, boolean>(
    projectAnglesStateSelector,
    (anglesReducerState) => anglesReducerState.isFormCreateEditAngleDisabled
  );

const anglesCardLink = (): OutputSelector<
  ProjectAnglesAppState,
  SelectOption[] | null,
  (res: ProjectAnglesReducerState) => SelectOption[] | null
> =>
  createSelector<
    ProjectAnglesAppState,
    ProjectAnglesReducerState,
    SelectOption[] | null
  >(
    projectAnglesStateSelector,
    (anglesReducerState) => anglesReducerState.angleLinks
  );

const isLoadingAngleLinks = (): OutputSelector<
  ProjectAnglesAppState,
  boolean,
  (res: ProjectAnglesReducerState) => boolean
> =>
  createSelector<ProjectAnglesAppState, ProjectAnglesReducerState, boolean>(
    projectAnglesStateSelector,
    (anglesReducerState) => anglesReducerState.isAngleLinksLoading
  );

const isCreateAngleLoading = (): OutputSelector<
  ProjectAnglesAppState,
  boolean,
  (res: ProjectAnglesReducerState) => boolean
> =>
  createSelector<ProjectAnglesAppState, ProjectAnglesReducerState, boolean>(
    projectAnglesStateSelector,
    (anglesReducerState) => anglesReducerState.isCreateAngleLoading
  );

const isEditAngleLoading = (): OutputSelector<
  ProjectAnglesAppState,
  boolean,
  (res: ProjectAnglesReducerState) => boolean
> =>
  createSelector<ProjectAnglesAppState, ProjectAnglesReducerState, boolean>(
    projectAnglesStateSelector,
    (anglesReducerState) => anglesReducerState.isEditAngleLoading
  );

const anglesList = (): OutputSelector<
  ProjectAnglesAppState,
  { id: string; name: string; color: string }[],
  (
    res: ProjectAnglesReducerState
  ) => { id: string; name: string; color: string }[]
> =>
  createSelector<
    ProjectAnglesAppState,
    ProjectAnglesReducerState,
    { id: string; name: string; color: string }[]
  >(projectAnglesStateSelector, (anglesReducerState) =>
    anglesReducerState.anglesCards.map((e) => ({
      id: e.id,
      name: e.title,
      color: e.color
    }))
  );

const createAngleErrors = (): OutputSelector<
  ProjectAnglesAppState,
  CIQError,
  (res: ProjectAnglesReducerState) => CIQError
> =>
  createSelector<ProjectAnglesAppState, ProjectAnglesReducerState, CIQError>(
    projectAnglesStateSelector,
    (anglesReducerState) => anglesReducerState.createAngleErrors
  );

const editAngleErrors = (): OutputSelector<
  ProjectAnglesAppState,
  CIQError,
  (res: ProjectAnglesReducerState) => CIQError
> =>
  createSelector<ProjectAnglesAppState, ProjectAnglesReducerState, CIQError>(
    projectAnglesStateSelector,
    (anglesReducerState) => anglesReducerState.editAngleErrors
  );

const addScreeningQuestionsView = (): OutputSelector<
  ProjectAnglesAppState,
  boolean,
  (res: ProjectAnglesReducerState) => boolean
> =>
  createSelector<ProjectAnglesAppState, ProjectAnglesReducerState, boolean>(
    projectAnglesStateSelector,
    (anglesReducerState) => anglesReducerState.addScreeningQuestionsView
  );

const noScreeningQuestionsView = (): OutputSelector<
  ProjectAnglesAppState,
  boolean,
  (res: ProjectAnglesReducerState) => boolean
> =>
  createSelector<ProjectAnglesAppState, ProjectAnglesReducerState, boolean>(
    projectAnglesStateSelector,
    (anglesReducerState) => anglesReducerState.noScreeningQuestionsView
  );

export const ProjectAnglesSelector = {
  projectAnglesStateSelector,
  anglesCardsSelector,
  anglesCardsLoadingSelector,
  selectedAngle,
  isSingleAngleLoading,
  clientIdByAdmin,
  isFormCreateEditAngleDisabled,
  isLoadingAngleLinks,
  anglesCardLink,
  isCreateAngleLoading,
  isEditAngleLoading,
  anglesList,
  createAngleErrors,
  editAngleErrors,
  addScreeningQuestionsView,
  noScreeningQuestionsView
};
