import queryString from "query-string";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { zip } from "rxjs";

import {
  ApiPaginatedRequest,
  CIQError,
  CompaniesPaginatedResponse,
  CompanyResponse,
  DefaultToasterService,
  ErrorResponse,
  MetaService,
  SORT_DIRECTION,
  ToasterService
} from "@arbolus-technologies/api";
import {
  DiscoverFilterOperator,
  DiscoverFilterOption,
  DiscoverFilterType,
  FilterTimeSelectorOptions
} from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  ProjectNxSelector,
  ProjectNxStoreActions
} from "@arbolus-technologies/stores/project";
import { useDebounce, utilService } from "@arbolus-technologies/utils";

import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { SEARCH_LIMIT } from "../../Modules/DiscoverFilters/DiscoverFilters";
import { cloneAndFilter } from "../../Utils/DiscoverExpertUtils";
import { useDiscoverFilters } from "./useDiscoverFilters";

interface UseDiscoverCompanyFilterProps {
  metaService?: typeof MetaService;
  notificationService?: ToasterService;
}

export interface UseDiscoverCompanyFilter {
  handleSetCompanyFilterPeriod: (filterTimeSelectorOption: string) => void;
  handleBottomReached: (query: string) => void | null;
  handleInputChangeEvent: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  handleClearQueryClicked: () => void;
  handleSelectOption: (option: DiscoverFilterOption) => void;
  handleSelectAll: () => void;
  filterOptions: DiscoverFilterOption[];
  applyCompanyFilter: () => void;
  urlChangeOnCompanyFilterUpdate: (options: DiscoverFilterOption[]) => void;
  handleClearFilter: () => void;
  updateCompanyInfoFromUrl: () => Promise<DiscoverFilterOption[]>;
  handleSetCompanyInPastMonths: (companyInPastMonths: number) => void;
}

const MINIMUM_SEARCH_LENGTH = 2;

export const useDiscoverCompanyFilter = ({
  metaService = MetaService,
  notificationService = DefaultToasterService
}: UseDiscoverCompanyFilterProps = {}): UseDiscoverCompanyFilter => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { trackFiltered } = useArbolusTracking();

  const parameters = queryString.parse(location.search);
  const {
    includedCompanies,
    excludedCompanies
  }: { includedCompanies?: string; excludedCompanies?: string } = parameters;

  const {
    selectedOptions,
    setSelectedOptions,
    preSelectedOptions,
    setPreSelectedOptions,
    clearFilter
  } = useDiscoverFilters({
    filterType: DiscoverFilterType.Company
  });

  const [isCompaniesLoading, setIsCompaniesLoading] = useState<boolean>(false);
  const [companiesPaginatedResponse, setCompaniesPaginatedResponse] =
    useState<CompaniesPaginatedResponse<CompanyResponse> | null>(null);
  const [filterOptions, setFilterOptions] = useState<DiscoverFilterOption[]>(
    []
  );

  const isAdmin = useSelector(CacheSelector.isAdmin());
  const projectName = useSelector(ProjectNxSelector.projectName());
  const projectClientName = useSelector(ProjectNxSelector.projectClientName());
  const companyFilterQuery = useSelector(ProjectNxSelector.filtersQuery())[
    DiscoverFilterType.Company
  ];
  const debouncedSearchQuery = useDebounce<string>(companyFilterQuery, 300);

  const getCompaniesSearchResults = (query?: string) => {
    setIsCompaniesLoading(true);
    const queryParams: ApiPaginatedRequest = {
      searchTerm: query,
      offset: companiesPaginatedResponse?.items.length ?? 0,
      limit: SEARCH_LIMIT,
      orderBy: "Name",
      orderDirection: SORT_DIRECTION.ASCENDING
    };

    metaService.getCompanies(queryParams).subscribe(
      (companiesResponse) => {
        const filterOptionsArray = companiesResponse.items.map((company) => ({
          value: company.id,
          label: company.name,
          operator: DiscoverFilterOperator.Include
        }));

        setCompaniesPaginatedResponse({
          items: [
            ...(companiesPaginatedResponse?.items ?? []),
            ...companiesResponse.items
          ],
          pagination: companiesResponse.pagination,
          exactMatch: companiesResponse.exactMatch
        });
        setIsCompaniesLoading(false);
        setFilterOptions([...filterOptions, ...filterOptionsArray]);
      },
      (error: ErrorResponse<CIQError>) => {
        setIsCompaniesLoading(false);
        notificationService.showError(error.message);
        return [];
      }
    );
  };

  const handleSetCompanyFilterPeriod = (filterTimeSelectorOption: string) => {
    dispatch(ProjectNxStoreActions.setCompanyInPastMonths(0));
    dispatch(
      ProjectNxStoreActions.setCompanyFilterPeriod(
        filterTimeSelectorOption as FilterTimeSelectorOptions
      )
    );

    if (isAdmin) {
      const nonEmptyParams = utilService.getNonEmptyQueryString(parameters);
      if (filterTimeSelectorOption === FilterTimeSelectorOptions.All) {
        delete nonEmptyParams["companyState"];
      } else {
        nonEmptyParams["companyState"] = filterTimeSelectorOption;
      }

      if (
        filterTimeSelectorOption === FilterTimeSelectorOptions.PastNotCurrent
      ) {
        nonEmptyParams["companyInPastMonths"] = "0";
      } else {
        delete nonEmptyParams["companyInPastMonths"];
      }

      const params = new URLSearchParams(nonEmptyParams);
      history.replace({
        pathname: location.pathname,
        search: params.toString()
      });
    }
  };

  const handleSetCompanyInPastMonths = (companyInPastMonths: number) => {
    dispatch(ProjectNxStoreActions.setCompanyInPastMonths(companyInPastMonths));

    if (isAdmin) {
      const nonEmptyParams = utilService.getNonEmptyQueryString(parameters);
      if (companyInPastMonths) {
        nonEmptyParams["companyInPastMonths"] = `${companyInPastMonths}`;
      } else {
        delete nonEmptyParams["companyInPastMonths"];
      }
      const params = new URLSearchParams(nonEmptyParams);
      history.replace({
        pathname: location.pathname,
        search: params.toString()
      });
    }
  };

  const handleBottomReached = (query: string) => {
    if (
      companiesPaginatedResponse &&
      companiesPaginatedResponse?.pagination.count >
        companiesPaginatedResponse?.pagination.offset + SEARCH_LIMIT &&
      !isCompaniesLoading
    ) {
      return getCompaniesSearchResults(query);
    }
    return null;
  };

  const handleInputChangeEvent = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const newQuery = event.target.value;
    dispatch(
      ProjectNxStoreActions.setFilterQuery(DiscoverFilterType.Company, newQuery)
    );
    setCompaniesPaginatedResponse(null);
    setFilterOptions([]);
  };

  useEffect(() => {
    if (debouncedSearchQuery.length >= MINIMUM_SEARCH_LENGTH) {
      getCompaniesSearchResults(debouncedSearchQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  const handleClearQueryClicked = () => {
    dispatch(
      ProjectNxStoreActions.setFilterQuery(DiscoverFilterType.Company, "")
    );
  };

  const handleSelectOption = (option: DiscoverFilterOption) => {
    const isOptionSelected = preSelectedOptions
      .map((selectedOption) => selectedOption.value)
      .includes(option.value);

    if (isOptionSelected) {
      const filteredOptions = preSelectedOptions.filter(
        (selectedOption) => selectedOption.value !== option.value
      );
      setPreSelectedOptions(filteredOptions);
    } else {
      setPreSelectedOptions([...preSelectedOptions, option]);
    }
  };

  const handleSelectAll = () => {
    const newSelectedOptions = filterOptions.filter(
      (option) =>
        !preSelectedOptions.some(
          (selectedOption) => selectedOption.value === option.value
        )
    );
    setPreSelectedOptions([...preSelectedOptions, ...newSelectedOptions]);
  };

  useEffect(() => {
    if (companyFilterQuery.length === 0) {
      setCompaniesPaginatedResponse(null);
      setFilterOptions([]);
    }
  }, [companyFilterQuery]);

  const applyCompanyFilter = () => {
    const selectedOptionsUpdated = cloneAndFilter(
      preSelectedOptions,
      selectedOptions
    );
    setSelectedOptions(selectedOptionsUpdated);
    urlChangeOnCompanyFilterUpdate(selectedOptionsUpdated);
    trackFiltered(MixPanelEventNames.InternalSearchCompaniesSearch, {
      Project: projectName,
      Client: projectClientName
    });
  };

  const updateCompanyInfoFromUrl = () => {
    const includedCompanyIds = includedCompanies
      ? includedCompanies.split(",")
      : [];
    const excludedCompanyIds = excludedCompanies
      ? excludedCompanies.split(",")
      : [];

    if (!includedCompanyIds.length && !excludedCompanyIds.length) {
      return Promise.resolve([]);
    }

    return new Promise<DiscoverFilterOption[]>((resolve, reject) => {
      const promises = [
        ...includedCompanyIds.map((value) => metaService.getCompanyById(value)),
        ...excludedCompanyIds.map((value) => metaService.getCompanyById(value))
      ];

      const subscription = zip(...promises).subscribe({
        next: (companies) => {
          const filterOptionsArray = companies.map(
            (company: CompanyResponse) => ({
              value: company.id,
              label: company.name,
              operator: includedCompanyIds.includes(company.id)
                ? DiscoverFilterOperator.Include
                : DiscoverFilterOperator.Exclude
            })
          );

          resolve(filterOptionsArray);
          subscription.unsubscribe();
        },
        error: (error) => {
          reject(error);
          subscription.unsubscribe();
        }
      });
    });
  };

  const urlChangeOnCompanyFilterUpdate = (options: DiscoverFilterOption[]) => {
    if (isAdmin) {
      const includedCompanies = options
        .filter((option) => option.operator === DiscoverFilterOperator.Include)
        .map((option) => option.value)
        .join(",");

      const excludedCompanies = options
        .filter((option) => option.operator === DiscoverFilterOperator.Exclude)
        .map((option) => option.value)
        .join(",");

      const nonEmptyParams = utilService.getNonEmptyQueryString(parameters);
      if (includedCompanies) {
        nonEmptyParams["includedCompanies"] = includedCompanies;
      } else {
        delete nonEmptyParams["includedCompanies"];
      }

      if (excludedCompanies) {
        nonEmptyParams["excludedCompanies"] = excludedCompanies;
        delete nonEmptyParams["companyInPastMonths"];
      } else {
        delete nonEmptyParams["excludedCompanies"];
      }

      const params = new URLSearchParams(nonEmptyParams);
      history.replace({
        pathname: location.pathname,
        search: params.toString()
      });
    }
  };

  const handleClearFilter = () => {
    clearFilter();
    if (isAdmin) {
      const nonEmptyParams = utilService.getNonEmptyQueryString(parameters);
      delete nonEmptyParams["includedCompanies"];
      delete nonEmptyParams["excludedCompanies"];
      delete nonEmptyParams["companyState"];
      delete nonEmptyParams["companyInPastMonths"];

      const params = new URLSearchParams(nonEmptyParams);
      history.replace({
        pathname: location.pathname,
        search: params.toString()
      });
    }
  };

  return {
    handleSetCompanyFilterPeriod,
    handleBottomReached,
    handleInputChangeEvent,
    handleClearQueryClicked,
    handleSelectOption,
    handleSelectAll,
    filterOptions,
    applyCompanyFilter,
    urlChangeOnCompanyFilterUpdate,
    handleClearFilter,
    updateCompanyInfoFromUrl,
    handleSetCompanyInPastMonths
  };
};
