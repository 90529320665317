import { useContext } from "react";

import { CanopyContext, CanopyContextProps } from "./CanopyContext";

export function useCanopyContext(): CanopyContextProps {
  const context = useContext(CanopyContext);

  if (!context) {
    throw new Error(
      "useCanopyContext must be used within a CanopyContextProvider"
    );
  }

  return context;
}
