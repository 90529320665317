import { GetLoggedInUserAction } from "../actions/CacheGetLoggedInUser/CacheGetLoggedInUserActionTypes";
import { CacheReducerState } from "../models/definitions";
import { initialState } from "./CacheInitialState";

export const handleGetLoggedInUser = (
  state = initialState,
  { payload }: GetLoggedInUserAction
): CacheReducerState => ({
  ...state,
  loggedInUser: payload.loggedInUser
});
