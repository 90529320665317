import clsx from "clsx";
import { Field, FieldProps, getIn } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, FormGroup, Label } from "reactstrap";

import { ComplianceAnswer } from "@arbolus-technologies/models/project";

import { ComplianceAnswerOptions } from "../../../../../constants/expert";
import { UtilsService } from "../../../../../services";
import { CIQFormInput } from "../../../../app/components";
import { yesNoTextQuestionSchema } from "../ProjectApplicationSchema";
import { ComplianceQuestionProps } from "./ProjectCompliance";

const YesNoText: React.FC<ComplianceQuestionProps> = ({
  compliance: { question },
  index,
  formProps: { errors, touched, setFieldValue }
}): JSX.Element => {
  const { t } = useTranslation("projectApplication");
  const { Yes, No } = ComplianceAnswerOptions;
  const { question: QuestionText, displayModel } = question;
  const { defaultText, textRequiredValue, required } = displayModel;

  const answerFieldKey = `compliances[${index}].answer`;
  const fieldTextKey = `compliances[${index}].answer.textAnswer`;

  const error = getIn(errors, answerFieldKey);
  const isError = error && getIn(touched, answerFieldKey);

  const yesNoTextPlaceholder =
    defaultText ||
    t("yesNoTextPlaceholder", {
      question: QuestionText.replace("?", "")
    });

  const handleChangeOption = (selectedOption: string): void => {
    const isTextRequired = selectedOption === textRequiredValue;
    setFieldValue(
      answerFieldKey,
      {
        answer: selectedOption,
        textAnswer: isTextRequired ? "" : undefined
      },
      true
    );
  };

  const validateAsync = async (
    value: ComplianceAnswer
  ): Promise<string | null> => {
    const textAnswerRequired = value.answer === textRequiredValue;

    try {
      await yesNoTextQuestionSchema.validate(
        {
          value,
          answerRequired: required
        },
        { context: { textAnswerRequired } }
      );

      return null;
    } catch (err) {
      return err.message;
    }
  };

  return (
    <div className="compliance-item">
      <FormGroup
        className={clsx({
          "is-invalid": isError
        })}
      >
        <Label>{UtilsService.parseRichTextContent(QuestionText)}</Label>
        <Field name={answerFieldKey} validate={validateAsync}>
          {({ field: { value } }: FieldProps): JSX.Element => {
            const { answer } = value;
            const isTextAreaVisible = answer === textRequiredValue;
            return (
              <>
                <ButtonGroup className="custom-radio-btn-group">
                  <Button
                    onClick={(): void => {
                      handleChangeOption(Yes);
                    }}
                    active={answer === Yes}
                  >
                    {t("yes")}
                  </Button>
                  <Button
                    onClick={(): void => {
                      handleChangeOption(No);
                    }}
                    active={answer === No}
                  >
                    {t("no")}
                  </Button>
                </ButtonGroup>
                {isTextAreaVisible && (
                  <Field
                    name={fieldTextKey}
                    type="textarea"
                    component={CIQFormInput}
                    placeholder={yesNoTextPlaceholder}
                  />
                )}
                {isError && <div className="invalid-feedback">{error}</div>}
              </>
            );
          }}
        </Field>
      </FormGroup>
    </div>
  );
};

export default YesNoText;
