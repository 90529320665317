import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest
} from "ag-grid-community";

import {
  CustomerSurveyOrderBy,
  CustomersService,
  PaginatedRequest,
  SORT_DIRECTION
} from "@arbolus-technologies/api";
import { CustomerSurvey } from "@arbolus-technologies/models/common";

class CustomerSurveyDataSource implements IServerSideDatasource {
  getRows(params: IServerSideGetRowsParams<CustomerSurvey>) {
    const { success, fail, request } = params;

    const queryParams = this.getQueryParams(request);
    CustomersService.getSurveyTemplates(queryParams).subscribe({
      next: (response) =>
        success({
          rowData: response.items,
          rowCount: response.pagination.count
        }),
      error: fail
    });
  }

  private getQueryParams(
    request: IServerSideGetRowsRequest
  ): PaginatedRequest<CustomerSurveyOrderBy> {
    const { startRow, endRow } = request;

    return {
      limit: endRow! - startRow!,
      offset: startRow!,
      orderBy: CustomerSurveyOrderBy.CREATED,
      orderDirection: SORT_DIRECTION.DESCENDING
    };
  }
}

export const DefaultCustomerSurveyDataSource = new CustomerSurveyDataSource();
