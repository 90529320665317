import {
  CIQError,
  ErrorResponse,
  ExpertDetail,
  ExpertsListPageTab,
  RecommendationResponse,
  ReferralSummary,
  ResponseAllExpertsTable,
  StatusExpertTable,
  TaglineRequestInterface,
  UpdateReferralRequest
} from "@arbolus-technologies/api";

import { ScheduleFilterDefinitions } from "../definitions";
import {
  ASSIGN_ANGLE_SHORTLIST_TO_CANDIDATE,
  ASSIGN_ANGLE_SHORTLIST_TO_CANDIDATE_FAILURE,
  ASSIGN_ANGLE_SHORTLIST_TO_CANDIDATE_SUCCESS,
  AssignAngleShortlistToCandidateAction,
  AssignAngleShortlistToCandidateFailureAction,
  AssignAngleShortlistToCandidateSuccessAction,
  EDIT_TAGLINE,
  EDIT_TAGLINE_FAILURE,
  EDIT_TAGLINE_SUCCESS,
  EditTaglineAction,
  EditTaglineFailureAction,
  EditTaglineSuccessAction,
  FILTER_TABLE_BY_ANGLE,
  FORCE_CLOSE_PANEL,
  FilterTableByAngleAction,
  ForceClosePanelAction,
  GET_ALL_EXPERTS,
  GET_ALL_EXPERTS_FAILURE,
  GET_ALL_EXPERTS_SUCCESS,
  GET_ANGLE_EXPERT,
  GET_ANGLE_EXPERT_FAILURE,
  GET_ANGLE_EXPERT_SUCCESS,
  GET_PROJECT_RECOMMENDATIONS,
  GET_PROJECT_RECOMMENDATIONS_FAILURE,
  GET_PROJECT_RECOMMENDATIONS_SUCCESS,
  GET_PROJECT_SUMMARY,
  GET_PROJECT_SUMMARY_FAILURE,
  GET_PROJECT_SUMMARY_SUCCESS,
  GetAllExpertsAction,
  GetAllExpertsFailureAction,
  GetAllExpertsSuccessAction,
  GetAngleExpertAction,
  GetAngleExpertFailureAction,
  GetAngleExpertSuccessAction,
  GetProjectSummaryAction,
  GetProjectSummaryFailureAction,
  GetProjectSummarySuccessAction,
  GetRecommendationsAction,
  GetRecommendationsFailureAction,
  GetRecommendationsSuccessAction,
  REFETCH_REFERRAL_SUMMARY,
  RESET_TABLE_ACTION,
  RefetchReferralSummaryAction,
  ResetTableAction,
  SET_EXPERT_LIST_TAB,
  SET_SCHEDULE_TAB_FILTERS,
  SetExpertListTabAction,
  SetScheduleTabFiltersAction,
  UPDATE_ALL_EXPERTS_FAST_TRACK_STATUS,
  UPDATE_REFERRAL_ANGLE,
  UPDATE_REFERRAL_ANGLE_FAILURE,
  UPDATE_REFERRAL_ANGLE_SUCCESS,
  UPDATE_REFERRAL_STATUS,
  UPDATE_REFERRAL_STATUS_FAILURE,
  UPDATE_REFERRAL_STATUS_SUCCESS,
  UpdateAllExpertsFastTrackStatusAction,
  UpdateReferralAngleAction,
  UpdateReferralAngleFailureAction,
  UpdateReferralAngleSuccessAction,
  UpdateReferralStatusAction,
  UpdateReferralStatusFailureAction,
  UpdateReferralStatusSuccessAction
} from "./ProjectExpertsActionTypes";

// MAGIC ACTION THAT TRIGGERS REFETCH FROM OUTSIDE NX
export const refetchReferralSummary = (
  projectId: string
): RefetchReferralSummaryAction => ({
  type: REFETCH_REFERRAL_SUMMARY,
  payload: {
    projectId
  }
});

// GET ALL EXPERTS LIST
export const getAllExperts = (
  projectId: string,
  activeTab: ExpertsListPageTab
): GetAllExpertsAction => ({
  type: GET_ALL_EXPERTS,
  payload: {
    projectId,
    activeTab
  }
});

export const getAllExpertsSuccess = (
  expertsList: ResponseAllExpertsTable[]
): GetAllExpertsSuccessAction => ({
  type: GET_ALL_EXPERTS_SUCCESS,
  payload: {
    expertsList
  }
});

export const getAllExpertsFailure = (): GetAllExpertsFailureAction => ({
  type: GET_ALL_EXPERTS_FAILURE
});

// GET RECOMMENDATIONS
export const getRecommendations = (
  projectId: string
): GetRecommendationsAction => ({
  type: GET_PROJECT_RECOMMENDATIONS,
  payload: {
    projectId
  }
});

export const getRecommendationsSuccess = (
  recommendations: RecommendationResponse[]
): GetRecommendationsSuccessAction => ({
  type: GET_PROJECT_RECOMMENDATIONS_SUCCESS,
  payload: {
    recommendations
  }
});

export const getRecommendationsFailure =
  (): GetRecommendationsFailureAction => ({
    type: GET_PROJECT_RECOMMENDATIONS_FAILURE
  });

// GET ANGLE EXPERT
export const getAngleExpert = (expertId: string): GetAngleExpertAction => ({
  type: GET_ANGLE_EXPERT,
  payload: {
    expertId
  }
});

export const getAngleExpertSuccess = (
  angleExpert: ExpertDetail
): GetAngleExpertSuccessAction => ({
  type: GET_ANGLE_EXPERT_SUCCESS,
  payload: {
    angleExpert
  }
});

export const getAngleExpertFailure = (): GetAngleExpertFailureAction => ({
  type: GET_ANGLE_EXPERT_FAILURE
});

export const updateReferralStatus = (
  projectId: string,
  referralId: string,
  status: UpdateReferralRequest,
  successMessage?: string
): UpdateReferralStatusAction => ({
  type: UPDATE_REFERRAL_STATUS,
  payload: {
    projectId,
    referralId,
    status,
    successMessage
  }
});

export const updateReferralStatusSuccess = (
  referralId: string,
  status: StatusExpertTable
): UpdateReferralStatusSuccessAction => ({
  type: UPDATE_REFERRAL_STATUS_SUCCESS,
  payload: {
    referralId,
    status
  }
});

export const updateReferralStatusFailure =
  (): UpdateReferralStatusFailureAction => ({
    type: UPDATE_REFERRAL_STATUS_FAILURE
  });

export const updateAllExpertsFastTrackStatus = (
  id: string
): UpdateAllExpertsFastTrackStatusAction => ({
  type: UPDATE_ALL_EXPERTS_FAST_TRACK_STATUS,
  payload: {
    id
  }
});

export const setExpertListTab = (
  tabActive: ExpertsListPageTab
): SetExpertListTabAction => ({
  type: SET_EXPERT_LIST_TAB,
  payload: {
    tabActive
  }
});

export const assignAngleShortlistToCandidate = (
  referralId: string,
  angleId: string,
  projectId: string
): AssignAngleShortlistToCandidateAction => ({
  type: ASSIGN_ANGLE_SHORTLIST_TO_CANDIDATE,
  payload: {
    referralId,
    angleId,
    projectId
  }
});

export const assignAngleShortlistToCandidateSuccess =
  (): AssignAngleShortlistToCandidateSuccessAction => ({
    type: ASSIGN_ANGLE_SHORTLIST_TO_CANDIDATE_SUCCESS
  });

export const assignAngleShortlistToCandidateFailure =
  (): AssignAngleShortlistToCandidateFailureAction => ({
    type: ASSIGN_ANGLE_SHORTLIST_TO_CANDIDATE_FAILURE
  });

export const forceClosePanel = (): ForceClosePanelAction => ({
  type: FORCE_CLOSE_PANEL
});

export const filterTableByAngleS = (
  values: Array<string | null>
): FilterTableByAngleAction => ({
  type: FILTER_TABLE_BY_ANGLE,
  payload: {
    values
  }
});

export const resetTable = (): ResetTableAction => ({
  type: RESET_TABLE_ACTION
});

export const getProjectSummary = (
  projectId: string,
  angleId?: string
): GetProjectSummaryAction => ({
  type: GET_PROJECT_SUMMARY,
  payload: {
    projectId,
    angleId
  }
});

export const getProjectSummarySuccess = (
  summaryList: ReferralSummary
): GetProjectSummarySuccessAction => ({
  type: GET_PROJECT_SUMMARY_SUCCESS,
  payload: {
    summaryList
  }
});

export const getProjectSummaryFailure = (): GetProjectSummaryFailureAction => ({
  type: GET_PROJECT_SUMMARY_FAILURE
});

// EDIT TAGLINE
export const editTagline = (
  taglineRequestObject: TaglineRequestInterface,
  closePanel: () => void,
  activeTab: ExpertsListPageTab
): EditTaglineAction => ({
  type: EDIT_TAGLINE,
  payload: {
    taglineRequestObject,
    closePanel,
    activeTab
  }
});

export const editTaglineSuccess = (): EditTaglineSuccessAction => ({
  type: EDIT_TAGLINE_SUCCESS
});

export const editTaglineFailure = (
  error: ErrorResponse<CIQError>
): EditTaglineFailureAction => ({
  type: EDIT_TAGLINE_FAILURE,
  payload: {
    error
  }
});

export const updateReferralAngle = (
  referralId: string,
  angleId: string,
  projectId: string
): UpdateReferralAngleAction => ({
  type: UPDATE_REFERRAL_ANGLE,
  payload: {
    referralId,
    angleId,
    projectId
  }
});

export const updateReferralAngleSuccess =
  (): UpdateReferralAngleSuccessAction => ({
    type: UPDATE_REFERRAL_ANGLE_SUCCESS
  });

export const updateReferralAngleFailure =
  (): UpdateReferralAngleFailureAction => ({
    type: UPDATE_REFERRAL_ANGLE_FAILURE
  });

export const setScheduleTabFilters = (
  projectId: string,
  filters: ScheduleFilterDefinitions
): SetScheduleTabFiltersAction => ({
  type: SET_SCHEDULE_TAB_FILTERS,
  payload: { projectId, filters }
});

export const ProjectExpertsStoreActions = {
  getAllExperts,
  getAllExpertsSuccess,
  getAllExpertsFailure,
  getRecommendations,
  getRecommendationsSuccess,
  getRecommendationsFailure,
  getAngleExpert,
  getAngleExpertSuccess,
  getAngleExpertFailure,
  updateReferralStatus,
  updateReferralStatusSuccess,
  updateReferralStatusFailure,
  updateAllExpertsFastTrackStatus,
  setExpertListTab,
  assignAngleShortlistToCandidate,
  assignAngleShortlistToCandidateSuccess,
  assignAngleShortlistToCandidateFailure,
  forceClosePanel,
  filterTableByAngleS,
  resetTable,
  getProjectSummary,
  getProjectSummarySuccess,
  getProjectSummaryFailure,
  editTagline,
  editTaglineSuccess,
  editTaglineFailure,
  updateReferralAngle,
  updateReferralAngleSuccess,
  updateReferralAngleFailure,
  setScheduleTabFilters,
  refetchReferralSummary
};
