import queryString from "query-string";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import {
  DefaultToasterService,
  ToasterService
} from "@arbolus-technologies/api";
import {
  DiscoverFilterOperator,
  DiscoverFilterOption,
  DiscoverFilterType
} from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  ProjectNxSelector,
  ProjectNxStoreActions
} from "@arbolus-technologies/stores/project";
import { useDebounce, utilService } from "@arbolus-technologies/utils";

import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { CountryAndRegion } from "../../Models/Discover";
import { cloneAndFilter } from "../../Utils/DiscoverExpertUtils";
import { useDiscoverFilters } from "./useDiscoverFilters";

interface UseDiscoverExpertLocationFilterProps {
  notificationService?: ToasterService;
}

export interface UseDiscoverExpertLocationFilter {
  handleInputChangeEvent: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  handleClearQueryClicked: () => void;
  handleSelectOption: (option: DiscoverFilterOption) => void;
  filterOptions: DiscoverFilterOption[];
  applyExpertLocationFilter: () => void;
  urlChangeOnExpertLocationFilterUpdate: (
    options: DiscoverFilterOption[]
  ) => void;
  handleClearFilter: () => void;
  updateExpertLocationInfoFromUrl: () => DiscoverFilterOption[];
}

const MINIMUM_SEARCH_LENGTH = 2;

export const useDiscoverExpertLocationFilter = ({
  notificationService = DefaultToasterService
}: UseDiscoverExpertLocationFilterProps = {}): UseDiscoverExpertLocationFilter => {
  const { t } = useTranslation("discoverPage");
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { trackFiltered } = useArbolusTracking();

  const parameters = queryString.parse(location.search);
  const {
    includedExpertLocations,
    excludedExpertLocations
  }: { includedExpertLocations?: string; excludedExpertLocations?: string } =
    parameters;

  const {
    selectedOptions,
    setSelectedOptions,
    preSelectedOptions,
    setPreSelectedOptions,
    clearFilter
  } = useDiscoverFilters({
    filterType: DiscoverFilterType.Expert_Locations
  });

  const [filterOptions, setFilterOptions] = useState<DiscoverFilterOption[]>(
    []
  );

  const metadataCountries = useSelector(CacheSelector.metadataCountries());
  const regions = useSelector(CacheSelector.regions());
  const isAdmin = useSelector(CacheSelector.isAdmin());
  const projectName = useSelector(ProjectNxSelector.projectName());
  const projectClientName = useSelector(ProjectNxSelector.projectClientName());

  const expertLocationFilterQuery = useSelector(
    ProjectNxSelector.filtersQuery()
  )[DiscoverFilterType.Expert_Locations];
  const debouncedSearchQuery = useDebounce<string>(
    expertLocationFilterQuery,
    300
  );

  const countriesAndRegions = (): CountryAndRegion[] => {
    const countriesWithoutRegions = metadataCountries.filter(
      (country) => !regions.map((region) => region.name).includes(country.name)
    );
    return Array.from(new Set([...countriesWithoutRegions, ...regions]));
  };

  const expertLocationSelectors = countriesAndRegions().map((location) => ({
    value: location.id,
    label: location.threeLetterCode ?? location.name,
    searchLabel: location.name,
    twoLetterCode: location.twoLetterCode ?? location.name,
    operator: DiscoverFilterOperator.Include
  }));

  useEffect(() => {
    if (preSelectedOptions.length === 10) {
      notificationService.showError(t("maximumLocations"));
    }
  }, [notificationService, preSelectedOptions, t]);

  const getExpertLocationSearchResults = (query: string) => {
    const locations = expertLocationSelectors.filter(
      (expertLocation) =>
        expertLocation.label.toLowerCase().includes(query.toLowerCase()) ||
        expertLocation.searchLabel.toLowerCase().includes(query.toLowerCase())
    );

    setFilterOptions(locations);
  };

  const handleInputChangeEvent = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const newQuery = event.target.value;
    dispatch(
      ProjectNxStoreActions.setFilterQuery(
        DiscoverFilterType.Expert_Locations,
        newQuery
      )
    );
  };

  useEffect(() => {
    if (debouncedSearchQuery.length >= MINIMUM_SEARCH_LENGTH) {
      getExpertLocationSearchResults(debouncedSearchQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  const handleClearQueryClicked = () => {
    dispatch(
      ProjectNxStoreActions.setFilterQuery(
        DiscoverFilterType.Expert_Locations,
        ""
      )
    );
  };

  const handleSelectOption = (option: DiscoverFilterOption) => {
    const isOptionSelected = preSelectedOptions
      .map((selectedOption) => selectedOption.value)
      .includes(option.value);

    if (isOptionSelected) {
      const filteredOptions = preSelectedOptions.filter(
        (selectedOption) => selectedOption.value !== option.value
      );
      setPreSelectedOptions(filteredOptions);
    } else {
      setPreSelectedOptions([...preSelectedOptions, option]);
    }
  };

  const applyExpertLocationFilter = () => {
    const selectedOptionsUpdated = cloneAndFilter(
      preSelectedOptions,
      selectedOptions
    );
    setSelectedOptions(selectedOptionsUpdated);
    urlChangeOnExpertLocationFilterUpdate(selectedOptionsUpdated);
    trackFiltered(MixPanelEventNames.InternalSearchCurrentLocationSearch, {
      Project: projectName,
      Client: projectClientName
    });
  };

  useEffect(() => {
    if (expertLocationFilterQuery.length === 0) {
      setFilterOptions([]);
    }
  }, [expertLocationFilterQuery.length]);

  const updateExpertLocationInfoFromUrl = () => {
    const includedExpertLocationIds = includedExpertLocations
      ? includedExpertLocations.split(",")
      : [];
    const excludedExpertLocationIds = excludedExpertLocations
      ? excludedExpertLocations.split(",")
      : [];

    return [...includedExpertLocationIds, ...excludedExpertLocationIds]
      .map((value) => {
        const option = expertLocationSelectors.find(
          (selectedOption) => selectedOption.value === value
        );

        if (option) {
          option.operator = includedExpertLocationIds.includes(value)
            ? DiscoverFilterOperator.Include
            : DiscoverFilterOperator.Exclude;
        }
        return option;
      })
      .filter((option) => !!option) as DiscoverFilterOption[];
  };

  const urlChangeOnExpertLocationFilterUpdate = (
    options: DiscoverFilterOption[]
  ) => {
    if (isAdmin) {
      const includedExpertLocations = options
        .filter((option) => option.operator === DiscoverFilterOperator.Include)
        .map((option) => option.value)
        .join(",");

      const excludedExpertLocations = options
        .filter((option) => option.operator === DiscoverFilterOperator.Exclude)
        .map((option) => option.value)
        .join(",");

      const nonEmptyParams = utilService.getNonEmptyQueryString(parameters);
      if (includedExpertLocations) {
        nonEmptyParams["includedExpertLocations"] = includedExpertLocations;
      } else {
        delete nonEmptyParams["includedExpertLocations"];
      }

      if (excludedExpertLocations) {
        nonEmptyParams["excludedExpertLocations"] = excludedExpertLocations;
      } else {
        delete nonEmptyParams["excludedExpertLocations"];
      }

      const params = new URLSearchParams(nonEmptyParams);
      history.replace({
        pathname: location.pathname,
        search: params.toString()
      });
    }
  };

  const handleClearFilter = () => {
    clearFilter();
    if (isAdmin) {
      const nonEmptyParams = utilService.getNonEmptyQueryString(parameters);
      delete nonEmptyParams["includedExpertLocations"];
      delete nonEmptyParams["excludedExpertLocations"];
      const params = new URLSearchParams(nonEmptyParams);
      history.replace({
        pathname: location.pathname,
        search: params.toString()
      });
    }
  };

  return {
    handleInputChangeEvent,
    handleClearQueryClicked,
    handleSelectOption,
    filterOptions,
    applyExpertLocationFilter,
    urlChangeOnExpertLocationFilterUpdate,
    handleClearFilter,
    updateExpertLocationInfoFromUrl
  };
};
