import { Icon } from "arbolus-ui-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ExpertsListPageTab,
  PROJECT_REFERRAL_STATE,
  ProjectService,
  ResponseAllExpertsTable,
  StatusExpertTable,
  ToasterService,
  UpdateReferralRequest
} from "@arbolus-technologies/api";
import {
  AddEditAngleSlidePanel,
  ClipboardManager,
  DefaultClipboardManager
} from "@arbolus-technologies/features/common";
import {
  SHARE_PROJECT_BOOKING,
  SHARE_PROJECT_EXPERT_APPLICATION
} from "@arbolus-technologies/routes";
import {
  ProjectExpertsSelector,
  ProjectExpertsStoreActions
} from "@arbolus-technologies/stores/project-experts-store";
import {
  ARBOLUS_COLORS,
  statusExpertCandidate
} from "@arbolus-technologies/theme";
import {
  ArbolusModal,
  CIQPopover,
  HR,
  PopoverItem,
  ThreeDotsUi
} from "@arbolus-technologies/ui/components";

import { ItemInterface, handleNameItems, itemList } from "./contentByActive";

import styles from "./ThreeDots.module.scss";

interface ModalStateInterface {
  isOpen: boolean;
  messageTitle: string;
  warningMessage: string;
  confirmActionText: string;
  itemSelected: string;
}

const modalInitialState = {
  isOpen: false,
  messageTitle: "",
  warningMessage: "",
  confirmActionText: "",
  itemSelected: ""
};

interface ThreeDotsProps {
  row: ResponseAllExpertsTable;
  expertProfilePath: (expertId: string) => string;
  handleSlidePanelExpertProfile: (
    projectId: string | undefined,
    expertId: string,
    referralId: string
  ) => void;
  notificationService?: ToasterService;
  clipboardManager?: ClipboardManager;
}

export const ThreeDots: React.FC<ThreeDotsProps> = ({
  row,
  expertProfilePath,
  handleSlidePanelExpertProfile,
  notificationService = DefaultToasterService,
  clipboardManager = DefaultClipboardManager
}) => {
  const { t } = useTranslation("projectExpertListMenu");
  const history = useHistory();
  const dispatch = useDispatch();

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [modalDetails, setModalDetails] =
    useState<ModalStateInterface>(modalInitialState);
  const [resetPopover, setResetPopover] = useState<boolean>(false);
  const [isAnglePanelOpen, setIsAnglePanelOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const forceClosePanel = useSelector(ProjectExpertsSelector.forceClosePanel());
  const activeTab = useSelector(ProjectExpertsSelector.activeTab());
  const showAskApplyNowItem =
    (activeTab === ExpertsListPageTab.Candidate ||
      activeTab === ExpertsListPageTab.ShortList) &&
    !row.fastTrack;
  const showBookingLink =
    activeTab === ExpertsListPageTab.Accept &&
    row.expert.isAnyValidAvailability;

  useEffect(() => {
    setIsAnglePanelOpen(false);
  }, [forceClosePanel]);

  const handleUpdateReferralState = useCallback(
    (state: UpdateReferralRequest, successText: string) => {
      dispatch(
        ProjectExpertsStoreActions.updateReferralStatus(
          row.projectId,
          row.id,
          state,
          successText
        )
      );
    },
    [dispatch, row.id, row.projectId]
  );

  useEffect(() => {
    if (modalDetails || isAnglePanelOpen) {
      setIsPopoverOpen(false);
    }
  }, [modalDetails, isAnglePanelOpen]);

  useEffect(() => {
    setIsPopoverOpen(false);
  }, [resetPopover]);

  const handleOnClick = useCallback(
    (itemName: string) => {
      const {
        EDIT_PROFILE,
        ADD_NOTE,
        CANDIDATE,
        REMOVE,
        RESET_APP,
        EDIT_ANGLE,
        SEND_APPLICATION_REMINDER,
        COPY_APPLICATION_LINK,
        COPY_BOOKING_LINK
      } = handleNameItems;

      switch (itemName) {
        case EDIT_PROFILE:
          history.push(expertProfilePath(row.expert.id));
          break;
        case ADD_NOTE:
          handleSlidePanelExpertProfile(row.projectId, row.expert.id, row.id);
          setResetPopover((prev) => !prev);
          break;
        case CANDIDATE: {
          if (row.angle === null) {
            setIsEdit(false);
            setIsAnglePanelOpen(true);
            break;
          } else {
            const hasPublicCompany = !!row.expert.lastPublicCompanyExpDate;
            setModalDetails((prev) => ({
              ...prev,
              isOpen: true,
              messageTitle: hasPublicCompany
                ? t("moveExpertPublicCompany")
                : t("moveExpert"),
              warningMessage: hasPublicCompany
                ? t("areYouSureCandidatePublicCompany")
                : t("areYouSureCandidate"),
              confirmActionText: t("accept"),
              itemSelected: CANDIDATE
            }));
            break;
          }
        }
        case REMOVE:
          setModalDetails((prev) => ({
            ...prev,
            isOpen: true,
            messageTitle: t("removeExpert"),
            warningMessage: t("areYouSure"),
            confirmActionText: t("delete"),
            itemSelected: REMOVE
          }));
          break;
        case RESET_APP:
          setModalDetails((prev) => ({
            ...prev,
            isOpen: true,
            messageTitle: t("resetApp"),
            warningMessage: t("areYouSureResetApp"),
            confirmActionText: t("reset"),
            itemSelected: RESET_APP
          }));
          break;
        case EDIT_ANGLE:
          setIsEdit(true);
          setIsAnglePanelOpen(true);
          break;
        case SEND_APPLICATION_REMINDER:
          setModalDetails((prev) => ({
            ...prev,
            isOpen: true,
            messageTitle: t("resendApplicationModalTitle"),
            warningMessage: t("resendApplicationModalMessage"),
            confirmActionText: t("confirm"),
            itemSelected: SEND_APPLICATION_REMINDER
          }));
          break;
        case COPY_APPLICATION_LINK:
          clipboardManager.copy(
            SHARE_PROJECT_EXPERT_APPLICATION(row.projectId)
          );
          break;
        case COPY_BOOKING_LINK:
          clipboardManager.copy(SHARE_PROJECT_BOOKING(row.projectId, row.id));
          break;
        default:
          return console.error("No case detected");
      }
    },
    [
      clipboardManager,
      expertProfilePath,
      handleSlidePanelExpertProfile,
      history,
      row.angle,
      row.expert.id,
      row.expert.lastPublicCompanyExpDate,
      row.id,
      row.projectId,
      t
    ]
  );

  const popoverItems = useMemo(() => {
    const items = [];

    if (showAskApplyNowItem) {
      items.push({
        popoverTitle: t("askToApply"),
        iconName: "send",
        isPurple: true,
        handleClickAction: () => {
          setResetPopover((prev) => !prev);
          handleUpdateReferralState({ fastTrack: true }, t("inviteSent"));
        }
      });
    } else if (
      activeTab !== ExpertsListPageTab.Reject &&
      activeTab !== ExpertsListPageTab.Recommendations &&
      row.applicationStatus !== StatusExpertTable.Accept
    ) {
      items.push({
        popoverTitle: t("sendApplicationReminder"),
        iconName: "send",
        isPurple: true,
        handleClickAction: () =>
          handleOnClick(handleNameItems.SEND_APPLICATION_REMINDER)
      });
      items.push({
        popoverTitle: t("copyApplicationLink"),
        iconName: "link",
        handleClickAction: () =>
          handleOnClick(handleNameItems.COPY_APPLICATION_LINK)
      });
    } else if (showBookingLink) {
      items.push({
        popoverTitle: t("copyBookingLink"),
        iconName: "link",
        handleClickAction: () =>
          handleOnClick(handleNameItems.COPY_BOOKING_LINK)
      });
    }

    return items.length ? (
      <>
        {items.map(
          ({ popoverTitle, handleClickAction, iconName, isPurple }) => (
            <div
              className={isPurple ? styles.popoverItem : ""}
              key={popoverTitle}
            >
              <PopoverItem
                title={popoverTitle}
                icon={
                  <Icon
                    color={
                      (isPurple && ARBOLUS_COLORS.bColorBasePurple) || undefined
                    }
                    fontSize="16px"
                    name={iconName}
                  />
                }
                handleOnClick={handleClickAction}
              />
            </div>
          )
        )}
      </>
    ) : null;
  }, [
    showAskApplyNowItem,
    activeTab,
    row.applicationStatus,
    showBookingLink,
    t,
    handleUpdateReferralState,
    handleOnClick
  ]);

  const handleOnConfirm = () => {
    const { itemSelected } = modalDetails;
    const { CANDIDATE, REMOVE, RESET_APP, SEND_APPLICATION_REMINDER } =
      handleNameItems;

    switch (itemSelected) {
      case CANDIDATE:
        handleUpdateReferralState(
          {
            status: PROJECT_REFERRAL_STATE.CANDIDATE.toLowerCase()
          },
          t("moveExpertToCandidateSuccess")
        );
        break;
      case REMOVE:
        removeExpertFromProject();
        break;
      case RESET_APP:
        handleUpdateReferralState({ reset: true }, t("resetSuccess"));
        break;
      case SEND_APPLICATION_REMINDER:
        setResetPopover((prev) => !prev);
        ProjectService.sendApplicationReminder(
          row.projectId,
          row.expert.id
        ).subscribe(
          () => {
            notificationService.showSuccess(t("applicationReminderSent"));
          },
          (error: ErrorResponse<CIQError>) => {
            notificationService.showError(error.message);
          }
        );
        break;
      default:
        console.error("No item selection detected.");
        break;
    }
    setModalDetails(modalInitialState);
  };

  const removeExpertFromProject = () => {
    ProjectService.removeFromProject(row.projectId, row.id).subscribe(
      () => {
        dispatch(
          ProjectExpertsStoreActions.getAllExperts(row.projectId, activeTab!)
        );
        dispatch(ProjectExpertsStoreActions.getProjectSummary(row.projectId));
        notificationService.showSuccess(t("expertRemoved"));
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showError(error.message);
      }
    );
  };

  if (!activeTab) {
    return null;
  }

  return (
    <>
      <div
        className={styles.referralTablePopover}
        id={`popover_${row.id}`}
        onClick={() => setIsPopoverOpen(true)}
      >
        <ThreeDotsUi isPopoverOpen={isPopoverOpen} />
        <CIQPopover
          id={`popover_${row.id}`}
          isOpen={isPopoverOpen}
          onPopoverClose={() => setIsPopoverOpen(false)}
          customBodyClasses={styles.popoverBody}
        >
          {popoverItems}
          {!!popoverItems && <HR margin={{ top: 0, bottom: 0 }} />}
          {itemList[activeTab]?.map((item: ItemInterface) => {
            const { title, iconName, showDivider, handleName } = item;

            const iconJsx =
              iconName === PROJECT_REFERRAL_STATE.CANDIDATE.toLowerCase() ? (
                <img
                  src={statusExpertCandidate}
                  alt={t("moveExpertToCandidateSuccess")}
                />
              ) : (
                <Icon
                  color={ARBOLUS_COLORS.bColorBaseDark}
                  fontSize="16px"
                  name={iconName}
                />
              );

            return (
              <React.Fragment key={title}>
                {showDivider && <HR margin={{ top: 0, bottom: 0 }} />}
                <PopoverItem
                  title={t(title)}
                  icon={iconJsx}
                  handleOnClick={() => handleOnClick(handleName)}
                />
              </React.Fragment>
            );
          })}
        </CIQPopover>
      </div>
      <ArbolusModal
        title={modalDetails.messageTitle}
        subtitle={modalDetails.warningMessage}
        toggle={() => setModalDetails(modalInitialState)}
        isOpen={modalDetails.isOpen}
        leftButton={{
          text: t("Cancel"),
          type: "secondary",
          onClick: () => setModalDetails(modalInitialState)
        }}
        rightButton={{
          text: modalDetails.confirmActionText,
          type:
            modalDetails.itemSelected === handleNameItems.REMOVE
              ? "rejection"
              : "primary",
          onClick: handleOnConfirm
        }}
      />
      <AddEditAngleSlidePanel
        panelOpen={isAnglePanelOpen}
        setPanelOpen={setIsAnglePanelOpen}
        isEdit={isEdit}
        projectId={row.projectId}
        referralId={row.id}
        angle={row.angle}
        expert={{
          ...row.expert,
          profileImageUrl: row.expert.profileImageUrl ?? ""
        }}
      />
    </>
  );
};
