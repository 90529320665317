import React from "react";

import styles from "./IncidentListInfoSection.module.scss";

interface BulletPoint {
  title: string;
  text: string;
}

interface IncidentInfoSectionProps {
  title: string;
  topText: string;
  bulletPoints: BulletPoint[];
  bottomText?: string;
}

export const IncidentListInfoSection: React.FC<IncidentInfoSectionProps> = ({
  title,
  topText,
  bulletPoints,
  bottomText
}) => {
  const getBulletList = (): JSX.Element | undefined =>
    bulletPoints ? (
      <ul>
        {bulletPoints.map((bulletPoint) => (
          <li key={bulletPoint.title} className={styles.bulletPoint}>
            <span className={styles.bulletPointTitle}>{bulletPoint.title}</span>
            {bulletPoint.text}
          </li>
        ))}
      </ul>
    ) : undefined;

  return (
    <div className={styles.container}>
      <span className={styles.title}>{title}</span>
      <span>{topText}</span>
      {getBulletList()}
      {bottomText && <div className={styles.bottomText}>{bottomText}</div>}
    </div>
  );
};
