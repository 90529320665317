import React from "react";

import { NumberCircle } from "../NumberCircle/NumberCircle";

import styles from "./OrderedList.module.scss";

interface OrderedListProps {
  items: string[];
  isNumberBold?: boolean;
}

export const OrderedList: React.FC<OrderedListProps> = ({
  items,
  isNumberBold = false
}) => (
  <div>
    {items.map((item, index) => (
      <div key={index} className={styles.listItem}>
        <NumberCircle number={index + 1} isBold={isNumberBold} />
        <span className={styles.itemContent}>{item}</span>
      </div>
    ))}
  </div>
);
