import i18next from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Collapse, Row } from "reactstrap";

import { REFERRAL_SUB_STATE } from "@arbolus-technologies/models/common";
import { APP_DATE_FORMAT, formatDate } from "@arbolus-technologies/utils";

import { ReferralReviewStats } from "../../../../../models/view/project";
import { UtilsService } from "../../../../../services";
import { CIQAvatar } from "../../../../app/components";
import { ExpertProjectProps } from "./ExpertProfileHolder";

interface ExpertReferralStateProps {
  uiReferral: ReferralReviewStats;
  projectData: ExpertProjectProps;
}

const referralStatApplicationDescText = {
  [`${REFERRAL_SUB_STATE.ACCEPT}`]: i18next.t(
    "expertReferralState:referralApplicationAccepted"
  ),
  [`${REFERRAL_SUB_STATE.REJECT}`]: i18next.t(
    "expertReferralState:referralApplicationRejected"
  )
};

const referralStatClientDescText = {
  [`${REFERRAL_SUB_STATE.ACCEPT}`]: i18next.t(
    "expertReferralState:referralReviewAccepted"
  ),
  [`${REFERRAL_SUB_STATE.REJECT}`]: i18next.t(
    "expertReferralState:referralReviewRejected"
  )
};

const referralApplicationStateText = {
  [`${REFERRAL_SUB_STATE.PENDING}`]: i18next.t(
    "expertReferralState:referralSubStatePending"
  ),
  [`${REFERRAL_SUB_STATE.ACCEPT}`]: i18next.t(
    "expertReferralState:referralSubStateApplicationAccept"
  ),
  [`${REFERRAL_SUB_STATE.REJECT}`]: i18next.t(
    "expertReferralState:referralSubStateApplicationReject"
  ),
  [`${REFERRAL_SUB_STATE.UI_NOT_REQUIRED}`]: i18next.t(
    "expertReferralState:referralSubStateNotRequired"
  )
};

const referralComplianceStateText = {
  [`${REFERRAL_SUB_STATE.PENDING}`]: i18next.t(
    "expertReferralState:referralSubStatePending"
  ),
  [`${REFERRAL_SUB_STATE.ACCEPT}`]: i18next.t(
    "expertReferralState:referralSubStateComplianceAccept"
  ),
  [`${REFERRAL_SUB_STATE.REJECT}`]: i18next.t(
    "expertReferralState:referralSubStateComplianceReject"
  ),
  [`${REFERRAL_SUB_STATE.UI_NOT_REQUIRED}`]: i18next.t(
    "expertReferralState:referralSubStateNotRequired"
  )
};

const referralStateText = {
  [`${REFERRAL_SUB_STATE.PENDING}`]: i18next.t(
    "expertReferralState:referralSubStatePending"
  ),
  [`${REFERRAL_SUB_STATE.ACCEPT}`]: i18next.t(
    "expertReferralState:referralSubStateAccept"
  ),
  [`${REFERRAL_SUB_STATE.REJECT}`]: i18next.t(
    "expertReferralState:referralSubStateReject"
  ),
  [`${REFERRAL_SUB_STATE.UI_NOT_REQUIRED}`]: i18next.t(
    "expertReferralState:referralSubStateNotRequired"
  )
};

const referralStateClasses = {
  [`${REFERRAL_SUB_STATE.PENDING}`]: "pending",
  [`${REFERRAL_SUB_STATE.ACCEPT}`]: "approved",
  [`${REFERRAL_SUB_STATE.REJECT}`]: "rejected",
  [`${REFERRAL_SUB_STATE.UI_NOT_REQUIRED}`]: "not-required"
};

const ExpertReferralState: React.FC<ExpertReferralStateProps> = ({
  uiReferral,
  projectData
}): JSX.Element => {
  const [isBriefExpanded, setBriefExpand] = useState<boolean>(false);
  const [t] = useTranslation("expertReferralState");

  const {
    applicationStatus,
    complianceStatus,
    reviewStatus,
    applicationUser,
    complianceUser,
    reviewUser,
    reviewUpdated,
    complianceUpdated,
    applicationUpdated,
    summaryText
  } = uiReferral;

  const toggleReferralBrief = (): void => setBriefExpand(!isBriefExpanded);

  return (
    <Row className="expert-status-container">
      <Col>
        <div className="top-container">
          <div className="left-action" />
          <div className="center-action">
            <Button
              size="md"
              color="primary"
              className="with-icon dropdown-btn"
              onClick={toggleReferralBrief}
            >
              {summaryText}
              <i className="ciq-icon ciq-ic-info d-none d-sm-inline-block" />
            </Button>
          </div>
        </div>
        <div className="bottom-container">
          <Collapse isOpen={isBriefExpanded}>
            <div className="card-container">
              <div className="card">
                <div className="title">{t("review")}</div>
                <div className={`status ${referralStateClasses[reviewStatus]}`}>
                  {referralStateText[reviewStatus]}
                </div>
                {reviewUser && (
                  <>
                    <div className="picture">
                      <CIQAvatar
                        username={UtilsService.displayUserName(reviewUser)}
                        profileImageUrl={reviewUser?.profileImageUrl}
                      />
                    </div>
                    <div className="timestamp">
                      <span className="line-1">
                        {" "}
                        {referralStatClientDescText[reviewStatus]}
                      </span>
                      <span className="line-2">
                        {UtilsService.displayUserName(reviewUser)}
                      </span>
                      <span className="line-3">
                        on {formatDate(reviewUpdated as Date, APP_DATE_FORMAT)}
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div className="card">
                <div className="title">{t("application")}</div>
                <div
                  className={`status ${referralStateClasses[applicationStatus]}`}
                >
                  {referralApplicationStateText[applicationStatus]}
                </div>
                {applicationUser && (
                  <>
                    <div className="picture">
                      <CIQAvatar
                        username={UtilsService.displayUserName(applicationUser)}
                        profileImageUrl={applicationUser?.profileImageUrl}
                      />
                    </div>
                    <div className="timestamp">
                      <span className="line-1">
                        {referralStatApplicationDescText[applicationStatus]}
                      </span>
                      <span className="line-2">
                        {UtilsService.displayUserName(applicationUser)}
                      </span>
                      <span className="line-3">
                        on{" "}
                        {formatDate(
                          applicationUpdated as Date,
                          APP_DATE_FORMAT
                        )}
                      </span>
                    </div>
                  </>
                )}
              </div>
              {projectData.hasCompliance && (
                <div className="card">
                  <div className="title">{t("compliance")}</div>
                  <div
                    className={`status ${referralStateClasses[complianceStatus]}`}
                  >
                    {referralComplianceStateText[complianceStatus]}
                  </div>
                  {complianceUser && (
                    <>
                      <div className="picture">
                        <CIQAvatar
                          username={UtilsService.displayUserName(
                            complianceUser
                          )}
                          profileImageUrl={complianceUser?.profileImageUrl}
                        />
                      </div>
                      <div className="timestamp">
                        <span className="line-1">
                          {referralStatClientDescText[complianceStatus]}
                        </span>
                        <span className="line-2">
                          {UtilsService.displayUserName(complianceUser)}
                        </span>
                        <span className="line-3">
                          on{" "}
                          {formatDate(
                            complianceUpdated as Date,
                            APP_DATE_FORMAT
                          )}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </Collapse>
        </div>
      </Col>
    </Row>
  );
};

export default ExpertReferralState;
