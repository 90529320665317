import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ExpertService,
  ProjectService,
  ToasterService
} from "@arbolus-technologies/api";
import {
  MixPanelEventNames,
  useArbolusTracking,
  useModalService
} from "@arbolus-technologies/features/common";
import {
  EXPERT_TYPE,
  ExternalExpertFormValues,
  PipelineExpert,
  PipelineExpertUpdateModel
} from "@arbolus-technologies/models/expert";
import { PROJECT_SHORTLIST_ROUTE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { PanelId, PanelSelector } from "@arbolus-technologies/stores/panels";
import {
  DncInfoBox,
  ExpertHeader,
  LoaderOrComponent,
  Tab
} from "@arbolus-technologies/ui/components";
import { ExternalExpertForm } from "@arbolus-technologies/ui/pipeline";

import { ExternalExpertHistories } from "../../ExternalExpertHistories/ExternalExpertHistories";

import styles from "./ExternalExpertDetails.module.scss";

export enum PIPELINE_EXPERT_FORM_TABS {
  DETAILS = "details",
  COMMS_HISTORY = "commsHistory"
}

interface ExternalExpertDetailsProps {
  handleUpdated: (isUpdated: boolean) => void;
  onSlidePanelClose: (isShortlisted: boolean) => void;
  expertService?: typeof ExpertService;
  projectService?: typeof ProjectService;
  notificationService?: ToasterService;
}

export const ExternalExpertDetails: React.FC<ExternalExpertDetailsProps> = ({
  handleUpdated,
  onSlidePanelClose,
  expertService = ExpertService,
  projectService = ProjectService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("externalExpertForm");
  const history = useHistory();
  const { trackClick, trackUpdated } = useArbolusTracking();
  const { projectId } = useParams<{ projectId: string }>();
  const panelData = useSelector(
    PanelSelector.panelData<{ projectPipelineId: string }>(
      PanelId.ExternalExpert
    )
  );
  const modalService = useModalService();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [externalExpert, setExternalExpert] = useState<PipelineExpert | null>(
    null
  );
  const [activeTab, setActiveTab] = useState<PIPELINE_EXPERT_FORM_TABS>(
    PIPELINE_EXPERT_FORM_TABS.DETAILS
  );

  const metadataCountries = useSelector(CacheSelector.metadataCountries());
  const currencies = useSelector(CacheSelector.metadataCurrencies());

  const expertCountry = metadataCountries.find(
    (metadataCountry) =>
      metadataCountry.threeLetterCode === externalExpert?.isoCountryCode
  );

  const getProjectPipelineExpert = () => {
    setIsLoading(true);
    if (panelData?.projectPipelineId) {
      projectService
        .getProjectPipelineExpert(projectId, panelData.projectPipelineId)
        .subscribe(
          (response) => {
            setExternalExpert(response);
            setIsLoading(false);
          },
          (error: ErrorResponse<CIQError>) => {
            notificationService.showError(error.message);
            setIsLoading(false);
          }
        );
    }
  };

  useEffect(() => {
    getProjectPipelineExpert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, panelData?.projectPipelineId]);

  const handleUpdate = (data: ExternalExpertFormValues) => {
    trackClick(MixPanelEventNames.ExternalSourcingSideUpdate);

    let rateCard = null;
    if (
      data.rateCard.experienceLevel ||
      data.rateCard.isoCurrencyCode ||
      data.rateCard.price
    ) {
      rateCard = {
        id: data.rateCard.id ?? "",
        experienceLevel: data.rateCard.experienceLevel,
        isoCurrencyCode: data.rateCard.isoCurrencyCode,
        price: data.rateCard.price
      };
    }

    const payload: PipelineExpertUpdateModel = {
      projectPipelineId: externalExpert?.projectPipelineId!,
      projectPipelineStatus: data.projectPipelineStatus,
      projectPipelineStatusComment: data.projectPipelineStatusComment,
      projectPipelineEmails: data.projectPipelineEmails.map(
        (email) => email.value
      ),
      projectPipelinePhones: data.projectPipelinePhones.map(
        (phone) => phone.value
      ),
      rateCard
    };

    setIsUpdating(true);
    projectService.updateProjectPipelineExpert(payload).subscribe(
      () => {
        if (
          externalExpert?.projectPipelineStatus !== data.projectPipelineStatus
        ) {
          trackUpdated(MixPanelEventNames.ExternalSourcingSideCommsStatus);
        }
        notificationService.showSuccess(t("expertUpdated"));
        handleUpdated(true);
        setIsUpdating(false);
        getProjectPipelineExpert();
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showError(error.message);
        setIsUpdating(false);
      }
    );
  };

  const createProjectPipelineReferral = () => {
    trackClick(MixPanelEventNames.ExternalSourcingSideMoveToShortlist);

    setIsUpdating(true);
    projectService
      .createProjectPipelineReferral(externalExpert?.projectPipelineId!)
      .subscribe(
        () => {
          notificationService.showAction(t("referralCreated"), {
            label: t("referralCreatedAction"),
            onClick: () => history.push(PROJECT_SHORTLIST_ROUTE(projectId))
          });
          setIsUpdating(false);
          onSlidePanelClose(true);
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showError(error.message);
          setIsUpdating(false);
        }
      );
  };

  const updateInternalExpertInfo = (internalExpertId: string) => {
    projectService
      .changeExpertToInternal(
        externalExpert?.projectPipelineId!,
        internalExpertId
      )
      .subscribe(
        () => {
          createProjectPipelineReferral();
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showError(error.message);
        }
      );
  };

  const moveToShortlist = () => {
    expertService
      .checkInternalByEmail(
        externalExpert?.projectPipelineEmails[0]!,
        externalExpert?.projectPipelinePhones?.[0]
      )
      .subscribe(({ internalExpert }) => {
        if (internalExpert) {
          modalService.openMainModal({
            title: t("alreadyInternal"),
            subtitle: t("alreadyInternalSubtitle"),
            confirmText: t("moveToShortlist"),
            cancelText: t("cancel"),
            onConfirm: () =>
              externalExpert?.platformStatus === EXPERT_TYPE.INTERNAL
                ? createProjectPipelineReferral()
                : updateInternalExpertInfo(internalExpert.id)
          });
        } else {
          modalService.openMainModal({
            title: t("moveToShortlist"),
            subtitle: t("moveToShortlistSubtitle"),
            type: "confirmation",
            confirmText: t("confirm"),
            cancelText: t("cancel"),
            onConfirm: createProjectPipelineReferral
          });
        }
      });
  };

  const renderPipelineExpertTabs = {
    [PIPELINE_EXPERT_FORM_TABS.DETAILS]: () => (
      <ExternalExpertForm
        expert={externalExpert!}
        currencies={currencies}
        isUpdating={isUpdating}
        onUpdate={handleUpdate}
        moveToShortlist={moveToShortlist}
      />
    ),
    [PIPELINE_EXPERT_FORM_TABS.COMMS_HISTORY]: () => (
      <ExternalExpertHistories
        projectPipelineId={externalExpert!.projectPipelineId}
      />
    )
  };

  return (
    <LoaderOrComponent isLoading={isLoading}>
      {externalExpert && (
        <div className={styles.container}>
          <ExpertHeader
            expertUser={{
              id: externalExpert.id,
              firstName: externalExpert.firstName,
              lastName: externalExpert.lastName,
              profileImageUrl: externalExpert.profileImageUrl ?? "",
              title: externalExpert.title
            }}
            lastPublicCompanyDate={externalExpert.lastPublicCompanyExpDate}
            countryTwoLetterCode={expertCountry?.twoLetterCode ?? null}
            countryName={expertCountry?.name ?? ""}
            linkedInProfile={externalExpert.linkedInProfile}
          />
          <DncInfoBox
            doNotContactStatus={externalExpert.doNotContactStatus}
            doNotContactStatusDescription={
              externalExpert.doNotContactStatusDescription
            }
            doNotContactStatusCategory={
              externalExpert.doNotContactStatusCategory
            }
            isAdmin
          />
          <div className={styles.tabs}>
            {Object.values(PIPELINE_EXPERT_FORM_TABS).map((tab) => (
              <Tab
                key={tab}
                onClick={() => setActiveTab(tab)}
                text={t(tab)}
                isActive={activeTab === tab}
                disabled={isUpdating}
              />
            ))}
          </div>
          {renderPipelineExpertTabs[activeTab]()}
        </div>
      )}
    </LoaderOrComponent>
  );
};
