import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React, { ReactNode } from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./IconWithText.module.scss";

interface IconWithTextProps {
  text?: { content: string; color?: string };
  img?: string;
  children?: ReactNode;
  isLink?: {
    textLink: string;
    urlLink: string;
  };
  icon?: {
    color?: string;
    size?: string;
    name: string;
    filled?: boolean;
    customStyle?: React.CSSProperties;
    customClasses?: string;
  };
  iconOnEnd?: boolean;
  isClickable?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
  customStyle?: React.CSSProperties;
  customClasses?: string;
}

export const IconWithText: React.FC<IconWithTextProps> = ({
  text,
  img,
  children,
  isLink,
  icon,
  iconOnEnd,
  isClickable,
  isDisabled,
  onClick,
  customStyle,
  customClasses
}) => (
  <div
    className={clsx(
      styles.container,
      {
        [styles.iconOnEnd]: iconOnEnd,
        [styles.isClickable]: isClickable,
        [styles.isDisabled]: isDisabled
      },
      customClasses
    )}
    onClick={onClick}
    style={customStyle}
  >
    {icon && (
      <div
        style={icon.customStyle}
        className={clsx(icon.customClasses, styles.icon)}
      >
        <Icon
          color={icon.color || ARBOLUS_COLORS.bColorBasePurple}
          fontSize={icon.size || "16px"}
          name={icon.name}
          filled={icon.filled}
        />
      </div>
    )}
    {img && <img src={img} alt="img" className={styles.img} />}
    {children}
    {isLink && (
      <a
        className={styles.link}
        href={isLink.urlLink}
        target="_blank"
        rel="noreferrer"
      >
        {isLink.textLink}
      </a>
    )}
    {text && (
      <h1
        className={styles.text}
        title={text?.content}
        style={{ color: text?.color ?? ARBOLUS_COLORS.bColorGrayIcon }}
      >
        {text?.content}
      </h1>
    )}
  </div>
);
