import i18next from "i18next";
import { Epic } from "redux-observable";
import { EMPTY, of, zip } from "rxjs";
import {
  catchError,
  filter,
  flatMap,
  ignoreElements,
  map,
  mergeMap,
  switchMap,
  tap
} from "rxjs/operators";
import { isOfType } from "typesafe-actions";

import {
  CIQError,
  CanopyService,
  ErrorResponse
} from "@arbolus-technologies/api";

import {
  COPY_CANOPY_INVITATION_LINK_TO_CLIPBOARD,
  EDIT_VIDEO_TRANSCRIPT,
  EditVideoTranscriptAction,
  GET_ANSWERS_BY_QUESTION,
  GET_CANOPY_ANSWERS_BY_EXPERT,
  GET_CANOPY_CLIENT_EXPERTS,
  GET_IS_EXPERT_IN_PROJECT,
  GET_VIDEO_TRANSCRIPT,
  GetApiAnswersByExpertsAction,
  GetCanopyAnswersByExpertAction,
  GetVideoTranscriptAction,
  RESET_VIDEO_TRANSCRIPT,
  ResetVideoTranscriptAction,
  SET_CANOPY_ANSWER_SELECTED_BY_EXPERTS,
  SET_COMPLIANCE_REVIEW_STATUS,
  SHOW_RESPONSES,
  SetCanopyAnswerSelectedByExpertsAction,
  SetComplianceReviewStatusAction
} from "../actions/CanopyClientsActionTypes";
import { CanopyClientStoreActions } from "../actions/CanopyClientsActions";
import {
  CanopyClientAppState,
  CanopyClientStoreAction,
  CanopyClientStoreDependencies
} from "../models/definitions";

const getAnswersByQuestion: Epic<
  CanopyClientStoreAction,
  CanopyClientStoreAction,
  CanopyClientAppState,
  CanopyClientStoreDependencies
> = (action$, _, { canopyService, notificationService }) =>
  action$.pipe(
    filter(isOfType(GET_ANSWERS_BY_QUESTION)),
    switchMap(
      ({ payload: { questionId, offset } }: GetApiAnswersByExpertsAction) =>
        canopyService.getAnswersFromQuestion(questionId, offset, 2).pipe(
          flatMap((canopyAnswerData) =>
            of(
              CanopyClientStoreActions.getAnswersByQuestionSuccess(
                questionId,
                canopyAnswerData
              )
            )
          ),
          catchError((error: ErrorResponse<CIQError>) => {
            notificationService.showApiErrors(error);
            return of(
              CanopyClientStoreActions.getAnswersByQuestionFailure(error)
            );
          })
        )
    )
  );

const getCanopyClientExperts: Epic<
  CanopyClientStoreAction,
  CanopyClientStoreAction,
  CanopyClientAppState,
  CanopyClientStoreDependencies
> = (action$, _, { canopyService, notificationService }) =>
  action$.pipe(
    filter(isOfType(GET_CANOPY_CLIENT_EXPERTS)),
    switchMap(({ payload: { canopyId, onlyComplete } }) =>
      canopyService.getExpertsInACanopy(canopyId, onlyComplete).pipe(
        map((experts) =>
          CanopyClientStoreActions.getCanopyClientExpertsSuccess(experts.items)
        ),
        catchError((error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
          return of(
            CanopyClientStoreActions.getCanopyClientExpertsFailure(error)
          );
        })
      )
    )
  );

const getVideoTranscript: Epic<
  CanopyClientStoreAction,
  CanopyClientStoreAction,
  CanopyClientAppState,
  CanopyClientStoreDependencies
> = (action$, _, { canopyService, notificationService }) =>
  action$.pipe(
    filter(isOfType(GET_VIDEO_TRANSCRIPT)),
    switchMap(
      ({
        payload: { answerId, transcriptWarning }
      }: GetVideoTranscriptAction) =>
        canopyService.getVideoTranscript(answerId).pipe(
          flatMap((videoTranscript) =>
            of(
              CanopyClientStoreActions.getVideoTranscriptSuccess(
                videoTranscript.content,
                answerId,
                videoTranscript.isSmartTranscript
              ),
              CanopyClientStoreActions.changeClientAnswerState("transcript")
            )
          ),
          catchError((error: ErrorResponse<CIQError>) => {
            notificationService.showWarning(transcriptWarning);
            return of(
              CanopyClientStoreActions.getVideoTranscriptFailure(error)
            );
          })
        )
    )
  );

const editVideoTranscript: Epic<
  CanopyClientStoreAction,
  CanopyClientStoreAction,
  CanopyClientAppState,
  CanopyClientStoreDependencies
> = (action$, _, { canopyService, notificationService }) =>
  action$.pipe(
    filter(isOfType(EDIT_VIDEO_TRANSCRIPT)),
    mergeMap(({ payload: { answerId, content } }: EditVideoTranscriptAction) =>
      canopyService.editVideoTranscript(answerId, content).pipe(
        flatMap(() =>
          of(
            CanopyClientStoreActions.editVideoTranscriptSuccess(
              content,
              answerId,
              true
            )
          )
        ),
        catchError((error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
          return of(CanopyClientStoreActions.editVideoTranscriptFailure(error));
        })
      )
    )
  );

const resetVideoTranscript: Epic<
  CanopyClientStoreAction,
  CanopyClientStoreAction,
  CanopyClientAppState,
  CanopyClientStoreDependencies
> = (action$, _, { canopyService, notificationService }) =>
  action$.pipe(
    filter(isOfType(RESET_VIDEO_TRANSCRIPT)),
    mergeMap(({ payload: { answerId } }: ResetVideoTranscriptAction) =>
      canopyService.resetVideoTranscript(answerId).pipe(
        flatMap(() =>
          of(CanopyClientStoreActions.getVideoTranscript(answerId, ""))
        ),
        catchError((error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
          return of(
            CanopyClientStoreActions.resetVideoTranscriptFailure(error)
          );
        })
      )
    )
  );

const getAllAnswersByExpert: Epic<
  CanopyClientStoreAction,
  CanopyClientStoreAction,
  CanopyClientAppState,
  CanopyClientStoreDependencies
> = (action$, _, { canopyService, notificationService }) =>
  action$.pipe(
    filter(isOfType(GET_CANOPY_ANSWERS_BY_EXPERT)),
    switchMap(
      ({
        payload: { canopyId, expertId, offset, onlyComplete }
      }: GetCanopyAnswersByExpertAction) =>
        zip(
          canopyService.getAllExpertAnswersFromApi(
            canopyId,
            expertId,
            offset,
            2
          ),
          canopyService.getExpertsInACanopy(canopyId, onlyComplete)
        ).pipe(
          flatMap(([canopyAnswerData, expertInACanopy]) =>
            of(
              CanopyClientStoreActions.getAllAnswersByExpertSuccess(
                canopyAnswerData,
                canopyId,
                expertId
              ),
              CanopyClientStoreActions.setSelectedExpertSuccess(
                expertInACanopy.items,
                expertId
              )
            )
          ),
          catchError((error: ErrorResponse<CIQError>) => {
            notificationService.showApiErrors(error);
            return of(
              CanopyClientStoreActions.getAllAnswersByExpertFailure(error)
            );
          })
        )
    )
  );

const setCanopyAnswerSelectedByExperts: Epic<
  CanopyClientStoreAction,
  CanopyClientStoreAction,
  CanopyClientAppState,
  CanopyClientStoreDependencies
> = (action$, _, { canopyService, notificationService }) =>
  action$.pipe(
    filter(isOfType(SET_CANOPY_ANSWER_SELECTED_BY_EXPERTS)),
    switchMap(
      ({
        payload: { questionId, answerId }
      }: SetCanopyAnswerSelectedByExpertsAction) =>
        canopyService.getAnswersFromQuestion(questionId, 0, 100).pipe(
          flatMap((canopyAnswerData) =>
            of(
              CanopyClientStoreActions.setCanopyAnswerSelectedByExpertsSuccess(
                answerId,
                canopyAnswerData
              )
            )
          ),
          catchError((error: ErrorResponse<CIQError>) => {
            notificationService.showApiErrors(error);
            return of(
              CanopyClientStoreActions.setCanopyAnswerSelectedByExpertsFailure(
                error
              )
            );
          })
        )
    )
  );

const copyCanopyInvitationLinkToClipboardEpic: Epic<
  CanopyClientStoreAction,
  CanopyClientStoreAction,
  CanopyClientAppState,
  CanopyClientStoreDependencies
> = (action$, _, { notificationService, routing }) =>
  action$.pipe(
    filter(isOfType(COPY_CANOPY_INVITATION_LINK_TO_CLIPBOARD)),
    tap(({ payload: { canopyId, successMessage } }) => {
      navigator.clipboard.writeText(routing.invitationLink(canopyId));
      notificationService.showSuccess(successMessage);
    }),
    ignoreElements()
  );

const showResponsesEpic: Epic<
  CanopyClientStoreAction,
  CanopyClientStoreAction,
  CanopyClientAppState
> = (action$, _, { routing, history }) =>
  action$.pipe(
    filter(isOfType(SHOW_RESPONSES)),
    switchMap(({ payload: { canopyId, showOnlyComplete } }) =>
      CanopyService.getCanopyQuestions(canopyId).pipe(
        mergeMap((questions) => {
          const question = questions.items[0];
          history.push(routing.canopyQuestionRoute(canopyId, question.id));
          return of(
            CanopyClientStoreActions.getAnswersByQuestion(question.id, 0),
            CanopyClientStoreActions.changeClientAnswerState("expertAnswers")
          );
        })
      )
    )
  );

const getIsExpertInProject: Epic<
  CanopyClientStoreAction,
  CanopyClientStoreAction,
  CanopyClientAppState,
  CanopyClientStoreDependencies
> = (action$, _, { canopyService, notificationService }) =>
  action$.pipe(
    filter(isOfType(GET_IS_EXPERT_IN_PROJECT)),
    switchMap(({ payload: { projectId, expertId } }) =>
      canopyService.getIsExpertInAProject(projectId, expertId).pipe(
        map((result) =>
          CanopyClientStoreActions.getIsExpertInProjectSuccess(
            result.hasExpertClientVisibleReferral
          )
        ),
        catchError((error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
          return of(
            CanopyClientStoreActions.getIsExpertInProjectFailure(error)
          );
        })
      )
    )
  );

const setComplianceReviewStatus: Epic<
  CanopyClientStoreAction,
  CanopyClientStoreAction,
  CanopyClientAppState,
  CanopyClientStoreDependencies
> = (action$, _, { canopyService, notificationService }) =>
  action$.pipe(
    filter(isOfType(SET_COMPLIANCE_REVIEW_STATUS)),
    mergeMap(
      ({
        payload: { canopyId, expertId, hasPassedCompliance }
      }: SetComplianceReviewStatusAction) =>
        canopyService
          .setComplianceReviewStatus(
            canopyId,
            expertId,
            hasPassedCompliance,
            []
          )
          .pipe(
            flatMap(() => {
              notificationService.showSuccess(
                i18next.t("canopy:reviewSubmittedSuccessfully")
              );
              return of(
                CanopyClientStoreActions.ResetCanopyAnswersByExpert(),
                CanopyClientStoreActions.getAllAnswersByExpert(
                  canopyId,
                  expertId,
                  0,
                  false
                ),
                CanopyClientStoreActions.getCanopyClientExperts(canopyId, false)
              );
            }),
            catchError((error: ErrorResponse<CIQError>) => {
              notificationService.showApiErrors(error);
              return EMPTY;
            })
          )
    )
  );

export const CanopyClientEpics = [
  getAnswersByQuestion,
  getVideoTranscript,
  editVideoTranscript,
  resetVideoTranscript,
  getCanopyClientExperts,
  getAllAnswersByExpert,
  copyCanopyInvitationLinkToClipboardEpic,
  showResponsesEpic,
  setCanopyAnswerSelectedByExperts,
  getIsExpertInProject,
  setComplianceReviewStatus
];
