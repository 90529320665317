import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { formatUnit } from "@arbolus-technologies/utils";

interface HRInterface {
  color?: string;
  margin?: {
    // Multipliers of base value from design system
    top?: number;
    bottom?: number;
  };
  noMargin?: boolean;
}

export const HR: React.FC<HRInterface> = ({ color, margin, noMargin }) => {
  const getMargin = (side: "top" | "bottom"): number | string => {
    if (noMargin) {
      return 0;
    } else if (typeof margin?.[side] === "undefined") {
      return "default";
    }
    return formatUnit(margin[side]!);
  };
  return (
    <hr
      style={{
        width: "100%",
        borderTop: `1px solid ${
          color ?? ARBOLUS_COLORS.bColorSecondaryGreyMid
        }`,
        marginTop: getMargin("top"),
        marginBottom: getMargin("bottom")
      }}
    />
  );
};
