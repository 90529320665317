import React from "react";
import { Control, Controller, FieldError } from "react-hook-form";

import { DatePicker } from "../DatePicker/DatePicker";

import styles from "./DatePickerController.module.scss";

interface DatePickerControllerProps {
  control: Control<any>;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  maxDate?: Date;
  minDate?: Date;
  error?: FieldError;
  customDateSelected?: (date: Date) => void;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  timeIntervals?: number;
  timeCaption?: string;
  dateFormat?: string;
  showMonthPicker?: boolean;
  isClearable?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
}

export const DatePickerController: React.FC<DatePickerControllerProps> = ({
  control,
  name,
  placeholder,
  disabled = false,
  maxDate,
  minDate,
  error,
  showTimeSelect,
  showTimeSelectOnly,
  timeIntervals,
  timeCaption,
  dateFormat,
  showMonthPicker = false,
  isClearable = false,
  customDateSelected,
  onKeyDown
}) => (
  <>
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => (
        <div className={error && styles.error}>
          <DatePicker
            isClearable={isClearable}
            disabled={disabled}
            maxDate={maxDate}
            minDate={minDate}
            name={name}
            popperPlacement="top-start"
            onBlur={onBlur}
            selectedDate={value}
            onDateChanged={(date: Date): void => {
              customDateSelected && customDateSelected(date);
              onChange(date);
            }}
            placeholderText={placeholder}
            showTimeSelectOnly={showTimeSelectOnly}
            showTimeSelect={showTimeSelect}
            timeIntervals={timeIntervals}
            showMonthPicker={showMonthPicker}
            timeCaption={timeCaption}
            dateFormat={dateFormat}
            onKeyDown={onKeyDown}
          />
        </div>
      )}
    />
    {error && (
      <div className={styles.errorMessage} data-testid="error-yup">
        {error.message}
      </div>
    )}
  </>
);
