import i18next from "i18next";
import * as Yup from "yup";

import { UserConstraints } from "../../../../../constants/validation";

export const NewEndorsementSchema = Yup.object().shape(
  {
    name1: Yup.string()
      .when(["email1"], {
        is: (val) => val !== "",
        then: Yup.string().required(i18next.t("newUserPage:nameRequiredError"))
      })
      .max(
        UserConstraints.MAX_FIRST_NAME_LENGTH,
        i18next.t("newUserPage:firstNameMaxLengthError", {
          length: UserConstraints.MAX_FIRST_NAME_LENGTH
        })
      ),
    email1: Yup.string()
      .when(["name1"], {
        is: (val) => val !== "",
        then: Yup.string().required(i18next.t("newUserPage:emailRequireError"))
      })
      .email(i18next.t("newUserPage:emailNotValidError"))
      .max(
        UserConstraints.MAX_EMAIL_LENGTH,
        i18next.t("newUserPage:maxEmailLength", {
          length: UserConstraints.MAX_EMAIL_LENGTH
        })
      ),
    name2: Yup.string()
      .when(["email2"], {
        is: (val) => val !== "",
        then: Yup.string().required(i18next.t("newUserPage:nameRequiredError"))
      })
      .max(
        UserConstraints.MAX_FIRST_NAME_LENGTH,
        i18next.t("newUserPage:firstNameMaxLengthError", {
          length: UserConstraints.MAX_FIRST_NAME_LENGTH
        })
      ),
    email2: Yup.string()
      .when(["name2"], {
        is: (val) => val !== "",
        then: Yup.string().required(i18next.t("newUserPage:emailRequireError"))
      })
      .email(i18next.t("newUserPage:emailNotValidError"))
      .max(
        UserConstraints.MAX_EMAIL_LENGTH,
        i18next.t("newUserPage:maxEmailLength", {
          length: UserConstraints.MAX_EMAIL_LENGTH
        })
      ),
    name3: Yup.string()
      .when(["email3"], {
        is: (val) => val !== "",
        then: Yup.string().required(i18next.t("newUserPage:nameRequiredError"))
      })
      .max(
        UserConstraints.MAX_FIRST_NAME_LENGTH,
        i18next.t("newUserPage:firstNameMaxLengthError", {
          length: UserConstraints.MAX_FIRST_NAME_LENGTH
        })
      ),

    email3: Yup.string()
      .when(["name3"], {
        is: (val) => val !== "",
        then: Yup.string().required(i18next.t("newUserPage:emailRequireError"))
      })
      .email(i18next.t("newUserPage:emailNotValidError"))
      .max(
        UserConstraints.MAX_EMAIL_LENGTH,
        i18next.t("newUserPage:maxEmailLength", {
          length: UserConstraints.MAX_EMAIL_LENGTH
        })
      )
  },
  [
    ["email1", "name1"],
    ["name1", "email1"],
    ["email2", "name2"],
    ["name2", "email2"],
    ["email3", "name3"],
    ["name3", "email3"]
  ]
);
