import { ColDef } from "ag-grid-community";
import React from "react";

export const useDefaultReferralGridColumns = (): ColDef =>
  React.useMemo<ColDef>(
    () => ({
      resizable: true,
      sortable: true,
      suppressMovable: true,
      menuTabs: ["filterMenuTab"]
    }),
    []
  );
