import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlaceholder from "react-placeholder/lib";
import { useDispatch, useSelector } from "react-redux";

import {
  CIQError,
  EARLIEST_AVAILABILITIES_ORDER_BY,
  EarliestAvailability,
  ErrorResponse,
  PaginatedRequest,
  ProjectService,
  SORT_DIRECTION
} from "@arbolus-technologies/api";
import { EarlyAvailabilityCard } from "@arbolus-technologies/features/common";
import {
  PanelId,
  PanelSelector,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { DashboardEmptyAvailability } from "@arbolus-technologies/ui/components";
import { GrayBox } from "@arbolus-technologies/ui/layout";
import { displayUserName } from "@arbolus-technologies/utils";

import { EarliestAvailabilitiesSidePanel } from "../../EarliestAvailabilitiesSidePanel/EarliestAvailabilitiesSidePanel";

interface EarliestAvailabilitiesProps {
  projectService?: typeof ProjectService;
}

const queryParams: PaginatedRequest<EARLIEST_AVAILABILITIES_ORDER_BY> = {
  limit: 3,
  offset: 0,
  orderBy: EARLIEST_AVAILABILITIES_ORDER_BY.EARLIEST_AVAILABILITIES,
  orderDirection: SORT_DIRECTION.ASCENDING
};

export const EarliestAvailabilities: React.FC<EarliestAvailabilitiesProps> = ({
  projectService = ProjectService
}) => {
  const { t } = useTranslation("earliestAvailabilities");
  const dispatch = useDispatch();
  const isExpertAvailabilityPanelOpen = useSelector(
    PanelSelector.isPanelOpen(PanelId.EarliestAvailabilities)
  );
  const [earliestAvailabilities, setEarliestAvailabilities] = useState<
    EarliestAvailability[]
  >([]);
  const [isAvailabilitiesLoading, setIsAvailabilitiesLoading] =
    useState<boolean>(false);

  const getEarliestAvailabilities = () => {
    setIsAvailabilitiesLoading(true);
    projectService.getEarliestAvailabilities(queryParams).subscribe(
      (response) => {
        setEarliestAvailabilities(response.items);
        setIsAvailabilitiesLoading(false);
      },
      (error: ErrorResponse<CIQError>) => {
        console.error(error);
        setIsAvailabilitiesLoading(false);
      }
    );
  };

  useEffect(() => {
    getEarliestAvailabilities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <EarliestAvailabilitiesSidePanel
        isPanelOpen={isExpertAvailabilityPanelOpen}
      />
      <GrayBox
        title={{
          text: t("earliestAvailabilities")
        }}
        actionButton={{
          title: t("seeAll"),
          onClick: () =>
            dispatch(
              PanelStoreActions.openPanel(PanelId.EarliestAvailabilities)
            ),
          isDisabled: isAvailabilitiesLoading,
          isHidden: earliestAvailabilities.length === 0
        }}
        flexDirection="column"
        gap={[2, 0]}
      >
        <ReactPlaceholder
          type="media"
          ready={!isAvailabilitiesLoading}
          showLoadingAnimation
          color="#e8e8eb"
        >
          {earliestAvailabilities.length === 0 && (
            <DashboardEmptyAvailability />
          )}
          {earliestAvailabilities.length > 0 &&
            earliestAvailabilities.map((earliestAvailability) => {
              const {
                expert,
                expertAvailabilitySlots,
                projectId,
                projectName,
                referralId,
                timezone
              } = earliestAvailability;
              if (expertAvailabilitySlots.length === 0) return null;

              const {
                expertId,
                tagline,
                profileImageUrl,
                lastPublicCompanyExpDate,
                doNotContactStatus
              } = expert;

              return (
                <EarlyAvailabilityCard
                  key={referralId}
                  earliestAvailability={{
                    startTime: expertAvailabilitySlots[0].startTime,
                    endTime: expertAvailabilitySlots[0].endTime
                  }}
                  project={{
                    title: projectName,
                    id: projectId
                  }}
                  expert={{
                    title: tagline,
                    imageUrl: profileImageUrl || "",
                    fullName: displayUserName(expert),
                    id: expertId,
                    lastPublicCompanyExpDate,
                    doNotContactStatus
                  }}
                  referralId={referralId}
                  remainingSlots={expertAvailabilitySlots.length - 1}
                  timezone={timezone}
                />
              );
            })}
        </ReactPlaceholder>
      </GrayBox>
    </>
  );
};
