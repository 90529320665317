import { Icon } from "arbolus-ui-components";
import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  ArbolusTooltip,
  ArbolusTooltipPlace
} from "@arbolus-technologies/ui/components";

import styles from "./FilterTitle.module.scss";

export const FilterTitle: React.FC<{
  title: string;
  tooltipText?: string;
  filterCounter: number;
}> = ({ title, tooltipText, filterCounter }) => (
  <div className={styles.header}>
    <div className={styles.titleWithTooltip}>
      <p className={styles.title}>
        <span>{title}</span>
        {filterCounter > 0 && <span>{`(${filterCounter})`}</span>}
      </p>
      {tooltipText && (
        <ArbolusTooltip content={tooltipText} place={ArbolusTooltipPlace.RIGHT}>
          <Icon
            fontSize="16px"
            name="help"
            color={ARBOLUS_COLORS.bColorGrayIcon}
          />
        </ArbolusTooltip>
      )}
    </div>
  </div>
);
