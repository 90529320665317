import React from "react";

import { CanopyAnswerSummaryItemShortText } from "@arbolus-technologies/models/canopy-panels";
import { HR } from "@arbolus-technologies/ui/components";

import { CanopySummaryQuote } from "../../CanopySummaryQuote/CanopySummaryQuote";

import styles from "./CanopySummaryComponentShortText.module.scss";

interface CanopySummaryComponentShortTextProps {
  summary: CanopyAnswerSummaryItemShortText;
  canopyId: string;
}

export const CanopySummaryComponentShortText: React.FC<
  CanopySummaryComponentShortTextProps
> = ({ summary, canopyId }) => {
  const { questionDetails, shortTextAnswers } = summary;

  return (
    <div className={styles.container}>
      <span className={styles.subtitle}>{questionDetails}</span>
      {shortTextAnswers?.map((shortTextAnswer, index) => (
        <React.Fragment key={shortTextAnswer.canopyAnswerId}>
          <CanopySummaryQuote
            quote={shortTextAnswer.shortText}
            expert={shortTextAnswer.expert}
            canopyId={canopyId}
            canopyAnswerId={shortTextAnswer.canopyAnswerId}
            hideLink
          />
          {shortTextAnswers.length !== index + 1 && <HR />}
        </React.Fragment>
      ))}
    </div>
  );
};
