import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import {
  Canopy,
  CanopyQuestion,
  CanopyService
} from "@arbolus-technologies/api";
import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import {
  CANOPY_STATUS,
  CanopyParamUrlTypes,
  CanopyQuestionFields
} from "@arbolus-technologies/models/canopy";
import {
  Button,
  DragAndDropWrapper
} from "@arbolus-technologies/ui/components";

import { QuestionItem } from "../../Components/QuestionItem/QuestionItem";
import { CanopyContextProvider } from "../../helpers/CanopyContext";
import {
  CanopyCreateFormValues,
  CanopyEditFormValues
} from "../../helpers/CanopyInitialFormValues";
import {
  prepareCanopyRequest,
  prepareEditQuestion
} from "../../helpers/CanopyQuestionHelpers";

import styles from "./QuestionsList.module.scss";

interface QuestionsListProps {
  canopyDetails: Canopy;
  questionList: CanopyQuestion[];
  getQuestions: () => void;
  showUnsavedChanges: boolean;
  canopyService?: typeof CanopyService;
}

export const QuestionsList: React.FC<QuestionsListProps> = ({
  canopyDetails,
  questionList,
  getQuestions,
  showUnsavedChanges,
  canopyService = CanopyService
}) => {
  const { t } = useTranslation("canopyBuilder");
  const { trackClick } = useArbolusTracking();
  const { canopyId } = useParams<CanopyParamUrlTypes>();

  const [questions, setQuestions] = useState<CanopyQuestionFields[]>([]);
  const [hasNewQuestionAtTop, setHasNewQuestionAtTop] =
    useState<boolean>(false);
  const [hasNewQuestionAtBottom, setHasNewQuestionAtBottom] =
    useState<boolean>(false);

  useEffect(() => {
    setQuestions(
      questionList.map((question) =>
        CanopyEditFormValues(prepareEditQuestion(question))
      )
    );
  }, [questionList]);

  const handleQuestionOrderChange = (
    items: CanopyQuestionFields[],
    updatedOrder: number
  ) => {
    setQuestions(items.map((item, index) => ({ ...item, order: index })));
    canopyService
      .editCanopyQuestion(
        prepareCanopyRequest(
          { ...items[updatedOrder], order: updatedOrder },
          canopyId
        )
      )
      .subscribe();
  };

  const handleQuestionsUpdate = (isNewQuestion?: boolean) => {
    if (isNewQuestion) {
      setHasNewQuestionAtTop(false);
      setHasNewQuestionAtBottom(false);
    }
    getQuestions();
  };

  const renderQuestion = ({
    question,
    questionNumber,
    title,
    isNewQuestion = false
  }: {
    question: CanopyQuestionFields;
    questionNumber: number;
    title: string;
    isNewQuestion?: boolean;
  }) => (
    <QuestionItem
      question={question}
      questionNumber={questionNumber}
      title={title}
      onQuestionsUpdate={handleQuestionsUpdate}
      showUnsavedNewQuestion={isNewQuestion && showUnsavedChanges}
    />
  );

  const handleClickOnAdd = (isTopButton = true) => {
    if (isTopButton) {
      setHasNewQuestionAtTop(true);
      trackClick(MixPanelEventNames.CanopyBuilderQuestionsTopAdd);
    } else {
      setHasNewQuestionAtBottom(true);
      trackClick(MixPanelEventNames.CanopyBuilderQuestionsBottomAdd);
    }
  };

  const isCanopyActive = canopyDetails.status === CANOPY_STATUS.ACTIVE;
  const disabledAddQuestion =
    hasNewQuestionAtTop || hasNewQuestionAtBottom || isCanopyActive;

  return (
    <div className={styles.container}>
      <div className={styles.addButton}>
        <h2>{t("newQuestion")}</h2>
        <Button
          type="secondary"
          text={t("add")}
          endIcon="add"
          disabled={disabledAddQuestion}
          onClick={handleClickOnAdd}
        />
      </div>
      {hasNewQuestionAtTop &&
        renderQuestion({
          question: CanopyCreateFormValues(0),
          questionNumber: 1,
          title: t("newQuestion"),
          isNewQuestion: true
        })}

      <CanopyContextProvider
        value={{ isEditMode: true, isCanopyActive, showUnsavedChanges }}
      >
        <div className={styles.questions}>
          <DragAndDropWrapper
            items={questions}
            isDisabled={isCanopyActive}
            onItemsChange={handleQuestionOrderChange}
            renderItem={(
              questionDetails: CanopyQuestionFields,
              questionNumber: number
            ) =>
              renderQuestion({
                question: questionDetails,
                questionNumber: hasNewQuestionAtTop
                  ? questionNumber + 1
                  : questionNumber,
                title: questionDetails.question
              })
            }
          />
        </div>
      </CanopyContextProvider>

      {questions.length > 0 && (
        <>
          {hasNewQuestionAtBottom &&
            renderQuestion({
              question: CanopyCreateFormValues(questions.length),
              questionNumber: questions.length + 1,
              title: t("newQuestion"),
              isNewQuestion: true
            })}
          <div className={styles.addButton}>
            <h2>{t("newQuestion")}</h2>
            <Button
              type="secondary"
              text={t("add")}
              endIcon="add"
              disabled={disabledAddQuestion}
              onClick={() => handleClickOnAdd(false)}
            />
          </div>
        </>
      )}
    </div>
  );
};
