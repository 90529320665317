/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";

export interface ContentPanelContextProps {
  isPanelExpand: boolean;
  isPanelLocked: boolean;
  panelClass: string;
  content?: React.ReactElement;
  setContent: (
    content: React.ReactElement,
    isExpand?: boolean,
    panelClass?: string,
    closeCallback?: () => void
  ) => void;
  setPanelLock: (isLock: boolean) => void;
  expandPanel: () => void;
  closePanel: () => void;
}

const defaultContentPanelContext: ContentPanelContextProps = {
  isPanelExpand: false,
  isPanelLocked: false,
  panelClass: "",
  setPanelLock: (): void => {},
  setContent: (): void => {},
  expandPanel: (): void => {},
  closePanel: (): void => {}
};

export const ContentPanelContext =
  React.createContext<ContentPanelContextProps>(defaultContentPanelContext);

/**
 * @deprecated
 * use SlidePanel from @arbolus-technologies/libs/feature
 */

export const ContentPanelContextProvider = ContentPanelContext.Provider;
export const ContentPanelContextConsumer = ContentPanelContext.Consumer;
