import clsx from "clsx";
import React from "react";
import { Input, Label } from "reactstrap";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { DocumentIcon } from "../Documents/DocumentIcon";
import { IconWithBackground } from "../IconWithBackground/IconWithBackground";

import styles from "./Checkbox.module.scss";

interface CheckboxProps {
  text: string;
  isChecked: boolean;
  isDisabled?: boolean;
  onChange?: (item: unknown) => void;
  onBlur?: (item: unknown) => void;
  isInvalid?: boolean;
  icon?: string;
  documentIcon?: {
    fileExtension: string;
    iconSize?: string;
    fontSize?: string;
  };
  countryTwoLetterCode?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  text,
  isChecked,
  isDisabled = false,
  onChange,
  onBlur,
  isInvalid,
  icon,
  documentIcon,
  countryTwoLetterCode
}) => (
  <Label
    className={clsx(styles.label, {
      [styles.disabled]: isDisabled
    })}
  >
    {icon && (
      <IconWithBackground
        backgroundColor={ARBOLUS_COLORS.bColorGrayIcon}
        size="small"
        fontSize="16px"
        name={icon}
        color={ARBOLUS_COLORS.bColorBaseWhite}
      />
    )}
    {documentIcon && (
      <DocumentIcon
        fileExtension={documentIcon.fileExtension}
        customHeight={documentIcon.iconSize}
        customWidth={documentIcon.iconSize}
        fontSize={documentIcon.fontSize}
      />
    )}
    {countryTwoLetterCode && (
      <span
        className={`flag-icon flag-icon-${countryTwoLetterCode.toLowerCase()}`}
        id="countryFlag"
      />
    )}
    <p
      className={clsx(styles.labelText, {
        [styles.iconMargin]: icon || documentIcon
      })}
      title={text}
    >
      {text}
    </p>
    <Input
      type="checkbox"
      checked={isChecked}
      onChange={onChange ?? undefined}
      disabled={isDisabled}
      onBlur={onBlur ?? undefined}
      invalid={isInvalid}
    />
    <span className={styles.checkmark} />
  </Label>
);
