import { Epic } from "redux-observable";
import { Observable, of } from "rxjs";
import { catchError, filter, flatMap, switchMap } from "rxjs/operators";
import { isOfType } from "typesafe-actions";

import {
  BOOKMARK_COLLECTION_ORDER_BY,
  BookmarkService,
  BookmarksPaginatedRequest,
  CIQError,
  ErrorResponse
} from "@arbolus-technologies/api";

import { BookmarksStoreActions } from "../BookmarksActions";
import {
  GET_BOOKMARKS_LIST,
  GetBookmarksListFailureAction,
  GetBookmarksListSuccessAction
} from "../actions/GetBookmarksList/GetBookmarksListActionTypes";
import {
  BookmarksAppState,
  BookmarksDependencies,
  BookmarksStoreAction,
  INotificationService
} from "../models/definitions";

const getBookmarks = (
  bookmarkService: typeof BookmarkService,
  notificationService: INotificationService,
  queryParams: BookmarksPaginatedRequest<BOOKMARK_COLLECTION_ORDER_BY>,
  searchTerm?: string
): Observable<GetBookmarksListSuccessAction | GetBookmarksListFailureAction> =>
  bookmarkService.getBookmarksList({ ...queryParams, searchTerm }).pipe(
    flatMap((paginatedResponse) =>
      of(BookmarksStoreActions.getBookmarksListSuccess(paginatedResponse))
    ),
    catchError((error: ErrorResponse<CIQError>) => {
      notificationService.showApiErrors(error);
      return of(BookmarksStoreActions.getBookmarksListFailure(error));
    })
  );

export const getBookmarksEpic: Epic<
  BookmarksStoreAction,
  BookmarksStoreAction,
  BookmarksAppState,
  BookmarksDependencies
> = (action$, _, { notificationService, bookmarkService }) =>
  action$.pipe(
    filter(isOfType(GET_BOOKMARKS_LIST)),
    switchMap(({ payload: { queryParams, searchTerm } }) =>
      getBookmarks(
        bookmarkService,
        notificationService,
        queryParams,
        searchTerm
      )
    )
  );
