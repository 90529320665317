import { useSelector } from "react-redux";

import { BookmarksSelector } from "@arbolus-technologies/stores/bookmarks";
import { useDocumentTitle } from "@arbolus-technologies/utils";

export const useBookmarkTitle = (includeCollectionTitle = false): void => {
  const collection = useSelector(BookmarksSelector.collection());

  useDocumentTitle(
    "bookmarkTitles",
    includeCollectionTitle ? "bookmarksWithTitle" : "bookmarks",
    {
      title: collection?.name || ""
    }
  );
};
