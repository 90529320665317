import i18next from "i18next";

import {
  CIQError,
  CompleteValidatorErrors,
  ErrorResponse,
  ValidatorErrors
} from "./models/api";

type ApiErrorType = "default" | "validation" | "unknown";

interface UnknownError {
  [key: string]: unknown;
}

export class ApiError {
  private readonly validationErrors?: CompleteValidatorErrors;

  private readonly defaultError?: ErrorResponse<CIQError>;

  private readonly unknownError?: UnknownError;

  private readonly errorType: ApiErrorType;

  private readonly messageChooser: Record<ApiErrorType, () => string[]> = {
    default: () => this.getDefaultErrorMessages(),
    validation: () => this.getValidationErrorMessages(),
    unknown: () => this.getUnknownErrorMessages()
  };

  constructor(error: ErrorResponse<CIQError> | CompleteValidatorErrors) {
    if ((error as CompleteValidatorErrors).title) {
      this.errorType = "validation";
      this.validationErrors = error as CompleteValidatorErrors;
    } else if ((error as ErrorResponse<CIQError>).errors === undefined) {
      this.errorType = "unknown";
      this.unknownError = error as unknown as UnknownError;
    } else {
      this.errorType = "default";
      this.defaultError = error as ErrorResponse<CIQError>;
    }
  }

  get errorMessages(): string[] {
    return this.messageChooser[this.errorType]();
  }

  get isValidationError(): boolean {
    return this.errorType === "validation";
  }

  get isDefaultError(): boolean {
    return this.errorType === "default";
  }

  public getValidationErrors(): ValidatorErrors {
    if (!this.isValidationError || !this.validationErrors) {
      console.error(
        `Tried to get validation errors from a different type of error: ${this.errorType}`
      );
      return {};
    }

    return this.validationErrors.errors;
  }

  private getValidationErrorMessages(): string[] {
    if (!this.validationErrors) {
      console.error(
        `Tried to get validation errors but it's not a validation error`
      );
      return [];
    }

    return Object.values(this.validationErrors.errors);
  }

  private getDefaultErrorMessages(): string[] {
    if (!this.defaultError) {
      console.error(`Tried to get default errors but it's not a default error`);
      return [];
    }

    const errors = [];
    if (this.defaultError.message) {
      errors.push(this.defaultError.message);
    }

    if (this.defaultError.errors) {
      errors.push(...this.defaultError.errors.map((e) => e.message));
    }

    return errors;
  }

  private getUnknownErrorMessages(): string[] {
    if (!this.unknownError) {
      console.error(
        `Tried to get unknown errors but it's not an unknown error`
      );
      return [];
    }

    if (this.unknownError.message) {
      return [this.unknownError.message as string];
    }

    if (this.unknownError.messages) {
      return this.unknownError.messages as string[];
    }

    if (typeof this.unknownError === "string") {
      return [this.unknownError as string];
    }

    return [i18next.t("restService:somethingWrong")];
  }
}
