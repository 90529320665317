import clsx from "clsx";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  CanopyQuestionMultiChoice,
  CreateEditQuestionFields
} from "@arbolus-technologies/models/canopy";
import { InputController } from "@arbolus-technologies/ui/components";

import { useCanopyContext } from "../../../../../../helpers/useCanopyContext";

import styles from "./MultiChoiceTypes.module.scss";

export const MultiChoiceTypeRange: React.FC = () => {
  const { t } = useTranslation("canopyBuilder");
  const { isCanopyActive, showUnsavedChanges } = useCanopyContext();

  const {
    control,
    formState: { errors, dirtyFields }
  } = useFormContext<CanopyQuestionMultiChoice>();

  return (
    <div className={styles.rangeContainer}>
      <div className={styles.rangeInnerContainer}>
        <InputController
          name={
            CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_INITIAL_RANGE
          }
          error={
            errors[
              CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_INITIAL_RANGE
            ]
          }
          classnames={clsx(styles.input, {
            [styles.unsavedField]:
              showUnsavedChanges &&
              dirtyFields[
                CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_INITIAL_RANGE
              ]
          })}
          control={control}
          placeholder={t("fromNumberPlaceholder")}
          type="number"
          disabled={isCanopyActive}
          onlyInteger
        />
      </div>
      <span>{t("to")}</span>
      <div className={styles.rangeInnerContainer}>
        <InputController
          name={CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_FINAL_RANGE}
          error={
            errors[
              CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_FINAL_RANGE
            ]
          }
          classnames={clsx(styles.input, {
            [styles.unsavedField]:
              showUnsavedChanges &&
              dirtyFields[
                CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_FINAL_RANGE
              ]
          })}
          control={control}
          placeholder={t("toNumberPlaceholder")}
          type="number"
          disabled={isCanopyActive}
          onlyInteger
        />
      </div>
    </div>
  );
};
