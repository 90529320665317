import { Icon } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import {
  CIQError,
  CIQUser,
  ClientService,
  ErrorResponse,
  ToasterService
} from "@arbolus-technologies/api";
import {
  ClipboardManager,
  DefaultClipboardManager
} from "@arbolus-technologies/features/common";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { IThreeDotsItem, ThreeDots } from "@arbolus-technologies/ui/components";

import { CLIENT_EDIT_USER_ROUTE } from "../usersRoutes";

import styles from "./UserActions.module.scss";

export interface UserActionsProps {
  row: CIQUser;
  onReinvite?: (userId: string) => void;
  showAssignSlidePanel: (userId: string) => void;
  isClientTeamMember: boolean;
  clientService?: typeof ClientService;
  onlyAssignAction?: boolean;
  clipboardManager?: ClipboardManager;
}

const notification = new ToasterService();
const CIQ_CLIENT_ID = process.env.NX_PUBLIC_CIQ_CLIENT_ID as string;

export const UserActions: React.FC<UserActionsProps> = ({
  row,
  onReinvite,
  showAssignSlidePanel,
  isClientTeamMember,
  clientService = ClientService,
  onlyAssignAction = false,
  clipboardManager = DefaultClipboardManager
}) => {
  const { t } = useTranslation("usersList");
  const history = useHistory();

  const { emailConfirmed } = row;
  const clientId = isClientTeamMember
    ? window.location.pathname.split("/")[2]
    : CIQ_CLIENT_ID;

  const handleAssign = () => {
    showAssignSlidePanel(row.id);
  };

  const copyOnboardingLink = () => {
    clientService.getInviteLink(clientId, row.id).subscribe(
      ({ inviteLink }) => {
        clipboardManager.copy(inviteLink);
      },
      (err: ErrorResponse<CIQError>) => {
        notification.showError(err.message);
      }
    );
  };

  const threeDotsItems: IThreeDotsItem[] = [
    {
      color: ARBOLUS_COLORS.bColorGrayIcon,
      icon: "add",
      onClick: handleAssign,
      title: t(isClientTeamMember ? "assignProject" : "assignClient"),
      iconColor: ARBOLUS_COLORS.bColorGrayIcon
    }
  ];

  if (emailConfirmed && isClientTeamMember) {
    threeDotsItems.push({
      color: ARBOLUS_COLORS.bColorGrayIcon,
      icon: "edit",
      onClick: () => history.push(CLIENT_EDIT_USER_ROUTE(clientId, row.id)),
      title: t("editProfile"),
      iconColor: ARBOLUS_COLORS.bColorGrayIcon
    });
  } else if (!emailConfirmed) {
    if (onReinvite) {
      threeDotsItems.push({
        color: ARBOLUS_COLORS.bColorGrayIcon,
        icon: "send",
        onClick: () => onReinvite(row.id),
        title: t("resendInvite"),
        iconColor: ARBOLUS_COLORS.bColorGrayIcon
      });
    }

    threeDotsItems.push({
      color: ARBOLUS_COLORS.bColorGrayIcon,
      icon: "link",
      onClick: copyOnboardingLink,
      title: t("onboardingLink"),
      iconColor: ARBOLUS_COLORS.bColorGrayIcon
    });
  }

  const showOnlyAssign =
    onlyAssignAction || (emailConfirmed && !isClientTeamMember);

  return (
    <div className={styles.userActions}>
      {showOnlyAssign ? (
        <Icon
          fontSize="20px"
          name="add"
          onClick={handleAssign}
          color={ARBOLUS_COLORS.bColorGrayIcon}
        />
      ) : (
        <ThreeDots popoverId={row.id} items={threeDotsItems} />
      )}
    </div>
  );
};
