import clsx from "clsx";
import React from "react";
import Pagination, { PaginationState } from "react-paginating";

import styles from "./CIQPagination.module.scss";

interface CIQPaginationProps {
  currentPage: number;
  totalCount: number;
  limit: number;
  pageCount: number;
  boxShadow?: boolean;
  onSetPage: (pageIndex: number) => void;
}

export const CIQPagination: React.FC<
  CIQPaginationProps & React.HTMLProps<HTMLDivElement>
> = ({
  currentPage,
  totalCount,
  limit,
  pageCount,
  boxShadow = true,
  onSetPage,
  className
}): JSX.Element => (
  <Pagination
    total={totalCount}
    limit={limit}
    pageCount={pageCount}
    currentPage={currentPage + 1}
    className={clsx(styles.container, className, {
      [styles.boxShadow]: boxShadow
    })}
  >
    {({
      pages,
      hasNextPage,
      hasPreviousPage
    }: PaginationState): JSX.Element => (
      <>
        <div
          onClick={(): void => onSetPage(currentPage - 1)}
          className={clsx(styles.actionContainer, {
            [styles.disabled]: !hasPreviousPage
          })}
        >
          <span className="ciq-icon ciq-chevron-left" />
        </div>
        <div className={clsx(styles.pagesContainer)}>
          {pages.map((page) => (
            <div
              key={page}
              onClick={(): void => onSetPage(page - 1)}
              className={clsx(styles.pageItem, {
                [styles.active]: page === currentPage + 1
              })}
            >
              {page}
            </div>
          ))}
        </div>
        <div
          onClick={(): void => onSetPage(currentPage + 1)}
          className={clsx(styles.actionContainer, {
            [styles.disabled]: !hasNextPage
          })}
        >
          <span className="ciq-icon ciq-chevron-right" />
        </div>
      </>
    )}
  </Pagination>
);
