import React from "react";
import { TypeaheadModel, TypeaheadResult } from "react-bootstrap-typeahead";

import { AdminService, ApiPaginatedRequest } from "@arbolus-technologies/api";
import { Avatar } from "@arbolus-technologies/ui/components";
import { displayUserName } from "@arbolus-technologies/utils";

import { SelectTypeahead } from "./SelectTypeahead";

import styles from "./SelectUnControlled.module.scss";

interface FullDisplayUser {
  firstName: string;
  lastName: string;
  email: string;
  profileImageUrl: string;
}

type DisplayUser = Partial<FullDisplayUser>;

const renderer = ({
  firstName,
  lastName,
  email,
  profileImageUrl
}: TypeaheadResult<DisplayUser>): JSX.Element => (
  <div className={styles.itemContainer}>
    <Avatar
      avatar={{
        name: displayUserName({ firstName, lastName, email }),
        imageUrl: profileImageUrl
      }}
    />

    <div>
      <span className={styles.itemText}>
        {displayUserName({ firstName, lastName, email })}
      </span>
    </div>
  </div>
);

const getUserLabelKey = (user: DisplayUser): string =>
  displayUserName(user) ?? "";

interface SelectUnControlledProps<T extends TypeaheadModel & object> {
  name: string;
  value: T[];
  orderBy: string;
  clientId: string;
  promptText: string;
  searchText: string;
  paginationText: string;
  defaultValue?: T;
  placeholderText?: string;
  disabled?: boolean;
  displayResults?: number;
  onChange: (value: string) => void;
  onBlur: () => void;
  clientFilter?: (item: T, query: string) => boolean;
}

export const SelectUnControlled = <T extends TypeaheadModel & object>({
  name,
  value,
  orderBy,
  clientId,
  promptText,
  searchText,
  paginationText,
  onChange,
  onBlur,
  displayResults,
  disabled = false,
  placeholderText = ""
}: SelectUnControlledProps<T>): React.ReactElement => {
  const getItems = (pagination: ApiPaginatedRequest) =>
    AdminService.getClientManagers(clientId, {
      ...pagination
    });

  const handleOnChange = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (...event: any[]) => void,
    selected: T[]
  ) => {
    onChange(selected[0]);
  };

  return (
    <SelectTypeahead
      selected={value}
      name={name}
      onChange={(items) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        handleOnChange(onChange, items as any);
      }}
      onBlur={onBlur}
      orderBy={orderBy}
      promptText={promptText}
      searchText={searchText}
      paginationText={paginationText}
      placeholderText={placeholderText}
      displayResults={displayResults}
      disabled={disabled}
      getItems={getItems}
      renderer={renderer}
      getLabelKey={getUserLabelKey}
    />
  );
};
