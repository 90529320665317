/* eslint-disable @typescript-eslint/no-empty-function */
import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import { push } from "connected-react-router";
import { Formik, FormikProps } from "formik";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Media from "react-media";
import { connect } from "react-redux";
import { RouteComponentProps, StaticContext } from "react-router";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import { Dispatch } from "redux";
import { Subscription } from "rxjs";

import {
  FilterCriteria,
  SortCriteria,
  ToasterService
} from "@arbolus-technologies/api";
import {
  MixPanelActions,
  MixPanelEventNames,
  MixpanelPages,
  trackEvent,
  trackPageView
} from "@arbolus-technologies/features/common";
import { Loader } from "@arbolus-technologies/ui/components";

import { APIConstants } from "../../../../constants";
import { MAX_PAGE_SIZE } from "../../../../constants/api";
import { APP_USER_ROLES, LISTING_TYPES } from "../../../../constants/app";
import { NEW_PROJECT } from "../../../../constants/navigation/authRoutes";
import {
  PROJECT_FILTER_CRITERIA,
  PROJECT_SORT_CRITERIA
} from "../../../../constants/project";
import { SEARCH_DEBOUNCE_TIMEOUT } from "../../../../constants/timer";
import {
  APP_DEVICE_MEDIA_QUERIES,
  UI_HOME_PAGE
} from "../../../../constants/ui";
import { MINIMUM_SEARCH_TERM_LENGTH } from "../../../../constants/validation";
import AccessManager from "../../../../contexts/roleBasedAccess/AccessManager";
import {
  ApiPaginatedResponse,
  CIQError,
  ErrorResponse
} from "../../../../models/api";
import { SavedClientBase } from "../../../../models/client";
import {
  BaseProject,
  ProjectsPaginatedRequest
} from "../../../../models/project";
import { ProjectService, RBServiceManager } from "../../../../services";
import { AppAction } from "../../../../store/actions";
import {
  CIQEmptyPlaceholder,
  CIQInfiniteScroll
} from "../../../app/components";
import { NavBarStoreActions } from "../../../app/store";
import ProjectListItem from "../../components/projectItem/ProjectClientListItem";
import ProjectExpertListItem from "../../components/projectItem/ProjectExpertListItem";

const notification = new ToasterService();

interface ProjectsPageProps
  extends RouteComponentProps<
    {},
    StaticContext,
    { clientFromNetwork?: SavedClientBase }
  > {
  navigateToProject: (tabRoute: string) => void;
  getProjectListForNavbar: () => void;
}

interface ProjectsPageState {
  projects: BaseProject[];
  isLoading: boolean;
  isMore: boolean;
  currentPage: number;
  isProjectTypeFilterOpen: boolean;
  isSortFilterOpen: boolean;
  deleteProjectId: string;
  clientFromNetwork: SavedClientBase | null;
}

type ProjectsPageIntersectProps = ProjectsPageProps & WithTranslation;

interface ProjectFilterFormValues {
  projectState: FilterCriteria;
  sortCriteria: SortCriteria;
  searchQuery: string;
}

class ProjectsPage extends React.Component<
  ProjectsPageIntersectProps,
  ProjectsPageState
> {
  constructor(props: ProjectsPageIntersectProps) {
    super(props);

    const { location } = props;
    this.state = {
      projects: [],
      currentPage: 0,
      isLoading: true,
      isMore: false,
      isProjectTypeFilterOpen: false,
      isSortFilterOpen: false,
      deleteProjectId: "",
      clientFromNetwork: location.state?.clientFromNetwork || null
    };
  }

  componentDidMount(): void {
    const { t } = this.props;
    document.title = t("home");

    trackPageView({ page: MixpanelPages.Projects });
  }

  componentWillUnmount(): void {
    if (this.searchQueryDebounceTimeout) {
      clearTimeout(this.searchQueryDebounceTimeout);
    }

    this.projectFetchSubscription?.unsubscribe();
    this.projectDeleteSubscription?.unsubscribe();
  }

  private formikRef: FormikProps<ProjectFilterFormValues> | null = null;

  private searchQueryDebounceTimeout: number | undefined;

  private projectFetchSubscription?: Subscription;

  private projectDeleteSubscription?: Subscription;

  private initialElementCount: number = MAX_PAGE_SIZE;

  fetchProjects = (): void => {
    const { projects, currentPage, clientFromNetwork } = this.state;

    const nextOffset = projects.length;

    if (this.formikRef) {
      const { values } = this.formikRef;
      const { searchQuery, projectState, sortCriteria } = values;
      this.setState({ isLoading: true });

      const apiParams: ProjectsPaginatedRequest = {
        offset: nextOffset,
        searchTerm: searchQuery,
        projectState: projectState.value ?? "",
        orderBy: sortCriteria.value,
        orderDirection: sortCriteria.direction,
        limit:
          currentPage === 0
            ? this.initialElementCount
            : APIConstants.MAX_PAGE_SIZE
      };

      if (clientFromNetwork) {
        apiParams.clientId = clientFromNetwork.id;
      }

      this.projectFetchSubscription = RBServiceManager.serviceCaller<
        ApiPaginatedResponse<BaseProject>,
        ApiPaginatedResponse<BaseProject>
      >(
        ProjectService.getClientProjects(apiParams),
        ProjectService.getExpertProjects(apiParams)
      ).subscribe(
        ({ items, pagination }) => {
          const nextProjects = projects.concat(items);
          const nextProjectCount = nextProjects.length;

          this.setState({
            projects: nextProjects,
            isMore: nextProjectCount < pagination.count,
            currentPage: currentPage + 1,
            isLoading: false
          });
        },
        (err: ErrorResponse<CIQError>) => notification.showError(err.message)
      );
    }
  };

  handleRemoveNetworkClient = (): void => {
    this.setState(
      {
        currentPage: 0,
        isLoading: true,
        projects: [],
        clientFromNetwork: null
      },
      () => this.fetchProjects()
    );
  };

  handleDeleteClicked = (event: React.MouseEvent, projectId: string): void => {
    event.stopPropagation(); // Prevent Propagate click event to parent
    this.setState({ deleteProjectId: projectId });
  };

  handleDeleteConfirmClicked = (): void => {
    const { t } = this.props;
    const { deleteProjectId } = this.state;
    this.projectDeleteSubscription = ProjectService.deleteDraftProject(
      deleteProjectId
    ).subscribe(
      (response) => {
        const { deleted, id } = response;
        if (deleted) {
          notification.showSuccess(t("draftDeleteSuccess"));
          const { projects } = this.state;
          const nextProjectState = projects.filter((p) => p.id !== id);
          this.setState({
            projects: [...nextProjectState],
            deleteProjectId: ""
          });
        }
      },
      (err: ErrorResponse<CIQError>) => {
        notification.showError(err.message);
      }
    );
  };

  handleDeleteCancelClicked = (): void => {
    this.setState({ deleteProjectId: "" });
  };

  handleSortToggleEvent = (): void => {
    const { isSortFilterOpen } = this.state;
    this.setState({
      isSortFilterOpen: !isSortFilterOpen
    });
  };

  handleProjectTypeFilterToggleEvent = (): void => {
    const { isProjectTypeFilterOpen } = this.state;
    this.setState({
      isProjectTypeFilterOpen: !isProjectTypeFilterOpen
    });
  };

  handleFilterStateChange = (): void => {
    if (this.formikRef && this.formikRef.isValid) {
      this.setState(
        {
          currentPage: 0,
          isLoading: true,
          projects: []
        },
        () => this.fetchProjects()
      );
    }
  };

  handleProjectsBottomReached = (): void => {
    const { isMore, isLoading } = this.state;

    if (isMore && !isLoading) {
      this.fetchProjects();
    }
  };

  handleInitialFetch = (elementCount?: number): void => {
    if (elementCount) this.initialElementCount = elementCount;
    this.fetchProjects();
  };

  handleCreateProjectClick = (): void => {
    const { history } = this.props;
    history.push(NEW_PROJECT);
  };

  renderProjectsList = (): JSX.Element => {
    const { projects, isLoading } = this.state;
    return (
      <>
        <Row className="projects-list">
          {projects.map((project) => (
            <React.Fragment key={project.id}>
              <AccessManager permission="projectsHome:clientProject">
                <ProjectListItem
                  project={project}
                  onDeleteClicked={this.handleDeleteClicked}
                />
              </AccessManager>
              <AccessManager
                permission="projectsHome:expertProject"
                key={project.id}
              >
                <ProjectExpertListItem project={project} />
              </AccessManager>
            </React.Fragment>
          ))}
        </Row>
        {isLoading && <Loader />}
      </>
    );
  };

  renderDeleteModal = (): JSX.Element => {
    const { t } = this.props;
    return (
      <Modal
        isOpen={this.state.deleteProjectId !== ""}
        className="modal-alert danger"
      >
        <ModalHeader>{t("draftProjectDelete")}</ModalHeader>
        <ModalBody>{t("draftProjectDeleteWarning")}</ModalBody>
        <ModalFooter>
          <Button
            size="md"
            color="secondary"
            onClick={this.handleDeleteCancelClicked}
          >
            {t("cancel")}
          </Button>
          <Button
            size="md"
            color="danger"
            onClick={this.handleDeleteConfirmClicked}
          >
            {t("delete")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  renderFilterForm = ({
    values,
    setFieldValue
  }: FormikProps<ProjectFilterFormValues>): JSX.Element => {
    const {
      isProjectTypeFilterOpen,
      isSortFilterOpen,
      projects,
      clientFromNetwork
    } = this.state;

    const { t } = this.props;

    const isProjectsAvailable = Boolean(projects.length);

    const handleProjectStateFilterChange = (
      filterCriteria: FilterCriteria
    ): void => {
      setFieldValue("projectState", filterCriteria);
    };

    const handleSortFilterChange = (sortCriteria: SortCriteria): void => {
      setFieldValue("sortCriteria", sortCriteria);
    };
    const handleQueryChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ): void => {
      const searchQuery = event.target.value;
      if (
        searchQuery === "" ||
        searchQuery.length >= MINIMUM_SEARCH_TERM_LENGTH
      ) {
        const nextQuery = event.target.value;
        if (this.searchQueryDebounceTimeout)
          clearTimeout(this.searchQueryDebounceTimeout);

        this.searchQueryDebounceTimeout = setTimeout(() => {
          setFieldValue("searchQuery", nextQuery);
        }, SEARCH_DEBOUNCE_TIMEOUT);
      }
    };

    return (
      <div className="search-filter-bar">
        <Row>
          <Col className="search-column">
            <InputGroup className="search-input-grey">
              <InputGroupAddon addonType="prepend">
                <span className="ciq-icon ciq-search" />
              </InputGroupAddon>
              <Input
                type="search"
                autoComplete="off"
                placeholder={
                  clientFromNetwork
                    ? t("searchClientsProjects", {
                        client: clientFromNetwork.name
                      })
                    : t("searchProjects")
                }
                name="searchQuery"
                onChange={handleQueryChange}
              />
            </InputGroup>
          </Col>
          <Col className="filter-column">
            <AccessManager permission="projectsHome:filterProjects">
              <Dropdown
                isOpen={isProjectTypeFilterOpen}
                toggle={this.handleProjectTypeFilterToggleEvent}
                className="d-none d-sm-flex"
              >
                <DropdownToggle caret>
                  {t(values.projectState.name)}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    onClick={(): void =>
                      handleProjectStateFilterChange(
                        PROJECT_FILTER_CRITERIA.ALL
                      )
                    }
                    value={PROJECT_FILTER_CRITERIA.ALL}
                  >
                    {t("all")}
                  </DropdownItem>
                  <DropdownItem
                    onClick={(): void =>
                      handleProjectStateFilterChange(
                        PROJECT_FILTER_CRITERIA.ACTIVE
                      )
                    }
                    value={PROJECT_FILTER_CRITERIA.ACTIVE}
                  >
                    {t("active")}
                  </DropdownItem>
                  <DropdownItem
                    onClick={(): void =>
                      handleProjectStateFilterChange(
                        PROJECT_FILTER_CRITERIA.ARCHIVE
                      )
                    }
                    value={PROJECT_FILTER_CRITERIA.ARCHIVE}
                  >
                    {t("archived")}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </AccessManager>
            <Dropdown
              isOpen={isSortFilterOpen}
              toggle={this.handleSortToggleEvent}
              className={clsx("d-none d-sm-flex", {
                disabled: !isProjectsAvailable
              })}
            >
              <DropdownToggle caret>
                {t(values.sortCriteria.name)}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={(): void =>
                    handleSortFilterChange(PROJECT_SORT_CRITERIA.NAME)
                  }
                >
                  {t("name")}
                </DropdownItem>
                <DropdownItem
                  onClick={(): void =>
                    handleSortFilterChange(PROJECT_SORT_CRITERIA.DATE)
                  }
                >
                  {t("date")}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>

            <AccessManager permission="projectsHome:newProject">
              <Link
                className="btn btn-sm btn-primary with-icon"
                to={NEW_PROJECT}
              >
                <span className="ciq-plus" />
                {t("new")}
              </Link>
            </AccessManager>
          </Col>
        </Row>
        {clientFromNetwork && (
          <Row>
            <Col>
              <div className="client-tag-container">
                <div className="client-tag searcher">
                  <p title={clientFromNetwork.name}>{clientFromNetwork.name}</p>
                  <Icon
                    name="close"
                    fontSize="18px"
                    onClick={this.handleRemoveNetworkClient}
                  />
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
    );
  };

  renderEmptyStates = (): JSX.Element => {
    const { t } = this.props;
    const { values } = this.formikRef!;
    const { searchQuery } = values;

    const isSearchResult = searchQuery !== "";

    const title = isSearchResult ? t("searchNoProjectsTitle") : t("noProjects");
    const expertEmptyResultDescription = isSearchResult
      ? t("searchNoResultDescription")
      : t("expertNoProjectsDescription");
    const clientEmptyResultDescription = isSearchResult
      ? t("searchNoResultDescription")
      : t("clientUserNoProjects");
    const clientAdminNoResultDescription = isSearchResult
      ? t("searchNoResultDescription")
      : t("noProjectsDescription");

    const buttonText = isSearchResult ? undefined : t("createProject");

    const emptyDescriptions = new Map([
      [APP_USER_ROLES.expert, expertEmptyResultDescription],
      [APP_USER_ROLES.client, clientEmptyResultDescription],
      [APP_USER_ROLES.adminClient, clientAdminNoResultDescription]
    ]);

    return (
      <>
        <CIQEmptyPlaceholder
          title={title}
          itemType={LISTING_TYPES.PROJECTS}
          buttonText={buttonText}
          onButtonClick={this.handleCreateProjectClick}
          roleDescription={emptyDescriptions}
          buttonVisibleRoles={[APP_USER_ROLES.adminClient]}
        />
      </>
    );
  };

  render(): JSX.Element {
    const { projects, isLoading, clientFromNetwork } = this.state;
    const { t } = this.props;

    return (
      <Media queries={APP_DEVICE_MEDIA_QUERIES}>
        {(matches): JSX.Element => (
          <div className="home-container page-content">
            <div className="home-header page-content-header">
              <div className="top-container">
                <h1>{t("projects")}</h1>
                <div className="btn-container">
                  <AccessManager permission="projectsHome:newProject">
                    <Link
                      className="btn btn-sm btn-primary with-icon"
                      to={NEW_PROJECT}
                      onClick={() =>
                        trackEvent(MixPanelEventNames.CreateSimplifiedProject, {
                          action: MixPanelActions.Clicked
                        })
                      }
                    >
                      <span className="ciq-plus" />
                      {t("project")}
                    </Link>
                  </AccessManager>
                </div>
              </div>
              <div className="bottom-container" />
            </div>

            <div className="home-body">
              {this.renderDeleteModal()}
              <Formik<ProjectFilterFormValues>
                initialValues={{
                  projectState: PROJECT_FILTER_CRITERIA.ACTIVE,
                  searchQuery: "",
                  sortCriteria: PROJECT_SORT_CRITERIA.DATE
                }}
                innerRef={(instance): void => {
                  this.formikRef = instance;
                }}
                validate={this.handleFilterStateChange}
                validateOnChange
                onSubmit={(): void => {}}
              >
                {this.renderFilterForm}
              </Formik>

              <CIQInfiniteScroll
                onBottomReached={this.handleProjectsBottomReached}
                className="projects-container simplebar-light"
                style={{
                  maxHeight: UI_HOME_PAGE.CONTAINER_HEIGHT(
                    matches,
                    Boolean(clientFromNetwork)
                  ),
                  height: UI_HOME_PAGE.CONTAINER_HEIGHT(
                    matches,
                    Boolean(clientFromNetwork)
                  ),
                  overflowX: "hidden"
                }}
                onInitialFetch={this.handleInitialFetch}
                elementHeight={UI_HOME_PAGE.PROJECT_ELEMENT_HEIGHT(matches)}
              >
                {this.renderProjectsList()}
                {!isLoading &&
                  projects.length === 0 &&
                  this.renderEmptyStates()}
              </CIQInfiniteScroll>
            </div>
          </div>
        )}
      </Media>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  navigateToProject: (tabRoute: string): AppAction => dispatch(push(tabRoute)),
  getProjectListForNavbar: (): AppAction =>
    dispatch(NavBarStoreActions.getProjectListForNavbar())
});
export default connect(
  null,
  mapDispatchToProps
)(withTranslation("projectsPage")(ProjectsPage));
