import { DateString } from "./types";

export enum EmployedStatus {
  Employed = "Employed",
  Unemployed = "Unemployed",
  Retired = "Retired",
  Independent = "Independent"
}

export interface ExpertWorkHistory {
  id: string;
  title: string | null;
  from: DateString;
  to: DateString | null;
  companyId: string | null;
  companyName: string | null;
  isCurrentWork: boolean;
  countryId: string | null;
  countryName: string | null;
  employedStatus: EmployedStatus;
}
