import React from "react";
import { useTranslation } from "react-i18next";

import { ExpertPipelineRateCard } from "@arbolus-technologies/models/expert";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { Tag } from "@arbolus-technologies/ui/components";
import { convertValueToCurrencyFormat } from "@arbolus-technologies/utils";

interface PipelineRateCardProps {
  rateCard?: ExpertPipelineRateCard | null;
}

export const PipelineRateCard: React.FC<PipelineRateCardProps> = ({
  rateCard
}) => {
  const { t } = useTranslation(`pipelineExpertCard`);

  const getRateCardText = () =>
    convertValueToCurrencyFormat(rateCard?.price!, rateCard?.isoCurrencyCode!);

  return (
    <Tag
      color={
        rateCard?.price
          ? ARBOLUS_COLORS.bColorAccentGreenDark
          : ARBOLUS_COLORS.bColorBaseOrange
      }
      text={rateCard?.price ? getRateCardText() : t("noRateCard")}
    />
  );
};
