import React from "react";

import {
  ANSWER_TYPE,
  CanopySelectedQuestionDetails
} from "@arbolus-technologies/models/canopy-panels";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { InternalSlidePanel } from "../../Panels/InternalSlidePanel/InternalSlidePanel";
import { ExpertsListForCanopyAnswers } from "./ExpertsListForCanopyAnswers/ExpertsListForCanopyAnswers";

export interface ExpertsListForCanopyAnswersSlidePanelProps {
  questionId?: string;
  selectedQuestionDetails?: CanopySelectedQuestionDetails | null;
  panelTitle: string;
  panelHeader: { title: string; message?: string };
  onClose: () => void;
  isOpen: boolean;
  type: ANSWER_TYPE;
}

export const ExpertsListForCanopyAnswersSlidePanel: React.FC<
  ExpertsListForCanopyAnswersSlidePanelProps
> = ({
  questionId,
  selectedQuestionDetails,
  panelTitle,
  panelHeader,
  onClose,
  isOpen,
  type
}) => (
  <InternalSlidePanel
    width={SIDE_PANEL_SIZE._720}
    customCloseRequest={onClose}
    isOpen={isOpen}
    noPadding
    title={panelTitle}
    closeButtonDirection="right"
  >
    <ExpertsListForCanopyAnswers
      questionId={questionId}
      selectedQuestionDetails={selectedQuestionDetails}
      panelHeader={panelHeader}
      type={type}
      onClose={onClose}
    />
  </InternalSlidePanel>
);
