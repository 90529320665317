import axios, { CancelTokenSource } from "axios";
import clsx from "clsx";
import React, { Component } from "react";
import { Button, Progress } from "reactstrap";
import { Subject, Subscription } from "rxjs";
// eslint-disable-next-line import/extensions

import { ToasterService } from "@arbolus-technologies/api";

import {
  PROJECT_CLIENTS_API,
  PROJECT_EXPERTS_API,
  REST_ERROR
} from "../../../../constants/api";
import { FILE_UPLOADING_STATES } from "../../../../constants/files";
import { CIQError, ErrorResponse } from "../../../../models/api";
import { Document } from "../../../../models/documents";
import { UploadingAttachment } from "../../../../models/view/documents";
import { DocumentService, RBServiceManager } from "../../../../services";

import { CIQFileIcon } from "./index";

const notification = new ToasterService();

interface UploadingDocumentProps {
  document: UploadingAttachment;
  projectId: string;
  handleOnFinishUploading: (
    file: UploadingAttachment,
    uploadedDocument: Document
  ) => void;
  onUploadStop: (file: UploadingAttachment) => void;
}

interface UploadingDocumentState {
  uploadingProgress: number;
  uploadState: FILE_UPLOADING_STATES;
}

export class UploadingDocument extends Component<
  UploadingDocumentProps,
  UploadingDocumentState
> {
  constructor(props: UploadingDocumentProps) {
    super(props);
    this.state = {
      uploadingProgress: 0,
      uploadState: FILE_UPLOADING_STATES.IDLE
    };
  }

  componentDidMount(): void {
    this.uploadSelectedFile();

    this.documentUploadProgressSubscription =
      this.documentUploadProgressSubject.subscribe((progress) => {
        this.setState({ uploadingProgress: progress });
      });
  }

  componentWillUnmount(): void {
    this.documentUploadSubscription &&
      this.documentUploadSubscription.unsubscribe();
    this.documentUploadProgressSubject &&
      this.documentUploadProgressSubject.unsubscribe();
  }

  private documentUploadSubscription?: Subscription;

  private uploadCancelSource: CancelTokenSource = axios.CancelToken.source();

  private documentUploadProgressSubject: Subject<number> = new Subject();

  private documentUploadProgressSubscription?: Subscription;

  uploadSelectedFile = (): void => {
    const { document, projectId, handleOnFinishUploading, onUploadStop } =
      this.props;

    this.setState({ uploadState: FILE_UPLOADING_STATES.UPLOADING });
    this.uploadCancelSource = axios.CancelToken.source();

    const formData = new FormData();
    formData.append("file", document.attachment);

    this.documentUploadSubscription = RBServiceManager.serviceCaller(
      DocumentService.uploadFile(
        PROJECT_CLIENTS_API.CREATE_DOCUMENT(projectId),
        formData,
        this.documentUploadProgressSubject
      ),
      DocumentService.uploadFile(
        PROJECT_EXPERTS_API.CREATE_DOCUMENT(projectId),
        formData,
        this.documentUploadProgressSubject
      )
    ).subscribe(
      (response: Document) =>
        this.setState({ uploadState: FILE_UPLOADING_STATES.UPLOADED }, () => {
          handleOnFinishUploading(document, response);
        }),
      ({ status, message }: ErrorResponse<CIQError>) => {
        this.setState({ uploadState: FILE_UPLOADING_STATES.UPLOAD_FAILED });
        switch (status) {
          case REST_ERROR.REQUEST_CANCELED:
            onUploadStop(document);
            break;
          case REST_ERROR.UNSUPPORTED_FILE_TYPE:
            notification.showError(message);
            onUploadStop(document);
            break;
          default:
            notification.showError(message);
        }
      }
    );
  };

  generateProgressBarColor = (): string => {
    const { uploadState } = this.state;
    let progressBarColor = "";
    switch (uploadState) {
      case FILE_UPLOADING_STATES.UPLOADED:
        progressBarColor = "success";
        break;
      case FILE_UPLOADING_STATES.UPLOAD_FAILED:
        progressBarColor = "danger";
        break;
      default:
        progressBarColor = "";
        break;
    }

    return progressBarColor;
  };

  handleStopUploading = (): void => {
    this.uploadCancelSource.cancel();
    this.documentUploadProgressSubscription &&
      this.documentUploadProgressSubscription.unsubscribe();
  };

  handleRetryFailedUpload = (): void => {
    this.setState(
      {
        uploadState: FILE_UPLOADING_STATES.UPLOADING,
        uploadingProgress: 0
      },
      () => this.uploadSelectedFile()
    );
  };

  render(): JSX.Element | null {
    const { document } = this.props;
    const { uploadingProgress, uploadState } = this.state;
    const prettifiedFileName = DocumentService.prettifyFileName(
      DocumentService.sanitizeFileName(document.attachment.name)
    );
    const progressColor = this.generateProgressBarColor();
    const isUploading = uploadState === FILE_UPLOADING_STATES.UPLOADING;
    const isUploadingFailed =
      uploadState === FILE_UPLOADING_STATES.UPLOAD_FAILED;
    const isUploaded = uploadState === FILE_UPLOADING_STATES.UPLOADED;

    if (document) {
      return (
        <div
          className={clsx("file-item", {
            uploading: isUploading || isUploadingFailed || isUploaded
          })}
        >
          <div className="left-container">
            <div className="file-type-icon">
              {CIQFileIcon(document.attachment.name)}
            </div>
            <div className="detail-container">
              <div className="file-name">{prettifiedFileName}</div>
              <div className="file-uploading-progressbar">
                <Progress color={progressColor} value={uploadingProgress} />
              </div>
            </div>
          </div>

          <div className="right-container">
            <div className="action-button">
              {isUploading && (
                <Button onClick={this.handleStopUploading}>
                  <i className="ciq-icon ciq-close" />
                </Button>
              )}

              {isUploadingFailed && (
                <Button onClick={this.handleRetryFailedUpload}>
                  <i className="ciq-icon ciq-repeat" />
                </Button>
              )}
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}
