import { ICellRendererParams } from "ag-grid-community";
import { Button } from "arbolus-ui-components";
import React from "react";

import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";

import { ReferralGridContext } from "../../Models/ReferralsTable";
import { SlotRow } from "../../Modules/ReferralsTable/scheduling/SlotRow";
import { Booking } from "../BookingButton/Booking";

export const BookSlotRenderer: React.FC<
  ICellRendererParams<SlotRow, never, ReferralGridContext>
> = ({ data, context }) => {
  if (!data) {
    return null;
  }

  return (
    <Booking
      slot={data}
      timezone={context.timezone}
      referral={data}
      project={context.project}
      render={(_, onClick) => (
        <Button
          type="tertiary"
          endIcon="chevron_right"
          text="Book"
          onClick={onClick}
          disabled={
            data.expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC
          }
        />
      )}
    />
  );
};
