import { INBOX_NOTIFICATION_CATEGORY } from "../../../../constants/notifications";
import { CIQError, ErrorResponse } from "../../../../models/api";

export const GET_NOTIFICATION_COUNTS = "INBOX_GET_NOTIFICATION_COUNTS";
export const GET_NOTIFICATION_COUNTS_FAILURE =
  "INBOX_GET_NOTIFICATION_COUNTS_FAILURE";
export const GET_NOTIFICATION_COUNTS_SUCCESS =
  "INBOX_GET_NOTIFICATION_COUNTS_SUCCESS";

export const MARK_NOTIFICATION_AS_READ = "INBOX_MARK_NOTIFICATION_AS_READ";
export const MARK_NOTIFICATION_AS_READ_SUCCESS =
  "INBOX_MARK_NOTIFICATION_AS_READ_SUCCESS";
export const MARK_NOTIFICATION_AS_READ_FAILURE =
  "INBOX_MARK_NOTIFICATION_AS_READ_FAILURE";

export interface GetNotificationCountsAction {
  type: typeof GET_NOTIFICATION_COUNTS;
}

export interface GetNotificationCountsSuccessAction {
  type: typeof GET_NOTIFICATION_COUNTS_SUCCESS;
  payload: {
    notificationCounts: Map<INBOX_NOTIFICATION_CATEGORY, number>;
  };
}

export interface GetNotificationCountsFailureAction {
  type: typeof GET_NOTIFICATION_COUNTS_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

export interface MarkNotificationAsReadAction {
  type: typeof MARK_NOTIFICATION_AS_READ;
  payload: {
    notificationId: string;
    notificationCategory: INBOX_NOTIFICATION_CATEGORY;
  };
}

export interface MarkNotificationAsReadSuccessAction {
  type: typeof MARK_NOTIFICATION_AS_READ_SUCCESS;
  payload: {
    notificationCategories: INBOX_NOTIFICATION_CATEGORY[];
  };
}

export interface MarkNotificationAsReadFailureAction {
  type: typeof MARK_NOTIFICATION_AS_READ_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}
