import React from "react";
import { TypeaheadResult } from "react-bootstrap-typeahead";
import { useTranslation } from "react-i18next";

import {
  AdminService,
  ApiPaginatedRequest,
  SORT_DIRECTION
} from "@arbolus-technologies/api";

import {
  SelectController,
  TypeaheadSelectorControllerProps
} from "../SelectTypeahead/SelectController";

interface DisplayCanopy {
  title: string;
}

export const CanopySelector = ({
  placeholderText,
  ...props
}: TypeaheadSelectorControllerProps<DisplayCanopy>): React.ReactElement => {
  const { t } = useTranslation();
  const placeholder = placeholderText ?? t("common:canopySelection:findCanopy");

  const renderer = ({ title }: TypeaheadResult<DisplayCanopy>): JSX.Element => (
    <span>{title}</span>
  );

  const getItems = (pagination: ApiPaginatedRequest) =>
    AdminService.getCanopies(
      pagination.offset,
      pagination.searchTerm ?? "",
      "created",
      pagination.limit,
      SORT_DIRECTION.ASCENDING
    );

  return (
    <SelectController
      {...props}
      placeholderText={placeholder}
      promptText={t("common:canopySelection:findingCanopies")}
      searchText={t("common:canopySelection:findingCanopies")}
      paginationText={t("common:canopySelection:loadMore")}
      orderBy="created"
      renderer={renderer}
      getLabelKey={(canopy) => canopy?.title ?? ""}
      getItems={getItems}
    />
  );
};
