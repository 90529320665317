import clsx from "clsx";
import React from "react";

import { SelectedFilter, SelectedFilterEnum } from "@arbolus-technologies/api";
import { AngleModel } from "@arbolus-technologies/models/common";
import { Button } from "@arbolus-technologies/ui/components";

import { FilterByAngles } from "../FilterComponents/FilterByAngles";
import { FilterByCallStatus } from "../FilterComponents/FilterByCallStatus";
import { FilterByStatus } from "../FilterComponents/FilterByStatus";

import styles from "./CardItem.module.scss";

interface CardItemProps {
  type: keyof SelectedFilter;
  statuses?: string[];
  angles?: AngleModel[];
  onClick: (type: keyof SelectedFilter, value: string) => void;
  classnames?: string;
  filters?: string[];
}

export const CardItem: React.FC<CardItemProps> = ({
  type,
  statuses,
  angles,
  onClick,
  classnames,
  filters
}) => {
  const isAngleCard = type === SelectedFilterEnum.ANGLE_IDS;
  const isStatusCard = type === SelectedFilterEnum.STATUSES;

  // TO_REPLACE

  if (isAngleCard) {
    return (
      <div className={styles.container}>
        {angles?.map((angle: AngleModel) => {
          const isSelected = filters?.includes(angle.id);

          return (
            <section
              key={angle.id}
              className={clsx(
                styles.cardItem,
                classnames,
                isSelected && styles.selected
              )}
            >
              <FilterByAngles isCard angle={angle} />
              <Button
                type="tertiary"
                centerIcon={isSelected ? "check" : "add"}
                onClick={() => onClick(type, angle.id)}
              />
            </section>
          );
        })}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {statuses?.map((status: string) => {
        const isSelected = filters?.includes(status);

        return (
          <section
            key={status}
            className={clsx(
              styles.cardItem,
              classnames,
              isSelected && styles.selected
            )}
          >
            {isStatusCard ? (
              <FilterByStatus isCard status={status} />
            ) : (
              <FilterByCallStatus isCard status={status} />
            )}
            <Button
              type="tertiary"
              centerIcon={isSelected ? "check" : "add"}
              onClick={() => onClick(type, status)}
            />
          </section>
        );
      })}
    </div>
  );
};
