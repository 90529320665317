import {
  ApiError,
  ApiErrorResponse,
  CIQError,
  CompleteValidatorErrors,
  UploadingAttachment,
  ValidatorErrors
} from "@arbolus-technologies/api";
import { isSupportedFileType } from "@arbolus-technologies/features/common";
import {
  MAXIMUM_FILE_NAME_LENGTH,
  MAXIMUM_FILE_UPLOADING_SIZE
} from "@arbolus-technologies/models/documents";
import { normalizeFileName } from "@arbolus-technologies/utils";

export const handleBrowserCloseListener = (event: BeforeUnloadEvent): void => {
  event.preventDefault();
  // eslint-disable-next-line no-param-reassign
  event.returnValue = "";
};

export const handleUploadStateChanges = (
  uploadingFiles: UploadingAttachment[]
): void => {
  if (uploadingFiles.length > 0) {
    window.addEventListener("beforeunload", handleBrowserCloseListener);
  } else {
    window.removeEventListener("beforeunload", handleBrowserCloseListener);
  }
};

export const ValidateFileAttributes = (
  file: File
): {
  error: string;
  limit?: number;
} | null => {
  // Validate file type
  const isSupported = isSupportedFileType(file);
  if (!isSupported) {
    return { error: "fileNotSupported" };
  }

  const { size, name } = file;

  // Validate file size
  if (size >= MAXIMUM_FILE_UPLOADING_SIZE) {
    return {
      error: "fileSizeLimitError",
      limit: MAXIMUM_FILE_UPLOADING_SIZE / 1024 ** 2
    };
  }

  // Validate file name
  const isFileNameTooLong = name.length >= MAXIMUM_FILE_NAME_LENGTH;
  if (isFileNameTooLong) {
    return {
      error: "fileNameMaxLengthError",
      limit: MAXIMUM_FILE_NAME_LENGTH
    };
  }
  return null;
};

export const getCanopyErrors = (
  apiErrors: ApiErrorResponse<CIQError> | null
): ValidatorErrors | null => {
  if (!apiErrors) {
    return null;
  }

  const apiError = new ApiError(apiErrors);
  if (apiError.isDefaultError) {
    return null;
  }
  return (apiErrors as CompleteValidatorErrors).errors;
};

export const getUpdatedDocuments = (
  uploadingFiles: UploadingAttachment[],
  documentsToRemove: string[]
): { addedDocs: FormData[]; removedDocs: string[] } => {
  const addedDocs = uploadingFiles
    .filter(
      (file) => !documentsToRemove.find((doc) => doc === file.attachmentId)
    ) // to filter out files that are being removed after being added
    .map((file) => file.attachment)
    .map((document) => {
      const formData = new FormData();

      const fileNameParsed = normalizeFileName(document.name);
      formData.append("document", document, fileNameParsed);

      return formData;
    });

  const removedDocs = documentsToRemove.filter(
    (docId) => !uploadingFiles?.find((doc) => doc.attachmentId === docId) // to filter out files that are being removed after being added
  );

  return { addedDocs, removedDocs };
};
