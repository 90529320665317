import React, { DispatchWithoutAction } from "react";

export type SchedulingState = "expert" | "slots";

export const SchedulingContext = React.createContext<SchedulingState>("expert");
export const SchedulingDispatchContext =
  React.createContext<DispatchWithoutAction | null>(null);

interface SchedulingProviderProps {
  render: (state: SchedulingState) => React.ReactNode;
}

export const SchedulingProvider: React.FC<SchedulingProviderProps> = ({
  render
}) => {
  const [state, dispatch] = React.useReducer(reducer, "expert");
  return (
    <SchedulingContext.Provider value={state}>
      <SchedulingDispatchContext.Provider value={dispatch}>
        {render(state)}
      </SchedulingDispatchContext.Provider>
    </SchedulingContext.Provider>
  );
};

function reducer(state: SchedulingState) {
  return state === "expert" ? "slots" : "expert";
}
