import clsx from "clsx";
import React from "react";
import { Control, Controller, FieldError } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { Label } from "reactstrap";

import styles from "./PhoneInputController.module.scss";

interface PhoneInputControllerProps {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  error?: FieldError;
  inputClass?: string;
}

export const PhoneInputController: React.FC<PhoneInputControllerProps> = ({
  name,
  control,
  error,
  inputClass
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, onBlur, value } }) => (
      <>
        <PhoneInput
          inputProps={{ name }}
          placeholder="e.g. 44 20 030 040 050"
          enableSearch
          country="us"
          value={value}
          onBlur={onBlur}
          // @ts-ignore
          onChange={(
            _: string,
            __: {},
            ___: React.ChangeEvent<HTMLInputElement>,
            formattedValue: string
          ) => onChange(formattedValue)}
          disableSearchIcon
          containerClass={clsx(error && styles.errorInput)}
          inputClass={inputClass ?? styles.input}
        />
        <div className={styles.errorContainer}>
          {error && (
            <Label className={styles.errorMessage}>{error.message}</Label>
          )}
        </div>
      </>
    )}
  />
);
