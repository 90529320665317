import React from "react";

import styles from "./ClearButton.module.scss";

export const ClearButton: React.FC<{
  text: string;
  onClick: () => void;
  disabled?: boolean;
}> = ({ text, onClick, disabled = false }) => (
  <button className={styles.clearButton} disabled={disabled} onClick={onClick}>
    {text}
  </button>
);
