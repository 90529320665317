import i18next from "i18next";
import { array, boolean, object, string } from "yup";

import { checkBoxesDefaultValues } from "./constants";
import { CheckBoxOption } from "./types";

const OTHER = checkBoxesDefaultValues.find(
  (checkbox) => checkbox.text === "Other"
)?.text;

export const GetClientReportExpertSchema = object().shape({
  feedbackText: string()
    .when("reportReason", {
      is: (reportReason: CheckBoxOption[]) => {
        const otherSelected = reportReason.some(
          (option: CheckBoxOption) => option.text === OTHER && option.isSelected
        );
        return otherSelected;
      },
      then: string().required(i18next.t("reportExpert:required"))
    })
    .max(250, i18next.t("reportExpert:maxLength", { length: 250 })),
  reportReason: array().of(
    object().shape({
      text: string(),
      isSelected: boolean()
    })
  )
});
