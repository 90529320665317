import React, { useEffect, useState } from "react";
import { FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  CIQError,
  CanopyService,
  CanopyTemplateItem,
  ErrorResponse
} from "@arbolus-technologies/api";

import { ISimpleCanopyCreationForm } from "../../SimpleCanopyCreationProvider";
import { TemplateBox } from "./TemplateBox/TemplateBox";

import styles from "./TemplateBoxList.module.scss";

interface TemplateBoxListProps {
  selectedValue?: string;
  handleSelectedValue: (value: string) => void;
  errors: FieldErrors<ISimpleCanopyCreationForm>;
}

export const TemplateBoxList: React.FC<TemplateBoxListProps> = ({
  handleSelectedValue,
  selectedValue,
  errors
}) => {
  const { t } = useTranslation(`simpleCanopyCreationProvider`);

  const [isLoading, setIsLoading] = useState(false);
  const [canopyTemplates, setCanopyTemplates] = useState<
    CanopyTemplateItem[] | undefined
  >();

  useEffect(() => {
    setIsLoading(true);
    CanopyService.requestCanopyTemplates().subscribe(
      (response) => {
        setCanopyTemplates(response.items);
        setIsLoading(false);
      },
      (error: ErrorResponse<CIQError>) => {
        setIsLoading(false);
      }
    );
  }, []);
  return (
    <div className={styles.container}>
      {errors.canopyCreationType?.canopyTemplate ? (
        <span className={styles.titleRed}>{t("chooseTemplateError")}</span>
      ) : (
        <span className={styles.title}>{t("chooseTemplate")}</span>
      )}
      <div className={styles.list}>
        {!isLoading &&
          canopyTemplates &&
          canopyTemplates.map((template) => (
            <TemplateBox
              key={template.canopyTemplateId}
              active={selectedValue === template.canopyTemplateId}
              handleOnClick={handleSelectedValue}
              template={template}
            />
          ))}
      </div>
    </div>
  );
};
