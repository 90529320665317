import { Button } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { EXPERT_CANOPY_STATUS } from "@arbolus-technologies/models/canopy";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  ArbolusModalAdminReportExpert,
  ArbolusModalClientReportExpert
} from "@arbolus-technologies/ui/components";

import { MixPanelActions, MixPanelEventNames } from "../../Mixpanel/enums";
import { useArbolusTracking } from "../../Mixpanel/useArbolusTracking";

interface ReportExpertButtonProps {
  expertStatus?: EXPERT_CANOPY_STATUS;
  expertName: string;
  expertId?: string;
}
export const ReportExpertButton: React.FC<ReportExpertButtonProps> = ({
  expertStatus,
  expertName,
  expertId = ""
}) => {
  const { t } = useTranslation("reportExpert");
  const { trackClick, trackSubmit } = useArbolusTracking();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const clientUser = useSelector(CacheSelector.loggedInUser());
  const adminUser = useSelector(CacheSelector.userAdminProfile());
  const isAdmin = useSelector(CacheSelector.isAdmin());

  const handleIsModalOpen = () => {
    setIsModalOpen((prev) => !prev);
  };

  const trackButtonClick = () => {
    !isAdmin && trackClick(MixPanelEventNames.CanopyReportExpertButton);
  };

  const trackSubmitButtonClick = (reason: string, feedbackText: string) => {
    trackSubmit(MixPanelEventNames.CanopyReportExpertSubmit, {
      action: MixPanelActions.SubmitReportExpert,
      reason,
      feedbackText
    });
  };

  return (
    <>
      <Button
        type="default"
        onClick={() => {
          trackButtonClick();
          handleIsModalOpen();
        }}
        disabled={
          expertStatus && expertStatus !== EXPERT_CANOPY_STATUS.COMPLETE
        }
        icon={<AntDIcon name="warning" />}
      >
        {t("reportExpert")}
      </Button>

      {isAdmin ? (
        <ArbolusModalAdminReportExpert
          isOpen={isModalOpen}
          email={adminUser?.email}
          expertName={expertName}
          expertId={expertId}
          toggle={handleIsModalOpen}
        />
      ) : (
        <ArbolusModalClientReportExpert
          isOpen={isModalOpen}
          email={clientUser?.email}
          expertName={expertName}
          toggle={handleIsModalOpen}
          trackSubmitMixPanel={trackSubmitButtonClick}
        />
      )}
    </>
  );
};
