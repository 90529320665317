import {
  Answer,
  MultiChoiceAnswer,
  MultipleChoiceQuestionDetails as MultiChoiceQuestionDetails,
  QuestionDetails,
  VideoAnswer
} from "./interfaces";

export const isVideoAnswer = (answer?: Answer | null): answer is VideoAnswer =>
  (answer as VideoAnswer)?.downloadUrl !== undefined;

export const isMultiChoiceAnswer = (
  answer?: Answer | null
): answer is MultiChoiceAnswer =>
  (answer as MultiChoiceAnswer)?.selectedOptionChoicesIds !== undefined;

export const isMultiChoiceQuestion = (
  question?: QuestionDetails | null
): boolean =>
  (question as MultiChoiceQuestionDetails)?.answersRange !== undefined;
