import {
  CIQError,
  ErrorResponse,
  ListBookmarkCollectionsQueryParams,
  PagedListResponse
} from "@arbolus-technologies/api";
import { BookmarkCollection } from "@arbolus-technologies/models/common";

export const LIST_COLLECTIONS = "BOOKMARK_COLLECTIONS_LIST_COLLECTIONS";
export const LIST_COLLECTIONS_SUCCESS =
  "BOOKMARK_COLLECTIONS_LIST_COLLECTIONS_SUCCESS";
export const LIST_COLLECTIONS_FAILURE =
  "BOOKMARK_COLLECTIONS_LIST_COLLECTIONS_FAILURE";
export const LIST_COLLECTIONS_RELOAD =
  "BOOKMARK_COLLECTIONS_LIST_COLLECTIONS_RELOAD";
export const LIST_COLLECTIONS_RESET =
  "BOOKMARK_COLLECTIONS_LIST_COLLECTIONS_RESET";

export interface ListCollectionsAction {
  type: typeof LIST_COLLECTIONS;
  payload: {
    searchTerm: string;
    queryParams: ListBookmarkCollectionsQueryParams;
  };
}

export interface ListCollectionsSuccessAction {
  type: typeof LIST_COLLECTIONS_SUCCESS;
  payload: {
    response: PagedListResponse<BookmarkCollection>;
  };
}

export interface ListCollectionsFailureAction {
  type: typeof LIST_COLLECTIONS_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

export interface ListCollectionsReloadAction {
  type: typeof LIST_COLLECTIONS_RELOAD;
}

export interface ListCollectionsResetAction {
  type: typeof LIST_COLLECTIONS_RESET;
}
