import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./ResponseDate.module.scss";

interface ResponseDateProps {
  responseDate: Date;
}

export const ResponseDate: React.FC<ResponseDateProps> = ({ responseDate }) => {
  const { t } = useTranslation("bookmarks");
  return (
    <div className={styles.responseDate}>
      <span>{t("responseDate")}</span>
      <span>{moment(responseDate).format("DD MMM YYYY")}</span>
    </div>
  );
};
