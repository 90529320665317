import clsx from "clsx";
import React from "react";
import { CustomInput } from "reactstrap";

import styles from "./CustomSwitch.module.scss";

/*
ON_OFF: Changes color from red to green when toggled
ALTERNATIVES: Provides two equal alternatives (either-or choice), no color change
*/
export enum CustomSwitchType {
  ON_OFF,
  ALTERNATIVES
}

interface CustomSwitchProps {
  name: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  type?: CustomSwitchType;
}

export const CustomSwitch: React.FC<CustomSwitchProps> = ({
  name,
  checked,
  onChange,
  disabled,
  type = CustomSwitchType.ON_OFF
}: CustomSwitchProps) => (
  <CustomInput
    className={clsx(styles.customSwitch, {
      [styles.disabled]: disabled,
      [styles.alternatives]: type === CustomSwitchType.ALTERNATIVES
    })}
    checked={checked}
    disabled={disabled}
    type="switch"
    id={name}
    name={name}
    onChange={onChange}
  />
);
