import { Button } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { EXPERT_CANOPY_STATUS } from "@arbolus-technologies/models/canopy";
import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  DNCTag,
  PublicCompanyBadge,
  UserPortrait
} from "@arbolus-technologies/ui/components";

import styles from "./Expert.module.scss";

export interface ExpertProps {
  expert: {
    expertId: string;
    fullName?: string;
    profileImageUrl?: string;
    title?: string;
    lastPublicCompanyExpDate: string | null;
    doNotContactStatus: DO_NOT_CONTACT_STATUS;
  };
  expertStatus?: EXPERT_CANOPY_STATUS;
  handleClickAnswers: (expertId: string) => void;
  handleOpenProfile: (expertId: string) => void;
}

export const Expert: React.FC<ExpertProps> = ({
  expert,
  expertStatus,
  handleClickAnswers,
  handleOpenProfile
}) => {
  const { t } = useTranslation("canopyPanels");

  const isAdmin = useSelector(CacheSelector.isAdmin());

  const isInReview = expertStatus === EXPERT_CANOPY_STATUS.IN_REVIEW;
  const isInComplete = expertStatus === EXPERT_CANOPY_STATUS.INCOMPLETE;

  const {
    expertId,
    fullName,
    title,
    profileImageUrl,
    lastPublicCompanyExpDate,
    doNotContactStatus
  } = expert;

  const isDNC = doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC;
  const isProceedWithCaution =
    doNotContactStatus === DO_NOT_CONTACT_STATUS.CAUTION;

  return (
    <div
      className={clsx(styles.container, {
        [styles.dnc]: isDNC,
        [styles.caution]: isAdmin && isProceedWithCaution
      })}
    >
      <div
        className={styles.expertPortraitWrapper}
        onClick={() => handleOpenProfile(expertId)}
      >
        <UserPortrait
          onHoverTooltip
          user={{
            id: expertId,
            fullName,
            title: title ?? "",
            status: expertStatus,
            profileImageUrl
          }}
          icon={
            <>
              {lastPublicCompanyExpDate && (
                <PublicCompanyBadge date={lastPublicCompanyExpDate} />
              )}
              <DNCTag
                dncStatus={doNotContactStatus}
                showTooltip
                isAdmin={isAdmin}
              />
            </>
          }
        />
      </div>
      {!isInComplete && (
        <Button
          type="tertiary"
          text={t(isInReview ? "reviewAnswers" : "answers")}
          onClick={() => handleClickAnswers(expertId)}
          endIcon="chevron_right"
        />
      )}
    </div>
  );
};
