export const LOGIN = "/login";
export const SSO = "/sso";
export const REGISTER = "/register";
export const FORGOT_PASSWORD = "/forgot";
export const RESET_PASSWORD = "/password";
export const FEDERATED_REDIRECT = "/federated";
export const ENDORSEMENT = "/endorsement";
export const WELCOME_CANOPY = "/canopy/:canopyId/welcome";
export const CANOPY_PAUSED_PUBLIC = "/public/canopy/paused";
export const SURVEY = "/survey/:surveyId";
export const SURVEY_COMPLIANCE = "/survey/:surveyId/compliance";
export const SURVEY_FEEDBACK = "/survey/:surveyId/feedback";

export const CANOPY_REGISTER = "/register?signUpType=canopy&canopyId=";

export const WELCOME_CANOPY_ROUTE = (canopyId: string): string =>
  `/canopy/${canopyId}/welcome`;

export const SURVEY_ROUTE = (surveyId: string): string => `/survey/${surveyId}`;
export const SURVEY_COMPLIANCE_ROUTE = (surveyId: string): string =>
  `/survey/${surveyId}/compliance`;

export const SURVEY_FEEDBACK_ROUTE = (surveyId: string): string =>
  `/survey/${surveyId}/feedback`;
