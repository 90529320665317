import { Observable } from "rxjs";

import { CHAT_API, MAX_PAGE_SIZE } from "../constants/api";
import { SORT_DIRECTION } from "../enums/apiEnums";
import {
  CreatedResponse,
  DeletedResponse,
  PagedListResponse,
  SuccessResponse
} from "../models/api";
import { ChatFileShareRequest, IChatMessage } from "../models/chat";
import { restService } from "../restService";

export const ChatService = {
  getMessages: (
    projectId: string,
    chatId: string,
    offset = 0,
    limit = MAX_PAGE_SIZE,
    orderBy?: "Created",
    orderDirection?: SORT_DIRECTION
  ): Observable<PagedListResponse<IChatMessage>> =>
    restService.get<PagedListResponse<IChatMessage>>(
      CHAT_API.GET_MESSAGES(projectId, chatId),
      {
        limit,
        offset,
        orderBy,
        orderDirection
      }
    ),
  updateLastActiveChat: (
    projectId: string,
    chatId: string
  ): Observable<SuccessResponse> =>
    restService.post<SuccessResponse>(
      CHAT_API.SET_LAST_ACTIVE_CHAT(projectId, chatId),
      {}
    ),
  sendMessage: (
    projectId: string,
    chatId: string,
    message: string
  ): Observable<CreatedResponse> =>
    restService.post(CHAT_API.SEND_MESSAGE(projectId, chatId), {
      message
    }),
  deleteMessage: (
    projectId: string,
    chatId: string,
    messageId: string
  ): Observable<DeletedResponse> =>
    restService.delete(
      CHAT_API.DELETE_MESSAGE(projectId, chatId, messageId),
      {}
    ),
  shareDocument: (
    projectId: string,
    chatId: string,
    request: ChatFileShareRequest
  ): Observable<CreatedResponse> =>
    restService.post(CHAT_API.SEND_FILE(projectId, chatId), request)
};
