import { Tooltip } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { Tag } from "../Tag/Tag";
import { ArbolusTooltipPlace } from "../Tooltip/ArbolusTooltip";

interface DNCTagProps {
  dncStatus: DO_NOT_CONTACT_STATUS;
  placement?: ArbolusTooltipPlace;
  skipOk?: boolean;
  showTooltip?: boolean;
  isAdmin?: boolean;
  isFullTag?: boolean;
  contactTooltip?: string;
  dncTooltip?: string;
  cautionTooltip?: string;
}

export const DNCTag: React.FC<DNCTagProps> = ({
  dncStatus,
  placement = ArbolusTooltipPlace.BOTTOM,
  skipOk = true,
  showTooltip = false,
  isAdmin = false,
  isFullTag = false,
  contactTooltip,
  dncTooltip,
  cautionTooltip
}) => {
  const { t } = useTranslation("dnc");

  const isDnc = dncStatus === DO_NOT_CONTACT_STATUS.DNC;
  const isCaution = dncStatus === DO_NOT_CONTACT_STATUS.CAUTION;
  const isOk = dncStatus === DO_NOT_CONTACT_STATUS.OK;

  const getTag = () => {
    if (isOk && !skipOk) {
      return (
        <Tag
          color={ARBOLUS_COLORS.bColorSecondaryGreen}
          text={isFullTag ? t("freeToContact") : t("contact")}
          icon="check_circle"
        />
      );
    }

    if (isDnc) {
      return (
        <Tag
          color={ARBOLUS_COLORS.bColorBaseOrange}
          text={isFullTag ? t("doNotContact") : t("dnc")}
          icon="block"
        />
      );
    }

    if (isAdmin && isCaution) {
      return (
        <Tag
          color={ARBOLUS_COLORS.bColorSecondaryOrange}
          text={isFullTag ? t("proceedWithCaution") : t("caution")}
          icon="warning"
        />
      );
    }

    return null;
  };

  const getTooltip = () => {
    if (!showTooltip) {
      return "";
    }

    if (isOk && !skipOk) {
      return contactTooltip ?? t("contactTooltip");
    }

    if (isDnc) {
      return dncTooltip ?? t("dncTooltip");
    }

    if (isCaution) {
      return cautionTooltip ?? t("cautionTooltip");
    }

    return "";
  };

  return (
    <Tooltip title={getTooltip()} placement={placement}>
      <span>{getTag()}</span>
    </Tooltip>
  );
};
