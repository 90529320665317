import { ColDef } from "ag-grid-community";
import i18next from "i18next";

import {
  DO_NOT_CONTACT_STATUS,
  REFERRAL_SUB_STATUS,
  Referral
} from "@arbolus-technologies/models/common";
import {
  ContextMenuRenderer,
  fixedWidth
} from "@arbolus-technologies/ui/components";

import { ApprovedCandidateActionRenderer } from "../../../Components/CellRenderers/ApprovedCandidateActionRenderer";
import { BookSlotRenderer } from "../../../Components/CellRenderers/BookSlotRenderer";
import { CandidateActionRenderer } from "../../../Components/CellRenderers/CandidateActionRenderer";
import { DefaultApproveReferralService } from "../../../Services/Referrals/ApproveReferralService";

const commonFields: ColDef<Referral> = {
  colId: "actions",
  headerName: i18next.t("referrals:headers:action"),
  headerTooltip: i18next.t("referrals:headers:action"),
  cellClass: "d-flex justify-content-center align-items-center",
  cellRenderer: ContextMenuRenderer,
  sortable: false
};

export const actionsCol: ColDef<Referral> = {
  ...commonFields,
  cellRenderer: ContextMenuRenderer,
  ...fixedWidth(85)
};

export const candidateActionsCol: ColDef<Referral> = {
  ...commonFields,
  cellRendererSelector: ({ data }) => ({
    component:
      data?.review === REFERRAL_SUB_STATUS.ACCEPT
        ? ApprovedCandidateActionRenderer
        : CandidateActionRenderer,
    params: { approveReferralService: DefaultApproveReferralService }
  }),
  ...fixedWidth(140)
};

export const SchedulingActionsCol: ColDef<Referral> = {
  ...commonFields,
  cellRendererSelector: ({ data }) => ({
    component:
      data?.expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC
        ? null
        : ContextMenuRenderer,
    params: { approveReferralService: DefaultApproveReferralService }
  }),
  ...fixedWidth(140)
};

export const bookActionsCol: ColDef = {
  ...commonFields,
  cellRenderer: BookSlotRenderer,
  cellClass: "p-0",
  ...fixedWidth(90)
};
