import dompurify from "dompurify";
import React from "react";

import { ReferralNote } from "../../../../../models/project";
import { UtilsService } from "../../../../../services";
import { CIQAvatar } from "../../../../app/components";

interface ReferralNoteProps {
  note: ReferralNote;
}

const ExpertReferralNote: React.FC<ReferralNoteProps> = ({
  note
}: ReferralNoteProps): JSX.Element => {
  const { noteUser } = note;
  const sanitizer = dompurify.sanitize;

  return (
    <div className="referral-note-container">
      <div className="view-container">
        <div
          className="rich-content"
          dangerouslySetInnerHTML={{ __html: sanitizer(note.note) }}
        />

        <div className="note-creator">
          <div className="user-container">
            <CIQAvatar
              username={UtilsService.displayUserName(noteUser)}
              profileImageUrl={noteUser?.profileImageUrl}
            />
            <span className="note-creator-name">
              <b>{UtilsService.displayUserName(noteUser, false)}</b>
              {", "}
              {noteUser?.title}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpertReferralNote;
