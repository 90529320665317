import {
  CIQError,
  Canopy,
  CanopyDeleteRequest,
  CanopyPublic,
  CanopyQuestion,
  CompleteValidatorErrors,
  CreateCanopyDataRequest,
  CreateEditCanopyQuestion,
  EditCanopyDataRequest,
  ErrorResponse
} from "@arbolus-technologies/api";
import {
  ANSWER_TYPE,
  CANOPY_STATUS
} from "@arbolus-technologies/models/canopy";

import {
  BUILDER_RESET,
  CANOPY_CHANGE_STATUS,
  CANOPY_CHANGE_STATUS_FAILURE,
  CANOPY_CHANGE_STATUS_SUCCESS,
  CLEAR_CANOPY,
  CLEAR_CANOPY_ERRORS,
  CLEAR_ERRORS_BUILDER,
  CREATE_CANOPY,
  CREATE_CANOPY_FAILURE,
  CREATE_CANOPY_QUESTION,
  CREATE_CANOPY_QUESTION_FAILURE,
  CREATE_CANOPY_QUESTION_SUCCESS,
  CREATE_CANOPY_SUCCESS,
  CanopyApiQuestions,
  CanopyBuilderResetAction,
  CanopyChangeStatusAction,
  CanopyChangeStatusFailureAction,
  CanopyChangeStatusSuccessAction,
  ClearCanopyAction,
  ClearErrorsBuilderAction,
  ClearErrorsCanopyAction,
  CreateCanopyAction,
  CreateCanopyFailureAction,
  CreateCanopyQuestionAction,
  CreateCanopyQuestionFailureAction,
  CreateCanopyQuestionSuccessAction,
  CreateCanopySuccessAction,
  DELETE_CANOPY,
  DELETE_CANOPY_FAILURE,
  DELETE_CANOPY_QUESTION,
  DELETE_CANOPY_QUESTION_FAILURE,
  DELETE_CANOPY_QUESTION_SUCCESS,
  DELETE_CANOPY_SUCCESS,
  DeleteCanopyAction,
  DeleteCanopyFailureAction,
  DeleteCanopyQuestionAction,
  DeleteCanopyQuestionFailureAction,
  DeleteCanopyQuestionSuccessAction,
  DeleteCanopySuccessAction,
  EDIT_CANOPY,
  EDIT_CANOPY_COMPLETED,
  EDIT_CANOPY_FAILURE,
  EDIT_CANOPY_QUESTION,
  EDIT_CANOPY_QUESTION_FAILURE,
  EDIT_CANOPY_QUESTION_SUCCESS,
  EDIT_CANOPY_SUCCESS,
  EXIT_FROM_CREATE_CANOPY,
  EditCanopyAction,
  EditCanopyCompletedAction,
  EditCanopyFailureAction,
  EditCanopyQuestionAction,
  EditCanopyQuestionFailureAction,
  EditCanopyQuestionSuccessAction,
  EditCanopySuccessAction,
  ExitFromCreateCanopyAction,
  GET_CANOPY_CLIENT,
  GET_CANOPY_CLIENT_FAILURE,
  GET_CANOPY_CLIENT_QUESTIONS,
  GET_CANOPY_CLIENT_QUESTIONS_FAILURE,
  GET_CANOPY_CLIENT_QUESTIONS_SUCCESS,
  GET_CANOPY_CLIENT_SUCCESS,
  GET_PUBLIC_CANOPY,
  GET_PUBLIC_CANOPY_FAILURE,
  GET_PUBLIC_CANOPY_SUCCESS,
  GET_QUESTION,
  GET_QUESTION_FAILURE,
  GET_QUESTION_SUCCESS,
  GetCanopyClientAction,
  GetCanopyClientFailureAction,
  GetCanopyClientQuestionsAction,
  GetCanopyClientQuestionsFailureAction,
  GetCanopyClientQuestionsSuccessAction,
  GetCanopyClientSuccessAction,
  GetPublicCanopyAction,
  GetPublicCanopyFailureAction,
  GetPublicCanopySuccessAction,
  GetQuestionAction,
  GetQuestionFailureAction,
  GetQuestionSuccessAction,
  LAUNCH_SURVEY_GO_BACK,
  LaunchSurveyGoBackAction,
  NOTIFY_EDIT_CANOPY,
  NotifyEditCanopyAction,
  REDIRECT_TO_CANOPY,
  REDIRECT_TO_PROJECT,
  RESET_PANEL,
  RedirectToCanopyAction,
  RedirectToProjectAction,
  ResetPanelAction,
  UPLOAD_DOCUMENTS_TO_CANOPY_FAILED,
  UploadDocumentsToCanopyFailedAction
} from "./CanopyBuilderActionTypes";

// Create canopy
export const createCanopy = (
  canopyDataRequest: CreateCanopyDataRequest,
  canopyDocuments: FormData[]
): CreateCanopyAction => ({
  type: CREATE_CANOPY,
  payload: {
    canopyDataRequest,
    canopyDocuments
  }
});

export const createCanopySuccess = (
  canopyId: string,
  canopyDocuments: FormData[]
): CreateCanopySuccessAction => ({
  type: CREATE_CANOPY_SUCCESS,
  payload: {
    canopyId,
    canopyDocuments
  }
});

export const createCanopyFailure = (
  errors: CompleteValidatorErrors
): CreateCanopyFailureAction => ({
  type: CREATE_CANOPY_FAILURE,
  payload: { errors }
});

// Upload documents to canopy
export const uploadDocumentsToCanopyFailed =
  (): UploadDocumentsToCanopyFailedAction => ({
    type: UPLOAD_DOCUMENTS_TO_CANOPY_FAILED,
    payload: {}
  });

// Edit canopy completed
export const editCanopyCompleted = (
  canopyId: string
): EditCanopyCompletedAction => ({
  type: EDIT_CANOPY_COMPLETED,
  payload: {
    canopyId
  }
});

// Edit canopy
export const editCanopy = (
  canopyDataRequest: EditCanopyDataRequest,
  canopyDocuments: FormData[],
  documentsToRemove: string[]
): EditCanopyAction => ({
  type: EDIT_CANOPY,
  payload: {
    canopyDataRequest,
    canopyDocuments,
    documentsToRemove
  }
});

export const editCanopySuccess = (
  canopyId: string,
  canopyDocuments: FormData[],
  documentsToRemove: string[]
): EditCanopySuccessAction => ({
  type: EDIT_CANOPY_SUCCESS,
  payload: {
    canopyId,
    canopyDocuments,
    documentsToRemove
  }
});

export const editCanopyFailure = (
  error: CompleteValidatorErrors | ErrorResponse<CIQError>
): EditCanopyFailureAction => ({
  type: EDIT_CANOPY_FAILURE,
  payload: { error }
});

// Redirect to canopy
export const redirectToCanopy = (
  canopyId: string,
  uploadSuccess: boolean
): RedirectToCanopyAction => ({
  type: REDIRECT_TO_CANOPY,
  payload: {
    canopyId,
    uploadSuccess
  }
});

// Redirect to project
export const redirectToProject = (
  projectId: string
): RedirectToProjectAction => ({
  type: REDIRECT_TO_PROJECT,
  payload: {
    projectId
  }
});

// Notify Edit canopy
export const notifyEditCanopy = (canopyId: string): NotifyEditCanopyAction => ({
  type: NOTIFY_EDIT_CANOPY,
  payload: {
    canopyId
  }
});

// Exit from create canopy
export const exitFromCreateCanopy = (): ExitFromCreateCanopyAction => ({
  type: EXIT_FROM_CREATE_CANOPY
});

// Get survey list Client

export const getCanopyClientQuestions = (
  canopyId: string
): GetCanopyClientQuestionsAction => ({
  type: GET_CANOPY_CLIENT_QUESTIONS,
  payload: {
    canopyId
  }
});

export const getCanopyClientQuestionsSuccess = (
  canopyQuestions: CanopyApiQuestions[]
): GetCanopyClientQuestionsSuccessAction => ({
  type: GET_CANOPY_CLIENT_QUESTIONS_SUCCESS,
  payload: {
    canopyQuestions
  }
});

export const getCanopyClientQuestionsFailure = (
  error: ErrorResponse<CIQError>
): GetCanopyClientQuestionsFailureAction => ({
  type: GET_CANOPY_CLIENT_QUESTIONS_FAILURE,
  payload: { error }
});

// Get Canopy by Client
export const getCanopyClient = (canopyId: string): GetCanopyClientAction => ({
  type: GET_CANOPY_CLIENT,
  payload: {
    canopyId
  }
});

export const getCanopyClientSuccess = (
  canopyData: Canopy
): GetCanopyClientSuccessAction => ({
  type: GET_CANOPY_CLIENT_SUCCESS,
  payload: { canopyData }
});

export const getCanopyClientFailure = (
  error: ErrorResponse<CIQError>
): GetCanopyClientFailureAction => ({
  type: GET_CANOPY_CLIENT_FAILURE,
  payload: { error }
});

// Get Public Canopy
export const getPublicCanopy = (canopyId: string): GetPublicCanopyAction => ({
  type: GET_PUBLIC_CANOPY,
  payload: {
    canopyId
  }
});

export const getPublicCanopySuccess = (
  canopyPublicData: CanopyPublic
): GetPublicCanopySuccessAction => ({
  type: GET_PUBLIC_CANOPY_SUCCESS,
  payload: { canopyPublicData }
});

export const getPublicCanopyFailure = (
  error: ErrorResponse<CIQError>
): GetPublicCanopyFailureAction => ({
  type: GET_PUBLIC_CANOPY_FAILURE,
  payload: { error }
});

// Delete Canopy
export const deleteCanopy = (
  canopyData: CanopyDeleteRequest,
  projectId: string
): DeleteCanopyAction => ({
  type: DELETE_CANOPY,
  payload: {
    canopyData,
    projectId
  }
});

// Clear Selected Canopy
export const clearSelectedCanopy = (): ClearCanopyAction => ({
  type: CLEAR_CANOPY
});

export const clearErrorsCanopy = (): ClearErrorsCanopyAction => ({
  type: CLEAR_CANOPY_ERRORS
});

export const deleteCanopySuccess = (
  canopyId: string
): DeleteCanopySuccessAction => ({
  type: DELETE_CANOPY_SUCCESS,
  payload: { canopyId }
});

export const deleteCanopyFailure = (
  error: ErrorResponse<CIQError>
): DeleteCanopyFailureAction => ({
  type: DELETE_CANOPY_FAILURE,
  payload: { error }
});

// Change canopy status to Active
export const canopyChangeStatus = (
  canopyId: string,
  status: CANOPY_STATUS
): CanopyChangeStatusAction => ({
  type: CANOPY_CHANGE_STATUS,
  payload: {
    canopyId,
    status
  }
});

export const canopyChangeStatusSuccess =
  (): CanopyChangeStatusSuccessAction => ({
    type: CANOPY_CHANGE_STATUS_SUCCESS
  });

export const canopyChangeStatusFailure = (
  error: ErrorResponse<CIQError> | CompleteValidatorErrors
): CanopyChangeStatusFailureAction => ({
  type: CANOPY_CHANGE_STATUS_FAILURE,
  payload: { error }
});

// Create Canopy Question

export const createCanopyQuestion = (
  createData: CreateEditCanopyQuestion
): CreateCanopyQuestionAction => ({
  type: CREATE_CANOPY_QUESTION,
  payload: createData
});

export const createCanopyQuestionSuccess = (
  newId: string,
  text: string,
  details: string,
  type: ANSWER_TYPE,
  order?: number
): CreateCanopyQuestionSuccessAction => ({
  type: CREATE_CANOPY_QUESTION_SUCCESS,
  payload: {
    newId,
    text,
    details,
    type,
    order
  }
});

export const createCanopyQuestionFailure = (
  error: ErrorResponse<CIQError> | CompleteValidatorErrors
): CreateCanopyQuestionFailureAction => ({
  type: CREATE_CANOPY_QUESTION_FAILURE,
  payload: { error }
});

// Get Question
export const getQuestion = (questionId: string): GetQuestionAction => ({
  type: GET_QUESTION,
  payload: {
    questionId
  }
});

export const getQuestionSuccess = (
  question: CanopyQuestion
): GetQuestionSuccessAction => ({
  type: GET_QUESTION_SUCCESS,
  payload: {
    question
  }
});

export const getQuestionFailure = (
  error: ErrorResponse<CIQError>
): GetQuestionFailureAction => ({
  type: GET_QUESTION_FAILURE,
  payload: { error }
});

// Edit Canopy Question

export const editCanopyQuestion = (
  editData: CreateEditCanopyQuestion
): EditCanopyQuestionAction => ({
  type: EDIT_CANOPY_QUESTION,
  payload: editData
});

export const editCanopyQuestionSuccess = (
  question: string,
  questionId: string,
  details: string,
  type: ANSWER_TYPE
): EditCanopyQuestionSuccessAction => ({
  type: EDIT_CANOPY_QUESTION_SUCCESS,
  payload: {
    question,
    questionId,
    details,
    type
  }
});

export const editCanopyQuestionFailure = (
  error: ErrorResponse<CIQError>
): EditCanopyQuestionFailureAction => ({
  type: EDIT_CANOPY_QUESTION_FAILURE,
  payload: {
    error
  }
});

// Delete Canopy Question

export const deleteCanopyQuestion = (
  questionId: string,
  canopyId: string
): DeleteCanopyQuestionAction => ({
  type: DELETE_CANOPY_QUESTION,
  payload: {
    questionId,
    canopyId
  }
});

export const deleteCanopyQuestionSuccess = (
  questionId: string
): DeleteCanopyQuestionSuccessAction => ({
  type: DELETE_CANOPY_QUESTION_SUCCESS,
  payload: {
    questionId
  }
});

export const deleteCanopyQuestionFailure = (
  error: ErrorResponse<CIQError>
): DeleteCanopyQuestionFailureAction => ({
  type: DELETE_CANOPY_QUESTION_FAILURE,
  payload: {
    error
  }
});

// ResetPanelAction

export const resetPanel = (): ResetPanelAction => ({
  type: RESET_PANEL
});

export const launchSurveyGoBack = (
  canopyId: string
): LaunchSurveyGoBackAction => ({
  type: LAUNCH_SURVEY_GO_BACK,
  payload: {
    canopyId
  }
});

// CLEAR ERRORS BUILDER

export const clearErrorsBuilder = (): ClearErrorsBuilderAction => ({
  type: CLEAR_ERRORS_BUILDER
});

// CANOPY BUILDER RESET

export const canopyBuilderReset = (): CanopyBuilderResetAction => ({
  type: BUILDER_RESET
});

export const CanopyBuilderStoreActions = {
  createCanopy,
  createCanopySuccess,
  createCanopyFailure,
  uploadDocumentsToCanopyFailed,
  editCanopyCompleted,
  editCanopy,
  editCanopySuccess,
  editCanopyFailure,
  redirectToCanopy,
  redirectToProject,
  notifyEditCanopy,
  exitFromCreateCanopy,
  getCanopyClientQuestions,
  getCanopyClientQuestionsSuccess,
  getCanopyClientQuestionsFailure,
  getCanopyClient,
  getCanopyClientSuccess,
  getCanopyClientFailure,
  getPublicCanopy,
  getPublicCanopySuccess,
  getPublicCanopyFailure,
  deleteCanopy,
  clearSelectedCanopy,
  clearErrorsCanopy,
  deleteCanopySuccess,
  deleteCanopyFailure,
  canopyChangeStatus,
  canopyChangeStatusSuccess,
  canopyChangeStatusFailure,
  createCanopyQuestion,
  createCanopyQuestionSuccess,
  createCanopyQuestionFailure,
  getQuestion,
  getQuestionSuccess,
  getQuestionFailure,
  editCanopyQuestion,
  editCanopyQuestionSuccess,
  editCanopyQuestionFailure,
  deleteCanopyQuestion,
  deleteCanopyQuestionSuccess,
  deleteCanopyQuestionFailure,
  resetPanel,
  launchSurveyGoBack,
  clearErrorsBuilder,
  canopyBuilderReset
};
