import { Epic } from "redux-observable";
import { Observable, of } from "rxjs";
import { catchError, filter, flatMap, switchMap } from "rxjs/operators";
import { isOfType } from "typesafe-actions";

import {
  BOOKMARK_COLLECTION_ORDER_BY,
  BookmarkService,
  CIQError,
  ErrorResponse,
  ListBookmarkCollectionsQueryParams,
  SORT_DIRECTION
} from "@arbolus-technologies/api";

import { BookmarkCollectionsStoreActions } from "../BookmarkCollectionsActions";
import {
  LIST_COLLECTIONS,
  LIST_COLLECTIONS_RELOAD,
  ListCollectionsFailureAction,
  ListCollectionsSuccessAction
} from "../actions/ListCollections/ListCollectionsActionTypes";
import {
  BookmarkCollectionsAppState,
  BookmarkCollectionsDependencies,
  BookmarkCollectionsStoreAction,
  INotificationService
} from "../models/definitions";

const listCollections = (
  bookmarkService: typeof BookmarkService,
  notificationService: INotificationService,
  queryParams: ListBookmarkCollectionsQueryParams,
  searchTerm?: string
): Observable<ListCollectionsSuccessAction | ListCollectionsFailureAction> =>
  bookmarkService.listBookmarkCollections({ ...queryParams, searchTerm }).pipe(
    flatMap((paginatedResponse) =>
      of(
        BookmarkCollectionsStoreActions.listCollectionsSuccess(
          paginatedResponse
        )
      )
    ),
    catchError((error: ErrorResponse<CIQError>) => {
      notificationService.showApiErrors(error);
      return of(BookmarkCollectionsStoreActions.listCollectionsFailure(error));
    })
  );

export const listCollectionsEpic: Epic<
  BookmarkCollectionsStoreAction,
  BookmarkCollectionsStoreAction,
  BookmarkCollectionsAppState,
  BookmarkCollectionsDependencies
> = (action$, _, { notificationService, bookmarkService }) =>
  action$.pipe(
    filter(isOfType(LIST_COLLECTIONS)),
    switchMap(({ payload: { queryParams, searchTerm } }) =>
      listCollections(
        bookmarkService,
        notificationService,
        queryParams,
        searchTerm
      )
    )
  );

export const reloadCollectionsEpic: Epic<
  BookmarkCollectionsStoreAction,
  BookmarkCollectionsStoreAction,
  BookmarkCollectionsAppState,
  BookmarkCollectionsDependencies
> = (action$, state$, { notificationService, bookmarkService }) =>
  action$.pipe(
    filter(isOfType(LIST_COLLECTIONS_RELOAD)),
    filter(() => !!state$.value.bookmarkCollections.collections.store),
    switchMap(() =>
      listCollections(
        bookmarkService,
        notificationService,
        {
          limit: Math.min(
            15,
            state$.value.bookmarkCollections.collections.store!.total + 1
          ),
          offset: 0,
          orderBy: BOOKMARK_COLLECTION_ORDER_BY.CREATED,
          orderDirection: SORT_DIRECTION.ASCENDING
        },
        state$.value.bookmarkCollections.collections.searchTerm
      )
    )
  );
