import React from "react";
import { useTranslation } from "react-i18next";
import { RequestDataButton } from "../RequestDataButton";
import styles from "./ChromeExtensionProjectBanner.module.scss";

export const ChromeExtensionProjectBanner: React.FC = () => {
  const { t } = useTranslation("chromeExtensionBanner");

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{t("title")}</h2>
      <RequestDataButton text={t("requestDataProject")} type={"primary"} />
    </div>
  );
};
