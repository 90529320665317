import { FormInterface } from "./NewEndorsement";

export interface DataToSend {
  name: string;
  email: string;
}

export const prepareToSend = (data: FormInterface): DataToSend[] => {
  const { name1, email1, name2, name3, email2, email3 } = data;
  const res = [
    {
      name: name1,
      email: email1
    },
    {
      name: name2,
      email: email2
    },
    {
      name: name3,
      email: email3
    }
  ];
  return res.filter((o) => o.name !== "" || o.email !== "");
};
