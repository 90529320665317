import { useEffect } from "react";

import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";

interface EventPageTrackComponentProps {
  isEventCreated: boolean;
  from: string | undefined;
}

export const EventPageTrackComponent: React.FC<
  EventPageTrackComponentProps
> = ({ isEventCreated, from }) => {
  const { trackSubmit } = useArbolusTracking();

  useEffect(() => {
    if (isEventCreated) {
      trackSubmit(MixPanelEventNames.CreateEvent, { from });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEventCreated]);

  return null;
};
