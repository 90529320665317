import { useContext } from "react";

import {
  ContentPanelContext,
  ContentPanelContextProps
} from "./ContentPanelContext";

export default function useContentPanelContext(): ContentPanelContextProps {
  const context = useContext(ContentPanelContext);
  if (!context) {
    throw new Error(
      "useContentPanelContext must be used within a ContentPanelContextProvider"
    );
  }
  return context;
}
