import {
  FilterModel,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest
} from "ag-grid-community";

import {
  LIST_PROJECTS_ORDER_BY,
  ListProjectDraftsRequest,
  ListProjectsRequest,
  ProjectService,
  SORT_DIRECTION
} from "@arbolus-technologies/api";
import { ProjectModel } from "@arbolus-technologies/models/common";

export class ProjectDraftsDataSource implements IServerSideDatasource {
  private readonly _projectService: typeof ProjectService;

  constructor(projectService: typeof ProjectService) {
    this._projectService = projectService;
  }

  getRows(params: IServerSideGetRowsParams<ProjectModel, never>): void {
    const { success, fail, request } = params;

    const queryParams = this.getQueryParams(request);

    this._projectService.listProjectDrafts(queryParams).subscribe({
      next: (response) => {
        success({
          rowData: response.items,
          rowCount: response.pagination.count
        });
      },
      error: () => fail()
    });
  }

  private getQueryParams(
    request: IServerSideGetRowsRequest
  ): ListProjectsRequest {
    const { startRow, endRow, sortModel } = request;
    const filterModel = request.filterModel as FilterModel;

    const queryParams: ListProjectDraftsRequest = {
      limit: endRow! - startRow!,
      offset: startRow!,
      sort:
        sortModel?.length > 0
          ? sortModel.map(({ colId, sort }) => ({
              orderBy: colId as LIST_PROJECTS_ORDER_BY,
              orderDirection: sort as SORT_DIRECTION
            }))
          : []
    };

    if (filterModel[LIST_PROJECTS_ORDER_BY.NAME]) {
      queryParams.projectName =
        filterModel[LIST_PROJECTS_ORDER_BY.NAME].filter || "";
    }

    if (filterModel[LIST_PROJECTS_ORDER_BY.CLIENT_NAME]) {
      queryParams.clientName =
        filterModel[LIST_PROJECTS_ORDER_BY.CLIENT_NAME].filter || "";
    }

    queryParams.inProject = !filterModel[LIST_PROJECTS_ORDER_BY.IN_PROJECT];

    return queryParams;
  }
}
