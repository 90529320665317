import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  ProjectAnglesSelector,
  ProjectAnglesStoreActions
} from "@arbolus-technologies/stores/project-angles-store";
import { HR, Tabs } from "@arbolus-technologies/ui/components";

import { AngleDetails } from "./AngleDetails";
import { AngleScreeningQuestions } from "./AngleScreeningQuestions";

import styles from "./ViewEditAngle.module.scss";

const DETAILS = "details";
const SCREENING_QUESTIONS = "screeningQuestions";

interface ViewEditAngleProps {
  isAdmin?: boolean;
  isEdit?: boolean;
  title?: string;
  projectId: string;
  handleCloseIcon: () => void;
}

export const ViewEditAngle: React.FC<ViewEditAngleProps> = ({
  isAdmin = false,
  isEdit = false,
  title,
  projectId,
  handleCloseIcon
}) => {
  const { t } = useTranslation("viewEditAngle");
  const dispatch = useDispatch();
  const selectedAngle = useSelector(ProjectAnglesSelector.selectedAngle());
  const { hasScreeningQuestions } = selectedAngle;

  useEffect(() => {
    if (hasScreeningQuestions) {
      dispatch(ProjectAnglesStoreActions.addScreeningQuestionsView());
    } else {
      dispatch(ProjectAnglesStoreActions.noScreeningQuestionsView());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs = useMemo(
    () => ({
      [DETAILS]: {
        title: t("details"),
        component: () => (
          <AngleDetails
            isAdmin={isAdmin}
            isEdit={isEdit}
            projectId={projectId}
          />
        )
      },
      [SCREENING_QUESTIONS]: {
        title: t("screeningQuestions"),
        component: () => (
          <AngleScreeningQuestions isAdmin={isAdmin} isEdit={isEdit} />
        )
      }
    }),
    [isAdmin, isEdit, projectId, t]
  );

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        {title && <h2>{title}</h2>}
        <div className={clsx(styles.closeContainer)} onClick={handleCloseIcon}>
          <Icon name="close" fontSize="24px" />
        </div>
      </div>
      <HR />
      <Tabs tabMapping={tabs} />
    </div>
  );
};
