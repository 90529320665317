import clsx from "clsx";
import React from "react";

import { ColoredBadge } from "@arbolus-technologies/ui/components";

import styles from "./ExpertChatListItem.module.scss";

interface ExpertChatListItemProps {
  name: string;
  imageUrl: string;
  isActive?: boolean;
  unreadCount: number;
  onClick: () => void;
}

export const ExpertChatListItem: React.FC<ExpertChatListItemProps> = ({
  name,
  imageUrl,
  isActive,
  unreadCount,
  onClick
}) => (
  <div className={styles.chatListItem} onClick={onClick} role="button">
    <div className={clsx(styles.content, isActive && styles.activeItem)}>
      <div className={styles.avatarContainer}>
        <img
          alt={name}
          src={imageUrl}
          className={clsx(imageUrl.endsWith(".svg") && styles.svg)}
        />
      </div>
      <span className={styles.name}>{name}</span>
      {unreadCount > 0 && (
        <ColoredBadge text={`${unreadCount}`} backgroundColor="orange" />
      )}
    </div>
  </div>
);
