import { ICellRendererParams } from "ag-grid-community";
import React from "react";

import { ProjectModel } from "@arbolus-technologies/models/common";
import { Avatar } from "@arbolus-technologies/ui/components";
import { displayUserName } from "@arbolus-technologies/utils";

import styles from "./ContactRenderer.module.scss";

export const ContactRenderer: React.FC<
  ICellRendererParams<ProjectModel, never, never>
> = ({ data }) => {
  if (!data?.arbolusContact) {
    return null;
  }

  const fullName = displayUserName(data.arbolusContact);

  return (
    <div className={styles.container}>
      <Avatar
        avatar={{
          name: fullName,
          imageUrl: data!.arbolusContact!.profileImageUrl
        }}
      />
      <span>{fullName}</span>
    </div>
  );
};
