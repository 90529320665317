import i18next from "i18next";
import * as Yup from "yup";

import { CanopyConstraints } from "@arbolus-technologies/models/canopy";

import {
  ICanopyCreationType,
  ISimpleCanopyCreationForm
} from "./SimpleCanopyCreationProvider";

export const SimpleCreationCanopySchema: Yup.ObjectSchema<ISimpleCanopyCreationForm> =
  Yup.object().shape({
    title: Yup.string()
      .required(i18next.t("SimpleCanopyCreationProvider:titleError"))
      .max(
        CanopyConstraints.MAX_CANOPY_TITLE_LENGTH,
        i18next.t("SimpleCanopyCreationProvider:titleLengthError", {
          length: CanopyConstraints.MAX_CANOPY_TITLE_LENGTH
        })
      ),
    brief: Yup.string()
      .required(i18next.t("SimpleCanopyCreationProvider:descriptionError"))
      .max(
        CanopyConstraints.MAX_CANOPY_OVERVIEW_LENGTH,
        i18next.t("SimpleCanopyCreationProvider:descriptionLengthError", {
          length: CanopyConstraints.MAX_CANOPY_OVERVIEW_LENGTH
        })
      ),
    canopyCreationType: Yup.object().shape({
      canopyType: Yup.string<ICanopyCreationType>().required(
        i18next.t("SimpleCanopyCreationProvider:detailsError")
      ),
      canopyTemplate: Yup.string().when("canopyType", {
        is: (val) => val === ICanopyCreationType.TEMPLATE,
        then: Yup.string().required(
          i18next.t("businessEntityForm:maxMarkupRequired")
        )
      })
    })
  });
