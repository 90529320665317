import { LocationDescriptorObject } from "history";
import { useTracking } from "react-tracking";

import {
  DISABLE_MIXPANEL,
  locationObjectToString
} from "@arbolus-technologies/utils";

import { MixPanelActions, MixPanelEvent } from "./enums";
import { MixPanelProperties } from "./types";

export const useArbolusTracking = (): {
  trackClick: (
    eventName: MixPanelEvent,
    properties?: MixPanelProperties
  ) => void;
  trackSubmit: (
    eventName: MixPanelEvent,
    properties?: MixPanelProperties
  ) => void;
  trackRequestFailed: (
    eventName: MixPanelEvent,
    properties?: MixPanelProperties
  ) => void;
  trackRedirect: (
    eventName: MixPanelEvent,
    to: LocationDescriptorObject | string,
    properties?: MixPanelProperties
  ) => void;
  trackRender: (
    eventName: MixPanelEvent,
    properties?: MixPanelProperties
  ) => void;
  trackUnMount: (
    eventName: MixPanelEvent,
    properties?: MixPanelProperties
  ) => void;
  trackSlidePanel: (
    eventName: MixPanelEvent,
    properties?: MixPanelProperties
  ) => void;
  trackUpdated: (
    eventName: MixPanelEvent,
    properties?: MixPanelProperties
  ) => void;
  trackFiltered: (
    eventName: MixPanelEvent,
    properties?: MixPanelProperties
  ) => void;
} => {
  const { trackEvent } = useTracking();

  return {
    trackClick: (eventName, properties?: MixPanelProperties) =>
      track(eventName, MixPanelActions.Clicked, trackEvent, properties),
    trackSubmit: (eventName, properties?: MixPanelProperties) =>
      track(eventName, MixPanelActions.FormSubmitted, trackEvent, properties),
    trackRequestFailed: (eventName, properties?: MixPanelProperties) =>
      track(eventName, MixPanelActions.RequestFailed, trackEvent, properties),
    trackRedirect: (eventName, to, properties?: MixPanelProperties) =>
      track(eventName, MixPanelActions.Redirect, trackEvent, {
        ...properties,
        to: UrlToString(to)
      }),
    trackRender: (eventName, properties?: MixPanelProperties) => {
      track(eventName, MixPanelActions.Rendered, trackEvent, properties);
    },
    trackUnMount: (eventName, properties?: MixPanelProperties) => {
      track(eventName, MixPanelActions.UnMounted, trackEvent, properties);
    },
    trackSlidePanel: (eventName, properties?: MixPanelProperties) => {
      track(
        eventName,
        MixPanelActions.SlidePanelLoaded,
        trackEvent,
        properties
      );
    },
    trackUpdated: (eventName, properties?: MixPanelProperties) => {
      track(eventName, MixPanelActions.Updated, trackEvent, properties);
    },
    trackFiltered: (eventName, properties?: MixPanelProperties) => {
      track(eventName, MixPanelActions.Filtered, trackEvent, properties);
    }
  };
};

const track = (
  eventName: MixPanelEvent,
  action: MixPanelActions,
  trackEvent: (data: Partial<{}>) => void,
  properties?: MixPanelProperties
): void => {
  if (DISABLE_MIXPANEL) return;

  trackEvent({
    eventName,
    dispatchOnMount: false,
    properties: {
      ...properties,
      action
    }
  });
};

const UrlToString = (to: LocationDescriptorObject | string): string =>
  typeof to === "string" ? to : locationObjectToString(to);
