import dompurify from "dompurify";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ProjectAnswer } from "@arbolus-technologies/api";
import { InputController } from "@arbolus-technologies/ui/components";

import { ScreeningQuestionsFormValues } from "../ScreeningQuestionsSchema";

import styles from "./ScreeningQuestion.module.scss";

interface ScreeningQuestionProps {
  screeningQuestion: ProjectAnswer;
  index: number;
}

export const ScreeningQuestion: React.FC<ScreeningQuestionProps> = ({
  screeningQuestion,
  index
}) => {
  const { t } = useTranslation("expertApplications");

  const { question } = screeningQuestion;

  const {
    control,
    formState: { errors }
  } = useFormContext<ScreeningQuestionsFormValues>();

  return (
    <div className={styles.container}>
      <div dangerouslySetInnerHTML={{ __html: dompurify.sanitize(question) }} />
      <InputController
        name={`questions[${index}].answer`}
        error={errors.questions?.[index]?.answer}
        control={control}
        placeholder={t("yesNoTextPlaceholder")}
        type="textarea"
        size="big"
      />
    </div>
  );
};
