import { Observable } from "rxjs";

import { DISCOVER_API, MAX_PAGE_SIZE } from "../constants/api";
import { DiscoverExpertsFilter } from "../interfaces/discoverExpertsFilter";
import { ApiPaginatedResponseExperts } from "../models/api";
import { Expert } from "../models/expert";
import { restService } from "../restService";

export const DiscoverService = {
  getFindExperts: (
    filter: DiscoverExpertsFilter,
    offset = 0,
    limit = MAX_PAGE_SIZE,
    orderBy = "",
    orderDirection = ""
  ): Observable<ApiPaginatedResponseExperts<Expert>> =>
    restService.post<ApiPaginatedResponseExperts<Expert>>(
      DISCOVER_API.GET_DISCOVER_EXPERTS(),
      {
        offset,
        limit,
        orderBy,
        orderDirection,
        searchTerm: filter.query,
        countries: filter.countries.map((c) => c.threeLetterCode),
        industries: filter.industries.map((i) => i.id),
        companies: filter.companies.map((c) => c.id)
      }
    )
};
