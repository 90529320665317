import { Packer } from "docx";
import saveAs from "file-saver";
import i18next from "i18next";
import moment from "moment";

import {
  DefaultToasterService,
  Monologue,
  Speaker,
  ToasterService,
  TranscriptMetaState
} from "@arbolus-technologies/api";
import { APP_DATE_FORMAT } from "@arbolus-technologies/utils";

import {
  DefaultTranscriptCreator,
  TranscriptCreator
} from "./TranscriptCreator";

export class TranscriptToBlob {
  private readonly _notificationService: ToasterService;
  private readonly _transcriptCreator: TranscriptCreator;

  constructor(
    notificationService: ToasterService,
    transcriptCreator: TranscriptCreator
  ) {
    this._notificationService = notificationService;
    this._transcriptCreator = transcriptCreator;
  }

  public downloadTranscript(
    transcriptMeta: TranscriptMetaState,
    monologues: Monologue[],
    speakersMap?: Map<string, Speaker>
  ): void {
    const { created, durationSeconds, title, transcriptSpeakerCount } =
      transcriptMeta;

    const meetingData = monologues.map((m) => ({
      speakerName: speakersMap?.get(m.speakerId)?.name || "Speaker",
      monologue: m.text
    }));

    const meetingDetails = this.generateEventDetails(
      created,
      durationSeconds,
      transcriptSpeakerCount
    );

    const document = this._transcriptCreator.create(
      meetingData,
      title,
      meetingDetails
    );

    Packer.toBlob(document)
      .then((blob) => {
        saveAs(blob, this.DOWNLOAD_FILE_NAME(title));
        this._notificationService.showSuccess(
          i18next.t("transcript:downloadSuccess")
        );
      })
      .catch(() => {
        this._notificationService.showError(
          i18next.t("transcript:downloadFail")
        );
      });
  }

  private generateEventDetails = (
    created: Date,
    durationSeconds: number,
    speakersCount: number
  ): string => {
    const activeZone = moment.utc(created).clone().local().toDate();
    const eventDate = moment(activeZone).format(APP_DATE_FORMAT);

    const duration = moment.utc(durationSeconds * 1000).format("H:mm:ss");

    return `${eventDate} • ${duration} • ${i18next.t(
      "transcript:participantCount",
      { count: speakersCount }
    )}`;
  };

  private DOWNLOAD_FILE_NAME = (title: string): string => `${title}.docx`;
}

export const DefaultTranscriptToBlob = new TranscriptToBlob(
  DefaultToasterService,
  DefaultTranscriptCreator
);
