import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { EXPERT_CANOPY_STATUS } from "@arbolus-technologies/models/canopy";
import { COMPLIANCE_STEPS } from "@arbolus-technologies/models/canopy-panels";
import { ComplianceBox } from "@arbolus-technologies/ui/canopy-panels";

export interface ExpertComplianceProps {
  expertCanopyStatus: EXPERT_CANOPY_STATUS;
  disabled: boolean;
  handleClickConfirm: (status: COMPLIANCE_STEPS) => void;
}

const COMPLIANCE_STATUS = {
  [EXPERT_CANOPY_STATUS.IN_REVIEW]: COMPLIANCE_STEPS.REVIEW,
  [EXPERT_CANOPY_STATUS.COMPLETE]: COMPLIANCE_STEPS.APPROVED,
  [EXPERT_CANOPY_STATUS.REJECTED]: COMPLIANCE_STEPS.REJECTED,
  [EXPERT_CANOPY_STATUS.INCOMPLETE]: COMPLIANCE_STEPS.REVIEW
};

export const ExpertCompliance: React.FC<ExpertComplianceProps> = ({
  expertCanopyStatus,
  disabled,
  handleClickConfirm
}) => {
  const { t } = useTranslation("canopyPanels");

  const [complianceStatus, setComplianceStatus] = useState<COMPLIANCE_STEPS>(
    COMPLIANCE_STEPS.REVIEW
  );

  useEffect(() => {
    setComplianceStatus(COMPLIANCE_STATUS[expertCanopyStatus]);
  }, [expertCanopyStatus]);

  const handleNext = (status: COMPLIANCE_STEPS) => {
    if (
      status === COMPLIANCE_STEPS.APPROVED ||
      status === COMPLIANCE_STEPS.REJECTED
    ) {
      handleClickConfirm(status);
    } else {
      setComplianceStatus(status);
    }
  };

  return (
    <ComplianceBox
      status={complianceStatus}
      title={t(`${complianceStatus}Title`)}
      subtitle={t(`${complianceStatus}Subtitle`)}
      disabled={disabled}
      handleNext={handleNext}
    />
  );
};
