import { useEffect, useState } from "react";
import { zip } from "rxjs";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ProjectService
} from "@arbolus-technologies/api";

export function useGetChatList(
  projectId: string,
  handleGetChatListSuccess: Function
): { isLoading: boolean } {
  const [isLoading, setIsLoading] = useState(true);

  const getChatList = () => {
    setIsLoading(true);
    zip(
      ProjectService.getChatList(projectId),
      ProjectService.getUnreadMessagesCount(projectId)
    ).subscribe(
      ([chatList, unreadMessagesCountList]) => {
        handleGetChatListSuccess(chatList, unreadMessagesCountList);
        setIsLoading(false);
      },
      (error: ErrorResponse<CIQError>) => {
        handleGetChatListError(error);
      }
    );
  };

  const handleGetChatListError = (error: ErrorResponse<CIQError>) => {
    DefaultToasterService.showApiErrors(error);
    setIsLoading(false);
  };

  useEffect(() => {
    getChatList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return { isLoading };
}
