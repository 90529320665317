import { Button } from "arbolus-ui-components";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { zip } from "rxjs";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  SORT_DIRECTION,
  ToasterService
} from "@arbolus-technologies/api";
import { ALLOWED_FILE_TYPES } from "@arbolus-technologies/models/documents";
import {
  ProjectNxSelector,
  ProjectNxStoreActions
} from "@arbolus-technologies/stores/project";
import { Loader } from "@arbolus-technologies/ui/components";

import { useChat } from "../../../../../../Contexts/ChatContext/ChatContext";
import {
  handleCheckFiles,
  uploadFileServiceByRole
} from "./FilesListButtonsUtils";

import styles from "./FilesListButtons.module.scss";

interface FilesListButtonsProps {
  projectId: string;
  notificationService?: ToasterService;
}
export const FilesListButtons: React.FC<FilesListButtonsProps> = ({
  projectId,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("chat");
  const { currentChat, chatSenderRole } = useChat();
  const dispatch = useDispatch();
  const acceptFileTypes = ALLOWED_FILE_TYPES.map((ext) => `.${ext}`).join(",");
  const selectedFilesToShare = useSelector(
    ProjectNxSelector.selectedFilesToShare()
  );
  const isSharingFiles = useSelector(ProjectNxSelector.isSharingFiles());
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);

  const handleFiles = useCallback(
    (event: Event) => {
      const changeEvent = event as unknown as ChangeEvent<HTMLInputElement>;
      const validFiles: File[] = handleCheckFiles(
        changeEvent.target.files,
        fileInputRef
      );
      const fileServicesBatch = validFiles.map((file) => {
        const formData = new FormData();
        formData.append("file", file);
        const uploadService = uploadFileServiceByRole[chatSenderRole];
        return uploadService(projectId, formData);
      });
      setIsUploadingFiles(true);
      zip(...fileServicesBatch).subscribe(
        (uploadedFiles) => {
          setIsUploadingFiles(false);
          dispatch(ProjectNxStoreActions.resetChatFiles());
          dispatch(
            ProjectNxStoreActions.getChatFiles(
              projectId,
              {
                searchTerm: "",
                limit: 20,
                offset: 0,
                orderBy: "Created",
                orderDirection: SORT_DIRECTION.DESCENDING
              },
              chatSenderRole
            )
          );
          // Immediately select the newly uploaded files
          for (const file of uploadedFiles) {
            dispatch(ProjectNxStoreActions.selectFileToShare(file.id));
          }
        },
        (error: ErrorResponse<CIQError>) => {
          setIsUploadingFiles(false);
          notificationService.showError(error.message);
        }
      );
    },
    [chatSenderRole, dispatch, notificationService, projectId]
  );

  useEffect(() => {
    const inputRef = fileInputRef.current;
    inputRef?.addEventListener("change", handleFiles);
    return () => {
      inputRef?.removeEventListener("change", handleFiles);
    };
  }, [handleFiles]);

  const handleShareFiles = () => {
    if (currentChat) {
      dispatch(
        ProjectNxStoreActions.shareChatFiles(
          projectId,
          currentChat?.id,
          selectedFilesToShare
        )
      );
    }
  };

  return (
    <div className={styles.buttonsContainer}>
      {isUploadingFiles ? (
        <div className={styles.uploadLoader}>
          <Loader />
        </div>
      ) : (
        <Button
          text={t("upload")}
          type="secondary"
          endIcon="upload"
          onClick={() => fileInputRef.current?.click()}
        />
      )}

      <input
        type="file"
        id="uploadFile"
        name="uploadFile"
        multiple
        accept={acceptFileTypes}
        ref={fileInputRef}
        style={{ display: "none" }}
      />
      {isSharingFiles ? (
        <div className={styles.shareLoader}>
          <Loader />
        </div>
      ) : (
        <Button
          text={t("send")}
          type="primary"
          endIcon="chevron_right"
          onClick={handleShareFiles}
          disabled={selectedFilesToShare.length === 0}
        />
      )}
    </div>
  );
};
