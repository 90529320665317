import React from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import {
  CustomerCompanyInfo,
  DiscoverExpertStats,
  KEYWORDS_SEARCH_OPTIONS
} from "@arbolus-technologies/models/project";
import { EXPERT_PROFILE } from "@arbolus-technologies/routes";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { ArbolusAvatar } from "@arbolus-technologies/ui/components";

import { DiscoverMatchingFiltersPanelData } from "../../../DiscoverMatchingFiltersPanel/interfaces";
import { ExpertStats } from "./ExpertStats";

import styles from "./DiscoverExpertInfo.module.scss";

interface DiscoverExpertInfoProps {
  expertId: string;
  fullName: string;
  countryName: string | null;
  countryTwoLetterCode: string | null;
  stats: DiscoverExpertStats;
  title: string | null;
  profileImageUrl: string | null;
  lastPublicCompanyDate: string | null;
  projectId: string;
  projectName: string;
  previousProjects: string[];
  isOnboarded: boolean;
  searchMatchColumns: string[];
  doNotContactStatus: DO_NOT_CONTACT_STATUS;
  recommendationMatchPercentage: number | null;
  isRecommendedExpert: boolean;
  customers: CustomerCompanyInfo[];
  linkedinUrl?: string;
}

export const DiscoverExpertInfo: React.FC<DiscoverExpertInfoProps> = ({
  expertId,
  fullName,
  countryName,
  countryTwoLetterCode,
  stats,
  title,
  profileImageUrl,
  lastPublicCompanyDate,
  projectId,
  projectName,
  previousProjects,
  isOnboarded,
  searchMatchColumns,
  doNotContactStatus,
  recommendationMatchPercentage,
  isRecommendedExpert,
  customers,
  linkedinUrl
}) => {
  const isHeadlineOptionSelected = useSelector(
    ProjectNxSelector.isKeywordSearchOptionSelected(
      KEYWORDS_SEARCH_OPTIONS.USER_TITLE
    )
  );
  const keywordsList = useSelector(ProjectNxSelector.keywordsList());

  const searchParams = new URLSearchParams({ projectId, projectName });
  const discoverMatchingFiltersPanelData: DiscoverMatchingFiltersPanelData = {
    expertId,
    fullName,
    title,
    countryTwoLetterCode,
    lastPublicCompanyDate,
    profileImageUrl,
    doNotContactStatus,
    projectId,
    searchMatchColumns,
    customers
  };

  return (
    <div className={styles.container}>
      <Link
        to={{
          pathname: EXPERT_PROFILE(expertId).pathname,
          search: `?${searchParams.toString()}`
        }}
        target="_blank"
        className={styles.link}
      >
        <div className={styles.expertContainer}>
          <ArbolusAvatar
            firstName={fullName}
            profileImageUrl={profileImageUrl}
            title={title}
            countryTwoLetterCode={countryTwoLetterCode}
            lastPublicCompanyDate={lastPublicCompanyDate}
            withGreenTick={isOnboarded}
            countryName={countryName ?? ""}
            avatarType={isMobile ? "avatarGroupCircleSmall" : "mediumXSCircle"}
            highlightTitleWords={
              isHeadlineOptionSelected ? keywordsList : undefined
            }
          />
        </div>
      </Link>
      <ExpertStats
        stats={stats}
        previousProjects={previousProjects}
        discoverMatchingFiltersPanelData={discoverMatchingFiltersPanelData}
        searchMatchColumns={searchMatchColumns}
        recommendationMatchPercentage={recommendationMatchPercentage}
        isRecommendedExpert={isRecommendedExpert}
        linkedinUrl={linkedinUrl}
      />
    </div>
  );
};
