import { yupResolver } from "@hookform/resolvers/yup";
import i18next from "i18next";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  DO_NOT_CONTACT_STATUS,
  SelectOption
} from "@arbolus-technologies/models/common";
import {
  CONTACT_TYPE_ENUM,
  EXPERT_TYPE,
  ExternalExpertFormValues,
  PipelineExpert
} from "@arbolus-technologies/models/expert";
import { HR } from "@arbolus-technologies/ui/components";

import { PipelineLogCard } from "../PipelineExpertCard/PipelineLogCard/PipelineLogCard";
import { CommsInfo } from "./CommsInfo/CommsInfo";
import { ContactInfo } from "./ContactInfo/ContactInfo";
import { ExternalExpertFormActions } from "./ExternalExpertFormActions/ExternalExpertFormActions";
import { ExternalExpertFormSchema } from "./ExternalExpertFormSchema";
import { RateCardInfo } from "./RateCardInfo/RateCardInfo";

import styles from "./ExternalExpertForm.module.scss";

interface ExternalExpertFormProps {
  expert: PipelineExpert;
  currencies: SelectOption[];
  isUpdating: boolean;
  onUpdate: (data: ExternalExpertFormValues) => void;
  moveToShortlist: () => void;
}

export const ExternalExpertForm: React.FC<ExternalExpertFormProps> = ({
  expert,
  currencies,
  isUpdating,
  onUpdate,
  moveToShortlist
}) => {
  const { t } = useTranslation("externalExpertForm");

  const currencyValue = currencies.find(
    (c) => c.value === expert.rateCard?.isoCurrencyCode
  )?.value;

  const statusList = Object.values(CONTACT_TYPE_ENUM).map((type) => ({
    value: type,
    label: i18next.t(`externalExpertStatus:${type}`)
  }));

  const isDnc = expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC;
  const hidePipelineActions = expert.isAlreadyAdded || isDnc;
  const isInternal = expert.platformStatus === EXPERT_TYPE.INTERNAL;
  const isFormDisabled = hidePipelineActions || isInternal;

  const methods = useForm<ExternalExpertFormValues>({
    resolver: yupResolver(ExternalExpertFormSchema),
    mode: "onChange",
    defaultValues: {
      projectPipelineStatus: expert.projectPipelineStatus,
      projectPipelineStatusComment: "",
      projectPipelineEmails: (expert.projectPipelineEmails ?? []).map(
        (email) => ({ value: email, label: email })
      ),
      customEmail: "",
      projectPipelinePhones: (expert.projectPipelinePhones ?? []).map(
        (phone) => ({ value: phone, label: phone })
      ),
      customPhone: "",
      rateCard: {
        id: expert.rateCard?.id,
        experienceLevel: expert.rateCard?.experienceLevel ?? "CSuiteLevel",
        isoCurrencyCode: currencyValue ?? currencies[0].value,
        price: expert.rateCard?.price ?? 0
      }
    }
  });

  return (
    <FormProvider {...methods}>
      <div className={styles.externalExpertFormContainer}>
        <CommsInfo statusList={statusList} isFormDisabled={isFormDisabled} />
        <ContactInfo
          lastContactInfoDate={expert.lastContactInfoDate}
          isFormDisabled={isFormDisabled}
        />
        <RateCardInfo currencies={currencies} isFormDisabled={isFormDisabled} />
        {!hidePipelineActions && (
          <ExternalExpertFormActions
            isUpdating={isUpdating}
            isInternal={isInternal}
            onUpdate={onUpdate}
            moveToShortlist={moveToShortlist}
          />
        )}
        <HR margin={{ top: 1, bottom: 1 }} />
        <PipelineLogCard
          createdDate={expert.createdDate}
          createdBy={expert.createdBy}
          createdByProfileImageUrl={expert.createdByProfileImageUrl}
          text={t("addedBy")}
        />
      </div>
    </FormProvider>
  );
};
