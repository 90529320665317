import {
  CIQError,
  ErrorResponse,
  ExpertDetail
} from "@arbolus-technologies/api";
import { EXPERT_FOLLOW_UP_STEPS } from "@arbolus-technologies/models/expert";

export const GET_EXPERT = "EXPERT_GET_EXPERT";
export const GET_EXPERT_SUCCESS = "EXPERT_GET_EXPERT_SUCCESS";
export const GET_EXPERT_FAILURE = "GET_EXPERT_FAILURE";

export const EXIT_FROM_EXPERT = "EXPERT_EXIT_FROM_EXPERT";

export const SET_EXPERT_FOLLOW_UP_STEP = "SET_EXPERT_FOLLOW_UP_STEP";
export const RESET_EXPERT_FOLLOW_UP_STEP = "RESET_EXPERT_FOLLOW_UP_STEP";

export interface GetExpertAction {
  type: typeof GET_EXPERT;
  payload: {
    expertId: string;
    initial: boolean;
  };
}

export interface GetExpertSuccessAction {
  type: typeof GET_EXPERT_SUCCESS;
  payload: {
    expertId: string;
    expertDetails: ExpertDetail;
  };
}

export interface GetExpertFailureAction {
  type: typeof GET_EXPERT_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

export interface ExitFromExpertAction {
  type: typeof EXIT_FROM_EXPERT;
  payload: {};
}

export interface SetExpertFollowUpStepAction {
  type: typeof SET_EXPERT_FOLLOW_UP_STEP;
  payload: { step: EXPERT_FOLLOW_UP_STEPS };
}

export interface ResetExpertFollowUpStepAction {
  type: typeof RESET_EXPERT_FOLLOW_UP_STEP;
}
