export const SURVEY_FORM_TYPE = {
  CREATE: "create",
  EDIT: "edit"
};

export const STEPS = [
  { order: 1, min: 0, max: 33 },
  { order: 2, min: 34, max: 66 },
  { order: 3, min: 67, max: 100 }
];
