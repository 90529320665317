import { Icon } from "arbolus-ui-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { MAXIMUM_FILE_UPLOADING_SIZE } from "@arbolus-technologies/models/documents";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { Badge } from "../Badges/Badge";

import styles from "./DragAndDropFileBox.module.scss";

interface DragAndDropFileBoxProps {
  onClickFile: (index: number) => void;
  onDropFiles: (files: File[]) => void;
  isValidFile: (file: File) => boolean;
  files: File[];
  maxFileSize: number;
  filesLimit: number;
  optional?: boolean;
}

export const DragAndDropFileBox: React.FC<DragAndDropFileBoxProps> = ({
  onClickFile,
  onDropFiles,
  isValidFile,
  files,
  maxFileSize,
  filesLimit,
  optional = null
}) => {
  const { t } = useTranslation();
  const [color, setColor] = useState<string>(ARBOLUS_COLORS.bColorGrayIcon);
  const [backgroundColor, setBackgroundColor] = useState<string>(
    ARBOLUS_COLORS.bColorBaseWhite
  );

  const setInactiveColors = () => {
    setColor(ARBOLUS_COLORS.bColorGrayIcon);
    setBackgroundColor(ARBOLUS_COLORS.bColorBaseWhite);
  };

  const setActiveColors = () => {
    setColor(ARBOLUS_COLORS.bColorBasePurple);
    setBackgroundColor(ARBOLUS_COLORS.angleDefaultBackgroundColor);
  };

  const handleOnDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setInactiveColors();
    const droppedFiles = Array.from(event.dataTransfer.files) as File[];
    const filesAreValid = droppedFiles.every(
      (file) => isValidFile(file) && file.size <= MAXIMUM_FILE_UPLOADING_SIZE
    );
    if (files.length + droppedFiles.length <= filesLimit && filesAreValid) {
      onDropFiles(droppedFiles);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.label}>
          <div>{t("common:dragAndDropFile:label")}</div>
          {optional && (
            <div className={styles.optional}>
              {t("common:dragAndDropFile:optional")}
            </div>
          )}
        </div>
        <div className={styles.constraints}>
          <div className={styles.maxFileSize}>
            {t("common:dragAndDropFile:maxSize", {
              maxSize: maxFileSize
            })}
          </div>
          <div className={styles.maxFileSize}>
            {t("common:dragAndDropFile:fileLimit", {
              limit: filesLimit
            })}
          </div>
        </div>
      </div>
      <div
        onDrop={(event: React.DragEvent<HTMLDivElement>) => {
          handleOnDrop(event);
        }}
        onDragOver={(event: React.DragEvent<HTMLDivElement>) => {
          event.preventDefault();
          setActiveColors();
        }}
        onDragEnter={() => setActiveColors()}
        onDragLeave={() => setInactiveColors()}
        className={styles.dragAndDropBox}
        style={{ borderColor: color, backgroundColor: backgroundColor }}
      >
        <Icon name="cloud_upload" fontSize="14" color={color} />
        <div style={{ color: color }}>
          {t("common:dragAndDropFile:dragAndDrop")}
        </div>
      </div>
      <div className={styles.attachments}>
        {files &&
          files.map((file, index) => (
            <Badge
              key={file.name + index}
              text={file.name}
              textStyle="lowercase"
              background={ARBOLUS_COLORS.bColorBaseWhite}
              fontColor={ARBOLUS_COLORS.bColorBaseDark}
              borderColor={ARBOLUS_COLORS.bColorGrayIcon}
              onClose={() => onClickFile(index)}
            >
              <Icon
                name="attach_file"
                fontSize="14"
                color={ARBOLUS_COLORS.bColorGrayIcon}
              />
            </Badge>
          ))}
      </div>
    </div>
  );
};
