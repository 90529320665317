import { Icon } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./ExpertInvitePanelHeader.module.scss";

export const ExpertInvitePanelHeader: React.FC = () => {
  const { t } = useTranslation("inviteExperts");
  const dispatch = useDispatch();

  return (
    <div className={styles.header}>
      <h1 className={styles.panelTitle}>{t("title")}</h1>
      <Icon
        name="close"
        fontSize="24px"
        color={ARBOLUS_COLORS.bColorGrayIcon}
        onClick={() =>
          dispatch(PanelStoreActions.closePanel(PanelId.AssignExperts))
        }
      />
    </div>
  );
};
