import { OutputSelector, createSelector } from "reselect";

import { CacheStateSelector } from "../CacheSelector";
import { CacheAppState, CacheReducerState } from "../models/definitions";

export const loggedInUserClientId = (): OutputSelector<
  CacheAppState,
  string | undefined,
  (res: CacheReducerState) => string | undefined
> =>
  createSelector<CacheAppState, CacheReducerState, string | undefined>(
    CacheStateSelector,
    (cacheReducerState) => cacheReducerState.loggedInUser.client?.id
  );
