import i18next from "i18next";
import { useState } from "react";

import { DefaultToasterService, UserService } from "@arbolus-technologies/api";

export function useChangePassword() {
  const [inProgress, setInProgress] = useState(false);

  function changePassword(currentPassword: string, newPassword: string) {
    setInProgress(true);
    UserService.changeUserPassword({
      currentPassword: currentPassword,
      newPassword: newPassword,
      confirmNewPassword: newPassword
    }).subscribe(
      () => {
        DefaultToasterService.showSuccess(
          i18next.t("newExpertProfileEditPage:passwordApiSuccess")
        );
        setInProgress(false);
      },
      () => {
        DefaultToasterService.showError(
          i18next.t("newExpertProfileEditPage:passwordApiError")
        );
        setInProgress(false);
      }
    );
  }

  return {
    changePassword,
    inProgress
  };
}
