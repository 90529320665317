import { ChatUserAPIRole, ChatUserRole } from "./enums";

// The order of the steps is important
export const PROJECT_CREATION_STEPS = {
  PROJECT_DETAILS: "projectDetails",
  CREATE_ANGLE: "createAngle",
  ANGLES_OVERVIEW: "anglesOverview",
  CHAT: "chat",
  AGREEMENT: "agreement"
} as const;

export type ProjectCreationStep =
  (typeof PROJECT_CREATION_STEPS)[keyof typeof PROJECT_CREATION_STEPS];

export const AnglesColors = [
  "#FFCC00",
  "#FFE580",
  "#FFAD9D",
  "#F5CDED",
  "#DBA8ED",
  "#B1B9FF",
  "#96BFFF",
  "#ADE4F7",
  "#94E8A2",
  "#CDE894"
];

export const DEFAULT_SLOTS_NUMBER = 6;
export const MAX_SLOTS_NUMBER = 100;

export enum COMPLIANCE_TYPE {
  NONE = "None",
  INTERVENTION = "Intervention",
  STRICT = "Strict"
}

export const ANGLE_SEARCH_PARAM = "angleId";

export enum AdminChatMode {
  ClientChat = "ClientChat",
  ExpertSupport = "ExpertSupport"
}

// Used as translation keys
export enum ChatType {
  // PATTERN: Sender [me (current user)] to recipient

  // support chat with arbolus team
  // reverse: AdminToProject
  ClientToProject = "clientToProjectChatType",

  // reverse: AdminToAngle
  ClientToAngle = "clientToAngleChatType",

  // reverse: AdminToExpertAndClient, ExpertToProject
  ClientToExpertAndAdmin = "clientToExpertAndAdminChatType",

  // support chat with arbolus team
  // reverse: ClientToProject
  AdminToProject = "adminToProjectChatType",

  // reverse: ClientToAngle
  AdminToAngle = "adminToAngleChatType",

  // reverse: ClientToExpertAndAdmin, ExpertToProject
  AdminToExpertAndClient = "adminToExpertAndClientChatType",

  // reverse: ExpertToAdminTeam
  AdminToExpertSupport = "adminToExpertSupportChatType",

  // support chat for experts
  // called "Arbolus Team" in UI
  // reverse: AdminToExpertSupport
  ExpertToAdminTeam = "expertToAdminTeamChatType",

  // chat for one expert and arbolus team + client team
  // called "Project Chat" in UI
  // reverse: AdminToExpertAndClient, ClientToExpertAndAdmin
  ExpertToProject = "expertToProjectChatType"
}

export const CHAT_API_USER_TO_CHAT_USER = {
  [ChatUserAPIRole.Admin]: ChatUserRole.Admin,
  [ChatUserAPIRole.Client]: ChatUserRole.Client,
  [ChatUserAPIRole.Expert]: ChatUserRole.Expert
};
