import { LoggedInUser } from "@arbolus-technologies/api";

export const GET_LOGGED_IN_USER = "CACHE_GET_LOGGED_IN_USER";

export interface GetLoggedInUserAction {
  type: typeof GET_LOGGED_IN_USER;
  payload: {
    loggedInUser: LoggedInUser;
  };
}
