import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { Button, Card, CardBody, Collapse } from "reactstrap";

import { AgreementTerm } from "../../../../models/meta";

interface TermItemProps {
  collapseItem: AgreementTerm;
  isDefaultExpanded?: boolean;
}

const TermItem: React.FC<TermItemProps> = ({
  collapseItem,
  isDefaultExpanded = false
}) => {
  const [isExpanded, setIsExpanded] = useState(!!isDefaultExpanded);
  const { termText, termTitle } = collapseItem;

  return (
    <div className="accordion">
      <Button
        className={`${isExpanded && "btn-success"}`}
        onClick={(): void => setIsExpanded(!isExpanded)}
      >
        {termTitle}
        <span className="ciq-icon ciq-chevron-down" />
      </Button>
      <Collapse isOpen={isExpanded}>
        <Card>
          <CardBody>
            <p>{ReactHtmlParser(termText)}</p>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
};

export default TermItem;
