import { LocationDescriptorObject } from "history";

type NotificationIcon =
  | "workspaces"
  | "calendar_today"
  | "person_search"
  | "check"
  | "block"
  | "forum";

export abstract class BaseNotification {
  id: string;
  icon: NotificationIcon;

  abstract title: string;
  abstract subtitle: string;
  abstract link: LocationDescriptorObject | string;

  constructor(id: string, icon: NotificationIcon) {
    this.id = id;
    this.icon = icon;
  }
}
