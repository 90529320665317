import { PageState } from "../models/definitions";

export const SAVE_PAGE_STATE = "PAGES_SAVE_PAGE_STATE";

export interface SavePageStateAction {
  type: typeof SAVE_PAGE_STATE;
  payload: {
    pageId: string;
    pageState: PageState;
  };
}
