import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./Responses.module.scss";

interface ResponsesProps {
  responses?: "Old" | "New" | "Empty";
}

export const Responses: React.FC<ResponsesProps> = ({ responses }) => {
  const { t } = useTranslation("surveyCard");

  const handleClassName = (): string => {
    switch (responses) {
      case "Empty": {
        return styles.empty;
      }
      case "New": {
        return styles.new;
      }
      default: {
        return "";
      }
    }
  };

  return (
    <div className={handleClassName()}>
      {responses === "Empty" ? (
        <span>{t("noResponses")}</span>
      ) : (
        <span>{t("newResponses")}</span>
      )}
    </div>
  );
};
