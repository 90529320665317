import {
  CIQError,
  Canopy,
  CanopyPublic,
  CanopyQuestion,
  CompleteValidatorErrors,
  CreateCanopyDataRequest,
  CreateEditCanopyQuestion,
  EditCanopyDataRequest,
  ErrorResponse
} from "@arbolus-technologies/api";
import {
  ANSWER_TYPE,
  CANOPY_STATUS
} from "@arbolus-technologies/models/canopy";

export const CLEAR_CANOPY = "CANOPY_BUILDER_CLEAR_CANOPY";
export const CLEAR_CANOPY_ERRORS = "CANOPY_BUILDER_CLEAR_CANOPY_ERRORS";
export const DELETE_CANOPY = "CANOPY_BUILDER_DELETE_CANOPY";
export const DELETE_CANOPY_SUCCESS = "CANOPY_BUILDER_DELETE_CANOPY_SUCCESS";
export const DELETE_CANOPY_FAILURE = "CANOPY_BUILDER_DELETE_CANOPY_FAILURE";
export const CREATE_CANOPY = "CANOPY_BUILDER_CREATE_CANOPY";
export const CREATE_CANOPY_SUCCESS = "CANOPY_BUILDER_CREATE_CANOPY_SUCCESS";
export const CREATE_CANOPY_FAILURE = "CANOPY_BUILDER_CREATE_CANOPY_FAILURE";
export const EDIT_CANOPY = "CANOPY_BUILDER_EDIT_CANOPY";
export const EDIT_CANOPY_SUCCESS = "CANOPY_BUILDER_EDIT_CANOPY_SUCCESS";
export const EDIT_CANOPY_FAILURE = "CANOPY_BUILDER_EDIT_CANOPY_FAILURE";
export const EDIT_CANOPY_COMPLETED = "CANOPY_BUILDER_EDIT_CANOPY_COMPLETED";
export const UPLOAD_DOCUMENT_TO_CANOPY =
  "CANOPY_BUILDER_UPLOAD_DOCUMENT_TO_CANOPY";
export const UPLOAD_DOCUMENT_TO_CANOPY_SUCCESS =
  "CANOPY_BUILDER_UPLOAD_DOCUMENT_TO_CANOPY_SUCCESS";
export const UPLOAD_DOCUMENT_TO_CANOPY_FAILURE =
  "CANOPY_BUILDER_UPLOAD_DOCUMENT_TO_CANOPY_FAILURE";
export const UPLOAD_DOCUMENTS_TO_CANOPY_FAILED =
  "CANOPY_BUILDER_UPLOAD_DOCUMENTS_TO_CANOPY_FAILED";
export const UPLOAD_DOCUMENTS_TO_CANOPY_COMPLETED =
  "CANOPY_BUILDER_UPLOAD_DOCUMENTS_TO_CANOPY_COMPLETED";
export const EXIT_FROM_CREATE_CANOPY = "CANOPY_BUILDER_EXIT_FROM_CREATE_CANOPY";

export const CANOPY_CHANGE_STATUS = "CANOPY_BUILDER_CANOPY_CHANGE_STATUS";
export const CANOPY_CHANGE_STATUS_SUCCESS =
  "CANOPY_BUILDER_CANOPY_CHANGE_STATUS_SUCCESS";
export const CANOPY_CHANGE_STATUS_FAILURE =
  "CANOPY_BUILDER_CANOPY_CHANGE_STATUS_FAILURE";

export const CREATE_CANOPY_QUESTION = "CANOPY_BUILDER_CREATE_CANOPY_QUESTION";
export const CREATE_CANOPY_QUESTION_SUCCESS =
  "CANOPY_BUILDER_CREATE_CANOPY_QUESTION_SUCCESS";
export const CREATE_CANOPY_QUESTION_FAILURE =
  "CANOPY_BUILDER_CREATE_CANOPY_QUESTION_FAILURE";

export const GET_CANOPY_CLIENT_QUESTIONS =
  "CANOPY_BUILDER_GET_CANOPY_CLIENT_QUESTIONS";
export const GET_CANOPY_CLIENT_QUESTIONS_SUCCESS =
  "CANOPY_BUILDER_GET_CANOPY_CLIENT_QUESTIONS_SUCCESS";
export const GET_CANOPY_CLIENT_QUESTIONS_FAILURE =
  "CANOPY_BUILDER_GET_CANOPY_CLIENT_QUESTIONS_FAILURE";

export const GET_CANOPY_CLIENT = "CANOPY_BUILDER_GET_CANOPY_CLIENT";
export const GET_CANOPY_CLIENT_SUCCESS =
  "CANOPY_BUILDER_GET_CANOPY_CLIENT_SUCCESS";
export const GET_CANOPY_CLIENT_FAILURE =
  "CANOPY_BUILDER_GET_CANOPY_CLIENT_FAILURE";

export const GET_PUBLIC_CANOPY = "CANOPY_BUILDER_GET_PUBLIC_CANOPY";
export const GET_PUBLIC_CANOPY_SUCCESS =
  "CANOPY_BUILDER_GET_PUBLIC_CANOPY_SUCCESS";
export const GET_PUBLIC_CANOPY_FAILURE =
  "CANOPY_BUILDER_GET_PUBLIC_CANOPY_FAILURE";

export const GET_QUESTION = "CANOPY_BUILDER_GET_QUESTION";
export const GET_QUESTION_SUCCESS = "CANOPY_BUILDER_GET_QUESTION_SUCCESS";
export const GET_QUESTION_FAILURE = "CANOPY_BUILDER_GET_QUESTION_FAILURE";

export const NOTIFY_EDIT_CANOPY = "CANOPY_BUILDER_NOTIFY_EDIT_CANOPY";

export const REDIRECT_TO_CANOPY = "CANOPY_BUILDER_REDIRECT_TO_CANOPY";
export const REDIRECT_TO_PROJECT = "CANOPY_BUILDER_REDIRECT_TO_PROJECT";

export const EDIT_CANOPY_QUESTION = "CANOPY_BUILDER_EDIT_CANOPY_QUESTION";
export const EDIT_CANOPY_QUESTION_SUCCESS =
  "CANOPY_BUILDER_EDIT_CANOPY_QUESTION_SUCCESS";
export const EDIT_CANOPY_QUESTION_FAILURE =
  "CANOPY_BUILDER_EDIT_CANOPY_QUESTION_FAILURE";

export const DELETE_CANOPY_QUESTION = "CANOPY_BUILDER_DELETE_CANOPY_QUESTION";
export const DELETE_CANOPY_QUESTION_SUCCESS =
  "CANOPY_BUILDER_DELETE_CANOPY_QUESTION_SUCCESS";
export const DELETE_CANOPY_QUESTION_FAILURE =
  "CANOPY_BUILDER_DELETE_CANOPY_QUESTION_FAILURE";

export const RESET_PANEL = "CANOPY_BUILDER_RESET_PANEL";

export const LAUNCH_SURVEY_GO_BACK = "CANOPY_BUILDER_LAUNCH_SURVEY_GO_BACK";

export const CLEAR_ERRORS_BUILDER = "CANOPY_BUILDER_CLEAR_ERRORS_BUILD";
export const BUILDER_RESET = "CANOPY_BUILDER_RESET";

// Get canopy
export interface GetCanopyClientAction {
  type: typeof GET_CANOPY_CLIENT;
  payload: {
    canopyId: string;
  };
}

export interface GetCanopyClientSuccessAction {
  type: typeof GET_CANOPY_CLIENT_SUCCESS;
  payload: {
    canopyData: Canopy;
  };
}

export interface GetCanopyClientFailureAction {
  type: typeof GET_CANOPY_CLIENT_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

// Get PUBLIC canopy
export interface GetPublicCanopyAction {
  type: typeof GET_PUBLIC_CANOPY;
  payload: {
    canopyId: string;
  };
}

export interface GetPublicCanopySuccessAction {
  type: typeof GET_PUBLIC_CANOPY_SUCCESS;
  payload: {
    canopyPublicData: CanopyPublic;
  };
}

export interface GetPublicCanopyFailureAction {
  type: typeof GET_PUBLIC_CANOPY_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

// Delete canopy
export interface DeleteCanopyAction {
  type: typeof DELETE_CANOPY;
  payload: {
    canopyData: { canopyId: string };
    projectId: string;
  };
}

// Clear Selected Canopy
export interface ClearCanopyAction {
  type: typeof CLEAR_CANOPY;
}

// Clear Canopy Errors
export interface ClearErrorsCanopyAction {
  type: typeof CLEAR_CANOPY_ERRORS;
}

export interface DeleteCanopySuccessAction {
  type: typeof DELETE_CANOPY_SUCCESS;
  payload: {
    canopyId: string;
  };
}

export interface DeleteCanopyFailureAction {
  type: typeof DELETE_CANOPY_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

// Create canopy
export interface CreateCanopyAction {
  type: typeof CREATE_CANOPY;
  payload: {
    canopyDataRequest: CreateCanopyDataRequest;
    canopyDocuments: FormData[];
  };
}

export interface CreateCanopySuccessAction {
  type: typeof CREATE_CANOPY_SUCCESS;
  payload: {
    canopyId: string;
    canopyDocuments: FormData[];
  };
}

export interface CreateCanopyFailureAction {
  type: typeof CREATE_CANOPY_FAILURE;
  payload: {
    errors: CompleteValidatorErrors;
  };
}

export interface EditCanopyFailureAction {
  type: typeof EDIT_CANOPY_FAILURE;
  payload: {
    error: CompleteValidatorErrors | ErrorResponse<CIQError>;
  };
}

// Edit canopy
export interface EditCanopyAction {
  type: typeof EDIT_CANOPY;
  payload: {
    canopyDataRequest: EditCanopyDataRequest;
    canopyDocuments: FormData[];
    documentsToRemove: string[];
  };
}

export interface EditCanopySuccessAction {
  type: typeof EDIT_CANOPY_SUCCESS;
  payload: {
    canopyId: string;
    canopyDocuments: FormData[];
    documentsToRemove: string[];
  };
}

// Edit canopy completed
export interface EditCanopyCompletedAction {
  type: typeof EDIT_CANOPY_COMPLETED;
  payload: {
    canopyId: string;
  };
}

// Upload document to canopy
export interface UploadDocumentsToCanopyFailedAction {
  type: typeof UPLOAD_DOCUMENTS_TO_CANOPY_FAILED;
  payload: {
    errors?: CompleteValidatorErrors;
  };
}

// Exit from create canopy
export interface ExitFromCreateCanopyAction {
  type: typeof EXIT_FROM_CREATE_CANOPY;
}

// GET CANOPY QUESTIONS

export interface CanopyApiQuestions {
  id: string;
  canopyId: string;
  text: string;
  details: string;
  type: ANSWER_TYPE;
  order: number;
  isAnswered?: boolean;
}

export interface GetCanopyClientQuestionsAction {
  type: typeof GET_CANOPY_CLIENT_QUESTIONS;
  payload: {
    canopyId: string;
    expertId?: string;
  };
}

export interface GetCanopyClientQuestionsSuccessAction {
  type: typeof GET_CANOPY_CLIENT_QUESTIONS_SUCCESS;
  payload: {
    canopyQuestions: CanopyApiQuestions[];
  };
}

export interface GetCanopyClientQuestionsFailureAction {
  type: typeof GET_CANOPY_CLIENT_QUESTIONS_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

// CHANGE STATUS CANOPY

export interface CanopyChangeStatusAction {
  type: typeof CANOPY_CHANGE_STATUS;
  payload: {
    canopyId: string;
    status: CANOPY_STATUS;
  };
}

export interface CanopyChangeStatusSuccessAction {
  type: typeof CANOPY_CHANGE_STATUS_SUCCESS;
}

export interface CanopyChangeStatusFailureAction {
  type: typeof CANOPY_CHANGE_STATUS_FAILURE;
  payload: {
    error: ErrorResponse<CIQError> | CompleteValidatorErrors;
  };
}

// CREATE CANOPY QUESTION

export interface CreateCanopyQuestionAction {
  type: typeof CREATE_CANOPY_QUESTION;
  payload: CreateEditCanopyQuestion;
}

export interface CreateCanopyQuestionSuccessAction {
  type: typeof CREATE_CANOPY_QUESTION_SUCCESS;
  payload: {
    newId: string;
    text: string;
    details: string;
    type: ANSWER_TYPE;
    order?: number;
  };
}

export interface CreateCanopyQuestionFailureAction {
  type: typeof CREATE_CANOPY_QUESTION_FAILURE;
  payload: {
    error: ErrorResponse<CIQError> | CompleteValidatorErrors;
  };
}

// GET QUESTION
export interface GetQuestionAction {
  type: typeof GET_QUESTION;
  payload: {
    questionId: string;
  };
}

export interface GetQuestionSuccessAction {
  type: typeof GET_QUESTION_SUCCESS;
  payload: {
    question: CanopyQuestion;
  };
}

export interface GetQuestionFailureAction {
  type: typeof GET_QUESTION_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

export interface RedirectToCanopyAction {
  type: typeof REDIRECT_TO_CANOPY;
  payload: {
    canopyId: string;
    uploadSuccess: boolean;
  };
}

// EDIT CANOPY QUESTION

export interface EditCanopyQuestionAction {
  type: typeof EDIT_CANOPY_QUESTION;
  payload: CreateEditCanopyQuestion;
}

export interface EditCanopyQuestionSuccessAction {
  type: typeof EDIT_CANOPY_QUESTION_SUCCESS;
  payload: {
    question: string;
    questionId: string;
    details: string;
    type: ANSWER_TYPE;
  };
}
export interface EditCanopyQuestionFailureAction {
  type: typeof EDIT_CANOPY_QUESTION_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

// DELETE CANOPY QUESTION

export interface DeleteCanopyQuestionAction {
  type: typeof DELETE_CANOPY_QUESTION;
  payload: {
    questionId: string;
    canopyId: string;
  };
}

export interface DeleteCanopyQuestionSuccessAction {
  type: typeof DELETE_CANOPY_QUESTION_SUCCESS;
  payload: {
    questionId: string;
  };
}

export interface RedirectToProjectAction {
  type: typeof REDIRECT_TO_PROJECT;
  payload: {
    projectId: string;
  };
}

export interface NotifyEditCanopyAction {
  type: typeof NOTIFY_EDIT_CANOPY;
  payload: {
    canopyId: string;
  };
}

export interface DeleteCanopyQuestionFailureAction {
  type: typeof DELETE_CANOPY_QUESTION_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

export interface ResetPanelAction {
  type: typeof RESET_PANEL;
}

export interface LaunchSurveyGoBackAction {
  type: typeof LAUNCH_SURVEY_GO_BACK;
  payload: {
    canopyId: string;
  };
}

export interface ClearErrorsBuilderAction {
  type: typeof CLEAR_ERRORS_BUILDER;
}

export interface CanopyBuilderResetAction {
  type: typeof BUILDER_RESET;
}
