import React, { HTMLProps } from "react";

import { useDebouncedSearch } from "@arbolus-technologies/utils";

import { SearchInputBase } from "../SearchInputBase/SearchInputBase";

interface SearchInputProps extends HTMLProps<HTMLInputElement> {
  initialValue?: string;
  isDebounced?: boolean;
  debouncingTime?: number;
  minimumSearchLength?: number;
  onQueryChange: (query: string) => void;
  containerClassnames?: string;
  hasSearchIcon?: boolean;
  autoFocus?: boolean;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  className,
  placeholder,
  initialValue = "",
  isDebounced = false,
  debouncingTime = 500,
  minimumSearchLength = 2,
  onQueryChange,
  containerClassnames,
  hasSearchIcon = false,
  autoFocus = false
}) => {
  const { handleClearQueryClicked, searchQuery, handleInputChangeEvent } =
    useDebouncedSearch({
      initialValue,
      debouncingTime,
      minimumSearchLength,
      onQueryChange,
      isDebounced
    });

  return (
    <SearchInputBase
      className={className}
      placeholder={placeholder}
      containerClassnames={containerClassnames}
      hasSearchIcon={hasSearchIcon}
      autoFocus={autoFocus}
      handleClearQueryClicked={handleClearQueryClicked}
      searchQuery={searchQuery}
      handleInputChangeEvent={handleInputChangeEvent}
    />
  );
};
