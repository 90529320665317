import { IconButton } from "arbolus-ui-components";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { UncontrolledPopover } from "reactstrap";

import {
  DefaultTranscriptToBlob,
  MixPanelActions,
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { TranscriptPageHeaderTemplate } from "@arbolus-technologies/features/projects";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ArbolusModalClientReportExpert } from "@arbolus-technologies/ui/components";

import { PROJECT_TRANSCRIPTS_ROUTE } from "../../../../../constants/navigation/projectRoutes";
import useTranscriptContext from "../../../../../contexts/transcript/UseTranscriptContext";
import { ProjectSelector } from "../../../store";
import TranscriptOptionsPopover from "../transcriptPopover/TranscriptOptionsPopover";

const TranscriptPageHeader: React.FC = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const projectId = useSelector(ProjectSelector.projectIdSelector());
  const loggedInUser = useSelector(CacheSelector.loggedInUser());
  const { trackClick, trackSubmit } = useArbolusTracking();

  const { event, speakersMap, transcriptMeta, monologues } =
    useTranscriptContext();

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isReportExpertModalOpen, setIsReportExpertModalOpen] =
    useState<boolean>(false);

  const handleGoBack = (): void => {
    if (location.state) {
      history.goBack();
    } else {
      history.push(PROJECT_TRANSCRIPTS_ROUTE(projectId));
    }
  };

  const toggleTranscriptionOptionPopup = (): void => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleIsReportExpertModalOpen = (): void => {
    toggleTranscriptionOptionPopup();
    trackClick(MixPanelEventNames.TranscriptReportExpertButton);
    setIsReportExpertModalOpen((prev) => !prev);
  };

  const trackSubmitButtonClick = (reason: string, feedbackText: string) =>
    trackSubmit(MixPanelEventNames.TranscriptReportExpert, {
      action: MixPanelActions.SubmitReportExpert,
      reason,
      feedbackText
    });

  const handleDownloadClick = (): void => {
    DefaultTranscriptToBlob.downloadTranscript(
      transcriptMeta,
      Array.from(monologues.values()),
      speakersMap
    );
  };

  return (
    <TranscriptPageHeaderTemplate
      onClickBack={handleGoBack}
      onDownload={handleDownloadClick}
      projectId={projectId}
    >
      <div id="Popover1">
        <IconButton icon="more_horiz" />
      </div>
      <UncontrolledPopover
        hideArrow
        isOpen={isPopupOpen}
        toggle={toggleTranscriptionOptionPopup}
        trigger="legacy"
        placement="bottom-end"
        target="Popover1"
        className="transcript-popover"
      >
        <TranscriptOptionsPopover
          eventData={event}
          onPopupToggle={toggleTranscriptionOptionPopup}
          onReportExpertToggle={handleIsReportExpertModalOpen}
        />
      </UncontrolledPopover>
      <ArbolusModalClientReportExpert
        isOpen={isReportExpertModalOpen}
        email={loggedInUser?.email}
        transcriptTitle={event.title}
        toggle={handleIsReportExpertModalOpen}
        trackSubmitMixPanel={trackSubmitButtonClick}
      />
    </TranscriptPageHeaderTemplate>
  );
};

export default TranscriptPageHeader;
