import queryString from "query-string";

import { ExpertNotificationData } from "../../models/view/notifications";
import { EXPERT_NETWORK } from "./authRoutes";
import { PROJECT_FILES_ROUTE, PROJECT_ROUTE } from "./projectRoutes";

export const EVENT_PANEL_HASH = "eventId";
export const DOCUMENT_PANEL_HASH = "docId";
export const CANDIDATE_PICKER_HASH = "candidates";
export const PROJECT_MEMBER_PANEL_HASH = "members";

// Expert Referral Hashes
const PROJECT_ID_HASH = "projectId";
const REFERRAL_ID_HASH = "referralId";
const EXPERT_ID_HASH = "expertId";
const REFERRAL_ACTION_HASH = "action";

export const PROJECT_EXPERTS_HASHES = [REFERRAL_ID_HASH, EXPERT_ID_HASH];
export const INBOX_COMPLIANCE_CHECK_HASHES = [
  PROJECT_ID_HASH,
  REFERRAL_ID_HASH,
  EXPERT_ID_HASH,
  REFERRAL_ACTION_HASH
];

export const USER_PROFILE_PANEL_HASH = "userId";
export const EXPERT_INVITE_PANEL_HASH = "expertInvite";
export const EXPERT_APPLICATION_DECLINED_HASH = "applicationDeclined";
export const EXPERT_APPLICATION_COMPLIANCE_FAILED_HASH = "complianceFailed";

export const PANEL_HASHES = [
  EVENT_PANEL_HASH,
  DOCUMENT_PANEL_HASH,
  CANDIDATE_PICKER_HASH,
  PROJECT_MEMBER_PANEL_HASH,
  USER_PROFILE_PANEL_HASH,
  EXPERT_INVITE_PANEL_HASH,
  EXPERT_APPLICATION_DECLINED_HASH,
  EXPERT_APPLICATION_COMPLIANCE_FAILED_HASH,
  REFERRAL_ID_HASH,
  EXPERT_ID_HASH,
  PROJECT_ID_HASH,
  REFERRAL_ACTION_HASH
];

export const DOCUMENT_PANEL_ROUTE = (
  docId: string,
  projectId?: string
): string =>
  `${
    projectId ? PROJECT_FILES_ROUTE(projectId) : window.location.pathname
  }#${DOCUMENT_PANEL_HASH}=${docId}`;

export const CANDIDATE_PICKER_PANEL_ROUTE = (projectId?: string): string =>
  `${
    projectId ? PROJECT_ROUTE(projectId) : window.location.pathname
  }#${CANDIDATE_PICKER_HASH}`;

export const PROJECT_MEMBER_PANEL_ROUTE = (projectId?: string): string =>
  `${
    projectId ? PROJECT_ROUTE(projectId) : window.location.pathname
  }#${PROJECT_MEMBER_PANEL_HASH}`;

export const EXPERT_INVITE_PANEL_ROUTE = `${EXPERT_NETWORK}#${EXPERT_INVITE_PANEL_HASH}`;

export const APPLICATION_DECLINED_PANEL_ROUTE = (): string =>
  `${window.location.pathname}#${EXPERT_APPLICATION_DECLINED_HASH}`;
export const COMPLIANCE_ISSUE_PANEL_ROUTE = (): string =>
  `${window.location.pathname}#${EXPERT_APPLICATION_COMPLIANCE_FAILED_HASH}`;

export const REFERRAL_PANEL_ROUTE = (
  panelData: ExpertNotificationData,
  projectExpertRoute?: string
): string => {
  const baseRoute = projectExpertRoute || window.location.pathname;
  const stagedData = { ...panelData };

  if (projectExpertRoute) {
    delete stagedData.projectId;
  }

  return `${baseRoute}#${queryString.stringify(stagedData)}`;
};
