export enum RateTypeActions {
  RATE_TYPE = "expertRate"
}

export interface RateTypeAction {
  type: RateTypeActions;
  payload: RateTypeState;
}

export interface RateTypeState {
  isInClientNetwork: boolean;
  info: string | null;
}

export const reducer = (
  state: RateTypeState,
  action: RateTypeAction
): RateTypeState => {
  const { type, payload } = action;
  switch (type) {
    case RateTypeActions.RATE_TYPE:
      return {
        ...state,
        isInClientNetwork: payload.isInClientNetwork,
        info: payload.info
      };
    default:
      return state;
  }
};

export const initialState = { isInClientNetwork: false, info: null };
