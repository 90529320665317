import React, { useEffect, useRef } from "react";

import styles from "./VideoPlayer.module.scss";

interface VideoPlayerProps {
  src: string;
  size: number;
  answerId?: string;
  playingVideoId?: string;
  setPlayingVideoId?: React.Dispatch<React.SetStateAction<string>>;
}

// preload="metadata" and #t=0.5 to display first frame of the video as video thumbnail
// size prop marks the dimension of the video element, always in square proportions

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  answerId,
  playingVideoId,
  setPlayingVideoId,
  src,
  size
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (
      answerId &&
      playingVideoId &&
      answerId !== playingVideoId &&
      videoRef.current
    ) {
      videoRef.current.pause();
    }
  }, [answerId, playingVideoId]);

  return (
    <video
      preload="metadata"
      controls
      className={styles.videoPlayer}
      style={{ height: size, width: size, minHeight: size, minWidth: size }}
      data-testid="videoPlayer"
      onPlay={() => setPlayingVideoId?.(answerId!)}
      ref={videoRef}
    >
      <source src={`${src}#t=0.5`} />
    </video>
  );
};
