import i18next from "i18next";
import * as Yup from "yup";

import { ValidationConstants } from "../../../../constants";
import { ComplianceAnswerOptions } from "../../../../constants/expert";
import { ProjectComplianceConstraints } from "../../../../constants/validation";

export const projectQuestionsValidationSchema = Yup.object().shape({
  questions: Yup.array().of(
    Yup.object().shape({
      answer: Yup.string()
        .nullable()
        .max(
          ValidationConstants.ProjectAnswersConstraints.MAX_ANSWER_LENGTH,
          i18next.t("projectApplication:answerMaxLengthError", {
            length:
              ValidationConstants.ProjectAnswersConstraints.MAX_ANSWER_LENGTH
          })
        )
        .required(i18next.t("projectApplication:answerRequiredError"))
    })
  )
});

export const textQuestionSchema = Yup.object().shape({
  required: Yup.boolean(),
  value: Yup.string()
    .when("required", {
      is: true,
      then: Yup.string()
        .nullable()
        .required(i18next.t("projectApplication:answerRequiredError")),
      otherwise: Yup.string().nullable()
    })
    .max(
      ProjectComplianceConstraints.MAX_COMPLIANCE_ANSWER_LENGTH,
      i18next.t("projectApplication:answerMaxLengthError", {
        length: ProjectComplianceConstraints.MAX_COMPLIANCE_ANSWER_LENGTH
      })
    )
});

export const yesNoQuestionSchema = Yup.object().shape({
  required: Yup.boolean(),
  value: Yup.string().when("required", {
    is: true,
    then: Yup.string()
      .notOneOf(
        [ComplianceAnswerOptions.NotSet],
        i18next.t("projectApplication:answerRequiredError")
      )
      .required(i18next.t("projectApplication:answerRequiredError")),
    otherwise: Yup.string().nullable()
  })
});

export const yesNoTextQuestionSchema = Yup.object().shape({
  answerRequired: Yup.boolean(),
  value: Yup.object().when("answerRequired", {
    is: true,
    then: Yup.object().shape({
      answer: Yup.string()
        .notOneOf(
          [ComplianceAnswerOptions.NotSet],
          i18next.t("projectApplication:answerRequiredError")
        )
        .required(i18next.t("projectApplication:answerRequiredError")),
      textAnswer: Yup.string()
        .nullable()
        .when(
          "$textAnswerRequired",
          (textAnswerRequired: boolean, schema: Yup.StringSchema) => {
            if (textAnswerRequired) {
              return schema.required(
                i18next.t("projectApplication:answerRequiredError")
              );
            }
            return schema.nullable().notRequired();
          }
        )
    }),
    otherwise: Yup.object().shape({
      answer: Yup.string().notRequired(),
      textAnswer: Yup.string().when(
        "$textAnswerRequired",
        (textAnswerRequired: boolean, schema: Yup.StringSchema) => {
          if (textAnswerRequired) {
            return schema.required(
              i18next.t("projectApplication:answerRequiredError")
            );
          }
          return schema.nullable();
        }
      )
    })
  })
});
