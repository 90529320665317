import { Icon } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { SquareNumber } from "@arbolus-technologies/ui/components";

import styles from "./QuestionTitle.module.scss";

interface QuestionTitleProps {
  number: string;
  text: string;
  icon?: string;
  isActive: boolean;
  showUnsavedMessage: boolean;
}

export const QuestionTitle: React.FC<QuestionTitleProps> = ({
  number,
  text,
  icon,
  isActive,
  showUnsavedMessage
}) => {
  const { t } = useTranslation("canopyBuilder");

  return (
    <div className={styles.questionTitle}>
      <div className={styles.icons}>
        <SquareNumber
          number={number}
          isActive={isActive}
          errorBackground={showUnsavedMessage}
        />
        {icon && (
          <Icon
            name={icon}
            color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
            fontSize="24px"
          />
        )}
      </div>
      <div className={styles.titleContainer}>
        <span className={styles.title}>{text}</span>
        {showUnsavedMessage && (
          <p className={styles.unsavedMessage}>{t("unsavedMessage")}</p>
        )}
      </div>
    </div>
  );
};
