import { ICellRendererParams } from "ag-grid-community";
import React from "react";

import { AdminExpertListResponseItem } from "@arbolus-technologies/api";

import { UserName } from "../../Components/UserName/UserName";

export function ExpertName({
  data
}: ICellRendererParams<AdminExpertListResponseItem>): React.ReactNode {
  if (!data) return null;

  return (
    <UserName
      row={{
        ...data,
        emailConfirmed: true
      }}
      lastPublicCompanyExpDate={data.lastPublicCompanyExpDate}
      bold
    />
  );
}
