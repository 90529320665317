import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { APP_DATE_FORMAT, formatDate } from "@arbolus-technologies/utils";

import AdminCard from "../../card/AdminCard";

interface BriefCardProps {
  title: string;
  modified: Date;
  onProjectBriefClicked: () => void;
}

type BriefCardPropsIntersectProps = BriefCardProps & WithTranslation;

const BriefCard = ({
  title,
  modified,
  t,
  onProjectBriefClicked
}: BriefCardPropsIntersectProps): JSX.Element => (
  <AdminCard
    title={title}
    onCardClick={(): void => {
      onProjectBriefClicked();
    }}
  >
    {`${t("lastUpdate")}: ${formatDate(modified, APP_DATE_FORMAT)}`}
  </AdminCard>
);

export default withTranslation("projectBasePage")(BriefCard);
