import { ProjectApplicationPage } from "./applyProject/ProjectApplicationPage";
import ClientSettingsPage from "./client-settings/ClientSettingsPage";
import UserProfilePage from "./clientProfile/ClientProfilePage";
import ProjectsPage from "./home/ProjectsPage";
import NewUserPage from "./newUser/NewUserPage";
import { UsersPage } from "./users/UsersPage";
import WelcomePage from "./welcome/WelcomePage";

export {
  ProjectsPage,
  NewUserPage,
  UserProfilePage,
  ClientSettingsPage,
  UsersPage,
  WelcomePage,
  ProjectApplicationPage
};
