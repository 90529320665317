import { Button } from "antd";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import { PROJECT_BRIEF_ROUTE } from "@arbolus-technologies/routes";
import { ProjectNxStoreActions } from "@arbolus-technologies/stores/project";

import {
  ACTIVATE_CHAT,
  BRIEF,
  CASE_CODE,
  CONTACT_NUMBER,
  END_DATE,
  MAIN_CONTACT,
  MANAGER_EMAIL,
  MANAGER_NAME,
  PARTNER_EMAIL,
  PARTNER_NAME,
  PROJECT_NAME,
  START_DATE,
  SimplifiedProjectEditFormValues,
  TIMEZONE
} from "../../../type";

import styles from "./EditFormFooter.module.scss";

export const EditFormFooter: React.FC = () => {
  const { t } = useTranslation("projectSimplifiedEdit");
  const { projectId } = useParams<{ projectId: string }>();
  const dispatch = useDispatch();
  const { handleSubmit, formState } =
    useFormContext<SimplifiedProjectEditFormValues>();

  const handleSubmitForm = (formValues: SimplifiedProjectEditFormValues) => {
    const formPhoneNumber = formValues[CONTACT_NUMBER];
    const phoneNumberFormatted = formPhoneNumber?.startsWith("+")
      ? formPhoneNumber
      : `+${formPhoneNumber}`;
    dispatch(
      ProjectNxStoreActions.simplifiedEditProject({
        projectId,
        name: formValues[PROJECT_NAME],
        brief: formValues[BRIEF],
        timeZone: formValues[TIMEZONE],
        mainContactUserId: formValues[MAIN_CONTACT]?.id || "",
        phoneNumber: phoneNumberFormatted,
        startDate: formValues[START_DATE],
        endDate: formValues[END_DATE],
        caseCode: formValues[CASE_CODE],
        managerName: formValues[MANAGER_NAME],
        managerEmail: formValues[MANAGER_EMAIL],
        partnerName: formValues[PARTNER_NAME],
        partnerEmail: formValues[PARTNER_EMAIL],
        activeProjectChatForAllUsers: formValues[ACTIVATE_CHAT]
      })
    );
  };

  return (
    <div className={styles.footer}>
      <Button type="link" href={PROJECT_BRIEF_ROUTE(projectId).pathname}>
        {t("cancel")}
      </Button>
      <Button
        type="primary"
        onClick={handleSubmit(handleSubmitForm)}
        disabled={!formState.isDirty}
      >
        {t("save")}
      </Button>
    </div>
  );
};
