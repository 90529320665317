import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { Menu, Tooltip } from "antd";
import { IconName } from "libs/antDComponents/src/lib/Icon/IconDictionary";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ISideBarItems } from "../../MenuItemsConfig";
import styles from "../AntDMenu.module.scss";

interface MainMenuProps {
  menuItems: ISideBarItems[];
  isActive: (route: string) => string | undefined;
}

export const MainMenu: React.FC<MainMenuProps> = ({ menuItems, isActive }) => {
  const location = useLocation();
  return (
    <Menu
      className={styles.menuTop}
      mode="inline"
      theme="light"
      inlineCollapsed
      selectedKeys={[location.pathname]}
    >
      {menuItems.map((item) => (
        <Menu.Item
          key={item.route}
          className={styles.menuItem}
          accessKey={item.route}
          icon={
            <AntDIcon
              name={item.icon as IconName}
              fontSize="16px"
              color={isActive(item.route!)}
            />
          }
        >
          <Tooltip title={item.name} placement="right">
            <Link to={item.route as string} />
          </Tooltip>
        </Menu.Item>
      ))}
    </Menu>
  );
};
