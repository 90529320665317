import i18next from "i18next";
import moment, { Moment } from "moment";

export interface TimeSpan<T> {
  start: T;
  end: T;
}

export const TimezoneService = {
  getTimezoneOffset: (
    expertTimezone: string,
    projectTimezone: string
  ): number => {
    const expertTimezoneOffset = moment().tz(expertTimezone).utcOffset();
    const projectTimezoneOffset = moment().tz(projectTimezone).utcOffset();

    return expertTimezoneOffset - projectTimezoneOffset;
  },
  getTimezoneDifference: (
    expertTimezone: string,
    projectTimezone: string
  ): string => {
    const offset = TimezoneService.getTimezoneOffset(
      expertTimezone,
      projectTimezone
    );

    if (offset === 0) {
      return "";
    }

    const isAhead = offset > 0;
    if (Math.abs(offset) === 60) {
      return i18next.t(
        isAhead
          ? "expertAvailabilityScheduler:hourAhead"
          : "expertAvailabilityScheduler:hourBehind",
        { duration: "1" }
      );
    }

    const hours = Math.floor(Math.abs(offset) / 60);
    const minutes = Math.abs(offset % 60);
    const minutesText = minutes ? `:${minutes}` : "";
    const timeDuration = `${hours}${minutesText}`;
    return i18next.t(
      isAhead
        ? "expertAvailabilityScheduler:hoursAhead"
        : "expertAvailabilityScheduler:hoursBehind",
      {
        duration: timeDuration
      }
    );
  },
  convertDatesTimezone: (
    startDate: string,
    endDate: string,
    from: string,
    to: string
  ): TimeSpan<Moment> => {
    const start = moment.tz(startDate, to).tz(from);
    const end = moment.tz(endDate, to).tz(from);

    return { start, end };
  }
};
