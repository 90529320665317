import { object, string } from "yup";

export const GetDangerDeleteSchema = (confirmationText: string) =>
  object().shape({
    confirmationText: string()
      .matches(
        new RegExp(`^${confirmationText}$`),
        `input is not ${confirmationText}`
      )
      .required("required")
  });
