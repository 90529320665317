import {
  AngleModel,
  DO_NOT_CONTACT_STATUS,
  REFERRAL_COMPLIANCE_STATE,
  REFERRAL_STATUS,
  REFERRAL_SUB_STATE
} from "@arbolus-technologies/models/common";
import {
  ChatMember,
  ChatUserAPIRole,
  DatabaseChatType
} from "@arbolus-technologies/models/project";

import { TimeZone } from "../../index";
import { CHAT_EVENT_STATUS } from "../enums/projectEnums";
import { FILE_TYPE } from "./documents";

export type ChatListResponse = {
  items: ChatListResponseItem[];
};

export interface ChatListResponseItem {
  chatId: string;
  chatName: string;
  chatType: DatabaseChatType;
  channelName: string;
  angle: AngleModel | null;
  expertChats: ExpertChatResponse[] | null;
  userTitle: string;
  userProfileImageUrl: string;
  expertId: string;
  chatUserId: string;
  referralId: string;
  referralApplicationStatus: REFERRAL_SUB_STATE;
  referralComplianceStatus: REFERRAL_COMPLIANCE_STATE;
  referralStatus: REFERRAL_STATUS;
  chatMembers: ChatMember[];
}

export interface ExpertChatResponse {
  chatId: string;
  chatName: string;
  userTitle: string;
  userProfileImageUrl: string;
  expertId: string;
  chatUserId: string;
  referralId: string;
  referralApplicationStatus: REFERRAL_SUB_STATE;
  referralComplianceStatus: REFERRAL_COMPLIANCE_STATE;
  channelName: string;
  chatType: DatabaseChatType;
  chatMembers: ChatMember[];
  doNotContactStatus: DO_NOT_CONTACT_STATUS;
}

export enum MessageActionType {
  EXPERT_AVAILABILITY = "WelcomeMessage",
  REQUIRE_AVAILABILITY = "RequireAvailabilitySlotsAction"
}

export interface IChatMessage {
  seen?: boolean;
  id: string;
  userId: string;
  created: Date | string;
  message: string;
  file?: ChatAttachment;
  event?: ChatEvent;
  messageAction?: MessageActionType;
  userFullName?: string;
  userProfileImageUrl?: string;
  userType?: ChatUserAPIRole;
}

export interface ChatAttachment {
  id: string;
  url: string;
  fileName: string;
  fileSize: number;
  type: FILE_TYPE;
  durationSeconds?: number;
}

export interface ChatEvent {
  id: string;
  title: string;
  startTime: Date;
  endTime: Date;
  timezone: TimeZone;
  status: CHAT_EVENT_STATUS;
}

export interface ChatFileShareRequest {
  message: string;
  documentIds: string[];
}
