import { Epic } from "redux-observable";
import { of } from "rxjs";
import { catchError, filter, flatMap, switchMap } from "rxjs/operators";
import { isOfType } from "typesafe-actions";

import { CIQError, ErrorResponse } from "@arbolus-technologies/api";

import { BookmarksStoreActions } from "../BookmarksActions";
import { GET_COLLECTION } from "../actions/GetCollection/GetCollectionActionTypes";
import {
  BookmarksAppState,
  BookmarksDependencies,
  BookmarksStoreAction
} from "../models/definitions";

export const getCollectionEpic: Epic<
  BookmarksStoreAction,
  BookmarksStoreAction,
  BookmarksAppState,
  BookmarksDependencies
> = (action$, _, { notificationService, bookmarkService }) =>
  action$.pipe(
    filter(isOfType(GET_COLLECTION)),
    switchMap(({ payload: { id } }) =>
      bookmarkService.getBookmarkCollection(id).pipe(
        flatMap((response) =>
          of(BookmarksStoreActions.getCollectionSuccess(response))
        ),
        catchError((error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
          return of(BookmarksStoreActions.getCollectionFailure(error));
        })
      )
    )
  );
