import { ICellRendererParams } from "ag-grid-community";
import { Button } from "arbolus-ui-components";
import React from "react";
import { useDispatch } from "react-redux";

import {
  BookedReferral,
  DO_NOT_CONTACT_STATUS
} from "@arbolus-technologies/models/common";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";

import {
  OpenEventParams,
  ReferralGridContext
} from "../../Models/ReferralsTable";
import { DefaultFormatDateService } from "../../Services/Referrals/FormatDateService";

export const JoinCallRenderer: React.FC<
  ICellRendererParams<BookedReferral, never, ReferralGridContext>
> = ({ data, context }) => {
  const dispatch = useDispatch();

  const handleJoinCall = React.useCallback(() => {
    dispatch(
      PanelStoreActions.openPanel<OpenEventParams>(PanelId.ReferralEventPanel, {
        projectId: context.project.id,
        eventId: data!.latestEvent.id
      })
    );
  }, [data, context, dispatch]);

  if (!data) {
    return null;
  }

  return (
    <Button
      type="tertiary"
      endIcon="chevron_right"
      text={DefaultFormatDateService.formatDayTime(
        data.latestEvent.startTime,
        context.timezone
      )}
      onClick={handleJoinCall}
      disabled={data.expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC}
    />
  );
};
