import { RefObject, useEffect } from "react";

export const useOnResize = (
  elementRef: RefObject<HTMLElement>,
  onResize: Function
): void => {
  useEffect(() => {
    if (!elementRef.current) {
      return;
    }

    const observer = new ResizeObserver(() => onResize());

    observer.observe(elementRef.current);

    return () => observer.disconnect();
  }, [elementRef, onResize]);
};
