import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { ListProjectBase } from "@arbolus-technologies/api";
import { Drawer, HR } from "@arbolus-technologies/ui/components";

import { PROJECT_ROUTE } from "../../usersRoutes";

import styles from "./ProjectList.module.scss";

interface ProjectListProps {
  projects: ListProjectBase[];
  isActive: boolean;
}
export const ProjectList: React.FC<ProjectListProps> = ({
  projects,
  isActive
}) => {
  const { t } = useTranslation("clientUserProfilePanel");

  const handleActiveProjectClicked = (projectId: string): void => {
    window.open(PROJECT_ROUTE(projectId), "_blank");
  };

  return (
    <Drawer
      title={isActive ? t("activeProjects") : t("archivedProjects")}
      openByDefault={isActive}
    >
      {projects.map((project, key) => (
        <React.Fragment key={project.id}>
          <div
            className={clsx(
              styles.itemWrapper,
              isActive && styles.clickableItem
            )}
            onClick={(): void => {
              if (isActive) {
                handleActiveProjectClicked(project.id);
              }
            }}
          >
            <span
              className={clsx("status-dot", isActive ? "active" : "archived")}
            />
            <span>{project.name}</span>
          </div>
          {key !== projects.length - 1 && <HR />}
        </React.Fragment>
      ))}
    </Drawer>
  );
};
