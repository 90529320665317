import React from "react";

import { ResponseAllExpertsTable } from "@arbolus-technologies/api";
import { TanstackTable } from "@arbolus-technologies/ui/components";

import { ReferralsTableDefinition } from "../constants";

import styles from "./ReferralsTable.module.scss";

const tableClasses = {
  head: { th: styles.gridHeader },
  body: { td: styles.gridItem, firstTd: styles.firstInRow },
  noData: styles.noData
};

interface ReferralsTableProps {
  defaultData: ResponseAllExpertsTable[];
  columnsOptions: ReferralsTableDefinition;
  handleClickRow: (
    expertId: string,
    referralId: string,
    openAvailabilityTab?: boolean
  ) => void;
  expertProfilePath?: (expertId: string) => string;
  handleSlidePanelExpertProfile?: (
    projectId: string,
    expertId: string,
    referralId: string
  ) => void;
  handleEditTagline?: (
    referralId: string,
    projectId: string,
    tagline: string,
    expertTitle: string
  ) => void;
  handleSlidePanelAvailability?: (
    projectId: string,
    expertId: string,
    referralId: string
  ) => void;
}

export const ReferralsTable: React.FC<ReferralsTableProps> = ({
  columnsOptions,
  defaultData,
  handleClickRow,
  expertProfilePath,
  handleSlidePanelExpertProfile,
  handleEditTagline,
  handleSlidePanelAvailability
}) => {
  const handleRowClick = (cellId: string, data: ResponseAllExpertsTable) => {
    if (cellId === "tagline" && handleEditTagline) {
      handleEditTagline(
        data.id,
        data.projectId,
        data.tagline || "",
        data.expert.title
      );
    } else if (
      cellId !== "action" &&
      cellId !== "moreOptions" &&
      cellId !== "time"
    ) {
      handleClickRow(data.expert.id, data.id);
    }
  };

  const { columnDefinitions, gridTemplate } = columnsOptions;

  return (
    <div className={styles.referralsTableContainer}>
      <TanstackTable
        defaultData={defaultData}
        columnDefinitions={columnDefinitions}
        handleClickRow={handleRowClick}
        cellProps={{
          expertProfilePath,
          handleSlidePanelExpertProfile,
          handleSlidePanelAvailability
        }}
        classnames={tableClasses}
        gridTemplate={gridTemplate}
      />
    </div>
  );
};
