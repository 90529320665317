// Transcript Playback
export const TRANSCRIPT_SKIP_TIME = 15;

// Draft JS
export const TRANSCRIPT_DRAFT_JS_ENTITY = {
  OWN_HIGHLIGHT: "OWN_HIGHLIGHT_ENTITY",
  HIGHLIGHT: "HIGHLIGHT_ENTITY"
};

export const TRANSCRIPT_HIGHLIGHT_TRIM_WORD_LIMIT = 4;
