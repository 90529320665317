import React, { ForwardedRef, ReactNode, forwardRef } from "react";

interface Props {
  children: ReactNode;
}

const PrinterWrapper = ({ children }: Props, ref: ForwardedRef<null>) => (
  <div ref={ref}>{children}</div>
);

export default forwardRef(PrinterWrapper);
