import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { CanopyExpertStoreActions } from "@arbolus-technologies/stores/canopy-expert";

import { ValueForExpert } from "../../Components/ValueForExpert/ValueForExpert";

export const ValuePage: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CanopyExpertStoreActions.enableExpertQuestions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ValueForExpert />;
};
