import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import {
  CIQError,
  CanopyService,
  DefaultToasterService,
  ErrorResponse,
  ExpertCanopy,
  ToasterService
} from "@arbolus-technologies/api";
import { PAUSED_CANOPY_ROUTE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  LoaderOrComponent,
  PageWithSteps
} from "@arbolus-technologies/ui/components";
import { MainPageLayout } from "@arbolus-technologies/ui/layout";

import { Welcome } from "../Components/Welcome/Welcome";
import { getCanopySteps } from "../helpers/constants";

interface CanopyApplicationProps {
  canopyService?: typeof CanopyService;
  notificationService?: ToasterService;
}

const CANOPY_STEPS = getCanopySteps();

export const CanopyApplication: React.FC<CanopyApplicationProps> = ({
  canopyService = CanopyService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("expertApplications");
  const history = useHistory();
  const { canopyId } = useParams<{ canopyId: string }>();

  const [canopy, setCanopy] = useState<ExpertCanopy | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showWelcomeScreen, setShowWelcomeScreen] = useState<boolean>(true);
  const [totalRetries, setTotalRetries] = useState<number>(0);
  const [initialStep, setInitialStep] = useState<number>(0);

  const expertId = useSelector(CacheSelector.loggedInUserExpertId());

  const getCanopyInfo = useCallback(() => {
    if (totalRetries === 2) {
      notificationService.showError(t("unableToFetchCanopyInfo"));
      setTotalRetries(0);
      setIsLoading(false);
    } else {
      setIsLoading(true);

      canopyService.getExpertCanopy(canopyId, expertId).subscribe(
        (canopy) => {
          setCanopy(canopy);
          setIsLoading(false);
          setTotalRetries(0);
        },
        (error: ErrorResponse<CIQError>) => {
          if (error.status !== "INTERNAL_ERROR") {
            setTotalRetries((retries) => retries + 1);
            return canopyService
              .assignExpertToCanopy(expertId, canopyId)
              .subscribe(
                () => {
                  getCanopyInfo();
                },
                (error: ErrorResponse<CIQError>) => {
                  if (error.status === "1190") {
                    history.replace(PAUSED_CANOPY_ROUTE());
                  } else {
                    notificationService.showError(error.message);
                  }
                  setIsLoading(false);
                }
              );
          }
          notificationService.showError(error.message);
          setIsLoading(false);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canopyId, canopyService, expertId, notificationService, t]);

  useEffect(() => {
    getCanopyInfo();
  }, [getCanopyInfo]);

  useEffect(() => {
    let step = 0;

    canopy?.isCanopyAgreementAccepted && step++;

    setInitialStep(step);
  }, [canopy]);

  const filteredSteps = CANOPY_STEPS;

  const updatedSteps = filteredSteps.map((step) => ({
    ...step,
    name: t(step.name)
  }));

  return (
    <MainPageLayout title={t("projectApplication")}>
      <LoaderOrComponent isLoading={isLoading} isFull>
        {canopy &&
          (showWelcomeScreen ? (
            <Welcome
              isLoading={isLoading}
              onStartApplication={() => setShowWelcomeScreen(false)}
              stepDetails={filteredSteps.map((step) => t(`${step.name}Step`))}
            />
          ) : (
            <PageWithSteps
              steps={updatedSteps}
              initialStep={initialStep}
              stepComponentProps={{
                steps: updatedSteps,
                projectId: canopy.projectId,
                isAgreementAccepted: canopy.isCanopyAgreementAccepted,
                goToWelcomeScreen: () => setShowWelcomeScreen(true)
              }}
            />
          ))}
      </LoaderOrComponent>
    </MainPageLayout>
  );
};
