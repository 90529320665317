import React from "react";
import { AsyncTypeahead, TypeaheadModel } from "react-bootstrap-typeahead";
import { useTranslation } from "react-i18next";

import styles from "./SelectCloseButton.module.scss";

interface SelectCloseButtonProps<T extends TypeaheadModel> {
  onChange: (user?: T) => void;
  typeaheadRef: React.RefObject<AsyncTypeahead<T>>;
}

export const SelectCloseButton = <T extends TypeaheadModel>({
  onChange,
  typeaheadRef
}: SelectCloseButtonProps<T>): React.ReactElement => {
  const { t } = useTranslation("projectNx");
  return (
    <div className={styles.closeButton}>
      <button
        aria-label="Clear"
        className="close rbt-close"
        type="button"
        onClick={() => {
          onChange();
          typeaheadRef.current?.clear();
        }}
      >
        <span aria-hidden="true">×</span>
        <span className="sr-only visually-hidden">{t("clear")}</span>
      </button>
    </div>
  );
};
