import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ExpertService,
  ProjectExpertAvailability,
  ToasterService
} from "@arbolus-technologies/api";
import { PageStep } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

import { AvailabilityScheduler } from "../../Components/AvailabilityScheduler/AvailabilityScheduler";
import { ExpertApplicationWrapper } from "../../Components/ExpertApplicationWrapper/ExpertApplicationWrapper";

interface AvailabilityProps {
  name: string;
  setCurrentStep: (step: number) => void;
  steps: PageStep[];
  projectId: string;
  expertService: typeof ExpertService;
  notificationService: ToasterService;
}

export const Availability: React.FC<AvailabilityProps> = ({
  name,
  setCurrentStep,
  steps,
  projectId,
  expertService = ExpertService,
  notificationService = DefaultToasterService
}) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [availabilityDetails, setAvailabilityDetails] =
    useState<ProjectExpertAvailability>();

  const [expertTimezone, setExpertTimezone] = useState<string>("");
  const expertId = useSelector(CacheSelector.loggedInUserExpertId());

  useEffect(() => {
    setIsLoading(true);

    expertService.getProjectExpertAvailability(projectId, expertId).subscribe(
      (response) => {
        setAvailabilityDetails(response);
        setExpertTimezone(response.expertTimezone);
        setIsLoading(false);
      },
      (error: ErrorResponse<CIQError>) => {
        if (error.message === i18next.t("restService:accessDenied")) {
          history.push("/");
        } else {
          notificationService.showError(error.message);
          setIsLoading(false);
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = () => {
    /** */
  };

  const currentStep = steps.findIndex((step) => step.name === name);

  return (
    <ExpertApplicationWrapper
      currentStep={currentStep}
      name={name}
      isLoading={isLoading}
    >
      {availabilityDetails && (
        <AvailabilityScheduler
          availabilityDetails={availabilityDetails}
          currentTimezone={expertTimezone}
          onSave={handleSave}
          goToPrevStep={() => setCurrentStep(currentStep - 1)}
        />
      )}
    </ExpertApplicationWrapper>
  );
};
