import { Button } from "arbolus-ui-components";
import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Input, InputGroup, InputGroupText } from "reactstrap";

import {
  CIQError,
  ErrorResponse,
  UserService
} from "@arbolus-technologies/api";
import { arbolusLogo } from "@arbolus-technologies/theme";

import { AuthPageBase } from "../../Components/AuthPageBase/AuthPageBase";

import styles from "./SSOPage.module.scss";

export const SSOPage: React.FC = () => {
  const { t } = useTranslation("sso");
  const [isCheckingDomain, setIsCheckingDomain] = useState<boolean>(false);
  const [domain, setDomain] = useState<string>();
  const [isInvalidDomain, setIsInvalidDomain] = useState<boolean>(false);
  const isButtonDisabled =
    domain?.length === 0 || typeof domain === "undefined" || isCheckingDomain;

  const handleOnChange = (domain: string) => {
    setDomain(domain);
    if (isInvalidDomain) {
      setIsInvalidDomain(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      validateDomain();
    }
  };

  const validateDomain = () => {
    if (domain) {
      setIsCheckingDomain(true);
      UserService.authenticateUserOpenId(domain).subscribe(
        (response) => {
          setIsCheckingDomain(false);
          window.location.replace(response.url);
        },
        (_: ErrorResponse<CIQError>) => {
          setIsCheckingDomain(false);
          setIsInvalidDomain(true);
        }
      );
    }
  };

  return (
    <AuthPageBase>
      <div className={styles.loginContainer}>
        <div className={styles.header}>
          <h1 className={styles.title}>{t("employeeLogin")}</h1>
          <img src={arbolusLogo} alt="logo" height="48px" width="auto" />
        </div>
        <div className={styles.inputGroup}>
          <InputGroup>
            <Input
              name="login"
              placeholder={t("inputPlaceholder")}
              className={clsx(styles.input, {
                [styles.isInvalid]: isInvalidDomain
              })}
              onChange={(e) => handleOnChange(e.target.value)}
              onKeyDown={handleKeyDown}
              invalid={isInvalidDomain}
            />
            <InputGroupText
              className={clsx(styles.inputAppend, {
                [styles.isInvalid]: isInvalidDomain
              })}
            >
              {t("inputAppend")}
            </InputGroupText>
          </InputGroup>
          {isInvalidDomain && (
            <span className={styles.inputError}>{t("inputError")}</span>
          )}
        </div>
        <div className={styles.buttonsContainer}>
          <Link to="/login">
            <Button type="tertiary" text={t("back")} />
          </Link>
          <Button
            type="primary"
            disabled={isButtonDisabled}
            onClick={validateDomain}
            text={t("continue")}
          />
        </div>
      </div>
    </AuthPageBase>
  );
};
