import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./ThreeDotsUi.module.scss";

interface ThreeDotsUiProps {
  isPopoverOpen: boolean;
}

export const ThreeDotsUi: React.FC<ThreeDotsUiProps> = ({
  isPopoverOpen = false
}) => (
  <div
    className={clsx(
      styles.threeDots,
      isPopoverOpen ? styles.threeDotsPressed : null
    )}
  >
    <Icon
      name="more_horiz"
      color={
        isPopoverOpen
          ? ARBOLUS_COLORS.bColorBaseDark
          : ARBOLUS_COLORS.bColorGrayIcon
      }
      fontSize="24px"
    />
  </div>
);
