import React from "react";
import { useSelector } from "react-redux";

import { ProjectAnglesSelector } from "@arbolus-technologies/stores/project-angles-store";

import { AngleScreeningQuestionsDisabled } from "./AngleScreeningQuestionsDisabled";
import { AngleScreeningQuestionsEnabled } from "./AngleScreeningQuestionsEnabled";

interface AngleScreeningQuestionsProps {
  isEdit?: boolean;
  isAdmin?: boolean;
}

export const AngleScreeningQuestions: React.FC<
  AngleScreeningQuestionsProps
> = ({ isAdmin, isEdit = false }) => {
  const addScreeningQuestionView = useSelector(
    ProjectAnglesSelector.addScreeningQuestionsView()
  );
  const noScreeningQuestionsView = useSelector(
    ProjectAnglesSelector.noScreeningQuestionsView()
  );

  return (
    <>
      {addScreeningQuestionView && (
        <AngleScreeningQuestionsEnabled isAdmin={isAdmin} isEdit={isEdit} />
      )}
      {noScreeningQuestionsView && (
        <AngleScreeningQuestionsDisabled isAdmin={isAdmin} />
      )}
    </>
  );
};
