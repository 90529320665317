import React from "react";

import { APP_USER_ROLES } from "../../constants/app";

export interface AccessControlContextProps {
  role: string;
}

const defaultNavBarContext: AccessControlContextProps = {
  role: APP_USER_ROLES.guest
};

export const AccessControlContext =
  React.createContext<AccessControlContextProps>(defaultNavBarContext);

export const AccessControlContextProvider = AccessControlContext.Provider;
export const AccessControlContextConsumer = AccessControlContext.Consumer;
