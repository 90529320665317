import React from "react";
import { Link } from "react-router-dom";

import { PROJECT_FILES_ROUTE } from "@arbolus-technologies/routes";
import { GenericAttachmentIcon } from "@arbolus-technologies/ui/components";

import styles from "./MoreDocuments.module.scss";

interface MoreDocumentsProps {
  count: number;
  projectId: string;
}

export const MoreDocuments: React.FC<MoreDocumentsProps> = ({
  count,
  projectId
}) => (
  <Link className={styles.container} to={PROJECT_FILES_ROUTE(projectId)}>
    <GenericAttachmentIcon
      customHeight="24px"
      customWidth="24px"
      fontSize="12px"
    />
    <span className={styles.count}>+ {count}</span>
  </Link>
);
