import { CanopyQuestion } from "@arbolus-technologies/api";
import {
  ANSWER_PREFERENCES_TYPE,
  AnswersRange,
  FormConstraints
} from "@arbolus-technologies/models/canopy";

export const handleAnswerPreferenceType = (
  questionSelected: CanopyQuestion
): ANSWER_PREFERENCES_TYPE => {
  if (questionSelected.answersRange && questionSelected.optionChoices) {
    if (
      questionSelected.answersRange.minimum ===
      questionSelected.answersRange.maximum
    ) {
      return ANSWER_PREFERENCES_TYPE.EXACT;
    } else if (
      questionSelected.answersRange.minimum === 1 &&
      questionSelected.answersRange.maximum ===
        questionSelected.optionChoices.length
    ) {
      return ANSWER_PREFERENCES_TYPE.UNLIMITED;
    }
    return ANSWER_PREFERENCES_TYPE.RANGE;
  }
  return ANSWER_PREFERENCES_TYPE.EXACT;
};

export const answerPreferenceRangeNumbers = (
  questionSelected: CanopyQuestion,
  preferenceType: ANSWER_PREFERENCES_TYPE
): AnswersRange => {
  if (questionSelected.answersRange && questionSelected.optionChoices) {
    if (preferenceType === ANSWER_PREFERENCES_TYPE.RANGE) {
      return {
        minimum: questionSelected.answersRange.minimum,
        maximum: questionSelected.answersRange.maximum
      };
    }
    // When default value is not range, return length - 1 as min and choices length as max
    // to fit range numbers when coming from unlimited or exact types
    return {
      minimum:
        questionSelected.optionChoices.length - 1 ||
        FormConstraints.MIN_ANSWER_CHOICES,
      maximum:
        questionSelected.optionChoices?.length ||
        FormConstraints.MAX_ANSWER_CHOICES
    };
  }
  return {
    minimum: FormConstraints.MIN_ANSWER_CHOICES,
    maximum: FormConstraints.MAX_ANSWER_CHOICES
  };
};
