import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router";

import {
  MixPanelEventNames,
  useTimeTracking
} from "@arbolus-technologies/features/common";
import {
  CANOPY_EXPERTS,
  CANOPY_SINGLE_EXPERT
} from "@arbolus-technologies/routes";
import { CanopyV2Selector } from "@arbolus-technologies/stores/canopy-V2";

import { ExpertDetails } from "./ExpertDetails/ExpertDetails";
import { ExpertsTabWrapper } from "./ExpertsTabWrapper/ExpertsTabWrapper";

export const CanopyExperts: React.FC = () => {
  const { startTrackingTime, endTrackingTime } = useTimeTracking(
    MixPanelEventNames.CanopyExpertsTimeSpent
  );

  const canopyData = useSelector(CanopyV2Selector.canopyData());

  useEffect(() => {
    startTrackingTime();

    return () => {
      endTrackingTime({
        canopyId: canopyData?.id,
        canopyName: canopyData?.title
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      <Route path={CANOPY_EXPERTS} component={ExpertsTabWrapper} exact />
      <Route path={CANOPY_SINGLE_EXPERT} component={ExpertDetails} exact />
    </Switch>
  );
};
