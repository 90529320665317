import { Button } from "arbolus-ui-components";
import React, { useCallback, useState } from "react";
import { FieldError, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";

import { IEntity, PROJECT_STATES } from "@arbolus-technologies/api";
import {
  DragAndDropFileBox,
  InputController,
  Loader
} from "@arbolus-technologies/ui/components";

import { CanopySelector } from "../../../Selectors/CanopySelector/CanopySelector";
import { ClientAdminSelector } from "../../../Selectors/ClientSelectors/ClientAdminSelector";
import { ProjectEventAdminSelector } from "../../../Selectors/EventSelector/ProjectEventAdminSelector";
import { ProjectAdminSelector } from "../../../Selectors/ProjectSelectors/ProjectAdminSelector";
import { isSupportedFileType } from "../../../documentUtils";
import { INCIDENT_CATEGORY, INCIDENT_FORM } from "../../../enums";
import { IncidentFormInterface } from "../../../interfaces";
import { IncidentCategorySelector } from "./Components/IncidentCategorySelector";
import { IncidentPrioritySelector } from "./Components/IncidentPrioritySelector";

import styles from "./ReportIncidentForm.module.scss";

interface ReportIncidentFormProps {
  onSubmit: () => void;
  onCancel: () => void;
  isLoading: boolean;
}

export const ReportIncidentForm: React.FC<ReportIncidentFormProps> = ({
  onSubmit,
  onCancel,
  isLoading
}) => {
  const { t } = useTranslation("incidentForm");
  const {
    control,
    formState: { errors },
    setValue,
    watch,
    register
  } = useFormContext<IncidentFormInterface>();

  const attachments: File[] = watch(INCIDENT_FORM.ATTACHMENTS);

  const handleOnSubmit = () => {
    onSubmit();
  };

  const handleOnDrop = (files: File[]) => {
    setValue(INCIDENT_FORM.ATTACHMENTS, [...attachments, ...files]);
  };

  const onClickAttachment = (index: number) => {
    const updatedAttachments = attachments.filter(
      (attachment) => attachment !== attachments[index]
    );
    setValue(INCIDENT_FORM.ATTACHMENTS, updatedAttachments);
  };
  const onSelectEntity = useCallback(
    (entity: IEntity) => setValue(INCIDENT_FORM.ENTITY, entity),
    [setValue]
  );

  const getCategorySpecificFields = useCallback(
    (category: string): React.ReactNode => {
      const entityError = errors[INCIDENT_FORM.ENTITY] as FieldError;
      const entityIdError = errors[INCIDENT_FORM.ENTITY_ID];
      switch (category) {
        case INCIDENT_CATEGORY.CALL:
          return (
            <>
              <Label className={styles.label}>{t("projectLabel")}</Label>
              <ProjectEventAdminSelector
                name={INCIDENT_FORM.ENTITY}
                labelStyle={styles.label}
                label={t("eventLabel")}
                error={entityError}
                control={control}
                onSelect={onSelectEntity}
              />
            </>
          );
        case INCIDENT_CATEGORY.CANOPY:
          return (
            <>
              <Label className={styles.label}>{t("canopyLabel")}</Label>
              <CanopySelector
                name={INCIDENT_FORM.ENTITY}
                error={entityError}
                control={control}
                onSelect={onSelectEntity}
              />
            </>
          );
        case INCIDENT_CATEGORY.CLIENTS:
          return (
            <>
              <Label className={styles.label}>{t("clientLabel")}</Label>
              <ClientAdminSelector
                name={INCIDENT_FORM.ENTITY}
                control={control}
                error={entityError}
                onSelect={onSelectEntity}
              />
            </>
          );
        case INCIDENT_CATEGORY.EXPERTS:
          return (
            <>
              <Label className={styles.label}>{t("expertLabel")}</Label>
              <InputController
                name={INCIDENT_FORM.ENTITY_ID}
                type="text"
                error={entityIdError}
                control={control}
                placeholder={t("expertPlaceholder")}
              />
            </>
          );
        case INCIDENT_CATEGORY.NOTIFICATIONS:
          return (
            <>
              <Label className={styles.label}>{t("userLabelMandatory")}</Label>
              <InputController
                name={INCIDENT_FORM.ENTITY_ID}
                type="text"
                control={control}
                placeholder={t("userPlaceholder")}
              />
            </>
          );
        case INCIDENT_CATEGORY.OTHER:
          return undefined;
        case INCIDENT_CATEGORY.PROJECTS:
          return (
            <>
              <Label className={styles.label}>{t("projectLabel")}</Label>
              <ProjectAdminSelector
                control={control}
                name={INCIDENT_FORM.ENTITY}
                error={entityError}
                projectStates={Object.values(PROJECT_STATES)}
                displayResults={10}
                onSelect={onSelectEntity}
              />
            </>
          );
        case INCIDENT_CATEGORY.TRANSACTIONS:
          return (
            <>
              <Label className={styles.label}>{t("transactionLabel")}</Label>
              <InputController
                name={INCIDENT_FORM.ENTITY_ID}
                type="text"
                error={entityIdError}
                control={control}
                placeholder={t("transactionPlaceholder")}
              />
            </>
          );
        case INCIDENT_CATEGORY.USERS:
          return (
            <>
              <Label className={styles.label}>{t("userLabel")}</Label>
              <InputController
                name={INCIDENT_FORM.ENTITY_ID}
                type="text"
                error={entityIdError}
                control={control}
                placeholder={t("userPlaceholder")}
              />
            </>
          );
        case INCIDENT_CATEGORY.DASHBOARDS:
          return (
            <>
              <Label className={styles.label}>{t("dashboardLabel")}</Label>
              <InputController
                name={INCIDENT_FORM.ENTITY_ID}
                type="text"
                error={entityIdError}
                control={control}
                placeholder={t("dashboardPlaceholder")}
              />
            </>
          );
      }
    },
    [control, errors, onSelectEntity, t]
  );

  const [categoryFields, setCategoryFields] = useState<React.ReactNode>();

  const onChangeCategory = useCallback(
    (category: string) => {
      setValue(INCIDENT_FORM.ENTITY_ID, "");
      setValue(INCIDENT_FORM.ENTITY, { id: "" });
      setCategoryFields(getCategorySpecificFields(category));
    },
    [getCategorySpecificFields, setValue]
  );

  return isLoading ? (
    <Loader isFull />
  ) : (
    <div className={styles.container}>
      <div className={styles.fieldsContainer}>
        <div className={styles.selectorGroupContainer}>
          <div className={styles.selectorContainer}>
            <Label className={styles.label}>{t("categoryLabel")}</Label>
            <IncidentCategorySelector
              placeholder={t("categorySelectPlaceholder")}
              noOptionsMessage={t("categorySelectPlaceholder")}
              watch={watch}
              control={control}
              error={errors[INCIDENT_FORM.CATEGORY]?.message}
              onChange={onChangeCategory}
            />
          </div>
          <div className={styles.selectorContainer}>
            <Label className={styles.label}>{t("priorityLabel")}</Label>
            <IncidentPrioritySelector
              placeholder={t("prioritySelectPlaceholder")}
              noOptionsMessage={t("prioritySelectPlaceholder")}
              control={control}
              watch={watch}
              error={errors[INCIDENT_FORM.PRIORITY]?.message}
            />
          </div>
        </div>
        <div className={styles.titleContainer}>
          <Label className={styles.label}>{t("title")}</Label>
          <InputController
            name={INCIDENT_FORM.TITLE}
            type="text"
            error={errors[INCIDENT_FORM.TITLE]}
            control={control}
            placeholder={t("titlePlaceholder")}
            required
          />
        </div>
        {categoryFields}
        <div className={styles.overviewContainer}>
          <Label className={styles.label}>{t("overview")}</Label>
          <InputController
            name={INCIDENT_FORM.OVERVIEW}
            type="textarea"
            error={errors[INCIDENT_FORM.OVERVIEW]}
            control={control}
            placeholder={t("overviewPlaceholder")}
            required
          />
        </div>
        <DragAndDropFileBox
          onClickFile={onClickAttachment}
          onDropFiles={handleOnDrop}
          filesLimit={3}
          files={attachments}
          optional
          maxFileSize={50}
          isValidFile={isSupportedFileType}
          {...register(INCIDENT_FORM.ATTACHMENTS)}
        />
      </div>
      <div className={styles.buttonsContainer}>
        <button className={styles.cancelButton} onClick={onCancel}>
          {t("cancel")}
        </button>
        <Button
          type="primary"
          onClick={handleOnSubmit}
          text={t("submit")}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};
