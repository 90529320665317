import { APP_USER_ROLES } from "../app";
import {
  CLIENT_ADMIN_ROUTES,
  CLIENT_ROUTES,
  EXPERT_ROUTES
} from "./authRoutes";

export const ROLE_BASE_BLOCKED_ROUTES = {
  [`${APP_USER_ROLES.adminClient}`]: [...EXPERT_ROUTES],
  [`${APP_USER_ROLES.client}`]: [...CLIENT_ADMIN_ROUTES, ...EXPERT_ROUTES],
  [`${APP_USER_ROLES.expert}`]: [...CLIENT_ADMIN_ROUTES, ...CLIENT_ROUTES],
  [`${APP_USER_ROLES.guest}`]: []
};
