import React from "react";
import { TypeaheadResult } from "react-bootstrap-typeahead";
import { useTranslation } from "react-i18next";

import {
  ApiPaginatedRequest,
  CompanyService,
  LIST_COMPANIES_ORDER_BY,
  SORT_DIRECTION
} from "@arbolus-technologies/api";

import {
  SelectController,
  TypeaheadSelectorControllerProps
} from "../SelectTypeahead/SelectController";

interface DisplayCompany {
  name: string;
}

export const CompanySelector = ({
  placeholderText,
  ...props
}: TypeaheadSelectorControllerProps<DisplayCompany>): React.ReactElement => {
  const { t } = useTranslation();
  const placeholder =
    placeholderText ?? t("common:companySelection:findCompany");

  const renderer = ({ name }: TypeaheadResult<DisplayCompany>): JSX.Element => (
    <span>{name}</span>
  );

  const getItems = (pagination: ApiPaginatedRequest) =>
    CompanyService.listCompanies({
      offset: pagination.offset,
      limit: pagination.limit,
      searchTerm: pagination.searchTerm,
      sort: [
        {
          orderBy: LIST_COMPANIES_ORDER_BY.Name,
          orderDirection: SORT_DIRECTION.ASCENDING
        }
      ]
    });

  return (
    <SelectController
      {...props}
      placeholderText={placeholder}
      promptText={t("common:companySelection:findingCompanies")}
      searchText={t("common:companySelection:findingCompanies")}
      paginationText={t("common:companySelection:loadMore")}
      orderBy="name"
      renderer={renderer}
      getLabelKey={(company: DisplayCompany) => company.name}
      getItems={getItems}
    />
  );
};
