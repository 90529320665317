import i18next from "i18next";
import * as Yup from "yup";

import { UserConstraints } from "../../../../constants/validation";
import { UtilsService } from "../../../../services";

export const NewUserCiqValidationSchema = Yup.object({
  profileImage: Yup.mixed(),
  firstName: Yup.string()
    .max(
      UserConstraints.MAX_FIRST_NAME_LENGTH,
      i18next.t("newUserPage:firstNameMaxLengthError", {
        length: UserConstraints.MAX_FIRST_NAME_LENGTH
      })
    )
    .required(i18next.t("newUserPage:firstNameRequireError")),
  lastName: Yup.string()
    .max(
      UserConstraints.MAX_LAST_NAME_LENGTH,
      i18next.t("newUserPage:lastNameMaxLengthError", {
        length: UserConstraints.MAX_LAST_NAME_LENGTH
      })
    )
    .required(i18next.t("newUserPage:lastNameRequireError")),
  title: Yup.string()
    .max(
      UserConstraints.MAX_TITLE_LENGTH,
      i18next.t("newUserPage:jobTitleMaxLengthError", {
        length: UserConstraints.MAX_TITLE_LENGTH
      })
    )
    .required(i18next.t("newUserPage:jobTitleRequireError")),
  country: Yup.object().shape({
    value: Yup.string().required(i18next.t("newUserPage:countryRequired")),
    label: Yup.string().required(i18next.t("newUserPage:countryRequired"))
  }),
  phoneNumber: Yup.string()
    .test(
      "phone-valid",
      i18next.t("newUserPage:phoneNumberInvalid"),
      (val: string) =>
        val ? UtilsService.validateContactNumber(`+${val}`) : true
    )
    .nullable(),
  email: Yup.string()
    .required(i18next.t("newUserPage:emailRequireError"))
    .email(i18next.t("newUserPage:emailNotValidError"))
    .max(
      UserConstraints.MAX_EMAIL_LENGTH,
      i18next.t("newUserPage:maxEmailLength", {
        length: UserConstraints.MAX_EMAIL_LENGTH
      })
    )
});

export const NewUserValidationSchema = NewUserCiqValidationSchema.concat(
  Yup.object().shape({
    displayName: Yup.string().when("isRealNameEnabled", {
      is: false,
      then: Yup.string().required(
        i18next.t("newUserPage:displayNameRequiredError")
      ),
      otherwise: Yup.string().nullable()
    })
  })
);
