import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";

import { PageStep } from "@arbolus-technologies/models/common";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./StepsSidebar.module.scss";

interface StepsSidebarProps {
  steps: PageStep[];
  currentStep: number;
  visitedSteps: number[];
  changeStepOnClick: boolean;
  onClick: (stepNum: number) => void;
}

export const StepsSidebar: React.FC<StepsSidebarProps> = ({
  steps,
  currentStep,
  visitedSteps,
  changeStepOnClick,
  onClick
}) => (
  <div className={styles.steps}>
    {steps.map(({ name, stepIcon, disabled = false }, index) => {
      const wasVisited = visitedSteps.includes(index);

      return (
        <StepSidebarItem
          key={name}
          disabled={disabled}
          stepNum={index + 1}
          stepName={name}
          isActive={currentStep === index}
          wasVisited={wasVisited}
          isClickable={
            wasVisited || visitedSteps.length === index || changeStepOnClick
          }
          stepIcon={stepIcon}
          onClick={onClick}
        />
      );
    })}
  </div>
);

interface StepProps {
  stepNum: number;
  stepName: string;
  stepIcon?: string;
  isActive: boolean;
  wasVisited: boolean;
  isClickable: boolean;
  disabled: boolean;
  onClick: (stepNum: number) => void;
}

const StepSidebarItem: React.FC<StepProps> = ({
  stepNum,
  stepName,
  stepIcon,
  isActive,
  wasVisited,
  isClickable,
  onClick,
  disabled
}) => (
  <div
    className={clsx(styles.step, {
      [styles.activeStep]: isActive,
      [styles.clickable]: isClickable,
      [styles.isDisabled]: disabled
    })}
    onClick={() => isClickable && onClick(stepNum - 1)}
    onKeyDown={() => isClickable && onClick(stepNum - 1)}
  >
    {stepIcon ? (
      <Icon
        name={stepIcon}
        fontSize="20px"
        color={
          isActive
            ? ARBOLUS_COLORS.bColorBasePurple
            : ARBOLUS_COLORS.bColorSecondaryGreyDark
        }
      />
    ) : (
      <span
        className={clsx(styles.stepNum, {
          [styles.highlightedStepNum]: isActive || wasVisited
        })}
      >
        {wasVisited && !isActive ? (
          <Icon name="done" fontSize="20px" />
        ) : (
          stepNum
        )}
      </span>
    )}
    {stepName}
  </div>
);
