import React from "react";
import { useTranslation } from "react-i18next";

import {
  COMPLIANCE_QUESTION_TYPE,
  ComplianceQuestion
} from "@arbolus-technologies/models/common";

import styles from "./PanelComplianceQuestion.module.scss";

interface PanelComplianceQuestionProps {
  question: ComplianceQuestion;
}

export const PanelComplianceQuestion: React.FC<
  PanelComplianceQuestionProps
> = ({ question }) => {
  const { t } = useTranslation("panelComplianceQuestion");
  const detailsText =
    question.type === COMPLIANCE_QUESTION_TYPE.YesNoText
      ? t("detailsRequired")
      : t("detailsNotRequired");

  return (
    <div className={styles.container}>
      <p className={styles.title}>{`${t("question")} #${
        question.sortOrder + 1
      }`}</p>
      <p className={styles.text}>{question.questionText}</p>
      {question.expectedAnswer && (
        <p className={styles.details}>{`${t("expectedAnswer")} ${
          question.expectedAnswer
        }`}</p>
      )}
      <p className={styles.details}>{detailsText}</p>
    </div>
  );
};
