import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { ExpertProfile } from "@arbolus-technologies/models/canopy-panels";
import { EXPERT_FOLLOW_UP_STEPS } from "@arbolus-technologies/models/expert";
import { ExpertNxSelector } from "@arbolus-technologies/stores/expert";
import { HR, UserPortrait } from "@arbolus-technologies/ui/components";

import { MixPanelEventNames } from "../../../Mixpanel/enums";
import { useArbolusTracking } from "../../../Mixpanel/useArbolusTracking";
import { EngageExpert } from "./EngageExpert/EngageExpert";
import { SendAdditionalQuestions } from "./SendAdditionalQuestions/SendAdditionalQuestions";

import styles from "./ExpertFollowUpSteps.module.scss";

interface ExpertFollowUpStepsProps {
  expertDetails: ExpertProfile;
  canopyId: string;
  onSendMessage: (question?: string) => void;
}

export const ExpertFollowUpSteps: React.FC<ExpertFollowUpStepsProps> = ({
  expertDetails,
  canopyId,
  onSendMessage
}) => {
  const { trackSlidePanel } = useArbolusTracking();

  useEffect(() => {
    trackSlidePanel(MixPanelEventNames.CanopyFollowUpPanel, {
      canopyId,
      expertId: expertDetails.expertId
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const step: EXPERT_FOLLOW_UP_STEPS = useSelector(
    ExpertNxSelector.expertFollowUpStep()
  );

  return (
    <>
      <div className={styles.userPortraitContainer}>
        <UserPortrait user={expertDetails} avatarSize="bigXSCircle" />
      </div>
      <HR margin={{ top: 3, bottom: 3 }} />
      {step === EXPERT_FOLLOW_UP_STEPS.ENGAGE_EXPERT && (
        <EngageExpert
          onBook={onSendMessage}
          expertId={expertDetails.expertId}
          canopyId={canopyId}
        />
      )}
      {step === EXPERT_FOLLOW_UP_STEPS.SEND_ADDITIONAL_QUESTIONS && (
        <SendAdditionalQuestions
          onSendMessage={onSendMessage}
          expertId={expertDetails.expertId}
          canopyId={canopyId}
        />
      )}
    </>
  );
};
