import i18next from "i18next";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import {
  DO_NOT_CONTACT_STATUS,
  REFERRAL_SUB_STATE
} from "@arbolus-technologies/models/common";
import { Compliance } from "@arbolus-technologies/models/project";

import {
  ComplianceAnswerOptions,
  ProjectComplianceQuestionType
} from "../../../../../../constants/expert";
import { ComplianceStatusRequest } from "../../../../../../models/project";
import { User } from "../../../../../../models/user";
import FeatureProtectedElement from "../../../../../app/components/featureManagement/FeatureManager";
import ComplianceItem from "./ComplianceItem";
import ComplianceApprovalForm from "./approval/ComplianceApprovalForm";
import ComplianceReviewedPane from "./reviewed/ComplianceReviewedPane";

interface ComplianceTabProps extends WithTranslation {
  compliances: Compliance[];
  expertName: string;
  complianceStatus: string;
  complianceUpdatedBy: User;
  canReviewCompliance: boolean;
  isComplianceLoading: boolean;
  chaperoneCall?: boolean;
  complianceNote?: string;
  updateDate?: Date;
  doNotContactStatus: DO_NOT_CONTACT_STATUS;
  onComplianceSubmit: (complianceStatus: ComplianceStatusRequest) => void;
}

const FIRST_CALL_STATUS = {
  YES: i18next.t("expertComplianceTab:yes"),
  NO: i18next.t("expertComplianceTab:no")
};

class ComplianceTab extends React.Component<ComplianceTabProps, {}> {
  renderComplianceAnswer = (compliance: Compliance): JSX.Element => {
    const { t } = this.props;
    const { question, answer, questionId } = compliance;
    const { displayModel } = question;
    const { questionType, expectedAnswer, importance, textRequiredValue } =
      displayModel;
    const { Text, YesNoText: YesNoTextType } = ProjectComplianceQuestionType;
    const { Yes, No, NotApplicable, NotSet } = ComplianceAnswerOptions;

    const COMPLIANCE_ANSWER = new Map<string, string>([
      [Yes, t("yes")],
      [No, t("no")],
      [NotApplicable, t("notApplicable")],
      [NotSet, t("notSet")]
    ]);

    let yesNoTextAnswer;

    if (
      answer?.answer &&
      textRequiredValue &&
      answer.answer === textRequiredValue
    ) {
      yesNoTextAnswer = answer.textAnswer;
    }

    if (questionType === Text) {
      return (
        <ComplianceItem
          key={questionId}
          question={question.question}
          textAnswer={answer?.answer}
          importance={importance}
        />
      );
    }
    if (questionType === YesNoTextType) {
      return (
        <ComplianceItem
          key={questionId}
          question={question.question}
          answer={COMPLIANCE_ANSWER.get(answer?.answer!)}
          textAnswer={yesNoTextAnswer}
          expectedAnswer={expectedAnswer}
          importance={importance}
        />
      );
    }
    return (
      <ComplianceItem
        key={questionId}
        question={question.question}
        answer={COMPLIANCE_ANSWER.get(answer?.answer!)}
        expectedAnswer={expectedAnswer}
        importance={importance}
      />
    );
  };

  renderComplianceApproval = (): JSX.Element => {
    const {
      complianceStatus,
      expertName,
      complianceUpdatedBy,
      onComplianceSubmit,
      chaperoneCall,
      complianceNote,
      isComplianceLoading,
      updateDate
    } = this.props;
    const isPendingCompliance = complianceStatus === REFERRAL_SUB_STATE.PENDING;

    const firstCall = chaperoneCall
      ? FIRST_CALL_STATUS.YES
      : FIRST_CALL_STATUS.NO;

    if (isPendingCompliance) {
      return (
        <FeatureProtectedElement featureName="complianceManager">
          <ComplianceApprovalForm
            expertName={expertName}
            onComplianceSubmit={onComplianceSubmit}
            isComplianceLoading={isComplianceLoading}
          />
        </FeatureProtectedElement>
      );
    }

    return (
      <ComplianceReviewedPane
        complianceStatus={complianceStatus}
        complianceNote={complianceNote}
        complianceUpdatedBy={complianceUpdatedBy}
        firstCall={firstCall}
        updateDate={updateDate}
      />
    );
  };

  render(): JSX.Element {
    const { compliances, t, canReviewCompliance, doNotContactStatus } =
      this.props;

    return (
      <div className="compliance-answers-container">
        <h2>{t("complianceCheck")}</h2>
        {compliances.map((compliance) =>
          this.renderComplianceAnswer(compliance)
        )}
        {canReviewCompliance &&
          doNotContactStatus !== DO_NOT_CONTACT_STATUS.DNC &&
          this.renderComplianceApproval()}
      </div>
    );
  }
}

export default withTranslation("expertComplianceTab")(ComplianceTab);
