import { createSelector } from "reselect";

import {
  ProjectNxReducerState,
  ProjectNxSelector,
  ReferralsTabCount
} from "../models/definitions";
import { ProjectStateSelector } from "./ProjectStateSelector";

export function referralTabCount(): ProjectNxSelector<ReferralsTabCount> {
  return createSelector(
    ProjectStateSelector,
    (projectReducerState: ProjectNxReducerState) =>
      projectReducerState.referrals.tabCount
  );
}
