import { Route } from "react-router";

import { CanopyListPage } from "@arbolus-technologies/features/canopyV2";
import {
  ClientProjectDashboard,
  DiscoverPage,
  ExpertProjectDashboard,
  ProjectSpendPage,
  TranscriptsListPage
} from "@arbolus-technologies/features/projects";
import { PROJECT_DISCOVER_BASE } from "@arbolus-technologies/routes";

import {
  EDIT_PROJECT,
  PROJECT_BASE,
  PROJECT_CANOPIES,
  PROJECT_EVENT_TRANSCRIPT,
  PROJECT_REFER_EXPERT,
  PROJECT_SPEND,
  PROJECT_TRANSCRIPTS
} from "../../constants/navigation/projectRoutes";
import { TranscriptPage } from "./pages";
import { SimplifiedProjectEditPage } from "./pages/SimplifiedProjectEditPage/SimplifiedProjectEditPage";
import ReferExpertPage from "./pages/referExpert/ReferExpertPage";

export const ExpertProjectRouter = [
  <Route
    key={PROJECT_REFER_EXPERT}
    path={PROJECT_REFER_EXPERT}
    component={ReferExpertPage}
  />,
  <Route
    key={PROJECT_BASE}
    path={PROJECT_BASE}
    exact
    component={ExpertProjectDashboard}
  />
];

export const ClientProjectRouter = [
  <Route
    key={PROJECT_BASE}
    path={PROJECT_BASE}
    exact
    component={ClientProjectDashboard}
  />,
  <Route
    key={PROJECT_TRANSCRIPTS}
    path={PROJECT_TRANSCRIPTS}
    component={TranscriptsListPage}
  />,
  <Route
    key={PROJECT_SPEND}
    path={PROJECT_SPEND}
    component={ProjectSpendPage}
  />,
  <Route
    key={EDIT_PROJECT}
    path={EDIT_PROJECT}
    exact
    component={SimplifiedProjectEditPage}
  />,
  <Route
    key={PROJECT_EVENT_TRANSCRIPT}
    path={PROJECT_EVENT_TRANSCRIPT}
    component={TranscriptPage}
  />,
  <Route
    key={PROJECT_CANOPIES}
    path={PROJECT_CANOPIES}
    component={CanopyListPage}
  />,
  <Route
    key={PROJECT_DISCOVER_BASE}
    path={PROJECT_DISCOVER_BASE}
    component={DiscoverPage}
  />
];
