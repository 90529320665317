import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "arbolus-ui-components";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import {
  CIQError,
  CanopyService,
  DefaultToasterService,
  ErrorResponse,
  ToasterService
} from "@arbolus-technologies/api";
import { CANOPY_ROUTE } from "@arbolus-technologies/routes";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { bolt } from "@arbolus-technologies/theme";
import {
  HR,
  InfoDrawer,
  InputController,
  SquareNumber,
  WordCounter
} from "@arbolus-technologies/ui/components";

import { MixPanelEventNames } from "../../Mixpanel/enums";
import { useArbolusTracking } from "../../Mixpanel/useArbolusTracking";
import { SelectableBoxList } from "./Components/SelectableBoxList/SelectableBoxList";
import { prepareData } from "./SimpleCanopyCreationData";
import { SimpleCreationCanopySchema } from "./SimpleCreationCanopySchema";

import styles from "./SimpleCanopyCreationProvider.module.scss";

export interface CanopyCreationType {
  canopyType: ICanopyCreationType;
  canopyTemplate?: string;
}
export interface ISimpleCanopyCreationForm {
  title: string;
  brief: string;
  canopyCreationType: CanopyCreationType;
}

export enum ICanopyCreationType {
  MANUAL = "Manual",
  CANOPAI = "Canopai",
  TEMPLATE = "Template"
}

interface SimpleCanopyCreationProviderProps {
  notificationService?: ToasterService;
  projectId?: string;
}

export const SimpleCanopyCreationProvider: React.FC<
  SimpleCanopyCreationProviderProps
> = ({ notificationService = DefaultToasterService, projectId }) => {
  const { t } = useTranslation("simpleCanopyCreationProvider");
  const dispatch = useDispatch();
  const { trackClick, trackSlidePanel } = useArbolusTracking();
  const [isLoading, setIsLoading] = useState(false);
  const [errorCanopyRequest, setErrorCanopyRequest] = useState<
    string | undefined
  >(undefined);

  const history = useHistory();

  useEffect(() => {
    trackSlidePanel(MixPanelEventNames.CanopyCreationPanel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closePanel = () => {
    trackClick(MixPanelEventNames.CanopyCreationPanelCancel);
    dispatch(PanelStoreActions.closePanel(PanelId.CreateSimpleCanopy));
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<ISimpleCanopyCreationForm>({
    resolver: yupResolver(SimpleCreationCanopySchema),
    mode: "onTouched",
    defaultValues: {
      title: "",
      brief: "",
      canopyCreationType: {
        canopyType: ICanopyCreationType.MANUAL,
        canopyTemplate: undefined || ""
      }
    }
  });

  const onSubmit = (data: ISimpleCanopyCreationForm) => {
    trackClick(MixPanelEventNames.CanopyCreationPanelAddQuestions);
    setIsLoading(true);
    CanopyService.createCanopy(prepareData(data, projectId)).subscribe(
      (response) => {
        setErrorCanopyRequest(undefined);
        notificationService.showSuccess(
          t("SimpleCanopyCreationProvider:canopyCreated")
        );
        setIsLoading(false);
        history.push(CANOPY_ROUTE(response.id));
      },
      (error: ErrorResponse<CIQError>) => {
        setErrorCanopyRequest(error.message);
        setIsLoading(false);
      }
    );
  };

  const contentDrawer = (
    <div className={styles.drawerContainer}>
      <div className={styles.containerPoints}>
        <div className={styles.drawerNumberContainer}>
          <SquareNumber number="1" isActive={false} />
          {t("drawer1")}
        </div>
        <div className={styles.drawerNumberContainer}>
          <SquareNumber number="2" isActive={false} />
          {t("drawer2")}
        </div>
        <div className={styles.drawerNumberContainer}>
          <SquareNumber number="3" isActive={false} />
          {t("drawer3")}
        </div>
        <div className={styles.drawerNumberContainer}>
          <SquareNumber number="4" isActive={false} />
          {t("drawer4")}
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <InfoDrawer
        icon={bolt}
        title={t("drawerTitle")}
        collapseContent={contentDrawer}
        isOpenByDefault={false}
      />
      <div className={styles.inputGroupWrapper}>
        <div className={styles.inputGroup}>
          <div className={styles.span}>{t("title")}</div>
          <InputController
            name="title"
            control={control}
            error={errors.title}
            newError={errorCanopyRequest}
            placeholder={t("titlePlaceholder")}
            onHandleChange={() => setErrorCanopyRequest(undefined)}
            onTrackInput={() =>
              trackClick(MixPanelEventNames.CanopyCreationPanelTitle)
            }
          />
        </div>
        <div className={styles.inputGroup}>
          <div className={styles.span}>{t("description")}</div>
          <InputController
            name="brief"
            control={control}
            error={errors.brief}
            placeholder={t("descriptionPlaceholder")}
            size="big"
            type="textarea"
            onTrackInput={() =>
              trackClick(MixPanelEventNames.CanopyCreationPanelDescription)
            }
          />
          <div className={styles.wordCounterContainer}>
            <WordCounter maxLength={2000} textLength={watch("brief").length} />
          </div>
        </div>
      </div>
      <div className={styles.canopyType}>{t("selectCanopyType")}</div>
      <div className={styles.selectableBoxContainer}>
        <Controller
          name="canopyCreationType"
          control={control}
          render={({ field }) => (
            <SelectableBoxList
              selectedValue={field.value.canopyType}
              selectedTemplate={field.value.canopyTemplate}
              handleSelectedValue={(canopyType) => {
                field.onChange({
                  ...field.value,
                  canopyType
                });
              }}
              handleSelectedTemplate={(canopyTemplateId) => {
                field.onChange({
                  ...field.value,
                  canopyTemplate: canopyTemplateId
                });
              }}
              errors={errors}
            />
          )}
        />
      </div>
      <HR />
      <div className={styles.buttonsContainer}>
        <Button text={t("cancel")} type="tertiary" onClick={closePanel} />
        <Button
          text={t("addQuestions")}
          disabled={isLoading || Object.keys(errors).length > 0}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </div>
  );
};
