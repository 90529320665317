import { Button } from "antd";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { Avatar, DNCTag } from "@arbolus-technologies/ui/components";

import { EXPERT_PROFILE_ROUTE } from "../../../../constants/navigation/authRoutes";
import { SavedExpert } from "../../../../models/expert";
import { Country } from "../../../../models/meta";
import { UtilsService } from "../../../../services";

interface ExpertNetworkCardProps {
  expert: SavedExpert;
  country?: Country;
}

const ExpertNetworkCard = ({
  expert,
  country
}: ExpertNetworkCardProps): JSX.Element => {
  const dispatch = useDispatch();
  const { profileImageUrl, title } = expert;
  const expertDisplayName = UtilsService.displayUserName(expert);

  const onAssignClicked = () => {
    dispatch(
      PanelStoreActions.openPanel(PanelId.AssignExpertToProject, [
        { expertId: expert.expertId }
      ])
    );
  };

  return (
    <Col xl={3} lg={4} md={4} sm={6} xs={12}>
      <div className="expert-network-card">
        <Link
          to={{
            pathname: EXPERT_PROFILE_ROUTE(expert.id),
            state: { hasSourceRoute: true }
          }}
          className="top-container"
        >
          <Avatar
            avatar={{
              name: expertDisplayName,
              imageUrl: profileImageUrl
            }}
            type="bigXSCircle"
          />
          <div className="name-container">
            <h4 title={expertDisplayName}>{expertDisplayName}</h4>
            {country && (
              <span
                className={`flag-icon flag-icon-${country.twoLetterCode.toLowerCase()}`}
              />
            )}
          </div>
          <p>{title}</p>
        </Link>

        {expert.doNotContactStatus !== DO_NOT_CONTACT_STATUS.OK && (
          <div className="dncContainer">
            <DNCTag dncStatus={expert.doNotContactStatus} />
          </div>
        )}

        {expert.doNotContactStatus !== DO_NOT_CONTACT_STATUS.DNC && (
          <div className="bottom-container">
            <Button
              type="primary"
              icon={<AntDIcon name="add" />}
              onClick={(e): void => {
                e.stopPropagation();
                onAssignClicked();
              }}
            />
          </div>
        )}
      </div>
    </Col>
  );
};

export default ExpertNetworkCard;
