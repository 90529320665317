import { GridApi } from "ag-grid-community";
import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

import { Referral } from "@arbolus-technologies/models/common";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";

import { ReferralGridContext } from "../../../Models/ReferralsTable";

import styles from "./StatusPanel.module.scss";

interface CustomStatusPanelProps {
  api: GridApi<Referral>;
  context: ReferralGridContext;
}

export const SlotsFilterStatusPanel: React.FC<CustomStatusPanelProps> = ({
  api
}) => {
  const timezone = useSelector(ProjectNxSelector.displayTimezoneId());
  const [isCurrentFilter, setIsCurrentFilter] = React.useState(true);
  const thisWeekFilter = React.useMemo(
    () => ({
      startTime: {
        dateFrom: moment.utc().add(7, "days").format("YYYY-MM-DD"),
        dateTo: null,
        filterType: "date",
        type: "lessThan"
      }
    }),
    []
  );
  const nextWeekFilter = React.useMemo(
    () => ({
      startTime: {
        dateFrom: moment.utc().add(7, "days").format("YYYY-MM-DD"),
        dateTo: null,
        filterType: "date",
        type: "greaterThan"
      }
    }),
    []
  );

  const thisWeekText = React.useMemo(
    () => `Today - ${formatWithAddedDays(timezone, 6)}`,
    [timezone]
  );

  const nextWeekText = React.useMemo(
    () =>
      `${formatWithAddedDays(timezone, 7)} - ${formatWithAddedDays(
        timezone,
        14
      )}`,
    [timezone]
  );

  React.useEffect(() => {
    api.setFilterModel(isCurrentFilter ? thisWeekFilter : nextWeekFilter);
  }, [isCurrentFilter, thisWeekFilter, nextWeekFilter, api]);

  return (
    <div className="flex-row-8 h-100">
      <div
        onClick={() => !isCurrentFilter && setIsCurrentFilter(true)}
        className={clsx(
          styles.button,
          { [styles.purple]: !isCurrentFilter },
          { [styles.inactive]: isCurrentFilter },
          "d-flex align-items-center"
        )}
      >
        <Icon name="chevron_left" fontSize="18px" />
      </div>
      <div className="d-flex h-100 align-items-center">
        {isCurrentFilter ? thisWeekText : nextWeekText}
      </div>
      <div
        onClick={() => isCurrentFilter && setIsCurrentFilter(false)}
        className={clsx(
          styles.button,
          { [styles.purple]: isCurrentFilter },
          { [styles.inactive]: !isCurrentFilter },
          "d-flex align-items-center"
        )}
      >
        <Icon name="chevron_right" fontSize="18px" />
      </div>
    </div>
  );
};

function formatWithAddedDays(timezone: string, days: number): string {
  return moment().tz(timezone).add(days, "days").format("D MMM");
}
