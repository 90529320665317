import {
  PROJECT_ANGLES,
  PROJECT_ANGLES_ROUTE,
  PROJECT_BASE,
  PROJECT_CALENDAR,
  PROJECT_CALENDAR_ROUTE,
  PROJECT_CANOPIES,
  PROJECT_CANOPIES_ROUTE,
  PROJECT_EVENT,
  PROJECT_EVENT_ROUTE,
  PROJECT_FILES,
  PROJECT_FILES_ROUTE,
  PROJECT_NEW_EVENT,
  PROJECT_NEW_EVENT_ROUTE,
  PROJECT_ROUTE,
  PROJECT_TRANSCRIPTS,
  PROJECT_TRANSCRIPTS_ROUTE
} from "./projectRoutes";

export const PROJECT_BASE_TAB = "PROJECT_BASE_TAB";
export const PROJECT_CANOPIES_TAB = "PROJECT_CANOPIES_TAB";
export const PROJECT_ANGLES_TAB = "PROJECT_ANGLES_TAB";
export const PROJECT_CALENDAR_TAB = "PROJECT_CALENDAR_TAB";
export const PROJECT_FILES_TAB = "PROJECT_FILES_TAB";
export const PROJECT_NEW_EVENT_TAB = "PROJECT_NEW_EVENT_TAB";
export const PROJECT_EVENT_TAB = "PROJECT_EVENT_TAB";
export const PROJECT_TRANSCRIPTS_TAB = "PROJECT_TRANSCRIPTS_TAB";

export const PROJECT_TAB_ROUTES = {
  [PROJECT_BASE]: {
    tabName: PROJECT_BASE_TAB,
    route: PROJECT_ROUTE
  },
  [PROJECT_CALENDAR]: {
    tabName: PROJECT_CALENDAR_TAB,
    route: PROJECT_CALENDAR_ROUTE
  },
  [PROJECT_FILES]: {
    tabName: PROJECT_FILES_TAB,
    route: PROJECT_FILES_ROUTE
  },
  [PROJECT_TRANSCRIPTS]: {
    tabName: PROJECT_TRANSCRIPTS_TAB,
    route: PROJECT_TRANSCRIPTS_ROUTE
  },
  [PROJECT_NEW_EVENT]: {
    tabName: PROJECT_NEW_EVENT_TAB,
    route: PROJECT_NEW_EVENT_ROUTE
  },
  [PROJECT_EVENT]: {
    tabName: PROJECT_EVENT_TAB,
    route: PROJECT_EVENT_ROUTE
  },
  [PROJECT_CANOPIES]: {
    tabName: PROJECT_CANOPIES_TAB,
    route: PROJECT_CANOPIES_ROUTE
  },
  [PROJECT_ANGLES]: {
    tabName: PROJECT_ANGLES_TAB,
    route: PROJECT_ANGLES_ROUTE
  }
};
