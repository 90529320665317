import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  CANOPY_STATUS,
  CanopyPageType,
  STATUS_COLORS
} from "@arbolus-technologies/models/canopy";
import {
  CANOPY_EXPERTS_ROUTE,
  CANOPY_ROUTE,
  PROJECT_ROUTE
} from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  BadgeWithIcon,
  BoxWithChildren,
  ChevronButton,
  ColoredBadge
} from "@arbolus-technologies/ui/components";
import {
  APP_DATE_FORMAT,
  getElapsedTimePeriod
} from "@arbolus-technologies/utils";

import { MixPanelEventNames } from "../../../Mixpanel/enums";
import { useArbolusTracking } from "../../../Mixpanel/useArbolusTracking";

import "react-placeholder/lib/reactPlaceholder.css";
import styles from "./CanopyCard.module.scss";

interface CanopyCardProps {
  id: string;
  title: string;
  status: CANOPY_STATUS;
  lastAnswerDate: string | null;
  projectInfo: { id?: string; name?: string };
  expertCount: number;
  created?: string;
  canopyPageType: CanopyPageType;
}

export const CanopyCard: React.FC<CanopyCardProps> = ({
  id,
  lastAnswerDate,
  projectInfo,
  status,
  title,
  expertCount,
  created,
  canopyPageType
}) => {
  const { t } = useTranslation("canopyCard");
  const { trackClick } = useArbolusTracking();

  const isAdmin = useSelector(CacheSelector.isAdmin());

  const showSummary =
    status === CANOPY_STATUS.ACTIVE ||
    (!isAdmin && status === CANOPY_STATUS.PAUSED);

  const events = {
    [CanopyPageType.UserDashboard]: {
      project: MixPanelEventNames.UserDashboardCanopyCardProjectName,
      title: MixPanelEventNames.UserDashboardCanopyCardTitle,
      expertLink: MixPanelEventNames.UserDashboardCanopyCardExperts
    },
    [CanopyPageType.ProjectDashboard]: {
      title: MixPanelEventNames.ProjectDashboardCanopyCardTitle,
      expertLink: MixPanelEventNames.ProjectDashboardCanopyCardExperts
    },
    [CanopyPageType.CanopyList]: {
      project: MixPanelEventNames.CanopyListCanopyCardProjectName,
      title: MixPanelEventNames.CanopyListCanopyCardTitle,
      expertLink: MixPanelEventNames.CanopyListCanopyCardExperts
    }
  };

  const { elapsedTimeUnits, elapsedTimeName } = getElapsedTimePeriod(
    lastAnswerDate ? new Date(lastAnswerDate) : null
  );

  const recentAnswersTimePeriod = (): string => {
    if (elapsedTimeUnits === null) {
      return t("createdOn", {
        date: moment(created).format(APP_DATE_FORMAT)
      });
    } else if (elapsedTimeUnits === 0) {
      return `${t("lastAnswer")} ${elapsedTimeName}`;
    } else {
      return `${t("lastAnswer")} ${elapsedTimeUnits} ${elapsedTimeName} ${t(
        "ago"
      )}`;
    }
  };

  const colorValue =
    STATUS_COLORS[status.toLowerCase() as keyof typeof STATUS_COLORS];

  return (
    <BoxWithChildren classnames={styles.card}>
      <>
        <div className={styles.header}>
          <ColoredBadge
            text={
              status === CANOPY_STATUS.GENERATING ? t("generating") : status
            }
            backgroundColor={colorValue}
          />
          {projectInfo.id && projectInfo.name && (
            <Link
              to={PROJECT_ROUTE(projectInfo.id)}
              className={styles.projectNameContainer}
              onClick={() =>
                canopyPageType !== CanopyPageType.ProjectDashboard &&
                trackClick(events[canopyPageType].project)
              }
            >
              <BadgeWithIcon
                text={projectInfo.name}
                icon="folder"
                enableCursorPointer
              />
            </Link>
          )}
        </div>
        <Link
          to={showSummary ? CANOPY_EXPERTS_ROUTE(id) : CANOPY_ROUTE(id)}
          onClick={() => trackClick(events[canopyPageType].title)}
        >
          <h4 className={styles.title} title={title}>
            {title}
          </h4>
        </Link>
        <div className={styles.footer}>
          <p className={styles.lastAnswerDate}>{recentAnswersTimePeriod()}</p>
          {expertCount > 0 && (
            <ChevronButton
              to={CANOPY_EXPERTS_ROUTE(id)}
              text={t("expert", {
                count: expertCount
              })}
              icon={{ direction: "right", position: "end" }}
              onClick={() => trackClick(events[canopyPageType].expertLink)}
            />
          )}
          {expertCount === 0 && (
            <ChevronButton
              to={showSummary ? CANOPY_EXPERTS_ROUTE(id) : CANOPY_ROUTE(id)}
              text={showSummary ? t("view") : t("review")}
              icon={{ direction: "right", position: "end" }}
            />
          )}
        </div>
      </>
    </BoxWithChildren>
  );
};
