import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import { LocationDescriptorObject } from "history";
import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  ChevronButton,
  ConditionalLink
} from "@arbolus-technologies/ui/components";
import { formatUnits } from "@arbolus-technologies/utils";

import styles from "./GrayBox.module.scss";

interface GrayBoxProps {
  fullWidth?: boolean;
  title?: {
    text: string;
    to?: string | LocationDescriptorObject;
    onClick?: () => void;
  };
  subtitle?: string;
  actionButton?: {
    title: string;
    onClick: () => void;
    isDisabled?: boolean;
    isHidden?: boolean;
  };
  actionComponent?: React.ReactNode;
  children: React.ReactNode;
  flexDirection?: "row" | "column";
  gap?: number[];
}
export const GrayBox: React.FC<GrayBoxProps> = ({
  fullWidth,
  children,
  title,
  subtitle,
  actionButton,
  gap,
  flexDirection,
  actionComponent
}) => (
  <div
    style={{
      display: flexDirection ? "flex" : undefined,
      gap: gap ? formatUnits(gap) : undefined,
      flexDirection: flexDirection ?? undefined
    }}
    className={clsx(fullWidth ? styles.containerFullWidth : styles.container)}
  >
    {title && (
      <div className={styles.headerContainer}>
        {title && (
          <div className={styles.titleContainer} onClick={title.onClick}>
            <ConditionalLink to={title.to} alignCenter>
              <span>{title.text}</span>
              {title.to && (
                <Icon
                  name="chevron_right"
                  fontSize="22px"
                  color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
                />
              )}
            </ConditionalLink>
            {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
          </div>
        )}
        {actionComponent ? (
          <div className={styles.actionContainer}>{actionComponent}</div>
        ) : null}
        {actionButton && !actionButton.isHidden && (
          <div className={styles.actionContainer}>
            <ChevronButton
              onClick={actionButton.onClick}
              text={actionButton.title}
              icon={{ direction: "right", position: "end" }}
              disabled={actionButton.isDisabled}
            />
          </div>
        )}
      </div>
    )}
    {children}
  </div>
);
