import React from "react";
import { Link, LinkProps, useLocation } from "react-router-dom";

export const CustomLink: React.FC<LinkProps> = (props) => {
  const { children, to, className, ...rest } = props;
  const path = useLocation();
  const alreadyOnPage = path.pathname === to;

  return alreadyOnPage ? (
    <div className={className}>{children}</div>
  ) : (
    <Link to={to} className={className} {...rest}>
      {children}
    </Link>
  );
};
