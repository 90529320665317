import i18next from "i18next";
import * as Yup from "yup";

import { ExpertProfileConstraints } from "../../../../../constants/validation";

export const ExpertRateValidationSchema = Yup.object().shape({
  currency: Yup.string().required(i18next.t("register:currencyRequiredError")),
  hourlyRate: Yup.number()
    .typeError(i18next.t("register:hourlyRateTypeError"))
    .max(
      ExpertProfileConstraints.MAX_HOURLY_RATE,
      i18next.t("register:hourlyRateMaxError")
    )
    .min(
      ExpertProfileConstraints.MIN_HOURLY_RATE,
      i18next.t("register:hourlyRatePositiveError")
    )
    .required(i18next.t("register:hourlyRateRequiredError"))
});
