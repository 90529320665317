import { useEffect, useState } from "react";

import { useQuery } from "./useQuery";

export const useSingleQuery = (key: string): string | null => {
  const searchParams = useQuery();
  const [value, setValue] = useState<string | null>(null);

  useEffect(() => {
    setValue(searchParams.get(key));
  }, [searchParams, setValue, key]);

  return value;
};
