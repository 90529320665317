import i18next from "i18next";
import * as Yup from "yup";

import { REFERRAL_REJECT_REASON } from "@arbolus-technologies/models/project";

export const REJECT_REASON = "rejectReason";
export const REJECT_TEXT = "rejectText";

export type RejectFeedbackFormObject = {
  [REJECT_REASON]: REFERRAL_REJECT_REASON | null;
  [REJECT_TEXT]: string;
};

export const RejectFeedbackSchema =
  Yup.object().shape<RejectFeedbackFormObject>({
    rejectReason: Yup.mixed<REFERRAL_REJECT_REASON>()
      .oneOf(Object.values(REFERRAL_REJECT_REASON))
      .required(),
    rejectText: Yup.string()
      .when(REJECT_REASON, {
        is: REFERRAL_REJECT_REASON.OTHER,
        then: (schema: Yup.StringSchema<string>) =>
          schema.required(i18next.t("common:forms:fieldRequired")).max(5000)
      })
      .label(i18next.t("common:forms:text"))
  });
