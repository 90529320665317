import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";
import { FieldErrors } from "react-hook-form";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { HR } from "@arbolus-technologies/ui/components";

import {
  ICanopyCreationType,
  ISimpleCanopyCreationForm
} from "../SimpleCanopyCreationProvider";
import { TemplateBoxList } from "./TemplateBoxList/TemplateBoxList";

import styles from "./SelectableBox.module.scss";

interface SelectableBoxProps {
  canopyCreationType: ICanopyCreationType;
  selectedTemplate?: string;
  title: string;
  subtitle: string;
  handleOnClick: (canopyCreationType: ICanopyCreationType) => void;
  handleOnCanopyTemplateTypeClick: (canopyCreationType: string) => void;
  active: boolean;
  firstIcon: string;
  errors: FieldErrors<ISimpleCanopyCreationForm>;
}

export const SelectableBox: React.FC<SelectableBoxProps> = ({
  title,
  subtitle,
  canopyCreationType,
  handleOnClick,
  active,
  firstIcon,
  selectedTemplate,
  handleOnCanopyTemplateTypeClick,
  errors
}) => (
  <div
    id={canopyCreationType}
    className={clsx(
      active ? styles.canopyTypeContainerActive : styles.canopyTypeContainer
    )}
  >
    <button
      className={styles.transparentButton}
      onClick={() => handleOnClick(canopyCreationType)}
    >
      <div className={styles.boxContainer}>
        <div
          className={clsx(
            active ? styles.iconRoundedActive : styles.iconRounded
          )}
        >
          <Icon
            fontSize="24px"
            name={firstIcon}
            color={
              active
                ? ARBOLUS_COLORS.bColorBasePurple
                : ARBOLUS_COLORS.bColorSecondaryGreyDark
            }
          />
        </div>
        <div className={styles.infoContainer}>
          <span className={styles.titleBox}>{title}</span>
          <span className={styles.subtitleBox}>{subtitle}</span>
        </div>
        <div className={styles.endIconContainer}>
          <Icon
            fontSize="24px"
            name={active ? "check" : "add"}
            color={
              active
                ? ARBOLUS_COLORS.bColorBasePurple
                : ARBOLUS_COLORS.bColorSecondaryGreyDark
            }
          />
        </div>
      </div>
    </button>
    {canopyCreationType === ICanopyCreationType.TEMPLATE && active && (
      <div className={styles.templateContainer}>
        <HR />
        <TemplateBoxList
          handleSelectedValue={handleOnCanopyTemplateTypeClick}
          selectedValue={selectedTemplate}
          errors={errors}
        />
      </div>
    )}
  </div>
);
