import { Dispatch } from "redux";

import {
  InputModalData,
  ListModalData,
  ModalData,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ExtractMethods } from "@arbolus-technologies/utils";

export type IModalService = ExtractMethods<ModalService>;

export class ModalService {
  private _dispatch: Dispatch;

  constructor(dispatch: Dispatch) {
    this._dispatch = dispatch;
  }

  public openMainModal(data: ModalData): void {
    this._dispatch(PanelStoreActions.openMainModal(data));
  }

  public openListMainModal(data: ListModalData): void {
    this._dispatch(PanelStoreActions.openListMainModal(data));
  }

  public openInputMainModal(data: InputModalData): void {
    this._dispatch(PanelStoreActions.openInputMainModal(data));
  }

  public closeMainModal(): void {
    this._dispatch(PanelStoreActions.closeMainModal());
  }

  public closeListMainModal(): void {
    this._dispatch(PanelStoreActions.closeListMainModal());
  }

  public closeInputMainModal(): void {
    this._dispatch(PanelStoreActions.closeInputMainModal());
  }
}
