import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { SelectOption } from "@arbolus-technologies/models/common";

import { CustomSelect } from "./components/CustomSelect/CustomSelect";

import styles from "./TimezoneSelect.module.scss";

interface Timezone {
  id: string;
  displayText: string;
  offset: number;
}

interface TimezoneSelectProps {
  timezones: Timezone[];
  initialTimezone?: Timezone;
  size?: "small" | "large";
  handleTimezoneChange: (value: Timezone) => void;
}

export const TimezoneSelect: React.FC<TimezoneSelectProps> = ({
  timezones,
  initialTimezone,
  size = "large",
  handleTimezoneChange
}) => {
  const { t } = useTranslation("timezoneSelect");
  const timezoneOptions = React.useMemo(
    () => timezones.map(mapTimezoneToOption),
    [timezones]
  );

  const onSelectChange = React.useCallback(
    (value: string) => {
      const selectedTimezone = timezones.find(
        (timezone) => timezone.id === value
      );
      if (selectedTimezone) {
        handleTimezoneChange(selectedTimezone);
      }
    },
    [handleTimezoneChange, timezones]
  );

  return (
    <div
      className={clsx(styles.container, { [styles.small]: size === "small" })}
    >
      <CustomSelect
        options={timezoneOptions}
        defaultValue={
          initialTimezone ? mapTimezoneToOption(initialTimezone) : undefined
        }
        onSelectChange={onSelectChange}
        noOptionsMessage={t("noResults")}
        isSearchable
        placeholder={t("placeholder")}
        classNamePrefix="ciqs-select"
      />
    </div>
  );
};

function mapTimezoneToOption(timezone: Timezone): SelectOption {
  return {
    label: timezone.displayText,
    value: timezone.id,
    id: timezone.id
  };
}
