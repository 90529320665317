import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { OptionChoiceAnswer } from "@arbolus-technologies/models/canopy-panels";
import { Checkbox } from "@arbolus-technologies/ui/components";

import styles from "./OptionCheckbox.module.scss";

interface OptionCheckboxProps {
  option: OptionChoiceAnswer;
}

export const OptionCheckbox: React.FC<OptionCheckboxProps> = ({ option }) => {
  const { t } = useTranslation("answer");
  const { isOther, id, isSelected, text, otherText } = option;
  const isOtherOption = isOther === true && isSelected === true && otherText;

  return (
    <>
      {isOtherOption && (
        <div className={styles.isOtherContainer} key={id}>
          <div
            className={clsx(styles.checkBoxContainer, {
              [styles.isSelected]: isSelected
            })}
          >
            <Checkbox
              isChecked={isSelected}
              isDisabled
              onChange={() => false}
              text={text}
            />
          </div>
          <div className={styles.explanationContainer}>
            <h2 className={styles.explanationTitle}>
              {t("explanationProvided")}
            </h2>
            <p className={styles.explanationText}>{`"${otherText}"`}</p>
          </div>
        </div>
      )}

      {!isOtherOption && (
        <div
          key={id}
          className={clsx(styles.checkBoxContainer, {
            [styles.isSelected]: isSelected
          })}
        >
          <Checkbox
            key={id}
            isChecked={isSelected}
            isDisabled
            onChange={() => false}
            text={text}
          />
        </div>
      )}
    </>
  );
};
