import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import { useTranslation } from "react-i18next";

import { CanopyQuestionPDF } from "@arbolus-technologies/api";
import { maxLengthString } from "@arbolus-technologies/utils";

import {
  expertNamePDF,
  expertSectionPDF,
  expertTitlePDF,
  npsValuePDF,
  questionDetailsPDF,
  sectionPDF,
  titleQuestionPDF,
  transcriptPDF,
  valueContainerPDF,
  valueMultiPDF,
  videoPDFContainer
} from "./styles";

interface NpsValuePDFViewProps {
  data: CanopyQuestionPDF;
}

export const NpsValuePDFView: React.FC<NpsValuePDFViewProps> = ({ data }) => {
  const { t } = useTranslation("PDF");

  const styles = StyleSheet.create({
    section: sectionPDF,
    npsValue: npsValuePDF,
    title: titleQuestionPDF,
    expertSection: expertSectionPDF,
    expertName: expertNamePDF,
    expertTitle: expertTitlePDF,
    transcript: transcriptPDF,
    videoPDFContainer,
    valueContainer: valueContainerPDF,
    valueMulti: valueMultiPDF,
    questionDetails: questionDetailsPDF
  });

  return (
    <>
      <View style={styles.section}>
        <Text style={styles.title}>
          {data.sortOrder + 1}. {data.questionText}
        </Text>
        <Text style={styles.npsValue}>
          {`${t("npsValue")}${data.netPromoterScore ?? "n/a"}`}
        </Text>
        <Text style={styles.questionDetails}>{data.questionDetails}</Text>
      </View>
      {data.answers.map((e) => (
        <View style={styles.expertSection} key={e.expert.lastName}>
          <div style={{ display: "flex", flexDirection: "row", gap: "4px" }}>
            <Text style={styles.expertName}>
              {maxLengthString(
                `${e.expert.firstName} ${e.expert.lastName}`,
                30
              )}
            </Text>
            <Text style={styles.expertName}>-</Text>
            <Text style={styles.expertTitle}>
              {maxLengthString(e.expert.title, 80)}
            </Text>
          </div>
          <Text style={styles.valueContainer} key={e.shortText}>
            {e.npsValue ?? "n/a"}
          </Text>
        </View>
      ))}
    </>
  );
};
