import React from "react";
import { useTranslation } from "react-i18next";

import { WorkHistoryEditList } from "@arbolus-technologies/features/common";
import { ProjectApplicationStepProps } from "@arbolus-technologies/models/project";

import { ProjectApplicationWrapper } from "../ProjectApplicationWrapper/ProjectApplicationWrapper";

import styles from "./CompanyValidationStep.module.scss";

interface CompanyValidationStepProps extends ProjectApplicationStepProps {
  expertId: string;
  onWorkHistoryConfirmed: () => void;
}

export const CompanyValidationStep: React.FC<CompanyValidationStepProps> = ({
  onWorkHistoryConfirmed,
  stepDetails,
  onBack,
  expertId
}: CompanyValidationStepProps) => {
  const { t } = useTranslation("companyValidationHeader");

  return (
    <ProjectApplicationWrapper
      stepDetails={stepDetails}
      onBack={onBack}
      isLoading
    >
      <div className={styles.container}>
        <h1 className={styles.title}>{t("title")}</h1>
        <p className={styles.description}>
          <span>{t("currentPositions")}</span>
          <span>{t("mandatoryTimeFrameWarning")}</span>
        </p>
      </div>
      <WorkHistoryEditList
        expertId={expertId}
        onSave={onWorkHistoryConfirmed}
        mandatoryTimeFrame
      />
    </ProjectApplicationWrapper>
  );
};
