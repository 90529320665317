import React from "react";
import { useSelector } from "react-redux";

import {
  ExpertsListPageTab,
  RecommendationResponse
} from "@arbolus-technologies/api";
import { ProjectExpertsSelector } from "@arbolus-technologies/stores/project-experts-store";
import { Loader } from "@arbolus-technologies/ui/components";

import { NoData } from "../../FilteredExperts/NoData";
import { RecommendationsTable } from "./RecommendationsTable/RecommendationsTable";
import { RecommendationsTableColumns } from "./RecommendationsTable/recommendationsTableDefinition";

export const Recommendations: React.FC<{
  projectId: string;
  handleClickRow: (
    expertId: string,
    referralId?: string,
    openAvailabilityTab?: boolean
  ) => void;
}> = ({ projectId, handleClickRow }) => {
  const recommendations = useSelector(
    ProjectExpertsSelector.recommendationsList()
  );
  const isLoading = useSelector(
    ProjectExpertsSelector.isRecommendationsListLoading()
  );

  const wrappedHandleClickRow = (
    cellId: string,
    recommendation: RecommendationResponse
  ) => {
    if (cellId === RecommendationsTableColumns.MORE_OPTIONS) return;
    handleClickRow(recommendation.id);
  };

  if (isLoading || !recommendations) {
    return <Loader />;
  }

  if (recommendations.length === 0) {
    return <NoData tabActive={ExpertsListPageTab.Recommendations} />;
  }

  return (
    <RecommendationsTable
      recommendations={recommendations}
      handleClickRow={wrappedHandleClickRow}
      projectId={projectId}
    />
  );
};
