import { Icon, IconProps } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";

import styles from "./IconWithBackground.module.scss";

interface IconWithBackgroundProps extends IconProps {
  backgroundColor: string;
  size: "small" | "big";
  circular?: boolean;
}
export const IconWithBackground: React.FC<IconWithBackgroundProps> = ({
  backgroundColor,
  size,
  circular,
  ...props
}) => (
  <div
    style={{ backgroundColor }}
    className={clsx(styles.icon, styles[size], { [styles.circular]: circular })}
  >
    <Icon {...props} />
  </div>
);
