/* eslint-disable dot-notation */
import i18next from "i18next";
import React from "react";

import { ToasterService } from "@arbolus-technologies/api";

const notification = new ToasterService();

interface InviteTagProps {
  url: string;
}

const InviteTag: React.FC<InviteTagProps> = ({ url }: InviteTagProps) => {
  const urlObj = new URL(url);
  const urlText = `${urlObj.hostname}${urlObj.pathname}`;

  const copyToClipboard = (): void => {
    if (navigator.clipboard === undefined) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const isCopied = (window as { [key: string]: any })[
        "clipboardData"
      ].setData("Text", url);

      if (isCopied) {
        notification.showSuccess(i18next.t("inviteTag:copiedToClipboard"));
      } else {
        notification.showError(i18next.t("inviteTag:copiedToClipboardError"));
      }
    } else {
      // for other navigators
      navigator.clipboard
        .writeText(url)
        .then(() =>
          notification.showSuccess(i18next.t("inviteTag:copiedToClipboard"))
        );
    }
  };

  return (
    <div className="invite-tag">
      <p className="invite-url" title={urlText}>
        {urlText}
      </p>
      <span
        className="ciq-icon ciq-assignments-outline"
        onClick={copyToClipboard}
      />
    </div>
  );
};

export default InviteTag;
