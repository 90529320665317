import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";

import { IconWithText } from "../IconWithText/IconWithText";

import styles from "./Tabs.module.scss";

interface TabsProps<T> {
  tabMapping: Record<
    keyof T,
    {
      title: string;
      component?: () => void;
      clickFn?: (tabName: T) => void;
      customClasses?: string;
      icon?: string;
    }
  >;
  defaultActiveTab?: T;
  actionButton?: JSX.Element;
}

export const Tabs = <T,>({
  tabMapping,
  defaultActiveTab,
  actionButton
}: TabsProps<T>): JSX.Element => {
  const [activeTab, setActiveTab] = useState<T>(
    defaultActiveTab || (Object.keys(tabMapping)[0] as unknown as T)
  );

  useEffect(() => {
    defaultActiveTab && setActiveTab(defaultActiveTab);
  }, [defaultActiveTab]);

  const tabsRender = () => {
    const tabKeys = Object.keys(tabMapping) as unknown as T[];
    if (tabKeys.length === 1) {
      return null;
    }

    return tabKeys.map((key) => {
      const isActive = activeTab === key;
      const tabDetails = tabMapping[key as unknown as keyof T];
      const handleClick = () => {
        tabDetails.clickFn ? tabDetails.clickFn(key) : setActiveTab(key);
      };

      return (
        <NavItem className={styles.navItem} key={tabDetails.title}>
          <NavLink
            className={clsx(
              styles.navLink,
              isActive ? styles.activeNavLink : "",
              tabDetails.customClasses
            )}
            active={isActive}
            onClick={handleClick}
          >
            {tabDetails.icon ? (
              <IconWithText
                text={{ content: tabDetails.title }}
                icon={{
                  name: tabDetails.icon,
                  customClasses: tabDetails.customClasses
                }}
                customClasses={clsx(
                  styles.navLinkWithIcon,
                  tabDetails.customClasses
                )}
              />
            ) : (
              tabDetails.title
            )}
          </NavLink>
        </NavItem>
      );
    });
  };

  const { component } = tabMapping[activeTab as unknown as keyof T];
  let content;
  if (component instanceof Function) {
    content = component();
  } else {
    content = component;
  }

  return (
    <>
      <div className={styles.container}>
        <Nav pills className={styles.tabs}>
          {tabsRender()}
        </Nav>
        {actionButton}
      </div>
      {content}
    </>
  );
};
