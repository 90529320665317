import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { TranscriptStatus } from "@arbolus-technologies/models/canopy-panels";

import styles from "./TranscriptStatusTag.module.scss";

export interface TranscriptStatusTagProps {
  transcriptStatus: TranscriptStatus;
}

export const TranscriptStatusTag: React.FC<TranscriptStatusTagProps> = ({
  transcriptStatus
}) => {
  const { t } = useTranslation("canopyPanels");

  const transcriptStatusObject = {
    NoTranscript: {
      icon: "insert_page_break",
      text: t("noTranscript"),
      container: styles.noTranscript
    },
    AutomaticTranscript: {
      icon: "smart_toy",
      text: t("automaticTranscript"),
      container: styles.automaticTranscript
    },
    SmartTranscript: {
      icon: "magic_button",
      text: t("smartTranscript"),
      container: styles.smartTranscript
    }
  };

  return (
    <div className={transcriptStatusObject[transcriptStatus].container}>
      <span className={clsx("material-symbols-sharp", styles.transcriptIcon)}>
        {transcriptStatusObject[transcriptStatus].icon}
      </span>
      <p className={styles.transcriptText}>
        {transcriptStatusObject[transcriptStatus].text}
      </p>
    </div>
  );
};
