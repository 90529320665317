import {
  GetBookmarksListAction,
  GetBookmarksListSuccessAction
} from "../actions/GetBookmarksList/GetBookmarksListActionTypes";
import { BookmarksReducerState } from "../models/definitions";
import { initialState } from "./InitialState";

export const handleGetBookmarksList = (
  state = initialState,
  { payload: { queryParams, searchTerm } }: GetBookmarksListAction
): BookmarksReducerState => {
  const store =
    queryParams.offset === 0 ? undefined : state.bookmarksList.store;

  return {
    ...state,
    bookmarksList: {
      store,
      isLoading: true,
      searchTerm: searchTerm
    }
  };
};

export const handleGetBookmarksListSuccess = (
  state = initialState,
  { payload: { response } }: GetBookmarksListSuccessAction
): BookmarksReducerState => ({
  ...state,
  bookmarksList: {
    ...state.bookmarksList,
    isLoading: false,
    store: {
      items:
        response.pagination.offset === 0
          ? response.items
          : [...(state.bookmarksList.store?.items || []), ...response.items],
      total: response.pagination.count
    }
  }
});

export const handleGetBookmarksListFailure = (
  state = initialState
): BookmarksReducerState => ({
  ...state,
  bookmarksList: {
    ...state.bookmarksList,
    isLoading: false
  }
});

export const handleResetBookmarksList = (
  state = initialState
): BookmarksReducerState => ({
  ...state,
  bookmarksList: {
    ...state.bookmarksList,
    isLoading: false,
    store: undefined
  }
});
