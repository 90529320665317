import i18next from "i18next";
import * as Yup from "yup";

import {
  DownloadCanopyInterface,
  FileInterface
} from "@arbolus-technologies/api";
import { CanopyConstraints } from "@arbolus-technologies/models/canopy";

export interface CanopyDetailsFormInterface {
  title: string;
  overview: string;
  links: FileInterface[];
  documents: DownloadCanopyInterface[];
  brief: string;
}

declare module "yup" {
  interface ArraySchema<T> {
    unique(message: string): ArraySchema<T>;
  }
}

interface LinkUrl {
  url: string;
}

Yup.addMethod(Yup.array, "unique", function checkUnique(message) {
  return this.test("unique", message, function checkUrlsList(list) {
    const urlArrays = list.map((item: LinkUrl) => item.url);
    const duplicates = urlArrays.filter(
      (item: LinkUrl, idx: number) => urlArrays.indexOf(item) !== idx
    );
    return duplicates.length > 0
      ? this.createError({
          path: `links[${urlArrays.indexOf(duplicates[0])}].url`,
          message
        })
      : true;
  });
});

export const CanopyDetailsSchema = Yup.object().shape({
  title: Yup.string()
    .required(i18next.t("canopyBuilder:titleRequired"))
    .max(
      CanopyConstraints.MAX_CANOPY_TITLE_LENGTH,
      i18next.t("canopyBuilder:canopyTitleMaxLength", {
        length: CanopyConstraints.MAX_CANOPY_TITLE_LENGTH
      })
    ),
  overview: Yup.string()
    .required(i18next.t("canopyBuilder:briefRequired"))
    .max(
      CanopyConstraints.MAX_CANOPY_OVERVIEW_LENGTH,
      i18next.t("canopyBuilder:canopyBriefMaxLength", {
        length: CanopyConstraints.MAX_CANOPY_OVERVIEW_LENGTH
      })
    ),
  links: Yup.array<FileInterface>()
    .of(
      Yup.object<FileInterface>().shape({
        url: Yup.string()
          .url(i18next.t("canopyBuilder:invalidUrl"))
          .required(i18next.t("canopyBuilder:urlRequired"))
      })
    )
    .unique(i18next.t("canopyBuilder:duplicatedLinkError")),
  documents: Yup.array<DownloadCanopyInterface>().of(
    Yup.object<DownloadCanopyInterface>().shape({
      id: Yup.string(),
      fileKey: Yup.string(),
      fileName: Yup.string().required(i18next.t("canopyBuilder:fileRequired")),
      downloadUrl: Yup.string()
    })
  )
});
