import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import i18next from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { DNCHistoryModel } from "@arbolus-technologies/api";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { Avatar, DNCTag } from "@arbolus-technologies/ui/components";
import { DNC_FORMAT } from "@arbolus-technologies/utils";

import { EditHistoryNoteForm } from "./EditHistoryNoteForm";

import styles from "./DNCHistoryItem.module.scss";

interface DNCHistoryItemProps {
  dncHistory: DNCHistoryModel;
  canEditComment: boolean;
  isFirstItem: boolean;
  onUpdate: () => void;
}

export const DNCHistoryItem: React.FC<DNCHistoryItemProps> = ({
  dncHistory,
  canEditComment,
  isFirstItem,
  onUpdate
}) => {
  const { t } = useTranslation("dnc");

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const isAdmin = useSelector(CacheSelector.isAdmin());

  useEffect(() => {
    setIsEdit(false);
  }, [isFirstItem]);

  const {
    id: dncHistoryId,
    doNotContactStatus,
    fullName,
    profileImageUrl,
    created,
    doNotContactCategory,
    doNotContactStatusDescription,
    edited
  } = dncHistory;

  const createdFormatted = moment(created).format(DNC_FORMAT);
  const editedFormatted = moment(edited).format(DNC_FORMAT);
  const isInitialStatus = created === null;

  const reasons = [];
  if (doNotContactCategory) {
    reasons.push(i18next.t(`dncCategories:${doNotContactCategory}`));
  }
  if (doNotContactStatusDescription?.trim()) {
    reasons.push(doNotContactStatusDescription);
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>
          <DNCTag
            dncStatus={doNotContactStatus}
            skipOk={false}
            isAdmin={isAdmin}
          />
          {!isInitialStatus && (
            <>
              <div className={styles.expertContainer}>
                <Avatar
                  avatar={{
                    name: fullName,
                    imageUrl: profileImageUrl
                  }}
                  type="avatarGroupCircleSmall"
                />
                <p className={styles.expertName}>{fullName}</p>
              </div>
              <span>-</span>
              <p className={styles.date}>{createdFormatted}</p>
            </>
          )}
        </div>
        {canEditComment && isFirstItem && (
          <div className={clsx(styles.icon, { [styles.edit]: isEdit })}>
            <Icon
              fontSize="24px"
              name="edit"
              color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
              onClick={() => setIsEdit(true)}
            />
          </div>
        )}
      </div>

      <div className={styles.reasonContainer}>
        <h1 className={styles.reasonTitle}>{t("reason")}</h1>
        {isEdit ? (
          <EditHistoryNoteForm
            dncHistoryId={dncHistoryId}
            doNotContactStatusDescription={doNotContactStatusDescription}
            onUpdate={onUpdate}
            onCancel={() => setIsEdit(false)}
          />
        ) : (
          <p className={styles.reasonText}>{reasons.join(" - ")}</p>
        )}
      </div>
      {edited && (
        <div className={styles.edited}>
          {t("edited")} {editedFormatted}
        </div>
      )}
    </div>
  );
};
