import { Button } from "arbolus-ui-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import {
  DefaultToasterService,
  ProjectService,
  ToasterService
} from "@arbolus-technologies/api";
import { MAIN_PROJECTS_ROUTE } from "@arbolus-technologies/routes";
import { ArbolusModalDanger } from "@arbolus-technologies/ui/components";

interface DeleteProjectProps {
  projectId: string;
  projectName: string;
  projectService?: typeof ProjectService;
  notificationService?: ToasterService;
}

const DeleteProject: React.FC<DeleteProjectProps> = ({
  projectId,
  projectName,
  projectService = ProjectService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("deleteProject");
  const history = useHistory();

  const [deleteModal, setDeleteModal] = useState(false);

  const deleteProject = () => {
    projectService.deleteProject(projectId, "delete").subscribe({
      next: (deleteResponse) => {
        if (deleteResponse.isDeleted) {
          notificationService.showSuccess(t("successMessage", { projectName }));
          history.push(MAIN_PROJECTS_ROUTE());
        }
      },
      error: (error) => {
        notificationService.showApiErrors(error);
      }
    });
  };

  const handleCancel = () => {
    setDeleteModal(false);
  };

  return (
    <>
      <Button
        text={t("title")}
        onClick={(): void => setDeleteModal(true)}
        type="rejection"
      />
      <ArbolusModalDanger
        isOpen={deleteModal}
        confirmationText={projectName}
        warningMessage={t("warningMessage", { projectName })}
        toggle={handleCancel}
        leftButton={{
          text: t("cancel"),
          onClick: handleCancel,
          type: "tertiary"
        }}
        rightButton={{
          text: t("delete"),
          onClick: deleteProject,
          type: "rejection"
        }}
      />
    </>
  );
};

export default DeleteProject;
