import React, { useCallback } from "react";

import { DocumentService, PaginatedRequest } from "@arbolus-technologies/api";

import { ProjectDocuments } from "./ProjectDocuments";

interface ExpertProjectDocumentsProps {
  projectId: string;
  documentService?: typeof DocumentService;
}

export const ExpertProjectDocuments: React.FC<ExpertProjectDocumentsProps> = ({
  projectId,
  documentService = DocumentService
}) => {
  const getDocuments = useCallback(
    (queryParams: PaginatedRequest<"created">) =>
      documentService.getExpertDocuments(projectId, queryParams),
    [documentService, projectId]
  );

  return <ProjectDocuments projectId={projectId} getDocuments={getDocuments} />;
};
