import clsx from "clsx";
import React from "react";

import {
  ResponseAllExpertsTable,
  StatusExpertTable
} from "@arbolus-technologies/api";
import {
  Avatar,
  DNCIcon,
  PublicCompanyIcon
} from "@arbolus-technologies/ui/components";
import { displayUserName } from "@arbolus-technologies/utils";

import styles from "./NameIconAngleBorder.module.scss";

interface NameIconAngleBorderProps {
  row: ResponseAllExpertsTable;
}

const NameIconAngleBorder: React.FC<NameIconAngleBorderProps> = ({ row }) => {
  const borderColor = () => {
    switch (row.reviewStatus) {
      case StatusExpertTable.Accept:
        return styles.approved;
      case StatusExpertTable.Pending:
        if (
          row.fastTrack &&
          row.applicationStatus === StatusExpertTable.Reject
        ) {
          return styles.declined;
        }
        return styles.pending;
      case StatusExpertTable.Reject:
        return styles.rejected;
      default:
        return null;
    }
  };

  const {
    firstName,
    lastName,
    email,
    profileImageUrl,
    lastPublicCompanyExpDate
  } = row.expert || {
    firstName: "",
    lastName: "",
    email: "",
    profileImageUrl: ""
  };

  return (
    <div className={clsx(styles.container, borderColor())}>
      <div className={styles.avatarContainer}>
        <Avatar
          avatar={{
            name: displayUserName({
              firstName,
              lastName,
              email
            }),
            imageUrl: profileImageUrl ?? ""
          }}
        />
        <div
          className={styles.avatarSpan}
          title={displayUserName({
            firstName,
            lastName,
            email
          })}
        >
          {displayUserName({
            firstName,
            lastName,
            email
          })}
        </div>
        <div className={styles.iconRow}>
          <DNCIcon dncStatus={row.expert.doNotContactStatus} />
          {lastPublicCompanyExpDate && (
            <div className={styles.icon}>
              <PublicCompanyIcon date={lastPublicCompanyExpDate} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NameIconAngleBorder;
