import { push } from "connected-react-router";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";

import {
  ExpertInvitePanel,
  InternalSlidePanel
} from "@arbolus-technologies/features/common";
import { AngleModel } from "@arbolus-technologies/models/common";
import { NEW_EXPERT } from "@arbolus-technologies/routes";
import {
  ProjectAnglesSelector,
  ProjectAnglesStoreActions
} from "@arbolus-technologies/stores/project-angles-store";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { ViewEditAngle } from "../ViewEditAnglePanel/ViewEditAngle";

import styles from "./AnglesCard.module.scss";

interface AnglesCardProps {
  angleCard: AngleModel;
  navigateToExpertsListTab: string;
  isAdmin?: boolean;
  projectId: string;
}

export const AnglesCard: React.FC<AnglesCardProps> = ({
  angleCard,
  navigateToExpertsListTab,
  isAdmin = false,
  projectId
}) => {
  const { t } = useTranslation("anglesCard");
  const dispatch = useDispatch();

  const [isViewEdit, setIsViewEdit] = useState<boolean>(false);
  const [isSlidePanelOpen, setIsSlidePanelOpen] = useState<boolean>(false);

  const isSingleAngleLoading = useSelector(
    ProjectAnglesSelector.isSingleAngleLoading()
  );

  const handleNavigateToExpertsListTab = () => {
    dispatch(push(navigateToExpertsListTab));
  };

  const { title, color, id } = angleCard;

  return (
    <>
      <InternalSlidePanel
        width="50vw"
        hideHeader
        isOpen={isViewEdit}
        customCloseRequest={() => {
          setIsViewEdit(false);
          dispatch(ProjectAnglesStoreActions.setFormCreateEditAngleEnabled());
          dispatch(ProjectAnglesStoreActions.resetSelectedAngle());
        }}
      >
        {!isSingleAngleLoading && (
          <ViewEditAngle
            isAdmin={isAdmin}
            isEdit
            projectId={projectId}
            handleCloseIcon={() => {
              dispatch(
                ProjectAnglesStoreActions.setFormCreateEditAngleEnabled()
              );
              dispatch(ProjectAnglesStoreActions.resetSelectedAngle());
              setIsViewEdit(false);
            }}
          />
        )}
      </InternalSlidePanel>
      <div
        className={styles.container}
        style={{ borderLeft: `6px solid ${color}` }}
      >
        <div
          className={styles.title}
          onClick={() => {
            setIsViewEdit(true);
            dispatch(ProjectAnglesStoreActions.getSingleAngle(id));
          }}
        >
          {title}
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            size="md"
            color="primary"
            className={styles.cardButton}
            onClick={() => setIsSlidePanelOpen(true)}
          >
            {t("invite")}
          </Button>
          <Button
            size="md"
            color="primary"
            className={styles.cardButton}
            onClick={handleNavigateToExpertsListTab}
          >
            {t("experts")}
          </Button>
        </div>
        <InternalSlidePanel
          width={SIDE_PANEL_SIZE._400}
          closeButtonDirection="right"
          title="Add Experts"
          isOpen={isSlidePanelOpen}
          customCloseRequest={() => setIsSlidePanelOpen(false)}
        >
          <ExpertInvitePanel
            angleId={id}
            projectId={projectId}
            createExpertRoute={NEW_EXPERT}
          />
        </InternalSlidePanel>
      </div>
    </>
  );
};
