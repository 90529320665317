import { ICellRendererParams } from "ag-grid-community";
import React from "react";

import {
  APPLICATION_STATUS,
  Referral
} from "@arbolus-technologies/models/common";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./ChipGroupRenderer.module.scss";

interface ChipGroupRendererProps {
  color: string;
  text: string;
  count: number;
}

const ChipGroupRenderer: React.FC<ChipGroupRendererProps> = ({
  color,
  text,
  count
}) => (
  <div className="flex-row-8 justify-content-center align-items-center">
    <div
      className={styles.chip}
      style={{
        backgroundColor: color
      }}
    >
      {text} · {count}
    </div>
  </div>
);

const applicationToColor = {
  [APPLICATION_STATUS.PENDING]: ARBOLUS_COLORS.bColorSecondaryGreyDark,
  [APPLICATION_STATUS.SENT]: ARBOLUS_COLORS.bColorSecondaryOrange,
  [APPLICATION_STATUS.ACCEPT]: ARBOLUS_COLORS.bColorBasePurple,
  [APPLICATION_STATUS.REJECT]: ARBOLUS_COLORS.bColorBaseOrange,
  approved: ARBOLUS_COLORS.bColorAccentGreenDark
} as const;

const scheduleToColor = {
  true: ARBOLUS_COLORS.bColorAccentGreenDark,
  false: ARBOLUS_COLORS.bColorSecondaryOrange
} as const;

const bookedToColor = {
  true: ARBOLUS_COLORS.bColorAccentGreenDark,
  false: ARBOLUS_COLORS.bColorBasePurple
} as const;

export const ApplicationGroupRenderer: React.FC<
  ICellRendererParams<Referral>
> = ({ value, valueFormatted, node: { allChildrenCount } }) => (
  <ChipGroupRenderer
    color={applicationToColor[value as APPLICATION_STATUS]}
    text={valueFormatted!}
    count={allChildrenCount!}
  />
);

export const SchedulingGroupRenderer: React.FC<
  ICellRendererParams<Referral, "true" | "false">
> = ({ value, valueFormatted, node: { allChildrenCount } }) => (
  <ChipGroupRenderer
    color={scheduleToColor[value!]}
    text={valueFormatted!}
    count={allChildrenCount!}
  />
);

export const BookedGroupRenderer: React.FC<
  ICellRendererParams<Referral, "true" | "false">
> = ({ value, valueFormatted, node: { allChildrenCount } }) => (
  <ChipGroupRenderer
    color={bookedToColor[value!]}
    text={valueFormatted!}
    count={allChildrenCount!}
  />
);
