import { GetContextMenuItemsParams, MenuItemDef } from "ag-grid-community";

import {
  ClipboardManager,
  DefaultClipboardManager
} from "@arbolus-technologies/features/common";
import {
  APPLICATION_STATUS,
  DO_NOT_CONTACT_STATUS,
  Referral
} from "@arbolus-technologies/models/common";
import { isBainClient } from "@arbolus-technologies/utils";

import {
  IBulkItemActionParams,
  ItemActionParams,
  ReferralGridContext
} from "../../Models/ReferralsTable";
import { BULK_ACTIONS } from "../../Modules/ReferralsTable/statusPanels/BulkActionsStatusPanel";
import {
  DefaultBulkActionsMenuService,
  IBulkActionsMenuService
} from "./BulkActionsMenuService";
import {
  DefaultBulkReferralActionsService,
  IBulkReferralActionsService
} from "./BulkReferralActionsService";
import { ContextMenuService } from "./ContextMenuService";
import {
  DefaultExportMenuService,
  IExportMenuService
} from "./ExportMenuService";

export class CandidateMenuService extends ContextMenuService {
  private _bulkActionsMenuService: IBulkActionsMenuService;

  constructor(
    exportMenuService: IExportMenuService,
    bulkActionsService: IBulkReferralActionsService,
    clipboardManager: ClipboardManager,
    bulkActionsMenuService: IBulkActionsMenuService
  ) {
    super(exportMenuService, bulkActionsService, clipboardManager);
    this._bulkActionsMenuService = bulkActionsMenuService;
  }

  getItems = ({
    api,
    node,
    context,
    value
  }: GetContextMenuItemsParams<Referral, ReferralGridContext>): (
    | string
    | MenuItemDef
  )[] => {
    if (value === BULK_ACTIONS) {
      const bulkParams: IBulkItemActionParams = {
        api,
        ...context
      };

      const approveDisabled = isBainClient(context.project.client.id);

      return [
        this._bulkActionsMenuService.getSendApplicationItem(bulkParams),
        "separator",
        this._bulkActionsMenuService.getApproveItem(
          bulkParams,
          approveDisabled
        ),
        "separator",
        this.getAddToAnotherProjectItem(bulkParams),
        this._bulkActionsMenuService.getRemoveItem(bulkParams),
        "separator",
        this.exportMenuService.getExportMenuItems(bulkParams, "Candidate")
      ];
    }

    if (!node?.data || node.group) {
      return [];
    }

    const params: ItemActionParams = {
      api,
      referral: node.data,
      ...context
    };

    if (
      params.referral.expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC
    ) {
      return [this.getRemoveItem(params)];
    }

    if (params.referral.application === APPLICATION_STATUS.REJECT) {
      return [
        this.getResetApplicationItem(params),
        "separator",
        this.getCopyEmailItem(params),
        this.getCopyPhoneItem(params),
        "separator",
        this.getRemoveItem(params)
      ];
    }

    if (params.referral.application === APPLICATION_STATUS.ACCEPT) {
      return [
        this.getEditAngleItem(params),
        this.getResetApplicationItem(params),
        "separator",
        this.getCopyEmailItem(params),
        this.getCopyPhoneItem(params),
        "separator",
        this.getRemoveItem(params)
      ];
    }

    if (params.referral.application === APPLICATION_STATUS.SENT) {
      return [
        this.getSendApplicationReminderItem(params),
        "separator",
        this.getCopyApplicationItem(params),
        this.getEditAngleItem(params),
        "separator",
        this.getCopyEmailItem(params),
        this.getCopyPhoneItem(params),
        "separator",
        this.getRemoveItem(params)
      ];
    }

    return [
      this.getSendApplicationItem(params),
      "separator",
      this.getEditAngleItem(params),
      "separator",
      this.getCopyEmailItem(params),
      this.getCopyPhoneItem(params),
      "separator",
      this.getRemoveItem(params)
    ];
  };
}

export const DefaultCandidateMenuService = new CandidateMenuService(
  DefaultExportMenuService,
  DefaultBulkReferralActionsService,
  DefaultClipboardManager,
  DefaultBulkActionsMenuService
);
