import { Icon } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { ExpertListProject } from "@arbolus-technologies/api";
import { useIsChatAvailable } from "@arbolus-technologies/features/common";
import {
  PROJECT_CALENDAR_ROUTE,
  PROJECT_CHAT_BASE_ROUTE,
  PROJECT_FILES_ROUTE,
  PROJECT_ROUTE
} from "@arbolus-technologies/routes";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { ArbolusTooltip } from "@arbolus-technologies/ui/components";

import styles from "./ProjectDetails.module.scss";

interface ProjectIconsProps {
  row: ExpertListProject;
}

const fontSize = "18px";
export const ProjectIcons: React.FC<ProjectIconsProps> = ({ row }) => {
  const { t } = useTranslation("expertProjects");
  const history = useHistory();
  const isChatAvailable = useIsChatAvailable();

  const {
    project: { id }
  } = row;

  return (
    <div className={styles.projectIcons}>
      <ArbolusTooltip content={t("project")}>
        <Icon
          fontSize={fontSize}
          color={ARBOLUS_COLORS.bColorGrayIcon}
          name="dashboard"
          onClick={() => history.push(PROJECT_ROUTE(id))}
          id="project"
        />
      </ArbolusTooltip>
      {isChatAvailable && (
        <ArbolusTooltip content={t("chat")}>
          <Icon
            fontSize={fontSize}
            color={ARBOLUS_COLORS.bColorGrayIcon}
            name="chat_bubble"
            onClick={() => history.push(PROJECT_CHAT_BASE_ROUTE(id))}
            id="chat"
          />
        </ArbolusTooltip>
      )}
      <ArbolusTooltip content={t("calendar")}>
        <Icon
          fontSize={fontSize}
          color={ARBOLUS_COLORS.bColorGrayIcon}
          name="calendar_today"
          onClick={() => history.push(PROJECT_CALENDAR_ROUTE(id))}
          id="calendar"
        />
      </ArbolusTooltip>
      <ArbolusTooltip content={t("notesAndDocs")}>
        <Icon
          fontSize={fontSize}
          color={ARBOLUS_COLORS.bColorGrayIcon}
          name="description"
          onClick={() => history.push(PROJECT_FILES_ROUTE(id))}
          id="notesAndDocs"
        />
      </ArbolusTooltip>
    </div>
  );
};
