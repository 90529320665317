/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RouterState, connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, combineReducers, createStore } from "redux";

import {
  CacheAppState,
  CacheReducer
} from "@arbolus-technologies/stores/cache";
import {
  CanopyV2AppState,
  CanopyV2Reducer
} from "@arbolus-technologies/stores/canopy-V2";
import {
  CanopyBuilderAppState,
  CanopyBuilderReducer
} from "@arbolus-technologies/stores/canopy-builder";
import {
  CanopyClientAppState,
  ClientCanopyReducer
} from "@arbolus-technologies/stores/canopy-client";
import {
  CanopyExpertAppState,
  CanopyExpertReducer
} from "@arbolus-technologies/stores/canopy-expert";
import {
  ExpertNxAppState,
  ExpertNxReducer
} from "@arbolus-technologies/stores/expert";
import {
  PanelAppState,
  PanelReducer
} from "@arbolus-technologies/stores/panels";
import {
  ProjectNxAppState,
  ProjectNxReducer
} from "@arbolus-technologies/stores/project";
import {
  ProjectAnglesAppState,
  ProjectAnglesReducer
} from "@arbolus-technologies/stores/project-angles-store";
import {
  ProjectCanopyAppState,
  ProjectCanopyReducer
} from "@arbolus-technologies/stores/project-canopy-store";
import {
  ProjectExpertsAppState,
  ProjectExpertsReducer
} from "@arbolus-technologies/stores/project-experts-store";

export interface AppTestState
  extends ProjectCanopyAppState,
    ProjectAnglesAppState,
    CanopyExpertAppState,
    CanopyV2AppState,
    CanopyClientAppState,
    CanopyBuilderAppState,
    ProjectNxAppState,
    CacheAppState,
    PanelAppState,
    ProjectExpertsAppState,
    ExpertNxAppState {
  router: RouterState;
}

const createRootReducer = () =>
  combineReducers<AppTestState>({
    router: connectRouter(createBrowserHistory()),
    canopyBuilder: CanopyBuilderReducer,
    canopyClient: ClientCanopyReducer,
    projectCanopy: ProjectCanopyReducer,
    projectAngles: ProjectAnglesReducer,
    projectExperts: ProjectExpertsReducer,
    canopyExpert: CanopyExpertReducer,
    canopyV2: CanopyV2Reducer,
    projectNx: ProjectNxReducer,
    panels: PanelReducer,
    cache: CacheReducer,
    expertNx: ExpertNxReducer
  });

export const createTestStore = (initialState = {}) =>
  createStore(createRootReducer(), initialState, applyMiddleware());
