import React from "react";
import { TypeaheadResult } from "react-bootstrap-typeahead";
import { useTranslation } from "react-i18next";
import { Observable } from "rxjs";

import {
  ApiPaginatedRequest,
  ApiPaginatedResponse
} from "@arbolus-technologies/api";
import { Avatar } from "@arbolus-technologies/ui/components";
import { displayUserName } from "@arbolus-technologies/utils";

import {
  SelectController,
  TypeaheadSelectorControllerProps
} from "../SelectTypeahead/SelectController";

import styles from "./UserSelector.module.scss";

interface FullDisplayUser {
  firstName: string;
  lastName: string;
  email: string;
  profileImageUrl: string;
}

type DisplayUser = Partial<FullDisplayUser>;

export interface UserSelectorProps<T extends DisplayUser>
  extends TypeaheadSelectorControllerProps<DisplayUser> {
  isExperts?: boolean;
  getItems: (
    pagination: ApiPaginatedRequest
  ) => Observable<ApiPaginatedResponse<T>>;
}

export const UserSelector = <T extends DisplayUser>({
  isExperts = false,
  placeholderText,
  ...props
}: UserSelectorProps<T>): React.ReactElement => {
  const { t } = useTranslation();
  const findingText = isExperts
    ? t("common:userSelection:findingExperts")
    : t("common:userSelection:findingUsers");
  const placeholder =
    placeholderText ??
    (isExperts
      ? t("common:userSelection:findExpert")
      : t("common:userSelection:findUser"));

  const renderer = ({
    firstName,
    lastName,
    email,
    profileImageUrl
  }: TypeaheadResult<DisplayUser>): JSX.Element => (
    <div className={styles.itemContainer}>
      <Avatar
        avatar={{
          name: displayUserName({ firstName, lastName, email }),
          imageUrl: profileImageUrl
        }}
      />

      <div className={styles.itemText}>
        <span>{displayUserName({ firstName, lastName, email })}</span>
      </div>
    </div>
  );

  const getUserLabelKey = (user: DisplayUser): string =>
    displayUserName(user) ?? "";

  return (
    <SelectController
      {...props}
      placeholderText={placeholder}
      promptText={findingText}
      searchText={findingText}
      paginationText={t("common:userSelection:loadMore")}
      orderBy="firstName"
      renderer={renderer}
      getLabelKey={getUserLabelKey}
    />
  );
};
