import React from "react";
import { useTranslation } from "react-i18next";

import { CALL_STATUSES } from "@arbolus-technologies/api";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { IconWithText } from "@arbolus-technologies/ui/components";

import styles from "./FilterTypeComponents.module.scss";

interface FilterByCallStatusProps {
  status?: string;
  isCard?: boolean;
}

export const FilterByCallStatus: React.FC<FilterByCallStatusProps> = ({
  status,
  isCard
}) => {
  const { t } = useTranslation("scheduleTabFilters");

  if (!status) {
    return null;
  }

  const statusInLowerCase = status.toLowerCase();

  const getIconName = () => {
    if (statusInLowerCase === CALL_STATUSES.UNSCHEDULED.toLowerCase()) {
      return "calendar_today";
    } else if (statusInLowerCase === CALL_STATUSES.SCHEDULED.toLowerCase()) {
      return "call";
    } else {
      return "call_end";
    }
  };

  return (
    <IconWithText
      text={{
        content: t(statusInLowerCase),
        color: ARBOLUS_COLORS.bColorBaseDark
      }}
      icon={{
        name: getIconName(),
        color: ARBOLUS_COLORS.bColorBaseDark,
        customClasses: styles.icon,
        size: "24px"
      }}
      customClasses={isCard ? styles.card : styles.badge}
    />
  );
};
