import clsx from "clsx";
import React from "react";
import { Spinner } from "reactstrap";

interface LoaderProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  isFull?: boolean;
  isCentered?: boolean;
  isSidePanel?: boolean;
  isLight?: boolean;
  hideLoader?: boolean;
  size?: string;
}

export const Loader: React.FC<LoaderProps> = ({
  isFull,
  isCentered,
  isLight,
  isSidePanel,
  className,
  hideLoader = false,
  size = "32px" // ONLY in pixels
}: LoaderProps) => (
  <div
    className={clsx(
      "loader",
      {
        "full-container": isFull,
        centered: isCentered,
        "side-container": isSidePanel,
        light: isLight
      },
      className
    )}
    data-testid="loader"
  >
    {!hideLoader && (
      <Spinner style={{ width: size, height: size }} color="secondary" />
    )}
  </div>
);
