import React from "react";
import { useDispatch } from "react-redux";

import { IModalService, ModalService } from "../Components/Modals/ModalService";

export function useModalService(): IModalService {
  const dispatch = useDispatch();

  return React.useMemo(() => new ModalService(dispatch), [dispatch]);
}
