import { SORT_DIRECTION, SortCriteria } from "@arbolus-technologies/api";

export const FILES_SORT_CRITERIA = {
  Name: {
    name: "A-Z",
    value: "fileName",
    direction: SORT_DIRECTION.ASCENDING
  } as SortCriteria,
  NameReverse: {
    name: "Z-A",
    value: "fileName",
    direction: SORT_DIRECTION.DESCENDING
  } as SortCriteria,
  DATE: {
    name: "date",
    value: "created",
    direction: SORT_DIRECTION.DESCENDING
  } as SortCriteria
};

export enum FILE_UPLOADING_STATES {
  IDLE,
  UPLOADING,
  UPLOADED,
  UPLOAD_FAILED
}

export const MAXIMUM_VIEWABLE_UPLOADING_FILES = 3;

export const MAXIMUM_FILE_UPLOADING_SIZE = 50 * 1024 ** 2;

export const MAXIMUM_FILES_UPLOAD_AT_ONCE = 5;

export const MAXIMUM_FILE_NAME_LENGTH = 100;
