import { composeWithDevTools } from "@redux-devtools/extension";
import { routerMiddleware } from "connected-react-router";
import { Store, applyMiddleware, createStore } from "redux";
import { createEpicMiddleware } from "redux-observable";

import { AppAction } from "./actions";
import {
  ClientStoreDependencies,
  StoreDependencies,
  history
} from "./dependencies";
import rootEpic from "./epics";
import createRootReducer, { AppState } from "./reducers";

export default function initializeStore(): Store<AppState, AppAction> {
  const epicMiddleware = createEpicMiddleware<
    AppAction,
    AppAction,
    AppState,
    ClientStoreDependencies
  >({
    dependencies: StoreDependencies
  });

  const middlewares = [routerMiddleware(history), epicMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const isDevelopment = process.env.NODE_ENV === "development";

  const composeEnhancers = composeWithDevTools({
    trace: true,
    traceLimit: 150
  });

  const store = createStore(
    createRootReducer(history),
    isDevelopment ? composeEnhancers(middlewareEnhancer) : middlewareEnhancer
  );

  epicMiddleware.run(rootEpic);

  return store;
}
