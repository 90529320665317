import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { IconWithText } from "../IconWithText/IconWithText";

import styles from "./AssignedAngle.module.scss";

interface AssignedAngleProps {
  angle: { title: string; color: string };
}

export const AssignedAngle: React.FC<AssignedAngleProps> = ({ angle }) => (
  <div className={styles.angleContainer}>
    <IconWithText
      text={{ content: angle.title }}
      icon={{
        name: "zoom_in_map",
        color: ARBOLUS_COLORS.bColorBaseDark,
        customStyle: { backgroundColor: angle.color },
        customClasses: styles.icon
      }}
      customStyle={{ borderColor: angle.color }}
      customClasses={styles.container}
    />
  </div>
);
