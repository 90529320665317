import { OutputSelector, createSelector } from "reselect";

import { BookmarkCollection } from "@arbolus-technologies/models/common";

import { BookmarksStateSelector } from "../BookmarksSelector";
import {
  BookmarksAppState,
  BookmarksReducerState
} from "../models/definitions";

export const collection = (): OutputSelector<
  BookmarksAppState,
  BookmarkCollection | null,
  (res: BookmarksReducerState) => BookmarkCollection | null
> =>
  createSelector<
    BookmarksAppState,
    BookmarksReducerState,
    BookmarkCollection | null
  >(
    BookmarksStateSelector,
    (bookmarksReducerState) => bookmarksReducerState.collection.item || null
  );

export const collectionIsLoading = (): OutputSelector<
  BookmarksAppState,
  boolean,
  (res: BookmarksReducerState) => boolean
> =>
  createSelector<BookmarksAppState, BookmarksReducerState, boolean>(
    BookmarksStateSelector,
    (bookmarksReducerState) => bookmarksReducerState.collection.isLoading
  );
