import { useEffect, useState } from "react";

import {
  CustomersService,
  DefaultToasterService,
  GetSurveyTemplateResponse
} from "@arbolus-technologies/api";

export function useSurveyTemplate(surveyId: string) {
  const [surveyTemplate, setSurveyTemplate] =
    useState<GetSurveyTemplateResponse>();

  useEffect(
    function callApi() {
      CustomersService.getSurveyTemplate(surveyId).subscribe(
        (response) => setSurveyTemplate(response),
        (error) => DefaultToasterService.showApiErrors(error)
      );
    },
    [surveyId]
  );

  return surveyTemplate;
}
