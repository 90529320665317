import React from "react";
import { useSelector } from "react-redux";

import { ProjectExpertManagementWrapper } from "@arbolus-technologies/features/common";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";

import { ReferralsTabNavigator } from "../../Components/ReferralsTabNavigator/ReferralsTabNavigator";
import { RejectFeedbackPanel } from "../../Components/RejectFeedback/RejectFeedbackPanel";
import { TimezoneDisplaySelect } from "../../Components/TimezoneDisplaySelect/TimezoneDisplaySelect";
import { UpdateExpertAnglePanel } from "../../Modules/UpdateExpertAngle/UpdateExpertAnglePanel";
import { ReferralsPages } from "./referralsPages";
interface ReferralsPageProps {
  page: ReferralsPages;
  children: React.ReactNode;
}

export const ReferralsPage: React.FC<ReferralsPageProps> = ({
  page,
  children
}) => {
  const project = useSelector(ProjectNxSelector.projectData());

  if (!project) return null;

  return (
    <ProjectExpertManagementWrapper stickyHeader>
      <div className="flex-row-8 justify-content-between flex-wrap">
        <ReferralsTabNavigator projectId={project.id} current={page} />
        <TimezoneDisplaySelect />
      </div>
      {children}
      <UpdateExpertAnglePanel />
      <RejectFeedbackPanel />
    </ProjectExpertManagementWrapper>
  );
};
