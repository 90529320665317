import { Subject } from "rxjs";

export enum ContentPanelEventType {
  RENAME_DOCUMENT,
  DELETE_DOCUMENT,
  DELETE_EVENT
}

export interface ContentPanelEvent {
  eventType: ContentPanelEventType;
  itemId: string;
  documentName?: string;
}

class ContentPanelEvents {
  contentPanelEventSubject: Subject<ContentPanelEvent>;

  constructor() {
    this.contentPanelEventSubject = new Subject();
  }

  renameDocument(itemId: string, documentName: string): void {
    this.contentPanelEventSubject.next({
      eventType: ContentPanelEventType.RENAME_DOCUMENT,
      itemId,
      documentName
    });
  }

  deleteDocument(itemId: string): void {
    this.contentPanelEventSubject.next({
      eventType: ContentPanelEventType.DELETE_DOCUMENT,
      itemId
    });
  }

  deleteEvent(itemId: string): void {
    this.contentPanelEventSubject.next({
      eventType: ContentPanelEventType.DELETE_EVENT,
      itemId
    });
  }
}

export default new ContentPanelEvents();
