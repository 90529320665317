import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "arbolus-ui-components";
import clsx from "clsx";
import dompurify from "dompurify";
import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FormGroup, Label } from "reactstrap";

import { ANSWER_PREFERENCES_TYPE_VALUE } from "@arbolus-technologies/models/canopy";
import { MultiRatingChoice } from "@arbolus-technologies/models/canopy-panels";
import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { HR, InputController } from "@arbolus-technologies/ui/components";

import { createValueExpertSchema } from "./ValueExpertSchema";

import styles from "./ValueForExpertProvider.module.scss";

interface ValueForExpertProviderProps {
  multiRatingValues: MultiRatingChoice[] | null;
  minValue: number;
  maxValue: number;
  handleOnSubmit: (data: MultiFormData) => void;
  isAnswered?: boolean;
  isSaving?: boolean;
  canopyIsCompleted?: boolean;
  answerPreferenceType: ANSWER_PREFERENCES_TYPE_VALUE;
  value: number | null;
}

export interface MultiFormData {
  multiRatingValues: MultiRatingChoice[] | null;
  value: number | null;
}

export const ValueForExpertProvider: React.FC<ValueForExpertProviderProps> = ({
  multiRatingValues,
  minValue,
  maxValue,
  handleOnSubmit,
  isAnswered,
  isSaving,
  canopyIsCompleted,
  answerPreferenceType,
  value
}) => {
  const { t } = useTranslation("canopyExpert");

  const loggedInExpert = useSelector(CacheSelector.loggedInUser());
  const isDnc = loggedInExpert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC;
  const isDisabled = isDnc || canopyIsCompleted;

  const {
    control,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<MultiFormData>({
    resolver: yupResolver(
      createValueExpertSchema(minValue, maxValue, answerPreferenceType)
    ),
    mode: "onChange",
    defaultValues: {
      value,
      multiRatingValues
    }
  });

  const { fields } = useFieldArray({
    control,
    name: "multiRatingValues",
    keyName: "id"
  });

  return (
    <div className={styles.container}>
      <div
        className={clsx(styles.message, errors.value && styles.red)}
        dangerouslySetInnerHTML={{
          __html: dompurify.sanitize(
            t(`value${answerPreferenceType}Message`, {
              min: minValue,
              max: maxValue
            })
          )
        }}
      />
      <div className={styles.fieldContainer}>
        {answerPreferenceType === ANSWER_PREFERENCES_TYPE_VALUE.MULTI_RATING ? (
          fields.map((field, index) => {
            const multiRatingError = errors.multiRatingValues
              ? errors.multiRatingValues[index]?.multiRatingValue?.message
              : "";

            return (
              <FormGroup key={field.id} className={styles.formContainer}>
                <div className={styles.fieldBlock}>
                  <InputController
                    name={`multiRatingValues[${index}].multiRatingValue`}
                    control={control}
                    placeholder={`${minValue} - ${maxValue}`}
                    type="number"
                    classnames={styles.input}
                    onlyInteger
                    disabled={isDisabled}
                  />
                  <Label>{field.multiRatingChoiceText}</Label>
                </div>
                {multiRatingError && (
                  <Label className={clsx("invalid-feedback", styles.error)}>
                    {multiRatingError}
                  </Label>
                )}
              </FormGroup>
            );
          })
        ) : (
          <FormGroup className={styles.formContainer}>
            <div className={styles.fieldBlock}>
              <InputController
                name="value"
                control={control}
                placeholder={`${minValue} - ${maxValue}`}
                type="number"
                classnames={styles.input}
                onlyInteger
                disabled={isDisabled}
              />
              {answerPreferenceType ===
                ANSWER_PREFERENCES_TYPE_VALUE.PERCENTAGE && <span>%</span>}
            </div>
            {errors.value && (
              <Label className={clsx("invalid-feedback", styles.error)}>
                {errors.value.message}
              </Label>
            )}
          </FormGroup>
        )}
      </div>
      <HR margin={{ top: 3, bottom: 5 }} />
      {!isDisabled && (
        <div className={styles.buttonContainer}>
          <Button
            type="primary"
            text={isAnswered ? t("update") : t("save")}
            disabled={!isValid || isSaving}
            onClick={handleSubmit(handleOnSubmit)}
          />
        </div>
      )}
    </div>
  );
};
