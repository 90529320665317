import i18next from "i18next";
import * as Yup from "yup";

const questionSchema: Yup.ObjectSchema<{ question: string }> = Yup.object({
  question: Yup.string()
    .required("Required")
    .max(
      500,
      i18next.t("createSurvey:canopyTitleMaxLength", {
        length: 500
      })
    )
});

export const AngleScreeningQuestionSchema = Yup.object().shape({
  questions: Yup.array().of(questionSchema)
});
