import React from "react";

import styles from "./RoundedImage.module.scss";

interface RoundedImageProps {
  imageUrl: string;
}
export const RoundedImage: React.FC<RoundedImageProps> = ({ imageUrl }) => (
  <img className={styles.roundedImage} src={imageUrl} alt="img-logo" />
);
