import React from "react";

import styles from "./IconCircleFeedback.module.scss";

interface IconCircleFeedbackProps {
  icon: string;
  message?: string;
}
export const IconCircleFeedback: React.FC<IconCircleFeedbackProps> = ({
  icon,
  message
}) => (
  <>
    <img src={icon} alt="" />
    {message && <span className={styles.text}>{message}</span>}
  </>
);
