import { Button } from "arbolus-ui-components";
import dompurify from "dompurify";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import {
  CANOPY_APPLICATION_PAGE_ROUTE,
  CANOPY_DETAILS_PAGE_ROUTE,
  PublicRoutes
} from "@arbolus-technologies/routes";
import { HR } from "@arbolus-technologies/ui/components";

import logoArbolus from "../../../assets/arbolusLogo.svg";

import styles from "./CanopyInfo.module.scss";

interface CanopyInfoProps {
  canopyId: string;
}

export const CanopyInfo: React.FC<CanopyInfoProps> = ({ canopyId }) => {
  const { t } = useTranslation("welcomeCanopyPage");
  const history = useHistory();
  const newExpertApplicationFF = false; //isDevEnv();

  const applicationRoute = newExpertApplicationFF
    ? CANOPY_APPLICATION_PAGE_ROUTE
    : CANOPY_DETAILS_PAGE_ROUTE;

  const CANOPY_INFO_DETAILS = [
    {
      title: t("privacy"),
      items: [
        { title: "privacy1Title", message: "privacy1Message" },
        { title: "privacy2Title", message: "privacy2Message" }
      ]
    },
    {
      title: t("techGuidelines"),
      items: [
        { title: "techGuidelines1Title", message: "techGuidelines1Message" },
        { title: "techGuidelines2Title", message: "techGuidelines2Message" },
        { title: "techGuidelines3Title", message: "techGuidelines3Message" }
      ]
    }
  ];

  return (
    <div className={styles.container}>
      <img className={styles.logo} src={logoArbolus} alt="arbolus" />
      <div className={styles.info}>
        <h1 className={styles.title}>{t("title")}</h1>
        <p
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(t("description"))
          }}
        />
        {CANOPY_INFO_DETAILS.map((detail) => (
          <div key={detail.title} className={styles.detail}>
            <h2 className={styles.detailTitle}>{t(detail.title)}</h2>
            <ul className={styles.detailList}>
              {detail.items.map((item) => (
                <li key={item.title} className={styles.detailItem}>
                  <p className={styles.detailItemTitle}>{t(item.title)}</p>
                  <p
                    className={styles.detailItemMessage}
                    dangerouslySetInnerHTML={{
                      __html: dompurify.sanitize(t(item.message))
                    }}
                  />
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <HR margin={{ top: 2, bottom: 2 }} />
      <div className={styles.buttonContainer}>
        <Button
          type="secondary"
          onClick={() => history.push(applicationRoute(canopyId))}
          text={t("signIn")}
        />
        <Button
          onClick={() =>
            history.replace(PublicRoutes.CANOPY_REGISTER + canopyId)
          }
          text={t("createAnAccount")}
        />
      </div>
    </div>
  );
};
