import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, Input, Label } from "reactstrap";

import styles from "./EditTranscript.module.scss";

interface EditTranscriptProps {
  content: string;
  disabled: boolean;
  saveTranscript: (editContent: string) => void;
  cancelTranscript: () => void;
}

export const EditTranscript: React.FC<EditTranscriptProps> = ({
  content,
  disabled,
  saveTranscript,
  cancelTranscript
}) => {
  const { t } = useTranslation("canopyTranscript");
  const [editContent, setEditContent] = useState(content);
  return (
    <>
      <h1 className={styles.transcriptTitle}>{t("transcript")}</h1>
      <FormGroup className={styles.formGroup}>
        <Label for="transcript" hidden />
        <Input
          className={styles.textarea}
          type="textarea"
          name="transcript"
          id="transcript"
          autoComplete="off"
          value={editContent}
          onChange={(e) => setEditContent(e.target.value)}
          disabled={disabled}
        />
      </FormGroup>
      <div className={styles.actionButtons}>
        <Button size="sm" color="secondary" onClick={cancelTranscript}>
          {t("cancelEditTranscript")}
        </Button>
        <Button
          size="sm"
          color="primary"
          onClick={() => saveTranscript(editContent)}
          disabled={disabled || editContent === content}
        >
          {t("saveEditTranscript")}
        </Button>
      </div>
    </>
  );
};
