import React from "react";

export interface CanopyContextProps {
  isEditMode: boolean;
  isCanopyActive: boolean;
  showUnsavedChanges: boolean;
}

const defaultCanopyContext: CanopyContextProps = {
  isEditMode: false,
  isCanopyActive: false,
  showUnsavedChanges: false
};

export const CanopyContext =
  React.createContext<CanopyContextProps>(defaultCanopyContext);

export const CanopyContextProvider = CanopyContext.Provider;
export const CanopyContextConsumer = CanopyContext.Consumer;
