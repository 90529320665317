import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "arbolus-ui-components";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FormGroup } from "reactstrap";

import { CanopyConstraints } from "@arbolus-technologies/models/canopy";
import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  HR,
  InputController,
  WordCounter
} from "@arbolus-technologies/ui/components";

import { createShortTextExpertSchema } from "./ShortTextExpertSchema";

import styles from "./ShortTextForExpertProvider.module.scss";

interface ShortTextForExpertProviderProps {
  shortText: string;
  handleOnSubmit: (data: MultiFormData) => void;
  isAnswered?: boolean;
  isSaving?: boolean;
  canopyIsCompleted?: boolean;
}

export interface MultiFormData {
  shortText: string;
}

export const ShortTextForExpertProvider: React.FC<
  ShortTextForExpertProviderProps
> = ({
  shortText,
  handleOnSubmit,
  isAnswered,
  isSaving,
  canopyIsCompleted
}) => {
  const { t } = useTranslation("canopyExpert");

  const loggedInExpert = useSelector(CacheSelector.loggedInUser());
  const isDnc = loggedInExpert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC;
  const isDisabled = isDnc || canopyIsCompleted;

  const {
    control,
    handleSubmit,
    trigger,
    watch,
    formState: { errors, isValid, isValidating }
  } = useForm<MultiFormData>({
    resolver: yupResolver(createShortTextExpertSchema),
    mode: "onChange",
    defaultValues: {
      shortText
    }
  });

  useEffect(
    () =>
      // We use this to skip the trigger in first render
      () => {
        trigger();
      },
    [isValidating, trigger]
  );

  const updatedShortText = watch("shortText");

  return (
    <div className={styles.container}>
      <div className={styles.message}>
        {t("shortTextForExpertMessage", {
          wordCount: CanopyConstraints.MAX_SHORT_TEXT_ANSWER_LENGTH
        })}
      </div>
      <FormGroup className={styles.fieldContainer}>
        <InputController
          type="textarea"
          control={control}
          name="shortText"
          placeholder={t("shortTextPlaceholder")}
          isInvalid={!!errors.shortText}
          size="big"
          disabled={isDisabled}
        />
        {errors.shortText && (
          <div className={styles.error}>{errors.shortText.message}</div>
        )}
        <WordCounter
          maxLength={CanopyConstraints.MAX_SHORT_TEXT_ANSWER_LENGTH}
          textLength={updatedShortText?.length || 0}
        />
      </FormGroup>
      <HR margin={{ top: 3, bottom: 5 }} />
      {!isDisabled && (
        <div className={styles.buttonContainer}>
          <Button
            type="primary"
            text={isAnswered ? t("update") : t("save")}
            disabled={!isValid || isSaving}
            onClick={handleSubmit(handleOnSubmit)}
          />
        </div>
      )}
    </div>
  );
};
