import React from "react";
import { Redirect } from "react-router-dom";

import {
  MixpanelPages,
  PageTracker,
  useIsChatAvailable
} from "@arbolus-technologies/features/common";
import { ChatClientDetails } from "@arbolus-technologies/models/project";
import { PROJECT_ROUTE } from "@arbolus-technologies/routes";

import { isMobileChat } from "../../Components/Chat/utils";
import { ChatProvider } from "../../Contexts/ChatContext/ChatContext";
import { DesktopTabletChatPage } from "./DesktopTabletChatPage/DesktopTabletChatPage";
import { MobileChatPage } from "./MobileChatPage/MobileChatPage";

export const ChatPage: React.FC<{
  projectId: string;
  client?: ChatClientDetails;
  handleOpenExpertSidePanel: (expertId: string, referralId: string) => void;
}> = ({ projectId, client, handleOpenExpertSidePanel }) => {
  const DesktopOrMobilePage = isMobileChat()
    ? MobileChatPage
    : DesktopTabletChatPage;
  const canAccessChat = useIsChatAvailable();

  if (!canAccessChat) {
    return <Redirect to={PROJECT_ROUTE(projectId)} />;
  }

  return (
    <PageTracker page={MixpanelPages.Chat}>
      <ChatProvider projectId={projectId} client={client}>
        <DesktopOrMobilePage
          projectId={projectId}
          handleOpenExpertSidePanel={handleOpenExpertSidePanel}
        />
      </ChatProvider>
    </PageTracker>
  );
};
