import React from "react";

import { DiscoverFilterOption } from "@arbolus-technologies/models/project";

import { SEARCH_LIMIT } from "../../../../Modules/DiscoverFilters/DiscoverFilters";
import { SelectedFilterPills } from "../SelectedFIlterPills/SelectedFilterPills";
import { SearchWithCheckboxDropdown } from "./SearchWithCheckboxDropdown/SearchWithCheckboxDropdown";

import styles from "./SearchWithCheckboxPills.module.scss";

interface SearchWithCheckboxPillsProps {
  disablePillToggle?: boolean;
  selectedOptions: DiscoverFilterOption[];
  preSelectedOptions: DiscoverFilterOption[];
  setSelectedOptions: (option: DiscoverFilterOption[]) => void;
  setPreSelectedOptions: (option: DiscoverFilterOption[]) => void;
  filterOptions: DiscoverFilterOption[];
  onBottomReached: (query: string) => void;
  onClearQueryClicked: () => void;
  onInputChangeEvent: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  onSelectOption: (option: DiscoverFilterOption) => void;
  searchQuery: string;
  placeholder?: string;
  disabled?: boolean;
  applyFilter: () => void;
  errorText?: string;
  urlChangeOnFilterUpdate: (options: DiscoverFilterOption[]) => void;
  handleSelectAll?: () => void;
  showFlagIcon?: boolean;
}

export const SearchWithCheckboxPills: React.FC<
  SearchWithCheckboxPillsProps
> = ({
  selectedOptions,
  preSelectedOptions,
  setSelectedOptions,
  setPreSelectedOptions,
  filterOptions,
  onBottomReached,
  onClearQueryClicked,
  onInputChangeEvent,
  onSelectOption,
  searchQuery,
  placeholder,
  disabled,
  disablePillToggle,
  applyFilter,
  errorText,
  urlChangeOnFilterUpdate,
  handleSelectAll,
  showFlagIcon = false
}) => {
  const maxFiltersReached = preSelectedOptions.length === SEARCH_LIMIT;

  return (
    <>
      <SearchWithCheckboxDropdown
        filterOptions={filterOptions}
        onClearQueryClicked={onClearQueryClicked}
        onInputChangeEvent={onInputChangeEvent}
        onSelectOption={onSelectOption}
        searchQuery={searchQuery}
        placeholder={placeholder}
        disabled={disabled}
        onBottomReached={onBottomReached}
        preSelectedOptions={preSelectedOptions}
        applyFilter={applyFilter}
        handleSelectAll={handleSelectAll}
        showFlagIcon={showFlagIcon}
      />
      {maxFiltersReached && errorText && (
        <span className={styles.error}>{errorText}</span>
      )}
      <SelectedFilterPills
        selectedOptions={selectedOptions}
        preSelectedOptions={preSelectedOptions}
        setSelectedOptions={setSelectedOptions}
        setPreSelectedOptions={setPreSelectedOptions}
        disablePillToggle={disablePillToggle}
        urlChangeOnFilterUpdate={urlChangeOnFilterUpdate}
        showFlagIcon={showFlagIcon}
      />
    </>
  );
};
