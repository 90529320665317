import React from "react";

import { Icon } from "arbolus-ui-components";
import styles from "./Pill.module.scss";

interface PillProps {
  text: string;
  icon: string;
  color?: string;
  borderColor?: string;
  onIconClick: () => void;
}

export const Pill: React.FC<PillProps> = ({
  icon,
  text,
  color,
  borderColor,
  onIconClick
}) => (
  <div className={styles.container} style={{ borderColor }}>
    <span className={styles.text} style={{ color, borderColor }}>
      {text}
    </span>
    <Icon name={icon} fontSize="12px" color={color} onClick={onIconClick} />
  </div>
);
