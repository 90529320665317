import React from "react";
import { useTranslation } from "react-i18next";

import { EmptyComponent } from "../EmptyComponent";

import clock from "../../../assets/images/clock.png";

export const DashboardEmptyNotification: React.FC = () => {
  const { t } = useTranslation("dashboardNotifications");
  return (
    <EmptyComponent
      title={t("emptyStateTitle")}
      subtitle={t("emptyStateSubtitle")}
      logo={clock}
    />
  );
};
