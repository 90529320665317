import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React, { useMemo } from "react";

import { Company } from "@arbolus-technologies/api";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./SelectableCompany.module.scss";

interface SelectableCompanyProps {
  company: Company;
  isSelected: boolean;
  handleSelectedCompany: (company: Company) => void;
}
export const SelectableCompany: React.FC<SelectableCompanyProps> = ({
  company,
  isSelected,
  handleSelectedCompany
}) => {
  const icon = useMemo(
    () =>
      isSelected
        ? { color: ARBOLUS_COLORS.bColorBasePurple, name: "check" }
        : { color: ARBOLUS_COLORS.bColorSecondaryGreyMid, name: "add" },
    [isSelected]
  );

  return (
    <div
      key={company.id}
      className={clsx(
        styles.companyItem,
        isSelected && styles.companyItemSelected
      )}
      onClick={() => handleSelectedCompany(company)}
    >
      <span className={styles.companyName}>{company.name}</span>
      <Icon {...icon} fontSize="24px" />
    </div>
  );
};
