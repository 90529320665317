import React from "react";

import styles from "./PopoverItem.module.scss";

interface PopoverItemInterface {
  title: string | JSX.Element;
  icon: JSX.Element;
  handleOnClick: () => void;
}

export const PopoverItem: React.FC<PopoverItemInterface> = ({
  title,
  icon,
  handleOnClick
}) => (
  <div className={styles.itemContainer} onClick={handleOnClick}>
    <span className={styles.itemText}>{title}</span>
    {icon}
  </div>
);
