import { Field, Formik, FormikProps } from "formik";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { createStructuredSelector } from "reselect";
import { Subscription } from "rxjs";
import SimpleBar from "simplebar-react";

import { ToasterService } from "@arbolus-technologies/api";
import {
  MixPanelActions,
  MixPanelEventNames,
  trackEvent
} from "@arbolus-technologies/features/common";
import { Loader } from "@arbolus-technologies/ui/components";
import { getFileExtension } from "@arbolus-technologies/utils";

import {
  ContentPanelContextConsumer,
  ContentPanelContextProps
} from "../../../../../contexts/contentPanel/ContentPanelContext";
import ContentPanelEvents from "../../../../../contexts/contentPanel/ContentPanelEvents";
import { CIQError, ErrorResponse } from "../../../../../models/api";
import { Document, ExpertDocument } from "../../../../../models/documents";
import { DocumentService, RBServiceManager } from "../../../../../services";
import { AppAction } from "../../../../../store/actions";
import { AppState } from "../../../../../store/reducers";
import { CIQFormInput } from "../../../../app/components";
import { AuthSelector } from "../../../../auth/store";
import { CIQFileIcon } from "../../documents";
import { DocumentNameValidationSchema } from "./DocumentNameSchema";
import ExpertsDocumentItem from "./ExpertsDocumentItem";

const notification = new ToasterService();

interface DocumentNameFormValues {
  documentName: string;
}
enum DocumentViewPanelStates {
  NONE,
  RENAME,
  DELETE
}

interface DocumentViewPanelStoreProps {
  currentUserId?: string;
}

interface DocumentViewPanelProps extends DocumentViewPanelStoreProps {
  projectId: string;
  documentId: string;
  onPanelClose: () => void;
}

interface DocumentViewPanelState {
  modificationType: DocumentViewPanelStates;
  documentName: string;
  expertsDocument: ExpertDocument[];
  sharingChatIds: string[];
  isDeleteLoading: boolean;
  isRenameLoading: boolean;
  isDocumentLoading: boolean;
  isExpertsDocumentLoading: boolean;
}

type DocumentViewPanelIntersectProps = DocumentViewPanelProps & WithTranslation;

class DocumentViewPanel extends React.Component<
  DocumentViewPanelIntersectProps,
  DocumentViewPanelState
> {
  static defaultProps = {
    projectId: "",
    document: {} as Document,
    currentUserId: ""
  };

  constructor(props: DocumentViewPanelIntersectProps) {
    super(props);
    this.state = {
      modificationType: DocumentViewPanelStates.NONE,
      documentName: "",
      expertsDocument: [],
      sharingChatIds: [],
      isDeleteLoading: false,
      isRenameLoading: false,
      isExpertsDocumentLoading: true,
      isDocumentLoading: true
    };
  }

  componentDidMount(): void {
    this.fetchDocument();
    this.fetchExpertsDocument();
  }

  componentWillUnmount(): void {
    this.expertsDocumentSubscription?.unsubscribe();
    this.documentDownloadSubscription?.unsubscribe();
    this.documentDeleteSubscription?.unsubscribe();
    this.documentRenameSubscription?.unsubscribe();
    this.documentShareSubscription?.unsubscribe();
    this.documentFetchSubscription?.unsubscribe();
  }

  private document?: Document;

  private documentFetchSubscription?: Subscription;

  private expertsDocumentSubscription?: Subscription;

  private documentDownloadSubscription?: Subscription;

  private documentDeleteSubscription?: Subscription;

  private documentRenameSubscription?: Subscription;

  private documentShareSubscription?: Subscription;

  fetchDocument = (): void => {
    const { projectId, documentId, onPanelClose } = this.props;

    this.documentFetchSubscription = DocumentService.getDocument(
      projectId,
      documentId
    ).subscribe(
      (document) => {
        this.document = document;
        this.setState({
          isDocumentLoading: false,
          documentName: DocumentService.prettifyFileName(document.fileName)
        });
      },
      (error: ErrorResponse<CIQError>) => {
        notification.showError(error.message);
        onPanelClose();
      }
    );
  };

  fetchExpertsDocument = (): void => {
    const { projectId, documentId, onPanelClose } = this.props;

    this.expertsDocumentSubscription = RBServiceManager.serviceCaller(
      DocumentService.getClientDocumentStatus(projectId, documentId),
      DocumentService.getExpertDocumentStatus(projectId, documentId)
    ).subscribe(
      (expert) => {
        const { items } = expert;
        this.setState({
          expertsDocument: items,
          isExpertsDocumentLoading: false
        });
      },
      (error: ErrorResponse<CIQError>) => {
        notification.showError(error.message);
        onPanelClose();
      }
    );
  };

  deleteDocument = (): void => {
    const { documentId, projectId, t, onPanelClose } = this.props;
    this.setState({
      isDeleteLoading: true
    });
    this.documentDeleteSubscription = DocumentService.deleteDocument(
      projectId,
      documentId
    ).subscribe(
      () => {
        notification.showSuccess(t("deleteSuccess"));
        ContentPanelEvents.deleteDocument(documentId);
        this.setState({
          isDeleteLoading: false
        });
        onPanelClose();
      },
      () => {
        this.setState({
          isDeleteLoading: false
        });
        notification.showError(t("deleteFailed"));
      }
    );
  };

  downloadDocument = (): void => {
    const { documentId, projectId } = this.props;

    const mixpanelProperties = {
      projectId,
      documentId,
      fileName: this.document?.fileName
    };

    this.documentDownloadSubscription = DocumentService.downloadDocument(
      projectId,
      documentId
    ).subscribe(
      (document) => {
        trackEvent(MixPanelEventNames.DownloadDocuments, {
          action: MixPanelActions.Downloaded,
          ...mixpanelProperties,
          uploadedBy: {
            id: this.document?.uploadedUserId,
            email: this.document?.uploadedUser.email
          }
        });
        window.location.href = document.downloadUrl;
      },
      (error: ErrorResponse<CIQError>) => {
        notification.showError(error.message);
        trackEvent(MixPanelEventNames.DownloadDocuments, {
          action: MixPanelActions.DownloadFailed,
          ...mixpanelProperties
        });
      }
    );
  };

  handleRenameDocumentSubmit = (values: DocumentNameFormValues): void => {
    const { documentId, projectId, t } = this.props;
    const { fileName } = this.document!;
    const { documentName } = values;
    const fileExtension = getFileExtension(fileName);
    const nextExtension = fileExtension ? `.${fileExtension}` : "";

    this.setState({
      isRenameLoading: true
    });

    this.documentRenameSubscription = DocumentService.renameDocument(
      projectId,
      documentId,
      documentName.trim()
    ).subscribe(
      () => {
        notification.showSuccess(t("renameSuccess"));
        const newFileName = `${documentName}${nextExtension}`;
        this.setState({
          documentName: documentName.trim(),
          modificationType: DocumentViewPanelStates.NONE,
          isRenameLoading: false
        });
        ContentPanelEvents.renameDocument(documentId, newFileName);
      },
      () => {
        notification.showError(t("renameFailed"));
        this.setState({
          isRenameLoading: false
        });
      }
    );
  };

  handleShareOnClicked = (chatId: string): void => {
    const { sharingChatIds } = this.state;
    const { projectId, documentId, t } = this.props;
    this.setState({
      sharingChatIds: sharingChatIds.concat(chatId)
    });

    this.documentShareSubscription = DocumentService.shareDocument(
      projectId,
      chatId,
      {
        message: "",
        documentIds: [documentId]
      }
    ).subscribe(
      () => {
        notification.showSuccess(t("shareSuccess"));
        // eslint-disable-next-line no-shadow
        const { expertsDocument, sharingChatIds } = this.state;
        this.setState({
          sharingChatIds: sharingChatIds.filter((w) => w !== chatId),
          expertsDocument: expertsDocument.map((ed) => ({
            ...ed,
            shared: ed.workspaceId === chatId ? true : ed.shared
          }))
        });
      },
      () => {
        notification.showError(t("shareFailed"));
        // eslint-disable-next-line no-shadow
        const { sharingChatIds } = this.state;
        this.setState({
          sharingChatIds: sharingChatIds.filter((w) => w !== chatId)
        });
      }
    );
  };

  handleDocumentModificationInitiate = (
    type: DocumentViewPanelStates
  ): void => {
    this.setState({
      modificationType: type
    });
  };

  handleRenameFileName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      documentName: event.target.value
    });
  };

  renderInitialContainer = (): JSX.Element => {
    const { currentUserId, t } = this.props;
    const { fileSize, fileName, uploadedUserId } = this.document!;

    const prettifiedFileSize = DocumentService.prettifyFileSize(fileSize);
    const isOwner = uploadedUserId === currentUserId;

    return (
      <div className="file-view-body panel-body">
        <div className="top-container">
          <div className="file-icon">{CIQFileIcon(fileName)}</div>
          <div className="file-size">{prettifiedFileSize}</div>
        </div>
        <div className="bottom-container">
          <div className="left-container">
            <Button
              size="lg"
              color="primary"
              type="button"
              className="btn-bold"
              onClick={this.downloadDocument}
            >
              {t("download")}
            </Button>
          </div>
          {isOwner && (
            <div className="right-container">
              <span
                className="ciq-icon ciq-edit"
                onClick={(): void =>
                  this.handleDocumentModificationInitiate(
                    DocumentViewPanelStates.RENAME
                  )
                }
              />
              <span
                className="ciq-icon ciq-trash"
                onClick={(): void =>
                  this.handleDocumentModificationInitiate(
                    DocumentViewPanelStates.DELETE
                  )
                }
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  renderRenameContainer = (): JSX.Element => {
    const { documentName, isRenameLoading } = this.state;
    const { t } = this.props;
    const { fileName } = this.document!;

    return (
      <Formik<DocumentNameFormValues>
        initialValues={{
          documentName
        }}
        validationSchema={DocumentNameValidationSchema}
        onSubmit={this.handleRenameDocumentSubmit}
      >
        {(formikProps: FormikProps<DocumentNameFormValues>): JSX.Element => {
          const { isValid, dirty, handleSubmit } = formikProps;
          const isSaveDisabled = !isValid || !dirty;

          return (
            <div className="file-view-body file-view-edit panel-body">
              <div className="top-container">
                <Field
                  name="documentName"
                  type="textarea"
                  autoComplete="off"
                  component={CIQFormInput}
                />
              </div>

              <div className="bottom-container">
                <Button
                  type="button"
                  size="sm"
                  color="primary"
                  // @ts-ignore
                  onClick={handleSubmit}
                  disabled={isSaveDisabled || isRenameLoading}
                >
                  {t("save")}
                </Button>
                <Button
                  size="sm"
                  color="secondary"
                  disabled={isRenameLoading}
                  type="button"
                  onClick={(): void =>
                    this.setState({
                      documentName: DocumentService.prettifyFileName(fileName),
                      modificationType: DocumentViewPanelStates.NONE
                    })
                  }
                >
                  {t("cancel")}
                </Button>
              </div>
            </div>
          );
        }}
      </Formik>
    );
  };

  renderDeleteContainer = (): JSX.Element => {
    const { t } = this.props;
    const { isDeleteLoading } = this.state;

    const { fileName, fileSize } = this.document!;
    const prettifiedFileSize = DocumentService.prettifyFileSize(fileSize);

    return (
      <div className="file-view-body file-view-delete panel-body">
        <div className="top-container">
          <div className="file-icon">{CIQFileIcon(fileName)}</div>
          <div className="file-size">{prettifiedFileSize}</div>
        </div>
        <div className="bottom-container">
          <div className="left-container">
            {t("areYouSureAlert")}
            <p />
          </div>
          <div className="right-container">
            <Button
              size="sm"
              color="danger"
              disabled={isDeleteLoading}
              type="button"
              onClick={this.deleteDocument}
            >
              {t("delete")}
            </Button>
            <Button
              size="sm"
              color="secondary"
              disabled={isDeleteLoading}
              type="button"
              onClick={(): void =>
                this.setState({
                  modificationType: DocumentViewPanelStates.NONE
                })
              }
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  renderDocumentShareContainer = (): JSX.Element => {
    const { expertsDocument, sharingChatIds, isExpertsDocumentLoading } =
      this.state;
    const { t } = this.props;
    const { isDocumentLoading } = this.state;

    return (
      <div className="user-list-container">
        <h2>{t("shareWith")}</h2>
        <SimpleBar className="users-list simplebar-light">
          {isExpertsDocumentLoading || isDocumentLoading ? (
            <Loader />
          ) : (
            expertsDocument.map(
              (expertDocument: ExpertDocument, index: number) => (
                <ExpertsDocumentItem
                  expertsDocument={expertDocument}
                  key={index}
                  onShareClicked={this.handleShareOnClicked}
                  isLoading={sharingChatIds.includes(
                    expertDocument.workspaceId
                  )}
                />
              )
            )
          )}
        </SimpleBar>
      </div>
    );
  };

  renderFileEditContainer = (): JSX.Element => {
    const { modificationType } = this.state;
    let panel = null;

    if (modificationType === DocumentViewPanelStates.RENAME) {
      panel = this.renderRenameContainer();
    } else if (modificationType === DocumentViewPanelStates.DELETE) {
      panel = this.renderDeleteContainer();
    } else {
      panel = this.renderInitialContainer();
    }

    return panel;
  };

  renderHeader = (): JSX.Element => {
    let content = null;
    const { modificationType, documentName } = this.state;
    const { t } = this.props;

    if (modificationType === DocumentViewPanelStates.NONE) {
      content = documentName;
    } else if (modificationType === DocumentViewPanelStates.RENAME) {
      content = t("editTitle");
    } else if (modificationType === DocumentViewPanelStates.DELETE) {
      content = documentName;
    }

    return <h2>{content}</h2>;
  };

  render(): JSX.Element {
    const { isDocumentLoading } = this.state;
    if (isDocumentLoading) {
      return <Loader isFull />;
    }

    return (
      <div className="content-panel-body">
        <div className="panel-header">
          {this.renderHeader()}
          <ContentPanelContextConsumer>
            {(contentPanelContext: ContentPanelContextProps): JSX.Element => (
              <div
                className="btn-close"
                onClick={contentPanelContext.closePanel}
              >
                <i className="ciq-icon ciq-close" />
              </div>
            )}
          </ContentPanelContextConsumer>
        </div>
        {this.renderFileEditContainer()}
        {this.renderDocumentShareContainer()}
      </div>
    );
  }
}

const mapStateToProps = (): Record<string, AppAction> =>
  createStructuredSelector<
    AppState,
    DocumentViewPanelProps,
    DocumentViewPanelStoreProps
  >({
    currentUserId: AuthSelector.authUserIdSelector()
  });

export default withTranslation("documentViewPanel")(
  connect(mapStateToProps, null)(DocumentViewPanel)
);
