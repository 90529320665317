import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ProjectApplication as ProjectApplicationType,
  ProjectService,
  ToasterService
} from "@arbolus-technologies/api";
import { REFERRAL_SUB_STATE } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  LoaderOrComponent,
  PageWithSteps
} from "@arbolus-technologies/ui/components";
import { MainPageLayout } from "@arbolus-technologies/ui/layout";

import { Welcome } from "../Components/Welcome/Welcome";
import { STEPS, getProjectSteps } from "../helpers/constants";

interface ProjectApplicationProps {
  projectService?: typeof ProjectService;
  notificationService?: ToasterService;
}

const PROJECT_STEPS = getProjectSteps();

export const ProjectApplication: React.FC<ProjectApplicationProps> = ({
  projectService = ProjectService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("expertApplications");
  const { projectId } = useParams<{ projectId: string }>();

  const [project, setProject] = useState<ProjectApplicationType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showWelcomeScreen, setShowWelcomeScreen] = useState<boolean>(true);
  const [initialStep, setInitialStep] = useState<number>(0);

  const expertId = useSelector(CacheSelector.loggedInUserExpertId());

  useEffect(() => {
    projectService.getExpertApplicationBrief(projectId).subscribe(
      (projectApplication) => {
        setProject(projectApplication);
        setIsLoading(false);
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showError(error.message);
        setIsLoading(false);
      }
    );
  }, [expertId, projectService, projectId, notificationService]);

  useEffect(() => {
    let step = 0;

    project?.referral.applicationStatus === REFERRAL_SUB_STATE.ACCEPT && step++;

    setInitialStep(step);
  }, [project]);

  const filteredSteps = PROJECT_STEPS.filter((step) => {
    if (step.name === STEPS.COMPLIANCE) {
      return project?.referral.hasCompliance;
    }

    if (step.name === STEPS.SCREENING_QUESTIONS) {
      return project?.referral.hasQuestions;
    }

    return true;
  });

  const updatedSteps = filteredSteps.map((step) => ({
    ...step,
    name: t(step.name)
  }));

  const handleDeclineApplication = (): void => {
    /** */
  };

  return (
    <MainPageLayout title={t("projectApplication")}>
      <LoaderOrComponent isLoading={isLoading}>
        {project &&
          (showWelcomeScreen ? (
            <Welcome
              isLoading={isLoading}
              onStartApplication={() => setShowWelcomeScreen(false)}
              onDeclineApplication={handleDeclineApplication}
              stepDetails={filteredSteps.map((step) => t(`${step.name}Step`))}
            />
          ) : (
            <PageWithSteps
              steps={updatedSteps}
              initialStep={initialStep}
              stepComponentProps={{
                steps: updatedSteps,
                projectId: project.id,
                referral: project.referral,
                isAgreementAccepted:
                  project.referral.applicationStatus ===
                  REFERRAL_SUB_STATE.ACCEPT,
                goToWelcomeScreen: () => setShowWelcomeScreen(true)
              }}
            />
          ))}
      </LoaderOrComponent>
    </MainPageLayout>
  );
};
