import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ApiPaginatedResponse,
  DefaultToasterService,
  INBOX_NOTIFICATION_CATEGORY,
  InboxNotification,
  NotificationsGetRequest,
  ToasterService,
  UserService
} from "@arbolus-technologies/api";
import { DashboardEmptyNotification } from "@arbolus-technologies/ui/components";
import { GrayBox } from "@arbolus-technologies/ui/layout";
import { isValueTruthy } from "@arbolus-technologies/utils";

import { DashboardNotificationCard } from "./DashboardNotificationCard";
import { BaseNotification } from "./models/BaseNotification";
import { inboxNotificationMapper } from "./models/InboxNotificationMapper";
import { PlaceholderNotification } from "./models/PlaceholderNotification";

interface DashboardNotificationsProps {
  route: string;
  userService?: typeof UserService;
  notificationService?: ToasterService;
}

const request: NotificationsGetRequest = {
  notificationType: [
    INBOX_NOTIFICATION_CATEGORY.Messages,
    INBOX_NOTIFICATION_CATEGORY.EventInvites,
    INBOX_NOTIFICATION_CATEGORY.Surveys,
    INBOX_NOTIFICATION_CATEGORY.NewReferrals
  ],
  adminNotification: false,
  seen: false,
  offset: 0,
  limit: 4
};

export const DashboardNotifications: React.FC<DashboardNotificationsProps> = ({
  route,
  userService = UserService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("dashboardNotifications");
  const [notifications, setNotifications] = useState<BaseNotification[]>([
    new PlaceholderNotification("placeholder0"),
    new PlaceholderNotification("placeholder1"),
    new PlaceholderNotification("placeholder2"),
    new PlaceholderNotification("placeholder3")
  ]);

  const getNotifications = useCallback(() => {
    userService.getNotifications(request).subscribe({
      next: (response: ApiPaginatedResponse<InboxNotification>) => {
        const mapped = response.items
          .map(inboxNotificationMapper)
          .filter(isValueTruthy);
        setNotifications(mapped);
      }
    });
  }, [userService, setNotifications]);

  const clearNotification = useCallback(
    (notificationId: string) => {
      setNotifications((notifications) => [
        ...notifications.filter(
          (notification) => notification.id !== notificationId
        ),
        new PlaceholderNotification(`placeholder${notificationId}`)
      ]);
      userService.markNotificationsAsDone([notificationId]).subscribe({
        error: notificationService.showApiErrors,
        complete: getNotifications
      });
    },
    [userService, notificationService, getNotifications]
  );

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  return (
    <GrayBox
      title={{
        text: t("title"),
        to: "/inbox"
      }}
      flexDirection="column"
      gap={[2, 0]}
    >
      {notifications.length === 0 && <DashboardEmptyNotification />}
      {notifications.map((notification) => (
        <DashboardNotificationCard
          key={notification.id}
          notification={notification}
          clearNotification={clearNotification}
        />
      ))}
    </GrayBox>
  );
};
