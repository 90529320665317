import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import { AuthRouteConstants } from "../../../../../constants";
import { EX_LINK_NOT_FOUND_PAGE } from "../../../../../constants/externalLink";
import {
  LOGIN,
  REGISTER
} from "../../../../../constants/navigation/publicRoutes";
import { AppState } from "../../../../../store/reducers";
import { AuthSelector } from "../../../../auth/store";

import { ReactComponent as ErrorImage } from "../../../../../assets/images/empty-states/404.svg";

interface NotFoundPageStoreProps {
  currentUserId?: string;
}

type NotFoundPageProps = NotFoundPageStoreProps;

const NotFoundPage = ({ currentUserId }: NotFoundPageProps): JSX.Element => {
  const { t } = useTranslation("notFoundPage");
  return (
    <div className="not-found-container">
      <ErrorImage className="not-found-image" />
      <div className="not-found-message">
        <h1>{t("404")}</h1>
        <h3>{t("notFound")}</h3>
        <p>{t("helpLinks")}</p>
        <div className="helpful-links">
          {currentUserId ? (
            <Link to={AuthRouteConstants.DASHBOARD}>{t("home")}</Link>
          ) : (
            <>
              <Link to={LOGIN}>{t("login")}</Link>
              <Link to={REGISTER}>{t("signUp")}</Link>
            </>
          )}

          <a
            href={EX_LINK_NOT_FOUND_PAGE.ARBOLUS_HELP}
            rel="noopener noreferrer"
            target="_blank"
          >
            {t("helpCenter")}
          </a>
          <a
            href={EX_LINK_NOT_FOUND_PAGE.ARBOLUS_FOR_EXPERT}
            rel="noopener noreferrer"
            target="_blank"
          >
            {t("forExperts")}
          </a>
          <a
            href={EX_LINK_NOT_FOUND_PAGE.ARBOLUS_FOR_COMPANIES}
            rel="noopener noreferrer"
            target="_blank"
          >
            {t("forCompanies")}
          </a>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector<
  AppState,
  NotFoundPageProps,
  NotFoundPageStoreProps
>({
  currentUserId: AuthSelector.authUserIdSelector()
});

export default connect(mapStateToProps)(NotFoundPage);
