/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConnectedRouter } from "connected-react-router";
import { createMemoryHistory } from "history";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { createTestStore } from "../createTestStore";

export function withRouterAndReduxTestWrapper(
  Component: React.ComponentType<any>,
  initialState = {}
): React.FC<any> {
  const history = createMemoryHistory();

  const RouterAndReduxWrapper = (props: any) => (
    <BrowserRouter>
      <Provider store={createTestStore(initialState)}>
        <ConnectedRouter history={history}>
          <Component {...props} />
        </ConnectedRouter>
      </Provider>
    </BrowserRouter>
  );

  return RouterAndReduxWrapper;
}
