import React from "react";
import { useTranslation } from "react-i18next";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { LinkBoxButton } from "../../BoxButton/LinkBoxButton";
import { StatButtonProps } from "./StatButtonProps";

export const CallsButton: React.FC<StatButtonProps> = ({
  itemCount,
  to,
  ...props
}) => {
  const { t } = useTranslation("callsButton");
  return (
    <LinkBoxButton
      text={t("followUp")}
      icon={{
        color: ARBOLUS_COLORS.bColorBaseWhite,
        background: ARBOLUS_COLORS.bColorBasePurple,
        name: "call_end"
      }}
      subTitle={
        itemCount > 0
          ? t("callsDoneCount", {
              count: itemCount
            })
          : t("noCallsDone")
      }
      to={to}
      chevronColor={ARBOLUS_COLORS.bColorBasePurple}
      {...props}
    />
  );
};
