import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./ViewTranscript.module.scss";

interface ViewTranscriptProps {
  content: string | null;
  canEdit?: boolean;
  editTranscript: () => void;
  resetTranscript: () => void;
}

export const ViewTranscript: React.FC<ViewTranscriptProps> = ({
  content,
  canEdit = false,
  editTranscript,
  resetTranscript
}) => {
  const { t } = useTranslation("canopyTranscript");
  return (
    <>
      <div className={styles.header}>
        <h1 className={styles.transcriptTitle}>{t("transcript")}</h1>
        {canEdit && (
          <div className={styles.actionButtons}>
            <div
              className={styles.actionButton}
              onClick={editTranscript}
              title={t("edit")}
            >
              <i className="ciq-icon ciq-edit" />
            </div>
            <div
              className={styles.actionButton}
              onClick={resetTranscript}
              title={t("reset")}
            >
              <i className="ciq-icon ciq-reload" />
            </div>
          </div>
        )}
      </div>
      <p className={styles.transcriptText}>
        {content === "" ? t("notAvailable") : content}
      </p>
    </>
  );
};
