import { BookmarksAppState, BookmarksReducerState } from "./models/definitions";
import {
  bookmarksList,
  bookmarksListHasNextPage,
  bookmarksListIsLoading,
  bookmarksListSearchTerm
} from "./selectors/GetBookmarksListSelector";
import {
  collection,
  collectionIsLoading
} from "./selectors/GetCollectionSelector";

export const BookmarksStateSelector = (
  state: BookmarksAppState
): BookmarksReducerState => state.bookmarks;

export const BookmarksSelector = {
  collection,
  collectionIsLoading,
  bookmarksList,
  bookmarksListHasNextPage,
  bookmarksListSearchTerm,
  bookmarksListIsLoading
};
