import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";

import {
  ExpertListProject,
  PROJECT_REFERRAL_STATE,
  PROJECT_STATUS
} from "@arbolus-technologies/api";
import { PROJECT_ROUTE } from "@arbolus-technologies/routes";

import styles from "./ProjectDetails.module.scss";

interface ProjectNameProps {
  row: ExpertListProject;
}

export const ProjectName: React.FC<ProjectNameProps> = ({ row }) => {
  const {
    project: { id, name, projectState },
    status
  } = row;
  const isArchived = projectState === PROJECT_STATUS.ARCHIVE;
  const { ACCEPT, CANDIDATE, REJECT } = PROJECT_REFERRAL_STATE;

  return (
    <div className={styles.projectName}>
      <i
        className={clsx("status-dot", {
          active: !isArchived && status === ACCEPT,
          pending: !isArchived && status === CANDIDATE,
          rejected: !isArchived && status === REJECT,
          archived: isArchived
        })}
      />
      <Link
        to={PROJECT_ROUTE(id)}
        target="_blank"
        className={styles.projectName}
      >
        <div className={isArchived ? styles.archived : ""}>{name}</div>
      </Link>
    </div>
  );
};
