/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Control, Controller } from "react-hook-form";

import { SelectOption } from "@arbolus-technologies/models/common";

import { CustomSelect } from "../../components/CustomSelect/CustomSelect";

import styles from "./CustomSelectController.module.scss";

interface CustomSelectControllerProps {
  options: SelectOption[];
  name: string;
  control: Control<any>;
  noOptionsMessage: string;
  displayIcon?: boolean;
  isSearchable?: boolean;
  placeholder?: string;
  defaultValue?: SelectOption;
  error?: string;
  disabled?: boolean;
  clearValue?: boolean;
}

export const CustomSelectController: React.FC<CustomSelectControllerProps> = ({
  options,
  name,
  control,
  noOptionsMessage,
  placeholder,
  defaultValue,
  error,
  disabled,
  displayIcon,
  isSearchable,
  clearValue = false
}) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    render={({ field: { onChange, onBlur, value } }) => (
      <div className={styles.selectContainer}>
        <CustomSelect
          disabled={disabled}
          options={options}
          onSelectChange={onChange}
          defaultValue={value && { value: `${value}`, label: `${value}` }}
          noOptionsMessage={noOptionsMessage}
          displayIcon={displayIcon}
          isSearchable={isSearchable}
          placeholder={placeholder}
          onBlur={onBlur}
          isError={!!error}
          clearValue={clearValue}
        />
        {error && <div className={styles.error}>{error}</div>}
      </div>
    )}
  />
);
