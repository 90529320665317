import clsx from "clsx";
import React from "react";
import { FieldError, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Label } from "reactstrap";

import { User } from "@arbolus-technologies/api";
import { ClientUserSelector } from "@arbolus-technologies/features/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  CustomSelectController,
  CustomSwitchType,
  DatePickerController,
  Drawer,
  HR,
  LabeledSwitch,
  PhoneInputController,
  TextInput,
  TiptapEditorController
} from "@arbolus-technologies/ui/components";
import { addDays } from "@arbolus-technologies/utils";

import {
  ACTIVATE_CHAT,
  BRIEF,
  CASE_CODE,
  CONTACT_NUMBER,
  END_DATE,
  EXPERT_COUNT,
  MAIN_CONTACT,
  MANAGER_EMAIL,
  MANAGER_NAME,
  PARTNER_EMAIL,
  PARTNER_NAME,
  PROJECT_NAME,
  START_DATE,
  SimplifiedProjectEditFormValues,
  TIMEZONE
} from "../../../type";
import { DrawerTitle } from "../DrawerTitle/DrawerTitle";

import styles from "./ProjectSimplifiedForm.module.scss";

interface ProjectSimplifiedFormProps {
  clientId: string;
  editMode?: boolean;
  disableExpertCount?: boolean;
}

export const ProjectSimplifiedForm: React.FC<ProjectSimplifiedFormProps> = ({
  clientId,
  editMode = false,
  disableExpertCount = false
}) => {
  const { t } = useTranslation("projectSimplifiedForm");
  const timeZones = useSelector(CacheSelector.timezones());

  const currentDate = new Date();

  const {
    control,
    formState: { errors },
    setValue,
    watch,
    trigger
  } = useFormContext<SimplifiedProjectEditFormValues>();

  return (
    <>
      <Drawer title="Details" openByDefault disableCollapse>
        <TextInput
          control={control}
          name={PROJECT_NAME}
          label={t("projectName")}
          required
          autoFocus
          error={errors[PROJECT_NAME]}
        />
        <div className={styles.inputContainer}>
          <Label className={styles.label}>{t("brief")}</Label>
          <TiptapEditorController
            control={control}
            placeholder={t("briefPlaceholder")}
            name={BRIEF}
            error={errors[BRIEF]}
          />
        </div>
        <HR
          margin={{ top: 1, bottom: 3 }}
          color={ARBOLUS_COLORS.bColorSecondaryGreyMid}
        />
        <div className={styles.twoColumnGrid}>
          <div className={styles.inputContainer}>
            <Label className={clsx(styles.label, "required")}>
              {t("projectTimezone")}
            </Label>
            <CustomSelectController
              name={TIMEZONE}
              control={control}
              options={timeZones}
              noOptionsMessage={t("noTimezone")}
              displayIcon
              isSearchable
              placeholder={t("projectTimezonePlaceholder")}
            />
          </div>
          {!disableExpertCount && (
            <TextInput
              control={control}
              name={EXPERT_COUNT}
              label={t("expertCount")}
              required
              type="number"
              onlyInteger
              error={errors[EXPERT_COUNT]}
            />
          )}
        </div>
        <div className={styles.twoColumnGrid}>
          <div
            className={clsx(styles.inputContainer, styles.clientUserSelector)}
          >
            <Label className={clsx(styles.label, "required")}>
              {t("mainContact")}
            </Label>
            <ClientUserSelector
              control={control}
              name={MAIN_CONTACT}
              clientId={clientId}
              error={errors[MAIN_CONTACT] as FieldError}
              placeholderText={t("mainContactPlaceholder")}
              onSelect={(selected: User) => {
                setValue(CONTACT_NUMBER, selected?.phoneNumber ?? "");
                trigger(CONTACT_NUMBER);
              }}
            />
          </div>
          <div className={styles.inputContainer}>
            <Label className={styles.label}>{t("mainContactNumber")}</Label>
            <PhoneInputController
              name={CONTACT_NUMBER}
              control={control}
              error={errors[CONTACT_NUMBER]}
              inputClass={styles.phoneInput}
            />
          </div>
        </div>
        {!editMode && (
          <>
            <HR
              margin={{ top: 0, bottom: 3 }}
              color={ARBOLUS_COLORS.bColorSecondaryGreyMid}
            />
            <p className={styles.chatToggleTitle}>{t("chatToggleTitle")}</p>
            <LabeledSwitch
              type={CustomSwitchType.ON_OFF}
              rightTabTitle={t("chatToggleLabel")}
              onChange={(event) =>
                setValue(ACTIVATE_CHAT, event.target.checked)
              }
              checked={watch(ACTIVATE_CHAT)}
            />
            <p className={styles.chatToggleDescription}>
              {t("chatToggleDescription")}
            </p>
          </>
        )}
      </Drawer>
      <Drawer
        titleChildren={() => (
          <DrawerTitle title={t("additionalDetailsFormTitle")} />
        )}
      >
        <div className={styles.additionalDetails}>
          <div className={styles.inputContainer}>
            <Label className={styles.label}>{t("startDate")}</Label>
            <DatePickerController
              name={START_DATE}
              control={control}
              placeholder={t("startDatePlaceholder")}
              minDate={currentDate}
            />
          </div>
          <div className={styles.inputContainer}>
            <Label className={styles.label}>{t("endDate")}</Label>
            <DatePickerController
              name={END_DATE}
              control={control}
              isClearable
              placeholder={t("endDatePlaceholder")}
              minDate={addDays(watch(START_DATE), 1)}
            />
          </div>
          <div className={styles.caseCode}>
            <TextInput
              control={control}
              name={CASE_CODE}
              label={t("caseCode")}
              placeholder={t("caseCodePlaceholder")}
              error={errors[CASE_CODE]}
            />
          </div>
        </div>
      </Drawer>
      <Drawer
        titleChildren={() => <DrawerTitle title={t("ownershipFormTitle")} />}
      >
        <div className={styles.twoColumnGrid}>
          <TextInput
            control={control}
            name={MANAGER_NAME}
            label={t("managerName")}
            placeholder={t("managerNamePlaceholder")}
            error={errors[MANAGER_NAME]}
          />
          <TextInput
            control={control}
            name={MANAGER_EMAIL}
            label={t("managerEmail")}
            placeholder={t("managerEmailPlaceholder")}
            error={errors[MANAGER_EMAIL]}
          />
          <TextInput
            control={control}
            name={PARTNER_NAME}
            label={t("partnerName")}
            placeholder={t("partnerNamePlaceholder")}
            error={errors[PARTNER_NAME]}
          />
          <TextInput
            control={control}
            name={PARTNER_EMAIL}
            label={t("partnerEmail")}
            placeholder={t("partnerEmailPlaceholder")}
            error={errors[PARTNER_EMAIL]}
          />
        </div>
      </Drawer>
    </>
  );
};
