import { Button } from "arbolus-ui-components";
import { Field, Form, Formik } from "formik";
import { History } from "history";
import React from "react";
import {
  IWithGoogleReCaptchaProps,
  withGoogleReCaptcha
} from "react-google-recaptcha-v3";
import { WithTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Subscription } from "rxjs";

import { ToasterService } from "@arbolus-technologies/api";
import {
  AuthPageBase,
  AuthPageHeader
} from "@arbolus-technologies/features/common";

import { PublicRouteConstants } from "../../../../constants";
import { RECAPTCHA_ACTIONS } from "../../../../constants/auth";
import { CIQError, ErrorResponse } from "../../../../models/api";
import { UserService } from "../../../../services";
import { CIQFormInput } from "../../../app/components";
import { Alert, AsyncAwareSubmitButton } from "../../components";
import { ForgotPasswordValidationSchema } from "./ForgotPasswordSchema";

import styles from "./ForgotPasswordPage.module.scss";

const notification = new ToasterService();

interface ForgotPasswordProps
  extends WithTranslation,
    IWithGoogleReCaptchaProps {
  history: History;
}

interface ForgotPasswordState {
  emailSent: boolean;
  isLoading: boolean;
  resetEmailRequestError?: ErrorResponse<CIQError>;
}

interface ForgotPasswordFormValues {
  email: string;
}

class ForgotPasswordPage extends React.Component<
  ForgotPasswordProps,
  ForgotPasswordState
> {
  constructor(props: ForgotPasswordProps) {
    super(props);
    this.state = {
      emailSent: false,
      isLoading: false
    };
  }

  componentDidMount(): void {
    const { t } = this.props;
    document.title = t("forgotPassword");
  }

  componentWillUnmount(): void {
    this.resetPasswordRequestEmail?.unsubscribe();
  }

  private resetPasswordRequestEmail?: Subscription;

  handleResetClicked = async ({
    email
  }: ForgotPasswordFormValues): Promise<void> => {
    const { googleReCaptchaProps, t } = this.props;
    const { executeRecaptcha } = googleReCaptchaProps;

    this.setState({
      resetEmailRequestError: undefined,
      isLoading: true
    });

    try {
      const recaptchaToken = await executeRecaptcha?.(
        RECAPTCHA_ACTIONS.FORGOT_PASSWORD
      );
      this.resetPasswordRequestEmail = UserService.resetPasswordRequestEmail({
        email,
        recaptchaToken: recaptchaToken!
      }).subscribe(
        () => {
          this.setState({
            isLoading: false,
            emailSent: true
          });
        },
        (error: ErrorResponse<CIQError>) => {
          this.setState({
            resetEmailRequestError: error,
            isLoading: false,
            emailSent: false
          });
        }
      );
    } catch (err) {
      notification.showError(t("errorOccurred"));
      this.setState({
        isLoading: false,
        emailSent: false
      });
    }
  };

  handleReturnToLoginClick = (): void => {
    const { history } = this.props;
    history.push(PublicRouteConstants.LOGIN);
  };

  renderForm = (): JSX.Element => {
    const { isLoading, emailSent } = this.state;
    const { t } = this.props;
    return (
      <Form className={styles.form}>
        {!emailSent && (
          <div>
            <Field
              disabled={isLoading}
              name="email"
              placeholder={t("email")}
              component={CIQFormInput}
            />
          </div>
        )}

        <div className={styles.footer}>
          <div>
            {!emailSent && (
              <Link to={PublicRouteConstants.LOGIN}>{t("signIn")}</Link>
            )}
          </div>
          {emailSent ? (
            <Button
              onClick={this.handleReturnToLoginClick}
              text={t("signIn")}
            />
          ) : (
            <AsyncAwareSubmitButton
              isLoading={isLoading}
              text={t("resetButtonText")}
            />
          )}
        </div>
      </Form>
    );
  };

  render(): JSX.Element {
    const { t } = this.props;
    const { emailSent, resetEmailRequestError } = this.state;
    return (
      <AuthPageBase>
        <div className={styles.forgotPasswordPage}>
          <AuthPageHeader title={emailSent ? t("done") : t("resetPassword")} />

          {emailSent && <p>{t("emailSentMessage")}</p>}
          <p>{emailSent ? t("spamFolderMessage") : t("instruction")}</p>

          {resetEmailRequestError && (
            <Alert feedback={resetEmailRequestError} isSuccess={false} />
          )}
          <Formik<ForgotPasswordFormValues>
            initialValues={{
              email: ""
            }}
            validationSchema={ForgotPasswordValidationSchema}
            onSubmit={this.handleResetClicked}
            render={this.renderForm}
          />
        </div>
      </AuthPageBase>
    );
  }
}

const translatedPage = withTranslation("forgotPassword")(ForgotPasswordPage);

export default withGoogleReCaptcha(translatedPage);
