import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { of } from "rxjs";

import {
  ApiPaginatedRequest,
  ApiPaginatedResponse
} from "@arbolus-technologies/api";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

import {
  SelectController,
  TypeaheadSelectorControllerProps
} from "../SelectTypeahead/SelectController";

interface DisplayCountry {
  name: string;
}

export const CountrySelector = ({
  placeholderText,
  ...props
}: TypeaheadSelectorControllerProps<DisplayCountry>): React.ReactElement => {
  const { t } = useTranslation();
  const placeholder =
    placeholderText ?? t("common:countrySelection:findCountry");
  const countries = useSelector(CacheSelector.metadataCountries());
  const renderer = ({ name }: DisplayCountry): JSX.Element => (
    <span>{name}</span>
  );

  const getItems = (pagination: ApiPaginatedRequest) => {
    const filtered = pagination.searchTerm
      ? countries.filter((country) =>
          country.name
            .toLowerCase()
            .includes(pagination.searchTerm!.toLowerCase())
        )
      : countries;

    const items = filtered.slice(pagination.offset, pagination.limit);
    return of({
      items: items,
      pagination: {
        offset: pagination.offset,
        limit: pagination.limit,
        count: filtered.length
      }
    } as ApiPaginatedResponse<DisplayCountry>);
  };

  return (
    <SelectController
      {...props}
      placeholderText={placeholder}
      promptText={t("common:countrySelection:findingCountries")}
      searchText={t("common:countrySelection:findingCountries")}
      paginationText={t("common:countrySelection:loadMore")}
      orderBy="name"
      renderer={renderer}
      getLabelKey={(country) => country.name}
      getItems={getItems}
    />
  );
};
