import React from "react";
import { useTranslation } from "react-i18next";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { LinkBoxButton } from "../../BoxButton/LinkBoxButton";
import { StatButtonProps } from "./StatButtonProps";

export const ProjectBriefButton: React.FC<
  Omit<StatButtonProps, "itemCount">
> = ({ to, ...props }) => {
  const { t } = useTranslation("projectBriefButton");

  return (
    <LinkBoxButton
      text={t("projectBrief")}
      to={to}
      icon={{
        color: ARBOLUS_COLORS.bColorBaseWhite,
        background: ARBOLUS_COLORS.bColorBasePurple,
        name: "sticky_note_2"
      }}
      subTitle={t("takeALook")}
      chevronColor={ARBOLUS_COLORS.bColorBasePurple}
      {...props}
    />
  );
};
