import i18next from "i18next";
import * as Yup from "yup";

import {
  CompanyEnquiryConstraints,
  UserConstraints
} from "../../../../../constants/validation";
import { UtilsService } from "../../../../../services";

export const CompanyEnquirySchema = Yup.object().shape({
  company: Yup.string()
    .required(i18next.t("register:companyNameRequired"))
    .max(
      CompanyEnquiryConstraints.MAX_COMPANY_NAME_LENGTH,
      i18next.t("register:companyNameMaxLength", {
        length: CompanyEnquiryConstraints.MAX_COMPANY_NAME_LENGTH
      })
    ),
  name: Yup.string()
    .required(i18next.t("register:nameRequired"))
    .max(
      CompanyEnquiryConstraints.ENQUIRER_NAME_MAX_LENGTH,
      i18next.t("register:nameMaxLength", {
        length: CompanyEnquiryConstraints.ENQUIRER_NAME_MAX_LENGTH
      })
    ),
  email: Yup.string()
    .required(i18next.t("register:emailRequired"))
    .email(i18next.t("register:emailInvalid"))
    .max(
      UserConstraints.MAX_EMAIL_LENGTH,
      i18next.t("register:maxEmailLength", {
        length: UserConstraints.MAX_EMAIL_LENGTH
      })
    ),
  phone: Yup.string()
    .test("phone-valid", i18next.t("register:phoneInvalid"), (val: string) =>
      val ? UtilsService.validateContactNumber(`+${val}`) : true
    )
    .required(i18next.t("register:phoneNumberRequired")),
  message: Yup.string()
    .required(i18next.t("register:messageRequired"))
    .max(
      CompanyEnquiryConstraints.ENQUIRY_MESSAGE_MAX_LENGTH,
      i18next.t("register:nameMaxLength", {
        length: CompanyEnquiryConstraints.ENQUIRY_MESSAGE_MAX_LENGTH
      })
    )
});
