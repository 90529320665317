import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";

import { IncidentListInfoSection } from "./Components/IncidentListInfoSection/IncidentListInfoSection";

import styles from "./IncidentInfoContainer.module.scss";

// TO_REPLACE

interface IncidentInfoContainerProps {
  nextStepOnClick: () => void;
}
export const IncidentInfoContainer: React.FC<IncidentInfoContainerProps> = ({
  nextStepOnClick
}) => {
  const { t } = useTranslation("incidentsInfo");

  return (
    <>
      <IncidentListInfoSection
        title={t("introSectionTitle")}
        topText={t("introSectionTopText")}
        bulletPoints={[
          {
            title: t("introSectionItem1Title"),
            text: t("introSectionItem1Text")
          }
        ]}
      />
      <IncidentListInfoSection
        title={t("prioritySectionTitle")}
        topText={t("prioritySectionTopText")}
        bottomText={t("prioritySectionBottomText")}
        bulletPoints={[
          {
            title: t("prioritySectionItem1Title"),
            text: t("prioritySectionItem1Text")
          },
          {
            title: t("prioritySectionItem2Title"),
            text: t("prioritySectionItem2Text")
          },
          {
            title: t("prioritySectionItem3Title"),
            text: t("prioritySectionItem3Text")
          },
          {
            title: t("prioritySectionItem4Title"),
            text: t("prioritySectionItem4Text")
          },
          {
            title: t("prioritySectionItem5Title"),
            text: t("prioritySectionItem6Text")
          }
        ]}
      />
      <div className={styles.nextButton}>
        <Button text={t("next")} onClick={nextStepOnClick} />
      </div>
    </>
  );
};
