import { PageStep } from "@arbolus-technologies/models/common";

import { Availability } from "../Modules/Availability/Availability";
import { CompanyValidation } from "../Modules/CompanyValidation/CompanyValidation";
import { Compliance } from "../Modules/Compliance/Compliance";
import { EngagementAgreement } from "../Modules/EngagementAgreement/EngagementAgreement";
import { ScreeningQuestions } from "../Modules/ScreeningQuestions/ScreeningQuestions";

export enum STEPS {
  ENGAGEMENT_AGREEMENT = "engagementAgreement",
  COMPANY_VALIDATION = "companyValidation",
  COMPLIANCE = "compliance",
  SCREENING_QUESTIONS = "screeningQuestions",
  AVAILABILITY = "availability"
}

const APPLICATION_STEPS: PageStep[] = [
  {
    name: STEPS.ENGAGEMENT_AGREEMENT,
    StepComponent: EngagementAgreement
  },
  {
    name: STEPS.COMPANY_VALIDATION,
    StepComponent: CompanyValidation
  },
  {
    name: STEPS.COMPLIANCE,
    StepComponent: Compliance
  },
  {
    name: STEPS.SCREENING_QUESTIONS,
    StepComponent: ScreeningQuestions
  },
  {
    name: STEPS.AVAILABILITY,
    StepComponent: Availability
  }
];

export const getCanopySteps = (): PageStep[] => APPLICATION_STEPS.slice(0, 3);

export const getProjectSteps = (): PageStep[] => APPLICATION_STEPS;

export enum PROJECT_COMPLIANCE_QUESTION_TYPE {
  YES_NO = "YesNo",
  YES_NO_TEXT = "YesNoText"
}

export enum COMPLIANCE_ANSWER_OPTIONS {
  YES = "Yes",
  NO = "No"
}
