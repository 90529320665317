import i18next from "i18next";

import { SORT_DIRECTION, SortCriteria } from "@arbolus-technologies/api";
import { SelectOption } from "@arbolus-technologies/models/common";

import { APP_USER_ROLES } from "./app";

export const USER_SORT_CRITERIA = {
  NAME: {
    name: "sortByName",
    value: "firstName",
    direction: SORT_DIRECTION.ASCENDING
  } as SortCriteria,
  ACCESS_LEVEL: {
    name: "sortByAccessLevel",
    value: "accessLevel",
    direction: SORT_DIRECTION.ASCENDING
  } as SortCriteria
};

export const USER_ACCESS_LEVEL = {
  USER: {
    label: i18next.t("newUserPage:user"),
    customLabel: i18next.t("newUserPage:user"),
    value: APP_USER_ROLES.client
  } as SelectOption,
  ADMIN: {
    label: i18next.t("newUserPage:admin"),
    customLabel: i18next.t("newUserPage:admin"),
    value: APP_USER_ROLES.admin
  } as SelectOption
};

export enum USER_INVITATION_TYPE {
  PROJECT = "Project",
  NETWORK = "Network",
  CLIENT_TEAM = "ClientTeam"
}
