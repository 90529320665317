import { Button } from "arbolus-ui-components";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";

import { useCookie } from "@arbolus-technologies/utils";

import styles from "./InfoCookieModal.module.scss";

interface InfoCookieModalProps {
  children: React.ReactNode;
  cookie: string;
  buttonText: string;
  buttonEndIcon?: string;
  containerStyle?: string | string[];
}
export const InfoCookieModal: React.FC<InfoCookieModalProps> = ({
  children,
  cookie,
  buttonText,
  buttonEndIcon,
  containerStyle
}) => {
  const { setCookie, isCookieStored } = useCookie(cookie);
  const [toggleModal, setToggleModal] = useState(false);

  useEffect(() => {
    if (!isCookieStored()) {
      setToggleModal(true);
    }
  }, [isCookieStored]);

  const handleDismissModal = () => {
    setToggleModal(false);
  };

  const handleCtaButtonClicked = () => {
    setCookie();
    setToggleModal(false);
  };

  return (
    <Modal
      isOpen={toggleModal}
      toggle={handleDismissModal}
      className={clsx(styles.modalBody, containerStyle)}
    >
      <div className={styles.modalContainer}>
        <div className={styles.childrenContainer}>{children}</div>

        <div className={styles.btnContainer}>
          <Button
            onClick={handleCtaButtonClicked}
            text={buttonText}
            endIcon={buttonEndIcon}
          />
        </div>
      </div>
    </Modal>
  );
};
