import React from "react";

import {
  InternalArbolusModal,
  InternalArbolusModalProps
} from "./InternalArbolusModal";

export const ArbolusModal: React.FC<InternalArbolusModalProps> = (props) => (
  <InternalArbolusModal {...props} />
);
