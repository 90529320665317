import { Icon } from "arbolus-ui-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { ArbolusModal } from "@arbolus-technologies/ui/components";

interface CreateEditQuestionHeaderProps {
  disabled: boolean;
  onDelete: () => void;
}

export const DeleteQuestion: React.FC<CreateEditQuestionHeaderProps> = ({
  disabled,
  onDelete
}) => {
  const { t } = useTranslation("canopyBuilder");

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleDeleteQuestion = () => {
    onDelete();
    setOpenDeleteModal(false);
  };

  return (
    <>
      <Icon
        name="delete"
        fontSize="24px"
        color={ARBOLUS_COLORS.bColorBaseOrange}
        onClick={() => setOpenDeleteModal(true)}
        disabled={disabled}
      />
      <ArbolusModal
        title={t("deleteQuestionTitle")}
        subtitle={t("deleteQuestionSubtitle")}
        toggle={() => setOpenDeleteModal((prev) => !prev)}
        isOpen={openDeleteModal}
        leftButton={{
          onClick: () => setOpenDeleteModal(false),
          type: "tertiary",
          text: t("cancel")
        }}
        rightButton={{
          onClick: handleDeleteQuestion,
          type: "rejection",
          text: t("confirmDelete")
        }}
      />
    </>
  );
};
