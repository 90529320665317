/* eslint-disable import/extensions */
import i18next from "i18next";
import { Epic } from "redux-observable";
import { of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { isOfType } from "typesafe-actions";

import { ToasterService } from "@arbolus-technologies/api";

import { InboxStoreActions } from "..";
import { INBOX_NOTIFICATION_CATEGORY } from "../../../../constants/notifications";
import { UserService } from "../../../../services";
import { AppAction } from "../../../../store/actions";
import { AppState } from "../../../../store/reducers";
import {
  GET_NOTIFICATION_COUNTS,
  MARK_NOTIFICATION_AS_READ
} from "../actions/InboxActionTypes";

const notification = new ToasterService();

const getNotificationDataEpic: Epic<AppAction, AppAction, AppState> = (
  action$
) =>
  action$.pipe(
    filter(isOfType(GET_NOTIFICATION_COUNTS)),
    switchMap(() =>
      UserService.getNotificationCounts().pipe(
        map((notifications) => {
          const { notificationCounts } = notifications;
          const notificationMap = new Map<
            INBOX_NOTIFICATION_CATEGORY,
            number
          >();
          Object.keys(notificationCounts).forEach((key) => {
            notificationMap.set(
              key as INBOX_NOTIFICATION_CATEGORY,
              notificationCounts[key]
            );
          });

          return InboxStoreActions.getNotificationCountsSuccess(
            notificationMap
          );
        }),
        catchError((error) => {
          notification.showError(i18next.t("restService:somethingWrong"));
          return of(InboxStoreActions.getNotificationCountsFailure(error));
        })
      )
    )
  );

const markNotificationAsReadEpic: Epic<AppAction, AppAction, AppState> = (
  action$
) =>
  action$.pipe(
    filter(isOfType(MARK_NOTIFICATION_AS_READ)),
    switchMap(({ payload: { notificationId, notificationCategory } }) =>
      UserService.markNotificationsAsRead([notificationId]).pipe(
        map(() =>
          InboxStoreActions.markNotificationsAsReadSuccess([
            notificationCategory
          ])
        ),
        catchError((error) => {
          notification.showError(i18next.t("restService:somethingWrong"));
          return of(InboxStoreActions.markNotificationsAsReadFailure(error));
        })
      )
    )
  );

const inboxEpics = [getNotificationDataEpic, markNotificationAsReadEpic];

export default inboxEpics;
