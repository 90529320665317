import { Icon } from "arbolus-ui-components";
import "moment-timezone";
import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  CIQError,
  ErrorResponse,
  ExpertService,
  PROJECT_REFERRAL_STATE,
  ReferralAvailability,
  Slot,
  ToasterService
} from "@arbolus-technologies/api";
import {
  APP_TRACKING_ROUTES,
  DO_NOT_CONTACT_STATUS,
  REFERRAL_COMPLIANCE_STATE,
  REFERRAL_SUB_STATE,
  SelectOption
} from "@arbolus-technologies/models/common";
import { REFERRAL_STATES } from "@arbolus-technologies/models/expert";
import {
  AvailableSlot,
  DEFAULT_SLOTS_NUMBER,
  ExpertAvailabilityActions,
  MAX_SLOTS_NUMBER
} from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ProjectExpertsStoreActions } from "@arbolus-technologies/stores/project-experts-store";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  ActionsAvailability,
  ArbolusModal,
  CustomSelect,
  InfoBox,
  LoadMore,
  SelectedSlot,
  SlotsList
} from "@arbolus-technologies/ui/components";
import {
  SIDE_PANEL_SIZE,
  useFormatSlotsValues
} from "@arbolus-technologies/utils";

import { MixPanelActions, MixPanelEventNames } from "../../Mixpanel/enums";
import { useMixpanel } from "../../Mixpanel/useMixpanel";
import { ExpertProfile } from "../../Panels/ExpertProfileSidePanel/ExpertProfile";
import { InternalSlidePanel } from "../../Panels/InternalSlidePanel/InternalSlidePanel";

import styles from "./ExpertAvailability.module.scss";

const bainId = process.env.NX_PUBLIC_BAIN_ID;

interface ExpertAvailabilityProps {
  angleBadge?: ReactNode;
  expertAvailabilitySlots: Slot[];
  createEvent: (selectedSlot: AvailableSlot, timezone?: string) => void;
  complianceStatus: REFERRAL_COMPLIANCE_STATE | null;
  expertStatus: PROJECT_REFERRAL_STATE | REFERRAL_STATES;
  onCallScheduled?: () => void;
  showHeader?: boolean;
  timezoneDetails: {
    timezones?: SelectOption[];
    projectTimezone?: string;
    expertTimezone?: string;
  };
  hasMinimumCallTimeRate?: boolean;
  clientId?: string;
  applicationStatus?: REFERRAL_SUB_STATE;
  projectId: string;
  expertId: string;
  requestSlotsForBain?: () => void;
  referralAvailability: ReferralAvailability;
  onPanelClose?: () => void;
  referralId: string;
  doNotContactStatus?: DO_NOT_CONTACT_STATUS;
}

export const ExpertAvailability: React.FC<ExpertAvailabilityProps> = ({
  angleBadge,
  expertAvailabilitySlots,
  createEvent,
  complianceStatus,
  expertStatus,
  onCallScheduled,
  timezoneDetails,
  hasMinimumCallTimeRate = false,
  showHeader = true,
  clientId = "",
  applicationStatus,
  projectId,
  expertId,
  requestSlotsForBain,
  referralAvailability,
  onPanelClose,
  referralId,
  doNotContactStatus
}: ExpertAvailabilityProps) => {
  const displayedSliceSlots = DEFAULT_SLOTS_NUMBER;
  const { t } = useTranslation("expertAvailabilityTab");
  const notificationService = new ToasterService();
  const { trackEvent } = useMixpanel();
  const dispatch = useDispatch();

  const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { timezones, projectTimezone } = timezoneDetails;

  const isAdmin = useSelector(CacheSelector.isAdmin());
  const [availableSlots, setAvailableSlots] = useState<AvailableSlot[]>([]);
  const [selectedTimezone, setSelectedTimezone] =
    useState<string>(systemTimezone);
  const [selectedSlot, setSelectedSlot] = useState<AvailableSlot>();
  const [sliceSlots, setSliceSlots] = useState<number>(displayedSliceSlots);
  const [isCandidateModalOpen, setIsCandidateModalOpen] = useState(false);
  const [isExpertProfileOpen, setIsExpertProfileOpen] = useState(false);
  const [requestTimeSlotsAlreadyClicked, setRequestTimeSlotsAlreadyClicked] =
    useState(false);
  const [actionClicked, setActionClicked] =
    useState<ExpertAvailabilityActions>();

  const timeZoneDisplayText = timezones?.find(
    (timezone) => timezone.value === selectedTimezone
  )?.label;

  const isCompliancePending =
    complianceStatus === REFERRAL_COMPLIANCE_STATE.REJECT ||
    complianceStatus === REFERRAL_COMPLIANCE_STATE.PENDING;

  const isExpertRejectedOrShortlisted =
    expertStatus === PROJECT_REFERRAL_STATE.REJECT ||
    expertStatus === PROJECT_REFERRAL_STATE.SHORTLIST;
  const isExpertUnapproved = expertStatus !== PROJECT_REFERRAL_STATE.ACCEPT;
  const isBainClient = clientId === bainId;
  const isBainAndUnapprovedExpert =
    isAdmin && isBainClient && isExpertUnapproved;

  const { getFormattedSlotsValues, sortFormattedSlotsValues } =
    useFormatSlotsValues(expertAvailabilitySlots, selectedTimezone);

  useEffect(() => {
    const slotsFormatted = getFormattedSlotsValues();
    const sortedSlots = sortFormattedSlotsValues(slotsFormatted);
    const selectedSlot = sortedSlots.find((slot) => slot.slotSelected);
    setAvailableSlots(sortedSlots);
    setSelectedSlot(selectedSlot);
  }, [
    getFormattedSlotsValues,
    sortFormattedSlotsValues,
    expertAvailabilitySlots
  ]);

  const handleSlotSelected = (slotId: number) => {
    const updatedSlots = availableSlots.map((slot) => ({
      ...slot,
      slotSelected: slot.slotId === slotId ? !slot.slotSelected : false
    }));
    const selectedSlot = updatedSlots.find((slot) => slot.slotSelected);
    setAvailableSlots(updatedSlots);
    setSelectedSlot(selectedSlot);
  };

  const handleCreateEvent = () => {
    if (!selectedSlot) {
      return;
    }
    if (applicationStatus !== REFERRAL_SUB_STATE.ACCEPT) {
      dispatch(
        ProjectExpertsStoreActions.updateReferralStatus(projectId, referralId, {
          review: REFERRAL_SUB_STATE.ACCEPT
        })
      );
    }
    createEvent(selectedSlot, projectTimezone);
    trackEvent?.(MixPanelEventNames.CreateEvent, {
      action: MixPanelActions.Initialized,
      from: APP_TRACKING_ROUTES.EXPERT_AVAILABILITY
    });
  };

  const handleArrangeCallClicked = (): void => {
    setActionClicked(ExpertAvailabilityActions.ArrangeCall);
    if (expertStatus === REFERRAL_STATES.CANDIDATE) {
      setIsCandidateModalOpen(true);
    } else {
      handleCreateEvent();
    }
  };

  const requestTimeSlots = () => {
    setRequestTimeSlotsAlreadyClicked(true);
    if (isBainClient && isExpertUnapproved && !isAdmin && requestSlotsForBain) {
      requestSlotsForBain();
    } else {
      // This service approves the expert directly
      ExpertService.requestExpertAvailability(projectId, expertId).subscribe(
        () => {
          onPanelClose?.();
          notificationService.showSuccess(t("requestTimeSlotSuccessMessage"));
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showError(error.message);
        }
      );
    }
  };

  const loadMore = (): void => {
    setSliceSlots(MAX_SLOTS_NUMBER);
  };

  const isDnc = doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC;
  const disableTimeSlots =
    isDnc ||
    isCompliancePending ||
    isExpertRejectedOrShortlisted ||
    applicationStatus === REFERRAL_SUB_STATE.PENDING;

  const hasAvailabilitySlotsExpired = expertAvailabilitySlots
    ? expertAvailabilitySlots.length === 0
    : false;

  const isArrangeCallButtonDisabled =
    isDnc ||
    !selectedSlot ||
    disableTimeSlots ||
    hasAvailabilitySlotsExpired ||
    isBainAndUnapprovedExpert;

  const {
    availabilityRequesterUser,
    availabilityRequiredDate,
    isRequiredMoreAvailabilitySlotsAllowed,
    waitingHours
  } = referralAvailability;

  return (
    <>
      <ArbolusModal
        title={t("expertCandidateTitle")}
        subtitle={t("expertCandidateSubtitleArrangeCall")}
        toggle={() => setIsCandidateModalOpen(false)}
        isOpen={isCandidateModalOpen}
        leftButton={{
          text: t("review"),
          type: "secondary",
          onClick: () => {
            setIsCandidateModalOpen(false);
            setIsExpertProfileOpen(true);
          }
        }}
        rightButton={{
          text: t(
            actionClicked === ExpertAvailabilityActions.ArrangeCall
              ? "approve"
              : "request"
          ),
          type: "confirmation",
          onClick: () => {
            setIsCandidateModalOpen(false);
            if (actionClicked === ExpertAvailabilityActions.ArrangeCall) {
              handleCreateEvent();
            }
            if (actionClicked === ExpertAvailabilityActions.RequestTimeSlots) {
              requestTimeSlots();
            }
          }
        }}
      />
      <InternalSlidePanel
        width={SIDE_PANEL_SIZE._720}
        title={t("expertProfile")}
        closeButtonDirection="right"
        customCloseRequest={() => setIsExpertProfileOpen(false)}
        isOpen={isExpertProfileOpen}
      >
        <ExpertProfile expertId={expertId} />
      </InternalSlidePanel>
      <div className={styles.expertAvailabilityContainer}>
        {angleBadge && (
          <>
            <div className={styles.badgeContainer}>{angleBadge}</div>
            <div className={styles.divider} />
          </>
        )}
        {showHeader && (
          <div className={styles.headerContainer}>
            <h2>{t("availability")}</h2>
          </div>
        )}
        {!hasAvailabilitySlotsExpired && (
          <p className={styles.content}>{t("availabilityText")}</p>
        )}
        {isCompliancePending && !hasAvailabilitySlotsExpired && (
          <div className={styles.infoBoxWrapper}>
            <InfoBox
              title={t("complianceCheckPending")}
              description={t("complianceNotEvaluated")}
              infoBoxType="warning"
              iconName="error_outline"
            />
          </div>
        )}
        {hasAvailabilitySlotsExpired && (
          <div className={styles.infoBoxWrapper}>
            <InfoBox
              title={t("expertAvailabilityExpired")}
              description={t("expiredTimeSlots")}
              infoBoxType="warning"
              iconName="error_outline"
            />
          </div>
        )}
        {!hasAvailabilitySlotsExpired && timezones && (
          <div>
            <p className={styles.dropdownTitle}>{t("timeDisplayedIn")}</p>
            <CustomSelect
              options={timezones}
              defaultValue={timezones.find(
                (timezone) => timezone.value === systemTimezone
              )}
              onSelectChange={(timezone) => setSelectedTimezone(timezone)}
              noOptionsMessage={t("noTimezonesOptions")}
              placeholder={t("notSetPlaceholder")}
              classNamePrefix="ciqs-select"
              ignoreCustomLabel
              customStyle={styles.customSelect}
              isSearchable
            />
          </div>
        )}
        <SlotsList
          availableSlots={availableSlots}
          sliceSlots={sliceSlots}
          handleSlotSelected={handleSlotSelected}
          handleCallScheduled={onCallScheduled}
          disableTimeSlots={disableTimeSlots}
        />
        {sliceSlots === displayedSliceSlots &&
          availableSlots.length > DEFAULT_SLOTS_NUMBER && (
            <LoadMore loadMore={loadMore} />
          )}
        <SelectedSlot
          isAdmin={isAdmin}
          selectedSlot={selectedSlot}
          timezoneDisplayTxt={timeZoneDisplayText}
        />
        <ActionsAvailability
          handleArrangeCallClicked={handleArrangeCallClicked}
          handleRequestTimeSlots={requestTimeSlots}
          isArrangeCallButtonDisabled={isArrangeCallButtonDisabled}
          isRequestAdditionalTimeSlotsDisabled={
            isDnc || disableTimeSlots || isBainAndUnapprovedExpert
          }
          availabilityRequiredDate={availabilityRequiredDate}
          availabilityRequesterName={`${availabilityRequesterUser?.firstName} ${availabilityRequesterUser?.lastName}`}
          isRequiredMoreAvailabilitySlotsAllowed={
            isRequiredMoreAvailabilitySlotsAllowed
          }
          waitingHours={waitingHours}
          isRequestTimeSlotsAlreadyClicked={requestTimeSlotsAlreadyClicked}
        />
        {hasMinimumCallTimeRate && (
          <div className={styles.warningWrapper}>
            <Icon
              name="info"
              fontSize="16px"
              color={ARBOLUS_COLORS.bColorBasePurple}
            />
            <span className={styles.rateChargedWarning}>
              {t("rateChargedWarning")}
            </span>
          </div>
        )}
      </div>
    </>
  );
};
