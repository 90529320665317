import { useSelector } from "react-redux";

import { ChatUserRole } from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

export function useChatRole(): {
  isAdmin: boolean;
  isExpert: boolean;
  isClient: boolean;
  chatSenderRole: ChatUserRole;
} {
  const isAdmin = useSelector(CacheSelector.isAdmin());
  const isExpert = useSelector(CacheSelector.isCurrentUserExpertSelector());
  const isClient = !isAdmin && !isExpert;

  let chatSenderRole = ChatUserRole.Client;
  if (isAdmin) {
    chatSenderRole = ChatUserRole.Admin;
  }
  if (isExpert) {
    chatSenderRole = ChatUserRole.Expert;
  }

  return { isAdmin, isExpert, isClient, chatSenderRole };
}
