import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Media from "react-media";
import { Subscription } from "rxjs";

import { ToasterService } from "@arbolus-technologies/api";
import { Loader, SearchBar } from "@arbolus-technologies/ui/components";

import { MAX_PAGE_SIZE } from "../../../../../constants/api";
import { SEARCH_DEBOUNCE_TIMEOUT } from "../../../../../constants/timer";
import {
  APP_DEVICE_MEDIA_QUERIES,
  UI_INVITE_GUESTS
} from "../../../../../constants/ui";
import {
  GUEST_INVITE_MAX_COUNT,
  MINIMUM_SEARCH_TERM_LENGTH,
  UserConstraints
} from "../../../../../constants/validation";
import {
  ContentPanelContextConsumer,
  ContentPanelContextProps
} from "../../../../../contexts/contentPanel/ContentPanelContext";
import { CIQError, ErrorResponse } from "../../../../../models/api";
import { EventGuest } from "../../../../../models/event";
import { User } from "../../../../../models/user";
import { EventService, UtilsService } from "../../../../../services";
import {
  CIQAvatar,
  CIQInfiniteScroll,
  CIQNoResults
} from "../../../../app/components";

const notification = new ToasterService();

interface InviteGuestsPanelProps {
  projectId: string;
  invitedEmails: string[];
  onGuestAdded: (guest: EventGuest, addedFromEmail?: boolean) => void;
  closePanel: () => void;
}

interface InviteGuestsPanelState {
  isLoading: boolean;
  isMore: boolean;
  projectMembers: User[];
  guestSearchQuery: string;
}

type InviteGuestsPanelIntersectProps = InviteGuestsPanelProps & WithTranslation;

class InviteGuestsPanel extends React.Component<
  InviteGuestsPanelIntersectProps,
  InviteGuestsPanelState
> {
  constructor(props: InviteGuestsPanelIntersectProps) {
    super(props);
    this.state = {
      isLoading: false,
      isMore: false,
      projectMembers: [],
      guestSearchQuery: ""
    };

    this.invitedEmails = props.invitedEmails;
  }

  componentWillUnmount(): void {
    this.eventCandidatesSubscription?.unsubscribe();
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
  }

  private eventCandidatesSubscription: Subscription | undefined;

  private nextOffset = 0;

  private invitedEmails: string[] = [];

  private initialElementLimit: number = MAX_PAGE_SIZE;

  private debounceTimeout: number | undefined;

  fetchEventCandidates = (): void => {
    const { projectId, invitedEmails } = this.props;
    const { projectMembers, guestSearchQuery } = this.state;

    let limit = projectMembers.length
      ? MAX_PAGE_SIZE
      : this.initialElementLimit;

    limit += invitedEmails.length;

    this.setState({ isLoading: true }, () => {
      this.eventCandidatesSubscription = EventService.getEventCandidates(
        projectId,
        this.nextOffset,
        limit,
        guestSearchQuery
      ).subscribe(
        ({ items, pagination }) => {
          this.nextOffset += items.length;

          const filteredInvitees = items.filter(
            (m) => !this.invitedEmails.includes(m.email)
          );

          const nextGuests = projectMembers.concat(filteredInvitees);
          this.setState({
            projectMembers: nextGuests,
            isMore: this.nextOffset < pagination.count,
            isLoading: false
          });
        },
        (error: ErrorResponse<CIQError>) => {
          notification.showError(error.message);
          this.setState({ isLoading: false });
        }
      );
    });
  };

  debouncedFetch = (isDelayed: boolean): void => {
    if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
    this.eventCandidatesSubscription?.unsubscribe();
    this.debounceTimeout = setTimeout(
      () => {
        this.setState(
          {
            projectMembers: []
          },
          this.fetchEventCandidates
        );
      },
      isDelayed ? SEARCH_DEBOUNCE_TIMEOUT : 0
    );
  };

  handleGuestSearchQueryChange = (guestSearchQuery: string): void => {
    this.nextOffset = 0;

    this.setState(
      {
        guestSearchQuery
      },
      () => {
        if (
          (guestSearchQuery === "" ||
            guestSearchQuery.length >= MINIMUM_SEARCH_TERM_LENGTH) &&
          !UtilsService.validateEmail(guestSearchQuery.trim())
        ) {
          this.debouncedFetch(guestSearchQuery.length > 0);
        }
      }
    );
  };

  handleAddMember = (member: User): void => {
    const { onGuestAdded } = this.props;
    this.invitedEmails = this.invitedEmails.concat(member.email);

    this.setState(
      (pre) => ({
        projectMembers: pre.projectMembers.filter(
          (m) => m.email !== member.email
        ),
        guestSearchQuery: ""
      }),
      () => onGuestAdded(EventService.parseMemberToEventGuest(member))
    );
  };

  handleAddWithEmail = (): void => {
    const { onGuestAdded } = this.props;
    const { guestSearchQuery } = this.state;

    const trimmedEmail = guestSearchQuery.toLowerCase().trim();

    if (!this.invitedEmails.includes(trimmedEmail)) {
      const newGuest = {} as EventGuest;
      newGuest.email = trimmedEmail;
      onGuestAdded(newGuest, true);
      this.props.closePanel();
    }
  };

  handleClearQueryClicked = (): void => {
    this.setState({
      guestSearchQuery: ""
    });
  };

  handleInitialFetch = (elementCount?: number): void => {
    if (elementCount) {
      this.initialElementLimit = elementCount;
    }
    this.fetchEventCandidates();
  };

  handleBottomReached = (): void => {
    const { isMore, isLoading } = this.state;

    if (isMore && !isLoading) {
      this.fetchEventCandidates();
    }
  };

  renderMemberItem = (
    { email, firstName, lastName, profileImageUrl, id }: User,
    onMemberClick: () => void
  ): JSX.Element => (
    <div className="user-item" key={id}>
      <div className="left-container">
        <div className="users-avatar">
          <CIQAvatar
            username={UtilsService.displayUserName({
              email,
              firstName,
              lastName
            } as User)}
            profileImageUrl={profileImageUrl}
          />
        </div>
        <div className="user-name">
          {UtilsService.displayUserName({
            email,
            firstName,
            lastName
          } as User)}
        </div>
      </div>
      <div className="right-container">
        <span className="ciq-icon ciq-plus" onClick={onMemberClick} />
      </div>
    </div>
  );

  renderMemberList = (): JSX.Element => {
    const { t } = this.props;
    const { guestSearchQuery, projectMembers, isLoading } = this.state;

    // Search within invited email list & selected emails
    const filterInvitedEmails = this.invitedEmails.filter(
      (m) => m.toLowerCase() === guestSearchQuery.trim().toLowerCase()
    );

    if (filterInvitedEmails.length) {
      return <CIQNoResults message={t("alreadyInvited")} />;
    }

    if (UtilsService.validateEmail(guestSearchQuery.trim())) {
      if (guestSearchQuery.length > UserConstraints.MAX_EMAIL_LENGTH) {
        return (
          <CIQNoResults
            message={t("maxEmailLength", {
              length: UserConstraints.MAX_EMAIL_LENGTH
            })}
          />
        );
      }

      return (
        <div
          className="invite-item"
          onClick={(): void => this.handleAddWithEmail()}
        >
          <i className="ciq-icon ciq-plus" />
          <div className="invite-name">
            {guestSearchQuery.trim().toLowerCase()}
          </div>
        </div>
      );
    }

    return (
      <div className="users-list">
        {projectMembers.map((m) =>
          this.renderMemberItem(m, (): void => this.handleAddMember(m))
        )}
        {isLoading && <Loader />}
        {projectMembers.length === 0 && !isLoading && (
          <CIQNoResults message={t("inviteByEmailMessage")} />
        )}
      </div>
    );
  };

  renderPanelBody = (): JSX.Element => {
    const { t } = this.props;
    const { guestSearchQuery } = this.state;
    const isMaximumReached =
      this.invitedEmails.length >= GUEST_INVITE_MAX_COUNT;

    return (
      <>
        <div className="guest-list-body panel-body">
          {!isMaximumReached && (
            <div className="top-container">
              <div className="search-filter-container">
                <SearchBar
                  initialValue={guestSearchQuery}
                  onClear={this.handleClearQueryClicked}
                  onQueryChange={this.handleGuestSearchQueryChange}
                  placeholder={t("inviteWithEmailName")}
                />
              </div>
            </div>
          )}
        </div>

        <Media queries={APP_DEVICE_MEDIA_QUERIES}>
          {(matches): JSX.Element => (
            <div className="user-list-container">
              {isMaximumReached ? (
                this.renderLimitExceededContainer()
              ) : (
                <CIQInfiniteScroll
                  style={{
                    maxHeight: UI_INVITE_GUESTS.CONTAINER_HEIGHT,
                    height: UI_INVITE_GUESTS.CONTAINER_HEIGHT,
                    overflowX: "hidden"
                  }}
                  elementHeight={UI_INVITE_GUESTS.LIST_ITEM_HEIGHT(matches)}
                  className=" users-list simplebar-light"
                  onInitialFetch={this.handleInitialFetch}
                  onBottomReached={this.handleBottomReached}
                >
                  {this.renderMemberList()}
                </CIQInfiniteScroll>
              )}
            </div>
          )}
        </Media>
      </>
    );
  };

  renderLimitExceededContainer = (): JSX.Element => {
    const { t } = this.props;
    return (
      <CIQNoResults
        message={t("maxGuestCountReached", { limit: GUEST_INVITE_MAX_COUNT })}
      />
    );
  };

  render(): JSX.Element {
    const { t } = this.props;

    return (
      <div className="invite-guests-container content-panel-body">
        <div className="panel-header">
          <h2>{t("inviteGuests")}</h2>
          <ContentPanelContextConsumer>
            {(contentPanelContext: ContentPanelContextProps): JSX.Element => (
              <div
                className="btn-close"
                onClick={contentPanelContext.closePanel}
              >
                <i className="ciq-icon ciq-close" />
              </div>
            )}
          </ContentPanelContextConsumer>
        </div>

        {this.renderPanelBody()}
      </div>
    );
  }
}

export default withTranslation("inviteGuestsPanel")(InviteGuestsPanel);
