import React, { useCallback } from "react";

import { DocumentService, PaginatedRequest } from "@arbolus-technologies/api";

import { ProjectDocuments } from "./ProjectDocuments";

interface AdminProjectDocumentsProps {
  projectId: string;
  documentService?: typeof DocumentService;
}

export const AdminProjectDocuments: React.FC<AdminProjectDocumentsProps> = ({
  projectId,
  documentService = DocumentService
}) => {
  const getDocuments = useCallback(
    (queryParams: PaginatedRequest<"created">) =>
      documentService.getDocuments(projectId, queryParams, "", true),
    [documentService, projectId]
  );

  return <ProjectDocuments projectId={projectId} getDocuments={getDocuments} />;
};
