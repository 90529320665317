/* eslint-disable @typescript-eslint/no-empty-interface */
import clsx from "clsx";
import { Field, Form, Formik, FormikProps } from "formik";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import { createStructuredSelector } from "reselect";
import { Subscription } from "rxjs";
import SimpleBar from "simplebar-react";

import { ToasterService } from "@arbolus-technologies/api";

import { PROJECT_ROUTE } from "../../../../constants/navigation/projectRoutes";
import { UI_REFER_EXPERT, UI_WINDOW_HEIGHT } from "../../../../constants/ui";
import {
  NavBarContextConsumer,
  NavBarContextProps
} from "../../../../contexts/navBar/NavBarContext";
import { CIQError, ErrorResponse } from "../../../../models/api";
import { ProjectService } from "../../../../services";
import { AppState } from "../../../../store/reducers";
import { CIQFormInput } from "../../../app/components";
import { AuthSelector } from "../../../auth/store";
import { ProjectSelector } from "../../store";
import { ReferExpertValidationSchema } from "./ReferExpertSchema";

import { ReactComponent as TickIcon } from "../../../../assets/images/icons/tick.svg";
import arbolusLogo from "../../../../assets/images/logos/logo-tree-blue.png";

const notification = new ToasterService();

interface ReferExpertPageStoreProps {
  expertName: string;
  projectName: string;
}

type ReferExpertPageIntersectProps = ReferExpertPageStoreProps &
  WithTranslation &
  RouteComponentProps<{ projectId: string }>;
interface ReferExpertPageState {
  isSent: boolean;
  isSending: boolean;
}

interface ReferExpertFormValues {
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  description: string;
}

class ReferExpertPage extends Component<
  ReferExpertPageIntersectProps,
  ReferExpertPageState
> {
  constructor(props: ReferExpertPageIntersectProps) {
    super(props);
    this.state = {
      isSent: false,
      isSending: false
    };
  }

  componentWillUnmount(): void {
    this.ReferExpertSubscription?.unsubscribe();
  }

  private formRef?: FormikProps<ReferExpertFormValues> | null = null;

  private ReferExpertSubscription?: Subscription;

  handleSubmit = (): void => {
    if (this.formRef) {
      const { match } = this.props;
      const {
        params: { projectId }
      } = match;

      const { description, email, firstName, lastName, jobTitle } =
        this.formRef.values;

      this.setState({
        isSending: true
      });

      this.ReferExpertSubscription = ProjectService.referExpert(projectId, {
        firstName,
        lastName,
        email: email.toLowerCase(),
        endorsement: description,
        designationTitle: jobTitle
      }).subscribe(
        () => {
          this.setState({
            isSending: false,
            isSent: true
          });
        },
        (error: ErrorResponse<CIQError>) => {
          notification.showError(error.message);
          this.setState({
            isSending: false
          });
        }
      );
    }
  };

  handleReferAnother = (): void => {
    this.formRef?.resetForm();
    this.setState({
      isSent: false
    });
  };

  handleNavigateToProject = (): void => {
    const { history, match } = this.props;
    const {
      params: { projectId }
    } = match;
    history.push(PROJECT_ROUTE(projectId));
  };

  renderForm = ({
    errors,
    touched,
    isValid,
    dirty
  }: FormikProps<ReferExpertFormValues>): JSX.Element => {
    const { t } = this.props;
    const { isSending } = this.state;
    const submitButtonEnabled = isValid && dirty;

    return (
      <Form style={{ width: "100%" }}>
        <section>
          <Row>
            <Col xs={12} md={6}>
              <FormGroup
                className={clsx({
                  "is-invalid": errors.firstName && touched.firstName
                })}
              >
                <Label>{t("referralFirstName")}</Label>
                <Field
                  name="firstName"
                  placeholder={t("firstName")}
                  type="text"
                  component={CIQFormInput}
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup
                className={clsx({
                  "is-invalid": errors.lastName && touched.lastName
                })}
              >
                <Label>{t("referralLastName")}</Label>
                <Field
                  name="lastName"
                  placeholder={t("lastName")}
                  type="text"
                  component={CIQFormInput}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <FormGroup
                className={clsx({
                  "is-invalid": errors.jobTitle && touched.jobTitle
                })}
              >
                <Label className="space-between">
                  {t("referralJobTitle")}
                  <span>{t("optional")}</span>
                </Label>
                <Field
                  name="jobTitle"
                  placeholder={t("jobTitle")}
                  type="text"
                  component={CIQFormInput}
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup
                className={clsx({
                  "is-invalid": errors.email && touched.email
                })}
              >
                <Label>{t("referralEmail")}</Label>
                <Field
                  className="email-input"
                  name="email"
                  placeholder={t("email")}
                  type="text"
                  component={CIQFormInput}
                />
              </FormGroup>
            </Col>
          </Row>
        </section>
        <section>
          <h3>{t("endorsement")}</h3>
          <Row>
            <Col>
              <FormGroup
                className={clsx({
                  "is-invalid": errors.description && touched.description
                })}
              >
                <Label>{t("why")}</Label>
                <Field
                  rows={9}
                  name="description"
                  placeholder={t("endorsementPlaceholder")}
                  type="textarea"
                  component={CIQFormInput}
                />
              </FormGroup>
            </Col>
          </Row>
        </section>
        <section>
          <div className="btn-container">
            <Button
              type="submit"
              size="lg"
              color="primary"
              className="btn-bold"
              disabled={!submitButtonEnabled || isSending}
            >
              {t("sendReferral")}
            </Button>
          </div>
        </section>
      </Form>
    );
  };

  renderConfirmationMessage = (): JSX.Element => {
    const { t, expertName } = this.props;

    const referredExpertName = this.formRef?.values.firstName;

    return (
      <div className="success-container">
        <TickIcon />
        <h1>{t("thanks", { expertName })}</h1>
        <p>{t("message", { referredExpertName })}</p>
        <div className="action-button-container">
          <Button
            size="lg"
            color="secondary"
            className="btn-bold"
            onClick={this.handleReferAnother}
          >
            {t("another")}
          </Button>
          <Button
            size="lg"
            color="primary"
            className="btn-bold"
            onClick={this.handleNavigateToProject}
          >
            {t("return")}
          </Button>
        </div>
      </div>
    );
  };

  render(): JSX.Element {
    const { t, projectName } = this.props;

    const { isSent } = this.state;
    return (
      <NavBarContextConsumer>
        {({ isProjectActionsActive }: NavBarContextProps): JSX.Element => {
          const projectActionHeight = isProjectActionsActive
            ? UI_REFER_EXPERT.PROJECT_ACTIONS_EXPAND_HEIGHT
            : 0;

          return (
            <SimpleBar
              className="simplebar-light"
              style={{
                height: `calc(${UI_WINDOW_HEIGHT} - ${projectActionHeight}px)`,
                maxHeight: `calc(${UI_WINDOW_HEIGHT} - ${projectActionHeight}px)`
              }}
            >
              <div className="w-100 project-refer-expert-page">
                <div className="full-page-logo">
                  <img
                    src={arbolusLogo}
                    height="30px"
                    width="30px"
                    alt="logo"
                  />
                </div>
                <div className="refer-expert-header">
                  <Col>
                    <Button
                      className="left-btn btn-link"
                      onClick={this.handleNavigateToProject}
                    >
                      {projectName}
                    </Button>
                  </Col>
                  <Col>
                    {!isSent && (
                      <Button
                        className="right-btn btn-link"
                        onClick={this.handleNavigateToProject}
                      >
                        {t("cancel")}
                      </Button>
                    )}
                  </Col>
                </div>
                <Row className="refer-expert-body">
                  {isSent ? (
                    this.renderConfirmationMessage()
                  ) : (
                    <>
                      <h1>{t("referExpert")}</h1>
                      <Formik<ReferExpertFormValues>
                        initialValues={{
                          description: "",
                          email: "",
                          firstName: "",
                          jobTitle: "",
                          lastName: ""
                        }}
                        validateOnBlur
                        validateOnChange
                        innerRef={(instance): void => {
                          this.formRef = instance;
                        }}
                        validationSchema={ReferExpertValidationSchema}
                        onSubmit={this.handleSubmit}
                      >
                        {this.renderForm}
                      </Formik>
                    </>
                  )}
                </Row>
              </div>
            </SimpleBar>
          );
        }}
      </NavBarContextConsumer>
    );
  }
}

const mapStateToProps = createStructuredSelector<
  AppState,
  ReferExpertPageStoreProps
>({
  expertName: AuthSelector.authUsernameSelector(false),
  projectName: ProjectSelector.projectNameSelector()
});

const storeConnectedReferExpertPage = connect(mapStateToProps)(ReferExpertPage);

const translatedReferExpertPage = withTranslation("referExpertPage")(
  storeConnectedReferExpertPage
);

export default translatedReferExpertPage;
