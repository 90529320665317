import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { CacheSelector } from "@arbolus-technologies/stores/cache";

import { MoveToShortlistButton } from "./MoveToShortlistButton";

import { Checkbox } from "@arbolus-technologies/ui/components";
import styles from "./ResultsAndActions.module.scss";

interface ResultsAndActionsProps {
  totalExpertsAvailable: number;
  totalExpertsFound: number;
  selectedExpertsIds: string[];
  selectedCautionExperts: { id: string; name: string }[];
  onShortlistConfirmed: (selectedExpertsIds: string[]) => void;
  handleToggleSelectAllExperts: () => void;
  areAllExpertsSelected: boolean;
}

export const ResultsAndActions: React.FC<ResultsAndActionsProps> = ({
  totalExpertsAvailable,
  totalExpertsFound,
  selectedExpertsIds,
  selectedCautionExperts,
  onShortlistConfirmed,
  handleToggleSelectAllExperts,
  areAllExpertsSelected
}) => {
  const { t } = useTranslation("discoverPage");
  const isClient = useSelector(CacheSelector.isCurrentUserClientSelector());

  const areActionsDisabled = selectedExpertsIds.length === 0;
  const searchEngineLimit = 10000;

  const displayedExpertsNumber =
    totalExpertsFound >= searchEngineLimit
      ? `+${searchEngineLimit}`
      : totalExpertsFound;

  return (
    <div className={styles.container}>
      <Checkbox
        isChecked={areAllExpertsSelected}
        onChange={handleToggleSelectAllExperts}
        isDisabled={totalExpertsFound === 0}
        text={t(
          totalExpertsFound > 0 ? "totalExpertsFound" : "totalExpertsAvailable",
          {
            count:
              totalExpertsFound > 0
                ? displayedExpertsNumber
                : totalExpertsAvailable
          }
        )}
      />
      <div className={styles.buttonsContainer}>
        {!isClient && (
          <MoveToShortlistButton
            disabled={areActionsDisabled}
            selectedExpertsIds={selectedExpertsIds}
            selectedCautionExperts={selectedCautionExperts}
            onShortlistConfirmed={onShortlistConfirmed}
          />
        )}
      </div>
    </div>
  );
};
