import React from "react";

import { Button } from "@arbolus-technologies/ui/components";

import styles from "./MobileBackButton.module.scss";

export const MobileBackButton: React.FC<{
  text: string;
  onClick: () => void;
}> = ({ text, onClick }) => (
  <div className={styles.backButton}>
    <Button
      type="tertiary"
      startIcon="chevron_left"
      text={text}
      onClick={onClick}
    />
  </div>
);
