import { Icon } from "arbolus-ui-components";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ARBOLUS_COLORS, arbolusLogo } from "@arbolus-technologies/theme";
import { Avatar, LinkWrapper } from "@arbolus-technologies/ui/components";
import { displayUserName } from "@arbolus-technologies/utils";

import { MixPanelEventNames } from "../../../Mixpanel/enums";
import { useArbolusTracking } from "../../../Mixpanel/useArbolusTracking";
import { ReportIncidentButton } from "../../ReportIncident/ReportIncidentButton/ReportIncidentButton";
import { MenuButton } from "../Components/MenuButton";
import SubMenuButton from "../Components/SubMenuButton/SubMenuButton";
import { ISideBarItems, handleActiveRoute } from "../MenuItemsConfig";
import { useMenuItems } from "../useMenuItems";

import styles from "./DesktopMenu.module.scss";

const external = /^(https?:\/\/|\/\/)/i;
export const isInternalLink = (link: string) => !external.test(link);

interface DesktopMenuProps {
  handleLogout: () => void;
  profileRoute: string;
  isAdmin?: boolean;
}

export const DesktopMenu: React.FC<DesktopMenuProps> = ({
  handleLogout,
  profileRoute,
  isAdmin = false
}) => {
  const authUser = useSelector(CacheSelector.loggedInUser());
  const adminUser = useSelector(CacheSelector.userAdminProfile());
  const userRole = useSelector(CacheSelector.generateActiveUserRole());
  const hasNotifications = useSelector(CacheSelector.hasNotifications());
  const { trackClick } = useArbolusTracking();
  const avatarUser = isAdmin ? adminUser : authUser;
  const clientId = isAdmin ? adminUser.clientId : undefined;
  const { menuItems } = useMenuItems(isAdmin, userRole, clientId);

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [submenu, setSubmenu] = useState<{
    title: string;
    items: ISideBarItems[];
  } | null>(null);

  const subMenuRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        subMenuRef.current &&
        !subMenuRef.current.contains(event.target as Node) &&
        menuRef.current &&
        !menuRef.current.contains(event.target as Node)
      ) {
        setIsSubMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [subMenuRef]);

  const trackMixPanelEvents = (name: string) => {
    switch (name) {
      case "Canopy":
        trackClick(MixPanelEventNames.CanopySection);
        break;
      case "Projects":
        trackClick(MixPanelEventNames.ProjectSection);
        break;
      default:
        break;
    }
  };

  const onHandleClick = (menuItem: ISideBarItems) => {
    trackMixPanelEvents(menuItem.name);

    if (menuItem?.subItems) {
      setSubmenu({ title: menuItem.name, items: menuItem.subItems });
      setIsSubMenuOpen(true);
    } else {
      setSubmenu(null);
      setIsSubMenuOpen(false);

      const isInternal = menuItem.route ? isInternalLink(menuItem.route) : true;
      if (!isInternal) {
        window.open(menuItem.route, "_blank");
      }
    }
  };

  return (
    <div className={styles.container} ref={menuRef}>
      <div className={styles.innerContainer}>
        <div className={styles.avatarContainer}>
          <LinkWrapper to={profileRoute}>
            <Avatar
              type="mediumXSCircle"
              avatar={{
                name: displayUserName(avatarUser),
                imageUrl: avatarUser.profileImageUrl
              }}
            />
          </LinkWrapper>
        </div>
        <div className={styles.buttonMenuContainer}>
          <div className={styles.main}>
            {menuItems.map(
              (item) =>
                item.position === "main" && (
                  <MenuButton
                    key={item.name}
                    text={item.name}
                    icon={item.icon}
                    handleButtonClick={() => onHandleClick(item)}
                    isActive={
                      item.route ? handleActiveRoute(item.route) : false
                    }
                    to={item.route}
                    hasNotifications={item.name === "Inbox" && hasNotifications}
                  />
                )
            )}
          </div>
          <div className={styles.footer}>
            {menuItems.map((item) => {
              if (
                item.position === "footer" &&
                !item.route &&
                item.name !== "Settings"
              ) {
                return <ReportIncidentButton key={item.name} />;
              }

              if (item.position === "footer") {
                return (
                  <MenuButton
                    key={item.name}
                    text={item.name}
                    icon={item.icon}
                    handleButtonClick={() => onHandleClick(item)}
                    isActive={
                      item.route ? handleActiveRoute(item.route) : false
                    }
                    to={
                      item.route && isInternalLink(item.route) ? item.route : ""
                    }
                  />
                );
              }
              return null;
            })}
            <img src={arbolusLogo} alt="Arbolus Logo" className={styles.logo} />
          </div>
        </div>
        <div
          className={`${styles.subMenuContainer} ${
            isSubMenuOpen ? styles.open : ""
          }`}
        >
          <div className={styles.containerSubItems}>
            <span className={styles.subMenuTitle}>{submenu?.title}</span>
            <Icon
              name="close"
              fontSize="24px"
              color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
              onClick={() => setIsSubMenuOpen(false)}
            />
          </div>
          {submenu?.items &&
            submenu.items.map((item) => (
              <SubMenuButton
                key={item.name}
                item={item}
                ref={subMenuRef}
                handleLogout={handleLogout}
                handleButtonClick={() => onHandleClick(item)}
                isActive={item.route ? handleActiveRoute(item.route) : false}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
