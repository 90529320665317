import i18next from "i18next";

import { ExpertsListPageTab } from "@arbolus-technologies/api";

import { Recommendations } from "./ComponentsTable/Recommendations/Recommendations";
import { AcceptList } from "./FilteredExperts/AcceptList";
import { AllExpertsList } from "./FilteredExperts/AllExpertsList";
import { CandidatesList } from "./FilteredExperts/CandidatesList";
import { RejectList } from "./FilteredExperts/RejectList";
import { ShortList } from "./FilteredExperts/ShortList";
import { Schedule } from "./Schedule/Schedule";

export const CLIENT_TABS: TabsWithPage[] = [
  ExpertsListPageTab.Book,
  ExpertsListPageTab.AllExperts,
  ExpertsListPageTab.Accept,
  ExpertsListPageTab.Reject
];

export const ADMIN_TABS: TabsWithPage[] = [
  ExpertsListPageTab.Book,
  ExpertsListPageTab.ShortList,
  ExpertsListPageTab.Candidate,
  ExpertsListPageTab.Accept,
  ExpertsListPageTab.Reject
];

export const ADMIN_ONLY_TABS: TabsWithPage[] = [
  ExpertsListPageTab.Recommendations,
  ExpertsListPageTab.ShortList,
  ExpertsListPageTab.Candidate
];

export const CLIENT_ONLY_TABS: TabsWithPage[] = [ExpertsListPageTab.AllExperts];

export type TabsWithPage = Exclude<
  ExpertsListPageTab,
  ExpertsListPageTab.Review
>;

export const TABS: Record<
  TabsWithPage,
  { title: string; Component: React.ComponentType<any>; icon?: string }
> = {
  [ExpertsListPageTab.Book]: {
    title: i18next.t("expertsList:book"),
    Component: Schedule
  },
  [ExpertsListPageTab.Recommendations]: {
    title: i18next.t("expertsList:recommendations"),
    Component: Recommendations
  },
  [ExpertsListPageTab.AllExperts]: {
    title: i18next.t("expertsList:allExperts"),
    Component: AllExpertsList
  },
  [ExpertsListPageTab.ShortList]: {
    title: i18next.t("expertsList:shortList"),
    Component: ShortList
  },
  [ExpertsListPageTab.Candidate]: {
    title: i18next.t("expertsList:candidates"),
    Component: CandidatesList
  },
  [ExpertsListPageTab.Accept]: {
    title: i18next.t("expertsList:approved"),
    Component: AcceptList
  },
  [ExpertsListPageTab.Reject]: {
    title: i18next.t("expertsList:rejected"),
    Component: RejectList
  }
};
