import { useSelector } from "react-redux";

import { IChatMessage } from "@arbolus-technologies/api";
import {
  AngleChatData,
  ChatData,
  ChatUserAPIRole,
  OnlineMembers
} from "@arbolus-technologies/models/project";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";

import { useChat } from "../../Contexts/ChatContext/ChatContext";
import { AngleWithChat } from "../../Models/chat";

export function useOnlineChatMembers(): {
  isClientOnlineInAngleChat: (angle: AngleWithChat) => boolean;
  isClientOnlineInProjectChat: (projectChat?: ChatData) => boolean;
  isMemberOnlineInMessages: (message: IChatMessage) => boolean;
  isMemberOnlineInChat: (chat: AngleChatData) => boolean;
} {
  const onlineMembersByChat = useSelector(
    ProjectNxSelector.onlineMembersByChat()
  );
  const { currentChat, projectChat } = useChat();

  const isClientOnlineInAngleChat = (angle: AngleWithChat): boolean => {
    const angleChatOnlineMembers: OnlineMembers | null = onlineMembersByChat
      ? onlineMembersByChat[angle.channelName]
      : null;

    const clientMembersOnline = angleChatOnlineMembers?.members ?? {};

    // Display client online if any user from the client team is online
    return angle.chatMembers.some(
      (user) =>
        user.userType === ChatUserAPIRole.Client &&
        Object.keys(clientMembersOnline).includes(user.id)
    );
  };

  const isClientOnlineInProjectChat = (): boolean => {
    const projectChatOnlineMembers: OnlineMembers | null = onlineMembersByChat
      ? onlineMembersByChat[projectChat?.channelName!]
      : null;

    const clientMembersOnline = projectChatOnlineMembers?.members ?? {};

    return (
      projectChat?.chatMembers.some(
        (user) =>
          user.userType === ChatUserAPIRole.Client &&
          Object.keys(clientMembersOnline).includes(user.id)
      ) ?? false
    );
  };

  const isMemberOnlineInMessages = (message: IChatMessage): boolean => {
    const currentChatOnlineMembers: OnlineMembers | null = onlineMembersByChat
      ? onlineMembersByChat[currentChat?.channelName!]
      : null;

    // Exclude admin users from being seen online
    return (
      typeof currentChatOnlineMembers?.members[message.userId] !==
        "undefined" &&
      message.userId !== currentChatOnlineMembers.me.id &&
      message.userType !== ChatUserAPIRole.Admin
    );
  };

  const isMemberOnlineInChat = (chat: AngleChatData): boolean => {
    const currentChatOnlineMembers: OnlineMembers | null = onlineMembersByChat
      ? onlineMembersByChat[chat.channelName]
      : null;

    return (
      typeof currentChatOnlineMembers?.members[chat?.userId!] !== "undefined"
    );
  };
  return {
    isClientOnlineInAngleChat,
    isClientOnlineInProjectChat,
    isMemberOnlineInMessages,
    isMemberOnlineInChat
  };
}
