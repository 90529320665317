import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface ConfirmModalProps {
  onCancel: () => void;
  onConfirm: () => void;
  toggleModal: boolean;
  cancelActionText?: string;
  confirmActionText?: string;
  warningMessage: string;
  messageTitle: string;
}

/**
 * @deprecated
 * use ArbolusModal from @arbolus-technologies/libs/ui/components
 */

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  onCancel,
  onConfirm,
  toggleModal,
  messageTitle,
  warningMessage,
  cancelActionText,
  confirmActionText
}) => {
  const { t } = useTranslation("confirmModal");

  return (
    <Modal isOpen={toggleModal} className="modal-alert danger">
      <ModalHeader>{messageTitle}</ModalHeader>
      <ModalBody>{warningMessage}</ModalBody>
      <ModalFooter>
        <Button size="md" color="secondary" onClick={onCancel}>
          {cancelActionText || t("cancel")}
        </Button>
        <Button size="md" color="primary" onClick={(): void => onConfirm()}>
          {confirmActionText || t("confirm")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
