import React from "react";
import { useTranslation } from "react-i18next";

import { EmptyComponent } from "../EmptyComponent";

import drawer from "../../../assets/images/drawer.png";

export const DashboardEmptyProjects: React.FC = () => {
  const { t } = useTranslation("dashboardProjects");
  return (
    <EmptyComponent
      title={t("emptyStateTitle")}
      subtitle={t("emptyStateSubtitle")}
      logo={drawer}
    />
  );
};
