import {
  BOOKMARK_COLLECTION_ORDER_BY,
  BookmarksPaginatedRequest,
  CIQError,
  ErrorResponse,
  PagedListResponse
} from "@arbolus-technologies/api";
import { BookmarkList } from "@arbolus-technologies/models/common";

export const GET_BOOKMARKS_LIST = "BOOKMARKS_GET_BOOKMARKS_LIST";
export const GET_BOOKMARKS_LIST_SUCCESS =
  "BOOKMARKS_GET_BOOKMARKS_LIST_SUCCESS";
export const GET_BOOKMARKS_LIST_FAILURE =
  "BOOKMARKS_GET_BOOKMARKS_LIST_FAILURE";

export const RESET_BOOKMARKS_LIST = "BOOKMARKS_RESET_BOOKMARKS_LIST";

export interface GetBookmarksListAction {
  type: typeof GET_BOOKMARKS_LIST;
  payload: {
    searchTerm: string;
    queryParams: BookmarksPaginatedRequest<BOOKMARK_COLLECTION_ORDER_BY>;
  };
}

export interface GetBookmarksListSuccessAction {
  type: typeof GET_BOOKMARKS_LIST_SUCCESS;
  payload: {
    response: PagedListResponse<BookmarkList>;
  };
}

export interface GetBookmarksListFailureAction {
  type: typeof GET_BOOKMARKS_LIST_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

// reset

export interface ResetBookmarksListAction {
  type: typeof RESET_BOOKMARKS_LIST;
}
