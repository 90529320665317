import i18next from "i18next";
import * as Yup from "yup";

import {
  DNC_CATEGORY_ENUM,
  DO_NOT_CONTACT_STATUS
} from "@arbolus-technologies/models/common";

import { DNC_MAX_NOTE_LENGTH } from "../../../constants";
import { DNCFormEnum } from "../../../enums";

export interface DNCFormValues {
  dncStatus: DO_NOT_CONTACT_STATUS;
  dncCategory?: DNC_CATEGORY_ENUM | null;
  dncNote?: string;
}

export const DNCSchema: Yup.ObjectSchema<DNCFormValues> = Yup.object().shape({
  [DNCFormEnum.DNC_STATUS]: Yup.mixed<DO_NOT_CONTACT_STATUS>().required(),
  [DNCFormEnum.DNC_CATEGORY]: Yup.mixed<DNC_CATEGORY_ENUM | null>().when(
    [DNCFormEnum.DNC_STATUS],
    (dncStatus, schema) =>
      dncStatus === DO_NOT_CONTACT_STATUS.OK ? schema : schema.required()
  ),
  [DNCFormEnum.DNC_NOTE]: Yup.string()
    .typeError(i18next.t("dncSection:noteRequired"))
    .max(
      DNC_MAX_NOTE_LENGTH,
      i18next.t("dncSection:noteLengthError", {
        length: DNC_MAX_NOTE_LENGTH
      })
    )
    .when([DNCFormEnum.DNC_STATUS], (dncStatus, schema) =>
      dncStatus === DO_NOT_CONTACT_STATUS.OK
        ? schema.required(i18next.t("dncSection:noteRequired"))
        : schema
    )
});
