import React, { useEffect, useMemo } from "react";
import { isMobile } from "react-device-detect";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import {
  ANSWER_TYPE,
  CanopyQuestionFields,
  CreateEditQuestionFields
} from "@arbolus-technologies/models/canopy";
import { CustomSelect, Tab } from "@arbolus-technologies/ui/components";

import { CanopyCreateFormValues } from "../../../../helpers/CanopyInitialFormValues";
import { useCanopyContext } from "../../../../helpers/useCanopyContext";

import styles from "./AnswerTypeTabs.module.scss";

const TABS_TRACKING = {
  [ANSWER_TYPE.VIDEO]: MixPanelEventNames.CanopyBuilderQuestionsTypeVideo,
  [ANSWER_TYPE.MULTIPLE_CHOICE]:
    MixPanelEventNames.CanopyBuilderQuestionsTypeMulti,
  [ANSWER_TYPE.VALUE]: MixPanelEventNames.CanopyBuilderQuestionsTypeValue,
  [ANSWER_TYPE.SHORT_TEXT]:
    MixPanelEventNames.CanopyBuilderQuestionsTypeShortText,
  [ANSWER_TYPE.NPS]: MixPanelEventNames.CanopyBuilderQuestionsTypeNPS
};

export const AnswerTypeTabs: React.FC = () => {
  const { t } = useTranslation("canopyBuilder");
  const { trackClick } = useArbolusTracking();
  const { isEditMode } = useCanopyContext();

  const { watch, reset, getValues, setValue } =
    useFormContext<CanopyQuestionFields>();
  const [answerType]: [ANSWER_TYPE] = watch([
    CreateEditQuestionFields.ANSWER_TYPE
  ]);
  const formValues = getValues();

  const handleSwitchAnswerType = (answerType: ANSWER_TYPE) => {
    trackClick(TABS_TRACKING[answerType]);
    setValue(CreateEditQuestionFields.ANSWER_TYPE, answerType, {
      shouldDirty: true,
      shouldValidate: true
    });
  };

  // Reset form values only when answer type changes
  useEffect(() => {
    if (!isEditMode) {
      reset({
        ...CanopyCreateFormValues(formValues.order),
        [CreateEditQuestionFields.QUESTION]: formValues.question,
        [CreateEditQuestionFields.DETAILS]: formValues.details,
        [CreateEditQuestionFields.ANSWER_TYPE]: formValues.answerType
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerType]);

  const TABS = useMemo(
    () => [
      {
        label: `${t("video")}`,
        value: ANSWER_TYPE.VIDEO
      },
      {
        label: `${t("multipleChoice")}`,
        value: ANSWER_TYPE.MULTIPLE_CHOICE
      },
      {
        label: `${t("value")}`,
        value: ANSWER_TYPE.VALUE
      },
      {
        label: `${t("shortText")}`,
        value: ANSWER_TYPE.SHORT_TEXT
      },
      {
        label: `${t("nps")}`,
        value: ANSWER_TYPE.NPS
      }
    ],
    [t]
  );

  return (
    <div className={styles.container}>
      {isEditMode && (
        <Tab
          text={TABS.find((tab) => tab.value === answerType)?.label!}
          isActive
          disabled
        />
      )}
      {!isEditMode &&
        (isMobile ? (
          <CustomSelect
            noOptionsMessage={t("noOptions")}
            options={TABS}
            onSelectChange={(value) =>
              handleSwitchAnswerType(value as ANSWER_TYPE)
            }
            defaultValue={TABS[0]}
            displayIcon
            placeholder={"select a value"}
          />
        ) : (
          TABS.map((tab) => (
            <Tab
              key={tab.value}
              onClick={() => handleSwitchAnswerType(tab.value)}
              text={tab.label}
              isActive={answerType === tab.value}
            />
          ))
        ))}
    </div>
  );
};
