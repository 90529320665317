import { Icon } from "arbolus-ui-components";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  DefaultToasterService,
  DocumentService,
  ToasterService
} from "@arbolus-technologies/api";
import {
  DefaultDocumentUtils,
  DocumentUtils
} from "@arbolus-technologies/features/common";
import { UserRole } from "@arbolus-technologies/models/common";
import { ALLOWED_FILE_TYPES } from "@arbolus-technologies/models/documents";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { UploadInput } from "@arbolus-technologies/ui/components";

import styles from "./UploadDocument.module.scss";

const acceptFileTypes = ALLOWED_FILE_TYPES.map((ext) => `.${ext}`).join(",");
export const uploadFileServiceByRole = {
  [UserRole.arbolusTeam]: DocumentService.uploadAdminProjectDocument,
  [UserRole.client]: DocumentService.uploadClientProjectDocument,
  [UserRole.clientAdmin]: DocumentService.uploadClientProjectDocument,
  [UserRole.expert]: DocumentService.uploadExpertProjectDocument
};

interface UploadDocumentProps {
  projectId: string;
  onDocumentUploaded: (id: string, fileName: string) => void;
  documentUtils?: DocumentUtils;
  notificationService?: ToasterService;
}

export const UploadDocument: React.FC<UploadDocumentProps> = ({
  projectId,
  onDocumentUploaded,
  documentUtils = DefaultDocumentUtils,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("uploadDocument");

  const [isUploading, setIsUploading] = useState(false);

  const userRole = useSelector(CacheSelector.generateActiveUserRole());

  const clickUpload = useCallback(
    (file: File) => {
      const result = documentUtils.isFileAllowed(file);
      if (!result.ok) {
        notificationService.showError(result.error);
        return;
      }

      setIsUploading(true);
      const formData = new FormData();
      formData.append("file", file);

      const uploadService =
        uploadFileServiceByRole[
          userRole as keyof typeof uploadFileServiceByRole
        ];

      uploadService(projectId, formData).subscribe(
        (doc) => {
          notificationService.showSuccess("File uploaded");
          setIsUploading(false);
          onDocumentUploaded(doc.id, file.name);
        },
        (error) => {
          setIsUploading(false);
          notificationService.showApiErrors(error);
        }
      );
    },
    [
      documentUtils,
      notificationService,
      onDocumentUploaded,
      projectId,
      userRole
    ]
  );

  return (
    <UploadInput
      isUploading={isUploading}
      acceptFileTypes={acceptFileTypes}
      onFileSelected={clickUpload}
    >
      <div className={styles.label}>
        <span>{t("title")}</span>
        <Icon
          name="add"
          fontSize="24px"
          color={ARBOLUS_COLORS.bColorBasePurple}
        />
      </div>
    </UploadInput>
  );
};
