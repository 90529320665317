import React from "react";
import { useTranslation } from "react-i18next";

import { Angle } from "@arbolus-technologies/api";
import { PROJECT_MAX_ANGLES } from "@arbolus-technologies/features/common";
import { AngleModel } from "@arbolus-technologies/models/common";
import {
  AngleBoxButton,
  ButtonNoBorderWithIcon
} from "@arbolus-technologies/ui/components";

import styles from "./AnglesList.module.scss";

interface AnglesListProps {
  angles: Angle[] | AngleModel[];
  onAngleClick: (angle: Angle | AngleModel) => void;
  onNewClick?: () => void;
  selectedAngles?: string[];
}

export const AnglesList: React.FC<AnglesListProps> = ({
  angles,
  onAngleClick,
  onNewClick,
  selectedAngles = []
}) => {
  const { t } = useTranslation("projectAngles");

  return (
    <div className={styles.container}>
      {angles.length < PROJECT_MAX_ANGLES && onNewClick && (
        <ButtonNoBorderWithIcon
          name={t("new")}
          iconName="add"
          align="left"
          onClick={() => onNewClick()}
        />
      )}
      {angles.map((angle) => (
        <AngleBoxButton
          key={angle.id}
          angle={{ title: angle.title, color: angle.color! }}
          onClick={() => onAngleClick(angle)}
          isSelected={selectedAngles.includes(angle.id)}
        />
      ))}
    </div>
  );
};
