import React, { useCallback, memo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { ChatUserRole } from "@arbolus-technologies/models/project";
import { ProjectNxStoreActions } from "@arbolus-technologies/stores/project";
import { TiptapEditorMemoized } from "@arbolus-technologies/ui/components";
import { linkifyMessage } from "@arbolus-technologies/utils";

import { useChat } from "../../../../Contexts/ChatContext/ChatContext";
import { isAngleChat, isProjectChat } from "../../../../helpers";
import { isMobileChat } from "../../utils";
import { AttachFilesButton } from "./AttachFilesButton/AttachFilesButton";
import { SendMessageButton } from "./SendMessageButton";

import styles from "./ChatEditor.module.scss";

interface ChatEditorProps {
  projectId: string;
  isDisabled: boolean;
}

export const ChatEditorMemoized: React.FC<ChatEditorProps> = memo(
  function ChatEditor({ projectId, isDisabled }) {
    const { t } = useTranslation("chat");
    const dispatch = useDispatch();
    const { chatType, currentChat, chatSenderRole } = useChat();
    const { trackSubmit } = useArbolusTracking();

    const sendMessage = useCallback(
      (message: string) => {
        if (currentChat) {
          dispatch(
            ProjectNxStoreActions.sendChatMessage(
              projectId,
              currentChat.id,
              linkifyMessage(message)
            )
          );
          if (chatSenderRole !== ChatUserRole.Admin) {
            trackSubmit(MixPanelEventNames.ChatMessageSent);
          }
        }
      },
      [chatSenderRole, currentChat, dispatch, projectId, trackSubmit]
    );

    let placeholder = currentChat?.title;
    if (isAngleChat(chatType)) placeholder = t("angleChatPlaceholder");
    if (isProjectChat(chatType)) placeholder = t("projectChatPlaceholder");

    return (
      <div className={styles.editor}>
        <TiptapEditorMemoized
          onSubmit={sendMessage}
          hasError={false}
          initialEditorState=""
          SubmitComponent={SendMessageButton}
          placeholder={t("editorPlaceholder", { placeholder })}
          flexibleHeight
          disabled={isDisabled}
          enterSendsMessage={!isMobileChat()}
          isChat
        />
        <AttachFilesButton projectId={projectId} disabled={isDisabled} />
      </div>
    );
  }
);
