import { LocationDescriptorObject } from "history";
import React from "react";
import { Link } from "react-router-dom";

import styles from "./LinkRenderer.module.scss";

interface LinkRendererProps {
  text: string;
  to: LocationDescriptorObject;
}

export const LinkRenderer: React.FC<LinkRendererProps> = ({ text, to }) => (
  <Link to={to} className={styles.link}>
    {text}
  </Link>
);
