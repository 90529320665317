import React from "react";

import {
  AdminService,
  ListProject,
  SORT_DIRECTION
} from "@arbolus-technologies/api";

import { ProjectSelector, ProjectSelectorProps } from "./ProjectSelector";

interface ProjectAdminSelectorProps
  extends Omit<ProjectSelectorProps<ListProject>, "getItems"> {
  clientId?: string;
  projectStates?: string[];
  clientBillingInfo?: boolean;
}

export const ProjectAdminSelector: React.FC<ProjectAdminSelectorProps> = ({
  clientId = "",
  projectStates = ["Active"],
  clientBillingInfo = false,
  ...props
}) => (
  <ProjectSelector
    {...props}
    getItems={(pagination) =>
      AdminService.getClientProjects(
        clientId,
        pagination.offset,
        pagination.searchTerm,
        "name",
        pagination.limit,
        SORT_DIRECTION.ASCENDING,
        projectStates,
        clientBillingInfo
      )
    }
  />
);
