import React from "react";

import { ProjectNote } from "@arbolus-technologies/api";
import { YELLOW } from "@arbolus-technologies/theme";
import { DangerouslySetInnerHTML } from "@arbolus-technologies/ui/components";
import { highlightWords } from "@arbolus-technologies/utils";

import { CardFooterData, CardHeaderData } from "../../../interfaces";
import { MatchingFiltersWrapper } from "../MatchingFiltersWrapper/MatchingFiltersWrapper";

interface ProjectNoteCardProps {
  projectNote: ProjectNote;
  keywordsList: string[];
}

export const ProjectNoteCard: React.FC<ProjectNoteCardProps> = ({
  projectNote,
  keywordsList
}) => {
  const { angle, client, note, project, noteDate } = projectNote;

  const highlightedWords = highlightWords({
    text: note,
    tags: keywordsList,
    highlightColor: YELLOW,
    boldedWords: false
  });

  const cardHeader: CardHeaderData = {
    angle,
    project
  };

  const cardFooter: CardFooterData = {
    client,
    date: noteDate
  };

  return (
    <MatchingFiltersWrapper
      cardFooterData={cardFooter}
      cardHeaderData={cardHeader}
    >
      <DangerouslySetInnerHTML text={highlightedWords} />
    </MatchingFiltersWrapper>
  );
};
