import {
  CompanyFilter,
  LocationFilter,
  ProjectFilter
} from "@arbolus-technologies/api";
import {
  DiscoverFilterOperator,
  DiscoverFilterOption
} from "@arbolus-technologies/models/project";

export const createLocationFilters = (
  locations: DiscoverFilterOption[]
): LocationFilter[] =>
  locations.map((location) => ({
    locationId: location.value,
    isExcluded: location.operator === DiscoverFilterOperator.Exclude
  }));

export const createCompanyFilters = (
  companies: DiscoverFilterOption[]
): CompanyFilter[] =>
  companies.map((company) => ({
    companyId: company.value,
    isExcluded: company.operator === DiscoverFilterOperator.Exclude
  }));

export const createProjectFilters = (
  projects: DiscoverFilterOption[]
): ProjectFilter[] =>
  projects.map((project) => ({
    projectId: project.value
  }));
