import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";
import { isMobile } from "react-device-detect";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./StepsRow.module.scss";

interface StepsRowProps {
  currentStep: number;
  totalSteps: number;
  completedSteps: number;
  onBack?: () => void;
}

export const StepsRow: React.FC<StepsRowProps> = ({
  currentStep,
  totalSteps,
  completedSteps,
  onBack
}) => (
  <>
    {!isMobile && (
      <div className={styles.backIcon}>
        <Icon
          color={ARBOLUS_COLORS.bColorBasePurple}
          fontSize="24px"
          name="arrow_back"
          onClick={onBack}
        />
      </div>
    )}
    <div className={styles.steps}>
      {[...Array(totalSteps)].map((_, index) => (
        <React.Fragment key={`step-${index}`}>
          <div
            className={clsx(
              styles.stepCount,
              completedSteps <= index && index !== currentStep
                ? styles.remainingStep
                : ""
            )}
          >
            {index < completedSteps && index !== currentStep ? (
              <div className={styles.checkIcon}>
                <Icon
                  color={ARBOLUS_COLORS.bColorBaseWhite}
                  fontSize="20px"
                  name="check"
                />
              </div>
            ) : (
              index + 1
            )}
          </div>
          {index !== totalSteps - 1 && <div className={styles.line} />}
        </React.Fragment>
      ))}
    </div>
  </>
);
