import React from "react";

import styles from "./CircleWithIcon.module.scss";

interface CircleWithIconProps {
  icon: string;
  color: string;
  background: string;
  border?: string;
  customWidth?: string;
  customHeight?: string;
  fontSize?: string;
}
export const CircleWithIcon: React.FC<CircleWithIconProps> = ({
  icon,
  color,
  background,
  border,
  customWidth,
  customHeight,
  fontSize
}) => (
  <div
    className={styles.circle}
    style={{
      color,
      background,
      border,
      minWidth: customWidth,
      minHeight: customHeight,
      maxWidth: customWidth,
      maxHeight: customHeight
    }}
  >
    <span style={{ fontSize: fontSize }} className="material-symbols-sharp">
      {icon}
    </span>
  </div>
);
