import clsx from "clsx";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  CanopyQuestionMultiChoice,
  CreateEditQuestionFields
} from "@arbolus-technologies/models/canopy";
import { InputController } from "@arbolus-technologies/ui/components";

import { useCanopyContext } from "../../../../../../helpers/useCanopyContext";

import styles from "./MultiChoiceTypes.module.scss";

export const MultiChoiceTypeExact: React.FC = () => {
  const { t } = useTranslation("canopyBuilder");
  const { isCanopyActive, showUnsavedChanges } = useCanopyContext();

  const {
    control,
    formState: { errors, dirtyFields }
  } = useFormContext<CanopyQuestionMultiChoice>();

  return (
    <div className={styles.exactContainer}>
      <InputController
        name={CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_EXACT}
        error={errors[CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_EXACT]}
        classnames={clsx(styles.input, {
          [styles.unsavedField]:
            showUnsavedChanges &&
            dirtyFields[
              CreateEditQuestionFields.ANSWER_PREFERENCES_NUMBER_EXACT
            ]
        })}
        control={control}
        placeholder={t("numberPlaceholder")}
        type="number"
        disabled={isCanopyActive}
        onlyInteger
      />
    </div>
  );
};
