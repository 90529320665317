import { ICellRendererParams } from "ag-grid-community";
import i18next from "i18next";
import moment from "moment";
import React from "react";

import { BookedReferral, Referral } from "@arbolus-technologies/models/common";
import { ChipPlusCounter } from "@arbolus-technologies/ui/components";
import { TRANSACTION_FORMAT, toOrdinal } from "@arbolus-technologies/utils";

import { ReferralGridContext } from "../../Models/ReferralsTable";

import styles from "./ReferralDateRenderer.module.scss";

const shortlisted = i18next.t("referrals:stageDate:shortlist");
const presentedOn = i18next.t("referrals:stageDate:candidate");
const approved = i18next.t("referrals:stageDate:approved");
const booked = i18next.t("referrals:stageDate:booked");
const followUp = i18next.t("referrals:stageDate:followUp");
const rejected = i18next.t("referrals:stageDate:rejected");

interface TwoLinesRendererProps {
  top: string;
  bottom: string;
  count?: number;
}

interface ReferralDateRendererProps {
  text: string;
  date: Date;
  timezone: string;
  count?: number;
}

type DateRendererParams = ICellRendererParams<
  Referral,
  Date,
  ReferralGridContext
>;

const TwoLinesRenderer: React.FC<TwoLinesRendererProps> = ({
  top,
  bottom,
  count
}) => (
  <div className={styles.container}>
    <div className={styles.text}>
      {top}
      <br />
      {bottom}
    </div>
    {count && <ChipPlusCounter count={count} />}
  </div>
);

const ReferralDateRenderer: React.FC<ReferralDateRendererProps> = ({
  text,
  date,
  timezone,
  count
}) => (
  <TwoLinesRenderer
    top={text}
    bottom={moment.utc(date).tz(timezone).format(TRANSACTION_FORMAT)}
    count={count}
  />
);

export const ShortlistDateRenderer: React.FC<DateRendererParams> = ({
  value,
  context: { timezone }
}) => (
  <ReferralDateRenderer text={shortlisted} date={value!} timezone={timezone} />
);

export const PresentedDateRenderer: React.FC<DateRendererParams> = ({
  value,
  context: { timezone }
}) => (
  <ReferralDateRenderer text={presentedOn} date={value!} timezone={timezone} />
);

export const ApprovedDateRenderer: React.FC<DateRendererParams> = ({
  value,
  context: { timezone }
}) => (
  <ReferralDateRenderer text={approved} date={value!} timezone={timezone} />
);

export const RejectedDateRenderer: React.FC<DateRendererParams> = ({
  value,
  context: { timezone }
}) => (
  <ReferralDateRenderer text={rejected} date={value!} timezone={timezone} />
);

type BookedRendererParams = ICellRendererParams<
  BookedReferral,
  Date,
  ReferralGridContext
>;

export const BookedDateRenderer: React.FC<BookedRendererParams> = ({
  value,
  data,
  context: { timezone }
}) => {
  const text = React.useMemo(() => {
    const eventsLength = data?.events.length || 1;
    return eventsLength === 1
      ? booked
      : `${toOrdinal(eventsLength - 1)} ${followUp}`;
  }, [data]);

  return <ReferralDateRenderer text={text} date={value!} timezone={timezone} />;
};

export const CallDateRenderer: React.FC<BookedRendererParams> = ({
  value,
  data,
  context: { timezone }
}) => {
  const momentDate = moment.utc(value).tz(timezone);
  return (
    <TwoLinesRenderer
      top={momentDate.format("DD MMM")}
      bottom={momentDate.format("LT")}
      count={
        data && data?.events.length > 1 ? data.events.length - 1 : undefined
      }
    />
  );
};
