import React from "react";

import styles from "./BadgeComponent.module.scss";
interface BadgeComponentProps {
  text: string;
  type?: "new" | "beta";
}

export const BadgeComponent: React.FC<BadgeComponentProps> = ({
  text,
  type = "beta"
}) => <span className={styles[type]}>{text}</span>;
