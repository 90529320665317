import clsx from "clsx";
import { LocationDescriptorObject } from "history";
import React from "react";
import { Link } from "react-router-dom";

import { BoxButton, BoxButtonProps } from "./BoxButton";

import styles from "./BoxButton.module.scss";

interface LinkDashboardButtonProps extends BoxButtonProps {
  to: string | LocationDescriptorObject;
  trackClick?: () => void;
}

export const LinkBoxButton: React.FC<LinkDashboardButtonProps> = ({
  to,
  trackClick,
  ...props
}) => (
  <Link
    to={to}
    className={clsx("w-100", { [styles.linkBoxDisabled]: props.disabled })}
    onClick={trackClick}
  >
    <BoxButton {...props} />
  </Link>
);
