import i18next from "i18next";
import * as Yup from "yup";

import {
  DOCUMENT_NAME_REGEX,
  MAX_DOCUMENT_NAME_LENGTH
} from "../../../../../constants/validation";

export const DocumentNameValidationSchema = Yup.object({
  documentName: Yup.string()
    .required(i18next.t("documentViewPanel:documentNameRequiredError"))
    .max(
      100,
      i18next.t("documentViewPanel:documentNameMaxLengthError", {
        length: MAX_DOCUMENT_NAME_LENGTH
      })
    )
    .matches(
      DOCUMENT_NAME_REGEX,
      i18next.t("documentViewPanel:invalidDocumentNameError")
    )
});
