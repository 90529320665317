import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./AssignedAngle.module.scss";

export interface AssignedAngleProps {
  angle?: {
    title: string;
    color: string;
  } | null;
  angleClassName?: string;
}

export const AssignedAngle: React.FC<AssignedAngleProps> = ({
  angle,
  angleClassName
}) => {
  const { t } = useTranslation("assignedAngle");

  return (
    <div
      className={clsx(styles.container, angleClassName)}
      style={{
        background: `${
          angle?.color || ARBOLUS_COLORS.angleDefaultBackgroundColor
        }`,
        color: `${
          angle?.title
            ? ARBOLUS_COLORS.bColorBaseBlack
            : ARBOLUS_COLORS.angleDefaultColor
        }`
      }}
      title={angle?.title}
    >
      {angle?.title ? (
        <span>{angle?.title}</span>
      ) : (
        <span>{t("noAngleAssigned")}</span>
      )}
    </div>
  );
};
