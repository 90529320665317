import React from "react";
import { Route, Switch } from "react-router";

import {
  CANOPY_DETAILS,
  CANOPY_QUESTION_PAGE_MULTI,
  CANOPY_QUESTION_PAGE_NPS,
  CANOPY_QUESTION_PAGE_SHORT_TEXT,
  CANOPY_QUESTION_PAGE_VALUE,
  CANOPY_QUESTION_PAGE_VIDEO
} from "@arbolus-technologies/routes";

import { CanopyDetailsExpert } from "../../Pages/CanopyDetailsExpert/CanopyDetailsExpert";
import { MultiChoicePage } from "../../Pages/CanopyQuestions/MultiChoicePage";
import { NpsPage } from "../../Pages/CanopyQuestions/NpsPage";
import { ShortTextPage } from "../../Pages/CanopyQuestions/ShortTextPage";
import { ValuePage } from "../../Pages/CanopyQuestions/ValuePage";
import { VideoPage } from "../../Pages/CanopyQuestions/VideoPage";

export const QuestionRoutes: React.FC = () => (
  <Switch>
    <Route exact path={CANOPY_DETAILS} component={CanopyDetailsExpert} />
    <Route exact path={CANOPY_QUESTION_PAGE_VIDEO} component={VideoPage} />
    <Route
      exact
      path={CANOPY_QUESTION_PAGE_MULTI}
      component={MultiChoicePage}
    />
    <Route exact path={CANOPY_QUESTION_PAGE_VALUE} component={ValuePage} />
    <Route
      exact
      path={CANOPY_QUESTION_PAGE_SHORT_TEXT}
      component={ShortTextPage}
    />
    <Route exact path={CANOPY_QUESTION_PAGE_NPS} component={NpsPage} />
  </Switch>
);
