import {
  getBookmarksList,
  getBookmarksListFailure,
  getBookmarksListSuccess,
  resetBookmarksList
} from "./actions/GetBookmarksList/GetBookmarksListActions";
import {
  getCollection,
  getCollectionFailure,
  getCollectionSuccess
} from "./actions/GetCollection/GetCollectionActions";
import { renameCollection } from "./actions/RenameCollection/RenameCollectionActions";

export const BookmarksStoreActions = {
  getCollection,
  getCollectionSuccess,
  getCollectionFailure,
  renameCollection,
  getBookmarksList,
  getBookmarksListSuccess,
  getBookmarksListFailure,
  resetBookmarksList
};
