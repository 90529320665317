import { EXPERT_CANOPY_STATUS } from "@arbolus-technologies/models/canopy";

import { DO_NOT_CONTACT_STATUS } from "./compliance/enums";

/**
 * @deprecated
 * use UserModel from below that it's not polluted
 */
export interface BaseUser {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  fullName?: string;
  title?: string;
  profileImageUrl?: string;
  status?: EXPERT_CANOPY_STATUS;
}

export interface UserModel {
  id: string;
  firstName?: string;
  lastName?: string;
  profileImageUrl?: string;
}

export interface ExpertModel extends UserModel {
  expertId: string;
}

export interface ConfirmedEmailUserModel {
  id: string;
  firstName: string;
  lastName: string;
  profileImageUrl?: string;
  email: string;
}

export interface ConfirmedEmailExpertModel extends ConfirmedEmailUserModel {
  expertId: string;
  doNotContactStatus?: DO_NOT_CONTACT_STATUS;
}

// User roles
export const APP_USER_ROLES = {
  guest: "Guest",
  expert: "Expert",
  admin: "Admin", // Client Admin User
  client: "Manager",
  ciqAdmin: "CIQAdmin", // CIQ Administration
  CIQManager: "CIQManager" // Support workspace ussr role
};
