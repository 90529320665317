import { push } from "connected-react-router";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ProjectCanopySelector } from "@arbolus-technologies/stores/project-canopy-store";
import {
  InformModal,
  SectionHeader
} from "@arbolus-technologies/ui/components";

import { SurveyList } from "../SurveyList/SurveyList";

import styles from "./SurveysSummary.module.scss";

export interface SurveysSummaryProps {
  projectId: string;
  createNewSurvey: () => void;
  canopyDetailsPageRoute: (canopyId: string) => string;
  canopyExpertsRoute: (canopyId: string) => string;
  viewAllCanopiesPageRoute: () => string;
}

export const SurveysSummary: React.FC<SurveysSummaryProps> = ({
  projectId,
  createNewSurvey,
  canopyDetailsPageRoute,
  canopyExpertsRoute,
  viewAllCanopiesPageRoute
}) => {
  const [showInformModal, setShowInformModal] = useState(false);
  const canopies = useSelector(ProjectCanopySelector.canopiesSelector());
  const dispatch = useDispatch();
  const { t } = useTranslation("surveyPane");

  const handleViewAll = (): void => {
    dispatch(push(viewAllCanopiesPageRoute()));
  };

  return (
    <>
      <div className={styles.container}>
        <SurveyList
          projectId={projectId}
          createNewSurvey={createNewSurvey}
          canopyDetailsPageRoute={canopyDetailsPageRoute}
          canopyExpertsRoute={canopyExpertsRoute}
        />
        <SectionHeader
          mainTitle={t("arbolusCanopy")}
          bodyInformModal={t("bodyInformModal")}
          buttonCreate={{
            onClick: createNewSurvey,
            title: t("survey")
          }}
          buttonViewAll={{
            onClick: handleViewAll,
            disable: canopies.length === 0,
            title: t("viewAll")
          }}
          titleInformModal={t("titleInformModal")}
        />
      </div>
      <InformModal
        toggleModal={showInformModal}
        messageTitle={t("titleInformModal")}
        warningMessage={t("bodyInformModal")}
        hideModal={() => setShowInformModal(false)}
      />
    </>
  );
};
