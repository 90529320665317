import queryString from "query-string";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import {
  ProjectNxSelector,
  ProjectNxStoreActions
} from "@arbolus-technologies/stores/project";
import { Checkbox } from "@arbolus-technologies/ui/components";
import { utilService } from "@arbolus-technologies/utils";

import styles from "./DncFilter.module.scss";

export const DncFilter: React.FC = () => {
  const { t } = useTranslation("discoverPage");
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const parameters = queryString.parse(location.search);

  const hideDncExperts = useSelector(ProjectNxSelector.hideDncExperts());

  const handleToggleDncExperts = () => {
    dispatch(ProjectNxStoreActions.toggleDncExperts(!hideDncExperts));

    const nonEmptyParams = utilService.getNonEmptyQueryString(parameters);
    if (!hideDncExperts) {
      nonEmptyParams["hideDncExperts"] = `${!hideDncExperts}`;
    } else {
      delete nonEmptyParams["hideDncExperts"];
    }
    const params = new URLSearchParams(nonEmptyParams);
    history.replace({ pathname: location.pathname, search: params.toString() });
  };

  return (
    <div className={styles.checkboxContainer}>
      <Checkbox
        isChecked={hideDncExperts}
        onChange={handleToggleDncExperts}
        text={t("hideDncExperts")}
      />
    </div>
  );
};
