import React from "react";
import { useFormContext } from "react-hook-form";

import { InputRadio } from "@arbolus-technologies/ui/components";

import { SurveyQuestion } from "../../../pages/models";

export function SingleChoiceRadio({ question }: { question: SurveyQuestion }) {
  const { control } = useFormContext();

  return (
    <InputRadio
      control={control}
      name={question.fieldName}
      options={question.options!}
    />
  );
}
