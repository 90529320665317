import React from "react";

import { SlidePanel } from "@arbolus-technologies/features/common";
import { PanelId } from "@arbolus-technologies/stores/panels";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { ClientsList } from "./ClientsList";

interface ClientsListSlidePanelProps {
  userId: string;
  onUpdateListOnAssignClient: () => void;
}

export const ClientsListSlidePanel: React.FC<ClientsListSlidePanelProps> = ({
  userId,
  onUpdateListOnAssignClient
}) => (
  <SlidePanel
    panelId={PanelId.ClientList}
    width={SIDE_PANEL_SIZE._720}
    hideHeader
    noPadding
  >
    <ClientsList
      userId={userId}
      onUpdateListOnAssignClient={onUpdateListOnAssignClient}
    />
  </SlidePanel>
);
