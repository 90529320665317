import React from "react";

import { CustomSwitch, CustomSwitchType } from "./CustomSwitch";

import styles from "./LabeledSwitch.module.scss";

interface LabeledSwitchProps {
  leftTabTitle?: string;
  rightTabTitle?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  disabled?: boolean;
  type?: CustomSwitchType;
}

export const LabeledSwitch: React.FC<LabeledSwitchProps> = ({
  leftTabTitle,
  rightTabTitle,
  checked,
  type,
  ...props
}) => (
  <div className={styles.switch}>
    {leftTabTitle && (
      <span className={checked ? styles.inactive : styles.active}>
        {leftTabTitle}
      </span>
    )}
    <CustomSwitch
      name={`tab-switch_${leftTabTitle}_${rightTabTitle}`}
      checked={checked}
      type={type ?? CustomSwitchType.ALTERNATIVES}
      {...props}
    />
    {rightTabTitle && (
      <span className={checked ? styles.active : styles.inactive}>
        {rightTabTitle}
      </span>
    )}
  </div>
);
