/* cspell:disable */

import profilea from "./profiles/profilea.png";
import profileb from "./profiles/profileb.png";
import profilec from "./profiles/profilec.png";
import profiled from "./profiles/profiled.png";
import profilee from "./profiles/profilee.png";
import rectanglea from "./profiles/rectanglea.svg";
import rectangleb from "./profiles/rectangleb.svg";
import rectanglec from "./profiles/rectanglec.svg";
import rectangled from "./profiles/rectangled.svg";
import rectanglee from "./profiles/rectanglee.svg";

export {
  profilea,
  profileb,
  profilec,
  profiled,
  profilee,
  rectanglea,
  rectangleb,
  rectanglec,
  rectangled,
  rectanglee
};
