import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import {
  CIQError,
  CanopyService,
  ErrorResponse,
  ToasterService
} from "@arbolus-technologies/api";
import { CANOPY_STATUS } from "@arbolus-technologies/models/canopy";
import {
  CANOPY_MOBILE_MENU_ROUTE,
  CANOPY_ROUTE
} from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  CanopyV2Selector,
  CanopyV2StoreActions
} from "@arbolus-technologies/stores/canopy-V2";
import {
  ActionComponent,
  ActionComponentType,
  SurveyDocs
} from "@arbolus-technologies/ui/canopy";
import { Header } from "@arbolus-technologies/ui/canopy-panels";
import { ArbolusModal, HR, InfoBox } from "@arbolus-technologies/ui/components";

import { ArchiveCanopyButton } from "../../Components/Buttons/ArchiveCanopyButton/ArchiveCanopyButton";
import { EditDetailsButton } from "../../Components/Buttons/EditDetailsButton/EditDetailsButton";
import { PauseResumeCanopyButton } from "../../Components/PauseResumeCanopyButton/PauseResumeCanopyButton";

import styles from "./Details.module.scss";

interface DetailsProps {
  notificationService?: ToasterService;
  canopyService?: typeof CanopyService;
}

export const Details: React.FC<DetailsProps> = ({
  notificationService = new ToasterService(),
  canopyService = CanopyService
}) => {
  const { t } = useTranslation("canopyV2");
  const dispatch = useDispatch();
  const history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCloningCanopy, setIsCloningCanopy] = useState(false);
  const canopyData = useSelector(CanopyV2Selector.canopyData());
  const isAdmin = useSelector(CacheSelector.isAdmin());

  const hasComplianceCheck = canopyData?.hasComplianceCheck;

  const copyInvitationLink = () => {
    if (canopyData) {
      dispatch(
        CanopyV2StoreActions.copyCanopyInvitationLinkToClipboard(
          canopyData.id,
          t("copyLinkSuccess")
        )
      );
    }
  };

  const title = hasComplianceCheck
    ? t("complianceCheckActivated")
    : t("complianceCheckNotActivated");
  const description = hasComplianceCheck
    ? t("answersWillBeReviewed")
    : t("complianceCheckNotActivatedDescription");

  const showDocsAndLinks =
    canopyData?.documents &&
    canopyData?.links &&
    (canopyData?.documents.length > 0 || canopyData?.links.length > 0);

  const renderHeaderButton = (): JSX.Element | undefined => {
    if (isAdmin && canopyData) {
      return (
        <EditDetailsButton
          canopyId={canopyData.id}
          status={canopyData.status}
        />
      );
    }
  };

  const handleCloneCanopy = () => {
    setIsCloningCanopy(true);
    canopyData &&
      canopyService.cloneCanopy(canopyData?.id).subscribe(
        (response) => {
          notificationService.showSuccess(t("copiedSuccessfully"));
          setIsCloningCanopy(false);
          history.push(CANOPY_ROUTE(response.id));
          setIsModalOpen(false);
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showError(error.message);
          setIsCloningCanopy(false);
        }
      );
  };

  const icon = hasComplianceCheck ? "check" : "error_outline";

  return (
    <>
      <Header
        title={t("details")}
        backButton={
          isMobile && canopyData
            ? {
                onClick: () =>
                  history.push(CANOPY_MOBILE_MENU_ROUTE(canopyData.id)),
                title: t("back")
              }
            : undefined
        }
        actionButton={renderHeaderButton()}
      />
      <div className={styles.mainContainer}>
        <div className={styles.overview}>{canopyData?.overview}</div>
        {showDocsAndLinks && (
          <SurveyDocs
            documents={canopyData.documents!}
            isCanopyDetails
            links={canopyData.links!}
          />
        )}
        {isAdmin && canopyData && (
          <InfoBox
            title={title}
            description={description}
            iconName={icon}
            infoBoxType={
              canopyData.hasComplianceCheck ? "notification" : "error"
            }
          />
        )}
        {!isAdmin && canopyData && canopyData.hasComplianceCheck && (
          <InfoBox
            title={title}
            description={description}
            iconName={icon}
            infoBoxType={"notification"}
          />
        )}
        <HR margin={{ top: 3, bottom: 3 }} />
        <div className={styles.getResponses}>
          <div className={styles.getResponsesTitle}>{t("getResponses")}</div>
          <ActionComponent
            type={ActionComponentType.copyLink}
            onClick={copyInvitationLink}
          />
          {isAdmin && (
            <>
              <ActionComponent
                type={ActionComponentType.cloneCanopy}
                onClick={() => setIsModalOpen(true)}
              />
              <ArbolusModal
                title={t("cloneCanopyTitle")}
                subtitle={t("cloneCanopySubtitle")}
                toggle={() => setIsModalOpen(!isModalOpen)}
                isOpen={isModalOpen}
                rightButton={{
                  type: "confirmation",
                  onClick: handleCloneCanopy,
                  text: t("cloneCanopyConfirmation"),
                  disabled: isCloningCanopy
                }}
                leftButton={{
                  type: "tertiary",
                  onClick: () => setIsModalOpen(false),
                  text: t("cloneCanopyCancel")
                }}
              />
            </>
          )}
        </div>

        {isAdmin &&
          (canopyData?.status === CANOPY_STATUS.ACTIVE ||
            canopyData?.status === CANOPY_STATUS.PAUSED) && (
            <div className={styles.pauseContainer}>
              <PauseResumeCanopyButton
                status={canopyData.status}
                canopyId={canopyData.id}
              />
            </div>
          )}
        {isAdmin && canopyData?.status !== CANOPY_STATUS.ARCHIVED && (
          <div className="d-flex justify-content-center w-100">
            <ArchiveCanopyButton
              canopyId={canopyData?.id ?? ""}
              canopyTitle={canopyData?.title ?? ""}
            />
          </div>
        )}
      </div>
    </>
  );
};
