import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "arbolus-ui-components";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FormGroup, Label } from "reactstrap";

import { FormConstraints } from "@arbolus-technologies/models/canopy";
import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { HR, InputController } from "@arbolus-technologies/ui/components";

import { createNpsExpertSchema } from "./NpsExpertSchema";

import styles from "./NpsForExpertProvider.module.scss";

interface NpsForExpertProviderProps {
  npsValue: number | null;
  handleOnSubmit: (data: MultiFormData) => void;
  isAnswered?: boolean;
  isSaving?: boolean;
  canopyIsCompleted?: boolean;
}

export interface MultiFormData {
  npsValue: number | null;
}

export const NpsForExpertProvider: React.FC<NpsForExpertProviderProps> = ({
  npsValue,
  handleOnSubmit,
  isAnswered,
  isSaving,
  canopyIsCompleted
}) => {
  const { t } = useTranslation("canopyExpert");

  const loggedInExpert = useSelector(CacheSelector.loggedInUser());
  const isDnc = loggedInExpert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC;
  const isDisabled = isDnc || canopyIsCompleted;

  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors, isValid, isValidating }
  } = useForm<MultiFormData>({
    resolver: yupResolver(createNpsExpertSchema),
    mode: "onChange",
    defaultValues: {
      npsValue
    }
  });

  useEffect(
    () =>
      // We use this to skip the trigger in first render
      () => {
        trigger();
      },
    [isValidating, trigger]
  );

  return (
    <div className={styles.container}>
      <div className={clsx(styles.message, errors.npsValue && styles.red)}>
        {t("npsMessage")}
      </div>
      <FormGroup className={styles.formContainer}>
        <InputController
          name="npsValue"
          control={control}
          placeholder={`${FormConstraints.MIN_NPS_VALUE} - ${FormConstraints.MAX_NPS_VALUE}`}
          type="number"
          classnames={styles.input}
          onlyInteger
          disabled={isDisabled}
        />
        {errors.npsValue && (
          <Label className={clsx("invalid-feedback", styles.error)}>
            {errors.npsValue.message}
          </Label>
        )}
      </FormGroup>
      <HR margin={{ top: 3, bottom: 5 }} />
      {!isDisabled && (
        <div className={styles.buttonContainer}>
          <Button
            type="primary"
            text={isAnswered ? t("update") : t("save")}
            disabled={!isValid || isSaving}
            onClick={handleSubmit(handleOnSubmit)}
          />
        </div>
      )}
    </div>
  );
};
