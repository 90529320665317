import i18next from "i18next";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { EXPERT_CANOPY_STATUS } from "@arbolus-technologies/models/canopy";
import {
  ArbolusDropdown,
  SearchInput
} from "@arbolus-technologies/ui/components";

import styles from "./CanopyListFilters.module.scss";

export type CanopyListFilterParams = {
  searchTerm: string;
  status?: EXPERT_CANOPY_STATUS[];
};

const filterStatusOptions: {
  label: string;
  value: EXPERT_CANOPY_STATUS[];
}[] = [
  {
    label: i18next.t("expertCanopyList:all"),
    value: [
      EXPERT_CANOPY_STATUS.IN_REVIEW,
      EXPERT_CANOPY_STATUS.REJECTED,
      EXPERT_CANOPY_STATUS.COMPLETE,
      EXPERT_CANOPY_STATUS.INCOMPLETE
    ]
  },
  {
    label: i18next.t("expertCanopyList:new"),
    value: [EXPERT_CANOPY_STATUS.INCOMPLETE]
  },
  {
    label: i18next.t("expertCanopyList:submitted"),
    value: [
      EXPERT_CANOPY_STATUS.IN_REVIEW,
      EXPERT_CANOPY_STATUS.REJECTED,
      EXPERT_CANOPY_STATUS.COMPLETE
    ]
  }
];

interface CanopyListFiltersProps {
  onFilterChange: (filterParams: CanopyListFilterParams) => void;
}

export const CanopyListFilters: React.FC<CanopyListFiltersProps> = ({
  onFilterChange
}) => {
  const { t } = useTranslation("expertCanopyList");
  const [params, setParams] = useState<
    CanopyListFilterParams & { label: string }
  >({
    searchTerm: "",
    label: t("All")
  });

  const onQueryChange = useCallback(
    (searchTerm: string) => {
      setParams((prev) => ({ ...prev, searchTerm }));
      onFilterChange({ ...params, searchTerm });
    },
    [onFilterChange, params]
  );

  const onFilterButton = useCallback(
    ({ label, value }: { label: string; value: EXPERT_CANOPY_STATUS[] }) => {
      setParams((prev) => ({ ...prev, label, status: value }));
      onFilterChange({ ...params, status: value });
    },
    [onFilterChange, params]
  );

  return (
    <div className={styles.canopyFilter}>
      <div className={styles.search}>
        <SearchInput
          onQueryChange={onQueryChange}
          minimumSearchLength={3}
          debouncingTime={500}
          isDebounced
          hasSearchIcon
          placeholder={t("searchPlaceholder")}
        />
      </div>

      <ArbolusDropdown
        options={filterStatusOptions as any}
        onSelect={onFilterButton}
        selectedLabel={params.label}
      />
    </div>
  );
};
