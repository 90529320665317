import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useIsChatAvailable } from "@arbolus-technologies/features/common";
import {
  DO_NOT_CONTACT_STATUS,
  REFERRAL_SUB_STATE
} from "@arbolus-technologies/models/common";
import {
  PROJECT_APPLICATION_ROUTE,
  PROJECT_CALENDAR_ROUTE,
  PROJECT_CHAT_BASE_ROUTE,
  PROJECT_FILES_ROUTE,
  PROJECT_ROUTE
} from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  BoxWithChildren,
  ChevronButton,
  ColoredBadge
} from "@arbolus-technologies/ui/components";

import { PROJECT_STATUS_COLORS } from "../../helpers/constants";

import styles from "./ProjectCard.module.scss";

interface ProjectCardProps {
  id: string;
  title: string;
  activeReferral: boolean;
  hasUnreadMessages: boolean;
  applicationStatus: REFERRAL_SUB_STATE;
}

export const ProjectCard: React.FC<ProjectCardProps> = ({
  id,
  title,
  activeReferral,
  hasUnreadMessages,
  applicationStatus
}) => {
  const { t } = useTranslation("expertProjectList");
  const isChatAvailable = useIsChatAvailable();

  const loggedInExpert = useSelector(CacheSelector.loggedInUser());
  const isDnc = loggedInExpert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC;

  const { PENDING, ACCEPT } = REFERRAL_SUB_STATE;
  const isNewApplication = applicationStatus === PENDING;
  const isApplied = applicationStatus === ACCEPT;

  let projectStatusDetails = {
    label: "approved",
    buttonText: "details",
    route: PROJECT_ROUTE(id).pathname!
  };

  if (!activeReferral) {
    if (isNewApplication) {
      projectStatusDetails = {
        label: "new",
        buttonText: "startApplication",
        route: PROJECT_APPLICATION_ROUTE(id)
      };
    } else if (isApplied) {
      projectStatusDetails = {
        label: "pendingApproval",
        buttonText: "details",
        route: PROJECT_APPLICATION_ROUTE(id)
      };
    }
  }
  const colorValue =
    PROJECT_STATUS_COLORS[
      projectStatusDetails.label as keyof typeof PROJECT_STATUS_COLORS
    ];

  return (
    <BoxWithChildren classnames={styles.card}>
      <>
        <div className={styles.header}>
          <ColoredBadge
            text={t(projectStatusDetails.label)}
            backgroundColor={colorValue}
          />
          {activeReferral && (
            <div className={styles.links}>
              {isChatAvailable && (
                <Link
                  to={PROJECT_CHAT_BASE_ROUTE(id)}
                  className={clsx({
                    "notification-active": hasUnreadMessages
                  })}
                  title={t("chat")}
                >
                  <Icon
                    name="forum"
                    fontSize="24px"
                    color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
                  />
                  {hasUnreadMessages && <span className="notification-dot" />}
                </Link>
              )}
              <Link to={PROJECT_CALENDAR_ROUTE(id)} title={t("calendar")}>
                <Icon
                  name="calendar_today"
                  fontSize="24px"
                  color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
                />
              </Link>
              {!isDnc && (
                <Link to={PROJECT_FILES_ROUTE(id)} title={t("documents")}>
                  <Icon
                    name="description"
                    fontSize="24px"
                    color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
                  />
                </Link>
              )}
            </div>
          )}
        </div>

        <Link to={projectStatusDetails.route}>
          <h4 className={styles.title} title={title}>
            {title}
          </h4>
        </Link>
        <div className={styles.footer}>
          <ChevronButton
            to={projectStatusDetails.route}
            text={t(projectStatusDetails.buttonText)}
            icon={{ direction: "right", position: "end" }}
          />
        </div>
      </>
    </BoxWithChildren>
  );
};
