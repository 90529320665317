import React, { useCallback, useEffect, useState } from "react";

import {
  AnglesService,
  DefaultToasterService,
  ToasterService
} from "@arbolus-technologies/api";
import { SelectableCardList } from "@arbolus-technologies/features/common";
import { AngleModel } from "@arbolus-technologies/models/common";

import styles from "./AngleStep.module.scss";

interface AngleStepProps {
  projectId: string;
  selectedAngle?: AngleModel;
  angleService?: typeof AnglesService;
  toasterService?: ToasterService;
  setSelectedAngle: (angle: AngleModel) => void;
}
export const AngleStep: React.FC<AngleStepProps> = ({
  projectId,
  selectedAngle,
  angleService = AnglesService,
  toasterService = DefaultToasterService,
  setSelectedAngle
}) => {
  const [angles, setAngles] = useState<AngleModel[]>();

  useEffect(() => {
    angleService?.getAnglesCards(projectId, 0, 50).subscribe({
      next: ({ items }) => setAngles(items),
      error: toasterService.showApiErrors
    });
  }, [projectId, angleService, toasterService.showApiErrors]);

  const onSelectAngle = useCallback(
    (id: string) => {
      setSelectedAngle(angles!.find((angle) => angle.id === id)!);
    },
    [angles, setSelectedAngle]
  );

  return (
    <div className={styles.listContainer}>
      {angles && (
        <SelectableCardList
          items={angles.map((angle) => ({
            key: angle.id,
            text: angle.title,
            iconName: "zoom_in_map",
            iconCustomStyle: { backgroundColor: angle.color }
          }))}
          filters={selectedAngle?.id ? [selectedAngle.id] : []}
          onClick={onSelectAngle}
        />
      )}
    </div>
  );
};
