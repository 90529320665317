import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ProjectNotificationSubscription,
  ProjectService,
  ProjectSettings,
  ToasterService
} from "@arbolus-technologies/api";
import { FormValue } from "@arbolus-technologies/models/common";
import {
  CheckboxSimpleForm,
  LoaderOrComponent
} from "@arbolus-technologies/ui/components";

import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { useSelector } from "react-redux";
import styles from "./ProjectNotificationsForm.module.scss";

interface ProjectNotificationsFormProps {
  projectId: string;
  notificationService?: ToasterService;
  projectService?: typeof ProjectService;
  onCancel: () => void;
}

const validProjectSettings = [
  "Messages",
  "SharedDocs",
  "NewReferrals",
  "Applications",
  "Approvals",
  "NewJoins",
  "Surveys",
  "clientNotificationsEnabled"
];

export const ProjectNotificationsForm: React.FC<
  ProjectNotificationsFormProps
> = ({
  projectId,
  notificationService = DefaultToasterService,
  projectService = ProjectService,
  onCancel
}) => {
  const formName = "projectNotificationsForm";
  const { t } = useTranslation(formName);
  const isAdmin = useSelector(CacheSelector.isAdmin());

  const [isLoading, setIsLoading] = useState(true);
  const [projectSettings, setProjectSettings] = useState<ProjectSettings>();

  const mapFormValues = useCallback(() => {
    const mappedValues: FormValue[] = Object.entries(
      projectSettings!.notificationSubscription
    )
      .filter(([propertyName]) => validProjectSettings.includes(propertyName))
      .map(([propertyName, value]) => ({
        label: propertyName,
        active: value as boolean,
        description: t(`${propertyName}Description`)
      }));

    // Explicitly add clientNotificationsEnabled as the first item
    if (
      validProjectSettings.includes("clientNotificationsEnabled") &&
      isAdmin
    ) {
      mappedValues.unshift({
        label: "clientNotificationsEnabled",
        active: projectSettings!.clientNotificationsEnabled,
        description: t("clientNotificationsEnabledDescription")
      });
    }

    return mappedValues;
  }, [t, projectSettings]);

  const onSubmit = useCallback(
    (formValues: FormValue[]) => {
      let clientNotificationsEnabled: boolean | undefined;

      const updatedSettings = formValues.reduce((acc, formValue) => {
        if (formValue.label === "clientNotificationsEnabled") {
          clientNotificationsEnabled = formValue.active;
        } else {
          acc[formValue.label as keyof ProjectNotificationSubscription] =
            formValue.active;
        }
        return acc;
      }, {} as ProjectNotificationSubscription);

      projectService
        .updateProjectSettings(projectId, {
          notificationSubscription: updatedSettings,
          pushNotifications: projectSettings!.pushNotifications,
          clientNotificationsEnabled
        })
        .subscribe(
          () => {
            notificationService.showSuccess(t("updateSuccess"));
            setProjectSettings({
              ...projectSettings!,
              notificationSubscription: updatedSettings,
              clientNotificationsEnabled: clientNotificationsEnabled!
            });
          },
          (error: ErrorResponse<CIQError>) => {
            notificationService.showApiErrors(error);
          }
        );
    },
    [notificationService, projectId, projectService, projectSettings, t]
  );

  useEffect(() => {
    setIsLoading(true);
    projectService.getProjectSettings(projectId).subscribe(
      (response) => {
        setProjectSettings(response);
        setIsLoading(false);
      },
      (error) => notificationService.showError(error)
    );
  }, [projectService, projectId, notificationService]);

  return (
    <>
      <div className={styles.textContainer}>
        <span className={styles.subtitle}>{t("emails")}</span>
        <div>{t("emailMessage")}</div>
      </div>
      <LoaderOrComponent isLoading={isLoading}>
        {projectSettings && (
          <CheckboxSimpleForm
            allowUpdate={true}
            name={formName}
            formValues={mapFormValues()}
            onSave={onSubmit}
            onCancel={onCancel}
          />
        )}
      </LoaderOrComponent>
    </>
  );
};
