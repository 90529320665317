import { Handlers, createReducer } from "reduxsauce";

import { EXPERT_FOLLOW_UP_STEPS } from "@arbolus-technologies/models/expert";

import {
  EXIT_FROM_EXPERT,
  GET_EXPERT,
  GET_EXPERT_SUCCESS,
  GetExpertAction,
  GetExpertSuccessAction,
  SET_EXPERT_FOLLOW_UP_STEP,
  SetExpertFollowUpStepAction
} from "./actions/ExpertActionTypes";
import { ExpertNxReducerState } from "./models/definitions";

export const initialState: ExpertNxReducerState = {
  isExpertLoading: false,
  error: null,
  expertFollowUpStep: EXPERT_FOLLOW_UP_STEPS.ENGAGE_EXPERT
};

const handleGetExpert = (
  state = initialState,
  { payload }: GetExpertAction
): ExpertNxReducerState => ({
  ...state,
  ...initialState,
  isExpertLoading: payload.initial
});

const handleGetExpertSuccess = (
  state = initialState,
  { payload }: GetExpertSuccessAction
): ExpertNxReducerState => ({
  ...state,
  isExpertLoading: false,
  expertDetails: { ...payload.expertDetails },
  expertId: payload.expertId
});

const handleExitFromExpert = (): ExpertNxReducerState => ({
  ...initialState
});

export const handleSetExpertFollowUpStep = (
  state = initialState,
  { payload }: SetExpertFollowUpStepAction
): ExpertNxReducerState => ({
  ...state,
  expertFollowUpStep: payload.step
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handlers: Handlers<ExpertNxReducerState, any> = {
  [GET_EXPERT]: handleGetExpert,
  [GET_EXPERT_SUCCESS]: handleGetExpertSuccess,
  [EXIT_FROM_EXPERT]: handleExitFromExpert,
  [SET_EXPERT_FOLLOW_UP_STEP]: handleSetExpertFollowUpStep
};

export const ExpertNxReducer = createReducer(initialState, handlers);
