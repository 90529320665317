import {
  AllCanopyAnswersByExpert,
  CIQError,
  CanopyAnswersByQuestion,
  CanopyVideoAnswers,
  ErrorResponse,
  ExpertInACanopy
} from "@arbolus-technologies/api";

import {
  CanopyClientsActiveTab,
  CanopyClientsAnswerState
} from "../models/enums";
import {
  CANOPY_CLIENT_ACTIVE_TAB,
  CANOPY_CLIENT_ANSWER_STATE,
  COPY_CANOPY_INVITATION_LINK_TO_CLIPBOARD,
  CanopyClientActiveTabAction,
  CanopyClientAnswerStateAction,
  CopyCanopyInvitationLinkToClipboardAction,
  EDIT_VIDEO_TRANSCRIPT,
  EDIT_VIDEO_TRANSCRIPT_FAILURE,
  EDIT_VIDEO_TRANSCRIPT_SUCCESS,
  EditVideoTranscriptAction,
  EditVideoTranscriptFailureAction,
  EditVideoTranscriptSuccessAction,
  GET_ANSWERS_BY_QUESTION,
  GET_ANSWERS_BY_QUESTION_FAILURE,
  GET_ANSWERS_BY_QUESTION_SUCCESS,
  GET_CANOPY_ANSWERS_BY_EXPERT,
  GET_CANOPY_ANSWERS_BY_EXPERT_FAILURE,
  GET_CANOPY_ANSWERS_BY_EXPERT_SUCCESS,
  GET_CANOPY_CLIENT_EXPERTS,
  GET_CANOPY_CLIENT_EXPERTS_FAILURE,
  GET_CANOPY_CLIENT_EXPERTS_SUCCESS,
  GET_IS_EXPERT_IN_PROJECT,
  GET_IS_EXPERT_IN_PROJECT_FAILURE,
  GET_IS_EXPERT_IN_PROJECT_SUCCESS,
  GET_VIDEO_TRANSCRIPT,
  GET_VIDEO_TRANSCRIPT_FAILURE,
  GET_VIDEO_TRANSCRIPT_SUCCESS,
  GetApiAnswersByExpertsAction,
  GetApiAnswersByExpertsFailureAction,
  GetApiAnswersByExpertsSuccessAction,
  GetCanopyAnswersByExpertAction,
  GetCanopyAnswersByExpertFailureAction,
  GetCanopyAnswersByExpertSuccessAction,
  GetCanopyClientExpertsAction,
  GetCanopyClientExpertsFailureAction,
  GetCanopyClientExpertsSuccessAction,
  GetIsExpertInProjectAction,
  GetIsExpertInProjectFailureAction,
  GetIsExpertInProjectSuccessAction,
  GetVideoTranscriptAction,
  GetVideoTranscriptFailureAction,
  GetVideoTranscriptSuccessAction,
  RESET_ANSWER_EXPERT,
  RESET_CANOPY_ANSWERS_BY_EXPERT,
  RESET_SELECTED_QUESTION_AND_EXPERT,
  RESET_VIDEO_TRANSCRIPT,
  RESET_VIDEO_TRANSCRIPT_FAILURE,
  ResetAnswerExpertAction,
  ResetCanopyAnswersByExpertAction,
  ResetSelectedQuestionAndExpertAction,
  ResetVideoTranscriptAction,
  ResetVideoTranscriptFailureAction,
  SET_CANOPY_ANSWER_SELECTED,
  SET_CANOPY_ANSWER_SELECTED_BY_EXPERTS,
  SET_CANOPY_ANSWER_SELECTED_BY_EXPERTS_FAILURE,
  SET_CANOPY_ANSWER_SELECTED_BY_EXPERTS_SUCCESS,
  SET_COMPLIANCE_REVIEW_STATUS,
  SET_SELECTED_EXPERT_SUCCESS,
  SHOW_RESPONSES,
  SHOW_RIGHT_COMPONENT,
  SetCanopyAnswerSelectedAction,
  SetCanopyAnswerSelectedByExpertsAction,
  SetCanopyAnswerSelectedByExpertsFailureAction,
  SetCanopyAnswerSelectedByExpertsSuccessAction,
  SetComplianceReviewStatusAction,
  SetSelectedExpertSuccessAction,
  ShowResponsesAction,
  ShowRightComponentAction
} from "./CanopyClientsActionTypes";

// Change CanopyAnswerState
export const changeClientAnswerState = (
  state: CanopyClientsAnswerState
): CanopyClientAnswerStateAction => ({
  type: CANOPY_CLIENT_ANSWER_STATE,
  payload: {
    state
  }
});

export const changeClientActiveTab = (
  activeTab: CanopyClientsActiveTab
): CanopyClientActiveTabAction => ({
  type: CANOPY_CLIENT_ACTIVE_TAB,
  payload: {
    activeTab
  }
});

// GET ANSWERS BY EXPERT

export const getAnswersByQuestion = (
  questionId: string,
  offset: number
): GetApiAnswersByExpertsAction => ({
  type: GET_ANSWERS_BY_QUESTION,
  payload: {
    questionId,
    offset
  }
});

export const getAnswersByQuestionSuccess = (
  questionId: string,
  answersByQuestion: CanopyAnswersByQuestion
): GetApiAnswersByExpertsSuccessAction => ({
  type: GET_ANSWERS_BY_QUESTION_SUCCESS,
  payload: {
    questionId,
    answersByQuestion
  }
});

export const getAnswersByQuestionFailure = (
  error: ErrorResponse<CIQError>
): GetApiAnswersByExpertsFailureAction => ({
  type: GET_ANSWERS_BY_QUESTION_FAILURE,
  error
});

export const resetAnswerExpert = (): ResetAnswerExpertAction => ({
  type: RESET_ANSWER_EXPERT
});

export const ResetCanopyAnswersByExpert =
  (): ResetCanopyAnswersByExpertAction => ({
    type: RESET_CANOPY_ANSWERS_BY_EXPERT
  });

// SHOW RESPONSES

export const showResponses = (
  canopyId: string,
  showOnlyComplete: boolean
): ShowResponsesAction => ({
  type: SHOW_RESPONSES,
  payload: {
    canopyId,
    showOnlyComplete
  }
});

// SET CANOPY ANSWER SELECTED

export const setCanopyAnswerSelected = (
  answer: CanopyVideoAnswers
): SetCanopyAnswerSelectedAction => ({
  type: SET_CANOPY_ANSWER_SELECTED,
  payload: {
    answer
  }
});

// GET VIDEO TRANSCRIPT

export const getVideoTranscript = (
  answerId: string,
  transcriptWarning: string
): GetVideoTranscriptAction => ({
  type: GET_VIDEO_TRANSCRIPT,
  payload: {
    answerId,
    transcriptWarning
  }
});

export const getVideoTranscriptSuccess = (
  transcript: string,
  answerId: string,
  isSmartTranscript: boolean
): GetVideoTranscriptSuccessAction => ({
  type: GET_VIDEO_TRANSCRIPT_SUCCESS,
  payload: {
    transcript,
    answerId,
    isSmartTranscript
  }
});

export const getVideoTranscriptFailure = (
  error: ErrorResponse<CIQError>
): GetVideoTranscriptFailureAction => ({
  type: GET_VIDEO_TRANSCRIPT_FAILURE,
  error
});

export const editVideoTranscript = (
  answerId: string,
  content: string
): EditVideoTranscriptAction => ({
  type: EDIT_VIDEO_TRANSCRIPT,
  payload: {
    answerId,
    content
  }
});

export const editVideoTranscriptSuccess = (
  transcript: string,
  answerId: string,
  isSmartTranscript: boolean
): EditVideoTranscriptSuccessAction => ({
  type: EDIT_VIDEO_TRANSCRIPT_SUCCESS,
  payload: {
    transcript,
    answerId,
    isSmartTranscript
  }
});

export const editVideoTranscriptFailure = (
  error: ErrorResponse<CIQError>
): EditVideoTranscriptFailureAction => ({
  type: EDIT_VIDEO_TRANSCRIPT_FAILURE,
  payload: { error }
});

export const resetVideoTranscript = (
  answerId: string
): ResetVideoTranscriptAction => ({
  type: RESET_VIDEO_TRANSCRIPT,
  payload: {
    answerId
  }
});

export const resetVideoTranscriptFailure = (
  error: ErrorResponse<CIQError>
): ResetVideoTranscriptFailureAction => ({
  type: RESET_VIDEO_TRANSCRIPT_FAILURE,
  payload: { error }
});

// GET Experts list who answered canopy
export const getCanopyClientExperts = (
  canopyId: string,
  onlyComplete: boolean
): GetCanopyClientExpertsAction => ({
  type: GET_CANOPY_CLIENT_EXPERTS,
  payload: {
    canopyId,
    onlyComplete
  }
});

export const getCanopyClientExpertsSuccess = (
  canopyExperts: ExpertInACanopy[]
): GetCanopyClientExpertsSuccessAction => ({
  type: GET_CANOPY_CLIENT_EXPERTS_SUCCESS,
  payload: {
    canopyExperts
  }
});

export const getCanopyClientExpertsFailure = (
  error: ErrorResponse<CIQError>
): GetCanopyClientExpertsFailureAction => ({
  type: GET_CANOPY_CLIENT_EXPERTS_FAILURE,
  error
});

// GET ALL ANSWERS BY EXPERT

export const getAllAnswersByExpert = (
  canopyId: string,
  expertId: string,
  offset: number,
  onlyComplete: boolean
): GetCanopyAnswersByExpertAction => ({
  type: GET_CANOPY_ANSWERS_BY_EXPERT,
  payload: {
    canopyId,
    expertId,
    offset,
    onlyComplete
  }
});

export const getAllAnswersByExpertSuccess = (
  answersByExpert: AllCanopyAnswersByExpert,
  canopyId: string,
  expertId: string
): GetCanopyAnswersByExpertSuccessAction => ({
  type: GET_CANOPY_ANSWERS_BY_EXPERT_SUCCESS,
  payload: {
    answersByExpert,
    canopyId,
    expertId
  }
});

export const setSelectedExpertSuccess = (
  ExpertInACanopy: ExpertInACanopy[],
  expertId: string
): SetSelectedExpertSuccessAction => ({
  type: SET_SELECTED_EXPERT_SUCCESS,
  payload: {
    ExpertInACanopy,
    expertId
  }
});

export const getAllAnswersByExpertFailure = (
  error: ErrorResponse<CIQError>
): GetCanopyAnswersByExpertFailureAction => ({
  type: GET_CANOPY_ANSWERS_BY_EXPERT_FAILURE,
  error
});

export const resetSelectedQuestionAndExpert =
  (): ResetSelectedQuestionAndExpertAction => ({
    type: RESET_SELECTED_QUESTION_AND_EXPERT
  });

export const copyCanopyInvitationLinkToClipboard = (
  canopyId: string,
  successMessage: string
): CopyCanopyInvitationLinkToClipboardAction => ({
  type: COPY_CANOPY_INVITATION_LINK_TO_CLIPBOARD,
  payload: {
    canopyId,
    successMessage
  }
});

export const setCanopyAnswerSelectedByExperts = (
  questionId: string,
  answerId: string
): SetCanopyAnswerSelectedByExpertsAction => ({
  type: SET_CANOPY_ANSWER_SELECTED_BY_EXPERTS,
  payload: {
    questionId,
    answerId
  }
});

export const setCanopyAnswerSelectedByExpertsSuccess = (
  answerId: string,
  answersByExpert: CanopyAnswersByQuestion
): SetCanopyAnswerSelectedByExpertsSuccessAction => ({
  type: SET_CANOPY_ANSWER_SELECTED_BY_EXPERTS_SUCCESS,
  payload: {
    answerId,
    answersByExpert
  }
});

export const setCanopyAnswerSelectedByExpertsFailure = (
  error: ErrorResponse<CIQError>
): SetCanopyAnswerSelectedByExpertsFailureAction => ({
  type: SET_CANOPY_ANSWER_SELECTED_BY_EXPERTS_FAILURE,
  error
});

export const showRightComponent = (
  showRightComponent: boolean
): ShowRightComponentAction => ({
  type: SHOW_RIGHT_COMPONENT,
  payload: {
    showRightComponent
  }
});

// Check if expert is in a project
export const getIsExpertInProject = (
  projectId: string,
  expertId: string
): GetIsExpertInProjectAction => ({
  type: GET_IS_EXPERT_IN_PROJECT,
  payload: {
    projectId,
    expertId
  }
});

export const getIsExpertInProjectSuccess = (
  hasExpertClientVisibleReferral: boolean
): GetIsExpertInProjectSuccessAction => ({
  type: GET_IS_EXPERT_IN_PROJECT_SUCCESS,
  payload: {
    hasExpertClientVisibleReferral
  }
});

export const getIsExpertInProjectFailure = (
  error: ErrorResponse<CIQError>
): GetIsExpertInProjectFailureAction => ({
  type: GET_IS_EXPERT_IN_PROJECT_FAILURE,
  error
});

export const setComplianceReviewStatus = (
  canopyId: string,
  expertId: string,
  hasPassedCompliance: boolean
): SetComplianceReviewStatusAction => ({
  type: SET_COMPLIANCE_REVIEW_STATUS,
  payload: {
    canopyId,
    expertId,
    hasPassedCompliance
  }
});

export const CanopyClientStoreActions = {
  changeClientAnswerState,
  changeClientActiveTab,
  getAnswersByQuestion,
  getAnswersByQuestionSuccess,
  getAnswersByQuestionFailure,
  resetAnswerExpert,
  setCanopyAnswerSelected,
  getVideoTranscript,
  getVideoTranscriptSuccess,
  getVideoTranscriptFailure,
  editVideoTranscript,
  editVideoTranscriptSuccess,
  editVideoTranscriptFailure,
  resetVideoTranscript,
  resetVideoTranscriptFailure,
  getCanopyClientExperts,
  getCanopyClientExpertsSuccess,
  getCanopyClientExpertsFailure,
  getAllAnswersByExpert,
  getAllAnswersByExpertSuccess,
  setSelectedExpertSuccess,
  getAllAnswersByExpertFailure,
  resetSelectedQuestionAndExpert,
  copyCanopyInvitationLinkToClipboard,
  showResponses,
  setCanopyAnswerSelectedByExperts,
  setCanopyAnswerSelectedByExpertsSuccess,
  setCanopyAnswerSelectedByExpertsFailure,
  showRightComponent,
  getIsExpertInProject,
  getIsExpertInProjectSuccess,
  getIsExpertInProjectFailure,
  setComplianceReviewStatus,
  ResetCanopyAnswersByExpert
};
