import React from "react";
import Media, { QueryResults } from "react-media";
import { Subtract } from "utility-types";

import { APP_DEVICE_MEDIA_QUERIES } from "../constants/UiConstants";

export interface CIQMediaQueryResults {
  large: boolean;
  medium: boolean;
  small: boolean;
}

export interface InjectedDeviceMonitorProps {
  matches: QueryResults<CIQMediaQueryResults>;
}

export const withDeviceMonitor = <P extends InjectedDeviceMonitorProps>(
  Component: React.ComponentType<P>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): React.ComponentType<any> =>
  class DeviceMonitor extends React.PureComponent<
    Subtract<P, InjectedDeviceMonitorProps>
  > {
    render(): JSX.Element {
      return (
        <div>
          <Media queries={APP_DEVICE_MEDIA_QUERIES}>
            {(matches): JSX.Element => (
              <Component {...(this.props as P)} matches={matches} />
            )}
          </Media>
        </div>
      );
    }
  };
