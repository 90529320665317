import i18next from "i18next";
import * as Yup from "yup";

import {
  INCIDENT_CATEGORY,
  INCIDENT_FORM,
  INCIDENT_PRIORITY
} from "../../../enums";
import { IncidentFormInterface } from "../../../interfaces";

const MIN_TITLE_LENGTH = 20;
const MIN_OVERVIEW_LENGTH = 50;
const MAX_TITLE_LENGTH = 200;
const MAX_OVERVIEW_LENGTH = 1000;

export const reportIncidentFormSchema =
  Yup.object<IncidentFormInterface>().shape({
    [INCIDENT_FORM.CATEGORY]: Yup.mixed<INCIDENT_CATEGORY>().required(
      i18next.t("incidentForm:fieldRequired")
    ),
    [INCIDENT_FORM.PRIORITY]: Yup.mixed<INCIDENT_PRIORITY>().required(
      i18next.t("incidentForm:fieldRequired")
    ),
    [INCIDENT_FORM.TITLE]: Yup.string()
      .required(i18next.t("incidentForm:fieldRequired"))
      .max(
        MAX_TITLE_LENGTH,
        i18next.t("incidentForm:maxLength", {
          length: MAX_TITLE_LENGTH
        })
      )
      .min(
        MIN_TITLE_LENGTH,
        i18next.t("incidentForm:minLength", {
          length: MIN_TITLE_LENGTH
        })
      ),
    [INCIDENT_FORM.OVERVIEW]: Yup.string()
      .required(i18next.t("incidentForm:fieldRequired"))
      .max(
        MAX_OVERVIEW_LENGTH,
        i18next.t("incidentForm:maxLength", {
          length: MAX_OVERVIEW_LENGTH
        })
      )
      .min(
        MIN_OVERVIEW_LENGTH,
        i18next.t("incidentForm:minLength", {
          length: MIN_OVERVIEW_LENGTH
        })
      )
  });
