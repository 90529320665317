import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

interface DurationRendererProps {
  durationInMinutes: number;
}

export const DurationRenderer: React.FC<DurationRendererProps> = ({
  durationInMinutes
}: DurationRendererProps) => {
  const { t } = useTranslation("duration");
  const getText = useCallback((): string => {
    if (!durationInMinutes) {
      return "";
    }

    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    if (minutes === 0) {
      return t("hours", { count: hours });
    }

    if (hours < 1) {
      return t("minutes", { count: minutes });
    }

    return t("hoursAndMinutes", { hours, minutes });
  }, [durationInMinutes, t]);

  return <span>{getText()}</span>;
};
