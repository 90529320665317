import {
  CIQError,
  ErrorResponse,
  ExpertDetail,
  ExpertsListPageTab,
  RecommendationResponse,
  ReferralSummary,
  ResponseAllExpertsTable,
  StatusExpertTable,
  TaglineRequestInterface,
  UpdateReferralRequest
} from "@arbolus-technologies/api";

import { ScheduleFilterDefinitions } from "../definitions";

export const GET_ALL_EXPERTS = "PROJECT_EXPERTS_GET_ALL_EXPERTS";
export const GET_ALL_EXPERTS_SUCCESS =
  "PROJECT_EXPERTS_GET_ALL_EXPERTS_SUCCESS";
export const GET_ALL_EXPERTS_FAILURE =
  "PROJECT_EXPERTS_GET_ALL_EXPERTS_FAILURE";

export const GET_PROJECT_RECOMMENDATIONS =
  "PROJECT_GET_PROJECT_RECOMMENDATIONS";
export const GET_PROJECT_RECOMMENDATIONS_SUCCESS =
  "PROJECT_GET_PROJECT_RECOMMENDATIONS_SUCCESS";
export const GET_PROJECT_RECOMMENDATIONS_FAILURE =
  "PROJECT_GET_PROJECT_RECOMMENDATIONS_FAILURE";

export const GET_ANGLE_EXPERT = "PROJECT_EXPERTS_GET_ANGLE_EXPERT";
export const GET_ANGLE_EXPERT_SUCCESS =
  "PROJECT_EXPERTS_GET_ANGLE_EXPERT_SUCCESS";
export const GET_ANGLE_EXPERT_FAILURE =
  "PROJECT_EXPERTS_GET_ANGLE_EXPERT_FAILURE";

export const UPDATE_REFERRAL_STATUS = "PROJECT_EXPERTS_UPDATE_REFERRAL_STATUS";
export const UPDATE_REFERRAL_STATUS_SUCCESS =
  "PROJECT_EXPERTS_UPDATE_REFERRAL_STATUS_SUCCESS";
export const UPDATE_REFERRAL_STATUS_FAILURE =
  "PROJECT_EXPERTS_UPDATE_REFERRAL_STATUS_FAILURE";

export const UPDATE_ALL_EXPERTS_FAST_TRACK_STATUS =
  "UPDATE_ALL_EXPERTS_FAST_TRACK_STATUS";

export const SET_EXPERT_LIST_TAB = "PROJECT_EXPERTS_SET_EXPERT_LIST_TAB";

export const ASSIGN_ANGLE_SHORTLIST_TO_CANDIDATE =
  "PROJECT_EXPERTS_ASSIGN_ANGLE_SHORTLIST_TO_CANDIDATE";
export const ASSIGN_ANGLE_SHORTLIST_TO_CANDIDATE_SUCCESS =
  "PROJECT_EXPERTS_ASSIGN_ANGLE_SHORTLIST_TO_CANDIDATE_SUCCESS";
export const ASSIGN_ANGLE_SHORTLIST_TO_CANDIDATE_FAILURE =
  "PROJECT_EXPERTS_ASSIGN_ANGLE_SHORTLIST_TO_CANDIDATE_FAILURE";

export const FORCE_CLOSE_PANEL = "PROJECT_EXPERTS_FORCE_CLOSE_PANEL";

export const FILTER_TABLE_BY_ANGLE = "PROJECT_EXPERTS_FILTER_TABLE_BY_ANGLE";
export const RESET_TABLE_ACTION = "PROJECT_EXPERTS_RESET_TABLE_ACTION";

export const GET_PROJECT_SUMMARY = "PROJECT_EXPERTS_GET_PROJECT_SUMMARY";
export const GET_PROJECT_SUMMARY_SUCCESS =
  "PROJECT_EXPERTS_GET_PROJECT_SUMMARY_SUCCESS";
export const GET_PROJECT_SUMMARY_FAILURE =
  "PROJECT_EXPERTS_GET_PROJECT_SUMMARY_FAILURE";

export const EDIT_TAGLINE = "PROJECT_EXPERTS_EDIT_TAGLINE";
export const EDIT_TAGLINE_SUCCESS = "PROJECT_EXPERTS_EDIT_TAGLINE_SUCCESS";
export const EDIT_TAGLINE_FAILURE = "PROJECT_EXPERTS_EDIT_TAGLINE_FAILURE";

export const UPDATE_REFERRAL_ANGLE = "PROJECT_EXPERTS_UPDATE_REFERRAL_ANGLE";
export const UPDATE_REFERRAL_ANGLE_SUCCESS =
  "PROJECT_EXPERTS_UPDATE_REFERRAL_ANGLE_SUCCESS";
export const UPDATE_REFERRAL_ANGLE_FAILURE =
  "PROJECT_EXPERTS_UPDATE_REFERRAL_ANGLE_FAILURE";

export const SET_SCHEDULE_TAB_FILTERS =
  "PROJECT_EXPERTS_SET_SCHEDULE_TAB_FILTERS";

// MAGIC ACTION THAT TRIGGERS REFETCH ON CLIENT SIDE FROM OUTSIDE NX
export const REFETCH_REFERRAL_SUMMARY =
  "PROJECT_CLIENT_REFETCH_REFERRAL_SUMMARY";

export interface RefetchReferralSummaryAction {
  type: typeof REFETCH_REFERRAL_SUMMARY;
  payload: {
    projectId: string;
  };
}

export interface GetAllExpertsAction {
  type: typeof GET_ALL_EXPERTS;
  payload: {
    projectId: string;
    activeTab: ExpertsListPageTab;
  };
}

export interface GetAllExpertsSuccessAction {
  type: typeof GET_ALL_EXPERTS_SUCCESS;
  payload: {
    expertsList: ResponseAllExpertsTable[];
  };
}

export interface GetAllExpertsFailureAction {
  type: typeof GET_ALL_EXPERTS_FAILURE;
}

export interface GetRecommendationsAction {
  type: typeof GET_PROJECT_RECOMMENDATIONS;
  payload: {
    projectId: string;
  };
}

export interface GetRecommendationsSuccessAction {
  type: typeof GET_PROJECT_RECOMMENDATIONS_SUCCESS;
  payload: {
    recommendations: RecommendationResponse[];
  };
}

export interface GetRecommendationsFailureAction {
  type: typeof GET_PROJECT_RECOMMENDATIONS_FAILURE;
}

// Get Angle Expert
export interface GetAngleExpertAction {
  type: typeof GET_ANGLE_EXPERT;
  payload: {
    expertId: string;
  };
}

export interface GetAngleExpertSuccessAction {
  type: typeof GET_ANGLE_EXPERT_SUCCESS;
  payload: {
    angleExpert: ExpertDetail;
  };
}

export interface GetAngleExpertFailureAction {
  type: typeof GET_ANGLE_EXPERT_FAILURE;
}

export interface UpdateReferralStatusAction {
  type: typeof UPDATE_REFERRAL_STATUS;
  payload: {
    projectId: string;
    referralId: string;
    status: UpdateReferralRequest;
    successMessage?: string;
  };
}

export interface UpdateReferralStatusSuccessAction {
  type: typeof UPDATE_REFERRAL_STATUS_SUCCESS;
  payload: {
    referralId: string;
    status: StatusExpertTable;
  };
}

export interface UpdateReferralStatusFailureAction {
  type: typeof UPDATE_REFERRAL_STATUS_FAILURE;
}

export interface UpdateAllExpertsFastTrackStatusAction {
  type: typeof UPDATE_ALL_EXPERTS_FAST_TRACK_STATUS;
  payload: {
    id: string;
  };
}

export interface SetExpertListTabAction {
  type: typeof SET_EXPERT_LIST_TAB;
  payload: {
    tabActive: ExpertsListPageTab;
  };
}

export interface AssignAngleShortlistToCandidateAction {
  type: typeof ASSIGN_ANGLE_SHORTLIST_TO_CANDIDATE;
  payload: {
    referralId: string;
    angleId: string;
    projectId: string;
  };
}

export interface AssignAngleShortlistToCandidateSuccessAction {
  type: typeof ASSIGN_ANGLE_SHORTLIST_TO_CANDIDATE_SUCCESS;
}

export interface AssignAngleShortlistToCandidateFailureAction {
  type: typeof ASSIGN_ANGLE_SHORTLIST_TO_CANDIDATE_FAILURE;
}

export interface ForceClosePanelAction {
  type: typeof FORCE_CLOSE_PANEL;
}

export interface FilterTableByAngleAction {
  type: typeof FILTER_TABLE_BY_ANGLE;
  payload: {
    values: Array<string | null>;
  };
}
export interface ResetTableAction {
  type: typeof RESET_TABLE_ACTION;
}

export interface GetProjectSummaryAction {
  type: typeof GET_PROJECT_SUMMARY;
  payload: {
    projectId: string;
    angleId?: string;
  };
}

export interface GetProjectSummarySuccessAction {
  type: typeof GET_PROJECT_SUMMARY_SUCCESS;
  payload: {
    summaryList: ReferralSummary;
  };
}

export interface GetProjectSummaryFailureAction {
  type: typeof GET_PROJECT_SUMMARY_FAILURE;
}

// EDIT TAGLINE
export interface EditTaglineAction {
  type: typeof EDIT_TAGLINE;
  payload: {
    taglineRequestObject: TaglineRequestInterface;
    closePanel: () => void;
    activeTab: ExpertsListPageTab;
  };
}

export interface EditTaglineSuccessAction {
  type: typeof EDIT_TAGLINE_SUCCESS;
}

export interface EditTaglineFailureAction {
  type: typeof EDIT_TAGLINE_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

export interface UpdateReferralAngleAction {
  type: typeof UPDATE_REFERRAL_ANGLE;
  payload: {
    referralId: string;
    angleId: string;
    projectId: string;
  };
}

export interface UpdateReferralAngleSuccessAction {
  type: typeof UPDATE_REFERRAL_ANGLE_SUCCESS;
}

export interface UpdateReferralAngleFailureAction {
  type: typeof UPDATE_REFERRAL_ANGLE_FAILURE;
}

export interface SetScheduleTabFiltersAction {
  type: typeof SET_SCHEDULE_TAB_FILTERS;
  payload: {
    projectId: string;
    filters: ScheduleFilterDefinitions;
  };
}
