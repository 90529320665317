import { ColDef, GridApi, GridReadyEvent } from "ag-grid-community";
import clsx from "clsx";
import React from "react";
import { useSelector } from "react-redux";

import {
  DefaultReferralsService,
  DefaultToasterService,
  IReferralsService,
  ToasterService
} from "@arbolus-technologies/api";
import { Referral } from "@arbolus-technologies/models/common";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { ArbolusGrid } from "@arbolus-technologies/ui/components";

import { LoadSlotsDetail } from "../../Components/ReferralSlotsDetail/LoadSlotsDetail";
import { IContextMenuService } from "../../Services/Referrals/ContextMenuService";
import {
  DefaultEditCellService,
  IEditCellService
} from "../../Services/Referrals/EditTaglineService";
import { DefaultShortlistMenuService } from "../../Services/Referrals/ShortlistMenuService";
import { actionsCol } from "./columns/ActionColumns";
import {
  applicationCol,
  applicationGroupRowRendererParams
} from "./columns/ApplicationGroupColumn";
import { angleCol, taglineCol } from "./columns/ColumnDefinitions";
import { modifiedDateCol, shortlistDateCol } from "./columns/DateColumns";
import { expertMasterNameCol, isRowMaster } from "./columns/ExpertColumn";
import { exportColumns } from "./columns/ExportColumns";
import { getOwnerCol } from "./columns/OwnerColumn";
import { complianceCol } from "./columns/StatusColumns";
import { useDefaultReferralGridColumns } from "./hooks/useDefaultReferralGridColumns";
import { useReferralGridContext } from "./hooks/useReferralGridContext";
import {
  commonProps,
  groupProps,
  selectionProps
} from "./referralsTableCommonProps";
import { BulkActionsStatusPanel } from "./statusPanels/BulkActionsStatusPanel";
import { ExpandCollapseStatusPanel } from "./statusPanels/ExpandCollapseStatusPanel";

import styles from "./ReferralsTable.module.scss";

interface ShortlistTableProps {
  referralsService?: IReferralsService;
  notificationService?: ToasterService;
  contextMenuService?: IContextMenuService;
  editTaglineService?: IEditCellService;
}

export const ShortlistTable: React.FC<ShortlistTableProps> = ({
  referralsService = DefaultReferralsService,
  notificationService = DefaultToasterService,
  contextMenuService = DefaultShortlistMenuService,
  editTaglineService = DefaultEditCellService
}) => {
  const [api, setApi] = React.useState<GridApi<Referral>>();

  const project = useSelector(ProjectNxSelector.projectData());
  const [referrals, setReferrals] = React.useState<Referral[]>();

  const onGridReady = React.useCallback(
    ({ api }: GridReadyEvent<Referral>) => {
      setApi(api);
      referralsService.getShortlist(project!.id).subscribe({
        next: setReferrals,
        error: notificationService.showApiErrors
      });
    },
    [notificationService, project, referralsService]
  );

  const defaultColDef = useDefaultReferralGridColumns();
  const gridContext = useReferralGridContext(api);

  const columnDefs = React.useMemo<ColDef<Referral>[]>(() => {
    const cols = [
      applicationCol,
      expertMasterNameCol,
      taglineCol,
      angleCol,
      getOwnerCol(project!.arbolusTeam),
      shortlistDateCol,
      modifiedDateCol,
      actionsCol,
      ...exportColumns
    ];
    if (project?.hasComplianceCheck) {
      cols.splice(7, 0, complianceCol);
    }

    return cols;
  }, [project]);

  const statusBar = React.useMemo(
    () => ({
      statusPanels: [
        {
          statusPanel: ExpandCollapseStatusPanel,
          align: "left"
        },
        {
          statusPanel: BulkActionsStatusPanel,
          align: "right"
        }
      ]
    }),
    []
  );

  return (
    <div className="d-flex h-100 flex-column">
      <div className={clsx(styles.greyGroup, styles.statusBarOnTop, "h-100")}>
        <ArbolusGrid
          {...commonProps}
          {...selectionProps}
          {...groupProps}
          onGridReady={onGridReady}
          context={gridContext}
          rowData={referrals}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          getContextMenuItems={contextMenuService.getItems}
          statusBar={statusBar}
          readOnlyEdit
          onCellEditRequest={(event) => editTaglineService.editCell(event)}
          masterDetail
          detailCellRenderer={LoadSlotsDetail}
          detailRowAutoHeight
          detailCellRendererParams={{ referralsService }}
          isRowMaster={isRowMaster}
          groupRowRendererParams={applicationGroupRowRendererParams}
        />
      </div>
    </div>
  );
};
