import { object, string } from "yup";

const escapeRegExp = (string: string) =>
  string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&");

export const GetDangerSchema = (confirmationText: string) => {
  const escapedConfirmationText = escapeRegExp(confirmationText);
  return object().shape({
    confirmationText: string()
      .matches(
        new RegExp(`^${escapedConfirmationText}$`),
        `Input is not '${confirmationText}'`
      )
      .required("required")
  });
};
