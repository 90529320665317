import React from "react";

import { WorkHistoryEditList } from "@arbolus-technologies/features/common";

import { EditExpertBackground } from "../../Components/EditExpertBackground/EditExpertBackground";

import styles from "./EditExpertExperience.module.scss";

interface EditExpertExperienceProps {
  expertId: string;
  background?: string;
  updateExpertExperience: (background: string) => void;
}

export const EditExpertExperience: React.FC<EditExpertExperienceProps> = ({
  expertId,
  background,
  updateExpertExperience
}) => (
  <div className={styles.container}>
    <EditExpertBackground
      background={background}
      updateExpertExperience={updateExpertExperience}
    />
    <WorkHistoryEditList expertId={expertId} />
  </div>
);
