import { GridApi, MenuItemDef } from "ag-grid-community";
import i18next from "i18next";

import { Referral } from "@arbolus-technologies/models/common";
import { AgGridCustomIcons } from "@arbolus-technologies/ui/components";
import { ExtractMethods, isValueTruthy } from "@arbolus-technologies/utils";

import { IBulkItemActionParams } from "../../Models/ReferralsTable";
import {
  DefaultApproveReferralService,
  IApproveReferralService
} from "./ApproveReferralService";
import {
  DefaultBulkReferralActionsService,
  IBulkReferralActionsService
} from "./BulkReferralActionsService";

export type IBulkActionsMenuService = ExtractMethods<BulkActionsMenuService>;

const t = (key: string) => i18next.t(`referrals:actions:${key}`);
const purpleCss = ["base-purple"];
const dangerCss = ["danger-color"];

export class BulkActionsMenuService {
  private readonly _bulkActionsService: IBulkReferralActionsService;
  private readonly _approveReferralService: IApproveReferralService;

  constructor(
    bulkActionsService: IBulkReferralActionsService,
    approveReferralService: IApproveReferralService
  ) {
    this._bulkActionsService = bulkActionsService;
    this._approveReferralService = approveReferralService;
  }

  public getMoveToCandidatesItem({
    api,
    project,
    modalService,
    reloadReferralsCount
  }: IBulkItemActionParams): MenuItemDef {
    const action = () =>
      this._bulkActionsService.moveToCandidate(
        project.id,
        this.getSelectedItems(api),
        modalService,
        api,
        reloadReferralsCount
      );
    return {
      action,
      name: t("moveToCandidate"),
      icon: AgGridCustomIcons.equal,
      cssClasses: purpleCss
    };
  }

  public getApproveItem(
    { api, project, modalService, reloadReferralsCount }: IBulkItemActionParams,
    disabled = false
  ): MenuItemDef {
    const action = () =>
      this._approveReferralService.approve(
        project,
        this.getSelectedItems(api),
        modalService,
        api,
        reloadReferralsCount
      );
    return {
      action,
      disabled,
      name: t("approveExpert"),
      icon: AgGridCustomIcons.approve
    };
  }

  public getSendApplicationItem({
    api,
    project,
    modalService
  }: IBulkItemActionParams): MenuItemDef {
    const referrals = this.getSelectedItems(api);
    const action = () =>
      this._bulkActionsService.sendApplication(
        project.id,
        referrals,
        modalService,
        api
      );
    return {
      action,
      name: t("sendApplication"),
      icon: AgGridCustomIcons.send,
      cssClasses: purpleCss
    };
  }

  public getRemoveItem({
    api,
    project,
    modalService,
    reloadReferralsCount
  }: IBulkItemActionParams): MenuItemDef {
    const action = () =>
      this._bulkActionsService.removeFromProject(
        project.id,
        this.getSelectedItems(api),
        modalService,
        api,
        reloadReferralsCount
      );
    return {
      action,
      name: t("removeExpert"),
      icon: AgGridCustomIcons.delete,
      cssClasses: dangerCss
    };
  }

  public getReactivateItem({
    api,
    project,
    modalService,
    reloadReferralsCount
  }: IBulkItemActionParams): MenuItemDef {
    const action = () =>
      this._bulkActionsService.reactivate(
        project.id,
        this.getSelectedItems(api),
        modalService,
        api,
        reloadReferralsCount
      );
    return {
      action,
      name: t("reactivate"),
      icon: AgGridCustomIcons.equal,
      cssClasses: purpleCss
    };
  }

  protected getSelectedItems(api: GridApi<Referral>): Referral[] {
    return api
      .getSelectedNodes()
      .map((node) => node.data)
      .filter(isValueTruthy);
  }
}

export const DefaultBulkActionsMenuService = new BulkActionsMenuService(
  DefaultBulkReferralActionsService,
  DefaultApproveReferralService
);
