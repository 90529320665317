import { Button, Flex, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  EXPERT_WORK_TIME_FORMAT,
  formatDate
} from "@arbolus-technologies/utils";

import { TimeGap } from "../../../interfaces";
import { MANDATORY_PAST_WORK_HISTORY_MONTHS } from "../helpers/constants";
import { getMissingTimeGaps } from "../helpers/utils";

const { Text } = Typography;

interface WhBoxFooterProps {
  workHistoryGaps: TimeGap[];
  mandatoryTimeFrame: boolean;
  isDisabled: boolean;
  onAddNewWorkHistoryItem: () => void;
  onSave?: () => void;
}

export const WhBoxFooter: React.FC<WhBoxFooterProps> = ({
  workHistoryGaps,
  isDisabled,
  onAddNewWorkHistoryItem,
  onSave,
  mandatoryTimeFrame
}) => {
  const { t } = useTranslation("workHistoryBoxFooter");

  const [missingTimeGaps, setMissingTimeGaps] = useState<TimeGap[]>([]);

  const timeGapsToString = useCallback(
    () =>
      missingTimeGaps.map(
        (gap) =>
          ` ${formatDate(gap.from, EXPERT_WORK_TIME_FORMAT)} - ${formatDate(
            gap.to,
            EXPERT_WORK_TIME_FORMAT
          )}`
      ),
    [missingTimeGaps]
  );

  useEffect(() => {
    const updateMissingGaps = getMissingTimeGaps(workHistoryGaps);
    setMissingTimeGaps(updateMissingGaps);
  }, [workHistoryGaps]);

  const timelineIsValid = !mandatoryTimeFrame || missingTimeGaps.length === 0;

  return (
    <Flex gap="16px">
      {!timelineIsValid && (
        <Flex vertical data-testid="recentWorkHistoryWarning">
          <Text type="danger">
            {t("recentWorkHistoryWarning", {
              quantityOfMonths: MANDATORY_PAST_WORK_HISTORY_MONTHS
            })}
          </Text>
          <Text type="danger" strong>
            {t("missingGaps", { missingGaps: timeGapsToString() })}
          </Text>
        </Flex>
      )}
      <Flex justify="space-between" style={{ marginTop: "24px" }}>
        <Button
          type="primary"
          disabled={isDisabled}
          onClick={onAddNewWorkHistoryItem}
        >
          {t("addNewPosition")}
        </Button>
        {onSave && (
          <Button
            disabled={!timelineIsValid || isDisabled}
            onClick={() => onSave()}
          >
            {mandatoryTimeFrame ? t("confirm") : t("update")}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
