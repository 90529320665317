import React from "react";
import { useSelector } from "react-redux";

import { ArbolusFeatureFlag } from "@arbolus-technologies/models/common";
import { AppSelector } from "@arbolus-technologies/stores/app";

export function withLegacyOrNewFeature<P>(
  NewComponent: React.ComponentType<P>,
  LegacyComponent: React.ComponentType<P>,
  flagName: ArbolusFeatureFlag
): React.FC<P> {
  const LegacyOrNew: React.FC<P> = (wrappedComponentProps) => {
    const flags = useSelector(AppSelector.getFeatureFlags());
    const featureEnabled = !!flags[flagName];

    if (featureEnabled)
      return (
        <NewComponent
          {...(wrappedComponentProps as JSX.IntrinsicAttributes & P)}
        />
      );

    return (
      <LegacyComponent
        {...(wrappedComponentProps as JSX.IntrinsicAttributes & P)}
      />
    );
  };

  return LegacyOrNew;
}
