import { LocationDescriptorObject } from "history";
import i18next from "i18next";
import moment from "moment";

import { PROJECT_EVENT } from "@arbolus-technologies/routes";
import { formatDateBasedOnToday } from "@arbolus-technologies/utils";

import { BaseNotification } from "./BaseNotification";

export class NewEventNotification extends BaseNotification {
  title: string;
  subtitle: string;
  link: LocationDescriptorObject;

  constructor(
    id: string,
    eventName: string,
    startTime: string,
    endTime: string,
    projectId: string,
    eventId: string
  ) {
    super(id, "calendar_today");
    this.title = eventName;
    const endTimeString = moment.utc(endTime).local().format("LT");
    const dateString = `${formatDateBasedOnToday(
      startTime
    )} - ${endTimeString}`;
    this.subtitle = i18next.t("dashboardNotifications:event:subtitle", {
      dateString
    });
    this.link = PROJECT_EVENT(projectId, eventId);
  }
}
