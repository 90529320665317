import i18next from "i18next";
import * as yup from "yup";

import { FormConstraints } from "@arbolus-technologies/models/canopy";

import { MultiFormData } from "./NpsForExpertProvider";

export const createNpsExpertSchema: yup.ObjectSchema<MultiFormData> = yup
  .object()
  .shape({
    npsValue: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .min(
        FormConstraints.MIN_NPS_VALUE,
        i18next.t(`answerNpsCanopyExpert:npsNumberMinError`, {
          minValue: FormConstraints.MIN_NPS_VALUE
        })
      )
      .max(
        FormConstraints.MAX_NPS_VALUE,
        i18next.t(`answerNpsCanopyExpert:npsNumberMaxError`, {
          maxValue: FormConstraints.MAX_NPS_VALUE
        })
      )
      .nullable()
  });
