import { ActionCreator } from "redux";

import { CIQError, ErrorResponse } from "../../../../models/api";
import {
  AUTHENTICATE_FEDERATED_USER,
  AUTHENTICATE_USER_FAILURE,
  AUTHENTICATE_USER_SUCCESS,
  AuthenticateFederatedUserAction,
  AuthenticateUserFailureAction,
  AuthenticateUserSuccessAction,
  LOGOUT_USER,
  LOGOUT_USER_FAILED,
  LOGOUT_USER_SUCCESS,
  LogoutUserAction,
  LogoutUserFailedAction,
  LogoutUserSuccessAction
} from "./LoginActionTypes";

export const authenticateFederatedUser: ActionCreator<
  AuthenticateFederatedUserAction
> = (code: string, state: string, user?: string) => ({
  type: AUTHENTICATE_FEDERATED_USER,
  payload: {
    code,
    state,
    user
  }
});

export const authenticateUserSuccess: ActionCreator<
  AuthenticateUserSuccessAction
> = (userId: string, roles: string[]) => ({
  type: AUTHENTICATE_USER_SUCCESS,
  payload: {
    userId,
    roles
  }
});

export const authenticateUserFailure: ActionCreator<
  AuthenticateUserFailureAction
> = (error: ErrorResponse<CIQError>) => ({
  type: AUTHENTICATE_USER_FAILURE,
  payload: {
    error
  }
});

export const logoutUser: ActionCreator<LogoutUserAction> = () => ({
  type: LOGOUT_USER,
  payload: {}
});

export const logoutUserSuccess: ActionCreator<
  LogoutUserSuccessAction
> = () => ({
  type: LOGOUT_USER_SUCCESS,
  payload: {}
});

export const logoutUserFailed: ActionCreator<LogoutUserFailedAction> = () => ({
  type: LOGOUT_USER_FAILED,
  payload: {}
});
