import dompurify from "dompurify";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

import { Avatar, ThreeDots } from "@arbolus-technologies/ui/components";

import styles from "./CommentCardItem.module.scss";

interface CommentCardItemProps {
  userId: string;
  userName: string;
  clientLogoUrl: string;
  userProfileImageUrl: string;
  commentDate: string;
  title: string;
  note: string;
  clientId: string;
  clientName: string;
  id: string;
  handleCommentSelected: () => void;
  showDeleteModal: () => void;
}

export const CommentCardItem: React.FC<CommentCardItemProps> = ({
  userName,
  clientLogoUrl,
  userProfileImageUrl,
  commentDate,
  title,
  note,
  clientName,
  id,
  handleCommentSelected,
  showDeleteModal
}) => {
  const { t } = useTranslation("internalComments");

  const threeDotsItems = [
    {
      title: t("editComment"),
      icon: "edit",
      iconColor: "grey",
      color: "grey",
      onClick: () => handleCommentSelected()
    },
    {
      title: t("deleteComment"),
      icon: "delete",
      iconColor: "red",
      color: "red",
      onClick: showDeleteModal
    }
  ];

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <Avatar
            avatar={{
              name: userName,
              imageUrl: userProfileImageUrl
            }}
            type="smallCircle"
          />
          <div className={styles.headerInfo}>
            <span className={styles.name}> {userName}</span>
            <span className={styles.name}>
              {moment(commentDate).format("DD MMM YYYY")}
            </span>
          </div>
        </div>
        <ThreeDots
          popoverId={`expert_profile_comment_${id}`}
          items={threeDotsItems}
        />
      </div>
      <hr className={styles.divider} />
      <div>
        <div className={styles.titleWrapper}>
          <span className={styles.title}>{title}</span>
        </div>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(note)
          }}
        />
      </div>
      <div className={styles.footer}>
        <Avatar
          avatar={{
            name: clientName,
            imageUrl: clientLogoUrl
          }}
          type="smallCircle"
        />
        <span className={styles.company}>{clientName}</span>
      </div>
    </div>
  );
};
