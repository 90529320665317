import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { getFileExtension } from "@arbolus-technologies/utils";

import { LoaderOrComponent } from "../Loader/LoaderOrComponent";
import { DocumentIcon } from "./DocumentIcon";

import styles from "./DocumentBox.module.scss";

interface DocumentBoxProps {
  name: string;
  isDownloading?: boolean;
  onClickDownload?: () => void;
  fileId: string;
  onRemove?: (fileId: string) => void;
}

export const DocumentBox: React.FC<DocumentBoxProps> = ({
  name,
  isDownloading = false,
  onClickDownload,
  fileId,
  onRemove
}) => {
  const fileExtension = getFileExtension(name);

  return (
    <div
      className={clsx(styles.container, isDownloading && styles.disabled)}
      role="button"
      onClick={onClickDownload}
    >
      <div className={styles.nameContainer}>
        <DocumentIcon
          fileExtension={fileExtension}
          customHeight="24px"
          customWidth="24px"
          fontSize="12px"
        />
        <span className={styles.name} title={name}>
          {name}
        </span>
      </div>
      <div className={styles.iconContainer}>
        {onClickDownload && (
          <LoaderOrComponent isLoading={isDownloading}>
            <Icon
              name="file_download"
              fontSize="24px"
              color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
            />
          </LoaderOrComponent>
        )}
        {onRemove && (
          <Icon
            name="delete"
            fontSize="24px"
            color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
            onClick={(e) => {
              e.stopPropagation();
              onRemove(fileId);
            }}
          />
        )}
      </div>
    </div>
  );
};
