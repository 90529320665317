import clsx from "clsx";
import React, { useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import {
  CIQError,
  CanopyService,
  DefaultToasterService,
  ErrorResponse,
  ToasterService
} from "@arbolus-technologies/api";
import {
  ArbolusGPTButton,
  MixPanelEventNames,
  MixpanelPages,
  PageTracker,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { ExpertCanopyV2 } from "@arbolus-technologies/models/canopy-panels";
import {
  CANOPY_EXPERTS_ROUTE,
  CANOPY_MOBILE_MENU_ROUTE
} from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  CanopyV2Selector,
  CanopyV2StoreActions
} from "@arbolus-technologies/stores/canopy-V2";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { Header } from "@arbolus-technologies/ui/canopy-panels";
import { CustomSelect, Tab } from "@arbolus-technologies/ui/components";

import { ExportAnswersButton } from "../../../Components/Buttons/ExportAnswersButton/ExportAnswersButton";
import { ExpertsList } from "./ExpertsList/ExpertsList";

import styles from "./ExpertsTabWrapper.module.scss";

export enum EXPERTS_TABS {
  INVITES = "Invites",
  IN_REVIEW = "InReview",
  APPROVED = "Complete",
  REJECTED = "Rejected",
  NO_RESPONSE = "Incomplete"
}

interface ExpertsTabWrapperProps {
  canopyService?: typeof CanopyService;
  notificationService?: ToasterService;
}

export const ExpertsTabWrapper: React.FC<ExpertsTabWrapperProps> = ({
  canopyService = CanopyService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("canopyV2");
  const dispatch = useDispatch();
  const history = useHistory();
  const { trackClick } = useArbolusTracking();

  const isAdmin = useSelector(CacheSelector.isAdmin());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [canopyExperts, setCanopyExperts] = useState<ExpertCanopyV2[]>([]);
  const [activeTab, setActiveTab] = useState<EXPERTS_TABS>(
    isAdmin ? EXPERTS_TABS.INVITES : EXPERTS_TABS.APPROVED
  );

  const loggedUserMail = useSelector(CacheSelector.userProfile()).email;
  const canopyExpertsCount = useSelector(CanopyV2Selector.canopyExpertsCount());
  const canopyData = useSelector(CanopyV2Selector.canopyData());

  const TABS = useMemo(() => {
    const {
      complete = 0,
      incomplete = 0,
      inReview = 0,
      rejected = 0
    } = canopyExpertsCount ?? {};

    return [
      {
        label: `${t(EXPERTS_TABS.INVITES)} · ${
          complete + inReview + rejected + incomplete
        }`,
        value: EXPERTS_TABS.INVITES
      },
      {
        label: `${t(EXPERTS_TABS.IN_REVIEW)} · ${inReview}`,
        value: EXPERTS_TABS.IN_REVIEW
      },
      {
        label: `${t(EXPERTS_TABS.APPROVED)} · ${complete}`,
        value: EXPERTS_TABS.APPROVED
      },
      {
        label: `${t(EXPERTS_TABS.REJECTED)} · ${rejected}`,
        value: EXPERTS_TABS.REJECTED
      },
      {
        label: `${t(EXPERTS_TABS.NO_RESPONSE)} · ${incomplete}`,
        value: EXPERTS_TABS.NO_RESPONSE
      }
    ];
  }, [canopyExpertsCount, t]);

  useEffect(() => {
    if (canopyData) {
      setIsLoading(true);

      canopyService
        .getExpertsInACanopyV2(
          canopyData.id,
          activeTab === EXPERTS_TABS.INVITES ? null : activeTab
        )
        .subscribe(
          (canopyExperts) => {
            setCanopyExperts(canopyExperts.items);
            dispatch(CanopyV2StoreActions.getCanopyExpertsCount(canopyData.id));
            setIsLoading(false);
          },
          (error: ErrorResponse<CIQError>) => {
            notificationService.showApiErrors(error);
            setIsLoading(false);
          }
        );
    }
  }, [canopyData, canopyService, activeTab, notificationService, dispatch]);

  const renderHeaderButton = (): JSX.Element | undefined => {
    if (canopyData) {
      return (
        <div className={styles.btnContainer}>
          <ArbolusGPTButton
            canopyPath={`${CANOPY_EXPERTS_ROUTE(canopyData.id)} | ${
              canopyData.title
            }>`}
            onTrack={() =>
              trackClick(MixPanelEventNames.CanopyExpertsArbolusGPT, {
                loggedUserMail
              })
            }
            projectId={canopyData.projectId}
          />
          <ExportAnswersButton
            canopyId={canopyData.id}
            canopyTitle={canopyData.title}
          />
        </div>
      );
    }
  };

  const handleOpenProfile = (expertId: string) => {
    dispatch(
      PanelStoreActions.openPanel(PanelId.ExpertProfile, {
        expertId,
        canopyId: canopyData?.id,
        projectId: canopyData?.projectId
      })
    );
  };

  return (
    <PageTracker page={MixpanelPages.CanopyExperts}>
      <Header
        title={t("experts")}
        backButton={
          isMobile && canopyData
            ? {
                onClick: () =>
                  history.push(CANOPY_MOBILE_MENU_ROUTE(canopyData.id)),
                title: t("back")
              }
            : undefined
        }
        actionButton={renderHeaderButton()}
      />
      <div className={styles.mainContainer}>
        {isAdmin && (
          <>
            {isMobile && (
              <CustomSelect
                noOptionsMessage={t("noOptions")}
                options={TABS}
                onSelectChange={(value) => setActiveTab(value as EXPERTS_TABS)}
                defaultValue={TABS[0]}
                displayIcon
                placeholder={"select a value"}
              />
            )}
            {!isMobile && (
              <div className={styles.tabButtonsContainer}>
                {TABS.map((tab) => (
                  <Tab
                    key={tab.value}
                    text={tab.label}
                    className={clsx({
                      [styles.isLastItem]:
                        tab.value === EXPERTS_TABS.NO_RESPONSE
                    })}
                    onClick={() => setActiveTab(tab.value)}
                    isActive={activeTab === tab.value}
                  />
                ))}
              </div>
            )}
          </>
        )}
        <ExpertsList
          activeTab={activeTab}
          canopyExperts={canopyExperts}
          isLoading={isLoading}
          handleOpenProfile={handleOpenProfile}
        />
      </div>
    </PageTracker>
  );
};
