import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";

import {
  AvatarWithTitle,
  ButtonNoBorderWithIcon,
  IThreeDotsItem,
  IconWithTitle,
  ThreeDots
} from "@arbolus-technologies/ui/components";

import styles from "./CardItem.module.scss";

interface CardItemProps {
  title: string;
  id?: string;
  imageUrl?: string;
  bigIcon?: string;
  subtitle?: string;
  classnames?: string;
  icon?: string;
  iconClassnames?: string;
  path?: string;
  onClick?: () => void;
  threeDots?: IThreeDotsItem[];
}

export const CardItem: React.FC<CardItemProps> = ({
  title,
  subtitle,
  imageUrl,
  bigIcon,
  icon,
  iconClassnames,
  path,
  onClick,
  classnames,
  threeDots,
  id
}) => {
  const hasThreeDots = (threeDots?.length || 0) > 0 && id;
  const iconWithPath = icon && path && !hasThreeDots;
  const iconWithButton = icon && !path && !hasThreeDots;
  return (
    <section className={clsx(styles.container, classnames)}>
      {bigIcon ? (
        <IconWithTitle
          icon={bigIcon}
          title={title}
          subtitle={subtitle}
          classnames={styles.bigIcon}
        />
      ) : (
        <AvatarWithTitle
          username={title}
          title={title}
          subtitle={subtitle}
          imageUrl={imageUrl}
        />
      )}
      {iconWithPath && (
        <Link to={path}>
          <span
            className={clsx(
              "material-symbols-sharp",
              styles.icon,
              iconClassnames
            )}
          >
            {icon}
          </span>
        </Link>
      )}
      {iconWithButton && (
        <ButtonNoBorderWithIcon
          iconName={icon}
          name={""}
          onClick={onClick}
          align={"right"}
          classnames={clsx(styles.iconButton, iconClassnames)}
        />
      )}
      {hasThreeDots && (
        <ThreeDots
          popoverId={id}
          items={threeDots!}
          classnames={styles.threeDots}
        />
      )}
    </section>
  );
};
