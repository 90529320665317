import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { CANOPY_STATUS } from "@arbolus-technologies/models/canopy";
import { CANOPY_ROUTE } from "@arbolus-technologies/routes";
import { CanopyV2StoreActions } from "@arbolus-technologies/stores/canopy-V2";

interface EditDetailsButtonProps {
  canopyId: string;
  status: CANOPY_STATUS;
}

export const EditDetailsButton: React.FC<EditDetailsButtonProps> = ({
  canopyId,
  status
}) => {
  const history = useHistory();
  const { t } = useTranslation("canopyV2");
  const dispatch = useDispatch();

  const handleEditDetails = () => {
    dispatch(CanopyV2StoreActions.resetCanopyData());
    history.push(CANOPY_ROUTE(canopyId));
  };

  return (
    <Button
      type="primary"
      onClick={() => handleEditDetails()}
      text={t("editDetails")}
      disabled={status === CANOPY_STATUS.ARCHIVED}
    />
  );
};
