import _ from "lodash";
import React from "react";
import { Route, RouteProps, Switch } from "react-router";

import { ROLE_BASE_BLOCKED_ROUTES } from "../../constants/navigation/rbRoutes";
import NotFoundPage from "../../containers/app/pages/errors/404/NotFoundPage";
import {
  AccessControlContextConsumer,
  AccessControlContextProps
} from "./AccessControlContext";

interface RBACRouteProps extends RouteProps {
  path: string[];
}

const RBACRoute: React.FC<RBACRouteProps> = ({ path, component, exact }) => {
  const roleBasedRoutes = (role: string): string[] => {
    const blockedRoutes = ROLE_BASE_BLOCKED_ROUTES[role];
    const allowedRoutes = _.filter(
      path,
      (route) => !blockedRoutes.includes(route)
    );

    return allowedRoutes;
  };

  return (
    <AccessControlContextConsumer>
      {({ role }: AccessControlContextProps): JSX.Element => (
        <Switch>
          <Route
            exact={exact}
            path={roleBasedRoutes(role)}
            component={component}
          />
          <Route component={NotFoundPage} />
        </Switch>
      )}
    </AccessControlContextConsumer>
  );
};

RBACRoute.defaultProps = {
  path: []
};

export default RBACRoute;
