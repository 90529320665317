import { Icon } from "arbolus-ui-components";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  DefaultToasterService,
  ProjectService,
  ToasterService
} from "@arbolus-technologies/api";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { ArbolusModal } from "@arbolus-technologies/ui/components";

interface DeleteProjectDraftButtonProps {
  projectService?: typeof ProjectService;
  notificationService?: ToasterService;
  id: string;
  onDelete?: () => void;
}

export const DeleteProjectDraftButton: React.FC<
  DeleteProjectDraftButtonProps
> = ({
  id,
  onDelete,
  projectService = ProjectService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("deleteProjectDraft");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleClickDeleteButton = useCallback(() => {
    projectService.deleteDraftProject(id).subscribe({
      next: (_) => {
        setIsModalOpen((prev) => !prev);
        if (onDelete) {
          onDelete();
        }
      },
      error: notificationService.showApiErrors
    });
  }, [projectService, id, notificationService.showApiErrors, onDelete]);

  return (
    <>
      <Icon
        name="delete"
        onClick={() => setIsModalOpen((prev) => !prev)}
        fontSize={"16px"}
        color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
      />
      <ArbolusModal
        title={t("modalTitle")}
        toggle={() => setIsModalOpen((prev) => !prev)}
        isOpen={isModalOpen}
        subtitle={t("confirmationMessage")}
        leftButton={{
          onClick: () => setIsModalOpen((prev) => !prev),
          type: "tertiary",
          text: t("cancel")
        }}
        rightButton={{
          onClick: handleClickDeleteButton,
          type: "rejection",
          text: t("delete")
        }}
      />
    </>
  );
};
