import {
  CIQError,
  CompleteValidatorErrors,
  Country,
  Currency,
  ErrorResponse,
  Industry,
  Region,
  TimeZone
} from "@arbolus-technologies/api";
import { Permission } from "@arbolus-technologies/models/common";

import {
  CACHE_GET_APP_DATA,
  CACHE_GET_APP_DATA_FAILURE,
  CACHE_GET_APP_DATA_SUCCESS,
  GetAppDataAction,
  GetAppDataFailureAction,
  GetAppDataSuccessAction
} from "./CacheGetAppDataActionTypes";

export const getAppData = (): GetAppDataAction => ({
  type: CACHE_GET_APP_DATA
});

export interface UserNotificationCounts {
  NewReferrals: number;
  EventInvites: number;
  ThingsTodo: number;
  Approvals: number;
  Messages: number;
  Undefined: number;
  SharedDocs: number;
  NewJoins: number;
  Applications: number;
  [key: string]: number;
}

export const getAppDataSuccess = (
  countries: Country[],
  industriesLevel0: Industry[],
  industriesLevel2: Industry[],
  timezones: TimeZone[],
  regions: Region[],
  currencies: Currency[],
  notificationCounts: UserNotificationCounts,
  specialPermissions?: Permission[]
): GetAppDataSuccessAction => ({
  type: CACHE_GET_APP_DATA_SUCCESS,
  payload: {
    countries,
    industriesLevel0,
    industriesLevel2,
    currencies,
    timezones,
    regions,
    notificationCounts,
    specialPermissions
  }
});

export const getAppDataFailure = (
  error: ErrorResponse<CIQError> | CompleteValidatorErrors
): GetAppDataFailureAction => ({
  type: CACHE_GET_APP_DATA_FAILURE,
  payload: {
    error
  }
});
