import {
  BOOKMARK_COLLECTION_ORDER_BY,
  BookmarksPaginatedRequest,
  CIQError,
  ErrorResponse,
  PagedListResponse
} from "@arbolus-technologies/api";
import { BookmarkList } from "@arbolus-technologies/models/common";

import {
  GET_BOOKMARKS_LIST,
  GET_BOOKMARKS_LIST_FAILURE,
  GET_BOOKMARKS_LIST_SUCCESS,
  GetBookmarksListAction,
  GetBookmarksListFailureAction,
  GetBookmarksListSuccessAction,
  RESET_BOOKMARKS_LIST,
  ResetBookmarksListAction
} from "./GetBookmarksListActionTypes";

export const getBookmarksList = (
  searchTerm: string,
  queryParams: BookmarksPaginatedRequest<BOOKMARK_COLLECTION_ORDER_BY>
): GetBookmarksListAction => ({
  type: GET_BOOKMARKS_LIST,
  payload: { searchTerm, queryParams }
});

export const getBookmarksListSuccess = (
  response: PagedListResponse<BookmarkList>
): GetBookmarksListSuccessAction => ({
  type: GET_BOOKMARKS_LIST_SUCCESS,
  payload: { response }
});

export const getBookmarksListFailure = (
  error: ErrorResponse<CIQError>
): GetBookmarksListFailureAction => ({
  type: GET_BOOKMARKS_LIST_FAILURE,
  payload: { error }
});

export const resetBookmarksList = (): ResetBookmarksListAction => ({
  type: RESET_BOOKMARKS_LIST
});
