import { PublicRoutes } from "@arbolus-technologies/routes";

export const {
  LOGIN,
  SSO,
  REGISTER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  FEDERATED_REDIRECT,
  ENDORSEMENT,
  WELCOME_CANOPY,
  WELCOME_CANOPY_ROUTE,
  CANOPY_PAUSED_PUBLIC
} = PublicRoutes;

export const PUBLIC_ROUTES = [
  LOGIN,
  SSO,
  REGISTER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  FEDERATED_REDIRECT,
  ENDORSEMENT,
  WELCOME_CANOPY,
  CANOPY_PAUSED_PUBLIC
];
