import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  DefaultToasterService,
  ProjectCompliance,
  ProjectService,
  ToasterService
} from "@arbolus-technologies/api";
import { COMPLIANCE_TYPE } from "@arbolus-technologies/models/project";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { BoxButton } from "@arbolus-technologies/ui/components";

import { ComplianceSlidePanel } from "../ComplianceSlidePanel/ComplianceSlidePanel";

interface ComplianceButtonProps {
  projectId: string;
  projectService?: typeof ProjectService;
  notificationService?: ToasterService;
}

const getComplianceRestrictionsCount = (
  projectCompliance: ProjectCompliance
): number => {
  let count = 0;
  if (
    projectCompliance.complianceQuestionSettings.complianceType !==
    COMPLIANCE_TYPE.NONE
  ) {
    count++;
  }

  if (projectCompliance.complianceWarningNote) {
    count++;
  }

  if (typeof projectCompliance.publicCompaniesPeriod === "number") {
    count++;
  }

  return count;
};

export const ComplianceButton: React.FC<ComplianceButtonProps> = ({
  projectId,
  projectService = ProjectService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("complianceButton");
  const dispatch = useDispatch();
  const [projectCompliance, setProjectCompliance] = React.useState<{
    compliance: ProjectCompliance;
    restrictions: number;
  }>();

  React.useEffect(() => {
    projectService.getCompliance(projectId).subscribe({
      next: (compliance) => {
        setProjectCompliance({
          compliance,
          restrictions: getComplianceRestrictionsCount(compliance)
        });
      },
      error: notificationService.showApiErrors
    });
  }, [notificationService, projectId, projectService]);

  return (
    <>
      <BoxButton
        text={t("compliance")}
        icon={{
          color: ARBOLUS_COLORS.bColorBaseWhite,
          background: projectCompliance?.restrictions
            ? ARBOLUS_COLORS.bColorBaseOrange
            : ARBOLUS_COLORS.bColorBasePurple,
          name: "error"
        }}
        subTitle={
          projectCompliance?.restrictions
            ? t("complianceSubtitle", {
                count: projectCompliance?.restrictions
              })
            : t("noCompliance")
        }
        onClick={() =>
          dispatch(PanelStoreActions.openPanel(PanelId.Compliance))
        }
      />
      {projectCompliance && (
        <ComplianceSlidePanel
          projectCompliance={projectCompliance.compliance}
        />
      )}
    </>
  );
};
