import clsx from "clsx";
import React from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import { getDatePrefix } from "../../../../utils";

import styles from "./ScheduleColumnHeader.module.scss";

interface ScheduleColumnHeaderProps {
  title: string;
  slotsNumber: number;
  timezone: string;
  columnIndex: number;
  currentPage: number;
}

export const ScheduleColumnHeader: React.FC<ScheduleColumnHeaderProps> = ({
  title,
  slotsNumber,
  timezone,
  columnIndex,
  currentPage
}) => {
  const { t } = useTranslation("scheduleTab");
  const columnHeaderTitle =
    getDatePrefix(columnIndex, currentPage) === ""
      ? title
      : `${getDatePrefix(columnIndex, currentPage)}, ${title}`;
  return (
    <div className={styles.columnHeaderContainer}>
      <div
        className={clsx(styles.titleAndSlotsContainer, {
          [styles.mobileTitleAndSlotsContainer]: isMobile
        })}
      >
        <h3 className={styles.title}>{columnHeaderTitle}</h3>
        <span className={styles.lightText}>
          {slotsNumber} {t("timeslots")}
        </span>
      </div>
      <span className={styles.lightText}>{timezone}</span>
    </div>
  );
};
