export const ID = "id";
export const DATE = "date";
export const START_TIME = "startTime";
export const END_TIME = "endTime";
export const EXPERT_TIMEZONE = "expertTimezone";

export interface ExpertAvailabilityPanelBase {
  [ID]: number;
  [DATE]: Date;
  [START_TIME]: Date;
  [END_TIME]: Date;
  [EXPERT_TIMEZONE]: string;
}
