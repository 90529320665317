import { CellEditRequestEvent } from "ag-grid-community";
import moment from "moment";

import {
  DefaultReferralsService,
  DefaultToasterService,
  ProjectService,
  ReferralsService,
  ToasterService
} from "@arbolus-technologies/api";
import { Referral } from "@arbolus-technologies/models/common";
import { ExtractMethods } from "@arbolus-technologies/utils";

import { TAGLINE_COL_ID } from "../../Modules/ReferralsTable/columns/ColumnDefinitions";
import { OWNER_COL_ID } from "../../Modules/ReferralsTable/columns/OwnerColumn";

export type IEditCellService = ExtractMethods<EditCellService>;

class EditCellService {
  private readonly _projectService: typeof ProjectService;
  private readonly _referralsService: ReferralsService;
  private readonly _toasterService: ToasterService;

  constructor(
    projectService: typeof ProjectService,
    referralsService: ReferralsService,
    toasterService: ToasterService
  ) {
    this._projectService = projectService;
    this._referralsService = referralsService;
    this._toasterService = toasterService;
  }

  public editCell(event: CellEditRequestEvent<Referral>) {
    const { colDef } = event;
    if (colDef.colId === TAGLINE_COL_ID) {
      this.editTagline(event);
    } else if (colDef.colId === OWNER_COL_ID) {
      this.editOwner(event);
    }
  }

  private editTagline(event: CellEditRequestEvent<Referral>): void {
    const { api, data: referral, newValue, context } = event;
    const updatedReferral = referral.createUpdated({
      expert: { ...referral.expert, tagline: newValue },
      modified: new Date(moment.utc().format())
    });
    api.applyTransaction({ update: [updatedReferral] });
    const request = {
      projectId: context.project.id,
      tagline: newValue,
      referralId: referral.id
    };
    this._projectService.editTagline(request).subscribe({
      error: (error) => {
        this._toasterService.showApiErrors(error);
        api.applyTransaction({ update: [referral] });
      }
    });
  }

  private editOwner(event: CellEditRequestEvent<Referral>): void {
    const { api, data: referral, newValue, context } = event;
    if (referral.owner?.id === newValue.id) {
      return;
    }

    const updatedReferral = referral.createUpdated({
      owner: newValue,
      modified: new Date(moment.utc().format())
    });
    api.applyTransaction({ update: [updatedReferral] });
    this._referralsService
      .updateOwner(context.project.id, referral.expert.expertId, newValue.id)
      .subscribe({
        error: (error) => {
          this._toasterService.showApiErrors(error);
          api.applyTransaction({ update: [referral] });
        }
      });
  }
}

export const DefaultEditCellService = new EditCellService(
  ProjectService,
  DefaultReferralsService,
  DefaultToasterService
);
