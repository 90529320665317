import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SlidePanel } from "@arbolus-technologies/features/common";
import { CanopyExpertSelector } from "@arbolus-technologies/stores/canopy-expert";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { CanopyEngagementAgreement } from "./CanopyEngagementAgreement/CanopyEngagementAgreement";

interface CanopyEngagementAgreementPanelProps {
  canopyId: string;
  expertId: string;
  isCanopyAgreementAccepted: boolean;
}

export const CanopyEngagementAgreementPanel: React.FC<
  CanopyEngagementAgreementPanelProps
> = ({ canopyId, expertId, isCanopyAgreementAccepted }) => {
  const slidePanelId = PanelId.CanopyAgreement;
  const dispatch = useDispatch();
  const hasAcceptedAgreement = useSelector(
    CanopyExpertSelector.isExpertCanopyAgreementAccepted()
  );

  useEffect(() => {
    if (!isCanopyAgreementAccepted) {
      dispatch(PanelStoreActions.openPanel(slidePanelId));
    }
  }, [isCanopyAgreementAccepted, dispatch, slidePanelId]);

  useEffect(() => {
    closeSlidePanel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAcceptedAgreement]);

  const closeSlidePanel = () => {
    if (hasAcceptedAgreement) {
      dispatch(PanelStoreActions.closePanel(slidePanelId));
    }
  };

  return (
    <SlidePanel
      panelId={slidePanelId}
      width={SIDE_PANEL_SIZE._720}
      hideHeader
      customCloseRequest={closeSlidePanel}
      skipClose
    >
      <CanopyEngagementAgreement canopyId={canopyId} expertId={expertId} />
    </SlidePanel>
  );
};
