/* eslint-disable jsx-a11y/media-has-caption */
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { isSafari } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-media";
import { Input } from "reactstrap";

import { ToasterService } from "@arbolus-technologies/api";
import { Loader } from "@arbolus-technologies/ui/components";

import { UIConstants } from "../../../../../constants";
import { TRANSCRIPT_SKIP_TIME } from "../../../../../constants/transcript";
import useTranscriptContext from "../../../../../contexts/transcript/UseTranscriptContext";
import { UtilsService } from "../../../../../services";

const notification = new ToasterService();

interface IMeetingPlayback {
  onSpeakerItemStart: (
    videoRef: React.MutableRefObject<Function | undefined>
  ) => void;
}

const MeetingPlayback: React.FC<IMeetingPlayback> = ({
  onSpeakerItemStart
}): JSX.Element => {
  const { t } = useTranslation("transcript");
  const matches = useMedia({ queries: UIConstants.APP_DEVICE_MEDIA_QUERIES });

  const { transcriptMeta } = useTranscriptContext();
  const { recordingUrl } = transcriptMeta;
  // Fix - Safari doesn't preload video thumbnail
  // https://muffinman.io/blog/hack-for-ios-safari-to-display-html-video-thumbnail/
  const modifiedVideoUrl = `${recordingUrl}#t=0.001`;

  const videoRef = useRef<HTMLVideoElement>(null);
  const videoTotalDurationRef = useRef<HTMLSpanElement>(null);
  const updateVideoElapsedTextRef = useRef<Function>();
  const updateVideoProgressRef = useRef<Function>();
  const elapsedDurationRef = useRef<HTMLSpanElement>(null);
  const speakerItemPlaybackRef = useRef<Function>();

  const [isPlaying, setPlaying] = useState(false);
  const [videoCanPlay, setVideoCanPlay] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isInitialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    const player = videoRef.current;
    onSpeakerItemStart(speakerItemPlaybackRef);

    updateVideoElapsedTextRef.current = (): void => {
      if (videoRef.current && elapsedDurationRef.current) {
        const elapsedText = UtilsService.displayTimeDuration(
          videoRef.current.currentTime
        );
        elapsedDurationRef.current.innerText = elapsedText;
      }
    };

    updateVideoProgressRef.current = (): void => {
      const { currentTime, duration } = videoRef.current!;
      const percentage = Math.floor((100 / duration) * currentTime);

      if (!isInitialLoading) {
        setProgress(percentage);
        updateVideoElapsedTextRef.current?.();
      }
    };

    speakerItemPlaybackRef.current = (startTime: number): void => {
      if (videoRef.current) {
        videoRef.current.currentTime = startTime;
        videoRef.current.play();
        setPlaying(true);
        updateVideoProgressRef.current?.();
      }
    };

    const onMetaLoadedHandler = (): void => {
      setInitialLoading(false);
      if (videoTotalDurationRef.current) {
        const totalDuration = videoRef.current!.duration;
        const totalDurationText =
          UtilsService.displayTimeDuration(totalDuration);
        videoTotalDurationRef.current.innerText = totalDurationText;
      }
      updateVideoElapsedTextRef.current?.();

      // Fix - https://noegenesis.atlassian.net/browse/CIQ-1853
      if (isSafari) {
        setVideoCanPlay(true);
      }
    };

    const onDurationChangeHandler = (): void => {
      updateVideoProgressRef.current?.();
    };

    const onVideoEndHandler = (): void => {
      setPlaying(false);
    };

    const onLoadedDataHandler = (): void => {
      setVideoCanPlay(true);
    };

    const onCanPlayHandler = (): void => {
      setVideoCanPlay(true);
    };

    const onWaitingHandler = (): void => {
      setVideoCanPlay(false);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onErrorHandler = (event: any): void => {
      if (event.target.error.code) {
        if (event.target.error.MEDIA_ERR_NETWORK) {
          notification.showError(t("playbackInternetError"));
        } else {
          notification.showError(t("playbackError"));
        }
      } else {
        notification.showError(t("playbackError"));
      }
    };

    if (player) {
      // On ready to play current frame
      player.addEventListener("loadeddata", onLoadedDataHandler);
      // On metadata loaded
      player.addEventListener("loadedmetadata", onMetaLoadedHandler);
      // Have enough buffer to play
      player.addEventListener("canplay", onCanPlayHandler);
      // Video stops because it needs to buffer the next frame
      player.addEventListener("waiting", onWaitingHandler);

      player.addEventListener("ended", onVideoEndHandler);

      player.addEventListener("timeupdate", onDurationChangeHandler);

      player.addEventListener("error", onErrorHandler);
    }

    return (): void => {
      player?.removeEventListener("loadedmetadata", onMetaLoadedHandler);
      player?.removeEventListener("timeupdate", onDurationChangeHandler);
      player?.removeEventListener("canplay", onCanPlayHandler);
      player?.removeEventListener("waiting", onWaitingHandler);
      player?.removeEventListener("loadeddata", onLoadedDataHandler);
      player?.removeEventListener("ended", onVideoEndHandler);
      player?.removeEventListener("error", onErrorHandler);
    };
  }, [t, onSpeakerItemStart, isInitialLoading]);

  const handlePlayVideo = (): void => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }

      setPlaying((prevPlay) => !prevPlay);
    }
  };

  const handleProgressChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const percentage = Number(event.target.value);

    if (videoRef.current) {
      const totalDuration = videoRef.current.duration;
      const newCurrentTime = (totalDuration / 100) * percentage;
      videoRef.current.currentTime = newCurrentTime;
      updateVideoElapsedTextRef.current?.();
    }

    setProgress(percentage);
  };

  const forwardSkipVideo = (): void => {
    if (videoRef.current) {
      videoRef.current.currentTime += TRANSCRIPT_SKIP_TIME;
    }
    updateVideoProgressRef.current?.();
  };

  const backwardSkipVideo = (): void => {
    if (videoRef.current) {
      videoRef.current.currentTime -= TRANSCRIPT_SKIP_TIME;
    }
    updateVideoProgressRef.current?.();
  };

  return (
    <div className="player-container">
      <div className="top-container">
        <video
          controls={false}
          ref={videoRef}
          src={modifiedVideoUrl}
          preload="auto"
          playsInline
        />
        {!videoCanPlay && (
          <div className="video-buffer">
            <Loader />
          </div>
        )}
      </div>
      <div className="bottom-container">
        <div className="video-container">
          <div className="video-progress">
            <Input
              onChange={handleProgressChange}
              type="range"
              min={0}
              max={100}
              value={progress}
              disabled={isInitialLoading}
            />
            {!videoCanPlay && matches.small && (
              <div className="audio-buffer">
                <Loader />
              </div>
            )}
          </div>
          <div className="time-container">
            <span className="time-start" ref={elapsedDurationRef}>
              --:--
            </span>
            <span className="time-end" ref={videoTotalDurationRef}>
              --:--
            </span>
          </div>
        </div>
        <div className="controls-container">
          <div
            className={clsx("fast-backward-btn", {
              disabled: isInitialLoading
            })}
            onClick={backwardSkipVideo}
          >
            <span className="ciq-icon ciq-component-icon-ic-skip-15-s-back" />
          </div>
          <div
            className={clsx("play-btn", {
              disabled: isInitialLoading
            })}
            onClick={handlePlayVideo}
          >
            {isPlaying ? (
              <span className="ciq-icon ciq-pause" />
            ) : (
              <span className="ciq-icon ciq-component-icon-ic-play" />
            )}
          </div>
          <div
            className={clsx("fast-forward-btn", {
              disabled: isInitialLoading
            })}
            onClick={forwardSkipVideo}
          >
            <span className="ciq-icon ciq-component-icon-ic-skip-15-s-forward" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetingPlayback;
