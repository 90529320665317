import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { SlidePanel } from "@arbolus-technologies/features/common";
import { PanelId, PanelSelector } from "@arbolus-technologies/stores/panels";

import { RejectFeedback } from "./RejectFeedback";
import { RejectFeedbackPanelData } from "./models";

export const RejectFeedbackPanel: React.FC = () => {
  const { t } = useTranslation("rejectExpert");
  const data = useSelector(
    PanelSelector.panelData<RejectFeedbackPanelData>(
      PanelId.RejectFeedbackPanel
    )
  );

  return (
    <SlidePanel panelId={PanelId.RejectFeedbackPanel} title={t("feedback")}>
      {data ? <RejectFeedback {...data} /> : <></>}
    </SlidePanel>
  );
};
