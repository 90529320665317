import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { IChatMessage } from "@arbolus-technologies/api";
import { CHAT_API_USER_TO_CHAT_USER } from "@arbolus-technologies/models/project";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { Avatar, NotificationDot } from "@arbolus-technologies/ui/components";
import { formatDateBasedOnToday } from "@arbolus-technologies/utils";

import { MessageContent } from "../../MessageContent/MessageContent";
import { ChatAvailabilityButtons } from "./ChatAvailabilityButtons/ChatAvailabilityButtons";
import { ChatMessageActions } from "./ChatMessageActions/ChatMessageActions";

import styles from "./ChatMessage.module.scss";

interface ChatMessageProps {
  message: IChatMessage;
  currentUserId: string;
  openAvailabilityPanel: () => void;
  isCurrentChatMemberOnline: boolean;
  isDncExpertChat: boolean;
}

export const ChatMessage: React.FC<ChatMessageProps> = ({
  message,
  currentUserId,
  openAvailabilityPanel,
  isCurrentChatMemberOnline,
  isDncExpertChat
}) => {
  const { t } = useTranslation("chat");
  const isOutgoingMessage = message.userId === currentUserId;

  const authorName = message.userFullName ?? "Anonymous";
  const authorAvatar = message.userProfileImageUrl ?? "";
  const authorRole = message.userType
    ? t(CHAT_API_USER_TO_CHAT_USER[message.userType])
    : "Unspecified role";
  const authorDescription = isOutgoingMessage
    ? t("outgoingMessageAuthor")
    : `${authorName} - ${authorRole}`;

  const timestamp = message.created.toString();

  return (
    <div className={styles.message} data-testid="chat-message">
      <div className={styles.messageHeader}>
        <div className={styles.authorAndTimestamp}>
          <div className={styles.avatarContainer}>
            <Avatar
              avatar={{ name: authorName ?? "", imageUrl: authorAvatar }}
            />
            {isCurrentChatMemberOnline && (
              <NotificationDot
                offsetTop="0"
                offsetRight="0"
                size="8px"
                color={ARBOLUS_COLORS.bColorSecondaryGreen}
              />
            )}
          </div>
          <span
            className={clsx(styles.author, {
              [styles.outgoingMessageAuthor]: isOutgoingMessage
            })}
          >
            {authorDescription}
          </span>
          <span className={styles.timestamp}>
            {formatDateBasedOnToday(timestamp)}
          </span>
        </div>
        {!isDncExpertChat && (
          <ChatMessageActions
            messageId={message.id}
            isOutgoingMessage={isOutgoingMessage}
          />
        )}
      </div>
      <MessageContent message={message} isDncExpertChat={isDncExpertChat} />
      {!isDncExpertChat && (
        <ChatAvailabilityButtons
          isOutgoingMessage={isOutgoingMessage}
          messageAction={message.messageAction}
          openAvailabilityPanel={openAvailabilityPanel}
        />
      )}
    </div>
  );
};
