import { RadioButton } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { DiscoverFilterType } from "@arbolus-technologies/models/project";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";

import { useDiscoverFilters } from "../../../../Hooks/Discover/useDiscoverFilters";
import { useDiscoverWorkHistoryLocationFilter } from "../../../../Hooks/Discover/useDiscoverWorkHistoryLocationFilter";
import { FilterWrapper } from "../FilterWrapper/FilterWrapper";
import { SearchWithCheckboxPills } from "../SearchWithCheckboxPills/SearchWithCheckboxPills";
import { periodFilterOptions } from "../utils";

import styles from "../FilterWrapper/FilterWrapper.module.scss";

export const WorkHistoryLocationFilter: React.FC = () => {
  const { t } = useTranslation("discoverPage");

  const {
    selectedOptions,
    setSelectedOptions,
    canClearFilter,
    setPreSelectedOptions,
    preSelectedOptions
  } = useDiscoverFilters({
    filterType: DiscoverFilterType.Work_History_Locations
  });
  const {
    handleClearQueryClicked,
    handleInputChangeEvent,
    handleSelectOption,
    handleSetWorkHistoryLocationFilterPeriod,
    filterOptions,
    applyWorkHistoryLocationFilter,
    urlChangeOnWorkHistoryLocationFilterUpdate,
    handleClearFilter
  } = useDiscoverWorkHistoryLocationFilter();

  const workHistoryLocationFilterPeriod = useSelector(
    ProjectNxSelector.workHistoryLocationFilterPeriod()
  );
  const locationFilterQuery = useSelector(ProjectNxSelector.filtersQuery())[
    DiscoverFilterType.Work_History_Locations
  ];

  return (
    <FilterWrapper
      title={t("workHistoryLocationFilterTitle")}
      canClearFilter={canClearFilter}
      onClear={handleClearFilter}
      tooltipText={t("locationFilterDescription")}
      filterCounter={selectedOptions.length}
    >
      <SearchWithCheckboxPills
        onClearQueryClicked={handleClearQueryClicked}
        filterOptions={filterOptions}
        onInputChangeEvent={handleInputChangeEvent}
        searchQuery={locationFilterQuery}
        selectedOptions={selectedOptions}
        preSelectedOptions={preSelectedOptions}
        setSelectedOptions={setSelectedOptions}
        setPreSelectedOptions={setPreSelectedOptions}
        placeholder={t("locationFilterPlaceholder")}
        onBottomReached={() => false}
        onSelectOption={handleSelectOption}
        applyFilter={applyWorkHistoryLocationFilter}
        errorText={t("maxNumberLocations")}
        urlChangeOnFilterUpdate={urlChangeOnWorkHistoryLocationFilterUpdate}
      />
      <div className={styles.radioButtonsContainer}>
        {periodFilterOptions.map((option) => (
          <RadioButton
            key={option.value}
            option={option}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleSetWorkHistoryLocationFilterPeriod(event.target.value)
            }
            isChecked={workHistoryLocationFilterPeriod === option.value}
          />
        ))}
      </div>
    </FilterWrapper>
  );
};
