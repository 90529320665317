import { ConnectedRouter } from "connected-react-router";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";

import { EXPERT_SURVEY, SHARED_ROUTES } from "@arbolus-technologies/routes";
import { Loader } from "@arbolus-technologies/ui/components";

import { AuthRouteConstants } from "../../constants";
import { PUBLIC_ROUTES } from "../../constants/navigation/publicRoutes";
import RBACRoute from "../../contexts/roleBasedAccess/RBACRoute";
import { history } from "../../store/dependencies";
import AuthContainer from "../auth/AuthContainer";
import { AuthSelector } from "../auth/store";
import { DashboardContainer } from "../dashboard/DashboardContainer";
import { ExpertSurveyWithAuth } from "./pages/ExpertSurveyWithAuth";
import { SharedRouter } from "./pages/SharedRouter";
import NotFoundPage from "./pages/errors/404/NotFoundPage";
import { AppStoreActions } from "./store";

const AppContainer: React.FC = () => {
  const dispatch = useDispatch();
  const isProfileLoading = useSelector(AuthSelector.authUserProfileLoading());

  useEffect(() => {
    dispatch(AppStoreActions.getUserProfile(true));
  }, [dispatch]);

  return (
    <div className="app-container-new-menu">
      <ConnectedRouter history={history}>
        {isProfileLoading ? (
          <Loader isFull />
        ) : (
          <Switch>
            <RBACRoute exact path={PUBLIC_ROUTES} component={AuthContainer} />
            <RBACRoute exact path={SHARED_ROUTES} component={SharedRouter} />
            <RBACRoute
              exact
              path={[EXPERT_SURVEY]}
              component={ExpertSurveyWithAuth}
            />
            <RBACRoute
              exact
              path={AuthRouteConstants.AUTH_ROUTES}
              component={DashboardContainer}
            />
            <Route component={NotFoundPage} />
          </Switch>
        )}
      </ConnectedRouter>
    </div>
  );
};

export default AppContainer;
