import React from "react";
import { useTranslation } from "react-i18next";

import { EmptyComponent } from "../EmptyComponent";

import warning from "../../../assets/images/warning.png";

export const GenerateSummaryWarning: React.FC = () => {
  const { t } = useTranslation("canopySummary");
  return (
    <EmptyComponent
      title={t("generateSummaryTitleWarning")}
      subtitle={t("generateSummarySubtitleWarning")}
      logo={warning}
    />
  );
};
