import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  ResponseAllExpertsTable,
  StatusExpertTable
} from "@arbolus-technologies/api";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";

import styles from "./Compliance.module.scss";

interface ComplianceProps {
  row: ResponseAllExpertsTable;
}

const bainId = process.env.NX_PUBLIC_BAIN_ID;

export const Compliance: React.FC<ComplianceProps> = ({ row }) => {
  const { t } = useTranslation("complianceColumn");

  const projectClientId = useSelector(ProjectNxSelector.projectClientId());
  const isBain = projectClientId === bainId;

  const complianceStatus = (complianceStatus: StatusExpertTable | null) => {
    if (!complianceStatus) {
      return (
        <>
          <div className={styles.notRequired} />
          <div>{t("notRequired")}</div>
        </>
      );
    }

    const isReviewPending = row?.applicationStatus !== StatusExpertTable.Accept;

    if (isReviewPending) {
      return <div>{t("reviewPending")}</div>;
    }

    switch (complianceStatus) {
      case StatusExpertTable.Accept:
        return (
          <>
            <div className={styles.circleCompleted} />
            <span>{t("completed")}</span>
          </>
        );
      case StatusExpertTable.Reject:
        return (
          <>
            <div className={styles.circleDeclined} />
            <div>{t("declined")}</div>
          </>
        );
      case StatusExpertTable.Pending:
      default:
        return (
          <>
            <div className={styles.circlePending} />
            <div>{t("pending")}</div>
          </>
        );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {isBain ? (
          <div>{t("cid")}</div>
        ) : (
          complianceStatus(row.complianceStatus)
        )}
      </div>
    </div>
  );
};
