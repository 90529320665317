import i18next from "i18next";
import React from "react";

import { ArbolusModal } from "./ArbolusModal";
import { InternalArbolusModalProps } from "./InternalArbolusModal";

const defaultConfirmText = i18next.t("common:buttons:confirm");
const defaultCancelText = i18next.t("common:buttons:cancel");

type InternalProps = Omit<
  InternalArbolusModalProps,
  "leftButton" | "rightButton"
>;

interface ConfirmationModalProps {
  confirmText?: string;
  cancelText?: string;
  onConfirm: () => void;
  onCancel: () => void;
  type?: "primary" | "confirmation" | "rejection";
}

type ConfirmationProps = InternalProps & ConfirmationModalProps;

export const ConfirmationModal: React.FC<ConfirmationProps> = ({
  type = "primary",
  confirmText = defaultConfirmText,
  cancelText = defaultCancelText,
  onConfirm,
  onCancel,
  ...rest
}) => (
  <ArbolusModal
    {...rest}
    leftButton={{
      type: "tertiary",
      text: cancelText,
      onClick: onCancel
    }}
    rightButton={{
      type: type,
      text: confirmText,
      onClick: onConfirm
    }}
  />
);
