import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  Project,
  ProjectService,
  ToasterService
} from "@arbolus-technologies/api";
import {
  MixpanelPages,
  PRIVACY_POLICY,
  PageTracker
} from "@arbolus-technologies/features/common";
import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { Loader } from "@arbolus-technologies/ui/components";
import { MainPageLayout } from "@arbolus-technologies/ui/layout";
import { useCookie } from "@arbolus-technologies/utils";

import { useTranslation } from "react-i18next";
import { ExpertHeaderButtons } from "../Components/HeaderButtons/ExpertHeaderButtons";
import { ExpertProjectCalendar } from "../Modules/ExpertProjectCalendar/ExpertProjectCalendar";
import { ExpertProjectDocuments } from "../Modules/ProjectDocuments/ExpertProjectDocuments";
import { ExpertActionsMenu } from "../Modules/QuickActionsMenu/ExpertActionsMenu";

import { Alert, Button, Space } from "antd";
import styles from "./ProjectDashboard.module.scss";

export interface ExpertProjectDashboardProps {
  projectService?: typeof ProjectService;
  notificationService?: ToasterService;
}

export const ExpertProjectDashboard: React.FC<ExpertProjectDashboardProps> = ({
  projectService = ProjectService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("adminProjectDashboard");
  const { projectId } = useParams<{ projectId: string }>();

  const [projectInfo, setProjectInfo] = useState<Project>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { setCookie, cookieStored } = useCookie("privacyPolicyUpdate");

  const loggedInExpert = useSelector(CacheSelector.loggedInUser());
  const isDnc = loggedInExpert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC;

  const getProject = useCallback((): void => {
    setIsLoading(true);
    projectService.getProject(projectId).subscribe(
      (project) => {
        setProjectInfo(project);
        setIsLoading(false);
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
        setIsLoading(false);
      }
    );
  }, [notificationService, projectId, projectService]);

  useEffect(() => {
    getProject();
  }, [getProject]);

  return (
    <>
      {isLoading && <Loader isFull />}
      {!isLoading && (
        <>
          {projectInfo && (
            <PageTracker page={MixpanelPages.ProjectDashboard}>
              <MainPageLayout
                title={projectInfo.name}
                rightButtonContainer={
                  <ExpertHeaderButtons
                    projectId={projectInfo.id}
                    isDnc={isDnc}
                  />
                }
              >
                {!cookieStored && (
                  <Alert
                    message={t("privacyPolicyUpdate")}
                    type="info"
                    action={
                      <Space direction="horizontal">
                        <Button
                          size="small"
                          type="link"
                          href={PRIVACY_POLICY}
                          target="_blank"
                          onClick={setCookie}
                        >
                          {t("readMore")}
                        </Button>
                      </Space>
                    }
                    closable
                    showIcon
                    onClose={setCookie}
                  />
                )}
                <ExpertActionsMenu
                  projectId={projectInfo.id}
                  isLoading={isLoading}
                  isDnc={isDnc}
                />
                <div className={styles.splitView}>
                  <ExpertProjectCalendar projectId={projectInfo.id} />
                  {!isDnc && (
                    <ExpertProjectDocuments projectId={projectInfo.id} />
                  )}
                </div>
              </MainPageLayout>
            </PageTracker>
          )}
        </>
      )}
    </>
  );
};
