import { GetCollectionSuccessAction } from "../actions/GetCollection/GetCollectionActionTypes";
import { BookmarksReducerState } from "../models/definitions";
import { initialState } from "./InitialState";

export const handleGetCollection = (
  state = initialState
): BookmarksReducerState => ({
  ...state,
  collection: {
    isLoading: true
  }
});

export const handleGetCollectionSuccess = (
  state = initialState,
  { payload: { response } }: GetCollectionSuccessAction
): BookmarksReducerState => ({
  ...state,
  collection: {
    isLoading: false,
    item: response
  }
});

export const handleGetCollectionFailure = (
  state = initialState
): BookmarksReducerState => ({
  ...state,
  collection: {
    isLoading: false
  }
});
