/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/static-property-placement */
import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { CONTENT_PANEL_ANIMATION_TIME } from "../../constants/timer";
import {
  closeContentPanel,
  openContentPanel
} from "../../containers/app/store/actions/ContentPanelActions";
import { AppAction } from "../../store/actions";
import {
  ContentPanelContextProps,
  ContentPanelContextProvider
} from "./ContentPanelContext";

export interface ContentPanelContextProviderProps {
  children: ReactNode;
  dispatchOpenPanel: (panelKey: string) => void;
  dispatchClosePanel: (panelKey: string) => void;
}

export interface ContentPanelContextProviderState {
  isPanelExpand: boolean;
  isPanelLocked: boolean;
  panelClass: string;
}

class ContentPanelProvider extends React.Component<
  ContentPanelContextProviderProps,
  ContentPanelContextProviderState
> {
  static defaultProps = {
    dispatchOpenPanel: (): void => {},
    dispatchClosePanel: (): void => {},
    children: null
  };

  constructor(props: ContentPanelContextProviderProps) {
    super(props);
    this.state = {
      isPanelExpand: false,
      isPanelLocked: false,
      panelClass: ""
    };
  }

  componentWillUnmount(): void {
    this.contentExpireTimeout && clearTimeout(this.contentExpireTimeout);
  }

  private content?: React.ReactElement;

  private contentExpireTimeout?: number;

  private contentPanelCloseCallback?: Function;

  handleContentPanelExpand = (): void => {
    if (!this.content) {
      return;
    }
    this.setState({
      isPanelExpand: true
    });
  };

  handleSetContent = (
    content: React.ReactElement,
    isExpand = false,
    panelClass = "",
    closeCallBack = (): void => {}
  ): void => {
    this.contentExpireTimeout && clearTimeout(this.contentExpireTimeout);
    this.content = content;
    this.contentPanelCloseCallback = closeCallBack;

    this.setState({
      isPanelExpand: isExpand,
      panelClass
    });

    if (isExpand) {
      this.props.dispatchOpenPanel(`${content.key}`);
    }
  };

  handlePanelLock = (isPanelLocked: boolean): void => {
    this.setState({
      isPanelLocked
    });
  };

  handleContentPanelClose = (): void => {
    const { isPanelLocked } = this.state;

    if (isPanelLocked) return;

    this.props.dispatchClosePanel(`${this.content!.key}`);

    if (this.contentPanelCloseCallback) {
      this.contentPanelCloseCallback();
    }

    this.setState({
      isPanelExpand: false
    });

    this.contentExpireTimeout = setTimeout(() => {
      this.content = undefined;
      this.contentPanelCloseCallback = undefined;
      this.setState({ panelClass: "" });
    }, CONTENT_PANEL_ANIMATION_TIME);
  };

  render(): JSX.Element {
    const { isPanelExpand, isPanelLocked, panelClass } = this.state;
    const { children } = this.props;
    const customContentPanelContext: ContentPanelContextProps = {
      isPanelExpand,
      isPanelLocked,
      panelClass,
      content: this.content,
      setPanelLock: this.handlePanelLock,
      closePanel: this.handleContentPanelClose,
      expandPanel: this.handleContentPanelExpand,
      setContent: this.handleSetContent
    };

    return (
      <ContentPanelContextProvider value={customContentPanelContext}>
        {children}
      </ContentPanelContextProvider>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): Record<string, AppAction> => ({
  dispatchOpenPanel: (panelKey: string): AppAction => {
    dispatch(openContentPanel(panelKey));
  },
  dispatchClosePanel: (panelKey: string): AppAction => {
    dispatch(closeContentPanel(panelKey));
  }
});

export default connect(null, mapDispatchToProps)(ContentPanelProvider);
