import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import {
  CIQError,
  CanopyService,
  ErrorResponse
} from "@arbolus-technologies/api";
import { MultiChoiceViewer } from "@arbolus-technologies/features/common";
import { CanopyParamUrlTypes } from "@arbolus-technologies/models/canopy";
import { AnswerMultiChoice } from "@arbolus-technologies/models/canopy-panels";
import { Loader } from "@arbolus-technologies/ui/components";

interface QuestionMultiAnswersProps {
  canopyService?: typeof CanopyService;
}

export const QuestionMultiAnswers: React.FC<QuestionMultiAnswersProps> = ({
  canopyService = CanopyService
}) => {
  const { t } = useTranslation("canopyV2");
  const { canopyQuestionId } = useParams<CanopyParamUrlTypes>();
  const [multiAnswers, setMultiAnswers] = useState<AnswerMultiChoice | null>(
    null
  );
  const [isMultiAnswersLoading, setIsMultiAnswersLoading] =
    useState<boolean>(false);

  const getQuestionMultiAnswers = () => {
    setIsMultiAnswersLoading(true);

    canopyService.getCanopyQuestionMultiAnswers(canopyQuestionId).subscribe(
      (response) => {
        setMultiAnswers(response);
        setIsMultiAnswersLoading(false);
      },
      (error: ErrorResponse<CIQError>) => {
        setIsMultiAnswersLoading(false);
      }
    );
  };

  useEffect(() => {
    getQuestionMultiAnswers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isMultiAnswersLoading && <Loader />}
      {!isMultiAnswersLoading && (
        <>
          {multiAnswers && (
            <>
              {multiAnswers.choiceAnswers.length === 0 && t("noAnswers")}
              {multiAnswers.choiceAnswers.length > 0 && (
                <MultiChoiceViewer
                  result={{
                    choiceAnswers: multiAnswers.choiceAnswers,
                    lastResponseDate: multiAnswers.lastResponseDate,
                    rangeMax: multiAnswers.rangeMax,
                    rangeMin: multiAnswers.rangeMin,
                    totalAnswersCount: multiAnswers.totalAnswersCount
                  }}
                />
              )}
            </>
          )}
          {!multiAnswers && t("noAnswers")}
        </>
      )}
    </>
  );
};
