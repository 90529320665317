import {
  CanopySurveyLaunchedNotificationData,
  EventInvitesNotificationData,
  ExpertAddedToProjectClientNotificationData,
  ExpertCandidateRejectedAdminNotificationData,
  ExpertMessagesClientTeamNotificationData,
  ExpertSubmittedCanopyNotificationData,
  ExpertsAssignedToProjectNotificationData,
  INBOX_NOTIFICATION_TYPE,
  InboxNotification,
  ProjectUnreadMessagesNotificationData
} from "@arbolus-technologies/api";

import { BaseNotification } from "./BaseNotification";
import { CanopyCompletedNotification } from "./CanopyCompletedNotification";
import { CanopyLaunchedNotification } from "./CanopyLaunchedNotification";
import { ExpertsApprovedNotification } from "./ExpertsApprovedNotification";
import { ExpertsRejectedNotification } from "./ExpertsRejectedNotification";
import { ExpertsToReviewNotification } from "./ExpertsToReviewNotification";
import { NewEventNotification } from "./NewEventNotification";
import { UnreadMessagesNotification } from "./UnreadMessagesNotification";

type NotificationDataToBaseNotification = {
  [K in INBOX_NOTIFICATION_TYPE | "default"]: (
    notification: Extract<InboxNotification, { notificationData: { type: K } }>
  ) => BaseNotification | undefined;
};

const mapper: NotificationDataToBaseNotification = {
  [INBOX_NOTIFICATION_TYPE.EventNotification]: (
    notification: InboxNotification<EventInvitesNotificationData>
  ) => {
    const { eventName, eventId, projectId, startTime, endTime } =
      notification.notificationData;
    return new NewEventNotification(
      notification.id,
      eventName,
      startTime,
      endTime,
      projectId,
      eventId
    );
  },

  [INBOX_NOTIFICATION_TYPE.CanopySurveyLaunchedNotification]: (
    notification: InboxNotification<CanopySurveyLaunchedNotificationData>
  ) => {
    const { canopyId, canopyTitle } = notification.notificationData;
    return new CanopyLaunchedNotification(
      notification.id,
      canopyId,
      canopyTitle,
      notification.created
    );
  },

  [INBOX_NOTIFICATION_TYPE.ExpertSubmittedCanopyNotification]: (
    notification: InboxNotification<ExpertSubmittedCanopyNotificationData>
  ) => {
    const { canopyId, canopyTitle } = notification.notificationData;
    return new CanopyCompletedNotification(
      notification.id,
      canopyId,
      canopyTitle,
      notification.created
    );
  },

  [INBOX_NOTIFICATION_TYPE.ProjectUnreadMessagesNotification]: (
    notification: InboxNotification<ProjectUnreadMessagesNotificationData>
  ) => {
    const { messageCount, projectId, projectName } =
      notification.notificationData;
    return new UnreadMessagesNotification(
      notification.id,
      messageCount,
      notification.created,
      projectId,
      projectName
    );
  },

  [INBOX_NOTIFICATION_TYPE.ExpertMessagesClientTeamNotification]: (
    notification: InboxNotification<ExpertMessagesClientTeamNotificationData>
  ) => {
    const { projectId, projectName } = notification.notificationData;
    return new UnreadMessagesNotification(
      notification.id,
      1,
      notification.created,
      projectId,
      projectName
    );
  },

  [INBOX_NOTIFICATION_TYPE.ExpertsAssignedToProjectNotification]: (
    notification: InboxNotification<ExpertsAssignedToProjectNotificationData>
  ) => {
    const { numOfCandidates, projectId, projectName } =
      notification.notificationData;
    return new ExpertsToReviewNotification(
      notification.id,
      numOfCandidates,
      notification.created,
      projectId,
      projectName
    );
  },

  [INBOX_NOTIFICATION_TYPE.ExpertAddedToProjectClientNotification]: (
    notification: InboxNotification<ExpertAddedToProjectClientNotificationData>
  ) => {
    const { numOfApprovedCandidates, projectId, projectName } =
      notification.notificationData;
    return new ExpertsApprovedNotification(
      notification.id,
      numOfApprovedCandidates,
      notification.created,
      projectId,
      projectName
    );
  },

  [INBOX_NOTIFICATION_TYPE.ExpertAddedToProjectAdminNotification]: (
    _: InboxNotification
  ) => undefined,
  [INBOX_NOTIFICATION_TYPE.ProjectLiveAdminNotification]: (
    _: InboxNotification
  ) => undefined,
  [INBOX_NOTIFICATION_TYPE.ProjectBriefUpdatedNotification]: (
    _: InboxNotification
  ) => undefined,
  [INBOX_NOTIFICATION_TYPE.ExpertCandidateRejectedAdminNotification]: (
    notification: InboxNotification<ExpertCandidateRejectedAdminNotificationData>
  ) => {
    const { projectId, projectName } = notification.notificationData;
    return new ExpertsRejectedNotification(
      notification.id,
      1,
      notification.created,
      projectId,
      projectName
    );
  },
  [INBOX_NOTIFICATION_TYPE.ApplicationCompletionAdminNotification]: (
    _: InboxNotification
  ) => undefined,
  [INBOX_NOTIFICATION_TYPE.DeclinedApplicationAdminNotification]: (
    _: InboxNotification
  ) => undefined,
  [INBOX_NOTIFICATION_TYPE.TranscriptAvailableNotification]: (
    _: InboxNotification
  ) => undefined,
  [INBOX_NOTIFICATION_TYPE.MemberAcceptInvitationAdminNotification]: (
    _: InboxNotification
  ) => undefined,
  [INBOX_NOTIFICATION_TYPE.ExpertAcceptInvitationAdminNotification]: (
    notification: InboxNotification<ExpertAddedToProjectClientNotificationData>
  ) => {
    const { projectId, numOfApprovedCandidates, projectName } =
      notification.notificationData;
    return new ExpertsApprovedNotification(
      notification.id,
      numOfApprovedCandidates,
      notification.created,
      projectId,
      projectName
    );
  },
  [INBOX_NOTIFICATION_TYPE.PendingProjectReviewNotification]: (
    _: InboxNotification
  ) => undefined,
  default: (_: InboxNotification) => undefined
};

export const inboxNotificationMapper = (
  notification: InboxNotification
): BaseNotification | undefined =>
  (mapper[notification.notificationData.type] ?? mapper.default)(
    notification as never
  );
