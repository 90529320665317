import { Observable } from "rxjs";

import { PROJECTS_API } from "../constants/api";
import { EXPERTS_API } from "../endpoints/experts";
import { SORT_DIRECTION } from "../enums/apiEnums";
import { CALL_STATUSES, STATUSES } from "../enums/projectExpertEnums";
import { ApiPaginatedResponse, SuccessResponse } from "../models/api";
import { ExpertDetail } from "../models/expert";
import {
  ExpertsListPageTab,
  ProjectReferralAvailability,
  RecommendationResponse,
  ResponseAllExpertsTable
} from "../models/projects";
import { restService } from "../restService";
import { restServiceV2 } from "../restServiceV2";

const ALLOWED_STATUSES = [
  ExpertsListPageTab.ShortList,
  ExpertsListPageTab.Candidate,
  ExpertsListPageTab.Accept,
  ExpertsListPageTab.Reject
];

export const ProjectExpertsService = {
  getAllExpertsList: (
    angleId: string,
    activeTab: ExpertsListPageTab | null
  ): Observable<ApiPaginatedResponse<ResponseAllExpertsTable>> => {
    const status =
      activeTab && ALLOWED_STATUSES.includes(activeTab)
        ? [activeTab]
        : [
            ExpertsListPageTab.Candidate,
            ExpertsListPageTab.Accept,
            ExpertsListPageTab.Reject
          ];

    return restService.get<ApiPaginatedResponse<ResponseAllExpertsTable>>(
      PROJECTS_API.GET_PROJECT_REFERRALS(angleId),
      {
        status,
        Limit: 2000
      }
    );
  },
  getRecommendations: (
    projectId: string
  ): Observable<ApiPaginatedResponse<RecommendationResponse>> =>
    restServiceV2.get<ApiPaginatedResponse<RecommendationResponse>>(
      PROJECTS_API.GET_PROJECT_RECOMMENDATIONS(projectId),
      {
        projectId,
        orderBy: "RecommendationLevel",
        orderDirection: SORT_DIRECTION.DESCENDING
      }
    ),
  getExpert: (expertId: string): Observable<ExpertDetail> =>
    restService.get<ExpertDetail>(EXPERTS_API.GET_EXPERT(expertId)),
  updateReferralAngle: (
    referralId: string,
    angleId: string
  ): Observable<SuccessResponse> =>
    restService.put<SuccessResponse>(
      PROJECTS_API.UPDATE_REFERRAL_ANGLE(referralId, angleId),
      {}
    ),
  getScheduleAvailabilityList: (
    projectId: string,
    from?: string,
    to?: string,
    statuses?: STATUSES[],
    angleIds?: string[],
    callStatuses?: CALL_STATUSES[]
  ): Observable<ProjectReferralAvailability> =>
    restService.get<ProjectReferralAvailability>(
      PROJECTS_API.GET_PROJECT_REFERRALS_AVAILABILITIES(projectId),
      {
        from,
        to,
        statuses,
        angleIds,
        callStatuses
      }
    )
};
