import i18next from "i18next";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CANOPY_ORDER_BY,
  SORT_DIRECTION,
  SortingParams
} from "@arbolus-technologies/api";
import { CANOPY_STATUS } from "@arbolus-technologies/models/canopy";
import {
  ArbolusDropdown,
  SearchInput
} from "@arbolus-technologies/ui/components";

import styles from "./CanopyListFilters.module.scss";

export type CanopyListFilterParams = SortingParams<CANOPY_ORDER_BY> & {
  searchTerm: string;
  status?: CANOPY_STATUS;
};

const options: { label: string; value: SortingParams<CANOPY_ORDER_BY> }[] = [
  {
    label: i18next.t("canopyList:mostExperts"),
    value: {
      orderBy: CANOPY_ORDER_BY.EXPERT_COUNT,
      orderDirection: SORT_DIRECTION.DESCENDING
    }
  },
  {
    label: i18next.t("canopyList:leastExperts"),
    value: {
      orderBy: CANOPY_ORDER_BY.EXPERT_COUNT,
      orderDirection: SORT_DIRECTION.ASCENDING
    }
  },
  {
    label: i18next.t("canopyList:newestAnswer"),
    value: {
      orderBy: CANOPY_ORDER_BY.LAST_ANSWER_DATE,
      orderDirection: SORT_DIRECTION.DESCENDING
    }
  },
  {
    label: i18next.t("canopyList:oldestAnswer"),
    value: {
      orderBy: CANOPY_ORDER_BY.LAST_ANSWER_DATE,
      orderDirection: SORT_DIRECTION.ASCENDING
    }
  }
];

const filterStatusOptions: {
  label: string;
  value: CANOPY_STATUS[];
}[] = [
  {
    label: "All",
    value: [
      CANOPY_STATUS.ACTIVE,
      CANOPY_STATUS.ARCHIVED,
      CANOPY_STATUS.PAUSED,
      CANOPY_STATUS.DRAFT
    ]
  },
  {
    label: "Active",
    value: [CANOPY_STATUS.ACTIVE]
  },
  {
    label: "Draft",
    value: [CANOPY_STATUS.DRAFT]
  },
  {
    label: "Paused",
    value: [CANOPY_STATUS.PAUSED]
  },
  {
    label: "Archived",
    value: [CANOPY_STATUS.ARCHIVED]
  }
];

interface CanopyListFiltersProps {
  onFilterChange: (filterParams: CanopyListFilterParams) => void;
}

export const CanopyListFilters: React.FC<CanopyListFiltersProps> = ({
  onFilterChange
}) => {
  const { t } = useTranslation("canopyList");
  const [params, setParams] = useState<
    CanopyListFilterParams & { labelOrder: string; labelStatus: string }
  >({
    searchTerm: "",
    orderBy: CANOPY_ORDER_BY.LAST_ANSWER_DATE,
    orderDirection: SORT_DIRECTION.DESCENDING,
    labelOrder: t("newestAnswer"),
    labelStatus: t("All")
  });

  const onQueryChange = useCallback(
    (searchTerm: string) => {
      setParams((prev) => ({ ...prev, searchTerm }));
      onFilterChange({ ...params, searchTerm });
    },
    [onFilterChange, params]
  );

  const onFilterButton = useCallback(
    ({ label, value }: { label: string; value: CANOPY_STATUS }) => {
      setParams((prev) => ({ ...prev, labelStatus: label, status: value }));
      onFilterChange({ ...params, status: value });
    },
    [onFilterChange, params]
  );

  const onSortChange = useCallback(
    ({
      label,
      value
    }: {
      label: string;
      value: SortingParams<CANOPY_ORDER_BY>;
    }) => {
      setParams((prev) => ({ ...prev, labelOrder: label, ...value }));
      onFilterChange({ ...params, ...value });
    },
    [onFilterChange, params]
  );

  return (
    <div className="d-flex flex-row w-100">
      <div className={styles.search}>
        <SearchInput
          onQueryChange={onQueryChange}
          minimumSearchLength={3}
          debouncingTime={500}
          isDebounced
          hasSearchIcon
          placeholder={t("searchPlaceholder")}
        />
      </div>

      <div className={styles.sort}>
        <ArbolusDropdown
          options={options}
          disabled={
            params.status?.includes(CANOPY_STATUS.DRAFT) &&
            params.status.length === 1
          }
          onSelect={onSortChange}
          selectedLabel={params.labelOrder}
        />
        <ArbolusDropdown
          options={filterStatusOptions as any}
          onSelect={onFilterButton}
          selectedLabel={params.labelStatus}
        />
      </div>
    </div>
  );
};
