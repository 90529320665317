import { Icon } from "arbolus-ui-components";
import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./Header.module.scss";

interface HeaderProps {
  title?: string;
  subtitle?: string;
  text?: string;
  shareOnClick?: () => void;
  bookmarkOnClick?: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  text,
  bookmarkOnClick,
  shareOnClick,
  title,
  subtitle
}) => (
  <div className={styles.container}>
    <div className={styles.titleContainer}>
      {title && <span className={styles.title}>{title}</span>}
      {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
      {text && <span className={styles.text}>{text}</span>}
    </div>
    {(bookmarkOnClick || shareOnClick) && (
      <div className={styles.buttonsContainer}>
        {shareOnClick && (
          <Icon
            fontSize="24px"
            name="share"
            color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
            onClick={shareOnClick}
          />
        )}
        {bookmarkOnClick && (
          <Icon
            fontSize="24px"
            name="bookmark"
            color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
            onClick={bookmarkOnClick}
          />
        )}
      </div>
    )}
  </div>
);
