import clsx from "clsx";
import React from "react";

import { User } from "@arbolus-technologies/models/project";
import { utilService } from "@arbolus-technologies/utils";

import { Avatar } from "../Avatar/Avatar";

interface UserRecordProps {
  user: User;
  selected: boolean;
  onSelected: (user: User) => void;
  onUnselected?: (user: User) => void;
}

export const UserRecord: React.FC<UserRecordProps> = ({
  user,
  selected,
  onSelected,
  onUnselected
}: UserRecordProps) => {
  const handleItemSelect = (): void => {
    onSelected(user);
  };

  const handleItemUnselect = (): void => {
    if (onUnselected) {
      onUnselected(user);
    }
  };

  const displayName = utilService.displayUserName(user, true);
  return (
    <div className="user-item">
      <div className="left-container">
        <div className="users-avatar">
          <Avatar
            avatar={{
              name: displayName,
              imageUrl: user.profileImageUrl
            }}
          />
        </div>
        <div className="user-name">{displayName}</div>
      </div>
      <div className="right-container">
        {selected && <span className="ciq-icon ciq-check" />}
        <span
          className={clsx("ciq-icon ", {
            "ciq-close": selected,
            "ciq-plus": !selected
          })}
          onClick={selected ? handleItemUnselect : handleItemSelect}
        />
      </div>
    </div>
  );
};
