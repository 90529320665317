export enum PROJECT_REFERRAL_STATE {
  SHORTLIST = "ShortList",
  CANDIDATE = "Candidate",
  ACCEPT = "Accept",
  REJECT = "Reject"
}

export enum PROJECT_STATUS {
  ACTIVE = "Active",
  ARCHIVE = "Archive",
  DRAFT = "Draft"
}

export enum PROJECT_APPLICATION_STATUS {
  PENDING = "Pending",
  ACCEPT = "Accept",
  REJECT = "Reject"
}

export enum EARLIEST_AVAILABILITIES_ORDER_BY {
  EARLIEST_AVAILABILITIES = "EarliestAvailability"
}

export enum PROJECT_STATES {
  ACTIVE = "Active",
  DRAFT = "Draft",
  ARCHIVE = "Archive"
}

export enum CHAT_EVENT_STATUS {
  NEW = "New",
  MODIFIED = "Modified",
  DELETED = "Deleted"
}
