import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";

import styles from "./BoxWithChildren.module.scss";

interface BoxWithChildrenChildrenProps {
  children: JSX.Element;
  specialBorder?: string;
  fullWidth?: boolean;
  flex1?: boolean;
  customWidth?: string;
  classnames?: string;
  boxShadow?: boolean;
  to?: string;
}
export const BoxWithChildren: React.FC<BoxWithChildrenChildrenProps> = ({
  children,
  specialBorder,
  fullWidth,
  flex1,
  customWidth,
  classnames,
  boxShadow,
  to
}) =>
  to ? (
    <Link
      to={to}
      className={clsx(styles.container, classnames, {
        [styles.fullWidth]: fullWidth,
        [styles.flex1]: flex1,
        [styles.boxShadow]: boxShadow
      })}
      style={{
        border: specialBorder,
        width: customWidth
      }}
    >
      {children}
    </Link>
  ) : (
    <div
      className={clsx(styles.container, classnames, {
        [styles.fullWidth]: fullWidth,
        [styles.flex1]: flex1,
        [styles.boxShadow]: boxShadow
      })}
      style={{
        border: specialBorder,
        width: customWidth
      }}
    >
      {children}
    </div>
  );
