import React from "react";

import { AdminService, CIQUser } from "@arbolus-technologies/api";

import { UserSelector, UserSelectorProps } from "./UserSelector";

type AdminSelectorProps = Omit<UserSelectorProps<CIQUser>, "getItems">;

export const AdminSelector: React.FC<AdminSelectorProps> = (props) => (
  <UserSelector {...props} getItems={AdminService.getAdminUsers} />
);
