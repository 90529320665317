import { Icon } from "arbolus-ui-components";
import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, InputGroup, InputGroupText } from "reactstrap";

import {
  CIQError,
  ErrorResponse,
  ProjectService
} from "@arbolus-technologies/api";
import { DataMessageGPT } from "@arbolus-technologies/models/project";
import { PanelId } from "@arbolus-technologies/stores/panels";
import { ARBOLUS_COLORS, thinkingFace } from "@arbolus-technologies/theme";
import { HR, InfoDrawer } from "@arbolus-technologies/ui/components";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { SlidePanel } from "../SlidePanel/SlidePanel";
import { MessagesComponent } from "./MessagesComponent";

import styles from "./ArbolusGPTPanel.module.scss";

interface ArbolusGPTPanelProps {
  handleCloseRequest: () => void;
  projectId: string;
  projectService?: typeof ProjectService;
}

export interface MessageGPT {
  message: string;
  data: DataMessageGPT[];
}

export const ArbolusGPTPanel: React.FC<ArbolusGPTPanelProps> = ({
  handleCloseRequest,
  projectId,
  projectService = ProjectService
}) => {
  const { t } = useTranslation("arbolusGPT");

  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [messages, setMessages] = useState<
    {
      messageSent?: string;
      gpt?: MessageGPT;
    }[]
  >([]);

  const contentDrawer = (
    <div className={styles.contentDrawerContainer}>
      <span>{t("contentGPTDrawer1")}</span>
      <span>{t("contentGPTDrawer2")}</span>
    </div>
  );

  const handleInputOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const sendMessage = () => {
    setIsLoading(true);
    setMessages([
      ...messages,
      {
        messageSent: searchTerm
      }
    ]);

    setSearchTerm("");
    projectService.askGpt(projectId, searchTerm).subscribe(
      (response) => {
        setIsLoading(false);
        setMessages((messages) => {
          // Find the index of the recently added message
          const indexOfRecentMessage = messages.length - 1;

          // Update the message with the GPT response
          return messages.map((message, index) => {
            if (index === indexOfRecentMessage) {
              return {
                ...message,
                gpt: {
                  message: response.answer,
                  data: response.data
                }
              };
            }
            return message;
          });
        });
      },
      (error: ErrorResponse<CIQError>) => {
        setIsLoading(false);
      }
    );
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") sendMessage();
  };

  return (
    <SlidePanel
      panelId={PanelId.ArbolusGpt}
      width={SIDE_PANEL_SIZE._720}
      closeButtonDirection="right"
      hideHeader
      noPadding
    >
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <Icon
            fontSize="24px"
            name="magic_button"
            color={ARBOLUS_COLORS.bColorBasePurple}
          />
          <span className={styles.title}>{t("welcomeArbolusGPT")}</span>
          <div className={styles.closeContainer}>
            <Icon
              name="close"
              onClick={() => handleCloseRequest()}
              fontSize="24px"
              color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
            />
          </div>
        </div>
        <div className={styles.drawerContainer}>
          <HR margin={{ top: 2, bottom: 2 }} />
          <InfoDrawer
            icon={thinkingFace}
            title={t("whatIsArbolusGPT")}
            collapseContent={contentDrawer}
            isOpenByDefault={false}
          />
        </div>
        {messages.length === 0 && (
          <div className={styles.whatDoUWant}>
            <div className={styles.midMessage}>
              <div className={styles.iconRound}>
                <Icon
                  fontSize="20px"
                  color={ARBOLUS_COLORS.bColorBaseWhite}
                  name="magic_button"
                  filled
                />
              </div>
              <span className={styles.midMessageTitle}>
                {t("midMessageTitle")}
              </span>
              <span className={styles.midMessageContent}>
                {t("midMessageContent1")}
              </span>
              <span className={styles.midMessageContent}>
                {t("midMessageContent2")}
              </span>
            </div>
          </div>
        )}
        {messages.length > 0 && (
          <MessagesComponent
            messages={messages}
            loading={isLoading}
            projectId={projectId}
          />
        )}
        <InputGroup>
          <Input
            className={styles.input}
            onChange={(e) => handleInputOnChange(e)}
            onKeyDown={(e) => handleKeyDown(e)}
            inputValue={searchTerm}
            value={searchTerm}
            disabled={isLoading}
          />
          <InputGroupText className={styles.inputGroupText}>
            <Icon
              name="send"
              fontSize="24px"
              onClick={sendMessage}
              disabled={isLoading || !searchTerm}
            />
          </InputGroupText>
        </InputGroup>
      </div>
    </SlidePanel>
  );
};
