import {
  GET_APP_TYPE_USER,
  GetAppTypeAction
} from "./CacheGetAppTypeActionTypes";

export const getAPPType = (isAdmin: boolean): GetAppTypeAction => ({
  type: GET_APP_TYPE_USER,
  payload: {
    isAdmin
  }
});
