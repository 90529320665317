import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./IconTextItem.module.scss";

interface IconTextItemProps {
  iconName: string;
  text?: string;
}

export const IconTextItem: React.FC<IconTextItemProps> = ({
  iconName,
  text
}) => {
  const { t } = useTranslation("clientUserSlidePanel");

  return (
    <div className={styles.textWrapper}>
      <Icon name={iconName} fontSize="18px" color="#6157fc" />
      {iconName === "email" ? (
        <a
          className={styles.textEmail}
          href={`mailto:${text}`}
          rel="noreferrer"
          target="_blank"
        >
          {text ?? t("notSet")}
        </a>
      ) : (
        <span
          className={clsx(
            styles.text,
            iconName === "phone" && styles.phoneText
          )}
        >
          {text ?? t("notSet")}
        </span>
      )}
    </div>
  );
};
