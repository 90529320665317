import { LocationDescriptorObject } from "history";
import i18next from "i18next";

import { PROJECT_ALL_REFERRALS } from "@arbolus-technologies/routes";
import { formatDateBasedOnToday } from "@arbolus-technologies/utils";

import { BaseNotification } from "./BaseNotification";

export class ExpertsToReviewNotification extends BaseNotification {
  title: string;
  subtitle: string;
  link: LocationDescriptorObject;

  constructor(
    id: string,
    count: number,
    created: string,
    projectId: string,
    projectName: string
  ) {
    super(id, "person_search");
    this.title = i18next.t("dashboardNotifications:expertsToReview:title", {
      count: Number(count),
      projectName
    });
    this.subtitle = formatDateBasedOnToday(created);
    this.link = PROJECT_ALL_REFERRALS(projectId);
  }
}
