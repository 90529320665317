import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import {
  ApiPaginatedRequest,
  BaseProject,
  DefaultToasterService,
  ProjectClientService,
  SORT_DIRECTION,
  ToasterService
} from "@arbolus-technologies/api";
import {
  MixPanelEventNames,
  ProjectCard,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { PROJECT_DRAFT_BASE_ROUTE } from "@arbolus-technologies/routes";
import {
  DashboardEmptyProjects,
  Loader
} from "@arbolus-technologies/ui/components";
import { GrayBox } from "@arbolus-technologies/ui/layout";

import styles from "./DashboardProjects.module.scss";

interface DashboardProjectsProps {
  route: string;
  projectClientService?: typeof ProjectClientService;
  notificationService?: ToasterService;
}

const request: ApiPaginatedRequest = {
  offset: 0,
  limit: 3,
  orderBy: "created",
  orderDirection: SORT_DIRECTION.DESCENDING
};

export const DashboardProjects: React.FC<DashboardProjectsProps> = ({
  projectClientService = ProjectClientService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("dashboardProjects");
  const [projects, setProjects] = useState<BaseProject[] | null>(null);
  const [isProjectsLoading, setIsProjectsLoading] = useState<boolean>(false);
  const [projectCount, setProjectCount] = useState<number>(0);

  const history = useHistory();
  const { trackClick } = useArbolusTracking();

  useEffect(() => {
    setIsProjectsLoading(true);
    projectClientService.getClientProjects(request, "active").subscribe({
      next: (response) => {
        setProjects(response.items);
        setProjectCount(response.pagination.count);
        setIsProjectsLoading(false);
      },
      error: () => {
        setIsProjectsLoading(false);
        return notificationService.showApiErrors;
      }
    });
  }, [notificationService, projectClientService]);

  return (
    <GrayBox
      title={{
        text: t("title", { count: projectCount }),
        to: "/projects"
      }}
      flexDirection="column"
      gap={[2, 0]}
      fullWidth
      actionComponent={
        <Button
          iconPosition="end"
          type="link"
          icon={<AntDIcon name="add" />}
          onClick={() => {
            trackClick(MixPanelEventNames.CreateSimplifiedProject);
            return history.push(PROJECT_DRAFT_BASE_ROUTE().pathname!);
          }}
        >
          {t("newProject")}
        </Button>
      }
    >
      <div className={styles.cardContainer}>
        {isProjectsLoading && <Loader />}
        {!isProjectsLoading && (
          <>
            {projects === null && <DashboardEmptyProjects />}
            {projects !== null && (
              <>
                {projects.length === 0 && <DashboardEmptyProjects />}
                {projects.length > 0 &&
                  projects.map((project) => (
                    <ProjectCard
                      key={project.id}
                      id={project.id}
                      name={project.name}
                      status={project.projectState}
                      created={project.created}
                    />
                  ))}
              </>
            )}
          </>
        )}
      </div>
    </GrayBox>
  );
};
