import React from "react";

import { Avatar } from "../../Avatar/Avatar";

interface ExpertRendererProps {
  name: string;
  profileImageUrl?: string;
}

export const ExpertRenderer: React.FC<ExpertRendererProps> = ({
  name,
  profileImageUrl
}: ExpertRendererProps) => (
  <div className="d-flex flex-row">
    <Avatar
      avatar={{
        name: name,
        imageUrl: profileImageUrl
      }}
    />
    <span className="ml-3" title={name}>
      {name}
    </span>
  </div>
);
