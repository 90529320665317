import { useCallback, useMemo, useState } from "react";

import {
  ChatListResponseItem,
  ExpertChatResponse
} from "@arbolus-technologies/api";
import {
  AdminChatMode,
  DatabaseChatType,
  UnreadMessagesCountItem
} from "@arbolus-technologies/models/project";

import { isAdminChat } from "../../helpers";

interface UnreadCountItem {
  chatId: string;
  channelName: string;
  chatType: DatabaseChatType;
  isAdminChat: boolean;
  angleId: string | undefined;
  unreadCount: number;
}

export type UnreadCountByAngle = Record<AdminChatMode, Record<string, number>>;

export function useUnreadCount(): {
  initializeUnreadCount: (
    chatListResponse: ChatListResponseItem[],
    unreadCountResponse: UnreadMessagesCountItem[]
  ) => void;
  incrementUnreadCount: (chatId: string) => void;
  markAsRead: (chatId: string) => void;
  channelNameByChatId: Record<string, string>;
  unreadMessagesCount: Record<string, number>;
  unreadMessagesCountByAngle: UnreadCountByAngle;
  unreadMessagesCountByAdminMode: {
    [AdminChatMode.ClientChat]: number;
    [AdminChatMode.ExpertSupport]: number;
  };
} {
  const [data, setData] = useState<Record<string, UnreadCountItem>>({});
  const [channelNameByChatId, setChannelNameByChatId] = useState<
    Record<string, string>
  >({});

  const initializeUnreadCount = useCallback(
    (
      chatListResponse: ChatListResponseItem[],
      unreadCountResponse: UnreadMessagesCountItem[]
    ) => {
      const chatsWithUnreadCount = {} as Record<string, UnreadCountItem>;
      const initialCountByChatId = {} as Record<string, number>;

      for (const responseItem of unreadCountResponse) {
        initialCountByChatId[responseItem.chatId] =
          responseItem.unreadMessagesCount ?? 0;
      }

      for (const chat of chatListResponse) {
        chatsWithUnreadCount[chat.chatId] = getChatData(
          chat,
          initialCountByChatId
        );

        if (chat.expertChats) {
          for (const expertChat of chat.expertChats) {
            chatsWithUnreadCount[expertChat.chatId] = getExpertChatData(
              expertChat,
              chat.angle?.id,
              initialCountByChatId
            );
          }
        }
      }

      setData(chatsWithUnreadCount);
      setChannelNameByChatId(getChannelNameByChatId(chatsWithUnreadCount));
    },
    []
  );

  const incrementUnreadCount = useCallback((chatId: string) => {
    setData((previousData) => ({
      ...previousData,
      [chatId]: {
        ...previousData[chatId],
        unreadCount: previousData[chatId].unreadCount + 1
      }
    }));
  }, []);

  const markAsRead = useCallback((chatId: string) => {
    setData((previousData) => ({
      ...previousData,
      [chatId]: {
        ...previousData[chatId],
        unreadCount: 0
      }
    }));
  }, []);

  const unreadMessagesCount = useMemo(() => {
    const countByChatId = {} as Record<string, number>;
    for (const chat of Object.values(data)) {
      countByChatId[chat.chatId] = chat.unreadCount;
    }
    return countByChatId;
  }, [data]);

  const unreadMessagesCountByAngle = useMemo(() => {
    const countByAngle: UnreadCountByAngle = {
      [AdminChatMode.ClientChat]: {},
      [AdminChatMode.ExpertSupport]: {}
    };

    for (const chat of Object.values(data)) {
      const { angleId, unreadCount } = chat;
      if (typeof angleId === "undefined") continue;

      const chatMode = chat.isAdminChat
        ? AdminChatMode.ExpertSupport
        : AdminChatMode.ClientChat;
      countByAngle[chatMode][angleId] =
        (countByAngle[chatMode][angleId] ?? 0) + unreadCount;
    }

    return countByAngle;
  }, [data]);

  const unreadMessagesCountByAdminMode = useMemo(() => {
    const countByAdminMode = {
      [AdminChatMode.ClientChat]: 0,
      [AdminChatMode.ExpertSupport]: 0
    };
    for (const chat of Object.values(data)) {
      if (chat.isAdminChat) {
        countByAdminMode[AdminChatMode.ExpertSupport] += chat.unreadCount;
      } else {
        countByAdminMode[AdminChatMode.ClientChat] += chat.unreadCount;
      }
    }
    return countByAdminMode;
  }, [data]);

  return {
    initializeUnreadCount,
    incrementUnreadCount,
    markAsRead,
    channelNameByChatId,
    unreadMessagesCount,
    unreadMessagesCountByAngle,
    unreadMessagesCountByAdminMode
  };
}

function getChatData(
  chat: ChatListResponseItem,
  initialCountByChatId: Record<string, number>
): UnreadCountItem {
  const { chatId, channelName, chatType, angle } = chat;
  return {
    chatId,
    channelName,
    chatType,
    isAdminChat: isAdminChat(chatType),
    angleId: angle?.id,
    unreadCount: initialCountByChatId[chatId]
  };
}

function getExpertChatData(
  expertChat: ExpertChatResponse,
  angleId: string | undefined,
  initialCountByChatId: Record<string, number>
): UnreadCountItem {
  const { chatId, channelName, chatType } = expertChat;
  return {
    chatId,
    channelName,
    chatType,
    isAdminChat: isAdminChat(chatType),
    angleId,
    unreadCount: initialCountByChatId[chatId]
  };
}

function getChannelNameByChatId(
  chats: Record<string, UnreadCountItem>
): Record<string, string> {
  const nameByChatId = {} as Record<string, string>;
  for (const chat of Object.values(chats)) {
    nameByChatId[chat.chatId] = chat.channelName;
  }
  return nameByChatId;
}
