import React, { useEffect, useState } from "react";
import { Loader } from "react-bootstrap-typeahead";
import { useParams } from "react-router";

import {
  CIQError,
  CanopyService,
  ErrorResponse
} from "@arbolus-technologies/api";
import { ValueTypeViewer } from "@arbolus-technologies/features/common";
import { CanopyParamUrlTypes } from "@arbolus-technologies/models/canopy";
import { AnswerValue } from "@arbolus-technologies/models/canopy-panels";

interface QuestionValueAnswersProps {
  canopyService?: typeof CanopyService;
}

export const QuestionValueAnswers: React.FC<QuestionValueAnswersProps> = ({
  canopyService = CanopyService
}) => {
  const { canopyQuestionId } = useParams<CanopyParamUrlTypes>();
  const [valueAnswers, setValueAnswers] = useState<AnswerValue | null>();
  const [isValueAnswersLoading, setIsValueAnswersLoading] =
    useState<boolean>(false);

  const getQuestionValueAnswers = () => {
    setIsValueAnswersLoading(true);

    canopyService.getCanopyQuestionValueAnswers(canopyQuestionId).subscribe(
      (response) => {
        setValueAnswers(response);
        setIsValueAnswersLoading(false);
      },
      (error: ErrorResponse<CIQError>) => {
        setIsValueAnswersLoading(false);
      }
    );
  };

  useEffect(() => {
    getQuestionValueAnswers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isValueAnswersLoading && <Loader />}
      {!isValueAnswersLoading && valueAnswers && (
        <ValueTypeViewer data={valueAnswers} />
      )}
    </>
  );
};
