import { Button } from "arbolus-ui-components";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  DefaultToasterService,
  ExpertService,
  ReferralDetail,
  ToasterService
} from "@arbolus-technologies/api";
import { PROJECT_APPROVED_REFERRALS } from "@arbolus-technologies/routes";
import { InfoBox, LinkNoBorder } from "@arbolus-technologies/ui/components";

import styles from "./AvailabilityExpired.module.scss";

interface AvailabilityExpiredProps {
  referral: ReferralDetail;
  expertService?: typeof ExpertService;
  notificationService?: ToasterService;
}

export const AvailabilityExpired: React.FC<AvailabilityExpiredProps> = ({
  referral,
  expertService = ExpertService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("expertAvailabilityTab");
  const [requested, setRequested] = useState(false);

  const handleRequestTimeSlots = useCallback((): void => {
    setRequested(true);
    expertService
      .requestExpertAvailability(referral.projectId, referral.expert.id)
      .subscribe(
        () =>
          notificationService.showSuccess(t("requestTimeSlotSuccessMessage")),
        (errors) => {
          notificationService.showApiErrors(errors);
          setRequested(false);
        }
      );
  }, [referral, expertService, notificationService, t]);

  return (
    <div className={styles.container}>
      <InfoBox
        title={t("expertAvailabilityExpired")}
        description={t("expiredTimeSlots")}
        infoBoxType="warning"
        iconName="error_outline"
      />
      <div className="d-flex">
        <div className={styles.actions}>
          <LinkNoBorder
            to={PROJECT_APPROVED_REFERRALS(referral.projectId)}
            name={t("viewExperts")}
          />
          <Button
            type="primary"
            onClick={handleRequestTimeSlots}
            disabled={
              !referral.referralAvailability
                .isRequiredMoreAvailabilitySlotsAllowed || requested
            }
            text={t("requestAdditionalTimeSlots")}
          />
        </div>
      </div>
    </div>
  );
};
