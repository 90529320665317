import i18next from "i18next";
import * as Yup from "yup";

import { LINKEDIN_URL_REGEX } from "@arbolus-technologies/ui/components";

import {
  ExpertProfileConstraints,
  UserConstraints
} from "../../../../../constants/validation";
import { UtilsService } from "../../../../../services";

export const ExpertDetailsValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(
      UserConstraints.MAX_FIRST_NAME_LENGTH,
      i18next.t("expertProfileEditPage:firstNameMaxLengthError", {
        length: UserConstraints.MAX_FIRST_NAME_LENGTH
      })
    )
    .trim("Cannot contain white spaces")
    .required(i18next.t("expertProfileEditPage:firstNameRequiredError")),
  lastName: Yup.string()
    .max(
      UserConstraints.MAX_LAST_NAME_LENGTH,
      i18next.t("expertProfileEditPage:lastNameMaxLengthError", {
        length: UserConstraints.MAX_LAST_NAME_LENGTH
      })
    )
    .required(i18next.t("expertProfileEditPage:lastNameRequiredError")),
  title: Yup.string()
    .max(
      UserConstraints.MAX_TITLE_LENGTH,
      i18next.t("expertProfileEditPage:jobTitleMaxLengthError", {
        length: UserConstraints.MAX_TITLE_LENGTH
      })
    )
    .required(i18next.t("expertProfileEditPage:jobTitleRequiredError")),
  linkedInUrl: Yup.string()
    .matches(
      LINKEDIN_URL_REGEX,
      i18next.t("expertProfileEditPage:linkedInUrlInvalidError")
    )
    .nullable(),
  seniority: Yup.string().required(
    "expertProfileEditPage:seniorityRequiredError"
  ),
  introduction: Yup.string()
    .test(
      "html-len-empty",
      i18next.t("expertProfileEditPage:introductionRequiredError"),
      (val) => {
        const stripedHtml = UtilsService.getStrippedTextFromHtml(val || "");
        return stripedHtml.length !== 0;
      }
    )
    .test(
      "html-len-max",
      i18next.t("expertProfileEditPage:introductionMaxLengthError", {
        length: ExpertProfileConstraints.MAX_INTRODUCTION_LENGTH
      }),
      (val) => {
        const stripedHtml = UtilsService.getStrippedTextFromHtml(val || "");
        return (
          stripedHtml.length < ExpertProfileConstraints.MAX_INTRODUCTION_LENGTH
        );
      }
    )
    .required(i18next.t("expertProfileEditPage:introductionRequiredError")),
  quickFacts: Yup.array().of(
    Yup.string()
      .nullable()
      .max(
        ExpertProfileConstraints.MAX_QUICK_FACT_LENGTH,
        i18next.t("expertProfileEditPage:quickFactsMaxLengthError", {
          length: ExpertProfileConstraints.MAX_QUICK_FACT_LENGTH
        })
      )
  ),
  funFact: Yup.string()
    .nullable()
    .max(
      ExpertProfileConstraints.MAX_FUN_FACT_LENGTH,
      i18next.t("expertProfileEditPage:funFactMaxLengthError", {
        length: ExpertProfileConstraints.MAX_FUN_FACT_LENGTH
      })
    ),
  phoneNumber: Yup.string()
    .test(
      "phone-valid",
      i18next.t("expertProfileEditPage:phoneNumberInvalidError"),
      function validate(val: string) {
        return val ? UtilsService.validateContactNumber(`+${val}`) : true;
      }
    )
    .nullable()
});
