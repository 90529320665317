import {
  RENAME_COLLECTION,
  RenameCollectionAction
} from "./RenameCollectionActionTypes";

export const renameCollection = (
  id: string,
  name: string
): RenameCollectionAction => ({
  type: RENAME_COLLECTION,
  payload: { id, name }
});
