import { PROJECT_BASE_ROUTE } from "@arbolus-technologies/routes";

export const USERS = "/users";
// CLIENT ROUTES
export const CLIENT_BASE_ROUTE = "/client";

export const EDIT_USER = `${USERS}/:userId`;

export const EDIT_USER_ROUTE = (userId: string): string => `${USERS}/${userId}`;

export const CLIENT_EDIT_USER_ROUTE = (
  clientId: string,
  userId: string
): string => `${CLIENT_BASE_ROUTE}/${clientId}/users/${userId}`;

export const PROJECT_ROUTE = (projectId: string): string =>
  `${PROJECT_BASE_ROUTE}/${projectId}`;
