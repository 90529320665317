import * as moment from "moment";

import { DateString } from "../types";

export interface ISlot {
  startTime: DateString;
  endTime: DateString;
}

export class Slot {
  readonly startTime!: Date;
  readonly endTime!: Date;

  public static fromObject(json: ISlot): Slot {
    const slot = {
      startTime: new Date(json.startTime),
      endTime: new Date(json.endTime)
    };

    return Object.assign(new Slot(), slot);
  }

  public compare(slot: Slot): number {
    const startDiff = this.startTime.getTime() - slot.startTime.getTime();
    if (startDiff !== 0) {
      return startDiff;
    }

    return this.endTime.getTime() - slot.endTime.getTime();
  }
}

export class SlotMoment {
  readonly startTime: moment.Moment;
  readonly endTime: moment.Moment;

  constructor(slot: Slot, timezone?: string) {
    this.startTime = moment.utc(slot.startTime);
    this.endTime = moment.utc(slot.endTime);
    if (timezone) {
      this.tz(timezone);
    }
  }

  public get identifier(): string {
    return `${this.startTime.unix()}-${this.endTime.unix()}`;
  }

  public tz(timezone: string): void {
    this.startTime.tz(timezone);
    this.endTime.tz(timezone);
  }

  public toSlot(): Slot {
    return Object.assign(new Slot(), {
      startTime: this.startTime.toDate(),
      endTime: this.endTime.toDate()
    });
  }

  public compare(slot: SlotMoment): number {
    const startDiff = this.startTime.unix() - slot.startTime.unix();
    if (startDiff !== 0) {
      return startDiff;
    }

    return this.endTime.unix() - slot.endTime.unix();
  }
}
