import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  PROJECT_BOOKED_ROUTE,
  PROJECT_CANDIDATES_ROUTE,
  PROJECT_REJECTED_ROUTE,
  PROJECT_SCHEDULING_ROUTE,
  PROJECT_SHORTLIST_ROUTE
} from "@arbolus-technologies/routes";
import {
  ProjectNxSelector,
  ProjectNxStoreActions
} from "@arbolus-technologies/stores/project";
import { TabLinks } from "@arbolus-technologies/ui/components";

import { ReferralsPages } from "../../Pages/Referrals/referralsPages";

interface ReferralsTabNavigatorProps {
  projectId: string;
  current: ReferralsPages;
}

export const ReferralsTabNavigator: React.FC<ReferralsTabNavigatorProps> = ({
  projectId,
  current
}) => {
  const dispatch = useDispatch();
  const referralTabCount = useSelector(ProjectNxSelector.referralTabCount());

  useEffect(() => {
    dispatch(ProjectNxStoreActions.loadReferralTabCount(projectId));
  }, [dispatch, projectId]);

  const options = [
    {
      isActive: current === "shortlist",
      text: "Shortlist",
      route: PROJECT_SHORTLIST_ROUTE(projectId),
      count: referralTabCount.shortList
    },
    {
      isActive: current === "candidate",
      text: "Candidate",
      route: PROJECT_CANDIDATES_ROUTE(projectId),
      count: referralTabCount.candidate
    },
    {
      isActive: current === "scheduling",
      text: "Scheduling",
      route: PROJECT_SCHEDULING_ROUTE(projectId),
      count: referralTabCount.scheduling
    },
    {
      isActive: current === "booked",
      text: "Booked",
      route: PROJECT_BOOKED_ROUTE(projectId),
      count: referralTabCount.booked
    },
    {
      isActive: current === "rejected",
      text: "Rejected",
      route: PROJECT_REJECTED_ROUTE(projectId),
      count: referralTabCount.rejected
    }
  ];

  return <TabLinks options={options} size="small" />;
};
