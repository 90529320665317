import clsx from "clsx";
import React from "react";

import { User } from "../../../../../models/user";
import { UtilsService } from "../../../../../services";
import { CIQAvatar } from "../../../../app/components";

interface ProjectMetaTagProps {
  icon?: string;
  user?: User;
  message?: string;
  onRemoveClicked?: () => void;
}

const ProjectMetaTag: React.FC<ProjectMetaTagProps> = ({
  user,
  icon,
  message
}: ProjectMetaTagProps) => (
  <button type="button" className="project-meta-tag">
    {user && (
      <CIQAvatar
        username={UtilsService.displayUserName(user)}
        profileImageUrl={user?.profileImageUrl}
      />
    )}
    {icon && <span className={`ciq-icon ${icon}`} />}
    {user && <span>{UtilsService.displayUserName(user)}</span>}
    {message && (
      <span className={clsx({ info: !icon, "info-with-icon": icon })}>
        {message}
      </span>
    )}
  </button>
);

export default ProjectMetaTag;
