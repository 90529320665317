export enum REVIEW_ACTION {
  APPROVE = "approve",
  CONFIRM_APPROVE = "confirmApprove",
  CONFIRM_REJECT = "confirmReject"
}

export enum CANOPY_ORDER_BY {
  LAST_ANSWER_DATE = "LastAnswerDate",
  EXPERT_COUNT = "ExpertCount",
  CREATED = "Created",
  TITLE = "Title"
}

export enum CANOPY_ANSWERS_ORDER_BY {
  Created = "Created"
}

export enum CANOPY_EXPERTS_ORDER_BY {
  JoinDate = "JoinDate"
}
