import i18next from "i18next";

export const convertValueToCurrencyFormat = (
  value: number,
  currencyFormat: string,
  minimumFractionDigits = 0
): string =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyFormat,
    maximumFractionDigits: 2,
    minimumFractionDigits
  }).format(value);

export const formatCredits = (value: number): string =>
  i18next.t("common:credit", { count: value });
