import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { SlidePanel } from "@arbolus-technologies/features/common";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";

import { ExportTransactions } from "./ExportTransactions";

interface ExportTransactionsButtonProps {
  projectId: string;
}

export const ExportTransactionsButton: React.FC<
  ExportTransactionsButtonProps
> = ({ projectId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("projectSpend");

  const handleClick = React.useCallback(() => {
    dispatch(PanelStoreActions.openPanel(PanelId.ProjectSpend));
  }, [dispatch]);

  return (
    <>
      <Button text={t("export")} onClick={handleClick} />
      <SlidePanel panelId={PanelId.ProjectSpend}>
        <ExportTransactions projectId={projectId} />
      </SlidePanel>
    </>
  );
};
