import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Media from "react-media";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router";
import { Nav, TabContent } from "reactstrap";
import { zip } from "rxjs";
import SimpleBar from "simplebar-react";
import { useDocumentTitle } from "usehooks-ts";

import {
  DefaultToasterService,
  ToasterService
} from "@arbolus-technologies/api";
import {
  CustomAvatarSelect,
  MixpanelPages,
  PageTracker
} from "@arbolus-technologies/features/common";
import { EditExpertExperience } from "@arbolus-technologies/features/expert-profile";
import { Loader } from "@arbolus-technologies/ui/components";
import { AntDHeader } from "@arbolus-technologies/ui/layout";

import { USERS_API } from "../../../../constants/api";
import {
  EXPERT_DETAILS_EDIT_TAB,
  EXPERT_ENDORSEMENTS_EDIT_TAB,
  EXPERT_EXPERIENCE_EDIT_TAB,
  EXPERT_INSIGHTS_EDIT_TAB,
  EXPERT_NOTIFICATION_EDIT_TAB,
  EXPERT_PASSWORD_EDIT_TAB,
  EXPERT_PROFILE_EDIT
} from "../../../../constants/navigation/authRoutes";
import {
  APP_DEVICE_MEDIA_QUERIES,
  UI_EXPERT_PROFILE_PAGE
} from "../../../../constants/ui";
import { CIQError, ErrorResponse } from "../../../../models/api";
import { Expert } from "../../../../models/expert";
import { ExpertDetails } from "../../../../models/view/expert";
import {
  DocumentService,
  ExpertService,
  UserService,
  UtilsService
} from "../../../../services";
import { AppStoreActions } from "../../../app/store";
import { AuthSelector } from "../../../auth/store";
import { EndorsementsForExpert } from "../../components/expertProfile/endorsementsForExpert/EndorsementsForExpert";
import ExpertInsightsPane from "../../components/expertProfile/expertInsights/ExpertInsightsPane";
import ExpertProfileDetailsPane from "../../components/expertProfile/expertProfileDetails/ExpertProfileDetailsPane";
import RoutedTabHeader from "../../components/spendTracker/RoutedTabHeader";
import EditUserPassword from "../../components/userProfile/editPassword/EditUserPassword";
import UserNotificationSettings, {
  ExpertNotificationDetails
} from "../../components/userProfile/notificationSettings/UserNotificationSettings";

interface ExpertProfileEditPageProps {
  expertService?: typeof ExpertService;
  userService?: typeof UserService;
  documentService?: typeof DocumentService;
  notificationService?: ToasterService;
}

export const ExpertProfileEditPage: React.FC<ExpertProfileEditPageProps> = ({
  expertService = ExpertService,
  userService = UserService,
  documentService = DocumentService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("expertProfileEditPage");
  const dispatch = useDispatch();
  useDocumentTitle("profile");

  const [expertProfile, setExpertProfile] = useState<Expert | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isProfileImageLoading, setIsProfileImageLoading] =
    useState<boolean>(false);

  const expertUserId = useSelector(AuthSelector.authExpertIdSelector());
  const userId = useSelector(AuthSelector.authUserIdSelector());

  const fetchExpertProfile = () => {
    setIsLoading(true);
    if (expertUserId) {
      expertService
        .getExpertById({
          expertId: expertUserId,
          includeRateCard: true
        })
        .subscribe(
          (expert) => {
            setExpertProfile(expert);
            setIsLoading(false);
          },
          (error: ErrorResponse<CIQError>) => {
            notificationService.showApiErrors(error);
          }
        );
    }
  };

  useEffect(() => {
    fetchExpertProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadSelectedImage = (userImage: File) => {
    setIsProfileImageLoading(true);
    if (userId) {
      const formData = new FormData();
      formData.append("file", userImage);

      documentService
        .uploadFile(USERS_API.ADD_USER_PROFILE_IMAGE(userId), formData)
        .subscribe(
          () => {
            notificationService.showSuccess(
              t("updateExpertProfileImageSuccess")
            );
            setIsProfileImageLoading(false);
            dispatch(AppStoreActions.getUserProfile());
          },
          (error: ErrorResponse<CIQError>) => {
            notificationService.showApiErrors(error);
            setIsProfileImageLoading(false);
          }
        );
    }
  };

  const updateExpertExperience = (background: string) => {
    const {
      overview = "",
      funFact = "",
      quickFacts,
      experienceLevel = "",
      linkedinProfile
    } = expertProfile!;

    setIsLoading(true);
    expertService
      .updateExpertAccount(expertUserId!, {
        background,
        experienceLevel,
        linkedinProfile: linkedinProfile!,
        funFact,
        overview,
        quickFacts
      })
      .subscribe(
        () => {
          notificationService.showSuccess(t("updateSuccessMessage"));
          const updatedExpert: Expert = {
            ...expertProfile!,
            background
          };
          setExpertProfile(updatedExpert);
          setIsLoading(false);
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
          setIsLoading(false);
        }
      );
  };

  const updateExpertDetails = (values: ExpertDetails) => {
    const {
      firstName,
      lastName,
      title,
      experienceLevel,
      linkedInUrl,
      funFact,
      introduction,
      quickFacts,
      phoneNumber
    } = values;

    const validPhoneNumber = phoneNumber ? `+${phoneNumber}` : "";

    if (expertUserId) {
      setIsLoading(true);
      zip(
        userService.updateUserProfile(userId!, {
          firstName,
          lastName,
          title,
          phoneNumber: validPhoneNumber
        }),
        expertService.updateExpertAccount(expertUserId, {
          background: expertProfile!.background,
          experienceLevel,
          linkedinProfile: linkedInUrl,
          funFact,
          overview: introduction,
          quickFacts
        })
      ).subscribe(
        () => {
          notificationService.showSuccess(t("updateSuccessMessage"));
          const updatedExpert: Expert = {
            ...expertProfile!,
            linkedinProfile: linkedInUrl,
            experienceLevel,
            user: {
              ...expertProfile!.user,
              firstName,
              lastName,
              title,
              phoneNumber: validPhoneNumber
            },
            overview: introduction,
            funFact,
            quickFacts
          };
          setExpertProfile(updatedExpert);
          setIsLoading(false);
          dispatch(
            AppStoreActions.updateUserProfile({
              firstName,
              lastName,
              title,
              phoneNumber: validPhoneNumber
            })
          );
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
          setIsLoading(false);
        }
      );
    }
  };

  const renderUserProfileImage = () => (
    <CustomAvatarSelect
      profileImageUrl={expertProfile!.user.profileImageUrl}
      onImageSelected={uploadSelectedImage}
      isLoading={isProfileImageLoading}
    />
  );

  const renderNameAndProfession = () => (
    <div className="name-container">
      <h3>{UtilsService.displayUserName(expertProfile!.user)}</h3>
      <p>{expertProfile!.user.title}</p>
    </div>
  );

  const renderExpertDetailsAndWorkTabs = () => {
    const {
      user,
      linkedinProfile,
      experienceLevel = "",
      overview,
      background,
      funFact,
      quickFacts,
      rateCards,
      endorsements,
      isActive
    } = expertProfile!;

    const { firstName, lastName, title, email, phoneNumber } = user;

    const expertDetails: ExpertDetails = {
      firstName: firstName || "",
      lastName: lastName || "",
      title,
      email,
      phoneNumber: phoneNumber?.split("+")[1],
      linkedInUrl: linkedinProfile || "",
      experienceLevel,
      introduction: overview || "",
      funFact: funFact || "",
      quickFacts,
      hourlyRate: (rateCards && rateCards[0]?.price) || undefined,
      isoCurrencyCode: (rateCards && rateCards[0]?.isoCurrencyCode) || undefined
    };

    const expertNotificationDetails: ExpertNotificationDetails = {
      expertId: expertProfile!.id,
      expertName: expertDetails.firstName + " " + expertDetails.lastName,
      isActive: isActive
    };

    return (
      <>
        <Nav tabs>
          <RoutedTabHeader
            activateRoute={[EXPERT_PROFILE_EDIT, EXPERT_DETAILS_EDIT_TAB]}
            navigateTo={EXPERT_DETAILS_EDIT_TAB}
            title={t("details")}
            isNavItem
          />
          <RoutedTabHeader
            activateRoute={EXPERT_EXPERIENCE_EDIT_TAB}
            navigateTo={EXPERT_EXPERIENCE_EDIT_TAB}
            title={t("experience")}
            isNavItem
          />
          <RoutedTabHeader
            activateRoute={EXPERT_ENDORSEMENTS_EDIT_TAB}
            navigateTo={EXPERT_ENDORSEMENTS_EDIT_TAB}
            title={t("endorsements")}
            isNavItem
          />
          <RoutedTabHeader
            activateRoute={EXPERT_INSIGHTS_EDIT_TAB}
            navigateTo={EXPERT_INSIGHTS_EDIT_TAB}
            title={t("insights")}
            isNavItem
          />
          <RoutedTabHeader
            activateRoute={EXPERT_PASSWORD_EDIT_TAB}
            navigateTo={EXPERT_PASSWORD_EDIT_TAB}
            title={t("password")}
            isNavItem
          />
          <RoutedTabHeader
            activateRoute={EXPERT_NOTIFICATION_EDIT_TAB}
            navigateTo={EXPERT_NOTIFICATION_EDIT_TAB}
            title={t("notifications")}
            isNavItem
          />
        </Nav>
        <TabContent>
          <Switch>
            <Route
              path={[EXPERT_PROFILE_EDIT, EXPERT_DETAILS_EDIT_TAB]}
              exact
              render={() => (
                <ExpertProfileDetailsPane
                  expertDetails={expertDetails}
                  onUpdateExpertDetails={updateExpertDetails}
                  isSaveInProgress={isLoading}
                />
              )}
            />
            <Route
              path={EXPERT_EXPERIENCE_EDIT_TAB}
              exact
              render={() => (
                <EditExpertExperience
                  expertId={expertProfile!.id}
                  background={background}
                  updateExpertExperience={updateExpertExperience}
                />
              )}
            />
            <Route
              path={EXPERT_ENDORSEMENTS_EDIT_TAB}
              exact
              render={() => (
                <EndorsementsForExpert
                  endorsements={endorsements}
                  expertId={expertProfile!.id}
                />
              )}
            />
            <Route
              path={EXPERT_INSIGHTS_EDIT_TAB}
              exact
              render={() => <ExpertInsightsPane expertId={expertProfile!.id} />}
            />
            <Route
              path={EXPERT_PASSWORD_EDIT_TAB}
              exact
              render={() => <EditUserPassword />}
            />
            <Route
              path={EXPERT_NOTIFICATION_EDIT_TAB}
              exact
              render={() => (
                <UserNotificationSettings
                  expertDetails={expertNotificationDetails}
                />
              )}
            />
          </Switch>
        </TabContent>
      </>
    );
  };

  return (
    <PageTracker page={MixpanelPages.Profile}>
      <Media queries={APP_DEVICE_MEDIA_QUERIES}>
        {(matches) => (
          <div className="user-edit-container page-content">
            <div className="tab-page-header page-content-header header-active">
              <AntDHeader title={t("myDetails")} />
              <div className="bottom-container" />
            </div>
            <SimpleBar
              className="simplebar-light"
              style={{
                maxHeight:
                  UI_EXPERT_PROFILE_PAGE.EXPERT_PROFILE_HEIGHT(matches),
                height: UI_EXPERT_PROFILE_PAGE.EXPERT_PROFILE_HEIGHT(matches),
                overflowX: "hidden"
              }}
            >
              {expertProfile && (
                <div className="expert-page-body">
                  <div className="top-container">
                    {renderUserProfileImage()}
                    {renderNameAndProfession()}
                  </div>
                  {renderExpertDetailsAndWorkTabs()}
                </div>
              )}
            </SimpleBar>
            {isLoading && <Loader isFull />}
          </div>
        )}
      </Media>
    </PageTracker>
  );
};
