import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as NoAvailabilitiesIcon } from "../../../../assets/images/no-availabilities.svg";

import styles from "./NoAvailabilityCard.module.scss";

export const NoAvailabilityCard: React.FC = () => {
  const { t } = useTranslation("scheduleTab");

  return (
    <div className={styles.noAvailabilityWrapper}>
      <div className={styles.iconWithTextContainer}>
        <NoAvailabilitiesIcon />
        <h4>{t("noSlots")}</h4>
      </div>
      <div className={styles.message}>{t("noSlotsMessage")}</div>
    </div>
  );
};
