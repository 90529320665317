import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";

import {
  CONTACT_TYPE_ENUM,
  ExternalExpertFormEnum,
  ExternalExpertFormValues
} from "@arbolus-technologies/models/expert";
import {
  CustomSelect,
  InputController
} from "@arbolus-technologies/ui/components";

import styles from "./CommsInfo.module.scss";

interface CommsInfoProps {
  statusList: { value: string; label: string }[];
  isFormDisabled: boolean;
}

export const CommsInfo: React.FC<CommsInfoProps> = ({
  statusList,
  isFormDisabled
}) => {
  const { t } = useTranslation("externalExpertForm");

  const {
    control,
    formState: { errors, dirtyFields },
    getValues,
    setValue
  } = useFormContext<ExternalExpertFormValues>();

  useEffect(() => {
    if (!dirtyFields.projectPipelineStatus) {
      // @ts-ignore
      setValue(ExternalExpertFormEnum.PROJECT_PIPELINE_STATUS_COMMENT, "", {
        shouldDirty: false,
        shouldValidate: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirtyFields.projectPipelineStatus]);

  return (
    <div className={styles.commsContainer}>
      <h3 className={styles.title}>{t("commsStatus")}</h3>
      <div className={styles.commsDropdown}>
        <CustomSelect
          options={statusList}
          noOptionsMessage={""}
          displayIcon
          defaultValue={statusList.find(
            (status) =>
              status.value ===
              getValues(ExternalExpertFormEnum.PROJECT_PIPELINE_STATUS)
          )}
          onSelectChange={(value: string) =>
            setValue(
              ExternalExpertFormEnum.PROJECT_PIPELINE_STATUS,
              // @ts-ignore
              value as CONTACT_TYPE_ENUM,
              { shouldDirty: true }
            )
          }
          disabled={isFormDisabled}
        />
      </div>
      <div className={styles.inputContainer}>
        <Label className={styles.label}>{t("commsNote")}</Label>
        <InputController
          name={ExternalExpertFormEnum.PROJECT_PIPELINE_STATUS_COMMENT}
          error={errors.projectPipelineStatusComment}
          control={control}
          placeholder={t("commsNotePlaceholder")}
          classnames={styles.input}
          type="textarea"
          size="big"
          disabled={isFormDisabled || !dirtyFields.projectPipelineStatus}
        />
      </div>
    </div>
  );
};
