import {
  FilterCriteria,
  PROJECT_REFERRAL_STATE
} from "@arbolus-technologies/api";

export const INTERNAL_COMMENT_MAX_TITLE_LENGTH = 100;
export const INTERNAL_COMMENT_MAX_NOTE_LENGTH = 500;

export const EXPERT_PROJECT_FILTER_CRITERIA = {
  ALL: {
    name: "All statuses",
    value: ""
  } as FilterCriteria,
  ACCEPT: {
    name: "Active",
    value: PROJECT_REFERRAL_STATE.ACCEPT
  } as FilterCriteria,
  PENDING: {
    name: "Pending",
    value: PROJECT_REFERRAL_STATE.CANDIDATE
  } as FilterCriteria,
  REJECT: {
    name: "Rejected",
    value: PROJECT_REFERRAL_STATE.REJECT
  } as FilterCriteria
};
