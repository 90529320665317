import React from "react";
import { useTranslation } from "react-i18next";

import { PrivateCompanyIconColor } from "../../../models/Company";
import { BadgeWithIcon } from "../BadgeWithIcon";

export const PrivateCompanyBadge: React.FC = () => {
  const { t } = useTranslation("privateCompanyIcon");

  return (
    <BadgeWithIcon
      text={t("text")}
      icon="bid_landscape_disabled"
      color={PrivateCompanyIconColor}
    />
  );
};
