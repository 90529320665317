import { Button } from "arbolus-ui-components";
import { omit } from "lodash";
import React from "react";

import {
  ArbolusFeatureFlag,
  ArbolusFeatureFlags,
  FEATURE_FLAGS
} from "@arbolus-technologies/models/common";
import { CustomSwitch } from "@arbolus-technologies/ui/components";
import { MainPageLayout } from "@arbolus-technologies/ui/layout";

import { useCachedFlags } from "../../Hooks/useCachedFlags";
import { noop } from "../../utils";

import styles from "./FeatureOverridePage.module.scss";

export function FeatureOverridePage({
  featureFlags,
  featureFlagsDefaults,
  onChangeFlag,
  onResetFlagsToDefault
}: {
  featureFlags: ArbolusFeatureFlags;
  featureFlagsDefaults: ArbolusFeatureFlags;
  onChangeFlag: (name: ArbolusFeatureFlag, value: boolean) => void;
  onResetFlagsToDefault: () => void;
}): React.ReactElement {
  const { cachedOverrides, setCachedOverrides } = useCachedFlags();
  const currentFlags =
    cachedOverrides && Object.keys(cachedOverrides).length > 0
      ? cachedOverrides
      : featureFlags;
  const filteredFlags = omit(currentFlags, [FEATURE_FLAGS.FeatureFlagOverride]);

  const handleReset = () => {
    onResetFlagsToDefault();
    setCachedOverrides(featureFlagsDefaults);
  };

  return (
    <MainPageLayout title="Feature flags override">
      <Button
        text={"Reset to defaults"}
        onClick={() => {
          handleReset();
        }}
      />

      <table className={styles.flagsTable}>
        <tr>
          <th>Feature</th>
          <th>Default</th>
          <th>Current</th>
        </tr>
        {Object.entries(filteredFlags).map(([name, value]) => (
          <tr key={name}>
            <td className={styles.featureName}>{name}</td>
            <td>
              <CustomSwitch
                name={`${name}-default`}
                checked={featureFlagsDefaults[name as ArbolusFeatureFlag]}
                disabled
                onChange={noop}
              />
            </td>
            <td>
              <CustomSwitch
                name={name}
                checked={value as boolean}
                onChange={function () {
                  onChangeFlag(name as ArbolusFeatureFlag, !value);
                  setCachedOverrides({ ...currentFlags, [name]: !value });
                }}
              />
            </td>
          </tr>
        ))}
      </table>
    </MainPageLayout>
  );
}
