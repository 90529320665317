import clsx from "clsx";
import React from "react";

import { useCookie } from "@arbolus-technologies/utils";

import { CrossButton } from "../CrossButton/CrossButton";

import styles from "./InfoCookieBoxWithChildren.module.scss";

interface InfoCookieBoxWithChildrenProps {
  children: JSX.Element;
  cookie: string;
  withPadding?: boolean;
}

export const InfoCookieBoxWithChildren: React.FC<
  InfoCookieBoxWithChildrenProps
> = ({ children, cookie, withPadding }) => {
  const { setCookie, cookieStored } = useCookie(cookie);

  return cookieStored ? null : (
    <div className={clsx(styles.container, withPadding && styles.withPadding)}>
      <div className={styles.crossContainer}>
        <CrossButton onClick={setCookie} />
      </div>
      {children}
    </div>
  );
};
