import { ICellRendererParams } from "ag-grid-community";
import React from "react";
import { Link } from "react-router-dom";

import { ProjectModel } from "@arbolus-technologies/models/common";
import { CLIENT_PAGE_ROUTE } from "@arbolus-technologies/routes";
import { Avatar } from "@arbolus-technologies/ui/components";

import styles from "./ClientRenderer.module.scss";

type ClientRendererProps = ICellRendererParams<ProjectModel, never, never>;

export const ClientRenderer: React.FC<ClientRendererProps> = ({ data }) => (
  <Link to={CLIENT_PAGE_ROUTE(data!.client.id)} className={styles.clientCell}>
    <Avatar
      avatar={{ name: data!.client.name, imageUrl: data!.client?.logoUrl }}
    />
    <span className={styles.clientName}>{data!.client.name}</span>
  </Link>
);
