import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { ChatUserRole } from "@arbolus-technologies/models/project";
import {
  PROJECT_CHAT_BASE_ROUTE,
  PROJECT_ROUTE
} from "@arbolus-technologies/routes";
import { Loader } from "@arbolus-technologies/ui/components";
import { MainPageHeader } from "@arbolus-technologies/ui/layout";

import { AdminChatSwitch } from "../../../Components/Chat/AdminChatSwitch/AdminChatSwitch";
import { ChatContent } from "../../../Components/Chat/ChatContent/ChatContent";
import { ChatList } from "../../../Components/Chat/ChatList/ChatList";
import { EmptyChatGuard } from "../../../Components/Chat/EmptyChatGuard/EmptyChatGuard";
import { ExpertChatList } from "../../../Components/Chat/ExpertChatList/ExpertChatList";
import { useChat } from "../../../Contexts/ChatContext/ChatContext";
import { MobileBackButton } from "./MobileBackButton/MobileBackButton";

import styles from "./MobileChatPage.module.scss";

export const MobileChatPage: React.FC<{
  projectId: string;
  handleOpenExpertSidePanel: (expertId: string, referralId: string) => void;
}> = ({ projectId, handleOpenExpertSidePanel }) => {
  const history = useHistory();
  const {
    chatSenderRole,
    isLoading,
    currentChat,
    projectData,
    changeChat,
    isChatEnabled
  } = useChat();
  const { t } = useTranslation("chat");

  const hasSelectedChat = typeof currentChat !== "undefined";
  // When chat is disabled only the admin mode is available - we can hide the switch
  const showAdminSwitch =
    chatSenderRole === ChatUserRole.Admin && isChatEnabled;

  if (isLoading) return <Loader isFull />;

  if (hasSelectedChat && projectData) {
    return (
      <div className={styles.mobileChatContent}>
        <MobileBackButton
          text={t("chat")}
          onClick={() => {
            history.push(PROJECT_CHAT_BASE_ROUTE(projectData.id));
            changeChat();
          }}
        />
        <ChatContent handleOpenExpertSidePanel={handleOpenExpertSidePanel} />
      </div>
    );
  }

  return (
    <div className={styles.mobileChatPage}>
      {projectData && (
        <MobileBackButton
          text={projectData.name}
          onClick={() => history.push(PROJECT_ROUTE(projectData.id))}
        />
      )}
      <MainPageHeader title="Chat" iconName="forum" />
      {showAdminSwitch && (
        <div className={styles.chatSwitch}>
          <AdminChatSwitch projectId={projectId} />
        </div>
      )}
      <EmptyChatGuard>
        {chatSenderRole === ChatUserRole.Expert ? (
          <ExpertChatList projectId={projectId} />
        ) : (
          <ChatList projectId={projectId} />
        )}
      </EmptyChatGuard>
    </div>
  );
};
