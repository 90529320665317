import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";

import { SelectOption } from "@arbolus-technologies/models/common";
import {
  EXPERT_EXPERIENCE,
  ExternalExpertFormEnum,
  ExternalExpertFormValues
} from "@arbolus-technologies/models/expert";
import {
  CustomSelect,
  CustomSelectController,
  InputController
} from "@arbolus-technologies/ui/components";

import styles from "./RateCardInfo.module.scss";

interface RateCardInfoProps {
  currencies: SelectOption[];
  isFormDisabled: boolean;
}

export const RateCardInfo: React.FC<RateCardInfoProps> = ({
  currencies,
  isFormDisabled
}) => {
  const { t } = useTranslation("externalExpertForm");

  const {
    control,
    formState: { errors },
    setValue,
    getValues
  } = useFormContext<ExternalExpertFormValues>();

  return (
    <div className={styles.rateContainer}>
      <h3 className={styles.title}>{t("rateCard")}</h3>
      <div className={styles.hourlyRateAndSeniorityContainer}>
        <div className={styles.inputContainer}>
          <Label className={styles.label}>{t("seniority")}</Label>
          <CustomSelect
            options={Array.from(EXPERT_EXPERIENCE.values())}
            noOptionsMessage={""}
            displayIcon
            defaultValue={EXPERT_EXPERIENCE.get(
              getValues(ExternalExpertFormEnum.RATE_CARD_EXPERIENCE_LEVEL)
            )}
            onSelectChange={(value: string) =>
              setValue(
                ExternalExpertFormEnum.RATE_CARD_EXPERIENCE_LEVEL,
                value,
                {
                  shouldDirty: true,
                  shouldValidate: true
                }
              )
            }
            disabled={isFormDisabled}
          />
        </div>
        <div className={styles.inputContainer}>
          <Label className={styles.label}>{t("hRate")}</Label>
          <div className={styles.hourlyRateContainer}>
            <CustomSelectController
              name={ExternalExpertFormEnum.RATE_CARD_ISO_CURRENCY_CODE}
              control={control}
              options={currencies}
              noOptionsMessage={""}
              displayIcon
              disabled={isFormDisabled}
            />
            <InputController
              name={ExternalExpertFormEnum.RATE_CARD_PRICE}
              error={errors.rateCard?.price}
              control={control}
              placeholder={t("hRate")}
              classnames={styles.input}
              type="number"
              disabled={isFormDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
