import { Icon } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { SlidePanel } from "../../../Panels/SlidePanel/SlidePanel";
import { IncidentsSlidePanel } from "../IncidentsSlidePanel/IncidentsSlidePanel";

import styles from "./ReportIncidentButton.module.scss";

interface ReportIncidentButtonProps {
  isActive?: boolean;
}
export const ReportIncidentButton: React.FC<ReportIncidentButtonProps> = ({
  isActive
}) => {
  const dispatch = useDispatch();
  const handleClickReportButton = () => {
    dispatch(PanelStoreActions.openPanel(PanelId.ReportIncident));
  };
  const { t } = useTranslation("incidentsInfo");

  return (
    <>
      <SlidePanel
        title={t("panelTitle")}
        panelId={PanelId.ReportIncident}
        closeButtonDirection="right"
        width={SIDE_PANEL_SIZE._720}
      >
        <IncidentsSlidePanel />
      </SlidePanel>

      <div className={styles.container} onClick={handleClickReportButton}>
        <Icon
          name="help"
          fontSize="24px"
          color={
            isActive
              ? ARBOLUS_COLORS.bColorBasePurple
              : ARBOLUS_COLORS.bColorSecondaryGreyDark
          }
        />
        <h1 className={styles.text}>{t("support")}</h1>
      </div>
    </>
  );
};
