import React from "react";
import { useTranslation } from "react-i18next";

import { BookmarkCollection } from "@arbolus-technologies/models/common";
import {
  IThreeDotsItem,
  LinkNoBorder,
  ThreeDots
} from "@arbolus-technologies/ui/components";

import styles from "./CollectionCard.module.scss";

interface CollectionCardProps {
  collection: BookmarkCollection;
  to: string;
  actionItems?: IThreeDotsItem[];
}

export const CollectionCard: React.FC<CollectionCardProps> = ({
  collection,
  to,
  actionItems = []
}) => {
  const { t } = useTranslation("bookmarkCollections");

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <span>{t("items", { count: collection.count })}</span>
        <ThreeDots
          popoverId={collection.id}
          items={actionItems}
          classnames={styles.threeDots}
        />
      </div>
      <div className={styles.content} title={collection.name}>
        {collection.name}
      </div>
      <div className={styles.footer}>
        <LinkNoBorder
          name={t("viewCollection")}
          to={to}
          iconName="chevron_right"
          classnames={styles.link}
        />
      </div>
    </section>
  );
};
