/* eslint-disable @typescript-eslint/no-empty-function */
import clsx from "clsx";
import { getMonth, getYear } from "date-fns";
import enGb from "date-fns/locale/en-GB";
import React, { SyntheticEvent } from "react";
import DatePickerReact, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useMedia } from "react-media";
// eslint-disable-next-line import/extensions

import "react-datepicker/dist/react-datepicker.css";
import { APP_DEVICE_MEDIA_QUERIES } from "@arbolus-technologies/models/common";
import { APP_EVENT_AND_HISTORY_FORMAT } from "@arbolus-technologies/utils";

import styles from "./DatePicker.module.scss";

registerLocale("en-gb", enGb);

interface DatePickerProps {
  name?: string;
  selectedDate?: Date;
  popperPlacement?: string;
  minDate?: Date;
  dateFormat?: string;
  placeholderText?: string;
  onBlur?: (e: SyntheticEvent<HTMLInputElement>) => void;
  onDateChanged: (date: Date) => void;
  showMonthPicker?: boolean;
  maxDate?: Date;
  disabled?: boolean;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  timeIntervals?: number;
  timeCaption?: string;
  autoComplete?: string;
  isClearable?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  name,
  onDateChanged,
  minDate,
  selectedDate,
  onBlur,
  popperPlacement,
  dateFormat = APP_EVENT_AND_HISTORY_FORMAT,
  placeholderText,
  showMonthPicker = false,
  maxDate,
  disabled = false,
  showTimeSelect,
  showTimeSelectOnly,
  timeIntervals,
  timeCaption,
  autoComplete = "Off",
  isClearable = false,
  onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {}
}) => {
  const { t } = useTranslation("customDatePicker");
  const matches = useMedia({ queries: APP_DEVICE_MEDIA_QUERIES });

  const handleDateChange = (date: Date): void => {
    onDateChanged(date);
  };

  const handleDateChangeRaw = (
    e: React.FocusEvent<HTMLInputElement>,
    isMobile: boolean
  ): void => {
    if (showMonthPicker || isMobile) {
      e.preventDefault();
      e.target.readOnly = true;
    } else {
      e.target.readOnly = false;
    }
  };

  const months = [
    t("january"),
    t("february"),
    t("march"),
    t("april"),
    t("may"),
    t("june"),
    t("july"),
    t("august"),
    t("september"),
    t("october"),
    t("november"),
    t("december")
  ];

  const locale = showTimeSelectOnly ? {} : { locale: "en-gb" };

  return (
    <DatePickerReact
      isClearable={isClearable}
      autoComplete={autoComplete}
      disabled={disabled}
      name={name}
      wrapperClassName="datePickerWrapper"
      onFocus={(e: React.FocusEvent<HTMLInputElement>): void =>
        handleDateChangeRaw(e, !matches.large)
      }
      popperPlacement={popperPlacement || "top-start"}
      dateFormat={dateFormat}
      placeholderText={placeholderText}
      onBlur={onBlur}
      className={styles.datePickerContainer}
      renderCustomHeader={({
        date,
        changeYear,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
        prevYearButtonDisabled,
        nextYearButtonDisabled
      }): JSX.Element => {
        const renderMonthText = (): string => months[getMonth(date)];
        const handleNextClick = (): void => {
          if (showMonthPicker) {
            return changeYear(getYear(date) + 1);
          }
          return increaseMonth();
        };
        const handlePreviousClick = (): void => {
          if (showMonthPicker) {
            return changeYear(getYear(date) - 1);
          }
          return decreaseMonth();
        };

        return (
          <div className={styles.datepickerHeader}>
            <div className={styles.leftContainer}>
              <div className={styles.monthYearText}>
                {!showMonthPicker && renderMonthText()} {getYear(date)}
              </div>
            </div>
            <div className={styles.rightContainer}>
              <div
                className={clsx(styles.leftArrow, {
                  disabled: showMonthPicker
                    ? prevYearButtonDisabled
                    : prevMonthButtonDisabled
                })}
                onClick={(): void =>
                  prevMonthButtonDisabled ? undefined : handlePreviousClick()
                }
              >
                <i className={clsx(styles.ciqIcon, "ciq-left")} />
              </div>

              <div
                className={clsx(styles.rightArrow, {
                  disabled: showMonthPicker
                    ? nextYearButtonDisabled
                    : nextMonthButtonDisabled
                })}
                onClick={(): void =>
                  nextMonthButtonDisabled ? undefined : handleNextClick()
                }
              >
                <i className={clsx(styles.ciqIcon, "ciq-right")} />
              </div>
            </div>
          </div>
        );
      }}
      selected={selectedDate}
      onChange={handleDateChange}
      maxDate={maxDate}
      minDate={minDate || null}
      showMonthYearPicker={showMonthPicker}
      endDate={maxDate}
      showTimeSelectOnly={showTimeSelectOnly}
      showTimeSelect={showTimeSelect}
      timeIntervals={timeIntervals}
      timeCaption={timeCaption}
      {...locale}
      onKeyDown={onKeyDown}
    />
  );
};
