import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Collapse } from "reactstrap";

import {
  DateString,
  ExpertWorkHistory
} from "@arbolus-technologies/models/common";
import { ExpertDiscoverFilters } from "@arbolus-technologies/models/project";
import { APP_DATE_FORMAT, formatDate } from "@arbolus-technologies/utils";

import { ChevronButton } from "../../Buttons/ChevronButton/ChevronButton";
import { WorkHistoryItem } from "../WorkHistoryItem/WorkHistoryItem";

import styles from "./WorkHistoryCollapse.module.scss";

const VISIBLE_WORK_HISTORY_ITEMS = 4;

interface WorkHistoryCollapseProps {
  workHistories: ExpertWorkHistory[];
  companyAndLocationFilter?: ExpertDiscoverFilters;
  keywordsList?: string[] | null;
  highlightWorkHistoryTitle?: boolean;
  highlightWorkHistoryCompany?: boolean;
  workHistoryLastUpdateDate?: DateString | null;
}

export const WorkHistoryCollapse: React.FC<WorkHistoryCollapseProps> = ({
  workHistories,
  companyAndLocationFilter,
  keywordsList,
  highlightWorkHistoryCompany = false,
  highlightWorkHistoryTitle = false,
  workHistoryLastUpdateDate
}) => {
  const { t } = useTranslation("workHistory");
  const [isWorkHistoryOpen, setIsWorkHistoryOpen] = useState(false);

  return (
    <>
      {workHistories
        .slice(0, VISIBLE_WORK_HISTORY_ITEMS)
        .map((workHistoryItem) => (
          <WorkHistoryItem
            key={workHistoryItem.id}
            title={workHistoryItem.title}
            from={workHistoryItem.from}
            to={workHistoryItem.to}
            companyName={workHistoryItem.companyName}
            countryName={workHistoryItem.countryName}
            companyAndLocationFilter={companyAndLocationFilter}
            keywordsList={keywordsList}
            highlightWorkHistoryCompany={highlightWorkHistoryCompany}
            highlightWorkHistoryTitle={highlightWorkHistoryTitle}
          />
        ))}
      {workHistories.length > VISIBLE_WORK_HISTORY_ITEMS && (
        <Collapse
          isOpen={isWorkHistoryOpen}
          className={styles.collapsibleWorkHistoryContainer}
        >
          {isWorkHistoryOpen &&
            workHistories
              .slice(VISIBLE_WORK_HISTORY_ITEMS)
              .map((workHistoryItem) => (
                <WorkHistoryItem
                  key={workHistoryItem.id}
                  title={workHistoryItem.title}
                  from={workHistoryItem.from}
                  to={workHistoryItem.to}
                  companyName={workHistoryItem.companyName}
                  countryName={workHistoryItem.countryName}
                  companyAndLocationFilter={companyAndLocationFilter}
                  keywordsList={keywordsList}
                  highlightWorkHistoryCompany={highlightWorkHistoryCompany}
                  highlightWorkHistoryTitle={highlightWorkHistoryTitle}
                />
              ))}
        </Collapse>
      )}
      {workHistoryLastUpdateDate && (
        <span className={styles.workHistoryLastUpdateDate}>
          {t("updatedDateWH", {
            date: formatDate(workHistoryLastUpdateDate, APP_DATE_FORMAT)
          })}
        </span>
      )}
      {workHistories.length > VISIBLE_WORK_HISTORY_ITEMS && (
        <div className={styles.chevronButton}>
          <ChevronButton
            icon={{
              direction: isWorkHistoryOpen ? "up" : "down",
              position: "end"
            }}
            text={t(isWorkHistoryOpen ? "showLess" : "showMore")}
            fontWeight={400}
            onClick={() => setIsWorkHistoryOpen((prev) => !prev)}
          />
        </div>
      )}
    </>
  );
};
