import { ICellRendererParams } from "ag-grid-community";
import { Icon } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useIsChatAvailable } from "@arbolus-technologies/features/common";
import { ProjectModel } from "@arbolus-technologies/models/common";
import {
  PROJECT_BOOK,
  PROJECT_CHAT_BASE_ROUTE,
  PROJECT_FILES_ROUTE,
  PROJECT_TRANSCRIPTS_ROUTE
} from "@arbolus-technologies/routes";

import styles from "./LinksRenderer.module.scss";

const iconSize = "16px";

export const LinksRenderer: React.FC<
  ICellRendererParams<ProjectModel, never, never>
> = ({ data }) => {
  const { t } = useTranslation("projectsTable");
  const displayChatLink = useIsChatAvailable();

  return (
    <div className="flex-row-16 align-items-center h-100">
      {displayChatLink && (
        <Link
          to={PROJECT_CHAT_BASE_ROUTE(data!.id)}
          className={styles.link}
          title={t("chat")}
        >
          <Icon name="forum" fontSize={iconSize} />
        </Link>
      )}
      <Link
        to={PROJECT_BOOK(data!.id)}
        className={styles.link}
        title={t("calendar")}
      >
        <Icon name="calendar_today" fontSize={iconSize} />
      </Link>
      <Link
        to={PROJECT_TRANSCRIPTS_ROUTE(data!.id)}
        className={styles.link}
        title={t("transcripts")}
      >
        <Icon name="speech_to_text" fontSize={iconSize} />
      </Link>
      <Link
        to={PROJECT_FILES_ROUTE(data!.id)}
        className={styles.link}
        title={t("resources")}
      >
        <Icon name="attach_file" fontSize={iconSize} />
      </Link>
    </div>
  );
};
