import moment from "moment";

import { WorkHistory, WorkHistoryUpdate } from "@arbolus-technologies/api";
import {
  getFirstDayOfLastMonth,
  getFirstDayOfMonth,
  monthsBetweenDates,
  monthsFromDate
} from "@arbolus-technologies/utils";

import { TimeGap, WorkHistoryItemModel } from "../../../interfaces";
import { MANDATORY_PAST_WORK_HISTORY_MONTHS } from "./constants";

export const getMissingTimeGaps = (workHistory: TimeGap[]): TimeGap[] => {
  const firstDayOfTheMonth = getFirstDayOfMonth();
  const firstDayOfLastMonth = getFirstDayOfLastMonth();
  const mandatoryPastWorkHistoryDate = monthsFromDate(
    firstDayOfTheMonth,
    -MANDATORY_PAST_WORK_HISTORY_MONTHS
  );

  const missingGaps: TimeGap[] = [];

  let lowerThreshold = mandatoryPastWorkHistoryDate;
  const filteredHistory = workHistory
    .filter((wh) => wh.to === null || wh.to >= mandatoryPastWorkHistoryDate)
    .sort((a, b) => a.from.getTime() - b.from.getTime());

  if (filteredHistory.length === 0) {
    missingGaps.push({
      from: mandatoryPastWorkHistoryDate,
      to: firstDayOfTheMonth
    });
    return missingGaps;
  }

  for (const workHistoryItem of filteredHistory) {
    const monthsDiff = monthsBetweenDates(workHistoryItem.from, lowerThreshold);

    if (monthsDiff > 2) {
      missingGaps.push({ from: lowerThreshold, to: workHistoryItem.from });
    }

    if (
      workHistoryItem.to === null ||
      workHistoryItem.to >= firstDayOfLastMonth
    ) {
      break;
    }

    if (workHistoryItem === filteredHistory[filteredHistory.length - 1]) {
      const monthsFromLastToNow = monthsBetweenDates(
        firstDayOfTheMonth,
        workHistoryItem.to
      );

      if (monthsFromLastToNow > 2) {
        missingGaps.push({ from: workHistoryItem.to, to: firstDayOfTheMonth });
      }
    }

    lowerThreshold = workHistoryItem.to!;
  }

  return missingGaps;
};

export const getThirdDayOfTheMonth = (date: Date): Date =>
  moment(date).date(3).toDate();

export const mapWorkHistoryToModel = (
  item: WorkHistory
): WorkHistoryItemModel => ({
  id: item.id,
  employedStatus: item.employedStatus,
  title: item.title,
  jobDescription: item.jobDescription,
  from: new Date(item.from),
  to: item.to ? new Date(item.to) : null,
  isCurrentWork: item.isCurrentWork,
  company: item.company
    ? {
        name: item.company.name,
        id: item.company.id
      }
    : null,
  country: item.country
    ? {
        threeLetterCode: item.country.isoCountryCode,
        name: item.country.name
      }
    : null
});

export const mapModelToWorkHistoryUpdate = (
  model: WorkHistoryItemModel
): WorkHistoryUpdate => ({
  companyId: model.company?.id,
  title: model.title,
  jobDescription: model.jobDescription,
  from: getThirdDayOfTheMonth(model.from),
  to: model.to && !model.isCurrentWork ? getThirdDayOfTheMonth(model.to) : null,
  isCurrentWork: model.isCurrentWork || model.to === null,
  isoCountryCode: model.country?.threeLetterCode,
  employedStatus: model.employedStatus
});

export const orderWorkHistoryItems = (
  a: WorkHistoryItemModel,
  b: WorkHistoryItemModel
): number => {
  if (!a.to && b.to) {
    return -1;
  }

  if (a.to && !b.to) {
    return 1;
  }

  if (!a.to && !b.to) {
    return a.from.getTime() >= b.from.getTime() ? -1 : 1;
  }

  return a.to!.getTime() >= b.to!.getTime() ? -1 : 1;
};
