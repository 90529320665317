import dompurify from "dompurify";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import { CanopyParamUrlTypes } from "@arbolus-technologies/models/canopy";
import { CanopyBuilderStoreActions } from "@arbolus-technologies/stores/canopy-builder";

import { CanopyInfo } from "../../Components/CanopyInfo/CanopyInfo";

import canopyInfoImage from "../../../assets/canopyInfo.png";

import styles from "./CanopyWelcome.module.scss";

export const CanopyWelcome: React.FC = () => {
  const { t } = useTranslation("welcomeCanopyPage");
  const { canopyId } = useParams<CanopyParamUrlTypes>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CanopyBuilderStoreActions.getPublicCanopy(canopyId));
  }, [dispatch, canopyId]);

  return (
    <div className={styles.container}>
      <CanopyInfo canopyId={canopyId} />
      <div className={styles.rightContainer}>
        <div className={styles.imageContainer}>
          <h2
            className={styles.title}
            dangerouslySetInnerHTML={{
              __html: dompurify.sanitize(t("arbolusTitle"))
            }}
          />
          <img
            className={styles.canopyImage}
            src={canopyInfoImage}
            alt="canopyImage"
          />
        </div>
      </div>
    </div>
  );
};
