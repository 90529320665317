export const SURVEY = "/survey/:surveyId";
export const SURVEY_COMPLIANCE = "/survey/:surveyId/compliance";
export const SURVEY_FEEDBACK = "/survey/:surveyId/feedback";

export const SURVEY_ROUTE = (surveyId: string): string => `/survey/${surveyId}`;
export const SURVEY_COMPLIANCE_ROUTE = (surveyId: string): string =>
  `/survey/${surveyId}/compliance`;
export const SURVEY_FEEDBACK_ROUTE = (surveyId: string): string =>
  `/survey/${surveyId}/feedback`;

export const SURVEY_ROUTES = [SURVEY, SURVEY_COMPLIANCE, SURVEY_FEEDBACK];
export const SHARED_ROUTES = [...SURVEY_ROUTES];
