import { CurrentUser } from "@arbolus-technologies/api";
import { useSingleQuery } from "@arbolus-technologies/utils";

import { MixPanelEvent } from "./enums";
import { MixPanelProperties } from "./types";
import {
  addEventInitialization,
  identifyUser,
  resetMixpanelOnLogout,
  setUserProfileDetails,
  trackEvent,
  trackPageView
} from "./utils";

export const useMixpanel = (): {
  trackEvent?: (
    eventName: MixPanelEvent,
    properties?: MixPanelProperties
  ) => void;
  trackPageView?: (properties?: MixPanelProperties) => void;
  addEventInitialization?: (eventName: MixPanelEvent) => void;
  identifyUser?: (id: string) => void;
  setUserProfileDetails?: (userDetails: CurrentUser) => void;
  resetMixpanelOnLogout?: () => void;
} => {
  const from = useSingleQuery("trackFrom");

  return {
    trackEvent: from
      ? (event: MixPanelEvent, properties?: MixPanelProperties) => {
          trackEvent(event, {
            ...properties,
            from
          });
        }
      : trackEvent,
    trackPageView,
    addEventInitialization,
    identifyUser,
    setUserProfileDetails,
    resetMixpanelOnLogout
  };
};
