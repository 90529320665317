import {
  DocumentType,
  extensionToIconMap
} from "@arbolus-technologies/models/documents";
import { getFileExtension } from "@arbolus-technologies/utils";

const allSupportedTypes: string[] = [
  DocumentType.CSV,
  DocumentType.DOC,
  DocumentType.EVENT,
  DocumentType.EXCEL,
  DocumentType.IMAGE,
  DocumentType.PDF,
  DocumentType.PRESENTATION,
  DocumentType.XLS,
  DocumentType.ZIP,
  DocumentType.GENERIC
];

export const isSupportedFileType = (
  file: File,
  supportedTypes: string[] = allSupportedTypes
): boolean => {
  const fileExtension = getFileExtension(file.name);

  return (
    extensionToIconMap.has(fileExtension) &&
    supportedTypes.includes(extensionToIconMap.get(fileExtension) || "")
  );
};
