import { OutputSelector, createSelector } from "reselect";

import { SelectOption } from "@arbolus-technologies/models/common";

import { CacheStateSelector } from "../CacheSelector";
import { CacheAppState, CacheReducerState } from "../models/definitions";

export const metadataCurrencies = (): OutputSelector<
  CacheAppState,
  SelectOption[],
  (res: CacheReducerState) => SelectOption[]
> =>
  createSelector<CacheAppState, CacheReducerState, SelectOption[]>(
    CacheStateSelector,
    (cacheReducerState) =>
      cacheReducerState.currencies.map((t) => ({
        value: t.isoCurrencyCode,
        label: t.isoCurrencyCode,
        customLabel: ""
      }))
  );
