import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Drawer } from "@arbolus-technologies/ui/components";

import { ProjectSimplifiedForm } from "../ProjectSimplifiedForm/ProjectSimplifiedForm";
import { StepHeader } from "../StepHeader/StepHeader";

import styles from "./ProjectDetailsStep.module.scss";

interface ProjectDetailsStepProps {
  name: string;
  setCanGoToNextStep: (canGoToNextStep: boolean) => void;
  clientId: string;
}

export const ProjectDetailsStep: React.FC<ProjectDetailsStepProps> = ({
  name,
  setCanGoToNextStep,
  clientId
}) => {
  const { t } = useTranslation("projectSimplifiedForm");
  const methods = useFormContext();
  useEffect(() => {
    setCanGoToNextStep(methods.formState.isValid);
  }, [methods.formState.isValid]);

  return (
    <div className={styles.formContainer}>
      <Drawer
        titleChildren={() => <StepHeader title={name} stepNum={1} />}
        openByDefault
        disableCollapse
      >
        {t("projectDetailsExplanation")}
      </Drawer>
      <ProjectSimplifiedForm clientId={clientId} />
    </div>
  );
};
