import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "arbolus-ui-components";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  DefaultToasterService,
  ExpertService,
  ToasterService
} from "@arbolus-technologies/api";
import {
  InputController,
  WordCounter
} from "@arbolus-technologies/ui/components";

import { DNC_MAX_NOTE_LENGTH } from "../../../constants";
import {
  DNCHistoryNoteFormValues,
  EditHistoryNoteFormSchema
} from "./EditHistoryNoteFormSchema";

import styles from "./DNCHistoryItem.module.scss";

interface EditHistoryNoteFormProps {
  dncHistoryId: string;
  doNotContactStatusDescription?: string | null;
  onUpdate: () => void;
  onCancel: () => void;
  expertService?: typeof ExpertService;
  notificationService?: ToasterService;
}

export const EditHistoryNoteForm: React.FC<EditHistoryNoteFormProps> = ({
  dncHistoryId,
  doNotContactStatusDescription,
  onUpdate,
  onCancel,
  expertService = ExpertService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("dnc");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { isDirty, isValid, errors }
  } = useForm<DNCHistoryNoteFormValues>({
    resolver: yupResolver(EditHistoryNoteFormSchema),
    mode: "all",
    defaultValues: {
      dncNote: doNotContactStatusDescription?.trim() ?? ""
    }
  });

  const onSubmit = (data: DNCHistoryNoteFormValues) => {
    setIsSubmitting(true);
    expertService
      .updateExpertsDNCNote(dncHistoryId, data.dncNote)
      .subscribe(
        () => {
          notificationService.showSuccess(t("editSuccess"));
          onUpdate();
        },
        () => {
          notificationService.showError(t("editFailed"));
        }
      )
      .add(() => {
        setIsSubmitting(false);
        handleCancel();
      });
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <>
      <div className={styles.inputContainer}>
        <InputController
          name="dncNote"
          error={errors.dncNote}
          classnames={styles.input}
          control={control}
          placeholder={t("notePlaceholder")}
          type="textarea"
          size="big"
          autoFocus
        />
        <WordCounter
          maxLength={DNC_MAX_NOTE_LENGTH}
          textLength={watch("dncNote")?.length}
        />
      </div>
      <div className={styles.btnContainer}>
        <Button
          type="secondary"
          text={t("cancel")}
          onClick={handleCancel}
          disabled={isSubmitting}
        />
        <Button
          text={t("save")}
          onClick={handleSubmit(onSubmit)}
          disabled={!isDirty || !isValid || isSubmitting}
        />
      </div>
    </>
  );
};
