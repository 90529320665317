import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import {
  BookedReferral,
  IBookedReferral,
  IReferral,
  ISlot,
  ISlotReferral,
  Referral,
  Slot,
  SlotsReferral
} from "@arbolus-technologies/models/common";
import {
  ReferralsStatsResponse,
  RejectDescription
} from "@arbolus-technologies/models/project";
import { ExtractMethods } from "@arbolus-technologies/utils";

import { BaseRestService } from "../BaseRestService";
import { ListResponse, SuccessResponse } from "../models/api";
import { restService } from "../restService";

export type IReferralsService = ExtractMethods<ReferralsService>;

export class ReferralsService {
  private static readonly Endpoint = "/projects";

  private readonly _restService: BaseRestService;

  constructor(restService: BaseRestService) {
    this._restService = restService;
  }

  public getShortlist(projectId: string): Observable<Referral[]> {
    return this.getStage(`${ReferralsService.Endpoint}/${projectId}/shortlist`);
  }

  public getCandidate(projectId: string): Observable<Referral[]> {
    return this.getStage(`${ReferralsService.Endpoint}/${projectId}/candidate`);
  }

  public getScheduling(projectId: string): Observable<SlotsReferral[]> {
    return this._restService
      .get<ListResponse<ISlotReferral>>(
        `${ReferralsService.Endpoint}/${projectId}/scheduling`
      )
      .pipe(map((response) => response.items.map(SlotsReferral.fromObject)));
  }

  public getBooked(projectId: string): Observable<BookedReferral[]> {
    return this._restService
      .get<ListResponse<IBookedReferral>>(
        `${ReferralsService.Endpoint}/${projectId}/booked`
      )
      .pipe(map((response) => response.items.map(BookedReferral.fromObject)));
  }

  public getReject(projectId: string): Observable<Referral[]> {
    return this.getStage(`${ReferralsService.Endpoint}/${projectId}/reject`);
  }

  public sendApplication(
    projectId: string,
    expertId: string
  ): Observable<SuccessResponse> {
    return this._restService.put(
      `${ReferralsService.Endpoint}/${projectId}/experts/${expertId}/apply`,
      {}
    );
  }

  public resetApplication(
    projectId: string,
    expertId: string
  ): Observable<SuccessResponse> {
    return this._restService.put(
      `${ReferralsService.Endpoint}/${projectId}/experts/${expertId}/reset`,
      {}
    );
  }

  public moveToCandidate(
    projectId: string,
    expertId: string
  ): Observable<SuccessResponse> {
    return this._restService.put(
      `${ReferralsService.Endpoint}/${projectId}/experts/${expertId}/candidate`,
      {}
    );
  }

  public approve(
    projectId: string,
    expertId: string
  ): Observable<SuccessResponse> {
    return this._restService.put(
      `${ReferralsService.Endpoint}/${projectId}/experts/${expertId}/approve`,
      {}
    );
  }

  public reject(
    projectId: string,
    expertId: string,
    rejectDescription?: RejectDescription
  ): Observable<SuccessResponse> {
    return this._restService.put(
      `${ReferralsService.Endpoint}/${projectId}/experts/${expertId}/reject`,
      { rejectDescription }
    );
  }

  public reactive(
    projectId: string,
    expertId: string
  ): Observable<SuccessResponse> {
    return this._restService.put(
      `${ReferralsService.Endpoint}/${projectId}/experts/${expertId}/reactivate`,
      {}
    );
  }

  public getReferralsStats(projectId: string, angleId?: string) {
    return this._restService.get<ReferralsStatsResponse>(
      `${ReferralsService.Endpoint}/${projectId}/referrals/stats`,
      { angleId }
    );
  }

  public getSlots(projectId: string, expertId: string) {
    return this._restService
      .get<ListResponse<ISlot>>(
        `${ReferralsService.Endpoint}/${projectId}/experts/${expertId}/slots`
      )
      .pipe(map(({ items }) => items.map(Slot.fromObject)));
  }

  public updateOwner(projectId: string, expertId: string, ownerId: string) {
    return this._restService.put<SuccessResponse>(
      `${ReferralsService.Endpoint}/${projectId}/experts/${expertId}/owner/${ownerId}`,
      {}
    );
  }

  private getStage(endpoint: string): Observable<Referral[]> {
    return this._restService
      .get<ListResponse<IReferral>>(endpoint)
      .pipe(map((response) => response.items.map(Referral.fromObject)));
  }
}

export const DefaultReferralsService = new ReferralsService(restService);
