import { LoggedInUser } from "@arbolus-technologies/api";

import {
  GET_LOGGED_IN_USER,
  GetLoggedInUserAction
} from "./CacheGetLoggedInUserActionTypes";

export const getLoggedInUser = (
  loggedInUser: LoggedInUser
): GetLoggedInUserAction => ({
  type: GET_LOGGED_IN_USER,
  payload: {
    loggedInUser
  }
});
