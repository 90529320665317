import { Observable } from "rxjs";

import { BaseRestService } from "../BaseRestService";
import { SuccessResponse } from "../models/api";
import {
  ClientCompliance,
  ComplianceCommunication,
  UpdateComplianceCommunicationRequest,
  UpdateComplianceRequest
} from "../models/compliance";
import { restService } from "../restService";

export class ComplianceService {
  private static readonly Endpoint = "/compliance";

  private readonly _restService: BaseRestService;

  constructor(restService: BaseRestService) {
    this._restService = restService;
  }

  public getCompliance(clientId: string): Observable<ClientCompliance> {
    return this._restService.get(`${ComplianceService.Endpoint}/${clientId}`);
  }

  public updateCompliance(
    clientId: string,
    body: UpdateComplianceRequest
  ): Observable<SuccessResponse> {
    return this._restService.put(
      `${ComplianceService.Endpoint}/${clientId}`,
      body
    );
  }

  public getComplianceCommunication(
    clientId: string
  ): Observable<ComplianceCommunication> {
    return this._restService.get(
      `${ComplianceService.Endpoint}/${clientId}/compliance-communication`
    );
  }

  public updateComplianceCallAutomatedMessages(
    clientId: string,
    body: UpdateComplianceCommunicationRequest
  ): Observable<SuccessResponse> {
    return this._restService.put(
      `${ComplianceService.Endpoint}/${clientId}/compliance-communication`,
      body
    );
  }
}

export const DefaultComplianceService = new ComplianceService(restService);
