import React from "react";
import { useParams } from "react-router";

import {
  MixpanelPages,
  PageTracker,
  ProjectExpertManagementWrapper
} from "@arbolus-technologies/features/common";

import { ExternalExpertList } from "../../Components/ExternalExpertList/ExternalExpertList";
import { ExternalSourcingFilters } from "../../Components/ExternalSourcingFilters/ExternalSourcingFilters";
import { ExternalSourcingProvider } from "../../Contexts/ExternalSourcingContext/ExternalSourcingContext";

import styles from "./ExternalSourcingPage.module.scss";

export const ExternalSourcingPage: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();

  return (
    <PageTracker page={MixpanelPages.ExternalSourcing}>
      <ProjectExpertManagementWrapper>
        <ExternalSourcingProvider projectId={projectId}>
          <div className={styles.container}>
            <ExternalSourcingFilters />
            <ExternalExpertList />
          </div>
        </ExternalSourcingProvider>
      </ProjectExpertManagementWrapper>
    </PageTracker>
  );
};
