import { Subject } from "rxjs";

export enum TranscriptHighLightEventType {
  CLICK_OWN_HIGHLIGHT
}

export interface TranscriptHighLightEvent {
  eventType: TranscriptHighLightEventType;
  highlightId: string;
}

class TranscriptHighLightEvents {
  eventSubject: Subject<TranscriptHighLightEvent>;

  constructor() {
    this.eventSubject = new Subject();
  }

  selectOwnHighlight(highlightId: string): void {
    this.eventSubject.next({
      eventType: TranscriptHighLightEventType.CLICK_OWN_HIGHLIGHT,
      highlightId
    });
  }
}

export default new TranscriptHighLightEvents();
