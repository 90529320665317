import { PanelId } from "../models/definitions";
import {
  closeInputMainModal,
  closeListMainModal,
  closeMainModal,
  openInputMainModal,
  openListMainModal,
  openMainModal
} from "./ModalActions";
import {
  CLOSE_PANEL,
  ClosePanelAction,
  OPEN_PANEL,
  OpenPanelAction
} from "./PanelActionTypes";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const openPanel = <T = any>(
  panelId: PanelId,
  data?: T
): OpenPanelAction => ({
  type: OPEN_PANEL,
  payload: {
    panelId,
    data
  }
});

export const closePanel = (panelId: PanelId): ClosePanelAction => ({
  type: CLOSE_PANEL,
  payload: {
    panelId
  }
});

export const PanelStoreActions = {
  openPanel,
  closePanel,
  openMainModal,
  closeMainModal,
  openListMainModal,
  closeListMainModal,
  openInputMainModal,
  closeInputMainModal
};
