import { Button, Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { HR } from "../HR/HR";

import styles from "./ButtonDropdown.module.scss";

interface ButtonDropdownProps {
  text: string;
  options: {
    name: string;
    iconName?: string;
    onClick?: () => void;
    disabled?: boolean;
    isSeparator?: boolean;
  }[];
  disabled?: boolean;
  onTrack?: () => void;
  direction?: "up" | "down" | "left" | "right";
  placement?: "left" | "right";
}

export const ButtonDropdown: React.FC<ButtonDropdownProps> = ({
  text,
  options,
  disabled = false,
  onTrack,
  direction = "down",
  placement = "left"
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    isOpen && onTrack?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Dropdown
      toggle={handleToggleDropdown}
      isOpen={isOpen}
      direction={direction}
    >
      <DropdownToggle data-toggle="dropdown" tag="span">
        <Button
          disabled={disabled}
          text={text}
          endIcon={isOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"}
        />
      </DropdownToggle>
      <DropdownMenu className={clsx(styles.dropdownMenu, styles[placement])}>
        <div className={styles.content}>
          {options.map((option) =>
            option.isSeparator ? (
              <HR key={option.name} margin={{ top: 0, bottom: 0 }} />
            ) : (
              <button
                key={option.name}
                className={styles.dropdownItemButton}
                onClick={option.onClick}
              >
                {option.iconName && (
                  <Icon
                    fontSize="16px"
                    name={option.iconName}
                    color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
                  />
                )}
                {option.name}
              </button>
            )
          )}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};
