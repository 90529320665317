import { Location } from "history";
import React from "react";
import { match } from "react-router";
import { TabContent, TabPane } from "reactstrap";

import {
  PROJECT_BASE_TAB,
  PROJECT_CALENDAR_TAB,
  PROJECT_EVENT_TAB,
  PROJECT_FILES_TAB
} from "../../../../../constants/navigation/projectTabRoutes";
import {
  CalendarPage,
  EventPage,
  FilesPage,
  ProjectBasePage
} from "../../../pages";

interface ProjectTabsProps {
  activeTab: string;
  location: Location<{
    eventPageUuid?: string;
  }>;
  match: match<{
    projectId: string;
    eventId: string;
  }>;
}

export const ProjectTabs: React.FC<ProjectTabsProps> = ({
  activeTab,
  location,
  match
}) => (
  <div className="p-0">
    <TabContent activeTab={activeTab}>
      <TabPane tabId={PROJECT_BASE_TAB} className="bg-grey">
        {activeTab === PROJECT_BASE_TAB && <ProjectBasePage />}
      </TabPane>
      <TabPane tabId={PROJECT_CALENDAR_TAB}>
        {/* @ts-ignore */}
        {activeTab === PROJECT_CALENDAR_TAB && <CalendarPage />}
      </TabPane>
      <TabPane tabId={PROJECT_FILES_TAB}>
        {/* @ts-ignore */}
        {activeTab === PROJECT_FILES_TAB && <FilesPage />}
      </TabPane>
      <TabPane tabId={PROJECT_EVENT_TAB}>
        {activeTab === PROJECT_EVENT_TAB && (
          // @ts-ignore
          <EventPage
            // @ts-ignore
            eventId={match.params.eventId}
            eventPageUuid={location.state?.eventPageUuid}
          />
        )}
      </TabPane>
    </TabContent>
  </div>
);
