import { Button } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import styles from "./DeleteModal.module.scss";

interface DeleteModalProps {
  onCancel: () => void;
  onConfirm: () => void;
  toggleModal: boolean;
  deleteActionText?: string;
  cancelActionText?: string;
  warningMessage: string;
  messageTitle: string;
  warningMessageSecond?: string;
  onConfirmButtonColor?: boolean;
}

/**
 * @deprecated
 * use ArbolusModal from @arbolus-technologies/libs/ui/components
 */

export const DeleteModal: React.FC<DeleteModalProps> = ({
  onCancel,
  onConfirm,
  toggleModal,
  messageTitle,
  warningMessage,
  deleteActionText,
  cancelActionText,
  warningMessageSecond,
  onConfirmButtonColor = false
}) => {
  const { t } = useTranslation("deleteModal");
  return (
    <Modal
      isOpen={toggleModal}
      className={clsx(styles.deleteModal, "modal-alert")}
    >
      <ModalHeader>{messageTitle}</ModalHeader>
      <ModalBody className={styles.content}>
        <div className="modal-body-container">
          <div>{warningMessage}</div>
          {warningMessageSecond ? <div>{warningMessageSecond}</div> : null}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="secondary"
          onClick={onCancel}
          text={cancelActionText || t("cancel")}
        />
        <Button
          type={onConfirmButtonColor ? "primary" : "rejection"}
          onClick={(): void => onConfirm()}
          text={deleteActionText || t("delete")}
        />
      </ModalFooter>
    </Modal>
  );
};
