import React, { useEffect, useState } from "react";
import { Loader } from "react-bootstrap-typeahead";
import { useTranslation } from "react-i18next";

import {
  CIQError,
  ClientService,
  DefaultToasterService,
  ErrorResponse,
  ToasterService,
  User
} from "@arbolus-technologies/api";
import { Permission } from "@arbolus-technologies/models/common";
import { PermissionsForm } from "@arbolus-technologies/ui/components";

import { UpdateUserPermissionsModal } from "../../../Permissions/UpdatePermissionsModal/UpdatePermissionsModal";

interface ClientMemberPermissionsTabProps {
  user: User;
  onUserDecision?: () => void;
  clientService?: typeof ClientService;
  notificationService?: ToasterService;
}

export const ClientMemberPermissionsTab: React.FC<
  ClientMemberPermissionsTabProps
> = ({
  user,
  onUserDecision,
  clientService = ClientService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("permissions");

  const [allowUpdate, setAllowUpdate] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<Permission[] | undefined>();
  const [updatedPermissions, setUpdatedPermissions] = useState<Permission[]>(
    []
  );
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onSavePermissions = (updatedPermissions: Permission[]) => {
    setUpdatedPermissions(updatedPermissions);
    setIsModalVisible(true);
  };

  const callUpdatePermissions = () => {
    clientService
      .updateTeamMemberPermissions(user.id, updatedPermissions)
      .subscribe(
        () => {
          notificationService.showSuccess(t("updateSuccess"));
          if (onUserDecision) {
            onUserDecision();
          }
          setIsModalVisible(false);
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
        }
      );
  };

  useEffect(() => {
    clientService.getTeamMemberPermissions(user.id).subscribe({
      next: (response) => {
        const { allowUpdate, items } = response;
        setAllowUpdate(allowUpdate);
        setPermissions(items);
      },
      error: (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
      }
    });
  }, [user.id, notificationService, clientService]);

  if (!permissions) return <Loader />;

  return (
    <>
      <PermissionsForm
        allowUpdate={allowUpdate}
        permissions={permissions}
        onSave={onSavePermissions}
        onCancel={onUserDecision}
      />
      <UpdateUserPermissionsModal
        userName={`${user.firstName} ${user.lastName}`}
        toggle={() => setIsModalVisible((prev) => !prev)}
        onConfirm={callUpdatePermissions}
        isOpen={isModalVisible}
      />
    </>
  );
};
