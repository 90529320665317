import { Button } from "arbolus-ui-components";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";

import {
  CIQError,
  CanopyService,
  DefaultToasterService,
  ErrorResponse,
  ToasterService
} from "@arbolus-technologies/api";
import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import {
  CANOPY_STATUS,
  CanopyParamUrlTypes
} from "@arbolus-technologies/models/canopy";
import {
  CANOPY_EXPERTS_ROUTE,
  CANOPY_QUESTIONS_ROUTE,
  PROJECT_ROUTE
} from "@arbolus-technologies/routes";
import {
  ArbolusModal,
  ChevronButton
} from "@arbolus-technologies/ui/components";

import styles from "./CanopyBuilderHeader.module.scss";

interface CanopyBuilderHeaderProps {
  hasQuestions: boolean;
  projectId: string;
  isActiveCanopy: boolean;
  handleShowUnsavedChanges: () => void;
  canopyService?: typeof CanopyService;
  notificationService?: ToasterService;
}

export const CanopyBuilderHeader: React.FC<CanopyBuilderHeaderProps> = ({
  hasQuestions,
  projectId,
  isActiveCanopy,
  handleShowUnsavedChanges,
  notificationService = DefaultToasterService,
  canopyService = CanopyService
}) => {
  const { t } = useTranslation("canopyBuilder");
  const { trackClick } = useArbolusTracking();
  const history = useHistory();
  const { canopyId } = useParams<CanopyParamUrlTypes>();

  const [showModal, setShowModal] = useState<boolean>(false);

  const handleLaunchNow = () => {
    trackClick(MixPanelEventNames.CanopyBuilderLaunch);

    canopyService.changeCanopyStatus(canopyId, CANOPY_STATUS.ACTIVE).subscribe(
      () => {
        notificationService.showSuccess(t("canopyLaunchedSuccessfully"));
        setShowModal(false);
        history.push(CANOPY_EXPERTS_ROUTE(canopyId));
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
        setShowModal(false);
      }
    );
  };

  return (
    <div className={styles.container}>
      <ChevronButton
        text={t("backToProject")}
        fontWeight={400}
        icon={{
          direction: "left",
          position: "start"
        }}
        to={PROJECT_ROUTE(projectId)}
      />
      {isActiveCanopy ? (
        <Button
          text={t("goToQuestions")}
          onClick={() => history.push(CANOPY_QUESTIONS_ROUTE(canopyId))}
        />
      ) : (
        <Button
          text={isMobile ? t("launch") : t("launchCanopy")}
          disabled={!hasQuestions}
          onClick={() => setShowModal(true)}
        />
      )}
      <ArbolusModal
        title={t("launchCanopyModalTitle")}
        subtitle={t("launchCanopyModalMessage")}
        toggle={() => setShowModal(false)}
        isOpen={showModal}
        leftButton={{
          text: t("cancel"),
          type: "secondary",
          onClick: () => {
            setShowModal(false);
            handleShowUnsavedChanges();
          }
        }}
        rightButton={{
          text: t("launchNow"),
          type: "primary",
          onClick: handleLaunchNow
        }}
      />
    </div>
  );
};
