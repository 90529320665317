import i18next from "i18next";
import * as Yup from "yup";

import { ExpertProfileConstraints } from "@arbolus-technologies/ui/components";
import { utilService } from "@arbolus-technologies/utils";

export interface ExpertBackgroundFormValues {
  background: string;
}

export const ExpertBackgroundSchema: Yup.ObjectSchema<ExpertBackgroundFormValues> =
  Yup.object().shape({
    background: Yup.string()
      .required()
      .test(
        "html-len-max",
        i18next.t("editExpertBackground:backgroundMaxLength", {
          length: ExpertProfileConstraints.MAX_BACKGROUND_LENGTH
        }),
        (val) => {
          const stripedHtml = utilService.getStrippedTextFromHtml(val || "");
          return (
            stripedHtml.length < ExpertProfileConstraints.MAX_BACKGROUND_LENGTH
          );
        }
      )
  });
