import React from "react";
import { useTranslation } from "react-i18next";

import { EmptyComponent } from "../EmptyComponent";

import openFolder from "../../../assets/images/open-folder.png";

export const ProjectEmptyDocuments: React.FC = () => {
  const { t } = useTranslation("projectEmptyDocuments");
  return (
    <EmptyComponent
      title={t("emptyStateTitle")}
      subtitle={t("emptyStateSubtitle")}
      logo={openFolder}
    />
  );
};
