import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { BookmarksList } from "../../Components/BookmarksList/BookmarksList";
import { CollectionPageActions } from "../../Components/CollectionPageActions/CollectionPageActions";
import { useBookmarkTitle } from "../../Components/Hooks/useBookmarkTitle";

import styles from "./CollectionPage.module.scss";

export const CollectionPage: React.FC = () => {
  const { t } = useTranslation("bookmarks");

  useBookmarkTitle(true);

  return (
    <div className={clsx("page-content", styles.whiteBackground)}>
      <div className={clsx("page-content-header", styles.pageHeader)}>
        <div className="top-container">
          <h1>{t("title")}</h1>
          <CollectionPageActions classnames={styles.withSideMenu} />
        </div>
      </div>
      <div className={styles.container}>
        <BookmarksList />
      </div>
    </div>
  );
};
