import { yupResolver } from "@hookform/resolvers/yup";
import { Icon } from "arbolus-ui-components";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  ProjectData,
  withProjectData
} from "@arbolus-technologies/features/common";
import { PROJECT_ROUTE } from "@arbolus-technologies/routes";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { Drawer, HR } from "@arbolus-technologies/ui/components";

import {
  ACTIVATE_CHAT,
  BRIEF,
  CASE_CODE,
  CONTACT_NUMBER,
  END_DATE,
  EXPERT_COUNT,
  MAIN_CONTACT,
  MANAGER_EMAIL,
  MANAGER_NAME,
  PARTNER_EMAIL,
  PARTNER_NAME,
  PROJECT_NAME,
  START_DATE,
  SimplifiedProjectEditFormValues,
  TIMEZONE
} from "../../type";

import { ProjectEditSchema } from "../../SimplifiedProjectSchema";
import { BackToProjectLink } from "../../components/SimplifiedCreateProject/BackToProjectLink/BackToProjectLink";
import { EditFormFooter } from "../../components/SimplifiedCreateProject/EditFormFooter/EditFormFooter";
import { ProjectSimplifiedForm } from "../../components/SimplifiedCreateProject/ProjectSimplifiedForm/ProjectSimplifiedForm";

import styles from "./SimplifiedProjectEditPage.module.scss";

export const SimplifiedProjectEditPage: React.FC<{
  projectData: ProjectData;
}> = withProjectData(({ projectData }) => {
  const { t } = useTranslation("projectSimplifiedEdit");
  const defaultValues: SimplifiedProjectEditFormValues = {
    [PROJECT_NAME]: projectData.name,
    [BRIEF]: projectData.internalDescription,
    [TIMEZONE]: projectData.timezone,
    [MAIN_CONTACT]: projectData.mainContactUser,
    [CONTACT_NUMBER]: projectData.adminContactNo,
    [START_DATE]: new Date(projectData.startDate),
    [END_DATE]: projectData.endDate ? new Date(projectData.endDate) : null,
    [CASE_CODE]: projectData.caseCode,
    [MANAGER_NAME]: projectData.managerName,
    [MANAGER_EMAIL]: projectData.managerEmail,
    [PARTNER_NAME]: projectData.partnerName,
    [PARTNER_EMAIL]: projectData.partnerEmail,
    [ACTIVATE_CHAT]: projectData.enableWorkspaces,
    [EXPERT_COUNT]: 0
  };
  const methods = useForm({
    // @ts-ignore
    resolver: yupResolver(ProjectEditSchema),
    mode: "all",
    defaultValues
  });

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.pageContainer}>
        <div className={styles.header}>
          <BackToProjectLink
            projectId={projectData.id}
            projectName={projectData.name}
          />
          <Link
            to={PROJECT_ROUTE(projectData.id)}
            className={styles.headerLink}
          >
            <Icon
              name="folder"
              fontSize="24px"
              color={ARBOLUS_COLORS.bColorBasePurple}
            />
            <h1>{t("brief")}</h1>
          </Link>
        </div>

        <HR margin={{ top: 3, bottom: 5 }} />

        <div className={styles.form}>
          <Drawer disableCollapse openByDefault>
            <h2 className={styles.pageSubtitle}>{t("projectDetails")}</h2>
          </Drawer>

          <FormProvider {...methods}>
            <ProjectSimplifiedForm
              clientId={projectData.clientId}
              disableExpertCount
              editMode
            />
            <EditFormFooter />
          </FormProvider>
        </div>
      </div>
    </div>
  );
});
