import { Button, Icon } from "arbolus-ui-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  AdminService,
  CIQError,
  ClientService,
  ErrorResponse,
  ToasterService
} from "@arbolus-technologies/api";
import { InternalSlidePanel } from "@arbolus-technologies/features/common";
import { Permission } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  Checkbox,
  FilterType,
  FilterWithBadges,
  HR
} from "@arbolus-technologies/ui/components";

import styles from "./PermissionsFilter.module.scss";

interface SelectedPermission {
  [key: string]: boolean;
}

export interface PermissionsFilterProps {
  onFilter: (filterIds: string[]) => void;
  selectedPermissionIds: string[];
  adminService?: typeof AdminService;
  clientService?: typeof ClientService;
}

const notificationService = new ToasterService();
export const PermissionsFilter: React.FC<PermissionsFilterProps> = ({
  onFilter,
  selectedPermissionIds = [],
  adminService = AdminService,
  clientService = ClientService
}) => {
  const { t } = useTranslation("permissions");
  const [permissionsList, setPermissionsList] = useState<Permission[]>([]);
  const [selectedPermissions, setSelectedPermissions] =
    useState<SelectedPermission>({});
  const [isPermissionFilterPanelOpen, setIsPermissionFilterPanelOpen] =
    useState<boolean>(false);

  const isAdmin = useSelector(CacheSelector.isAdmin());

  useEffect(() => {
    const permission: SelectedPermission = {};
    selectedPermissionIds.forEach((id) => (permission[id] = true));
    setSelectedPermissions(permission);
  }, [selectedPermissionIds]);

  useEffect(() => {
    const observable = isAdmin
      ? adminService.getSpecialPermissions()
      : clientService.getSpecialPermissions();

    observable.subscribe({
      next: (response) => {
        setPermissionsList(response.items);

        const permission: SelectedPermission = {};
        response.items.forEach((item) => (permission[item.claimValue] = false));
        setSelectedPermissions(permission);
      },
      error: (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
      }
    });
  }, [adminService, clientService, isAdmin]);

  const handleSelectPermission = (claimValue: string) => {
    setSelectedPermissions((selectedPermissions) => {
      selectedPermissions[claimValue] = !selectedPermissions[claimValue];
      return { ...selectedPermissions };
    });
  };

  const handleTogglePermissionsFilterPanel = () => {
    setIsPermissionFilterPanelOpen((permissionsFilter) => !permissionsFilter);
  };

  const handleCancel = () => {
    const permission: SelectedPermission = {};
    selectedPermissionIds.forEach((id) => (permission[id] = true));
    setSelectedPermissions(permission);
    handleTogglePermissionsFilterPanel();
  };

  const handleApplyFilter = () => {
    onFilter(
      Object.keys(selectedPermissions)
        .filter((perm) => selectedPermissions[perm])
        .map((perm) => perm)
    );
    handleTogglePermissionsFilterPanel();
  };

  const handleFilter = (filteredItems: FilterType[]) => {
    onFilter(filteredItems.map((perm) => perm.id));
  };

  return (
    <>
      <FilterWithBadges
        data={permissionsList
          .filter(
            (permission) =>
              !!selectedPermissionIds.includes(permission.claimValue)
          )
          .map((permission) => ({
            id: permission.claimValue,
            text: t(permission.claimValue)
          }))}
        onFilter={handleFilter}
        filterButtonText={t("permissions")}
        onFilterButtonClick={handleTogglePermissionsFilterPanel}
        showNoFiltersAppliedMessage
      />
      <InternalSlidePanel
        customCloseRequest={handleCancel}
        isOpen={isPermissionFilterPanelOpen}
        hideHeader
      >
        <div className={styles.filterPermissionsSlidePanelContainer}>
          <div className={styles.filterPermissionsSlidePanelHeader}>
            <h2>{t("filterPermissions")}</h2>
            <Icon name="close" fontSize="18px" onClick={handleCancel} />
          </div>
          <HR margin={{ top: 3, bottom: 3 }} />
          <div className={styles.filterPermissionsSlidePanelBody}>
            {permissionsList.map((permission) => (
              <Checkbox
                key={permission.claimValue}
                isChecked={!!selectedPermissions[permission.claimValue]}
                onChange={() => handleSelectPermission(permission.claimValue)}
                text={t(permission.claimValue)}
              />
            ))}
          </div>
          <HR margin={{ top: 0, bottom: 3 }} />
          <div className={styles.filterPermissionsSlidePanelFooter}>
            <Button type="tertiary" text={t("cancel")} onClick={handleCancel} />
            <Button text={t("seeResults")} onClick={handleApplyFilter} />
          </div>
        </div>
      </InternalSlidePanel>
    </>
  );
};
