import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Media from "react-media";
import { connect } from "react-redux";
import { TabContent, TabPane } from "reactstrap";
import { Dispatch } from "redux";
import { createStructuredSelector } from "reselect";
import { Subscription } from "rxjs";
import SimpleBar from "simplebar-react";

import { ToasterService } from "@arbolus-technologies/api";
import {
  CustomAvatarSelect,
  MixpanelPages,
  trackPageView
} from "@arbolus-technologies/features/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { Loader } from "@arbolus-technologies/ui/components";
import { AntDHeader } from "@arbolus-technologies/ui/layout";

import { CLIENTS_API } from "../../../../constants/api";
import {
  APP_DEVICE_MEDIA_QUERIES,
  UI_SETTINGS_PAGE
} from "../../../../constants/ui";
import { CIQError, ErrorResponse } from "../../../../models/api";
import { Client } from "../../../../models/client";
import { LoggedInUser, UpdateClientRequest } from "../../../../models/user";
import { ClientService, DocumentService } from "../../../../services";
import { AppAction } from "../../../../store/actions";
import { AppState } from "../../../../store/reducers";
import { AppStoreActions } from "../../../app/store";
import { AuthSelector } from "../../../auth/store";
import DetailsTab, {
  DetailsTabFormValues
} from "../../components/client-account/DetailsTab";

const notification = new ToasterService();

export enum ClientAccountTab {
  DETAILS
}

interface ClientSettingsPageStoreProps {
  clientId?: string;
  loggedInUser: LoggedInUser;
  isCurrentUserClient: boolean;
}

interface ClientSettingsPageProps
  extends ClientSettingsPageStoreProps,
    WithTranslation {
  getUserProfile: () => void;
  updateUserProfileClientName: (name: string) => void;
}

interface ClientSettingsPageState {
  isLoading: boolean;
  isSaving: boolean;
  isCompanyLogoUploading: boolean;
  client?: Client;
  activeTab: number;
}

class ClientSettingsPage extends React.Component<
  ClientSettingsPageProps,
  ClientSettingsPageState
> {
  constructor(props: ClientSettingsPageProps) {
    super(props);
    this.state = {
      isLoading: true,
      isSaving: false,
      isCompanyLogoUploading: false,
      activeTab: ClientAccountTab.DETAILS
    };
  }

  componentDidMount(): void {
    const { t, isCurrentUserClient } = this.props;
    document.title = t("account");
    this.fetchClient();

    if (isCurrentUserClient) {
      trackPageView({ page: MixpanelPages.Profile });
    }
  }

  componentWillUnmount(): void {
    this.companyLogoUploadSubscription?.unsubscribe();
    this.getClientSubscription?.unsubscribe();
    this.updateClientDetailsSubscription?.unsubscribe();
  }

  private companyLogoUploadSubscription?: Subscription;

  private getClientSubscription?: Subscription;

  private updateClientDetailsSubscription?: Subscription;

  fetchClient = (): void => {
    const { clientId, loggedInUser, t } = this.props;

    this.getClientSubscription = ClientService.getClient(clientId!).subscribe(
      (res: Client) => {
        const { owner, ownerId } = res;
        const isIamMainContact = loggedInUser.id === ownerId;
        let editedOwner = owner;

        if (isIamMainContact) {
          editedOwner = { ...owner, firstName: t("me"), lastName: "" };
        }

        const clientResponse = {
          ...res,
          owner: editedOwner
        } as Client;

        this.setState({
          isLoading: false,
          client: clientResponse
        });
      },
      (error: ErrorResponse<CIQError>) => {
        notification.showError(error.message);
      }
    );
  };

  getClientAccountRequestObject = ({
    name,
    externalName,
    ownerId
  }: Client): UpdateClientRequest => ({
    name,
    externalName,
    ownerId
  });

  handleSelectedImage = (companyLogo: File): void => {
    const { clientId, getUserProfile, t } = this.props;

    this.setState({
      isCompanyLogoUploading: true
    });

    const formData = new FormData();
    formData.append("file", companyLogo);

    if (clientId) {
      this.companyLogoUploadSubscription = DocumentService.uploadFile(
        CLIENTS_API.CLIENT_LOGO_UPDATE(clientId),
        formData
      ).subscribe(
        () => {
          notification.showSuccess(t("updateSuccess"));
          this.setState(
            {
              isCompanyLogoUploading: false
            },
            () => getUserProfile()
          );
        },
        (error: ErrorResponse<CIQError>) => {
          notification.showError(error.message);
          this.setState({
            isCompanyLogoUploading: false
          });
        }
      );
    }
  };

  handleDetailTabUpdate = ({
    companyName,
    externalName,
    mainContact
  }: DetailsTabFormValues): void => {
    const { t, updateUserProfileClientName } = this.props;
    const { client } = this.state;
    if (client) {
      const requestObj = this.getClientAccountRequestObject({
        ...client,
        name: companyName,
        externalName,
        ownerId: mainContact.id
      });

      this.setState(
        {
          isSaving: true
        },
        () => {
          this.updateClientDetailsSubscription = ClientService.updateClient(
            client.id,
            requestObj
          ).subscribe(
            () => {
              notification.showSuccess(t("updateSuccess"));
              this.setState(
                {
                  isSaving: false,
                  client: { ...client, ...requestObj, owner: mainContact }
                },
                () => updateUserProfileClientName(companyName)
              );
            },
            () => {
              notification.showError(t("updateError"));
              this.setState({
                isSaving: false
              });
            }
          );
        }
      );
    }
  };

  renderCompanyLogo = (): JSX.Element => {
    const { client, isCompanyLogoUploading } = this.state;
    return (
      <CustomAvatarSelect
        isLoading={isCompanyLogoUploading}
        onImageSelected={this.handleSelectedImage}
        profileImageUrl={client?.logoUrl}
      />
    );
  };

  renderCompanyNameAndSubdomain = (): JSX.Element => {
    const { client } = this.state;

    const subdomainHostName = new URL(client!.subdomain).hostname;

    return (
      <div style={{ textAlign: "center" }}>
        <h3>{client?.name}</h3>
        <p>{subdomainHostName}</p>
      </div>
    );
  };

  renderContent = (): JSX.Element => {
    const { activeTab, client, isSaving } = this.state;
    const { id, name, externalName, owner } = client!;
    const { DETAILS } = ClientAccountTab;

    return (
      <div className="settings-page-body">
        <div className="account-page-tab-content">
          <Media queries={APP_DEVICE_MEDIA_QUERIES}>
            {(matches): JSX.Element => (
              <SimpleBar
                className="simplebar-light"
                style={{
                  maxHeight: UI_SETTINGS_PAGE.CONTAINER_HEIGHT(matches),
                  height: UI_SETTINGS_PAGE.CONTAINER_HEIGHT(matches),
                  overflowX: "hidden"
                }}
              >
                <TabContent activeTab={activeTab}>
                  <TabPane tabId={DETAILS}>
                    <div className="top-container">
                      {this.renderCompanyLogo()}
                      {this.renderCompanyNameAndSubdomain()}
                    </div>
                    <DetailsTab
                      isSaving={isSaving}
                      companyName={name}
                      externalName={externalName}
                      mainContact={owner}
                      clientId={id}
                      updateClient={this.handleDetailTabUpdate}
                    />
                  </TabPane>
                </TabContent>
              </SimpleBar>
            )}
          </Media>
        </div>
      </div>
    );
  };

  render(): JSX.Element {
    const { isLoading } = this.state;
    const { t } = this.props;
    return (
      <div className="page-wrapper">
        <div className="settings-container page-content">
          <AntDHeader title={t("account")} />
          {isLoading ? <Loader isFull /> : this.renderContent()}
        </div>
      </div>
    );
  }
}
const mapStateToProps = createStructuredSelector<
  AppState,
  ClientSettingsPageProps,
  ClientSettingsPageStoreProps
>({
  clientId: AuthSelector.authClientIdSelector(),
  loggedInUser: AuthSelector.authUserSelector(),
  isCurrentUserClient: CacheSelector.isCurrentUserClientSelector()
});

const mapDispatchToProps = (dispatch: Dispatch): Record<string, AppAction> => ({
  getUserProfile: (): AppAction => dispatch(AppStoreActions.getUserProfile()),
  updateUserProfileClientName: (name: string): AppAction =>
    dispatch(AppStoreActions.updateUserProfileClientName(name))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("settingsPage")(ClientSettingsPage));
