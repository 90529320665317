import {
  FirstDataRenderedEvent,
  GetRowIdParams,
  InitialGroupOrderComparatorParams,
  RowClassParams,
  RowHeightParams
} from "ag-grid-community";

import {
  APPLICATION_STATUS,
  DO_NOT_CONTACT_STATUS,
  Referral
} from "@arbolus-technologies/models/common";

import { ReferralGridContext } from "../../Models/ReferralsTable";
import {
  ApplicationGroups,
  applicationValueGetter
} from "./columns/ApplicationGroupColumn";

const onFirstDataRendered = ({ api }: FirstDataRenderedEvent) => {
  api.sizeColumnsToFit();
  api.expandAll();
};

const ApplicationStatusMap: Record<ApplicationGroups, number> = {
  [APPLICATION_STATUS.REJECT]: 0,
  [APPLICATION_STATUS.PENDING]: 1,
  [APPLICATION_STATUS.SENT]: 2,
  [APPLICATION_STATUS.ACCEPT]: 3,
  approved: 4
};

const initialGroupOrderComparator = ({
  nodeA,
  nodeB
}: InitialGroupOrderComparatorParams<Referral>): number => {
  const a = nodeA.allLeafChildren[0].data
    ? applicationValueGetter({ data: nodeA.allLeafChildren[0].data })
    : APPLICATION_STATUS.REJECT;
  const b = nodeB.allLeafChildren[0].data
    ? applicationValueGetter({ data: nodeB.allLeafChildren[0].data })
    : APPLICATION_STATUS.REJECT;

  return ApplicationStatusMap[b] - ApplicationStatusMap[a];
};

export const selectionProps = {
  rowSelection: "multiple",
  groupSelectsChildren: true
} as const;

export const groupProps = {
  initialGroupOrderComparator,
  groupDisplayType: "groupRows",
  getRowHeight: (params: RowHeightParams) =>
    params.node.group ? 35 : undefined
} as const;

export const commonProps = {
  onFirstDataRendered,
  getRowId: ({ data }: GetRowIdParams) => data.id,
  suppressRowClickSelection: true,
  skipHeaderOnAutoSize: true,
  suppressMenuHide: true,
  animateRows: false,
  suppressAnimationFrame: true,
  suppressColumnMoveAnimation: true,
  getRowStyle: (params: RowClassParams<Referral, ReferralGridContext>) => {
    if (params.data?.expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC) {
      return { "background-color": "#ffeeeb" };
    }
    if (
      params.data?.expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.CAUTION
    ) {
      return { "background-color": "#f7810426" };
    }
    return undefined;
  }
} as const;
