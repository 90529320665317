import { UserModel } from "@arbolus-technologies/api";

export const ANGLE_TITLE = "title";
export const ANGLE_COLOR = "color";
export const OWNER = "owner";
export const EXPERT_DESCRIPTION = "expertDescription";
export const EXPERT_COUNT = "expertCount";
export const INCLUDE_SCREENING_QUESTION = "hasScreeningQuestions";
export const QUESTIONS = "questions";

export interface AnglesDetailsBase {
  [ANGLE_TITLE]: string;
  [ANGLE_COLOR]: string;
  [OWNER]: UserModel;
  [EXPERT_DESCRIPTION]: string;
  [EXPERT_COUNT]: number;
  [INCLUDE_SCREENING_QUESTION]?: boolean;
  [QUESTIONS]?: { question: string }[] | null;
}
