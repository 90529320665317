import i18next from "i18next";

import { BaseReviewStatus, ComplianceStatus } from "@arbolus-technologies/api";
import {
  DateString,
  REFERRAL_COMPLIANCE_STATE,
  REFERRAL_SUB_STATE
} from "@arbolus-technologies/models/common";
import { REFERRAL_STATES } from "@arbolus-technologies/models/expert";

import { Referral, ReferralDetail } from "../models/project";
import { User } from "../models/user";
import { ReferralReviewStats } from "../models/view/project";

export const ExpertReferralService = {
  getExpertReferralDetailState: (
    {
      application,
      compliance,
      review,
      expertId,
      fastTrack,
      status,
      id,
      projectId,
      referredUserId,
      complianceRequired,
      activeReferral,
      lastPublicCompanyExpDate
    }: ReferralDetail,
    expertUser: User,
    hasCompliance: boolean,
    savedOnNetwork: boolean
  ): ReferralReviewStats => {
    const baseApplication: BaseReviewStatus = application;
    const baseCompliance: ComplianceStatus = compliance;
    const baseReview: BaseReviewStatus = review;

    const uiReferral = ExpertReferralService.getExpertReferralState(
      {
        application: baseApplication,
        compliance: baseCompliance,
        review: baseReview,
        fastTrack,
        expertId,
        status,
        id,
        projectId,
        referredUserId,
        complianceRequired,
        activeReferral,
        savedExpert: savedOnNetwork
      } as Referral,
      expertUser,
      hasCompliance,
      lastPublicCompanyExpDate
    );

    return {
      ...uiReferral,
      complianceUser: compliance.updateUser,
      reviewUser: review.updateUser,
      applicationUser: application.updateUser
    };
  },
  getExpertReferralState: (
    referral: Referral,
    expertUser: User,
    hasCompliance: boolean,
    lastPublicCompanyExpDate: DateString | null
  ): ReferralReviewStats => {
    const {
      status,
      review: { subStatus: reviewState, updateDate: reviewUpdated },
      application: { subStatus: application, updateDate: applicationUpdated },
      compliance: { subStatus: compliance, updateDate: complianceUpdated },
      expertId,
      referredUserId,
      id,
      fastTrack: { fastTrack },
      modified,
      activeReferral,
      complianceRequired,
      savedExpert
    } = referral;

    const {
      PENDING: PENDING_STATE,
      REJECT: REJECT_STATE,
      ACCEPT: ACCEPT_STATE,
      UI_NOT_REQUIRED
    } = REFERRAL_SUB_STATE;
    const { ACCEPT, CANDIDATE, REJECT } = REFERRAL_STATES;

    const referralState: ReferralReviewStats = {
      isReview: status === CANDIDATE,
      reviewStatus: reviewState,
      reviewUpdated,
      isApplication: false,
      applicationStatus: application,
      applicationUpdated,

      isCompliance: hasCompliance,
      complianceStatus: compliance,
      complianceUpdated,

      expertUser,
      expertId,
      isReadyToWork: activeReferral,
      referralStatus: status,
      modified,
      referredUserId,
      referralId: id,
      summaryText: "",
      savedExpert,
      lastPublicCompanyExpDate
    };

    // Client has 'Accept' the candidate
    if (status === ACCEPT) {
      referralState.isApplication = true;

      if (application === PENDING_STATE) {
        referralState.summaryText = i18next.t(
          "expertReferralState:referralApprovedApplicationPending"
        );
      }

      if (application === ACCEPT_STATE) {
        if (hasCompliance) {
          if (compliance === REFERRAL_COMPLIANCE_STATE.PENDING) {
            referralState.summaryText = i18next.t(
              "expertReferralState:referralApprovedCompliancePending"
            );

            // Compliance type intervention
            if (complianceRequired === false) {
              referralState.complianceStatus = UI_NOT_REQUIRED;
              referralState.summaryText = i18next.t(
                "expertReferralState:referralApprovedActive"
              );
            }
          } else if (compliance === REFERRAL_COMPLIANCE_STATE.REJECT) {
            referralState.summaryText = i18next.t(
              "expertReferralState:referralComplianceFailed"
            );
          } else {
            referralState.summaryText = i18next.t(
              "expertReferralState:referralApprovedActive"
            );
          }
        } else {
          referralState.summaryText = i18next.t(
            "expertReferralState:referralApprovedActive"
          );
        }
      }

      // Expert has 'Reject' the application
      if (application === REJECT_STATE) {
        referralState.summaryText = i18next.t(
          "expertReferralState:referralDeclinedProject"
        );
        if (compliance === REFERRAL_COMPLIANCE_STATE.PENDING) {
          referralState.complianceStatus = UI_NOT_REQUIRED;
        }
      }
    }
    // Still in 'Pending' state
    else if (status === CANDIDATE) {
      referralState.isReview = !fastTrack;
      const applicationRejected = application === REJECT_STATE;

      referralState.isApplication = applicationRejected;
      referralState.isCompliance = applicationRejected;
      referralState.isReview = !applicationRejected;

      referralState.summaryText = i18next.t(
        "expertReferralState:referralPending"
      );

      // Expert has Fast Tracked
      if (applicationRejected) {
        referralState.complianceStatus = UI_NOT_REQUIRED;
        referralState.reviewStatus = UI_NOT_REQUIRED;

        referralState.summaryText = i18next.t(
          "expertReferralState:referralDeclinedProject"
        );
      }
    }
    // Client has rejected the candidate
    else if (status === REJECT) {
      referralState.isApplication = true;
      referralState.summaryText = i18next.t(
        "expertReferralState:referralRejected"
      );

      if (compliance === REFERRAL_COMPLIANCE_STATE.REJECT) {
        referralState.summaryText = i18next.t(
          "expertReferralState:referralComplianceFailed"
        );
      }

      if (application === PENDING_STATE) {
        referralState.applicationStatus = UI_NOT_REQUIRED;
        referralState.complianceStatus = UI_NOT_REQUIRED;
      }
      if (compliance === REFERRAL_COMPLIANCE_STATE.PENDING) {
        referralState.complianceStatus = UI_NOT_REQUIRED;
      }
    }

    return referralState;
  }
};
