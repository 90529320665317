import React, { useCallback, useEffect, useState } from "react";

import {
  CIQError,
  DefaultReferralsService,
  DefaultToasterService,
  ErrorResponse,
  ReferralsService,
  ToasterService
} from "@arbolus-technologies/api";
import { ReferralsStatsResponse } from "@arbolus-technologies/models/project";
import {
  PROJECT_BOOKED_ROUTE,
  PROJECT_CANDIDATES_ROUTE,
  PROJECT_SCHEDULING_ROUTE,
  PROJECT_SHORTLIST_ROUTE
} from "@arbolus-technologies/routes";
import {
  BookedButton,
  CandidateButton,
  LoaderOrComponent,
  SchedulingButton,
  ShortlistButton
} from "@arbolus-technologies/ui/components";
import { GrayBox } from "@arbolus-technologies/ui/layout";

import { useAngleQuery } from "../../Hooks/useAngleQuery";
import { getCurrentAngleId } from "../../helpers";

import styles from "./QuickActionsMenu.module.scss";

interface AdminReferralsActionMenuProps {
  projectId: string;
  referralsService?: ReferralsService;
  notificationService?: ToasterService;
}

export const AdminReferralsActionMenu: React.FC<
  AdminReferralsActionMenuProps
> = ({
  projectId,
  referralsService = DefaultReferralsService,
  notificationService = DefaultToasterService
}) => {
  const [referralStats, setReferralStats] = useState<ReferralsStatsResponse>();
  const [isLoading, setIsLoading] = useState(true);
  const angleId = useAngleQuery();

  const fetchStats = useCallback(
    (angleId?: string | null) => {
      setIsLoading(true);
      referralsService
        .getReferralsStats(projectId, angleId ?? undefined)
        .subscribe(
          (response) => {
            setReferralStats(response);
            setIsLoading(false);
          },
          (error: ErrorResponse<CIQError>) => {
            notificationService.showApiErrors(error);
            setIsLoading(false);
          }
        );
    },
    [referralsService, notificationService, projectId]
  );

  useEffect(() => {
    if (getCurrentAngleId() === angleId) {
      fetchStats(angleId);
    }
  }, [angleId, fetchStats]);

  return (
    <GrayBox fullWidth>
      <LoaderOrComponent isLoading={isLoading}>
        <div className={styles.container}>
          <ShortlistButton
            itemCount={referralStats?.shortList || 0}
            to={PROJECT_SHORTLIST_ROUTE(projectId)}
          />
          <CandidateButton
            itemCount={referralStats?.candidate || 0}
            to={PROJECT_CANDIDATES_ROUTE(projectId)}
          />
          <SchedulingButton
            itemCount={referralStats?.scheduling || 0}
            to={PROJECT_SCHEDULING_ROUTE(projectId)}
          />
          <BookedButton
            itemCount={referralStats?.booked || 0}
            to={PROJECT_BOOKED_ROUTE(projectId)}
          />
        </div>
      </LoaderOrComponent>
    </GrayBox>
  );
};
