import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "arbolus-ui-components";
import clsx from "clsx";
import dompurify from "dompurify";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "reactstrap";
import { forkJoin } from "rxjs";

import {
  CIQError,
  Canopy,
  CanopyService,
  DefaultToasterService,
  ErrorResponse,
  ToasterService,
  UploadingAttachment
} from "@arbolus-technologies/api";
import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import {
  CANOPY_STATUS,
  CanopyConstraints
} from "@arbolus-technologies/models/canopy";
import {
  CanopyBuilderSelector,
  CanopyBuilderStoreActions
} from "@arbolus-technologies/stores/canopy-builder";
import {
  ArbolusModalDanger,
  Drawer,
  InputController,
  Loader,
  WordCounter
} from "@arbolus-technologies/ui/components";

import { Attachments } from "../../Components/Attachments/Attachments";
import { getCanopyErrors, getUpdatedDocuments } from "../../helpers/utils";
import {
  CanopyDetailsFormInterface,
  CanopyDetailsSchema
} from "./CanopyDetailsSchema";

import styles from "./Details.module.scss";

interface DetailsProps {
  canopyDetails: Canopy;
  getCanopyDetails: () => void;
  showUnsavedChanges: boolean;
  canopyService?: typeof CanopyService;
  notificationService?: ToasterService;
}

export const Details: React.FC<DetailsProps> = ({
  canopyDetails,
  getCanopyDetails,
  showUnsavedChanges,
  notificationService = DefaultToasterService,
  canopyService = CanopyService
}) => {
  const { t } = useTranslation("canopyBuilder");
  const { trackClick } = useArbolusTracking();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadingFiles, setUploadingFiles] = useState<UploadingAttachment[]>(
    []
  );
  const [documentsToRemove, setDocumentsToRemove] = useState<string[]>([]);

  const apiErrors = useSelector(CanopyBuilderSelector.canopyErrorsSelector());
  const isCanopySaving = useSelector(
    CanopyBuilderSelector.canopySavingSelector()
  );

  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isDirty, dirtyFields }
  } = useForm<CanopyDetailsFormInterface>({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resolver: yupResolver(CanopyDetailsSchema) as any,
    mode: "onChange",
    defaultValues: {
      title: canopyDetails.title,
      overview: canopyDetails.overview ?? "",
      links: (canopyDetails.links ?? []).map((link) => ({ url: link })),
      documents: canopyDetails.documents ?? [],
      brief: canopyDetails.brief ?? ""
    }
  });

  const {
    fields: urls,
    append: addLink,
    remove: removeLink
  } = useFieldArray({
    control,
    name: "links",
    keyName: "url"
  });

  const {
    fields: files,
    append: addFile,
    remove: removeFile
  } = useFieldArray({
    control,
    name: "documents",
    keyName: "fileKey"
  });

  useEffect(() => {
    canopyDetails.documents
      ?.filter((doc) => !files.find((file) => file.id === doc.id))
      .forEach((doc) => addFile(doc));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canopyDetails]);

  const handleSetDocumentsToRemove = (id: string) =>
    setDocumentsToRemove((prevState) => [...prevState, id]);

  const onSubmit: SubmitHandler<CanopyDetailsFormInterface> = (data) => {
    const { title, overview, links } = data;

    const canopyEditRequest = {
      canopyId: canopyDetails.id,
      title: title.trim(),
      overview: overview.trim(),
      links: links.flatMap((link) => link.url),
      hasComplianceCheck: canopyDetails.hasComplianceCheck,
      defaultOwnerId: canopyDetails.defaultOwner?.id
    };

    const { addedDocs, removedDocs } = getUpdatedDocuments(
      uploadingFiles,
      documentsToRemove
    );

    const requests = [
      canopyService.editCanopy(canopyEditRequest),
      ...addedDocs.map((document: FormData) =>
        canopyService.uploadDocumentToCanopy(canopyDetails.id, document)
      ),
      ...removedDocs.map((documentId: string) =>
        canopyService.removeDocumentFromCanopy({ documentId })
      )
    ];

    setIsLoading(true);
    return forkJoin(requests).subscribe(
      () => {
        setUploadingFiles([]);
        setDocumentsToRemove([]);
        setIsLoading(false);
        reset({
          title: canopyEditRequest.title,
          overview: canopyEditRequest.overview,
          links: links,
          documents: []
        });
        getCanopyDetails();
        notificationService.showSuccess(t("canopyUpdatedSuccessfully"));
      },
      (error: ErrorResponse<CIQError>) => {
        setIsLoading(false);
        notificationService.showApiErrors(error);
      }
    );
  };

  const handleDeleteCanopy = () => {
    dispatch(
      CanopyBuilderStoreActions.deleteCanopy(
        { canopyId: canopyDetails.id },
        canopyDetails.projectId
      )
    );
  };

  const shouldBeDisabled = () => {
    if (watch("overview") !== "" && watch("title") !== "") return false;
    if (canopyDetails.links?.length === 0 && !isDirty) return true;
    return true;
  };

  return (
    <div className={styles.container}>
      <Drawer title={t("canopyBrief")} openByDefault>
        <div className={styles.details}>
          <div className={styles.inputContainer}>
            <Label className={clsx(styles.label, "required")}>
              {t("title")}
            </Label>
            <InputController
              name="title"
              error={errors.title}
              classnames={clsx(styles.input, {
                [styles.unsavedField]:
                  showUnsavedChanges && dirtyFields["title"]
              })}
              validatorErrors={getCanopyErrors(apiErrors)}
              control={control}
              placeholder={t("title")}
              type="text"
              onTrackInput={() =>
                trackClick(MixPanelEventNames.CanopyBuilderDetailsBriefTitle)
              }
            />
          </div>
          <div className={styles.inputContainer}>
            <Label className={styles.label}>
              <span className="required">{t("brief")}</span>
              <span className={styles.message}>{t("visibleToExperts")}</span>
            </Label>
            <InputController
              name="overview"
              error={errors.overview}
              classnames={clsx(styles.input, {
                [styles.unsavedField]:
                  showUnsavedChanges && dirtyFields["overview"]
              })}
              control={control}
              placeholder={t("brief")}
              type="textarea"
              size="big"
              onTrackInput={() =>
                trackClick(
                  MixPanelEventNames.CanopyBuilderDetailsBriefDescription
                )
              }
            />
            <WordCounter
              maxLength={CanopyConstraints.MAX_CANOPY_OVERVIEW_LENGTH}
              textLength={watch("overview").length}
            />
          </div>
          {canopyDetails.isAutomaticallyCreated && (
            <div className={styles.inputContainer}>
              <Label className={styles.label}>
                <span>{t("aiPrompt")}</span>
                <span className={styles.message}>{t("aiMessage")}</span>
              </Label>
              <InputController
                name="brief"
                error={errors.brief}
                disabled
                classnames={clsx(styles.input, {
                  [styles.unsavedField]:
                    showUnsavedChanges && dirtyFields["brief"]
                })}
                control={control}
                placeholder={t("brief")}
                type="textarea"
                size="big"
              />
            </div>
          )}
        </div>
      </Drawer>
      <Drawer title={t("attachments")}>
        <Attachments
          control={control}
          errors={errors}
          urls={urls}
          addLink={addLink}
          removeLink={removeLink}
          files={files}
          addFile={addFile}
          removeFile={removeFile}
          uploadingFiles={uploadingFiles}
          handleUploadingFiles={setUploadingFiles}
          handleRemoveFiles={handleSetDocumentsToRemove}
        />
      </Drawer>
      <Drawer title={t("troubleshooting")}>
        <div
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(t("troubleshootingMessage"))
          }}
        />
      </Drawer>
      <div className={styles.buttonActionsContainer}>
        <ArbolusModalDanger
          toggle={() => setIsModalOpen(false)}
          confirmationText={canopyDetails.title}
          isOpen={isModalOpen}
          rightButton={{
            onClick: handleDeleteCanopy,
            text: t("delete"),
            type: "rejection"
          }}
          leftButton={{
            onClick: () => setIsModalOpen(false),
            text: t("cancel"),
            type: "tertiary"
          }}
        />
        {canopyDetails.status === CANOPY_STATUS.DRAFT && (
          <Button
            text={t("delete")}
            type="tertiary"
            onClick={() => setIsModalOpen(true)}
          />
        )}
        <Button
          text={t("update")}
          disabled={
            shouldBeDisabled() ||
            Object.keys(errors).length > 0 ||
            isCanopySaving
          }
          onClick={handleSubmit(onSubmit)}
        />
      </div>
      {isLoading && <Loader isFull />}
    </div>
  );
};
