import clsx from "clsx";
import React from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import { ANSWER_TYPE } from "@arbolus-technologies/models/canopy";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { IconWithText } from "@arbolus-technologies/ui/components";

import answered from "../../../assets/answered.svg";

import styles from "./Question.module.scss";

interface QuestionProps {
  title: string;
  type: ANSWER_TYPE;
  order: number;
  isAnswered?: boolean;
  onClick: () => void;
  disableQuestion?: boolean;
  isClient?: boolean;
  isSelected?: boolean;
}

export const Question: React.FC<QuestionProps> = ({
  order,
  title,
  type,
  onClick,
  disableQuestion,
  isAnswered,
  isClient,
  isSelected
}) => {
  const { t } = useTranslation("canopyV2");

  const icon = {
    [ANSWER_TYPE.VIDEO]: { text: t("video"), icon: "videocam" },
    [ANSWER_TYPE.MULTIPLE_CHOICE]: { text: t("multiChoice"), icon: "sort" },
    [ANSWER_TYPE.VALUE]: { text: t("value"), icon: "numbers" },
    [ANSWER_TYPE.SHORT_TEXT]: { text: t("shortText"), icon: "short_text" },
    [ANSWER_TYPE.NPS]: { text: t("nps"), icon: "analytics" }
  };

  return (
    <div
      className={clsx(styles.container, {
        [styles.isDisabled]: disableQuestion,
        [styles.disableClick]: isSelected && !isMobile
      })}
      onClick={onClick}
      onKeyDown={onClick}
    >
      {!isClient && isAnswered && (
        <img className={styles.answeredTick} src={answered} alt="Answered" />
      )}
      <div className={styles.numberContainer}>
        <div
          className={clsx(styles.number, {
            [styles.active]: isSelected && !isMobile
          })}
        >
          {order}
        </div>
      </div>
      <div className={styles.questionContainer}>
        <h1
          className={clsx(styles.questionTitle, {
            [styles.selected]: isSelected
          })}
        >
          {title}
        </h1>
        <IconWithText
          icon={{
            name: icon[type].icon,
            color: ARBOLUS_COLORS.bColorSecondaryGreyMid,
            filled: false,
            size: "24px"
          }}
          text={{
            content: icon[type].text,
            color: ARBOLUS_COLORS.bColorSecondaryGreyMid
          }}
        />
      </div>
    </div>
  );
};
