import { Observable } from "rxjs";

import { BaseRestService } from "../BaseRestService";
import { TipaltiIframes } from "../models/expertPayments";
import { restService } from "../restService";

export class ExpertPaymentsService {
  // cspell:disable-next-line
  private static readonly Endpoint = "/expertpayments";

  private readonly _restService: BaseRestService;

  constructor(restService: BaseRestService) {
    this._restService = restService;
  }

  public getIframes(expertId?: string): Observable<TipaltiIframes> {
    return this._restService.get(`${ExpertPaymentsService.Endpoint}/iframes`, {
      expertId
    });
  }
}

export const DefaultExpertPaymentsService = new ExpertPaymentsService(
  restService
);
