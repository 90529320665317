import { MbscCalendarColor } from "@mobiscroll/react";
import moment from "moment";

import {
  CALENDAR_DATE_TIME_FORMAT,
  DATE_TIME_FORMAT,
  TimeSpan,
  TimezoneService
} from "@arbolus-technologies/utils";

import { noWorkingDaysColor } from "./constants";

enum WeekDays {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7
}

interface HighlightDayTimes {
  startHighlightDateToday: Date;
  endHighlightDateToday: Date;
  startHighlightDateNextDay: Date;
  endHighlightDateNextDay: Date;
}

// returns upcoming week time for highlighting when the upcoming days are weekends
export const getClientNextWeekTime = (
  day: WeekDays,
  expertTimezone: string,
  projectTimezone: string
): TimeSpan<Date> => {
  const { start, end } = TimezoneService.convertDatesTimezone(
    `${moment()
      .add(1, "weeks")
      .tz(projectTimezone)
      .isoWeekday(day)
      .format(DATE_TIME_FORMAT)} 07:00`,
    `${moment()
      .add(1, "weeks")
      .tz(projectTimezone)
      .isoWeekday(day)
      .format(DATE_TIME_FORMAT)} 22:00`,
    expertTimezone,
    projectTimezone
  );

  return {
    start: start.toDate(),
    end: end.toDate()
  };
};

// returns upcoming day time for highlighting
export const getClientNextDayTime = (
  monthDay: number,
  expertTimezone: string,
  projectTimezone: string
): TimeSpan<Date> => {
  const { start, end } = TimezoneService.convertDatesTimezone(
    `${moment()
      .add(monthDay, "day")
      .tz(projectTimezone)
      .format(DATE_TIME_FORMAT)} 07:00`,
    `${moment()
      .add(monthDay, "day")
      .tz(projectTimezone)
      .format(DATE_TIME_FORMAT)} 22:00`,
    expertTimezone,
    projectTimezone
  );

  return {
    start: start.toDate(),
    end: end.toDate()
  };
};

export const getClientTimeIfTodayActiveHoursExpired = (
  expertTimezone: string,
  projectTimezone: string,
  upcomingActiveDay: number
): HighlightDayTimes => {
  const firstActiveDay = getClientNextDayTime(
    1,
    expertTimezone,
    projectTimezone
  );

  let secondActiveDay: TimeSpan<Date>;
  // checks if upcomingActiveDay is Friday
  if (upcomingActiveDay === 5) {
    secondActiveDay = getClientNextWeekTime(
      WeekDays.Monday,
      expertTimezone,
      projectTimezone
    );
  } else {
    secondActiveDay = getClientNextDayTime(2, expertTimezone, projectTimezone);
  }

  return {
    startHighlightDateToday: firstActiveDay.start,
    endHighlightDateToday: firstActiveDay.end,
    startHighlightDateNextDay: secondActiveDay.start,
    endHighlightDateNextDay: secondActiveDay.end
  };
};

export const getClientTimeIfTodayHasActiveHours = (
  expertTimezone: string,
  projectTimezone: string,
  upcomingActiveDay: number,
  currentHour: number
): HighlightDayTimes => {
  const start = moment().add(1, "hour");
  const remainder = 30 - (start.minute() % 30);
  const startTime = start
    .add(remainder, "minutes")
    .tz(projectTimezone)
    .format(CALENDAR_DATE_TIME_FORMAT);

  const firstActiveDay = TimezoneService.convertDatesTimezone(
    currentHour < 6
      ? `${moment().tz(projectTimezone).format(DATE_TIME_FORMAT)} 07:00`
      : startTime,
    `${moment().tz(projectTimezone).format(DATE_TIME_FORMAT)} 22:00`,
    expertTimezone,
    projectTimezone
  );

  let secondActiveDay: TimeSpan<Date>;
  // checks if upcomingActiveDay is Friday
  if (upcomingActiveDay === 5) {
    secondActiveDay = getClientNextWeekTime(
      WeekDays.Monday,
      expertTimezone,
      projectTimezone
    );
  } else {
    secondActiveDay = getClientNextDayTime(1, expertTimezone, projectTimezone);
  }

  return {
    startHighlightDateToday: firstActiveDay.start.toDate(),
    endHighlightDateToday: firstActiveDay.end.toDate(),
    startHighlightDateNextDay: secondActiveDay.start,
    endHighlightDateNextDay: secondActiveDay.end
  };
};

export const getHighlightedClientDays = (
  expertTimezone: string,
  projectTimezone: string
): HighlightDayTimes => {
  const currentDay = moment().tz(projectTimezone).isoWeekday();

  const currentTime = moment().tz(projectTimezone).format("HH:mm").split(":");

  const currentHour = parseInt(currentTime[0]);
  const currentMinute = parseInt(currentTime[1]);

  const moveToNextDay =
    (currentHour === 20 && currentMinute > 30) || currentHour >= 21;

  // moves current day by 1 when the active hours for today has passed
  const today = moveToNextDay ? currentDay + 1 : currentDay;
  // Adding a 1 with Sunday(7) has to be Monday(1), so this calculation has been done
  const upcomingActiveDay = today > 7 ? today - 7 : today;

  // checks if today is weekend
  if (upcomingActiveDay === 6 || upcomingActiveDay === 7) {
    const mondayTimes = getClientNextWeekTime(
      WeekDays.Monday,
      expertTimezone,
      projectTimezone
    );

    const tuesdayTimes = getClientNextWeekTime(
      WeekDays.Tuesday,
      expertTimezone,
      projectTimezone
    );

    return {
      startHighlightDateToday: mondayTimes.start,
      endHighlightDateToday: mondayTimes.end,
      startHighlightDateNextDay: tuesdayTimes.start,
      endHighlightDateNextDay: tuesdayTimes.end
    };
  }

  if (moveToNextDay) {
    return getClientTimeIfTodayActiveHoursExpired(
      expertTimezone,
      projectTimezone,
      upcomingActiveDay
    );
  }

  return getClientTimeIfTodayHasActiveHours(
    expertTimezone,
    projectTimezone,
    upcomingActiveDay,
    currentHour
  );
};

export const getClientInactiveTimes = (
  expertTimezone: string,
  projectTimezone: string
): MbscCalendarColor[] => {
  const { start, end } = TimezoneService.convertDatesTimezone(
    `${moment().format(DATE_TIME_FORMAT)} 22:00`,
    `${moment().format(DATE_TIME_FORMAT)} 07:00`,
    expertTimezone,
    projectTimezone
  );

  const startTime = start.format("HH:mm");
  const endTime = end.format("HH:mm");

  // inactive client's weekdays time
  if (endTime > startTime) {
    return [
      {
        start: startTime,
        end: endTime,
        recurring: {
          repeat: "weekly",
          weekDays: "MO,TU,WE,TH,FR"
        },
        background: noWorkingDaysColor
      }
    ];
  }

  return [
    {
      start: startTime,
      end: "24:00",
      recurring: {
        repeat: "weekly",
        weekDays: "MO,TU,WE,TH,FR"
      },
      background: noWorkingDaysColor
    },
    {
      start: "00:00",
      end: endTime,
      recurring: {
        repeat: "weekly",
        weekDays: "MO,TU,WE,TH,FR"
      },
      background: noWorkingDaysColor
    }
  ];
};
