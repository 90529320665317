import { Region, TimeZone } from "@arbolus-technologies/api";
import {
  ArbolusFeatureFlag,
  ArbolusFeatureFlags
} from "@arbolus-technologies/models/common";

import { REDIRECT_STATES } from "../../../../constants/app";
import { INBOX_NOTIFICATION_CATEGORY } from "../../../../constants/notifications";
import { CIQError, ErrorResponse } from "../../../../models/api";
import {
  Country,
  Currency,
  Industry,
  UserPermissions
} from "../../../../models/meta";
import { LoggedInUser } from "../../../../models/user";

export const GET_USER_PROFILE = "APP_GET_USER_PROFILE";
export const GET_USER_PROFILE_SUCCESS = "APP_GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAILURE = "APP_GET_USER_PROFILE_FAILURE";

export const GET_APP_DATA = "APP_GET_APP_DATA";
export const GET_APP_DATA_SUCCESS = "APP_GET_APP_DATA_SUCCESS";
export const GET_APP_DATA_FAILURE = "APP_GET_APP_DATA_FAILURE";

export const UPDATE_USER_PROFILE = "APP_UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_CLIENT_NAME =
  "APP_UPDATE_USER_PROFILE_CLIENT_NAME";

export const SWITCH_SUBDOMAIN = "APP_SWITCH_SUBDOMAIN";

export const GET_FEATURE_FLAGS = "APP_GET_FEATURE_FLAGS";
export const GET_FEATURE_FLAGS_SUCCESS = "APP_GET_FEATURE_FLAGS_SUCCESS";
export const GET_FEATURE_FLAGS_FAILURE = "APP_GET_FEATURE_FLAGS_FAILURE";
export const OVERRIDE_FEATURE_FLAG = "APP_OVERRIDE_FEATURE_FLAG";
export const RESET_OVERRIDE_FEATURE_FLAG = "APP_RESET_OVERRIDE_FEATURE_FLAG";
export const INITIALIZE_FLAGS_OVERRIDE = "APP_INITIALIZE_FLAGS_OVERRIDE";

export interface SwitchSubdomainAction {
  type: typeof SWITCH_SUBDOMAIN;
  payload: {
    redirectType: REDIRECT_STATES;
  };
}

export interface GetUserProfileAction {
  type: typeof GET_USER_PROFILE;
  payload: {
    initial: boolean;
  };
}

export interface GetUserProfileSuccessAction {
  type: typeof GET_USER_PROFILE_SUCCESS;
  payload: {
    user: LoggedInUser;
  };
}

export interface GetUserProfileFailureAction {
  type: typeof GET_USER_PROFILE_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

export interface GetAppDataAction {
  type: typeof GET_APP_DATA;
}

export interface GetAppDataSuccessAction {
  type: typeof GET_APP_DATA_SUCCESS;
  payload: {
    countries: Country[];
    industriesLevel0: Industry[];
    industriesLevel2: Industry[];
    timezones: TimeZone[];
    regions: Region[];
    currencies: Currency[];
    notificationCounts: Map<INBOX_NOTIFICATION_CATEGORY, number>;
  };
}

export interface GetAppDataFailureAction {
  type: typeof GET_APP_DATA_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

export interface UpdateUserProfileAction {
  type: typeof UPDATE_USER_PROFILE;
  payload: {
    user: LoggedInUser;
  };
}

export interface UpdateUserProfileClientNameAction {
  type: typeof UPDATE_USER_PROFILE_CLIENT_NAME;
  payload: {
    name: string;
  };
}

export interface GetFeatureFlagsAction {
  type: typeof GET_FEATURE_FLAGS;
}

export interface GetFeatureFlagsSuccessAction {
  type: typeof GET_FEATURE_FLAGS_SUCCESS;
  payload: {
    userPermissions: UserPermissions;
  };
}

export interface GetFeatureFlagsFailureAction {
  type: typeof GET_FEATURE_FLAGS_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

export interface OverrideFeatureFlagAction {
  type: typeof OVERRIDE_FEATURE_FLAG;
  payload: {
    name: ArbolusFeatureFlag;
    value: boolean;
  };
}

export interface ResetOverrideFeatureFlagAction {
  type: typeof RESET_OVERRIDE_FEATURE_FLAG;
}

export interface InitializeFlagsOverrideAction {
  type: typeof INITIALIZE_FLAGS_OVERRIDE;
  payload: {
    flags: ArbolusFeatureFlags;
  };
}
