import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Button, Col } from "reactstrap";

import { useIsChatAvailable } from "@arbolus-technologies/features/common";
import { REFERRAL_SUB_STATE } from "@arbolus-technologies/models/common";
import {
  PROJECT_APPLICATION_ROUTE,
  PROJECT_CHAT_BASE_ROUTE
} from "@arbolus-technologies/routes";

import {
  PROJECT_CALENDAR_ROUTE,
  PROJECT_FILES_ROUTE,
  PROJECT_ROUTE
} from "../../../../constants/navigation/projectRoutes";
import { BaseProject } from "../../../../models/project";

interface ProjectExpertListItemProps {
  project: BaseProject;
}

const ProjectExpertListItem: React.FC<ProjectExpertListItemProps> = ({
  project
}) => {
  const history = useHistory();
  const { t } = useTranslation("projectsPage");
  const isChatAvailable = useIsChatAvailable();

  const {
    id,
    activeReferral,
    name,
    client,
    referralApplicationStatus,
    hasUnreadMessages
  } = project;
  const isNotJoinedYet = activeReferral === false;

  const { PENDING, ACCEPT } = REFERRAL_SUB_STATE;
  const isNewApplication = referralApplicationStatus === PENDING;
  const isApplied = referralApplicationStatus === ACCEPT;

  const handleViewApplication = (): void => {
    history.push(PROJECT_APPLICATION_ROUTE(id));
  };

  const getProjectName = (): string => {
    if (isNotJoinedYet) {
      if (isApplied) {
        return `${t("newApplication")} - ${t("awaitingApproval")}`;
      }
      if (isNewApplication) {
        return t("newApplication");
      }
    }

    return name;
  };

  const renderProjectActions = (): JSX.Element => {
    if (isNotJoinedYet) {
      if (isNewApplication) {
        return (
          <Button
            color="secondary"
            size="lg"
            onClick={(event: React.MouseEvent): void => {
              event.stopPropagation();
              handleViewApplication();
            }}
            className="d-none d-lg-flex project-details btn-bold"
          >
            {t("getStarted")}
          </Button>
        );
      } else {
        return (
          <Button
            color="primary"
            size="lg"
            onClick={(event: React.MouseEvent): void => {
              event.stopPropagation();
              handleViewApplication();
            }}
            className="d-none d-lg-flex project-details btn-bold"
          >
            {t("details")}
          </Button>
        );
      }
    }

    return (
      <>
        <Link
          className={clsx("ciq-icon ciq-card-view d-none d-md-block")}
          to={PROJECT_ROUTE(id)}
        />
        {isChatAvailable && (
          <Link
            className={clsx("ciq-icon ciq-comment d-none d-md-block", {
              "notification-active": hasUnreadMessages
            })}
            to={PROJECT_CHAT_BASE_ROUTE(id)}
          >
            {hasUnreadMessages && <span className="notification-dot" />}
          </Link>
        )}
        <Link
          className="ciq-icon ciq-calender d-none d-md-block"
          to={PROJECT_CALENDAR_ROUTE(id)}
        />
        <Link
          className="ciq-icon ciq-file d-none d-md-block"
          to={PROJECT_FILES_ROUTE(id)}
        />
      </>
    );
  };

  return (
    <Col sm="12" key={id}>
      <div
        className={clsx("new-project-card-container", {
          "new-project-applied": isApplied && isNotJoinedYet,
          "new-project-not-applied": !isApplied
        })}
      >
        <Link
          to={
            isNotJoinedYet ? PROJECT_APPLICATION_ROUTE(id) : PROJECT_ROUTE(id)
          }
          className="left-container"
        >
          <div className="np-title">{getProjectName()}</div>
          <div className="np-subtitle">
            <div>
              <div className="project-icon">
                <span className="ciq-icon ciq-archive" />
              </div>
            </div>
            <span className="d-block">{client?.name ?? project.name}</span>
          </div>
        </Link>
        <div className="right-container">{renderProjectActions()}</div>
      </div>
    </Col>
  );
};

export default ProjectExpertListItem;
