import React, { SyntheticEvent } from "react";
import DatePicker from "react-datepicker";
import { useMedia } from "react-media";

import { PROJECT_EVENT_TIME_FORMAT } from "@arbolus-technologies/utils";

import { UIConstants } from "../../../constants";

interface CustomTimePickerProps {
  onBlur?: (e: SyntheticEvent<HTMLInputElement>) => void;
  dateFormat?: string;
  popperPlacement?: string;
  selectedTime?: Date;
  onTimeChanged: (date: Date) => void;
  minTime?: Date;
  maxTime?: Date;
  timeIntervals?: number;
}

const CustomTimePicker: React.FC<CustomTimePickerProps> = ({
  onBlur,
  dateFormat = PROJECT_EVENT_TIME_FORMAT,
  popperPlacement,
  selectedTime,
  onTimeChanged,
  minTime,
  maxTime,
  timeIntervals
}) => {
  const matches = useMedia({ queries: UIConstants.APP_DEVICE_MEDIA_QUERIES });

  const handleDateChangeRaw = (e: React.FocusEvent<HTMLInputElement>): void => {
    if (matches.large) {
      e.target.readOnly = false;
    } else {
      e.preventDefault();
      e.target.readOnly = true;
    }
  };
  return (
    <DatePicker
      onFocus={handleDateChangeRaw}
      autoComplete="off"
      showTimeSelect
      showTimeSelectOnly
      adjustDateOnChange
      onBlur={onBlur}
      timeIntervals={timeIntervals}
      minTime={minTime}
      maxTime={maxTime}
      dateFormat={dateFormat}
      selected={selectedTime}
      onChange={onTimeChanged}
      popperClassName="timepicker-select"
      popperPlacement={popperPlacement || "bottom-start"}
      popperModifiers={{
        preventOverflow: {
          enabled: true,
          boundariesElement: "viewport"
        }
      }}
    />
  );
};

export default CustomTimePicker;
