import { ErrorInfo } from "react";

export type Result<T = boolean> =
  | { ok: true; value: T }
  | { ok: false; error: string };

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export type PublicInterface<T> = { [K in keyof T]: T[K] };

export type ClassProperties<C> = {
  [K in keyof C as C[K] extends Function ? never : K]: C[K];
};

type ExtractMethodNames<T> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [K in keyof T]: T[K] extends (...args: any[]) => any ? K : never;
}[keyof T];

export type ExtractMethods<T> = Pick<T, ExtractMethodNames<T>>;

export interface DisplayUser {
  firstName?: string;
  lastName?: string;
  email: string;
}

export enum ERROR_TYPE {
  APP_CRASH = "APP_CRASH",
  FIREBASE_DELETE_TOKEN_FAILURE = "FIREBASE_DELETE_TOKEN_FAILURE",
  FIREBASE_GET_TOKEN_FAILURE = "FIREBASE_GET_TOKEN_FAILURE"
}

export interface CIQCrashAnalytics {
  crashId: string;
  errorMessage: string;
  errorType: ERROR_TYPE;
  errorStack?: string;
  errorInfo?: ErrorInfo;
  browserName: string;
  fullBrowserVersion: string;
  deviceType: string;
  engineName: string;
  engineVersion: string;
  osName: string;
  osVersion: string;
  mobileModel: string;
  mobileVendor: string;
  windowLocation: string;
  isAdmin: boolean;
  userId?: string;
}
