import { PaginationBar } from "arbolus-ui-components";
import saveAs from "file-saver";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ExportProjectPipelinesRequest,
  FileResponse,
  MAX_PAGE_SIZE_25,
  ProjectService,
  ToasterService
} from "@arbolus-technologies/api";
import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import {
  CONTACT_TYPE_ENUM,
  EXPERT_TYPE,
  PipelineExpertUser
} from "@arbolus-technologies/models/expert";
import { EXPERT_PROFILE_ROUTE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import {
  CheckboxWrapper,
  LoaderOrComponent,
  NoResultsPlaceholder
} from "@arbolus-technologies/ui/components";
import {
  PipelineExpertCard,
  PipelineResultsAndActions
} from "@arbolus-technologies/ui/pipeline";
import { string64toBlob } from "@arbolus-technologies/utils";

import { useExternalSourcing } from "../../Contexts/ExternalSourcingContext/ExternalSourcingContext";
import { ExternalExpertSlidePanel } from "../ExternalExpertSlidePanel/ExternalExpertSlidePanel";
import { UpdatePipelineStatusModal } from "./UpdatePipelineStatusModal/UpdatePipelineStatusModal";

import styles from "./ExternalExpertList.module.scss";

interface ExternalExpertListProps {
  projectService?: typeof ProjectService;
  notificationService?: ToasterService;
}

export const ExternalExpertList: React.FC<ExternalExpertListProps> = ({
  projectService = ProjectService,
  notificationService = DefaultToasterService
}) => {
  const { projectId } = useParams<{ projectId: string }>();
  const { t } = useTranslation("pipelineExpertList");
  const dispatch = useDispatch();
  const {
    searchTerm,
    selectedAngle,
    excludeAlreadyAdded,
    contactType,
    contactMethod,
    selectedExpertsIds,
    currentPage,
    isLoading,
    expertListPaginated,
    setSelectedExpertsIds,
    handleSelectExpert,
    fetchExpertList,
    fetchStats
  } = useExternalSourcing();

  const [isExportDisabled, setIsExportDisabled] = useState<boolean>(false);
  const [isStatusUpdateModalOpen, setIsStatusUpdateModalOpen] =
    useState<boolean>(false);

  const metadataCountries = useSelector(CacheSelector.metadataCountries());

  const totalExpertsFound = expertListPaginated?.pagination?.count ?? 0;
  const blobTypeExtension =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const selectedAngleIdOrNull =
    selectedAngle?.id === "allAngles" ? null : selectedAngle?.id;

  useEffect(() => {
    fetchExpertList(0);
  }, [
    fetchExpertList,
    searchTerm,
    selectedAngle,
    excludeAlreadyAdded,
    contactType,
    contactMethod
  ]);

  const handleBulkStatusUpdated = () => {
    fetchExpertList(currentPage - 1);
    fetchStats();
  };

  const openExpertProfile = (expertId: string) => {
    window.open(EXPERT_PROFILE_ROUTE(expertId), "_blank");
  };

  const openExternalExpertSlidePanel = (projectPipelineId: string) => {
    dispatch(
      PanelStoreActions.openPanel(PanelId.ExternalExpert, {
        projectPipelineId: projectPipelineId
      })
    );
  };

  const isExpertExportable = (expert: PipelineExpertUser) => {
    const { projectPipelineStatus, isAlreadyAdded, platformStatus } = expert;

    return (
      projectPipelineStatus !== CONTACT_TYPE_ENUM.REJECTED_DECLINED &&
      !isAlreadyAdded &&
      platformStatus === EXPERT_TYPE.EXTERNAL
    );
  };

  const handleSelectAllExperts = (selectAll: boolean) => {
    if (!expertListPaginated) {
      return;
    }

    const selectedIds = selectAll
      ? expertListPaginated.items
          .filter((expert) => isExpertExportable(expert))
          .map((expert) => expert.id)
      : [];
    setSelectedExpertsIds(selectedIds);
  };

  const handleExportProjectPipelines = (exportAll = false) => {
    setIsExportDisabled(true);
    const exportProjectPipelinesRequest: ExportProjectPipelinesRequest = {
      projectId,
      angleId: selectedAngleIdOrNull,
      searchTerm,
      projectPipelineStatus: contactType,
      projectPipelineContactMethod: contactMethod,
      projectPipelineIds: selectedExpertsIds,
      exportAllContacts: exportAll
    };

    projectService
      .exportProjectPipelines(exportProjectPipelinesRequest)
      .subscribe(
        (response: FileResponse) => {
          const blob = string64toBlob(response.file, blobTypeExtension);
          saveAs(blob, response.name);
          setIsExportDisabled(false);
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showError(error.message);
          setIsExportDisabled(false);
        }
      );
  };

  const expertListPaginatedItemsEmpty =
    expertListPaginated?.items?.length === 0;

  const selectedExperts =
    expertListPaginated?.items.filter((expert) =>
      selectedExpertsIds.includes(expert.id)
    ) || [];
  const isAllExpertsHasSameStatus =
    selectedExperts.length > 0 &&
    selectedExperts.every(
      (expert) =>
        expert.projectPipelineStatus ===
        selectedExperts[0].projectPipelineStatus
    );

  return (
    <LoaderOrComponent isLoading={isLoading} isFull>
      <div className={styles.expertsColumn}>
        <PipelineResultsAndActions
          totalSelectedExperts={selectedExpertsIds.length}
          totalExperts={expertListPaginated?.items.length ?? 0}
          isExportDisabled={isExportDisabled}
          isChangeStatusDisabled={!isAllExpertsHasSameStatus}
          handleSelectAllExperts={handleSelectAllExperts}
          onExport={handleExportProjectPipelines}
          openStatusChangeModal={() => setIsStatusUpdateModalOpen(true)}
        />
        <div className={styles.message}>{t("message")}</div>

        <div className={styles.expertList}>
          {expertListPaginatedItemsEmpty ? (
            <NoResultsPlaceholder
              noResultsTitle={t("oops")}
              noResultsText={t("empty")}
            />
          ) : (
            expertListPaginated?.items?.map((expert) => (
              <CheckboxWrapper
                key={expert.id}
                isChecked={selectedExpertsIds.includes(expert.id)}
                onChange={() => handleSelectExpert(expert.id)}
                isDisabled={!isExpertExportable(expert)}
                hidden={expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC}
              >
                <PipelineExpertCard
                  expert={expert}
                  metadataCountries={metadataCountries}
                  openExpertProfile={() => openExpertProfile(expert.expertId)}
                  openExternalExpertSlidePanel={() =>
                    openExternalExpertSlidePanel(expert.projectPipelineId)
                  }
                />
              </CheckboxWrapper>
            ))
          )}
        </div>
        <div className={styles.paginationContainer}>
          <PaginationBar
            currentPage={currentPage}
            totalCount={totalExpertsFound}
            onSetPage={(nextPage) => fetchExpertList(nextPage - 1)}
            itemsPerPage={MAX_PAGE_SIZE_25}
          />
        </div>
      </div>
      <ExternalExpertSlidePanel />
      {selectedExperts.length > 0 && isStatusUpdateModalOpen && (
        <UpdatePipelineStatusModal
          currentStatus={selectedExperts[0].projectPipelineStatus}
          close={() => setIsStatusUpdateModalOpen(false)}
          onBulkStatusUpdated={handleBulkStatusUpdated}
        />
      )}
    </LoaderOrComponent>
  );
};
