import clsx from "clsx";
import React from "react";

import { User } from "../../../../../models/user";
import { UtilsService } from "../../../../../services";
import { CIQAvatar } from "../../../../app/components";

interface ClientMemberProps {
  user: User;
  onSelectUser: (member: User) => void;
  isLoading: boolean;
}

export const ClientMember: React.FC<ClientMemberProps> = (props) => {
  const { user, isLoading } = props;

  const handleAddMemberToProject = (): void => {
    // eslint-disable-next-line no-shadow
    const { user, onSelectUser } = props;
    onSelectUser(user);
  };

  return (
    <div className={clsx("user-item", { disabled: isLoading })} key={user.id}>
      <div className="left-container">
        <div className="users-avatar">
          <CIQAvatar
            username={UtilsService.displayUserName(user)}
            profileImageUrl={user.profileImageUrl}
          />
        </div>
        <div className="user-name">{UtilsService.displayUserName(user)}</div>
      </div>
      <div className="right-container">
        <span
          className="ciq-icon ciq-plus"
          onClick={handleAddMemberToProject}
        />
      </div>
    </div>
  );
};
