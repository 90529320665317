/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as yup from "yup";

import { OptionChoice } from "@arbolus-technologies/api";

export const createMultiChoiceExpertSchema = (
  minChoices: number,
  maxChoices: number
) =>
  yup.object().shape({
    selectedChoices: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string().required(),
          text: yup.string().required(),
          sortOrder: yup.number().required(),
          isOther: yup.boolean().required(),
          isSelected: yup.boolean().required()
        })
      )
      .test({
        name: "selectedChoices",
        test: function (value) {
          const trueCount = value.filter(
            (obj: OptionChoice) => obj.isSelected
          ).length;

          if (minChoices && (!trueCount || trueCount < minChoices)) {
            return this.createError({
              message: `At least ${minChoices} options must be selected`,
              path: "selectedMinChoices"
            });
          }
          if (maxChoices && trueCount && trueCount > maxChoices) {
            return this.createError({
              message: `No more than ${maxChoices} options can be selected`,
              path: "selectedMaxChoices"
            });
          }
          if (
            value.some(
              (obj: OptionChoice) =>
                obj.isOther && obj.otherText === "" && obj.isSelected
            )
          ) {
            return this.createError({
              message: `Text Required`,
              path: "OtherText"
            });
          }
          return true;
        }
      })
  });
