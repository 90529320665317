// STAGED TO DELETE - CIQ - 1994/ 2011
// These transactions type not valid with new transaction api changes
export enum SPEND_DISTRIBUTION_TYPE {
  CALL = "Call",
  MEETING = "Meeting",
  FEE = "Fee",
  WORK = "Work",
  REFUND = "Refund"
}

export enum CLIENT_PRICING_TYPE {
  IntroductoryFeeMarkup = "IntroductoryFeeMarkup",
  CreditModel = "CreditPackage",
  FixedFee = "FixedFee "
}
