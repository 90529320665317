import { User } from "./user";

export interface DocumentUpload {
  id: string;
  file: File;
  projectId: string;
  cancel: boolean;
}

export interface BaseFile {
  id: string;
  created: Date;
  modified: Date;
  fileSize: number;
  uploadedUserId: string;
  uploadedUser: User;
  type: FILE_TYPE;
  durationSeconds?: number;
}

export interface Document extends BaseFile {
  fileName: string;
  transcriptId?: string;
}

export enum FILE_TYPE {
  TRANSCRIPT = "Transcription",
  DOCUMENT = "Document"
}

export interface NoteOrDoc extends BaseFile {
  title: string;
}

export interface DocumentCreateResponse {
  fileKey: string;
}

export interface DocumentDownloadResponse {
  downloadUrl: string;
  id: string;
  fileName: string;
  uploadedUserId: string;
  uploadedUser: User;
  created: Date;
  modified: Date;
}

export interface ExpertDocument {
  workspaceId: string;
  firstName: string;
  lastName: string;
  profileImageUrl: string;
  shared: boolean;
  workspaceType: string;
}

export interface DocumentStatusChangeRequest {
  message: string;
  documentIds: string[];
}
