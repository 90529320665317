import { RouterState, connectRouter } from "connected-react-router";
import { History } from "history";
import { Reducer, combineReducers } from "redux";

import {
  BookmarkCollectionsAppState,
  BookmarkCollectionsReducer
} from "@arbolus-technologies/stores/bookmark-collections";
import {
  BookmarksAppState,
  BookmarksReducer
} from "@arbolus-technologies/stores/bookmarks";
import {
  CacheAppState,
  CacheReducer
} from "@arbolus-technologies/stores/cache";
import {
  CanopyV2AppState,
  CanopyV2Reducer
} from "@arbolus-technologies/stores/canopy-V2";
import {
  CanopyBuilderAppState,
  CanopyBuilderReducer
} from "@arbolus-technologies/stores/canopy-builder";
import {
  CanopyClientAppState,
  ClientCanopyReducer
} from "@arbolus-technologies/stores/canopy-client";
import {
  CanopyExpertAppState,
  CanopyExpertReducer
} from "@arbolus-technologies/stores/canopy-expert";
import {
  ExpertNxAppState,
  ExpertNxReducer
} from "@arbolus-technologies/stores/expert";
import {
  PagesAppState,
  PagesReducer
} from "@arbolus-technologies/stores/pages";
import {
  PanelAppState,
  PanelReducer
} from "@arbolus-technologies/stores/panels";
import {
  ProjectNxAppState,
  ProjectNxReducer
} from "@arbolus-technologies/stores/project";
import {
  ProjectAnglesAppState,
  ProjectAnglesReducer
} from "@arbolus-technologies/stores/project-angles-store";
import {
  ProjectCanopyAppState,
  ProjectCanopyReducer
} from "@arbolus-technologies/stores/project-canopy-store";
import {
  ProjectExpertsAppState,
  ProjectExpertsReducer
} from "@arbolus-technologies/stores/project-experts-store";

import AppReducer, { AppReducerState } from "../containers/app/store/reducer";
import AuthReducer, {
  AuthReducerState
} from "../containers/auth/store/reducer";
import ProjectReducer, {
  ProjectReducerState
} from "../containers/project/store/reducer";

export interface AppState
  extends ProjectCanopyAppState,
    ProjectAnglesAppState,
    CanopyExpertAppState,
    CanopyV2AppState,
    CanopyClientAppState,
    CanopyBuilderAppState,
    ProjectNxAppState,
    ExpertNxAppState,
    CacheAppState,
    PanelAppState,
    PagesAppState,
    BookmarkCollectionsAppState,
    BookmarksAppState,
    ProjectExpertsAppState {
  app: AppReducerState;
  router: RouterState;
  auth: AuthReducerState;
  project: ProjectReducerState;
}

const createRootReducer = (history: History): Reducer<AppState> =>
  combineReducers<AppState>({
    app: AppReducer,
    auth: AuthReducer,
    bookmarkCollections: BookmarkCollectionsReducer,
    bookmarks: BookmarksReducer,
    cache: CacheReducer,
    canopyBuilder: CanopyBuilderReducer,
    canopyClient: ClientCanopyReducer,
    canopyExpert: CanopyExpertReducer,
    canopyV2: CanopyV2Reducer,
    pages: PagesReducer,
    panels: PanelReducer,
    project: ProjectReducer,
    projectAngles: ProjectAnglesReducer,
    projectCanopy: ProjectCanopyReducer,
    projectExperts: ProjectExpertsReducer,
    projectNx: ProjectNxReducer,
    expertNx: ExpertNxReducer,
    router: connectRouter(history)
  });

export default createRootReducer;
