import { ANSWER_TYPE } from "@arbolus-technologies/models/canopy";
import {
  CANOPY_QUESTION_MULTI_ROUTE,
  CANOPY_QUESTION_NPS_ROUTE,
  CANOPY_QUESTION_SHORT_TEXT_ROUTE,
  CANOPY_QUESTION_VALUE_ROUTE,
  CANOPY_QUESTION_VIDEO_ROUTE
} from "@arbolus-technologies/routes";

export const QUESTION_PAGE_ROUTES = {
  [ANSWER_TYPE.MULTIPLE_CHOICE]: CANOPY_QUESTION_MULTI_ROUTE,
  [ANSWER_TYPE.VIDEO]: CANOPY_QUESTION_VIDEO_ROUTE,
  [ANSWER_TYPE.VALUE]: CANOPY_QUESTION_VALUE_ROUTE,
  [ANSWER_TYPE.SHORT_TEXT]: CANOPY_QUESTION_SHORT_TEXT_ROUTE,
  [ANSWER_TYPE.NPS]: CANOPY_QUESTION_NPS_ROUTE
};
