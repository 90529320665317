import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { PROJECT_ROUTE } from "@arbolus-technologies/routes";
import { MainPageLayout } from "@arbolus-technologies/ui/layout";
import { useDocumentTitle } from "@arbolus-technologies/utils";

import { ProjectSpendActions } from "../Modules/ProjectSpend/ProjectSpendActions";
import { ProjectSpendTable } from "../Modules/ProjectSpend/ProjectSpendTable";

export const ProjectSpendPage: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { t } = useTranslation("projectSpend");
  const backLink = useMemo(
    () => ({ text: t("back"), to: PROJECT_ROUTE(projectId) }),
    [projectId, t]
  );

  useDocumentTitle("projectSpend", "title");

  return (
    <MainPageLayout
      title={t("title")}
      stickyHeader
      backLink={backLink}
      rightButtonContainer={<ProjectSpendActions projectId={projectId} />}
    >
      <ProjectSpendTable projectId={projectId} />
    </MainPageLayout>
  );
};
