import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  CustomersService,
  DefaultToasterService,
  SurveyAnswer
} from "@arbolus-technologies/api";
import { Button, Drawer } from "@arbolus-technologies/ui/components";

import { Question } from "../../../../components/Question/Question";
import { EXPERT_SURVEY_QUESTIONS } from "./expertSurveyQuestions";

import styles from "./CompanyQuestionsForm.module.scss";
import { CompanyQuestionsSchema } from "./CompanyQuestionsSchema";

export function CompanyQuestionsForm({
  company,
  customerInsightId,
  surveyId,
  previousAnswer,
  isFirst = false
}: {
  company: { name: string };
  customerInsightId: string;
  surveyId: string;
  previousAnswer?: SurveyAnswer;
  isFirst?: boolean;
}) {
  const { t } = useTranslation("expertSurvey");

  const methods = useForm<Omit<SurveyAnswer, "customerInsightId">>({
    // @ts-ignore
    resolver: yupResolver(CompanyQuestionsSchema),
    defaultValues: { ...(previousAnswer ?? {}) },
    mode: "all"
  });

  function saveAnswer() {
    const formValues = methods.getValues();
    CustomersService.updateSurveyInsights(surveyId, {
      ...formValues,
      customerInsightId
    }).subscribe(
      () => {
        DefaultToasterService.showSuccess(t("surveySubmitted"));
      },
      (error) => {
        DefaultToasterService.showApiErrors(error);
      }
    );
  }

  return (
    <Drawer title={company.name} openByDefault={isFirst}>
      <FormProvider {...methods}>
        {EXPERT_SURVEY_QUESTIONS.map((question) => (
          <Question key={question.title} question={question} />
        ))}
        <div className={styles.footer}>
          <Button
            text={t("saveAnswerButton")}
            onClick={methods.handleSubmit(saveAnswer)}
            nativeType="submit"
          />
        </div>
      </FormProvider>
    </Drawer>
  );
}
