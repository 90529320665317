import { Radio, RadioChangeEvent } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, useHistory, useLocation } from "react-router";

import {
  EXPERT_SELF_PROFILE_EDIT,
  EXPERT_DETAILS_EDIT_TABS_PATH as ROUTES,
  EXPERT_DETAILS_EDIT_TABS as TABS
} from "@arbolus-technologies/routes";
import { AntDHeader } from "@arbolus-technologies/ui/layout";

import { Details } from "../../Components/Details/Details";
import { NotificationSettings } from "../../Components/NotificationSettings/NotificationSettings";
import { SecuritySettings } from "../../Components/SecuritySettings/SecuritySettings";
import { ExpertNotificationDetails } from "../../helpers/types";

import styles from "./EditProfile.module.scss";

const { Group } = Radio;

interface EditProfileProps {
  expertDetails: ExpertNotificationDetails;
  userId: string;
  logOut: () => void;
}

export const EditProfile: React.FC<EditProfileProps> = ({
  expertDetails,
  userId,
  logOut
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const activeTab = location.pathname.split("/").pop();

  const handleTabChange = ({ target: { value } }: RadioChangeEvent) => {
    history.push(`${EXPERT_SELF_PROFILE_EDIT}/${value}`);
  };

  const TABS_ROUTES = [
    {
      label: "Details",
      value: TABS.DETAILS,
      route: ROUTES.DETAILS,
      component: () => (
        <Details expertId={expertDetails.expertId!} userId={userId} />
      )
    },
    {
      label: "Settings",
      value: TABS.SETTINGS,
      route: ROUTES.SETTINGS,
      component: () => (
        <NotificationSettings expertDetails={expertDetails} logOut={logOut} />
      )
    },
    {
      label: "Security",
      value: TABS.PASSWORD,
      route: ROUTES.PASSWORD,
      component: SecuritySettings
    }
  ];

  return (
    <div className={styles.page}>
      <AntDHeader title={t("editProfile")} />

      <div className={styles.pageContent}>
        <Group
          options={TABS_ROUTES}
          value={activeTab}
          onChange={handleTabChange}
          optionType="button"
          buttonStyle="solid"
        />
        <Switch>
          {TABS_ROUTES.map(({ route, component }) => (
            <Route key={route} path={route} component={component} />
          ))}
        </Switch>
      </div>
    </div>
  );
};
