import { Observable } from "rxjs";

import { PROJECT_BILLING_API } from "../constants/api";
import { ApiPaginatedRequest, ApiPaginatedResponse } from "../models/api";
import {
  ClientListExpertSpend,
  ClientListProjectSpend,
  ClientSpendDistribution,
  ClientTotalSpend
} from "../models/client";
import { restService } from "../restService";

export const ProjectBillingService = {
  getTotalSpend: (
    clientId: string,
    projectId?: string
  ): Observable<ClientTotalSpend> =>
    restService.get<ClientTotalSpend>(
      PROJECT_BILLING_API.getClientTotalSpend(),
      {
        clientId,
        projectId
      }
    ),
  getSpendDistribution: (
    clientId: string,
    projectId?: string
  ): Observable<ClientSpendDistribution> =>
    restService.get<ClientSpendDistribution>(
      PROJECT_BILLING_API.getClientSpendDistribution(),
      {
        clientId,
        projectId
      }
    ),
  getProjectSpendList: (
    clientId: string,
    apiParams: ApiPaginatedRequest,
    projectId?: string
  ): Observable<ApiPaginatedResponse<ClientListProjectSpend>> =>
    restService.get<ApiPaginatedResponse<ClientListProjectSpend>>(
      PROJECT_BILLING_API.getClientProjectSpend(),
      {
        clientId,
        ...apiParams,
        projectId
      }
    ),
  getExpertSpendList: (
    clientId: string,
    apiParams: ApiPaginatedRequest,
    projectId?: string
  ): Observable<ApiPaginatedResponse<ClientListExpertSpend>> =>
    restService.get<ApiPaginatedResponse<ClientListExpertSpend>>(
      PROJECT_BILLING_API.getClientExpertSpend(),
      {
        clientId,
        ...apiParams,
        projectId
      }
    )
};
