import { Button } from "arbolus-ui-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  PROJECT_STATES,
  ProjectService,
  ToasterService
} from "@arbolus-technologies/api";

import DeleteProject from "../DeleteProject/DeleteProject";

import styles from "./UpdateProjectStateButton.module.scss";

interface UpdateProjectStateButtonProps {
  projectId: string;
  projectState: PROJECT_STATES;
  projectName?: string;
  isAdmin?: boolean;
  projectService?: typeof ProjectService;
  notificationService?: ToasterService;
}

export const UpdateProjectStateButton: React.FC<
  UpdateProjectStateButtonProps
> = ({
  projectId,
  projectState,
  projectName,
  isAdmin,
  projectService = ProjectService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("updateProjectStateButton");
  const [isActiveProject, setIsActiveProject] = useState<boolean>(false);

  useEffect(() => {
    setIsActiveProject(projectState === PROJECT_STATES.ACTIVE);
  }, [projectState]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleUpdateProjectState = () => {
    const newState = isActiveProject
      ? PROJECT_STATES.ARCHIVE
      : PROJECT_STATES.ACTIVE;

    projectService.updateProjectState(projectId, newState).subscribe(
      () => {
        const isActivated = newState === PROJECT_STATES.ACTIVE;
        setIsLoading(false);
        notificationService.showSuccess(
          t(isActivated ? "activateSuccess" : "archivedSuccess")
        );
        setIsActiveProject(isActivated);
      },
      (error: ErrorResponse<CIQError>) => {
        setIsLoading(false);
        notificationService.showApiErrors(error);
      }
    );
  };

  return (
    <div className={styles.btnContainer}>
      <Button
        type={isActiveProject ? "rejection" : "confirmation"}
        text={isActiveProject ? t("archiveProject") : t("activeProject")}
        onClick={handleUpdateProjectState}
        disabled={isLoading}
      />
      {!isActiveProject && isAdmin && projectName && (
        <DeleteProject projectId={projectId} projectName={projectName} />
      )}
    </div>
  );
};
