import { Icon } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";

import { DateString } from "@arbolus-technologies/models/common";

import { ArbolusTooltip } from "../../Tooltip/ArbolusTooltip";
import {
  PublicCompanyIconColors,
  getPublicBadgeType
} from "../../models/Company";

interface PublicCompanyIconProps {
  date: DateString | Date | null;
  customFontSize?: string;
}

export const PublicCompanyIcon: React.FC<PublicCompanyIconProps> = ({
  date,
  customFontSize = "16px"
}) => {
  const { t } = useTranslation("publicCompanyIcon");
  const type = getPublicBadgeType(date);

  return (
    <ArbolusTooltip content={t(type)}>
      <Icon
        name="chart_data"
        color={PublicCompanyIconColors[type]}
        fontSize={customFontSize}
        filled
      />
    </ArbolusTooltip>
  );
};
