import dompurify from "dompurify";
import React from "react";
import { useTranslation } from "react-i18next";

import { CompanyAndLocationFilter } from "@arbolus-technologies/models/project";
import { YELLOW } from "@arbolus-technologies/theme";
import {
  EXPERT_DISCOVER_TIME_FORMAT,
  formatDate,
  highlightWords
} from "@arbolus-technologies/utils";

import styles from "./WorkHistoryItem.module.scss";

interface WorkHistoryItemProps {
  title: string | null;
  from: string;
  to: string | null;
  companyName: string | null;
  countryName: string | null;
  companyAndLocationFilter?: CompanyAndLocationFilter;
  keywordsList?: string[] | null;
  highlightWorkHistoryTitle?: boolean;
  highlightWorkHistoryCompany?: boolean;
}

export const WorkHistoryItem: React.FC<WorkHistoryItemProps> = ({
  title,
  from,
  to,
  companyName,
  countryName,
  companyAndLocationFilter,
  keywordsList,
  highlightWorkHistoryCompany = false,
  highlightWorkHistoryTitle = false
}) => {
  const { t } = useTranslation("workHistory");
  const sanitizer = dompurify.sanitize;
  const fromDateFormatted = formatDate(from, EXPERT_DISCOVER_TIME_FORMAT);
  const toDateFormatted = to
    ? formatDate(to, EXPERT_DISCOVER_TIME_FORMAT)
    : t("present");

  const companies = companyAndLocationFilter?.company.map(
    (company) => company.label
  );
  const locations = companyAndLocationFilter?.workHistoryLocations.map(
    (location) => location.label
  );

  const companyTags = (): string[] => {
    let companyTagsArray: string[] = [];
    if (companies) {
      companyTagsArray = [...companies];
    }
    if (highlightWorkHistoryCompany && keywordsList) {
      companyTagsArray = [...companyTagsArray, ...keywordsList];
    }
    return companyTagsArray;
  };

  const highlightedTitle = highlightWords({
    text: title ?? "",
    tags: highlightWorkHistoryTitle && keywordsList ? [...keywordsList] : [],
    highlightColor: YELLOW,
    boldedWords: true
  });

  const highlightedCompanies = highlightWords({
    text: companyName ?? "",
    tags: companyTags(),
    highlightColor: YELLOW,
    boldedWords: true
  });

  const highlightedLocations = highlightWords({
    text: countryName ?? "",
    tags: locations ? [...locations] : [],
    highlightColor: YELLOW,
    boldedWords: true
  });

  return (
    <div className={styles.workHistoryItem}>
      <div className={styles.workHistoryDetails}>
        {highlightedTitle && (
          <span
            title={title ?? t("notDefined")}
            className={styles.singleLine}
            dangerouslySetInnerHTML={{
              __html: sanitizer(highlightedTitle ?? "")
            }}
          />
        )}
        {highlightedCompanies && (
          <>
            <span className={styles.middleDot}>&nbsp;•&nbsp;</span>
            <span
              title={companyName ?? undefined}
              className={styles.singleLine}
              dangerouslySetInnerHTML={{
                __html: sanitizer(highlightedCompanies ?? "")
              }}
            />
          </>
        )}
        <span className={styles.middleDot}>&nbsp;•&nbsp;</span>
        <span
          className={styles.lightText}
        >{`${fromDateFormatted} - ${toDateFormatted}`}</span>
        {highlightedLocations && (
          <>
            <span className={styles.middleDot}>&nbsp;•&nbsp;</span>
            <span
              title={countryName ?? undefined}
              className={styles.lightText}
              dangerouslySetInnerHTML={{
                __html: sanitizer(highlightedLocations ?? "")
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};
