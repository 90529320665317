import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  Angle,
  AnglesService,
  DefaultToasterService,
  ToasterService
} from "@arbolus-technologies/api";
import { ProjectData } from "@arbolus-technologies/features/common";
import { DropdownAngle } from "@arbolus-technologies/models/project";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ChevronButton } from "@arbolus-technologies/ui/components";

import { AnglesDropdown } from "../../Components/AnglesDropdown/AnglesDropdown";
import { AnglesSlidePanel } from "../../Components/AnglesSlidePanel/AnglesSlidePanel";
import { ANGLE_DROPDOWN_DEFAULT_OPTION } from "../../Constants/angle";

interface ProjectAnglesProps {
  projectData: ProjectData;
  isAdmin?: boolean;
  anglesService?: typeof AnglesService;
  notificationService?: ToasterService;
}

export const ProjectAngles: React.FC<ProjectAnglesProps> = ({
  projectData,
  isAdmin = false,
  anglesService = AnglesService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("projectAngles");
  const [angles, setAngles] = useState<Angle[]>();
  const [selectedAngle, setSelectedAngle] = useState<DropdownAngle>(
    ANGLE_DROPDOWN_DEFAULT_OPTION
  );
  const dispatch = useDispatch();

  const getAngles = useCallback(() => {
    anglesService.getAnglesCards(projectData.id, 0, 200).subscribe({
      next: (response) => setAngles(response.items),
      error: notificationService.showApiErrors
    });
  }, [anglesService, notificationService, projectData]);

  useEffect(() => {
    getAngles();
  }, [getAngles]);

  const manageAngles = () => {
    dispatch(PanelStoreActions.openPanel(PanelId.ManageAngles));
  };

  return (
    <>
      <div className="d-flex flex-row w-100 px-4 align-items-center">
        {angles && (
          <AnglesDropdown
            angles={angles}
            projectId={projectData.id}
            selectedAngle={selectedAngle}
            size="large"
            setSelectedAngle={setSelectedAngle}
          />
        )}
        <div className="ml-auto">
          <ChevronButton
            text={t("manage")}
            icon={{
              position: "end",
              direction: "right"
            }}
            onClick={manageAngles}
          />
        </div>
      </div>
      {angles && (
        <AnglesSlidePanel
          projectId={projectData.id}
          angles={angles}
          updateAngles={getAngles}
          isAdmin={isAdmin}
        />
      )}
    </>
  );
};
