import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";

import { HR } from "@arbolus-technologies/ui/components";

import styles from "./ViewEditAngleFooter.module.scss";

export interface ViewEditAngleFooterProps {
  canEdit: boolean;
  isEdit: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  isScreeningQuestionView?: boolean;
  editAction: () => void;
  handleSubmit: () => void;
}
export const ViewEditAngleFooter: React.FC<ViewEditAngleFooterProps> = ({
  canEdit,
  isEdit,
  isLoading,
  isDisabled,
  isScreeningQuestionView = false,
  editAction,
  handleSubmit
}) => {
  const { t } = useTranslation("viewEditAngle");
  return (
    <>
      <HR />
      <div className={styles.buttonContainer}>
        {canEdit && isEdit && (
          <Button
            text={
              isScreeningQuestionView ? t("editScreeningQuestions") : t("edit")
            }
            type="tertiary"
            onClick={editAction}
          />
        )}
        <Button
          text={isEdit ? t("update") : t("create")}
          onClick={handleSubmit}
          disabled={isLoading || isDisabled}
        />
      </div>
    </>
  );
};
