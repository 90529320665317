import React from "react";
import Media from "react-media";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Subscription } from "rxjs";

import { SORT_DIRECTION, ToasterService } from "@arbolus-technologies/api";
import { Loader, SearchBar } from "@arbolus-technologies/ui/components";

import { APIConstants, ValidationConstants } from "../../../../../constants";
import { SEARCH_DEBOUNCE_TIMEOUT } from "../../../../../constants/timer";
import {
  APP_DEVICE_MEDIA_QUERIES,
  UI_USER_SELECTION_PANEL
} from "../../../../../constants/ui";
import { USER_ACCESS_LEVEL } from "../../../../../constants/user";
import {
  ContentPanelContextConsumer,
  ContentPanelContextProps
} from "../../../../../contexts/contentPanel/ContentPanelContext";
import { CIQError, ErrorResponse } from "../../../../../models/api";
import { ClientUsersGetRequest } from "../../../../../models/client";
import { Member } from "../../../../../models/project";
import { LoggedInUser, User } from "../../../../../models/user";
import { ClientService, UserService } from "../../../../../services";
import { AppState } from "../../../../../store/reducers";
import { CIQInfiniteScroll } from "../../../../app/components";
import { AuthSelector } from "../../../../auth/store";
import { ProjectSelector } from "../../../../project/store";
import { ClientMember } from "./ClientMember";

const notification = new ToasterService();

interface UserSelectionPanelStoreProps {
  loggedInUser: LoggedInUser;
  members: Member[];
}
interface UserSelectionPanelProps extends UserSelectionPanelStoreProps {
  currentBillingUser?: User;
  onSelectBillingUser: (user: User) => void;
  onPanelClose: () => void;
  clientMembers: boolean;
  panelHeader: string;
  searchPlaceholder: string;
  isAdminUsersOnly?: boolean;
}

interface UserSelectionPanelState {
  currentPage: number;
  projectMembers: Member[];
  users: User[];
  hasMore: boolean;
  isLoading: boolean;
  searchQuery: string;
}

class UserSelectionPanel extends React.Component<
  UserSelectionPanelProps,
  UserSelectionPanelState
> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    loggedInUser: {} as LoggedInUser,
    members: []
  };

  constructor(props: UserSelectionPanelProps) {
    super(props);

    this.state = {
      currentPage: 0,
      users: [],
      projectMembers: props.members,
      isLoading: false,
      searchQuery: "",
      hasMore: false
    };
  }

  componentWillUnmount(): void {
    this.clientMembersFetchSubscription &&
      this.clientMembersFetchSubscription.unsubscribe();
  }

  private clientMembersFetchSubscription?: Subscription;

  private apiNextOffset = 0;

  private searchQueryDebounceTimeout?: number;

  private initialElementCount = APIConstants.MAX_PAGE_SIZE;

  fetchMembers = (): void => {
    const { searchQuery, currentPage, users } = this.state;
    const { loggedInUser, currentBillingUser, onPanelClose, isAdminUsersOnly } =
      this.props;

    const {
      id: LoggedInUserId,
      client,
      email,
      phoneNumber,
      profileImageUrl
    } = loggedInUser;

    this.setState({ isLoading: true });
    const clientMemberParams: ClientUsersGetRequest = {
      searchTerm: searchQuery,
      offset: this.apiNextOffset,
      orderBy: "firstName",
      orderDirection: SORT_DIRECTION.ASCENDING,
      limit:
        users.length === 0
          ? this.initialElementCount
          : APIConstants.MAX_PAGE_SIZE,
      accessLevel: isAdminUsersOnly ? USER_ACCESS_LEVEL.ADMIN.value : undefined
    };
    this.clientMembersFetchSubscription = ClientService.getTeamMembers(
      client!.id,
      clientMemberParams
    ).subscribe(
      ({ pagination, items }) => {
        this.apiNextOffset += items.length;

        let receivingUsers: User[] = items;
        let totalCount = pagination.count;

        if (currentBillingUser) {
          // if billing user has already been selected eliminate both current-billing user and logged-in user
          receivingUsers = receivingUsers.filter(
            (user) =>
              user.id !== currentBillingUser.id && user.id !== LoggedInUserId
          );

          totalCount -= 1;
        } else {
          receivingUsers = receivingUsers.filter(
            (user) => user.id !== LoggedInUserId
          );
        }

        if (
          !users.length && // only for first page
          UserService.isUserMatchesSearchCriteria(loggedInUser, searchQuery) &&
          (!currentBillingUser || currentBillingUser.id !== LoggedInUserId)
        ) {
          receivingUsers = [
            {
              id: LoggedInUserId,
              profileImageUrl,
              email,
              firstName: "Me",
              lastName: "",
              phoneNumber
            } as User
          ].concat(receivingUsers);
        }
        const updatedUsers = users.concat(receivingUsers);

        this.setState({
          users: users.concat(receivingUsers),
          hasMore: updatedUsers.length < totalCount,
          currentPage: currentPage + 1,
          isLoading: false
        });
      },
      (err: ErrorResponse<CIQError>) => {
        notification.showError(err.message);
        this.setState({ isLoading: false }, () => onPanelClose());
      }
    );
  };

  filterBillingUsersInProject = (): Member[] => {
    const { loggedInUser, currentBillingUser } = this.props;

    const { projectMembers, searchQuery } = this.state;

    let members: Member[] = projectMembers;

    const index = members.findIndex(
      (member) => member.user.id === loggedInUser.id
    );

    members[index].user.firstName = "Me";
    members[index].user.lastName = "";

    if (currentBillingUser) {
      members = members.filter(
        (member) => member.user.id !== currentBillingUser.id
      );
    }

    let searchedMembers = members.filter(
      (m) => m.user.accessLevel === USER_ACCESS_LEVEL.ADMIN.value
    );

    if (searchQuery.length >= ValidationConstants.MINIMUM_SEARCH_TERM_LENGTH) {
      searchedMembers = members.filter((m) =>
        UserService.isUserMatchesSearchCriteria(m.user, searchQuery)
      );
    }

    return searchedMembers;
  };

  debounceAPICall = (isDebounced: boolean): void => {
    if (this.searchQueryDebounceTimeout)
      clearTimeout(this.searchQueryDebounceTimeout);

    this.searchQueryDebounceTimeout = setTimeout(
      () => {
        this.clientMembersFetchSubscription?.unsubscribe();
        this.apiNextOffset = 0;
        this.setState(
          {
            users: [],
            currentPage: 0
          },
          this.fetchMembers
        );
      },
      isDebounced ? SEARCH_DEBOUNCE_TIMEOUT : 0
    );
  };

  handleGuestSearchQueryChange = (searchQuery: string): void => {
    const { clientMembers } = this.props;

    this.setState(
      {
        searchQuery
      },
      clientMembers
        ? (): void => this.debounceAPICall(Boolean(searchQuery))
        : undefined
    );
  };

  handleClearQueryClicked = (): void => {
    this.setState({
      searchQuery: "",
      currentPage: 0,
      users: []
    });
  };

  handleInitialFetch = (elementCount?: number): void => {
    const { clientMembers } = this.props;
    if (elementCount) {
      this.initialElementCount = elementCount;
    }

    if (clientMembers) this.fetchMembers();
  };

  handleMemberListBottomReached = (): void => {
    const { hasMore, isLoading } = this.state;

    if (hasMore && !isLoading) {
      this.fetchMembers();
    }
  };

  renderNoResultContainer = (): JSX.Element => (
    <div className="no-result-container">
      <p>No results</p>
    </div>
  );

  renderClientMembers = (): JSX.Element[] | JSX.Element => {
    const { onSelectBillingUser, clientMembers } = this.props;
    const { users, isLoading } = this.state;

    if (clientMembers) {
      if (users.length === 0) {
        return isLoading ? <></> : this.renderNoResultContainer();
      }

      return users.map((user) => (
        <ClientMember
          key={user.id}
          user={user}
          isLoading={false}
          onSelectUser={onSelectBillingUser}
        />
      ));
    }

    const members = this.filterBillingUsersInProject();
    if (members.length === 0) {
      return isLoading ? <></> : this.renderNoResultContainer();
    }

    return members.map((member) => (
      <ClientMember
        key={member.id}
        user={member.user}
        isLoading={false}
        onSelectUser={onSelectBillingUser}
      />
    ));
  };

  renderMemberList = (): JSX.Element => {
    const { isLoading } = this.state;

    return (
      <Media queries={APP_DEVICE_MEDIA_QUERIES}>
        {(matches): JSX.Element => (
          <div className="user-list-container">
            <CIQInfiniteScroll
              onBottomReached={this.handleMemberListBottomReached}
              elementHeight={UI_USER_SELECTION_PANEL.USER_ELEMENT_HEIGHT(
                matches
              )}
              onInitialFetch={this.handleInitialFetch}
              className="users-list simplebar-light"
              style={{
                maxHeight: UI_USER_SELECTION_PANEL.PANEL_LIST_HEIGHT,
                height: UI_USER_SELECTION_PANEL.PANEL_LIST_HEIGHT,
                overflowX: "hidden"
              }}
            >
              {this.renderClientMembers()}
              {isLoading && <Loader key={0} />}
            </CIQInfiniteScroll>
          </div>
        )}
      </Media>
    );
  };

  render(): JSX.Element {
    const { searchQuery } = this.state;
    const { panelHeader, searchPlaceholder } = this.props;
    return (
      <div className="project-members-container content-panel-body">
        <div className="panel-header">
          <h2>{panelHeader}</h2>
          <ContentPanelContextConsumer>
            {(contentPanelContext: ContentPanelContextProps): JSX.Element => (
              <div
                className="btn-close"
                onClick={contentPanelContext.closePanel}
              >
                <i className="ciq-icon ciq-close" />
              </div>
            )}
          </ContentPanelContextConsumer>
        </div>
        <div className="members-list-body panel-body">
          <div className="top-container">
            <div className="search-filter-container">
              <SearchBar
                initialValue={searchQuery}
                placeholder={searchPlaceholder}
                isDebounced
                onQueryChange={this.handleGuestSearchQueryChange}
                onClear={this.handleClearQueryClicked}
              />
            </div>
          </div>
        </div>
        {this.renderMemberList()}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<
  AppState,
  UserSelectionPanelProps,
  UserSelectionPanelStoreProps
>({
  loggedInUser: AuthSelector.authUserSelector(),
  members: ProjectSelector.projectMembersSelector()
});

export default connect(mapStateToProps, null)(UserSelectionPanel);
