import React from "react";
import { useTranslation } from "react-i18next";

import {
  REFERRAL_REJECT_REASON,
  RejectDescription
} from "@arbolus-technologies/models/project";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { BoxWithIconTitleWithSubtitle } from "@arbolus-technologies/ui/components";

interface RejectBoxProps {
  rejectDescription: RejectDescription;
}

export const RejectBox: React.FC<RejectBoxProps> = ({ rejectDescription }) => {
  const { t } = useTranslation("rejectExpert");

  const subtitle =
    rejectDescription.reason === REFERRAL_REJECT_REASON.OTHER
      ? rejectDescription.description || t(REFERRAL_REJECT_REASON.OTHER)
      : t(rejectDescription.reason);

  return rejectDescription ? (
    <BoxWithIconTitleWithSubtitle
      title={t("rejectionFeedback")}
      subtitle={subtitle || ""}
      icon={{
        iconName: "thumb_down",
        color: ARBOLUS_COLORS.bColorBaseWhite,
        background: ARBOLUS_COLORS.bColorBaseOrange,
        border: undefined
      }}
      noEllipsis
    />
  ) : null;
};
