import React from "react";
import {
  TypeaheadLabelKey,
  TypeaheadModel,
  TypeaheadResult
} from "react-bootstrap-typeahead";
import { Control, Controller, FieldError } from "react-hook-form";
import { Label } from "reactstrap";
import { Observable } from "rxjs";

import {
  ApiPaginatedRequest,
  ApiPaginatedResponse
} from "@arbolus-technologies/api";

import { SelectTypeahead } from "./SelectTypeahead";

import styles from "./SelectController.module.scss";

export type TypeaheadSelectorControllerProps<
  T extends TypeaheadModel & object
> = Omit<
  SelectControllerProps<T>,
  | "getItems"
  | "renderer"
  | "getLabelKey"
  | "promptText"
  | "searchText"
  | "paginationText"
  | "orderBy"
>;

interface SelectControllerProps<T extends TypeaheadModel & object> {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  orderBy: string;
  promptText: string;
  searchText: string;
  paginationText: string;
  defaultValue?: T;
  error?: FieldError;
  placeholderText?: string;
  disabled?: boolean;
  displayResults?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect?: (item: any) => void;
  getItems: (
    pagination: ApiPaginatedRequest
  ) => Observable<ApiPaginatedResponse<T>>;
  renderer: (item: TypeaheadResult<T>) => JSX.Element;
  getLabelKey: TypeaheadLabelKey<T>;
  clientFilter?: (item: T, query: string) => boolean;
}

export const SelectController = <T extends TypeaheadModel & object>({
  name,
  control,
  orderBy,
  promptText,
  searchText,
  paginationText,
  defaultValue,
  error,
  onSelect,
  getItems,
  renderer,
  getLabelKey,
  clientFilter,
  displayResults,
  disabled = false,
  placeholderText = ""
}: SelectControllerProps<T>): React.ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (onChange: (...event: any[]) => void, selected: T[]) => {
    onChange(selected[0]);
    if (onSelect) {
      onSelect(selected[0]);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      defaultValue={defaultValue as any}
      render={({ field: { value, onBlur, onChange } }) => (
        <>
          <SelectTypeahead
            selected={value ? [value] : []}
            name={name}
            onChange={(items) => handleChange(onChange, items)}
            onBlur={onBlur}
            error={error}
            orderBy={orderBy}
            promptText={promptText}
            searchText={searchText}
            paginationText={paginationText}
            placeholderText={placeholderText}
            displayResults={displayResults}
            disabled={disabled}
            defaultSelected={defaultValue ? [defaultValue] : []}
            getItems={getItems}
            renderer={renderer}
            getLabelKey={getLabelKey}
            clientFilter={clientFilter}
          />
          {error && (
            <div className={styles.errorContainer}>
              <Label className={styles.errorMessage}>{error.message}</Label>
            </div>
          )}
        </>
      )}
    />
  );
};
