import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ToasterService } from "@arbolus-technologies/api";
import { SurveysSummary } from "@arbolus-technologies/features/canopy";
import {
  CreateSurvey,
  MixPanelActions,
  MixPanelEventNames,
  SlidePanel,
  trackEvent
} from "@arbolus-technologies/features/common";
import { CANOPY_EXPERTS_ROUTE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { CanopyBuilderStoreActions } from "@arbolus-technologies/stores/canopy-builder";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { AppConstants } from "../../../../../constants";
import { CANOPY_DETAILS_PAGE_ROUTE } from "../../../../../constants/navigation/canopyRoutes";
import { PROJECT_CANOPIES } from "../../../../../constants/navigation/projectRoutes";
import { PROJECT_TAB_ROUTES } from "../../../../../constants/navigation/projectTabRoutes";
import { ProjectSelector } from "../../../store";

import styles from "./SurveyPane.module.scss";

const notification = new ToasterService();

const SLIDE_PANEL_ID = PanelId.CreateSurvey;

const SurveyPane: React.FC = () => {
  const projectId = useSelector(ProjectSelector.projectIdSelector());
  const dispatch = useDispatch();
  const { t } = useTranslation("createSurvey");
  useEffect(() => {
    dispatch(CanopyBuilderStoreActions.clearSelectedCanopy());
    dispatch(CanopyBuilderStoreActions.clearErrorsCanopy());
  }, [dispatch]);
  const isCurrentUserClient = useSelector(
    CacheSelector.isCurrentUserClientSelector()
  );

  const canopyDetailsPageRoute = (canopyId: string) =>
    CANOPY_DETAILS_PAGE_ROUTE(canopyId);
  const canopyExpertsRoute = (canopyId: string) =>
    CANOPY_EXPERTS_ROUTE(canopyId);
  const viewAllCanopiesPageRoute = () =>
    PROJECT_TAB_ROUTES[PROJECT_CANOPIES].route(projectId);

  const closePanel = () => {
    dispatch(PanelStoreActions.closePanel(SLIDE_PANEL_ID));
  };

  const createNewSurvey = (): void => {
    dispatch(PanelStoreActions.openPanel(SLIDE_PANEL_ID));

    if (isCurrentUserClient) {
      trackEvent(MixPanelEventNames.Surveys, {
        action: MixPanelActions.Initialized
      });
    }
  };

  return (
    <>
      <SurveysSummary
        projectId={projectId}
        createNewSurvey={createNewSurvey}
        canopyDetailsPageRoute={canopyDetailsPageRoute}
        viewAllCanopiesPageRoute={viewAllCanopiesPageRoute}
        canopyExpertsRoute={canopyExpertsRoute}
      />
      <SlidePanel
        title={<div className={styles.surveyTitle}>{t("newSurvey")}</div>}
        panelId={SLIDE_PANEL_ID}
        closeButtonDirection="right"
        width={SIDE_PANEL_SIZE._720}
      >
        <CreateSurvey
          type="create"
          canopyId=""
          projectId={projectId}
          closePanel={closePanel}
          showError={notification.showError}
          key={AppConstants.CONTENT_PANEL.CREATE_CANOPY}
        />
      </SlidePanel>
    </>
  );
};

export default SurveyPane;
