import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import SimpleBar from "simplebar-react";

interface ReferExpertPanelProps {
  onClose: () => void;
  onReferExpert: () => void;
}

const ReferExpertPanel = ({
  onClose,
  onReferExpert
}: ReferExpertPanelProps): JSX.Element => {
  const { t } = useTranslation("referExpert");

  const handleReferNowClicked = (): void => {
    onReferExpert();
    onClose();
  };

  return (
    <SimpleBar className="content-panel-body refer-expert-container simplebar-light">
      <div className="panel-header">
        <h2>{t("referExperts")}</h2>
        <div className="btn-close" onClick={onClose}>
          <i className="ciq-icon ciq-close" />
        </div>
      </div>

      <hr />

      <div className="panel-body">
        <div className="info-container">
          <h3>{t("howWorks")}</h3>
          <p>{t("detail_1")}</p>
          <p>{t("detail_2")}</p>
        </div>
        <Button size="sm" color="primary" onClick={handleReferNowClicked}>
          {t("referNow")}
        </Button>
      </div>
    </SimpleBar>
  );
};

export default ReferExpertPanel;
