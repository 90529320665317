import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./NotificationDot.module.scss";

// These absolute position properties allow you to reposition the NotificationDot
// component in a relative container.
export interface NotificationDotProps {
  offsetTop?: string;
  offsetRight?: string;
  offsetBottom?: string;
  offsetLeft?: string;
  size?: string;
  color?: string;
}

export const NotificationDot: React.FC<NotificationDotProps> = ({
  offsetTop,
  offsetRight,
  offsetBottom,
  offsetLeft,
  size,
  color = ARBOLUS_COLORS.bColorBaseOrange
}) => (
  <div
    className={styles.notificationDot}
    style={{
      top: offsetTop,
      right: offsetRight,
      bottom: offsetBottom,
      left: offsetLeft,
      width: size,
      height: size,
      minWidth: size,
      minHeight: size,
      background: color
    }}
  />
);
