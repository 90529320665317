import { LocationDescriptorObject } from "history";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { AntDButtonLink, AntDIcon } from "@arbolus-technologies/antDComponents";
import {
  MAIN_PROJECTS_ROUTE,
  PROJECT_DRAFT_BASE_ROUTE
} from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { LabeledSwitch } from "@arbolus-technologies/ui/components";
import { MainPageLayout } from "@arbolus-technologies/ui/layout";

interface ProjectListLayoutProps {
  title: string;
  to: LocationDescriptorObject;
  children: ReactNode;
}

export const ProjectListLayout: React.FC<ProjectListLayoutProps> = ({
  title,
  to,
  children
}) => {
  const { push } = useHistory();
  const isAdmin = useSelector(CacheSelector.isAdmin());
  const { t } = useTranslation("projectsTable");

  return (
    <MainPageLayout
      title={title}
      stickyHeader
      rightButtonContainer={
        isAdmin ? (
          <LabeledSwitch
            leftTabTitle={t("actives")}
            rightTabTitle={t("drafts")}
            checked={to.pathname === MAIN_PROJECTS_ROUTE().pathname}
            onChange={() => push(to)}
          />
        ) : (
          <AntDButtonLink
            text={t("create")}
            type="primary"
            to={PROJECT_DRAFT_BASE_ROUTE().pathname!}
            icon={<AntDIcon name="add" />}
          />
        )
      }
    >
      {children}
    </MainPageLayout>
  );
};
