import { ReferralSummary } from "@arbolus-technologies/api";
import { REFERRAL_STATES } from "@arbolus-technologies/models/expert";

import { CIQError, ErrorResponse } from "../../../../models/api";
import { ClientTotalSpend } from "../../../../models/client";
import { EventsDuration } from "../../../../models/event";
import { Member } from "../../../../models/project";
import { User } from "../../../../models/user";
import { StatusReferral } from "../../../../models/view/candidatePicker";

export const GET_PROJECT = "PROJECT_CLIENT_GET_PROJECT";
export const GET_PROJECT_SUCCESS = "PROJECT_CLIENT_GET_PROJECT_SUCCESS";
export const GET_PROJECT_FAILURE = "PROJECT_CLIENT_GET_PROJECT_FAILURE";

export const GET_PROJECT_BASE_DATA_SUCCESS =
  "PROJECT_CLIENT_GET_PROJECT_BASE_DATA_SUCCESS";
export const GET_PROJECT_CLIENT_DATA_SUCCESS =
  "PROJECT_CLIENT_GET_PROJECT_CLIENT_DATA_SUCCESS";

export const REFETCH_PROJECT_REFERRALS_DATA =
  "PROJECT_CLIENT_REFETCH_PROJECT_REFERRALS_DATA";
export const REFETCH_PROJECT_REFERRALS_DATA_SUCCESS =
  "PROJECT_CLIENT_REFETCH_PROJECT_REFERRALS_DATA_SUCCESS";
export const REFETCH_PROJECT_REFERRALS_DATA_FAILURE =
  "PROJECT_CLIENT_REFETCH_PROJECT_REFERRALS_DATA_FAILURE";

export const UPDATE_PROJECT_STATE = "PROJECT_CLIENT_UPDATE_PROJECT_STATE";
export const UPDATE_PROJECT_STATE_SUCCESS =
  "PROJECT_CLIENT_UPDATE_PROJECT_STATE_SUCCESS";
export const UPDATE_PROJECT_STATE_FAILURE =
  "PROJECT_CLIENT_UPDATE_PROJECT_STATE_FAILURE";

export const UPDATE_CANDIDATE_REFERRAL_STATUS =
  "PROJECT_CLIENT_UPDATE_CANDIDATE_REFERRAL_STATUS";

export const COMPLETE_CANDIDATE_REVIEWAL =
  "PROJECT_CLIENT_COMPLETE_CANDIDATE_REVIEWAL";

export const EXIT_FROM_PROJECT = "PROJECT_CLIENT_EXIT_FROM_PROJECT";

export const ADD_PROJECT_MEMBER = "PROJECT_CLIENT_ADD_PROJECT_MEMBER";
export const REMOVE_PROJECT_MEMBER = "PROJECT_CLIENT_REMOVE_PROJECT_MEMBER";

export const UPDATE_PROJECT_EVENT_DURATION =
  "PROJECT_CLIENT_UPDATE_PROJECT_EVENT_DURATION";

export const UPDATE_PROJECT_DETAILS = "PROJECT_CLIENT_UPDATE_PROJECT_DETAILS";

export const REFETCH_REFERRAL_SUMMARY =
  "PROJECT_CLIENT_REFETCH_REFERRAL_SUMMARY";
export const REFETCH_REFERRAL_SUMMARY_SUCCESS =
  "PROJECT_CLIENT_REFETCH_REFERRAL_SUMMARY_SUCCESS";
export const REFETCH_REFERRAL_SUMMARY_FAILURE =
  "PROJECT_CLIENT_REFETCH_REFERRAL_SUMMARY_FAILURE";

export interface GetProjectAction {
  type: typeof GET_PROJECT;
  payload: {
    projectId: string;
  };
}

export interface GetProjectSuccessAction {
  type: typeof GET_PROJECT_SUCCESS;
  payload: {
    projectName: string;
    projectId: string;
    modified: Date;
    projectState: string;
    hasCompliance: boolean;
    hasScreeningQuestions: boolean;
    clientId: string;
    invitationUrl?: string;
    timezone?: string;
    defaultEventGuests: User[];
  };
}

export interface GetProjectActionFailureAction {
  type: typeof GET_PROJECT_FAILURE;
  payload: {};
}

export interface GetProjectBaseDataSuccessAction {
  type: typeof GET_PROJECT_BASE_DATA_SUCCESS;
  payload: {
    members: Member[];
    eventsDuration: EventsDuration;
  };
}

export interface GetProjectClientDataSuccessAction {
  type: typeof GET_PROJECT_CLIENT_DATA_SUCCESS;
  payload: {
    referrals: StatusReferral[];
    referralSummary: ReferralSummary;
    totalSpend: ClientTotalSpend;
  };
}

export interface RefetchReferralDataAction {
  type: typeof REFETCH_PROJECT_REFERRALS_DATA;
  payload: {
    projectId: string;
  };
}

export interface RefetchReferralDataSuccessAction {
  type: typeof REFETCH_PROJECT_REFERRALS_DATA_SUCCESS;
  payload: {
    referrals: StatusReferral[];
  };
}

export interface RefetchReferralDataFailureAction {
  type: typeof REFETCH_PROJECT_REFERRALS_DATA_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

export interface UpdateProjectStateAction {
  type: typeof UPDATE_PROJECT_STATE;
  payload: {
    projectId: string;
    projectState: string;
  };
}

export interface UpdateProjectStateSuccessAction {
  type: typeof UPDATE_PROJECT_STATE_SUCCESS;
  payload: {
    projectState: string;
  };
}

export interface UpdateProjectStateFailureAction {
  type: typeof UPDATE_PROJECT_STATE_FAILURE;
  payload: {};
}

export interface ExitFromProjectAction {
  type: typeof EXIT_FROM_PROJECT;
  payload: {};
}

export interface CompleteCandidateReviewalAction {
  type: typeof COMPLETE_CANDIDATE_REVIEWAL;
  payload: {};
}

export interface UpdateCandidateReferralStatusAction {
  type: typeof UPDATE_CANDIDATE_REFERRAL_STATUS;
  payload: {
    referralId: string;
    status: REFERRAL_STATES;
  };
}

export interface AddProjectMemberAction {
  type: typeof ADD_PROJECT_MEMBER;
  payload: {
    member: Member;
  };
}

export interface RemoveProjectMemberSuccessAction {
  type: typeof REMOVE_PROJECT_MEMBER;
  payload: {
    memberId: string;
  };
}

export interface UpdateEventDurationAction {
  type: typeof UPDATE_PROJECT_EVENT_DURATION;
  payload: {
    eventStart: string;
  };
}

export interface UpdateProjectDetailsAction {
  type: typeof UPDATE_PROJECT_DETAILS;
  payload: {
    projectName: string;
    modifiedDate: Date;
    timezone?: string;
  };
}

export interface RefetchReferralSummaryAction {
  type: typeof REFETCH_REFERRAL_SUMMARY;
  payload: {
    projectId: string;
  };
}

export interface RefetchReferralSummarySuccessAction {
  type: typeof REFETCH_REFERRAL_SUMMARY_SUCCESS;
  payload: {
    referralSummary: ReferralSummary;
  };
}

export interface RefetchReferralSummaryFailureAction {
  type: typeof REFETCH_REFERRAL_SUMMARY_FAILURE;
  payload: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any;
  };
}
