import {
  ExpertDetail,
  Endorsement as LibsEndorsement,
  RateCard as LibsRateCard
} from "@arbolus-technologies/api";
import {
  COMPANY_TYPE,
  DO_NOT_CONTACT_STATUS,
  DateString
} from "@arbolus-technologies/models/common";
import { WorkHistory } from "@arbolus-technologies/models/expert";
import { Answer } from "@arbolus-technologies/models/project";

import { User } from "./user";

export interface SavedExpert {
  created: string; // ISO 8601 date string
  doNotContactStatus: DO_NOT_CONTACT_STATUS;
  email: string;
  experienceLevel: string;
  expertId: string;
  firstName: string;
  id: string;
  isoCountryCode: string;
  lastName: string;
  linkedInProfile: string;
  overview: string;
  phoneNumber: string;
  profileImageUrl: string;
  title: string;
}

interface Relevant {
  title: string | null;
  company: string | null;
  country: string | null;
}

export type Expert = ExpertDetail;

export type ExpertPackage = LibsRateCard;

export interface RateCard {
  packageName: string;
  hours: number;
  price: number;
  isoCurrencyCode: string;
  rateCardType: ExpertRateCardType;
}

export enum ExpertRateCardType {
  AdHoc = "AdHoc",
  Package = "Package"
}

export type ExpertWorkHistory = WorkHistory;

export type Endorsement = LibsEndorsement;
export interface ProjectAnswersResponse {
  version: number;
  items: Answer[];
}

export interface ExpertUpdateRequest {
  overview: string;
  background?: string;
  countries?: ExpertCountry[];
  industries?: ExpertIndustry[];
  tags?: [];
  linkedinProfile: string;
  experienceLevel: string;
  funFact: string;
  quickFacts: string[];
}

// For use of project questions
interface ProjectQuestion {
  id: string;
  question: string;
}

export interface ProjectQuestionsResponse {
  projectQuestionsUpdateUser: User;
  projectQuestionsUpdate: Date;
  items: ProjectQuestion[];
  version: number;
}

export interface SavedExpertStatusResponse {
  savedExpert: boolean;
}

export interface ExpertCountry {
  id: string;
  name: string;
  isoCountryCode: string;
}

export interface ExpertCompany {
  id: string;
  name: string;
  type?: COMPANY_TYPE;
}

export interface ExpertIndustry {
  id: string;
  name: string;
  naicsId: number;
  parentId?: string;
}
