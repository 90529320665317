import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { DiscoverFilterType } from "@arbolus-technologies/models/project";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";

import { useDiscoverFilters } from "../../../../Hooks/Discover/useDiscoverFilters";
import { useDiscoverProjectFilter } from "../../../../Hooks/Discover/useDiscoverProjectFilter";
import { FilterWrapper } from "../FilterWrapper/FilterWrapper";
import { SearchWithCheckboxPills } from "../SearchWithCheckboxPills/SearchWithCheckboxPills";

export const ProjectsFilter: React.FC = () => {
  const { t } = useTranslation("discoverPage");

  const {
    selectedOptions,
    setSelectedOptions,
    canClearFilter,
    setPreSelectedOptions,
    preSelectedOptions
  } = useDiscoverFilters({ filterType: DiscoverFilterType.Projects });
  const {
    handleBottomReached,
    handleClearQueryClicked,
    handleInputChangeEvent,
    handleSelectOption,
    filterOptions,
    applyProjectFilter,
    urlChangeOnProjectFilterUpdate,
    handleClearFilter
  } = useDiscoverProjectFilter();

  const projectFilterQuery = useSelector(ProjectNxSelector.filtersQuery())[
    DiscoverFilterType.Projects
  ];

  return (
    <FilterWrapper
      title={t("projectsFilterTitle")}
      canClearFilter={canClearFilter}
      onClear={handleClearFilter}
      tooltipText={t("projectsFilterDescription")}
      filterCounter={selectedOptions.length}
    >
      <SearchWithCheckboxPills
        onClearQueryClicked={handleClearQueryClicked}
        filterOptions={filterOptions}
        onInputChangeEvent={handleInputChangeEvent}
        searchQuery={projectFilterQuery}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        placeholder={t("projectsFilterPlaceholder")}
        onBottomReached={handleBottomReached}
        onSelectOption={handleSelectOption}
        disablePillToggle
        applyFilter={applyProjectFilter}
        preSelectedOptions={preSelectedOptions}
        setPreSelectedOptions={setPreSelectedOptions}
        errorText={t("maxNumberProjects")}
        urlChangeOnFilterUpdate={urlChangeOnProjectFilterUpdate}
      />
    </FilterWrapper>
  );
};
