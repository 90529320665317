import i18next from "i18next";

import {
  CustomerPersona,
  ImplementationStage,
  RenewalIntent
} from "@arbolus-technologies/api";

import { SurveyQuestionType } from "../../../../pages/models";

export const FIELD_NAMES = {
  PERSONA: "persona",
  IMPLEMENTATION_STAGE: "implementationStage",
  SPEND: "spend",
  NPS: "nps",
  RENEWAL_INTENT: "renewalIntent"
} as const;

const { PERSONA, IMPLEMENTATION_STAGE, SPEND, NPS, RENEWAL_INTENT } =
  FIELD_NAMES;

export const EXPERT_SURVEY_QUESTIONS = [
  {
    title: i18next.t("expertSurvey:relationshipQuestion"),
    fieldName: PERSONA,
    type: SurveyQuestionType.SingleChoiceRadio,
    options: [
      {
        value: CustomerPersona.User,
        label: i18next.t("expertSurvey:relationshipAnswerKeyUser")
      },
      {
        value: CustomerPersona.Influencer,
        label: i18next.t("expertSurvey:relationshipAnswerInfluencer")
      }
    ]
  },
  {
    title: i18next.t("expertSurvey:adoptionQuestion"),
    fieldName: IMPLEMENTATION_STAGE,
    type: SurveyQuestionType.SingleChoiceRadio,
    options: [
      {
        value: ImplementationStage.Pilot,
        label: i18next.t("expertSurvey:adoptionAnswerPilot")
      },
      {
        label: i18next.t("expertSurvey:adoptionAnswerLessThanYear"),
        value: ImplementationStage.LessThan1Year
      },
      {
        label: i18next.t("expertSurvey:adoptionAnswerMoreThanYear"),
        value: ImplementationStage.MoreThan1Year
      },
      {
        label: i18next.t("expertSurvey:adoptionAnswerOffBoarding"),
        value: ImplementationStage.OffBoarding
      }
    ]
  },
  {
    title: i18next.t("expertSurvey:spendQuestion"),
    fieldName: SPEND,
    type: SurveyQuestionType.MoneyTextInput
  },
  {
    title: i18next.t("expertSurvey:recommendQuestion"),
    fieldName: NPS,
    type: SurveyQuestionType.ScoreSelect
  },
  {
    title: i18next.t("expertSurvey:renewQuestion"),
    fieldName: RENEWAL_INTENT,
    type: SurveyQuestionType.SingleChoiceRadio,
    options: [
      {
        value: RenewalIntent.Yes,
        label: i18next.t("expertSurvey:renewAnswerYes")
      },
      {
        value: RenewalIntent.No,
        label: i18next.t("expertSurvey:renewAnswerNo")
      },
      {
        value: RenewalIntent.NotSure,
        label: i18next.t("expertSurvey:renewAnswerNotSure")
      }
    ]
  }
];
