import { CIQError, ErrorResponse } from "../../../../models/api";

export const AUTHENTICATE_FEDERATED_USER = "LOGIN_AUTHENTICATE_FEDERATED_USER";
export const AUTHENTICATE_USER_SUCCESS = "LOGIN_AUTHENTICATE_USER_SUCCESS";
export const AUTHENTICATE_USER_FAILURE = "LOGIN_AUTHENTICATE_USER_FAILURE";

export const LOGOUT_USER = "LOGIN_LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGIN_LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAILED = "LOGIN_LOGOUT_USER_FAILED";

export interface LogoutUserAction {
  type: typeof LOGOUT_USER;
  payload: {};
}

export interface LogoutUserSuccessAction {
  type: typeof LOGOUT_USER_SUCCESS;
  payload: {};
}

export interface LogoutUserFailedAction {
  type: typeof LOGOUT_USER_FAILED;
  payload: {};
}

export interface AuthenticateFederatedUserAction {
  type: typeof AUTHENTICATE_FEDERATED_USER;
  payload: {
    code: string;
    state: string;
    user?: string;
  };
}

export interface AuthenticateUserSuccessAction {
  type: typeof AUTHENTICATE_USER_SUCCESS;
  payload: {
    userId: string;
    roles: string[];
  };
}

export interface AuthenticateUserFailureAction {
  type: typeof AUTHENTICATE_USER_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}
