import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { IndividualResponse } from "@arbolus-technologies/features/common";
import { CanopyParamUrlIndividualResponse } from "@arbolus-technologies/models/canopy";
import {
  CANOPY_SINGLE_EXPERT_ROUTE,
  CANOPY_SINGLE_QUESTION_ROUTE,
  CANOPY_SUMMARY_ROUTE
} from "@arbolus-technologies/routes";
import { LinkNoBorder } from "@arbolus-technologies/ui/components";
import { useSingleQuery } from "@arbolus-technologies/utils";

import styles from "./CanopyIndividualResponse.module.scss";

export const CanopyIndividualResponse: React.FC = () => {
  const { t } = useTranslation("individualResponse");
  const { canopyId, answerId } = useParams<CanopyParamUrlIndividualResponse>();
  const from = useSingleQuery("from");
  const questionId = useSingleQuery("questionId");

  const [expertId, setExpertId] = useState<string>("");

  const handleBackRoute = () => {
    if (from === "question") {
      return CANOPY_SINGLE_QUESTION_ROUTE(canopyId, questionId ?? "");
    }
    if (from === "expert") {
      return CANOPY_SINGLE_EXPERT_ROUTE(canopyId, expertId);
    }
    if (from === "summary") {
      return CANOPY_SUMMARY_ROUTE(canopyId);
    }
    return CANOPY_SINGLE_EXPERT_ROUTE(canopyId, expertId);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <LinkNoBorder
          leftIconName="chevron_left"
          name={t("backTo", {
            from: from
          })}
          to={handleBackRoute()}
        />
      </div>
      <IndividualResponse
        setExpertId={(expertId: string) => setExpertId(expertId)}
        expertId={expertId}
        answerId={answerId}
      />
    </div>
  );
};
