import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import {
  CIQError,
  CanopyService,
  ErrorResponse,
  ToasterService
} from "@arbolus-technologies/api";
import { AnswerVideo } from "@arbolus-technologies/features/common";
import { CanopyParamUrlTypes } from "@arbolus-technologies/models/canopy";
import { VideoAnswer } from "@arbolus-technologies/models/canopy-panels";
import { CANOPY_INDIVIDUAL_RESPONSE_FROM_QUESTION_ROUTE } from "@arbolus-technologies/routes";
import { CanopyV2Selector } from "@arbolus-technologies/stores/canopy-V2";
import { InfiniteScrollV2 } from "@arbolus-technologies/ui/components";

interface QuestionVideoAnswersProps {
  toggleState: boolean;
  notificationService?: ToasterService;
  canopyService?: typeof CanopyService;
}

export const QuestionVideoAnswers: React.FC<QuestionVideoAnswersProps> = ({
  toggleState,
  notificationService = new ToasterService(),
  canopyService = CanopyService
}) => {
  const { t } = useTranslation("canopyV2");
  const { canopyQuestionId } = useParams<CanopyParamUrlTypes>();

  const [videoAnswers, setVideoAnswers] = useState<VideoAnswer[]>([]);
  const [playingVideoId, setPlayingVideoId] = useState<string>("");
  const [isMore, setIsMore] = useState<boolean>(false);
  const [isVideoAnswersLoading, setIsVideoAnswersLoading] =
    useState<boolean>(false);

  const canopyData = useSelector(CanopyV2Selector.canopyData());

  useEffect(() => {
    getQuestionVideoAnswers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getQuestionVideoAnswers = () => {
    setIsVideoAnswersLoading(true);

    canopyService
      .getCanopyQuestionVideoAnswers(canopyQuestionId, videoAnswers.length)
      .subscribe(
        ({ items, pagination }) => {
          const updatedVideoAnswers = videoAnswers.concat(items);
          setVideoAnswers(updatedVideoAnswers);
          setIsMore(
            items.length ? updatedVideoAnswers.length < pagination.count : false
          );
          setIsVideoAnswersLoading(false);
        },
        (error: ErrorResponse<CIQError>) => {
          setIsVideoAnswersLoading(false);
        }
      );
  };

  const handleBottomReached = () => {
    if (!isMore || isVideoAnswersLoading) {
      return;
    }

    getQuestionVideoAnswers();
  };

  const handleShareLink = (answerId: string, expertId?: string) => {
    notificationService.showInfo(t("copiedToClipboard"));

    if (canopyData) {
      navigator.clipboard.writeText(
        CANOPY_INDIVIDUAL_RESPONSE_FROM_QUESTION_ROUTE(
          canopyData.id,
          answerId,
          canopyQuestionId
        )
      );
    }
  };

  const renderVideoAnswer = (videoAnswer: VideoAnswer) => {
    const {
      answerId,
      created,
      downloadUrl,
      expert,
      isSmartTranscript,
      transcript,
      isBookmarked
    } = videoAnswer;

    const { expertId, profileImageUrl, fullName, title } = expert;
    return (
      <AnswerVideo
        key={answerId}
        answer={{
          id: answerId,
          transcript: transcript ?? "",
          created,
          downloadUrl,
          isSmartTranscript,
          isBookmarked
        }}
        expert={{
          expertId,
          fullName,
          profileImageUrl,
          title
        }}
        handleShareLink={handleShareLink}
        openAll={toggleState}
        playingVideoId={playingVideoId}
        setPlayingVideoId={setPlayingVideoId}
      />
    );
  };

  return (
    <InfiniteScrollV2
      onBottomReached={handleBottomReached}
      items={videoAnswers}
      renderer={renderVideoAnswer}
      isLoading={isVideoAnswersLoading}
    />
  );
};
