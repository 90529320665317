import { CIQError, ErrorResponse, ListCanopy } from "@arbolus-technologies/api";
import { PreviousPage } from "@arbolus-technologies/models/canopy";

export const GET_CANOPIES = "PROJECT_CANOPY_GET_CANOPIES";
export const GET_CANOPIES_SUCCESS = "PROJECT_CANOPY_GET_CANOPIES_SUCCESS";
export const GET_CANOPIES_FAILURE = "PROJECT_CANOPY_GET_CANOPIES_FAILURE";
export const SET_CANOPY_PREVIOUS_PAGE =
  "PROJECT_CANOPY_SET_CANOPY_PREVIOUS_PAGE";
export const ARCHIVE_CANOPY = "PROJECT_CANOPY_ARCHIVE_CANOPY";

export interface GetCanopiesAction {
  type: typeof GET_CANOPIES;
  payload: {
    projectId: string;
  };
}

export interface GetCanopiesSuccessAction {
  type: typeof GET_CANOPIES_SUCCESS;
  payload: {
    canopies: ListCanopy[];
  };
}

export interface GetCanopiesFailureAction {
  type: typeof GET_CANOPIES_FAILURE;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

export interface SetCanopyPreviousPageAction {
  type: typeof SET_CANOPY_PREVIOUS_PAGE;
  payload: {
    previousPage: PreviousPage;
  };
}

export interface ArchiveCanopyAction {
  type: typeof ARCHIVE_CANOPY;
  payload: {
    canopyId: string;
  };
}
