import React from "react";
import { useTranslation } from "react-i18next";

import { eyes } from "@arbolus-technologies/theme";

import { EmptyComponent } from "../EmptyComponent";

export const ExpertProjectEmptyCalendar: React.FC = () => {
  const { t } = useTranslation("expertProjectEmptyCalendar");

  return (
    <EmptyComponent
      title={t("emptyStateTitle")}
      subtitle={t("emptyStateSubtitle")}
      logo={eyes}
    />
  );
};
