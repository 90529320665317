/* eslint-disable @typescript-eslint/no-empty-function */
import clsx from "clsx";
import { push } from "connected-react-router";
import { Formik, FormikProps } from "formik";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Media from "react-media";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupAddon,
  Row
} from "reactstrap";
import { Dispatch } from "redux";
import { createStructuredSelector } from "reselect";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import SimpleBar from "simplebar-react";

import {
  LoggedInUser,
  SortCriteria,
  ToasterService
} from "@arbolus-technologies/api";
import { isSupportedFileType } from "@arbolus-technologies/features/common";
import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { Loader } from "@arbolus-technologies/ui/components";

import {
  APIConstants,
  ProjectRouteConstants,
  ValidationConstants
} from "../../../../constants";
import { APP_USER_ROLES, LISTING_TYPES } from "../../../../constants/app";
import {
  FILES_SORT_CRITERIA,
  MAXIMUM_FILES_UPLOAD_AT_ONCE,
  MAXIMUM_FILE_NAME_LENGTH,
  MAXIMUM_FILE_UPLOADING_SIZE,
  MAXIMUM_VIEWABLE_UPLOADING_FILES
} from "../../../../constants/files";
import { PROJECT_BASE } from "../../../../constants/navigation/projectRoutes";
import {
  DOCUMENT_UPLOAD_COMPLETE_TIMEOUT,
  SEARCH_DEBOUNCE_TIMEOUT
} from "../../../../constants/timer";
import {
  APP_DEVICE_MEDIA_QUERIES,
  UI_FILES_PAGE,
  UI_WINDOW_HEIGHT
} from "../../../../constants/ui";
import ContentPanelEvents, {
  ContentPanelEvent,
  ContentPanelEventType
} from "../../../../contexts/contentPanel/ContentPanelEvents";
import {
  NavBarContextConsumer,
  NavBarContextProps
} from "../../../../contexts/navBar/NavBarContext";
import {
  ApiPaginatedRequest,
  CIQError,
  ErrorResponse
} from "../../../../models/api";
import { Document } from "../../../../models/documents";
import { UploadingAttachment } from "../../../../models/view/documents";
import {
  DocumentService,
  RBServiceManager,
  UtilsService
} from "../../../../services";
import { AppAction } from "../../../../store/actions";
import { AppState } from "../../../../store/reducers";
import {
  CIQEmptyPlaceholder,
  CIQInfiniteScroll
} from "../../../app/components";
import { DocumentItem, UploadingDocument } from "../../components/documents";
import { ProjectSelector } from "../../store";

const notification = new ToasterService();

interface FilesPageStoreProps {
  projectId: string;
  projectName: string;
  loggedInUser: LoggedInUser;
}

interface FilesPageProps extends FilesPageStoreProps {
  navigateToBase: (tabRouteName: string) => void;
}

interface FilesPageState {
  isFilterDropDownOpen: boolean;
  documents: Document[];
  currentPage: number;
  hasMoreFiles: boolean;
  isMoreDataLoading: boolean;
  uploadingFiles: UploadingAttachment[];
}

interface NotesAndDocsFilterFormValues {
  searchQuery: string;
  sortCriteria: SortCriteria;
}

type FilesPageIntersectProps = FilesPageProps & WithTranslation;

class FilesPage extends React.Component<
  FilesPageIntersectProps,
  FilesPageState
> {
  static defaultProps = {
    projectId: "",
    isTabActive: false,
    navigateToBase: (): void => {},
    projectName: ""
  };

  constructor(props: FilesPageIntersectProps) {
    super(props);
    this.state = {
      isFilterDropDownOpen: false,
      documents: [],
      currentPage: 0,
      hasMoreFiles: false,
      isMoreDataLoading: true,
      uploadingFiles: []
    };
  }

  componentDidMount(): void {
    this.documentEventSubscription = ContentPanelEvents.contentPanelEventSubject
      .pipe(
        filter(({ eventType }) =>
          [
            ContentPanelEventType.DELETE_DOCUMENT,
            ContentPanelEventType.RENAME_DOCUMENT
          ].includes(eventType)
        ),
        filter(({ itemId }) => {
          const { documents } = this.state;
          return documents.filter((d) => d.id === itemId).length > 0;
        })
      )
      .subscribe(this.handleDocumentEvent);
  }

  componentWillUnmount(): void {
    this.searchQueryDebounceTimeout &&
      clearTimeout(this.searchQueryDebounceTimeout);

    this.documentsFetchSubscription?.unsubscribe();
    this.documentDownloadSubscription?.unsubscribe();
    this.documentEventSubscription?.unsubscribe();
    window.removeEventListener("beforeunload", this.handleBrowserCloseListener);
  }

  private formikRef: FormikProps<NotesAndDocsFilterFormValues> | null = null;

  private documentsFetchSubscription?: Subscription;

  private documentDownloadSubscription?: Subscription;

  private documentEventSubscription?: Subscription;

  private searchQueryDebounceTimeout: number | undefined;

  private initialElementLimit: number | undefined;

  fetchDocuments = (): void => {
    const { documents, currentPage } = this.state;
    const { projectId } = this.props;
    const nextOffset = documents.length;

    if (this.formikRef) {
      const { values } = this.formikRef;
      this.setState({ isMoreDataLoading: true });

      const documentsParams: ApiPaginatedRequest = {
        searchTerm: values.searchQuery,
        offset: nextOffset,
        orderBy: values.sortCriteria.value,
        orderDirection: values.sortCriteria.direction,
        limit:
          currentPage === 0
            ? this.initialElementLimit!
            : APIConstants.MAX_PAGE_SIZE
      };
      this.documentsFetchSubscription = RBServiceManager.serviceCaller(
        DocumentService.getClientDocuments(projectId, documentsParams),
        DocumentService.getExpertDocuments(projectId, documentsParams)
      ).subscribe(
        (documentsResponse) => {
          this.setState({
            documents: documents.concat(documentsResponse.items),
            hasMoreFiles: documents.length < documentsResponse.pagination.count,
            currentPage: currentPage + 1,
            isMoreDataLoading: false
          });
        },
        (err: ErrorResponse<CIQError>) => {
          this.setState({
            isMoreDataLoading: false
          });
          notification.showError(err.message);
        }
      );
    }
  };

  handleDocumentEvent = ({
    eventType,
    itemId,
    documentName
  }: ContentPanelEvent): void => {
    const { RENAME_DOCUMENT, DELETE_DOCUMENT } = ContentPanelEventType;

    if (eventType === RENAME_DOCUMENT) {
      this.setState((prevState) => ({
        documents: prevState.documents.map((d) => {
          const doc = d;
          if (d.id === itemId) {
            doc.fileName = documentName!;
          }
          return doc;
        })
      }));
    } else if (eventType === DELETE_DOCUMENT) {
      this.setState((prevState) => ({
        documents: prevState.documents.filter((d) => d.id !== itemId)
      }));
    }
  };

  handleBrowserCloseListener = (event: BeforeUnloadEvent): void => {
    event.preventDefault();
    // eslint-disable-next-line no-param-reassign
    event.returnValue = "";
  };

  handleUploadStateChanges = (): void => {
    const { uploadingFiles } = this.state;

    const isUploading = Boolean(uploadingFiles.length);

    if (isUploading) {
      window.addEventListener("beforeunload", this.handleBrowserCloseListener);
    } else {
      window.removeEventListener(
        "beforeunload",
        this.handleBrowserCloseListener
      );
    }
  };

  handleFilterStateChange = (): void => {
    if (this.formikRef && this.formikRef.isValid) {
      this.setState(
        {
          currentPage: 0,
          documents: [],
          isMoreDataLoading: true
        },
        () => this.fetchDocuments()
      );
    }
  };

  handleFilterFilesDropDownToggle = (): void => {
    const { isFilterDropDownOpen } = this.state;
    this.setState({
      isFilterDropDownOpen: !isFilterDropDownOpen
    });
  };

  handleFileSelected = (selectedFiles: FileList): void => {
    const { t } = this.props;
    const { uploadingFiles } = this.state;

    if (uploadingFiles.length < MAXIMUM_FILES_UPLOAD_AT_ONCE) {
      let newUploadFiles = Array.from(selectedFiles)
        .filter((file: File) => {
          // Validate file type
          const isSupported = isSupportedFileType(file);
          if (!isSupported) {
            notification.showError(t("unsupported"));
            return false;
          }

          // Validate file size
          if (file.size >= MAXIMUM_FILE_UPLOADING_SIZE) {
            notification.showError(
              t("fileSizeLimitError", {
                limit: MAXIMUM_FILE_UPLOADING_SIZE / 1024 ** 2
              })
            );
            return false;
          }

          // Validate file name
          const isFileNameTooLong =
            file.name.length >= MAXIMUM_FILE_NAME_LENGTH;
          if (isFileNameTooLong) {
            notification.showError(
              t("fileNameMaxLengthError", {
                length: MAXIMUM_FILE_NAME_LENGTH
              })
            );

            return false;
          }
          return true;
        })
        .map((file: File) => ({
          attachment: file,
          attachmentId: UtilsService.generateUUID()
        }));

      const availableUploadSlots =
        MAXIMUM_FILES_UPLOAD_AT_ONCE - uploadingFiles.length;
      if (availableUploadSlots < newUploadFiles.length) {
        notification.showError(t("fileCountLimitError"));
      }

      newUploadFiles.splice(
        MAXIMUM_FILES_UPLOAD_AT_ONCE - uploadingFiles.length
      );

      newUploadFiles = uploadingFiles.concat(newUploadFiles);

      this.setState(
        {
          uploadingFiles: newUploadFiles
        },
        () => this.handleUploadStateChanges()
      );
    }
  };

  handleOnFinishUploading = (
    file: UploadingAttachment,
    uploadedDocument: Document
  ): void => {
    setTimeout(() => {
      const { uploadingFiles, documents } = this.state;
      this.setState(
        {
          uploadingFiles: uploadingFiles.filter(
            (f) => f.attachmentId !== file.attachmentId
          ),
          documents: [uploadedDocument, ...documents]
        },
        () => this.handleUploadStateChanges()
      );
    }, DOCUMENT_UPLOAD_COMPLETE_TIMEOUT);
  };

  handleUploadInterrupted = (file: UploadingAttachment): void => {
    const { uploadingFiles } = this.state;
    this.setState({
      uploadingFiles: uploadingFiles.filter(
        (f) => f.attachmentId !== file.attachmentId
      )
    });
  };

  handleDocumentsBottomReached = (): void => {
    const { hasMoreFiles, isMoreDataLoading } = this.state;

    if (hasMoreFiles && !isMoreDataLoading) {
      this.fetchDocuments();
    }
  };

  handleInitialFetch = (elementCount?: number): void => {
    this.initialElementLimit = elementCount;
    this.fetchDocuments();
  };

  handleProjectNameClicked = (): void => {
    const { navigateToBase, projectId } = this.props;
    const nextTabRoute = ProjectRouteConstants.PROJECT_TAB_ROUTES[PROJECT_BASE];
    navigateToBase(nextTabRoute.route(projectId));
  };

  handleUploadDocument = (): void => {
    const docSelectInput = document.getElementById("upload_doc_input");
    docSelectInput?.click();
  };

  renderSearchAndFilterForm = ({
    values,
    setFieldValue
  }: FormikProps<NotesAndDocsFilterFormValues>): JSX.Element => {
    const { isFilterDropDownOpen, documents } = this.state;
    const { t } = this.props;

    const isDocumentsAvailable = Boolean(documents.length);

    const handleSortFilterChange = (sortCriteria: SortCriteria): void => {
      setFieldValue("sortCriteria", sortCriteria);
    };

    const handleQueryChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ): void => {
      const searchQuery = event.target.value;
      if (
        searchQuery === "" ||
        searchQuery.length >= ValidationConstants.MINIMUM_SEARCH_TERM_LENGTH
      ) {
        const nextQuery = event.target.value;
        if (this.searchQueryDebounceTimeout)
          clearTimeout(this.searchQueryDebounceTimeout);

        this.searchQueryDebounceTimeout = setTimeout(() => {
          setFieldValue("searchQuery", nextQuery);
        }, SEARCH_DEBOUNCE_TIMEOUT);
      }
    };

    return (
      <Row className="search-filter-bar mr-0 ml-0">
        <Col className="search-column p-0">
          <InputGroup className="search-input-white">
            <InputGroupAddon addonType="prepend">
              <span className="ciq-icon ciq-search" />
            </InputGroupAddon>
            <Input
              autoComplete="off"
              type="search"
              placeholder={t("searchNotesAndDocs")}
              name="searchQuery"
              onChange={handleQueryChange}
            />
          </InputGroup>
        </Col>
        <Col className="filter-column p-0 d-none d-sm-flex">
          <Dropdown
            isOpen={isFilterDropDownOpen && isDocumentsAvailable}
            toggle={this.handleFilterFilesDropDownToggle}
            className={clsx("d-none d-sm-flex", {
              disabled: !isDocumentsAvailable
            })}
          >
            <DropdownToggle caret>
              {t("sortBy")} {values.sortCriteria.name}
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={(): void =>
                  handleSortFilterChange(FILES_SORT_CRITERIA.Name)
                }
              >
                {t("aToz")}
              </DropdownItem>
              <DropdownItem
                onClick={(): void =>
                  handleSortFilterChange(FILES_SORT_CRITERIA.NameReverse)
                }
              >
                {t("zToa")}
              </DropdownItem>
              <DropdownItem
                onClick={(): void =>
                  handleSortFilterChange(FILES_SORT_CRITERIA.DATE)
                }
              >
                {t("date")}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Col>
      </Row>
    );
  };

  renderEmptyStates = (isExpertDnc: boolean): JSX.Element => {
    const { t } = this.props;

    const isSearchResult =
      this.formikRef && this.formikRef?.values.searchQuery !== "";

    const emptyResultTitle = isSearchResult
      ? t("noSearchResultTitle")
      : t("noDocs");

    const clientEmptyDescription = isSearchResult
      ? t("noSearchResultDescription")
      : t("noDocsDescription");

    const clientAdminEmptyDescription = isSearchResult
      ? t("noSearchResultDescription")
      : t("noDocsDescription");

    const expertEmptyDescription = isSearchResult
      ? t("noSearchResultDescription")
      : t("expertNoDocsDescription");

    const description = new Map([
      [APP_USER_ROLES.client, clientEmptyDescription],
      [APP_USER_ROLES.expert, expertEmptyDescription],
      [APP_USER_ROLES.adminClient, clientAdminEmptyDescription]
    ]);

    return (
      <CIQEmptyPlaceholder
        title={emptyResultTitle}
        roleDescription={description}
        itemType={LISTING_TYPES.DOCUMENTS}
        buttonText={isExpertDnc ? "" : t("uploadDocument")}
        buttonVisibleRoles={[
          APP_USER_ROLES.client,
          APP_USER_ROLES.expert,
          APP_USER_ROLES.adminClient
        ]}
        onButtonClick={this.handleUploadDocument}
      />
    );
  };

  renderNotesAndDocsList = (
    isExpertDnc: boolean
  ): JSX.Element[] | JSX.Element => {
    const { documents, isMoreDataLoading, uploadingFiles } = this.state;
    const { projectId } = this.props;

    if (
      isExpertDnc ||
      (documents.length === 0 &&
        uploadingFiles.length === 0 &&
        !isMoreDataLoading)
    ) {
      return this.renderEmptyStates(isExpertDnc);
    }

    return documents.map((document: Document, index: number) => (
      // eslint-disable-next-line react/no-array-index-key
      <React.Fragment key={document.id}>
        <DocumentItem
          key={document.id}
          document={document}
          projectId={projectId}
        />
      </React.Fragment>
    ));
  };

  renderUploadingNotesAndDocsList = (): JSX.Element[] => {
    const { uploadingFiles } = this.state;
    const { projectId } = this.props;

    return uploadingFiles.map((file) => (
      <UploadingDocument
        document={file}
        projectId={projectId}
        onUploadStop={this.handleUploadInterrupted}
        key={file.attachmentId}
        handleOnFinishUploading={this.handleOnFinishUploading}
      />
    ));
  };

  render(): JSX.Element {
    const { isMoreDataLoading, uploadingFiles } = this.state;

    const { projectName, t, loggedInUser } = this.props;
    const isUploading = uploadingFiles.length > 0;
    const uploadingCount = uploadingFiles.length;

    const uploadingElementHeight =
      (isUploading
        ? UI_FILES_PAGE.SINGLE_UPLOADING_ELEMENT_MARGIN_WHILE_UPLOADING
        : 0) +
      UI_FILES_PAGE.SINGLE_UPLOADING_ELEMENT_HEIGHT *
        (uploadingCount < MAXIMUM_VIEWABLE_UPLOADING_FILES
          ? uploadingCount
          : MAXIMUM_VIEWABLE_UPLOADING_FILES);

    const isLoggedInUserExpert = !!loggedInUser.expertId;
    const isExpertDnc =
      isLoggedInUserExpert &&
      loggedInUser.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC;

    const isUploadDisabled =
      uploadingFiles.length >= MAXIMUM_FILES_UPLOAD_AT_ONCE;

    return (
      <Media queries={APP_DEVICE_MEDIA_QUERIES}>
        {(matches): JSX.Element => (
          <NavBarContextConsumer>
            {({ isProjectActionsActive }: NavBarContextProps): JSX.Element => {
              const actualNotesContainerHeight =
                UI_FILES_PAGE.CONTAINER_EXCESS_HEIGHT(matches) +
                uploadingElementHeight +
                (isProjectActionsActive && !matches.large
                  ? UI_FILES_PAGE.HEADER_HEIGHT(matches)
                  : 0);

              return (
                <div className="files-page-body page-content-body">
                  <div className="files-container m-0 row">
                    <Row className="header-container m-0">
                      <div className="left-container">
                        <h3 onClick={this.handleProjectNameClicked}>
                          {projectName}
                        </h3>
                        <h1>{t("notes&Docs")}</h1>
                      </div>
                      {!isExpertDnc && (
                        <div className="right-container">
                          <div className="form-file-upload">
                            <Button
                              size="sm"
                              color="primary"
                              disabled={isUploadDisabled}
                            >
                              <i className="ciq-icon ciq-plus" /> {t("upload")}
                            </Button>
                            <Input
                              id="upload_doc_input"
                              disabled={isUploadDisabled}
                              type="file"
                              multiple
                              onChange={({
                                target
                              }: React.ChangeEvent<HTMLInputElement>): void => {
                                if (target.files && target.files?.length > 0) {
                                  this.handleFileSelected(target.files);
                                }

                                // eslint-disable-next-line no-param-reassign
                                target.value = "";
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </Row>
                  </div>

                  <div className="files-body">
                    <Formik<NotesAndDocsFilterFormValues>
                      initialValues={{
                        searchQuery: "",
                        sortCriteria: FILES_SORT_CRITERIA.DATE
                      }}
                      innerRef={(ref): void => {
                        this.formikRef = ref;
                      }}
                      validate={this.handleFilterStateChange}
                      validateOnChange
                      onSubmit={(): void => {}}
                    >
                      {this.renderSearchAndFilterForm}
                    </Formik>
                    <div className="file-list-container">
                      {isUploading && (
                        <SimpleBar
                          className="uploading-files-container simplebar-light"
                          style={{
                            maxHeight: uploadingElementHeight,
                            overflowX: "hidden"
                          }}
                        >
                          {this.renderUploadingNotesAndDocsList()}
                        </SimpleBar>
                      )}

                      <CIQInfiniteScroll
                        className="uploaded-files-container simplebar-light"
                        style={{
                          maxHeight: `calc(${UI_WINDOW_HEIGHT} - ${actualNotesContainerHeight}px)`,
                          height: `calc(${UI_WINDOW_HEIGHT} - ${actualNotesContainerHeight}px)`,
                          overflowX: "hidden"
                        }}
                        onBottomReached={this.handleDocumentsBottomReached}
                        elementHeight={UI_FILES_PAGE.SINGLE_FILE_ELEMENT_HEIGHT(
                          matches
                        )}
                        onInitialFetch={this.handleInitialFetch}
                      >
                        {this.renderNotesAndDocsList(isExpertDnc)}
                        {isMoreDataLoading && <Loader />}
                      </CIQInfiniteScroll>
                    </div>
                  </div>
                </div>
              );
            }}
          </NavBarContextConsumer>
        )}
      </Media>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): Record<string, AppAction> => ({
  navigateToBase: (tabRouteName: string): AppAction => {
    dispatch(push(tabRouteName));
  }
});

const mapStateToProps = createStructuredSelector<
  AppState,
  FilesPageProps,
  FilesPageStoreProps
>({
  projectId: ProjectSelector.projectIdSelector(),
  projectName: ProjectSelector.projectNameSelector(),
  loggedInUser: CacheSelector.loggedInUser()
});

export default withTranslation("filesPage")(
  connect(mapStateToProps, mapDispatchToProps)(FilesPage)
);
