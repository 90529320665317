import {
  CIQError,
  CanopyExpertBaseAnswer,
  CanopyQuestion,
  ErrorResponse,
  ExpertCanopy
} from "@arbolus-technologies/api";
import { VIDEO_MODE_ENUM } from "@arbolus-technologies/models/canopy";

export const CANOPY_RESET_CAMERA = "CANOPY_EXPERT_CANOPY_RESET_CAMERA";
export const SET_CANOPY_VIDEO = "CANOPY_EXPERT_SET_CANOPY_VIDEO";

export const RECORDING_VIDEO = "CANOPY_EXPERT_SET_RECORDING_VIDEO";
export const RECORD_QUESTION = "CANOPY_EXPERT_RECORD_QUESTION";

export const UPLOAD_CANOPY_VIDEO = "CANOPY_EXPERT_UPLOAD_CANOPY_VIDEO";
export const UPLOAD_CANOPY_VIDEO_SUCCESS =
  "CANOPY_EXPERT_UPLOAD_CANOPY_VIDEO_SUCCESS";
export const UPLOAD_CANOPY_VIDEO_ERROR =
  "CANOPY_EXPERT_UPLOAD_CANOPY_VIDEO_ERROR";

export const GET_CANOPY_ANSWER_DATA = "CANOPY_EXPERT_GET_CANOPY_ANSWER_DATA";
export const GET_CANOPY_ANSWER_DATA_SUCCESS =
  "CANOPY_EXPERT_GET_CANOPY_ANSWER_DATA_SUCCESS";
export const GET_CANOPY_ANSWER_DATA_FAILURE =
  "CANOPY_EXPERT_GET_CANOPY_ANSWER_DATA_FAILURE";

export const RETAKE_VIDEO = "CANOPY_EXPERT_RETAKE_VIDEO";

export const DELETE_AND_UPLOAD_VIDEO = "CANOPY_EXPERT_DELETE_AND_UPLOAD_VIDEO";
export const DELETE_AND_UPLOAD_VIDEO_FAILURE =
  "CANOPY_EXPERT_DELETE_AND_UPLOAD_VIDEO_FAILURE";

export const PLAYING_VIDEO = "CANOPY_EXPERT_PLAYING_VIDEO";

export const VIDEO_MODE = "CANOPY_EXPERT_VIDEO_MODE";

export const SEND_ANSWERS = "CANOPY_EXPERT_SEND_ANSWERS";
export const SEND_ANSWERS_SUCCESS = "CANOPY_EXPERT_SEND_ANSWERS_SUCCESS";
export const SEND_ANSWERS_ERROR = "CANOPY_EXPERT_SEND_ANSWERS_ERROR";
export const RESET_MOBILE = "CANOPY_EXPERT_RESET_MOBILE";

export const GET_CANOPY_EXPERT_QUESTIONS =
  "CANOPY_EXPERT_GET_CANOPY_EXPERT_QUESTIONS";
export const GET_CANOPY_EXPERT_QUESTIONS_SUCCESS =
  "CANOPY_EXPERT_GET_CANOPY_EXPERT_QUESTIONS_SUCCESS";
export const GET_CANOPY_EXPERT_QUESTIONS_FAILURE =
  "CANOPY_EXPERT_GET_CANOPY_EXPERT_QUESTIONS_FAILURE";

export const GET_CANOPY_EXPERT = "CANOPY_EXPERT_GET_CANOPY_EXPERT";
export const GET_CANOPY_EXPERT_SUCCESS =
  "CANOPY_EXPERT_GET_CANOPY_EXPERT_SUCCESS";
export const GET_CANOPY_EXPERT_FAILURE =
  "CANOPY_EXPERT_GET_CANOPY_EXPERT_FAILURE";

export const ADD_RETRY = "CANOPY_EXPERT_ADD_RETRY";

export const ASSIGN_EXPERT_TO_CANOPY = "CANOPY_EXPERT_ASSIGN_EXPERT_TO_CANOPY";
export const ASSIGN_EXPERT_TO_CANOPY_SUCCESS =
  "CANOPY_EXPERT_ASSIGN_EXPERT_TO_CANOPY_SUCCESS";
export const ASSIGN_EXPERT_TO_CANOPY_FAILURE =
  "CANOPY_EXPERT_ASSIGN_EXPERT_TO_CANOPY_FAILURE";
export const RESET_CANOPY_ANSWER_DATA =
  "CANOPY_EXPERT_RESET_CANOPY_ANSWER_DATA";
export const SHOW_RIGHT_COMPONENT = "CANOPY_EXPERT_SHOW_RIGHT_COMPONENT";

export const ADD_EXPERT_CANOPY_AGREEMENT =
  "CANOPY_EXPERT_ADD_EXPERT_CANOPY_AGREEMENT";
export const ADD_EXPERT_CANOPY_AGREEMENT_SUCCESS =
  "CANOPY_EXPERT_ADD_EXPERT_CANOPY_AGREEMENT_SUCCESS";
export const ADD_EXPERT_CANOPY_AGREEMENT_FAILURE =
  "CANOPY_EXPERT_ADD_EXPERT_CANOPY_AGREEMENT_FAILURE";

export const ENABLE_EXPERT_QUESTIONS = "ENABLE_EXPERT_QUESTIONS";

export const MARK_QUESTION_AS_ANSWERED = "MARK_QUESTION_AS_ANSWERED";

// Get canopy
export interface GetCanopyExpertAction {
  type: typeof GET_CANOPY_EXPERT;
  payload: {
    canopyId: string;
    expertId: string;
  };
}

export interface GetCanopyExpertSuccessAction {
  type: typeof GET_CANOPY_EXPERT_SUCCESS;
  payload: {
    canopyData: ExpertCanopy;
  };
}

export interface GetCanopyExpertFailureAction {
  type: typeof GET_CANOPY_EXPERT_FAILURE;
  error: ErrorResponse<CIQError>;
}

// Assign expert to canopy
export interface AssignExpertToCanopyAction {
  type: typeof ASSIGN_EXPERT_TO_CANOPY;
  payload: {
    expertId: string;
    canopyId: string;
  };
}

export interface AssignExpertToCanopySuccessAction {
  type: typeof ASSIGN_EXPERT_TO_CANOPY_SUCCESS;
}

export interface AssignExpertToCanopyFailureAction {
  type: typeof ASSIGN_EXPERT_TO_CANOPY_FAILURE;
  error: ErrorResponse<CIQError>;
}

// ADD RETRY
export interface AddRetryAction {
  type: typeof ADD_RETRY;
  payload: {
    addRetry: number;
  };
}

export interface GetCanopyExpertQuestionsAction {
  type: typeof GET_CANOPY_EXPERT_QUESTIONS;
  payload: {
    canopyId: string;
    expertId?: string;
  };
}

export interface GetCanopyExpertQuestionsSuccessAction {
  type: typeof GET_CANOPY_EXPERT_QUESTIONS_SUCCESS;
  payload: {
    canopyQuestions: CanopyQuestion[];
  };
}

export interface GetCanopyExpertQuestionsFailureAction {
  type: typeof GET_CANOPY_EXPERT_QUESTIONS_FAILURE;
  error: ErrorResponse<CIQError>;
}

// Reset Camera

export interface CanopyCameraResetAction {
  type: typeof CANOPY_RESET_CAMERA;
}

// SET CANOPY VIDEO

export interface SetCanopyVideoAction {
  type: typeof SET_CANOPY_VIDEO;
  payload: {
    videoURL: string | null;
    videoBlob: Blob | null;
    isVideoSaving: boolean;
    isVideoUploaded: boolean;
    time: number;
    supportedVideoType: "webm" | "mp4" | "mpeg" | "quicktime" | null;
  };
}

// RECORDING VIDEO
export interface RecordingVideoAction {
  type: typeof RECORDING_VIDEO;
  payload: {
    isVideoRecording: boolean;
  };
}
// PLAYING VIDEO
export interface PlayingVideoAction {
  type: typeof PLAYING_VIDEO;
  payload: {
    isVideoPlaying: boolean;
  };
}

export interface RecordQuestionAction {
  type: typeof RECORD_QUESTION;
  payload: {
    question: CanopyQuestion | null;
  };
}

// UPLOAD CANOPY VIDEO
export interface UploadCanopyVideoAction {
  type: typeof UPLOAD_CANOPY_VIDEO;
  payload: {
    questionId: string;
    videoBlob: FormData;
  };
}

// UPLOAD CANOPY VIDEO SUCCESS
export interface UploadCanopyVideoSuccessAction {
  type: typeof UPLOAD_CANOPY_VIDEO_SUCCESS;
  payload: {
    questionId: string;
    answerId: string;
  };
}

// UPLOAD CANOPY VIDEO ERROR
export interface UploadCanopyVideoErrorAction {
  type: typeof UPLOAD_CANOPY_VIDEO_ERROR;
  payload: {
    error: ErrorResponse<CIQError>;
  };
}

// GET CANOPY ANSWER DATA

export interface GetCanopyAnswerDataAction {
  type: typeof GET_CANOPY_ANSWER_DATA;
  payload: {
    questionId: string;
    expertId: string;
  };
}

export interface GetCanopyAnswerDataSuccessAction {
  type: typeof GET_CANOPY_ANSWER_DATA_SUCCESS;
  payload: {
    canopyAnswerData: CanopyExpertBaseAnswer;
  };
}

export interface GetCanopyAnswerDataFailureAction {
  type: typeof GET_CANOPY_ANSWER_DATA_FAILURE;
  error: ErrorResponse<CIQError>;
}

export interface RetakeVideoAction {
  type: typeof RETAKE_VIDEO;
}

// DELETE AND UPLOAD VIDEO
export interface DeleteAndUploadVideoAction {
  type: typeof DELETE_AND_UPLOAD_VIDEO;
  payload: {
    answerId: string;
    questionId: string;
    videoFormData: FormData;
  };
}

export interface DeleteAndUploadVideoFailureAction {
  type: typeof DELETE_AND_UPLOAD_VIDEO_FAILURE;
  error: ErrorResponse<CIQError>;
}

export interface VideoModeAction {
  type: typeof VIDEO_MODE;
  payload: {
    mode: VIDEO_MODE_ENUM;
  };
}

export interface SendCanopyAnswersAction {
  type: typeof SEND_ANSWERS;
  payload: {
    canopyId: string;
    expertId: string;
  };
}

export interface SendCanopyAnswersSuccessAction {
  type: typeof SEND_ANSWERS_SUCCESS;
}

export interface SendCanopyAnswersErrorAction {
  type: typeof SEND_ANSWERS_ERROR;
  error: ErrorResponse<CIQError>;
}

export interface ResetMobileAction {
  type: typeof RESET_MOBILE;
}

export interface ResetCanopyAnswerDataAction {
  type: typeof RESET_CANOPY_ANSWER_DATA;
}

export interface ShowRightComponentAction {
  type: typeof SHOW_RIGHT_COMPONENT;
  payload: {
    showRightComponent: boolean;
  };
}

// Add expert Canopy Engagement Agreement
export interface AddExpertCanopyAgreementAction {
  type: typeof ADD_EXPERT_CANOPY_AGREEMENT;
  payload: {
    canopyId: string;
    expertId: string;
  };
}

export interface AddExpertCanopyAgreementSuccessAction {
  type: typeof ADD_EXPERT_CANOPY_AGREEMENT_SUCCESS;
}

export interface AddExpertCanopyAgreementFailureAction {
  type: typeof ADD_EXPERT_CANOPY_AGREEMENT_FAILURE;
  error: ErrorResponse<CIQError>;
}

export interface EnableExpertQuestionsAction {
  type: typeof ENABLE_EXPERT_QUESTIONS;
  payload: { enabled: boolean };
}

export interface MarkQuestionAsAnsweredAction {
  type: typeof MARK_QUESTION_AS_ANSWERED;
  payload: {
    questionId: string;
  };
}
