import { ActionCreator } from "redux";

import { UserNotificationSettings } from "../../../../models/user";
import {
  UPDATE_USER_NOTIFICATION_SETTINGS,
  UpdateUserNotificationSettingsAction
} from "./UserActionTypes";

export const updateUserNotificationSettings: ActionCreator<
  UpdateUserNotificationSettingsAction
> = (userNotificationSettings: UserNotificationSettings) => ({
  type: UPDATE_USER_NOTIFICATION_SETTINGS,
  payload: {
    userNotificationSettings
  }
});
