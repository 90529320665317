import React from "react";

import {
  AdminService,
  ListEvent,
  SORT_DIRECTION
} from "@arbolus-technologies/api";

import { EventSelector, EventSelectorProps } from "./EventSelector";

interface EventAdminSelectorProps
  extends Omit<EventSelectorProps<ListEvent>, "getItems"> {
  projectId: string;
}

export const EventAdminSelector: React.FC<EventAdminSelectorProps> = ({
  projectId,
  ...props
}) => (
  <EventSelector
    {...props}
    getItems={(pagination) =>
      AdminService.getProjectEvents(
        projectId,
        pagination.searchTerm,
        "Title",
        pagination.offset,
        pagination.limit,
        SORT_DIRECTION.ASCENDING
      )
    }
  />
);
