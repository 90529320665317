import React from "react";
import { useSelector } from "react-redux";

import { PaginatedResponseWithStatus } from "@arbolus-technologies/api";
import { AngleModel } from "@arbolus-technologies/models/common";
import { DiscoverExpert } from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

import { ExpertListWithCheckboxes } from "./ExpertListWithCheckboxes";
import { ExpertListWithoutCheckboxes } from "./ExpertListWithoutCheckboxes";

interface ExpertListContainerProps {
  handleSelectExpert: (expertId: string) => void;
  expertListPaginated: PaginatedResponseWithStatus<DiscoverExpert>;
  selectedExpertsIds: string[];
  shortlistedExpertsIds: string[];
  projectId: string;
  projectName: string;
  angles: AngleModel[];
}

export const ExpertListContainer: React.FC<ExpertListContainerProps> = ({
  expertListPaginated,
  handleSelectExpert,
  selectedExpertsIds,
  shortlistedExpertsIds,
  projectId,
  projectName,
  angles
}) => {
  const isClient = useSelector(CacheSelector.isCurrentUserClientSelector());

  if (isClient) {
    return (
      <ExpertListWithoutCheckboxes
        expertListPaginated={expertListPaginated}
        projectId={projectId}
        projectName={projectName}
        angles={angles}
      />
    );
  }

  return (
    <ExpertListWithCheckboxes
      expertListPaginated={expertListPaginated}
      handleSelectExpert={handleSelectExpert}
      projectId={projectId}
      projectName={projectName}
      selectedExpertsIds={selectedExpertsIds}
      shortlistedExpertsIds={shortlistedExpertsIds}
      angles={angles}
    />
  );
};
