import { OutputSelector, createSelector } from "reselect";

import {
  BookmarkCollection,
  PaginatedStoreHasNextPage
} from "@arbolus-technologies/models/common";

import { BookmarkCollectionsStateSelector } from "../BookmarkCollectionsSelector";
import {
  BookmarkCollectionsAppState,
  BookmarkCollectionsReducerState
} from "../models/definitions";

export const collectionList = (): OutputSelector<
  BookmarkCollectionsAppState,
  BookmarkCollection[] | null,
  (res: BookmarkCollectionsReducerState) => BookmarkCollection[] | null
> =>
  createSelector<
    BookmarkCollectionsAppState,
    BookmarkCollectionsReducerState,
    BookmarkCollection[] | null
  >(
    BookmarkCollectionsStateSelector,
    (bookmarkCollectionsReducerState) =>
      bookmarkCollectionsReducerState.collections.store?.items || null
  );

export const collectionListHasNextPage = (): OutputSelector<
  BookmarkCollectionsAppState,
  boolean,
  (res: BookmarkCollectionsReducerState) => boolean
> =>
  createSelector<
    BookmarkCollectionsAppState,
    BookmarkCollectionsReducerState,
    boolean
  >(BookmarkCollectionsStateSelector, (bookmarkCollectionsReducerState) =>
    PaginatedStoreHasNextPage(bookmarkCollectionsReducerState.collections.store)
  );

export const collectionListSearchTerm = (): OutputSelector<
  BookmarkCollectionsAppState,
  string,
  (res: BookmarkCollectionsReducerState) => string
> =>
  createSelector<
    BookmarkCollectionsAppState,
    BookmarkCollectionsReducerState,
    string
  >(
    BookmarkCollectionsStateSelector,
    (bookmarkCollectionsReducerState) =>
      bookmarkCollectionsReducerState.collections.searchTerm || ""
  );

export const collectionListIsLoading = (): OutputSelector<
  BookmarkCollectionsAppState,
  boolean,
  (res: BookmarkCollectionsReducerState) => boolean
> =>
  createSelector<
    BookmarkCollectionsAppState,
    BookmarkCollectionsReducerState,
    boolean
  >(
    BookmarkCollectionsStateSelector,
    (bookmarkCollectionsReducerState) =>
      bookmarkCollectionsReducerState.collections.isLoading
  );
