import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { PROJECT_ROUTE } from "@arbolus-technologies/routes";

import { useChat } from "../../../Contexts/ChatContext/ChatContext";

import alertIcon from "./alert.png";

import styles from "./EmptyChatGuard.module.scss";

interface EmptyChatGuardProps {
  children: React.ReactNode;
}

export function EmptyChatGuard({ children }: EmptyChatGuardProps) {
  const { projectId, chatsByAngle } = useChat();
  const { push } = useHistory();
  const { t } = useTranslation("chat");

  const hasChats = Object.keys(chatsByAngle).length > 0;
  if (hasChats) return <>{children}</>;

  return (
    <div className={styles.noChats}>
      <img className={styles.warningIcon} src={alertIcon} alt="" />
      <h2>{t("chatGuardTitle")}</h2>
      <p className={styles.chatGuardDescription}>{t("chatGuardDescription")}</p>
      <Button
        text={t("chatGuardBackButton")}
        onClick={() => push(PROJECT_ROUTE(projectId!))}
      />
    </div>
  );
}
