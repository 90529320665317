import dompurify from "dompurify";
import React from "react";
import { useTranslation } from "react-i18next";

import { PROJECT_STATUS, WorkHistory } from "@arbolus-technologies/api";
import { Drawer } from "@arbolus-technologies/ui/components";

import { ProjectsApplicationDrawer } from "./ProjectsApplicationDrawer/ProjectsApplicationDrawer";
import { WorkHistoryDrawerTitle } from "./WorkHistoryDrawer/WorkHistoryDrawerTitle";

import styles from "./ExpertDrawerContainer.module.scss";

export interface ExpertDrawerContainerProps {
  workHistories: WorkHistory[];
  background?: string;
  overview?: string;
  expertId?: string;
  displayPublicCompanies: boolean;
}

export const ExpertDrawerContainer: React.FC<ExpertDrawerContainerProps> = ({
  overview,
  background,
  workHistories,
  expertId,
  displayPublicCompanies
}) => {
  const { t } = useTranslation("expertDrawer");

  return (
    <div className={styles.drawersContainer}>
      {expertId && (
        <ProjectsApplicationDrawer
          expertId={expertId}
          projectStatus={[PROJECT_STATUS.ACTIVE]}
          title={t("activeProjects")}
        />
      )}
      {overview && (
        <Drawer title={t("introduction")} openByDefault>
          <div
            className={styles.introduction}
            dangerouslySetInnerHTML={{
              __html: dompurify.sanitize(overview)
            }}
          />
        </Drawer>
      )}
      {background && (
        <Drawer title={t("background")}>
          <div
            className={styles.background}
            dangerouslySetInnerHTML={{
              __html: dompurify.sanitize(background)
            }}
          />
        </Drawer>
      )}
      {workHistories.map((workItem) => (
        <Drawer
          key={workItem.id}
          titleChildren={() => (
            <WorkHistoryDrawerTitle
              workItem={workItem}
              displayPublicCompanies={displayPublicCompanies}
            />
          )}
          data-testid="workHistory"
        >
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: dompurify.sanitize(
                workItem.jobDescription
                  ? workItem.jobDescription
                  : t("descriptionNotAvailable")
              )
            }}
          />
        </Drawer>
      ))}
    </div>
  );
};
