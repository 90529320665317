import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import {
  AgreementType,
  CIQError,
  DefaultToasterService,
  EngagementAgreement as EngagementAgreementModel,
  ErrorResponse,
  MetaService,
  ProjectReferral,
  ToasterService
} from "@arbolus-technologies/api";
import { PageStep } from "@arbolus-technologies/models/common";

import { EngagementAgreementInfo } from "../../Components/EngagementAgreementInfo/EngagementAgreementInfo";
import { ExpertApplicationWrapper } from "../../Components/ExpertApplicationWrapper/ExpertApplicationWrapper";

interface EngagementAgreementProps {
  name: string;
  setCurrentStep: (step: number) => void;
  steps: PageStep[];
  goToWelcomeScreen: () => void;
  projectId: string;
  isAgreementAccepted: boolean;
  referral: ProjectReferral;
  metaService?: typeof MetaService;
  notificationService?: ToasterService;
}

export const EngagementAgreement: React.FC<EngagementAgreementProps> = ({
  name,
  setCurrentStep,
  steps,
  goToWelcomeScreen,
  projectId,
  isAgreementAccepted,
  referral,
  metaService = MetaService,
  notificationService = DefaultToasterService
}) => {
  const { canopyId } = useParams<{ canopyId: string }>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [engagementAgreement, setEngagementAgreement] =
    useState<EngagementAgreementModel>();

  useEffect(() => {
    setIsLoading(true);

    metaService
      .getEngagementAgreement(AgreementType.EXPERT, undefined, projectId)
      .subscribe(
        (res) => {
          setEngagementAgreement(res);
          setIsLoading(false);
        },
        (err: ErrorResponse<CIQError>) => {
          notificationService.showError(err.message);
          setIsLoading(false);
        }
      );
  }, [canopyId, metaService, notificationService, projectId]);

  const currentStep = steps.findIndex((step) => step.name === name);

  return (
    <ExpertApplicationWrapper
      currentStep={currentStep}
      name={name}
      isLoading={isLoading}
    >
      {engagementAgreement && (
        <EngagementAgreementInfo
          engagementAgreement={engagementAgreement}
          isAgreementAccepted={isAgreementAccepted}
          nextStepName={steps[currentStep + 1].name}
          referral={referral}
          goToWelcomeScreen={goToWelcomeScreen}
          goToNextStep={() => setCurrentStep(currentStep + 1)}
        />
      )}
    </ExpertApplicationWrapper>
  );
};
