import i18next from "i18next";
import { Epic } from "redux-observable";
import { of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { isOfType } from "typesafe-actions";

import { GET_CANOPIES } from "../actions/ProjectCanopyActionTypes";
import { ProjectCanopyStoreActions } from "../actions/ProjectCanopyActions";
import {
  ProjectCanopyAppState,
  ProjectCanopyStoreAction,
  ProjectCanopyStoreDependencies
} from "../definitions";

const getCanopies: Epic<
  ProjectCanopyStoreAction,
  ProjectCanopyStoreAction,
  ProjectCanopyAppState,
  ProjectCanopyStoreDependencies
> = (action$, _, { canopyService, notificationService }) =>
  action$.pipe(
    filter(isOfType(GET_CANOPIES)),
    switchMap(({ payload: { projectId } }) =>
      canopyService.getCanopies(projectId).pipe(
        map((canopies) =>
          ProjectCanopyStoreActions.getCanopiesSuccess(canopies.items)
        ),
        catchError((error) => {
          notificationService.showError(
            i18next.t("restService:somethingWrong")
          );
          return of(ProjectCanopyStoreActions.getCanopiesFailure(error));
        })
      )
    )
  );

export const ProjectCanopyEpics = [getCanopies];
