import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./Facts.module.scss";

export interface FactsProps {
  funFact?: string;
  quickFacts: string[];
}

export const Facts: React.FC<FactsProps> = ({ funFact, quickFacts }) => {
  const { t } = useTranslation("facts");

  if (!funFact && quickFacts.length === 0) {
    return null;
  }

  return (
    <div className={styles.factsContainer}>
      {quickFacts.map((quickFact, i) => (
        <div key={quickFact} className={styles.container}>
          <div>{t("quickFact", { num: i + 1 })}</div>
          {quickFact}
        </div>
      ))}
      {funFact && (
        <div className={styles.container}>
          <div>{t("funFact")}</div>
          {funFact}
        </div>
      )}
    </div>
  );
};
