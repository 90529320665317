import i18next from "i18next";
import * as Yup from "yup";

import { UserModel } from "@arbolus-technologies/api";
import { CanopyConstraints } from "@arbolus-technologies/models/canopy";

declare module "yup" {
  interface ArraySchema<T> {
    unique(message: string): ArraySchema<T>;
  }
}

interface itemProp {
  url: string;
}

Yup.addMethod(Yup.array, "unique", function checkUnique(message) {
  return this.test("unique", message, function checkUrlsList(list) {
    const urlArrays = list.map((item: itemProp) => item.url);
    const duplicates = urlArrays.filter(
      (item: itemProp, idx: number) => urlArrays.indexOf(item) !== idx
    );
    return duplicates.length > 0
      ? this.createError({
          path: `links[${urlArrays.indexOf(duplicates[0])}].url`,
          message
        })
      : true;
  });
});

export const CreateSurveySchema = Yup.object().shape({
  isAdmin: Yup.boolean(),
  title: Yup.string()
    .required(i18next.t("createSurvey:requiredTitle"))
    .max(
      CanopyConstraints.MAX_CANOPY_TITLE_LENGTH,
      i18next.t("createSurvey:canopyTitleMaxLength", {
        length: CanopyConstraints.MAX_CANOPY_TITLE_LENGTH
      })
    ),
  overview: Yup.string()
    .required(i18next.t("createSurvey:requiredOverview"))
    .max(
      CanopyConstraints.MAX_CANOPY_OVERVIEW_LENGTH,
      i18next.t("createSurvey:canopyOverviewMaxLength", {
        length: CanopyConstraints.MAX_CANOPY_OVERVIEW_LENGTH
      })
    ),
  links: Yup.array()
    .of(
      Yup.object().shape({
        url: Yup.string()
          .url(i18next.t("createSurvey:invalidUrl"))
          .required(i18next.t("createSurvey:requiredUrl"))
      })
    )
    .unique(i18next.t("createSurvey:duplicatedLinkError")),
  documents: Yup.array().of(
    Yup.object().shape({
      fileName: Yup.string().required(i18next.t("createSurvey:requiredFile")),
      id: Yup.string(),
      fileKey: Yup.string(),
      downloadUrl: Yup.string()
    })
  ),
  hasComplianceCheck: Yup.boolean(),
  defaultOwner: Yup.object<UserModel>()
    .nullable()
    .test(
      "defaultOwner",
      i18next.t("createSurvey:ownerRequired"),
      function validate() {
        const { isAdmin, defaultOwner } = this.parent;
        return isAdmin ? !!defaultOwner : !defaultOwner;
      }
    )
});
