import {
  ColDef,
  GridApi,
  GridReadyEvent,
  InitialGroupOrderComparatorParams
} from "ag-grid-community";
import clsx from "clsx";
import React from "react";
import { useSelector } from "react-redux";

import {
  DefaultReferralsService,
  DefaultToasterService,
  IReferralsService,
  ToasterService
} from "@arbolus-technologies/api";
import {
  BookedReferral,
  DO_NOT_CONTACT_STATUS,
  Referral
} from "@arbolus-technologies/models/common";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { ArbolusGrid } from "@arbolus-technologies/ui/components";

import { BookedGroupRenderer } from "../../Components/CellRenderers/ChipGroupRenderer";
import { LoadSlotsDetail } from "../../Components/ReferralSlotsDetail/LoadSlotsDetail";
import { DefaultBookedMenuService } from "../../Services/Referrals/BookedMenuService";
import { IContextMenuService } from "../../Services/Referrals/ContextMenuService";
import {
  DefaultEditCellService,
  IEditCellService
} from "../../Services/Referrals/EditTaglineService";
import {
  angleCol,
  bookedGroupCol,
  callStatusCol,
  taglineCol
} from "./columns/ColumnDefinitions";
import { bookedDateCol } from "./columns/DateColumns";
import { expertMasterNameCol } from "./columns/ExpertColumn";
import { exportColumns } from "./columns/ExportColumns";
import { getOwnerCol } from "./columns/OwnerColumn";
import { useDefaultReferralGridColumns } from "./hooks/useDefaultReferralGridColumns";
import { useReferralGridContext } from "./hooks/useReferralGridContext";
import {
  commonProps,
  groupProps,
  selectionProps
} from "./referralsTableCommonProps";
import { BulkActionsStatusPanel } from "./statusPanels/BulkActionsStatusPanel";
import { ExpandCollapseStatusPanel } from "./statusPanels/ExpandCollapseStatusPanel";

import styles from "./ReferralsTable.module.scss";

interface BookedTableProps {
  referralsService?: IReferralsService;
  notificationService?: ToasterService;
  editCellService?: IEditCellService;
  contextMenuService?: IContextMenuService;
}

export const BookedTable: React.FC<BookedTableProps> = ({
  referralsService = DefaultReferralsService,
  notificationService = DefaultToasterService,
  editCellService = DefaultEditCellService,
  contextMenuService = DefaultBookedMenuService
}) => {
  const [api, setApi] = React.useState<GridApi<BookedReferral>>();
  const project = useSelector(ProjectNxSelector.projectData());
  const [referrals, setReferrals] = React.useState<BookedReferral[]>();

  const onGridReady = React.useCallback(
    ({ api }: GridReadyEvent<BookedReferral>) => {
      setApi(api);
      referralsService.getBooked(project!.id).subscribe({
        next: setReferrals,
        error: notificationService.showApiErrors
      });
    },
    [notificationService, project, referralsService]
  );

  const defaultColDef = useDefaultReferralGridColumns();
  const gridContext = useReferralGridContext(api);

  const columnDefs = React.useMemo<ColDef<Referral>[]>(
    () => [
      bookedGroupCol as unknown as ColDef<Referral>,
      {
        ...expertMasterNameCol,
        cellClass
      },
      taglineCol,
      angleCol,
      getOwnerCol(project!.arbolusTeam),
      bookedDateCol as unknown as ColDef<Referral>,
      callStatusCol as unknown as ColDef<Referral>,
      ...exportColumns
    ],
    [project]
  );

  const statusBar = React.useMemo(
    () => ({
      statusPanels: [
        {
          statusPanel: ExpandCollapseStatusPanel,
          align: "left"
        },
        {
          statusPanel: BulkActionsStatusPanel,
          align: "right"
        }
      ]
    }),
    []
  );

  return (
    <div className="d-flex h-100 flex-column">
      <div className={clsx(styles.greyGroup, styles.statusBarOnTop, "h-100")}>
        <ArbolusGrid
          {...commonProps}
          {...selectionProps}
          {...groupProps}
          onGridReady={onGridReady}
          context={gridContext}
          rowData={referrals}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          getContextMenuItems={contextMenuService.getItems}
          statusBar={statusBar}
          initialGroupOrderComparator={initialGroupOrderComparator}
          readOnlyEdit
          onCellEditRequest={(event) => editCellService.editCell(event)}
          masterDetail
          detailCellRenderer={LoadSlotsDetail}
          detailRowAutoHeight
          detailCellRendererParams={{ referralsService }}
          isRowMaster={isRowMaster}
          groupRowRendererParams={{
            innerRenderer: BookedGroupRenderer,
            suppressCount: true
          }}
        />
      </div>
    </div>
  );
};

function initialGroupOrderComparator({
  nodeA,
  nodeB
}: InitialGroupOrderComparatorParams): number {
  const keyA = nodeA.key === "true" ? 1 : 0;
  const keyB = nodeB.key === "true" ? 1 : 0;

  return keyB - keyA;
}

function isRowMaster(data: BookedReferral) {
  return (
    !data.latestEvent.isInFuture &&
    data.expert.doNotContactStatus !== DO_NOT_CONTACT_STATUS.DNC
  );
}

function cellClass({ data }: { data: Referral | undefined }) {
  if (!data) {
    return undefined;
  }

  return isRowMaster(data as BookedReferral)
    ? "cursor-pointer"
    : "cursor-pointer hide-row-grouping";
}
