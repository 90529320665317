/* eslint-disable react/jsx-no-useless-fragment */
import clsx from "clsx";
import { LocationDescriptorObject } from "history";
import React from "react";
import { Link } from "react-router-dom";

import styles from "./ConditionalLink.module.scss";

interface ConditionalLinkProps {
  to?: string | LocationDescriptorObject;
  alignCenter?: boolean;
  children: React.ReactNode;
}

export const ConditionalLink: React.FC<ConditionalLinkProps> = ({
  children,
  to,
  alignCenter
}) =>
  to ? (
    <Link to={to} className={clsx(alignCenter ? styles.alignCenter : null)}>
      {children}
    </Link>
  ) : (
    <div className={styles.text}>{children}</div>
  );
