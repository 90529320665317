import { GetContextMenuItemsParams, GridReadyEvent } from "ag-grid-community";
import React from "react";
import { useTranslation } from "react-i18next";

import { AdminExpertListResponseItem } from "@arbolus-technologies/api";
import {
  AgGridCustomIcons,
  ArbolusGrid
} from "@arbolus-technologies/ui/components";
import { MainPageLayout } from "@arbolus-technologies/ui/layout";
import { useDocumentTitle } from "@arbolus-technologies/utils";

import { DefaultClipboardManager } from "../../classes/ClipboardManager";
import { ExpertTableDataSource } from "./ExpertTableDataSource";
import { GoToAddExpertButton } from "./GoToAddExpertButton";
import { COLUMN_DEFINITIONS, DEFAULT_COLUMN_DEFINITION } from "./constants";

export function ExpertTablePage(): React.ReactElement {
  const { t } = useTranslation("expertTablePage");
  useDocumentTitle("expertTablePage", "pageTitle");

  return (
    <MainPageLayout
      title={t("pageTitle")}
      rightButtonContainer={<GoToAddExpertButton />}
      stickyHeader
    >
      <ArbolusGrid
        rowModelType="serverSide"
        columnDefs={COLUMN_DEFINITIONS}
        defaultColDef={DEFAULT_COLUMN_DEFINITION}
        onGridReady={onGridReady}
        getRowId={({ data }) => data.id}
        suppressRowClickSelection
        suppressMenuHide
        tooltipShowDelay={500}
        skipHeaderOnAutoSize
        pagination
        paginationAutoPageSize
        getContextMenuItems={getContextMenuItems}
      />
    </MainPageLayout>
  );
}

function onGridReady(event: GridReadyEvent) {
  const { api } = event;
  api.setGridOption("serverSideDatasource", new ExpertTableDataSource());
  api.sizeColumnsToFit();
}

function getContextMenuItems(
  params: GetContextMenuItemsParams<AdminExpertListResponseItem>
) {
  const { node } = params;
  if (!node?.data) {
    return [];
  }

  const { email, phoneNumber } = node.data;
  return [
    {
      name: email,
      action: () => DefaultClipboardManager.copy(email),
      icon: AgGridCustomIcons.email
    },
    {
      name: phoneNumber,
      action: () => DefaultClipboardManager.copy(phoneNumber),
      icon: AgGridCustomIcons.phone
    }
  ];
}
