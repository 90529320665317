import { replace } from "connected-react-router";
import i18next from "i18next";
import React from "react";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dispatch } from "redux";
import { Subscription } from "rxjs";

import { ToasterService } from "@arbolus-technologies/api";
import {
  AuthPageHeader,
  MixPanelActions,
  MixPanelEventNames,
  trackEvent
} from "@arbolus-technologies/features/common";
import { SelectOption } from "@arbolus-technologies/models/common";
import { Loader } from "@arbolus-technologies/ui/components";

import { AppConstants, PublicRouteConstants } from "../../../../../constants";
import { APP_USER_ROLES } from "../../../../../constants/app";
import {
  CreateAccountStages,
  FEDERATED_AUTH_PROVIDER,
  RECAPTCHA_ACTIONS
} from "../../../../../constants/auth";
import {
  ENDORSEMENT,
  WELCOME
} from "../../../../../constants/navigation/authRoutes";
import {
  CANOPY_APPLICATION_PAGE_ROUTE,
  CANOPY_DETAILS_PAGE_ROUTE
} from "../../../../../constants/navigation/canopyRoutes";
import { CIQError, ErrorResponse } from "../../../../../models/api";
import { ExpertRateCardType, RateCard } from "../../../../../models/expert";
import {
  Invitation,
  RegisterUserRequest,
  User
} from "../../../../../models/user";
import { MetaService, UserService } from "../../../../../services";
import { AppAction } from "../../../../../store/actions";
import { LoginStoreActions } from "../../../store";
import ExpertRate, { ExpertRateFormValues } from "../expertRate/ExpertRate";
import RegisterForm from "../registerForm/RegisterForm";

const notification = new ToasterService();

interface CreateAccountState {
  isLoading: boolean;
  isCurrencyLoading: boolean;
  signUpError?: ErrorResponse<CIQError>;
  stage: CreateAccountStages;
}

interface CreateAccountProps {
  termId: string;
  user?: User;
  token?: string;
  isExpert: boolean;
  isEmailInvite: boolean;
  invitationCode?: string;
  invitation?: Invitation;
  clientName?: string;
  isCanopySignUp?: boolean;
  canopyId?: string;
  executeRecaptcha: (action: string) => Promise<string>;
  onError: () => void;
}

interface CreateAccountDispatchProps {
  onRegisterUserSuccess: (
    userId: string,
    roles: string[],
    nextPath: string,
    invitation?: Invitation
  ) => void;
}

type CreateAccountIntersectProps = CreateAccountProps &
  CreateAccountDispatchProps &
  WithTranslation;

export interface CreateAccountFormValues {
  email: string;
  password: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  title?: string;
  isRealNameEnabled?: boolean;
  displayName?: string;
}
class CreateAccount extends React.Component<
  CreateAccountIntersectProps,
  CreateAccountState
> {
  constructor(props: CreateAccountIntersectProps) {
    super(props);
    this.state = {
      isLoading: false,
      isCurrencyLoading: true,
      stage: CreateAccountStages.CREATE_ACCOUNT
    };
  }

  componentDidMount(): void {
    const { t } = this.props;
    document.title = t("createAccountTitle");
    this.fetchCurrencies();
  }

  componentWillUnmount(): void {
    this.registerUserSubscription?.unsubscribe();
    this.federatedLoginSubscription?.unsubscribe();
    this.authenticateUserSubscription?.unsubscribe();
    this.currencyFetchSubscription?.unsubscribe();
  }

  private registerUserSubscription?: Subscription;

  private federatedLoginSubscription?: Subscription;

  private authenticateUserSubscription?: Subscription;

  private currencyFetchSubscription?: Subscription;

  private currencies?: SelectOption[];

  private preservedUserData?: CreateAccountFormValues;

  private preservedRateCard?: RateCard;

  fetchCurrencies = async (): Promise<void> => {
    const { executeRecaptcha, onError } = this.props;
    try {
      const recaptchaToken = await executeRecaptcha(
        RECAPTCHA_ACTIONS.LIST_CURRENCIES
      );

      this.currencyFetchSubscription = MetaService.getCurrencies(
        recaptchaToken
      ).subscribe(
        (currencies) => {
          this.currencies = currencies.items.map((t) => ({
            value: t.isoCurrencyCode,
            label: t.isoCurrencyCode,
            customLabel: ""
          }));
          this.setState({ isCurrencyLoading: false });

          trackEvent(MixPanelEventNames.UserRegistrationCreateAccount, {
            action: MixPanelActions.CurrenciesLoaded
          });
        },
        (error: ErrorResponse<CIQError>) => {
          notification.showError(error.message);
          onError();

          trackEvent(MixPanelEventNames.UserRegistrationCreateAccount, {
            action: MixPanelActions.CurrencyLoadFailed
          });
        }
      );
    } catch (error) {
      notification.showError(i18next.t("restService:somethingWrong"));
      onError();

      trackEvent(MixPanelEventNames.UserRegistrationCreateAccount, {
        action: MixPanelActions.CurrencyLoadFailed
      });
    }
  };

  handleOnFederatedLoginClicked = (provider: string): void => {
    const { termId } = this.props;

    this.setState({ isLoading: true }, () => {
      this.federatedLoginSubscription = UserService.getFederatedLogin(
        provider,
        termId
      ).subscribe(
        (response) => {
          window.location.assign(response.url);
        },
        (error: ErrorResponse<CIQError>) => {
          this.setState({ isLoading: false });
          notification.showError(error.message);
        }
      );
    });
  };

  handleOnCreateClicked = async (): Promise<void> => {
    const { executeRecaptcha, t, invitationCode, termId, token, isExpert } =
      this.props;
    const { expert, client } = APP_USER_ROLES;

    const accountType = isExpert ? expert : client;
    const {
      email,
      password,
      firstName,
      lastName,
      phoneNumber,
      title,
      isRealNameEnabled,
      displayName
    } = this.preservedUserData!;

    this.setState({ isLoading: true, signUpError: undefined });

    const mixpanelProperties = {
      firstName,
      lastName,
      email,
      invitationCode,
      accountType,
      phoneNumber: phoneNumber ? `+${phoneNumber}` : ""
    };

    try {
      const recaptchaToken = await executeRecaptcha(
        RECAPTCHA_ACTIONS.CREATE_ACCOUNT
      );

      const user: RegisterUserRequest = {
        termId,
        accountType,
        token,
        email,
        password,
        firstName,
        lastName,
        phoneNumber: `+${phoneNumber}`,
        recaptchaToken,
        rateCard: this.preservedRateCard,
        invitationCode,
        title,
        isRealNameEnabled,
        displayName: isRealNameEnabled ? "" : displayName
      };

      this.registerUserSubscription = UserService.registerUser(user).subscribe(
        () => {
          this.handleOnRegisterUserSuccess(email, password);
          trackEvent(MixPanelEventNames.UserRegistrationCreateAccount, {
            action: MixPanelActions.AccountCreated,
            ...mixpanelProperties
          });
        },
        (error: ErrorResponse<CIQError>) => {
          this.setState({
            signUpError: error,
            stage: CreateAccountStages.CREATE_ACCOUNT,
            isLoading: false
          });

          trackEvent(MixPanelEventNames.UserRegistrationCreateAccount, {
            action: MixPanelActions.AccountCreationFailed,
            ...mixpanelProperties
          });
        }
      );
    } catch (error) {
      this.setState({
        isLoading: false,
        stage: CreateAccountStages.CREATE_ACCOUNT
      });
      notification.showError(t("errorInCreateAccount"));

      trackEvent(MixPanelEventNames.UserRegistrationCreateAccount, {
        action: MixPanelActions.AccountCreationFailed,
        ...mixpanelProperties
      });
    }
  };

  handleOnRegisterUserSuccess = (email: string, password: string): void => {
    const {
      onRegisterUserSuccess,
      invitation,
      isExpert,
      isCanopySignUp,
      canopyId
    } = this.props;
    let nextPath = WELCOME;
    if (isExpert && !isCanopySignUp) {
      nextPath = ENDORSEMENT;
    }
    if (isExpert && isCanopySignUp && canopyId) {
      const newExpertApplicationFF = false; //isDevEnv();

      nextPath = newExpertApplicationFF
        ? CANOPY_APPLICATION_PAGE_ROUTE(canopyId)
        : CANOPY_DETAILS_PAGE_ROUTE(canopyId);
    }
    this.authenticateUserSubscription = UserService.authenticateUser({
      email,
      password,
      remember: true
    }).subscribe(
      ({ userId, userRoles }) => {
        this.setState(
          {
            isLoading: false
          },
          () => {
            localStorage.setItem(
              AppConstants.LOCALSTORAGE.LAST_LOGIN,
              new Date().getTime().toString()
            );
            onRegisterUserSuccess(userId, userRoles, nextPath, invitation);
          }
        );
      },
      (error: ErrorResponse<CIQError>) => {
        this.setState({
          signUpError: error,
          isLoading: false
        });
      }
    );
  };

  handleCreateAccountClicked = (values: CreateAccountFormValues): void => {
    const { invitation, isEmailInvite, isExpert, invitationCode } = this.props;
    const { expert, client } = APP_USER_ROLES;

    this.preservedUserData = {
      ...values,
      // Force new registrations to lowercase email addresses
      email: isEmailInvite ? values.email : values.email.toLowerCase()
    };

    const { email, firstName, lastName, phoneNumber } = this.preservedUserData;
    const accountType = isExpert ? expert : client;
    trackEvent(MixPanelEventNames.UserRegistrationCreateAccount, {
      action: MixPanelActions.Clicked,
      button: "Create Account",
      firstName,
      lastName,
      email,
      invitationCode,
      accountType,
      phoneNumber: phoneNumber ? `+${phoneNumber}` : ""
    });

    if (isExpert && invitation) {
      this.setState({
        stage: CreateAccountStages.EXPERT_RATE,
        signUpError: undefined
      });
    } else {
      this.handleOnCreateClicked();
    }
  };

  handleExpertRateSaveClicked = ({
    currency,
    hourlyRate
  }: ExpertRateFormValues): void => {
    this.preservedRateCard = {
      hours: 1,
      isoCurrencyCode: currency,
      rateCardType: ExpertRateCardType.AdHoc,
      packageName: "Base",
      price: hourlyRate!
    };
    this.handleOnCreateClicked();
  };

  renderFederatedLogins = (): JSX.Element => {
    const { t } = this.props;

    return (
      <div className="another-login-section">
        <div>
          <h3>{t("socialAuth")}</h3>
        </div>
        <div className="social-login-container">
          <div
            className="apple-icon"
            onClick={(): void =>
              this.handleOnFederatedLoginClicked(FEDERATED_AUTH_PROVIDER.APPLE)
            }
          />
          <div
            className="google-icon"
            onClick={(): void =>
              this.handleOnFederatedLoginClicked(FEDERATED_AUTH_PROVIDER.GOOGLE)
            }
          />
          <div
            className="microsoft-icon"
            onClick={(): void =>
              this.handleOnFederatedLoginClicked(
                FEDERATED_AUTH_PROVIDER.MICROSOFT
              )
            }
          />
        </div>
      </div>
    );
  };

  renderMessagePanel = (): JSX.Element => {
    const { isExpert, clientName } = this.props;

    return isExpert ? (
      <Trans
        ns="register"
        i18nKey="expertSelfOnBoarding"
        components={[<span key="1" />]}
      />
    ) : (
      <Trans
        ns="register"
        i18nKey="clientInviteClient"
        values={{ clientName }}
        components={[<span key="1" />]}
      />
    );
  };

  renderStages = (): JSX.Element => {
    const { user, isEmailInvite, isCanopySignUp, invitationCode } = this.props;
    const { stage, isLoading, signUpError } = this.state;

    const { EXPERT_RATE } = CreateAccountStages;

    let userData = user;

    if (this.preservedUserData) {
      const { email, firstName, lastName, phoneNumber, title } =
        this.preservedUserData;

      userData = { email, firstName, lastName, phoneNumber, title } as User;
    }

    if (stage === EXPERT_RATE) {
      return (
        <ExpertRate
          hourlyRate={this.preservedRateCard?.price}
          currencies={this.currencies!}
          isLoading={isLoading}
          currencyCode={this.preservedRateCard?.isoCurrencyCode}
          onSaveButtonClicked={this.handleExpertRateSaveClicked}
          user={userData}
          invitationCode={invitationCode}
        />
      );
    }

    return (
      <RegisterForm
        user={userData}
        isEmailInvite={isEmailInvite}
        signUpError={signUpError}
        isLoading={isLoading}
        isCanopySignUp={isCanopySignUp}
        onCreateAccountClicked={this.handleCreateAccountClicked}
        isExpert={this.props.isExpert}
        invitationCode={invitationCode}
      />
    );
  };

  render(): JSX.Element {
    const { user, isCanopySignUp } = this.props;
    const { stage, isCurrencyLoading } = this.state;
    const isExpertRateState = stage === CreateAccountStages.EXPERT_RATE;

    if (isCurrencyLoading) {
      return <Loader isFull />;
    }

    return (
      <div className="create-account-container">
        <AuthPageHeader title="New account" />

        {!isExpertRateState && <h3>{this.renderMessagePanel()}</h3>}

        {this.renderStages()}

        {!user &&
          !isExpertRateState &&
          !isCanopySignUp &&
          this.renderFederatedLogins()}

        {!isExpertRateState && (
          <div className="create-account-footer">
            <Trans
              ns="register"
              i18nKey="alreadyHaveAccount"
              components={[<Link key="0" to={PublicRouteConstants.LOGIN} />]}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch
): CreateAccountDispatchProps => ({
  onRegisterUserSuccess: (
    userId: string,
    roles: string[],
    nextPath: string,
    invitation?: Invitation
  ): AppAction => {
    dispatch(LoginStoreActions.authenticateUserSuccess(userId, roles));
    dispatch(replace(nextPath, invitation));
  }
});

export default withTranslation("register")(
  // @ts-ignore
  connect(null, mapDispatchToProps)(CreateAccount)
);
