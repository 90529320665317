import React from "react";
import { useTranslation } from "react-i18next";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { LinkBoxButton } from "../../BoxButton/LinkBoxButton";
import { StatButtonProps } from "./StatButtonProps";

export const PipelineButton: React.FC<StatButtonProps> = ({ to, ...props }) => {
  const { t } = useTranslation("pipelineButton");

  return (
    <LinkBoxButton
      text={t("pipelineText")}
      icon={{
        color: ARBOLUS_COLORS.bColorGrayIcon,
        background: ARBOLUS_COLORS.bColorSecondaryGreyLight,
        name: "checklist"
      }}
      subTitle={t("pipelineSubtitle")}
      to={to}
      chevronColor={ARBOLUS_COLORS.bColorBasePurple}
      {...props}
    />
  );
};
