import Hamburger from "hamburger-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  Avatar,
  IconWithText,
  LinkWrapper
} from "@arbolus-technologies/ui/components";
import { displayUserName } from "@arbolus-technologies/utils";

import styles from "./TabletMobileMenuHeader.module.scss";

interface TabletMobileMenuHeaderProps {
  handleToggle: () => void;
  handleBack: () => void;
  handleClose: () => void;
  isOpen: boolean;
  isSubmenu: boolean;
  profileRoute: string;
  isAdmin?: boolean;
}
export const TabletMobileMenuHeader: React.FC<TabletMobileMenuHeaderProps> = ({
  handleToggle,
  handleBack,
  handleClose,
  isOpen,
  isSubmenu,
  profileRoute,
  isAdmin
}) => {
  const { t } = useTranslation("menuNavigation");
  const authUser = useSelector(CacheSelector.loggedInUser());
  const adminUser = useSelector(CacheSelector.userAdminProfile());
  const avatarUser = isAdmin ? adminUser : authUser;

  return (
    <div className={styles.header}>
      {isSubmenu ? (
        <IconWithText
          icon={{
            name: "chevron_left",
            size: "24px"
          }}
          text={{
            content: t("back"),
            color: ARBOLUS_COLORS.bColorBasePurple
          }}
          onClick={handleBack}
        />
      ) : (
        <LinkWrapper to={profileRoute} onClick={handleClose}>
          <Avatar
            avatar={{
              name: displayUserName(avatarUser),
              imageUrl: avatarUser.profileImageUrl
            }}
          />
        </LinkWrapper>
      )}
      <div className={styles.iconContainer}>
        <Hamburger
          toggled={isOpen}
          toggle={handleToggle}
          size={18}
          color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
        />
      </div>
    </div>
  );
};
