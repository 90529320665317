import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { CacheSelector } from "@arbolus-technologies/stores/cache";

import { ISideBarItems } from "../MenuItemsConfig";
import { useMenuItems } from "../useMenuItems";
import { TabletMobileMenuBody } from "./TabletMobileMenuBody/TabletMobileMenuBody";
import { TabletMobileMenuHeader } from "./TabletMobileMenuHeader/TabletMobileMenuHeader";

import styles from "./TabletMobileMenu.module.scss";

interface TabletMobileMenuProps {
  handleLogout: () => void;
  profileRoute: string;
  isAdmin?: boolean;
}
export const TabletMobileMenu: React.FC<TabletMobileMenuProps> = ({
  handleLogout,
  profileRoute,
  isAdmin = false
}) => {
  const userRole = useSelector(CacheSelector.generateActiveUserRole());
  const adminUser = useSelector(CacheSelector.userAdminProfile());
  const clientId = isAdmin ? adminUser.clientId : undefined;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [menuItemsBySection, setMenuItemsBySection] = useState<
    ISideBarItems[] | null
  >(null);
  const [isSubmenu, setIsSubmenu] = useState<boolean>(false);
  const { menuItems } = useMenuItems(isAdmin, userRole, clientId);

  useEffect(() => {
    setMenuItemsBySection(menuItems);
  }, [menuItems]);

  const handleMenuItemOnClick = (menuItem: ISideBarItems): void => {
    if (menuItem?.subItems) {
      setMenuItemsBySection(menuItem?.subItems);
      setIsSubmenu(true);
    } else {
      handleClose();
    }
  };

  const handleBack = () => {
    setMenuItemsBySection(menuItems);
    setIsSubmenu(false);
  };

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
    setIsSubmenu(false);
    setMenuItemsBySection(menuItems);
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsSubmenu(false);
    setMenuItemsBySection(null);
  };

  return (
    <div
      className={clsx(styles.container, {
        [styles.isOpen]: isOpen
      })}
    >
      <TabletMobileMenuHeader
        handleToggle={handleToggle}
        isOpen={isOpen}
        handleBack={handleBack}
        isSubmenu={isSubmenu}
        isAdmin={isAdmin}
        profileRoute={profileRoute}
        handleClose={handleClose}
      />
      {isOpen && (
        <TabletMobileMenuBody
          handleMenuItemOnClick={handleMenuItemOnClick}
          menuItems={menuItemsBySection}
          handleLogout={handleLogout}
        />
      )}
    </div>
  );
};
