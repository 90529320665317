import {
  Referral,
  Slot,
  SlotsReferral
} from "@arbolus-technologies/models/common";

export class SlotRow extends Referral {
  private _rowId: string | undefined;
  readonly startTime!: Date;
  readonly endTime!: Date;
  readonly expertTimezone?: string;

  get rowId() {
    if (!this._rowId) {
      this._rowId = `${
        this.id
      }-${this.startTime.toISOString()}-${this.endTime.toISOString()}`;
    }

    return this._rowId;
  }

  public static create(referral: SlotsReferral, slot: Slot): SlotRow {
    return Object.assign(new SlotRow(), { ...referral, ...slot });
  }
}
