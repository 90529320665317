import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import type { CustomerSurveyCompany } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { Loader } from "@arbolus-technologies/ui/components";

import { useGetSurveyAnswers } from "../../../hooks/useGetSurveyAnswers";
import { AlternativesForm } from "./AlternativesForm";
import { CompanyQuestionsForm } from "./CompanyQuestionsForm/CompanyQuestionsForm";

import styles from "./GiveDetailsStep.module.scss";

export function GiveDetailsStep({
  next,
  vendors,
  defaultAlternatives
}: {
  next: () => void;
  vendors: CustomerSurveyCompany[];
  defaultAlternatives: CustomerSurveyCompany[];
}) {
  const currentUser = useSelector(CacheSelector.currentUserSelector());
  const { surveyId } = useParams<{ surveyId: string }>();

  const surveyAnswers = useGetSurveyAnswers(surveyId, currentUser.loggedInId);
  const insights = surveyAnswers?.customerInsights ?? [];

  if (surveyAnswers === undefined) {
    return <Loader isFull isCentered />;
  }

  return (
    <div className={styles.questionsList}>
      {vendors.map((company, index) => {
        const customerInsightId =
          insights.find((vendor) => vendor.vendorCompanyId === company.id)
            ?.customerInsightId ?? "";
        return (
          <CompanyQuestionsForm
            key={company.id}
            isFirst={index === 0}
            company={company}
            customerInsightId={customerInsightId}
            surveyId={surveyId}
            previousAnswer={surveyAnswers?.customerInsights.find(
              (insight) => insight.customerInsightId === customerInsightId
            )}
          />
        );
      })}

      <AlternativesForm
        surveyId={surveyId}
        customerId={surveyAnswers.customerId}
        defaultAlternatives={defaultAlternatives}
        previousCompetitors={surveyAnswers.competitors.map(
          (competitor) => competitor.companyId
        )}
        next={next}
      />
    </div>
  );
}
