import clsx from "clsx";
import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./Button.module.scss";

export type ButtonType =
  | "primary"
  | "secondary"
  | "tertiary"
  | "danger"
  | "success"
  | "tab"
  | "iconButton"
  | "filter"
  | "confirmation";

export interface ButtonProps {
  text?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e: any) => void;
  type?: ButtonType;
  disabled?: boolean;
  startIcon?: string;
  endIcon?: string;
  activeTab?: boolean;
  nativeType?: "submit" | "reset" | "button";
  centerIcon?: string;
  testId?: string;
  filterIcon?: string;
  size?: "small" | "large";
  color?: ARBOLUS_COLORS;
}

// We agreed to NEVER allow custom classNames in this component
export const Button: React.FC<ButtonProps> = ({
  text,
  onClick,
  startIcon,
  endIcon,
  type = "primary",
  disabled = false,
  activeTab,
  nativeType = "button",
  size = "large",
  centerIcon,
  testId,
  filterIcon,
  color
}) => {
  if (type === "filter") {
    endIcon = filterIcon ?? "filter_list";
    centerIcon = filterIcon ?? "filter_list";
  }

  return (
    <button
      className={clsx(
        styles[type],
        activeTab && styles.activeTab,
        startIcon && styles.startIconPadding,
        endIcon && styles.endIconPadding,
        { [styles.small]: size === "small" }
      )}
      style={{ color: color }}
      onClick={onClick}
      disabled={disabled}
      type={nativeType}
      data-testid={testId}
    >
      {startIcon && (
        <span
          className={clsx(
            "material-symbols-sharp",
            styles.icon,
            styles.startIconMargin
          )}
        >
          {startIcon}
        </span>
      )}
      {text ? (
        text
      ) : (
        <span
          className={clsx(
            "material-symbols-sharp",
            styles.icon,
            styles.centerIcon
          )}
        >
          {centerIcon}
        </span>
      )}
      {endIcon && (
        <span
          className={clsx(
            "material-symbols-sharp",
            styles.icon,
            styles.endIconMargin
          )}
        >
          {endIcon}
        </span>
      )}
    </button>
  );
};
