import { OutputSelector, createSelector } from "reselect";

import { CacheStateSelector } from "../CacheSelector";
import { CacheAppState, CacheReducerState } from "../models/definitions";

export const loggedInUserExpertId = (): OutputSelector<
  CacheAppState,
  string,
  (res: CacheReducerState) => string
> =>
  createSelector<CacheAppState, CacheReducerState, string>(
    CacheStateSelector,
    (cacheReducerState) => cacheReducerState.loggedInUser.expertId ?? ""
  );
