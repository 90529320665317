import { GridApi, GridReadyEvent } from "ag-grid-community";
import clsx from "clsx";
import React, { useRef } from "react";

import {
  AddCreateVendorsPanel,
  ConfirmedVendors
} from "@arbolus-technologies/features/common";
import { CustomerSurveyCompany } from "@arbolus-technologies/models/common";
import { ArbolusGrid } from "@arbolus-technologies/ui/components";

import { CustomerSurveyCompanies } from "./CustomerSurveyCompanies/CustomerSurveyCompanies";
import { DefaultCustomerSurveyDataSource } from "./CustomerSurveyDataSource";
import {
  colDefs,
  defaultColDef,
  getContextMenuItems,
  statusBar
} from "./tableDefinition";
import { requestUpdateCompanies } from "./utils";

import styles from "./CustomerSurveyTable.module.scss";

export const CustomerSurveyTable: React.FC = () => {
  const gridApi = useRef<GridApi | undefined>();

  const onGridReady = React.useCallback(({ api }: GridReadyEvent) => {
    api.sizeColumnsToFit();
    api.setGridOption("serverSideDatasource", DefaultCustomerSurveyDataSource);
    gridApi.current = api;
  }, []);

  function addCompaniesToSurvey(vendors: ConfirmedVendors, rowId?: string) {
    const api = gridApi.current;
    if (api === undefined) return;

    const rowNode = rowId ? api.getRowNode(rowId) : undefined;
    if (rowNode === undefined) return;

    const surveyCompanies: CustomerSurveyCompany[] = vendors.map((company) => ({
      id: company.vendorCompanyId,
      name: company.vendorCompanyName,
      visible: true
    }));
    const updatedCompanies = [...rowNode.data.companies, ...surveyCompanies];
    rowNode.setData({
      ...rowNode.data,
      companies: updatedCompanies
    });
    requestUpdateCompanies(rowNode.data.id, updatedCompanies);
  }

  return (
    <>
      <div className="d-flex h-100 flex-column">
        <div className={clsx(styles.customerSurveyTable, "h-100")}>
          <ArbolusGrid
            getRowId={({ data }) => data?.id}
            onGridReady={onGridReady}
            columnDefs={colDefs}
            defaultColDef={defaultColDef}
            statusBar={statusBar}
            rowModelType="serverSide"
            pagination
            paginationAutoPageSize
            rowSelection="multiple"
            suppressRowClickSelection
            getContextMenuItems={getContextMenuItems}
            masterDetail
            detailCellRenderer={CustomerSurveyCompanies}
            detailRowAutoHeight
            detailCellRendererParams={{ refreshStrategy: "everything" }}
            suppressAnimationFrame
            animateRows={false}
            reactiveCustomComponents={true}
          />
        </div>
      </div>
      <AddCreateVendorsPanel onVendorsConfirmed={addCompaniesToSurvey} />
    </>
  );
};
