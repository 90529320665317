import { Button } from "arbolus-ui-components";
import dompurify from "dompurify";
import React from "react";
import { useTranslation } from "react-i18next";

import { Loader } from "@arbolus-technologies/ui/components";

import styles from "./VideoLoader.module.scss";

interface VideoLoaderProps {
  downloadUrl?: string;
}

export const VideoLoader: React.FC<VideoLoaderProps> = ({ downloadUrl }) => {
  const { t } = useTranslation("canopyVideo");

  const handleGoToHelpSite = () => {
    window.open(t("helpUrl"), "_blank");
  };

  return (
    <div className={styles.container}>
      {downloadUrl && t("processing")}
      <Loader isLight />
      {!downloadUrl && (
        <div className={styles.loadingMessage}>
          <h2>{t("loadingTitle")} </h2>
          <p
            dangerouslySetInnerHTML={{
              __html: dompurify.sanitize(t("loadingMessage"))
            }}
          />
          <Button
            type="tertiary"
            onClick={handleGoToHelpSite}
            text={t("helpSite")}
            endIcon="chevron_right"
          />
        </div>
      )}
    </div>
  );
};
