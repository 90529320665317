import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import {
  PhoneNumber,
  PhoneNumberFormat,
  PhoneNumberUtil
} from "google-libphonenumber";
import htmlToDraft from "html-to-draftjs";
import moment from "moment";
import queryString from "query-string";
import { ReactElement } from "react";
import {
  browserName,
  deviceType,
  engineName,
  engineVersion,
  fullBrowserVersion,
  mobileModel,
  mobileVendor,
  osName,
  osVersion
} from "react-device-detect";
import ReactHtmlParser from "react-html-parser";
import sanitizeHtml from "sanitize-html";
import { object, string } from "yup";

import { ALLOWED_RICH_TEXT_ELEMENTS } from "./UtilsServiceModels";
import { NUMBER_FORMAT_LOCALE } from "./constants/DateConstants";
import { CIQCrashAnalytics, DisplayUser, ERROR_TYPE } from "./types";

export function displayUserName(user: DisplayUser, isFullName = true): string {
  const { firstName, lastName, email } = user;

  if (isFullName && firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  return firstName ?? lastName ?? email ?? "Not Available";
}

export function validateContactNumber(contactNumber: string): boolean {
  const phoneNumberValidatingInstance = PhoneNumberUtil.getInstance();
  let phoneNumberValidity = false;

  try {
    const phoneNumber =
      phoneNumberValidatingInstance.parseAndKeepRawInput(contactNumber);
    phoneNumberValidity =
      phoneNumberValidatingInstance.isValidNumber(phoneNumber);
  } catch (error) {
    phoneNumberValidity = false;
  } finally {
    // eslint-disable-next-line no-unsafe-finally
    return phoneNumberValidity;
  }
}

export function getStrippedTextFromHtml(html: string): string {
  return html.replace(/<[^>]+>/g, "").replace(/\r?\n|\r/g, "");
}

export function mergeDateTime(date: Date, time: Date): Date {
  const timeMoment = moment(time);
  const moderateDate = moment(date);

  moderateDate.set({
    hour: timeMoment.get("hour"),
    minute: timeMoment.get("minute"),
    second: timeMoment.get("second")
  });

  return moderateDate.toDate();
}

export function nameOf<T>(name: keyof T): string {
  return name as string;
}

export function capitalLetterToLower(str: string): string {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export function isStringUrl(value: string): boolean {
  try {
    const url = new URL(value);
    return url.protocol !== "http:" && url.protocol !== "https:";
  } catch (_) {
    return false;
  }
}

export function parseRichTextContent(richContent: string): ReactElement[] {
  // @ts-ignore
  return ReactHtmlParser(richContent, {
    transform: (node) => {
      if (
        node.type === "tag" &&
        !ALLOWED_RICH_TEXT_ELEMENTS.includes(node.name)
      ) {
        return null;
      }
      if (node.type === "style") {
        return null;
      }
    }
  });
}

export function validateEmail(email: string): boolean {
  const schema = object().shape({
    email: string().email().required()
  });
  return schema.isValidSync({ email });
}

export function isBetween(x: number, a: number, b: number): boolean {
  const min = Math.min.apply(Math, [a, b]);
  const max = Math.max.apply(Math, [a, b]);
  return x >= min && x <= max;
}

export function generateURL(baseURL: string): string {
  const { pathname, hash, search } = window.location;
  const buildURL = new URL(baseURL);
  buildURL.hash = hash;
  buildURL.pathname = pathname;
  buildURL.search = search;
  return buildURL.toString();
}

export function convertSecondsToHoursAndMinutes(seconds: number): string {
  const SECONDS_PER_MINUTE = 60;
  const SECONDS_PER_HOUR = 3600;
  const totalHours = Math.floor(seconds / SECONDS_PER_HOUR);
  const remainingMinutes = Math.floor(
    (seconds - totalHours * SECONDS_PER_HOUR) / SECONDS_PER_MINUTE
  );
  const duration = `${
    totalHours > 0 ? `${totalHours}h` : ""
  }${remainingMinutes}m `;
  return duration;
}

export function enableFunctionalityBasedOnDate(
  startingDate: Date,
  limitDate: Date
): boolean {
  const currentDate = new Date();
  return (
    currentDate.getTime() >= startingDate.getTime() &&
    currentDate.getTime() <= limitDate.getTime()
  );
}

export function convertToUTC(date: Date | string, format: string): string {
  return moment.utc(date).format(format);
}

export function convertActiveZoneToUTC(
  date: Date | string = new Date()
): string {
  const current = moment(date);
  const utcDateTime = current.clone().utc();
  return utcDateTime.toISOString();
}

export function overrideToUTC(date: Date): string {
  const locale = moment.parseZone(date.toDateString());
  return locale.utc().format();
}

export function convertValueToCurrencyFormat(
  value: number,
  currencyFormat: string,
  minimumFractionDigits = 0
): string {
  return new Intl.NumberFormat(NUMBER_FORMAT_LOCALE, {
    style: "currency",
    currency: currencyFormat,
    maximumFractionDigits: 2,
    minimumFractionDigits
  }).format(value);
}

export function nameToAvatarText(fullName: string): string {
  return fullName
    .split(/\s/)
    .map((part) => part.substring(0, 1).toUpperCase())
    .filter((v) => !!v)
    .slice(0, 2)
    .join("");
}

export function convertUTCToTimeZone(
  date: Date | string,
  timeZone: string
): Date {
  const utcDate = moment.utc(date, "YYYY/MM/DD HH:mm:ss");
  const utcTimeZoneDate = utcDate.clone().tz(timeZone, false);
  const formatted = utcTimeZoneDate.format("YYYY/MM/DD HH:mm:ss");
  return new Date(formatted);
}

export function formatCountIndicator(value: number): string | number {
  return value > 9 ? "9+" : value;
}

export function nameFromEmail(email: string): string {
  const userNameArray = Array.from(
    email.split("@")[0].replace(/[^a-z0-9]/gi, "")
  ).join("");

  if (userNameArray.length >= 2) {
    return `${userNameArray[0]} ${userNameArray[1]}`;
  }
  return userNameArray[0];
}

export function formatPhoneNumber(number: string): string {
  const phoneNumberFormatInstance = PhoneNumberUtil.getInstance();
  const phoneNumber: PhoneNumber =
    phoneNumberFormatInstance.parseAndKeepRawInput(number);

  return phoneNumberFormatInstance.format(
    phoneNumber,
    PhoneNumberFormat.INTERNATIONAL
  );
}

export function parseHTMLToRichText(markup: string): EditorState {
  const cleaned = sanitizeHtml(markup, {
    allowedTags: ALLOWED_RICH_TEXT_ELEMENTS
  });
  const blocksFromHtml = htmlToDraft(cleaned);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );

  return EditorState.createWithContent(contentState);
}

export function displayTimeDuration(seconds: number): string {
  const SECONDS_PER_MINUTE = 60;
  const SECONDS_PER_HOUR = 3600;
  const totalMinutes = Math.floor(seconds / SECONDS_PER_MINUTE);
  const remainingSeconds = Math.floor(
    seconds - totalMinutes * SECONDS_PER_MINUTE
  );
  const totalHours = Math.floor(seconds / SECONDS_PER_HOUR);

  const minuteTime = totalMinutes.toString().padStart(2, "0");
  const secondsTime = remainingSeconds.toString().padStart(2, "0");

  let displayTime = `${minuteTime}:${secondsTime}`;

  if (totalHours > 0) {
    const remainingMinutes = Math.floor(totalMinutes - totalHours * 60);
    const hoursTime = totalHours.toString().padStart(2, "0");
    const minutes = remainingMinutes.toString().padStart(2, "0");
    displayTime = `${hoursTime}:${minutes}:${secondsTime}`;
  }

  return displayTime;
}

export function generateUUID(): string {
  return "xxxx-xxxx-xxxx".replace(/[xy]/g, () =>
    Math.random().toString(36).substring(10)
  );
}

export function parseRichTextToHTML(editorState: EditorState): string {
  const rawContentState = convertToRaw(editorState.getCurrentContent());
  const markup = draftToHtml(rawContentState);
  const cleaned = sanitizeHtml(markup, {
    allowedTags: ALLOWED_RICH_TEXT_ELEMENTS
  });
  return cleaned;
}

export function isTimeBeforeLocalTime(dateTime: Date): boolean {
  const utcDate = moment.utc(dateTime);
  const activeZoneDate = utcDate.clone().local();
  const withActiveZone = activeZoneDate.toDate();
  return moment(withActiveZone).isBefore(moment());
}

export function getCrashAnalyticsData({
  crashId,
  userId,
  errorType,
  errorMessage,
  isAdmin
}: {
  crashId: string;
  errorMessage: string;
  errorType: ERROR_TYPE;
  userId?: string;
  isAdmin?: boolean;
}): CIQCrashAnalytics {
  return {
    crashId,
    errorMessage,
    errorType,
    browserName,
    deviceType,
    engineName,
    engineVersion,
    fullBrowserVersion,
    osName,
    osVersion,
    windowLocation: window.location.pathname,
    mobileModel,
    mobileVendor,
    userId,
    isAdmin: isAdmin ?? false
  };
}

export const getNonEmptyQueryString = (
  parameters: queryString.ParsedQuery<string>
): Record<string, string> => {
  const nonEmptyParams: Record<string, string> = {};
  for (const key in parameters) {
    if (parameters[key] !== "") {
      nonEmptyParams[key] = parameters[key] as string;
    }
  }

  return nonEmptyParams;
};
