import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import {
  DefaultReferralsService,
  DefaultToasterService,
  IReferralsService,
  SharedRoutes,
  ToasterService
} from "@arbolus-technologies/api";
import {
  generateEventName,
  useModalService
} from "@arbolus-technologies/features/common";
import { Referral, Slot } from "@arbolus-technologies/models/common";
import { EventCreationState } from "@arbolus-technologies/models/project";

import { SlotRow } from "../../Modules/ReferralsTable/scheduling/SlotRow";

interface BookingProps {
  slot?: Slot | SlotRow;
  timezone: string;
  referral: Referral;
  project: {
    id: string;
    name: string;
  };
  render: (
    timezone: string,
    onClick: () => void,
    slot?: Slot | SlotRow
  ) => React.ReactNode;
  referralsService?: IReferralsService;
  toasterService?: ToasterService;
}

export const Booking: React.FC<BookingProps> = ({
  slot,
  timezone,
  project,
  referral,
  render,
  referralsService = DefaultReferralsService,
  toasterService = DefaultToasterService
}) => {
  const { t } = useTranslation("referrals", { keyPrefix: "actions" });
  const { push } = useHistory();
  const modalService = useModalService();

  const goToEventPage = React.useCallback(() => {
    const title = generateEventName(referral.expertName, project.name);
    const eventCreationState: EventCreationState = {
      title,
      expert: {
        ...referral.expert,
        title: referral.expert.tagline || "",
        phoneNumber: referral.expert.phoneNumber ?? undefined
      },
      startDate: slot ? getDate(slot.startTime, timezone) : undefined,
      endDate: slot ? getDate(slot.endTime, timezone) : undefined,
      timezone: timezone
    };

    push(SharedRoutes.PROJECT_EVENT_ROUTE(project.id), eventCreationState);
  }, [referral, project, push, slot, timezone]);

  const createEvent = React.useCallback(() => {
    if (referral.isBookable) {
      goToEventPage();
      return;
    }

    if (!referral.onlyMissingReview) {
      toasterService.showError(t("approveAndBookError"));
      return;
    }

    modalService.openMainModal({
      title: t("approveAndBookTitle"),
      subtitle: t("approveAndBookSubtitle"),
      type: "confirmation",
      confirmText: t("approveAndBookConfirmText"),
      onConfirm: () => {
        referralsService
          .approve(project.id, referral.expert.expertId)
          .subscribe({
            next: () => goToEventPage(),
            error: toasterService.showApiErrors
          });
      }
    });
  }, [
    goToEventPage,
    modalService,
    referral,
    toasterService,
    project,
    referralsService,
    t
  ]);

  return (
    <div className="cursor-pointer">{render(timezone, createEvent, slot)}</div>
  );
};

function getDate(date: Date, timezone: string): Date {
  return new Date(moment.utc(date).tz(timezone).format("YYYY-MM-DD HH:mm:ss"));
}
