import clsx from "clsx";
import { type IntlTelInputRef } from "intl-tel-input/react";
import React, { lazy, Suspense, useRef } from "react";

import styles from "./CountriesPhoneInput.module.scss";

interface CountriesPhoneInputProps {
  phoneNumber: string;
  initialCountry?: string;
  onChangeNumber: (prefix: string, phoneNumValue: string) => void;
  phoneNumberValidHandle?: (value: boolean) => void;
  isPhoneNumberValid?: boolean;
}

export const CountriesPhoneInput: React.FC<CountriesPhoneInputProps> = ({
  phoneNumber,
  initialCountry = "us",
  onChangeNumber,
  phoneNumberValidHandle,
  isPhoneNumberValid = true
}) => {
  const ref = useRef<IntlTelInputRef | null>(null);

  const onChangeNumberHandle = (phoneNumValue: string) => {
    const countryData = ref?.current?.getInstance()?.getSelectedCountryData();
    const dialCode = `+${countryData?.dialCode}`;
    const onlyPhoneNum = phoneNumValue.replace(dialCode!, "");
    onChangeNumber(dialCode, onlyPhoneNum);
  };

  const IntlTelInput = lazy(() => import("intl-tel-input/reactWithUtils"));

  return (
    <div
      className={clsx(styles.intlTelInputWrapper, {
        [styles.invalidPhoneNumber]: !isPhoneNumberValid
      })}
    >
      <Suspense fallback={<div />}>
        <IntlTelInput
          ref={ref}
          initialValue={phoneNumber}
          onChangeNumber={onChangeNumberHandle}
          onChangeValidity={(value: boolean) => {
            phoneNumberValidHandle?.(value);
          }}
          initOptions={{ initialCountry }}
        />
      </Suspense>
    </div>
  );
};
