import { PageState } from "../models/definitions";
import { SAVE_PAGE_STATE, SavePageStateAction } from "./PagesActionTypes";

export const savePageState = (
  pageId: string,
  pageState: PageState
): SavePageStateAction => ({
  type: SAVE_PAGE_STATE,
  payload: {
    pageId,
    pageState
  }
});

export const PagesStoreActions = {
  savePageState
};
