import clsx from "clsx";
import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import styles from "./CanopyModal.module.scss";

interface CanopyModalProps {
  title: string;
  description: string;
  onCancel: () => void;
  onConfirm: () => void;
  confirmText: string;
  cancelText: string;
  toggleModal: boolean;
  modalMobile: boolean;
}

/**
 * @deprecated
 * use ArbolusModal from @arbolus-technologies/libs/ui/components
 */

export const CanopyModal: React.FC<CanopyModalProps> = ({
  title,
  description,
  onCancel,
  onConfirm,
  confirmText,
  cancelText,
  toggleModal,
  modalMobile
}) => (
  <Modal
    isOpen={toggleModal}
    className={clsx("modal-alert danger", modalMobile ? styles.isMobile : "")}
  >
    <ModalHeader>{title}</ModalHeader>
    <ModalBody>
      <div>{description}</div>
    </ModalBody>
    <ModalFooter>
      <Button size="md" color="secondary" onClick={onCancel}>
        {cancelText}
      </Button>
      <Button size="md" color="danger" onClick={(): void => onConfirm()}>
        {confirmText}
      </Button>
    </ModalFooter>
  </Modal>
);
