import {
  CIQError,
  ErrorResponse,
  ListBookmarkCollectionsQueryParams,
  PagedListResponse
} from "@arbolus-technologies/api";
import { BookmarkCollection } from "@arbolus-technologies/models/common";

import {
  LIST_COLLECTIONS,
  LIST_COLLECTIONS_FAILURE,
  LIST_COLLECTIONS_RELOAD,
  LIST_COLLECTIONS_RESET,
  LIST_COLLECTIONS_SUCCESS,
  ListCollectionsAction,
  ListCollectionsFailureAction,
  ListCollectionsReloadAction,
  ListCollectionsResetAction,
  ListCollectionsSuccessAction
} from "./ListCollectionsActionTypes";

export const listCollections = (
  searchTerm: string,
  queryParams: ListBookmarkCollectionsQueryParams
): ListCollectionsAction => ({
  type: LIST_COLLECTIONS,
  payload: { searchTerm, queryParams }
});

export const listCollectionsSuccess = (
  response: PagedListResponse<BookmarkCollection>
): ListCollectionsSuccessAction => ({
  type: LIST_COLLECTIONS_SUCCESS,
  payload: { response }
});

export const listCollectionsFailure = (
  error: ErrorResponse<CIQError>
): ListCollectionsFailureAction => ({
  type: LIST_COLLECTIONS_FAILURE,
  payload: { error }
});

export const listCollectionsReload = (): ListCollectionsReloadAction => ({
  type: LIST_COLLECTIONS_RELOAD
});

export const listCollectionsReset = (): ListCollectionsResetAction => ({
  type: LIST_COLLECTIONS_RESET
});
