import { Button, Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  CanopyQuestionMultiChoice,
  CreateEditQuestionFields,
  FormConstraints
} from "@arbolus-technologies/models/canopy";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { InputController } from "@arbolus-technologies/ui/components";

import { useCanopyContext } from "../../../../../../helpers/useCanopyContext";

import styles from "./MultiChoices.module.scss";

export const MultiChoices: React.FC = () => {
  const { t } = useTranslation("canopyBuilder");
  const { isCanopyActive, showUnsavedChanges } = useCanopyContext();

  const {
    control,
    formState: { errors, dirtyFields },
    watch
  } = useFormContext<CanopyQuestionMultiChoice>();

  const {
    fields: choiceFields,
    append: choiceAppend,
    remove: choiceRemove
  } = useFieldArray({
    name: CreateEditQuestionFields.ANSWER_CHOICES
  });

  const answerChoices = watch(CreateEditQuestionFields.ANSWER_CHOICES);
  const canAddIsOtherField = answerChoices.some((e) => e.isOther);
  const isAddChoiceDisabled =
    answerChoices.length === FormConstraints.MAX_CHOICES || isCanopyActive;

  return (
    <div className={styles.container}>
      {choiceFields.length > 0 && (
        <div className={styles.inputList}>
          {choiceFields.map((field, index) => (
            <div key={field.id} className={styles.inputContainer}>
              <InputController
                key={field.id}
                name={`answerChoices[${index}].text`}
                error={errors.answerChoices?.[index]?.text}
                control={control}
                placeholder={t("choicePlaceholder", {
                  index: index + 1
                })}
                type="text"
                disabled={answerChoices[index]?.isOther || isCanopyActive}
                classnames={clsx(styles.input, {
                  [styles.unsavedField]:
                    showUnsavedChanges &&
                    dirtyFields.answerChoices?.[index]?.text
                })}
                autoFocus
              />
              <Icon
                name="delete"
                color={ARBOLUS_COLORS.bColorGrayIcon}
                fontSize="18px"
                onClick={() => choiceRemove(index)}
                disabled={isCanopyActive}
              />
            </div>
          ))}
        </div>
      )}
      <div className={styles.buttonsContainer}>
        <Button
          onClick={() =>
            choiceAppend({
              text: "",
              isOther: false,
              sortOrder: answerChoices.length
            })
          }
          text={t("addChoice")}
          type="tertiary"
          disabled={isAddChoiceDisabled}
        />
        {!canAddIsOtherField && (
          <>
            <p>{t("or")}</p>
            <Button
              onClick={() =>
                choiceAppend({
                  text: t("other"),
                  isOther: true,
                  sortOrder: answerChoices.length
                })
              }
              text={t("addOther")}
              type="tertiary"
              disabled={isAddChoiceDisabled}
            />
          </>
        )}
      </div>
    </div>
  );
};
