import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "arbolus-ui-components";
import clsx from "clsx";
import React, { Fragment, useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { OptionChoice } from "@arbolus-technologies/api";
import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  CheckboxController,
  HR,
  InputController,
  WordCounter
} from "@arbolus-technologies/ui/components";

import { createMultiChoiceExpertSchema } from "./MultiChoiceExpertSchema";

import styles from "./MultiChoiceForExpertProvider.module.scss";

interface MultiChoiceForExpertProviderProps {
  options: OptionChoice[];
  minChoices: number;
  maxChoices: number;
  handleOnSubmit: (data: MultiFormData) => void;
  isAnswered?: boolean;
  isSaving?: boolean;
  canopyIsCompleted?: boolean;
}

export interface MultiFormData {
  selectedChoices: OptionChoice[];
  selectedMaxChoices?: {
    message: string;
    type: string;
  };
  selectedMinChoices?: {
    message: string;
    type: string;
  };
  OtherText?: string;
}

export const MultiChoiceForExpertProvider: React.FC<
  MultiChoiceForExpertProviderProps
> = ({
  options,
  minChoices,
  maxChoices,
  handleOnSubmit,
  isAnswered,
  isSaving,
  canopyIsCompleted
}) => {
  const { t } = useTranslation("canopyExpert");

  const loggedInExpert = useSelector(CacheSelector.loggedInUser());
  const isDnc = loggedInExpert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC;
  const isDisabled = isDnc || canopyIsCompleted;

  const {
    control,
    handleSubmit,
    watch,
    trigger,
    formState: { errors, isValid, isValidating }
  } = useForm<MultiFormData>({
    resolver: yupResolver(
      createMultiChoiceExpertSchema(minChoices, maxChoices)
    ),
    mode: "onChange",
    defaultValues: {
      selectedChoices: options
    }
  });

  const { fields } = useFieldArray({
    control,
    name: "selectedChoices",
    keyName: "id"
  });

  const chooseOption = (minimum: number, maximum: number): string => {
    if (minimum === maximum) {
      return minimum === 1 ? "Choose 1 option" : `Choose ${minimum} options`;
    }
    return `Choose between ${minimum} to ${maximum} options`;
  };

  useEffect(
    () =>
      // We use this to skip the trigger in first render
      () => {
        trigger();
      },
    [isValidating, trigger]
  );

  return (
    <div className={styles.container}>
      <div
        className={clsx(
          styles.chooseOption,
          (errors.selectedMaxChoices || errors.selectedMinChoices) && styles.red
        )}
      >
        {chooseOption(minChoices, maxChoices)}
      </div>
      <div className={styles.fieldContainer}>
        {fields.map((field, index) => {
          const isOther = watch("selectedChoices")[index].isOther;
          const isSelected = watch("selectedChoices")[index].isSelected;
          const otherText = watch("selectedChoices")[index]?.otherText;
          return (
            <Fragment key={field.id}>
              <CheckboxController
                name={`selectedChoices[${index}].isSelected`}
                errors={errors}
                control={control}
                text={field.text}
                disabled={isDisabled}
              />

              {isOther && isSelected && (
                <div>
                  <InputController
                    type="textarea"
                    classnames={styles.otherText}
                    control={control}
                    name={`selectedChoices[${index}].otherText`}
                    error={errors.selectedChoices?.[index]?.otherText}
                    placeholder={t("otherText")}
                    required
                    isInvalid={!!errors.OtherText}
                    size="big"
                    disabled={isDisabled}
                  />
                  {errors.OtherText && (
                    <div className={styles.error}>{t("requiredText")}</div>
                  )}
                  <WordCounter
                    maxLength={200}
                    textLength={otherText?.length ?? 0}
                  />
                </div>
              )}
            </Fragment>
          );
        })}
      </div>
      <HR margin={{ top: 3, bottom: 5 }} />
      {!isDisabled && (
        <div className={styles.buttonContainer}>
          <Button
            type="primary"
            text={isAnswered ? t("update") : t("save")}
            disabled={!isValid || isSaving}
            onClick={handleSubmit(handleOnSubmit)}
          />
        </div>
      )}
    </div>
  );
};
