import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";

import { REFERRAL_SUB_STATE } from "@arbolus-technologies/models/common";
import { COMPLIANCE_UPDATE_FORMAT } from "@arbolus-technologies/utils";

import { User } from "../../../../../../../models/user";
import { UtilsService } from "../../../../../../../services";
import { CIQAvatar } from "../../../../../../app/components";
import CompliancePill from "../compliancePill/CompliancePill";

const complianceAutoApproval = "Compliance automatically approved";

interface ComplianceReviewedPaneProps {
  complianceStatus: string;
  complianceNote?: string;
  complianceUpdatedBy: User;
  firstCall: string;
  updateDate?: Date;
}

const ComplianceReviewedPane: React.FC<ComplianceReviewedPaneProps> = ({
  complianceStatus,
  complianceNote,
  complianceUpdatedBy,
  firstCall,
  updateDate
}) => {
  const { t } = useTranslation("expertComplianceTab");

  const isCompliancePassed = complianceStatus === REFERRAL_SUB_STATE.ACCEPT;
  const isComplianceRejected = complianceStatus === REFERRAL_SUB_STATE.REJECT;
  const isAutomaticallyApproved = complianceNote === complianceAutoApproval;

  let complianceAnswer = "";
  let profileImageUrl: string | undefined = "";
  let complianceApprovedBy = "";

  if (complianceUpdatedBy) {
    profileImageUrl = complianceUpdatedBy.profileImageUrl;
    complianceApprovedBy = UtilsService.displayUserName(complianceUpdatedBy);
  }

  if (isCompliancePassed) {
    complianceAnswer = t("passed");
  } else if (isComplianceRejected) {
    complianceAnswer = t("failed");
  }

  return (
    <div className="compliance-status">
      <Row className="header">
        <Col>
          <h2>{t("finalApproval")}</h2>
        </Col>
        <Col>
          <div className="status">
            <CompliancePill
              text={complianceAnswer}
              isComplianceStatus={isCompliancePassed}
            />
            {complianceUpdatedBy && isCompliancePassed && (
              <CompliancePill
                text={t("joinFirstCall", { answer: firstCall.toUpperCase() })}
                isPreferred
              />
            )}
          </div>
        </Col>
      </Row>
      {!isAutomaticallyApproved && (
        <div className="comment">
          {complianceNote ? `"${complianceNote}"` : t("noComments")}
        </div>
      )}
      {complianceUpdatedBy && (
        <div className="compliance-manager">
          <div className="row">
            {isAutomaticallyApproved ? (
              <p>{t("automaticallyApproved")}</p>
            ) : (
              <>
                <CIQAvatar
                  username={complianceApprovedBy}
                  profileImageUrl={profileImageUrl}
                />
                <div>
                  <p style={{ marginLeft: "8px" }}>{complianceApprovedBy} - </p>
                </div>
              </>
            )}
            <p>{moment(updateDate).format(COMPLIANCE_UPDATE_FORMAT)}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ComplianceReviewedPane;
