import { Icon } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";

import { NumberCircle } from "@arbolus-technologies/ui/components";
import { APP_DATE_FORMAT, formatDate } from "@arbolus-technologies/utils";

import { TranscriptStatusTag } from "../../../../TranscriptStatusTag/TranscriptStatusTag";
import { AnswerQuestion, Data } from "../../../types";
import { Transcript } from "../Transcript/Transcript";
import { TranscriptButtons } from "../TranscriptButtons/TranscriptButtons";

import styles from "./WithQuestion.module.scss";

interface WithQuestionProps {
  question: AnswerQuestion;
  redirectToQuestion: (questionId: string) => void;
  data: Data;
  videoDuration: string;
}

export const WithQuestion: React.FC<WithQuestionProps> = ({
  question,
  redirectToQuestion,
  data,
  videoDuration
}) => {
  const { t } = useTranslation("answer");
  const {
    answer,
    wordsToHighlight,
    transcriptState,
    resetTranscript,
    internalTranscript,
    transcriptStatus,
    isDrawerOpen,
    saveTranscript,
    handleSetTranscriptState,
    handleSetResetModal,
    resetModal,
    handleShareLink,
    handleTranscriptClick
  } = data;
  const { id, text, details } = question;
  const { created } = answer;

  return (
    <div className={styles.container}>
      {/* Javascript shenanigans make number 0 to not have a style. This is a workaround to never display 0. */}
      <NumberCircle
        number={question.sortOrder ? question.sortOrder + 1 : 1}
        classnames={styles.number}
      />
      <div className={styles.contentContainer}>
        <h1 className={styles.title} onClick={() => redirectToQuestion(id)}>
          {text}
        </h1>
        {isDrawerOpen && (
          <>
            <h2 className={styles.subtitle}>{details}</h2>
            <div className={styles.buttonsContainer}>
              <div className={styles.innerButtonsContainer}>
                <TranscriptStatusTag transcriptStatus={transcriptStatus} />
                <div
                  onClick={() => handleShareLink(answer.id)}
                  title={t("copy")}
                  className={styles.icon}
                >
                  <Icon name="link" color="#7f849f" fontSize="24px" />
                </div>
              </div>
              <TranscriptButtons
                handleSetResetModal={handleSetResetModal}
                handleSetTranscriptState={handleSetTranscriptState}
                transcriptStatus={transcriptStatus}
              />
            </div>
          </>
        )}
        <Transcript
          transcriptState={transcriptState}
          resetTranscript={resetTranscript}
          internalTranscript={internalTranscript}
          isDrawerOpen={isDrawerOpen}
          saveTranscript={saveTranscript}
          handleSetTranscriptState={handleSetTranscriptState}
          handleSetResetModal={handleSetResetModal}
          resetModal={resetModal}
          wordsToHighlight={wordsToHighlight}
          handleTranscriptClick={handleTranscriptClick}
        />
        <p className={styles.responseDateInner}>
          <span className={styles.videoDuration}>{videoDuration}</span>
          {` - `}
          {formatDate(created, APP_DATE_FORMAT)}
        </p>
      </div>
    </div>
  );
};
