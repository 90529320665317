import React from "react";

import { IAvatar } from "@arbolus-technologies/models/common";

import { Avatar, AvatarType } from "../Avatar/Avatar";

import styles from "./AvatarGroup.module.scss";

export interface AvatarGroupProps {
  avatars: IAvatar[];
  totalCount?: number;
  type?: AvatarType;
}

export const AvatarGroup: React.FC<AvatarGroupProps> = ({
  avatars,
  totalCount,
  type = "avatarGroupCircle"
}) => (
  <div className={styles.avatarsContainer}>
    {avatars.slice(0, 4).map((avatar) => (
      <div key={avatar.name} className={styles.avatarContainer}>
        <Avatar avatar={avatar} type={type} />
      </div>
    ))}
    {avatars.length > 4 && (
      <div className={styles.avatarContainer}>
        <Avatar
          data-testid="number-avatar-plus"
          type={type}
          adding
          avatar={{
            name: (totalCount ? totalCount - 4 : avatars.length - 4).toString()
          }}
        />
      </div>
    )}
  </div>
);
