import i18next from "i18next";

import { SORT_DIRECTION, SortCriteria } from "@arbolus-technologies/api";

export const CLIENT_SORT_CRITERIA = {
  NAME_ASC: {
    name: i18next.t("clientNetwork:aToz"),
    value: "name",
    direction: SORT_DIRECTION.ASCENDING
  } as SortCriteria,
  NAME_DESC: {
    name: i18next.t("clientNetwork:zToa"),
    value: "name",
    direction: SORT_DIRECTION.DESCENDING
  } as SortCriteria
};
