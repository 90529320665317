import React from "react";

import { QuestionAnswer } from "@arbolus-technologies/api";
import { YELLOW } from "@arbolus-technologies/theme";
import { DangerouslySetInnerHTML } from "@arbolus-technologies/ui/components";
import { highlightWords } from "@arbolus-technologies/utils";

import styles from "./ScreeningAnswer.module.scss";

interface ScreeningAnswerProps {
  questionAnswer: QuestionAnswer;
  index: number;
  keywordsList: string[];
}
export const ScreeningAnswer: React.FC<ScreeningAnswerProps> = ({
  questionAnswer,
  index,
  keywordsList
}) => {
  const { projectAnswer, projectQuestion } = questionAnswer;

  const highlightedWords = highlightWords({
    text: projectAnswer.answer,
    tags: keywordsList,
    highlightColor: YELLOW,
    boldedWords: false
  });

  return (
    <div className={styles.questionContainer}>
      <p className={styles.questionTitle}>
        Q{index + 1}: {projectQuestion.question}
      </p>
      <DangerouslySetInnerHTML text={highlightedWords} />
    </div>
  );
};
