import { Drawer } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { Country } from "../../interfaces";
import { CountryPanelBody } from "./CountryPanelBody/CountryPanelBody";

interface CountrySelectionPanelProps {
  country?: Country | null;
  onCountrySelected: (country: Country, rowId?: string) => void;
  isOpen: boolean;
  handleClose: () => void;
}

export const CountrySelectionPanel: React.FC<CountrySelectionPanelProps> = ({
  country,
  onCountrySelected,
  isOpen,
  handleClose
}) => {
  const { t } = useTranslation("countrySelectionPanel");

  return (
    <Drawer
      title={t("title")}
      onClose={handleClose}
      open={isOpen}
      destroyOnClose
    >
      <CountryPanelBody
        onSelectCountry={(country) => {
          onCountrySelected(country);
          handleClose();
        }}
        country={country ?? null}
      />
    </Drawer>
  );
};
