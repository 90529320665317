import React, { useCallback, useState } from "react";
import { Control, FieldError } from "react-hook-form";
import { Label } from "reactstrap";

import { ListEvent, ListProject } from "@arbolus-technologies/api";

import { ProjectAdminSelector } from "../ProjectSelectors/ProjectAdminSelector";
import { EventAdminSelector } from "./EventAdminSelector";

interface ProjectEventAdminSelectorProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, object>;
  error?: FieldError;
  label: string;
  labelStyle: string;
  name: string;
  onSelect: (event: ListEvent) => void;
}

export const ProjectEventAdminSelector: React.FC<
  ProjectEventAdminSelectorProps
> = ({ control, error, label, labelStyle, name, onSelect }) => {
  const [projectId, setProjectId] = useState<string | undefined>(undefined);

  const onSelectProject = useCallback(
    (selectedProjectId: string) => setProjectId(selectedProjectId),
    []
  );

  return (
    <>
      <ProjectAdminSelector
        control={control}
        name="project"
        error={error}
        projectStates={["Active", "Draft", "Archive"]}
        displayResults={10}
        onSelect={(project: ListProject) => onSelectProject(project?.id)}
      />
      {projectId && (
        <>
          <Label className={labelStyle}>{label}</Label>
          <EventAdminSelector
            control={control}
            name={name}
            error={error}
            displayResults={10}
            onSelect={onSelect}
            projectId={projectId}
          />
        </>
      )}
    </>
  );
};
