/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Subscription } from "rxjs";
import SimpleBar from "simplebar-react";

import { ToasterService } from "@arbolus-technologies/api";
import { Loader } from "@arbolus-technologies/ui/components";

import { UI_WINDOW_HEIGHT } from "../../../../../constants/ui";
import { CIQError, ErrorResponse } from "../../../../../models/api";
import {
  NotificationSubscription,
  ProjectSettingsUpdateRequest
} from "../../../../../models/project";
import { ProjectService } from "../../../../../services";
import NotificationSettingsList from "./notificationSettingList/NotificationSettingsList";

const notification = new ToasterService();

interface NotificationSettingsProps extends WithTranslation {
  onPanelClose: () => void;
  projectId: string;
}
interface NotificationSettingsState {
  isLoading: boolean;
  isSaving: boolean;
}

class NotificationSettings extends Component<
  NotificationSettingsProps,
  NotificationSettingsState
> {
  constructor(props: NotificationSettingsProps) {
    super(props);

    this.state = {
      isLoading: true,
      isSaving: false
    };
  }

  componentDidMount(): void {
    this.fetchProjectSettings();
  }

  componentWillUnmount(): void {
    this.fetchProjectSettingsSubscription?.unsubscribe();
    this.updateProjectSettingsSubscription?.unsubscribe();
  }

  private fetchProjectSettingsSubscription?: Subscription;

  private notificationSubscription?: NotificationSubscription;

  private pushNotifications?: boolean;

  private updateProjectSettingsSubscription?: Subscription;

  fetchProjectSettings = (): void => {
    const { projectId, onPanelClose } = this.props;

    this.fetchProjectSettingsSubscription = ProjectService.getProjectSettings(
      projectId
    ).subscribe(
      ({ notificationSubscription, pushNotifications }) => {
        this.notificationSubscription = notificationSubscription;
        this.pushNotifications = pushNotifications;
        this.setState({
          isLoading: false
        });
      },
      (error: ErrorResponse<CIQError>) => {
        notification.showError(error.message);
        onPanelClose();
      }
    );
  };

  updateProjectSettings = (
    projectSettings: ProjectSettingsUpdateRequest
  ): void => {
    const { projectId, t } = this.props;

    this.setState({
      isSaving: true
    });

    this.updateProjectSettingsSubscription =
      ProjectService.updateProjectSettings(projectId, {
        ...projectSettings,
        notificationSubscription: {
          ...projectSettings.notificationSubscription,
          MobileMessage:
            !!projectSettings.notificationSubscription.MobileMessage
        }
      }).subscribe(
        () => {
          this.notificationSubscription =
            projectSettings.notificationSubscription;
          this.pushNotifications = projectSettings.pushNotifications;
          this.setState({
            isSaving: false
          });
          notification.showSuccess(t("projectSettingsSaveSuccess"));
        },
        (error: ErrorResponse<CIQError>) => {
          this.setState({
            isSaving: false
          });
          notification.showError(error.message);
        }
      );
  };

  render(): JSX.Element {
    const { onPanelClose, t } = this.props;
    const { isLoading, isSaving } = this.state;

    return (
      <SimpleBar
        className="notification-settings-container simplebar-light"
        style={{
          height: UI_WINDOW_HEIGHT,
          overflowX: "hidden"
        }}
      >
        {isLoading ? (
          <Loader isFull />
        ) : (
          <div className="content-panel-body">
            <div className="panel-header">
              <h2>{t("projectNotification")}</h2>
              <div className="btn-close" onClick={onPanelClose}>
                <i className="ciq-icon ciq-close" />
              </div>
            </div>
            <div className="notification-settings-body panel-body">
              <NotificationSettingsList
                isPanel
                notificationSubscription={this.notificationSubscription!}
                pushNotifications={this.pushNotifications}
                onUpdateSettings={this.updateProjectSettings}
                isLoading={isSaving}
              />
            </div>
          </div>
        )}
      </SimpleBar>
    );
  }
}

export default withTranslation("notificationSettings")(NotificationSettings);
