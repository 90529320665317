export const OPEN_CONTENT_PANEL = "CONTENT_PANEL_OPEN_CONTENT_PANEL";
export const CLOSE_CONTENT_PANEL = "CONTENT_PANEL_CLOSE_CONTENT_PANEL";

export interface OpenContentPanelAction {
  type: typeof OPEN_CONTENT_PANEL;
  payload: {};
}

export interface CloseContentPanelAction {
  type: typeof CLOSE_CONTENT_PANEL;
  payload: {};
}
