import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import {
  BookmarkCollection,
  BookmarkCollectionModel,
  BookmarkList
} from "@arbolus-technologies/models/common";

import { BOOKMARKS_API } from "../constants/api";
import {
  CreatedResponse,
  DeletedResponse,
  PagedListResponse,
  SuccessResponse
} from "../models/api";
import {
  BookmarksListQueryParams,
  CreateBookmark,
  CreateBookmarkCollection,
  DeleteBookmark,
  ListBookmarkCollectionsQueryParams
} from "../models/bookmarks";
import { FileResponse } from "../models/common";
import { restService } from "../restService";
import { mapPagedListResponse } from "../utils/paginationUtils";

export const BookmarkService = {
  listBookmarkCollections: (
    params: ListBookmarkCollectionsQueryParams
  ): Observable<PagedListResponse<BookmarkCollection>> =>
    restService
      .get<PagedListResponse<BookmarkCollectionModel>>(
        BOOKMARKS_API.LIST_BOOKMARK_COLLECTIONS(),
        params
      )
      .pipe(mapPagedListResponse(BookmarkCollection.create)),
  getBookmarkCollection: (id: string): Observable<BookmarkCollection> =>
    restService
      .get<BookmarkCollectionModel>(BOOKMARKS_API.GET_BOOKMARK_COLLECTION(id))
      .pipe(map(BookmarkCollection.create)),
  postBookmarkCollection: (
    body: CreateBookmarkCollection
  ): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(
      BOOKMARKS_API.POST_BOOKMARK_COLLECTION(),
      body
    ),
  updateBookmarkCollection: (
    id: string,
    body: Partial<CreateBookmarkCollection>
  ): Observable<SuccessResponse> =>
    restService.put<SuccessResponse>(
      BOOKMARKS_API.PUT_BOOKMARK_COLLECTION(id),
      body
    ),
  deleteBookmarkCollection: (id: string): Observable<DeletedResponse> =>
    restService.delete<DeletedResponse>(
      BOOKMARKS_API.DELETE_BOOKMARK_COLLECTION(id)
    ),
  getBookmarksList: (
    params: BookmarksListQueryParams
  ): Observable<PagedListResponse<BookmarkList>> =>
    restService
      .get<PagedListResponse<BookmarkList>>(
        BOOKMARKS_API.GET_BOOKMARKS_LIST(params.bookmarkCollectionId),
        params
      )
      .pipe(mapPagedListResponse(BookmarkList.create)),
  postBookmark: (body: CreateBookmark): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(BOOKMARKS_API.POST_BOOKMARK(), body),
  deleteBookmark: (body: DeleteBookmark): Observable<DeletedResponse> =>
    restService.delete<DeletedResponse>(
      BOOKMARKS_API.DELETE_BOOKMARK(),
      undefined,
      body
    ),
  exportBookmarks: (id: string): Observable<FileResponse> =>
    restService.get<FileResponse>(BOOKMARKS_API.EXPORT_BOOKMARK_COLLECTION(id))
};
