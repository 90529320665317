import React from "react";
import { useTranslation } from "react-i18next";

import { ButtonNoBorderWithIcon } from "@arbolus-technologies/ui/components";

import styles from "./CanopyCardMarketing.module.scss";

export const CanopyCardMarketing: React.FC = () => {
  const { t } = useTranslation("canopyCardMarketing");

  return (
    <div className={styles.card}>
      <div className={styles.content}>
        <h3 className={styles.title}>
          Arbolus<span className={styles.purple}>Canopy</span>
        </h3>
        <p className={styles.text}>{t("text")}</p>
        <ButtonNoBorderWithIcon
          align={"left"}
          iconName="chevron_right"
          name="New Canopy"
          classnames={styles.newCanopyButton}
        />
      </div>
    </div>
  );
};
