import { Icon } from "arbolus-ui-components";
import React from "react";
import { useSelector } from "react-redux";

import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

export function SendMessageButton({
  onSubmit,
  editorIsEmpty
}: {
  onSubmit: () => void;
  editorIsEmpty: boolean;
}): React.ReactElement {
  const isMessageSending = useSelector(ProjectNxSelector.isSendingMessage());
  const isDisabled = isMessageSending || editorIsEmpty;

  return (
    <Icon
      name="send"
      fontSize="20px"
      color={
        isDisabled
          ? ARBOLUS_COLORS.bColorSecondaryGreyMid
          : ARBOLUS_COLORS.bColorBasePurple
      }
      onClick={onSubmit}
      disabled={isDisabled}
    />
  );
}
