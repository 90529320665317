import React from "react";
import { useTranslation } from "react-i18next";

import { ArbolusModal } from "@arbolus-technologies/ui/components";

interface UpdateUserPermissionsModalProps {
  userName: string;
  isOpen: boolean;
  toggle: () => void;
  onConfirm: () => void;
}

export const UpdateUserPermissionsModal: React.FC<
  UpdateUserPermissionsModalProps
> = ({ userName, isOpen, toggle, onConfirm }) => {
  const { t } = useTranslation("permissions");

  return (
    <ArbolusModal
      title={t("confirmationModalTitle", { userName })}
      subtitle={t("confirmationModalSubtitle")}
      toggle={toggle}
      isOpen={isOpen}
      leftButton={{
        text: t("cancel"),
        onClick: toggle,
        type: "tertiary"
      }}
      rightButton={{
        text: t("confirmUpdate"),
        onClick: onConfirm,
        type: "confirmation"
      }}
    />
  );
};
