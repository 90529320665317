import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  ResponseAllExpertsTable,
  StatusExpertTable
} from "@arbolus-technologies/api";

import styles from "./ActivityTime.module.scss";

interface ActivityTimeProps {
  row: ResponseAllExpertsTable;
  handleSlidePanelAvailability: (
    projectId: string | undefined,
    expertId: string,
    referralId: string
  ) => void;
}

export const ActivityTime: React.FC<ActivityTimeProps> = ({
  row,
  handleSlidePanelAvailability
}) => {
  const { t } = useTranslation("expertAvailabilityStatus");
  const {
    expert: { id: expertId, isAnyValidAvailability },
    projectId,
    id,
    applicationStatus
  } = row;

  const isValid =
    isAnyValidAvailability && applicationStatus !== StatusExpertTable.Reject;

  const handleTimeIconClick = () => {
    if (isValid) {
      return handleSlidePanelAvailability(projectId, expertId, id);
    }
  };

  return (
    <div className={clsx(styles.container, { [styles.disabled]: !isValid })}>
      <span
        className={styles.wrapperIcon}
        data-title={isAnyValidAvailability ? t("available") : t("unavailable")}
        onClick={handleTimeIconClick}
      >
        <Icon name="Schedule" fontSize="20px" />
      </span>
    </div>
  );
};
