import { GetContextMenuItemsParams, MenuItemDef } from "ag-grid-community";

import {
  ClipboardManager,
  DefaultClipboardManager
} from "@arbolus-technologies/features/common";
import {
  DO_NOT_CONTACT_STATUS,
  Referral
} from "@arbolus-technologies/models/common";

import {
  IBulkItemActionParams,
  ItemActionParams,
  ReferralGridContext
} from "../../Models/ReferralsTable";
import { BULK_ACTIONS } from "../../Modules/ReferralsTable/statusPanels/BulkActionsStatusPanel";
import {
  DefaultBulkActionsMenuService,
  IBulkActionsMenuService
} from "./BulkActionsMenuService";
import {
  DefaultBulkReferralActionsService,
  IBulkReferralActionsService
} from "./BulkReferralActionsService";
import { ContextMenuService } from "./ContextMenuService";
import {
  DefaultExportMenuService,
  IExportMenuService
} from "./ExportMenuService";

export class RejectedMenuService extends ContextMenuService {
  private _bulkActionsMenuService: IBulkActionsMenuService;

  constructor(
    exportMenuService: IExportMenuService,
    bulkActionsService: IBulkReferralActionsService,
    clipboardManager: ClipboardManager,
    bulkActionsMenuService: IBulkActionsMenuService
  ) {
    super(exportMenuService, bulkActionsService, clipboardManager);
    this._bulkActionsMenuService = bulkActionsMenuService;
  }

  getItems = ({
    api,
    node,
    context,
    value
  }: GetContextMenuItemsParams<Referral, ReferralGridContext>): (
    | string
    | MenuItemDef
  )[] => {
    if (value === BULK_ACTIONS) {
      const bulkParams: IBulkItemActionParams = {
        api,
        ...context
      };
      return [this._bulkActionsMenuService.getRemoveItem(bulkParams)];
    }

    if (!node?.data || node.group) {
      return [];
    }

    const params: ItemActionParams = {
      api,
      referral: node.data,
      ...context
    };

    if (
      params.referral.expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC
    ) {
      return [this.getRemoveItem(params)];
    }

    return [
      this.getReactivateItem(params),
      "separator",
      this.getCopyEmailItem(params),
      this.getCopyPhoneItem(params),
      "separator",
      this.getRemoveItem(params)
    ];
  };
}

export const DefaultRejectedMenuService = new RejectedMenuService(
  DefaultExportMenuService,
  DefaultBulkReferralActionsService,
  DefaultClipboardManager,
  DefaultBulkActionsMenuService
);
