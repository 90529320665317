import {
  ColDef,
  GridApi,
  GridReadyEvent,
  InitialGroupOrderComparatorParams,
  RowHeightParams
} from "ag-grid-community";
import clsx from "clsx";
import React from "react";
import { useSelector } from "react-redux";

import { SlotsReferral } from "@arbolus-technologies/models/common";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { ArbolusGrid } from "@arbolus-technologies/ui/components";

import { GroupSlotsRenderer } from "../../../Components/CellRenderers/GroupSlotsRenderer";
import { IContextMenuService } from "../../../Services/Referrals/ContextMenuService";
import { DefaultSchedulingMenuService } from "../../../Services/Referrals/SchedulingMenuService";
import { bookActionsCol } from "../columns/ActionColumns";
import { angleCol, taglineCol } from "../columns/ColumnDefinitions";
import { approvedDateCol, modifiedDateCol } from "../columns/DateColumns";
import { expertNameCol } from "../columns/ExpertColumn";
import { getOwnerCol } from "../columns/OwnerColumn";
import {
  dayGroupCol,
  slotGroupCol,
  slotStartFilterCol
} from "../columns/SlotsColumns";
import { useDefaultReferralGridColumns } from "../hooks/useDefaultReferralGridColumns";
import { useReferralGridContext } from "../hooks/useReferralGridContext";
import { commonProps } from "../referralsTableCommonProps";
import { BulkActionsStatusPanel } from "../statusPanels/BulkActionsStatusPanel";
import { ExpandCollapseStatusPanel } from "../statusPanels/ExpandCollapseStatusPanel";
import { SchedulingViewStatusPanel } from "../statusPanels/SchedulingViewStatusPanel";
import { SlotsFilterStatusPanel } from "../statusPanels/SlotsFilterStatusPanel";
import { SlotRow } from "./SlotRow";

import styles from "./SlotsSchedulingTable.module.scss";

interface SlotsSchedulingTableProps {
  referrals?: SlotsReferral[];
  contextMenuService?: IContextMenuService;
}

export const SlotsSchedulingTable: React.FC<SlotsSchedulingTableProps> = ({
  referrals,
  contextMenuService = DefaultSchedulingMenuService
}) => {
  const [api, setApi] = React.useState<GridApi<SlotRow>>();
  const [rows, setRows] = React.useState<SlotRow[]>();
  const project = useSelector(ProjectNxSelector.projectData());

  const defaultColDef = useDefaultReferralGridColumns();
  const gridContext = useReferralGridContext(api, rows);

  const onGridReady = React.useCallback(({ api }: GridReadyEvent) => {
    setApi(api);
  }, []);

  React.useEffect(
    function mapSlotsReferralsToRows() {
      if (!referrals) {
        return;
      }

      setRows(mapRows(referrals));
    },
    [referrals]
  );

  const columnDefs = React.useMemo<ColDef[]>(
    () => [
      dayGroupCol,
      slotGroupCol,
      {
        ...expertNameCol,
        checkboxSelection: false,
        headerCheckboxSelection: false
      },
      { ...taglineCol, editable: false },
      angleCol,
      { ...getOwnerCol(project!.arbolusTeam), editable: false },
      approvedDateCol,
      modifiedDateCol,
      bookActionsCol,
      slotStartFilterCol
    ],
    [project]
  );

  const statusBar = React.useMemo(
    () => ({
      statusPanels: [
        {
          statusPanel: ExpandCollapseStatusPanel,
          align: "left"
        },
        {
          statusPanel: SlotsFilterStatusPanel,
          align: "center"
        },
        {
          statusPanel: SchedulingViewStatusPanel,
          align: "right"
        },
        {
          statusPanel: BulkActionsStatusPanel,
          align: "right"
        }
      ]
    }),
    []
  );

  return (
    <div className="d-flex h-100 flex-column status-bar-gap">
      <div className={clsx(styles.slotsGrid, "h-100")}>
        <ArbolusGrid
          {...commonProps}
          groupDisplayType="groupRows"
          getRowHeight={getRowHeight}
          initialGroupOrderComparator={initialGroupOrderComparator}
          columnDefs={columnDefs}
          getRowId={({ data }) => data.rowId}
          getContextMenuItems={contextMenuService.getItems}
          context={gridContext}
          onGridReady={onGridReady}
          rowData={rows}
          defaultColDef={defaultColDef}
          statusBar={statusBar}
          groupRowRendererParams={{ innerRenderer: GroupSlotsRenderer }}
        />
      </div>
    </div>
  );
};

function getRowHeight(params: RowHeightParams) {
  if (!params.node.group) {
    return undefined;
  }

  return params.node.level === 0 ? 35 : 31;
}

function mapRows(referrals: SlotsReferral[]): SlotRow[] {
  return referrals.flatMap((referral) =>
    referral.slots.map((slot) => SlotRow.create(referral, slot))
  );
}

function initialGroupOrderComparator({
  nodeA,
  nodeB
}: InitialGroupOrderComparatorParams<SlotRow>): number {
  const a = nodeA.allLeafChildren[0].data;
  const b = nodeB.allLeafChildren[0].data;
  if (!a || !b) {
    return 0;
  }

  const diff = a.startTime.getTime() - b.startTime.getTime();
  if (diff !== 0) {
    return diff;
  }

  return a.endTime.getTime() - b.endTime.getTime();
}
