import clsx from "clsx";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  CanopyQuestionValue,
  CreateEditQuestionFields
} from "@arbolus-technologies/models/canopy";
import { InputController } from "@arbolus-technologies/ui/components";

import { useCanopyContext } from "../../../../../../helpers/useCanopyContext";

import styles from "./ValueTypes.module.scss";

export const ValueRange: React.FC = () => {
  const { t } = useTranslation("canopyBuilder");
  const { isCanopyActive, showUnsavedChanges } = useCanopyContext();

  const {
    control,
    formState: { errors, dirtyFields }
  } = useFormContext<CanopyQuestionValue>();

  return (
    <div className={styles.rangeContainer}>
      <div className={styles.rangeInnerContainer}>
        <InputController
          name={
            CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE_NUMBER_INITIAL_RANGE
          }
          control={control}
          classnames={clsx(styles.input, {
            [styles.unsavedField]:
              showUnsavedChanges &&
              dirtyFields[
                CreateEditQuestionFields
                  .ANSWER_PREFERENCES_TYPE_VALUE_NUMBER_INITIAL_RANGE
              ]
          })}
          error={
            errors[
              CreateEditQuestionFields
                .ANSWER_PREFERENCES_TYPE_VALUE_NUMBER_INITIAL_RANGE
            ]
          }
          placeholder={t("fromNumberPlaceholder")}
          type="number"
          disabled={isCanopyActive}
          onlyInteger
        />
      </div>
      <span>{t("to")}</span>
      <div className={styles.rangeInnerContainer}>
        <InputController
          name={
            CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE_NUMBER_FINAL_RANGE
          }
          control={control}
          classnames={clsx(styles.input, {
            [styles.unsavedField]:
              showUnsavedChanges &&
              dirtyFields[
                CreateEditQuestionFields
                  .ANSWER_PREFERENCES_TYPE_VALUE_NUMBER_FINAL_RANGE
              ]
          })}
          error={
            errors[
              CreateEditQuestionFields
                .ANSWER_PREFERENCES_TYPE_VALUE_NUMBER_FINAL_RANGE
            ]
          }
          placeholder={t("toNumberPlaceholder")}
          type="number"
          disabled={isCanopyActive}
          onlyInteger
        />
      </div>
    </div>
  );
};
