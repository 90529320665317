import i18next from "i18next";
import moment from "moment";

import {
  ExpertSlot,
  ExpertSlotDetails,
  ExpertSlotsByDate
} from "@arbolus-technologies/api";
import { DATE_TIME_FORMAT } from "@arbolus-technologies/utils";

export const parseExpertSlotsByDate = (
  slots: ExpertSlot[],
  experts: ExpertSlotDetails[],
  projectTimezone: string
): ExpertSlotsByDate[] => {
  let expertMap: { [id: string]: ExpertSlotDetails } = {};
  const expertSlotsByDate: ExpertSlotsByDate[] = [];

  // Step 1: Initialize experts and populate the expertMap
  expertMap = initializeExperts(experts, expertMap);

  // Step 2: Process slots, update expert timeSlots, and expertSlotsByDate
  processSlots(slots, expertMap, expertSlotsByDate, projectTimezone);
  return expertSlotsByDate.map((expertSlotByDate) => ({
    ...expertSlotByDate,
    count: expertSlotByDate.experts.reduce(
      (total, expert) => total + expert.slots.length,
      0
    )
  }));
};

const initializeExperts = (
  experts: ExpertSlotDetails[],
  expertMap: { [id: string]: ExpertSlotDetails }
): { [id: string]: ExpertSlotDetails } => {
  // Create a new object by spreading the existing expertMap entries into it
  const updatedExpertMap: { [id: string]: ExpertSlotDetails } = {
    ...expertMap
  };
  for (const expert of experts) {
    // Create a copy of the expert object with an empty timeSlots array
    const expertCopy = { ...expert, timeSlots: [] };
    // Update the expertMap with the copied expert object
    updatedExpertMap[expert.expertId] = expertCopy;
  }

  // Return the updated expertMap
  return updatedExpertMap;
};

// Processes slots, updates expert timeSlots, and expertSlotsByDate
const processSlots = (
  slots: ExpertSlot[],
  expertMap: { [id: string]: ExpertSlotDetails },
  expertSlotsByDate: ExpertSlotsByDate[],
  projectTimezone: string
): void => {
  for (const slot of slots) {
    const expertId = slot.expertId;
    if (expertId in expertMap) {
      // Update the expert's timeSlots array with the current slot
      expertMap[expertId].timeSlots.push(slot);
      // Step 3: Update expertSlotsByDate based on the slot's date and expert
      updateExpertSlotsByDate(
        slot,
        expertId,
        expertMap,
        expertSlotsByDate,
        projectTimezone
      );
    }
  }
};

const updateExpertSlotsByDate = (
  slot: ExpertSlot,
  expertId: string,
  expertMap: { [id: string]: ExpertSlotDetails },
  expertSlotsByDate: ExpertSlotsByDate[],
  projectTimezone: string
): void => {
  const date = moment(slot.startTime)
    .tz(projectTimezone)
    .format(DATE_TIME_FORMAT);

  // Find the expertSlotsByDate entry for the current date
  let expertSlotByDate = expertSlotsByDate.find(
    (expertSlot) => expertSlot.date === date
  );

  const updatedSlot = {
    ...slot,
    startTime: moment(slot.startTime).tz(projectTimezone),
    endTime: moment(slot.endTime).tz(projectTimezone)
  };

  if (!expertSlotByDate) {
    // If the entry doesn't exist, create a new one
    expertSlotByDate = {
      date,
      experts: [],
      count: 0
    };
    expertSlotsByDate.push(expertSlotByDate);
  }

  const expert = expertMap[expertId];
  const expertSlots = expertSlotByDate.experts.find(
    (e) => e.expert.expertId === expert.expertId
  );

  if (expertSlots) {
    // Add the current slot to the existing expertSlots
    expertSlots.slots.push(updatedSlot);
  } else {
    // Create a new expertSlots entry for the current expert and the slot
    expertSlotByDate.experts.push({
      expert,
      slots: [updatedSlot]
    });
  }
};

export const getDatePrefix = (index: number, currentPage: number): string => {
  if (currentPage === 0) {
    if (index === 0) {
      return `${i18next.t("scheduleTab:today")}`;
    } else if (index === 1) {
      return `${i18next.t("scheduleTab:tomorrow")}`;
    }
  }
  return "";
};
