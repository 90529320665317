import { ICellRendererParams } from "ag-grid-community";
import React from "react";

import { AngleModel } from "@arbolus-technologies/models/common";
import { AssignedAngle } from "@arbolus-technologies/ui/components";

interface IAngleData {
  angle: AngleModel;
}

export const AngleCellRenderer: React.FC<
  ICellRendererParams<IAngleData, never, never>
> = ({ data }) => (data?.angle ? <AssignedAngle angle={data?.angle} /> : null);
