import { ColDef } from "ag-grid-community";
import moment from "moment";

import { GroupSlotsRenderer } from "../../../Components/CellRenderers/GroupSlotsRenderer";
import { SlotRow } from "../scheduling/SlotRow";

export const dayGroupCol: ColDef<SlotRow> = {
  colId: "dayGroup",
  rowGroup: true,
  hide: true,
  rowGroupIndex: 0,
  showRowGroup: true,
  valueGetter: ({ data, context: { timezone } }) =>
    moment.utc(data!.startTime).tz(timezone).format("YYYY-MM-DD"),
  cellRendererParams: {
    innerRenderer: GroupSlotsRenderer
  }
};

export const slotGroupCol: ColDef = {
  colId: "slotGroup",
  rowGroup: true,
  hide: true,
  rowGroupIndex: 1,
  valueGetter: ({ data, context: { timezone } }) => {
    /* spell-checker: disable */
    const start = moment.utc(data!.startTime).tz(timezone).format("YYMMDDHHmm");
    const end = moment.utc(data!.end).tz(timezone).format("YYMMDDHHmm");
    /* spell-checker: enable */
    return `${start}-${end}`;
  }
};

export const slotStartFilterCol: ColDef = {
  field: "startTime",
  filter: "agDateColumnFilter",
  hide: true
};
