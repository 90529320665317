import React, { useEffect, useState } from "react";

import { PageStep } from "@arbolus-technologies/models/common";

import { PageWithStepsFooter } from "./PageWithStepsFooter/PageWithStepsFooter";
import { StepsSidebar } from "./StepsSidebar/StepsSidebar";

import styles from "./PageWithSteps.module.scss";

interface PageWithStepsProps {
  steps: PageStep[];
  initialStep?: number;
  onAfterLastStep?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stepComponentProps?: any;
  isProcessingLastStep?: boolean;
  changeStepOnClick?: boolean;
  sidebarComponent?: () => JSX.Element;
}

export const PageWithSteps: React.FC<PageWithStepsProps> = ({
  onAfterLastStep,
  steps,
  initialStep = 0,
  stepComponentProps,
  isProcessingLastStep = false,
  changeStepOnClick = false,
  sidebarComponent
}) => {
  const [currentStep, setCurrentStep] = useState(initialStep);
  const { name, StepComponent } = steps[currentStep];
  const [visitedSteps, setVisitedSteps] = useState<number[]>([]);
  const [canGoToNextStep, setCanGoToNextStep] = useState(false);

  useEffect(() => {
    setVisitedSteps(Array.from({ length: initialStep }, (_, index) => index));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialStep]);

  const changeStep = (step: number) => {
    setCurrentStep((previousStep) => {
      if (!visitedSteps.includes(previousStep) && step > previousStep)
        // Mark previous step as visited
        setVisitedSteps([...visitedSteps, previousStep]);
      return step;
    });
  };

  return (
    <div className={styles.pageLayout}>
      <div className={styles.menu}>
        <StepsSidebar
          steps={steps}
          currentStep={currentStep}
          visitedSteps={visitedSteps}
          changeStepOnClick={changeStepOnClick}
          onClick={changeStep}
        />
        {sidebarComponent?.()}
      </div>
      <div className={styles.pageContent}>
        <StepComponent
          name={name}
          setCanGoToNextStep={setCanGoToNextStep}
          setCurrentStep={changeStep}
          isLastStep={steps.length === currentStep + 1}
          {...stepComponentProps}
        />
        {steps[currentStep].nextStepButtonText && (
          <PageWithStepsFooter
            steps={steps}
            currentStep={currentStep}
            setCurrentStep={changeStep}
            onAfterLastStep={onAfterLastStep!}
            canGoToNextStep={canGoToNextStep}
            isProcessingLastStep={isProcessingLastStep}
          />
        )}
      </div>
    </div>
  );
};
