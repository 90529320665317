import { Button } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormValue } from "@arbolus-technologies/models/common";

import { CustomSwitchController } from "../CustomSwitchController/CustomSwitchController";
import { HR } from "../HR/HR";

import styles from "./CheckboxSimpleForm.module.scss";

interface CheckboxSimpleFormInterface {
  formValues: FormValue[];
}

export interface SettingsFormProps {
  allowUpdate: boolean;
  name: string;
  formValues: FormValue[];
  onSave: (formValues: FormValue[]) => void;
  onCancel?: () => void;
}

export const CheckboxSimpleForm: React.FC<SettingsFormProps> = ({
  allowUpdate,
  formValues,
  name,
  onSave,
  onCancel
}) => {
  const { t } = useTranslation(name);
  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm<CheckboxSimpleFormInterface>({
    defaultValues: {
      formValues
    }
  });
  const onSubmit = (form: CheckboxSimpleFormInterface) => {
    onSave(form.formValues);
  };

  return formValues.length > 0 ? (
    <>
      <div className={clsx(styles.container, allowUpdate && styles.update)}>
        {formValues.map((formValue, index) => (
          <React.Fragment key={formValue.label}>
            <CustomSwitchController
              name={`formValues.${index}.active`}
              label={t(formValue.label)}
              subtitle={
                formValue.description ? t(formValue.description) : undefined
              }
              errors={errors}
              control={control}
              disabled={!allowUpdate}
            />
            {formValue.label === "clientNotificationsEnabled" && (
              <HR margin={{ top: 1, bottom: 1 }} />
            )}
          </React.Fragment>
        ))}
      </div>
      {allowUpdate && (
        <>
          <HR margin={{ top: 3, bottom: 3 }} />
          <div className={styles.buttonsContainer}>
            <Button type="tertiary" text={t("cancel")} onClick={onCancel} />
            <Button text={t("save")} onClick={handleSubmit(onSubmit)} />
          </div>
        </>
      )}
    </>
  ) : (
    t(`emptyForm`)
  );
};
