import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./BadgeWithIcon.module.scss";

interface BadgeWithIconProps {
  text: string;
  icon: string;
  enableCursorPointer?: boolean;
  color?: string;
  backgroundColor?: ARBOLUS_COLORS;
  fontSize?: string;
}
export const BadgeWithIcon: React.FC<BadgeWithIconProps> = ({
  text,
  icon,
  enableCursorPointer = false,
  color = ARBOLUS_COLORS.bColorGrayIcon,
  backgroundColor,
  fontSize = "18px"
}) => (
  <div
    data-testid="badgeWithIcon"
    className={clsx(styles.container, {
      [styles.enableCursorPointer]: enableCursorPointer
    })}
    title={text}
  >
    <div style={{ backgroundColor }} className={styles.icon}>
      <Icon name={icon} color={color} fontSize={fontSize} />
    </div>
    <span className={styles.text} title={text}>
      {text}
    </span>
  </div>
);
