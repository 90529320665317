import clsx from "clsx";
import { push } from "connected-react-router";
import { filter, partition } from "lodash";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Nav,
  NavItem,
  NavLink,
  PopoverBody,
  Row,
  UncontrolledPopover
} from "reactstrap";
import { Dispatch } from "redux";
import { createStructuredSelector } from "reselect";
import { Subscription, zip } from "rxjs";
import SimpleBar from "simplebar-react";

import { ToasterService } from "@arbolus-technologies/api";
import {
  Avatar,
  Drawer,
  HR,
  Loader
} from "@arbolus-technologies/ui/components";

import { EDIT_USER_ROUTE } from "../../../../../constants/navigation/authRoutes";
import { PROJECT_ROUTE } from "../../../../../constants/navigation/projectRoutes";
import { PROJECT_STATES } from "../../../../../constants/project";
import { UI_WINDOW_HEIGHT } from "../../../../../constants/ui";
import { USER_ACCESS_LEVEL } from "../../../../../constants/user";
import { CIQError, ErrorResponse } from "../../../../../models/api";
import { ListProjectBase } from "../../../../../models/project";
import { BaseClientMember } from "../../../../../models/user";
import { ClientService, UtilsService } from "../../../../../services";
import { AppState } from "../../../../../store/reducers";
import { AuthSelector } from "../../../../auth/store";
import UserProjectSearch from "../../userProjectSearch/UserProjectSearch";

import styles from "./ClientUserProfilePanel.module.scss";

const notification = new ToasterService();

interface ClientUserProfilePanelStoreProps {
  clientName?: string;
  clientLogo?: string;
  clientId?: string;
}

interface ClientUserProfilePanelDispatchProps {
  onUserClicked: (userId: string) => void;
}

interface ClientUserProfilePanelProps {
  userId: string;
  onPanelClose: () => void;
  onProjectListUpdate?: () => void;
}

interface ClientUserProfilePanelState {
  isLoading: boolean;
  isPopOverOpen: boolean;
  user: BaseClientMember;
  projectList: ListProjectBase[];
}

type ClientUserProfilePanelIntersectProps = ClientUserProfilePanelProps &
  ClientUserProfilePanelStoreProps &
  ClientUserProfilePanelDispatchProps &
  WithTranslation;

class ClientUserProfilePanel extends React.Component<
  ClientUserProfilePanelIntersectProps,
  ClientUserProfilePanelState
> {
  constructor(props: ClientUserProfilePanelIntersectProps) {
    super(props);
    this.state = {
      isLoading: true,
      isPopOverOpen: false,
      user: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        accessLevel: "",
        emailConfirmed: false,
        isoCountryCode: "",
        profileImageUrl: "",
        title: "",
        phoneNumber: undefined
      },
      projectList: []
    };
  }

  componentDidMount(): void {
    this.fetchProfileData();
  }

  componentWillUnmount(): void {
    this.profileDataFetchSubscription?.unsubscribe();
  }

  private profileDataFetchSubscription?: Subscription;

  fetchProfileData = (): void => {
    const { clientId, userId, onPanelClose } = this.props;
    this.setState({ isLoading: true });

    this.profileDataFetchSubscription = zip(
      ClientService.getUser(clientId!, userId),
      ClientService.getTeamMembersProjects(clientId!, userId)
    ).subscribe(
      ([user, { items: projectList }]) => {
        this.setState({ user, isLoading: false, projectList });
      },
      (error: ErrorResponse<CIQError>) => {
        notification.showError(error.message);
        onPanelClose();
      }
    );
  };

  handleListProjectClicked = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.stopPropagation();
  };

  handleOnProjectClick = (addedProject: ListProjectBase): void => {
    const { projectList } = this.state;
    const { onProjectListUpdate } = this.props;
    const updatedProject = addedProject;
    updatedProject.projectState = PROJECT_STATES.ACTIVE;

    this.setState(
      {
        isPopOverOpen: false,
        projectList: [...projectList, updatedProject]
      },
      () => {
        if (onProjectListUpdate) {
          onProjectListUpdate();
        }
      }
    );
  };

  handleActiveProjectClicked = (projectId: string): void => {
    window.open(PROJECT_ROUTE(projectId), "_blank");
  };

  renderNoProjectsMessage = (): JSX.Element => {
    const { t } = this.props;
    return (
      <div className="empty-message">
        <span className="ciq-icon ciq-archive" />
        {t("noProjectsMessage")}
      </div>
    );
  };

  renderProjectList = (
    projects: ListProjectBase[],
    isActive: boolean
  ): JSX.Element => {
    const { t } = this.props;

    return (
      <Drawer
        title={isActive ? t("activeProjects") : t("archivedProjects")}
        openByDefault={isActive}
      >
        {projects.map((project, key) => (
          <React.Fragment key={project.id}>
            <div
              className={clsx(
                styles.itemWrapper,
                isActive && styles.clickableItem
              )}
              onClick={(): void => {
                if (isActive) {
                  this.handleActiveProjectClicked(project.id);
                }
              }}
            >
              <span
                className={clsx("status-dot", isActive ? "active" : "archived")}
              />
              <span>{project.name}</span>
            </div>
            {key !== projects.length - 1 && <HR />}
          </React.Fragment>
        ))}
      </Drawer>
    );
  };

  render(): JSX.Element {
    const { isLoading, isPopOverOpen, user, projectList } = this.state;
    const { userId, clientId, onUserClicked, t, onPanelClose } = this.props;
    const isAdmin = user.accessLevel === USER_ACCESS_LEVEL.ADMIN.value;

    const filteredList = filter(
      projectList,
      (p) =>
        p.projectState === PROJECT_STATES.ARCHIVE ||
        p.projectState === PROJECT_STATES.ACTIVE
    );

    const projectsPartitions = partition(
      filteredList,
      (p) => p.projectState === PROJECT_STATES.ACTIVE
    );
    const activeList = projectsPartitions[0];
    const archivedList = projectsPartitions[1];
    const noProjects = !activeList.length && !archivedList.length;

    return (
      <SimpleBar
        className="content-panel-body user-profile-body no-header simplebar-light"
        style={{
          height: UI_WINDOW_HEIGHT,
          overflowX: "hidden"
        }}
      >
        {isLoading ? (
          <Loader isSidePanel />
        ) : (
          <div>
            <div className="panel-header">
              <div
                className="left-action"
                onClick={(): void => {
                  onUserClicked(userId);
                  onPanelClose();
                }}
              >
                <i className="ciq-icon ciq-edit" />
              </div>

              <div className="btn-close" onClick={onPanelClose}>
                <i className="ciq-icon ciq-close" />
              </div>
            </div>

            <div className="panel-body">
              <div className="user-container">
                <Row className="user-header">
                  <Col />
                  <Col className="middle-container">
                    <Avatar
                      avatar={{
                        name: UtilsService.displayUserName(user),
                        imageUrl: user.profileImageUrl
                      }}
                    />
                  </Col>
                  <Col className="right-container">
                    {isAdmin && (
                      <span className="state-tag grey">{user.accessLevel}</span>
                    )}
                  </Col>
                </Row>
                <Row className="user-name-container">
                  <Col>
                    <h2>{UtilsService.displayUserName(user)}</h2>
                    <p className="text-center">{user.title}</p>
                  </Col>
                </Row>
                <div className="user-body">
                  <Nav>
                    <NavItem>
                      <NavLink>{t("projects")}</NavLink>
                    </NavItem>
                  </Nav>
                  <div className="btn-container">
                    <Button
                      onClick={this.handleListProjectClicked}
                      id={`user_item_${userId}`}
                      color="primary"
                      type="button"
                      className={clsx("ciq-icon", {
                        "ciq-close": isPopOverOpen,
                        "ciq-plus": !isPopOverOpen
                      })}
                    />
                    <UncontrolledPopover
                      hideArrow
                      isOpen={isPopOverOpen}
                      toggle={(): void =>
                        this.setState({ isPopOverOpen: !isPopOverOpen })
                      }
                      target={`user_item_${userId}`}
                      placement="bottom-end"
                      trigger="legacy"
                      className="project-search-popover"
                    >
                      <PopoverBody>
                        <UserProjectSearch
                          userId={userId}
                          clientId={clientId!}
                          onProjectClick={this.handleOnProjectClick}
                        />
                      </PopoverBody>
                    </UncontrolledPopover>
                  </div>
                </div>

                {noProjects ? (
                  this.renderNoProjectsMessage()
                ) : (
                  <div className={styles.collapsibleWrapper}>
                    {this.renderProjectList(activeList, true)}
                    {this.renderProjectList(archivedList, false)}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </SimpleBar>
    );
  }
}

const mapStateToProps = createStructuredSelector<
  AppState,
  ClientUserProfilePanelProps,
  ClientUserProfilePanelStoreProps
>({
  clientName: AuthSelector.authClientNameSelector(),
  clientLogo: AuthSelector.authClientLogoUrlSelector(),
  clientId: AuthSelector.authClientIdSelector()
});

const mapDispatchToProps = (
  dispatch: Dispatch
): ClientUserProfilePanelDispatchProps => ({
  onUserClicked: (userId: string): void => {
    dispatch(push(EDIT_USER_ROUTE(userId)));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("clientUserProfilePanel")(ClientUserProfilePanel));
