import { ColDef, GridReadyEvent } from "ag-grid-community";
import React, { useCallback, useMemo } from "react";

import { ProjectService } from "@arbolus-technologies/api";
import { ProjectModel } from "@arbolus-technologies/models/common";
import { ArbolusGrid } from "@arbolus-technologies/ui/components";

import { useProjectTableColumnDefs } from "./ProjectTableColumnDefs";
import { ProjectsDataSource } from "./ProjectsDataSource";

interface ProjectsTableProps {
  projectService?: typeof ProjectService;
}

export const ProjectsTable: React.FC<ProjectsTableProps> = ({
  projectService = ProjectService
}) => {
  const onGridReady = useCallback(
    (event: GridReadyEvent<ProjectModel>) => {
      const { api } = event;
      api.setGridOption(
        "serverSideDatasource",
        new ProjectsDataSource(projectService)
      );
      api.sizeColumnsToFit();
    },
    [projectService]
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      resizable: true,
      sortable: true,
      suppressMovable: true,
      menuTabs: ["filterMenuTab"]
    }),
    []
  );

  const columnDefs: ColDef[] = useProjectTableColumnDefs();

  return (
    <ArbolusGrid
      rowModelType="serverSide"
      getRowId={({ data }) => data.id}
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      onGridReady={onGridReady}
      rowSelection="multiple"
      suppressRowClickSelection
      skipHeaderOnAutoSize
      suppressContextMenu
      suppressMenuHide
      pagination
      paginationAutoPageSize
    />
  );
};
