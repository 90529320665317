import React, { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { convertToPercentage } from "@arbolus-technologies/features/common";
import { CanopyAnswerSummaryItemMultipleChoice } from "@arbolus-technologies/models/canopy-panels";
import {
  HR,
  HorizontalProgressComponentGroup
} from "@arbolus-technologies/ui/components";

import { CanopySummaryQuote } from "../../CanopySummaryQuote/CanopySummaryQuote";
import { CanopySummaryTitleComponent } from "../../CanopySummaryTitleComponent/CanopySummaryTitleComponent";

import styles from "./CanopySummaryComponentMultiChoice.module.scss";

interface CanopySummaryComponentMultiChoiceProps {
  summary: CanopyAnswerSummaryItemMultipleChoice;
  canopyId: string;
}

export const CanopySummaryComponentMultiChoice: React.FC<
  CanopySummaryComponentMultiChoiceProps
> = ({ summary, canopyId }) => {
  const { t } = useTranslation("canopySummary");
  const { totalAnswersCount, questionDetails, choiceAnswers, otherAnswers } =
    summary;

  const convertedArray = useMemo(
    () =>
      choiceAnswers.map((answer) => {
        const percentage = convertToPercentage(
          0,
          totalAnswersCount,
          answer.answersCount
        );

        return {
          id: answer.optionChoiceId,
          title: answer.optionChoiceText ?? "",
          progressText: `${percentage.toFixed(2)} %`,
          value: percentage
        };
      }),
    [choiceAnswers, totalAnswersCount]
  );

  return (
    <div className={styles.container}>
      <span className={styles.subtitle}>{questionDetails}</span>
      <HorizontalProgressComponentGroup data={convertedArray} hideBorder />
      {otherAnswers.length > 0 && (
        <>
          <CanopySummaryTitleComponent title={t("otherResponses")} />
          <div className={styles.quotesContainer}>
            {otherAnswers?.map((quote, index) => {
              const { otherResponse, expert, canopyAnswerId } = quote;
              return (
                <Fragment key={index}>
                  <CanopySummaryQuote
                    quote={otherResponse!}
                    expert={expert}
                    canopyId={canopyId}
                    canopyAnswerId={canopyAnswerId}
                  />
                  {!(otherAnswers.length === index + 1) && <HR />}
                </Fragment>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
