import { Handlers, createReducer } from "reduxsauce";

import { AppConstants } from "../../../constants";
import { LoggedInUser } from "../../../models/user";
import { UserService } from "../../../services";
import { AppAction } from "../../../store/actions";
import {
  GET_USER_PROFILE,
  GET_USER_PROFILE_FAILURE,
  GET_USER_PROFILE_SUCCESS,
  GetUserProfileAction,
  GetUserProfileSuccessAction,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_CLIENT_NAME,
  UpdateUserProfileAction,
  UpdateUserProfileClientNameAction
} from "../../app/store/actions/AppActionTypes";
import {
  AUTHENTICATE_USER_SUCCESS,
  AuthenticateUserSuccessAction,
  LOGOUT_USER,
  LOGOUT_USER_FAILED,
  LOGOUT_USER_SUCCESS
} from "./actions/LoginActionTypes";
import {
  ACCEPT_LATEST_TERMS,
  UPDATE_USER_NOTIFICATION_SETTINGS,
  UpdateUserNotificationSettingsAction
} from "./actions/UserActionTypes";

export interface AuthReducerState {
  user: LoggedInUser;
  isProfileLoading: boolean;
  isLogoutLoading: boolean;
  currentRole: string;
  roles: string[];
  termUpdateRequired: boolean;
}

export const initialState: AuthReducerState = {
  user: {} as LoggedInUser,
  isProfileLoading: true, // Definitely fetch user profile as first action
  isLogoutLoading: false,
  currentRole: AppConstants.APP_USER_ROLES.guest,
  roles: [AppConstants.APP_USER_ROLES.guest],
  termUpdateRequired: false
};

const handleAuthenticateUserSuccess = (
  state = initialState,
  { payload }: AuthenticateUserSuccessAction
): AuthReducerState => ({
  ...state,
  currentRole: UserService.generateActiveUserRole(payload.roles),
  roles: payload.roles
});

const handleLogoutUser = (state = initialState): AuthReducerState => ({
  ...state,
  isLogoutLoading: true
});

const handleLogoutUserSuccess = (): AuthReducerState => ({
  ...initialState,
  isProfileLoading: false,
  isLogoutLoading: false
});

const handleLogoutUserFailed = (state = initialState): AuthReducerState => ({
  ...state,
  isLogoutLoading: false
});

const handleGetUser = (
  state = initialState,
  { payload }: GetUserProfileAction
): AuthReducerState => ({
  ...state,
  isProfileLoading: payload.initial
});

const handleGetUserSuccess = (
  state = initialState,
  { payload: { user } }: GetUserProfileSuccessAction
): AuthReducerState => ({
  ...state,
  user,
  currentRole: UserService.generateActiveUserRole(user.userRoles),
  roles: user.userRoles,
  termUpdateRequired: user.termUpdateRequired,
  isProfileLoading: false
});

const handleGetUserFailure = (state = initialState): AuthReducerState => ({
  ...state,
  isProfileLoading: false
});

const handleUpdateUserProfile = (
  state = initialState,
  { payload }: UpdateUserProfileAction
): AuthReducerState => {
  const {
    firstName,
    lastName,
    phoneNumber,
    title,
    timezone,
    complianceManager
  } = payload.user;
  return {
    ...state,
    user: {
      ...state.user,
      firstName,
      lastName,
      phoneNumber,
      title,
      timezone,
      complianceManager
    }
  };
};

const handleUpdateUserProfileClientName = (
  state = initialState,
  { payload }: UpdateUserProfileClientNameAction
): AuthReducerState => {
  const { name } = payload;
  const { user } = state;
  const { client } = user;

  return {
    ...state,
    user: {
      ...user,
      client: {
        ...client!,
        name
      }
    }
  };
};

const handleAcceptLatestTerms = (state = initialState): AuthReducerState => ({
  ...state,
  termUpdateRequired: false
});

const handleUpdateUserNotificationSettings = (
  state = initialState,
  { payload }: UpdateUserNotificationSettingsAction
): AuthReducerState => {
  const {
    userNotificationSettings: {
      dismissNotificationBanner,
      notificationSubscription
    }
  } = payload;

  return {
    ...state,
    user: {
      ...state.user,
      dismissNotificationBanner,
      defaultNotificationSettings: notificationSubscription
    }
  };
};

export const handlers: Handlers<AuthReducerState, AppAction> = {
  [AUTHENTICATE_USER_SUCCESS]: handleAuthenticateUserSuccess,
  [GET_USER_PROFILE]: handleGetUser,
  [GET_USER_PROFILE_SUCCESS]: handleGetUserSuccess,
  [GET_USER_PROFILE_FAILURE]: handleGetUserFailure,
  [LOGOUT_USER]: handleLogoutUser,
  [LOGOUT_USER_SUCCESS]: handleLogoutUserSuccess,
  [LOGOUT_USER_FAILED]: handleLogoutUserFailed,
  [UPDATE_USER_PROFILE]: handleUpdateUserProfile,
  [UPDATE_USER_PROFILE_CLIENT_NAME]: handleUpdateUserProfileClientName,
  [ACCEPT_LATEST_TERMS]: handleAcceptLatestTerms,
  [UPDATE_USER_NOTIFICATION_SETTINGS]: handleUpdateUserNotificationSettings
};

export default createReducer(initialState, handlers);
