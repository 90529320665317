import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  AnglesService,
  ApiError,
  ApiErrorResponse,
  CIQError,
  DefaultToasterService,
  Expert,
  ToasterService,
  createForkJoinObservables
} from "@arbolus-technologies/api";
import { SlidePanel } from "@arbolus-technologies/features/common";
import { AngleModel, Referral } from "@arbolus-technologies/models/common";
import { DiscoverExpert } from "@arbolus-technologies/models/project";
import {
  PanelId,
  PanelSelector,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";

import { AngleStep } from "./AngleStep/AngleStep";
import { Footer } from "./Footer/Footer";
import { Header } from "./Header/Header";
import { ProjectStep } from "./ProjectStep/ProjectStep";

import styles from "./AssignExpertToProjectPanel.module.scss";

const EXPERT_ALREADY_ASSIGNED_ERROR = "1004";

type AssignExpertToProjectResponseSuccess = { [key: string]: string };

interface ResultsResponse {
  [key: string]: ApiError | AssignExpertToProjectResponseSuccess;
}

const manageResponse = (results: ResultsResponse) => {
  const resultsValues = Object.values(results);

  const hasError = resultsValues.some((r: any) => {
    if (r?.errorType) {
      const error = r.defaultError as ApiErrorResponse<CIQError>;
      return error.status !== EXPERT_ALREADY_ASSIGNED_ERROR;
    }
    return false;
  });

  const hasSuccess = resultsValues.some((r: any) => !r.errorType);

  const isAlreadyAssigned = resultsValues.some((r: any) => {
    if (r?.errorType === "default") {
      const error = r.defaultError as ApiErrorResponse<CIQError>;
      return error.status === EXPERT_ALREADY_ASSIGNED_ERROR;
    }
    return false;
  });

  return { hasError, hasSuccess, isAlreadyAssigned };
};

interface AssignExpertToProjectPanelProps {
  angleService?: typeof AnglesService;
  toasterService?: ToasterService;
}

export const AssignExpertToProjectPanel: React.FC<
  AssignExpertToProjectPanelProps
> = ({
  angleService = AnglesService,
  toasterService = DefaultToasterService
}) => {
  const { t } = useTranslation("assignExpertToProjectPanel");
  const [isAngleStep, setIsAngleStep] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<string>("");
  const [selectedAngle, setSelectedAngle] = useState<AngleModel>();
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const isAssignExpertToProjectPanelOpen = useSelector(
    PanelSelector.isPanelOpen(PanelId.AssignExpertToProject)
  );
  const assignExpertToProjectParams: Referral[] = useSelector(
    PanelSelector.panelData(PanelId.AssignExpertToProject)
  );

  useEffect(() => {
    if (!isAssignExpertToProjectPanelOpen) {
      setIsAngleStep(false);
      setSelectedAngle(undefined);
    }
  }, [isAssignExpertToProjectPanelOpen]);

  const handleIsAngleStep = () => setIsAngleStep((prevState) => !prevState);

  const onSelectAngle = (angle: AngleModel) => setSelectedAngle(angle);

  const assignExpertToProjectRequest = (
    item: { expertId: string } | { expert: { expertId: string } }
  ) => {
    const referralData = {
      // @ts-ignore
      expertId: item.expertId ?? item.expert.expertId
    };

    return angleService.assignAngle(selectedAngle?.id!, referralData);
  };

  const handleOnConfirm = () => {
    setLoading(true);

    createForkJoinObservables(
      assignExpertToProjectParams,
      assignExpertToProjectRequest,
      "id",
      (_) => ({ errorType: "" })
    ).subscribe({
      next: (results: ResultsResponse) => {
        dispatch(PanelStoreActions.closePanel(PanelId.AssignExpertToProject));

        const { hasError, hasSuccess, isAlreadyAssigned } =
          manageResponse(results);

        if (hasSuccess) {
          toasterService.showSuccess(t("assignProjectSuccess"));
        }
        if (hasError) {
          toasterService.showError(t("assignProjectError"));
        }
        if (isAlreadyAssigned) {
          toasterService.showWarning(t("alreadyAssignedWarning"));
        }
        setLoading(false);
      }
    });
  };

  return (
    <SlidePanel
      panelId={PanelId.AssignExpertToProject}
      title={t("assignProject")}
      noPadding
      hideHeader
    >
      <Header isAngleStep={isAngleStep} setIsAngleStep={handleIsAngleStep} />
      {isAngleStep ? (
        <div className={styles.angleStepContainer}>
          <AngleStep
            projectId={projectId}
            selectedAngle={selectedAngle}
            setSelectedAngle={onSelectAngle}
          />
          <Footer
            confirmDisabled={!selectedAngle?.id || loading}
            onConfirm={handleOnConfirm}
          />
        </div>
      ) : (
        <ProjectStep
          setProjectId={(projectId) => setProjectId(projectId)}
          setIsAngleStep={handleIsAngleStep}
        />
      )}
    </SlidePanel>
  );
};
