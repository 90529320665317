import { useEffect, useState } from "react";

import { SEARCH_DEBOUNCE_TIMEOUT } from "../constants/Timer";
import { useDebounce } from "./DebounceHook";

export function useDebouncedSearch({
  initialValue = "",
  debouncingTime = SEARCH_DEBOUNCE_TIMEOUT,
  minimumSearchLength = 2,
  onQueryChange,
  isDebounced = false
}: {
  initialValue?: string;
  debouncingTime?: number;
  minimumSearchLength?: number;
  onQueryChange: (query: string) => void;
  isDebounced?: boolean;
}): {
  handleClearQueryClicked: () => void;
  searchQuery: string;
  handleInputChangeEvent: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
} {
  const [searchQuery, setSearchQuery] = useState<string>(initialValue);
  const debouncedValue = useDebounce<string>(
    searchQuery,
    debouncingTime || SEARCH_DEBOUNCE_TIMEOUT
  );

  const handleQueryChange = (query: string) => {
    if (query.length >= minimumSearchLength) {
      onQueryChange(query);
    }
    if (query.length < minimumSearchLength) {
      onQueryChange("");
    }
  };

  useEffect(() => {
    if (isDebounced) {
      handleQueryChange(searchQuery);
    } else {
      onQueryChange("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const handleInputChangeEvent = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const newQuery = event.target.value;
    setSearchQuery(newQuery);
    if (!isDebounced) {
      handleQueryChange(newQuery);
    }
  };

  const handleClearQueryClicked = () => {
    setSearchQuery("");
  };

  return {
    handleClearQueryClicked,
    searchQuery,
    handleInputChangeEvent
  };
}
