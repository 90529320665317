import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./ClosePanel.module.scss";

interface ClosePanelProps {
  closePanel: () => void;
}

export const ClosePanel = ({ closePanel }: ClosePanelProps): JSX.Element => {
  const { t } = useTranslation("createSurvey");
  return (
    <div className={styles.titleBack}>
      <span>{t("newSurvey")}</span>
      <button onClick={closePanel}>
        <span className="ciq-icon ciq-close" />
      </button>
    </div>
  );
};
