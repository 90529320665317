import React from "react";
import { useTranslation } from "react-i18next";

import screenshots from "./screenshots.webp";
import trustpilotScore from "./trustpilot-score.webp";

import styles from "./AuthPageBase.module.scss";

export function AuthPageBase({
  children
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const { t } = useTranslation("authPageBase");

  return (
    <div className={styles.wrapper}>
      <div>{children}</div>
      <div className={styles.banner}>
        <div className={styles.bannerContent}>
          <span className={styles.header}>{t("slogan")}</span>
          <img src={screenshots} className={styles.screenshots} alt="" />
          <div className={styles.trustpilotScore}>
            <img src={trustpilotScore} alt="TrustScore 4.6 - 868 reviews" />
          </div>
        </div>
      </div>
    </div>
  );
}
