import clsx from "clsx";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { SelectedFilterEnum } from "@arbolus-technologies/api";
import { ProjectAnglesSelector } from "@arbolus-technologies/stores/project-angles-store";
import {
  ProjectExpertsSelector,
  ProjectExpertsStoreActions,
  ScheduleFilterDefinitions
} from "@arbolus-technologies/stores/project-experts-store";
import {
  Button,
  FilterType,
  FilterWithBadges
} from "@arbolus-technologies/ui/components";

import { FilterByAngles } from "./ScheduleSlotsFilter/FilterComponents/FilterByAngles";
import { FilterByCallStatus } from "./ScheduleSlotsFilter/FilterComponents/FilterByCallStatus";
import { FilterByStatus } from "./ScheduleSlotsFilter/FilterComponents/FilterByStatus";
import { ScheduleSlotsFilter } from "./ScheduleSlotsFilter/ScheduleSlotsFilter";

import styles from "./ScheduleHeader.module.scss";

// TO_REPLACE

interface ScheduleHeaderProps {
  dateHeader: string;
  pageNumber: number;
  onNext: () => void;
  onPrev: () => void;
  projectId: string;
}

export const ScheduleHeader: React.FC<ScheduleHeaderProps> = ({
  dateHeader,
  pageNumber,
  onNext,
  onPrev,
  projectId
}) => {
  const { t } = useTranslation("scheduleTabFilters");
  const dispatch = useDispatch();
  const appliedFilters = useSelector(
    ProjectExpertsSelector.scheduleTabFilters(projectId)
  );
  const anglesCards = useSelector(ProjectAnglesSelector.anglesCardsSelector());
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState<boolean>(false);
  const [newFilters, setNewFilters] = useState<FilterType[]>(appliedFilters);

  const getAllFilterValues = () =>
    appliedFilters.map((filter) => {
      const updatedFilter: FilterType = { ...filter };
      if (filter.key === SelectedFilterEnum.STATUSES) {
        updatedFilter.iconComponent = <FilterByStatus status={filter.id} />;
      } else if (filter.key === SelectedFilterEnum.ANGLE_IDS) {
        updatedFilter.iconComponent = (
          <FilterByAngles
            angle={anglesCards.find((card) => card.id === filter.id)}
          />
        );
      } else {
        updatedFilter.iconComponent = <FilterByCallStatus status={filter.id} />;
      }
      return updatedFilter;
    });

  const handleFilter = (filters: FilterType[]) => {
    setNewFilters(filters);
    dispatch(
      ProjectExpertsStoreActions.setScheduleTabFilters(
        projectId,
        filters as ScheduleFilterDefinitions
      )
    );
  };
  const handleApply = () => {
    dispatch(
      ProjectExpertsStoreActions.setScheduleTabFilters(
        projectId,
        newFilters as ScheduleFilterDefinitions
      )
    );
    setIsFilterPanelOpen(false);
  };
  const handleCancel = () => {
    setNewFilters(appliedFilters);
    setIsFilterPanelOpen(false);
  };

  const isFirstPage = pageNumber === 0;
  const isLastPage = pageNumber === 3;

  return (
    <div className={clsx(styles.header, { [styles.mobileHeader]: isMobile })}>
      <div className={clsx(styles.date, { [styles.mobileDate]: isMobile })}>
        <span className={styles.title}>{dateHeader}</span>
        <div className={styles.pagination}>
          <Button
            type="iconButton"
            centerIcon="chevron_left"
            onClick={onPrev}
            disabled={isFirstPage}
          />
          <Button
            type="iconButton"
            centerIcon="chevron_right"
            onClick={onNext}
            disabled={isLastPage}
          />
        </div>
      </div>
      <div className={styles.filters}>
        <FilterWithBadges
          data={getAllFilterValues()}
          onFilter={handleFilter}
          filterButtonText={t("filter")}
          resetFiltersText={t("clearFilters")}
          onFilterButtonClick={() => setIsFilterPanelOpen(true)}
        />
        <ScheduleSlotsFilter
          newFilters={newFilters}
          setNewFilters={setNewFilters}
          isOpen={isFilterPanelOpen}
          onApply={handleApply}
          onCancel={handleCancel}
        />
      </div>
    </div>
  );
};
