import clsx from "clsx";
import dompurify from "dompurify";
import React from "react";

import styles from "./ProfileDetailItem.module.scss";

const sanitizer = dompurify.sanitize;
const sanitizerReplaceOption = "target='_blank' href";

interface ProfileDetailItemProps {
  labelText: string;
  text?: string;
}

export const ProfileDetailItem: React.FC<ProfileDetailItemProps> = ({
  labelText,
  text
}) => (
  <>
    <hr />
    <div className={styles.detailsWrapper}>
      <span className={styles.detailsLabel}>{labelText}</span>
      <div className={clsx("richtexteditor-container", styles.detailsValue)}>
        {text && (
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizer(text).replace(/href/g, sanitizerReplaceOption)
            }}
          />
        )}
      </div>
    </div>
  </>
);
