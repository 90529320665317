import { MenuItemDef, ShouldRowBeSkippedParams } from "ag-grid-community";
import saveAs from "file-saver";
import i18next from "i18next";

import {
  DefaultToasterService,
  ProjectService,
  ToasterService
} from "@arbolus-technologies/api";
import {
  DO_NOT_CONTACT_STATUS,
  Referral
} from "@arbolus-technologies/models/common";
import { AgGridCustomIcons } from "@arbolus-technologies/ui/components";
import { ExtractMethods, string64toBlob } from "@arbolus-technologies/utils";

import {
  IBulkItemActionParams,
  ReferralGridContext
} from "../../Models/ReferralsTable";
import {
  EMAIL_COLUMN_ID,
  FIRST_NAME_COLUMN_ID,
  LAST_NAME_COLUMN_ID,
  PHONE_COLUMN_ID
} from "../../Modules/ReferralsTable/columns/ExportColumns";

const t = (key: string) => i18next.t(`referrals:actions:${key}`);

export type IExportMenuService = ExtractMethods<ExportMenuService>;

class ExportMenuService {
  private readonly _toasterService: ToasterService;
  private readonly _projectService: typeof ProjectService;

  constructor(
    toasterService: ToasterService,
    projectService: typeof ProjectService
  ) {
    this._toasterService = toasterService;
    this._projectService = projectService;
  }

  public getExportMenuItems(
    params: IBulkItemActionParams,
    stage: string
  ): MenuItemDef {
    return {
      name: t("export"),
      icon: AgGridCustomIcons.download,
      subMenu: [
        this.getExportAll(params),
        "separator",
        {
          name: `${stage}`,
          disabled: true,
          cssClasses: ["font-weight-bold"]
        },
        this.getExportStage(params, stage),
        this.getExportContactInfo(params, stage)
      ]
    };
  }

  private getExportContactInfo(
    { api, project }: IBulkItemActionParams,
    stage: string,
    disabled = false
  ): MenuItemDef {
    return {
      action: () =>
        api.exportDataAsCsv({
          skipRowGroups: true,
          columnKeys: [
            FIRST_NAME_COLUMN_ID,
            LAST_NAME_COLUMN_ID,
            EMAIL_COLUMN_ID,
            PHONE_COLUMN_ID
          ],
          fileName: `${project.name}_${stage}_contact_info.csv`,
          shouldRowBeSkipped(
            params: ShouldRowBeSkippedParams<Referral, ReferralGridContext>
          ) {
            return (
              params.node.data?.expert.doNotContactStatus ===
              DO_NOT_CONTACT_STATUS.DNC
            );
          }
        }),
      disabled,
      name: t("contactInfo")
    };
  }

  private getExportAll(
    { project }: IBulkItemActionParams,
    disabled = false
  ): MenuItemDef {
    return {
      action: () => this.fetchExportAction(project.id),
      disabled,
      name: t("allTabs")
    };
  }

  private getExportStage(
    { project }: IBulkItemActionParams,
    stage: string,
    disabled = false
  ): MenuItemDef {
    return {
      action: () => this.fetchExportAction(project.id, stage),
      disabled,
      name: t("fullData")
    };
  }

  private fetchExportAction(projectId: string, stage?: string): void {
    this._toasterService.showInfo("Download started");
    this._projectService
      .exportExperts(projectId, stage ? [stage] : undefined)
      .subscribe({
        next: (response) => {
          const blob = string64toBlob(
            response.file,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
          saveAs(blob, response.name);
          this._toasterService.showSuccess("Download Success");
        },
        error: this._toasterService.showApiErrors
      });
  }
}

export const DefaultExportMenuService = new ExportMenuService(
  DefaultToasterService,
  ProjectService
);
