import { ICellRendererParams } from "ag-grid-community";
import React from "react";

import { ExpertTaglineModel } from "@arbolus-technologies/models/common";
import {
  ArbolusTooltip,
  Avatar,
  DNCIcon,
  PublicCompanyIcon
} from "@arbolus-technologies/ui/components";
import { displayUserName } from "@arbolus-technologies/utils";

import styles from "./ExpertRenderer.module.scss";

interface IExpert {
  expert: ExpertTaglineModel;
}

export const ExpertRenderer: React.FC<
  ICellRendererParams<IExpert, never, never>
> = ({ data }) => {
  if (!data?.expert) {
    return null;
  }

  const fullName = displayUserName(data.expert);

  return (
    <div className={styles.container}>
      <ArbolusTooltip content={fullName}>
        <div className={styles.title}>
          <Avatar
            avatar={{
              name: fullName,
              imageUrl: data.expert.profileImageUrl
            }}
          />
          <span className={styles.name}>{fullName}</span>
        </div>
      </ArbolusTooltip>
      <div className={styles.iconContainer}>
        {data.expert.doNotContactStatus && (
          <DNCIcon dncStatus={data.expert.doNotContactStatus} isAdmin />
        )}
        {data.expert.lastPublicCompanyExpDate && (
          <PublicCompanyIcon date={data.expert.lastPublicCompanyExpDate} />
        )}
      </div>
    </div>
  );
};
