import clsx from "clsx";
import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./ProgressVerticalComponent.module.scss";

interface ProgressVerticalComponentProps {
  number: number;
  percentage: number;
  color?: string;
  actionComponent?: React.ReactNode;
}

export const ProgressVerticalComponent: React.FC<
  ProgressVerticalComponentProps
> = ({
  number,
  percentage,
  color = ARBOLUS_COLORS.bColorAccentOrangeDark,
  actionComponent
}) => (
  <div className={styles.container}>
    <div
      className={clsx(
        percentage === 0 ? styles.grayPercentage : styles.percentage
      )}
    >
      {percentage}%
    </div>
    <div className={styles.containerProgress}>
      <div
        className={styles.insider}
        style={{ height: `${percentage}%`, backgroundColor: color }}
      />
    </div>
    <div className={styles.number}>{number}</div>
    {actionComponent}
  </div>
);
