export enum ProjectDashboardMixpanelEventNames {
  // Canopy
  ProjectDashboardTopNewCanopy = "project_dashboard_top_NewCanopy",
  ProjectDashboardBottomNewCanopy = "project_dashboard_bottom_NewCanopy",
  ProjectDashboardCanopyCanopies = "project_dashboard_canopy_canopies",
  ProjectDashboardCanopyCardTitle = "project_dashboard_canopy_card_title",
  ProjectDashboardCanopyCardExperts = "project_dashboard_canopy_card_experts",

  // Chat
  ProjectDashboardClientChat = "project_dashboard_client_chat",
  ProjectDashboardTopChat = "project_dashboard_top_chat",

  // ArbolusGPT
  ProjectDashboardArbolusGPTTest = "project_dashboard_arbolusGPT_test"
}
