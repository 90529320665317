import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CIQError,
  CustomersService,
  DefaultToasterService,
  ErrorResponse,
  ExpertService,
  ToasterService
} from "@arbolus-technologies/api";
import {
  AddCreateVendorsPanel,
  ConfirmedVendors,
  useModalService
} from "@arbolus-technologies/features/common";
import { JobWithVendors, Vendor } from "@arbolus-technologies/models/common";
import { LoaderOrComponent } from "@arbolus-technologies/ui/components";

import { EditExpertVendorItem } from "./EditExpertVendorItem";

import styles from "./EditExpertVendors.module.scss";

interface EditExpertVendorsProps {
  expertId: string;
  expertService?: typeof ExpertService;
  notificationService?: ToasterService;
}
export const EditExpertVendors: React.FC<EditExpertVendorsProps> = ({
  expertId,
  notificationService = DefaultToasterService
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [jobsWithVendors, setJobsWithVendors] = useState<JobWithVendors[]>([]);
  const modalService = useModalService();
  const { t } = useTranslation("editExpertVendors");

  useEffect(
    function getJobsWithVendors() {
      setIsLoading(true);
      CustomersService.getInsightsFromExpert(expertId).subscribe(
        (response) => {
          setJobsWithVendors(response.items);
          setIsLoading(false);
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showError(error.message);
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [expertId]
  );

  function assignVendorsToExpert(
    vendors: ConfirmedVendors,
    _: unknown,
    workHistoryId?: string
  ) {
    if (!workHistoryId) return;
    const insight = {
      expertId,
      vendorCompanyIds: vendors.map((vendor) => vendor.vendorCompanyId),
      workHistoryId
    };
    CustomersService.createInsight(insight).subscribe(
      (response) => {
        const createdInsights: Vendor[] = response.insightVendors!.map(
          (item) => ({
            vendorCompanyId: item.companyId,
            vendorCompanyName: item.companyName,
            customerInsightId: item.customerInsightId
          })
        );
        setJobsWithVendors((previousJobs) => {
          return previousJobs.map((job) => {
            if (job.expertWorkHistoryId === workHistoryId) {
              return {
                ...job,
                insightVendors: [...job.insightVendors, ...createdInsights]
              };
            }
            return job;
          });
        });
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showError(error.message);
      }
    );
  }

  function handleRemoveVendor(workHistoryId: string, vendor: Vendor) {
    modalService.openMainModal({
      title: t("titleModal", { name: vendor.vendorCompanyName }),
      subtitle: t("subtitleModal"),
      type: "rejection",
      confirmText: t("confirmTextButtonModal"),
      onConfirm: () => {
        callDeleteInsight(workHistoryId, vendor.customerInsightId);
        modalService.closeMainModal();
      }
    });
  }

  function callDeleteInsight(workHistoryId: string, customerInsightId: string) {
    CustomersService.deleteInsight(customerInsightId).subscribe(
      () => {
        setJobsWithVendors((previousJobs) =>
          previousJobs.map((job) => {
            if (job.expertWorkHistoryId === workHistoryId) {
              return {
                ...job,
                insightVendors: job.insightVendors.filter(
                  (insight) => insight.customerInsightId !== customerInsightId
                )
              };
            }
            return job;
          })
        );
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showError(error.message);
      }
    );
  }

  return (
    <LoaderOrComponent isLoading={isLoading}>
      <div className={styles.container}>
        {jobsWithVendors.map((job, index) => (
          <EditExpertVendorItem
            key={job.expertWorkHistoryId}
            isFirst={index === 0}
            companyName={job.expertCompanyName}
            workHistoryId={job.expertWorkHistoryId}
            editVendorsList={job.insightVendors}
            onRemoveVendor={handleRemoveVendor}
            isCurrentJob={job.isCurrentJob}
          />
        ))}
      </div>

      <AddCreateVendorsPanel onVendorsConfirmed={assignVendorsToExpert} />
    </LoaderOrComponent>
  );
};
