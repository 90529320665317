import { Button, Divider, Space } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

interface LoadMoreProps {
  query: string;
  offset: number;
  onGetItems: (query: string, offset: number) => void;
}

export const LoadMore: React.FC<LoadMoreProps> = ({
  query,
  offset,
  onGetItems
}) => {
  const { t } = useTranslation("antDSelectMenu");

  return (
    <>
      <Divider style={{ margin: "8px 0" }} />
      <Space style={{ padding: "0 8px 4px" }}>
        <Button type="text" onClick={() => onGetItems(query, offset)}>
          {t("loadMore")}
        </Button>
      </Space>
    </>
  );
};
