import clsx from "clsx";
import dompurify from "dompurify";
import React from "react";

import { ProgressComponent } from "../ProgressComponent/ProgressComponent";

import styles from "./ProgressComponentWithText.module.scss";

interface ProgressComponentWithTextProps {
  progressEndClick?: () => void;
  progressText?: string;
  progressEndTextClick?: string;
  progressTitle?: string;
  value: number;
  hasValue?: boolean;
  isDisabled?: boolean;
}

export const ProgressComponentWithText: React.FC<
  ProgressComponentWithTextProps
> = ({
  progressTitle,
  progressText,
  progressEndClick,
  progressEndTextClick,
  value,
  hasValue,
  isDisabled
}) => (
  <div className={styles.containerProgressComponent}>
    <span className={styles.progressTitle}>{progressTitle}</span>
    <div className={styles.progressBarContainer}>
      <ProgressComponent value={value} hasValue={hasValue} />
      {(progressText || progressEndTextClick) && (
        <div className={styles.progressEndContainer}>
          {progressText && (
            <div
              className={styles.progressEndText}
              dangerouslySetInnerHTML={{
                __html: dompurify.sanitize(progressText)
              }}
            />
          )}
          {progressEndTextClick && (
            <div
              className={clsx(styles.progressEndCTA, {
                [styles.disabled]: isDisabled
              })}
              onClick={progressEndClick}
            >
              {progressEndTextClick}
              <span
                style={{ marginRight: "-9px" }}
                className={clsx("material-symbols-sharp icon", styles.icon)}
              >
                chevron_right
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  </div>
);
