import React from "react";

import { SchedulingTable } from "../../Modules/ReferralsTable/SchedulingTable";
import { ReferralsPage } from "./ReferralsPage";

export const SchedulingPage: React.FC = () => (
  <ReferralsPage page="scheduling">
    <SchedulingTable />
  </ReferralsPage>
);
