/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from "@hookform/resolvers/yup";
import { Icon } from "arbolus-ui-components";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { InputController } from "../InputController/InputController";
import { GetDangerSchema } from "./ArbolusModalDangerSchema";
import {
  InternalArbolusModal,
  InternalArbolusModalProps
} from "./InternalArbolusModal";
import { ModalButton } from "./ModalButton";

import styles from "./ArbolusModalDanger.module.scss";

type DangerInternalProps = Omit<
  InternalArbolusModalProps,
  "subtitle" | "title"
>;

interface ArbolusModalDangerProps extends DangerInternalProps {
  confirmationText: string;
  warningMessage?: string;
  title?: string;
  leftButton: ModalButton;
}

interface ArbolusModalDangerForm {
  confirmationText: string;
}

export const ArbolusModalDanger: React.FC<ArbolusModalDangerProps> = ({
  title,
  isOpen,
  toggle,
  iconTitle,
  leftButton,
  rightButton,
  confirmationText,
  warningMessage
}) => {
  const { t } = useTranslation("dangerDeleteModal");
  const {
    control,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors, isDirty, isValid }
  } = useForm<ArbolusModalDangerForm>({
    mode: "onChange",
    // @ts-ignore
    resolver: yupResolver(GetDangerSchema(confirmationText)),
    defaultValues: {
      confirmationText: ""
    }
  });

  const onSubmit: SubmitHandler<ArbolusModalDangerForm> = (_) => {
    reset();
    clearErrors();
    rightButton.onClick(undefined);
  };

  const cancelForm = (e: any) => {
    reset();
    clearErrors();
    leftButton.onClick(e);
  };

  return (
    <InternalArbolusModal
      title={title ?? t("title")}
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
      iconTitle={iconTitle}
      leftButton={{ ...leftButton, onClick: cancelForm }}
      rightButton={{
        ...rightButton,
        onClick: handleSubmit(onSubmit),
        disabled: rightButton.disabled || !isValid || !isDirty
      }}
      body={
        <div className={styles.modalBodyContainer}>
          <div className={styles.warningContainer}>
            <div className={styles.warningIcon}>
              <Icon
                name="warning"
                color={ARBOLUS_COLORS.bColorBaseWhite}
                fontSize="24px"
              />
            </div>
            <div>
              <div className={styles.warningMessageTitle}>
                {t("warningTitle")}
              </div>
              <div className={styles.warningMessageText}>
                {warningMessage ?? t("warningMessage", { confirmationText })}
              </div>
            </div>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.warningMessage}>
              <div className={styles.warningMessageTitle}>
                {t("pleaseTypeA")}
                <span className={styles.warningConfirmationText}>
                  {confirmationText}
                </span>
                {t("pleaseTypeB")}
              </div>
              <div className={styles.warningMessage}>
                <InputController
                  name="confirmationText"
                  error={errors.confirmationText}
                  control={control}
                  placeholder=""
                />
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};
