/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";

export interface NavBarContextProps {
  isExpand: boolean;
  isHeaderVisible: boolean;
  isProjectActionsActive: boolean;
  toggle: () => void;
  projectActionToggle: () => void;
}

const defaultNavBarContext: NavBarContextProps = {
  isExpand: false,
  isHeaderVisible: false,
  isProjectActionsActive: false,
  toggle: () => {},
  projectActionToggle: () => {}
};

const NavBarContext =
  React.createContext<NavBarContextProps>(defaultNavBarContext);

export const NavBarContextProvider = NavBarContext.Provider;
export const NavBarContextConsumer = NavBarContext.Consumer;
