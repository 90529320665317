import React from "react";
import { useTranslation } from "react-i18next";

import people from "../../assets/people.svg";

import styles from "./NoResponses.module.scss";

export const NoResponses: React.FC = () => {
  const { t } = useTranslation("canopy");
  return (
    <div className={styles.noQuestions}>
      <img className={styles.imgRound} src={people} alt="logo people" />
      <span className={styles.text}>{t("noResponsesYet")}</span>
    </div>
  );
};
