import { ColDef, GridReadyEvent } from "ag-grid-community";
import React, { useCallback, useMemo } from "react";

import { ProjectService } from "@arbolus-technologies/api";
import { ProjectDraftModel } from "@arbolus-technologies/models/common";
import { ArbolusGrid } from "@arbolus-technologies/ui/components";

import { ProjectDraftsDataSource } from "./ProjectDraftsDataSource";
import { useProjectDraftsTableColumnDefs } from "./ProjectTableColumnDefs";

interface ProjectDraftsTableProps {
  projectService?: typeof ProjectService;
}

export const ProjectDraftsTable: React.FC<ProjectDraftsTableProps> = ({
  projectService = ProjectService
}) => {
  const onGridReady = useCallback(
    (event: GridReadyEvent<ProjectDraftModel>) => {
      const { api } = event;
      api.setGridOption(
        "serverSideDatasource",
        new ProjectDraftsDataSource(projectService)
      );
      api.sizeColumnsToFit();
    },
    [projectService]
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      resizable: true,
      sortable: true,
      suppressMovable: true,
      menuTabs: ["filterMenuTab"]
    }),
    []
  );

  const columnDefs: ColDef[] = useProjectDraftsTableColumnDefs();

  return (
    <ArbolusGrid
      rowModelType="serverSide"
      getRowId={({ data }) => data.id}
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      onGridReady={onGridReady}
      rowSelection="multiple"
      suppressRowClickSelection
      skipHeaderOnAutoSize
      suppressContextMenu
      suppressMenuHide
      pagination
      paginationAutoPageSize
    />
  );
};
