import React from "react";
import { useTranslation } from "react-i18next";

import { UnknownCompanyIconColor } from "../../../models/Company";
import { BadgeWithIcon } from "../BadgeWithIcon";

export const UnknownCompanyBadge: React.FC = () => {
  const { t } = useTranslation("unknownCompanyIcon");

  return (
    <BadgeWithIcon
      text={t("text")}
      icon="help"
      color={UnknownCompanyIconColor}
    />
  );
};
