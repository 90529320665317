import { Button, Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  CanopyQuestionValue,
  CreateEditQuestionFields,
  FormConstraints
} from "@arbolus-technologies/models/canopy";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { InputController } from "@arbolus-technologies/ui/components";

import { useCanopyContext } from "../../../../../../../helpers/useCanopyContext";

import styles from "./ValueTypeMultiRating.module.scss";

export const ValueTypeMultiRating: React.FC = () => {
  const { t } = useTranslation("canopyBuilder");
  const { isCanopyActive, showUnsavedChanges } = useCanopyContext();

  const {
    control,
    formState: { errors, dirtyFields },
    watch,
    trigger
  } = useFormContext<CanopyQuestionValue>();

  const {
    fields: choiceFields,
    append: choiceAppend,
    remove: choiceRemove,
    update: choiceUpdate
  } = useFieldArray({
    name: CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE_MULTI_RATING_CHOICES
  });

  const answerPreferencesTypeValueMultiRatingChoices = watch(
    CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE_MULTI_RATING_CHOICES
  );

  useEffect(() => {
    // to fix sortOrder when one of the option is deleted from the middle
    answerPreferencesTypeValueMultiRatingChoices.forEach((choice, index) => {
      if (choice.sortOrder !== index) {
        choiceUpdate(index, { text: choice.text, sortOrder: index });
      }
    });
  }, [answerPreferencesTypeValueMultiRatingChoices, choiceUpdate, trigger]);

  const isAddChoiceDisabled =
    answerPreferencesTypeValueMultiRatingChoices.length ===
      FormConstraints.MAX_CHOICES || isCanopyActive;

  return (
    <div className={styles.container}>
      {choiceFields.length > 0 && (
        <div className={styles.inputList}>
          {choiceFields.map((field, index) => (
            <div key={field.id} className={styles.inputContainer}>
              <InputController
                key={field.id}
                name={`answerPreferencesTypeValueMultiRatingChoices[${index}].text`}
                error={
                  errors.answerPreferencesTypeValueMultiRatingChoices?.[index]
                    ?.text
                }
                control={control}
                placeholder={t("choicePlaceholder", {
                  index: index + 1
                })}
                type="text"
                disabled={isCanopyActive}
                classnames={clsx(styles.input, {
                  [styles.unsavedField]:
                    showUnsavedChanges &&
                    dirtyFields.answerPreferencesTypeValueMultiRatingChoices?.[
                      index
                    ]?.text
                })}
                autoFocus
              />
              <Icon
                name="delete"
                color={ARBOLUS_COLORS.bColorGrayIcon}
                fontSize="18px"
                onClick={() => {
                  choiceRemove(index);
                  trigger(
                    CreateEditQuestionFields.ANSWER_PREFERENCES_TYPE_VALUE_MULTI_RATING_CHOICES
                  );
                }}
                disabled={isCanopyActive}
              />
            </div>
          ))}
        </div>
      )}
      <div className={styles.buttonsContainer}>
        <Button
          onClick={() =>
            choiceAppend({
              text: "",
              sortOrder: answerPreferencesTypeValueMultiRatingChoices.length
            })
          }
          text={t("addChoice")}
          type="tertiary"
          disabled={isAddChoiceDisabled}
        />
      </div>
    </div>
  );
};
