import React from "react";

import { CandidateTable } from "../../Modules/ReferralsTable/CandidateTable";
import { ReferralsPage } from "./ReferralsPage";

export const CandidatesPage: React.FC = () => (
  <ReferralsPage page="candidate">
    <CandidateTable />
  </ReferralsPage>
);
