import { BookmarkCollection } from "@arbolus-technologies/models/common";

import { RenameCollectionAction } from "../actions/RenameCollection/RenameCollectionActionTypes";
import { BookmarksReducerState } from "../models/definitions";
import { initialState } from "./InitialState";

export const handleRenameCollection = (
  state = initialState,
  { payload: { id, name } }: RenameCollectionAction
): BookmarksReducerState => {
  if (state.collection.item?.id !== id) {
    return state;
  }

  const item = BookmarkCollection.create({
    ...state.collection.item.toModel(),
    name
  });

  return {
    ...state,
    collection: {
      ...state.collection,
      item
    }
  };
};
