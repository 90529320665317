import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { Badge } from "../../../Badges/Badge";

import styles from "./MenuButton.module.scss";

export interface MenuButtonProps {
  label: string;
  icon?: string;
  path: string;
  isActive?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  classnames?: string;
  badge?: {
    text: string;
    background?: string;
    fontColor?: string;
    borderColor?: string;
  };
}

export const MenuButton: React.FC<MenuButtonProps> = ({
  path,
  label,
  icon,
  onClick,
  classnames,
  isActive = false,
  disabled,
  badge
}) => {
  const handleOnClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (disabled) {
      event.preventDefault();
    } else if (onClick) {
      onClick();
    }
  };

  return (
    <Link
      to={path}
      className={clsx(styles.buttonLink, { disabled: disabled }, classnames)}
      onClick={(event) => handleOnClick(event)}
    >
      <button
        className={isActive ? styles.containerActive : styles.containerInactive}
        disabled={disabled}
      >
        {icon && (
          <span
            className={clsx(
              "material-symbols-sharp",
              isActive ? styles.iconActive : styles.iconInactive
            )}
          >
            {icon}
          </span>
        )}

        <p className={isActive ? styles.nameActive : styles.nameInactive}>
          {label}
        </p>
        {badge && (
          <Badge
            text={badge.text}
            textStyle="uppercase"
            background={badge.background ?? ARBOLUS_COLORS.bColorSecondaryGreen}
            fontColor={badge.fontColor}
            borderColor={badge.borderColor}
          />
        )}
      </button>
    </Link>
  );
};
