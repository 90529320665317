import clsx from "clsx";
import React, { useState } from "react";
import { GithubPicker } from "react-color";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";

import styles from "./ColorPicker.module.scss";

export interface ColorPickerProps {
  colors: string[];
  width?: string;
  triangle?: "hide" | "top-left" | "top-right";
  handleColorChange: (
    color: string,
    event?: React.ChangeEvent<HTMLInputElement>
  ) => void;
  selectedColor?: string;
  disabled?: boolean;
}

interface ColorEx {
  hex: string;
}

export const ColorPicker: React.FC<ColorPickerProps> = ({
  colors,
  triangle,
  handleColorChange,
  selectedColor,
  disabled
}) => {
  const [visible, setVisible] = useState(false);
  return (
    <Dropdown
      className={clsx(styles.dropdown, disabled && styles.disabledContainer)}
      isOpen={visible}
      toggle={() => setVisible(!visible)}
      disabled={disabled}
    >
      <DropdownToggle
        className={clsx(styles.toggle, disabled && styles.disabled)}
        caret
      >
        <div
          className={styles.selectedColor}
          style={{ background: selectedColor ? selectedColor : colors[0] }}
        />
      </DropdownToggle>
      <DropdownMenu
        className={styles.dropDownMenu}
        style={{ left: colors.length < 3 ? "-10px" : "-109px" }}
      >
        <div className={styles.colorPicker}>
          <GithubPicker
            onChange={() => setVisible(!visible)}
            onChangeComplete={(color: ColorEx) => handleColorChange(color.hex)}
            triangle={triangle}
            width={colors.length < 3 ? "100px" : "190px"}
            colors={colors}
          />
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};
