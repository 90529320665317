import React from "react";
import { Control, Controller } from "react-hook-form";

import { RadioOption } from "../RadioChoice/RadioChoice";
import { RADIO_VARIANT, RadioChoices } from "../RadioChoices/RadioChoices";

interface RadioChoicesControllerProps<T extends string | boolean> {
  control: Control<any>;
  name: string;
  options: RadioOption<T>[];
  variant?: RADIO_VARIANT;
}

export const RadioChoicesController = <T extends string | boolean>({
  options,
  name,
  control,
  variant
}: RadioChoicesControllerProps<T>): JSX.Element => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value } }) => (
      <RadioChoices
        defaultValue={value}
        onChange={onChange}
        options={options}
        variant={variant}
      />
    )}
  />
);
