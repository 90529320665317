import { Observable } from "rxjs";

import { Region, TimeZone, restService } from "@arbolus-technologies/api";
import { FeatureFlagsAppType } from "@arbolus-technologies/models/common";
import { CIQCrashAnalytics } from "@arbolus-technologies/utils";

import { METADATA_API, USERS_API } from "../constants/api";
import { ApiNonPaginatedResponse, ApiSuccessResponse } from "../models/api";
import {
  FederatedLoginUrlRequest,
  FederatedLoginUrlResponse
} from "../models/auth";
import {
  AgreementType,
  Country,
  Currency,
  EngagementAgreement,
  IndustriesRequest,
  Industry,
  UserPermissions
} from "../models/meta";

export const metaService = {
  getCountries: (): Observable<ApiNonPaginatedResponse<Country>> =>
    restService.get<ApiNonPaginatedResponse<Country>>(
      METADATA_API.GET_COUNTRIES()
    ),
  getIndustries: (
    level: IndustriesRequest
  ): Observable<ApiNonPaginatedResponse<Industry>> =>
    restService.get<ApiNonPaginatedResponse<Industry>>(
      METADATA_API.GET_INDUSTRIES(),
      level
    ),
  getTimezones: (): Observable<ApiNonPaginatedResponse<TimeZone>> =>
    restService.get<ApiNonPaginatedResponse<TimeZone>>(
      METADATA_API.GET_TIMEZONES()
    ),
  getRegions: (): Observable<ApiNonPaginatedResponse<Region>> =>
    restService.get<ApiNonPaginatedResponse<Region>>(
      METADATA_API.GET_REGIONS()
    ),
  federatedLoginUrl({
    authenticationProvider
  }: FederatedLoginUrlRequest): Observable<FederatedLoginUrlResponse> {
    return restService.get<FederatedLoginUrlResponse>(USERS_API.OPEN_ID(), {
      authenticationProvider
    });
  },
  getCurrencies: (
    recaptchaToken?: string
  ): Observable<ApiNonPaginatedResponse<Currency>> =>
    restService.get<ApiNonPaginatedResponse<Currency>>(
      METADATA_API.GET_CURRENCIES(),
      { recaptchaToken }
    ),
  getEngagementAgreement: (
    agreementType: AgreementType,
    clientId?: string,
    projectId?: string
  ): Observable<EngagementAgreement> =>
    restService.get<EngagementAgreement>(
      METADATA_API.GET_ENGAGEMENT_AGREEMENT(),
      { agreementType, clientId, projectId }
    ),
  sendAppCrashData: (
    errorData: CIQCrashAnalytics
  ): Observable<ApiSuccessResponse> =>
    restService.post<ApiSuccessResponse>(METADATA_API.POST_APP_CRASH_DATA(), {
      errorData
    }),
  getFeatureFlags: (
    frontendAppType: FeatureFlagsAppType
  ): Observable<UserPermissions> =>
    restService.get<UserPermissions>(METADATA_API.GET_FEATURE_FLAGS(), {
      frontendAppType
    })
};
