import { Handlers, createReducer } from "reduxsauce";

import { Region, TimeZone } from "@arbolus-technologies/api";
import { DEFAULT_FEATURE_FLAGS } from "@arbolus-technologies/models/common";

import { REDIRECT_STATES } from "../../../constants/app";
import { INBOX_NOTIFICATION_CATEGORY } from "../../../constants/notifications";
import {
  Country,
  Currency,
  Industry,
  UserPermissions
} from "../../../models/meta";
import { ListProject } from "../../../models/project";
import { LOGOUT_USER_SUCCESS } from "../../auth/store/actions/LoginActionTypes";
import {
  GET_APP_DATA,
  GET_APP_DATA_FAILURE,
  GET_APP_DATA_SUCCESS,
  GET_FEATURE_FLAGS,
  GET_FEATURE_FLAGS_FAILURE,
  GET_FEATURE_FLAGS_SUCCESS,
  GetAppDataSuccessAction,
  GetFeatureFlagsSuccessAction,
  INITIALIZE_FLAGS_OVERRIDE,
  InitializeFlagsOverrideAction,
  OVERRIDE_FEATURE_FLAG,
  OverrideFeatureFlagAction,
  RESET_OVERRIDE_FEATURE_FLAG,
  SWITCH_SUBDOMAIN,
  SwitchSubdomainAction
} from "./actions/AppActionTypes";
import {
  CLOSE_CONTENT_PANEL,
  OPEN_CONTENT_PANEL
} from "./actions/ContentPanelActionTypes";
import {
  GET_NOTIFICATION_COUNTS_SUCCESS,
  GetNotificationCountsSuccessAction,
  MARK_NOTIFICATION_AS_READ_SUCCESS,
  MarkNotificationAsReadSuccessAction
} from "./actions/InboxActionTypes";
import {
  GET_PROJECT_LIST_FOR_NAVBAR,
  GET_PROJECT_LIST_FOR_NAVBAR_FAILURE,
  GET_PROJECT_LIST_FOR_NAVBAR_SUCCESS,
  GetProjectListForNavbarAction,
  GetProjectListForNavbarSuccessAction,
  NAVBAR_ADD_NEW_PROJECT,
  NAVBAR_REMOVE_PROJECT,
  NAVBAR_UPDATE_EXPERT_NEW_APPLICATIONS_COUNT,
  NAVBAR_UPDATE_PROJECT_NAME,
  NavbarAddNewProjectAction,
  NavbarRemoveProjectAction,
  NavbarUpdateProjectNameAction
} from "./actions/NavbarActionTypes";

export interface AppReducerState {
  projectListForNavbar: ListProject[];
  expertNewApplicationsCount?: number;
  projectListForNavbarLoading: boolean;
  countries: Country[];
  industriesLevel0: Industry[];
  industriesLevel2: Industry[];
  timezones: TimeZone[];
  regions: Region[];
  isMetaLoading: boolean;
  isContentPanelOpen: boolean;
  currencies: Currency[];
  redirectionType: REDIRECT_STATES;
  notificationCounts: Map<INBOX_NOTIFICATION_CATEGORY, number>;
  isFeatureFlagsReady: boolean;
  userPermissions: UserPermissions;
}

export const initialState: AppReducerState = {
  projectListForNavbar: [],
  projectListForNavbarLoading: false,
  countries: [],
  industriesLevel0: [],
  industriesLevel2: [],
  timezones: [],
  regions: [],
  isMetaLoading: false,
  isContentPanelOpen: false,
  currencies: [],
  redirectionType: REDIRECT_STATES.IDLE,
  notificationCounts: new Map(),
  isFeatureFlagsReady: false,
  userPermissions: {
    featureFlags: DEFAULT_FEATURE_FLAGS,
    featureFlagsDefaults: DEFAULT_FEATURE_FLAGS
  }
};

const handleGetProjectListForNavbar = (
  state = initialState,
  { payload }: GetProjectListForNavbarAction
): AppReducerState => ({
  ...state,
  projectListForNavbarLoading: payload.showLoading
});

const handleGetProjectListForNavbarSuccess = (
  state = initialState,
  { payload }: GetProjectListForNavbarSuccessAction
): AppReducerState => ({
  ...state,
  projectListForNavbar: payload.projectList,
  expertNewApplicationsCount: payload.expertNewProjectCount,
  projectListForNavbarLoading: false
});

const handleGetProjectListForNavbarFailure = (
  state = initialState
): AppReducerState => ({
  ...state,
  projectListForNavbarLoading: false
});

const handleNavbarAddNewProject = (
  state = initialState,
  { payload }: NavbarAddNewProjectAction
): AppReducerState => {
  const projectList = state.projectListForNavbar.slice(0, 4);
  return {
    ...state,
    projectListForNavbar: [payload.addedProject, ...projectList]
  };
};

const handleGetMetaData = (state = initialState): AppReducerState => ({
  ...state,
  isMetaLoading: true
});

const handleGetMetaDataSuccess = (
  state = initialState,
  { payload }: GetAppDataSuccessAction
): AppReducerState => ({
  ...state,
  isMetaLoading: false,
  countries: payload.countries,
  industriesLevel0: payload.industriesLevel0,
  industriesLevel2: payload.industriesLevel2,
  timezones: payload.timezones,
  regions: payload.regions,
  currencies: payload.currencies,
  notificationCounts: payload.notificationCounts
});
const handleGetMetaDataFailure = (state = initialState): AppReducerState => ({
  ...state,
  isMetaLoading: false
});

const handleNavbarUpdateCreatedProjectName = (
  state = initialState,
  { payload }: NavbarUpdateProjectNameAction
): AppReducerState => {
  const updatedNavbarList = state.projectListForNavbar.map((project) =>
    project.id === payload.projectId
      ? { ...project, name: payload.projectName }
      : project
  );
  return {
    ...state,
    projectListForNavbar: updatedNavbarList
  };
};

const handleNavbarRemoveProject = (
  state = initialState,
  { payload: { projectId } }: NavbarRemoveProjectAction
): AppReducerState => {
  const updatedNavbarProjectList = state.projectListForNavbar.filter(
    (project) => project.id !== projectId
  );

  return {
    ...state,
    projectListForNavbar: updatedNavbarProjectList
  };
};

const handleOpenContextPanel = (state = initialState): AppReducerState => ({
  ...state,
  isContentPanelOpen: true
});

const handleCloseContextPanel = (state = initialState): AppReducerState => ({
  ...state,
  isContentPanelOpen: false
});

const handleSwitchSubdomain = (
  state = initialState,
  { payload: { redirectType } }: SwitchSubdomainAction
): AppReducerState => ({
  ...state,
  redirectionType: redirectType
});

const handleLogoutUserSuccess = (): AppReducerState => initialState;

const handleGetNotificationCount = (
  state = initialState,
  { payload: { notificationCounts } }: GetNotificationCountsSuccessAction
): AppReducerState => ({ ...state, notificationCounts });

const handleMarkNotificationAsRead = (
  state = initialState,
  { payload: { notificationCategories } }: MarkNotificationAsReadSuccessAction
): AppReducerState => {
  const { notificationCounts } = state;
  const nextNotificationCounts = new Map<INBOX_NOTIFICATION_CATEGORY, number>(
    notificationCounts
  );

  notificationCategories.forEach((notificationCategory) => {
    const oldUnreadCount =
      nextNotificationCounts.get(notificationCategory) || 0;
    if (oldUnreadCount > 0) {
      const newUnreadCount = oldUnreadCount - 1;
      nextNotificationCounts.set(notificationCategory, newUnreadCount);
    }
  });

  return {
    ...state,
    notificationCounts: nextNotificationCounts
  };
};

const handleUpdateExpertNewApplicationsCount = (
  state = initialState
): AppReducerState => {
  const { expertNewApplicationsCount } = state;
  let newApplicationsCount = expertNewApplicationsCount;
  if (expertNewApplicationsCount && expertNewApplicationsCount > 0) {
    newApplicationsCount = expertNewApplicationsCount - 1;
  }

  return {
    ...state,
    expertNewApplicationsCount: newApplicationsCount
  };
};

const handleGetFeatureFlags = (state = initialState): AppReducerState => ({
  ...state,
  isFeatureFlagsReady: false
});

const handleGetFeatureFlagsSuccess = (
  state = initialState,
  { payload }: GetFeatureFlagsSuccessAction
): AppReducerState => ({
  ...state,
  isFeatureFlagsReady: true,
  userPermissions: {
    featureFlags: {
      ...payload.userPermissions.featureFlags
    },
    featureFlagsDefaults: {
      ...payload.userPermissions.featureFlags
    }
  }
});

const handleGetFeatureFlagsFailure = (
  state = initialState
): AppReducerState => ({
  ...state,
  isFeatureFlagsReady: false
});

const handleOverrideFeatureFlag = (
  state: AppReducerState,
  { payload }: OverrideFeatureFlagAction
): AppReducerState => ({
  ...state,
  userPermissions: {
    ...state.userPermissions,
    featureFlags: {
      ...state.userPermissions.featureFlags,
      [payload.name]: payload.value
    }
  }
});

const handleResetOverrideFeatureFlag = (
  state: AppReducerState
): AppReducerState => ({
  ...state,
  userPermissions: {
    ...state.userPermissions,
    featureFlags: {
      ...state.userPermissions.featureFlagsDefaults
    }
  }
});

const handleInitializeFlagsOverride = (
  state: AppReducerState,
  { payload }: InitializeFlagsOverrideAction
): AppReducerState => ({
  ...state,
  userPermissions: {
    ...state.userPermissions,
    featureFlags: {
      ...payload.flags
    }
  }
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handlers: Handlers<AppReducerState, any> = {
  [CLOSE_CONTENT_PANEL]: handleCloseContextPanel,
  [GET_APP_DATA]: handleGetMetaData,
  [GET_APP_DATA_FAILURE]: handleGetMetaDataFailure,
  [GET_APP_DATA_SUCCESS]: handleGetMetaDataSuccess,
  [GET_NOTIFICATION_COUNTS_SUCCESS]: handleGetNotificationCount,
  [GET_PROJECT_LIST_FOR_NAVBAR]: handleGetProjectListForNavbar,
  [GET_PROJECT_LIST_FOR_NAVBAR_FAILURE]: handleGetProjectListForNavbarFailure,
  [GET_PROJECT_LIST_FOR_NAVBAR_SUCCESS]: handleGetProjectListForNavbarSuccess,
  [LOGOUT_USER_SUCCESS]: handleLogoutUserSuccess,
  [NAVBAR_ADD_NEW_PROJECT]: handleNavbarAddNewProject,
  [NAVBAR_REMOVE_PROJECT]: handleNavbarRemoveProject,
  [NAVBAR_UPDATE_PROJECT_NAME]: handleNavbarUpdateCreatedProjectName,
  [OPEN_CONTENT_PANEL]: handleOpenContextPanel,
  [SWITCH_SUBDOMAIN]: handleSwitchSubdomain,
  [MARK_NOTIFICATION_AS_READ_SUCCESS]: handleMarkNotificationAsRead,
  [NAVBAR_UPDATE_EXPERT_NEW_APPLICATIONS_COUNT]:
    handleUpdateExpertNewApplicationsCount,
  [GET_FEATURE_FLAGS]: handleGetFeatureFlags,
  [GET_FEATURE_FLAGS_SUCCESS]: handleGetFeatureFlagsSuccess,
  [GET_FEATURE_FLAGS_FAILURE]: handleGetFeatureFlagsFailure,
  [OVERRIDE_FEATURE_FLAG]: handleOverrideFeatureFlag,
  [RESET_OVERRIDE_FEATURE_FLAG]: handleResetOverrideFeatureFlag,
  [INITIALIZE_FLAGS_OVERRIDE]: handleInitializeFlagsOverride
};

export default createReducer(initialState, handlers);
