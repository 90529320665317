import { OutputSelector, createSelector } from "reselect";

import {
  ArbolusFeatureFlags,
  DEFAULT_FEATURE_FLAGS
} from "@arbolus-technologies/models/common";

type AppState = {
  app: AppReducerState;
};

type AppReducerState = {
  userPermissions: {
    featureFlags: ArbolusFeatureFlags;
    featureFlagsDefaults: ArbolusFeatureFlags;
  };
};

const initialState: AppReducerState = {
  userPermissions: {
    featureFlags: DEFAULT_FEATURE_FLAGS,
    featureFlagsDefaults: DEFAULT_FEATURE_FLAGS
  }
};

const appStateSelector = (state: AppState): AppReducerState =>
  state.app || initialState;

const getFeatureFlags = (): OutputSelector<
  AppState,
  ArbolusFeatureFlags,
  (res: AppReducerState) => ArbolusFeatureFlags
> =>
  createSelector<AppState, AppReducerState, ArbolusFeatureFlags>(
    appStateSelector,
    (appState) => appState.userPermissions.featureFlags
  );

export const AppSelector = {
  getFeatureFlags
};
