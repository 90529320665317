import { ColDef } from "ag-grid-community";

import { Referral } from "@arbolus-technologies/models/common";

export const FIRST_NAME_COLUMN_ID = "firstNameCol";
export const LAST_NAME_COLUMN_ID = "lastNameCol";
export const EMAIL_COLUMN_ID = "emailCol";
export const PHONE_COLUMN_ID = "phoneCol";

const firstNameCol: ColDef<Referral> = {
  colId: FIRST_NAME_COLUMN_ID,
  field: "expert.firstName",
  headerName: "First name",
  hide: true
};

const lastNameCol: ColDef<Referral> = {
  colId: LAST_NAME_COLUMN_ID,
  field: "expert.lastName",
  headerName: "Last name",
  hide: true
};

const emailCol: ColDef<Referral> = {
  colId: EMAIL_COLUMN_ID,
  field: "expert.email",
  headerName: "Email",
  hide: true
};

const phoneCol: ColDef<Referral> = {
  colId: PHONE_COLUMN_ID,
  field: "expert.phoneNumber",
  headerName: "Phone",
  hide: true
};

export const exportColumns = [firstNameCol, lastNameCol, emailCol, phoneCol];
