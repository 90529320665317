import i18next from "i18next";

import { FEATURE_FLAGS, UserRole } from "@arbolus-technologies/models/common";
import { PAYMENTS_ROUTE } from "@arbolus-technologies/routes";
import { EX_LINK_ARBOLUS_HELP } from "@arbolus-technologies/utils";

import { ISideBarItems } from "./MenuItemsConfig";
import {
  accountRouteAdmin,
  arbolusCanopyRoute,
  canopiesRoute,
  expertNetworkRoute,
  inboxRoute,
  mainRoute,
  profileEditRoute,
  projectsRoute,
  teamMembersRoute
} from "./MenuItemsRoutes";

export const sideBarItemsClientEnv = (): ISideBarItems[] => [
  {
    name: i18next.t("newMenu:Dashboard"),
    icon: "apps",
    route: mainRoute,
    position: "main",
    userRole: [UserRole.clientAdmin, UserRole.arbolusTeam, UserRole.client]
  },
  {
    name: i18next.t("newMenu:Canopy"),
    icon: "workspaces",
    route: canopiesRoute,
    position: "main",
    userRole: [UserRole.clientAdmin, UserRole.arbolusTeam, UserRole.client]
  },
  {
    name: i18next.t("newMenu:Projects"),
    icon: "folder",
    route: projectsRoute,
    position: "main",
    userRole: [
      UserRole.clientAdmin,
      UserRole.arbolusTeam,
      UserRole.client,
      UserRole.expert
    ]
  },
  {
    name: i18next.t("newMenu:ArbolusCanopy"),
    icon: "workspaces",
    route: arbolusCanopyRoute,
    position: "main",
    userRole: [UserRole.expert]
  },
  {
    name: i18next.t("newMenu:Network"),
    icon: "groups",
    route: expertNetworkRoute,
    position: "main",
    userRole: [UserRole.clientAdmin, UserRole.arbolusTeam, UserRole.client]
  },
  {
    name: i18next.t("newMenu:Inbox"),
    icon: "mail",
    route: inboxRoute,
    position: "main",
    userRole: [
      UserRole.clientAdmin,
      UserRole.arbolusTeam,
      UserRole.client,
      UserRole.expert
    ]
  },
  {
    name: i18next.t("newMenu:Payments"),
    icon: "account_balance",
    route: PAYMENTS_ROUTE().pathname,
    position: "main",
    userRole: [UserRole.expert],
    featureFlag: FEATURE_FLAGS.Tipalti
  },
  {
    name: i18next.t("newMenu:Settings"),
    icon: "settings",
    subItemsTitle: i18next.t("newMenu:Settings"),
    position: "footer",
    userRole: [
      UserRole.clientAdmin,
      UserRole.arbolusTeam,
      UserRole.client,
      UserRole.expert
    ],
    subItems: [
      {
        name: i18next.t("newMenu:Account"),
        route: accountRouteAdmin,
        icon: "manage_accounts",
        position: "main",
        userRole: [UserRole.arbolusTeam, UserRole.clientAdmin]
      },
      {
        name: i18next.t("newMenu:MyDetails"),
        route: profileEditRoute,
        icon: "manage_accounts",
        position: "main",
        userRole: [UserRole.expert]
      },
      {
        name: i18next.t("newMenu:TeamMembers"),
        route: teamMembersRoute,
        icon: "group",
        position: "main",
        userRole: [UserRole.arbolusTeam, UserRole.clientAdmin]
      },
      {
        name: "Feature flags",
        route: "/feature-override",
        icon: "flag",
        position: "main",
        userRole: [
          UserRole.clientAdmin,
          UserRole.arbolusTeam,
          UserRole.client,
          UserRole.expert
        ],
        featureFlag: FEATURE_FLAGS.FeatureFlagOverride
      },
      {
        name: i18next.t("newMenu:Logout"),
        icon: "logout",
        position: "footer",
        userRole: [
          UserRole.clientAdmin,
          UserRole.arbolusTeam,
          UserRole.client,
          UserRole.expert
        ]
      }
    ]
  },
  {
    name: i18next.t("newMenu:Support"),
    icon: "help",
    position: "footer",
    route: EX_LINK_ARBOLUS_HELP,
    userRole: [UserRole.expert]
  }
];
