import React from "react";
import { useDispatch } from "react-redux";

import {
  PROJECT_AVAILABILITY_ROUTE,
  PROJECT_BRIEF_ROUTE
} from "@arbolus-technologies/routes";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import {
  AvailabilityButton,
  LoaderOrComponent,
  ProjectBriefButton,
  ReferExpertButton
} from "@arbolus-technologies/ui/components";
import { GrayBox } from "@arbolus-technologies/ui/layout";

import { ReferExpertSlidePanel } from "../../Components/ReferExpertSlidePanel/ReferExpertSlidePanel";

import styles from "./QuickActionsMenu.module.scss";

interface ExpertActionsMenuProps {
  projectId: string;
  isLoading: boolean;
  isDnc: boolean;
}

export const ExpertActionsMenu: React.FC<ExpertActionsMenuProps> = ({
  projectId,
  isLoading,
  isDnc
}) => {
  const dispatch = useDispatch();

  return (
    <GrayBox fullWidth>
      <LoaderOrComponent isLoading={isLoading}>
        <div className={styles.container}>
          <ProjectBriefButton to={PROJECT_BRIEF_ROUTE(projectId)} />
          {!isDnc && (
            <>
              <AvailabilityButton to={PROJECT_AVAILABILITY_ROUTE(projectId)} />
              <ReferExpertButton
                onClick={() => {
                  dispatch(PanelStoreActions.openPanel(PanelId.ReferExpert));
                }}
              />
            </>
          )}
        </div>
        <ReferExpertSlidePanel projectId={projectId} />
      </LoaderOrComponent>
    </GrayBox>
  );
};
