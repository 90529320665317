import clsx from "clsx";
import { Field, FieldProps, getIn } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, FormGroup, Label } from "reactstrap";

import { ComplianceAnswerOptions } from "../../../../../constants/expert";
import { UtilsService } from "../../../../../services";
import { yesNoQuestionSchema } from "../ProjectApplicationSchema";
import { ComplianceQuestionProps } from "./ProjectCompliance";

const YesNoNa: React.FC<ComplianceQuestionProps> = ({
  compliance: { question },
  index,
  formProps: { touched, errors }
}): JSX.Element => {
  const { t } = useTranslation("projectApplication");
  const { Yes, No, NotApplicable } = ComplianceAnswerOptions;
  const { question: QuestionText } = question;
  const { displayModel } = question;
  const { required } = displayModel;

  const fieldKey = `compliances[${index}].answer.answer`;

  const error = getIn(errors, fieldKey);
  const isError = error && getIn(touched, fieldKey);

  const validateAsync = async (value: string): Promise<string | null> => {
    try {
      await yesNoQuestionSchema.validate({ value, required });
      return null;
    } catch (err) {
      return err.message;
    }
  };

  return (
    <div className="compliance-item">
      <FormGroup
        className={clsx({
          "is-invalid": isError
        })}
      >
        <Label>{UtilsService.parseRichTextContent(QuestionText)}</Label>
        <Field name={fieldKey} validate={validateAsync}>
          {({
            field: { value },
            form: { setFieldValue }
          }: FieldProps): JSX.Element => (
            <ButtonGroup className="custom-radio-btn-group not-applicable">
              <Button
                onClick={(): void => {
                  setFieldValue(fieldKey, Yes);
                }}
                active={value === Yes}
              >
                {t("yes")}
              </Button>
              <Button
                onClick={(): void => {
                  setFieldValue(fieldKey, No);
                }}
                active={value === No}
              >
                {t("no")}
              </Button>
              <Button
                onClick={(): void => {
                  setFieldValue(fieldKey, NotApplicable);
                }}
                active={value === NotApplicable}
              >
                {t("n/a")}
              </Button>
            </ButtonGroup>
          )}
        </Field>
        {isError && <div className="invalid-feedback">{error}</div>}
      </FormGroup>
    </div>
  );
};

export default YesNoNa;
