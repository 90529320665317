import React from "react";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";

import { ValueTypes } from "./ValueTypes/ValueTypes";

import styles from "./ValueAnswers.module.scss";

export const ValueAnswers: React.FC = () => {
  const { t } = useTranslation("canopyBuilder");

  return (
    <div className={styles.container}>
      <Label className={styles.label}>{t("answerPreferences")}</Label>
      <ValueTypes />
    </div>
  );
};
