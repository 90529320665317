import i18next from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  DefaultToasterService,
  ProjectService,
  ToasterService
} from "@arbolus-technologies/api";
import { CONTACT_TYPE_ENUM } from "@arbolus-technologies/models/expert";
import { ArbolusModalSelectable } from "@arbolus-technologies/ui/components";

import { useExternalSourcing } from "../../../Contexts/ExternalSourcingContext/ExternalSourcingContext";

interface UpdatePipelineStatusModalProps {
  currentStatus: CONTACT_TYPE_ENUM;
  close: () => void;
  onBulkStatusUpdated: () => void;
  projectService?: typeof ProjectService;
  notificationService?: ToasterService;
}

export const UpdatePipelineStatusModal: React.FC<
  UpdatePipelineStatusModalProps
> = ({
  currentStatus,
  close,
  onBulkStatusUpdated,
  projectService = ProjectService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("pipelineExpertList");
  const { selectedExpertsIds } = useExternalSourcing();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [targetStatus, setTargetStatus] = useState(currentStatus);

  const statusList = Object.values(CONTACT_TYPE_ENUM)
    .filter(
      (type) =>
        type !== CONTACT_TYPE_ENUM.REPLIED &&
        type !== CONTACT_TYPE_ENUM.REJECTED_DECLINED
    )
    .map((type) => ({
      value: type,
      label: i18next.t(`externalExpertStatus:${type}`)
    }));

  const closeModal = () => {
    if (isSubmitting) {
      return;
    }

    close();
  };

  const updatePipelineStatus = () => {
    setIsSubmitting(true);

    projectService.updateBulkStatus(selectedExpertsIds, targetStatus).subscribe(
      () => {
        notificationService.showSuccess(t("statusChangedSuccessfully"));
        setIsSubmitting(false);
        onBulkStatusUpdated();
        close();
      },
      () => {
        notificationService.showError(t("bulkUpdateFailed"));
        setIsSubmitting(false);
      }
    );
  };

  return (
    <ArbolusModalSelectable
      title={t("changeStatusTitle")}
      subtitle={t("changeStatusSubtitle")}
      toggle={closeModal}
      rightButton={{
        type: "primary",
        onClick: updatePipelineStatus,
        text: t("confirm"),
        disabled: isSubmitting || currentStatus === targetStatus
      }}
      leftButton={{
        type: "tertiary",
        onClick: closeModal,
        text: t("cancel"),
        disabled: isSubmitting
      }}
      isOpen
      options={statusList}
      defaultValue={statusList.find((status) => status.value === targetStatus)!}
      onSelectChange={(value: string) =>
        setTargetStatus(value as CONTACT_TYPE_ENUM)
      }
    />
  );
};
