import {
  ColDef,
  GridApi,
  GridReadyEvent,
  InitialGroupOrderComparatorParams
} from "ag-grid-community";
import clsx from "clsx";
import React from "react";
import { useSelector } from "react-redux";

import {
  DO_NOT_CONTACT_STATUS,
  Referral,
  SlotsReferral
} from "@arbolus-technologies/models/common";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { ArbolusGrid } from "@arbolus-technologies/ui/components";

import { SchedulingGroupRenderer } from "../../../Components/CellRenderers/ChipGroupRenderer";
import { SlotsDetail } from "../../../Components/ReferralSlotsDetail/SlotsDetail";
import { IContextMenuService } from "../../../Services/Referrals/ContextMenuService";
import {
  DefaultEditCellService,
  IEditCellService
} from "../../../Services/Referrals/EditTaglineService";
import { DefaultSchedulingMenuService } from "../../../Services/Referrals/SchedulingMenuService";
import { SchedulingActionsCol } from "../columns/ActionColumns";
import {
  angleCol,
  earliestAvailabilityCol,
  slotsColumn,
  taglineCol
} from "../columns/ColumnDefinitions";
import { approvedDateCol, modifiedDateCol } from "../columns/DateColumns";
import { expertMasterNameCol } from "../columns/ExpertColumn";
import { exportColumns } from "../columns/ExportColumns";
import { getOwnerCol } from "../columns/OwnerColumn";
import { useDefaultReferralGridColumns } from "../hooks/useDefaultReferralGridColumns";
import { useReferralGridContext } from "../hooks/useReferralGridContext";
import { commonProps, groupProps } from "../referralsTableCommonProps";
import { BulkActionsStatusPanel } from "../statusPanels/BulkActionsStatusPanel";
import { ExpandCollapseStatusPanel } from "../statusPanels/ExpandCollapseStatusPanel";
import { SchedulingViewStatusPanel } from "../statusPanels/SchedulingViewStatusPanel";

import styles from "../ReferralsTable.module.scss";

interface ExpertSchedulingTableProps {
  referrals?: SlotsReferral[];
  contextMenuService?: IContextMenuService;
  editCellService?: IEditCellService;
}

export const ExpertSchedulingTable: React.FC<ExpertSchedulingTableProps> = ({
  referrals,
  contextMenuService = DefaultSchedulingMenuService,
  editCellService = DefaultEditCellService
}) => {
  const [api, setApi] = React.useState<GridApi<SlotsReferral>>();
  const project = useSelector(ProjectNxSelector.projectData());

  const onGridReady = React.useCallback(({ api }: GridReadyEvent) => {
    setApi(api);
  }, []);

  const defaultColDef = useDefaultReferralGridColumns();
  const gridContext = useReferralGridContext(api);

  const columnDefs = React.useMemo<ColDef<Referral>[]>(
    () => [
      slotsColumn as unknown as ColDef<Referral>,
      expertMasterNameCol,
      taglineCol,
      angleCol,
      getOwnerCol(project!.arbolusTeam),
      approvedDateCol,
      { ...modifiedDateCol, sort: undefined },
      earliestAvailabilityCol as unknown as ColDef<Referral>,
      SchedulingActionsCol,
      ...exportColumns
    ],
    [project]
  );

  const statusBar = React.useMemo(
    () => ({
      statusPanels: [
        {
          statusPanel: ExpandCollapseStatusPanel,
          align: "left"
        },
        {
          statusPanel: SchedulingViewStatusPanel,
          align: "right"
        },
        {
          statusPanel: BulkActionsStatusPanel,
          align: "right"
        }
      ]
    }),
    []
  );

  function isRowMaster(data: SlotsReferral) {
    return data.expert.doNotContactStatus !== DO_NOT_CONTACT_STATUS.DNC;
  }

  return (
    <div className="d-flex h-100 flex-column status-bar-gap">
      <div className={clsx(styles.greyGroup, styles.statusBarOnTop, "h-100")}>
        <ArbolusGrid
          {...commonProps}
          {...groupProps}
          onGridReady={onGridReady}
          context={gridContext}
          rowData={referrals}
          rowSelection="multiple"
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          getContextMenuItems={contextMenuService.getItems}
          statusBar={statusBar}
          initialGroupOrderComparator={initialGroupOrderComparator}
          readOnlyEdit
          onCellEditRequest={(event) => editCellService.editCell(event)}
          masterDetail
          detailCellRenderer={SlotsDetail}
          detailRowAutoHeight
          isRowMaster={isRowMaster}
          groupRowRendererParams={{
            innerRenderer: SchedulingGroupRenderer,
            suppressCount: true
          }}
        />
      </div>
    </div>
  );
};

function initialGroupOrderComparator({
  nodeA,
  nodeB
}: InitialGroupOrderComparatorParams): number {
  const keyA = nodeA.key === "true" ? 1 : 0;
  const keyB = nodeB.key === "true" ? 1 : 0;

  return keyB - keyA;
}
