import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import { ProjectService } from "@arbolus-technologies/api";
import { Loader } from "@arbolus-technologies/ui/components";

import { ProjectData } from "../types";

export function withProjectData(
  Component: React.ComponentType<{ projectData: ProjectData }>,
  projectService = ProjectService
): React.FC {
  const ProjectDataOrLoader = () => {
    const { projectId } = useParams<{ projectId: string }>();
    const [projectData, setProjectData] = useState<ProjectData>();

    useEffect(() => {
      projectService.getProject(projectId).subscribe(setProjectData);
    }, [projectId]);

    if (!projectData) return <Loader isFull />;

    return <Component projectData={projectData} />;
  };

  return ProjectDataOrLoader;
}
