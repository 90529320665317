import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  AdminService,
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ToasterService,
  User
} from "@arbolus-technologies/api";
import { Permission } from "@arbolus-technologies/models/common";
import { Loader, PermissionsForm } from "@arbolus-technologies/ui/components";

import { UpdateUserPermissionsModal } from "../../../Permissions/UpdatePermissionsModal/UpdatePermissionsModal";

interface AdminMemberPermissionsTabProps {
  user: User;
  handleCloseTeamMemberSlidePanel?: () => void;
  adminService?: typeof AdminService;
  notificationService?: ToasterService;
}

export const AdminMemberPermissionsTab: React.FC<
  AdminMemberPermissionsTabProps
> = ({
  user,
  handleCloseTeamMemberSlidePanel,
  adminService = AdminService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("permissions");
  const [
    displayPermissionConfirmationModal,
    setDisplayPermissionConfirmationModal
  ] = useState<boolean>(false);

  const [allowUpdate, setAllowUpdate] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<Permission[] | undefined>(
    undefined
  );
  const [updatedPermissions, setUpdatedPermissions] = useState<Permission[]>(
    []
  );

  const onSavePermissions = (formPermissions: Permission[]) => {
    setUpdatedPermissions(formPermissions);
    setDisplayPermissionConfirmationModal((prev) => !prev);
  };

  const updateUserPermissions = useCallback(() => {
    setDisplayPermissionConfirmationModal((prev) => !prev);
    adminService
      .updateUserSpecialPermissions(user.id, updatedPermissions)
      .subscribe(
        () => {
          notificationService.showSuccess(t("updateSuccess"));
          if (handleCloseTeamMemberSlidePanel) {
            handleCloseTeamMemberSlidePanel();
          }
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
        }
      );
  }, [
    handleCloseTeamMemberSlidePanel,
    notificationService,
    t,
    updatedPermissions,
    user.id,
    adminService
  ]);

  useEffect(() => {
    adminService.getUserSpecialPermissions(user.id).subscribe({
      next: (response) => {
        const { allowUpdate, items } = response;
        setAllowUpdate(allowUpdate);
        setPermissions(items);
      },
      error: (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
      }
    });
  }, [user.id, notificationService, adminService]);

  return (
    <>
      {permissions ? (
        <>
          <PermissionsForm
            allowUpdate={allowUpdate}
            permissions={permissions}
            onSave={onSavePermissions}
            onCancel={handleCloseTeamMemberSlidePanel}
          />
          <UpdateUserPermissionsModal
            isOpen={displayPermissionConfirmationModal}
            toggle={() =>
              setDisplayPermissionConfirmationModal((prev) => !prev)
            }
            userName={`${user.firstName} ${user.lastName}`}
            onConfirm={updateUserPermissions}
          />
        </>
      ) : (
        <Loader isFull />
      )}
    </>
  );
};
