import dompurify from "dompurify";
import React from "react";

import { EngagementAgreement } from "@arbolus-technologies/api";
import { CollapseComponent } from "@arbolus-technologies/ui/components";

import styles from "./AgreementsList.module.scss";

interface AgreementsListProps {
  agreements: EngagementAgreement;
}

export const AgreementsList: React.FC<AgreementsListProps> = ({
  agreements
}) => (
  <>
    <div className={styles.contentContainer}>
      <h4
        className={styles.title}
        dangerouslySetInnerHTML={{
          __html: dompurify
            .sanitize(agreements?.headerTitle)
            .replace(/href/g, "target='_blank' href")
        }}
      />

      <p
        className={styles.text}
        dangerouslySetInnerHTML={{
          __html: dompurify
            .sanitize(agreements?.headerText)
            .replace(/href/g, "target='_blank' href")
        }}
      />
    </div>
    {agreements?.agreementTerms.map((agreement, key) => (
      <CollapseComponent
        key={key}
        title={agreement.termTitle}
        text={agreement.termText}
        containerStyle={styles.accordionContainer}
        titleStyle={styles.accordionTitle}
        textStyle={styles.accordionText}
      />
    ))}
    <div className={styles.contentContainer}>
      <h4
        className={styles.title}
        dangerouslySetInnerHTML={{
          __html: dompurify
            .sanitize(agreements?.footerTitle)
            .replace(/href/g, "target='_blank' href")
        }}
      />
      <p
        className={styles.text}
        dangerouslySetInnerHTML={{
          __html: dompurify
            .sanitize(agreements?.footerText)
            .replace(/href/g, "target='_blank' href")
        }}
      />
    </div>
  </>
);
