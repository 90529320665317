import { Icon } from "arbolus-ui-components";
import i18next from "i18next";
import React from "react";

import { PipelineExpertHistoryItem } from "@arbolus-technologies/models/expert";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { displayUserName } from "@arbolus-technologies/utils";

import { PipelineLogCard } from "../PipelineExpertCard/PipelineLogCard/PipelineLogCard";

import styles from "./PipelineExpertHistory.module.scss";

interface PipelineExpertHistoryProps {
  pipelineExpertHistory: PipelineExpertHistoryItem;
}

export const PipelineExpertHistory: React.FC<PipelineExpertHistoryProps> = ({
  pipelineExpertHistory
}) => {
  const {
    initialStatus: pipelineInitialStatus,
    finalStatus: pipelineFinalStatus,
    changedByUser: { firstName, lastName, profileImageUrl },
    changedDate,
    comment
  } = pipelineExpertHistory;

  const initialStatus = i18next.t(
    `externalExpertStatus:${pipelineInitialStatus}`
  );
  const finalStatus = i18next.t(`externalExpertStatus:${pipelineFinalStatus}`);
  const fullName = displayUserName({ firstName, lastName });

  return (
    <div className={styles.historyContainer}>
      <div className={styles.historyHeader}>
        <div className={styles.title}>
          <p className={styles.status}>{initialStatus}</p>
          {pipelineFinalStatus && (
            <>
              <Icon
                name="chevron_right"
                fontSize="16px"
                color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
              />
              <p className={styles.status}>{finalStatus}</p>
            </>
          )}
        </div>
        <PipelineLogCard
          createdDate={changedDate}
          createdBy={fullName}
          createdByProfileImageUrl={profileImageUrl}
          classnames={styles.changedBy}
        />
      </div>
      {comment && <p className={styles.comment}>{comment}</p>}
    </div>
  );
};
