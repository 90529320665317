import { useFeature } from "flagged";
import React from "react";
import { useSelector } from "react-redux";

import { ProjectClientInfo } from "@arbolus-technologies/api";
import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { FEATURE_FLAGS } from "@arbolus-technologies/models/common";
import {
  CLIENT_DASHBOARD_ROUTE,
  EXTERNAL_SOURCING_ROUTE,
  PROJECT_DISCOVER_ROUTE
} from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  ClientDashboardButton,
  DiscoverButton,
  LoaderOrComponent,
  PipelineButton
} from "@arbolus-technologies/ui/components";
import { GrayBox } from "@arbolus-technologies/ui/layout";
import { isProdEnv } from "@arbolus-technologies/utils";

import { ComplianceButton } from "../../Components/ComplianceButton/ComplianceButton";

import styles from "./AdminActionsMenu.module.scss";

interface AdminActionsMenuProps {
  projectId: string;
  client: ProjectClientInfo;
  isLoading: boolean;
  expertsCount: number;
}

export const AdminActionsMenu: React.FC<AdminActionsMenuProps> = ({
  projectId,
  client,
  isLoading,
  expertsCount
}) => {
  const { trackClick } = useArbolusTracking();

  // TO BE DELETED AFTER THE PILOT
  const externalSourcingFF = useFeature(FEATURE_FLAGS.ExternalSourcing);
  const adminUserId = useSelector(CacheSelector.userProfile()).id;
  const isAdmin: boolean = useSelector(CacheSelector.isAdmin());

  const pilots = [
    /* cspell:disable */
    "24ccab18-9a03-4b83-8520-70c12e414010", // Georgina
    "39d818f7-6e85-44d5-94b4-4b7bee33324b", // Julia
    "160c669f-179d-4ac0-91eb-eb3d0699cf9c", // Elena
    "ad5bbcb8-c590-4a46-b2b6-84cd6cfd1643", // Nora
    "24ffaa88-c963-44d6-9b7f-a03d8d9638e3", // Arjun
    "1d84a917-e23b-460a-85b5-6536188c3cbb", // Jai Sharma
    "d64214ed-5e26-4c30-9ab4-3188e01e937e", // Jasmine
    "835ca997-aaa6-4d20-99a4-d2fcb9a87edf", // PROD SuperUser
    "e01c62e6-5cec-4760-a962-58a183a005a1", // WILL
    "25fd2423-5c87-4ac5-a10f-41b77803ae6b", // JOSH
    "bcdf8c95-21c0-43c0-9de2-ec9ab76fcaae" // NURIA
    /* cspell:enable */
  ];
  const isPilot = pilots.includes(adminUserId);

  const isProduction = isProdEnv();
  const isPilotActive =
    isAdmin && isPilot && isProduction && externalSourcingFF;
  const isDevOrDemo = isAdmin && !isProduction && externalSourcingFF;

  return (
    <GrayBox fullWidth>
      <LoaderOrComponent isLoading={isLoading}>
        <div className={styles.container}>
          <DiscoverButton
            itemCount={expertsCount}
            to={PROJECT_DISCOVER_ROUTE(projectId)}
            trackClick={() =>
              trackClick(MixPanelEventNames.ProjectDashboardFindExperts)
            }
          />
          {(isPilotActive || isDevOrDemo) && (
            <PipelineButton
              itemCount={0}
              to={EXTERNAL_SOURCING_ROUTE(projectId)}
            />
          )}
          <ComplianceButton projectId={projectId} />
          <ClientDashboardButton
            clientName={client.name}
            to={CLIENT_DASHBOARD_ROUTE(client.id)}
          />
        </div>
      </LoaderOrComponent>
    </GrayBox>
  );
};
