import { OutputSelector, createSelector } from "reselect";

import {
  ApiErrorResponse,
  CIQError,
  Canopy,
  CanopyPublic,
  CanopyQuestion
} from "@arbolus-technologies/api";
import {
  ANSWER_PREFERENCES_TYPE,
  ANSWER_TYPE,
  AnswersRange,
  CANOPY_STATUS,
  FormConstraints
} from "@arbolus-technologies/models/canopy";

import {
  CanopyBuilderAppState,
  CanopyBuilderReducerState
} from "../models/definitions";
import {
  answerPreferenceRangeNumbers,
  handleAnswerPreferenceType
} from "./helpers";

const canopyBuilderStateSelector = (
  state: CanopyBuilderAppState
): CanopyBuilderReducerState => state.canopyBuilder;

// Canopy
const canopySelector = (): OutputSelector<
  CanopyBuilderAppState,
  Canopy,
  (res: CanopyBuilderReducerState) => Canopy
> =>
  createSelector<CanopyBuilderAppState, CanopyBuilderReducerState, Canopy>(
    canopyBuilderStateSelector,
    (canopyReducerState) => canopyReducerState.canopy
  );

const canopyLoadingSelector = (): OutputSelector<
  CanopyBuilderAppState,
  boolean,
  (res: CanopyBuilderReducerState) => boolean
> =>
  createSelector<CanopyBuilderAppState, CanopyBuilderReducerState, boolean>(
    canopyBuilderStateSelector,
    (canopyReducerState) => canopyReducerState.isCanopyLoading
  );

// Canopy create loading
const canopySavingSelector = (): OutputSelector<
  CanopyBuilderAppState,
  boolean,
  (res: CanopyBuilderReducerState) => boolean
> =>
  createSelector<CanopyBuilderAppState, CanopyBuilderReducerState, boolean>(
    canopyBuilderStateSelector,
    (canopyReducerState) => canopyReducerState.isCanopySaving
  );

// Canopy get survey questions loading
const surveyListLoadingSelector = (): OutputSelector<
  CanopyBuilderAppState,
  boolean,
  (res: CanopyBuilderReducerState) => boolean
> =>
  createSelector<CanopyBuilderAppState, CanopyBuilderReducerState, boolean>(
    canopyBuilderStateSelector,
    (canopyReducerState) => canopyReducerState.loadingSurveyQuestions
  );

// Canopy questions list
const questionsSelector = (): OutputSelector<
  CanopyBuilderAppState,
  CanopyQuestion[],
  (res: CanopyBuilderReducerState) => CanopyQuestion[]
> =>
  createSelector<
    CanopyBuilderAppState,
    CanopyBuilderReducerState,
    CanopyQuestion[]
  >(
    canopyBuilderStateSelector,
    (canopyReducerState) => canopyReducerState.canopyQuestions
  );

// Canopy selected
const canopySelected = (): OutputSelector<
  CanopyBuilderAppState,
  Canopy | null,
  (res: CanopyBuilderReducerState) => Canopy | null
> =>
  createSelector<
    CanopyBuilderAppState,
    CanopyBuilderReducerState,
    Canopy | null
  >(
    canopyBuilderStateSelector,
    (canopyReducerState) => canopyReducerState.canopy
  );

// Question Active
const questionSelected = (): OutputSelector<
  CanopyBuilderAppState,
  CanopyQuestion | null,
  (res: CanopyBuilderReducerState) => CanopyQuestion | null
> =>
  createSelector<
    CanopyBuilderAppState,
    CanopyBuilderReducerState,
    CanopyQuestion | null
  >(canopyBuilderStateSelector, (canopyReducerState) => {
    const selectedQuestion = canopyReducerState.selectedQuestion;
    if (selectedQuestion) {
      if (selectedQuestion.type === ANSWER_TYPE.MULTIPLE_CHOICE) {
        const preferenceType: ANSWER_PREFERENCES_TYPE =
          handleAnswerPreferenceType(selectedQuestion);

        const rangeNumbers: AnswersRange = answerPreferenceRangeNumbers(
          selectedQuestion,
          preferenceType
        );

        return {
          ...selectedQuestion,
          preferenceType: preferenceType,
          exactNumber:
            selectedQuestion.answersRange?.maximum ||
            FormConstraints.MAX_ANSWER_CHOICES,
          answersRange: {
            minimum: rangeNumbers.minimum,
            maximum: rangeNumbers.maximum
          }
        };
      }
    }
    return selectedQuestion;
  });

// Question loader
const isQuestionLoading = (): OutputSelector<
  CanopyBuilderAppState,
  boolean,
  (res: CanopyBuilderReducerState) => boolean
> =>
  createSelector<CanopyBuilderAppState, CanopyBuilderReducerState, boolean>(
    canopyBuilderStateSelector,
    (canopyReducerState) => canopyReducerState.isQuestionLoading
  );

// Question added correctly
const isQuestionAddedCorrectly = (): OutputSelector<
  CanopyBuilderAppState,
  boolean,
  (res: CanopyBuilderReducerState) => boolean
> =>
  createSelector<CanopyBuilderAppState, CanopyBuilderReducerState, boolean>(
    canopyBuilderStateSelector,
    (canopyReducerState) => canopyReducerState.questionAddedCorrectly
  );

// Delete question
const isDeletingQuestion = (): OutputSelector<
  CanopyBuilderAppState,
  boolean,
  (res: CanopyBuilderReducerState) => boolean
> =>
  createSelector<CanopyBuilderAppState, CanopyBuilderReducerState, boolean>(
    canopyBuilderStateSelector,
    (canopyReducerState) => canopyReducerState.isDeletingQuestion
  );

// Canopy created or edited successfully
const isCanopySuccessful = (): OutputSelector<
  CanopyBuilderAppState,
  boolean,
  (res: CanopyBuilderReducerState) => boolean
> =>
  createSelector<CanopyBuilderAppState, CanopyBuilderReducerState, boolean>(
    canopyBuilderStateSelector,
    (canopyReducerState) => canopyReducerState.canopySuccess
  );

const isLoadingCreateEditQuestion = (): OutputSelector<
  CanopyBuilderAppState,
  boolean,
  (res: CanopyBuilderReducerState) => boolean
> =>
  createSelector<CanopyBuilderAppState, CanopyBuilderReducerState, boolean>(
    canopyBuilderStateSelector,
    (canopyReducerState) => canopyReducerState.loadingQuestion
  );

// Question added correctly
const questionErrorsSelector = (): OutputSelector<
  CanopyBuilderAppState,
  ApiErrorResponse<CIQError> | null,
  (res: CanopyBuilderReducerState) => ApiErrorResponse<CIQError> | null
> =>
  createSelector<
    CanopyBuilderAppState,
    CanopyBuilderReducerState,
    ApiErrorResponse<CIQError> | null
  >(
    canopyBuilderStateSelector,
    (canopyReducerState) => canopyReducerState.questionErrors
  );

// Canopy errors
const canopyErrorsSelector = (): OutputSelector<
  CanopyBuilderAppState,
  ApiErrorResponse<CIQError> | null,
  (res: CanopyBuilderReducerState) => ApiErrorResponse<CIQError> | null
> =>
  createSelector<
    CanopyBuilderAppState,
    CanopyBuilderReducerState,
    ApiErrorResponse<CIQError> | null
  >(
    canopyBuilderStateSelector,
    (canopyReducerState) => canopyReducerState.error
  );

// Public Canopy
const publicCanopySelector = (): OutputSelector<
  CanopyBuilderAppState,
  CanopyPublic | null,
  (res: CanopyBuilderReducerState) => CanopyPublic | null
> =>
  createSelector<
    CanopyBuilderAppState,
    CanopyBuilderReducerState,
    CanopyPublic | null
  >(
    canopyBuilderStateSelector,
    (canopyReducerState) => canopyReducerState.publicCanopy
  );

// Public Canopy loader
const isPublicCanopyLoading = (): OutputSelector<
  CanopyBuilderAppState,
  boolean,
  (res: CanopyBuilderReducerState) => boolean
> =>
  createSelector<CanopyBuilderAppState, CanopyBuilderReducerState, boolean>(
    canopyBuilderStateSelector,
    (canopyReducerState) => canopyReducerState.isPublicCanopyLoading
  );

//new order question number
const newOrderQuestion = (): OutputSelector<
  CanopyBuilderAppState,
  number,
  (res: CanopyBuilderReducerState) => number
> =>
  createSelector<CanopyBuilderAppState, CanopyBuilderReducerState, number>(
    canopyBuilderStateSelector,
    (canopyReducerState) => canopyReducerState.canopyQuestions.length
  );

const changeStatusLoading = (): OutputSelector<
  CanopyBuilderAppState,
  boolean,
  (res: CanopyBuilderReducerState) => boolean
> =>
  createSelector<CanopyBuilderAppState, CanopyBuilderReducerState, boolean>(
    canopyBuilderStateSelector,
    (canopyReducerState) => canopyReducerState.changeStatusLoading
  );

const canopyBuilderStatus = (
  status: CANOPY_STATUS
): OutputSelector<
  CanopyBuilderAppState,
  boolean,
  (res: CanopyBuilderReducerState) => boolean
> =>
  createSelector<CanopyBuilderAppState, CanopyBuilderReducerState, boolean>(
    canopyBuilderStateSelector,
    (canopyReducerState) => canopyReducerState.canopy.status === status
  );

export const CanopyBuilderSelector = {
  canopySelector,
  canopyLoadingSelector,
  canopyBuilderStateSelector,
  canopySavingSelector,
  surveyListLoadingSelector,
  questionsSelector,
  canopySelected,
  questionSelected,
  isQuestionLoading,
  isQuestionAddedCorrectly,
  isLoadingCreateEditQuestion,
  questionErrorsSelector,
  canopyErrorsSelector,
  isCanopySuccessful,
  isPublicCanopyLoading,
  publicCanopySelector,
  newOrderQuestion,
  changeStatusLoading,
  isDeletingQuestion,
  canopyBuilderStatus
};
