import React from "react";
import { useTranslation } from "react-i18next";
import Media from "react-media";

import InfiniteScroll from "../../../InfiniteScroll/InfiniteScroll";
import {
  APP_DEVICE_MEDIA_QUERIES,
  UI_CANOPIES_PAGE
} from "../../../constants/UiConstants";
import { RowItem } from "./RowItem/RowItem";

interface InfiniteScrollPickerListInterface {
  initialRequest: () => void;
  bottomReachedRequest?: () => void;
  handleOnClickItem?: (item: any) => void;
  items: { id: string; name: string }[];
  itemSelectedId?: string;
  isInternalPanel?: boolean;
}

/**
 * @deprecated use ant design instead
 */
export const InfiniteScrollPickerList: React.FC<
  InfiniteScrollPickerListInterface
> = ({
  initialRequest,
  bottomReachedRequest,
  handleOnClickItem,
  items,
  itemSelectedId = "",
  isInternalPanel = false
}) => {
  const { t } = useTranslation("infiniteScrollPickerList");
  return (
    <Media queries={APP_DEVICE_MEDIA_QUERIES}>
      {(matches): JSX.Element => (
        <InfiniteScroll
          onBottomReached={bottomReachedRequest ?? initialRequest}
          onInitialFetch={initialRequest}
          className="simplebar-light"
          bottomThreshold={10}
          elementHeight={UI_CANOPIES_PAGE.CANOPY_ROW_ELEMENT_HEIGHT(matches)}
          style={{
            maxHeight: UI_CANOPIES_PAGE.CONTAINER_HEIGHT(),
            height: UI_CANOPIES_PAGE.CONTAINER_HEIGHT(),
            overflowX: "hidden"
          }}
        >
          {items.length > 0 ? (
            items.map((item) => (
              <RowItem
                key={item.id}
                item={item}
                handleOnClickItem={() => {
                  if (handleOnClickItem) {
                    handleOnClickItem(item);
                  }
                }}
                itemSelectedId={itemSelectedId}
                isInternalPanel={isInternalPanel}
              />
            ))
          ) : (
            <h5>{t("noResultsFound")}</h5>
          )}
        </InfiniteScroll>
      )}
    </Media>
  );
};
