import React from "react";
import { TypeaheadModel, TypeaheadResult } from "react-bootstrap-typeahead";

import { ApiPaginatedRequest, MetaService } from "@arbolus-technologies/api";

import { SelectTypeahead } from "../SelectTypeahead/SelectTypeahead";

interface FullDisplayCity {
  name: string;
  id: string;
  stateCode: string;
}

type DisplayCity = Partial<FullDisplayCity>;

const renderer = ({
  name,
  stateCode
}: TypeaheadResult<DisplayCity>): JSX.Element => (
  <div>
    <div>
      <span>{name}</span>
      {stateCode && <span> ({stateCode})</span>}
    </div>
  </div>
);

const getUserLabelKey = ({ name }: DisplayCity): string => name ?? "";

interface CitySelectorProps<T extends TypeaheadModel & object> {
  name: string;
  value: T[];
  countryId: string;
  orderBy: string;
  promptText: string;
  searchText: string;
  paginationText: string;
  defaultValue?: T;
  placeholderText?: string;
  disabled?: boolean;
  displayResults?: number;
  onChange: (value: string) => void;
  onBlur: () => void;
  clientFilter?: (item: T, query: string) => boolean;
}

export const CitySelector = <T extends TypeaheadModel & object>({
  name,
  value,
  countryId,
  orderBy,
  promptText,
  searchText,
  paginationText,
  onChange,
  onBlur,
  displayResults,
  disabled = false,
  placeholderText = ""
}: CitySelectorProps<T>): React.ReactElement => {
  const getItems = (pagination: ApiPaginatedRequest) =>
    MetaService.getCities({
      countryId,
      ...pagination
    });

  const handleOnChange = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (...event: any[]) => void,
    selected: T[]
  ) => {
    onChange(selected[0]);
  };

  return (
    <SelectTypeahead
      selected={value}
      name={name}
      onChange={(items) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        handleOnChange(onChange, items as any);
      }}
      onBlur={onBlur}
      orderBy={orderBy}
      promptText={promptText}
      searchText={searchText}
      paginationText={paginationText}
      placeholderText={placeholderText}
      displayResults={displayResults}
      disabled={disabled}
      getItems={getItems}
      renderer={renderer}
      getLabelKey={getUserLabelKey}
    />
  );
};
