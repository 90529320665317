import i18next from "i18next";

export const formatDuration = (value: number): string => {
  if (!value) {
    return "";
  }

  const hours = Math.floor(value / 60);
  const minutes = value % 60;
  if (minutes === 0) {
    return i18next.t("common:duration:hours", { count: hours });
  }

  if (hours < 1) {
    return i18next.t("common:duration:minutes", { count: minutes });
  }

  return i18next.t("common:duration:hoursAndMinutes", { hours, minutes });
};
