import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import {
  ChatListResponse,
  ChatListResponseItem,
  ListResponse,
  Project
} from "@arbolus-technologies/api";
import {
  AdminChatMode,
  AngleChat,
  ChatData,
  ChatUserRole,
  DatabaseChatType,
  UnreadMessagesCountItem
} from "@arbolus-technologies/models/project";
import { PROJECT_CHAT_ROUTE } from "@arbolus-technologies/routes";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";

import { isMobileChat } from "../../Components/Chat/utils";
import {
  buildChatFromAngle,
  convertChat,
  extractChatsByAngle,
  extractSupportChatsByAngle,
  mapChatsById
} from "../../Contexts/ChatContext/ChatContextUtils";
import { AngleWithChatById } from "../../Models/chat";
import { useChatRole } from "./useChatRole";
import { useGetChatList } from "./useGetChatList";

export function useInitializeChat(
  projectId: string,
  changeChat: (chat: ChatData | undefined) => void,
  setActiveAdminMode: (mode: AdminChatMode) => void,
  initializeUnreadCount: (
    chatListResponse: ChatListResponseItem[],
    unreadCountResponse: UnreadMessagesCountItem[]
  ) => void
): {
  projectData: Project | null;
  isLoading: boolean;
  isChatEnabled: boolean;
  chatsByAngle: AngleWithChatById;
  projectChat: ChatData | undefined;
  expertProjectChat: ChatData | undefined;
  expertToArbolusChat: ChatData | undefined;
  expertToArbolusChats: Record<string, AngleChat>;
} {
  const history = useHistory();
  const { chatId } = useParams<{ chatId: string }>();

  const { isAdmin, isExpert, chatSenderRole } = useChatRole();

  // Project is loaded by ProjectContainer (ciq-client and ciq-admin)
  const projectData = useSelector(ProjectNxSelector.projectData());
  const isChatEnabled = projectData?.enableWorkspaces ?? false;

  const [chatsByAngle, setChatsByAngle] = useState<AngleWithChatById>({});
  const [projectChat, setProjectChat] = useState<ChatData>();
  // Client, admin and expert in one conversation (for ExpertChatList)
  const [expertProjectChat, setExpertProjectChat] = useState<ChatData>();
  // Support chat between expert and admin
  const [expertToArbolusChat, setExpertToArbolusChat] = useState<ChatData>();
  // Expert support chats divided by angle (from admin perspective)
  const [expertToArbolusChats, setExpertToArbolusChats] = useState<
    Record<string, AngleChat>
  >({});

  const dashboardFilter = useSelector(ProjectNxSelector.dashboardAngleFilter());
  const selectedAngle = dashboardFilter?.angleId;

  const handleGetChatListSuccess = (
    chatList: ChatListResponse,
    unreadMessagesCountList: ListResponse<UnreadMessagesCountItem>
  ) => {
    const chatListItems = chatList.items;

    initializeUnreadCount(chatListItems, unreadMessagesCountList.items);

    if (chatListItems.length === 0) return;

    const extractedChatsByAngle = extractChatsByAngle(chatListItems);
    setChatsByAngle(extractedChatsByAngle);

    const arbolusTeamChats = chatListItems.filter(
      (item) => item.chatType === DatabaseChatType.ExpertSupport
    );
    const arbolusTeamChat = arbolusTeamChats[0];

    let arbolusTeamChatConverted;
    if (isExpert) {
      // Expert to Arbolus chat (single conversation)
      if (arbolusTeamChat) {
        arbolusTeamChatConverted = convertChat(arbolusTeamChat);
        setExpertToArbolusChat(arbolusTeamChatConverted);
      }

      // Expert to project chat
      const expertChatWithClient = chatListItems.find(
        (chat) => chat.chatType === DatabaseChatType.Workspace
      );
      if (expertChatWithClient) {
        setExpertProjectChat(convertChat(expertChatWithClient));
      }
    }

    let projectChatFromResponse;
    let projectChatConverted;
    let expertSupportChats: Record<string, AngleChat> = {};
    if (isAdmin) {
      // Expert to Arbolus chats (multiple conversations from admin)
      expertSupportChats = extractSupportChatsByAngle(arbolusTeamChats);
      setExpertToArbolusChats(expertSupportChats);

      // Project chat
      projectChatFromResponse = chatListItems.find(
        (chat) => chat.chatType === DatabaseChatType.ClientSupport
      );
      if (projectChatFromResponse) {
        projectChatConverted = convertChat(projectChatFromResponse);
        setProjectChat(projectChatConverted);
      }
    }

    // Client
    if (!isAdmin && !isExpert) {
      // Project chat
      projectChatFromResponse = chatListItems.find(
        (chat) => chat.chatType === DatabaseChatType.ClientSupport
      );
      if (projectChatFromResponse) {
        projectChatConverted = convertChat(projectChatFromResponse);
        setProjectChat(projectChatConverted);
      }
    }

    // Set active chat

    if (chatId) {
      const chatIdToChatData = mapChatsById(chatListItems);
      const isSupportChat =
        chatIdToChatData[chatId]?.chatType === DatabaseChatType.ExpertSupport;
      if (isSupportChat) {
        setActiveAdminMode(AdminChatMode.ExpertSupport);
      }
      changeChat(chatIdToChatData[chatId]);
      return;
    }

    // Default chat

    const hasSupportChats = Object.keys(expertToArbolusChats ?? {}).length > 0;
    if (isAdmin && (!isChatEnabled || hasSupportChats)) {
      setActiveAdminMode(AdminChatMode.ExpertSupport);
      if (!chatId && Object.keys(expertSupportChats).length > 0) {
        const angle = selectedAngle
          ? expertSupportChats[selectedAngle]
          : expertSupportChats[Object.keys(expertSupportChats)[0]];
        const firstChat = angle.chats[0];
        changeChat(firstChat);
        history.replace(PROJECT_CHAT_ROUTE(projectId, firstChat.id));
        return;
      }
    }

    const defaultChat =
      chatSenderRole === ChatUserRole.Expert
        ? arbolusTeamChatConverted
        : projectChatConverted;

    const changeChatToDefault = () => {
      changeChat(defaultChat);
      history.replace(PROJECT_CHAT_ROUTE(projectId, defaultChat!.id));
    };

    if (selectedAngle) {
      const angle = extractedChatsByAngle[selectedAngle];
      if (angle) {
        changeChat(buildChatFromAngle(angle));
      } else {
        changeChatToDefault();
      }
    } else if (!isMobileChat()) {
      changeChatToDefault();
    }
  };

  const { isLoading } = useGetChatList(projectId, handleGetChatListSuccess);

  return {
    projectData,
    isLoading,
    isChatEnabled,
    chatsByAngle,
    projectChat,
    expertProjectChat,
    expertToArbolusChat,
    expertToArbolusChats
  };
}
