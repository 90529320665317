import { Button } from "arbolus-ui-components";
import clsx from "clsx";
import dompurify from "dompurify";
import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import styles from "./ExpertTrainingModal.module.scss";

interface ExpertTrainingModalProps {
  title: string;
  text: string;
  isOpen: boolean;
  isLoading: boolean;
  onRead: () => void;
  onDownload: () => void;
}

export const ExpertTrainingModal: React.FC<ExpertTrainingModalProps> = ({
  title,
  text,
  isOpen,
  isLoading,
  onRead,
  onDownload
}) => {
  const { t } = useTranslation("expertTrainingModal");

  return (
    <Modal isOpen={isOpen} className={clsx("modal-alert", styles.container)}>
      <ModalHeader className={styles.title}>{title}</ModalHeader>
      <ModalBody className={styles.text}>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(text)
          }}
        />
      </ModalBody>
      <ModalFooter className={styles.footer}>
        <div className={styles.buttonsContainer}>
          <Button
            text={t("download")}
            endIcon="download"
            type="secondary"
            onClick={onDownload}
            disabled={isLoading}
          />
          <Button text={t("ok")} onClick={onRead} disabled={isLoading} />
        </div>
      </ModalFooter>
    </Modal>
  );
};
