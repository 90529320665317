import React from "react";
import { useTranslation } from "react-i18next";

import { eyes } from "@arbolus-technologies/theme";

import styles from "./NoResultsPlaceholder.module.scss";

interface NoResultsPlaceholderProps {
  noResultsTitle: string;
  noResultsText?: string;
}

export const NoResultsPlaceholder: React.FC<NoResultsPlaceholderProps> = ({
  noResultsTitle,
  noResultsText
}) => {
  const { t } = useTranslation("noResultsPlaceholder");

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <img
          src={eyes}
          alt={t("noResultsIconAltText")}
          className={styles.image}
        />
        <h1 className={styles.title}>{noResultsTitle}</h1>
      </div>
      {noResultsText && <p className={styles.text}>{noResultsText}</p>}
    </div>
  );
};
