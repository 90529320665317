/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";

import { CustomSwitch } from "../CustomSwitch/CustomSwitch";

import styles from "./CustomSwitchController.module.scss";

interface CustomSwitchControllerProps {
  control: Control<any>;
  name: string;
  errors: FieldErrors<any>;
  disabled?: boolean;
  label?: string;
  subtitle?: string;
}

export const CustomSwitchController: React.FC<CustomSwitchControllerProps> = ({
  control,
  name,
  errors,
  label,
  subtitle,
  disabled
}): JSX.Element => {
  const isError = !!(Object.keys(errors).length > 0 && errors[name]);
  return (
    <div className={styles.container}>
      {label && (
        <div className={styles.textContainer}>
          <span className={styles.label}>{label}</span>
          {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
        </div>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <CustomSwitch
              name={name}
              checked={value}
              disabled={disabled}
              onChange={onChange}
            />
            <div className={styles.error} data-testid="error-yup">
              {isError && errors[name]?.message}
            </div>
          </>
        )}
      />
    </div>
  );
};
