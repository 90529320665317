import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React, { forwardRef } from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { ConditionalLink } from "@arbolus-technologies/ui/components";

import { ISubItems } from "../../MenuItemsConfig";

import styles from "./SubMenuButton.module.scss";

interface SubMenuButtonProps {
  item: ISubItems;
  isActive?: boolean;
  handleLogout: () => void;
  handleButtonClick: () => void;
}

const SubMenuButton = forwardRef<HTMLDivElement, SubMenuButtonProps>(
  ({ item, isActive, handleLogout, handleButtonClick }, ref) => (
    <ConditionalLink to={item.route}>
      <div
        className={clsx(
          isActive ? styles.subMenuItemActive : styles.subMenuItem
        )}
        key={item.name}
        ref={ref}
        onClick={item.name === "Logout" ? handleLogout : handleButtonClick}
        style={{
          background: isActive ? ARBOLUS_COLORS.bColorBaseWhite : "transparent"
        }}
      >
        <Icon
          name={item.icon}
          fontSize="24px"
          color={
            isActive
              ? ARBOLUS_COLORS.bColorBasePurple
              : ARBOLUS_COLORS.bColorGrayIcon
          }
        />
        <span className={isActive ? styles.active : styles.default}>
          {item.name}
        </span>
      </div>
    </ConditionalLink>
  )
);
SubMenuButton.displayName = "SubMenuButton";
export default SubMenuButton;
