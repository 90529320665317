import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { SlidePanel } from "../../../Panels/SlidePanel/SlidePanel";
import { IncidentsSlidePanel } from "../IncidentsSlidePanel/IncidentsSlidePanel";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { Menu } from "antd";
import clsx from "clsx";
import styles from "./ReportIncidentButtonForAnt.module.scss";

interface ReportIncidentButtonForAntProps {
  onSupportClick?: () => void;
  children?: React.ReactNode;
  isMobile?: boolean;
}
export const ReportIncidentButtonForAnt: React.FC<
  ReportIncidentButtonForAntProps
> = ({ onSupportClick, children, isMobile }) => {
  const dispatch = useDispatch();
  const handleClickReportButton = () => {
    dispatch(PanelStoreActions.openPanel(PanelId.ReportIncident));
  };
  const { t } = useTranslation("incidentsInfo");

  return (
    <>
      <SlidePanel
        title={t("panelTitle")}
        panelId={PanelId.ReportIncident}
        closeButtonDirection="right"
        width={SIDE_PANEL_SIZE._720}
      >
        <IncidentsSlidePanel />
      </SlidePanel>
      <Menu.Item
        className={clsx(isMobile ? styles.mobileMenuItem : styles.menuItem)}
        icon={<AntDIcon name={"help"} fontSize="16px" />}
        title={"support"}
        onClick={() => {
          handleClickReportButton();
          onSupportClick && onSupportClick();
        }}
      >
        {children}
      </Menu.Item>
    </>
  );
};
