import { Button } from "arbolus-ui-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import {
  CIQError,
  CanopyService,
  DefaultToasterService,
  ErrorResponse,
  ExpertDetail,
  ExpertService,
  ToasterService
} from "@arbolus-technologies/api";
import { CanopyParamUrlIndividualResponse } from "@arbolus-technologies/models/canopy";
import {
  Answer,
  isMultiChoiceAnswer,
  isVideoAnswer
} from "@arbolus-technologies/models/canopy-panels";
import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { CanopyV2Selector } from "@arbolus-technologies/stores/canopy-V2";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import {
  DNCTag,
  Loader,
  UserPortrait,
  VideoPlayer
} from "@arbolus-technologies/ui/components";

import { MixPanelEventNames } from "../../Mixpanel/enums";
import { useArbolusTracking } from "../../Mixpanel/useArbolusTracking";
import { ResponseDate } from "../BookmarksList/BookmarkAnswer/ResponseDate";
import { IndividualMultiChoice } from "./IndividualMultiChoiceResponse";
import { IndividualTranscript } from "./IndividualTranscriptResponse";

import styles from "./IndividualResponse.module.scss";

interface IndividualResponseProps {
  setExpertId: (expertId: string) => void;
  expertId: string;
  answerId: string;
  expertService?: typeof ExpertService;
  canopyService?: typeof CanopyService;
  notificationService?: ToasterService;
}

export const IndividualResponse: React.FC<IndividualResponseProps> = ({
  setExpertId,
  expertId,
  answerId,
  expertService = ExpertService,
  canopyService = CanopyService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("individualResponse");
  const dispatch = useDispatch();
  const { trackClick } = useArbolusTracking();
  const { canopyId } = useParams<CanopyParamUrlIndividualResponse>();

  const [expertProfile, setExpertProfile] = useState<ExpertDetail | null>(null);
  const [expertAnswer, setExpertAnswer] = useState<Answer | null>(null);
  const isVideo = isVideoAnswer(expertAnswer);
  const isMultiChoice = isMultiChoiceAnswer(expertAnswer);

  const canopyProjectId = useSelector(CanopyV2Selector.canopyProjectId());
  const isAdmin = useSelector(CacheSelector.isAdmin());

  const getExpertData = () => {
    canopyService.getCanopyExpertAnswer(answerId).subscribe(
      (expertAnswer) => {
        setExpertAnswer(expertAnswer);
        setExpertId(expertAnswer.expert.expertId);

        if (expertAnswer) {
          expertService
            .getExpertById({ expertId: expertAnswer.expert.expertId })
            .subscribe(
              (expertProfile) => {
                setExpertProfile(expertProfile);
              },
              (error: ErrorResponse<CIQError>) => {
                notificationService.showApiErrors(error);
              }
            );
        }
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
      }
    );
  };

  useEffect(() => {
    getExpertData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEngageWithMeButtonClick = () => {
    if (expertProfile) {
      trackClick(MixPanelEventNames.CanopySummaryQuoteLinkFollowup, {
        canopyId,
        expertId
      });
      dispatch(
        PanelStoreActions.openPanel(PanelId.ExpertEngageWithMe, {
          expertId,
          ...expertProfile.user
        })
      );
    }
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!expertAnswer && !expertProfile && <Loader isFull />}
      {expertAnswer && expertProfile && (
        <div className={styles.container}>
          {isVideo && <VideoPlayer src={expertAnswer.downloadUrl} size={224} />}
          <div className={styles.textContainer}>
            <h1 className={styles.title}>{expertAnswer.question.text}</h1>
            <h2 className={styles.subtitle}>{expertAnswer.question.details}</h2>
            {isVideo && <IndividualTranscript videoAnswer={expertAnswer} />}
            {isMultiChoice && (
              <IndividualMultiChoice multiChoiceAnswer={expertAnswer} />
            )}
            <div className={styles.expertContainer}>
              <div
                className={styles.userPortraitContainer}
                onClick={() =>
                  dispatch(
                    PanelStoreActions.openPanel(PanelId.ExpertProfile, {
                      expertId,
                      projectId: canopyProjectId,
                      canopyId
                    })
                  )
                }
              >
                <UserPortrait
                  user={expertProfile.user}
                  icon={
                    <DNCTag
                      dncStatus={expertAnswer.expert.doNotContactStatus}
                      isAdmin={isAdmin}
                    />
                  }
                />
              </div>
              {!isAdmin &&
                expertAnswer.expert.doNotContactStatus !==
                  DO_NOT_CONTACT_STATUS.DNC && (
                  <Button
                    text={t("askMore")}
                    type="primary"
                    onClick={handleEngageWithMeButtonClick}
                  />
                )}
            </div>
            <ResponseDate responseDate={new Date(expertAnswer.created)} />
          </div>
        </div>
      )}
    </>
  );
};
