import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "arbolus-ui-components";
import moment from "moment";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  CIQError,
  DefaultToasterService,
  Endorsement,
  ErrorResponse,
  ExpertService,
  ToasterService
} from "@arbolus-technologies/api";
import {
  AvatarWithTitle,
  BoxWithChildren,
  InfoCookieBoxWithChildren,
  InputController
} from "@arbolus-technologies/ui/components";
import { APP_DATE_FORMAT } from "@arbolus-technologies/utils";

import { DataToSend } from "../../../pages/welcome/newEndorsement/helpers";
import { EndorsementFormSchema } from "./EndorsementFormSchema";
import { EndorsementForm } from "./types";

import styles from "./EndorsementsForExpert.module.scss";

interface EndorsementsForExpertProps {
  endorsements: Endorsement[];
  notificationService?: ToasterService;
  expertId: string;
}

export const EndorsementsForExpert: React.FC<EndorsementsForExpertProps> = ({
  endorsements,
  notificationService = DefaultToasterService,
  expertId
}) => {
  const { t } = useTranslation("expertEndorsement");
  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValidating }
  } = useForm<EndorsementForm>({
    resolver: yupResolver(EndorsementFormSchema),
    mode: "onSubmit",
    defaultValues: {
      endorsementName: "",
      endorsementEmail: ""
    }
  });

  const onSubmit: SubmitHandler<EndorsementForm> = (data: EndorsementForm) => {
    setIsLoading(true);
    const dataToSend: DataToSend[] = [
      {
        name: data.endorsementName,
        email: data.endorsementEmail
      }
    ];
    ExpertService.requestEndorsement(dataToSend, expertId || "").subscribe(
      () => {
        notificationService.showSuccess(t("register:reinviteSuccess"));
        setIsLoading(false);
        reset();
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showError(error.message);
        setIsLoading(false);
      }
    );
  };

  return (
    <div className={styles.container}>
      <BoxWithChildren classnames={styles.boxWithChildren}>
        <InfoCookieBoxWithChildren cookie="expert_endorsement" withPadding>
          <div className={styles.containerCookieBox}>
            <span className={styles.title}>{t("howDoWork")}</span>
            <span className={styles.subtitle}>{t("ourClients")}</span>
            <span className={styles.text}>{t("toGetEndorsement")}</span>
          </div>
        </InfoCookieBoxWithChildren>
      </BoxWithChildren>
      <BoxWithChildren classnames={styles.boxWithChildren}>
        <div className={styles.formContainer}>
          <div className={styles.titleForm}>{t("requestEndorsement")}</div>
          <div className={styles.inputsContainer}>
            <div className={styles.inputContainer}>
              <span>{t("nameAndSurname")}</span>
              <InputController
                name="endorsementName"
                control={control}
                error={errors.endorsementName}
                placeholder="John Doe"
              />
            </div>
            <div className={styles.inputContainer}>
              <span>{t("email")}</span>
              <InputController
                name="endorsementEmail"
                control={control}
                error={errors.endorsementEmail}
                placeholder="john.doe@email.com"
              />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <Button
              text="Send request"
              onClick={handleSubmit(onSubmit)}
              disabled={isValidating || isLoading}
            />
          </div>
        </div>
      </BoxWithChildren>
      {endorsements.length > 0 && (
        <BoxWithChildren classnames={styles.boxWithChildren}>
          <div className={styles.formContainer}>
            <div className={styles.titleForm}>{t("endorsementsReceived")}</div>
            <div className={styles.endorsementsContainer}>
              {endorsements.map((e) => {
                const endorsementFullName = `${e.endorser.firstName} ${
                  e.endorser.lastName ?? ""
                }`;
                return (
                  <div
                    className={styles.endorsementContainer}
                    key={e.endorserId}
                  >
                    <div className={styles.avatarContainer}>
                      <AvatarWithTitle
                        title={endorsementFullName}
                        username={endorsementFullName}
                        subtitle={e.endorser.title}
                        imageUrl={e.endorser.profileImageUrl}
                      />
                    </div>
                    <div className={styles.quote}>{`"${e.quote}"`}</div>
                    <div className={styles.date}>
                      {moment(e.created).format(APP_DATE_FORMAT)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </BoxWithChildren>
      )}
    </div>
  );
};
