import React, { useEffect } from "react";
import { useLocation } from "react-router";

interface AutoLoginProps {
  login: (user: string, access: string) => void;
}

const AutoLogin: React.FC<AutoLoginProps> = ({ login }) => {
  const location = useLocation();

  useEffect(() => {
    const userMatch = /user=(.+)&/g.exec(location.search);
    const accessMatch = /access=(.+)/g.exec(location.search);
    if (userMatch?.length === 2 && accessMatch?.length === 2) {
      login(userMatch[1], accessMatch[1]);
      return;
    }

    // cspell:disable-next-line
    const userSecondMatch = /%3Fuser%3D(.+)%26/g.exec(location.search);
    const accessSecondMatch = /%26access%3D(.+)/g.exec(location.search);
    if (userSecondMatch?.length === 2 && accessSecondMatch?.length === 2) {
      const user = decodeURIComponent(userSecondMatch[1]);
      const access = decodeURIComponent(accessSecondMatch[1]);
      login(user, access);
    }
  }, [location, login]);

  return <></>;
};

export default AutoLogin;
