import React from "react";

import { ExportTransactionsButton } from "./ExportTransactions/ExportTransactionsButton";
import { ProjectSpendTotalAmount } from "./ProjectSpendTotalAmount/ProjectSpendTotalAmount";

interface ProjectSpendActionsProps {
  projectId: string;
}

export const ProjectSpendActions: React.FC<ProjectSpendActionsProps> = ({
  projectId
}) => (
  <div className="flex-row-8">
    <ProjectSpendTotalAmount projectId={projectId} />
    <ExportTransactionsButton projectId={projectId} />
  </div>
);
