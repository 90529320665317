import i18next from "i18next";
import React from "react";

import styles from "./TextArea.module.scss";

interface TextAreaProps {
  text: string | null;
}

// This component is a box to display text.
// If the intended action is to handle a text input, use the InputController component instead.

export const TextArea: React.FC<TextAreaProps> = ({ text }) => {
  const textNotAvailable = i18next.t("common:textArea:textNotAvailable");

  return (
    <textarea
      className={styles.textArea}
      placeholder={i18next.t("common:textArea:placeholder")}
      value={text || textNotAvailable}
      disabled
    />
  );
};
