import { useLocation } from "react-router";

//We should use it in admin side from Auth routes and below
export const useCurrentPath = (isAdmin = false): string => {
  const location = useLocation();

  const BASE_URL = isAdmin
    ? process.env.NX_PUBLIC_ADMIN_BASE_URL
    : process.env.NX_PUBLIC_BASE_URL;

  return `${BASE_URL}${location?.pathname}`;
};
