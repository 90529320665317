import React, { useLayoutEffect, useRef, useState } from "react";

import { ResponseAllExpertsTable } from "@arbolus-technologies/api";
import { ArbolusTooltip } from "@arbolus-technologies/ui/components";

import styles from "./EditableTagline.module.scss";

interface EditableTaglineProps {
  row: ResponseAllExpertsTable;
}

export const EditableTagline: React.FC<EditableTaglineProps> = ({ row }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (ref.current && ref.current.clientHeight < ref.current.scrollHeight) {
      // show tooltip when text is truncated
      setShowTooltip(true);
    }
  }, [ref]);

  return (
    <ArbolusTooltip
      content={(row.tagline || row.expert.title) ?? ""}
      disabled={!showTooltip}
    >
      <div ref={ref} className={styles.container}>
        {row.tagline || row.expert.title}
      </div>
    </ArbolusTooltip>
  );
};
