import clsx from "clsx";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  PROJECT_CALENDAR_DATE_HEADER_FORMAT,
  formatDate
} from "@arbolus-technologies/utils";

import { BaseEvent } from "../../../../../models/event";
import { UtilsService } from "../../../../../services";

interface DayHeaderProps {
  isMoreEventForDay: boolean;
  isNextEventSameMonth: boolean;
  event: BaseEvent;
  isToday: boolean;
  isTomorrow: boolean;
}

const DayHeader: React.FC<DayHeaderProps> = ({
  isMoreEventForDay,
  isToday,
  isNextEventSameMonth,
  isTomorrow,
  event: { startTime }
}) => {
  const { t } = useTranslation("dayHeader");

  const startMoment = moment(UtilsService.convertUTCToActiveZone(startTime));

  const formattedDate = formatDate(
    startMoment.toDate(),
    PROJECT_CALENDAR_DATE_HEADER_FORMAT
  );

  return (
    <div
      className={clsx("day-header", {
        "active-border": !isMoreEventForDay && isNextEventSameMonth
      })}
    >
      <div className="left-container">
        <h4>{formattedDate.toUpperCase()}</h4>
      </div>
      <div className="middle-container">
        {isToday && (
          <span className="date-tag today" id="todayElement">
            {t("today")}
          </span>
        )}
        {isTomorrow && <span className="date-tag tomorrow">Tomorrow</span>}
        {!isMoreEventForDay && (
          <span className="date-tag nothing-planned">
            {t("nothingPlanned")}
          </span>
        )}
      </div>
    </div>
  );
};

export default DayHeader;
