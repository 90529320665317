import { Document, Image, Page, StyleSheet, Text } from "@react-pdf/renderer";
import React from "react";

import {
  CanopyQuestionPDF,
  CanopyQuestionsPDF,
  QuestionType
} from "@arbolus-technologies/api";

import { MultiChoicePDFView } from "./components/MultiChoicePDFView";
import { NpsValuePDFView } from "./components/NpsValuePDFView";
import { ShortTextPDFView } from "./components/ShortTextPDFView";
import { ValuePDFView } from "./components/ValuePDFView";
import { VideoPDFView } from "./components/VideoPDFView";
import { canopyTitlePDF } from "./components/styles";

import arbLogo from "../../../assets/images/arbLogo.png";

interface PDFExportCanopyAnswersProps {
  data: CanopyQuestionsPDF;
  canopyTitle: string;
}

export const PDFExportCanopyAnswers: React.FC<PDFExportCanopyAnswersProps> = ({
  data,
  canopyTitle
}) => {
  const styles = StyleSheet.create({
    page: { backgroundColor: "white", padding: "40px" },
    image: { width: "100px", marginBottom: "40px", padding: "2px" },
    canopyTitle: canopyTitlePDF
  });

  const typeToComponent = {
    [QuestionType.MultiChoice]: (data: CanopyQuestionPDF) => (
      <MultiChoicePDFView data={data} />
    ),
    [QuestionType.Video]: (data: CanopyQuestionPDF) => (
      <VideoPDFView data={data} />
    ),
    [QuestionType.Value]: (data: CanopyQuestionPDF) => (
      <ValuePDFView data={data} />
    ),
    [QuestionType.ShortText]: (data: CanopyQuestionPDF) => (
      <ShortTextPDFView data={data} />
    ),
    [QuestionType.Nps]: (data: CanopyQuestionPDF) => (
      <NpsValuePDFView data={data} />
    ),

    default: <div />
  };

  const renderer = () =>
    data?.map((e) => {
      const component = typeToComponent[e.type] || typeToComponent.default;
      return <React.Fragment key={e.sortOrder}>{component(e)}</React.Fragment>;
    });

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <Image src={arbLogo} style={styles.image} />
        <Text style={styles.canopyTitle}>{canopyTitle}</Text>
        {renderer()}
      </Page>
    </Document>
  );
};
