import { ContentBlock } from "draft-js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { MonologueContentBlockDataKey } from "@arbolus-technologies/models/common";

import { TranscriptMode } from "../../../../../contexts/transcript/TranscriptContext";
import useTranscriptContext from "../../../../../contexts/transcript/UseTranscriptContext";

interface MonologueWrapperProps {
  block: ContentBlock;
  children: React.ReactNode;
}

const MonologueWrapper: React.FC<MonologueWrapperProps> = ({
  children,
  block
}): JSX.Element => {
  const { t } = useTranslation("transcript");
  const { mode, toggleMonologueText, monologues } = useTranscriptContext();

  const [isFullMode, setFullMode] = useState(false);
  const isHighlightMode = mode === TranscriptMode.HIGHLIGHTS;
  const truncated = isHighlightMode && !isFullMode;

  const monologueId = block.getKey();
  const blockData: Immutable.Map<
    MonologueContentBlockDataKey,
    string | number
  > = block.getData();

  const actualLength = monologues.get(monologueId)!.text.length;
  const trimStart = blockData.get("trimStart") as number;
  const trimEnd = blockData.get("trimEnd") as number;
  const isStartTrimmed = trimStart > 0;
  const isEndTrimmed = trimEnd < actualLength;
  const isTotallyHighlights = !isStartTrimmed && !isEndTrimmed;

  useEffect(() => {
    if (mode === TranscriptMode.HIGHLIGHTS) setFullMode(false);
  }, [mode]);

  const handleViewToggle = (event: React.MouseEvent): void => {
    event.stopPropagation();

    // Update transcript context
    toggleMonologueText(monologueId, !isFullMode);

    setFullMode(!isFullMode);
  };

  return (
    <>
      {truncated && isStartTrimmed && (
        <span className="truncate-quotes">...</span>
      )}
      {children}
      {truncated && isEndTrimmed && (
        <span className="truncate-quotes">...</span>
      )}
      {!isTotallyHighlights && isHighlightMode && (
        <span
          className="view-more"
          onClick={handleViewToggle}
          contentEditable={false}
        >
          {t(isFullMode ? "viewHighlightText" : "viewFullText")}
        </span>
      )}
    </>
  );
};

export default MonologueWrapper;
