import dompurify from "dompurify";
import React from "react";

import { IChatMessage } from "@arbolus-technologies/api";

import { EventMessageContent } from "./EventMessageContent/EventMessageContent";
import { FileMessageContent } from "./FileMessageContent/FileMessageContent";

import styles from "./MessageContent.module.scss";

interface MessageContentProps {
  message: IChatMessage;
  isDncExpertChat: boolean;
}

export const MessageContent: React.FC<MessageContentProps> = ({
  message,
  isDncExpertChat
}) => {
  const { sanitize } = dompurify;

  return (
    <div className={styles.wrapper}>
      {message.event && (
        <EventMessageContent
          event={message.event}
          isDncExpertChat={isDncExpertChat}
        />
      )}
      {message.file && (
        <FileMessageContent
          file={message.file}
          isDncExpertChat={isDncExpertChat}
        />
      )}
      {message.message && (
        <div
          className={styles.message}
          dangerouslySetInnerHTML={{
            __html: sanitize(message.message, { ADD_ATTR: ["target"] })
          }}
        />
      )}
    </div>
  );
};
