import { History, createBrowserHistory } from "history";

import {
  AdminService,
  AnglesService,
  BookmarkService,
  CanopyService,
  ChatService,
  DefaultReferralsService,
  DocumentService,
  MetaService,
  ProjectExpertsService,
  ProjectService,
  ToasterService
} from "@arbolus-technologies/api";
import { PROJECT_DISCOVER_ROUTE } from "@arbolus-technologies/routes";
import { BookmarkCollectionsDependencies } from "@arbolus-technologies/stores/bookmark-collections";
import { BookmarksDependencies } from "@arbolus-technologies/stores/bookmarks";
import { CacheDependencies } from "@arbolus-technologies/stores/cache";
import {
  CanopyBuilderStoreDependencies,
  ICanopyBuilderRouting
} from "@arbolus-technologies/stores/canopy-builder";
import { ICanopyClientRouting } from "@arbolus-technologies/stores/canopy-client";
import {
  CanopyExpertStoreDependencies,
  ICanopyExpertRouting
} from "@arbolus-technologies/stores/canopy-expert";
import {
  IProjectRouting,
  ProjectNxStoreDependencies
} from "@arbolus-technologies/stores/project";
import { ProjectAnglesStoreDependencies } from "@arbolus-technologies/stores/project-angles-store";
import { ProjectCanopyStoreDependencies } from "@arbolus-technologies/stores/project-canopy-store";
import { ProjectExpertsStoreDependencies } from "@arbolus-technologies/stores/project-experts-store";

import { BASE_URL } from "../constants/api";
import {
  DRAFT_PROJECT_ROUTE,
  PROJECT_MAIN_PAGE_ROUTE
} from "../constants/navigation/authRoutes";
import {
  CANOPY_DETAILS_PAGE_ROUTE,
  CANOPY_MAIN_PAGE_ROUTE,
  CANOPY_PAUSED,
  CANOPY_QUESTION_ROUTE
} from "../constants/navigation/canopyRoutes";
import {
  PROJECT_BASE_URL_NX,
  PROJECT_BRIEF_ROUTE
} from "../constants/navigation/projectRoutes";
import {
  CANOPY_PAUSED_PUBLIC,
  WELCOME_CANOPY_ROUTE
} from "../constants/navigation/publicRoutes";

// History
export const history = createBrowserHistory();

// Routing
type StoreRouting = ICanopyExpertRouting &
  ICanopyBuilderRouting &
  ICanopyClientRouting &
  IProjectRouting;

const storeRouting: StoreRouting = {
  canopyDetailsPageRoute: CANOPY_DETAILS_PAGE_ROUTE,
  canopyMainPageRoute: CANOPY_MAIN_PAGE_ROUTE,
  projectListRoute: PROJECT_BASE_URL_NX,
  projectRoute: (projectId: string) => PROJECT_MAIN_PAGE_ROUTE(projectId),
  projectBriefRoute: (projectId: string) => PROJECT_BRIEF_ROUTE(projectId),
  invitationLink: (canopyId: string) =>
    `${BASE_URL}${WELCOME_CANOPY_ROUTE(canopyId)}`,
  canopyQuestionRoute: CANOPY_QUESTION_ROUTE,
  draftProjectRoute: (draftId: string): string => DRAFT_PROJECT_ROUTE(draftId),
  discoverExpertsRoute: (projectId: string): string =>
    PROJECT_DISCOVER_ROUTE(projectId),
  canopyPaused: () => CANOPY_PAUSED,
  canopyPausedPublic: () => CANOPY_PAUSED_PUBLIC
};

// Dependencies
export type ClientStoreDependencies = {
  history: History;
  routing: StoreRouting;
} & ProjectCanopyStoreDependencies &
  ProjectAnglesStoreDependencies &
  CanopyExpertStoreDependencies &
  CanopyBuilderStoreDependencies &
  ProjectNxStoreDependencies &
  CacheDependencies &
  BookmarkCollectionsDependencies &
  BookmarksDependencies &
  ProjectExpertsStoreDependencies;

export const StoreDependencies: ClientStoreDependencies = {
  history,
  routing: storeRouting,
  notificationService: new ToasterService(),
  canopyService: CanopyService,
  anglesService: AnglesService,
  projectService: ProjectService,
  adminService: AdminService,
  cacheService: MetaService,
  bookmarkService: BookmarkService,
  projectExpertsService: ProjectExpertsService,
  documentService: DocumentService,
  chatService: ChatService,
  referralsService: DefaultReferralsService
};
