import { MutableRefObject, useCallback, useEffect, useState } from "react";

import { IChatMessage } from "@arbolus-technologies/api";
import { usePrevious } from "@arbolus-technologies/utils";

import { useChat } from "../../Contexts/ChatContext/ChatContext";

export function useScrollChat(
  chatMessages: IChatMessage[] | null,
  getNewChatMessages: Function,
  messagesHaveNextPage: boolean,
  areMessagesLoading: boolean,
  messageContainerRef: MutableRefObject<HTMLDivElement | undefined>
): boolean {
  const { currentChat, currentChatId } = useChat();

  const previousChatId = usePrevious(currentChatId);
  const [enableAutoScroll, setEnableAutoScroll] = useState(false);
  useEffect(() => {
    if (previousChatId !== currentChatId) {
      setEnableAutoScroll(true);
    }
  }, [currentChatId, previousChatId]);

  const handleScroll = useCallback(
    (event: WheelEvent): void => {
      if (messageContainerRef?.current) {
        setEnableAutoScroll(false);
        const { scrollHeight, scrollTop, clientHeight } =
          messageContainerRef.current;
        event.stopPropagation();
        const scrollHeightCalc = scrollHeight - clientHeight;
        const scrollPercentage = (scrollTop / scrollHeightCalc) * 100;
        // Scroll at top: get older messages
        if (scrollPercentage === 0 && event.deltaY < 0) {
          // Check that there are still messages to fetch and is not loading
          if (messagesHaveNextPage && !areMessagesLoading) {
            getNewChatMessages();
          }
        }
      }
    },
    [
      messageContainerRef,
      setEnableAutoScroll,
      messagesHaveNextPage,
      areMessagesLoading,
      getNewChatMessages
    ]
  );

  useEffect(() => {
    if (currentChat) {
      const copyMessageContainerRef = messageContainerRef.current;
      copyMessageContainerRef?.addEventListener("wheel", handleScroll);
      return () => {
        copyMessageContainerRef?.removeEventListener("wheel", handleScroll);
      };
    }
  }, [
    currentChat,
    chatMessages,
    areMessagesLoading,
    messagesHaveNextPage,
    handleScroll,
    messageContainerRef
  ]);

  return enableAutoScroll;
}
