import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Button } from "reactstrap";

import { CanopyDocument } from "@arbolus-technologies/models/canopy";
import { InfoBox } from "@arbolus-technologies/ui/components";
import { Nullable } from "@arbolus-technologies/utils";

import {
  ActionComponent,
  ActionComponentType
} from "../ActionComponent/ActionComponent";
import { SurveyDocs } from "../SurveyDocs/SurveyDocs";

import styles from "./CanopyDetailsRightClient.module.scss";

interface CanopyDetailsRightProps {
  canopyData: {
    overview?: string;
    links?: string[];
    documents?: CanopyDocument[];
    id: string;
    hasComplianceCheck: boolean;
  };
  copyInvitationLink: () => void;
  builderRoute: Nullable<(canopyId: string) => string>;
  showCompliance?: boolean;
}

export const CanopyDetailsRightClient: React.FC<CanopyDetailsRightProps> = ({
  canopyData,
  copyInvitationLink,
  builderRoute,
  showCompliance = true
}) => {
  const {
    overview,
    links = [],
    documents = [],
    hasComplianceCheck
  } = canopyData;
  const { t } = useTranslation("canopy");
  const history = useHistory();
  const title = hasComplianceCheck
    ? t("complianceCheckActivated")
    : t("complianceCheckNotActivated");
  const description = hasComplianceCheck
    ? t("answersWillBeReviewed")
    : t("complianceCheckNotActivated");
  const onEditClick = () => {
    history.push(builderRoute!(canopyData.id));
  };
  return (
    <div className={styles.rightContainer}>
      <div className={styles.documentsOverviewPiece}>
        <div className={styles.headerContainer}>
          <h1 className={styles.surveyDetails}>{t("surveyDetails")}</h1>
          {builderRoute !== null && (
            <Button
              size="md"
              color="secondary"
              onClick={onEditClick}
              className={styles.editButton}
            >
              {t("editDetails")}
            </Button>
          )}
        </div>
        <div className={styles.surveyOverview}>
          <span className={styles.titleOverview}>{t("overview")}</span>
          <span className={styles.textOverview}>{overview}</span>
        </div>
        <SurveyDocs documents={documents} isCanopyDetails links={links} />
        <div className={styles.infoboxContainer}>
          {showCompliance && (
            <InfoBox
              title={title}
              description={description}
              iconName={showCompliance ? "check" : "error_outline"}
              infoBoxType={showCompliance ? "notification" : "error"}
            />
          )}
        </div>
      </div>
      <div className={styles.getResponses}>
        <span className={styles.getResponsesTitle}>{t("getResponses")}</span>
        <ActionComponent
          type={ActionComponentType.copyLink}
          onClick={copyInvitationLink}
        />
      </div>
    </div>
  );
};
