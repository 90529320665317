import {
  AngleModel,
  EventModel,
  ExpertTaglineModel
} from "@arbolus-technologies/models/common";

import { PaginatedRequest } from "./api";
import { IEntity, TimeZone } from "./common";
import { PROJECT_EVENT_PLATFORM } from "./projects";
import { User } from "./user";

export interface ListEvent extends IEntity {
  title: string;
  startTime: Date;
  endTime: Date;
}

export interface ListProjectEvent extends ListEvent {
  timezone: string;
}

export interface TransactionEventDetails extends ListProjectEvent {
  userEnteredStartTime: Date;
  realDurationMinutes: number;
  expertHasMinimumCallTimeRate: boolean;
}

export interface BaseEvent extends ListEvent {
  timezone: TimeZone;
  location: string;
  eventGuests: EventGuest[];
  organizer: User;
  meetingPlatform?: PROJECT_EVENT_PLATFORM;
}

export interface Event extends BaseEvent {
  meetingJoinUrl?: string;
  notes: string;
  transcribe: boolean;
  humanTranscribe: boolean;
  eventAttachments: EventAttachment[];
  expertHasMinimumCallTimeRate?: boolean;
}

export interface EventGuest {
  email: string;
  userId?: string;
  user?: User;
}

export interface EventAttachment {
  id: string;
  fileName: string;
  fileSize: number;
}

export interface EventsDuration {
  start: string;
  end: string;
}

export interface EventGetPaginatedRequest {
  startTime: string;
  endTime: string;
  offset: number;
  OrderDirection: string;
  limit: number;
}

export interface CreateEventRequest {
  title: string;
  startTime: string;
  endTime: string;
  timezone: string;
  guests: string[];
  meetingPlatform?: PROJECT_EVENT_PLATFORM;
  transcribe: boolean;
  humanTranscribe: boolean;
  location: string;
  notes: string;
  attachments: string[];
  workspaceId?: string;
}

export type SaveEventRequest = CreateEventRequest;

export interface SavedExpertStatusResponse {
  savedExpert: boolean;
}

export interface GetEventDurationResponse {
  realDurationMinutes: number;
}

export enum LIST_EVENTS_ORDER_BY {
  StartTime = "startTime"
}

export interface ListEventsParams
  extends PaginatedRequest<LIST_EVENTS_ORDER_BY> {
  startDate?: string;
  endDate?: string;
  excludePastEvents?: boolean;
  angleId?: string | null;
}

export interface ListEventModel extends EventModel {
  angle?: AngleModel;
  expert?: ExpertTaglineModel;
}
