import { BookmarkListModel } from "../Bookmarks";

export interface BookmarkVideoAnswer {
  id: string;
  created: string;
  isBookmarked: boolean;
  downloadUrl: string;
  transcript: string;
  isSmartTranscript: boolean;
}

export interface BookmarkExpert {
  title: string;
  expertId: string;
  id: string;
  firstName: string;
  lastName: string;
  profileImageUrl: string;
}
export class BookmarkList {
  videoAnswer: BookmarkVideoAnswer;
  questionId: string;
  questionText: string;
  questionDetails: string;
  expert: BookmarkExpert;
  id: string;
  created: Date;
  referenceCreated: Date;
  canopyAnswerId: string;
  canopyTitle: string;
  canopyId: string;

  constructor(model: BookmarkListModel) {
    this.id = model.id;
    this.videoAnswer = model.videoAnswer;
    this.canopyAnswerId = model.canopyAnswerId;
    this.canopyTitle = model.canopyTitle;
    this.created = model.created;
    this.expert = model.expert;
    this.questionDetails = model.questionDetails;
    this.questionId = model.questionId;
    this.questionText = model.questionText;
    this.referenceCreated = model.referenceCreated;
    this.canopyId = model.canopyId;
  }

  static create(model: BookmarkListModel): BookmarkList {
    return new BookmarkList(model);
  }

  toModel(): BookmarkListModel {
    return {
      id: this.id,
      videoAnswer: this.videoAnswer,
      canopyAnswerId: this.canopyAnswerId,
      canopyTitle: this.canopyTitle,
      created: this.created,
      expert: this.expert,
      questionDetails: this.questionDetails,
      questionId: this.questionId,
      questionText: this.questionText,
      referenceCreated: this.referenceCreated,
      canopyId: this.canopyId
    };
  }
}
