import React, { RefObject } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { useTranslation } from "react-i18next";

import { formatUnits } from "@arbolus-technologies/utils";

import { Loader } from "../Loader/Loader";

import styles from "./InfiniteScrollV2.module.scss";

export interface InfiniteScrollV2Props<T> {
  items: T[] | null;
  onBottomReached: () => void;
  renderer: (item: T) => JSX.Element | null;
  isLoading: boolean;
  customPadding?: number[];
  customGap?: number[];
}

export const InfiniteScrollV2 = <T,>({
  items,
  onBottomReached,
  renderer,
  isLoading,
  customPadding,
  customGap = [2, 0]
}: InfiniteScrollV2Props<T>): JSX.Element => {
  const { t } = useTranslation();
  const scrollRef: RefObject<HTMLElement> = useBottomScrollListener(() => {
    onBottomReached();
  });
  return (
    <div
      className={styles.container}
      style={{
        padding: formatUnits(customPadding),
        gap: formatUnits(customGap)
      }}
      ref={scrollRef as RefObject<HTMLDivElement>}
    >
      {!isLoading && !items && <p>{t("common:infiniteScroll:noResults")}</p>}
      {items && items.map(renderer)}
      {isLoading && <Loader />}
    </div>
  );
};
