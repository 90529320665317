import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  LIST_TRANSCRIPTS_ORDER_BY,
  ListTranscriptModel,
  ListTranscriptsParams,
  PaginationData,
  SORT_DIRECTION,
  ToasterService,
  Transcript,
  TranscriptService
} from "@arbolus-technologies/api";
import {
  DefaultTranscriptToBlob,
  TranscriptToBlob
} from "@arbolus-technologies/features/common";
import {
  PROJECT_TRANSCRIPT,
  PROJECT_TRANSCRIPTS_ROUTE
} from "@arbolus-technologies/routes";
import {
  LoaderOrComponent,
  ProjectEmptyTranscripts,
  TranscriptCard
} from "@arbolus-technologies/ui/components";
import { GrayBox } from "@arbolus-technologies/ui/layout";

import { Pagination } from "../../Components/Pagination/Pagination";
import { useAngleQuery } from "../../Hooks/useAngleQuery";
import { getCurrentAngleId } from "../../helpers";

import { Alert } from "antd";
import styles from "./ProjectTranscripts.module.scss";

interface ProjectTranscriptsProps {
  projectId: string;
  timezone: string;
  transcriptService?: typeof TranscriptService;
  notificationService?: ToasterService;
  transcriptToBlob?: TranscriptToBlob;
}

const queryParams: ListTranscriptsParams = {
  limit: 3,
  offset: 0,
  sort: [
    {
      orderBy: LIST_TRANSCRIPTS_ORDER_BY.CREATED,
      orderDirection: SORT_DIRECTION.DESCENDING
    }
  ]
};

export const ProjectTranscripts: React.FC<ProjectTranscriptsProps> = ({
  projectId,
  timezone,
  transcriptService = TranscriptService,
  notificationService = DefaultToasterService,
  transcriptToBlob = DefaultTranscriptToBlob
}) => {
  const { t } = useTranslation("projectTranscripts");
  const angleId = useAngleQuery();

  const [transcripts, setTranscripts] = useState<ListTranscriptModel[]>([]);
  const [expiringThisWeek, setExpiringThisWeek] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [paginationDetails, setPaginationDetails] = useState<
    Omit<PaginationData, "offset">
  >({ limit: 0, count: 0 });

  const fetchTranscripts = useCallback(
    (angleId?: string | null, offset = 0) => {
      setIsLoading(true);
      transcriptService
        .listTranscripts(projectId, {
          angleId,
          ...queryParams,
          offset
        })
        .subscribe(
          (response) => {
            setTranscripts(response.items);
            setPaginationDetails(response.pagination);
            setExpiringThisWeek(response.expiringThisWeek);
            setIsLoading(false);
          },
          (error: ErrorResponse<CIQError>) => {
            notificationService.showApiErrors(error);
            setIsLoading(false);
          }
        );
    },
    [notificationService, projectId, transcriptService]
  );

  const handleDownloadDocx = (transcriptId: string) => {
    transcriptService.getTranscriptData(projectId, transcriptId).subscribe({
      next: (response: Transcript) => {
        const { transcriptionData } = response;
        transcriptToBlob.downloadTranscript(
          {
            transcriptSpeakerCount: transcriptionData.speakers.length,
            ...response
          },
          transcriptionData.monologues,
          new Map(transcriptionData.speakers.map((s) => [s.name, s]))
        );
      },
      error: notificationService.showApiErrors
    });
  };

  useEffect(() => {
    if (getCurrentAngleId() === angleId) {
      fetchTranscripts(angleId, pageNumber * queryParams.limit);
    }
  }, [fetchTranscripts, angleId, pageNumber]);

  return (
    <GrayBox
      title={{
        text: t("title", { count: paginationDetails.count }),
        to: PROJECT_TRANSCRIPTS_ROUTE(projectId)
      }}
      flexDirection="column"
      gap={[2, 0]}
      fullWidth
      actionComponent={
        <Pagination
          limit={paginationDetails.limit}
          count={paginationDetails.count}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      }
    >
      {expiringThisWeek > 0 && (
        <Alert
          message={t("warningXTranscriptsWillExpire", {
            count: expiringThisWeek
          })}
          type="warning"
          showIcon
        />
      )}
      <LoaderOrComponent isLoading={isLoading}>
        {!transcripts?.length && <ProjectEmptyTranscripts />}
        {transcripts.length > 0 && (
          <div className={styles.transcriptsContainer}>
            {transcripts?.map((transcript) => (
              <TranscriptCard
                key={transcript.id}
                transcript={transcript}
                angle={transcript.angle}
                expert={transcript.expert}
                timezone={timezone}
                to={PROJECT_TRANSCRIPT(projectId, transcript.id)}
                handleDownloadDocx={handleDownloadDocx}
              />
            ))}
          </div>
        )}
      </LoaderOrComponent>
    </GrayBox>
  );
};
