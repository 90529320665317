import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useDocumentTitle } from "usehooks-ts";

import { SurveyAlreadyCompletedFeedback } from "../../Components/SurveyAlreadyCompletedFeedback";
import { SurveyClosedFeedback } from "../../Components/SurveyClosedFeedback";

interface LocationState {
  isSurveyClosed: boolean;
  isSurveyCompleted: boolean;
}

export const SurveyFeedbackPage: React.FC = () => {
  const { t } = useTranslation("surveyFeedbackPage");
  useDocumentTitle(t("survey"));
  const location = useLocation<LocationState>();

  const { isSurveyClosed, isSurveyCompleted } = location.state;

  return (
    <>
      {isSurveyClosed && !isSurveyCompleted && <SurveyClosedFeedback />}
      {isSurveyCompleted && <SurveyAlreadyCompletedFeedback />}
    </>
  );
};
