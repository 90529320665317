import React from "react";
import { useTranslation } from "react-i18next";

import { EmptyComponent } from "../EmptyComponent";

import warning from "../../../assets/images/warning.png";

export const SomethingWentWrongWarning: React.FC = () => {
  const { t } = useTranslation("canopySummary");
  return (
    <EmptyComponent
      title={t("somethingWentWrongWarningTitleWarning")}
      subtitle={t("somethingWentWrongWarningSubtitleWarning")}
      logo={warning}
    />
  );
};
