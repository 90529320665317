import { Observable } from "rxjs";

import { AngleLink } from "@arbolus-technologies/models/project";

import { ANGLES_API, MAX_PAGE_SIZE } from "../constants/api";
import { SORT_DIRECTION } from "../enums/apiEnums";
import { ApiPaginatedResponse, CreatedResponse } from "../models/api";
import { ReferralData } from "../models/expert";
import {
  Angle,
  AngleResponse,
  AngleToSend,
  QuestionsResponse
} from "../models/projects";
import { restService } from "../restService";

export const AnglesService = {
  getAnglesCards: (
    projectId: string,
    Offset = 0,
    Limit: number = MAX_PAGE_SIZE,
    OrderBy = "Created",
    OrderDirection: string = SORT_DIRECTION.DESCENDING
  ): Observable<ApiPaginatedResponse<Angle>> =>
    restService.get<ApiPaginatedResponse<Angle>>(
      ANGLES_API.GET_ANGLES_CARDS(),
      { projectId, Offset, Limit, OrderBy, OrderDirection }
    ),

  getAngleLinks: (
    projectId: string
  ): Observable<ApiPaginatedResponse<AngleLink>> =>
    restService.get<ApiPaginatedResponse<AngleLink>>(
      ANGLES_API.GET_ANGLES_LINKS(projectId)
    ),
  getAngle: (angleId: string): Observable<AngleResponse> =>
    restService.get<AngleResponse>(ANGLES_API.GET_ANGLE(angleId)),
  getAngleQuestions: (angleId: string): Observable<QuestionsResponse> =>
    restService.get<QuestionsResponse>(ANGLES_API.GET_ANGLE_QUESTIONS(angleId)),
  editAngle: (angleId: string, data: AngleToSend): Observable<AngleResponse> =>
    restService.put<AngleResponse>(ANGLES_API.EDIT_ANGLE(angleId), data),
  addAngle: (data: AngleToSend): Observable<AngleResponse> =>
    restService.post<AngleResponse>(ANGLES_API.ADD_ANGLE(), data),
  assignAngle: (
    angleId: string,
    referralData: ReferralData
  ): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(
      ANGLES_API.ASSIGN_ANGLE(angleId),
      referralData
    )
};
