import { GridApi } from "ag-grid-community";
import { Icon } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";

import { Referral } from "@arbolus-technologies/models/common";

import styles from "./StatusPanel.module.scss";

interface CustomStatusPanelProps {
  api: GridApi<Referral>;
}

export const ExpandCollapseStatusPanel: React.FC<CustomStatusPanelProps> = ({
  api
}) => {
  const { t } = useTranslation("referrals", { keyPrefix: "gridNavigation" });

  const expandAll = React.useCallback(() => {
    api.expandAll();
  }, [api]);

  const collapseAll = React.useCallback(() => {
    api.collapseAll();
  }, [api]);

  return (
    <div className="flex-row-8 h-100">
      <div onClick={expandAll} className={styles.button}>
        <Icon name="unfold_more" fontSize="18px" tooltip={t("expand")} />
        <span>{t("expand")}</span>
      </div>
      <div onClick={collapseAll} className={styles.button}>
        <Icon name="unfold_less" fontSize="18px" tooltip={t("collapse")} />
        <span>{t("collapse")}</span>
      </div>
    </div>
  );
};
