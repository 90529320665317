import { Button } from "arbolus-ui-components";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { EXPERT_CANOPY_STATUS } from "@arbolus-technologies/models/canopy";
import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  CanopyExpertSelector,
  CanopyExpertStoreActions
} from "@arbolus-technologies/stores/canopy-expert";

import { ConfirmationModals } from "../../ConfirmationModals/ConfirmationModals";

import styles from "./ButtonsPlayVideo.module.scss";

interface ButtonsPlayVideoProps {
  handleOnClickSave: () => void;
  showSaveButton?: boolean;
}

export const ButtonsPlayVideo: React.FC<ButtonsPlayVideoProps> = ({
  handleOnClickSave,
  showSaveButton
}) => {
  const { t } = useTranslation("canopyVideo");
  const dispatch = useDispatch();

  const [isRetakeModalOpen, setIsRetakeModalOpen] = useState(false);
  const [isGoBackModalOpen, setIsGoBackModalOpen] = useState(false);
  const [isShortVideoModalOpen, setIsShortVideoModalOpen] = useState(false);

  const isVideoSaving = useSelector(CanopyExpertSelector.isCanopyVideoSaving());
  const expertCanopy = useSelector(CanopyExpertSelector.canopySelector());
  const videoTime = useSelector(CanopyExpertSelector.videoTime());
  const loggedInExpert = useSelector(CacheSelector.loggedInUser());

  const { canopyExpertStatus } = expertCanopy;
  const isCanopyCompleted =
    canopyExpertStatus === EXPERT_CANOPY_STATUS.COMPLETE ||
    canopyExpertStatus === EXPERT_CANOPY_STATUS.REJECTED;

  const checkVideoDuration = useCallback(() => {
    if (videoTime <= 30) {
      setIsShortVideoModalOpen(true);
    } else {
      handleOnClickSave();
    }
  }, [videoTime, handleOnClickSave]);

  const handleConfirmShortVideo = () => {
    setIsShortVideoModalOpen(false);
    dispatch(CanopyExpertStoreActions.retakeVideo());
  };

  const handleSaveShortVideo = () => {
    setIsShortVideoModalOpen(false);
    handleOnClickSave();
  };

  const handleConfirmRetake = () => {
    setIsRetakeModalOpen(false);
    dispatch(CanopyExpertStoreActions.retakeVideo());
  };

  const handleConfirmGoBack = () => {
    handleResetMobile();
    setIsGoBackModalOpen(false);
  };

  const handleResetMobile = () => {
    dispatch(CanopyExpertStoreActions.resetMobile());
    dispatch(CanopyExpertStoreActions.resetCanopyCamera());
    dispatch(CanopyExpertStoreActions.showRightComponent(false));
  };

  useEffect(() => {
    if (!isCanopyCompleted && showSaveButton && !isVideoSaving) {
      checkVideoDuration();
    }
  }, [checkVideoDuration, isCanopyCompleted, showSaveButton, isVideoSaving]);

  return (
    <>
      <ConfirmationModals
        shortVideoModal={{
          handleConfirmShortVideo,
          handleSaveShortVideo,
          isShortVideoModalOpen,
          setIsShortVideoModalOpen
        }}
        retakeModal={{
          handleConfirmRetake,
          isRetakeModalOpen,
          setIsRetakeModalOpen
        }}
        backModal={{
          handleConfirmGoBack,
          isGoBackModalOpen,
          setIsGoBackModalOpen
        }}
      />
      {!isCanopyCompleted &&
        loggedInExpert.doNotContactStatus !== DO_NOT_CONTACT_STATUS.DNC && (
          <div className={clsx(styles.container)}>
            <Button
              type="secondary"
              text={t("retake")}
              onClick={() => setIsRetakeModalOpen(true)}
              endIcon="undo"
            />
          </div>
        )}
    </>
  );
};
