import React from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { QuestionParamUrlTypes } from "@arbolus-technologies/models/canopy";
import { CANOPY_DETAILS_PAGE_ROUTE } from "@arbolus-technologies/routes";
import {
  CanopyExpertSelector,
  CanopyExpertStoreActions
} from "@arbolus-technologies/stores/canopy-expert";
import { LinkButton } from "@arbolus-technologies/ui/components";

import { QUESTION_PAGE_ROUTES } from "../../helpers/routes";

import styles from "./CanopyDetailsContainer.module.scss";

const DETAILS_REGEX =
  /canopy\/[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}\/details/i;

export const CanopyNavigation: React.FC = () => {
  const { t } = useTranslation("canopyExpertPage");
  const dispatch = useDispatch();
  const { questionId } = useParams<QuestionParamUrlTypes>();

  const expertCanopy = useSelector(CanopyExpertSelector.canopySelector());
  const expertQuestionList = useSelector(
    CanopyExpertSelector.questionsSelector()
  );

  const currentQuestionIndex = expertQuestionList.findIndex(
    (question) => question.id === questionId
  );

  const prevQuestion =
    currentQuestionIndex === 0
      ? null
      : expertQuestionList[currentQuestionIndex - 1];
  const nextQuestion =
    currentQuestionIndex === expertQuestionList.length - 1
      ? null
      : expertQuestionList[currentQuestionIndex + 1];

  const isDetailsPage = DETAILS_REGEX.test(window.location.toString());

  const handleBack = () => {
    dispatch(CanopyExpertStoreActions.resetMobile());
    dispatch(CanopyExpertStoreActions.showRightComponent(false));
  };

  const getNavigationRoute = (isPrev = false) => {
    if (isPrev) {
      return prevQuestion
        ? QUESTION_PAGE_ROUTES[prevQuestion.type](
            expertCanopy.id,
            prevQuestion.id
          )
        : "";
    } else {
      return nextQuestion
        ? QUESTION_PAGE_ROUTES[nextQuestion.type](
            expertCanopy.id,
            nextQuestion.id
          )
        : "";
    }
  };

  if (!isMobile && isDetailsPage) {
    return null;
  }

  return (
    <div className={styles.navigationContainer}>
      {isMobile && (
        <LinkButton
          to={CANOPY_DETAILS_PAGE_ROUTE(expertCanopy.id)}
          type="tertiary"
          text={t("back")}
          startIcon="chevron_left"
          onClick={handleBack}
        />
      )}
      {!isDetailsPage && (
        <div className={styles.questionNavigation}>
          <LinkButton
            to={getNavigationRoute(true)}
            type="tertiary"
            text={t("prev")}
            startIcon="chevron_left"
            disabled={!prevQuestion}
          />
          <LinkButton
            to={getNavigationRoute()}
            type="tertiary"
            text={t("next")}
            endIcon="chevron_right"
            disabled={!nextQuestion}
          />
        </div>
      )}
    </div>
  );
};
