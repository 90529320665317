export const fixedWidth = (
  width: number
): { width: number; minWidth: number; maxWidth: number; resizable: false } => ({
  resizable: false,
  width,
  minWidth: width,
  maxWidth: width
});

export const floatingFilter = {
  floatingFilter: true,
  suppressHeaderMenuButton: true,
  suppressFloatingFilterButton: true
};

export const floatingSetFilter = {
  floatingFilter: true,
  suppressHeaderMenuButton: true
};
