import { Icon } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { ChatErrors } from "../../utils";

import styles from "./PendingWarningNotification.module.scss";

interface PendingWarningNotificationProps {
  chatErrors: ChatErrors | null;
}

export const PendingWarningNotification: React.FC<
  PendingWarningNotificationProps
> = ({ chatErrors }) => {
  const { t } = useTranslation("chat");

  if (!chatErrors) return null;

  const mapVisibleErrors = {
    [t("expertChatReferralNotAccepted")]: chatErrors.referralNotAccepted,
    [t("expertChatPendingReview")]: chatErrors.isReviewPending,
    [t("expertChatRejectedReview")]: chatErrors.isReviewRejected,
    [t("expertChatPendingApplication")]: chatErrors.isApplicationPending,
    [t("expertChatRejectedApplication")]: chatErrors.isApplicationRejected,
    [t("expertChatPendingCompliance")]: chatErrors.isCompliancePending,
    [t("expertChatRejectedCompliance")]: chatErrors.isComplianceRejected,
    [t("expertChatDoNotContact")]: chatErrors.doNotContact
  };

  return (
    <div className={styles.notificationsContainer}>
      {Object.entries(mapVisibleErrors).map(([errorMessage, isVisible]) =>
        isVisible ? (
          <ChatErrorMessage key={errorMessage} message={errorMessage} />
        ) : null
      )}
    </div>
  );
};

const ChatErrorMessage: React.FC<{ message: string }> = ({ message: text }) => (
  <div className={styles.notificationRowDanger}>
    <Icon fontSize="16px" name="error" color={ARBOLUS_COLORS.bColorBaseWhite} />
    <p className={styles.textDanger}>{text}</p>
  </div>
);
