import React from "react";

import { User } from "@arbolus-technologies/api";
import { fullName } from "@arbolus-technologies/utils";

import { Avatar } from "../Avatar/Avatar";
import { HR } from "../HR/HR";
import { IThreeDotsItem, ThreeDots } from "../ThreeDots/ThreeDots";

import styles from "./UserHeader.module.scss";

interface ClientUserHeaderProps {
  user: User;
  threeDotsItems?: IThreeDotsItem[];
  clientName?: string;
}

export const UserHeader: React.FC<ClientUserHeaderProps> = ({
  user,
  threeDotsItems,
  clientName
}) => (
  <>
    <div className={styles.userInfoWrapper}>
      <div className={styles.leftWrapper}>
        <Avatar
          avatar={{
            name: fullName(user.firstName, user.lastName),
            imageUrl: user.profileImageUrl
          }}
          type="mediumCircle"
        />
        <div className={styles.nameCompanyWrapper}>
          <span className={styles.name}>
            {fullName(user.firstName, user.lastName)}
          </span>
          <span className={styles.company}>
            {clientName}
            {clientName && user.title && `- `}
            {user.title}
          </span>
        </div>
      </div>
      {threeDotsItems && (
        <ThreeDots
          popoverId={`client_user_profile_${user.id}`}
          items={threeDotsItems}
        />
      )}
    </div>
    <HR margin={{ top: 1, bottom: 0 }} />
  </>
);
