import i18next from "i18next";
import * as Yup from "yup";

import { DNC_MAX_NOTE_LENGTH } from "../../../constants";

export interface DNCHistoryNoteFormValues {
  dncNote: string;
}

export const EditHistoryNoteFormSchema: Yup.ObjectSchema<DNCHistoryNoteFormValues> =
  Yup.object().shape({
    dncNote: Yup.string()
      .required(i18next.t("dncSection:noteRequired"))
      .typeError(i18next.t("dncSection:noteRequired"))
      .max(
        DNC_MAX_NOTE_LENGTH,
        i18next.t("dncSection:noteLengthError", {
          length: DNC_MAX_NOTE_LENGTH
        })
      )
  });
