import moment from "moment";

import {
  EventCreationDetailsParams,
  EventCreationState
} from "@arbolus-technologies/models/project";
import { EVENT_DATE_TIME_FORMAT } from "@arbolus-technologies/utils";

export const buildEventFromChat = ({
  expert,
  workspaceId,
  selectedSlot,
  timezone,
  title
}: EventCreationDetailsParams): EventCreationState => {
  const startMoment = timezone
    ? moment.utc(selectedSlot.startDate).tz(timezone)
    : moment(selectedSlot.startDate);
  const startDate = new Date(startMoment.format(EVENT_DATE_TIME_FORMAT));

  const endMoment = timezone
    ? moment.utc(selectedSlot.endDate).tz(timezone)
    : moment(selectedSlot.endDate);
  const endDate = new Date(endMoment.format(EVENT_DATE_TIME_FORMAT));

  return {
    title: title ?? "",
    workspaceId: workspaceId ?? "",
    expert,
    startDate,
    endDate,
    timezone
  };
};

export const generateEventName = (
  expertName: string,
  projectName: string
): string => `${expertName}, ${projectName}`;

export const convertToPercentage = (
  rangeMin: number,
  rangeMax: number,
  answersAverage: number
): number => {
  // Calculate the range of values
  const range = rangeMax - rangeMin;

  // Calculate the distance of answersAverage from the minimum value
  const distanceFromMin = answersAverage - rangeMin;

  // Calculate the percentage
  const percentage = (distanceFromMin / range) * 100;

  return percentage;
};

export function noop(): void {
  // For rare cases where we're forced to pass a function but don't want to do anything
}
