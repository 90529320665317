import clsx from "clsx";
import React, { createRef } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { Subscription, of, zip } from "rxjs";
import { switchMap } from "rxjs/operators";

import { ToasterService, TranscriptService } from "@arbolus-technologies/api";
import {
  MixpanelPages,
  trackPageView
} from "@arbolus-technologies/features/common";
import { Loader } from "@arbolus-technologies/ui/components";

import { Alert } from "antd";
import { PROJECT_ROUTE } from "../../../../constants/navigation/projectRoutes";
import TranscriptProvider from "../../../../contexts/transcript/TranscriptProvider";
import { CIQError, ErrorResponse } from "../../../../models/api";
import { Event } from "../../../../models/event";
import { Transcript } from "../../../../models/transcript";
import { EventService } from "../../../../services";
import TranscriptEditor from "../../components/transcript/editor/TranscriptEditor";
import TranscriptPageHeader from "../../components/transcript/header/PageHeader";
import TranscriptHeader from "../../components/transcript/header/TranscriptHeader";
import MeetingPlayback from "../../components/transcript/meetingPlayback/MeetingPlayback";

const notification = new ToasterService();

type TranscriptPageProps = RouteComponentProps<{
  projectId: string;
  transcriptId: string;
}>;

interface TranscriptPageState {
  isLoading: boolean;
  transcript?: Transcript;
}

type TranscriptPageIntersectProps = TranscriptPageProps & WithTranslation;

class TranscriptPage extends React.Component<
  TranscriptPageIntersectProps,
  TranscriptPageState
> {
  constructor(props: TranscriptPageIntersectProps) {
    super(props);
    this.state = {
      isLoading: true
    };
  }

  componentDidMount(): void {
    this.fetchTranscriptData();
  }

  componentWillUnmount(): void {
    this.transcriptDetailsFetchSubscription?.unsubscribe();
  }

  private transcriptDetailsFetchSubscription?: Subscription;

  private eventData?: Event;

  private transcript?: Transcript;

  private speakerItemPlaybackRef = createRef<Function | undefined>();

  fetchTranscriptData = (): void => {
    const {
      t,
      match: {
        params: { projectId, transcriptId }
      }
    } = this.props;

    this.transcriptDetailsFetchSubscription =
      TranscriptService.getTranscriptData(projectId, transcriptId)
        .pipe(
          switchMap((transcript) => {
            if (
              !transcript.transcriptionData.speakers?.length ||
              !transcript.transcriptionData.monologues.length
            ) {
              notification.showWarning(t("emptyTranscript"));
            }
            return zip(
              EventService.getEvent(projectId, transcript.eventId),
              of(transcript)
            );
          })
        )
        .subscribe(
          ([event, transcript]) => {
            this.eventData = event;
            this.transcript = transcript;

            this.setState({
              isLoading: false
            });

            trackPageView({
              page: MixpanelPages.CallTranscripts,
              projectId,
              transcriptId: transcript.id,
              transcriptTitle: transcript.title,
              eventId: transcript.eventId,
              clientName: transcript.clientName
            });
          },
          (error: ErrorResponse<CIQError>) => {
            notification.showError(error.message);
            const { history } = this.props;
            history.replace(PROJECT_ROUTE(projectId));
          }
        );
  };

  handleSpeakerItemStartPlay = (startTime: number): void => {
    this.speakerItemPlaybackRef.current?.(startTime);
  };

  render(): JSX.Element {
    const { isLoading } = this.state;
    const { t } = this.props;
    if (isLoading) {
      return <Loader isFull />;
    }

    const hasRecording = !!this.transcript?.recordingUrl;
    const expiresInDays = this.transcript?.expiresInDays!;
    const expirationAlertType = expiresInDays <= 7 ? "error" : "warning";
    const isTranscriptReady = !!this.transcript;

    const speakers = this.transcript?.transcriptionData.speakers ?? [];
    const monologues = this.transcript?.transcriptionData.monologues ?? [];
    const transcriptHasSpeakersData =
      (speakers.length || monologues.length) > 0;

    return (
      <div className="transcript-container">
        {isTranscriptReady && (
          <TranscriptProvider
            initTranscript={this.transcript!}
            initEvent={this.eventData!}
          >
            <TranscriptPageHeader />
            {transcriptHasSpeakersData && expiresInDays <= 30 && (
              <div className="expiration-alert">
                <Alert
                  message={t("expiringSoonAlertTitle", { days: expiresInDays })}
                  description={t("expiringSoonAlertDescription")}
                  type={expirationAlertType}
                  showIcon
                />
              </div>
            )}
            <div
              className={clsx("transcript-body", {
                "no-video": !hasRecording
              })}
            >
              {hasRecording && (
                <div className="left-container">
                  <MeetingPlayback
                    onSpeakerItemStart={(
                      ref: React.MutableRefObject<Function | undefined>
                    ): void => {
                      this.speakerItemPlaybackRef = ref;
                    }}
                  />
                </div>
              )}
              <div className="right-container">
                <TranscriptHeader />
                {transcriptHasSpeakersData ? (
                  <>
                    <TranscriptEditor
                      onPlayFromMonologue={this.handleSpeakerItemStartPlay}
                    />
                  </>
                ) : (
                  <div className="speaker-details">
                    <div className="speaker-empty">{t("emptyTranscript")}</div>
                  </div>
                )}
              </div>
            </div>
          </TranscriptProvider>
        )}
      </div>
    );
  }
}

export default withTranslation("transcriptPage")(TranscriptPage);
