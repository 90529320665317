import React from "react";
import { useTranslation } from "react-i18next";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import { BoxButton } from "../../BoxButton/BoxButton";

interface ProjectNotificationsButtonProps {
  onClick: () => void;
}

export const ProjectNotificationsButton: React.FC<
  ProjectNotificationsButtonProps
> = ({ onClick }) => {
  const { t } = useTranslation("projectNotificationsButton");
  return (
    <BoxButton
      text={t("projectNotifications")}
      icon={{
        color: ARBOLUS_COLORS.bColorSecondaryGreyDark,
        background: ARBOLUS_COLORS.bColorBaseWhite,
        name: "settings"
      }}
      onClick={onClick}
      displayRightIcon={false}
    />
  );
};
