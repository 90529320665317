import React, { useCallback, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Webcam from "react-webcam";

import {
  QuestionParamUrlTypes,
  VIDEO_MODE_ENUM
} from "@arbolus-technologies/models/canopy";
import {
  CanopyExpertSelector,
  CanopyExpertStoreActions
} from "@arbolus-technologies/stores/canopy-expert";
import { useRecordVideo } from "@arbolus-technologies/utils";

import { searchAndReplaceSupported } from "../../../helpers/utils";
import { ButtonsRecordVideo } from "../ButtonsRecordVideo/ButtonsRecordVideo";
import { VideoInfo } from "../VideoInfo/VideoInfo";
import { VideoLoader } from "../VideoLoader/VideoLoader";

export const VideoRecorder: React.FC = () => {
  const dispatch = useDispatch();
  const { questionId } = useParams<QuestionParamUrlTypes>();
  const videoRef = useRef(null);

  const [camLoaded, setCamLoaded] = useState<boolean>(false);

  const forceResetCam = useSelector(CanopyExpertSelector.forceResetCam());

  const {
    startRecording,
    stopRecording,
    recordedChunks,
    recordingTime,
    supportedVideoType,
    resetRecording,
    isRecording
  } = useRecordVideo(videoRef);

  const videoContainerWidth = isMobile ? "100%" : 400;
  const defaultVideoType = "video/mp4;codecs=avc1";
  const supportedType = supportedVideoType ?? defaultVideoType;

  const videoConstraints = {
    video: {
      facingMode: "user"
    },
    width: 400,
    height: 400
  };

  const handleStartRecording = (): void => {
    startRecording();
    dispatch(CanopyExpertStoreActions.setRecordingVideo(true));
  };

  const handleStopRecording = (): void => {
    stopRecording();
    dispatch(CanopyExpertStoreActions.setRecordingVideo(false));
  };

  useEffect(() => {
    if (recordingTime === 300) {
      handleStopRecording();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordingTime]);

  const prepareVideo = useCallback(() => {
    if (recordedChunks?.length) {
      const blob = new Blob(recordedChunks, {
        type: supportedType
      });
      const url = URL.createObjectURL(blob);

      dispatch(
        CanopyExpertStoreActions.setCanopyVideo(
          url,
          blob,
          false,
          false,
          recordingTime,
          searchAndReplaceSupported(supportedType)
        )
      );
      dispatch(CanopyExpertStoreActions.changeVideoMode(VIDEO_MODE_ENUM.PLAY));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordedChunks]);

  useEffect(() => {
    if (recordedChunks !== null && !isRecording) {
      prepareVideo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordedChunks, isRecording]);

  useEffect(() => {
    resetRecording();
    dispatch(
      CanopyExpertStoreActions.setCanopyVideo(null, null, false, false, 0, null)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceResetCam]);

  // Stop recording and reset on questionId change
  useEffect(() => {
    resetRecording();
    dispatch(CanopyExpertStoreActions.setRecordingVideo(false));
    dispatch(
      CanopyExpertStoreActions.setCanopyVideo(null, null, false, false, 0, null)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId]);

  return (
    <>
      <Webcam
        ref={videoRef}
        onUserMedia={() => {
          setTimeout(() => setCamLoaded(true), 500);
        }}
        audio
        videoConstraints={videoConstraints}
        width={camLoaded ? videoContainerWidth : 0}
        mirrored
        playsInline
        muted
      />
      {camLoaded ? (
        <>
          <ButtonsRecordVideo
            handleStartRecording={handleStartRecording}
            handleStopRecording={handleStopRecording}
            seconds={recordingTime}
          />
          <VideoInfo />
        </>
      ) : (
        <VideoLoader />
      )}
    </>
  );
};
