import clsx from "clsx";
import React, { useState } from "react";

import { MobileExpertsPage } from "./MobileExpertsPage/MobileExpertsPage";
import { MobileFilterPage } from "./MobileFilterPage/MobileFilterPage";

import styles from "./DiscoverMobileLayout.module.scss";

interface DiscoverMobileLayoutProps {
  projectId: string;
  projectName: string;
}

export const DiscoverMobileLayout: React.FC<DiscoverMobileLayoutProps> = ({
  projectId,
  projectName
}) => {
  const [areFiltersOpen, setAreFiltersOpen] = useState(false);

  const handleCloseFilters = () => {
    setAreFiltersOpen(false);
  };

  return (
    <>
      <div
        className={clsx(styles.filterView, {
          [styles.showFilters]: areFiltersOpen
        })}
      >
        <MobileFilterPage handleCloseFilters={handleCloseFilters} />
      </div>
      <div
        className={clsx(styles.expertsView, {
          [styles.showExperts]: !areFiltersOpen
        })}
      >
        <MobileExpertsPage
          projectId={projectId}
          projectName={projectName}
          areFiltersOpen={areFiltersOpen}
          setAreFiltersOpen={setAreFiltersOpen}
        />
      </div>
    </>
  );
};
