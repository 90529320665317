import React from "react";
import ReactHtmlParser from "react-html-parser";

import { EngagementAgreement } from "../../../../../models/meta";
import TermItem from "../TermItem";

import styles from "./AgreementPane.module.scss";

interface AgreementPaneProps {
  engagementAgreement: EngagementAgreement;
}

const AgreementPane: React.FC<AgreementPaneProps> = ({
  engagementAgreement
}: AgreementPaneProps) => {
  const { agreementTerms, footerText, footerTitle, headerText, headerTitle } =
    engagementAgreement;

  return (
    <div className={styles.agreementContainer}>
      <div className={styles.agreementTerms}>
        <TermItem
          collapseItem={{ termTitle: headerTitle, termText: headerText }}
          isDefaultExpanded={true}
        />
        {agreementTerms.map((agreement) => (
          <TermItem collapseItem={agreement} key={agreement.termTitle} />
        ))}
      </div>
      <div className={styles.agreementFooter}>
        <h4>{footerTitle}</h4>
        <p>{ReactHtmlParser(footerText)}</p>
      </div>
    </div>
  );
};

export default AgreementPane;
