import { useTracking } from "react-tracking";

import { MixPanelActions, MixPanelEvent } from "./enums";
import { MixPanelProperties } from "./types";
import { addEventInitialization } from "./utils";

export const useTimeTracking = (
  eventName: MixPanelEvent
): {
  startTrackingTime: () => void;
  endTrackingTime: (properties?: MixPanelProperties) => void;
} => {
  const { trackEvent } = useTracking();

  const startTrackingTime = () => {
    addEventInitialization(eventName);
  };

  const endTrackingTime = (properties?: MixPanelProperties) => {
    trackEvent({
      eventName,
      properties: { action: MixPanelActions.TimeTracking, ...properties }
    });
  };

  return {
    startTrackingTime,
    endTrackingTime
  };
};
