import { Button, Icon } from "arbolus-ui-components";
import clsx from "clsx";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { zip } from "rxjs";

import {
  AdminService,
  CIQError,
  Company,
  ErrorResponse,
  ToasterService
} from "@arbolus-technologies/api";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { CardItem } from "@arbolus-technologies/ui/canopy-panels";
import {
  Header,
  InfiniteScrollWithHeader,
  SearchInput,
  UI_CARDS
} from "@arbolus-technologies/ui/components";
import { SEARCH_DEBOUNCE_TIMEOUT_COMMON } from "@arbolus-technologies/utils";

import styles from "./ClientsList.module.scss";

export interface ClientsListProps {
  userId: string;
  onUpdateListOnAssignClient: () => void;
  adminService?: typeof AdminService;
}

const notification = new ToasterService();
export const ClientsList: React.FC<ClientsListProps> = ({
  userId,
  onUpdateListOnAssignClient,
  adminService = AdminService
}) => {
  const { t } = useTranslation("clientsList");

  const [clients, setClients] = useState<Company[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [isMore, setIsMore] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedCompanyIds, setSelectedCompanyIds] = useState<string[]>([]);
  const dispatch = useDispatch();

  const fetchCandidateClients = useCallback((): void => {
    setIsLoading(true);

    adminService
      .getUserCandidateClients(userId, searchText, clients.length)
      .subscribe(
        ({ items, pagination }) => {
          const nextClients = clients.concat(items as Company[]);

          setClients(nextClients);
          setIsMore(
            items.length ? nextClients.length < pagination.count : false
          );
          setIsLoading(false);
        },
        (err: ErrorResponse<CIQError>) => {
          notification.showError(err.message);
          setIsLoading(false);
        }
      );
  }, [clients, searchText, userId, adminService]);

  useEffect(() => {
    userId && fetchCandidateClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, userId]);

  const searchTermUpdated = useCallback((nextSearchTerm: string) => {
    setSearchText(nextSearchTerm);
    setClients([]);
  }, []);

  const nextItems = useCallback(
    (limit: number): void => {
      if (!isMore || isLoading) {
        return;
      }

      fetchCandidateClients();
    },
    [isMore, isLoading, fetchCandidateClients]
  );

  const onClickItem = (isSelected: boolean, company: Company) => {
    const companyIds = isSelected
      ? selectedCompanyIds.filter((companyId) => companyId !== company.id)
      : [...selectedCompanyIds, company.id];

    setSelectedCompanyIds(companyIds);
  };

  const handleClosePanel = useCallback(
    () => dispatch(PanelStoreActions.closePanel(PanelId.ClientList)),
    [dispatch]
  );

  const handleConfirm = useCallback((): void => {
    setIsLoading(true);
    const addCompaniesPromises = selectedCompanyIds.map((companyId: string) =>
      adminService.addClientManager(companyId, userId)
    );

    zip(...addCompaniesPromises).subscribe(
      () => {
        setIsLoading(false);
        notification.showSuccess(t("clientsAssignedSuccess"));
        onUpdateListOnAssignClient();
        handleClosePanel();
      },
      (error: ErrorResponse<CIQError>) => {
        setIsLoading(false);
        notification.showError(error.message);
      }
    );
  }, [
    handleClosePanel,
    selectedCompanyIds,
    t,
    userId,
    adminService,
    onUpdateListOnAssignClient
  ]);

  const header = useMemo(
    () => (
      <Header classnames={styles.header} title={t("assignClient")} skipMobile>
        <div className={styles.flexGap}>
          <Button
            text={t("cancel")}
            onClick={handleClosePanel}
            type="tertiary"
          />
          <Button
            text={t("confirm")}
            onClick={handleConfirm}
            type="primary"
            disabled={isLoading || selectedCompanyIds.length === 0}
          />
        </div>
      </Header>
    ),
    [handleConfirm, t, isLoading, handleClosePanel, selectedCompanyIds.length]
  );

  const renderer = (company: Company) => {
    const isSelected = selectedCompanyIds.some((id) => id === company.id);
    return (
      <CardItem
        key={company.id}
        title={company.name}
        {...(company.logoUrl
          ? { imageUrl: company.logoUrl }
          : { bigIcon: "topic" })}
        icon={isSelected ? "check" : "add"}
        iconClassnames={isSelected ? styles.selectedIcon : ""}
        classnames={clsx(styles.cardItem, isSelected && styles.selected)}
        onClick={() => onClickItem(isSelected, company)}
      />
    );
  };

  return (
    <InfiniteScrollWithHeader
      header={header}
      items={clients}
      itemHeight={UI_CARDS.CARD_ITEM_HEIGHT()}
      isLoading={isLoading}
      nextItems={nextItems}
      renderer={renderer}
      rendererContainerClassnames={styles.list}
      classnames={styles.infiniteScroll}
      smallMarginBottom
    >
      <div className={styles.clientListBody}>
        <div className={styles.icon}>
          <Icon
            fontSize="20px"
            color={ARBOLUS_COLORS.bColorGrayIcon}
            name="search"
          />
        </div>
        <SearchInput
          onQueryChange={searchTermUpdated}
          placeholder={t("searchClientPlaceholder")}
          containerClassnames={styles.searchInput}
          isDebounced
          debouncingTime={SEARCH_DEBOUNCE_TIMEOUT_COMMON}
          minimumSearchLength={1}
        />
      </div>
    </InfiniteScrollWithHeader>
  );
};
