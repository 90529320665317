import React, { useCallback } from "react";
import { map } from "rxjs/operators";

import {
  CanopyService,
  ExpertInACanopy,
  ExpertInACanopyProfile,
  User,
  mapNonPaginatedToPaginated
} from "@arbolus-technologies/api";

import { UserSelector, UserSelectorProps } from "./UserSelector";

interface DisplayExpertInCanopy
  extends ExpertInACanopy,
    ExpertInACanopyProfile {}

interface CanopyExpertSelectorProps
  extends Omit<UserSelectorProps<DisplayExpertInCanopy>, "getItems"> {
  canopyId: string;
  completed?: boolean;
}

export const CanopyExpertSelector: React.FC<CanopyExpertSelectorProps> = ({
  canopyId,
  completed = true,
  ...props
}) => {
  const clientFilter = (user: Partial<User>, query: string): boolean => {
    const firstName = user.firstName?.toLocaleLowerCase() || "";
    const lastName = user.lastName?.toLocaleLowerCase() || "";
    const queryInput = query.toLocaleLowerCase();
    return firstName.includes(queryInput) || lastName.includes(queryInput);
  };

  const getItems = useCallback(
    () =>
      CanopyService.getExpertsInACanopy(canopyId, completed).pipe(
        map((response) => ({
          ...response,
          items: response.items.map((item) => ({
            ...item,
            ...item.expert,
            id: item.expert.expertId
          }))
        })),
        map(mapNonPaginatedToPaginated)
      ),
    [canopyId, completed]
  );

  return (
    <UserSelector
      {...props}
      isExperts
      clientFilter={clientFilter}
      getItems={getItems}
    />
  );
};
