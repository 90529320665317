import clsx from "clsx";
import React from "react";

import { AvailableSlot } from "@arbolus-technologies/models/project";

import styles from "./SlotsList.module.scss";

interface SlotsListProps {
  availableSlots: AvailableSlot[];
  sliceSlots: number;
  handleSlotSelected: (slotId: number) => void;
  handleCallScheduled?: (slotId: number) => void;
  disableTimeSlots: boolean;
}

export const SlotsList: React.FC<SlotsListProps> = ({
  availableSlots,
  sliceSlots,
  handleSlotSelected,
  handleCallScheduled,
  disableTimeSlots
}) => {
  const handleSlotClick = (availableSlot: AvailableSlot) => {
    availableSlot.slotScheduled && handleCallScheduled
      ? handleCallScheduled(availableSlot.slotId)
      : handleSlotSelected(availableSlot.slotId);
  };

  return (
    <div className={styles.slotsContainer}>
      {availableSlots.slice(0, sliceSlots).map((availableSlot) => (
        <div
          className={clsx(
            styles.slotContainer,
            availableSlot.slotSelected && styles.slotContainerSelected,
            availableSlot.slotScheduled && styles.slotContainerScheduled,
            disableTimeSlots && styles.disabled
          )}
          key={availableSlot.slotId}
          onClick={() => handleSlotClick(availableSlot)}
        >
          <div className={styles.slotLeft}>
            <div className={styles.time}>
              {availableSlot.slotTimeStart} - {availableSlot.slotTimeEnd}
            </div>
            <div className={styles.date} />
            {availableSlot.slotDate}
          </div>
          <div className={styles.slotRight}>
            {availableSlot.slotSelected && (
              <span
                className={clsx(
                  "material-symbols-sharp",
                  styles.icon,
                  styles.iconCheck
                )}
              >
                check
              </span>
            )}
            {availableSlot.slotScheduled && (
              <span
                className={clsx(
                  "material-symbols-sharp",
                  styles.icon,
                  styles.iconCall,
                  disableTimeSlots && styles.iconCallDisabled
                )}
              >
                call
              </span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
