import clsx from "clsx";
import dompurify from "dompurify";
import React, { useState } from "react";
import { Button, Card, CardBody, Collapse } from "reactstrap";

import styles from "./CollapseComponent.module.scss";

interface CollapseComponentProps {
  title: string;
  text: string | string[];
  isDefaultExpanded?: boolean;
  containerStyle?: string;
  titleStyle?: string;
  textStyle?: string;
}

export const CollapseComponent: React.FC<CollapseComponentProps> = ({
  title,
  text,
  isDefaultExpanded = false,
  containerStyle,
  titleStyle,
  textStyle
}) => {
  const [isExpanded, setIsExpanded] = useState(!!isDefaultExpanded);

  const sanitizer = dompurify.sanitize;

  return (
    <div
      className={clsx(
        styles.collapseContainer,
        containerStyle ? containerStyle : null
      )}
    >
      <div className="accordion">
        <Button
          className={clsx(
            `${isExpanded && "btn-success"}`,
            titleStyle ? titleStyle : null
          )}
          onClick={(): void => setIsExpanded(!isExpanded)}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizer(title).replace(/href/g, "target='_blank' href")
            }}
          />
          <span className="ciq-icon ciq-chevron-down" />
        </Button>
        <Collapse isOpen={isExpanded}>
          <Card>
            <CardBody>
              {text instanceof Array ? (
                text.map((e) => <p key={e}>{e}</p>)
              ) : (
                <p
                  className={clsx(textStyle ? textStyle : null)}
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(text).replace(
                      /href/g,
                      "target='_blank' href"
                    )
                  }}
                />
              )}
            </CardBody>
          </Card>
        </Collapse>
      </div>
    </div>
  );
};
