import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  AddCreateVendorsPanel,
  AddCreateVendorsPanelData,
  ConfirmedVendors
} from "@arbolus-technologies/features/common";
import { CustomerSurveyCompany } from "@arbolus-technologies/models/common";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { Button, Drawer } from "@arbolus-technologies/ui/components";

import {
  Options,
  VendorsCheckboxes
} from "../../components/VendorsCheckboxes/VendorsCheckboxes";

export function VendorsSelection({
  title,
  options,
  selectedCompaniesIds,
  onExtraVendorsConfirmed
}: {
  title: string;
  options: Options;
  selectedCompaniesIds: string[];
  onExtraVendorsConfirmed: (vendors: CustomerSurveyCompany[]) => void;
}) {
  const { t } = useTranslation("expertSurvey");
  const dispatch = useDispatch();

  function openPanel() {
    dispatch(
      PanelStoreActions.openPanel<AddCreateVendorsPanelData>(
        PanelId.AddVendor,
        {
          vendorIds: selectedCompaniesIds
        }
      )
    );
  }

  function handleVendorsConfirmed(vendors: ConfirmedVendors) {
    onExtraVendorsConfirmed(
      vendors.map((vendor) => ({
        id: vendor.vendorCompanyId,
        name: vendor.vendorCompanyName,
        visible: true
      }))
    );
  }

  return (
    <Drawer title={title} openByDefault={true} disableCollapse={true}>
      <VendorsCheckboxes options={options} />
      <Button
        text={t("alternativesAddButton")}
        endIcon="add"
        type="tertiary"
        onClick={openPanel}
      />

      <AddCreateVendorsPanel onVendorsConfirmed={handleVendorsConfirmed} />
    </Drawer>
  );
}
