/* eslint-disable react/no-array-index-key */
import clsx from "clsx";
import { ContentBlock, ContentState, EditorBlock } from "draft-js";
import React, { useEffect, useRef, useState } from "react";
import { Button, PopoverBody, UncontrolledPopover } from "reactstrap";

import { TranscriptMode } from "../../../../../contexts/transcript/TranscriptContext";
import { Speaker } from "../../../../../models/view/transcript";
import { DraftJSUtilService } from "../../../../../services";
import { CIQAvatar } from "../../../../app/components";
import SpeakerSearch from "../speakerSearch/SpeakerSearch";
import MonologueWrapper from "./MonologueWrapper";

export interface MonologueProps {
  mode: TranscriptMode;
  hasVideoURL: boolean;
  monologueStart: string;
  isSpeakerChangeLoading: boolean;
  speaker: Speaker;
  speakers: Speaker[];
  onSpeakerItemStartPlay: () => void;
  onSpeakerAssign: (newSpeaker: Speaker) => void;
  onSpeakerAdd: (newSpeaker: Speaker) => void;
  onFocusChange: (isFocus: boolean) => void;
}

export interface MonologueBlockProps<T> {
  block: ContentBlock;
  contentState: ContentState;
  blockProps: T;
}

const MonologueBlock: React.FC<MonologueBlockProps<MonologueProps>> = (
  props
) => {
  const { blockProps, block, contentState } = props;
  const {
    mode,
    monologueStart,
    hasVideoURL,
    onSpeakerItemStartPlay,
    speaker,
    speakers,
    isSpeakerChangeLoading,
    onSpeakerAdd,
    onSpeakerAssign,
    onFocusChange
  } = blockProps;

  const [isPopOverOpen, setIsPopOverOpen] = useState(false);
  const viewingSpeakers = useRef<Speaker[]>([]);

  const monologueKey = block.getKey();
  const isHighlightMode = mode === TranscriptMode.HIGHLIGHTS;
  const isHighlightsExist = DraftJSUtilService.isHighlightsExist(
    block,
    contentState
  );

  useEffect(() => {
    viewingSpeakers.current = speakers.filter(({ userId, name }) =>
      speaker.userId ? userId !== speaker.userId : name !== speaker.name
    );
  }, [speaker, speakers]);

  return (
    <div className="speaker-item">
      <div
        onClick={(): void => onFocusChange(false)}
        contentEditable={false}
        className={clsx("speaker-info", {
          loading: isSpeakerChangeLoading
        })}
      >
        {speaker.edited ? (
          <CIQAvatar
            id={`speaker_item_${monologueKey}`}
            onClick={(): void => setIsPopOverOpen(true)}
            username={speaker.userId ? speaker.name : ""}
            profileImageUrl={speaker.profileImageUrl}
            fallBackImageClass={clsx("fallback-avatar", {
              newSpeaker: !speaker.userId
            })}
          />
        ) : (
          <Button
            id={`speaker_item_${monologueKey}`}
            size="sm"
            color="secondary"
            type="button"
            onClick={(): void => setIsPopOverOpen(true)}
          >
            <span className="ciq-icon ciq-plus" />
          </Button>
        )}

        <p onClick={(): void => setIsPopOverOpen(true)}>{speaker.name}</p>
        {hasVideoURL && (
          <span
            className="ciq-icon ciq-component-icon-ic-play"
            onClick={onSpeakerItemStartPlay}
          />
        )}
        <div className="speaker-time">
          <span>{monologueStart}</span>
        </div>
      </div>

      <div className="monologue-body">
        <div
          className={clsx("speaking-text", {
            "no-selection": isHighlightMode
          })}
          contentEditable={false}
          onClick={(): void => onFocusChange(true)}
        >
          <MonologueWrapper block={block}>
            <EditorBlock {...props} />
          </MonologueWrapper>
        </div>
        <div className="monologue-stats" contentEditable={false}>
          {isHighlightsExist && (
            <span className="ciq-icon ciq-component-icon-ic-bookmarks" />
          )}
        </div>
      </div>

      <UncontrolledPopover
        hideArrow
        isOpen={isPopOverOpen}
        toggle={(): void => setIsPopOverOpen(!isPopOverOpen)}
        target={`speaker_item_${monologueKey}`}
        placement="bottom-start"
        trigger="legacy"
        className="speaker-search-popover"
      >
        <PopoverBody>
          <SpeakerSearch
            currentSpeakerName={speaker.name}
            speakers={viewingSpeakers.current}
            onSpeakerAssign={onSpeakerAssign}
            onSpeakerAdd={onSpeakerAdd}
            togglePopover={setIsPopOverOpen}
          />
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  );
};
export default MonologueBlock;
