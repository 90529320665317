import { Button } from "arbolus-ui-components";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  CALL_STATUSES,
  STATUSES,
  SelectedFilter,
  SelectedFilterEnum
} from "@arbolus-technologies/api";
import { InternalSlidePanel } from "@arbolus-technologies/features/common";
import { ProjectAnglesSelector } from "@arbolus-technologies/stores/project-angles-store";
import { FilterType, Header, Tabs } from "@arbolus-technologies/ui/components";

import { CardItem } from "./CardItem/CardItem";

import styles from "./ScheduleSlotsFilter.module.scss";

export interface ScheduleSlotsFilterProps {
  newFilters: FilterType[];
  setNewFilters: (
    newFilters: FilterType[] | ((currentFilters: FilterType[]) => FilterType[])
  ) => void;
  isOpen: boolean;
  onApply: () => void;
  onCancel: () => void;
}

export const ScheduleSlotsFilter: React.FC<ScheduleSlotsFilterProps> = ({
  newFilters,
  setNewFilters,
  isOpen,
  onApply,
  onCancel
}) => {
  const { t } = useTranslation("scheduleTabFilters");

  const anglesCards = useSelector(ProjectAnglesSelector.anglesCardsSelector());

  const handleFilterSelect = useCallback(
    (filterKey: keyof SelectedFilter, filterId: string) => {
      setNewFilters((currentFilters: FilterType[]) => {
        const isAlreadySelected = currentFilters.find(
          (filter) => filter.id === filterId
        );

        if (isAlreadySelected) {
          return currentFilters.filter((filter) => filter.id !== filterId);
        }

        return [...currentFilters, { key: filterKey, id: filterId }];
      });
    },
    [setNewFilters]
  );

  const tabs = useMemo(
    () => ({
      Status: {
        title: t("status"),
        component: () => (
          <CardItem
            type={SelectedFilterEnum.STATUSES}
            statuses={[STATUSES.CANDIDATE, STATUSES.ACCEPT]}
            onClick={handleFilterSelect}
            filters={newFilters
              .filter((item) => item.key === SelectedFilterEnum.STATUSES)
              .map((item) => item.id)}
          />
        )
      },
      Angles: {
        title: t("angles"),
        component: () => (
          <CardItem
            type={SelectedFilterEnum.ANGLE_IDS}
            angles={anglesCards}
            onClick={handleFilterSelect}
            filters={newFilters
              .filter((item) => item.key === SelectedFilterEnum.ANGLE_IDS)
              .map((item) => item.id)}
          />
        )
      },
      callStatuses: {
        title: t("callStatus"),
        component: () => (
          <CardItem
            type={SelectedFilterEnum.CALL_STATUSES}
            statuses={Object.values(CALL_STATUSES)}
            onClick={handleFilterSelect}
            filters={newFilters
              .filter((item) => item.key === SelectedFilterEnum.CALL_STATUSES)
              .map((item) => item.id)}
          />
        )
      }
    }),
    [anglesCards, handleFilterSelect, newFilters, t]
  );

  return (
    <InternalSlidePanel
      customCloseRequest={onCancel}
      isOpen={isOpen}
      hideHeader
    >
      <div className={styles.filterSlidePanelContainer}>
        <Header
          classnames={styles.header}
          title={t("filterTimeslots")}
          skipMobile
        >
          <div className={styles.actionButtons}>
            <Button text={t("cancel")} type="tertiary" onClick={onCancel} />
            <Button text={t("apply")} onClick={onApply} />
          </div>
        </Header>
        <Tabs tabMapping={tabs} />
      </div>
    </InternalSlidePanel>
  );
};
