import clsx from "clsx";
import React from "react";

import styles from "./BackComponent.module.scss";

interface BackComponentProps {
  onClick: () => void;
  title: string;
  isMobile?: boolean;
  hidden?: boolean;
}

export const BackComponent: React.FC<BackComponentProps> = ({
  onClick,
  isMobile,
  hidden,
  title
}) => (
  <div className={clsx(hidden ? styles.hidden : styles.buttonContainer)}>
    <button
      className={clsx(styles.button, isMobile && styles.isMobile)}
      onClick={onClick}
    >
      <span className="ciq-chevron-left" />
      <span>{title}</span>
    </button>
  </div>
);
