import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";

import { Optional, useDebounce } from "@arbolus-technologies/utils";

import styles from "./TableTextFilter.module.scss";

interface CIQTableTextFilterProps {
  displayName: string;
  debounceTime?: number;
  minLength?: number;
  onTextFilterChange: (text: Optional<string>) => void;
}

export const CIQTableTextFilter: React.FC<CIQTableTextFilterProps> = ({
  displayName,
  debounceTime = 500,
  minLength = 3,
  onTextFilterChange
}: CIQTableTextFilterProps) => {
  const [currentTextFilter, setCurrentTextFilter] =
    useState<Optional<string>>();
  const debouncedTextFilter = useDebounce(currentTextFilter, debounceTime);
  useEffect(() => {
    if (debouncedTextFilter && debouncedTextFilter.length < minLength) {
      return;
    }

    onTextFilterChange(debouncedTextFilter);
  }, [onTextFilterChange, debouncedTextFilter, minLength]);

  const changeFilter = (text: string) => {
    if (text.length === 0) {
      setCurrentTextFilter(undefined);
    } else {
      setCurrentTextFilter(text);
    }
  };

  return (
    <div className="d-flex flex-row align-items-center">
      <Input
        type="text"
        placeholder={displayName}
        value={currentTextFilter || ""}
        onChange={(event) => changeFilter(event.target.value)}
        className={styles.input}
      />
      <span
        onClick={() => changeFilter("")}
        className={clsx({
          [styles.active]: !!currentTextFilter,
          [styles.inactive]: !currentTextFilter
        })}
      >
        <i
          className={clsx("ml-auto", styles.searchIcon, "ciq-icon", {
            "ciq-close": !!currentTextFilter,
            "ciq-search": !currentTextFilter
          })}
        />
      </span>
    </div>
  );
};
