import clsx from "clsx";
import React from "react";

import styles from "./NumberCircle.module.scss";

interface NumberCircleProps {
  number: number;
  classnames?: string;
  color?: "purple" | "grey";
  isBold?: boolean;
  includePlus?: boolean;
}
export const NumberCircle: React.FC<NumberCircleProps> = ({
  number,
  classnames,
  isBold = false,
  includePlus,
  color = "purple"
}) => (
  <div
    data-testid="numberCircle"
    className={clsx(
      isBold ? styles.circleBold : styles.circle,
      classnames,
      styles[color]
    )}
  >
    <span>{includePlus ? "+" + number : number}</span>
  </div>
);
