import clsx from "clsx";
import React from "react";

import { AngleModel } from "@arbolus-technologies/models/common";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { IconWithText } from "@arbolus-technologies/ui/components";

import styles from "./FilterTypeComponents.module.scss";

interface FilterByAnglesProps {
  angle?: AngleModel;
  isCard?: boolean;
}

export const FilterByAngles: React.FC<FilterByAnglesProps> = ({
  angle,
  isCard
}) =>
  angle ? (
    <IconWithText
      text={{ content: angle.title, color: ARBOLUS_COLORS.bColorBaseDark }}
      icon={{
        name: "zoom_in_map",
        color: ARBOLUS_COLORS.bColorBaseDark,
        customStyle: { backgroundColor: angle.color },
        customClasses: clsx(styles.icon, styles.angleIcon)
      }}
      customClasses={isCard ? styles.card : styles.badge}
    />
  ) : null;
