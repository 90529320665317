import { replace } from "connected-react-router";
import React from "react";
import { useTranslation } from "react-i18next";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { IconWithBackground } from "@arbolus-technologies/ui/components";

import { EXPERT_PROFILE_EDIT_INSIGHTS_ROUTE } from "@arbolus-technologies/routes";
import { Button } from "arbolus-ui-components";
import { useDispatch } from "react-redux";
import styles from "./DoneStep.module.scss";

export function DoneStep() {
  const { t } = useTranslation("expertSurvey");
  const dispatch = useDispatch();

  return (
    <div className={styles.doneStep}>
      <IconWithBackground
        color={ARBOLUS_COLORS.bColorBaseWhite}
        backgroundColor={ARBOLUS_COLORS.bColorSecondaryGreen}
        name="done"
        size="big"
        fontSize="32px"
        circular={true}
      />
      <span className={styles.title}>{t("doneStepTitle")}</span>
      <span className={styles.subtitle}>{t("doneStepSubtitle")}</span>
      <Button
        text={t("goToMyProfile")}
        onClick={() => dispatch(replace(EXPERT_PROFILE_EDIT_INSIGHTS_ROUTE))}
      />
    </div>
  );
}
