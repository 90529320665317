import clsx from "clsx";
import { replace } from "connected-react-router";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Media from "react-media";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { EMPTY, zip } from "rxjs";
import { switchMap } from "rxjs/operators";
import SimpleBar from "simplebar-react";

import {
  DefaultToasterService,
  ProjectExpertAvailability
} from "@arbolus-technologies/api";
import {
  DO_NOT_CONTACT_STATUS,
  REFERRAL_SUB_STATE
} from "@arbolus-technologies/models/common";
import { Answer, Compliance } from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { Loader } from "@arbolus-technologies/ui/components";
import { AntDHeader } from "@arbolus-technologies/ui/layout";

import { PROJECTS } from "../../../../constants/navigation/authRoutes";
import { APP_DEVICE_MEDIA_QUERIES } from "../../../../constants/ui";
import {
  ApiNonPaginatedResponse,
  CIQError,
  ErrorResponse
} from "../../../../models/api";
import { AgreementType, EngagementAgreement } from "../../../../models/meta";
import { ProjectApplication } from "../../../../models/project";
import {
  ExpertService,
  MetaService,
  ProjectService
} from "../../../../services";
import { NavBarStoreActions } from "../../../app/store";
import { ProjectBrief } from "../../components/applyProject/projectBrief/ProjectBrief";
import { ProjectApplicationStep } from "./ProjectApplicationStep";

import styles from "./ProjectApplicationPage.module.scss";

const notificationService = DefaultToasterService;

export const ProjectApplicationPage: React.FC<
  RouteComponentProps<{
    projectId: string;
  }>
> = ({ match, history }) => {
  const { t } = useTranslation("projectApplication");
  const dispatch = useDispatch();

  const { projectId } = match.params;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isUpdateLoading, setIsUpdateLoading] = useState<boolean>(false);
  const [showBriefScreen, setShowBriefScreen] = useState<boolean>(true);
  const [hasScreeningQuestions, setHasScreeningQuestions] =
    useState<boolean>(false);
  const [hasComplianceQuestions, setHasComplianceQuestions] =
    useState<boolean>(false);

  const [projectApplicationBrief, setProjectApplicationBrief] =
    useState<ProjectApplication>({} as ProjectApplication);
  const [expertAnswers, setExpertAnswers] = useState<Answer[]>([]);
  const [expertComplianceAnswers, setExpertComplianceAnswers] = useState<
    Compliance[]
  >([]);
  const [projectExpertAvailability, setProjectExpertAvailability] =
    useState<ProjectExpertAvailability>({} as ProjectExpertAvailability);
  const [engagementAgreement, setEngagementAgreement] =
    useState<EngagementAgreement>({} as EngagementAgreement);

  const loggedInExpert = useSelector(CacheSelector.loggedInUser());
  const expertId = loggedInExpert.expertId ?? "";
  const isDnc = loggedInExpert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC;

  const scrollRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (isDnc) {
      goToProjects();
      notificationService.showError(t("projectNotAvailableError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDnc]);

  const goToProjects = () => {
    dispatch(replace(PROJECTS));
  };

  const updateExpertNewApplicationsCount = () => {
    dispatch(NavBarStoreActions.navbarUpdateExpertNewApplicationsCount());
  };

  useEffect(function fetchProjectApplication() {
    ProjectService.getExpertApplicationBrief(projectId)
      .pipe(
        switchMap((response) => {
          setProjectApplicationBrief(response);
          const {
            applicationStatus,
            complianceAnswered,
            questionsAnswered,
            expertAvailabilitySlotsSelected
          } = response.referral;

          const isApplied = applicationStatus === REFERRAL_SUB_STATE.ACCEPT;

          const noStepsCompleted =
            !complianceAnswered &&
            !questionsAnswered &&
            !expertAvailabilitySlotsSelected;

          setShowBriefScreen(isApplied || noStepsCompleted);

          if (applicationStatus === REFERRAL_SUB_STATE.REJECT) {
            history.push(PROJECTS);
            notificationService.showError(t("alreadyDeclined"));
          } else if (applicationStatus !== REFERRAL_SUB_STATE.ACCEPT) {
            return zip(
              ExpertService.getAnswers(expertId, projectId),
              ExpertService.getComplianceAnswers(expertId, projectId),
              ExpertService.getProjectExpertAvailability(projectId, expertId),
              MetaService.getEngagementAgreement(
                AgreementType.EXPERT,
                undefined,
                projectId
              )
            );
          }
          return EMPTY;
        })
      )
      .subscribe(
        (
          value: [
            ApiNonPaginatedResponse<Answer>,
            ApiNonPaginatedResponse<Compliance>,
            ProjectExpertAvailability,
            EngagementAgreement
          ]
        ) => {
          const [
            answers,
            complianceQuestions,
            projectExpertAvailability,
            engagementAgreement
          ] = value;

          setExpertAnswers(answers.items);
          setExpertComplianceAnswers(complianceQuestions.items);
          setProjectExpertAvailability(projectExpertAvailability);
          setEngagementAgreement(engagementAgreement);
          setHasScreeningQuestions(!!answers.items.length);
          setHasComplianceQuestions(!!complianceQuestions.items.length);
        },
        () => {
          notificationService.showError(t("failedError"));
          goToProjects();
        },
        () => {
          setIsLoading(false);
        }
      );
  }, []);

  const declineApplication = (): void => {
    const { id: referralId } = projectApplicationBrief.referral;
    const { id: agreementId } = engagementAgreement;

    setIsUpdateLoading(true);
    ProjectService.acceptAgreement(
      projectId,
      referralId,
      agreementId,
      REFERRAL_SUB_STATE.REJECT
    ).subscribe(
      () => {
        setIsUpdateLoading(false);
        notificationService.showSuccess(t("declinedSuccess"));
        goToProjects();
        updateExpertNewApplicationsCount();
      },
      (error: ErrorResponse<CIQError>) => {
        setIsUpdateLoading(false);
        notificationService.showError(error.message);
      }
    );
  };

  const scrollToTop = (): void => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  };

  const hideBriefScreen = (): void => {
    setShowBriefScreen(false);
    scrollToTop();
  };

  if (isLoading) return <Loader isFull />;

  const { description, referral } = projectApplicationBrief;

  return (
    <Media queries={APP_DEVICE_MEDIA_QUERIES}>
      <div className={clsx(styles.projectApplicationPage, "page-content")}>
        {showBriefScreen && (
          <>
            <AntDHeader
              title={`Project: ${projectApplicationBrief.name}`}
              backLink={PROJECTS}
            />
          </>
        )}
        <div className="page-content-body bg-white">
          <SimpleBar
            className={`simplebar-light`}
            style={{
              minHeight: `calc( 100vh - ${showBriefScreen ? "80px" : "0px"})`,
              maxHeight: `calc( 100vh - ${showBriefScreen ? "80px" : "0px"})`,
              overflowX: "hidden"
            }}
            scrollableNodeProps={{ ref: scrollRef }}
          >
            {showBriefScreen ? (
              <ProjectBrief
                isLoading={isUpdateLoading}
                projectDescription={description}
                applicationStatus={referral.applicationStatus}
                onDecline={declineApplication}
                onApply={hideBriefScreen}
                hasScreeningQuestions={hasScreeningQuestions}
                hasComplianceQuestions={hasComplianceQuestions}
                projectId={projectId}
              />
            ) : (
              <ProjectApplicationStep
                projectId={projectId}
                expertId={expertId}
                expertAnswers={expertAnswers}
                setExpertAnswers={setExpertAnswers}
                expertComplianceAnswers={expertComplianceAnswers}
                setExpertComplianceAnswers={setExpertComplianceAnswers}
                projectApplicationBrief={projectApplicationBrief}
                setProjectApplicationBrief={setProjectApplicationBrief}
                setShowBriefScreen={setShowBriefScreen}
                projectExpertAvailability={projectExpertAvailability}
                setProjectExpertAvailability={setProjectExpertAvailability}
                updateExpertNewApplicationsCount={
                  updateExpertNewApplicationsCount
                }
                engagementAgreement={engagementAgreement}
                hasScreeningQuestions={hasScreeningQuestions}
                hasComplianceQuestions={hasComplianceQuestions}
                isUpdateLoading={isUpdateLoading}
                setIsUpdateLoading={setIsUpdateLoading}
                goToProjects={goToProjects}
                scrollRef={scrollRef}
              />
            )}
          </SimpleBar>
        </div>
      </div>
    </Media>
  );
};
