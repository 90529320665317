import clsx from "clsx";
import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import styles from "./InformModal.module.scss";

interface InformModalProps {
  toggleModal: boolean;
  messageTitle: string;
  warningMessage: string;
  hideModal: () => void;
}

/**
 * @deprecated
 * use ArbolusModal from @arbolus-technologies/libs/ui/components
 */

export const InformModal: React.FC<InformModalProps> = ({
  toggleModal,
  messageTitle,
  warningMessage,
  hideModal
}) => (
  <Modal
    isOpen={toggleModal}
    centered
    toggle={hideModal}
    className={styles.modal}
  >
    <div className={styles.wrapperContent}>
      <ModalHeader
        close={
          <div
            className={clsx("ciq-icon ciq-close", styles.closeButton)}
            onClick={hideModal}
          />
        }
        className={styles.titleContainer}
      >
        {messageTitle}
      </ModalHeader>
      <ModalBody className={styles.bodyContainer}>{warningMessage}</ModalBody>
    </div>
  </Modal>
);
