/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import { Carousel, CarouselIndicators, CarouselItem } from "reactstrap";

import { CANDIDATE_ENDORSEMENT_CAROUSEL_SLIDE_INTERVAL } from "../../../../../constants/timer";
import { Endorsement } from "../../../../../models/expert";
import { UtilsService } from "../../../../../services";
import { CIQAvatar } from "../../../../app/components";

interface ExpertEndorsementsProps {
  endorsements?: Endorsement[];
}

interface ExpertEndorsementsState {
  endorsementIndex: number;
  endorsementAnimating: boolean;
}

class ExpertEndorsements extends Component<
  ExpertEndorsementsProps,
  ExpertEndorsementsState
> {
  constructor(props: ExpertEndorsementsProps) {
    super(props);

    this.state = {
      endorsementAnimating: false,
      endorsementIndex: 0
    };
  }

  goToIndex = (newIndex: number): void => {
    const { endorsementAnimating } = this.state;
    if (endorsementAnimating) return;
    this.setState({ endorsementIndex: newIndex });
  };

  next = (): void => {
    const { endorsements } = this.props;
    const { endorsementAnimating, endorsementIndex } = this.state;
    if (endorsementAnimating) return;
    const nextIndex =
      endorsementIndex === endorsements!.length - 1 ? 0 : endorsementIndex + 1;
    this.setState({ endorsementIndex: nextIndex });
  };

  previous = (): void => {
    const { endorsements } = this.props;
    const { endorsementAnimating, endorsementIndex } = this.state;
    if (endorsementAnimating) return;
    const nextIndex =
      endorsementIndex === 0 ? endorsements!.length - 1 : endorsementIndex - 1;
    this.setState({ endorsementIndex: nextIndex });
  };

  render(): JSX.Element {
    const { endorsementIndex } = this.state;
    const { endorsements } = this.props;

    return (
      <section>
        <div className="expert-endorsements">
          <Carousel
            activeIndex={endorsementIndex}
            next={this.next}
            slide
            interval={CANDIDATE_ENDORSEMENT_CAROUSEL_SLIDE_INTERVAL}
            previous={this.previous}
            style={{ width: "100%" }}
          >
            <CarouselIndicators
              items={endorsements!}
              activeIndex={endorsementIndex}
              onClickHandler={this.goToIndex}
            />
            {endorsements!.map((endorsement) => (
              <CarouselItem
                key={endorsement.endorserId}
                onExiting={(): void =>
                  this.setState({ endorsementAnimating: true })
                }
                onExited={(): void =>
                  this.setState({ endorsementAnimating: false })
                }
              >
                <div className="endorsement-item" style={{ width: "100%" }}>
                  <CIQAvatar
                    username={UtilsService.displayUserName(
                      endorsement.endorser
                    )}
                    profileImageUrl={endorsement.endorser.profileImageUrl}
                  />
                  <h3>{UtilsService.displayUserName(endorsement.endorser)}</h3>
                  <p>{endorsement.endorser.title}</p>
                  <blockquote>{`"${endorsement.quote}"`}</blockquote>
                </div>
              </CarouselItem>
            ))}
          </Carousel>
        </div>
      </section>
    );
  }
}

export default ExpertEndorsements;
