/* spell-checker: disable */

export const NUMBER_FORMAT_LOCALE = "en-US";

export const APP_DATE_FORMAT_WITH_DAY = "ddd, DD MMM YYYY";
export const APP_DATE_FORMAT = "DD MMM YYYY";
export const APP_DATE_CHAT_EVENT_START_FORMAT = "ddd DD MMM h:mma";
export const APP_DATE_CHAT_EVENT_END_FORMAT = "h:mma";
export const APP_EVENT_AND_HISTORY_FORMAT = "dd MMM, yyyy";

export const EXPERT_CREATED_FORMAT = "DD MMM, yyyy";
export const EXPERT_WORK_TIME_FORMAT = "MMM yyyy";

export const PROJECT_EVENT_TIME_FORMAT = "h:mmaa";
export const PROJECT_PAGE_CARD_DATE_FORMAT = "D MMM";
export const PROJECT_TIMELINE_FORMAT = "DD MMM yyyy";
export const PROJECT_EVENT_SIDE_PANEL_VIEW_DATE_FORMAT = "ddd, DD MMM";
export const PROJECT_CALENDAR_MONTH_HEADER_FORMAT = "MMMM YYYY";
export const PROJECT_CALENDAR_DATE_HEADER_FORMAT = "ddd, D MMM";
export const PROJECT_EVENT_SIDE_PANEL_FORMAT = "h:mma";
export const PROJECT_EVENT_SIDE_PANEL_START_TIME_FORMAT = "h";
export const PROJECT_EVENT_SIDE_PANEL_START_TIME_FORMAT_WITH_MINUTES = "h:mm";

export const CALENDAR_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm";
export const CHAT_EVENT_FORMAT = "ddd D MMM hh:mm a";
export const CHAT_EVENT_FORMAT_SHORT = "hh:mm a";
export const CLIENT_ACCOUNT_SINCE_FORMAT = "MMMM YYYY";
export const COMPLIANCE_UPDATE_FORMAT = "DD MMM YYYY - hh:mm A";
export const DNC_FORMAT = "D MMM YYYY - hh:mm a";
export const DATE_SHORT_MONTH_FORMAT = "DD MMM";
export const DATE_TIME_FORMAT = "YYYY-MM-DD";
export const EVENT_DATE_TIME_FORMAT = "YYYY/MM/DD HH:mm:ss";
export const FULL_MONTH_YEAR_FORMAT = "MMMM yyyy";
export const INBOX_NOTIFICATION_FORMAT = "DD MMM, h:mma";
export const SLOT_EDIT_DATE_FORMAT = "EEEE, dd MMMM yyyy";

export const TRANSACTION_FORMAT = "DD MMM ‘YY";
export const TRANSACTION_DETAILS_FORMAT = "DD MMM, YYYY h:mma";
export const TRANSACTION_DETAILS_FORMAT_NOTES = "DD MMM, YYYY - h:mma";
export const TRANSACTION_DETAILS_FORMAT_CREATED = "DD MMM, YYYY";
export const YEAR_MONTH_FORMAT = "yyyy-MM-DD";

export const CANOPY_DATE_TIME_FORMAT = "DD MMM YYYY h:mm A";

export const EXPERT_DISCOVER_TIME_FORMAT = "MMM YYYY";
export const EXPERT_WORK_HISTORY_TIME_FORMAT = "MMM YYYY";

export const PIPELINE_UPDATE_DATE_FORMAT = "DD MMM YYYY - HH:mm";
