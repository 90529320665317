import dompurify from "dompurify";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { TRANSCRIPT_EDIT_MODE } from "@arbolus-technologies/models/common";
import {
  DeleteModal,
  EditTranscript
} from "@arbolus-technologies/ui/components";
import { highlightWords } from "@arbolus-technologies/utils";

import styles from "./Transcript.module.scss";

interface TranscriptProps {
  wordsToHighlight?: string[];
  transcriptState: TRANSCRIPT_EDIT_MODE;
  internalTranscript: string;
  isDrawerOpen: boolean;
  resetModal: boolean;
  resetTranscript: () => void;
  saveTranscript: (content: string) => void;
  handleSetTranscriptState: (mode: TRANSCRIPT_EDIT_MODE) => void;
  handleSetResetModal: (value: boolean) => void;
  handleTranscriptClick: () => void;
}

export const Transcript: React.FC<TranscriptProps> = ({
  wordsToHighlight = [],
  transcriptState,
  internalTranscript,
  isDrawerOpen,
  resetModal,
  resetTranscript,
  saveTranscript,
  handleSetTranscriptState,
  handleSetResetModal,
  handleTranscriptClick
}) => {
  const { t } = useTranslation("answer");
  const sanitizer = dompurify.sanitize;
  const [highlightTranscript, setHighlightTranscript] = useState<string>("");

  useEffect(() => {
    if (
      typeof internalTranscript === "undefined" ||
      internalTranscript === null ||
      internalTranscript === ""
    ) {
      setHighlightTranscript(t("notAvailable"));
    } else {
      const highlightedText = highlightWords({
        tags: wordsToHighlight,
        text: internalTranscript
      });
      setHighlightTranscript(highlightedText);
    }
  }, [internalTranscript, t, wordsToHighlight]);

  return (
    <>
      <DeleteModal
        toggleModal={resetModal}
        messageTitle={t("resetTitle")}
        warningMessage={t("resetWarning")}
        deleteActionText={t("confirmReset")}
        cancelActionText={t("cancelReset")}
        onCancel={() => handleSetResetModal(false)}
        onConfirm={() => resetTranscript()}
      />
      {transcriptState === TRANSCRIPT_EDIT_MODE.VIEW && (
        <div
          dangerouslySetInnerHTML={{
            __html: sanitizer(highlightTranscript)
          }}
          className={
            isDrawerOpen ? styles.transcriptOpen : styles.transcriptClosed
          }
          onClick={handleTranscriptClick}
        />
      )}
      {transcriptState === TRANSCRIPT_EDIT_MODE.EDIT && (
        <EditTranscript
          saveTranscript={(content) => saveTranscript(content)}
          content={internalTranscript || ""}
          cancelTranscript={() =>
            handleSetTranscriptState(TRANSCRIPT_EDIT_MODE.VIEW)
          }
          disabled={false}
        />
      )}
    </>
  );
};
