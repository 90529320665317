export const types = ["webm", "mp4", "mpeg", "quicktime"];
export const codecs = [
  "h264",
  "h.264",
  "avc1",
  "mpeg",
  "av1",
  "h265",
  "h.265",
  "vp9",
  "vp9.0",
  "vp8",
  "vp8.0"
];

export const getSupportedVideoType = (): string | null => {
  const isSupported = MediaRecorder.isTypeSupported;
  for (let i = 0; i < types.length; i++) {
    const mimeType = `video/${types[i]}`;
    for (let e = 0; e < codecs.length; e++) {
      const variations = [
        `${mimeType};codecs=${codecs[e]}`,
        `${mimeType};codecs:${codecs[e]}`,
        `${mimeType};codecs=${codecs[e].toUpperCase()}`,
        `${mimeType};codecs:${codecs[e].toUpperCase()}`
      ];
      for (let o = 0; o < variations.length; o++) {
        if (isSupported(variations[o])) {
          return variations[o];
        }
      }
    }
  }
  return null;
};
