import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  CIQError,
  ChatService,
  DefaultToasterService,
  ErrorResponse,
  SORT_DIRECTION
} from "@arbolus-technologies/api";
import {
  AngleChatData,
  ChatData,
  ChatType,
  ChatUserRole
} from "@arbolus-technologies/models/project";
import { ProjectNxStoreActions } from "@arbolus-technologies/stores/project";

import { getChatType } from "../../Contexts/ChatContext/ChatContextUtils";

export type ChangeChatFunction = (chat?: ChatData | AngleChatData) => void;

export function useChangeChat(
  projectId: string,
  chatSenderRole: ChatUserRole,
  currentChat: ChatData | AngleChatData | undefined,
  setCurrentChat: (chat?: ChatData | AngleChatData) => void,
  setChatType: (chatType?: ChatType) => void,
  markAsRead: Function
): ChangeChatFunction {
  const dispatch = useDispatch();

  const updateLastActiveChat = useCallback(
    (projectId: string, chatId: string) => {
      ChatService.updateLastActiveChat(projectId, chatId).subscribe(
        () => {
          markAsRead(chatId);
        },
        (error: ErrorResponse<CIQError>) => {
          DefaultToasterService.showApiErrors(error);
        }
      );
    },
    [markAsRead]
  );

  const changeChat = useCallback<ChangeChatFunction>(
    (chat) => {
      if (chat) {
        const chatType = getChatType(chatSenderRole, chat.chatType);
        if (chatType) {
          setChatType(chatType);
        }
      }
      if (chat?.id === currentChat?.id) return;
      setCurrentChat(chat);

      // In case we reset chat (mobile view) we don't need to get messages
      if (!chat?.id) return;

      updateLastActiveChat(projectId, chat.id);

      // Fetch messages when changing chat and scroll
      dispatch(
        ProjectNxStoreActions.getChatMessages(projectId, chat.id, true, {
          limit: 10,
          offset: 0,
          orderBy: "Created",
          orderDirection: SORT_DIRECTION.DESCENDING
        })
      );
    },
    [
      currentChat?.id,
      setCurrentChat,
      updateLastActiveChat,
      projectId,
      dispatch,
      chatSenderRole,
      setChatType
    ]
  );

  return changeChat;
}
