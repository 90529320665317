import {
  Eventcalendar,
  MbscCalendarEvent,
  MbscCellClickEvent,
  MbscEventClickEvent,
  MbscEventCreatedEvent,
  MbscEventDragEvent,
  MbscEventUpdateEvent,
  momentTimezone,
  setOptions,
  toast
} from "@mobiscroll/react";
import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import dompurify from "dompurify";
import moment from "moment-timezone";
import queryString from "query-string";
import React, { useState, useCallback, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button } from "reactstrap";

import {
  ProjectExpertAvailability,
  SharedRoutes,
  Slot
} from "@arbolus-technologies/api";
import { SelectOption } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  CALENDAR_DATE_TIME_FORMAT,
  EVENT_DATE_TIME_FORMAT,
  TimezoneService
} from "@arbolus-technologies/utils";

import { toastDuration } from "../../consts";
import { useSchedulerSettings } from "../../hooks/useSchedulerSettings";
import ExpertAvailabilitySchedulerFooter from "./ExpertAvailabilitySchedulerFooter/ExpertAvailabilitySchedulerFooter";
import ExpertAvailabilitySchedulerHeader from "./ExpertAvailabilitySchedulerHeader/ExpertAvailabilitySchedulerHeader";
import ExpertAvailabilitySchedulerPanel, {
  SelectedEventDetails
} from "./ExpertAvailabilitySchedulerPanel/ExpertAvailabilitySchedulerPanel";
import { ExpertAvailabilityPanelBase } from "./ExpertAvailabilitySchedulerPanel/types";

import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import styles from "./ExpertAvailabilityScheduler.module.scss";

const sanitizer = dompurify.sanitize;
// setup Mobiscroll Timezone plugin with Moment
momentTimezone.moment = moment;

setOptions({
  theme: "ios",
  themeVariant: "light"
});

interface ExpertAvailabilitySchedulerProps {
  projectTimezone: string;
  currentTimezone: string;
  expertAvailabilitySlotsSelected: Slot[];
  expertName?: string;
  timezones: Map<string, SelectOption>;
  onSaveProgress: (
    values: ProjectExpertAvailability,
    isManualSave: boolean
  ) => void;
  updateAvailabilityDetails?: (availabilityDetails: {
    expertAvailabilitySlots: MbscCalendarEvent[];
    expertTimezone: string;
  }) => void;
  projectName?: string;
  isAdmin: boolean;
  hasBeenRequiredMoreAvailabilitySlots?: boolean;
  isApplicationProcess?: boolean;
}

export const ExpertAvailabilityScheduler: React.FC<
  ExpertAvailabilitySchedulerProps
> = ({
  projectTimezone,
  currentTimezone,
  expertAvailabilitySlotsSelected,
  expertName,
  timezones,
  onSaveProgress,
  updateAvailabilityDetails,
  projectName,
  isAdmin,
  hasBeenRequiredMoreAvailabilitySlots,
  isApplicationProcess
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { requireAdditionalTimeSlots }: any = queryString.parse(
    history.location.search
  );

  const slidePanelId = PanelId.ExpertAvailability;
  const { t } = useTranslation("expertAvailabilityScheduler");
  const [selectedSlots, setSelectedSlots] = useState<MbscCalendarEvent[]>([]);
  const [selectedEvent, setSelectedEvent] =
    useState<SelectedEventDetails | null>(null);
  const [newEventDetails, setNewEventDetails] = useState(
    {} as { start: string; end: string }
  );

  const systemTimezone = useSelector(
    CacheSelector.appGuessCurrentTimeZoneSelector()
  );
  const [expertTimezone, setExpertTimezone] = useState<string>(
    currentTimezone || systemTimezone.value
  );

  const { minDate, maxDate, viewSettings, invalidDates, colorSettings } =
    useSchedulerSettings(expertTimezone, projectTimezone, timezones);

  const isAdditionalTimeSlotsRequested = () => {
    if (requireAdditionalTimeSlots === "true") {
      return true;
    } else if (requireAdditionalTimeSlots === "false") {
      return false;
    } else if (hasBeenRequiredMoreAvailabilitySlots) {
      return true;
    }
    return false;
  };
  const hasAdditionalTimeSlotsRequested = isAdditionalTimeSlotsRequested();

  useEffect(() => {
    const savedSlots = expertAvailabilitySlotsSelected
      .filter((slot) => !slot.isExpired)
      .map((slot, idx) => ({
        id: idx + 1,
        start: slot.startTime,
        end: slot.endTime,
        color: slot.isScheduled
          ? ARBOLUS_COLORS.bColorBaseWhite
          : ARBOLUS_COLORS.bColorBasePurple,
        isScheduled: slot.isScheduled,
        eventId: slot.eventId,
        cssClass: clsx(
          hasAdditionalTimeSlotsRequested ? styles.disabled : "",
          slot.isScheduled ? styles.scheduledSlot : ""
        )
      }));

    setSelectedSlots(savedSlots);
  }, [expertAvailabilitySlotsSelected, hasAdditionalTimeSlotsRequested]);

  useEffect(() => {
    updateAvailabilityDetails?.({
      expertAvailabilitySlots: selectedSlots,
      expertTimezone
    });
  }, [selectedSlots, expertTimezone, updateAvailabilityDetails]);

  useEffect(() => {
    if (selectedEvent) {
      dispatch(PanelStoreActions.openPanel(slidePanelId));
    }
  }, [dispatch, selectedEvent, slidePanelId]);

  const hasOverlap = useCallback(
    (args: MbscEventCreatedEvent | MbscEventDragEvent, inst: Eventcalendar) => {
      const newEvent = args.event;
      const events = inst
        .getEvents(newEvent.start, newEvent.end)
        .filter((e: MbscCalendarEvent) => e.id !== newEvent.id);

      return events.length > 0;
    },
    []
  );

  const handleCreateEvent = (
    args: MbscEventCreatedEvent | MbscEventDragEvent,
    inst: Eventcalendar
  ) => {
    const { id, start, end } = args.event;
    const overlapEvents = inst.getEvents(start, end);
    const events = overlapEvents.filter((e: MbscCalendarEvent) => e.id !== id);

    //if there are overlap events, the return false is using, so we don't need to do the next checks
    if (overlapEvents.length > 1) {
      return false;
    }

    if (events.length > 0) {
      const diffMin = moment(overlapEvents[0].end).diff(
        overlapEvents[0].start,
        "minutes"
      );

      if (diffMin < 60) {
        const overlapEventStartMinute = moment(overlapEvents[0].start)
          .tz(expertTimezone)
          .format("mm");
        const startsAtFullHour = overlapEventStartMinute === "00";

        const startNewEventTime = startsAtFullHour
          ? moment(overlapEvents[0].end)
          : moment(start);

        const endNewEventTime = moment(
          startsAtFullHour ? overlapEvents[0].end : start
        ).add(30, "minutes");

        setNewEventDetails({
          start: startNewEventTime.toISOString(),
          end: endNewEventTime.toISOString()
        });
        return true;
      }
    }

    if (isMobile) {
      const differenceMin = moment(end).diff(start, "minutes");
      const moreThanOneHour = differenceMin > 60;

      if (moreThanOneHour) {
        toast({
          message: t("warningMaxTime"),
          duration: toastDuration
        });
        return false;
      }
    }

    if (hasOverlap(args, inst)) {
      toast({
        message: t("overlapWarning"),
        duration: toastDuration
      });
      return false;
    }

    setNewEventDetails({
      start: moment(start).toISOString(),
      end: moment(end).toISOString()
    });
  };

  const handleCreatedEvent = (
    args: MbscEventCreatedEvent | MbscEventDragEvent
  ) => {
    setSelectedSlots((prevSelectedSlots) => [
      ...prevSelectedSlots,
      {
        id: prevSelectedSlots.length + 1,
        start: newEventDetails.start,
        end: newEventDetails.end,
        color: ARBOLUS_COLORS.bColorBasePurple,
        mobiScrollId: args.event.id
      }
    ]);
  };

  const handleUpdateEvent = (
    args: MbscEventUpdateEvent,
    inst: Eventcalendar
  ) => {
    const { id, start, end, isScheduled } = args.event;
    // handle mobile long press slot creation scenarios
    if (isMobile) {
      if (selectedSlots.filter((s) => s.id === id).length === 0) {
        const events = inst
          .getEvents(start, end)
          .filter(
            (e: MbscCalendarEvent) => e.id !== id && e.mobiScrollId !== id
          );

        if (events.length > 0) {
          toast({
            message: t("overlapWarning"),
            duration: toastDuration
          });
          return false;
        } else {
          setSelectedSlots(
            selectedSlots.map((s) => {
              if (s.mobiScrollId === id) {
                return {
                  ...s,
                  start: moment(start).toISOString(),
                  end: moment(end).toISOString()
                };
              }
              return s;
            })
          );
        }
        return true;
      }
    }

    if (isScheduled) {
      toast({
        message: t("callScheduledWarning"),
        duration: toastDuration
      });
      return false;
    }

    const differenceMin = moment(end).diff(start, "minutes");
    const moreThanOneHour = differenceMin > 60;

    if (moreThanOneHour) {
      toast({
        message: t("warningMaxTime"),
        duration: toastDuration
      });
      return false;
    }

    if (hasOverlap(args, inst)) {
      toast({
        message: t("overlapWarning"),
        duration: toastDuration
      });
      return false;
    }

    return true;
  };

  const handleCellClick = (args: MbscCellClickEvent, inst: Eventcalendar) => {
    const startTime = moment(
      moment(args.date).format(EVENT_DATE_TIME_FORMAT)
    ).tz(expertTimezone, true);

    const endTime = moment(
      moment(args.date).add(1, "hour").format(EVENT_DATE_TIME_FORMAT)
    ).tz(expertTimezone, true);

    const isInInvalidDateRange = startTime.isBetween(
      moment(new Date(invalidDates[0].start as Date)),
      moment(new Date(invalidDates[0].end as Date))
    );

    if (isInInvalidDateRange) {
      return false;
    }

    const overlapEvents = inst.getEvents(startTime, endTime);

    const start = startTime;
    const end = endTime;
    if (overlapEvents.length > 0) {
      const startTimeDifference = moment(overlapEvents[0].start).diff(
        startTime,
        "minutes"
      );

      const endTimeDifference = moment(overlapEvents[0].end).diff(
        endTime,
        "minutes"
      );

      if (startTimeDifference === 0 && endTimeDifference === 0) {
        toast({
          message: t("overlapWarning"),
          duration: toastDuration
        });
        return false;
      }

      if (startTimeDifference > 0) {
        end.subtract(30, "minutes");
      } else if (endTimeDifference < 0) {
        start.add(30, "minutes");
      }
    }

    setSelectedSlots((prevSelectedSlots) => [
      ...prevSelectedSlots,
      {
        id: prevSelectedSlots.length + 1,
        start: start.toISOString(),
        end: end.toISOString(),
        color: ARBOLUS_COLORS.bColorBasePurple,
        mobiScrollId: prevSelectedSlots.length + 1
      }
    ]);
  };

  const handleTimezoneChange = (value: string): void => {
    setExpertTimezone(value);
  };

  const handleSaveProgress = (isManualSave = true) => {
    const expertAvailabilitySlots: Slot[] = selectedSlots.map((slot) => ({
      startTime: slot.start as string,
      endTime: slot.end as string
    }));

    onSaveProgress(
      {
        expertAvailabilitySlots,
        expertTimezone,
        hasBeenRequiredMoreAvailabilitySlots: false
      },
      isManualSave
    );
  };

  const onEventClick = (args: MbscEventClickEvent, inst: Eventcalendar) => {
    const { id, start, end, isScheduled, eventId } = args.event;
    if (isScheduled) {
      history.replace(SharedRoutes.EVENT_PANEL_ROUTE(eventId), {
        isSchedulerScreen: true
      });
    } else {
      setSelectedEvent({
        id: id as number,
        date: new Date(
          moment(start)
            .tz(expertTimezone)
            .format(CALENDAR_DATE_TIME_FORMAT)
            .replace(" ", "T")
        ),
        startTime: new Date(
          moment(start)
            .tz(expertTimezone)
            .format(CALENDAR_DATE_TIME_FORMAT)
            .replace(" ", "T")
        ),
        endTime: new Date(
          moment(end)
            .tz(expertTimezone)
            .format(CALENDAR_DATE_TIME_FORMAT)
            .replace(" ", "T")
        ),
        inst
      });
    }
  };

  const handleUpdateEventDetails = (
    data: ExpertAvailabilityPanelBase
  ): void => {
    const { startTime, endTime } = data;
    const filteredSlots = selectedSlots.filter((slot) => slot.id !== data.id);

    const { start, end } = TimezoneService.convertDatesTimezone(
      `${moment(startTime).format(CALENDAR_DATE_TIME_FORMAT)}`,
      `${moment(endTime).format(CALENDAR_DATE_TIME_FORMAT)}`,
      systemTimezone.value,
      expertTimezone
    );

    const updatedSlots = [
      ...filteredSlots,
      {
        id: data.id,
        start: start.toDate(),
        end: end.toDate(),
        color: ARBOLUS_COLORS.bColorBasePurple
      }
    ];
    setSelectedSlots(updatedSlots);
    setSelectedEvent(null);
    dispatch(PanelStoreActions.closePanel(slidePanelId));
  };

  const handleDeleteEvent = (eventId: number): void => {
    const filteredSlots = selectedSlots.filter((slot) => slot.id !== eventId);
    const updatedSlots = [...filteredSlots];
    setSelectedSlots(updatedSlots);
    setSelectedEvent(null);
    dispatch(PanelStoreActions.closePanel(slidePanelId));
  };

  const handleClosePanel = () => {
    setSelectedEvent(null);
    dispatch(PanelStoreActions.closePanel(slidePanelId));
  };

  const expertAvailabilitySchedulerHeader = () => (
    <ExpertAvailabilitySchedulerHeader
      projectTimezone={projectTimezone}
      expertTimezone={expertTimezone}
      timezones={timezones}
      handleTimezoneChange={handleTimezoneChange}
      isAdmin={isAdmin}
    />
  );

  const getAvailabilityTitle = () => {
    if (isAdmin && !hasAdditionalTimeSlotsRequested) {
      return t("editAvailability", { expertName, projectName });
    } else if (projectName) {
      return projectName;
    }
    return t("availability");
  };

  return (
    <>
      {!isApplicationProcess && (
        <div className={styles.buttonContainer}>
          <Button onClick={history.goBack}>
            <Icon color="#6157FC" fontSize="24px" name="arrow_back" />
            {isAdmin && !hasAdditionalTimeSlotsRequested && t("back")}
          </Button>
          {isAdmin && !hasAdditionalTimeSlotsRequested && (
            <Button onClick={history.goBack}>{t("cancel")}</Button>
          )}
        </div>
      )}
      <div
        className={clsx(
          styles.expertAvailabilitySchedulerPage,
          isMobile ? styles.mobileOnly : "",
          projectName ? styles.updateView : ""
        )}
      >
        <h1>{getAvailabilityTitle()}</h1>
        {(!projectName || hasAdditionalTimeSlotsRequested) && (
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: sanitizer(
                hasAdditionalTimeSlotsRequested
                  ? t("additionalTimeSlotDescription")
                  : t("description")
              )
            }}
          />
        )}
        <div className={styles.expertAvailabilityScheduler}>
          <Eventcalendar
            data={selectedSlots}
            view={viewSettings}
            colors={colorSettings}
            min={minDate}
            max={maxDate}
            clickToCreate={isMobile ? "double" : "single"}
            dragToCreate={isMobile}
            dragToMove={true}
            dragToResize={true}
            eventDelete
            displayTimezone={expertTimezone}
            onEventCreate={handleCreateEvent}
            onEventCreated={handleCreatedEvent}
            onEventDragEnd={handleUpdateEvent}
            onEventUpdate={handleUpdateEvent}
            onCellClick={isMobile ? handleCellClick : undefined}
            onEventClick={onEventClick}
            timezonePlugin={momentTimezone}
            dragTimeStep={30}
            renderHeader={expertAvailabilitySchedulerHeader}
            invalid={invalidDates}
          />
        </div>
        <ExpertAvailabilitySchedulerFooter
          slotsCount={selectedSlots.length}
          onSave={() => handleSaveProgress(false)}
          isUpdate={!!projectName}
          isAdmin={isAdmin}
          isDisabled={
            selectedSlots.length === 0 ||
            selectedSlots.filter((s) => s.cssClass?.includes("disabled"))
              .length === selectedSlots.length
          }
          hasAdditionalTimeSlotsRequested={hasAdditionalTimeSlotsRequested}
          isApplicationProcess={isApplicationProcess}
        />
      </div>
      {selectedEvent && (
        <ExpertAvailabilitySchedulerPanel
          slidePanelId={slidePanelId}
          projectTimezone={projectTimezone}
          expertTimezone={expertTimezone}
          timezones={timezones}
          selectedEvent={selectedEvent}
          onUpdateEvent={handleUpdateEventDetails}
          onDeleteEvent={handleDeleteEvent}
          onClosePanel={handleClosePanel}
        />
      )}
    </>
  );
};
