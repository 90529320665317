import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { Expert } from "@arbolus-technologies/features/common";
import { ExpertCanopyV2 } from "@arbolus-technologies/models/canopy-panels";
import { CANOPY_SINGLE_EXPERT_ROUTE } from "@arbolus-technologies/routes";
import { CanopyV2Selector } from "@arbolus-technologies/stores/canopy-V2";
import { InfoBox, Loader } from "@arbolus-technologies/ui/components";

import { EXPERTS_TABS } from "../ExpertsTabWrapper";

import styles from "./ExpertsList.module.scss";

interface ExpertsListProps {
  activeTab: EXPERTS_TABS;
  canopyExperts: ExpertCanopyV2[];
  isLoading: boolean;
  handleOpenProfile: (expertId: string) => void;
}

export const ExpertsList: React.FC<ExpertsListProps> = ({
  activeTab,
  canopyExperts,
  isLoading,
  handleOpenProfile
}) => {
  const { t } = useTranslation("canopyV2");
  const history = useHistory();

  const canopyData = useSelector(CanopyV2Selector.canopyData());

  const handleClickAnswers = (expertId: string) => {
    if (canopyData) {
      history.push(CANOPY_SINGLE_EXPERT_ROUTE(canopyData?.id, expertId));
    }
  };

  const noExpertMessage = {
    [EXPERTS_TABS.INVITES]: {
      title: t("nothingToDoHere"),
      description: t("noInvitesDesc")
    },
    [EXPERTS_TABS.IN_REVIEW]: {
      title: t("nothingToDoHere"),
      description: t("noInReviewDesc")
    },
    [EXPERTS_TABS.APPROVED]: {
      title: t("nothingToDoHere"),
      description: t("noApprovedDesc")
    },
    [EXPERTS_TABS.REJECTED]: {
      title: t("nothingToDoHere"),
      description: t("noRejectedDesc")
    },
    [EXPERTS_TABS.NO_RESPONSE]: {
      title: t("noResponse"),
      description: t("noResponseDesc")
    }
  };

  return (
    <div className={styles.container}>
      {isLoading && <Loader />}
      {!isLoading &&
        (canopyExperts.length === 0 ? (
          <InfoBox
            title={noExpertMessage[activeTab].title}
            description={noExpertMessage[activeTab].description}
            infoBoxType="warning"
            iconName="mark_chat_read"
          />
        ) : (
          canopyExperts.map((item) => (
            <Expert
              key={item.expert.expertId}
              expert={item.expert}
              expertStatus={item.status}
              handleClickAnswers={handleClickAnswers}
              handleOpenProfile={handleOpenProfile}
            />
          ))
        ))}
    </div>
  );
};
