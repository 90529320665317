import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./NoWorkHistory.module.scss";

export const NoWorkHistory: React.FC = () => {
  const { t } = useTranslation("discoverPage");

  return <p className={styles.noWorkHistory}>{t("noWorkHistory")}</p>;
};
