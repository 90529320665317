import clsx from "clsx";
import React from "react";

import styles from "./SquareNumber.module.scss";

interface SquareNumberProps {
  number: string;
  isActive: boolean;
  errorBackground?: boolean;
}
export const SquareNumber: React.FC<SquareNumberProps> = ({
  number,
  isActive,
  errorBackground
}) => (
  <div
    className={clsx(
      styles.container,
      isActive ? styles.isActive : styles.isInactive,
      { [styles.error]: errorBackground }
    )}
  >
    <span>{number}</span>
  </div>
);
