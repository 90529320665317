import i18next from "i18next";
import * as Yup from "yup";

export const NAME = "name";

export type RenameCollectionFormObject = {
  [NAME]: string;
};

export const renameCollectionSchemaBuilder = (
  currentName: string
): Yup.ObjectSchema<object & RenameCollectionFormObject> =>
  Yup.object().shape<RenameCollectionFormObject>({
    name: Yup.string()
      .required(i18next.t("common:forms:fieldRequired"))
      .min(1)
      .max(50)
      .test(
        "name",
        i18next.t("bookmarkCollectionActions:renameSameNameError"),
        (value) => currentName.toLocaleUpperCase() !== value.toLocaleUpperCase()
      )
      .label(i18next.t("common:forms:text"))
  });
