/* eslint-disable @typescript-eslint/no-empty-function */
import React, { Component, createRef, RefObject } from "react";
import SimpleBar from "simplebar-react";

export enum CIQInfiniteScrollDirection {
  TOP,
  BOTTOM
}

interface CustomInfiniteScrollProps extends React.HTMLAttributes<HTMLElement> {
  onBottomReached: () => void;
  onTopReached: () => void;
  isBidirectional: boolean;
  elementHeight: number;
  onInitialFetch?: (elementCount?: number) => void;
  direction?: CIQInfiniteScrollDirection;
  bottomThreshold: number;
  topThreshold: number;
  innerRef?: (ref: RefObject<HTMLDivElement>) => void;
  overrideScrollHeight?: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CustomInfiniteScrollStore {}

class CustomInfiniteScroll extends Component<
  CustomInfiniteScrollProps,
  CustomInfiniteScrollStore
> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    onBottomReached: (): void => {},
    onTopReached: (): void => {},
    isBidirectional: false,
    direction: CIQInfiniteScrollDirection.BOTTOM,
    bottomThreshold: 10,
    topThreshold: 10,
    innerRef: (): void => {}
  };

  // eslint-disable-next-line react/sort-comp
  private scrollRef = createRef<HTMLDivElement>();

  componentDidMount(): void {
    const { innerRef } = this.props;
    if (innerRef) {
      innerRef(this.scrollRef);
    }
    this.scrollRef.current!.addEventListener("wheel", this.handleMouseWheel);
    this.handleInitElementHeight();
  }

  componentWillUnmount(): void {
    this.scrollRef.current!.removeEventListener("wheel", this.handleMouseWheel);
  }

  handleInfiniteScroll = (): void => {
    const { scrollTop, offsetTop, offsetHeight, scrollHeight } =
      this.scrollRef.current!;

    const { bottomThreshold, topThreshold } = this.props;
    const scrolledUp = scrollTop + offsetTop;
    const scrollableHeight = scrollHeight - offsetHeight;
    const percentageToTop = (scrolledUp / scrollableHeight) * 100;

    if (percentageToTop >= 100 - bottomThreshold) {
      this.handleBottomReached();
    } else if (percentageToTop <= topThreshold) {
      this.handleTopReached();
    }
  };

  handleBottomReached = (): void => {
    const { onBottomReached, isBidirectional, direction } = this.props;
    if (isBidirectional) {
      onBottomReached();
    } else if (direction === CIQInfiniteScrollDirection.BOTTOM) {
      onBottomReached();
    }
  };

  handleTopReached = (): void => {
    const { onTopReached, isBidirectional, direction } = this.props;
    if (isBidirectional) {
      onTopReached();
    } else if (direction === CIQInfiniteScrollDirection.TOP) {
      onTopReached();
    }
  };

  handleMouseWheel = (event: WheelEvent): void => {
    event.stopPropagation();
    const { offsetHeight, scrollHeight } = this.scrollRef.current!;

    if (offsetHeight < scrollHeight) {
      this.handleInfiniteScroll();
    } else if (event.deltaY < 0) {
      this.handleTopReached();
    } else if (event.deltaY > 0) {
      this.handleBottomReached();
    }
  };

  handleInitElementHeight = (): void => {
    const { elementHeight, onInitialFetch, overrideScrollHeight } = this.props;
    const { scrollHeight } = this.scrollRef.current!;
    const initElementHeight = elementHeight;
    const actualScrollHeight = overrideScrollHeight
      ? window.innerHeight - overrideScrollHeight
      : scrollHeight;
    const elementCount = Math.floor(actualScrollHeight / initElementHeight) + 2;

    if (onInitialFetch) {
      onInitialFetch(elementCount);
    }
  };

  render(): JSX.Element {
    const {
      children,
      isBidirectional,
      onBottomReached,
      onTopReached,
      elementHeight,
      onInitialFetch,
      bottomThreshold,
      topThreshold,
      innerRef,
      overrideScrollHeight,
      ...htmlProps
    } = this.props;
    return (
      <SimpleBar
        onScroll={this.handleInfiniteScroll}
        className="simplebar-transparent"
        scrollableNodeProps={{
          ref: this.scrollRef
        }}
        {...htmlProps}
      >
        {children}
      </SimpleBar>
    );
  }
}

/**
 * @deprecated
 * use InfiniteScroll from @arbolus-technologies/libs/ui/components
 */
export default CustomInfiniteScroll;
