import {
  InputModalData,
  ListModalData,
  ModalData
} from "../models/definitions";
import {
  CLOSE_INPUT_MAIN_MODAL,
  CLOSE_LIST_MAIN_MODAL,
  CLOSE_MAIN_MODAL,
  CloseInputMainModalAction,
  CloseListMainModalAction,
  CloseMainModalAction,
  OPEN_INPUT_MAIN_MODAL,
  OPEN_LIST_MAIN_MODAL,
  OPEN_MAIN_MODAL,
  OpenInputMainModalAction,
  OpenListMainModalAction,
  OpenMainModalAction
} from "./ModalActionTypes";

export const openMainModal = (modalData: ModalData): OpenMainModalAction => ({
  type: OPEN_MAIN_MODAL,
  payload: modalData
});

export const closeMainModal = (): CloseMainModalAction => ({
  type: CLOSE_MAIN_MODAL
});

export const openListMainModal = (
  modalData: ListModalData
): OpenListMainModalAction => ({
  type: OPEN_LIST_MAIN_MODAL,
  payload: modalData
});

export const closeListMainModal = (): CloseListMainModalAction => ({
  type: CLOSE_LIST_MAIN_MODAL
});

export const openInputMainModal = (
  modalData: InputModalData
): OpenInputMainModalAction => ({
  type: OPEN_INPUT_MAIN_MODAL,
  payload: modalData
});

export const closeInputMainModal = (): CloseInputMainModalAction => ({
  type: CLOSE_INPUT_MAIN_MODAL
});
