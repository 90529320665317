import React from "react";

import { ListProject, ProjectClientService } from "@arbolus-technologies/api";

import { ProjectSelector, ProjectSelectorProps } from "./ProjectSelector";

interface ProjectClientSelectorProps
  extends Omit<ProjectSelectorProps<ListProject>, "getItems"> {
  clientId?: string;
  projectStates?: string[];
  clientBillingInfo?: boolean;
}

export const ProjectClientSelector: React.FC<ProjectClientSelectorProps> = ({
  clientId = "",
  projectStates = ["Active"],
  clientBillingInfo = false,
  ...props
}) => (
  <ProjectSelector
    {...props}
    getItems={(pagination) =>
      ProjectClientService.getClientProjects(pagination, projectStates[0])
    }
  />
);
