import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { rocket } from "@arbolus-technologies/theme";
import {
  ActionButton,
  InfoCookieBoxWithChildren
} from "@arbolus-technologies/ui/components";

import canopyPreview from "../../../assets/canopy-preview.png";

import styles from "./InfoCookieBoxPane.module.scss";

export const InfoCookieBoxPane: React.FC = () => {
  const { t } = useTranslation("infoCookieBox");
  const dispatch = useDispatch();

  const createNewSurvey = (): void => {
    dispatch(PanelStoreActions.openPanel(PanelId.CreateSurvey));
  };

  return (
    <InfoCookieBoxWithChildren cookie="canopy" withPadding>
      <div className={styles.container}>
        <img
          className={styles.imageFrame}
          src={canopyPreview}
          alt="canopy-preview"
        />
        <div className={styles.textContainer}>
          <div className={styles.header}>
            <img className={styles.icon} src={rocket} alt="rocket" />
            <h1 className={styles.title}>{t("canopyInfoTitle")}</h1>
          </div>
          <div className={styles.body}>
            <p>{t("canopyInfo1")}</p>
            <p>{t("canopyInfo2")}</p>
          </div>
          <div className={styles.footer}>
            <ActionButton
              handleOnClick={createNewSurvey}
              text={t("createSurvey")}
              type="primary"
            />
          </div>
        </div>
      </div>
    </InfoCookieBoxWithChildren>
  );
};
