import {
  DefaultToasterService,
  ToasterService,
  WebHooksService
} from "@arbolus-technologies/api";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { useCurrentPath } from "@arbolus-technologies/utils";
import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const CLIENT_INSIGHTS_EXTENSION_SLACK_CHANNEL_URL =
  process.env.NX_PUBLIC_CLIENT_INSIGHTS_EXTENSION_SLACK_CHANNEL_URL;

interface RequestDataButtonProps {
  type: "primary" | "secondary";
  text: string;
  notificationService?: ToasterService;
  webHooksService?: typeof WebHooksService;
}

export const RequestDataButton: React.FC<RequestDataButtonProps> = ({
  type,
  text,
  notificationService = DefaultToasterService,
  webHooksService = WebHooksService
}) => {
  const { t } = useTranslation("chromeExtensionBanner");
  const clientUser = useSelector(CacheSelector.loggedInUser());
  const link = useCurrentPath();
  const sendSlackMessage = () => {
    const message =
      t("slackMessage", {
        email: clientUser.email
      }) + link;

    webHooksService
      .sendSlackMessage(message, CLIENT_INSIGHTS_EXTENSION_SLACK_CHANNEL_URL!)
      .subscribe(
        () => {
          notificationService.showSuccess(t("dataRequested"), "bottom-center");
        },
        () => {
          notificationService.showError(
            t("dataRequestedError"),
            "bottom-center"
          );
        }
      );
  };

  return (
    <Button
      text={text}
      endIcon="chevron_right"
      type={type}
      onClick={sendSlackMessage}
    />
  );
};
