import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from "react";

import {
  ApiPaginatedResponse,
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  MAX_PAGE_SIZE_25,
  ProjectService
} from "@arbolus-technologies/api";
import {
  CONTACT_METHOD_FILTERS_ENUM,
  CONTACT_TYPE_FILTERS_ENUM,
  ExternalExpertStats,
  PipelineExpertUser
} from "@arbolus-technologies/models/expert";
import { DropdownAngle } from "@arbolus-technologies/models/project";

import { ANGLE_DROPDOWN_DEFAULT_OPTION } from "../../Constants/angle";

export interface ContextValue {
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  selectedAngle: DropdownAngle;
  setSelectedAngle: React.Dispatch<React.SetStateAction<DropdownAngle>>;
  excludeAlreadyAdded: boolean;
  setExcludeAlreadyAdded: React.Dispatch<React.SetStateAction<boolean>>;
  contactType: CONTACT_TYPE_FILTERS_ENUM;
  setContactType: React.Dispatch<
    React.SetStateAction<CONTACT_TYPE_FILTERS_ENUM>
  >;
  contactMethod: CONTACT_METHOD_FILTERS_ENUM;
  setContactMethod: React.Dispatch<
    React.SetStateAction<CONTACT_METHOD_FILTERS_ENUM>
  >;
  selectedExpertsIds: string[];
  setSelectedExpertsIds: React.Dispatch<React.SetStateAction<string[]>>;
  handleSelectExpert: (expertId: string) => void;
  externalExpertStats: ExternalExpertStats | null;
  isLoading: boolean;
  expertListPaginated: ApiPaginatedResponse<PipelineExpertUser> | null;
  currentPage: number;
  fetchExpertList: (currentPage: number) => void;
  fetchStats: () => void;
}

const ExternalSourcingContext = createContext({} as ContextValue);

export const ExternalSourcingProvider: React.FC<{
  projectId: string;
  children: React.ReactNode;
}> = ({ projectId, children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedAngle, setSelectedAngle] = useState<DropdownAngle>(
    ANGLE_DROPDOWN_DEFAULT_OPTION
  );
  const [excludeAlreadyAdded, setExcludeAlreadyAdded] = useState<boolean>(true);
  const [contactType, setContactType] = useState<CONTACT_TYPE_FILTERS_ENUM>(
    CONTACT_TYPE_FILTERS_ENUM.ALL_CONTACT_TYPES
  );
  const [contactMethod, setContactMethod] =
    useState<CONTACT_METHOD_FILTERS_ENUM>(
      CONTACT_METHOD_FILTERS_ENUM.ALL_CONTACT_METHODS
    );

  const [expertListPaginated, setExpertListPaginated] =
    useState<ApiPaginatedResponse<PipelineExpertUser> | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [externalExpertStats, setExternalExpertStats] =
    useState<ExternalExpertStats | null>(null);
  const [selectedExpertsIds, setSelectedExpertsIds] = useState<string[]>([]);

  const handleSelectExpert = useCallback(
    (expertId: string) => {
      if (selectedExpertsIds.includes(expertId)) {
        setSelectedExpertsIds(
          selectedExpertsIds.filter(
            (expertIdStored) => expertIdStored !== expertId
          )
        );
      } else {
        setSelectedExpertsIds([...selectedExpertsIds, expertId]);
      }
    },
    [selectedExpertsIds]
  );

  const selectedAngleIdOrNull =
    selectedAngle?.id === "allAngles" ? null : selectedAngle?.id;

  const fetchExpertList = useCallback(
    (currentPage: number) => {
      setSelectedExpertsIds([]);
      setIsLoading(true);
      ProjectService.getProjectPipelineExperts(projectId, {
        searchTerm,
        angleId: selectedAngleIdOrNull,
        excludeAlreadyAdded,
        projectPipelineStatus: contactType,
        projectPipelineContactMethod: contactMethod,
        offset: currentPage * MAX_PAGE_SIZE_25,
        limit: MAX_PAGE_SIZE_25
      }).subscribe(
        (response) => {
          setExpertListPaginated(response);
          setCurrentPage(response.items.length === 0 ? 0 : currentPage + 1);
          setIsLoading(false);
        },
        (error: ErrorResponse<CIQError>) => {
          DefaultToasterService.showError(error.message);
          setIsLoading(false);
        }
      );
    },
    [
      projectId,
      searchTerm,
      selectedAngleIdOrNull,
      excludeAlreadyAdded,
      contactType,
      contactMethod
    ]
  );

  const fetchStats = useCallback(() => {
    ProjectService.getProjectPipelineStats(
      projectId,
      searchTerm,
      selectedAngleIdOrNull,
      excludeAlreadyAdded
    ).subscribe(
      (response) => {
        setExternalExpertStats(response);
      },
      (error: ErrorResponse<CIQError>) => {
        DefaultToasterService.showError(error.message);
      }
    );
  }, [projectId, searchTerm, selectedAngleIdOrNull, excludeAlreadyAdded]);

  const contextValue = useMemo(
    () => ({
      searchTerm,
      setSearchTerm,
      selectedAngle,
      setSelectedAngle,
      excludeAlreadyAdded,
      setExcludeAlreadyAdded,
      contactType,
      setContactType,
      contactMethod,
      setContactMethod,
      selectedExpertsIds,
      setSelectedExpertsIds,
      handleSelectExpert,
      externalExpertStats,
      isLoading,
      expertListPaginated,
      currentPage,
      fetchExpertList,
      fetchStats
    }),
    [
      searchTerm,
      selectedAngle,
      excludeAlreadyAdded,
      contactType,
      contactMethod,
      selectedExpertsIds,
      handleSelectExpert,
      externalExpertStats,
      isLoading,
      expertListPaginated,
      currentPage,
      fetchExpertList,
      fetchStats
    ]
  );

  return (
    <ExternalSourcingContext.Provider value={contextValue}>
      {children}
    </ExternalSourcingContext.Provider>
  );
};

export const useExternalSourcing = (): ContextValue => {
  const context = useContext(ExternalSourcingContext);
  if (typeof context === undefined) {
    throw new Error(
      "useExternalSourcing must be used within a ExternalSourcingProvider"
    );
  }
  return context;
};
