import { Button } from "arbolus-ui-components";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ExpertService,
  ToasterService
} from "@arbolus-technologies/api";
import { IExpertProfile } from "@arbolus-technologies/models/canopy-panels";
import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import { EXPERT_PROFILE_ROUTE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import {
  DncInfoBox,
  ExpertLinkedin,
  HR,
  IconWithText,
  Loader,
  PublicCompanyIcon,
  UserPortrait
} from "@arbolus-technologies/ui/components";

import { EngageWithMe } from "../../Components/EngageWithMe/EngageWithMe";
import { ExpertEndorsements } from "../../Components/ExpertEndorsements/ExpertEndorsements";
import { ExpertDrawerContainer } from "../../Components/ExpertProfileDrawers/ExpertDrawerContainer";
import { MixPanelEventNames } from "../../Mixpanel/enums";
import { useArbolusTracking } from "../../Mixpanel/useArbolusTracking";

import styles from "./ExpertProfile.module.scss";

interface ExpertProfileProps {
  expertId?: string;
  projectId?: string;
  canopyId?: string;
  hasFollowUp?: boolean;
  expertService?: typeof ExpertService;
  notificationService?: ToasterService;
}

export const ExpertProfile: React.FC<ExpertProfileProps> = ({
  expertId,
  projectId,
  canopyId,
  hasFollowUp,
  expertService = ExpertService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("expertProfileSidePanel");
  const dispatch = useDispatch();
  const { trackClick } = useArbolusTracking();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [expertProfile, setExpertProfile] = useState<IExpertProfile | null>(
    null
  );

  const isAdmin = useSelector(CacheSelector.isAdmin());
  const metadataCountries = useSelector(CacheSelector.metadataCountries());

  const getExpertProfile = useCallback(() => {
    setIsLoading(true);
    if (expertId) {
      expertService.getExpertById({ expertId, projectId }).subscribe(
        (expertProfileResponse) => {
          const { user } = expertProfileResponse;

          const userTwoLetterCountryCode = metadataCountries.find(
            (metadataCountry) =>
              metadataCountry.threeLetterCode === user.isoCountryCode
          );

          const updatedExpertProfile = {
            ...expertProfileResponse,
            twoLetterCode:
              userTwoLetterCountryCode?.twoLetterCode.toLowerCase(),
            countryName: userTwoLetterCountryCode?.name
          };

          setExpertProfile(updatedExpertProfile);
          setIsLoading(false);
        },
        (error: ErrorResponse<CIQError>) => {
          setIsLoading(false);
          notificationService.showApiErrors(error);
        }
      );
    }
  }, [
    expertId,
    expertService,
    metadataCountries,
    notificationService,
    projectId
  ]);

  useEffect(() => {
    getExpertProfile();
  }, [getExpertProfile]);

  const handleOpenEngageSidePanel = (): void => {
    hasFollowUp &&
      trackClick(MixPanelEventNames.CanopyExpertsProfileFollowup, {
        canopyId,
        expertId
      });

    dispatch(PanelStoreActions.closePanel(PanelId.ExpertProfile));
    dispatch(
      PanelStoreActions.openPanel(PanelId.ExpertEngageWithMe, {
        expertId: expertProfile?.id,
        ...expertProfile?.user
      })
    );
  };

  const openFullProfile = () => {
    window.open(EXPERT_PROFILE_ROUTE(expertId!), "_blank");
  };

  if (isLoading) {
    return <Loader isFull />;
  }

  if (!expertProfile) {
    return null;
  }

  const {
    id,
    endorsements,
    user,
    workHistories,
    background,
    linkedinProfile,
    overview,
    twoLetterCode,
    countryName,
    lastPublicCompanyExpDate,
    doNotContactStatus,
    doNotContactStatusDescription,
    doNotContactStatusCategory
  } = expertProfile;

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles.userPortraitContainer}>
          <UserPortrait
            user={user}
            avatarSize="bigXSCircle"
            icon={
              lastPublicCompanyExpDate ? (
                <PublicCompanyIcon
                  date={lastPublicCompanyExpDate}
                  customFontSize="24px"
                />
              ) : undefined
            }
          />
          <Button text={t("fullProfile")} onClick={openFullProfile} />
        </div>
        <HR margin={{ top: 3, bottom: 3 }} />
        <DncInfoBox
          doNotContactStatus={doNotContactStatus}
          doNotContactStatusDescription={doNotContactStatusDescription}
          doNotContactStatusCategory={doNotContactStatusCategory}
          isAdmin={isAdmin}
        />
        <div className={styles.iconsContainer}>
          {countryName && twoLetterCode && (
            <IconWithText text={{ content: countryName }}>
              <span
                className={clsx(
                  `flag-icon flag-icon-${twoLetterCode}`,
                  styles.icon
                )}
              />
            </IconWithText>
          )}
          {linkedinProfile && <ExpertLinkedin linkedInUrl={linkedinProfile} />}
          {user?.timezone && (
            <IconWithText
              text={{ content: user.timezone }}
              icon={{ name: "public", filled: true }}
            />
          )}
        </div>

        {!isAdmin &&
          hasFollowUp &&
          doNotContactStatus !== DO_NOT_CONTACT_STATUS.DNC && (
            <EngageWithMe
              handleOpenEngageSidePanel={handleOpenEngageSidePanel}
            />
          )}

        <ExpertDrawerContainer
          overview={overview}
          background={background}
          workHistories={workHistories}
          expertId={id}
          displayPublicCompanies={lastPublicCompanyExpDate !== null}
        />
        <ExpertEndorsements endorsements={endorsements} />
      </div>
    </div>
  );
};
