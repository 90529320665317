import { Icon } from "arbolus-ui-components";
import React from "react";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";

import styles from "./BadgeGroup.module.scss";

interface BadgeIconProps {
  imageUrl?: string;
  defaultIcon?: string;
}

export const BadgeIcon: React.FC<BadgeIconProps> = ({
  imageUrl,
  defaultIcon
}) => {
  if (imageUrl) {
    return (
      <div className={styles.imgContainer}>
        <img
          src={imageUrl}
          alt="logo"
          className={styles.logo}
          data-testid="image"
        />
      </div>
    );
  }

  if (defaultIcon) {
    return (
      <div className={styles.logo}>
        <Icon
          name={defaultIcon}
          color={ARBOLUS_COLORS.bColorGrayIcon}
          fontSize="20px"
          id="icon"
        />
      </div>
    );
  }

  return null;
};
