/* eslint-disable @typescript-eslint/no-empty-function */
import { push } from "connected-react-router";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { Dispatch } from "redux";
import { createStructuredSelector } from "reselect";
import { Subscription } from "rxjs";
import SimpleBar from "simplebar-react";

import { ToasterService } from "@arbolus-technologies/api";
import {
  MixPanelActions,
  MixPanelEventNames,
  trackEvent
} from "@arbolus-technologies/features/common";
import { SelectOption } from "@arbolus-technologies/models/common";
import { Avatar, Loader } from "@arbolus-technologies/ui/components";

import {
  PROJECT_CALENDAR,
  PROJECT_EVENT_ROUTE
} from "../../../../../constants/navigation/projectRoutes";
import {
  ContentPanelContextConsumer,
  ContentPanelContextProps
} from "../../../../../contexts/contentPanel/ContentPanelContext";
import ContentPanelEvents from "../../../../../contexts/contentPanel/ContentPanelEvents";
import AccessManager from "../../../../../contexts/roleBasedAccess/AccessManager";
import { CIQError, ErrorResponse } from "../../../../../models/api";
import { Event } from "../../../../../models/event";
import {
  DocumentService,
  EventService,
  UtilsService
} from "../../../../../services";
import { AppAction } from "../../../../../store/actions";
import { AppState } from "../../../../../store/reducers";
import { AppSelector } from "../../../../app/store";
import { AuthSelector } from "../../../../auth/store";
import { CIQFileIcon } from "../../documents";

const notification = new ToasterService();

interface EventSidePanelStoreProps {
  eventData?: Event;
  currentUserId?: string;
  currentTimeZone?: SelectOption;
}

interface EventSidePanelProps extends EventSidePanelStoreProps {
  eventId: string;
  projectId: string;
  onEventEdit: (projectId: string, eventId: string) => void;
  onPanelClose: () => void;
  onBackToCalender: () => void;
}

interface EventSidePanelState {
  event: Event;
  isLoading: boolean;
  isEventDeleteAction: boolean;
}

type EventSidePanelIntersectedProps = EventSidePanelProps & WithTranslation;

class EventSidePanel extends Component<
  EventSidePanelIntersectedProps,
  EventSidePanelState
> {
  static defaultProps = {
    currentUserId: "",
    projectId: "",
    onEventEdit: (): void => {},
    onBackToCalender: (): void => {}
  };

  constructor(props: EventSidePanelIntersectedProps) {
    super(props);

    const { eventData } = this.props;
    this.state = {
      event: eventData || ({} as Event),
      isLoading: !eventData,
      isEventDeleteAction: false
    };
  }

  componentDidMount(): void {
    const { eventData } = this.props;
    if (!eventData) this.fetchEvent();
  }

  componentWillUnmount(): void {
    this.eventFetchSubscription?.unsubscribe();
    this.eventDeleteSubscription?.unsubscribe();
    this.documentDownloadSubscription?.unsubscribe();
  }

  private eventFetchSubscription?: Subscription;

  private eventDeleteSubscription?: Subscription;

  private documentDownloadSubscription?: Subscription;

  fetchEvent = (): void => {
    const { eventId, projectId, onPanelClose } = this.props;
    this.setState({
      isLoading: true
    });
    this.eventFetchSubscription = EventService.getEvent(
      projectId,
      eventId
    ).subscribe(
      (eventResponse) => {
        this.setState({
          event: eventResponse,
          isLoading: false
        });
      },
      (err: ErrorResponse<CIQError>) => {
        notification.showError(err.message);
        onPanelClose();
      }
    );
  };

  deleteEvent = (): void => {
    const { projectId, eventId, onPanelClose, t } = this.props;
    this.eventDeleteSubscription = EventService.deleteEvent(
      projectId,
      eventId
    ).subscribe(
      ({ deleted }) => {
        if (deleted) {
          notification.showSuccess(t("deleteSuccess"));
          ContentPanelEvents.deleteEvent(eventId);
          onPanelClose();
        }
      },
      (error: ErrorResponse<CIQError>) => notification.showError(error.message)
    );
  };

  handleBackToCalenderClick = (): void => {
    const { onBackToCalender, onPanelClose } = this.props;
    onBackToCalender();
    onPanelClose();
  };

  handleEditEventClick = (): void => {
    const { eventId, onPanelClose, onEventEdit, projectId } = this.props;

    onEventEdit(projectId, eventId);
    onPanelClose();
  };

  handleJoinCall = (): void => {
    const { event } = this.state;
    const { meetingJoinUrl } = event;
    window.open(meetingJoinUrl, "_blank");
  };

  handleOnDocumentClicked = (id: string, fileName: string): void => {
    const { projectId } = this.props;

    const mixpanelProperties = {
      projectId,
      documentId: id,
      fileName
    };

    this.documentDownloadSubscription = DocumentService.downloadDocument(
      projectId,
      id
    ).subscribe(
      (document) => {
        trackEvent(MixPanelEventNames.DownloadDocuments, {
          action: MixPanelActions.Downloaded,
          ...mixpanelProperties,
          uploadedBy: {
            id: document.uploadedUserId,
            email: document.uploadedUser.email
          }
        });
        window.location.href = document.downloadUrl;
      },
      (error: ErrorResponse<CIQError>) => {
        notification.showError(error.message);
        trackEvent(MixPanelEventNames.DownloadDocuments, {
          action: MixPanelActions.DownloadFailed,
          ...mixpanelProperties
        });
      }
    );
  };

  handleDeleteClicked = (): void => {
    this.setState({ isEventDeleteAction: true });
  };

  renderEventDeleteAction = (): JSX.Element => {
    const { t } = this.props;

    return (
      <div className="panel-footer event-delete">
        <div className="bottom-container">
          <div className="left-container">
            <p> {t("areYouSureAlert")}</p>
          </div>
          <div className="right-container">
            <Button
              size="sm"
              color="danger"
              type="button"
              onClick={this.deleteEvent}
            >
              {t("delete")}
            </Button>
            <Button
              size="sm"
              color="secondary"
              type="button"
              onClick={(): void =>
                this.setState({ isEventDeleteAction: false })
              }
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  renderEventDetails = (): JSX.Element => {
    const { event } = this.state;
    const { currentTimeZone } = this.props;
    const { startTime, endTime, location } = event;
    return (
      <div className="panel-body">
        <div className="detail-row">
          <div className="detail-icon">
            <i className="ciq-icon ciq-calender" />
          </div>
          <div className="detail-text">
            {EventService.generateEventDate(startTime, endTime)}
          </div>
        </div>
        <div className="detail-row-container">
          <div className="left-container">
            <div className="detail-icon">
              <i className="ciq-icon ciq-activity" />
            </div>
            <div className="detail-text">
              {EventService.generateEventTime(startTime, endTime)}
            </div>
          </div>
          <div className="right-container">
            {currentTimeZone && (
              <div className="detail-timezone">
                {currentTimeZone.customLabel}
              </div>
            )}
          </div>
        </div>
        {location && (
          <div className="detail-row">
            <div className="detail-icon">
              <i className="ciq-icon ciq-component-icon-ic-place" />
            </div>
            <div className="detail-text">{location}</div>
          </div>
        )}
      </div>
    );
  };

  renderEventActions = (): JSX.Element => {
    const { event, isEventDeleteAction } = this.state;
    const { currentUserId, t } = this.props;
    const { organizer, endTime, meetingJoinUrl } = event;
    const iamOrganizer = currentUserId === organizer.id;
    const isEventPassed = UtilsService.isTimeBeforeLocalTime(endTime);
    const isEventControl = iamOrganizer && !isEventPassed;

    if (isEventDeleteAction) {
      return this.renderEventDeleteAction();
    }

    return (
      <div className="panel-footer">
        <div className="left-container">
          {meetingJoinUrl && (
            <Button size="lg" color="primary" onClick={this.handleJoinCall}>
              {t("joinCall")}
            </Button>
          )}
          {isEventControl && (
            <AccessManager permission="eventViewPanel:editEvent">
              <Button
                size="lg"
                color="secondary"
                onClick={this.handleEditEventClick}
              >
                {t("editEvent")}
              </Button>
            </AccessManager>
          )}
        </div>
        <div className="right-container">
          {isEventControl && (
            <AccessManager permission="eventViewPanel:deleteEvent">
              <span
                onClick={this.handleDeleteClicked}
                className="ciq-icon ciq-trash"
              />
            </AccessManager>
          )}
        </div>
      </div>
    );
  };

  renderGuestList = (): JSX.Element => {
    const { event } = this.state;
    const { t } = this.props;
    const { organizer, eventGuests } = event;

    return (
      <div className="user-list-container">
        <h2>Guests</h2>
        <div className="users-list">
          <div className="user-item organizer">
            <div className="left-container">
              {organizer && (
                <div className="users-avatar">
                  <Avatar
                    avatar={{
                      name: UtilsService.displayUserName(organizer),
                      imageUrl: organizer?.profileImageUrl
                    }}
                  />
                </div>
              )}
              <div className="user-name">
                {UtilsService.displayUserName(organizer)}
              </div>
            </div>
          </div>
          {eventGuests &&
            eventGuests
              .filter((guest) => guest.userId !== organizer.id)
              .map(({ user, email }) => (
                <div
                  className="user-item event-guest"
                  key={user ? user.id : email}
                >
                  <div className="left-container">
                    <AccessManager
                      permission="eventViewPanel:showClientUserInfo"
                      deniedElement={
                        <>
                          <div className="users-avatar">
                            <Avatar
                              avatar={{
                                name: user?.firstName
                                  ? UtilsService.displayUserName(user)
                                  : t("guestUser"),
                                imageUrl: user?.profileImageUrl
                              }}
                            />
                          </div>
                          <div className="user-name event-guest-name">
                            {user?.firstName
                              ? UtilsService.displayUserName(user)
                              : t("guestUser")}
                          </div>
                        </>
                      }
                    >
                      <div className="users-avatar">
                        <Avatar
                          avatar={{
                            name: user
                              ? UtilsService.displayUserName(user)
                              : email,
                            imageUrl: user?.profileImageUrl
                          }}
                        />
                      </div>
                      <div className="user-name event-guest-name">
                        {user ? UtilsService.displayUserName(user) : email}
                      </div>
                    </AccessManager>
                  </div>
                </div>
              ))}
        </div>
      </div>
    );
  };

  renderNotesAndAttachments = (): JSX.Element => {
    const {
      event: { eventAttachments, notes }
    } = this.state;
    const { t } = this.props;
    const isAttachmentsAvailable = eventAttachments
      ? eventAttachments.length !== 0
      : false;
    const isNotesAvailable = notes.length !== 0;
    if (!isAttachmentsAvailable && !isNotesAvailable) {
      return <></>;
    }

    return (
      <>
        <hr />
        <div className="bottom-container">
          <h2>{t("notes&Attachments")}</h2>
          {notes && (
            <div className="notes-container">
              <p>{notes}</p>
            </div>
          )}
          <div className="attachment-list-container">
            {eventAttachments
              ? eventAttachments.map((attachment) => (
                  <div
                    className="file-item"
                    key={attachment.id}
                    onClick={(): void =>
                      this.handleOnDocumentClicked(
                        attachment.id,
                        attachment.fileName
                      )
                    }
                  >
                    <div className="left-container">
                      <div className="file-type-icon">
                        {CIQFileIcon(attachment.fileName)}
                      </div>
                      <div className="detail-container">
                        <div className="file-name">
                          {DocumentService.prettifyFileName(
                            attachment.fileName
                          )}
                        </div>
                        <div className="file-size">
                          {DocumentService.prettifyFileSize(
                            attachment.fileSize
                          )}
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </>
    );
  };

  renderEventHeader = (): JSX.Element => {
    const { event } = this.state;
    const { title } = event;
    return (
      <div className="panel-header">
        <h2>{title}</h2>
        <ContentPanelContextConsumer>
          {({ closePanel }: ContentPanelContextProps): JSX.Element => (
            <div className="btn-close" onClick={closePanel}>
              <i className="ciq-icon ciq-close" />
            </div>
          )}
        </ContentPanelContextConsumer>
      </div>
    );
  };

  renderBackToCalender = (): JSX.Element => {
    const { t } = this.props;
    return (
      <div className="panel-footer">
        <Button className="btn-link" onClick={this.handleBackToCalenderClick}>
          {t("backToCalendar")}
        </Button>
      </div>
    );
  };

  render(): JSX.Element {
    const { isLoading } = this.state;

    return (
      <SimpleBar className="event-view-body content-panel-body simplebar-light">
        {isLoading ? (
          <Loader isSidePanel />
        ) : (
          <div className="event-container">
            <div className="top-container">
              {this.renderEventHeader()}
              {this.renderEventDetails()}
              {this.renderEventActions()}
            </div>
            <hr />
            <div className="middle-container">{this.renderGuestList()}</div>

            {this.renderNotesAndAttachments()}
            {this.renderBackToCalender()}
          </div>
        )}
      </SimpleBar>
    );
  }
}

const mapStateToProps = createStructuredSelector<
  AppState,
  EventSidePanelProps,
  EventSidePanelStoreProps
>({
  currentUserId: AuthSelector.authUserIdSelector(),
  currentTimeZone: AppSelector.appGuessCurrentTimeZoneSelector()
});

const mapDispatchToProps = (dispatch: Dispatch): Record<string, AppAction> => ({
  onEventEdit: (projectId: string, eventId: string): AppAction =>
    dispatch(push(PROJECT_EVENT_ROUTE(projectId, eventId))),
  onBackToCalender: (): AppAction => dispatch(push(PROJECT_CALENDAR))
});

export default withTranslation("eventSidePanel")(
  connect(mapStateToProps, mapDispatchToProps)(EventSidePanel)
);
