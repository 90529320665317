import { Handlers, createReducer } from "reduxsauce";

import { SAVE_PAGE_STATE } from "./actions/PagesActionTypes";
import { PagesReducerState } from "./models/definitions";
import { handleSavePageState } from "./reducers/PagesHandleSavePageReducer";
import { initialState } from "./reducers/PagesInitialState";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handlers: Handlers<PagesReducerState, any> = {
  [SAVE_PAGE_STATE]: handleSavePageState
};

export const PagesReducer = createReducer(initialState, handlers);
