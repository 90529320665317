import React from "react";

import styles from "./DrawerTitle.module.scss";

export const DrawerTitle: React.FC<{ title: string }> = ({ title }) => (
  <span className={styles.drawerTitle}>
    {`${title} `}
    <span className={styles.drawerSubtitle}>(optional)</span>
  </span>
);
