/* eslint-disable @typescript-eslint/no-explicit-any */
import { Handlers, createReducer } from "reduxsauce";

import { GET_ADMIN_USER_PROFILE } from "./actions/CacheGetAdminUserProfile/CacheGetAdminUserProfileActionTypes";
import {
  CACHE_GET_APP_DATA,
  CACHE_GET_APP_DATA_FAILURE,
  CACHE_GET_APP_DATA_SUCCESS
} from "./actions/CacheGetAppData/CacheGetAppDataActionTypes";
import { GET_APP_TYPE_USER } from "./actions/CacheGetAppType/CacheGetAppTypeActionTypes";
import { GET_LOGGED_IN_USER } from "./actions/CacheGetLoggedInUser/CacheGetLoggedInUserActionTypes";
import { CacheReducerState } from "./models/definitions";
import { checkIfIsAdmin } from "./reducers/CacheCheckIfIsAdminReducer";
import { handleGetLoggedInUser } from "./reducers/CacheHandleGetLoggedInUserReducer";
import {
  handleGetMetaData,
  handleGetMetaDataFailure,
  handleGetMetaDataSuccess
} from "./reducers/CacheHandleGetMetadataReducer";
import { handleGetUserProfile } from "./reducers/CacheHandleGetUserProfileReducer";
import { initialState } from "./reducers/CacheInitialState";

export const handlers: Handlers<CacheReducerState, any> = {
  [CACHE_GET_APP_DATA]: handleGetMetaData,
  [CACHE_GET_APP_DATA_SUCCESS]: handleGetMetaDataSuccess,
  [CACHE_GET_APP_DATA_FAILURE]: handleGetMetaDataFailure,
  [GET_LOGGED_IN_USER]: handleGetLoggedInUser,
  [GET_APP_TYPE_USER]: checkIfIsAdmin,
  [GET_ADMIN_USER_PROFILE]: handleGetUserProfile
};

export const CacheReducer = createReducer(initialState, handlers);
