import { Icon } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./CameraMicrophoneWarning.module.scss";

interface CameraMicrophoneWarningProps {
  handleHideCameraMicrophoneWarning: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

export const CameraMicrophoneWarning: React.FC<
  CameraMicrophoneWarningProps
> = ({ handleHideCameraMicrophoneWarning }) => {
  const { t } = useTranslation("cameraMicrophoneWarning");

  return (
    <div className={styles.container}>
      <p className={styles.message}>{t("enableSettings")}</p>
      <div className={styles.rightContainer}>
        <a
          href={t("helpUrl")}
          rel="noreferrer"
          target={"_blank"}
          className={styles.link}
        >
          {t("needHelp")}
        </a>
        <Icon
          name="close"
          fontSize="24px"
          onClick={handleHideCameraMicrophoneWarning}
        />
      </div>
    </div>
  );
};
