import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { zip } from "rxjs";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ProjectService,
  ProjectStats,
  ToasterService
} from "@arbolus-technologies/api";
import {
  ChromeExtensionBanner,
  MixpanelPages,
  PageTracker,
  ProjectData,
  SimpleCanopyCreationProvider,
  SlidePanel
} from "@arbolus-technologies/features/common";
import { PanelId } from "@arbolus-technologies/stores/panels";
import { Loader } from "@arbolus-technologies/ui/components";
import { MainPageLayout } from "@arbolus-technologies/ui/layout";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { HeaderButtons } from "../Components/HeaderButtons/HeaderButtons";
import { UpdateProjectStateButton } from "../Components/UpdateProjectStateButton/UpdateProjectStateButton";
import { useAngleQuery } from "../Hooks/useAngleQuery";
import { ProjectAngles } from "../Modules/ProjectAngles/ProjectAngles";
import { ProjectAvailabilities } from "../Modules/ProjectAvailabilities/ProjectAvailabilities";
import { ProjectCanopy } from "../Modules/ProjectCanopy/ProjectCanopy";
import { ClientProjectDocuments } from "../Modules/ProjectDocuments/ClientProjectDocuments";
import { ProjectEvents } from "../Modules/ProjectEvents/ProjectEvents";
import { ProjectManagementMenu } from "../Modules/ProjectManagementMenu/ProjectManagementMenu";
import { ProjectTranscripts } from "../Modules/ProjectTranscripts/ProjectTranscripts";
import { ClientActionsMenu } from "../Modules/QuickActionsMenu/ClientActionsMenu";
import { getCurrentAngleId } from "../helpers";

import styles from "./ProjectDashboard.module.scss";

export interface ClientProjectDashboardProps {
  projectService?: typeof ProjectService;
  notificationService?: ToasterService;
}

export const ClientProjectDashboard: React.FC<ClientProjectDashboardProps> = ({
  projectService = ProjectService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("clientProjectDashboard");
  const { projectId } = useParams<{ projectId: string }>();
  const [projectData, setProjectData] = useState<ProjectData>();
  const [projectStats, setProjectStats] = useState<ProjectStats>();
  const [expertsCount, setExpertsCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const angleId = useAngleQuery();

  const getProjectDataAndStats = useCallback(
    (angleId?: string | null): void => {
      setIsLoading(true);
      zip(
        projectService.getProject(projectId),
        projectService.getProjectStats(projectId, angleId),
        projectService.getSearchStats(projectId)
      ).subscribe(
        ([projectData, projectStats, searchStats]) => {
          setProjectData(projectData);
          setProjectStats(projectStats);
          setExpertsCount(searchStats.expertsCount);
          setIsLoading(false);
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
          setIsLoading(false);
        }
      );
    },
    [notificationService, projectId, projectService]
  );

  useEffect(() => {
    if (getCurrentAngleId() === angleId) {
      getProjectDataAndStats(angleId);
    }
  }, [angleId, getProjectDataAndStats]);

  return (
    <>
      {isLoading && <Loader isFull />}
      {!isLoading && (
        <>
          {projectData && projectStats && (
            <PageTracker page={MixpanelPages.ProjectDashboard}>
              <MainPageLayout
                title={projectData.name}
                rightButtonContainer={
                  <HeaderButtons
                    projectId={projectData.id}
                    unreadMessagesCount={projectStats.unreadMessageCount}
                  />
                }
              >
                {projectData?.targetCompany && <ChromeExtensionBanner />}
                <ProjectCanopy projectId={projectData.id} />
                <ProjectAngles projectData={projectData} />
                <ClientActionsMenu
                  projectId={projectData.id}
                  projectStats={projectStats}
                  expertsCount={expertsCount}
                  isLoading={isLoading}
                />
                <div className={styles.splitView}>
                  <ProjectEvents
                    projectId={projectData.id}
                    timezone={projectData.timezone}
                  />
                  <ProjectAvailabilities
                    projectId={projectData.id}
                    projectName={projectData.name}
                    timezone={projectData.timezone}
                  />
                </div>
                <ProjectTranscripts
                  projectId={projectData.id}
                  timezone={projectData.timezone}
                />
                <ClientProjectDocuments projectId={projectData.id} />
                <ProjectManagementMenu projectId={projectData.id} />
                <UpdateProjectStateButton
                  projectId={projectData.id}
                  projectState={projectData.projectState}
                />
              </MainPageLayout>
              <SlidePanel
                title={<div>{t("newCanopy")}</div>}
                panelId={PanelId.CreateSimpleCanopy}
                closeButtonDirection="right"
                width={SIDE_PANEL_SIZE._720}
              >
                <SimpleCanopyCreationProvider projectId={projectId} />
              </SlidePanel>
            </PageTracker>
          )}
        </>
      )}
    </>
  );
};
