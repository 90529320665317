import { Icon } from "arbolus-ui-components";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Markdown from "react-markdown";
import { Link } from "react-router-dom";

import { DataMessageGPT } from "@arbolus-technologies/models/project";
import {
  CANOPY_INDIVIDUAL_RESPONSE_FROM_SUMMARY_ROUTE,
  PROJECT_TRANSCRIPT
} from "@arbolus-technologies/routes";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { HR } from "@arbolus-technologies/ui/components";
import { APP_DATE_FORMAT_WITH_DAY } from "@arbolus-technologies/utils";

import { MessageGPT } from "./ArbolusGPTPanel";

import styles from "./MessagesComponent.module.scss";

interface MessagesComponentProps {
  loading: boolean;
  messages: {
    messageSent?: string;
    gpt?: MessageGPT;
  }[];
  projectId: string;
}

export const MessagesComponent: React.FC<MessagesComponentProps> = ({
  messages,
  loading,
  projectId
}) => {
  const { t } = useTranslation("arbolusGPT");

  const today = moment().format(APP_DATE_FORMAT_WITH_DAY);

  const messagesRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // Scroll to the bottom of the div when messages change
    messagesRef.current!.scrollTop = messagesRef.current!.scrollHeight;
  }, [messages]);

  const handleSources = (data: DataMessageGPT[]) => {
    const canopies = data.filter(
      (source) => source.canopyId && source.canopyAnswerId
    );
    const transcripts = data.filter((source) => source.transcriptHeaderId);

    return [
      ...transcripts.map((transcript, i) => (
        <Link
          key={transcript.transcriptHeaderId}
          to={PROJECT_TRANSCRIPT(projectId, transcript.transcriptHeaderId!)}
          className={styles.sourceLink}
          target="_blank"
        >
          <Icon
            name="speech_to_text"
            color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
            fontSize="16px"
          />
          {t("transcript", { index: i + 1 })}
        </Link>
      )),
      ...canopies.map((canopy, i) => (
        <Link
          key={canopy.canopyAnswerId}
          to={CANOPY_INDIVIDUAL_RESPONSE_FROM_SUMMARY_ROUTE(
            canopy.canopyId!,
            canopy.canopyAnswerId!
          )}
          className={styles.sourceLink}
          target="_blank"
        >
          <Icon
            name="workspaces"
            color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
            fontSize="16px"
          />
          {t("canopy", { index: i + 1 })}
        </Link>
      ))
    ];
  };

  return (
    <div className={styles.container} ref={messagesRef}>
      <div className={styles.dateContainer}>
        <div className={styles.date}>{today}</div>
        <HR margin={{ top: 1, bottom: 1 }} />
      </div>
      {messages.map((message, i) => (
        <div key={message.messageSent} className={styles.messageContainer}>
          <div className={styles.messageBlock}>
            <div className={styles.youTag}>
              <div className={styles.roundedMeIcon}>
                <Icon name="person" color="gray" fontSize="16px" />
              </div>
              <span>{t("you")}</span>
            </div>
            <span className={styles.message}>{message.messageSent}</span>
          </div>
          <div className={styles.messageBlock}>
            <div className={styles.gptTag}>
              <div className={styles.roundedGptIcon}>
                <Icon name="magic_button" color="white" fontSize="16px" />
              </div>
              <span>{t("arbolusGpt")}</span>
            </div>
            {message.gpt?.message && (
              <>
                <Markdown className={styles.message}>
                  {message.gpt.message}
                </Markdown>
                <div className={styles.sourceContainer}>
                  <div className={styles.sourceTitle}>
                    <Icon
                      name="format_quote"
                      color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
                      fontSize="16px"
                      filled
                    />
                    <span>{t("sources")}</span>
                  </div>
                  <div className={styles.sources}>
                    {handleSources(message.gpt.data)}
                  </div>
                </div>
              </>
            )}
          </div>
          {i < messages.length - 1 && <HR margin={{ top: 2, bottom: 2 }} />}
        </div>
      ))}
      {loading && <div className={styles.dotStretching} />}
    </div>
  );
};
