import React from "react";
import { useTranslation } from "react-i18next";

import { magnifyingGlass } from "@arbolus-technologies/theme";

import styles from "./InitialSearchPlaceholder.module.scss";

export const InitialSearchPlaceholder: React.FC = () => {
  const { t } = useTranslation("discoverPage");

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <img
          src={magnifyingGlass}
          alt={t("initialSearchIconAltText")}
          className={styles.image}
        />
        <h1 className={styles.title}>{t("initialSearchTitle")}</h1>
      </div>
      <p className={styles.text}>{t("initialSearchText")}</p>
    </div>
  );
};
