import { CacheSelector } from "@arbolus-technologies/stores/cache";
import React from "react";
import { useSelector } from "react-redux";
import { ChromeExtensionCanopyBanner } from "./components/ChromeExtensionCanopyBanner/ChromeExtensionCanopyBanner";
import { ChromeExtensionProjectBanner } from "./components/ChromeExtensionProjectBanner/ChromeExtensionProjectBanner";

interface ChromeExtensionBannerProps {
  isCanopy?: boolean;
}

const clientIdsWithoutExtensionBanner = [
  "4c1060bb-88ea-4755-a4ee-78ffe04296d4" //FPE Capital
];

export const ChromeExtensionBanner: React.FC<ChromeExtensionBannerProps> = ({
  isCanopy
}) => {
  const clientUser = useSelector(CacheSelector.loggedInUser());
  const isBannerHidden = clientIdsWithoutExtensionBanner.some(
    (id) => clientUser.clientId === id
  );

  const bannerToShow = isCanopy ? (
    <ChromeExtensionCanopyBanner />
  ) : (
    <ChromeExtensionProjectBanner />
  );

  return isBannerHidden ? <></> : bannerToShow;
};
