import clsx from "clsx";
import React, { HTMLAttributeAnchorTarget, ReactNode } from "react";
import { Link } from "react-router-dom";

import styles from "./LinkWrapper.module.scss";

interface LinkWrapperProps {
  children: ReactNode;
  to: string;
  onClick?: () => void;
  classnames?: string;
  target?: HTMLAttributeAnchorTarget;
  isExternal?: boolean;
}
export const LinkWrapper: React.FC<LinkWrapperProps> = ({
  children,
  to,
  onClick,
  classnames,
  target = "_self",
  isExternal = false
}) => (
  <Link
    target={target}
    to={isExternal ? { pathname: to } : to}
    onClick={onClick}
    className={clsx(styles.link, classnames)}
  >
    {children}
  </Link>
);
