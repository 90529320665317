import { OutputSelector, createSelector } from "reselect";

import {
  InputModalData,
  ListModalData,
  ModalData,
  PanelAppState,
  PanelId,
  PanelReducerState
} from "../models/definitions";

const PanelStateSelector = (state: PanelAppState): PanelReducerState =>
  state.panels;

// Panel Open Selector
const isPanelOpen = (
  panelId: PanelId
): OutputSelector<
  PanelAppState,
  boolean,
  (res: PanelReducerState) => boolean
> =>
  createSelector<PanelAppState, PanelReducerState, boolean>(
    PanelStateSelector,
    (panelReducerState) => {
      const panelSelected = panelReducerState.panels.find(
        (panel) => panel.id === panelId
      );
      return panelSelected!.isOpen;
    }
  );

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const panelData = <T = any>(
  panelId: PanelId
): OutputSelector<PanelAppState, T, (res: PanelReducerState) => T> =>
  createSelector<PanelAppState, PanelReducerState, T>(
    PanelStateSelector,
    (panelReducerState) => {
      const panelSelected = panelReducerState.panels.find(
        (panel) => panel.id === panelId
      );
      return panelSelected!.data;
    }
  );

const isMainModalOpen = (): OutputSelector<
  PanelAppState,
  boolean,
  (res: PanelReducerState) => boolean
> =>
  createSelector<PanelAppState, PanelReducerState, boolean>(
    PanelStateSelector,
    (state) => state.mainModal.isOpen
  );

const mainModalData = (): OutputSelector<
  PanelAppState,
  ModalData,
  (res: PanelReducerState) => ModalData
> =>
  createSelector<PanelAppState, PanelReducerState, ModalData>(
    PanelStateSelector,
    (state) => {
      const { isOpen, ...modalData } = state.mainModal;
      return modalData;
    }
  );

const isListMainModalOpen = (): OutputSelector<
  PanelAppState,
  boolean,
  (res: PanelReducerState) => boolean
> =>
  createSelector<PanelAppState, PanelReducerState, boolean>(
    PanelStateSelector,
    (state) => state.listMainModal.isOpen
  );

const listMainModalData = (): OutputSelector<
  PanelAppState,
  ListModalData,
  (res: PanelReducerState) => ListModalData
> =>
  createSelector<PanelAppState, PanelReducerState, ListModalData>(
    PanelStateSelector,
    (state) => {
      const { isOpen, ...modalData } = state.listMainModal;
      return modalData;
    }
  );

const isInputMainModalOpen = (): OutputSelector<
  PanelAppState,
  boolean,
  (res: PanelReducerState) => boolean
> =>
  createSelector<PanelAppState, PanelReducerState, boolean>(
    PanelStateSelector,
    (state) => state.inputMainModal.isOpen
  );

const inputMainModalData = (): OutputSelector<
  PanelAppState,
  InputModalData,
  (res: PanelReducerState) => InputModalData
> =>
  createSelector<PanelAppState, PanelReducerState, InputModalData>(
    PanelStateSelector,
    (state) => {
      const { isOpen, ...modalData } = state.inputMainModal;
      return modalData;
    }
  );

export const PanelSelector = {
  isPanelOpen,
  panelData,
  isMainModalOpen,
  mainModalData,
  isListMainModalOpen,
  listMainModalData,
  isInputMainModalOpen,
  inputMainModalData
};
