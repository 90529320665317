import { Button } from "arbolus-ui-components";
import React from "react";

export interface BackButtonProps {
  title: string;
  onClick: () => void;
}

export const BackButton: React.FC<BackButtonProps> = ({ title, onClick }) => (
  <Button
    type="tertiary"
    onClick={onClick}
    startIcon="arrow_back_ios"
    text={title}
  />
);
