import { OutputSelector, createSelector } from "reselect";

import {
  BookmarkList,
  PaginatedStoreHasNextPage
} from "@arbolus-technologies/models/common";

import { BookmarksStateSelector } from "../BookmarksSelector";
import {
  BookmarksAppState,
  BookmarksReducerState
} from "../models/definitions";

export const bookmarksList = (): OutputSelector<
  BookmarksAppState,
  BookmarkList[] | null,
  (res: BookmarksReducerState) => BookmarkList[] | null
> =>
  createSelector<
    BookmarksAppState,
    BookmarksReducerState,
    BookmarkList[] | null
  >(
    BookmarksStateSelector,
    (bookmarksReducerState) =>
      bookmarksReducerState.bookmarksList.store?.items || null
  );

export const bookmarksListHasNextPage = (): OutputSelector<
  BookmarksAppState,
  boolean,
  (res: BookmarksReducerState) => boolean
> =>
  createSelector<BookmarksAppState, BookmarksReducerState, boolean>(
    BookmarksStateSelector,
    (bookmarksReducerState) =>
      PaginatedStoreHasNextPage(bookmarksReducerState.bookmarksList.store)
  );

export const bookmarksListSearchTerm = (): OutputSelector<
  BookmarksAppState,
  string,
  (res: BookmarksReducerState) => string
> =>
  createSelector<BookmarksAppState, BookmarksReducerState, string>(
    BookmarksStateSelector,
    (bookmarksReducerState) =>
      bookmarksReducerState.bookmarksList.searchTerm || ""
  );

export const bookmarksListIsLoading = (): OutputSelector<
  BookmarksAppState,
  boolean,
  (res: BookmarksReducerState) => boolean
> =>
  createSelector<BookmarksAppState, BookmarksReducerState, boolean>(
    BookmarksStateSelector,
    (bookmarksReducerState) => bookmarksReducerState.bookmarksList.isLoading
  );
