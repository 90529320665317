import { Button } from "antd";
import clsx from "clsx";
import { LocationDescriptor } from "history";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import { AntDIcon, IconName } from "@arbolus-technologies/antDComponents";

import styles from "./ChevronButton.module.scss";

interface IconDirection {
  left: string;
  right: string;
  up: string;
  down: string;
}

export interface ChevronButtonProps {
  text?: string;
  fontWeight?: number;
  fontSize?: string;
  icon: {
    position: "start" | "end";
    direction: "left" | "right" | "up" | "down";
    size?: string;
  };
  to?: LocationDescriptor;
  onClick?: () => void;
  disabled?: boolean;
  nativeType?: "submit" | "reset" | "button";
}

const chevronIcon: IconDirection = {
  left: "chevronLeft",
  right: "chevronRight",
  up: "expandLess",
  down: "expandMore"
};

export const ChevronButton: React.FC<ChevronButtonProps> = ({
  text,
  fontWeight = 600,
  fontSize = "14px",
  icon: { position, direction, size = "20px" },
  to,
  onClick,
  disabled = false,
  nativeType = "button"
}) => {
  const iconPosition = (direction: "left" | "right" | "up" | "down") => {
    if (direction === "left") return "chevronLeft";
    if (direction === "right") return "chevronRight";
    if (direction === "up") return "arrowUp";
    if (direction === "down") return "arrowDown";
    else return "chevronRight";
  };

  const buttonMemoized = useMemo(
    () => (
      <Button
        type="link"
        icon={<AntDIcon name={iconPosition(direction) as IconName} />}
        iconPosition={position}
        disabled={disabled}
        onClick={onClick}
      >
        {text}
      </Button>
    ),
    [disabled, fontSize, fontWeight, nativeType, onClick, position, text]
  );

  return to ? (
    <Link
      to={to}
      className={clsx(styles.link, { [styles.disabled]: disabled })}
    >
      {buttonMemoized}
    </Link>
  ) : (
    buttonMemoized
  );
};
