import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CIQError,
  CanopyService,
  DefaultToasterService,
  ErrorResponse,
  ToasterService
} from "@arbolus-technologies/api";
import {
  MixpanelPages,
  PageTracker
} from "@arbolus-technologies/features/common";
import { ExpertCanopyItem } from "@arbolus-technologies/models/canopy";
import { InfiniteScrollWithHeader } from "@arbolus-technologies/ui/components";
import { AntDHeader } from "@arbolus-technologies/ui/layout";
import { useDocumentTitle } from "@arbolus-technologies/utils";

import { CanopyCard } from "../../Components/CanopyCard/CanopyCard";
import {
  CanopyListFilterParams,
  CanopyListFilters
} from "../../Components/CanopyListFilters/CanopyListFilters";

import styles from "./ExpertCanopyList.module.scss";

interface ExpertCanopyListProps {
  canopyService?: typeof CanopyService;
  toasterService?: ToasterService;
}

export const ExpertCanopyList: React.FC<ExpertCanopyListProps> = ({
  canopyService = CanopyService,
  toasterService = DefaultToasterService
}): JSX.Element => {
  const { t } = useTranslation("expertCanopyList");
  useDocumentTitle("expertCanopyList", "arbolusCanopy");

  const [canopies, setCanopies] = useState<ExpertCanopyItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isMore, setIsMore] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<CanopyListFilterParams>({
    searchTerm: ""
  });

  useEffect(() => {
    getCanopies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCanopies = useCallback(
    (
      searchTerm = filterParams.searchTerm,
      status = filterParams.status,
      restart = false
    ) => {
      if (!isMore && !restart) {
        return;
      }

      if (restart) {
        setCanopies([]);
      }

      setIsLoading(true);

      canopyService
        .getExpertCanopies(
          searchTerm,
          status,
          (!restart && canopies?.length) || 0
        )
        .subscribe(
          ({ items, pagination }) => {
            setCanopies((prev) =>
              prev && !restart ? [...prev, ...items] : [...items]
            );
            setIsMore(
              pagination.count >
                ((!restart && canopies?.length) || 0) + items.length
            );

            setIsLoading(false);
          },
          (error: ErrorResponse<CIQError>) => {
            setIsLoading(false);
            toasterService.showError(error.message);
          }
        );
    },
    [
      canopies?.length,
      canopyService,
      filterParams.searchTerm,
      filterParams.status,
      isMore,
      toasterService
    ]
  );

  const renderer = (canopy: ExpertCanopyItem) => (
    <CanopyCard
      key={canopy.id}
      id={canopy.id}
      status={canopy.status}
      title={canopy.title}
    />
  );

  const onFilterChange = useCallback(
    (filterParams: CanopyListFilterParams) => {
      setFilterParams(filterParams);
      getCanopies(filterParams.searchTerm, filterParams.status, true);
    },
    [getCanopies]
  );

  return (
    <PageTracker page={MixpanelPages.Canopies}>
      <div className={styles.container}>
        <InfiniteScrollWithHeader
          smallMarginBottom
          classnames={styles.infiniteScroll}
          rendererContainerClassnames={styles.cardsContainer}
          items={canopies}
          itemHeight={59}
          isLoading={isLoading}
          nextItems={() => getCanopies()}
          renderer={renderer}
        >
          <div className={styles.filterContainer}>
            <AntDHeader title={t("arbolusCanopy")} />
            <CanopyListFilters onFilterChange={onFilterChange} />
          </div>
        </InfiniteScrollWithHeader>
      </div>
    </PageTracker>
  );
};
