import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { ProjectSettings } from "@arbolus-technologies/api";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import {
  Button,
  CustomSwitchController,
  HR
} from "@arbolus-technologies/ui/components";

import { validProjectSettings } from "../../ProjectNotificationsSlidePanel/ExpertProjectNotificationsSlidePanel";

import styles from "./ExpertProjectNotificationsCheckboxForm.module.scss";

interface ExpertProjectNotificationsCheckboxFormInterface {
  projectSettings: ProjectSettings;
}

interface ExpertProjectNotificationsCheckboxFormProps {
  formName: string;
  projectSettings: ProjectSettings;
  onSave: (projectSettings: ProjectSettings) => void;
}

export const ExpertProjectNotificationsCheckboxForm: React.FC<
  ExpertProjectNotificationsCheckboxFormProps
> = ({ formName, projectSettings, onSave }) => {
  const { t } = useTranslation(formName);
  const dispatch = useDispatch();

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm<ExpertProjectNotificationsCheckboxFormInterface>({
    defaultValues: {
      projectSettings
    }
  });

  const onSubmit = (form: ExpertProjectNotificationsCheckboxFormInterface) => {
    onSave(form.projectSettings);
  };

  const handleCancel = () => {
    dispatch(PanelStoreActions.closePanel(PanelId.ProjectNotifications));
  };

  return (
    <>
      <div className={styles.textContainer}>
        <span className={styles.subtitle}>{t("instantAlerts")}</span>
        <div>{t("alertsMessage")}</div>
        <CustomSwitchController
          name="projectSettings.pushNotifications"
          label={t("browserNotification")}
          errors={errors}
          control={control}
        />
      </div>
      <HR margin={{ top: 3, bottom: 3 }} />
      <div className={styles.textContainer}>
        <span className={styles.subtitle}>{t("emails")}</span>
        <div>{t("emailMessage")}</div>
        <div className={styles.container}>
          {Object.keys(projectSettings.notificationSubscription).map(
            (key) =>
              validProjectSettings.includes(key) && (
                <CustomSwitchController
                  key={key}
                  name={`projectSettings.notificationSubscription.${key}`}
                  subtitle={t(`${key}Description`)}
                  label={t(key)}
                  errors={errors}
                  control={control}
                />
              )
          )}
        </div>
      </div>
      <HR margin={{ top: 3, bottom: 3 }} />
      <div className={styles.textContainer}>
        <span className={styles.subtitle}>{t("sms")}</span>
        <div>{t("smsMessage")}</div>
        <CustomSwitchController
          name="projectSettings.notificationSubscription.MobileMessage"
          label={t("smsNotification")}
          errors={errors}
          control={control}
        />
      </div>
      <HR margin={{ top: 3, bottom: 3 }} />
      <div className={styles.buttonsContainer}>
        <Button type="tertiary" text={t("cancel")} onClick={handleCancel} />
        <Button text={t("save")} onClick={handleSubmit(onSubmit)} />
      </div>
    </>
  );
};
