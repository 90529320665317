import { OutputSelector, createSelector } from "reselect";

import { CacheStateSelector } from "../CacheSelector";
import { CacheAppState, CacheReducerState } from "../models/definitions";

export const hasNotifications = (): OutputSelector<
  CacheAppState,
  boolean,
  (res: CacheReducerState) => boolean
> =>
  createSelector<CacheAppState, CacheReducerState, boolean>(
    CacheStateSelector,
    (cacheReducerState) =>
      Object.values(cacheReducerState.notificationCounts).some(
        (count) => count > 0
      )
  );
