import { Button } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";

import { DiscoverFilters } from "../../../../Modules/DiscoverFilters/DiscoverFilters";

import styles from "./MobileFilterPage.module.scss";

interface MobileFilterPageProps {
  handleCloseFilters: () => void;
}

export const MobileFilterPage: React.FC<MobileFilterPageProps> = ({
  handleCloseFilters
}) => {
  const { t } = useTranslation("discoverPage");

  return (
    <div className={styles.filterPage}>
      <div className={styles.filterHeader}>
        <h1>{t("mobileFilterHeader")}</h1>
        <Button
          type="tertiary"
          text={t("close")}
          onClick={handleCloseFilters}
        />
      </div>
      <DiscoverFilters withoutHeader />
    </div>
  );
};
