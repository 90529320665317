import { Icon } from "arbolus-ui-components";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  DefaultToasterService,
  ToasterService
} from "@arbolus-technologies/api";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  ArbolusTooltip,
  ArbolusTooltipPlace,
  Tag
} from "@arbolus-technologies/ui/components";

import styles from "./EmailPhoneComponent.module.scss";

interface EmailPhoneComponentProps {
  arrayOfStrings: string[];
  iconName: string;
  notificationService?: ToasterService;
}

export const EmailPhoneComponent: React.FC<EmailPhoneComponentProps> = ({
  arrayOfStrings,
  iconName,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("pipelineExpertCard");

  const handleCopy = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() =>
        notificationService.showInfo(i18next.t("clipboard:copiedToClipboard"))
      )
      .catch(() => {
        notificationService.showError(
          i18next.t("clipboard:copiedToClipboardError")
        );
      });
  };

  return (
    <div className={styles.iconInfo}>
      <Icon
        name={iconName}
        fontSize="16px"
        color={
          arrayOfStrings.length > 0
            ? ARBOLUS_COLORS.bColorAccentGreenDark
            : ARBOLUS_COLORS.bColorSecondaryGreyDark
        }
      />
      {arrayOfStrings.length > 0 ? (
        <div className={styles.containerList}>
          {arrayOfStrings.slice(0, 3).map((item, index) => (
            <React.Fragment key={item}>
              <div
                key={item}
                className={styles.containerItem}
                onClick={() => handleCopy(item)}
                title={t("copyToClipboard")}
              >
                {item}
              </div>
              {index < 2 && index < arrayOfStrings.length - 1 && <div>•</div>}
            </React.Fragment>
          ))}
          {arrayOfStrings.length > 3 && (
            <ArbolusTooltip
              content={arrayOfStrings.slice(3).map((item) => (
                <span key={item} onClick={() => handleCopy(item)}>
                  {item}
                </span>
              ))}
              place={ArbolusTooltipPlace.RIGHT}
            >
              <Tag
                text={`+${arrayOfStrings.length - 3}`}
                color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
              />
            </ArbolusTooltip>
          )}
        </div>
      ) : (
        <span className={styles.notAvailable}>
          {t(
            `no${iconName.charAt(0).toUpperCase() + iconName.slice(1)}Available`
          )}
        </span>
      )}
    </div>
  );
};
