import React, { ChangeEvent } from "react";

import { CIQFormInput } from "../CIQFormInput/CIQFormInput";

export const LinkedInURLInput: typeof CIQFormInput = (props) => {
  const modifiedProps = { ...props };
  modifiedProps.field.onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = props.field;
    const { value } = event.target;
    const { setFieldValue } = props.form;
    try {
      setFieldValue(name, decodeURI(value));
    } catch (error) {
      setFieldValue(name, value);
    }
  };

  return <CIQFormInput {...modifiedProps} />;
};
