import React from "react";

import { SelectOption } from "@arbolus-technologies/models/common";

import { CustomSelect } from "../Selects/components/CustomSelect/CustomSelect";
import {
  InternalArbolusModal,
  InternalArbolusModalProps
} from "./InternalArbolusModal";

import styles from "./ArbolusModalSelectable.module.scss";

interface ArbolusModalSelectableProps extends InternalArbolusModalProps {
  options: SelectOption[];
  defaultValue: SelectOption;
  onSelectChange: (value: string) => void;
}

export const ArbolusModalSelectable: React.FC<ArbolusModalSelectableProps> = ({
  title,
  subtitle,
  isOpen,
  toggle,
  iconTitle,
  leftButton,
  rightButton,
  options,
  defaultValue,
  onSelectChange
}) => (
  <InternalArbolusModal
    title={title}
    subtitle={subtitle}
    isOpen={isOpen}
    toggle={toggle}
    iconTitle={iconTitle}
    leftButton={leftButton}
    rightButton={rightButton}
    body={
      <div className={styles.dropdown}>
        <CustomSelect
          hasMenuPositionFixed
          defaultValue={defaultValue}
          onSelectChange={onSelectChange}
          options={options}
          noOptionsMessage={""}
          displayIcon
        />
      </div>
    }
  />
);
