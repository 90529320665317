import React from "react";

import { CardFooterData, CardHeaderData } from "../../../interfaces";
import { CardFooter } from "./CardFooter";
import { CardHeader } from "./CardHeader";

import styles from "./MatchingFiltersWrapper.module.scss";

interface MatchingFiltersWrapperProps {
  cardHeaderData?: CardHeaderData;
  cardFooterData?: CardFooterData;
  children: React.ReactNode;
}

export const MatchingFiltersWrapper: React.FC<MatchingFiltersWrapperProps> = ({
  cardHeaderData,
  cardFooterData,
  children
}) => (
  <div className={styles.matchingFiltersWrapper}>
    {cardHeaderData && <CardHeader headerData={cardHeaderData} />}
    {children}
    {cardFooterData && <CardFooter footerData={cardFooterData} />}
  </div>
);
