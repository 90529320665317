import React from "react";

import { Project } from "@arbolus-technologies/api";
import {
  Referral,
  Slot,
  SlotMoment
} from "@arbolus-technologies/models/common";
import { HR } from "@arbolus-technologies/ui/components";

import { DefaultFormatDateService } from "../../Services/Referrals/FormatDateService";
import { SingleSlot } from "./SingleSlot";

import styles from "./SlotsByDay.module.scss";

interface SlotsByDayProps {
  referral: Referral;
  slots: Slot[];
  project: Project;
  timezone: string;
}

export const SlotsByDay: React.FC<SlotsByDayProps> = ({
  referral,
  project,
  slots,
  timezone
}) => {
  const slotsByDay = React.useMemo(
    () => groupSlotsByDay(slots, timezone),
    [slots, timezone]
  );

  if (!slotsByDay.length) {
    return null;
  }

  return (
    <div className={styles.container}>
      {slotsByDay.map((slots) => (
        <div
          className={styles.row}
          key={slots[0].startTime.format("YYYY-MM-DD")}
        >
          <div className={styles.day}>
            {DefaultFormatDateService.formatDayMoment(slots[0].startTime)}
          </div>
          <div className={styles.slots}>
            {slots.map((slot) => (
              <SingleSlot
                key={slot.identifier}
                referral={referral}
                slot={slot}
                project={project}
                timezone={timezone}
              />
            ))}
          </div>
        </div>
      ))}
      <HR />
    </div>
  );
};

function groupSlotsByDay(slots: Slot[], timezone: string): SlotMoment[][] {
  const grouped = slots.reduce(
    (acc, slot) => {
      const slotMoment = new SlotMoment(slot, timezone);
      const day = slotMoment.startTime.format("YYYY-MM-DD");
      if (!acc[day]) {
        acc[day] = [];
      }
      acc[day].push(slotMoment);
      return acc;
    },
    {} as Record<string, SlotMoment[]>
  );

  for (const key in grouped) {
    grouped[key].sort((a, b) => a.compare(b));
  }

  return Object.values(grouped).sort(
    (a, b) => a[0].startTime.unix() - b[0].startTime.unix()
  );
}
