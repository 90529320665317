import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";

import { Optional } from "@arbolus-technologies/utils";

import styles from "./TableSetFilter.module.scss";

export interface SetOption<T> {
  displayName: string;
  type: T;
}

interface CIQTableSetFilterProps<T> {
  displayName: string;
  options: SetOption<T>[];
  onSetFilterChange: (selectedOption: Optional<T>) => void;
}

export const CIQTableSetFilter = <T,>({
  displayName,
  options,
  onSetFilterChange
}: CIQTableSetFilterProps<T>): React.ReactElement => {
  const { t } = useTranslation("table");
  const [currentSetFilter, setCurrentSetFilter] = useState<SetOption<T>>();
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    onSetFilterChange(currentSetFilter?.type);
  }, [onSetFilterChange, currentSetFilter]);

  const changeFilter = (option: Optional<SetOption<T>>) => {
    setCurrentSetFilter(option);
  };

  return (
    <div className="d-flex flex-row align-items-center">
      <Dropdown
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
        className="w-100"
      >
        <DropdownToggle className={styles.dropdown} color="">
          {currentSetFilter?.displayName ?? displayName}
        </DropdownToggle>
        <DropdownMenu className={styles.dropdownMenu}>
          <DropdownItem onClick={() => changeFilter(undefined)}>
            {t("headerSetAll")}
          </DropdownItem>
          {options.map((option) => (
            <DropdownItem
              key={option.displayName}
              onClick={() => changeFilter(option)}
            >
              {option.displayName}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
      <i
        className={clsx(
          "ml-auto",
          "ciq-icon",
          "ciq-component-icon-ic-filter-2",
          styles.setIcon
        )}
      />
    </div>
  );
};
