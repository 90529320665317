import { Icon } from "arbolus-ui-components";
import React from "react";
import { useTranslation } from "react-i18next";

import { ANSWER_TYPE } from "@arbolus-technologies/models/canopy";

import styles from "./QuestionType.module.scss";

interface QuestionTypeProps {
  type: ANSWER_TYPE;
}

export const QuestionType: React.FC<QuestionTypeProps> = ({ type }) => {
  const { t } = useTranslation("canopyComponents");
  return (
    <div className={styles.container}>
      {type === ANSWER_TYPE.VIDEO && (
        <>
          <Icon fontSize="24px" name="videocam" />
          <span>{t("video")}</span>
        </>
      )}
      {type === ANSWER_TYPE.MULTIPLE_CHOICE && (
        <>
          <Icon fontSize="24px" name="notes" />
          <span>{t("multiChoice")}</span>
        </>
      )}
      {type === ANSWER_TYPE.VALUE && (
        <>
          <Icon fontSize="24px" name="numbers" />
          <span>{t("value")}</span>
        </>
      )}
      {type === ANSWER_TYPE.SHORT_TEXT && (
        <>
          <Icon fontSize="24px" name="short_text" />
          <span>{t("shortText")}</span>
        </>
      )}
      {type === ANSWER_TYPE.NPS && (
        <>
          <Icon fontSize="24px" name="analytics" />
          <span>{t("nps")}</span>
        </>
      )}
    </div>
  );
};
