import i18next from "i18next";
import * as Yup from "yup";

import { COMPLIANCE_ANSWER_OPTIONS } from "../../helpers/constants";

export interface ComplianceFormValues {
  complianceAnswers: {
    questionId: string;
    answer: string;
    textAnswer: string;
    textRequiredValue: string | null;
  }[];
}

const ComplianceQuestionSchema = Yup.object().shape({
  questionId: Yup.string().required(),
  textRequiredValue: Yup.string().nullable(),
  answer: Yup.mixed<COMPLIANCE_ANSWER_OPTIONS>().test(
    "hasAnswer",
    i18next.t("expertApplications:answerRequiredError"),
    (val) => {
      const options = Object.values(COMPLIANCE_ANSWER_OPTIONS);

      if (options.includes(val)) {
        return true;
      }

      return false;
    }
  ),
  textAnswer: Yup.string()
    .nullable()
    .when(
      ["answer", "textRequiredValue"],
      (
        answer: string,
        textAnswerRequired: string,
        schema: Yup.StringSchema
      ) => {
        if (answer === textAnswerRequired) {
          return schema
            .required(i18next.t("expertApplications:textAnswerRequiredError"))
            .test(
              "hasText",
              i18next.t("expertApplications:textAnswerRequiredError"),
              (val = "") => {
                if (val.trim()) {
                  return true;
                }

                return false;
              }
            );
        }
        return schema.nullable().notRequired();
      }
    )
});

export const ComplianceQuestionsSchema = Yup.object().shape({
  complianceAnswers: Yup.array().of(ComplianceQuestionSchema)
});
