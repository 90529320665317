/* eslint-disable react/no-danger */
import clsx from "clsx";
import dompurify from "dompurify";
import React from "react";
import { Button } from "reactstrap";

import styles from "./Approve.module.scss";

export type ActionBoxType = "approve" | "confirmApprove" | "confirmReject";

export interface ActionBoxInterface {
  type: ActionBoxType;
  title: string;
  description: string;
  icon: string;
  icon2: string;
  text: string;
  text2: string;
}

const buttonStyle = {
  approve: {
    button: styles.approve,
    button2: styles.reject
  },
  confirmApprove: {
    button: styles.cancel,
    button2: styles.approve
  },
  confirmReject: {
    button: styles.cancel,
    button2: styles.reject
  }
};

interface ApproveProps {
  type: ActionBoxType;
  data: ActionBoxInterface;
  handleAction: () => void;
  handleAction2: () => void;
}

const Approve: React.FC<ApproveProps> = ({
  type,
  data,
  handleAction,
  handleAction2
}) => {
  const { title, description, text, icon, text2, icon2 } = data;
  const sanitizer = dompurify.sanitize;

  return (
    <>
      <div className={clsx(styles.textContainer)}>
        <div className={styles.title}>{title}</div>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: sanitizer(description).replace(
              /href/g,
              "target='_blank' href"
            )
          }}
        />
      </div>
      <div className={clsx(styles.actionContainer)}>
        <Button
          color="secondary"
          className={clsx(buttonStyle[type].button, styles.buttonContainer)}
          onClick={handleAction}
        >
          <span className={styles.actionText}>{text}</span>
          {icon && <img className={styles.icon} src={icon} alt="" />}
        </Button>
        <Button
          className={clsx(buttonStyle[type].button2, styles.buttonContainer)}
          onClick={handleAction2}
        >
          <span className={styles.actionText}>{text2}</span>
          <img className={styles.icon} src={icon2} alt="" />
        </Button>
      </div>
    </>
  );
};

export default Approve;
