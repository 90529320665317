import { Button } from "arbolus-ui-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  CanopyService,
  DefaultToasterService,
  ToasterService
} from "@arbolus-technologies/api";
import { CANOPY_STATUS } from "@arbolus-technologies/models/canopy";
import { CanopyV2StoreActions } from "@arbolus-technologies/stores/canopy-V2";
import { ProjectCanopyStoreActions } from "@arbolus-technologies/stores/project-canopy-store";
import { ArbolusModalDanger } from "@arbolus-technologies/ui/components";

interface ArchiveCanopyButtonProps {
  canopyId: string;
  canopyTitle: string;
  canopyService?: typeof CanopyService;
  notificationService?: ToasterService;
}

export const ArchiveCanopyButton: React.FC<ArchiveCanopyButtonProps> = ({
  canopyId,
  canopyTitle,
  canopyService = CanopyService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("archiveCanopy");
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [confirmationModal, setConfirmationModel] = useState(false);

  const archiveCanopy = () => {
    setIsLoading(true);

    canopyService
      .changeCanopyStatus(canopyId, CANOPY_STATUS.ARCHIVED)
      .subscribe({
        next: () => {
          setIsLoading(false);
          notificationService.showSuccess(
            t("archiveCanopySuccess", { title: canopyTitle })
          );
          dispatch(CanopyV2StoreActions.archiveCanopy());
          dispatch(ProjectCanopyStoreActions.archiveCanopy(canopyId));
        },
        error: (error) => {
          setIsLoading(false);
          notificationService.showApiErrors(error);
        }
      });
  };

  return (
    <>
      <Button
        type="tertiary"
        text={t("button")}
        onClick={() => setConfirmationModel(true)}
        disabled={!canopyId}
      />
      {confirmationModal && (
        <ArbolusModalDanger
          isOpen={confirmationModal}
          confirmationText={canopyTitle}
          warningMessage={t("warningMessage")}
          toggle={() => setConfirmationModel(false)}
          leftButton={{
            text: t("cancel"),
            onClick: () => setConfirmationModel(false),
            type: "tertiary",
            disabled: isLoading
          }}
          rightButton={{
            text: t("confirm"),
            onClick: archiveCanopy,
            type: "rejection",
            disabled: isLoading
          }}
        />
      )}
    </>
  );
};
