import { ClassProperties } from "@arbolus-technologies/utils";

import { EventModel } from "../Events";
import { CallEvent } from "./Event";
import { IReferral, Referral } from "./Referral";

export type IBookedReferral = Omit<IReferral, "expertName"> & {
  events: EventModel[];
};

export class BookedReferral extends Referral {
  public readonly events!: CallEvent[];

  public get latestEvent(): CallEvent {
    return this.events[0];
  }

  public static fromObject(json: IBookedReferral): BookedReferral {
    const referral = {
      ...Referral.fromObject(json),
      events: json.events
        .map((event) => new CallEvent(event))
        .sort((a, b) => b.slot.compare(a.slot))
    };

    return Object.assign(new BookedReferral(), referral);
  }

  public createUpdated(
    updatedValues: Partial<ClassProperties<BookedReferral>>
  ): BookedReferral {
    return Object.assign(new BookedReferral(), { ...this, ...updatedValues });
  }
}
