/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Control, Controller } from "react-hook-form";

import { SelectOption } from "@arbolus-technologies/models/common";

import { CustomSelect } from "../../components/CustomSelect/CustomSelect";

import styles from "./CustomSelectLabelController.module.scss";

interface CustomSelectLabelControllerProps {
  options: SelectOption[];
  name: string;
  control: Control<any>;
  noOptionsMessage: string;
  placeholder?: string;
  defaultValue?: SelectOption;
  error?: string;
  disabled?: boolean;
  customLabel?: string | JSX.Element;
  displayIcon?: boolean;
  customOnChange?: (value: any) => void;
}

export const CustomSelectLabelController: React.FC<
  CustomSelectLabelControllerProps
> = ({
  options,
  name,
  control,
  noOptionsMessage,
  placeholder,
  error,
  disabled,
  customLabel,
  displayIcon,
  customOnChange
}) => {
  const renderLabel = (value: any) => {
    if (typeof customLabel === "undefined") {
      return value;
    }
    return {
      value: `${value}`,
      label: customLabel
    };
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => (
        <div className={styles.selectContainer}>
          <CustomSelect
            disabled={disabled}
            options={options}
            onSelectChange={customOnChange ?? onChange}
            value={renderLabel(value)}
            noOptionsMessage={noOptionsMessage}
            displayIcon={displayIcon}
            isSearchable
            placeholder={placeholder}
            onBlur={typeof value === null ? undefined : onBlur}
            isError={!!error}
          />
          {error && <div className={styles.error}>{error}</div>}
        </div>
      )}
    />
  );
};
