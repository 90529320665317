import { INCIDENT_CATEGORY } from "./enums";

export const getCategoryByUrl = (url: string): INCIDENT_CATEGORY => {
  if (url.includes("event")) {
    return INCIDENT_CATEGORY.CALL;
  }

  if (url.includes("canopy")) {
    return INCIDENT_CATEGORY.CANOPY;
  }

  if (url.includes("expert")) {
    return INCIDENT_CATEGORY.EXPERTS;
  }

  if (url.includes("users")) {
    return INCIDENT_CATEGORY.EXPERTS;
  }

  if (url.includes("inbox")) {
    return INCIDENT_CATEGORY.NOTIFICATIONS;
  }

  if (url.includes("client")) {
    return INCIDENT_CATEGORY.CLIENTS;
  }

  if (url.includes("transaction")) {
    return INCIDENT_CATEGORY.TRANSACTIONS;
  }

  if (url.includes("project")) {
    return INCIDENT_CATEGORY.PROJECTS;
  }

  return INCIDENT_CATEGORY.OTHER;
};
