import { CreateCanopyDataRequest } from "@arbolus-technologies/api";

import { MixPanelEvent, MixPanelEventNames } from "../../Mixpanel/enums";
import {
  ICanopyCreationType,
  ISimpleCanopyCreationForm
} from "./SimpleCanopyCreationProvider";

export const prepareData = (
  data: ISimpleCanopyCreationForm,
  projectId?: string
): CreateCanopyDataRequest => {
  const { title, brief, canopyCreationType } = data;
  const { canopyType, canopyTemplate } = canopyCreationType;
  const canopyTypeToResultMap = {
    [ICanopyCreationType.CANOPAI]: {
      title,
      type: canopyType,
      brief,
      projectId
    },
    [ICanopyCreationType.MANUAL]: {
      title,
      type: canopyType,
      overview: brief,
      projectId
    },
    [ICanopyCreationType.TEMPLATE]: {
      title,
      type: canopyType,
      canopyTemplateId: canopyTemplate,
      overview: brief,
      projectId
    }
  };

  const result = canopyTypeToResultMap[canopyType];

  if (result === undefined) {
    throw new Error(`Unsupported canopyType: ${canopyType}`);
  }

  return result;
};

export const mixPanelTrackerClick = (
  canopyType: ICanopyCreationType
): MixPanelEvent => {
  const canopyTypeToEventMap = {
    [ICanopyCreationType.MANUAL]: MixPanelEventNames.CanopyCreationPanelScratch,
    [ICanopyCreationType.CANOPAI]: MixPanelEventNames.CanopyCreationPanelAI,
    [ICanopyCreationType.TEMPLATE]:
      MixPanelEventNames.CanopyCreationPanelTemplates
  };

  const event = canopyTypeToEventMap[canopyType];

  if (event === undefined) {
    throw new Error(`Unsupported canopyType: ${canopyType}`);
  }

  return event;
};
