import { ColDef, ValueFormatterParams } from "ag-grid-community";
import i18next from "i18next";

import { REJECT_REASON, Referral } from "@arbolus-technologies/models/common";
import { floatingFilter } from "@arbolus-technologies/ui/components";

const NO_REASON = "Not specified";
const rejectReasonToText: Record<REJECT_REASON | "default", string> = {
  [REJECT_REASON.ALREADY_ENGAGED]: i18next.t("rejectExpert:AlreadyEngaged"),
  [REJECT_REASON.WRONG_GEOGRAPHY]: i18next.t("rejectExpert:WrongGeography"),
  [REJECT_REASON.COMPANY_COVERED]: i18next.t("rejectExpert:CompanyCovered"),
  [REJECT_REASON.NOT_SENIOR]: i18next.t("rejectExpert:NotSenior"),
  [REJECT_REASON.ANGLE_COMPLETED]: i18next.t("rejectExpert:AngleCompleted"),
  [REJECT_REASON.OTHER]: i18next.t("rejectExpert:Other"),
  default: NO_REASON
};

const reasonFormatter = (reason?: REJECT_REASON | null) =>
  reason
    ? rejectReasonToText[reason as REJECT_REASON]
    : rejectReasonToText.default;

export const rejectReasonCol: ColDef<Referral> = {
  field: "rejectDescription.reason",
  tooltipField: "rejectDescription.description",
  cellStyle: ({ value }) => (value ? null : { fontStyle: "italic" }),
  valueFormatter: ({ data }) =>
    reasonFormatter(data?.rejectDescription?.reason),
  headerName: i18next.t("referrals:headers:rejectReason"),
  headerTooltip: i18next.t("referrals:headers:rejectReason"),
  filter: "agSetColumnFilter",
  filterParams: {
    buttons: ["reset"],
    valueFormatter: ({ value }: ValueFormatterParams<unknown, REJECT_REASON>) =>
      reasonFormatter(value)
  },
  ...floatingFilter,
  minWidth: 100
};
