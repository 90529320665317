/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from "arbolus-ui-components";
import React, { useRef } from "react";
import { Control, FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormGroup } from "reactstrap";

import {
  DefaultToasterService,
  DownloadCanopyInterface,
  FileInterface,
  ToasterService,
  UploadingAttachment
} from "@arbolus-technologies/api";
import {
  MAXIMUM_FILES_UPLOAD_AT_ONCE,
  MAXIMUM_LINKS_AT_ONCE
} from "@arbolus-technologies/models/documents";
import { InputGroupController } from "@arbolus-technologies/ui/components";
import { utilService } from "@arbolus-technologies/utils";

import {
  ValidateFileAttributes,
  handleUploadStateChanges
} from "../../helpers/utils";

import styles from "./Attachments.module.scss";

interface AttachmentsProps {
  control: Control<any>;
  errors: FieldErrors<any>;
  urls: FileInterface[];
  addLink: (link: FileInterface) => void;
  removeLink: (index: number) => void;
  files: DownloadCanopyInterface[];
  addFile: (document: DownloadCanopyInterface) => void;
  removeFile: (index: number) => void;
  uploadingFiles: UploadingAttachment[];
  handleUploadingFiles: React.Dispatch<
    React.SetStateAction<UploadingAttachment[]>
  >;
  handleRemoveFiles: (id: string) => void;
  notificationService?: ToasterService;
}

export const Attachments: React.FC<AttachmentsProps> = ({
  control,
  errors,
  uploadingFiles,
  urls,
  files,
  addLink,
  removeLink,
  removeFile,
  addFile,
  handleUploadingFiles,
  handleRemoveFiles,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("canopyBuilder");
  const inputRef = useRef<HTMLInputElement | null>(null);

  const getNewFilesToUpload = (
    uploadingFiles: UploadingAttachment[],
    selectedFiles: FileList
  ) => {
    const newFiles = Array.from(selectedFiles)
      .filter((file: File) => {
        const errorDetails = ValidateFileAttributes(file);
        if (errorDetails) {
          notificationService.showError(
            t(errorDetails.error, {
              limit: errorDetails.limit
            })
          );
          return false;
        } else {
          return true;
        }
      })
      .map((file: File) => ({
        attachment: file,
        attachmentId: utilService.generateUUID()
      }));

    const availableUploadSlots =
      MAXIMUM_FILES_UPLOAD_AT_ONCE - uploadingFiles.length;
    if (availableUploadSlots < newFiles.length) {
      notificationService.showError(t("fileCountLimitError"));
    }

    newFiles.splice(availableUploadSlots);
    return newFiles;
  };

  const handleFilesUpload = (selectedFiles: FileList) => {
    if (files.length >= MAXIMUM_FILES_UPLOAD_AT_ONCE) {
      notificationService.showError(
        t("maxFiles", {
          length: MAXIMUM_FILES_UPLOAD_AT_ONCE
        })
      );
    } else if (uploadingFiles.length < MAXIMUM_FILES_UPLOAD_AT_ONCE) {
      const newFiles = getNewFilesToUpload(uploadingFiles, selectedFiles);

      newFiles.forEach((newFile) => {
        addFile({
          fileName: newFile.attachment.name,
          id: newFile.attachmentId,
          fileKey: newFile.attachmentId,
          downloadUrl: ""
        });
      });
      handleUploadingFiles((prevUploadingFiles) =>
        prevUploadingFiles.concat(newFiles)
      );
      handleUploadStateChanges(uploadingFiles);
    }
  };

  const handleLinkAdd = () => {
    if (urls.length < MAXIMUM_LINKS_AT_ONCE) {
      addLink({ url: "" });
    } else {
      notificationService.showError(
        t("maxLinks", {
          length: MAXIMUM_LINKS_AT_ONCE
        })
      );
    }
  };

  const handleRemoveFile = (index: number, fileId: string, fileKey: string) => {
    handleUploadingFiles((prevUploadingFiles) =>
      prevUploadingFiles.filter((file) => file.attachmentId !== fileKey)
    );
    removeFile(index);
    handleRemoveFiles(fileId);
  };

  return (
    <div className={styles.container}>
      <div className={styles.fileUploadMessage}>{t("uploadFileMessage")}</div>
      <div className={styles.buttonContainer}>
        <input
          style={{ display: "none" }}
          ref={inputRef}
          type="file"
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
            if (target.files && target.files?.length > 0) {
              handleFilesUpload(target.files);
            }
            // eslint-disable-next-line no-param-reassign
            target.value = "";
          }}
        />
        <Button
          type="secondary"
          text={t("uploadFiles")}
          endIcon="upload"
          onClick={() => inputRef.current?.click()}
        />
        <Button
          type="secondary"
          text={t("addLink")}
          endIcon="link"
          onClick={handleLinkAdd}
        />
      </div>
      <FormGroup className={styles.attachments}>
        {urls.map((url, index) => (
          <InputGroupController
            key={url.url}
            name={`links.${index}.url`}
            index={index}
            errors={errors}
            control={control}
            placeholder={t("enterUrl")}
            inputType="text"
            remove={() => removeLink(index)}
          />
        ))}
        {files.map((file, index) => (
          <InputGroupController
            key={file.id}
            name={`documents.${index}.fileName`}
            index={index}
            errors={errors}
            control={control}
            placeholder={t("selectFile")}
            readonly
            inputType="text"
            remove={(index) => handleRemoveFile(index, file.id, file.fileKey)}
          />
        ))}
      </FormGroup>
    </div>
  );
};
