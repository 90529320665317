import { Observable, forkJoin, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { ApiError } from "../ApiError";

export type ForkJoinRequests<T = boolean> = {
  [key: string]: Observable<T | ApiError>;
};

export function createForkJoinObservables<T, S, V>(
  items: T[],
  singleRequest: (item: T) => Observable<S>,
  key: keyof T & string,
  mapResponse: (item: S) => V
): Observable<{ [key: string]: V | ApiError }> {
  const observables = items.reduce<{ [key: string]: Observable<V | ApiError> }>(
    (acc, item) => {
      acc[item[key] as unknown as string] = singleRequest(item).pipe(
        map(mapResponse),
        catchError((apiError) => of(new ApiError(apiError)))
      );
      return acc;
    },
    {}
  );
  return forkJoin(observables);
}
