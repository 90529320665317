import { Button, Icon } from "arbolus-ui-components";
import React from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import { ProjectApplicationStepDetails } from "@arbolus-technologies/models/project";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { StepsRow } from "@arbolus-technologies/ui/components";

import styles from "./ProjectApplicationWrapper.module.scss";

interface ProjectApplicationWrapperProps {
  isValid?: boolean;
  dirty?: boolean;
  stepDetails: ProjectApplicationStepDetails;
  isLoading: boolean;
  onSaveProgress?: () => void;
  onNext?: () => void;
  onBack?: () => void;
  children: React.ReactNode;
  downloadProjectAgreement?: () => void;
}

export const ProjectApplicationWrapper: React.FC<
  ProjectApplicationWrapperProps
> = ({
  children,
  isValid,
  dirty,
  stepDetails,
  onNext,
  onSaveProgress,
  onBack,
  isLoading,
  downloadProjectAgreement
}) => {
  const { t } = useTranslation("projectApplication");
  const { currentStep, totalSteps, nextStepTitle, completedSteps } =
    stepDetails;
  const isLastStep = totalSteps === currentStep + 1;

  const renderPageHeader = (): JSX.Element => (
    <div className={styles.header}>
      {isMobile && (
        <div className={styles.backIconHeader}>
          <Icon
            color={ARBOLUS_COLORS.bColorBasePurple}
            fontSize="24px"
            name="arrow_back"
            onClick={onBack}
          />
        </div>
      )}
      <div className={styles.leftContainer}>
        <StepsRow
          onBack={onBack}
          currentStep={currentStep}
          totalSteps={totalSteps}
          completedSteps={completedSteps}
        />
      </div>
      <div className={styles.buttons}>
        {onSaveProgress && (
          <Button
            type="tertiary"
            onClick={onSaveProgress}
            disabled={!(isValid && dirty) || isLoading}
            text={t("saveProgress")}
          />
        )}
      </div>
    </div>
  );

  const renderPageFooter = (): JSX.Element => (
    <div className="pa-footer">
      {isMobile && (
        <div onClick={onBack} className={styles.backIcon}>
          <Icon color="#6157FC" fontSize="24px" name="arrow_back" />
        </div>
      )}
      {downloadProjectAgreement && (
        <div className={styles.projectAgreementButton}>
          <Button
            type="secondary"
            endIcon="file_download"
            disabled={!isValid || isLoading}
            onClick={downloadProjectAgreement}
            text={"Download"}
          />
        </div>
      )}
      {onNext && (
        <div className={styles.nextButtonContainer}>
          <Button
            disabled={!isValid || isLoading}
            onClick={onNext}
            text={isLastStep ? nextStepTitle : `${t("next")}: ${nextStepTitle}`}
          />
        </div>
      )}
    </div>
  );

  return (
    <div className={styles.applicationWrapper}>
      {renderPageHeader()}
      <div>{children}</div>
      {renderPageFooter()}
    </div>
  );
};
