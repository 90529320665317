import React from "react";
import { useTranslation } from "react-i18next";

import { linkedin } from "@arbolus-technologies/theme";

import { IconWithText } from "../IconWithText/IconWithText";

import styles from "./ExpertLinkedin.module.scss";

interface ExpertLinkedinProps {
  linkedInUrl?: string;
}

export const ExpertLinkedin: React.FC<ExpertLinkedinProps> = ({
  linkedInUrl
}) => {
  const { t } = useTranslation("expertProfileLinkedin");

  return linkedInUrl ? (
    <div className={styles.linkedinContainer}>
      <IconWithText
        isLink={{
          urlLink: linkedInUrl,
          textLink: t("linkedinProfile")
        }}
        img={linkedin}
      />
    </div>
  ) : (
    <></>
  );
};
