import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { CanopyExpertSelector } from "@arbolus-technologies/stores/canopy-expert";

import { CanopyBrief } from "../../Components/CanopyBrief/CanopyBrief";
import { QUESTION_PAGE_ROUTES } from "../../helpers/routes";

export const CanopyDetailsExpert: React.FC = () => {
  const history = useHistory();

  const expertCanopy = useSelector(CanopyExpertSelector.canopySelector());
  const expertQuestionList = useSelector(
    CanopyExpertSelector.questionsSelector()
  );

  const redirectToFirstQuestion = () => {
    const firstQuestion = expertQuestionList[0];
    history.push(
      QUESTION_PAGE_ROUTES[firstQuestion.type](
        expertCanopy.id,
        firstQuestion.id
      )
    );
  };

  return (
    <CanopyBrief
      expertCanopy={expertCanopy}
      redirectToFirstQuestion={redirectToFirstQuestion}
    />
  );
};
