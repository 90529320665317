/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ColumnDef,
  ColumnFiltersState,
  FilterFn,
  Overwrite,
  Render,
  TableInstance,
  createTable,
  filterFns,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useTableInstance
} from "@tanstack/react-table";
import clsx from "clsx";
import React, { useState, ReactNode, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { Loader } from "../Loader/Loader";

import styles from "./TanstackTable.module.scss";

export type TanStackTableColumn = {
  name: string;
  component?: FunctionComponent<any>;
  cellComponent?: FunctionComponent<any>;
  size?: string;
  filterFn?: FilterFn<any> | keyof typeof filterFns;
  accessorFn?: any;
  accessorKey?: string;
};

interface TanstackTableProps {
  defaultData: any[];
  columnDefinitions: any;
  handleClickRow?: (cellId: string, row: any) => void;
  isLoading?: boolean;
  classnames?: {
    head?: { thead?: string; tr?: string; th?: string };
    body?: { tbody?: string; tr?: string; td?: string; firstTd?: string };
    noData?: string;
  };
  cellProps?: any;
  gridTemplate?: string;
}

export const TanstackTable: React.FC<TanstackTableProps> = ({
  columnDefinitions,
  defaultData,
  handleClickRow,
  isLoading = false,
  classnames,
  cellProps,
  gridTemplate
}) => {
  const { t } = useTranslation("tanStack");

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const table = createTable().setRowType<any>();
  const defaultColumns: ColumnDef<
    Overwrite<
      {
        Renderer: Render;
        Rendered: JSX.Element | ReactNode;
      },
      any
    >
  >[] = columnDefinitions.map(
    (columnDefinition: TanStackTableColumn & { size: number }) => {
      const {
        name,
        component: Component,
        cellComponent: CellComponent,
        size,
        filterFn,
        accessorFn
      } = columnDefinition;

      return table.createDataColumn(name, {
        id: name,
        header: ({ column }) =>
          Component ? <Component column={column} /> : null,
        cell: ({ row }: { row: any }) =>
          CellComponent ? (
            <CellComponent row={row.original} {...cellProps} />
          ) : (
            <div title={row.original[name]} className={styles.defaultText}>
              {row.original[name]}
            </div>
          ),
        size,
        filterFn,
        accessorFn
      });
    }
  );

  const [columns] = useState<typeof defaultColumns>(() => [...defaultColumns]);

  const instance = useTableInstance(table, {
    data: defaultData,
    columns,
    columnResizeMode: "onChange",
    enableColumnResizing: false,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: false,
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      columnFilters
    },
    manualSorting: true
  });

  if (gridTemplate)
    return (
      <GridTanstackTable
        isLoading={isLoading}
        classnames={classnames}
        instance={instance}
        gridTemplate={gridTemplate}
        handleClickRow={handleClickRow}
      />
    );

  const renderRows = () => {
    if (isLoading) {
      return <Loader />;
    }

    if (instance.getRowModel().rows.length === 0) {
      return (
        <div className={clsx(styles.tr, styles.noData, classnames?.noData)}>
          <span> {t("noData")}</span>
        </div>
      );
    }

    return instance.getRowModel().rows.map((row) => (
      <div key={row.id} className={clsx(styles.tr, classnames?.body?.tr)}>
        {row.getVisibleCells().map((cell) => (
          <div
            onClick={() => {
              handleClickRow?.(cell.column.id, row.original);
            }}
            key={cell.id}
            style={{
              width: cell.column.columnDef.size
            }}
            className={clsx(styles.td, classnames?.body?.td)}
          >
            {cell.renderCell()}
          </div>
        ))}
      </div>
    ));
  };

  return (
    <div className={styles.divTable}>
      <div className={clsx(styles.thead, classnames?.head?.thead)}>
        {instance.getHeaderGroups().map((headerGroup) => (
          <div
            key={headerGroup.id}
            className={clsx(styles.tr, classnames?.head?.tr)}
          >
            {headerGroup.headers.map((header) => (
              <div
                key={header.id}
                className={clsx(styles.th, classnames?.head?.th)}
                style={{ width: header.column.columnDef.size }}
              >
                {header.isPlaceholder ? null : header.renderHeader()}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className={clsx(styles.tbody, classnames?.body?.tbody)}>
        {renderRows()}
      </div>
    </div>
  );
};

interface GridTanstackTableProps
  extends Pick<TanstackTableProps, "classnames"> {
  isLoading: boolean;
  instance: TableInstance<any>;
  gridTemplate: string;
  handleClickRow?: (cellId: string, row: any) => void;
}

const GridTanstackTable: React.FC<GridTanstackTableProps> = ({
  isLoading,
  classnames,
  instance,
  gridTemplate,
  handleClickRow
}: GridTanstackTableProps) => {
  if (isLoading) {
    return <Loader />;
  }

  return (
    <div
      className={styles.gridTable}
      style={{ gridTemplateColumns: gridTemplate }}
    >
      {instance.getHeaderGroups().map((headerGroup) =>
        headerGroup.headers.map((header) => (
          <div key={header.id} className={clsx(classnames?.head?.th)}>
            {header.isPlaceholder ? null : header.renderHeader()}
          </div>
        ))
      )}
      {instance.getRowModel().rows.map((row) =>
        row.getVisibleCells().map((cell, index) => (
          <div
            key={cell.id}
            className={clsx(
              styles.gridItem,
              classnames?.body?.td,
              index === 0 && classnames?.body?.firstTd
            )}
            onClick={() => {
              handleClickRow?.(cell.column.id, row.original);
            }}
          >
            {cell.renderCell()}
          </div>
        ))
      )}
    </div>
  );
};
