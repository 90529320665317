import { Button } from "arbolus-ui-components";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { DiscoverFilterOption } from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  Checkbox,
  InfiniteScrollV2,
  SearchInputBase
} from "@arbolus-technologies/ui/components";

import { SEARCH_LIMIT } from "../../../../../Modules/DiscoverFilters/DiscoverFilters";

import styles from "./SearchWithCheckboxDropdown.module.scss";

interface SearchWithCheckboxDropdownProps {
  filterOptions: DiscoverFilterOption[];
  searchQuery: string;
  onBottomReached: (query: string) => void;
  onClearQueryClicked: () => void;
  onInputChangeEvent: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  onSelectOption: (option: DiscoverFilterOption) => void;
  placeholder?: string;
  disabled?: boolean;
  preSelectedOptions: DiscoverFilterOption[];
  applyFilter: () => void;
  handleSelectAll?: () => void;
  showFlagIcon: boolean;
}

export const SearchWithCheckboxDropdown: React.FC<
  SearchWithCheckboxDropdownProps
> = ({
  filterOptions,
  placeholder,
  disabled,
  searchQuery,
  onBottomReached,
  onClearQueryClicked,
  onInputChangeEvent,
  onSelectOption,
  preSelectedOptions,
  applyFilter,
  handleSelectAll,
  showFlagIcon
}) => {
  const { t } = useTranslation("discoverPage");
  const dropdownRef = useRef(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false);

  const metadataCountries = useSelector(CacheSelector.metadataCountries());

  const confirmFilters = () => {
    setFiltersApplied(true); // Set the flag when clicking outside the dropdown
    setIsDropdownOpen(false);
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (
      isDropdownOpen &&
      dropdownRef.current &&
      !(dropdownRef.current as unknown as HTMLElement).contains(
        e.target as Node
      )
    ) {
      confirmFilters();
    }
  };

  useEffect(() => {
    if (!isDropdownOpen && filtersApplied) {
      applyFilter();
      setFiltersApplied(false); // Reset the flag after applying filters
    }
  }, [isDropdownOpen, filtersApplied, applyFilter]);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDropdownOpen, applyFilter]);

  const handleClickOption = (
    option: DiscoverFilterOption,
    isChecked: boolean
  ) => {
    if (preSelectedOptions.length < SEARCH_LIMIT || isChecked) {
      onSelectOption(option);
    }
  };

  const renderSearchItem = (option: DiscoverFilterOption) => {
    const isChecked = preSelectedOptions
      .map((selectedOption) => selectedOption.value)
      .includes(option.value);

    const twoLetterCode = metadataCountries.find(
      (country) => country.id === option.value
    )?.twoLetterCode;

    return (
      <button
        key={option.value}
        onClick={(e) => {
          e.preventDefault();
          return handleClickOption(option, isChecked);
        }}
        className={styles.dropdownItem}
      >
        <Checkbox
          isChecked={isChecked}
          text={option.label}
          countryTwoLetterCode={showFlagIcon ? twoLetterCode : undefined}
        />
      </button>
    );
  };

  return (
    <div className={styles.searchWithCheckboxDropdown} ref={dropdownRef}>
      <SearchInputBase
        searchQuery={searchQuery}
        handleClearQueryClicked={onClearQueryClicked}
        handleInputChangeEvent={onInputChangeEvent}
        placeholder={placeholder}
        disabled={disabled}
        onInputFocus={() => setIsDropdownOpen(true)}
      />
      {isDropdownOpen && filterOptions.length > 0 && (
        <div className={styles.dropdown}>
          <InfiniteScrollV2
            onBottomReached={() => onBottomReached(searchQuery)}
            items={filterOptions}
            renderer={renderSearchItem}
            isLoading={false}
            customGap={[0, 0]}
          />
          <div
            className={clsx(
              styles.buttonContainer,
              handleSelectAll && styles.isSelectAllActive
            )}
          >
            {handleSelectAll && (
              <Button
                onClick={handleSelectAll}
                size="small"
                text={t("selectAll")}
              />
            )}
            <Button onClick={confirmFilters} text={t("apply")} size="small" />
          </div>
        </div>
      )}
    </div>
  );
};
