import React from "react";
import { useParams } from "react-router";

import { CanopyParamUrlTypes } from "@arbolus-technologies/models/canopy";

import { RBServiceManager } from "../../services";
import { CanopyClientV2Router } from "./CanopyClientV2Router";
import { CanopyExpertRouter } from "./CanopyExpertRouter";

export const CanopyRouterContainer: React.FC = () => {
  const { canopyId } = useParams<CanopyParamUrlTypes>();

  return (
    <>
      {RBServiceManager.valueSelector(
        [
          <CanopyClientV2Router
            key="CanopyClientV2Router"
            canopyId={canopyId}
          />
        ],
        [<CanopyExpertRouter key="CanopyExpertRouter" />]
      )}
    </>
  );
};
