import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Media from "react-media";
import { connect } from "react-redux";
import { Col, Row, TabContent, TabPane } from "reactstrap";
import { Dispatch } from "redux";
import { createStructuredSelector } from "reselect";
import SimpleBar from "simplebar-react";

import {
  MixpanelPages,
  trackPageView
} from "@arbolus-technologies/features/common";

import {
  INBOX_NOTIFICATION_CATEGORY,
  clientCategories,
  expertCategories
} from "../../../../constants/notifications";
import {
  APP_DEVICE_MEDIA_QUERIES,
  UI_INBOX_PAGE
} from "../../../../constants/ui";
import { RBServiceManager } from "../../../../services";
import { AppAction } from "../../../../store/actions";
import { AppState } from "../../../../store/reducers";
import { AppSelector, InboxStoreActions } from "../../../app/store";
import InboxCategoryList from "../../components/inbox/CategoryListing";
import NotificationTab from "../../components/inbox/notificationTab/NotificationTab";

interface InboxPageStoreProps {
  inboxCategoryCounts: Map<INBOX_NOTIFICATION_CATEGORY, number>;
}

interface InboxPageProps extends InboxPageStoreProps, WithTranslation {
  getNotificationCounts: () => void;
  updateNotificationCounts: (categories: INBOX_NOTIFICATION_CATEGORY[]) => void;
}

interface InboxPageState {
  activeCategory: INBOX_NOTIFICATION_CATEGORY;
}

class InboxPage extends React.Component<InboxPageProps, InboxPageState> {
  constructor(props: InboxPageProps) {
    super(props);

    this.state = {
      activeCategory: INBOX_NOTIFICATION_CATEGORY.ThingsTodo
    };

    this.categories = RBServiceManager.valueSelector(
      clientCategories,
      expertCategories
    );
  }

  componentDidMount(): void {
    const { t, getNotificationCounts } = this.props;
    document.title = t("title");

    getNotificationCounts();
    trackPageView({ page: MixpanelPages.Inbox });
  }

  private categories: INBOX_NOTIFICATION_CATEGORY[] = [];

  handleCategoryClick = (category: INBOX_NOTIFICATION_CATEGORY): void => {
    const { getNotificationCounts } = this.props;
    this.setState({
      activeCategory: category
    });

    getNotificationCounts();
  };

  render(): JSX.Element {
    const { t, updateNotificationCounts, inboxCategoryCounts } = this.props;
    const { activeCategory } = this.state;

    return (
      <div className="page-wrapper">
        <div className="inbox-container page-content">
          <div className="page-content-header">
            <div className="top-container">
              <h1>{t("title")}</h1>
              <div className="btn-container" />
              <div className="bottom-container" />
            </div>
          </div>
          <Row className="page-content-body m-0">
            <Col className="left-container p-0">
              <div className="header-container">
                <h1>{t("title")}</h1>
              </div>
              <Media queries={APP_DEVICE_MEDIA_QUERIES}>
                {(matches): JSX.Element => (
                  <SimpleBar
                    style={{
                      height: UI_INBOX_PAGE.CATEGORY_LIST_HEIGHT(matches),
                      maxHeight: UI_INBOX_PAGE.CATEGORY_LIST_HEIGHT(matches),
                      overflowX: "hidden"
                    }}
                    className="simplebar-light tiny"
                  >
                    <InboxCategoryList
                      onCategorySelect={this.handleCategoryClick}
                      activeCategory={activeCategory}
                      categories={this.categories}
                    />
                  </SimpleBar>
                )}
              </Media>
            </Col>
            <Col className="right-container p-0">
              <TabContent activeTab={activeCategory}>
                {this.categories.map((category) => (
                  <TabPane tabId={category} key={category}>
                    <NotificationTab
                      activeCategory={activeCategory}
                      onMobileCategorySelect={this.handleCategoryClick}
                      updateNotificationCounts={updateNotificationCounts}
                      category={category}
                      inboxCategoryCount={
                        inboxCategoryCounts.get(category) || 0
                      }
                      isActive={category === activeCategory}
                      categories={this.categories}
                    />
                  </TabPane>
                ))}
              </TabContent>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector<
  AppState,
  InboxPageProps,
  InboxPageStoreProps
>({
  inboxCategoryCounts: AppSelector.appInboxCategoriesSelector()
});

const mapDispatchToProps = (dispatch: Dispatch): Record<string, AppAction> => ({
  updateNotificationCounts: (
    categories: INBOX_NOTIFICATION_CATEGORY[]
  ): AppAction =>
    dispatch(InboxStoreActions.markNotificationsAsReadSuccess(categories)),
  getNotificationCounts: (): AppAction =>
    dispatch(InboxStoreActions.getNotificationCounts())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("inbox")(InboxPage));
