import { Button, Icon } from "arbolus-ui-components";
import React from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { DiscoverExpert } from "@arbolus-technologies/models/project";
import { EXPERT_PROFILE_ROUTE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { ArbolusAvatar, HR } from "@arbolus-technologies/ui/components";

import { useDiscoverExperts } from "../../../../../Hooks/Discover/useDiscoverExperts";
import { NAVIGATION_DIRECTION_ENUM } from "../../enums";
import { DiscoverMatchingFiltersPanelData } from "../../interfaces";
import { NavigationDirection } from "../../types";

import styles from "./Header.module.scss";

interface HeaderProps {
  expertProfile: DiscoverMatchingFiltersPanelData;
}
export const Header: React.FC<HeaderProps> = ({ expertProfile }) => {
  const { t } = useTranslation("discoverMatchingFiltersPanel");
  const dispatch = useDispatch();
  const metadataCountries = useSelector(CacheSelector.metadataCountries());
  const isAdmin = useSelector(CacheSelector.isAdmin());
  const { projectId } = expertProfile;
  const { expertListPaginated } = useDiscoverExperts({ projectId });

  const {
    countryTwoLetterCode,
    fullName,
    lastPublicCompanyDate,
    profileImageUrl,
    title,
    expertId,
    doNotContactStatus
  } = expertProfile || {};

  const findExpertPosition = (
    expertList: DiscoverExpert[],
    searchExpertId: string
  ): number => expertList.findIndex((item) => item.id === searchExpertId);

  const currentExpertPosition = findExpertPosition(
    expertListPaginated?.items ?? [],
    expertId
  );

  const closePanel = () =>
    dispatch(PanelStoreActions.closePanel(PanelId.DiscoverMatchingFilters));

  const navigateToExpert = (direction: NavigationDirection) => {
    if (expertListPaginated) {
      const newIndex =
        direction === NAVIGATION_DIRECTION_ENUM.PREVIOUS
          ? Math.max(currentExpertPosition - 1, 0)
          : Math.min(
              currentExpertPosition + 1,
              expertListPaginated.items.length - 1
            );
      const expert = expertListPaginated.items[newIndex];
      const {
        id,
        fullName,
        title,
        isoCountryCode,
        lastPublicCompanyDate,
        profileImageUrl,
        searchMatchColumns,
        doNotContactStatus,
        customers
      } = expert;
      const panelData: DiscoverMatchingFiltersPanelData = {
        expertId: id,
        fullName,
        title,
        countryTwoLetterCode:
          metadataCountries.find(
            (country) => country.threeLetterCode === isoCountryCode
          )?.twoLetterCode ?? null,
        lastPublicCompanyDate,
        profileImageUrl,
        projectId,
        searchMatchColumns,
        doNotContactStatus,
        customers
      };
      dispatch(
        PanelStoreActions.openPanel(PanelId.DiscoverMatchingFilters, panelData)
      );
    }
  };

  return (
    <div className={styles.header}>
      <div className={styles.titleSection}>
        <h1 className={styles.title}>{t("filterMatch")}</h1>
        <div className={styles.headerButtons}>
          <Button
            onClick={() => navigateToExpert(NAVIGATION_DIRECTION_ENUM.PREVIOUS)}
            text={t("prev")}
            startIcon="chevron_left"
            type="tertiary"
            disabled={currentExpertPosition === 0}
          />
          <Button
            onClick={() => navigateToExpert(NAVIGATION_DIRECTION_ENUM.NEXT)}
            text={t("next")}
            endIcon="chevron_right"
            type="tertiary"
            disabled={
              currentExpertPosition + 1 === expertListPaginated?.items.length
            }
          />
          <Icon
            fontSize="24px"
            name="close"
            onClick={closePanel}
            color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
          />
        </div>
      </div>
      <HR
        color={ARBOLUS_COLORS.bColorSecondaryGreyMid}
        margin={{ bottom: 3, top: 3 }}
      />
      <Link
        to={EXPERT_PROFILE_ROUTE(expertId)}
        target="_blank"
        className={styles.link}
      >
        <ArbolusAvatar
          firstName={fullName}
          profileImageUrl={profileImageUrl}
          title={title}
          countryTwoLetterCode={countryTwoLetterCode}
          lastPublicCompanyDate={lastPublicCompanyDate}
          avatarType={isMobile ? "avatarGroupCircleSmall" : "mediumXSCircle"}
          doNotContactStatus={doNotContactStatus}
          isAdmin={isAdmin}
        />
      </Link>
      <HR
        color={ARBOLUS_COLORS.bColorSecondaryGreyMid}
        margin={{ bottom: 3, top: 3 }}
      />
    </div>
  );
};
