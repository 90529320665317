import clsx from "clsx";
import React from "react";
import { useHistory } from "react-router";

import { PROJECT_EVENT_TRANSCRIPT_DOCUMENT_ROUTE } from "@arbolus-technologies/routes";
import { APP_DATE_FORMAT, formatDate } from "@arbolus-technologies/utils";

import { DOCUMENT_PANEL_ROUTE } from "../../../../constants/navigation/panelRoutes";
import { Document, FILE_TYPE } from "../../../../models/documents";
import { DocumentService, UtilsService } from "../../../../services";
import { CIQAvatar } from "../../../app/components";

import { CIQFileIcon } from "./index";

interface DocumentItemProps {
  document: Document;
  projectId: string;
}

export const DocumentItem: React.FC<DocumentItemProps> = ({
  document,
  projectId
}: DocumentItemProps) => {
  const history = useHistory();

  const { uploadedUser, durationSeconds, fileName, type } = document;
  const activeZoneTime = UtilsService.convertUTCToActiveZone(document.created);
  const formattedCreated = formatDate(activeZoneTime, APP_DATE_FORMAT);

  const isTranscriptFile = type === FILE_TYPE.TRANSCRIPT;

  const prettifiedFileName = isTranscriptFile
    ? fileName
    : DocumentService.prettifyFileName(fileName);

  const fileSizeOrDuration = isTranscriptFile
    ? UtilsService.convertSecondsToHoursAndMinutes(durationSeconds || 0)
    : DocumentService.prettifyFileSize(document.fileSize);

  const avatarDisplayName = isTranscriptFile
    ? ""
    : UtilsService.displayUserName(uploadedUser);

  const userImageUrl = isTranscriptFile ? "" : uploadedUser.profileImageUrl;

  const handleFileItemClick = (): void => {
    if (document.type === FILE_TYPE.TRANSCRIPT) {
      history.push(
        PROJECT_EVENT_TRANSCRIPT_DOCUMENT_ROUTE(
          projectId,
          document.transcriptId!
        )
      );
    } else {
      history.replace(DOCUMENT_PANEL_ROUTE(document.id));
    }
  };

  return (
    <div className="file-item" onClick={handleFileItemClick}>
      <div className="left-container">
        <div className="file-type-icon">{CIQFileIcon(fileName, type)}</div>
        <div className="detail-container">
          <div className="file-name">{prettifiedFileName}</div>
          <div className="file-size">{fileSizeOrDuration}</div>
          <div className="file-uploading-progressbar" />
        </div>
      </div>
      <div className="right-container">
        <div className="file-uploaded-date">{`Added : ${formattedCreated}`}</div>
        <div className="file-uploaded-user-avatar">
          <CIQAvatar
            username={avatarDisplayName}
            profileImageUrl={userImageUrl}
            fallBackImageClass={clsx("fallback-avatar", {
              "transcript-avatar": isTranscriptFile
            })}
          />
        </div>
      </div>
    </div>
  );
};
