import { OutputSelector, createSelector } from "reselect";

import { EXPERT_FOLLOW_UP_STEPS } from "@arbolus-technologies/models/expert";

import { ExpertNxAppState, ExpertNxReducerState } from "../models/definitions";

const expertStateSelector = (state: ExpertNxAppState): ExpertNxReducerState =>
  state.expertNx;

export const expertFollowUpStep = (): OutputSelector<
  ExpertNxAppState,
  EXPERT_FOLLOW_UP_STEPS,
  (res: ExpertNxReducerState) => EXPERT_FOLLOW_UP_STEPS
> =>
  createSelector<
    ExpertNxAppState,
    ExpertNxReducerState,
    EXPERT_FOLLOW_UP_STEPS
  >(
    expertStateSelector,
    (expertNxReducerState) => expertNxReducerState.expertFollowUpStep
  );

export const ExpertSelector = {
  expertFollowUpStep
};
